export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: `Un lien d'activation a été envoyé, veuillez vérifier votre e-mail`,
    weHaveSentActivationLinkToEmailAddress: `Nous avons envoyé un lien d'activation vers l'adresse e-mail`,
    toVerifyYourAccount: 'pour vérifier votre compte.',
    onceVerifiedAutomaticallyRedirected: `Une fois vérifié, vous serez automatiquement redirigé vers le panneau.`,
    didNotReceiveAnEmail: `Vous n'avez pas reçu d'e-mail? Vérifiez votre dossier "courrier indésirable`,
    resendIn: 'Repenser',
    seconds: ' secondes',
    resend: 'Revivre',
    backToSignUp: `Retour pour s'inscrire`,
    greatPleaseCheckYourMessage: 'Super, veuillez vérifier votre e-mail',
    weHaveSentEmailTo: 'Nous avons envoyé un email à',
    withALinkToReset: ' avec un lien pour réinitialiser votre compte. Une fois activé, vous pourrez finaliser la création de votre compte et démarrer votre essai gratuit.',
    resent: 'Ne pas vouloir',
    backToLogin: 'Retour connexion',
  },
  forgotPassword: {
    forgotPassword: 'Mot de passe oublié?',
    noWorries: 'Pas de soucis,',
    weWillSendYouResetPasswordInstructions: 'Nous vous enverrons des instructions de mot de passe réinitialisées',
    workEmail: 'Email de travail',
    continue: 'Continuer',
    backToLogin: 'Retour connexion',
    placeholders: {
      enterYourEmail: 'Entrer votre Email',
    }
  },
  logout: {
    logout: 'Se déconnecter',
    wantToLogOut: 'Êtes-vous sûr de vouloir vous déconnecter?',
    yesIWillBeBack: 'Oui, je reviendrai',
    cancel: 'Annuler',
  },
  newPassword: {
    createNewPassword: 'Créer un nouveau mot de passe',
    yourNewPasswordMustBeDifferent: 'Votre nouveau mot de passe doit être différent des mots de passe précédemment utilisés.',
    password: 'Mot de passe',
    confirmPassword: 'Confirmez le mot de passe',
    setPassword: 'Définir le mot de passe',
    signUp: `S'inscrire`,
    login: 'Connexion',
    backTo: 'Retour à',
    setAPassword: 'Définir un mot de passe',
    setNewPasswordToYourAccount: 'Définissez un nouveau mot de passe sur votre compte à des fins de sécurité et de confidentialité',
    backToSignUp: `Retour pour s'inscrire`,
    placeholders: {
      enterYourPassword: 'Tapez votre mot de passe',
    }
  },
  resetPassword: {
    passwordReset: 'Réinitialisation du mot de passe',
    yourPasswordHasBeenSuccessfullyReset: 'Votre mot de passe a été réinitialisé avec succès.',
    clickBelowToLogIn: 'Cliquez ci-dessous pour vous connecter',
    continueToLogin: 'Continuer à se connecter',
  },
  newSignUp: {
    welcomeBack: 'Content de te revoir',
    to: 'pour',
    logIn: 'Connexion',
    as: 'comme',
    withFacebook: 'avec Facebook',
    logInWithGoogle: 'Connectez-vous avec Google',
    or: 'OU',
    pleaseFillAValidWorkEmail: 'Veuillez remplir un e-mail de travail valide *',
    workEmailIsRequired: 'Un e-mail de travail est requis *',
    pleaseFillAValidPassword: 'Veuillez remplir un mot de passe valide *',
    passwordIsRequired: 'Mot de passe requis*',
    rememberMe: 'Souviens-toi de moi',
    forgotPassword: 'Mot de passe oublié?',
    login: 'Connexion',
    doNotHaveAnAccount: `Vous n'avez pas de compte?`,
    setUpAFreeAccount: 'Configurer un compte gratuit',
    noCreditCardRequired: 'Pas de carte de crédit nécessaire',
    cancelAnytime: 'Annuler à tout moment',
    signUp: `S'inscrire`,
    signUpWithGoogle: 'Inscrivez-vous avec Google',
    emailIsAlreadyRegistered: 'Le courrier électronique est déjà enregistré avec nous, cliquez sur Connectez-vous pour continuer',
    phoneNumberShouldHaveMinimum7Digits: 'Le numéro de téléphone doit avoir un minimum de 7 chiffres *',
    phoneNumberIsRequired: 'Le numéro de téléphone est requis *',
    passwordMustContain: 'Le mot de passe doit contenir',
    atleast6Characters: 'au moins 6 caractères,',
    oneUpperCaseLetter: '1 lettre Majuscule',
    oneSpecialCharacter: '1 caractère spécial *',
    alreadyHaveAccount: 'Vous avez déjà un compte?',
    signingUpToBotPenguinIsAConsentToOur: `L'inscription à un compte BotPenguin est votre consentement à notre`,
    privacyPolicy: 'politique de confidentialité',
    termsAndConditions: 'Termes et conditions',
    and: ' et',
    english: 'Anglais',
    hindi: 'hindi',
    german: 'Allemand',
    french: 'Français',
    portuguese: 'Portugais',
    spanish: 'Espagnol',
    italian: 'italien',
    russian: 'russe',
    placeholders: {
      workEmail: 'Email de travail*',
      password: 'Mot de passe*',
      phone: 'Téléphone*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Désinscription réussie',
    yourEmail: 'Votre email ',
    haveBeenSuccessfullyUnsubscribedFrom: ' a été désinscrit avec succès de',
    backToLogin: 'Retour à la connexion'
  }
};
