export const whitelabel = {
  plans: 'Planos',
  managePlan: 'Gerenciar planos',
  whitelabelCharges: 'Whitelabel cobra',
  chargesOrTaxesConfiguration: 'Configuração de cobranças/impostos',
  websitePlans: 'Planos de site',
  whatsappPlansHeader: 'Planos do WhatsApp',
  addOnPlans: {
    createAddOnPlans: 'Crie planos de complemento',
    checkoutYourCurrentMessage: 'Confira seus planos complementares de mensagens atuais e novos planos para seus clientes',
    choosePlanValidity: 'Escolha a validade do plano',
    chooseValidity: 'Escolha a validade',
    oneYear: 'Um ano',
    billingCycle: 'Ciclo de cobrança',
    numberOfMessages: 'Número de mensagens',
    pleaseEnterNumberOfMessages: 'Por favor, insira o número de mensagens',
    setCurrency: 'Defina moeda',
    select: 'Selecione',
    inr: 'EM R',
    usd: 'USD',
    cad: 'cafajeste',
    clearAll: 'Limpar tudo',
    apply: 'Aplicar',
    currency: 'Moeda',
    amount: 'Quantia',
    validity: 'Validade',
    upgradePlan: 'Plano de atualização',
    addPlan: 'Adicione o plano',
    serialNumber: 'Sr. Não.',
    messages: 'Mensagens',
    // validity: 'Validade',
    active: 'Ativo',
    priceDetails: 'Detalhes dos preços',
    action: 'Ação',
    addOnChanges: 'Cobranças adicionais:',
    // messages: ' Mensagens',
    perYear: 'por ano',
    taxes: 'Impostos',
    placeholders: {
      enterNumberOfMesasges: 'Insira o número de mensagens',
      enterAmount: 'Insira o valor',
      enterValidity: 'Digite validade',
      searchByMessages: 'Pesquise por mensagens',
    },
    modal: {
      deleteAddOnsPlan: 'Exclua o plano de complementos',
      wantToDeleteThisAddOnsPlan: 'Tem certeza de que deseja excluir este plano de complementos?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Adicione as cobranças de Whitelabel',
    checkoutExistingCharges: 'Confira as cobranças existentes em diferentes métodos de pagamento e adicione novas cobranças de Whitelabel',
    currency: 'Moeda',
    chooseCurrency: 'escolha a moeda',
    amountPerBotPerMonth: 'Valor por bot por mês',
    cancel: 'Cancelar',
    upgradeChanges: 'Atualizar cobranças',
    addCharges: 'Adicione cobranças',
    serialNumber: 'Sr. Não.',
    // currency: 'Moeda',
    action: 'Ação',
    taxes: 'Impostos',
    placeholders: {
      enterAmountBotPerMonth: 'Insira o valor do valor por mês',
      searchByCurrency: 'Pesquisa por moeda',
    },
    modal: {
      deleteCharges: 'Excluir acusações',
      wantToDeleteThisCharge: 'Tem certeza de que deseja excluir essa cobrança?',
    }
  },
  planManagement: {
    createNewPlans: 'Crie novos planos',
    checkoutYourCurrentPlan: 'Confira seus planos atuais e adicione novos planos para seus clientes',
    choosePlan: 'Escolha o plano',
    selectPlan: 'Selecione o plano',
    baby: 'Bebê',
    king: 'Rei',
    displayName: 'Nome de exibição',
    messageLimit: 'Limite de mensagem',
    enterAValidEmail: 'Entre com um email válido',
    setCurrency: 'Defina moeda',
    select: 'Selecione',
    inr: 'EM R',
    usd: 'USD',
    cad: 'cafajeste',
    clearAll: 'Limpar tudo',
    apply: 'Aplicar',
    perMonth: '/Mês',
    perYear: '/Ano',
    cancel: 'Cancelar',
    upgradePlan: 'Plano de atualização',
    addPlan: 'Adicione o plano',
    serialNumber: 'Sr. Não.',
    plan: ' Plano',
    // displayName: 'Nome de exibição',
    messages: 'Mensagens',
    pricing: 'Preço',
    actions: 'Ações',
    // plan: ' Plano',
    perMonthText: 'Por mês',
    perYearText: 'Por ano',
    placeholders: {
      enterDisplayName: 'Digite o nome da exibição',
      enterMessageLimit: 'Digite o limite da mensagem',
      searchByPlanName: 'Pesquisar por Nome do Plano ou Mensagens'
    },
    modal: {
      deletePlan: 'Excluir Plano',
      wantToDeleteThisBabyPlan: 'Excluir o Plano Baby afetará todos os clientes. Tem certeza de que deseja excluir este plano?',
      wantToDeleteThisPlan: 'Tem certeza de que deseja excluir este plano?',
    },
    appTooltip: {
      chooseYourPlanType: 'Escolha seu tipo de plano',
      pleaseInputYourPlanDisplayName: 'Insira o nome de exibição do seu plano',
      typeMessageLimitForCustomer: 'Digite o limite de mensagem para o cliente',
      selectYourPreferredCurrencies: 'Selecione suas moedas preferidas',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Adicione impostos',
    checkoutExistingTaxes: 'Confira os impostos existentes sobre diferentes métodos de pagamento e novos impostos',
    taxName: 'Nome de imposto',
    applicableOn: 'Aplicável em',
    choosePlans: 'Escolha planos',
    chooseCurrency: 'escolha a moeda',
    percentage: 'Percentagem',
    cancel: 'Cancelar',
    // addTaxes: 'Adicione impostos',
    serialNumber: 'Sr. Não.',
    name: 'nome',
    // percentage: 'Percentagem',
    currency: 'Moeda',
    updateTaxes: 'Atualizar impostos',
    actions: 'Ações',
    modal: {
      deleteTaxes: 'Excluir impostos',
      wantToDeleteThisTax: 'Tem certeza de que deseja excluir esses impostos?',
    },
    placeholders: {
      taxName: 'Nome de imposto',
      taxPercentage: 'Porcentagem de impostos',
      searchByCurrency: 'Pesquisa por moeda',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Crie novos planos',
    checkoutYourCurrentAndNewPlans: 'Confira seus planos atuais e adicione novos planos para seus clientes',
    choosePlan: 'Escolha o plano',
    selectPlan: 'Selecione o plano',
    baby: 'Bebê',
    king: 'Rei',
    displayName: 'Nome de exibição',
    messageLimit: 'Limite de Conversa',
    enterAValidMessageLimit: 'Insira um limite de conversa válido',
    messages: 'Conversas',
    setCurrency: 'Defina moeda',
    select: 'Selecione',
    inr: 'EM R',
    usd: 'USD',
    cad: 'cafajeste',
    clearAll: 'Limpar tudo',
    apply: 'Aplicar',
    perMonth: '/Mês',
    perYear: '/Ano',
    cancel: 'Cancelar',
    addPlan: 'Adicione o plano',
    serialNumber: 'Sr. Não.',
    plan: 'Plano',
    pricing: 'Preço',
    actions: 'Ações',
    perMonthText: 'Por mês',
    perYearText: 'Por ano',
    updatePlan: 'Plano de atualização',
    // addPlan: 'Adicione o plano',
    placeholders: {
      enterDisplayName: 'Digite o nome da exibição',
      enterMessageLimit: 'Insira o limite de conversa',
      searchByPlanName: 'Pesquisar por Nome do Plano ou Conversas',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Digite o limite de conversa para o cliente',
      chooseYourPlanType: 'Escolha seu tipo de plano',
      selectYourPreferredCurrencies: 'Selecione suas moedas preferidas',
      pleaseInputYourPlanDisplayName: 'Insira o nome de exibição do seu plano',
    },
    modal: {
      deletePlan: 'Excluir Plano',
      wantToDeleteThisBabyPlan: 'Excluir o Plano Baby afetará todos os clientes. Tem certeza de que deseja excluir este plano?',
      areYouSureYouWantToDeleteThisPlan: 'Tem certeza de que deseja excluir este plano?',
    }
  }
};
