export const inboxMerged = {
  inboxHeader: '受信トレイ',
  chatsHeader: 'チャット',
  contactsHeader: '連絡先',
  segmentsHeader: 'グループ',
  ordersHeader: '注文',
  exportStatus: 'エクスポートのステータス',
  tasksExport: 'タスクエクスポート',
  download: 'ダウンロード',
  ourAllNewOmniChannelInbox: '私たちの新しいオムニチャネル受信トレイ！',
  allYourMessagesContactsAndLeads: 'すべてのメッセージ、連絡先、リードが一つの場所にあります。整理してグループ化して、ビジネスを成長させましょう！',
  close: '閉じる',
  takeALook: '見てみる',
  inProgress: '進行中',
  appTooltip: {
    manageAllYourLeadsHere: 'すべてのリード/連絡先をここで管理します',
    segmentYourUserLeads: '人口統計情報と興味に基づいてリード/ボットユーザーをグループ化します。これらはブロードキャストの作成に使用されます',
    manageAllBotsConversationsHere: 'すべてのボットの対話をここで管理します',
    manageAllYourOrdersHere: 'すべての注文をここで管理します',
  },
  contacts: {
    seeHowToUse: '使用方法を確認',
    help: 'ヘルプ',
    visitorName: '訪問者名',
    email: 'メール',
    phoneNumber: '電話番号',
    segment: 'グループ',
    status: 'ステータス',
    tag: 'タグ',
    action: 'アクション',
    discard: '破棄',
    addTag: 'タグを追加',
    loadMore: 'さらに読み込む',
    nA: 'N/A',
    assign: '割り当て',
    deleteUser: 'ユーザーを削除',
    areYouSureToDeleteLead: 'このリードを削除してもよろしいですか？',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'まだ連絡先がありません！',
      allOfYourContactsAppearHere: 'すべての連絡先がここに表示されます'
    },
    placeholders: {
      searchSegmentByName: '名前でグループを検索',
      searchOrAddTag: 'タグを検索または追加',
    },
    addContacts: {
      name: '名前',
      email: 'メール',
      enterAValidEmail: '有効なメールアドレスを入力してください',
      phoneNumber: '電話番号',
      cancel: 'キャンセル',
      addContact: '連絡先を追加',
      placeholders: {
        enterEmailAddress: 'メールアドレスを入力',
        enterUserrName: 'ユーザー名を入力',
        searchCountry: '国を検索',
        enterPhoneNumber: '電話番号を入力',
      }
    }
  },
  inboxFilter: {
    applied: '適用済み',
    assignTags: 'タグを割り当てる',
    discard: '破棄',
    addTag: 'タグを追加',
    assignSegments: 'グループを割り当てる',
    filterYourLeads: 'リードをフィルタリング',
    filters: 'フィルター',
    filter: 'フィルター',
    resetAllFilters: 'すべてのフィルターをリセット',
    importContacts: '連絡先をインポート',
    beforeYouStart: 'アップロードを開始する前に、次のことを確認してください',
    point1: '1. サンプルのExcelファイルをダウンロードします。',
    point2: '2. 2行目以降からデータを入力します。',
    point3: '3. 各行には1つの連絡先のデータが含まれます。',
    point4: '4. すべての電話番号が一意であることを確認してください。',
    point5: '5. ファイルの最大サイズは20MBです。',
    selectFile: 'ファイルを選択',
    clickHere: 'ここをクリック',
    toDownloadSampleFile: 'サンプルファイルをダウンロードするには',
    modal: {
      closeLeads: 'リードを閉じる',
      areYouSureToselectAllLeads: '選択したすべてのリードを閉じてもよろしいですか？',
      assignAgent: 'エージェントを割り当てる',
      toAssignAgentToAllLeads: '選択したすべてのリードにエージェントを割り当ててもよろしいですか？',
      assignTag: 'タグを割り当てる',
      wantToReplacePreviouslyAssignedTagWithNew: '選択したすべてのリードに新しい選択したタグで以前に割り当てられたタグを置き換えてもよろしいですか？',
      deleteLeads: 'リードを削除する',
      wantToDeleteSelectedLeads: '選択したすべてのリードを削除してもよろしいですか？',
      exportData: 'データをエクスポート',
      exportDataBasedOn: '次に基づいてデータをエクスポート',
      assignSegment: 'グループを割り当てる',
      wantToAssignSegmentsToSelectedLeads: '選択したすべてのリードにグループを割り当ててもよろしいですか？',
      yesGoAhead: 'はい、進む！',
      export: 'エクスポート'
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'タグ、ボット、ステータスなどの設定に基づいてデータをフィルタリングします。',
      markLeadAsClosed: 'ユーザー/リードを閉じたとしてマークします',
      assignAgentToLead: 'ユーザーにエージェントを割り当てます',
      assignTagToUser: 'ユーザーにタグを割り当てます',
      exportData: 'データをエクスポートします',
      importContactsForWhatsappBot: 'WhatsAppボットの連絡先をインポートします',
      addWhatsappContact: 'WhatsAppの連絡先を追加します',
      assignSegmentToUser: 'ユーザーにグループを割り当てます',
      deleteUsers: 'ユーザーを削除します',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: '名前、メール、または電話番号を入力してEnterキーを押してください',
      searchAgentByName: 'エージェントの名前を検索',
      searchOrAddTag: 'タグを検索または追加',
      searchSegmentByName: '名前でグループを検索',
    },
    filterContainer: {
      filterOn: 'フィルター',
      filterBy: 'フィルター',
      startFrom: '開始日',
      tillDate: '終了日',
      offlineOrOnline: 'オフライン/オンライン',
      liveChat: 'ライブチャット',
      userInteractedWithBot: 'ユーザーがボットと対話しました',
      discard: '破棄',
      applyFilter: 'フィルターを適用',
      bots: 'ボット',
      tags: 'タグ',
      status: 'ステータス',
      assignedTo: '割り当て先',
      lastMessageBy: '最終メッセージ送信者',
      onlineOrOffline: 'オンライン/オフライン',
      userInteracted: 'ユーザーが対話しました',
      segments: 'グループ',
      lastSeenDate: '最終アクセス日',
      onboardingDate: 'オンボーディング日',
      createTags: 'タグを作成',
      createTagsToSortAndManageLeads: 'リードを簡単に整理および管理するためのタグを作成します',
      typeYourTagHere: 'タグをここに入力してください',
      createSegment: 'グループを作成',
      manageSegmentForMarketingAutomation: 'マーケティングオートメーションのためにグループを管理します',
      typeYourSegmentHere: 'グループをここに入力してください',
      placeholders: {
        searchFilter: 'フィルターを検索',
        searchByBotName: 'ボットの名前で検索',
        searchOrAddTag: 'タグを検索または追加',
        searchByAgentName: 'エージェントの名前で検索',
        searchBySegments: 'グループで検索',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: '選択を終了したい日付範囲',
        dateRangeYouWishToStartSelectionFrom: '選択を開始したい日付範囲',
        filterThoseThatAreOfflineOrOnline: 'オフライン/オンラインのユーザーをフィルタリング',
        filterThoseThatAreCurrentlyLiveChatting: '現在ライブチャット中のユーザーをフィルタリング',
        filterThoseThatInteractedWithBot: 'ボットと対話したユーザーをフィルタリング'
      },
      indexMergeDatePicker: {
        selectStartDate: '開始日を選択',
        selectEndDate: '終了日を選択',
      }
    }
  },
  leads: {
    seeHowToUse: '使用方法を確認',
    help: 'ヘルプ',
    visitorName: '訪問者名',
    email: 'メール',
    phoneNumber: '電話番号',
    assignedTo: '割り当て先',
    status: 'ステータス',
    tag: 'タグ',
    action: 'アクション',
    discard: '破棄',
    addTag: 'タグを追加',
    loadMore: 'さらに読み込む',
    nA: 'N/A',
    deleteUsers: 'ユーザーを削除',
    areYouSureToDeleteLead: 'このリードを削除してもよろしいですか？',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'まだチャットがありません',
      allOfYourChatsAppearHere: 'すべてのチャットはここに表示されます',
    },
    placeholders: {
      searchAgentByName: 'エージェントの名前を検索',
      searchStatusByName: 'ステータスを名前で検索',
      searchOrAddTag: 'タグを検索または追加',
    }
  },
  messages: {
    seeHowToUse: '使用方法を確認',
    help: 'ヘルプ',
    editChatFlow: 'チャットフローを編集',
    connect: '接続',
    chat: {
      photo: '写真',
      video: 'ビデオ',
      document: '文書',
      location: '位置',
      assignedTo: '担当者',
      selectBot: 'ボットを選択',
      discard: '破棄',
      addTag: 'タグを追加',
      yourBrowserDoesNotSupportVideoTag: 'お使いのブラウザはビデオタグをサポートしていません。',
      userHasSharedADocument: 'ユーザーが文書を共有しました。',
      clickHereToDownload: 'ダウンロードするにはここをクリック',
      thisStoryIsNoLongerAvailable: 'このストーリーはもう利用できません',
      yourBrowserDoesNotSupportAudioElement: 'お使いのブラウザはオーディオ要素をサポートしていません。',
      thisMessageIsNoLongerAvailable: 'このメッセージは送信者によって取り消されたため、もう利用できません',
      userHasSharedHisLocation: 'ユーザーが位置情報を共有しました。表示するにはここをクリック',
      sms: 'SMS',
      failed: '失敗',
      a: 'A',
      i: 'I',
      r: 'R',
      m: 'M',
      conversationsSelected: '選択された対話',
      nA: 'NA',
      assignTagsCloseOrBulkReply: 'タグを割り当て、閉じる、または一括返信をすべて一度に行う',
      appTooltip: {
        assignAgent: 'エージェントを割り当て',
        selectBot: 'ボットを選択',
        assignTagsToManageLeads: 'リードと対話を管理するためのタグを割り当て',
      },
      placeholders: {
        searchAgentByName: 'エージェントの名前で検索',
        searchBotByName: 'ボットの名前で検索',
        searchOrAddTag: 'タグを検索または追加',
        searchStatusByName: '名前でステータスを検索',
      }
    },
    profile: {
      userDetails: 'ユーザーの詳細',
      orderDetails: '注文の詳細',
      orderedOn: '注文日',
      showMore: '詳細表示',
      showLess: '簡略表示',
      subTotal: '小計',
      nA: 'NA',
      addAWhatsappNumber: 'WhatsApp番号を追加',
      addTelegramUserName: 'Telegramユーザー名を追加',
      addInstagramProfileId: 'InstagramプロファイルIDを追加',
      addFacebookProfileId: 'FacebookプロファイルIDを追加',
      add: '追加',
      deviceProperties: 'デバイスプロパティ',
      privateNotes: 'プライベートノート',
      urlsVisited: '訪問したURL',
      source: 'ソース - ',
      sourceNa: 'ソース - NA',
      medium: 'メディアム - ',
      mediumNa: 'メディアム - NA',
      campaign: 'キャンペーン - ',
      campaignNa: 'キャンペーン - NA',
      attributeKey: '属性キー',
      attributeName: '属性名',
      discard: '破棄',
      addAttribute: '属性を追加',
      editAttribute: '属性を編集',
      deleteAttribute: '属性を削除',
      headsUp: 'ご注意',
      areYouSureToDeleteAttribute: 'この属性を削除してもよろしいですか？',
      okay: 'OK',
      dataAddedToLead: '既存の設定に基づいてリードに追加されたデータは、これらの新しい設定に従って更新されません。',
      assignedOnly: '担当者のみ',
      allUsers: 'すべてのユーザー',
      save: '保存',
      manageCustomAttributes: 'カスタム属性を管理する',
      placeholders: {
        title: 'タイトル',
        egServices: '例：サービス',
        egRequestedServices: '例：要求されたサービス',
        enterYourNotesHere: 'ここにノートを入力してください...',
      },
      bpDropDown: {
        selectBot: 'ボットを選択',
        selectFlow: 'フローを選択',
        selectQuestion: '質問を選択*',
      }
    },
    sendMessage: {
      send: '送信',
      sendToAllChannels: 'すべてのチャネルに送信',
      email: '電子メール',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      facebook: 'Facebook',
      sendSms: 'SMSを送信',
      addNew: '新規追加',
      addNewQuickReply: '新規クイックリプライを追加',
      discard: '破棄',
      fromComputer: 'コンピュータから',
      add: '追加',
      dragAndDropHereOr: 'ここにドラッグアンドドロップするか、',
      browseFiles: 'ファイルを参照',
      caption: 'キャプション',
      templates: 'テンプレート',
      addHeaderMedia: 'ヘッダーメディアを追加',
      selectFile: 'ファイルを選択',
      previousConversationClosed: '前の会話は終了しました！',
      sendTemplateMessage: 'テンプレートメッセージを送信して会話を再開します。',
      selectTemplate: 'テンプレートを選択',
      conversationLocked: '会話がロックされています！',
      demoBotOnlyAllowConversations: 'デモボットでは、事前に確認した番号との会話のみ許可されています。',
      placeholders: {
        searchQuickReplyByTitle: 'タイトルでクイックリプライを検索',
        addTitle: 'タイトルを追加',
        addYourRepliesHere: 'ここに返信を追加...',
        addCaption: 'キャプションを追加',
        enterValueForThisVariable: 'この変数の値を入力',
      },
      modal: {
        sendBulkMessages: '一括メッセージ送信',
        send: '送信',
        description: 'メッセージはユーザーのプラットフォームのタイプに基づいてクリーンアップされ、送信されます。' +
          '\n - ウェブサイトのユーザーはテキストベースのメッセージのみサポートします \n - WhatsApp（未登録）の連絡先はテンプレートメッセージのみ受信できます'
      },
      noDataFound: {
        oops: 'おっと！',
        currentlyDoesNotSupportBulkMessages: '現在、WhatsAppボット用の一括メッセージ送信はサポートされていません。ブロードキャスト機能を使用してください',
        quickReplies: 'クイックリプライ',
        noQuickRepliesAddedYet: 'まだクイックリプライは追加されていません！',
      }
    },
    userList: {
      selectAll: 'すべて選択',
      nA: 'NA',
      loadMore: 'さらに読み込み',
    }
  },
  segments: {
    seeHowToUse: '使用方法を確認',
    help: 'ヘルプ',
    manageSegment: 'セグメントを管理',
    manageYourSegmentsForMarketingAutomation: 'マーケティング自動化のためにセグメントを管理します',
    createNewSegment: '新しいセグメントを作成',
    segmentName: 'セグメント名',
    totalContacts: '総連絡先数',
    action: 'アクション',
    createSegmentButton: 'セグメントを作成',
    deleteSegments: 'セグメントを削除',
    areYouSureToDeleteSegment: 'このセグメントを削除してもよろしいですか？',
    placeholders: {
      searchHere: 'ここで検索',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'まだ作成されたセグメントがありません',
      allYourSegmentsAppearHere: 'すべてのセグメントがここに表示されます'
    },
    modal: {
      exportData: 'データをエクスポート',
      exportDataBasedOnAppliedFilters: '適用されたフィルタに基づいてデータをエクスポート',
      export: 'エクスポート',
    },
    appTooltip: {
      deleteSegments: 'セグメントを削除',
      editSegment: 'セグメントを編集',
      downloadSegment: 'セグメントをダウンロード',
      deleteSegment: 'セグメントを削除'
    },
    createSegment: {
      createSegment: 'グループを作成',
      manageYourSegmentsForMarketingAutomation: 'マーケティング自動化のためにグループを管理します',
      segmentName: 'グループ名',
      segmentNameIsRequired: 'グループ名は必須です',
      selectContactsFilterForSegment: 'グループの連絡先フィルタを選択',
      filterYourContacts: '連絡先をフィルタリング',
      removeAllFilters: 'すべてのフィルタを削除',
      addFilters: 'フィルタを追加',
      discard: '破棄',
      filterSelected: '選択したフィルタ',
      placeholders: {
        enterSegmentName: 'グループ名を入力',
      }
    },
  },
  orders: {
    visitorName: '訪問者名',
    email: 'メール',
    phoneNumber: '電話番号',
    status: 'ステータス',
    itemCount: 'アイテム数',
    total: '合計',
    tag: 'タグ',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'まだ注文がありません',
      allOfYourOrdersAppearHere: 'すべての注文がここに表示されます'
    },
  }
};

