import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/appv2/modules/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class UnsavedWarningGuard implements CanActivate {
  constructor(
    private sharedService: SharedService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentState = this.sharedService.unsavedWarning.getValue();

    /**
     * Author: Apoorv Saxena
     * Date: 7 June, 20023
     * Summary: Checks added to verify, Either api in process or not
     * Description: apiInProgress is checking api in process and isConfirm is resolve infinite
     * unsaved dialog box showing error, After click on confirm
     */
    if (currentState.apiInProgress && !currentState.isConfirm) {
      this.sharedService.unsavedWarning.next({ ...currentState, openUnsavedDialog: true, navigateUrl: state.url });
      return false; // Prevent navigation
    } else {
      this.sharedService.unsavedWarning.next({
        ...currentState,
        ...(currentState.isConfirm && { isConfirm: false }),
        ...(currentState.apiInProgress && { apiInProgress: false })
      });
      return true;
    }

  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

}
