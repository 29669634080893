const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'अपने चैट प्रवाह को संपादित करें',
    seeHowToUse: 'देखें कि कैसे उपयोग करें',
    help: 'मदद',
    classicBuilder: 'क्लासिक बिल्डर',
    visualiseFlow: 'प्रवाह की कल्पना करें',
    testLiveOnTelegramWeb: 'टेलीग्राम वेब पर लाइव टेस्ट करें',
    testFlow: 'परीक्षण प्रवाह',
    importTemplate: 'आयात टेम्पलेट',
    landingPageBotReview: 'लैंडिंग पेज बॉट पूर्वावलोकन',
    install: 'स्थापित करना',
    testLiveOnMessengerWeb: 'मैसेंजर वेब पर लाइव टेस्ट करें',
    testLiveOnWhatsappWeb: 'व्हाट्सएप वेब पर लाइव टेस्ट करें',
    testLiveOnInstagramWeb: 'इंस्टाग्राम वेब पर लाइव टेस्ट करें',
    seeAllChatFlow: 'सभी चैट प्रवाह देखें',
    launchDemoModal: 'लॉन्च डेमो मोडल',
    selectATemplateToKickStartYourBot: 'अपनी बॉट शुरू करने के लिए एक टेम्पलेट का चयन करें',
    hireChatBotDesigner: 'चैटबॉट डिजाइनर को किराए पर लें',
    recommendedForYou: 'आप के लिए अनुशंसित',
    useTemplate: 'टेम्पलेट का इस्तेमाल करें',
    exportStatus: 'निर्यात की स्थिति',
    flowExport: 'प्रवाह-निर्यात',
    download: 'डाउनलोड करना',
    importFlow: 'आयात प्रवाह',
    beforeYouStartUploadPleaseMakeSure: 'इससे पहले कि आप अपलोड शुरू करें कृपया सुनिश्चित करें',
    ponit1: '1. अधिकतम फ़ाइल आकार अपलोड सीमा 20MB है',
    point2: '2. फ़ाइल प्रारूप JSON में होना चाहिए',
    selectFile: 'फ़ाइल का चयन करें',
    preview: 'पूर्व दर्शन',
    test: 'परीक्षण',
    OopsIntegrationAreNotAvailable: 'ओहो! इंटीग्रेशन ',
    pleaseUpgradeTo: ' में उपलब्ध नहीं हैं । कृपया अपग्रेड करें ',
    planForIntegration: ' में इंटीग्रेशन के लिए ।',
    placeholders: {
      searchTemplateYouNeed: 'जिस टेम्पलेट की आवश्यकता है उसे खोजें'
    },
    noDataFound: {
      botFlow: 'बॉट प्रवाह',
      noBotFlowAddedYet: 'कोई बॉट प्रवाह अभी तक नहीं जोड़ा गया!',
      templates: 'टेम्पलेट्स',
      noTemplatesFound: 'कोई टेम्पलेट नहीं मिला!'
    },
    appTooltip: {
      exportFlow: 'निर्यात प्रवाह',
      importFlow: 'आयात प्रवाह',
      clickToViewChatFlow: 'ड्रैग एंड ड्रॉप बिल्डर में चैट फ्लो देखने के लिए क्लिक करें',
      clickToPreviewChatFlow: 'चैट प्रवाह का पूर्वावलोकन करने के लिए क्लिक करें',
      clickToViewChatFlowAsFlowDiagram: 'फ्लो आरेख के रूप में चैट प्रवाह को देखने के लिए क्लिक करें',
      clickToTestChatFlow: 'चैट प्रवाह का परीक्षण करने के लिए क्लिक करें',
      clickToSelectAndImportTemplate: 'टेम्पलेट का चयन करने और आयात करने के लिए क्लिक करें जो आपके व्यवसाय को सबसे अच्छा लगता है।',
      clickToInstallYourBotToSelectedPlatform: 'चयनित प्लेटफ़ॉर्म पर अपना बॉट इंस्टॉल करने के लिए क्लिक करें',
      clickToTestDefaultFlowOfTelegramBot: 'टेलीग्राम वेब पर अपने बॉट के डिफ़ॉल्ट (सक्रिय) प्रवाह का परीक्षण करने के लिए क्लिक करें',
      clickToTestDefaultFlowOfFacebookBot: 'फेसबुक वेब पर अपने बॉट के डिफ़ॉल्ट (सक्रिय) प्रवाह का परीक्षण करने के लिए क्लिक करें',
      clickToTestDefaultFlowOfWhatsappBot: 'व्हाट्सएप वेब पर अपने बॉट के डिफ़ॉल्ट (सक्रिय) प्रवाह का परीक्षण करने के लिए क्लिक करें',
      clickToTestDefaultFlowOfInstagramBot: 'इंस्टाग्राम वेब पर अपने बॉट के डिफ़ॉल्ट (सक्रिय) प्रवाह का परीक्षण करने के लिए क्लिक करें'
    }
  },
  createNewBot: {
    createNewBot: 'नया बॉट बनाएं',
    stepOneOfTwo: 'चरण 2 में से 1 ',
    selectThePlatform: 'प्लेटफ़ॉर्म का चयन करें',
    selectTheBotType: 'बॉट प्रकार का चयन करें',
    aiBasedChatbot: 'एआई आधारित चैटबॉट',
    ruleBasedChatBot: 'नियम आधारित चैटबॉट',
    webPlatforms: 'वेब प्लेटफ़ॉर्म',
    websiteChatBot: 'वेबसाइट चैटबॉट',
    withLiveChat: '(लाइव चैट के साथ)',
    landingPageBot: 'लैंडिंग पेज बॉट',
    liveChatWidget: 'लाइव चैट विजेट',
    onlyLiveChat: '(केवल लाइव चैट)',
    socialPlatforms: 'सामाजिक प्लेटफ़ॉर्म',
    whatsappChatBot: 'व्हाट्सएप चैटबॉट',
    telegramChatBot: 'टेलीग्राम चैटबोट',
    facebookChatBot: 'फेसबुक चैटबॉट',
    instagramChatBot: 'इंस्टाग्राम चैटबॉट',
    selectBotType: 'बॉट प्रकार का चयन करें',
    selectProblem: 'वह समस्या चुनें जिसे आप हल करना चाहते हैं',
    leadGeneration: 'लीड जनरेशन',
    customerSupport: 'ग्राहक सहायता',
    appointmentBooking: 'अपॉइंटमेंट बुकिंग',
    marketingAutomation: 'मार्केटिंग स्वचालन',
    ecommerceWebsite: 'ई-कॉमर्स वेबसाइट',
    otherUseCase: 'अन्य',
    back: 'पीछे',
    typeDomainUrl: 'सत्यापन के लिए अपना वेबसाइट URL टाइप करें।',
    dataSourceForChatBotTraining: 'चैट बॉट प्रशिक्षण के लिए डेटा स्रोत',
    upload: 'अपलोड करें',
    uploadDocuments: 'दस्तावेज़ अपलोड करें',
    uploadDocument: 'दस्तावेज़ अपलोड करें',
    selectFile: 'फ़ाइल चुनें',
    continue: 'जारी रखें',
    websiteUrl: 'अपना वेबसाइट URL टाइप करें',
    skipNow: 'अब छोड़ें',
    completeTheExistingBotSetup: 'संपादित बॉट सेटिंग को पूरा करें',
    youHaveAlreadyCreatedOneWhatsAppBot: 'आपने पहले से ही एक WhatsApp बॉट बना लिया है, कृपया उसकी सेटअप पूरी करें ताकि एक नया बना सकें।',
    completeSetup: 'सेटिंग को पूरा करें',
    seeHowToUse: 'देखें कि कैसे उपयोग करें',
    help: 'मदद',
    fbBot: {
      createNewBot: 'नया बॉट बनाएं',
      help: 'मदद',
      back: 'पीछे',
      step: 'चरण 3 में से ',
      of3: ' ',
      loginWithFacebook: 'फ़ेसबुक लॉगिन करें',
      asPerLatestChangesInFacebookPolicy: 'फेसबुक नीतियों में नवीनतम परिवर्तनों के अनुसार, फेसबुक बॉट के मालिक के पास फेसबुक पेज की सभी सुविधाओं तक पहुंचने के लिए `प्रबंधक` या उससे ऊपर की भूमिका होनी चाहिए।',
      login: 'लॉग इन करें',
      connectFacebookBot: 'फेसबुक बॉट कनेक्ट करें',
      connectBotWithYourFacebookPagesAnd: 'अपने फेसबुक पेजों के साथ बॉट कनेक्ट करें और',
      exploreTruePowerOfFacebook: 'फेसबुक की सच्ची शक्ति का अन्वेषण करें',
      connect: 'जोड़ना',
      connected: 'जुड़े हुए',
      createNewPage: 'नया पेज बनाएं',
      refreshPageList: 'पृष्ठ सूची को ताज़ा करें',
      thisPageIsSuccessfullyIntegratedWith: 'यह पृष्ठ सफलतापूर्वक एकीकृत है',
      yourMessagesFromMessengerWillAppearIn: '। मैसेंजर से आपके संदेश दिखाई देंगे',
      inbox: ' इनबॉक्स',
      continueToBotConfiguration: 'बॉट कॉन्फ़िगरेशन को जारी रखें',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} में आपका स्वागत है। हम यहां आपकी सहायता के लिए उपलब्ध हैं।`,
      letsTryYourBotAndSeeHowItWorks: 'चलो अपने बॉट की कोशिश करते हैं और देखें कि यह कैसे काम करता है! "फेसबुक पर टेस्ट" पर क्लिक करें।',
      howAreYouDoingToday: 'आज आप कैसे हैं?',
      thisIsHowSimpleToUseFacebookBot: 'इतना ही! यह फेसबुक बॉट का उपयोग करना कितना सरल है',
      letsCreateYourOwnChatFlowNow: 'आइए अब अपना खुद का चैट फ्लो बनाएं',
    },
    instagramBot: {
      createNewBot: 'नया बॉट बनाएं',
      back: 'पीछे',
      step: 'चरण 3 में से ',
      of3: ' ',
      loginWithInstagram: 'इंस्टाग्राम के साथ लॉगिन करें',
      loginToYourInstagramAccount: 'अपने हैंडल पर चैटबॉट को सक्षम करने के लिए अपने इंस्टाग्राम अकाउंट पर लॉगिन करें।',
      managerLevelDescription: 'इंस्टाग्राम बॉट मालिकों को सभी सुविधाओं तक पहुंचने के लिए कम से कम एक प्रबंधक स्तर की अनुमति या उच्चतर होना आवश्यक है।',
      login: 'लॉग इन करें',
      connectInstagramBot: 'इंस्टाग्राम बॉट कनेक्ट करें',
      connectBotWithYourInstagramAnd: 'अपने इंस्टाग्राम के साथ बॉट कनेक्ट करें और',
      exploreTruePowerOfInstagram: ' इंस्टाग्राम की सच्ची शक्ति का अन्वेषण करें',
      connected: 'जुड़े हुए',
      connect: 'जोड़ना',
      createNewPage: 'नया पेज बनाएं',
      refreshPageList: 'पृष्ठ सूची को ताज़ा करें',
      thisPageIsSuccessfullyintegratedWith: 'यह पृष्ठ सफलतापूर्वक एकीकृत है',
      yourMessagesFromMessengerWillAppearIn: '। मैसेंजर से आपके संदेश दिखाई देंगे',
      inbox: ' इनबॉक्स',
      continueToBotConfiguration: 'बॉट कॉन्फ़िगरेशन को जारी रखें',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} में आपका स्वागत है। हम यहां आपकी सहायता के लिए उपलब्ध हैं।`,
      letsTryYourBotAndSeeHowItWorks: 'चलो कोशिश करते हैं और देखें कि यह बॉट कैसे काम करता है! "इंस्टाग्राम पर टेस्ट" पर क्लिक करें।',
      howAreYouDoingToday: 'आज आप कैसे हैं?',
      thisIsHowSimpleToUseInstagramBot: 'इतना ही! यह इंस्टाग्राम बॉट का उपयोग करना कितना सरल है',
      letsCreateYourOwnChatFlowNow: 'आइए अब अपना खुद का चैट फ्लो बनाएं',
    },
    telegramBot: {
      back: 'पीछे',
      step: 'चरण 3 में से ',
      of3: ' ',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      help: 'मदद',
      loginToYourTelegramAccount: 'अपने टेलीग्राम खाते में लॉगिन करें',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'नया बॉट बनाने या मौजूदा बॉट को कनेक्ट करने के लिए अपने खाते में लॉगिन करें',
      verificationCodeSentOnRegisteredNumber: 'पंजीकृत संख्या पर भेजा गया सत्यापन कोड',
      createYourBot: 'अपना बॉट बनाएं',
      enterYourBotsNameAnd: 'अपने बॉट का नाम दर्ज करें, कनेक्ट करें और मज़ा शुरू करें',
      botUserNameMustEndWithBotAtTheEnd: 'बॉट उपयोगकर्ता नाम अंत में बॉट के साथ समाप्त होना चाहिए।',
      terrisBot: 'उदा। teris_bot या terisbot',
      botNameAlreadyTaken: 'यह बॉट नाम पहले से ही लिया गया है। कृपया कुछ अलग करने की कोशिश करें',
      connect: 'जोड़ना',
      createBot: 'बॉट बनाएँ',
      codeNotReceivedResend: 'कोड प्राप्त नहीं हुआ? फिर से बसाना!',
      changeNumber: 'अंक बदलो',
      telegramPreview: 'टेलीग्राम पूर्वावलोकन',
      worldsFastestMessagingApp: 'दुनिया का सबसे तेज़ मैसेजिंग ऐप और इसके मुफ़्त!',
      bot: 'बीओटी',
      phoneNumber: 'फ़ोन नंबर',
      userName: 'उपयोगकर्ता नाम',
      none: 'कोई नहीं',
      bio: 'जैव',
      fewWordsAboutYourself: 'अपने बारे में कुछ शब्द',
      settings: 'समायोजन',
      notificationsAndSound: 'सूचनाएं और ध्वनियाँ',
      privacyAndSecurity: 'गोपनीयता और सुरक्षा',
      dataAndStorage: 'आंकड़ा और भंडारण',
      chatSettings: 'चैट सेटिंग्स',
      chatFolders: 'चैट फ़ोल्डर',
      devices: 'उपकरण',
      connectYourBot: 'अपनी बॉट कनेक्ट करें',
      createNewBotOrConnectWithExistingOne: 'नया बॉट बनाएं या मौजूदा को कनेक्ट करें',
      createNewBot: 'नया बॉट बनाएं',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} में आपका स्वागत है। हम यहां आपकी सहायता के लिए उपलब्ध हैं।`,
      letsTryYourBotAndSeeHowItWorks: 'चलो कोशिश करते हैं और देखें कि यह बॉट कैसे काम करता है! "टेलीग्राम पर टेस्ट" पर क्लिक करें।',
      howAreYouDoingToday: 'आज आप कैसे हैं?',
      thisIsHowSimpleToUseTelegramBot: 'इतना ही! यह टेलीग्राम बॉट का उपयोग करना कितना सरल है',
      letsCreateYourOwnChatFlowNow: 'आइए अब अपना खुद का चैट फ्लो बनाएं',
      placeholders: {
        searchCountry: 'देश खोजें',
        enterTelegramMobileNumber: 'टेलीग्राम मोबाइल नंबर दर्ज करें',
        enterLoginCodeReceived: 'टेलीग्राम खाते पर प्राप्त लॉगिन कोड दर्ज करें *',
        twoFactorAuthentication: '2FA पासवर्ड (वैकल्पिक)',
        botName: 'बॉट नाम *',
        botUserName: 'बॉट उपयोगकर्ता नाम *'
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'नया बॉट बनाएं',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      help: 'मदद',
      back: 'पीछे',
      step: 'चरण 2 में से ',
      of2: ' ',
      buildA: 'निर्माण करें',
      thatMatchesYourBrandPersonality: ' जो आपके ब्रांड के व्यक्तित्व से मेल खाता है',
      chatBotIcon: 'चैटबोट आइकन',
      optional: ' वैकल्पिक',
      themeColor: 'थीम रंग',
      chatBotName: 'चैटबॉट नाम',
      botNameIsRequired: 'बॉट नाम की आवश्यकता है',
      botNameShouldBeLessThan50Characters: 'बॉट नाम 50 से कम अक्षर होना चाहिए',
      continue: 'जारी रखना',
      whatIsYourEmailAddress: 'अापका ईमेल पता क्या है?',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} में आपका स्वागत है। हम यहां आपकी सहायता के लिए उपलब्ध हैं।`,
      invalidEmailAddress: 'अमान्य ईमेल पता',
      customizeYourLandingPageBotLook: 'अपने लैंडिंग पृष्ठ बॉट लुक को अनुकूलित करें',
      customizeYourWidgetLook: 'अपने विजेट लुक को अनुकूलित करें',
      customizeYourBotLook: 'अपने बॉट लुक को अनुकूलित करें',
      buildYourLandingPageBotAndAutomateInteraction: 'अपने लैंडिंग पेज बॉट का निर्माण करें और अपने लिंक को साझा करके अपने ग्राहकों के साथ बातचीत को स्वचालित करें',
      useLiveChatBotToConnectToCustomers: 'वास्तविक समय में अपने ग्राहकों से जुड़ने के लिए लाइव चैट बॉट का उपयोग करें',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'अपनी वेबसाइट पर चैटबॉट कनेक्ट करें और आगंतुकों के साथ बातचीत को स्वचालित करें',
      widget: 'विजेट',
      botInterface: ' बॉट इंटरफ़ेस',
      mergedBot: 'वेबसाइट चैटबॉट, लैंडिंग पेज बॉट, मोबाइल ऐप चैटबॉट',
      mergedBotToolTip: 'आगंतुकों का ध्यान खींचने और उन्हें बातचीत में शामिल करने के लिए एक बॉट पेज बनाएं।',
      placeholders: {
        typeANameForYourBot: 'अपने नए बॉट के लिए एक नाम टाइप करें'
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'आप बॉट नाम दर्ज करें',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'अपनी चैट विंडो के लिए एक थीम रंग का चयन करें',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `इस लोगो का उपयोग केवल ${brand ? brand : ''} प्लेटफॉर्म में किया जाएगा। हम आपकी कंपनी का लोगो चुनने का सुझाव देते हैं। 1 एमबी के तहत एक पीएनजी या जेपीजी चुकता छवि जोड़ें।`,
      }
    },
    whatsappBot: {
      back: 'पीछे',
      justAStepAway: 'बस एक कदम दूर',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      help: 'मदद',
      createWhatsappChatBot: 'व्हाट्सएप चैटबॉट बनाएं',
      businessNameIsRequired: 'व्यवसाय का नाम आवश्यक है।',
      maximumLengthForBusinessName40Characters: 'व्यवसाय के नाम के लिए अधिकतम लंबाई 40 वर्ण हैं।',
      businessCategory: 'व्यापार वर्ग',
      categoryIsRequired: 'श्रेणी की आवश्यकता है।',
      descriptionIsRequired: 'विवरण आवश्यक है।',
      maximumLengthForDescription200Characters: 'विवरण के लिए अधिकतम लंबाई 200 वर्ण हैं।',
      pleaseFillAValidEmailAddress: 'कृपया एक मान्य व्यवसाय ईमेल भरें।',
      businessEmailIsRequired: 'व्यावसायिक ईमेल की आवश्यकता है।',
      phoneNumberShouldHaveMinimum7Digits: 'फोन नंबर में न्यूनतम 7 अंक होने चाहिए',
      phoneNumberIsRequired: 'फोन नंबर की आवश्यकता है',
      websiteIsRequired: 'वेबसाइट की आवश्यकता है।',
      maximumLengthForWebsite40Characters: 'वेबसाइट के लिए अधिकतम लंबाई 40 वर्ण हैं।',
      maximumLengthForAddress150Characters: ' पते के लिए अधिकतम लंबाई 150 वर्ण हैं।',
      doYouHaveWhatsappApiKey: 'क्या आपके पास व्हाट्सएप एपीआई कुंजी है?',
      apiProvider: 'एपीआई प्रदाता',
      three60Dialog: '360 संवाद',
      whatsappCloud: 'व्हाट्सएप क्लाउड',
      netcore: 'नेटकोर',
      apiKeyIsRequired: 'एपीआई कुंजी की आवश्यकता है।',
      whatsappBusinessAccountIdIsRequired: 'व्हाट्सएप Businness अकाउंट आईडी की आवश्यकता है।',
      whatsappPhoneNumberIdIsRequired: ' व्हाट्सएप फोन नंबर आईडी की आवश्यकता है।',
      systemAccessTokenIsRequired: 'सिस्टम एक्सेस टोकन की आवश्यकता है।',
      sourceIdIsRequired: 'स्रोत आईडी की आवश्यकता है।',
      businessName: 'व्यवसाय का नाम',
      businessAccount: 'व्यवसायिक खाता',
      businessDescription: 'व्यापार विवरण',
      address: 'पता',
      businessEmail: 'बिजनेस ईमेल',
      websiteUrl: 'वेबसाइट यूआरएल',
      thankYouForYourInterestIn: 'में आपकी रुचि के लिए धन्यवाद',
      whatsappChatBot: ' व्हाट्सएप चैटबॉट।',
      weHaveReceivedYourInterests: 'हमें आपकी रुचि मिली है, और हमारी बिक्री टीम अगले 24 घंटों में आपके संपर्क में रहेगी।',
      theyWillProvideYouWhatsappApiKey: 'वे आपको व्हाट्सएप एपीआई कुंजी प्रदान करेंगे।',
      readyToCreateWhatsappBot: 'मैं व्हाट्सएप बॉट बनाने के लिए तैयार हूं',
      exploreWhatsappFeatures: 'व्हाट्सएप सुविधाओं का अन्वेषण करें',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} में आपका स्वागत है। हम यहां आपकी सहायता के लिए उपलब्ध हैं।`,
      letsTryYourBotAndSeeHowItWorks: 'चलो कोशिश करते हैं और देखें कि यह बॉट कैसे काम करता है! "व्हाट्सप्प पर टेस्ट" पर क्लिक करें।',
      howAreYouDoingToday: 'आज आप कैसे हैं?',
      thisIsHowSimpleToUseWhatsappBot: 'इतना ही! यह व्हाट्सप्प बॉट का उपयोग करना कितना सरल है',
      letsCreateYourOwnChatFlowNow: 'आइए अब अपना खुद का चैट फ्लो बनाएं',
      proceed: 'आगे बढ़ें',
      connectWhatsAppBot: 'WhatsApp बॉट कनेक्ट करें',
      createtWhatsAppBot: 'WhatsApp बॉट बनाएं',
      whatsApiProvider: 'WhatsApp एपीआई प्रदाता',
      cloudApi: 'क्लाउड एपीआई',
      netcoreApi: 'नेटकोर एपीआई',
      placeholders: {
        yourBusinessName: 'आपका व्यवसाय नाम',
        businessDescription: 'व्यापार विवरण',
        businessEmail: 'बिजनेस ईमेल',
        phone: 'फ़ोन*',
        website: 'वेबसाइट (जैसे www.example.com)',
        address: 'पता',
        enterApiKey: 'API कुंजी दर्ज करें',
        enterWhatsappBusinessAccountId: 'व्हाट्सएप Businness अकाउंट आईडी दर्ज करें',
        enterWhatsappPhoneNumberId: 'व्हाट्सएप फोन नंबर आईडी दर्ज करें',
        enterSystemAccessToken: 'सिस्टम एक्सेस टोकन दर्ज करें',
        enterSourceId: 'स्रोत आईडी दर्ज करें',
        businessName: 'व्यापार का नाम',
        businessWebsite: 'व्यापार वेबसाइट',
        selectWhatsappApiProvider: 'व्हाट्सएप एपीआई प्रदाता का चयन करें',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'अपने व्हाट्सएप एपीआई प्रदाता का चयन करें',
        doYouHaveWhatsAppBusinessAPI: 'क्या आपके पास व्हाट्सएप व्यापार एपीआई है?',
        yes: 'हाँ',
        noIAmNotSure: 'नहीं, मुझे यकीन नहीं है',
        selectApiProvider: 'एपीआई प्रदाता का चयन करें',
        whatsAppCloudAPI: 'व्हाट्सएप क्लाउड एपीआई',
        enterYourPhoneNumberId: 'अपना फ़ोन नंबर आईडी दर्ज करें',
        phoneNumberId: 'फ़ोन नंबर आईडी',
        enterYourWhatsAppBusinessAccountId: 'अपना व्हाट्सएप व्यावसायिक खाता आईडी दर्ज करें',
        whatsAppBusinessAccountId: 'व्हाट्सएप व्यावसायिक खाता आईडी',
        enterYourSystemUserToken: 'अपना सिस्टम उपयोगकर्ता टोकन दर्ज करें',
        systemUserToken: 'सिस्टम उपयोगकर्ता टोकन',
        help: 'सहायता',
        netcoreAPI: 'नेटकोर एपीआई',
        enterYourSourceId: 'अपना स्रोत आईडी दर्ज करें',
        sourceId: 'स्रोत आईडी',
        enterYourNetcoreApiKey: 'अपना नेटकोर एपीआई की चाबी दर्ज करें',
        netcoreApiKey: 'नेटकोर एपीआई की चाबी',
        submit: 'प्रस्तुत करें',
        noneOftheAbove: 'इनमें से कोई भी नहीं',
        next: 'आगे'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'कानूनी रूप से पंजीकृत व्यवसाय',
        validWebsiteWithDataPrivacyPolicy: 'डेटा गोपनीयता नीति के साथ वैध वेबसाइट',
        gstin: 'जीएसटीआईएन',
        debit: 'डेबिट',
        creditCard: 'क्रेडिट कार्ड',
        onlyRequiredForIndianResidents: 'केवल भारतीय निवासियों के लिए आवश्यक',
        onlyRequiredFor: 'केवल भारतीय निवासियों के लिए आवश्यक है',
        indianResidents: 'भारतीय निवासी',
        validPaymentMethod: 'वैध भुगतान विधि',
        enabledWithInternationalPayments: 'अंतरराष्ट्रीय भुगतान के साथ सक्रिय किया गया',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'वॉट्सऐप वाणिज्य नीति के अनुसार वैध उपयोग मामूली का मामूली मामूला',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'ऐक्टिव WhatsApp नहीं होने वाला और एसएमएस प्राप्त करने के क्षमता वाला फोन नंबर',
        discard: 'छोड़ें',
        proceed: 'जारी रखें',
        whatsAppBotPrerequisites: 'व्हाट्सएप बॉट पूर्वापेक्षाएँ',
        letUsGetItDoneForYou: 'हम आपके लिए यह कर दें!',
        ifYouAreFacingSomeIssues: 'यदि आपको कुछ समस्याएं हो रही हैं',
        ourTeamCanHelpYouDoTheSame: 'हमारी टीम आपको इसी के रूप में मदद कर सकती है',
        requestACallbackNow: 'अभी कॉलबैक अनुरोध करें!',
      },
      stepThree: {
        setupWhatsAppBot: 'व्हाट्सएप बॉट सेटअप करें',
        selectingTheBestBusinessManagerForYourNeeds: 'अपनी आवश्यकताओं के लिए सर्वश्रेष्ठ व्यापार प्रबंधक चुनें',
        createMetaApp: 'मेटा ऐप्लिकेशन कैसे बनाएं',
        createaMetaAppOn: 'पर मेटा ऐप बनाएं',
        howToCreate: 'कैसे बनाएं',
        addWhatsAppNumber: 'व्हाट्सएप नंबर कैसे जोड़ें',
        addYourWhatsAppNumberAndVerifyTheSame: 'अपना व्हाट्सएप नंबर जोड़ें और सत्यापित करें',
        subscribe: 'सदस्यता लें',
        copyAndSubscribeWebhook: 'Webhook कॉपी करें और सदस्यता लें',
        howToSubscribe: 'सदस्यता लेने का तरीका',
        copy: 'कॉपी करें',
        appSecretId: 'ऐप सीक्रेट आईडी',
        enterYourAppSecretId: 'अपना ऐप सीक्रेट आईडी डालें',
        enterAppSecret: 'ऐप सीक्रेट डालें',
        phoneNumberId: 'फोन नंबर आईडी',
        whatsAppBusinessId: 'व्हाट्सएप व्यापार आईडी',
        assignSystemUser: 'सिस्टम उपयोगकर्ता को कैसे सौंपें',
        assign: 'सौंपें',
        createApp: 'मेटा ऐप बनाएं',
        registerAndVerify: 'रजिस्टर और सत्यापित करें',
        add: 'जोड़ें',
        systemUserToken: 'सिस्टम उपयोगकर्ता टोकन',
        enterYourSystemUserToken: 'अपने सिस्टम उपयोगकर्ता टोकन डालें',
        getAllNecessaryDetails: 'सभी आवश्यक विवरण कैसे प्राप्त करें',
        subscribeWhatsAppWebhook: 'WhatsApp वेबहुक कैसे सब्सक्राइब करें',
        configureTheWebhookForWhatsAppAccount: 'WhatsApp खाते के लिए वेबहुक कॉन्फ़िगर करें',
        upadatePaymentMethod: 'भुगतान विधि कैसे अपडेट करें',
        assignThePaymentMethodToWhatsAppAccount: 'किसी भी WhatsApp खाते को भुगतान विधि सौंपें',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'WhatsApp व्यावसायिक खाते का नियंत्रण सिस्टम उपयोगकर्ता को दें',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'मेटा डैशबोर्ड से कॉपी करने वाले मुख्य विवरण',
        updatePayment: 'भुगतान अपडेट करें',
        letUsGetItDoneForYou: 'हम आपके लिए यह कर दें!',
        ifYouAreFacingSomeIssues: 'यदि आपको कुछ समस्याएं हो रही हैं',
        ourTeamCanHelpYouDoTheSame: 'हमारी टीम आपको इसी के रूप में मदद कर सकती है',
        requestACallbackNow: 'अभी कॉलबैक अनुरोध करें!',
        update: 'अपडेट करें',
        connectNow: 'अब जुड़ें'
      },
      stepFive: {
        selectBusinessManager: 'व्यापार प्रबंधक का चयन करें',
        selectingTheBestBusinessManagerForYourNeeds: 'आपकी आवश्यकताओं के लिए सर्वश्रेष्ठ व्यापार प्रबंधक का चयन करें'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'आपका बॉट स्थापित और सक्रिय है',
        youhaveSuccessfullyConnected: 'आपने सफलतापूर्वक कनेक्ट किया है',
        withWhatsApp: 'व्हाट्सएप के साथ',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'आपको डिफ़ॉल्ट रूप से प्रति माह 100 वार्तालाप आवंटित किए गए हैं',
        createChatFlow: 'चैट फ्लो बनाएँ',
        upgradePlan: 'प्लान अपग्रेड करें'

      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'QR स्कैन करें या बॉट को सक्रिय करने के लिए संदेश भेजें',
        scanTheQrCodeFrom: 'क्यूआर कोड स्कैन करें',
        yourPhoneWillOpen: 'आपका फोन हमारे सैंडबॉक्स नंबर पर भेजने के लिए पूर्व-भरा हुआ संदेश खोलेगा। WhatsApp पर "भेजें" करें',
        sendMessageFromYourWhatsAppNumber: 'अपने WhatsApp नंबर से संदेश भेजें',
        sendUsAWhatsAppMessageOn: 'हमें एक WhatsApp संदेश भेजें ',
        bySavingInYourContactsAndMessageShouldStartWith: ' को अपने संपर्क में सहेजकर और संदेश इसके साथ शुरू होना चाहिए ',
        send: 'भेजें',
        or: 'या'
      }

    }
  },
  gettingStarted: {
    hello: 'नमस्ते,',
    upgrade: 'उन्नत करना',
    gettingStarted: 'शुरू करना -',
    yourFacebookBotToken: 'आपका फेसबुक बॉट टोकन',
    hasBeenExpired: 'समाप्त हो चुकी है',
    isExpiringIn: 'में समाप्त हो रहा है',
    days: ' दिन',
    facebookAuthenticationTokenRemainsFor60Days: 'फेसबुक प्रमाणीकरण टोकन केवल 60 दिनों के लिए सक्रिय रहता है',
    kindlyProceedWithTheRenewalOfYourBotToken: 'चैटबॉट सेवाओं को जारी रखने के लिए कृपया अपने बॉट टोकन की नवीनीकरण करें।',
    renewYourBotToken: 'अपने बॉट टोकन को नवीनीकृत करें',
    editYourChatFlow: 'अपने चैट प्रवाह को संपादित करें',
    buildConversationEngagingBot: 'अपने बॉट उपयोगकर्ताओं के लिए आकर्षक वार्तालाप प्रवाह का निर्माण करें।',
    takes5minutes: '5 मिनट लेता है',
    previewYourLiveChatWidget: 'अपने लाइव चैट विजेट का पूर्वावलोकन करें',
    discoverHowThe: 'कैसे खोजें',
    liveChatFeatureWorks: ' लाइव चैट फीचर हमारे लाइव चैट प्लेग्राउंड का उपयोग करके काम करता है',
    takes2minutes: '2 मिनट लेता है',
    takes1minute: '1 मिनट लेता है',
    connected: 'जुड़े हुए',
    customizeYourBotApperance: 'अपने बॉट की उपस्थिति और कार्यक्षमता को अनुकूलित करें।',
    takes3minutes: '3 मिनट लेता है',
    thirdPartyAppsIntegration: 'तृतीय-पक्ष एप्लिकेशन एकीकरण',
    integrateYourBot: 'व्यवसाय स्वचालन बनाने के लिए अन्य ऐप्स/सीआरएम के साथ अपने बॉट को एकीकृत करें।',
    upgradeYourPlanToReceiveLeadsInEmail: 'अपने ईमेल पर लीड प्राप्त करने के लिए अपनी योजना को अपग्रेड करें',
    getStartedWithUs: 'हमारे साथ शुरुआत करें',
    howToBuildAGoodChatFlow: 'कैसे एक अच्छा चैटफ़्लो बनाने के लिए',
    howToUseBotPenguinsInboxAndItsFeatures: 'Botpenguin के इनबॉक्स और इसकी विशेषताओं का उपयोग कैसे करें',
    botPenguinsPricing: 'Botpenguin की कीमत: योजनाओं और सुविधाओं को जाने',
    openHelpDesk: 'हेल्प डेस्क खोले',
    helpAndSupport: 'सहायता और समर्थन',
    extensiveGuides: 'आपके सभी प्रश्नों और वर्कफ़्लो के लिए व्यापक गाइड हमारे पास उपलब्ध हैं',
    resourceCentre: 'संसाधन केन्द्र',
    checkoutOurAnswers: 'आप नीचे दिए गए सामान्य प्रश्नों के लिए हमारे उत्तर देख सकते हैं।',
    createANewBot: 'एक नया बॉट बनाएं',
    installYourBot: 'अपना बॉट स्थापित करें',
    plansAndPricing: 'योजना और मूल्य निर्धारण',
    previewYourLandingPageBot: ' अपने लैंडिंग पेज बॉट का पूर्वावलोकन करें',
    installYourLiveChatWidget: 'अपना लाइव चैट विजेट स्थापित करें',
    installYourWebsiteBot: 'वेबसाइट, मोबाइल ऐप पर अपने चैटबॉट को इंस्टॉल करें, या लैंडिंग पेज के रूप में लॉन्च करें या एम्बेडेड चैटबॉट को शुरू करें',
    integrateBotToLandingPage: 'बॉट को अपने लैंडिंग पेज बॉट पर एकीकृत करें',
    installWidgetToWebsite: 'अपनी वेबसाइट पर विजेट स्थापित करें',
    installBotToWebsite: 'अपने चैटबॉट को स्थापित करें',
    whatsappAutomation: 'व्हाट्सएप ऑटोमेशन',
    facebookAutomation: 'फेसबुक ऑटोमेशन',
    telegramBotSettings: 'टेलीग्राम बॉट सेटिंग्स',
    liveChatWidgetSettings: 'लाइव चैट विजेट सेटिंग्स',
    landingPageBotSettings: 'लैंडिंग पेज बॉट सेटिंग्स',
    websiteChatBotSettings: 'वेबसाइट चैटबॉट सेटिंग्स',
    instagramAutomation: 'इंस्टाग्राम ऑटोमेशन',
    diveDeeperIntoBotPenguin: 'बॉटपेंग्विन को गहराई से जाने',
    knowWhereWeAreGoing: 'पता है कि हम कहाँ जा रहे हैं, हाल के अपडेट, और हमें कुछ कीड़े को ठीक करने में मदद करते हैं।',
    productUpdates: 'उत्पाद अद्यतन',
    requestAFeature: 'एक सुविधा का अनुरोध करें',
    reportABug: 'एक बग रिपोर्ट करो',
    prodcutRoadmap: 'उत्पाद सड़क',
    trainYourChatbot: 'अपने एआई चैट बॉट को प्रशिक्षित करें (ज्ञान आधार)',
    buildEngagingConversation: 'अपने बॉट उपयोगकर्ताओं के लिए आकर्षक वार्तालाप प्रवाह बनाएँ।',
    previewYourAIBot: 'अपने AI बॉट का पूर्वावलोकन करें',
    integrateAIbot: 'बॉट को अपने AI बॉट से एकीकृत करें',
    aiBotSettings: 'एआई बॉट सेटिंग्स',
    CustomizeAIBot: 'अपने बॉट की उपस्थिति और कार्यक्षमता को अनुकूलित करें।',
    gptKeyWarning: 'हमारी चैटजीपीटी एपीआई सेवा में आपका स्वागत है! कृपया ध्यान दें कि प्रत्येक बॉट के लिए 100 संदेशों की सीमा है। 100 संदेश सीमा तक पहुंचने के बाद, आपको हमारी सेवा का आनंद लेना जारी रखने के लिए अपनी स्वयं की चैटजीपीटी एपीआई कुंजी का उपयोग करना होगा। चैटजीपीटी एपीआई कुंजी दर्ज करने के लिए, चैटजीपीटी मॉडल कॉन्फ़िगरेशन पर जाएं।',
    whatsappBotSettings: 'व्हाट्सएप बॉट सेटिंग्स',
    automateCustomerEngagementWithWhatsApp: 'व्हाट्सएप के साथ ग्राहक बुलावा स्वत:क्रिय।',
    connectToYourWhatsappNumber: 'अपने व्हाट्सएप नंबर से कनेक्ट करें',
    connectYourWhatsappBotWithYourWhatsappNumber: 'अपने व्हाट्सएप बॉट को अपने व्हाट्सएप नंबर से कनेक्ट करें',
    facebookBotSettings: 'फेसबुक बॉट सेटिंग्स',
    automateCustomerEngagementWithTelegram: 'टेलीग्राम के साथ ग्राहक व्यापार को स्वचालित करें',
    automateCustomerEngagementWithFacebook: 'फेसबुक के साथ ग्राहक व्यापार को स्वचालित करें',
    automateCustomerEngagementWithInstagram: 'इंस्टाग्राम के साथ ग्राहक व्यापार को स्वचालित करें',
    telegramAutomation: 'टेलीग्राम ऑटोमेशन',

  },
  install: {
    previewYourLandingPageBot: 'अपने लैंडिंग पेज बॉट का पूर्वावलोकन करें',
    installYourChatBot: 'अपना चैट बॉट स्थापित करें',
    preview: 'पूर्व दर्शन',
    test: 'परीक्षण',
    squareSpace: 'स्क्वायरस्पेस',
    seeHowToUse: 'देखें कि कैसे उपयोग करें',
    help: 'मदद',
    uniqueLinkToLandingPageBot: 'यहाँ आपके लैंडिंग पेज बॉट का अनूठा लिंक है।',
    shareOnSocialMedia: 'बस इसे अपने उपयोगकर्ताओं के साथ सोशल मीडिया, संपर्क पृष्ठों या व्यवसाय कार्ड पर साझा करें।',
    or: 'या',
    copyUrl: 'यूआरएल कॉपी करें',
    selectYourWebsiteCms: 'अपनी वेबसाइट CMS का चयन करें',
    selectCms: 'अपनी वेबसाइट बनाने के लिए आपके द्वारा उपयोग किए गए CMS/बिल्डर का चयन करें',
    custom: 'रिवाज़',
    wordpress: 'वर्डप्रेस',
    shopify: 'शॉपिफाई',
    wix: 'विक्स',
    zoomla: 'ज़ूमला',
    otherCms: 'अन्य सीएमएस',
    howWouldYouLikeToInstall: 'आप इसे कैसे स्थापित करना चाहेंगे?',
    installingWebsiteBot: 'चुनें कि आप अपनी वेबसाइट पर बॉट स्थापित करेंगे',
    iWillAddMyself: 'मैं खुद को जोड़ूंगा',
    getChatBotScript: 'नीचे चैटबॉट स्क्रिप्ट कोड प्राप्त करें और इसे अपने आप से स्थापित करें',
    getWordpressPlugin: 'वर्डप्रेस प्लगइन एपीआई कुंजी प्राप्त करें',
    getOurOfficialPlugin: 'हमारे आधिकारिक प्लगइन प्राप्त करें',
    fromWordpressPluginStore: ' वर्डप्रेस प्लगइन स्टोर से',
    getPlugin: 'प्लगइन प्राप्त करें',
    quickGuideToInstall: 'स्थापित करने के लिए त्वरित गाइड',
    placeApiKeyBelowIn: 'नीचे एपीआई कुंजी पेस्ट करें',
    afterDownloadingAndActivatingPlugin: ' प्लगइन को डाउनलोड करने और सक्रिय करने के बाद',
    copy: 'प्रतिलिपि',
    enterYourWebsite: 'अपनी वेबसाइट दर्ज करें और आइए देखें कि आपके चैटबॉट्स कैसे दिखते हैं',
    enterAValidUrl: 'एक वैध URL दर्ज करें',
    verifyYourInstallation: 'सत्यापित करें कि आपकी स्थापना सफल है या नहीं?',
    testInstallation: 'परीक्षण स्थापना',
    getChatBotScriptCode: 'चैटबॉट स्क्रिप्ट कोड प्राप्त करें',
    copyThisCodeSnippet: 'इस कोड स्निपेट को कॉपी करें और इसे प्रत्येक वेब पेज के HTML में डालें, जहां आप प्रत्येक पृष्ठ पर दिखाई देने से पहले टैग बंद होने से पहले चैटबॉट या पैस्ट को पाद को प्रदर्शित करना चाहते हैं।',
    sendCodeToMyDeveloper: 'मेरे डेवलपर को कोड भेजें',
    sendCodeToMyDevelopmentTeam: 'मेरी विकास टीम को कोड भेजें',
    sendEmail: 'ईमेल भेजें',
    enterAValidEmail: 'एक वैध ईमेल प्रविष्ट करें',
    codeHasBeen: 'कोड रहा है',
    toYourDeveloper: ' अपने डेवलपर को',
    resent: 'क्रोध करना',
    sent: 'भेजा',
    resendEmail: 'ईमेल दुबारा भेजें',
    ask: 'पूछना',
    supportTeamToInstall: ' स्थापित करने के लिए टीम का समर्थन करें',
    raiseTheInstallationRequestTo: 'के लिए स्थापना अनुरोध बढ़ाएं',
    team: ' टीम',
    helpMeInstallingChatBot: 'चैटबॉट स्थापित करने में मेरी मदद करें',
    yourBotInstallationIsNotActive: 'आपका बॉट इंस्टॉलेशन सक्रिय नहीं है!',
    monthlyMessageLimitExceeded: 'मासिक संदेश सीमा समाप्त हो गई',
    exceededMonthlyMessageLimitUpgradeNow: 'आपने अपनी मासिक संदेश सीमा को पार कर लिया है, अब अपग्रेड करें।',
    upgradeNow: 'अभी अपग्रेड करें',
    botInstallationNotActivePleaseFix: 'आपकी बॉट इंस्टॉलेशन सक्रिय नहीं है, कृपया उसी को ठीक करें।',
    incorrectBotScript: 'गलत बॉट स्क्रिप्ट',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'इंस्टॉलेशन कोड जो आप सत्यापित कर रहे हैं, वह आपकी साइट पर एक से मेल नहीं खाता है। \ n कृपया सुनिश्चित करें कि आपने इस बॉट के लिए सही कोड स्थापित किया है।',
    unableToSee: 'देखने में असमर्थ',
    codeOnYourWebsite: ' अपनी वेबसाइट पर कोड',
    unableToFindCodePleaseReinstall: 'हम आपकी वेबसाइट पर कोड खोजने में असमर्थ हैं, कृपया फिर से इंस्टॉल करें।',
    reinstallBot: 'बॉट को फिर से स्थापित करें',
    yourBotIsInstalledAndActive: 'आपका बॉट स्थापित और सक्रिय है!',
    noPendingAction: 'कोई लंबित कार्रवाई नहीं, आपका बॉट स्थापित और सक्रिय है',
    youAreAllSet: 'आप सब तैयार हैं!',
    showMeMyLeads: 'मुझे मेरे लीड दिखाओ',
    connectingToYourWebsiteToVerifyInstallation: 'बॉट इंस्टॉलेशन को सत्यापित करने के लिए अपनी वेबसाइट से कनेक्ट करना',
    thisMayTakeUpto30Seconds: 'इसमें तीस सेकंड का समय लग सकता है',
    hereIsTheuniqueAILink: 'यहां आपके AI बॉट का अनोखा लिंक है',
    installHeading: 'अपने चैटबॉट को इंस्टॉल करें',
    websiteChatbot: 'वेबसाइट चैटबॉट',
    aiChatBot: 'एआई चैटबॉट',
    landingPageBot: 'लैंडिंग पेज बॉट',
    mobileChatBot: 'मोबाइल ऐप चैटबॉट',
    embeddedBot: 'एम्बेडेड चैटबॉट',
    selectMobileDevice: 'अपने मोबाइल डिवाइस का चयन करें',
    mobileDeviceSubheading: 'चुनें उन्नत भाषा जिसका उपयोग आपने अपने मोबाइल ऐप बनाने में किया है',
    ios: 'IOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'अन्य',
    copyEmbeddedCodeSnippet: 'इस कोड स्निपेट को कॉपी करें और इसे प्रत्येक वेब पेज के HTML में डालें जहां आप चैटबॉट प्रदर्शित करना चाहते हैं।',
    copyMobileCodeSnippet: 'चैटबॉट दिखाना चाहते हैं तो इस कोड टुकड़े को अपने मोबाइल एप्लिकेशन कोड में कॉपी करें।',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'डेवलपर ईमेल दर्ज करें'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'अपने डेवलपर को निर्देश भेजें',
      notSureHowToDoIt: 'निश्चित नहीं है कि यह कैसे करें? आपके डेवलपर्स इसे एक जिफ में कर सकते हैं।',
      developersEmail: 'डेवलपर का ईमेल',
      emailIsRequired: 'ईमेल की जरूरत है',
      subject: 'विषय',
      message: 'संदेश',
      send: 'भेजना',
      placeholders: {
        enterYourWorkEmail: 'अपना कार्य ईमेल दर्ज करें',
        createBotUsingBotPenguin: 'उत्तेजित! बॉटपेंगुइन का उपयोग करके बॉट बनाएं'
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'यहाँ आपके लैंडिंग पेज बॉट का अनूठा लिंक है।',
      shareWithUsersOnSocialMedia: 'बस इसे अपने उपयोगकर्ताओं के साथ सोशल मीडिया, संपर्क पृष्ठों या व्यवसाय कार्ड पर साझा करें।',
      previewMyLandingPageBot: 'मेरे लैंडिंग पेज बॉट का पूर्वावलोकन करें',
      or: 'या',
      copyUrl: 'यूआरएल कॉपी करें',
      howYouLikeToAddBot: 'चुनें कि आप अपनी वेबसाइट पर अपना बॉट कोड कैसे जोड़ना चाहते हैं',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'एक अद्वितीय कोड को कॉपी करके अपने कस्टम कोडित या वर्डप्रेस वेबसाइट पर बॉट स्थापित करें।',
      name: 'नाम',
      actions: 'कार्रवाई',
      javascript: 'जावास्क्रिप्ट',
      install: 'स्थापित करना',
      onYourCustomCodedWebsite: ' अपनी कस्टम कोडित वेबसाइट पर',
      copyAndPaste: 'कॉपी और पेस्ट',
      copyThisCodeSnippet: 'इस कोड स्निपेट को कॉपी करें और इसे प्रत्येक वेब पेज के HTML में डालें जहां आप विजेट प्रदर्शित करना चाहते हैं। अधिक जानकारी के लिए',
      seeGuide: 'गाइड देखें',
      getCode: 'कोड प्राप्त करें',
      howToInstall: 'स्थापित करने के लिए कैसे?',
      copyCode: 'प्रतिलिपि कोड',
      wordpress: 'वर्डप्रेस',
      onYourWordPressWebiste: ' आपकी वर्डप्रेस वेबसाइट पर',
      preview: 'पूर्व दर्शन',
      test: 'परीक्षण',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'लाइव चैट पूर्वावलोकन',
    agentWindow: 'एजेंट विंडो',
    person: 'व्यक्ति',
    inOrderToTestTypeHere: 'यहां का परीक्षण करने के लिए यहां',
    agentWindowCannotBeCustomized: 'नोट: एजेंट विंडो को अनुकूलित नहीं किया जा सकता है',
    customerWindow: 'ग्राहक खिड़की',
    clickHereToChat: 'चैट करने के लिए यहाँ क्लिक करें',
    customerWillChatHere: 'ग्राहक यहां चैट करेंगे',
    chat: 'बात करना',
    by: 'द्वारा',
    changeYourBotLookFromLeftPanel: 'बाएं पैनल से अपना बॉट लुक बदलें',
    placeholders: {
      typeYourMessage: 'अपना संदेश टाइप करें...',
      typeYourAnswer: 'अपना उत्तर लिखें'
    }
  },
  previews: {
    previewYourLiveChatWidget: 'अपने लाइव चैट विजेट का पूर्वावलोकन करें',
    install: 'स्थापित करना',
    seeHowToUse: 'देखें कि कैसे उपयोग करें',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'चयनित प्लेटफ़ॉर्म पर अपना बॉट इंस्टॉल करने के लिए क्लिक करें'
    }
  },
  trainYourBot: {
    trainYourBot: 'अपने चैटबॉट को प्रशिक्षित करें',
    knowledgeBase: 'ज्ञान आधार',
    faqs: 'सामान्य प्रश्न',
    history: 'इतिहास',
    faqHeading: 'अक्सर पूछे जाने वाले प्रश्न',
    faqSubHeading: 'आपके चैटबॉट प्रशिक्षण से संबंधित सवालों के जवाब',
    knowledgeBaseHeading: 'अपने चैटबॉट को प्रशिक्षित करें',
    knowledgeBaseSubheading: 'बातचीत को बेहतर बनाने के लिए अपने चैटबॉट को प्रशिक्षित करें',
    websiteLink: 'वेबसाइट लिंक',
    uploadFile: 'अपनी फ़ाइल अपलोड करें',
    selectFile: 'फ़ाइल का चयन करें',
    submit: 'सबमिट करें',
    searchHere: 'यहाँ खोजें',
    question: 'सवाल',
    answer: 'उत्तर',
    questionPlaceholder: 'अपना सवाल यहाँ लिखें',
    answerPlaceholder: 'अपना उत्तर यहाँ लिखें',
    save: 'सहेजें',
    discard: 'छोड़ें',
    addFaq: 'एफएक्यूएस जोड़ें',
    createdAt: 'बनाया गया',
    updatedAt: 'अंतिम अपडेट',
    actions: 'कार्रवाई',
    noFaqFound: 'आपके पास अभी तक कोई एफएक्यूएस नहीं बनाई गई है',
    noFaqFoundMessage: 'आपके सभी एफएक्यूएस यहाँ प्रदर्शित होंगे',
    historyTitle: 'ज्ञान आधार इतिहास',
    historySubtitle: 'चैटबॉट प्रशिक्षण सवालों के जवाब',
    fileName: 'फ़ाइल का नाम',
    status: 'स्थिति',
    noHistoryTitle: 'आपके पास अभी तक कोई प्रशिक्षित मॉडल नहीं है',
    noHistoryDescription: 'आपके सभी प्रशिक्षित मॉडल यहाँ प्रदर्शित होंगे',
    fileUpload: 'फ़ाइल अपलोड करें',
    files: 'फ़ाइलें',
    fileSubHeading: 'अपने बॉट को ट्रेन करने के लिए सभी फ़ाइलें जोड़ें।',
    noFileFoundHeading: 'आपने अभी तक कोई फ़ाइलें नहीं बनाई हैं',
    noFileFoundSubheading: 'आपकी सभी फ़ाइलें यहाँ प्रदर्शित होंगी',
    uploadYourFiles: 'अपनी फ़ाइलें अपलोड करें',
    upload: 'अपलोड करें',
    fileShouldBe: 'फ़ाइल PDF, DOC, DOCX, PPTX, TXT होनी चाहिए।',
    uploadStatus: 'अपलोड स्थिति',
    uploadStatusSubHeading: 'बॉट प्रशिक्षण के लिए फ़ाइल अपलोड की स्थिति',
    addFile: 'फ़ाइल जोड़ें',
    otherConfiguration: 'एआई सेटिंग्स',
    deleteFiles: 'फ़ाइलें हटाने के लिए क्लिक करें',
    websiteURL: 'वेबसाइट यूआरएल',
    websiteUrlSubheading: 'उन सभी वेबसाइट यूआरएल जिन पर आप अपने बॉट को ट्रेन करना चाहते हैं, जोड़ें।',
    enterWebsiteUrl: 'यूआरएल दर्ज करें',
    addWebsite: 'वेबसाइट जोड़ें',
    tableWebsiteUrl: 'वेबसाइट यूआरएल',
    trainingStatus: 'ट्रेनिंग स्थिति',
    lastTrained: 'अंतिम ट्रेनिंग',
    noWebsitesFound: 'आपके पास अभी तक कोई वेबसाइट नहीं बनाई गई है',
    noWebsitesFoundMessage: 'आपकी सभी वेबसाइटें यहां दिखाई देंगी',
    loadMore: 'और लोड करें',
    botBehaviour: 'बॉट व्यवहार कॉन्फ़िगर करें',
    botBehaviourSubHeading: 'बॉट के व्यक्तित्व, ग्राहक डेटा को रेडैक्ट करें, फ़्लो को हैंडल करें और ChatGPT मॉडल को कॉन्फ़िगर करें',
    botPersonality: 'बॉट के व्यक्तित्व को कॉन्फ़िगर करें',
    botPersonalitySubHeading: 'व्यक्तित्व के मामले में बॉट की प्रतिक्रिया कैसे होनी चाहिए, इसे प्रबंधित करें',
    selectAnswerLength: 'उत्तर लंबाई का चयन करें',
    answerLength: 'उत्तर लंबाई',
    answerAs: 'उत्तर के रूप में',
    tone: 'भाव',
    language: 'भाषा',
    answerFormatting: 'उत्तर स्वरूपण',
    customInstructions: 'कस्टम प्रॉम्प्ट',
    customerInstructionPlaceholder: 'शिष्ट और मित्रपूर्ण रहें। अशुद्ध भाषा का उपयोग न करें। इमोजी का उपयोग न करें।',
    redactCustomerData: 'ग्राहक डेटा को एकत्र करें',
    redactDataSubheading: 'आप जो ग्राहक जानकारी एकत्र करना चाहते हैं उसे कॉन्फ़िगर करें',
    collectName: 'नाम एकत्र करें',
    configureQuestion: 'प्रश्न कॉन्फ़िगर करें',
    maxCharacterLimit: 'प्रदान किए गए नाम में अधिकतम अक्षर सीमा',
    collectEmail: 'ईमेल एकत्र करें',
    excludeEmail: 'डोमेन के साथ ईमेल छोड़ें',
    collectPhone: 'फोन एकत्र करें',
    manageUnusualMessages: 'असामान्य चैट स्थितियों का प्रबंधन करें',
    unusualMessagesSubheading: 'बॉट के लिए जवाब नहीं दिया गया और अन्य दुर्लभ परिस्थितियों को हैंडल करें',
    startConversation: 'बातचीत शुरू करने का संदेश',
    configureMessage: 'संदेश जब बॉट उत्तर नहीं दे सकता',
    profanityMessage: 'अवैध भाषा का उपयोग संदेश के लिए',
    chatGptModel: 'ChatGPT मॉडल कॉन्फ़िगरेशन',
    chatGptModelSubheading: 'कौन सा ChatGPT मॉडल उपयोग करें और दुरुपयोग से बचें',
    chatGptApi: 'ChatGPT एपीआई कुंजी',
    enableDisableRedactData: 'ग्राहक डेटा को एकत्र करें',
    triggerToCollectData: 'सेट सवालों के बाद संपर्क विवरण एकत्र करें।',
    train: 'प्रशिक्षित करें',
    website: 'वेबसाइट',
    includedSourcesForResponse: 'प्रतिसाद के लिए शामिल स्रोत',
    yourConversation: 'आपकी बातचीतें',
    analytics: 'विश्लेषण',
    reviewAndSelectConversation: 'चैटबॉट प्रशिक्षण के लिए समीक्षा और चयन करें',
    exportAiAnalytics: 'एनालिटिक्स निर्यात करें',
    startDate: 'प्रारंभ तिथि',
    endDate: 'समाप्ति तिथि',
    userQuestion: 'उपयोगकर्ता का प्रश्न',
    botsReply: 'बॉट की जवाब',
    dateCreated: 'निर्माण तिथि',
    noAnalyticsFound: 'आपके पास अब तक कोई एनालिटिक्स नहीं है',
    noAnalyticsFoundMessage: 'आपकी सभी एनालिटिक्स संदेश यहां दिखाई देंगे',
    exportData: 'डेटा निर्यात करें',
    exportCompleteAnalyticsData: 'पूर्ण एनालिटिक्स डेटा निर्यात करें',
    export: 'निर्यात करें',
    integrateWithChatGpt: 'ChatGpt के साथ एकीकृत करें',
    integrated: 'एकीकृत किया गया',
    configure: 'कॉन्फ़िगर',
    appToolTip: {
      deleteWebsite: 'वेबसाइट हटाएं',
      knowledgeBase: 'ज्ञान आधार के साथ अपने बॉट को प्रशिक्षित करें',
      faqs: 'एफएक्यूएस के साथ अपने बॉट को प्रशिक्षित करें',
      knowledgeHistory: 'ज्ञान आधार इतिहास',
      uploadFaqs: 'एफएक्यूएस अपलोड करें',
      addFaqs: 'एफएक्यूएस जोड़ने के लिए क्लिक करें',
      deleteFaqs: 'एफएक्यूएस हटाएं',
      deleteHistory: 'इतिहास हटाएं',
      uploadFile: 'अपलोड की गई फ़ाइल PDF, DOCX, DOC या CSV होनी चाहिए और आकार अधिकतम 1 MB होना चाहिए।',
      trainWebsite: 'वेबसाइट का उपयोग करके अपने बॉट को ट्रेन करें।',
      fileUpload: 'PDFs, TXTs, PPTXs, DOCs और DOCXs जैसे फ़ाइलों का उपयोग करके अपने बॉट को प्रशिक्षित करें।',
      configureBotBehaviour: 'अपने बॉट की पर्सनैलिटी को अनुकूलित करें, फ्लो को संचालित करें और ChatGPT मॉडल को समायोजित करें।',
      clickToAddWebsite: 'वेबसाइट जोड़ने के लिए क्लिक करें',
      clickToDeleteWebsites: 'वेबसाइट हटाने के लिए क्लिक करें',
      syncWebsite: 'वेबसाइट सिंक करें',
      copyUrl: 'URL कॉपी करें',
      weWillBeFetchingAllTheWebpages: 'हम सभी वेबपेज फेच करेंगे और उन पर ChatGPT मॉडल को प्रशिक्षित करेंगे।'
    },
    visibility: 'दृश्यता',
    modal: {
      deleteWebsiteUrl: 'वेबसाइट URL को हटाएं',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'क्या आप वाकई इस वेबसाइट URL को हटाना चाहते हैं?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'वेबसाइट चैटबॉट सेटिंग्स',
    preview: 'पूर्वावलोकन',
    test: 'परीक्षण',
    install: 'इंस्टॉल करें',
    designHeader: 'डिज़ाइन',
    generalHeader: 'जनरल',
    alertsHeader: 'अलर्ट',
    triggerHeader: 'ट्रिगर',
    userAccessHeader: 'यूजर एक्सेस',
    chatWindowHeader: 'चैट विंडो',
    otherConfiguration: 'एआई सेटिंग्स',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'चयनित प्लेटफ़ॉर्म पर अपना बॉट इंस्टॉल करने के लिए क्लिक करें',
      customizeAppearanceOfBot: 'अपने बॉट इंटरफ़ेस की स्थिति और उपस्थिति को अनुकूलित करें।',
      configureBotsSettingsWhenAgentUnavailable: 'जब आपका एजेंट उपलब्ध न हो तो बॉट की सेटिंग्स कॉन्फ़िगर करें।',
      configureHowBotWillAlertYou: 'कॉन्फ़िगर करें कि आपका बॉट आपको नए लीड से कैसे सचेत करेगा।',
      makeSureYourBotsPopUpProperlyTimed: 'सुनिश्चित करें कि आपके बॉट के पॉप-अप पूरी तरह से समयबद्ध हैं।',
      customizeWhereYouWantYourBotToAppear: 'कस्टमाइज़ करें जहां आप चाहते हैं कि आपका बॉट दिखाई दे और जहां नहीं।',
      enableChatOptionsForVisitorsToTransferChat: 'चैट, लाइव चैट और रिफ्रेश चैट को स्थानांतरित करने के लिए अपने आगंतुकों के लिए चैट विकल्प सक्षम करें।',
      configureBotBehaviour: 'अपने बॉट की पर्सनैलिटी को अनुकूलित करें, फ्लो को संचालित करें और ChatGPT मॉडल को समायोजित करें।',
    },
    alerts: {
      configureYourBotsAlertSettings: 'अपने बॉट की अलर्ट सेटिंग्स को कॉन्फ़िगर करें',
      specifyHowYourBotWillNotify: 'निर्दिष्ट करें कि आपका बॉट आपको नए लीड के बारे में कैसे सूचित करेगा।',
      subject: 'विषय',
      emailNotification: 'ई - मेल अधिसूचना',
      emailAddresses: 'ईमेल पते',
      leadRevisitNotification: 'लीड रिविसिट नोटिफिकेशन',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'आपने डेस्कटॉप सूचनाओं के साथ सफलतापूर्वक पंजीकृत किया है',
      youHaveBlockedYourNotificationsOnBrowser: 'आपने अपनी ब्राउज़र सेटिंग्स पर सूचनाएं अवरुद्ध कर दी हैं।',
      discard: 'खारिज करना',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      saveChanges: 'परिवर्तनों को सुरक्षित करें',
      placeholders: {
        enterSubjectForYourEmail: 'अपने ईमेल के लिए विषय दर्ज करें',
        typeYourEmailAddressAndHitEnter: 'अपना ईमेल पता टाइप करें और एंटर को हिट करें'
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'बॉट से अपने ईमेल सूचनाओं के विषय को अनुकूलित करें',
        TOOLTIP_EMAIL_NOTIFICATION: 'नए लीड के लिए अपने ईमेल पर बॉट की अधिसूचना को सक्षम/अक्षम करें',
        TOOLTIP_EMAIL_ADDRESS: 'उस खाते का ईमेल पता सबमिट करें जहाँ आप ईमेल सूचनाएं प्राप्त करना चाहते हैं। आप एक दर्ज करके और दूसरे को जोड़ने के लिए एंटर दबाकर कई ईमेल पते जोड़ सकते हैं।',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' अपने ईमेल पर बॉट की अधिसूचना को सक्षम/अक्षम करें यदि कोई उपयोगकर्ता पहले एक लीड के रूप में कैप्चर किया गया था तो बॉट के साथ फिर से बातचीत करता है।',
      }
    },
    broadcasts: {
      createBroadcastHeading: 'प्रसारण बनाएँ',
      youCanCreateMultipleBroadcasts: 'आप कई प्रसारण बना सकते हैं',
      broadcastName: 'प्रसारण नाम',
      broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
      continue: 'जारी रखना',
      manageBroadcasts: 'प्रसारण प्रबंधित करें',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'आप विभिन्न ग्राहक समूहों के लिए कई अनुसूचित टेम्प्लेट के साथ कई प्रसारण बना सकते हैं।',
      createNewBroadcast: 'नया प्रसारण बनाएं',
      name: 'नाम',
      segment: 'समूह',
      template: 'खाका',
      contacts: 'संपर्क',
      send: 'भेजना',
      delivered: 'पहुंचा दिया',
      read: 'पढ़ना',
      failed: 'असफल',
      broadcastAt: 'प्रसारित करना',
      status: 'दर्जा',
      actions: 'कार्रवाई',
      nA: '-',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      deleteBroadcast: 'प्रसारण हटाएं',
      wantToDeleteThisBroadcast: 'क्या आप सुनिश्चित हैं कि आप इस प्रसारण को हटाना चाहते हैं?',
      placeholders: {
        searchHere: 'यहां तलाश करो',
        enterBroadcastName: 'प्रसारण नाम दर्ज करें'
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'कई प्रसारण हटाएं',
        broadcastAnalytics: 'प्रसारण विश्लेषिकी',
        deleteBroadcast: 'प्रसारण हटाएं'
      },
      broadcastAnalytics: {
        broadcastInsights: 'प्रसारण अंतर्दृष्टि',
        broadcastStatus: 'प्रसारण स्थिति',
        youCanFindDetailsOfEachContactBelow: 'आप नीचे दिए गए प्रत्येक संपर्क का विवरण पा सकते हैं और उन लोगों को निर्यात कर सकते हैं जिनके पास मुद्दे हो सकते हैं।',
        filterApplied: 'फ़िल्टर एप्लाइड',
        placeholders: {
          searchByPhoneNumber: 'फोन नंबर द्वारा खोजें',
          searchByName: 'नाम से फ़िल्टर खोजें'
        },
        appTooltip: {
          filterDataBasedOnStatus: 'फ़िल्टर डेटा आधारित स्थिति',
          exportData: 'निर्यात जानकारी'
        },
        modal: {
          exportData: 'निर्यात जानकारी',
          exportCompleteBroadcastData: 'पूर्ण प्रसारण डेटा निर्यात करें'
        }
      },
      createBroadcast: {
        broadcastName: 'प्रसारण नाम',
        broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
        template: 'खाका',
        segments: 'सेगमेंट',
        whenToSend: 'कब भेजना है',
        now: 'अब',
        later: 'बाद में',
        selectBroadcastUsing: 'प्रसारण का चयन करें',
        usingCsv: 'CSV का उपयोग करना',
        usingSegment: 'समूह का उपयोग करना',
        allContacts: 'सभी संपर्क',
        selectDate: 'तारीख़ चुनें',
        selectTimeSlot: 'टाइम स्लॉट का चयन करें',
        assignVariablesToTemplates: 'टेम्प्लेट को चर असाइन करें',
        addHeaderMedia: 'हेडर मीडिया जोड़ें',
        selectFile: 'फ़ाइल का चयन करें',
        cancel: 'रद्द करना',
        createBroadcast: 'प्रसारण बनाएँ',
        broadcastUsing: 'प्रसारण का उपयोग करना',
        selectCSVFile: 'CSV फ़ाइल का चयन करें',
        importContacts: 'संपर्क आयात करें',
        beforeYouStartUpload: 'इससे पहले कि आप अपलोड शुरू करें कृपया सुनिश्चित करें',
        point1: '1. नमूना CSV फ़ाइल डाउनलोड करें।',
        point2: '2. दूसरी पंक्ति से अपना डेटा दर्ज करना शुरू करें।',
        point3: '3. प्रत्येक पंक्ति में, एक संपर्क के लिए डेटा होगा',
        point4: '4. सुनिश्चित करें कि सभी फोन नंबर अद्वितीय हैं।',
        point5: '5. अधिकतम फ़ाइल आकार अपलोड सीमा 20MB है',
        // selectFile: 'फ़ाइल का चयन करें',
        clickHere: 'यहाँ क्लिक करें',
        toDownloadSampleFile: 'नमूना फ़ाइल डाउनलोड करने के लिए',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          selectTemplate: 'टेम्पलेट का चयन करें',
          searchTemplateByName: 'नाम से खोज टेम्पलेट',
          selectSegments: 'सेगमेंट का चयन करें',
          selectTimeSlot: 'टाइम स्लॉट का चयन करें',
          selectValueForThisVariable: 'इस चर के लिए मान का चयन करें',
          searchHeaderByVariableName: 'नाम से हेडर चर खोजें',
          variableFallbackValue: 'परिवर्तनीय गिरावट मूल्य',
          searchBodyVariableByName: 'नाम से शरीर चर खोजें',
          searchSegmentByName: 'नाम से खोज समूह',
          selectBroadcastUsing: 'प्रसारण का चयन करें',
        },
        indexMergeDatePicker: {
          selectStartDate: 'प्रारंभ तिथि का चयन करें'
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'व्हाट्सएप प्रसारण के लिए आयात संपर्क',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'व्हाट्सएप नंबर',
        errorCode: 'त्रुटि कोड',
        errorMessage: 'त्रुटि संदेश',
        nA: '-'
      }
    },
    design: {
      customizeYourLandingPage: 'अपने लैंडिंग पेज को अनुकूलित करें',
      customizeYourConversationalBot: 'अपने संवादात्मक बॉट को अनुकूलित करें',
      customizeYourBot: 'अपने बॉट को अनुकूलित करें',
      createHeadingTextAndTheme: 'हेडिंग टेक्स्ट और थीम बनाएं जो आपके ब्रांड के व्यक्तित्व को दर्शाता है',
      content: 'कंटेंट',
      theme: 'थीम',
      layout: 'लेआउट',
      landingPageName: 'लैंडिंग पेज का नाम',
      botName: 'बॉट का नाम',
      charactersRemaining: ' शेष वर्ण',
      landingPageHeader: 'लैंडिंग पेज का हेडर',
      landingPageDescription: 'लैंडिंग पेज का विवरण',
      hideContent: 'सामग्री छिपाना',
      profilePic: 'प्रोफाइल तस्वीर',
      selectFile: 'फ़ाइल का चयन करें',
      socialMediaLinks: 'सोशल मीडिया के लिंक',
      initialMessage: 'संवादात्मक बॉट प्रारंभिक संदेश',
      selectType: 'प्रकार चुनें',
      url: 'यू आर एल',
      delete: 'मिटाना',
      editLink: 'लिंक संपादित करें',
      addLink: 'लिंक जोड़ें',
      callToAction: 'कार्यवाई के लिए बुलावा',
      liveChatWelcomeMessage: 'लाइव चैट पर स्वागत संदेश',
      liveChatTriggerMessage: 'लाइव चैट पर ट्रिगर संदेश',
      autoHideCallToAction: 'ऑटो एक्शन के लिए कॉल छिपाएं',
      maxDelayShouldBe300Seconds: 'अधिकतम देरी 300 सेकंड की होनी चाहिए',
      landingPageThemeColor: 'लैंडिंग पेज का थीम रंग',
      themeColor: 'थीम रंग',
      landingPageBackground: 'लैंडिंग पेज बैकग्राउंड',
      chatBackgroundColor: 'चैट बैकग्राउंड कलर',
      color: 'रंग',
      gradient: 'ग्रेडिएंट',
      image: 'छवि',
      video: 'वीडियो',
      gradientStart: 'ग्रेडिएंट स्टार्ट',
      gradientEnd: 'ग्रेडिएंट अंत',
      positionOnWeb: 'वेब पर स्थिति',
      positionOnMobile: 'मोबाइल पर स्थिति',
      windowSize: 'खिड़की का आकार',
      s: 'एस',
      m: 'एम',
      l: 'एल',
      xl: 'एक्स्ट्रा लार्ज',
      xxl: 'XXL',
      custom: 'रिवाज़',
      height: 'ऊंचाई',
      minimum300HeightIsRequired: 'न्यूनतम 300px ऊंचाई की आवश्यकता है',
      heightCannotExceed720: 'ऊंचाई 720px से अधिक नहीं हो सकती है',
      width: 'चौड़ाई',
      px: 'पिक्सल',
      minimum200WidthIsRequired: 'न्यूनतम 200px चौड़ाई आवश्यक है',
      widthCannotExceed1050: 'चौड़ाई 1050px से अधिक नहीं हो सकती है',
      discard: 'खारिज करना',
      saveChanges: 'परिवर्तनों को सुरक्षित करें',
      landingPageBot: 'लैंडिंग पेज बॉट',
      chatBot: 'चैटबोट',
      preview: ' पूर्व दर्शन',
      test: 'परीक्षण',
      botIcon: 'बॉट चिह्न',
      websiteBot: 'वेबसाइट चैटबॉट',
      selectFont: 'फ़ॉन्ट का चयन करें',
      fontLibrary: 'फ़ॉन्ट पुस्तकालय',
      uploadFont: 'फ़ॉन्ट अपलोड करें',
      selectFontForBot: 'बॉट के लिए फ़ॉन्ट का चयन करें',
      uploadedFont: 'अपलोड किया गया फ़ॉन्ट',
      flowTriggerDetail: 'Trigger की उपयोगिता संपर्क विवरण एकत्र करने के लिए है।',
      placeholders: {
        enterName: 'नाम दर्ज करें',
        enterHeaderText: 'हेडर टेक्स्ट दर्ज करें',
        enterDescription: 'विवरण दर्ज करें',
        enterUrl: 'यू आर एल दर्ज करो',
        howCanIHelpYou: 'मैं आपकी कैसे मदद कर सकता हूँ?',
        enterYourWelcomeMessageHere: 'अपना स्वागत संदेश यहां दर्ज करें',
        enterYourLiveChatMessageHere: 'अपना लाइव चैट संदेश यहां दर्ज करें',
        enterTimeInSeconds: 'सेकंड में समय दर्ज करें',
        enterHeight: 'ऊंचाई दर्ज करें',
        enterWidth: 'चौड़ाई दर्ज करें',
        flowInitialMessage: 'प्रारंभिक प्रश्न दर्ज करें',
      },
      appTooltip: {
        standardLayout: 'मानक लेआउट',
        rightLayout: 'सही लेआउट',
        mergedLayout: 'विलय की गई लेआउट',
        enterNameOfYourBot: 'अपने बॉट का नाम दर्ज करें',
        enterNameOfYourLandingPageBot: 'अपने लैंडिंग पेज बॉट का नाम दर्ज करें',
        headingTextThatAppearOnLandingPageBot: 'हेडिंग टेक्स्ट दर्ज करें जिसे आप अपने लैंडिंग पेज बॉट पर दिखाई देना चाहते हैं',
        descriptionTextThatAppearOnLandingPageBot: 'वह विवरण पाठ दर्ज करें जिसे आप अपने लैंडिंग पेज बॉट पर दिखाई देना चाहते हैं।',
        addLinksAndSocialMediaIcons: 'लिंक और सोशल मीडिया आइकन जोड़ें',
        initialFlowMessage: 'पहला संदेश दर्ज करें जो बॉट ग्राहक के साथ बातचीत शुरू करने के लिए भेजेगा',
        anInvitingCatchPhrase: 'एक आमंत्रित कैच वाक्यांश जो उपयोगकर्ता को बॉट से बात करेगा',
        setAWelcomeMessageForYourVisitors: 'अपने आगंतुकों के लिए एक स्वागत संदेश सेट करें। यह आपकी बातचीत में पहला संदेश होगा।',
        setAMessageToInitiateLiveChat: 'आगंतुक के अंत से लाइव चैट शुरू करने के लिए एक संदेश सेट करें। आप हर बार जब यह संदेश अपनी किसी भी बातचीत में ट्रिगर किया जाता है, तो आप एक अधिसूचना प्राप्त करेंगे।',
        autoHideCallToAction: 'ऑटो निर्दिष्ट समय के बाद कॉल टू एक्शन टेक्स्ट को छिपाएं',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'आप स्क्रीन पर केवल चैट विंडो दिखाने के लिए लोगो और ग्रंथों को छिपाने के लिए चुन सकते हैं',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'चैटबॉट पृष्ठ के लिए एक लोगो अपलोड करें।',
        TOOLTIP_CHAT_THEME_COLOR: 'अपने बॉट की चैट विंडो का रंग चुनें',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'बॉट की चैट विंडो का पृष्ठभूमि रंग चुनें',
        TOOLTIP_WEB_1ST_POSITION: 'बॉट उपयोगकर्ता के डेस्कटॉप के निचले-बाएँ पर अपना बॉट रखें',
        TOOLTIP_WEB_2ND_POSITION: 'बॉट उपयोगकर्ता के डेस्कटॉप के मध्य दाएं कोने पर अपना बॉट रखें',
        TOOLTIP_WEB_3RD_POSITION: 'बॉट उपयोगकर्ता के डेस्कटॉप के निचले दाएं कोने पर अपने बॉट को रखें',
        TOOLTIP_MOBILE_1ST_POSITION: 'बॉट उपयोगकर्ता के मोबाइल के निचले बाएँ कोने पर अपना बॉट रखें',
        TOOLTIP_MOBILE_2ND_POSITION: 'बॉट उपयोगकर्ता के मोबाइल के मध्य दाएं कोने पर अपना बॉट रखें',
        TOOLTIP_MOBILE_3RD_POSITION: 'बॉट यूजर के मोबाइल के निचले दाएं कोने पर अपना बॉट रखें',
        TOOLTIP_WINDOW_HEIGHT: 'बॉट की चैट विंडो का आकार बदलें',
        TOOLTIP_BOT_ICON: 'एक आइकन चुनें या अपने बॉट के लिए लोगो/छवि अपलोड करें',
        flowTriggerMessage: 'नाम, ईमेल और फोन जैसे संपर्क विवरण एकत्र करने से पहले पूछे जाने वाले संदेशों की संख्या दर्ज करें',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'ड्रिप अभियान बनाएँ',
      youCanCreateMultipleCampaigns: 'आप कई अभियान बना सकते हैं',
      dripCampaignName: 'ड्रिप अभियान नाम',
      dripCampaignNameIsReuired: 'ड्रिप अभियान का नाम आवश्यक है',
      continue: 'जारी रखना',
      manageDripCampaign: 'ड्रिप अभियान प्रबंधित करें',
      youCanCreateMultipleCampaignsWith: 'आप विशिष्ट उपयोगकर्ता समूहों से प्रतिक्रियाओं को ट्रिगर करने के लिए अनुसूचित, स्वचालित संदेशों के साथ कई ड्रिप अभियान बना सकते हैं।',
      createNewDripCampaign: 'नया ड्रिप अभियान बनाएं',
      campaignName: 'अभियान का नाम',
      appliedOn: 'पर लागू',
      segment: 'समूह',
      template: 'खाका',
      startDate: 'आरंभ करने की तिथि',
      action: 'कार्य',
      nA: '-',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      placeholders: {
        enterDripCampaignName: 'ड्रिप अभियान का नाम दर्ज करें',
        searchByName: 'नाम से खोजें'
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'कई ड्रिप अभियान हटाएं',
        deleteDripCampaign: 'ड्रिप अभियान हटाएं'
      },
      createDripCampaign: {
        dripCampaignName: 'ड्रिप अभियान नाम',
        appliedOn: 'पर लागू',
        segments: 'सेगमेंट',
        selectDate: 'तारीख़ चुनें',
        scheduleYourTemplate: 'अपने टेम्प्लेट शेड्यूल करें',
        schedule: 'अनुसूची',
        template: 'खाका',
        sendAfter: 'के बाद भेज देना',
        assignVariablesToTemplate: 'टेम्प्लेट को चर असाइन करें',
        addHeaderMedia: 'हेडर मीडिया जोड़ें',
        selectFile: 'फ़ाइल का चयन करें',
        createDripCampaign: 'ड्रिप अभियान बनाएँ',
        newSubscriber: 'नएं ग्राहक',
        days: 'दिन',
        minutes: 'मिनट',
        hours: 'घंटे',
        placeholders: {
          enterDripName: 'ड्रिप नाम दर्ज करें',
          selectAppliedOn: 'पर लागू करें का चयन करें',
          selectSegments: 'सेगमेंट का चयन करें',
          searchByName: 'नाम से खोजें',
          selectTemplate: 'टेम्पलेट का चयन करें',
          searchTemplateByName: 'नाम से खोज टेम्पलेट',
          enterSendAfter: 'इसके बाद भेजें',
          selectValueForThisVariable: 'इस चर के लिए मान का चयन करें',
          searchHeaderVariableByName: 'नाम से हेडर चर खोजें',
          searchBodyVariableByName: 'नाम से शरीर चर खोजें'
        },
        dateTimePicker: {
          selectStartDate: 'प्रारंभ तिथि का चयन करें'
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'अपने लैंडिंग पेज की सामान्य सेटिंग्स कॉन्फ़िगर करें',
      configureBotsGeneralSettings: 'अपने बॉट की सामान्य सेटिंग्स कॉन्फ़िगर करें',
      selectTheTimeZone: 'जब अपने ग्राहकों के लिए समय-क्षेत्र, भाषा और थोड़ा नोट का चयन करें',
      isNotAvailable: ' उपलब्ध नहीं है',
      landingPagesBot: 'लैंडिंग पेज का बॉट',
      bot: 'बीओटी',
      waitingMessage: 'प्रतीक्षा संदेश',
      landingPageBotStatus: 'लैंडिंग पेज बॉट स्थिति',
      chatBotStatus: 'चैटबोट की स्थिति',
      unavailabilityMessage: 'उपलब्ध नहीं-उपलब्धता संदेश',
      sendIncompleteResponses: 'अधूरी प्रतिक्रियाएं भेजें',
      language: 'भाषा',
      invalidateCache: 'अमान्य कैश',
      timezone: 'समय क्षेत्र',
      chatWindowMessageHistory: 'चैट विंडो संदेश इतिहास',
      session: 'सेशन',
      forever: 'फॉरएवर ',
      english: 'अंग्रेज़ी',
      spanish: 'स्पैनिश',
      french: 'फ्रांसीसी',
      portuguese: 'पुर्तगाली',
      german: 'जर्मन',
      italian: 'इतालवी',
      swedish: 'स्वीडिश',
      arabic: 'अरबी',
      malay: 'मलायी',
      russian: 'रूसी',
      discard: 'खारिज करना',
      saveChanges: 'परिवर्तनों को सुरक्षित करें',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      placeholders: {
        enterYourWaitingMessageHere: 'अपना प्रतीक्षा संदेश यहां दर्ज करें',
        searchTimeZone: 'टाइम जोन खोजें'
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'कस्टमाइज़ संदेश जैसा कि आप चाहते हैं कि यह एक बॉट उपयोगकर्ता के तुरंत बाद चैट विंडो पर दिखाई दे, लाइव चैट के लिए अनुरोध भेजता है',
        TOOLTIP_CHATBOT_STATUS: 'बॉट को सक्रिय /निष्क्रिय करें',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'कस्टमाइज़ संदेश जैसा कि आप चाहते हैं कि यह चैट विंडो पर दिखाई दे जब आपका कोई भी एजेंट बॉट उपयोगकर्ता के साथ लाइव चैट के लिए उपलब्ध नहीं है',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'जब बॉट उपयोगकर्ता ने बातचीत को समाप्त नहीं किया, तब भी आपको ईमेल सूचनाएं भेजें।',
        TOOLTIP_LANGUAGE: 'बॉट की भाषा का चयन करें',
        TOOLTIP_INVALIDATE_CACHE: 'कैश को क्लियर करने के लिए यहां क्लिक करें और जब आप बॉट चैट फ्लो या सेटिंग्स को संपादित करते हैं, तो परिवर्तन 15 मिनट के बाद ही प्रतिबिंबित करते हैं',
        TOOLTIP_TIME_ZONE: 'अपने बॉट के लिए समय-क्षेत्र का चयन करें',
        TOOLTIP_CHAT_RETENTION: 'यदि आप चैट विंडो पर या केवल ब्राउज़र सत्र के लिए बॉट उपयोगकर्ता की चैट को हमेशा के लिए बनाए रखना चाहते हैं, तो चयन करें।',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'अपनी चैट विंडो को अनुकूलित करें',
      controlAndCustomizeTheChatWindow: 'अपने ग्राहकों के साथ बातचीत करने वाली चैट विंडो को नियंत्रित और अनुकूलित करें।',
      allowVisitorsToTransferChatToWhatsapp: 'आगंतुकों को व्हाट्सएप में चैट ट्रांसफर करने की अनुमति दें',
      allowVisitorsToRefreshChat: 'आगंतुकों को चैट को ताज़ा करने की अनुमति दें',
      displayNameForTransferringChatToWhatsapp: 'व्हाट्सएप में चैट ट्रांसफर करने के लिए नाम प्रदर्शित करें',
      displayNameForTransferringToLiveChat: 'लाइव/एजेंट चैट में स्थानांतरित करने के लिए प्रदर्शन नाम',
      displayNameForTransferringAChatToBot: 'बॉट को चैट ट्रांसफर करने के लिए प्रदर्शन का नाम प्रदर्शित करें',
      discard: 'खारिज करना',
      saveChanges: 'परिवर्तनों को सुरक्षित करें',
      allowVisitorsToTransferChatToBot: 'आगंतुकों को बॉट में चैट स्थानांतरित करने की अनुमति दें।',
      allowVisitorsToRequestForLiveChat: 'आगंतुकों को लाइव चैट के लिए अनुरोध करने की अनुमति दें।',
      indicativeTextForCustomersReply: 'ग्राहक के उत्तर के लिए सांकेतिक पाठ',
      displayNameForRestartingChatBot: 'चैटबॉट वार्तालाप को फिर से शुरू करने के लिए नाम प्रदर्शित करें',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      placeholders: {
        enterButtonNameForWhatsapp: 'व्हाट्सएप में स्थानांतरित करने के लिए बटन नाम दर्ज करें',
        enterButtonNameForLiveChat: 'लाइव चैट या एजेंट में स्थानांतरित करने के लिए बटन नाम दर्ज करें',
        enterButtonNameForBot: 'बॉट में स्थानांतरित करने के लिए बटन नाम दर्ज करें',
        enterTextDisplayInEmptyReplyArea: 'खाली उत्तर क्षेत्र में प्रदर्शित पाठ दर्ज करें',
        enterButtonForRestartingConversation: 'बातचीत को फिर से शुरू करने के लिए बटन नाम दर्ज करें'
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'वेबसाइट के आगंतुकों को व्हाट्सएप नं को चैट ट्रांसफर करने की अनुमति दें। संबंधित एजेंट से वे जुड़े हुए हैं। इस सुविधा को काम करने के लिए लाइव चैट फीचर सक्षम होना चाहिए।',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'बॉट उपयोगकर्ताओं को चैट को रिफ्रेश करने की अनुमति दें ताकि वे बातचीत शुरू कर सकें।',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'बॉट उपयोगकर्ताओं को लाइव चैट एजेंट के साथ बातचीत के बीच कभी भी बॉट को चैट को ट्रांसफर करने की अनुमति दें।',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'जब भी वे चाहें, बॉट उपयोगकर्ताओं को एजेंट के साथ लाइव चैट के लिए अनुरोध करने की अनुमति दें।',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'यदि आपने उपयोगकर्ता को व्हाट्सएप में स्थानांतरित करने में सक्षम बनाया है, तो आप उस विकल्प को कैसे प्रदर्शित करना चाहते हैं, इसे भरें',
        ifYouHaveEnabledUserToTransferToLiveChat: 'यदि आपने उपयोगकर्ता को लाइव चैट में स्थानांतरित करने में सक्षम बनाया है, तो आप उस विकल्प को कैसे प्रदर्शित करना चाहते हैं, इसे भरें',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'यदि आपने उपयोगकर्ता को व्हाट्सएप में स्थानांतरित करने में सक्षम बनाया है, तो आप बॉट में वापस स्थानांतरित करना चाहते हैं',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'भरें कि आप ग्राहकों के लिए उत्तर स्थान कैसे प्रदर्शित करना चाहते हैं',
        ifYouHaveEnabledUserToRestartChatBot: 'यदि आपने उपयोगकर्ता को चैटबॉट वार्तालाप को पुनरारंभ करने में सक्षम बनाया है, तो यह भरें कि आप उस विकल्प को कैसे प्रदर्शित करना चाहते हैं',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'चैट प्रवाह',
      dripCampaignHeader: 'ड्रिप कैंपेन',
      widgetsHeader: 'विजेट',
      broadcastHeader: 'प्रसारण',
      ordersHeader: 'आदेश',
      manageHeader: 'प्रबंधित करना',
      inputHeader: 'इनपुट',
      apiKeyHeader: 'एपीआई कुंजी',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'अनुसूचित चैट प्रवाह के ड्रिप अभियान का उपयोग करके एक ग्राहक ऑनबोर्डिंग यात्रा बनाएं।',
        TOOLTIP_CHAT_FLOW_TITLE: 'चैट फ्लो टेम्प्लेट को कस्टमाइज़ करें, और विभिन्न लक्ष्य दर्शकों के लिए कई चैट फ्लो बनाएं।',
        TOOLTIP_WIDGET_TITLE: 'विजेट (चैट इंटरफेस) बनाएं और उन्हें बॉट के लिए आगंतुकों को निर्देशित करने के लिए प्रचारक वेब पेजों में एम्बेड करें',
        TOOLTIP_BROADCAST_TITLE: 'ब्लास्ट संदेश या पूर्व-निर्धारित समय पर एक ही क्लिक पर अपने संपर्कों पर बहता है',
        TOOLTIP_ORDERS_TITLE: 'ग्राहक द्वारा रखे गए आदेशों की सूची',
        TOOLTIP_MANAGE_TITLE: 'संपर्कों/लीड और कीवर्ड के लिए बॉट कार्यक्षमता का प्रबंधन',
        TOOLTIP_INPUT_TITLE: 'संपर्कों/लीड के लिए उत्तर सामग्री के रूप में व्हाट्सएप टेम्प्लेट, चित्र और पाठ बनाएं',
      },
      apiWrapper: {
        whatsappApiCredentials: 'व्हाट्सएप एपीआई क्रेडेंशियल',
        generateYourWhatsappApiKey: 'अपने कस्टम विकसित ऐप्स के साथ एकीकृत करने के लिए अपने व्हाट्सएप एपीआई कुंजी उत्पन्न करें।',
        generateApiKey: 'API कुंजी उत्पन्न करें',
        webhookUrl: 'वेबहूक यूआरएल',
        addHeader: 'हेडर जोड़ें',
        test: 'परीक्षा',
        webhookNotSet: 'वेबहूक सेट नहीं है',
        webhookHeaders: 'वेबहूक हेडर',
        setWebhookUrl: 'WebHook URL सेट करें',
        placeholders: {
          yourApiKey: 'आपकी एपीआई कुंजी',
          enterWebhookUrl: 'Webhook URL दर्ज करें',
          enterHeaderKey: 'हेडर कुंजी दर्ज करें',
          enterValue: 'दर्ज मूल्य'
        }
      },
      broadcast: {
        createBroadcast: 'प्रसारण बनाएँ',
        createMultipleBroadcasts: 'आप विभिन्न ग्राहक समूहों के लिए कई अनुसूचित प्रवाह/टेम्प्लेट के साथ कई प्रसारण बना सकते हैं।',
        broadcastName: 'प्रसारण नाम',
        nameIsRequired: 'नाम आवश्यक है',
        selectType: 'प्रकार चुनें',
        selectYourFlowType: 'अपने प्रवाह प्रकार का चयन करें',
        segment: 'समूह',
        selectYourSegmentType: 'अपने समूह प्रकार का चयन करें',
        whenToSend: 'कब भेजना है',
        selectDate: 'तारीख़ चुनें',
        messageTag: 'संदेश टैग',
        selectMessageTag: 'संदेश टैग चुनें',
        contentType: 'सामग्री प्रकार',
        nonPromotional: 'गैर-प्रचारक (संदेश टैग लागू)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'प्रत्येक संदेश को टैग करें जो किसी भी इंटरैक्शन से पहले या उचित टैग के साथ 24 घंटे से अधिक की देरी के चरणों के बाद आता है। अनटैग्ड मैसेज सब्सक्राइबर तक नहीं पहुंचेगा, जिन्होंने 24 घंटे पहले बॉट के साथ बातचीत की है',
        promotional: 'प्रचारक (संदेश टैग अनदेखा)',
        theseBroadcasts: 'इन प्रसारणों में पदोन्नति हो सकती है, लेकिन लक्षित दर्शक पिछले 24 घंटों में आपके बॉट के साथ उन ग्राहकों तक सीमित है।',
        createNewBroadcast: 'नया प्रसारण बनाएं',
        sentMessages: 'सन्देश भेज दिया है',
        deliveredMessages: 'संदेश दिए गए संदेश',
        readMessages: 'संदेश पढ़ें',
        failedMessages: 'विफल संदेश',
        type: 'प्रकार',
        template: 'खाका',
        sent: 'भेजा',
        delivered: 'पहुंचा दिया',
        read: 'पढ़ना',
        failed: 'असफल',
        broadcastAt: 'प्रसारित करना',
        status: 'दर्जा',
        shipmentUpdate: 'शिपमेंट अद्यतन',
        flow: 'प्रवाह',
        resendFailedMessages: 'असफल संदेशों को फिर से तैयार करें',
        goBack: 'वापस जाओ',
        number: 'संख्या',
        errorCode: 'त्रुटि कोड',
        failedAt: 'पर असफल रहा',
        badGateway: '502 खराब गेटवे',
        loadMore: 'और लोड करें',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          searchBroadcastByName: 'नाम से प्रसारित खोज'
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'कुल प्रसारण संदेश भेजे गए',
          totalBroadcastMessagesDelivered: 'कुल प्रसारण संदेश दिए गए',
          totalBroadcastMessagesReadByContacts: 'संपर्कों द्वारा पढ़े गए कुल प्रसारण संदेश',
          totalFailedBroadcastMessages: 'कुल प्रसार संदेश',
          clickToViewDetailedInformationOfFailedMessages: 'विफल संदेशों की जानकारी देखने के लिए क्लिक करें',
          clickToCreateNewBroadcast: 'नया प्रसारण बनाने के लिए क्लिक करें',
          templateUsedInBroadcast: 'प्रसारण में उपयोग किए गए टेम्पलेट',
          numberOfBroadcastMessagesSent: 'प्रसारण संदेशों की संख्या',
          numberOfBroadcastMessagesDelivered: 'प्रसारण संदेशों की संख्या',
          numberOfBroadcastMessagesReadByContacts: 'संपर्कों द्वारा पढ़े गए प्रसारण संदेशों की संख्या',
          numberOfTimesBroadcastMessagesHasFailed: 'कई बार प्रसारण संदेश विफल रहा है',
          contactNumberOnWhichMessageDeliveryFailed: 'संपर्क नंबर जिस पर संदेश वितरण विफल रहा',
          unDeliveredTemplate: 'अविभाजित टेम्पलेट',
          errorReceivedForFailedMessage: 'विफल संदेश के लिए प्राप्त त्रुटि',
          timeWhenMessageDeliveryFailed: 'वह समय जब संदेश वितरण विफल रहा',
          TOOLTIP_ADD_BROADCAST_NAME: 'एक नया प्रसारण नाम दर्ज करें',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'यदि आप एक प्रवाह या संदेश प्रसारित कर रहे हैं तो चयन करें।',
          TOOLTIP_BROADCAST_FLOW: 'उस चैट प्रवाह का चयन करें जिसे आप प्रसारित करना चाहते हैं,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'उस संपर्क समूह का चयन करें जिसे आप प्रसारित करना चाहते हैं',
          TOOLTIP_BROADCAST_NAME: 'प्रसारण का नाम',
          TOOLTIP_BROADCAST_TYPE: 'प्रसारण के लिए चयनित प्रवाह या संदेश',
          TOOLTIP_BROADCAST_SEGMENT: 'जिस समूह के लिए प्रसारण बनाया गया है',
          TOOLTIP_BROADCAST_AT: 'प्रसारण के लिए निर्धारित दिनांक और समय',
          TOOLTIP_BROADCAST_STATUS: 'प्रसारण की स्थिति अनुसूचित के रूप में, वर्तमान में चल रही है या पूरी हुई है',
        }
      },
      drip: {
        createDripCampaign: 'ड्रिप अभियान बनाएँ',
        youCanCreateMultipleCampaigns: 'आप कई अभियान बना सकते हैं',
        dripCampaignName: 'ड्रिप अभियान नाम',
        dripCampaignNameIsRequired: 'ड्रिप अभियान का नाम आवश्यक है',
        youCanCreateMultipleDripCampaigns: 'आप विशिष्ट उपयोगकर्ता समूहों से प्रतिक्रियाओं को ट्रिगर करने के लिए अनुसूचित, स्वचालित संदेशों के साथ कई ड्रिप अभियान बना सकते हैं।',
        campaignName: 'अभियान का नाम',
        campaignNameIsRequired: 'अभियान का नाम आवश्यक है',
        after: 'बाद',
        thisMessageWillBeSendAfter: 'यह संदेश बाद भेज दिया जाएगा',
        saveAndUpdate: 'सहेजें और अद्यतन करें',
        schedule: ' अनुसूची',
        select: 'चुनना',
        messages: 'संदेशों',
        cancel: 'रद्द करना',
        createCampaign: 'अभियान बनाएँ',
        updateCampaign: 'अद्यतन अभियान',
        createNewCampaign: 'नया अभियान बनाएं',
        messagesSent: 'भेजे गए संदेश',
        createdAt: 'पर बनाया गया',
        updatedAt: 'पर अद्यतन किया गया',
        action: 'कार्य',
        placeholders: {
          nameYourDripCampaign: 'अपने ड्रिप अभियान का नाम बताइए',
          enterCampaignName: 'अभियान का नाम दर्ज करें',
          searchCampaignByName: 'नाम से खोज अभियान'
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'एक नया अभियान जोड़ने के लिए क्लिक करें',
          TOOLTIP_SCHEDULE: 'इस अभियान में पहले भेजने के लिए कब और कौन सा चैट प्रवाह करें।',
          TOOLTIP_AFTER_DAYS: 'समय, घंटे, या दिनों में अभियान के पहले प्रवाह को भेजने के लिए अनुसूची।',
          TOOLTIP_ADD_MESSAGES: 'अभियान में एक नया प्रवाह जोड़ने के लिए क्लिक करें',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'एक नया अभियान नाम दर्ज करें',
          TOOLTIP_CAMPAIGN_NAME: 'अभियान का नाम',
          TOOLTIP_NO_MESSAGES_SENT: 'अभियान द्वारा भेजे गए संदेशों की संख्या',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'अभियान के निर्माण की दिनांक और समय',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'दिनांक और समय जब अभियान को अंतिम बार संशोधित किया गया था',
          TOOLTIP_CAMPAIGN_ACTION: 'अभियान को संपादित या हटाएं',
          TOOLTIP_UPDATE_CAMPAIGN: 'अभियान सेट-अप में किए गए संशोधनों को सहेजने के लिए क्लिक करें',
        }
      },
      flows: {
        chatFlows: 'चैट धाराएँ',
        createChatFlow: 'चैट प्रवाह बनाएं',
        youCanCreateMultipleConversationsFlow: 'आप ए/बी परीक्षण और प्रसारण के लिए कई चैनलों में कई वार्तालाप प्रवाह बना सकते हैं।',
        flowName: 'प्रवाह नाम',
        nameIsRequired: 'नाम आवश्यक है',
        cancel: 'रद्द करना',
        createFlow: 'प्रवाह बनाएँ',
        createNewFlow: 'नया प्रवाह बनाएं',
        ofMessages: 'संदेशों की',
        triggered: 'उत्प्रेरित',
        stepsFinsished: 'चरण समाप्त हो गया',
        finished: 'खत्म',
        createdOn: 'पर बनाया',
        lastModified: 'अंतिम बार संशोधित',
        defaultFlow: 'डिफ़ॉल्ट प्रवाह',
        action: 'कार्य',
        flowAnalytics: 'प्रवाह विश्लेषण',
        placeholders: {
          enterFlowName: 'प्रवाह नाम दर्ज करें',
          searchFlowByName: 'नाम से खोज प्रवाह'
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'चैट प्रवाह की संख्या ट्रिगर हो जाती है',
          numberOfCompletedChatFlowStepsWhenTriggered: 'जब यह ट्रिगर हो जाता है तो पूर्ण चैट प्रवाह चरणों की संख्या',
          numberOfTimesUsersCompletedChatFlowSteps: 'कई बार उपयोगकर्ताओं ने सभी चैट फ्लो चरणों को पूरा कर लिया है',
          dateAndTimeOfCreationOfChatFlow: 'चैट प्रवाह के निर्माण की दिनांक और समय',
          dateAndTimeWhenChatFlowLastModified: 'दिनांक और समय जब चैट प्रवाह को अंतिम बार संशोधित किया गया था',
          TOOLTIP_CREATE_FLOW: 'एक नया चैट प्रवाह बनाने के लिए क्लिक करें',
          TOOLTIP_FLOW_NAME: 'चैट फ्लो नाम दर्ज करें',
          TOOLTIP_FLOW_TABLE_NAME: 'चैट प्रवाह का नाम',
          TOOLTIP_NO_OF_MESSAGES: 'चैट प्रवाह में संदेश घटकों की संख्या',
          TOOLTIP_DEFAULT_FLOW: 'डिफ़ॉल्ट के रूप में एक चैट प्रवाह सेट करने के लिए इस टॉगल का उपयोग करें, जो सभी नए उपयोगकर्ताओं को परोसा जाएगा।',
          TOOLTIP_ACTION: 'चैट प्रवाह को संपादित या हटाएं',
        }
      },
      manage: {
        leads: 'सुराग',
        manageBotsFunctionalityForContacts: 'संपर्कों/लीड के लिए बॉट की कार्यक्षमता प्रबंधित करें',
        whenToSendLeadInfo: 'लीड जानकारी कब भेजने के लिए',
        smartFlow: 'स्मार्ट प्रवाह',
        welcomeMessageWhenNoSearchKeywordIsMet: 'आपका स्वागत है जब कोई कीवर्ड खोज पूरी नहीं होती है',
        selectTemplates: 'टेम्प्लेट का चयन करें',
        selectFlows: 'प्रवाह का चयन करें',
        selectText: 'पाठ चुनें',
        selectImages: 'छवियों का चयन करें',
        whenItAlmostReaches24HoursSinceMessage: 'जब यह पिछले संदेश के बाद लगभग 24 घंटे तक पहुंच जाता है',
        updateChanges: 'अद्यतन परिवर्तन',
        manageBotsFunctionalityAccordingToKeywords: 'स्मार्ट प्रवाह के मामले में बॉट उपयोगकर्ता द्वारा कीवर्ड इनपुट के अनुसार बॉट की कार्यक्षमता प्रबंधित करें',
        addKeywordAction: 'कीवर्ड क्रिया जोड़ें',
        keywords: 'कीवर्ड',
        matchingMethod: 'मिलान विधि',
        replyMaterial: 'उत्तर सामग्री',
        action: 'कार्य',
        select: 'चुनना',
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'सुझाव दें कि क्या बॉट संपर्क/लीड जानकारी भेजने के लिए एकीकरण सीआरएम को तुरंत, या 5 मिनट के बाद, या प्रवाह के अंत में',
          TOOLTIP_LEAD_SMART_FLOW: 'सभी बॉट उपयोगकर्ताओं, या स्मार्ट प्रवाह (बॉट उपयोगकर्ता द्वारा कीवर्ड इनपुट द्वारा निर्धारित) को भेजने के लिए कोई भी डिफ़ॉल्ट प्रवाह चुनें।',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'उत्तर सामग्री (प्रवाह, टेम्पलेट, पाठ, छवि) का चयन करें यदि बॉट को बॉट उपयोगकर्ता की प्रतिक्रिया से कोई कीवर्ड मैच नहीं मिलता है',
          TOOLTIP_IT_ALMOST_REACHED: 'अंतिम संदेश के 24 घंटे से पहले संपर्कों को भेजने के लिए उत्तर सामग्री का चयन करें',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'स्मार्ट प्रवाह के मामले में बॉट उपयोगकर्ता द्वारा कीवर्ड इनपुट के अनुसार बॉट की कार्यक्षमता प्रबंधित करें',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'विशिष्ट कीवर्ड इनपुट के खिलाफ टेम्पलेट, प्रवाह, पाठ या छवि के रूप में बॉट के उत्तर का चयन करें',
          TOOLTIP_ADD_KEYWORD: 'कीवर्ड जोड़ें',
          TOOLTIP_MATCHING_METHOD: 'कीवर्ड मैच को सटीक या समावेश के रूप में जोड़ें',
          TOOLTIP_REPLY_MATERIAL: 'सुझाए गए कीवर्ड के लिए वापस उत्तर देने के लिए टेम्पलेट, प्रवाह, पाठ या छवि का चयन करें',
          TOOLTIP_KEYWORD_ACTIONS: 'कीवर्ड क्रिया को हटाएं',
          TOOLTIP_UPDATE_CAMPAIGN: 'अभियान सेट-अप में किए गए संशोधनों को सहेजने के लिए क्लिक करें',
        },
        placeholders: {
          keyword: 'कीवर्ड'
        },
        socialAlerts: {
          alerts: 'अलर्ट',
          specifyHowYourBotWillNotify: 'निर्दिष्ट करें कि आपका बॉट आपको नए लीड के बारे में कैसे सूचित करेगा।'
        }
      },
      orders: {
        manageCustomersOrder: 'ग्राहक आदेशों का प्रबंधन करें',
        checkAndManageAllOfYourCustomerOrders: 'अपने सभी ग्राहक आदेशों और अपडेट की जाँच करें और प्रबंधित करें।',
        selectShipmentTemplate: 'शिपमेंट टेम्पलेट का चयन करें',
        send: 'भेजना',
        export: 'निर्यात',
        phoneNumber: 'फ़ोन नंबर',
        numberOfItems: 'मदों की संख्या',
        orderValue: 'ऑर्डर का मूल्य',
        orderDate: 'आर्डर की तारीख',
        status: 'दर्जा',
        chat: 'बात करना',
        item: 'वस्तु',
        quantity: 'मात्रा',
        amount: 'मात्रा',
        unitPrice: 'यूनिट मूल्य',
        placeholders: {
          searchOrdersByPhoneNumber: 'फोन नंबर द्वारा आदेश खोजें'
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'CSV फ़ाइल में आदेश डेटा निर्यात करें'
        }
      },
      widgets: {
        installWidgetHeader: 'विजेट स्थापित करें',
        createWidgetHeader: 'विजेट बनाएँ',
        copyTheCodeAndPasteItWhereYouWant: 'कोड कॉपी करें और इसे पेस्ट करें जहां भी आपको अपने HTML, वेबसाइट आदि में आवश्यकता हो।',
        youCanCreateMultipleWidgets: 'आप अपने बॉट को अलग -अलग वेब पेजों पर एक अलग उपस्थिति देने के लिए कई विजेट बना सकते हैं।',
        installWidget: {
          javascript: 'जावास्क्रिप्ट',
          install: 'स्थापित करना',
          onYourWebsite: ' आपकी वेबसाइट पर',
          copyCode: 'प्रतिलिपि कोड',
          copyAndPaste: 'कॉपी और पेस्ट',
          copyThisCodeSnippet: 'इस कोड स्निपेट को कॉपी करें और इसे प्रत्येक वेब पेज के HTML में डालें जहां आप विजेट प्रदर्शित करना चाहते हैं। अधिक जानकारी के लिए',
          seeGuide: 'गाइड देखें'
        },
        widgetList: {
          widgetName: 'विजेट नाम',
          nameIsRequired: 'नाम आवश्यक है',
          cancel: 'रद्द करना',
          createWidget: 'विजेट बनाएँ',
          icon: 'आइकन',
          name: 'नाम',
          createdAt: 'पर बनाया गया',
          action: 'कार्य',
          placeholders: {
            enterWidgetName: 'विजेट नाम दर्ज करें',
            searchWidgetByName: 'नाम से विजेट खोजें'
          },
          appTooltip: {
            widgetIcon: 'विजेट आइकन',
            nameOfWidget: 'विजेट का नाम',
            dateAndTimeOfCreationOfWidget: 'विजेट के निर्माण की दिनांक और समय',
            editOrDeleteWidget: 'विजेट को संपादित या हटाएं'
          }
        },
        widgetTemplates: {
          widgetName: 'विजेट नाम',
          nameIsRequired: 'नाम आवश्यक है',
          chooseTypeOfWidget: 'विजेट का प्रकार चुनें',
          bar: 'छड़',
          slideIn: 'अंदर फिसलना',
          pageTakeOver: 'पृष्ठ अधिग्रहण',
          button: 'बटन',
          facebookModal: 'फेसबुक मोडल',
          comment: 'टिप्पणी',
          direct: 'प्रत्यक्ष',
          whatsappIcon: 'व्हाट्सएप आइकन',
          refUrl: 'रेफरी',
          qrCode: 'क्यू आर संहिता',
          backgroundColor: 'पृष्ठभूमि का रंग',
          descriptionColor: 'विवरण रंग',
          headlineColor: 'हेडलाइन कलर',
          buttonBackgroundColor: 'बटन पृष्ठभूमि रंग',
          contactNumber: 'संपर्क संख्या',
          entryMessage: 'प्रवेश संदेश',
          buttonText: 'टेक्स्ट बटन',
          selectButtonText: 'बटन पाठ का चयन करें',
          buttonSize: 'बटन के आकार',
          selectOne: 'एक का चयन करें',
          placement: 'प्लेसमेंट',
          rightBottom: 'दाहिने नीचे',
          optInFlow: 'ऑप्ट-इन प्रवाह',
          selectFlow: 'प्रवाह का चयन करें',
          addSubscriberToSequence: 'अनुक्रम में ग्राहक जोड़ें',
          selectDrip: 'ड्रिप का चयन करें',
          whenDoesItDisplay: 'यह कब प्रदर्शित करता है (सेकंड)',
          showWidgetToSameUserAgainAfter: 'उसी उपयोगकर्ता को फिर से विजेट दिखाएं',
          ifManuallyClosedByUser: 'यदि उपयोगकर्ता द्वारा मैन्युअल रूप से बंद है, तो बाद में दिखाएं',
          specificPosts: 'विशिष्ट पद',
          allPosts: 'सभी पद',
          postMessage: 'संदेश भेजें',
          postDate: 'पोस्ट करने की तारीख',
          status: 'दर्जा',
          action: 'कार्य',
          discard: 'खारिज करना',
          saveChanges: 'परिवर्तनों को सुरक्षित करें',
          nextStep: 'अगला कदम',
          widgetDescription: 'विजेट विवरण',
          integrateTheBarWidget: 'अपनी वेबसाइट के बॉडी में बार विजेट को एकीकृत करें और अपने पेज सब्सक्राइबर को आगे बढ़ाने के साथ -साथ कस्टम बॉट मैसेज फ्लो भेजें।',
          hereIsYourWidgetHeadline: 'यहां आपका विजेट हेडलाइन है, इसे बदलने के लिए यहां क्लिक करें।',
          sendUsMessage: 'हमें संदेश भेजें',
          weAlsoPutDefaultTextHere: 'हम यहां डिफ़ॉल्ट पाठ भी डालते हैं। सुनिश्चित करें कि इसे मूल्यवान संदेश में बदल दें',
          selectYourPagePost: 'अपना पेज पोस्ट चुनें',
          nA: '-',
          saveRule: 'नियम बचाओ',
          placeholders: {
            enterWidgetName: 'विजेट नाम दर्ज करें',
            enterNumber: 'नंबर डालें',
            enterMessageHere: 'यहां संदेश दर्ज करें',
            enterTimeInSeconds: 'सेकंड में समय दर्ज करें'
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'अपने बॉट की ट्रिगर सेटिंग्स को अनुकूलित करें',
      makeYourBotsTimingPerfect: 'अपने बॉट के समय को एकदम सही बनाएं।',
      onDesktopTriggerTime: 'ऑन-डेस्कटॉप ट्रिगर समय (सेकंड)',
      onMobileTriggerTime: 'ऑन-मोबाइल ट्रिगर समय (सेकंड)',
      chatBubble: 'चैट बबल स्वचालित उद्घाटन',
      seconds: 'सेकंड',
      discard: 'खारिज करना',
      saveChanges: 'परिवर्तनों को सुरक्षित करें',
      autoTriggerOnOrOff: 'ऑटो ट्रिगर: चालू/बंद',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      alwaysOpenAfter: 'हमेशा ऊपर निर्दिष्ट ट्रिगर के बाद खोलें',
      automaticallyAfterSelectedNumberOfTime: 'समय की चयनित राशि के बाद स्वचालित रूप से',
      neverAutomaticallyOpenBot: 'कभी भी बॉट न खोलें',
      placeholders: {
        enterTimeInSeconds: 'सेकंड में समय दर्ज करें'
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'सेकंड में प्रतीक्षा समय, विज़िटर के डेस्कटॉप उपकरणों पर, इससे पहले कि आपका बॉट स्वचालित रूप से पॉप अप हो जाए',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'सेकंड में प्रतीक्षा समय, आगंतुक के मोबाइल पर, आपके बॉट से पहले स्वचालित रूप से पॉप अप हो जाता है',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'जब आगंतुक चैट विंडो को बंद कर देता है, तो चुनें कि बॉट ऑटो पॉप-अप व्यवहार कैसे होगा',
        customerWaitingTimeToOpenBubble: 'चैट बबल खोलने के लिए ग्राहक प्रतीक्षा समय',
        wantToDisableAutoTriggering: 'यदि आप वेबपेजों पर बॉट के ऑटो ट्रिगर को अक्षम करना चाहते हैं तो क्लिक करें',
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'अपने बॉट की उपयोगकर्ता सेटिंग्स को अनुकूलित करें',
      decideWhoCanAccessYourBot: 'तय करें कि आपके बॉट तक कौन एक्सेस कर सकता है',
      deviceSupport: 'युक्ति समर्थन',
      blockIpAddresses: 'ब्लॉक आईपी पते',
      hideChatBotOnSpecificPages: 'विशिष्ट पृष्ठों पर चैटबॉट छिपाएं',
      selectCountriesToEnableBot: 'बॉट को सक्षम करने के लिए देशों का चयन करें',
      discard: 'खारिज करना',
      saveChanges: 'परिवर्तनों को सुरक्षित करें',
      seeHowToUse: 'देखें कि कैसे उपयोग करें',
      allDevices: 'सभी उपकरणों',
      mobileOnly: 'केवल मोबाइल',
      desktopOnly: 'केवल डेस्कटॉप',
      placeholders: {
        enterIpAddress: 'आई पी पता दर्ज करें (आई पीv4 या आई पीv6) और एंटर दबाएँ',
        enterUrl: 'पृष्ठों का यू आर एल दर्ज करें और एंटर दबाएँ',
        searchCountry: 'खोज देश'
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'चुनें कि आपके बॉट को कौन से डिवाइस दिखाई देना चाहिए',
        TOOLTIP_BLOCK_IP_ADDRESS: 'आईपी ​​पते दर्ज करें जहां आप अपने बॉट को प्रदर्शित नहीं करना चाहते हैं',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'अपने वेबपेजों का यू आर एल दर्ज करें जहां आप अपने बॉट को प्रदर्शित नहीं करना चाहते हैं',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'उन स्थानों का चयन करें जहां आप उपयोगकर्ताओं को अपने बॉट तक पहुंचने के लिए चाहते हैं',
      }
    },
    facebook: {
      facebookBotSettings: 'फेसबुक बॉट सेटिंग्स',
      chatFlowsHeader: 'चैट प्रवाह',
      dripCampaignHeader: 'ड्रिप कैंपेन',
      broadcastHeader: 'प्रसारण',
      widgetsHeader: 'विजेट',
      autoReplyHeader: 'ऑटो-रिप्लाई',
      generalHeader: 'जनरल',
      facebookAutomation: 'फेसबुक ऑटो',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'चैट फ्लो टेम्प्लेट को कस्टमाइज़ करें, और विभिन्न लक्ष्य दर्शकों के लिए कई चैट फ्लो बनाएं।',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'अनुसूचित चैट प्रवाह के ड्रिप अभियान का उपयोग करके एक ग्राहक ऑनबोर्डिंग यात्रा बनाएं।',
      },
      broadcasts: {
        createBroadcast: 'प्रसारण बनाएँ',
        youCanCreateMultipleBroadcasts: 'आप कई प्रसारण बना सकते हैं',
        broadcastName: 'प्रसारण नाम',
        broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
        continue: 'जारी रखना',
        manageBroadcasts: 'प्रसारण प्रबंधित करें',
        youCanCreateMultipleBroadcastsWith: 'आप विभिन्न ग्राहक समूहों के लिए कई अनुसूचित टेम्प्लेट के साथ कई प्रसारण बना सकते हैं।',
        createNewBroadcast: 'नया प्रसारण बनाएं',
        name: 'नाम',
        segment: 'समूह',
        template: 'खाका',
        contacts: 'संपर्क',
        send: 'भेजना',
        failed: 'असफल',
        broadcastAt: 'प्रसारित करना',
        status: 'दर्जा',
        actions: 'कार्रवाई',
        nA: '-',
        deleteBroadcast: 'प्रसारण हटाएं',
        wantToDeleteThisBroadcast: 'क्या आप सुनिश्चित हैं कि आप इस प्रसारण को हटाना चाहते हैं?',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          searchHere: 'यहां तलाश करो'
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'कई प्रसारण हटाएं',
          deleteBroadcast: 'प्रसारण हटाएं'
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'प्रसारण नाम',
        broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
        flow: 'प्रवाह',
        segments: 'सेगमेंट',
        whenToSend: 'कब भेजना है',
        selectDate: 'तारीख़ चुनें',
        selectTimeSlot: 'टाइम स्लॉट का चयन करें',
        createBroadcast: 'प्रसारण बनाएँ',
        cancel: 'रद्द करना',
        now: 'अब',
        later: 'बाद में',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          selectFlow: 'प्रवाह का चयन करें',
          searchFlowByName: 'नाम से खोज प्रवाह',
          selectSegments: 'सेगमेंट का चयन करें',
          searchSegmentByName: 'नाम से खोज समूह',
          selectTimeSlot: 'टाइम स्लॉट का चयन करें'
        },
        indexMergeDatePicker: {
          selectStartDate: 'प्रारंभ तिथि का चयन करें'
        }
      },
      dripCampaigns: {
        createDripCampaign: 'ड्रिप अभियान बनाएँ',
        youCanCreateMultipleCampaign: 'आप कई अभियान बना सकते हैं',
        dripCampaignName: 'ड्रिप अभियान नाम',
        dripCampaignNameIsRequired: 'ड्रिप अभियान का नाम आवश्यक है',
        continue: 'जारी रखना',
        manageDripCampaign: 'ड्रिप अभियान प्रबंधित करें',
        youCanCreateMutlipleDripCampaign: 'आप विशिष्ट उपयोगकर्ता समूहों से प्रतिक्रियाओं को ट्रिगर करने के लिए अनुसूचित, स्वचालित संदेशों के साथ कई ड्रिप अभियान बना सकते हैं।',
        createNewDripCampaign: 'नया ड्रिप अभियान बनाएं',
        campaignName: 'अभियान का नाम',
        appliedOn: 'पर लागू',
        segment: 'समूह',
        flow: 'प्रवाह',
        startDate: 'आरंभ करने की तिथि',
        action: 'कार्य',
        nA: '-',
        deleteDripCampaign: 'ड्रिप अभियान हटाएं',
        wantToDeleteThisDripCampaign: 'क्या आप सुनिश्चित हैं कि आप इस ड्रिप अभियानों को हटाना चाहते हैं?',
        placeholders: {
          enterDripCampaignName: 'ड्रिप अभियान का नाम दर्ज करें',
          searchByName: 'नाम से खोजें'
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'कई ड्रिप अभियान हटाएं',
          deleteDripCampaign: 'ड्रिप अभियान हटाएं'
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'ड्रिप अभियान नाम',
          appliedOn: 'पर लागू',
          segments: 'सेगमेंट',
          selectDate: 'तारीख़ चुनें',
          scheduleYourFlows: 'अपने प्रवाह को शेड्यूल करें',
          schedule: 'अनुसूची',
          flow: 'प्रवाह',
          sendAfter: 'के बाद भेज देना',
          cancel: 'रद्द करना',
          createDripCampaign: 'ड्रिप अभियान बनाएँ',
          newSubscriber: 'नएं ग्राहक',
          days: 'दिन',
          minutes: 'मिनट',
          hours: 'घंटे',
          placeholders: {
            enterDripName: 'ड्रिप नाम दर्ज करें',
            selectAppliedOn: 'पर लागू करें का चयन करें',
            selectSegment: 'समूह का चयन करें',
            searchByName: 'नाम से खोजें',
            selectTemplate: 'टेम्पलेट का चयन करें',
            searchFlowByName: 'नाम से खोज प्रवाह',
            enterSendAfter: 'इसके बाद भेजें'
          },
          dateTimePicker: {
            selectStartDate: 'प्रारंभ तिथि का चयन करें'
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'चैट आईडी',
        errorCode: 'त्रुटि कोड',
        errorMessage: 'त्रुटि संदेश',
        nA: '-'
      },
      flows: {
        chatFlows: 'चैट धाराएँ',
        createChatFlow: 'चैट प्रवाह बनाएं',
        youCanCreateMultipleChatFlows: 'आप ए/बी परीक्षण और प्रसारण के लिए कई चैनलों में कई वार्तालाप प्रवाह बना सकते हैं।',
        flowName: 'प्रवाह नाम',
        nameIsRequired: 'नाम आवश्यक है',
        cancel: 'रद्द करना',
        createFlow: 'प्रवाह बनाएँ',
        importFlow: 'आयात प्रवाह',
        createNewFlow: 'नया प्रवाह बनाएं',
        ofMessages: 'संदेशों की',
        createdOn: 'पर बनाया',
        lastModified: 'अंतिम बार संशोधित',
        defaultFlow: 'डिफ़ॉल्ट प्रवाह',
        action: 'कार्य',
        exportStatus: 'निर्यात की स्थिति',
        flowExport: 'प्रवाह-निर्यात',
        download: 'डाउनलोड करना',
        inProgress: ' चल रहा',
        available: ' उपलब्ध',
        initiated: ' शुरू किया',
        beforeYouStartUploadPleaseMakeSure: 'इससे पहले कि आप अपलोड शुरू करें कृपया सुनिश्चित करें',
        point1: '1. अधिकतम फ़ाइल आकार अपलोड सीमा 20MB है',
        point2: '2. फ़ाइल प्रारूप JSON में होना चाहिए',
        selectFile: 'फ़ाइल का चयन करें',
        placeholders: {
          enterFlowName: 'प्रवाह नाम दर्ज करें'
        },
        appTooltip: {
          importFlowForFacebookBot: 'फेसबुक बॉट के लिए आयात प्रवाह',
          dateAndTimeOfCreationOfChatFlow: 'चैट प्रवाह के निर्माण की दिनांक और समय',
          dateAndTimeWhenChatFlowLastModified: 'दिनांक और समय जब चैट प्रवाह को अंतिम बार संशोधित किया गया था',
          TOOLTIP_CREATE_FLOW: 'एक नया चैट प्रवाह बनाने के लिए क्लिक करें',
          TOOLTIP_FLOW_NAME: 'चैट फ्लो नाम दर्ज करें',
          TOOLTIP_FLOW_TABLE_NAME: 'चैट प्रवाह का नाम',
          TOOLTIP_NO_OF_MESSAGES: 'चैट प्रवाह में संदेश घटकों की संख्या',
          TOOLTIP_DEFAULT_FLOW: 'डिफ़ॉल्ट के रूप में एक चैट प्रवाह सेट करने के लिए इस टॉगल का उपयोग करें, जो सभी नए उपयोगकर्ताओं को परोसा जाएगा।',
          TOOLTIP_ACTION: 'चैट प्रवाह को संपादित या हटाएं',
        },
        noDataFound: {
          flows: 'प्रवाह',
          noFlowsAddedYet: 'अभी तक कोई प्रवाह नहीं जोड़ा गया!'
        },
        modal: {
          deleteFlow: 'प्रवाह को हटा दें',
          wantToDeleteThisFlow: 'क्या आप सुनिश्चित हैं कि आप इस प्रवाह को हटाना चाहते हैं?'
        }
      },
      widgets: {
        manageWidgets: 'विजेट प्रबंधित करें',
        widgetsAreRoutingTools: 'बॉट के उपयोग को बढ़ाने के लिए विजेट रूटिंग उपकरण हैं',
        needHelp: 'मदद की ज़रूरत है?',
        wantToMakeChanges: 'परिवर्तन करना चाहते हैं?',
        editIcon: 'आइकन संपादित करें',
        discard: 'खारिज करना',
        updateWidget: 'अद्यतन विजेट',
        installChatBotIconOnYourWebsite: 'अपनी वेबसाइट पर चैटबॉट आइकन स्थापित करें',
        shareWhereEverYouWantToRedirectUsersTo:
          'जहां भी आप चाहते हैं और सिंगल क्लिक में अपने फेसबुक बॉट पर उपयोगकर्ताओं को रीडायरेक्ट करें',
        copyAndAddInYourWebsiteHeaderFile: 'अपनी वेबसाइट हेडर फ़ाइल में कॉपी और जोड़ें',
        copyLinkAndStartSharing: 'कॉपी लिंक और साझा करना शुरू करें!',
        downloadQrCodeAndStartSharing: 'क्यूआर कोड डाउनलोड करें और साझा करना शुरू करें!',
        addCodeAndDeployNewCodeAndDone: 'कोड जोड़ें, नया कोड तैनात करें और किया!',
        wantToLearnHowToUseIt: 'सीखना चाहते हैं कि इसका उपयोग कैसे करें?',
        copyChatBotCode: 'चैट बोट कोड कॉपी करें',
        copyFacebookLink: 'फेसबुक लिंक कॉपी करें',
        downloadQrCode: 'क्यूआर कोड डाउनलोड करें',
        link: 'जोड़ना',
        chatBotIcon: 'चैटबोट आइकन',
        qrcode: 'क्यू आर संहिता',
        small: 'छोटा',
        medium: 'मध्यम',
        large: 'बड़ा',
        leftMiddle: 'बायां मध्य',
        rightMiddle: 'दाहिने मध्य',
        leftBottom: 'बायां नीचे',
        rightBottom: 'दाहिने नीचे',
        bpDropDown: {
          selectIcons: 'आइकन का चयन करें',
          iconSize: 'आइकन आकार',
          iconPlacement: 'आइकन प्लेसमेंट'
        }
      },
      autoReply: {
        autoReplyTitle: 'ऑटो-रिप्लाई',
        configureYour: 'अपना ',
        message: 'संदेश कॉन्फ़िगर करें',
        customizeAutoReplyMessagesForYourPostsComments: 'अपने पोस्ट के टिप्पणियों के लिए ऑटो-रिप्लाई संदेश परिवर्तित करें',
        wouldYouLikeToApply: 'क्या आप सभी पोस्ट पर ',
        autoreply: 'ऑटो-रिप्लाई ',
        onAllPost: ' लागू करना चाहेंगे?',
        configureYourMessageForEachPost: 'प्रत्येक पोस्ट के लिए अपना संदेश कॉन्फ़िगर करें',
        configureFlowToTriggerAfterOnReply: 'रिप्लाई के बाद फ़्लो कॉन्फ़िगर करें',
        whenUserLeavesACommentSendMessageAfter: 'जब उपयोगकर्ता टिप्पणी छोड़ता है, तो उसके बाद संदेश भेजें',
        triggerFlowOnReply: 'रिप्लाई पर फ़्लो ट्रिगर करें',
        add: 'जोड़ें',
        update: 'अपडेट करें',
        save: 'सहेजें',
        cancel: 'रद्द करें ',
        discard: 'रद्द करें ',
        bpDropDown: {
          selectPost: 'पोस्ट का चयन करें',
          triggerFlowOnReply: 'रिप्लाई पर फ़्लो ट्रिगर करें',
          immendiately: 'तुरंत',
          minutes: 'मिनट'
        },
        bpTable: {
          message: 'संदेश',
          selectedPost: 'चयनित पोस्ट',
          triggerFlow: 'फ्लो ट्रिगर',
          actions: 'क्रियाएँ'
        },
        placeholder: {
          configureYourMessage: 'अपना संदेश कॉन्फ़िगर करें'
        },
        deleteModel: {
          title: 'पोस्ट ऑटो-रिप्लाई हटाएं',
          description: 'क्या आप इस पोस्ट ऑटो-रिप्लाई को हटाना चाहते हैं?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'सत्र समय समाप्ति',
        enterSessionTimeOutWindowInHours: 'सत्र समय समाप्ति विंडो में घंटों में प्रवेश करें',
        sendInactivityMessage: 'निष्क्रियता संदेश भेजें',
        sessionTimeOutInHoursTooltip: `अप्रसंगता के कारण, चैट सत्र स्वचालित रूप से कितने घंटों (में) के बाद समाप्त हो जाएगा, इसे दर्ज करें। चैटफ्लो यदि उपयोगकर्ता सत्र समय समाप्त होने के बाद किसी भी संदेश को भेजता है, तो पुनः प्रारंभ होगा`,
        restartChatFlowTriggers: 'चैट फ़्लो ट्रिगर्स को पुनरारंभ करें',
        enterKeyWordsToRestartChatAndhitEnter: 'चैट को पुनरारंभ करने के लिए कीवर्ड दर्ज करें और Enter दबाएं',
        restartChatFlowTriggersTooltip: `यहां विशिष्ट कीवर्ड दर्ज करें जो, जब उपयोगकर्ता द्वारा दर्ज किए जाएं और प्रस्तुत किए जाएं, तो चैट फ़्लो को पुनरारंभ करेंगे। इससे उपयोगकर्ता किसी भी समय बातचीत को पुनरारंभ कर सकते हैं।
         कई कीवर्ड जोड़ने के बाद Enter दबाएं।`,
        liveChatTriggers: 'लाइव चैट ट्रिगर्स',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'लाइव चैट को ट्रिगर करने के लिए कीवर्ड दर्ज करें और Enter दबाएं',
        liveChatTriggersTooltip: `यहां उपयोगकर्ता द्वारा दर्ज किए जाने पर जब वे चैट के स्वतंत्र मोड पर जाने के लिए शब्द दर्ज करेंगे और प्रस्तुत करेंगे, तो स्वचालित चैट से लाइव चैट का आरंभ होगा। यह उपयोगकर्ताओं को जब वे लाइव एजेंट से सहायता की आवश्यकता होती है, तो उपयोगकर्ता को अनुमति देता है।
         प्रत्येक कीवर्ड जोड़ने के बाद Enter दबाएं।`,
        messageAfterAutomatedFlowCompleted: 'स्वचालित फ़्लो पूरा होने के बाद संदेश',
        enterYourMessage: 'अपना संदेश दर्ज करें',
        messageAfterAutomatedFlowCompletedTooltip: `इसमें एक संदेश दर्ज करें जो उपयोगकर्ता को प्रदर्शित किया जाएगा जब वे स्वचालित चैट फ़्लो को पूरा कर चुके हैं, लेकिन सत्र समय समाप्त नहीं हुआ है।`,
        restart: 'पुनरारंभ',
        liveChat: 'लाइव चैट',
        lineOneForDefaultMessage: 'यदि आपके पास और सवाल हैं या मदद की आवश्यकता है, तो यहां आपके बाद क्या कर सकते हैं:',
        to: 'को',
        restartTheChat: 'चैट को पुनरारंभ करें',
        simpleTypeRestartAndHitEnter: `बस 'पुनरारंभ' लिखें और Enter दबाएं। इससे आपको हमारी बातचीत के शुरुआत पर वापस ले जाया जाएगा।`,
        lineThreeForDefaultMessagePartOne: `यदि आप बात करना चाहते हैं `,
        liveAgent: `लाइव एजेंट से,`,
        lineThreeForDefaultMessageLastPart: `तो 'लाइव चैट' लिखें और Enter दबाएं। हमारी टीम आपकी मदद के लिए वास्तविक समय में यहां है।`,
        saveChanges: 'बदलाव सहेजें',
        discard: 'रद्द करें',
        configureBotsGeneralSettings: 'अपने बॉट की सामान्य सेटिंग्स कॉन्फ़िगर करें',
        minutes: 'मिनट',
        hours: 'घंटे',
      }
    },
    instagram: {
      instagramBotSettings: 'इंस्टाग्राम बॉट सेटिंग्स',
      chatFlowsHeader: 'चैट प्रवाह',
      broadcastHeader: 'प्रसारण',
      dripCampaignHeader: 'ड्रिप कैंपेन',
      widgetsHeader: 'विजेट',
      alertsHeader: 'अलर्ट',
      alerts: {
        configureYourBotsSettings: 'अपने बॉट की अलर्ट सेटिंग्स को कॉन्फ़िगर करें',
        specifyHowYourBotNotifyYou: 'निर्दिष्ट करें कि आपका बॉट आपको नए लीड के बारे में कैसे सूचित करेगा।',
        subject: 'विषय',
        emailNotification: 'ई - मेल अधिसूचना',
        emailAddresses: 'ईमेल पते',
        leadRevisitNotifications: 'लीड रिविसिट नोटिफिकेशन',
        whenToSendLeadInfo: 'लीड जानकारी कब भेजने के लिए',
        discard: 'खारिज करना',
        saveChanges: 'परिवर्तनों को सुरक्षित करें',
        placeholders: {
          enterSubjectForYourEmail: 'अपने ईमेल के लिए विषय दर्ज करें',
          typeYourEmailAddressAndHitEnter: 'अपना ईमेल पता टाइप करें और एंटर को हिट करें'
        }
      },
      broadcasts: {
        createBroadcast: 'प्रसारण बनाएँ',
        youCanCreateMultipleBroadcasts: 'आप कई प्रसारण बना सकते हैं',
        broadcastName: 'प्रसारण नाम',
        broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
        continue: 'जारी रखना',
        manageBroadcasts: 'प्रसारण प्रबंधित करें',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'आप विभिन्न ग्राहक समूहों के लिए कई अनुसूचित टेम्प्लेट के साथ कई प्रसारण बना सकते हैं।',
        createNewBroadcast: 'नया प्रसारण बनाएं',
        segment: 'समूह',
        template: 'खाका',
        contacts: 'संपर्क',
        send: 'भेजना',
        failed: 'असफल',
        broadcastAt: 'प्रसारित करना',
        status: 'दर्जा',
        actions: 'कार्रवाई',
        nA: '-',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          searchHere: 'यहां तलाश करो'
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'कई प्रसारण हटाएं',
          deleteBroadcast: 'प्रसारण हटाएं'
        }
      },
      createBroadcast: {
        broadcastName: 'प्रसारण नाम',
        broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
        flow: 'प्रवाह',
        segments: 'सेगमेंट',
        whenToSend: 'कब भेजना है',
        selectDate: 'तारीख़ चुनें',
        selectTimeSlot: 'टाइम स्लॉट का चयन करें',
        cancel: 'रद्द करना',
        createBroadcast: 'प्रसारण बनाएँ',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          selectFlow: 'प्रवाह का चयन करें',
          searchFlowByName: 'नाम से खोज प्रवाह',
          selectSegments: 'सेगमेंट का चयन करें',
          searchSegmentByName: 'नाम से खोज समूह',
          selectTimeSlot: 'टाइम स्लॉट का चयन करें'
        },
        indexMergeDatePicker: {
          selectStartDate: 'प्रारंभ तिथि का चयन करें'
        }
      },
      dripCampaigns: {
        createDripCampaign: 'ड्रिप अभियान बनाएँ',
        youCanCreateMultipleCampaigns: 'आप कई अभियान बना सकते हैं',
        dripCampaignName: 'ड्रिप अभियान नाम',
        dripCampaignNameIsRequired: 'ड्रिप अभियान का नाम आवश्यक है',
        continue: 'जारी रखना',
        manageDripCampaign: 'ड्रिप अभियान प्रबंधित करें',
        youCanCreateMultipleCampaignsWith: 'आप विशिष्ट उपयोगकर्ता समूहों से प्रतिक्रियाओं को ट्रिगर करने के लिए अनुसूचित, स्वचालित संदेशों के साथ कई ड्रिप अभियान बना सकते हैं।',
        createNewDripCampaign: 'नया ड्रिप अभियान बनाएं',
        campaignName: 'अभियान का नाम',
        appliedOn: 'पर लागू',
        segment: 'समूह',
        flow: 'प्रवाह',
        startDate: 'आरंभ करने की तिथि',
        action: 'कार्य',
        nA: '-',
        placeholders: {
          enterDripCampaignName: 'ड्रिप अभियान का नाम दर्ज करें',
          searchByName: 'नाम से खोजें'
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'कई ड्रिप अभियान हटाएं',
          deleteDripCampaign: 'ड्रिप अभियान हटाएं'
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'ड्रिप अभियान नाम',
          appliedOn: 'पर लागू',
          segments: 'सेगमेंट',
          selectDate: 'तारीख़ चुनें',
          scheduleYourFlows: 'अपने प्रवाह को शेड्यूल करें',
          flow: 'प्रवाह',
          sendAfter: 'के बाद भेज देना',
          cancel: 'रद्द करना',
          createDripCampaign: 'ड्रिप अभियान बनाएँ',
          placeholders: {
            enterDripName: 'ड्रिप नाम दर्ज करें',
            selectAppliedOn: 'पर लागू करें का चयन करें',
            selectSegment: 'समूह का चयन करें',
            searchByName: 'नाम से खोजें',
            selectTemplate: 'टेम्पलेट का चयन करें',
            searchFlowByName: 'नाम से खोज प्रवाह',
            enterSendAfter: 'इसके बाद भेजें'
          },
          dateTimePicker: {
            selectStartDate: 'प्रारंभ तिथि का चयन करें'
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'चैट आईडी',
        errorCode: 'त्रुटि कोड',
        errorMessage: 'त्रुटि संदेश',
        nA: '-'
      },
      flows: {
        chatFlows: 'चैट धाराएँ',
        createChatFlow: 'चैट प्रवाह बनाएं',
        youCanCreateMultipleChatFlows: 'आप ए/बी परीक्षण और प्रसारण के लिए कई चैनलों में कई वार्तालाप प्रवाह बना सकते हैं।',
        flowName: 'प्रवाह नाम',
        nameIsRequired: 'नाम आवश्यक है',
        cancel: 'रद्द करना',
        createFlow: 'प्रवाह बनाएँ',
        importFlow: 'आयात प्रवाह',
        createNewFlow: 'नया प्रवाह बनाएं',
        ofMessages: ' संदेशों की',
        createdOn: 'पर बनाया',
        lastModified: 'अंतिम बार संशोधित',
        defaultFlow: 'डिफ़ॉल्ट प्रवाह',
        action: 'कार्य',
        exportStatus: 'निर्यात की स्थिति',
        flowExport: 'प्रवाह-निर्यात',
        download: 'डाउनलोड करना',
        beforeYouStartUploadPleaseMakeSure: 'इससे पहले कि आप अपलोड शुरू करें कृपया सुनिश्चित करें',
        point1: '1. अधिकतम फ़ाइल आकार अपलोड सीमा 20MB है',
        point2: '2. फ़ाइल प्रारूप JSON में होना चाहिए',
        selectFile: 'फ़ाइल का चयन करें',
        placeholders: {
          enterFlowName: 'प्रवाह नाम दर्ज करें'
        },
        appTooltip: {
          importFlowForInstagramBot: 'इंस्टाग्राम बॉट के लिए आयात प्रवाह',
          dateAndTimeOfCreationOfChatFlow: 'चैट प्रवाह के निर्माण की दिनांक और समय',
          dateAndTimeWhenChatFlowLastModified: 'दिनांक और समय जब चैट प्रवाह को अंतिम बार संशोधित किया गया था'
        },
        noDataFound: {
          flows: 'प्रवाह',
          noFlowsAddedYet: 'अभी तक कोई प्रवाह नहीं जोड़ा गया!'
        },
        modal: {
          deleteFlow: 'प्रवाह को हटा दें',
          wantToDeleteThisFlow: 'क्या आप सुनिश्चित हैं कि आप इस प्रवाह को हटाना चाहते हैं?'
        }
      },
      widgets: {
        manageWidgets: 'विजेट प्रबंधित करें',
        widgetsAreRoutingTools: 'बॉट के उपयोग को बढ़ाने के लिए विजेट रूटिंग उपकरण हैं',
        needHelp: 'मदद की ज़रूरत है?',
        wantToMakeChanges: 'परिवर्तन करना चाहते हैं?',
        editIcon: 'आइकन संपादित करें',
        discard: 'खारिज करना',
        updateWidget: 'अद्यतन विजेट',
        bpDropDown: {
          selectIcons: 'आइकन का चयन करें',
          iconSize: 'प्रतीक आकार',
          iconPlacement: 'आइकन प्लेसमेंट'
        }
      }
    },
    telegram: {
      telegramBotSettings: 'टेलीग्राम बॉट सेटिंग्स',
      chatFlowsHeader: 'चैट प्रवाह',
      telegramProfileHeader: 'टेलीग्राम -प्रोफाइल',
      widgetsHeader: 'विजेट',
      broadcastHeader: 'प्रसारण',
      dripCampaignHeader: 'ड्रिप कैंपेन',
      generalHeader: 'जनरल',
      telegramAutomation: 'टेलीग्राम ऑटो',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'अनुसूचित चैट प्रवाह के ड्रिप अभियान का उपयोग करके एक ग्राहक ऑनबोर्डिंग यात्रा बनाएं।',
        TOOLTIP_CHAT_FLOW_TITLE: 'चैट फ्लो टेम्प्लेट को कस्टमाइज़ करें, और विभिन्न लक्ष्य दर्शकों के लिए कई चैट फ्लो बनाएं।',
      },
      broadcasts: {
        createBroadcast: 'प्रसारण बनाएँ',
        youCanCreateMutlipleBroadcasts: 'आप कई प्रसारण बना सकते हैं',
        broadcastName: 'प्रसारण नाम',
        broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
        continue: 'जारी रखना',
        manageBroadcasts: 'प्रसारण प्रबंधित करें',
        youCanCreateMultipleBroadcastsWith: 'आप विभिन्न ग्राहक समूहों के लिए कई अनुसूचित टेम्प्लेट के साथ कई प्रसारण बना सकते हैं।',
        createNewBroadcast: 'नया प्रसारण बनाएं',
        name: 'नाम',
        segment: 'समूह',
        template: 'खाका',
        contacts: 'संपर्क',
        send: 'भेजना',
        failed: 'असफल',
        broadcastAt: 'प्रसारित करना',
        status: 'दर्जा',
        actions: 'कार्रवाई',
        nA: '-',
        deleteBroadcast: 'प्रसारण हटाएं',
        wantToDeleteThisBroadcast: 'क्या आप सुनिश्चित हैं कि आप इस प्रसारण को हटाना चाहते हैं?',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          searchHere: 'यहां तलाश करो'
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'कई प्रसारण हटाएं',
          deleteBroadcast: 'प्रसारण हटाएं'
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'प्रसारण नाम',
        broadcastNameIsRequired: 'प्रसारण नाम आवश्यक है',
        flow: 'प्रवाह',
        segments: 'सेगमेंट',
        whenToSend: 'कब भेजना है',
        selectDate: 'तारीख़ चुनें',
        selectTimeSlot: 'टाइम स्लॉट का चयन करें',
        cancel: 'रद्द करना',
        createBroadcast: 'प्रसारण बनाएँ',
        now: 'अब',
        later: 'बाद में',
        placeholders: {
          enterBroadcastName: 'प्रसारण नाम दर्ज करें',
          selectFlow: 'प्रवाह का चयन करें',
          searchFlowByName: 'नाम से खोज प्रवाह',
          selectSegments: 'सेगमेंट का चयन करें',
          searchSegmentByName: 'नाम से खोज समूह',
          selectTimeSlot: 'टाइम स्लॉट का चयन करें'
        },
        indexMergeDatePicker: {
          selectStartDate: 'प्रारंभ तिथि का चयन करें'
        }
      },
      dripCampaigns: {
        createDripCampaign: 'ड्रिप अभियान बनाएँ',
        youCanCreateMultipleCampaigns: 'आप कई अभियान बना सकते हैं',
        dripCampaignName: 'ड्रिप अभियान नाम',
        dripCampaignNameIsRequired: 'ड्रिप अभियान का नाम आवश्यक है',
        continue: 'जारी रखना',
        manageDripCampaign: 'ड्रिप अभियान प्रबंधित करें',
        youCanCreateMultipleDripCampaignsWith: 'आप विशिष्ट उपयोगकर्ता समूहों से प्रतिक्रियाओं को ट्रिगर करने के लिए अनुसूचित, स्वचालित संदेशों के साथ कई ड्रिप अभियान बना सकते हैं।',
        createNewDripCampaign: 'नया ड्रिप अभियान बनाएं',
        campaignName: 'अभियान का नाम',
        appliedOn: 'पर लागू',
        segment: 'समूह',
        flow: 'प्रवाह',
        startDate: 'आरंभ करने की तिथि',
        action: 'कार्य',
        nA: '-',
        deleteDripCampaign: 'ड्रिप अभियान हटाएं',
        wantToDeleteThisDripCampaign: 'क्या आप सुनिश्चित हैं कि आप इस ड्रिप अभियानों को हटाना चाहते हैं?',
        placeholders: {
          enterDripCampaignName: 'ड्रिप अभियान का नाम दर्ज करें',
          searchByName: 'नाम से खोजें'
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'कई ड्रिप अभियान हटाएं',
          deleteDripCampaign: 'ड्रिप अभियान हटाएं'
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'ड्रिप अभियान नाम',
          appliedOn: 'पर लागू',
          segments: 'सेगमेंट',
          startDate: 'तारीख़ चुनें',
          scheduleYourFlows: 'अपने प्रवाह को शेड्यूल करें',
          schedule: 'अनुसूची',
          flow: 'प्रवाह',
          sendAfter: 'के बाद भेज देना',
          cancel: 'रद्द करना',
          createDripCampaign: 'ड्रिप अभियान बनाएँ',
          newSubscriber: 'नया ग्राहक',
          days: 'दिन',
          minutes: 'मिनट',
          hours: 'घंटे',
          placeholders: {
            enterDripName: 'ड्रिप नाम दर्ज करें',
            selectAppliedOn: 'पर लागू करें का चयन करें',
            selectSegment: 'समूह का चयन करें',
            searchByName: 'नाम से खोजें',
            selectFlow: 'प्रवाह का चयन करें',
            searchFlowByName: 'नाम से खोज प्रवाह'
          },
          dateTimePicker: {
            selectStartDate: 'प्रारंभ तिथि का चयन करें'
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'नाम',
        email: 'ईमेल',
        phone: 'फ़ोन',
        errorCode: 'त्रुटि कोड',
        errorMessage: 'त्रुटि संदेश',
        nA: '-'
      },
      flows: {
        chatFlows: 'चैट धाराएँ',
        createChatFlow: 'चैट प्रवाह बनाएं',
        youCanCreateMultipleChatFlows: 'आप ए/बी परीक्षण और प्रसारण के लिए कई चैनलों में कई वार्तालाप प्रवाह बना सकते हैं।',
        flowName: 'प्रवाह नाम',
        nameIsRequired: 'नाम आवश्यक है',
        cancel: 'रद्द करना',
        createFlow: 'प्रवाह बनाएँ',
        importFlow: 'आयात प्रवाह',
        createNewFlow: 'नया प्रवाह बनाएं',
        ofMessages: ' संदेशों की',
        createdOn: 'पर बनाया',
        lastModified: 'अंतिम बार संशोधित',
        defaultFlow: 'डिफ़ॉल्ट प्रवाह',
        action: 'कार्य',
        exportStatus: 'निर्यात की स्थिति',
        flowExport: 'प्रवाह-निर्यात',
        beforeYouStartUploadPleaseMakeSure: 'इससे पहले कि आप अपलोड शुरू करें कृपया सुनिश्चित करें',
        point1: '1. अधिकतम फ़ाइल आकार अपलोड सीमा 20MB है',
        point2: '2. फ़ाइल प्रारूप JSON में होना चाहिए',
        selectFile: 'फ़ाइल का चयन करें',
        inProgress: ' चल रहा',
        available: ' उपलब्ध',
        initiated: ' शुरू किया',
        download: 'डाउनलोड करना',
        placeholders: {
          enterFlowName: 'प्रवाह नाम दर्ज करें'
        },
        appTooltip: {
          importFlowForTelegramBot: 'इंस्टाग्राम बॉट के लिए आयात प्रवाह',
          dateAndTimeOfCreationOfChatFlow: 'चैट प्रवाह के निर्माण की दिनांक और समय',
          dateAndTimeWhenChatFlowLastModified: 'दिनांक और समय जब चैट प्रवाह को अंतिम बार संशोधित किया गया था',
          TOOLTIP_CREATE_FLOW: 'एक नया चैट प्रवाह बनाने के लिए क्लिक करें',
          TOOLTIP_FLOW_NAME: 'चैट फ्लो नाम दर्ज करें',
          TOOLTIP_FLOW_TABLE_NAME: 'चैट प्रवाह का नाम',
          TOOLTIP_NO_OF_MESSAGES: 'चैट प्रवाह में संदेश घटकों की संख्या',
          TOOLTIP_DEFAULT_FLOW: 'डिफ़ॉल्ट के रूप में एक चैट प्रवाह सेट करने के लिए इस टॉगल का उपयोग करें, जो सभी नए उपयोगकर्ताओं को परोसा जाएगा।',
          TOOLTIP_ACTION: 'चैट प्रवाह को संपादित या हटाएं',
        },
        noDataFound: {
          flows: 'प्रवाह',
          noFlowsAddedYet: 'अभी तक कोई प्रवाह नहीं जोड़ा गया!'
        },
        modal: {
          deleteFlow: 'प्रवाह को हटा दें',
          wantToDeleteThisFlow: 'क्या आप सुनिश्चित हैं कि आप इस प्रवाह को हटाना चाहते हैं?'
        }
      },
      telegramProfile: {
        telegramProfile: 'टेलीग्राम -प्रोफाइल',
        manageYourBotProfile: 'जब आप चाहते हैं तो अपने बॉट प्रोफाइल को प्रबंधित करें।',
        allSetNoActionRequired: 'सभी सेट, कोई कार्रवाई की आवश्यकता नहीं है!',
        congratulationYourAccountIsLive: 'बधाई हो! आपका खाता पूर्ण पहुंच के साथ लाइव है',
        botDetails: 'बॉट विवरण',
        name: 'नाम',
        nA: '-',
        b: 'बी',
        botName: 'बॉट नाम',
        bot: 'बॉट',
        info: 'जानकारी',
        none: 'कोई नहीं',
        username: 'उपयोगकर्ता नाम',
        notifications: 'अधिसूचना',
        on: 'पर',
        addToGroupOrChannel: 'समूह या चैनल में जोड़ें',
        userName: 'उपयोगकर्ता नाम',
        botToken: 'बॉट टोकन',
      },
      widgets: {
        manageWidgets: 'विजेट प्रबंधित करें',
        widgetsAreRoutingTools: 'बॉट के उपयोग को बढ़ाने के लिए विजेट रूटिंग उपकरण हैं',
        needHelp: 'मदद की ज़रूरत है?',
        wantToMakeChanges: 'परिवर्तन करना चाहते हैं?',
        editIcon: 'आइकन संपादित करें',
        discard: 'खारिज करना',
        updateWidget: 'अद्यतन विजेट',
        installChatBotIconOnYourWebsite: 'अपनी वेबसाइट पर चैटबॉट आइकन स्थापित करें',
        shareWhereEverYouWantRedirectUsersTo: 'जहां भी आप चाहते हैं और एकल क्लिक में अपने टेलीग्राम बॉट पर उपयोगकर्ताओं को पुनर्निर्देशित करें',
        copyAndAddInYourWebsiteHeaderFile: 'अपनी वेबसाइट हेडर फ़ाइल में कॉपी और जोड़ें',
        copyLinkAndStartSharing: 'कॉपी लिंक और साझा करना शुरू करें!',
        downloadQrCodeAndStartSharing: 'क्यूआर कोड डाउनलोड करें और साझा करना शुरू करें!',
        addCodeAndDeployNewCodeAndDone: 'कोड जोड़ें, नया कोड तैनात करें और किया!',
        wantToLearnHowToUseIt: 'सीखना चाहते हैं कि इसका उपयोग कैसे करें?',
        copyChatBotCode: 'चैट बोट कोड कॉपी करें',
        downloadQrCode: 'क्यूआर कोड डाउनलोड करें',
        copyTelegramLink: 'प्रतिलिपि लिंक',
        link: 'जोड़ना',
        chatBotIcon: 'चैटबोट आइकन',
        qrcode: 'क्यू आर संहिता',
        small: 'छोटा',
        medium: 'मध्यम',
        large: 'बड़ा',
        leftMiddle: 'बायां मध्य',
        rightMiddle: 'दाहिने मध्य',
        leftBottom: 'बायां नीचे',
        rightBottom: 'दाहिने नीचे',
        bpDropDown: {
          selectIcons: 'आइकन का चयन करें',
          iconSize: 'आइकन आकार',
          iconPlacement: 'आइकन प्लेसमेंट'
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'सत्र समय समाप्ति',
        enterSessionTimeOutWindowInHours: 'सत्र समय समाप्ति विंडो में घंटों में प्रवेश करें',
        sendInactivityMessage: 'निष्क्रियता संदेश भेजें',
        sessionTimeOutInHoursTooltip: `अप्रसंगता के कारण, चैट सत्र स्वचालित रूप से कितने घंटों (में) के बाद समाप्त हो जाएगा, इसे दर्ज करें। चैटफ्लो यदि उपयोगकर्ता सत्र समय समाप्त होने के बाद किसी भी संदेश को भेजता है, तो पुनः प्रारंभ होगा`,
        restartChatFlowTriggers: 'चैट फ़्लो ट्रिगर्स को पुनरारंभ करें',
        enterKeyWordsToRestartChatAndhitEnter: 'चैट को पुनरारंभ करने के लिए कीवर्ड दर्ज करें और Enter दबाएं',
        restartChatFlowTriggersTooltip: `यहां विशिष्ट कीवर्ड दर्ज करें जो, जब उपयोगकर्ता द्वारा दर्ज किए जाएं और प्रस्तुत किए जाएं, तो चैट फ़्लो को पुनरारंभ करेंगे। इससे उपयोगकर्ता किसी भी समय बातचीत को पुनरारंभ कर सकते हैं।
         कई कीवर्ड जोड़ने के बाद Enter दबाएं।`,
        liveChatTriggers: 'लाइव चैट ट्रिगर्स',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'लाइव चैट को ट्रिगर करने के लिए कीवर्ड दर्ज करें और Enter दबाएं',
        liveChatTriggersTooltip: `यहां उपयोगकर्ता द्वारा दर्ज किए जाने पर जब वे चैट के स्वतंत्र मोड पर जाने के लिए शब्द दर्ज करेंगे और प्रस्तुत करेंगे, तो स्वचालित चैट से लाइव चैट का आरंभ होगा। यह उपयोगकर्ताओं को जब वे लाइव एजेंट से सहायता की आवश्यकता होती है, तो उपयोगकर्ता को अनुमति देता है।
         प्रत्येक कीवर्ड जोड़ने के बाद Enter दबाएं।`,
        messageAfterAutomatedFlowCompleted: 'स्वचालित फ़्लो पूरा होने के बाद संदेश',
        enterYourMessage: 'अपना संदेश दर्ज करें',
        messageAfterAutomatedFlowCompletedTooltip: `इसमें एक संदेश दर्ज करें जो उपयोगकर्ता को प्रदर्शित किया जाएगा जब वे स्वचालित चैट फ़्लो को पूरा कर चुके हैं, लेकिन सत्र समय समाप्त नहीं हुआ है।`,
        restart: 'पुनरारंभ',
        liveChat: 'लाइव चैट',
        lineOneForDefaultMessage: 'यदि आपके पास और सवाल हैं या मदद की आवश्यकता है, तो यहां आपके बाद क्या कर सकते हैं:',
        to: 'को',
        restartTheChat: 'चैट को पुनरारंभ करें',
        simpleTypeRestartAndHitEnter: `बस 'पुनरारंभ' लिखें और Enter दबाएं। इससे आपको हमारी बातचीत के शुरुआत पर वापस ले जाया जाएगा।`,
        lineThreeForDefaultMessagePartOne: `यदि आप बात करना चाहते हैं `,
        liveAgent: `लाइव एजेंट से,`,
        lineThreeForDefaultMessageLastPart: `तो 'लाइव चैट' लिखें और Enter दबाएं। हमारी टीम आपकी मदद के लिए वास्तविक समय में यहां है।`,
        saveChanges: 'बदलाव सहेजें',
        discard: 'रद्द करें',
        configureBotsGeneralSettings: 'अपने बॉट की सामान्य सेटिंग्स कॉन्फ़िगर करें',
        minutes: 'मिनट',
        hours: 'घंटे',
      }
    },
    whatsapp: {
      whatsappAutomation: 'व्हाट्सएप ऑटोमेशन',
      whatsappBotSettings: 'व्हाट्सएप बॉट सेटिंग्स',
      chatFlowsHeader: 'चैट प्रवाह',
      generalHeader: 'सामान्य',
      whatsappProfileHeader: 'व्हाट्सएप प्रोफाइल',
      broadcastHeader: 'प्रसारण',
      dripCampaignHeader: 'ड्रिप कैंपेन',
      widgetHeader: 'विजेट',
      templatesHeader: 'टेम्पलेट्स',
      alertsHeader: 'अलर्ट',
      apiKeyHeader: 'एपीआई कुंजी',
      catalogHeader: 'कैटलॉग',
      otherConfiguration: 'एआई सेटिंग्स',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'चैट फ्लो टेम्प्लेट को कस्टमाइज़ करें, और विभिन्न लक्ष्य दर्शकों के लिए कई चैट फ्लो बनाएं।',
      },
      alerts: {
        configureYourBotsAlertSettings: 'अपने बॉट की अलर्ट सेटिंग्स को कॉन्फ़िगर करें',
        specifyHowYouBotWillNotify: 'निर्दिष्ट करें कि आपका बॉट आपको नए लीड के बारे में कैसे सूचित करेगा।',
        subject: 'विषय',
        emailNotification: 'ई - मेल अधिसूचना',
        emailAddresses: 'ईमेल पते',
        leadRevisitNotifications: 'लीड रिविसिट नोटिफिकेशन',
        whenToSendLeadInfo: 'लीड जानकारी कब भेजने के लिए',
        discard: 'खारिज करना',
        saveChanges: 'परिवर्तनों को सुरक्षित करें',
        seeHowToUse: 'देखें कि कैसे उपयोग करें',
        beginningTheFlow: 'प्रवाह शुरू करना',
        fiveMinutesLater: '5 मिनट के बाद',
        endOfFlow: 'प्रवाह समाप्ति',
        placeholders: {
          enterSubjectForYourEmail: 'अपने ईमेल के लिए विषय दर्ज करें',
          typeEmailAndHitEnter: 'अपना ईमेल पता टाइप करें और एंटर को हिट करें'
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'बॉट से अपने ईमेल सूचनाओं के विषय को अनुकूलित करें',
          TOOLTIP_EMAIL_NOTIFICATION: 'नए लीड के लिए अपने ईमेल पर बॉट की अधिसूचना को सक्षम/अक्षम करें',
          TOOLTIP_EMAIL_ADDRESS: 'उस खाते का ईमेल पता सबमिट करें जहाँ आप ईमेल सूचनाएं प्राप्त करना चाहते हैं। आप एक दर्ज करके और दूसरे को जोड़ने के लिए एंटर दबाकर कई ईमेल पते जोड़ सकते हैं।',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' अपने ईमेल पर बॉट की अधिसूचना को सक्षम/अक्षम करें यदि कोई उपयोगकर्ता पहले एक लीड के रूप में कैप्चर किया गया था तो बॉट के साथ फिर से बातचीत करता है।',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'व्हाट्सएप चैटबॉट अपडेट करें',
        businessNameIsRequired: 'व्यवसाय का नाम आवश्यक है।',
        maxLengthForBusinessName40Characters: 'व्यवसाय के नाम के लिए अधिकतम लंबाई 40 वर्ण हैं।',
        categoryIsRequired: 'श्रेणी की आवश्यकता है।',
        descriptionIsRequired: 'विवरण आवश्यक है।',
        maxLengthForDescription200Characters: 'विवरण के लिए अधिकतम लंबाई 200 वर्ण हैं।',
        aboutIsRequired: 'के बारे में आवश्यक है।',
        maxLengthForAbout139Characters: 'लगभग 139 वर्णों के लिए अधिकतम लंबाई है।',
        pleaseFillAValidBusinessEmail: 'कृपया एक मान्य व्यवसाय ईमेल भरें।',
        businessEmailIsRequired: 'व्यावसायिक ईमेल की आवश्यकता है।',
        websiteIsRequired: 'वेबसाइट की आवश्यकता है।',
        maxLengthForWebsite40Characters: 'वेबसाइट के लिए अधिकतम लंबाई 40 वर्ण हैं।',
        maxLengthForAddress150Characters: 'पते के लिए अधिकतम लंबाई 150 वर्ण हैं।',
        cancel: 'रद्द करना',
        update: 'अद्यतन',
        businessCategory: 'व्यापार वर्ग',
        placeholders: {
          yourBusinessName: 'आपका व्यवसाय नाम',
          businessDescription: 'व्यापार विवरण',
          about: 'के बारे में',
          businessEmail: 'बिजनेस ईमेल',
          website: 'वेबसाइट (जैसे www.example.com)',
          address: 'पता'
        }
      },
      flows: {
        chatFlows: 'चैट धाराएँ',
        createChatFlow: 'चैट प्रवाह बनाएं',
        youCanCreateMultipleChatFlows: 'आप ए/बी परीक्षण और प्रसारण के लिए कई चैनलों में कई वार्तालाप प्रवाह बना सकते हैं।',
        flowName: 'प्रवाह नाम',
        nameIsRequired: 'नाम आवश्यक है',
        cancel: 'रद्द करना',
        createFlow: 'प्रवाह बनाएँ',
        importFlow: 'आयात प्रवाह',
        createNewFlow: 'नया प्रवाह बनाएं',
        ofMessages: ' संदेशों की',
        createdOn: 'पर बनाया',
        lastModified: 'अंतिम बार संशोधित',
        defaultFlow: 'डिफ़ॉल्ट प्रवाह',
        revisitFlow: 'पुनरीक्षण प्रवाह',
        action: 'कार्य',
        exportStatus: 'निर्यात की स्थिति',
        flowExport: 'प्रवाह-निर्यात',
        beforeYouStartUploadPleaseMakeSure: 'इससे पहले कि आप अपलोड शुरू करें कृपया सुनिश्चित करें',
        point1: '1. अधिकतम फ़ाइल आकार अपलोड सीमा 20MB है',
        point2: '2. फ़ाइल प्रारूप JSON में होना चाहिए',
        selectFile: 'फ़ाइल का चयन करें',
        seeHowToUse: 'देखें कि कैसे उपयोग करें',
        inProgress: ' चल रहा',
        available: ' उपलब्ध',
        initiated: ' शुरू किया',
        download: 'डाउनलोड करना',
        placeholders: {
          enterFlowName: 'प्रवाह नाम दर्ज करें'
        },
        appTooltip: {
          importFlowForWhatsappBot: 'व्हाट्सएप बॉट के लिए आयात प्रवाह',
          dateAndTimeOfCreationOfChatFlow: 'चैट प्रवाह के निर्माण की दिनांक और समय',
          dateAndTimeWhenChatFlowLastModified: 'दिनांक और समय जब चैट प्रवाह को अंतिम बार संशोधित किया गया था',
          TOOLTIP_CREATE_FLOW: 'एक नया चैट प्रवाह बनाने के लिए क्लिक करें',
          TOOLTIP_FLOW_NAME: 'चैट फ्लो नाम दर्ज करें',
          TOOLTIP_FLOW_TABLE_NAME: 'चैट प्रवाह का नाम',
          TOOLTIP_NO_OF_MESSAGES: 'चैट प्रवाह में संदेश घटकों की संख्या',
          TOOLTIP_DEFAULT_FLOW: 'डिफ़ॉल्ट के रूप में एक चैट प्रवाह सेट करने के लिए इस टॉगल का उपयोग करें, जो सभी नए उपयोगकर्ताओं को परोसा जाएगा।',
          TOOLTIP_REVISIT_FLOW: 'जब वे आपके चैटबॉट को फिर से करते हैं, तो उपयोगकर्ताओं को परोसे जाने वाले चैट प्रवाह का चयन करने के लिए इस टॉगल का उपयोग करें।',
          TOOLTIP_ACTION: 'चैट प्रवाह को संपादित या हटाएं',
        },
        modal: {
          deleteFlow: 'प्रवाह को हटा दें',
          wantToDeleteThisFlow: 'क्या आप सुनिश्चित हैं कि आप इस प्रवाह को हटाना चाहते हैं?'
        },
        noDataFound: {
          flows: 'प्रवाह',
          noFlowsAddedYet: 'अभी तक कोई प्रवाह नहीं जोड़ा गया!'
        }
      },
      input: {
        manageTemplates: 'टेम्प्लेट का प्रबंधन करें',
        manageYourTemplatesForMarketingAutomation: 'मार्केटिंग ऑटोमेशन के लिए अपने टेम्प्लेट का प्रबंधन करें',
        syncTemplates: 'सिंक टेम्प्लेट',
        createTemplateHeader: 'टेम्पलेट बनाएं',
        title: 'शीर्षक',
        info: 'जानकारी',
        category: 'वर्ग',
        status: 'दर्जा',
        action: 'कार्य',
        shipmentUpdate: 'शिपमेंट अद्यतन',
        name: 'नाम',
        headerOptional: 'हेडर (वैकल्पिक)',
        bodyRequired: 'शरीर (आवश्यक)',
        footerOptional: 'पाद (वैकल्पिक)',
        buttonsOptional: 'बटन (वैकल्पिक)',
        makeUpto3Buttons: '3 बटन बनाएं',
        makeUpto2ButtonsToRedirect: 'अपनी वेबसाइट पर ग्राहक को पुनर्निर्देशित करने के लिए 2 बटन बनाएं',
        placeholders: {
          nameYourTemplate: 'अपने टेम्पलेट का नाम दें (अलग -अलग शब्दों के लिए अंडरस्कोर का उपयोग करें)',
          enterText: 'पाठ दर्ज करें (अधिकतम 60 वर्ण)',
          enterImageUrl: 'छवि url दर्ज करें',
          enterVideoUrl: 'वीडियो URL दर्ज करें',
          templateMessage: 'टेम्पलेट संदेश',
          smallFooterAtBottom: 'तल पर छोटे पाद',
          buttons: 'बटन',
          text: 'मूलपाठ',
          numberWithCountryCode: 'देश कोड के साथ संख्या',
          enterButtonText: 'बटन पाठ दर्ज करें (अधिकतम वर्ण 20)'
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'व्हाट्सएप टेम्पलेट का शीर्षक',
          TOOLTIP_INPUT_TABLE_INFO: 'व्यवसाय की विस्तृत जानकारी ने संदेश दिए',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'व्यवसाय के प्रकार ने नियुक्तियों, अनुस्मारक, ग्राहक देखभाल संदेश, शिपिंग अपडेट, अलर्ट और बहुत कुछ जैसे संदेश दिए',
          TOOLTIP_INPUT_TABLE_STATUS: 'टेम्पलेट की स्थिति को व्हाट्सएप द्वारा अस्वीकार या अनुमोदित किया जाता है। व्हाट्सएप टेम्प्लेट को पहले व्हाट्सएप द्वारा अनुमोदित किया जाना है और यह एक भुगतान सेवा है।',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'टेम्पलेट हटाएं',
        },
        noDataFound: {
          templates: 'टेम्पलेट्स',
          noTemplatesCreatedYet: 'अभी तक कोई टेम्प्लेट नहीं बनाया गया है!'
        },
        modal: {
          deleteTemplate: 'टेम्पलेट हटाएं',
          wantToDeleteThisTemplate: 'क्या आप सुनिश्चित हैं कि आप इस टेम्पलेट को हटाना चाहते हैं?'
        },
        createTemplate: {
          createTemplates: 'टेम्प्लेट बनाएं',
          createTemplatesForMarketingAutomation: 'मार्केटिंग ऑटोमेशन के लिए अपने टेम्प्लेट बनाएं',
          templateMeta: 'टेम्पलेट मेटा',
          selectLanguage: 'भाषा चुने',
          header: 'हैडर',
          optional: 'वैकल्पिक',
          samplesForHeaderContent: 'हेडर सामग्री के लिए नमूने',
          toHelpMetaReviewYourContent: 'मेटा को अपनी सामग्री की समीक्षा करने में मदद करने के लिए, हेडर में चर या मीडिया के उदाहरण प्रदान करें। कोई ग्राहक जानकारी शामिल न करें।',
          body: 'शरीर',
          addVariable: 'चर जोड़ें',
          samplesForBodyContent: 'शरीर की सामग्री के लिए नमूने',
          toHelpUsReviewYourContent: 'हमारी सामग्री की समीक्षा करने में हमारी मदद करने के लिए, शरीर में चर के उदाहरण प्रदान करें। कोई ग्राहक जानकारी शामिल न करें।',
          footer: 'फ़ुटबाल',
          buttons: 'बटन',
          typeOfAction: 'कार्रवाई का प्रकार',
          call: 'पुकारना',
          phone: 'फ़ोन',
          website: 'वेबसाइट',
          static: 'स्थिर',
          urlType: 'URL प्रकार',
          type: 'प्रकार',
          customButton: 'कस्टम बटन',
          addAnotherButton: 'एक और बटन जोड़ें',
          preview: 'पूर्व दर्शन',
          test: 'परीक्षण',
          create: 'बनाएं',
          addSampleUrl: 'नमूना URL जोड़ें',
          toHelpMetaReviewYourMessage: 'मेटा को अपने संदेश टेम्पलेट की समीक्षा करने में मदद करने के लिए, कृपया वेबसाइट URL का एक उदाहरण जोड़ें। वास्तविक ग्राहक जानकारी का उपयोग न करें।',
          placeholders: {
            enterMessageTemplateName: 'संदेश टेम्पलेट नाम दर्ज करें',
            searchTemplateByName: 'नाम से खोज टेम्पलेट',
            selectTemplate: 'टेम्पलेट का चयन करें',
            enterText: 'पाठ दर्ज करें',
            enterBody: 'निकाय दर्ज करें',
            enterContentForVariable: 'चर के लिए सामग्री दर्ज करें',
            enterFooter: 'पाद दर्ज करें',
            phone: 'फ़ोन*',
            enterWebsiteUrl: 'वेबसाइट URL दर्ज करें'
          },
          bpInput: {
            name: 'नाम',
            buttonText: 'टेक्स्ट बटन',
            websiteUrl: 'वेबसाइट यूआरएल'
          },
          bpDropDown: {
            selectCategory: 'श्रेणी चुनना'
          }
        },
        templatePreview: {
          preview: 'पूर्व दर्शन',
          test: 'परीक्षण',
        },
        templateConfig: {
          discard: 'खारिज करना',
          saveChanges: 'परिवर्तनों को सुरक्षित करें',
          triggerChatFlowOnTemplateReply: 'टेम्पलेट प्रतिक्रिया पर चैट फ़्लो ट्रिगर करें',
          templateDetails: 'टेम्पलेट विवरण',
          configureFlows: 'फ़्लो कॉन्फ़िगर करें',
          selectFlow: 'फ़्लो का चयन करें',
          name: 'नाम'
        }
      },
      shopManagement: {
        shopManagement: 'दुकान प्रबंधन',
        manageECommerceShopFromBotPenguin: 'बोटपेंगुइन से जुड़े ई-कॉमर्स की दुकानों का प्रबंधन करें',
        comingSoon: '( जल्द आ रहा है )',
        wooCommerce: {
          createConfiguration: 'कॉन्फ़िगरेशन बनाएं',
          eventConfiguration: {
            createConfiguration: 'कॉन्फ़िगरेशन बनाएं',
            templateHeader: 'टेम्पलेट हेडर',
            selectFile: 'फ़ाइल का चयन करें',
            templateMessage: 'टेम्पलेट संदेश',
            saveChanges: 'परिवर्तनों को सुरक्षित करें',
            bpDropDown: {
              selectEvent: 'इवेंट का चयन करें',
              selectTemplate: 'टेम्पलेट का चयन करें'
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'व्हाट्सएप प्रोफाइल',
        manageYourBotProfile: 'जब आप चाहते हैं तो अपने बॉट प्रोफाइल को प्रबंधित करें।',
        allSetNoActionRequired: 'सभी सेट, कोई कार्रवाई की आवश्यकता नहीं है!',
        congratulationsYourAccountIsLive: 'बधाई हो! आपका खाता पूर्ण पहुंच के साथ लाइव है',
        somethingIsWrongReactivateYourBot: 'कुछ गलत है, अपने बॉट को फिर से सक्रिय करें!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'ऐसा लगता है कि आपका बॉट ठीक से काम नहीं कर रहा है, कृपया अपना बॉट रीसेट करें',
        reConfigureYourBot: 'अपने बॉट को फिर से कॉन्फ़िगर करें',
        phoneNumberDetails: 'फ़ोन नंबर विवरण',
        phoneNumber: 'फ़ोन नंबर',
        nA: '-',
        phoneNumberId: 'फ़ोन नंबर आईडी',
        wabaId: 'वबा आईडी',
        change: 'परिवर्तन',
        accessToken: 'एक्सेस टोकन',
        messagingLimit: 'संदेश -सीमा',
        qualityRating: 'गुणवत्ता रेटिंग',
        whatsappBusinessAccountName: 'व्हाट्सएप बिजनेस अकाउंट का नाम',
        whatsappPublicProfile: 'व्हाट्सएप पब्लिक प्रोफाइल',
        profilePicture: 'प्रोफ़ाइल फोटो',
        aboutText: 'पाठ के बारे में',
        address: 'पता',
        description: 'विवरण',
        email: 'ईमेल',
        websitePrimary: 'वेबसाइट (प्राथमिक)',
        websiteSecondary: 'वेबसाइट (माध्यमिक)',
        businessVertical: 'व्यापार ऊर्ध्वाधर',
        editProfile: 'प्रोफ़ाइल संपादित करें',
        thisIsABusinessAccount: 'यह एक व्यावसायिक खाता है',
        aboutAndPhoneNumber: 'के बारे में और फोन नंबर',
        yourTrialAccountIsLive: 'बधाई हो! आपका परीक्षण खाता आपके सत्यापित नंबर पर पूरी पहुंच के साथ लाइव है',
        updateWithNewAccessToken: 'नए AccessToken के साथ अपडेट करें',
      },
      widgets: {
        manageWidgets: 'विजेट प्रबंधित करें',
        widgetsAreRoutingTools: 'बॉट के उपयोग को बढ़ाने के लिए विजेट रूटिंग उपकरण हैं',
        needHelp: 'मदद की ज़रूरत है?',
        wantToMakeChanges: 'परिवर्तन करना चाहते हैं?',
        prePopulatedMessage: 'पूर्व आबादी संदेश',
        discard: 'खारिज करना',
        updateWidget: 'अद्यतन विजेट',
        hiThere: 'नमस्ते',
        seeHowToUse: 'देखें कि कैसे उपयोग करें',
        editIcon: 'आइकन संपादित करें',
        editPrePopulatedMessage: 'पूर्व-आबादी वाले संदेश को संपादित करें',
        small: 'छोटा',
        medium: 'मध्यम',
        large: 'बड़ा',
        leftMiddle: 'बायां मध्य',
        rightMiddle: 'दाहिने मध्य',
        leftBottom: 'बायां नीचे',
        rightBottom: 'दाहिने नीचे',
        chatBotIcon: 'चैटबोट आइकन',
        link: 'जोड़ना',
        qrcode: 'क्यू आर संहिता',
        copyChatBotCode: 'चैट बोट कोड कॉपी करें',
        copyWALink: 'कॉपी वा लिंक',
        downloadQrCode: 'क्यूआर कोड डाउनलोड करें',
        installChatBotIconOnYourWebsite: 'अपनी वेबसाइट पर चैटबॉट आइकन स्थापित करें',
        shareWhereEverYouWantRedirectUsersTo: 'जहां भी आप चाहते हैं और सिंगल क्लिक में अपने व्हाट्सएप बॉट पर उपयोगकर्ताओं को रीडायरेक्ट करें',
        copyAndAddInYourWebsiteHeaderFile: 'अपनी वेबसाइट हेडर फ़ाइल में कॉपी और जोड़ें',
        copyLinkAndStartSharing: 'कॉपी लिंक और साझा करना शुरू करें!',
        downloadQrCodeAndStartSharing: 'क्यूआर कोड डाउनलोड करें और साझा करना शुरू करें!',
        addCodeAndDeployNewCodeAndDone: 'कोड जोड़ें, नया कोड तैनात करें और किया!',
        wantToLearnHowToUseIt: 'सीखना चाहते हैं कि इसका उपयोग कैसे करें?',
        placeholders: {
          enterWhatsappNumber: 'व्हाट्सएप नंबर दर्ज करें',
          updateYourMessageHere: 'अपना संदेश यहाँ अपडेट करें ...'
        },
        bpDropDown: {
          selectIcons: 'आइकन का चयन करें',
          iconSize: 'प्रतीक आकार',
          iconPlacement: 'आइकन प्लेसमेंट'
        },
        bpInput: {
          whatsappNumber: 'व्हाट्सएप नंबर'
        }
      },
      catalog: {
        createCatalog: 'कैटलॉग बनाएं',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'मेटा कॉमर्स प्रबंधक पर उत्पाद कैटलॉग बनाएं या उसे शॉपिफाई से लिंक करें',
        useYourMetaCommerceManagertoManageCatalogs: 'कैटलॉग प्रबंधन के लिए अपने मेटा कॉमर्स प्रबंधक का उपयोग करें',
        connectCatalogToWhatsAppAccount: 'कैटलॉग को WhatsApp खाते से कनेक्ट करें',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'अपने WhatsApp मैनेजर का उपयोग करके अपने कैटलॉग को WhatsApp खाते से कनेक्ट करें',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'अपने मेटा WhatsApp मैनेजर का उपयोग करके कैटलॉग को कनेक्ट करें और उन्हें BotPenguin के साथ उपयोग करें',
        goToMeta: 'मेटा पर जाएँ',
        goToShopify: 'शॉपिफ़ाई पर जाएँ',
        next: 'आगे',
        back: 'पीछे',
        setupCatalog: 'कैटलॉग सेटअप करें',
        completeAllTheStepsToEnableCatalogMessages: 'कैटलॉग संदेशों को सक्षम करने के लिए सभी चरण पूरा करें'
      },
      generalSetting: {
        sessionTimeOutInHours: 'सत्र समय समाप्ति',
        enterSessionTimeOutWindowInHours: 'सत्र समय समाप्ति विंडो में घंटों में प्रवेश करें',
        sendInactivityMessage: 'निष्क्रियता संदेश भेजें',
        sessionTimeOutInHoursTooltip: `अप्रसंगता के कारण, चैट सत्र स्वचालित रूप से कितने घंटों (में) के बाद समाप्त हो जाएगा, इसे दर्ज करें। चैटफ्लो यदि उपयोगकर्ता सत्र समय समाप्त होने के बाद किसी भी संदेश को भेजता है, तो पुनः प्रारंभ होगा`,
        restartChatFlowTriggers: 'चैट फ़्लो ट्रिगर्स को पुनरारंभ करें',
        enterKeyWordsToRestartChatAndhitEnter: 'चैट को पुनरारंभ करने के लिए कीवर्ड दर्ज करें और Enter दबाएं',
        restartChatFlowTriggersTooltip: `यहां विशिष्ट कीवर्ड दर्ज करें जो, जब उपयोगकर्ता द्वारा दर्ज किए जाएं और प्रस्तुत किए जाएं, तो चैट फ़्लो को पुनरारंभ करेंगे। इससे उपयोगकर्ता किसी भी समय बातचीत को पुनरारंभ कर सकते हैं।
         कई कीवर्ड जोड़ने के बाद Enter दबाएं।`,
        liveChatTriggers: 'लाइव चैट ट्रिगर्स',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'लाइव चैट को ट्रिगर करने के लिए कीवर्ड दर्ज करें और Enter दबाएं',
        liveChatTriggersTooltip: `यहां उपयोगकर्ता द्वारा दर्ज किए जाने पर जब वे चैट के स्वतंत्र मोड पर जाने के लिए शब्द दर्ज करेंगे और प्रस्तुत करेंगे, तो स्वचालित चैट से लाइव चैट का आरंभ होगा। यह उपयोगकर्ताओं को जब वे लाइव एजेंट से सहायता की आवश्यकता होती है, तो उपयोगकर्ता को अनुमति देता है।
         प्रत्येक कीवर्ड जोड़ने के बाद Enter दबाएं।`,
        messageAfterAutomatedFlowCompleted: 'स्वचालित फ़्लो पूरा होने के बाद संदेश',
        enterYourMessage: 'अपना संदेश दर्ज करें',
        messageAfterAutomatedFlowCompletedTooltip: `इसमें एक संदेश दर्ज करें जो उपयोगकर्ता को प्रदर्शित किया जाएगा जब वे स्वचालित चैट फ़्लो को पूरा कर चुके हैं, लेकिन सत्र समय समाप्त नहीं हुआ है।`,
        restart: 'पुनरारंभ',
        liveChat: 'लाइव चैट',
        lineOneForDefaultMessage: 'यदि आपके पास और सवाल हैं या मदद की आवश्यकता है, तो यहां आपके बाद क्या कर सकते हैं:',
        to: 'को',
        restartTheChat: 'चैट को पुनरारंभ करें',
        simpleTypeRestartAndHitEnter: `बस 'पुनरारंभ' लिखें और Enter दबाएं। इससे आपको हमारी बातचीत के शुरुआत पर वापस ले जाया जाएगा।`,
        lineThreeForDefaultMessagePartOne: `यदि आप बात करना चाहते हैं `,
        liveAgent: `लाइव एजेंट से,`,
        lineThreeForDefaultMessageLastPart: `तो 'लाइव चैट' लिखें और Enter दबाएं। हमारी टीम आपकी मदद के लिए वास्तविक समय में यहां है।`,
        saveChanges: 'बदलाव सहेजें',
        discard: 'रद्द करें',
        configureBotsGeneralSettings: 'अपने बॉट की सामान्य सेटिंग्स कॉन्फ़िगर करें',
        minutes: 'मिनट',
        hours: 'घंटे',

      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'बस एक कदम दूर',
      seeHowToUse: 'उपयोग कैसे करें देखें',
      testWhatsAppTrialBot: 'अपने WhatsApp परीक्षण चैटबॉट को परीक्षण करें',
      sendMessage: 'संदेश भेजें',
      sendWhatsAppMessageOn: 'हमें WhatsApp पर एक संदेश भेजें ',
      bySavingInYourContact: 'जो संदेश के साथ शुरू होना चाहिए और आपके संपर्कों में सहेजकर ',
      connect: 'कनेक्ट करें',
      or: 'या',
      scanQRCode: 'QR कोड स्कैन करें',
      yourPhoneWillOpen: 'आपका फ़ोन एक पूर्व-भरी हुई संदेश खोलेगा जो हमारे सैंडबॉक्स नंबर को भेजने के लिए होगा। WhatsApp पर ‘Send’ दबाएं',
      sendVerificationCode: 'सत्यापन कोड भेजें',
      copyAndEnterCode: '6-अंकीय कोड कॉपी करें और दर्ज करें',
      shareWhereYouWant: 'जहां चाहें वहां साझा करें और उपयोगकर्ताओं को एक ही क्लिक में अपने WhatsApp बॉट पर रीडायरेक्ट करें',
      youShouldHaveReceived: 'आपको अपने WhatsApp नंबर पर 6-अंकीय कोड प्राप्त होना चाहिए',
      EnterYourPhoneNumber: 'अपना फ़ोन नंबर दर्ज करें',
      send: 'भेजें',
      sixDigitCode: '6-अंकीय कोड दर्ज करें',
      pleaseNote: 'कृपया ध्यान दें: 6-अंकीय सत्यापन कोड तकनीकी कारणों से 3 मिनट तक लग सकता है, कृपया दुबारा भेजने से पहले प्रतीक्षा करें।',
      resendVerificationCode: 'पुन: सत्यापन कोड भेजें',
      verify: 'सत्यापित करें',
      providePhoneNumber: 'कृपया परीक्षण के लिए उपयोग करने वाला नंबर प्रदान करें',
      demoBotisReady: 'बहुत बढ़िया! आपका डेमो बॉट उपयोग के लिए तैयार है',
      placeHolder: {
        phone: 'अपना फ़ोन नंबर दर्ज करें',
        code: 'सत्यापन कोड दर्ज करें',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'तृतीय-पक्ष एप्लिकेशन एकीकरण',
    thirdPartyApps: 'तृतीय-पक्ष ऐप्स',
    customApps: 'कस्टम ऐप्स',
    preview: 'पूर्वावलोकन',
    test: 'परीक्षण',
    install: 'इंस्टॉल करें',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'चयनित प्लेटफ़ॉर्म पर अपना बॉट इंस्टॉल करने के लिए क्लिक करें',
      TOOLTIP_MY_INTEGRATION_TITLE: 'अपने बॉट के साथ अपने कस्टम बनाए गए ऐप्स को एकीकृत करें',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'अपने बॉट को उपलब्ध तृतीय-पक्ष ऐप्स/सीआरएमएस के साथ कनेक्ट करें',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'तृतीय-पक्ष एप्लिकेशन एकीकरण',
        integrateYourBotWithListedCrms: 'सूचीबद्ध ऐप्स/सीआरएम में से एक के साथ अपने बॉट को एकीकृत करें।',
        help: 'मदद',
        name: 'नाम',
        status: 'दर्जा',
        action: 'कार्य',
        chatGpt: 'चटपट',
        addOn: 'ऐड ऑन',
        purchased: 'खरीदी',
        readyToRideGptWave: 'GPT लहर की सवारी करने के लिए तैयार हैं? अब एकीकृत करें।',
        purchase: 'खरीदना',
        notConnected: 'जुड़े नहीं हैं',
        integrated: 'एकीकृत',
        configure: 'कॉन्फ़िगर',
        connected: 'जुड़े हुए',
        edit: 'संपादन करना',
        connect: 'जोड़ना',
        requestYourIntegration: 'अपने एकीकरण का अनुरोध करें',
        toRequestNewIntegration: 'नए एकीकरण का अनुरोध करने के लिए, कृपया नीचे उल्लिखित विवरण दर्ज करें और फिर हम आपके पास वापस आ जाएंगे।',
        emailId: 'ईमेल आईडी',
        integrationName: 'एकीकरण नाम',
        contactNumber: 'संपर्क संख्या',
        howSoonYouWantToAddThisIntegration: 'कितनी जल्दी आप इस एकीकरण को जोड़ना चाहते हैं',
        sendRequest: 'अनुरोध भेजा',
        cancel: 'रद्द करना',
        requestSent: 'निमंत्रण भेजा गया!',
        yourRequestSuccessfullySent: 'नए एकीकरण के लिए आपका अनुरोध सफलतापूर्वक भेजा गया है।',
        goBack: 'वापस जाओ',
        disconnect: 'डिस्कनेक्ट',
        OopsIntegrationAreNotAvailable: 'ओहो! इंटीग्रेशन ',
        pleaseUpgradeTo: ' में उपलब्ध नहीं हैं । कृपया अपग्रेड करें ',
        planForIntegration: ' में इंटीग्रेशन के लिए ।',
        placeholders: {
          searchIntegrationByName: 'नाम से एकीकरण खोजें',
          enterTeamMemberName: 'टीम के सदस्य का नाम दर्ज करें',
          enterEmailId: 'ईमेल आईडी दर्ज करें',
          enterIntegrationName: 'एक एकीकरण नाम दर्ज करें',
          searchCountry: 'खोज देश',
          enterContactNumber: 'संपर्क नंबर दर्ज करें',
          addComment: 'टिप्पणी जोड़ना'
        },
        noDataFound: {
          integrations: 'एकीकरण',
          noIntegrationsFound: 'कोई एकीकरण नहीं मिला!'
        },
        appTooltip: {
          nameOfAppOrCrm: 'ऐप/सीआरएम का नाम',
          statusAsConnectedOrNotConnected: 'कनेक्टेड / नॉट-कनेक्टेड के रूप में स्थिति',
          clickToConnectToAppOrCrm: 'ऐप/सीआरएम कनेक्ट करने के लिए क्लिक करें'
        },
        feedbackModal: {
          submit: 'जमा करना',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          agileCrmDomain: 'चंचल सीआरएम डोमेन',
          nameIsRequired: 'नाम आवश्यक है',
          emailAddress: 'मेल पता',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameter: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'उदाहरण@company.extention',
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          accessKey: 'प्रवेश की चाबी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiKeyHere: 'यहां API कुंजी दर्ज करें',
            enterAccessKeyHere: 'यहां एक्सेस कुंजी दर्ज करें'
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          nameColon: 'नाम:',
          inboundWebhookUrl: 'इनबाउंड वेबहूक URL',
          nameIsRequired: 'नाम आवश्यक है',
          leadTitle: 'प्रधान शीर्षक',
          source: 'स्रोत',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterInboundWebhookUrl: 'इनबाउंड वेबहूक URL दर्ज करें ...',
            enterTitle: 'शीर्षक दर्ज करें',
            enterSource: 'स्रोत दर्ज करें'
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          testLead: 'परीक्षण का नेतृत्व करें',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          testLead: 'परीक्षण का नेतृत्व करें',
          connect: 'जोड़ना',
          selectTheQuestion: 'उस प्रश्न का चयन करें जिसका उपयोगकर्ता उत्तर आप मूल्य के रूप में भेजना चाहते हैं',
          mapWith: 'के साथ मैप करना',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें',
            attributes: 'विशेषताओं (कुंजी)',
            values: 'मान'
          }
        },
        chatGpt: {
          chatGptApiKey: 'CHATGPT (ओपन एआई) एपीआई कुंजी',
          chatGpt: 'चैट (ओपन एआई)',
          readyToRideWave: 'GPT लहर की सवारी करने के लिए तैयार हैं? अब एकीकृत करें।',
          doNotHaveAnyApiKeyNeedHelp: 'एपीआई कुंजी नहीं है, मदद की आवश्यकता है?',
          authenticate: 'प्रमाणित',
          nowYouCanAccessChatGpt: 'अब आप चैट फ्लो बिल्डर में चैट जीपीटी घटक का उपयोग कर सकते हैं',
          paymentSuccessful: 'भुगतान सफल!',
          paymentHasBeenCompletedSuccessfully: 'भुगतान सफलतापूर्वक पूरा हो गया है। आप अपने बॉट को प्रशिक्षित करने के लिए तैयार हैं',
          cancel: 'रद्द करना',
          trainNow: 'अब प्रशिक्षित करें',
          paymentInProgress: 'प्रगति में भुगतान ...',
          apiKey: 'ChatGPT API कुंजी',
          model: 'शीर्षक',
          integrateWithChatGPT: 'चैटजीपीटी के साथ एकीकृत करें',
          updateKey: 'एपीआई कुंजी अपडेट करें',
          connect: 'जोड़ना',
          update: 'अपडेट',
          placeholders: {
            chatGptApiKey: 'CHATGPT (ओपन एआई) एपीआई कुंजी'
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          leadId: 'लीड आईडी',
          selectLeadId: 'लीडिड का चयन करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          emailAddress: 'मेल पता',
          leadTitle: 'प्रधान शीर्षक',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            exampleEmail: 'उदाहरण@company.extention',
            enterYourLeadTitle: 'अपना लीड शीर्षक दर्ज करें',
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          dripCrmAccountId: 'ड्रिप सीआरएम खाता आईडी',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterAccountIdHere: 'खाता आईडी यहां दर्ज करें',
            enterYourTokenHere: 'यहां अपना टोकन दर्ज करें'
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apikey: 'एपीआई कुंजी',
          accessToken: 'एक्सेस टोकन',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          selectParameter: 'पैरामीटर का चयन करें',
          disconnect: 'डिस्कनेक्ट',
          update: 'अद्यतन',
          save: 'बचाना',
          placeholders: {
            enterApiKeyHere: 'यहां API कुंजी दर्ज करें',
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          testLead: 'परीक्षण का नेतृत्व करें',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          authToken: 'अधिवृषण',
          source: 'स्रोत',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterAuthTokenHere: 'यहाँ AuthToken दर्ज करें',
            enterSourceHere: 'यहां स्रोत दर्ज करें'
          }
        },
        facebookPixel: {
          facebookPixel: 'फेसबुक पिक्सल',
          getInsight: 'उन अंतर्दृष्टि प्राप्त करें जो आपके व्यवसाय को बोटेंगुइन के फेसबुक पिक्सल एकीकरण के साथ एक उद्यम में बदल दें',
          pixelId: 'फेसबुक पिक्सल आईडी',
          connect: 'जोड़ना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterYourId: 'फेसबुक पिक्सल आईडी दर्ज करें'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          companyName: 'कंपनी का नाम',
          emailAddress: 'मेल पता',
          password: 'पासवर्ड',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapsWith: 'के साथ नक्शे',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterCompanyName: 'कंपनी का नाम दर्ज करें',
            enterEmailAddress: 'ईमेल पता दर्ज करें',
            enterPassword: 'पास वर्ड दर्ज करें'
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiKeyHere: 'Apikey यहाँ दर्ज करें'
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          flowluDomain: 'फ्लोइलू डोमेन',
          apiToken: 'एपीआई टोकन',
          title: 'शीर्षक',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'यहां टोकन दर्ज करें',
            enterYourLeadTitle: 'अपना लीड शीर्षक दर्ज करें'
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          freshdeskDomain: 'फ्रेशडेस्क डोमेन',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          freshworkCrmDomain: 'फ्रेशवर्क सीआरएम डोमेन',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'यहां टोकन दर्ज करें'
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          mapWith: 'के साथ मैप करना',
          select: 'चुनना',
          update: 'अद्यतन',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          campaignList: 'अभियान सूची',
          selectCampaign: 'चुनिंदा अभियान',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        googleAnalytics: {
          googleAnalytics: 'गूगल विश्लेषिकी',
          getInsights: 'उन अंतर्दृष्टि प्राप्त करें जो आपके व्यवसाय को बोटेंगुइन के Google Analytics एकीकरण के साथ एक उद्यम में बदल दें',
          measurementId: 'माप आईडी',
          connect: 'जोड़ना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterYourId: 'अपनी आईडी दर्ज करें'
          }
        },
        googleCalendar: {
          testLead: 'परीक्षण का नेतृत्व करें',
          name: 'नाम:',
          email: 'ईमेल:',
          appointmentSummary: 'नियुक्ति सारांश',
          appointmentDescription: 'नियुक्ति विवरण',
          addParameters: ' पैरामीटर जोड़ें',
          questionColon: 'सवाल:',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          pause: 'रोकना',
          resume: 'फिर शुरू करना',
          disconnect: 'डिस्कनेक्ट',
          reconnect: 'रिकनेक्ट'
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          name: 'नाम :',
          email: 'ईमेल :',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट'
        },
        googleRedirect: {},
        googleSheet: {
          testLead: 'परीक्षण का नेतृत्व करें',
          title: 'शीर्षक',
          questionColon: 'सवाल:',
          addParameters: 'पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          update: 'अद्यतन',
          save: 'बचाना',
          pause: 'रोकना',
          resume: 'फिर शुरू करना',
          disconnect: 'डिस्कनेक्ट',
          reconnect: 'रिकनेक्ट',
          placeholders: {
            mappingValues: 'मानचित्रण मान'
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          name: 'नाम:',
          email: 'ईमेल:',
          taskList: 'कार्य सूची',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          pause: 'रोकना',
          resume: 'फिर शुरू करना',
          disconnect: 'डिस्कनेक्ट',
          reconnect: 'रिकनेक्ट'
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          select: 'चुनना',
          addParameters: ' पैरामीटर जोड़ें',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiHere: 'यहां एपीआई दर्ज करें'
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          testLead: 'परीक्षण का नेतृत्व करें',
          accountId: 'खाता आईडी',
          accessToken: 'एक्सेस टोकन',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterAccountIdHere: 'खाता-आईडी दर्ज करें',
            enterAccessTokenHere: 'यहां एक्सेस टोकन दर्ज करें'
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          testLead: 'परीक्षण का नेतृत्व करें',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        helpLink: {
          help: 'मदद'
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          helpRaceCrmDomain: 'हेल्पस सीआरएम डोमेन',
          emailAddress: 'मेल पता',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          update: 'अद्यतन',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'उदाहरण@company.extension',
            token: 'टोकन'
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          domainName: 'डोमेन नाम',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiHere: 'यहां एपीआई दर्ज करें'
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          emailAddress: 'मेल पता',
          apiUrl: 'एपीआई यूआरएल',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          selectProjectName: 'प्रोजेक्ट नाम का चयन करें',
          selectIssueType: 'अंक प्रकार का चयन करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapsWith: 'के साथ नक्शे',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें',
            enterEmailAddress: 'ईमेल पता दर्ज करें',
            exampleUrl: 'https://example.atlassian.net'
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiUrl: 'एपीआई यूआरएल',
          nameIsRequired: 'नाम आवश्यक है',
          apiKey: 'एपीआई कुंजी',
          apiId: 'एपीआई आईडी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          mapWith: 'के साथ मैप करना',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          selectTheQuestion: 'उस प्रश्न का चयन करें जिसका उपयोगकर्ता उत्तर आप मूल्य के रूप में भेजना चाहते हैं',
          placeholders: {
            enterApiUrl: 'API URL दर्ज करें',
            enterApiKey: 'API कुंजी दर्ज करें',
            enterApiId: 'एपीआई आईडी दर्ज करें',
            attributes: 'विशेषताओं (कुंजी)',
            values: 'मान'
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          accessKey: 'प्रवेश की चाबी',
          secretKey: 'गुप्त कुंजी',
          connect: 'जोड़ना',
          apiLogs: 'एपीआई लॉग',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          udpate: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          filterApplied: 'फ़िल्टर लागू किया हुआ',
          retry: 'पुन: प्रयास करें',
          back: 'पीछे',
          filterYourLeads: 'अपने लीड को फ़िल्टर करें',
          filterOn: 'फ़िल्टर करना',
          filterBy: 'फिल्टर के द्वारा',
          discard: 'खारिज करना',
          applyFilter: 'फिल्टर लागू करें',
          loadMore: 'और लोड करें',
          placeholders: {
            enterAccessKeyHere: 'यहां एक्सेस कुंजी दर्ज करें',
            enterSecretKeyHere: 'यहाँ गुप्त कुंजी दर्ज करें',
            attributes: 'विशेषताओं (कुंजी)',
            values: 'मान'
          },
          appTooltip: {
            filterDataBasedOnResponse: 'प्रतिक्रिया के आधार पर फ़िल्टर डेटा'
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          apiUrl: 'एपीआई यूआरएल',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiHere: 'यहां एपीआई दर्ज करें',
            exampleUrl: 'https://comapanyname.ladesk.com'
          }
        },
        microsoftTeams: {
          downloadPackage: 'Microsoft टीमों ऐप पैकेज डाउनलोड करें',
          downloadApp: 'ऐप डाउनलोड करें',
          wantToLearnHowToUploadIt: 'यह सीखना चाहते हैं कि इसे कैसे अपलोड किया जाए?',
          needHelp: 'मदद की ज़रूरत है?'
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiUrl: 'एपीआई यूआरएल',
          appId: 'ऐप आईडी',
          nameIsRequired: 'ऐप आईडी की आवश्यकता है',
          dataApiKey: 'आंकड़ा एपीआई कुंजी',
          dataApiKeyIsRequired: 'डेटा एपीआई कुंजी की आवश्यकता है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          addParameters: ' पैरामीटर जोड़ें',
          mapWith: 'के साथ मैप करना',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          selectTheQuestion: 'उस प्रश्न का चयन करें जिसका उपयोगकर्ता उत्तर आप मूल्य के रूप में भेजना चाहते हैं',
          placeholders: {
            enterApiUrl: 'API URL दर्ज करें',
            enterAppId: 'ऐप आईडी दर्ज करें',
            enterDataApiKey: 'डेटा API कुंजी दर्ज करें',
            attributes: 'विशेषताओं (कुंजी)',
            pressForMappingQuestion: 'मैपिंग प्रश्न के लिए दबाएं /',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          emailAddress: 'मेल पता',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          leadId: 'लीड आईडी',
          selectLeadId: 'लीडिड का चयन करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterEmailHere: 'यहाँ ईमेल दर्ज करें',
            enterApiKeyHere: 'Apikey यहाँ दर्ज करें'
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          noCrmDomain: 'NOCRM डोमेन',
          leadTitle: 'प्रधान शीर्षक',
          titleIsRequired: 'शीर्षक आवश्यक है',
          token: 'टोकन',
          tokenIsRequired: 'टोकन की आवश्यकता है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            exampleDomain: 'https://your_subdomain_here.nocrm.ioio',
            enterYourLeadTitle: 'अपना लीड शीर्षक दर्ज करें',
            enterYourToken: 'अपना टोकन दर्ज करें'
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          emailAddress: 'मेल पता',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          secretToken: 'गुप्त टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          appId: 'ऐप आईडी',
          appIdIsRequired: 'ऐप आईडी की आवश्यकता है',
          apiKey: 'एपीआई कुंजी',
          apiKeyIsRequired: 'एपीआई कुंजी की आवश्यकता है',
          connect: 'जोड़ना',
          update: 'अद्यतन',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            appId: 'ऐप आईडी',
            apiKey: 'एपीआई कुंजी'
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          clientId: 'ग्राहक ID',
          connect: 'जोड़ना',
          clientSecret: 'ग्राहक गुप्त',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          save: 'बचाना',
          update: 'अद्यतन',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterClientIdHere: 'यहां क्लाइंट आईडी दर्ज करें',
            enterClientSecretHere: 'यहां क्लाइंट सीक्रेट दर्ज करें'
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          pipedriverDomain: 'पाइपड्राइवर डोमेन',
          domainIsRequired: 'डोमेन की आवश्यकता है',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          apiKeyIsRequired: 'एपीआई कुंजी की आवश्यकता है',
          appKey: 'ऐप कुंजी',
          appKeyIsRequired: 'ऐप कुंजी की आवश्यकता है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiKey: 'API कुंजी दर्ज करें',
            enterAppKey: 'ऐप कुंजी दर्ज करें'
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          authId: 'प्रामाणिक आईडी',
          authToken: 'प्रामाणिक टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiHere: 'यहां एपीआई दर्ज करें'
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiKey: 'API कुंजी दर्ज करें'
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          domain: 'कार्यक्षेत्र',
          clientId: 'ग्राहक ID',
          clientSecret: 'ग्राहक गुप्त',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterDomainHere: 'यहां डोमेन दर्ज करें',
            enterClientIdHere: 'यहां क्लाइंट आईडी दर्ज करें',
            enterClientSecretHere: 'यहां क्लाइंट सीक्रेट दर्ज करें'
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          addEventsAndTemplates: 'घटनाओं और टेम्प्लेट जोड़ें:',
          add: ' जोड़ना',
          question: 'सवाल',
          reply: 'जवाब',
          requestBody: 'अनुरोध निकाय',
          validateJson: 'JSON को मान्य करें',
          emailAddress: 'मेल पता',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          selectTheQuestion: 'उस प्रश्न का चयन करें जिसका उपयोगकर्ता उत्तर आप मूल्य के रूप में भेजना चाहते हैं',
          placeholders: {
            enterApiHere: 'यहां एपीआई दर्ज करें',
            enterTemplateId: 'टेम्पलेट आईडी दर्ज करें',
            typeYourJsonCodeHere: 'यहां अपना JSON कोड टाइप करें'
          },
          appTooltip: {
            typeYourJsonCode: 'अपना JSON कोड टाइप करें',
            clickToValidateJsonCode: 'JSON कोड को मान्य करने के लिए क्लिक करें'
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          userName: 'उपयोगकर्ता नाम',
          licenseKey: 'लाइसेंस कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterNameHere: 'यहाँ नाम दर्ज करें',
            enterKeyHere: 'यहां कुंजी दर्ज करें'
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एक एकीकरण बना लिया है। अब, आप इसे विन्यासित कर सकते हैं।',
          company: 'कंपनी',
          loginId: 'लॉगिन आईडी',
          password: 'पासवर्ड',
          testLead: 'परीक्षण नेता',
          connect: 'कनेक्ट करें',
          selectTheQuestion: 'उस सवाल को चुनें जिसके उपयोगकर्ता उत्तर को मूल्य के रूप में भेजना चाहते हैं',
          mapWith: 'मैप करें',
          update: 'अपडेट करें',
          save: 'सहेजें',
          disconnect: 'डिस्कनेक्ट करें',
          selectParameter: 'पैरामीटर का चयन करें',
          placeholders: {
            enterCompanyHere: 'कंपनी का नाम यहाँ डालें',
            enterLoginIdHere: 'लॉगिन आईडी यहाँ डालें',
            enterPasswordHere: 'पासवर्ड यहाँ डालें',
            values: 'मूल्य',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          applicationKey: 'अनुप्रयोग कुंजी',
          authToken: 'प्रामाणिक टोकन',
          connect: 'जोड़ना',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApplicationKeyHere: 'यहां आवेदन कुंजी दर्ज करें',
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          accessToken: 'टोकन',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          question: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          webhoperDomain: 'वेबहॉपर डोमेन',
          domainIsRequired: 'डोमेन की आवश्यकता है',
          companyName: 'कंपनी का नाम',
          companyIsRequired: 'कंपनी की आवश्यकता है',
          leadSource: 'लीड स्रोत',
          leadSourceIsRequired: 'लीड स्रोत की आवश्यकता है',
          division: 'विभाजन',
          divisionIsRequired: 'विभाजन की आवश्यकता है',
          enterPassword: 'पास वर्ड दर्ज करें',
          passwordIsRequired: 'पासवर्ड की आवश्यकता है',
          disconnect: 'डिस्कनेक्ट',
          update: 'अद्यतन',
          connect: 'जोड़ना',
          placeholders: {
            exampleDomain: 'http: // yourcompanydomain',
            enterYourCompanyName: 'अपनी कंपनी का नाम दर्ज करें',
            enterLeadSource: 'लीड सोर्स दर्ज करें',
            enterDivision: 'डिवीजन दर्ज करें',
            enterPassword: 'पास वर्ड दर्ज करें'
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          name: 'नाम:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'इनबाउंड वेबहूक URL',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterWebhookUrl: 'WebHook URL दर्ज करें'
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          emailAddress: 'मेल पता',
          apiUrl: 'एपीआई यूआरएल',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterEmailAddress: 'ईमेल पता दर्ज करें',
            enterUrlHere: 'यहाँ URL दर्ज करें',
            enterTokenHere: 'यहां टोकन दर्ज करें'
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiKey: 'एपीआई कुंजी',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiHere: 'यहां एपीआई दर्ज करें'
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          connect: 'जोड़ना',
          update: 'अद्यतन',
          disconnect: 'डिस्कनेक्ट',
          companyName: 'कंपनी का नाम',
          emailAddress: 'मेल पता',
          phone: 'फ़ोन',
          countryCode: 'कंट्री कोड',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          save: 'बचाना'
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          connect: 'जोड़ना',
          disconnect: 'डिस्कनेक्ट',
          testLead: 'परीक्षण का नेतृत्व करें',
          emailAddress: 'मेल पता',
          selectOrganizationName: 'संगठन का नाम चुनें',
          selectDepartmentName: 'विभाग का नाम चुनें',
          addParameters: ' पैरामीटर जोड़ें',
          questionColon: 'सवाल:',
          select: 'चुनना',
          mapsWith: 'के साथ नक्शे',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना'
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          testLead: 'परीक्षण का नेतृत्व करें',
          connect: 'जोड़ना',
          disconnect: 'डिस्कनेक्ट',
          projectsList: 'परियोजना सूची',
          selectProjectsName: 'परियोजनाओं का नाम चुनें',
          projectsTaskList: 'प्रोजेक्ट्स टास्कलिस्ट',
          selectTaskListName: 'टास्कलिस्ट नाम का चयन करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना'
        },
        wooCommerce: {
          configure: 'कॉन्फ़िगर करें',
          automatedMessage: 'स्वचालित संदेश',
          configureTab: {
            wooCommercePluginApiKey: 'वूकॉमर्स प्लगइन एपीआई कुंजी',
            downloadWooCommercePlugin: 'वूकॉमर्स प्लगइन डाउनलोड करें'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'छोड़े गए कार्ट की पुनर्प्राप्ति संदेश',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'ऐसे स्वचालित पुनर्प्राप्ति संदेश को कॉन्फ़िगर करें जो उन ग्राहकों को वापस लाने में मदद करें जिन्होंने अपने कार्ट को छोड़ दिया है',
            enableAbandonedCartRecoveryMessaegs: 'छोड़े गए कार्ट पुनर्प्राप्ति संदेश सक्षम करें',
            selectTemplate: 'टेम्पलेट चुनें',
            sendMessageAfter: 'के बाद संदेश भेजें',
            templateHeader: 'टेम्पलेट हेडर',
            templateMessage: 'टेम्पलेट संदेश',
            saveChanges: 'परिवर्तन सहेजें',
            selectFile: 'फ़ाइल का चयन करें',
            orderFulfillMent: 'आर्डर पूरा करना',
            orderCompleted: 'आर्डर पूरा हुआ',
            enableOrderConfirmationMessages: 'आर्डर पुष्टिकरण संदेश सक्षम करें',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'एक ग्राहक ने आर्डर देने के बाद तुरंत आर्डर पुष्टिकरण संदेश भेजें।',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'आप एक आर्डर पूरा करते समय आर्डर शिपमेंट संदेश भेजते हैं।',
            enableOrderFulfillmentMessages: 'आर्डर पूरा करने के संदेश सक्षम करें',
            returnExchangeRequest: 'वापसी/आदान-प्रदान अनुरोध',
            theMessageIsSentAfterARefundIsRequested: 'संदेश एक रिफंड का अनुरोध किया जाने के बाद भेजा जाता है।',
            enableOrderRefundMessages: 'आर्डर रिफंड संदेश सक्षम करें',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
          apiToken: 'एपीआई टोकन',
          nameIsRequired: 'नाम आवश्यक है',
          connect: 'जोड़ना',
          testLead: 'परीक्षण का नेतृत्व करें',
          questionColon: 'सवाल:',
          addParameters: ' पैरामीटर जोड़ें',
          select: 'चुनना',
          mapWith: 'के साथ मैप करना',
          selectParameter: 'पैरामीटर का चयन करें',
          update: 'अद्यतन',
          save: 'बचाना',
          disconnect: 'डिस्कनेक्ट',
          placeholders: {
            enterApiToken: 'एपीआई टोकन दर्ज करें'
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'नया एकीकरण बनाएं',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'आपके पास अभी तक कोई एकीकरण सेटअप नहीं है।',
      pleaseCreateOneToIntegrateYourFavourite: 'कृपया अपने पसंदीदा को एकीकृत करने के लिए एक बनाएं',
      appsWith: 'के साथ ऐप्स',
      integrationName: 'एकीकरण नाम',
      integrationNameIsRequired: 'एकीकरण नाम की आवश्यकता है',
      continue: 'जारी रखना',
      addAnImageToRepresentYourIntegration: 'अपने एकीकरण का प्रतिनिधित्व करने के लिए एक छवि जोड़ें।',
      description: 'विवरण',
      submit: 'जमा करना',
      youHaveSuccessfullyCreatedIntegration: 'आपने सफलतापूर्वक एकीकरण बनाया है। अब, आप इसे कॉन्फ़िगर कर सकते हैं।',
      apiUrl: 'एपीआई यूआरएल',
      pleaseEnterAValidApiUrl: 'कृपया एक वैध एपीआई URL दर्ज करें',
      requestMethod: 'अनुरोध विधि',
      get: 'पाना',
      post: 'डाक',
      put: 'रखना',
      patch: 'पैबंद',
      queryParameter: 'क्वेरी पैरामीटर',
      addParameter: ' पैरामीटर जोड़ें',
      mapsWith: ' के साथ नक्शे',
      question: 'सवाल',
      addQuestion: ' प्रश्न जोड़ें',
      select: 'चुनना',
      selectParameter: 'पैरामीटर का चयन करें',
      header: 'हैडर',
      addHeader: ' हेडर जोड़ें',
      value: 'कीमत',
      connect: 'जोड़ना',
      requestBody: 'अनुरोध निकाय',
      validate: 'मान्य',
      key: 'चाबी'
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' इस मुक्त CRM के साथ भी एकीकृत किया जा सकता है।',
        apiUrl: 'एपीआई यूआरएल',
        pleaseEnterAValidApiUrl: 'कृपया एक वैध एपीआई URL दर्ज करें',
        requestMethod: 'अनुरोध विधि:',
        get: 'पाना',
        post: 'डाक',
        put: 'रखना',
        patch: 'पैबंद',
        queryParameter: 'क्वेरी पैरामीटर',
        addParameter: ' पैरामीटर जोड़ें',
        header: 'हैडर',
        addHeader: ' हेडर जोड़ें',
        requestBody: 'अनुरोध निकाय',
        validateJson: 'JSON को मान्य करें',
        connect: 'जोड़ना',
        update: 'अद्यतन',
        delete: 'मिटाना',
        selectTheQuestion: 'उस प्रश्न का चयन करें जिसका उपयोगकर्ता उत्तर आप मूल्य के रूप में भेजना चाहते हैं',
        appTooltip: {
          typeYourJsonCode: 'अपना JSON कोड टाइप करें',
          clickToValidateJsonCode: 'JSON कोड को मान्य करने के लिए क्लिक करें'
        }
      },
      list: {
        customAppsIntegrations: 'कस्टम एप्लिकेशन एकीकरण',
        integrateYourAppsWith: 'के साथ अपने स्वयं के ऐप को एकीकृत करें',
        help: 'मदद',
        addIntegration: ' एकीकरण जोड़ें',
        name: 'नाम',
        status: 'दर्जा',
        action: 'कार्य',
        insatlled: 'स्थापित',
        edit: 'संपादन करना',
        connect: 'जोड़ना',
        requestYourIntegration: 'अपने एकीकरण का अनुरोध करें',
        toRequestNewIntegration: 'नए एकीकरण का अनुरोध करने के लिए, कृपया नीचे उल्लिखित विवरण दर्ज करें और फिर हम आपके पास वापस आ जाएंगे।',
        emailId: 'ईमेल आईडी',
        integrationName: 'एकीकरण नाम',
        contactNumber: 'संपर्क संख्या',
        howSoonYouWantToAddThisIntegration: 'कितनी जल्दी आप इस एकीकरण को जोड़ना चाहते हैं',
        sendRequest: 'अनुरोध भेजा',
        cancel: 'रद्द करना',
        delete: 'मिटाना',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'ओहो! आपके खुद के इंटीग्रेशन केवल ',
        plan: 'योजना',
        pleaseUpgradeTo: ' में समर्थित हैं कृपया अपग्रेड करें',
        toCreateOwnIntegrations: ' पर अपने खुद के इंटीग्रेशन बनाने के लिए',
        appTooltip: {
          integrations: 'एकीकरण',
          noIntegrationsFound: 'कोई एकीकरण नहीं मिला!',
          nameOfYourApp: 'आपके ऐप का नाम',
          clickToConnectApp: 'ऐप कनेक्ट करने के लिए क्लिक करें',
          statusAsConnectedOrNotConnected: 'कनेक्टेड / नॉट-कनेक्टेड के रूप में स्थिति'
        }
      }
    }
  }
};
