const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Hallo,',
  greetingHand: '👋',
  upgrade: 'Aktualisierung',
  integrate: 'Integrieren',
  integrate2: 'Integrieren',
  chatGPT: 'Chatgpt',
  forYourBusiness: 'für dein Geschäft',
  insights: 'Erkenntnisse',
  message: 'Mitteilungen',
  addon: 'Hinzufügen Auf:',
  leads: 'Führt',
  yourBots: 'Ihre Bots',
  botName: 'Bot Name',
  platform: 'Plattform',
  contactOrLeads: 'Kontakte/Leads',
  actions: 'Aktionen',
  edit: 'bearbeiten',
  delete: 'löschen',
  createA: 'Ein ... kreieren',
  whatsappChatbot: 'WhatsApp Chatbot',
  connect: 'Verbinden',
  getStartedWithUs: 'Beginnen Sie mit uns 👏 👏',
  howToBuildAGoodChatFlow: ' So erstellen Sie einen guten Chatflow',
  howToUseBotPenguinInboxAndItsFeatures: ' So verwenden Sie den Posteingang von Botpenguin und seine Funktionen',
  botPenguinPricing: ' Preise von Botpenguin: Pläne und Funktionen erkunden',
  openHelpDesk: ' Helpdesk öffnen',
  helpAndSupport: 'Hilfe und Unterstützung',
  extensiveGuides: 'Umfangreiche Leitfäden für alle Ihre Fragen und Arbeitsabläufe sind in unserem verfügbar',
  resourceCentre: 'Ressourcenzentrum.',
  checkoutOurAnswers: 'Sie können unsere Antworten auf die gängigen Fragen unten überprüfen.',
  createANewBot: 'Erstellen Sie einen neuen Bot',
  installYourBot: 'Installieren Sie Ihren Bot',
  plansAndPricing: 'Pläne und Preisgestaltung',
  searchLanguage: 'Suchsprache',
  whatsappConversations: 'WhatsApp -Gespräche',
  diveDeeperIntoBotPenguin: 'Tauchen Sie tiefer in Botpenguin ein',
  knowWhereWeAreGoing: 'Wissen Sie, wohin wir gehen, die jüngsten Updates und helfen Sie uns, einige Fehler zu beheben.',
  productUpdates: 'Produktaktualisierungen',
  requestAFeature: 'Fordern Sie eine Funktion an',
  reportABug: 'Melde einen technischen Fehler',
  productRoadmap: 'Produkt-Roadmap',
  contactsOrLeads: 'Kontakte/Leads',
  english: 'Englisch',
  hindi: 'Hindi',
  german: 'Deutsch',
  french: 'Französisch',
  portuguese: 'Portugiese',
  spanish: 'Spanisch',
  italian: 'Italienisch',
  russian: 'Russisch',
  pleaseSelectYourReason: 'Bitte wählen Sie Ihren Grund aus',
  cancel: 'Stornieren',
  submit: 'Einreichen!',
  giveFeedback: 'Feedback geben',
  ratings: 'Bewertungen',
  reasonBehindYourDecision: 'Grund für Ihre Entscheidung',
  shareYourExperience: 'Teile deine Erfahrung',
  reason1: 'Grund1',
  reason2: 'Grund2',
  appTooltip: {
    HOME_INSIGHT: 'Geben Sie einen Einblick in alle Nachrichten und Leads Ihres Bots (Daten haben alle Mitternacht aktualisiert)',
    HOME_MESSAGES: 'Nachrichten, die während dieses Abrechnungszyklus von Ihrem Konto konsumiert werden. (Nachrichten von WhatsApp, Facebook und Telegram Bot sind ausgeschlossen.)',
    HOME_TOTAL_MESSAGES: 'Gesamtnachrichten, die Ihr Konto seit der Kontoerstellung verbraucht haben (Nachrichten von WhatsApp, Facebook und Telegram -Bot werden ausgeschlossen.)',
    LEADS: 'Leads, die während dieses Abrechnungszyklus von Bots für Ihr Konto erfasst wurden.',
    TOTAL_LEADS: 'Gesamtkontakte/Leads, die von Bots für Ihr Konto seit der Kontoerstellung erfasst wurden (Daten werden alle um Mitternacht aktualisiert).',
    YOUR_BOT: 'Überblick über alle Ihre Bots und ihre Verwendung während dieses Abrechnungszyklus.',
    NAME: 'Bots Name',
    PLATFORM: `Bot's Plattform`,
    MESSAGES: 'Bots Gesamtnachrichten seit der Erstellung von Konto',
    CONTACTS: 'Bots Gesamtkontakte/-leitungen',
    ACTIONS: 'Bearbeiten/löschen Sie den Bot',
    EDIT_ICON: 'Bearbeiten Sie den Bot -Chat -Fluss und die Einstellungen',
    DELETE_ICON: 'Löschen Sie den Bot',
    TOOL_TIP_CLASSIC_VERSION: 'alte Version von $ {Brand} ui',
  },
  modal: {
    deleteBot: 'Bot löschen!',
    areYouSure: 'Sind Sie sicher, dass Sie diesen Bot löschen möchten?',
    enquiryForm: 'Anfrageformular',
    payNow: 'Zahlen Sie jetzt!',
    saveAndDelete: 'Speichern und löschen',
    submit: 'Einreichen',
  },
  noDataFound: {
    bots: 'Bots',
    noBotCreatedYet: 'Noch keine Bots geschaffen!',
  },
  yourFriendWillMissYou: 'Dein Freund wird dich vermissen',
  rateYourBotCreationExperience: 'Bewerten Sie Ihre Bot -Kreationserfahrung',
  whatMadeYouDeleteYourBot: 'Was hat Sie dazu gebracht, Ihren Bot zu löschen?',
  iCreatedByMistake: 'Ich habe versehentlich erstellt',
  iWantToCreateAnotherBot: 'Ich möchte einen weiteren Bot erstellen',
  couldNotFindFeaturesThatINeeded: ',',
  uiWasHardToNavigate: 'UI war schwer zu navigieren',
  iDonotWantToTell: 'Ich möchte es nicht erzählen',
  other: 'Andere',
  weWillDoOurBestToMakeAutomationFun: 'Wir werden unser Bestes tun, um die Automatisierung für alle Spaß und einfach zu machen.',
};
