import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule2 } from './appv2/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// const script = document.createElement('script');
// script.src = `https://www.google.com/recaptcha/api.js?render=${environment.recaptchaSiteKey}`;
// document.head.appendChild(script);

platformBrowserDynamic().bootstrapModule(AppModule2);

