export const inboxMerged = {
  inboxHeader: 'Postvak IN',
  chatsHeader: 'Chats',
  contactsHeader: 'Contacten',
  segmentsHeader: 'Groepen',
  ordersHeader: 'Bestellingen',
  exportStatus: 'Exportstatus',
  tasksExport: 'Taken - Export',
  download: 'Download',
  ourAllNewOmniChannelInbox: 'Onze geheel nieuwe omnichannel-inbox!',
  allYourMessagesContactsAndLeads: 'Al uw berichten, contacten en leads bevinden zich nu op één plek. Klaar om gesorteerd en geGroepd te worden, om uw bedrijf te laten groeien!',
  close: 'Sluiten',
  takeALook: 'Neem een kijkje',
  inProgress: 'in uitvoering',
  appTooltip: {
    manageAllYourLeadsHere: 'Beheer al uw leads/contacten hier',
    segmentYourUserLeads: 'Groep uw leads/bot-gebruikers op basis van demografische gegevens en interesses. Deze worden gebruikt bij het maken van uitzendingen.',
    manageAllBotsConversationsHere: 'Beheer hier alle gesprekken van de bots',
    manageAllYourOrdersHere: 'Beheer al uw bestellingen hier',
  },
  contacts: {
    seeHowToUse: 'Bekijk hoe het werkt',
    help: 'Hulp',
    visitorName: 'Naam van de Bezoeker',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    segment: 'Groep',
    status: 'Toestand',
    tag: 'Tag',
    action: 'Actie',
    discard: 'Annuleren',
    addTag: 'Tag Toevoegen',
    loadMore: 'Meer Laden',
    nA: 'Niet Beschikbaar',
    assign: 'Toewijzen',
    deleteUser: 'Gebruiker Verwijderen',
    areYouSureToDeleteLead: 'Weet u zeker dat u deze lead wilt verwijderen?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'U heeft nog geen contacten!',
      allOfYourContactsAppearHere: 'Al uw contacten zullen hier verschijnen'
    },
    placeholders: {
      searchSegmentByName: 'Zoek Groep op naam',
      searchOrAddTag: 'Zoeken of Tag Toevoegen',
    },
    addContacts: {
      name: 'Naam',
      email: 'E-mail',
      enterAValidEmail: 'Voer een geldig e-mailadres in',
      phoneNumber: 'Telefoonnummer',
      cancel: 'Annuleren',
      addContact: 'Contact Toevoegen',
      placeholders: {
        enterEmailAddress: 'Voer e-mailadres in',
        enterUserrName: 'Voer gebruikersnaam in',
        searchCountry: 'Zoek Land',
        enterPhoneNumber: 'Voer Telefoonnummer in',
      }
    }
  },
  inboxFilter: {
    applied: 'toegepast',
    assignTags: 'Tags Toewijzen',
    discard: 'Annuleren',
    addTag: 'Tag Toevoegen',
    assignSegments: 'Groepen Toewijzen',
    filterYourLeads: 'Filter uw leads',
    filters: 'filters',
    filter: 'filter',
    resetAllFilters: 'Reset alle filters',
    importContacts: 'Contacten Importeren',
    beforeYouStart: 'Voordat u begint met uploaden, zorg ervoor dat',
    point1: '1. Het voorbeeld Excel-bestand downloaden.',
    point2: '2. Begin met het invoeren van uw gegevens vanaf de 2e rij.',
    point3: '3. In elke rij zal er gegevens zijn voor één contact',
    point4: '4. Zorg ervoor dat alle telefoonnummers uniek zijn.',
    point5: '5. De maximale bestandsgrootte voor upload is 1MB',
    selectFile: 'Selecteer Bestand',
    clickHere: 'Klik hier',
    toDownloadSampleFile: 'om het voorbeeldbestand te downloaden',
    modal: {
      closeLeads: 'Leads Sluiten',
      areYouSureToselectAllLeads: 'Weet u zeker dat u alle geselecteerde leads wilt sluiten?',
      assignAgent: 'Agent Toewijzen',
      toAssignAgentToAllLeads: 'Weet u zeker dat u een agent wilt toewijzen aan alle geselecteerde leads?',
      assignTag: 'Tag Toewijzen',
      wantToReplacePreviouslyAssignedTagWithNew: 'Weet u zeker dat u de eerder toegewezen tags wilt vervangen door de nieuwe gekozen tag voor alle geselecteerde leads?',
      deleteLeads: 'Leads Verwijderen',
      wantToDeleteSelectedLeads: 'Weet u zeker dat u alle geselecteerde leads wilt verwijderen?',
      exportData: 'Gegevens Exporteren',
      exportDataBasedOn: 'Gegevens exporteren op basis van de ',
      assignSegment: 'Groep Toewijzen',
      wantToAssignSegmentsToSelectedLeads: 'Weet u zeker dat u Groepen wilt toewijzen aan alle geselecteerde leads?',
      yesGoAhead: 'Ja, Ga Door!',
      export: 'Exporteren'
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Gegevens filteren op basis van uw voorkeuren, zoals tags, bot, status, etc.',
      markLeadAsClosed: 'Markeer gebruiker/lead als gesloten',
      assignAgentToLead: 'Wijs agent toe aan gebruiker',
      assignTagToUser: 'Wijs tag toe aan gebruiker',
      exportData: 'Gegevens Exporteren',
      importContactsForWhatsappBot: 'Contacten importeren voor WhatsApp-bot',
      addWhatsappContact: 'WhatsApp-contacten toevoegen',
      assignSegmentToUser: 'Wijs Groep toe aan gebruiker',
      deleteUsers: 'Gebruikers verwijderen',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Type naam, e-mail of telefoon en druk op enter',
      searchAgentByName: 'Zoek agent op naam',
      searchOrAddTag: 'Zoeken of Tag Toevoegen',
      searchSegmentByName: 'Zoek Groep op naam',
    },
    filterContainer: {
      filterOn: 'Filter op',
      filterBy: 'Filteren op',
      startFrom: 'Beginnen vanaf',
      tillDate: 'Tot datum',
      offlineOrOnline: 'Offline/Online',
      liveChat: 'Live Chat',
      userInteractedWithBot: 'Gebruiker Geïnteracteerd Met Bot',
      discard: 'Annuleren',
      applyFilter: 'Filter Toepassen',
      bots: 'Bots',
      tags: 'Tags',
      status: 'Status',
      assignedTo: 'Toegewezen aan',
      lastMessageBy: 'Laatst Bericht Door',
      onlineOrOffline: 'Online/Offline',
      userInteracted: 'Gebruiker geïnteracteerd',
      segments: 'Groepen',
      lastSeenDate: 'Laatst gezien datum',
      onboardingDate: 'Aanmeldingsdatum',
      createTags: 'Tags Maken',
      createTagsToSortAndManageLeads: 'Creëer tags om uw leads gemakkelijk te sorteren en te beheren',
      typeYourTagHere: 'Type uw tag hier',
      createSegment: 'Groep Maken',
      manageSegmentForMarketingAutomation: 'Beheer uw Groepen voor marketingautomatisering',
      typeYourSegmentHere: 'Type uw Groep hier',
      placeholders: {
        searchFilter: 'Zoek filter',
        searchByBotName: 'Zoek op bot-naam',
        searchOrAddTag: 'Zoeken of Tag Toevoegen',
        searchByAgentName: 'Zoek op agentnaam',
        searchBySegments: 'Zoek op Groepen',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Selecteer de einddatum van het datumbereik',
        dateRangeYouWishToStartSelectionFrom: 'Selecteer de begindatum van het datumbereik',
        filterThoseThatAreOfflineOrOnline: 'Filter de gebruikers die offline/online zijn',
        filterThoseThatAreCurrentlyLiveChatting: 'Filter de gebruikers die momenteel live chatten',
        filterThoseThatInteractedWithBot: 'Filter de gebruikers die met de bot hebben geïnteracteerd'
      },
      indexMergeDatePicker: {
        selectStartDate: 'Selecteer begindatum',
        selectEndDate: 'Selecteer einddatum',
      }
    }
  },
  leads: {
    seeHowToUse: 'Bekijk hoe te gebruiken',
    help: 'Hulp',
    visitorName: 'Bezoeker Naam',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    assignedTo: 'Toegewezen aan',
    status: 'Status',
    tag: 'Tag',
    action: 'Actie',
    discard: 'Verwerpen',
    addTag: 'Tag Toevoegen',
    loadMore: 'Meer Laden',
    nA: 'NA',
    deleteUsers: 'Gebruiker Verwijderen',
    areYouSureToDeleteLead: 'Weet u zeker dat u deze lead wilt verwijderen?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Je hebt nog geen chats',
      allOfYourChatsAppearHere: 'Al je chats verschijnen hier',
    },
    placeholders: {
      searchAgentByName: 'Zoek agent op naam',
      searchStatusByName: 'Zoek status op naam',
      searchOrAddTag: 'Zoeken of Tag Toevoegen',
    }
  },
  messages: {
    seeHowToUse: 'Bekijk hoe te gebruiken',
    help: 'Hulp',
    editChatFlow: 'Chatflow bewerken',
    connect: 'Verbinden',
    chat: {
      photo: 'Foto',
      video: 'Video',
      document: 'Document',
      location: 'Locatie',
      assignedTo: 'Toegewezen aan',
      selectBot: 'Selecteer Bot',
      discard: 'Verwerpen',
      addTag: 'Tag Toevoegen',
      yourBrowserDoesNotSupportVideoTag: 'Uw browser ondersteunt de videotag niet.',
      userHasSharedADocument: 'Gebruiker heeft een document gedeeld.',
      clickHereToDownload: 'Klik hier om te downloaden',
      thisStoryIsNoLongerAvailable: 'Dit verhaal is niet langer beschikbaar',
      yourBrowserDoesNotSupportAudioElement: 'Uw browser ondersteunt het audio-element niet.',
      thisMessageIsNoLongerAvailable: 'Dit bericht is niet langer beschikbaar omdat het door de afzender is ingetrokken',
      userHasSharedHisLocation: 'Gebruiker heeft zijn locatie gedeeld. Klik hier om te bekijken',
      sms: 'SMS',
      failed: 'Mislukt',
      a: 'A',
      i: 'Ik',
      r: 'R',
      m: 'M',
      conversationsSelected: 'gesprekken geselecteerd',
      nA: 'NA',
      assignTagsCloseOrBulkReply: 'Tags toewijzen, sluiten of bulkantwoord in één keer',
      appTooltip: {
        assignAgent: 'Agent toewijzen',
        selectBot: 'Selecteer Bot',
        assignTagsToManageLeads: 'Tags toewijzen om leads en gesprekken te beheren',
      },
      placeholders: {
        searchAgentByName: 'Zoek agent op naam',
        searchBotByName: 'Zoek Bot op naam',
        searchOrAddTag: 'Zoeken of Tag Toevoegen',
        searchStatusByName: 'Zoek status op naam',
      }
    },
    profile: {
      userDetails: 'Gebruikersgegevens',
      orderDetails: 'Bestellingsdetails',
      orderedOn: 'Besteld op',
      showMore: 'Toon Meer',
      showLess: 'Toon Minder',
      subTotal: 'Subtotaal',
      nA: 'NA',
      addAWhatsappNumber: 'Voeg Whatsapp-nummer toe',
      addTelegramUserName: 'Voeg Telegram-gebruikersnaam toe',
      addInstagramProfileId: 'Voeg Instagram-profiel-ID toe',
      addFacebookProfileId: 'Voeg Facebook-profiel-ID toe',
      add: 'Toevoegen',
      deviceProperties: 'Apparaateigenschappen',
      privateNotes: 'Privénotities',
      urlsVisited: 'Bezochte URL\'s',
      source: 'Bron - ',
      sourceNa: 'Bron - NA',
      medium: 'Medium - ',
      mediumNa: 'Medium - NA',
      campaign: 'Campagne - ',
      campaignNa: 'Campagne - NA',
      attributeKey: 'Attribuutsleutel',
      attributeName: 'Attribuutnaam',
      discard: 'Verwerpen',
      addAttribute: 'Attribuut Toevoegen',
      editAttribute: 'Attribuut Bewerken',
      deleteAttribute: 'Attribuut Verwijderen',
      headsUp: 'Let op',
      areYouSureToDeleteAttribute: 'Weet u zeker dat u dit attribuut wilt verwijderen?',
      okay: 'Oké',
      dataAddedToLead: `De aan de API's of Bot Flows gekoppelde attributen worden niet bijgewerkt. Werk de API's of Bot Flows dienovereenkomstig bij`,
      assignedOnly: 'Alleen Toegewezen',
      allUsers: 'Alle Gebruikers',
      save: 'Opslaan',
      manageCustomAttributes: 'Beheer Attributen',
      placeholders: {
        title: 'Titel',
        egServices: 'Bijv. Diensten',
        egRequestedServices: 'Bijv. Aangevraagde Diensten',
        enterYourNotesHere: 'Voer hier uw notities in ...'
      },
      bpDropDown: {
        selectBot: 'Selecteer Bot',
        selectFlow: 'Selecteer Flow',
        selectQuestion: 'Selecteer Vraag*'
      }
    },
    sendMessage: {
      send: 'Verstuur',
      sendToAllChannels: 'Verstuur naar alle kanalen',
      email: 'E-mail',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      facebook: 'Facebook',
      sendSms: 'Verstuur SMS',
      addNew: 'Nieuwe Toevoegen',
      addNewQuickReply: 'Voeg Nieuwe Snelle Reactie Toe',
      discard: 'Verwerpen',
      fromComputer: 'VANAF COMPUTER',
      add: 'Toevoegen',
      dragAndDropHereOr: 'Drag & Drop hier of',
      browseFiles: 'Blader door bestanden',
      caption: 'Bijschrift',
      templates: 'Sjablonen',
      addHeaderMedia: 'Voeg kopmedia toe',
      selectFile: 'Selecteer Bestand',
      previousConversationClosed: 'Vorig gesprek gesloten!',
      sendTemplateMessage: 'Verstuur sjabloongesprek om het gesprek opnieuw te starten.',
      selectTemplate: 'Selecteer Sjabloon',
      conversationLocked: 'Gesprek vergrendeld!',
      demoBotOnlyAllowConversations: 'Demo bot staat alleen gesprekken toe met het nummer dat u eerder heeft geverifieerd.',
      placeholders: {
        searchQuickReplyByTitle: 'Zoek Snelle Reacties op titel',
        addTitle: 'Titel Toevoegen',
        addYourRepliesHere: 'Voeg hier uw reacties toe... ',
        addCaption: 'Bijschrift Toevoegen',
        enterValueForThisVariable: 'Voer waarde in voor deze variabele',
      },
      modal: {
        sendBulkMessages: 'Verstuur Bulkberichten',
        send: 'Verstuur',
        description: 'Uw bericht wordt schoongemaakt en verstuurd op basis van het type platform waar een gebruiker toe behoort.' +
          '\n - Gebruikers van de website ondersteunen alleen tekstberichten \n - Whatsapp (niet-geabonneerde) contacten kunnen alleen sjabloongesprekken ontvangen'
      },
      noDataFound: {
        oops: 'Oeps!',
        currentlyDoesNotSupportBulkMessages: 'Op dit moment ondersteunen we geen bulkberichten voor WhatsApp-bots. Gebruik de uitzendfunctie',
        quickReplies: 'Snelle Reacties',
        noQuickRepliesAddedYet: 'Nog geen snelle reacties toegevoegd!',
      }
    },
    userList: {
      selectAll: 'Selecteer Alles',
      nA: 'NA',
      loadMore: 'Meer Laden',
    }
  },
  segments: {
    seeHowToUse: 'Bekijk hoe te gebruiken',
    help: 'Help',
    manageSegment: 'Beheer Groep',
    manageYourSegmentsForMarketingAutomation: 'Beheer uw Groepen voor marketingautomatisering',
    createNewSegment: 'Maak Nieuw Groep Aan',
    segmentName: 'Groep Naam',
    totalContacts: 'Totaal Aantal Contacten',
    action: 'Actie',
    createSegmentButton: 'Maak Groep Aan',
    deleteSegments: 'Verwijder Groepen',
    areYouSureToDeleteSegment: 'Weet u zeker dat u dit Groep wilt verwijderen?',
    placeholders: {
      searchHere: 'Zoek hier',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'U heeft nog geen Groepen aangemaakt',
      allYourSegmentsAppearHere: 'Al uw Groepen verschijnen hier',
    },
    modal: {
      exportData: 'Exporteer Data',
      exportDataBasedOnAppliedFilters: 'Exporteer data op basis van toegepaste filters',
      export: 'Exporteer',
    },
    appTooltip: {
      deleteSegments: 'Verwijder Groepen',
      editSegment: 'Groep Bewerken',
      downloadSegment: 'Groep Downloaden',
      deleteSegment: 'Groep Verwijderen',
    },
    createSegment: {
      createSegment: 'Maak Groep Aan',
      manageYourSegmentsForMarketingAutomation: 'Beheer uw Groepen voor marketingautomatisering',
      segmentName: 'Groep Naam',
      segmentNameIsRequired: 'Groep Naam is verplicht',
      selectContactsFilterForSegment: 'Selecteer contactfilters voor het Groep',
      filterYourContacts: 'Filter uw contacten',
      removeAllFilters: 'Verwijder alle filters',
      addFilters: 'Voeg Filters Toe',
      discard: 'Verwerpen',
      filterSelected: 'Filters Geselecteerd',
      placeholders: {
        enterSegmentName: 'Voer Groep Naam in',
      },
    },
  },
  orders: {
    visitorName: 'Bezoeker Naam',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    status: 'Status',
    itemCount: 'Aantal Items',
    total: 'Totaal',
    tag: 'Tag',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'U heeft nog geen bestellingen',
      allOfYourOrdersAppearHere: 'Al uw bestellingen verschijnen hier',
    },
  }
};
