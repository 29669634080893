export const agency = {
  activities: {
    activities: 'Activities',
    upcomingRenewals: 'Upcoming Renewals',
    subscriptionActivities: 'Subscription Activities',
    upcomingDowngradeRequests: 'Upcoming Downgrade Requests',
    serialNumber: 'Sr. No.',
    email: 'Email',
    phoneNumber: 'Phone No.',
    currentPlan: 'Current Plan',
    price: 'Price',
    daysLeft: `Day's Left`,
    noDataFound: {
      upcomingDowngrades: 'Upcoming Downgrades',
      noUpcomingDowngradesFoundYet: 'No Upcoming Downgrades found Yet!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Upcoming renewals of the customers',
      subscriptionActivities: 'Subscription activities',
      upcomingDowngradeRequestsOfCustomers: 'Upcoming downgrade requests of the customers',
      serialNumber: 'Serial number',
      customerEmail: 'Customer  email',
      customerPhoneNumber: 'Customer phone number',
      currentPlanOfCustomer: 'Current plan of the customer',
      currentPlanPrice: 'Current plan price',
      daysLeft: `Day's left of the customer's current plan`
    },
  },
  configuration: {
    configurationsHeader: 'Configurations',
    emailHeader: 'Email',
    razorPayHeader: 'RazorPay',
    invoiceDetailsHeader: 'Invoice Details',
    stripeHeader: 'Stripe',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Integrate your email provider details to send customer emails seamlessly.',
      enterYourRazorPayAccountDetails: 'Enter your RazorPay account details for seamless customer payment processing.',
      enterYourStripeAccountDetails: 'Enter your Stripe account details for seamless customer payment processing.',
      enterYourCompanyDetails: 'Enter your company details for accurate invoicing and professional invoice generation.',
    },
    email: {
      testEmail: 'Test Email',
      addEmailIntegrationsDetails: 'Add Email Integration Details',
      integrationDescription: 'To integrate your email provider details, please provide your email provider information.' +
      'All communication emails will be sent to your customers using this email address, ensuring consistent and' +
      'reliable delivery of important notifications and updates.',
      awsRegion: 'AWS Region',
      awsAccessKeyId: 'AWS Access Key Id',
      awsSecretAccessKey: 'AWS Secret Access Key',
      reset: 'Reset',
      addDetails: 'Add Details',
      updateDetails: 'Update Details',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Enter your AWS Region. The region determines the physical location of your resources and affects factors such as data latency and regulatory compliance.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'Enter your AWS Access Key ID. This key uniquely identifies your AWS account and is required for secure authentication and access to AWS services.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Enter your AWS Secret Access Key. This key is used for secure authentication and access to AWS services.'
      },
      placeholders: {
        enterAwsRegion: 'Enter AWS Region',
        enterAwsAccessKeyId: 'Enter AWS Access Key Id',
        enterAwsSecretAccessKey: 'Enter AWS Secret Access Key',
      },
      modal: {
        deleteEmailIntegration: 'Delete Email Integration',
        wantToDeleteEmailIntegration: 'Are you sure you want to delete this Email Integration?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Add Facebook Integration Details',
      integrateYourEmailProviderDetails: 'Integrate your email provider details. All the emails will be sent using this email to your customers',
      facebookAppId: 'Facebook App Id',
      facebookSecretAccessKey: 'Facebook Secret Access Key',
      reset: 'Reset',
      cancel: 'Cancel',
      addDetails: 'Add Details',
      updateDetails: 'Update Details',
      delete: 'Delete',
      testEmail: 'Test Email',
      modal: {
        deleteFacebookIntegration: 'Delete Facebook Integration',
        wantToDeleteFacebookIntegration: 'Are you sure you want to delete this Email Integration?'
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Enter Facebook Secret Access Key',
        enterFacebookAppId: 'Enter Facebook App ID'
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Customize message as you wish it to appear on chat window immediately after a bot user sends request for Live Chat',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Add Invoice Details',
      invoicesDescription: 'To generate invoices for your customers, please provide your company details, including your company name,' +
      'address, contact information, and any other relevant information required for accurate invoicing.' +
      'This information will be used to create professional invoices on behalf of your company for each transaction.',
      companyName: 'Company Name',
      country: 'Country',
      selectCountry: 'Select Country',
      stateOrRegion: 'State/Region',
      streetAddress: 'Street Address',
      gstin: 'GSTIN',
      postalCode: 'Postal Code',
      addDetails: 'Add Details',
      updateDetails: 'Update Details',
      reset: 'Reset',
      delete: 'Delete',
      loadMore: 'Load More',
      time: 'Time',
      phoneNumber: 'Phone Number',
      email: 'Email',
      status: 'Status',
      message: 'Message',
      response: 'Response',
      placeOfSupply: 'Place of Supply',
      placeholders: {
        enterYourCompanyName: 'Enter your company name',
        enterYourStateOrRegion: 'Enter your state/region',
        enterYourStreetAddress: 'Enter your street address',
        enterYourGstin: 'Enter your GSTIN',
        enterPostalCode: 'Enter postal code',
        enterPlaceOfSupply: 'Enter place of supply'
      },
      modal: {
        deleteEmailIntegration: 'Delete Email Integration',
        wantToDeleteEmailIntegration: 'Are you sure you want to delete this Email Integration?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME:
         'Enter your company name. This information will be used for identification and branding purposes in invoices.',
        TOOLTIP_COUNTRY: 'Select your Country. This information will be used for identification and branding purposes in invoices.',
        TOOLTIP_STATE: 'Enter your State. This information will be used for identification and branding purposes in invoices.',
        TOOLTIP_STREET_ADDRESS: 'Enter your Street Address. This information will be used for identification and branding purposes in invoices.',
        TOOLTIP_GSTIN: 'Enter your GSTIN. This information will be used for identification and branding purposes in invoices.',
        TOOLTIP_POSTAL_CODE: 'Enter your Postal Code. This information will be used for identification and branding purposes in invoices.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Enter your Place Of Supply. This information will be used for identification and branding purposes in invoices.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'Add RAZORPAY Integration Details',
      integrationDescription: 'To integrate your RazorPay account details, please provide your RazorPay account information.' +
      ' All customer payments will be processed through your RazorPay account for seamless transactions',
      razorPayAccessKeyId: 'RAZORPAY Access Key Id',
      razorPaySecretAccessKey: 'RAZORPAY Secret Access Key',
      reset: 'Reset',
      addDetails: 'Add Details',
      updateDetails: 'Update Details',
      loadMore: 'Load More',
      time: 'Time',
      phoneNumber: 'Phone Number',
      email: 'Email',
      status: 'Status',
      message: 'Message',
      response: 'Response',
      placeholders: {
        enterRazorPayAccessKeyId: 'Enter Razorpay Access Key Id',
        enterRazorPaySecretAccessKey: 'Enter Razorpay Secret Access Key',
        enterRazorPayAccessId: 'Enter RazorPay Access Id',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Enter your Razorpay Access Key ID. This key uniquely identifies your integration' +
        ' with Razorpay and is required for secure payment transactions.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Enter your Razorpay Secret Access Key. This key is essential for secure communication between' +
        'application and Razorpay, enabling seamless transaction processing and payment management.',
      },
      modal: {
        deleteRazorPayIntegration: 'Delete Email Integration',
        wantToDeleteThisIntegrtion: 'Are you sure you want to delete this Email Integration?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Add Stripe Integration Details',
      integrationDescription: 'To integrate your Stripe account details, please provide your Stripe account information.' +
      ' All customer payments will be processed through your Stripe account for seamless transactions',
      stripeSecretAccessKey: 'Stripe Secret Access Key',
      stripePublicKey: 'Stripe Public Key',
      reset: 'Reset',
      addDetails: 'Add Details',
      updateDetails: 'Update Details',
      cancel: 'Cancel',
      loadMore: 'Load More',
      time: 'Time',
      phoneNumber: 'Phone Number',
      email: 'Email',
      status: 'Status',
      message: 'Message',
      response: 'Response',
      placeholders: {
        enterStripeSecretAccessKey: 'Enter Stripe Secret Access Key',
        enterStripePublicKey: 'Enter Stripe Public Key',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Enter your Stripe Secret Access Key. This key is used to securely authenticate your' +
        ' integration with Stripe and is necessary for processing customer payments.',
        TOOLTIP_PUBLIC_KEY: 'Enter your Stripe Public Key. This key is used to securely authenticate your integration with' +
         ' Stripe on client side and is necessary for processing customer payments.',
      },
      modal: {
        deleteStripeIntegration: 'Delete Stripe Integration',
        wantToDeleteStripeIntegration: 'Are you sure you want to delete this Stripe Integration?'
      }
    }
  },
  customer: {
    manageCustomers: 'Manage Customers',
    addNewCustomers: 'Add new Customers',
    ensureYouHaveAddedAllYourCustomersHere: `Ensure you've added all your customers here`,
    customerName: 'Customer Name',
    nameIsRequired: 'Name is required',
    emailId: 'Email ID',
    enterAValidEmail: 'Enter a valid email',
    createPassword: 'Create Password',
    enterAStrongPassword: 'Enter a strong password',
    confirmPassword: 'Confirm Password',
    cancel: 'Cancel',
    customer: 'Customer',
    searchByNameOrEmail: 'Search by name or email',
    filters: 'Filters',
    allCustomers: 'All Customers',
    paidCustomers: 'Paid Customers',
    freeCustomers: 'Free Customers',
    messageAddOnCustomers: 'Message Add On Customers',
    botWhiteLabelCustomers: 'Bot Whitelabel Customers',
    sortBy: 'Sort by',
    sortByFrequency: 'Sort by frequency',
    all: 'All',
    joinedDate: 'Joined Date',
    plan: 'Plan',
    lastLogin: 'Last Login',
    email: 'Email',
    nextDue: 'Next Due',
    frequency: 'Frequency',
    clearAll: 'Clear all',
    apply: 'Apply',
    messageConsumed: 'Message Consumed',
    addCustomer: 'Add Customer',
    serialNumber: 'Sr. No.',
    name: 'Name',
    contact: 'Contact',
    limit: 'Limit',
    location: 'Location',
    actions: 'Actions',
    joinedOn: 'Joined On',
    typeOfCustomer: 'Type of customer',
    exceptWhatsapp: ' (Except Whatsapp)',
    exceptWA: '(Except WA)',
    wa: '(WA)',
    loadMore: 'Load More',
    conversationsWhatsapp: ' Conversations (Whatsapp)',
    appTooltip: {
      enterCustomerEmail: 'Enter customer email',
      enterCustomerPassword: 'Enter customer password',
      enterCustomerConfirmPassword: 'Enter customer confirm password',
      enterCustomerName: 'Enter customer name',
    },
    placeholders: {
      enterCustomerEmail: 'Enter customer email',
      searchByNameOrEmail: 'Search by name or email',
      enterPassword: 'Enter password',
      enterCustomerName: 'Enter customer name',
    }
  },
  dashboard: {
    dashboard: 'Dashboard',
    overview: 'Overview',
    totalCustomers: 'Total Customers',
    paidCustomers: 'Paid Customers',
    freeCustomers: 'Free Customers',
    thisWeeksSignUp: `This week's Sign-up`,
    todaysSignUp: `Today's Sign-ups`,
    totalEarnings: 'Total Earnings',
    nA: 'NA',
    customerName: 'Customer name',
    nameIsRequired: 'Name is required',
    emailId: 'Email ID',
    enterAValidEmail: 'Enter a valid email',
    editCustomer: 'Edit Customer',
    recentSignUps: 'Recent Sign Ups',
    serialNumber: 'Sr. No.',
    name: 'Name',
    email: 'Email',
    contact: 'Contact',
    plan: 'Plan',
    limit: 'Limit',
    location: 'Location',
    lastLogin: 'Last Login',
    joinedOn: 'Joined On',
    actions: 'Actions',
    exceptWhatsapp: ' (Except Whatsapp)',
    messageConsumed: 'Message Consumed',
    conversationsWhatsapp: ' Conversations (Whatsapp)',
    placeholders: {
      enterCustomerName: 'Enter customer name',
      enterCustomerEmail: 'Enter customer email',
    },
    modal: {
      deleteCustomer: 'Delete Customer',
      areYouSure: 'Are you sure you want to delete this customer?'
    }
  },
  downgradeRequests: {
    activites: 'Activities',
    upcomingRenewals: 'Upcoming Renewals',
    subscriptionActivities: 'Subscription Activities',
    upcomingDonwgradeRequests: 'Upcoming Downgrade Requests',
    serialNumber: 'Sr. No.',
    email: 'Email',
    phoneNumber: 'Phone No.',
    currentPlan: 'Current Plan',
    requestedOn: 'Requested On',
    downgradeOn: 'Downgrade On',
    nA: 'NA',
    commaMonthly: ', Monthly',
    commaYearly: ' , Yearly',
    noDataFound: {
      downgradeRequests: `Downgrade Request's`,
      noDowngradeRequestFound: `No Downgrade Request's found Yet!`,
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Upcoming renewals of the customers',
      subscriptionActivities: 'Subscription activities',
      upcomingDowngradeOfCustomers: 'Upcoming downgrade requests of the customers',
      serialNumber: 'Serial number',
      customerEmail: 'Customer  email',
      customerPhoneNumber: 'Customer phone number',
      currentPlanOfCustomer: 'Current plan of the customer',
      currentPlanPrice: 'Current plan price',
    }
  },
  emailIntegration: {
    emailIntegration: 'Email Integration',
    addEmailIntegrationDetails: 'Add Email Integration Details',
    integrateYourEmailProviderDetails: 'Integrate your email provider details. All the emails will be sent using this email to your customers',
    awsRegion: 'AWS Region',
    awsRegionIsRequired: 'AWS Region is required',
    awsAccessKeyId: 'AWS Access Key Id',
    awsAccessKeyIdIsRequired: 'AWS Access Key Id is required',
    awsSecretAccessKey: 'AWS Secret Access Key',
    awsSecretAccessKeyIsRequired: 'AWS Secret Access Key is required',
    cancel: 'Cancel',
    test: 'Test',
    action: 'Action',
    testEmail: 'Test Email',
    addDetails: 'Add Details',
    updateDetails: 'Update Details',
    saveDetails: 'Save Details',
    placeholders: {
      enterAwsRegion: 'Enter AWS Region',
      enterAwsAccessKeyId: 'Enter AWS Access Key Id',
      enterAwsSecretAccessKey: 'Enter AWS Secret Access Key',
    },
    modal: {
      deleteEmailIntegration: 'Delete Email Integration',
      areYouSure: 'Are you sure you want to delete this Email Integration?',
    },
    noDataFound: {
      emailIntegration: 'Email Integration',
      noEmailIntegrationFoundYet: 'No Email Integration found Yet!'
    }
  },
  subscription: {
    activities: 'Activities',
    upcomingRenewals: 'Upcoming Renewals',
    subscriptionActivities: 'Subscription Activities',
    upcomingDonwgradeRequests: 'Upcoming Downgrade Requests',
    filters: 'Filters',
    manageActivities: 'Manage Activities',
    sortBy: 'Sort by',
    all: 'All',
    upgrades: 'Upgrades',
    donwgrades: 'Downgrades',
    renewals: 'Renewal',
    serialNumber: 'Sr. No.',
    email: 'Email',
    phoneNumber: 'Phone No.',
    previousPlan: 'Previous Plan',
    newPlan: 'New Plan',
    description: 'Description',
    date: 'Date',
    nA: 'NA',
    upgraded: 'upgraded',
    commaYearly: ' , Yearly',
    commaMonthly: ' , Monthly',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'No Subscription Activities found Yet!',
      subscriptionsActivities: 'Subscriptions Activities',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Upcoming renewals of the customers',
      subscriptionActivities: 'Subscription activities',
      upcomingDowngradeRequests: 'Upcoming downgrade requests of the customers',
      serialNumber: 'Serial number',
      customerEmail: 'Customer email',
      customerPhoneNumber: 'Customer phone number',
      previousPlan: 'Previous plan of the customer',
      newPlan: 'New plan of the customer',
      description: 'Description of the plan',
      typeOfPayment: 'Type of payment',
    }
  },
  feedbacks: {
    feedbacks: 'Feedbacks',
    serialNumber: 'Sr. No.',
    customer: 'Customer',
    type: 'Type',
    rating: 'Rating',
    reason: 'Reason',
    description: 'Description',
    createdAt: 'Created At',
    nA: 'NA',
    loadMore: 'Load More',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Account and bot Deletion Feedbacks',
      noFeedbacksFoundYet: 'No Feedbacks found Yet!',
    },
    appTooltip: {
      serialNumber: 'Serial number',
      customerEmail: 'Customer  email',
      feedbackForBotOrAccountDeletion: 'Feeback for (Bot/Account Deletion)',
      customerRating: 'Customer rating',
      customerReason: 'Customer reason',
      feedbackCreatedAt: 'Feedback created at',
      customerFeedback: 'Customer Feedback',
    }
  }
};
