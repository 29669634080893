import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { InboxFilter } from '../models/inbox/filter';
import { Segments } from '../models/inbox/segments';
import { InboxUser } from '../models/inbox/user';
import { NetworkService } from './network.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  resetFilters = new Subject();
  isExportStatusPopup = new Subject<false | true>();
  selectedUsers = new BehaviorSubject<string[]>([]);
  selectedUsersDetails = new BehaviorSubject<InboxUser[]>([]);
  allSelectedUsers = new BehaviorSubject<string[]>([]);
  liveChatRequestAccepted = new Subject<false | true>();
  appliedFilters = new BehaviorSubject(this.getDefaultFilters(true));
  currentFilters = new BehaviorSubject(this.getDefaultFilters(true));
  isExport = new BehaviorSubject<false | 'filtered' | 'all'>(false);
  preSelectedInboxUser = new BehaviorSubject<InboxUser | undefined>(undefined);
  initiateBulkActions = new Subject<
    { action: 'close' | 'assignAgent' | 'assignTag' | 'delete' | 'export' | 'import' | 'assignSegment', payload: string | string[] }
  >();
  editableSegment = new BehaviorSubject<Segments | undefined>(undefined);
  closeTablePopups = new BehaviorSubject<false | true>(false);
  closeFilterPopups = new BehaviorSubject<false | true>(false);
  inboxAddedNewTags = new BehaviorSubject<any>({});
  isCreateNew = new BehaviorSubject({ name: '', type: '' });
  redirectFormDemoBot = new BehaviorSubject<{
    isDemoBot: false | true, useCase: string, botName: string
  }>({ isDemoBot: false, useCase: '', botName: '' });

  constructor(
    public networkService: NetworkService
  ) { }

  getDefaultFilters(isInitial: boolean = false): InboxFilter {
    return {
      searchText: '',
      tags: [],
      status: [],
      isOnline: false,
      _botWebsite: [],
      _botWhatsapp: [],
      _botTelegram: [],
      _botFacebook: [],
      _agentAssigned: [],
      segments: [],
      applicableFilters: isInitial ? ['userInteracted'] : [],
      lastSeenAt: { startAt: '', endsAt: '' },
      createdAt: { startAt: '', endsAt: '' },
      userInteracted: isInitial,
      lastMessageBy: [],
      isLiveChatActive: false,
      hasOrdered: {
        status: false,
        lastAt: ''
      }
    };
  }

  async exportTranscript(userId) {
    return this.networkService.httpRequest(`aggregation/user/export-transcript?_user=${userId}`);
  }

  async getFacebookFlowQuestionNames(payload) {
    return this.networkService.httpRequest('facebook-automation/flows/flow-question-name', { ...payload }, 'POST', true);
  }

  async getWhatsAppFlowQuestionNames(payload) {
    return this.networkService.httpRequest('whatsapp-automation/flows/flow-question-name', { ...payload }, 'POST', true);
  }

  async getTelegramFlowQuestionNames(payload) {
    return this.networkService.httpRequest('telegram-automation/flows/flow-question-name', { ...payload }, 'POST', true);
  }

  async getInboxUsers(payload) {
    return this.networkService.httpRequest('aggregation/session/list', payload, 'POST', true);
  }

  async assignAgent(payload: { ids: string[], agentId: string }) {
    return this.networkService.httpRequest('inbox/users/assign/agent', payload, 'PUT', true);
  }

  async assignStatus(payload: { ids: string[], status: string, subscriberIds?: string[] }) {
    return this.networkService.httpRequest('inbox/users/status-update', payload, 'PUT', true);
  }

  async deleteUser(payload: object) {
    return this.networkService.httpRequest('aggregation/user', payload, 'DELETE', true);
  }

  async assignTags(payload: { ids: string[], tags: string[] }) {
    return this.networkService.httpRequest('inbox/users/assign/tags', payload, 'PUT', true);
  }

  async getTags() {
    return this.networkService.httpRequest('inbox/tags', undefined, 'GET', true);
  }

  async addTag(payload: { tag: string }) {
    return this.networkService.httpRequest('inbox/tags', { ...payload, status: 'ACTIVE' }, 'POST', true);
  }

  async updateInboxUsers(payload, userId: string) {
    return this.networkService.httpRequest(`inbox/users/${userId}`, payload, 'PUT', true);
  }

  async getWebsiteBotMessages(uuid: string) {
    return this.networkService.httpRequest('communication/messages?uuid=' + uuid, undefined, 'GET', true);
  }

  async getWhatsAppBotMessages(id: string) {
    return this.networkService.httpRequest(`whatsapp-automation/messages/subscriber-messages?id=${id}`);
  }

  async getFacebookBotMessages(id: string) {
    return this.networkService.httpRequest(`facebook-automation/messages/subscriber-messages?id=${id}`);
  }

  async getTelegramBotMessages(id: string) {
    return this.networkService.httpRequest(`telegram-automation/messages/subscriber-messages?id=${id}`);
  }

  async saveMessage(payload) {
    return this.networkService.httpRequest('communication/messages', payload, 'POST');
  }

  async getCustomAttributes() {
    return this.networkService.httpRequest(`inbox/attributes`, undefined, 'GET', true);
  }

  // async getCustomAttributesBySearch(search: string) {
  //   return this.networkService.httpRequest(`inbox/attributes?search=${search}`, undefined, 'GET', true);
  // }

  // async getCustomAttributes(page) {
  //   const queryParams = { page };
  //   return this.networkService.httpRequest('inbox/attributes', 'GET', true);
  // }

  async addCustomAttributes(payload) {
    return this.networkService.httpRequest('inbox/attributes', { ...payload, status: 'ACTIVE' }, 'POST', true);
  }

  async deleteCustomAttributes(attributesId) {
    return this.networkService.httpRequest(`inbox/attributes/${attributesId}`, undefined, 'DELETE', true);
  }

  async deleteMultipleCustomAttributes(payload) {
    return this.networkService.httpRequest('inbox/attributes', payload, 'DELETE', true);
  }

  async updateCustomAttributes(attributesId, payload) {
    return this.networkService.httpRequest(`inbox/attributes/${attributesId}`, payload, 'PUT', true);
  }

  async getQuickReplies() {
    return this.networkService.httpRequest('inbox/quick-reply', undefined, 'GET', true);
  }

  async addQuickReplies(payload: { title: string, description: string }) {
    return this.networkService.httpRequest('inbox/quick-reply', { ...payload, status: 'ACTIVE' }, 'POST', true);
  }

  async addContact(payload: any[]) {
    return this.networkService.httpRequest('inbox/users/import', payload, 'POST', true);
  }

  async exportInboxUser(payload) {
    return this.networkService.httpRequest('inbox/users/export', payload, 'POST', true);
  }

  async getActiveExports() {
    return this.networkService.httpRequest('inbox/users/export', undefined, 'GET', true);
  }

  async removeActiveExports() {
    return this.networkService.httpRequest('inbox/users/export/status-update', undefined, 'PUT', true);
  }

  async sendTelegramMessage(payload: {
    _bot: string; text: string; _subscriber: string;
    type: 'text' | 'photo' | 'video' | 'document';
    medias: { id: string, url: string, caption: string }[]
  }) {
    return this.networkService.httpRequest('telegram-automation/messages/v2/send-message', payload, 'POST');
  }

  async sendFacebookMessage(payload: {
    _bot: string; text: string; sid: string; _subscriber: string; sentBy: string;
    type: 'text' | 'image' | 'video' | 'file';
    medias: { url: string }[]
  }) {
    return this.networkService.httpRequest(`facebook-automation/messages/send-message`, payload, 'POST');
  }

  async sendWhatsappMessage(_bot, text, whatsAppNumber, _subscriber, isImported, type, sentBy, allImages?, userName?, inboxId?) {
    return this.networkService.httpRequest('whatsapp-automation/messages/v2/send-message',
      { _bot, text, whatsAppNumber, _subscriber, sentBy, type, isImported, medias: allImages, userName, inboxId }, 'POST');
  }

  async sendBulkWebsiteMessage(payload) {
    return this.networkService.httpRequest('communication/messages/bulk-message', payload, 'POST');
  }
  async getSegments() {
    return this.networkService.httpRequest('inbox/segments');
  }

  async createSegment(payload) {
    return this.networkService.httpRequest('inbox/segments', payload, 'POST');
  }

  async deleteSegments(payload) {
    return this.networkService.httpRequest('inbox/segments', payload, 'DELETE');
  }

  async editSegment(id: string, payload) {
    return this.networkService.httpRequest(`inbox/segments/${id}`, payload, 'PUT');
  }

  async assignSegments(payload: { ids: string[], segments: string[], type: 'added' | 'removed' }) {
    return this.networkService.httpRequest('inbox/users/assign/segments', payload, 'PUT', true);
  }
  async getInboxUser(uuid: string) {
    return this.networkService.httpRequest(`inbox/users/${uuid}`);
  }

  async sendBulkFacebookMessage(payload) {
    return this.networkService.httpRequest('facebook-automation/messages/bulk-message', payload, 'POST');
  }
  async sendBulkWhatsappMessage(payload) {
    return this.networkService.httpRequest('whatsapp-automation/messages/bulk-message', payload, 'POST');
  }
  async sendBulkTelegramMessage(payload) {
    return this.networkService.httpRequest('telegram-automation/messages/bulk-message', payload, 'POST');
  }

  async createWhatsAppSubscriber(payload) {
    return this.networkService.httpRequest('whatsapp-automation/subscribers', payload, 'POST');
  }

  async getInboxUserForWiget(payload) {
    return this.networkService.httpRequest(`inbox/users/whatsapp-user?whatsAppNumber=${payload}`);
  }

  async sendInstagramMessage(payload: {
    _bot: string; text: string; instagramProfileId: string; _subscriber: string; sentBy: string;
    type: 'text' | 'image' | 'video' | 'file';
    medias: { url: string }[]
  }) {
    return this.networkService.httpRequest(`instagram-automation/messages/send-message`, payload, 'POST');
  }

  async sendBulkInstagramMessage(payload) {
    return this.networkService.httpRequest('instagram-automation/messages/bulk-message', payload, 'POST');
  }

  async getInstagramBotMessages(id: string) {
    return this.networkService.httpRequest(`instagram-automation/messages/subscriber-messages?id=${id}`);
  }

  async transferToBot(payload) {
    return this.networkService.httpRequest('communication/messages/transfer/bot', payload, 'PUT');
  }

  getIsUserOnline(inboxUser) {
    let isUserOnline = true;
    const lastInteractionDate = moment(inboxUser?.lastMessage?.at);
    const pastOneHourWindow = moment().subtract(1, 'hours');
    if ((inboxUser.platform.includes('WEBSITE') && lastInteractionDate.isBefore(pastOneHourWindow)) || !inboxUser.isOnline) {
      isUserOnline = false;
    }
    return isUserOnline;
  }

  resetInboxMessageCount(): Promise<any> {
    return this.networkService.httpRequest('customer/read-messages', null, 'PUT');
  }

  resetInboxUserMessageCount(payload: { _id: string }) {
    return this.networkService.httpRequest('inbox/users/read-messages', payload, 'PUT');
  }

  getSubscribersIds(inboxUsers: InboxUser[], selectedUsers: string[]): SubscriptionIdsPayload {
    const subscriberIds = inboxUsers
      .filter(data =>
        selectedUsers.includes(data._id) &&
        (data._userWhatsapp || data._user || data._userFacebook || data._userTelegram || data._userWebsite)
      )
      .map(inboxUser => ({
        whatsappSubscriberIds: inboxUser._userWhatsapp || inboxUser._user,
        facebookSubscriberIds: inboxUser._userFacebook || inboxUser._user,
        telegramSubscriberIds: inboxUser._userTelegram || inboxUser._user,
        websiteSubscriberIds: inboxUser._userWebsite || inboxUser._user
      }));


    return subscriberIds.reduce((result, subscriber) => ({
      whatsappSubscriberIds: result.whatsappSubscriberIds.concat(subscriber.whatsappSubscriberIds || []),
      facebookSubscriberIds: result.facebookSubscriberIds.concat(subscriber.facebookSubscriberIds || []),
      telegramSubscriberIds: result.telegramSubscriberIds.concat(subscriber.telegramSubscriberIds || []),
      websiteSubscriberIds: result.websiteSubscriberIds.concat(subscriber.websiteSubscriberIds || [])
    }), {
      whatsappSubscriberIds: [],
      facebookSubscriberIds: [],
      telegramSubscriberIds: [],
      websiteSubscriberIds: []
    });
  }
}

interface SubscriptionIdsPayload {
  whatsappSubscriberIds: Array<string>;
  facebookSubscriberIds: Array<string>;
  telegramSubscriberIds: Array<string>;
  websiteSubscriberIds: Array<string>;
}
