export const subscription = {
  subscriptions: 'Abonnements',
  plan: 'Planen',
  whatsappPlan: 'WhatsApp -Plan',
  addOns: 'Add-Ons',
  billingDetails: 'Rechnungsdetails',
  invoices: 'Rechnungen',
  wa1Reminder: 'WA 1. Erinnerung',
  wa2Reminder: 'WA 2. Erinnerung',
  waRenewApi: 'WA Renew API',
  waCheckUsageApi: 'WA Überprüfen Sie die Verwendung der Verwendung API',
  waResetUsageApi: 'WA zurücksetzen Nutzungs -API',
  waExceedUsageApi: 'WA übertreffen die Nutzungs -API',
  waGetBotsInThisAccount: 'Wa Holen Sie sich Bots in diesem Konto',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Wir haben die günstigsten Preise für alle Ihre Bedürfnisse.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'WhatsApp -Plan',
    TOOLTIP_ADDS_ON_HEADING: 'Kaufen Sie zusätzliche Massagenpakete und Whitelabel Bot',
    TOOLTIP_ADDS_ON_MESSAGE: 'Wenn Sie Ihr monatliches Nachrichtenlimit erreicht haben, können Sie zusätzliche Packungen kaufen.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Überprüfen Sie Ihre aktuellen Abonnementdetails',
    TOOLTIP_HORIZONTAL_BAR: 'Überprüfen Sie unsere monatlichen oder jährlichen Preise von 3K auf 500.000 Nachrichten, indem Sie die Balken von links nach rechts verschieben.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Aktualisieren Sie Ihre Rechnungsdetails für die Rechnungsstellung.',
    TOOLTIP_INVOICES_HEADING: 'Überprüfen Sie Ihre vorherigen Rechnungen.',
    TOOLTIP_INVOICES_ACTION: 'Klicken Sie hier, um die Rechnung in Ihrem E -Mail -Konto zu erhalten',
  },
  currentPlan: {
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    downgradeRequest1: 'Sie haben eine Anfrage gestellt, um Ihren Plan herabzustufen',
    downgradeRequest2: 'Mitteilungen. Bitte beachten Sie, dass Ihr Plan bis zum Ende des aktuellen Abrechnungszyklus herabgestuft wird',
    downgradeRequest3: 'Sie können das genießen',
    downgradeRequest4: 'Dienstleistungen bis dahin.',
    downgradeRequestMaster1: 'Sie haben eine Anfrage zur Heruntergrade Ihrer gestellt',
    downgradeRequestMaster2: ' planen',
    downgradeRequestMaster3: ' Gespräche. Bitte beachten Sie, dass Ihr Plan bis zum Ende des aktuellen Abrechnungszyklus herabgestuft wird',
    downgradeRequestMaster4: 'Sie können das genießen',
    downgradeRequestMaster5: 'Dienstleistungen bis dahin.',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    month: 'Monat',
    year: 'Jahr',
    messagePack: 'Message Pack',
    king: 'König',
    baby: 'Baby',
    whatsapp: 'WhatsApp',
    youAreOn: 'Du bist an',
    plan: 'planen',
    totalMessagesLimit: 'Gesamtnachrichten begrenzt',
    liveAgentLimit: 'Live -Agent -Grenze',
    perMonth: '/Monat',
    agents: 'Agent (en)',
    messagesAddOn: 'Nachrichten add-on',
    botWhitelabels: 'Bot Whitelabels',
    totalAddOnMessages: 'Gesamtadditionsmeldungen',
    basePrice: 'Grundpreis',
    gst: 'GST',
    totalPrice: 'Gesamtpreis',
    totalAddOnPrice: 'Gesamtpreis von Add-on',
    whitelabelPrice: 'Whitelabel -Preis',
    startedOn: 'Begann am',
    nextDueOn: 'Als nächstes fällig auf',
    addOnExpiry: 'Add-on-Ablauf',
    website: 'Website',
    facebook: 'Facebook',
    telegram: 'Telegram',
  },
  invoice: {
    invoiceNumber: 'Rechnung Nr.',
    date: 'Datum',
    invoiceAmount: 'Rechnungsbetrag',
    planAmount: 'Planenbetrag planen',
    plan: 'Planen',
    messages: 'Mitteilungen',
    action: 'Aktion',
    nA: 'N / A',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Klicken Sie hier, um die Rechnung in Ihrem E -Mail -Konto zu erhalten',
      clickToDownloadInvoice: 'Klicken Sie hier, um die Rechnung im PDF -Formular herunterzuladen',
    }
  },
  messageAddOn: {
    validity: 'Gültigkeit',
    kingPlanHeader: '👑 Kingsplan',
    youAreOnThe: 'Sie sind auf der',
    asOfNowAndWillGet: 'Ab sofort und werden bekommen',
    messages: 'Mitteilungen',
    inThisPlan: 'in diesem Plan.',
    youCanBuyAdditionalMessaes: 'Sie können zusätzliche Nachrichten gemäß Ihren Geschäftsanforderungen kaufen.',
    plan: ' Planen',
    baby: 'Baby',
    king: 'König',
    get: 'Erhalten',
    babyPlan: 'Babyplan',
    upgradePlan: 'Upgrade -Plan',
    asOfNow: ' ab jetzt.',
    pleaseUpgradeToKingPlan: 'Bitte upgraden Sie auf den ',
    toEnableAddOnsAndWhitelabelPacks: ' So aktivieren Sie Add-Ons & Whitelabel Packs.',
    messageAddOnPacks: 'Meldungen Add-On Packs',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Wenn Sie Ihr monatliches Nachrichtenlimit erreicht haben, kaufen Sie zusätzliche Packungen und genießen Sie ununterbrochene Dienste.',
    pleaseSelectNumberOfExpectedMessages: 'Bitte wählen Sie die Anzahl der erwarteten Nachrichten aus',
    addOnsValidity: 'Add-On-Gültigkeit',
    currentBillingCycle: 'Aktueller Abrechnungszyklus',
    oneYear: 'Ein Jahr',
    summary: 'Zusammenfassung',
    messageCreditsWithKingPlan: 'Nachrichten -Credits mit King Plan',
    messageCreditsWith: 'Nachrichten -Credits mit ',
    withPlan: '',
    billingCycle: 'Rechnungskreislauf',
    payableAmount: 'Zu zahlender Betrag',
    oneTimePayment: 'Einmalige Zahlung',
    plusTaxes: ' + Steuern',
    buyNow: 'Kaufe jetzt',
    whiteLabelChatWindow: 'Weißes Etikett -Chatfenster',
    noBotsLeftToWhiteLabel: 'Keine Bots zu Whitelabel.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Bitte wählen Sie die Bots aus, die Sie Whitelabel haben möchten.',
    selectBots: 'Wählen Sie Bots aus',
    bot: 'Bot',
    bots: 'Bots',
    toBeWhiteLabelled: ' weißeLabelliert sein',
    remove: 'Entfernen',
    brandNameFromChatWindow: ' Brandname aus dem Chat -Fenster.',
    willBeValidTill: 'Wird gültig sein bis',
    ourYearlyPrice: 'Unser jährlicher Preis',
    directWhiteLabel: 'Direkte Whitelabel',
    whiteLabelledBots: 'Whitelabelled Bots',
    nextRenewalColon: 'Nächste Erneuerung:',
    optOutRequested: 'Out Out angefordert',
    contactAgency: 'Kontaktagentur',
    optOut: 'Ablehnen',
    removeWhiteLabel: 'Whitelabel entfernen',
    messageCount: 'Nachrichten',
    numberOfPacks: 'Anzahl der Pakete',
    amount: 'Betrag',
    taxes: 'Steuern',
    totalAmount: 'Gesamtbetrag',
    numOfBots: 'Anzahl der Bots',
    select: 'Auswählen',
    selectTheValidity: 'selektieren Sie die Geschwindigkeit',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Zahlungsdetails',
    cardNumber: 'Kartennummer',
    cvv: 'CVV',
    // cardNumber: 'Kartennummer',
    month: 'Monat',
    year: 'Jahr',
    save: 'Speichern',
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    disclaimer: 'Haftungsausschluss:',
    disclaimerDescription1: 'Die oben genannten Zahlungsmethodendetails gelten nur für Benutzer außerhalb Indiens.',
    disclaimerDescription2: 'Wir speichern Ihre Kartendaten nicht bei uns. Falls Sie Ihre Kartendaten ändern möchten, wird sie direkt mit Payment Gateway geändert.',
    billingAddress: 'Rechnungsadresse',
    companyName: 'Name der Firma',
    country: 'Land',
    selectCountry: 'Land auswählen',
    stateOrRegion: 'Staat/Region',
    streetAddress: 'Adresse',
    gstIn: 'Gstin',
    postalCode: 'Postleitzahl',
    cancel: 'Stornieren',
    proceedToPay: 'Weiter zur Zahlung',
    company: 'Unternehmen',
    state: 'Zustand',
    masterCard: 'Master -Karte',
    yourCompanyName: 'Ihr Firmenname',
    yourCountry: 'Dein Land',
    yourRegion: 'Ihre Region',
    yourPostalCode: 'Ihre Postleitzahl',
    plusTaxes: ' + Steuern',
    dropDown: {
      month: 'Monat *',
      year: 'Jahr *',
    },
    placeholders: {
      enterCardNumber: 'Kartennummer eingeben',
      cvv: 'CVV',
      enterYourCompanyName: 'Geben Sie Ihren Firmennamen ein',
      enterYourStateOrRegion: 'Geben Sie Ihren Staat/Ihre Region ein',
      enterYourStreetAddress: 'Geben Sie Ihre Straßenadresse ein',
      enterYourGSTIN: 'Geben Sie Ihren GSTIN ein',
      enterPostalCode: 'Eingeben Sie die Postleitzahl ein',
    }
  },
  plans: {
    viewDetailedPricing: 'Detaillierte Preise anzeigen',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    weHaveGotAPricingPlanPerfectForYou: 'Wir haben einen Preisplan, der perfekt für Sie ist',
    weBelieve: 'Wir glauben',
    shouldBeAccessibleToAllCompanies: ' sollte für alle Unternehmen zugänglich sein, keine Rolle der Größe.',
    monthly: 'Monatlich',
    yearly: 'Jährlich',
    twoMonthsFree: '(2 Monate frei)',
    babyPlan: 'Babyplan',
    freeForever: 'Für immer frei',
    messagesPerMonth: 'Nachrichten pro Monat',
    currentPlan: 'Derzeitiger Plan',
    freePlan: 'Freier Plan',
    kingPlan: 'Königsplan',
    perYear: 'pro Jahr',
    plan: 'planen',
    toThe: ' zum',
    plusTaxes: ' + Steuern',
    perMonth: 'pro Monat',
    upgradePlan: 'Upgrade -Plan',
    changePlan: 'Plan ändern',
    currentlyActive: 'Derzeit aktiv',
    emperorPlan: 'Kaiserplan (Unternehmen)',
    sendAsManyMessagesAsYouWant: 'Senden Sie so viele Nachrichten, wie Sie benötigen',
    contactUs: 'Kontaktiere uns',
    features: 'Merkmale',
    areYouSureYouWantToShiftFrom: 'Sind Sie sicher, dass Sie sich von der verschieben möchten',
    messages: 'Mitteilungen',
    planQuestionMark: 'planen?',
    downgradeToFreePlan: 'Herabstufung zum freien Plan',
    goToBabyPlan: 'Gehen Sie zum Babyplan',
    thankYouForChoosingBotPenguin: 'Vielen Dank, dass Sie Botpenguin gewählt haben',
    bots: 'Bots',
    facebookPages: 'Facebook -Seiten',
    teamMember: 'Teammitglied',
    dataStorage: 'Datenspeicher',
    conditionalFlow: 'Bedingter Fluss',
    liveChat: 'Live-Chat',
    bookAppointments: 'Buchtermine',
    csvExport: 'CSV -Export',
    emailAndCallSupport: 'E -Mail- und Anrufunterstützung',
    googleCalendarIntegrations: 'Integration von Google -Kalender',
    zohoCrmIntegrations: 'Zoho CRM -Integration',
    reportsAndAnalytics: 'Berichte und Analysen',
    removeBotPenguinBranding: 'Entfernen Sie das Branding von Botpenguin',
    oneBot: '1 Bot',
    twoKMessagesPerMonth: '2k Nachrichten/Monat',
    oneFacebookPage: '1 Facebook -Seite',
    oneTeamMember: '1 Teammitglied',
    thirtyDaysOfDataStorage: '30 Tage Datenspeicherung',
    unlimitedBot: 'Unbegrenzter Bot',
    threeTo500kMessagesPerMonth: '3K bis 500K Nachrichten/Monat',
    tenFacebookPages: '10 Facebook -Seiten',
    threeTeamMembers: '3 Teammitglieder',
    nintyDaysOfDataStorage: '90 Tage Datenspeicherung',
    unlimitedMessages: 'Unbegrenzte Nachrichten',
    unlimitedFacebookPages: 'Unbegrenzte Facebook -Seiten',
    unlimitedTeamMembers: 'Unbegrenzte Teammitglieder',
    unlimitedDataStorage: 'Unbegrenzte Datenspeicherung',
    youAreHere: 'Sie sind hier!',
    detailedPricing: {
      kingsplan: 'Königsplan',
      elementary: 'Elementar',
      solutionType: 'Lösungstyp',
      saas: 'SaaS',
      fbpage1fb: '1 FB-Seite',
      perMonth100: 'Pro Monat 100',
      enterprise: 'Unternehmen',
      saasOnPremises: 'SaaS Vor Ort',
      aiChatbotFeatures: 'KI-Chatbot-Funktionen',
      dataSourceForTraning: 'Datenquelle für das Training',
      smartFeatures: 'Intelligente Funktionen',
      liveChat: 'Live-Chat',
      day30: '30 Tage',
      day90: '90 Tage',
      landSquared: 'Landfläche',
      doItYourself: 'Selber machen',
      unifiedInbox: 'Einheitlicher Posteingang',
      platforms: 'Plattformen',
      websiteChatbot: 'Website Chatbot',
      FacebookMessengerChatbot: 'Facebook Messenger Chatbot',
      Telegram: 'Telegramm',
      conversationalLandingPage: 'Konversations-Landingpage',
      integrations: 'Integrationen',
      reportsAndAnalytics: 'Berichte und Analysen',
      customerSupport: 'Kundensupport',
      security: 'Sicherheit',
      bots: 'Bots',
      babyPlan: 'Babyplan',
      kingPlan: 'Königsplan',
      emperorPlan: 'Kaiserplan',
      noofBots: 'Anzahl der Bots',
      one: '1 (Eins)',
      unlimitedBots: 'Unbegrenzte Bots',
      noOfWebsite: 'Anzahl der Websites',
      unlimitedWebsites: 'Unbegrenzte Websites',
      unlimitedMessages: 'Unbegrenzte Nachrichten',
      messagesSend: 'Gesendete Nachrichten',
      perMonth2000: 'Pro Monat 2,000',
      upto500000perMonth: 'Bis zu 500.000 pro Monat',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Unbegrenzte Seiten',
      telegramNumbers: 'Telegramm-Nummern',
      unlimited: 'Unbegrenzt',
      whatsappNumbers: 'WhatsApp-Nummern',
      subscribersLeads: 'Abonnenten/Leads',
      createSupportTeam: 'Support-Team für Live-Chat erstellen',
      oneMember: '1 Mitglied',
      threeMembers: '3 Mitglieder',
      conversationHistory: 'Konversationsverlauf',
      thirtyDays: '30 Tage',
      ninetyDays: '90 Tage',
      chatbotDesigning: 'Chatbot-Design',
      weAreHereToHelp: 'Wir sind hier, um zu helfen',
      weTakeCareEndToEnd: 'Wir kümmern uns von Anfang bis Ende',
      multipleLanguages: 'Mehrere Sprachen',
      conditionalFlow: 'Bedingter Ablauf',
      removeBotPenguinBranding: 'BotPenguin-Branding entfernen',
      gptMessages: 'GPT-Nachrichten',
      aiChatbot: 'KI-Chatbot',
      chatGPTIntegration: 'ChatGPT-Integration',
      configureAIChatbotPersonality: 'KI-Chatbot-Persönlichkeit konfigurieren',
      trainChatbotOnYourOwnData: 'Trainiere den Chatbot mit deinen eigenen Daten',
      trainChatbotOnWebPages: 'Trainiere den Chatbot auf Webseiten',
      trainChatbotOnMultipleFiles: 'Trainiere den Chatbot mit mehreren Dateien',
      trainChatbotOnFAQs: 'Trainiere den Chatbot auf FAQs',
      dataTrainingStorage: 'Datentrainings-Speicher',
      useChatGPTModel4_3_5Turbo: 'Verwende das ChatGPT-Modell 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Lade mehrere Dateien für das Chatbot-Training hoch',
      naturalLanguageProcessing: 'Natürliche Sprachverarbeitung',
      naturalLanguageUnderstanding: 'Natürliches Sprachverständnis',
      answerLength: 'Antwortlänge',
      chatTone: 'Chat-Ton',
      multiLanguage: 'Mehrere Sprachen',
      answerFormatting: 'Antwortformatierung',
      customPrompt: 'Benutzerdefinierte Aufforderung',
      integrateBotPenguinLLM: 'Integration von BotPenguin LLM',
      integrateOwnCustomLLM: 'Integration von eigenem/benutzerdefiniertem LLM',
      periodicallySyncDataSources: 'Datenquellen periodisch synchronisieren',
      aiBasedSuggestedQuestions: 'KI-basierte vorgeschlagene Fragen',
      chooseVisibilityForParticularWebpageSitemap: 'Sichtbarkeit für bestimmte Webseiten/Sitemaps wählen',
      chooseKnowledgeMix: 'Wissensmix wählen:\nDeine Wissensdatenbank\nChatGPT-Wissen\n"ChatGPT-Wissen + Deine Wissensdatenbank"',
      faqExtractionFromFileAndURL: 'FAQ-Extraktion aus Datei und URL',
      analyticsToImproveAI: 'Analysen zur Verbesserung der KI',
      exportQuestionFlow: 'Fragenfluss exportieren',
      sessionManagement: 'Sitzungsverwaltung',
      collectFeedbackOnChatbotResponses: 'Feedback zu Chatbot-Antworten sammeln',
      smartAIFollowUpQuestions: 'Intelligente/AI-Folgefragen',
      trainOnYourNotionWorkspaceData: 'Training mit deinen Notion-Arbeitsplatzdaten',
      trainOnYoutubeVideo: 'Training mit YouTube-Videos',
      trainOnCustomVideoUpload: 'Training mit benutzerdefinierter Video-Upload',
      rssFeed: 'RSS-Feed',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'IP- und Regions-Blocking',
      emailAndMobileValidation: 'E-Mail- und Mobilvalidierung',
      appointmentsBooking: 'Terminbuchung',
      humanHandover: 'Menschliche Übergabe',
      botHandover: 'Bot-Übergabe',
      handoverBetweenAgents: 'Übergabe zwischen Agenten',
      mobileAppsForAgents: 'Mobile Apps für Agenten',
      roleBasedAccessControls: 'Rollenbasierte Zugriffskontrollen',
      apiIntegrationOnChatFlows: 'API-Integration in Chat-Flows',
      liveChatWidget: 'Live-Chat-Widget',
      typingIndicator: 'Schreibindikator',
      liveVisitorsDetails: 'Details zu Live-Besuchern',
      multipleSimultaneousChat: 'Mehrere gleichzeitige Chats',
      liveTypingView: 'Live-Schreibansicht',
      ruleBasedChatRouting: 'Regelbasierte Chat-Routing',
      chatAutoAssignments: 'Automatische Zuweisungen im Chat',
      efficientOmnichannelInterface: 'Effiziente Omnichannel-Schnittstelle',
      quickReplies: 'Schnelle Antworten',
      unifiedCustomerProfile: 'Vereinheitlichtes Kundenprofil',
      visitorStats: 'Besucherstatistiken',
      addContactsFromWhatsapp: 'Kontakte von WhatsApp hinzufügen',
      applyFilterAndSortConversations: 'Filter anwenden und Konversationen sortieren',
      seeChatLogs: 'Chatprotokolle anzeigen',
      assignNotesAndChatTags: 'Notizen und Chat-Tags zuweisen',
      directCallsToLeadsWithTwilio: 'Direkte Anrufe an Leads mit Twilio',
      customerSegmentation: 'Kundensegmentierung',
      exportLeadsInCSV: 'Leads in CSV exportieren',
      leadNotificationsByEmail: 'Lead-Benachrichtigungen per E-Mail',
      website: 'Website',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegramm',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Automatische Auslösung auf Mobilgeräten/ Desktops',
      hideBotOnSpecificPages: 'Bot auf bestimmten Seiten ausblenden',
      imageSharing: 'Bildfreigabe',
      readyToUseChatFlowTemplates: 'Fertige Chatflussvorlagen',
      fileSharing: 'Dateifreigabe',
      customDesignForChatWindow: 'Benutzerdefiniertes Design für den Chatfenster',
      facebookMessengerBot: 'Facebook Messenger Bot',
      facebookSubheading: 'Facebook-Unterüberschrift',
      telegramSubheading: 'Telegramm-Unterüberschrift',
      conversationalLangdingPageSubheading: 'Unterüberschrift für konversationsfähige Landingpages',
      integrationsSubheading: 'Unterüberschrift für Integrationen',
      multipleSmartFlows: 'Mehrere intelligente Flows',
      dripCampaigns: 'Tropfkampagnen',
      broadcastFBMessages: 'Broadcast FB-Nachrichten',
      multipleFacebookBotWidgets: 'Mehrere Facebook-Bot-Widgets',
      facebookAutoReplyOnComments: 'Automatische Antwort auf Facebook-Kommentare',
      facebookECommerce: 'Facebook-E-Commerce',
      broadcastAnalytics: 'Broadcast-Analytik',
      telegramMessengerBot: 'Telegramm Messenger Bot',
      readyToUseTemplates: 'Fertige Vorlagen',
      groupBroadcastTelegramMessages: 'Gruppen- und Broadcast-Telegramm-Nachrichten',
      multipleTelegramBotWidgets: 'Mehrere Telegram Bot-Widgets',
      conversationalLandingPageBot: 'Konversationsfähige Landingpage-Bot',
      smartReplies: 'Intelligente Antworten',
      interactiveAndCustomizableUI: 'Interaktive und anpassbare Benutzeroberfläche',
      leadManagement: 'Lead-Management',
      easyToInstallAndShare: 'Einfache Installation und Freigabe',
      agileCRM: 'Agile CRM',
      capsule: 'Kapsel',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Schließen',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Tabellen',
      googleCalendar: 'Google Kalender',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Support-Ticket-System',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'In-Chat-Audio- und Videoanruf (Twilio/Agora)',
      courierTracking: 'Kurierverfolgung',
      emailIntegration: 'E-Mail-Integration',
      webhooks: 'Webhooks',
      schedulers: 'Planer',
      paymentGateways: 'Zahlungsgateways (Stripe & RazorPay)',
      enterpriseIntegrations: 'Unternehmensintegrationen',
      customIntegration: 'Benutzerdefinierte Integration',
      shopifyIntegration: 'Shopify-Integration',
      totalNumberOfMessages: 'Gesamtanzahl der Nachrichten',
      totalNumberOfLeads: 'Gesamtanzahl der Leads',
      weeklyReportBotwise: 'Wöchentlicher Bericht, botweise',
      averageAgentResponeTime: 'Durchschnittliche Reaktionszeit des Agenten',
      visitorTimingReport: 'Besucher-Timing-Bericht',
      customerSegmentBasedReports: 'Kundensegmentspezifische Berichte',
      tagBasedReports: 'Tagbasierte Berichte',
      leadGenerationReport: 'Lead-Generierungsbericht',
      supportOnWhatsApp: 'Support auf WhatsApp',
      knowledgeBase: 'Wissensdatenbank',
      onboardingAndTraining: 'Onboarding und Schulung',
      callAndEmailSupport: 'Anruf- und E-Mail-Support',
      freeChatbotCreation: 'Kostenlose Chatbot-Erstellung',
      customerSuccessOfficer: 'Kundenerfolgsbeauftragter',
      secureConnection2048BitSSL: 'Sichere Verbindung (2048 Bit SSL)',
      securityAssessment: 'Sicherheitsbewertung',
      dataCenterInUSOrEU: 'Rechenzentrum in den USA oder der EU',
      twoStepVerification: 'Zweistufige Verifizierung',
      accessRestrictionsBasedOnIPs: 'Zugriffsbeschränkungen basierend auf IPs',
      onDemandAuditLog: 'Audit-Log auf Abruf',
      bannedVisitorsList: 'Liste der gesperrten Besucher',
      customAPIRate: 'Benutzerdefinierte API-Rate',
      trustedDomains: 'Vertrauenswürdige Domains',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Codierungsinteressiert',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Unser freier Plan',
      TOOLTIP_KING_PLAN: 'Unser beliebtester Plan',
      TOOLTIP_EMPEROR_PLAN: 'Customized Plan für unbegrenzte Nachrichten, unbegrenzte Daten, unbegrenzte Teammitglieder',
      TOOLTIP_CONTACT_US: 'Greifen Sie uns über WhatsApp oder senden Sie eine E -Mail an contact@botpenguin.com, um mehr über unseren individuellen Plan zu erfahren',
      TOOLTIP_UPGRADE: 'Aktualisieren Sie Ihren aktuellen Plan für zusätzliche Funktionen',
      TOOLTIP_MONTHLY: 'Wählen Sie einen monatlichen Zahlungszyklus aus',
      TOOLTIP_YEARLY: 'Wählen Sie einen jährlichen Zahlungszyklus für Rabattleistungen aus',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Gehen Sie zum Babyplan',
    downgradeToFreePlan: 'Herabstufung zum freien Plan',
    freeForever: 'Für immer frei',
    features: 'Merkmale',
    currentPlan: 'Derzeitiger Plan',
    changePlan: 'Plan ändern',
    currentlyActive: 'Derzeit aktiv',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    weHaveGotPricingPerfectForYou: 'Wir haben einen Preisplan, der perfekt für Sie ist',
    weBelieve: 'Wir glauben',
    shouldBeAccessibleToAllCompanies: ' sollte für alle Unternehmen zugänglich sein, keine Rolle der Größe.',
    monthly: 'Monatlich',
    yearly: 'Jährlich',
    twoMonthsFree: '(2 Monate frei)',
    kingPlan: 'Königsplan',
    perYear: 'pro Jahr',
    perMonth: 'pro Monat',
    conversationsPerMonth: 'Gespräche pro Monat',
    upgradePlan: 'Upgrade -Plan',
    emperorPlan: 'Kaiserplan (Unternehmen)',
    haveAsManyConversationsAsYouNeed: 'Führen Sie so viele Gespräche wie Sie brauchen',
    contactUs: 'Kontaktiere uns',
    metaConversationChargesAreAdditional: 'Meta -Gesprächsgebühren werden zusätzlich und direkt von Ihnen an Meta bezahlt, basierend auf Ihrer Verwendung. Wir berechnen keine Markup- oder Erleichterungsgebühren.',
    viewDetailedPricing: 'Detaillierte Preise anzeigen',
    conversationPricingModelUsedByMeta: 'PREISIONS -MODEL VON META VERWENDET',
    whatsappBusinessPlatformConversationsFallInto: 'WhatsApp Business -Plattform -Gespräche fallen in zwei Kategorien, die unterschiedlich bewertet werden',
    userInitiatedConversation: 'Benutzerverbindliche Konversation',
    userInitiatedConversationDescription: 'Ein Gespräch, das als Antwort auf eine Benutzernachricht initiiert. Immer wenn ein Unternehmen im 24-Stunden-Kundendienstfenster auf einen Benutzer antwortet, wird diese Meldung mit einer vom Benutzer initiierten Konversation zugeordnet. Unternehmen können innerhalb dieses 24-Stunden-Kundendienstfensters Freiform-Nachrichten senden.',
    businessInitiatedConversation: 'Geschäfte initiiertes Gespräch',
    businessInitiatedConversationDescription: 'Ein Gespräch, das von einem Unternehmen initiiert, das einem Benutzer eine Nachricht außerhalb des 24 -Stunden -Kundendienstfensters sendet. Nachrichten, die eine von Business initiierte Konversation initiieren, erfordern eine Nachrichtenvorlage.',
    areYouSureYouWantTo: 'Sind Sie sicher, dass Sie sich von der verschieben möchten',
    conversations: ' Gespräche',
    plan: ' planen',
    toThe: ' zum',
    metaPricingMarkup: 'META -Preisgestaltung',
    numberOfChatFlows: 'Anzahl der Chat -Flüsse',
    greenTickApplication: 'Grüne Zeckenanwendung',
    extraPhoneNumberLinking: 'Zusätzliche Telefonnummer Verknüpfung',
    teamMembers: 'Teammitglieder',
    dataStorage: 'Datenspeicher',
    conditionalFlow: 'Bedingter Fluss',
    liveChat: 'Live-Chat',
    smartInbox: 'Smart Posteingang',
    whatsappTemplates: 'WhatsApp -Vorlagen',
    customerSegments: 'Kundengruppen',
    broadcastAndDripCampaigns: 'Sendungs- und Tropfkampagnen',
    multipleLanguages: 'Mehrere Sprachen',
    bookAppointments: 'Buchtermine',
    csvContactsExport: 'CSV -Kontakte Export',
    googleCalendarIntegrations: 'Integration von Google -Kalender',
    nativeApplications: '60+ native Integration',
    whatsappSupport: 'WhatsApp -Unterstützung',
    emailAndCallSupport: 'E -Mail- und Anrufunterstützung',
    customerIntegrations: 'Kundenintegrationen',
    nlpAndNluCapabilities: 'NLP- und NLU -Funktionen',
    chatGPTBot: 'Chatgpt Chatbot',
    customerSuccessOfficer: 'Kundenerfolgsbeauftragter',
    noMarkupTransparency: 'Keine Markup -Transparenz',
    upto500kPerMonth: 'Bis zu 500.000 pro Monat',
    beyond500k: 'Über 500.000 hinaus',
    unlimited: 'Unbegrenzt',
    oneTeamMember: '1 Teammitglied',
    unlimitedTeamMember: 'Unbegrenzte Teammitglieder',
    nintyDaysOfDataStorage: '90 Tage Datenspeicherung',
    unlimitedDataStorage: 'Unbegrenzte Datenspeicherung',
    detailedPricing: {
      onDemandAuditLog: 'Audit-Log auf Abruf',
      accessRestrictionsBasedOnIPs: 'Zugriffsbeschränkungen basierend auf IPs',
      averageAgentResponeTime: 'Durchschnittliche Reaktionszeit des Agents',
      perMonth100: 'Pro Monat 100',
      solutionType: 'Lösungstyp',
      saas: 'SaaS',
      saasOnPremises: 'SaaS vor Ort',
      emperorPlan: 'Kaiserplan',
      babyPlan: 'Babyplan',
      kingsplan: 'Königsplan',
      braodcastsAndDrips: 'Übertragungen & Tropfen',
      thirtyDays: '30 Tage',
      ninetyDays: '90 Tage',
      oneMember: '1 Mitglied',
      threeMembers: '3 Mitglieder',
      one: '1 (Eins)',
      unlimitedMessages: 'Unbegrenzte Nachrichten',
      unlimitedBots: 'Unbegrenzte Bots',
      messagesSend: 'Gesendete Nachrichten',
      upto500000perMonth: 'Bis zu 500.000 pro Monat',
      unlimited: 'Unbegrenzt',
      onlyAdmin: 'Nur Administrator',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Selber machen',
      weAreHereToHelp: 'Wir sind hier, um zu helfen',
      weTakeCareEndToEnd: 'Wir kümmern uns von Anfang bis Ende',
      numberOfBots: 'Anzahl der Bots',
      conversations: 'Gespräche',
      gptMessages: 'GPT-Nachrichten',
      whatsappNumbers: 'WhatsApp-Nummern',
      subscribersLeads: 'Abonnenten/Leads',
      createSupportTeamForLiveChat: 'Support-Team für Live-Chat erstellen',
      conversationHistory: 'Gesprächsverlauf',
      chatbotDesigning: 'Chatbot-Design',
      multipleLanguages: 'Mehrere Sprachen',
      conditionalFlow: 'Bedingter Ablauf',
      whatsappTemplates: 'WhatsApp-Vorlagen',
      restartChatFlowTriggers: 'Neustart der Chat-Flussauslöser',
      multipleWhatsAppWidgets: 'Mehrere WhatsApp-Widgets',
      importWhatsappContacts: 'Importieren von WhatsApp-Kontakten',
      whatsappQuickReply: 'WhatsApp-Schnellantwort',
      whatsappSmartInbox: 'WhatsApp Smart Posteingang',
      whatsappEcommerceCatalogs: 'WhatsApp E-Commerce mit Katalogen',
      whatsappBroadcast: 'WhatsApp-Broadcast',
      whatsappBroadcastAnalytics: 'WhatsApp-Broadcast-Analyse',
      dripCampaigns: 'Tropfkampagnen',
      customerSegmentation: 'Kundensegmentierung',
      keywordBasedResponse: 'Antworten basierend auf Schlüsselwörtern',
      whatsappShopManagement: 'WhatsApp-Shop-Verwaltung',
      aiChatbot: 'KI-Chatbot',
      chatGPTIntegration: 'ChatGPT-Integration',
      configureAIChatbotPersonality: 'Konfigurieren der Persönlichkeit des KI-Chatbots',
      trainChatbotOnYourOwnData: 'Trainieren des Chatbots mit Ihren eigenen Daten',
      trainChatbotOnWebPages: 'Trainieren des Chatbots auf Webseiten',
      trainChatbotOnMultipleFiles: 'Trainieren des Chatbots mit mehreren Dateien',
      trainChatbotOnFAQs: 'Trainieren des Chatbots auf häufig gestellte Fragen',
      dataTrainingStorage: 'Speicherung der Trainingsdaten',
      useChatGPTModel4or35Turbo: 'Verwendung des ChatGPT-Modells 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Hochladen mehrerer Dateien für das Chatbot-Training',
      naturalLanguageProcessing: 'Natürliche Sprachverarbeitung',
      naturalLanguageUnderstanding: 'Verständnis natürlicher Sprache',
      answerLength: 'Antwortlänge',
      chatTone: 'Chat-Ton',
      multiLanguage: 'Mehrsprachig',
      answerFormatting: 'Antwortformatierung',
      customPrompt: 'Benutzerdefinierte Aufforderung',
      whatsAppSessionManagement: 'WhatsApp-Sitzungsverwaltung (Inaktivitäts- und Flussneustartnachrichten)',
      integrateBotPenguinLLM: 'Integration von BotPenguin LLM',
      integrateOwnCustomLLM: 'Integration von Eigenem/Individuellem LLM',
      periodicallySyncDataSources: 'Periodische Synchronisierung von Datenquellen',
      aiBasedSuggestedQuestions: 'KI-basierte vorgeschlagene Fragen',
      chooseVisibilityForParticularWebpageSitemap: 'Sichtbarkeit für bestimmte Webseite/Sitemap auswählen',
      chooseKnowledgeMix: 'Wissensmix auswählen: Ihr Wissensbasis ChatGPT-Wissen "ChatGPT-Wissen + Ihre Wissensbasis"',
      faqExtractionFromFileAndURL: 'FAQ-Extraktion aus Datei und URL',
      analyticsToImproveAI: 'Analytik zur Verbesserung der KI',
      exportQuestionFlow: 'Fragefluss exportieren',
      collectFeedbackOnChatbotResponses: 'Rückmeldungen zu Chatbot-Antworten sammeln',
      smartAIFollowUpQuestions: 'Intelligente KI-Folgefragen',
      trainOnYourNotionWorkspaceData: 'Training anhand Ihrer Notion-Arbeitsplatzdaten',
      trainOnYoutubeVideo: 'Training anhand von YouTube-Videos',
      trainOnCustomVideoUpload: 'Training anhand individueller Video-Uploads',
      rssFeed: 'RSS-Feed',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'Menschliche Übergabe',
      botHandover: 'Bot-Übergabe',
      handoverBetweenAgents: 'Übergabe zwischen Agenten',
      emailAndMobileValidation: 'E-Mail- und Mobilvalidierung',
      mobileAppsForAgents: 'Mobile Apps für Agenten',
      appointmentBooking: 'Terminvereinbarung',
      roleBasedAccessControls: 'Rollenbasierte Zugriffskontrollen',
      ruleBasedChatRouting: 'Regelbasierte Chat-Routing',
      liveVisitorsDetails: 'Details zu Live-Besuchern',
      multipleSimultaneousChats: 'Mehrere gleichzeitige Chats',
      visitorStats: 'Besucherstatistiken',
      addContactsFromWhatsApp: 'Kontakte von WhatsApp hinzufügen',
      applyFilterAndSortConversations: 'Filter anwenden und Gespräche sortieren',
      seeChatLogs: 'Chatprotokolle anzeigen',
      quickReplies: 'Schnellantworten',
      unifiedCustomerProfile: 'Einheitliches Kundenprofil',
      assignNotesAndChatTags: 'Notizen und Chat-Tags zuweisen',
      exportLeadsInCSV: 'Leads als CSV exportieren',
      tagContacts: 'Kontakte kennzeichnen',
      customAttributes: 'Benutzerdefinierte Attribute',
      markStatusOfConversations: 'Status von Gesprächen markieren',
      assignConversationToAgents: 'Gespräch Agenten zuweisen',
      leadNotificationsByEmail: 'Lead-Benachrichtigungen per E-Mail',
      directCallsToLeadsWithTwilio: 'Direkte Anrufe bei Leads mit Twilio',
      whatsAppTemplates: 'WhatsApp-Vorlagen',
      promotionalMessageFrequencyControl: 'Steuerung der Häufigkeit von Werbenachrichten',
      whatsAppWidget: 'WhatsApp-Widget',
      whatsAppQR: 'WhatsApp-QR-Code',
      whatsAppLink: 'WhatsApp-Link',
      campaignScheduler: 'Kampagnenplaner',
      customTriggers: 'Benutzerdefinierte Auslöser',
      customCampaigns: 'Benutzerdefinierte Kampagnen',
      repeatPurchaseReminders: 'Wiederholte Kauf-Erinnerungen',
      campaignOpenRateTracking: 'Verfolgung der Kampagnen-Öffnungsraten',
      paymentCollectionOnWhatsApp: 'Zahlungseinzug über WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Kapsel',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Schließen',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'FrontApp',
      webhoper: 'Webhoper',
      engagebay: 'EngageBay',
      helpshift: 'Helpshift',
      copper: 'Kupfer',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Tabellen',
      googleCalendar: 'Google Kalender',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Support-Ticket-System',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Audio- und Videoanrufe im Chat (Twilio/Agora)',
      courierTracking: 'Kurierverfolgung',
      emailIntegration: 'E-Mail-Integration',
      webhooks: 'Webhooks',
      schedulers: 'Zeitplaner',
      paymentGateways: 'Zahlungs-Gateways (Stripe & RazorPay)',
      enterpriseIntegrations: 'Unternehmensintegrationen',
      customIntegration: 'Benutzerdefinierte Integration',
      freeWhatsAppAPIApprovalAndSetup: 'Kostenlose WhatsApp-API-Genehmigung und Einrichtung',
      developerAPIsAccess: 'Zugriff auf Entwickler-APIs',
      customRateLimit: 'Benutzerdefinierte Ratenbegrenzung',
      totalNumberOfLeads: 'Gesamtanzahl der Leads',
      averageAgentResponseTime: 'Durchschnittliche Reaktionszeit des Agents',
      visitorTimingReport: 'Bericht zur Besucherzeit',
      customerSegmentBasedReports: 'Kundensegmentspezifische Berichte',
      tagBasedReports: 'Tagbasierte Berichte',
      leadGenerationReport: 'Lead-Generierungsbericht',
      supportOnWhatsApp: 'Support auf WhatsApp',
      knowledgeBase: 'Wissensdatenbank',
      onboardingAndTraining: 'Einführung und Schulung',
      callAndEmailSupport: 'Anruf- und E-Mail-Support',
      freeChatbotCreation: 'Kostenlose Chatbot-Erstellung',
      customerSuccessOfficer: 'Kundenbetreuungsbeauftragter',
      secureConnection: 'Sichere Verbindung (2048 Bit SSL)',
      securityAssessment: 'Sicherheitsbewertung',
      dataCenterLocation: 'Standort des Rechenzentrums (USA oder EU)',
      twoStepVerification: 'Zwei-Faktor-Verifikation',
      accessRestrictions: 'Zugriffsbeschränkungen',
      auditLog: 'Audit-Protokoll auf Abruf',
      bannedVisitorsList: 'Liste gesperrter Besucher',
      customAPIRate: 'Benutzerdefinierte API-Rate',
      trustedDomains: 'Vertrauenswürdige Domänen',
      elementary: 'Elementar',
      bots: 'Bots',
      unlockWhatsapp: 'WhatsApp entsperren',
      aiChatbotFeatures: 'KI-Chatbot-Funktionen',
      dataSourceForTraining: 'Datenquelle für das Training',
      smartFeatures: 'Intelligente Funktionen',
      liveChat: 'Live-Chat',
      smartInbox: 'Intelligenter Posteingang',
      broadcastsAndDrips: 'Übertragungen & Tropfen',
      integrations: 'Integrationen',
      development: 'Entwicklung',
      reportsAndAnalytics: 'Berichte und Analytik',
      customerSupport: 'Kundensupport',
      security: 'Sicherheit',
      whatsappWidgets: 'WhatsApp-Widgets',
      campaignSchedular: 'Kampagnenplaner',
      repeatPurchaseRemainder: 'Wiederholte Kauf-Erinnerungen',
    },

  appTooltip: {
      TOOLTIP_BABY_PLAN: 'Unser freier Plan',
      TOOLTIP_KING_PLAN: 'Unser beliebtester Plan',
      TOOLTIP_EMPEROR_PLAN: 'Customized Plan für unbegrenzte Nachrichten, unbegrenzte Daten, unbegrenzte Teammitglieder',
      TOOLTIP_CONTACT_US: 'Greifen Sie uns über WhatsApp oder senden Sie eine E -Mail an contact@botpenguin.com, um mehr über unseren individuellen Plan zu erfahren',
      TOOLTIP_UPGRADE: 'Aktualisieren Sie Ihren aktuellen Plan für zusätzliche Funktionen',
      TOOLTIP_MONTHLY: 'Wählen Sie einen monatlichen Zahlungszyklus aus',
      TOOLTIP_YEARLY: 'Wählen Sie einen jährlichen Zahlungszyklus für Rabattleistungen aus',
    }
  }
};
