const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Merhaba, ',
  greetingHand: '👋',
  upgrade: 'Yükselt',
  integrate: 'Entegre Et',
  integrate2: 'Entegre Et',
  chatGPT: 'ChatGPT ',
  forYourBusiness: 'işiniz için',
  insights: 'Görüşler',
  message: 'Mesajlar',
  addon: 'Eklenti: ',
  leads: 'Potansiyel Müşteriler',
  yourBots: 'Botlarınız',
  botName: 'Bot Adı',
  platform: 'Platform',
  contactOrLeads: 'İletişim / Potansiyel Müşteriler',
  actions: 'Eylemler',
  edit: 'düzenle',
  delete: 'sil',
  createA: 'Yeni bir ',
  whatsappChatbot: 'WhatsApp Sohbet Botu ',
  connect: 'Bağlan',
  getStartedWithUs: 'Bizimle başlayın 👏',
  howToBuildAGoodChatFlow: ' İyi bir sohbet akışı nasıl oluşturulur',
  howToUseBotPenguinInboxAndItsFeatures: ` BotPenguin'ın Inbox'ını ve özelliklerini nasıl kullanırım`,
  botPenguinPricing: ` BotPenguin'ın Fiyatlandırması: Planları ve Özellikleri Keşfetme`,
  openHelpDesk: ' Yardım masasını aç',
  helpAndSupport: 'Yardım ve destek',
  extensiveGuides: 'Tüm sorularınız ve iş akışları için kapsamlı kılavuzlar,',
  resourceCentre: 'Kaynak Merkezi’nde bulunmaktadır.',
  checkoutOurAnswers: 'Ortak sorulara verdiğimiz yanıtları aşağıda kontrol edebilirsiniz.',
  createANewBot: 'Yeni bir bot oluştur',
  installYourBot: 'Botunuzu kurun',
  plansAndPricing: 'Planlar ve Fiyatlandırma',
  searchLanguage: 'Dil Ara',
  whatsappConversations: 'WhatsApp Konuşmaları',
  diveDeeperIntoBotPenguin: 'BotPenguin\'a daha derinlemesine inin',
  knowWhereWeAreGoing: 'Nereye gittiğimizi, son güncellemeleri ve bazı hataları düzeltmemize yardımcı olun.',
  productUpdates: 'Ürün Güncellemeleri',
  requestAFeature: 'Bir özellik iste',
  reportABug: 'Hata bildir',
  productRoadmap: 'Ürün Yol Haritası',
  contactsOrLeads: 'İletişim / Potansiyel Müşteriler',
  english: 'İngilizce',
  hindi: 'Hintçe',
  german: 'Almanca',
  french: 'Fransızca',
  portuguese: 'Portekizce',
  spanish: 'İspanyolca',
  italian: 'İtalyanca',
  russian: 'Rusça',
  pleaseSelectYourReason: 'Lütfen nedeninizi seçin',
  cancel: 'İptal',
  submit: 'Gönder!',
  giveFeedback: 'Geri bildirim ver',
  ratings: 'Değerlendirmeler',
  reasonBehindYourDecision: 'Kararınızın arkasındaki neden',
  shareYourExperience: 'Deneyiminizi paylaşın',
  reason1: 'neden1',
  reason2: 'neden2',
  appTooltip: {
    HOME_INSIGHT: 'Tüm botların mesajlarını ve potansiyel müşterilerini sunar (Veriler her gece güncellenir)',
    HOME_MESSAGES: 'Bu fatura döneminde hesabınız tarafından tüketilen mesajlar. (WhatsApp, Facebook ve Telegram Bot mesajları hariç)',
    HOME_TOTAL_MESSAGES: 'Hesabınız tarafından hesap oluşturulduğundan beri tüketilen toplam mesajlar (WhatsApp, Facebook ve Telegram Bot mesajları hariç)',
    LEADS: 'Bu fatura döneminde botlarınız tarafından yakalanan potansiyel müşteriler.',
    TOTAL_LEADS: 'Hesabınız için hesap oluşturulduğundan beri botlar tarafından yakalanan toplam iletişim / potansiyel müşteriler (Veriler her gece güncellenir).',
    YOUR_BOT: 'Tüm botlarınızın ve bu fatura dönemindeki kullanımlarının genel bakışı.',
    NAME: 'Bot adı',
    PLATFORM: 'Bot platformu',
    MESSAGES: 'Hesap oluşturulduğundan beri botun toplam mesajları',
    CONTACTS: 'Botun toplam iletişim / potansiyel müşterileri',
    ACTIONS: 'Botu düzenle/sil',
    EDIT_ICON: 'Bot sohbet akışını ve ayarlarını düzenle',
    DELETE_ICON: 'Botu sil',
    TOOL_TIP_CLASSIC_VERSION: `${brand} UI'nin eski sürümü`
  },
  modal: {
    deleteBot: 'Botu Sil!',
    areYouSure: 'Bu Bot\'u silmek istediğinizden emin misiniz?',
    enquiryForm: 'Soru Formu',
    payNow: 'Şimdi Öde!',
    saveAndDelete: 'Kaydet ve Sil',
    submit: 'Gönder',
  },
  noDataFound: {
    bots: 'Botlar',
    noBotCreatedYet: 'Henüz bot oluşturulmadı!',
  },
  yourFriendWillMissYou: 'Arkadaşın seni özleyecek',
  rateYourBotCreationExperience: 'Bot oluşturma deneyiminizi değerlendirin',
  whatMadeYouDeleteYourBot: 'Botunuzu silmenize ne sebep oldu?',
  iCreatedByMistake: 'Hata ile oluşturdum',
  iWantToCreateAnotherBot: 'Başka bir bot oluşturmak istiyorum',
  couldNotFindFeaturesThatINeeded: 'İhtiyacım olan özellikleri bulamadım',
  uiWasHardToNavigate: 'UI gezinmesi zordu',
  iDonotWantToTell: 'Söylemek istemiyorum',
  other: 'Diğer',
  weWillDoOurBestToMakeAutomationFun:
    'Otomasyonu herkes için eğlenceli ve basit hale getirmek için elimizden gelenin en iyisini yapacağız.',
};
