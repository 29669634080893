import { createAction, props } from '@ngrx/store';


export const loadActiveMessages = createAction(
  '[Message] Load Active Messages',
  props<{ activeMessages: any[] }>()
);

export const loadMoreActiveMessages = createAction(
  '[Message] Load More Active Messages',
  props<{ activeMessages: any[] }>()
);

export const removeActiveMessages = createAction(
  '[Message] remove Active Message',
  props<{ uuid: string }>()
);
