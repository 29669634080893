import { Injectable } from '@angular/core';
import { DateFilter } from '../models/leads';
import { NetworkService } from './network.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { IDRAFT } from '../models/bots/draft';

@Injectable({
  providedIn: 'root',
})
export class WhatsAppService {

  /**
   * Variable is being used for holding the value for current Step
   */
  public createBotStep = new BehaviorSubject<number>(Number(localStorage.getItem('currenBotStep')) || 3);

  public showPopupForConnectBot = new BehaviorSubject<{
    show: false | true, useCase: string, botName: string
  }>({ show: false, useCase: '', botName: '' });
  /**
   * Variable to hold values as user moves across the create bot flow
   */
  public currentBotDetails = {
    appSecret: '',
    phoneNumberId: '',
    wabaId: '',
    accessToken: ''
  };

  // bot crate draft varibale
  public draft = new Subject();

  // to maintain connectedForm across steps
  public connectForm: any;

  constructor(public networkService: NetworkService) { }

  setWebhook(apiKey) {
    return this.networkService.httpRequest(
      'whatsapp-automation/create-bot',
      apiKey,
      'POST'
    );
  }

  createBot(bot: any) {
    return this.networkService.httpRequest(`whatsapp-automation/`, bot, 'POST');
  }

  /**
   * Function to create a bot using netcore api
   * @param payload Contains apikey and name
   */
  public createNetCoreBot(payload: any) {
    return this.networkService.httpRequest(`whatsapp-automation/create-netcore-bot`, payload, 'POST');
  }

  /**
   * Function to update whatsapp profile info if profile is not added at time of bot creation
   * @param _bot contains whatsapp Bot Id
   */
  public updateWhatsAppProfile(_bot: string) {
    return this.networkService.httpRequest(`whatsapp-automation/update-whatsapp-profile/${_bot}`, '', 'PUT', true);
  }

  public whatsAppUpdateProfileSection(_bot: string, payload: any) {
    return this.networkService.httpRequest(`whatsapp-automation/update-whatsApp-profile-info/${_bot}`, payload, 'PUT', true);
  }

  public getBotById(_bot: string) {
    return this.networkService.httpRequest(`whatsapp-automation/bot/${_bot}`);
  }

  createTool(payload, type) {
    return this.networkService.httpRequest(`${type}-automation/tools/tool`, payload, 'POST');
  }
  updateTool(payload, id) {
    return this.networkService.httpRequest(`whatsapp-automation/tools/tool/${id}`, payload, 'PUT');
  }

  sendMessage(_bot, text, waId, _subscriber, sentBy, allImages?) {
    this.networkService.httpRequest('whatsapp-automation/messages/send-message',
      { _bot, text, wa_id: waId, _subscriber, sentBy, medias: allImages }, 'POST')
      .then((data: any) => {
        return data;
      });
  }

  sendMessagev2(payload: {
    _bot: string; text: string; wa_id: string; _subscriber: string; sentBy: string;
    type: 'text' | 'image' | 'video' | 'document';
    medias: { id: string, url: string, caption: string }[]
  }) {
    return this.networkService.httpRequest('whatsapp-automation/messages/send-message',
      payload, 'POST');
  }

  exportLeads(start: string, end: string) {
    return new Promise((resolve, reject) => {
      const url = 'whatsapp-automation/subscribers/export?start=' + start + '&end=' + end;
      // this.cos.ngxService.start();
      this.networkService.httpRequest(url, null, 'GET', true)
        .then(data => {
          resolve(data);
          // this.cos.ngxService.stop();
        })
        .catch(reject);
    });
  }

  updateSubscriber(id, payload) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`whatsapp-automation/subscribers/subscriber/${id}`, payload, 'PUT')
        .then(data => {
          resolve(data);
        })
        .catch(reject);
    });
  }
  getSubscribersCount() {
    return this.networkService.httpRequest(`whatsapp-automation/subscribers/count`);
  }

  getFailedMessages(_bot, page) {
    return this.networkService.httpRequest(`whatsapp-automation/messages/get-failed-messages/${_bot}?page=${page}`);

  }

  resendAllFailedMessages(id) {
    return this.networkService.httpRequest(`whatsapp-automation/messages/resend-failed-messages/${id}`, {}, 'POST');
  }
  updateBot(payload, id) {
    return this.networkService.httpRequest(`whatsapp-automation/bot/${id}`, payload, 'PUT');
  }

  importContacts(payload: any) {
    return this.networkService.httpRequest(`whatsapp-automation/subscribers/import-subscribers`, payload, 'POST');
  }

  getConnectedBots() {
    return this.networkService.httpRequest('whatsapp-automation');
  }

  getWhatsAppGroup(id) {
    return this.networkService.httpRequest(`whatsapp-automation/groups/bot/${id}`);
  }

  getSubscriberById(id) {
    return this.networkService.httpRequest(`whatsapp-automation/subscribers/subscriber/${id}`);
  }

  checkSubmittedEnquiry(id) {
    return this.networkService.httpRequest(`whatsapp-automation/enquiry/enquiry-exists?_id=${id}`, null, 'GET');
  }

  getFilterList(date: DateFilter, page: number) {
    return this.networkService.httpRequest(`whatsapp-automation/subscribers/filter-list?startDate=${date.startDate}&endDate=${date.endDate}&page=${page || 1}`);
  }

  getSearchedLeads(searchValue: string) {
    return this.networkService.httpRequest(`whatsapp-automation/subscribers/filter-lead?search=${searchValue}`);
  }

  filterSubscriber(date?: DateFilter, search?: string, page?: number) {
    const queryUrl = date && search ? `?startDate=${date.startDate}&endDate=${date.endDate}&search=${search}&page=${page}` :
      date ? `?startDate=${date.startDate}&endDate=${date.endDate}&page=${page}` : !!search ? `?search=${search}&page=${page}` : '';
    return this.networkService.httpRequest(`whatsapp-automation/subscribers/${queryUrl}`);
  }

  getBroadcasts(_bot: string) {
    return this.networkService.httpRequest(`whatsapp-automation/broadcast/${_bot}`);
  }

  createBroadcast(broadcast) {
    return this.networkService.httpRequest(
      broadcast.selfNumber
        ? 'whatsapp-automation/broadcast/demo'
        : 'whatsapp-automation/broadcast',
      broadcast, 'POST');
  }

  deleteBroadcasts(ids: string[]) {
    return this.networkService.httpRequest('whatsapp-automation/broadcast', { ids }, 'DELETE');
  }
  getWhatsAppTemplates(id: string) {
    return this.networkService.httpRequest(`whatsapp-automation/templates/v2/bot/${id}`);
  }

  getWhatsAppWidgets(id: string) {
    return this.networkService.httpRequest(`whatsapp-automation/tools/bot/${id}`);
  }

  sendWhatsAppTemplate(payload) {
    return this.networkService.httpRequest(`whatsapp-automation/templates/v2/template`, payload, 'POST');
  }

  createWhatsAppTool(payload) {
    return this.networkService.httpRequest(`whatsapp-automation/tools/tool`, payload, 'POST');
  }

  getFailedMessagesBroadcast(broadcastId: string) {
    return this.networkService.httpRequest(`whatsapp-automation/broadcast/failed-messages-broadcast/${broadcastId}`);
  }

  /**
   * Method to call bot controller api for validating webhook url
   */
  public validateWebhookUrl(payload) {
    return this.networkService.httpRequest(`whatsapp-automation/validate-webhook`, payload, 'POST');
  }
  /**
   * Method to call api to generate apiKey
   */
  public generateApiKey(id) {
    return this.networkService.httpRequest(`whatsapp-automation/bot/generate-access-token?id=${id}`);
  }

  /**
   * Method to call api to sync template from netcore apis
   */
  getTemplateFromNetcore(id) {
    return this.networkService.httpRequest(`whatsapp-automation/templates/get-netcore-templates/${id}`);
  }

  /**
   * Drip Campaigns
   */
  getDripCampaigns(_bot: string) {
    return this.networkService.httpRequest(`whatsapp-automation/drips/${_bot}`);
  }

  createDripCampaign(dripCampaign) {
    return this.networkService.httpRequest('whatsapp-automation/drips', dripCampaign, 'POST');
  }

  deleteDripCampaigns(ids: string[]) {
    return this.networkService.httpRequest('whatsapp-automation/drips', { ids }, 'DELETE');
  }

  /**
   * function to get whatsapp flows
   */
  async getFlows(botId) {
    return this.networkService.httpRequest(`whatsapp-automation/flows/bot/${botId}`);
  }

  getBroadcastMessages(id: string, page: number, search: { whatsAppNumber: string; status: string }) {
    return this.networkService.httpRequest(`whatsapp-automation/messages/get-broadcast-messages?id=${id}&page=${page}&whatsAppNumber=${search.whatsAppNumber}&status=${search.status}`);
  }

  exportBroadcast(id) {
    return this.networkService.httpRequest(`whatsapp-automation/messages/export-broadcast`, { id }, 'POST');
  }

  // createDemoBot(bot: any) {
  //   return this.networkService.httpRequest(`whatsapp-automation/demo-bot`, bot, 'POST');
  // }

  updateDemoBot(payload, id) {
    return this.networkService.httpRequest(`whatsapp-automation/demo-bot/${id}`, payload, 'PUT');
  }

  verifyOTP(payload, id) {
    return this.networkService.httpRequest(`whatsapp-automation/verify-otp/${id}`, payload, 'PUT');
  }

  resendOTP(id) {
    return this.networkService.httpRequest(`whatsapp-automation/resend-otp/${id}`, null, 'GET');
  }

  updateFlow(id, payload) {
    return this.networkService.httpRequest(`whatsapp-automation/flows/flow/${id}`, payload, 'PUT');
  }

  updateTemplate(name, payload) {
    return this.networkService.httpRequest(`whatsapp-automation/templates/template/${name}`, payload, 'PUT');
  }

  createDemoBot(bot: any) {
    return this.networkService.httpRequest(`whatsapp-automation/demo-bot`, bot, 'POST');
  }

  updateDemoBotDetails(botId: string, payload: any) {
    return this.networkService.httpRequest(`whatsapp-automation/update-demo-bot/${botId}`, payload, 'PUT');
  }
  updateCloudApiDemoBot(botId: string, payload: any) {
    return this.networkService.httpRequest(`whatsapp-automation/demo-bot/cloud-api/${botId}`, payload, 'PUT');
  }

  updateNetCoreDemoBot(botId: string, payload: any) {
    return this.networkService.httpRequest(`whatsapp-automation/demo-bot/netcore-bot/${botId}`, payload, 'PUT');
  }

  async createElasticSearchIndex(_bot: string, payload: any) {
    return this.networkService.httpRequest(`whatsapp-automation/e-index/${_bot}`, payload, 'POST');
  }
}
