import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ToastrComponent } from './toastr/toastr.component';
import { LoaderComponent } from './loader/loader.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { TitleCaseDirective } from './directives/title-case.directive';
import { InputValidatorDirective } from './directives/input-validator.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './modal/modal.component';
import { SafePipe } from './safe.pipe';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DaterangepickerComponent, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { UpgradeModalComponent } from './upgrade-modal/upgrade-modal.component';
import { CountryDropdownComponent } from './country-dropdown/country-dropdown.component';
import { ProfileIconComponent } from './profile-icon/profile-icon.component';
import { WebPreviewComponent } from './web-preview/web-preview.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IndexMergeDatePickerComponent } from './index-merge-date-picker/index-merge-date-picker.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { CreateNewComponent } from './create-new/create-new.component';
import { MatRadioModule } from '@angular/material/radio';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { BpInputComponent } from './bp-input/bp-input.component';
import { BpIsRootAgencyDirective } from './directives/bp-is-root-agency.directive';
import { BpDropdownComponent } from './bp-dropdown/bp-dropdown.component';
import { BpIsWhiteLabelAccountDirective } from './directives/bp-is-white-label-account.directive';
import { BpLogoComponent } from './bp-logo/bp-logo.component';
import { BpTableComponent } from './bp-table/bp-table.component';
import { AclDirective } from './directives/acl.directive';
import { FeedbackComponent } from './feedback/feedback.component';
import { DemoBotModalComponent } from './demo-bot-modal/demo-bot-modal.component';
import { PaymentLinkComponent } from './payment-link/payment-link.component';
import { BpButtonComponent } from './bp-button/bp-button.component';
import { BpExportComponent } from './bp-export/bp-export.component';
import { SidebarToggleComponent } from './sidebar-toggle/sidebar-toggle.component';
import { BpMultiSelectDropdownComponent } from './bp-multi-select-dropdown/bp-multi-select-dropdown.component';
import { UnsavedWarningComponent } from './unsaved-warning/unsaved-warning.component';


@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ToastrComponent,
    LoaderComponent,
    TitleCaseDirective,
    InputValidatorDirective,
    TooltipComponent,
    NoDataFoundComponent,
    DropdownComponent,
    ModalComponent,
    SafePipe,
    DateRangePickerComponent,
    DatePickerComponent,
    ColorPickerComponent,
    ImagePreviewComponent,
    TooltipDirective,
    UpgradeModalComponent,
    CountryDropdownComponent,
    ProfileIconComponent,
    WebPreviewComponent,
    IndexMergeDatePickerComponent,
    DateTimePickerComponent,
    FeedbackModalComponent,
    BpInputComponent,
    BpIsRootAgencyDirective,
    BpDropdownComponent,
    CreateNewComponent,
    BpIsWhiteLabelAccountDirective,
    BpLogoComponent,
    BpTableComponent,
    FeedbackComponent,
    DemoBotModalComponent,
    AclDirective,
    PaymentLinkComponent,
    BpButtonComponent,
    BpExportComponent,
    SidebarToggleComponent,
    BpMultiSelectDropdownComponent,
    UnsavedWarningComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSlideToggleModule,
    MatTableModule,
    MatCheckboxModule,
    CdkAccordionModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ColorPickerModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    ToastrComponent,
    LoaderComponent,
    MatSlideToggleModule,
    MatInputModule,
    CdkAccordionModule,
    TitleCaseDirective,
    InputValidatorDirective,
    TooltipComponent,
    NoDataFoundComponent,
    DropdownComponent,
    ModalComponent,
    SafePipe,
    FormsModule,
    DateRangePickerComponent,
    DatePickerComponent,
    ColorPickerComponent,
    ImagePreviewComponent,
    TooltipDirective,
    CountryDropdownComponent,
    UpgradeModalComponent,
    ProfileIconComponent,
    WebPreviewComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    IndexMergeDatePickerComponent,
    MatTableModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    CreateNewComponent,
    DateTimePickerComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FeedbackModalComponent,
    BpInputComponent,
    BpIsRootAgencyDirective,
    BpDropdownComponent,
    BpIsWhiteLabelAccountDirective,
    BpLogoComponent,
    BpTableComponent,
    AclDirective,
    FeedbackComponent,
    DemoBotModalComponent,
    PaymentLinkComponent,
    BpButtonComponent,
    BpExportComponent,
    SidebarToggleComponent,
    BpMultiSelectDropdownComponent,
    UnsavedWarningComponent
  ],
  providers: [
    TitleCasePipe,
    DaterangepickerComponent,
    MatDatepickerModule
  ]
})
export class SharedModule { }
