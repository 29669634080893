export const agency = {
  activities: {
    activities: 'פעילויות',
    upcomingRenewals: 'חידושים קרובים',
    subscriptionActivities: 'פעילויות מינוי',
    upcomingDowngradeRequests: 'בקשות להפחתה קרובות',
    serialNumber: 'מספר סידורי',
    email: 'כתובת דוא"ל',
    phoneNumber: 'מספר טלפון',
    currentPlan: 'תוכנית נוכחית',
    price: 'מחיר',
    daysLeft: 'ימים נותרו',
    noDataFound: {
      upcomingDowngrades: 'בקשות להפחתה קרובות',
      noUpcomingDowngradesFoundYet: 'עדיין לא נמצאו בקשות להפחתה קרובות!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'חידושים קרובים של הלקוחות',
      subscriptionActivities: 'פעילויות מינוי',
      upcomingDowngradeRequestsOfCustomers: 'בקשות להפחתה קרובות של הלקוחות',
      serialNumber: 'מספר סידורי',
      customerEmail: 'כתובת דוא"ל של הלקוח',
      customerPhoneNumber: 'מספר טלפון של הלקוח',
      currentPlanOfCustomer: 'תוכנית נוכחית של הלקוח',
      currentPlanPrice: 'מחיר תוכנית נוכחית',
      daysLeft: 'ימים נותרו מהתוכנית הנוכחית של הלקוח'
    },
  },
  configuration: {
    configurationsHeader: 'הגדרות',
    emailHeader: 'דוא"ל',
    razorPayHeader: 'RazorPay',
    invoiceDetailsHeader: 'פרטי חשבונית',
    stripeHeader: 'Stripe',
    appTooltip: {
      integrateYourEmailProviderDetails: 'שלבו את פרטי ספק הדוא"ל שלכם כדי לשלוח דואר אלקטרוני ללקוחות שלכם בצורה חלקה.',
      enterYourRazorPayAccountDetails: 'הזינו את פרטי החשבון שלכם ב-RazorPay לעיבוד תשלומי הלקוח.',
      enterYourStripeAccountDetails: 'הזינו את פרטי החשבון שלכם ב-Stripe לעיבוד תשלומי הלקוח.',
      enterYourCompanyDetails: 'הזינו את פרטי החברה שלכם לצורך הפקת חשבוניות מדויקות ופרופסיונליות.',
    },
    email: {
      testEmail: 'דוא"ל בדיקה',
      addEmailIntegrationsDetails: 'הוספת פרטי ספק הדוא"ל',
      integrationDescription: 'כדי לשלב את פרטי ספק הדוא"ל שלכם, נא לספק את פרטי ספק הדוא"ל שלכם.' +
        ' כל הדואר האלקטרוני יישלח ללקוחות שלכם באמצעות כתובת הדוא"ל הזו, וכך תוכלו להבטיח משלוח אמין ואיכותי של הודעות ועדכונים חשובים.',
      awsRegion: 'אזור AWS',
      awsAccessKeyId: 'מזהה מפתח גישה של AWS',
      awsSecretAccessKey: 'מפתח גישה סודי של AWS',
      reset: 'איפוס',
      addDetails: 'הוספת פרטים',
      updateDetails: 'עדכון פרטים',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'הזינו את אזור AWS שלכם. האזור מגדיר את המיקום הפיזי של המשאבים שלכם ומשפיע על גורמים כמו השהיית נתונים והתאמה לתקנות.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'הזינו את מזהה מפתח הגישה של AWS שלכם. מפתח זה מזהה את חשבון AWS שלכם באופן ייחודי ונדרש למטרות היכנסה מאובטחת וגישה לשירותי AWS.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'הזינו את מפתח הגישה הסודי של AWS שלכם. מפתח זה משמש לצורך היכנסה מאובטחת וגישה לשירותי AWS.'
      },
      placeholders: {
        enterAwsRegion: 'הזן את אזור AWS שלך',
        enterAwsAccessKeyId: 'הזן את מזהה הגישה של AWS שלך',
        enterAwsSecretAccessKey: 'הזן את מפתח הגישה הסודי של AWS שלך',
      },
      modal: {
        deleteEmailIntegration: 'מחיקת אינטגרציה של דוא"ל',
        wantToDeleteEmailIntegration: 'האם אתם בטוחים שברוצים למחוק את אינטגרציה זו של דוא"ל?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'הוספת פרטי אינטגרציה של Facebook',
      integrateYourEmailProviderDetails: 'שלבו את פרטי ספק הדוא"ל שלכם. כל הודעות הדוא"ל יישלחו באמצעות הדוא"ל הזה ללקוחות שלכם',
      facebookAppId: 'Facebook App Id',
      facebookSecretAccessKey: 'Facebook Secret Access Key',
      reset: 'איפוס',
      cancel: 'ביטול',
      addDetails: 'הוספת פרטים',
      updateDetails: 'עדכון פרטים',
      delete: 'מחיקה',
      testEmail: 'דוא"ל בדיקה',
      modal: {
        deleteFacebookIntegration: 'מחיקת אינטגרציה של Facebook',
        wantToDeleteFacebookIntegration: 'האם אתם בטוחים שברוצים למחוק את אינטגרציה זו של Facebook?'
      },
      placeholders: {
        enterFacebookAccessKeyId: 'הזן Facebook Secret Access Key שלך',
        enterFacebookAppId: 'הזן את הזיהוי של אפליקציית Facebook שלך'
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'התאימו את ההודעה לרצונכם כיצד היא תופיע בחלון הצ\'אט מיידית לאחר שמשתמש בוט שולח בקשת לייב צ\'אט'
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'הוספת פרטי חשבונית',
      invoicesDescription: 'כדי ליצור חשבוניות ללקוחות שלכם, נא לספק את פרטי החברה שלכם, כולל שם החברה שלכם,' +
        'כתובת, פרטי יצירת קשר וכל מידע נוסף רלוונטי שנדרש להפקת חשבוניות מדויקות.' +
        'המידע הזה יושם ליצירת חשבוניות מקצועיות בשמך לכל עסקה.',
      companyName: 'שם החברה',
      country: 'מדינה',
      selectCountry: 'בחר מדינה',
      stateOrRegion: 'מדינה/אזור',
      streetAddress: 'כתובת',
      gstin: 'GSTIN',
      postalCode: 'מיקוד',
      addDetails: 'הוספת פרטים',
      updateDetails: 'עדכון פרטים',
      reset: 'איפוס',
      delete: 'מחיקה',
      loadMore: 'טעינה נוספת',
      time: 'זמן',
      phoneNumber: 'מספר טלפון',
      email: 'דוא"ל',
      status: 'סטטוס',
      message: 'הודעה',
      response: 'תשובה',
      placeOfSupply: 'מקום אספקה',
      placeholders: {
        enterYourCompanyName: 'הזן את שם החברה שלך',
        enterYourStateOrRegion: 'הזן את המדינה/האזור שלך',
        enterYourStreetAddress: 'הזן את כתובת הרחוב שלך',
        enterYourGstin: 'הזן את מספר ה- GSTIN שלך',
        enterPostalCode: 'הזן מיקוד',
        enterPlaceOfSupply: 'הזן מקום אספקה'
      },
      modal: {
        deleteEmailIntegration: 'מחיקת אינטגרציה של דוא"ל',
        wantToDeleteEmailIntegration: 'האם אתם בטוחים שברוצים למחוק את אינטגרציה זו של דוא"ל?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME:
          'הזינו את שם החברה שלכם. מידע זה ישמש לצורך זיהוי ומיתוג בחשבוניות.',
        TOOLTIP_COUNTRY: 'בחר את המדינה שלכם. מידע זה ישמש לצורך זיהוי ומיתוג בחשבוניות.',
        TOOLTIP_STATE: 'הזינו את המדינה שלכם. מידע זה ישמש לצורך זיהוי ומיתוג בחשבוניות.',
        TOOLTIP_STREET_ADDRESS: 'הזינו את כתובת הרחוב שלכם. מידע זה ישמש לצורך זיהוי ומיתוג בחשבוניות.',
        TOOLTIP_GSTIN: 'הזינו את מספר ה- GSTIN שלכם. מידע זה ישמש לצורך זיהוי ומיתוג בחשבוניות.',
        TOOLTIP_POSTAL_CODE: 'הזינו את המיקוד שלכם. מידע זה ישמש לצורך זיהוי ומיתוג בחשבוניות.',
        TOOLTIP_PLACE_OF_SUPPLY: 'הזינו את מקום האספקה שלכם. מידע זה ישמש לצורך זיהוי ומיתוג בחשבוניות.'
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'הוספת פרטי אינטגרציה של RazorPay',
      integrationDescription: 'כדי לשלב את פרטי החשבון שלכם ב-RazorPay, נא לספק את פרטי חשבון RazorPay שלכם.' +
        ' כל התשלומים של הלקוחות יעובדו דרך החשבון שלכם ב-RazorPay לטובת תשלומים חלקים',
      razorPayAccessKeyId: 'מזהה גישה של RazorPay',
      razorPaySecretAccessKey: 'מפתח גישה סודי של RazorPay',
      reset: 'איפוס',
      addDetails: 'הוספת פרטים',
      updateDetails: 'עדכון פרטים',
      loadMore: 'טעינה נוספת',
      time: 'זמן',
      phoneNumber: 'מספר טלפון',
      email: 'דוא"ל',
      status: 'סטטוס',
      message: 'הודעה',
      response: 'תשובה',
      placeholders: {
        enterRazorPayAccessKeyId: 'הזן מזהה גישה של Razorpay',
        enterRazorPaySecretAccessKey: 'הזן מפתח גישה סודי של Razorpay',
        enterRazorPayAccessId: 'הזן מזהה גישה של RazorPay',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'הזן את מזהה הגישה של Razorpay שלך. מפתח זה מזהה באופן ייחוד את האינטגרציה שלך' +
          ' עם Razorpay ונדרש לעסקאות תשלומים מאובטחות.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'הזן את מפתח הגישה הסודי של Razorpay שלך. מפתח זה הוא חיוני לתקשורת מאובטחת בין' +
          ' היישום ו-Razorpay ומאפשר עיבוד עסקאות חלק וניהול תשלומים.',
      },
      modal: {
        deleteRazorPayIntegration: 'מחק אינטגרציה של RazorPay',
        wantToDeleteThisIntegrtion: 'האם אתה בטוח שברצונך למחוק את אינטגרציה זו של אימייל?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'הוסף פרטי אינטגרציה של Stripe',
      integrationDescription: 'כדי לאינטגרציה את פרטי החשבון שלך ב-Stripe, אנא מספק את פרטי החשבון שלך ב-Stripe.' +
        ' כל התשלומים של הלקוחות יעובדו דרך חשבון ה-Stripe שלך לעסקאות חלקות',
      stripeSecretAccessKey: 'מפתח גישה סודי של Stripe',
      stripePublicKey: 'מפתח גישה ציבורי של Stripe',
      reset: 'אפס',
      addDetails: 'הוסף פרטים',
      updateDetails: 'עדכן פרטים',
      cancel: 'ביטול',
      loadMore: 'טען עוד',
      time: 'זמן',
      phoneNumber: 'מספר טלפון',
      email: 'דוא"ל',
      status: 'סטטוס',
      message: 'הודעה',
      response: 'תשובה',
      placeholders: {
        enterStripeSecretAccessKey: 'הזן מפתח גישה סודי של Stripe',
        enterStripePublicKey: 'הזן מפתח גישה ציבורי של Stripe',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'הזן את מפתח הגישה הסודי של Stripe שלך. מפתח זה משמש לאימות מאובטח של אינטגרציה שלך עם' +
          ' Stripe והוא הכרחי לעיבוד תשלומי לקוחות.',
        TOOLTIP_PUBLIC_KEY: 'הזן את מפתח הגישה הציבורי של Stripe שלך. מפתח זה משמש לאימות מאובטח של אינטגרציה שלך עם' +
          ' Stripe בצד הלקוח והוא הכרחי לעיבוד תשלומי לקוחות.',
      },
      modal: {
        deleteStripeIntegration: 'מחק אינטגרציה של Stripe',
        wantToDeleteStripeIntegration: 'האם אתה בטוח שברצונך למחוק את אינטגרציה זו של Stripe?'
      }
    }
  },
  customer: {
    manageCustomers: 'ניהול לקוחות',
    addNewCustomers: 'הוספת לקוחות חדשים',
    ensureYouHaveAddedAllYourCustomersHere: 'ודא שהוספת את כל הלקוחות שלך כאן',
    customerName: 'שם לקוח',
    nameIsRequired: 'שם הוא שדה חובה',
    emailId: 'כתובת דוא"ל',
    enterAValidEmail: 'הזן כתובת דוא"ל חוקית',
    createPassword: 'צור סיסמה',
    enterAStrongPassword: 'הזן סיסמה חזקה',
    confirmPassword: 'אימות סיסמה',
    cancel: 'ביטול',
    customer: 'לקוח',
    searchByNameOrEmail: 'חיפוש לפי שם או כתובת דוא"ל',
    filters: 'מסננים',
    allCustomers: 'כל הלקוחות',
    paidCustomers: 'לקוחות בתשלום',
    freeCustomers: 'לקוחות חינם',
    messageAddOnCustomers: 'לקוחות תוספת הודעות',
    botWhiteLabelCustomers: 'לקוחות בוט מותאמים אישית',
    sortBy: 'מיון לפי',
    sortByFrequency: 'מיון לפי תדירות',
    all: 'הכל',
    joinedDate: 'תאריך הצטרפות',
    plan: 'תוכנית',
    lastLogin: 'כניסה אחרונה',
    email: 'דוא"ל',
    nextDue: 'התשלום הבא',
    frequency: 'תדירות',
    clearAll: 'נקה הכל',
    apply: 'החל',
    messageConsumed: 'הודעות משומשות',
    addCustomer: 'הוסף לקוח',
    serialNumber: 'מספר סידורי',
    name: 'שם',
    contact: 'יצירת קשר',
    limit: 'הגבלה',
    location: 'מיקום',
    actions: 'פעולות',
    joinedOn: 'הצטרף בתאריך',
    typeOfCustomer: 'סוג לקוח',
    exceptWhatsapp: ' (למעט WhatsApp)',
    exceptWA: '(למעט WA)',
    wa: '(WA)',
    loadMore: 'טען עוד',
    conversationsWhatsapp: ' שיחות (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'הזן כתובת דוא"ל של הלקוח',
      enterCustomerPassword: 'הזן סיסמה של הלקוח',
      enterCustomerConfirmPassword: 'הזן אימות סיסמה של הלקוח',
      enterCustomerName: 'הזן את שם הלקוח',
    },
    placeholders: {
      enterCustomerEmail: 'הזן כתובת דוא"ל של הלקוח',
      searchByNameOrEmail: 'חיפוש לפי שם או כתובת דוא"ל',
      enterPassword: 'הזן סיסמה',
      enterCustomerName: 'הזן את שם הלקוח',
    }
  },
  dashboard: {
    dashboard: 'לוח בקרה',
    overview: 'סקירה כללית',
    totalCustomers: 'סה"כ לקוחות',
    paidCustomers: 'לקוחות בתשלום',
    freeCustomers: 'לקוחות חינם',
    thisWeeksSignUp: 'הרשמות לשבוע זה',
    todaysSignUp: 'הרשמות ליום זה',
    totalEarnings: 'רווח כולל',
    nA: 'לא ידוע',
    customerName: 'שם לקוח',
    nameIsRequired: 'שם הוא שדה חובה',
    emailId: 'כתובת דוא"ל',
    enterAValidEmail: 'הזן כתובת דוא"ל חוקית',
    editCustomer: 'ערוך לקוח',
    recentSignUps: 'הרשמות אחרונות',
    serialNumber: 'מספר סידורי',
    name: 'שם',
    email: 'דוא"ל',
    contact: 'צור קשר',
    plan: 'תוכנית',
    limit: 'הגבלה',
    location: 'מיקום',
    lastLogin: 'כניסה אחרונה',
    joinedOn: 'הצטרף בתאריך',
    actions: 'פעולות',
    exceptWhatsapp: ' (למעט WhatsApp)',
    messageConsumed: 'הודעות משומשות',
    conversationsWhatsapp: ' שיחות (WhatsApp)',
    placeholders: {
      enterCustomerName: 'הזן את שם הלקוח',
      enterCustomerEmail: 'הזן את כתובת הדוא"ל של הלקוח',
    },
    modal: {
      deleteCustomer: 'מחק לקוח',
      areYouSure: 'האם אתה בטוח שברצונך למחוק את הלקוח הזה?'
    }
  },
  downgradeRequests: {
    activites: 'פעילויות',
    upcomingRenewals: 'חידושים קרובים',
    subscriptionActivities: 'פעילויות מנויים',
    upcomingDonwgradeRequests: 'בקשות לירידת מרמה קרובות',
    serialNumber: 'מספר סידורי',
    email: 'אימייל',
    phoneNumber: 'מספר טלפון',
    currentPlan: 'תוכנית נוכחית',
    requestedOn: 'נשלטה בתאריך',
    downgradeOn: 'ירידה בתאריך',
    nA: 'לא ידוע',
    commaMonthly: ', חודשי',
    commaYearly: ', שנתי',
    noDataFound: {
      downgradeRequests: 'בקשות לירידת מרמה',
      noDowngradeRequestFound: 'לא נמצאו בקשות לירידת מרמה עדיין!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'חידושים קרובים של הלקוחות',
      subscriptionActivities: 'פעילויות מנויים',
      upcomingDowngradeOfCustomers: 'בקשות לירידת מרמה קרובות של הלקוחות',
      serialNumber: 'מספר סידורי',
      customerEmail: 'דוא"ל של הלקוח',
      customerPhoneNumber: 'מספר טלפון של הלקוח',
      currentPlanOfCustomer: 'תוכנית נוכחית של הלקוח',
      currentPlanPrice: 'מחיר התוכנית הנוכחית',
    }
  },
  emailIntegration: {
    emailIntegration: 'אינטגרציה של דוא"ל',
    addEmailIntegrationDetails: 'הוסף פרטי אינטגרציה של דוא"ל',
    integrateYourEmailProviderDetails: 'אינטגרציה של פרטי ספק דוא"ל שלך. כל האימיילים ישלחו באמצעות כתובת הדוא"ל הזו ללקוחותיך',
    awsRegion: 'אזור AWS',
    awsRegionIsRequired: 'אזור AWS הוא שדה חובה',
    awsAccessKeyId: 'מזהה מפתח גישה של AWS',
    awsAccessKeyIdIsRequired: 'מזהה מפתח גישה של AWS הוא שדה חובה',
    awsSecretAccessKey: 'מפתח גישה סודי של AWS',
    awsSecretAccessKeyIsRequired: 'מפתח גישה סודי של AWS הוא שדה חובה',
    cancel: 'ביטול',
    test: 'בדוק',
    action: 'פעולה',
    testEmail: 'בדוק אימייל',
    addDetails: 'הוסף פרטים',
    updateDetails: 'עדכן פרטים',
    saveDetails: 'שמור פרטים',
    placeholders: {
      enterAwsRegion: 'הזן אזור AWS',
      enterAwsAccessKeyId: 'הזן מזהה מפתח גישה של AWS',
      enterAwsSecretAccessKey: 'הזן מפתח גישה סודי של AWS',
    },
    modal: {
      deleteEmailIntegration: 'מחק אינטגרציה של דוא"ל',
      areYouSure: 'האם אתה בטוח שברצונך למחוק את אינטגרציה של דוא"ל זו?',
    },
    noDataFound: {
      emailIntegration: 'אינטגרצית דוא"ל',
      noEmailIntegrationFoundYet: 'לא נמצאו עדיין אינטגרציות דוא"ל!',
    }
  },
  subscription: {
    activities: 'פעילויות',
    upcomingRenewals: 'חידושים קרובים',
    subscriptionActivities: 'פעילויות מנויים',
    upcomingDonwgradeRequests: 'בקשות לירידת מרמה קרובות',
    filters: 'מסננים',
    manageActivities: 'נהל פעילויות',
    sortBy: 'מיין לפי',
    all: 'הכל',
    upgrades: 'שדרוגים',
    donwgrades: 'ירידות',
    renewals: 'חידושים',
    serialNumber: 'מספר סידורי',
    email: 'אימייל',
    phoneNumber: 'מספר טלפון',
    previousPlan: 'תוכנית קודמת',
    newPlan: 'תוכנית חדשה',
    description: 'תיאור',
    date: 'תאריך',
    nA: 'לא ידוע',
    upgraded: 'שודרג',
    commaYearly: ', שנתי',
    commaMonthly: ', חודשי',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'לא נמצאו עדיין פעילויות מנויים!',
      subscriptionsActivities: 'פעילויות מנויים',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'חידושים קרובים של הלקוחות',
      subscriptionActivities: 'פעילויות מנויים',
      upcomingDowngradeRequests: 'בקשות לירידת מרמה קרובות של הלקוחות',
      serialNumber: 'מספר סידורי',
      customerEmail: 'דוא"ל של הלקוח',
      customerPhoneNumber: 'מספר טלפון של הלקוח',
      previousPlan: 'תוכנית קודמת של הלקוח',
      newPlan: 'תוכנית חדשה של הלקוח',
      description: 'תיאור התוכנית',
      typeOfPayment: 'סוג התשלום',
    }
  },
  feedbacks: {
    feedbacks: 'משובים',
    serialNumber: 'מספר סידורי',
    customer: 'לקוח',
    type: 'סוג',
    rating: 'דירוג',
    reason: 'סיבה',
    description: 'תיאור',
    createdAt: 'נוצר בתאריך',
    nA: 'לא ידוע',
    loadMore: 'טען עוד',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'משובים על מחיקת חשבון ובוט',
      noFeedbacksFoundYet: 'טרם נמצאו משובים!',
    },
    appTooltip: {
      serialNumber: 'מספר סידורי',
      customerEmail: 'דוא"ל של הלקוח',
      feedbackForBotOrAccountDeletion: 'משוב עבור (מחיקת בוט/חשבון)',
      customerRating: 'דירוג של הלקוח',
      customerReason: 'סיבת הלקוח',
      feedbackCreatedAt: 'משוב נוצר בתאריך',
      customerFeedback: 'משוב מהלקוח',
    }
  }
};
