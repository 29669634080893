export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Zoeken'
    }
  },
  bpTable: {
    loadMore: 'Meer laden',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Zoek land'
    }
  },
  createNew: {
    continue: 'Doorgaan',
  },
  datePicker: {
    chooseADate: 'Kies een datum',
  },
  dateRangePicker: {
    choosePeriod: 'Kies periode',
    today: 'Vandaag',
    lastWeek: 'Afgelopen week',
    lastMonth: 'Afgelopen maand'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Geef het nummer op dat je wilt gebruiken voor testen',
    resendOtp: 'Stuur OTP opnieuw',
    enterOtp: 'Voer OTP in',
    verifyOtp: 'Verifieer OTP',
    sendOtp: 'Stuur OTP',
    cancel: 'Annuleren',
    placeholders: {
      phone: 'Telefoon*',
      enterOtp: 'Voer OTP in*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Selecteer alsjeblieft de reden',
    cancel: 'Annuleren',
    submit: 'Versturen!',
    giveFeedback: 'Geef feedback',
    ratings: 'Beoordelingen',
    reasonBehindYourDecision: 'Reden achter je beslissing',
    shareYourExperience: 'Deel je ervaring',
    reason1: 'reden1',
    reason2: 'reden2'
  },
  feedbackModal: {
    paymentIsInProgress: 'Betaling is aan de gang',
    pleaseDoNotCloseThisWindow: 'Sluit dit venster niet',
    placeholders: {
      name: 'Naam',
      email: 'E-mail*',
      phone: 'Telefoon*',
      pleaseShareYourUseCase: 'Deel alsjeblieft je gebruiksscenario'
    }
  },
  layout: {
    sidebar: {
      notifications: 'Meldingen',
      myAccount: 'Mijn Account',
      logout: 'Uitloggen',
      goOnline: 'Ga Online',
      manage: 'Beheren',
      deactivate: 'Deactiveren',
      activate: 'Activeren',
      delete: 'Verwijderen',
      home: 'Home',
      bots: 'Bots',
      inbox: 'Postvak IN',
      developer: 'Ontwikkelaar',
      analytics: 'Analytics',
      team: 'Team',
      subscriptions: 'Abonnementen',
      more: 'Meer',
      users: 'Gebruikers',
      partnerTeam: 'Partner Team',
      createNewBot: 'Nieuwe Bot maken',
      dashboard: 'Dashboard',
      customers: 'Klanten',
      plans: 'Plannen',
      activities: 'Activiteiten',
      feedbacks: 'Feedbacks',
      configurations: 'Configuraties',
      copy: 'Kopiëren',
      yourFriendWillMissYou: 'Je vriend zal je missen',
      rateYourBotCreationExperience: 'Beoordeel je ervaring met het maken van een Bot',
      whatMadeYouDeleteYourBot: 'Wat heeft je doen besluiten je Bot te verwijderen?',
      iCreatedByMistake: 'Ik heb het per ongeluk aangemaakt',
      iWantToCreateAnotherBot: 'Ik wil een andere Bot aanmaken',
      couldNotFindFeaturesThatINeeded: 'Ik vond de functies die ik nodig had niet',
      uiWasHardToNavigate: 'De UI was moeilijk te navigeren',
      iDonotWantToTell: 'Dat wil ik niet vertellen',
      other: 'Anders',
      weWillDoOurBestToMakeAutomationFun: 'We zullen ons best doen om automatisering leuk en eenvoudig te maken voor iedereen.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Schakel in of uit om live chatverzoeken toe te staan of te blokkeren',
        TOOLTIP_NOTIFICATION: 'Alle meldingen',
        TOOLTIP_MY_ACCOUNT: 'Je profiel en wachtwoordbeveiliging',
        TOOLTIP_LOGOUT: 'Uitloggen uit het dashboard'
      },
      modal: {
        logout: 'Uitloggen',
        botDeleteFeedbackForm: 'Feedbackformulier Bot Verwijderen',
        areYouSureToLogOut: 'Weet je zeker dat je wilt uitloggen?',
        yesIWillBeBack: 'Ja! Ik kom terug',
        deleteBot: 'Bot Verwijderen',
        areYouSureToDeleteBot: 'Weet je zeker dat je deze Bot wilt verwijderen?',
        cancel: 'Annuleren',
        saveAndDelete: 'Opslaan en Verwijderen'
      }
    }
  },
  modal: {
    cancel: 'Annuleren',
    yesDelete: 'Ja, verwijderen!',
    continue: 'Doorgaan'
  },
  unsavedChanges: {
    title: 'Niet-opgeslagen wijzigingen',
    description: 'Er kunnen niet-opgeslagen wijzigingen zijn. Weet u zeker dat u wilt vertrekken?'
  },
  notify: {
    youHave: 'Je hebt ',
    new: ' nieuw '
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'We raden aan om je bedrijfslogo te kiezen. Voeg een vierkante PNG- of JPG-afbeelding toe van minder dan 1 MB.'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Oeps! Het Baby-plan biedt ALLEEN EEN GRATIS bot aan. Upgrade naar het King-plan om meer bots te maken.',
    noIAmGood: 'Nee, ik ben goed',
    iWantToUpgrade: 'Ik wil upgraden',
    oops: 'Ops! ',
    planOfferOnlyOneFreeBot: ' OFFRE SOLO UN BOT GRATUITO. Effettua l\'upgrade a ',
    planToCreateMoreBots: ' per creare più bots.'
  },
  webPreview: {
    preview: ' Voorbeeld',
    botsMessages: 'Berichten van de Bot',
    bot: 'Bot ',
    eleven36AM: ' 11:36 AM',
    usersReply: 'Antwoord van de Gebruiker',
    landingPageBot: 'Bot voor Landingspagina',
    chatbot: 'Chatbot',
    chat: 'Chat',
    placeholders: {
      typeYourAnswer: 'Typ je antwoord'
    }
  },
  paymentLink: {
    discardPayment: 'Betaling annuleren',
    completePayment: 'Voltooi Betaling',
    importantUpdate: 'Belangrijke Update',
    accountUpdate: 'Account Update',
    youHave: 'Je hebt',
    exceededTheMessagesLimit: 'hebt de berichtlimiet overschreden',
    ofYour: 'van je',
    planAnd: 'plan en',
    botsAreNoLongerActive: 'bots zijn niet langer actief',
    onYourWebsite: 'op je website.',
    youHaveEitherIncompletePaymentOn: 'Je hebt ofwel een onvoltooide betaling op',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'of je hebt je plan niet succesvol geüpgraded.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'of je hebt je Bot niet succesvol wit gelabeld.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'of je hebt de "berichten Add-on" niet succesvol aangeschaft.',
    incompletePaymentMessage: 'Je hebt ofwel een onvoltooide betaling op of je hebt je plan niet succesvol geüpgraded.',
    please: 'Alsjeblieft',
    complete: 'Voltooi',
    upgrade: 'Upgrade',
    services: 'diensten',
    yourPlanToContinueServices: 'je plan om de diensten voort te zetten',
    yourPaymentToContinue: 'je betaling om door te gaan',
    forAnyQueryPleaseWriteUs: 'Voor vragen kun je ons schrijven op',
    close: 'Sluiten',
    writeOn: '',
    upgradePlan: 'Plan Upgraden',
    discardModal: {
      title: 'Betaling Annuleren',
      description: 'Weet je zeker dat je je betaling wilt annuleren?',
      confirmText: 'Ja, Betaling Annuleren'
    }
  },
  bpExport: {
    exportStatus: 'Status Export',
    tasksExport: 'Taak Export',
    download: 'Download',
    inProgress: 'in progress',
  }
};
