export const whitelabel = {
  plans: 'Planes',
  managePlan: 'Administrar planes',
  whitelabelCharges: 'Cargos de Whitelabel',
  chargesOrTaxesConfiguration: 'Configuración de cargos/impuestos',
  websitePlans: 'Planes del sitio web',
  whatsappPlansHeader: 'Planes de whatsapp',
  addOnPlans: {
    createAddOnPlans: 'Crear planes de complemento',
    checkoutYourCurrentMessage: 'Consulte sus planes de complemento de mensajes actuales y nuevos planes para sus clientes',
    choosePlanValidity: 'Elija la validez del plan',
    chooseValidity: 'Elija validez',
    oneYear: 'Un año',
    billingCycle: 'Ciclo de facturación',
    numberOfMessages: 'Número de mensajes',
    pleaseEnterNumberOfMessages: 'Ingrese el número de mensajes',
    setCurrency: 'Moneda establecida',
    select: 'Seleccionar',
    inr: 'INR',
    usd: 'Dólar estadounidense',
    cad: 'CANALLA',
    clearAll: 'Limpiar todo',
    apply: 'Aplicar',
    currency: 'Divisa',
    amount: 'Cantidad',
    validity: 'Validez',
    upgradePlan: 'Plan de actualización',
    addPlan: 'Agregar plan',
    serialNumber: 'No Señor.',
    messages: 'Mensajes',
    // validity: 'Validez',
    active: 'Activo',
    priceDetails: 'Detalles del precio',
    action: 'Acción',
    addOnChanges: 'Cargos adicionales:',
    // messages: ' Mensajes',
    perYear: 'por año',
    taxes: 'Impuestos',
    placeholders: {
      enterNumberOfMesasges: 'Ingrese el número de mensajes',
      enterAmount: 'Ingresar la cantidad',
      enterValidity: 'Ingrese la validez',
      searchByMessages: 'Buscar por mensajes',
    },
    modal: {
      deleteAddOnsPlan: 'Eliminar plan de complementos',
      wantToDeleteThisAddOnsPlan: '¿Estás seguro de que quieres eliminar este plan de complementos?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Agregar cargos Whitelabel',
    checkoutExistingCharges: 'Consulte los cargos existentes en diferentes métodos de pago y agregue nuevos cargos de Whitelabel',
    currency: 'Divisa',
    chooseCurrency: 'Elegir Moneda',
    amountPerBotPerMonth: 'Cantidad por bot por mes',
    cancel: 'Cancelar',
    upgradeChanges: 'ACTUALIZAR CARGOS',
    addCharges: 'Agregar cargos',
    serialNumber: 'No Señor.',
    // currency: 'Divisa',
    action: 'Acción',
    taxes: 'Impuestos',
    placeholders: {
      enterAmountBotPerMonth: 'Ingrese la cantidad bot por mes',
      searchByCurrency: 'Buscar por moneda',
    },
    modal: {
      deleteCharges: 'Eliminar cargos',
      wantToDeleteThisCharge: '¿Estás seguro de que quieres eliminar este cargo?',
    }
  },
  planManagement: {
    createNewPlans: 'Crear nuevos planes',
    checkoutYourCurrentPlan: 'Consulte sus planes actuales y agregue nuevos planes para sus clientes.',
    choosePlan: 'Elija el plan',
    selectPlan: 'Plan de selección',
    baby: 'Bebé',
    king: 'Rey',
    displayName: 'Nombre para mostrar',
    messageLimit: 'Límite de mensaje',
    enterAValidEmail: 'Introduzca un correo electrónico válido',
    setCurrency: 'Moneda establecida',
    select: 'Seleccionar',
    inr: 'INR',
    usd: 'Dólar estadounidense',
    cad: 'CANALLA',
    clearAll: 'Limpiar todo',
    apply: 'Aplicar',
    perMonth: '/Mes',
    perYear: '/Año',
    cancel: 'Cancelar',
    upgradePlan: 'Plan de actualización',
    addPlan: 'Agregar plan',
    serialNumber: 'No Señor.',
    plan: ' Plan',
    // displayName: 'Nombre para mostrar',
    messages: 'Mensajes',
    pricing: 'Fijación de precios',
    actions: 'Comportamiento',
    // plan: ' Plan',
    perMonthText: 'Por mes',
    perYearText: 'Por año',
    placeholders: {
      enterDisplayName: 'Ingrese el nombre de la pantalla',
      enterMessageLimit: 'Ingrese el límite de mensaje',
      searchByPlanName: 'Buscar por Nombre del Plan o Mensajes'
    },
    modal: {
      deletePlan: 'Eliminar Plan',
      wantToDeleteThisBabyPlan: 'Eliminar el Plan Baby tendrá un impacto en todos los clientes. ¿Estás seguro de que quieres eliminar este plan?',
      wantToDeleteThisPlan: '¿Estás seguro de que quieres eliminar este plan?',
    },
    appTooltip: {
      chooseYourPlanType: 'Elija su tipo de plan de plan',
      pleaseInputYourPlanDisplayName: 'Ingrese el nombre de la pantalla de su plan',
      typeMessageLimitForCustomer: 'Tipo de límite de mensaje para el cliente',
      selectYourPreferredCurrencies: 'Seleccione sus monedas preferidas',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Agregar impuestos',
    checkoutExistingTaxes: 'Consulte los impuestos existentes sobre diferentes métodos de pago y nuevos impuestos',
    taxName: 'Nombre de impuestos',
    applicableOn: 'Aplicable en',
    choosePlans: 'Elija planes',
    chooseCurrency: 'Elegir Moneda',
    percentage: 'Porcentaje',
    cancel: 'Cancelar',
    // addTaxes: 'Agregar impuestos',
    serialNumber: 'No Señor.',
    name: 'nombre',
    // percentage: 'Porcentaje',
    currency: 'Divisa',
    updateTaxes: 'Actualizar impuestos',
    actions: 'Comportamiento',
    modal: {
      deleteTaxes: 'Eliminar impuestos',
      wantToDeleteThisTax: '¿Estás seguro de que quieres eliminar estos impuestos?',
    },
    placeholders: {
      taxName: 'Nombre de impuestos',
      taxPercentage: 'Porcentaje fiscal',
      searchByCurrency: 'Buscar por moneda',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Crear nuevos planes',
    checkoutYourCurrentAndNewPlans: 'Consulte sus planes actuales y agregue nuevos planes para sus clientes.',
    choosePlan: 'Elija el plan',
    selectPlan: 'Plan de selección',
    baby: 'Bebé',
    king: 'Rey',
    displayName: 'Nombre para mostrar',
    messageLimit: 'Límite de Conversación',
    enterAValidMessageLimit: 'Ingrese un límite de conversación válido',
    messages: 'Conversaciones',
    setCurrency: 'Moneda establecida',
    select: 'Seleccionar',
    inr: 'INR',
    usd: 'Dólar estadounidense',
    cad: 'CANALLA',
    clearAll: 'Limpiar todo',
    apply: 'Aplicar',
    perMonth: '/Mes',
    perYear: '/Año',
    cancel: 'Cancelar',
    addPlan: 'Agregar plan',
    serialNumber: 'No Señor.',
    plan: 'Plan',
    pricing: 'Fijación de precios',
    actions: 'Comportamiento',
    perMonthText: 'Por mes',
    perYearText: 'Por año',
    updatePlan: 'Plan de actualización',
    // addPlan: 'Agregar plan',
    placeholders: {
      enterDisplayName: 'Ingrese el nombre de la pantalla',
      enterMessageLimit: 'Ingrese el límite de conversación',
      searchByPlanName: 'Buscar por nombre del plan o Conversaciones',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Ingrese el límite de conversación para el cliente',
      chooseYourPlanType: 'Elija su tipo de plan de plan',
      selectYourPreferredCurrencies: 'Seleccione sus monedas preferidas',
      pleaseInputYourPlanDisplayName: 'Ingrese el nombre de la pantalla de su plan',
    },
    modal: {
      deletePlan: 'Eliminar Plan',
      wantToDeleteThisBabyPlan: 'Eliminar el Plan Baby tendrá un impacto en todos los clientes. ¿Estás seguro de que quieres eliminar este plan?',
      areYouSureYouWantToDeleteThisPlan: '¿Estás seguro de que quieres eliminar este plan?',
    }
  }
};
