export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Bienvenue à BotPenguin!',
      login: 'Connexion',
      youAreAllSet: 'Vous êtes prêt! Vous pouvez utiliser la boîte de réception WhatsApp à partir de la page Détails du lead.',
      placeholders: {
        enterEmail: 'Entrez le courrier électronique',
        enterPassword: 'Entrer le mot de passe',
      }
    }
  }
};
