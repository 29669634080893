export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'Aktivasyon bağlantısı gönderildi, lütfen e-postanızı doğrulayın',
    weHaveSentActivationLinkToEmailAddress: 'Aktivasyon bağlantısını e-posta adresinize gönderdik',
    toVerifyYourAccount: 'hesabınızı doğrulamak için',
    onceVerifiedAutomaticallyRedirected: 'Doğrulandıktan sonra otomatik olarak panele yönlendirileceksiniz',
    didNotReceiveAnEmail: 'E-posta almadınız mı? Spam klasörünüzü kontrol edin',
    resendIn: 'Yeniden gönderme süresi: ',
    seconds: ' saniye',
    resend: 'Yeniden Gönder',
    backToSignUp: 'Kaydolmaya Geri Dön',
    greatPleaseCheckYourMessage: 'Harika, lütfen e-postanızı kontrol edin',
    weHaveSentEmailTo: 'E-posta gönderdik',
    withALinkToReset: 'hesabınızı sıfırlamak için bir bağlantıyla. Aktive olduktan sonra hesabınızı tamamlayabilir ve ücretsiz denemenize başlayabilirsiniz.',
    resent: 'Yeniden Gönder',
    backToLogin: 'Girişe Geri Dön',
  },
  forgotPassword: {
    forgotPassword: 'Şifrenizi mi unuttunuz?',
    noWorries: 'Endişelenmeyin,',
    weWillSendYouResetPasswordInstructions: 'size şifre sıfırlama talimatları göndereceğiz',
    workEmail: 'İş E-postası',
    continue: 'Devam Et',
    backToLogin: 'Girişe Geri Dön',
    placeholders: {
      enterYourEmail: 'E-postanızı Girin',
    },
  },
  logout: {
    logout: 'Çıkış Yap',
    wantToLogOut: 'Çıkış yapmak istediğinizden emin misiniz?',
    yesIWillBeBack: 'Evet, geri döneceğim',
    cancel: 'İptal',
  },
  newPassword: {
    createNewPassword: 'Yeni Şifre Oluştur',
    yourNewPasswordMustBeDifferent: 'Yeni şifreniz önceki kullanılan şifrelerden farklı olmalıdır.',
    password: 'Şifre',
    confirmPassword: 'Şifreyi Onayla',
    setPassword: 'Şifre Belirle',
    signUp: 'Kaydol',
    login: 'Giriş',
    backTo: 'Geri Dön ',
    setAPassword: 'Bir şifre belirleyin',
    setNewPasswordToYourAccount: 'Hesabınıza güvenlik ve gizlilik amacıyla yeni bir şifre belirleyin',
    backToSignUp: 'Kaydolmaya Geri Dön',
    placeholders: {
      enterYourPassword: 'Şifrenizi Girin',
    },
  },
  resetPassword: {
    passwordReset: 'Şifre Sıfırlama',
    yourPasswordHasBeenSuccessfullyReset: 'Şifreniz başarıyla sıfırlandı.',
    clickBelowToLogIn: 'Giriş yapmak için aşağıya tıklayın',
    continueToLogin: 'Girişe devam edin',
  },
  newSignUp: {
    welcomeBack: 'Hoş geldiniz ',
    to: ' ',
    logIn: 'Giriş ',
    as: 'olarak ',
    withFacebook: 'Facebook ile giriş yap',
    logInWithGoogle: 'Google ile giriş yap',
    or: 'VEYA',
    pleaseFillAValidWorkEmail: 'Lütfen geçerli bir iş e-postası doldurun*',
    workEmailIsRequired: 'İş e-postası gereklidir*',
    pleaseFillAValidPassword: 'Lütfen geçerli bir şifre doldurun*',
    passwordIsRequired: 'Şifre gereklidir*',
    rememberMe: 'Beni Hatırla',
    forgotPassword: 'Şifrenizi mi unuttunuz?',
    login: 'Giriş',
    doNotHaveAnAccount: 'Hesabınız yok mu?',
    setUpAFreeAccount: 'ÜCRETSİZ bir hesap kurun',
    noCreditCardRequired: 'Kredi kartı gerekmeksizin',
    cancelAnytime: 'Her zaman iptal edebilirsiniz',
    signUp: 'Kaydol ',
    signUpWithGoogle: 'Google\'a Kayıt Olun',
    emailIsAlreadyRegistered: 'E-posta zaten kayıtlı, devam etmek için Giriş\'e tıklayın',
    phoneNumberShouldHaveMinimum7Digits: 'Telefon numarası en az 7 haneli olmalıdır*',
    phoneNumberIsRequired: 'Telefon numarası gereklidir*',
    passwordMustContain: 'Şifre içermelidir',
    atleast6Characters: 'en az 6 karakter, ',
    oneUpperCaseLetter: '1 büyük harf ',
    oneSpecialCharacter: '1 özel karakter*',
    alreadyHaveAccount: 'Zaten bir hesabınız var mı?',
    signingUpToBotPenguinIsAConsentToOur: 'BotPenguin hesabı oluşturarak Gizlilik Politikamızı ve Kullanım Koşullarımızı kabul etmiş olursunuz.',
    privacyPolicy: 'Gizlilik Politikası',
    termsAndConditions: 'Kullanım Koşulları',
    and: ' ve ',
    english: 'İngilizce',
    hindi: 'Hintçe',
    german: 'Almanca',
    french: 'Fransızca',
    portuguese: 'Portekizce',
    spanish: 'İspanyolca',
    italian: 'İtalyanca',
    russian: 'Rusça',
    placeholders: {
      workEmail: 'İş E-postası*',
      password: 'Şifre*',
      phone: 'Telefon*',
    },
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Başarıyla abonelikten çıkarıldı',
    yourEmail: 'E-posta adresiniz ',
    haveBeenSuccessfullyUnsubscribedFrom: ' başarıyla abonelikten çıkarıldı',
    backToLogin: 'Girişe Geri Dön'
  }
};
