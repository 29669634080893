export const subscription = {
  subscriptions: 'Abonamente',
  plan: 'Plan',
  whatsappPlan: 'Plan WhatsApp',
  addOns: 'Adăugări',
  billingDetails: 'Detalii de facturare',
  invoices: 'Facturi',
  wa1Reminder: 'WA 1-a notificare',
  wa2Reminder: 'WA 2-a notificare',
  waRenewApi: 'API WA pentru reînnoire',
  waCheckUsageApi: 'API WA pentru verificarea utilizării',
  waResetUsageApi: 'API WA pentru resetarea utilizării',
  waExceedUsageApi: 'API WA pentru depășirea utilizării',
  waGetBotsInThisAccount: 'API WA pentru obținerea roboților din acest cont',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Avem cele mai accesibile prețuri pentru toate nevoile dumneavoastră.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'Plan WhatsApp',
    TOOLTIP_ADDS_ON_HEADING: 'Cumpărați pachete suplimentare de mesaje și etichetați-vă roboții',
    TOOLTIP_ADDS_ON_MESSAGE: 'Dacă ați atins limita lunară de mesaje, puteți cumpăra pachete suplimentare.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Verificați detaliile abonamentului curent',
    TOOLTIP_HORIZONTAL_BAR: 'Vizualizați prețurile noastre lunare sau anuale de la 3K la 500K de mesaje mutând bara de la stânga la dreapta.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Actualizați detaliile de facturare pentru facturare.',
    TOOLTIP_INVOICES_HEADING: 'Verificați facturile anterioare.',
    TOOLTIP_INVOICES_ACTION: 'Faceți clic pentru a primi factura în contul dvs. de e-mail'
  },
  currentPlan: {
    seeHowToUse: 'Aflați cum să utilizați',
    help: 'Ajutor',
    downgradeRequest1: 'Ați făcut o cerere de degradare a planului dumneavoastră la',
    downgradeRequest2: 'mesaje. Vă rugăm să rețineți că planul dumneavoastră va fi degradat până la sfârșitul ciclului de facturare curent',
    downgradeRequest3: 'Puteți să vă bucurați de',
    downgradeRequest4: 'servicii până atunci.',
    downgradeRequestMaster1: 'Ați făcut o cerere de degradare a planului dumneavoastră ',
    downgradeRequestMaster2: ' la',
    downgradeRequestMaster3: ' convorbiri. Vă rugăm să rețineți că planul dumneavoastră va fi degradat până la sfârșitul ciclului de facturare curent',
    downgradeRequestMaster4: 'Puteți să vă bucurați de ',
    downgradeRequestMaster5: 'servicii până atunci.',
    active: 'Activ',
    inactive: 'Inactiv',
    month: 'lună',
    year: 'an',
    messagePack: 'Pachet de Mesaje',
    king: 'King',
    baby: 'Baby',
    whatsapp: 'WhatsApp',
    youAreOn: 'Sunteți pe',
    plan: 'plan',
    totalMessagesLimit: 'Limita totală de mesaje',
    liveAgentLimit: 'Limită Agenta Live',
    perMonth: '/lună',
    agents: 'agenți',
    messagesAddOn: 'Adăugare Mesaje',
    botWhitelabels: 'Etichete Alb RoBot',
    totalAddOnMessages: 'Total Adăugare Mesaje',
    basePrice: 'Preț de bază',
    gst: 'TVA',
    totalPrice: 'Preț total',
    totalAddOnPrice: 'Preț total Adăugare',
    whitelabelPrice: 'Preț etichetă alb',
    startedOn: 'Început la',
    nextDueOn: 'Următoarea scadență la',
    addOnExpiry: 'Expirare Adăugare',
    website: 'Site web',
    facebook: 'Facebook',
    telegram: 'Telegram'
  },
  invoice: {
    invoiceNumber: 'Număr Factură',
    date: 'Dată',
    invoiceAmount: 'Suma Factură',
    planAmount: 'Suma Plan',
    plan: 'Plan',
    messages: 'Mesaje',
    action: 'Acțiune',
    nA: 'N/A',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Faceți clic pentru a primi factura în contul dvs. de e-mail',
      clickToDownloadInvoice: 'Faceți clic pentru a descărca factura în format PDF',
    }
  },
  messageAddOn: {
    validity: 'Valabilitate',
    kingPlanHeader: '👑 Plan King',
    youAreOnThe: 'Vă aflați pe ',
    asOfNowAndWillGet: 'începând de acum și veți obține ',
    messages: 'mesaje ',
    inThisPlan: 'în acest plan. ',
    youCanBuyAdditionalMessaes: 'Puteți cumpăra mesaje suplimentare conform nevoilor dvs. de afaceri.',
    plan: ' Plan',
    baby: 'Baby',
    king: 'King',
    get: 'Obțineți ',
    babyPlan: 'Plan Baby ',
    upgradePlan: 'Upgrade Plan',
    asOfNow: ' de acum.',
    pleaseUpgradeToKingPlan: 'Vă rugăm să faceți upgrade la ',
    toEnableAddOnsAndWhitelabelPacks: ' pentru a activa Adăugările și Etichetele Alb.',
    messageAddOnPacks: 'Pachete Adăugare Mesaje',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Dacă ați atins limita lunară de mesaje, cumpărați pachete suplimentare și bucurați-vă de servicii neîntrerupte.',
    pleaseSelectNumberOfExpectedMessages: 'Vă rugăm să selectați numărul de mesaje așteptate',
    addOnsValidity: 'Valabilitate Adăugări',
    currentBillingCycle: 'Ciclul de Facturare Curent',
    oneYear: 'Un An',
    summary: 'Rezumat',
    messageCreditsWithKingPlan: 'credite de mesaje cu planul King',
    messageCreditsWith: 'credite de mesaje cu ',
    withPlan: '',
    billingCycle: 'Ciclul de Facturare',
    payableAmount: 'Sumă de Plătit ',
    oneTimePayment: 'Plată o singură dată',
    plusTaxes: ' + Taxe',
    buyNow: 'Cumpărați Acum',
    whiteLabelChatWindow: 'Fereastră de Chat Etichetă Albă',
    noBotsLeftToWhiteLabel: 'Nu mai există roboți pentru a eticheta alb.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Vă rugăm să selectați roboții pe care doriți să-i etichetați alb.',
    selectBots: 'Selectați Roboți',
    bot: 'RoBot',
    bots: 'Roboți',
    toBeWhiteLabelled: ' pentru a fi Etichetă Albă',
    remove: 'Eliminați ',
    brandNameFromChatWindow: ' numele mărcii din fereastra de chat.',
    willBeValidTill: 'Va fi valabil până la ',
    ourYearlyPrice: 'Prețul nostru anual ',
    directWhiteLabel: 'Etichetă Albă Directă',
    whiteLabelledBots: 'Roboți Etichetați Alb',
    nextRenewalColon: 'Următoarea Reînnoire: ',
    optOutRequested: 'Cerere Optare',
    contactAgency: 'Contactați Agenția',
    optOut: 'Optare',
    removeWhiteLabel: 'Eliminare Etichetă Albă',
    numOfBots: 'Nr. de Bot-uri',
    amount: 'Suma',
    taxes: 'Taxe',
    totalAmount: 'Suma totală',
    select: 'Selecteă',
    selectTheValidity: 'Selecteă Valabilitatea',
    messageCount: 'Mesaje',
    numberOfPacks: 'Anzahl der Pakete',

  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Detalii de plată',
    cardNumber: 'Numărul cardului ',
    cvv: 'CVV ',
    //
    month: 'Luna',
    year: 'Anul',
    save: 'Salvează',
    add: 'Adaugă',
    edit: 'Editează',
    disclaimer: 'Disclaimer: ',
    disclaimerDescription1: 'Detaliile metodei de plată de mai sus se aplică doar utilizatorilor din afara Indiei.',
    disclaimerDescription2: 'Nu salvăm detaliile cardului dvs. Cu toate acestea, în cazul în care doriți să vă schimbați ' +
      'detaliile cardului, acestea vor fi schimbate direct cu gateway-ul de plată.',
    billingAddress: 'Adresa de facturare',
    companyName: 'Numele companiei ',
    country: 'Țara ',
    selectCountry: 'Selectează țara',
    stateOrRegion: 'Stat/Regiune ',
    streetAddress: 'Adresa străzii ',
    gstIn: 'GSTIN ',
    postalCode: 'Cod poștal ',
    cancel: 'Anulează',
    proceedToPay: 'Continuă spre plată',
    company: 'Companie',
    state: 'Stat',
    masterCard: 'Mastercard',
    yourCompanyName: 'Numele companiei dvs.',
    yourCountry: 'Țara dvs.',
    yourRegion: 'Regiunea dvs.',
    yourPostalCode: 'Codul poștal al dvs.',
    plusTaxes: ' + taxe',
    dropDown: {
      month: 'Luna *',
      year: 'Anul *',
    },
    placeholders: {
      enterCardNumber: 'Introduceți numărul cardului',
      cvv: 'CVV',
      enterYourCompanyName: 'Introduceți numele companiei dvs.',
      enterYourStateOrRegion: 'Introduceți statul/regiunea dvs.',
      enterYourStreetAddress: 'Introduceți adresa străzii dvs.',
      enterYourGSTIN: 'Introduceți codul dvs. GSTIN',
      enterPostalCode: 'Introduceți codul poștal',
    }
  },
  plans: {
    viewDetailedPricing: 'Vezi Detaliile de Preț',
    seeHowToUse: 'Aflați cum să utilizați',
    help: 'Ajutor',
    weHaveGotAPricingPlanPerfectForYou: 'Avem un plan de preț perfect pentru dvs',
    weBelieve: 'Credem că ',
    shouldBeAccessibleToAllCompanies: ' ar trebui să fie accesibil tuturor companiilor, indiferent de dimensiune.',
    monthly: 'Lunar',
    yearly: 'Anual',
    twoMonthsFree: '(2 Luni GRATUITE)',
    babyPlan: 'Plan Bebeluș',
    freeForever: 'GRATUIT Pentru Totdeauna',
    messagesPerMonth: 'Mesaje pe lună',
    currentPlan: 'Planul Curent',
    freePlan: 'Plan GRATUIT',
    kingPlan: 'Plan King',
    perYear: 'pe an',
    plan: 'plan',
    toThe: ' la',
    plusTaxes: ' + Taxe',
    perMonth: 'pe lună',
    upgradePlan: 'Upgrade Plan',
    changePlan: 'Schimbă Planul',
    currentlyActive: 'În prezent Activ',
    emperorPlan: 'Plan Împărat (Întreprindere)',
    sendAsManyMessagesAsYouWant: 'Trimiteți cât de multe mesaje doriți',
    contactUs: 'Contactați-ne',
    features: 'Caracteristici',
    areYouSureYouWantToShiftFrom: 'Sunteți sigur că doriți să treceți de la',
    messages: 'Mesaje',
    planQuestionMark: 'plan?',
    downgradeToFreePlan: 'Downgrade la Planul GRATUIT',
    goToBabyPlan: 'Mergi la Planul Bebeluș',
    thankYouForChoosingBotPenguin: 'Vă mulțumim că ați ales BotPenguin',
    bots: 'Bots',
    facebookPages: 'Pagini Facebook',
    teamMember: 'Membru al echipei',
    dataStorage: 'Stocare date',
    conditionalFlow: 'Flux condiționat',
    liveChat: 'Chat Live',
    bookAppointments: 'Rezervă Programări',
    csvExport: 'Export CSV',
    emailAndCallSupport: 'Suport prin E-mail și Telefon',
    googleCalendarIntegrations: 'Integrare Google Calendar',
    zohoCrmIntegrations: 'Integrare Zoho CRM',
    reportsAndAnalytics: 'Rapoarte și Analize',
    removeBotPenguinBranding: 'Elimină Brandingul BotPenguin',
    oneBot: '1 Bot',
    twoKMessagesPerMonth: '2k mesaje/lună',
    oneFacebookPage: '1 Pagină Facebook',
    oneTeamMember: '1 Membru al echipei',
    thirtyDaysOfDataStorage: '30 de zile de stocare a datelor',
    unlimitedBot: 'Bot Nelimitat',
    threeTo500kMessagesPerMonth: '3k până la 500k mesaje/lună',
    tenFacebookPages: '10 Pagini Facebook',
    threeTeamMembers: '3 Membri ai echipei',
    nintyDaysOfDataStorage: '90 de zile de stocare a datelor',
    unlimitedMessages: 'Mesaje nelimitate',
    unlimitedFacebookPages: 'Pagini Facebook nelimitate',
    unlimitedTeamMembers: 'Membri ai echipei nelimitați',
    unlimitedDataStorage: 'Stocare date nelimitată',
    youAreHere: 'Vă aflați aici!',
    detailedPricing: {
      kingsplan: 'Planul King',
      elementary: 'Elementar',
      solutionType: 'Tipul Soluției',
      saas: 'SaaS',
      fbpage1fb: '1 Pagină FB',
      perMonth100: '100 pe lună',
      enterprise: 'Enterprise',
      saasOnPremises: 'SaaS On-Premises',
      aiChatbotFeatures: 'Caracteristici AI Chatbot',
      dataSourceForTraning: 'Sursa de date pentru antrenament',
      smartFeatures: 'Caracteristici Inteligente',
      liveChat: 'Chat Live',
      day30: '30 de zile',
      day90: '90 de zile',
      landSquared: 'LandSquared',
      doItYourself: 'Fă-o singur',
      unifiedInbox: 'Căsuță poștală unificată',
      platforms: 'Platforme',
      websiteChatbot: 'Chatbot pe website',
      FacebookMessengerChatbot: 'Chatbot Facebook Messenger',
      Telegram: 'Telegram',
      conversationalLandingPage: 'Pagina de Aterizare Conversațională',
      integrations: 'Integrări',
      reportsAndAnalytics: 'Rapoarte și Analitice',
      customerSupport: 'Suport pentru Clienți',
      security: 'Securitate',
      bots: 'Boti',
      babyPlan: 'Plan Mic',
      kingPlan: 'Plan King',
      emperorPlan: 'Plan Împărat',
      noofBots: 'Numărul de Boti',
      one: '1 (Unul)',
      unlimitedBots: 'Boti nelimitati',
      noOfWebsite: 'Numărul de website-uri',
      unlimitedWebsites: 'Website-uri nelimitate',
      unlimitedMessages: 'Mesaje nelimitate',
      messagesSend: 'Mesaje Trimise',
      perMonth2000: '2,000 pe lună',
      upto500000perMonth: 'Până la 500.000 pe lună',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Pagini nelimitate',
      telegramNumbers: 'Numere Telegram',
      unlimited: 'Nelimitat',
      whatsappNumbers: 'Numere WhatsApp',
      subscribersLeads: 'Abonați/Prospecte',
      createSupportTeam: 'Creează Echipa de Suport pentru Chat Live',
      oneMember: '1 Membru',
      threeMembers: '3 membri',
      conversationHistory: 'Istoric Conversații',
      thirtyDays: '30 de zile',
      ninetyDays: '90 de zile',
      chatbotDesigning: 'Design Chatbot',
      weAreHereToHelp: 'Suntem aici să ajutăm',
      weTakeCareEndToEnd: 'Ne ocupăm de tot',
      multipleLanguages: 'Mai multe Limbi',
      conditionalFlow: 'Flux Condițional',
      removeBotPenguinBranding: 'Înlătură Branding-ul BotPenguin',
      gptMessages: 'Mesaje GPT',
      aiChatbot: 'Chatbot AI',
      chatGPTIntegration: 'Integrare ChatGPT',
      configureAIChatbotPersonality: 'Configurează Personalitatea Chatbotului AI',
      trainChatbotOnYourOwnData: 'Antrenează Chatbotul pe datele tale',
      trainChatbotOnWebPages: 'Antrenează Chatbotul pe paginile web',
      trainChatbotOnMultipleFiles: 'Antrenează Chatbotul pe fișiere multiple',
      trainChatbotOnFAQs: 'Antrenează Chatbotul pe Întrebări Frecvente (FAQ)',
      dataTrainingStorage: 'Stocare Date Antrenament',
      useChatGPTModel4_3_5Turbo: 'Folosește Modelul ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Încarcă fișiere multiple pentru antrenamentul Chatbotului',
      naturalLanguageProcessing: 'Prelucrare a Limbajului Natural',
      naturalLanguageUnderstanding: 'Înțelegere a Limbajului Natural',
      answerLength: 'Lungimea Răspunsului',
      chatTone: 'Tonul Conversației',
      multiLanguage: 'Limbi Multiple',
      answerFormatting: 'Formatarea Răspunsului',
      customPrompt: 'Solicitare Personalizată',
      integrateBotPenguinLLM: 'Integrează BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrează LLM Propriu/Personalizat',
      periodicallySyncDataSources: 'Sincronizează Periodic Sursele de Date',
      aiBasedSuggestedQuestions: 'Întrebări Sugerate Bazate pe AI',
      chooseVisibilityForParticularWebpageSitemap: 'Alege Vizibilitatea pentru Pagină/Sitemap Specific',
      chooseKnowledgeMix: 'Alege Mixul de Cunoștințe:\nBaza ta de Cunoștințe\nCunoștințe ChatGPT\n"Cunoștințe ChatGPT + Baza ta de Cunoștințe"',
      faqExtractionFromFileAndURL: 'Extragere FAQ din Fișier și URL',
      analyticsToImproveAI: 'Analitice pentru Îmbunătățirea AI',
      exportQuestionFlow: 'Exportă Fluxul de Întrebări',
      sessionManagement: 'Managementul Sesiunii',
      collectFeedbackOnChatbotResponses: 'Colectează Feedback privind Răspunsurile Chatbotului',
      smartAIFollowUpQuestions: 'Întrebări de Urmărire Inteligentă AI',
      trainOnYourNotionWorkspaceData: 'Antrenează pe datele din Spațiul Notion al tău',
      trainOnYoutubeVideo: 'Antrenează pe Video YouTube',
      trainOnCustomVideoUpload: 'Antrenează pe Încărcare Video Personalizată',
      rssFeed: 'Flux RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'Blocare IP și Regiune',
      emailAndMobileValidation: 'Validare Email și Mobil',
      appointmentsBooking: 'Programări pentru Întâlniri',
      humanHandover: 'Transfer la Om',
      botHandover: 'Transfer la Bot',
      handoverBetweenAgents: 'Transfer între Agente',
      mobileAppsForAgents: 'Aplicații Mobile pentru Agenți',
      roleBasedAccessControls: 'Control de Acces Bazat pe Roluri',
      apiIntegrationOnChatFlows: 'Integrare API în Fluxurile de Chat',
      liveChatWidget: 'Widget Chat Live',
      typingIndicator: 'Indicator de Scriere',
      liveVisitorsDetails: 'Detalii despre Vizitatori în Timp Real',
      multipleSimultaneousChat: 'Chaturi Simultane Multiple',
      liveTypingView: 'Vizualizare Scriere în Timp Real',
      ruleBasedChatRouting: 'Dirijare Chat Bazată pe Reguli',
      chatAutoAssignments: 'Atribuiri Automate Chat',
      efficientOmnichannelInterface: 'Interfață Omnicanal Eficientă',
      quickReplies: 'Răspunsuri Rapide',
      unifiedCustomerProfile: 'Profil Unificat al Clientului',
      visitorStats: 'Statistici despre Vizitatori',
      addContactsFromWhatsapp: 'Adaugă Contacte din WhatsApp',
      applyFilterAndSortConversations: 'Aplică Filtre și Sortează Conversații',
      seeChatLogs: 'Vezi Jurnalele de Chat',
      assignNotesAndChatTags: 'Atribuie Note și Etichete Chatului',
      directCallsToLeadsWithTwilio: 'Apeluri Directe către Prospecte cu Twilio',
      customerSegmentation: 'Segmentarea Clienților',
      exportLeadsInCSV: 'Exportă Prospecte în format CSV',
      leadNotificationsByEmail: 'Notificări pentru Prospecte prin Email',
      website: 'Website',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegram',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Declanșare Automată pe Dispozitive Mobile/Deskop',
      hideBotOnSpecificPages: 'Ascunde Botul pe Anumite Pagini',
      imageSharing: 'Partajare Imagini',
      readyToUseChatFlowTemplates: 'Șabloane de Flux de Chat Gata de Utilizare',
      fileSharing: 'Partajare Fișiere',
      customDesignForChatWindow: 'Design Personalizat pentru Fereastra de Chat',
      facebookMessengerBot: 'Bot Facebook Messenger',
      facebookSubheading: 'Subtitlu Facebook',
      telegramSubheading: 'Subtitlu Telegram',
      conversationalLangdingPageSubheading: 'Subtitlu Pagină de Aterizare Conversațională',
      integrationsSubheading: 'Subtitlu Integrări',
      multipleSmartFlows: 'Fluxuri Inteligente Multiple',
      dripCampaigns: 'Campanii cu Stropi',
      broadcastFBMessages: 'Transmitere Mesaje FB',
      multipleFacebookBotWidgets: 'Widget-uri Multiple pentru Botul Facebook',
      facebookAutoReplyOnComments: 'Răspuns Automat la Comentarii Facebook',
      facebookECommerce: 'Comerț Electronic Facebook',
      broadcastAnalytics: 'Analitici de Transmitere',
      telegramMessengerBot: 'Bot Telegram Messenger',
      readyToUseTemplates: 'Șabloane Gata de Utilizare',
      groupBroadcastTelegramMessages: 'Mesaje Grupate și de Transmitere pe Telegram',
      multipleTelegramBotWidgets: 'Mai multe widgeturi pentru botul Telegram',
      conversationalLandingPageBot: 'Bot Pagină de Aterizare Conversațională',
      smartReplies: 'Răspunsuri Inteligente',
      interactiveAndCustomizableUI: 'Interfață Interactivă și Personalizabilă',
      leadManagement: 'Gestionează Prospectele',
      easyToInstallAndShare: 'Ușor de Instalat și Partajat',
      agileCRM: 'CRM Agil',
      capsule: 'Capsulă',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Închide',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistem de Emitere a Biletelor de Suport',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Apeluri Audio și Video în Chat (Twilio/Agora)',
      courierTracking: 'Urmărirea Curierilor',
      emailIntegration: 'Integrare Email',
      webhooks: 'Webhooks',
      schedulers: 'Planificatori',
      paymentGateways: 'Gateway-uri de Plată (Stripe și RazorPay)',
      enterpriseIntegrations: 'Integrări pentru Întreprinderi',
      customIntegration: 'Integrare Personalizată',
      shopifyIntegration: 'Integrare Shopify',
      totalNumberOfMessages: 'Număr Total de Mesaje',
      totalNumberOfLeads: 'Număr Total de Prospecte',
      weeklyReportBotwise: 'Raport Săptămânal pe Bot',
      averageAgentResponeTime: 'Timpul Mediu de Răspuns al Agenților',
      visitorTimingReport: 'Raport de Timp al Vizitatorilor',
      customerSegmentBasedReports: 'Rapoarte Bazate pe Segmentarea Clienților',
      tagBasedReports: 'Rapoarte Bazate pe Etichete',
      leadGenerationReport: 'Raport de Generare a Prospectelor',
      supportOnWhatsApp: 'Suport pe WhatsApp',
      knowledgeBase: 'Baza de Cunoștințe',
      onboardingAndTraining: 'Proces de Integrare și Instruire',
      callAndEmailSupport: 'Suport Telefonic și prin Email',
      freeChatbotCreation: 'Creare Chatbot Gratuită',
      customerSuccessOfficer: 'Ofițer de Succes al Clienților',
      secureConnection2048BitSSL: 'Conexiune Sigură cu SSL de 2048 de biți',
      securityAssessment: 'Evaluare a Securității',
      dataCenterInUSOrEU: 'Centru de Date în SUA sau UE',
      twoStepVerification: 'Verificare în Două Etape',
      accessRestrictionsBasedOnIPs: 'Restricții de Acces Bazate pe Adrese IP',
      onDemandAuditLog: 'Jurnal de Audit la Cerere',
      bannedVisitorsList: 'Listă de Vizitatori Interziși',
      customAPIRate: 'Tarifare API Personalizată',
      trustedDomains: 'Domenii de Încredere',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Curios cu Privire la Programare',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Planul nostru gratuit',
      TOOLTIP_KING_PLAN: 'Planul nostru cel mai popular',
      TOOLTIP_EMPEROR_PLAN: 'Plan personalizat pentru mesaje nelimitate, date nelimitate, membri ai echipei nelimitați',
      TOOLTIP_CONTACT_US: 'Contactați-ne prin WhatsApp sau e-mail la contact@botpenguin.com pentru a afla mai multe despre planul nostru personalizat',
      TOOLTIP_UPGRADE: 'Upgrade la planul dvs. curent pentru funcționalități suplimentare',
      TOOLTIP_MONTHLY: 'Selectați un ciclu de plată lunar',
      TOOLTIP_YEARLY: 'Selectați un ciclu de plată anual pentru beneficii de reducere',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Mergi la Planul Bebeluș',
    freeForever: 'GRATUIT pentru Totdeauna',
    features: 'Caracteristici',
    currentPlan: 'Planul Actual',
    changePlan: 'Schimbă Planul',
    currentlyActive: 'În prezent Activ',
    seeHowToUse: 'Află cum să folosești',
    weHaveGotPricingPerfectForYou: 'Avem un plan de prețuri perfect pentru tine',
    weBelieve: 'Noi credem că ',
    shouldBeAccessibleToAllCompanies: ' ar trebui să fie accesibil tuturor companiilor, indiferent de mărime.',
    monthly: 'Lunar',
    yearly: 'Anual',
    twoMonthsFree: '(2 Luni GRATUIT)',
    kingPlan: 'Planul King',
    perYear: 'pe an',
    perMonth: 'pe lună',
    conversationsPerMonth: 'Conversații pe lună',
    upgradePlan: 'Upgradează Planul',
    emperorPlan: 'Planul Împărat (Enterprise)',
    haveAsManyConversationsAsYouNeed: 'Ai atâtea conversații cât ai nevoie',
    contactUs: 'Contactează-ne',
    metaConversationChargesAreAdditional: 'Taxele suplimentare pentru conversații Meta sunt plătite direct de tine către Meta, ' +
      'în funcție de utilizarea ta. Nu percepem niciun adaos comercial sau taxe de facilitare.',
    viewDetailedPricing: 'Vezi Detaliile de Preț',
    conversationPricingModelUsedByMeta: 'Modelul de Prețuri pentru Conversații folosit de Meta',
    whatsappBusinessPlatformConversationsFallInto: 'Conversațiile de pe Platforma de Afaceri WhatsApp se împart în două categorii care sunt taxate diferit',
    userInitiatedConversation: 'Conversație Inițiată de Utilizator',
    userInitiatedConversationDescription: 'O conversație care începe ca răspuns la un mesaj al utilizatorului. ' +
      'În fiecare dată când o afacere răspunde unui utilizator în cadrul ferestrei de servicii pentru clienți de 24 ' +
      'de ore, acel mesaj va fi asociat cu o conversație inițiată de utilizator. Afacerile pot trimite mesaje în formă liberă în această fereastră de servicii pentru clienți de 24 de ore.',
    businessInitiatedConversation: 'Conversație Inițiată de Afacere',
    businessInitiatedConversationDescription: 'O conversație care începe de la o afacere trimitând un mesaj unui utilizator în afara ' +
      'ferestrei de servicii pentru clienți de 24 de ore. Mesajele care inițiază o conversație inițiată de afacere vor necesita un șablon de mesaj.',
    areYouSureYouWantTo: 'Sigur doriți să treceți de la',
    conversations: ' Conversații ',
    plan: ' plan',
    toThe: ' la',
    metaPricingMarkup: 'Taxa Suplimentară de Preț Meta',
    numberOfChatFlows: 'Numărul de Fluxuri de Chat',
    greenTickApplication: 'Aplicație Cu Bifa Verde',
    extraPhoneNumberLinking: 'Link Suplimentar pentru Numărul de Telefon',
    teamMembers: 'Membrii Echipei',
    dataStorage: 'Stocare de Date',
    conditionalFlow: 'Flux Condiționat',
    liveChat: 'Chat Live',
    smartInbox: 'Caseta de Mesaje Inteligentă',
    whatsappTemplates: 'Șabloane WhatsApp',
    customerSegments: 'Grup de Clienți',
    broadcastAndDripCampaigns: 'Campanii de Difuzare și Picurare',
    multipleLanguages: 'Mai multe Limbi',
    bookAppointments: 'Rezervare Programări',
    csvContactsExport: 'Exportare Contacte CSV',
    googleCalendarIntegrations: 'Integrare Google Calendar',
    nativeApplications: 'Peste 60 de Integrări Native',
    whatsappSupport: 'Asistență WhatsApp',
    emailAndCallSupport: 'Asistență prin E-mail și Apel',
    customerIntegrations: 'Integrări de Clienți',
    nlpAndNluCapabilities: 'Capabilități NLP și NLU',
    chatGPTBot: 'Chatbot ChatGPT',
    customerSuccessOfficer: 'Ofițer de Succes al Clienților',
    noMarkupTransparency: 'Fără Markup Transparent',
    upto500kPerMonth: 'Până la 500.000 pe lună',
    beyond500k: 'Peste 500.000',
    unlimited: 'Nelimitat',
    oneTeamMember: '1 Membru al Echipei',
    unlimitedTeamMember: 'Membrii Echipei Nelimitați',
    nintyDaysOfDataStorage: 'Stocare Date 90 de Zile',
    unlimitedDataStorage: 'Stocare de Date Nelimitată',
    downgradeToFreePlan: 'Downgrade la Planul GRATUIT',
    detailedPricing: {
      onDemandAuditLog: 'Jurnal audit la cerere',
      accessRestrictionsBasedOnIPs: 'Restricții de acces bazate pe adrese IP',
      averageAgentResponeTime: 'Timp mediu de răspuns al agentului',
      perMonth100: '100 pe lună',
      solutionType: 'Tip soluție',
      saas: 'SaaS',
      saasOnPremises: 'SaaS pe locație',
      emperorPlan: 'Plan Împărat',
      babyPlan: 'Plan Baby',
      kingsplan: 'Plan Regal',
      braodcastsAndDrips: 'Transmisiuni și Picături',
      thirtyDays: '30 de zile',
      ninetyDays: '90 de zile',
      oneMember: '1 Membru',
      threeMembers: '3 membri',
      one: '1 (Unu)',
      unlimitedMessages: 'Mesaje nelimitate',
      unlimitedBots: 'Bots nelimitați',
      messagesSend: 'Mesaje trimise',
      upto500000perMonth: 'Până la 500,000 pe lună',
      unlimited: 'Nelimitat',
      onlyAdmin: 'Doar Administrator',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Fă-l singur',
      weAreHereToHelp: 'Suntem aici pentru a ajuta',
      weTakeCareEndToEnd: 'Ne ocupăm de tot procesul',
      numberOfBots: 'Număr de roboți',
      conversations: 'Conversații',
      gptMessages: 'Mesaje GPT',
      whatsappNumbers: 'Numere WhatsApp',
      subscribersLeads: 'Abonați/Leads',
      createSupportTeamForLiveChat: 'Creează echipa de suport pentru chat în direct',
      conversationHistory: 'Istoric conversații',
      chatbotDesigning: 'Design chatbot',
      multipleLanguages: 'Limbi multiple',
      conditionalFlow: 'Flux condiționat',
      whatsappTemplates: 'Șabloane WhatsApp',
      restartChatFlowTriggers: 'Declanșatoare pentru repornirea fluxului de chat',
      multipleWhatsAppWidgets: 'Widgeturi WhatsApp multiple',
      importWhatsappContacts: 'Importă contacte WhatsApp',
      whatsappQuickReply: 'Răspuns rapid WhatsApp',
      whatsappSmartInbox: 'Căsuță poștală inteligentă WhatsApp',
      whatsappEcommerceCatalogs: 'E-commerce WhatsApp cu cataloage',
      whatsappBroadcast: 'Transmisie WhatsApp',
      whatsappBroadcastAnalytics: 'Analize transmisie WhatsApp',
      dripCampaigns: 'Campanii picătură',
      customerSegmentation: 'Segmentarea clienților',
      keywordBasedResponse: 'Răspuns bazat pe cuvinte cheie',
      whatsappShopManagement: 'Gestionarea magazinului WhatsApp',
      aiChatbot: 'Chatbot AI',
      chatGPTIntegration: 'Integrare ChatGPT',
      configureAIChatbotPersonality: 'Configurare personalitate Chatbot AI',
      trainChatbotOnYourOwnData: 'Antrenează Chatbot pe datele tale',
      trainChatbotOnWebPages: 'Antrenează Chatbot pe paginile web',
      trainChatbotOnMultipleFiles: 'Antrenează Chatbot pe mai multe fișiere',
      trainChatbotOnFAQs: 'Antrenează Chatbot pe Întrebări frecvente',
      dataTrainingStorage: 'Stocare antrenament date',
      useChatGPTModel4or35Turbo: 'Folosește modelul ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Încarcă mai multe fișiere pentru antrenamentul Chatbot',
      naturalLanguageProcessing: 'Prelucrarea limbajului natural',
      naturalLanguageUnderstanding: 'Înțelegere limbaj natural',
      answerLength: 'Lungime răspuns',
      chatTone: 'Tonul conversației',
      multiLanguage: 'Multilimbă',
      answerFormatting: 'Formatare răspuns',
      customPrompt: 'Solicitare personalizată',
      whatsAppSessionManagement: 'Gestionare sesiune WhatsApp (mesaje de reactivare și întreruperi)',
      integrateBotPenguinLLM: 'Integrează BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrează LLM personalizat propriu',
      periodicallySyncDataSources: 'Sincronizare periodică surse de date',
      aiBasedSuggestedQuestions: 'Întrebări sugerate pe baza AI',
      chooseVisibilityForParticularWebpageSitemap: 'Alege vizibilitatea pentru o anumită pagină web/hartă site',
      chooseKnowledgeMix: 'Alege amestec de cunoștințe: Baza ta de cunoștințe Cunoștințe ChatGPT "Cunoștințe ChatGPT + Baza ta de cunoștințe"',
      faqExtractionFromFileAndURL: 'Extragere Întrebări frecvente din fișier și URL',
      analyticsToImproveAI: 'Analize pentru îmbunătățirea AI',
      exportQuestionFlow: 'Exportă flux de întrebări',
      collectFeedbackOnChatbotResponses: 'Colectează feedback cu privire la răspunsurile chatbotului',
      smartAIFollowUpQuestions: 'Întrebări inteligente de urmărire bazate pe AI',
      trainOnYourNotionWorkspaceData: 'Antrenează pe datele spațiului tău Notion',
      trainOnYoutubeVideo: 'Antrenează pe video YouTube',
      trainOnCustomVideoUpload: 'Antrenează pe încărcare personalizată video',
      rssFeed: 'Flux RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'Predare către om',
      botHandover: 'Predare către bot',
      handoverBetweenAgents: 'Predare între agenți',
      emailAndMobileValidation: 'Validare email și mobil',
      mobileAppsForAgents: 'Aplicații mobile pentru agenți',
      appointmentBooking: 'Programare pentru întâlniri',
      roleBasedAccessControls: 'Control acces bazat pe roluri',
      ruleBasedChatRouting: 'Direcționare chat bazată pe reguli',
      liveVisitorsDetails: 'Detalii vizitatori în timp real',
      multipleSimultaneousChats: 'Mai multe conversații simultane',
      visitorStats: 'Statistici vizitatori',
      addContactsFromWhatsApp: 'Adaugă contacte din WhatsApp',
      applyFilterAndSortConversations: 'Aplică filtre și sortează conversațiile',
      seeChatLogs: 'Vezi jurnalele de chat',
      quickReplies: 'Răspunsuri rapide',
      unifiedCustomerProfile: 'Profil client unificat',
      assignNotesAndChatTags: 'Atribuie note și etichete de chat',
      exportLeadsInCSV: 'Exportă lead-uri în format CSV',
      tagContacts: 'Etichetează contactele',
      customAttributes: 'Atribute personalizate',
      markStatusOfConversations: 'Marchează starea conversațiilor',
      assignConversationToAgents: 'Atribuie conversații agenților',
      leadNotificationsByEmail: 'Notificări lead prin email',
      directCallsToLeadsWithTwilio: 'Apeluri directe către lead-uri cu Twilio',
      whatsAppTemplates: 'Șabloane WhatsApp',
      promotionalMessageFrequencyControl: 'Control frecvență mesaje promoționale',
      whatsAppWidget: 'Widget WhatsApp',
      whatsAppQR: 'Cod QR WhatsApp',
      whatsAppLink: 'Link WhatsApp',
      campaignScheduler: 'Planificator de campanii',
      customTriggers: 'Declanșatoare personalizate',
      customCampaigns: 'Campanii personalizate',
      repeatPurchaseReminders: 'Mementouri de cumpărare repetată',
      campaignOpenRateTracking: 'Urmărirea ratei de deschidere a campaniei',
      paymentCollectionOnWhatsApp: 'Colectarea plăților pe WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Tubulatură',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Închide',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Cupru',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Calendar Google',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistem de gestionare a tichetelor de suport',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Apeluri audio și video în chat (Twilio/Agora)',
      courierTracking: 'Urmărirea curierilor',
      emailIntegration: 'Integrare email',
      webhooks: 'Webhooks',
      schedulers: 'Planificatoare',
      paymentGateways: 'Gateway-uri de plată (Stripe și RazorPay)',
      enterpriseIntegrations: 'Integrări enterprise',
      customIntegration: 'Integrare personalizată',
      freeWhatsAppAPIApprovalAndSetup: 'Aprobare și configurare API WhatsApp gratuită',
      developerAPIsAccess: 'Acces la API-urile dezvoltatorului',
      customRateLimit: 'Limită de rată personalizată',
      totalNumberOfLeads: 'Număr total de lead-uri',
      averageAgentResponseTime: 'Timpul mediu de răspuns al agentului',
      visitorTimingReport: 'Raport de sincronizare a vizitatorilor',
      customerSegmentBasedReports: 'Rapoarte bazate pe segmentarea clienților',
      tagBasedReports: 'Rapoarte bazate pe etichete',
      leadGenerationReport: 'Raport de generare a lead-urilor',
      supportOnWhatsApp: 'Suport pe WhatsApp',
      knowledgeBase: 'Bază de cunoștințe',
      onboardingAndTraining: 'Onboarding și pregătire',
      callAndEmailSupport: 'Suport telefonic și prin email',
      freeChatbotCreation: 'Creare chatbot gratuit',
      customerSuccessOfficer: 'Ofițer de succes al clienților',
      secureConnection: 'Conexiune securizată SSL de 2048 de biți',
      securityAssessment: 'Evaluare a securității',
      dataCenterLocation: 'Locația centrului de date (SUA sau UE)',
      twoStepVerification: 'Verificare în două etape',
      accessRestrictions: 'Restricții de acces',
      auditLog: 'Jurnal de audit la cerere',
      bannedVisitorsList: 'Listă de vizitatori interziși',
      customAPIRate: 'Rată personalizată API',
      trustedDomains: 'Domenii de încredere',
      elementary: 'Elementar',
      bots: 'Roboți',
      unlockWhatsapp: 'Deblochează WhatsApp',
      aiChatbotFeatures: 'Caracteristici AI Chatbot',
      dataSourceForTraining: 'Sursă de date pentru antrenament',
      smartFeatures: 'Caracteristici inteligente',
      liveChat: 'Chat în direct',
      smartInbox: 'Cutie poștală inteligentă',
      broadcastsAndDrips: 'Transmisii și picături',
      integrations: 'Integrări',
      development: 'Dezvoltare',
      reportsAndAnalytics: 'Rapoarte și analize',
      customerSupport: 'Asistență pentru clienți',
      security: 'Securitate',
      whatsappWidgets: 'Widgeturi WhatsApp',
      campaignSchedular: 'Planificator de campanii',
      repeatPurchaseRemainder: 'Memento de cumpărare repetată',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Planul nostru gratuit',
      TOOLTIP_KING_PLAN: 'Planul nostru cel mai popular',
      TOOLTIP_EMPEROR_PLAN: 'Plan personalizat pentru mesaje nelimitate, date nelimitate, membri echipă nelimitați',
      TOOLTIP_CONTACT_US: 'Contactează-ne prin WhatsApp sau e-mail la contact@botpenguin.com pentru a afla mai multe despre planul nostru personalizat',
      TOOLTIP_UPGRADE: 'Upgradează-ți planul actual pentru caracteristici suplimentare',
      TOOLTIP_MONTHLY: 'Selectează un ciclu de plată lunar',
      TOOLTIP_YEARLY: 'Selectează un ciclu de plată anual pentru beneficii de reducere',
    }
  }
};
