export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'البحث'
    }
  },
  bpTable: {
    loadMore: 'تحميل المزيد',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'بحث البلد'
    }
  },
  createNew: {
    continue: 'متابعة',
  },
  datePicker: {
    chooseADate: 'اختر تاريخًا',
  },
  dateRangePicker: {
    choosePeriod: 'اختر فترة',
    today: 'اليوم',
    lastWeek: 'الأسبوع الماضي',
    lastMonth: 'الشهر الماضي'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'الرجاء تقديم الرقم الذي ترغب في استخدامه للفحص',
    resendOtp: 'إعادة إرسال رمز التحقق',
    enterOtp: 'أدخل رمز التحقق',
    verifyOtp: 'تحقق من رمز التحقق',
    sendOtp: 'إرسال رمز التحقق',
    cancel: 'إلغاء',
    placeholders: {
      phone: 'الهاتف*',
      enterOtp: 'أدخل رمز التحقق*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'الرجاء تحديد سببك',
    cancel: 'إلغاء',
    submit: 'إرسال!',
    giveFeedback: 'أعطي ملاحظات',
    ratings: 'التقييمات',
    reasonBehindYourDecision: 'السبب وراء قرارك',
    shareYourExperience: 'شارك تجربتك',
    reason1: 'السبب 1',
    reason2: 'السبب 2'
  },
  feedbackModal: {
    paymentIsInProgress: 'جاري الدفع',
    pleaseDoNotCloseThisWindow: 'الرجاء عدم إغلاق هذه النافذة',
    placeholders: {
      name: 'الاسم',
      email: 'البريد الإلكتروني*',
      phone: 'الهاتف*',
      pleaseShareYourUseCase: 'الرجاء مشاركة حالتك الاستخدامية'
    }
  },
  layout: {
    sidebar: {
      notifications: 'الإشعارات',
      myAccount: 'حسابي',
      logout: 'تسجيل الخروج',
      goOnline: 'الذهاب على الإنترنت',
      manage: 'إدارة',
      deactivate: 'إلغاء تفعيل',
      activate: 'تفعيل',
      delete: 'حذف',
      home: 'الصفحة الرئيسية',
      bots: 'الروبوتات',
      inbox: 'الوارد',
      analytics: 'التحليلات',
      team: 'الفريق',
      developer: 'مطور',
      subscriptions: 'الاشتراكات',
      more: 'المزيد',
      users: 'المستخدمين',
      partnerTeam: 'فريق الشريك',
      createNewBot: 'إنشاء روبوت جديد',
      dashboard: 'لوحة القيادة',
      customers: 'العملاء',
      plans: 'الخطط',
      activities: 'الأنشطة',
      feedbacks: 'الملاحظات',
      configurations: 'التكوينات',
      copy: 'نسخ',
      yourFriendWillMissYou: 'سيفتقدك صديقك',
      rateYourBotCreationExperience: 'قيم تجربة إنشاء الروبوت الخاصة بك',
      whatMadeYouDeleteYourBot: 'ما الذي دفعك إلى حذف الروبوت الخاص بك؟',
      iCreatedByMistake: 'أنشأته بالخطأ',
      iWantToCreateAnotherBot: 'أريد إنشاء روبوت آخر',
      couldNotFindFeaturesThatINeeded: 'لم أتمكن من العثور على الميزات التي أحتاجها',
      uiWasHardToNavigate: 'كانت واجهة المستخدم صعبة التصفح',
      iDonotWantToTell: 'لا أرغب في القول',
      other: 'آخر',
      weWillDoOurBestToMakeAutomationFun: 'سنبذل قصارى جهدنا لجعل الأتمتة ممتعة وبسيطة للجميع.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'تبديل للسماح أو حظر طلبات الدردشة الحية',
        TOOLTIP_NOTIFICATION: 'جميع الإشعارات',
        TOOLTIP_MY_ACCOUNT: 'الملف الشخصي وأمان كلمة المرور الخاص بك',
        TOOLTIP_LOGOUT: 'تسجيل الخروج من لوحة المعلومات'
      },
      modal: {
        logout: 'تسجيل الخروج',
        botDeleteFeedbackForm: 'نموذج ملاحظات حذف الروبوت',
        areYouSureToLogOut: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
        yesIWillBeBack: `نعم! سأعود`,
        deleteBot: 'حذف الروبوت',
        areYouSureToDeleteBot: 'هل أنت متأكد أنك تريد حذف هذا الروبوت؟',
        cancel: 'إلغاء',
        saveAndDelete: 'حفظ وحذف'
      }
    }
  },
  modal: {
    cancel: 'إلغاء',
    yesDelete: 'نعم، حذف!',
    continue: 'متابعة'
  },
  unsavedChanges: {
    title: 'تغييرات غير محفوظة',
    description: 'قد تكون هناك تغييرات غير محفوظة. هل أنت متأكد أنك تريد المغادرة؟'
  },
  notify: {
    youHave: 'لديك ',
    new: ' جديد '
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'نقترح عليك اختيار شعار شركتك. قم بإضافة صورة PNG أو JPG مربعة تحت 1 ميجابايت.'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'عذرًا! تقدم خطة Baby فقط روبوت واحد مجاني فقط. يرجى الترقية إلى خطة King لإنشاء المزيد من الروبوتات.',
    noIAmGood: 'لا، أنا بخير',
    iWantToUpgrade: 'أريد الترقية',
    oops: 'عذرًا! ',
    planOfferOnlyOneFreeBot: 'يقدم BOT واحدًا فقط لكل خطة.',
    planToCreateMoreBots: ' لإنشاء المزيد من الروبوتات.'
  },
  webPreview: {
    preview: ' معاينة',
    botsMessages: `رسائل الروبوت`,
    bot: 'روبوت ',
    eleven36AM: ' 11:36 صباحًا',
    usersReply: `رد المستخدم`,
    landingPageBot: 'روبوت صفحة الهبوط',
    chatbot: 'روبوت المحادثة',
    chat: 'محادثة',
    placeholders: {
      typeYourAnswer: 'اكتب إجابتك'
    }
  },
  paymentLink: {
    discardPayment: 'تجاهل الدفع',
    completePayment: 'اكمال الدفع',
    importantUpdate: 'تحديث هام',
    accountUpdate: 'تحديث الحساب',
    youHave: 'لديك',
    exceededTheMessagesLimit: 'تجاوزت حدود الرسائل',
    ofYour: 'من خطتك',
    planAnd: 'وخطتك',
    botsAreNoLongerActive: 'الروبوتات لم تعد نشطة',
    onYourWebsite: 'على موقع الويب الخاص بك.',
    youHaveEitherIncompletePaymentOn: 'لديك إما دفع غير مكتمل على',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'أو أنك لم تقم بترقية خطتك بنجاح.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'أو أنك لم تقم بتسمية روبوتك بشكل ناجح.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'أو أنك لم تقم بشراء "ملحق الرسائل" بنجاح.',
    incompletePaymentMessage: 'لديك إما دفع غير مكتمل على خطتك أو أنك لم تقم بترقية خطتك بنجاح.',
    please: 'الرجاء',
    complete: 'اكمال',
    upgrade: 'الترقية',
    services: 'الخدمات',
    yourPlanToContinueServices: 'خطتك لمواصلة الخدمات',
    yourPaymentToContinue: 'دفعتك لمواصلة',
    forAnyQueryPleaseWriteUs: 'لأي استفسار يرجى كتابتنا على',
    close: 'إغلاق',
    writeOn: '',
    upgradePlan: 'ترقية الخطة',
    discardModal: {
      title: 'تجاهل الدفع',
      description: 'هل أنت متأكد أنك تريد تجاهل الدفع؟',
      confirmText: 'نعم، تجاهل الدفع'
    }
  },
  bpExport: {
    exportStatus: 'حالة التصدير',
    tasksExport: 'تصدير المهام',
    download: 'تحميل',
    inProgress: 'جاري التنفيذ',
  }
};
