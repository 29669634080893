const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'حسابي',
  yourProfileAndPasswordSecurity: 'ملف التعريف الخاص بك وأمان كلمة المرور',
  detailsHeader: 'التفاصيل',
  passwordHeader: 'كلمة المرور',
  customAttributeHeader: 'سمات مخصصة',
  tagsHeader: 'العلامات',
  accessTokenHeader: 'رمز الوصول',
  thirdPartyIntegrationsHeader: 'التكامل مع الأطراف الثالثة',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'تحديث ملف تعريف حسابك',
    TOOLTIP_PASSWORD_HEADING: 'تغيير كلمة المرور الخاصة بك',
    TOOLTIP_TAGS_HEADING: 'إدارة علاماتك',
    TOOLTIP_THIRD_PARTY_HEADING: 'التكامل مع الأطراف الثالثة',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `تساعد كلمة المرور الآمنة في حماية حسابك ${brand}`,
    TOOLTIP_INTEGRATIONS: 'التكامل',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'إضافة أو تحديث السمات المخصصة',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'إدارة السمات المخصصة الخاصة بك'
  },
  accessToken: {
    accessToken: 'رمز الوصول',
    generateYourAccessTokenForUsingPlatformAPIs: 'قم بإنشاء رمز الوصول الخاص بك لاستخدام واجهات برمجة التطبيقات للمنصة',
    generateApiToken: 'إنشاء رمز API',
    revokeApiToken: 'إلغاء رمز API',
    regenerateApiToken: 'إعادة إنشاء رمز API',
    placeholders: {
      yourApiKey: 'مفتاح API الخاص بك',
    }
  },
  details: {
    name: 'الاسم',
    emailId: 'عنوان البريد الإلكتروني',
    yourPlan: 'خطتك',
    messagesPerMonth: 'رسائل/شهر',
    daysOfStoragePlan: 'أيام من خطة التخزين',
    conditionalFeatures: 'ميزات مشروطة',
    upgradeToEnterprisePlanToGetMore: 'قم بالترقية إلى خطة الشركة للحصول على المزيد',
    featuresAndStorageCapacity: 'سعة التخزين والميزات',
    upgradePlan: 'ترقية الخطة',
    submit: 'إرسال',
    selectLanguage: 'اختر اللغة',
    baby: 'Baby',
    king: 'King',
    conversationsperMonth: 'محادثات/شهر',
    english: 'الإنجليزية',
    hindi: 'الهندية',
    german: 'الألمانية',
    french: 'الفرنسية',
    portuguese: 'البرتغالية',
    spanish: 'الإسبانية',
    italian: 'الإيطالية',
    russian: 'الروسية',
    chatBotBrandName: 'اسم العلامة التجارية للدردشة',
    placeholders: {
      john: 'جون',
      email: 'البريد الإلكتروني',
      searchLanguage: 'ابحث عن اللغة',
      chatBotBrandName: 'اسم العلامة التجارية للدردشة'
    },
    appTooltip: {
      TOOLTIP_NAME: 'أدخل اسم ملف التعريف الخاص بك',
      TOOLTIP_EMAIL_ADDRESS: 'أدخل عنوان بريدك الإلكتروني',
    }
  },
  customAttribute: {
    addAttribute: 'إضافة سمة',
    discard: 'تجاهل',
    customAttribute: 'سمات مخصصة',
    checkOrAddCustomAttribute: 'هنا يمكنك التحقق وإضافة سماتك المخصصة.',
    attributeKey: 'مفتاح السمة',
    attributeName: 'اسم السمة',
    bot: 'روبوت',
    flow: 'تدفق',
    loadMore: 'تحميل المزيد',
    question: 'سؤال',
    action: 'إجراء',
    search: 'بحث',
    selectBot: 'اختر الروبوت',
    selectFlow: 'اختر التدفق',
    selectQuestion: 'اختر السؤال',
    noDataFoundHeading: 'ليس لديك أي سمة تم إنشاؤها بعد',
    noDataFoundSubheading: 'سيظهر جميع سماتك هنا',
    deleteTitle: 'حذف السمة',
    deleteDescription: 'هل أنت متأكد أنك تريد حذف هذه السمة؟',
    deleteAttributes: 'حذف السمات',
    areYouSureYouWantToDeleteThisAttributes: ' هل أنت متأكد من رغبتك في حذف هذه السمات',
    editAttribute: 'تحرير السمة',
    headsUp: 'تنبيه',
    okay: 'موافق',
    dataAddedToLead: 'لن يتم تحديث السمات المعينة لواجهات برمجة التطبيقات (APIs) أو تدفقات البوت. يرجى تحديث واجهات برمجة التطبيقات (APIs) أو تدفقات البوت وفقًا لذلك.',
    placeholders: {
      enterAttributeKey: 'أدخل مفتاح السمة',
      enterAttributeName: 'أدخل اسم السمة',
      selectBot: 'اختر الروبوت',
      selectFlow: 'اختر التدفق',
      selectQuestion: 'اختر السؤال',
      search: 'البحث حسب اسم السمة'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'أدخل مفتاح السمة',
      TOOLTIP_ATTRIBUTE_NAME: 'أدخل اسم السمة',
      TOOLTIP_SELECT_BOT: 'اختر الروبوت',
      TOOLTIP_SELECT_FLOW: 'اختر التدفق',
      TOOLTIP_SELECT_QUESTION: 'اختر السؤال',
    }
  },
  password: {
    changePassword: 'تغيير كلمة المرور',
    aSecurePasswordHelpsProtectYour: 'تساعد كلمة المرور الآمنة في حماية حسابك ',
    account: 'حساب',
    currentPassword: 'كلمة المرور الحالية',
    newPassword: 'كلمة المرور الجديدة',
    confirmPassword: 'تأكيد كلمة المرور',
    cancel: 'إلغاء',
    updatePassword: 'تحديث كلمة المرور',
    deleteAccount: 'حذف الحساب',
    placeholders: {
      enterYourCurrentPassword: 'أدخل كلمة المرور الحالية',
      enterYourNewPassword: 'أدخل كلمة المرور الجديدة',
      enterYourConfirmPassword: 'أدخل تأكيد كلمة المرور الجديدة',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'أدخل كلمة المرور القديمة',
      TOOLTIP_NEW_PASSWORD: 'أدخل كلمة مرور جديدة',
      TOOLTIP_CONFIRM_PASSWORD: 'قم بتأكيد كلمة المرور الجديدة',
      TOOLTIP_DELETE_ACCOUNT: `حذف حسابك الحالي ${brand}`,
    }
  },
  tags: {
    manageTags: 'إدارة العلامات',
    createNewTags: 'إنشاء علامات جديدة',
    createNewTagsSubText: 'قم بإدارة علاماتك هنا لاستخدامها في تنظيم العملاء، المحادثات، المجموعات، إلخ بشكل أفضل',
    tagName: 'اسم العلامة',
    updateTag: 'تحديث العلامة',
    createTag: 'إنشاء علامة',
    discard: 'تجاهل',
    searchTag: 'البحث عن علامة بالاسم',
    noTagFound: 'لم يتم العثور على علامة!',
    createATag: 'إنشاء علامة',
    deleteTagModalSubText: 'هل أنت متأكد أنك تريد حذف هذه العلامة؟ سيؤدي حذف هذه العلامة إلى إزالتها من المستخدمين المعينين أيضًا!',
    deleteTagModalHeading: 'حذف العلامات',
    deleteOneTagModalHeading: 'حذف العلامة',
    actions: 'الإجراءات',
    placeholders: {
      enterTag: 'أدخل اسم العلامة',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'حذف العلامات',
    }
  },
  thirdPartyIntegration: {
    list: {
      name: 'الاسم',
      status: 'الحالة',
      action: 'الإجراء',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird لإرسال الرسائل والبريد الإلكتروني',
      notConnected: 'غير متصل',
      connected: 'متصل',
      edit: 'تعديل',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo لإرسال الرسائل',
      connect: 'اتصال',
      appTooltip: {
        nameOfAppOrCrm: 'اسم التطبيق/نظام إدارة العلاقات',
        statusAsConnectedOrNotConnected: 'الحالة كما هو متصل / غير متصل',
        clickToConnectAppOrCrm: 'انقر لتوصيل App/CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'عذرًا! التكامل غير متاح في خطة Baby. يُرجى الترقية إلى خطة King للتكامل.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'messageBird',
        integrateWithMessageBird: 'التكامل مع MessageBird',
        apiKey: 'مفتاح API',
        originator: 'المُنشئ',
        connect: 'اتصال',
        disconnect: 'قطع الاتصال',
        seeActivities: 'عرض الأنشطة',
        sms: 'SMS',
        time: 'الزمن',
        phoneNumber: 'رقم الهاتف',
        status: 'الحالة',
        botName: 'اسم الروبوت',
        message: 'الرسالة',
        error: 'خطأ',
        placeholders: {
          apiKey: 'مفتاح API',
          messageBirdPhoneNumber: 'رقم الهاتف لدى MessageBird',
        },
        noDataFound: {
          activities: 'الأنشطة',
          noActivitiesFound: 'لم يتم العثور على أنشطة!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'التكامل مع Plivo',
        authId: 'معرف المصادقة',
        authToken: 'رمز المصادقة',
        yourPlivoPhoneNumber: 'رقم هاتف Plivo الخاص بك',
        connect: 'اتصال',
        disconnect: 'قطع الاتصال',
        seeActivities: 'عرض الأنشطة',
        sms: 'SMS',
        time: 'الزمن',
        phoneNumber: 'رقم الهاتف',
        status: 'الحالة',
        botName: 'اسم الروبوت',
        message: 'الرسالة',
        error: 'خطأ',
        noDataFound: {
          activities: 'الأنشطة',
          noActivitiesFound: 'لم يتم العثور على أنشطة!',
        },
        placeholders: {
          authId: 'معرف المصادقة',
          authToken: 'رمز المصادقة',
          yourPlivoPhoneNumber: 'رقم هاتف Plivo الخاص بك'
        }
      }
    }
  }
};
