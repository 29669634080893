const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Hello, ',
  greetingHand: '👋',
  upgrade: 'Upgrade',
  integrate: 'Integrate',
  integrate2: 'Integrate',
  chatGPT: 'ChatGPT ',
  forYourBusiness: 'for your business',
  insights: 'Insights',
  message: 'Messages',
  addon: 'Add-on: ',
  leads: 'Leads',
  yourBots: 'Your Bots',
  botName: 'Bot Name',
  platform: 'Platform',
  contactOrLeads: 'Contacts/Leads',
  actions: 'Actions',
  edit: 'edit',
  delete: 'delete',
  createA: 'Create a ',
  whatsappChatbot: 'WhatsApp Chatbot ',
  connect: 'Connect',
  getStartedWithUs: 'Get started with us 👏',
  howToBuildAGoodChatFlow: ' How to build a good chatflow',
  howToUseBotPenguinInboxAndItsFeatures: ` How to use BotPenguin's Inbox and its features`,
  botPenguinPricing: ` BotPenguin's Pricing: Exploring Plans and Features`,
  openHelpDesk: ' Open Help desk',
  helpAndSupport: 'Help and support',
  extensiveGuides: 'Extensive guides for all your queries and workflows are available in our',
  resourceCentre: 'Resource Centre.',
  checkoutOurAnswers: 'You can check out our answers to the common queries below.',
  createANewBot: 'Create a new bot',
  installYourBot: 'Install your bot',
  plansAndPricing: 'Plans and Pricing',
  searchLanguage: 'Search Language',
  whatsappConversations: 'WhatsApp Conversations',
  diveDeeperIntoBotPenguin: 'Dive Deeper into BotPenguin',
  knowWhereWeAreGoing: 'Know where we are going, the recent updates, and help us fix some bugs.',
  productUpdates: 'Product Updates',
  requestAFeature: 'Request a feature',
  reportABug: 'Report a bug',
  productRoadmap: 'Product Roadmap',
  contactsOrLeads: 'Contacts/Leads',
  english: 'English',
  hindi: 'Hindi',
  german: 'German',
  french: 'French',
  portuguese: 'Portugese',
  spanish: 'Spanish',
  italian: 'Italian',
  russian: 'Russian',
  pleaseSelectYourReason: 'Please select your reason',
  cancel: 'Cancel',
  submit: 'Submit!',
  giveFeedback: 'Give feedback',
  ratings: 'Ratings',
  reasonBehindYourDecision: 'Reason behind your decision',
  shareYourExperience: 'Share your experience',
  reason1: 'reason1',
  reason2: 'reason2',
  appTooltip: {
    HOME_INSIGHT: 'Provide insight into all of your bots\' messages and leads (Data updated every midnight)',
    HOME_MESSAGES: 'Messages consumed by your account during this billing cycle. (Messages from WhatsApp, Facebook, and Telegram Bot are excluded.)',
    HOME_TOTAL_MESSAGES: 'Total messages consumed by your account since account creation (Messages from WhatsApp, Facebook, and Telegram Bot are excluded.)',
    LEADS: 'Leads captured by bots for your account during this billing cycle. ',
    TOTAL_LEADS: 'Total contacts/leads captured by bots for your account since account creation (Data updated every midnight).',
    YOUR_BOT: 'Overview of all your bots and their usage during this billing cycle.',
    NAME: 'Bot\'s name',
    PLATFORM: 'Bot\'s platform',
    MESSAGES: 'Bot\'s total messages since account creation',
    CONTACTS: 'Bot\'s total contacts/leads',
    ACTIONS: 'Edit/Delete the bot',
    EDIT_ICON: 'Edit the bot chat flow and settings',
    DELETE_ICON: 'Delete the bot',
    TOOL_TIP_CLASSIC_VERSION: `old version of ${brand} UI`
  },
  modal: {
    deleteBot: 'Delete Bot!',
    areYouSure: 'Are you sure you want to delete this Bot?',
    enquiryForm: 'Enquiry Form',
    payNow: 'Pay Now!',
    saveAndDelete: 'Save and Delete',
    submit: 'Submit',
  },
  noDataFound: {
    bots: 'Bots',
    noBotCreatedYet: 'No bots created yet!',
  },
  yourFriendWillMissYou: 'Your friend will miss you',
  rateYourBotCreationExperience: 'Rate your bot creation experience',
  whatMadeYouDeleteYourBot: 'What made you delete your bot?',
  iCreatedByMistake: 'I created by mistake',
  iWantToCreateAnotherBot: 'I want to create another Bot',
  couldNotFindFeaturesThatINeeded: 'Couldn\'t find features that I need',
  uiWasHardToNavigate: 'UI was hard to navigate',
  iDonotWantToTell: 'I don\'t want to tell',
  other: 'Other',
  weWillDoOurBestToMakeAutomationFun: 'We will do our best to make automation fun and simple for everyone.',
};
