import { NgModule } from '@angular/core';

import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationComponent } from './notification.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { ChatRequestsListComponent } from './components/chat-requests-list/chat-requests-list.component';
import { NewChatRequestComponent } from './components/new-chat-request/new-chat-request.component';
import { NotificationSettingComponent } from './components/notification-setting/notification-setting.component';

@NgModule({
  declarations: [
    NotificationComponent, NotificationListComponent, ChatRequestsListComponent,
    NewChatRequestComponent, NotificationSettingComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NotificationRoutingModule
  ],
  exports: [
    ChatRequestsListComponent,
    NewChatRequestComponent
  ]
})
export class NotificationModule { }
