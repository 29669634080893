export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: '¡Bienvenido a Botpenguin!',
      login: 'Acceso',
      youAreAllSet: '¡Estas listo! Puede usar la bandeja de entrada de WhatsApp desde la página Detalles del líder.',
      placeholders: {
        enterEmail: 'Ingrese correo electrónico',
        enterPassword: 'Introducir la contraseña',
      }
    }
  }
};
