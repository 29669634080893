import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/appv2/services/language.service';
import { SocketService } from 'src/appv2/services/socket.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit, OnDestroy {
  staticText: any;
  timer: any;
  hide = false;
  newNotifcationSubscriber$: Subscription;
  notification = {
    count: 0,
    userName: '',
    usersCount: 1,
    lastMessage: '',
    uniqueUsers: []
  };

  constructor(
    private socketService: SocketService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.notify;
    this.newNotifcationSubscriber$ = socketService.newNotification.subscribe(
      (data: { name: string, message: string, _customer: string, _user: string }) => {
        if (data) {

          this.hide = false;
          this.notification = data as any;

          if (this.timer) {
            clearTimeout(this.timer);
          }

          this.timer = setTimeout(() => {
            this.hide = true;
          }, 2500);
        }
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.newNotifcationSubscriber$.unsubscribe();
  }

  reset() {
    this.hide = true;
  }

  get isNotificationViaMultiple(): boolean {
    return this.notification.usersCount > 1;
  }

  get shouldHide(): boolean {
    return window.location.pathname.includes('inbox-merged') || this.hide;
  }
}
