import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-index-merge-date-picker',
  templateUrl: './index-merge-date-picker.component.html',
  styleUrls: ['./index-merge-date-picker.component.scss']
})
export class IndexMergeDatePickerComponent implements OnInit {

  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() selectedDate: Date;
  @Input() label: string;

  @Output() getDate = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  emitDate(event) {
    this.selectedDate = event.target.value;
    this.getDate.emit(event.target.value.toUTCString());
  }

  sanitizeDate(date: Date | string) {
    if (typeof date === 'string') {
      return date
        ? new Date(date)
          .toLocaleDateString()
        : '';
    }

    return date
      ? date.toLocaleDateString()
      : '';
  }

  getMaxDate(): Date | string {
    return this.maxDate
      ? new Date(this.maxDate)
      : '';
  }

  getMinDate(): Date | string {
    return this.minDate
      ? new Date(this.minDate)
      : '';
  }

  getSelectedDate(): Date | string {
    return this.selectedDate
      ? new Date(this.selectedDate)
      : '';
  }
}
