import {Action, createReducer, on} from '@ngrx/store';
import Broadcast from '../../models/broadcast';
import {addSocialBroadcast, loadSocialBroadcasts} from '../actions/social-broadcast.actions';

export const broadcastFeatureKey = 'broadcast';

export const initialState: Broadcast[] = [];

const socialBroadcastReducer = createReducer(
  initialState,
  on(loadSocialBroadcasts, (state, {broadcasts}) => [...broadcasts]),
  on(addSocialBroadcast, (state, {broadcast}) => [...state, broadcast])
);

export function reducer(state: Broadcast[], action: Action) {
  return socialBroadcastReducer(state, action);
}
