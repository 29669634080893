export const developer = {
  developers: 'Sviluppatori',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Dai ai tuoi sviluppatori la possibilità di sfruttare appieno il potenziale del tuo cruscotto',
  accessToken: 'Token di accesso',
  yourAPI: 'Le tue API',
  api: {
    apiManagement: 'Gestione API',
    apiManagementSubHeading: 'Aggiungi, configura, testa e gestisci le tue API che puoi attivare nei tuoi flussi di chat.',
    addApi: 'Aggiungi API',
    createApi: 'Crea una API',
    noApiFound: 'Nessuna API trovata!',
    deleteApis: 'Elimina API',
    areYouSureYouWantToDeleteAPI: 'Sei sicuro di voler eliminare le API?',
    placeholders: {
      search: 'Cerca per nome API'
    },
    tableHead: {
      method: 'Metodo',
      apiName: 'Nome API',
      url: 'URL',
      actions: 'Azioni'
    },
    parametersTab: 'Parametri',
    headersTab: 'Intestazioni',
    bodyTab: 'Corpo',
    manageApiResponses: 'Gestisci le risposte API',
    chooseVariableToSave: 'Scegli la variabile da salvare',
    selectCustomAttribute: 'Seleziona attributo personalizzato',
    subTab: {
      key: 'Chiave',
      enterKey: 'Inserisci Chiave',
      value: 'Valore',
      enterValue: 'Inserisci Valore',
      addAnotherField: 'Aggiungi un altro campo',
      save: 'Salva',
      useslashToInsertCustomVariables: `Usa '/' per inserire variabili personalizzate o valori dinamici dalla risposta dell'utente.`,
      response: 'Risposta',
      apiRequestBody: 'Corpo della richiesta API',
      hit: 'Successo',
      test: 'Test',
      buttonToGetAResponse: 'Pulsante per ottenere una risposta',
      variable: `Variabile`,
      saveToCustomAttribute: `Salva nell'Attributo Personalizzato`
    },
    apiLogs: {
      apiManagement: 'Gestione API',
      addConfigureTestAndManageYourAPIs: 'Aggiungi, configura, testa e gestisci le tue API che puoi attivare nei tuoi flussi di chat.',
      searchHere: 'Ricerca per codice di stato',
      deleteApis: 'Elimina API',
      testAnApi: 'Testa una API',
      noAPILogFound: 'Nessun log API trovato',
      responseCode: 'Codice di risposta',
      message: 'Messaggio',
      apiResponse: 'Risposta API',
      actions: 'Azioni',
      deleteAPIs: 'Elimina API',
      areYouSureYouWantToDelete: 'Sei sicuro di voler eliminare questa API?',
      apiLogs: `Registri API`,
      subTitle: `Registrazione dettagliata di tutte le richieste e risposte API generate all'interno del flusso di chat del chatbot.`
    }
  },
  configApi: {
    configureApi: 'Configura API',
    configureApiSubHeading: 'Puoi configurare la tua API basata su JSON qui e usarla per arricchire il tuo flusso di chat.',
    apiLogs: 'Log API',
    test: 'Test',
    manageApiResponse: 'Gestisci le risposte API',
    body: 'Corpo',
    headers: 'Intestazioni',
    parameters: 'Parametri',
    placeholders: {
      enterApiName: `Inserisci il nome dell'API`,
      enterApiUrl: `Inserisci l'URL dell'API`
    }
  }

};
