export const analytics = {
  botWise: {
    botWiseAnalytics: 'Bot Tabanlı Analitikler',
    currentBillingCycle: 'Mevcut Fatura Dönemi',
    currentMonth: 'Mevcut Ay',
    sinceAccountCreation: 'Hesap Oluşturulduğundan Beri',
    messages: 'Mesajlar',
    leads: 'Potansiyel Müşteriler',
    noDataFound: {
      botwiseAnalytics: 'Bot Tabanlı Analitikler',
      noBotHasBeenCreatedYet: 'Henüz bir bot oluşturulmadı!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Mevcut fatura dönemi, mevcut ay veya hesap oluşturulduğundan beri belirli botlar için mesajlar ve potansiyel müşteriler verilerini özetleyin (Her gece güncellenir)',
      TOOLTIP_FILTERS: 'Seçilen bot için verileri filtreleyin',
      TOOLTIP_MESSAGES: 'Hesabınızın oluşturulduğu tarihten bu yana tüketilen toplam mesaj sayısı',
      TOOLTIP_LEADS: 'Hesabınız için botlar tarafından yakalanan toplam potansiyel müşteri sayısı.',
    },
  },
  overview: {
    analytics: 'Analitikler',
    seeHowToUse: 'Nasıl Kullanılacağını Görün',
    help: 'Yardım',
    overview: 'Genel Bakış',
    messages: 'Mesajlar',
    leads: 'Potansiyel Müşteriler',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Tüm botlarınızın mesajları ve potansiyel müşterileri için analitikler. (Her gece güncellenir)',
    },
  },
  summary: {
    analyticsSummary: 'Analitik Özet',
    allBots: 'Tüm Botlar',
    daily: 'Günlük',
    weekly: 'Haftalık',
    monthly: 'Aylık',
    messages: 'Mesajlar',
    leads: 'Potansiyel Müşteriler',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Belirli ve tüm botlar için günlük, haftalık veya aylık verileri özetleyin (Her gece güncellenir)',
    },
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp Analitikleri',
      month: 'Ay',
      week: 'Hafta',
      today: 'Bugün',
      filters: 'Filtreler',
      clearAll: 'Tümünü Temizle',
      apply: 'Uygula',
      totalConversations: 'Toplam Konuşmalar',
      freeConversations: 'Ücretsiz Konuşmalar',
      paidConversations: 'Ücretli Konuşmalar',
      messageSent: 'Gönderilen Mesajlar',
      messageDelivered: 'Teslim Edilen Mesajlar',
      bpDropDown: {
        selectBot: 'Bot Seç',
      },
      indexMergeDatePicker: {
        startDate: 'Başlangıç Tarihi',
        endDate: 'Bitiş tarihi',
      },
    },
    conversationAnalytics: {
      conversationAnalytics: 'Konuşma Analitikleri',
      month: 'Ay',
      week: 'Hafta',
      today: 'Bugün',
      totalConversations: 'Toplam Konuşmalar',
      paidConversations: 'Ücretli Konuşmalar',
      freeConversations: 'Ücretsiz Konuşmalar',
      indexMergeDatePicker: {
        startDate: 'Başlangıç Tarihi',
        endDate: 'Bitiş tarihi',
      },
      bpDropDown: {
        allBots: 'Tüm botlar',
      },
    },
    messageAnalytics: {
      whatsappMessages: 'WhatsApp Mesajları',
      sent: 'Gönderildi',
      delivered: 'Teslim Edildi',
      indexMergeDatePicker: {
        startDate: 'Başlangıç Tarihi',
        endDate: 'Bitiş tarihi',
      },
      bpDropDown: {
        allBots: 'Tüm botlar',
      },
      month: 'Ay',
      week: 'Hafta',
      today: 'Bugün',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'Duruma Göre WhatsApp Mesajları',
      sent: 'Gönderildi',
      delivered: 'Teslim Edildi',
      indexMergeDatePicker: {
        startDate: 'Başlangıç Tarihi',
        endDate: 'Bitiş tarihi',
      },
      bpDropDown: {
        allBots: 'Tüm botlar',
      },
      month: 'Ay',
      week: 'Hafta',
      today: 'Bugün',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Tüm Aboneler',
      whatsappSubscribers: 'WhatsApp Aboneleri',
    }
  }
};

