import { Component, OnDestroy, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { WebsiteBot } from 'src/appv2/models/bots/website-bot';
import { BotsService } from 'src/appv2/services/bots.service';
import { SharedService } from '../../shared.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { FacebookBot } from 'src/appv2/models/bots/facebook-bot';
import { TelegramBot } from 'src/appv2/models/bots/telegram-bots';
import { WhatsAppBot } from 'src/appv2/models/bots/whatsapp-bot';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { updateProfile } from 'src/appv2/ngrx/actions/profile.actions';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { State } from '../../../../ngrx/reducers/index';
import { addBot, deleteBot, deleteSocialBot, loadBots, moveBotToTop, updateBot } from 'src/appv2/ngrx/actions/bot.actions';
import { Subscription } from 'rxjs';
import { NetworkService } from 'src/appv2/services/network.service';
import { loadPlans } from 'src/appv2/ngrx/actions/subscription.actions';
import { LayoutService } from 'src/appv2/services/layout.service';
import { SubscriptionService } from 'src/appv2/services/subscription.service';
import { UserMasterSubscription, UserSubscription } from 'src/appv2/models/subscription';
import Profile from 'src/appv2/models/profile';
import { sidebarToolTipText } from 'src/appv2/tooltip';
import { environment } from 'src/environments/environment';
import { InboxService } from 'src/appv2/services/inbox.service';
import moment from 'moment';
import { InstagramBot } from 'src/appv2/models/bots/instagram-bot';
import { WhitelabelService } from 'src/appv2/services/whitelabel.service';
import { loadPendingConfigurations } from 'src/appv2/ngrx/actions/pending-configurations.action';
import { RedirectHandlerService } from 'src/appv2/services/redirect-handler.service';
import { CommonService } from 'src/appv2/services/common.service';
import { LanguageService } from 'src/appv2/services/language.service';
import { loadWhatsAppPlans } from 'src/appv2/ngrx/actions/whatsapp-subscription.action';
import { SocketService } from 'src/appv2/services/socket.service';
declare var window: any;
declare let $: any;


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition('void => yes', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition('void => no', [
        style({ opacity: 0 }),
        animate('0ms', style({ opacity: 1 })),
      ])
    ]),
  ],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  staticText: any;
  selectedId = '';
  selectedBot: any;
  openDeleteModal = false;
  activeBot = '';
  timerOne: any;
  timerTwo: any;
  tokens: any = {};
  botType: string;
  isOnline = false;
  actionIndex = -1;
  currentPage = 1;
  profile: Profile;
  currentBotPage = 1;
  threeDotMenu = false;
  agencyToken = false;
  apiCalled = false;
  logoutModal = false;
  profileType: string;
  agencyLogo: string;
  agencyId: string;
  dropdownIcon = true;
  collapseLabel = false;
  isSmallDevice = false;
  sidebar$: Subscription;
  isDarkMode$: Subscription;
  unsavedWarning$: Subscription;
  showCloseButton = false;
  agencyPermission: string;
  isSideBarCollapsed = false;
  openDeleteUpgradeModal = false;
  toolTip = sidebarToolTipText;
  menuSubscription$: Subscription;
  storeSubscription$: Subscription;
  popupSubscription$: Subscription;
  animationSubscription$: Subscription;
  createBotText: string;
  shouldAnimate: 'yes' | 'no' = 'yes';
  paymentGateway: 'RAZORPAY' | 'STRIPE';
  bottomSidebar: 'closed' | 'open' = 'closed';
  bots: (WebsiteBot | FacebookBot | TelegramBot | WhatsAppBot | InstagramBot)[] = [];
  editOption: { xPosition: string, yPosition: string } = { xPosition: '-100px', yPosition: '0' };
  activeMenu: 'home'
    | 'bot'
    | 'inbox'
    | 'analytics'
    | 'team'
    | 'subscription'
    | 'accessControl'
    | 'notification'
    | 'settings'
    | 'developer'
    | 'logout'
    | 'agencyDashboard'
    | 'customers'
    | 'more'
    | 'agencyActivities'
    | 'configurations'
    | 'feedbacks' = 'home';
  menus = [];
  agencyDetailsInLocal: any;
  customerField = [];
  timeoutId: NodeJS.Timeout;
  brandName: string;
  sidebarToggleSubscription$: Subscription;
  plansList = [];
  feedback = {
    hasOne: false,
    openBotDelete: false,
    headingOne: 'Your friend will miss you',
    headingTwo: 'Rate your bot creation experience',
    deleteReasonHeading: 'What made you delete your bot?',
    botDeletionReasons: [
      { title: 'I created by mistake' },
      { title: 'I want to create another Bot' },
      { title: 'Couldn\'t find features that I need' },
      { title: 'UI was hard to navigate' },
      { title: 'I don\'t want to tell' },
      { title: 'Other' },
    ],
    textAreaPlaceholder: 'We will do our best to make automation fun and simple for everyone.'
  };

  numberOfPendingConfigurations = 0;
  isMobile: boolean;
  location: any;
  isAgency = Boolean(localStorage.getItem('bp-is-agency'));
  unreadMessageCount = 0;
  unreadMessage$: Subscription;
  lastMenuLoadFor: string;

  constructor(
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private botService: BotsService,
    private router: Router,
    private store: Store<State>,
    private networkService: NetworkService,
    private layoutService: LayoutService,
    private subscriptionService: SubscriptionService,
    private inboxService: InboxService,
    private whitelabelService: WhitelabelService,
    private redirectHandlerService: RedirectHandlerService,
    private languageService: LanguageService,
    private commonService: CommonService,
    private socketService: SocketService
  ) {
    if (this.isAgency && this.router.url.includes('agency')) {
      if (!localStorage.getItem('bp-isReloaded')) {
        localStorage.setItem('bp-isReloaded', 'no reload');
        location.reload();
      } else {
        localStorage.removeItem('bp-isReloaded');
      }
    }
    this.checkDevice();
    this.SetSidebarStatus();
    this.staticText = languageService.getStaticText().shared.layout.sidebar;
    this.reInitializeStaticText();
    if (localStorage.getItem('location')) {
      this.location = JSON.parse(localStorage.getItem('location'));
    }
    let plansFetched = false;
    this.setInitialActiveMenu();
    this.checkForPendingConfiguration();
    /**
     * Author: Divyansh
     * Date: 13 August 2022
     * Code commented as sidebar is made scrollable as of now. New UI to be implemented later on for sidebar
     */
    this.isSmallDevice = screen.height <= 900;
    this.activeBot = this.sharedService.selectedBotMeta._id || JSON.parse(sessionStorage.getItem('currentBotTypeAndId'))?._id;

    this.agencyDetailsInLocal = JSON.parse(localStorage.getItem('bp_parent_meta'));

    this.menuSubscription$ = sharedService.activeMenu.subscribe(menu => {
      this.activeMenu = menu;
    });

    this.animationSubscription$ = sharedService.showAnimation.subscribe(status => {
      this.shouldAnimate = status ? 'yes' : 'no';
    });

    this.isDarkMode$ = sharedService.isDarkMode.subscribe(isDarkMode => {
      this.isOnline = isDarkMode;
      document.body.classList.toggle('body-dark', isDarkMode);
    });

    this.storeSubscription$ = store
      .select((state) => state)
      .subscribe((state) => {
        this.agencyPermission = state.agencyDetails?.permission;
        this.agencyId = state.agencyDetails._id;
        this.tokens = state.tokens;
        this.profile = state.profile;
        this.agencyLogo = state.agencyDetails?.meta?.logo || this.agencyDetailsInLocal?.meta?.logo;
        this.profileType = state.profile.type;
        this.agencyToken = !!state.tokens.agencyToken;
        this.brandName = state.agencyDetails?.meta?.brand || 'BotPenguin';
        this.numberOfPendingConfigurations = state?.pendingConfigurations.length;
        this.unreadMessageCount = this.profile?.unreadMessages || 0;

        this.plansList = state?.subscription?.plans.reduce((result, item) => {
          result[item.displayName] = item;
          return result;
        }, {});
        // Logic to set payment Methods: Stripe or razorpay
        if ((state.profile as any)?._id && !plansFetched) {
          plansFetched = true;
          // TODO: Remove getOwnSubscription() when website subscription are merged into master subscription
          this.getOwnSubscription((state.profile as any)?.meta?.currency_code);
          this.getOwnMasterSubscription();

          if (this.profileType === 'CUSTOMER') {
            this.checkForFeedback();
          }
        }

        if (this.profileType === 'CUSTOM_USER') {
          this.profileType = state.profile.parentRoleType;
          this.getMenus();
        }

        if (this.profileType === 'ADMIN') {
          this.getMenus();
        }

        if (this.profileType && !this.menus.length && this.profileType !== 'AGENT' ? this.agencyPermission : true) {
          if (!this.menus.length) {
            this.lastMenuLoadFor = this.agencyPermission;
            this.getMenus();
          }

          sessionStorage.setItem('brand', environment.rootAgencyDomain.includes(window.location.host) ? 'BotPenguin' :
            state.agencyDetails?.meta?.brand);
        }

        /**
         * Date: 4/Nov/2023
         * Author: Amit Kumar
         * Summary: This Code is used to load menu on basis of permission change
         * Description: This Code is used to load menu on basis of permission change
         *
         * getMenu : This function is used to load sidebar menu list on basis of user permission
         * Like this : If Agent login then it will show only Inbox and Analytics
         * But it will load only first time
         * When Super Admin redirect to dashboard with token then it will show old token user menu list
         * So i have created this function for that case
         * If User change like customer to Agent then it will update sidebar menu list
         */
        if (this.agencyPermission !== this.lastMenuLoadFor && this.menus.length) {
          this.lastMenuLoadFor = this.agencyPermission;
          this.getMenus();
        }

        if (
          !state.bots.length &&
          !this.apiCalled &&
          (['AGENT', 'CUSTOMER'].includes(this.profileType) ||
            (this.profileType === 'CUSTOM_USER' && this.profile.parentRoleType === 'CUSTOMER')
          )
        ) {
          this.getBots();
          this.apiCalled = true;
        } else {
          this.bots = state.bots;

          const today = moment().format('l');
          const customerCreatedAt = moment.utc(this.profile?.createdAt).format('l');

          const todayDate = new Date(today);
          const createDate = new Date(customerCreatedAt);

          const differenceInTime = todayDate.getTime() - createDate.getTime();
          const differenceInDay = differenceInTime / (1000 * 3600 * 24);

          const nonDemoBots = this.bots.filter((bot: any) => !bot.isDemoBot);

          if (nonDemoBots.length === 0 && !localStorage.getItem('impersonate') &&
            this.profileType === 'CUSTOMER' && !this.router.url.includes('create-new-bot') && differenceInDay < 2) {
            // this.makeOneBot();
          } else {
            if (this.timeoutId) {
              clearTimeout(this.timeoutId);
              this.timeoutId = null;
            }
          }
        }

        if (state.profile?.plan?._plan?.name === 'baby') {
          this.sharedService.isBabyPlan.next(true);
        }
      });



    this.sidebar$ = sharedService.sideBarStatus.subscribe((value) => {
      this.isSideBarCollapsed = value === 'collapsed' ? true : false;
      if (this.isSideBarCollapsed) {
        this.createBotText = '+';
        this.collapseLabel = true;
        this.dropdownIcon = false;
      } else {
        setTimeout(() => {
          this.createBotText = this.staticText.createNewBot;
          this.collapseLabel = false;
          this.dropdownIcon = true;
        }, 390);
      }
    });

    this.popupSubscription$ = this.sharedService.closePopups
      .subscribe(() => {
        this.actionIndex = -1;
        this.editOption = { xPosition: '-100px', yPosition: '-100px' };
      });

    /**
     * Author: Satyam Sharma
     * Date: 11 July 2023
     */
    this.sharedService.selectedBotMeta = JSON.parse(sessionStorage.getItem('currentBotTypeAndId')) ? JSON.parse(sessionStorage.getItem('currentBotTypeAndId')) : { type: '', _id: '' };

    this.sidebarToggleSubscription$ = commonService.toggleSidebar$.subscribe(() => {
      this.toggleSidebar();
    });
    /**
     * Author: Satyam Sharma
     * Date: 11 July 2023
     * Summary: To show sidebar bot background color active
     * Description: When user click on any bot, I just store bot id and show unsaved change
     * dialog if api in process. Once user confirm then here it set new bot id and make them activate
     */
    this.unsavedWarning$ = this.sharedService.unsavedWarning.subscribe(item => {
      if (item.isConfirm && item.botId) {
        this.activeBot = item.botId;
        this.sharedService.unsavedWarning.next({
          ...item,
          isConfirm: false,
          botId: ''
        });
      }
    });

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.checkDevice();
    this.SetSidebarStatus();
  }

  ngOnInit(): void {
    this.fetchPlans();
    this.fetchWhatsAppPlans();
    this.getPlansDisplayName();
  }

  ngAfterViewInit() {

    this.unreadMessage$ = this.socketService.unreadMessageCount.subscribe(newMessage => {
      if (newMessage && !this.router.url.includes('inbox-merged')) {
        this.unreadMessageCount++;
      }
    });
  }

  getOwnSubscription(profileCurrencyCode): void {
    // this.subscriptionService.getSubscription()
    //   .then((data: UserSubscription) => {
    //     let currency = localStorage.getItem('default-currency');
    //     if (this.tokens.agencyToken) {
    //       currency = localStorage.getItem('original-default-currency');
    //     }

    //     let currencyToFetch = profileCurrencyCode || currency;
    //     if (data._plan.name === 'baby') {
    //       this.paymentGateway = (profileCurrencyCode || currency) === 'INR' ? 'RAZORPAY' : 'STRIPE';
    //     } else {
    //       this.paymentGateway = data.finalAmount.currency === 'INR' ? 'RAZORPAY' : 'STRIPE';
    //       currencyToFetch = data.finalAmount.currency;
    //     }
    //     if (!['USD', 'INR', 'CAD'].includes(currencyToFetch)) {
    //       currencyToFetch = this.paymentGateway === 'STRIPE' ? 'USD' : 'INR';
    //     }

    //     this.store.dispatch(
    //       updateProfile({
    //         update: { _plan: data._plan },
    //       })
    //     );
    //   })
    //   .catch(console.log);
  }

  reInitializeStaticText() {
    this.toolTip = this.staticText.appTooltip;
    this.createBotText = this.staticText.createNewBot;
    this.feedback = {
      hasOne: false,
      openBotDelete: false,
      headingOne: this.staticText.yourFriendWillMissYou,
      headingTwo: this.staticText.rateYourBotCreationExperience,
      deleteReasonHeading: this.staticText.whatMadeYouDeleteYourBot,
      botDeletionReasons: [
        { title: this.staticText.iCreatedByMistake },
        { title: this.staticText.iWantToCreateAnotherBot },
        { title: this.staticText.couldNotFindFeaturesThatINeeded },
        { title: this.staticText.uiWasHardToNavigate },
        { title: this.staticText.iDonotWantToTell },
        { title: this.staticText.other },
      ],
      textAreaPlaceholder: this.staticText.weWillDoOurBestToMakeAutomationFun
    };
  }

  getOwnMasterSubscription() {
    // this.subscriptionService.getMasterSubscription()
    //   .then((data: UserMasterSubscription) => {
    //     this.store.dispatch(
    //       updateProfile({
    //         update: { planMaster: data },
    //       })
    //     );
    //   })
    //   .catch(console.log);
  }


  ngOnDestroy(): void {
    this.sidebar$.unsubscribe();
    this.menuSubscription$.unsubscribe();
    this.storeSubscription$.unsubscribe();
    this.animationSubscription$.unsubscribe();
    this.popupSubscription$.unsubscribe();
    this.unsavedWarning$.unsubscribe();
    this.isDarkMode$.unsubscribe();

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.timerOne) {
      clearTimeout(this.timerOne);
    }
    if (this.timerTwo) {
      clearTimeout(this.timerTwo);
    }
    this.sidebarToggleSubscription$.unsubscribe();
    this.unreadMessage$.unsubscribe();
  }

  toggleSidebar(): void {
    if (this.timerOne) {
      clearTimeout(this.timerOne);
    }
    if (this.timerTwo) {
      clearTimeout(this.timerTwo);
    }

    this.createBotText = '';
    this.isSideBarCollapsed = !this.isSideBarCollapsed;
    this.sharedService.sideBarStatus.next(
      this.isSideBarCollapsed ? 'collapsed' : 'open'
    );

    if (this.isSideBarCollapsed) {
      this.collapseLabel = true;
      this.dropdownIcon = false;
    } else {
      this.timerOne = setTimeout(() => {
        this.collapseLabel = false;
        this.dropdownIcon = true;
      }, 390);
      this.menus.find(item => {
        if (item.key === 'bot') {
          this.bottomSidebar = 'closed';
          item.dropDownActive = localStorage.getItem('moreDropdown') !== 'true';
        }
      });
    }
    // Timeout added for smooth text transition. Transition on width is set to 0.5s
    this.timerTwo = setTimeout(() => {
      this.createBotText = this.isSideBarCollapsed ? '+' : this.staticText.createNewBot;
    }, 250);
  }

  isActive(key: string): boolean {
    const url = this.router.url;
    if (key === 'more') {
      return this.activeMenu === 'settings' || this.activeMenu === 'notification'
        || this.activeMenu === 'developer' || key === this.activeMenu;
    } else if (['settings', 'notification', 'developer'].includes(key)) {
      return url.includes(key) || key === this.activeMenu;
    }
    return key === this.activeMenu;
  }


  goOnline(): void { }

  showDropdown(menu: any): void {

    if (['bot', 'more'].includes(menu.key)) {
      const newDropdownValue = !menu.dropDownActive;

      const botObject = this.menus.find(m => m.key === 'bot');
      const moreObject = this.menus.find(m => m.key === 'more');

      if (menu.key === 'bot') {
        localStorage.setItem('botDropdown', String(newDropdownValue));
        localStorage.setItem('moreDropdown', String(!newDropdownValue));
        botObject.dropDownActive = newDropdownValue;
        moreObject.dropDownActive = !newDropdownValue;
      } else if (menu.key === 'more') {
        localStorage.setItem('botDropdown', String(!newDropdownValue));
        localStorage.setItem('moreDropdown', String(newDropdownValue));
        moreObject.dropDownActive = newDropdownValue;
        botObject.dropDownActive = !newDropdownValue;
      }


      this.sharedService.showAnimation.next(!menu.dropDownActive === false ? true : false);

      if (this.isSideBarCollapsed) {
        this.toggleSidebar();
      }
      // if (menu.dropDownActive) {
      //   this.toggleBottomSidebar('close');
      // }
    }
  }

  // toggleBottomSidebar(action: 'open' | 'close'): void {
  //   if (this.isSmallDevice) {
  //     switch (action) {
  //       case 'open':
  //         this.bottomSidebar = 'open';
  //         this.menus = this.menus.map((menu) => {
  //           if (menu.key === 'bot') {
  //             menu.dropDownActive = false;
  //           }
  //           return menu;
  //         });
  //         break;

  //       default:
  //         this.bottomSidebar = 'closed';
  //         this.menus = this.menus.map((menu) => {
  //           if (menu.key === 'bot') {
  //             menu.dropDownActive = true;
  //           }
  //           return menu;
  //         });
  //         break;
  //     }
  //   }
  // }

  getBots() {
    // this.sharedService.loader('start');
    // this.botService
    //   .getBots()
    //   .then((bots: WebsiteBot[]) => this.appendBots(bots, 'website'))
    //   .catch((err: any) => {
    //     return this.sharedService.showToast('error', '', err.message || err);
    //   })
    //   .finally(() => this.sharedService.loader('stop'));

    // this.sharedService.loader('start');
    // this.botService
    //   .getFacebookBots()
    //   .then((bots: FacebookBot[]) => this.appendBots(bots, 'facebook'))
    //   .catch((err: any) => {
    //     return this.sharedService.showToast('error', '', err.message || err);
    //   })
    //   .finally(() => this.sharedService.loader('stop'));

    // this.sharedService.loader('start');
    // this.botService
    //   .getWhatsappBots()
    //   .then((bots: WhatsAppBot[]) => this.appendBots(bots, 'whatsapp'))
    //   .catch((err: any) => {
    //     return this.sharedService.showToast('error', '', err.message || err);
    //   })
    //   .finally(() => this.sharedService.loader('stop'));

    // this.sharedService.loader('start');
    // this.botService
    //   .getTelegramBots()
    //   .then((bots: TelegramBot[]) => this.appendBots(bots, 'telegram'))
    //   .catch((err: any) => {
    //     return this.sharedService.showToast('error', '', err.message || err);
    //   })
    //   .finally(() => this.sharedService.loader('stop'));

    // this.sharedService.loader('start');
    // this.botService
    //   .getInstagramBots()
    //   .then((bots: any) => this.appendBots(bots, 'instagram'))
    //   .catch((err: any) => {
    //     return this.sharedService.showToast('error', '', err.message || err);
    //   })
    //   .finally(() => this.sharedService.loader('stop'));
  }

  appendBots(
    bots: (WebsiteBot | FacebookBot | TelegramBot | WhatsAppBot)[],
    type: 'website' | 'facebook' | 'telegram' | 'whatsapp' | 'instagram'
  ): void {

    const activeBotDetails: any = bots.find((b: any) => b._id === this.activeBot || b?.bot?._id === this.activeBot);

    this.bots = [...this.bots, ...bots.map((bot: any) => ({ ...bot, type }))];

    this.store.dispatch(
      loadBots({ bots: bots.map((bot: any) => ({ ...bot, type })) })
    );

    if (activeBotDetails) {
      this.sharedService.currentBot.next(activeBotDetails);
      // Logic to move selected bot to the top of the list
      this.store.dispatch(moveBotToTop({ botId: activeBotDetails._id || activeBotDetails?.bot?._id }));
    }

    this.menus.forEach((menu) => {
      if (menu.key === 'bot') {
        this.sharedService.showAnimation.next(false);
        menu.dropDownActive = true;
      }
    });
  }

  setActionIndex(index: number, event: PointerEvent): void {

    this.actionIndex = index;
    this.selectedBot = this.bots[this.actionIndex];
    const xPosition = (event.target as any).getBoundingClientRect().x - 45;
    const yPosition = (event.target as any).getBoundingClientRect().y + 30;
    this.editOption.xPosition = xPosition + 'px';
    this.editOption.yPosition = yPosition + 'px';
  }

  routeToGettingStarted(bot: any): void {
    /**
     * Author: Apoorv Saxena
     * Date: 7 June, 20023
     * Summary: Prevent bot active state if APi in process
     * Description: If API in precess, I just keep Store botId and show unsaved changes dialog,
     *  Once user click on continue then it help to show their state active
     */
    const currentState = this.sharedService.unsavedWarning.getValue();
    if (currentState.apiInProgress) {
      this.sharedService.unsavedWarning.next({
        ...currentState,
        botId: bot._id || bot.bot?._id
      });
    } else {
      this.activeBot = bot._id || bot.bot?._id;
    }

    if (this.activeBot) {
      this.sharedService.currentBot.next(bot);
      sessionStorage.setItem('currentId', bot._id);
      sessionStorage.setItem('currentBotTypeAndId', JSON.stringify({ _id: bot._id, type: '' }));
      this.sharedService.selectedBotMeta._id = bot._id;

      if (bot.type === 'telegram' || bot.type === 'whatsapp') {
        sessionStorage.setItem('currentId', bot._id || bot.bot._id);
        this.sharedService.selectedBotMeta._id = bot._id;
        sessionStorage.setItem(
          'currentBotTypeAndId',
          JSON.stringify({
            type: bot.type,
            _id: bot._id || bot.bot._id,
          })
        );
        this.sharedService.selectedBotMeta = {
          type: bot.type,
          _id: bot._id || bot.bot._id,
        };
      } else {
        if (bot.type === 'facebook' || bot.type === 'instagram') {
          sessionStorage.setItem(
            'currentBotTypeAndId',
            JSON.stringify({
              type: bot.type,
              _id: bot._id,
            })
          );
          this.sharedService.selectedBotMeta = {
            type: bot.type,
            _id: bot._id,
          };
        } else {
          this.sharedService.selectedBotMeta = { type: '', _id: bot._id };
          sessionStorage.setItem('currentBotTypeAndId', JSON.stringify({ type: '', _id: bot._id }));
        }
      }
      this.sharedService.loader('start');
      setTimeout(() => {
        this.router.navigateByUrl('/bot-builder/getting-started');
        this.sharedService.loader('stop');
      }, 200);
    }
  }

  navigate(menu: { router: string, key?: string }) {
    const currentState = this.sharedService.unsavedWarning.getValue();
    if (menu.router && menu.key === 'customers' && this.menus[6]?.key === 'customers') {
      if (!currentState.apiInProgress) {
        localStorage.removeItem('impersonate');
        /**
         * Initialize whatsapp onboarding steps
         */
        localStorage.setItem('currenBotStep', '3');
        localStorage.removeItem('pre-requistes');
      }
      window.location.href = `/${menu.router}`;
    } else if (menu.router) {
      this.redirectHandlerService.redirectTo(menu.router as any);
    }
  }

  logoutAction(shouldLogout: boolean): void {

    this.logoutModal = false;
    /**
     * Author: Parvesh Bhatt
     * Date: 11 July, 2023
     * Description: If user is on mobile and logout modal is open, we are resetting isMobile to true
     * after setting it false in setActiveMenu method.
     */
    if (this.isMobile) {
      this.commonService.updateIsMobileValue(true);
    }
    if (shouldLogout) {
      this.sharedService.loader('start');
      const activeView = localStorage.getItem('view-bp');
      const lastEmail = localStorage.getItem('last-email');

      localStorage.clear();
      sessionStorage.clear();

      localStorage.setItem('view-bp', activeView);
      localStorage.setItem('last-email', lastEmail);
      setTimeout(() => (window.location.href = '/authentication'), 800);
    }
  }

  async switchTheme(event: MatSlideToggleChange) {
    const userData = {
      isDarkTheme: event.checked
    };


    try {
      this.sharedService.loader('start');

      if (this.profile?.type === 'ADMIN') {
        await this.networkService.httpRequest('admin-user', userData, 'PUT');
        this.sharedService.isDarkMode.next(event.checked);
      } else {
        await this.networkService.httpRequest('customer/profile', userData, 'PUT');
        this.sharedService.isDarkMode.next(event.checked);
      }
      this.sharedService.loader('stop');
    } catch (error) {
      console.log('Error in theme chnage', error);
      this.sharedService.loader('stop');
    }
  }

  setAgentAvailability(event: MatSlideToggleChange) {
    this.sharedService.loader('start');
    this.store.dispatch(
      updateProfile({
        update: { liveAvailability: event.checked ? 'ONLINE' : 'OFFLINE' },
      })
    );
    this.authService
      .setAgentAvailabilty(
        this.profileType.toLowerCase(),
        event.checked ? 'ONLINE' : 'OFFLINE'
      )
      .catch((err: any) => {
        return this.sharedService.showToast('error', '', err.message || err);
      })
      .finally(() => this.sharedService.loader('stop'));
  }

  setActiveMenu(menu: 'home' | 'bot' | 'inbox' | 'analytics' | 'team' | 'subscription' | 'notification' | 'developer' | 'settings' | 'logout'
    | 'customers' | 'agencyDashboard' | 'agencyActivities' | 'configurations' | 'feedbacks'): void {
    if (menu !== 'logout') {

      this.sharedService.activeMenu.next(menu);
      if (menu === 'bot') {
        if (!this.bots.length) {
          if (this.location.country_code === 'GB' || this.location.country_code === 'IN') {
            window.location.href = '/bot-builder/create-new-bot';
          } else {
            window.location.href = '/bot-builder/select-use-case';
          }
          // this.sharedService.showToast('info', '', 'Please create a new bot first');
        } else {
          this.routeToGettingStarted(this.bots[0]);
        }
      }
    } else if (this.isMobile) {
      /**
       * Author: Parvesh Bhatt
       * Date: 11 July, 2023
       * Description: If user is on mobile, we are setting isMobile to false and open the full Sidebar.
       * So, that the modal backdrop will remain backside of the modal and user can interact with it.
       */
      this.commonService.updateIsMobileValue(false);
    }
  }

  setInitialActiveMenu(): void {
    if (this.router.url.includes('home')) {
      this.sharedService.activeMenu.next('home');
    } else if (this.router.url.includes('bot-builder')) {
      this.sharedService.activeMenu.next('bot');
    } else if (this.router.url.includes('inbox')) {
      this.sharedService.activeMenu.next('inbox');
    } else if (this.router.url.includes('analytics')) {
      this.sharedService.activeMenu.next('analytics');
    } else if (this.router.url.includes('team')) {
      this.sharedService.activeMenu.next('team');
    } else if (this.router.url.includes('agency/activities')) {
      this.sharedService.activeMenu.next('agencyActivities');
    } else if (this.router.url.includes('agency/subscriptions')) {
      this.sharedService.activeMenu.next('agencyActivities');
    } else if (this.router.url.includes('subscription')) {
      this.sharedService.activeMenu.next('subscription');
    } else if (this.router.url.includes('settings')) {
      this.sharedService.activeMenu.next('settings');
    } else if (this.router.url.includes('notification')) {
      this.sharedService.activeMenu.next('notification');
    } else if (this.router.url.includes('developer')) {
      this.sharedService.activeMenu.next('developer');
    } else if (this.router.url.includes('agency/dashboard')) {
      this.sharedService.activeMenu.next('agencyDashboard');
    } else if (this.router.url.includes('agency/customers')) {
      this.sharedService.activeMenu.next('customers');
    } else if (this.router.url.includes('access-control')) {
      this.sharedService.activeMenu.next('accessControl');
    } else if (this.router.url.includes('agency/configurations')) {
      this.sharedService.activeMenu.next('configurations');
    } else if (this.router.url.includes('agency/feedbacks')) {
      this.sharedService.activeMenu.next('feedbacks');
    }

  }

  botActions(action, status): void {
    const bot: any = this.bots[this.actionIndex];
    switch (action) {
      case 'edit':
        this.routeToGettingStarted(bot);
        break;
      case 'delete':
        this.selectedId = bot?._id || bot?.bot?._id;
        this.botType = bot.type || 'website';
        /**
         * Author: Parvesh Bhatt
         * Date: 11 July, 2023
         * Description: If user is on mobile, we are setting isMobile to false and open the full Sidebar.
         * So, that the modal backdrop will remain backside of the modal and user can interact with it.
         */
        if (this.isMobile) {
          this.commonService.updateIsMobileValue(false);
        }
        break;
      case 'clone':
        this.copyBot(bot);
        break;
      case 'deactivate':
        this.changeStatus(bot, status);
        break;
      default:
        break;
    }
  }

  copyBot(bot): void {
    /** King plan check */
    // const nonDemoBots = this.bots.filter((b: any) => !b.isDemoBot);

    if (this.subscriptionService.isBabyPlan && this.bots?.length >= 1) {
      return this.sharedService.openUpgradeModal.next({ isUpgradeModal: true });
    } else if (
      this.bots?.length >= 50 && !this.subscriptionService.isBabyPlan
    ) {
      return this.sharedService
        .showToast('warning', '', ' Your current plan supports max 50 bots. Please contact support to increase the limit.');
    }

    const botType = bot.type;
    // this.sharedService.loader('start');
    // this.authService
    //   .createBot({ ...bot, _id: undefined, name: bot.name + ' - copy', whitelabel: { status: false } })
    //   .then((newBot: any) => {
    //     this.store.dispatch(addBot({ bot: { ...newBot, type: botType } }));
    //     this.sharedService.showToast('success', '', 'Bot Copied Successfully');
    //   })
    //   .catch((err) => {
    //     if (err.split(' ').includes('upgrade')) {
    //       return this.sharedService.openUpgradeModal.next({ isUpgradeModal: true });
    //     } else if (new RegExp('Bot validation failed').test(err)) {
    //       return this.sharedService.showToast('error', '', 'You cannot copy a bot with broken flow, Please fix the flow first');
    //     } else {
    //       this.sharedService.showToast('error', '', err.message || err);
    //     }
    //   })
    //   .finally(() => this.sharedService.loader('stop'));
  }

  action(status) {
    if (status) {
      this.router.navigate(['/subscription']);
    }
    this.sharedService.openUpgradeModal.next({ isUpgradeModal: false });
  }

  deleteBot(event) {

    this.openDeleteModal = false;
    /**
     * Author: Parvesh Bhatt
     * Date: 11 July, 2023
     * Description: If user is on mobile and delete bot modal is open, we are resetting isMobile to true
     * after setting it false in botActions method.
     */
    if (this.isMobile) {
      this.commonService.updateIsMobileValue(true);
    }
    if (event) {
      // if (this.botType === 'website') {
      //   this.authService
      //     .deleteBot(this.selectedId)
      //     .then((res: any) => {
      //       this.sharedService.showToast('success', '', 'Bot Deleted Successfully');
      //       this.store.dispatch(deleteBot({ bot: this.selectedId }));
      //       setTimeout(() => {
      //         if (this.location.country_code === 'GB' || this.location.country_code === 'IN') {
      //           window.location.href = 'bot-builder/create-new-bot';
      //         } else {
      //           window.location.href = 'bot-builder/select-use-case';
      //         }
      //       }, 1000);
      //     })
      //     .catch((err) => {
      //       this.sharedService.showToast('error', '', err);
      //     });
      // } else if (this.botType === 'facebook') {
      //   this.networkService.httpRequest(`facebook-automation/bots/bot/${this.selectedId}`, { id: this.selectedId }, 'DELETE')
      //     .then((res) => {
      //       this.store.dispatch(deleteBot({ bot: this.selectedId }));
      //       this.sharedService.showToast('success', '', 'Bot Deleted Successfully');
      //       setTimeout(() => {
      //         if (this.location.country_code === 'GB' || this.location.country_code === 'IN') {
      //           window.location.href = 'bot-builder/create-new-bot';
      //         } else {
      //           window.location.href = 'bot-builder/select-use-case';
      //         }
      //       }, 1000);
      //     })
      //     .catch(error => console.log(error));
      // } else if (this.botType === 'instagram') {
      //   this.networkService.httpRequest(`instagram-automation/bots/bot/${this.selectedId}`, { id: this.selectedId }, 'DELETE')
      //     .then((res) => {
      //       this.store.dispatch(deleteBot({ bot: this.selectedId }));
      //       this.sharedService.showToast('success', '', 'Bot Deleted Successfully');
      //       setTimeout(() => {
      //         if (this.location.country_code === 'GB' || this.location.country_code === 'IN') {
      //           window.location.href = 'bot-builder/create-new-bot';
      //         } else {
      //           window.location.href = 'bot-builder/select-use-case';
      //         }
      //       }, 1000);
      //     })
      //     .catch(error => console.log(error));
      // } else {
      //   this.networkService.httpRequest(`${this.botType}-automation/bot/${this.selectedId}`, { id: this.selectedId }, 'DELETE')
      //     .then((res) => {
      //       this.sharedService.showToast('success', '', 'Bot Deleted Successfully');
      //       this.store.dispatch(deleteSocialBot({ bot: this.selectedId }));
      //       setTimeout(() => {
      //         if (this.location.country_code === 'GB' || this.location.country_code === 'IN') {
      //           window.location.href = 'bot-builder/create-new-bot';
      //         } else {
      //           window.location.href = 'bot-builder/select-use-case';
      //         }
      //       }, 1000);
      //     })
      //     .catch(error => this.sharedService.showToast('error', '', error));
      // }
    }
  }

  changeStatus(bot: any, status) {
    // if (bot.type === 'telegram') {

    //   this.sharedService.loader('start');
    //   this.layoutService.changeTelegramBotStatus(bot?.bot?._id, bot.bot.botToken, status)
    //     .then((res: any) => {
    //       if (res.status) {
    //         this.bots = this.bots.map((b: any) => {
    //           if (b?.bot?._id === bot?._id) {
    //             return b = { ...b, bot: { ...b.bot, status } };
    //           } else {
    //             return b;
    //           }
    //         });
    //         this.sharedService.showToast('success', '', 'Updated Successfully');
    //       }
    //     })
    //     .catch(error => this.sharedService.showToast('error', '', error.message))
    //     .finally(() => this.sharedService.loader('stop'));
    // }
    // else if (bot.type === 'whatsapp') {

    //   this.layoutService.changeWhatsAppBotStatus(bot._id, status)
    //     .then((res: any) => {
    //       if (res.status) {
    //         this.bots = this.bots.map((b: any) => {
    //           if (b._id === bot._id) {
    //             return b = { ...b, bot: { ...b, status } };
    //           } else {
    //             return b;
    //           }
    //         });
    //         if (res.status === 'ACTIVE') {
    //           this.selectedBot.bot.status = 'ACTIVE';
    //         } else if (res.status === 'INACTIVE') {
    //           this.selectedBot.bot.status = 'INACTIVE';
    //         }
    //         this.store.dispatch(updateBot({ bot: res._id, update: res }));
    //         this.sharedService.showToast('success', '', 'Updated Successfully');
    //       }
    //     })
    //     .catch(err => this.sharedService.showToast('error', '', err))
    //     .finally(() => this.sharedService.loader('stop'));
    // } else if (bot.type === 'facebook') {
    //   this.layoutService
    //     .changePageStatus(bot._id, bot.pageId, bot.pageToken, status)
    //     .then((res: any) => {
    //       if (res.status) {
    //         this.bots = this.bots.map((p) => {
    //           if (p._id === bot._id) {
    //             return { ...p, status };
    //           } else {
    //             return p;
    //           }
    //         });
    //         this.store.dispatch(updateBot({ bot: res._id, update: res }));
    //         this.sharedService.showToast('success', '', 'Updated Successfully');
    //       }
    //     })
    //     .catch(err => this.sharedService.showToast('error', '', err))
    //     .finally(() => this.sharedService.loader('stop'));
    // }
  }

  getMenus() {
    this.menus = [
      { key: 'home', value: this.staticText.dashboard, router: 'home', image: '/dashboard', permission: 'customer.read' },
      {
        key: 'inbox', value: 'Sessions', router: '/inbox-merged/sessions', image: '/video_call',
        permission: 'recording.read||actionable.read'
      },
      {
        key: 'accessControl', value: this.staticText.team, router: 'access-control', image: '/groups',
        permission: 'team-user.read||team-role.read'
      }
    ];
  }

  get logoUrl(): string {
    if (this.agencyPermission === 'WHITE_LABEL' || (this.agencyDetailsInLocal && this.profileType === 'AGENT')) {
      return this.agencyLogo;
    } else {
      return 'assets/images/logo-v2.svg';
    }
  }

  makeOneBot() {
    if (!this.timeoutId) {
      this.timeoutId = setTimeout(() => {
        const nonDemoBots = this.bots.filter((bot: any) => !bot.isDemoBot);
        if (nonDemoBots.length !== 0) {
          return;
        }
        this.sharedService.showToast('info', '', `Please create your first chatbot to continue exploring chat automation with ${this.brandName}`);
        this.router.navigate(['/bot-builder/onboarding/create-new-bot']);
      }, 15000);
    }
  }

  saveFeedbackAndDeleteBot(userInput) {
    this.feedback.openBotDelete = false;
    /**
     * Author: Parvesh Bhatt
     * Date: 11 July, 2023
     * Description: If user is on mobile and feedback bot modal is open, we are resetting isMobile to true
     * after setting it false in botActions method.
     */

    if (this.isMobile) {
      this.commonService.updateIsMobileValue(true);
    }

    // if (userInput) {
    //   this.sharedService.loader('start');
    //   this.authService
    //     .createFeedback({ ...userInput, type: 'bot-delete' })
    //     .then(() => {
    //       this.deleteBot(true);
    //     })
    //     .catch((err) => {
    //       this.sharedService.showToast('error', '', err.message || err);
    //     })
    //     .finally(() => this.sharedService.loader('stop'));
    // }
  }

  checkForFeedback() {
    // this.authService
    //   .isUserFeedback()
    //   .then((data) => {
    //     this.feedback.hasOne = !!data;
    //   })
    //   .catch((err) => {
    //     this.sharedService.showToast('error', '', err.message || err);
    //   });
  }

  checkForPendingConfiguration() {
    // this.whitelabelService
    //   .getPendingConfigurations()
    //   .then((data: string[]) => {
    //     this.store.dispatch(loadPendingConfigurations({ configurations: data.filter(config => config !== 'poweredBy') || [] }));
    //   })
    //   .catch((err) => {
    //     this.sharedService.showToast('error', '', err?.message || err);
    //   });
  }

  private checkDevice() {
    this.isMobile = window.innerWidth <= 992;
    this.commonService.updateIsMobileValue(this.isMobile);
  }

  SetSidebarStatus() {
    if (this.isMobile) {
      this.isSideBarCollapsed = true;
      this.sharedService.sideBarStatus.next('collapsed');
    } else {
      this.isSideBarCollapsed = false;
      this.sharedService.sideBarStatus.next('open');
    }
  }

  onScrollMobileSidebar(event: Event) {
    const element = event.target as HTMLElement;
    element.classList.toggle('show-top-shadow', element.scrollTop > 0);
  }

  // Fetching Website PLans
  fetchPlans() {
    // this.subscriptionService
    //   .getAvailablePricing()
    //   .then((plans: any[]) => {

    //     const kingPlan = plans.find(p => p.name === 'king');
    //     const babyPlan = plans.find(p => p.name === 'baby');
    //     this.sharedService.plansDisplayName.next({
    //       baby: babyPlan?.displayName || this.sharedService.toTitleCase(babyPlan?.name),
    //       king: kingPlan?.displayName || this.sharedService.toTitleCase(kingPlan?.name)
    //     });
    //     this.store.dispatch(
    //       loadPlans({ plans })
    //     );
    //   })
    //   .catch(error => {
    //     console.log('Could not Fetch Plans', error);
    //   });
  }

  // Fetching WhatsApp Plans
  fetchWhatsAppPlans() {
    // this.subscriptionService
    //   .getAvailablePricing(true)
    //   .then((plansList: any[]) => {
    //     const kingPlan = plansList.find(p => p.name === 'king');
    //     const babyPlan = plansList.find(p => p.name === 'baby');
    //     this.sharedService.whatsappPlansDisplayName.next({
    //       baby: babyPlan?.pricing?.whatsapp?.displayName || this.sharedService.toTitleCase(babyPlan?.name),
    //       king: kingPlan?.pricing?.whatsapp?.displayName || this.sharedService.toTitleCase(kingPlan?.name)
    //     });
    //     this.store.dispatch(
    //       loadWhatsAppPlans({ masterPlans: plansList })
    //     );
    //   })
    //   .catch(error => {
    //     console.log('Could not Fetch WhatsApp Plans', error);
    //   });

  }

  routeToSelectUseCaseOrCreateNewBot() {
    if (this.location.country_code === 'GB' || this.location.country_code === 'IN') {
      return this.router.navigate([`/bot-builder/create-new-bot`]);
    }
    return this.router.navigate([`/bot-builder/select-use-case`]);
  }

  async getPlansDisplayName() {
    // try {
    //   const response: PlanDisplayNameResponse = await this.subscriptionService.getPlanDisplayName();
    //   const plans = {
    //     exceptWA: {
    //       king: this.findDisplayName(response.exceptWA, 'king'),
    //       baby: this.findDisplayName(response.exceptWA, 'baby'),
    //     },
    //     whatsapp: {
    //       king: this.findDisplayName(response.whatsapp, 'king', true),
    //       baby: this.findDisplayName(response.whatsapp, 'baby', true),
    //     },
    //   };

    //   this.sharedService.plansDisplayName.next({
    //     baby: plans.exceptWA.baby,
    //     king: plans.exceptWA.king,
    //   });

    //   this.sharedService.whatsappPlansDisplayName.next({
    //     baby: plans.whatsapp.baby,
    //     king: plans.whatsapp.king,
    //   });
    // } catch (err) {
    //   console.error(err);
    // }
  }

  navigateSideMenu(data, type) {
    const unsavedWarning = this.sharedService.unsavedWarning.getValue();

    switch (type) {
      case 'createBot': {
        this.routeToSelectUseCaseOrCreateNewBot();
        break;
      }
      case 'bots': {
        this.routeToGettingStarted(data);
        break;
      }
      case 'notification':
      case 'developer':
      case 'settings': {
        this.navigate({ router: type });
        if (!unsavedWarning.apiInProgress) {
          this.setActiveMenu(type);
        }
        break;
      }
      default: {
        if (data.key !== 'more') {
          if (!unsavedWarning.apiInProgress) {
            this.setActiveMenu(data.key);
          }
        }
        this.showDropdown(data);
        this.navigate(data);
        break;
      }
    }

  }

  findDisplayName(plans, name, isMasterPlan = false) {
    const plan = plans.find(p => p.name === name);
    return plan ? (isMasterPlan ? plan.pricing.whatsapp?.displayName : plan.displayName) || this.sharedService.toTitleCase(plan.name) : '';
  }

  get getUnreadMessageCount() {
    return this.unreadMessageCount > 99 ? '99+' : this.unreadMessageCount;
  }
}

interface PlanDisplayNameResponse {
  exceptWA: PlanInfo[];
  whatsapp: PlanInfo[];
}

interface PlanInfo {
  name: string;
  displayName?: string;
  pricing?: {
    whatsapp?: {
      displayName?: string;
    };
  };
}
