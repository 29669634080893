import { NgxUiLoaderService } from 'ngx-ui-loader';
import { updateToken } from './ngrx/actions/tokens.action';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { updateProfile } from 'src/appv2/ngrx/actions/profile.actions';
// import { SubscriptionService } from '../app/subscription/subscription.service';
// import { TwilioService } from '../app/conversation/leads/twilio.service';
import { updateAgencyDetails } from './ngrx/actions/agency-details.action';
import AgencyDetails from './models/agency-details';
import { update } from 'lodash';
import { TwilioService } from './services/twilio.service';
import { SharedService } from './modules/shared/shared.service';
import { SocketService } from './services/socket.service';
import { SubscriptionService } from './services/subscription.service';
import { CanvasComponent } from './modules/bot-builder/components/canvas/canvas.component';
import { CanvasService } from './services/canvas.service';
import { UploadService } from './services/upload.service';
import { Title } from '@angular/platform-browser';
import { version } from '../../package.json';
import { AccessControlService } from './services/access-control.service';
import { WhatsAppService } from './services/whatsApp.service';
import { LanguageService } from './services/language.service';
declare const window: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  // environment = environment;
  userToken = localStorage.getItem('token');
  userId = localStorage.getItem('userProfile');
  isNewVersionAvailable = false;
  state: any = {};
  subscription$: any;
  showBotText = true;
  showError = false;
  isCallBox = true;
  isCallOngoing$: Subscription;
  isCallOngoing = false;
  showChatbotPreview$: Subscription;
  showChatbotPreview = false;
  pendingPayments: Array<any> = [];
  agencyDetails: AgencyDetails;
  isSideBarCollapsed = false;
  sidebar$: Subscription;
  maintenanceAlert = false;
  profile: any;
  isShowModal = false;
  botUseCase: string;
  showPopupForConnectBot$: Subscription;
  staticText: any;
  botName: string;
  constructor(
    private router: Router,
    private socket: SocketService,
    private store: Store<any>,
    // private commonService: CommonService,
    private authService: AuthenticationService,
    private ngxService: NgxUiLoaderService,
    private subscriptionService: SubscriptionService,
    private activatedRoute: ActivatedRoute,
    public twilio: TwilioService,
    private sharedService: SharedService,
    private canvasService: CanvasService,
    private uploadService: UploadService,
    private title: Title,
    private aclService: AccessControlService,
    private whatsAppService: WhatsAppService,
    private languageService: LanguageService
  ) {

    if (this.router.url.includes('/')) {
      const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      this.sharedService.isDarkMode.next(isDark);
      sharedService.isDarkMode.subscribe(isDarkMode => {
        document.body.classList.toggle('body-dark', isDarkMode);
      });
    }
    this.staticText = languageService.getStaticText().app;
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', JSON.stringify({ title: 'English', localValue: 'English', value: 'en' }));
    }
    this.sidebar$ = this.sharedService.sideBarStatus.subscribe(value => {
      this.isSideBarCollapsed = value === 'collapsed' ? true : false;
    });

    activatedRoute.queryParamMap.subscribe(params => {
      const referral = params.get('fp_ref');

      sessionStorage.setItem('referral', JSON.stringify({
        exists: !!referral,
        by: referral,
        status: 'PENDING'
      }));
    });
    // document.getElementById('favicon').setAttribute('href', environment.favicon);
    // document.title = environment.appName;
    // commonService.requestNotificationPermission()
    //   .then(async () => {
    // return commonService.Toast.fire({
    //   title: 'You have successfully registered for desktop notifications',
    //   icon: 'success'
    // });
    // })
    // .catch(() => {

    // });

    const developerMessage = 'Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.Do not enter or paste code that you do not understand.';
    console.log(
      `%c${developerMessage}`,
      `color: red; font-weight: bold; font-size:20px;text-shadow: 0 0 5px rgba(0,0,0,0.2);`
    );

    console.log(
      `%cIf you are looking for job 🐧, please visit https://careers.relinns.com/`,
      `color: #118cfc; font-weight: bold; font-size:16px;text-shadow: 0 0 2px rgba(0,0,0,0.2);`
    );
    fetch(environment.ipDetailsUrl)
      .then((res: any) => res.json())
      .then((res: any) => {
        localStorage.setItem('location', JSON.stringify(res));

        switch ((res.countryCode || '').toUpperCase()) {

          case 'IN': {
            localStorage.setItem('default-currency', 'INR');
            localStorage.setItem('original-default-currency', 'INR');
            break;
          }
          case 'INDIA': {
            localStorage.setItem('default-currency', 'INR');
            localStorage.setItem('original-default-currency', 'INR');
            break;
          }
          case 'CA': {
            localStorage.setItem('default-currency', 'USD');
            localStorage.setItem('original-default-currency', 'CAD');
            break;
          }
          default: {
            // Adding scope for other future where whitelabel agency can create their own currency pricing
            localStorage.setItem('default-currency', res.currency_code || 'USD');
            localStorage.setItem('original-default-currency', res.currency_code || 'USD');
          }
        }
      })
      .catch(console.log);

    class BotPenguin {
      currentOpener = undefined;

      closeWindow(chatWindow: any = null, launcher: any = null) {
        try {
          const frame = document.getElementById(chatWindow || 'BotPenguin-messenger');
          // frame.style.visibility = 'hidden';
          // frame.style.opacity = '0';
          frame.style.display = 'none';

          const icon = document.getElementById(launcher || 'botpenguin-launcher-12');
          icon.style.visibility = 'visible';
          icon.style.opacity = '1';
        } catch (e) {
        }
      }

      setOpener(opener: string) {
        this.currentOpener = opener;
      }

      openWindow(opener: string, chatWindow: any = null, launcher: any = null) {
        if (this.currentOpener === opener) {
          const icon = document.getElementById(launcher || 'botpenguin-launcher-12');
          icon.style.visibility = 'hidden';
          icon.style.opacity = '0';
          const frame = document.getElementById(chatWindow || 'BotPenguin-messenger');
          // frame.style.visibility = 'visible';
          // frame.style.opacity = '1';
          frame.style.display = 'block';

          window
            .document
            .getElementById(chatWindow || 'BotPenguin-messenger')
            .contentWindow
            .postMessage({ origin: 'BotPenguin', action: 'start' }, '*');
        }

      }

      isWindowOpen(chatWindow: any = null) {
        return document.getElementById(chatWindow || 'BotPenguin-messenger').style.display === 'block';
      }

      isWindowClose(chatWindow: any = null) {
        return document.getElementById(chatWindow || 'BotPenguin-messenger').style.display !== 'block';
      }
    }


    window.BotPenguin = BotPenguin;
    this.subscription$ = this.store.select(state => state).subscribe(state => {
      this.state = state;
    });

    this.showPopupForConnectBot$ = this.whatsAppService?.showPopupForConnectBot?.subscribe(({ show, useCase, botName }) => {
      if (show) {
        this.whatsAppService.showPopupForConnectBot.next({ show: false, useCase: '', botName: '' });
        this.botUseCase = useCase;
        this.botName = botName;
        setTimeout(() => {
          this.isShowModal = true;
        }, 1000);
      }
    });

  }

  ngOnInit() {
    sessionStorage.removeItem('flowId');
    // const agencyDetails: any = JSON.parse(localStorage.getItem('bp_parent_meta'));
    // if (agencyDetails) {
    //   this.title.setTitle(agencyDetails?.meta?.brand);
    //   document.getElementById('favicon').setAttribute('href', agencyDetails.meta.logo);
    // } else if (environment.rootAgencyDomain.includes(window.location.host)) {
    //   document.title = environment.appName;
    // }
    if (localStorage.getItem('token')) {
      this.getProfile();
    }
    this.isCallOngoing$ = this.twilio.isCallOngoing.subscribe((call: any) => {
      this.isCallOngoing = call;
      this.isCallBox = true;
    });

    this.showChatbotPreview$ = this.canvasService.isShowChatbotPreview.subscribe(value => {
      this.showChatbotPreview = value;
    });

    if (environment.production) {
      setInterval(() => {
        this.checkForNewVersion();
      }, 60 * 1000);
    }
  }


  getProfile() {
    if (localStorage.getItem('token')) {
      this.ngxService.start();
      /**
       * As of now bp_parent is being as undefined hence causing a crash for agent hence commented out the statement
       */
      if (localStorage.getItem('bp_parent') !== 'undefined') {
        this.store.dispatch(updateAgencyDetails({ update: JSON.parse(localStorage.getItem('bp_parent')) }));
      }
      if (localStorage.getItem('impersonate')) {
        this.store.dispatch(updateToken({ key: 'agencyToken', token: localStorage.getItem('token') }));
        this.store.dispatch(updateToken({ key: 'accessToken', token: localStorage.getItem('impersonate') }));
      } else {
        this.store.dispatch(updateToken({ key: 'accessToken', token: localStorage.getItem('token') }));
      }
      this.authService.getProfile()
        .then((response: any) => {
          this.profile = response;
          this.sharedService.isDarkMode.next(response.isDarkTheme);
          this.aclService.permissions.next({
            fetched: true,
            resources: response?.permissions || []
          });
          this.store.dispatch(updateProfile({ update: response }));
          if (response.type === 'CUSTOMER') {
            this.showError = response.limitOver;
            this.socket.makeConnection({
              token: localStorage.getItem('impersonate') || localStorage.getItem('token'),
              user: response._id
            });
            this.store.dispatch(updateToken({ key: 'userToken', token: localStorage.getItem('token') }));
            // this.subscriptionService.getPendingPayments()
            //   .then((payments: Array<any>) => {
            //     this.pendingPayments = payments;
            //   });
          } else if (response.type === 'AGENCY') {
            this.store.dispatch(updateToken({ key: 'agencyToken', token: localStorage.getItem('token') }));
          } else if (response.type === 'AGENT') {
            this.socket.makeConnection({
              token: localStorage.getItem('token'),
              user: response._parent
            });
            this.store.dispatch(updateToken({ key: 'agentToken', token: localStorage.getItem('token') }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }


  get ifRootAgency(): boolean {
    return environment.rootAgencyDomain.includes(window.location.host);
  }

  ngOnDestroy(): void {
    try {
      this.subscription$.unsubscribe();
      this.isCallOngoing$.unsubscribe();
      this.showChatbotPreview$.unsubscribe();
      this.sidebar$.unsubscribe();
      this.showPopupForConnectBot$.unsubscribe();
    } catch (e) {
      console.error(e.message);
    }
  }

  hideProgressCallBox() {
    this.isCallBox = false;
  }

  closeBotText() {
    this.showBotText = false;
  }

  openWhatsAppWindow() {
    const whatsAppNumber = environment.rootAgencyDomain.includes(window.location.host) ?
      '919740374298' : this.agencyDetails.contact.phone.number;

    const body = `Hi Team,
I want to build WhatsApp Bot, Please help us build the same. Here are my details

Registered Email: ${this.profile.contact?.email || ''}
Registered Phone: ${this.profile?.contact?.phone?.number || 'N/A'}

Thanks`;
    window.open(`https://wa.me/${whatsAppNumber}/?text=${encodeURIComponent(body)}`, `_blank'`);
  }

  removePendingPayment(linkId: string) {
    this.pendingPayments = this.pendingPayments.filter(p => p.linkId !== linkId);
  }

  closePopups() {
    this.sharedService.closePopups.next();
  }


  checkForNewVersion() {
    fetch(environment.servingDomain)
      .then(res => res.text())
      .then(html => {
        this.isNewVersionAvailable = !html.includes(`<script id="${version}"></script>`);
      })
      .catch(err => console.log(err));
  }

  getNewVersion() {
    window.location.href = window.location.href;
  }

  get isAllowedOnCurrentPage(): boolean {
    return !this.router.url.includes('authentication');
  }

  whatsappConnect(userInput) {
    if (userInput) {
      /**
       * Handling for auto created demo bots
       * if the bot name is 'WhatsApp Trial Bot'
       * then redirect to enquiry form
       * else redirect to whatsapp connect
       */
      this.botName === 'WhatsApp Trial Bot'
        ? this.whatsAppService.createBotStep.next(3)
        : this.whatsAppService.createBotStep.next(5);
      this.router.navigate(['/bot-builder/whatsapp'], { queryParams: { useCase: this.botUseCase || 'others' } });
    }
    return this.isShowModal = false;
  }

}
