export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Willkommen bei Botpenguin!',
      login: 'Anmeldung',
      youAreAllSet: 'Sie sind alle eingestellt! Sie können den WhatsApp -Posteingang von Lead -Details -Seite verwenden.',
      placeholders: {
        enterEmail: 'Email eingeben',
        enterPassword: 'Passwort eingeben',
      }
    }
  }
};
