import { Directive, ElementRef, HostListener } from '@angular/core';
import * as validator from 'card-validator';
import { SharedService } from '../shared.service';

@Directive({
  selector: '[appInputValidator]',
})
export class InputValidatorDirective {
  border: string;
  validIcon: any;
  errorIcon: any;
  errorMessage: any;
  public characterLimitError: any;
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{6,})/gm;
  urlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;

  @HostListener('change', ['$event.target.name', '$event.target.value'])
  handler(
    name:
      | 'bp-email'
      | 'bp-password'
      | 'bp-card-validator'
      | 'bp-cvv'
      | 'bp-url'
      | 'bp-name',
    value
  ) {
    this.validIcon = (
      this.elementRef.nativeElement as HTMLInputElement
    ).nextElementSibling;
    this.errorIcon = this.validIcon?.nextElementSibling;
    this.errorMessage = this.errorIcon?.nextElementSibling;

    if (name === 'bp-email') {
      if (value.match(new RegExp(this.emailRegex))) {
        this.hideError();
      } else {
        this.showError();
      }
    } else if (name === 'bp-password') {
      if (value.match(new RegExp(this.passwordRegex))) {
        this.hideError();
      } else {
        this.showError();
        this.sharedService.showToast(
          'warning',
          '',
          'Password should have one capital letter, one number, one special character and should be min 6 character long'
        );
      }
    } else if (name === 'bp-card-validator') {
      const numberValidation = validator.number(value);

      if (!numberValidation.isPotentiallyValid || value.length < 16) {
        this.showError();
      } else {
        this.hideError();
        this.sharedService.checkCardType(numberValidation.card.type);
      }
    } else if (name === 'bp-cvv') {
      if (value.length < 3) {
        this.showError();
      } else {
        this.hideError();
      }
    } else if (name === 'bp-url') {
      if (
        value.match(new RegExp(this.urlRegex)) &&
        value.match(/^https:\/\//gi)
      ) {
        this.hideError();
      } else {
        this.showError();
        this.sharedService.showToast(
          'warning',
          '',
          'Please enter a valid website URL'
        );
      }
    }
  }

  @HostListener('keyup', ['$event.target.name', '$event.target.value'])
  keyUpHandler(name: 'bp-name', value) {
    this.validIcon = (
      this.elementRef.nativeElement as HTMLInputElement
    ).nextElementSibling;
    this.errorIcon = this.validIcon?.nextElementSibling;
    this.errorMessage = this.errorIcon?.nextElementSibling;
    this.characterLimitError = this.errorMessage?.nextElementSibling;

    if (name === 'bp-name') {
        if (value.length >= 50) {
        this.showError();
      } else if (value.length === 0) {
        this.showCharacterLimitError();
      } else if (value.length > 0 && value.length < 50) {
        this.hideCharacterLimitError();
        this.hideError();
      }
    }
  }

  constructor(
    public elementRef: ElementRef,
    public sharedService: SharedService
  ) {}

  showError(): void {
    this.elementRef.nativeElement.style.border = '1px solid #AA263F';
    this.elementRef.nativeElement.style.color = '#AA263F';
    this.errorIcon?.classList.add('d-block');
    this.characterLimitError?.classList.add('d-block');
    this.validIcon?.classList.remove('d-block');
  }

  hideError(): void {
    this.elementRef.nativeElement.style.border = '1px solid #dfdfdf';
    this.elementRef.nativeElement.style.color = '#000000';
    this.validIcon?.classList.add('d-block');
    this.errorIcon?.classList.remove('d-block');
    this.characterLimitError?.classList.remove('d-block');
  }

  showCharacterLimitError(): void {
    this.elementRef.nativeElement.style.border = '1px solid #AA263F';
    this.elementRef.nativeElement.style.color = '#AA263F';
    this.errorIcon?.classList.add('d-block');
    this.validIcon?.classList.remove('d-block');
    this.errorMessage?.classList.add('d-block');
  }

  hideCharacterLimitError(): void {
    this.elementRef.nativeElement.style.border = '1px solid #dfdfdf';
    this.elementRef.nativeElement.style.color = '#000000';
    this.validIcon?.classList.add('d-block');
    this.errorIcon?.classList.remove('d-block');
    this.errorMessage?.classList.remove('d-block');
  }
}
