export const whitelabel = {
  plans: 'Plannen',
  managePlan: 'Beheer Plannen',
  whitelabelCharges: 'Whitelabel Kosten',
  chargesOrTaxesConfiguration: 'Kosten/Belasting Configuratie',
  websitePlans: 'Website Plannen',
  whatsappPlansHeader: 'WhatsApp Plannen',
  addOnPlans: {
    createAddOnPlans: 'Voeg Add-On plannen toe',
    checkoutYourCurrentMessage: 'Bekijk uw huidige bericht Add-On Plannen en nieuwe plannen voor uw klanten',
    choosePlanValidity: 'Kies Plan Geldigheid',
    chooseValidity: 'Kies Geldigheid',
    oneYear: 'Een Jaar',
    billingCycle: 'Factureringscyclus',
    numberOfMessages: 'Aantal Berichten',
    pleaseEnterNumberOfMessages: 'Voer het aantal berichten in',
    setCurrency: 'Stel Valuta in',
    select: 'Selecteer',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Wis alles',
    apply: 'Toepassen',
    currency: 'Valuta',
    amount: 'Bedrag',
    validity: 'Geldigheid',
    upgradePlan: 'Upgrade Plan',
    addPlan: 'Voeg Plan Toe',
    serialNumber: 'Sr. Nr.',
    messages: 'Berichten',
    //
    active: 'Actief',
    priceDetails: 'Prijs Details',
    action: 'Actie',
    addOnChanges: 'Add-On Kosten: ',
    //
    perYear: 'per Jaar',
    taxes: 'Belastingen',
    placeholders: {
      enterNumberOfMesasges: 'Voer aantal berichten in',
      enterAmount: 'Voer Bedrag in',
      enterValidity: 'Voer Geldigheid in',
      searchByMessages: 'Zoeken op Berichten'
    },
    modal: {
      deleteAddOnsPlan: 'Verwijder Add-ons Plan',
      wantToDeleteThisAddOnsPlan: 'Weet u zeker dat u dit Add-ons Plan wilt verwijderen?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Voeg Whitelabel Kosten toe',
    checkoutExistingCharges: 'Bekijk bestaande kosten voor verschillende betaalmethoden en voeg nieuwe whitelabel kosten toe',
    currency: 'Valuta',
    chooseCurrency: 'Kies Valuta',
    amountPerBotPerMonth: 'Bedrag per bot per maand',
    cancel: 'Annuleren',
    upgradeChanges: 'Wijzig Kosten',
    addCharges: 'Voeg Kosten Toe',
    serialNumber: 'Sr. Nr.',
    //
    action: 'Actie',
    taxes: 'Belastingen',
    placeholders: {
      enterAmountBotPerMonth: 'Voer bedrag per bot per maand in',
      searchByCurrency: 'Zoeken op Valuta',
    },
    modal: {
      deleteCharges: 'Verwijder Kosten',
      wantToDeleteThisCharge: 'Weet u zeker dat u deze Kosten wilt verwijderen?',
    }
  },
  planManagement: {
    createNewPlans: 'Maak nieuwe plannen aan',
    checkoutYourCurrentPlan: 'Bekijk uw huidige plannen en voeg nieuwe plannen toe voor uw Klanten',
    choosePlan: 'Kies Plan',
    selectPlan: 'Selecteer Plan',
    baby: 'Baby',
    king: 'King',
    displayName: 'Weergavenaam',
    messageLimit: 'Bericht Limiet',
    enterAValidEmail: 'Voer een geldig e-mailadres in',
    setCurrency: 'Stel Valuta in',
    select: 'Selecteer',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Wis alles',
    apply: 'Toepassen',
    perMonth: '/Maand',
    perYear: '/Jaar',
    cancel: 'Annuleren',
    upgradePlan: 'Upgrade Plan',
    addPlan: 'Voeg Plan Toe',
    serialNumber: 'Sr. Nr.',
    plan: ' Plan',
    //
    messages: 'Berichten',
    pricing: 'Prijzen',
    actions: 'Acties',
    //
    perMonthText: 'Per Maand',
    perYearText: 'Per Jaar',
    placeholders: {
      enterDisplayName: 'Voer weergavenaam in',
      enterMessageLimit: 'Voer bericht limiet in',
      searchByPlanName: 'Zoeken op plannaam of berichten'
    },
    modal: {
      deletePlan: 'Plan Verwijderen',
      wantToDeleteThisBabyPlan: 'Het verwijderen van het Baby Plan zal invloed hebben op alle klanten. Weet je zeker dat je deze plan wilt verwijderen?',
      wantToDeleteThisPlan: 'Weet u zeker dat u dit plan wilt verwijderen?',
    },
    appTooltip: {
      chooseYourPlanType: 'Kies uw planningssoort',
      pleaseInputYourPlanDisplayName: 'Voer de weergavenaam van uw plan in',
      typeMessageLimitForCustomer: 'Typ berichtlimiet voor klant',
      selectYourPreferredCurrencies: 'Selecteer uw voorkeursvaluta',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Voeg Belastingen toe',
    checkoutExistingTaxes: 'Bekijk bestaande belastingen voor verschillende betaalmethoden en nieuwe belastingen',
    taxName: 'Belasting Naam',
    applicableOn: 'Toepasbaar op',
    choosePlans: 'Kies Plannen',
    chooseCurrency: 'Kies Valuta',
    percentage: 'Percentage',
    cancel: 'Annuleren',
    //
    serialNumber: 'Sr. Nr.',
    name: 'naam',
    //
    currency: 'Valuta',
    updateTaxes: 'Wijzig Belastingen',
    actions: 'Acties',
    modal: {
      deleteTaxes: 'Verwijder Belastingen',
      wantToDeleteThisTax: 'Weet u zeker dat u deze belastingen wilt verwijderen?'
    },
    placeholders: {
      taxName: 'Belasting Naam',
      taxPercentage: 'Belasting Percentage',
      searchByCurrency: 'Zoeken op Valuta',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Nieuwe plannen maken',
    checkoutYourCurrentAndNewPlans: 'Bekijk uw huidige plannen en voeg nieuwe plannen toe voor uw klanten',
    choosePlan: 'Kies Plan',
    selectPlan: 'Selecteer Plan',
    baby: 'Baby',
    king: 'King',
    displayName: 'Weergavenaam',
    setCurrency: 'Valuta instellen',
    select: 'Selecteer',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Alles wissen',
    apply: 'Toepassen',
    perMonth: '/Maand',
    perYear: '/Jaar',
    cancel: 'Annuleren',
    addPlan: 'Plan Toevoegen',
    serialNumber: 'Nr. ',
    plan: 'Plan',
    pricing: 'Prijsstelling',
    actions: 'Acties',
    perMonthText: 'Per Maand',
    perYearText: 'Per Jaar',
    updatePlan: 'Plan bijwerken',
    //
    messageLimit: 'Gesprekslimiet',
    enterAValidMessageLimit: 'Voer een geldige gesprekslimiet in',
    messages: 'Gesprekken',
    placeholders: {
      enterDisplayName: 'Voer weergavenaam in',
      enterMessageLimit: 'Voer de gesprekslimiet in',
      searchByPlanName: 'Zoeken op Plan Naam of Gesprekken',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Voer de gesprekslimiet in voor de klant',
      chooseYourPlanType: 'Kies uw planningssoort',
      selectYourPreferredCurrencies: 'Selecteer uw voorkeursvaluta',
      pleaseInputYourPlanDisplayName: 'Voer de weergavenaam van uw plan in',
    },
    modal: {
      deletePlan: 'Plan Verwijderen',
      wantToDeleteThisBabyPlan: 'Het verwijderen van het Baby Plan zal invloed hebben op alle klanten. Weet je zeker dat je deze plan wilt verwijderen?',
      areYouSureYouWantToDeleteThisPlan: 'Weet u zeker dat u dit plan wilt verwijderen?',
    }
  }
};
