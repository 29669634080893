import {createAction, props} from '@ngrx/store';

export const loadTokens = createAction(
  '[Token] Load Tokens',
  props<{tokens: any}>()
);
export const addToken = createAction(
  '[Token] Add Token',
  props<{key: string, token: string}>()
);
export const deleteToken = createAction(
  '[Token] Delete Token',
  props<{key: string}>()
);
export const updateToken = createAction(
  '[Token] Update Token',
  props<{key: string, token: string | any}>()
);




