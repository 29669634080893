import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  // colorsList = ['#EF9A9A', '#E47373', '#C62828', '#F06291', '#AD1357', '#CE92D8', '#691B99', '#512DA8',
  //   '#1664C0', '#8FC9F9', '#035F64', '#4CD0E1', '#004D3F', '#26A69A', '#F57E16',
  //   '#FDD736', '#5BC15B', '#F9A725', '#F8BACF', '#B3B3B3'];
  colorsList = ['#00cbff', '#ff8800', '#ff0000', '#f700ff', '#226cf4', '#14c800', '#000000', '#C62828'];
  gradientColorsList = ['#00cbff', '#ff8800', '#000000'];

  @Input() themeColor = '#1664C0';
  isCustomColorSelected = false;
  @Output() selectedColor = new EventEmitter<any>();
  @Input() isGradientColorsList = false;


  constructor() { }

  ngOnInit(): void {
    if (!this.colorsList.includes(this.themeColor)) {
      this.colorsList[0] = this.themeColor;
    }
    if (!this.gradientColorsList.includes(this.themeColor)) {
      this.gradientColorsList[0] = this.themeColor;
    }
  }


  changeColor(event, name) {
    this.isCustomColorSelected = true;
    name === 'customColor' ? this.themeColor = event : this.themeColor = name;
    this.selectedColor.emit(this.themeColor);
    if (name === 'customColor') {
      this.colorsList[0] = this.themeColor;
      this.gradientColorsList[0] = this.themeColor;
    }
  }

}
