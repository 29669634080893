export const analytics = {
  botWise: {
    botWiseAnalytics: 'Análisis de Bot-Wise',
    currentBillingCycle: 'Ciclo de facturación actual',
    currentMonth: 'Mes actual',
    sinceAccountCreation: 'Desde la creación de cuentas',
    messages: 'Mensajes',
    leads: 'Dirige',
    noDataFound: {
      botwiseAnalytics: 'Análisis de Bot-Wise',
      noBotHasBeenCreatedYet: '¡Aún no se ha creado bot!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Resumir datos de mensajes y contactos/clientes potenciales para bots específicos para el ciclo de facturación actual, mes actual o desde la creación de cuentas (actualice cada medianoche)',
      TOOLTIP_FILTERS: 'Filtro de datos para bot seleccionado',
      TOOLTIP_MESSAGES: 'Mensajes totales consumidos por su cuenta desde la creación de la cuenta',
      TOOLTIP_LEADS: 'Total de clientes potenciales capturados por bots para su cuenta desde la creación de la cuenta.',
    }
  },
  overview: {
    analytics: 'Analítica',
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    overview: 'Descripción general',
    messages: 'Mensajes',
    leads: 'Dirige',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Análisis para todos los mensajes y clientes potenciales de los bots. (Datos actualizados cada medianoche)',
    }
  },
  summary: {
    analyticsSummary: 'Resumen de análisis',
    allBots: 'Todos los bots',
    daily: 'A diario',
    weekly: 'Semanalmente',
    monthly: 'Mensual',
    messages: 'Mensajes',
    leads: 'Dirige',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Resumir datos diarios, semanales o mensuales de mensajes y contactos/clientes potenciales para los bots específicos (se actualice cada medianoche)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'Análisis de whatsapp',
      month: 'Mes',
      week: 'Semana',
      today: 'Hoy',
      filters: 'Filtros',
      clearAll: 'Limpiar todo',
      apply: 'Aplicar',
      totalConversations: 'Conversaciones totales',
      freeConversations: 'Conversaciones gratuitas',
      paidConversations: 'Conversaciones pagadas',
      messageSent: 'Mensaje enviado',
      messageDelivered: 'Mensaje enviado',
      bpDropDown: {
        selectBot: 'Seleccionar bot',
      },
      indexMergeDatePicker: {
        startDate: 'Fecha de inicio',
        endDate: 'Fecha final',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Análisis de conversación',
      month: 'Mes',
      week: 'Semana',
      today: 'Hoy',
      totalConversations: 'Conversaciones totales',
      paidConversations: 'Conversaciones pagadas',
      freeConversations: 'Conversaciones gratuitas',
      indexMergeDatePicker: {
        startDate: 'Fecha de inicio',
        endDate: 'Fecha final',
      },
      bpDropDown: {
        allBots: 'Todos los bots',
      }
    },
    messageAnalytics: {
      whatsappMessages: 'Mensajes de WhatsApp',
      sent: 'Enviado',
      delivered: 'Entregado',
      indexMergeDatePicker: {
        startDate: 'Fecha de inicio',
        endDate: 'Fecha final',
      },
      bpDropDown: {
        allBots: 'Todos los bots',
      },
      month: 'Mes',
      week: 'Semana',
      today: 'Hoy',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'Mensajes de WhatsApp por estado',
      sent: 'Enviado',
      delivered: 'Entregado',
      indexMergeDatePicker: {
        startDate: 'Fecha de inicio',
        endDate: 'Fecha final',
      },
      bpDropDown: {
        allBots: 'Todos los bots',
      },
      month: 'Mes',
      week: 'Semana',
      today: 'Hoy',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Todos los suscriptores',
      whatsappSubscribers: 'Suscriptores de whatsapp',
    }
  }
};
