export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'Um link de ativação foi enviado, verifique seu e -mail',
    weHaveSentActivationLinkToEmailAddress: 'Enviamos um link de ativação para o endereço de e -mail',
    toVerifyYourAccount: 'Para verificar sua conta.',
    onceVerifiedAutomaticallyRedirected: 'Após verificação, você será redirecionado automaticamente para o painel.',
    didNotReceiveAnEmail: 'você não recebeu um email? Verifique sua pasta de spam',
    resendIn: 'Reenviar',
    seconds: ' segundos',
    resend: 'Reenviar',
    backToSignUp: 'Voltar para se inscrever',
    greatPleaseCheckYourMessage: 'Ótimo, verifique seu e -mail',
    weHaveSentEmailTo: 'Enviamos um e -mail para',
    withALinkToReset: ' com um link para redefinir sua conta. Uma vez ativado, você poderá finalizar a criação da sua conta e iniciar sua avaliação gratuita.',
    resent: 'Ressentir -se',
    backToLogin: 'Volte ao login',
  },
  forgotPassword: {
    forgotPassword: 'Esqueceu sua senha?',
    noWorries: 'Sem problemas,',
    weWillSendYouResetPasswordInstructions: 'Enviaremos uma instrução de senha de redefinição',
    workEmail: 'Email de trabalho',
    continue: 'Continuar',
    backToLogin: 'Volte ao login',
    placeholders: {
      enterYourEmail: 'Digite seu e-mail',
    }
  },
  logout: {
    logout: 'Sair',
    wantToLogOut: 'Tem certeza que deseja sair?',
    yesIWillBeBack: 'Sim, estarei de volta',
    cancel: 'Cancelar',
  },
  newPassword: {
    createNewPassword: 'Crie nova senha',
    yourNewPasswordMustBeDifferent: 'Sua nova senha deve ser diferente das senhas usadas anteriormente.',
    password: 'Senha',
    confirmPassword: 'Confirme sua senha',
    setPassword: 'Configurar senha',
    signUp: 'Inscrever-se',
    login: 'Conecte-se',
    backTo: 'De volta a',
    setAPassword: 'Defina uma senha',
    setNewPasswordToYourAccount: 'Defina uma nova senha para sua conta para fins de segurança e privacidade',
    backToSignUp: 'Voltar para se inscrever',
    placeholders: {
      enterYourPassword: 'Coloque sua senha',
    }
  },
  resetPassword: {
    passwordReset: 'Redefinição de senha',
    yourPasswordHasBeenSuccessfullyReset: 'Sua senha foi redefinida com sucesso.',
    clickBelowToLogIn: 'Clique abaixo para fazer login',
    continueToLogin: 'Continue a fazer login',
  },
  newSignUp: {
    welcomeBack: 'Bem vindo de volta',
    to: 'para',
    logIn: 'Conecte-se',
    as: 'como',
    withFacebook: 'com o Facebook',
    logInWithGoogle: 'Faça login com o Google',
    or: 'OU',
    pleaseFillAValidWorkEmail: 'Por favor, preencha um e -mail de trabalho válido*',
    workEmailIsRequired: 'O email de trabalho é necessário*',
    pleaseFillAValidPassword: 'Por favor, preencha uma senha válida*',
    passwordIsRequired: 'Senha requerida*',
    rememberMe: 'Lembre de mim',
    forgotPassword: 'Esqueceu sua senha?',
    login: 'Conecte-se',
    doNotHaveAnAccount: 'Não possui uma conta?',
    setUpAFreeAccount: 'Configure uma conta gratuita',
    noCreditCardRequired: 'Não é necessário cartão de crédito',
    cancelAnytime: 'Cancelar a qualquer hora',
    signUp: 'Inscrever-se',
    signUpWithGoogle: 'Inscreva -se com o Google',
    emailIsAlreadyRegistered: 'O email já está registrado conosco, clique em fazer login para continuar',
    phoneNumberShouldHaveMinimum7Digits: 'O número de telefone deve ter no mínimo 7 dígitos*',
    phoneNumberIsRequired: 'O número de telefone é necessário*',
    passwordMustContain: 'A senha deve conter',
    atleast6Characters: 'pelo menos 6 caracteres,',
    oneUpperCaseLetter: '1 letra maiúscula',
    oneSpecialCharacter: '1 personagem especial*',
    alreadyHaveAccount: 'já tem uma conta?',
    signingUpToBotPenguinIsAConsentToOur: 'Inscrever -se em uma conta BotPenguin é o seu consentimento para o nosso',
    privacyPolicy: 'política de Privacidade',
    termsAndConditions: 'Termos e Condições',
    and: ' e',
    english: 'Inglês',
    hindi: 'hindi',
    german: 'Alemão',
    french: 'Francês',
    portuguese: 'Português',
    spanish: 'Espanhol',
    italian: 'italiano',
    russian: 'russo',
    placeholders: {
      workEmail: 'Email de trabalho*',
      password: 'Senha*',
      phone: 'Telefone*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Cancelamento bem-sucedido',
    yourEmail: 'Seu email ',
    haveBeenSuccessfullyUnsubscribedFrom: ' foi cancelado com sucesso de',
    backToLogin: 'Voltar para o Login'
  }
};
