export const team = {
  addMember: {
    team: 'צוות',
    seeHowToUse: 'ראה כיצד להשתמש',
    help: 'עזרה',
    addAMember: 'הוסף חבר',
    addYourTeamMembersText: 'הוסף את חברי הצוות שלך כסוכני תמיכה לשיחה חיה עם משתמשי הבוט.',
    name: 'שם',
    nameIsRequired: 'שם הוא שדה חובה',
    email: 'אימייל',
    enterAValidEmail: 'הזן כתובת אימייל חוקית',
    password: 'סיסמה',
    passwordMustContain: 'הסיסמה חייבת לכלול',
    atleast6Characters: 'לפחות 6 תווים,',
    oneUpperCaseLetter: '1 אות גדולה',
    oneSpecialCharacter: '1 תו מיוחד*',
    profileIcon: 'צלמית פרופיל',
    whatsappNumber: 'מספר WhatsApp',
    leadVisibility: 'ראות ליד',
    addMember: 'הוסף חבר',
    role: 'תפקיד',
    createdOn: 'נוצר בתאריך',
    action: 'פעולה',
    updateMember: 'עדכן חבר',
    add: 'הוסף',
    cancel: 'ביטול',
    selectVisibilityOption: 'בחר אפשרות ראות',
    all: 'הכל',
    assigned: 'משוייך',
    nA: 'לא ידוע',
    admin: 'מנהל',
    agent: 'סוכן',
    placeholders: {
      enterMemberName: 'הזן את שם החבר',
      enterMemberEmailAddress: 'הזן כתובת אימייל של החבר',
      createPasswordForMember: 'צור סיסמה עבור החבר',
      searchCountry: 'חיפוש במדינה',
      enterWhatsappNumber: 'הזן מספר WhatsApp',
      selectLeadsVisibility: 'בחר ראות ידים',
      searchTeamByNameOrEmail: 'חפש חבר צוות לפי שם או אימייל',
    },
    modal: {
      deleteTeamMember: 'מחק חבר צוות',
      wantToDeleteThisTeamMember: 'האם אתה בטוח שברצונך למחוק חבר צוות זה?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'לחץ כדי להוסיף חבר צוות חדש',
      profileIconOfTeamMember: 'סמל הפרופיל / התמונה של חבר הצוות',
      nameOfTeamMember: 'שם חבר הצוות',
      emailOfTeamMember: 'כתובת האימייל של חבר הצוות',
      whatsappNumberOfTeamMember: 'מספר ה-WhatsApp של חבר הצוות',
      roleOfTeamMemberAsAdminOrAgent: 'תפקיד חבר הצוות כמנהל / סוכן',
      dateAndTimeOfCreatingTeamMemberRole: 'תאריך ושעת יצירת תפקיד חבר הצוות',
      editOrDeleteTeamMember: 'ערוך / מחק חבר צוות',
      editTeamMembersDetails: 'ערוך את פרטי חבר הצוות',
      removeAccessOfTeamMemberByDeletingAccount: 'הסר את הגישה של חבר הצוות על ידי מחיקת החשבון',
    }
  },
  users: {
    manageMembers: 'ניהול חברים',
    managerMembersWhichCanAccessPanel: 'ניהול צוות השותפים שיכול לגשת ללוח המחוונים שלך',
    createNewMember: 'צור חבר חדש',
    createMember: 'צור חבר',
    updateMember: 'עדכן חבר',
    deleteTitleMember: 'מחק חבר',
    deleteDescriptionMember: 'האם אתה בטוח שברצונך למחוק חבר זה?',
    noMemberFound: 'לא נמצא חבר!',
    titleMember: 'צור חבר',
    manageUsers: 'ניהול משתמשים',
    createNewUser: 'צור משתמש חדש',
    managerUserWhichCanAccessPanel: 'נהל משתמש שיכול לגשת ללוח שלך',
    phoneNumber: 'מספר טלפון',
    discard: 'ביטול',
    createUser: 'צור משתמש',
    updateUser: 'עדכן משתמש',
    noUserFOund: 'לא נמצאו משתמשים!',
    title: 'צור משתמש חדש',
    profileIcon: 'צלמית פרופיל*',
    name: 'שם*',
    email: 'אימייל*',
    password: 'סיסמה*',
    selectRole: 'בחר תפקיד*',
    selectARole: 'בחר תפקיד',
    selectLeadVisibility: 'בחר ראות ידיים*',
    confirmPassword: 'אשר סיסמה*',
    tableHeadingName: 'שם',
    tableHeadingEmail: 'אימייל',
    tableHeadingPhoneNumber: 'מספר טלפון',
    tableHeadingRole: 'תפקיד',
    tableHeadingStatus: 'סטטוס',
    tableHeadingAction: 'פעולה',
    deleteTitle: 'מחיקת תפקיד',
    deleteDescription: 'אזהרה: כאשר אתה מוחק את התפקיד הזה, ימחקו גם כל המשתמשים שמשוייכים לתפקיד זה.\nהאם אתה בטוח שברצונך למחוק את התפקיד הזה?',
    placeholders: {
      searchHere: 'חפש כאן',
      enterName: 'הזן שם',
      enterEmail: 'הזן אימייל',
      searchCountry: 'חיפוש במדינה',
      enterPhoneNumber: 'הזן מספר טלפון',
      enterPassword: 'הזן סיסמה',
      enterConfirmPassword: 'הזן אימות לסיסמה',
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'מחק משתמשים',
      TOOLTIP_DELETE_MEMBERS: 'מחק חברים'
    }
  },
  roles: {
    manageRoles: 'ניהול תפקידים',
    manageYourRoles: 'נהל את התפקידים שלך.',
    name: 'שם*',
    description: 'תיאור',
    discard: 'ביטול',
    createRole: 'צור תפקיד',
    creteARole: 'צור תפקיד',
    updateRole: 'עדכן תפקיד',
    noUserFound: 'לא נמצאו תפקידים משתמשים!',
    assignPermissions: 'הקצאת הרשאות*',
    tableHeadingName: 'שם',
    tableHeadingDescription: 'תיאור',
    tableHeadingStatus: 'סטטוס',
    tableHeadingAction: 'פעולה',
    module: 'מודול',
    read: 'קריאה',
    write: 'כתיבה',
    remove: 'הסרה',
    notes: 'הערות',
    deleteTitle: 'מחק תפקיד',
    deleteDescription: 'אזהרה: כאשר אתה מוחק את התפקיד הזה, ימחקו גם כל המשתמשים שמשוייכים לתפקיד זה.\nהאם אתה בטוח שברצונך למחוק את התפקיד הזה?',
    deleteMemberDescription: 'אזהרה: כאשר אתה מוחק תפקיד זה, זה גם ימחוק את כל החברים שמוקצים לתפקיד זה. האם אתה בטוח שאתה רוצה למחוק תפקיד זה?',
    placeholders: {
      enterRoleName: 'הזן את שם התפקיד',
      searchHere: 'חפש כאן',
      enterDescriptionForRole: 'הזן תיאור לתפקיד',
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'מקטעים שבאפשרותך להתיר או לחסום ממשתמשים לגשת אליהם',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'לחץ כדי להוסיף תפקיד חדש',
    }
  },
  accessControl: {
    users: 'משתמשים',
    members: 'חברים',
    partnerTeam: 'צוות שותפים',
    roles: 'תפקידים'
  }
};
