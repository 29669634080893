import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccessControl } from '../models/accessControl';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  permissions: BehaviorSubject<AccessControl> = new BehaviorSubject({ fetched: false, resources: [] });
  constructor(public networkService: NetworkService) { }

  async getRoles(currentPage = 1, ispagination = false, searchText = '') {
    return this.networkService.httpRequest(`role?page=${currentPage}&ispagination=${ispagination}&searchText=${searchText}`,
      undefined, 'GET', true);
  }
  async addRole(payload) {
    return this.networkService.httpRequest('role', payload, 'POST', true);
  }

  async updateRole(id, payload) {
    return this.networkService.httpRequest(`role/${id}`, payload, 'PUT', true);
  }

  async deleteRole(id) {
    return this.networkService.httpRequest(`role/${id}`, undefined, 'DELETE', true);
  }

  async getPermissions() {
    return this.networkService.httpRequest('permissions', undefined, 'GET', true);
  }
  async addCustomUser(payload) {
    return this.networkService.httpRequest('custom-user', payload, 'POST', true);
  }
  async getCustomUser(currentPage = 1, ispagination = false, searchText = '') {
    return this.networkService.httpRequest(
      `custom-user?page=${currentPage}&ispagination=${ispagination}&searchText=${searchText}`,
      undefined, 'GET', true
    );
  }
  async deleteCustomUser(payload) {
    return this.networkService.httpRequest('custom-user', payload, 'DELETE', true);
  }

  async updateCustomUser(id, payload) {
    return this.networkService.httpRequest(`custom-user/${id}`, payload, 'PUT', true);
  }
}


