import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationComponent } from './notification.component';
import { NotificationSettingComponent } from './components/notification-setting/notification-setting.component';
import { UnsavedWarningGuard } from 'src/appv2/guards/unsaved-warning.guard';

const routes: Routes = [{
  path: '', component: NotificationComponent, children: [
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    { path: 'list', component: NotificationListComponent, canActivate: [UnsavedWarningGuard] },
    { path: 'setting', component: NotificationSettingComponent }
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationRoutingModule { }
