import {Action, createReducer, on} from '@ngrx/store';
import {addToken, deleteToken, loadTokens, updateToken} from '../actions/tokens.action';
import Tokens from '../../models/tokens';

export const tokenFeatureKey = 'token';


export const initialState: Tokens = {accessToken: ''};

const tokenReducer = createReducer(
  initialState,
  on(loadTokens, (state, {tokens}) => tokens),
  on(addToken, (state, {key, token}) => ({...state, [key]: token})),
  on(deleteToken, (state, {key}) => ({...state, [key]: undefined})),
  on(updateToken, (state, {key, token}) => ({...state, [key]: token})),
);

export function reducer(state: Tokens, action: Action) {
  return tokenReducer(state, action);
}
