export const whitelabel = {
  plans: 'プラン',
  managePlan: 'プランの管理',
  whitelabelCharges: 'ホワイトラベル料金',
  chargesOrTaxesConfiguration: '料金/税の設定',
  websitePlans: 'ウェブサイトプラン',
  whatsappPlansHeader: 'WhatsAppプラン',
  addOnPlans: {
    createAddOnPlans: 'アドオンプランの作成',
    checkoutYourCurrentMessage: '現在のメッセージアドオンプランとお客様のための新しいプランを確認してください',
    choosePlanValidity: 'プランの有効期限を選択',
    chooseValidity: '有効期限を選択',
    oneYear: '1年',
    billingCycle: '請求サイクル',
    numberOfMessages: 'メッセージ数',
    pleaseEnterNumberOfMessages: 'メッセージ数を入力してください',
    setCurrency: '通貨を設定',
    select: '選択',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'すべてクリア',
    apply: '適用',
    currency: '通貨',
    amount: '金額',
    validity: '有効期限',
    upgradePlan: 'プランを更新',
    addPlan: 'プランを追加',
    serialNumber: '連番',
    messages: 'メッセージ',
    // validity: '有効期限',
    active: 'アクティブ',
    priceDetails: '価格の詳細',
    action: 'アクション',
    addOnChanges: 'アドオン料金: ',
    // messages: ' メッセージ',
    perYear: '年ごと',
    taxes: '税金',
    placeholders: {
      enterNumberOfMesasges: 'メッセージ数を入力してください',
      enterAmount: '金額を入力してください',
      enterValidity: '有効期限を入力してください',
      searchByMessages: 'メッセージで検索',
    },
    modal: {
      deleteAddOnsPlan: 'アドオンプランを削除',
      wantToDeleteThisAddOnsPlan: 'このアドオンプランを削除してもよろしいですか？',
    }
  },
  charges: {
    addWhitelabelCharges: 'ホワイトラベル料金を追加',
    checkoutExistingCharges: '異なる支払方法での既存の料金を確認し、新しいホワイトラベル料金を追加します',
    currency: '通貨',
    chooseCurrency: '通貨を選択',
    amountPerBotPerMonth: 'ボットごとの月額金額',
    cancel: 'キャンセル',
    upgradeChanges: '料金を更新',
    addCharges: '料金を追加',
    serialNumber: '連番',
    // currency: '通貨',
    action: 'アクション',
    taxes: '税金',
    placeholders: {
      enterAmountBotPerMonth: 'ボットごとの月額金額を入力してください',
      searchByCurrency: '通貨で検索',
    },
    modal: {
      deleteCharges: '料金を削除',
      wantToDeleteThisCharge: 'この料金を削除してもよろしいですか？',
    }
  },
planManagement: {
  createNewPlans: '新しいプランを作成',
  checkoutYourCurrentPlan: '現在のプランを確認し、お客様のために新しいプランを追加してください',
  choosePlan: 'プランを選択',
  selectPlan: 'プランを選択',
  baby: 'ベビー',
  king: 'キング',
  displayName: '表示名',
  messageLimit: 'メッセージ制限',
  enterAValidEmail: '有効なメールアドレスを入力してください',
  setCurrency: '通貨を設定',
  select: '選択',
  inr: 'INR',
  usd: 'USD',
  cad: 'CAD',
  clearAll: 'すべてクリア',
  apply: '適用',
  perMonth: '/月',
  perYear: '/年',
  cancel: 'キャンセル',
  upgradePlan: 'プランを更新',
  addPlan: 'プランを追加',
  serialNumber: 'シリアル番号',
  plan: ' プラン',
  messages: 'メッセージ',
  pricing: '価格',
  actions: 'アクション',
  perMonthText: '月ごと',
  perYearText: '年ごと',
  placeholders: {
    enterDisplayName: '表示名を入力してください',
    enterMessageLimit: 'メッセージ制限を入力してください',
    searchByPlanName: 'プラン名で検索',
  },
  modal: {
    deletePlan: 'プランを削除',
    wantToDeleteThisPlan: 'このプランを削除してもよろしいですか？',
  },
  appTooltip: {
    chooseYourPlanType: 'プランタイプを選択してください',
    pleaseInputYourPlanDisplayName: 'プランの表示名を入力してください',
    typeMessageLimitForCustomer: 'お客様のメッセージ制限を入力してください',
    selectYourPreferredCurrencies: 'お好みの通貨を選択してください',
  }
},
taxesConfiguration: {
  addTaxes: '税金を追加',
  checkoutExistingTaxes: '異なる支払方法と新しい税金の既存の税金を確認する',
  taxName: '税名',
  applicableOn: '適用対象',
  choosePlans: 'プランを選択',
  chooseCurrency: '通貨を選択',
  percentage: 'パーセンテージ',
  cancel: 'キャンセル',
  serialNumber: 'シリアル番号',
  name: '名前',
  currency: '通貨',
  updateTaxes: '税金を更新',
  actions: 'アクション',
  modal: {
    deleteTaxes: '税金を削除',
    wantToDeleteThisTax: 'この税金を削除してもよろしいですか？',
  },
  placeholders: {
    taxName: '税名',
    taxPercentage: '税のパーセンテージ',
    searchByCurrency: '通貨で検索',
  }
},
whatsappPlans: {
  createNewPlans: '新しいプランを作成',
  checkoutYourCurrentAndNewPlans: '現在のプランを確認し、お客様のために新しいプランを追加してください',
  choosePlan: 'プランを選択',
  selectPlan: 'プランを選択',
  baby: 'ベビー',
  king: 'キング',
  displayName: '表示名',
  messageLimit: 'メッセージ制限',
  enterAValidMessageLimit: '有効なメッセージ制限を入力してください',
  setCurrency: '通貨を設定',
  select: '選択',
  inr: 'INR',
  usd: 'USD',
  cad: 'CAD',
  clearAll: 'すべてクリア',
  apply: '適用',
  perMonth: '/月',
  perYear: '/年',
  cancel: 'キャンセル',
  addPlan: 'プランを追加',
  serialNumber: 'シリアル番号',
  plan: 'プラン',
  messages: 'メッセージ',
  pricing: '価格',
  actions: 'アクション',
  perMonthText: '月ごと',
  perYearText: '年ごと',
  updatePlan: 'プランを更新',
  // addPlan: 'プランを追加',
  placeholders: {
    enterDisplayName: '表示名を入力してください',
    enterMessageLimit: 'メッセージ制限を入力してください',
    searchByPlanName: 'プラン名で検索',
  },
  appTooltip: {
    typeMessageLimitForCustomer: 'お客様のメッセージ制限を入力してください',
    chooseYourPlanType: 'プランタイプを選択してください',
    selectYourPreferredCurrencies: 'お好みの通貨を選択してください',
    pleaseInputYourPlanDisplayName: 'プランの表示名を入力してください',
  },
  modal: {
    areYouSureYouWantToDeleteThisPlan: 'このプランを削除してもよろしいですか？',
  }
}
};



