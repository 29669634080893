export const developer = {
  developers: 'المطورين',
  enpowerYourDevelopersToHarnessTheFullPotential: 'مكن مطوريك لاستغلال الإمكانات الكاملة لوحة التحكم الخاصة بك',
  accessToken: 'رمز الوصول',
  yourAPI: 'APIs الخاصة بك',
  api: {
    apiManagement: 'إدارة APIs',
    apiManagementSubHeading: 'إضافة وتكوين واختبار وإدارة الAPIs التي يمكنك استخدامها لتشغيل تدفقات الدردشة الخاصة بك.',
    addApi: 'إضافة API',
    createApi: 'إنشاء API',
    noApiFound: 'لم يتم العثور على APIs!',
    deleteApis: 'حذف الAPIs',
    areYouSureYouWantToDeleteAPI: 'هل أنت متأكد أنك تريد حذف الـAPI؟',
    placeholders: {
      search: `البحث بواسطة اسم الـAPI`
    },
    tableHead: {
      method: 'الطريقة',
      apiName: 'اسم الAPI',
      url: 'رابط',
      actions: 'إجراءات'
    },
    parametersTab: 'المعلمات',
    headersTab: 'الرؤوس',
    bodyTab: 'جسم الرسالة',
    manageApiResponses: 'إدارة ردود الAPI',
    chooseVariableToSave: 'اختر المتغير للحفظ',
    selectCustomAttribute: 'حدد السمة المخصصة',
    subTab: {
      key: 'المفتاح',
      enterKey: 'أدخل المفتاح',
      value: 'القيمة',
      enterValue: 'أدخل القيمة',
      addAnotherField: 'أضف حقل آخر',
      save: 'حفظ',
      useslashToInsertCustomVariables: `استخدم '/' لإدراج المتغيرات المخصصة أو القيم الديناميكية من رد المستخدم.`,
      response: 'الرد',
      apiRequestBody: 'جسم طلب الAPI',
      hit: 'ضربة',
      test: 'اختبار',
      buttonToGetAResponse: 'زر للحصول على استجابة',
      variable: 'متغير',
      saveToCustomAttribute: 'حفظ في السمة المخصصة'
    },
    apiLogs: {
      apiManagement: 'إدارة APIs',
      addConfigureTestAndManageYourAPIs: 'إضافة وتكوين واختبار وإدارة الAPIs التي يمكنك استخدامها لتشغيل تدفقات الدردشة الخاصة بك.',
      searchHere: 'البحث حسب رمز الحالة',
      deleteApis: 'حذف الAPIs',
      testAnApi: 'اختبار API',
      noAPILogFound: 'لم يتم العثور على سجل API',
      responseCode: 'رمز الاستجابة',
      message: 'رسالة',
      apiResponse: 'استجابة API',
      actions: 'إجراءات',
      deleteAPIs: 'حذف الAPIs',
      areYouSureYouWantToDelete: 'هل أنت متأكد أنك تريد حذف هذا الAPI؟',
      apiLogs: `سجلات الواجهة البرمجية (API)`,
      subTitle: `سجل مفصل لجميع طلبات الواجهة البرمجية والاستجابات التي تم تشغيلها داخل تدفق الدردشة للروبوت المحادثي.`
    }
  },
  configApi: {
    configureApi: `تكوين API`,
    configureApiSubHeading: `يمكنك تكوين API الخاص بك بناءً على JSON هنا واستخدامه في تحسين تدفق الدردشة الخاص بك`,
    apiLogs: `سجلات الAPI`,
    test: `اختبار`,
    manageApiResponse: `إدارة ردود الAPI`,
    body: `جسم الرسالة`,
    headers: `الرؤوس`,
    parameters: `المعلمات`,
    placeholders: {
      enterApiName: `أدخل اسم الAPI`,
      enterApiUrl: `أدخل عنوان الAPI`
    }
  }

};
