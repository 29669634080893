import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import UpgradeModal from 'src/appv2/models/upgradeModal';
import { phone } from 'phone';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  currentBot: BehaviorSubject<any> = new BehaviorSubject(undefined);
  closePopups: Subject<void> = new Subject();
  cardTypeCheck: Subject<string> = new Subject();
  showLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showAnimation: BehaviorSubject<boolean> = new BehaviorSubject(true);
  openUpgradeModal: BehaviorSubject<UpgradeModal> = new BehaviorSubject({ isUpgradeModal: false });
  isBabyPlan: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isDarkMode: BehaviorSubject<boolean> = new BehaviorSubject(true);
  unsavedWarning: BehaviorSubject<{
    apiInProgress?: boolean,
    openUnsavedDialog?: boolean,
    navigateUrl?: string
    isConfirm?: boolean,
    botId?: string
  }> = new BehaviorSubject({
    apiInProgress: false,
    openUnsavedDialog: false,
    isConfirm: false,
    botId: '',
    navigateUrl: ''
  });

  sideBarStatus: BehaviorSubject<'open' | 'collapsed'> = new BehaviorSubject('open');
  toast: Subject<{ title: string, message?: string, type: 'info' | 'success' | 'warning' | 'error' | 'custom' }> = new Subject();
  activeMenu: BehaviorSubject<
    'home' | 'bot' | 'inbox' | 'analytics' | 'team' | 'subscription' | 'notification' | 'settings' | 'logout' | 'customers' | 'agencyDashboard' | 'agencyActivities'
    | 'configurations' | 'feedbacks' | 'accessControl' | 'developer'
  > = new BehaviorSubject('home');

  plansDisplayName: BehaviorSubject<{ baby: string, king: string }> = new BehaviorSubject({ baby: '', king: '' });
  whatsappPlansDisplayName: BehaviorSubject<{ baby: string, king: string }> = new BehaviorSubject({ baby: '', king: '' });

  emailRegex =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{6,})/gm;

  selectedBotMeta: { _id: string; type: 'whatsapp' | 'instagram' | 'facebook' | 'telegram' | 'website' | '' } = { _id: '', type: '' };
  constructor() { }

  showToast(type: 'info' | 'success' | 'warning' | 'error' | 'custom', title: string, message: string = '') {

    if (title || message &&
      (
        !(title || '').toLowerCase().includes('failed') &&
        !(message || '').toLowerCase().includes('failed')
      )
    ) {
      this.toast.next({ message, type, title });
    }
  }

  loader(action: 'start' | 'stop') {
    this.showLoader.next(action === 'start');
  }
  checkCardType(cardType: string) {
    this.cardTypeCheck.next(cardType);
  }

  numberWithCommas(x, fix = false) {
    if (isNaN(x)) {
      return '';
    }

    if (fix && x) {
      const num = x.toFixed(0);
      return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  getLowerCase(data) {
    if (data) {
      return data.toLowerCase();
    }
  }

  getWhiteLabelledFlow(questionsList, brandName) {
    return questionsList.map(question => {
      return {
        ...question,
        label: question.label.replace(/botpenguin/gi, brandName)
      };
    });
  }

  toTitleCase(str) {
    return (str || '').toLowerCase().replace(/(^|\s)\w/g, (match) => {
      return match.toUpperCase();
    });
  }

  public formatDate(date, format = 'MMM Do, YYYY hh:mm A') {
    return moment
      .utc(date)
      .local()
      .format(format);
  }

  checkInput(event: any, type?: string, limit?: number): any {
    switch (type) {
      case 'number':
        if (
          (event.keyCode >= 35 && event.keyCode <= 40) ||
          event.keyCode === 46 ||
          event.keyCode === 13 ||
          event.keyCode === 8 ||
          event.keyCode === 9 ||
          event.keyCode === 27 ||
          event.keyCode === 110 ||
          event.keyCode === 190
        ) {
          return true;
        } else if (event.target.value.length === limit) {
          return false;
        } else {
          return (
            (event.keyCode >= 48 && event.keyCode < 58) ||
            (event.keyCode >= 96 && event.keyCode < 106)
          );
        }
      case 'alphanumeric':
        return (
          (event.keyCode >= 65 && event.keyCode <= 90) ||
          (event.keyCode >= 96 && event.keyCode < 106) ||
          (event.keyCode >= 48 && event.keyCode < 58) ||
          event.keyCode === 8 ||
          event.keyCode === 9 ||
          event.keyCode === 13 ||
          (event.keyCode >= 35 && event.keyCode <= 40) ||
          event.keyCode === 46 ||
          event.keyCode === 27
        );
      case 'name':
        return (
          (event.keyCode >= 65 && event.keyCode <= 90) ||
          event.keyCode === 8 ||
          event.keyCode === 32 ||
          event.keyCode === 9 ||
          event.keyCode === 13 ||
          (event.keyCode >= 35 && event.keyCode <= 40) ||
          event.keyCode === 46 ||
          event.keyCode === 27
        );
      default:
        return false;
    }
  }

  getPlanName(plan: { name: 'king' | 'baby'; displayName: string }) {
    return plan?.displayName || this.toTitleCase(plan?.name);
  }

  validateUrl = (url: string) => {
    const regex = /^(http:\/\/|https:\/\/)/;
    regex.test(url);

    if (regex.test(url)) {
      return url.endsWith('/') ? url.slice(0, -1) : url;
    }
    // Remove the slash from the end
    return url.endsWith('/') ? 'https://' + url.slice(0, -1) : 'https://' + url;

  }

  isPhoneNumberValid(prefix: string, phoneNumber: string) {
    const result = phone(`+${prefix}${phoneNumber}`);
    return result.isValid;
  }


}
