const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'こんにちは、',
  greetingHand: '👋',
  upgrade: 'アップグレード',
  integrate: '統合',
  integrate2: '統合',
  chatGPT: 'ChatGPT ',
  forYourBusiness: ' あなたのビジネスのため',
  insights: 'インサイト',
  message: 'メッセージ',
  addon: 'アドオン：',
  leads: 'リード',
  yourBots: 'あなたのボット',
  botName: 'ボット名',
  platform: 'プラットフォーム',
  contactOrLeads: '連絡先/リード',
  actions: 'アクション',
  edit: '編集',
  delete: '削除',
  createA: '新規作成：',
  whatsappChatbot: 'WhatsApp チャットボット ',
  connect: '接続',
  getStartedWithUs: '始めましょう 👏',
  howToBuildAGoodChatFlow: ' 良いチャットフローの構築方法',
  howToUseBotPenguinInboxAndItsFeatures: 'BotPenguin Inboxとその機能の使用方法',
  botPenguinPricing: 'BotPenguinの価格：プランと機能の詳細',
  openHelpDesk: 'ヘルプデスクを開く',
  helpAndSupport: 'ヘルプとサポート',
  extensiveGuides: 'すべての質問とワークフローに関する詳細なガイドは、次で利用できます',
  resourceCentre: 'リソースセンター。',
  checkoutOurAnswers: '以下の一般的な質問への回答を確認できます。',
  createANewBot: '新しいボットを作成する',
  installYourBot: 'ボットをインストールする',
  plansAndPricing: 'プランと価格',
  searchLanguage: '言語を検索',
  whatsappConversations: 'WhatsApp チャット',
  diveDeeperIntoBotPenguin: 'BotPenguinをさらに詳しく',
  knowWhereWeAreGoing: '私たちがどこに向かっているか、最新の更新情報、バグの報告をサポートしてください。',
  productUpdates: '製品の更新情報',
  requestAFeature: '機能のリクエスト',
  reportABug: 'バグの報告',
  productRoadmap: '製品ロードマップ',
  contactsOrLeads: '連絡先/リード',
  english: '英語',
  hindi: 'ヒンディー語',
  german: 'ドイツ語',
  french: 'フランス語',
  portuguese: 'ポルトガル語',
  spanish: 'スペイン語',
  italian: 'イタリア語',
  russian: 'ロシア語',
  pleaseSelectYourReason: '理由を選択してください',
  cancel: 'キャンセル',
  submit: '提出する',
  giveFeedback: 'フィードバックを提供',
  ratings: '評価',
  reasonBehindYourDecision: '決定の背後にある理由',
  shareYourExperience: 'あなたの経験を共有',
  reason1: '理由1',
  reason2: '理由2',
  appTooltip: {
    HOME_INSIGHT: 'すべてのボットのメッセージとリードに対する洞察を提供します（データは毎日深夜に更新されます）',
    HOME_MESSAGES: 'この課金サイクル中にアカウントで消費されたメッセージ数（WhatsApp、Facebook、Telegram Botからのメッセージは除外されます）',
    HOME_TOTAL_MESSAGES: 'アカウント作成以来、アカウントで消費された総メッセージ数（WhatsApp、Facebook、Telegram Botからのメッセージは除外されます）',
    LEADS: '課金サイクル中にアカウントのためにボットがキャプチャしたリード',
    TOTAL_LEADS: 'アカウント作成以来、ボットによってキャプチャされたアカウント/リードの総数（データは毎日深夜に更新されます）',
    YOUR_BOT: 'すべてのボットとその課金サイクル中の使用状況の概要',
    NAME: 'ボットの名前',
    PLATFORM: 'ボットのプラットフォーム',
    MESSAGES: 'アカウント作成以来のボットの総メッセージ数',
    CONTACTS: 'ボットの総連絡先/リード',
    ACTIONS: 'ボットを編集/削除',
    EDIT_ICON: 'ボットのチャットフローと設定を編集',
    DELETE_ICON: 'ボットを削除',
    TOOL_TIP_CLASSIC_VERSION: `古いバージョンの ${brand} UI`
  },
  modal: {
    deleteBot: 'ボットを削除',
    areYouSure: 'このボットを削除してもよろしいですか？',
    enquiryForm: '問い合わせフォーム',
    payNow: '今すぐ支払う',
    saveAndDelete: '保存して削除',
    submit: '送信',
  },
  noDataFound: {
    bots: 'ボット',
    noBotCreatedYet: 'まだボットが作成されていません！',
  },
  yourFriendWillMissYou: 'あなたの友達があなたを寂しく思うでしょう',
  rateYourBotCreationExperience: 'ボット作成の経験を評価してください',
  whatMadeYouDeleteYourBot: 'ボットを削除した理由は何ですか？',
  iCreatedByMistake: '誤って作成しました',
  iWantToCreateAnotherBot: '別のボットを作成したい',
  couldNotFindFeaturesThatINeeded: '必要な機能が見つかりませんでした',
  uiWasHardToNavigate: 'UIがナビゲートしにくかった',
  iDonotWantToTell: '教えたくない',
  other: 'その他',
  weWillDoOurBestToMakeAutomationFun: '自動化を楽しくかつシンプルにするために最善を尽くします。',
};
