export const notification = {
  notification: 'התראות',
  allNotifications: 'כל ההתראות',
  general: 'כללי',
  settings: 'הגדרות',
  newChatRequest: {
    youHaveANewChatRequest: 'יש לך בקשת צ\'אט חדשה!!!',
    youHaveTransferredChatRequest: 'יש לך בקשת צ\'אט שהועברה!!!',
    acceptRequest: 'אשר בקשה',
    discardRequest: 'סרב בקשה'
  },
  notificationList: {
    name: 'שם',
    message: 'הודעה',
    noDataFound: {
      notification: 'התראה',
      noNotificationsFound: 'לא נמצאו התראות!',
    }
  },
  notificationSetting: {
    userMessage: 'הודעת משתמש',
    receiveNotificationsWhenUserSendMessage: 'קבל התראות כל פעם שמשתמש מסוים שולח הודעה',
    liveChatRequest: 'בקשת צ\'אט חי',
    receiveNotificationsWhenUserRequestLiveChat: 'קבל התראות כאשר משתמש מבקש צ\'אט חי',
    newLead: 'ליד חדש',
    receiveNotificationsWhenNewLeadCreated: 'קבל התראות כאשר לידים חדשות נוצרות',
    assignedOnly: 'משוייכים בלבד',
    allUsers: 'כל המשתמשים',
    customizeWhichNotificationsYouWantToRecieve: 'התאם אילו התראות ברצונך לקבל',
    youCanCustomizeSettingForTheNotifications: 'באפשרותך להתאים את הגדרות ההתראות',
    receiveNotifications: 'קבל התראות',
    desktopNotifications: 'התראות בשולחן העבודה',
    mobilePushNotifications: 'התראות דחיפה לנייד',
    discard: 'בטל',
    update: 'עדכן',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'שלח התראות על הודעה חדשה דרך שולחן העבודה',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'שלח התראות על הודעה חדשה לנייד',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'שלח התראות על ליד חדש דרך שולחן העבודה',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'שלח התראות על ליד חדש לנייד',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'שלח התראות על בקשת צ\'אט חי דרך שולחן העבודה',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'שלח התראות על בקשת צ\'אט חי לנייד',
      TOOLTIP_RECEIVED_NOTIFICATION: 'בחר אילו הודעות ברצונך לקבל התראות עליהם'
    }
  }
};
