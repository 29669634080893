import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { NetworkService } from 'src/appv2/services/network.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  public botChange$: Subject<any> = new Subject<any>();
  private URL: any = 'facebook-automation/';
  newMessage$: Subject<any> = new Subject<any>();
  loadMoreChat: Subject<any> = new Subject<any>();
  setPage: Subject<any> = new Subject<any>();
  isQuickReplyComponentOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    public sharedService: SharedService,
    public networkService: NetworkService,
  ) { }

  getChatList(
    type: 'history' | 'active' | 'own-live',
    tag?: string | undefined,
    botId?: string | undefined,
    platform?: string | undefined,
    page?: any
  ) {
    return new Promise((resolve, reject) => {

      let url = `communication/chats${type === 'history' ? '' : ('/' + type)}?page=${page || 1}`;
      if (tag) {
        url += `&tag=${tag}`;
      }
      if (botId) {
        url += `&_bot=${botId}`;
      }
      if (platform) {
        url += `&platform=${platform}`;
      }

      this.sharedService.loader('start');
      this.networkService.httpRequest(url, null, 'GET', true)
        .then(data => {
          resolve(data);
        })
        .catch(reject)
        .finally(() => this.sharedService.loader('stop'));
    });
  }

  getChat(uuid) {
    return new Promise((resolve, reject) => {
      const url = 'communication/chats/' + uuid;
      this.networkService.httpRequest(url, null, 'GET', true)
        .then(data => {
          resolve(data);
        })
        .catch(reject);
    });
  }

  updateMessage(update) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('communication/chats', update, 'PUT', true)
        .then(data => {
          resolve(data);
        })
        .catch(reject);
    });
  }

  updateMeta(update) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('communication/chats/meta', update, 'PUT', true)
        .then(data => {
          resolve(data);
        })
        .catch(reject);
    });
  }

  getChatTags() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('communication/chats/tags')
        .then(data => {
          resolve(data);
        })
        .catch(reject);
    });
  }

  updateChatTag(tagData) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('communication/chats/tags', tagData, 'PUT', true)
        .then(response => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  getMessage(uuid: string) {
    return new Promise((resolve, reject) => {
      // this.sharedService.loader('start');
      // this.networkService.httpRequest('communication/messages?uuid=' + uuid, null, 'GET', true)
      //   .then(data => {
      //     resolve(data);
      //     this.sharedService.loader('stop');
      //   })
      //   .catch(reject);
    });
  }

  getConversations(payload) {
    // this.cos.ngxService.start();
    return new Promise((resolve, reject) => {
      this.networkService
        .httpRequest(
          this.URL + 'subscribers/'
          // `?bot_id=${payload.bot}&page_offset=${payload.offset}&platform=${payload.platform}`
        )
        .then((response) => {
          resolve(response);
          // this.cos.ngxService.stop();
        })
        .catch((error) => {
          reject(reject);
          // this.cos.ngxService.stop();
        });
    });
  }

  exploreChat(payload, operator = 'exact') {
    // this.cos.ngxService.startBackground();
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.URL, {
        chatbot_id: payload.bot,
        u_id: payload.u_id,
        platform: payload.platform,
        session_id: payload.session_id,
        operator
      }, 'POST')
        .then((response) => {
          resolve(response);
          // this.cos.ngxService.stopBackground();
        })
        .catch((error) => {
          reject(reject);
          // this.cos.ngxService.stopBackground();
        });
    });
  }

  scrollChat() {
    const elem = document.querySelector('.chating-list');
    const height = elem.scrollHeight;
    setTimeout(() => {
      elem.scrollTop = height;
    }, 200);
  }

  saveMessage(payload): void {
    this.networkService.httpRequest('communication/messages', payload, 'POST')
      .finally();
  }

  addReplies(whatsAppId, reply) {
    return this.networkService.httpRequest(`whatsapp-automation/bot/add-reply/${whatsAppId}`, { reply }, 'PUT');
  }

  transferToBot(payload) {
    return new Promise((resolve, reject) => {
      // this.cos.ngxService.start();
      this.networkService.httpRequest('communication/messages/transfer/bot', payload, 'PUT', true)
        .then(data => {
          resolve(data);
          // this.cos.ngxService.stop();
        })
        .catch(reject);
    });
  }

  transferToWhatsapp(payload) {
    return new Promise((resolve, reject) => {
      // this.cos.ngxService.start();
      this.sharedService.loader('start');
      this.networkService.httpRequest('communication/messages/transfer/whatsapp', payload, 'PUT', true)
        .then(data => {
          resolve(data);
          // this.cos.ngxService.stop();
        })
        .catch(reject)
        .finally(() => this.sharedService.loader('stop'));
    });
  }
}
