const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Hallo, ',
  greetingHand: '👋',
  upgrade: 'Upgrade',
  integrate: 'Integreer',
  integrate2: 'Integreer',
  chatGPT: 'ChatGPT ',
  forYourBusiness: 'voor jouw bedrijf',
  insights: 'Inzichten',
  message: 'Berichten',
  addon: 'Add-on: ',
  leads: 'Leads',
  yourBots: 'Jouw Bots',
  botName: 'Bot Naam',
  platform: 'Platform',
  contactOrLeads: 'Contacten/Leads',
  actions: 'Acties',
  edit: 'bewerk',
  delete: 'verwijder',
  createA: 'Maak een ',
  whatsappChatbot: 'WhatsApp Chatbot ',
  connect: 'Verbind',
  getStartedWithUs: 'Begin met ons 👏',
  howToBuildAGoodChatFlow: ' Hoe je een goede chatflow bouwt',
  howToUseBotPenguinInboxAndItsFeatures: ' Hoe je BotPenguin\'s Inbox en de functies ervan gebruikt',
  botPenguinPricing: ' BotPenguin\'s Prijzen: Verken Plannen en Functies',
  openHelpDesk: ' Open de Helpdesk',
  helpAndSupport: 'Hulp en ondersteuning',
  extensiveGuides: 'Uitgebreide gidsen voor al je vragen en workflows zijn beschikbaar in onze',
  resourceCentre: 'Kennisbank.',
  checkoutOurAnswers: 'Je kunt onze antwoorden op veelgestelde vragen hieronder bekijken.',
  createANewBot: 'Maak een nieuwe bot',
  installYourBot: 'Installeer jouw bot',
  plansAndPricing: 'Plannen en Prijzen',
  searchLanguage: 'Zoek Taal',
  whatsappConversations: 'WhatsApp Gesprekken',
  diveDeeperIntoBotPenguin: 'Duik dieper in BotPenguin',
  knowWhereWeAreGoing: 'Weet waar we naartoe gaan, de recente updates, en help ons wat bugs op te lossen.',
  productUpdates: 'Product Updates',
  requestAFeature: 'Vraag een functie aan',
  reportABug: 'Rapporteer een bug',
  productRoadmap: 'Product Roadmap',
  contactsOrLeads: 'Contacten/Leads',
  english: 'Engels',
  hindi: 'Hindi',
  german: 'Duits',
  french: 'Frans',
  portuguese: 'Portugees',
  spanish: 'Spaans',
  italian: 'Italiaans',
  russian: 'Russisch',
  pleaseSelectYourReason: 'Selecteer alsjeblieft je reden',
  cancel: 'Annuleer',
  submit: 'Verstuur!',
  giveFeedback: 'Geef feedback',
  ratings: 'Beoordelingen',
  reasonBehindYourDecision: 'Reden achter je beslissing',
  shareYourExperience: 'Deel je ervaring',
  reason1: 'reden1',
  reason2: 'reden2',
  appTooltip: {
    HOME_INSIGHT: 'Biedt inzicht in alle berichten en leads van jouw bots (Data wordt elke nacht bijgewerkt)',
    HOME_MESSAGES: 'Berichten verbruikt door jouw account tijdens deze factureringscyclus. (Berichten van WhatsApp, Facebook en Telegram Bot zijn uitgesloten.)',
    HOME_TOTAL_MESSAGES: 'Totaal aantal berichten verbruikt door jouw account sinds aanmaak (Berichten van WhatsApp, Facebook en Telegram Bot zijn uitgesloten.)',
    LEADS: 'Leads vastgelegd door bots voor jouw account tijdens deze factureringscyclus.',
    TOTAL_LEADS: 'Totaal aantal contacten/leads vastgelegd door bots voor jouw account sinds aanmaak (Data wordt elke nacht bijgewerkt).',
    YOUR_BOT: 'Overzicht van al jouw bots en hun gebruik tijdens deze factureringscyclus.',
    NAME: 'Naam van de bot',
    PLATFORM: 'Platform van de bot',
    MESSAGES: 'Totaal aantal berichten van de bot sinds aanmaak',
    CONTACTS: 'Totaal aantal contacten/leads van de bot',
    ACTIONS: 'Bewerk/Verwijder de bot',
    EDIT_ICON: 'Bewerk de chatflow en instellingen van de bot',
    DELETE_ICON: 'Verwijder de bot',
    TOOL_TIP_CLASSIC_VERSION: 'oude versie van de ' + brand + ' UI'
  },
  modal: {
    deleteBot: 'Verwijder Bot!',
    areYouSure: 'Weet je zeker dat je deze Bot wilt verwijderen?',
    enquiryForm: 'Vraag een offerte aan',
    payNow: 'Betaal Nu!',
    saveAndDelete: 'Opslaan en Verwijderen',
    submit: 'Verstuur',
  },
  noDataFound: {
    bots: 'Bots',
    noBotCreatedYet: 'Nog geen bots gemaakt!',
  },
  yourFriendWillMissYou: 'Je vriend zal je missen',
  rateYourBotCreationExperience: 'Beoordeel je ervaring met bot-creatie',
  whatMadeYouDeleteYourBot: 'Wat heeft je doen besluiten om je bot te verwijderen?',
  iCreatedByMistake: 'Ik heb het per ongeluk gemaakt',
  iWantToCreateAnotherBot: 'Ik wil een andere Bot maken',
  couldNotFindFeaturesThatINeeded: 'Kon de functies die ik nodig had niet vinden',
  uiWasHardToNavigate: 'De UI was moeilijk te navigeren',
  iDonotWantToTell: 'Dat wil ik niet vertellen',
  other: 'Andere reden',
  weWillDoOurBestToMakeAutomationFun: 'We zullen ons best doen om automatisering leuk en eenvoudig te maken voor iedereen.',
};
