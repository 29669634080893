import { Action, createReducer, on } from '@ngrx/store';
import { loadActiveMessages, loadMoreActiveMessages, removeActiveMessages } from '../actions/activeMessage.actions';
import Message from '../../models/message';

export const messageFeatureKey = 'message';


export const initialState: Message[] = [];

const inActiveMessageReducer = createReducer(
    initialState,
    on(loadActiveMessages, (state, { activeMessages }) => activeMessages),
    on(loadMoreActiveMessages, (state, { activeMessages }) => [...state, ...activeMessages]),
    on(removeActiveMessages, (state, { uuid }) => state.filter(elem => elem.uuid !== uuid))
);

export function reducer(state: Message[], action: Action) {
    return inActiveMessageReducer(state, action);
}
