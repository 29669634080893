export const team = {
  addMember: {
    team: 'टीम',
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'मदद',
    addAMember: 'एक सदस्य जोड़ें',
    addYourTeamMembersText: 'बॉट उपयोगकर्ताओं के साथ चैट करने के लिए अपनी टीम के सदस्यों को समर्थन एजेंटों के रूप में जोड़ें।',
    name: 'नाम',
    nameIsRequired: 'नाम आवश्यक है',
    email: 'ईमेल',
    enterAValidEmail: 'एक वैध ईमेल प्रविष्ट करें',
    password: 'पासवर्ड',
    passwordMustContain: 'पासवर्ड में अवश्य होना चाहिए',
    atleast6Characters: 'कम से कम 6 वर्ण,',
    oneUpperCaseLetter: '1 बड़ा अक्षर',
    oneSpecialCharacter: '1 विशेष चरित्र*',
    profileIcon: 'प्रोफ़ाइल आइकन',
    whatsappNumber: 'व्हाट्सएप नंबर',
    leadVisibility: 'लीड विजिबिलिटी*',
    addMember: 'सदस्य जोड़ें',
    role: 'भूमिका',
    createdOn: 'पर बनाया',
    action: 'कार्य',
    updateMember: 'सदस्य अद्यतन करें',
    add: 'जोड़ना',
    cancel: 'रद्द करना',
    selectVisibilityOption: 'दृश्यता विकल्प चुनें',
    all: 'सभी',
    assigned: 'सौंपा गया',
    nA: '-',
    admin: 'व्यवस्थापक',
    agent: 'एजेंट ',
    placeholders: {
      enterMemberName: 'सदस्य नाम दर्ज करें',
      enterMemberEmailAddress: 'सदस्य ईमेल पता दर्ज करें',
      createPasswordForMember: 'सदस्य के लिए एक पासवर्ड बनाएं',
      searchCountry: 'खोज देश',
      enterWhatsappNumber: 'व्हाट्सएप नंबर दर्ज करें',
      selectLeadsVisibility: 'लीड दृश्यता का चयन करें',
      searchTeamByNameOrEmail: 'नाम या ईमेल से टीम के सदस्य को खोजें'
    },
    modal: {
      deleteTeamMember: 'टीम के सदस्य को हटा दें',
      wantToDeleteThisTeamMember: 'क्या आप सुनिश्चित हैं कि आप टीम के इस सदस्य को हटाना चाहते हैं?'
    },
    appTooltip: {
      clickToAddNewTeamMember: 'एक नया टीम सदस्य जोड़ने के लिए क्लिक करें',
      profileIconOfTeamMember: 'प्रोफाइल आइकन/टीम के सदस्य की छवि',
      nameOfTeamMember: 'टीम के सदस्य का नाम',
      emailOfTeamMember: 'टीम के सदस्य का ईमेल',
      whatsappNumberOfTeamMember: 'टीम के सदस्य का व्हाट्सएप नंबर',
      roleOfTeamMemberAsAdminOrAgent: 'व्यवस्थापक/एजेंट के रूप में टीम के सदस्य की भूमिका',
      dateAndTimeOfCreatingTeamMemberRole: 'टीम के सदस्य भूमिका बनाने की तारीख और समय',
      editOrDeleteTeamMember: 'टीम के सदस्य को संपादित करें/हटाएं',
      editTeamMembersDetails: 'टीम के सदस्य के विवरण को संपादित करें।',
      removeAccessOfTeamMemberByDeletingAccount: 'खाता हटाकर टीम के सदस्य की पहुंच हटाएं'
    }
  },
  users: {
    manageMembers: 'सदस्य प्रबंधन',
    managerMembersWhichCanAccessPanel: 'अपनी साथी टीम का प्रबंधन करें जो आपके पैनल तक पहुँच सकती है',
    createNewMember: 'नया सदस्य बनाएं',
    createMember: 'सदस्य बनाएं',
    updateMember: 'सदस्य को अपडेट करें',
    deleteTitleMember: 'सदस्य को हटाएं',
    deleteDescriptionMember: 'क्या आप इस सदस्य को हटाना चाहते हैं?',
    noMemberFound: 'कोई सदस्य नहीं मिला!',
    titleMember: 'सदस्य बनाएं',
    manageUsers: 'उपयोगकर्ता प्रबंधन',
    createNewUser: 'नए उपयोगकर्ता बनाएँ',
    managerUserWhichCanAccessPanel: 'अपने पैनल का उपयोगकर्ता प्रबंधित करें जिन्हें पहुँचने की अनुमति है',
    phoneNumber: 'फ़ोन नंबर',
    discard: 'छोड़ दें',
    createUser: 'उपयोगकर्ता बनाएँ',
    updateUser: 'उपयोगकर्ता को अपडेट करें',
    noUserFOund: 'कोई उपयोगकर्ता नहीं मिला!',
    title: 'उपयोगकर्ता बनाएँ',
    profileIcon: 'प्रोफ़ाइल आइकन*',
    name: 'नाम*',
    email: 'ईमेल*',
    password: 'पासवर्ड*',
    selectRole: 'भूमिका चुनें*',
    selectARole: 'एक भूमिका चुनें',
    selectLeadVisibility: 'लीड दिखावट चुनें*',
    confirmPassword: 'पासवर्ड की पुष्टि करें*',
    tableHeadingName: 'नाम',
    tableHeadingEmail: 'ईमेल',
    tableHeadingPhoneNumber: 'फ़ोन नंबर',
    tableHeadingRole: 'भूमिका',
    tableHeadingStatus: 'स्थिति',
    tableHeadingAction: 'क्रिया',
    noUserFound: 'कोई उपयोगकर्ता नहीं मिला!',
    deleteTitle: 'उपयोगकर्ता हटाएं',
    deleteDescription: 'क्या आप वाकई इस उपयोगकर्ता को हटाना चाहते हैं?',
    placeholders: {
      searchHere: 'यहाँ खोजें',
      enterName: 'नाम दर्ज करें',
      enterEmail: 'ईमेल दर्ज करें',
      searchCountry: 'देश खोजें',
      enterPhoneNumber: 'फ़ोन नंबर दर्ज करें',
      enterPassword: 'पासवर्ड दर्ज करें',
      enterConfirmPassword: 'पासवर्ड की पुष्टि करें'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'उपयोगकर्ताओं को हटाएं',
      TOOLTIP_DELETE_MEMBERS: 'सदस्यों को हटाएं'
    }
  },
  roles: {
    manageRoles: 'भूमिकाओं का प्रबंधन',
    manageYourRoles: 'अपनी भूमिकाओं का प्रबंधन करें।',
    name: 'नाम*',
    description: 'विवरण',
    discard: 'छोड़ दें',
    createRole: 'भूमिका बनाएँ',
    creteARole: 'एक भूमिका बनाएँ',
    updateRole: 'भूमिका अपडेट करें',
    assignPermissions: 'अनुमतियाँ सौंपें',
    noUserFound: 'कोई उपयोगकर्ता भूमिकाएँ नहीं मिलीं!',
    tableHeadingName: 'नाम',
    tableHeadingDescription: 'विवरण',
    tableHeadingStatus: 'स्थिति',
    tableHeadingAction: 'क्रिया',
    module: 'मॉड्यूल',
    read: 'पढ़ें',
    write: 'लिखें',
    remove: 'हटाएं',
    notes: 'नोट्स',
    deleteTitle: 'रोल हटाएं',
    deleteDescription: 'सावधानी: जब आप इस रोल को हटाते हैं, तो इस रोल को दिए गए सभी उपयोगकर्ताओं को भी हटा दिया जाएगा। \n क्या आप इस रोल को हटाने के लिए सुनिश्चित हैं?',
    deleteMemberDescription: 'सावधानी: इस भूमिका को हटाने पर, इस भूमिका को सौंपे सभी सदस्यों को भी हटा दिया जाएगा। क्या आप इस भूमिका को हटाना चाहते हैं?',
    placeholders: {
      enterRoleName: 'भूमिका का नाम दर्ज करें',
      searchHere: 'यहाँ खोजें',
      enterDescriptionForRole: 'भूमिका के लिए विवरण दर्ज करें'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'आप किसे पहुंचने की अनुमति देने या ब्लॉक करने की सेक्शन',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'नई भूमिका जोड़ने के लिए क्लिक करें',
    }
  },
  accessControl: {
    users: 'उपयोगकर्ता',
    members: 'सदस्य',
    partnerTeam: 'साथी टीम',
    roles: 'भूमिकाएँ'
  }
};
