export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'BotPenguin\'a hoş geldiniz!',
      login: 'Giriş Yap',
      youAreAllSet: 'Her şey hazır! WhatsApp gelen kutusunu müşteri detayları sayfasından kullanabilirsiniz.',
      placeholders: {
        enterEmail: 'E-posta adresinizi girin',
        enterPassword: 'Şifrenizi girin'
      }
    }
  }
};
