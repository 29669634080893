export const analytics = {
  botWise: {
    botWiseAnalytics: 'تحليلات بوت وايز',
    currentBillingCycle: 'دورة الفوترة الحالية',
    currentMonth: 'الشهر الحالي',
    sinceAccountCreation: 'منذ إنشاء الحساب',
    messages: 'الرسائل',
    leads: 'العملاء المتوقعين',
    noDataFound: {
      botwiseAnalytics: 'تحليلات بوت وايز',
      noBotHasBeenCreatedYet: 'لم يتم إنشاء أي روبوت بعد!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'تلخيص البيانات من الرسائل وجهات الاتصال/العملاء لروبوتات محددة لدورة الفوترة الحالية أو الشهر الحالي أو منذ إنشاء الحساب (يتم تحديثها كل منتصف الليل)',
      TOOLTIP_FILTERS: 'تصفية البيانات للروبوت المحدد',
      TOOLTIP_MESSAGES: 'إجمالي الرسائل المستهلكة من قبل حسابك منذ إنشاء الحساب',
      TOOLTIP_LEADS: 'إجمالي العملاء المتوقعين الذين تم التقاطهم بواسطة الروبوتات لحسابك منذ إنشاء الحساب.',
    }
  },
  overview: {
    analytics: 'تحليلات',
    seeHowToUse: 'انظر كيفية الاستخدام',
    help: 'مساعدة',
    overview: 'نظرة عامة',
    messages: 'الرسائل',
    leads: 'العملاء المتوقعين',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'تحليلات جميع رسائل روبوتاتك وعملاءك المتوقعين. (البيانات تُحدث كل منتصف الليل)',
    }
  },
  summary: {
    analyticsSummary: 'ملخص التحليلات',
    allBots: 'جميع الروبوتات',
    daily: 'يومياً',
    weekly: 'أسبوعياً',
    monthly: 'شهرياً',
    messages: 'الرسائل',
    leads: 'العملاء المتوقعين',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'تلخيص البيانات اليومية أو الأسبوعية أو الشهرية من الرسائل وجهات الاتصال/العملاء لروبوتات محددة وجميع الروبوتات (تُحدث كل منتصف الليل)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'تحليلات WhatsApp',
      month: 'الشهر',
      week: 'الأسبوع',
      today: 'اليوم',
      filters: 'مرشحات',
      clearAll: 'مسح الكل',
      apply: 'تطبيق',
      totalConversations: 'مجموع المحادثات',
      freeConversations: 'محادثات مجانية',
      paidConversations: 'محادثات مدفوعة',
      messageSent: 'تم الإرسال',
      messageDelivered: 'تم التسليم',
      bpDropDown: {
        selectBot: 'حدد الروبوت',
      },
      indexMergeDatePicker: {
        startDate: 'تاريخ البدء',
        endDate: 'تاريخ الانتهاء',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'تحليلات المحادثات',
      month: 'الشهر',
      week: 'الأسبوع',
      today: 'اليوم',
      totalConversations: 'مجموع المحادثات',
      paidConversations: 'محادثات مدفوعة',
      freeConversations: 'محادثات مجانية',
      indexMergeDatePicker: {
        startDate: 'تاريخ البدء',
        endDate: 'تاريخ الانتهاء',
      },
      bpDropDown: {
        allBots: 'جميع الروبوتات'
      }
    },
    messageAnalytics: {
      whatsappMessages: 'رسائل WhatsApp',
      sent: 'تم الإرسال',
      delivered: 'تم التسليم',
      indexMergeDatePicker: {
        startDate: 'تاريخ البدء',
        endDate: 'تاريخ الانتهاء',
      },
      bpDropDown: {
        allBots: 'جميع الروبوتات'
      },
      month: 'الشهر',
      week: 'الأسبوع',
      today: 'اليوم',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'رسائل WhatsApp حسب الحالة',
      sent: 'تم الإرسال',
      delivered: 'تم التسليم',
      indexMergeDatePicker: {
        startDate: 'تاريخ البدء',
        endDate: 'تاريخ الانتهاء',
      },
      bpDropDown: {
        allBots: 'جميع الروبوتات'
      },
      month: 'الشهر',
      week: 'الأسبوع',
      today: 'اليوم',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'جميع المشتركين',
      whatsappSubscribers: 'مشتركو WhatsApp'
    }
  }
};
