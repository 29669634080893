import {Action, createReducer, on} from '@ngrx/store';
import {addCustomer, deleteCustomer, loadCustomers, loadMoreCustomers, updateCustomer} from '../actions/customers.action';

export const customerFeatureKey = 'customer';


export const initialState: any[] = [];

const customerReducer = createReducer(
  initialState,
  on(loadCustomers, (state, {customers}) => customers),
  on(loadMoreCustomers, (state, {customers}) => [...state, ...customers]),
  on(addCustomer, (state, {customer}) => [customer, ...state]),
  on(deleteCustomer, (state, {customer}) => state.filter(item => item._id !== customer)),
  on(updateCustomer, (state, {customer, update}) => state.map(item => {
    if (item._id === customer) {
      return {...item, ...update};
    } else {
      return item;
    }
  })),
);

export function reducer(state: any[], action: Action) {
  return customerReducer(state, action);
}
