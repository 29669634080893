export const developer = {
  developers: 'Geliştiriciler',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Geliştiricilerinize panonuzun tam potansiyelini kullanma yetkisi verin',
  accessToken: 'Erişim Jetonu',
  yourAPI: 'API\'larınız',
  api: {
    apiManagement: 'API Yönetimi',
    apiManagementSubHeading: 'API\'larınızı ekleyin, yapılandırın, test edin ve yönetin; bunları sohbet akışlarınızda tetiklemek için kullanabilirsiniz.',
    addApi: 'API Ekle',
    createApi: 'Bir API Oluştur',
    noApiFound: 'API Bulunamadı!',
    deleteApis: 'API\'ları Sil',
    areYouSureYouWantToDeleteAPI: `API'leri silmek istediğinizden emin misiniz?`,
    placeholders: {
      search: `API adına göre arama`
    },
    tableHead: {
      method: 'Yöntem',
      apiName: 'API Adı',
      url: 'URL',
      actions: 'İşlemler'
    },
    parametersTab: 'Parametreler',
    headersTab: 'Başlıklar',
    bodyTab: 'Gövde Sekmesi',
    manageApiResponses: 'API Yanıtlarını Yönet',
    chooseVariableToSave: 'Kaydetmek için değişken seçin',
    selectCustomAttribute: 'Özel özelliği seçin',
    subTab: {
      key: 'Anahtar',
      enterKey: 'Anahtar Girin',
      value: 'Değer',
      enterValue: 'Değer Girin',
      addAnotherField: 'Başka bir alan ekleyin',
      save: 'Kaydet',
      useslashToInsertCustomVariables: `Özel Değişkenleri veya kullanıcının yanıtından dinamik değerleri eklemek için '/' kullanın.`,
      response: 'Yanıt',
      apiRequestBody: 'API İstek Gövdesi',
      hit: 'Başarı',
      test: 'Test',
      buttonToGetAResponse: 'Bir yanıt almak için düğme',
      variable: 'Değişken',
      saveToCustomAttribute: 'Özel Özelliğe Kaydet'
    },
    apiLogs: {
      apiManagement: 'API Yönetimi',
      addConfigureTestAndManageYourAPIs: 'API\'larınızı ekleyin, yapılandırın, test edin ve yönetin; bunları sohbet akışlarınızda tetiklemek için kullanabilirsiniz.',
      searchHere: 'Durum koduna göre arama',
      deleteApis: 'API\'ları Sil',
      testAnApi: 'Bir API\'yı Test Et',
      noAPILogFound: 'API Günlüğü Bulunamadı',
      responseCode: 'Yanıt Kodu',
      message: 'Mesaj',
      apiResponse: 'API Yanıtı',
      actions: 'İşlemler',
      deleteAPIs: 'API Günlüklerini Sil',
      areYouSureYouWantToDelete: 'Bu API Günlüğünü silmek istediğinizden emin misiniz?',
      apiLogs: `API Kayıtları`,
      subTitle: `Chatbot'un sohbet akışı içinde tetiklenen tüm API isteklerinin ve yanıtlarının detaylı kaydı.`
    }
  },
  configApi: {
    configureApi: 'API\'yı Yapılandır',
    configureApiSubHeading: 'Json tabanlı API\'nızı burada yapılandırabilir ve sohbet akışınızı zenginleştirmek için kullanabilirsiniz.',
    apiLogs: 'API Günlükleri',
    test: 'Test Et',
    manageApiResponse: 'API Yanıtlarını Yönet',
    body: 'Gövde',
    headers: 'Başlıklar',
    parameters: 'Parametreler',
    placeholders: {
      enterApiName: 'API Adını Girin',
      enterApiUrl: 'API URL\'sini Girin'
    }
  }
};
