export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Welcome to BotPenguin !',
      login: 'Login',
      youAreAllSet: 'You are all set! You can use the WhatsApp inbox from lead details page.',
      placeholders: {
        enterEmail: 'Enter email',
        enterPassword: 'Enter Password'
      }
    }
  }
};
