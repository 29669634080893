export const analytics = {
  botWise: {
    botWiseAnalytics: 'बॉट-वार एनालिटिक्स',
    currentBillingCycle: 'वर्तमान बिलिंग चक्र',
    currentMonth: 'अभी चल रहा माह',
    sinceAccountCreation: 'खाता निर्माण के बाद से',
    messages: 'संदेश',
    leads: 'लीड्स',
    noDataFound: {
      botwiseAnalytics: 'बॉट-वार एनालिटिक्स',
      noBotHasBeenCreatedYet: 'अभी तक कोई बॉट नहीं बनाया गया है!'
    },
    appTooltip: {
      TOOLTIP_FILTERS: 'चयनित बॉट के लिए फ़िल्टर डेटा',
      TOOLTIP_MESSAGES: 'खाता निर्माण के बाद से आपके खाते द्वारा उपभोग किए गए कुल संदेश',
      TOOLTIP_LEADS: 'खाता निर्माण के बाद से आपके खाते के लिए बॉट द्वारा कैप्चर किए गए कुल लीड।',
      TOOLTIP_BOTWISE_SUMMARY: 'वर्तमान बिलिंग चक्र, वर्तमान महीने, या खाता निर्माण के बाद से संदेशों और संपर्कों से डेटा को विशिष्ट बॉट्स के लिए संक्षेप में प्रस्तुत करें (हर आधी रात को अपडेट करें)'
    },
  },
  overview: {
    analytics: 'एनालिटिक्स',
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'मदद',
    overview: 'अवलोकन',
    messages: 'संदेश',
    leads: 'लीड्स',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'आपके सभी बॉट संदेशों और लीड के लिए एनालिटिक्स। (हर आधी रात को अपडेट किया गया डेटा)',
    }
  },
  summary: {
    analyticsSummary: 'एनालिटिक्स सारांश',
    allBots: 'सभी बॉट्स',
    daily: 'दैनिक',
    weekly: 'साप्ताहिक',
    monthly: 'महीने के',
    messages: 'संदेश',
    leads: 'लीड्स',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'संदेश और संपर्कों से दैनिक, साप्ताहिक या मासिक डेटा को संक्षेप में प्रस्तुत करें/विशिष्ट और सभी बॉट के लिए लीड (हर आधी रात को अपडेट करें)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'व्हाट्सएप एनालिटिक्स',
      month: 'महीना',
      week: 'सप्ताह',
      today: 'आज',
      filters: 'फिल्टर',
      clearAll: 'सभी साफ करें',
      apply: 'आवेदन करना',
      totalConversations: 'कुल बातचीत',
      freeConversations: 'नि: शुल्क बातचीत',
      paidConversations: 'भुगतान की गई बातचीत',
      messageSent: 'संदेश भेजा गया',
      messageDelivered: 'संदेश दिया',
      bpDropDown: {
        selectBot: 'बॉट का चयन करें'
      },
      indexMergeDatePicker: {
        startDate: 'आरंभ करने की तिथि',
        endDate: 'अंतिम तिथि'
      },
    },
    conversationAnalytics: {
      conversationAnalytics: 'वार्तालाप एनालिटिक्स',
      month: 'महीना',
      week: 'सप्ताह',
      today: 'आज',
      totalConversations: 'कुल बातचीत',
      paidConversations: 'भुगतान की गई बातचीत',
      freeConversations: 'नि: शुल्क बातचीत',
      indexMergeDatePicker: {
        startDate: 'आरंभ करने की तिथि',
        endDate: 'अंतिम तिथि'
      },
      bpDropDown: {
        allBots: 'सभी बॉट'
      },
    },
    messageAnalytics: {
      whatsappMessages: 'व्हाट्सएप संदेश',
      sent: 'भेजा',
      delivered: 'पहुंचा दिया',
      month: 'महीना',
      week: 'सप्ताह',
      today: 'आज',
      indexMergeDatePicker: {
        startDate: 'आरंभ करने की तिथि',
        endDate: 'अंतिम तिथि'
      },
      bpDropDown: {
        allBots: 'सभी बॉट'
      },
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'स्टेटस द्वारा व्हाट्सएप संदेश',
      sent: 'भेजा',
      month: 'महीना',
      week: 'सप्ताह',
      today: 'आज',
      delivered: 'पहुंचा दिया',
      indexMergeDatePicker: {
        startDate: 'आरंभ करने की तिथि',
        endDate: 'अंतिम तिथि'
      },
      bpDropDown: {
        allBots: 'सभी बॉट'
      },
    },
    newWhatsappAnalytics: {
      whatsappSubscribers: 'व्हाट्सएप सब्सक्राइबर्स',
      allSubscribers: 'सभी ग्राहक',
    }
  }
};
