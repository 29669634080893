import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanvasService {
  isShowChatbotPreview: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showChatbotPreview = false;
  constructor() {

  }

  emitChatPreviewStatus() {
    this.isShowChatbotPreview.next(this.showChatbotPreview);
  }

}
