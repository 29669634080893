import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../../modules/shared/shared.service';
import { NotificationService } from '../../../../services/notification.service';
import { notificationToolTipText } from '../../../../tooltip';
import { updateProfile } from '../../../../ngrx/actions/profile.actions';
import { AccessControlService } from 'src/appv2/services/access-control.service';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss']
})
export class NotificationSettingComponent implements OnInit, OnDestroy {

  staticText: any;
  receivedNotificationOptions: any[];
  storeSubscription$: Subscription;
  userCurrentNotificationsSetting: any;
  notificationsSetting: NotificationsSetting = {
    newMessage: {
      notificationVisibility: 'ALL',
      mobile: true,
      desktop: true,
    },
    newLead: {
      notificationVisibility: 'ALL',
      mobile: true,
      desktop: true
    },
    liveChat: {
      notificationVisibility: 'ALL',
      mobile: true,
      desktop: true
    },
  };
  profileType: string;
  dropdowns = this.initialDropdowns();
  notificationsSettingOptions: any[];
  toolTip = notificationToolTipText;
  // notificationsSettingOptions = [{
  //   title: 'User Message',
  //   description: 'Receive notifications whenever any user sends any message',
  //   icon: '/assets/version2/icons/message_icon.svg',
  //   type: 'newMessage',
  //   mobileNotificationsToolTip: notificationToolTipText.TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION,
  //   desktopNotificationsToolTip: notificationToolTipText.TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION,
  //   receivedNotificationsToolTip: notificationToolTipText.TOOLTIP_RECEIVED_NOTIFICATION
  // },
  // {
  //   title: 'Live Chat Request',
  //   description: 'Receive notifications whenever any user request for live chat',
  //   icon: '/assets/version2/icons/live_chat_icon.svg',
  //   type: 'liveChat',
  //   mobileNotificationsToolTip: notificationToolTipText.TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION,
  //   desktopNotificationsToolTip: notificationToolTipText.TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION
  // },
  // {
  //   title: 'New Lead',
  //   description: 'Receive notifications whenever new leads gets created',
  //   icon: '/assets/version2/icons/lead_icon.svg',
  //   type: 'newLead',
  //   mobileNotificationsToolTip: notificationToolTipText.TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION,
  //   desktopNotificationsToolTip: notificationToolTipText.TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION
  // }];


  constructor(
    private store: Store<any>,
    private notificationService: NotificationService,
    private sharedService: SharedService,
    private aclService: AccessControlService,
    private languageService: LanguageService
    ) {
    this.staticText = languageService.getStaticText().notification.notificationSetting;
    this.initializeStaticText();
    this.storeSubscription$ = this.store.select(state => state?.profile)
      .subscribe((profile: any) => {
        this.profileType = profile.type;
        if (profile && Object.keys((profile?.notifications || {})).length !== 0) {
          this.userCurrentNotificationsSetting = { ...this.notificationsSetting, ...profile.notifications };
          this.notificationsSetting = { ...this.notificationsSetting, ...profile.notifications };
        }
      });
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.dropdowns = this.initialDropdowns();
    if (Object.keys((this.userCurrentNotificationsSetting || {})).length !== 0) {
      this.notificationsSetting = this.userCurrentNotificationsSetting;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.storeSubscription$.unsubscribe();
  }

  initializeStaticText() {
    this.toolTip = this.staticText.appTooltip;
    this.notificationsSettingOptions = [{
      title: this.staticText.userMessage,
      description: this.staticText.receiveNotificationsWhenUserSendMessage,
      icon: '/assets/version2/icons/message_icon.svg',
      type: 'newMessage',
      mobileNotificationsToolTip: this.toolTip.TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION,
      desktopNotificationsToolTip: this.toolTip.TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION,
      receivedNotificationsToolTip: this.toolTip.TOOLTIP_RECEIVED_NOTIFICATION
    },
    {
      title: this.staticText.liveChatRequest,
      description: this.staticText.receiveNotificationsWhenUserRequestLiveChat,
      icon: '/assets/version2/icons/live_chat_icon.svg',
      type: 'liveChat',
      mobileNotificationsToolTip: this.toolTip.TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION,
      desktopNotificationsToolTip: this.toolTip.TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION
    },
    {
      title: this.staticText.newLead,
      description: this.staticText.receiveNotificationsWhenNewLeadCreated,
      icon: '/assets/version2/icons/lead_icon.svg',
      type: 'newLead',
      mobileNotificationsToolTip: this.toolTip.TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION,
      desktopNotificationsToolTip: this.toolTip.TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION
    }];
    this.receivedNotificationOptions = [
      { title: this.staticText.assignedOnly, value: 'ASSIGNED' },
      { title: this.staticText.allUsers, value: 'ALL' }
    ];
  }

  initialDropdowns() {
    return { newMessage: false, newLead: false, liveChat: false };
  }

  toggleDropdown(event: Event, type: 'newMessage' | 'newLead' | 'liveChat') {
    event.stopPropagation();
    this.dropdowns = { ...this.initialDropdowns(), [type]: !this.dropdowns[type] };
  }
  updateNotification(event: any, notificationType: 'newMessage' | 'newLead' | 'liveChat', type: 'mobile' | 'desktop' | 'notificationVisibility') {
    if (type === 'notificationVisibility') {
      this.notificationsSetting = {
        ...this.notificationsSetting,
        [notificationType]: {
          ...this.notificationsSetting[notificationType],
          [type]: event.item.value
        }
      };
    } else {
      this.notificationsSetting = {
        ...this.notificationsSetting,
        [notificationType]: {
          ...this.notificationsSetting[notificationType],
          [type]: event.target.checked
        }
      };
    }
  }
  saveNotificationSetting() {
    this.sharedService.loader('start');
    this.notificationService.updateNotificationSetting(this.notificationsSetting)
      .then((data) => {
        this.sharedService.showToast('success', '', 'Updated Successfully');
        this.store.dispatch(updateProfile({ update: data }));
      })
      .catch((err) => this.sharedService.showToast('error', '', err.message))
      .finally(() => this.sharedService.loader('stop'));
  }
  discardChanges(event) {
    event.stopPropagation();
    this.dropdowns = this.initialDropdowns();
  }

  getDropdownTitle(selectValue) {
    return this.receivedNotificationOptions.find((option) => option.value === selectValue)?.title || 'Select Received For';
  }

  get getnotificationsSettingOptions() {
    return this.profileType === 'AGENT'
      ? this.notificationsSettingOptions.filter((option) => option.type !== 'newLead')
      : this.notificationsSettingOptions;
  }
}

interface NotificationsSetting {
  newMessage: {
    notificationVisibility: 'ASSIGNED' | 'ALL';
    mobile: boolean;
    desktop: boolean;
  };
  newLead: {
    notificationVisibility: 'ASSIGNED' | 'ALL';
    mobile: boolean;
    desktop: boolean;
  };
  liveChat: {
    notificationVisibility: 'ASSIGNED' | 'ALL';
    mobile: boolean;
    desktop: boolean;
  };

}
