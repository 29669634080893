export const notification = {
  notification: 'Bildirimler',
  allNotifications: 'Tüm bildirimler',
  general: 'Genel',
  settings: 'Ayarlar',
  newChatRequest: {
    youHaveANewChatRequest: 'Yeni bir sohbet isteğiniz var!!!',
    youHaveTransferredChatRequest: 'Aktarılmış bir sohbet isteğiniz var!!!',
    acceptRequest: 'İsteği Kabul Et',
    discardRequest: 'İsteği Reddet'
  },
  notificationList: {
    name: 'Ad',
    message: 'Mesaj',
    noDataFound: {
      notification: 'Bildirim',
      noNotificationsFound: 'Hiç bildirim bulunamadı!',
    }
  },
  notificationSetting: {
    userMessage: 'Kullanıcı Mesajı',
    receiveNotificationsWhenUserSendMessage: 'Kullanıcı mesaj gönderdiğinde bildirim al',
    liveChatRequest: 'Canlı Sohbet İsteği',
    receiveNotificationsWhenUserRequestLiveChat: 'Kullanıcı canlı sohbet istediğinde bildirim al',
    newLead: 'Yeni Müşteri',
    receiveNotificationsWhenNewLeadCreated: 'Yeni müşteri oluşturulduğunda bildirim al',
    assignedOnly: 'Sadece Atanmış',
    allUsers: 'Tüm Kullanıcılar',
    customizeWhichNotificationsYouWantToRecieve: 'Almak istediğiniz bildirimleri özelleştirin',
    youCanCustomizeSettingForTheNotifications: 'Bildirimler için ayarları özelleştirebilirsiniz',
    receiveNotifications: 'Bildirimleri Al',
    desktopNotifications: 'Masaüstü Bildirimleri',
    mobilePushNotifications: 'Mobil Bildirimler',
    discard: 'Reddet',
    update: 'Güncelle',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Yeni Mesaj Bildirimlerini Masaüstünde Gönder',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Yeni Mesaj Bildirimlerini Mobil Cihazda Gönder',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Yeni Müşteri Bildirimlerini Masaüstünde Gönder',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Yeni Müşteri Bildirimlerini Mobil Cihazda Gönder',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Canlı Sohbet Bildirimlerini Masaüstünde Gönder',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Canlı Sohbet Bildirimlerini Mobil Cihazda Gönder',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Bildirim Almak İstediğiniz Mesajları Seçin'
    }
  }
};
