export const subscription = {
  subscriptions: 'सुब्स्क्रिप्शन्स',
  plan: 'योजना',
  whatsappPlan: 'व्हाट्सएप योजना',
  addOns: 'ऐड-ऑन',
  billingDetails: 'बिलिंग विवरण',
  invoices: 'चालान',
  wa1Reminder: 'WA 1 रिमाइंडर',
  wa2Reminder: 'WA 2nd अनुस्मारक',
  waRenewApi: 'WA नवीकरण एपीआई',
  waCheckUsageApi: 'WA उपयोग चेक करने की एपीआई',
  waResetUsageApi: 'WA उपयोग रीसेट करने की एपीआई',
  waExceedUsageApi: 'WA एपीआई का अत्यधिक उपयोग',
  waGetBotsInThisAccount: 'WA को इस खाते में बॉट मिलते हैं',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'हमारे पास आपकी सभी आवश्यकताओं के लिए सबसे सस्ती कीमतें हैं।',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'व्हाट्सएप योजना',
    TOOLTIP_ADDS_ON_HEADING: 'अतिरिक्त संदेश पैक और व्हिटेलबेल बॉट खरीदें',
    TOOLTIP_ADDS_ON_MESSAGE: 'यदि आप अपनी मासिक संदेश सीमा तक पहुंच गए हैं, तो आप अतिरिक्त पैक खरीद सकते हैं।',
    TOOLTIP_SUBSCRIPTION_HEADING: 'अपने वर्तमान सदस्यता विवरण की जाँच करें',
    TOOLTIP_HORIZONTAL_BAR: 'हमारे मासिक या वार्षिक मूल्य निर्धारण को 3k से 500k संदेशों से बाएं से दाएं ले जाकर देखें।',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'चालान के लिए अपने बिलिंग विवरण को अपडेट करें।',
    TOOLTIP_INVOICES_HEADING: 'अपने पिछले चालान की जाँच करें।',
    TOOLTIP_INVOICES_ACTION: 'अपने ईमेल खाते में चालान प्राप्त करने के लिए क्लिक करें',
  },
  currentPlan: {
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'मदद',
    downgradeRequest1: 'आपने अपनी योजना को डाउनग्रेड करने का अनुरोध किया है',
    downgradeRequest2: 'संदेश। कृपया ध्यान दें, आपकी योजना वर्तमान बिलिंग चक्र के अंत तक डाउनग्रेड हो जाएगी',
    downgradeRequest3: 'आप आनंद ले सकते हैं',
    downgradeRequest4: 'तब तक सेवाएं।',
    downgradeRequestMaster1: 'आपने अपना डाउनग्रेड करने का अनुरोध किया है',
    downgradeRequestMaster2: ' करने के लिए योजना',
    downgradeRequestMaster3: ' बात चित। कृपया ध्यान दें, आपकी योजना वर्तमान बिलिंग चक्र के अंत तक डाउनग्रेड हो जाएगी',
    downgradeRequestMaster4: 'आप आनंद ले सकते हैं',
    downgradeRequestMaster5: 'तब तक सेवाएं।',
    active: 'सक्रिय',
    inactive: 'निष्क्रिय',
    month: 'महीना',
    year: 'वर्ष',
    messagePack: 'संदेश पैक',
    king: ' किंग',
    baby: ' बेबी',
    whatsapp: 'व्हाट्सप्प',
    youAreOn: 'आप पर है',
    plan: 'योजना',
    totalMessagesLimit: 'कुल संदेश सीमा',
    liveAgentLimit: 'लाइव एजेंट सीमा',
    perMonth: '/महीना',
    agents: 'एजेंट (ओं)',
    messagesAddOn: 'संदेश ऐड-ऑन',
    botWhitelabels: 'बॉट व्हिटेलबेल्स',
    totalAddOnMessages: 'कुल ऐड-ऑन संदेश',
    basePrice: 'आधार मूल्य',
    gst: 'जीएसटी',
    totalPrice: 'कुल कीमत',
    totalAddOnPrice: 'कुल ऐड-ऑन मूल्य',
    whitelabelPrice: 'व्हिटलेबेल मूल्य',
    startedOn: 'को प्रारंभ करें',
    nextDueOn: 'आगे के कारण',
    addOnExpiry: 'ऐड-ऑन एक्सपायरी',
    website: 'वेबसाइट',
    facebook: 'फेसबुक',
    telegram: 'टेलीग्राम'
  },
  invoice: {
    invoiceNumber: 'चालान नंबर',
    date: 'तारीख',
    invoiceAmount: 'बीजक राशि',
    planAmount: 'योजना राशि',
    plan: 'योजना',
    messages: 'संदेशों',
    action: 'कार्य',
    nA: '-',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'अपने ईमेल खाते में चालान प्राप्त करने के लिए क्लिक करें',
      clickToDownloadInvoice: 'पीडीएफ फॉर्म में चालान डाउनलोड करने के लिए क्लिक करें'
    }
  },
  messageAddOn: {
    validity: 'वैधता',
    kingPlanHeader: '👑 किंग प्लान',
    youAreOnThe: 'आप पर हैं',
    asOfNowAndWillGet: 'अब के रूप में, और मिल जाएगा',
    messages: 'संदेशों',
    inThisPlan: 'इस योजना में।',
    youCanBuyAdditionalMessaes: 'आप अपनी व्यावसायिक आवश्यकताओं के अनुसार अतिरिक्त संदेश खरीद सकते हैं।',
    plan: ' योजना',
    babyPlan: ' बेबी योजना',
    upgradePlan: 'अपग्रेड योजना',
    asOfNow: ' इस समय।',
    king: ' किंग',
    baby: ' बेबी',
    get: 'पाएं ',
    pleaseUpgradeToKingPlan: 'कृपया इस प्लान में अपग्रेड करें ',
    toEnableAddOnsAndWhitelabelPacks: ' ऐड-ऑन और व्हिटलबेल पैक को सक्षम करने के लिए।',
    messageAddOnPacks: 'संदेश ऐड-ऑन पैक',
    ifYouHaveReachedYourMonthlyMessageLimit: 'यदि आप अपनी मासिक संदेश सीमा तक पहुंच गए हैं, तो अतिरिक्त पैक खरीदें और निर्बाध सेवाओं का आनंद लें',
    pleaseSelectNumberOfExpectedMessages: 'कृपया अपेक्षित संदेशों की संख्या चुनें',
    addOnsValidity: 'ऐड-ऑन वैधता',
    currentBillingCycle: 'चालू बिलिंग चक्र',
    oneYear: 'एक वर्ष',
    summary: 'सारांश',
    messageCreditsWithKingPlan: 'किंग प्लान के साथ संदेश क्रेडिट',
    messageCreditsWith: 'संदेश क्रेडिट करें ',
    withPlan: ' के साथ',
    billingCycle: 'बिलिंग चक्र',
    payableAmount: 'भुगतान योग्य राशि',
    oneTimePayment: 'एक - बार भुगतान',
    plusTaxes: ' + कर',
    buyNow: 'अभी खरीदें',
    whiteLabelChatWindow: 'व्हाइटलेबेल्ड चैट विंडो',
    noBotsLeftToWhiteLabel: 'कोई बॉट व्हिटेलबेल के लिए नहीं बचा है।',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'कृपया उन बॉट्स का चयन करें जिन्हें आप व्हाइटलेबेल करना चाहते हैं',
    selectBots: 'बॉट का चयन करें',
    bot: 'बॉट',
    bots: 'बॉट',
    toBeWhiteLabelled: ' व्हाइटलेबेल्ड होना',
    remove: 'निकालना ',
    brandNameFromChatWindow: ' चैट विंडो से ब्रांडनेम',
    willBeValidTill: 'तक मान्य होगा',
    ourYearlyPrice: 'हमारी वार्षिक कीमत',
    directWhiteLabel: 'प्रत्यक्ष व्हाइटलेबेल',
    whiteLabelledBots: 'व्हाइटलेबेलड बॉट्स',
    nextRenewalColon: 'अगला नवीकरण:',
    optOutRequested: 'अनुरोध किया',
    contactAgency: 'संपर्क एजेंसी',
    optOut: 'बाहर निकलना',
    removeWhiteLabel: 'व्हाइटलेबेल निकालें',
    messageCount: 'संदेश',
    numberOfPacks: 'पैक की संख्या',
    amount: 'रकम',
    taxes: 'कर',
    totalAmount: 'कुल रकम',
    numOfBots: 'बॉट की संख्या',
    select: 'चयन करें',
    selectTheValidity: 'वैधता का चयन करें',
  },
  payment: {},
  paymentMethod: {
    paymentDetails: 'भुगतान विवरण',
    cardNumber: 'कार्ड संख्या',
    cvv: 'सीवीवी',
    month: 'महीना',
    year: 'वर्ष',
    save: 'बचाना',
    add: 'जोड़ना',
    edit: 'संपादन करना',
    disclaimer: 'अस्वीकरण:',
    disclaimerDescription1: 'उपरोक्त भुगतान विधि विवरण केवल भारत के बाहर के उपयोगकर्ताओं पर लागू होगा।',
    disclaimerDescription2: 'हम आपके कार्ड के विवरण को हमारे साथ नहीं सहेज रहे हैं। मामले में, आप अपना कार्ड विवरण बदलना चाहते हैं, इसे सीधे भुगतान गेटवे के साथ बदल दिया जाएगा।',
    billingAddress: 'बिल भेजने का पता',
    companyName: 'कंपनी का नाम',
    country: 'देश',
    selectCountry: 'देश चुनें',
    stateOrRegion: 'राज्य/क्षेत्र',
    streetAddress: 'गली का पता',
    gstIn: 'जीएसटीआईएन',
    postalCode: 'डाक कोड',
    cancel: 'रद्द करना',
    proceedToPay: 'चुकाने के लिए कार्रवाई शुरू करो',
    company: 'कंपनी',
    state: 'राज्य',
    masterCard: 'मास्टर कार्ड',
    yourCompanyName: 'आपके संगठन का नाम',
    yourCountry: 'आपका देश',
    yourRegion: 'आपका क्षेत्र',
    yourPostalCode: 'आपका डाक पिन',
    plusTaxes: ' + कर',
    dropDown: {
      month: 'महीना *',
      year: 'वर्ष *'
    },
    placeholders: {
      enterCardNumber: 'कार्ड नंबर दर्ज करें',
      cvv: 'सीवीवी',
      enterYourCompanyName: 'अपनी कंपनी का नाम दर्ज करें',
      enterYourStateOrRegion: 'अपना राज्य/क्षेत्र दर्ज करें',
      enterYourStreetAddress: 'अपना स्ट्रीट एड्रेस दर्ज करें',
      enterYourGSTIN: 'अपना जीएसटीआईएन दर्ज करें',
      enterPostalCode: 'डाक कोड दर्ज करें'
    }
  },
  plans: {
    viewDetailedPricing: 'विस्तृत मूल्य देखें',
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'मदद',
    weHaveGotAPricingPlanPerfectForYou: 'हमें एक मूल्य निर्धारण योजना मिली है जो आपके लिए एकदम सही है',
    weBelieve: 'हमें यकीन है ',
    shouldBeAccessibleToAllCompanies: ' सभी कंपनियों के लिए सुलभ होना चाहिए, न कि आकार में कोई फर्क नहीं पड़ता।',
    monthly: 'महीने के',
    yearly: 'सालाना',
    twoMonthsFree: '(2 महीने मुफ़्त)',
    babyPlan: 'बेबी योजना',
    freeForever: 'हमेशा के लिए आज़ाद',
    messagesPerMonth: 'प्रति माह संदेश',
    currentPlan: 'वर्तमान योजना',
    freePlan: 'मुक्त योजना',
    kingPlan: 'किंग योजना',
    perYear: 'प्रति वर्ष',
    perMonth: 'प्रति महीने',
    upgradePlan: 'अपग्रेड योजना',
    changePlan: 'परिवर्तन योजना',
    currentlyActive: 'वर्तमान में सक्रिय',
    emperorPlan: 'एम्परर योजना (एंटरप्राइज)',
    sendAsManyMessagesAsYouWant: 'जितनी जरूरत हो उतना संदेश भेजें',
    contactUs: 'संपर्क करें',
    features: 'विशेषताएँ',
    // features: 'Features',
    areYouSureYouWantToShiftFrom: 'क्या आप सुनिश्चित हैं कि आप से शिफ्ट करना चाहते हैं',
    messages: 'संदेशों',
    planQuestionMark: 'योजना?',
    downgradeToFreePlan: 'मुक्त योजना के लिए डाउनग्रेड',
    goToBabyPlan: 'बेबी प्लान पर जाएं',
    thankYouForChoosingBotPenguin: 'बॉटपेंगुइन चुनने के लिए धन्यवाद',
    bots: 'बॉट',
    facebookPages: 'फेसबुक पेज',
    teamMember: 'टीम के सदस्य',
    dataStorage: 'आधार सामग्री भंडारण',
    conditionalFlow: 'सशर्त प्रवाह',
    liveChat: 'सीधी बातचीत',
    bookAppointments: 'बही -नियुक्ति',
    csvExport: 'सीएसवी निर्यात',
    emailAndCallSupport: 'ईमेल और कॉल समर्थन',
    googleCalendarIntegrations: 'गूगल कैलेंडर एकीकरण',
    zohoCrmIntegrations: 'ज़ोहो सीआरएम एकीकरण',
    reportsAndAnalytics: 'रिपोर्ट और विश्लेषिकी',
    removeBotPenguinBranding: 'Botpenguin ब्रांडिंग निकालें',
    oneBot: '1 बॉट',
    twoKMessagesPerMonth: '2k संदेश/महीना',
    oneFacebookPage: '1 फेसबुक पेज',
    oneTeamMember: '1 टीम का सदस्य',
    thirtyDaysOfDataStorage: '30 दिन का डेटा भंडारण',
    unlimitedBot: 'असीमित बॉट',
    threeTo500kMessagesPerMonth: '3k से 500k संदेश/महीना',
    tenFacebookPages: '10 फेसबुक पेज',
    threeTeamMembers: '3 टीम के सदस्य',
    nintyDaysOfDataStorage: '90 दिन का डेटा भंडारण',
    unlimitedMessages: 'असीमित संदेश',
    unlimitedFacebookPages: 'असीमित फेसबुक पेज',
    unlimitedTeamMembers: 'असीमित टीम के सदस्य',
    unlimitedDataStorage: 'असीमित डेटा भंडारण',
    youAreHere: 'आप यहां हैं',
    plan: 'योजना',
    toThe: ' तक',
    plusTaxes: ' + कर',
    detailedPricing: {
      kingsplan: 'राजा योजना',
      elementary: 'प्राथमिक',
      solutionType: 'समाधान प्रकार',
      saas: 'SaaS',
      fbpage1fb: '1 फेसबुक पेज',
      perMonth100: 'महीने के 100',
      enterprise: 'एंटरप्राइज',
      saasOnPremises: 'SaaS ऑन प्रेमिसेस',
      aiChatbotFeatures: 'एआई चैटबॉट सुविधाएँ',
      dataSourceForTraning: 'प्रशिक्षण के लिए डेटा स्रोत',
      smartFeatures: 'स्मार्ट सुविधाएँ',
      liveChat: 'लाइव चैट',
      day30: '30 दिन',
      day90: '90 दिन',
      landSquared: 'लैंडस्क्वायर्ड',
      doItYourself: 'खुद से करें',
      unifiedInbox: 'एकीकृत इनबॉक्स',
      platforms: 'प्लेटफ़ॉर्म',
      websiteChatbot: 'वेबसाइट चैटबॉट',
      FacebookMessengerChatbot: 'फेसबुक मैसेंजर चैटबॉट',
      Telegram: 'टेलीग्राम',
      conversationalLandingPage: 'संवादात्मक लैंडिंग पेज',
      integrations: 'एकीकरण',
      reportsAndAnalytics: 'रिपोर्ट और विश्लेषण',
      customerSupport: 'ग्राहक समर्थन',
      security: 'सुरक्षा',
      bots: 'बोट्स',
      babyPlan: 'बेबी योजना',
      kingPlan: 'राजा योजना',
      emperorPlan: 'सम्राट योजना',
      noofBots: 'बोट की संख्या',
      one: '1 (एक)',
      unlimitedBots: 'असीमित बोट्स',
      noOfWebsite: 'वेबसाइट की संख्या',
      unlimitedWebsites: 'असीमित वेबसाइट',
      unlimitedMessages: 'असीमित संदेश',
      messagesSend: 'संदेश भेजें',
      perMonth2000: 'महीने के 2,000',
      upto500000perMonth: '500,000 तक प्रति माह',
      facebookMessenger: 'फेसबुक मैसेंजर',
      unlimitedPages: 'असीमित पेज्स',
      telegramNumbers: 'टेलीग्राम नंबर्स',
      unlimited: 'असीमित',
      whatsappNumbers: 'व्हाट्सएप नंबर्स',
      subscribersLeads: 'सब्सक्राइबर्स/लीड्स',
      createSupportTeam: 'लाइव चैट के लिए समर्थन टीम बनाएं',
      oneMember: '1 सदस्य',
      threeMembers: '3 सदस्य',
      conversationHistory: 'बातचीत इतिहास',
      thirtyDays: '30 दिन',
      ninetyDays: '90 दिन',
      chatbotDesigning: 'चैटबॉट डिज़ाइनिंग',
      weAreHereToHelp: 'हम यहाँ मदद करने के लिए हैं',
      weTakeCareEndToEnd: 'हम समाप्त समय समय पर देखभाल करते हैं',
      multipleLanguages: 'एकाधिक भाषाएँ',
      conditionalFlow: 'शर्तमुक्त प्रवृत्ति',
      removeBotPenguinBranding: 'बॉट पेंगुइन ब्रांडिंग हटाएं',
      gptMessages: 'जीपीटी संदेश',
      aiChatbot: 'एआई चैटबॉट',
      chatGPTIntegration: 'चैटजीपीटी एकीकरण',
      configureAIChatbotPersonality: 'एआई चैटबॉट व्यक्तित्व कॉन्फ़िगर करें',
      trainChatbotOnYourOwnData: 'अपने डेटा पर चैटबॉट प्रशिक्षित करें',
      trainChatbotOnWebPages: 'वेब पेज्स पर चैटबॉट प्रशिक्षित करें',
      trainChatbotOnMultipleFiles: 'मल्टीपल फ़ाइल्स पर चैटबॉट प्रशिक्षित करें',
      trainChatbotOnFAQs: 'FAQs पर चैटबॉट प्रशिक्षित करें',
      dataTrainingStorage: 'डेटा प्रशिक्षण स्टोरेज',
      useChatGPTModel4_3_5Turbo: 'चैटजीपीटी मॉडल 4/3.5 टर्बो का उपयोग करें',
      uploadMultipleFilesForChatbotTraining: 'चैटबॉट प्रशिक्षण के लिए मल्टीपल फ़ाइल्स अपलोड करें',
      naturalLanguageProcessing: 'प्राकृतिक भाषा प्रसंस्करण',
      naturalLanguageUnderstanding: 'प्राकृतिक भाषा समझना',
      answerLength: 'उत्तर लम्बाई',
      chatTone: 'चैट टोन',
      multiLanguage: 'बहु-भाषा',
      answerFormatting: 'उत्तर स्वरूपण',
      customPrompt: 'कस्टम प्रॉम्प्ट',
      integrateBotPenguinLLM: 'बॉट पेंगुइन एलएलएम एकीकरण',
      integrateOwnCustomLLM: 'अपना कस्टम एलएलएम एकीकरण',
      periodicallySyncDataSources: 'आकालिक रूप से डेटा स्रोत सिंक करें',
      aiBasedSuggestedQuestions: 'एआई आधारित सुझाए गए प्रश्न',
      chooseVisibilityForParticularWebpageSitemap: 'विशेष वेबपेज/साइटमैप के लिए दृश्यता चुनें',
      chooseKnowledgeMix: 'ज्ञान मिश्रण चुनें:\nआपका ज्ञान आधार\nचैटजीपीटी ज्ञान\n"चैटजीपीटी ज्ञान + आपका ज्ञान आधार"',
      faqExtractionFromFileAndURL: 'फ़ैक्यू निकासन फ़्रॉम फ़ाइल और यूआरएल',
      analyticsToImproveAI: 'एआई को सुधारने के लिए विश्लेषण',
      exportQuestionFlow: 'प्रश्न प्रवृत्ति निर्यात करें',
      sessionManagement: 'सत्र प्रबंधन',
      collectFeedbackOnChatbotResponses: 'चैटबॉट प्रतिसाद पर प्रतिक्रिया जमा करें',
      smartAIFollowUpQuestions: 'स्मार्ट/एआई फ़ॉलो अप प्रश्न',
      trainOnYourNotionWorkspaceData: 'अपने नोशन वर्कस्पेस डेटा पर प्रशिक्षित हों',
      trainOnYoutubeVideo: 'यूट्यूब वीडियो पर प्रशिक्षित हों',
      trainOnCustomVideoUpload: 'कस्टम वीडियो अपलोड पर प्रशिक्षित हों',
      rssFeed: 'आरएसएस फ़ीड',
      googleDrive: 'गूगल ड्राइव',
      dropBox: 'ड्रॉपबॉक्स',
      ipAndRegionBlocking: 'आईपी और क्षेत्र अवरोधन',
      emailAndMobileValidation: 'ईमेल और मोबाइल मान्यता',
      appointmentsBooking: 'अपॉइंटमेंट बुकिंग',
      humanHandover: 'मानव हैंडओवर',
      botHandover: 'बॉट हैंडओवर',
      handoverBetweenAgents: 'एजेंट्स के बीच हैंडओवर',
      mobileAppsForAgents: 'एजेंट्स के लिए मोबाइल ऐप्स',
      roleBasedAccessControls: 'भूमिका आधारित पहुंच नियंत्रण',
      apiIntegrationOnChatFlows: 'चैट फ्लो पर एपीआई एकीकरण',
      liveChatWidget: 'लाइव चैट विड्जेट',
      typingIndicator: 'टाइपिंग सूची',
      liveVisitorsDetails: 'लाइव आगंतुक विवरण',
      multipleSimultaneousChat: 'एक साथ कई चैट',
      liveTypingView: 'लाइव टाइपिंग व्यू',
      ruleBasedChatRouting: 'नियम आधारित चैट रूटिंग',
      chatAutoAssignments: 'चैट ऑटो-असाइनमेंट्स',
      efficientOmnichannelInterface: 'कुशल ओम्नीचैनल इंटरफेस',
      quickReplies: 'त्वरित उत्तर',
      unifiedCustomerProfile: 'एकीकृत ग्राहक प्रोफ़ाइल',
      visitorStats: 'आगंतुक आँकड़े',
      addContactsFromWhatsapp: 'व्हाट्सएप से संपर्क जोड़ें',
      applyFilterAndSortConversations: 'फ़िल्टर और बातचीत को क्रमित करें',
      seeChatLogs: 'चैट लॉग देखें',
      assignNotesAndChatTags: 'नोट और चैट टैग्स का निर्धारण करें',
      directCallsToLeadsWithTwilio: 'ट्विलियो के साथ लीड्स को सीधे कॉल करें',
      customerSegmentation: 'ग्राहक विभाजन',
      exportLeadsInCSV: 'CSV में लीड्स निर्यात करें',
      leadNotificationsByEmail: 'ईमेल द्वारा लीड अधिसूचनाएँ',
      website: 'वेबसाइट',
      wordPress: 'वर्डप्रेस',
      whatsApp: 'व्हाट्सएप',
      facebook: 'फेसबुक',
      telegram: 'टेलीग्राम',
      shopify: 'शॉपिफाई',
      autoTriggeringOnMobileDesktop: 'मोबाइल/डेस्कटॉप पर ऑटो-ट्रिगरिंग',
      hideBotOnSpecificPages: 'निर्दिष्ट पेज पर बॉट छिपाएं',
      imageSharing: 'छवि साझा करना',
      readyToUseChatFlowTemplates: 'तैयार उपयोग के लिए चैट फ्लो टेम्पलेट्स',
      fileSharing: 'फ़ाइल साझा करना',
      customDesignForChatWindow: 'चैट विंडो के लिए कस्टम डिज़ाइन',
      facebookMessengerBot: 'फेसबुक मैसेंजर बॉट',
      facebookSubheading: 'फेसबुक उपशीर्षक',
      telegramSubheading: 'टेलीग्राम उपशीर्षक',
      conversationalLangdingPageSubheading: 'संवादात्मक लैंडिंग पेज उपशीर्षक',
      integrationsSubheading: 'एकीकरण उपशीर्षक',
      multipleSmartFlows: 'मल्टीपल स्मार्ट फ़्लोज़',
      dripCampaigns: 'ड्रिप कैम्पेन्स',
      broadcastFBMessages: 'ब्रॉडकास्ट फेसबुक संदेश',
      multipleFacebookBotWidgets: 'मल्टीपल फेसबुक बॉट विड्जेट्स',
      facebookAutoReplyOnComments: 'फेसबुक टिप्पणियों पर ऑटो रिप्लाई',
      facebookECommerce: 'फेसबुक ई-कॉमर्स',
      broadcastAnalytics: 'ब्रॉडकास्ट विश्लेषण',
      telegramMessengerBot: 'टेलीग्राम मैसेंजर बॉट',
      readyToUseTemplates: 'तैयार उपयोग के लिए टेम्पलेट्स',
      groupBroadcastTelegramMessages: 'समूह और ब्रॉडकास्ट टेलीग्राम संदेश',
      multipleTelegramBotWidgets: 'मल्टीपल टेलीग्राम बॉट विड्जेट्स',
      conversationalLandingPageBot: 'संवादात्मक लैंडिंग पेज बॉट',
      smartReplies: 'स्मार्ट उत्तर',
      interactiveAndCustomizableUI: 'इंटरऐक्टिव और कस्टमाइज़ेबल यूआई',
      leadManagement: 'लीड प्रबंधन',
      easyToInstallAndShare: 'स्थापित करने और साझा करने में आसान',
      agileCRM: 'एजाइल सीआरएम',
      capsule: 'कैप्सूल',
      insightly: 'इंसाइटली',
      nimble: 'निम्बल',
      leadsquared: 'लीडस्क्वेयर्ड',
      chakra: 'चक्र',
      plivo: 'प्लिवो',
      helpcrunch: 'हेल्पक्रंच',
      getresponse: 'गेटरिस्पॉन्स',
      supportbee: 'सपोर्टबी',
      pipeline: 'पाइपलाइन',
      zendesk: 'जेंडेस्क',
      joget: 'जोगेट',
      firmao: 'फिरमाओ',
      kylas: 'क्यलास',
      close: 'बंद करें',
      firstpromoter: 'फर्स्टप्रोमोटर',
      zingtree: 'ज़िंगट्री',
      liveagent: 'लाइव एजेंट',
      ontraport: 'ऑनट्रापोर्ट',
      frontapp: 'फ्रंटएप',
      webhoper: 'वेबहोपर',
      engagebay: 'एंगेजबे',
      helpshift: 'हेल्पशिफ्ट',
      copper: 'कॉपर',
      autopilot: 'ऑटोपायलट',
      flowlu: 'फ्लोलू',
      freshdesk: 'फ्रेशडेस्क',
      salesflare: 'सेल्सफ्लेयर',
      teamgate: 'टीमगेट',
      shipway: 'शिपवे',
      googleSheets: 'गूगल शीट्स',
      googleCalendar: 'गूगल कैलेंडर',
      googleAnalytics: 'गूगल एनालिटिक्स',
      supportTicketingSystem: 'सपोर्ट टिकटिंग सिस्टम',
      zapier: 'जेपियर',
      pabbly: 'पैब्ली',
      hubSpot: 'हबस्पॉट',
      gitHub: 'गिटहब',
      salesforce: 'सेल्सफ़ोर्स',
      inChatAudioAndVideoCall: 'इन-चैट ऑडियो और वीडियो कॉल (ट्विलियो/आगोरा)',
      courierTracking: 'कुरियर ट्रैकिंग',
      emailIntegration: 'ईमेल एकीकरण',
      webhooks: 'वेबहुक्स',
      schedulers: 'स्केज्यूलर्स',
      paymentGateways: 'भुगतान गेटवे (स्ट्राइप और रेज़रपे)',
      enterpriseIntegrations: 'एंटरप्राइज एकीकरण',
      customIntegration: 'कस्टम एकीकरण',
      shopifyIntegration: 'शॉपिफ़ाई एकीकरण',
      totalNumberOfMessages: 'संदेशों की कुल संख्या',
      totalNumberOfLeads: 'लीड्स की कुल संख्या',
      weeklyReportBotwise: 'साप्ताहिक रिपोर्ट, बॉटवाइस',
      averageAgentResponeTime: 'औसत एजेंट प्रतिसाद समय',
      visitorTimingReport: 'आगंतुक समय रिपोर्ट',
      customerSegmentBasedReports: 'ग्राहक सेगमेंट आधारित रिपोर्ट',
      tagBasedReports: 'टैग आधारित रिपोर्ट',
      leadGenerationReport: 'लीड जनरेशन रिपोर्ट',
      supportOnWhatsApp: 'व्हाट्सएप पर समर्थन',
      knowledgeBase: 'ज्ञान आधार',
      onboardingAndTraining: 'ऑनबोर्डिंग और प्रशिक्षण',
      callAndEmailSupport: 'कॉल और ईमेल समर्थन',
      freeChatbotCreation: 'मुफ्त चैटबॉट निर्माण',
      customerSuccessOfficer: 'ग्राहक सफलता अधिकारी',
      secureConnection2048BitSSL: '2048 बिट एसएसएल सुरक्षित कनेक्शन',
      securityAssessment: 'सुरक्षा मूल्यांकन',
      dataCenterInUSOrEU: 'यूएस या यूई में डेटा केंद्र',
      twoStepVerification: 'दो-कदम सत्यापन',
      accessRestrictionsBasedOnIPs: 'आईपी पर आधारित पहुंच प्रतिष्ठान',
      onDemandAuditLog: 'मांग पर मंग प्रक्रिया लॉग',
      bannedVisitorsList: 'प्रतिष्ठान आगंतुक सूची',
      customAPIRate: 'कस्टम एपीआई दर',
      trustedDomains: 'विश्वसनीय डोमेन्स',
      mb100: '100 एमबी',
      gb1: '1 जीबी',
      codingCurious: 'कोडिंग क्यूरियस'
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'हमारी मुफ्त योजना',
      TOOLTIP_KING_PLAN: 'हमारी सबसे लोकप्रिय योजना',
      TOOLTIP_EMPEROR_PLAN: 'असीमित संदेशों, असीमित डेटा, असीमित टीम के सदस्यों के लिए अनुकूलित योजना',
      TOOLTIP_CONTACT_US: 'हमारे अनुकूलित योजना के बारे में अधिक जानने के लिए contact@botpenguin.com पर व्हाट्सएप या ईमेल के माध्यम से हमारे पास पहुंचें',
      TOOLTIP_UPGRADE: 'अतिरिक्त सुविधाओं के लिए अपनी वर्तमान योजना को अपग्रेड करें',
      TOOLTIP_MONTHLY: 'एक मासिक भुगतान चक्र का चयन करें',
      TOOLTIP_YEARLY: 'छूट लाभ के लिए वार्षिक भुगतान चक्र का चयन करें',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'बेबी प्लान पर जाएं',
    downgradeToFreePlan: 'मुक्त योजना के लिए डाउनग्रेड',
    weHaveGotPricingPerfectForYou: 'हमें एक मूल्य निर्धारण योजना मिली है जो आपके लिए एकदम सही है',
    weBelieve: 'हमें यकीन है',
    shouldBeAccessibleToAllCompanies: ' सभी कंपनियों के लिए सुलभ होना चाहिए, न कि आकार में कोई फर्क नहीं पड़ता।',
    monthly: 'महीने के',
    yearly: 'सालाना',
    twoMonthsFree: '(2 महीने मुफ़्त)',
    kingPlan: 'किंग योजना',
    perYear: 'प्रति वर्ष',
    perMonth: 'प्रति महीने',
    conversationsPerMonth: 'प्रति माह बातचीत',
    upgradePlan: 'अपग्रेड योजना',
    features: 'विशेषताएँ',
    emperorPlan: 'एम्परर योजना (एंटरप्राइज)',
    haveAsManyConversationsAsYouNeed: 'जितनी जरूरत है उतनी बातचीत करें',
    contactUs: 'संपर्क करें',
    metaConversationChargesAreAdditional: 'मेटा वार्तालाप शुल्क अतिरिक्त हैं और सीधे आपके द्वारा मेटा के लिए भुगतान किया जाता है, जो कि उपयोग के आधार पर है। हम कोई मार्कअप या सुविधा शुल्क नहीं लेते हैं।',
    viewDetailedPricing: 'विस्तृत मूल्य देखें',
    conversationPricingModelUsedByMeta: 'मेटा द्वारा उपयोग किए जाने वाले वार्तालाप मूल्य निर्धारण मॉडल',
    whatsappBusinessPlatformConversationsFallInto: 'व्हाट्सएप बिजनेस प्लेटफॉर्म वार्तालाप दो श्रेणियों में आते हैं जिनकी कीमत अलग -अलग है',
    userInitiatedConversation: 'उपयोगकर्ता द्वारा शुरू की गई बातचीत',
    userInitiatedConversationDescription: 'एक वार्तालाप जो उपयोगकर्ता संदेश के जवाब में शुरू होता है। जब भी कोई व्यवसाय 24 घंटे ग्राहक सेवा विंडो के भीतर किसी उपयोगकर्ता को जवाब देता है, तो यह संदेश उपयोगकर्ता द्वारा शुरू की गई बातचीत के साथ जुड़ा होगा।',
    businessInitiatedConversation: 'व्यापार-पहल बातचीत',
    businessInitiatedConversationDescription: 'एक वार्तालाप जो एक व्यवसाय से शुरू होता है, जो उपयोगकर्ता को 24 घंटे ग्राहक सेवा विंडो के बाहर एक संदेश भेजता है। एक व्यवसाय द्वारा शुरू की गई बातचीत शुरू करने वाले संदेशों के लिए एक संदेश टेम्पलेट की आवश्यकता होगी।',
    freeForever: 'हमेशा के लिए मुफ्त',
    currentPlan: 'वर्तमान योजना',
    changePlan: 'परिवर्तन योजना',
    currentlyActive: 'वर्तमान में सक्रिय',
    seeHowToUse: 'देखें कि कैसे उपयोग करें',
    areYouSureYouWantTo: 'क्या आप सुनिश्चित हैं कि आप से शिफ्ट करना चाहते हैं',
    conversations: ' बात चीत',
    plan: ' योजना',
    toThe: ' तक',
    metaPricingMarkup: 'मेटा मूल्य निर्धारण मार्कअप',
    numberOfChatFlows: 'चैट प्रवाह की संख्या',
    greenTickApplication: 'ग्रीन टिक आवेदन',
    extraPhoneNumberLinking: 'अतिरिक्त फोन नंबर लिंकिंग',
    teamMembers: 'टीम के सदस्य',
    dataStorage: 'आधार सामग्री भंडारण',
    conditionalFlow: 'सशर्त प्रवाह',
    liveChat: 'सीधी बातचीत',
    smartInbox: 'स्मार्ट इनबॉक्स',
    whatsappTemplates: 'व्हाट्सएप टेम्प्लेट',
    customerSegments: 'ग्राहक समूह',
    broadcastAndDripCampaigns: 'प्रसारण और ड्रिप अभियान',
    multipleLanguages: 'कई भाषाएं',
    bookAppointments: 'बही -नियुक्ति',
    csvContactsExport: 'सीएसवी संपर्क निर्यात',
    googleCalendarIntegrations: 'गूगल कैलेंडर एकीकरण',
    nativeApplications: '60+ देशी एकीकरण',
    whatsappSupport: 'व्हाट्सएप सपोर्ट',
    emailAndCallSupport: 'ईमेल और कॉल समर्थन',
    customerIntegrations: 'ग्राहक एकीकरण',
    nlpAndNluCapabilities: 'एनएलपी और एनएलयू क्षमताएं',
    chatGPTBot: 'चैटबॉट चैटबॉट',
    customerSuccessOfficer: 'ग्राहक सफलता अधिकारी',
    noMarkupTransparency: 'कोई मार्कअप पारदर्शिता नहीं',
    upto500kPerMonth: 'प्रति माह 500,000 तक',
    beyond500k: '500,000 से परे',
    unlimited: 'असीमित',
    oneTeamMember: '1 टीम का सदस्य',
    unlimitedTeamMember: 'असीमित टीम के सदस्य',
    nintyDaysOfDataStorage: '90 दिन का डेटा भंडारण',
    unlimitedDataStorage: 'असीमित डेटा भंडारण',
    detailedPricing: {
      onDemandAuditLog: 'डिमांड पर लेखा लॉग',
      accessRestrictionsBasedOnIPs: 'IPs पर आधारित पहुंच प्रतिबंध',
      averageAgentResponeTime: 'औसत एजेंट प्रतिक्रिया समय',
      perMonth100: 'माह 100',
      solutionType: 'समाधान प्रकार',
      saas: 'SaaS',
      saasOnPremises: 'SaaS On Premises',
      emperorPlan: 'सम्राट योजना',
      babyPlan: 'शिशु योजना',
      kingsplan: 'राजा की योजना',
      braodcastsAndDrips: 'प्रसारण और टपकाने',
      thirtyDays: '30 दिन',
      ninetyDays: '90 दिन',
      oneMember: '1 सदस्य',
      threeMembers: '3 सदस्य',
      one: '1 (एक)',
      unlimitedMessages: 'असीमित संदेश',
      unlimitedBots: 'असीमित बॉट्स',
      messagesSend: 'संदेश भेजें',
      upto500000perMonth: 'माह 500,000 तक',
      unlimited: 'असीमित',
      onlyAdmin: 'केवल प्रशासनिक',
      mb100: '100 मेगाबाइट',
      gb1: '1 जीबी',
      doItYourself: 'खुद करो',
      weAreHereToHelp: 'हम यहाँ मदद करने के लिए हैं',
      weTakeCareEndToEnd: 'हम अंत से अंत देखभाल करते हैं',
      numberOfBots: 'बॉट्स की संख्या',
      conversations: 'बातचीत',
      gptMessages: 'GPT संदेश',
      whatsappNumbers: 'WhatsApp नंबर्स',
      subscribersLeads: 'सब्सक्राइबर/लीड्स',
      createSupportTeamForLiveChat: 'लाइव चैट के लिए समर्थन टीम बनाएं',
      conversationHistory: 'बातचीत इतिहास',
      chatbotDesigning: 'चैटबॉट डिज़ाइनिंग',
      multipleLanguages: 'बहुभाषा',
      conditionalFlow: 'शर्तमुक्त धारा',
      whatsappTemplates: 'WhatsApp टेम्प्लेट्स',
      restartChatFlowTriggers: 'चैट फ्लो ट्रिगर को पुनः प्रारंभ करें',
      multipleWhatsAppWidgets: 'मल्टीपल WhatsApp विजेट्स',
      importWhatsappContacts: 'WhatsApp संपर्क आयात करें',
      whatsappQuickReply: 'WhatsApp त्वरित उत्तर',
      whatsappSmartInbox: 'WhatsApp स्मार्ट इनबॉक्स',
      whatsappEcommerceCatalogs: 'WhatsApp ई-कॉमर्स कैटलॉग्स',
      whatsappBroadcast: 'WhatsApp प्रसारण',
      whatsappBroadcastAnalytics: 'WhatsApp प्रसारण विश्लेषण',
      dripCampaigns: 'टपकाने अभियांत्रण',
      customerSegmentation: 'ग्राहक विभाजन',
      keywordBasedResponse: 'कीवर्ड पर आधारित प्रतिक्रिया',
      whatsappShopManagement: 'WhatsApp दुकान प्रबंधन',
      aiChatbot: 'AI चैटबॉट',
      chatGPTIntegration: 'ChatGPT संघटन',
      configureAIChatbotPersonality: 'AI चैटबॉट व्यक्तित्व कॉन्फ़िगर करें',
      trainChatbotOnYourOwnData: 'अपने डेटा पर चैटबॉट प्रशिक्षित करें',
      trainChatbotOnWebPages: 'वेब पेज पर चैटबॉट प्रशिक्षित करें',
      trainChatbotOnMultipleFiles: 'मल्टीपल फ़ाइलों पर चैटबॉट प्रशिक्षित करें',
      trainChatbotOnFAQs: 'FAQs पर चैटबॉट प्रशिक्षित करें',
      dataTrainingStorage: 'डेटा प्रशिक्षण संग्रहण',
      useChatGPTModel4or35Turbo: 'ChatGPT मॉडल 4/3.5 टर्बो का उपयोग करें',
      uploadMultipleFilesForChatbotTraining: 'चैटबॉट प्रशिक्षण के लिए मल्टीपल फ़ाइलें अपलोड करें',
      naturalLanguageProcessing: 'प्राकृतिक भाषा प्रसंस्करण',
      naturalLanguageUnderstanding: 'प्राकृतिक भाषा समझ',
      answerLength: 'उत्तर लंबाई',
      chatTone: 'चैट टोन',
      multiLanguage: 'बहुभाषा',
      answerFormatting: 'उत्तर स्वरूपण',
      customPrompt: 'कस्टम प्रॉम्प्ट',
      whatsAppSessionManagement: 'WhatsApp सत्र प्रबंधन (नृपविद्रान और फ्लो पुनरारंभ संदेश)',
      integrateBotPenguinLLM: 'BotPenguin LLM को एकत्र करें',
      integrateOwnCustomLLM: 'अपना कस्टम LLM एकत्र करें',
      periodicallySyncDataSources: 'समय-समय पर डेटा स्रोतों को सिंक्रनाइज़ करें',
      aiBasedSuggestedQuestions: 'AI पर आधारित सुझाई गई सवाल',
      chooseVisibilityForParticularWebpageSitemap: 'विशेष वेबपेज साइटमैप के लिए दृश्यता चुनें',
      chooseKnowledgeMix: 'ज्ञान मिश्रण चुनें: आपका ज्ञान आधार चैटGPT ज्ञान "चैटGPT ज्ञान + आपका ज्ञान आधार"',
      faqExtractionFromFileAndURL: 'फ़ाक निकालने के लिए फ़ाइल और URL',
      analyticsToImproveAI: 'AI को सुधारने के लिए विश्लेषण',
      exportQuestionFlow: 'प्रश्न फ्लो निर्यात करें',
      collectFeedbackOnChatbotResponses: 'चैटबॉट प्रतिक्रिया पर प्रतिक्रिया जमा करें',
      smartAIFollowUpQuestions: 'स्मार्ट/AI अनुभव प्रश्न',
      trainOnYourNotionWorkspaceData: 'अपने नोशन वर्कस्पेस डेटा पर प्रशिक्षण',
      trainOnYoutubeVideo: 'YouTube वीडियो पर प्रशिक्षण',
      trainOnCustomVideoUpload: 'कस्टम वीडियो अपलोड पर प्रशिक्षण',
      rssFeed: 'RSS फ़ीड',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'मानव हस्तांतरण',
      botHandover: 'बॉट हस्तांतरण',
      handoverBetweenAgents: 'एजेंट्स के बीच हस्तांतरण',
      emailAndMobileValidation: 'ईमेल और मोबाइल मान्यता',
      mobileAppsForAgents: 'एजेंट्स के लिए मोबाइल ऐप्स',
      appointmentBooking: 'अपॉइंटमेंट बुकिंग',
      roleBasedAccessControls: 'भूमिका के आधार पर पहुंच नियंत्रण',
      ruleBasedChatRouting: 'नियम के आधार पर चैट रूटिंग',
      liveVisitorsDetails: 'लाइव दर्शक विवरण',
      multipleSimultaneousChats: 'मल्टीपल समयानुपातिक चैट',
      visitorStats: 'दर्शक आँकड़े',
      addContactsFromWhatsApp: 'WhatsApp से संपर्क जोड़ें',
      applyFilterAndSortConversations: 'फ़िल्टर और बातचीत को क्रमबद्ध करें',
      seeChatLogs: 'चैट लॉग देखें',
      quickReplies: 'त्वरित उत्तर',
      unifiedCustomerProfile: 'एकीकृत ग्राहक प्रोफाइल',
      assignNotesAndChatTags: 'नोट और चैट टैग का काम सौंपें',
      exportLeadsInCSV: 'CSV में लीड्स निर्यात करें',
      tagContacts: 'संपर्कों को टैग करें',
      customAttributes: 'कस्टम गुण',
      markStatusOfConversations: 'बातचीत की स्थिति को चिह्नित करें',
      assignConversationToAgents: 'एजेंट्स को बातचीत का काम सौंपें',
      leadNotificationsByEmail: 'लीड नोटिफिकेशन ईमेल द्वारा',
      directCallsToLeadsWithTwilio: 'ट्विलियो के साथ लीड्स को सीधे कॉल करें',
      whatsAppTemplates: 'WhatsApp टेम्पलेट्स',
      promotionalMessageFrequencyControl: 'प्रसारण संदेश आवृत्ति नियंत्रण',
      whatsAppWidget: 'WhatsApp विजेट',
      whatsAppQR: 'WhatsApp क्यूआर',
      whatsAppLink: 'WhatsApp लिंक',
      campaignScheduler: 'कैम्पेन स्केड्यूलर',
      customTriggers: 'कस्टम ट्रिगर',
      customCampaigns: 'कस्टम कैम्पेन्स',
      repeatPurchaseReminders: 'फिर से खरीदी याददिलान',
      campaignOpenRateTracking: 'कैम्पेन खुलने की दर का ट्रैकिंग',
      paymentCollectionOnWhatsApp: 'WhatsApp पर भुगतान संग्रहण',
      agileCRM: 'एजाइल सीआरएम',
      capsule: 'कैप्सूल',
      insightly: 'इंसाइटली',
      nimble: 'निम्बल',
      leadsquared: 'लीडस्क्वेयर्ड',
      chakra: 'चक्र',
      plivo: 'प्लिवो',
      helpcrunch: 'हेल्पक्रंच',
      getresponse: 'गेटरिस्पॉन्स',
      supportbee: 'सपोर्टबी',
      pipeline: 'पाइपलाइन',
      zendesk: 'जेंडेस्क',
      joget: 'जोगेट',
      firmao: 'फर्माओ',
      kylas: 'काइलास',
      close: 'बंद करें',
      firstpromoter: 'पहले प्रमोटर',
      zingtree: 'ज़िंगट्री',
      liveagent: 'लाइव एजेंट',
      ontraport: 'ऑनट्रेपोर्ट',
      frontapp: 'फ्रंटएप',
      webhoper: 'वेबहोपर',
      engagebay: 'एंगेजबे',
      helpshift: 'हेल्पशिफ्ट',
      copper: 'कॉपर',
      autopilot: 'ऑटोपायलट',
      flowlu: 'फ्लोलू',
      freshdesk: 'फ्रेशडेस्क',
      salesflare: 'सेल्सफ्लेयर',
      teamgate: 'टीमगेट',
      shipway: 'शिपवे',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'समर्थन टिकटिंग सिस्टम',
      zapier: 'जपियर',
      pabbly: 'पैबली',
      hubSpot: 'हबस्पॉट',
      gitHub: 'गिटहब',
      salesforce: 'सेल्सफोर्स',
      inChatAudioAndVideoCall: 'चैट ऑडियो और वीडियो कॉल (ट्विलियो/आगोरा)',
      courierTracking: 'कुरियर ट्रैकिंग',
      emailIntegration: 'ईमेल एकीकरण',
      webhooks: 'वेबहुक्स',
      schedulers: 'शेड्यूलर्स',
      paymentGateways: 'भुगतान द्वार प्रवेश (स्ट्राइप और रेज़रपे)',
      enterpriseIntegrations: 'एंटरप्राइज इंटीग्रेशन',
      customIntegration: 'कस्टम इंटीग्रेशन',
      freeWhatsAppAPIApprovalAndSetup: 'मुफ्त WhatsApp API मंजूरी और सेटअप',
      developerAPIsAccess: 'डेवलपर API पहुंच',
      customRateLimit: 'कस्टम दर सीमा',
      totalNumberOfLeads: 'लीड्स की कुल संख्या',
      averageAgentResponseTime: 'औसत एजेंट प्रतिक्रिया समय',
      visitorTimingReport: 'दर्शक समय रिपोर्ट',
      customerSegmentBasedReports: 'ग्राहक वर्ग आधारित रिपोर्ट',
      tagBasedReports: 'टैग आधारित रिपोर्ट',
      leadGenerationReport: 'लीड जनरेशन रिपोर्ट',
      supportOnWhatsApp: 'WhatsApp पर समर्थन',
      knowledgeBase: 'ज्ञान आधार',
      onboardingAndTraining: 'ऑनबोर्डिंग और प्रशिक्षण',
      callAndEmailSupport: 'कॉल और ईमेल समर्थन',
      freeChatbotCreation: 'मुफ्त चैटबॉट निर्माण',
      customerSuccessOfficer: 'ग्राहक सफलता अधिकारी',
      secureConnection: '2048 बिट SSL सुरक्षित कनेक्शन',
      securityAssessment: 'सुरक्षा मूल्यांकन',
      dataCenterLocation: 'डेटा केंद्र स्थान',
      twoStepVerification: '2 कदम सत्यापन',
      accessRestrictions: 'पहुंच प्रतिबंध',
      auditLog: 'डिमांड पर लेखा लॉग',
      bannedVisitorsList: 'प्रतिबंधित दर्शक सूची',
      customAPIRate: 'कस्टम API दर',
      trustedDomains: 'विश्वसनीय डोमेन्स',
      elementary: 'प्राथमिक',
      bots: 'बॉट्स',
      unlockWhatsapp: 'WhatsApp को अनलॉक करें',
      aiChatbotFeatures: 'AI चैटबॉट सुविधाएँ',
      dataSourceForTraining: 'प्रशिक्षण के लिए डेटा स्रोत',
      smartFeatures: 'स्मार्ट सुविधाएँ',
      liveChat: 'लाइव चैट',
      smartInbox: 'स्मार्ट इनबॉक्स',
      broadcastsAndDrips: 'प्रसारण और टपकाने',
      integrations: 'एकत्रण',
      development: 'विकास',
      reportsAndAnalytics: 'रिपोर्ट और विश्लेषण',
      customerSupport: 'ग्राहक समर्थन',
      security: 'सुरक्षा',
      whatsappWidgets: 'WhatsApp विजेट',
      campaignSchedular: 'कैम्पेन स्केड्यूलर',
      repeatPurchaseRemainder: 'फिर से खरीदी याददिलान',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'हमारी मुफ्त योजना',
      TOOLTIP_KING_PLAN: 'हमारी सबसे लोकप्रिय योजना',
      TOOLTIP_EMPEROR_PLAN: 'असीमित संदेशों, असीमित डेटा, असीमित टीम के सदस्यों के लिए अनुकूलित योजना',
      TOOLTIP_CONTACT_US: 'हमारे अनुकूलित योजना के बारे में अधिक जानने के लिए contact@botpenguin.com पर व्हाट्सएप या ईमेल के माध्यम से हमारे पास पहुंचें',
      TOOLTIP_UPGRADE: 'अतिरिक्त सुविधाओं के लिए अपनी वर्तमान योजना को अपग्रेड करें',
      TOOLTIP_MONTHLY: 'एक मासिक भुगतान चक्र का चयन करें',
      TOOLTIP_YEARLY: 'छूट लाभ के लिए वार्षिक भुगतान चक्र का चयन करें',
    }
  }
};
