export const notification = {
  notification: 'Notifiche',
  allNotifications: 'Tutte le notifiche',
  general: 'Generale',
  settings: 'Impostazioni',
  newChatRequest: {
    youHaveANewChatRequest: 'Hai una nuova richiesta di chat !!!',
    youHaveTransferredChatRequest: 'Hai trasferito la richiesta di chat !!!',
    acceptRequest: 'Richiesta accettata',
    discardRequest: 'Richiesta di scarto',
  },
  notificationList: {
    name: 'Nome',
    message: 'Messaggio',
    noDataFound: {
      notification: 'Notifica',
      noNotificationsFound: 'Nessuna notifica trovata!',
    }
  },
  notificationSetting: {
    userMessage: 'Messaggio utente',
    receiveNotificationsWhenUserSendMessage: 'Ricevi notifiche ogni volta che un utente invia qualsiasi messaggio',
    liveChatRequest: 'Richiesta di chat dal vivo',
    receiveNotificationsWhenUserRequestLiveChat: 'Ricevi notifiche ogni volta che qualsiasi richiesta dell`utente per la chat live',
    newLead: 'Nuovo vantaggio',
    receiveNotificationsWhenNewLeadCreated: 'Ricevere notifiche ogni volta che vengono creati nuovi lead',
    assignedOnly: 'Solo assegnato',
    allUsers: 'Tutti gli utenti',
    customizeWhichNotificationsYouWantToRecieve: 'Personalizza quali notifiche che desideri ricevere',
    youCanCustomizeSettingForTheNotifications: 'È possibile personalizzare l`impostazione per le notifiche',
    receiveNotifications: 'Ricevere notifiche',
    desktopNotifications: 'Notifiche sul desktop',
    mobilePushNotifications: 'Notifiche push mobili',
    discard: 'Scartare',
    update: 'Aggiornamento',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Invia notifiche del nuovo messaggio su desktop',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Invia notifiche del nuovo messaggio su Mobile',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Invia notifiche del nuovo lead sul desktop',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Invia notifiche del nuovo lead sul cellulare',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Invia notifiche di chat dal vivo sul desktop',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Invia notifiche di chat dal vivo sul cellulare',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Scegli per quali messaggi desideri essere avvisato',
    }
  }
};
