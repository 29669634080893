import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() label = 'Month';
  @Input() dropdownStatus = false;
  @Input() selectedValue = 'Month';
  @Input() name: any | undefined = '';
  @Input() acceptValue: any = '';
  @Input() dropdownArray: any[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12];
  @Output() value = new EventEmitter<any>();
  @HostListener('document:click')
  clickout() {
   this.dropdownStatus = false;
  }
  constructor() { }

  ngOnInit(): void {
  }
  selectedItem(value: any) {
    this.dropdownStatus = false;
    if (this.acceptValue) {
      this.value.emit(value[this.acceptValue]);
    } else {
      this.value.emit(value);
    }
    if (this.name) {
      this.selectedValue = value[this.name];
    } else {
      this.selectedValue = value;
    }

  }
  showValue(item: any) {
    if (this.name) {
      return item[this.name];
    } else {
      return item;
    }
  }

}
