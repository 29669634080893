const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Привет,',
  greetingHand: '👋',
  upgrade: 'Обновление',
  integrate: 'Интегрировать',
  integrate2: 'Интегрировать',
  chatGPT: 'Чатгпт',
  forYourBusiness: 'для вашего бизнеса',
  insights: 'Понимание',
  message: 'Сообщения',
  addon: 'Добавить:',
  leads: 'Лидеры',
  yourBots: 'Ваши боты',
  botName: 'Название бота',
  platform: 'Платформа',
  contactOrLeads: 'Контакты/Ведущие',
  actions: 'Действия',
  edit: 'редактировать',
  delete: 'удалить',
  createA: 'Создать',
  whatsappChatbot: 'WhatsApp Chatbot',
  connect: 'Соединять',
  getStartedWithUs: 'Начните с нами 👏',
  howToBuildAGoodChatFlow: ' Как построить хороший чат -поток',
  howToUseBotPenguinInboxAndItsFeatures: ' Как использовать почтовые ящики Botpenguin и его функции',
  botPenguinPricing: ' Цена Botpenguin: изучение планов и функций',
  openHelpDesk: ' Открыть службу поддержки',
  helpAndSupport: 'Помощь и поддержка',
  extensiveGuides: 'Обширные руководства для всех ваших запросов и рабочих процессов доступны в наших',
  resourceCentre: 'Ресурсный центр.',
  checkoutOurAnswers: 'Вы можете проверить наши ответы на общие запросы ниже.',
  createANewBot: 'Создать нового бота',
  installYourBot: 'Установите свой бот',
  plansAndPricing: 'Планы и цены',
  searchLanguage: 'Язык поиска',
  whatsappConversations: 'WhatsApp разговоры',
  diveDeeperIntoBotPenguin: 'Погрузиться в ботпенгуин',
  knowWhereWeAreGoing: 'Знайте, куда мы идем, недавние обновления, и помогите нам исправить некоторые ошибки.',
  productUpdates: 'Обновления продукта',
  requestAFeature: 'Запросить функцию',
  reportABug: 'Сообщить об ошибке',
  productRoadmap: 'Дорожная карта продукта',
  contactsOrLeads: 'Контакты/Ведущие',
  english: 'Английский',
  hindi: 'хинди',
  german: 'Немецкий',
  french: 'Французский',
  portuguese: 'Португальский',
  spanish: 'испанский',
  italian: 'Итальянский',
  russian: 'Русский',
  pleaseSelectYourReason: 'Пожалуйста, выберите свою причину',
  cancel: 'Отмена',
  submit: 'Представлять на рассмотрение!',
  giveFeedback: 'Дать обратную связь',
  ratings: 'Рейтинги',
  reasonBehindYourDecision: 'Причина вашего решения',
  shareYourExperience: 'Поделитесь своим опытом',
  reason1: 'Причина1',
  reason2: 'Причина2',
  appTooltip: {
    HOME_INSIGHT: 'Предоставьте понимание всех сообщений и лидов вашего бота (данные обновляются каждую полуночью)',
    HOME_MESSAGES: 'Сообщения, потребляемые вашей учетной записью в течение этого цикла выставления счетов. (Сообщения от WhatsApp, Facebook и Telegram Bot исключены.)',
    HOME_TOTAL_MESSAGES: 'Общие сообщения, потребляемые вашей учетной записью с момента создания учетной записи (сообщения от WhatsApp, Facebook и Telegram Bot исключены.)',
    LEADS: 'Ведущие боты для вашего аккаунта во время этого цикла выставления счетов.',
    TOTAL_LEADS: 'Общее количество контактов/лидов, полученных ботами для вашей учетной записи с момента создания учетной записи (данные обновляются каждую полуночью).',
    YOUR_BOT: 'Обзор всех ваших ботов и их использования во время этого цикла выставления счетов.',
    NAME: 'Имя бота',
    PLATFORM: 'Платформа бота',
    MESSAGES: 'Общее количество сообщений BOT с момента создания учетной записи',
    CONTACTS: 'Общее количество контактов BOT/',
    ACTIONS: 'Редактировать/удалить бот',
    EDIT_ICON: 'Отредактируйте поток и настройки чата бота',
    DELETE_ICON: 'Удалить бот',
    TOOL_TIP_CLASSIC_VERSION: 'Старая версия $ {бренд} пользовательский интерфейс',
  },
  modal: {
    deleteBot: 'Удалите бот!',
    areYouSure: 'Вы уверены, что хотите удалить этого бота?',
    enquiryForm: 'Форма запроса',
    payNow: 'Заплатить сейчас!',
    saveAndDelete: 'Сохранить и удалить',
    submit: 'Представлять на рассмотрение',
  },
  noDataFound: {
    bots: 'Боты',
    noBotCreatedYet: 'Еще не создано боты!',
  },
  yourFriendWillMissYou: 'Ваш друг будет скучать по тебе',
  rateYourBotCreationExperience: 'Оцените свой опыт создания бота',
  whatMadeYouDeleteYourBot: 'Что заставило вас удалить своего бота?',
  iCreatedByMistake: 'Я создал по ошибке',
  iWantToCreateAnotherBot: 'Я хочу создать другого бота',
  couldNotFindFeaturesThatINeeded: ',',
  uiWasHardToNavigate: 'Пользовательский интерфейс был трудно ориентироваться',
  iDonotWantToTell: 'Я не хочу рассказывать',
  other: 'Другой',
  weWillDoOurBestToMakeAutomationFun: 'Мы сделаем все возможное, чтобы сделать автоматизацию забавным и простым для всех.',
};
