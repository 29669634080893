const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'マイアカウント',
  yourProfileAndPasswordSecurity: 'プロフィールとパスワードのセキュリティ',
  detailsHeader: '詳細',
  passwordHeader: 'パスワード',
  customAttributeHeader: 'カスタム属性',
  tagsHeader: 'タグ',
  accessTokenHeader: 'アクセストークン',
  thirdPartyIntegrationsHeader: 'サードパーティーの統合',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'アカウントプロフィールを更新',
    TOOLTIP_PASSWORD_HEADING: 'パスワードを変更',
    TOOLTIP_THIRD_PARTY_HEADING: 'サードパーティーの統合',
    TOOLTIP_TAGS_HEADING: 'タグを管理',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `セキュアなパスワードは、${brand} アカウントを保護します`,
    TOOLTIP_INTEGRATIONS: '統合',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'カスタム属性を追加または更新',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'カスタム属性を管理'
  },
  accessToken: {
    accessToken: 'アクセストークン',
    generateYourAccessTokenForUsingPlatformAPIs: `プラットフォームAPIを使用するためのアクセストークンを生成`,
    generateApiToken: 'APIトークンを生成',
    revokeApiToken: 'APIトークンを取り消す',
    regenerateApiToken: 'APIトークンを再生成',
    placeholders: {
      yourApiKey: 'APIキー',
    }
  },
  details: {
    name: '名前',
    emailId: 'メールID ',
    yourPlan: 'あなたのプラン',
    messagesPerMonth: 'メッセージ/月',
    daysOfStoragePlan: '保存プランの日数',
    conditionalFeatures: '条件付き機能',
    upgradeToEnterprisePlanToGetMore: 'さらに多くの機能とストレージ容量を得るには、エンタープライズプランにアップグレード',
    featuresAndStorageCapacity: '機能とストレージ容量',
    upgradePlan: 'プランをアップグレード',
    submit: '送信',
    selectLanguage: '言語を選択',
    baby: 'ベビー',
    king: 'キング',
    conversationsperMonth: '会話/月',
    english: '英語',
    hindi: 'ヒンディー語',
    german: 'ドイツ語',
    french: 'フランス語',
    portuguese: 'ポルトガル語',
    spanish: 'スペイン語',
    italian: 'イタリア語',
    russian: 'ロシア語',
    placeholders: {
      john: 'ジョン',
      email: 'メール',
      searchLanguage: '言語を検索'
    },
    appTooltip: {
      TOOLTIP_NAME: 'プロフィール名を入力',
      TOOLTIP_EMAIL_ADDRESS: 'メールアドレスを入力',
    }
  },
  customAttribute: {
    addAttribute: '属性の追加',
    discard: '破棄',
    customAttribute: 'カスタム属性',
    checkOrAddCustomAttribute: 'ここでカスタム属性を確認および追加できます。',
    attributeKey: '属性キー',
    attributeName: '属性名',
    bot: 'ボット',
    flow: 'フロー',
    loadMore: 'さらに読み込む',
    question: '質問',
    action: 'アクション',
    search: '検索',
    selectBot: 'ボットの選択',
    selectFlow: 'フローの選択',
    selectQuestion: '質問の選択',
    noDataFoundHeading: 'まだ作成された属性がありません',
    noDataFoundSubheading: 'すべての属性はここに表示されます',
    deleteTitle: '属性の削除',
    deleteDescription: 'この属性を削除してもよろしいですか？',
    deleteAttributes: '属性の削除',
    areYouSureYouWantToDeleteThisAttributes: 'この属性を削除してもよろしいですか？',
    editAttribute: '属性の編集',
    headsUp: 'ご注意',
    okay: '了解',
    dataAddedToLead: 'APIまたはボットフローにマッピングされた属性は更新されません。適切にAPIまたはボットフローを更新してください。',
    placeholders: {
      enterAttributeKey: '属性キーを入力',
      enterAttributeName: '属性名を入力',
      selectBot: 'ボットを選択',
      selectFlow: 'フローを選択',
      selectQuestion: '質問を選択',
      search: '属性名で検索',
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: '属性キーを入力',
      TOOLTIP_ATTRIBUTE_NAME: '属性名を入力',
      TOOLTIP_SELECT_BOT: 'ボットを選択',
      TOOLTIP_SELECT_FLOW: 'フローを選択',
      TOOLTIP_SELECT_QUESTION: '質問を選択',
    }
  },
  password: {
    changePassword: 'パスワードを変更',
    aSecurePasswordHelpsProtectYour: 'セキュアなパスワードは、',
    account: ' アカウント',
    currentPassword: '現在のパスワード',
    newPassword: '新しいパスワード',
    confirmPassword: 'パスワードの確認',
    cancel: 'キャンセル',
    updatePassword: 'パスワードを更新',
    deleteAccount: 'アカウントを削除',
    placeholders: {
      enterYourCurrentPassword: '現在のパスワードを入力',
      enterYourNewPassword: '新しいパスワードを入力',
      enterYourConfirmPassword: '確認用パスワードを入力',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: '古いパスワードを入力',
      TOOLTIP_NEW_PASSWORD: '新しいパスワードを入力',
      TOOLTIP_CONFIRM_PASSWORD: '新しいパスワードを確認',
      TOOLTIP_DELETE_ACCOUNT: `${brand} アカウントを削除`,
    }
  },
  tags: {
    manageTags: 'タグ管理',
    createNewTags: '新しいタグを作成',
    createNewTagsSubText: 'リード、チャット、グループなどをより効果的に整理するために、ここでタグを管理します',
    tagName: 'タグ名',
    updateTag: 'タグを更新',
    createTag: 'タグを作成',
    discard: '破棄',
    searchTag: '名前でタグを検索',
    noTagFound: 'タグが見つかりません',
    createATag: 'タグを作成',
    deleteTagModalSubText: 'このタグを削除してもよろしいですか？ このタグを割り当てられたユーザーからも削除されます！',
    deleteTagModalHeading: 'タグを削除',
    deleteOneTagModalHeading: 'タグを削除',
    actions: 'アクション',
    placeholders: {
      enterTag: 'タグ名を入力',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'タグを削除',
      TOOLTIP_MANAGE_TAGS: 'タグの管理',
    }
  },

  thirdPartyIntegration: {
    list: {
      name: '名前',
      status: 'ステータス',
      action: 'アクション',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'メッセージとメールの送信用のMessageBird',
      notConnected: '未接続',
      connected: '接続済み',
      edit: '編集',
      plivo: 'Plivo',
      plivoForSendingMessages: 'メッセージの送信用のPlivo',
      connect: '接続',
      appTooltip: {
        nameOfAppOrCrm: 'アプリ/CRMの名前',
        statusAsConnectedOrNotConnected: '接続/未接続のステータス',
        clickToConnectAppOrCrm: 'アプリ/CRMを接続するにはクリック',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'おっと！ベビープランでは統合が利用できません。統合を利用するにはKingプランにアップグレードしてください。',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'MessageBirdと統合',
        apiKey: 'APIキー',
        originator: '送信元',
        connect: '接続',
        disconnect: '切断',
        seeActivities: 'アクティビティを表示',
        sms: 'SMS ',
        time: '時間',
        phoneNumber: '電話番号',
        status: 'ステータス',
        botName: 'ボット名',
        message: 'メッセージ',
        error: 'エラー',
        placeholders: {
          apiKey: 'APIキー',
          messageBirdPhoneNumber: 'MessageBirdの電話番号',
        },
        noDataFound: {
          activities: 'アクティビティ',
          noActivitiesFound: 'アクティビティが見つかりませんでした！',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Plivoと統合',
        authId: '認証ID',
        authToken: '認証トークン',
        yourPlivoPhoneNumber: 'あなたのPlivo電話番号',
        connect: '接続',
        disconnect: '切断',
        seeActivities: 'アクティビティを表示',
        sms: 'SMS',
        time: '時間',
        phoneNumber: '電話番号',
        status: 'ステータス',
        botName: 'ボット名',
        message: 'メッセージ',
        error: 'エラー',
        noDataFound: {
          activities: 'アクティビティ',
          noActivitiesFound: 'アクティビティが見つかりませんでした！',
        },
        placeholders: {
          authId: '認証ID',
          authToken: '認証トークン',
          yourPlivoPhoneNumber: 'あなたのPlivo電話番号',
        }
      }
    }
  }
};
