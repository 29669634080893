import { Action, createReducer, on } from '@ngrx/store';
import { addProfile, loadProfile, updateProfile, deleteProfile } from '../actions/profile.actions';
import Profile from '../../models/profile';

export const profileFeatureKey = 'profile';


export const initialState: Profile | {} = {};

const profileReducer = createReducer(
  initialState,
  on(loadProfile, (state) => state),
  on(addProfile, (state, { profile }) => profile),
  on(deleteProfile, (state) => { }),
  on(updateProfile, (state, { update }) => ({  ...state, ...update }))
);

export function reducer(state: Profile, action: Action) {
  return profileReducer(state, action);
}
