export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Procurar',
    }
  },
  bpTable: {
    loadMore: 'Carregue mais',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'País de pesquisa',
    }
  },
  createNew: {
    continue: 'Continuar',
  },
  datePicker: {
    chooseADate: 'Escolha uma data',
  },
  dateRangePicker: {
    choosePeriod: 'Escolha o período',
    today: 'Hoje',
    lastWeek: 'Semana passada',
    lastMonth: 'Mês passado',
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Forneça o número que você deseja usar para testar',
    resendOtp: 'Reenvie otp',
    enterOtp: 'Digite otp',
    verifyOtp: 'Verifique o OTP',
    sendOtp: 'Enviar OTP',
    cancel: 'Cancelar',
    placeholders: {
      phone: 'Telefone*',
      enterOtp: 'Digite OTP*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Selecione seu motivo',
    cancel: 'Cancelar',
    submit: 'Enviar!',
    giveFeedback: 'Dê retorno',
    ratings: 'Classificações',
    reasonBehindYourDecision: 'Razão por trás de sua decisão',
    shareYourExperience: 'Compartilhe sua experiência',
    reason1: 'Razão1',
    reason2: 'Razão2',
  },
  feedbackModal: {
    paymentIsInProgress: 'O pagamento está em andamento',
    pleaseDoNotCloseThisWindow: 'Por favor, não feche esta janela',
    placeholders: {
      name: 'Nome',
      email: 'E-mail*',
      phone: 'Telefone*',
      pleaseShareYourUseCase: 'Compartilhe sua USECASE',
    }
  },
  layout: {
    sidebar: {
      notifications: 'Notificações',
      myAccount: 'Minha conta',
      logout: 'Sair',
      goOnline: 'Fique online',
      manage: 'Gerenciar',
      deactivate: 'Desativar',
      activate: 'Ativar',
      delete: 'Excluir',
      home: 'Lar',
      bots: 'Bots',
      inbox: 'Caixa de entrada',
      analytics: 'Análise',
      team: 'Equipe',
      subscriptions: 'Assinaturas',
      more: 'Mais',
      users: 'Usuários',
      partnerTeam: 'Equipe parceira',
      createNewBot: 'Crie novo bot',
      dashboard: 'Painel',
      customers: 'Clientes',
      plans: 'Planos',
      developer: 'Desenvolvedor',
      activities: 'Atividades',
      feedbacks: 'Feedbacks',
      configurations: 'Configurações',
      copy: 'cópia de',
      yourFriendWillMissYou: 'Seu amigo vai sentir sua falta',
      rateYourBotCreationExperience: 'Avalie sua experiência de criação de bot',
      whatMadeYouDeleteYourBot: 'O que fez você excluir seu bot?',
      iCreatedByMistake: 'Eu criei por engano',
      iWantToCreateAnotherBot: 'Eu quero criar outro bot',
      couldNotFindFeaturesThatINeeded: ',',
      uiWasHardToNavigate: 'Ui era difícil de navegar',
      iDonotWantToTell: ',',
      other: 'Outro',
      weWillDoOurBestToMakeAutomationFun: 'Faremos o possível para tornar a automação divertida e simples para todos.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Alternar para permitir ou bloquear solicitações de bate -papo ao vivo',
        TOOLTIP_NOTIFICATION: 'Todas as notificações',
        TOOLTIP_MY_ACCOUNT: 'Seu perfil e segurança de senha',
        TOOLTIP_LOGOUT: 'Logout do painel',
      },
      modal: {
        logout: 'Sair',
        botDeleteFeedbackForm: 'Formulário de feedback de exclusão de bot',
        areYouSureToLogOut: 'Tem certeza que deseja sair?',
        yesIWillBeBack: 'Sim! eu retornarei',
        deleteBot: 'Excluir bot',
        areYouSureToDeleteBot: 'Tem certeza de que deseja excluir este bot?',
        cancel: 'Cancelar',
        saveAndDelete: 'Salvar e excluir',
      }
    }
  },
  modal: {
    cancel: 'Cancelar',
    yesDelete: 'Sim, Excluir!',
    continue: 'Continuar'
  },
  unsavedChanges: {
    title: 'Alterações não salvas',
    description: 'Pode haver alterações não salvas. Tem certeza de que deseja sair?'
  },
  notify: {
    youHave: 'Você tem',
    new: ' novo',
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Sugerimos escolher o logotipo da sua empresa. Adicione uma imagem quadrada PNG ou JPG sob 1 MB.',
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Opa! O Plano de Baby oferece apenas um bot grátis. Atualize -se para o rei planeja criar mais bots.',
    noIAmGood: 'Não, eu sou bom',
    iWantToUpgrade: 'Eu quero atualizar',
    oops: 'Ops! ',
    planOfferOnlyOneFreeBot: ' OFERECE APENAS UM BOT GRATUITO. Faça um upgrade para o plano ',
    planToCreateMoreBots: ' criar mais bots.'
  },
  webPreview: {
    preview: ' Visualização',
    botsMessages: 'Mensagens de bots',
    bot: 'Robô',
    eleven36AM: '11:36',
    usersReply: 'Resposta do usuário',
    landingPageBot: 'Página de destino Bot',
    chatbot: 'Chatbot',
    chat: 'Bater papo',
    placeholders: {
      typeYourAnswer: 'Digite sua resposta',
    }
  },
  paymentLink: {
    discardPayment: 'Anular o pagamento',
    completePayment: 'Finalizar o pagamento',
    importantUpdate: 'Atualização Importante',
    accountUpdate: 'Atualização de conta',
    youHave: 'Você tem',
    exceededTheMessagesLimit: 'Você superou o limite de mensagens',
    ofYour: 'de sua',
    planAnd: 'Plano e',
    botsAreNoLongerActive: 'Os bots não estão mais ativos',
    onYourWebsite: 'no seu site.',
    youHaveEitherIncompletePaymentOn: 'Você tem um pagamento incompleto ativo',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'ou você não atualizou com sucesso seu plano',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'ou você não rotulou com sucesso seu bot',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'ou você não comprou com sucesso o “complemento de mensagens”.',
    please: 'Por favor',
    complete: 'Finalizar',
    upgrade: 'Atualizar',
    services: 'Serviços',
    yourPlanToContinueServices: 'plano para continuar os serviços.',
    yourPaymentToContinue: 'pagamento para continuar.',
    forAnyQueryPleaseWriteUs: 'Para qualquer dúvida escreva-nos',
    close: 'Fechar',
    writeOn: '',
    upgradePlan: 'Atualizar Plano',
    discardModal: {
      title: 'Anular o pagamento',
      description: 'Você tem certeza que deseja anular o pagamento?',
      confirmText: 'Sí, anula o pagamento'
    }
  },
  bpExport: {
    exportStatus: 'Status de exportação',
    tasksExport: 'Tarefas- exportação',
    download: 'Baixar',
    inProgress: 'em andamento',
  }
};
