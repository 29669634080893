import { createAction, props } from '@ngrx/store';


export const loadNewLiveChatRequests = createAction(
    '[LiveChatRequests] New Chat Requests',
    props<{ requests: {} | any }>()
);

export const removeLiveChatRequests = createAction(
    '[RemoveLiveChatRequests] Remove Live Chat Requests',
    props<{ requestId: string }>()
);
