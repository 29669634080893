import { createAction, props } from '@ngrx/store';
import { Widget } from 'src/appv2/models/tool';

export const loadSocialTools = createAction(
  '[Tools] Load Social Tools',
  props<{ tools: Widget[] }>()
);

export const addSocialTool = createAction(
  '[Tools] Add Social Tool',
  props<{ tool: any | {} }>()
);

export const deleteSocialTool = createAction(
  '[Tools] Delete Social Tool',
  props<{ tool: string | number }>()
);
export const updateSocialTool = createAction(
  '[Tools] Update Social Tool',
  props<{ tool: string | number, update: any | {} }>()
);




