export const agency = {
  activities: {
    activities: 'الأنشطة',
    upcomingRenewals: 'تجديدات قادمة',
    subscriptionActivities: 'أنشطة الاشتراك',
    upcomingDowngradeRequests: 'طلبات التخفيض القادمة',
    serialNumber: 'الرقم التسلسلي',
    email: 'البريد الإلكتروني',
    phoneNumber: 'رقم الهاتف',
    currentPlan: 'الخطة الحالية',
    price: 'السعر',
    daysLeft: 'أيام متبقية',
    noDataFound: {
      upcomingDowngrades: 'تخفيضات قادمة',
      noUpcomingDowngradesFoundYet: 'لا توجد تخفيضات قادمة حتى الآن!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'التجديدات القادمة للعملاء',
      subscriptionActivities: 'أنشطة الاشتراك',
      upcomingDowngradeRequestsOfCustomers: 'طلبات التخفيض القادمة للعملاء',
      serialNumber: 'الرقم التسلسلي',
      customerEmail: 'بريد العميل',
      customerPhoneNumber: 'رقم هاتف العميل',
      currentPlanOfCustomer: 'الخطة الحالية للعميل',
      currentPlanPrice: 'سعر الخطة الحالية للعميل',
      daysLeft: 'الأيام المتبقية من خطة العميل الحالية',
    },
  },
  configuration: {
    configurationsHeader: 'التكوينات',
    emailHeader: 'البريد الإلكتروني',
    razorPayHeader: 'RazorPay',
    invoiceDetailsHeader: 'تفاصيل الفاتورة',
    stripeHeader: 'Stripe',
    appTooltip: {
      integrateYourEmailProviderDetails: 'قم بدمج تفاصيل مزود البريد الإلكتروني الخاص بك لإرسال رسائل البريد الإلكتروني للعملاء بسلاسة.',
      enterYourRazorPayAccountDetails: 'أدخل تفاصيل حساب RazorPay الخاص بك لمعالجة مدفوعات العملاء بسهولة.',
      enterYourStripeAccountDetails: 'أدخل تفاصيل حساب Stripe الخاص بك لمعالجة مدفوعات العملاء بسهولة.',
      enterYourCompanyDetails: 'أدخل تفاصيل شركتك لإصدار الفواتير الدقيقة وإنشاء فواتير احترافية.',
    },
    email: {
      testEmail: 'اختبار البريد الإلكتروني',
      addEmailIntegrationsDetails: 'إضافة تفاصيل التكامل مع البريد الإلكتروني',
      integrationDescription: 'لدمج تفاصيل مزود البريد الإلكتروني الخاص بك، يرجى تقديم معلومات مزود البريد الإلكتروني الخاص بك.' +
        'سيتم إرسال جميع رسائل البريد الإلكتروني إلى عملائك باستخدام عنوان البريد الإلكتروني هذا، مما يضمن تسليم الإشعارات والتحديثات المهمة بشكل متسق وموثوق.',
      awsRegion: 'منطقة AWS',
      awsAccessKeyId: 'معرف مفتاح الوصول إلى AWS',
      awsSecretAccessKey: 'مفتاح الوصول السري إلى AWS',
      reset: 'إعادة تعيين',
      addDetails: 'إضافة التفاصيل',
      updateDetails: 'تحديث التفاصيل',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'أدخل منطقة AWS الخاصة بك. تحدد المنطقة الموقع الجغرافي لمواردك وتؤثر على عوامل مثل تأخير البيانات والامتثال التنظيمي.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'أدخل معرف مفتاح الوصول إلى AWS الخاص بك. يُستخدم هذا المفتاح لتحديد هوية حساب AWS الخاص بك بشكل فريد وهو مطلوب للمصادقة الآمنة والوصول إلى خدمات AWS.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'أدخل مفتاح الوصول السري إلى AWS الخاص بك. يُستخدم هذا المفتاح للمصادقة الآمنة والوصول إلى خدمات AWS.',
      },
      placeholders: {
        enterAwsRegion: 'أدخل منطقة AWS',
        enterAwsAccessKeyId: 'أدخل معرف مفتاح الوصول إلى AWS',
        enterAwsSecretAccessKey: 'أدخل مفتاح الوصول السري إلى AWS',
      },
      modal: {
        deleteEmailIntegration: 'حذف تكامل البريد الإلكتروني',
        wantToDeleteEmailIntegration: 'هل أنت متأكد أنك تريد حذف تكامل البريد الإلكتروني هذا؟',
      }
    },

    facebook: {
      addFacebookIntegrationsDetails: 'إضافة تفاصيل التكامل الفيسبوك',
      integrateYourEmailProviderDetails: 'دمج تفاصيل مزود البريد الإلكتروني الخاص بك. سيتم إرسال جميع رسائل البريد الإلكتروني باستخدام هذا البريد إلى عملائك',
      facebookAppId: 'معرف تطبيق Facebook',
      facebookSecretAccessKey: 'مفتاح الوصول السري لـ Facebook',
      reset: 'إعادة تعيين',
      cancel: 'إلغاء',
      addDetails: 'إضافة التفاصيل',
      updateDetails: 'تحديث التفاصيل',
      delete: 'حذف',
      testEmail: 'اختبار البريد الإلكتروني',
      modal: {
        deleteFacebookIntegration: 'حذف تكامل Facebook',
        wantToDeleteFacebookIntegration: 'هل أنت متأكد أنك تريد حذف تكامل البريد الإلكتروني هذا؟'
      },
      placeholders: {
        enterFacebookAccessKeyId: 'أدخل مفتاح الوصول السري لـ Facebook',
        enterFacebookAppId: 'أدخل معرف تطبيق Facebook'
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'قم بتخصيص الرسالة كما تريد أن تظهر في نافذة الدردشة مباشرة بعد أن يرسل مستخدم الروبوت طلبًا للدردشة المباشرة'
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'إضافة تفاصيل الفاتورة',
      invoicesDescription: 'لإنشاء فواتير لعملائك، يرجى تقديم تفاصيل شركتك، بما في ذلك اسم الشركة، والعنوان، ومعلومات الاتصال، وأي معلومات أخرى ذات صلة مطلوبة لإصدار فواتير دقيقة.' +
        'سيتم استخدام هذه المعلومات لإنشاء فواتير احترافية نيابة عن شركتك لكل عملية.',
      companyName: 'اسم الشركة',
      country: 'البلد',
      selectCountry: 'حدد البلد',
      stateOrRegion: 'الولاية/المنطقة',
      streetAddress: 'عنوان الشارع',
      gstin: 'GSTIN',
      postalCode: 'الرمز البريدي',
      addDetails: 'إضافة التفاصيل',
      updateDetails: 'تحديث التفاصيل',
      reset: 'إعادة تعيين',
      delete: 'حذف',
      loadMore: 'تحميل المزيد',
      time: 'الوقت',
      phoneNumber: 'رقم الهاتف',
      email: 'البريد الإلكتروني',
      status: 'الحالة',
      message: 'الرسالة',
      response: 'الاستجابة',
      placeOfSupply: 'مكان التوريد',
      placeholders: {
        enterYourCompanyName: 'أدخل اسم شركتك',
        enterYourStateOrRegion: 'أدخل ولايتك/منطقتك',
        enterYourStreetAddress: 'أدخل عنوان الشارع الخاص بك',
        enterYourGstin: 'أدخل GSTIN الخاص بك',
        enterPostalCode: 'أدخل الرمز البريدي الخاص بك',
        enterPlaceOfSupply: 'أدخل مكان التوريد الخاص بك'
      },
      modal: {
        deleteEmailIntegration: 'حذف تكامل البريد الإلكتروني',
        wantToDeleteEmailIntegration: 'هل أنت متأكد أنك تريد حذف تكامل البريد الإلكتروني هذا؟',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME:
          'أدخل اسم شركتك. سيتم استخدام هذه المعلومات لأغراض التعرف والعلامة التجارية في الفواتير.',
        TOOLTIP_COUNTRY: 'اختر بلدك. سيتم استخدام هذه المعلومات لأغراض التعرف والعلامة التجارية في الفواتير.',
        TOOLTIP_STATE: 'أدخل ولايتك/منطقتك. سيتم استخدام هذه المعلومات لأغراض التعرف والعلامة التجارية في الفواتير.',
        TOOLTIP_STREET_ADDRESS: 'أدخل عنوان الشارع الخاص بك. سيتم استخدام هذه المعلومات لأغراض التعرف والعلامة التجارية في الفواتير.',
        TOOLTIP_GSTIN: 'أدخل GSTIN الخاص بك. سيتم استخدام هذه المعلومات لأغراض التعرف والعلامة التجارية في الفواتير.',
        TOOLTIP_POSTAL_CODE: 'أدخل الرمز البريدي الخاص بك. سيتم استخدام هذه المعلومات لأغراض التعرف والعلامة التجارية في الفواتير.',
        TOOLTIP_PLACE_OF_SUPPLY: 'أدخل مكان التوريد الخاص بك. سيتم استخدام هذه المعلومات لأغراض التعرف والعلامة التجارية في الفواتير.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'إضافة تفاصيل تكامل RAZORPAY',
      integrationDescription: 'لدمج تفاصيل حساب RazorPay الخاص بك، يرجى تقديم معلومات حساب RazorPay الخاص بك.' +
        ' سيتم معالجة جميع مدفوعات العملاء من خلال حساب RazorPay الخاص بك لعمليات سلسة',
      razorPayAccessKeyId: 'معرف مفتاح الوصول لـ RAZORPAY',
      razorPaySecretAccessKey: 'مفتاح الوصول السري لـ RAZORPAY',
      reset: 'إعادة تعيين',
      addDetails: 'إضافة التفاصيل',
      updateDetails: 'تحديث التفاصيل',
      loadMore: 'تحميل المزيد',
      time: 'الوقت',
      phoneNumber: 'رقم الهاتف',
      email: 'البريد الإلكتروني',
      status: 'الحالة',
      message: 'الرسالة',
      response: 'الاستجابة',
      placeholders: {
        enterRazorPayAccessKeyId: 'أدخل معرف مفتاح الوصول لـ Razorpay',
        enterRazorPaySecretAccessKey: 'أدخل مفتاح الوصول السري لـ Razorpay',
        enterRazorPayAccessId: 'أدخل معرف الوصول لـ RazorPay',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'أدخل معرف مفتاح الوصول لـ Razorpay الخاص بك. يميز هذا المفتاح تمييزًا فريدًا لتكاملك' +
          ' مع Razorpay وهو مطلوب لعمليات الدفع الآمنة.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'أدخل مفتاح الوصول السري لـ Razorpay الخاص بك. يعد هذا المفتاح ضروريًا للتواصل الآمن بين التطبيق وRazorpay، مما يمكن من معالجة المعاملات بسلاسة وإدارة الدفع.',
      },
      modal: {
        deleteRazorPayIntegration: 'حذف تكامل البريد الإلكتروني',
        wantToDeleteThisIntegrtion: 'هل أنت متأكد أنك تريد حذف هذا التكامل البريدي؟',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'إضافة تفاصيل التكامل مع Stripe',
      integrationDescription: 'لدمج تفاصيل حساب Stripe الخاص بك، يرجى تقديم معلومات حساب Stripe الخاص بك.' +
        ' سيتم معالجة جميع مدفوعات العملاء من خلال حساب Stripe الخاص بك لعمليات سلسة',
      stripeSecretAccessKey: 'مفتاح الوصول السري لـ Stripe',
      stripePublicKey: 'مفتاح الوصول العام لـ Stripe',
      reset: 'إعادة تعيين',
      addDetails: 'إضافة التفاصيل',
      updateDetails: 'تحديث التفاصيل',
      cancel: 'إلغاء',
      loadMore: 'تحميل المزيد',
      time: 'الوقت',
      phoneNumber: 'رقم الهاتف',
      email: 'البريد الإلكتروني',
      status: 'الحالة',
      message: 'الرسالة',
      response: 'الاستجابة',
      placeholders: {
        enterStripeSecretAccessKey: 'أدخل مفتاح الوصول السري لـ Stripe',
        enterStripePublicKey: 'أدخل مفتاح الوصول العام لـ Stripe',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'أدخل مفتاح الوصول السري لـ Stripe الخاص بك. يتم استخدام هذا المفتاح للمصادقة بشكل آمن على تكاملك مع Stripe وهو ضروري لمعالجة مدفوعات العملاء.',
        TOOLTIP_PUBLIC_KEY: 'أدخل مفتاح الوصول العام لـ Stripe الخاص بك. يتم استخدام هذا المفتاح للمصادقة بشكل آمن على تكاملك مع Stripe على الجانب العميل وهو ضروري لمعالجة مدفوعات العملاء.',
      },
      modal: {
        deleteStripeIntegration: 'حذف تكامل Stripe',
        wantToDeleteStripeIntegration: 'هل أنت متأكد أنك تريد حذف هذا التكامل مع Stripe؟'
      }
    }
  },
  customer: {
    manageCustomers: 'إدارة العملاء',
    addNewCustomers: 'إضافة عملاء جدد',
    ensureYouHaveAddedAllYourCustomersHere: 'تأكد من أنك قد أضفت جميع عملائك هنا',
    customerName: 'اسم العميل',
    nameIsRequired: 'الاسم مطلوب',
    emailId: 'معرف البريد الإلكتروني',
    enterAValidEmail: 'أدخل بريدًا إلكترونيًا صالحًا',
    createPassword: 'إنشاء كلمة مرور',
    enterAStrongPassword: 'أدخل كلمة مرور قوية',
    confirmPassword: 'تأكيد كلمة المرور',
    cancel: 'إلغاء',
    customer: 'العميل',
    searchByNameOrEmail: 'البحث بالاسم أو البريد الإلكتروني',
    filters: 'المرشحات',
    allCustomers: 'جميع العملاء',
    paidCustomers: 'العملاء المدفوعين',
    freeCustomers: 'العملاء المجانيين',
    messageAddOnCustomers: 'عملاء إضافة الرسالة',
    botWhiteLabelCustomers: 'عملاء Bot Whitelabel',
    sortBy: 'الترتيب حسب',
    sortByFrequency: 'الترتيب حسب التكرار',
    all: 'الكل',
    joinedDate: 'تاريخ الانضمام',
    plan: 'الخطة',
    lastLogin: 'آخر تسجيل دخول',
    email: 'البريد الإلكتروني',
    nextDue: 'المقبلة',
    frequency: 'التكرار',
    clearAll: 'مسح الكل',
    apply: 'تطبيق',
    messageConsumed: 'استهلاك الرسائل',
    addCustomer: 'إضافة عميل',
    serialNumber: 'الرقم التسلسلي',
    name: 'الاسم',
    contact: 'الاتصال',
    limit: 'الحد',
    location: 'الموقع',
    actions: 'الإجراءات',
    joinedOn: 'انضم في',
    typeOfCustomer: 'نوع العميل',
    exceptWhatsapp: ' (باستثناء Whatsapp)',
    exceptWA: ' (باستثناء WA)',
    wa: ' (WA)',
    loadMore: 'تحميل المزيد',
    conversationsWhatsapp: 'محادثات (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'أدخل بريد العميل الإلكتروني',
      enterCustomerPassword: 'أدخل كلمة مرور العميل',
      enterCustomerConfirmPassword: 'أدخل تأكيد كلمة مرور العميل',
      enterCustomerName: 'أدخل اسم العميل',
    },
    placeholders: {
      enterCustomerEmail: 'أدخل بريد العميل',
      searchByNameOrEmail: 'البحث بالاسم أو البريد الإلكتروني',
      enterPassword: 'أدخل كلمة المرور',
      enterCustomerName: 'أدخل اسم العميل',
    }
  },
  dashboard: {
    dashboard: 'لوحة القيادة',
    overview: 'نظرة عامة',
    totalCustomers: 'إجمالي العملاء',
    paidCustomers: 'العملاء المدفوعين',
    freeCustomers: 'العملاء المجانيين',
    thisWeeksSignUp: 'التسجيلات هذا الأسبوع',
    todaysSignUp: 'التسجيلات اليوم',
    totalEarnings: 'إجمالي الأرباح',
    nA: 'غير متوفر',
    customerName: 'اسم العميل',
    nameIsRequired: 'الاسم مطلوب',
    emailId: 'معرف البريد الإلكتروني',
    enterAValidEmail: 'ادخل بريدًا إلكترونيًا صالحًا',
    editCustomer: 'تحرير العميل',
    recentSignUps: 'آخر التسجيلات',
    serialNumber: 'الرقم التسلسلي',
    name: 'الاسم',
    email: 'البريد الإلكتروني',
    contact: 'جهة الاتصال',
    plan: 'الخطة',
    limit: 'الحد',
    location: 'الموقع',
    lastLogin: 'آخر تسجيل دخول',
    joinedOn: 'انضم في',
    actions: 'الإجراءات',
    exceptWhatsapp: ' (باستثناء واتساب)',
    messageConsumed: 'الرسائل المستهلكة',
    conversationsWhatsapp: 'المحادثات (واتساب)',
    placeholders: {
      enterCustomerName: 'أدخل اسم العميل',
      enterCustomerEmail: 'أدخل بريد العميل',
    },
    modal: {
      deleteCustomer: 'حذف العميل',
      areYouSure: 'هل أنت متأكد أنك تريد حذف هذا العميل؟',
    }
  },

  downgradeRequests: {
    activites: 'الأنشطة',
    upcomingRenewals: 'التجديدات القادمة',
    subscriptionActivities: 'أنشطة الاشتراك',
    upcomingDonwgradeRequests: 'طلبات التخفيض القادمة',
    serialNumber: 'الرقم التسلسلي',
    email: 'البريد الإلكتروني',
    phoneNumber: 'رقم الهاتف',
    currentPlan: 'الخطة الحالية',
    requestedOn: 'طلب في',
    downgradeOn: 'التخفيض في',
    nA: 'غير متوفر',
    commaMonthly: '، شهريًا',
    commaYearly: '، سنويًا',
    noDataFound: {
      downgradeRequests: 'طلبات التخفيض',
      noDowngradeRequestFound: 'لم يتم العثور على طلبات تخفيض حتى الآن!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'التجديدات القادمة للعملاء',
      subscriptionActivities: 'أنشطة الاشتراك',
      upcomingDowngradeOfCustomers: 'طلبات تخفيض العملاء القادمة',
      serialNumber: 'الرقم التسلسلي',
      customerEmail: 'بريد العميل',
      customerPhoneNumber: 'رقم هاتف العميل',
      currentPlanOfCustomer: 'الخطة الحالية للعميل',
      currentPlanPrice: 'سعر الخطة الحالي',
    }
  },
  emailIntegration: {
    emailIntegration: 'تكامل البريد الإلكتروني',
    addEmailIntegrationDetails: 'إضافة تفاصيل تكامل البريد الإلكتروني',
    integrateYourEmailProviderDetails: 'قم بتكامل تفاصيل مزود البريد الإلكتروني الخاص بك. سيتم إرسال جميع الرسائل البريدية باستخدام هذا البريد إلى عملائك',
    awsRegion: 'منطقة AWS',
    awsRegionIsRequired: 'المنطقة AWS مطلوبة',
    awsAccessKeyId: 'معرف مفتاح الوصول AWS',
    awsAccessKeyIdIsRequired: 'معرف مفتاح الوصول AWS مطلوب',
    awsSecretAccessKey: 'مفتاح الوصول السري لـ AWS',
    awsSecretAccessKeyIsRequired: 'مفتاح الوصول السري لـ AWS مطلوب',
    cancel: 'إلغاء',
    test: 'اختبار',
    action: 'إجراء',
    testEmail: 'اختبار البريد الإلكتروني',
    addDetails: 'إضافة التفاصيل',
    updateDetails: 'تحديث التفاصيل',
    saveDetails: 'حفظ التفاصيل',
    placeholders: {
      enterAwsRegion: 'أدخل منطقة AWS',
      enterAwsAccessKeyId: 'أدخل معرف مفتاح الوصول AWS',
      enterAwsSecretAccessKey: 'أدخل مفتاح الوصول السري لـ AWS',
    },
    modal: {
      deleteEmailIntegration: 'حذف تكامل البريد الإلكتروني',
      areYouSure: 'هل أنت متأكد أنك تريد حذف هذا التكامل البريدي؟',
    },
    noDataFound: {
      emailIntegration: 'تكامل البريد الإلكتروني',
      noEmailIntegrationFoundYet: 'لم يتم العثور على تكامل بريدي حتى الآن!'
    }
  },

  subscription: {
    activities: 'الأنشطة',
    upcomingRenewals: 'التجديدات القادمة',
    subscriptionActivities: 'أنشطة الاشتراك',
    upcomingDonwgradeRequests: 'طلبات التخفيض القادمة',
    filters: 'مرشحات',
    manageActivities: 'إدارة الأنشطة',
    sortBy: 'ترتيب حسب',
    all: 'الكل',
    upgrades: 'الترقيات',
    donwgrades: 'التخفيضات',
    renewals: 'التجديد',
    serialNumber: 'الرقم التسلسلي',
    email: 'البريد الإلكتروني',
    phoneNumber: 'رقم الهاتف',
    previousPlan: 'الخطة السابقة',
    newPlan: 'الخطة الجديدة',
    description: 'الوصف',
    date: 'التاريخ',
    nA: 'غير متوفر',
    upgraded: 'تم الترقية',
    commaYearly: '، سنويًا',
    commaMonthly: '، شهريًا',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'لم يتم العثور على أنشطة اشتراك حتى الآن!',
      subscriptionsActivities: 'أنشطة الاشتراك',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'التجديدات القادمة للعملاء',
      subscriptionActivities: 'أنشطة الاشتراك',
      upcomingDowngradeRequests: 'طلبات تخفيض العملاء القادمة',
      serialNumber: 'الرقم التسلسلي',
      customerEmail: 'بريد العميل',
      customerPhoneNumber: 'رقم هاتف العميل',
      previousPlan: 'الخطة السابقة للعميل',
      newPlan: 'الخطة الجديدة للعميل',
      description: 'وصف الخطة',
      typeOfPayment: 'نوع الدفع',
    }
  },

  feedbacks: {
    feedbacks: 'الملاحظات',
    serialNumber: 'الرقم التسلسلي',
    customer: 'العميل',
    type: 'النوع',
    rating: 'التقييم',
    reason: 'السبب',
    description: 'الوصف',
    createdAt: 'تاريخ الإنشاء',
    nA: 'غير متوفر',
    loadMore: 'تحميل المزيد',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'ملاحظات حذف الحساب والروبوت',
      noFeedbacksFoundYet: 'لم يتم العثور على ملاحظات حتى الآن!',
    },
    appTooltip: {
      serialNumber: 'الرقم التسلسلي',
      customerEmail: 'بريد العميل',
      feedbackForBotOrAccountDeletion: 'ملاحظات حذف الحساب أو الروبوت',
      customerRating: 'تقييم العميل',
      customerReason: 'سبب العميل',
      feedbackCreatedAt: 'تاريخ إنشاء الملاحظة',
      customerFeedback: 'ملاحظات العميل',
    }
  }
};
