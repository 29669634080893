export const agency = {
  activities: {
    activities: 'Atividades',
    upcomingRenewals: 'Próximas renovações',
    subscriptionActivities: 'Atividades de assinatura',
    upcomingDowngradeRequests: 'Próximos pedidos de rebaixamento',
    serialNumber: 'Sr. Não.',
    email: 'E-mail',
    phoneNumber: 'Telefone não.',
    currentPlan: 'Plano atual',
    price: 'Preço',
    daysLeft: 'Dias restantes',
    noDataFound: {
      upcomingDowngrades: 'Os próximos rebaixamentos',
      noUpcomingDowngradesFoundYet: 'Nenhum dos próximos rebaixamentos encontrados ainda!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Próximas renovações dos clientes',
      subscriptionActivities: 'Atividades de assinatura',
      upcomingDowngradeRequestsOfCustomers: 'Próximos pedidos de rebaixamento dos clientes',
      serialNumber: 'Número de série',
      customerEmail: 'Email do cliente',
      customerPhoneNumber: 'Número de telefone do cliente',
      currentPlanOfCustomer: 'Plano atual do cliente',
      currentPlanPrice: 'Preço do plano atual',
      daysLeft: 'Dias restantes do Plano atual dos clientes',
    },
  },
  configuration: {
    configurationsHeader: 'Configurações',
    emailHeader: 'E-mail',
    razorPayHeader: 'Razorpay',
    invoiceDetailsHeader: 'Detalhes da fatura',
    stripeHeader: 'Listra',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Integre os detalhes do seu provedor de e -mail para enviar e -mails de clientes sem problemas.',
      enterYourRazorPayAccountDetails: 'Digite os detalhes da sua conta Razorpay para o processamento contínuo de pagamento do cliente.',
      enterYourStripeAccountDetails: 'Digite os detalhes da sua conta do Stripe para o processamento contínuo de pagamento do cliente.',
      enterYourCompanyDetails: 'Digite os detalhes da sua empresa para obter faturamento preciso e geração de faturas profissionais.',
    },
    email: {
      testEmail: 'Email de teste',
      addEmailIntegrationsDetails: 'Adicionar detalhes de integração por e -mail',
      integrationDescription: 'Para integrar os detalhes do seu provedor de e -mail, forneça as informações do seu provedor de e -mail. Todos os e -mails de comunicação serão enviados aos seus clientes usando este endereço de e -mail, garantindo uma entrega consistente e confiável de notificações e atualizações importantes.',
      awsRegion: 'Região da AWS',
      awsAccessKeyId: 'AWS Access Key ID',
      awsSecretAccessKey: 'Chave de acesso secreto da AWS',
      reset: 'Reiniciar',
      addDetails: 'Adicione detalhes',
      updateDetails: 'Atualizar detalhes',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Digite sua região da AWS. A região determina a localização física de seus recursos e afeta fatores como latência de dados e conformidade regulatória.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'Digite seu ID da chave de acesso AWS. Essa chave identifica exclusivamente sua conta da AWS e é necessária para autenticação segura e acesso aos serviços da AWS.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Digite sua chave de acesso secreto da AWS. Essa chave é usada para autenticação segura e acesso aos serviços da AWS.',
      },
      placeholders: {
        enterAwsRegion: 'Digite a região da AWS',
        enterAwsAccessKeyId: 'Digite ID da chave de acesso da AWS',
        enterAwsSecretAccessKey: 'Digite a chave de acesso secreto da AWS',
      },
      modal: {
        deleteEmailIntegration: 'Excluir integração por e -mail',
        wantToDeleteEmailIntegration: 'Tem certeza de que deseja excluir esta integração de email?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Adicionar detalhes de integração do Facebook',
      integrateYourEmailProviderDetails: 'Integre os detalhes do seu provedor de e -mail. Todos os e -mails serão enviados usando este e -mail para seus clientes',
      facebookAppId: 'ID do aplicativo do Facebook',
      facebookSecretAccessKey: 'Chave de acesso secreto do Facebook',
      reset: 'Reiniciar',
      cancel: 'Cancelar',
      addDetails: 'Adicione detalhes',
      updateDetails: 'Atualizar detalhes',
      delete: 'Excluir',
      testEmail: 'Email de teste',
      modal: {
        deleteFacebookIntegration: 'Exclua a integração do Facebook',
        wantToDeleteFacebookIntegration: 'Tem certeza de que deseja excluir esta integração de email?',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Digite a chave de acesso secreto do Facebook',
        enterFacebookAppId: 'Digite ID do aplicativo do Facebook',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalize a mensagem como você deseja que ela apareça na janela de bate -papo imediatamente depois que um usuário de bot envia uma solicitação de chat ao vivo',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Adicione detalhes da fatura',
      invoicesDescription: 'Para gerar faturas para seus clientes, forneça os detalhes da sua empresa, incluindo o nome da sua empresa, endereço, informações de contato e quaisquer outras informações relevantes necessárias para um faturamento preciso. Essas informações serão usadas para criar faturas profissionais em nome da sua empresa para cada transação.',
      companyName: 'nome da empresa',
      country: 'País',
      selectCountry: 'Selecione o pais',
      stateOrRegion: 'Estado/região',
      streetAddress: 'Endereço da Rua',
      gstin: 'Gstin',
      postalCode: 'Código postal',
      addDetails: 'Adicione detalhes',
      updateDetails: 'Atualizar detalhes',
      reset: 'Reiniciar',
      delete: 'Excluir',
      loadMore: 'Carregue mais',
      time: 'Tempo',
      phoneNumber: 'Número de telefone',
      email: 'E-mail',
      status: 'Status',
      message: 'Mensagem',
      response: 'Resposta',
      placeOfSupply: 'Lugar de compra',
      placeholders: {
        enterYourCompanyName: 'Digite o nome da sua empresa',
        enterYourStateOrRegion: 'Digite seu estado/região',
        enterYourStreetAddress: 'Digite seu endereço de rua',
        enterYourGstin: 'Digite seu gstin',
        enterPostalCode: 'Digite o código postal',
        enterPlaceOfSupply: 'Digite o lugar de compra',
      },
      modal: {
        deleteEmailIntegration: 'Excluir integração por e -mail',
        wantToDeleteEmailIntegration: 'Tem certeza de que deseja excluir esta integração de email?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: 'Digite o nome da sua empresa. Essas informações serão usadas para fins de identificação e marca em faturas.',
        TOOLTIP_COUNTRY: 'Escolha o seu país. Essas informações serão usadas para fins de identificação e marca em faturas.',
        TOOLTIP_STATE: 'Entre no seu estado. Essas informações serão usadas para fins de identificação e marca em faturas.',
        TOOLTIP_STREET_ADDRESS: 'Digite seu endereço de rua. Essas informações serão usadas para fins de identificação e marca em faturas.',
        TOOLTIP_GSTIN: 'Digite seu gstin. Essas informações serão usadas para fins de identificação e marca em faturas.',
        TOOLTIP_POSTAL_CODE: 'Digite seu código postal. Essas informações serão usadas para fins de identificação e marca em faturas.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Digite o lugar de compra. Essas informações serão usadas para fins de identificação e marca em faturas.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'Adicionar detalhes de integração Razorpay',
      integrationDescription: 'Para integrar os detalhes da sua conta Razorpay, forneça as informações da sua conta Razorpay. Todos os pagamentos dos clientes serão processados ​​por meio de sua conta Razorpay para transações perfeitas',
      razorPayAccessKeyId: 'ID da chave de acesso Razorpay',
      razorPaySecretAccessKey: 'Chave de acesso secreto de Razorpay',
      reset: 'Reiniciar',
      addDetails: 'Adicione detalhes',
      updateDetails: 'Atualizar detalhes',
      loadMore: 'Carregue mais',
      time: 'Tempo',
      phoneNumber: 'Número de telefone',
      email: 'E-mail',
      status: 'Status',
      message: 'Mensagem',
      response: 'Resposta',
      placeholders: {
        enterRazorPayAccessKeyId: 'Digite ID da chave de acesso Razorpay',
        enterRazorPaySecretAccessKey: 'Digite a chave de acesso secreto da Razorpay',
        enterRazorPayAccessId: 'Digite Razorpay Access ID',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Digite seu ID da chave de acesso Razorpay. Essa chave identifica exclusivamente sua integração com a Razorpay e é necessária para transações de pagamento seguras.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Digite sua chave de acesso secreto do Razorpay. Essa chave é essencial para a comunicação segura entre a aplicação e a Razorpay, permitindo o processamento de transações e gerenciamento de pagamentos sem costura.',
      },
      modal: {
        deleteRazorPayIntegration: 'Excluir integração por e -mail',
        wantToDeleteThisIntegrtion: 'Tem certeza de que deseja excluir esta integração de email?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Adicionar detalhes de integração de faixas',
      integrationDescription: 'Para integrar os detalhes da sua conta do Stripe, forneça as informações da conta do Stripe. Todos os pagamentos dos clientes serão processados ​​por meio de sua conta de faixa para transações perfeitas',
      stripeSecretAccessKey: 'Chave de acesso secreto de listras',
      stripePublicKey: 'Chave pública Stripe',
      reset: 'Reiniciar',
      addDetails: 'Adicione detalhes',
      updateDetails: 'Atualizar detalhes',
      cancel: 'Cancelar',
      loadMore: 'Carregue mais',
      time: 'Tempo',
      phoneNumber: 'Número de telefone',
      email: 'E-mail',
      status: 'Status',
      message: 'Mensagem',
      response: 'Resposta',
      placeholders: {
        enterStripeSecretAccessKey: 'Digite a chave de acesso secreto do Stripe',
        enterStripePublicKey: 'Digite a chave pública Stripe',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Digite sua chave de acesso secreto de listras. Essa chave é usada para autenticar com segurança sua integração com o Stripe e é necessário para o processamento de pagamentos dos clientes.',
        TOOLTIP_PUBLIC_KEY: 'Digite sua chave pública Stripe. Essa chave é usada para autenticar com segurança sua integração com a faixa no lado do cliente e é necessária para o processamento de pagamentos dos clientes.',
      },
      modal: {
        deleteStripeIntegration: 'Excluir integração de faixas',
        wantToDeleteStripeIntegration: 'Tem certeza de que deseja excluir essa integração de faixas?',
      }
    }
  },
  customer: {
    manageCustomers: 'Gerenciar clientes',
    addNewCustomers: 'Adicione novos clientes',
    ensureYouHaveAddedAllYourCustomersHere: 'Certifique -se de ter adicionado todos os seus clientes aqui',
    customerName: 'nome do cliente',
    nameIsRequired: 'O nome é necessário',
    emailId: 'Identificação do email',
    enterAValidEmail: 'Entre com um email válido',
    createPassword: 'Criar senha',
    enterAStrongPassword: 'Digite uma senha forte',
    confirmPassword: 'Confirme sua senha',
    cancel: 'Cancelar',
    customer: 'Cliente',
    searchByNameOrEmail: 'Pesquise por nome ou e -mail',
    filters: 'Filtros',
    allCustomers: 'Todos os clientes',
    paidCustomers: 'Clientes pagos',
    freeCustomers: 'Clientes gratuitos',
    messageAddOnCustomers: 'Mensagem Adicionar sobre clientes',
    botWhiteLabelCustomers: 'BOT WHITELABEL CLIENTES',
    sortBy: 'Ordenar por',
    sortByFrequency: 'Classificar por frequência',
    all: 'Todos',
    joinedDate: 'Data ingressada',
    plan: 'Plano',
    lastLogin: 'Último Login',
    email: 'E-mail',
    nextDue: 'Próximo devido',
    frequency: 'Frequência',
    clearAll: 'Limpar tudo',
    apply: 'Aplicar',
    messageConsumed: 'Mensagem consumida',
    addCustomer: 'Adicionar cliente',
    serialNumber: 'Sr. Não.',
    name: 'Nome',
    contact: 'Contato',
    limit: 'Limite',
    location: 'Localização',
    actions: 'Ações',
    joinedOn: 'Juntou -se',
    typeOfCustomer: 'Tipo de cliente',
    exceptWhatsapp: ' (Exceto WhatsApp)',
    exceptWA: '(Exceto WA)',
    wa: '(WA)',
    loadMore: 'Carregue mais',
    conversationsWhatsapp: ' Conversas (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'Insira o e -mail do cliente',
      enterCustomerPassword: 'Digite a senha do cliente',
      enterCustomerConfirmPassword: 'Digite o cliente Confirme a senha',
      enterCustomerName: 'Digite o nome do cliente',
    },
    placeholders: {
      enterCustomerEmail: 'Insira o e -mail do cliente',
      searchByNameOrEmail: 'Pesquise por nome ou e -mail',
      enterPassword: 'Digite a senha',
      enterCustomerName: 'Digite o nome do cliente',
    }
  },
  dashboard: {
    dashboard: 'Painel',
    overview: 'Visão geral',
    totalCustomers: 'Total de clientes',
    paidCustomers: 'Clientes pagos',
    freeCustomers: 'Clientes gratuitos',
    thisWeeksSignUp: 'Esta semana se inscreveu',
    todaysSignUp: 'As inscrições de hoje',
    totalEarnings: 'Ganhos totais',
    nA: 'N / D',
    customerName: 'Nome do cliente',
    nameIsRequired: 'O nome é necessário',
    emailId: 'Identificação do email',
    enterAValidEmail: 'Entre com um email válido',
    editCustomer: 'Editar cliente',
    recentSignUps: 'Assinaturas recentes',
    serialNumber: 'Sr. Não.',
    name: 'Nome',
    email: 'E-mail',
    contact: 'Contato',
    plan: 'Plano',
    limit: 'Limite',
    location: 'Localização',
    lastLogin: 'Último Login',
    joinedOn: 'Juntou -se',
    actions: 'Ações',
    exceptWhatsapp: ' (Exceto WhatsApp)',
    messageConsumed: 'Mensagem consumida',
    conversationsWhatsapp: 'Conversas (WhatsApp)',
    placeholders: {
      enterCustomerName: 'Digite o nome do cliente',
      enterCustomerEmail: 'Insira o e -mail do cliente',
    },
    modal: {
      deleteCustomer: 'Excluir cliente',
      areYouSure: 'Tem certeza de que deseja excluir este cliente?',
    }
  },
  downgradeRequests: {
    activites: 'Atividades',
    upcomingRenewals: 'Próximas renovações',
    subscriptionActivities: 'Atividades de assinatura',
    upcomingDonwgradeRequests: 'Próximos pedidos de rebaixamento',
    serialNumber: 'Sr. Não.',
    email: 'E-mail',
    phoneNumber: 'Telefone não.',
    currentPlan: 'Plano atual',
    requestedOn: 'Solicitado em',
    downgradeOn: 'Rebaixando',
    nA: 'N / D',
    commaMonthly: ', Por mês',
    commaYearly: ' , Anual',
    noDataFound: {
      downgradeRequests: 'Pedidos de rebaixamento',
      noDowngradeRequestFound: 'Nenhum pedido de rebaixamento ainda encontrado!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Próximas renovações dos clientes',
      subscriptionActivities: 'Atividades de assinatura',
      upcomingDowngradeOfCustomers: 'Próximos pedidos de rebaixamento dos clientes',
      serialNumber: 'Número de série',
      customerEmail: 'Email do cliente',
      customerPhoneNumber: 'Número de telefone do cliente',
      currentPlanOfCustomer: 'Plano atual do cliente',
      currentPlanPrice: 'Preço do plano atual',
    }
  },
  emailIntegration: {
    emailIntegration: 'Integração por e -mail',
    addEmailIntegrationDetails: 'Adicionar detalhes de integração por e -mail',
    integrateYourEmailProviderDetails: 'Integre os detalhes do seu provedor de e -mail. Todos os e -mails serão enviados usando este e -mail para seus clientes',
    awsRegion: 'Região da AWS',
    awsRegionIsRequired: 'A região da AWS é necessária',
    awsAccessKeyId: 'AWS Access Key ID',
    awsAccessKeyIdIsRequired: 'AWS Access Key ID é necessária',
    awsSecretAccessKey: 'Chave de acesso secreto da AWS',
    awsSecretAccessKeyIsRequired: 'A chave de acesso secreto da AWS é necessária',
    cancel: 'Cancelar',
    test: 'Teste',
    action: 'Ação',
    testEmail: 'Email de teste',
    addDetails: 'Adicione detalhes',
    updateDetails: 'Atualizar detalhes',
    saveDetails: 'Salve detalhes',
    placeholders: {
      enterAwsRegion: 'Digite a região da AWS',
      enterAwsAccessKeyId: 'Digite ID da chave de acesso da AWS',
      enterAwsSecretAccessKey: 'Digite a chave de acesso secreto da AWS',
    },
    modal: {
      deleteEmailIntegration: 'Excluir integração por e -mail',
      areYouSure: 'Tem certeza de que deseja excluir esta integração de email?',
    },
    noDataFound: {
      emailIntegration: 'Integração por e -mail',
      noEmailIntegrationFoundYet: 'Nenhuma integração de e -mail ainda encontrada!',
    }
  },
  subscription: {
    activities: 'Atividades',
    upcomingRenewals: 'Próximas renovações',
    subscriptionActivities: 'Atividades de assinatura',
    upcomingDonwgradeRequests: 'Próximos pedidos de rebaixamento',
    filters: 'Filtros',
    manageActivities: 'Gerenciar atividades',
    sortBy: 'Ordenar por',
    all: 'Todos',
    upgrades: 'Atualizações',
    donwgrades: 'Rebaixamentos',
    renewals: 'Renovação',
    serialNumber: 'Sr. Não.',
    email: 'E-mail',
    phoneNumber: 'Telefone não.',
    previousPlan: 'Plano anterior',
    newPlan: 'Novo plano',
    description: 'Descrição',
    date: 'Data',
    nA: 'N / D',
    upgraded: 'atualizado',
    commaYearly: ' , Anual',
    commaMonthly: ' , Por mês',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'Ainda não foram encontradas atividades de assinatura!',
      subscriptionsActivities: 'Atividades de assinaturas',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Próximas renovações dos clientes',
      subscriptionActivities: 'Atividades de assinatura',
      upcomingDowngradeRequests: 'Próximos pedidos de rebaixamento dos clientes',
      serialNumber: 'Número de série',
      customerEmail: 'Email do cliente',
      customerPhoneNumber: 'Número de telefone do cliente',
      previousPlan: 'Plano anterior do cliente',
      newPlan: 'Novo plano do cliente',
      description: 'Descrição do plano',
      typeOfPayment: 'Tipo de pagamento',
    }
  },
  feedbacks: {
    feedbacks: 'Feedbacks',
    serialNumber: 'Sr. Não.',
    customer: 'Cliente',
    type: 'Tipo',
    rating: 'Avaliação',
    reason: 'Razão',
    description: 'Descrição',
    createdAt: 'Criado em',
    nA: 'N / D',
    loadMore: 'Carregue mais',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Feedbacks de exclusão de conta e exclusão de bot',
      noFeedbacksFoundYet: 'Ainda não foram encontrados feedbacks!',
    },
    appTooltip: {
      serialNumber: 'Número de série',
      customerEmail: 'Email do cliente',
      feedbackForBotOrAccountDeletion: 'Feeback para (exclusão de bot/conta)',
      customerRating: 'Avaliação do cliente',
      customerReason: 'Razão do cliente',
      feedbackCreatedAt: 'Feedback criado em',
      customerFeedback: 'Feedback do cliente',
    }
  }
};
