import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { State } from '../ngrx/reducers';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  GET_CUSTOMERS = 'customer/list';
  IMPERSONATE = 'customer/impersonate/';
  DELETE_CUSTOMER = 'customer/';
  ADD_CUSTOMER = 'customer';
  EDIT_CUSTOMER = 'customer/';
  COUNT_CUSTOMER = 'old-analytics/customers-count';
  state: State;

  constructor(
    public networkService: NetworkService,
    private store: Store<State>
  ) {
    store
      .select((state) => state)
      .subscribe((state) => {
        this.state = state;
      });
  }

  async getCustomer(page: number = 1, queryString = '&sort=createdAt', filter?: string) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(filter ? `${this.GET_CUSTOMERS}?page=${page}${queryString}&email=${filter}` : this.GET_CUSTOMERS + '?page=' + page + queryString, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async impersonate(id: string) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.IMPERSONATE + id, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async deleteCustomer(id: string) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.DELETE_CUSTOMER + id, {}, 'DELETE', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async addCustomer(data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.ADD_CUSTOMER, data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async editCustomer(id, data: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.EDIT_CUSTOMER + id, data, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  countCustomers() {
    return new Promise<{ paidCount: number, freeCount: number, dayWise: [number, number] }>((resolve, reject) => {
      this.networkService.httpRequest(this.COUNT_CUSTOMER, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  getAnalytics() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('customer/analytics', null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  getConsumedMessages() {
    return this.networkService.httpRequest('agencies/limit', null, 'GET', true);
  }

  public getAgencyActivities() {
    return this.networkService.httpRequest('payment/subscription-activity/upcoming-renewal');
  }

  public getSubscriptionActivities(page, filter) {
    if (!filter) {
      return this.networkService.httpRequest(`payment/subscription-activity?page=${page}`);
    }
    return this.networkService.httpRequest(`payment/subscription-activity?page=${page}&description=${filter}`);
  }

  public getUpcomingDowngradeRequests() {
    return this.networkService.httpRequest('payment/subscription-activity/upcoming-downgrade-requests');
  }

  public getAgencyFeedbacks(page: number) {
    return this.networkService.httpRequest(`feedback/agency?page=${page}`);
  }

  getDomain(type: 'landingPageAI' | 'landingPage' | 'messenger' | 'cdn' | 'api' | 'bot-script') {
    let value = '';

    if (this.state && this.state.agencyDetails && this.state.agencyDetails.permission === 'WHITE_LABEL' &&
      this.state.agencyDetails.meta && this.state.agencyDetails.meta.domain && this.state.agencyDetails.meta.domain[type]) {

      value = this.state.agencyDetails.meta.domain[type];
    }

    if (!value) {
      switch (type) {
        case 'api':
          value = environment.baseUrl;
          break;
        case 'landingPageAI':
          value = environment.conversationalBot;
          break;
        case 'landingPage':
          value = environment.landingPage;
          break;
        case 'messenger':
          value = environment.embeddedBot;
          break;
        case 'cdn':
          value = environment.cdnUrl;
          break;
        case 'bot-script':
          value = environment.botScript;
          break;
        default:
          value = `error-${type}`;
          break;
      }
    }

    return value.charAt(value.length - 1) === '/'
      ? value.slice(0, -1)
      : value;

  }
}
