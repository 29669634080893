const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'नमस्ते,',
  greetingHand: '👋',
  upgrade: 'उन्नत करना',
  integrate2: 'अपने व्यवसाय के लिए',
  integrate: 'एकीकृत करें',
  chatGPT: 'ChatGPT',
  forYourBusiness: ' एकीकृत करें',
  insights: 'इनसाइट्स',
  message: 'संदेश',
  addon: 'ऐड ऑन:',
  leads: 'लीड्स',
  yourBots: 'आपके बॉट्स',
  botName: 'बॉट नाम',
  platform: 'प्लैटफ़ॉर्म',
  contactOrLeads: 'संपर्क/लीड',
  actions: 'कार्रवाई',
  edit: 'संपादन करना',
  delete: 'मिटाना',
  // createA: 'एक बनाएं ',
  createA: 'अपने व्यवसाय के लिए',
  whatsappChatbot: ' व्हाट्सएप चैटबॉट',
  forYourWhatsappBusiness: ' बनाएं ',
  connect: 'जोड़ना',
  getStartedWithUs: 'हमारे साथ शुरुआत करो 👏',
  howToBuildAGoodChatFlow: ' कैसे एक अच्छा चैटफ़्लो बनाएं',
  howToUseBotPenguinInboxAndItsFeatures: 'बॉटपेंगुइन के इनबॉक्स और इसकी विशेषताओं का उपयोग कैसे करें',
  botPenguinPricing: ' बॉटपेंगुइन की कीमत: योजनाओं और सुविधाओं को जाने ',
  openHelpDesk: ' हेल्प डेस्क खोले ',
  helpAndSupport: 'सहायता और समर्थन',
  extensiveGuides: 'आपके सभी प्रश्नों और वर्कफ़्लो के लिए व्यापक गाइड हमारे में उपलब्ध हैं',
  resourceCentre: 'संसाधन केन्द्र।',
  checkoutOurAnswers: 'आप नीचे दिए गए सामान्य प्रश्नों के लिए हमारे उत्तर देख सकते हैं।',
  createANewBot: 'एक नया बॉट बनाएं',
  installYourBot: 'अपना बॉट स्थापित करें',
  plansAndPricing: 'योजना और मूल्य निर्धारण',
  searchLanguage: 'खोज भाषा',
  whatsappConversations: 'व्हाट्सएप वार्तालाप',
  diveDeeperIntoBotPenguin: 'बॉटपेंग्विन को गहराई से जानें',
  knowWhereWeAreGoing: 'पता है कि हम कहाँ जा रहे हैं, हाल के अपडेट, और हमें कुछ कीड़े को ठीक करने में मदद करते हैं।',
  productUpdates: 'उत्पाद अद्यतन',
  requestAFeature: 'सुविधा का अनुरोध करें',
  reportABug: 'बग रिपोर्ट करें',
  productRoadmap: 'प्रोडक्ट रोडमैप',
  contactsOrLeads: 'संपर्क/लीड',
  english: 'अंग्रेज़ी',
  hindi: 'हिंदी',
  german: 'जर्मन',
  french: 'फ्रांसीसी',
  portuguese: 'पुर्तगाली',
  spanish: 'स्पैनिश',
  italian: 'इतालवी',
  russian: 'रूसी',
  pleaseSelectYourReason: 'कृपया अपना कारण चुनें',
  cancel: 'रद्द करना',
  submit: 'जमा करना!',
  giveFeedback: 'प्रतिक्रिया दें',
  ratings: 'रेटिंग',
  shareYourExperience: 'अपने अनुभव को साझा करें',
  reason1: 'कारण 1',
  reason2: 'कारण 2',
  reasonBehindYourDecision: 'आपके निर्णय के पीछे का कारण',
  appTooltip: {
    HOME_INSIGHT: 'अपने सभी बॉट्स के संदेशों और लीड में अंतर्दृष्टि प्रदान करें (हर आधी रात को अपडेट किया गया डेटा)',
    HOME_MESSAGES: 'इस बिलिंग चक्र के दौरान आपके खाते द्वारा उपभोग किए गए संदेश। (व्हाट्सएप, फेसबुक और टेलीग्राम बॉट के संदेशों को बाहर रखा गया है।)',
    HOME_TOTAL_MESSAGES: 'खाता निर्माण के बाद से आपके खाते द्वारा उपभोग किए गए कुल संदेश (व्हाट्सएप, फेसबुक और टेलीग्राम बॉट के संदेशों को बाहर रखा गया है।)',
    LEADS: 'इस बिलिंग चक्र के दौरान आपके खाते के लिए बॉट्स द्वारा कैप्चर किए गए लीड।',
    TOTAL_LEADS: 'खाता निर्माण के बाद से आपके खाते के लिए बॉट्स द्वारा कैप्चर किए गए कुल संपर्क/लीड (हर आधी रात को अपडेट किया गया डेटा)।',
    YOUR_BOT: 'इस बिलिंग चक्र के दौरान अपने सभी बॉट्स और उनके उपयोग का अवलोकन।',
    NAME: 'बॉट का नाम',
    PLATFORM: 'बॉट का मंच',
    MESSAGES: 'खाता निर्माण के बाद से बॉट द्वारा भेजे कुल संदेश',
    CONTACTS: 'बॉट के कुल संपर्क/लीड',
    ACTIONS: 'बॉट को संपादित/हटाएं',
    EDIT_ICON: 'बॉट चैट प्रवाह और सेटिंग्स संपादित करें',
    DELETE_ICON: 'बॉट हटाएं',
    TOOL_TIP_CLASSIC_VERSION: `${brand} यू आयी का पुराना संस्करण`,
  },
  modal: {
    deleteBot: 'बॉट हटाएं!',
    areYouSure: 'क्या आप सुनिश्चित हैं कि आप इस बॉट को हटाना चाहते हैं?',
    enquiryForm: 'जानकारी फॉर्म',
    payNow: 'अब भुगतान करें!',
    saveAndDelete: 'सहेजें और हटाएं',
    submit: 'जमा करना',
  },
  noDataFound: {
    bots: 'बॉट',
    noBotCreatedYet: 'अभी तक कोई बॉट नहीं बनाया गया है!'
  },
  yourFriendWillMissYou: 'आपका दोस्त आपको याद करेगा',
  rateYourBotCreationExperience: 'अपने बॉट निर्माण अनुभव को दर करें',
  whatMadeYouDeleteYourBot: 'क्या आपने अपना बॉट हटा दिया?',
  iCreatedByMistake: 'मैंने गलती से बनाया',
  iWantToCreateAnotherBot: 'मैं एक और बॉट बनाना चाहता हूं',
  couldNotFindFeaturesThatINeeded: 'मुझे ऐसी विशेषताएं नहीं मिलीं जिनकी मुझे जरूरत है',
  uiWasHardToNavigate: 'UI नेविगेट करना मुश्किल था',
  iDonotWantToTell: 'मैं नहीं बताना चाहता',
  other: 'अन्य',
  weWillDoOurBestToMakeAutomationFun: 'हम सभी के लिए स्वचालन को मजेदार और सरल बनाने की पूरी कोशिश करेंगे।',
};
