import { Injectable, NgZone } from '@angular/core';
import { NetworkService } from './network.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { InboxService } from './inbox.service';
import { CommonService } from './common.service';
@Injectable({
  providedIn: 'root',
})
export class DesktopNotificationService {

  agencyPermission: string;
  agencyLogo: string;
  planName: string;

  constructor(
    public networkService: NetworkService,
    private store: Store<any>,
    public zone: NgZone,
    private router: Router,
    private inboxService: InboxService,
    private commonService: CommonService) {
    this.store.select(state => state)
      .subscribe(state => {
        this.planName = state?.profile?.plan?._plan?.name;
        this.agencyPermission = state.agencyDetails?.permission;
        this.agencyLogo = state.agencyDetails?.meta?.logo;
      });
  }

  pushNewInboxMessageNotification(title: string, message: string, payload: any) {
    if ('Notification' in window && Notification.permission === 'granted' && document.visibilityState === 'hidden' && this.planName !== 'baby') {
      const notification = new Notification(title, {
        body: message,
        icon: this.agencyPermission === 'WHITE_LABEL' ? this.agencyLogo : 'assets/images/logo-v2.svg',
        data: payload
      });

      notification.addEventListener('click', (event: any) => {
        this.zone.run(() => {
          event.preventDefault();
          (window as any).focus();
          this.router.navigate(['/inbox-merged/chats']);
        });
        notification.close();
      });
    } else if ('Notification' in window && Notification.permission === 'default') {
      this.commonService.requestNotificationPermission()
        .catch(error => console.log(error));
    }
  }

  pushLiveChatRequestNotification(title: string, message: string | undefined) {

    if ('Notification' in window && Notification.permission === 'granted' && document.visibilityState === 'hidden' && this.planName !== 'baby') {
      const notification = new Notification(title, {
        body: message,
        icon: this.agencyPermission === 'WHITE_LABEL' ? this.agencyLogo : 'assets/images/logo-v2.svg',
      });

      notification.addEventListener('click', (event: any) => {
        this.zone.run(() => {
          event.preventDefault();
          (window as any).focus();
          this.router.navigate(['/inbox-merged/chats']);
        });
        notification.close();
      });
    } else if ('Notification' in window && Notification.permission === 'default') {
      this.commonService.requestNotificationPermission()
        .catch(error => console.log(error));
    }
  }

  pushNewLeadNotification(title: string, message: string) {
    if ('Notification' in window && Notification.permission === 'granted' && document.visibilityState === 'hidden' && this.planName !== 'baby') {
      const notification = new Notification(title, {
        body: message,
        icon: this.agencyPermission === 'WHITE_LABEL' ? this.agencyLogo : 'assets/images/logo-v2.svg',
      });

      notification.addEventListener('click', (event) => {
        this.zone.run(() => {
          event.preventDefault();
          (window as any).focus();
          this.router.navigate(['/inbox-merged/contacts']);
        });
        notification.close();
      });
    } else if ('Notification' in window && Notification.permission === 'default') {
      this.commonService.requestNotificationPermission()
        .catch(error => console.log(error));
    }
  }
}
