import {createAction, props} from '@ngrx/store';
import Broadcast from '../../models/broadcast';

export const loadSocialBroadcasts = createAction(
  '[Broadcast] Load Social Broadcasts',
  props<{ broadcasts: Broadcast[] }>()
);
export const addSocialBroadcast = createAction(
  '[Broadcast] Add Social Broadcast',
  props<{ broadcast: any }>()
);





