export const inboxMerged = {
  inboxHeader: 'इनबॉक्स',
  chatsHeader: 'चैट',
  contactsHeader: 'संपर्क',
  segmentsHeader: 'समूह',
  ordersHeader: 'ऑर्डर',
  exportStatus: 'निर्यात की स्थिति',
  tasksExport: 'कार्य- निर्यात',
  download: 'डाउनलोड करना',
  ourAllNewOmniChannelInbox: 'हमारे सभी नए ओमनी-चैनल इनबॉक्स!',
  allYourMessagesContactsAndLeads: 'आपके सभी संदेश, संपर्क और लीड अब एक ही स्थान पर हैं। अपने व्यवसाय को बढ़ाने के लिए, क्रमबद्ध और समूहित होने के लिए तैयार हैं!',
  close: 'बंद करना',
  takeALook: 'नज़र रखना',
  inProgress: 'जारी',
  appTooltip: {
    manageAllYourLeadsHere: 'यहां अपने सभी लीड/संपर्क प्रबंधित करें',
    segmentYourUserLeads: 'जनसांख्यिकी और रुचियों के आधार पर अपने लीड/बॉट उपयोगकर्ताओं को समूहित करें। इनका उपयोग ब्रॉडकास्ट बनाने में किया जाएगा ',
    manageAllBotsConversationsHere: 'यहां सभी बॉट की बातचीत का प्रबंधन करें',
    manageAllYourOrdersHere: 'यहां अपने सभी ऑर्डर प्रबंधित करें',
  },
  contacts: {
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'हेल्प',
    visitorName: 'आगंतुक नाम',
    email: 'ईमेल',
    phoneNumber: 'फ़ोन नंबर',
    segment: 'समूह',
    status:  'स्थिति',
    tag: 'उपनाम',
    action: 'कार्य',
    discard: 'खारिज करना',
    addTag: 'टैग जोड़ो',
    loadMore: 'और लोड करें',
    nA: '-',
    assign: 'सौंपना',
    deleteUser: 'उपयोगकर्ता को हटा दें',
    areYouSureToDeleteLead: 'क्या आप वाकई इस लीड को हटाना चाहते हैं?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'आपकी संपर्क नहीं हैं!',
      allOfYourContactsAppearHere: 'आपके सभी संपर्क देखें'
    },
    placeholders: {
      searchSegmentByName: 'नाम से समूह खोजे',
      searchOrAddTag: 'टैग खोजें या जोड़ें'
    },
    addContacts: {
      name: 'नाम',
      email: 'ईमेल',
      enterAValidEmail: 'वैध ईमेल प्रविष्ट करें',
      phoneNumber: 'फ़ोन नंबर',
      cancel: 'रद्द करना',
      addContact: 'संपर्क जोड़ें',
      placeholders: {
        enterEmailAddress: 'ईमेल पता दर्ज करें',
        enterUserrName: 'उपयोगकर्ता नाम दर्ज करें',
        searchCountry: 'देश खोजे',
        enterPhoneNumber: 'फोन नंबर दर्ज करें'
      }
    }
  },
  inboxFilter: {
    applied: 'लागू',
    assignTags: 'टैग असाइन करें',
    discard: 'खारिज करना',
    addTag: 'टैग जोड़ें',
    assignSegments: 'समूह असाइन करें',
    filterYourLeads: 'अपने लीड को फ़िल्टर करें',
    filters: 'फिल्टर',
    filter: 'फ़िल्टर',
    resetAllFilters: 'सभी फिल्टर रीसेट करें',
    importContacts: 'संपर्क आयात करें',
    beforeYouStart: 'इससे पहले कि आप अपलोड शुरू करें कृपया सुनिश्चित करें',
    point1: '1. सैंपल एक्सेल फ़ाइल डाउनलोड करें।',
    point2: '2. दूसरी पंक्ति से अपना डेटा दर्ज करना शुरू करें।',
    point3: '3. प्रत्येक पंक्ति में, एक संपर्क के लिए डेटा होगा',
    point4: '4. सुनिश्चित करें कि सभी फोन नंबर अद्वितीय हैं।',
    point5: '5. अधिकतम फ़ाइल आकार अपलोड सीमा 20MB है',
    selectFile: 'फ़ाइल का चयन करें',
    clickHere: 'यहाँ क्लिक करें',
    toDownloadSampleFile: 'सैंपल फ़ाइल डाउनलोड करने के लिए',
    modal: {
      closeLeads: 'लीड बंद करें',
      areYouSureToselectAllLeads: 'क्या आप सुनिश्चित हैं कि आप सभी चयनित लीड को बंद करना चाहते हैं?',
      assignAgent: 'एजेंट असाइन करें',
      toAssignAgentToAllLeads: 'क्या आप सुनिश्चित हैं कि आप सभी चयनित लीड्स को एजेंट असाइन करना चाहते हैं?',
      assignTag: 'टैग असाइन करें',
      wantToReplacePreviouslyAssignedTagWithNew: 'क्या आप सुनिश्चित हैं कि आप सभी चयनित लीड्स के लिए नए चुने हुए टैग के साथ पहले से असाइन किए गए टैग को बदलना चाहते हैं?',
      deleteLeads: 'लीड को हटाएं',
      wantToDeleteSelectedLeads: 'क्या आप सुनिश्चित हैं कि आप सभी चयनित लीड को हटाना चाहते हैं?',
      exportData: 'निर्यात जानकारी',
      exportDataBasedOn: 'के आधार पर डेटा निर्यात करें',
      assignSegment: 'असाइन करें समूह',
      wantToAssignSegmentsToSelectedLeads: 'क्या आप सुनिश्चित हैं कि आप सभी चयनित लीड्स को समूह असाइन करना चाहते हैं?',
      yesGoAhead: 'हाँ, आगे बढ़ो!',
      export: 'निर्यात'
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'अपनी प्राथमिकताओं के आधार पर डेटा फ़िल्टर करें जैसे, टैग, बॉट, स्थिति, आदि।',
      markLeadAsClosed: 'उपयोगकर्ता/लीड को बंद के रूप में चिह्नित करें',
      assignAgentToLead: 'उपयोगकर्ता को एजेंट असाइन करें',
      assignTagToUser: 'उपयोगकर्ता को टैग असाइन करें',
      exportData: 'निर्यात जानकारी',
      importContactsForWhatsappBot: 'व्हाट्सएप बॉट के लिए आयात संपर्क',
      addWhatsappContact: 'व्हाट्सएप संपर्क जोड़ें',
      assignSegmentToUser: 'उपयोगकर्ता को समूह असाइन करें',
      deleteUsers: 'उपयोगकर्ताओं को हटा दें'
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'नाम, ईमेल या फोन टाइप करें, और एंटर दबाएं',
      searchAgentByName: 'नाम से एजेंट खोजें ',
      searchOrAddTag: 'टैग खोजें या जोड़ें',
      searchSegmentByName: 'नाम से समूह खोजें'
    },
    filterContainer: {
      filterOn: 'फ़िल्टर करना',
      filterBy: 'फिल्टर के द्वारा',
      startFrom: 'से शुरु करें',
      tillDate: 'आज तक',
      offlineOrOnline: 'ऑफलाइन/ऑनलाइन',
      liveChat: 'सीधी बातचीत',
      userInteractedWithBot: 'उपयोगकर्ता ने बॉट के साथ बातचीत की',
      discard: 'खारिज करना',
      applyFilter: 'फिल्टर लागू करें',
      bots: 'बोट्स ',
      tags: 'टैग्स ',
      status: 'स्टेटस',
      assignedTo: 'को एसाइन्ड  ',
      lastMessageBy: 'अंतिम बार किसके द्वारा संदेश भेजा गया?',
      onlineOrOffline: 'ऑनलाइन/ऑफलाइन',
      userInteracted: 'उपयोगकर्ता की द्वारा बातचीत ',
      segments: 'समूहों',
      lastSeenDate: ' तारीख जब अंतिम बार यूजर ने देखा ',
      onboardingDate: 'ऑनबोर्डिंग की तारीख',
      createTags: 'टैग बनाएँ',
      createTagsToSortAndManageLeads: 'अपने लीड को आसानी से सॉर्ट और प्रबंधित करने के लिए टैग बनाएँ',
      typeYourTagHere: 'अपना टैग यहाँ लिखें',
      createSegment: 'समूह बनाएँ',
      manageSegmentForMarketingAutomation: 'विपणन स्वचालन के लिए अपने समूह प्रबंधित करें',
      typeYourSegmentHere: 'अपना समूह यहाँ लिखें',
      placeholders: {
        searchFilter: 'फ़िल्टर खोजें',
        searchByBotName: 'बॉट के नाम से खोजें',
        searchOrAddTag: 'टैग खोजें या जोड़ें',
        searchByAgentName: 'एजेंट के नाम से खोजें',
        searchBySegments: 'समूहों के द्वारा खोजें'
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'दिनांक सीमा जब आप चयन को समाप्त करना चाहते हैं',
        dateRangeYouWishToStartSelectionFrom: 'दिनांक रेंज जब आप चयन शुरू करना चाहते हैं',
        filterThoseThatAreOfflineOrOnline: 'उन उपयोगकर्ताओं को फ़िल्टर करें जो ऑफ़लाइन/ऑनलाइन हैं',
        filterThoseThatAreCurrentlyLiveChatting: 'उन उपयोगकर्ताओं को फ़िल्टर करें जो वर्तमान में लाइव चैटिंग कर रहे हैं',
        filterThoseThatInteractedWithBot: 'उन उपयोगकर्ताओं को फ़िल्टर करें जो बॉट के साथ बातचीत करते हैं'
      },
      indexMergeDatePicker: {
        selectStartDate: 'प्रारंभ तिथि का चयन करें',
        selectEndDate: 'अंतिम तिथि का चयन करें'
      }
    }
  },
  leads: {
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'मदद',
    visitorName: 'विजिटर का नाम',
    email: 'ईमेल',
    phoneNumber: 'फ़ोन नंबर',
    assignedTo: 'सौंपना',
    status: 'स्थिति',
    tag: 'उपनाम',
    action: 'कार्य',
    discard: 'खारिज करना',
    addTag: 'टैग जोड़ें',
    loadMore: 'और लोड करें',
    nA: '-',
    deleteUser: 'उपयोगकर्ता को हटा दें',
    areYouSureToDeleteLead: 'क्या आप वाकई इस लीड को हटाना चाहते हैं?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'आपके पास अभी तक कोई चैट नहीं है',
      allOfYourChatsAppearHere: 'आपके सभी चैट यहाँ दिखाई देंगे'
    },
    placeholders: {
      searchAgentByName: 'नाम से एजेंट खोजें',
      searchStatusByName: 'नाम से स्थिति खोजें',
      searchOrAddTag: 'टैग खोजें या जोड़ें'
    }
  },
  messages: {
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'मदद',
    editChatFlow: 'चैट फ़्लो संपादित करें',
    connect: 'कनेक्ट करें',
    chat: {
      photo: 'फोटो',
      video: 'वीडियो',
      document: 'दस्तावेज़',
      location: 'स्थान',
      assignedTo: 'सौंपना',
      selectBot: 'बॉट का चयन करें',
      discard: 'खारिज करना',
      addTag: 'टैग जोड़ो',
      yourBrowserDoesNotSupportVideoTag: 'आपके ब्राउज़र में वीडियो टैग समर्थित नहीं है।',
      userHasSharedADocument: 'उपयोगकर्ता ने एक दस्तावेज़ साझा किया है।',
      clickHereToDownload: 'इसे डाउनलोड करने के लिए यहाँ क्लिक करें',
      thisStoryIsNoLongerAvailable: 'यह कहानी अब उपलब्ध नहीं है',
      yourBrowserDoesNotSupportAudioElement: 'आपके ब्राउजर में ऑडियो तत्व समर्थित नहीं है।',
      thisMessageIsNoLongerAvailable: 'यह संदेश अब उपलब्ध नहीं है क्योंकि यह प्रेषक द्वारा असंगत था',
      userHasSharedHisLocation: 'उपयोगकर्ता ने अपना स्थान साझा किया है। देखने के लिए यहां क्लिक करें',
      sms: 'एसएमएस',
      failed: 'असफल',
      a: 'ए',
      i: 'आ',
      r: 'आर',
      m: 'एम',
      conversationsSelected: 'चयनित बातचीत',
      nA: '-',
      assignTagsCloseOrBulkReply: 'एक बार में सभी को टैग, बंद या बल्क उत्तर असाइन करें',
      appTooltip: {
        assignAgent: 'एजेंट असाइन करें ',
        selectBot: 'बॉट का चयन करें',
        assignTagsToManageLeads: 'लीड और वार्तालापों को प्रबंधित करने के लिए टैग असाइन करें'
      },
      placeholders: {
        searchAgentByName: 'नाम से एजेंट खोजें',
        searchBotByName: 'नाम से बॉट खोजें',
        searchOrAddTag: 'टैग खोजें या जोड़ें',
        searchStatusByName: 'नाम से स्थिति खोजें'
      }
    },
    profile: {
      userDetails: 'उपयोगकर्ता विवरण',
      orderDetails: 'ऑर्डर विवरण',
      orderedOn: 'ऑर्डर की तारीख',
      showMore: 'और दिखाओ',
      showLess: 'कम दिखाओ',
      subTotal:  'उप कुल',
      nA: '-',
      addAWhatsappNumber: 'व्हाट्सएप नंबर जोड़ें',
      addTelegramUserName: 'टेलीग्राम उपयोगकर्ता का नाम जोड़ें',
      addInstagramProfileId: 'इंस्टाग्राम प्रोफाइल की आईडी जोड़ें',
      addFacebookProfileId: 'फेसबुक प्रोफाइल की आईडी जोड़ें',
      add: 'जोड़ना',
      deviceProperties: 'डिवाइस गुण',
      privateNotes: 'निजी नोट्स',
      urlsVisited: 'यूआरएल का दौरा किया',
      source: 'स्रोत -',
      sourceNa: 'स्रोत - उपलब्ध नहीं',
      medium: 'माधयम  -',
      mediumNa: 'माधयम - उपलब्ध नहीं',
      campaign: 'अभियान -',
      campaignNa: 'अभियान - उपलब्ध नहीं',
      attributeKey: 'विशेषता कुंजी',
      attributeName: 'उत्तरदायी ठहराने के लिए नाम',
      discard: 'खारिज करना',
      addAttribute: 'विशेषता जोड़ें',
      editAttribute: 'संपादित करें',
      deleteAttribute: 'हटाएं विशेषता',
      headsUp: 'सचेत',
      areYouSureToDeleteAttribute: 'क्या आप सुनिश्चित हैं कि आप इस विशेषता को हटाना चाहते हैं?',
      okay: 'ठीक',
      dataAddedToLead: 'आपके एपीआई या बॉट प्रवाह में मैप की गई विशेषताएँ अपडेट नहीं की जाएंगी। कृपया एपीआई या बॉट प्रवाह को तदनुसार अद्यतन करें',
      assignedOnly: 'केवल सौंपा',
      allUsers: 'सभी उपयोगकर्ता',
      save: 'सहेजें',
      manageCustomAttributes: 'कस्टम विशेषताएँ प्रबंधित करें',
      placeholders: {
        title: 'शीर्षक',
        egServices: 'उदा। सेवाएं',
        egRequestedServices: 'उदा। अनुरोधित सेवाएँ',
        enterYourNotesHere: 'यहाँ अपने नोट्स दर्ज करें ...',
      },
      bpDropDown: {
        selectBot: 'बॉट का चयन करें',
        selectFlow: 'प्रवाह का चयन करें',
        selectQuestion: 'प्रश्न का चयन करें*'
      }
    },
    sendMessage: {
      send: 'भेजना',
      sendToAllChannels: 'सभी चैनलों पर भेजें',
      email: 'ईमेल',
      whatsapp: 'व्हाट्सप्प ',
      telegram: 'टेलीग्राम ',
      facebook: 'फेसबुक',
      sendSms: 'एसएमएस भेजें',
      addNew: 'नया जोड़ें',
      addNewQuickReply: 'नया त्वरित उत्तर जोड़ें',
      discard: 'खारिज करना',
      fromComputer: 'कंप्यूटर से',
      add: 'जोड़ना',
      dragAndDropHereOr: 'यहाँ खींचें और ड्रॉप करें या',
      browseFiles: 'फ़ाइलों को ब्राउज़ करें',
      caption: 'कैप्शन',
      templates: 'टेम्पलेट्स',
      addHeaderMedia: 'हेडर मीडिया जोड़ें',
      selectFile: 'फ़ाइल का चयन करें',
      previousConversationClosed: 'पिछली बातचीत बंद करें!',
      sendTemplateMessage: 'बातचीत को फिर से शुरू करने के लिए टेम्पलेट संदेश भेजें।',
      selectTemplate: 'टेम्पलेट का चयन करें',
      conversationLocked: 'बातचीत बंद!',
      demoBotOnlyAllowConversations: 'डेमो बॉट केवल उस संख्या के साथ बातचीत की अनुमति देता है जिसे आपने पहले सत्यापित किया है।',
      placeholders: {
        searchQuickReplyByTitle: 'शीर्षक द्वारा त्वरित उत्तर खोजें',
        addTitle: 'शीर्षक जोडें',
        addYourRepliesHere: 'अपने उत्तरों को यहाँ जोड़ें ...',
        addCaption: 'कैप्शन जोड़ें',
        enterValueForThisVariable: 'इस चर के लिए मान दर्ज करें'
      },
      modal: {
        sendBulkMessages: 'थोक संदेश भेजें',
        send: 'भेजना',
        description: 'आपके संदेश को स्वच्छ किया जाएगा और उस प्लेटफ़ॉर्म के प्रकार के आधार पर भेजा जाएगा जो उपयोगकर्ता से संबंधित है।'
      },
      noDataFound: {
        oops: 'उफ़!',
        currentlyDoesNotSupportBulkMessages: 'वर्तमान में हम व्हाट्सएप बॉट के लिए थोक संदेशों का समर्थन नहीं करते हैं। कृपया प्रसारण सुविधा का उपयोग करें',
        quickReplies: 'त्वरित उत्तर',
        noQuickRepliesAddedYet: 'कोई त्वरित उत्तर अभी तक नहीं जोड़ा गया!'
      }
    },
    userList: {
      selectAll: 'सबका चयन करें',
      nA: '-',
      loadMore: 'और लोड करें'
    }
  },
  segments: {
    seeHowToUse: 'कैसे उपयोग करें',
    help: 'मदद',
    manageSegment: 'समूह प्रबंधित करें',
    manageYourSegmentsForMarketingAutomation: 'मार्केटिंग ऑटोमेशन के लिए अपने समूह का प्रबंधन करें',
    createNewSegment: 'नया समूह बनाएं',
    segmentName: 'समूह नाम',
    totalContacts: 'कुल संपर्क',
    action: 'कार्य',
    createSegmentButton: 'समूह बनाएँ',
    deleteSegments: 'समूह हटाएं',
    areYouSureToDeleteSegment: 'क्या आप वाकई इस समूह को हटाना चाहते हैं?',
    placeholders: {
      searchHere: 'यहां तलाश करें'
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'आपके पास अभी तक कोई समूह नहीं है',
      allYourSegmentsAppearHere: 'आपके सभी समूह यहां दिखाई देंगे'
    },
    modal: {
      exportData: 'निर्यात जानकारी',
      exportDataBasedOnAppliedFilters: 'लागू फिल्टर के आधार पर डेटा निर्यात करें',
      export: 'निर्यात'
    },
    appTooltip: {
      deleteSegments: 'समूह हटाएं',
      editSegment: 'समूह संपादन करें',
      downloadSegment: ' समूह डाउनलोड करें',
      deleteSegment: 'समूह हटाएं '
    },
    createSegment: {
      createSegment: 'समूह बनाएँ',
      manageYourSegmentsForMarketingAutomation: 'मार्केटिंग ऑटोमेशन के लिए अपने समूह का प्रबंधन करें',
      segmentName: 'समूह नाम',
      segmentNameIsRequired: 'समूह का नाम आवश्यक है',
      selectContactsFilterForSegment: 'समूह के लिए संपर्क फ़िल्टर का चयन करें',
      filterYourContacts: 'अपने संपर्कों को फ़िल्टर करें',
      removeAllFilters: 'सभी फिल्टर हटाएं',
      addFilters: 'फिल्टर जोड़ें',
      discard: 'खारिज करना',
      filterSelected: 'फ़िल्टर चयनित हुए',
      placeholders: {
        enterSegmentName: 'समूह नाम दर्ज करें'
      }
    }
  },
  orders: {
    visitorName: 'आगंतुक नाम',
    email: 'ईमेल',
    phoneNumber: 'फ़ोन नंबर',
    status: 'स्थिति',
    itemCount: 'आइटम की संख्या',
    total: 'कुल',
    tag: 'टैग',
    noDataFound: {
      youDoNotHaveAnyOrdersYet:  'आपके पास अभी तक कोई ऑर्डर नहीं है',
      allOfYourOrdersAppearHere: 'आपके सभी ऑर्डर यहां दिखाई देंगे'
    },
  }
};
