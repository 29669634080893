export const agency = {
  activities: {
    activities: 'Etkinlikler',
    upcomingRenewals: 'Yaklaşan Yenilemeler',
    subscriptionActivities: 'Abonelik Etkinlikleri',
    upcomingDowngradeRequests: 'Yaklaşan Düşürme Talepleri',
    serialNumber: 'Sıra No.',
    email: 'E-posta',
    phoneNumber: 'Telefon No.',
    currentPlan: 'Mevcut Plan',
    price: 'Fiyat',
    daysLeft: `Kalan Gün`,
    noDataFound: {
      upcomingDowngrades: 'Yaklaşan Not Düşürmeleri',
      noUpcomingDowngradesFoundYet: 'Henüz Yaklaşan Bir Düşüş Bulunamadı!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Müşterilerin yaklaşan yenilemeleri',
      subscriptionActivities: 'Abonelik etkinlikleri',
      upcomingDowngradeRequestsOfCustomers: 'Müşterilerin yaklaşan düşürme talepleri',
      serialNumber: 'Sıra numarası',
      customerEmail: 'Müşteri e-postası',
      customerPhoneNumber: 'Müşteri telefon numarası',
      currentPlanOfCustomer: 'Müşterinin mevcut planı',
      currentPlanPrice: 'Mevcut plan fiyatı',
      daysLeft: `Müşterinin mevcut planının kalan günü`
    }
  },
  configuration: {
    configurationsHeader: 'Yapılandırmalar',
    emailHeader: 'E-posta',
    razorPayHeader: 'RazorPay',
    invoiceDetailsHeader: 'Fatura Detayları',
    stripeHeader: 'Stripe',
    appTooltip: {
      integrateYourEmailProviderDetails: 'E-posta sağlayıcı ayrıntılarınızı entegre ederek müşteri e-postalarını sorunsuz bir şekilde göndermek için.',
      enterYourRazorPayAccountDetails: 'Müşteri ödeme işlemleri için RazorPay hesap ayrıntılarınızı girin.',
      enterYourStripeAccountDetails: 'Müşteri ödeme işlemleri için Stripe hesap ayrıntılarınızı girin.',
      enterYourCompanyDetails: 'Doğru faturalandırma ve profesyonel fatura oluşturma için şirket ayrıntılarınızı girin.',
    },
    email: {
      testEmail: 'Test E-postası',
      addEmailIntegrationsDetails: 'E-posta Entegrasyon Ayrıntıları Ekle',
      integrationDescription: 'E-posta sağlayıcı ayrıntılarınızı entegre etmek için lütfen e-posta sağlayıcı bilgilerinizi girin. ' +
        'Tüm iletişim e-postaları, bu e-posta adresini kullanarak müşterilerinize gönderilir ve önemli bildirimlerin ve güncellemelerin tutarlı ve güvenilir bir şekilde iletilmesini sağlar.',
      awsRegion: 'AWS Bölgesi',
      awsAccessKeyId: 'AWS Erişim Anahtar Kimliği',
      awsSecretAccessKey: 'AWS Gizli Erişim Anahtarı',
      reset: 'Sıfırla',
      addDetails: 'Ayrıntıları Ekle',
      updateDetails: 'Ayrıntıları Güncelle',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'AWS Bölgenizi girin. Bölge, kaynaklarınızın fiziksel konumunu belirler ve veri gecikmesi gibi faktörleri etkiler ve düzenleyici uyumluluk gibi etkilere sahiptir.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'AWS Erişim Anahtar Kimliğinizi girin. Bu anahtar, AWS hesabınızı benzersiz bir şekilde tanımlar ve güvenli kimlik doğrulama ve AWS hizmetlerine erişim için gereklidir.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'AWS Gizli Erişim Anahtarınızı girin. Bu anahtar, güvenli kimlik doğrulama ve AWS hizmetlerine erişim için kullanılır.'
      },
      placeholders: {
        enterAwsRegion: 'AWS Bölgesini Girin',
        enterAwsAccessKeyId: 'AWS Erişim Anahtar Kimliğini Girin',
        enterAwsSecretAccessKey: 'AWS Gizli Erişim Anahtarını Girin',
      },
      modal: {
        deleteEmailIntegration: 'E-posta Entegrasyonunu Sil',
        wantToDeleteEmailIntegration: 'Bu E-posta Entegrasyonunu silmek istediğinizden emin misiniz?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Facebook Entegrasyon Detayları Ekle',
      integrateYourEmailProviderDetails: 'E-posta sağlayıcı ayrıntılarınızı entegre edin. Tüm e-postalar, müşterilerinize bu e-posta adresini kullanarak gönderilecektir',
      facebookAppId: 'Facebook Uygulama Kimliği',
      facebookSecretAccessKey: 'Facebook Gizli Erişim Anahtarı',
      reset: 'Sıfırla',
      cancel: 'İptal',
      addDetails: 'Ayrıntıları Ekle',
      updateDetails: 'Ayrıntıları Güncelle',
      delete: 'Sil',
      testEmail: 'E-posta Testi',
      modal: {
        deleteFacebookIntegration: 'Facebook Entegrasyonunu Sil',
        wantToDeleteFacebookIntegration: 'Bu E-posta Entegrasyonunu silmek istediğinizden emin misiniz?'
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Facebook Gizli Erişim Anahtarını Girin',
        enterFacebookAppId: 'Facebook Uygulama Kimliğini Girin'
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Bot kullanıcısı bir Canlı Sohbet için istek gönderdikten hemen sonra görünmesini istediğiniz iletişimi özelleştirin',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Fatura Detayları Ekle',
      invoicesDescription: 'Müşterileriniz için fatura oluşturmak için lütfen şirket bilgilerinizi girin, şirket adınızı, adresinizi, iletişim bilgilerinizi ve işlem başına doğru fatura oluşturmak için gereken diğer ilgili bilgileri içerir.' +
        'Bu bilgiler, her işlem için şirketiniz adına profesyonel faturalar oluşturmak için kullanılacaktır.',
      companyName: 'Şirket Adı',
      country: 'Ülke',
      selectCountry: 'Ülke Seçin',
      stateOrRegion: 'Eyalet/Bölge',
      streetAddress: 'Cadde Adresi',
      gstin: 'GSTIN',
      postalCode: 'Posta Kodu',
      addDetails: 'Ayrıntıları Ekle',
      updateDetails: 'Ayrıntıları Güncelle',
      reset: 'Sıfırla',
      delete: 'Sil',
      loadMore: 'Daha Fazla Yükle',
      time: 'Zaman',
      phoneNumber: 'Telefon Numarası',
      email: 'E-posta',
      status: 'Durum',
      message: 'Mesaj',
      response: 'Yanıt',
      placeOfSupply: 'Tedarik Yeri',
      placeholders: {
        enterYourCompanyName: 'Şirket Adınızı Girin',
        enterYourStateOrRegion: 'Eyalet/Bölgenizi Girin',
        enterYourStreetAddress: 'Cadde Adresinizi Girin',
        enterYourGstin: 'GSTIN\'inizi Girin',
        enterPostalCode: 'Posta Kodunu Girin',
        enterPlaceOfSupply: 'Tedarik Yerini Girin'
      },
      modal: {
        deleteEmailIntegration: 'E-posta Entegrasyonunu Sil',
        wantToDeleteEmailIntegration: 'Bu E-posta Entegrasyonunu silmek istediğinizden emin misiniz?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: 'Şirket adınızı girin. Bu bilgi, faturalarda tanımlama ve markalaşma amaçları için kullanılacaktır.',
        TOOLTIP_COUNTRY: 'Ülkenizi seçin. Bu bilgi, faturalarda tanımlama ve markalaşma amaçları için kullanılacaktır.',
        TOOLTIP_STATE: 'Eyaletinizi girin. Bu bilgi, faturalarda tanımlama ve markalaşma amaçları için kullanılacaktır.',
        TOOLTIP_STREET_ADDRESS: 'Cadde Adresinizi Girin. Bu bilgi, faturalarda tanımlama ve markalaşma amaçları için kullanılacaktır.',
        TOOLTIP_GSTIN: 'GSTIN\'inizi Girin. Bu bilgi, faturalarda tanımlama ve markalaşma amaçları için kullanılacaktır.',
        TOOLTIP_POSTAL_CODE: 'Posta Kodunuzu Girin. Bu bilgi, faturalarda tanımlama ve markalaşma amaçları için kullanılacaktır.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Tedarik Yerinizi Girin. Bu bilgi, faturalarda tanımlama ve markalaşma amaçları için kullanılacaktır.',
      }
    },

    razorPay: {
      addRazorPayIntegrationsDetails: 'RAZORPAY Entegrasyon Detayları Ekle',
      integrationDescription: 'RazorPay hesap ayrıntılarınızı entegre etmek için lütfen RazorPay hesap bilgilerinizi sağlayın.' +
        ' Tüm müşteri ödemeleri, sorunsuz işlemler için RazorPay hesabınız aracılığıyla işlenecektir',
      razorPayAccessKeyId: 'RAZORPAY Erişim Anahtarı Kimliği',
      razorPaySecretAccessKey: 'RAZORPAY Gizli Erişim Anahtarı',
      reset: 'Sıfırla',
      addDetails: 'Ayrıntıları Ekle',
      updateDetails: 'Ayrıntıları Güncelle',
      loadMore: 'Daha Fazla Yükle',
      time: 'Zaman',
      phoneNumber: 'Telefon Numarası',
      email: 'E-posta',
      status: 'Durum',
      message: 'Mesaj',
      response: 'Yanıt',
      placeholders: {
        enterRazorPayAccessKeyId: 'Razorpay Erişim Anahtarını Girin',
        enterRazorPaySecretAccessKey: 'Razorpay Gizli Erişim Anahtarını Girin',
        enterRazorPayAccessId: 'RazorPay Erişim Kimliğini Girin',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Razorpay Erişim Anahtar Kimliğinizi Girin. Bu anahtar, Razorpay entegrasyonunuzu benzersiz olarak tanır ve güvenli ödeme işlemleri için gereklidir.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Razorpay Gizli Erişim Anahtarınızı Girin. Bu anahtar, uygulama ve Razorpay arasındaki güvenli iletişim için gereklidir, böylece sorunsuz işlem işleme ve ödeme yönetimi sağlanır.',
      },
      modal: {
        deleteRazorPayIntegration: 'E-posta Entegrasyonunu Sil',
        wantToDeleteThisIntegrtion: 'Bu E-posta Entegrasyonunu silmek istediğinizden emin misiniz?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Stripe Entegrasyon Ayrıntılarını Ekle',
      integrationDescription: 'Stripe hesap ayrıntılarınızı entegre etmek için lütfen Stripe hesap bilgilerinizi sağlayın.' +
        ' Tüm müşteri ödemeleri, sorunsuz işlemler için Stripe hesabınız aracılığıyla işlenecektir',
      stripeSecretAccessKey: 'Stripe Gizli Erişim Anahtarı',
      stripePublicKey: 'Stripe Genel Anahtar',
      reset: 'Sıfırla',
      addDetails: 'Ayrıntıları Ekle',
      updateDetails: 'Ayrıntıları Güncelle',
      cancel: 'İptal',
      loadMore: 'Daha Fazla Yükle',
      time: 'Zaman',
      phoneNumber: 'Telefon Numarası',
      email: 'E-posta',
      status: 'Durum',
      message: 'Mesaj',
      response: 'Yanıt',
      placeholders: {
        enterStripeSecretAccessKey: 'Stripe Gizli Erişim Anahtarını Girin',
        enterStripePublicKey: 'Stripe Genel Anahtarını Girin',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Stripe Gizli Erişim Anahtarınızı Girin. Bu anahtar, müşteri ödemelerini işlemek için güvenli bir şekilde kimlik doğrulamanızı sağlar ve müşteri ödemelerinin işlenmesi için gereklidir.',
        TOOLTIP_PUBLIC_KEY: 'Stripe Genel Anahtarınızı Girin. Bu anahtar, müşteri ödemelerini işlemek için Stripe ile istemci tarafında güvenli bir şekilde kimlik doğrulamanızı sağlar ve müşteri ödemelerinin işlenmesi için gereklidir.',
      },
      modal: {
        deleteStripeIntegration: 'Stripe Entegrasyonunu Sil',
        wantToDeleteStripeIntegration: 'Bu Stripe Entegrasyonunu silmek istediğinizden emin misiniz?',
      }
    }
  },
  customer: {
    manageCustomers: 'Müşterileri Yönet',
    addNewCustomers: 'Yeni Müşteri Ekle',
    ensureYouHaveAddedAllYourCustomersHere: 'Tüm müşterilerinizi buraya eklediğinizden emin olun',
    customerName: 'Müşteri Adı',
    nameIsRequired: 'Ad alanı gereklidir',
    emailId: 'E-posta Kimliği',
    enterAValidEmail: 'Geçerli bir e-posta girin',
    createPassword: 'Şifre Oluştur',
    enterAStrongPassword: 'Güçlü bir şifre girin',
    confirmPassword: 'Şifreyi Onayla',
    cancel: 'İptal',
    customer: 'Müşteri',
    searchByNameOrEmail: 'Ad veya e-posta ile ara',
    filters: 'Filtreler',
    allCustomers: 'Tüm Müşteriler',
    paidCustomers: 'Ücretli Müşteriler',
    freeCustomers: 'Ücretsiz Müşteriler',
    messageAddOnCustomers: 'Mesaj Ekleme Müşterileri',
    botWhiteLabelCustomers: 'Bot Beyaz Etiket Müşterileri',
    sortBy: 'Sıralama',
    sortByFrequency: 'Sıklığa Göre Sırala',
    all: 'Tümü',
    joinedDate: 'Katılma Tarihi',
    plan: 'Plan',
    lastLogin: 'Son Giriş',
    email: 'E-posta',
    nextDue: 'Sonraki Ödeme',
    frequency: 'Sıklık',
    clearAll: 'Tümünü Temizle',
    apply: 'Uygula',
    messageConsumed: 'Tüketilen Mesajlar',
    addCustomer: 'Müşteri Ekle',
    serialNumber: 'Sıra No.',
    name: 'Adı',
    contact: 'İletişim',
    limit: 'Sınırla',
    location: 'Konum',
    actions: 'İşlemler',
    joinedOn: 'Katıldı',
    typeOfCustomer: 'Müşteri Türü',
    exceptWhatsapp: ' (WhatsApp Hariç)',
    exceptWA: '(WA Hariç)',
    wa: '(WA)',
    loadMore: 'Daha Fazla Yükle',
    conversationsWhatsapp: ' Konuşmalar (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'Müşteri e-postasını girin',
      enterCustomerPassword: 'Müşteri şifresini girin',
      enterCustomerConfirmPassword: 'Müşteri şifresini onaylayın',
      enterCustomerName: 'Müşteri adını girin',
    },
    placeholders: {
      enterCustomerEmail: 'Müşteri e-postasını girin',
      searchByNameOrEmail: 'Ad veya e-posta ile arama yapın',
      enterPassword: 'Şifreyi girin',
      enterCustomerName: 'Müşteri adını girin',
    }
  },
  dashboard: {
    dashboard: 'Pano',
    overview: 'Genel Bakış',
    totalCustomers: 'Toplam Müşteri',
    paidCustomers: 'Ücretli Müşteriler',
    freeCustomers: 'Ücretsiz Müşteriler',
    thisWeeksSignUp: 'Bu haftanın Kayıtları',
    todaysSignUp: 'Bugünün Kayıtları',
    totalEarnings: 'Toplam Kazanç',
    nA: 'Bilgi Yok',
    customerName: 'Müşteri Adı',
    nameIsRequired: 'Ad gereklidir',
    emailId: 'E-posta Kimliği',
    enterAValidEmail: 'Geçerli bir e-posta girin',
    editCustomer: 'Müşteriyi Düzenle',
    recentSignUps: 'Son Kayıtlar',
    serialNumber: 'Sıra No.',
    name: 'Ad',
    email: 'E-posta',
    contact: 'İletişim',
    plan: 'Plan',
    limit: 'Sınırla',
    location: 'Konum',
    lastLogin: 'Son Giriş',
    joinedOn: 'Katılım Tarihi',
    actions: 'İşlemler',
    exceptWhatsapp: ' (WhatsApp Hariç)',
    messageConsumed: 'Tüketilen Mesajlar',
    conversationsWhatsapp: ' Konuşmalar (WhatsApp)',
    placeholders: {
      enterCustomerName: 'Müşteri adını girin',
      enterCustomerEmail: 'Müşteri e-postasını girin',
    },
    modal: {
      deleteCustomer: 'Müşteriyi Sil',
      areYouSure: 'Bu müşteriyi silmek istediğinizden emin misiniz?'
    }
  },
  downgradeRequests: {
    activites: 'Etkinlikler',
    upcomingRenewals: 'Yaklaşan Yenilemeler',
    subscriptionActivities: 'Abonelik Etkinlikleri',
    upcomingDonwgradeRequests: 'Yaklaşan Düşüş Talepleri',
    serialNumber: 'Sıra No.',
    email: 'E-posta',
    phoneNumber: 'Telefon No.',
    currentPlan: 'Geçerli Plan',
    requestedOn: 'Talep Edildi',
    downgradeOn: 'Düşüş Tarihi',
    nA: 'Bilgi Yok',
    commaMonthly: ', Aylık',
    commaYearly: ' , Yıllık',
    noDataFound: {
      downgradeRequests: 'Düşüş Talepleri',
      noDowngradeRequestFound: 'Henüz Düşüş Talebi Bulunamadı!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Müşterilerin yaklaşan yenilemeleri',
      subscriptionActivities: 'Abonelik etkinlikleri',
      upcomingDowngradeOfCustomers: 'Müşterilerin yaklaşan düşüş talepleri',
      serialNumber: 'Seri numarası',
      customerEmail: 'Müşteri e-postası',
      customerPhoneNumber: 'Müşteri telefon numarası',
      currentPlanOfCustomer: 'Müşterinin mevcut planı',
      currentPlanPrice: 'Mevcut plan fiyatı',
    }
  },
  emailIntegration: {
    emailIntegration: 'E-posta Entegrasyonu',
    addEmailIntegrationDetails: 'E-posta Entegrasyon Detayları Ekle',
    integrateYourEmailProviderDetails: 'E-posta sağlayıcı ayrıntılarınızı entegre edin. Tüm e-postalar, müşterilerinize bu e-posta aracılığıyla gönderilecektir.',
    awsRegion: 'AWS Bölgesi',
    awsRegionIsRequired: 'AWS Bölgesi gereklidir',
    awsAccessKeyId: 'AWS Erişim Anahtarı Kimliği',
    awsAccessKeyIdIsRequired: 'AWS Erişim Anahtarı Kimliği gereklidir',
    awsSecretAccessKey: 'AWS Gizli Erişim Anahtarı',
    awsSecretAccessKeyIsRequired: 'AWS Gizli Erişim Anahtarı gereklidir',
    cancel: 'İptal',
    test: 'Test',
    action: 'Eylem',
    testEmail: 'Test E-postası',
    addDetails: 'Detayları Ekle',
    updateDetails: 'Detayları Güncelle',
    saveDetails: 'Detayları Kaydet',
    placeholders: {
      enterAwsRegion: 'AWS Bölgesini Girin',
      enterAwsAccessKeyId: 'AWS Erişim Anahtarı Kimliğini Girin',
      enterAwsSecretAccessKey: 'AWS Gizli Erişim Anahtarını Girin',
    },
    modal: {
      deleteEmailIntegration: 'E-posta Entegrasyonunu Sil',
      areYouSure: 'Bu E-posta Entegrasyonunu silmek istediğinizden emin misiniz?',
    },
    noDataFound: {
      emailIntegration: 'E-posta Entegrasyonu',
      noEmailIntegrationFoundYet: 'Henüz E-posta Entegrasyonu bulunamadı!'
    }
  },
  subscription: {
    activities: 'Etkinlikler',
    upcomingRenewals: 'Yaklaşan Yenilemeler',
    subscriptionActivities: 'Abonelik Etkinlikleri',
    upcomingDonwgradeRequests: 'Yaklaşan Düşüş Talepleri',
    filters: 'Filtreler',
    manageActivities: 'Etkinlikleri Yönet',
    sortBy: 'Sırala',
    all: 'Tümü',
    upgrades: 'Yükseltmeler',
    donwgrades: 'Düşüşler',
    renewals: 'Yenileme',
    serialNumber: 'Sıra No.',
    email: 'E-posta',
    phoneNumber: 'Telefon No.',
    previousPlan: 'Önceki Plan',
    newPlan: 'Yeni Plan',
    description: 'Açıklama',
    date: 'Tarih',
    nA: 'Bilgi Yok',
    upgraded: 'yükseltildi',
    commaYearly: ' , Yıllık',
    commaMonthly: ' , Aylık',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'Henüz Abonelik Etkinliği bulunamadı!',
      subscriptionsActivities: 'Abonelik Etkinlikleri',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Müşterilerin yaklaşan yenilemeleri',
      subscriptionActivities: 'Abonelik etkinlikleri',
      upcomingDowngradeRequests: 'Müşterilerin yaklaşan düşüş talepleri',
      serialNumber: 'Seri numarası',
      customerEmail: 'Müşteri e-postası',
      customerPhoneNumber: 'Müşteri telefon numarası',
      previousPlan: 'Müşterinin önceki planı',
      newPlan: 'Müşterinin yeni planı',
      description: 'Plan açıklaması',
      typeOfPayment: 'Ödeme Türü',
    }
  },
  feedbacks: {
    feedbacks: 'Geribildirimler',
    serialNumber: 'Sıra No.',
    customer: 'Müşteri',
    type: 'Tür',
    rating: 'Puan',
    reason: 'Neden',
    description: 'Açıklama',
    createdAt: 'Oluşturulma Tarihi',
    nA: 'Bilgi Yok',
    loadMore: 'Daha Fazla Yükle',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Hesap ve bot Silme Geribildirimleri',
      noFeedbacksFoundYet: 'Henüz Geribildirim Bulunamadı!',
    },
    appTooltip: {
      serialNumber: 'Seri numarası',
      customerEmail: 'Müşteri  e-postası',
      feedbackForBotOrAccountDeletion: '(Bot/Hesap Silme) için geri bildirim',
      customerRating: 'Müşteri puanı',
      customerReason: 'Müşteri nedeni',
      feedbackCreatedAt: 'Geri bildirim oluşturulma tarihi',
      customerFeedback: 'Müşteri Geribildirimi',
    }
  }
};
