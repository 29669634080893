export const developer = {
  developers: '開発者',
  enpowerYourDevelopersToHarnessTheFullPotential: '開発者に、ダッシュボードのフルポテンシャルを活用する力を与えます',
  accessToken: 'アクセストークン',
  yourAPI: 'あなたのAPI',
  api: {
    apiManagement: 'API管理',
    apiManagementSubHeading: 'APIを追加、設定、テストし、チャットフローでトリガーとして使用できるAPIを管理します。',
    addApi: 'APIを追加',
    createApi: 'APIを作成',
    noApiFound: 'APIが見つかりません！',
    deleteApis: 'APIを削除',
    areYouSureYouWantToDeleteAPI: 'APIを削除してもよろしいですか？',
    placeholders: {
      search: 'API名で検索'
    },
    tableHead: {
      method: 'メソッド',
      apiName: 'API名',
      url: 'URL',
      actions: 'アクション'
    },
    parametersTab: 'パラメータ',
    headersTab: 'ヘッダー',
    bodyTab: 'ボディ タブ',
    manageApiResponses: 'API応答を管理',
    chooseVariableToSave: '保存する変数を選択',
    selectCustomAttribute: 'カスタム属性を選択',
    subTab: {
      key: 'キー',
      enterKey: 'キーを入力',
      value: '値',
      enterValue: '値を入力',
      addAnotherField: '別のフィールドを追加',
      save: '保存',
      useslashToInsertCustomVariables: 'ユーザーの応答からカスタム変数または動的値を挿入するには「/」を使用します。',
      response: 'レスポンス',
      apiRequestBody: 'APIリクエストボディ',
      hit: '実行',
      test: 'テスト',
      buttonToGetAResponse: 'ボタンをクリックしてレスポンスを取得',
      variable: '変数',
      saveToCustomAttribute: 'カスタム属性に保存'
    },
    apiLogs: {
      apiManagement: 'API管理',
      addConfigureTestAndManageYourAPIs: 'APIを追加、設定、テストし、チャットボットのチャットフロー内でトリガーされたすべてのAPIリクエストとレスポンスの詳細な記録。',
      searchHere: 'ステータスコードで検索',
      deleteApis: 'APIを削除',
      testAnApi: 'APIをテスト',
      noAPILogFound: 'APIログが見つかりません',
      responseCode: 'レスポンスコード',
      message: 'メッセージ',
      apiResponse: 'APIレスポンス',
      actions: 'アクション',
      deleteAPIs: 'APIログを削除',
      areYouSureYouWantToDelete: 'APIログを削除してもよろしいですか？',
      apiLogs: 'APIログ',
      subTitle: 'チャットボットのチャットフロー内でトリガーされたすべてのAPIリクエストとレスポンスの詳細な記録。'
    }
  },
  configApi: {
    configureApi: 'APIを設定',
    configureApiSubHeading: 'ここでJSONベースのAPIを設定し、チャットフローを豊かにするために使用できます',
    apiLogs: 'APIログ',
    test: 'テスト',
    manageApiResponse: 'API応答を管理',
    body: 'ボディ',
    headers: 'ヘッダー',
    parameters: 'パラメータ',
    placeholders: {
      enterApiName: 'API名を入力',
      enterApiUrl: 'API URLを入力'
    }
  }
};
