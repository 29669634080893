import { Action, createReducer, on } from '@ngrx/store';
import { addAgencyDetails, deleteAgencyDetails, loadAgencyDetails, updateAgencyDetails } from '../actions/agency-details.action';
import AgencyDetails from '../../models/agency-details';

export const initialState: AgencyDetails | {} = {};

const agencyDetailsReducer = createReducer(
  initialState,
  on(loadAgencyDetails, (state) => state),
  on(addAgencyDetails, (state, { agencyDetails }) => agencyDetails),
  on(deleteAgencyDetails, (state) => { }),
  on(updateAgencyDetails, (state, { update }) => ({ ...state, ...update }))
);


export function reducer(state: AgencyDetails, action: Action) {
  return agencyDetailsReducer(state, action);
}
