import { createAction, props } from '@ngrx/store';

export const loadProfile = createAction(
  '[Profile] Load Profile'
);
export const addProfile = createAction(
  '[Profile] Add Profile',
  props<{ profile: {} | any }>()
);
export const deleteProfile = createAction(
  '[Profile] Delete Profile'
);
export const updateProfile = createAction(
  '[Profile] Update Profile',
  props<{ update: {} | any }>()
);
