export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Benvenuti in BotPenguin!',
      login: 'Login',
      youAreAllSet: 'Siete a posto! È possibile utilizzare la posta in arrivo di WhatsApp dalla pagina dei dettagli del lead.',
      placeholders: {
        enterEmail: 'Inserisci l`email',
        enterPassword: 'Inserire la password',
      }
    }
  }
};
