export const team = {
  addMember: {
    team: 'Echipă',
    seeHowToUse: 'Vezi cum se utilizează',
    help: 'Ajutor',
    addAMember: 'Adaugă un Membru',
    addYourTeamMembersText: 'Adaugă membrii echipei tale ca agenți de suport pentru a purta discuții live cu utilizatorii botului.',
    name: 'Nume',
    nameIsRequired: 'Numele este obligatoriu',
    email: 'E-mail',
    enterAValidEmail: 'Introduceți o adresă de e-mail validă',
    password: 'Parolă',
    passwordMustContain: 'Parola trebuie să conțină',
    atleast6Characters: 'cel puțin 6 caractere,',
    oneUpperCaseLetter: '1 literă majusculă',
    oneSpecialCharacter: '1 caracter special*',
    profileIcon: 'Pictogramă Profil',
    whatsappNumber: 'Număr WhatsApp',
    leadVisibility: 'Vizibilitate Prospecte*',
    addMember: 'Adaugă Membru',
    role: 'Rol',
    createdOn: 'Creat la',
    action: 'Acțiune',
    updateMember: 'Actualizează Membru',
    add: 'Adaugă',
    cancel: 'Anulează',
    selectVisibilityOption: 'Selectați opțiunea de vizibilitate',
    all: 'Toate',
    assigned: 'Alocate',
    nA: 'Nedeterminat',
    admin: 'Administrator',
    agent: 'Agent',
    placeholders: {
      enterMemberName: 'Introduceți numele membrului',
      enterMemberEmailAddress: 'Introduceți adresa de e-mail a membrului',
      createPasswordForMember: 'Creați o parolă pentru membru',
      searchCountry: 'Caută Țară',
      enterWhatsappNumber: 'Introduceți numărul de WhatsApp',
      selectLeadsVisibility: 'Selectați vizibilitatea prospectelor',
      searchTeamByNameOrEmail: 'Căutați membrul echipei după nume sau e-mail',
    },
    modal: {
      deleteTeamMember: 'Ștergeți membrul echipei',
      wantToDeleteThisTeamMember: 'Sigur doriți să ștergeți acest membru al echipei?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Faceți clic pentru a adăuga un nou membru în echipă',
      profileIconOfTeamMember: 'Pictogramă/Imagine de profil a membrului echipei',
      nameOfTeamMember: 'Numele membrului echipei',
      emailOfTeamMember: 'E-mailul membrului echipei',
      whatsappNumberOfTeamMember: 'Numărul WhatsApp al membrului echipei',
      roleOfTeamMemberAsAdminOrAgent: 'Rolul membrului echipei ca administrator/agent',
      dateAndTimeOfCreatingTeamMemberRole: 'Dată și oră a creării rolului membrului echipei',
      editOrDeleteTeamMember: 'Editați/ștergeți membrul echipei',
      editTeamMembersDetails: 'Editați detaliile membrului echipei.',
      removeAccessOfTeamMemberByDeletingAccount: 'Eliminați accesul membrului echipei prin ștergerea contului',
    }
  },
  users: {
    manageMembers: 'Gestionați Membrii',
    managerMembersWhichCanAccessPanel: 'Gestionați echipa parteneră care poate accesa panoul dvs.',
    createNewMember: 'Creați un Membru Nou',
    createMember: 'Creați Membru',
    updateMember: 'Actualizați Membrul',
    deleteTitleMember: 'Ștergeți Membrul',
    deleteDescriptionMember: 'Sunteți sigur că doriți să ștergeți acest membru?',
    noMemberFound: 'Niciun Membru Găsit!',
    titleMember: 'Creați un Membru',
    manageUsers: 'Gestionați Utilizatorii',
    createNewUser: 'Creați un Utilizator Nou',
    managerUserWhichCanAccessPanel: 'Gestionați utilizatorul care poate accesa panoul dvs.',
    phoneNumber: 'Număr de Telefon',
    discard: 'Renunțați',
    createUser: 'Creați utilizator',
    updateUser: 'Actualizați utilizatorul',
    noUserFOund: 'Niciun Utilizator Găsit!',
    title: 'Creați un Utilizator',
    profileIcon: 'Icoană Profil*',
    name: 'Nume*',
    email: 'Email*',
    password: 'Parolă*',
    selectRole: 'Selectați Rolul*',
    selectARole: 'Selectați un rol',
    selectLeadVisibility: 'Selectați Vizibilitatea Conducătorului*',
    confirmPassword: 'Confirmați Parola*',
    tableHeadingName: 'Nume',
    tableHeadingEmail: 'Email',
    tableHeadingPhoneNumber: 'Număr de telefon',
    tableHeadingRole: 'Rol',
    tableHeadingStatus: 'Stare',
    tableHeadingAction: 'Acțiune',
    noUserFound: 'Niciun Utilizator Găsit!',
    deleteTitle: 'Șterge Utilizatorii',
    deleteDescription: 'Sunteți sigur că doriți să ștergeți acest utilizator?',
    placeholders: {
      searchHere: 'Căutare aici',
      enterName: 'Introduceți Numele',
      enterEmail: 'Introduceți adresa de email',
      searchCountry: 'Căutați țara',
      enterPhoneNumber: 'Introduceți Numărul de Telefon',
      enterPassword: 'Introduceți Parola',
      enterConfirmPassword: 'Introduceți Confirmați Parola'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Ștergeți utilizatorii',
      TOOLTIP_DELETE_MEMBERS: 'Ștergeți membrii'
    }
  },
  roles: {
    manageRoles: 'Gestionați Roluri',
    manageYourRoles: 'Gestionați rolurile dvs.',
    name: 'Nume*',
    description: 'Descriere',
    discard: 'Renunțați',
    createRole: 'Creați Rol',
    creteARole: 'Creați un Rol',
    updateRole: 'Actualizați Rolul',
    assignPermissions: 'Atribuiți Permisiuni',
    noUserFound: 'Niciun Rol al Utilizatorului Găsit!',
    tableHeadingName: 'Nume',
    tableHeadingDescription: 'Descriere',
    tableHeadingStatus: 'Stare',
    tableHeadingAction: 'Acțiune',
    module: 'Modul',
    read: 'Citire',
    write: 'Scriere',
    remove: 'Ștergere',
    notes: 'Note',
    deleteTitle: 'Șterge Rolul',
    deleteDescription: 'Atenție: Când ștergeți acest rol, se vor șterge și toți utilizatorii asignați acestui rol. \n Sunteți sigur că doriți să ștergeți acest rol?',
    deleteMemberDescription: 'Atenție: La ștergerea acestui rol, vor fi șterși și toți membrii atribuiți acestui rol. Sunteți sigur că doriți să ștergeți acest rol?',
    placeholders: {
      enterRoleName: 'Introduceți numele rolului',
      searchHere: 'Căutare aici',
      enterDescriptionForRole: 'Introduceți o descriere pentru rol'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Secțiunile la care puteți permite sau bloca accesul utilizatorilor',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Faceți clic pentru a adăuga un nou rol',
    }
  },
  accessControl: {
    users: 'Utilizatori',
    members: 'Membri',
    partnerTeam: 'Echipa de parteneri',
    roles: 'Roluri'
  }
};
