import 'zone.js/dist/zone-error';

export const environment = {
  production: false,
  environmentName: 'test',
  // baseUrl: 'https://api.v7.botpenguin.com/',
  // baseUrl: 'http://192.168.1.91:8080/',
  // baseUrl: 'https://api-dev.botpenguin.us/',
  baseUrl: 'https://api-exa.relinns.website/',
  // baseUrl: 'http://192.168.1.92/',
  facebookCdnUrl: 'https://dev-botpenguin.s3.us-east-2.amazonaws.com/tools',
  preview: 'cdn.js',
  botScript: 'cdn.js',
  // cdnUrl: 'https://dev-botpenguin.s3.us-east-2.amazonaws.com/',
  cdnUrl: 'https://launcher.botpenguin.us/',
  domain: 'https://api-dev.botpenugin.us/',
  profileId: 122,
  razorpay: 'rzp_test_bPDQkFo1rmCe3w',
  stripe: 'pk_test_GoVB6wxLsfTW9aTHPi50dkOq00FiMuWbkV',
  giphy: 'A7zgGwm71lsBSK5ED6cKTZoG6T0fTZnL',
  embedded: 'https://window.botpenguin.us',
  // socket: 'https://api-dev.botpenugin.us/',
  socket: 'http://192.168.1.91:4000/',
  // landingPage: 'https://page.botpenguin.com/',
  landingPage: 'http://192.168.1.91:3001/',
  googleClientId: '593742482865-t7ln3krn11dms37tif7fqfc5tktb9969.apps.googleusercontent.com',
  logo: '//cdn.botpenguin.com/assets/logos/botpenguin-logo.png',
  appName: 'ExpertEaxe AI',
  privacyPolicyUrl: 'https://botpenguin.com/privacy-policy',
  websiteUrl: 'https://botpenguin.com',
  favicon: 'favicon.ico',
  rootAgencyId: '614c81464774d71b1fad621f',
  chatWindowUrl: 'https://window-new.botpenguin.com',
  // chatWindowUrl: 'https://dev-botpenguin.s3.us-east-2.amazonaws.com/messenger',
  toolsUrl: 'https://dev-botpenguin.s3.us-east-2.amazonaws.com/tools',
  telegramWidgetFile: 'widget.js',
  whatsAppWidgetFile: 'whatsapp-widget.js',
  zohoClientId: '1000.JSJAGKT9SZ78EBIEOMNZTRI3SDASRQ',
  zohoDeskRedirectURL: 'https://dev.botpenguin.us/zoho-desk.html',
  zohoCrmRedirectURL: 'https://dev.botpenguin.us/zoho-crm.html',
  zohoProjectsRedirectURL: 'http://localhost:4200/zoho-projects.html',
  canvasUrl: 'http://192.168.1.92:3000',
  // canvasUrl: 'https://canvas.botpenguin.com',
  oldVersion: 'https://classic.app.botpenguin.com',
  newVersion: 'https://app.botpenguin.com',
  liveChatBotId: '5fed62fbb3053e15ba12c0de',
  leadGenerationBotId: '5fed62fbb3053e15ba12c4a1',
  outlookRedirectUrl: 'http://localhost:4200/outlook.html',
  ipDetailsUrl: 'https://api.botpenguin.com/meta/ip',
  salesForceRedirectUrl: 'http://localhost:4200/salesforce-crm.html',
  rootAgencyDomain: ['localhost:4200', '192.168.1.55:4200', '192.168.1.91:4200', 'dev.botpenguin.us', '192.168.1.88:4200', '192.168.1.91:4200', '192.168.1.92:4200', 'admin-exa.relinns.website'],
  recaptchaSiteKey: '6Le6zeYkAAAAAJ4RJn0X0Gpe5bGIwBoo34Oqi4eM',
  graphUrl: 'https://graph.facebook.com/v16.0/',
  servingDomain: 'http://localhost:4200',
  help: {
    productUpdates: 'https://help.botpenguin.com/product-updates/whats-new',
    requestFeature: 'https://page.botpenguin.com/632b02e3d955092f0582f320/5fed38346fb193dcc6c92d65',
    reportBug: 'https://page.botpenguin.com/632b09b7d955092f0582f952/5fed38346fb193dcc6c92d65',
    productRoadmap: 'https://botpenguin.notion.site/botpenguin/07030e5110894c19bdf5583d6fc7f773?v=8a612ba08cbd4a198e1c9db89813cb23'
  },
  instagramTestUserId: '648c64fb34e5b23a4463205a',

  demoBotNumber: '15550154880',
  conversationalBot: 'http://192.168.1.88:4203/',
  createBotPenguin: 'http://192.168.1.88:4202/',
  stripePublicKey: 'pk_test_sfBX8Xc4DCFAIEXu575HqT3q',
  embeddedBot: 'https://messenger.botpenguin.com/'
};
