export const agency = {
  activities: {
    activities: 'Aktivitäten',
    upcomingRenewals: 'Bevorstehende Erneuerungen',
    subscriptionActivities: 'Abonnementaktivitäten',
    upcomingDowngradeRequests: 'Bevorstehende Downgrade -Anfragen',
    serialNumber: 'Sr. Nr.',
    email: 'Email',
    phoneNumber: 'Telefon-Nr.',
    currentPlan: 'Derzeitiger Plan',
    price: 'Preis',
    daysLeft: 'Tage übrig',
    noDataFound: {
      upcomingDowngrades: 'Bevorstehende Herabstufungen',
      noUpcomingDowngradesFoundYet: 'Noch keine bevorstehenden Herabstufungen gefunden!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Bevorstehende Erneuerungen der Kunden',
      subscriptionActivities: 'Abonnementaktivitäten',
      upcomingDowngradeRequestsOfCustomers: 'Bevorstehende Downgrade -Anfragen der Kunden',
      serialNumber: 'Seriennummer',
      customerEmail: 'Kunden-eMail',
      customerPhoneNumber: 'Kundennummer',
      currentPlanOfCustomer: 'Aktueller Plan des Kunden',
      currentPlanPrice: 'Aktueller Planpreis',
      daysLeft: 'Tage des Kunden links von den Kunden',
    },
  },
  configuration: {
    configurationsHeader: 'Konfigurationen',
    emailHeader: 'Email',
    razorPayHeader: 'Razorpay',
    invoiceDetailsHeader: 'Rechnungs-Details',
    stripeHeader: 'Streifen',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Integrieren Sie Ihre E -Mail -Anbieter -Details, um Kunden -E -Mails nahtlos zu senden.',
      enterYourRazorPayAccountDetails: 'Geben Sie Ihre Razorpay -Kontodetails für eine nahtlose Kundenzahlungsbearbeitung ein.',
      enterYourStripeAccountDetails: 'Geben Sie Ihre Stripe -Konto -Details für eine nahtlose Kundenzahlungsverarbeitung ein.',
      enterYourCompanyDetails: 'Geben Sie Ihre Unternehmensdetails ein, um eine genaue Rechnungsstellung und die professionelle Rechnung zu generieren.',
    },
    email: {
      testEmail: 'Test-Email',
      addEmailIntegrationsDetails: 'Fügen Sie E -Mail -Integrationsdetails hinzu',
      integrationDescription: 'Um Ihre E -Mail -Anbieter -Details zu integrieren, geben Sie Ihre E -Mail -Anbieterinformationen an. Alle Kommunikations -E -Mails werden an Ihre Kunden mit dieser E -Mail -Adresse gesendet, um eine konsequente und zuverlässige Zustellung wichtiger Benachrichtigungen und Aktualisierungen zu gewährleisten.',
      awsRegion: 'AWS -Region',
      awsAccessKeyId: 'AWS Access Key ID',
      awsSecretAccessKey: 'AWS Secret Access Key',
      reset: 'Zurücksetzen',
      addDetails: 'Details hinzufügen',
      updateDetails: 'Details aktualisieren',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Betreten Sie Ihre AWS -Region. Die Region bestimmt den physischen Standort Ihrer Ressourcen und wirkt sich auf Faktoren wie die Datenlatenz und die Einhaltung von regulatorischen Vorschriften aus.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'Geben Sie Ihre AWS -Zugriffs -Schlüssel -ID ein. Dieser Schlüssel identifiziert Ihr AWS -Konto eindeutig und ist für die sichere Authentifizierung und den Zugriff auf AWS -Dienste erforderlich.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Geben Sie Ihren AWS Secret Access -Schlüssel ein. Dieser Schlüssel wird für die sichere Authentifizierung und den Zugriff auf AWS -Dienste verwendet.',
      },
      placeholders: {
        enterAwsRegion: 'Geben Sie die AWS -Region ein',
        enterAwsAccessKeyId: 'Geben Sie die Key -ID der AWS Access ein',
        enterAwsSecretAccessKey: 'Geben Sie den AWS Secret Access -Schlüssel ein',
      },
      modal: {
        deleteEmailIntegration: 'E -Mail -Integration löschen',
        wantToDeleteEmailIntegration: 'Sind Sie sicher, dass Sie diese E -Mail -Integration löschen möchten?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Fügen Sie Facebook -Integrationsdetails hinzu',
      integrateYourEmailProviderDetails: 'Integrieren Sie Ihre E -Mail -Anbieterdetails. Alle E -Mails werden mit dieser E -Mail an Ihre Kunden gesendet',
      facebookAppId: 'Facebook App ID',
      facebookSecretAccessKey: 'Facebook Secret Access Key',
      reset: 'Zurücksetzen',
      cancel: 'Stornieren',
      addDetails: 'Details hinzufügen',
      updateDetails: 'Details aktualisieren',
      delete: 'Löschen',
      testEmail: 'Test-Email',
      modal: {
        deleteFacebookIntegration: 'Facebook -Integration löschen',
        wantToDeleteFacebookIntegration: 'Sind Sie sicher, dass Sie diese E -Mail -Integration löschen möchten?',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Geben Sie Facebook Secret Access Key ein',
        enterFacebookAppId: 'Geben Sie die Facebook -App -ID ein',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Passen Sie die Nachricht an, wie Sie möchten, dass sie im Chat -Fenster sofort angezeigt werden',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Fügen Sie Rechnungsdetails hinzu',
      invoicesDescription: 'Um Rechnungen für Ihre Kunden zu generieren, geben Sie Ihre Firmendetails an, einschließlich Ihres Firmennamens, Ihrer Adresse, Ihrer Kontaktinformationen und aller relevanten Informationen, die für eine genaue Rechnungsstellung erforderlich sind. Diese Informationen werden verwendet, um für jede Transaktion professionelle Rechnungen im Namen Ihres Unternehmens zu erstellen.',
      companyName: 'Name der Firma',
      country: 'Land',
      selectCountry: 'Land auswählen',
      stateOrRegion: 'Staat/Region',
      streetAddress: 'Adresse',
      gstin: 'Gstin',
      postalCode: 'Postleitzahl',
      addDetails: 'Details hinzufügen',
      updateDetails: 'Details aktualisieren',
      reset: 'Zurücksetzen',
      delete: 'Löschen',
      loadMore: 'Mehr laden',
      time: 'Zeit',
      phoneNumber: 'Telefonnummer',
      email: 'Email',
      status: 'Status',
      message: 'Nachricht',
      response: 'Antwort',
      placeOfSupply: 'Platz der Lieferung',
      placeholders: {
        enterYourCompanyName: 'Geben Sie Ihren Firmennamen ein',
        enterYourStateOrRegion: 'Geben Sie Ihren Staat/Ihre Region ein',
        enterYourStreetAddress: 'Geben Sie Ihre Straßenadresse ein',
        enterYourGstin: 'Geben Sie Ihren GSTIN ein',
        enterPostalCode: 'Eingeben Sie die Postleitzahl ein',
        enterPlaceOfSupply: 'Geben Sie den Platz der Lieferung ein',
      },
      modal: {
        deleteEmailIntegration: 'E -Mail -Integration löschen',
        wantToDeleteEmailIntegration: 'Sind Sie sicher, dass Sie diese E -Mail -Integration löschen möchten?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: 'Geben Sie Ihren Firmennamen ein. Diese Informationen werden zur Identifizierung und Markenzwecken in Rechnungen verwendet.',
        TOOLTIP_COUNTRY: 'Wähle dein Land. Diese Informationen werden zur Identifizierung und Markenzwecken in Rechnungen verwendet.',
        TOOLTIP_STATE: 'Geben Sie Ihren Staat ein. Diese Informationen werden zur Identifizierung und Markenzwecken in Rechnungen verwendet.',
        TOOLTIP_STREET_ADDRESS: 'Geben Sie Ihre Straßenadresse ein. Diese Informationen werden zur Identifizierung und Markenzwecken in Rechnungen verwendet.',
        TOOLTIP_GSTIN: 'Geben Sie Ihren GSTIN ein. Diese Informationen werden zur Identifizierung und Markenzwecken in Rechnungen verwendet.',
        TOOLTIP_POSTAL_CODE: 'Geben Sie Ihre Postleitzahl ein. Diese Informationen werden zur Identifizierung und Markenzwecken in Rechnungen verwendet.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Geben Sie den Platz der Lieferung ein. Diese Informationen werden zur Identifizierung und Markenzwecken in Rechnungen verwendet.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'Fügen Sie Razorpay -Integrationsdetails hinzu',
      integrationDescription: 'Um Ihre Razorpay -Kontodetails zu integrieren, geben Sie bitte Ihre Razorpay -Kontoinformationen an. Alle Kundenzahlungen werden über Ihr Razorpay -Konto für nahtlose Transaktionen bearbeitet',
      razorPayAccessKeyId: 'Razorpay Access Key ID',
      razorPaySecretAccessKey: 'Razorpay Secret Access -Schlüssel',
      reset: 'Zurücksetzen',
      addDetails: 'Details hinzufügen',
      updateDetails: 'Details aktualisieren',
      loadMore: 'Mehr laden',
      time: 'Zeit',
      phoneNumber: 'Telefonnummer',
      email: 'Email',
      status: 'Status',
      message: 'Nachricht',
      response: 'Antwort',
      placeholders: {
        enterRazorPayAccessKeyId: 'Geben Sie die Schlüssel -ID der Razorpay -Zugriff ein',
        enterRazorPaySecretAccessKey: 'Geben Sie Razorpay Secret Access -Schlüssel ein',
        enterRazorPayAccessId: 'Geben Sie die Razorpay -Zugriffs -ID ein',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Geben Sie Ihre Razorpay -Zugriffsschlüssel -ID ein. Dieser Schlüssel identifiziert Ihre Integration mit RazorPay eindeutig und ist für sichere Zahlungsgeschäfte erforderlich.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Geben Sie Ihren Razorpay Secret Access -Schlüssel ein. Dieser Schlüssel ist für die sichere Kommunikation zwischen Anwendung und RazorPay von wesentlicher Bedeutung, wodurch die Verarbeitung von Transaktion und das Zahlungsmanagement von nahtlosen Transaktionen ermöglicht wird.',
      },
      modal: {
        deleteRazorPayIntegration: 'E -Mail -Integration löschen',
        wantToDeleteThisIntegrtion: 'Sind Sie sicher, dass Sie diese E -Mail -Integration löschen möchten?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Fügen Sie Stripe -Integrationsdetails hinzu',
      integrationDescription: 'Um Ihre Stripe -Kontodetails zu integrieren, geben Sie bitte Ihre Stripe -Kontoinformationen an. Alle Kundenzahlungen werden über Ihr Stripe -Konto für nahtlose Transaktionen verarbeitet',
      stripeSecretAccessKey: 'Stripe Secret Access -Schlüssel',
      stripePublicKey: 'Stripe Public Key',
      reset: 'Zurücksetzen',
      addDetails: 'Details hinzufügen',
      updateDetails: 'Details aktualisieren',
      cancel: 'Stornieren',
      loadMore: 'Mehr laden',
      time: 'Zeit',
      phoneNumber: 'Telefonnummer',
      email: 'Email',
      status: 'Status',
      message: 'Nachricht',
      response: 'Antwort',
      placeholders: {
        enterStripeSecretAccessKey: 'Geben Sie Stripe Secret Access -Schlüssel ein',
        enterStripePublicKey: 'Geben Sie den öffentlichen Streikschlüssel ein',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Geben Sie Ihren Stripe Secret Access -Schlüssel ein. Dieser Schlüssel wird verwendet, um Ihre Integration mit Stripe sicher zu authentifizieren und ist für die Bearbeitung von Kundenzahlungen erforderlich.',
        TOOLTIP_PUBLIC_KEY: 'Geben Sie Ihren Stripe Public Key ein. Dieser Schlüssel wird verwendet, um Ihre Integration mit Stripe auf der Kunden sicher zu authentifizieren und ist für die Bearbeitung von Kundenzahlungen erforderlich.',
      },
      modal: {
        deleteStripeIntegration: 'Streifenintegration löschen',
        wantToDeleteStripeIntegration: 'Sind Sie sicher, dass Sie diese Streifenintegration löschen möchten?',
      }
    }
  },
  customer: {
    manageCustomers: 'Kunden verwalten',
    addNewCustomers: 'Neue Kunden hinzufügen',
    ensureYouHaveAddedAllYourCustomersHere: 'Stellen Sie sicher, dass Sie alle Ihre Kunden hier hinzugefügt haben',
    customerName: 'Kundenname',
    nameIsRequired: 'Name ist erforderlich',
    emailId: 'E -Mail -ID',
    enterAValidEmail: 'Geben Sie eine gültige E -Mail ein',
    createPassword: 'Passwort erstellen',
    enterAStrongPassword: 'Geben Sie ein starkes Passwort ein',
    confirmPassword: 'Bestätige das Passwort',
    cancel: 'Stornieren',
    customer: 'Kunde',
    searchByNameOrEmail: 'Suche nach Namen oder E -Mail',
    filters: 'Filter',
    allCustomers: 'Alle Kunden',
    paidCustomers: 'Bezahlte Kunden',
    freeCustomers: 'Kostenlose Kunden',
    messageAddOnCustomers: 'Nachrichten hinzufügen zu Kunden',
    botWhiteLabelCustomers: 'Bot Whitelabel Kunden',
    sortBy: 'Sortiere nach',
    sortByFrequency: 'Sortieren nach Frequenz',
    all: 'Alle',
    joinedDate: 'Date beigetreten',
    plan: 'Planen',
    lastLogin: 'Letzte Anmeldung',
    email: 'Email',
    nextDue: 'Als nächstes fällig',
    frequency: 'Frequenz',
    clearAll: 'Alles löschen',
    apply: 'Anwenden',
    messageConsumed: 'Nachricht konsumiert',
    addCustomer: 'Kunden hinzufügen',
    serialNumber: 'Sr. Nr.',
    name: 'Name',
    contact: 'Kontakt',
    limit: 'Grenze',
    location: 'Standort',
    actions: 'Aktionen',
    joinedOn: 'Beigetreten',
    typeOfCustomer: 'Kundentyp',
    exceptWhatsapp: ' (Außer WhatsApp)',
    exceptWA: '(Außer wa)',
    wa: '(WA)',
    loadMore: 'Mehr laden',
    conversationsWhatsapp: ' Gespräche (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'Geben Sie Kunden -E -Mail ein',
      enterCustomerPassword: 'Geben Sie das Kundenpasswort ein',
      enterCustomerConfirmPassword: 'Kundenbestätigen Sie das Passwort bestätigen',
      enterCustomerName: 'Geben Sie den Kundennamen ein',
    },
    placeholders: {
      enterCustomerEmail: 'Geben Sie Kunden -E -Mail ein',
      searchByNameOrEmail: 'Suche nach Namen oder E -Mail',
      enterPassword: 'Passwort eingeben',
      enterCustomerName: 'Geben Sie den Kundennamen ein',
    }
  },
  dashboard: {
    dashboard: 'Armaturenbrett',
    overview: 'Überblick',
    totalCustomers: 'Gesamtkunden',
    paidCustomers: 'Bezahlte Kunden',
    freeCustomers: 'Kostenlose Kunden',
    thisWeeksSignUp: 'In dieser Wochen Anmeldung',
    todaysSignUp: 'Heutzutage Anmeldungen',
    totalEarnings: 'Gesamteinnahmen',
    nA: 'N / A',
    customerName: 'Kundenname',
    nameIsRequired: 'Name ist erforderlich',
    emailId: 'E -Mail -ID',
    enterAValidEmail: 'Geben Sie eine gültige E -Mail ein',
    editCustomer: 'Kunden bearbeiten',
    recentSignUps: 'Neue Anmeldungen',
    serialNumber: 'Sr. Nr.',
    name: 'Name',
    email: 'Email',
    contact: 'Kontakt',
    plan: 'Planen',
    limit: 'Grenze',
    location: 'Standort',
    lastLogin: 'Letzte Anmeldung',
    joinedOn: 'Beigetreten',
    actions: 'Aktionen',
    exceptWhatsapp: ' (Außer WhatsApp)',
    messageConsumed: 'Nachricht konsumiert',
    conversationsWhatsapp: ' Gespräche (WhatsApp)',
    placeholders: {
      enterCustomerName: 'Geben Sie den Kundennamen ein',
      enterCustomerEmail: 'Geben Sie Kunden -E -Mail ein',
    },
    modal: {
      deleteCustomer: 'Kunden löschen',
      areYouSure: 'Sind Sie sicher, dass Sie diesen Kunden löschen möchten?',
    }
  },
  downgradeRequests: {
    activites: 'Aktivitäten',
    upcomingRenewals: 'Bevorstehende Erneuerungen',
    subscriptionActivities: 'Abonnementaktivitäten',
    upcomingDonwgradeRequests: 'Bevorstehende Downgrade -Anfragen',
    serialNumber: 'Sr. Nr.',
    email: 'Email',
    phoneNumber: 'Telefon-Nr.',
    currentPlan: 'Derzeitiger Plan',
    requestedOn: 'Angefordert auf',
    downgradeOn: 'Herabstufung an',
    nA: 'N / A',
    commaMonthly: 'Monatlich',
    commaYearly: ' Jährlich',
    noDataFound: {
      downgradeRequests: 'Anfragen herabstufen',
      noDowngradeRequestFound: 'Noch keine Downgrade -Anfragen gefunden!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Bevorstehende Erneuerungen der Kunden',
      subscriptionActivities: 'Abonnementaktivitäten',
      upcomingDowngradeOfCustomers: 'Bevorstehende Downgrade -Anfragen der Kunden',
      serialNumber: 'Seriennummer',
      customerEmail: 'Kunden-eMail',
      customerPhoneNumber: 'Kundennummer',
      currentPlanOfCustomer: 'Aktueller Plan des Kunden',
      currentPlanPrice: 'Aktueller Planpreis',
    }
  },
  emailIntegration: {
    emailIntegration: 'E -Mail -Integration',
    addEmailIntegrationDetails: 'Fügen Sie E -Mail -Integrationsdetails hinzu',
    integrateYourEmailProviderDetails: 'Integrieren Sie Ihre E -Mail -Anbieterdetails. Alle E -Mails werden mit dieser E -Mail an Ihre Kunden gesendet',
    awsRegion: 'AWS -Region',
    awsRegionIsRequired: 'AWS -Region ist erforderlich',
    awsAccessKeyId: 'AWS Access Key ID',
    awsAccessKeyIdIsRequired: 'AWS Access Key ID ist erforderlich',
    awsSecretAccessKey: 'AWS Secret Access Key',
    awsSecretAccessKeyIsRequired: 'AWS Secret Access Key ist erforderlich',
    cancel: 'Stornieren',
    test: 'Prüfen',
    action: 'Aktion',
    testEmail: 'Test-Email',
    addDetails: 'Details hinzufügen',
    updateDetails: 'Details aktualisieren',
    saveDetails: 'Details speichern',
    placeholders: {
      enterAwsRegion: 'Geben Sie die AWS -Region ein',
      enterAwsAccessKeyId: 'Geben Sie die Key -ID der AWS Access ein',
      enterAwsSecretAccessKey: 'Geben Sie den AWS Secret Access -Schlüssel ein',
    },
    modal: {
      deleteEmailIntegration: 'E -Mail -Integration löschen',
      areYouSure: 'Sind Sie sicher, dass Sie diese E -Mail -Integration löschen möchten?',
    },
    noDataFound: {
      emailIntegration: 'E -Mail -Integration',
      noEmailIntegrationFoundYet: 'Noch keine E -Mail -Integration gefunden!',
    }
  },
  subscription: {
    activities: 'Aktivitäten',
    upcomingRenewals: 'Bevorstehende Erneuerungen',
    subscriptionActivities: 'Abonnementaktivitäten',
    upcomingDonwgradeRequests: 'Bevorstehende Downgrade -Anfragen',
    filters: 'Filter',
    manageActivities: 'Aktivitäten verwalten',
    sortBy: 'Sortiere nach',
    all: 'Alle',
    upgrades: 'Upgrades',
    donwgrades: 'Herabstufungen',
    renewals: 'Erneuerung',
    serialNumber: 'Sr. Nr.',
    email: 'Email',
    phoneNumber: 'Telefon-Nr.',
    previousPlan: 'Vorheriger Plan',
    newPlan: 'Neuer Plan',
    description: 'Beschreibung',
    date: 'Datum',
    nA: 'N / A',
    upgraded: 'verbessert',
    commaYearly: ' Jährlich',
    commaMonthly: ' Monatlich',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'Noch keine Abonnementaktivitäten gefunden!',
      subscriptionsActivities: 'Abonnementaktivitäten',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Bevorstehende Erneuerungen der Kunden',
      subscriptionActivities: 'Abonnementaktivitäten',
      upcomingDowngradeRequests: 'Bevorstehende Downgrade -Anfragen der Kunden',
      serialNumber: 'Seriennummer',
      customerEmail: 'Kunden-eMail',
      customerPhoneNumber: 'Kundennummer',
      previousPlan: 'Vorheriger Plan des Kunden',
      newPlan: 'Neuer Plan des Kunden',
      description: 'Beschreibung des Plans',
      typeOfPayment: 'Zahlungsart',
    }
  },
  feedbacks: {
    feedbacks: 'Feedbacks',
    serialNumber: 'Sr. Nr.',
    customer: 'Kunde',
    type: 'Typ',
    rating: 'Bewertung',
    reason: 'Grund',
    description: 'Beschreibung',
    createdAt: 'Hergestellt in',
    nA: 'N / A',
    loadMore: 'Mehr laden',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Konto- und Bot -Löschung Feedbacks',
      noFeedbacksFoundYet: 'Noch keine Feedbacks gefunden!',
    },
    appTooltip: {
      serialNumber: 'Seriennummer',
      customerEmail: 'Kunden-eMail',
      feedbackForBotOrAccountDeletion: 'Feeback für (Bot/Konto -Löschung)',
      customerRating: 'Kundenbewertung',
      customerReason: 'Kundengrund',
      feedbackCreatedAt: 'Feedback erstellt bei',
      customerFeedback: 'Kundenbewertung',
    }
  }
};
