const brand = sessionStorage.getItem('brand');

export const subscriptionToolTipText = {
  TOOLTIP_PLAN_HEADING: 'We have the most affordable prices for all of your needs.',
  TOOLTIP_WHATSAPP_PLAN_HEADING: 'Whatsapp plan',
  TOOLTIP_ADDS_ON_HEADING: 'Buy additional massages packs and whitelabel bot',
  TOOLTIP_ADDS_ON_MESSAGE: 'If you\'ve reached your monthly message limit, you can buy additional packs.',
  TOOLTIP_SUBSCRIPTION_HEADING: 'Check your current subscription details',
  TOOLTIP_BABY_PLAN: 'Our free plan',
  TOOLTIP_KING_PLAN: 'Our most popular plan',
  TOOLTIP_EMPEROR_PLAN: 'Customized plan for unlimited messages, unlimited data, unlimited team members',
  TOOLTIP_CONTACT_US: 'Reach to us via WhatsApp or email at contact@botpenguin.com to learn more about our customized plan',
  TOOLTIP_UPGRADE: 'Upgrade your current plan for additional features',
  TOOLTIP_HORIZONTAL_BAR: 'Check our monthly or yearly pricing from 3K to 500K messages by moving the bar left to right.',
  TOOLTIP_MONTHLY: 'Select a monthly payment cycle',
  TOOLTIP_YEARLY: 'Select a yearly payment cycle for discount benefits',
  TOOLTIP_BILLING_ADDRESS_HEADING: 'Update your billing details for invoicing.',
  TOOLTIP_INVOICES_HEADING: 'Check your previous invoices.',
  TOOLTIP_INVOICES_ACTION: 'Click to receive the invoice in your email account'
};

export const homeToolTipText = {
  HOME_INSIGHT: 'Provide insight into all of your bots\' messages and leads (Data updated every midnight)',
  HOME_MESSAGES: 'Messages consumed by your account during this billing cycle. (Messages from WhatsApp, Facebook, and Telegram Bot are excluded.)',
  HOME_TOTAL_MESSAGES: 'Total messages consumed by your account since account creation (Messages from WhatsApp, Facebook, and Telegram Bot are excluded.)',
  LEADS: 'Leads captured by bots for your account during this billing cycle. ',
  TOTAL_LEADS: 'Total contacts/leads captured by bots for your account since account creation (Data updated every midnight).',
  YOUR_BOT: 'Overview of all your bots and their usage during this billing cycle.',
  NAME: 'Bot\'s name',
  PLATFORM: 'Bot\'s platform',
  MESSAGES: 'Bot\'s total messages since account creation',
  CONTACTS: 'Bot\'s total contacts/leads',
  ACTIONS: 'Edit/Delete the bot',
  EDIT_ICON: 'Edit the bot chat flow and settings',
  DELETE_ICON: 'Delete the bot',
  TOOL_TIP_CLASSIC_VERSION: `old version of ${brand} UI`
};

export const settingToolTipText = {
  TOOLTIP_DETAILS_HEADING: 'Update your account profile',
  TOOLTIP_NAME: 'Enter your profile name',
  TOOLTIP_EMAIL_ADDRESS: 'Enter your email address',
  TOOLTIP_PASSWORD_HEADING: 'Change your password',
  TOOLTIP_CHANGE_PASSWORD_TITLE: `A secure password helps protect your ${brand} account`,
  TOOLTIP_CURRENT_PASSWORD: 'Enter old password',
  TOOLTIP_NEW_PASSWORD: 'Enter new password',
  TOOLTIP_THIRD_PARTY_HEADING: 'Third Party Integrations',
  TOOLTIP_CONFIRM_PASSWORD: 'Confirm your new password',
  TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
  TOOLTIP_INTEGRATIONS: 'Integrate',
  TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Add or update custom attributes',
  TOOLTIP_CUSTOM_ATTRIBUTES: 'Manage your custom attributes',
  TOOLTIP_TAGS_HEADING: 'Manage your tags'
};

export const sidebarToolTipText = {
  TOOLTIP_GO_ONLINE: 'Toggle to allow or block live chat requests',
  TOOLTIP_NOTIFICATION: 'All notifications',
  TOOLTIP_MY_ACCOUNT: 'Your profile and password security',
  TOOLTIP_LOGOUT: 'Logout from the dashboard'
};

export const createNewBotToolTipText = {
  TOOLTIP_SELECT_PLATFORM_TITLE: 'Please select the platform for which you wish to create the bot',
  TOOLTIP_TELEGRAM_BOT_ICON: 'Connect bot to your Telegram messaging App for automated replies',
  TOOLTIP_FACEBOOK_BOT_ICON: 'Connect bot to your Facebook business page.',
  TOOLTIP_WHATSAPP_BOT_ICON: 'Connect bot to Whatsapp for automated conversations',
  TOOLTIP_LIVE_CHAT_BOT_ICON: 'Use live chat widget to connect to your customers in real time.',
  TOOLTIP_CHATBOT_FB_BOT_ICON: 'Create a bot page to capture visitors\' attention and engage them in conversations.',
  TOOLTIP_WEBSITE_BOT_ICON: 'Connect bot to your website and generate business contacts/leads',
  TOOLTIP_CONNECT_TELEGRAM_BOT: 'Connect the Bot to your Telegram App',
  TOOLTIP_TELEGRAM_BOT_NAME: 'Enter your Bot Name',
  TOOLTIP_TELEGRAM_BOT_TOKEN: 'Enter your Bot Token',
  TOOLTIP_LOGIN_WITH_FACEBOOK: 'Login to your Facebook account and connect Bot to your Facebook business page.',
  TOOLTIP_CONNECT_WHATSAPP_BOT: 'Connect Bot to your WhatsApp account',
  TOOLTIP_WHATSAPP_BOT_NAME: 'Enter your Bot Name',
  TOOLTIP_WHATSAPP_BOT_KEY: 'Enter Whatsapp API Key',
  TOOLTIP_TOOLTIP_CONTACT_US_FOR_WHATSAPP_API_KEY: `Contact ${brand}\'s support team to generate Whatsapp API key for your account`,
  TOOLTIP_YOUR_NAME: 'Enter your Name',
  TOOLTIP_WHATSAPP_PHONE: 'Select your country code from the drop down and enter your WhatsApp Business number',
  TOOLTIP_MONTHLY_MESSAGES: 'Select the range of monthly messages that you expect WhatsApp bot to send and receive.',
  TOOLTIP_NOTES: 'You can give us a brief note if there is any specific requirement.',
  TOOLTIP_WHATSAPP_SUBMIT_BUTTON: 'Submit details for WhatsApp API key and our Sales team will contact you within 24 hours.',
  TOOLTIP_CREATE_NEW_CHATBOT_FB_BOT: 'Build a Bot Page that matches your brand\'s personality',
  TOOLTIP_CHATBOT_FB_BOT_NAME: 'Enter you Bot name',
  TOOLTIP_CHATBOT_THEME_COLOR: 'Select a theme color for your chat window',
  TOOLTIP_CHATBOT_BOT_ICON: 'Choose a personalized icon or upload your logo',
  TOOLTIP_CHATBOT_UPLOAD_IMAGE_ICON: 'Upload any image from local storage. Add a PNG or JPG squared image under 20MB',
  TOOLTIP_CREATE_LIVE_CHAT_WIDGET: 'Build a Live Chat Widget that matches your brand\'s personality',
  TOOLTIP_LIVE_CHAT_BOT_NAME: 'Enter you Bot name',
  TOOLTIP_lIVE_CHAT_BOT_THEME_COLOR: 'Select a theme color for your chat window',
  TOOLTIP_LIVE_CHAT_BOT_ICON_TEXT: 'Choose a personalized icon or upload your logo',
  TOOLTIP_LIVE_CHAT_BOT_UPLOAD_IMAGE_ICON: 'Upload any image from local storage. Add a PNG or JPG squared image under 20MB',
  TOOLTIP_CREATE_NEW_WEBSITE_BOT: 'Build a bot interface for your website that matches your brand\'s personality',
  TOOLTIP_WEBSITE_BOT_NAME: 'Enter you Bot name',
  TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Select a theme color for your chat window',
  TOOLTIP_WEBSITE_BOT_ICON_TEXT: 'Choose a personalized icon or upload your logo',
  TOOLTIP_WEBSITE_BOT_UPLOAD_IMAGE_ICON: 'Upload any image from local storage. Add a PNG or JPG squared image under 20MB',
  TOOLTIP_WEBSITE_BOT_CUSTOMIZE_BOT_LOOK: `Build a bot interface that matches your brand's personality`,
  TOOLTIP_LIVECHAT_BOT_CUSTOMIZE_BOT_LOOK: `Build a widget that matches your brand's personality`
};

export const websiteTooTipText = {
  TOOLTIP_CHAT_RETENTION: 'Select if you wish to retain bot user\'s chats forever on the chat window or only for a browser session.',
  TOOLTIP_EDIT_YOUR_FLOW: 'Build engaging conversation flows for your bot users.',
  TOOLTIP_INSTALL_YOUR_CHAT_BOT: 'Integrate the bot to your Landing Page',
  TOOLTIP_BOT_SETTINGS: 'Customize your bot\'s appearance and functionality.',
  TOOLTIP_THIRD_PARTY_INTEGRATIONS: 'Integrate your bot with other Apps/CRMs to create automation for your business.',
  TOOLTIP_EDIT_YOUR_FLOW_TITLE: 'Customize your bot\'s chat flow',
  TOOLTIP_TEST_FLOW_BUTTON: 'Click here to test the chat flow',
  // TOOLTIP_THIRD_PARTY_INTEGRATIONS_TITLE: 'Integrate the Bot to your Website',
  // TOOLTIP_PLATFORMS_TITLE: 'Install the Bot to your custom coded or WordPress website by copy-pasting a unique code.',
  TOOLTIP_INSTALL_BUTTON: 'Click to copy the code',
  TOOLTIP_CUSTOMIZE_YOUR_BOT_LOOK: 'Customize positioning and look of your bot interface.',
  TOOLTIP_WEB_1ST_POSITION: 'Position your bot on the bottom-left of the bot user\'s desktop',
  TOOLTIP_WEB_2ND_POSITION: 'Position your bot on the middle right corner of the bot user\'s desktop',
  TOOLTIP_WEB_3RD_POSITION: 'Position your bot on the bottom right corner of the bot user\'s desktop',
  TOOLTIP_MOBILE_1ST_POSITION: 'Position your bot on the bottom left corner of the bot user\'s mobile',
  TOOLTIP_MOBILE_2ND_POSITION: 'Position your bot on the middle right corner of the bot user\'s mobile',
  TOOLTIP_MOBILE_3RD_POSITION: 'Position your bot on the bottom right corner of the bot user\'s mobile',
  TOOLTIP_CHAT_THEME_COLOR: 'Choose color of your bot\'s chat window',
  TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Choose background color of Bot\'s chat window',
  TOOLTIP_WINDOW_HEIGHT: 'Resize bot\'s chat window',
  TOOLTIP_WINDOW_WIDTH: 'Resize width of bot\'s chat window',
  TOOLTIP_BOT_ICON: 'Select an icon or upload logo/image for your bot',
  TOOLTIP_HIDE_LANDINGPAGE_INFO: 'You can choose to hide the logo and texts to show only the chat window on the screen',
  TOOLTIP_CALL_TO_ACTION: 'An inviting catch phrase that will make website visitor talk to the Bot',
  TOOLTIP_BOT_GENERAL_SETTINGS_TITLE: 'Set the time zone, language, and a little note for the bot user when your agent is not online.',
  TOOLTIP_WAITING_MESSAGES: 'Customize message as you wish it to appear on chat window immediately after a bot user sends request for Live Chat',
  TOOLTIP_UNAVAILABILITY_MESSAGE: 'Customize message as you wish it to appear on chat window when none of your agents available for Live Chat with the bot user',
  TOOLTIP_LANGUAGE: 'Select the bot\'s language',
  TOOLTIP_TIME_ZONE: 'Select the time-zone for your bot',
  TOOLTIP_CHATBOT_STATUS: 'Activate /deactivate the bot',
  TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Send you email notifications even when the bot user hasn\'t finished the conversation.',
  TOOLTIP_INVALIDATE_CACHE: 'Click here to clear cache as and when you edit bot chat flow or settings as changes reflect only after 15 minutes',
  TOOLTIP_BOT_ALERTS_SETTINGS_TITLE: 'Specify how your Bot will notify you of new leads.',
  TOOLTIP_SUBJECTS: 'Customize subject of your email notifications from the bot',
  TOOLTIP_EMAIL_NOTIFICATION: 'Enable/disable the bot\'s notification on your email for new leads',
  TOOLTIP_EMAIL_ADDRESS: 'Submit email address of the account where you wish to receive email notifications. You can add multiple email addresses by entering one and pressing enter to add another.',
  // tslint:disable-next-line:max-line-length
  TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Enable/disable bot\'s notification on your email if a user previously captured as a lead interacts with the bot again.',
  // TOOLTIP_DESKTOP_NOTIFICATIONS: 'Activate/Deactivate bot message\'s notifications on your desktop.',
  TOOLTIP_BOT_TRIGGER_SETTINGD_TITLE: 'Make sure your bot\'s pop-ups are perfectly timed.',
  TOOLTIP_DESKTOP_TRIGGER_TIME: 'Waiting time in seconds, on visitor\'s desktop devices, before your bot automatically pops up',
  TOOLTIP_MOBILE_TRIGGER_TIME: 'Waiting time in seconds, on visitor\'s mobile, before your bot automatically pops up',
  TOOLTIP_CHAT_BUBBLE_OPENING: 'When visitor closes the chat window, select how shall be the bot auto pop-up behavior',
  TOOLTIP_BOT_USER_SETTINGS_TITLE: 'Customize where you want your bot to appear and where not.',
  TOOLTIP_DEVICE_SUPPORT: 'Select on which devices your bot should appear',
  TOOLTIP_BLOCK_IP_ADDRESS: 'Enter IP addresses where you don\'t wish your bot to appear',
  TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Enter URL of your webpages where you don\'t wish your bot to appear',
  TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Select locations from where you wish users to access your bot',
  TOOLTIP_LIVE_CHAT_SETTINGS_TITLE: 'Enable the chat options for your visitors to transfer chats, live chat, and refresh chat.',
  TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Allow website visitors to transfer the chat to WhatsApp no. of the respective agent they\'re connected to. Live Chat feature must be enabled for this feature to work.',
  TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Allow the bot users to refresh chat so that they can start conversation afresh.',
  TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Allow the bot users to transfer the chat to bot anytime in-between conversation with the live chat agent.',
  TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Allow the bot users to request for live chat with the agent whenever they wish to.',

  TOOLTIP_ALL_INTEGRATION_TITLE: 'Connect your bot with available third-party Apps/CRMs',
  TOOLTIP_ALL_INTEGRATION_CONNECT_BUTTON: 'Click to connect the App/CRM with your bot',
  TOOLTIP_MY_INTEGRATION_TITLE: 'Integrate your custom created Apps with your bot',
  TOOLTIP_MY_INTEGRATION_CUSTOM_INTEGRATION: `Integrate your own Apps with ${brand}`,
  TOOLTIP_ADD_INTEGRATION_BUTTON: 'Click to create a new integration set-up',
  TOOLTIP_CREATE_NEW_INTEGRATION: 'Create an integration set-up to connect your custom created App with the bot',
  TOOLTIP_INTEGRATION_NAME: 'Name your integration set-up',
  TOOLTIP_MY_INTEGRATION_UPLOAD_ICON: 'Add logo/image to represent your integration set-up',
  TOOLTIP_MY_INTEGRATION_DESCRIPTION: 'Write description for your App',
  TOOLTIP_MY_INTEGRATION_API_URL: 'API URL of your CRM that will add the lead provided by Bot',
  TOOLTIP_MY_INTEGRATION_REQUEST_METHOD: 'Specify request method used by CRM API to authenticate. Enter Query paramater, JSON code or Question for authentication',
  TOOLTIP_MY_INTEGRATION_QUERY_PARAMETER: 'Enter query parameter and it\'s value for the API',
  TOOLTIP_MY_INTEGRATION_QUESTION: 'Add question and select parameter for the API',
  TOOLTIP_MY_INTEGRATION_HEADERS: 'Add header key and it\'s value to authenticate',
  TOOLTIP_MY_INTEGRATION_REQUEST_BODY: 'Type your JSON code',
  TOOLTIP_MY_INTEGRATION_VALIDATE: 'Click to validate JSON code',
  TOOLTIP_MY_INTEGRATION_CONNECT: 'Click connect to authenticate and integrate Bot with your CRM',

  TOOLTIP_CHATBOT_FB_BOT_EDIT_FLOW: 'Build engaging conversation flows for your bot users.',
  TOOLTIP_CHATBOT_FB_BOT_INSTALL_BOT: 'Integrate the Bot to your Landing Page',
  TOOLTIP_CHATBOT_FB_BOT_BOT_SETTINGS: 'Customize your Bot\'s appearance and functionality.',
  TOOLTIP_CHATBOT_FB_BOT_THIRD_PARTY_INTEGRATION: 'Integrate your Bot with other Apps/CRMs to create automation for your business.',

  TOOLTIP_LIVE_CHAT_EDIT_FLOW: 'Build engaging conversation flows for the bot users.',
  TOOLTIP_LIVE_CHAT_INSTALL_BOT: 'Integrate the Live Chat Widget to your web pages',
  TOOLTIP_LIVE_CHAT_BOT_SETTINGS: 'Customize your Bot\'s appearance and functionality.',

  TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Upload a logo for the chatbot page.'
};

export const analyticsToolTipText = {
  TOOLTIP_OVERVIEW_TITLE: 'Analytics for all your bots messages and leads. (Data updated every midnight)',
  TOOLTIP_FILTERS: 'Filter data for selected bot',
  TOOLTIP_MESSAGES: 'Total messages consumed by your account since account creation',
  TOOLTIP_LEADS: 'Total leads captured by bots for your account since account creation.',
  TOOLTIP_ANALYTICS_SUMMARY: 'Summarize daily, weekly, or monthly data from messages and contacts/leads for specific and all bots (Get updated every midnight)',
  TOOLTIP_BOTWISE_SUMMARY: 'Summarize data from messages and contacts/leads for specific bots for the current billing cycle, current month, or since account creation (Get updated every midnight)'
};

export const whatsappToolTipText = {
  TOOLTIP_EDIT_YOUR_FLOW: 'Build engaging conversation flows for the bot users.',
  TOOLTIP_BOT_SETTINGS: 'Customize your Bot\'s appearance and functionality.',
  TOOLTIP_THIRD_PARTY_INTEGRATIONS: 'Integrate your Bot with other Apps/CRMs to create business automation',
  TOOLTIP_EDIT_YOUR_CHAT_FLOW_TITLE: 'Customize your Bot\'s chat flow',
  TOOLTIP_BOT_SETTINGS_TITLE: 'Set up your Bot chat flows, drip campaigns, broadcasts, and manage customer orders',
  TOOLTIP_CHAT_FLOW_TITLE: 'Customize the chat flow templates, and create multiple chat flows for different target audiences.',
  TOOLTIP_CREATE_NEW_CHAT_FLOW: 'You can easily create new chat flows and edit the old one\'s',
  TOOLTIP_CREATE_FLOW: 'Click to create a new chat flow',
  TOOLTIP_FLOW_NAME: 'Enter chat flow name',
  TOOLTIP_FLOW_TABLE_NAME: 'Name of the chat flow',
  TOOLTIP_NO_OF_MESSAGES: 'No. of message components in the chat flow',
  TOOLTIP_DEFAULT_FLOW: 'Use this toggle to set a chat flow as default, which will be served to all the new users.',
  TOOLTIP_REVISIT_FLOW: 'Use this toggle to select a chat flow to be served to users when they revisit your chatbot.',
  TOOLTIP_ACTION: 'Edit or delete the chat flow',
  TOOLTIP_EDIT_ICON: 'Click to edit the chat flow',
  TOOLTIP_DELETE_FLOW: 'Delete the chat flow',
  TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Create a customer onboarding journey using a drip campaign of scheduled chat flows.',
  TOOLTIP_CREATE_NEW_DRIP_CAMPAIGN: 'You can easily create and edit multiple campaigns for various contacts/leads.',
  TOOLTIP_CREATE_CAMPAIGN: 'Click to add a new campaign',
  TOOLTIP_SCHEDULE: 'Plan when and which chat flow to send first in this campaign.',
  TOOLTIP_AFTER_DAYS: 'Schedule when to send the first flow of the campaign in minutes, hours, or days.',
  TOOLTIP_ADD_MESSAGES: 'Click to add a new flow to the campaign',
  TOOLTIP_ADD_CAMPAIGN_NAME: 'Enter a new campaign name',
  TOOLTIP_CAMPAIGN_NAME: 'Name of the campaign',
  TOOLTIP_NO_MESSAGES_SENT: 'No. of messages sent by the campaign',
  TOOLTIP_CAMPAIGN_CREATED_AT: 'Date and time of creation of the campaign',
  TOOLTIP_CAMPAIGN_UPDATED_AT: 'Date and time when the campaign was last modified',
  TOOLTIP_CAMPAIGN_ACTION: 'Edit or delete the campaign',
  TOOLTIP_CAMPAIGN_EDIT_ICON: 'Click to edit the campaign',
  TOOLTIP_CAMPAIGN_DELETE_ICON: 'Click to delete the campaign',
  TOOLTIP_UPDATE_CAMPAIGN: 'Click to save the modifications made in campaign set-up',
  TOOLTIP_WIDGET_TITLE: 'Create widgets (chat interfaces) and embed them in promotional web pages to direct visitors to the bot',
  TOOLTIP_CREATE_WIDGET_HEADING: 'Create multiple widgets and embed them in the promotional web pages to direct visitors to the Bot.',
  TOOLTIP_CREATE_WIDGET: 'Click to create a new widget',
  TOOLTIP_WIDGET_NAME: 'Enter a new widget name',
  TOOLTIP_CHOOSE_TYPE_WIDGET: 'Choose how you want your widget to be integrated into the web pages.',
  TOOLTIP_WIDGET_BAR: 'Position widget as headline bar on top of the web page',
  TOOLTIP_WIDGET_SLIDE_IN: 'A square widget will slide in from the bottom right of the page',
  TOOLTIP_WIDGET_PAGE_TAKEOVER: 'Widget will be displayed as full page over the page',
  TOOLTIP_WIDGET_BUTTON: 'Widget will appear as a call to action button in the middle of the page',
  TOOLTIP_WIDGET_WHATSAPP_ICON: 'Widget will appear as a WhatsApp icon on bottom-right of the page',
  TOOLTIP_WIDGET_URL_REF: 'Widget will appear as URL in the middle of the page',
  TOOLTIP_WIDGET_QR_CODE: 'Widget will appear as QR code n in the middle of the page',

  TOOLTIP_BACKGROUND_COLOR: 'Choose widget\'s background color',
  TOOLTIP_HEADLINE_COLOR: 'Choose widget\'s headline text color',
  TOOLTIP_DESCRIPTION_COLOR: 'Choose widget\'s body text color',
  TOOLTIP_BUTTON_BACKGROUND_COLOR: 'Choose widget\'s call to action button color',
  TOOLTIP_CONTACT_NUMBER: 'Contact information that will appear on the widget',
  TOOLTIP_ENTRY_MESSAGE: 'Default message that will appear on the widget',
  TOOLTIP_BUTTON_SIZE: 'Select the call to action button size as small, medium, big',
  TOOLTIP_WHEN_DOES_DISPLAY: 'Schedule after how many seconds widget displays over the content',
  TOOLTIP_SHOW_WIDGET_TO_SAME_USER_AGAIN: 'Schedule when widget should reappear to a repeat visitor',
  TOOLTIP_MANUALLY_CLOSED_BY_USER: 'Schedule when widget should reappear to a visitor who has manually closed the widget',
  TOOLTIP_INSTALL_WIDGET: 'Copy the javasript snippet and paste it whereever you need in your HTML, website etc.',
  TOOLTIP_COPY_CODE: 'Copy the javascript snippet',

  TOOLTIP_BROADCAST_TITLE: 'Blast messages or flows to your contacts on a single click at the pre-scheduled time',
  TOOLTIP_CREATE_BROADCAST: 'You can easily create and edit multiple broadcasts for various segmented contacts',
  TOOLTIP_ADD_BROADCAST_NAME: 'Enter a new broadcast name',
  TOOLTIP_BROADCAST_SELECT_TYPE: 'Select if your are broadcasting a flow or a message.',
  TOOLTIP_BROADCAST_FLOW: 'Select the chat flow you wish to broadcast,',
  TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Select the contacts segment to which you wish to broadcast',
  TOOLTIP_BROADCAST_NAME: 'Name of the broadcast',
  TOOLTIP_BROADCAST_TYPE: 'Flow or message selected for the broadcast',
  TOOLTIP_BROADCAST_SEGMENT: 'Segment for which broadcast is created',
  TOOLTIP_BROADCAST_AT: 'Date and time scheduled for the broadcast',
  TOOLTIP_BROADCAST_STATUS: 'Broadcast status as scheduled, currently running or completed',

  TOOLTIP_ORDERS_TITLE: 'List of orders placed by the customer',
  TOOLTIP_ORDERS_PHONE_NO: 'Phone number of the customer who placed the order',
  TOOLTIP_NO_OF_ITEMS: 'No. of items ordered',
  TOOLTIP_ORDER_VALUE: 'Value of the order',
  TOOLTIP_ORDER_DATE: 'The order was placed on this date.',
  TOOLTIP_ORDER_STATUS: 'Status of order as confirmed, pending, rejected. or shipped',
  TOOLTIP_ORDER_CHAT: 'All previous chat conversations regarding this order',

  TOOLTIP_MANAGE_TITLE: 'Managing bot functionality for contacts/leads and keywords',
  TOOLTIP_MANAGE_LEADS_HEADING: 'Manage bot\'s functionality for the contacts/leads',
  TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Suggest if bot to send contact/lead information to integration CRM immediately, or after 5 minutes, or at the end of the flow',
  TOOLTIP_LEAD_SMART_FLOW: 'Choose any default flow to send it to all the bot users, or smart flow (determined by the keywords input by the bot user).',
  TOOLTIP_LEAD_WELCOME_MESSAGE: 'Select reply material (flow, template, text, image) to send if bot receives no keyword match from bot user\'s response',
  TOOLTIP_IT_ALMOST_REACHED: 'Select reply material to send to contacts/leads just before 24 hours of last messaging',
  TOOLTIP_KEYWORD_ACTION_TITLE: 'Manage bot\'s functionality according to keywords input by the bot user, in case of smart flow',
  TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Select bot\'s reply as template, flow, text or image against specific keywords input',
  TOOLTIP_ADD_KEYWORD: 'Add keywords',
  TOOLTIP_MATCHING_METHOD: 'Add keyword match as \'exact\' or \'contains\'',
  TOOLTIP_REPLY_MATERIAL: 'Select template, flow , text or image to reply back for the suggested keyword',
  TOOLTIP_KEYWORD_ACTIONS: 'Delete the keyword action',

  TOOLTIP_INPUT_TITLE: 'Create WhatsApp templates, images and text as reply material for contacts/leads',
  TOOLTIP_CREATE_REPLY_MATERIAL_HEADING: 'Create templates, images, and text',
  TOOLTIP_CREATE_REPLY_MATERIAL: 'Click to create a new reply material',
  // TOOLTIP_INPUT_TEMPLATES_TITLE: 'Create WhatsApp templates to send as reply material.',
  TOOLTIP_INPUT_TABLE_TITLE: 'Title of the whatsapp template',
  TOOLTIP_INPUT_TABLE_INFO: 'Detailed information of the business initiated messages',
  TOOLTIP_INPUT_TABLE_CATEGORY: 'Type of business initiated messages like appointments, reminders, customer care messages, shipping updates, alerts and more',
  TOOLTIP_INPUT_TABLE_STATUS: 'Template status is rejected or approved by Whatsapp. Whatsapp Templates have to be first approved by Whatsapp and this is a paid service.',
  TOOLTIP_INPUT_TABLE_ACTIONS: 'Delete the template',
  TOOLTIP_IMAGE_TITLE_HEADING: 'Create image to send as reply material',
  TOOLTIP_IMAGE_TABLE_TITLE: 'Title of the image',
  TOOLTIP_IMAGE_TABLE_IMAGE: 'Uploaded image',
  TOOLTIP_IMAGE_TABLE_ACTION: 'Delete the image',
  TOOLTIP_TEXT_HEADING: 'Create text to send as reply material',
  TOOLTIP_TEXT_TITLE: 'Title of the text message',
  TOOLTIP_TEXT_DESCRIPTION: 'Description of the text message',
  TOOLTIP_TEXT_ACTION: 'Delete the text',

  TOOLTIP_SELECT_REPLY_MATERIAL: 'Select to create template, image or text',
  TOOLTIP_SELECT_REPLY_MATERIAL_TITLE: 'Enter title of template being created',
  TOOLTIP_REPLY_MATERIAL_CATEGORY: 'Select category of template',
  TOOLTIP_REPLY_MATERIAL_HEADER: 'Select header as text ',
  TOOLTIP_REPLY_MATERIAL_HEADER_TEXT: 'Enter text for header',
  TOOLTIP_REPLY_MATERIAL_DESCRIPTION: 'Enter decsription of template',
  TOOLTIP_REPLY_MATERIAL_FOOTER: 'Create footer. Enter footer text',
  TOOLTIP_REPLAY_MATERIAL_BUTTON: 'Select button type as \'call to action\' or \'quick reply\'',
  TOOLTIP_REPLY_MATERIAL_ACTION: 'Select button action as \'website\' or \'call\'',
  TOOLTIP_REPLY_MATERIAL_TEXT: 'Text for website',
  TOOLTIP_REPLY_MATERIAL_URL: 'URL of website',
  TOOLTIP_REPLY_MATERIAL_TEXT_TITLE: 'Enter title of the text',
  TOOLTIP_REPLY_MATERIAL_TEXT_DESCRIPTION: 'Enter description for the text to be sent as reply',
  TOOLTIP_REPLY_MATERIAL_IMAGE_TITLE: 'Enter title of the image',
  TOOLTIP_REPLY_MATERIAL_UPLOAD_ICON: 'Upload the image from local storage',
};

export const notificationToolTipText = {
  TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Send Notifications of New Message Over Desktop',
  TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Send Notifications of New Message Over Mobile',
  TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Send Notifications of New Lead Over Desktop',
  TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Send Notifications of New Lead Over Mobile',
  TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Send Notifications of Live Chat  Over Desktop',
  TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Send Notifications of Live Chat Over Mobile',
  TOOLTIP_RECEIVED_NOTIFICATION: 'Choose Which Messages Do You Wish To Be Notified For'
};

export const configurationToolTipText = {
  TOOLTIP_RAZORPAY_ID: 'Enter your Razorpay Access Key ID. This key uniquely identifies your integration with Razorpay and is required for secure payment transactions.',
  TOOLTIP_RAZORPAY_SECRET_KEY: 'Enter your Razorpay Secret Access Key. This key is essential for secure communication between application and Razorpay, enabling seamless transaction processing and payment management.',
  TOOLTIP_STRIPE_SECRET_KEY: 'Enter your Stripe Secret Access Key. This key is used to securely authenticate your integration with Stripe and is necessary for processing customer payments.',
  TOOLTIP_PUBLIC_KEY: 'Enter your Stripe Public Key. This key is used to securely authenticate your integration with Stripe on client side and is necessary for processing customer payments.',
  TOOLTIP_AWS_REGION: 'Enter your AWS Region. The region determines the physical location of your resources and affects factors such as data latency and regulatory compliance.',
  TOOLTIP_AWS_ACCESS_KEY_ID: 'Enter your AWS Access Key ID. This key uniquely identifies your AWS account and is required for secure authentication and access to AWS services.',
  TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Enter your AWS Secret Access Key. This key is used for secure authentication and access to AWS services.',
  TOOLTIP_COMPANY_NAME: 'Enter your company name. This information will be used for identification and branding purposes in invoices.',
  TOOLTIP_COUNTRY: 'Select your Country. This information will be used for identification and branding purposes in invoices.',
  TOOLTIP_STATE: 'Enter your State. This information will be used for identification and branding purposes in invoices.',
  TOOLTIP_STREET_ADDRESS: 'Enter your Street Address. This information will be used for identification and branding purposes in invoices.',
  TOOLTIP_GSTIN: 'Enter your GSTIN. This information will be used for identification and branding purposes in invoices.',
  TOOLTIP_POSTAL_CODE: 'Enter your Postal Code. This information will be used for identification and branding purposes in invoices.',
};
