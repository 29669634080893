export const analytics = {
  botWise: {
    botWiseAnalytics: 'Bot-Weise Analytics',
    currentBillingCycle: 'Aktueller Abrechnungszyklus',
    currentMonth: 'Aktueller Monat',
    sinceAccountCreation: 'Seit Account -Schöpfung',
    messages: 'Mitteilungen',
    leads: 'Führt',
    noDataFound: {
      botwiseAnalytics: 'Bot-Weise Analytics',
      noBotHasBeenCreatedYet: 'Es wurde noch kein Bot erstellt!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Fassen Sie Daten aus Nachrichten und Kontakten/Leads für bestimmte Bots für den aktuellen Abrechnungszyklus, den aktuellen Monat oder seit der Kontoerstellung zusammen (werden Sie alle Mitternacht aktualisiert).',
      TOOLTIP_FILTERS: 'Filterdaten für ausgewählten Bot',
      TOOLTIP_MESSAGES: 'Gesamtnachrichten, die Ihr Konto seit der Kontoerstellung konsumiert haben',
      TOOLTIP_LEADS: 'Total Leads, die seit der Erstellung von Bots für Ihr Konto erfasst wurden.',
    }
  },
  overview: {
    analytics: 'Analyse',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    overview: 'Überblick',
    messages: 'Mitteilungen',
    leads: 'Führt',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Analytics für alle Ihre Bots -Nachrichten und Leads. (Daten alle Mitternacht aktualisiert)',
    }
  },
  summary: {
    analyticsSummary: 'Analytics Zusammenfassung',
    allBots: 'Alle Bots',
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    monthly: 'Monatlich',
    messages: 'Mitteilungen',
    leads: 'Führt',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Fassen Sie die täglichen, wöchentlichen oder monatlichen Daten aus Nachrichten und Kontakten/Leads für bestimmte und alle Bots zusammen (werden Sie alle Mitternacht aktualisiert)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp -Analyse',
      month: 'Monat',
      week: 'Woche',
      today: 'Heute',
      filters: 'Filter',
      clearAll: 'Alles löschen',
      apply: 'Anwenden',
      totalConversations: 'Gesamtgespräche',
      freeConversations: 'Kostenlose Gespräche',
      paidConversations: 'Bezahlte Gespräche',
      messageSent: 'Nachricht gesendet',
      messageDelivered: 'Nachricht zugestellt',
      bpDropDown: {
        selectBot: 'Wählen Sie Bot',
      },
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Endtermin',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Gesprächsanalyse',
      month: 'Monat',
      week: 'Woche',
      today: 'Heute',
      totalConversations: 'Gesamtgespräche',
      paidConversations: 'Bezahlte Gespräche',
      freeConversations: 'Kostenlose Gespräche',
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Endtermin',
      },
      bpDropDown: {
        allBots: 'Alle Bots',
      }
    },
    messageAnalytics: {
      whatsappMessages: 'WhatsApp -Nachrichten',
      sent: 'Gesendet',
      delivered: 'Geliefert',
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Endtermin',
      },
      bpDropDown: {
        allBots: 'Alle Bots',
      },
      month: 'Monat',
      week: 'Woche',
      today: 'Heute',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'WhatsApp -Nachrichten nach Status',
      sent: 'Gesendet',
      delivered: 'Geliefert',
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Endtermin',
      },
      bpDropDown: {
        allBots: 'Alle Bots',
      },
      month: 'Monat',
      week: 'Woche',
      today: 'Heute',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Alle Abonnenten',
      whatsappSubscribers: 'WhatsApp -Abonnenten',
    }
  }
};
