export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'एक सक्रियण लिंक भेजा गया है, कृपया अपना ईमेल सत्यापित करें',
    weHaveSentActivationLinkToEmailAddress: 'हमने ईमेल पते पर एक सक्रियण लिंक भेजा है',
    toVerifyYourAccount: 'अपने खाते को सत्यापित करने के लिए',
    onceVerifiedAutomaticallyRedirected: 'जब सत्यापित हो जाएगा, तो आप स्वचालित रूप से पैनल पर पुनर्निर्देशित किए जाएंगे।',
    didNotReceiveAnEmail: 'एक ईमेल नहीं मिला? अपना स्पैम फोल्डर देखें',
    resendIn: 'बचाव करना',
    seconds: ' सेकंड',
    resend: 'पुन: भेजें',
    backToSignUp: 'वापस साइन अप करने के लिए',
    greatPleaseCheckYourMessage: 'महान, कृपया अपना ईमेल देखें',
    weHaveSentEmailTo: 'हमने एक ईमेल भेजा है',
    withALinkToReset: ' अपने खाते को रीसेट करने के लिए एक लिंक के साथ। एक बार सक्रिय होने के बाद आप अपने खाते की रचना को अंतिम रूप दे सकते हैं और अपना निःशुल्क परीक्षण शुरू कर सकते हैं।',
    resent: 'पुन: भेज',
    backToLogin: 'लॉगिन पर वापस जाएं'
  },
  forgotPassword: {
    forgotPassword: 'पासवर्ड भूल गए?',
    noWorries: 'चिंता न करें,',
    weWillSendYouResetPasswordInstructions: 'हम आपको पासवर्ड निर्देश भेजेंगे',
    workEmail: 'कार्य ईमेल',
    continue: 'जारी रखना',
    backToLogin: 'लॉगिन पर वापस जाएं',
    placeholders: {
      enterYourEmail: 'अपना ईमेल दर्ज करें'
    }
  },
  logout: {
    logout: 'लॉग आउट',
    wantToLogOut: 'क्या आप लॉग आउट करना चाहते हैं?',
    yesIWillBeBack: 'हां, मैं वापस आऊंगा'
  },
  newPassword: {
    createNewPassword: 'नया पासवर्ड बनाएं',
    yourNewPasswordMustBeDifferent: 'आपका नया पासवर्ड पहले से उपयोग किए गए पासवर्ड से अलग होना चाहिए।',
    password: 'पासवर्ड',
    confirmPassword: 'पासवर्ड की पुष्टि कीजिये',
    setPassword: 'सांकेतिक शब्द लगना',
    signUp: 'साइन अप करें',
    login: 'लॉग इन करें',
    backTo: 'वापस',
    setAPassword: 'एक पासवर्ड सेट करें',
    setNewPasswordToYourAccount: 'सुरक्षा और गोपनीयता उद्देश्य के लिए अपने खाते में एक नया पासवर्ड सेट करें',
    backToSignUp: 'वापस साइन अप करने के लिए',
    placeholders: {
      enterYourPassword: 'अपना कूटशब्द भरें'
    }
  },
  resetPassword: {
    passwordReset: 'पासवर्ड रीसेट',
    yourPasswordHasBeenSuccessfullyReset: 'आपका पासवर्ड सफलतापूर्वक रीसेट कर दिया गया है।',
    clickBelowToLogIn: 'लॉग इन करने के लिए नीचे क्लिक करें',
    continueToLogin: 'लॉगिन करना जारी रखें'
  },
  newSignUp: {
    welcomeBack: 'वापसी पर स्वागत है',
    // to: 'को',
    to: '',
    logIn: 'लॉग इन करें',
    as: 'जैसा',
    withFacebook: ' फेसबुक के साथ',
    logInWithGoogle: 'गूगल के साथ लॉग इन करें',
    or: 'या',
    pleaseFillAValidWorkEmail: 'कृपया एक मान्य कार्य ईमेल भरें*',
    workEmailIsRequired: 'कार्य ईमेल की आवश्यकता है*',
    pleaseFillAValidPassword: 'कृपया एक वैध पासवर्ड भरें*',
    passwordIsRequired: 'पासवर्ड की आवश्यकता है*',
    rememberMe: 'याद करना',
    forgotPassword: 'पासवर्ड भूल गए?',
    login: 'लॉग इन करें',
    doNotHaveAnAccount: 'कोई खाता नहीं है?',
    setUpAFreeAccount: 'एक मुफ्त खाता सेट करें',
    noCreditCardRequired: 'किसी क्रेडिट कार्ड की आवश्यकता नहीं',
    cancelAnytime: 'कभी भी रद्द करें',
    signUp: 'साइन अप करें',
    signUpWithGoogle: 'गूगल के साथ साइन अप करें',
    emailIsAlreadyRegistered: 'ईमेल पहले से ही हमारे साथ पंजीकृत है, कृपया जारी रखने के लिए लॉग इन पर क्लिक करें',
    phoneNumberShouldHaveMinimum7Digits: 'फोन नंबर में न्यूनतम 7 अंक होना चाहिए*',
    phoneNumberIsRequired: 'फोन नंबर की आवश्यकता है*',
    passwordMustContain: 'पासवर्ड में अवश्य होना चाहिए',
    atleast6Characters: 'कम से कम 6 वर्ण,',
    oneUpperCaseLetter: '1 बड़ा अक्षर',
    oneSpecialCharacter: '1 विशेष चरित्र*',
    alreadyHaveAccount: 'क्या आपके पास पहले से एक खाता मौजूद है?',
    signingUpToBotPenguinIsAConsentToOur: 'बोटेंगुइन खाते के लिए साइन अप करना हमारी सहमति है',
    privacyPolicy: 'गोपनीयता नीति',
    termsAndConditions: ' नियम और शर्तें',
    and: ' और',
    english: 'अंग्रेज़ी',
    hindi: 'हिंदी',
    german: 'जर्मन',
    french: 'फ्रांसीसी',
    portuguese: 'पुर्तगाली',
    spanish: 'स्पैनिश',
    italian: 'इतालवी',
    russian: 'रूसी',
    placeholders: {
      workEmail: 'कार्य ईमेल*',
      password: 'पासवर्ड*',
      phone: 'फ़ोन*'
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'सफलतापूर्वक अनचाहे',
    yourEmail: 'आपका ईमेल ',
    haveBeenSuccessfullyUnsubscribedFrom: ' से सफलतापूर्वक अनचाहे हो गए हैं',
    backToLogin: 'लॉगिन पर वापस जाएं'
  }

};
