export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'È stato inviato un link di attivazione, verifica la tua email',
    weHaveSentActivationLinkToEmailAddress: `Abbiamo inviato un link di attivazione all'indirizzo e -mail`,
    toVerifyYourAccount: 'Per verificare il tuo account.',
    onceVerifiedAutomaticallyRedirected: `Una volta verificato, verrai automaticamente reindirizzato al pannello.`,
    didNotReceiveAnEmail: `Non hai ricevuto un'e -mail? controlla la tua cartella spam`,
    resendIn: 'Resendendo in',
    seconds: ' Secondi',
    resend: 'Resendendo',
    backToSignUp: 'Torna a registrarsi',
    greatPleaseCheckYourMessage: 'Fantastico, per favore controlla la tua email',
    weHaveSentEmailTo: `Abbiamo inviato un'e -mail a`,
    withALinkToReset: ' con un collegamento per ripristinare il tuo account. Una volta attivato, sarai in grado di finalizzare la creazione del tuo account e iniziare la tua prova gratuita.',
    resent: 'Risentirsi di',
    backToLogin: 'Torna al login',
  },
  forgotPassword: {
    forgotPassword: 'Ha dimenticato la password?',
    noWorries: 'Nessun problema,',
    weWillSendYouResetPasswordInstructions: `Ti invieremo l'istruzione per reimpostare la password`,
    workEmail: 'Email di lavoro',
    continue: 'Continua',
    backToLogin: 'Torna al login',
    placeholders: {
      enterYourEmail: 'Inserisci il tuo indirizzo email',
    }
  },
  logout: {
    logout: 'Disconnettersi',
    wantToLogOut: 'Sei sicuro di voler uscire?',
    yesIWillBeBack: 'Sì, tornerò',
    cancel: 'Annulla',
  },
  newPassword: {
    createNewPassword: 'Crea nuova password',
    yourNewPasswordMustBeDifferent: 'La tua nuova password deve essere diversa dalle password precedentemente utilizzate.',
    password: `Parola d'ordine`,
    confirmPassword: 'Conferma password',
    setPassword: 'Impostare la password',
    signUp: 'Iscrizione',
    login: 'Login',
    backTo: 'Torna a',
    setAPassword: 'Imposta una password',
    setNewPasswordToYourAccount: 'Imposta una nuova password sul tuo account per la sicurezza e lo scopo della privacy',
    backToSignUp: 'Torna a registrarsi',
    placeholders: {
      enterYourPassword: 'Inserisci la tua password',
    }
  },
  resetPassword: {
    passwordReset: 'Reimpostazione della password',
    yourPasswordHasBeenSuccessfullyReset: 'La tua password è stata ripristinata correttamente.',
    clickBelowToLogIn: 'Fai clic di seguito per accedere',
    continueToLogin: 'Continua ad accedere',
  },
  newSignUp: {
    welcomeBack: 'Bentornato',
    to: 'A',
    logIn: 'Login',
    as: 'COME',
    withFacebook: 'con Facebook',
    logInWithGoogle: 'Accedi con Google',
    or: 'O',
    pleaseFillAValidWorkEmail: `Si prega di compilare un'e -mail di lavoro valida*`,
    workEmailIsRequired: `È richiesta un'e -mail di lavoro*`,
    pleaseFillAValidPassword: 'Si prega di riempire una password valida*',
    passwordIsRequired: `E 'richiesta la password*`,
    rememberMe: 'Ricordati di me',
    forgotPassword: 'Ha dimenticato la password?',
    login: 'Login',
    doNotHaveAnAccount: 'Non hai un account?',
    setUpAFreeAccount: 'Impostare un account gratuito',
    noCreditCardRequired: 'Nessuna carta di credito richiesta',
    cancelAnytime: 'Annulla in qualsiasi momento',
    signUp: 'Iscrizione',
    signUpWithGoogle: 'Iscriviti a Google',
    emailIsAlreadyRegistered: `L'e -mail è già registrata con noi, fare clic su Accedi per continuare`,
    phoneNumberShouldHaveMinimum7Digits: 'Il numero di telefono dovrebbe avere almeno 7 cifre*',
    phoneNumberIsRequired: 'Il numero di telefono è richiesto*',
    passwordMustContain: 'La password deve contenere',
    atleast6Characters: 'almeno 6 caratteri,',
    oneUpperCaseLetter: '1 lettera maiuscola',
    oneSpecialCharacter: '1 carattere speciale*',
    alreadyHaveAccount: 'Hai già un account?',
    signingUpToBotPenguinIsAConsentToOur: 'Iscriviti per un account BotPenguin è il tuo consenso al nostro',
    privacyPolicy: 'politica sulla riservatezza',
    termsAndConditions: 'Termini e Condizioni',
    and: ' E',
    english: 'Inglese',
    hindi: 'hindi',
    german: 'Tedesco',
    french: 'francese',
    portuguese: 'Portoghese',
    spanish: 'spagnolo',
    italian: 'Italiano',
    russian: 'russo',
    placeholders: {
      workEmail: 'Email di lavoro*',
      password: `Parola d'ordine*`,
      phone: 'Telefono*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Disiscrizione avvenuta con successo',
    yourEmail: 'La tua email ',
    haveBeenSuccessfullyUnsubscribedFrom: ' è stata disiscritta con successo da',
    backToLogin: 'Torna al Login'
  }
};
