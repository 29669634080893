export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'アクティベーションリンクが送信されました。メールを確認してください。',
    weHaveSentActivationLinkToEmailAddress: 'アクティベーションリンクを以下のメールアドレスに送信しました。',
    toVerifyYourAccount: 'アカウントを確認するために。',
    onceVerifiedAutomaticallyRedirected: '確認が完了したら、自動的にパネルにリダイレクトされます。',
    didNotReceiveAnEmail: 'メールを受け取りませんでしたか？スパムフォルダを確認してください。',
    resendIn: '再送信まで ',
    seconds: ' 秒',
    resend: '再送信',
    backToSignUp: 'サインアップに戻る',
    greatPleaseCheckYourMessage: '素晴らしい、メールを確認してください。',
    weHaveSentEmailTo: '以下のメールアドレスにメールを送信しました。',
    withALinkToReset: ' リンクを含む。アクティベーション後、アカウントの作成を完了し、無料トライアルを開始できます。',
    resent: '再送',
    backToLogin: 'ログインに戻る',
  },
  forgotPassword: {
    forgotPassword: 'パスワードをお忘れですか？',
    noWorries: '心配しないで、',
    weWillSendYouResetPasswordInstructions: 'パスワードリセットの手順をお送りします',
    workEmail: '業務用メール',
    continue: '続ける',
    backToLogin: 'ログインに戻る',
    placeholders: {
      enterYourEmail: 'メールアドレスを入力',
    }
  },
  logout: {
    logout: 'ログアウト',
    wantToLogOut: '本当にログアウトしますか？',
    yesIWillBeBack: 'はい、戻ります',
    cancel: 'キャンセル',
  },
  newPassword: {
    createNewPassword: '新しいパスワードを作成',
    yourNewPasswordMustBeDifferent: '新しいパスワードは以前に使用したパスワードと異なる必要があります。',
    password: 'パスワード',
    confirmPassword: 'パスワードを確認',
    setPassword: 'パスワードを設定',
    signUp: 'サインアップ',
    login: 'ログイン',
    backTo: '戻る',
    setAPassword: 'パスワードを設定',
    setNewPasswordToYourAccount: 'セキュリティとプライバシーの目的でアカウントに新しいパスワードを設定します',
    backToSignUp: 'サインアップに戻る',
    placeholders: {
      enterYourPassword: 'パスワードを入力',
    }
  },
  resetPassword: {
    passwordReset: 'パスワードリセット',
    yourPasswordHasBeenSuccessfullyReset: 'パスワードが正常にリセットされました。',
    clickBelowToLogIn: '以下をクリックしてログイン',
    continueToLogin: 'ログインを続行',
  },
  newSignUp: {
    signUpWithGoogle: 'Googleでサインアップ',
    welcomeBack: 'おかえりなさい ',
    to: 'へ',
    logIn: 'ログイン ',
    as: 'として ',
    withFacebook: 'Facebookでログイン',
    logInWithGoogle: 'Googleでログイン',
    or: 'または',
    pleaseFillAValidWorkEmail: '有効な業務用メールアドレスを入力してください*',
    workEmailIsRequired: '業務用メールは必須です*',
    pleaseFillAValidPassword: '有効なパスワードを入力してください*',
    passwordIsRequired: 'パスワードは必須です*',
    rememberMe: 'ログイン情報を記憶する',
    forgotPassword: 'パスワードをお忘れですか？',
    login: 'ログイン',
    doNotHaveAnAccount: 'アカウントをお持ちでないですか？',
    setUpAFreeAccount: '無料アカウントを設定',
    noCreditCardRequired: 'クレジットカードは必要ありません',
    cancelAnytime: 'いつでもキャンセルできます',
    signUp: 'サインアップ ',
    emailIsAlreadyRegistered: 'このメールアドレスはすでに登録されています。続行するにはログインをクリックしてください。',
    phoneNumberShouldHaveMinimum7Digits: '電話番号は最低7桁必要です*',
    phoneNumberIsRequired: '電話番号は必須です*',
    passwordMustContain: 'パスワードは含まれる必要があります',
    atleast6Characters: '少なくとも6文字、',
    oneUpperCaseLetter: '1つの大文字の文字、',
    oneSpecialCharacter: '1つの特殊文字*',
    alreadyHaveAccount: 'すでにアカウントをお持ちですか？',
    signingUpToBotPenguinIsAConsentToOur: 'BotPenguinのアカウントにサインアップすることは、弊社の',
    privacyPolicy: 'プライバシーポリシー',
    termsAndConditions: '利用規約',
    and: ' と ',
    english: '英語',
    hindi: 'ヒンディー語',
    german: 'ドイツ語',
    french: 'フランス語',
    portuguese: 'ポルトガル語',
    spanish: 'スペイン語',
    italian: 'イタリア語',
    russian: 'ロシア語',
    placeholders: {
      workEmail: '業務用メール*',
      password: 'パスワード*',
      phone: '電話番号*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: '正常に登録解除されました',
    yourEmail: 'あなたのメール ',
    haveBeenSuccessfullyUnsubscribedFrom: ' が正常に登録解除されました',
    backToLogin: 'ログインに戻る'
  }
};
