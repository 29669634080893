const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Mi cuenta',
  yourProfileAndPasswordSecurity: 'Su perfil y seguridad de contraseña',
  detailsHeader: 'Detalles',
  passwordHeader: 'Contraseña',
  customAttributeHeader: 'Atributos Personalizados',
  tagsHeader: 'Etiquetas',
  accessTokenHeader: 'Token de acceso',
  thirdPartyIntegrationsHeader: 'Integraciones de terceros',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Actualice el perfil de su cuenta',
    TOOLTIP_PASSWORD_HEADING: 'cambia tu contraseña',
    TOOLTIP_TAGS_HEADING: 'Administre sus etiquetas',
    TOOLTIP_THIRD_PARTY_HEADING: 'Integraciones de terceros',
    TOOLTIP_CHANGE_PASSWORD_TITLE: 'Una contraseña segura ayuda a proteger su cuenta de $ {marca}',
    TOOLTIP_INTEGRATIONS: 'Integrar',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Agregar o actualizar atributos personalizados',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Administre sus atributos personalizados'
  },
  accessToken: {
    accessToken: 'Token de acceso',
    generateYourAccessTokenForUsingPlatformAPIs: 'Genere su token de acceso para usar API de plataforma',
    generateApiToken: 'Generar token API',
    revokeApiToken: 'Revocar el token API',
    regenerateApiToken: 'Regenerar token API',
    placeholders: {
      yourApiKey: 'Tu clave API',
    }
  },
  details: {
    name: 'Nombre',
    emailId: 'Identificación de correo',
    yourPlan: 'Tu plan',
    messagesPerMonth: ' mensajes/mes',
    daysOfStoragePlan: ' Plan de días de almacenamiento',
    conditionalFeatures: 'características condicionales',
    upgradeToEnterprisePlanToGetMore: 'Actualizar al plan empresarial para obtener más',
    featuresAndStorageCapacity: 'Característica y capacidad de almacenamiento',
    upgradePlan: 'Plan de actualización',
    submit: 'Entregar',
    selectLanguage: 'Seleccione el idioma',
    baby: 'Bebé',
    king: 'Rey',
    conversationsperMonth: 'coberturas/mes',
    english: 'Inglés',
    hindi: 'hindi',
    german: 'Alemán',
    french: 'Francés',
    portuguese: 'Portugués',
    spanish: 'Español',
    italian: 'italiano',
    russian: 'ruso',
    chatBotBrandName: 'Nombre de la marca de chatbot',
    placeholders: {
      john: 'John',
      email: 'Correo electrónico',
      searchLanguage: 'Lenguaje de búsqueda',
      chatBotBrandName: 'Nombre de la marca de chatbot',
    },
    appTooltip: {
      TOOLTIP_NAME: 'Ingrese el nombre de su perfil',
      TOOLTIP_EMAIL_ADDRESS: 'Ingrese su dirección de correo electrónico',
    }
  },
  customAttribute: {
    addAttribute: 'Agregar Atributo',
    discard: 'Descartar',
    customAttribute: 'Atributos Personalizados',
    checkOrAddCustomAttribute: 'Aquí puedes verificar y agregar tus atributos personalizados.',
    attributeKey: 'Clave del Atributo',
    attributeName: 'Nombre del Atributo',
    bot: 'Bot',
    flow: 'Flujo',
    loadMore: 'Cargar Más',
    question: 'Pregunta',
    action: 'Acción',
    search: 'Buscar',
    selectBot: 'Seleccionar Bot',
    selectFlow: 'Seleccionar Flujo',
    selectQuestion: 'Seleccionar Pregunta',
    noDataFoundHeading: 'Sie haben noch keine Eigenschaft erstellt',
    noDataFoundSubheading: 'Alle Ihre Ehrungen werden hier angezeigt',
    deleteTitle: 'Eliminar Atributo',
    deleteDescription: '¿Está seguro de que desea eliminar este Atributo?',
    deleteAttributes: 'Eliminar Atributos',
    areYouSureYouWantToDeleteThisAttributes: '¿Está seguro de que desea eliminar estos atributos?',
    editAttribute: 'Editar Atributo',
    headsUp: 'Aviso',
    okay: 'Bueno',
    dataAddedToLead: 'Los atributos asignados a sus APIs o flujos de bot no se actualizarán. Por favor, actualice las APIs o flujos de bot en consecuencia.',
    placeholders: {
      enterAttributeKey: 'Ingrese la Clave del Atributo',
      enterAttributeName: 'Ingrese el Nombre del Atributo',
      selectBot: 'Seleccionar Bot',
      selectFlow: 'Seleccionar Flujo',
      selectQuestion: 'Seleccionar Pregunta',
      search: 'Buscar por Nombre de Atributo'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Ingrese la Clave del Atributo',
      TOOLTIP_ATTRIBUTE_NAME: 'Ingrese el Nombre del Atributo',
      TOOLTIP_SELECT_BOT: 'Seleccionar Bot',
      TOOLTIP_SELECT_FLOW: 'Seleccionar Flujo',
      TOOLTIP_SELECT_QUESTION: 'Seleccionar Pregunta',
    }
  },
  password: {
    changePassword: 'Cambiar la contraseña',
    aSecurePasswordHelpsProtectYour: 'Una contraseña segura ayuda a proteger su',
    account: ' Cuenta',
    currentPassword: 'Contraseña actual',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'confirmar Contraseña',
    cancel: 'Cancelar',
    updatePassword: 'Actualiza contraseña',
    deleteAccount: 'Borrar cuenta',
    placeholders: {
      enterYourCurrentPassword: 'Introduce tu contraseña actual',
      enterYourNewPassword: 'Introduzca su nueva contraseña',
      enterYourConfirmPassword: 'Ingrese su contraseña de confirmación',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Ingrese la contraseña antigua',
      TOOLTIP_NEW_PASSWORD: 'Ingrese nueva clave',
      TOOLTIP_CONFIRM_PASSWORD: 'Confirma tu nueva contraseña',
      TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Gestionar etiquetas',
    createNewTags: 'Crear nuevas etiquetas',
    createNewTagsSubText: 'Administre sus etiquetas aquí para organizar mejor sus clientes potenciales, chats, grupos, etc.',
    tagName: 'Nombre de la etiqueta',
    updateTag: 'Actualizar etiqueta',
    createTag: 'Crear etiqueta',
    discard: 'Descartar',
    searchTag: 'Buscar etiqueta por nombre',
    noTagFound: '¡No se encontró ninguna etiqueta!',
    createATag: 'Crear una etiqueta',
    deleteTagModalSubText: '¿Está seguro de que desea eliminar esta etiqueta? ¡Eliminar esta etiqueta también la quitará de los usuarios asignados!',
    deleteTagModalHeading: 'Eliminar etiquetas',
    deleteOneTagModalHeading: 'Eliminar etiqueta',
    actions: 'Acciones',
    placeholders: {
        enterTag: 'Ingrese el nombre de la etiqueta',
    },
    appTooltip: {
        TOOLTIP_DELETE_TAGS: 'Eliminar etiquetas',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'Nombre',
      status: 'Estado',
      action: 'Acción',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird para enviar mensajes y correos electrónicos',
      notConnected: 'No conectado',
      connected: 'Conectado',
      edit: 'Editar',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo para enviar mensajes',
      connect: 'Conectar',
      appTooltip: {
        nameOfAppOrCrm: 'Nombre de la aplicación/CRM',
        statusAsConnectedOrNotConnected: 'Estado conectado / no conectado',
        clickToConnectAppOrCrm: 'Haga clic para conectar la aplicación/CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: '¡Ups! Las integraciones no están disponibles en el plan para bebés. Actualice a King Plan para integraciones.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'Integrar con MessageBird',
        apiKey: 'Clave API',
        originator: 'Autor',
        connect: 'Conectar',
        disconnect: 'Desconectar',
        seeActivities: 'Ver actividades',
        sms: 'SMS',
        time: 'Tiempo',
        phoneNumber: 'Número de teléfono',
        status: 'Estado',
        botName: 'Nombre de bot',
        message: 'Mensaje',
        error: 'Error',
        placeholders: {
          apiKey: 'Clave API',
          messageBirdPhoneNumber: 'Número de teléfono de MessageBird',
        },
        noDataFound: {
          activities: 'Actividades',
          noActivitiesFound: '¡No se encontraron actividades!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Integrarse con Plivo',
        authId: 'Auth ID',
        authToken: 'Token de autenticación',
        yourPlivoPhoneNumber: 'Tu número de teléfono Plivo',
        connect: 'Conectar',
        disconnect: 'Desconectar',
        seeActivities: 'Ver actividades',
        sms: 'SMS',
        time: 'Tiempo',
        phoneNumber: 'Número de teléfono',
        status: 'Estado',
        botName: 'Nombre de bot',
        message: 'Mensaje',
        error: 'Error',
        noDataFound: {
          activities: 'Actividades',
          noActivitiesFound: '¡No se encontraron actividades!',
        },
        placeholders: {
          authId: 'Auth ID',
          authToken: 'Token de autenticación',
          yourPlivoPhoneNumber: 'Tu número de teléfono Plivo',
        }
      }
    }
  }
};
