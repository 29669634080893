import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss']
})
export class ToastrComponent implements OnInit, OnDestroy {
  messageStack = [];

  toastrSubscription$: Subscription;
  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.showToastr();
  }

  showToastr() {
    this.toastrSubscription$ = this.sharedService.toast.subscribe((result) => {
      const uid = uuid();

      setTimeout(() => {
        this.closeToastr(uid);
      }, 5000);

      if (!this.messageStack.find(message =>
        message.description === result.message &&
        message.type === result.type &&
        message.title === result.title)) {
        this.messageStack.unshift({ type: result.type, description: result.message, title: result.title, uid });
      }
    });
  }

  closeToastr(uid: string) {
    this.messageStack = this.messageStack.filter(message => message.uid !== uid);
  }

  ngOnDestroy(): void {
    this.toastrSubscription$.unsubscribe();
  }
}
