export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Welkom bij BotPenguin!',
      login: 'Inloggen',
      youAreAllSet: 'Je bent helemaal klaar! Je kunt de WhatsApp-inbox gebruiken vanaf de leadgegevenspagina.',
      placeholders: {
        enterEmail: 'Voer e-mailadres in',
        enterPassword: 'Voer wachtwoord in'
      }
    }
  }
};
