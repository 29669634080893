export const shared = {
  bpDropDown: {
    placeholders: {
      search: '検索'
    }
  },
  bpTable: {
    loadMore: 'もっと読む',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: '国を検索'
    }
  },
  createNew: {
    continue: '続ける',
  },
  datePicker: {
    chooseADate: '日付を選択',
  },
  dateRangePicker: {
    choosePeriod: '期間を選択',
    today: '今日',
    lastWeek: '先週',
    lastMonth: '先月'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'テストに使用する電話番号を提供してください',
    resendOtp: 'OTPを再送信',
    enterOtp: 'OTPを入力',
    verifyOtp: 'OTPを検証',
    sendOtp: 'OTPを送信',
    cancel: 'キャンセル',
    placeholders: {
      phone: '電話*',
      enterOtp: 'OTPを入力*',
    }
  },
  feedback: {
    pleaseSelectYourReason: '理由を選択してください',
    cancel: 'キャンセル',
    submit: '送信！',
    giveFeedback: 'フィードバックを提供',
    ratings: '評価',
    reasonBehindYourDecision: '決定の背後にある理由',
    shareYourExperience: 'あなたの経験を共有',
    reason1: '理由1',
    reason2: '理由2'
  },
  feedbackModal: {
    paymentIsInProgress: '支払いが進行中です',
    pleaseDoNotCloseThisWindow: 'このウィンドウを閉じないでください',
    placeholders: {
      name: '名前',
      email: 'メール*',
      phone: '電話*',
      pleaseShareYourUseCase: 'ご利用のユースケースを共有してください'
    }
  },
  layout: {
    sidebar: {
      notifications: '通知',
      myAccount: 'マイアカウント',
      logout: 'ログアウト',
      goOnline: 'オンラインにする',
      manage: '管理',
      deactivate: '無効化',
      activate: '有効化',
      delete: '削除',
      home: 'ホーム',
      bots: 'ボット',
      inbox: '受信トレイ',
      analytics: '分析',
      team: 'チーム',
      subscriptions: 'サブスクリプション',
      more: 'その他',
      users: 'ユーザー',
      partnerTeam: 'パートナーチーム',
      createNewBot: '新しいボットを作成',
      dashboard: 'ダッシュボード',
      customers: '顧客',
      plans: 'プラン',
      activities: 'アクティビティ',
      feedbacks: 'フィードバック',
      configurations: '設定',
      copy: 'コピー',
      developer: '開発者',
      yourFriendWillMissYou: '友達はあなたを恋しがります',
      rateYourBotCreationExperience: 'ボット作成の体験を評価してください',
      whatMadeYouDeleteYourBot: 'ボットを削除した理由は何ですか？',
      iCreatedByMistake: '誤って作成しました',
      iWantToCreateAnotherBot: '別のボットを作成したい',
      couldNotFindFeaturesThatINeeded: '必要な機能が見つかりませんでした',
      uiWasHardToNavigate: 'UIが操作しにくかった',
      iDonotWantToTell: '教えたくない',
      other: 'その他',
      weWillDoOurBestToMakeAutomationFun: '自動化を楽しく簡単にするために最善を尽くします。',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'ライブチャットリクエストを許可またはブロックするには切り替えます',
        TOOLTIP_NOTIFICATION: 'すべての通知',
        TOOLTIP_MY_ACCOUNT: 'プロフィールとパスワードセキュリティ',
        TOOLTIP_LOGOUT: 'ダッシュボードからログアウト'
      },
      modal: {
        logout: 'ログアウト',
        botDeleteFeedbackForm: 'ボット削除フィードバックフォーム',
        areYouSureToLogOut: '本当にログアウトしますか？',
        yesIWillBeBack: 'はい、戻ります',
        deleteBot: 'ボットを削除',
        areYouSureToDeleteBot: 'このボットを削除しますか？',
        cancel: 'キャンセル',
        saveAndDelete: '保存して削除'
      }
    }
  },
  modal: {
    cancel: 'キャンセル',
    yesDelete: 'はい、削除！',
    continue: '続行'
  },
  unsavedChanges: {
    title: '保存されていない変更',
    description: '保存されていない変更があるかもしれません。本当に出ますか？'
  },
  notify: {
    youHave: '新しい',
    new: '新しい'
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: '会社のロゴを選択することをお勧めします。1MB未満のPNGまたはJPGの正方形の画像を追加してください。'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'おっと！ベビープランでは1つの無料ボットのみが提供されています。もっと多くのボットを作成するには、キングプランにアップグレードしてください。',
    noIAmGood: 'いいえ、大丈夫です',
    iWantToUpgrade: 'アップグレードしたい',
    oops: 'おっと！ ',
    planOfferOnlyOneFreeBot: ' は、1つの無料ボットのみを提供しています。もっと多くのボットを作成するには、',
    planToCreateMoreBots: ' にアップグレードしてください。'
  },
  webPreview: {
    preview: ' プレビュー',
    botsMessages: 'ボットのメッセージ',
    bot: 'ボット ',
    eleven36AM: ' 11:36 AM',
    usersReply: 'ユーザーの返信',
    landingPageBot: 'ランディングページボット',
    chatbot: 'チャットボット',
    chat: 'チャット',
    placeholders: {
      typeYourAnswer: '回答を入力'
    }
  },
  paymentLink: {
    discardPayment: '支払いを破棄',
    completePayment: '支払いを完了',
    importantUpdate: '重要な更新',
    accountUpdate: 'アカウントの更新',
    youHave: 'あなたは',
    exceededTheMessagesLimit: 'メッセージ制限を超えました',
    ofYour: 'あなたの',
    planAnd: 'プランと',
    botsAreNoLongerActive: 'ボットはもうアクティブではありません',
    onYourWebsite: 'あなたのウェブサイト上で。',
    youHaveEitherIncompletePaymentOn: 'あなたは、',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'またはプランを正常にアップグレードしていない可能性があります。',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'またはボットを正常にホワイトラベル化していない可能性があります。',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'または「メッセージアドオン」を正常に購入していない可能性があります。',
    incompletePaymentMessage: 'あなたは、',
    please: 'お願いします',
    complete: '完了',
    upgrade: 'アップグレード',
    services: 'サービス',
    yourPlanToContinueServices: 'サービスを続けるためにプランをアップグレードしてください',
    yourPaymentToContinue: '継続するための支払い',
    forAnyQueryPleaseWriteUs: 'ご質問がある場合は、',
    close: '閉じる',
    writeOn: '',
    upgradePlan: 'プランをアップグレード',
    discardModal: {
      title: '支払いを破棄',
      description: '支払いを破棄してもよろしいですか？',
      confirmText: 'はい、支払いを破棄'
    }
  },
  bpExport: {
    exportStatus: 'エクスポートステータス',
    tasksExport: 'タスクエクスポート',
    download: 'ダウンロード',
    inProgress: '進行中',
  }
};
