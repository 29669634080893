export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'הודעת ההפעלה נשלחה, אנא אמת את האימייל שלך',
    weHaveSentActivationLinkToEmailAddress: 'שלחנו קישור הפעלה לכתובת האימייל',
    toVerifyYourAccount: 'לאימות החשבון שלך.',
    onceVerifiedAutomaticallyRedirected: 'פעם שהוא מועמד, תופנה אוטומטית לפאנל.',
    didNotReceiveAnEmail: 'לא קיבלת אימייל? בדוק בתיקיית הזבל',
    resendIn: 'שלח מחדש בעוד ',
    seconds: ' שניות',
    resend: 'שלח מחדש',
    backToSignUp: 'חזור להרשמה',
    greatPleaseCheckYourMessage: 'גרנד, אנא בדוק את האימייל שלך',
    weHaveSentEmailTo: 'שלחנו אימייל אל',
    withALinkToReset: 'עם קישור לאיפוס החשבון. לאחר ההפעלה, תוכל לסיים את יצירת החשבון שלך ולהתחיל את הניסיון החינמי שלך.',
    resent: 'נשלח מחדש',
    backToLogin: 'חזור להתחברות',
  },
  forgotPassword: {
    forgotPassword: 'שכחתי סיסמה?',
    noWorries: 'אין בעיה,',
    weWillSendYouResetPasswordInstructions: 'נשלח לך הוראות לאיפוס הסיסמה',
    workEmail: 'אימייל עסקי',
    continue: 'המשך',
    backToLogin: 'חזור להתחברות',
    placeholders: {
      enterYourEmail: 'הזן את האימייל שלך',
    }
  },
  logout: {
    logout: 'התנתק',
    wantToLogOut: 'האם אתה בטוח שברצונך להתנתק?',
    yesIWillBeBack: 'כן, אחזור בקרוב',
    cancel: 'ביטול',
  },
  newPassword: {
    createNewPassword: 'צור סיסמה חדשה',
    yourNewPasswordMustBeDifferent: 'הסיסמה החדשה שלך חייבת להיות שונה מהסיסמאות ששומשו בעבר.',
    password: 'סיסמה',
    confirmPassword: 'אשר סיסמה',
    setPassword: 'הגדר סיסמה',
    signUp: 'הרשמה',
    login: 'התחברות',
    backTo: 'חזור אל ',
    setAPassword: 'הגדר סיסמה לחשבונך לצורך אבטחה ופרטיות',
    backToSignUp: 'חזור להרשמה',
    placeholders: {
      enterYourPassword: 'הזן את הסיסמה שלך',
    }
  },
  resetPassword: {
    passwordReset: 'איפוס סיסמה',
    yourPasswordHasBeenSuccessfullyReset: 'הסיסמה שלך אופסה בהצלחה.',
    clickBelowToLogIn: 'לחץ למטה להתחברות',
    continueToLogin: 'המשך להתחברות',
  },
  newSignUp: {
    welcomeBack: 'ברוך הבא חזרת ',
    to: 'אל ',
    logIn: 'התחברות ',
    as: 'כ',
    withFacebook: 'עם Facebook',
    logInWithGoogle: 'התחברות עם Google',
    or: 'או',
    pleaseFillAValidWorkEmail: 'אנא הזן אימייל עסקי חוקי*',
    workEmailIsRequired: 'שדה האימייל הוא שדה חובה*',
    pleaseFillAValidPassword: 'אנא הזן סיסמה חוקית*',
    passwordIsRequired: 'שדה הסיסמה הוא שדה חובה*',
    rememberMe: 'זכור אותי',
    forgotPassword: 'שכחתי סיסמה?',
    login: 'התחברות',
    doNotHaveAnAccount: 'אין לך חשבון?',
    setUpAFreeAccount: 'הקמת חשבון בחינם',
    noCreditCardRequired: 'אין צורך בכרטיס אשראי',
    cancelAnytime: 'אפשרות לביטול בכל עת',
    signUp: 'הרשמה ',
    // as: 'כ ',
    // withFacebook: 'עם Facebook',
    signUpWithGoogle: 'הרשמה עם Google',
    // or: 'או',
    emailIsAlreadyRegistered: 'האימייל כבר רשום אצלנו, נא ללחוץ על התחברות להמשך',
    phoneNumberShouldHaveMinimum7Digits: 'מספר הטלפון צריך להכיל לפחות 7 ספרות*',
    phoneNumberIsRequired: 'שדה מספר הטלפון הוא שדה חובה*',
    passwordMustContain: 'הסיסמה צריכה לכלול',
    atleast6Characters: 'לפחות 6 תווים, ',
    oneUpperCaseLetter: 'אות אחת בגובה רישיות ',
    oneSpecialCharacter: '1 תו מיוחד*',
    alreadyHaveAccount: 'כבר יש לך חשבון?',
    signingUpToBotPenguinIsAConsentToOur: 'הרשמה לחשבון BotPenguin מהווה הסכמה ל ',
    privacyPolicy: 'מדיניות הפרטיות',
    termsAndConditions: 'תנאי השימוש',
    and: ' ו',
    english: 'אנגלית',
    hindi: 'הינדית',
    german: 'גרמנית',
    french: 'צרפתית',
    portuguese: 'פורטוגזית',
    spanish: 'ספרדית',
    italian: 'איטלקית',
    russian: 'רוסית',
    placeholders: {
      workEmail: 'אימייל עסקי*',
      password: 'סיסמה*',
      phone: 'מספר טלפון*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'הסרת מנוי בהצלחה',
    yourEmail: 'האימייל שלך ',
    haveBeenSuccessfullyUnsubscribedFrom: ' הוסר בהצלחה מ',
    backToLogin: 'חזור להתחברות'
  }
};
