export const notification = {
  notification: '通知',
  allNotifications: 'すべての通知',
  general: '一般',
  settings: '設定',
  newChatRequest: {
    youHaveANewChatRequest: '新しいチャットリクエストがあります!!!',
    youHaveTransferredChatRequest: '転送されたチャットリクエストがあります!!!',
    acceptRequest: 'リクエストを受け入れる',
    discardRequest: 'リクエストを破棄する'
  },
  notificationList: {
    name: '名前',
    message: 'メッセージ',
    noDataFound: {
      notification: '通知',
      noNotificationsFound: '通知が見つかりませんでした！',
    }
  },
  notificationSetting: {
    userMessage: 'ユーザーメッセージ',
    receiveNotificationsWhenUserSendMessage: 'ユーザーがメッセージを送信したときに通知を受け取る',
    liveChatRequest: 'ライブチャットリクエスト',
    receiveNotificationsWhenUserRequestLiveChat: 'ユーザーがライブチャットをリクエストしたときに通知を受け取る',
    newLead: '新しいリード',
    receiveNotificationsWhenNewLeadCreated: '新しいリードが作成されたときに通知を受け取る',
    assignedOnly: '割り当てのみ',
    allUsers: 'すべてのユーザー',
    customizeWhichNotificationsYouWantToRecieve: '受け取りたい通知をカスタマイズする',
    youCanCustomizeSettingForTheNotifications: '通知の設定をカスタマイズできます',
    receiveNotifications: '通知を受け取る',
    desktopNotifications: 'デスクトップ通知',
    mobilePushNotifications: 'モバイルプッシュ通知',
    discard: '破棄',
    update: '更新',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: '新しいメッセージのデスクトップ通知を送信',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: '新しいメッセージのモバイル通知を送信',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: '新しいリードのデスクトップ通知を送信',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: '新しいリードのモバイル通知を送信',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'デスクトップでのライブチャットの通知を送信',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'モバイルでのライブチャットの通知を送信',
      TOOLTIP_RECEIVED_NOTIFICATION: '通知を受信したいメッセージを選択'
    }
  }
};
