import { createAction, props } from '@ngrx/store';

export const loadAgencyDetails = createAction(
  '[Agency Details] Load Agency Details'
);

export const addAgencyDetails = createAction(
  '[Agency Details] Add Agency Details',
  props<{ agencyDetails: {} | any }>()
);

export const deleteAgencyDetails = createAction(
  '[Agency Details] Delete agency Details'
);

export const updateAgencyDetails = createAction(
  '[Agency Details] Update Agency Details',
  props<{ update: {} | any }>()
);
