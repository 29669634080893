import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import UpgradeModal from 'src/appv2/models/upgradeModal';
import { SharedService } from '../shared.service';
import { LanguageService } from 'src/appv2/services/language.service';
import { Store } from '@ngrx/store';
import { State } from 'src/appv2/ngrx/reducers';
declare let $: any;

@Component({
  selector: 'app-upgrade-modal',
  templateUrl: './upgrade-modal.component.html',
  styleUrls: ['./upgrade-modal.component.scss']
})
export class UpgradeModalComponent implements OnInit, OnDestroy {
  staticText: any;
  agencyToken: boolean;
  upgradePlanDescription: string;
  public textMessage: string;
  public canAccessPayment: boolean;
  private showUpgradeModal$: Subscription;
  private showUpgradeModal = false;
  private storeSubscription$: Subscription;
  @Output() userInput: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router, private sharedService: SharedService,
    private languageService: LanguageService, private store: Store<State>) {
    this.staticText = languageService.getStaticText().shared.upgradeModal;
    this.storeSubscription$ = this.store.subscribe(
      ({ profile, tokens, subscription }) => {
        this.canAccessPayment = profile.canAccessPayment;
        this.agencyToken = !!tokens.agencyToken;


        if (this.staticText.upgradeToKingPlan) {
          this.upgradePlanDescription = this.staticText.oops + this.getPlanName('baby') +
            this.staticText.planOfferOnlyOneFreeBot + this.getPlanName('king') +
            this.staticText.planToCreateMoreBots;

        }
      });
  }
  ngOnInit(): void {
    this.showUpgradeModal$ = this.sharedService.openUpgradeModal.subscribe((item: UpgradeModal) => {
      this.showUpgradeModal = item.isUpgradeModal;
      this.textMessage = item.textMessage;
      if (this.showUpgradeModal) {
        return $(document.getElementById('trigger-upgrade-modal')).modal('show');
      }
      return $(document.getElementById('trigger-upgrade-modal')).modal('hide');
    });
  }

  ngOnDestroy() {
    this.showUpgradeModal$.unsubscribe();
    this.storeSubscription$.unsubscribe();
  }

  action(status: boolean): void {
    this.userInput.emit(status);
    if (!status) {
      $(document.getElementById('trigger-upgrade-modal')).modal('hide');
    }
  }

  get isUserCanAccessPayment(): boolean {
    return !!(this.canAccessPayment || this.agencyToken);
  }

  getPlanName(type: 'baby' | 'king') {
    // If plan name is default then  add Plan with name baby or king
    return type === 'king'
      ? this.sharedService.plansDisplayName.value.king + `${this.sharedService.plansDisplayName.value.king.toLowerCase() === 'king' ? ' Plan' : ''}`
      : this.sharedService.plansDisplayName.value.baby + `${this.sharedService.plansDisplayName.value.baby.toLowerCase() === 'baby' ? ' Plan' : ''}`;
  }
}
