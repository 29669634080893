import { createAction, props } from '@ngrx/store';

export const loadLeads = createAction(
    '[Lead] Load Leads',
    props<{ leads: any[] }>()
);
export const loadMoreLeads = createAction(
    '[Lead] Load More Leads',
    props<{ leads: any[] }>()
);
export const addLead = createAction(
    '[Lead] Add Lead',
    props<{ lead: {} | any }>()
);
export const deleteLead = createAction(
    '[Lead] Delete Lead',
    props<{ lead: number | string }>()
);
export const updateLead = createAction(
    '[Lead] Update Lead',
    props<{ lead: number | string, update: {} | any }>()
);
