const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Editează fluxul de chat',
    seeHowToUse: 'Vezi cum se folosește',
    help: 'Ajutor',
    classicBuilder: 'Constructor Clasic',
    visualiseFlow: 'Vizualizează Fluxul',
    testLiveOnTelegramWeb: 'Testează în direct pe Telegram Web',
    testFlow: 'Testează fluxul',
    importTemplate: 'Importă șablonul',
    landingPageBotReview: 'Previzualizare Pagină de Aterizare Bot',
    install: 'Instalează',
    testLiveOnMessengerWeb: 'Testează în direct pe Messenger Web',
    testLiveOnWhatsappWeb: 'Testează în direct pe WhatsApp Web',
    testLiveOnInstagramWeb: 'Testează în direct pe Instagram Web',
    seeAllChatFlow: 'Vezi Toate Fluxurile de Chat',
    launchDemoModal: 'Lansează modalul demonstrativ',
    selectATemplateToKickStartYourBot: 'Selectează un șablon pentru a începe bot-ul tău',
    hireChatBotDesigner: 'Angajează Designer Chatbot',
    recommendedForYou: 'Recomandat pentru tine',
    useTemplate: 'Utilizează șablonul',
    exportStatus: 'Stare Export',
    flowExport: 'Export Flux',
    download: 'Descarcă',
    importFlow: 'Importă Flux',
    beforeYouStartUploadPleaseMakeSure: 'Înainte de a începe încărcarea, asigură-te că',
    ponit1: '1. Limita maximă de dimensiune a fișierului este de 1MB',
    point2: '2. Formatul fișierului trebuie să fie JSON',
    selectFile: 'Selectează Fișierul',
    preview: 'Previzualizare',
    test: 'Test',
    OopsIntegrationAreNotAvailable: 'Oops! Integrările nu sunt disponibile în ',
    pleaseUpgradeTo: ' Vă rugăm să faceți upgrade la ',
    planForIntegration: ' pentru integrări.',
    placeholders: {
      searchTemplateYouNeed: 'Caută șablonul de care ai nevoie',
    },
    noDataFound: {
      botFlow: 'Flux Bot',
      noBotFlowAddedYet: 'Încă nu a fost adăugat niciun flux de bot!',
      templates: 'Șabloane',
      noTemplatesFound: 'Nu s-au găsit șabloane!',
    },
    appTooltip: {
      exportFlow: 'Exportă Fluxul',
      importFlow: 'Importă Fluxul',
      clickToViewChatFlow: 'Apasă pentru a vedea fluxul de chat în constructorul de trască și lasă',
      clickToPreviewChatFlow: 'Apasă pentru a previzualiza fluxul de chat',
      clickToViewChatFlowAsFlowDiagram: 'Apasă pentru a vedea fluxul de chat ca diagramă de flux',
      clickToTestChatFlow: 'Apasă pentru a testa fluxul de chat',
      clickToSelectAndImportTemplate: 'Apasă pentru a selecta și importa șablonul care se potrivește cel mai bine afacerii tale.',
      clickToInstallYourBotToSelectedPlatform: 'Apasă pentru a instala bot-ul tău pe platforma selectată',
      clickToTestDefaultFlowOfTelegramBot: `Apasă pentru a testa fluxul implicit (activ) al bot-ului pe Telegram Web`,
      clickToTestDefaultFlowOfFacebookBot: `Apasă pentru a testa fluxul implicit (activ) al bot-ului pe Facebook Web`,
      clickToTestDefaultFlowOfWhatsappBot: `Apasă pentru a testa fluxul implicit (activ) al bot-ului pe WhatsApp Web`,
      clickToTestDefaultFlowOfInstagramBot: `Apasă pentru a testa fluxul implicit (activ) al bot-ului pe Instagram Web`,
    }
  },
  createNewBot: {
    createNewBot: 'Creează un Bot Nou',
    stepOneOfTwo: 'Pasul 1 din 2',
    selectThePlatform: 'Selectează platforma',
    selectTheBotType: 'Selectează Tipul de Bot',
    aiBasedChatbot: 'Chatbot bazat pe AI',
    ruleBasedChatBot: 'Chatbot bazat pe reguli',
    webPlatforms: 'Platforme Web',
    websiteChatBot: 'Chatbot pentru site web',
    withLiveChat: '(Cu Chat Live)',
    landingPageBot: 'Bot pentru Pagină de Aterizare',
    liveChatWidget: 'Widget Chat Live',
    onlyLiveChat: '(Doar Chat Live)',
    socialPlatforms: 'Platforme Sociale',
    whatsappChatBot: 'Chatbot WhatsApp',
    telegramChatBot: 'Chatbot Telegram',
    facebookChatBot: 'Chatbot Facebook',
    instagramChatBot: 'Chatbot Instagram',
    selectBotType: 'Selectează Tipul de Bot',
    selectProblem: 'Selectează problema pe care vrei să o rezolvi',
    leadGeneration: 'Generare de lead-uri',
    customerSupport: 'Asistență pentru clienți',
    appointmentBooking: 'Programare de întâlniri',
    marketingAutomation: 'Automatizare marketing',
    ecommerceWebsite: 'Site de comerț electronic',
    otherUseCase: 'Altele',
    back: 'Înapoi',
    typeDomainUrl: 'Introduceți URL-ul site-ului pentru verificare.',
    dataSourceForChatBotTraining: `Sursa de date pentru instruirea chatbotului`,
    upload: 'Încărcați',
    uploadDocuments: 'Încărcați documente',
    uploadDocument: 'Încărcați documentul',
    selectFile: 'Selectați fișierul',
    continue: 'Continuare',
    websiteUrl: 'Introduceți URL-ul site-ului dvs.',
    skipNow: 'Săriți acum',
    completeTheExistingBotSetup: 'completează configurația existentă a botului',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Ați creat deja un bot WhatsApp. Vă rugăm să finalizați configurarea pentru a crea unul nou.',
    completeSetup: 'Completează configurația',
    seeHowToUse: 'Vezi cum se folosește',
    help: 'Ajutor',
    fbBot: {
      createNewBot: 'Creează un Bot Nou',
      help: 'Ajutor',
      back: 'Înapoi',
      step: 'Pasul ',
      of3: ' din 3',
      loginWithFacebook: 'Conectează-te cu Facebook',
      asPerLatestChangesInFacebookPolicy: 'Conform celor mai recente modificări în politica Facebook, proprietarul unui bot pe Facebook trebuie să aibă rolul de `manager` sau mai sus pentru a avea acces la toate funcțiile paginii Facebook.',
      login: 'Conectare',
      connectFacebookBot: 'Conectează Bot-ul cu Facebook',
      connectBotWithYourFacebookPagesAnd: 'Conectează Bot-ul cu paginile tale de Facebook și ',
      exploreTruePowerOfFacebook: 'descoperă adevărata putere a Facebook-ului',
      connect: 'Conectare',
      connected: 'Conectat',
      createNewPage: 'Creează pagină nouă',
      refreshPageList: 'Reîmprospătează lista de pagini',
      thisPageIsSuccessfullyIntegratedWith: 'Această pagină este integrată cu succes cu ',
      yourMessagesFromMessengerWillAppearIn: '. Mesajele tale de pe Messenger vor apărea în ',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Continuă la Configurarea Bot-ului',
      welcomeToBrandNameWeAreHereToHelpYou: `Bine ai venit la ${brand}. Suntem aici să te ajutăm.`,
      letsTryYourBotAndSeeHowItWorks: `Hai să încercăm bot-ul tău și să vedem cum funcționează! Apasă pe "Testează pe Facebook".`,
      howAreYouDoingToday: 'Cum îți merge astăzi?',
      thisIsHowSimpleToUseFacebookBot: `Asta e! Așa de simplu este să folosești un bot de pe Facebook.`,
      letsCreateYourOwnChatFlowNow: `Hai să-ți creezi propria flux de chat acum.`,
    },
    instagramBot: {
      createNewBot: 'Creează un Bot Nou',
      back: 'Înapoi',
      step: 'Pasul ',
      of3: ' din 3',
      loginWithInstagram: 'Autentifică-te cu Instagram',
      loginToYourInstagramAccount: 'Conectează-te la contul tău de Instagram pentru a activa chatbot-ul pe profilul tău.',
      managerLevelDescription: 'Proprietarii de roboți Instagram trebuie să aibă cel puțin nivelul "manager" sau mai mare pentru a avea acces la toate funcțiile.',
      login: 'Autentificare',
      connectInstagramBot: 'Conectează Bot-ul Instagram',
      connectBotWithYourInstagramAnd: 'Conectează bot-ul cu contul tău de Instagram și ',
      exploreTruePowerOfInstagram: 'explorând adevărata putere a Instagram-ului',
      connected: 'Conectat',
      connect: 'Conectează',
      createNewPage: 'Creează pagină nouă',
      refreshPageList: 'Reîmprospătează lista de pagini',
      thisPageIsSuccessfullyintegratedWith: 'Această pagină este integrată cu succes cu ',
      yourMessagesFromMessengerWillAppearIn: '. Mesajele tale de pe Messenger vor apărea în ',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Continuă la Configurarea Bot-ului',
      welcomeToBrandNameWeAreHereToHelpYou: `Bun venit la ${brand}. Suntem aici să te ajutăm.`,
      letsTryYourBotAndSeeHowItWorks: `Hai să încercăm bot-ul tău și să vedem cum funcționează! Click pe "Testează pe Instagram".`,
      howAreYouDoingToday: 'Cum îți merge astăzi?',
      thisIsHowSimpleToUseInstagramBot: `Atât de simplu este să folosești un bot Instagram.`,
      letsCreateYourOwnChatFlowNow: `Hai să creăm acum propria ta flux de conversație.`,
    },
    telegramBot: {
      back: 'Înapoi',
      step: 'Pasul ',
      of3: ' din 3',
      seeHowToUse: 'Vezi cum să folosești',
      help: 'Ajutor',
      loginToYourTelegramAccount: 'Conectează-te la contul tău de Telegram',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Conectează-te la contul tău pentru a crea un bot nou sau pentru a conecta un bot existent',
      verificationCodeSentOnRegisteredNumber: 'Codul de verificare a fost trimis la numărul înregistrat',
      createYourBot: 'Creează-ți Bot-ul',
      enterYourBotsNameAnd: `Introdu numele bot-ului tău, conectează-te și lasă distracția să înceapă cu `,
      botUserNameMustEndWithBotAtTheEnd: 'Numele de utilizator al bot-ului trebuie să se termine cu "bot" la sfârșit.',
      terrisBot: 'Ex. teris_bot sau terisbot',
      botNameAlreadyTaken: 'Acest nume de bot este deja folosit. Te rog să încerci altceva',
      connect: 'Conectează',
      createBot: 'Creează Bot',
      codeNotReceivedResend: 'Codul nu a fost primit? Retrimite!',
      changeNumber: 'Schimbă Numărul',
      telegramPreview: 'Previzualizare Telegram',
      worldsFastestMessagingApp: `Cea mai rapidă aplicație de mesagerie din lume și este gratuită!`,
      bot: 'Bot',
      phoneNumber: 'Număr de telefon',
      userName: 'Nume de utilizator',
      none: 'Niciunul',
      bio: 'Bio',
      fewWordsAboutYourself: 'Câteva cuvinte despre tine',
      settings: 'Setări',
      notificationsAndSound: 'Notificări și sunete',
      privacyAndSecurity: 'Confidențialitate și securitate',
      dataAndStorage: 'Date și stocare',
      chatSettings: 'Setări Chat',
      chatFolders: 'Foldere Chat',
      devices: 'Dispozitive',
      connectYourBot: 'Conectează-ți Bot-ul',
      createNewBotOrConnectWithExistingOne: 'Creează un bot nou sau conectează unul existent',
      createNewBot: 'Creează un Bot nou',
      welcomeToBrandNameWeAreHereToHelpYou: `Bine ai venit la ${brand}. Suntem aici să te ajutăm.`,
      letsTryYourBotAndSeeHowItWorks: `Hai să încercăm bot-ul tău și să vedem cum funcționează! Click pe "Testează pe Telegram".`,
      howAreYouDoingToday: 'Cum îți merge astăzi?',
      thisIsHowSimpleToUseTelegramBot: `Atât de simplu este să folosești un bot Telegram.`,
      letsCreateYourOwnChatFlowNow: `Hai să creăm acum propria ta flux de conversație.`,
      placeholders: {
        searchCountry: 'Caută Țară',
        enterTelegramMobileNumber: 'Introdu Numărul de Telefon Telegram',
        enterLoginCodeReceived: 'Introdu codul de conectare primit pe contul tău de Telegram *',
        twoFactorAuthentication: 'Parolă 2FA (opțională)',
        botName: 'Nume Bot *',
        botUserName: 'Nume Utilizator Bot *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Creează un Bot Nou',
      seeHowToUse: 'Vezi cum se folosește',
      help: 'Ajutor',
      back: 'Înapoi',
      step: 'Pasul ',
      of2: ' din 2',
      buildA: 'Construiește un ',
      thatMatchesYourBrandPersonality: ` care se potrivește personalității brand-ului tău`,
      chatBotIcon: 'Icoana Chatbot ',
      optional: 'Opțional',
      themeColor: 'Culoare temă',
      chatBotName: 'Nume Chatbot',
      botNameIsRequired: 'Numele chatbot-ului este obligatoriu',
      botNameShouldBeLessThan50Characters: 'Numele Chatbotului trebuie să aibă mai puțin de 50 de caractere',
      continue: 'Continuă',
      welcomeToBrandNameWeAreHereToHelpYou: `Bun venit la ${brand}. Suntem aici să te ajutăm.`,
      whatIsYourEmailAddress: 'Care este adresa ta de e-mail?',
      invalidEmailAddress: 'Adresă de e-mail invalidă',
      customizeYourLandingPageBotLook: 'Personalizează aspectul Botului paginii de aterizare',
      customizeYourWidgetLook: 'Personalizează aspectul widget-ului',
      customizeYourBotLook: 'Personalizează aspectul chatbot-ului',
      buildYourLandingPageBotAndAutomateInteraction: 'Construiește-ți botul paginii de aterizare și automatizează interacțiunea cu clienții prin partajarea link-ului său',
      useLiveChatBotToConnectToCustomers: 'Folosește Live Chat Bot pentru a te conecta la clienții tăi în timp real',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Conectează ChatBot-ul la site-ul tău web și automatizează interacțiunile cu vizitatorii',
      widget: 'widget',
      botInterface: 'interfața chatbot-ului',
      mergedBot: 'Chatbot Site Web, Chatbot Pagină de Aterizare, Chatbot Aplicație Mobilă',
      mergedBotToolTip: 'Creează o pagină bot pentru a atrage atenția vizitatorilor și a-i implica în conversații.',
      placeholders: {
        typeANameForYourBot: 'Tastează un nume pentru noul tău bot',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Introdu numele botului tău',
        mergedBotToolTip: 'Creează o pagină bot pentru a atrage atenția vizitatorilor și a-i implica în conversații.',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Selectează o culoare temă pentru fereastra de chat',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Acest logo va fi folosit doar în platforma ${brand ? brand : ''}. Îți sugerăm să alegi logo-ul companiei. Adaugă o imagine PNG sau JPG cu dimensiunea maximă de 1 MB.`
      }
    },
    whatsappBot: {
      back: 'Înapoi',
      justAStepAway: 'Doar un pas distanță',
      seeHowToUse: 'Vezi cum se utilizează',
      help: 'Ajutor',
      createWhatsappChatBot: 'Creează Bot WhatsApp',
      businessNameIsRequired: 'Numele afacerii este obligatoriu.',
      maximumLengthForBusinessName40Characters: 'Lungimea maximă pentru numele afacerii este de 40 de caractere.',
      businessCategory: 'Categorie afacere',
      categoryIsRequired: 'Categoria este obligatorie.',
      descriptionIsRequired: 'Descrierea este obligatorie.',
      maximumLengthForDescription200Characters: 'Lungimea maximă pentru descriere este de 200 de caractere.',
      pleaseFillAValidEmailAddress: 'Vă rugăm să completați o adresă de email validă pentru afacere.',
      businessEmailIsRequired: 'Adresa de email a afacerii este obligatorie.',
      phoneNumberShouldHaveMinimum7Digits: 'Numărul de telefon trebuie să aibă cel puțin 7 cifre.',
      phoneNumberIsRequired: 'Numărul de telefon este obligatoriu.',
      websiteIsRequired: 'Website-ul este obligatoriu.',
      maximumLengthForWebsite40Characters: 'Lungimea maximă pentru website este de 40 de caractere.',
      maximumLengthForAddress150Characters: 'Lungimea maximă pentru adresa este de 150 de caractere.',
      doYouHaveWhatsappApiKey: 'Aveți cheia API WhatsApp?',
      apiProvider: 'Furnizor API',
      three60Dialog: '360 Dialog',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'Cheia API este obligatorie.',
      whatsappBusinessAccountIdIsRequired: 'ID-ul Contului de Afaceri WhatsApp este obligatoriu.',
      whatsappPhoneNumberIdIsRequired: 'ID-ul Numărului de Telefon WhatsApp este obligatoriu.',
      systemAccessTokenIsRequired: 'Tokenul de Acces al Sistemului este obligatoriu.',
      sourceIdIsRequired: 'ID-ul Sursei este obligatoriu.',
      businessName: 'Nume afacere',
      businessAccount: 'Cont afacere',
      businessDescription: 'Descriere afacere',
      address: 'Adresă',
      businessEmail: 'Email afacere',
      websiteUrl: 'URL website',
      thankYouForYourInterestIn: 'Vă mulțumim pentru interesul dvs. pentru ',
      whatsappChatBot: ' Chatbot WhatsApp.',
      weHaveReceivedYourInterests: 'Am primit interesul dvs., iar echipa noastră de vânzări va fi în contact cu dvs. în următoarele 24 de ore.',
      theyWillProvideYouWhatsappApiKey: 'Vor furniza cheia API WhatsApp.',
      readyToCreateWhatsappBot: `Sunt gata să creez Bot-ul WhatsApp`,
      exploreWhatsappFeatures: 'Explorați funcțiile WhatsApp',
      welcomeToBrandNameWeAreHereToHelpYou: `Bine ați venit la ${brand}. Suntem aici să vă ajutăm.`,
      letsTryYourBotAndSeeHowItWorks: `Să încercăm botul dvs. și să vedem cum funcționează! Apăsați "Testați pe WhatsApp".`,
      howAreYouDoingToday: 'Cum vă simțiți astăzi?',
      thisIsHowSimpleToUseWhatsappBot: `Atât de simplu este să utilizați botul WhatsApp`,
      letsCreateYourOwnChatFlowNow: `Hai să creăm acum propriul flux de chat`,
      connectWhatsAppBot: 'Conectați Bot-ul WhatsApp',
      createtWhatsAppBot: 'Creați un Bot WhatsApp',
      proceed: 'Continuați',
      whatsApiProvider: 'Furnizor API WhatsApp',
      cloudApi: 'API Cloud',
      netcoreApi: 'API Netcore',
      placeholders: {
        yourBusinessName: 'Numele afacerii dvs.',
        businessDescription: 'Descrierea afacerii',
        businessEmail: 'Email Afacere',
        phone: 'Telefon*',
        website: 'Website (de ex. www.exemplu.com)',
        address: 'Adresă',
        enterApiKey: 'Introduceți cheia API',
        enterWhatsappBusinessAccountId: 'Introduceți ID-ul Contului de Afaceri WhatsApp',
        enterWhatsappPhoneNumberId: 'Introduceți ID-ul Numărului de Telefon WhatsApp',
        enterSystemAccessToken: 'Introduceți Tokenul de Acces al Sistemului',
        enterSourceId: 'Introduceți ID-ul Sursei',
        businessName: 'Nume afacere',
        businessWebsite: 'Website afacere',
        selectWhatsappApiProvider: 'Alegeți furnizorul dvs. de API WhatsApp',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'Alegeți furnizorul dvs. de API WhatsApp',
        doYouHaveWhatsAppBusinessAPI: 'Aveți API de Afaceri WhatsApp?',
        yes: 'Da',
        noIAmNotSure: 'Nu, nu sunt sigur',
        selectApiProvider: 'Selectați furnizorul API',
        whatsAppCloudAPI: 'API WhatsApp Cloud',
        enterYourPhoneNumberId: 'Introduceți ID-ul numărului dvs. de telefon',
        phoneNumberId: 'ID-ul numărului de telefon',
        enterYourWhatsAppBusinessAccountId: 'Introduceți ID-ul Contului de Afaceri WhatsApp',
        whatsAppBusinessAccountId: 'ID-ul Contului de Afaceri WhatsApp',
        enterYourSystemUserToken: 'Introduceți Token-ul Utilizatorului de Sistem',
        systemUserToken: 'Token Utilizator de Sistem',
        help: 'Ajutor',
        netcoreAPI: 'API Netcore',
        enterYourSourceId: 'Introduceți ID-ul Sursă',
        sourceId: 'ID-ul Sursă',
        enterYourNetcoreApiKey: 'Introduceți Cheia API Netcore',
        netcoreApiKey: 'Cheia API Netcore',
        submit: 'Trimite',
        noneOftheAbove: 'Niciuna dintre cele de mai sus',
        next: 'Următor'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Afaceri înregistrată legal',
        validWebsiteWithDataPrivacyPolicy: 'Site web valid cu politica de confidențialitate a datelor',
        debit: 'Card de debit',
        creditCard: 'Card de credit',
        gstin: 'GSTIN',
        onlyRequiredForIndianResidents: 'Necesar doar pentru rezidenții indieni',
        onlyRequiredFor: 'Necesar doar pentru',
        indianResidents: 'Rezidenți indieni',
        validPaymentMethod: 'Metodă de plată validă',
        enabledWithInternationalPayments: 'Activat pentru plățile internaționale',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'Caz de utilizare valid în conformitate cu politica de comerț WhatsApp',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Număr de telefon care nu are WhatsApp activ și capabil să primească SMS',
        discard: 'Renunță',
        proceed: 'Continuă',
        whatsAppBotPrerequisites: 'Precondiții pentru Bot WhatsApp',
        letUsGetItDoneForYou: 'Hai să o facem pentru tine!',
        ifYouAreFacingSomeIssues: 'Dacă întâmpinați probleme',
        ourTeamCanHelpYouDoTheSame: 'Echipa noastră vă poate ajuta să faceți același lucru',
        requestACallbackNow: 'Solicitați acum un apel înapoi!',
      },
      stepThree: {
        setupWhatsAppBot: 'Configurați Bot-ul WhatsApp',
        selectingTheBestBusinessManagerForYourNeeds: 'Selectați cel mai bun Manager de Afaceri pentru nevoile dvs.',
        createMetaApp: 'Cum să creați o aplicație Meta',
        createaMetaAppOn: 'Creați o Aplicație Meta pe',
        howToCreate: 'Cum să creați',
        addWhatsAppNumber: 'Cum să adăugați un număr de WhatsApp',
        addYourWhatsAppNumberAndVerifyTheSame: 'Adăugați numărul WhatsApp și verificați-l',
        subscribe: 'Abonați-vă',
        copyAndSubscribeWebhook: 'Copiați și abonați Webhook',
        howToSubscribe: 'Cum să vă abonați',
        copy: 'Copiați',
        appSecretId: 'ID Secret al Aplicației',
        enterYourAppSecretId: 'Introduceți ID-ul Secret al Aplicației dvs.',
        enterAppSecret: 'Introduceți Secretul Aplicației',
        phoneNumberId: 'ID-ul Numărului de Telefon',
        whatsAppBusinessId: 'ID-ul Afacerii WhatsApp',
        assignSystemUser: 'Cum să atribuiți un utilizator de sistem',
        assign: 'Atribuiți',
        createApp: 'Creați Meta',
        registerAndVerify: 'Înregistrați și verificați',
        add: 'Adăugați',
        systemUserToken: 'Token Utilizator de Sistem',
        enterYourSystemUserToken: 'Introduceți Token-ul Utilizatorului de Sistem',
        getAllNecessaryDetails: 'Cum să obțineți toate detaliile necesare',
        subscribeWhatsAppWebhook: 'Cum să vă abonați la Webhook WA',
        configureTheWebhookForWhatsAppAccount: 'Configurați Webhook-ul pentru contul WhatsApp',
        upadatePaymentMethod: 'Cum să actualizați metoda de plată',
        assignThePaymentMethodToWhatsAppAccount: 'Atribuiți metoda de plată la orice Cont WhatsApp',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Acordați controlul unui Cont de Afaceri WhatsApp unui utilizator de sistem',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Detaliile cheie pe care trebuie să le copiați din Tabloul de bord Meta',
        updatePayment: 'Actualizați Plata',
        letUsGetItDoneForYou: 'Hai să o facem pentru tine!',
        ifYouAreFacingSomeIssues: 'Dacă întâmpinați probleme',
        ourTeamCanHelpYouDoTheSame: 'Echipa noastră vă poate ajuta să faceți același lucru',
        requestACallbackNow: 'Solicitați acum un apel înapoi!',
        update: 'Actualizați',
        connectNow: 'Conectați acum'
      },
      stepFive: {
        selectBusinessManager: 'Selectați Managerul de Afaceri',
        selectingTheBestBusinessManagerForYourNeeds: 'Selectați cel mai bun Manager de Afaceri pentru nevoile dvs.'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Bot-ul dvs. este instalat și activ',
        youhaveSuccessfullyConnected: 'Ați conectat cu succes',
        withWhatsApp: 'cu WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'V-au fost alocate implicit 100 de conversații pe lună',
        upgradeYourPlanForMore: 'Faceți upgrade la plan pentru mai mult.',
        createChatFlow: 'Creați Flux de Conversație',
        upgradePlan: 'Upgrade la Plan'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Scanați codul QR sau Trimiteți un mesaj pentru a activa Bot-ul',
        scanTheQrCodeFrom: 'Scanați codul QR de pe',
        yourPhoneWillOpen: 'Telefonul dvs. va deschide un mesaj precompletat de trimis la numărul nostru Sandbox. Apăsați "Trimite" pe WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Trimiteți un mesaj de pe numărul dvs. WhatsApp',
        sendUsAWhatsAppMessageOn: 'Trimiteți-ne un mesaj WhatsApp la ',
        bySavingInYourContactsAndMessageShouldStartWith: ' salvându-le în contactele dvs. și mesajul trebuie să înceapă cu ',
        send: 'Trimite',
        or: 'SAU'
      }
    }
  },
  gettingStarted: {
    hello: 'Salut, ',
    upgrade: 'Upgrade',
    gettingStarted: 'Pornire - ',
    yourFacebookBotToken: 'Tokenul botului dvs. de Facebook ',
    hasBeenExpired: 'a expirat',
    isExpiringIn: 'expiră în ',
    days: ' zile',
    facebookAuthenticationTokenRemainsFor60Days: 'Tokenul de autentificare Facebook rămâne activ doar pentru 60 de zile',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Vă rugăm să continuați cu reînnoirea token-ului bot-ului pentru a vă asigura accesul neîntrerupt la serviciile de chatbot.',
    renewYourBotToken: 'Renunțați la tokenul botului dvs.',
    editYourChatFlow: 'Editați fluxul dvs. de chat',
    buildConversationEngagingBot: 'Construiți fluxuri de conversație captivante pentru utilizatorii botului dvs.',
    takes5minutes: 'durează 5 minute',
    previewYourLiveChatWidget: 'Previzualizați widgetul Live Chat',
    discoverHowThe: 'Descoperiți cum ',
    liveChatFeatureWorks: ' funcția de Live Chat funcționează utilizând zona noastră de joacă pentru Live Chat',
    takes2minutes: 'durează 2 minute',
    takes1minute: 'durează 1 minut',
    customizeYourBotApperance: 'Personalizați aspectul și funcționalitatea botului dvs.',
    takes3minutes: 'durează 3 minute',
    thirdPartyAppsIntegration: 'Integrarea de Aplicații Terțe',
    integrateYourBot: 'Integrați botul dvs. cu alte aplicații/CRM-uri pentru a crea automatizări pentru afaceri.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Faceți upgrade la planul dvs. pentru a primi lead-uri pe email',
    getStartedWithUs: 'Începeți cu noi',
    howToBuildAGoodChatFlow: 'Cum să construiți un flux de chat bun',
    howToUseBotPenguinsInboxAndItsFeatures: 'Cum să utilizați Inbox-ul BotPenguin și caracteristicile sale',
    botPenguinsPricing: 'Prețurile BotPenguin: Explorarea planurilor și caracteristicilor',
    openHelpDesk: 'Deschideți biroul de ajutor',
    helpAndSupport: 'Ajutor și suport',
    extensiveGuides: 'Ghiduri extinse pentru toate întrebările și fluxurile dvs. sunt disponibile în ',
    resourceCentre: 'Centrul de Resurse',
    checkoutOurAnswers: 'Puteți consulta răspunsurile noastre la întrebările obișnuite mai jos.',
    createANewBot: 'Creați un bot nou',
    installYourBot: 'Instalați botul dvs.',
    plansAndPricing: 'Planuri și Prețuri',
    previewYourLandingPageBot: 'Previzualizați botul dvs. pentru paginile de aterizare',
    installYourLiveChatWidget: 'Instalați widgetul dvs. de Live Chat',
    installYourWebsiteBot: 'Instalați botul dvs. pe site, în aplicația mobilă sau lansați-l ca pagină de aterizare sau ca bot încorporat',
    integrateBotToLandingPage: 'Integrați botul în botul dvs. pentru paginile de aterizare',
    installWidgetToWebsite: 'Instalați widgetul pe site-ul dvs.',
    installBotToWebsite: 'Instalați botul dvs.',
    whatsappAutomation: 'Automatizare WhatsApp',
    facebookAutomation: 'Automatizare Facebook',
    telegramBotSettings: 'Setări bot Telegram',
    liveChatWidgetSettings: 'Setări widget Live Chat',
    landingPageBotSettings: 'Setări bot pentru paginile de aterizare',
    websiteChatBotSettings: 'Setări chatbot pentru site',
    instagramAutomation: 'Automatizare Instagram',
    diveDeeperIntoBotPenguin: 'Aprofundați-vă cunoștințele despre BotPenguin',
    knowWhereWeAreGoing: 'Știți încotro ne îndreptăm, actualizările recente și ajutați-ne să rezolvăm unele erori.',
    productUpdates: 'Actualizări ale produsului',
    requestAFeature: 'Solicitați o funcționalitate',
    reportABug: 'Raportați o eroare',
    prodcutRoadmap: 'Harta produsului',
    trainYourChatbot: 'Antrenează-ți Chatbot-ul IA (baza de cunoștințe)',
    buildEngagingConversation: 'Construiți fluxuri de conversație captivante pentru utilizatorii botului dvs.',
    previewYourAIBot: 'Previzualizați botul dvs. AI',
    integrateAIbot: 'Integrați botul în botul dvs. AI',
    aiBotSettings: 'Setări bot AI',
    CustomizeAIBot: 'Personalizați aspectul și funcționalitatea botului dvs. AI.',
    gptKeyWarning: 'Bine ați venit la serviciul nostru ChatGPT API! Vă rugăm să notați că există o limitare de 100 de mesaje pentru fiecare bot. După ce ați atins limita de 100 de mesaje, va trebui să utilizați propria cheie API ChatGPT pentru a continua să vă bucurați de serviciul nostru. Pentru a introduce cheia API ChatGPT, mergeți la Configurarea modelului ChatGPT.',
    connectToYourWhatsappNumber: 'Conectați-vă la numărul dvs. WhatsApp',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Conectați botul WhatsApp la numărul dvs. WhatsApp',
    whatsappBotSettings: 'Setări Bot WhatsApp',
    facebookBotSettings: 'Setări Bot Facebook',
    automateCustomerEngagementWithTelegram: 'Automatizare conversație cu utilizatorii dvs. pe Telegram',
    automateCustomerEngagementWithFacebook: 'Automatizare conversație cu utilizatorii dvs. pe Facebook',
    automateCustomerEngagementWithInstagram: 'Automatizare conversație cu utilizatorii dvs. pe Instagram',
    automateCustomerEngagementWithWhatsapp: 'Automatizare conversație cu utilizatorii dvs. pe WhatsApp',
    telegramAutomation: 'Automatizare Telegram',
  },
  install: {
    previewYourLandingPageBot: 'Previzualizați botul pentru pagina de aterizare',
    preview: 'Previzualizare',
    test: 'Test',
    squareSpace: 'Squarespace',
    installYourChatBot: 'Instalați botul dvs. de chat',
    seeHowToUse: 'Aflați cum să utilizați',
    help: 'Ajutor',
    uniqueLinkToLandingPageBot: 'Aici este link-ul unic către botul pentru pagina de aterizare.',
    shareOnSocialMedia: 'Pur și simplu partajați-l cu utilizatorii dvs. pe rețelele de socializare, pagini de contact sau cărți de vizită.',
    or: 'SAU',
    copyUrl: 'Copiați URL-ul',
    selectYourWebsiteCms: 'Selectați CMS-ul dvs. pentru site-ul web',
    selectCms: 'Selectați CMS-ul/Constructorul pe care l-ați folosit pentru a crea site-ul web',
    custom: 'Personalizat',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Alte CMS-uri',
    howWouldYouLikeToInstall: 'Cum doriți să-l instalați?',
    installingWebsiteBot: 'Alegeți cum veți instala botul pe site-ul dvs.',
    iWillAddMyself: 'Eu îl voi adăuga singur',
    getChatBotScript: 'Obțineți codul script al botului de chat mai jos și instalați-l singur',
    getWordpressPlugin: 'Obțineți cheia API pentru modulul WordPress',
    getOurOfficialPlugin: 'Obțineți modulul nostru oficial ',
    fromWordpressPluginStore: ' din Magazinul de module WordPress',
    getPlugin: 'Obțineți Modulul',
    quickGuideToInstall: 'Ghid rapid pentru instalare',
    placeApiKeyBelowIn: 'Lipiți cheia API mai jos în ',
    afterDownloadingAndActivatingPlugin: ' după descărcarea și activarea modulului',
    copy: 'Copiați',
    enterYourWebsite: 'Introduceți adresa site-ului dvs. web și să vedem cum arată chatbot-ul dvs.',
    enterAValidUrl: 'Introduceți o adresă URL validă',
    verifyYourInstallation: 'Verificați dacă instalarea dvs. a avut succes sau nu?',
    testInstallation: 'Testați Instalarea',
    getChatBotScriptCode: 'Obțineți Codul Script pentru Botul de Chat',
    copyThisCodeSnippet: 'Copiați acest fragment de cod și inserați-l în' +
      'HTML-ul fiecărei pagini web unde doriți să afișați widgetul de chat sau lipiți-l în subsol înainte de închiderea tag-ului pentru a apărea pe fiecare pagină.',
    // copy: 'Copy'
    sendCodeToMyDeveloper: 'Trimiteți codul către dezvoltatorul meu',
    sendCodeToMyDevelopmentTeam: 'Trimiteți codul către echipa mea de dezvoltare',
    sendEmail: 'Trimiteți e-mail',
    enterAValidEmail: 'Introduceți o adresă de e-mail validă',
    codeHasBeen: 'Codul a fost ',
    toYourDeveloper: ' către dezvoltatorul dvs. ',
    resent: 'retrimis',
    sent: 'trimis',
    resendEmail: 'Retrimiteți e-mailul',
    ask: 'Întrebați ',
    supportTeamToInstall: 'Echipa de suport pentru a instala',
    raiseTheInstallationRequestTo: 'Ridicați cererea de instalare la ',
    team: ' echipă',
    helpMeInstallingChatBot: 'Ajutați-mă la instalarea ChatBot-ului',
    yourBotInstallationIsNotActive: 'Instalarea botului dvs. nu este activă!',
    monthlyMessageLimitExceeded: 'Limita lunară de mesaje a fost depășită',
    exceededMonthlyMessageLimitUpgradeNow: 'Ați depășit limita lunară de mesaje, faceți upgrade acum.',
    upgradeNow: 'Faceți Upgrade Acum',
    botInstallationNotActivePleaseFix: 'Instalarea botului dvs. nu este activă, Vă rugăm să remediați acest lucru. ',
    incorrectBotScript: 'Scriptul Botului Incorect',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'Codul de instalare pe care îl verificați nu se potrivește cu ' +
      'cel de pe site-ul dvs. Vă rugăm să vă asigurați că ați instalat codul corect pentru acest bot.',
    unableToSee: 'Nu pot vedea ',
    codeOnYourWebsite: ' Codul pe site-ul dvs.',
    unableToFindCodePleaseReinstall: 'Nu putem găsi codul pe site-ul dvs., Vă rugăm să reinstalați.',
    reinstallBot: 'Reinstalați botul',
    yourBotIsInstalledAndActive: 'Botul dvs. este instalat și activ!',
    noPendingAction: 'Nicio acțiune în așteptare, Botul dvs. este instalat și activ',
    youAreAllSet: 'Sunteți pregătit!',
    showMeMyLeads: 'Arătați-mi lead-urile mele',
    connectingToYourWebsiteToVerifyInstallation: 'Se conectează la site-ul dvs. pentru a verifica Instalarea Botului',
    thisMayTakeUpto30Seconds: 'Acest lucru poate dura până la 30 de secunde',
    hereIsTheuniqueAILink: 'Iată link-ul unic către Botul dvs. AI',
    installHeading: 'Instalați Chatbot-ul dvs.',
    websiteChatbot: 'Chatbot pentru site-ul web',
    aiChatBot: 'Chatbot AI',
    landingPageBot: 'Chatbot pentru pagina de aterizare',
    mobileChatBot: 'Chatbot pentru aplicații mobile',
    embeddedBot: 'Chatbot Înglobat',
    selectMobileDevice: 'Selectați dispozitivul dvs. mobil',
    mobileDeviceSubheading: 'Selectați limba nativă pe care ați utilizat-o pentru a crea aplicația dvs. mobilă',
    ios: 'IOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Alt dispozitiv mobil',
    copyEmbeddedCodeSnippet: 'Copiați acest fragment de cod și inserați-l în HTML-ul fiecărei pagini web unde doriți ' +
      'să afișați chatbot-ul.',
    copyMobileCodeSnippet: 'Copiați acest fragment de cod în codul aplicației dvs. mobilă, unde doriți să afișați chatbot-ul.',
    placeholders: {
      exampleSite: 'https://www.situlvostru.com',
      enterDeveloperEmail: 'Introduceți adresa de e-mail a dezvoltatorului'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Trimiteți instrucțiuni dezvoltatorului dvs.',
      notSureHowToDoIt: 'Nu sunteți sigur cum să faceți asta? Dezvoltatorii dvs. pot face asta într-un timp scurt.',
      developersEmail: 'E-mailul dezvoltatorului',
      emailIsRequired: 'E-mailul este obligatoriu',
      subject: 'Subiect',
      message: 'Mesaj',
      send: 'Trimiteți',
      placeholders: {
        enterYourWorkEmail: 'Introduceți adresa dvs. de e-mail de lucru',
        createBotUsingBotPenguin: 'Încântat! Creați un bot folosind BotPenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Iată link-ul unic către Botul pentru pagina de aterizare.',
      shareWithUsersOnSocialMedia: 'Pur și simplu partajați-l cu utilizatorii dvs. pe rețelele de socializare, pagini de contact sau cărți de vizită.',
      previewMyLandingPageBot: 'Previzualizați botul pentru pagina de aterizare',
      or: 'SAU',
      copyUrl: 'Copiați URL-ul',
      howYouLikeToAddBot: 'Alegeți cum doriți să adăugați codul Bot la site-ul dvs.',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Instalați Botul pe site-ul dvs. cu cod personalizat sau pe WordPress prin copierea și lipirea unui cod unic.',
      name: 'Nume',
      actions: 'Acțiuni',
      javascript: 'Javascript',
      install: 'Instalați ',
      onYourCustomCodedWebsite: ' pe site-ul dvs. cu cod personalizat',
      copyAndPaste: 'Copiați și lipiți',
      copyThisCodeSnippet: 'Copiați acest fragment de cod și inserați-l în ' +
        'HTML-ul fiecărei pagini web unde doriți să afișați Widgetul de Chat sau lipiți-l în subsol înainte de închiderea tag-ului pentru a apărea pe fiecare pagină.',
      seeGuide: 'vezi ghidul',
      getCode: 'Obțineți Codul',
      howToInstall: 'Cum să instalați?',
      copyCode: 'Copiați codul',
      wordpress: 'WordPress',
      // install: 'Instalați ',
      onYourWordPressWebiste: ' pe site-ul dvs. WordPress',
      preview: 'Previzualizare',
      test: 'Test',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Previzualizare Chat Live',
    agentWindow: 'Fereastra Agent',
    person: 'persoană',
    inOrderToTestTypeHere: 'Pentru a testa, tastați aici',
    agentWindowCannotBeCustomized: 'Notă: Fereastra agentului nu poate fi personalizată',
    customerWindow: 'Fereastra Client',
    clickHereToChat: 'Faceți clic aici pentru a Chatui',
    customerWillChatHere: 'Clientul va chatea aici',
    chat: 'Chat',
    by: 'de',
    changeYourBotLookFromLeftPanel: 'Schimbați aspectul botului dvs. din panoul din stânga',
    placeholders: {
      typeYourMessage: 'Tastați mesajul dvs...',
      typeYourAnswer: 'Tastați răspunsul dvs',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Previzualizați widgetul Live Chat',
    install: 'Instalare',
    seeHowToUse: 'Aflați cum să utilizați',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Faceți clic pentru a instala botul pe platforma selectată',
    }
  },
  trainYourBot: {
    trainYourBot: 'Antrenați Chatbotul Dvs.',
    knowledgeBase: 'Bază de cunoștințe',
    faqs: 'Întrebări frecvente',
    history: 'Istoric',
    faqHeading: 'Întrebări frecvente',
    faqSubHeading: 'Răspunsuri la întrebările dvs. despre antrenarea chatbotului',
    knowledgeBaseHeading: 'Antrenați chatbotul',
    knowledgeBaseSubheading: 'Antrenați Chatbotul pentru Conversații Îmbunătățite',
    websiteLink: 'Link către site',
    uploadFile: 'Încărcați fișierul',
    selectFile: 'Selectați fișierul',
    submit: 'Trimite',
    searchHere: 'Căutați aici',
    question: 'Întrebare',
    answer: 'Răspuns',
    questionPlaceholder: 'Scrieți întrebarea aici',
    answerPlaceholder: 'Scrieți răspunsul aici',
    save: 'Salvați',
    discard: 'Renunțați',
    addFaq: 'Adăugați Întrebări frecvente',
    createdAt: 'Creat la',
    updatedAt: 'Ultima actualizare la',
    actions: 'Acțiuni',
    noFaqFound: 'Nu aveți încă Întrebări frecvente',
    noFaqFoundMessage: 'Toate Întrebările frecvente vor apărea aici',
    historyTitle: 'Istoric baza de cunoștințe',
    historySubtitle: 'Răspunsuri la întrebările dvs. despre antrenarea chatbotului',
    fileName: 'Nume fișier',
    status: 'Stare',
    noHistoryTitle: 'Nu aveți încă modele antrenate create',
    noHistoryDescription: 'Toate modelele antrenate vor apărea aici',
    fileUpload: 'Încărcare fișier',
    files: 'Fișiere',
    fileSubHeading: 'Adăugați toate fișierele pe care doriți să le folosiți pentru antrenarea botului.',
    noFileFoundHeading: 'Nu aveți încă fișiere create',
    noFileFoundSubheading: 'Toate fișierele dvs. vor apărea aici',
    uploadYourFiles: 'Încărcați fișierele dvs.',
    upload: 'Încărcați',
    fileShouldBe: 'Fișierul trebuie să fie PDF, DOC, DOCX, PPTX, TXT.',
    uploadStatus: 'Stare încărcare',
    uploadStatusSubHeading: 'Starea încărcării fișierelor pentru antrenarea botului',
    addFile: 'Adăugați fișier',
    otherConfiguration: 'Configurări AI',
    deleteFiles: 'Faceți clic pentru a șterge fișierele',
    websiteURL: 'Adrese URL ale site-urilor',
    websiteUrlSubheading: 'Adăugați toate adresele URL ale site-urilor pe care doriți să le folosiți pentru antrenarea botului.',
    enterWebsiteUrl: 'Introduceți URL-ul',
    addWebsite: 'Adăugați site-ul',
    tableWebsiteUrl: 'Adresă URL a site-ului',
    trainingStatus: 'Starea Antrenării',
    lastTrained: 'Ultima dată antrenat',
    noWebsitesFound: 'Nu aveți încă site-uri create',
    noWebsitesFoundMessage: 'Toate site-urile dvs. vor apărea aici',
    loadMore: 'Încărcați mai mult',
    botBehaviour: 'Configurați Comportamentul Botului',
    botBehaviourSubHeading: 'Configurați personalitatea botului, redactați datele clienților, gestionați fluxul și modelul ChatGPT',
    botPersonality: 'Configurați personalitatea botului',
    botPersonalitySubHeading: 'Gestionați modul în care botul dvs. ar trebui să răspundă în funcție de personalitate',
    selectAnswerLength: 'Selectați lungimea răspunsului',
    answerLength: 'Lungimea răspunsului',
    answerAs: 'Răspunde ca',
    tone: 'Ton',
    language: 'Limbă',
    answerFormatting: 'Formatare Răspuns',
    customInstructions: 'Solicitare personalizată',
    customerInstructionPlaceholder: 'Fiți politicos și prietenos. Nu folosiți argoul. Nu folosiți emoji.',
    redactCustomerData: 'Colectează datele clienților',
    redactDataSubheading: 'Configurați informațiile despre clienți pe care doriți să le colectați',
    collectName: 'Colectare Nume',
    configureQuestion: 'Configurați Întrebarea',
    maxCharacterLimit: 'Limita maximă de caractere în Numele Furnizat',
    collectEmail: 'Colectare E-mail',
    excludeEmail: 'Exclude E-mailurile cu Domeniu',
    collectPhone: 'Colectare Telefon',
    manageUnusualMessages: 'Gestionați Scenariile de Chat Neobișnuite',
    unusualMessagesSubheading: 'Gestionați scenariile rare în care botul nu răspunde și altele',
    startConversation: 'Mesaj pentru a începe conversația',
    configureMessage: 'Mesaj atunci când botul nu poate răspunde',
    profanityMessage: 'Mesaj pentru a răspunde la utilizarea limbajului inadecvat',
    chatGptModel: 'Configurare Model ChatGPT',
    chatGptModelSubheading: 'Gestionați ce model ChatGPT să utilizați și evitați utilizarea abuzivă',
    chatGptApi: 'Cheia API ChatGPT',
    enableDisableRedactData: 'Colectează datele clienților',
    triggerToCollectData: 'Colectează detaliile de contact după un set de întrebări.',
    train: 'Antrenează',
    website: 'site web',
    includedSourcesForResponse: 'Surse incluse pentru răspuns',
    visibility: 'Vizibilitate',
    analytics: 'Analiză',
    yourConversation: 'Conversațiile tale',
    reviewAndSelectConversation: 'Revizuiți și Selectați Conversațiile pentru Export la Antrenamentul Chatbotului',
    exportAiAnalytics: 'Exportați analitica',
    startDate: 'Data de început',
    endDate: 'Data de sfârșit',
    userQuestion: 'Întrebarea Utilizatorului',
    botsReply: 'Răspunsul Botului',
    dateCreated: 'Data Creării',
    noAnalyticsFound: 'Încă nu ați creat nicio analiză',
    noAnalyticsFoundMessage: 'Toate mesajele dvs. analitice vor apărea aici',
    exportData: 'Exportați Datele',
    exportCompleteAnalyticsData: 'Exportați date complete de analiză',
    export: 'Exportați',
    integrateWithChatGpt: 'Integrați cu ChatGPT',
    integrated: 'Integrat',
    configure: 'Configurați',
    appToolTip: {
      deleteWebsite: 'Faceți clic pentru a șterge site-urile',
      knowledgeBase: 'Antrenați botul dvs. cu baza de cunoștințe',
      faqs: 'Antrenați botul dvs. cu întrebări frecvente',
      knowledgeHistory: 'Istoricul bazei de cunoștințe',
      uploadFaqs: 'Încărcați Întrebări frecvente',
      addFaqs: 'Faceți clic pentru a adăuga Întrebări frecvente',
      deleteFaqs: 'Ștergeți Întrebările frecvente',
      deleteHistory: 'Ștergeți istoricul',
      uploadFile: 'Fișierul încărcat trebuie să fie PDF, DOCX, DOC sau CSV și dimensiunea maximă este de 1 MB.',
      trainWebsite: 'Antrenați botul dvs. utilizând un site web.',
      fileUpload: 'Antrenați-vă botul utilizând fișiere precum PDF-uri, TXT-uri, PPTX-uri, DOC-uri și DOCX-uri.',
      configureBotBehaviour: 'Personalizați personalitatea botului, gestionați fluxul și ajustați modelul ChatGPT.',
      clickToAddWebsite: 'Faceți clic pentru a adăuga un site web',
      clickToDeleteWebsites: 'Faceți clic pentru a șterge site-uri web',
      syncWebsite: 'Sincronizați site-ul web',
      copyUrl: 'Copiați URL-ul',
      weWillBeFetchingAllTheWebpages: 'Vom extrage toate paginile web și vom antrena modelul ChatGPT pe acestea.'
    },
    modal: {
      deleteWebsiteUrl: 'Ștergeți URL-ul site-ului web',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Sunteți sigur că doriți să ștergeți acest URL al site-ului web?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Setări Chatbot pentru Website',
    preview: 'Previzualizare',
    test: 'Test',
    install: 'Instalare',
    designHeader: 'Design',
    generalHeader: 'General',
    alertsHeader: 'Alerte',
    triggerHeader: 'Declanșator',
    userAccessHeader: 'Acces Utilizator',
    chatWindowHeader: 'Fereastră de Chat',
    otherConfiguration: 'Configurări AI',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Faceți clic pentru a instala botul pe platforma selectată',
      customizeAppearanceOfBot: 'Personalizați poziționarea și aspectul interfeței botului dvs.',
      configureBotsSettingsWhenAgentUnavailable: 'Configurați setările botului când agentul dvs. nu este disponibil.',
      configureHowBotWillAlertYou: 'Configurați modul în care botul dvs. vă va alerta cu privire la noile potențiale clienți.',
      makeSureYourBotsPopUpProperlyTimed: 'Asigurați-vă că pop-up-urile botului dvs. sunt programate perfect.',
      customizeWhereYouWantYourBotToAppear: 'Personalizați unde doriți să apară botul dvs. și unde nu.',
      enableChatOptionsForVisitorsToTransferChat: 'Activați opțiunile de chat pentru vizitatori pentru a transfera chat-uri, chat-uri live și reîmprospătare a chat-ului.',
      configureBotBehaviour: 'Personalizați personalitatea botului, gestionați fluxul și ajustați modelul ChatGPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Configurați setările de alertă ale botului dvs.',
      specifyHowYourBotWillNotify: 'Specificați cum botul dvs. vă va notifica cu privire la noile potențiale clienți.',
      subject: 'Subiect',
      emailNotification: 'Notificare prin Email',
      emailAddresses: 'Adrese de Email',
      leadRevisitNotification: 'Notificări de Revenire a Potențialilor Clienți',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'V-ați înregistrat cu succes cu notificările de pe desktop',
      youHaveBlockedYourNotificationsOnBrowser: 'Ați blocat notificările în setările browser-ului dvs.',
      discard: 'Renunțare',
      saveChanges: 'Salvare Modificări',
      seeHowToUse: 'Aflați cum să utilizați',
      placeholders: {
        enterSubjectForYourEmail: 'Introduceți Subiectul pentru e-mailul dvs.',
        typeYourEmailAddressAndHitEnter: 'Tastați adresa dvs. de email și apăsați enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Personalizați subiectul notificărilor dvs. prin e-mail de la bot',
        TOOLTIP_EMAIL_NOTIFICATION: 'Activare/dezactivare notificare bot prin e-mail pentru noile potențiale clienți',
        TOOLTIP_EMAIL_ADDRESS: 'Introduceți adresa de e-mail a contului în care doriți să primiți notificări prin e-mail. Puteți adăuga mai multe adrese de email introducând una și apăsând enter pentru a adăuga alta.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'Activare/dezactivare notificare bot prin e-mail dacă un utilizator anterior capturat ca potențial client interacționează din nou cu botul.',

      }
    },
    broadcasts: {
      createBroadcastHeading: 'Creați O Uitzendlijst',
      youCanCreateMultipleBroadcasts: 'Puteți crea mai multe uitzendlijsturi',
      broadcastName: 'Numele Uitzendlijstului',
      broadcastNameIsRequired: 'Numele uitzendlijstului este obligatoriu',
      continue: 'Continuați',
      manageBroadcasts: 'Gestionați Uitzendlijsturile',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'Puteți crea mai multe difuzări cu șabloane diferite pentru diferite grupuri de clienți.',
      createNewBroadcast: 'Creați un Nou Uitzendlijst',
      name: 'Nume',
      segment: 'Grup',
      template: 'Șablon',
      contacts: 'Contacte',
      send: 'Trimite',
      delivered: 'Livrare',
      read: 'Citit',
      failed: 'Eșuat',
      broadcastAt: 'Uitzendlijst la',
      status: 'Status',
      actions: 'Acțiuni',
      nA: 'N/B',
      seeHowToUse: 'Aflați cum să utilizați',
      deleteBroadcast: 'Ștergeți Uitzendlijstul',
      wantToDeleteThisBroadcast: 'Sigur doriți să ștergeți acest uitzendlijst?',
      placeholders: {
        searchHere: 'Căutare aici',
        enterBroadcastName: 'Introduceți numele uitzendlijstului',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Ștergeți mai multe uitzendlijsturi',
        broadcastAnalytics: 'Analize Uitzendlijst',
        deleteBroadcast: 'Ștergeți Uitzendlijstul',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Percepții Uitzendlijst',
        broadcastStatus: 'Status Uitzendlijst',
        youCanFindDetailsOfEachContactBelow: 'Puteți găsi detalii ale fiecărui contact mai jos și exporta pe cei care ar putea avea probleme.',
        filterApplied: 'Filtru Aplicat',
        placeholders: {
          searchByPhoneNumber: 'Căutare după Număr de Telefon',
          searchByName: 'Căutare cu filtru după nume',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Filtrare date în funcție de status',
          exportData: 'Exportare Date',
        },
        modal: {
          exportData: 'Exportare Date',
          exportCompleteBroadcastData: 'Exportați datele complete ale uitzendlijstului',
          export: 'Exportare'
        }
      },
      createBroadcast: {
        broadcastName: 'Numele Uitzendlijstului',
        broadcastNameIsRequired: 'Numele uitzendlijstului este obligatoriu',
        template: 'Șablon',
        segments: 'Grupuri',
        whenToSend: 'Când să Trimiteți',
        now: 'ACUM',
        later: 'MAI TÂRZIU',
        selectBroadcastUsing: 'Selectați uitzendlijstul folosind',
        usingCsv: 'Folosind CSV',
        usingSegment: 'Utilizând Grupul',
        allContacts: 'Toate Contactele',
        selectDate: 'Selectați Data',
        selectTimeSlot: 'Selectați Intervalul de Timp',
        assignVariablesToTemplates: 'Alocați variabile șabloanelor',
        addHeaderMedia: 'Adăugați Media pentru Antet',
        selectFile: 'Selectați Fișierul',
        cancel: 'Anulare',
        createBroadcast: 'Creați Uitzendlijstul',
        broadcastUsing: 'Uitzendlijstul Folosind',
        selectCSVFile: 'Selectați Fișierul CSV',
        importContacts: 'Importați Contactele',
        beforeYouStartUpload: 'Înainte de a începe încărcarea, asigurați-vă că',
        point1: '1. Descărcați fișierul CSV de exemple.',
        point2: '2. Începeți să introduceți datele de la rândul 2 în sus.',
        point3: '3. În fiecare rând, există date pentru un contact',
        point4: '4. Asigurați-vă că toate numerele de telefon sunt unice.',
        point5: '5. Limita maximă de dimensiune pentru încărcare este de 1 MB',
        //
        clickHere: 'Faceți clic aici',
        toDownloadSampleFile: 'pentru a descărca un fișier de exemplu',
        placeholders: {
          enterBroadcastName: 'Introduceți numele uitzendlijstului',
          selectTemplate: 'Selectați Șablonul',
          searchTemplateByName: 'Căutare șablon după nume',
          selectSegments: 'Selectați Grupurile',
          selectTimeSlot: 'Selectați Intervalul de Timp',
          selectValueForThisVariable: 'Selectați valoarea pentru această variabilă',
          searchHeaderByVariableName: 'Căutare antet după nume de variabilă',
          variableFallbackValue: 'Valoare Rezervă pentru Variabilă',
          searchBodyVariableByName: 'Căutare corp după nume de variabilă',
          searchSegmentByName: 'Căutați grup după nume',
          selectBroadcastUsing: 'Selectați uitzendlijstul folosind',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selectați data de început',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Importați Contactele pentru Uitzendlijst prin WhatsApp',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'Număr WhatsApp',
        errorCode: 'Cod Eroare',
        errorMessage: 'Mesaj Eroare',
        nA: 'N/B',
      }
    },
    design: {
      customizeYourLandingPage: 'Personalizați pagina dvs. de aterizare',
      customizeYourConversationalBot: 'Personalizați botul dvs. AI',
      customizeYourBot: 'Personalizați botul dvs.',
      createHeadingTextAndTheme: 'Creați un text de antet și un temă care să reflecte personalitatea mărcii dvs.',
      content: 'Conținut',
      theme: 'Temă',
      layout: 'Aspect',
      landingPageName: 'Nume pagină de aterizare',
      botName: 'Nume bot',
      charactersRemaining: ' caractere rămase',
      landingPageHeader: 'Antet pagină de aterizare',
      landingPageDescription: 'Descriere pagină de aterizare',
      hideContent: 'Ascunde conținut',
      profilePic: 'Imagine de profil',
      selectFile: 'Selectați fișierul',
      socialMediaLinks: 'Link-uri de socializare',
      initialMessage: 'Mesaj inițial al botului de conversație',
      flowTriggerDetail: 'Declanșator pentru colectarea detaliilor de contact',
      selectType: 'Selectați Tipul',
      url: 'URL',
      delete: 'Șterge',
      editLink: 'Editați Link-ul',
      addLink: 'Adăugați Link',
      callToAction: 'Apel la acțiune',
      liveChatWelcomeMessage: 'Mesaj de bun venit la chatul live',
      liveChatTriggerMessage: 'Mesaj declanșator pentru chatul live',
      autoHideCallToAction: 'Ascundere automată a textului Apel la acțiune',
      maxDelayShouldBe300Seconds: 'Durata maximă trebuie să fie de 300 de secunde',
      landingPageThemeColor: 'Culoare temă pagină de aterizare',
      themeColor: 'Culoare temă',
      landingPageBackground: 'Fundal pagină de aterizare',
      chatBackgroundColor: 'Culoare fundal chat',
      color: 'Culoare',
      gradient: 'Gradient',
      image: 'Imagine',
      video: 'Video',
      gradientStart: 'Început gradient',
      gradientEnd: 'Sfârșit gradient',
      //
      positionOnWeb: 'Poziție pe web',
      positionOnMobile: 'Poziție pe mobil',
      windowSize: 'Dimensiune fereastră',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'Personalizat',
      height: 'Înălțime',
      minimum300HeightIsRequired: 'Este necesară o înălțime minimă de 300 de pixeli',
      heightCannotExceed720: 'Înălțimea nu poate depăși 720 de pixeli',
      width: 'Lățime',
      px: 'px',
      minimum200WidthIsRequired: 'Este necesară o lățime minimă de 200 de pixeli',
      widthCannotExceed1050: 'Lățimea nu poate depăși 1050 de pixeli',
      discard: 'Renunță',
      saveChanges: 'Salvează Modificările',
      landingPageBot: 'Bot Pagină de Aterizare',
      chatBot: 'Chatbot',
      preview: 'Previzualizare',
      test: 'Test',
      botIcon: 'Pictogramă Bot',
      websiteBot: 'Chatbot pe Site',
      selectFont: 'Selectați fontul',
      fontLibrary: 'Bibliotecă de fonturi',
      uploadFont: 'Încărcați fontul',
      selectFontForBot: 'Selectați fontul pentru bot',
      uploadedFont: 'Font încărcat',
      placeholders: {
        enterName: 'Introduceți numele',
        enterHeaderText: 'Introduceți textul antetului',
        enterDescription: 'Introduceți descrierea',
        enterUrl: 'Introduceți URL-ul',
        howCanIHelpYou: 'Cum vă pot ajuta?',
        enterYourWelcomeMessageHere: 'Introduceți aici mesajul de bun venit',
        enterYourLiveChatMessageHere: 'Introduceți aici mesajul pentru chatul live',
        enterTimeInSeconds: 'Introduceți timpul în secunde',
        enterHeight: 'Introduceți înălțimea',
        enterWidth: 'Introduceți lățimea',
        flowInitialMessage: 'Introduceți întrebarea inițială',
      },
      appTooltip: {
        standardLayout: 'Aspect standard',
        rightLayout: 'Aspect dreapta',
        mergedLayout: 'Aspect combinat',
        enterNameOfYourBot: 'Introduceți numele botului dvs.',
        enterNameOfYourLandingPageBot: 'Introduceți numele botului de pe pagina de aterizare',
        headingTextThatAppearOnLandingPageBot: 'Introduceți textul antetului care trebuie să apară pe botul de pe pagina de aterizare',
        descriptionTextThatAppearOnLandingPageBot: 'Introduceți textul descrierii care trebuie să apară pe botul de pe pagina ' +
          'de aterizare',
        addLinksAndSocialMediaIcons: 'Adăugați link-uri și pictograme de socializare',
        initialFlowMessage: 'Introduceți primul mesaj pe care botul îl va trimite pentru a iniția conversația cu clientul',
        anInvitingCatchPhrase: 'O expresie invitată care va determina utilizatorul să discute cu botul',
        setAWelcomeMessageForYourVisitors: 'Setați un mesaj de bun venit pentru vizitatorii dvs. Acesta va fi primul mesaj ' +
          'din conversație.',
        setAMessageToInitiateLiveChat: 'Setați un mesaj pentru a iniția chatul live de la capătul vizitatorului. ' +
          'Veți primi o notificare de fiecare dată când acest mesaj este declanșat în oricare dintre conversațiile dvs.',
        autoHideCallToAction: 'Ascunde automat textul "Apel la acțiune" după un timp specificat',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Puteți alege să ascundeți logo-ul și textele și să afișați doar fereastra de chat pe ecran',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Încărcați un logo pentru pagina chatbot-ului.',
        TOOLTIP_CHAT_THEME_COLOR: 'Alegeți culoarea ferestrei de chat a botului dvs.',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Alegeți culoarea de fundal a ferestrei de chat a botului dvs.',
        TOOLTIP_WEB_1ST_POSITION: 'Plasați botul în colțul din stânga jos al desktopului utilizatorului',
        TOOLTIP_WEB_2ND_POSITION: 'Plasați botul în colțul din dreapta sus al desktopului utilizatorului',
        TOOLTIP_WEB_3RD_POSITION: 'Plasați botul în colțul din dreapta jos al desktopului utilizatorului',
        TOOLTIP_MOBILE_1ST_POSITION: 'Plasați botul în colțul din stânga jos al dispozitivului mobil al utilizatorului',
        TOOLTIP_MOBILE_2ND_POSITION: 'Plasați botul în colțul din dreapta sus al dispozitivului mobil al utilizatorului',
        TOOLTIP_MOBILE_3RD_POSITION: 'Plasați botul în colțul din dreapta jos al dispozitivului mobil al utilizatorului',
        TOOLTIP_WINDOW_HEIGHT: 'Modificați dimensiunea ferestrei de chat a botului',
        TOOLTIP_BOT_ICON: 'Selectați o pictogramă sau încărcați un logo/imagini pentru botul dvs.',
        flowTriggerMessage: 'Introduceți numărul de mesaje care trebuie să fie solicitate înainte de a colecta detaliile de contact precum numele, adresa de e-mail și numărul de telefon',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Creați Campanie de Picurare',
      youCanCreateMultipleCampaigns: 'Puteți crea mai multe campanii',
      dripCampaignName: 'Nume Campanie Picurare',
      dripCampaignNameIsReuired: 'Numele Campaniei Picurare este obligatoriu',
      continue: 'Continuați',
      manageDripCampaign: 'Gestionați Campania de Picurare',
      youCanCreateMultipleCampaignsWith: 'Puteți crea mai multe campanii de picurare ' +
        'cu mesaje programate și automate pentru a declanșa răspunsuri din grup de utilizatori specifice.',
      createNewDripCampaign: 'Creați Campanie de Picurare Nouă',
      campaignName: 'Nume Campanie',
      appliedOn: 'Aplicat La',
      segment: 'Grup',
      template: 'Șablon',
      startDate: 'Data de început',
      action: 'Acțiune',
      nA: 'N/A',
      seeHowToUse: 'Vedeți cum să utilizați',
      deleteDripCampaign: 'Ștergeți Campaniile de Picurare',
      wantToDeleteThisDripCampaign: 'Sigur doriți să ștergeți aceste Campanii de Picurare?',
      placeholders: {
        enterDripCampaignName: 'Introduceți Numele Campaniei de Picurare',
        searchByName: 'Căutare după nume'
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Ștergeți mai multe campanii de picurare',
        deleteDripCampaign: 'Ștergeți Campanie de Picurare',
      },
      createDripCampaign: {
        dripCampaignName: 'Nume Campanie Picurare',
        appliedOn: 'Aplicat La',
        segments: 'Grupuri',
        selectDate: 'Selectați Data',
        scheduleYourTemplate: 'Planificați-Vă Șabloanele',
        schedule: 'Programați',
        template: 'Șablon',
        sendAfter: 'Trimite După',
        assignVariablesToTemplate: 'Atribuiți variabile la șabloane',
        addHeaderMedia: 'Adăugați Media Antet ',
        selectFile: 'Selectați Fișier',
        createDripCampaign: 'Creați Campanie de Picurare',
        newSubscriber: 'Abonat Nou',
        minutes: 'Minute',
        hours: 'Ore',
        days: 'Zile',
        placeholders: {
          enterDripName: 'Introduceți numele campaniei de picurare',
          selectAppliedOn: 'Selectați aplicat la',
          selectSegments: 'Selectați Grupurile',
          searchByName: 'Căutare după nume',
          selectTemplate: 'Selectați Șablonul',
          searchTemplateByName: 'Căutare după nume șablon',
          enterSendAfter: 'Introduceți Trimite După',
          selectValueForThisVariable: 'Selectați valoarea pentru această variabilă',
          searchHeaderVariableByName: 'Căutare după nume variabilă antet',
          searchBodyVariableByName: 'Căutare după nume variabilă corp',
        },
        dateTimePicker: {
          selectStartDate: 'Selectați data de început',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Configurați setările generale ale paginii dvs. de aterizare',
      configureBotsGeneralSettings: 'Configurați setările generale ale botului dvs.',
      selectTheTimeZone: 'Selectați fusul orar, limba și o mică notă pentru clienții dvs. când ',
      isNotAvailable: ` nu este disponibil`,
      landingPagesBot: `botul paginii de aterizare`,
      bot: 'bot',
      waitingMessage: 'Mesaj de așteptare',
      landingPageBotStatus: 'Stare Bot Pagină de Aterizare',
      chatBotStatus: 'Stare Chatbot',
      unavailabilityMessage: 'Mesaj de Indisponibilitate',
      sendIncompleteResponses: 'Trimite Răspunsuri Incomplete',
      language: 'Limba',
      invalidateCache: 'Invalidați Cache-ul',
      timezone: 'Fus Orar',
      chatWindowMessageHistory: 'Istoric Mesaje Fereastră de Chat',
      session: 'sesiune',
      forever: 'pentru totdeauna',
      english: 'Engleză',
      spanish: 'Spaniolă',
      french: 'Franceză',
      portuguese: 'Portugheză',
      german: 'Germană',
      italian: 'Italiană',
      swedish: 'Suedeză',
      arabic: 'Arabă',
      malay: 'Malaeziană',
      russian: 'Rusă',
      discard: 'Renunță',
      saveChanges: 'Salvează Modificările',
      seeHowToUse: 'Aflați cum să utilizați',
      placeholders: {
        enterYourWaitingMessageHere: 'Introduceți mesajul dvs. de așteptare aici',
        //
        searchTimeZone: 'Căutați fusul orar',
        //
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalizați mesajul așa cum doriți să apară imediat pe fereastra de chat după ce un utilizator al botului trimite o cerere pentru Chat Live',
        TOOLTIP_CHATBOT_STATUS: 'Activează / dezactivează botul',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Personalizați mesajul așa cum doriți să apară pe fereastra de chat atunci când niciunul dintre agenții dvs. nu este disponibil pentru Chat Live cu utilizatorul botului',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Trimiteți notificări prin e-mail chiar și atunci când utilizatorul botului nu a finalizat conversația.',
        TOOLTIP_LANGUAGE: 'Selectați limba botului',
        TOOLTIP_INVALIDATE_CACHE: 'Faceți clic aici pentru a invalida cache-ul atunci când editați fluxul de chat al botului sau setările, deoarece modificările se reflectă doar după 15 minute',
        TOOLTIP_TIME_ZONE: 'Selectați fusul orar pentru botul dvs.',
        TOOLTIP_CHAT_RETENTION: 'Selectați dacă doriți să păstrați conversațiile utilizatorilor botului pentru totdeauna în fereastra de chat sau doar pentru o sesiune a browserului.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Personalizați fereastra de chat',
      controlAndCustomizeTheChatWindow: 'Controlați și personalizați fereastra de chat cu care clienții dvs. vor interacționa.',
      allowVisitorsToTransferChatToWhatsapp: 'Permiteți vizitatorilor să transfere chatul pe WhatsApp',
      allowVisitorsToRefreshChat: 'Permiteți vizitatorilor să reîmprospăteze chatul',
      displayNameForTransferringChatToWhatsapp: 'Nume afișat pentru transferarea unui chat pe WhatsApp',
      displayNameForTransferringToLiveChat: 'Nume afișat pentru transfer la Chat Live/Agent',
      displayNameForTransferringAChatToBot: 'Nume afișat pentru transferarea unui chat la Bot',
      discard: 'Anulați',
      saveChanges: 'Salvați Modificările',
      allowVisitorsToTransferChatToBot: 'Permiteți vizitatorilor să transfere chatul la Bot.',
      allowVisitorsToRequestForLiveChat: 'Permiteți vizitatorilor să solicite chat live.',
      indicativeTextForCustomersReply: 'Text indicativ pentru răspunsul clienților',
      displayNameForRestartingChatBot: 'Nume afișat pentru repornirea conversației cu Chatbot-ul',
      seeHowToUse: 'Vezi cum se utilizează',
      placeholders: {
        enterButtonNameForWhatsapp: 'Introduceți numele butonului pentru transferul pe WhatsApp',
        enterButtonNameForLiveChat: 'Introduceți numele butonului pentru transferul la chat live sau agent',
        enterButtonNameForBot: 'Introduceți numele butonului pentru transferul la Bot',
        enterTextDisplayInEmptyReplyArea: 'Introduceți textul afișat în zona de răspuns gol',
        enterButtonForRestartingConversation: 'Introduceți numele butonului pentru repornirea conversației',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Permiteți vizitatorilor site-ului să transfere chatul pe WhatsApp către agentul respectiv cu care sunt conectați. Funcția Chat Live trebuie să fie activată pentru ca această funcție să funcționeze.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Permiteți utilizatorilor bot să reîmprospăteze chatul astfel încât să poată începe conversația din nou.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Permiteți utilizatorilor bot să transfere chatul la orice moment în timpul conversației cu agentul de chat live.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Permiteți utilizatorilor bot să solicite chat live cu agentul ori de câte ori doresc.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Dacă ați activat utilizatorul pentru a transfera la WhatsApp, completați cum doriți să afișați această opțiune',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Dacă ați activat utilizatorul pentru a transfera la chat live, completați cum doriți să afișați această opțiune',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Dacă ați activat utilizatorul pentru a transfera la WhatsApp, completați cum doriți să reveniți la bot',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Completați cum doriți să afișați spațiul pentru răspuns pentru clienți',
        ifYouHaveEnabledUserToRestartChatBot: 'Dacă ați activat utilizatorul pentru a reporni conversația cu chatbot-ul, completați cum doriți să afișați această opțiune',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Fluxuri de chat',
      dripCampaignHeader: 'Campanie de picurare',
      widgetsHeader: 'Widget-uri',
      broadcastHeader: 'Transmisie',
      ordersHeader: 'Comenzi',
      manageHeader: 'Gestionare',
      inputHeader: 'Intrare',
      apiKeyHeader: 'Cheie API',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Creați un proces de integrare a clienților folosind o campanie de picurare cu fluxuri de chat programate.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizați șabloanele de flux de chat și creați mai multe fluxuri de chat pentru diferite audiențe țintă.',
        TOOLTIP_WIDGET_TITLE: 'Creați widget-uri (interfețe de chat) și integrați-le în paginile web promoționale pentru a direcționa vizitatorii către bot.',
        TOOLTIP_BROADCAST_TITLE: 'Trimiteți mesaje sau fluxuri către contactele dvs. cu un singur clic la ora pre-programată.',
        TOOLTIP_ORDERS_TITLE: 'Listă de comenzi plasate de clienți.',
        TOOLTIP_MANAGE_TITLE: 'Gestionarea funcționalității botului pentru contacte/lead-uri și cuvinte cheie.',
        TOOLTIP_INPUT_TITLE: 'Creați șabloane WhatsApp, imagini și text ca material de răspuns pentru contacte/lead-uri.',
      },
      apiWrapper: {
        whatsappApiCredentials: 'Credențiale API WhatsApp',
        generateYourWhatsappApiKey: 'Generați cheia dvs. API WhatsApp pentru a o integra în aplicațiile dezvoltate personalizate.',
        generateApiKey: 'Generați cheia API',
        webhookUrl: 'URL webhook',
        addHeader: 'Adăugați antet',
        test: 'Testați',
        webhookNotSet: 'Webhookul nu este setat',
        webhookHeaders: 'Antete webhook',
        setWebhookUrl: 'Setați URL-ul webhook',
        placeholders: {
          yourApiKey: 'Cheia API a dvs.',
          enterWebhookUrl: 'Introduceți URL-ul webhook',
          enterHeaderKey: 'Introduceți cheia antetului',
          enterValue: 'Introduceți Valoarea',
        }
      },
      broadcast: {
        createBroadcast: 'Creați transmitere',
        createMultipleBroadcasts: 'Puteți crea mai multe transmisii cu fluxuri/sabloane programate pentru diferite grup de clienți.',
        broadcastName: 'Nume transmitere',
        nameIsRequired: 'Numele este obligatoriu',
        selectType: 'Selectați Tipul',
        selectYourFlowType: 'Selectați tipul fluxului dvs.',
        segment: 'Grup',
        selectYourSegmentType: 'Selectați tipul grupului dvs.',
        whenToSend: 'Când să trimiteți',
        selectDate: 'Selectați Data',
        messageTag: 'Etichetă mesaj',
        selectMessageTag: 'Selectați eticheta mesajului',
        contentType: 'Tipul Conținutului',
        nonPromotional: 'Non-promoțional (etichete de mesaje aplicate)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Etichetați fiecare mesaj care vine înaintea oricărei interacțiuni sau ' +
          'după pașii de întârziere care depășesc 24 de ore cu eticheta corespunzătoare. Mesajele netaguite nu vor ajunge la ' +
          'abonații care au interacționat cu botul acum mai mult de 24 de ore.',
        promotional: 'Promoțional (etichete de mesaje ignorate)',
        theseBroadcasts: 'Aceste transmisii pot conține promoții, dar publicul țintă este limitat la abonații care au interacționat ' +
          'cu botul în ultimele 24 de ore.',
        createNewBroadcast: 'Creați o nouă transmitere',
        sentMessages: 'Mesaje trimise',
        deliveredMessages: 'Mesaje livrate',
        readMessages: 'Mesaje citite',
        failedMessages: 'Mesaje eșuate',
        //
        type: 'Tip',
        template: 'Șablon',
        sent: 'Trimis',
        delivered: 'Livrat',
        read: 'Citit',
        failed: 'Eșuat',
        broadcastAt: 'Transmis la',
        status: 'Stare',
        shipmentUpdate: 'Actualizare expediție',
        //
        flow: 'Flux',
        resendFailedMessages: 'Retrimiteți mesajele eșuate',
        //
        //
        goBack: 'Înapoi',
        number: 'Număr',
        errorCode: 'Cod eroare',
        failedAt: 'Eșuat la',
        badGateway: '502 gateway nefuncțional',
        loadMore: 'Încărcați mai mult',
        placeholders: {
          enterBroadcastName: 'Introduceți numele transmisiei',
          searchBroadcastByName: 'Căutați transmisia după nume',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Total mesaje de transmitere trimise',
          totalBroadcastMessagesDelivered: 'Total mesaje de transmitere livrate',
          totalBroadcastMessagesReadByContacts: 'Total mesaje de transmitere citite de contacte',
          totalFailedBroadcastMessages: 'Total mesaje de transmitere eșuate',
          clickToViewDetailedInformationOfFailedMessages: 'faceți clic pentru a vizualiza informațiile detaliate ale mesajelor eșuate',
          clickToCreateNewBroadcast: 'Faceți clic pentru a crea o nouă transmitere',
          templateUsedInBroadcast: 'Șablon utilizat în transmitere',
          numberOfBroadcastMessagesSent: 'Numărul de mesaje de transmitere trimise',
          numberOfBroadcastMessagesDelivered: 'Numărul de mesaje de transmitere livrate',
          numberOfBroadcastMessagesReadByContacts: 'Numărul de mesaje de transmitere citite de contacte',
          numberOfTimesBroadcastMessagesHasFailed: 'Numărul de ori când mesajele de transmitere au eșuat',
          contactNumberOnWhichMessageDeliveryFailed: 'Numărul de contact la care a eșuat livrarea mesajului',
          unDeliveredTemplate: 'Șablon ne-livrat',
          errorReceivedForFailedMessage: 'Eroare primită pentru mesajul eșuat',
          timeWhenMessageDeliveryFailed: 'Timpul când livrarea mesajului a eșuat',
          TOOLTIP_ADD_BROADCAST_NAME: 'Introduceți un nume nou pentru transmitere',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Selectați dacă trimiteți un flux sau un mesaj.',
          TOOLTIP_BROADCAST_FLOW: 'Selectați fluxul de chat pe care doriți să îl transmiteți.',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Selectați grupul de contacte către care doriți să faceți o difuzare',
          TOOLTIP_BROADCAST_NAME: 'Numele transmiterii',
          TOOLTIP_BROADCAST_TYPE: 'Fluxul sau mesajul selectat pentru transmitere',
          TOOLTIP_BROADCAST_SEGMENT: 'Grupul pentru care este creată difuzarea',
          TOOLTIP_BROADCAST_AT: 'Data și ora programate pentru transmitere',
          TOOLTIP_BROADCAST_STATUS: 'Starea transmiterii ca programată, în desfășurare sau finalizată',
        }
      },
      drip: {
        createDripCampaign: 'Creează Campanie Drip',
        youCanCreateMultipleCampaigns: 'Puteți crea mai multe campanii',
        dripCampaignName: 'Nume Campanie Drip',
        dripCampaignNameIsRequired: 'Numele campaniei drip este obligatoriu',
        //
        youCanCreateMultipleDripCampaigns: 'Puteți crea mai multe campanii drip cu mesaje programate și automate pentru a genera răspunsuri din grup specifice de utilizatori.',
        campaignName: 'Nume Campanie',
        campaignNameIsRequired: 'Numele campaniei este obligatoriu',
        after: 'După ',
        thisMessageWillBeSendAfter: 'Acest mesaj va fi trimis după',
        saveAndUpdate: 'Salvează și Actualizează',
        schedule: ' Programează',
        select: 'Selectează',
        messages: 'Mesaje',
        cancel: 'Anulează',
        createCampaign: 'Creează Campanie',
        updateCampaign: 'Actualizează Campanie',
        createNewCampaign: 'Creează Campanie Nouă',
        //
        messagesSent: 'Mesaje trimise',
        createdAt: 'Creat la',
        updatedAt: 'Actualizat la',
        action: 'Acțiune',
        placeholders: {
          nameYourDripCampaign: 'Denumiți campania drip',
          enterCampaignName: 'Introduceți numele campaniei',
          searchCampaignByName: 'Caută campanie după nume',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Faceți clic pentru a adăuga o nouă campanie',
          TOOLTIP_SCHEDULE: 'Planificați când și care flux de chat să fie trimis primul în această campanie.',
          TOOLTIP_AFTER_DAYS: 'Programați când să trimiteți primul flux al campaniei în minute, ore sau zile.',
          TOOLTIP_ADD_MESSAGES: 'Faceți clic pentru a adăuga un nou flux la campanie',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Introduceți un nou nume de campanie',
          TOOLTIP_CAMPAIGN_NAME: 'Numele campaniei',
          TOOLTIP_NO_MESSAGES_SENT: 'Numărul de mesaje trimise de către campanie',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Data și ora creării campaniei',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Data și ora ultimei modificări a campaniei',
          TOOLTIP_CAMPAIGN_ACTION: 'Editați sau ștergeți campania',
          TOOLTIP_UPDATE_CAMPAIGN: 'Faceți clic pentru a salva modificările efectuate în configurația campaniei',
        }
      },
      flows: {
        createChatFlow: 'Creează Flux de Chat',
        youCanCreateMultipleConversationsFlow: 'Puteți crea fluxuri de conversații multiple pe mai multe canale pentru teste A/B și difuzări.',
        flowName: 'Nume Flux',
        nameIsRequired: 'Numele este obligatoriu',
        cancel: 'Anulează',
        createFlow: 'Creează Flux',
        createNewFlow: 'Creează Flux Nou',
        //
        ofMessages: 'de mesaje',
        triggered: 'Activat',
        stepsFinsished: 'Pași finalizați',
        finished: 'Finalizat',
        createdOn: 'Creat pe',
        lastModified: 'Ultima modificare',
        defaultFlow: 'Flux implicit',
        action: 'Acțiune',
        flowAnalytics: 'Analize Flux',
        placeholders: {
          enterFlowName: 'Introduceți numele fluxului',
          searchFlowByName: 'Caută flux după nume',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'Numărul de ori când fluxul de chat este activat',
          numberOfCompletedChatFlowStepsWhenTriggered: 'Numărul de pași de chat finalizați când este activat',
          numberOfTimesUsersCompletedChatFlowSteps: 'Numărul de ori când utilizatorii au finalizat toți pașii fluxului de chat',
          dateAndTimeOfCreationOfChatFlow: 'Data și ora creării fluxului de chat',
          dateAndTimeWhenChatFlowLastModified: 'Data și ora ultimei modificări a fluxului de chat',
          TOOLTIP_CREATE_FLOW: 'Faceți clic pentru a crea un nou flux de chat',
          TOOLTIP_FLOW_NAME: 'Introduceți numele fluxului de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Numele fluxului de chat',
          TOOLTIP_NO_OF_MESSAGES: 'Numărul de componente de mesaj în fluxul de chat',
          TOOLTIP_DEFAULT_FLOW: 'Folosiți această comutare pentru a seta un flux de chat ca implicit, care va fi furnizat tuturor utilizatorilor noi.',
          TOOLTIP_ACTION: 'Editați sau ștergeți fluxul de chat',
        }
      },
      manage: {
        leads: 'Prospecte',
        manageBotsFunctionalityForContacts: 'Gestionați funcționalitatea bot-ului pentru contacte/prospecte',
        whenToSendLeadInfo: 'Când să trimiteți informații despre prospect',
        smartFlow: 'Flux Inteligent',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Mesaj de bun venit atunci când nu se găsește nicio potrivire de cuvinte cheie',
        selectTemplates: 'Selectați Șabloane',
        selectFlows: 'Selectați Fluxuri',
        selectText: 'Selectați Text',
        selectImages: 'Selectați Imagini',
        whenItAlmostReaches24HoursSinceMessage: 'Când aproape că trec 24 de ore de la ultimul mesaj',
        //
        updateChanges: 'Actualizați Modificările',
        manageBotsFunctionalityAccordingToKeywords: 'Gestionați funcționalitatea bot-ului în funcție de cuvintele cheie introduse de utilizatorul bot-ului, în cazul fluxului inteligent',
        addKeywordAction: 'Adăugați Acțiune cuvânt cheie',
        keywords: 'Cuvinte Cheie',
        matchingMethod: 'Metoda de Potrivire',
        replyMaterial: 'Material Răspuns',
        action: 'Acțiune',
        select: 'Selectați',
        placeholders: {
          keyword: 'cuvânt cheie'
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Sugerați dacă bot-ul trebuie să trimită imediat informații de contact/prospect către CRM-ul de integrare, sau după 5 minute, sau la sfârșitul fluxului',
          TOOLTIP_LEAD_SMART_FLOW: 'Alegeți un flux implicit pentru a-l trimite tuturor utilizatorilor bot sau fluxul inteligent (determinat de cuvintele cheie introduse de utilizatorul bot-ului).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Selectați materialul de răspuns (flux, șablon, text, imagine) pentru a trimite dacă bot-ul primește un răspuns fără potrivire de cuvinte cheie din partea utilizatorului bot-ului',
          TOOLTIP_IT_ALMOST_REACHED: 'Selectați materialul de răspuns pentru a trimite contactelor/prospectelor chiar înainte de 24 de ore de la ultimul mesaj',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Gestionați funcționalitatea bot-ului în funcție de cuvintele cheie introduse de utilizatorul bot-ului, în cazul fluxului inteligent',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Selectați răspunsul bot-ului ca șablon, flux, text sau imagine în funcție de cuvintele cheie specifice introduse',
          TOOLTIP_ADD_KEYWORD: 'Adăugați cuvinte cheie',
          TOOLTIP_MATCHING_METHOD: 'Adăugați potrivire de cuvinte cheie ca "exact" sau "conține"',
          TOOLTIP_REPLY_MATERIAL: 'Selectați șablon, flux, text sau imagine pentru a răspunde la cuvântul cheie sugerat',
          TOOLTIP_KEYWORD_ACTIONS: 'Ștergeți acțiunea cuvântului cheie',
        },
        socialAlerts: {
          alerts: 'Alerte',
          specifyHowYourBotWillNotify: 'Specificați cum bot-ul vă va notifica cu privire la noile perspective.'
        }
      },
      orders: {
        manageCustomersOrder: 'Gestionați Comenzile Clienților',
        checkAndManageAllOfYourCustomerOrders: 'Verificați și gestionați toate comenzile clienților și actualizările.',
        selectShipmentTemplate: 'Selectați Șablonul de Livrare',
        send: 'Trimite',
        export: 'Export',
        phoneNumber: 'Număr de Telefon',
        numberOfItems: 'Număr de Articole',
        orderValue: 'Valoare Comandă',
        orderDate: 'Data Comenzii',
        status: 'Status',
        chat: 'Chat',
        item: 'Articol',
        quantity: 'Cantitate',
        amount: 'Sumă',
        unitPrice: 'Preț Unitar',
        placeholders: {
          searchOrdersByPhoneNumber: 'Căutați comenzi după numărul de telefon',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Exportați datele comenzilor în fișierul CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'Numărul de telefon al clientului care a plasat comanda',
          TOOLTIP_NO_OF_ITEMS: 'Numărul de articole comandate',
          TOOLTIP_ORDER_VALUE: 'Valoarea comenzii',
          TOOLTIP_ORDER_DATE: 'Comanda a fost plasată în această dată.',
          TOOLTIP_ORDER_STATUS: 'Statusul comenzii ca fiind confirmat, în așteptare, respins sau livrat',
          TOOLTIP_ORDER_CHAT: 'Toate conversațiile anterioare prin chat referitoare la această comandă',

        }
      },
      widgets: {
        installWidgetHeader: 'Instalați Widgetul',
        createWidgetHeader: 'Creați Widgetul',
        copyTheCodeAndPasteItWhereYouWant: 'Copiați codul și lipiți-l oriunde aveți nevoie în HTML-ul dumneavoastră, pe site, etc.',
        youCanCreateMultipleWidgets: 'Puteți crea mai multe widgeturi pentru a oferi bot-ului dumneavoastră o aparență diferită pe pagini web diferite.',
        installWidget: {
          javascript: 'JavaScript',
          install: 'Instalați',
          onYourWebsite: ' pe site-ul dumneavoastră',
          copyCode: 'Copiați codul',
          copyAndPaste: 'Copiați și lipiți',
          copyThisCodeSnippet: 'Copiați acest fragment de cod și inserați-l în HTML-ul fiecărei pagini web ' +
            'în care doriți să afișați Widgetul. Pentru mai multe detalii',
          seeGuide: 'vedeți ghidul',
        },
        widgetList: {
          widgetName: 'Numele Widgetului',
          nameIsRequired: 'Numele este obligatoriu',
          cancel: 'Anulare',
          createWidget: 'Creați Widgetul',
          icon: 'Pictogramă',
          name: 'Nume',
          createdAt: 'Creat La',
          action: 'Acțiune',
          placeholders: {
            enterWidgetName: 'Introduceți numele widgetului',
            searchWidgetByName: 'Căutați widgetul după nume',
          },
          appTooltip: {
            widgetIcon: 'Pictogramă widget',
            nameOfWidget: 'Numele widgetului',
            dateAndTimeOfCreationOfWidget: 'Data și ora creării widgetului',
            editOrDeleteWidget: 'Editați sau ștergeți widgetul',
          }
        },
        widgetTemplates: {
          widgetName: 'Numele Widgetului',
          nameIsRequired: 'Numele este obligatoriu',
          chooseTypeOfWidget: 'Alegeți tipul de Widget',
          bar: 'Bară',
          slideIn: 'Glisant',
          pageTakeOver: 'Preluare Pagină',
          button: 'Buton',
          facebookModal: 'Modal Facebook',
          comment: 'Comentariu',
          direct: 'Direct',
          whatsappIcon: 'Pictogramă WhatsApp',
          refUrl: 'URL Ref',
          qrCode: 'Cod QR',
          //
          backgroundColor: 'Culoarea Fundalului',
          descriptionColor: 'Culoarea Descrierii',
          headlineColor: 'Culoarea Titlului',
          buttonBackgroundColor: 'Culoarea Fundalului Butonului',
          contactNumber: 'Număr de Contact',
          entryMessage: 'Mesaj de Intrare',
          buttonText: 'Textul Butonului',
          selectButtonText: 'Selectați Textul Butonului',
          buttonSize: 'Dimensiunea Butonului',
          selectOne: 'Selectați Unul',
          placement: 'Plasare',
          rightBottom: 'Dreapta-Jos',
          optInFlow: 'Flux Opt-in',
          selectFlow: 'Selectați Fluxul',
          addSubscriberToSequence: 'Adăugați Abonat la Secvență',
          selectDrip: 'Selectați Dripul',
          whenDoesItDisplay: 'Când se afișează (Secunde)',
          showWidgetToSameUserAgainAfter: 'Afișați widgetul aceluiași utilizator din nou după',
          ifManuallyClosedByUser: 'Dacă este închis manual de către utilizator, afișați după',
          //
          specificPosts: 'Postări Specifice',
          allPosts: 'Toate Postările',
          //
          postMessage: 'Mesaj de Postare',
          postDate: 'Data Postării',
          status: 'Status',
          action: 'Acțiune',
          discard: 'Renunțați',
          saveChanges: 'Salvați Modificările',
          nextStep: 'Pasul Următor',
          widgetDescription: 'Descrierea Widgetului',
          integrateTheBarWidget: 'Integrați Widgetul Bar în corpul site-ului dvs. și creșteți numărul de abonați la pagină' +
            ' împreună cu trimiterea fluxului de mesaje personalizate prin bot.',
          hereIsYourWidgetHeadline: 'Iată titlul widgetului dvs., faceți clic aici pentru a-l schimba.',
          sendUsMessage: 'Trimiteți-ne un Mesaj',
          weAlsoPutDefaultTextHere: 'Punem aici și textul implicit. Asigurați-vă că îl transformați într-un mesaj valoros',
          selectYourPagePost: 'Selectați Postarea Dvs. pe Pagină',
          nA: 'N/A',
          //
          saveRule: 'Salvați Regula',
          placeholders: {
            enterWidgetName: 'Introduceți numele widgetului',
            enterNumber: 'Introduceți Numărul',
            enterMessageHere: 'Introduceți mesajul aici',
            enterTimeInSeconds: 'Introduceți timpul în secunde',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: `Personalizați setările de declanșare ale botului dvs.`,
      makeYourBotsTimingPerfect: `Faceți momentul botului dvs. perfect.`,
      onDesktopTriggerTime: 'Timp declanșare pe desktop (secunde)',
      onMobileTriggerTime: 'Timp declanșare pe mobil (secunde)',
      chatBubble: 'Deschidere automată a balonului de chat',
      seconds: 'Secunde',
      discard: 'Renunțați',
      saveChanges: 'Salvați Modificările',
      autoTriggerOnOrOff: 'Declanșare automată: Activată/Dezactivată',
      seeHowToUse: 'Aflați cum să utilizați',
      alwaysOpenAfter: 'Deschideți întotdeauna după declanșările specificate mai sus',
      automaticallyAfterSelectedNumberOfTime: 'Automat după un interval de timp selectat',
      neverAutomaticallyOpenBot: 'Nu deschideți niciodată automat botul',
      placeholders: {
        enterTimeInSeconds: 'Introduceți timpul în secunde',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Timpul de așteptare în secunde pe dispozitivele desktop ale vizitatorului, înainte ca botul dvs. să apară automat',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Timpul de așteptare în secunde pe dispozitivele mobile ale vizitatorului, înainte ca botul dvs. să apară automat',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Când vizitatorul închide fereastra de chat, selectați cum ar trebui să fie comportamentul automat de pop-up al botului',
        customerWaitingTimeToOpenBubble: 'Timpul de așteptare al clientului pentru a deschide balonul de chat',
        wantToDisableAutoTriggering: 'Faceți clic aici dacă doriți să dezactivați declanșarea automată a botului pe paginile web'
      }
    },
    userAccess: {
      customizeYourBotsSettings: `Personalizați setările de utilizator ale botului dvs.`,
      decideWhoCanAccessYourBot: 'Decideți cine poate accesa botul dvs.',
      deviceSupport: 'Suport Dispozitive',
      blockIpAddresses: 'Blocați Adresele IP',
      hideChatBotOnSpecificPages: 'Ascundeți Chatbotul pe anumite pagini',
      selectCountriesToEnableBot: 'Selectați țările pentru a permite botul',
      discard: 'Renunțați',
      saveChanges: 'Salvați Modificările',
      seeHowToUse: 'Aflați cum să utilizați',
      allDevices: 'Toate Dispozitivele',
      mobileOnly: 'Doar Mobil',
      desktopOnly: 'Doar Desktop',
      placeholders: {
        enterIpAddress: `Introduceți adresa IP (ipv4 sau ipv6) și apăsați 'Enter'`,
        enterUrl: `Introduceți adresa URL a paginilor și apăsați 'Enter'`,
        searchCountry: 'Căutați țara',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Selectați pe ce dispozitive ar trebui să apară botul dvs.',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Introduceți adresele IP unde nu doriți ca botul să apară',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Introduceți adresele URL ale paginilor web unde nu doriți ca botul să apară',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Selectați locațiile de unde doriți ca utilizatorii să aibă acces la botul dvs.',
      }
    },
    facebook: {
      facebookBotSettings: 'Setări Bot Facebook',
      chatFlowsHeader: 'Fluxuri de Conversație',
      dripCampaignHeader: 'Campanie de Picurare',
      broadcastHeader: 'Difuzare',
      widgetsHeader: 'Widget-uri',
      autoReplyHeader: 'Auto-Respins',
      generalHeader: 'General',
      facebookAutomation: 'Automatizare Facebook',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizați șabloanele de flux de conversație și creați mai multe fluxuri de conversație pentru diferite audiențe țintă.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Creați o călătorie de onboardare a clienților folosind o campanie de picurare a fluxurilor de conversație programate.',
      },
      broadcasts: {
        createBroadcast: 'Creați Difuzare',
        youCanCreateMultipleBroadcasts: 'Puteți crea mai multe difuzări',
        broadcastName: 'Nume Difuzare',
        broadcastNameIsRequired: 'Numele difuzării este obligatoriu',
        continue: 'Continuați',
        manageBroadcasts: 'Gestionați Difuzările',
        youCanCreateMultipleBroadcastsWith: 'Puteți crea mai multe difuzări cu mai multe șabloane programate pentru diferite grup de clienți.',
        createNewBroadcast: 'Creați Difuzare Nouă',
        name: 'Nume',
        segment: 'Grup',
        template: 'Șablon',
        contacts: 'Contacte',
        send: 'Trimite',
        failed: 'Eșuat',
        broadcastAt: 'Difuzat La',
        status: 'Stare',
        actions: 'Acțiuni',
        nA: 'NA',
        deleteBroadcast: 'Ștergeți Difuzarea',
        wantToDeleteThisBroadcast: 'Sunteți sigur că doriți să ștergeți această Difuzare?',
        placeholders: {
          enterBroadcastName: 'Introduceți numele difuzării',
          searchHere: 'Căutare aici',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Ștergeți mai multe difuzări',
          deleteBroadcast: 'Ștergeți Difuzarea',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Nume Difuzare',
        broadcastNameIsRequired: 'Numele difuzării este obligatoriu',
        flow: 'Flux',
        segments: 'Grup',
        whenToSend: 'Când să trimiteți',
        selectDate: 'Selectați Data',
        selectTimeSlot: 'Selectați Intervalul de Timp',
        createBroadcast: 'Creați Difuzare',
        cancel: 'Anulare',
        now: 'Acum',
        later: 'Mai Târziu',
        placeholders: {
          enterBroadcastName: 'Introduceți numele difuzării',
          selectFlow: 'Selectați Fluxul',
          searchFlowByName: 'Căutați fluxul după nume',
          selectSegments: 'Selectați Grupurile',
          searchSegmentByName: 'Căutați grup după nume',
          selectTimeSlot: 'Selectați Intervalul de Timp',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selectați data de început',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Creați Campanie de Picurare',
        youCanCreateMultipleCampaign: 'Puteți crea mai multe campanii de picurare',
        dripCampaignName: 'Nume Campanie de Picurare',
        dripCampaignNameIsRequired: 'Numele Campaniei de Picurare este obligatoriu',
        continue: 'Continuați',
        manageDripCampaign: 'Gestionați Campaniile de Picurare',
        youCanCreateMutlipleDripCampaign: 'Puteți crea mai multe campanii de picurare cu mesaje automate programate pentru a declanșa răspunsuri din grup specifice de utilizatori.',
        createNewDripCampaign: 'Creați o Campanie Nouă de Picurare',
        campaignName: 'Nume Campanie',
        appliedOn: 'Aplicat Pe',
        segment: 'Grup',
        flow: 'Flux',
        startDate: 'Data de Început',
        action: 'Acțiune',
        nA: 'NA',
        deleteDripCampaign: 'Ștergeți Campaniile de Picurare',
        wantToDeleteThisDripCampaign: 'Sunteți sigur că doriți să ștergeți această Campanie de Picurare?',
        placeholders: {
          enterDripCampaignName: 'Introduceți Numele Campaniei de Picurare',
          searchByName: 'Căutare După Nume',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Ștergeți mai multe campanii de picurare',
          deleteDripCampaign: 'Ștergeți Campania de Picurare',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Nume Campanie de Picurare',
          appliedOn: 'Aplicat Pe',
          segments: 'Grupuri',
          selectDate: 'Selectați Data',
          scheduleYourFlows: 'Programați Fluxurile Dvs.',
          schedule: 'Programare',
          flow: 'Flux',
          sendAfter: 'Trimite După',
          cancel: 'Anulare',
          newSubscriber: 'Abonat Nou',
          days: 'Zile',
          minutes: 'Minute',
          hours: 'Ore',
          createDripCampaign: 'Creați Campanie de Picurare',
          placeholders: {
            enterDripName: 'Introduceți Numele Campaniei de Picurare',
            selectAppliedOn: 'Selectați Aplicat Pe',
            selectSegment: 'Selectați Grupurile',
            searchByName: 'Căutare După Nume',
            selectTemplate: 'Selectați Șablonul',
            searchFlowByName: 'Căutați Fluxul După Nume',
            enterSendAfter: 'Introduceți Trimite După',
          },
          dateTimePicker: {
            selectStartDate: 'Selectați data de început',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'ID Conversație',
        errorCode: 'Cod Eroare',
        errorMessage: 'Mesaj Eroare',
        nA: 'NA',

      },
      flows: {
        createChatFlow: 'Creați Flux de Conversație',
        youCanCreateMultipleChatFlows: 'Puteți crea fluxuri de conversație multiple pe mai multe canale pentru testele A/B și difuzări.',
        flowName: 'Nume Flux',
        nameIsRequired: 'Numele este obligatoriu',
        cancel: 'Anulare',
        createFlow: 'Creați Flux',
        importFlow: 'Importați Flux',
        createNewFlow: 'Creați un Flux Nou',
        ofMessages: 'de mesaje',
        createdOn: 'Creat pe',
        lastModified: 'Ultima modificare',
        defaultFlow: 'Flux implicit',
        action: 'Acțiune',
        exportStatus: 'Stare Export',
        flowExport: 'Export Flux',
        download: 'Descărcare',
        // importFlow: 'Import Flow'
        beforeYouStartUploadPleaseMakeSure: 'Înainte de a începe încărcarea, asigurați-vă că',
        point1: '1. Limita maximă de dimensiune a fișierului pentru încărcare este de 1 MB',
        point2: '2. Formatul fișierului trebuie să fie JSON',
        selectFile: 'Selectați Fișierul',
        inProgress: 'în desfășurare',
        available: 'Disponibil',
        initiated: 'Inițiat',
        placeholders: {
          enterFlowName: 'Introduceți numele fluxului',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Importați fluxul pentru botul de pe Facebook',
          dateAndTimeOfCreationOfChatFlow: 'Data și ora creării fluxului de conversație',
          dateAndTimeWhenChatFlowLastModified: 'Data și ora ultimei modificări a fluxului de conversație',
          TOOLTIP_CREATE_FLOW: 'Faceți clic pentru a crea un flux de conversație nou',
          TOOLTIP_FLOW_NAME: 'Introduceți numele fluxului de conversație',
          TOOLTIP_FLOW_TABLE_NAME: 'Numele fluxului de conversație',
          TOOLTIP_NO_OF_MESSAGES: 'Numărul de componente de mesaj în fluxul de conversație',
          TOOLTIP_DEFAULT_FLOW: 'Utilizați această comutare pentru a seta un flux de conversație ca implicit, care va fi servit tuturor utilizatorilor noi.',
          TOOLTIP_ACTION: 'Editați sau ștergeți fluxul de conversație',
        },
        noDataFound: {
          flows: 'Fluxuri',
          noFlowsAddedYet: 'Niciun Flux adăugat încă!',
        },
        modal: {
          deleteFlow: 'Ștergeți Fluxul',
          wantToDeleteThisFlow: 'Sunteți sigur că doriți să ștergeți acest Flux?',
        }
      },
      widgets: {
        manageWidgets: 'Gestionați Widget-urile',
        widgetsAreRoutingTools: 'Widget-urile sunt instrumente de rutare pentru a crește utilizarea botului',
        needHelp: 'Nevoie de Ajutor?',
        wantToMakeChanges: 'Doriți să faceți modificări?',
        editIcon: 'Editați Pictograma',
        discard: 'Renunțați',
        updateWidget: 'Actualizați Widget-ul',
        installChatBotIconOnYourWebsite: 'Instalați pictograma chatbot pe site-ul dvs.',
        shareWhereEverYouWantToRedirectUsersTo: 'Partajați oriunde doriți și redirecționați utilizatorii către botul dvs. de pe Facebook printr-un singur clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copiați și adăugați în fișierul antetului site-ului dvs.',
        copyLinkAndStartSharing: 'Copiați linkul și începeți partajarea!',
        downloadQrCodeAndStartSharing: 'Descărcați codul QR și începeți partajarea!',
        addCodeAndDeployNewCodeAndDone: 'Adăugați codul, implementați noul cod și Gata!',
        wantToLearnHowToUseIt: 'Doriți să învățați cum să îl utilizați?',
        copyChatBotCode: 'Copiați Codul Chatbot',
        copyFacebookLink: 'Copiați Linkul Facebook',
        downloadQrCode: 'Descărcați Codul QR',
        link: 'Link',
        chatBotIcon: 'Pictograma Chatbot',
        qrcode: 'Cod QR',
        small: 'Mic',
        medium: 'Mediu',
        large: 'Mare',
        leftMiddle: 'Stânga-Mijloc',
        rightMiddle: 'Dreapta-Mijloc',
        leftBottom: 'Stânga-Jos',
        rightBottom: 'Dreapta-Jos',
        bpDropDown: {
          selectIcons: 'Selectați Pictogramele',
          iconSize: 'Dimensiune Pictogramă',
          iconPlacement: 'Plasare Pictogramă',
        }
      },
      autoReply: {
        autoReplyTitle: 'Răspuns Automat',
        configureYour: 'Configurați Mesajul Dvs. de ',
        message: '',
        customizeAutoReplyMessagesForYourPostsComments: 'Personalizați mesajele de răspuns automat pentru comentariile la postările dvs.',
        wouldYouLikeToApply: 'Doriți să aplicați',
        autoreply: 'Răspunsul Automat',
        onAllPost: ' la toate postările?',
        configureYourMessageForEachPost: 'Configurați Mesajul Dvs. pentru Fiecare Postare',
        configureFlowToTriggerAfterOnReply: 'Configurați fluxul pentru a fi declanșat după un răspuns',
        whenUserLeavesACommentSendMessageAfter: 'Când un utilizator lasă un comentariu, trimiteți mesajul ulterior',
        triggerFlowOnReply: 'Declanșați Fluxul la Răspuns',
        add: 'Adăugați',
        update: 'Actualizați',
        save: 'Salvează',
        cancel: 'Anulare',
        discard: 'Renunțare',
        bpDropDown: {
          selectPost: 'Selectați Postarea',
          triggerFlowOnReply: 'Declanșați Fluxul la Răspuns',
          immendiately: 'Imediat',
          minutes: 'minute'
        },
        bpTable: {
          message: 'Mesaj',
          selectedPost: 'Postare selectată',
          triggerFlow: 'Declanșați fluxul',
          actions: 'Acțiuni'
        },
        placeholder: {
          configureYourMessage: 'Configurați mesajul dvs.'
        },
        deleteModel: {
          title: 'Ștergeți Răspunsul Automat la Postare',
          description: 'Sunteți sigur că doriți să ștergeți acest răspuns automat la postare?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'Expirarea sesiunii',
        sendInactivityMessage: 'Trimiteți mesaj de inactivitate',
        enterSessionTimeOutWindowInHours: 'Introduceți fereastra de expirare a sesiunii în ore',
        sessionTimeOutInHoursTooltip: `Introduceți durata după care sesiunea de chat se va încheia automat din cauza inactivității. Fluxul de chat va fi restartat dacă utilizatorul trimite un mesaj după expirarea sesiunii.`,
        restartChatFlowTriggers: 'Declanșatoare pentru repornirea fluxului de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Introduceți cuvinte cheie pentru a reporni chat-ul și apăsați Enter',
        restartChatFlowTriggersTooltip: `Specificați aici cuvintele cheie care, atunci când sunt introduse de către utilizator și trimise, vor declanșa repornirea fluxului de chat. Acest lucru le permite utilizatorilor să repornească conversația în orice moment.
         Apăsați Enter după adăugarea fiecărei cuvinte cheie pentru a adăuga mai multe cuvinte cheie.`,
        liveChatTriggers: 'Declanșatoare pentru chat în direct',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Introduceți cuvinte cheie pentru a declanșa chat-ul în direct și apăsați Enter',
        liveChatTriggersTooltip: `Definiți cuvintele cheie care, atunci când sunt introduse de către utilizator și trimise, vor iniția o tranziție de la chat-ul automatizat la chat-ul în direct. Acest lucru este util atunci când utilizatorii au nevoie de asistență de la un agent în direct.
         Apăsați Enter după adăugarea fiecărei cuvinte cheie pentru a adăuga mai multe cuvinte cheie.`,
        messageAfterAutomatedFlowCompleted: 'Mesaj după finalizarea fluxului automatizat',
        enterYourMessage: 'Introduceți mesajul dvs.',
        messageAfterAutomatedFlowCompletedTooltip: `Introduceți un mesaj care va fi afișat utilizatorului după ce a terminat fluxul de chat automatizat, dar sesiunea nu s-a încheiat.`,
        restart: 'Repornire',
        liveChat: 'Chat în Direct',
        lineOneForDefaultMessage: 'Dacă aveți mai multe întrebări sau aveți nevoie de ajutor, iată ce puteți face:',
        to: 'Către',
        restartTheChat: 'reporniți chat-ul',
        simpleTypeRestartAndHitEnter: `pur și simplu tastați 'Repornire' și apăsați Enter. Acest lucru vă va aduce înapoi la începutul conversației noastre.`,
        lineThreeForDefaultMessagePartOne: `Dacă doriți să vorbiți cu un `,
        liveAgent: `agent în direct,`,
        lineThreeForDefaultMessageLastPart: `tastați 'Chat în Direct' și apăsați Enter. Echipa noastră este aici pentru a vă ajuta în timp real.`,
        saveChanges: 'Salvați Modificările',
        discard: 'Renunțați',
        configureBotsGeneralSettings: 'Configurați setările generale ale botului dvs.',
        minutes: 'Minute',
        hours: 'Ore',
      }
    },
    instagram: {
      instagramBotSettings: 'Setări Bot Instagram',
      chatFlowsHeader: 'Conversații în Chat',
      broadcastHeader: 'Transmisiune',
      dripCampaignHeader: 'Campanie Stropire',
      widgetsHeader: 'Widget-uri',
      alertsHeader: 'Alerte',
      alerts: {
        configureYourBotsSettings: `Configurează setările de alertă ale botului tău`,
        specifyHowYourBotNotifyYou: 'Specificați cum botul dvs. vă va notifica despre noi lead-uri.',
        subject: 'Subiect',
        emailNotification: 'Notificare prin email',
        emailAddresses: 'Adrese de email',
        leadRevisitNotifications: 'Alerte pentru re-vizită a lead-urilor',
        whenToSendLeadInfo: 'Când să trimiteți informațiile despre lead',
        discard: 'Renunță',
        saveChanges: 'Salvează Modificările',
        placeholders: {
          enterSubjectForYourEmail: 'Introduceți subiectul pentru emailul dvs.',
          typeYourEmailAddressAndHitEnter: 'Tastați adresa dvs. de email și apăsați Enter',
        }
      },
      broadcasts: {
        createBroadcast: 'Creează Transmitere',
        youCanCreateMultipleBroadcasts: 'Puteți crea mai multe transmiteri',
        broadcastName: 'Nume Transmitere',
        broadcastNameIsRequired: 'Numele transmiterii este obligatoriu',
        continue: 'Continuă',
        manageBroadcasts: 'Administrează Transmiterile',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Puteți crea mai multe transmiteri cu ' +
          'mai multe șabloane programate pentru diferite grup de clienți.',
        createNewBroadcast: 'Creează Transmitere Nouă',
        segment: 'Grup',
        template: 'Șablon',
        contacts: 'Contacte',
        send: 'Trimite',
        failed: 'Eșuat',
        broadcastAt: 'Transmisie la',
        status: 'Stare',
        actions: 'Acțiuni',
        nA: 'N/A',
        placeholders: {
          enterBroadcastName: 'Introduceți numele transmiterii',
          searchHere: 'Caută aici',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Ștergeți mai multe transmiteri',
          deleteBroadcast: 'Ștergeți Transmiterea',
        }
      },
      createBroadcast: {
        broadcastName: 'Nume Transmitere',
        broadcastNameIsRequired: 'Numele transmiterii este obligatoriu',
        flow: 'Flux',
        segments: 'Grupuri',
        whenToSend: 'Când să trimiteți',
        selectDate: 'Selectați Data',
        selectTimeSlot: 'Selectați Intervalul de Timp',
        cancel: 'Anulare',
        createBroadcast: 'Creează Transmitere',
        placeholders: {
          enterBroadcastName: 'Introduceți numele transmiterii',
          selectFlow: 'Selectați Fluxul',
          searchFlowByName: 'Caută după nume de flux',
          selectSegments: 'Selectați Grupurile',
          searchSegmentByName: 'Căutați grup după nume',
          selectTimeSlot: 'Selectați Intervalul de Timp',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selectați data de început',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Creează Campanie Picurare',
        youCanCreateMultipleCampaigns: 'Puteți crea mai multe campanii',
        dripCampaignName: 'Nume Campanie Picurare',
        dripCampaignNameIsRequired: 'Numele Campaniei Picurare este obligatoriu',
        continue: 'Continuă',
        manageDripCampaign: 'Gestionează Campanie Picurare',
        youCanCreateMultipleCampaignsWith: 'Puteți crea mai multe campanii picurare cu mesaje programate și ' +
          'automate pentru a declanșa răspunsuri din grup specifice de utilizatori.',
        createNewDripCampaign: 'Creează Campanie Nouă Picurare',
        campaignName: 'Nume Campanie',
        appliedOn: 'Aplicat La',
        segment: 'Grup',
        flow: 'Flow',
        startDate: 'Data de Început',
        action: 'Acțiune',
        nA: 'N.A.',
        placeholders: {
          enterDripCampaignName: 'Introduceți Nume Campanie Picurare',
          searchByName: 'Caută După Nume',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Ștergeți mai multe campanii picurare',
          deleteDripCampaign: 'Ștergeți Campanie Picurare',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Nume Campanie Picurare',
          appliedOn: 'Aplicat La',
          segments: 'Grupuri',
          selectDate: 'Selectați Data',
          scheduleYourFlows: 'Programează Flows-urile Tale',
          flow: 'Flow',
          sendAfter: 'Trimite După',
          cancel: 'Anulează',
          createDripCampaign: 'Creează Campanie Picurare',
          placeholders: {
            enterDripName: 'Introduceți Nume Picurare',
            selectAppliedOn: 'Selectați Aplicat La',
            selectSegment: 'Selectați Grupurile',
            searchByName: 'Caută După Nume',
            selectTemplate: 'Selectați Șablonul',
            searchFlowByName: 'Caută După Nume Flow',
            enterSendAfter: 'Introduceți Trimite După',
          },
          dateTimePicker: {
            selectStartDate: 'Selectați data de început',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'ID Chat',
        errorCode: 'Cod Eroare',
        errorMessage: 'Mesaj Eroare',
        nA: 'N.A.',
      },
      flows: {
        createChatFlow: 'Creează Flow Conversație',
        youCanCreateMultipleChatFlows: 'Puteți crea mai multe fluxuri de conversație pe mai multe canale pentru teste A/B și transmiteri.',
        flowName: 'Nume Flow',
        nameIsRequired: 'Numele este obligatoriu',
        cancel: 'Anulează',
        createFlow: 'Creează Flow',
        importFlow: 'Importă Flow',
        createNewFlow: 'Creează Flow Nou',
        //
        ofMessages: ' de mesaje',
        createdOn: 'Creat pe',
        lastModified: 'Ultima modificare',
        defaultFlow: 'Flow implicit',
        action: 'Acțiune',
        exportStatus: 'Stare Export',
        flowExport: 'Export Flow',
        download: 'Descarcă',
        //
        beforeYouStartUploadPleaseMakeSure: 'Înainte de a începe încărcarea, asigurați-vă că',
        point1: '1. Limita maximă de dimensiune a fișierului pentru încărcare este de 1MB',
        point2: '2. Formatul fișierului trebuie să fie JSON',
        selectFile: 'Selectează Fișierul',
        placeholders: {
          enterFlowName: 'Introduceți Numele Flow-ului',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Importă flow pentru botul de Instagram',
          dateAndTimeOfCreationOfChatFlow: 'Data și ora creării fluxului de conversație',
          dateAndTimeWhenChatFlowLastModified: 'Data și ora ultimei modificări a fluxului de conversație',
        },
        noDataFound: {
          flows: 'Fluxuri',
          noFlowsAddedYet: 'Încă nu au fost adăugate fluxuri!',
        },
        modal: {
          deleteFlow: 'Șterge Flow-ul',
          wantToDeleteThisFlow: 'Sigur doriți să ștergeți acest flow?',
        }
      },
      widgets: {
        manageWidgets: 'Gestionează Widget-uri',
        widgetsAreRoutingTools: 'Widget-urile sunt instrumente de rutare pentru a crește utilizarea botului',
        needHelp: 'Ai nevoie de ajutor?',
        wantToMakeChanges: 'Doriți să faceți modificări?',
        editIcon: 'Editează Pictograma',
        discard: 'Renunță',
        updateWidget: 'Actualizează Widget-ul',
        bpDropDown: {
          selectIcons: 'Selectează Pictograme',
          iconSize: 'Dimensiune Pictogramă',
          iconPlacement: 'Plasare Pictogramă',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Setări Bot Telegram',
      chatFlowsHeader: 'Fluxuri de Chat',
      telegramProfileHeader: 'Profil Telegram',
      widgetsHeader: 'Widget-uri',
      broadcastHeader: 'Transmisiune',
      dripCampaignHeader: 'Campanie Drip',
      generalHeader: 'General',
      telegramAutomation: 'Automatizație Telegram',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Creați un traseu de înrolare a clienților folosind o campanie drip cu fluxuri de chat programate.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizați șabloanele fluxului de chat și creați mai multe fluxuri de chat pentru diferite audiențe țintă.',
      },
      broadcasts: {
        createBroadcast: 'Creați Transmisiune',
        youCanCreateMutlipleBroadcasts: 'Puteți crea mai multe transmisiuni',
        broadcastName: 'Nume Transmisiune',
        broadcastNameIsRequired: 'Numele transmisiunii este obligatoriu',
        continue: 'Continua',
        manageBroadcasts: 'Gestionați Transmisiunile',
        youCanCreateMultipleBroadcastsWith: 'Puteți crea mai multe transmisiuni cu mai multe șabloane programate pentru diferite Grup de clienți.',
        createNewBroadcast: 'Creați o Nouă Transmisiune',
        name: 'Nume',
        segment: 'Grup',
        template: 'Șablon',
        contacts: 'Contacte',
        send: 'Trimite',
        failed: 'Eșuat',
        broadcastAt: 'Transmis la',
        status: 'Stare',
        actions: 'Acțiuni',
        nA: 'NA',
        deleteBroadcast: 'Ștergeți Transmisiunea',
        wantToDeleteThisBroadcast: 'Sunteți sigur că doriți să ștergeți această transmisiune?',
        placeholders: {
          enterBroadcastName: 'Introduceți numele transmisiunii',
          searchHere: 'Caută aici',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Ștergeți mai multe transmisiuni',
          deleteBroadcast: 'Ștergeți Transmisiunea',
        },
      },
      createBroadcastTelegram: {
        broadcastName: 'Nume Transmisiune',
        broadcastNameIsRequired: 'Numele transmisiunii este obligatoriu',
        flow: 'Flux',
        segments: 'Grupuri',
        whenToSend: 'Când să trimiteți',
        selectDate: 'Selectați Data',
        selectTimeSlot: 'Selectați Intervalul de Timp',
        cancel: 'Anulați',
        createBroadcast: 'Creați Transmisiunea',
        now: 'Acum',
        later: 'Mai târziu',
        placeholders: {
          enterBroadcastName: 'Introduceți numele transmisiunii',
          selectFlow: 'Selectați Fluxul',
          searchFlowByName: 'Caută după nume de flux',
          selectSegments: 'Selectați Grupurile',
          searchSegmentByName: 'Căutați grup după nume',
          selectTimeSlot: 'Selectați Intervalul de Timp',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selectați data de început',
        },
      },
      dripCampaigns: {
        createDripCampaign: 'Creează Campanie Drip',
        youCanCreateMultipleCampaigns: 'Puteți crea mai multe campanii',
        dripCampaignName: 'Nume Campanie Drip',
        dripCampaignNameIsRequired: 'Numele Campaniei Drip este obligatoriu',
        continue: 'Continuare',
        manageDripCampaign: 'Gestionează Campania Drip',
        youCanCreateMultipleDripCampaignsWith: 'Puteți crea mai multe campanii drip cu mesaje programate și automate' +
          ' pentru a declanșa răspunsuri din grup de utilizatori specifice.',
        createNewDripCampaign: 'Creează Campanie Drip Nouă',
        campaignName: 'Nume Campanie',
        appliedOn: 'Aplicat pe',
        segment: 'Grup',
        flow: 'Flow',
        startDate: 'Dată de început',
        action: 'Acțiune',
        nA: 'Indisponibil',
        deleteDripCampaign: 'Ștergeți Campanii Drip',
        wantToDeleteThisDripCampaign: 'Sunteți sigur că doriți să ștergeți aceste Campanii Drip?',
        placeholders: {
          enterDripCampaignName: 'Introduceți Numele Campaniei Drip',
          searchByName: 'Caută după nume',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Ștergeți mai multe campanii drip',
          deleteDripCampaign: 'Ștergeți Campania Drip',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Nume Campanie Drip',
          appliedOn: 'Aplicat pe',
          segments: 'Grupuri',
          startDate: 'Selectați Data',
          scheduleYourFlows: 'Programează Flows-urile Tale',
          schedule: 'Programează',
          flow: 'Flow',
          sendAfter: 'Trimite După',
          cancel: 'Anulează',
          createDripCampaign: 'Creează Campanie Drip',
          newSubscriber: 'Abonat Nou',
          days: 'Zile',
          minutes: 'Minute',
          hours: 'Ore',
          placeholders: {
            enterDripName: 'Introduceți Numele Drip-ului',
            selectAppliedOn: 'Selectați Aplicat Pe',
            selectSegment: 'Selectați Grupurile',
            searchByName: 'Caută după nume',
            selectFlow: 'Selectați Flow-ul',
            searchFlowByName: 'Caută Flow După Nume',
          },
          dateTimePicker: {
            selectStartDate: 'Selectați data de început',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Nume',
        email: 'Email',
        phone: 'Telefon',
        errorCode: 'Cod Eroare',
        errorMessage: 'Mesaj Eroare',
        nA: 'Indisponibil',
      },
      flows: {
        createChatFlow: 'Creează Flux de Chat',
        youCanCreateMultipleChatFlows: 'Puteți crea fluxuri de conversații multiple pe mai multe canale pentru teste A/B și difuzări.',
        flowName: 'Nume Flux',
        nameIsRequired: 'Numele este obligatoriu',
        cancel: 'Anulează',
        createFlow: 'Creează Flux',
        importFlow: 'Importă Fluxul',
        createNewFlow: 'Creează Flux Nou',
        //
        ofMessages: ' de mesaje',
        createdOn: 'Creat pe',
        lastModified: 'Ultima modificare',
        defaultFlow: 'Flux implicit',
        action: 'Acțiune',
        exportStatus: 'Stare Export',
        flowExport: 'Export Flux ',
        inProgress: 'în curs',
        available: 'Disponibil',
        initiated: 'Inițiat',
        download: 'Descărcare',
        //
        beforeYouStartUploadPleaseMakeSure: 'Înainte de a începe încărcarea, asigurați-vă că',
        point1: '1. Limita maximă de dimensiune pentru încărcare este de 1MB',
        point2: '2. Formatul fișierului trebuie să fie JSON',
        selectFile: 'Selectează Fișier',
        placeholders: {
          enterFlowName: 'Introduceți numele fluxului',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Importă fluxul pentru bot-ul Telegram',
          dateAndTimeOfCreationOfChatFlow: 'Data și ora creării fluxului de chat',
          dateAndTimeWhenChatFlowLastModified: 'Data și ora ultimei modificări a fluxului de chat',
          TOOLTIP_CREATE_FLOW: 'Faceți clic pentru a crea un flux de chat nou',
          TOOLTIP_FLOW_NAME: 'Introduceți numele fluxului de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Numele fluxului de chat',
          TOOLTIP_NO_OF_MESSAGES: 'Numărul de componente de mesaje din fluxul de chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilizați această comutare pentru a seta un flux de chat ca implicit, care va fi servit tuturor utilizatorilor noi.',
          TOOLTIP_ACTION: 'Editați sau ștergeți fluxul de chat',
        },
        noDataFound: {
          flows: 'Fluxuri',
          noFlowsAddedYet: 'Niciun flux adăugat încă!',
        },
        modal: {
          deleteFlow: 'Ștergeți Fluxul',
          wantToDeleteThisFlow: 'Sunteți sigur că doriți să ștergeți acest Flux?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Profil Telegram',
        manageYourBotProfile: 'Gestionați profilul botului dumneavoastră oricând doriți.',
        allSetNoActionRequired: 'Totul este în regulă, nu este necesară nicio acțiune!',
        congratulationYourAccountIsLive: 'Felicitări! Contul dumneavoastră este activ cu acces complet',
        botDetails: 'Detalii Bot',
        name: 'Nume',
        nA: 'Indisponibil',
        b: 'B',
        botName: 'Nume Bot',
        bot: 'Bot',
        info: 'Informații',
        none: 'Niciunul',
        username: 'Nume de utilizator',
        notifications: 'Notificări',
        on: 'Pornit',
        addToGroupOrChannel: 'Adăugați în Grup sau Canal',
        userName: 'Nume de utilizator',
        botToken: 'Token Bot',
      },
      widgets: {
        manageWidgets: 'Gestionați Widget-urile',
        widgetsAreRoutingTools: 'Widget-urile sunt instrumente de rutare pentru a crește utilizarea botului',
        needHelp: 'Aveți nevoie de ajutor?',
        wantToMakeChanges: 'Doriți să faceți modificări?',
        editIcon: 'Editare Pictogramă',
        discard: 'Renunțare',
        updateWidget: 'Actualizați Widget-ul',
        installChatBotIconOnYourWebsite: 'Instalați pictograma chatbot-ului pe site-ul dumneavoastră',
        shareWhereEverYouWantRedirectUsersTo: 'Partajați oriunde doriți și redirecționați utilizatorii către botul Telegram într-un singur clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copiați și adăugați în fișierul antet al site-ului dumneavoastră',
        copyLinkAndStartSharing: 'Copiați link-ul și începeți să partajați!',
        downloadQrCodeAndStartSharing: 'Descărcați codul QR și începeți să partajați!',
        addCodeAndDeployNewCodeAndDone: 'Adăugați codul, implementați codul nou și Gata!',
        wantToLearnHowToUseIt: 'Doriți să învățați cum să-l utilizați?',
        copyChatBotCode: 'Copiați Codul Chatbot',
        downloadQrCode: 'Descărcați Codul QR',
        copyTelegramLink: 'Copiați Link-ul Telegram',
        link: 'Link',
        chatBotIcon: 'Pictogramă Chatbot',
        qrcode: 'Cod QR',
        small: 'Mic',
        medium: 'Mediu',
        large: 'Mare',
        leftMiddle: 'Stânga-Mijloc',
        rightMiddle: 'Dreapta-Mijloc',
        leftBottom: 'Stânga-Jos',
        rightBottom: 'Dreapta-Jos',
        bpDropDown: {
          selectIcons: 'Selectați Pictogramele',
          iconSize: 'Dimensiune Pictogramă',
          iconPlacement: 'Amplasare Pictogramă',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Expirarea sesiunii',
        sendInactivityMessage: 'Trimiteți mesaj de inactivitate',
        enterSessionTimeOutWindowInHours: 'Introduceți fereastra de expirare a sesiunii în ore',
        sessionTimeOutInHoursTooltip: `Introduceți durata după care sesiunea de chat se va încheia automat din cauza inactivității. Fluxul de chat va fi restartat dacă utilizatorul trimite un mesaj după expirarea sesiunii.`,
        restartChatFlowTriggers: 'Declanșatoare pentru repornirea fluxului de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Introduceți cuvinte cheie pentru a reporni chat-ul și apăsați Enter',
        restartChatFlowTriggersTooltip: `Specificați aici cuvintele cheie care, atunci când sunt introduse de către utilizator și trimise, vor declanșa repornirea fluxului de chat. Acest lucru le permite utilizatorilor să repornească conversația în orice moment.
         Apăsați Enter după adăugarea fiecărei cuvinte cheie pentru a adăuga mai multe cuvinte cheie.`,
        liveChatTriggers: 'Declanșatoare pentru chat în direct',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Introduceți cuvinte cheie pentru a declanșa chat-ul în direct și apăsați Enter',
        liveChatTriggersTooltip: `Definiți cuvintele cheie care, atunci când sunt introduse de către utilizator și trimise, vor iniția o tranziție de la chat-ul automatizat la chat-ul în direct. Acest lucru este util atunci când utilizatorii au nevoie de asistență de la un agent în direct.
         Apăsați Enter după adăugarea fiecărei cuvinte cheie pentru a adăuga mai multe cuvinte cheie.`,
        messageAfterAutomatedFlowCompleted: 'Mesaj după finalizarea fluxului automatizat',
        enterYourMessage: 'Introduceți mesajul dvs.',
        messageAfterAutomatedFlowCompletedTooltip: `Introduceți un mesaj care va fi afișat utilizatorului după ce a terminat fluxul de chat automatizat, dar sesiunea nu s-a încheiat.`,
        restart: 'Repornire',
        liveChat: 'Chat în Direct',
        lineOneForDefaultMessage: 'Dacă aveți mai multe întrebări sau aveți nevoie de ajutor, iată ce puteți face:',
        to: 'Către',
        restartTheChat: 'reporniți chat-ul',
        simpleTypeRestartAndHitEnter: `pur și simplu tastați 'Repornire' și apăsați Enter. Acest lucru vă va aduce înapoi la începutul conversației noastre.`,
        lineThreeForDefaultMessagePartOne: `Dacă doriți să vorbiți cu un `,
        liveAgent: `agent în direct,`,
        lineThreeForDefaultMessageLastPart: `tastați 'Chat în Direct' și apăsați Enter. Echipa noastră este aici pentru a vă ajuta în timp real.`,
        saveChanges: 'Salvați Modificările',
        discard: 'Renunțați',
        configureBotsGeneralSettings: 'Configurați setările generale ale botului dvs.',
        minutes: 'Minute',
        hours: 'Ore',
      }
    },
    whatsapp: {
      whatsappBotSettings: 'Setări Bot WhatsApp',
      chatFlowsHeader: 'Fluxuri de Chat',
      generalHeader: 'General',
      whatsappProfileHeader: 'Profil WhatsApp',
      broadcastHeader: 'Transmisie',
      dripCampaignHeader: 'Campanie Drip',
      widgetHeader: 'Widget',
      templatesHeader: 'Șabloane',
      alertsHeader: 'Alerte',
      apiKeyHeader: 'Cheie API',
      catalogHeader: 'Catalog',
      otherConfiguration: 'Configurări AI',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizați șabloanele de fluxuri de chat și creați mai multe fluxuri de chat pentru diferite audiențe țintă.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Configurați setările de alertă ale botului dumneavoastră',
        specifyHowYouBotWillNotify: 'Specificați cum vă va notifica botul despre noi lead-uri.',
        subject: 'Subiect',
        emailNotification: 'Notificare prin Email',
        emailAddresses: 'Adrese de Email',
        leadRevisitNotifications: 'Notificări de Revenire Lead-uri',
        whenToSendLeadInfo: 'Când să trimiteți informațiile despre lead',
        discard: 'Renunțare',
        saveChanges: 'Salvați Modificările',
        seeHowToUse: 'Vezi cum se folosește',
        beginningTheFlow: 'Începutul Fluxului',
        fiveMinutesLater: '5 Minute Mai Târziu',
        endOfFlow: 'Sfârșitul Fluxului',
        placeholders: {
          enterSubjectForYourEmail: 'Introduceți Subiectul pentru e-mailul dumneavoastră',
          typeEmailAndHitEnter: 'Tastați adresa dvs. de email și apăsați Enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Personalizați subiectul notificărilor prin e-mail ale botului dumneavoastră',
          TOOLTIP_EMAIL_NOTIFICATION: 'Activați/dezactivați notificarea botului pe e-mail pentru noi lead-uri',
          TOOLTIP_EMAIL_ADDRESS: 'Introduceți adresa de e-mail a contului unde doriți să primiți notificări prin e-mail. Puteți adăuga mai multe adrese de e-mail introducând una și apăsând Enter pentru a adăuga alta.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'Activați/dezactivați notificarea botului pe e-mail dacă un utilizator anterior capturat ca lead interacționează din nou cu botul.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Actualizați Chatbot WhatsApp',
        businessNameIsRequired: 'Numele Companiei este obligatoriu.',
        maxLengthForBusinessName40Characters: 'Lungimea maximă pentru Numele Companiei este de 40 de caractere.',
        categoryIsRequired: 'Categoria este obligatorie.',
        descriptionIsRequired: 'Descrierea este obligatorie.',
        maxLengthForDescription200Characters: 'Lungimea maximă pentru descriere este de 200 de caractere.',
        aboutIsRequired: 'Despre este obligatoriu.',
        maxLengthForAbout139Characters: 'Lungimea maximă pentru "Despre" este de 139 de caractere.',
        pleaseFillAValidBusinessEmail: 'Vă rugăm să introduceți o adresă de email validă pentru afacere.',
        businessEmailIsRequired: 'Adresa de email a companiei este obligatorie.',
        websiteIsRequired: 'Site-ul web este obligatoriu.',
        maxLengthForWebsite40Characters: 'Lungimea maximă pentru site-ul web este de 40 de caractere.',
        maxLengthForAddress150Characters: 'Lungimea maximă pentru adresă este de 150 de caractere.',
        cancel: 'Anulare',
        update: 'Actualizare',
        businessCategory: 'Categorie Companie',
        placeholders: {
          yourBusinessName: 'Numele Companiei',
          businessDescription: 'Descrierea Companiei',
          about: 'Despre',
          businessEmail: 'Email Companie',
          website: 'Site web (de ex. www.exemplu.com)',
          address: 'Adresă',
        }
      },
      flows: {
        createChatFlow: 'Creați Flux de Chat',
        youCanCreateMultipleChatFlows: 'Puteți crea mai multe fluxuri de conversație pe mai multe canale pentru testare A/B și difuzări.',
        flowName: 'Numele Fluxului',
        nameIsRequired: 'Numele este obligatoriu',
        cancel: 'Anulare',
        createFlow: 'Creați Flux',
        importFlow: 'Importați Fluxul',
        createNewFlow: 'Creați Flux Nou',
        ofMessages: ' din mesaje',
        createdOn: 'Creat la',
        lastModified: 'Ultima modificare',
        defaultFlow: 'Flux implicit',
        revisitFlow: 'Flux pentru revenirea utilizatorilor',
        action: 'Acțiune',
        exportStatus: 'Stare Export',
        flowExport: 'Export Flux ',
        //
        beforeYouStartUploadPleaseMakeSure: 'Înainte de a începe încărcarea, asigurați-vă că',
        point1: '1. Limita maximă de dimensiune a fișierului pentru încărcare este de 1 MB',
        point2: '2. Formatul fișierului trebuie să fie JSON',
        selectFile: 'Selectați Fișierul',
        seeHowToUse: 'Vezi cum se folosește',
        download: 'Descărcare',
        available: 'Disponibil',
        initiated: 'Inițiat',
        inProgress: 'În desfășurare',
        placeholders: {
          enterFlowName: 'Introduceți numele fluxului',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Importați fluxul pentru botul WhatsApp',
          dateAndTimeOfCreationOfChatFlow: 'Data și ora creării fluxului de chat',
          dateAndTimeWhenChatFlowLastModified: 'Data și ora ultimei modificări a fluxului de chat',
          TOOLTIP_CREATE_FLOW: 'Faceți clic pentru a crea un flux de chat nou',
          TOOLTIP_FLOW_NAME: 'Introduceți numele fluxului de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Numele fluxului de chat',
          TOOLTIP_NO_OF_MESSAGES: 'Numărul de componente de mesaj în fluxul de chat',
          TOOLTIP_DEFAULT_FLOW: 'Folosiți acest comutator pentru a seta un flux de chat ca implicit, care va fi servit tuturor utilizatorilor noi.',
          TOOLTIP_REVISIT_FLOW: 'Folosiți acest comutator pentru a selecta un flux de chat care să fie servit utilizatorilor atunci când revin la chatbot-ul dumneavoastră.',
          TOOLTIP_ACTION: 'Editați sau ștergeți fluxul de chat',
        },
        modal: {
          deleteFlow: 'Ștergeți Fluxul',
          wantToDeleteThisFlow: 'Sunteți sigur că doriți să ștergeți acest Flux?',
        },
        noDataFound: {
          flows: 'Fluxuri',
          noFlowsAddedYet: 'Niciun Flux adăugat încă!',
        }
      },
      input: {
        manageTemplates: 'Gestionează Șabloanele',
        manageYourTemplatesForMarketingAutomation: 'Gestionează-ți șabloanele pentru automatizarea marketingului',
        syncTemplates: 'Sincronizează Șabloanele',
        createTemplateHeader: 'Creează Șablonul',
        title: 'Titlu',
        info: 'Informații',
        category: 'Categorie',
        status: 'Status',
        action: 'Acțiune',
        shipmentUpdate: 'Actualizare Expediere',
        name: 'Nume',
        //
        headerOptional: 'Antet (Opțional)',
        bodyRequired: 'Corp (obligatoriu)',
        footerOptional: 'Subsol (opțional)',
        buttonsOptional: 'Butoane (opțional)',
        makeUpto3Buttons: 'Creați până la 3 butoane',
        makeUpto2ButtonsToRedirect: 'Creați până la 2 butoane pentru a redirecționa clienții către site-ul dvs.',
        placeholders: {
          nameYourTemplate: 'Denumiți șablonul dvs. (folosiți linie de subliniere pentru a separa cuvintele)',
          enterText: 'Introduceți textul (maxim 60 de caractere)',
          enterImageUrl: 'Introduceți URL-ul imaginii',
          enterVideoUrl: 'Introduceți URL-ul videoclipului',
          templateMessage: 'Mesajul șablonului',
          smallFooterAtBottom: 'Subsol mic în partea de jos',
          buttons: 'Butoane',
          text: 'Text',
          numberWithCountryCode: 'Număr cu cod de țară',
          enterButtonText: 'Introduceți textul butonului (maxim 20 de caractere)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Titlul șablonului WhatsApp',
          TOOLTIP_INPUT_TABLE_INFO: 'Informații detaliate despre mesajele inițiate de afaceri',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Tipuri de mesaje inițiate de afaceri, cum ar fi programări, mementouri, mesaje de îngrijire a clienților, actualizări de expediere, alerte și altele',
          TOOLTIP_INPUT_TABLE_STATUS: 'Starea șablonului este respinsă sau aprobată de WhatsApp. Șabloanele WhatsApp trebuie să fie mai întâi aprobate de WhatsApp, iar acest lucru este un serviciu plătit.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Ștergeți șablonul',
        },
        noDataFound: {
          templates: 'Șabloane',
          noTemplatesCreatedYet: 'Niciun șablon creat încă!',
        },
        modal: {
          deleteTemplate: 'Ștergeți Șablonul',
          wantToDeleteThisTemplate: 'Sunteți sigur că doriți să ștergeți acest șablon?',
        },
        createTemplate: {
          createTemplates: 'Creați Șabloane',
          createTemplatesForMarketingAutomation: 'Creați-vă șabloanele pentru automatizarea marketingului',
          templateMeta: 'Meta pentru șablon',
          selectLanguage: 'Selectați limba',
          header: 'Antet',
          optional: 'Opțional',
          samplesForHeaderContent: 'Exemple pentru conținutul antetului',
          toHelpMetaReviewYourContent: 'Pentru a ajuta Meta să revizuiască conținutul dvs., furnizați exemple de variabile sau media în antet. Nu includeți informații despre clienți.',
          body: 'Corp',
          addVariable: 'Adăugați variabilă',
          samplesForBodyContent: 'Exemple pentru conținutul corpului',
          toHelpUsReviewYourContent: 'Pentru a ne ajuta să revizuim conținutul dvs., furnizați exemple de variabile în corp. Nu includeți informații despre clienți.',
          footer: 'Subsol',
          //
          buttons: 'Butoane',
          //
          typeOfAction: 'Tip de Acțiune',
          call: 'Apel',
          phone: 'Telefon',
          website: 'Website',
          static: 'Static',
          urlType: 'Tip URL',
          type: 'Tip',
          customButton: 'Buton personalizat',
          addAnotherButton: 'Adăugați un alt buton',
          preview: 'Previzualizare',
          test: 'Test',
          create: 'Creați',
          addSampleUrl: 'Adăugați URL de exemplu',
          toHelpMetaReviewYourMessage: 'Pentru a ajuta Meta să revizuiască șablonul dvs. de mesaj, adăugați un exemplu de URL al site-ului web. Nu utilizați informații reale despre clienți.',
          placeholders: {
            enterMessageTemplateName: 'Introduceți numele șablonului de mesaj',
            searchTemplateByName: 'Căutați șablonul după nume',
            selectTemplate: 'Selectați Șablonul',
            enterText: 'Introduceți Textul',
            enterBody: 'Introduceți Corpul',
            enterContentForVariable: 'Introduceți conținut pentru variabilă',
            enterFooter: 'Introduceți Subsolul',
            phone: 'Telefon*',
            enterWebsiteUrl: 'Introduceți URL-ul site-ului web',
          },
          bpInput: {
            name: 'Nume',
            buttonText: 'Textul Butonului',
            websiteUrl: 'URL-ul Site-ului Web',
          },
          bpDropDown: {
            selectCategory: 'Selectați Categorie',
          },
        },
        templatePreview: {
          preview: 'Previzualizare',
          test: 'Test',
        },
        templateConfig: {
          saveChanges: 'Salvați Modificările',
          discard: 'Renunțați',
          triggerChatFlowOnTemplateReply: 'Declanșați Fluxul de Chat la Răspunsul Șablonului',
          templateDetails: 'Detalii Șablon',
          configureFlows: 'Configurați Fluxuri',
          selectFlow: 'Selectați Fluxul',
          name: 'Nume',
        },
      },
      shopManagement: {
        shopManagement: 'Administrare Magazin',
        manageECommerceShopFromBotPenguin: 'Administrați magazinele de comerț electronic conectate la BotPenguin',
        comingSoon: '( În curând )',
        wooCommerce: {
          createConfiguration: 'Creați Configurație',
          eventConfiguration: {
            createConfiguration: 'Creați Configurație',
            templateHeader: 'Antet Schemă',
            selectFile: 'Selectați Fișierul',
            templateMessage: 'Mesaj Schemă',
            saveChanges: 'Salvați Modificările',
            bpDropDown: {
              selectEvent: 'Selectați Evenimentul',
              selectTemplate: 'Selectați Schemă',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'Profil WhatsApp',
        manageYourBotProfile: 'Administrați Profilul Bot-ului când doriți.',
        allSetNoActionRequired: 'Totul este pregătit, nu este necesară nicio acțiune!',
        congratulationsYourAccountIsLive: 'Felicitări! Contul dvs. este activ cu acces complet',
        somethingIsWrongReactivateYourBot: 'Ceva nu este în regulă, reactivați-vă bot-ul!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Se pare că bot-ul dvs. nu funcționează corect. Vă rugăm să resetați bot-ul.',
        reConfigureYourBot: 'Reconfigurați-vă bot-ul',
        phoneNumberDetails: 'Detalii Număr de Telefon',
        phoneNumber: 'Număr de Telefon',
        nA: 'Nedisp.',
        phoneNumberId: 'ID Număr de Telefon',
        wabaId: 'ID WABA',
        change: 'Schimbare',
        accessToken: 'Token de Acces',
        //
        messagingLimit: 'Limită Mesaje',
        qualityRating: 'Evaluare Calitate',
        whatsappBusinessAccountName: 'Nume Cont WhatsApp Business',
        whatsappPublicProfile: 'Profil Public WhatsApp',
        profilePicture: 'Fotografie Profil',
        aboutText: 'Despre Text',
        address: 'Adresă',
        description: 'Descriere',
        email: 'Email',
        websitePrimary: 'Website (Primar)',
        websiteSecondary: 'Website (Secundar)',
        businessVertical: 'Verticală Afacere',
        editProfile: 'Editare Profil',
        thisIsABusinessAccount: 'Acesta este un cont de afaceri',
        aboutAndPhoneNumber: 'Despre și Număr de Telefon',
        yourTrialAccountIsLive: 'Felicitări! Contul dvs. de încercare este activ cu acces complet la numărul dvs. verificat',
        updateWithNewAccessToken: 'Actualizare cu un nou token de acces',
      },
      widgets: {
        manageWidgets: 'Administrare Widget-uri',
        widgetsAreRoutingTools: 'Widget-urile sunt instrumente de rutare pentru a crește utilizarea bot-ului',
        needHelp: 'Aveți nevoie de ajutor?',
        wantToMakeChanges: 'Doriți să efectuați modificări?',
        prePopulatedMessage: 'Mesaj Pre-populat',
        discard: 'Renunțare',
        updateWidget: 'Actualizare Widget',
        hiThere: 'Bună acolo ',
        seeHowToUse: 'Aflați cum să utilizați',
        editIcon: 'Editare Pictogramă',
        editPrePopulatedMessage: 'Editare Mesaj Pre-populat',
        small: 'Mic',
        medium: 'Mediu',
        large: 'Mare',
        leftMiddle: 'Stânga-Mijloc',
        rightMiddle: 'Dreapta-Mijloc',
        leftBottom: 'Stânga-Jos',
        rightBottom: 'Dreapta-Jos',
        chatBotIcon: 'Pictogramă ChatBot',
        link: 'Link',
        qrcode: 'Cod QR',
        copyChatBotCode: 'Copiați Codul Chatbot',
        copyWALink: 'Copiați Link-ul WA',
        downloadQrCode: 'Descărcați Codul QR',
        installChatBotIconOnYourWebsite: 'Instalați pictograma chatbot pe site-ul dvs.',
        shareWhereEverYouWantRedirectUsersTo: 'Distribuiți oriunde doriți și redirecționați utilizatorii către bot-ul dvs. WhatsApp într-un singur clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copiați și adăugați în fișierul de antet al site-ului dvs.',
        copyLinkAndStartSharing: 'Copiați link-ul și începeți să distribuiți!',
        downloadQrCodeAndStartSharing: 'Descărcați codul QR și începeți să distribuiți!',
        addCodeAndDeployNewCodeAndDone: 'Adăugați codul, implementați codul nou și gata!',
        wantToLearnHowToUseIt: 'Doriți să învățați cum să îl utilizați?',
        placeholders: {
          enterWhatsappNumber: 'Introduceți numărul WhatsApp',
          updateYourMessageHere: 'Actualizați mesajul aici...',
        },
        bpDropDown: {
          selectIcons: 'Selectați Pictogramele',
          iconSize: 'Dimensiune Pictogramă',
          iconPlacement: 'Plasare Pictogramă',
        },
        bpInput: {
          whatsappNumber: 'Număr WhatsApp',
        }
      },
      catalog: {
        createCatalog: 'Creați Catalog',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Creați catalogul de produse în Meta Commerce Manager sau legați-l de Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Utilizați Meta Commerce Manager pentru a administra cataloagele',
        connectCatalogToWhatsAppAccount: 'Conectați Catalogul la contul WhatsApp',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Conectați cataloagele la contul WhatsApp utilizând managerul WhatsApp',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Utilizați Meta WhatsApp Manager pentru a conecta cataloagele și pentru a le utiliza cu BotPenguin',
        goToMeta: 'Mergeți la Meta',
        goToShopify: 'Mergeți la Shopify',
        next: 'Următorul',
        back: 'Înapoi',
        setupCatalog: 'Configurați Catalogul',
        completeAllTheStepsToEnableCatalogMessages: 'Finalizați toți pașii pentru a activa mesajele din catalog'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Expirarea sesiunii',
        sendInactivityMessage: 'Trimiteți mesaj de inactivitate',
        enterSessionTimeOutWindowInHours: 'Introduceți fereastra de expirare a sesiunii în ore',
        sessionTimeOutInHoursTooltip: `Introduceți durata după care sesiunea de chat se va încheia automat din cauza inactivității. Fluxul de chat va fi restartat dacă utilizatorul trimite un mesaj după expirarea sesiunii.`,
        restartChatFlowTriggers: 'Declanșatoare pentru repornirea fluxului de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Introduceți cuvinte cheie pentru a reporni chat-ul și apăsați Enter',
        restartChatFlowTriggersTooltip: `Specificați aici cuvintele cheie care, atunci când sunt introduse de către utilizator și trimise, vor declanșa repornirea fluxului de chat. Acest lucru le permite utilizatorilor să repornească conversația în orice moment.
         Apăsați Enter după adăugarea fiecărei cuvinte cheie pentru a adăuga mai multe cuvinte cheie.`,
        liveChatTriggers: 'Declanșatoare pentru chat în direct',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Introduceți cuvinte cheie pentru a declanșa chat-ul în direct și apăsați Enter',
        liveChatTriggersTooltip: `Definiți cuvintele cheie care, atunci când sunt introduse de către utilizator și trimise, vor iniția o tranziție de la chat-ul automatizat la chat-ul în direct. Acest lucru este util atunci când utilizatorii au nevoie de asistență de la un agent în direct.
         Apăsați Enter după adăugarea fiecărei cuvinte cheie pentru a adăuga mai multe cuvinte cheie.`,
        messageAfterAutomatedFlowCompleted: 'Mesaj după finalizarea fluxului automatizat',
        enterYourMessage: 'Introduceți mesajul dvs.',
        messageAfterAutomatedFlowCompletedTooltip: `Introduceți un mesaj care va fi afișat utilizatorului după ce a terminat fluxul de chat automatizat, dar sesiunea nu s-a încheiat.`,
        restart: 'Repornire',
        liveChat: 'Chat în Direct',
        lineOneForDefaultMessage: 'Dacă aveți mai multe întrebări sau aveți nevoie de ajutor, iată ce puteți face:',
        to: 'Către',
        restartTheChat: 'reporniți chat-ul',
        simpleTypeRestartAndHitEnter: `pur și simplu tastați 'Repornire' și apăsați Enter. Acest lucru vă va aduce înapoi la începutul conversației noastre.`,
        lineThreeForDefaultMessagePartOne: `Dacă doriți să vorbiți cu un `,
        liveAgent: `agent în direct,`,
        lineThreeForDefaultMessageLastPart: `tastați 'Chat în Direct' și apăsați Enter. Echipa noastră este aici pentru a vă ajuta în timp real.`,
        saveChanges: 'Salvați Modificările',
        discard: 'Renunțați',
        configureBotsGeneralSettings: 'Configurați setările generale ale botului dvs.',
        minutes: 'Minute',
        hours: 'Ore',

      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'Doar un Pas Distanță',
      seeHowToUse: 'Aflați cum să utilizați',
      testWhatsAppTrialBot: 'Testați Botul dvs. de Test WhatsApp',
      sendMessage: 'Trimiteți Mesaj',
      sendWhatsAppMessageOn: 'Trimiteți-ne un mesaj pe WhatsApp la ',
      bySavingInYourContact: 'salvând numărul nostru în contactele dvs. și mesajul ar trebui să înceapă cu ',
      connect: 'Conectați',
      or: 'SAU',
      scanQRCode: 'Scanați Codul QR',
      yourPhoneWillOpen: 'Telefonul dvs. va deschide un mesaj pre-umplut pentru a fi trimis la numărul nostru Sandbox. Apăsați "Trimite" pe WhatsApp',
      sendVerificationCode: 'Trimiteți Codul de Verificare',
      copyAndEnterCode: 'Copiați și introduceți codul format din 6 cifre',
      shareWhereYouWant: 'Distribuiți oriunde doriți și redirecționați utilizatorii către bot-ul dvs. WhatsApp într-un singur clic',
      youShouldHaveReceived: 'Ar trebui să fiți primit un cod de 6 cifre pe numărul dvs. WhatsApp',
      EnterYourPhoneNumber: 'Introduceți numărul dvs. de telefon',
      send: 'Trimite',
      sixDigitCode: 'Introduceți codul format din 6 cifre',
      pleaseNote: 'Vă rugăm să rețineți: Codul de verificare format din 6 cifre poate dura până la 3 minute, Vă rugăm să așteptați înainte de a îl retrimite.',
      resendVerificationCode: 'Retrimiteți codul de verificare',
      verify: 'Verificare',
      providePhoneNumber: 'Vă rugăm să furnizați numărul pe care doriți să îl utilizați în scop de testare',
      demoBotisReady: 'Minunat! Botul dvs. demo este gata pentru utilizare',
      placeHolder: {
        phone: 'Introduceți numărul dvs. de telefon',
        code: 'Introduceți codul de verificare',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Integrare Aplicații de la Terți',
    thirdPartyApps: 'Aplicații de la Terți',
    customApps: 'Aplicații Personalizate',
    preview: 'Previzualizare',
    test: 'Test',
    install: 'Instalare',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Clic pentru a instala bot-ul pe platforma selectată',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Integrează aplicațiile personalizate create de tine cu bot-ul tău',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Conectează-ți bot-ul cu aplicații de la terți/CRMs disponibile',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Integrări Aplicații de la Terți',
        integrateYourBotWithListedCrms: 'Integrează-ți bot-ul cu una dintre aplicațiile/CRMs enumerate.',
        help: 'Ajutor',
        name: 'Nume',
        status: 'Status',
        action: 'Acțiune',
        chatGpt: 'ChatGPT',
        addOn: 'Adăugare',
        purchased: 'Achiziționat',
        readyToRideGptWave: 'Gata să urci pe valul GPT? Integrează acum.',
        purchase: 'Cumpără',
        notConnected: 'Neconectat',
        integrated: 'Integrat',
        configure: 'Configurează',
        connected: 'Conectat',
        edit: 'Editează',
        connect: 'Conectează',
        requestYourIntegration: 'Solicită Integrarea ta',
        toRequestNewIntegration: 'Pentru a solicita o integrare nouă, vă rugăm să introduceți detaliile ' +
          'menționate mai jos și apoi vom reveni la dumneavoastră.',
        //
        emailId: 'Adresă de Email',
        integrationName: 'Nume Integrare',
        contactNumber: 'Număr de Contact',
        howSoonYouWantToAddThisIntegration: 'Cât de curând doriți să adăugați această integrare',
        sendRequest: 'Trimite Cererea',
        cancel: 'Anulează',
        requestSent: 'Cerere Trimisă!',
        yourRequestSuccessfullySent: 'Cererea dvs. pentru o nouă integrare a fost trimisă cu succes.',
        goBack: 'Înapoi',
        disconnect: 'Deconectare',
        OopsIntegrationAreNotAvailable: 'Oops! Integrările nu sunt disponibile în ',
        pleaseUpgradeTo: ' Vă rugăm să faceți upgrade la ',
        planForIntegration: ' pentru integrări.',
        placeholders: {
          searchIntegrationByName: 'Caută integrarea după nume',
          enterTeamMemberName: 'Introduceți numele membrului echipei',
          enterEmailId: 'Introduceți Adresa de Email',
          enterIntegrationName: 'Introduceți un Nume de Integrare',
          searchCountry: 'Caută Țara',
          enterContactNumber: 'Introduceți Numărul de Contact',
          addComment: 'Adaugă Comentariu',
        },
        noDataFound: {
          integrations: 'Integrări',
          noIntegrationsFound: 'Nicio integrare găsită!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Numele Aplicației/CRM-ului',
          statusAsConnectedOrNotConnected: 'Status ca conectat / neconectat',
          clickToConnectToAppOrCrm: 'Clic pentru a conecta Aplicația/CRM-ul',
        },
        feedbackModal: {
          submit: 'Trimite',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          agileCrmDomain: 'Domeniul Agile CRM',
          nameIsRequired: 'Numele este obligatoriu',
          emailAddress: 'Adresă de email',
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Testează Prospectul',
          questionColon: 'Întrebare:',
          addParameter: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            exampleSite: 'https://companie.agilecrm.com',
            exampleEmail: 'exemplu@companie.extensie',
            enterTokenHere: 'Introduceți token aici',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiKey: 'Cheie API',
          accessKey: 'Cheie de Acces',
          connect: 'Conectare',
          testLead: 'Testează Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiKeyHere: 'Introduceți Cheia API aici',
            enterAccessKeyHere: 'Introduceți Cheia de Acces aici',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          testLead: 'Testează Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți Tokenul API',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          nameColon: 'Nume:',
          inboundWebhookUrl: 'URL webhook de intrare',
          nameIsRequired: 'Numele este obligatoriu',
          leadTitle: 'Titlu Prospect',
          source: 'Sursă',
          connect: 'Conectare',
          testLead: 'Testează Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterInboundWebhookUrl: 'Introduceți URL-ul webhook de intrare...',
            enterTitle: 'Introduceți titlul',
            enterSource: 'Introduceți sursa'
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiToken: 'Token API',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți token aici',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          testLead: 'Testează Prospectul',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți Tokenul API',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiToken: 'Token API',
          testLead: 'Testează Prospectul',
          connect: 'Conectare',
          selectTheQuestion: 'Selectează întrebarea a cărei răspuns de la utilizator dorești să-l trimiți ca valoare',
          mapWith: 'asociază cu',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți Tokenul API',
            attributes: 'Atribute (cheie)',
            values: 'valori',
          }
        },
        chatGpt: {
          chatGptApiKey: 'Cheie API ChatGPT (Open AI)',
          chatGpt: 'ChatGPT (Open AI)',
          readyToRideWave: 'Gata să îți așezi pe valul GPT? Integrează acum.',
          doNotHaveAnyApiKeyNeedHelp: 'Nu ai nicio cheie API, Ai nevoie de ajutor?',
          authenticate: 'Autentificare',
          nowYouCanAccessChatGpt: 'Acum poți accesa componenta Chat GPT în constructorul fluxului de chat',
          paymentSuccessful: 'Plată reușită!',
          paymentHasBeenCompletedSuccessfully: 'Plata a fost finalizată cu succes. Ești gata să-ți antrenezi botul',
          cancel: 'Anulare',
          trainNow: 'Antrenează acum',
          paymentInProgress: 'Plată în desfășurare...',
          apiKey: 'Cheie API',
          model: 'Model',
          integrateWithChatGPT: 'Integră cu ChatGPT',
          updateKey: 'Actualizează ',
          connect: 'Conectare',
          update: 'Actualizare',
          placeholders: {
            chatGptApiKey: 'Cheie API ChatGPT (Open AI)',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Testați Prospectul',
          leadId: 'Id Prospect',
          selectLeadId: 'Selectați Id-ul Prospectului',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți token aici',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          emailAddress: 'Adresă de email',
          leadTitle: 'Titlu Prospect',
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          testLead: 'Testați Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            exampleEmail: 'exemplu@companie.extensie',
            enterYourLeadTitle: 'Introduceți titlul Prospectului',
            enterTokenHere: 'Introduceți token aici',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          dripCrmAccountId: 'Id Cont Drip CRM',
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Testați Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            enterAccountIdHere: 'Introduceți Id-ul Contului aici',
            enterYourTokenHere: 'Introduceți token aici',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          apikey: 'Cheie API',
          accessToken: 'Cheie de Acces',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          selectParameter: 'Selectați Parametrul',
          disconnect: 'Deconectare',
          update: 'Actualizați',
          save: 'Salvați',
          placeholders: {
            enterApiKeyHere: 'Introduceți Cheia API aici',
            enterTokenHere: 'Introduceți Cheia de Acces aici',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          apiKey: 'Cheie API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          testLead: 'Testați Prospectul',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți Cheia API',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          authToken: 'AuthToken',
          source: 'Sursă',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            enterAuthTokenHere: 'Introduceți AuthToken aici',
            enterSourceHere: 'Introduceți Sursa aici'
          }
        },
        facebookPixel: {
          facebookPixel: 'Pixel Facebook',
          getInsight: `Obțineți Insights care transformă afacerea într-o întreprindere cu integrarea Google Analytics a BotPenguin`,
          pixelId: 'Id Pixel Facebook',
          connect: 'Conectare',
          disconnect: 'Deconectare',
          placeholders: {
            enterYourId: 'Introduceți Id-ul Pixelului Facebook'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          companyName: 'Nume Companie',
          emailAddress: 'Adresă de email',
          password: 'Parolă',
          connect: 'Conectare',
          testLead: 'Testați Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapsWith: 'asociază cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            enterCompanyName: 'Introduceți numele Companiei',
            enterEmailAddress: 'Introduceți adresa de email',
            enterPassword: 'Introduceți Parola',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testați Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiKeyHere: 'Introduceți Cheia API aici',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          flowluDomain: 'Domeniul Flowlu',
          apiToken: 'Token API',
          title: 'Titlu',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            exampleDomain: 'https://companiata.flowlu.com',
            enterTokenHere: 'Introduceți Token-ul aici',
            enterYourLeadTitle: 'Introduceți titlul Prospectului',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ați creat cu succes Integrarea. Acum puteți să o configurați.',
          freshdeskDomain: 'Domeniul Freshdesk',
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectare',
          placeholders: {
            exampleDomain: 'https://subdomeniul.tau.freshdesk.com',
            enterTokenHere: 'Introduceți Token-ul aici',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          freshworkCrmDomain: 'Domeniu Freshwork CRM',
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Prospect de test',
          questionColon: 'Întrebare:',
          addParameters: 'Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            exampleDomain: 'https://companiatadomeniu.myfreshworks.com',
            enterToken: 'Introduceți token aici'
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Prospect de test',
          questionColon: 'Întrebare:',
          addParameters: 'Adaugă Parametri',
          mapWith: 'asociază cu',
          select: 'Selectează',
          update: 'Actualizează',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți Tokenul API',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Prospect de test',
          campaignList: 'Listă de Campanii',
          selectCampaign: 'Selectează Campania',
          questionColon: 'Întrebare:',
          addParameters: 'Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți token aici',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          getInsights: `Obțineți insight-uri care transformă afacerea dvs. într-o întreprindere cu integrarea Google Analytics de la BotPenguin`,
          measurementId: 'ID măsurare ',
          connect: 'Conectare',
          disconnect: 'Deconectare',
          placeholders: {
            enterYourId: 'Introduceți ID-ul dvs.',
          }
        },
        googleCalendar: {
          testLead: 'Test Lead',
          name: 'Nume:',
          email: 'E-mail:',
          appointmentSummary: 'Rezumatul programării',
          appointmentDescription: 'Descrierea programării',
          addParameters: ' Adăugați Parametri',
          questionColon: 'Întrebare:',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          pause: 'Pauză',
          resume: 'Reia',
          disconnect: 'Deconectare',
          reconnect: 'Reconectare',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes o integrare. Acum puteți să o configurați.`,
          name: 'Nume :',
          email: 'E-mail : ',
          testLead: 'Test Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
        },
        googleRedirect: {

        },
        googleSheet: {
          testLead: 'Test Lead',
          title: 'Titlu',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          update: 'Actualizare',
          save: 'Salvare',
          pause: 'Pauză',
          resume: 'Reia',
          disconnect: 'Deconectare',
          reconnect: 'Reconectare',
          placeholders: {
            mappingValues: 'Valori de asignare',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes o integrare. Acum puteți să o configurați.`,
          name: 'Nume:',
          email: 'E-mail:',
          taskList: 'Listă de sarcini',
          testLead: 'Test Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          pause: 'Pauză',
          resume: 'Reia',
          disconnect: 'Deconectare',
          reconnect: 'Reconectare',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes o integrare. Acum puteți să o configurați.`,
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Test Lead',
          questionColon: 'Întrebare:',
          select: 'Selectați',
          addParameters: ' Adăugați Parametri',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți token aici',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes o integrare. Acum puteți să o configurați.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiHere: 'Introduceți API aici',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes o integrare. Acum puteți să o configurați.`,
          testLead: 'Test Lead',
          accountId: 'ID cont',
          accessToken: 'Token de acces',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterAccountIdHere: 'Introduceți ID cont aici',
            enterAccessTokenHere: 'Introduceți Token de acces aici',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes o integrare. Acum puteți să o configurați.`,
          apiToken: 'Token API',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          testLead: 'Test Lead',
          addParameters: ' Adăugați Parametri',
          select: 'Selectați',
          mapWith: 'asociați cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți token aici',
          }
        },
        helpLink: {
          help: 'Ajutor',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          helpRaceCrmDomain: 'Domeniu Helprace CRM',
          emailAddress: 'Adresă de e-mail',
          apiToken: 'Token API',
          connect: 'Conectare',
          update: 'Actualizare',
          placeholders: {
            exampleDomain: 'https://companie.helprace.com',
            exampleEmail: 'exemplu@companie.extensie',
            token: 'Token'
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          domainName: 'Nume domeniu',
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți tokenul aici'
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți tokenul API'
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiHere: 'Introduceți API-ul aici'
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți tokenul API'
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiToken: 'Token API',
          emailAddress: 'Adresă de e-mail',
          apiUrl: 'URL API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          //
          selectProjectName: 'Selectați numele proiectului',
          selectIssueType: 'Selectați tipul de problemă',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapsWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți tokenul aici',
            enterEmailAddress: 'Introduceți adresa de e-mail',
            exampleUrl: 'https://exemplu.atlassian.net'
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiUrl: 'URL API',
          nameIsRequired: 'Numele este obligatoriu',
          apiKey: 'Cheie API',
          apiId: 'ID API',
          connect: 'Conectare',
          //
          testLead: 'Testare principală',
          mapWith: 'Hartă cu',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          selectTheQuestion: 'Selectați întrebarea ale cărei răspunsuri ale utilizatorului doriți să le trimiteți ca valoare',
          placeholders: {
            enterApiUrl: 'Introduceți URL-ul API',
            enterApiKey: 'Introduceți cheia API',
            enterApiId: 'Introduceți ID-ul API',
            attributes: 'Atribute(cheie)',
            values: 'valori',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți tokenul aici'
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți tokenul API'
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          accessKey: 'Cheie de acces',
          secretKey: 'Cheie secretă',
          connect: 'Conectare',
          apiLogs: 'Jurnale API',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          udpate: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          filterApplied: 'filtru aplicat',
          retry: 'Reîncercați',
          back: 'Înapoi',
          filterYourLeads: 'Filtrare leads-uri',
          filterOn: 'Filtru pe',
          filterBy: 'Filtrare după ',
          discard: 'Renunțare',
          applyFilter: 'Aplicare filtru',
          loadMore: 'Încărcați mai mult',
          placeholders: {
            enterAccessKeyHere: 'Introduceți cheia de acces aici',
            enterSecretKeyHere: 'Introduceți cheia secretă aici',
            attributes: 'Atribute(cheie)',
            values: 'valori',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Filtrarea datelor în funcție de răspuns',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ați creat cu succes integrarea. Acum o puteți configura.`,
          apiKey: 'Cheie API',
          apiUrl: 'URL API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: 'Adăugare parametri',
          select: 'Selectare',
          mapWith: 'Hartă cu',
          selectParameter: 'Selectare parametru',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiHere: 'Introduceți API-ul aici',
            exampleUrl: 'https://comapanyName.ladesk.com'
          }
        },
        microsoftTeams: {
          downloadPackage: 'Descarcă pachetul de aplicații Microsoft Teams',
          downloadApp: 'Descarcă Aplicația',
          wantToLearnHowToUploadIt: 'Vrei să înveți cum să îl încarci?',
          needHelp: 'Ai nevoie de ajutor?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiUrl: 'URL API',
          appId: 'ID Aplicație',
          nameIsRequired: 'ID Aplicație este obligatoriu',
          dataApiKey: 'CHEIE API DATE',
          dataApiKeyIsRequired: 'Cheie API Date este obligatorie',
          connect: 'Conectare',
          testLead: 'Testare principală',
          addParameters: ' Adaugă Parametri',
          mapWith: 'harta cu',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          selectTheQuestion: 'Selectează întrebarea ale cărei răspunsuri ale utilizatorului doriți să le trimiteți ca valoare',
          placeholders: {
            enterApiUrl: 'Introduceți URL-ul API',
            enterAppId: 'Introduceți ID-ul Aplicației',
            enterDataApiKey: 'Introduceți cheia API Date',
            attributes: 'Atribute(cheie)',
            pressForMappingQuestion: 'Apăsați / pentru Harta cu întrebări',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          emailAddress: 'Adresă de email',
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          leadId: 'ID Lead',
          selectLeadId: 'Selectați ID Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterEmailHere: 'Introduceți adresa de email aici',
            enterApiKeyHere: 'Introduceți cheia API aici',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți cheia API',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          noCrmDomain: 'Domeniu Nocrm',
          leadTitle: 'Titlu Lead',
          titleIsRequired: 'Titlul este obligatoriu',
          token: 'Token',
          tokenIsRequired: 'Tokenul este obligatoriu',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            exampleDomain: 'https://SUBDOMENIUL_TĂU_AICI.nocrm.io',
            enterYourLeadTitle: 'Introduceți titlul Lead-ului dvs.',
            enterYourToken: 'Introduceți Token-ul dvs.',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          emailAddress: 'Adresă de email',
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți cheia API',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          secretToken: 'Token Secret',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți Token aici',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          appId: 'ID Aplicație',
          appIdIsRequired: 'ID Aplicație este obligatoriu',
          apiKey: 'Cheie API',
          apiKeyIsRequired: 'Cheia API este obligatorie',
          connect: 'Conectare',
          update: 'Actualizare',
          disconnect: 'Deconectare',
          placeholders: {
            appId: 'ID Aplicație',
            apiKey: 'Cheie API',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          clientId: 'ID Client',
          connect: 'Conectare',
          clientSecret: 'Secret Client',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          save: 'Salvare',
          update: 'Actualizare',
          disconnect: 'Deconectare',
          placeholders: {
            enterClientIdHere: 'Introduceți ID-ul Clientului aici',
            enterClientSecretHere: 'Introduceți Secretul Clientului aici',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          pipedriverDomain: 'Domeniu Pipedrive',
          domainIsRequired: 'Domeniul este obligatoriu',
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            exampleDomain: 'https://companiata.pipedrive.com',
            enterTokenHere: 'Introduceți token-ul aici',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiKey: 'Cheie API',
          apiKeyIsRequired: 'Cheia API este obligatorie',
          appKey: 'Cheie APP',
          appKeyIsRequired: 'Cheia APP este obligatorie',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiKey: 'Introduceți cheia API',
            enterAppKey: 'Introduceți cheia APP',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          authId: 'ID Autentificare',
          authToken: 'Token Autentificare',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți Token aici',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiHere: 'Introduceți API aici',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiKey: 'Introduceți cheia API',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          domain: 'Domeniu',
          clientId: 'ID Client',
          clientSecret: 'Secret Client',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterDomainHere: 'Introduceți Domeniul aici',
            enterClientIdHere: 'Introduceți ID-ul Clientului aici',
            enterClientSecretHere: 'Introduceți Secretul Clientului aici',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testare principală',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectare',
          mapWith: 'harta cu',
          selectParameter: 'Selectați Parametrul',
          addEventsAndTemplates: 'Adaugă Evenimente și Șabloane:',
          add: ' Adaugă',
          question: 'Întrebare',
          reply: 'Răspuns',
          requestBody: 'Corp Cerere',
          validateJson: 'Validați JSON',
          emailAddress: 'Adresă de email',
          //
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          selectTheQuestion: 'Selectați întrebarea al cărei răspuns al utilizatorului doriți să-l trimiteți ca valoare',
          placeholders: {
            enterApiHere: 'Introduceți API aici',
            enterTemplateId: 'Introduceți ID-ul Șablonului',
            typeYourJsonCodeHere: 'Tastați codul JSON aici',
          },
          appTooltip: {
            typeYourJsonCode: 'Tastați codul JSON',
            clickToValidateJsonCode: 'Apăsați pentru a valida codul JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          userName: 'Nume utilizator',
          licenseKey: 'Cheie de licență',
          connect: 'Conectare',
          testLead: 'Testează Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterNameHere: 'Introduceți numele aici',
            enterKeyHere: 'Introduceți cheia aici',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiToken: 'Token API',
          connect: 'Conectare',
          testLead: 'Testează Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți tokenul aici',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes integrarea. Acum o poți configura.',
          company: 'Companie',
          loginId: 'ID de conectare',
          password: 'Parolă',
          testLead: 'Lider de testare',
          connect: 'Conectați-vă',
          selectTheQuestion: 'Selectați întrebarea ale cărei răspunsuri de la utilizatori doriți să le trimiteți ca valoare',
          mapWith: 'Asociați cu',
          update: 'Actualizați',
          save: 'Salvați',
          disconnect: 'Deconectați-vă',
          selectParameter: 'Selectare parametru',
          placeholders: {
            enterCompanyHere: 'Introduceți numele companiei aici',
            enterLoginIdHere: 'Introduceți ID-ul de conectare aici',
            enterPasswordHere: 'Introduceți parola aici',
            values: 'valori',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          applicationKey: 'Cheie Aplicație',
          authToken: 'Token Autentificare',
          connect: 'Conectare',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApplicationKeyHere: 'Introduceți cheia aplicației aici',
            enterTokenHere: 'Introduceți tokenul aici',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          accessToken: 'Token Acces',
          connect: 'Conectare',
          testLead: 'Testează Lead',
          question: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterTokenHere: 'Introduceți tokenul aici',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          webhoperDomain: 'Domeniu Webhoper',
          domainIsRequired: 'Domeniul este obligatoriu',
          companyName: 'Nume Companie',
          companyIsRequired: 'Compania este obligatorie',
          leadSource: 'Sursă Lead',
          leadSourceIsRequired: 'Sursa Lead este obligatorie',
          division: 'Divizie',
          divisionIsRequired: 'Divizia este obligatorie',
          enterPassword: 'Introduceți Parola',
          passwordIsRequired: 'Parola este obligatorie',
          disconnect: 'Deconectare',
          update: 'Actualizare',
          connect: 'Conectare',
          placeholders: {
            exampleDomain: 'http://domeniulcompaniei',
            enterYourCompanyName: 'Introduceți numele companiei',
            enterLeadSource: 'Introduceți Sursa Lead',
            enterDivision: 'Introduceți Divizia',
            enterPassword: 'Introduceți parola',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          name: 'Nume:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'URL Inbound webhook',
          connect: 'Conectare',
          testLead: 'Testează Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterWebhookUrl: 'Introduceți URL-ul webhook-ului',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiToken: 'Token API',
          emailAddress: 'Adresă Email',
          apiUrl: 'URL API',
          connect: 'Conectare',
          testLead: 'Testează Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterEmailAddress: 'Introduceți adresa de email',
            enterUrlHere: 'Introduceți URL-ul aici',
            enterTokenHere: 'Introduceți tokenul aici',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          apiKey: 'Cheie API',
          connect: 'Conectare',
          testLead: 'Testează Lead',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiHere: 'Introduceți API-ul aici',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          connect: 'Conectare',
          update: 'Actualizare',
          disconnect: 'Deconectare',
          companyName: 'Nume Companie',
          emailAddress: 'Adresă Email',
          phone: 'Telefon',
          countryCode: 'Cod țară',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          //
          save: 'Salvare',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          connect: 'Conectare',
          disconnect: 'Deconectare',
          testLead: 'Testează Lead',
          emailAddress: 'Adresă Email',
          selectOrganizationName: 'Selectează Numele Organizației',
          selectDepartmentName: 'Selectează Numele Departamentului',
          addParameters: ' Adaugă Parametri',
          questionColon: 'Întrebare:',
          select: 'Selectează',
          mapsWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
          testLead: 'Testează Lead',
          connect: 'Conectare',
          disconnect: 'Deconectare',
          projectsList: 'Listă de Proiecte',
          selectProjectsName: 'Selectează Numele Proiectelor',
          projectsTaskList: 'Listă de Sarcini pentru Proiecte',
          selectTaskListName: 'Selectează Numele Listei de Sarcini',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizare',
          save: 'Salvare',
        },
        wooCommerce: {
          configure: 'Configurare',
          automatedMessage: 'Mesaj Automatizat',
          configureTab: {
            wooCommercePluginApiKey: 'Cheie API Plugin WooCommerce',
            downloadWooCommercePlugin: 'Descărcați Pluginul WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Mesaj de Recuperare a Coșurilor Abandonate',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Configurați mesajele automate de recuperare pentru a recâștiga clienții care au abandonat coșurile lor',
            enableAbandonedCartRecoveryMessaegs: 'Activați mesajele de recuperare a coșului abandonat',
            selectTemplate: 'Selectați Șablonul',
            sendMessageAfter: 'Trimiteți Mesajul După',
            templateMessage: 'Mesajul Șablon',
            templateHeader: 'Antet Schemă',
            saveChanges: 'Salvați Modificările',
            selectFile: 'Selectați Fișierul',
            orderFulfillMent: 'Îndeplinirea Comenzii',
            orderCompleted: 'Comandă Finalizată',
            enableOrderConfirmationMessages: 'Activați mesajele de confirmare a comenzii',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Trimiteți un mesaj de confirmare a comenzii imediat ce un client plasează o comandă.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Mesajul de expediere al comenzii este trimis atunci când îndepliniți o comandă.',
            enableOrderFulfillmentMessages: 'Activați mesajele de îndeplinire a comenzii',
            returnExchangeRequest: 'Solicitare Returnare/Schimb',
            theMessageIsSentAfterARefundIsRequested: 'Mesajul este trimis după ce este solicitat un ramburs.',
            enableOrderRefundMessages: 'Activați mesajele de rambursare a comenzii',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Ai creat cu succes Integrarea. Acum o poți configura.',
          apiToken: 'Token API',
          nameIsRequired: 'Numele este obligatoriu',
          connect: 'Conectare',
          testLead: 'Testează Prospectul',
          questionColon: 'Întrebare:',
          addParameters: ' Adaugă Parametri',
          select: 'Selectează',
          mapWith: 'asociază cu',
          selectParameter: 'Selectează Parametrul',
          update: 'Actualizează',
          save: 'Salvează',
          disconnect: 'Deconectare',
          placeholders: {
            enterApiToken: 'Introduceți Tokenul API',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Creează o Integrare Nouă',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'Momentan nu ai nicio integrare configurată.',
      pleaseCreateOneToIntegrateYourFavourite: 'Te rugăm să creezi una pentru a-ți integra favoritele',
      appsWith: 'aplicații cu ',
      integrationName: 'Nume Integrare',
      integrationNameIsRequired: 'Numele integrării este obligatoriu',
      continue: 'Continuă',
      addAnImageToRepresentYourIntegration: 'Adaugă o imagine pentru a reprezenta integrarea ta.',
      description: 'Descriere',
      submit: 'Trimite',
      youHaveSuccessfullyCreatedIntegration: `Ai creat cu succes integrarea. Acum o poți configura.`,
      apiUrl: 'URL API',
      pleaseEnterAValidApiUrl: 'Te rugăm să introduci un URL API valid',
      requestMethod: 'Metoda Cererii',
      get: 'Get',
      post: 'Post',
      put: 'Put',
      patch: 'Patch',
      queryParamter: 'Parametru de Interogare',
      addParameter: ' Adaugă Parametru',
      mapsWith: ' se asociază cu',
      question: 'Întrebare',
      addQuestion: ' Adaugă Întrebare',
      select: 'Selectează',
      selectParameter: 'Selectează Parametrul',
      header: 'Antet',
      addHeader: ' Adaugă Antet',
      value: 'Valoare',
      connect: 'Conectare',
      requestBody: 'Corp Cerere',
      validate: 'Validează',
      //
      key: 'Cheie',
      //
      //
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' poate fi de asemenea integrat cu acest CRM gratuit.',
        apiUrl: 'URL API',
        pleaseEnterAValidApiUrl: 'Te rugăm să introduci un URL API valid',
        requestMethod: 'Metoda Cererii:',
        get: 'Get',
        post: 'Post',
        put: 'Put',
        patch: 'Patch',
        queryParameter: 'Parametru de Interogare',
        addParameter: ' Adaugă Parametru',
        header: 'Antet',
        addHeader: ' Adaugă Antet',
        requestBody: 'Corp Cerere',
        validateJson: 'Validează JSON',
        connect: 'Conectare',
        update: 'Actualizează',
        delete: 'Șterge',
        selectTheQuestion: 'Selectează întrebarea ale cărei răspuns al utilizatorului dorești să-l trimiți ca valoare',
        appTooltip: {
          typeYourJsonCode: 'Tastează codul tău JSON',
          clickToValidateJsonCode: 'Click pentru a valida codul JSON',
        }
      },
      list: {
        customAppsIntegrations: 'Integrări Aplicații Personalizate',
        integrateYourAppsWith: 'Integrează-ți propriile aplicații cu ',
        help: 'Ajutor',
        addIntegration: ' Adaugă Integrare',
        name: 'Nume',
        status: 'Stare',
        action: 'Acțiune',
        insatlled: 'Instalat',
        edit: 'Editează',
        connect: 'Conectare',
        requestYourIntegration: 'Solicită-ți Integrarea',
        toRequestNewIntegration: 'Pentru a solicita o nouă integrare, te rugăm să introduci detaliile ' +
          'menționate mai jos și apoi vom reveni la tine.',
        //
        emailId: 'Adresă de Email',
        integrationName: 'Nume Integrare',
        contactNumber: 'Număr de Contact',
        howSoonYouWantToAddThisIntegration: 'Cât de curând dorești să adaugi această Integrare',
        sendRequest: 'Trimite Cererea',
        cancel: 'Anulează',
        delete: 'Șterge',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Oups! Propriile integrări sunt acceptate doar în',
        plan: 'Plan',
        pleaseUpgradeTo: 'Vă rugăm să faceți upgrade la',
        toCreateOwnIntegrations: 'pentru a crea Integrări Proprii',
        appTooltip: {
          integrations: 'Integrări',
          noIntegrationsFound: 'Nicio integrare găsită!',
          nameOfYourApp: 'Numele aplicației tale',
          clickToConnectApp: 'Click pentru a conecta aplicația',
          statusAsConnectedOrNotConnected: 'Stare ca conectat / neconectat',
        }
      }
    }
  }
};
