export const notification = {
  notification: 'अधिसूचना',
  allNotifications: 'सभी अधिसूचनाएं',
  general: 'आम',
  settings: 'समायोजन',
  newChatRequest: {
    youHaveANewChatRequest: 'आपके पास नया चैट अनुरोध है !!!',
    youHaveTransferredChatRequest: 'आपने चैट अनुरोध स्थानांतरित कर दिया है !!!',
    acceptRequest: 'अनुरोध स्वीकार करें',
    discardRequest: 'अनुरोध को त्यागना'
  },
  notificationList: {
    name: 'नाम',
    message: 'संदेश',
    noDataFound: {
      notification: 'अधिसूचना',
      noNotificationsFound: 'कोई सूचना नहीं मिली!'
    }
  },
  notificationSetting: {
    userMessage: 'उपयोगकर्ता संदेश',
    receiveNotificationsWhenUserSendMessage: 'जब भी कोई उपयोगकर्ता कोई संदेश भेजता है तो सूचनाएं प्राप्त करें',
    liveChatRequest: 'लाइव चैट अनुरोध',
    receiveNotificationsWhenUserRequestLiveChat: 'जब भी कोई उपयोगकर्ता लाइव चैट के लिए अनुरोध प्राप्त करता है तो सूचनाएं प्राप्त करें',
    newLead: 'नया नेतृत्व',
    receiveNotificationsWhenNewLeadCreated: 'जब भी नई लीड बनाई जाती है तो सूचनाएं प्राप्त करें',
    assignedOnly: 'केवल सौंपा',
    allUsers: 'सभी उपयोगकर्ता',
    customizeWhichNotificationsYouWantToRecieve: 'आप जो सूचनाएं प्राप्त करना चाहते हैं, उसे अनुकूलित करें',
    youCanCustomizeSettingForTheNotifications: 'आप सूचनाओं के लिए सेटिंग को अनुकूलित कर सकते हैं',
    receiveNotifications: 'सूचनाएं प्राप्त करें',
    desktopNotifications: 'डेस्कटॉप सूचनाएँ',
    mobilePushNotifications: 'मोबाइल पुश नोटिफिकेशन',
    discard: 'खारिज करना',
    update: 'अद्यतन',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'डेस्कटॉप पर नए संदेश की सूचनाएं भेजें',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'मोबाइल पर नए संदेश की सूचनाएं भेजें',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'डेस्कटॉप पर नए लीड की सूचनाएं भेजें',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'मोबाइल पर नए लीड की सूचनाएं भेजें',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'डेस्कटॉप पर लाइव चैट की सूचनाएं भेजें',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'मोबाइल पर लाइव चैट की सूचनाएं भेजें',
      TOOLTIP_RECEIVED_NOTIFICATION: 'चुनें कि आप किन संदेशों को सूचित करना चाहते हैं'
    }
  }
};
