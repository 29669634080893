import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { NetworkService } from 'src/appv2/services/network.service';

@Component({
  selector: 'app-login-wrapper',
  templateUrl: './login-wrapper.component.html',
  styleUrls: ['./login-wrapper.component.scss']
})
export class LoginWrapperComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private networkService: NetworkService
  ) {
    this.route.queryParamMap.subscribe(params => {
      const token = params.get('token');
      const jwtToken = params.get('jwtToken');

      if (!token && !jwtToken) {
        this.sharedService.showToast('error', '', 'Please provide valid access token or login with username or password');
        return this.router.navigate(['/authentication']);
      }
      if (jwtToken) {
        localStorage.setItem('token', jwtToken);
        return window.location.href = '/agency/dashboard';
      }

      const redirectUri = params.get('redirect_uri');
      const fullScreen = params.get('fullscreen');
      const sidebar = fullScreen === '1' ? '?s=0' : '';

      if (!token) {
        this.sharedService.showToast('error', '', 'Please provide valid access token or login with username or password');
        return this.router.navigate(['/authentication']);
      }
      if (token) {
        this.sharedService.loader('start');
        this.networkService.httpRequest(`auth/get-token?token=${token}`)
          .then((response: { access_token: string; type: string; status: string; _parent: string }) => {
            if (response.access_token) {
              localStorage.setItem('token', response.access_token);
              if (response.type === 'AGENCY') {
                window.location.href = (redirectUri || '/agency/dashboard') + sidebar;
              } else if (response.type === 'AGENT') {
                window.location.href = (redirectUri || '/inbox/leads') + sidebar;
              } else {
                window.location.href = (redirectUri || '/home') + sidebar;
              }
            } else if (response.status === 'INACTIVE') {
              return this.router.navigate(['/confirm-email'], { queryParams: { id: response._parent } });
            }
          })
          .finally(() => this.sharedService.loader('stop'))
          .catch(error => {
            this.sharedService.showToast('error', '', error?.message || error);
            return this.router.navigate(['/authentication']);
          });
      }
    });

  }

  ngOnInit(): void {
  }

}
