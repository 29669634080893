export const team = {
  addMember: {
    team: 'Equipo',
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    addAMember: 'Agregar un miembro',
    addYourTeamMembersText: 'Agregue los miembros de su equipo como agentes de soporte para chatear en vivo con los usuarios de BOT.',
    name: 'Nombre',
    nameIsRequired: 'Se requiere el nombre',
    email: 'Correo electrónico',
    enterAValidEmail: 'Introduzca un correo electrónico válido',
    password: 'Contraseña',
    passwordMustContain: 'La contraseña debe contener',
    atleast6Characters: 'al menos 6 caracteres,',
    oneUpperCaseLetter: '1 letra mayúscula',
    oneSpecialCharacter: '1 personaje especial*',
    profileIcon: 'Icono de perfil',
    whatsappNumber: 'Número de whatsapp',
    leadVisibility: 'Visibilidad de plomo*',
    addMember: 'Añadir miembro',
    role: 'Role',
    createdOn: 'Creado en',
    action: 'Acción',
    updateMember: 'Miembro de actualización',
    add: 'Agregar',
    cancel: 'Cancelar',
    selectVisibilityOption: 'Seleccionar la opción de visibilidad',
    all: 'Todo',
    assigned: 'Asignado',
    nA: 'N / A',
    admin: 'Administración',
    agent: 'Agente',
    placeholders: {
      enterMemberName: 'Ingrese el nombre del miembro',
      enterMemberEmailAddress: 'Ingrese la dirección de correo electrónico del miembro',
      createPasswordForMember: 'Crear una contraseña para el miembro',
      searchCountry: 'Búsqueda del país',
      enterWhatsappNumber: 'Ingrese el número de whatsapp',
      selectLeadsVisibility: 'Seleccionar visibilidad de los cables',
      searchTeamByNameOrEmail: 'Buscar miembro del equipo por nombre o correo electrónico',
    },
    modal: {
      deleteTeamMember: 'Eliminar miembro del equipo',
      wantToDeleteThisTeamMember: '¿Estás seguro de que quieres eliminar este miembro del equipo?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Haga clic para agregar un nuevo miembro del equipo',
      profileIconOfTeamMember: 'Icono/imagen de perfil del miembro del equipo',
      nameOfTeamMember: 'Nombre del miembro del equipo',
      emailOfTeamMember: 'Correo electrónico de miembro del equipo',
      whatsappNumberOfTeamMember: 'WhatsApp Número de miembro del equipo',
      roleOfTeamMemberAsAdminOrAgent: 'Cole del miembro del equipo como administrador/agente',
      dateAndTimeOfCreatingTeamMemberRole: 'Fecha y hora de crear rol de miembro del equipo',
      editOrDeleteTeamMember: 'Editar/eliminar miembro del equipo',
      editTeamMembersDetails: 'Editar detalles de los miembros del equipo.',
      removeAccessOfTeamMemberByDeletingAccount: 'Eliminar el acceso del miembro del equipo eliminando la cuenta',
    }
  },
  users: {
    manageMembers: 'Gestionar Miembros',
    managerMembersWhichCanAccessPanel: 'Administrar el equipo de colaboradores que puede acceder a su panel',
    createNewMember: 'Crear Nuevo Miembro',
    createMember: 'Crear Miembro',
    updateMember: 'Actualizar Miembro',
    deleteTitleMember: 'Eliminar Miembro',
    deleteDescriptionMember: '¿Estás seguro de que deseas eliminar a este miembro?',
    noMemberFound: 'No se encontraron Miembros',
    titleMember: 'Crear un Miembro',
    manageUsers: 'Gestionar Usuarios',
    createNewUser: 'Crear Nuevo Usuario',
    managerUserWhichCanAccessPanel: 'Administrar los usuarios que pueden acceder a su panel',
    phoneNumber: 'Número de teléfono',
    discard: 'Descartar',
    createUser: 'Crear usuario',
    updateUser: 'Actualizar usuario',
    noUserFOund: '¡No se encontró ningún usuario!',
    title: 'Crear un Usuario',
    profileIcon: 'Icono de Perfil*',
    name: 'Nombre*',
    email: 'Correo electrónico*',
    password: 'Contraseña*',
    selectRole: 'Seleccionar Rol*',
    selectARole: 'Seleccionar un rol',
    selectLeadVisibility: 'Seleccionar Visibilidad de Prospectos*',
    confirmPassword: 'Confirmar Contraseña*',
    tableHeadingName: 'Nombre',
    tableHeadingEmail: 'Correo electrónico',
    tableHeadingPhoneNumber: 'Número de teléfono',
    tableHeadingRole: 'Rol',
    tableHeadingStatus: 'Estado',
    tableHeadingAction: 'Acción',
    noUserFound: 'No se Encontró Ningún Usuario!',
    deleteTitle: 'Eliminar Usuarios',
    deleteDescription: '¿Está seguro de que desea eliminar a este usuario?',
    placeholders: {
      searchHere: 'Buscar aquí',
      enterName: 'Ingresar nombre',
      enterEmail: 'Ingresar correo electrónico',
      searchCountry: 'Buscar país',
      enterPhoneNumber: 'Ingresar número de teléfono',
      enterPassword: 'Ingresar contraseña',
      enterConfirmPassword: 'Confirmar contraseña'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Eliminar usuarios',
      TOOLTIP_DELETE_MEMBERS: 'Eliminar Miembros'
    }
  },
  roles: {
    manageRoles: 'Gestionar Roles',
    manageYourRoles: 'Gestiona tus roles.',
    name: 'Nombre*',
    description: 'Descripción',
    discard: 'Descartar',
    createRole: 'Crear Rol',
    creteARole: 'Crear un Rol',
    assignPermissions: 'Asignar Permisos',
    updateRole: 'Actualizar Rol',
    noUserFound: '¡No se encontraron roles de usuario!',
    tableHeadingName: 'Nombre',
    tableHeadingDescription: 'Descripción',
    tableHeadingStatus: 'Estado',
    tableHeadingAction: 'Acción',
    module: 'Módulo',
    read: 'Leer',
    write: 'Escribir',
    remove: 'Eliminar',
    notes: 'Notas',
    deleteTitle: 'Eliminar Rol',
    deleteDescription: 'Atención: Al eliminar este rol, también se eliminarán todos los usuarios asignados a este rol. \n ¿Está seguro de que desea eliminar este rol?',
    deleteMemberDescription: 'Precaución: Al eliminar este rol, también se eliminarán todos los miembros asignados a este rol. ¿Estás seguro de que quieres eliminar este rol?',
    placeholders: {
      enterRoleName: 'Ingrese el nombre del rol',
      searchHere: 'Buscar aquí',
      enterDescriptionForRole: 'Ingrese una descripción para el rol'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Secciones a las que puede permitir o bloquear el acceso de los usuarios',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Haga clic para agregar un nuevo rol',
    }
  },
  accessControl: {
    users: 'Usuarios',
    members: 'Miembros',
    partnerTeam : 'Equipo de socios',
    roles: 'Roles'
  }
};
