const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Hesabım',
  yourProfileAndPasswordSecurity: 'Profiliniz ve Şifre Güvenliğiniz',
  detailsHeader: 'Detaylar',
  passwordHeader: 'Şifre',
  customAttributeHeader: 'Özel Özellikler',
  tagsHeader: 'Etiketler',
  accessTokenHeader: 'Erişim Anahtarı',
  thirdPartyIntegrationsHeader: 'Üçüncü Taraf Entegrasyonlar',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Hesap profilinizi güncelleyin',
    TOOLTIP_PASSWORD_HEADING: 'Şifrenizi değiştirin',
    TOOLTIP_TAGS_HEADING: 'Etiketlerinizi Yönetin',
    TOOLTIP_THIRD_PARTY_HEADING: 'Üçüncü Taraf Entegrasyonları',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `Güvenli bir şifre, ${brand} hesabınızı korumaya yardımcı olur`,
    TOOLTIP_INTEGRATIONS: 'Entegrasyonlar',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Özel öznitelikleri ekleyin veya güncelleyin',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Özel öznitelikleri'
  },
  accessToken: {
    accessToken: 'Erişim Anahtarı',
    generateYourAccessTokenForUsingPlatformAPIs: `Platform API'larını kullanmak için erişim anahtarınızı oluşturun`,
    generateApiToken: 'API Anahtarı Oluştur',
    revokeApiToken: 'API Anahtarı İptal Et',
    regenerateApiToken: 'API Anahtarı Yeniden Oluştur',
    placeholders: {
      yourApiKey: 'API Anahtarınız',
    }
  },
  details: {
    name: 'Ad',
    emailId: 'E-posta Kimliği',
    yourPlan: 'Planınız',
    messagesPerMonth: ' mesaj/ay',
    daysOfStoragePlan: ' gün saklama planı',
    conditionalFeatures: 'koşullu özellikler',
    upgradeToEnterprisePlanToGetMore: 'Daha fazlasını almak için Enterprise planına yükseltin',
    featuresAndStorageCapacity: 'özellik ve depolama kapasitesi',
    upgradePlan: 'Planı Yükselt',
    submit: 'Gönder',
    selectLanguage: 'Dil Seç',
    baby: 'Baby',
    king: 'King',
    conversationsperMonth: 'konuşmalar/ay',
    english: 'İngilizce',
    hindi: 'Hintçe',
    german: 'Almanca',
    french: 'Fransızca',
    portuguese: 'Portekizce',
    spanish: 'İspanyolca',
    italian: 'İtalyanca',
    russian: 'Rusça',
    chatBotBrandName: 'ChatBot markası',
    placeholders: {
      john: 'John',
      email: 'E-posta',
      searchLanguage: 'Dil Ara',
      chatBotBrandName: 'ChatBot markası',
    },
    appTooltip: {
      TOOLTIP_NAME: 'Profil adınızı girin',
      TOOLTIP_EMAIL_ADDRESS: 'E-posta adresinizi girin',
    }
  },
  customAttribute: {
    addAttribute: 'Özellik Ekle',
    discard: 'At',
    customAttribute: 'Özel Özellikler',
    checkOrAddCustomAttribute: 'Burada özel özelliklerinizi kontrol edebilir ve ekleyebilirsiniz.',
    attributeKey: 'Özellik Anahtarı',
    attributeName: 'Özellik Adı',
    bot: 'Bot',
    flow: 'Akış',
    loadMore: 'Daha Fazla Yükle',
    question: 'Soru',
    action: 'Eylem',
    search: 'Ara',
    selectBot: 'Bot Seç',
    selectFlow: 'Akış Seç',
    selectQuestion: 'Soru Seç',
    noDataFoundHeading: 'Henüz oluşturulmuş bir özellik yok',
    noDataFoundSubheading: 'Tüm özellikleriniz burada görüntülenecektir',
    deleteTitle: 'Özelliği Sil',
    deleteDescription: 'Bu özelliği silmek istediğinizden emin misiniz?',
    deleteAttributes: 'Özellikleri Sil',
    areYouSureYouWantToDeleteThisAttributes: 'Bu özelliği silmek istediğinizden emin misiniz?',
    editAttribute: 'Özelliği Düzenle',
    headsUp: 'Dikkat',
    okay: 'Tamam',
    dataAddedToLead: `API'lerinize veya Bot Akışlarınıza eşlenen özellikler güncellenmeyecektir. Lütfen API'leri veya Bot Akışlarını buna `,
    placeholders: {
      enterAttributeKey: 'Özellik Anahtarı Girin',
      enterAttributeName: 'Özellik Adını Girin',
      selectBot: 'Bot Seç',
      selectFlow: 'Akış Seç',
      selectQuestion: 'Soru Seç',
      search: 'Özellik Adına Göre Ara',
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Özellik Anahtarı Girin',
      TOOLTIP_ATTRIBUTE_NAME: 'Özellik Adını Girin',
      TOOLTIP_SELECT_BOT: 'Bot Seçin',
      TOOLTIP_SELECT_FLOW: 'Akış Seçin',
      TOOLTIP_SELECT_QUESTION: 'Soru Seçin',
    }
  },
  password: {
    changePassword: 'Şifre Değiştir',
    aSecurePasswordHelpsProtectYour: 'Güvenli bir şifre, ',
    account: ' Hesabınızı korumaya yardımcı olur',
    currentPassword: 'Mevcut Şifre',
    newPassword: 'Yeni Şifre',
    confirmPassword: 'Şifreyi Onayla',
    cancel: 'İptal',
    updatePassword: 'Şifre Güncelle',
    deleteAccount: 'Hesabı Sil',
    placeholders: {
      enterYourCurrentPassword: 'Mevcut Şifrenizi Girin',
      enterYourNewPassword: 'Yeni şifrenizi girin',
      enterYourConfirmPassword: 'Şifrenizi onaylayın',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Eski şifreyi girin',
      TOOLTIP_NEW_PASSWORD: 'Yeni şifreyi girin',
      TOOLTIP_CONFIRM_PASSWORD: 'Yeni şifrenizi onaylayın',
      TOOLTIP_DELETE_ACCOUNT: `${brand} Hesabınızı silin`,
    }
  },
  tags: {
    manageTags: 'Etiketleri Yönet',
    createNewTags: 'Yeni Etiketler Oluştur',
    createNewTagsSubText: 'Leads, sohbetler, gruplar vb. gibi öğeleri daha iyi düzenlemek için etiketlerinizi burada yönetin',
    tagName: 'Etiket Adı',
    updateTag: 'Etiketi Güncelle',
    createTag: 'Etiket Oluştur',
    discard: 'At',
    searchTag: 'Adına Göre Etiket Ara',
    noTagFound: 'Etiket Bulunamadı!',
    createATag: 'Etiket Oluştur',
    deleteTagModalSubText: 'Bu etiketi silmek istediğinizden emin misiniz? Bu etiketi atan kullanıcılardan da kaldıracaktır!',
    deleteTagModalHeading: 'Etiketleri Sil',
    deleteOneTagModalHeading: 'Etiketi Sil',
    actions: 'İşlemler',
    placeholders: {
      enterTag: 'Etiket Adını Girin',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'Etiketleri Sil',
    }
  }
  ,
  thirdPartyIntegration: {
    list: {
      name: 'Adı',
      status: 'Durum',
      action: 'Aksiyon',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'Mesajlar ve E-postalar Göndermek İçin MessageBird',
      notConnected: 'Bağlı Değil',
      connected: 'Bağlı',
      edit: 'Düzenle',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Mesaj Göndermek İçin Plivo',
      connect: 'Bağlan',
      appTooltip: {
        nameOfAppOrCrm: 'Uygulama/CRM Adı',
        statusAsConnectedOrNotConnected: 'Bağlı / Bağlı Değil olarak durum',
        clickToConnectAppOrCrm: 'Uygulamayı/CRM\'yi bağlamak için tıklayın',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Üzgünüz! Entegrasyonlar bebek planında kullanılamaz. Entegrasyonlar için King planına yükseltin.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'MessageBird ile Entegre Ol',
        apiKey: 'API Anahtarı',
        originator: 'Gönderen',
        connect: 'Bağlan',
        disconnect: 'Bağlantıyı Kes',
        seeActivities: 'Aktiviteleri Görüntüle',
        sms: 'SMS',
        time: 'Zaman',
        phoneNumber: 'Telefon Numarası',
        status: 'Durum',
        botName: 'Bot Adı',
        message: 'Mesaj',
        error: 'Hata',
        placeholders: {
          apiKey: 'API Anahtarı',
          messageBirdPhoneNumber: 'MessageBird Telefon Numarası',
        },
        noDataFound: {
          activities: 'Aktiviteler',
          noActivitiesFound: 'Aktivite bulunamadı!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Plivo ile Entegre Ol',
        authId: 'Auth Kimliği',
        authToken: 'Auth Anahtarı',
        yourPlivoPhoneNumber: 'Plivo Telefon Numaranız',
        connect: 'Bağlan',
        disconnect: 'Bağlantıyı Kes',
        seeActivities: 'Aktiviteleri Görüntüle',
        sms: 'SMS',
        time: 'Zaman',
        phoneNumber: 'Telefon Numarası',
        status: 'Durum',
        botName: 'Bot Adı',
        message: 'Mesaj',
        error: 'Hata',
        noDataFound: {
          activities: 'Aktiviteler',
          noActivitiesFound: 'Aktivite bulunamadı!',
        },
        placeholders: {
          authId: 'Auth Kimliği',
          authToken: 'Auth Anahtarı',
          yourPlivoPhoneNumber: 'Plivo Telefon Numaranız',
        }
      }
    }
  }
};
