const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'החשבון שלי',
  yourProfileAndPasswordSecurity: 'הפרופיל שלך ואבטחת הסיסמה',
  detailsHeader: 'פרטים',
  passwordHeader: 'סיסמה',
  customAttributeHeader: 'מאפיינים מותאמים אישית',
  tagsHeader: 'תגים',
  accessTokenHeader: 'מפתח גישה',
  thirdPartyIntegrationsHeader: 'אינטגרציות מצד שלישי',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'עדכן את פרטי החשבון שלך',
    TOOLTIP_PASSWORD_HEADING: 'שנה את הסיסמה שלך',
    TOOLTIP_TAGS_HEADING: 'נהל את התגים שלך',
    TOOLTIP_THIRD_PARTY_HEADING: 'אינטגרציות מצד שלישי',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `סיסמה מאובטחת עוזרת להגן על חשבון ה${brand} שלך`,
    TOOLTIP_INTEGRATIONS: 'אינטגרציות',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'הוסף או עדכן מאפיינים מותאמים אישית',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'נהל את מאפיינים מותאמים אישית שלך'
  },
  accessToken: {
    accessToken: 'מפתח גישה',
    generateYourAccessTokenForUsingPlatformAPIs: `צור מפתח גישה עבור שימוש בממשקי ה- API של הפלטפורמה`,
    generateApiToken: 'צור מפתח API',
    revokeApiToken: 'בטל מפתח API',
    regenerateApiToken: 'צור מחדש מפתח API',
    placeholders: {
      yourApiKey: 'ה- API Key שלך',
    }
  },
  details: {
    name: 'שם',
    emailId: 'כתובת דוא"ל',
    yourPlan: 'תוכנית שלך',
    messagesPerMonth: ' הודעות/חודש',
    daysOfStoragePlan: ' ימי תוכנית אחסון',
    conditionalFeatures: 'תכונות שניתן להוסיף',
    upgradeToEnterprisePlanToGetMore: 'שדרג לתוכנית אנטרפרייז כדי לקבל יותר',
    featuresAndStorageCapacity: 'תכונות וקיבולת אחסון',
    upgradePlan: 'שדרג את התוכנית',
    submit: 'שלח',
    selectLanguage: 'בחר שפה',
    baby: 'בייבי',
    king: 'מלך',
    conversationsperMonth: 'שיחות/חודש',
    english: 'אנגלית',
    hindi: 'הינדי',
    german: 'גרמנית',
    french: 'צרפתית',
    portuguese: 'פורטוגזית',
    spanish: 'ספרדית',
    italian: 'איטלקית',
    russian: 'רוסית',
    chatBotBrandName: 'שם החברה של הצ',
    placeholders: {
      john: 'ג\'ון',
      email: 'דוא"ל',
      searchLanguage: 'חפש שפה',
      chatBotBrandName: 'שם החברה של הצ'
    },
    appTooltip: {
      TOOLTIP_NAME: 'הזן את שמך בפרופיל',
      TOOLTIP_EMAIL_ADDRESS: 'הזן את כתובת הדוא"ל שלך',
    }
  },
  customAttribute: {
    addAttribute: 'הוסף מאפיין',
    discard: 'בטל',
    customAttribute: 'מאפיינים מותאמים אישית',
    checkOrAddCustomAttribute: 'כאן תוכל לבדוק ולהוסיף מאפיינים מותאמים אישית שלך.',
    attributeKey: 'מפתח המאפיין',
    attributeName: 'שם המאפיין',
    bot: 'בוט',
    flow: 'זרימה',
    loadMore: 'טען עוד',
    question: 'שאלה',
    action: 'פעולה',
    search: 'חיפוש',
    selectBot: 'בחר בוט',
    selectFlow: 'בחר זרימה',
    selectQuestion: 'בחר שאלה',
    noDataFoundHeading: 'עדיין לא יצרת מאפיינים',
    noDataFoundSubheading: 'כל המאפיינים שלך יופיעו כאן',
    deleteTitle: 'מחק מאפיין',
    deleteDescription: 'האם אתה בטוח שברצונך למחוק מאפיין זה?',
    deleteAttributes: 'מחק מאפיינים',
    areYouSureYouWantToDeleteThisAttributes: 'האם אתה בטוח שברצונך למחוק את האפיונות האלו?',
    editAttribute: 'ערוך מאפיין',
    headsUp: 'הזהרה',
    okay: 'אישור',
    dataAddedToLead: 'המאפיינים שממופים ל-API שלך או לזרימות הבוט לא יתעדכנו. בבקשה עדכן את ה-API או את זרימות הבוט בהתאם.',
    placeholders: {
      enterAttributeKey: 'הזן מפתח מאפיין',
      enterAttributeName: 'הזן שם מאפיין',
      selectBot: 'בחר בוט',
      selectFlow: 'בחר זרימה',
      selectQuestion: 'בחר שאלה',
      search: 'חפש לפי שם המאפיין'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'הזן מפתח מאפיין',
      TOOLTIP_ATTRIBUTE_NAME: 'הזן שם מאפיין',
      TOOLTIP_SELECT_BOT: 'בחר בוט',
      TOOLTIP_SELECT_FLOW: 'בחר זרימה',
      TOOLTIP_SELECT_QUESTION: 'בחר שאלה',
    }
  },
  password: {
    changePassword: 'שנה סיסמה',
    aSecurePasswordHelpsProtectYour: 'סיסמה מאובטחת עוזרת להגן על ה',
    account: ' חשבון',
    currentPassword: 'סיסמה נוכחית',
    newPassword: 'סיסמה חדשה',
    confirmPassword: 'אשר סיסמה',
    cancel: 'ביטול',
    updatePassword: 'עדכן סיסמה',
    deleteAccount: 'מחק חשבון',
    placeholders: {
      enterYourCurrentPassword: 'הזן את הסיסמה הנוכחית שלך',
      enterYourNewPassword: 'הזן סיסמה חדשה',
      enterYourConfirmPassword: 'אשר את הסיסמה החדשה שלך',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'הזן את הסיסמה הישנה שלך',
      TOOLTIP_NEW_PASSWORD: 'הזן סיסמה חדשה',
      TOOLTIP_CONFIRM_PASSWORD: 'אשר את הסיסמה החדשה שלך',
      TOOLTIP_DELETE_ACCOUNT: `מחק את החשבון הנוכחי של ${brand}`,
    }
  },
  tags: {
    manageTags: 'נהל תגי',
    createNewTags: 'צור תגים חדשים',
    createNewTagsSubText: 'נהל את התגים שלך כאן כדי לארגן את הלידים, השיחות, הקבוצות ועוד בצורה טובה יותר',
    tagName: 'שם התג',
    updateTag: 'עדכן תג',
    createTag: 'צור תג',
    discard: 'בטל',
    searchTag: 'חפש תג לפי השם',
    noTagFound: 'תג לא נמצא!',
    createATag: 'צור תג',
    deleteTagModalSubText: 'האם אתה בטוח שברצונך למחוק את התג הזה? מחיקת התג הזה תסיר אותו מהמשתמשים המשויכים גם!',
    deleteTagModalHeading: 'מחק תגים',
    deleteOneTagModalHeading: 'מחק תג',
    actions: 'פעולות',
    placeholders: {
      enterTag: 'הזן שם תג',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'מחק תגים',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'שם',
      status: 'סטטוס',
      action: 'פעולה',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird לשליחת הודעות ואימיילים',
      notConnected: 'לא מחובר',
      connected: 'מחובר',
      edit: 'ערוך',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo לשליחת הודעות',
      connect: 'התחבר',
      appTooltip: {
        nameOfAppOrCrm: 'שם היישום/CRM',
        statusAsConnectedOrNotConnected: 'סטטוס כמחובר / לא מחובר',
        clickToConnectAppOrCrm: 'לחץ כדי להתחבר ליישום/CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'אופס! אינטגרציות אינן זמינות בתוכנית הבייבי. אנא שדרג לתוכנית מלך כדי להשתמש באינטגרציות.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'אינטגרציה עם MessageBird',
        apiKey: 'מפתח API',
        originator: 'מפעיל',
        connect: 'התחבר',
        disconnect: 'התנתק',
        seeActivities: 'ראה פעילויות',
        sms: 'הודעות SMS',
        time: 'זמן',
        phoneNumber: 'מספר טלפון',
        status: 'סטטוס',
        botName: 'שם הבוט',
        message: 'הודעה',
        error: 'שגיאה',
        placeholders: {
          apiKey: 'מפתח API שלך',
          messageBirdPhoneNumber: 'מספר טלפון MessageBird',
        },
        noDataFound: {
          activities: 'פעילויות',
          noActivitiesFound: 'לא נמצאו פעילויות!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'אינטגרציה עם Plivo',
        authId: 'זיהוי מאמת',
        authToken: 'טוקן מאמת',
        yourPlivoPhoneNumber: 'מספר הטלפון שלך ב- Plivo',
        connect: 'התחבר',
        disconnect: 'התנתק',
        seeActivities: 'ראה פעילויות',
        sms: 'הודעות SMS',
        time: 'זמן',
        phoneNumber: 'מספר טלפון',
        status: 'סטטוס',
        botName: 'שם הבוט',
        message: 'הודעה',
        error: 'שגיאה',
        noDataFound: {
          activities: 'פעילויות',
          noActivitiesFound: 'לא נמצאו פעילויות!',
        },
        placeholders: {
          authId: 'זיהוי מאמת',
          authToken: 'טוקן מאמת',
          yourPlivoPhoneNumber: 'מספר הטלפון שלך ב- Plivo',
        }
      }
    }
  }
};
