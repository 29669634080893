const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Мой счет',
  yourProfileAndPasswordSecurity: 'Ваш профиль и безопасность пароля',
  detailsHeader: 'Подробности',
  passwordHeader: 'Пароль',
  customAttributeHeader: 'Пользовательские атрибуты',
  tagsHeader: 'Ярлык',
  accessTokenHeader: 'Токен доступа',
  thirdPartyIntegrationsHeader: 'Сторонние интеграции',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Обновите профиль своей учетной записи',
    TOOLTIP_PASSWORD_HEADING: 'Измените свой пароль',
    TOOLTIP_TAGS_HEADING: 'Управляйте своими метками',
    TOOLTIP_THIRD_PARTY_HEADING: 'Сторонние интеграции',
    TOOLTIP_CHANGE_PASSWORD_TITLE: 'Безопасный пароль помогает защитить вашу учетную запись $ {brand}',
    TOOLTIP_INTEGRATIONS: 'Интегрировать',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Добавить или обновить пользовательские атрибуты',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Управляйте своими пользовательскими атрибутами'
  },
  accessToken: {
    accessToken: 'Токен доступа',
    generateYourAccessTokenForUsingPlatformAPIs: 'Создайте свой токен доступа для использования API платформы',
    generateApiToken: 'Генерировать токен API',
    revokeApiToken: 'Отменить токен API',
    regenerateApiToken: 'Регенерация токена API',
    placeholders: {
      yourApiKey: 'Ваш ключ API',
    }
  },
  details: {
    name: 'Имя',
    emailId: 'Email ID',
    yourPlan: 'Ваш план',
    messagesPerMonth: ' Сообщения/месяц',
    daysOfStoragePlan: ' Дни плана хранения',
    conditionalFeatures: 'условные особенности',
    upgradeToEnterprisePlanToGetMore: 'Обновите на предприятие плана получить больше',
    featuresAndStorageCapacity: 'Особенность и емкость хранения',
    upgradePlan: 'План обновления',
    submit: 'Представлять на рассмотрение',
    selectLanguage: 'Выберите язык',
    baby: 'Малыш',
    king: 'Король',
    conversationsperMonth: 'покрытия/месяц',
    english: 'Английский',
    hindi: 'хинди',
    german: 'Немецкий',
    french: 'Французский',
    portuguese: 'Португальский',
    spanish: 'испанский',
    italian: 'Итальянский',
    russian: 'Русский',
    chatBotBrandName: 'Имя бота чат',
    placeholders: {
      john: 'Джон',
      email: 'Электронная почта',
      searchLanguage: 'Язык поиска',
      chatBotBrandName: 'Имя бота чат',
    },
    appTooltip: {
      TOOLTIP_NAME: 'Введите имя своего профиля',
      TOOLTIP_EMAIL_ADDRESS: 'Введите ваш адрес электронной почты',
    }
  },
  customAttribute: {
    addAttribute: 'Добавить атрибут',
    discard: 'Отклонить',
    customAttribute: 'Пользовательские атрибуты',
    checkOrAddCustomAttribute: 'Здесь вы можете проверить и добавить ваши пользовательские атрибуты.',
    attributeKey: 'Ключ атрибута',
    attributeName: 'Имя атрибута',
    bot: 'Бот',
    flow: 'Поток',
    loadMore: 'Загрузить еще',
    question: 'Вопрос',
    action: 'Действие',
    search: 'Поиск',
    selectBot: 'Выберите бота',
    selectFlow: 'Выберите поток',
    selectQuestion: 'Выберите вопрос',
    noDataFoundHeading: 'У вас пока нет созданных атрибутов',
    noDataFoundSubheading: 'Здесь будут отображаться все ваши атрибуты',
    deleteTitle: 'Удалить Атрибут',
    deleteDescription: 'Вы уверены, что хотите удалить этот атрибут?',
    deleteAttributes: 'Удалить Атрибуты',
    areYouSureYouWantToDeleteThisAttributes: 'Вы уверены, что хотите удалить эти атрибуты?',
    editAttribute: 'Изменить Атрибут',
    headsUp: 'Берегись',
    okay: 'Хорошо',
    dataAddedToLead: 'Атрибуты, сопоставленные с вашими API или потоками ботов, не будут обновлены. Пожалуйста, обновите API или потоки ботов соответственно.',
    placeholders: {
      enterAttributeKey: 'Введите ключ атрибута',
      enterAttributeName: 'Введите имя атрибута',
      selectBot: 'Выберите бота',
      selectFlow: 'Выберите поток',
      selectQuestion: 'Выберите вопрос',
      search: 'Поиск по названию атрибута'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Введите ключ атрибута',
      TOOLTIP_ATTRIBUTE_NAME: 'Введите имя атрибута',
      TOOLTIP_SELECT_BOT: 'Выберите бота',
      TOOLTIP_SELECT_FLOW: 'Выберите поток',
      TOOLTIP_SELECT_QUESTION: 'Выберите вопрос',
    }
  },
  password: {
    changePassword: 'Изменить пароль',
    aSecurePasswordHelpsProtectYour: 'Безопасный пароль помогает защитить ваш',
    account: ' Счет',
    currentPassword: 'Текущий пароль',
    newPassword: 'Новый пароль',
    confirmPassword: 'Подтвердите пароль',
    cancel: 'Отмена',
    updatePassword: 'Обновить пароль',
    deleteAccount: 'Удалить аккаунт',
    placeholders: {
      enterYourCurrentPassword: 'Введи свой текущий пароль',
      enterYourNewPassword: 'Введите свой новый пароль',
      enterYourConfirmPassword: 'Введите свой подтвержденный пароль',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Введите старый пароль',
      TOOLTIP_NEW_PASSWORD: 'Введите новый пароль',
      TOOLTIP_CONFIRM_PASSWORD: 'Подтвердите свой новый пароль',
      TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Управление метками',
    createNewTags: 'Создать новые метки',
    createNewTagsSubText: 'Управляйте своими метками здесь, чтобы лучше организовать ваши лиды, чаты, группы и т. д.',
    tagName: 'Имя метки',
    updateTag: 'Обновить метку',
    createTag: 'Создать метку',
    discard: 'Отменить',
    searchTag: 'Поиск метки по имени',
    noTagFound: 'Метка не найдена!',
    createATag: 'Создать метку',
    deleteTagModalSubText: 'Вы уверены, что хотите удалить эту метку? Удаление этой метки также удалит ее у назначенных пользователей!',
    deleteTagModalHeading: 'Удалить метки',
    deleteOneTagModalHeading: 'Удалить метку',
    actions: 'Действия',
    placeholders: {
      enterTag: 'Введите имя метки',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'Удалить метки',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'Имя',
      status: 'Положение дел',
      action: 'Действие',
      messageBird: 'Bessagebird',
      messageBirdForSendingMessagesAndEmails: 'Bessagebird для отправки сообщений и электронных писем',
      notConnected: 'Не подключен',
      connected: 'Связанный',
      edit: 'Редактировать',
      plivo: 'Пливо',
      plivoForSendingMessages: 'Plivo для отправки сообщений',
      connect: 'Соединять',
      appTooltip: {
        nameOfAppOrCrm: 'Имя приложения/CRM',
        statusAsConnectedOrNotConnected: 'Статус подключенного / не подключенного',
        clickToConnectAppOrCrm: 'Нажмите, чтобы подключить приложение/CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Уп! Интеграции недоступны в плане ребенка. Пожалуйста, перейдите к плану интеграции.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'Bessagebird',
        integrateWithMessageBird: 'Интегрируйте с Messagebird',
        apiKey: 'API -ключ',
        originator: 'Создатель',
        connect: 'Соединять',
        disconnect: 'Отключить',
        seeActivities: 'См. Действия',
        sms: 'SMS',
        time: 'Время',
        phoneNumber: 'Номер телефона',
        status: 'Положение дел',
        botName: 'Название бота',
        message: 'Сообщение',
        error: 'Ошибка',
        placeholders: {
          apiKey: 'API -ключ',
          messageBirdPhoneNumber: 'Номер телефона Messagebird',
        },
        noDataFound: {
          activities: 'Деятельность',
          noActivitiesFound: 'Не найдено занятий!',
        }
      },
      plivo: {
        plivo: 'Пливо',
        integrateWithPlivo: 'Интегрируйте с Plivo',
        authId: 'Идентификатор аудитории',
        authToken: 'АВТОРНЫЙ ПЕРЕДАЧИ',
        yourPlivoPhoneNumber: 'Ваш номер телефона Plivo',
        connect: 'Соединять',
        disconnect: 'Отключить',
        seeActivities: 'См. Действия',
        sms: 'SMS',
        time: 'Время',
        phoneNumber: 'Номер телефона',
        status: 'Положение дел',
        botName: 'Название бота',
        message: 'Сообщение',
        error: 'Ошибка',
        noDataFound: {
          activities: 'Деятельность',
          noActivitiesFound: 'Не найдено занятий!',
        },
        placeholders: {
          authId: 'Идентификатор аудитории',
          authToken: 'АВТОРНЫЙ ПЕРЕДАЧИ',
          yourPlivoPhoneNumber: 'Ваш номер телефона Plivo',
        }
      }
    }
  }
};
