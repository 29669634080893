export const analytics = {
  botWise: {
    botWiseAnalytics: 'Analytics per quanto riguarda il bot',
    currentBillingCycle: 'Ciclo di fatturazione attuale',
    currentMonth: 'Corrente mese',
    sinceAccountCreation: `Dalla creazione dell'account`,
    messages: 'Messaggi',
    leads: 'Conduce',
    noDataFound: {
      botwiseAnalytics: 'Analytics per quanto riguarda il bot',
      noBotHasBeenCreatedYet: 'Nessun bot è stato ancora creato!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: `Riassumi i dati da messaggi e contatti/lead per robot specifici per il ciclo di fatturazione corrente, il mese corrente o dalla creazione dell'account (ricevi aggiornare ogni mezzanotte)`,
      TOOLTIP_FILTERS: 'Filtro dati per bot selezionato',
      TOOLTIP_MESSAGES: 'Messaggi totali consumati dal tuo account dalla creazione di account',
      TOOLTIP_LEADS: `Lead totali catturati dai robot per il tuo account dalla creazione dell'account.`,
    }
  },
  overview: {
    analytics: 'Analisi',
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    overview: 'Panoramica',
    messages: 'Messaggi',
    leads: 'Conduce',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Analisi per tutti i tuoi messaggi e lead dei tuoi robot. (Dati aggiornati ogni mezzanotte)',
    }
  },
  summary: {
    analyticsSummary: `Riepilogo dell'analisi`,
    allBots: 'Tutti i robot',
    daily: 'Quotidiano',
    weekly: 'settimanalmente',
    monthly: 'Mensile',
    messages: 'Messaggi',
    leads: 'Conduce',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Riassumi dati giornalieri, settimanali o mensili da messaggi e contatti/lead per specifici e tutti i bot (ricevi ogni mezzanotte)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp Analytics',
      month: 'Mese',
      week: 'Settimana',
      today: 'Oggi',
      filters: 'Filtri',
      clearAll: 'Cancella tutto',
      apply: 'Fare domanda a',
      totalConversations: 'Conversazioni totali',
      freeConversations: 'Conversazioni gratuite',
      paidConversations: 'Conversazioni a pagamento',
      messageSent: 'Messaggio inviato',
      messageDelivered: 'Messaggio consegnato',
      bpDropDown: {
        selectBot: 'Seleziona Bot',
      },
      indexMergeDatePicker: {
        startDate: `Data d'inizio`,
        endDate: 'Data di fine',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Analisi della conversazione',
      month: 'Mese',
      week: 'Settimana',
      today: 'Oggi',
      totalConversations: 'Conversazioni totali',
      paidConversations: 'Conversazioni a pagamento',
      freeConversations: 'Conversazioni gratuite',
      indexMergeDatePicker: {
        startDate: `Data d'inizio`,
        endDate: 'Data di fine',
      },
      bpDropDown: {
        allBots: 'Tutti i robot',
      }
    },
    messageAnalytics: {
      whatsappMessages: 'Messaggi WhatsApp',
      sent: 'Inviato',
      delivered: 'Consegnato',
      indexMergeDatePicker: {
        startDate: `Data d'inizio`,
        endDate: 'Data di fine',
      },
      bpDropDown: {
        allBots: 'Tutti i robot',
      },
      month: 'Mese',
      week: 'Settimana',
      today: 'Oggi',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'Messaggi di WhatsApp per stato',
      sent: 'Inviato',
      delivered: 'Consegnato',
      indexMergeDatePicker: {
        startDate: `Data d'inizio`,
        endDate: 'Data di fine',
      },
      bpDropDown: {
        allBots: 'Tutti i robot',
      },
      month: 'Mese',
      week: 'Settimana',
      today: 'Oggi',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Tutti gli abbonati',
      whatsappSubscribers: 'Abbonati whatsapp',
    }
  }
};
