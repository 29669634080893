export const subscription = {
  subscriptions: 'Подписки',
  plan: 'План',
  whatsappPlan: 'План WhatsApp',
  addOns: 'Надстройки',
  billingDetails: 'Платежные реквизиты',
  invoices: 'Счета',
  wa1Reminder: 'WA 1 -е напоминание',
  wa2Reminder: 'WA 2 -е напоминание',
  waRenewApi: 'WA Renew API',
  waCheckUsageApi: 'WA Проверьте использование API',
  waResetUsageApi: 'WA Reset Использование API',
  waExceedUsageApi: 'WA превышает использование API',
  waGetBotsInThisAccount: 'Валузите ботов в этом аккаунте',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'У нас самые доступные цены на все ваши потребности.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'План WhatsApp',
    TOOLTIP_ADDS_ON_HEADING: 'Купить дополнительные массажи и белолетлевой бот',
    TOOLTIP_ADDS_ON_MESSAGE: 'Если вы достигли своего ежемесячного лимита сообщения, вы можете купить дополнительные пакеты.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Проверьте свою текущую информацию о подписке',
    TOOLTIP_HORIZONTAL_BAR: 'Проверьте наши ежемесячные или ежегодные цены от 3K до 500K сообщений, перемещая планку влево направо.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Обновите информацию о выставлении счетов для выставления счетов.',
    TOOLTIP_INVOICES_HEADING: 'Проверьте свои предыдущие счета.',
    TOOLTIP_INVOICES_ACTION: 'Нажмите, чтобы получить счет в своей учетной записи электронной почты',
  },
  currentPlan: {
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    downgradeRequest1: 'Вы поместили запрос на понижение вашего плана на',
    downgradeRequest2: 'Сообщения. Обратите внимание, что ваш план будет понижен к концу текущего платежного цикла',
    downgradeRequest3: 'Вы можете наслаждаться',
    downgradeRequest4: 'Услуги до тех пор.',
    downgradeRequestMaster1: 'Вы поместили запрос на понижение вашего',
    downgradeRequestMaster2: ' планировать',
    downgradeRequestMaster3: ' разговоры. Обратите внимание, что ваш план будет понижен к концу текущего платежного цикла',
    downgradeRequestMaster4: 'Вы можете наслаждаться',
    downgradeRequestMaster5: 'Услуги до тех пор.',
    active: 'Активный',
    inactive: 'Неактивный',
    month: 'месяц',
    year: 'год',
    messagePack: 'Пакет сообщений',
    king: 'Король',
    baby: 'Малыш',
    whatsapp: 'WhatsApp',
    youAreOn: 'Вы находитесь на',
    plan: 'план',
    totalMessagesLimit: 'Общее ограничение сообщений',
    liveAgentLimit: 'Предел живого агента',
    perMonth: '/месяц',
    agents: 'агент (ы)',
    messagesAddOn: 'Сообщения дополнения',
    botWhitelabels: 'Бот белые',
    totalAddOnMessages: 'Общее дополнение',
    basePrice: 'Базисная цена',
    gst: 'Гф',
    totalPrice: 'Итоговая цена',
    totalAddOnPrice: 'Общая добавленная цена',
    whitelabelPrice: 'Белая цена',
    startedOn: 'Начал',
    nextDueOn: 'Следующее',
    addOnExpiry: 'Дополнение истечения',
    website: 'Веб-сайт',
    facebook: 'Фейсбук',
    telegram: 'Телеграм'
  },
  invoice: {
    invoiceNumber: 'Счет.',
    date: 'Дата',
    invoiceAmount: 'Сумма счета',
    planAmount: 'Сумма плана',
    plan: 'План',
    messages: 'Сообщения',
    action: 'Действие',
    nA: 'НА',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Нажмите, чтобы получить счет в своей учетной записи электронной почты',
      clickToDownloadInvoice: 'Нажмите, чтобы загрузить счет в форме PDF',
    }
  },
  messageAddOn: {
    validity: 'Период действия',
    kingPlanHeader: '👑 План короля',
    youAreOnThe: 'Вы на',
    asOfNowAndWillGet: 'На данный момент и получит',
    messages: 'Сообщения',
    inThisPlan: 'в этом плане.',
    youCanBuyAdditionalMessaes: 'Вы можете купить дополнительные сообщения в соответствии с вашими бизнес -требованиями.',
    plan: ' План',
    baby: 'Малыш',
    king: 'Король',
    get: 'Получать',
    babyPlan: 'Детский план',
    upgradePlan: 'План обновления',
    asOfNow: ' На данный момент.',
    pleaseUpgradeToKingPlan: 'Пожалуйста, обновите до',
    toEnableAddOnsAndWhitelabelPacks: ' Чтобы включить дополнения и пакеты белой атлетики.',
    messageAddOnPacks: 'Сообщения добавленные пакеты',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Если вы достигли своего ежемесячного лимита сообщений, купите дополнительные пакеты и наслаждайтесь непрерывными услугами.',
    pleaseSelectNumberOfExpectedMessages: 'Пожалуйста, выберите количество ожидаемых сообщений',
    addOnsValidity: 'Достоверность дополнений',
    currentBillingCycle: 'Текущий цикл выставления счетов',
    oneYear: 'Один год',
    summary: 'Краткое содержание',
    messageCreditsWithKingPlan: 'Объединения с планом King Plan',
    messageCreditsWith: 'Объединения с планом ',
    withPlan: '',
    billingCycle: 'Платежный цикл',
    payableAmount: 'Уплаченная сумма',
    oneTimePayment: 'Одноразовый платеж',
    plusTaxes: ' + Налоги',
    buyNow: 'Купить сейчас',
    whiteLabelChatWindow: 'Белое окно чата',
    noBotsLeftToWhiteLabel: 'Никаких ботов не осталось в белую.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Пожалуйста, выберите боты, которые вы хотите белить.',
    selectBots: 'Выберите ботов',
    bot: 'Бот',
    bots: 'Боты',
    toBeWhiteLabelled: ' быть белым',
    remove: 'Удалять',
    brandNameFromChatWindow: ' Имя бренда из окна чата.',
    willBeValidTill: 'Будет действительным до',
    ourYearlyPrice: 'Наша годовая цена',
    directWhiteLabel: 'Прямая белолетка',
    whiteLabelledBots: 'Белые боты',
    nextRenewalColon: 'Следующее обновление:',
    optOutRequested: 'Отказаться от запроса',
    contactAgency: 'Контактное агентство',
    optOut: 'Уклоняться',
    removeWhiteLabel: 'Удалите белую',
    messageCount: 'Сообщения',
    numberOfPacks: 'Количество пакетов',
    amount: 'Сумма',
    taxes: 'Налоги',
    totalAmount: 'Общая сумма',
    numOfBots: 'Nr. de Bot-uri',
    select: 'Выбрать',
    selectTheValidity: 'Выберите действительность',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Детали оплаты',
    cardNumber: 'Номер карты',
    cvv: 'CVV',
    // cardNumber: 'Номер карты',
    month: 'Месяц',
    year: 'Год',
    save: 'Сохранять',
    add: 'Добавлять',
    edit: 'Редактировать',
    disclaimer: 'Отказ от ответственности:',
    disclaimerDescription1: 'Вышеуказанные данные метода оплаты будут применимы только к пользователям за пределами Индии.',
    disclaimerDescription2: 'Мы не сохраняем с собой данные вашей карты. В случае, если вы хотите изменить данные своей карты, она будет напрямую изменена с помощью платежного шлюза.',
    billingAddress: 'Адрес для выставления счета',
    companyName: 'Название компании',
    country: 'Страна',
    selectCountry: 'Выберите страну',
    stateOrRegion: 'Государство/регион',
    streetAddress: 'Адрес улицы',
    gstIn: 'GSTIN',
    postalCode: 'Почтовый индекс',
    cancel: 'Отмена',
    proceedToPay: 'Приступить к оплате',
    company: 'Компания',
    state: 'Состояние',
    masterCard: 'Мастер -карта',
    yourCompanyName: 'Название вашей компании',
    yourCountry: 'Твоя страна',
    yourRegion: 'Ваш регион',
    yourPostalCode: 'Ваш почтовый код',
    plusTaxes: ' + налоги',
    dropDown: {
      month: 'Месяц *',
      year: 'Год *',
    },
    placeholders: {
      enterCardNumber: 'Введите номер карты',
      cvv: 'CVV',
      enterYourCompanyName: 'Введите название вашей компании',
      enterYourStateOrRegion: 'Введите свое состояние/регион',
      enterYourStreetAddress: 'Введите свой уличный адрес',
      enterYourGSTIN: 'Введите свой GSTIN',
      enterPostalCode: 'Введите почтовый код',
    }
  },
  plans: {
    viewDetailedPricing: 'Просмотреть подробные цены',
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    weHaveGotAPricingPlanPerfectForYou: 'У нас есть план ценообразования, который идеально подходит для вас',
    weBelieve: 'Мы верим',
    shouldBeAccessibleToAllCompanies: ' Должен быть доступен для всех компаний, не имеет значения по размеру.',
    monthly: 'Ежемесячно',
    yearly: 'Ежегодно',
    twoMonthsFree: '(2 месяца бесплатно)',
    babyPlan: 'Детский план',
    freeForever: 'Бесплатно навсегда',
    messagesPerMonth: 'Сообщения в месяц',
    currentPlan: 'Текущий план',
    freePlan: 'Бесплатный план',
    kingPlan: 'Король план',
    perYear: 'в год',
    plan: 'план',
    toThe: ' в',
    plusTaxes: ' + Налоги',
    perMonth: 'в месяц',
    upgradePlan: 'План обновления',
    changePlan: 'Изменить план',
    currentlyActive: 'В настоящее время активно',
    emperorPlan: 'План Императора (предприятие)',
    sendAsManyMessagesAsYouWant: 'Отправьте столько сообщений, сколько вам нужно',
    contactUs: 'Связаться с нами',
    features: 'Функции',
    areYouSureYouWantToShiftFrom: 'Вы уверены, что хотите перебраться с',
    messages: 'Сообщения',
    planQuestionMark: 'план?',
    downgradeToFreePlan: 'Понижать до бесплатного плана',
    goToBabyPlan: 'Переход в план ребенка',
    thankYouForChoosingBotPenguin: 'Спасибо, что выбрали Botpenguin',
    bots: 'Боты',
    facebookPages: 'Facebook страницы',
    teamMember: 'Участник команды',
    dataStorage: 'Хранилище данных',
    conditionalFlow: 'Условный поток',
    liveChat: 'Живой чат',
    bookAppointments: 'Книжные встречи',
    csvExport: 'CSV Экспорт',
    emailAndCallSupport: 'Электронная почта и поддержка звонка',
    googleCalendarIntegrations: 'Интеграция календаря Google',
    zohoCrmIntegrations: 'Zoho CRM Интеграция',
    reportsAndAnalytics: 'Отчеты и аналитика',
    removeBotPenguinBranding: 'Удалить брендинг Botpenguin',
    oneBot: '1 бот',
    twoKMessagesPerMonth: '2K сообщений/месяц',
    oneFacebookPage: '1 страница в Facebook',
    oneTeamMember: '1 член команды',
    thirtyDaysOfDataStorage: '30 дней хранения данных',
    unlimitedBot: 'Неограниченный бот',
    threeTo500kMessagesPerMonth: 'Сообщения от 3K до 500K/месяц',
    tenFacebookPages: '10 страниц Facebook',
    threeTeamMembers: '3 члена команды',
    nintyDaysOfDataStorage: '90 дней хранения данных',
    unlimitedMessages: 'Неограниченные сообщения',
    unlimitedFacebookPages: 'Неограниченные страницы Facebook',
    unlimitedTeamMembers: 'Неограниченные члены команды',
    unlimitedDataStorage: 'Неограниченное хранилище данных',
    youAreHere: 'Вы здесь!',
    detailedPricing: {
      kingsplan: 'План King',
      elementary: 'Начальный',
      solutionType: 'Тип решения',
      saas: 'SaaS',
      fbpage1fb: '1 Страница Facebook',
      perMonth100: '100 в месяц',
      enterprise: 'Предприятие',
      saasOnPremises: 'SaaS в офисе',
      aiChatbotFeatures: 'Функции чатбота AI',
      dataSourceForTraning: 'Источник данных для обучения',
      smartFeatures: 'Умные функции',
      liveChat: 'Онлайн-чат',
      day30: '30 дней',
      day90: '90 дней',
      landSquared: 'LandSquared',
      doItYourself: 'Сделай сам',
      unifiedInbox: 'Общий почтовый ящик',
      platforms: 'Платформы',
      websiteChatbot: 'Чатбот для веб-сайта',
      FacebookMessengerChatbot: 'Чатбот Facebook Messenger',
      Telegram: 'Telegram',
      conversationalLandingPage: 'Страница для разговора',
      integrations: 'Интеграции',
      reportsAndAnalytics: 'Отчеты и аналитика',
      customerSupport: 'Поддержка клиентов',
      security: 'Безопасность',
      bots: 'Боты',
      babyPlan: 'План Baby',
      kingPlan: 'План King',
      emperorPlan: 'План Emperor',
      noofBots: 'Количество ботов',
      one: '1 (Один)',
      unlimitedBots: 'Неограниченное количество ботов',
      noOfWebsite: 'Количество веб-сайтов',
      unlimitedWebsites: 'Неограниченное количество веб-сайтов',
      unlimitedMessages: 'Неограниченное количество сообщений',
      messagesSend: 'Отправленные сообщения',
      perMonth2000: '2,000 в месяц',
      upto500000perMonth: 'До 500 000 в месяц',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Неограниченное количество страниц',
      telegramNumbers: 'Номера Telegram',
      unlimited: 'Неограниченно',
      whatsappNumbers: 'Номера WhatsApp',
      subscribersLeads: 'Подписчики/лиды',
      createSupportTeam: 'Создать команду поддержки для онлайн-чата',
      oneMember: '1 Участник',
      threeMembers: '3 участника',
      conversationHistory: 'История бесед',
      thirtyDays: '30 дней',
      ninetyDays: '90 дней',
      chatbotDesigning: 'Проектирование чатбота',
      weAreHereToHelp: 'Мы здесь, чтобы помочь',
      weTakeCareEndToEnd: 'Мы заботимся о всем процессе',
      multipleLanguages: 'Множество языков',
      conditionalFlow: 'Условный поток',
      removeBotPenguinBranding: 'Удалить брендирование BotPenguin',
      gptMessages: 'Сообщения GPT',
      aiChatbot: 'AI чатбот',
      chatGPTIntegration: 'Интеграция ChatGPT',
      configureAIChatbotPersonality: 'Настроить личность AI чатбота',
      trainChatbotOnYourOwnData: 'Обучение чатбота на ваших данных',
      trainChatbotOnWebPages: 'Обучение чатбота на веб-страницах',
      trainChatbotOnMultipleFiles: 'Обучение чатбота на нескольких файлах',
      trainChatbotOnFAQs: 'Обучение чатбота на ЧаВО (FAQ)',
      dataTrainingStorage: 'Хранилище данных для обучения',
      useChatGPTModel4_3_5Turbo: 'Использовать модель ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Загрузка нескольких файлов для обучения чатбота',
      naturalLanguageProcessing: 'Обработка естественного языка',
      naturalLanguageUnderstanding: 'Понимание естественного языка',
      answerLength: 'Длина ответа',
      chatTone: 'Тон чата',
      multiLanguage: 'Множество языков',
      answerFormatting: 'Форматирование ответа',
      customPrompt: 'Пользовательский запрос',
      integrateBotPenguinLLM: 'Интеграция BotPenguin LLM',
      integrateOwnCustomLLM: 'Интеграция собственного/пользовательского LLM',
      periodicallySyncDataSources: 'Периодическая синхронизация источников данных',
      aiBasedSuggestedQuestions: 'Вопросы, предложенные на основе ИИ',
      chooseVisibilityForParticularWebpageSitemap: 'Выбор видимости для конкретной веб-страницы/карты сайта',
      chooseKnowledgeMix: 'Выбор смеси знаний:\nВаша база знаний\nЗнания ChatGPT\n"Знания ChatGPT + Ваша база знаний"',
      faqExtractionFromFileAndURL: 'Извлечение ЧаВО из файла и URL',
      analyticsToImproveAI: 'Аналитика для улучшения ИИ',
      exportQuestionFlow: 'Экспорт потока вопросов',
      sessionManagement: 'Управление сессией',
      collectFeedbackOnChatbotResponses: 'Сбор обратной связи на ответы чатбота',
      smartAIFollowUpQuestions: 'Умные/ИИ последующие вопросы',
      trainOnYourNotionWorkspaceData: 'Обучение на ваших данных в рабочем пространстве Notion',
      trainOnYoutubeVideo: 'Обучение на видеороликах YouTube',
      trainOnCustomVideoUpload: 'Обучение на пользовательских видеозаписях',
      rssFeed: 'Лента RSS',
      googleDrive: 'Google Диск',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'Блокировка IP и региона',
      emailAndMobileValidation: 'Проверка email и мобильного телефона',
      appointmentsBooking: 'Бронирование встреч',
      humanHandover: 'Передача человеку',
      botHandover: 'Передача боту',
      handoverBetweenAgents: 'Передача между агентами',
      mobileAppsForAgents: 'Мобильные приложения для агентов',
      roleBasedAccessControls: 'Управление доступом на основе ролей',
      apiIntegrationOnChatFlows: 'Интеграция API в потоки чата',
      liveChatWidget: 'Виджет онлайн-чата',
      typingIndicator: 'Индикатор печати',
      liveVisitorsDetails: 'Данные о посетителях в реальном времени',
      multipleSimultaneousChat: 'Множество одновременных чатов',
      liveTypingView: 'Вид ввода в реальном времени',
      ruleBasedChatRouting: 'Маршрутизация чата на основе правил',
      chatAutoAssignments: 'Автоматическое назначение чата',
      efficientOmnichannelInterface: 'Эффективный интерфейс Omnichannel',
      quickReplies: 'Быстрые ответы',
      unifiedCustomerProfile: 'Единый профиль клиента',
      visitorStats: 'Статистика по посетителям',
      addContactsFromWhatsapp: 'Добавить контакты из WhatsApp',
      applyFilterAndSortConversations: 'Применить фильтры и сортировать беседы',
      seeChatLogs: 'Просмотр логов чата',
      assignNotesAndChatTags: 'Присвоение заметок и меток чату',
      directCallsToLeadsWithTwilio: 'Прямые звонки лидам с Twilio',
      customerSegmentation: 'Сегментация клиентов',
      exportLeadsInCSV: 'Экспорт лидов в формате CSV',
      leadNotificationsByEmail: 'Уведомления о лидах по email',
      website: 'Веб-сайт',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegram',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Автозапуск на мобильных устройствах/настольных ПК',
      hideBotOnSpecificPages: 'Скрытие бота на определенных страницах',
      imageSharing: 'Обмен изображениями',
      readyToUseChatFlowTemplates: 'Готовые шаблоны потока чата',
      fileSharing: 'Обмен файлами',
      customDesignForChatWindow: 'Пользовательский дизайн окна чата',
      facebookMessengerBot: 'Чатбот Facebook Messenger',
      facebookSubheading: 'Подзаголовок Facebook',
      telegramSubheading: 'Подзаголовок Telegram',
      conversationalLangdingPageSubheading: 'Подзаголовок разговорной страницы',
      integrationsSubheading: 'Подзаголовок интеграций',
      multipleSmartFlows: 'Множество умных потоков',
      dripCampaigns: 'Кампании по каплям',
      broadcastFBMessages: 'Рассылка сообщений Facebook',
      multipleFacebookBotWidgets: 'Множество виджетов ботов Facebook',
      facebookAutoReplyOnComments: 'Автоответ на комментарии Facebook',
      facebookECommerce: 'Электронная коммерция Facebook',
      broadcastAnalytics: 'Аналитика рассылки',
      telegramMessengerBot: 'Чатбот Telegram Messenger',
      readyToUseTemplates: 'Готовые шаблоны',
      groupBroadcastTelegramMessages: 'Групповые и рассылочные сообщения Telegram',
      multipleTelegramBotWidgets: 'Несколько виджетов Telegram бота',
      conversationalLandingPageBot: 'Чатбот для разговорной страницы',
      smartReplies: 'Умные ответы',
      interactiveAndCustomizableUI: 'Интерактивный и настраиваемый интерфейс',
      leadManagement: 'Управление лидами',
      easyToInstallAndShare: 'Прост в установке и совместном использовании',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Закрыть',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Таблицы',
      googleCalendar: 'Google Календарь',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Система тикетов поддержки',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Аудио и видеозвонки в чате (Twilio/Agora)',
      courierTracking: 'Отслеживание курьеров',
      emailIntegration: 'Интеграция по электронной почте',
      webhooks: 'Вебхуки',
      schedulers: 'Планировщики',
      paymentGateways: 'Платежные шлюзы (Stripe и RazorPay)',
      enterpriseIntegrations: 'Интеграции для предприятий',
      customIntegration: 'Пользовательская интеграция',
      shopifyIntegration: 'Интеграция с Shopify',
      totalNumberOfMessages: 'Общее количество сообщений',
      totalNumberOfLeads: 'Общее количество лидов',
      weeklyReportBotwise: 'Еженедельный отчет по ботам',
      averageAgentResponeTime: 'Среднее время ответа агента',
      visitorTimingReport: 'Отчет по времени посетителя',
      customerSegmentBasedReports: 'Отчеты на основе сегментации клиентов',
      tagBasedReports: 'Отчеты на основе меток',
      leadGenerationReport: 'Отчет о генерации лидов',
      supportOnWhatsApp: 'Поддержка в WhatsApp',
      knowledgeBase: 'База знаний',
      onboardingAndTraining: 'Вводное обучение и тренинг',
      callAndEmailSupport: 'Телефонная и электронная поддержка',
      freeChatbotCreation: 'Бесплатное создание чатбота',
      customerSuccessOfficer: 'Офицер по успешному обслуживанию клиентов',
      secureConnection2048BitSSL: 'Безопасное соединение SSL с 2048 битами',
      securityAssessment: 'Оценка безопасности',
      dataCenterInUSOrEU: 'Центр данных в США или ЕС',
      twoStepVerification: 'Двухэтапная проверка',
      accessRestrictionsBasedOnIPs: 'Ограничения доступа на основе IP',
      onDemandAuditLog: 'Аудит журнала по запросу',
      bannedVisitorsList: 'Список заблокированных посетителей',
      customAPIRate: 'Пользовательская тарификация API',
      trustedDomains: 'Доверенные домены',
      mb100: '100 MB',
      gb1: '1 ГБ',
      codingCurious: 'Любознательный по кодированию',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Наш бесплатный план',
      TOOLTIP_KING_PLAN: 'Наш самый популярный план',
      TOOLTIP_EMPEROR_PLAN: 'Индивидуальный план для неограниченных сообщений, неограниченных данных, неограниченных членов команды',
      TOOLTIP_CONTACT_US: 'Обратитесь к нам через WhatsApp или по электронной почте по адресу contact@botpenguin.com, чтобы узнать больше о нашем индивидуальном плане',
      TOOLTIP_UPGRADE: 'Обновите свой текущий план для дополнительных функций',
      TOOLTIP_MONTHLY: 'Выберите ежемесячный цикл оплаты',
      TOOLTIP_YEARLY: 'Выберите ежегодный цикл платежей для скидки',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Переход в план ребенка',
    downgradeToFreePlan: 'Понижать до бесплатного плана',
    freeForever: 'Бесплатно навсегда',
    features: 'Функции',
    currentPlan: 'Текущий план',
    changePlan: 'Изменить план',
    currentlyActive: 'В настоящее время активно',
    seeHowToUse: 'Посмотрите, как использовать',
    weHaveGotPricingPerfectForYou: 'У нас есть план ценообразования, который идеально подходит для вас',
    weBelieve: 'Мы верим',
    shouldBeAccessibleToAllCompanies: ' Должен быть доступен для всех компаний, не имеет значения по размеру.',
    monthly: 'Ежемесячно',
    yearly: 'Ежегодно',
    twoMonthsFree: '(2 месяца бесплатно)',
    kingPlan: 'Король план',
    perYear: 'в год',
    perMonth: 'в месяц',
    conversationsPerMonth: 'Разговоры в месяц',
    upgradePlan: 'План обновления',
    emperorPlan: 'План Императора (предприятие)',
    haveAsManyConversationsAsYouNeed: 'Иметь столько разговоров, сколько вам нужно',
    contactUs: 'Связаться с нами',
    metaConversationChargesAreAdditional: 'Ставка на мета -разговор является дополнительной и выплачивается вам непосредственно на Meta, в зависимости от вашего использования. Мы не взимаем каких -либо платы за наценку или облегчение.',
    viewDetailedPricing: 'Просмотреть подробные цены',
    conversationPricingModelUsedByMeta: 'Модель ценообразования разговоров, используемая Meta',
    whatsappBusinessPlatformConversationsFallInto: 'В WhatsApp Business Platform Beargity делятся на две категории, которые по -разному оценены',
    userInitiatedConversation: 'Инициализированный пользователем разговор',
    userInitiatedConversationDescription: 'Разговор, который инициирует в ответ на пользовательское сообщение. Всякий раз, когда бизнес отвечает пользователю в течение 24-часового окна обслуживания клиентов, это сообщение будет связано с инициированным пользователем разговором. Предприятия могут отправлять сообщения бесплатной формы в этом 24-часовом окне обслуживания клиентов.',
    businessInitiatedConversation: 'Благодаря бизнесу разговор',
    businessInitiatedConversationDescription: 'Разговор, который инициирует от бизнеса, отправляя пользователю сообщение за пределами 24 -часового окна обслуживания клиентов. Сообщения, которые инициируют инициированный бизнесом разговора, потребуют шаблона сообщения.',
    areYouSureYouWantTo: 'Вы уверены, что хотите перебраться с',
    conversations: ' Разговоры',
    plan: ' план',
    toThe: ' в',
    metaPricingMarkup: 'Мета ценообразование',
    numberOfChatFlows: 'Количество потоков чата',
    greenTickApplication: 'Приложение зеленого тика',
    extraPhoneNumberLinking: 'Дополнительный номер телефона связывания',
    teamMembers: 'Члены команды',
    dataStorage: 'Хранилище данных',
    conditionalFlow: 'Условный поток',
    liveChat: 'Живой чат',
    smartInbox: 'Умный почтовый ящик',
    whatsappTemplates: 'Шаблоны WhatsApp',
    customerSegments: 'Потребительские Группа',
    broadcastAndDripCampaigns: 'Кампании трансляции и капель',
    multipleLanguages: 'Несколько языков',
    bookAppointments: 'Книжные встречи',
    csvContactsExport: 'CSV Контакты экспорта',
    googleCalendarIntegrations: 'Интеграция календаря Google',
    nativeApplications: '60+ Нативная интеграция',
    whatsappSupport: 'Поддержка WhatsApp',
    emailAndCallSupport: 'Электронная почта и поддержка звонка',
    customerIntegrations: 'Интеграции клиентов',
    nlpAndNluCapabilities: 'Возможности NLP и NLU',
    chatGPTBot: 'Chatgpt Chatbot',
    customerSuccessOfficer: 'Сотрудник по успеху клиента',
    noMarkupTransparency: 'Нет прозрачности разметки',
    upto500kPerMonth: 'До 500 000 в месяц',
    beyond500k: 'За пределами 500 000',
    unlimited: 'Неограниченный',
    oneTeamMember: '1 член команды',
    unlimitedTeamMember: 'Неограниченные члены команды',
    nintyDaysOfDataStorage: '90 дней хранения данных',
    unlimitedDataStorage: 'Неограниченное хранилище данных',
    detailedPricing: {
      onDemandAuditLog: 'Аудит по требованию',
      accessRestrictionsBasedOnIPs: 'Ограничения доступа на основе IP-адресов',
      averageAgentResponeTime: 'Среднее время ответа агента',
      perMonth100: '100 в месяц',
      solutionType: 'Тип решения',
      saas: 'SaaS',
      saasOnPremises: 'SaaS на месте',
      emperorPlan: 'План Императора',
      babyPlan: 'План Baby',
      kingsplan: 'План Королей',
      braodcastsAndDrips: 'Трансляции и Поступления',
      thirtyDays: '30 дней',
      ninetyDays: '90 дней',
      oneMember: '1 участник',
      threeMembers: '3 участника',
      one: '1 (Один)',
      unlimitedMessages: 'Неограниченные сообщения',
      unlimitedBots: 'Неограниченные боты',
      messagesSend: 'Отправленные сообщения',
      upto500000perMonth: 'До 500 000 в месяц',
      unlimited: 'Неограниченно',
      onlyAdmin: 'Только администратор',
      mb100: '100 МБ',
      gb1: '1 ГБ',
      doItYourself: 'Сделай сам',
      weAreHereToHelp: 'Мы здесь, чтобы помочь',
      weTakeCareEndToEnd: 'Мы заботимся с начала до конца',
      numberOfBots: 'Количество ботов',
      conversations: 'Диалоги',
      gptMessages: 'Сообщения GPT',
      whatsappNumbers: 'Номера WhatsApp',
      subscribersLeads: 'Подписчики/Лиды',
      createSupportTeamForLiveChat: 'Создать команду поддержки для онлайн-чата',
      conversationHistory: 'История диалогов',
      chatbotDesigning: 'Проектирование чат-бота',
      multipleLanguages: 'Множество языков',
      conditionalFlow: 'Условный поток',
      whatsappTemplates: 'Шаблоны WhatsApp',
      restartChatFlowTriggers: 'Триггеры перезапуска чата',
      multipleWhatsAppWidgets: 'Несколько виджетов WhatsApp',
      importWhatsappContacts: 'Импорт контактов WhatsApp',
      whatsappQuickReply: 'Быстрый ответ в WhatsApp',
      whatsappSmartInbox: 'Умный почтовый ящик WhatsApp',
      whatsappEcommerceCatalogs: 'Электронная коммерция WhatsApp с каталогами',
      whatsappBroadcast: 'Трансляция WhatsApp',
      whatsappBroadcastAnalytics: 'Аналитика трансляций WhatsApp',
      dripCampaigns: 'Капельные кампании',
      customerSegmentation: 'Сегментация клиентов',
      keywordBasedResponse: 'Ответ на основе ключевых слов',
      whatsappShopManagement: 'Управление магазином WhatsApp',
      aiChatbot: 'AI Чат-бот',
      chatGPTIntegration: 'Интеграция ChatGPT',
      configureAIChatbotPersonality: 'Настройка личности AI Чат-бота',
      trainChatbotOnYourOwnData: 'Обучение Чат-бота на ваших собственных данных',
      trainChatbotOnWebPages: 'Обучение Чат-бота на веб-страницах',
      trainChatbotOnMultipleFiles: 'Обучение Чат-бота на нескольких файлах',
      trainChatbotOnFAQs: 'Обучение Чат-бота на ЧАВО',
      dataTrainingStorage: 'Хранилище данных для обучения',
      useChatGPTModel4or35Turbo: 'Использование модели ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Загрузка нескольких файлов для обучения чат-бота',
      naturalLanguageProcessing: 'Обработка естественного языка',
      naturalLanguageUnderstanding: 'Понимание естественного языка',
      answerLength: 'Длина ответа',
      chatTone: 'Тон чата',
      multiLanguage: 'Многоязычный',
      answerFormatting: 'Форматирование ответа',
      customPrompt: 'Пользовательский запрос',
      whatsAppSessionManagement: 'Управление сессией WhatsApp (сообщения об неактивности и перезапуск потока)',
      integrateBotPenguinLLM: 'Интеграция BotPenguin LLM',
      integrateOwnCustomLLM: 'Интеграция собственного/пользовательского LLM',
      periodicallySyncDataSources: 'Периодическая синхронизация источников данных',
      aiBasedSuggestedQuestions: 'Вопросы, предложенные на основе ИИ',
      chooseVisibilityForParticularWebpageSitemap: 'Выбор видимости для конкретной веб-страницы/карты сайта',
      chooseKnowledgeMix: 'Выбор знаний: Ваша база знаний ChatGPT "База знаний ChatGPT + Ваша база знаний"',
      faqExtractionFromFileAndURL: 'Извлечение ЧАВО из файла и URL',
      analyticsToImproveAI: 'Аналитика для улучшения ИИ',
      exportQuestionFlow: 'Экспорт потока вопросов',
      collectFeedbackOnChatbotResponses: 'Сбор обратной связи по ответам чат-бота',
      smartAIFollowUpQuestions: 'Умные вопросы для последующего обсуждения на основе ИИ',
      trainOnYourNotionWorkspaceData: 'Обучение на данных вашего рабочего пространства в Notion',
      trainOnYoutubeVideo: 'Обучение на видео YouTube',
      trainOnCustomVideoUpload: 'Обучение на пользовательской загрузке видео',
      rssFeed: 'RSS-канал',
      googleDrive: 'Google Диск',
      dropBox: 'DropBox',
      humanHandover: 'Передача человеку',
      botHandover: 'Передача бота',
      handoverBetweenAgents: 'Передача между агентами',
      emailAndMobileValidation: 'Проверка электронной почты и мобильного телефона',
      mobileAppsForAgents: 'Мобильные приложения для агентов',
      appointmentBooking: 'Бронирование встреч',
      roleBasedAccessControls: 'Управление доступом на основе ролей',
      ruleBasedChatRouting: 'Маршрутизация чата на основе правил',
      liveVisitorsDetails: 'Данные о посетителях в реальном времени',
      multipleSimultaneousChats: 'Множество одновременных чатов',
      visitorStats: 'Статистика посетителей',
      addContactsFromWhatsApp: 'Добавление контактов из WhatsApp',
      applyFilterAndSortConversations: 'Применение фильтров и сортировка бесед',
      seeChatLogs: 'Просмотр логов чата',
      quickReplies: 'Быстрые ответы',
      unifiedCustomerProfile: 'Общий профиль клиента',
      assignNotesAndChatTags: 'Назначение заметок и тегов к чату',
      exportLeadsInCSV: 'Экспорт лидов в формате CSV',
      tagContacts: 'Маркировка контактов',
      customAttributes: 'Пользовательские атрибуты',
      markStatusOfConversations: 'Отметка статуса бесед',
      assignConversationToAgents: 'Назначение бесед агентам',
      leadNotificationsByEmail: 'Уведомления о лидах по электронной почте',
      directCallsToLeadsWithTwilio: 'Прямые звонки лидам с помощью Twilio',
      whatsAppTemplates: 'Шаблоны WhatsApp',
      promotionalMessageFrequencyControl: 'Управление частотой рекламных сообщений',
      whatsAppWidget: 'Виджет WhatsApp',
      whatsAppQR: 'QR-код WhatsApp',
      whatsAppLink: 'Ссылка WhatsApp',
      campaignScheduler: 'Планировщик кампаний',
      customTriggers: 'Пользовательские триггеры',
      customCampaigns: 'Пользовательские кампании',
      repeatPurchaseReminders: 'Напоминания о повторных покупках',
      campaignOpenRateTracking: 'Отслеживание открытой скорости кампании',
      paymentCollectionOnWhatsApp: 'Сбор платежей в WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Капсула',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Пайплайн',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Закрыть',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Медь',
      autopilot: 'Автопилот',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Таблицы Google',
      googleCalendar: 'Календарь Google',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Система обработки запросов поддержки',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Аудио- и видеозвонки в чате (Twilio/Agora)',
      courierTracking: 'Отслеживание курьеров',
      emailIntegration: 'Интеграция электронной почты',
      webhooks: 'Вебхуки',
      schedulers: 'Планировщики',
      paymentGateways: 'Платежные шлюзы (Stripe и RazorPay)',
      enterpriseIntegrations: 'Корпоративные интеграции',
      customIntegration: 'Пользовательская интеграция',
      freeWhatsAppAPIApprovalAndSetup: 'Бесплатное одобрение и настройка API WhatsApp',
      developerAPIsAccess: 'Доступ к API разработчика',
      customRateLimit: 'Пользовательский лимит скорости',
      totalNumberOfLeads: 'Общее количество лидов',
      averageAgentResponseTime: 'Среднее время ответа агента',
      visitorTimingReport: 'Отчет по времени посетителей',
      customerSegmentBasedReports: 'Отчеты на основе сегментации клиентов',
      tagBasedReports: 'Отчеты на основе тегов',
      leadGenerationReport: 'Отчет по генерации лидов',
      supportOnWhatsApp: 'Поддержка в WhatsApp',
      knowledgeBase: 'База знаний',
      onboardingAndTraining: 'Ввод и обучение',
      callAndEmailSupport: 'Поддержка по телефону и по электронной почте',
      freeChatbotCreation: 'Создание бесплатного чат-бота',
      customerSuccessOfficer: 'Офицер по работе с клиентами',
      secureConnection: 'Защищенное соединение SSL 2048 бит',
      securityAssessment: 'Оценка безопасности',
      dataCenterLocation: 'Местоположение центра обработки данных (США или ЕС)',
      twoStepVerification: 'Двухэтапная проверка',
      accessRestrictions: 'Ограничения доступа',
      auditLog: 'Журнал аудита по требованию',
      bannedVisitorsList: 'Список заблокированных посетителей',
      customAPIRate: 'Пользовательская ставка API',
      trustedDomains: 'Доверенные домены',
      elementary: 'Элементарно',
      bots: 'Боты',
      unlockWhatsapp: 'Разблокировать WhatsApp',
      aiChatbotFeatures: 'Функции ИИ чат-бота',
      dataSourceForTraining: 'Источник данных для обучения',
      smartFeatures: 'Умные функции',
      liveChat: 'Онлайн-чат',
      smartInbox: 'Умный почтовый ящик',
      broadcastsAndDrips: 'Трансляции и Капельные кампании',
      integrations: 'Интеграции',
      development: 'Разработка',
      reportsAndAnalytics: 'Отчеты и аналитика',
      customerSupport: 'Поддержка клиентов',
      security: 'Безопасность',
      whatsappWidgets: 'Виджеты WhatsApp',
      campaignSchedular: 'Планировщик кампаний',
      repeatPurchaseRemainder: 'Напоминания о повторных покупках',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Наш бесплатный план',
      TOOLTIP_KING_PLAN: 'Наш самый популярный план',
      TOOLTIP_EMPEROR_PLAN: 'Индивидуальный план для неограниченных сообщений, неограниченных данных, неограниченных членов команды',
      TOOLTIP_CONTACT_US: 'Обратитесь к нам через WhatsApp или по электронной почте по адресу contact@botpenguin.com, чтобы узнать больше о нашем индивидуальном плане',
      TOOLTIP_UPGRADE: 'Обновите свой текущий план для дополнительных функций',
      TOOLTIP_MONTHLY: 'Выберите ежемесячный цикл оплаты',
      TOOLTIP_YEARLY: 'Выберите ежегодный цикл платежей для скидки',
    }
  }
};
