import {Action, createReducer, on} from '@ngrx/store';
import { addWhatAppTemplates, loadWhatsAppTemplates, removeWhatsAppTemplates, modifyWhatsAppTemplate } from '../actions/whatsapp-templates.actions';


export const initialState: any[] = [];

const whatsAppTemplatesReducer =  createReducer(
    initialState,
    on(loadWhatsAppTemplates, (state, {templates}) => templates),
    on(addWhatAppTemplates,  (state, {template}) => [...state, template]),
    on(removeWhatsAppTemplates, (state, {template}) => state.filter(item => item._id !== template)),
    on(modifyWhatsAppTemplate, (state, {template, update}) => state.map(item => {
        if (String(item._id) === String(template)) {
          return {...item, ...update};
        }
        return item;
      })),
);

export function reducer(state: any | undefined, action: Action) {
    return whatsAppTemplatesReducer(state, action);
  }
