import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(
    private networkService: NetworkService,
  ) {
  }

  acceptLiveChatRequest(payload: any) {
    const { uuid, _bot, _customer, _user } = payload;
    const _id = payload.requestId;
    if (payload.agentId) {
      const agentId = payload.agentId;
      return this.networkService.httpRequest('real-time/transferred-request/accept', { uuid, _bot, _customer,  _id}, 'PUT');
    }else{
      return this.networkService.httpRequest('real-time/request/accept', { uuid, _bot, _customer, _id}, 'PUT');
    }
  }

  acceptWhatsAppLiveChatRequest(payload: any) {
    const { uuid, _bot, _customer, _user } = payload;
    const _id = payload.requestId;
    if (payload.agentId) {
      const agentId = payload.agentId;
      return this.networkService.httpRequest('real-time/whatsapp-transferred-request/accept', { uuid, _bot, _customer,  _id}, 'PUT');
    }else{
      return this.networkService.httpRequest('real-time/request/accept', { uuid, _bot, _customer, _id}, 'PUT');
    }
  }

  rejectLiveChatRequest(requestId: any) {
    return this.networkService.httpRequest(`real-time/request/reject/${requestId}`, null, 'PUT');
  }

  rejectTransferredRequest(requestId: any) {
    return this.networkService.httpRequest(`real-time/transferred-request/reject/${requestId}`, null, 'PUT');
  }

  getRequestNotifications() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('notification', null, 'GET', true)
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  markNotificationAsRead() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('notification/read', {}, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  changeTelegramBotStatus(id, token, status) {
    return this.networkService.httpRequest(`telegram-automation/bot/${token}`, {
      status
    }, 'PUT');
  }
  changeWhatsAppBotStatus( _bot, status) {
    return this.networkService.httpRequest(`whatsapp-automation/bot/${_bot}`, {
      status
    }, 'PUT');
  }

  changePageStatus(id, pageId, token, status) {
    return new Promise((resolve, reject) => {
      if (status === 'ACTIVE') {
        this.subscribeApps(pageId, token)
          .then(() => this.networkService.httpRequest(`facebook-automation/bots/bot/${id}`, {
            status
          }, 'PUT'))
          .then(resolve)
          .catch(reject);
      } else {
        this.unsubscribeApps(pageId, token)
          .then(() => this.networkService.httpRequest(`facebook-automation/bots/bot/${id}`, {
            status
          }, 'PUT'))
          .then(resolve)
          .catch(reject);
      }
    });
  }

  subscribeApps(pageId, pageToken) {
    return new Promise((resolve, reject) => {
      fetch(`https://graph.facebook.com/v9.0/${pageId}/subscribed_apps?access_token=${pageToken}`, {
        body: JSON.stringify({
          subscribed_fields: 'messages,messaging_postbacks,message_reads,message_deliveries,feed,messaging_optins'
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })
        .then((res: any) => {
          if (res.status === 200) {
            return res.json();
          } else {
            reject(res.json());
          }
        })
        .then(resolve)
        .catch(reject);
    });
  }

  unsubscribeApps(pageId, pageToken) {
    return new Promise((resolve, reject) => {
      fetch(`https://graph.facebook.com/v9.0/${pageId}/subscribed_apps?access_token=${pageToken}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'DELETE'
      })
        .then((res: any) => res.json())
        .then(resolve)
        .catch(reject);
    });
  }
}
