export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'مرحبًا بك في BotPenguin!',
      login: 'تسجيل الدخول',
      youAreAllSet: 'أنت جاهز تمامًا! يمكنك استخدام صندوق الوارد على WhatsApp من صفحة تفاصيل العميل المتوقع.',
      placeholders: {
        enterEmail: 'أدخل البريد الإلكتروني',
        enterPassword: 'أدخل كلمة المرور'
      }
    }
  }
};
