export const notification = {
  notification: 'Meldingen',
  allNotifications: 'Alle meldingen',
  general: 'Algemeen',
  settings: 'Instellingen',
  newChatRequest: {
    youHaveANewChatRequest: 'Je hebt een nieuw chatverzoek!!!',
    youHaveTransferredChatRequest: 'Je hebt een overgedragen chatverzoek!!!',
    acceptRequest: 'Accepteer Verzoek',
    discardRequest: 'Verwerp Verzoek'
  },
  notificationList: {
    name: 'Naam',
    message: 'Bericht',
    noDataFound: {
      notification: 'Melding',
      noNotificationsFound: 'Geen meldingen gevonden!',
    }
  },
  notificationSetting: {
    userMessage: 'Gebruikersbericht',
    receiveNotificationsWhenUserSendMessage: 'Ontvang meldingen wanneer een gebruiker een bericht stuurt',
    liveChatRequest: 'Live Chat Verzoek',
    receiveNotificationsWhenUserRequestLiveChat: 'Ontvang meldingen wanneer een gebruiker een live chat aanvraagt',
    newLead: 'Nieuwe Lead',
    receiveNotificationsWhenNewLeadCreated: 'Ontvang meldingen wanneer er een nieuwe lead wordt aangemaakt',
    assignedOnly: 'Alleen toegewezen',
    allUsers: 'Alle Gebruikers',
    customizeWhichNotificationsYouWantToRecieve: 'Pas aan welke meldingen je wilt ontvangen',
    youCanCustomizeSettingForTheNotifications: 'Je kunt instellingen aanpassen voor de meldingen',
    receiveNotifications: 'Ontvang Meldingen',
    desktopNotifications: 'Desktop Meldingen',
    mobilePushNotifications: 'Mobiele Push Meldingen',
    discard: 'Annuleren',
    update: 'Bijwerken',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Stuur meldingen van nieuw bericht op desktop',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Stuur meldingen van nieuw bericht op mobiel',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Stuur meldingen van nieuwe lead op desktop',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Stuur meldingen van nieuwe lead op mobiel',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Stuur meldingen van live chat op desktop',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Stuur meldingen van live chat op mobiel',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Kies welke berichten je meldingen voor wilt ontvangen'
    }
  }
};
