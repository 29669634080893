const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Mein Konto',
  yourProfileAndPasswordSecurity: 'Ihr Profil- und Passwortsicherheit',
  detailsHeader: 'Einzelheiten',
  passwordHeader: 'Passwort',
  customAttributeHeader: 'Benutzerdefinierte Attribute',
  tagsHeader: 'Stichworte',
  accessTokenHeader: 'Zugangstoken',
  thirdPartyIntegrationsHeader: 'Integrationen von Drittanbietern',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Aktualisieren Sie Ihr Kontoprofil',
    TOOLTIP_PASSWORD_HEADING: 'Ändern Sie Ihr Passwort',
    TOOLTIP_TAGS_HEADING: 'Verwalten Sie Ihre Tags',
    TOOLTIP_THIRD_PARTY_HEADING: 'Integrationen von Drittanbietern',
    TOOLTIP_CHANGE_PASSWORD_TITLE: 'Ein sicheres Passwort schützt Ihr $ {Brand} -Konto',
    TOOLTIP_INTEGRATIONS: 'Integrieren',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Benutzerdefinierte Attribute hinzufügen oder aktualisieren',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Beheer uw aangepaste attributen'
  },
  accessToken: {
    accessToken: 'Zugangstoken',
    generateYourAccessTokenForUsingPlatformAPIs: 'Generieren Sie Ihr Zugriffstoken für die Verwendung von Plattform -APIs',
    generateApiToken: 'API -Token erzeugen',
    revokeApiToken: 'Revoke API -Token',
    regenerateApiToken: 'API -Token regenerieren',
    placeholders: {
      yourApiKey: 'Ihr API -Schlüssel',
    }
  },
  details: {
    name: 'Name',
    emailId: 'E -Mail -ID',
    yourPlan: 'Dein Plan',
    messagesPerMonth: ' Nachrichten/Monat',
    daysOfStoragePlan: ' Aufbewahrungsplan',
    conditionalFeatures: 'Bedingte Merkmale',
    upgradeToEnterprisePlanToGetMore: 'Upgrade auf den Unternehmensplan, um mehr zu bekommen',
    featuresAndStorageCapacity: 'Funktions- und Speicherkapazität',
    upgradePlan: 'Upgrade -Plan',
    submit: 'Einreichen',
    selectLanguage: 'Sprache auswählen',
    baby: 'Baby',
    king: 'König',
    conversationsperMonth: 'Deckungen/Monat',
    english: 'Englisch',
    hindi: 'Hindi',
    german: 'Deutsch',
    french: 'Französisch',
    portuguese: 'Portugiese',
    spanish: 'Spanisch',
    italian: 'Italienisch',
    russian: 'Russisch',
    chatBotBrandName: 'Chatbot-Markenname',
    placeholders: {
      john: 'John',
      email: 'Email',
      searchLanguage: 'Suchsprache',
      chatBotBrandName: 'Chatbot-Markenname'
    },
    appTooltip: {
      TOOLTIP_NAME: 'Geben Sie Ihren Profilnamen ein',
      TOOLTIP_EMAIL_ADDRESS: 'Geben sie ihre E-Mailadresse ein',
    }
  },
  customAttribute: {
    addAttribute: 'Attribut hinzufügen',
    discard: 'Verwerfen',
    customAttribute: 'Benutzerdefinierte Attribute',
    checkOrAddCustomAttribute: 'Hier können Sie Ihre benutzerdefinierten Attribute überprüfen und hinzufügen.',
    attributeKey: 'Attributschlüssel',
    attributeName: 'Attributname',
    bot: 'Bot',
    flow: 'Ablauf',
    loadMore: 'Mehr laden',
    question: 'Frage',
    action: 'Aktion',
    search: 'Suchen',
    selectBot: 'Bot auswählen',
    selectFlow: 'Ablauf auswählen',
    selectQuestion: 'Frage auswählen',
    noDataFoundHeading: 'Sie haben noch keine Eigenschaft erstellt',
    noDataFoundSubheading: 'Alle Ihre Ehrungen werden hier angezeigt',
    deleteTitle: 'Attribut Löschen',
    deleteDescription: 'Sind Sie sicher, dass Sie dieses Attribut löschen möchten?',
    deleteAttributes: 'Attribute Löschen',
    areYouSureYouWantToDeleteThisAttributes: 'Sind Sie sicher, dass Sie diese Attribute löschen möchten?',
    editAttribute: 'Attribut Bearbeiten',
    headsUp: 'Kopf hoch',
    okay: 'Okay',
    dataAddedToLead: 'Die den APIs oder Bot-Flows zugeordneten Attribute werden nicht aktualisiert. Bitte aktualisieren Sie die APIs oder Bot-Flows entsprechend.',
    placeholders: {
      enterAttributeKey: 'Attributschlüssel eingeben',
      enterAttributeName: 'Attributname eingeben',
      selectBot: 'Bot auswählen',
      selectFlow: 'Ablauf auswählen',
      selectQuestion: 'Frage auswählen',
      search: 'Suche nach Attributnamen'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Attributschlüssel eingeben',
      TOOLTIP_ATTRIBUTE_NAME: 'Attributname eingeben',
      TOOLTIP_SELECT_BOT: 'Bot auswählen',
      TOOLTIP_SELECT_FLOW: 'Ablauf auswählen',
      TOOLTIP_SELECT_QUESTION: 'Frage auswählen',
    }
  },
  password: {
    changePassword: 'Kennwort ändern',
    aSecurePasswordHelpsProtectYour: 'Ein sicheres Passwort schützt Ihre',
    account: ' Konto',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Kennwort',
    confirmPassword: 'Bestätige das Passwort',
    cancel: 'Stornieren',
    updatePassword: 'Kennwort aktualisieren',
    deleteAccount: 'Konto löschen',
    placeholders: {
      enterYourCurrentPassword: 'Gib dein aktuelles Passwort ein',
      enterYourNewPassword: 'Gib dein neues Passwort ein',
      enterYourConfirmPassword: 'Geben Sie Ihr Bestätigungskennwort ein',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Geben Sie ein altes Passwort ein',
      TOOLTIP_NEW_PASSWORD: 'Neues Passwort eingeben',
      TOOLTIP_CONFIRM_PASSWORD: 'Bestätigen Sie Ihr neues Passwort',
      TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Tags verwalten',
    createNewTags: 'Neue Tags erstellen',
    createNewTagsSubText: 'Verwalten Sie hier Ihre Tags, um Ihre Leads, Chats, Gruppen usw. besser zu organisieren',
    tagName: 'Tag-Name',
    updateTag: 'Tag aktualisieren',
    createTag: 'Tag erstellen',
    discard: 'Verwerfen',
    searchTag: 'Tag nach Namen suchen',
    noTagFound: 'Kein Tag gefunden!',
    createATag: 'Tag erstellen',
    deleteTagModalSubText: 'Sind Sie sicher, dass Sie dieses Tag löschen möchten? Das Löschen dieses Tags entfernt es auch von den zugewiesenen Benutzern!',
    deleteTagModalHeading: 'Tags löschen',
    deleteOneTagModalHeading: 'Tag löschen',
    actions: 'Aktionen',
    placeholders: {
        enterTag: 'Tag-Namen eingeben',
    },
    appTooltip: {
        TOOLTIP_DELETE_TAGS: 'Tags löschen',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'Name',
      status: 'Status',
      action: 'Aktion',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird zum Senden von Nachrichten und E -Mails',
      notConnected: 'Nicht verbunden',
      connected: 'In Verbindung gebracht',
      edit: 'Bearbeiten',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo zum Senden von Nachrichten',
      connect: 'Verbinden',
      appTooltip: {
        nameOfAppOrCrm: 'Name von App/CRM',
        statusAsConnectedOrNotConnected: 'Status als verbunden / nicht verbunden',
        clickToConnectAppOrCrm: 'Klicken Sie hier, um die App/CRM zu verbinden',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Hoppla! Integrationen sind im Babyplan nicht verfügbar. Bitte upgrade auf den King -Plan für Integrationen.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'In MessageBird integrieren',
        apiKey: 'API-Schlüssel',
        originator: 'Urheber',
        connect: 'Verbinden',
        disconnect: 'Trennen',
        seeActivities: 'Siehe Aktivitäten',
        sms: 'SMS',
        time: 'Zeit',
        phoneNumber: 'Telefonnummer',
        status: 'Status',
        botName: 'Bot Name',
        message: 'Nachricht',
        error: 'Fehler',
        placeholders: {
          apiKey: 'API-Schlüssel',
          messageBirdPhoneNumber: 'MessageBird Telefonnummer',
        },
        noDataFound: {
          activities: 'Aktivitäten',
          noActivitiesFound: 'Keine Aktivitäten gefunden!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'In Plivo integrieren',
        authId: 'Auth id',
        authToken: 'Auth Token',
        yourPlivoPhoneNumber: 'Ihre Plivo -Telefonnummer',
        connect: 'Verbinden',
        disconnect: 'Trennen',
        seeActivities: 'Siehe Aktivitäten',
        sms: 'SMS',
        time: 'Zeit',
        phoneNumber: 'Telefonnummer',
        status: 'Status',
        botName: 'Bot Name',
        message: 'Nachricht',
        error: 'Fehler',
        noDataFound: {
          activities: 'Aktivitäten',
          noActivitiesFound: 'Keine Aktivitäten gefunden!',
        },
        placeholders: {
          authId: 'Auth id',
          authToken: 'Auth Token',
          yourPlivoPhoneNumber: 'Ihre Plivo -Telefonnummer',
        }
      }
    }
  }
};
