export const whitelabel = {
  plans: 'Des plans',
  managePlan: 'Gérer les plans',
  whitelabelCharges: 'Charges Whitelabel',
  chargesOrTaxesConfiguration: 'Configuration des frais / taxes',
  websitePlans: 'Plans de site Web',
  whatsappPlansHeader: 'Plans WhatsApp',
  addOnPlans: {
    createAddOnPlans: 'Créer des plans complémentaires',
    checkoutYourCurrentMessage: 'Consultez vos plans de modules complémentaires de message actuels et les nouveaux plans pour vos clients',
    choosePlanValidity: 'Choisissez la validité du plan',
    chooseValidity: 'Choisissez la validité',
    oneYear: 'Un ans',
    billingCycle: 'Cycle de facturation',
    numberOfMessages: 'Nombre de messages',
    pleaseEnterNumberOfMessages: 'Veuillez saisir le nombre de messages',
    setCurrency: 'Régler la monnaie',
    select: 'Sélectionner',
    inr: 'INR',
    usd: 'USD',
    cad: 'GOUJAT',
    clearAll: 'Tout effacer',
    apply: 'Appliquer',
    currency: 'Monnaie',
    amount: 'Montant',
    validity: 'Validité',
    upgradePlan: 'Plan de mise à jour',
    addPlan: 'Ajouter un plan',
    serialNumber: 'Sr. Non',
    messages: 'messages',
    // validity: 'Validité',
    active: 'Actif',
    priceDetails: 'Détails des prix',
    action: 'Action',
    addOnChanges: 'Frais complémentaires:',
    // messages: ' messages',
    perYear: 'par an',
    taxes: 'Taxes',
    placeholders: {
      enterNumberOfMesasges: 'Entrez le nombre de messages',
      enterAmount: 'Entrer le montant',
      enterValidity: 'Entrer la validité',
      searchByMessages: 'Recherche par messages',
    },
    modal: {
      deleteAddOnsPlan: 'Supprimer le plan complémentaire',
      wantToDeleteThisAddOnsPlan: 'Êtes-vous sûr de vouloir supprimer ce plan complémentaire?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Ajouter les frais de WhiteLabel',
    checkoutExistingCharges: 'Consultez les frais existants sur les différentes méthodes de paiement et ajoutez de nouveaux frais WhiteLabel',
    currency: 'Monnaie',
    chooseCurrency: 'Choisir la devise',
    amountPerBotPerMonth: 'Montant par bot par mois',
    cancel: 'Annuler',
    upgradeChanges: 'Mettre à jour les frais',
    addCharges: 'Ajouter des frais',
    serialNumber: 'Sr. Non',
    // currency: 'Monnaie',
    action: 'Action',
    taxes: 'Taxes',
    placeholders: {
      enterAmountBotPerMonth: 'Entrez le montant du bot par mois',
      searchByCurrency: 'Recherche par monnaie',
    },
    modal: {
      deleteCharges: 'Supprimer les frais',
      wantToDeleteThisCharge: 'Êtes-vous sûr de vouloir supprimer cette charge?',
    }
  },
  planManagement: {
    createNewPlans: 'Créer de nouveaux plans',
    checkoutYourCurrentPlan: 'Consultez vos plans actuels et ajoutez de nouveaux plans pour vos clients',
    choosePlan: 'Choisir le plan',
    selectPlan: 'Sélectionnez le plan',
    baby: 'Bébé',
    king: 'Roi',
    displayName: 'Afficher un nom',
    messageLimit: 'Limite de message',
    enterAValidEmail: 'Entrer un email valide',
    setCurrency: 'Régler la monnaie',
    select: 'Sélectionner',
    inr: 'INR',
    usd: 'USD',
    cad: 'GOUJAT',
    clearAll: 'Tout effacer',
    apply: 'Appliquer',
    perMonth: '/Mois',
    perYear: '/Année',
    cancel: 'Annuler',
    upgradePlan: 'Plan de mise à jour',
    addPlan: 'Ajouter un plan',
    serialNumber: 'Sr. Non',
    plan: ' Plan',
    // displayName: 'Afficher un nom',
    messages: 'messages',
    pricing: 'Prix',
    actions: 'Actions',
    // plan: ' Plan',
    perMonthText: 'Par mois',
    perYearText: 'Par an',
    placeholders: {
      enterDisplayName: 'Entrez le nom de l`affichage',
      enterMessageLimit: 'Entrez la limite des messages',
      searchByPlanName: 'Rechercher par nom du plan ou messages'
    },
    modal: {
      deletePlan: 'Supprimer le Plan',
      wantToDeleteThisBabyPlan: 'La suppression du Plan Bébé aura un impact sur tous les clients. Êtes-vous sûr de vouloir supprimer ce plan ?',
      wantToDeleteThisPlan: 'Êtes-vous sûr de vouloir supprimer ce plan?',
    },
    appTooltip: {
      chooseYourPlanType: 'Choisissez votre type de type',
      pleaseInputYourPlanDisplayName: 'Veuillez saisir le nom d`affichage de votre plan',
      typeMessageLimitForCustomer: 'Tapez la limite de messages pour le client',
      selectYourPreferredCurrencies: 'Sélectionnez vos devises préférées',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Ajouter des taxes',
    checkoutExistingTaxes: 'Découvrez les taxes existantes sur les différentes méthodes de paiement et les nouvelles taxes',
    taxName: 'Nom de taxe',
    applicableOn: 'Applicable sur',
    choosePlans: 'Choisir les plans',
    chooseCurrency: 'Choisir la devise',
    percentage: 'Pourcentage',
    cancel: 'Annuler',
    // addTaxes: 'Ajouter des taxes',
    serialNumber: 'Sr. Non',
    name: 'nom',
    // percentage: 'Pourcentage',
    currency: 'Monnaie',
    updateTaxes: 'Mettre à jour les taxes',
    actions: 'Actions',
    modal: {
      deleteTaxes: 'Supprimer les taxes',
      wantToDeleteThisTax: 'Êtes-vous sûr de vouloir supprimer ces taxes?',
    },
    placeholders: {
      taxName: 'Nom de taxe',
      taxPercentage: 'Pourcentage d`impôt',
      searchByCurrency: 'Recherche par monnaie',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Créer de nouveaux plans',
    checkoutYourCurrentAndNewPlans: 'Consultez vos plans actuels et ajoutez de nouveaux plans pour vos clients',
    choosePlan: 'Choisir le plan',
    selectPlan: 'Sélectionnez le plan',
    baby: 'Bébé',
    king: 'Roi',
    displayName: 'Afficher un nom',
    messageLimit: 'Limite de Conversation',
    enterAValidMessageLimit: 'Entrez une limite de conversation valide',
    messages: 'Conversations',
    setCurrency: 'Régler la monnaie',
    select: 'Sélectionner',
    inr: 'INR',
    usd: 'USD',
    cad: 'GOUJAT',
    clearAll: 'Tout effacer',
    apply: 'Appliquer',
    perMonth: '/Mois',
    perYear: '/Année',
    cancel: 'Annuler',
    addPlan: 'Ajouter un plan',
    serialNumber: 'Sr. Non',
    plan: 'Plan',
    pricing: 'Prix',
    actions: 'Actions',
    perMonthText: 'Par mois',
    perYearText: 'Par an',
    updatePlan: 'Plan de mise à jour',
    // addPlan: 'Ajouter un plan',
    placeholders: {
      enterDisplayName: 'Entrez le nom de l`affichage',
      enterMessageLimit: 'Entrez la limite de conversation',
      searchByPlanName: 'Recherche par nom du plan ou Conversations',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Entrez la limite de conversation pour le client',
      chooseYourPlanType: 'Choisissez votre type de type',
      selectYourPreferredCurrencies: 'Sélectionnez vos devises préférées',
      pleaseInputYourPlanDisplayName: 'Veuillez saisir le nom d`affichage de votre plan',
    },
    modal: {
      deletePlan: 'Supprimer le Plan',
      wantToDeleteThisBabyPlan: 'La suppression du Plan Bébé aura un impact sur tous les clients. Êtes-vous sûr de vouloir supprimer ce plan ?',
      areYouSureYouWantToDeleteThisPlan: 'Êtes-vous sûr de vouloir supprimer ce plan?',
    }
  }
};
