export const inboxMerged = {
  inboxHeader: 'Bandeja de entrada',
  chatsHeader: 'Charlatanes',
  contactsHeader: 'Contactos',
  segmentsHeader: 'Grupos',
  ordersHeader: 'Pedidos',
  exportStatus: 'Estado de exportación',
  tasksExport: 'Tareas: exportación',
  download: 'Descargar',
  ourAllNewOmniChannelInbox: '¡Nuestra nueva bandeja de entrada omnicanal!',
  allYourMessagesContactsAndLeads: 'Todos sus mensajes, contactos y clientes potenciales ahora están en un solo lugar. ¡Listo para ser ordenado y grupoado, para que su negocio crezca!',
  close: 'Cerca',
  takeALook: 'Echar un vistazo',
  inProgress: 'en curso',
  appTooltip: {
    manageAllYourLeadsHere: 'Administre todos sus clientes potenciales/contactos aquí',
    segmentYourUserLeads: 'Grupo sus clientes potenciales/usuarios de BOT en función de la demografía e intereses. Estos se utilizarán para crear transmisiones',
    manageAllBotsConversationsHere: 'Administre todas las conversaciones de los bots aquí',
    manageAllYourOrdersHere: 'Administre todos sus pedidos aquí',
  },
  contacts: {
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    visitorName: 'Nombre del visitante',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    segment: 'Grupo',
    status: 'Estado',
    tag: 'Etiqueta',
    action: 'Acción',
    discard: 'Desechar',
    addTag: 'Añadir etiqueta',
    loadMore: 'Carga más',
    nA: 'N / A',
    assign: 'Asignar',
    deleteUser: 'Borrar usuario',
    areYouSureToDeleteLead: '¿Estás seguro de que quieres eliminar este liderazgo?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'No tienes contactos todavía!',
      allOfYourContactsAppearHere: 'Todos tus contactos aparecerán aquí'
    },
    placeholders: {
      searchSegmentByName: 'Grupo de búsqueda por nombre',
      searchOrAddTag: 'Buscar o agregar una etiqueta',
    },
    addContacts: {
      name: 'Nombre',
      email: 'Correo electrónico',
      enterAValidEmail: 'Introduzca un correo electrónico válido',
      phoneNumber: 'Número de teléfono',
      cancel: 'Cancelar',
      addContact: 'Agregar contacto',
      placeholders: {
        enterEmailAddress: 'Introducir la dirección de correo electrónico',
        enterUserrName: 'Introduzca su nombre de usuario',
        searchCountry: 'Búsqueda del país',
        enterPhoneNumber: 'Ingresa número telefónico',
      }
    }
  },
  inboxFilter: {
    applied: 'aplicado',
    assignTags: 'Asignar etiquetas',
    discard: 'Desechar',
    addTag: 'Añadir etiqueta',
    assignSegments: 'Asignar Grupos',
    filterYourLeads: 'Filtra tus cables',
    filters: 'filtros',
    filter: 'filtrar',
    resetAllFilters: 'Restablecer todos los filtros',
    importContacts: 'Importar contactos',
    beforeYouStart: 'Antes de comenzar a subir por favor asegúrese de',
    point1: '1. Descargue el archivo de Excel de muestra.',
    point2: '2. Comience a ingresar sus datos desde la segunda fila en adelante.',
    point3: '3. En cada fila, habrá datos para un contacto',
    point4: '4. Asegúrese de que todos los números de teléfono sean únicos.',
    point5: '5. El límite máximo de carga de tamaño de archivo es 1 MB',
    selectFile: 'Seleccione Archivo',
    clickHere: 'haga clic aquí',
    toDownloadSampleFile: 'Para descargar el archivo de muestra',
    modal: {
      closeLeads: 'Peques cerrados',
      areYouSureToselectAllLeads: '¿Estás seguro de que quieres cerrar todos los clientes potenciales seleccionados?',
      assignAgent: 'Asignar agente',
      toAssignAgentToAllLeads: '¿Está seguro de que desea asignar agente a todos los clientes potenciales seleccionados?',
      assignTag: 'Asignar etiqueta',
      wantToReplacePreviouslyAssignedTagWithNew: '¿Está seguro de que desea reemplazar las etiquetas asignadas previamente con la nueva etiqueta elegida para todos los cables seleccionados?',
      deleteLeads: 'Eliminar los clientes potenciales',
      wantToDeleteSelectedLeads: '¿Estás seguro de que quieres eliminar todos los clientes potenciales seleccionados?',
      exportData: 'Exportar datos',
      exportDataBasedOn: 'Exportar datos basados ​​en el',
      assignSegment: 'Grupo de asignación',
      wantToAssignSegmentsToSelectedLeads: '¿Estás seguro de que quieres asignar Grupo a todos los cables seleccionados?',
      yesGoAhead: '¡Sí adelante!',
      export: 'Exportar',
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Filtrar datos basados ​​en sus preferencias como etiquetas, bot, estado, etc.',
      markLeadAsClosed: 'Marque el usuario/plomo como cerrado',
      assignAgentToLead: 'Asignar agente al usuario',
      assignTagToUser: 'Asignar etiqueta al usuario',
      exportData: 'Exportar datos',
      importContactsForWhatsappBot: 'Importar contactos para WhatsApp Bot',
      addWhatsappContact: 'Agregar contactos de whatsapp',
      assignSegmentToUser: 'Asignar Grupo al usuario',
      deleteUsers: 'Eliminar usuarios',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Escriba el nombre, el correo electrónico o el teléfono, y presione Entrar',
      searchAgentByName: 'Agente de búsqueda por nombre',
      searchOrAddTag: 'Buscar o agregar una etiqueta',
      searchSegmentByName: 'Grupo de búsqueda por nombre',
    },
    filterContainer: {
      filterOn: 'Filtrar',
      filterBy: 'Filtrado por',
      startFrom: 'Empezar desde',
      tillDate: 'Hasta la fecha',
      offlineOrOnline: 'Fuera de linea en linea',
      liveChat: 'Chat en vivo',
      userInteractedWithBot: 'El usuario interactúa con BOT',
      discard: 'Desechar',
      applyFilter: 'Aplicar filtro',
      bots: 'Bots',
      tags: 'Etiquetas',
      status: 'Estado',
      assignedTo: 'Asignado a',
      lastMessageBy: 'Último mensaje por',
      onlineOrOffline: 'Conectado desconectado',
      userInteracted: 'Interactuado por el usuario',
      segments: 'Grupos',
      lastSeenDate: 'Última fecha vista',
      onboardingDate: 'Fecha de incorporación',
      createTags: 'Crear etiquetas',
      createTagsToSortAndManageLeads: 'Crear etiquetas para clasificar y administrar sus clientes potenciales fácilmente',
      typeYourTagHere: 'Escriba su etiqueta aquí',
      createSegment: 'Crear Grupo',
      manageSegmentForMarketingAutomation: 'Administre sus Grupos para la automatización de marketing',
      typeYourSegmentHere: 'Escriba su Grupo aquí',
      placeholders: {
        searchFilter: 'Filtro de búsqueda',
        searchByBotName: 'Buscar por nombre de bot',
        searchOrAddTag: 'Buscar o agregar una etiqueta',
        searchByAgentName: 'Buscar por nombre del agente',
        searchBySegments: 'Buscar por Grupos',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Rango de fechas que desea finalizar la selección en',
        dateRangeYouWishToStartSelectionFrom: 'Rango de fechas que desea comenzar la selección desde',
        filterThoseThatAreOfflineOrOnline: 'Filtrar a los usuarios fuera de línea/en línea',
        filterThoseThatAreCurrentlyLiveChatting: 'Filtrar a los usuarios que actualmente están chatando en vivo',
        filterThoseThatInteractedWithBot: 'Filtrar a los usuarios que interactuaron con el bot',
      },
      indexMergeDatePicker: {
        selectStartDate: 'Seleccione la fecha de inicio',
        selectEndDate: 'Seleccionar fecha de finalización',
      }
    }
  },
  leads: {
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    visitorName: 'Nombre del visitante',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    assignedTo: 'Asignado a',
    status: 'Estado',
    tag: 'Etiqueta',
    action: 'Acción',
    discard: 'Desechar',
    addTag: 'Añadir etiqueta',
    loadMore: 'Carga más',
    nA: 'N / A',
    deleteUsers: 'Borrar usuario',
    areYouSureToDeleteLead: '¿Estás seguro de que quieres eliminar este liderazgo?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Todavía no tienes chats',
      allOfYourChatsAppearHere: 'Todos tus chats aparecerán aquí',
    },
    placeholders: {
      searchAgentByName: 'Agente de búsqueda por nombre',
      searchStatusByName: 'Estado de búsqueda por nombre',
      searchOrAddTag: 'Buscar o agregar una etiqueta',
    }
  },
  messages: {
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    editChatFlow: 'Editar el flujo de chat',
    connect: 'Conectar',
    chat: {
      photo: 'Foto',
      video: 'Video',
      document: 'Documento',
      location: 'Ubicación',
      assignedTo: 'Asignado a',
      selectBot: 'Seleccionar bot',
      discard: 'Desechar',
      addTag: 'Añadir etiqueta',
      yourBrowserDoesNotSupportVideoTag: 'Su navegador no soporta la etiqueta de vídeo.',
      userHasSharedADocument: 'El usuario ha compartido un documento.',
      clickHereToDownload: 'Haga click aquí para descargar',
      thisStoryIsNoLongerAvailable: 'Esta historia ya no está disponible',
      yourBrowserDoesNotSupportAudioElement: 'Su navegador no admite el elemento de audio.',
      thisMessageIsNoLongerAvailable: 'Este mensaje ya no está disponible porque el remitente no lo ha intentado',
      userHasSharedHisLocation: 'El usuario ha compartido su ubicación. Haga clic aquí para ver',
      sms: 'SMS',
      failed: 'Fallido',
      a: 'A',
      i: 'I',
      r: 'Riñonal',
      m: 'METRO',
      conversationsSelected: 'conversaciones seleccionadas',
      nA: 'N / A',
      assignTagsCloseOrBulkReply: 'Asignar etiquetas, respuesta cercana o masiva a todos de una vez',
      appTooltip: {
        assignAgent: 'Asignar agente',
        selectBot: 'Seleccionar bot',
        assignTagsToManageLeads: 'Asigne etiquetas para administrar clientes potenciales y conversaciones',
      },
      placeholders: {
        searchAgentByName: 'Agente de búsqueda por nombre',
        searchBotByName: 'Bot de búsqueda por nombre',
        searchOrAddTag: 'Buscar o agregar una etiqueta',
        searchStatusByName: 'Estado de búsqueda por nombre',
      }
    },
    profile: {
      userDetails: 'Detalles de usuario',
      orderDetails: 'Detalles del pedido',
      orderedOn: 'Ordenado en',
      showMore: 'Mostrar más',
      showLess: 'Mostrar menos',
      subTotal: 'Subtotal',
      nA: 'N / A',
      addAWhatsappNumber: 'Agregar número de whatsapp',
      addTelegramUserName: 'Agregar nombre de usuario Telegram',
      addInstagramProfileId: 'Agregar ID de perfil de Instagram',
      addFacebookProfileId: 'Agregar ID de perfil de Facebook',
      add: 'Agregar',
      deviceProperties: 'Propiedades del dispositivo',
      privateNotes: 'Notas privadas',
      urlsVisited: 'URL visitadas',
      source: 'Fuente -',
      sourceNa: 'Fuente - Na',
      medium: 'Medio -',
      mediumNa: 'Medio - Na',
      campaign: 'Campaña -',
      campaignNa: 'Campaña - NA',
      attributeKey: 'Clave de atributo',
      attributeName: 'Nombre del Atributo',
      discard: 'Desechar',
      addAttribute: 'Agregar atributo',
      editAttribute: 'Editar atributo',
      deleteAttribute: 'Eliminar atributo',
      headsUp: 'Aviso',
      areYouSureToDeleteAttribute: '¿Estás seguro de que quieres eliminar este atributo?',
      okay: 'Bueno',
      dataAddedToLead: 'Los atributos asignados a sus APIs o flujos de bot no se actualizarán. Por favor, actualice las APIs o flujos de bot en consecuencia.',
      assignedOnly: 'Solo asignado',
      allUsers: 'Todos los usuarios',
      save: 'Guardar',
      manageCustomAttributes: 'Administrar atributos personalizados',
      placeholders: {
        title: 'Título',
        egServices: 'P.ej. Servicios',
        egRequestedServices: 'P.ej. Servicios solicitados',
        enterYourNotesHere: 'Ingresa tus notas aquí ...',
      },
      bpDropDown: {
        selectBot: 'Seleccionar bot',
        selectFlow: 'Seleccionar flujo',
        selectQuestion: 'Seleccione la pregunta*',
      }
    },
    sendMessage: {
      send: 'Enviar',
      sendToAllChannels: 'Enviar a todos los canales',
      email: 'Correo electrónico',
      whatsapp: 'Whatsapp',
      telegram: 'Telegrama',
      facebook: 'Facebook',
      sendSms: 'Enviar SMS',
      addNew: 'Agregar nuevo',
      addNewQuickReply: 'Agregar nueva respuesta rápida',
      discard: 'Desechar',
      fromComputer: 'DE COMPUTADORA',
      add: 'Agregar',
      dragAndDropHereOr: 'Arrastrar y soltar aquí o',
      browseFiles: 'Búsqueda de archivos',
      caption: 'Subtítulo',
      templates: 'Plantillas',
      addHeaderMedia: 'Agregar medios de encabezado',
      selectFile: 'Seleccione Archivo',
      previousConversationClosed: '¡Conversación anterior cerrada!',
      sendTemplateMessage: 'Envíe un mensaje de plantilla para iniciar la conversación nuevamente.',
      selectTemplate: 'Seleccionar plantilla',
      conversationLocked: 'Conversación bloqueada!',
      demoBotOnlyAllowConversations: 'Demo Bot solo permite una conversación con el número que ha verificado anteriormente.',
      placeholders: {
        searchQuickReplyByTitle: 'Busque respuestas rápidas por título',
        addTitle: 'Añadir título',
        addYourRepliesHere: 'Agregue sus respuestas aquí ...',
        addCaption: 'Añadir un subtitulo',
        enterValueForThisVariable: 'Ingrese el valor para esta variable',
      },
      modal: {
        sendBulkMessages: 'Enviar mensajes a granel',
        send: 'Enviar',
        description: 'Su mensaje será desinfectado y enviado en función del tipo de plataforma a la que pertenece un usuario. - Los usuarios del sitio web solo admiten mensajes basados ​​en textos: los contactos de WhatsApp (no suscritos) solo pueden recibir mensajes de plantilla',
      },
      noDataFound: {
        oops: '¡Ups!',
        currentlyDoesNotSupportBulkMessages: 'Actualmente no admitimos mensajes a granel para bots de WhatsApp. Utilice la función de transmisión',
        quickReplies: 'Respuestas rápidas',
        noQuickRepliesAddedYet: '¡No se agregaron respuestas rápidas!',
      }
    },
    userList: {
      selectAll: 'Seleccionar todo',
      nA: 'N / A',
      loadMore: 'Carga más',
    }
  },
  segments: {
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    manageSegment: 'Administrar Grupo',
    manageYourSegmentsForMarketingAutomation: 'Administre sus Grupos para la automatización de marketing',
    createNewSegment: 'Crear un nuevo Grupo',
    segmentName: 'Nombre de Grupo',
    totalContacts: 'Contactos totales',
    action: 'Acción',
    createSegmentButton: 'Crear Grupo',
    deleteSegments: 'Eliminar Grupos',
    areYouSureToDeleteSegment: '¿Estás seguro de que quieres eliminar este Grupo?',
    placeholders: {
      searchHere: 'Busca aquí',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'No tienes ningún Grupo creado todavía',
      allYourSegmentsAppearHere: 'Todos tus Grupos aparecerán aquí',
    },
    modal: {
      exportData: 'Exportar datos',
      exportDataBasedOnAppliedFilters: 'Exportar datos basados ​​en los filtros aplicados',
      export: 'Exportar',
    },
    appTooltip: {
      deleteSegments: 'Eliminar Grupos',
      editSegment: 'Editar Grupo',
      downloadSegment: 'Descargar Grupo',
      deleteSegment: 'Eliminar Grupo',
    },
    createSegment: {
      createSegment: 'Crear Grupo',
      manageYourSegmentsForMarketingAutomation: 'Administre sus Grupos para la automatización de marketing',
      segmentName: 'Nombre de Grupo',
      segmentNameIsRequired: 'Se requiere el nombre del Grupo',
      selectContactsFilterForSegment: 'Seleccione Filtros de contacto para el Grupo',
      filterYourContacts: 'Filtrar sus contactos',
      removeAllFilters: 'Eliminar todos los filtros',
      addFilters: 'Agregar filtros',
      discard: 'Desechar',
      filterSelected: 'Filtros seleccionados',
      placeholders: {
        enterSegmentName: 'Ingrese el nombre del Grupo',
      }
    }
  },
  orders: {
    visitorName: 'Nombre del visitante',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    status: 'Estado',
    itemCount: 'Cantidad de artículos',
    total: 'Total',
    tag: 'Etiqueta',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'Todavía no tienes pedidos',
      allOfYourOrdersAppearHere: 'Todos tus pedidos aparecerán aquí'
    },
  }
};
