export const inboxMerged = {
  inboxHeader: 'البريد الوارد',
  chatsHeader: 'الدردشات',
  contactsHeader: 'جهات الاتصال',
  segmentsHeader: 'مجموعة',
  ordersHeader: 'الطلبات',
  exportStatus: 'حالة التصدير',
  tasksExport: 'تصدير المهام',
  download: 'تحميل',
  ourAllNewOmniChannelInbox: 'البريد الوارد متعدد القنوات الجديد بالكامل!',
  allYourMessagesContactsAndLeads: 'جميع رسائلك وجهات الاتصال والعملاء الجدد الآن في مكان واحد. جاهزة للفرز والتجزئة لتعزيز نمو أعمالك!',
  close: 'إغلاق',
  takeALook: 'تفقد',
  inProgress: 'قيد التقدم',
  appTooltip: {
    manageAllYourLeadsHere: 'إدارة جميع عملائك/جهات الاتصال هنا',
    segmentYourUserLeads: 'قم بتجميع عملائك/مستخدمي الروبوتات بناءً على التركيبة السكانية والاهتمامات. سيتم استخدامها لإنشاء عمليات البث',
    manageAllBotsConversationsHere: 'إدارة جميع محادثات البوت هنا',
    manageAllYourOrdersHere: 'إدارة جميع طلباتك هنا',
  },
  contacts: {
    seeHowToUse: 'شاهد كيفية الاستخدام',
    help: 'مساعدة',
    visitorName: 'اسم الزائر',
    email: 'البريد الإلكتروني',
    phoneNumber: 'رقم الهاتف',
    segment: 'مجموعة',
    status: 'حالة',
    tag: 'وسم',
    action: 'إجراء',
    discard: 'تجاهل',
    addTag: 'إضافة وسم',
    loadMore: 'تحميل المزيد',
    nA: 'NA',
    assign: 'تعيين',
    deleteUser: 'حذف المستخدم',
    areYouSureToDeleteLead: 'هل أنت متأكد أنك تريد حذف هذا العميل؟',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'ليس لديك أي جهات اتصال بعد!',
      allOfYourContactsAppearHere: 'ستظهر جميع جهات الاتصال هنا'
    },
    placeholders: {
      searchSegmentByName: 'مجموعة البحث بالاسم',
      searchOrAddTag: 'البحث أو إضافة وسم',
    },
    addContacts: {
      name: 'الاسم',
      email: 'البريد الإلكتروني',
      enterAValidEmail: 'ادخل بريدًا إلكترونيًا صالحًا',
      phoneNumber: 'رقم الهاتف',
      cancel: 'إلغاء',
      addContact: 'إضافة جهة اتصال',
      placeholders: {
        enterEmailAddress: 'أدخل عنوان البريد الإلكتروني',
        enterUserrName: 'أدخل اسم المستخدم',
        searchCountry: 'البحث عن البلد',
        enterPhoneNumber: 'أدخل رقم الهاتف',
      }
    }
  },
  inboxFilter: {
    applied: 'تم التطبيق',
    assignTags: 'تعيين الوسوم',
    discard: 'تجاهل',
    addTag: 'إضافة وسم',
    assignSegments: 'تعيين مجموعة',
    filterYourLeads: 'فلترة عملائك',
    filters: 'مرشحات',
    filter: 'مرشح',
    resetAllFilters: 'إعادة تعيين جميع المرشحات',
    importContacts: 'استيراد جهات الاتصال',
    beforeYouStart: 'قبل أن تبدأ في التحميل، يرجى التأكد من',
    point1: '1. تحميل ملف الإكسل المثالي.',
    point2: '2. ابدأ في إدخال البيانات الخاصة بك ابتداءً من الصف الثاني فصاعدًا.',
    point3: '3. في كل صف، ستكون هناك بيانات لاتصال واحدة.',
    point4: '4. تأكد من أن جميع أرقام الهواتف فريدة.',
    point5: '5. حد تحميل الحجم الأقصى للملف هو 20 ميجا بايت',
    selectFile: 'اختيار ملف',
    clickHere: 'انقر هنا',
    toDownloadSampleFile: 'لتنزيل ملف العينة',
    modal: {
      closeLeads: 'إغلاق العملاء',
      areYouSureToselectAllLeads: 'هل أنت متأكد أنك تريد إغلاق جميع العملاء المحددين؟',
      assignAgent: 'تعيين وكيل',
      toAssignAgentToAllLeads: 'هل أنت متأكد أنك تريد تعيين وكيل لجميع العملاء المحددين؟',
      assignTag: 'تعيين وسم',
      wantToReplacePreviouslyAssignedTagWithNew: 'هل أنت متأكد أنك تريد استبدال الوسوم المعينة سابقًا بالوسم الجديد المختار لجميع العملاء المحددين؟',
      deleteLeads: 'حذف العملاء',
      wantToDeleteSelectedLeads: 'هل أنت متأكد أنك تريد حذف جميع العملاء المحددين؟',
      exportData: 'تصدير البيانات',
      exportDataBasedOn: 'تصدير البيانات استنادًا إلى',
      assignSegment: 'تعيين مجموعة',
      wantToAssignSegmentsToSelectedLeads: 'هل أنت متأكد أنك تريد تعيين مجموعة لجميع العملاء المحددين؟',
      yesGoAhead: 'نعم، قم بذلك!',
      export: 'تصدير'
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'تصفية البيانات استنادًا إلى تفضيلاتك مثل الوسوم والبوت والحالة، وما إلى ذلك.',
      markLeadAsClosed: 'وسم العميل/الزبون كمغلق',
      assignAgentToLead: 'تعيين وكيل للعميل',
      assignTagToUser: 'تعيين وسم للمستخدم',
      exportData: 'تصدير البيانات',
      importContactsForWhatsappBot: 'استيراد جهات الاتصال لبوت واتساب',
      addWhatsappContact: 'إضافة جهات اتصال واتساب',
      assignSegmentToUser: 'تعيين مجموعة للمستخدم',
      deleteUsers: 'حذف المستخدمين',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'اكتب الاسم أو البريد الإلكتروني أو الهاتف واضغط على "إدخال"',
      searchAgentByName: 'البحث عن الوكيل بالاسم',
      searchOrAddTag: 'البحث أو إضافة وسم',
      searchSegmentByName: 'مجموعة البحث بالاسم',
    },
    filterContainer: {
      filterOn: 'تصفية على أساس',
      filterBy: 'تصفية بواسطة',
      startFrom: 'ابدأ من',
      tillDate: 'حتى تاريخ',
      offlineOrOnline: 'غير متصل / متصل',
      liveChat: 'الدردشة المباشرة',
      userInteractedWithBot: 'المستخدم تفاعل مع البوت',
      discard: 'تجاهل',
      applyFilter: 'تطبيق التصفية',
      bots: 'البوتات',
      tags: 'الوسوم',
      status: 'الحالة',
      assignedTo: 'تعيين لـ',
      lastMessageBy: 'آخر رسالة بواسطة',
      onlineOrOffline: 'متصل / غير متصل',
      userInteracted: 'تفاعل المستخدم',
      segments: 'مجموعة',
      lastSeenDate: 'تاريخ آخر ظهور',
      onboardingDate: 'تاريخ التسجيل',
      createTags: 'إنشاء وسوم',
      createTagsToSortAndManageLeads: 'إنشاء وسوم لفرز وإدارة عملائك بسهولة',
      typeYourTagHere: 'اكتب وسمك هنا',
      createSegment: 'إنشاء مجموعة',
      manageSegmentForMarketingAutomation: 'إدارة مجموعة الخاصة بك لأتمتة التسويق',
      typeYourSegmentHere: '"اكتب مجموعتك هنا"',
      placeholders: {
        searchFilter: 'بحث في التصفية',
        searchByBotName: 'البحث بواسطة اسم البوت',
        searchOrAddTag: 'البحث أو إضافة وسم',
        searchByAgentName: 'البحث بواسطة اسم الوكيل',
        searchBySegments: 'البحث بواسطة مجموعة',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'نطاق التاريخ الذي ترغب في إنهاء التحديد فيه',
        dateRangeYouWishToStartSelectionFrom: 'نطاق التاريخ الذي ترغب في بدء التحديد منه',
        filterThoseThatAreOfflineOrOnline: 'تصفية الأشخاص الذين غير متصلين / متصلين حاليًا',
        filterThoseThatAreCurrentlyLiveChatting: 'تصفية الأشخاص الذين يجرون دردشة مباشرة حاليًا',
        filterThoseThatInteractedWithBot: 'تصفية الأشخاص الذين تفاعلوا مع البوت'
      },
      indexMergeDatePicker: {
        selectStartDate: 'حدد تاريخ البدء',
        selectEndDate: 'حدد تاريخ الانتهاء',
      }
    }
  },
  leads: {
    seeHowToUse: 'انظر كيفية الاستخدام',
    help: 'المساعدة',
    visitorName: 'اسم الزائر',
    email: 'البريد الإلكتروني',
    phoneNumber: 'رقم الهاتف',
    assignedTo: 'تم التعيين لـ',
    status: 'الحالة',
    tag: 'وسم',
    action: 'إجراء',
    discard: 'تجاهل',
    addTag: 'إضافة وسم',
    loadMore: 'تحميل المزيد',
    nA: 'NA',
    deleteUsers: 'حذف المستخدم',
    areYouSureToDeleteLead: 'هل أنت متأكد أنك تريد حذف هذا الزائر؟',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'ليس لديك أي محادثات بعد',
      allOfYourChatsAppearHere: 'ستظهر جميع محادثاتك هنا',
    },
    placeholders: {
      searchAgentByName: 'البحث عن وكيل بالاسم',
      searchStatusByName: 'البحث عن الحالة بالاسم',
      searchOrAddTag: 'البحث أو إضافة وسم',
    }
  },
  messages: {
    seeHowToUse: 'انظر كيفية الاستخدام',
    help: 'المساعدة',
    editChatFlow: 'تحرير تدفق الدردشة',
    connect: 'يتصل',
    chat: {
      photo: 'صورة',
      video: 'فيديو',
      document: 'مستند',
      location: 'الموقع',
      assignedTo: 'تم التعيين لـ',
      selectBot: 'حدد البوت',
      discard: 'تجاهل',
      addTag: 'إضافة وسم',
      yourBrowserDoesNotSupportVideoTag: 'متصفحك لا يدعم عنصر الفيديو.',
      userHasSharedADocument: 'قام المستخدم بمشاركة مستند.',
      clickHereToDownload: 'انقر هنا للتنزيل',
      thisStoryIsNoLongerAvailable: 'هذه القصة غير متاحة بعد الآن',
      yourBrowserDoesNotSupportAudioElement: 'متصفحك لا يدعم عنصر الصوت.',
      thisMessageIsNoLongerAvailable: 'هذه الرسالة غير متاحة بعد الآن لأن المرسل قام بإلغائها',
      userHasSharedHisLocation: 'قام المستخدم بمشاركة موقعه. انقر هنا لعرضه',
      sms: 'رسالة نصية قصيرة',
      failed: 'فشل',
      a: 'A',
      i: 'I',
      r: 'R',
      m: 'M',
      conversationsSelected: 'المحادثات المحددة',
      nA: 'NA',
      assignTagsCloseOrBulkReply: 'قم بتعيين الوسوم أو إغلاق أو الرد الجماعي في خطوة واحدة',
      appTooltip: {
        assignAgent: 'تعيين وكيل',
        selectBot: 'حدد البوت',
        assignTagsToManageLeads: 'قم بتعيين الوسوم لإدارة الزوار والمحادثات',
      },
      placeholders: {
        searchAgentByName: 'البحث عن وكيل بالاسم',
        searchBotByName: 'البحث عن البوت بالاسم',
        searchOrAddTag: 'البحث أو إضافة وسم',
        searchStatusByName: 'البحث عن الحالة بالاسم',
      }
    },
    profile: {
      userDetails: 'تفاصيل المستخدم',
      orderDetails: 'تفاصيل الطلب',
      orderedOn: 'تم الطلب في',
      showMore: 'عرض المزيد',
      showLess: 'عرض أقل',
      subTotal: 'المجموع الفرعي',
      nA: 'NA',
      addAWhatsappNumber: 'إضافة رقم واتساب',
      addTelegramUserName: 'إضافة اسم المستخدم على تليجرام',
      addInstagramProfileId: 'إضافة معرف الحساب على إنستجرام',
      addFacebookProfileId: 'إضافة معرف الصفحة على فيسبوك',
      add: 'إضافة',
      deviceProperties: 'خصائص الجهاز',
      privateNotes: 'ملاحظات خاصة',
      urlsVisited: 'الروابط التي تم زيارتها',
      source: 'Source - ',
      sourceNa: 'Source - NA',
      medium: 'Medium - ',
      mediumNa: 'Medium - NA',
      campaign: 'Campaign - ',
      campaignNa: 'Campaign - NA',
      attributeKey: 'مفتاح السمة',
      attributeName: 'اسم السمة',
      discard: 'تجاهل',
      addAttribute: 'إضافة سمة',
      editAttribute: 'تعديل السمة',
      deleteAttribute: 'حذف السمة',
      headsUp: 'تنبيه',
      areYouSureToDeleteAttribute: 'هل أنت متأكد أنك تريد حذف هذه السمة؟',
      okay: 'موافق',
      dataAddedToLead: 'لن يتم تحديث السمات المعينة لواجهات برمجة التطبيقات (APIs) أو تدفقات البوت. يرجى تحديث واجهات برمجة التطبيقات (APIs) أو تدفقات البوت وفقًا لذلك.',
      assignedOnly: 'مخصص للتعيين فقط',
      allUsers: 'جميع المستخدمين',
      save: 'حفظ',
      manageCustomAttributes: 'إدارة السمات المعينة',
      placeholders: {
        title: 'العنوان',
        egServices: 'مثال: الخدمات',
        egRequestedServices: 'مثال: الخدمات المطلوبة',
        enterYourNotesHere: 'أدخل ملاحظاتك هنا ...'
      },
      bpDropDown: {
        selectBot: 'حدد البوت',
        selectFlow: 'حدد التدفق',
        selectQuestion: 'حدد السؤال*'
      }
    },
    sendMessage: {
      send: 'إرسال',
      sendToAllChannels: 'إرسال إلى جميع القنوات',
      email: 'البريد الإلكتروني',
      whatsapp: 'واتساب',
      telegram: 'تليجرام',
      facebook: 'فيسبوك',
      sendSms: 'إرسال رسالة نصية',
      addNew: 'إضافة جديد',
      addNewQuickReply: 'إضافة رد سريع جديد',
      discard: 'تجاهل',
      fromComputer: 'من الكمبيوتر',
      add: 'إضافة',
      dragAndDropHereOr: 'اسحب وأسقط هنا أو',
      browseFiles: 'تصفح الملفات',
      caption: 'تسمية',
      templates: 'القوالب',
      addHeaderMedia: 'إضافة وسائط رأس',
      selectFile: 'اختر ملف',
      previousConversationClosed: 'تم إغلاق المحادثة السابقة!',
      sendTemplateMessage: 'إرسال رسالة قالب لبدء المحادثة مرة أخرى.',
      selectTemplate: 'حدد القالب',
      conversationLocked: 'المحادثة مغلقة!',
      demoBotOnlyAllowConversations: 'البوت التجريبي يسمح فقط بالمحادثات مع الرقم الذي قمت بالتحقق منه مسبقًا.',
      placeholders: {
        searchQuickReplyByTitle: 'البحث في الردود السريعة حسب العنوان',
        addTitle: 'إضافة عنوان',
        addYourRepliesHere: 'أضف ردودك هنا... ',
        addCaption: 'إضافة تسمية',
        enterValueForThisVariable: 'أدخل قيمة لهذه المتغير',
      },
      modal: {
        sendBulkMessages: 'إرسال رسائل جماعية',
        send: 'إرسال',
        description: 'سيتم تنقية رسالتك وإرسالها استنادًا إلى نوع النظام الأساسي للمستخدم.' +
          '\n - مستخدمو الموقع يدعمون فقط الرسائل النصية' +
          '\n - جهات الاتصال في واتساب (غير المشتركين) يمكنهم فقط استقبال رسائل القوالب'
      },
      noDataFound: {
        oops: 'عذرًا!',
        currentlyDoesNotSupportBulkMessages: 'نحن حاليًا لا ندعم رسائل الجماعية لبوتات واتساب. يرجى استخدام ميزة البث',
        quickReplies: 'ردود سريعة',
        noQuickRepliesAddedYet: 'لم يتم إضافة ردود سريعة بعد!',
      },
    },
    userList: {
      selectAll: 'تحديد الكل',
      nA: 'غير متاح',
      loadMore: 'تحميل المزيد',
    }
  },
  segments: {
    seeHowToUse: 'راجع كيفية الاستخدام',
    help: 'المساعدة',
    manageSegment: 'إدارة مجموعة',
    manageYourSegmentsForMarketingAutomation: 'قم بإدارة أقسامك للتسويق التلقائي',
    createNewSegment: 'إنشاء قسم جديد',
    segmentName: 'اسم مجموعة',
    totalContacts: 'إجمالي الجهات الاتصال',
    action: 'إجراء',
    createSegmentButton: 'إنشاء مجموعة',
    deleteSegments: 'حذف مجموعة',
    areYouSureToDeleteSegment: 'هل أنت متأكد أنك تريد حذف هذا مجموعة؟',
    placeholders: {
      searchHere: 'ابحث هنا',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'ليس لديك أي مجموعة تم إنشاؤها بعد كلها',
      allYourSegmentsAppearHere: 'سيظهر جميع أقسامك هنا'
    },
    modal: {
      exportData: 'تصدير البيانات',
      exportDataBasedOnAppliedFilters: 'تصدير البيانات بناءً على الفلاتر المطبقة',
      export: 'تصدير',
    },
    appTooltip: {
      deleteSegments: 'حذف مجموعة',
      editSegment: 'تحرير مجموعة',
      downloadSegment: 'تنزيل مجموعة',
      deleteSegment: 'حذف مجموعة'
    },
    createSegment: {
      createSegment: 'إنشاء مجموعة',
      manageYourSegmentsForMarketingAutomation: 'قم بإدارة أقسامك للتسويق التلقائي',
      segmentName: 'اسم مجموعة',
      segmentNameIsRequired: 'اسم مجموعة مطلوب',
      selectContactsFilterForSegment: 'حدد عوامل تصفية جهات الاتصال للمجموعة',
      filterYourContacts: 'قم بتصفية جهات الاتصال الخاصة بك',
      removeAllFilters: 'إزالة جميع الفلاتر',
      addFilters: 'إضافة فلاتر',
      discard: 'تجاهل',
      filterSelected: 'الفلاتر المحددة',
      placeholders: {
        enterSegmentName: 'أدخل اسم مجموعة',
      }
    }
  },

  orders: {
    visitorName: 'اسم الزائر',
    email: 'البريد الإلكتروني',
    phoneNumber: 'رقم الهاتف',
    status: 'الحالة',
    itemCount: 'عدد العناصر',
    total: 'الإجمالي',
    tag: 'علامة',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'ليس لديك أي طلبات حتى الآن',
      allOfYourOrdersAppearHere: 'سيظهر جميع طلباتك هنا'
    },
  }
};
