import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Router } from '@angular/router';
import { SharedService } from '../modules/shared/shared.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AccessControlService } from './access-control.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectHandlerService {
  permissions: string[];
  subscribe$: Subscription;
  constructor(
    public networkService: NetworkService,
    private router: Router,
    private sharedService: SharedService,
    private store: Store<any>,
    private aclService: AccessControlService
  ) {
    this.aclService.permissions
      .subscribe((permissions: { fetched: boolean, resources: string[] }) => {
        if (permissions.fetched) {
          this.permissions = permissions.resources;
        }
      });
  }
  redirectTo(type: 'inbox-merged' | 'analytics' | 'access-control' | 'subscription' | 'whitelabel' | 'activities' | 'configurations') {
    switch (type) {
      case 'inbox-merged':
        this.inboxRedirectHandler();
        break;
      case 'access-control':
        this.router.navigate(['/access-control/users']);
        break;
      case 'subscription':
        this.subscriptionRedirectHandler();
        break;
      case 'activities':
        this.agencyActivitiesRedirectHandler();
        break;
      case 'configurations':
        this.agencyConfigurationsRedirectHandler();
        break;
      default:
        this.router.navigate([type]);
        break;
    }
  }

  inboxRedirectHandler() {
    if (this.permissions.includes('chat')) {
      return this.router.navigate(['/inbox-merged/chats']);
    } else if (this.permissions.includes('contact')) {
      return this.router.navigate(['/inbox-merged/contacts']);
    } else if (this.permissions.includes('segment')) {
      return this.router.navigate(['/inbox-merged/segments']);
    } else {
      return this.sharedService.showToast('error', '', 'You do not have access to this page');
    }
  }

  subscriptionRedirectHandler() {

    if (this.permissions.includes('plan')) {
      return this.router.navigate(['/subscription/plans']);
    } else if (this.permissions.includes('transactions')) {
      return this.router.navigate(['/subscription/plans']);
    } else {
      return this.sharedService.showToast('error', '', 'You do not have access to this page');
    }
  }

  agencyActivitiesRedirectHandler() {

    if (this.permissions.includes('upcoming-renewal')) {
      return this.router.navigate(['/agency/activities']);
    } else if (this.permissions.includes('subscription-activity')) {
      return this.router.navigate(['/agency/subscriptions']);
    } else if (this.permissions.includes('downgrade-requests')) {
      return this.router.navigate(['/agency/downgrade-requests']);
    } else {
      return this.sharedService.showToast('error', '', 'You do not have access to this page');
    }
  }

  plansRedirectHandler() {

    if (this.permissions.includes('upcoming-renewal')) {
      return this.router.navigate(['/whitelabel/activities']);
    } else if (this.permissions.includes('agency-charges')) {
      return this.router.navigate(['/whitelabel/charges']);
    } else if (this.permissions.includes('downgrade-requests')) {
      return this.router.navigate(['whitelabel/downgrade-requests']);
    } else {
      return this.sharedService.showToast('error', '', 'You do not have access to this page');
    }
  }

  agencyConfigurationsRedirectHandler() {
    if (this.permissions.includes('email-integration')) {
      return this.router.navigate(['/agency/configurations/email']);
    } else if (this.permissions.includes('razorpay-integration')) {
      return this.router.navigate(['/agency/configurations/razorpay']);
    } else if (this.permissions.includes('stripe-integration')) {
      return this.router.navigate(['agency/configurations/stripe']);
    } else if (this.permissions.includes('invoice-integration')) {
      return this.router.navigate(['agency/configurations/invoice-details']);
    } else {
      return this.sharedService.showToast('error', '', 'You do not have access to this page');
    }
  }

}
