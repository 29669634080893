export const analytics = {
  botWise: {
    botWiseAnalytics: 'Análise de bots',
    currentBillingCycle: 'Ciclo de cobrança atual',
    currentMonth: 'Mês atual',
    sinceAccountCreation: 'Desde a criação da conta',
    messages: 'Mensagens',
    leads: 'Pistas',
    noDataFound: {
      botwiseAnalytics: 'Análise de bots',
      noBotHasBeenCreatedYet: 'Nenhum bot foi criado ainda!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Resuma dados de mensagens e contatos/leads para bots específicos para o ciclo de cobrança atual, mês atual ou, desde a criação de contas (seja atualizado a cada meia -noite)',
      TOOLTIP_FILTERS: 'Filtrar dados para bot selecionado',
      TOOLTIP_MESSAGES: 'Total de mensagens consumidas por sua conta desde a criação de conta',
      TOOLTIP_LEADS: 'Total de leads capturados por bots para sua conta desde a criação da conta.',
    }
  },
  overview: {
    analytics: 'Análise',
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    overview: 'Visão geral',
    messages: 'Mensagens',
    leads: 'Pistas',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Analytics para todas as suas mensagens e leads de bots. (Dados atualizados a cada meia -noite)',
    }
  },
  summary: {
    analyticsSummary: 'Resumo da análise',
    allBots: 'Todos os bots',
    daily: 'Diário',
    weekly: 'Semanalmente',
    monthly: 'Por mês',
    messages: 'Mensagens',
    leads: 'Pistas',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Resuma dados diários, semanais ou mensais de mensagens e contatos/leads para os bots específicos e todos (sejam atualizados a cada meia -noite)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'Whatsapp Analytics',
      month: 'Mês',
      week: 'Semana',
      today: 'Hoje',
      filters: 'Filtros',
      clearAll: 'Limpar tudo',
      apply: 'Aplicar',
      totalConversations: 'Total de conversas',
      freeConversations: 'Conversas grátis',
      paidConversations: 'Conversas pagas',
      messageSent: 'Mensagem enviada',
      messageDelivered: 'Mensagem enviada',
      bpDropDown: {
        selectBot: 'Selecione Bot',
      },
      indexMergeDatePicker: {
        startDate: 'Data de início',
        endDate: 'Data final',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Análise de conversação',
      month: 'Mês',
      week: 'Semana',
      today: 'Hoje',
      totalConversations: 'Total de conversas',
      paidConversations: 'Conversas pagas',
      freeConversations: 'Conversas grátis',
      indexMergeDatePicker: {
        startDate: 'Data de início',
        endDate: 'Data final',
      },
      bpDropDown: {
        allBots: 'Todos os bots',
      }
    },
    messageAnalytics: {
      whatsappMessages: 'Mensagens do WhatsApp',
      sent: 'Enviado',
      delivered: 'Entregue',
      indexMergeDatePicker: {
        startDate: 'Data de início',
        endDate: 'Data final',
      },
      bpDropDown: {
        allBots: 'Todos os bots',
      },
      month: 'Mês',
      week: 'Semana',
      today: 'Hoje',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'Mensagens do WhatsApp por status',
      sent: 'Enviado',
      delivered: 'Entregue',
      indexMergeDatePicker: {
        startDate: 'Data de início',
        endDate: 'Data final',
      },
      bpDropDown: {
        allBots: 'Todos os bots',
      },
      month: 'Mês',
      week: 'Semana',
      today: 'Hoje',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Todos os assinantes',
      whatsappSubscribers: 'Assinantes do WhatsApp',
    }
  }
};
