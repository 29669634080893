export const whitelabel = {
  plans: 'الخطط',
  managePlan: 'إدارة الخطط',
  whitelabelCharges: 'رسوم Whitelabel',
  chargesOrTaxesConfiguration: 'تكوين الرسوم / الضرائب',
  websitePlans: 'خطط الموقع الإلكتروني',
  whatsappPlansHeader: 'خطط واتساب',
  addOnPlans: {
    createAddOnPlans: 'إنشاء خطط إضافة',
    checkoutYourCurrentMessage: 'تحقق من خطط الرسائل الإضافية الحالية الخاصة بك والخطط الجديدة لعملائك',
    choosePlanValidity: 'اختر صلاحية الخطة',
    chooseValidity: 'اختر الصلاحية',
    oneYear: 'سنة واحدة',
    billingCycle: 'دورة الفوترة',
    numberOfMessages: 'عدد الرسائل',
    pleaseEnterNumberOfMessages: 'الرجاء إدخال عدد الرسائل',
    setCurrency: 'تعيين العملة',
    select: 'اختر',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'مسح الكل',
    apply: 'تطبيق',
    currency: 'عملة',
    amount: 'المبلغ',
    validity: 'الصلاحية',
    upgradePlan: 'تحديث الخطة',
    addPlan: 'إضافة خطة',
    serialNumber: 'الرقم التسلسلي',
    messages: 'الرسائل',
    active: 'نشط',
    priceDetails: 'تفاصيل السعر',
    action: 'العملية',
    addOnChanges: 'رسوم الإضافات: ',
    perYear: 'سنوياً',
    taxes: 'الضرائب',
    placeholders: {
      enterNumberOfMesasges: 'أدخل عدد الرسائل',
      enterAmount: 'أدخل المبلغ',
      enterValidity: 'أدخل الصلاحية',
      searchByMessages: 'البحث حسب الرسائل'
    },
    modal: {
      deleteAddOnsPlan: 'حذف خطط الإضافات',
      wantToDeleteThisAddOnsPlan: 'هل أنت متأكد أنك تريد حذف خطط الإضافات هذه؟',
    }
  },
  charges: {
    addWhitelabelCharges: 'إضافة رسوم العلامة البيضاء',
    checkoutExistingCharges: 'تحقق من الرسوم الحالية على طرق الدفع المختلفة وإضافة رسوم العلامة البيضاء الجديدة',
    currency: 'العملة',
    chooseCurrency: 'اختر العملة',
    amountPerBotPerMonth: 'المبلغ لكل روبوت شهريًا',
    cancel: 'إلغاء',
    upgradeChanges: 'تحديث الرسوم',
    addCharges: 'إضافة رسوم',
    serialNumber: 'الرقم التسلسلي',
    action: 'العملية',
    taxes: 'الضرائب',
    placeholders: {
      enterAmountBotPerMonth: 'أدخل المبلغ لكل روبوت شهريًا',
      searchByCurrency: 'البحث حسب العملة',
    },
    modal: {
      deleteCharges: 'حذف الرسوم',
      wantToDeleteThisCharge: 'هل أنت متأكد أنك تريد حذف هذه الرسوم؟',
    }
  },
  planManagement: {
    createNewPlans: 'إنشاء خطط جديدة',
    checkoutYourCurrentPlan: 'تحقق من خططك الحالية وأضف خططًا جديدة لعملائك',
    choosePlan: 'اختر الخطة',
    selectPlan: 'اختر الخطة',
    baby: 'Baby',
    king: 'King',
    displayName: 'اسم العرض',
    messageLimit: 'حد الرسائل',
    enterAValidEmail: 'أدخل بريدًا إلكترونيًا صالحًا',
    setCurrency: 'تعيين العملة',
    select: 'اختر',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'مسح الكل',
    apply: 'تطبيق',
    perMonth: '/شهر',
    perYear: '/عام',
    cancel: 'إلغاء',
    upgradePlan: 'تحديث الخطة',
    addPlan: 'إضافة خطة',
    serialNumber: 'الرقم التسلسلي',
    plan: ' خطة',
    messages: 'رسائل',
    pricing: 'التسعير',
    actions: 'إجراءات',
    perMonthText: 'شهريًا',
    perYearText: 'سنويًا',
    placeholders: {
      enterDisplayName: 'أدخل اسم العرض',
      enterMessageLimit: 'أدخل حد الرسائل',
      searchByPlanName: 'البحث حسب اسم الخطة أو الرسائل'
    },
    modal: {
      deletePlan: 'حذف الخطة',
      wantToDeleteThisBabyPlan: 'سيكون لحذف خطة الرضع تأثير على جميع العملاء. هل أنت متأكد من أنك تريد حذف هذه الخطة؟',
      wantToDeleteThisPlan: 'هل أنت متأكد أنك تريد حذف هذه الخطة؟'
    },
    appTooltip: {
      chooseYourPlanType: 'اختر نوع الخطة الخاصة بك',
      pleaseInputYourPlanDisplayName: 'يرجى إدخال اسم العرض الخاص بخطتك',
      typeMessageLimitForCustomer: 'اكتب حد الرسائل للعميل',
      selectYourPreferredCurrencies: 'حدد عملاتك المفضلة',
    }
  },
  taxesConfiguration: {
    addTaxes: 'إضافة الضرائب',
    checkoutExistingTaxes: 'تحقق من الضرائب الحالية على طرق الدفع المختلفة والضرائب الجديدة',
    taxName: 'اسم الضريبة',
    applicableOn: 'قابل للتطبيق على',
    choosePlans: 'اختر الخطط',
    chooseCurrency: 'اختر العملة',
    percentage: 'النسبة المئوية',
    cancel: 'إلغاء',
    serialNumber: 'الرقم التسلسلي',
    name: 'الاسم',
    currency: 'العملة',
    updateTaxes: 'تحديث الضرائب',
    actions: 'إجراءات',
    modal: {
      deleteTaxes: 'حذف الضرائب',
      wantToDeleteThisTax: 'هل أنت متأكد أنك تريد حذف هذه الضرائب؟'
    },
    placeholders: {
      taxName: 'اسم الضريبة',
      taxPercentage: 'نسبة الضريبة',
      searchByCurrency: 'البحث حسب العملة',
    }
  },
  whatsappPlans: {
    createNewPlans: 'إنشاء خطط جديدة',
    checkoutYourCurrentAndNewPlans: 'تحقق من الخطط الحالية الخاصة بك وإضافة خطط جديدة لعملائك',
    choosePlan: 'اختر الخطة',
    selectPlan: 'حدد الخطة',
    baby: 'Baby',
    king: 'King',
    displayName: 'اسم العرض',
    messageLimit: 'حد الرسائل',
    enterAValidMessageLimit: 'أدخل حد رسائل صالح',
    setCurrency: 'تعيين العملة',
    select: 'اختر',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'مسح الكل',
    apply: 'تطبيق',
    perMonth: '/شهر',
    perYear: '/عام',
    cancel: 'إلغاء',
    addPlan: 'إضافة خطة',
    serialNumber: 'الرقم التسلسلي',
    plan: 'الخطة',
    messages: 'محادثات',
    pricing: 'التسعير',
    actions: 'إجراءات',
    perMonthText: 'شهريًا',
    perYearText: 'سنويًا',
    updatePlan: 'تحديث الخطة',
    placeholders: {
      enterDisplayName: 'أدخل اسم العرض',
      enterMessageLimit: 'أدخل حد الرسائل',
      searchByPlanName: 'البحث بواسطة اسم الخطة',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'اكتب حد الدردشة للعميل',
      chooseYourPlanType: 'اختر نوع الخطة الخاصة بك',
      selectYourPreferredCurrencies: 'حدد عملاتك المفضلة',
      pleaseInputYourPlanDisplayName: 'يرجى إدخال اسم العرض الخاص بخطتك',
    },
    modal: {
      deletePlan: 'حذف الخطة',
      wantToDeleteThisBabyPlan: 'سيكون لحذف خطة الرضع تأثير على جميع العملاء. هل أنت متأكد من أنك تريد حذف هذه الخطة؟',
      areYouSureYouWantToDeleteThisPlan: 'هل أنت متأكد أنك تريد حذف هذه الخطة؟'
    }
  }
};

