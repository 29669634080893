import { Component, OnInit, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { LanguageService } from 'src/appv2/services/language.service';
import { UploadService } from 'src/appv2/services/upload.service';

@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss']
})
export class ProfileIconComponent implements OnInit, AfterViewInit {
  avatarsUrl = [
    '/assets/images/new-images/upload-full.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-28.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-27.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-26.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-1.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-2.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-3.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-4.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-5.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-6.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-7.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-8.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-9.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-10.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-11.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-12.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-13.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-14.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-15.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-16.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-17.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-18.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-19.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-20.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-21.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-22.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-23.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-24.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-25.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-29.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-30.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-31.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-32.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-33.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-34.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-35.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-36.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-37.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-38.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-39.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-40.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-41.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-42.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-43.svg',
    'https://cdn.botpenguin.com/assets/avatars/profile-44.svg',
    'https://cdn.botpenguin.com/assets/avatars/bp-avatar-28.svg',
  ];
  shiftedAvatars = [];
  selectedAvatar = 'https://cdn.botpenguin.com/assets/avatars/profile-28.svg';
  @Output() finalSelectedImage = new EventEmitter<string>();
  @Input() initialSelectedImage = '';
  @Output() iconsScroll = new EventEmitter<Event>();
  staticText: any;


  constructor(
    private uploadService: UploadService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.profileIcon;
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.avatarsUrl = this.avatarsUrl.filter(url => url !== (this.initialSelectedImage || ''));
      if (this.initialSelectedImage) {
        this.avatarsUrl.splice(1, 0, this.initialSelectedImage);
      }
    }, 150);
  }

  arrayRotate(reverse) {
    if (!reverse) {
      if (this.avatarsUrl.length > 4) {
        this.shiftedAvatars.push(this.avatarsUrl.shift());
      }
    } else if (this.shiftedAvatars.length) {
      {
        this.avatarsUrl.unshift(this.shiftedAvatars.pop());
      }
    }
  }

  // Opens folder to choose image to upload
  showFolder() {
    const elem = document.getElementById('upload-input');
    elem.click();
  }

  async uploadImage(event) {
    await this.uploadService.uploadImage(event).then((response: any) => {
      setTimeout(() => {
        this.finalSelectedImage.emit(response);
        this.avatarsUrl.splice(1, 0, response);
        this.initialSelectedImage = response;
      }, 2000);

    });
  }

  chooseAvatar(value) {
    this.finalSelectedImage.emit(value);

  }

}
