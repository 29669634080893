import { Component, Input, OnInit, OnDestroy} from '@angular/core';
import { InboxService } from 'src/appv2/services/inbox.service';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-create-new',
  templateUrl: './create-new.component.html',
  styleUrls: ['./create-new.component.scss']
})
export class CreateNewComponent implements OnInit {
  staticText: any;
  @Input() templateData = this.initializeData();
  inputName: string;

  constructor(
    private inboxService: InboxService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.createNew;
   }

  ngOnInit(): void {
  }

  initializeData() {
    return {
      heading: '',
      description: '',
      placeholder: '',
      type: ''
    };
  }

  continueBtn() {
    this.inboxService.isCreateNew.next({ name: this.inputName, type: this.templateData.type });
  }

}
