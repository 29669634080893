export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'חיפוש'
    }
  },
  bpTable: {
    loadMore: 'טען עוד',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'חיפוש מדינה'
    }
  },
  createNew: {
    continue: 'המשך',
  },
  datePicker: {
    chooseADate: 'בחר תאריך',
  },
  dateRangePicker: {
    choosePeriod: 'בחר תקופה',
    today: 'היום',
    lastWeek: 'שבוע שעבר',
    lastMonth: 'חודש שעבר'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'אנא הזן את המספר שברצונך לשנותו לצורכי בדיקה',
    resendOtp: 'שלח שוב קוד',
    enterOtp: 'הזן קוד',
    verifyOtp: 'אמת קוד',
    sendOtp: 'שלח קוד',
    cancel: 'ביטול',
    placeholders: {
      phone: 'טלפון*',
      enterOtp: 'הזן קוד*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'אנא בחר את הסיבה שלך',
    cancel: 'ביטול',
    submit: 'שלח!',
    giveFeedback: 'תן משוב',
    ratings: 'דירוגים',
    reasonBehindYourDecision: 'הסיבה להחלטתך',
    shareYourExperience: 'שתף את החוויה שלך',
    reason1: 'סיבה 1',
    reason2: 'סיבה 2'
  },
  feedbackModal: {
    paymentIsInProgress: 'התשלום מתבצע',
    pleaseDoNotCloseThisWindow: 'אנא אל תסגור את החלון הזה',
    placeholders: {
      name: 'שם',
      email: 'דוא"ל*',
      phone: 'טלפון*',
      pleaseShareYourUseCase: 'אנא שתף את מקרה השימוש שלך'
    }
  },
  layout: {
    sidebar: {
      notifications: 'התראות',
      myAccount: 'החשבון שלי',
      logout: 'התנתק',
      goOnline: 'התחבר לשיחות',
      manage: 'נהל',
      deactivate: 'בטל פעילות',
      activate: 'הפעל',
      delete: 'מחק',
      home: 'דף הבית',
      bots: 'בוטים',
      developer: 'פִּתְחֵן',
      inbox: 'תיק דואר',
      analytics: 'נתונים וניתוחים',
      team: 'צוות',
      subscriptions: 'מינויים',
      more: 'עוד',
      users: 'משתמשים',
      partnerTeam: 'צוות שותפים',
      createNewBot: 'צור בוט חדש',
      dashboard: 'לוח בקרה',
      customers: 'לקוחות',
      plans: 'תוכניות',
      activities: 'פעילויות',
      feedbacks: 'משובים',
      configurations: 'הגדרות',
      copy: 'העתק',
      yourFriendWillMissYou: 'הידיד שלך יתגעגע אליך',
      rateYourBotCreationExperience: 'דרג את חוויית היצירה שלך עם הבוט',
      whatMadeYouDeleteYourBot: 'מה גרם לך למחוק את הבוט שלך?',
      iCreatedByMistake: 'יצרתי בטעות',
      iWantToCreateAnotherBot: 'אני רוצה ליצור בוט נוסף',
      couldNotFindFeaturesThatINeeded: 'לא מצאתי את התכונות שצריך',
      uiWasHardToNavigate: 'ה-UI היה קשה לניווט',
      iDonotWantToTell: 'אני לא רוצה לספר',
      other: 'אחר',
      weWillDoOurBestToMakeAutomationFun: 'אנחנו נעשה את המיטב כדי להפוך את האוטומציה לכיף ולפשוטה לכולם.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'הפעל או השבת בקשות לצ\'אט חי',
        TOOLTIP_NOTIFICATION: 'כל ההתראות',
        TOOLTIP_MY_ACCOUNT: 'הפרופיל שלך ואבטחת הסיסמה',
        TOOLTIP_LOGOUT: 'התנתק מלוח הבקרה'
      },
      modal: {
        logout: 'התנתקות',
        botDeleteFeedbackForm: 'טופס משוב למחיקת בוט',
        areYouSureToLogOut: 'האם אתה בטוח שברצונך להתנתק?',
        yesIWillBeBack: 'כן, אחזור',
        deleteBot: 'מחיקת בוט',
        areYouSureToDeleteBot: 'האם אתה בטוח שברצונך למחוק את הבוט הזה?',
        cancel: 'ביטול',
        saveAndDelete: 'שמור ומחק'
      }
    }
  },
  modal: {
    cancel: 'ביטול',
    yesDelete: 'כן, מחק!',
    continue: 'המשך'
  },
  unsavedChanges: {
    title: 'שינויים לא נשמרו',
    description: 'יתכן שיש שינויים לא נשמרים. האם אתה בטוח שברצונך לצאת?'
  },
  notify: {
    youHave: 'יש לך ',
    new: ' חדשים '
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'אנו ממליצים לבחור בלוגו של החברה שלך. הוסף תמונה מרובעת מסוג PNG או JPG בגודל עד 1 מגה-בייט.'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'אופס! מסלול בייבי מציע רק בוט אחד בחינם. אנא שדרג למסלול המלך כדי ליצור עוד בוטים.',
    noIAmGood: 'לא, תודה',
    iWantToUpgrade: 'אני רוצה לשדרג',
    oops: 'אופס! ',
    planOfferOnlyOneFreeBot: 'מציע רק בוט בחינם. בבקשה לתוכניתכנית',
    planToCreateMoreBots: ' כדי ליצור עוד בוטים.'
  },
  webPreview: {
    preview: ' תצוגה מקדימה',
    botsMessages: 'הודעות הבוט',
    bot: 'בוט ',
    eleven36AM: ' 11:36 בבוקר',
    usersReply: 'תגובת המשתמש',
    landingPageBot: 'בוט עמוד הנחיתה',
    chatbot: 'צ\'אטבוט',
    chat: 'צ\'אט',
    placeholders: {
      typeYourAnswer: 'הזן את תשובתך'
    }
  },
  paymentLink: {
    discardPayment: 'בטל את התשלום',
    completePayment: 'השלם את התשלום',
    importantUpdate: 'עדכון חשוב',
    accountUpdate: 'עדכון חשבון',
    youHave: 'יש לך',
    exceededTheMessagesLimit: 'חרגת מהמגבלת הודעות',
    ofYour: 'של התוכנית שלך ו',
    planAnd: 'תוכנית ו',
    botsAreNoLongerActive: 'הבוטים אינם פעילים יותר',
    onYourWebsite: 'על האתר שלך.',
    youHaveEitherIncompletePaymentOn: 'יש לך או תשלום לא מושלם ב',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'או שלא שדרגת בהצלחה את התוכנית שלך.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'או שלא שדרגת בהצלחה את התוכנית שלך.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'או שלא שדרגת בהצלחה את התוכנית שלך.',
    incompletePaymentMessage: 'יש לך או תשלום לא מושלם באו שלא שדרגת בהצלחה את התוכנית שלך.',
    please: 'אנא',
    complete: 'השלם',
    upgrade: 'שדרג',
    services: 'שירותים',
    yourPlanToContinueServices: 'את התוכנית שלך כדי להמשיך בשירותים',
    yourPaymentToContinue: 'את התשלום שלך כדי להמשיך',
    forAnyQueryPleaseWriteUs: 'לכל שאלה, אנא כתוב לנו ב-',
    close: 'סגור',
    writeOn: 'כתוב ל',
    upgradePlan: 'שדרג תוכנית',
    discardModal: {
      title: 'בטל את התשלום',
      description: 'האם אתה בטוח שברצונך לבטל את התשלום?',
      confirmText: 'כן, בטל את התשלום'
    }
  },
  bpExport: {
    exportStatus: 'סטטוס ייצוא',
    tasksExport: 'ייצוא משימות',
    download: 'הורד',
    inProgress: 'בתהליך',
  }
};
