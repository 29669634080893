export const team = {
  addMember: {
    team: 'Équipe',
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    addAMember: 'Ajouter un membre',
    addYourTeamMembersText: 'Ajoutez les membres de votre équipe en tant qu`agents de support pour discuter en direct avec les utilisateurs de bots.',
    name: 'Nom',
    nameIsRequired: 'Le nom est requis',
    email: 'E-mail',
    enterAValidEmail: 'Entrer un email valide',
    password: 'Mot de passe',
    passwordMustContain: 'Le mot de passe doit contenir',
    atleast6Characters: 'au moins 6 caractères,',
    oneUpperCaseLetter: '1 lettre Majuscule',
    oneSpecialCharacter: '1 caractère spécial *',
    profileIcon: 'Icône de profil',
    whatsappNumber: 'Numéro WhatsApp',
    leadVisibility: 'Visibilité en plomb *',
    addMember: 'Ajouter un membre',
    role: 'Rôle',
    createdOn: 'Créé sur',
    action: 'Action',
    updateMember: 'Mettre à jour le membre',
    add: 'Ajouter',
    cancel: 'Annuler',
    selectVisibilityOption: 'Sélectionnez l`option de visibilité',
    all: 'Tous',
    assigned: 'Attribué',
    nA: 'N / A',
    admin: 'Administrer',
    agent: 'Agent',
    placeholders: {
      enterMemberName: 'Entrez le nom du membre',
      enterMemberEmailAddress: 'Entrez l`adresse e-mail des membres',
      createPasswordForMember: 'Créer un mot de passe pour le membre',
      searchCountry: 'Pays de recherche',
      enterWhatsappNumber: 'Entrez le numéro WhatsApp',
      selectLeadsVisibility: 'Sélectionnez la visibilité des fils',
      searchTeamByNameOrEmail: 'Recherchez membre de l`équipe par son nom ou par e-mail',
    },
    modal: {
      deleteTeamMember: 'Supprimer le membre de l`équipe',
      wantToDeleteThisTeamMember: 'Êtes-vous sûr de vouloir supprimer ce membre de l`équipe?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Cliquez pour ajouter un nouveau membre de l`équipe',
      profileIconOfTeamMember: 'Icône de profil / image du membre de l`équipe',
      nameOfTeamMember: 'Nom du membre de l`équipe',
      emailOfTeamMember: 'Courriel du membre de l`équipe',
      whatsappNumberOfTeamMember: 'Nombre WhatsApp de membres de l`équipe',
      roleOfTeamMemberAsAdminOrAgent: 'Rôle du membre de l`équipe en tant qu`administrateur / agent',
      dateAndTimeOfCreatingTeamMemberRole: 'Date et heure de la création du rôle des membres de l`équipe',
      editOrDeleteTeamMember: 'Modifier / supprimer le membre de l`équipe',
      editTeamMembersDetails: 'Modifier les détails du membre de l`équipe.',
      removeAccessOfTeamMemberByDeletingAccount: 'Supprimer l`accès du membre de l`équipe en supprimant le compte',
    }
  },
  users: {
    manageMembers: 'Gérer les Membres',
    managerMembersWhichCanAccessPanel: 'Gérez votre équipe de partenaires qui peut accéder à votre tableau de bord',
    createNewMember: 'Créer un Nouveau Membre',
    createMember: 'Créer un Membre',
    updateMember: 'Mettre à Jour le Membre',
    deleteTitleMember: 'Supprimer le Membre',
    deleteDescriptionMember: 'Êtes-vous sûr de vouloir supprimer ce membre ?',
    noMemberFound: 'Aucun Membre Trouvé !',
    titleMember: 'Créer un Membre',
    manageUsers: 'Gérer les utilisateurs',
    createNewUser: 'Créer un nouvel utilisateur',
    managerUserWhichCanAccessPanel: 'Gérez les utilisateurs ayant accès à votre panneau',
    phoneNumber: 'Numéro de téléphone',
    discard: 'Annuler',
    createUser: 'Créer un utilisateur',
    updateUser: 'Mettre à jour l\'utilisateur',
    noUserFOund: 'Aucun utilisateur trouvé !',
    title: 'Créer un utilisateur',
    profileIcon: 'Icône de profil*',
    name: 'Nom*',
    email: 'E-mail*',
    password: 'Mot de passe*',
    selectRole: 'Sélectionnez le Rôle*',
    selectARole: 'Sélectionnez un rôle',
    selectLeadVisibility: 'Sélectionnez la Visibilité des Leads*',
    confirmPassword: 'Confirmez le Mot de passe*',
    tableHeadingName: 'Nom',
    tableHeadingEmail: 'Email',
    tableHeadingPhoneNumber: 'Numéro de téléphone',
    tableHeadingRole: 'Rôle',
    tableHeadingStatus: 'Statut',
    tableHeadingAction: 'Action',
    noUserFound: 'Aucun Utilisateur Trouvé !',
    deleteTitle: 'Supprimer les Utilisateurs',
    deleteDescription: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
    placeholders: {
      searchHere: 'Rechercher ici',
      enterName: 'Entrez le nom',
      enterEmail: 'Entrez l\'adresse e-mail',
      searchCountry: 'Rechercher le pays',
      enterPhoneNumber: 'Entrez le numéro de téléphone',
      enterPassword: 'Entrez le mot de passe',
      enterConfirmPassword: 'Confirmez le mot de passe'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Supprimer les utilisateurs',
      TOOLTIP_DELETE_MEMBERS: 'Supprimer les membres'
    }
  },
  roles: {
    manageRoles: 'Gérer les Rôles',
    manageYourRoles: 'Gérez vos rôles.',
    name: 'Nom*',
    description: 'Description',
    discard: 'Annuler',
    createRole: 'Créer un Rôle',
    creteARole: 'Créer un Rôle',
    updateRole: 'Mettre à jour le Rôle',
    assignPermissions: 'Attribuer des Autorisations',
    noUserFound: 'Aucun rôle d\'utilisateur trouvé !',
    tableHeadingName: 'Nom',
    tableHeadingDescription: 'Description',
    tableHeadingStatus: 'Statut',
    tableHeadingAction: 'Action',
    module: 'Module',
    read: 'Lire',
    write: 'Écrire',
    remove: 'Supprimer',
    notes: 'Notes',
    deleteTitle: 'Supprimer le Rôle',
    deleteDescription: 'Attention : Lorsque vous supprimez ce rôle, tous les utilisateurs assignés à ce rôle seront également supprimés. \n Êtes-vous sûr de vouloir supprimer ce rôle ?',
    deleteMemberDescription: 'Attention : En supprimant ce rôle, tous les membres assignés à ce rôle seront également supprimés. Êtes-vous sûr de vouloir supprimer ce rôle ?',
    placeholders: {
      enterRoleName: 'Entrez le nom du rôle',
      searchHere: 'Rechercher ici',
      enterDescriptionForRole: 'Entrez une description pour le rôle'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Sections que vous pouvez autoriser ou bloquer l\'accès des utilisateurs',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Cliquez pour ajouter un nouveau rôle',
    }
  },
  accessControl: {
    users: 'Utilisateurs',
    members: 'Membres',
    partnerTeam: 'Équipe partenaire',
    roles: 'Rôles'
  }
};
