const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Bonjour,',
  greetingHand: '👋',
  upgrade: 'Améliorer',
  integrate: 'Intégrer',
  integrate2: 'Intégrer',
  chatGPT: 'Chatte',
  forYourBusiness: 'pour votre entreprise',
  insights: 'Connaissances',
  message: 'messages',
  addon: 'Ajouter:',
  leads: 'Fils',
  yourBots: 'Vos bots',
  botName: 'Nom de bot',
  platform: 'Plateforme',
  contactOrLeads: 'Contacts / prospects',
  actions: 'Actions',
  edit: 'modifier',
  delete: 'supprimer',
  createA: 'Créer un',
  whatsappChatbot: 'Chatbot whatsapp',
  connect: 'Connecter',
  getStartedWithUs: 'Commencez avec nous 👏',
  howToBuildAGoodChatFlow: ' Comment construire un bon chatflow',
  howToUseBotPenguinInboxAndItsFeatures: ' Comment utiliser la boîte de réception de BotPenguin et ses fonctionnalités',
  botPenguinPricing: ' Prix ​​de BotPenguin: Explorer les plans et les fonctionnalités',
  openHelpDesk: ' Open Help Desk',
  helpAndSupport: 'Aide et soutien',
  extensiveGuides: 'Des guides étendus pour toutes vos requêtes et flux de travail sont disponibles dans notre',
  resourceCentre: 'Centre de ressources.',
  checkoutOurAnswers: 'Vous pouvez consulter nos réponses aux requêtes communes ci-dessous.',
  createANewBot: 'Créer un nouveau bot',
  installYourBot: 'Installez votre bot',
  plansAndPricing: 'Plans et prix',
  searchLanguage: 'Langue de recherche',
  whatsappConversations: 'Conversations WhatsApp',
  diveDeeperIntoBotPenguin: 'Plongez plus profondément dans le botpenguin',
  knowWhereWeAreGoing: 'Sachez où nous allons, les mises à jour récentes et nous aidez à corriger certains bogues.',
  productUpdates: 'Mises à jour du produit',
  requestAFeature: 'Demander une fonctionnalité',
  reportABug: 'Signaler un bug',
  productRoadmap: 'Feuille de route du produit',
  contactsOrLeads: 'Contacts / prospects',
  english: 'Anglais',
  hindi: 'hindi',
  german: 'Allemand',
  french: 'Français',
  portuguese: 'Portugais',
  spanish: 'Espagnol',
  italian: 'italien',
  russian: 'russe',
  pleaseSelectYourReason: 'Veuillez sélectionner votre raison',
  cancel: 'Annuler',
  submit: 'Soumettre!',
  giveFeedback: 'Donner des commentaires',
  ratings: 'Notes',
  reasonBehindYourDecision: 'Raison derrière votre décision',
  shareYourExperience: 'Partagez votre expérience',
  reason1: 'Raison1',
  reason2: 'Raison2',
  appTooltip: {
    HOME_INSIGHT: 'Fournir un aperçu de tous les messages et prospects de vos bot (données mises à jour chaque nuit)',
    HOME_MESSAGES: 'Messages consommés par votre compte pendant ce cycle de facturation. (Les messages de WhatsApp, Facebook et Telegram Bot sont exclus.)',
    HOME_TOTAL_MESSAGES: 'Les messages totaux consommés par votre compte depuis la création de compte (les messages de WhatsApp, Facebook et Telegram Bot sont exclus.)',
    LEADS: 'Diriges capturés par des bots pour votre compte pendant ce cycle de facturation.',
    TOTAL_LEADS: 'Total Contacts / Leads Capturés par bots pour votre compte depuis la création de compte (données mises à jour à minuit).',
    YOUR_BOT: 'Aperçu de tous vos robots et leur utilisation pendant ce cycle de facturation.',
    NAME: 'Nom du bot',
    PLATFORM: 'Plateforme de bot',
    MESSAGES: 'Messages totaux de bot depuis la création du compte',
    CONTACTS: 'Total des contacts / prospects du bot',
    ACTIONS: 'Modifier / supprimer le bot',
    EDIT_ICON: 'Modifier le flux de chat et les paramètres',
    DELETE_ICON: 'Supprimer le bot',
    TOOL_TIP_CLASSIC_VERSION: 'ancienne version de $ {marque} ui',
  },
  modal: {
    deleteBot: 'Supprimer le bot!',
    areYouSure: 'Êtes-vous sûr de vouloir supprimer ce bot?',
    enquiryForm: 'formulaire',
    payNow: 'Payez maintenant!',
    saveAndDelete: 'Enregistrer et supprimer',
    submit: 'Soumettre',
  },
  noDataFound: {
    bots: 'Bots',
    noBotCreatedYet: 'Pas encore de robots créés!',
  },
  yourFriendWillMissYou: 'Votre ami vous manquera',
  rateYourBotCreationExperience: 'Évaluez votre expérience de création de bot',
  whatMadeYouDeleteYourBot: 'Qu`est-ce qui vous a fait supprimer votre bot?',
  iCreatedByMistake: 'J`ai créé par erreur',
  iWantToCreateAnotherBot: 'Je veux créer un autre bot',
  couldNotFindFeaturesThatINeeded: ',',
  uiWasHardToNavigate: 'L`interface utilisateur était difficile à naviguer',
  iDonotWantToTell: 'Je ne veux pas dire',
  other: 'Autre',
  weWillDoOurBestToMakeAutomationFun: 'Nous ferons de notre mieux pour rendre l`automatisation amusante et simple pour tout le monde.',
};
