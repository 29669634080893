import { Directive, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { State } from '../../../ngrx/reducers/index';

@Directive({
  selector: '[appBpIsWhiteLabelAccount]'
})
export class BpIsWhiteLabelAccountDirective {

  constructor(
    public elRef: ElementRef,
    private store: Store<State>,
  ) {
    this.store.select(state => state.profile)
    .subscribe((profile: any) => {
      if (profile.agency && profile.agency.type !== 'WHITE_LABEL') {
        this.elRef.nativeElement.style.display = 'block' ;
      } else {
        this.elRef.nativeElement.style.display = 'none';
      }
    });
  }

}
