export const subscription = {
  subscriptions: 'Assinaturas',
  plan: 'Plano',
  whatsappPlan: 'Plano do WhatsApp',
  addOns: 'Complementos',
  billingDetails: 'Detalhes de cobrança',
  invoices: 'Faturas',
  wa1Reminder: 'WA 1º lembrete',
  wa2Reminder: 'Wa 2nd Lembrete',
  waRenewApi: 'API de renovação de WA',
  waCheckUsageApi: 'WA Verifique a API de uso',
  waResetUsageApi: 'WA Redefinir API de Uso',
  waExceedUsageApi: 'WA excede o uso da API',
  waGetBotsInThisAccount: 'WA Get Bots nesta conta',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Temos os preços mais acessíveis para todas as suas necessidades.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'Plano do WhatsApp',
    TOOLTIP_ADDS_ON_HEADING: 'Compre pacotes adicionais de massagens e bots whitelabel',
    TOOLTIP_ADDS_ON_MESSAGE: 'Se você atingiu seu limite mensal de mensagens, pode comprar pacotes adicionais.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Verifique seus detalhes atuais de assinatura',
    TOOLTIP_HORIZONTAL_BAR: 'Verifique nosso preço mensal ou anual de mensagens de 3k a 500k movendo a barra da esquerda para a direita.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Atualize seus detalhes de cobrança para faturamento.',
    TOOLTIP_INVOICES_HEADING: 'Verifique suas faturas anteriores.',
    TOOLTIP_INVOICES_ACTION: 'Clique para receber a fatura em sua conta de e -mail',
  },
  currentPlan: {
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    downgradeRequest1: 'Você fez um pedido para rebaixar seu plano para',
    downgradeRequest2: 'mensagens. Observe que seu plano será rebaixado até o final do ciclo de cobrança atual',
    downgradeRequest3: 'Você pode aproveitar o',
    downgradeRequest4: 'serviços até então.',
    downgradeRequestMaster1: 'Você fez um pedido para fazer o downgrade do seu',
    downgradeRequestMaster2: ' planejar',
    downgradeRequestMaster3: ' conversas. Observe que seu plano será rebaixado até o final do ciclo de cobrança atual',
    downgradeRequestMaster4: 'Você pode aproveitar o',
    downgradeRequestMaster5: 'serviços até então.',
    active: 'Ativo',
    inactive: 'Inativo',
    month: 'mês',
    year: 'ano',
    messagePack: 'Pacote de mensagens',
    king: 'Rei',
    baby: 'Bebê',
    whatsapp: 'Whatsapp',
    youAreOn: 'Você está ligado',
    plan: 'plano',
    totalMessagesLimit: 'Limite de mensagens totais',
    liveAgentLimit: 'Limite de agente ao vivo',
    perMonth: '/mês',
    agents: 'Agente (s)',
    messagesAddOn: 'Mensagens complementares',
    botWhitelabels: 'Bot Whitelabels',
    totalAddOnMessages: 'Total de mensagens complementares',
    basePrice: 'Preço base',
    gst: 'GST',
    totalPrice: 'Preço total',
    totalAddOnPrice: 'Preço total do complemento',
    whitelabelPrice: 'Preço de Whitelabel',
    startedOn: 'Começou em',
    nextDueOn: 'Próximo devido',
    addOnExpiry: 'Expiração complementar',
    website: 'Site',
    facebook: 'Facebook',
    telegram: 'Telegrama',
  },
  invoice: {
    invoiceNumber: 'Fatura No.',
    date: 'Data',
    invoiceAmount: 'Valor da fatura',
    planAmount: 'Valor do plano',
    plan: 'Plano',
    messages: 'Mensagens',
    action: 'Ação',
    nA: 'N / D',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Clique para receber a fatura em sua conta de e -mail',
      clickToDownloadInvoice: 'Clique para baixar a fatura no formulário PDF',
    }
  },
  messageAddOn: {
    validity: 'Validade',
    kingPlanHeader: '👑 Plano do rei',
    youAreOnThe: 'Você está no',
    asOfNowAndWillGet: 'A partir de agora, e vai ficar',
    messages: 'mensagens',
    inThisPlan: 'neste plano.',
    youCanBuyAdditionalMessaes: 'Você pode comprar mensagens adicionais de acordo com os requisitos de negócios.',
    plan: ' Plano',
    baby: 'Bebê',
    king: 'Rei',
    get: 'Pegar',
    babyPlan: 'Plano de bebê',
    upgradePlan: 'Plano de atualização',
    asOfNow: ' A partir de agora.',
    pleaseUpgradeToKingPlan: 'Atualizar para ',
    toEnableAddOnsAndWhitelabelPacks: ' Para ativar os pacotes de complementos e whitelabel.',
    messageAddOnPacks: 'Pacotes complementares de mensagens',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Se você atingiu seu limite mensal de mensagens, compre pacotes adicionais e desfrute de serviços ininterruptos.',
    pleaseSelectNumberOfExpectedMessages: 'Selecione o número de mensagens esperadas',
    addOnsValidity: 'Validade de complementos',
    currentBillingCycle: 'Ciclo de cobrança atual',
    oneYear: 'Um ano',
    summary: 'Resumo',
    messageCreditsWithKingPlan: 'Créditos de mensagem com Plano King',
    messageCreditsWith: 'Créditos de mensagem com ',
    withPlan: '',
    billingCycle: 'Ciclo de cobrança',
    payableAmount: 'Valor a pagar',
    oneTimePayment: 'Pagamento único',
    plusTaxes: ' + Impostos',
    buyNow: 'Comprar agora',
    whiteLabelChatWindow: 'Janela de bate -papo de etiqueta branca',
    noBotsLeftToWhiteLabel: 'Nenhum bots deixou para Whitelabel.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Selecione os bots que deseja Whitelabel.',
    selectBots: 'Selecione bots',
    bot: 'Robô',
    bots: 'Bots',
    toBeWhiteLabelled: ' estar em branco',
    remove: 'Remover',
    brandNameFromChatWindow: ' BrandName da janela de bate -papo.',
    willBeValidTill: 'Será válido até',
    ourYearlyPrice: 'Nosso preço anual',
    directWhiteLabel: 'Whitelabel direto',
    whiteLabelledBots: 'Bots de Whitelabelled',
    nextRenewalColon: 'Próxima renovação:',
    optOutRequested: 'Optep não solicitado',
    contactAgency: 'Agência de contato',
    optOut: 'Excluir',
    removeWhiteLabel: 'Remova Whitelabel',
    messageCount: 'Mensagens',
    numberOfPacks: 'Número de pacotes',
    amount: 'Valor',
    taxes: 'Impostos',
    totalAmount: 'Valor total',
    numOfBots: 'Nº de Bots',
    select: 'Selecionar',
    selectTheValidity: 'Selecionar a validade',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Detalhes do pagamento',
    cardNumber: 'Número do cartão',
    cvv: 'Cvv',
    // cardNumber: 'Número do cartão',
    month: 'Mês',
    year: 'Ano',
    save: 'Salvar',
    add: 'Adicionar',
    edit: 'Editar',
    disclaimer: 'Isenção de responsabilidade:',
    disclaimerDescription1: 'Os detalhes do método de pagamento acima serão aplicáveis ​​apenas aos usuários fora da Índia.',
    disclaimerDescription2: 'Não estamos salvando os detalhes do seu cartão conosco. Caso você queira alterar os detalhes do seu cartão, ele será alterado diretamente com o gateway de pagamento.',
    billingAddress: 'Endereço de Cobrança',
    companyName: 'nome da empresa',
    country: 'País',
    selectCountry: 'Selecione o pais',
    stateOrRegion: 'Estado/região',
    streetAddress: 'Endereço da Rua',
    gstIn: 'Gstin',
    postalCode: 'Código postal',
    cancel: 'Cancelar',
    proceedToPay: 'Prossiga para pagar',
    company: 'Empresa',
    state: 'Estado',
    masterCard: 'Cartão mestre',
    yourCompanyName: 'O nome da sua empresa',
    yourCountry: 'Seu país',
    yourRegion: 'Sua região',
    yourPostalCode: 'Seu CEP',
    plusTaxes: ' + impostos',
    dropDown: {
      month: 'Mês *',
      year: 'Ano *',
    },
    placeholders: {
      enterCardNumber: 'Digite o número do cartão',
      cvv: 'Cvv',
      enterYourCompanyName: 'Digite o nome da sua empresa',
      enterYourStateOrRegion: 'Digite seu estado/região',
      enterYourStreetAddress: 'Digite seu endereço de rua',
      enterYourGSTIN: 'Digite seu gstin',
      enterPostalCode: 'Digite o código postal',
    }
  },
  plans: {
    viewDetailedPricing: 'Ver preços detalhados',
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    weHaveGotAPricingPlanPerfectForYou: 'Temos um plano de preços que isso é perfeito para você',
    weBelieve: 'Acreditamos',
    shouldBeAccessibleToAllCompanies: ' deve estar acessível a todas as empresas, não importa o tamanho.',
    monthly: 'Por mês',
    yearly: 'Anual',
    twoMonthsFree: '(2 meses grátis)',
    babyPlan: 'Plano de bebê',
    freeForever: 'Livre para sempre',
    messagesPerMonth: 'Mensagens por mês',
    currentPlan: 'Plano atual',
    freePlan: 'Plano livre',
    kingPlan: 'Plano King',
    perYear: 'por ano',
    plan: 'plano',
    toThe: ' para o',
    plusTaxes: ' + Impostos',
    perMonth: 'por mês',
    upgradePlan: 'Plano de atualização',
    changePlan: 'Alterar Plano',
    currentlyActive: 'Atualmente ativo',
    emperorPlan: 'Plano do Imperador (Enterprise)',
    sendAsManyMessagesAsYouWant: 'Envie quantas mensagens forem necessárias',
    contactUs: 'Contate-nos',
    features: 'Características',
    areYouSureYouWantToShiftFrom: 'Tem certeza de que deseja mudar do',
    messages: 'Mensagens',
    planQuestionMark: 'plano?',
    downgradeToFreePlan: 'Downgrade para o plano gratuito',
    goToBabyPlan: 'Vá para o Plano de Baby',
    thankYouForChoosingBotPenguin: 'Obrigado por escolher o BotPenguin',
    bots: 'Bots',
    facebookPages: 'Páginas do Facebook',
    teamMember: 'Membro da equipe',
    dataStorage: 'Armazenamento de dados',
    conditionalFlow: 'Fluxo condicional',
    liveChat: 'Bate-papo ao vivo',
    bookAppointments: 'Compromissos de livros',
    csvExport: 'Exportação de CSV',
    emailAndCallSupport: 'E -mail e suporte de chamada',
    googleCalendarIntegrations: 'Integração do calendário do Google',
    zohoCrmIntegrations: 'Integração do Zoho CRM',
    reportsAndAnalytics: 'Relatórios e análises',
    removeBotPenguinBranding: 'Remova a marca BotPenguin',
    oneBot: '1 bot',
    twoKMessagesPerMonth: 'Mensagens/mês de 2k',
    oneFacebookPage: '1 página do Facebook',
    oneTeamMember: '1 membro da equipe',
    thirtyDaysOfDataStorage: '30 dias de armazenamento de dados',
    unlimitedBot: 'Bot ilimitado',
    threeTo500kMessagesPerMonth: '3k a 500k mensagens/mês',
    tenFacebookPages: '10 páginas do Facebook',
    threeTeamMembers: '3 membros da equipe',
    nintyDaysOfDataStorage: '90 dias de armazenamento de dados',
    unlimitedMessages: 'Mensagens ilimitadas',
    unlimitedFacebookPages: 'Páginas ilimitadas do Facebook',
    unlimitedTeamMembers: 'Membros da equipe ilimitados',
    unlimitedDataStorage: 'Armazenamento de dados ilimitado',
    youAreHere: 'Você está aqui!',
    detailedPricing: {
      kingsplan: 'Plano Rei',
      elementary: 'Elementar',
      solutionType: 'Tipo de Solução',
      saas: 'SaaS',
      fbpage1fb: '1 Página do Facebook',
      perMonth100: '100 por mês',
      enterprise: 'Empresa',
      saasOnPremises: 'SaaS nas Instalações',
      aiChatbotFeatures: 'Recursos de Chatbot de IA',
      dataSourceForTraning: 'Fonte de Dados para Treinamento',
      smartFeatures: 'Recursos Inteligentes',
      liveChat: 'Chat Ao Vivo',
      day30: '30 dias',
      day90: '90 dias',
      landSquared: 'Landsquared',
      doItYourself: 'Faça Você Mesmo',
      unifiedInbox: 'Caixa de Entrada Unificada',
      platforms: 'Plataformas',
      websiteChatbot: 'Chatbot de Website',
      FacebookMessengerChatbot: 'Chatbot do Facebook Messenger',
      Telegram: 'Telegrama',
      conversationalLandingPage: 'Página de Destino Conversacional',
      integrations: 'Integrações',
      reportsAndAnalytics: 'Relatórios e Análises',
      customerSupport: 'Suporte ao Cliente',
      security: 'Segurança',
      bots: 'Bots',
      babyPlan: 'Plano Básico',
      kingPlan: 'Plano Rei',
      emperorPlan: 'Plano Imperador',
      noofBots: 'Nº de Bots',
      one: '1 (Um)',
      unlimitedBots: 'Bots Ilimitados',
      noOfWebsite: 'Nº de Sites',
      unlimitedWebsites: 'Sites Ilimitados',
      unlimitedMessages: 'Mensagens Ilimitadas',
      messagesSend: 'Mensagens Enviadas',
      perMonth2000: '2,000 por mês',
      upto500000perMonth: 'Até 500.000 por mês',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Páginas Ilimitadas',
      telegramNumbers: 'Números do Telegram',
      unlimited: 'Ilimitado',
      whatsappNumbers: 'Números do WhatsApp',
      subscribersLeads: 'Assinantes/Leads',
      createSupportTeam: 'Criar Equipe de Suporte para Chat Ao Vivo',
      oneMember: '1 Membro',
      threeMembers: '3 membros',
      conversationHistory: 'Histórico de Conversação',
      thirtyDays: '30 Dias',
      ninetyDays: '90 Dias',
      chatbotDesigning: 'Design de Chatbot',
      weAreHereToHelp: 'Estamos Aqui para Ajudar',
      weTakeCareEndToEnd: 'Cuidamos de Tudo do Início ao Fim',
      multipleLanguages: 'Múltiplos Idiomas',
      conditionalFlow: 'Fluxo Condicional',
      removeBotPenguinBranding: 'Remover a Marca BotPenguin',
      gptMessages: 'Mensagens GPT',
      aiChatbot: 'Chatbot de IA',
      chatGPTIntegration: 'Integração com ChatGPT',
      configureAIChatbotPersonality: 'Configurar Personalidade do Chatbot de IA',
      trainChatbotOnYourOwnData: 'Treinar o Chatbot com Seus Próprios Dados',
      trainChatbotOnWebPages: 'Treinar o Chatbot em Páginas da Web',
      trainChatbotOnMultipleFiles: 'Treinar o Chatbot com Múltiplos Arquivos',
      trainChatbotOnFAQs: 'Treinar o Chatbot em Perguntas Frequentes (FAQs)',
      dataTrainingStorage: 'Armazenamento de Treinamento de Dados',
      useChatGPTModel4_3_5Turbo: 'Usar o Modelo ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Enviar Múltiplos Arquivos para Treinamento de Chatbot',
      naturalLanguageProcessing: 'Processamento de Linguagem Natural',
      naturalLanguageUnderstanding: 'Compreensão de Linguagem Natural',
      answerLength: 'Tamanho da Resposta',
      chatTone: 'Tom do Chat',
      multiLanguage: 'Multi-Idioma',
      answerFormatting: 'Formatação da Resposta',
      customPrompt: 'Mensagem Personalizada',
      integrateBotPenguinLLM: 'Integrar BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrar Seu Próprio/LTM Personalizado',
      periodicallySyncDataSources: 'Sincronizar Fontes de Dados Periodicamente',
      aiBasedSuggestedQuestions: 'Perguntas Sugeridas com Base em IA',
      chooseVisibilityForParticularWebpageSitemap: 'Escolher Visibilidade para Páginas/Mapas de Sites Específicos',
      chooseKnowledgeMix: 'Escolher Mix de Conhecimento:\nSua Base de Conhecimento\nConhecimento do ChatGPT\n"Conhecimento do ChatGPT + Sua Base de Conhecimento"',
      faqExtractionFromFileAndURL: 'Extração de FAQs de Arquivos e URLs',
      analyticsToImproveAI: 'Analytics para Melhorar a IA',
      exportQuestionFlow: 'Exportar Fluxo de Perguntas',
      sessionManagement: 'Gestão de Sessões',
      collectFeedbackOnChatbotResponses: 'Coletar Feedback nas Respostas do Chatbot',
      smartAIFollowUpQuestions: 'Perguntas Inteligentes/AI de Acompanhamento',
      trainOnYourNotionWorkspaceData: 'Treinar com os Dados do seu Espaço de Trabalho no Notion',
      trainOnYoutubeVideo: 'Treinar com Vídeos do YouTube',
      trainOnCustomVideoUpload: 'Treinar com Upload de Vídeos Personalizados',
      rssFeed: 'Feed RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'Bloqueio de IP e Região',
      emailAndMobileValidation: 'Validação de E-mail e Celular',
      appointmentsBooking: 'Agendamento de Consultas',
      humanHandover: 'Transferência para Atendimento Humano',
      botHandover: 'Transferência para o Bot',
      handoverBetweenAgents: 'Transferência entre Agentes',
      mobileAppsForAgents: 'Aplicativos Móveis para Agentes',
      roleBasedAccessControls: 'Controles de Acesso Baseados em Função',
      apiIntegrationOnChatFlows: 'Integração de API em Fluxos de Chat',
      liveChatWidget: 'Widget de Chat Ao Vivo',
      typingIndicator: 'Indicador de Digitação',
      liveVisitorsDetails: 'Detalhes dos Visitantes Ao Vivo',
      multipleSimultaneousChat: 'Chat Múltiplo Simultâneo',
      liveTypingView: 'Visualização de Digitação Ao Vivo',
      ruleBasedChatRouting: 'Roteamento de Chat Baseado em Regras',
      chatAutoAssignments: 'Atribuições Automáticas de Chat',
      efficientOmnichannelInterface: 'Interface Omnicanal Eficiente',
      quickReplies: 'Respostas Rápidas',
      unifiedCustomerProfile: 'Perfil do Cliente Unificado',
      visitorStats: 'Estatísticas de Visitantes',
      addContactsFromWhatsapp: 'Adicionar Contatos do WhatsApp',
      applyFilterAndSortConversations: 'Aplicar Filtros e Ordenar Conversas',
      seeChatLogs: 'Ver Registros de Chat',
      assignNotesAndChatTags: 'Atribuir Notas e Etiquetas de Chat',
      directCallsToLeadsWithTwilio: 'Chamadas Diretas para Leads com Twilio',
      customerSegmentation: 'Segmentação de Clientes',
      exportLeadsInCSV: 'Exportar Leads em CSV',
      leadNotificationsByEmail: 'Notificações de Leads por E-mail',
      website: 'Website',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegrama',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Ativação Automática em Dispositivos Móveis/Computadores',
      hideBotOnSpecificPages: 'Ocultar Bot em Páginas Específicas',
      imageSharing: 'Compartilhamento de Imagens',
      readyToUseChatFlowTemplates: 'Modelos de Fluxo de Chat Prontos para Uso',
      fileSharing: 'Compartilhamento de Arquivos',
      customDesignForChatWindow: 'Design Personalizado para a Janela de Chat',
      facebookMessengerBot: 'Bot do Facebook Messenger',
      facebookSubheading: 'Subtítulo do Facebook',
      telegramSubheading: 'Subtítulo do Telegrama',
      conversationalLangdingPageSubheading: 'Subtítulo da Página de Destino Conversacional',
      integrationsSubheading: 'Subtítulo de Integrações',
      multipleSmartFlows: 'Múltiplos Fluxos Inteligentes',
      dripCampaigns: 'Campanhas Drip',
      broadcastFBMessages: 'Mensagens de Transmissão do Facebook',
      multipleFacebookBotWidgets: 'Múltiplos Widgets de Bot do Facebook',
      facebookAutoReplyOnComments: 'Resposta Automática a Comentários no Facebook',
      facebookECommerce: 'E-Commerce no Facebook',
      broadcastAnalytics: 'Analytics de Transmissão',
      telegramMessengerBot: 'Bot do Telegrama Messenger',
      readyToUseTemplates: 'Modelos Prontos para Uso',
      groupBroadcastTelegramMessages: 'Mensagens de Grupo e Transmissão no Telegrama',
      multipleTelegramBotWidgets: 'Vários Widgets de Bot do Telegram',
      conversationalLandingPageBot: 'Bot da Página de Destino Conversacional',
      smartReplies: 'Respostas Inteligentes',
      interactiveAndCustomizableUI: 'Interface Interativa e Personalizável',
      leadManagement: 'Gestão de Leads',
      easyToInstallAndShare: 'Fácil de Instalar e Compartilhar',
      agileCRM: 'Agile CRM',
      capsule: 'Cápsula',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Fechar',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Planilhas',
      googleCalendar: 'Google Agenda',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistema de Emissão de Bilhetes de Suporte',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Chamada de Áudio e Vídeo no Chat (Twilio/Agora)',
      courierTracking: 'Rastreamento de Correio',
      emailIntegration: 'Integração de E-mail',
      webhooks: 'Webhooks',
      schedulers: 'Agendadores',
      paymentGateways: 'Portais de Pagamento (Stripe e RazorPay)',
      enterpriseIntegrations: 'Integrações Empresariais',
      customIntegration: 'Integração Personalizada',
      shopifyIntegration: 'Integração com Shopify',
      totalNumberOfMessages: 'Número Total de Mensagens',
      totalNumberOfLeads: 'Número Total de Leads',
      weeklyReportBotwise: 'Relatório Semanal, por Bot',
      averageAgentResponeTime: 'Tempo Médio de Resposta do Agente',
      visitorTimingReport: 'Relatório de Tempo dos Visitantes',
      customerSegmentBasedReports: 'Relatórios com Base em Segmentos de Clientes',
      tagBasedReports: 'Relatórios com Base em Tags',
      leadGenerationReport: 'Relatório de Geração de Leads',
      supportOnWhatsApp: 'Suporte no WhatsApp',
      knowledgeBase: 'Base de Conhecimento',
      onboardingAndTraining: 'Integração e Treinamento',
      callAndEmailSupport: 'Suporte por Chamada e E-mail',
      freeChatbotCreation: 'Criação de Chatbot Gratuita',
      customerSuccessOfficer: 'Oficial de Sucesso do Cliente',
      secureConnection2048BitSSL: 'Conexão Segura de 2048 bits SSL',
      securityAssessment: 'Avaliação de Segurança',
      dataCenterInUSOrEU: 'Data Center nos EUA ou UE',
      twoStepVerification: 'Verificação em Duas Etapas',
      accessRestrictionsBasedOnIPs: 'Restrições de Acesso com Base em IPs',
      onDemandAuditLog: 'Registro de Auditoria Sob Demanda',
      bannedVisitorsList: 'Lista de Visitantes Banidos',
      customAPIRate: 'Taxa de API Personalizada',
      trustedDomains: 'Domínios Confiáveis',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Curioso pela Codificação',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Nosso plano gratuito',
      TOOLTIP_KING_PLAN: 'Nosso plano mais popular',
      TOOLTIP_EMPEROR_PLAN: 'Plano personalizado para mensagens ilimitadas, dados ilimitados, membros ilimitados da equipe',
      TOOLTIP_CONTACT_US: 'Alcance para nós via WhatsApp ou envie um e -mail em contact@botpenguin.com para saber mais sobre nosso plano personalizado',
      TOOLTIP_UPGRADE: 'Atualize seu plano atual para recursos adicionais',
      TOOLTIP_MONTHLY: 'Selecione um ciclo de pagamento mensal',
      TOOLTIP_YEARLY: 'Selecione um ciclo de pagamento anual para benefícios de desconto',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Vá para o Plano de Baby',
    downgradeToFreePlan: 'Downgrade para o plano gratuito',
    freeForever: 'Livre para sempre',
    features: 'Características',
    currentPlan: 'Plano atual',
    changePlan: 'Alterar Plano',
    currentlyActive: 'Atualmente ativo',
    seeHowToUse: 'Veja como usar',
    weHaveGotPricingPerfectForYou: 'Temos um plano de preços que isso é perfeito para você',
    weBelieve: 'Acreditamos',
    shouldBeAccessibleToAllCompanies: ' deve estar acessível a todas as empresas, não importa o tamanho.',
    monthly: 'Por mês',
    yearly: 'Anual',
    twoMonthsFree: '(2 meses grátis)',
    kingPlan: 'Plano King',
    perYear: 'por ano',
    perMonth: 'por mês',
    conversationsPerMonth: 'Conversas por mês',
    upgradePlan: 'Plano de atualização',
    emperorPlan: 'Plano do Imperador (Enterprise)',
    haveAsManyConversationsAsYouNeed: 'Tem tantas conversas quanto você precisa',
    contactUs: 'Contate-nos',
    metaConversationChargesAreAdditional: 'As cobranças de meta -conversa são adicionais e pagas diretamente por você à Meta, com base no seu uso. Não cobramos taxas de marcação ou facilitação.',
    viewDetailedPricing: 'Ver preços detalhados',
    conversationPricingModelUsedByMeta: 'Modelo de precificação de conversas usado por meta',
    whatsappBusinessPlatformConversationsFallInto: 'As conversas da plataforma de negócios do WhatsApp se enquadram em duas categorias que têm preços de maneira diferente',
    userInitiatedConversation: 'Conversa iniciada pelo usuário',
    userInitiatedConversationDescription: 'Uma conversa que inicia em resposta a uma mensagem do usuário. Sempre que uma empresa responde a um usuário dentro da janela de atendimento ao cliente 24 horas, essa mensagem será associada a uma conversa iniciada pelo usuário. As empresas podem enviar mensagens de formato gratuito nesta janela de atendimento ao cliente de 24 horas.',
    businessInitiatedConversation: 'Conversa iniciada por negócios',
    businessInitiatedConversationDescription: 'Uma conversa que inicia de uma empresa enviando uma mensagem ao usuário fora da janela de atendimento ao cliente de 24 horas. As mensagens que iniciam uma conversa iniciada por negócios exigirão um modelo de mensagem.',
    areYouSureYouWantTo: 'Tem certeza de que deseja mudar do',
    conversations: ' Conversas',
    plan: ' plano',
    toThe: ' para o',
    metaPricingMarkup: 'Marcada de Preços de Meta',
    numberOfChatFlows: 'Número de fluxos de bate -papo',
    greenTickApplication: 'Aplicação de carrapato verde',
    extraPhoneNumberLinking: 'Número de telefone extra vinculando',
    teamMembers: 'Membros do time',
    dataStorage: 'Armazenamento de dados',
    conditionalFlow: 'Fluxo condicional',
    liveChat: 'Bate-papo ao vivo',
    smartInbox: 'Caixa de entrada inteligente',
    whatsappTemplates: 'Modelos do WhatsApp',
    customerSegments: 'Grupoos de clientes',
    broadcastAndDripCampaigns: 'Campanhas de transmissão e gotejamento',
    multipleLanguages: 'Múltiplos idiomas',
    bookAppointments: 'Compromissos de livros',
    csvContactsExport: 'CSV contatos exportar',
    googleCalendarIntegrations: 'Integração do calendário do Google',
    nativeApplications: 'Mais de 60 integração nativa',
    whatsappSupport: 'Suporte do WhatsApp',
    emailAndCallSupport: 'E -mail e suporte de chamada',
    customerIntegrations: 'Integrações de clientes',
    nlpAndNluCapabilities: 'Recursos de PNL e NLU',
    chatGPTBot: 'Chatgpt chatbot',
    customerSuccessOfficer: 'Oficial de sucesso do cliente',
    noMarkupTransparency: 'Sem transparência de marcação',
    upto500kPerMonth: 'Até 500.000 por mês',
    beyond500k: 'Além de 500.000',
    unlimited: 'Ilimitado',
    oneTeamMember: '1 membro da equipe',
    unlimitedTeamMember: 'Membros da equipe ilimitados',
    nintyDaysOfDataStorage: '90 dias de armazenamento de dados',
    unlimitedDataStorage: 'Armazenamento de dados ilimitado',
    detailedPricing: {
      onDemandAuditLog: 'Registro de auditoria sob demanda',
      accessRestrictionsBasedOnIPs: 'Restrições de acesso com base em IPs',
      averageAgentResponeTime: 'Tempo médio de resposta do agente',
      perMonth100: '100 por mês',
      solutionType: 'Tipo de solução',
      saas: 'SaaS',
      saasOnPremises: 'SaaS nas instalações',
      emperorPlan: 'Plano Imperador',
      babyPlan: 'Plano Bebê',
      kingsplan: 'Plano Reis',
      braodcastsAndDrips: 'Transmissões e Gotejamentos',
      thirtyDays: '30 dias',
      ninetyDays: '90 dias',
      oneMember: '1 membro',
      threeMembers: '3 membros',
      one: '1 (Um)',
      unlimitedMessages: 'Mensagens ilimitadas',
      unlimitedBots: 'Bots ilimitados',
      messagesSend: 'Mensagens enviadas',
      upto500000perMonth: 'Até 500.000 por mês',
      unlimited: 'Ilimitado',
      onlyAdmin: 'Somente Admin',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Faça você mesmo',
      weAreHereToHelp: 'Estamos aqui para ajudar',
      weTakeCareEndToEnd: 'Cuidamos de tudo, de ponta a ponta',
      numberOfBots: 'Número de bots',
      conversations: 'Conversas',
      gptMessages: 'Mensagens GPT',
      whatsappNumbers: 'Números do WhatsApp',
      subscribersLeads: 'Assinantes/Leads',
      createSupportTeamForLiveChat: 'Criar equipe de suporte para chat ao vivo',
      conversationHistory: 'Histórico de conversas',
      chatbotDesigning: 'Design de chatbot',
      multipleLanguages: 'Múltiplos idiomas',
      conditionalFlow: 'Fluxo condicional',
      whatsappTemplates: 'Modelos do WhatsApp',
      restartChatFlowTriggers: 'Disparadores de reinício do fluxo de chat',
      multipleWhatsAppWidgets: 'Widgets múltiplos do WhatsApp',
      importWhatsappContacts: 'Importar contatos do WhatsApp',
      whatsappQuickReply: 'Resposta rápida do WhatsApp',
      whatsappSmartInbox: 'Caixa de entrada inteligente do WhatsApp',
      whatsappEcommerceCatalogs: 'Catálogos de comércio eletrônico do WhatsApp',
      whatsappBroadcast: 'Transmissão do WhatsApp',
      whatsappBroadcastAnalytics: 'Análise de transmissão do WhatsApp',
      dripCampaigns: 'Campanhas de gotejamento',
      customerSegmentation: 'Segmentação de clientes',
      keywordBasedResponse: 'Resposta com base em palavras-chave',
      whatsappShopManagement: 'Gestão de loja do WhatsApp',
      aiChatbot: 'Chatbot de IA',
      chatGPTIntegration: 'Integração do ChatGPT',
      configureAIChatbotPersonality: 'Configurar a personalidade do chatbot de IA',
      trainChatbotOnYourOwnData: 'Treine o chatbot com seus próprios dados',
      trainChatbotOnWebPages: 'Treine o chatbot em páginas da web',
      trainChatbotOnMultipleFiles: 'Treine o chatbot em vários arquivos',
      trainChatbotOnFAQs: 'Treine o chatbot em FAQs',
      dataTrainingStorage: 'Armazenamento de treinamento de dados',
      useChatGPTModel4or35Turbo: 'Use o modelo ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Faça o upload de vários arquivos para treinamento do chatbot',
      naturalLanguageProcessing: 'Processamento de linguagem natural',
      naturalLanguageUnderstanding: 'Compreensão de linguagem natural',
      answerLength: 'Comprimento da resposta',
      chatTone: 'Tom do chat',
      multiLanguage: 'Múltiplos idiomas',
      answerFormatting: 'Formatação da resposta',
      customPrompt: 'Prompt personalizado',
      whatsAppSessionManagement: 'Gestão de sessão do WhatsApp (mensagens de inatividade e reinício de fluxo)',
      integrateBotPenguinLLM: 'Integrar BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrar LLM personalizado',
      periodicallySyncDataSources: 'Sincronizar periodicamente fontes de dados',
      aiBasedSuggestedQuestions: 'Perguntas sugeridas com base em IA',
      chooseVisibilityForParticularWebpageSitemap: 'Escolher visibilidade para uma página da web ou mapa de site específico',
      chooseKnowledgeMix: 'Escolher Mix de Conhecimento: Base de Conhecimento ChatGPT "Base de Conhecimento ChatGPT + Sua Base de Conhecimento"',
      faqExtractionFromFileAndURL: 'Extração de FAQ de Arquivo e URL',
      analyticsToImproveAI: 'Análise para melhorar a IA',
      exportQuestionFlow: 'Exportar fluxo de perguntas',
      collectFeedbackOnChatbotResponses: 'Coletar feedback sobre as respostas do chatbot',
      smartAIFollowUpQuestions: 'Perguntas de acompanhamento inteligentes baseadas em IA',
      trainOnYourNotionWorkspaceData: 'Treine com os dados do seu espaço de trabalho Notion',
      trainOnYoutubeVideo: 'Treine com vídeo do YouTube',
      trainOnCustomVideoUpload: 'Treine com envio de vídeo personalizado',
      rssFeed: 'Feed RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'Transferência humana',
      botHandover: 'Transferência do bot',
      handoverBetweenAgents: 'Transferência entre agentes',
      emailAndMobileValidation: 'Validação de e-mail e celular',
      mobileAppsForAgents: 'Aplicativos móveis para agentes',
      appointmentBooking: 'Agendamento de compromissos',
      roleBasedAccessControls: 'Controles de acesso baseados em funções',
      ruleBasedChatRouting: 'Roteamento de chat baseado em regras',
      liveVisitorsDetails: 'Detalhes de visitantes ao vivo',
      multipleSimultaneousChats: 'Múltiplos chats simultâneos',
      visitorStats: 'Estatísticas de visitantes',
      addContactsFromWhatsApp: 'Adicionar contatos do WhatsApp',
      applyFilterAndSortConversations: 'Aplicar filtro e classificar conversas',
      seeChatLogs: 'Ver logs de chat',
      quickReplies: 'Respostas rápidas',
      unifiedCustomerProfile: 'Perfil de cliente unificado',
      assignNotesAndChatTags: 'Atribuir notas e tags de chat',
      exportLeadsInCSV: 'Exportar leads em CSV',
      tagContacts: 'Marcar contatos',
      customAttributes: 'Atributos personalizados',
      markStatusOfConversations: 'Marcar o status das conversas',
      assignConversationToAgents: 'Atribuir conversa a agentes',
      leadNotificationsByEmail: 'Notificações de leads por e-mail',
      directCallsToLeadsWithTwilio: 'Chamadas diretas para leads com Twilio',
      whatsAppTemplates: 'Modelos do WhatsApp',
      promotionalMessageFrequencyControl: 'Controle de frequência de mensagens promocionais',
      whatsAppWidget: 'Widget do WhatsApp',
      whatsAppQR: 'Código QR do WhatsApp',
      whatsAppLink: 'Link do WhatsApp',
      campaignScheduler: 'Agendador de campanha',
      customTriggers: 'Acionadores personalizados',
      customCampaigns: 'Campanhas personalizadas',
      repeatPurchaseReminders: 'Lembretes de compra repetida',
      campaignOpenRateTracking: 'Rastreamento da taxa de abertura da campanha',
      paymentCollectionOnWhatsApp: 'Coleta de pagamento no WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Cápsula',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Fechar',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Cobre',
      autopilot: 'Piloto automático',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Planilhas do Google',
      googleCalendar: 'Calendário do Google',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistema de emissão de ingressos de suporte',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Chamada de áudio e vídeo no chat (Twilio/Agora)',
      courierTracking: 'Rastreamento de entregas',
      emailIntegration: 'Integração de e-mail',
      webhooks: 'Webhooks',
      schedulers: 'Agendadores',
      paymentGateways: 'Gateways de pagamento (Stripe e RazorPay)',
      enterpriseIntegrations: 'Integrações empresariais',
      customIntegration: 'Integração personalizada',
      freeWhatsAppAPIApprovalAndSetup: 'Aprovação e configuração gratuita da API do WhatsApp',
      developerAPIsAccess: 'Acesso às APIs de desenvolvedor',
      customRateLimit: 'Limite de taxa personalizado',
      totalNumberOfLeads: 'Número total de leads',
      averageAgentResponseTime: 'Tempo médio de resposta do agente',
      visitorTimingReport: 'Relatório de cronometragem de visitantes',
      customerSegmentBasedReports: 'Relatórios com base em segmentos de clientes',
      tagBasedReports: 'Relatórios com base em tags',
      leadGenerationReport: 'Relatório de geração de leads',
      supportOnWhatsApp: 'Suporte no WhatsApp',
      knowledgeBase: 'Base de conhecimento',
      onboardingAndTraining: 'Integração e treinamento',
      callAndEmailSupport: 'Suporte por telefone e e-mail',
      freeChatbotCreation: 'Criação gratuita de chatbot',
      customerSuccessOfficer: 'Oficial de sucesso do cliente',
      secureConnection: 'Conexão segura SSL de 2048 bits',
      securityAssessment: 'Avaliação de segurança',
      dataCenterLocation: 'Localização do centro de dados (EUA ou UE)',
      twoStepVerification: 'Verificação em duas etapas',
      accessRestrictions: 'Restrições de acesso',
      auditLog: 'Registro de auditoria sob demanda',
      bannedVisitorsList: 'Lista de visitantes banidos',
      customAPIRate: 'Taxa personalizada de API',
      trustedDomains: 'Domínios confiáveis',
      elementary: 'Elementar',
      bots: 'Bots',
      unlockWhatsapp: 'Desbloquear o WhatsApp',
      aiChatbotFeatures: 'Recursos de chatbot de IA',
      dataSourceForTraining: 'Fonte de dados para treinamento',
      smartFeatures: 'Recursos inteligentes',
      liveChat: 'Chat ao vivo',
      smartInbox: 'Caixa de entrada inteligente',
      broadcastsAndDrips: 'Transmissões e gotejamentos',
      integrations: 'Integrações',
      development: 'Desenvolvimento',
      reportsAndAnalytics: 'Relatórios e análises',
      customerSupport: 'Suporte ao cliente',
      security: 'Segurança',
      whatsappWidgets: 'Widgets do WhatsApp',
      campaignSchedular: 'Agendador de campanha',
      repeatPurchaseRemainder: 'Lembrete de compra repetida',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Nosso plano gratuito',
      TOOLTIP_KING_PLAN: 'Nosso plano mais popular',
      TOOLTIP_EMPEROR_PLAN: 'Plano personalizado para mensagens ilimitadas, dados ilimitados, membros ilimitados da equipe',
      TOOLTIP_CONTACT_US: 'Alcance para nós via WhatsApp ou envie um e -mail em contact@botpenguin.com para saber mais sobre nosso plano personalizado',
      TOOLTIP_UPGRADE: 'Atualize seu plano atual para recursos adicionais',
      TOOLTIP_MONTHLY: 'Selecione um ciclo de pagamento mensal',
      TOOLTIP_YEARLY: 'Selecione um ciclo de pagamento anual para benefícios de desconto',
    }
  }
};
