import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, AfterViewInit {
  environment = environment;
  code: any;
  text = 'Please wait, verifying your email';
  username = '';
  showError = false;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
  ) {
  }



  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params.code;
      this.sharedService.loader('start');
      this.authService.verifyEmail(this.code)
        .then((res: any) => {
          localStorage.setItem('token', res.access_token);
          this.sharedService.showToast('success', '', 'Account has been activated');
          const location: any = localStorage.getItem('location');
          if (location && (JSON.parse(location).country_code === 'IN' || JSON.parse(location).country_code === 'GB')) {
            return window.location.href = '/home';
          }
          return window.location.href = '/bot-builder/onboarding/select-use-case';
        })
        .catch(err => {
          this.text = 'The link has expired, please try again with a new one!';
          // this.sharedService.showToast('error', '', 'The link has expired, please try again with a new one!');
        })
        .finally(() => this.sharedService.loader('stop'));
    });
  }

  hideShowError() {
    this.showError = false;
  }

  resendEmail() { }

}
