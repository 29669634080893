import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { CanvasComponent } from '../../bot-builder/components/canvas/canvas.component';
import { Router } from '@angular/router';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-unsaved-warning',
  templateUrl: './unsaved-warning.component.html',
  styleUrls: ['./unsaved-warning.component.scss']
})
export class UnsavedWarningComponent implements OnInit, OnDestroy {
  staticText: any;
  storeSubscribe$: Subscription;
  openUnsavedDialog = false;
  lastNavigationRoute: string;
  unsavedWarning$: Subscription;

  @ViewChild(CanvasComponent) canvasComponent: CanvasComponent;

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared;
  }

  ngOnInit(): void {
    this.unsavedWarning$ = this.sharedService.unsavedWarning.subscribe((data) => {
      this.openUnsavedDialog = data.openUnsavedDialog;
    });
  }

  ngOnDestroy() {
    this.unsavedWarning$.unsubscribe();
  }

  cancel() {
    const currentState = this.sharedService.unsavedWarning.getValue();
    this.sharedService.unsavedWarning.next({
      ...currentState,
      openUnsavedDialog: false,
      navigateUrl: ''
    });
  }

  confirm(event) {
    if (event) {
      const currentState = this.sharedService.unsavedWarning.getValue();
      this.router.navigate([currentState.navigateUrl]);
      this.sharedService.unsavedWarning.next({
        ...currentState,
        openUnsavedDialog: false,
        isConfirm: true,
        navigateUrl: ''
      });
    } else {
      this.cancel();
    }
  }
}
