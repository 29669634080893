export const subscription = {
  subscriptions: 'Abonnements',
  plan: 'Plan',
  whatsappPlan: 'Plan WhatsApp',
  addOns: 'Complémentations',
  billingDetails: 'Détails de la facturation',
  invoices: 'Factures',
  wa1Reminder: 'WA 1er rappel',
  wa2Reminder: 'WA 2ème rappel',
  waRenewApi: 'API de renouvellement',
  waCheckUsageApi: 'API d`utilisation de vérification',
  waResetUsageApi: 'API d`utilisation de réinitialisation',
  waExceedUsageApi: 'Wa dépasse l`API d`utilisation',
  waGetBotsInThisAccount: 'Wa obtient des bots dans ce compte',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Nous avons les prix les plus abordables pour tous vos besoins.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'Plan WhatsApp',
    TOOLTIP_ADDS_ON_HEADING: 'Acheter des packs de massages supplémentaires et un bot WhiteLabel',
    TOOLTIP_ADDS_ON_MESSAGE: 'Si vous avez atteint votre limite mensuelle de messages, vous pouvez acheter des packs supplémentaires.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Vérifiez vos détails d`abonnement actuels',
    TOOLTIP_HORIZONTAL_BAR: 'Vérifiez nos prix mensuels ou annuels de 3K à 500k messages en déplaçant la barre de gauche à droite.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Mettez à jour vos détails de facturation pour la facturation.',
    TOOLTIP_INVOICES_HEADING: 'Vérifiez vos factures précédentes.',
    TOOLTIP_INVOICES_ACTION: 'Cliquez pour recevoir la facture dans votre compte de messagerie',
  },
  currentPlan: {
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    downgradeRequest1: 'Vous avez mis une demande pour rétrograder votre plan à',
    downgradeRequest2: 'messages. Veuillez noter que votre plan sera rétrogradé à la fin du cycle de facturation actuel',
    downgradeRequest3: 'Vous pouvez profiter du',
    downgradeRequest4: 'services jusque-là.',
    downgradeRequestMaster1: 'Vous avez mis une demande pour rétrograder votre',
    downgradeRequestMaster2: ' planifier',
    downgradeRequestMaster3: ' conversations. Veuillez noter que votre plan sera rétrogradé à la fin du cycle de facturation actuel',
    downgradeRequestMaster4: 'Vous pouvez profiter du',
    downgradeRequestMaster5: 'services jusque-là.',
    active: 'Actif',
    inactive: 'Inactif',
    month: 'mois',
    year: 'année',
    messagePack: 'Pack de messages',
    king: 'Roi',
    baby: 'Bébé',
    whatsapp: 'Whatsapp',
    youAreOn: 'Tu es sur',
    plan: 'plan',
    totalMessagesLimit: 'Limite des messages totaux',
    liveAgentLimit: 'Limite d`agent en direct',
    perMonth: '/mois',
    agents: 'agents)',
    messagesAddOn: 'Messages complémentaire',
    botWhitelabels: 'Bot whitelabels',
    totalAddOnMessages: 'Messages complémentaires totaux',
    basePrice: 'Prix ​​de base',
    gst: 'TPS',
    totalPrice: 'Prix ​​total',
    totalAddOnPrice: 'Prix ​​complémentaire total',
    whitelabelPrice: 'Prix ​​de Whitelabel',
    startedOn: 'Commencé le',
    nextDueOn: 'Suivant dû sur',
    addOnExpiry: 'Expiration complémentaire',
    website: 'Site web',
    facebook: 'Facebook',
    telegram: 'Telegram',
  },
  invoice: {
    invoiceNumber: 'N ° de facture',
    date: 'Date',
    invoiceAmount: 'Montant de la facture',
    planAmount: 'Montant du plan',
    plan: 'Plan',
    messages: 'messages',
    action: 'Action',
    nA: 'N / A',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Cliquez pour recevoir la facture dans votre compte de messagerie',
      clickToDownloadInvoice: 'Cliquez pour télécharger la facture dans le formulaire PDF',
    }
  },
  messageAddOn: {
    validity: 'Validité',
    kingPlanHeader: '👑 Plan King',
    youAreOnThe: 'Vous êtes sur le',
    asOfNowAndWillGet: 'à partir de maintenant, et obtiendra',
    messages: 'messages',
    inThisPlan: 'dans ce plan.',
    youCanBuyAdditionalMessaes: 'Vous pouvez acheter des messages supplémentaires conformément aux besoins de votre entreprise.',
    plan: ' Plan',
    baby: 'Bébé',
    king: 'Roi',
    get: 'Obtenir',
    babyPlan: 'Plan pour bébé',
    upgradePlan: 'Plan de mise à niveau',
    asOfNow: ' à partir de maintenant.',
    pleaseUpgradeToKingPlan: 'Veuillez passer à ',
    toEnableAddOnsAndWhitelabelPacks: ' Pour activer les modules complémentaires et les packs WhiteLabel.',
    messageAddOnPacks: 'Messages Packs complémentaires',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Si vous avez atteint votre limite mensuelle de messages, achetez des packs supplémentaires et profitez de services ininterrompus.',
    pleaseSelectNumberOfExpectedMessages: 'Veuillez sélectionner le nombre de messages attendus',
    addOnsValidity: 'Validité complémentaire',
    currentBillingCycle: 'Cycle de facturation actuel',
    oneYear: 'Un ans',
    summary: 'Résumé',
    messageCreditsWithKingPlan: 'Crédits de message avec King Plan',
    messageCreditsWith: 'Crédits de message avec ',
    withPlan: '',
    billingCycle: 'Cycle de facturation',
    payableAmount: 'Montant payable',
    oneTimePayment: 'Paiement une fois',
    plusTaxes: ' + Taxes',
    buyNow: 'Acheter maintenant',
    whiteLabelChatWindow: 'Fenêtre de chat à étiquette blanche',
    noBotsLeftToWhiteLabel: 'Il n`y a pas de robots à Whitelabel.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Veuillez sélectionner les robots que vous souhaitez WhiteLabel.',
    selectBots: 'Sélectionner des bots',
    bot: 'Bot',
    bots: 'Bots',
    toBeWhiteLabelled: ' être entièrement',
    remove: 'Retirer',
    brandNameFromChatWindow: ' BrandName de la fenêtre de chat.',
    willBeValidTill: 'Sera valable jusqu`à',
    ourYearlyPrice: 'Notre prix annuel',
    directWhiteLabel: 'Whitelabel direct',
    whiteLabelledBots: 'Bots entièrement',
    nextRenewalColon: 'Renouvellement suivant:',
    optOutRequested: 'Opt Out demandé',
    contactAgency: 'Agence de contact',
    optOut: 'Se désengager',
    removeWhiteLabel: 'Retirer Whitelabel',
    messageCount: 'Messages',
    numberOfPacks: 'Nombre de packs',
    amount: 'Montant',
    taxes: 'Taxes',
    totalAmount: 'Montant total',
    numOfBots: 'Nombre de Bots',
    select: 'Sélectionner',
    selectTheValidity: 'Sélectionner la validité',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Détails de paiement',
    cardNumber: 'Numéro de carte',
    cvv: 'CVC',
    // cardNumber: 'Numéro de carte',
    month: 'Mois',
    year: 'Année',
    save: 'Sauvegarder',
    add: 'Ajouter',
    edit: 'Modifier',
    disclaimer: 'Clause de non-responsabilité:',
    disclaimerDescription1: 'Les détails du mode de paiement ci-dessus ne seront applicables qu`aux utilisateurs en dehors de l`Inde.',
    disclaimerDescription2: 'Nous n`enregistrons pas les détails de votre carte avec nous. Dans le cas, vous souhaitez modifier les détails de votre carte, il sera directement modifié avec la passerelle de paiement.',
    billingAddress: 'adresse de facturation',
    companyName: 'Nom de l`entreprise',
    country: 'Pays',
    selectCountry: 'Choisissez le pays',
    stateOrRegion: 'État / région',
    streetAddress: 'Adresse de la rue',
    gstIn: 'Gstin',
    postalCode: 'Code Postal',
    cancel: 'Annuler',
    proceedToPay: 'Procéder au paiement',
    company: 'Entreprise',
    state: 'État',
    masterCard: 'Carte maîtresse',
    yourCompanyName: 'Le nom de votre société',
    yourCountry: 'Votre pays',
    yourRegion: 'Votre région',
    yourPostalCode: 'Votre code postal',
    plusTaxes: ' + taxes',
    dropDown: {
      month: 'Mois *',
      year: 'Année *',
    },
    placeholders: {
      enterCardNumber: 'Entrez le numéro de carte',
      cvv: 'CVC',
      enterYourCompanyName: 'Entrez le nom de votre entreprise',
      enterYourStateOrRegion: 'Entrez votre état / région',
      enterYourStreetAddress: 'Entrez votre adresse de rue',
      enterYourGSTIN: 'Entrez votre Gstin',
      enterPostalCode: 'Entrez le code postal',
    }
  },
  plans: {
    viewDetailedPricing: 'Afficher les prix détaillés',
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    weHaveGotAPricingPlanPerfectForYou: 'Nous avons un plan de tarification qui vous est parfait pour vous',
    weBelieve: 'Nous croyons',
    shouldBeAccessibleToAllCompanies: ' devrait être accessible à toutes les sociétés, peu importe la taille.',
    monthly: 'Mensuel',
    yearly: 'Annuel',
    twoMonthsFree: '(2 mois gratuits)',
    babyPlan: 'Plan pour bébé',
    freeForever: 'Libre pour toujours',
    messagesPerMonth: 'Messages par mois',
    currentPlan: 'Plan actuel',
    freePlan: 'Plan gratuit',
    kingPlan: 'Plan du roi',
    perYear: 'par an',
    plan: 'plan',
    toThe: ' au',
    plusTaxes: ' + Taxes',
    perMonth: 'par mois',
    upgradePlan: 'Plan de mise à niveau',
    changePlan: 'Plan de changement',
    currentlyActive: 'Actuellement actif',
    emperorPlan: 'Plan d`empereur (entreprise)',
    sendAsManyMessagesAsYouWant: 'Envoyez autant de messages que vous avez besoin',
    contactUs: 'Contactez-nous',
    features: 'Caractéristiques',
    areYouSureYouWantToShiftFrom: 'Êtes-vous sûr de vouloir passer du',
    messages: 'messages',
    planQuestionMark: 'plan?',
    downgradeToFreePlan: 'Plan de rétrogradation',
    goToBabyPlan: 'Aller au plan bébé',
    thankYouForChoosingBotPenguin: 'Merci d`avoir choisi BotPenguin',
    bots: 'Bots',
    facebookPages: 'Pages Facebook',
    teamMember: 'Membre de l`équipe',
    dataStorage: 'Stockage de données',
    conditionalFlow: 'Flux conditionnel',
    liveChat: 'Chat en direct',
    bookAppointments: 'Rendez-vous de livre',
    csvExport: 'Exportation de CSV',
    emailAndCallSupport: 'Email et assistance des appels',
    googleCalendarIntegrations: 'Intégration du calendrier Google',
    zohoCrmIntegrations: 'Intégration Zoho CRM',
    reportsAndAnalytics: 'Rapports et analyses',
    removeBotPenguinBranding: 'Supprimer la marque de botpenguin',
    oneBot: '1 bot',
    twoKMessagesPerMonth: 'Messages 2K / mois',
    oneFacebookPage: '1 page Facebook',
    oneTeamMember: '1 membre de l`équipe',
    thirtyDaysOfDataStorage: '30 jours de stockage de données',
    unlimitedBot: 'Bot illimité',
    threeTo500kMessagesPerMonth: 'Messages 3K à 500k / mois',
    tenFacebookPages: '10 pages Facebook',
    threeTeamMembers: '3 membres de l`équipe',
    nintyDaysOfDataStorage: '90 jours de stockage de données',
    unlimitedMessages: 'Messages illimités',
    unlimitedFacebookPages: 'Pages Facebook illimitées',
    unlimitedTeamMembers: 'Membres de l`équipe illimités',
    unlimitedDataStorage: 'Stockage de données illimité',
    youAreHere: 'Tu es là!',
    detailedPricing: {
      kingsplan: 'Plan Roi',
      elementary: 'Élémentaire',
      solutionType: 'Type de Solution',
      saas: 'SaaS',
      fbpage1fb: '1 Page FB',
      perMonth100: '100 par mois',
      enterprise: 'Entreprise',
      saasOnPremises: 'SaaS Sur Site',
      aiChatbotFeatures: 'Fonctionnalités du Chatbot AI',
      dataSourceForTraning: 'Source de Données pour la Formation',
      smartFeatures: 'Fonctionnalités Intelligentes',
      liveChat: 'Chat en Direct',
      day30: '30 jours',
      day90: '90 jours',
      landSquared: 'LandSquared',
      doItYourself: 'Faites-le Vous-même',
      unifiedInbox: 'Boîte de Réception Unifiée',
      platforms: 'Plateformes',
      websiteChatbot: 'Chatbot de Site Web',
      FacebookMessengerChatbot: 'Chatbot de Facebook Messenger',
      Telegram: 'Télégramme',
      conversationalLandingPage: '\'Page d\'Atterrissage Conversationnelle\'',
      integrations: 'Intégrations',
      reportsAndAnalytics: 'Rapports et Analytiques',
      customerSupport: 'Support Client',
      security: 'Sécurité',
      bots: 'Bots',
      babyPlan: 'Plan Bébé',
      kingPlan: 'Plan Roi',
      emperorPlan: 'Plan Empereur',
      noofBots: 'Nombre de Bots',
      one: '1 (Un)',
      unlimitedBots: 'Bots Illimités',
      noOfWebsite: 'Nombre de Sites Web',
      unlimitedWebsites: 'Sites Web Illimités',
      unlimitedMessages: 'Messages Illimités',
      messagesSend: 'Messages Envoyés',
      perMonth2000: '2,000 par mois',
      upto500000perMonth: '\'Jusqu\'à 500 000 par mois\'',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Pages Illimitées',
      telegramNumbers: 'Numéros de Télégramme',
      unlimited: 'Illimité',
      whatsappNumbers: 'Numéros WhatsApp',
      subscribersLeads: 'Abonnés/Leads',
      createSupportTeam: 'Créer une Équipe de Support pour le Chat en Direct',
      oneMember: '1 Membre',
      threeMembers: '3 Membres',
      conversationHistory: 'Historique des Conversations',
      thirtyDays: '30 Jours',
      ninetyDays: '90 Jours',
      chatbotDesigning: 'Conception de Chatbot',
      weAreHereToHelp: 'Nous sommes là pour aider',
      weTakeCareEndToEnd: 'Nous prenons soin de bout en bout',
      multipleLanguages: 'Langues Multiples',
      conditionalFlow: 'Flux Conditionnel',
      removeBotPenguinBranding: 'Supprimer la Marque BotPenguin',
      gptMessages: 'Messages GPT',
      aiChatbot: 'Chatbot AI',
      chatGPTIntegration: 'Intégration de ChatGPT',
      configureAIChatbotPersonality: 'Configurer la Personnalité du Chatbot AI',
      trainChatbotOnYourOwnData: 'Entraîner le Chatbot sur Vos Propres Données',
      trainChatbotOnWebPages: 'Entraîner le Chatbot sur les Pages Web',
      trainChatbotOnMultipleFiles: 'Entraîner le Chatbot sur Plusieurs Fichiers',
      trainChatbotOnFAQs: 'Entraîner le Chatbot sur les FAQ',
      dataTrainingStorage: '\'Stockage des Données d\'Entraînement\'',
      useChatGPTModel4_3_5Turbo: 'Utiliser le Modèle ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: '\'Télécharger Plusieurs Fichiers pour l\'Entraînement du Chatbot\'',
      naturalLanguageProcessing: 'Traitement du Langage Naturel',
      naturalLanguageUnderstanding: 'Compréhension du Langage Naturel',
      answerLength: 'Longueur de la Réponse',
      chatTone: 'Ton du Chat',
      multiLanguage: 'Multi-Langue',
      answerFormatting: 'Format de la Réponse',
      customPrompt: 'Invite Personnalisée',
      integrateBotPenguinLLM: 'Intégrer BotPenguin LLM',
      integrateOwnCustomLLM: 'Intégrer LLM Personnalisé',
      periodicallySyncDataSources: 'Synchroniser Périodiquement les Sources de Données',
      aiBasedSuggestedQuestions: '\'Questions Suggérées Basées sur l\'IA\'',
      chooseVisibilityForParticularWebpageSitemap: 'Choisir la Visibilité pour une Page Web ou une Carte du Site Particulière',
      chooseKnowledgeMix: 'Choisir le Mélange de Connaissances : Votre Base de Connaissances, Connaissances ChatGPT, "Connaissances ChatGPT + Votre Base de Connaissances"',
      faqExtractionFromFileAndURL: 'Extraction de FAQ à partir de Fichier et URL',
      analyticsToImproveAI: '\'Analytiques pour Améliorer l\'IA\'',
      exportQuestionFlow: 'Exporter le Flux de Questions',
      sessionManagement: 'Gestion de Session',
      collectFeedbackOnChatbotResponses: 'Recueillir des Retours sur les Réponses du Chatbot',
      smartAIFollowUpQuestions: '\'Questions de Suivi Intelligentes de l\'IA\'',
      trainOnYourNotionWorkspaceData: '\'Entraîner avec vos Données d\'Espace de Travail Notion\'',
      trainOnYoutubeVideo: 'Entraîner avec des Vidéos YouTube',
      trainOnCustomVideoUpload: 'Entraîner avec un Chargement Vidéo Personnalisé',
      rssFeed: 'Flux RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'Blocage IP et Régional',
      emailAndMobileValidation: '\'Validation d\'Email et de Mobile\'',
      appointmentsBooking: 'Réservation de Rendez-vous',
      humanHandover: 'Transfert à un Humain',
      botHandover: 'Transfert à un Bot',
      handoverBetweenAgents: 'Transfert entre Agents',
      mobileAppsForAgents: 'Applications Mobiles pour les Agents',
      roleBasedAccessControls: '\'Contrôles d\'Accès Basés sur les Rôles\'',
      apiIntegrationOnChatFlows: 'Intégration API sur les Flux de Chat',
      liveChatWidget: 'Widget de Chat en Direct',
      typingIndicator: 'Indicateur de Saisie',
      liveVisitorsDetails: 'Détails des Visiteurs en Direct',
      multipleSimultaneousChat: 'Chat Multiple Simultané',
      liveTypingView: 'Vue de Saisie en Direct',
      ruleBasedChatRouting: 'Routage de Chat Basé sur des Règles',
      chatAutoAssignments: 'Attributions Automatiques de Chat',
      efficientOmnichannelInterface: 'Interface Omnicanal Efficace',
      quickReplies: 'Réponses Rapides',
      unifiedCustomerProfile: 'Profil Client Unifié',
      visitorStats: 'Statistiques des Visiteurs',
      addContactsFromWhatsapp: 'Ajouter des Contacts depuis WhatsApp',
      applyFilterAndSortConversations: 'Appliquer des Filtres et Trier les Conversations',
      seeChatLogs: 'Voir les Journaux de Chat',
      assignNotesAndChatTags: 'Attribuer des Notes et des Étiquettes de Chat',
      directCallsToLeadsWithTwilio: 'Appels Directs aux Leads avec Twilio',
      customerSegmentation: 'Segmentation des Clients',
      exportLeadsInCSV: 'Exporter les Leads au Format CSV',
      leadNotificationsByEmail: 'Notifications des Leads par Email',
      website: 'Site Web',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Télégramme',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Déclenchement Automatique sur Mobile/Desktop',
      hideBotOnSpecificPages: 'Masquer le Bot sur des Pages Spécifiques',
      imageSharing: '\'Partage d\'Images\'',
      readyToUseChatFlowTemplates: '\'Modèles de Flux de Chat Prêts à l\'Emploi\'',
      fileSharing: 'Partage de Fichiers',
      customDesignForChatWindow: 'Conception Personnalisée pour la Fenêtre de Chat',
      facebookMessengerBot: 'Chatbot de Facebook Messenger',
      facebookSubheading: 'Sous-titre de Facebook',
      telegramSubheading: 'Sous-titre de Télégramme',
      conversationalLangdingPageSubheading: '\'Sous-titre de Page d\'Atterrissage Conversationnelle\'',
      integrationsSubheading: 'Sous-titre des Intégrations',
      multipleSmartFlows: 'Flux Intelligents Multiples',
      dripCampaigns: 'Campagnes Goutte à Goutte',
      broadcastFBMessages: 'Messages Facebook en Diffusion',
      multipleFacebookBotWidgets: 'Widgets de Bot Facebook Multiples',
      facebookAutoReplyOnComments: 'Réponse Automatique sur les Commentaires Facebook',
      facebookECommerce: 'Commerce Électronique Facebook',
      broadcastAnalytics: 'Analytiques de Diffusion',
      telegramMessengerBot: 'Chatbot de Télégramme',
      readyToUseTemplates: '\'Modèles Prêts à l\'Emploi\'',
      groupBroadcastTelegramMessages: 'Messages de Groupe et Diffusion sur Télégramme',
      multipleTelegramBotWidgets: 'Widgets de plusieurs bots Telegram',
      conversationalLandingPageBot: '\'Bot de Page d\'Atterrissage Conversationnelle\'',
      smartReplies: 'Réponses Intelligentes',
      interactiveAndCustomizableUI: 'Interface Interactive et Personnalisable',
      leadManagement: 'Gestion des Leads',
      easyToInstallAndShare: 'Facile à Installer et à Partager',
      agileCRM: 'CRM Agile',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Fermer',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Système de Billetterie de Support',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Appel Audio et Vidéo en Chat (Twilio/Agora)',
      courierTracking: 'Suivi de Courrier',
      emailIntegration: '\'Intégration d\'Email\'',
      webhooks: 'Webhooks',
      schedulers: 'Planificateurs',
      paymentGateways: 'Passerelles de Paiement (Stripe et RazorPay)',
      enterpriseIntegrations: '\'Intégrations d\'Entreprise\'',
      customIntegration: 'Intégration Personnalisée',
      shopifyIntegration: 'Intégration Shopify',
      totalNumberOfMessages: 'Nombre Total de Messages',
      totalNumberOfLeads: 'Nombre Total de Leads',
      weeklyReportBotwise: 'Rapport Hebdomadaire, Botwise',
      averageAgentResponeTime: '\'Temps de Réponse Moyen de l\'Agent\'',
      visitorTimingReport: 'Rapport sur la Fréquentation des Visiteurs',
      customerSegmentBasedReports: 'Rapports Basés sur les Segments de Clients',
      tagBasedReports: 'Rapports Basés sur les Balises',
      leadGenerationReport: 'Rapport sur la Génération de Leads',
      supportOnWhatsApp: 'Support sur WhatsApp',
      knowledgeBase: 'Base de Connaissances',
      onboardingAndTraining: 'Intégration et Formation',
      callAndEmailSupport: 'Support Téléphonique et par Email',
      freeChatbotCreation: 'Création de Chatbot Gratuite',
      customerSuccessOfficer: 'Agent de la Réussite Client',
      secureConnection2048BitSSL: 'Connexion Sécurisée de 2048 Bits SSL',
      securityAssessment: 'Évaluation de la Sécurité',
      dataCenterInUSOrEU: 'Centre de Données aux États-Unis ou en UE',
      twoStepVerification: 'Vérification à Deux Étapes',
      accessRestrictionsBasedOnIPs: '\'Restrictions d\'Accès Basées sur les Adresses IP\'',
      onDemandAuditLog: '\'Journal d\'Audit sur Demande\'',
      bannedVisitorsList: 'Liste des Visiteurs Bannis',
      customAPIRate: '\'Taux d\'API Personnalisé\'',
      trustedDomains: 'Domaines de Confiance',
      mb100: '100 Mo',
      gb1: '1 Go',
      codingCurious: 'Curieux du Codage'
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Notre plan gratuit',
      TOOLTIP_KING_PLAN: 'Notre plan le plus populaire',
      TOOLTIP_EMPEROR_PLAN: 'Plan personnalisé pour les messages illimités, les données illimitées, les membres de l`équipe illimités',
      TOOLTIP_CONTACT_US: 'Atteignez-nous via WhatsApp ou envoyez un courriel à contact@botpenguin.com pour en savoir plus sur notre plan personnalisé',
      TOOLTIP_UPGRADE: 'Mettez à niveau votre plan actuel pour les fonctionnalités supplémentaires',
      TOOLTIP_MONTHLY: 'Sélectionnez un cycle de paiement mensuel',
      TOOLTIP_YEARLY: 'Sélectionnez un cycle de paiement annuel pour les prestations de réduction',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Aller au plan bébé',
    downgradeToFreePlan: 'Plan de rétrogradation',
    freeForever: 'Libre pour toujours',
    features: 'Caractéristiques',
    currentPlan: 'Plan actuel',
    changePlan: 'Plan de changement',
    currentlyActive: 'Actuellement actif',
    seeHowToUse: 'Voir comment utiliser',
    weHaveGotPricingPerfectForYou: 'Nous avons un plan de tarification qui vous est parfait pour vous',
    weBelieve: 'Nous croyons',
    shouldBeAccessibleToAllCompanies: ' devrait être accessible à toutes les sociétés, peu importe la taille.',
    monthly: 'Mensuel',
    yearly: 'Annuel',
    twoMonthsFree: '(2 mois gratuits)',
    kingPlan: 'Plan du roi',
    perYear: 'par an',
    perMonth: 'par mois',
    conversationsPerMonth: 'Conversations par mois',
    upgradePlan: 'Plan de mise à niveau',
    emperorPlan: 'Plan d`empereur (entreprise)',
    haveAsManyConversationsAsYouNeed: 'Avoir autant de conversations que vous avez besoin',
    contactUs: 'Contactez-nous',
    metaConversationChargesAreAdditional: 'Les frais de conversation de méta sont supplémentaires et payés directement par vous à Meta, en fonction de votre utilisation. Nous ne facturons aucun balisage ou frais de facilitation.',
    viewDetailedPricing: 'Afficher les prix détaillés',
    conversationPricingModelUsedByMeta: 'Modèle de tarification des conversations utilisé par Meta',
    whatsappBusinessPlatformConversationsFallInto: 'Les conversations sur la plate-forme commerciale de WhatsApp se répartissent en deux catégories dont le prix est différent',
    userInitiatedConversation: 'Conversation initiée par l`utilisateur',
    userInitiatedConversationDescription: 'Une conversation qui initie en réponse à un message utilisateur. Chaque fois qu`une entreprise répond à un utilisateur dans la fenêtre du service client 24 heures sur 24, ce message sera associé à une conversation initiée par l`utilisateur. Les entreprises peuvent envoyer des messages de forme gratuite dans cette fenêtre de service client de 24 heures.',
    businessInitiatedConversation: 'Conversation initiée par les entreprises',
    businessInitiatedConversationDescription: 'Une conversation qui initie à partir d`une entreprise envoyant à un utilisateur un message en dehors de la fenêtre du service client 24 heures sur 24. Les messages qui lancent une conversation initiée par l`entreprise nécessiteront un modèle de message.',
    areYouSureYouWantTo: 'Êtes-vous sûr de vouloir passer du',
    conversations: ' Conversations',
    plan: ' plan',
    toThe: ' au',
    metaPricingMarkup: 'Meta Pricing Markup',
    numberOfChatFlows: 'Nombre de flux de discussion',
    greenTickApplication: 'Green Tick Application',
    extraPhoneNumberLinking: 'Lien de téléphone supplémentaire',
    teamMembers: 'Membres de l`équipe',
    dataStorage: 'Stockage de données',
    conditionalFlow: 'Flux conditionnel',
    liveChat: 'Chat en direct',
    smartInbox: 'Boîte de réception intelligente',
    whatsappTemplates: 'Modèles WhatsApp',
    customerSegments: 'Groupes de clientèle',
    broadcastAndDripCampaigns: 'Campagnes de diffusion et de goutte',
    multipleLanguages: 'Plusieurs langues',
    bookAppointments: 'Rendez-vous de livre',
    csvContactsExport: 'CSV Contacts Export',
    googleCalendarIntegrations: 'Intégration du calendrier Google',
    nativeApplications: '60+ intégration native',
    whatsappSupport: 'Support WhatsApp',
    emailAndCallSupport: 'Email et assistance des appels',
    customerIntegrations: 'Intégrations client',
    nlpAndNluCapabilities: 'Capacités NLP et NLU',
    chatGPTBot: 'Chatppt chatbot',
    customerSuccessOfficer: 'Officier de réussite client',
    noMarkupTransparency: 'Aucune transparence de balisage',
    upto500kPerMonth: 'Jusqu`à 500 000 par mois',
    beyond500k: 'Au-delà de 500 000',
    unlimited: 'Illimité',
    oneTeamMember: '1 membre de l`équipe',
    unlimitedTeamMember: 'Membres de l`équipe illimités',
    nintyDaysOfDataStorage: '90 jours de stockage de données',
    unlimitedDataStorage: 'Stockage de données illimité',
    detailedPricing: {
      onDemandAuditLog: '\'Journal d\'audit à la demande\'',
      accessRestrictionsBasedOnIPs: '\'Restrictions d\'accès basées sur les adresses IP\'',
      averageAgentResponeTime: '\'Temps de réponse moyen de l\'agent\'',
      perMonth100: '\'100 par mois\'',
      solutionType: 'Type de solution',
      saas: 'SaaS',
      saasOnPremises: 'SaaS sur site',
      emperorPlan: 'Plan Empereur',
      babyPlan: 'Plan Bébé',
      kingsplan: 'Plan Roi',
      braodcastsAndDrips: 'Diffusions et Gouttes',
      thirtyDays: '30 jours',
      ninetyDays: '90 jours',
      oneMember: '1 membre',
      threeMembers: '3 membres',
      one: '1 (Un)',
      unlimitedMessages: 'Messages illimités',
      unlimitedBots: 'Bots illimités',
      messagesSend: 'Messages envoyés',
      upto500000perMonth: '\'Jusqu\'à 500 000 par mois\'',
      unlimited: 'Illimité',
      onlyAdmin: 'Administrateur seulement',
      mb100: '100 Mo',
      gb1: '1 Go',
      doItYourself: 'Faites-le vous-même',
      weAreHereToHelp: 'Nous sommes là pour vous aider',
      weTakeCareEndToEnd: 'Nous prenons soin de tout, de A à Z',
      numberOfBots: 'Nombre de bots',
      conversations: 'Conversations',
      gptMessages: 'Messages GPT',
      whatsappNumbers: 'Numéros WhatsApp',
      subscribersLeads: 'Abonnés/Leads',
      createSupportTeamForLiveChat: 'Créer une équipe de support pour le chat en direct',
      conversationHistory: 'Historique des conversations',
      chatbotDesigning: 'Conception de chatbot',
      multipleLanguages: 'Langues multiples',
      conditionalFlow: 'Flux conditionnel',
      whatsappTemplates: 'Modèles WhatsApp',
      restartChatFlowTriggers: 'Déclencheurs de redémarrage du flux de chat',
      multipleWhatsAppWidgets: 'Widgets WhatsApp multiples',
      importWhatsappContacts: 'Importer des contacts WhatsApp',
      whatsappQuickReply: 'Réponse rapide WhatsApp',
      whatsappSmartInbox: 'Boîte de réception intelligente WhatsApp',
      whatsappEcommerceCatalogs: 'Catalogues de commerce électronique WhatsApp',
      whatsappBroadcast: 'Diffusion WhatsApp',
      whatsappBroadcastAnalytics: 'Analytique de diffusion WhatsApp',
      dripCampaigns: 'Campagnes de goutte à goutte',
      customerSegmentation: 'Segmentation des clients',
      keywordBasedResponse: 'Réponse basée sur les mots-clés',
      whatsappShopManagement: 'Gestion de la boutique WhatsApp',
      aiChatbot: 'Chatbot IA',
      chatGPTIntegration: 'Intégration de ChatGPT',
      configureAIChatbotPersonality: 'Configurer la personnalité du chatbot IA',
      trainChatbotOnYourOwnData: 'Entraîner le chatbot sur vos propres données',
      trainChatbotOnWebPages: 'Entraîner le chatbot sur des pages web',
      trainChatbotOnMultipleFiles: 'Entraîner le chatbot sur plusieurs fichiers',
      trainChatbotOnFAQs: 'Entraîner le chatbot sur des FAQ',
      dataTrainingStorage: '\'Stockage de l\'entraînement des données\'',
      useChatGPTModel4or35Turbo: 'Utiliser le modèle ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: '\'Télécharger plusieurs fichiers pour l\'entraînement du chatbot\'',
      naturalLanguageProcessing: 'Traitement du langage naturel',
      naturalLanguageUnderstanding: 'Compréhension du langage naturel',
      answerLength: 'Longueur de la réponse',
      chatTone: 'Ton de la conversation',
      multiLanguage: 'Multilingue',
      answerFormatting: 'Mise en forme de la réponse',
      customPrompt: 'Invite personnalisée',
      whatsAppSessionManagement: '\'Gestion des sessions WhatsApp (messages d\'inactivité et de redémarrage du flux)\'',
      integrateBotPenguinLLM: 'Intégrer BotPenguin LLM',
      integrateOwnCustomLLM: 'Intégrer LLM personnalisé',
      periodicallySyncDataSources: 'Synchroniser périodiquement les sources de données',
      aiBasedSuggestedQuestions: '\'Questions suggérées basées sur l\'IA\'',
      chooseVisibilityForParticularWebpageSitemap: 'Choisir la visibilité pour une page Web ou un plan de site particulier',
      chooseKnowledgeMix: 'Choisir le mix de connaissances : Votre base de connaissances ChatGPT "Base de connaissances ChatGPT + Votre base de connaissances"',
      faqExtractionFromFileAndURL: '\'Extraction de FAQ à partir de fichiers et d\'URL\'',
      analyticsToImproveAI: '\'Analytique pour améliorer l\'IA\'',
      exportQuestionFlow: 'Exporter le flux de questions',
      collectFeedbackOnChatbotResponses: 'Collecter des commentaires sur les réponses du chatbot',
      smartAIFollowUpQuestions: '\'Questions de suivi intelligentes basées sur l\'IA\'',
      trainOnYourNotionWorkspaceData: 'Entraîner sur les données de votre espace de travail Notion',
      trainOnYoutubeVideo: 'Entraîner sur des vidéos YouTube',
      trainOnCustomVideoUpload: 'Entraîner sur des téléchargements vidéo personnalisés',
      rssFeed: 'Flux RSS',
      googleDrive: 'Google Drive',
      dropBox: 'Dropbox',
      humanHandover: 'Passage en main humaine',
      botHandover: 'Passage en main du bot',
      handoverBetweenAgents: 'Passage en main entre agents',
      emailAndMobileValidation: 'Validation par e-mail et mobile',
      mobileAppsForAgents: 'Applications mobiles pour les agents',
      appointmentBooking: 'Prise de rendez-vous',
      roleBasedAccessControls: '\'Contrôles d\'accès basés sur les rôles\'',
      ruleBasedChatRouting: 'Routage de chat basé sur des règles',
      liveVisitorsDetails: 'Détails des visiteurs en direct',
      multipleSimultaneousChats: 'Chats simultanés multiples',
      visitorStats: 'Statistiques des visiteurs',
      addContactsFromWhatsApp: 'Ajouter des contacts depuis WhatsApp',
      applyFilterAndSortConversations: 'Appliquer des filtres et trier les conversations',
      seeChatLogs: 'Voir les journaux de chat',
      quickReplies: 'Réponses rapides',
      unifiedCustomerProfile: 'Profil client unifié',
      assignNotesAndChatTags: 'Attribuer des notes et des balises de chat',
      exportLeadsInCSV: 'Exporter des leads au format CSV',
      tagContacts: 'Marquer les contacts',
      customAttributes: 'Attributs personnalisés',
      markStatusOfConversations: '\'Marquer l\'état des conversations\'',
      assignConversationToAgents: 'Attribuer la conversation aux agents',
      leadNotificationsByEmail: 'Notifications de leads par e-mail',
      directCallsToLeadsWithTwilio: 'Appels directs aux leads avec Twilio',
      whatsAppTemplates: 'Modèles WhatsApp',
      promotionalMessageFrequencyControl: 'Contrôle de la fréquence des messages promotionnels',
      whatsAppWidget: 'Widget WhatsApp',
      whatsAppQR: 'Code QR WhatsApp',
      whatsAppLink: 'Lien WhatsApp',
      campaignScheduler: 'Planificateur de campagne',
      customTriggers: 'Déclencheurs personnalisés',
      customCampaigns: 'Campagnes personnalisées',
      repeatPurchaseReminders: '\'Rappels d\'achat répété\'',
      campaignOpenRateTracking: '\'Suivi du taux d\'ouverture de la campagne\'',
      paymentCollectionOnWhatsApp: 'Collecte de paiements sur WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Fermer',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'FrontApp',
      webhoper: 'Webhoper',
      engagebay: 'EngageBay',
      helpshift: 'Helpshift',
      copper: 'Cuivre',
      autopilot: 'Autopilote',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Système de billetterie de support',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Appel audio et vidéo dans le chat (Twilio/Agora)',
      courierTracking: 'Suivi du coursier',
      emailIntegration: 'Intégration de messagerie électronique',
      webhooks: 'Webhooks',
      schedulers: 'Planificateurs',
      paymentGateways: 'Passerelles de paiement (Stripe et RazorPay)',
      enterpriseIntegrations: '\'Intégrations d\'entreprise\'',
      customIntegration: 'Intégration personnalisée',
      freeWhatsAppAPIApprovalAndSetup: '\'Approbation et configuration gratuite de l\'API WhatsApp\'',
      developerAPIsAccess: 'Accès aux API de développeur',
      customRateLimit: 'Limite de taux personnalisée',
      totalNumberOfLeads: 'Nombre total de leads',
      averageAgentResponseTime: '\'Temps de réponse moyen de l\'agent\'',
      visitorTimingReport: 'Rapport sur la chronologie des visiteurs',
      customerSegmentBasedReports: 'Rapports basés sur le segment de client',
      tagBasedReports: 'Rapports basés sur les tags',
      leadGenerationReport: 'Rapport sur la génération de leads',
      supportOnWhatsApp: 'Support sur WhatsApp',
      knowledgeBase: 'Base de connaissances',
      onboardingAndTraining: 'Intégration et formation',
      callAndEmailSupport: 'Assistance téléphonique et par e-mail',
      freeChatbotCreation: 'Création de chatbot gratuite',
      customerSuccessOfficer: 'Responsable de la réussite client',
      secureConnection: 'Connexion sécurisée 2048 bits SSL',
      securityAssessment: 'Évaluation de la sécurité',
      dataCenterLocation: 'Emplacement du centre de données (États-Unis ou UE)',
      twoStepVerification: 'Vérification en deux étapes',
      accessRestrictions: '\'Restrictions d\'accès\'',
      auditLog: '\'Journal d\'audit à la demande\'',
      bannedVisitorsList: 'Liste des visiteurs bannis',
      customAPIRate: '\'Taux personnalisé de l\'API\'',
      trustedDomains: 'Domaines de confiance',
      elementary: 'Élémentaire',
      bots: 'Bots',
      unlockWhatsapp: 'Débloquer WhatsApp',
      aiChatbotFeatures: 'Fonctionnalités du chatbot IA',
      dataSourceForTraining: 'Source de données pour la formation',
      smartFeatures: 'Fonctionnalités intelligentes',
      liveChat: 'Chat en direct',
      smartInbox: 'Boîte de réception intelligente',
      broadcastsAndDrips: 'Diffusions et gouttes',
      integrations: 'Intégrations',
      development: 'Développement',
      reportsAndAnalytics: 'Rapports et analyses',
      customerSupport: 'Support client',
      security: 'Sécurité',
      whatsappWidgets: 'Widgets WhatsApp',
      campaignSchedular: 'Planificateur de campagne',
      repeatPurchaseRemainder: '\'Rappels d\'achat répété\'',
    },


    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Notre plan gratuit',
      TOOLTIP_KING_PLAN: 'Notre plan le plus populaire',
      TOOLTIP_EMPEROR_PLAN: 'Plan personnalisé pour les messages illimités, les données illimitées, les membres de l`équipe illimités',
      TOOLTIP_CONTACT_US: 'Atteignez-nous via WhatsApp ou envoyez un courriel à contact@botpenguin.com pour en savoir plus sur notre plan personnalisé',
      TOOLTIP_UPGRADE: 'Mettez à niveau votre plan actuel pour les fonctionnalités supplémentaires',
      TOOLTIP_MONTHLY: 'Sélectionnez un cycle de paiement mensuel',
      TOOLTIP_YEARLY: 'Sélectionnez un cycle de paiement annuel pour les prestations de réduction',
    }
  }
};
