export const whitelabel = {
  plans: 'Piani',
  managePlan: 'Gestire i piani',
  whitelabelCharges: 'Accuse di whitelabel',
  chargesOrTaxesConfiguration: 'Configurazione addebiti/tasse',
  websitePlans: 'Piani del sito web',
  whatsappPlansHeader: 'Piani di whatsapp',
  addOnPlans: {
    createAddOnPlans: 'Crea piani aggiuntivi',
    checkoutYourCurrentMessage: 'Dai un`occhiata ai tuoi attuali piani aggiuntivi del messaggio e nuovi piani per i tuoi clienti',
    choosePlanValidity: 'Scegli la validità del piano',
    chooseValidity: 'Scegli la validità',
    oneYear: 'Un anno',
    billingCycle: 'Ciclo di fatturazione',
    numberOfMessages: 'Numero di messaggi',
    pleaseEnterNumberOfMessages: 'Inserisci il numero di messaggi',
    setCurrency: 'Imposta valuta',
    select: 'Selezionare',
    inr: 'INR',
    usd: 'Dollaro statunitense',
    cad: 'CAD',
    clearAll: 'Cancella tutto',
    apply: 'Fare domanda a',
    currency: 'Valuta',
    amount: 'Quantità',
    validity: 'Validità',
    upgradePlan: 'Piano di aggiornamento',
    addPlan: 'Aggiungi piano',
    serialNumber: 'Sr. No.',
    messages: 'Messaggi',
    // validity: 'Validità',
    active: 'Attivo',
    priceDetails: 'Dettagli del prezzo',
    action: 'Azione',
    addOnChanges: 'Accendi aggiuntivi:',
    // messages: ' Messaggi',
    perYear: 'per anno',
    taxes: 'Le tasse',
    placeholders: {
      enterNumberOfMesasges: 'Immettere il numero di messaggi',
      enterAmount: 'Inserire l`importo',
      enterValidity: 'Immettere la validità',
      searchByMessages: 'Cerca tramite messaggi',
    },
    modal: {
      deleteAddOnsPlan: 'Elimina il piano dei componenti aggiuntivi',
      wantToDeleteThisAddOnsPlan: 'Sei sicuro di voler eliminare questo piano per componenti aggiuntivi?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Aggiungi le spese di Whitelabel',
    checkoutExistingCharges: 'Dai un`occhiata ad addebiti esistenti su diversi metodi di pagamento e aggiungi nuovi addebiti di Whitelabel',
    currency: 'Valuta',
    chooseCurrency: 'Scegli Valuta',
    amountPerBotPerMonth: 'Importo per bot al mese',
    cancel: 'Annulla',
    upgradeChanges: 'Aggiorna addebiti',
    addCharges: 'Aggiungi addebiti',
    serialNumber: 'Sr. No.',
    // currency: 'Valuta',
    action: 'Azione',
    taxes: 'Le tasse',
    placeholders: {
      enterAmountBotPerMonth: 'Inserisci l`importo bot al mese',
      searchByCurrency: 'Cerca per valuta',
    },
    modal: {
      deleteCharges: 'Elimina le spese',
      wantToDeleteThisCharge: 'Sei sicuro di voler eliminare questa carica?',
    }
  },
  planManagement: {
    createNewPlans: 'Crea nuovi piani',
    checkoutYourCurrentPlan: 'Dai un`occhiata ai tuoi piani attuali e aggiungi nuovi piani per i tuoi clienti',
    choosePlan: 'Scegli il piano',
    selectPlan: 'Seleziona Piano',
    baby: 'Bambino',
    king: 'Re',
    displayName: 'Nome da visualizzare',
    messageLimit: 'Limite di messaggio',
    enterAValidEmail: 'Inserisci un`e -mail valida',
    setCurrency: 'Imposta valuta',
    select: 'Selezionare',
    inr: 'INR',
    usd: 'Dollaro statunitense',
    cad: 'CAD',
    clearAll: 'Cancella tutto',
    apply: 'Fare domanda a',
    perMonth: '/Mese',
    perYear: '/Anno',
    cancel: 'Annulla',
    upgradePlan: 'Piano di aggiornamento',
    addPlan: 'Aggiungi piano',
    serialNumber: 'Sr. No.',
    plan: ' Piano',
    // displayName: 'Nome da visualizzare',
    messages: 'Messaggi',
    pricing: 'Prezzi',
    actions: 'Azioni',
    // plan: ' Piano',
    perMonthText: 'Al mese',
    perYearText: 'Per anno',
    placeholders: {
      enterDisplayName: 'Immettere il nome di visualizzazione',
      enterMessageLimit: 'Immettere il limite dei messaggi',
      searchByPlanName: 'Cerca per Nome del Piano o Messaggi'
    },
    modal: {
      deletePlan: 'Elimina Piano',
      wantToDeleteThisBabyPlan: 'L\'eliminazione del Piano Baby avrà un impatto su tutti i clienti. Sei sicuro di voler eliminare questo piano?',
      wantToDeleteThisPlan: 'Sei sicuro di voler eliminare questo piano?',
    },
    appTooltip: {
      chooseYourPlanType: 'Scegli il tipo di pianificazione',
      pleaseInputYourPlanDisplayName: 'Inserisci il nome visualizzato del tuo piano',
      typeMessageLimitForCustomer: 'Digita il limite dei messaggi per il cliente',
      selectYourPreferredCurrencies: 'Seleziona le tue valute preferite',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Aggiungi tasse',
    checkoutExistingTaxes: 'Controlla le tasse esistenti su diversi metodi di pagamento e nuove tasse',
    taxName: 'Nome fiscale',
    applicableOn: 'Applicabile su',
    choosePlans: 'Scegli i piani',
    chooseCurrency: 'Scegli Valuta',
    percentage: 'Percentuale',
    cancel: 'Annulla',
    // addTaxes: 'Aggiungi tasse',
    serialNumber: 'Sr. No.',
    name: 'nome',
    // percentage: 'Percentuale',
    currency: 'Valuta',
    updateTaxes: 'Aggiorna le tasse',
    actions: 'Azioni',
    modal: {
      deleteTaxes: 'Elimina le tasse',
      wantToDeleteThisTax: 'Sei sicuro di voler eliminare queste tasse?',
    },
    placeholders: {
      taxName: 'Nome fiscale',
      taxPercentage: 'Percentuale fiscale',
      searchByCurrency: 'Cerca per valuta',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Crea nuovi piani',
    checkoutYourCurrentAndNewPlans: 'Dai un`occhiata ai tuoi piani attuali e aggiungi nuovi piani per i tuoi clienti',
    choosePlan: 'Scegli il piano',
    selectPlan: 'Seleziona Piano',
    baby: 'Bambino',
    king: 'Re',
    displayName: 'Nome da visualizzare',
    messageLimit: 'Limite Conversazione',
    enterAValidMessageLimit: 'Inserisci un limite di conversazione valido',
    messages: 'Conversazioni',
    setCurrency: 'Imposta valuta',
    select: 'Selezionare',
    inr: 'INR',
    usd: 'Dollaro statunitense',
    cad: 'CAD',
    clearAll: 'Cancella tutto',
    apply: 'Fare domanda a',
    perMonth: '/Mese',
    perYear: '/Anno',
    cancel: 'Annulla',
    addPlan: 'Aggiungi piano',
    serialNumber: 'Sr. No.',
    plan: 'Piano',
    pricing: 'Prezzi',
    actions: 'Azioni',
    perMonthText: 'Al mese',
    perYearText: 'Per anno',
    updatePlan: 'Piano di aggiornamento',
    // addPlan: 'Aggiungi piano',
    placeholders: {
      enterDisplayName: 'Immettere il nome di visualizzazione',
      enterMessageLimit: 'Inserisci il limite di conversazione',
      searchByPlanName: 'Cerca per Nome del Piano o Conversazioni',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Inserisci il limite di conversazione per il cliente',
      chooseYourPlanType: 'Scegli il tipo di pianificazione',
      selectYourPreferredCurrencies: 'Seleziona le tue valute preferite',
      pleaseInputYourPlanDisplayName: 'Inserisci il nome visualizzato del tuo piano',
    },
    modal: {
      deletePlan: 'Elimina Piano',
      wantToDeleteThisBabyPlan: 'L\'eliminazione del Piano Baby avrà un impatto su tutti i clienti. Sei sicuro di voler eliminare questo piano?',
      areYouSureYouWantToDeleteThisPlan: 'Sei sicuro di voler eliminare questo piano?',
    }
  }
};
