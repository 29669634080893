import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { LanguageService } from 'src/appv2/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  staticText: any;
  environment = environment;
  formData: any = {
    email: '',
    _agency: '',
  };
  agencyMeta: {
    _id: string;
    name: string;
    logo: string;
  };
  disableLogin: any = false;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().authentication.forgotPassword;
    this.checkAgency();
  }

  ngOnInit(): void {
  }

  get ifRootAgency(): boolean {
    return environment.rootAgencyDomain.includes(window.location.host);
  }

  checkAgency() {

    if (environment.rootAgencyDomain.includes(window.location.host)) {

      this.agencyMeta = {
        _id: environment.rootAgencyId,
        name: 'BotPenguin',
        logo: '/assets/images/logo-v2.svg'
      };
      this.formData._agency = this.agencyMeta._id;
      return true;
    } else {
      this.sharedService.loader('start');
      this.authService.getAgencyInfo(window.location.host)
        .then(data => {

          this.agencyMeta = {
            _id: data._id,
            name: data.name,
            logo: data.meta.logo
          };
          this.formData._agency = this.agencyMeta._id;
        })
        .catch(error => console.log(error))
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  handleChange(e) {
    this.formData[e.target.name] = (e.target.value || '')
      .trim()
      .toLowerCase();
  }

  handleSignUpBtn() {
    this.authService.signUpPage();
  }

  reset() {
    if (!this.formData.email) {
      return this.sharedService.showToast('warning', '', 'Your registered email is required to reset your password');
    }

    this.disableLogin = true;
    this.authService.forgetPassword(this.formData)
      .then((res: any) => {
        this.sharedService.showToast('success', '', 'We have sent you an email with the link to reset your password');

        localStorage.setItem('email', this.formData.email);
        return this.router.navigate(['/confirm-email']);
      })
      .catch(err => {
        return this.sharedService.showToast('error', '', err.match(new RegExp('no user', 'gi'))
          ? 'This provided email is not registered with us'
          : err);
      });
  }

}
