import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  profileType: string;

  constructor(
    private router: Router
  ) {
    const encodedPayload = localStorage.getItem('token')?.split('.')[1];
    this.profileType = encodedPayload ? JSON.parse(atob(encodedPayload))?.type : '';
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (!localStorage.getItem('token')) {
      sessionStorage.setItem('lastUrl', location.pathname);
      this.router.navigate(['/login']);
      return false;
    } else if (state.url === '/home' && !localStorage.getItem('impersonate') && this.profileType === 'AGENCY') {
      this.router.navigate(['/agency/dashboard']);
      return false;
    }
    return true;

  }

  canLoad() {
    if (!localStorage.getItem('token')) {
      sessionStorage.setItem('lastUrl', location.pathname);
      this.router.navigate(['/login']);

      return false;
    }
    return true;

  }

}
