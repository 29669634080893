import { TitleCasePipe } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTitleCase]'
})
export class TitleCaseDirective {

  @HostListener('ngModelChange', ['$event'])
  handler(value) {
    const titleCaseStr = this.titleCase.transform(value);
    this.ngControl.valueAccessor.writeValue(titleCaseStr);
    const validIcon = (this.elRef.nativeElement as HTMLInputElement).nextElementSibling;
    const errorIcon = validIcon.nextElementSibling;
    const errorMessage = errorIcon?.nextElementSibling;

    if (value) {
      validIcon.classList.add('d-block');
      errorIcon.classList.remove('d-block');
      errorMessage.classList.remove('d-block');
      this.elRef.nativeElement.style.border = '1px solid #dfdfdf';
    } else {
      validIcon.classList.remove('d-block');
      errorIcon.classList.add('d-block');
      errorMessage.classList.add('d-block');
      this.elRef.nativeElement.style.border = '1px solid #AA263F';

    }
  }

  constructor(
    public ngControl: NgControl,
    public titleCase: TitleCasePipe,
    public elRef: ElementRef
  ) {

  }

}
