import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  staticText: any;
  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().authentication.logout;
   }

  ngOnInit(): void {
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('impersonate');
    localStorage.removeItem('bp-is-agency');

    this.router.navigate(['/login']);
  }
}
