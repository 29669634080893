export const subscription = {
  subscriptions: 'Subscriptions',
  plan: 'Plan',
  whatsappPlan: 'WhatsApp Plan',
  addOns: 'Add-ons',
  billingDetails: 'Billing Details',
  invoices: 'Invoices',
  wa1Reminder: 'WA 1st reminder',
  wa2Reminder: 'WA 2nd reminder',
  waRenewApi: 'WA Renew API',
  waCheckUsageApi: 'WA check usage API',
  waResetUsageApi: 'WA Reset Usage API',
  waExceedUsageApi: 'WA Exceed Usage API',
  waGetBotsInThisAccount: 'WA Get bots in this account',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'We have the most affordable prices for all of your needs.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'Whatsapp plan',
    TOOLTIP_ADDS_ON_HEADING: 'Buy additional massages packs and whitelabel bot',
    TOOLTIP_ADDS_ON_MESSAGE: 'If you\'ve reached your monthly message limit, you can buy additional packs.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Check your current subscription details',
    TOOLTIP_HORIZONTAL_BAR: 'Check our monthly or yearly pricing from 3K to 500K messages by moving the bar left to right.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Update your billing details for invoicing.',
    TOOLTIP_INVOICES_HEADING: 'Check your previous invoices.',
    TOOLTIP_INVOICES_ACTION: 'Click to receive the invoice in your email account'
  },
  currentPlan: {
    seeHowToUse: 'See how to use',
    help: 'Help',
    downgradeRequest1: 'You have placed a request to downgrade your plan to',
    downgradeRequest2: 'messages. Please note, Your plan will be downgraded by the end of the current billing cycle',
    downgradeRequest3: 'You may enjoy the',
    downgradeRequest4: 'services till then.',
    downgradeRequestMaster1: 'You have placed a request to downgrade your ',
    downgradeRequestMaster2: ' plan to',
    downgradeRequestMaster3: ' conversations. Please note, Your plan will be downgraded by the end of the current billing cycle',
    downgradeRequestMaster4: 'You may enjoy the ',
    downgradeRequestMaster5: 'services till then.',
    active: 'Active',
    inactive: 'Inactive',
    month: 'month',
    year: 'year',
    messagePack: 'Message Pack',
    king: 'King',
    baby: 'Baby',
    whatsapp: 'WhatsApp',
    youAreOn: 'You are on',
    plan: 'plan',
    totalMessagesLimit: 'Total Messages limit',
    liveAgentLimit: 'Live Agent limit',
    perMonth: '/month',
    agents: 'agent(s)',
    messagesAddOn: 'Messages Add-on',
    botWhitelabels: 'Bot Whitelabels',
    totalAddOnMessages: 'Total Add-on Messages',
    basePrice: 'Base price',
    gst: 'GST',
    totalPrice: 'Total price',
    totalAddOnPrice: 'Total Add-on price',
    whitelabelPrice: 'Whitelabel price',
    startedOn: 'Started on',
    nextDueOn: 'Next due on',
    addOnExpiry: 'Add-on Expiry',
    website: 'Website',
    facebook: 'Facebook',
    telegram: 'Telegram',
  },
  invoice: {
    invoiceNumber: 'Invoice No.',
    date: 'Date',
    invoiceAmount: 'Invoice Amount',
    planAmount: 'Plan Amount',
    plan: 'Plan',
    messages: 'Messages',
    action: 'Action',
    nA: 'NA',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Click to receive the invoice in your email account',
      clickToDownloadInvoice: 'Click to download Invoice in PDF form',
    }
  },
  messageAddOn: {
    validity: 'Validity',
    kingPlanHeader: '👑 King Plan',
    youAreOnThe: 'You are on the ',
    asOfNowAndWillGet: 'as of now, and will get ',
    messages: 'messages ',
    inThisPlan: 'in this plan. ',
    youCanBuyAdditionalMessaes: 'You can buy Additional messages as per your business requirements.',
    plan: ' Plan',
    baby: 'Baby',
    king: 'King',
    get: 'Get ',
    babyPlan: 'Baby Plan ',
    upgradePlan: 'Upgrade Plan',
    asOfNow: ' as of now.',
    pleaseUpgradeToKingPlan: 'Please upgrade to ',
    toEnableAddOnsAndWhitelabelPacks: ' to enable Add-ons & Whitelabel Packs.',
    messageAddOnPacks: 'Messages Add-on Packs',
    ifYouHaveReachedYourMonthlyMessageLimit: `If you've reached your monthly message limit, buy additional packs and enjoy uninterrupted services.`,
    pleaseSelectNumberOfExpectedMessages: 'Please select the number of expected messages',
    addOnsValidity: 'Add-ons Validity',
    currentBillingCycle: 'Current Billing Cycle',
    oneYear: 'One Year',
    summary: 'Summary',
    messageCreditsWithKingPlan: 'message credits with king plan',
    messageCreditsWith: 'message credits with ',
    withPlan: '',
    billingCycle: 'Billing Cycle',
    payableAmount: 'Payable Amount ',
    oneTimePayment: 'One time Payment',
    plusTaxes: ' + Taxes',
    buyNow: 'Buy Now',
    whiteLabelChatWindow: 'White Label Chat Window',
    noBotsLeftToWhiteLabel: 'No Bots left to Whitelabel.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Please select the bots you want to Whitelabel.',
    selectBots: 'Select Bots',
    bot: 'Bot',
    bots: 'Bots',
    toBeWhiteLabelled: ' to be WhiteLabelled',
    remove: 'Remove ',
    brandNameFromChatWindow: ' brandname from the chat window.',
    willBeValidTill: 'Will be valid till ',
    ourYearlyPrice: 'Our yearly price ',
    directWhiteLabel: 'Direct WhiteLabel',
    whiteLabelledBots: 'WhiteLabelled Bots',
    nextRenewalColon: 'Next Renewal: ',
    optOutRequested: 'Opt Out Requested',
    contactAgency: 'Contact Agency',
    optOut: 'Opt Out',
    removeWhiteLabel: 'Remove WhiteLabel',
    messageCount: 'Messages',
    numberOfPacks: 'No. of Packs',
    amount: 'Amount',
    taxes: 'Taxes',
    totalAmount: 'Total Amount',
    numOfBots: 'No. of Bots',
    select: 'Select',
    selectTheValidity: 'Select the validity',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Payment Details',
    cardNumber: 'Card Number ',
    cvv: 'CVV ',
    // cardNumber: 'Card Number',
    month: 'Month',
    year: 'Year',
    save: 'Save',
    add: 'Add',
    edit: 'Edit',
    disclaimer: 'Disclaimer: ',
    disclaimerDescription1: 'The above payment method details will be only applicable to users outside India.',
    disclaimerDescription2: 'We are not saving your card details with us.' +
      'In case, you want to change your card details it will be directly changed with payment gateway.',
    billingAddress: 'Billing Address',
    companyName: 'Company Name ',
    country: 'Country ',
    selectCountry: 'Select Country',
    stateOrRegion: 'State/Region ',
    streetAddress: 'Street Address ',
    gstIn: 'GSTIN ',
    postalCode: 'Postal Code ',
    cancel: 'Cancel',
    proceedToPay: 'Proceed To Pay',
    company: 'Company',
    state: 'State',
    masterCard: 'Master Card',
    yourCompanyName: 'Your Company Name',
    yourCountry: 'Your Country',
    yourRegion: 'Your Region',
    yourPostalCode: 'Your Postal Code',
    plusTaxes: ' + taxes',
    dropDown: {
      month: 'Month *',
      year: 'Year *',
    },
    placeholders: {
      enterCardNumber: 'Enter card number',
      cvv: 'CVV',
      enterYourCompanyName: 'Enter your company name',
      enterYourStateOrRegion: 'Enter your state/region',
      enterYourStreetAddress: 'Enter your street address',
      enterYourGSTIN: 'Enter your GSTIN',
      enterPostalCode: 'Enter postal code',
    }
  },
  plans: {
    viewDetailedPricing: 'View Detailed Pricing',
    seeHowToUse: 'See how to use',
    help: 'Help',
    weHaveGotAPricingPlanPerfectForYou: `We've got a pricing plan that's perfect for you`,
    weBelieve: 'We believe ',
    shouldBeAccessibleToAllCompanies: ' should be accessible to all companies, not matter the size.',
    monthly: 'Monthly',
    yearly: 'Yearly',
    twoMonthsFree: '(2 Months FREE)',
    babyPlan: 'Baby Plan',
    freeForever: 'FREE Forever',
    messagesPerMonth: 'Messages per month',
    currentPlan: 'Current Plan',
    freePlan: 'FREE Plan',
    kingPlan: 'King Plan',
    perYear: 'per year',
    plan: 'plan',
    toThe: ' to the',
    plusTaxes: ' + Taxes',
    perMonth: 'per month',
    upgradePlan: 'Upgrade Plan',
    changePlan: 'Change Plan',
    currentlyActive: 'Currently Active',
    emperorPlan: 'Emperor Plan (Enterprise)',
    sendAsManyMessagesAsYouWant: 'Send as many messages as you need',
    contactUs: 'Contact us',
    features: 'Features',
    areYouSureYouWantToShiftFrom: 'Are you sure you want to shift from the',
    messages: 'Messages',
    planQuestionMark: 'plan?',
    downgradeToFreePlan: 'Downgrade To FREE Plan',
    goToBabyPlan: 'Go To Baby Plan',
    thankYouForChoosingBotPenguin: 'Thank you for choosing BotPenguin',
    bots: 'Bots',
    facebookPages: 'Facebook pages',
    teamMember: 'Team member',
    dataStorage: 'Data storage',
    conditionalFlow: 'Conditional Flow',
    liveChat: 'Live Chat',
    bookAppointments: 'Book Appointments',
    csvExport: 'CSV Export',
    emailAndCallSupport: 'Email and Call Support',
    googleCalendarIntegrations: 'Google Calendar Integration',
    zohoCrmIntegrations: 'Zoho CRM Integration',
    reportsAndAnalytics: 'Reports and Analytics',
    removeBotPenguinBranding: 'Remove BotPenguin Branding',
    oneBot: '1 Bot',
    twoKMessagesPerMonth: '2k messages/month',
    oneFacebookPage: '1 Facebook Page',
    oneTeamMember: '1 Team Member',
    thirtyDaysOfDataStorage: '30 days of data storage',
    unlimitedBot: 'Unlimited Bot',
    threeTo500kMessagesPerMonth: '3k to 500k messages/month',
    tenFacebookPages: '10 Facebook Pages',
    threeTeamMembers: '3 Team Members',
    nintyDaysOfDataStorage: '90 days of data storage',
    unlimitedMessages: 'Unlimited messages',
    unlimitedFacebookPages: 'Unlimited Facebook Pages',
    unlimitedTeamMembers: 'Unlimited Team Members',
    unlimitedDataStorage: 'Unlimited data storage',
    youAreHere: `You are here!`,
    detailedPricing: {
      kingsplan: 'King Plan',
      elementary: 'Elementary',
      solutionType: 'Solution Type',
      saas: 'SaaS',
      fbpage1fb: '1 FB Page',
      perMonth100: '100 per month',
      enterprise: 'Enterprise',
      saasOnPremises: 'SaaS On Premises',
      aiChatbotFeatures: 'AI Chatbot Features',
      dataSourceForTraning: 'Data source for training',
      smartFeatures: 'Smart Features',
      liveChat: 'Live Chat',
      day30: '30 days',
      day90: '90 days',
      landSquared: 'Landsquared',
      doItYourself: 'Do it yourself',
      unifiedInbox: 'Unified Inbox',
      platforms: 'Platforms',
      websiteChatbot: 'Website Chatbot',
      FacebookMessengerChatbot: 'Facebook Messenger Chatbot',
      Telegram: 'Telegram',
      conversationalLandingPage: 'Conversational Landing Page ',
      integrations: 'Integrations',
      reportsAndAnalytics: 'Reports and Analytics',
      customerSupport: 'Customer Support',
      security: 'Security',
      bots: 'Bots',
      babyPlan: 'Baby Plan',
      kingPlan: 'King Plan',
      emperorPlan: 'Emperor Plan',
      noofBots: 'No. of bots',
      one: '1 (One)',
      unlimitedBots: 'Unlimited Bots',
      noOfWebsite: 'No. of websites',
      unlimitedWebsites: 'Unlimited websites',
      unlimitedMessages: 'Unlimited Messages',
      messagesSend: 'Messages Send',
      perMonth2000: '2,000 per month',
      upto500000perMonth: 'Upto 500,000 per month',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Unlimited Pages',
      telegramNumbers: 'Telegram Numbers',
      unlimited: 'Unlimited',
      whatsappNumbers: 'WhatsApp Numbers',
      subscribersLeads: 'Subscribers/Leads',
      createSupportTeam: 'Create Support Team for Live chat',
      oneMember: '1 Member',
      threeMembers: '3 members',
      conversationHistory: 'Conversation History',
      thirtyDays: '30 Days',
      ninetyDays: '90 Days',
      chatbotDesigning: 'Chatbot designing',
      weAreHereToHelp: 'We are here to help',
      weTakeCareEndToEnd: 'We take care end to end',
      multipleLanguages: 'Multiple Languages',
      conditionalFlow: 'Conditional Flow',
      removeBotPenguinBranding: 'Remove BotPenguin Branding',
      gptMessages: 'GPT Messages',
      aiChatbot: 'AI Chatbot',
      chatGPTIntegration: 'ChatGPT Integration',
      configureAIChatbotPersonality: 'Configure AI Chatbot personality',
      trainChatbotOnYourOwnData: 'Train Chatbot on your own data',
      trainChatbotOnWebPages: 'Train Chatbot on web pages',
      trainChatbotOnMultipleFiles: 'Train Chatbot on multiple files',
      trainChatbotOnFAQs: 'Train Chatbot on FAQs',
      dataTrainingStorage: 'Data training storage',
      useChatGPTModel4_3_5Turbo: 'Use ChatGPT Model 4/3.5 turbo',
      uploadMultipleFilesForChatbotTraining: 'Upload multiple files for chatbot training',
      naturalLanguageProcessing: 'Natural Language Processing',
      naturalLanguageUnderstanding: 'Natural Language Understanding',
      answerLength: 'Answer length',
      chatTone: 'Chat Tone',
      multiLanguage: 'Multi-Language',
      answerFormatting: 'Answer Formatting',
      customPrompt: 'Custom Prompt',
      integrateBotPenguinLLM: 'Integrate BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrate Own/Custom LLM',
      periodicallySyncDataSources: 'Periodically Sync data sources',
      aiBasedSuggestedQuestions: 'AI based Suggested questions',
      chooseVisibilityForParticularWebpageSitemap: 'Choose visibility for particular webpage/sitemap',
      chooseKnowledgeMix: 'Choose Knowledge Mix:\nYour Knowledge Base\nChatGPT knowledge\n"ChatGPT knowledge + Your Knowledge Base"',
      faqExtractionFromFileAndURL: 'FAQ extraction from File and URL',
      analyticsToImproveAI: 'Analytics to improve AI',
      exportQuestionFlow: 'Export Question flow',
      sessionManagement: 'Session Management',
      collectFeedbackOnChatbotResponses: 'Collect feedback on Chatbot responses',
      smartAIFollowUpQuestions: 'Smart/AI Follow up questions',
      trainOnYourNotionWorkspaceData: 'Train on your Notion workspace data',
      trainOnYoutubeVideo: 'Train on Youtube video',
      trainOnCustomVideoUpload: 'Train on custom video upload',
      rssFeed: 'RSS Feed',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'IP and Region Blocking',
      emailAndMobileValidation: 'Email and Mobile Validation',
      appointmentsBooking: 'Appointments Booking',
      humanHandover: 'Human Handover',
      botHandover: 'Bot Handover',
      handoverBetweenAgents: 'Handover between Agents',
      mobileAppsForAgents: 'Mobile Apps for agents',
      roleBasedAccessControls: 'Role-based Access Controls',
      apiIntegrationOnChatFlows: 'API Integration on Chat Flows',
      liveChatWidget: 'Live Chat Widget',
      typingIndicator: 'Typing Indicator',
      liveVisitorsDetails: 'Live Visitors details',
      multipleSimultaneousChat: 'Multiple simultanaeous chat',
      liveTypingView: 'Live Typing View',
      ruleBasedChatRouting: 'Rule based Chat Routing',
      chatAutoAssignments: 'Chat Auto-assignments',
      efficientOmnichannelInterface: 'Efficient Omnichannel Interface',
      quickReplies: 'Quick Replies',
      unifiedCustomerProfile: 'Unified Customer Profile',
      visitorStats: 'Visitor stats',
      addContactsFromWhatsapp: 'Add Contacts from Whatsapp',
      applyFilterAndSortConversations: 'Apply filter and sort conversations',
      seeChatLogs: 'See chat logs',
      assignNotesAndChatTags: 'Assign Notes and Chat Tags',
      directCallsToLeadsWithTwilio: 'Direct Calls-to-leads (with Twilio)',
      customerSegmentation: 'Customer Segmentation',
      exportLeadsInCSV: 'Export Leads in CSV',
      leadNotificationsByEmail: 'Lead Notifications by email',
      website: 'Website',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegram',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Auto-triggering on mobile/desktop',
      hideBotOnSpecificPages: 'Hide bot on specific pages',
      imageSharing: 'Image Sharing',
      readyToUseChatFlowTemplates: 'Ready to use Chat flow Templates',
      fileSharing: 'File Sharing',
      customDesignForChatWindow: 'Custom design for Chat window',
      facebookMessengerBot: 'Facebook Messenger Bot',
      facebookSubheading: 'facebooksubheading',
      telegramSubheading: 'telegramsubheading',
      conversationalLangdingPageSubheading: 'conversational landing page subheading',
      integrationsSubheading: 'integrationssubheading',
      multipleSmartFlows: 'Multiple Smart Flows',
      dripCampaigns: 'Drip campaigns',
      broadcastFBMessages: 'Broadcast FB Messages',
      multipleFacebookBotWidgets: 'Multiple Facebook bot Widgets',
      facebookAutoReplyOnComments: 'Facebook Auto-Reply on Comments',
      facebookECommerce: 'Facebook E-commerce',
      broadcastAnalytics: 'Broadcast Analytics',
      telegramMessengerBot: 'Telegram Messenger Bot',
      readyToUseTemplates: 'Ready to use Templates',
      groupBroadcastTelegramMessages: 'Group & Broadcast Telegram Messages',
      multipleTelegramBotWidgets: 'Multiple Telegram bot Widgets',
      conversationalLandingPageBot: 'Conversational Landing Page Bot',
      smartReplies: 'Smart Replies',
      interactiveAndCustomizableUI: 'Interactive and Customizable UI',
      leadManagement: 'Lead management',
      easyToInstallAndShare: 'Easy to install and Share',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'Leadsquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Close',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Support Ticketing System',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'In-chat audio and video call (Twilio/Agora)',
      courierTracking: 'Courier Tracking',
      emailIntegration: 'Email Integration',
      webhooks: 'Webhooks',
      schedulers: 'Schedulers',
      paymentGateways: 'Payment Gateways (Stripe & RazorPay)',
      enterpriseIntegrations: 'Enterprise Integrations',
      customIntegration: 'Custom Integration',
      shopifyIntegration: 'Shopify Integration',
      totalNumberOfMessages: 'Total Number of Messages',
      totalNumberOfLeads: 'Total Number of Leads',
      weeklyReportBotwise: 'Weekly Report, Botwise',
      averageAgentResponeTime: 'Average agent respone time',
      visitorTimingReport: 'Visitor Timing Report',
      customerSegmentBasedReports: 'Customer Segment-based Reports',
      tagBasedReports: 'Tag-based Reports',
      leadGenerationReport: 'Lead Generation Report',
      supportOnWhatsApp: 'Support on WhatsApp',
      knowledgeBase: 'Knowledge Base',
      onboardingAndTraining: 'Onboarding and Training',
      callAndEmailSupport: 'Call and Email Support',
      freeChatbotCreation: 'FREE chatbot creation',
      customerSuccessOfficer: 'Customer Success Officer',
      secureConnection2048BitSSL: '2048 bit SSL secure connection',
      securityAssessment: 'Security Assessment',
      dataCenterInUSOrEU: 'Data center in US or EU',
      twoStepVerification: '2 step verification',
      accessRestrictionsBasedOnIPs: 'Access resctrictions based on IPs',
      onDemandAuditLog: 'On demand Audit log',
      bannedVisitorsList: 'Banned visitors list',
      customAPIRate: 'Custom API rate',
      trustedDomains: 'Trusted domains',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Coding Curious',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Our free plan',
      TOOLTIP_KING_PLAN: 'Our most popular plan',
      TOOLTIP_EMPEROR_PLAN: 'Customized plan for unlimited messages, unlimited data, unlimited team members',
      TOOLTIP_CONTACT_US: 'Reach to us via WhatsApp or email at contact@botpenguin.com to learn more about our customized plan',
      TOOLTIP_UPGRADE: 'Upgrade your current plan for additional features',
      TOOLTIP_MONTHLY: 'Select a monthly payment cycle',
      TOOLTIP_YEARLY: 'Select a yearly payment cycle for discount benefits',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Go To Baby Plan',
    freeForever: 'FREE Forever',
    features: 'Features',
    currentPlan: 'Current Plan',
    changePlan: 'Change Plan',
    currentlyActive: 'Currently Active',
    seeHowToUse: 'See how to use',
    weHaveGotPricingPerfectForYou: `We've got a pricing plan that's perfect for you`,
    weBelieve: 'We believe ',
    shouldBeAccessibleToAllCompanies: ' should be accessible to all companies, not matter the size.',
    monthly: 'Monthly',
    yearly: 'Yearly',
    twoMonthsFree: '(2 Months FREE)',
    kingPlan: 'King Plan',
    perYear: 'per year',
    perMonth: 'per month',
    conversationsPerMonth: 'Conversations per month',
    upgradePlan: 'Upgrade Plan',
    emperorPlan: 'Emperor Plan (Enterprise)',
    haveAsManyConversationsAsYouNeed: 'Have as many conversations as you need',
    contactUs: 'Contact us',
    metaConversationChargesAreAdditional: 'Meta conversation charges are additional and paid directly by you to Meta, based on' +
      'your usage. We do not charge any markup or facilitation fees.',
    viewDetailedPricing: 'View Detailed Pricing',
    conversationPricingModelUsedByMeta: 'Conversations Pricing Model used by Meta',
    whatsappBusinessPlatformConversationsFallInto: 'WhatsApp Business Platform conversations fall into two categories that are priced differently',
    userInitiatedConversation: 'User-initiated Conversation',
    userInitiatedConversationDescription: 'A conversation that initiates in response to a user message. Whenever a business replies to a user within' +
      'the 24 hour customer service window, that message will be associated with a user-initiated conversation.' +
      'Businesses can send free-form messages within this 24 hour customer service window.',
    businessInitiatedConversation: 'Business-initiated Conversation',
    businessInitiatedConversationDescription: 'A conversation that initiates from a business sending a user a message outside the 24 hour' +
      ' customer service window. Messages that initiate a business-initiated conversation will require a message template.',
    areYouSureYouWantTo: 'Are you sure you want to shift from the',
    conversations: ' Conversations ',
    plan: ' plan',
    toThe: ' to the',
    metaPricingMarkup: 'Meta Pricing Markup',
    numberOfChatFlows: 'Number of chat flows',
    greenTickApplication: 'Green Tick Application',
    extraPhoneNumberLinking: 'Extra Phone Number Linking',
    teamMembers: 'Team members',
    dataStorage: 'Data Storage',
    conditionalFlow: 'Conditional Flow',
    liveChat: 'Live Chat',
    smartInbox: 'Smart Inbox',
    whatsappTemplates: 'WhatsApp Templates',
    customerSegments: 'Customer Groups',
    broadcastAndDripCampaigns: 'Broadcast and Drip Campaigns',
    multipleLanguages: 'Multiple Languages',
    bookAppointments: 'Book Appointments',
    csvContactsExport: 'CSV Contacts Export',
    googleCalendarIntegrations: 'Google Calendar Integration',
    nativeApplications: '60+ Native Integration',
    whatsappSupport: 'WhatsApp Support',
    emailAndCallSupport: 'Email and Call Support',
    customerIntegrations: 'Customer Integrations',
    nlpAndNluCapabilities: 'NLP and NLU capabilities',
    chatGPTBot: 'ChatGPT chatbot',
    customerSuccessOfficer: 'Customer Success Officer',
    noMarkupTransparency: 'No markup transparency',
    upto500kPerMonth: 'Upto 500,000 per month',
    beyond500k: 'Beyond 500,000',
    unlimited: 'Unlimited',
    unlimitedMessages: 'Unlimited messages',
    oneTeamMember: '1 Team Member',
    unlimitedTeamMember: 'Unlimited Team Members',
    nintyDaysOfDataStorage: '90 days of data storage',
    unlimitedDataStorage: 'Unlimited data storage',
    downgradeToFreePlan: 'Downgrade To FREE Plan',
    detailedPricing: {
      onDemandAuditLog: 'On demand Audit log',
      accessRestrictionsBasedOnIPs: 'Access resctrictions based on IPs',
      averageAgentResponeTime: 'Average agent respone time',
      perMonth100: '100 per month',
      solutionType: 'Solution Type',
      saas: 'SaaS',
      saasOnPremises: 'SaaS On Premises',
      emperorPlan: 'Emperor Plan',
      babyPlan: 'Baby Plan',
      kingsplan: 'Kings Plan',
      braodcastsAndDrips: 'Broadcasts & Drips',
      thirtyDays: '30 Days',
      ninetyDays: '90 Days',
      oneMember: '1 Member',
      threeMembers: '3 members',
      one: '1 (One)',
      unlimitedMessages: 'Unlimited messages',
      unlimitedBots: 'Unlimited Bots',
      messagesSend: 'Messages Send',
      upto500000perMonth: 'Upto 500,000 per month',
      unlimited: 'Unlimited',
      onlyAdmin: 'Only Admin',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Do it yourself',
      weAreHereToHelp: 'We are here to help',
      weTakeCareEndToEnd: 'We take care end to end',
      numberOfBots: 'No. of bots',
      conversations: 'Conversations',
      gptMessages: 'GPT messages',
      whatsappNumbers: 'WhatsApp Numbers',
      subscribersLeads: 'Subscribers/Leads',
      createSupportTeamForLiveChat: 'Create Support Team for Live chat',
      conversationHistory: 'Conversation History',
      chatbotDesigning: 'Chatbot designing',
      multipleLanguages: 'Multiple Languages',
      conditionalFlow: 'Conditional Flow',
      whatsappTemplates: 'WhatsApp Templates',
      restartChatFlowTriggers: 'Restart Chat Flow Triggers',
      multipleWhatsAppWidgets: 'Multiple WhatsApp Widgets',
      importWhatsappContacts: 'Import Whatsapp Contacts',
      whatsappQuickReply: 'WhatsApp Quick reply',
      whatsappSmartInbox: 'WhatsApp Smart Inbox',
      whatsappEcommerceCatalogs: 'WhatsApp E-commerce with Catalogs',
      whatsappBroadcast: 'WhatsApp Broadcast',
      whatsappBroadcastAnalytics: 'WhatsApp Broadcast Analytics',
      dripCampaigns: 'Drip campaigns',
      customerSegmentation: 'Customer Segmentation',
      keywordBasedResponse: 'Keyword Based Response',
      whatsappShopManagement: 'WhatsApp shop Management',
      aiChatbot: 'AI Chatbot',
      chatGPTIntegration: 'ChatGPT Integration',
      configureAIChatbotPersonality: 'Configure AI Chatbot personality',
      trainChatbotOnYourOwnData: 'Train Chatbot on your own data',
      trainChatbotOnWebPages: 'Train Chatbot on web pages',
      trainChatbotOnMultipleFiles: 'Train Chatbot on multiple files',
      trainChatbotOnFAQs: 'Train Chatbot on FAQs',
      dataTrainingStorage: 'Data training storage',
      useChatGPTModel4or35Turbo: 'Use ChatGPT Model 4/3.5 turbo',
      uploadMultipleFilesForChatbotTraining: 'Upload multiple files for chatbot training',
      naturalLanguageProcessing: 'Natural Language Processing',
      naturalLanguageUnderstanding: 'Natural Language Understanding',
      answerLength: 'Answer length',
      chatTone: 'Chat Tone',
      multiLanguage: 'Multi-Language',
      answerFormatting: 'Answer Formatting',
      customPrompt: 'Custom Prompt',
      whatsAppSessionManagement: 'WhatsApp Session Management (Inactivity and Flow restart messages)',
      integrateBotPenguinLLM: 'Integrate BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrate Own/Custom LLM',
      periodicallySyncDataSources: 'Periodically Sync data sources',
      aiBasedSuggestedQuestions: 'AI based Suggested questions',
      chooseVisibilityForParticularWebpageSitemap: 'Choose visibility for a particular webpage/sitemap',
      chooseKnowledgeMix: 'Choose Knowledge Mix: Your Knowledge Base ChatGPT knowledge "ChatGPT knowledge + Your Knowledge Base"',
      faqExtractionFromFileAndURL: 'FAQ extraction from File and URL',
      analyticsToImproveAI: 'Analytics to improve AI',
      exportQuestionFlow: 'Export Question flow',
      collectFeedbackOnChatbotResponses: 'Collect feedback on Chatbot responses',
      smartAIFollowUpQuestions: 'Smart/AI Follow up questions',
      trainOnYourNotionWorkspaceData: 'Train on your Notion workspace data',
      trainOnYoutubeVideo: 'Train on Youtube video',
      trainOnCustomVideoUpload: 'Train on custom video upload',
      rssFeed: 'RSS Feed',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'Human Handover',
      botHandover: 'Bot Handover',
      handoverBetweenAgents: 'Handover between Agents',
      emailAndMobileValidation: 'Email and Mobile Validation',
      mobileAppsForAgents: 'Mobile Apps for Agents',
      appointmentBooking: 'Appointment Booking',
      roleBasedAccessControls: 'Role-based Access Controls',
      ruleBasedChatRouting: 'Rule based Chat Routing',
      liveVisitorsDetails: 'Live Visitors details',
      multipleSimultaneousChats: 'Multiple simultanaeous chats',
      visitorStats: 'Visitor stats',
      addContactsFromWhatsApp: 'Add Contacts from WhatsApp',
      applyFilterAndSortConversations: 'Apply filter and sort conversations',
      seeChatLogs: 'See chat logs',
      quickReplies: 'Quick Replies',
      unifiedCustomerProfile: 'Unified Customer Profile',
      assignNotesAndChatTags: 'Assign Notes and Chat Tags',
      exportLeadsInCSV: 'Export Leads in CSV',
      tagContacts: 'Tag Contacts',
      customAttributes: 'Custom Attributes',
      markStatusOfConversations: 'Mark Status of Conversations',
      assignConversationToAgents: 'Assign Conversation to Agents',
      leadNotificationsByEmail: 'Lead Notifications by Email',
      directCallsToLeadsWithTwilio: 'Direct Calls to Leads (with Twilio)',
      whatsAppTemplates: 'WhatsApp Templates',
      promotionalMessageFrequencyControl: 'Promotional Message Frequency Control',
      whatsAppWidget: 'WhatsApp Widget',
      whatsAppQR: 'WhatsApp QR',
      whatsAppLink: 'WhatsApp Link',
      campaignScheduler: 'Campaign Scheduler',
      customTriggers: 'Custom Triggers',
      customCampaigns: 'Custom Campaigns',
      repeatPurchaseReminders: 'Repeat Purchase Reminders',
      campaignOpenRateTracking: 'Campaign Open Rate Tracking',
      paymentCollectionOnWhatsApp: 'Payment Collection On WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'Leadsquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Close',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Support Ticketing System',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'In-chat audio and video call (Twilio/Agora)',
      courierTracking: 'Courier Tracking',
      emailIntegration: 'Email Integration',
      webhooks: 'Webhooks',
      schedulers: 'Schedulers',
      paymentGateways: 'Payment Gateways (Stripe & RazorPay)',
      enterpriseIntegrations: 'Enterprise Integrations',
      customIntegration: 'Custom Integration',
      freeWhatsAppAPIApprovalAndSetup: 'Free WhatsApp API approval and setup',
      developerAPIsAccess: 'Developer APIs access',
      customRateLimit: 'Custom Rate Limit',
      totalNumberOfLeads: 'Total Number of Leads',
      averageAgentResponseTime: 'Average agent response time',
      visitorTimingReport: 'Visitor Timing Report',
      customerSegmentBasedReports: 'Customer Segment-based Reports',
      tagBasedReports: 'Tag-based Reports',
      leadGenerationReport: 'Lead Generation Report',
      supportOnWhatsApp: 'Support on WhatsApp',
      knowledgeBase: 'Knowledge Base',
      onboardingAndTraining: 'Onboarding and Training',
      callAndEmailSupport: 'Call and Email Support',
      freeChatbotCreation: 'FREE chatbot creation',
      customerSuccessOfficer: 'Customer Success Officer',
      secureConnection: '2048 bit SSL secure connection',
      securityAssessment: 'Security Assessment',
      dataCenterLocation: 'Data center in US or EU',
      twoStepVerification: '2 step verification',
      accessRestrictions: 'Access restrictions based on IPs',
      auditLog: 'On-demand Audit log',
      bannedVisitorsList: 'Banned visitors list',
      customAPIRate: 'Custom API rate',
      trustedDomains: 'Trusted domains',
      elementary: 'Elementary',
      bots: 'Bots',
      unlockWhatsapp: 'Unlock WhatsApp',
      aiChatbotFeatures: 'AI Chatbot Features',
      dataSourceForTraining: 'Data Source for training',
      smartFeatures: 'Smart Features',
      liveChat: 'Live Chat',
      smartInbox: 'Smart Inbox',
      broadcastsAndDrips: 'Broadcasts & Drips',
      integrations: 'Integrations',
      development: 'Development',
      reportsAndAnalytics: 'Reports and Analytics',
      customerSupport: 'Customer Support',
      security: 'Security',
      whatsappWidgets: 'WhatsApp Widget',
      campaignSchedular: 'Campaign Scheduler',
      repeatPurchaseRemainder: 'Repeat Purchase Reminders',
      // metaConversationChargesAreAdditional: 'Meta conversation charges are additional and paid directly by you to Meta, based on' +
      //   ' your usage. We do not charge any markup or facilitation fees.',
      // kingPlan: 'King Plan',
      // emperorPlan: 'Emperor Plan',
      // solutionType: 'Solution Type',
      // whatsappTemplates: 'WhatsApp Templates',
      // dripCampaigns: 'Drip campaigns',
      // multipleWhatsappWidgets: 'Multiple WhatsApp Widgets',
      // importWhatsappContacts: 'Import Whatsapp Contacts',
      // whatsappQuickReply: 'WhatsApp Quick reply',
      // whatsappSmartInbox: 'WhatsApp Smart Inbox',
      // whatsappECommerceWithCatalogs: 'WhatsApp E-commerce with Catalogs',
      // whatsappBroadcast: 'WhatsApp Broadcast',
      // whatsappBroadcastAnalytics: 'WhatsApp Broadcast Analytics',
      // customerSegmentation: 'Customer Grouping',
      // keywordBasedResponse: 'Keyword Based Response',
      // nlpBasedTraining: 'NLP Based Training',
      // humanHandOver: 'Human Handover',
      // botHandOver: 'Bot Handover',
      // handOverBetweenAgents: 'Handover between Agents',
      // emailAndMobileValidation: 'Email and Mobile Validation',
      // mobileAppsForAgents: 'Mobile Apps for Agents',
      // appointmentsBooking: 'Appointments Booking',
      // chatGPTIntegration: 'ChatGPT integration',
      // faqTraining: 'FAQ Training',
      // naturalLanguageProcessing: 'Natural Language Processing',
      // naturalLanguageUnderstanding: 'Natural Language Understanding',
      // roleBasedAccessControl: 'Role-based Access Controls',
      // liveVisitorsDetails: 'Live Visitors details',
      // multipleSimultaneousChats: 'Multiple simultanaeous chats',
      // ruleBasedChatRouting: 'Rule based Chat Routing',
      // createSupportTeamForLiveChat: 'Create Support Team for Live chat',
      // visitorStats: 'Visitor stats',
      // addContactsFromWhatsapp: 'Add Contacts from Whatsapp',
      // applyFilterAndSortConversation: 'Apply filter and sort conversations',
      // seeChatLogos: 'See chat logs',
      // directCallToLeads: 'Direct Calls-to-leads (with Twilio)',
      // quickReplies: 'Quick Replies',
      // unifiedCustomerProfile: 'Unified Customer Profile',
      // assignNotesAndChatTags: 'Assign Notes and Chat Tags',
      // exportLeadsInCsv: 'Export Leads in CSV',
      // leadNotificationsByEmail: 'Lead Notifications by email',
      // promotionalMessageFrequencyControl: 'Promotional Message Frequency Control',
      // repeatPurchaseRemainder: 'Repeat Purchase Reminders',
      // whatsappWidgets: 'WhatsApp Widget',
      // whatsappQR: 'WhatsApp QR',
      // whatsappLink: 'WhatsApp Link',
      // campaignSchedular: 'Campaign Scheduler',
      // campaignOpenRateTracking: 'Campaign Open Rate Tracking',
      // customTriggers: 'Custom Triggers',
      // customCampaigns: 'Custom Campaigns',
      // paymentCollectionOnWhatsapp: 'Payment Collection On WhatsApp',
      // zapier: 'Zapier',
      // crmIntegrations: 'CRM Integrations (25+)',
      // supportTicketingSystem: 'Support Ticketing System',
      // googleSheets: 'Google Sheets',
      // inChatAudioAndVideo: 'In-chat audio and video call',
      // googleCalendar: 'Google Calendar',
      // googleAnalytics: 'Google Analytics',
      // emailIntegration: 'Email Integration',
      // courierTracking: 'Courier Tracking',
      // webhooks: 'Webhooks',
      // paymentGateways: 'Payment Gateways',
      // hubspot: 'HubSpot ',
      // gitHub: 'GitHub ',
      // zendesk: 'Zendesk',
      // salesforce: 'Salesforce',
      // customIntegrations: 'Custom Integrations',
      // developerApisAccess: 'Developer APIs access',
      // customRateLimit: 'Custom Rate Limit ',
      // totalNumberOfLeads: 'Total Number of Leads',
      // averageAgentResponseTime: 'Average agent respone time',
      // visitorTimingReport: 'Visitor Timing Report',
      // customerSemgentBasedReport: 'Customer Group-based Reports',
      // tagBasedReport: 'Tag-based Reports',
      // leadGenerationReport: 'Lead Generation Report',
      // supportOnWhatsapp: 'Support on WhatsApp',
      // knowledgeBase: 'Knowledge Base',
      // onboardingAndTraining: 'Onboarding and Training',
      // callAndEmailSupport: 'Call and Email Support',
      // freeChatBotCreation: 'FREE chatbot creation',
      // elementary: 'Elementary',
      // smartFeatures: 'Smart Features',
      // liveChat: 'Live Chat',
      // smartInbox: 'Smart Inbox',
      // broadcastsAndDrips: 'Broadcasts & Drips',
      // integrations: 'Integrations',
      // development: 'Development',
      // reportsAndAnalytics: 'Reports and Analytics',
      // customerSupport: 'Customer Support',
      // unlockWhatsapp: 'Unlock WhatsApp',
      // saas: 'SaaS',
      // saasOnPremises: 'SaaS/On-Premises',
      // customerFaqs: `Custom FAQ's`,
      // oneMember: '1 member',
      // beWhereTheCustomersAre: 'Be where the customers are',
      // lifesEasy: 'Life\'s easy',
      // oneTextAway: 'One text away',
      // everyWhereAtOnce: 'Everywhere at Once',
      // reachOut: 'Reach Out',
      // morePowerToYou: 'More power to you',
      // codingCurious: 'Coding Curious',
      // knowledgeIsPower: 'Knowledge is power',
      // noDoubtsRemain: 'No doubts remain',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Our free plan',
      TOOLTIP_KING_PLAN: 'Our most popular plan',
      TOOLTIP_EMPEROR_PLAN: 'Customized plan for unlimited messages, unlimited data, unlimited team members',
      TOOLTIP_CONTACT_US: 'Reach to us via WhatsApp or email at contact@botpenguin.com to learn more about our customized plan',
      TOOLTIP_UPGRADE: 'Upgrade your current plan for additional features',
      TOOLTIP_MONTHLY: 'Select a monthly payment cycle',
      TOOLTIP_YEARLY: 'Select a yearly payment cycle for discount benefits',
    }
  }
};
