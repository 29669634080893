export const whitelabel = {
  plans: 'Pläne',
  managePlan: 'Pläne verwalten',
  whitelabelCharges: 'Whitelabel -Gebühren',
  chargesOrTaxesConfiguration: 'Gebühren-/Steuernkonfiguration',
  websitePlans: 'Website -Pläne',
  whatsappPlansHeader: 'WhatsApp -Pläne',
  addOnPlans: {
    createAddOnPlans: 'Erstellen Sie Add-On-Pläne',
    checkoutYourCurrentMessage: 'Schauen Sie sich Ihre aktuellen Meldungspläne und neue Pläne für Ihre Kunden an',
    choosePlanValidity: 'Wählen Sie Plan Gültigkeit',
    chooseValidity: 'Wählen Sie Gültigkeit',
    oneYear: 'Ein Jahr',
    billingCycle: 'Rechnungskreislauf',
    numberOfMessages: 'Anzahl der Nachrichten',
    pleaseEnterNumberOfMessages: 'Bitte geben Sie die Anzahl der Nachrichten ein',
    setCurrency: 'Währung festlegen',
    select: 'Wählen',
    inr: 'INR',
    usd: 'US Dollar',
    cad: 'CAD',
    clearAll: 'Alles löschen',
    apply: 'Anwenden',
    currency: 'Währung',
    amount: 'Menge',
    validity: 'Gültigkeit',
    upgradePlan: 'Aktualisieren Sie den Plan',
    addPlan: 'Plan hinzufügen',
    serialNumber: 'Sr. Nr.',
    messages: 'Mitteilungen',
    // validity: 'Gültigkeit',
    active: 'Aktiv',
    priceDetails: 'Preisdetails',
    action: 'Aktion',
    addOnChanges: 'Add-On-Gebühren:',
    // messages: ' Mitteilungen',
    perYear: 'pro Jahr',
    taxes: 'Steuern',
    placeholders: {
      enterNumberOfMesasges: 'Geben Sie die Anzahl der Nachrichten ein',
      enterAmount: 'Menge eingeben',
      enterValidity: 'Gültigkeit eingeben',
      searchByMessages: 'Suche nach Nachrichten',
    },
    modal: {
      deleteAddOnsPlan: 'Add-Ons-Plan löschen',
      wantToDeleteThisAddOnsPlan: 'Sind Sie sicher, dass Sie diesen Add-On-Plan löschen möchten?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Fügen Sie Whitelabel -Gebühren hinzu',
    checkoutExistingCharges: 'Überprüfen Sie die vorhandenen Gebühren für verschiedene Zahlungsmethoden und fügen Sie neue Whitelabel -Gebühren hinzu',
    currency: 'Währung',
    chooseCurrency: 'Währung wählen',
    amountPerBotPerMonth: 'Betrag pro Bot pro Monat',
    cancel: 'Stornieren',
    upgradeChanges: 'Gebühren aktualisieren',
    addCharges: 'Fügen Sie Gebühren hinzu',
    serialNumber: 'Sr. Nr.',
    // currency: 'Währung',
    action: 'Aktion',
    taxes: 'Steuern',
    placeholders: {
      enterAmountBotPerMonth: 'Geben Sie den Betrag Bot pro Monat ein',
      searchByCurrency: 'Suche nach Währung',
    },
    modal: {
      deleteCharges: 'Gebühren löschen',
      wantToDeleteThisCharge: 'Sind Sie sicher, dass Sie diese Gebühr löschen möchten?',
    }
  },
  planManagement: {
    createNewPlans: 'Neue Pläne erstellen',
    checkoutYourCurrentPlan: 'Schauen Sie sich Ihre aktuellen Pläne an und fügen Sie Ihre Kunden neue Pläne hinzu',
    choosePlan: 'Wählen Sie einen Plan',
    selectPlan: 'Plan auswählen',
    baby: 'Baby',
    king: 'König',
    displayName: 'Anzeigename',
    messageLimit: 'Nachrichtenlimit',
    enterAValidEmail: 'Geben Sie eine gültige E -Mail ein',
    setCurrency: 'Währung festlegen',
    select: 'Wählen',
    inr: 'INR',
    usd: 'US Dollar',
    cad: 'CAD',
    clearAll: 'Alles löschen',
    apply: 'Anwenden',
    perMonth: '/Monat',
    perYear: '/Jahr',
    cancel: 'Stornieren',
    upgradePlan: 'Aktualisieren Sie den Plan',
    addPlan: 'Plan hinzufügen',
    serialNumber: 'Sr. Nr.',
    plan: ' Planen',
    // displayName: 'Anzeigename',
    messages: 'Mitteilungen',
    pricing: 'Preisgestaltung',
    actions: 'Aktionen',
    // plan: ' Planen',
    perMonthText: 'Pro Monat',
    perYearText: 'Pro Jahr',
    placeholders: {
      enterDisplayName: 'Geben Sie den Anzeigenamen ein',
      enterMessageLimit: 'Geben Sie die Nachrichtenlimit ein',
      searchByPlanName: 'Nach Planname oder Nachrichten suchen'
    },
    modal: {
      deletePlan: 'Plan löschen',
      wantToDeleteThisBabyPlan: 'Das Löschen des Baby-Plans wirkt sich auf alle Kunden aus. Bist du sicher, dass du diesen Plan löschen möchtest?',
      wantToDeleteThisPlan: 'Sind Sie sicher, dass Sie diesen Plan löschen möchten?',
    },
    appTooltip: {
      chooseYourPlanType: 'Wählen Sie Ihren Plantyp',
      pleaseInputYourPlanDisplayName: 'Bitte geben Sie Ihren Plan -Anzeignamen ein',
      typeMessageLimitForCustomer: 'Geben Sie das Nachrichtenlimit für den Kunden ein',
      selectYourPreferredCurrencies: 'Wählen Sie Ihre bevorzugten Währungen aus',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Steuern hinzufügen',
    checkoutExistingTaxes: 'Überprüfen Sie die bestehenden Steuern auf verschiedene Zahlungsmethoden und neue Steuern',
    taxName: 'Steuername',
    applicableOn: 'Anwendbar auf',
    choosePlans: 'Wählen Sie Pläne',
    chooseCurrency: 'Währung wählen',
    percentage: 'Prozentsatz',
    cancel: 'Stornieren',
    // addTaxes: 'Steuern hinzufügen',
    serialNumber: 'Sr. Nr.',
    name: 'Name',
    // percentage: 'Prozentsatz',
    currency: 'Währung',
    updateTaxes: 'Steuern aktualisieren',
    actions: 'Aktionen',
    modal: {
      deleteTaxes: 'Steuern löschen',
      wantToDeleteThisTax: 'Sind Sie sicher, dass Sie diese Steuern löschen möchten?',
    },
    placeholders: {
      taxName: 'Steuername',
      taxPercentage: 'Steueranteil',
      searchByCurrency: 'Suche nach Währung',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Neue Pläne erstellen',
    checkoutYourCurrentAndNewPlans: 'Schauen Sie sich Ihre aktuellen Pläne an und fügen Sie Ihre Kunden neue Pläne hinzu',
    choosePlan: 'Wählen Sie einen Plan',
    selectPlan: 'Plan auswählen',
    baby: 'Baby',
    king: 'König',
    displayName: 'Anzeigename',
    messageLimit: 'Gesprächslimit',
    enterAValidMessageLimit: 'Geben Sie ein gültiges Gesprächslimit ein',
    setCurrency: 'Währung festlegen',
    select: 'Wählen',
    inr: 'INR',
    usd: 'US Dollar',
    cad: 'CAD',
    clearAll: 'Alles löschen',
    apply: 'Anwenden',
    perMonth: '/Monat',
    perYear: '/Jahr',
    cancel: 'Stornieren',
    addPlan: 'Plan hinzufügen',
    serialNumber: 'Sr. Nr.',
    plan: 'Planen',
    messages: 'Gespräche',
    pricing: 'Preisgestaltung',
    actions: 'Aktionen',
    perMonthText: 'Pro Monat',
    perYearText: 'Pro Jahr',
    updatePlan: 'Aktualisieren Sie den Plan',
    // addPlan: 'Plan hinzufügen',
    placeholders: {
      enterDisplayName: 'Geben Sie den Anzeigenamen ein',
      enterMessageLimit: 'Geben Sie das Gesprächslimit ein',
      searchByPlanName: 'Suche nach Planname oder Gesprächen',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Geben Sie das Gesprächslimit für den Kunden ein',
      chooseYourPlanType: 'Wählen Sie Ihren Plantyp',
      selectYourPreferredCurrencies: 'Wählen Sie Ihre bevorzugten Währungen aus',
      pleaseInputYourPlanDisplayName: 'Bitte geben Sie Ihren Plan -Anzeignamen ein',
    },
    modal: {
      deletePlan: 'Plan löschen',
      wantToDeleteThisBabyPlan: 'Das Löschen des Baby-Plans wirkt sich auf alle Kunden aus. Bist du sicher, dass du diesen Plan löschen möchtest?',
      areYouSureYouWantToDeleteThisPlan: 'Sind Sie sicher, dass Sie diesen Plan löschen möchten?',
    }
  }
};
