export const subscription = {
  subscriptions: 'Abonelikler',
  plan: 'Plan',
  whatsappPlan: 'WhatsApp Planı',
  addOns: 'Ek Paketler',
  billingDetails: 'Fatura Detayları',
  invoices: 'Faturalar',
  wa1Reminder: 'WA 1. Hatırlatıcı',
  wa2Reminder: 'WA 2. Hatırlatıcı',
  waRenewApi: 'WA Yenileme API',
  waCheckUsageApi: 'WA Kullanımı Kontrol Etme API',
  waResetUsageApi: 'WA Kullanım Sıfırlama API',
  waExceedUsageApi: 'WA Aşırı Kullanım API',
  waGetBotsInThisAccount: 'Bu Hesaptaki Botları Alın',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Tüm ihtiyaçlarınız için en uygun fiyatları sunuyoruz.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'Whatsapp planı',
    TOOLTIP_ADDS_ON_HEADING: 'Ek mesaj paketleri ve beyaz etiketli bot satın alın',
    TOOLTIP_ADDS_ON_MESSAGE: 'Aylık mesaj sınırınıza ulaştıysanız ek paketler satın alabilirsiniz.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Mevcut abonelik detaylarınızı kontrol edin',
    TOOLTIP_HORIZONTAL_BAR: 'Barı sola doğru kaydırarak aylık veya yıllık fiyatlarımızı 3K ila 500K mesaj arasında kontrol edin.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Fatura bilgilerinizi güncelleyin.',
    TOOLTIP_INVOICES_HEADING: 'Önceki faturalarınızı kontrol edin.',
    TOOLTIP_INVOICES_ACTION: 'Faturayı e-posta hesabınıza almak için tıklayın'
  },
  currentPlan: {
    seeHowToUse: 'Nasıl kullanılacağını görün',
    help: 'Yardım',
    downgradeRequest1: 'Planınızı şu mesajla düşürme isteği gönderdiniz:',
    downgradeRequest2: 'mesaj. Lütfen unutmayın, Planınız, mevcut fatura döneminin sonunda düşürülecektir',
    downgradeRequest3: 'O zamana kadar',
    downgradeRequest4: 'hizmetlerinin tadını çıkarabilirsiniz.',
    downgradeRequestMaster1: 'Planınızı şu mesajla düşürme isteği gönderdiniz: ',
    downgradeRequestMaster2: 'mesaj. Lütfen unutmayın, Planınız, mevcut fatura döneminin sonunda düşürülecektir.',
    downgradeRequestMaster3: 'O zamana kadar',
    downgradeRequestMaster4: 'hizmetlerinin tadını çıkarabilirsiniz.',
    active: 'Aktif',
    inactive: 'Pasif',
    month: 'ay',
    year: 'yıl',
    messagePack: 'Mesaj Paketi',
    king: 'Kral',
    baby: 'Bebek',
    whatsapp: 'WhatsApp',
    youAreOn: 'Şu an',
    plan: 'planındasınız',
    totalMessagesLimit: 'Toplam Mesaj Sınırı',
    liveAgentLimit: 'Canlı Ajan Sınırı',
    perMonth: '/ay',
    agents: 'ajan(lar)',
    messagesAddOn: 'Mesajlar Ek Paketi',
    botWhitelabels: 'Bot Beyaz Etiketleri',
    totalAddOnMessages: 'Toplam Ek Paket Mesajlar',
    basePrice: 'Taban Fiyat',
    gst: 'GST',
    totalPrice: 'Toplam Fiyat',
    totalAddOnPrice: 'Toplam Ek Paket Fiyatı',
    whitelabelPrice: 'Beyaz Etiket Fiyatı',
    startedOn: 'Başlama Tarihi',
    nextDueOn: 'Sonraki ödeme tarihi',
    addOnExpiry: 'Ek Paket Süresi',
    website: 'Web Sitesi',
    facebook: 'Facebook',
    telegram: 'Telegram'
  },
  invoice: {
    invoiceNumber: 'Fatura No.',
    date: 'Tarih',
    invoiceAmount: 'Fatura Tutarı',
    planAmount: 'Plan Tutarı',
    plan: 'Plan',
    messages: 'Mesajlar',
    action: 'Eylem',
    nA: 'NA',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Faturayı e-posta hesabınıza almak için tıklayın',
      clickToDownloadInvoice: 'Faturayı PDF olarak indirmek için tıklayın',
    }
  },
  messageAddOn: {
    validity: 'Geçerlilik',
    kingPlanHeader: '👑 Kral Planı',
    youAreOnThe: 'Şu an',
    asOfNowAndWillGet: 'itibariyle ve',
    messages: 'mesaj ',
    inThisPlan: 'bu plan içinde.',
    youCanBuyAdditionalMessaes: 'İş gereksinimlerinize göre ek mesajlar satın alabilirsiniz.',
    plan: ' Planı',
    baby: 'Baby',
    king: 'King',
    get: 'Alın ',
    babyPlan: 'Baby Plan ',
    upgradePlan: 'Planı Yükselt',


    asOfNow: 'şu an itibariyle',
    pleaseUpgradeToKingPlan: 'Vă rugăm să faceți upgrade la ',
    toEnableAddOnsAndWhitelabelPacks: 'Ekstraları ve Beyaz Etiket Paketlerini etkinleştirmek için',
    messageAddOnPacks: 'Mesaj Ek Paketleri',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Aylık mesaj sınırınıza ulaştıysanız, ek paketler satın alın ve kesintisiz hizmetin tadını çıkarın.',
    pleaseSelectNumberOfExpectedMessages: 'Lütfen beklenen mesaj sayısını seçin',
    addOnsValidity: 'Ekstraların Geçerliliği',
    currentBillingCycle: 'Geçerli Fatura Dönemi',
    oneYear: 'Bir Yıl',
    summary: 'Özet',
    messageCreditsWithKingPlan: 'King Plan ile mesaj kredileri',
    messageCreditsWith: 'ile mesaj kredileri ',
    withPlan: '',
    billingCycle: 'Fatura Dönemi',
    payableAmount: 'Ödenecek Tutar',
    oneTimePayment: 'Bir Kez Ödeme',
    plusTaxes: ' + Vergiler',
    buyNow: 'Şimdi Satın Al',
    whiteLabelChatWindow: 'Beyaz Etiket Sohbet Penceresi',
    noBotsLeftToWhiteLabel: 'Beyaz etiketlemek için kalan bot yok.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Lütfen beyaz etiketlemek istediğiniz botları seçin.',
    selectBots: 'Botları Seç',
    bot: 'Bot',
    bots: 'Botlar',
    toBeWhiteLabelled: ' beyaz etiketlenecek',
    remove: 'Kaldır',
    brandNameFromChatWindow: ' sohbet penceresinden marka adını kaldırın.',
    willBeValidTill: 'Geçerli olacak kadar ',
    ourYearlyPrice: 'Yıllık fiyatımız ',
    directWhiteLabel: 'Doğrudan Beyaz Etiket',
    whiteLabelledBots: 'Beyaz Etiketlenmiş Botlar',
    nextRenewalColon: 'Sonraki Yenileme: ',
    optOutRequested: 'Çıkış İsteği',
    contactAgency: 'Ajansla İletişime Geçin',
    optOut: 'Çıkış Yap',
    removeWhiteLabel: 'Beyaz Etiketi Kaldır',
    numOfBots: 'Bot Sayısı',
    amount: 'Miktar',
    taxes: 'Vergiler',
    totalAmount: 'Toplam Miktar',
    select: 'Seç',
    selectTheValidity: 'Geçerlilik Seçin',
    messageCount: 'Mesaj Sayısı',
    numberOfPacks: 'Paket Sayısı',

  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Ödeme Detayları',
    cardNumber: 'Kart Numarası ',
    cvv: 'CVV ',
    month: 'Ay',
    year: 'Yıl',
    save: 'Kaydet',
    add: 'Ekle',
    edit: 'Düzenle',
    disclaimer: 'Açıklama: ',
    disclaimerDescription1: 'Yukarıdaki ödeme yöntemi bilgileri sadece Hindistan dışındaki kullanıcılar için geçerlidir.',
    disclaimerDescription2: 'Kart bilgilerinizi bizimle saklamıyoruz.' +
      'Kart bilgilerinizi değiştirmek isterseniz, doğrudan ödeme ağ geçidi ile değiştirilir.',
    billingAddress: 'Fatura Adresi',
    companyName: 'Şirket Adı ',
    country: 'Ülke ',
    selectCountry: 'Ülke Seçiniz',
    stateOrRegion: 'Eyalet/Bölge ',
    streetAddress: 'Cadde Adresi ',
    gstIn: 'GSTIN ',
    postalCode: 'Posta Kodu ',
    cancel: 'İptal',
    proceedToPay: 'Ödemeye Devam Et',
    company: 'Şirket',
    state: 'Eyalet',
    masterCard: 'Master Kart',
    yourCompanyName: 'Şirket Adınız',
    yourCountry: 'Ülkeniz',
    yourRegion: 'Bölgeniz',
    yourPostalCode: 'Posta Kodunuz',
    plusTaxes: ' + vergiler',
    dropDown: {
      month: 'Ay *',
      year: 'Yıl *',
    },
    placeholders: {
      enterCardNumber: 'Kart numarasını girin',
      cvv: 'CVV',
      enterYourCompanyName: 'Şirket adınızı girin',
      enterYourStateOrRegion: 'Eyalet/bölgenizi girin',
      enterYourStreetAddress: 'Cadde adresinizi girin',
      enterYourGSTIN: 'GSTIN numaranızı girin',
      enterPostalCode: 'Posta kodunu girin',
    }

  },
  plans: {
    viewDetailedPricing: 'Detaylı Fiyatları Görüntüle',
    seeHowToUse: 'Kullanımı Nasıl Göster',
    help: 'Yardım',
    weHaveGotAPricingPlanPerfectForYou: `Sizin için mükemmel bir fiyatlandırma planımız var`,
    weBelieve: 'İnanıyoruz ki ',
    shouldBeAccessibleToAllCompanies: ' bütün şirketler için erişilebilir olmalı, boyut fark etmeksizin.',
    monthly: 'Aylık',
    yearly: 'Yıllık',
    twoMonthsFree: '(2 Ay Bedava)',
    babyPlan: 'Baby Plan',
    freeForever: 'Sonsuza Kadar Ücretsiz',
    messagesPerMonth: 'Aylık Mesaj Sayısı',
    currentPlan: 'Mevcut Plan',
    freePlan: 'ÜCRETSİZ Plan',
    kingPlan: 'King Plan',
    perYear: 'yıllık',
    plan: 'plan',
    toThe: ' için',
    plusTaxes: ' + Vergiler',
    perMonth: 'aylık',
    upgradePlan: 'Plan Yükselt',
    changePlan: 'Plan Değiştir',
    currentlyActive: 'Şu Anda Aktif',
    emperorPlan: 'Emperor Plan (Kurumsal)',
    sendAsManyMessagesAsYouWant: 'İhtiyacınız olan kadar çok mesaj gönderin',
    contactUs: 'Bize Ulaşın',
    features: 'Özellikler',
    areYouSureYouWantToShiftFrom: 'Şu anki plandan geçmek istediğinizden emin misiniz',
    messages: 'Mesajlar',
    planQuestionMark: ' plan?',
    downgradeToFreePlan: '\'ÜCRETSİZ Plan\'a Dönüş Yap\'',
    goToBabyPlan: 'Baby Plan\'a Git',
    thankYouForChoosingBotPenguin: 'BotPenguin\'ı seçtiğiniz için teşekkür ederiz',
    bots: 'Botlar',
    facebookPages: 'Facebook Sayfaları',
    teamMember: 'Ekip Üyesi',
    dataStorage: 'Veri Depolama',
    conditionalFlow: 'Koşullu Akış',
    liveChat: 'Canlı Sohbet',
    bookAppointments: 'Randevu Al',
    csvExport: 'CSV İhracat',
    emailAndCallSupport: 'E-posta ve Arama Desteği',
    googleCalendarIntegrations: 'Google Takvim Entegrasyonları',
    zohoCrmIntegrations: 'Zoho CRM Entegrasyonları',
    reportsAndAnalytics: 'Raporlar ve Analitik',
    removeBotPenguinBranding: 'BotPenguin Markasını Kaldır',
    oneBot: '1 Bot',
    twoKMessagesPerMonth: '2 bin mesaj/ay',
    oneFacebookPage: '1 Facebook Sayfası',
    oneTeamMember: '1 Ekip Üyesi',
    thirtyDaysOfDataStorage: '30 gün veri saklama',
    unlimitedBot: 'Sınırsız Bot',
    threeTo500kMessagesPerMonth: '3 bin ile 500 bin mesaj/ay',
    tenFacebookPages: '10 Facebook Sayfası',
    threeTeamMembers: '3 Ekip Üyesi',
    nintyDaysOfDataStorage: '90 gün veri saklama',
    unlimitedMessages: 'Sınırsız mesaj',
    unlimitedFacebookPages: 'Sınırsız Facebook Sayfaları',
    unlimitedTeamMembers: 'Sınırsız Ekip Üyeleri',
    unlimitedDataStorage: 'Sınırsız veri saklama',
    youAreHere: `Şu an buradasınız!`,
    detailedPricing: {
      kingsplan: 'King Plan',
      elementary: 'Elementary',
      solutionType: 'Çözüm Türü',
      saas: 'SaaS',
      fbpage1fb: '1 FB Sayfası',
      perMonth100: '100 ayda bir',
      enterprise: 'Kurumsal',
      saasOnPremises: 'SaaS İşyeri',
      aiChatbotFeatures: 'AI Sohbet Botu Özellikleri',
      dataSourceForTraning: 'Eğitim için Veri Kaynağı',
      smartFeatures: 'Akıllı Özellikler',
      liveChat: 'Canlı Sohbet',
      day30: '30 gün',
      day90: '90 gün',
      landSquared: 'Landsquared',
      doItYourself: 'Kendin Yap',
      unifiedInbox: 'Birleşik Gelen Kutusu',
      platforms: 'Platformlar',
      websiteChatbot: 'Web Sitesi Sohbet Botu',
      FacebookMessengerChatbot: 'Facebook Messenger Sohbet Botu',
      Telegram: 'Telegram',
      conversationalLandingPage: 'Konuşma Sayfası ',
      integrations: 'Entegrasyonlar',
      reportsAndAnalytics: 'Raporlar ve Analitik',
      customerSupport: 'Müşteri Desteği',
      security: 'Güvenlik',
      bots: 'Botlar',
      babyPlan: 'Baby Plan',
      kingPlan: 'King Plan',
      emperorPlan: 'Emperor Plan',
      noofBots: 'Bot Sayısı',
      one: '1 (Bir)',
      unlimitedBots: 'Sınırsız Botlar',
      noOfWebsite: 'Web Sitesi Sayısı',
      unlimitedWebsites: 'Sınırsız web sitesi',
      unlimitedMessages: 'Sınırsız Mesajlar',
      messagesSend: 'Gönderilen Mesajlar',
      perMonth2000: '2,000 ayda bir',
      upto500000perMonth: '500,000 ayda bir',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Sınırsız Sayfalar',
      telegramNumbers: 'Telegram Numaraları',
      unlimited: 'Sınırsız',
      whatsappNumbers: 'WhatsApp Numaraları',
      subscribersLeads: 'Aboneler/Leads',
      createSupportTeam: 'Canlı Sohbet için Destek Ekibi Oluşturun',
      oneMember: '1 Üye',
      threeMembers: '3 üye',
      conversationHistory: 'Sohbet Geçmişi',
      thirtyDays: '30 Gün',
      ninetyDays: '90 Gün',
      chatbotDesigning: 'Sohbet Botu Tasarımı',
      weAreHereToHelp: 'Size yardımcı olmak için buradayız',
      weTakeCareEndToEnd: 'Baştan sona bakıyoruz',
      multipleLanguages: 'Birden Fazla Dil',
      conditionalFlow: 'Koşullu Akış',
      removeBotPenguinBranding: 'BotPenguin Markasını Kaldır',
      gptMessages: 'GPT Mesajlar',
      aiChatbot: 'AI Sohbet Botu',
      chatGPTIntegration: 'ChatGPT Entegrasyonu',
      configureAIChatbotPersonality: 'AI Sohbet Botu kişiliğini yapılandırın',
      trainChatbotOnYourOwnData: 'Kendi verilerinizle Sohbet Botu eğitin',
      trainChatbotOnWebPages: 'Web sayfaları üzerinde Sohbet Botu eğitin',
      trainChatbotOnMultipleFiles: 'Çoklu dosyalar üzerinde Sohbet Botu eğitin',
      trainChatbotOnFAQs: 'SSS\'lere (FAQs) Sohbet Botu eğitin',
      dataTrainingStorage: 'Veri eğitim depolama',
      useChatGPTModel4_3_5Turbo: 'ChatGPT Model 4/3.5 turbo\'yu kullan',
      uploadMultipleFilesForChatbotTraining: 'Sohbet Botu eğitimi için çoklu dosyaları yükleme',
      naturalLanguageProcessing: 'Doğal Dil İşleme',
      naturalLanguageUnderstanding: 'Doğal Dil Anlama',
      answerLength: 'Cevap Uzunluğu',
      chatTone: 'Sohbet Tonu',
      multiLanguage: 'Çoklu Dil',
      answerFormatting: 'Cevap Biçimlendirme',
      customPrompt: 'Özel İstek',
      integrateBotPenguinLLM: 'BotPenguin LLM\'yi entegre etme',
      integrateOwnCustomLLM: 'Kendi Özel LLM\'nizi Entegre Edin',
      periodicallySyncDataSources: 'Veri kaynaklarını periyodik olarak senkronize etme',
      aiBasedSuggestedQuestions: 'AI tabanlı Önerilen Sorular',
      chooseVisibilityForParticularWebpageSitemap: 'Belirli bir web sayfası/sayfa haritası için görünürlüğü seçin',
      chooseKnowledgeMix: 'Bilgi Karışımını Seçin:\nKendi Bilgi Tabanınız\nChatGPT bilgisi\n"ChatGPT bilgisi + Sizin Bilgi Tabanınız"',
      faqExtractionFromFileAndURL: 'Dosya ve URL\'den FAQ çıkarma',
      analyticsToImproveAI: 'AI\'yi İyileştirmek için Analitik',
      exportQuestionFlow: 'Soru akışını dışa aktar',
      sessionManagement: 'Oturum Yönetimi',
      collectFeedbackOnChatbotResponses: 'Sohbet Botu yanıtlarına geri bildirim toplama',
      smartAIFollowUpQuestions: 'Akıllı/AI Takip Soruları',
      trainOnYourNotionWorkspaceData: 'Notion çalışma alanı verilerinizle eğitin',
      trainOnYoutubeVideo: 'Youtube videosu ile eğitin',
      trainOnCustomVideoUpload: 'Özel video yükleme ile eğitin',
      rssFeed: 'RSS Besleme',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'IP ve Bölge Engelleme',
      emailAndMobileValidation: 'E-posta ve Mobil Onaylama',
      appointmentsBooking: 'Randevu Rezervasyonu',
      humanHandover: 'İnsan Devri',
      botHandover: 'Bot Devri',
      handoverBetweenAgents: 'Ajanlar Arasında Devir',
      mobileAppsForAgents: 'Ajanlar İçin Mobil Uygulamalar',
      roleBasedAccessControls: 'Rol Tabanlı Erişim Kontrolleri',
      apiIntegrationOnChatFlows: 'Sohbet Akışlarına API Entegrasyonu',
      liveChatWidget: 'Canlı Sohbet Widget\'ı',
      typingIndicator: 'Yazma Göstergesi',
      liveVisitorsDetails: 'Canlı Ziyaretçi Detayları',
      multipleSimultaneousChat: 'Çoklu eş zamanlı sohbet',
      liveTypingView: 'Canlı Yazma Görünümü',
      ruleBasedChatRouting: 'Kural tabanlı sohbet yönlendirmesi',
      chatAutoAssignments: 'Sohbet Otomatik Atamaları',
      efficientOmnichannelInterface: 'Verimli Çok Kanallı Arabirim',
      quickReplies: 'Hızlı Yanıtlar',
      unifiedCustomerProfile: 'Birleşik Müşteri Profili',
      visitorStats: 'Ziyaretçi İstatistikleri',
      addContactsFromWhatsapp: 'WhatsApp\'tan Kişiler Ekle',
      applyFilterAndSortConversations: 'Filtre Uygula ve Konuşmaları Sırala',
      seeChatLogs: 'Sohbet Günlüklerini Görüntüle',
      assignNotesAndChatTags: 'Notlar ve Sohbet Etiketleri Ata',
      directCallsToLeadsWithTwilio: 'Twilio ile Potansiyel Müşterilere Doğrudan Aramalar',
      customerSegmentation: 'Müşteri Segmentasyonu',
      exportLeadsInCSV: 'Potansiyel Müşterileri CSV Olarak Dışa Aktar',
      leadNotificationsByEmail: 'Potansiyel Müşteri Bildirimleri E-posta ile',
      website: 'Web Sitesi',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegram',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Mobil/Masaüstü Otomatik Tetikleme',
      hideBotOnSpecificPages: 'Belirli Sayfalarda Botu Gizleme',
      imageSharing: 'Resim Paylaşımı',
      readyToUseChatFlowTemplates: 'Kullanıma Hazır Sohbet Akış Şablonları',
      fileSharing: 'Dosya Paylaşımı',
      customDesignForChatWindow: 'Sohbet Penceresi için Özel Tasarım',
      facebookMessengerBot: 'Facebook Messenger Botu',
      facebookSubheading: 'Facebook Altyazı',
      telegramSubheading: 'Telegram Altyazı',
      conversationalLangdingPageSubheading: 'Konuşma Sayfası Altyazısı',
      integrationsSubheading: 'Entegrasyon Altyazısı',
      multipleSmartFlows: 'Birden Fazla Akıllı Akış',
      dripCampaigns: 'Damla Kampanyaları',
      broadcastFBMessages: 'Facebook Mesajlarını Yayınlama',
      multipleFacebookBotWidgets: 'Birden Fazla Facebook Botu Aracı',
      facebookAutoReplyOnComments: 'Facebook Yorumlarına Otomatik Yanıt',
      facebookECommerce: 'Facebook E-Ticaret',
      broadcastAnalytics: 'Yayın Analitiği',
      telegramMessengerBot: 'Telegram Messenger Botu',
      readyToUseTemplates: 'Kullanıma Hazır Şablonlar',
      groupBroadcastTelegramMessages: 'Grup ve Yayın Telegram Mesajları',
      multipleTelegramBotWidgets: 'Birden Fazla Telegram Botu Aracı',
      conversationalLandingPageBot: 'Konuşma Sayfası Botu',
      smartReplies: 'Akıllı Yanıtlar',
      interactiveAndCustomizableUI: 'Etkileşimli ve Özelleştirilebilir Kullanıcı Arayüzü',
      leadManagement: 'Potansiyel Müşteri Yönetimi',
      easyToInstallAndShare: 'Kolay Kurulum ve Paylaşım',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'Leadsquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Kapat',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Destek Bilet Sistemi',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Sohbet İçi Ses ve Görüntülü Arama (Twilio/Agora)',
      courierTracking: 'Kurye Takibi',
      emailIntegration: 'E-posta Entegrasyonu',
      webhooks: 'Webhooks',
      schedulers: 'Zamanlayıcılar',
      paymentGateways: 'Ödeme Geçitleri (Stripe & RazorPay)',
      enterpriseIntegrations: 'Kurumsal Entegrasyonlar',
      customIntegration: 'Özel Entegrasyon',
      shopifyIntegration: 'Shopify Entegrasyonu',
      totalNumberOfMessages: 'Toplam Mesaj Sayısı',
      totalNumberOfLeads: 'Toplam Potansiyel Müşteri Sayısı',
      weeklyReportBotwise: 'Haftalık Rapor, Bot Bazında',
      averageAgentResponeTime: 'Ortalama Ajan Yanıt Süresi',
      visitorTimingReport: 'Ziyaretçi Zamanlama Raporu',
      customerSegmentBasedReports: 'Müşteri Segmentine Dayalı Raporlar',
      tagBasedReports: 'Etiket Tabanlı Raporlar',
      leadGenerationReport: 'Potansiyel Müşteri Üretim Raporu',
      supportOnWhatsApp: 'WhatsApp Üzerinde Destek',
      knowledgeBase: 'Bilgi Tabanı',
      onboardingAndTraining: 'Kullanıma Alma ve Eğitim',
      callAndEmailSupport: 'Arama ve E-posta Desteği',
      freeChatbotCreation: 'ÜCRETSİZ Sohbet Botu Oluşturma',
      customerSuccessOfficer: 'Müşteri Başarı Görevlisi',
      secureConnection2048BitSSL: 'Güvenli Bağlantı 2048 Bit SSL',
      securityAssessment: 'Güvenlik Değerlendirmesi',
      dataCenterInUSOrEU: 'ABD veya AB\'de Veri Merkezi',
      twoStepVerification: 'İki Adımlı Onay',
      accessRestrictionsBasedOnIPs: 'IP\'lere Dayalı Erişim Sınırlamaları',
      onDemandAuditLog: 'Talep Üzerine Denetim Kaydı',
      bannedVisitorsList: 'Yasaklı Ziyaretçi Listesi',
      customAPIRate: 'Özel API Oranı',
      trustedDomains: 'Güvenilir Alanlar',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Kodlama Meraklısı',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Ücretsiz planımız',
      TOOLTIP_KING_PLAN: 'En popüler planımız',
      TOOLTIP_EMPEROR_PLAN: 'Sınırsız mesaj, sınırsız veri, sınırsız ekip üyesi için özelleştirilmiş plan',
      TOOLTIP_CONTACT_US: 'Daha fazla bilgi almak için WhatsApp veya contact@botpenguin.com adresinden bize ulaşın',
      TOOLTIP_UPGRADE: 'Mevcut planınızı ek özellikler için yükseltin',
      TOOLTIP_MONTHLY: 'Aylık ödeme döngüsünü seçin',
      TOOLTIP_YEARLY: 'İndirim avantajları için yıllık ödeme döngüsünü seçin',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Bebek Planına Geç',
    freeForever: 'SÜREKLİ ÜCRETSİZ',
    features: 'Özellikler',
    currentPlan: 'Mevcut Plan',
    changePlan: 'Planı Değiştir',
    currentlyActive: 'Şu Anda Aktif',
    seeHowToUse: 'Nasıl Kullanılacağını Gör',
    weHaveGotPricingPerfectForYou: 'Sizin için mükemmel bir fiyatlandırma planımız var',
    weBelieve: 'İnanıyoruz ki ',
    shouldBeAccessibleToAllCompanies: ' tüm şirketler için erişilebilir olmalı, boyutu ne olursa olsun.',
    monthly: 'Aylık',
    yearly: 'Yıllık',
    twoMonthsFree: '(2 Ay BEDAVA)',
    kingPlan: 'Kral Planı',
    perYear: 'yılda',
    perMonth: 'aylık',
    conversationsPerMonth: 'Aylık Konuşmalar',
    upgradePlan: 'Planı Yükselt',
    emperorPlan: 'İmparator Planı (Kurumsal)',
    haveAsManyConversationsAsYouNeed: 'İhtiyacınız kadar çok konuşma yapın',
    contactUs: 'Bize Ulaşın',
    metaConversationChargesAreAdditional: 'Meta konuşma ücretleri, kullanımınıza bağlı olarak doğrudan sizin tarafınızdan Meta\'ya ödenen ek ücretlerdir. Herhangi bir işaretleme veya kolaylaştırma ücreti talep etmiyoruz.',
    viewDetailedPricing: 'Ayrıntılı Fiyatlandırmayı Görüntüle',
    conversationPricingModelUsedByMeta: 'Meta tarafından kullanılan Konuşma Fiyatlandırma Modeli',
    whatsappBusinessPlatformConversationsFallInto: 'WhatsApp İş Platformu konuşmaları farklı fiyatlandırılan iki kategoriye ayrılır',
    userInitiatedConversation: 'Kullanıcı Başlatılan Konuşma',
    userInitiatedConversationDescription: 'Kullanıcı mesajına yanıt olarak başlatılan bir konuşma. İşletme, 24 saatlik müşteri hizmeti penceresi içinde bir kullanıcıya yanıt verdiğinde, o mesaj bir kullanıcı başlatılan konuşma ile ilişkilendirilecektir. İşletmeler, bu 24 saatlik müşteri hizmeti penceresi içinde serbest form mesajlar gönderebilirler.',
    businessInitiatedConversation: 'İşletme Başlatılan Konuşma',
    businessInitiatedConversationDescription: 'İşletme tarafından 24 saatlik müşteri hizmeti penceresi dışında bir kullanıcıya mesaj göndererek başlatılan bir konuşma. İşletme tarafından başlatılan bir konuşma, bir mesaj şablonu gerektirir.',
    areYouSureYouWantTo: 'Şu anki ',
    conversations: ' Konuşmalar ',
    plan: ' planından emin misiniz?',
    toThe: ' ',
    metaPricingMarkup: 'Meta Fiyatlandırma İşaretleme',
    numberOfChatFlows: 'Sohbet Akışı Sayısı',
    greenTickApplication: 'Yeşil Onay Başvurusu',
    extraPhoneNumberLinking: 'Ek Telefon Numarası Bağlama',
    teamMembers: 'Ekip Üyeleri',
    dataStorage: 'Veri Depolama',
    conditionalFlow: 'Koşullu Akış',
    liveChat: 'Canlı Sohbet',
    smartInbox: 'Akıllı Gelen Kutusu',
    whatsappTemplates: 'WhatsApp Şablonları',
    customerSegments: 'Müşteri Gruplandırması',
    broadcastAndDripCampaigns: 'Yayın ve Damla Kampanyaları',
    multipleLanguages: 'Birden Fazla Dil',
    bookAppointments: 'Randevu Al',
    csvContactsExport: 'CSV Kişileri Dışa Aktar',
    googleCalendarIntegrations: 'Google Takvim Entegrasyonları',
    nativeApplications: '60+ Yerel Entegrasyon',
    whatsappSupport: 'WhatsApp Desteği',
    emailAndCallSupport: 'E-posta ve Arama Desteği',
    customerIntegrations: 'Müşteri Entegrasyonları',
    nlpAndNluCapabilities: 'NLP ve NLU Yetenekleri',
    chatGPTBot: 'ChatGPT sohbet botu',
    customerSuccessOfficer: 'Müşteri Başarı Görevlisi',
    noMarkupTransparency: 'İşaretleme şeffaflığı yok',
    upto500kPerMonth: 'Aya kadar 500,000',
    beyond500k: '500,000 ötesi',
    unlimited: 'Sınırsız',
    oneTeamMember: '1 Ekip Üyesi',
    unlimitedTeamMember: 'Sınırsız Ekip Üyeleri',
    nintyDaysOfDataStorage: '90 gün veri depolama',
    unlimitedDataStorage: 'Sınırsız veri depolama',
    downgradeToFreePlan: 'ÜCRETSİZ Planına Dönüş Yap',
    detailedPricing: {
      onDemandAuditLog: 'Talep Üzerine Denetim Günlüğü',
      accessRestrictionsBasedOnIPs: '\'IP\'ler Temelinde Erişim Kısıtlamaları',
      averageAgentResponeTime: 'Ortalama Ajan Yanıt Süresi',
      perMonth100: 'Aylık 100',
      solutionType: 'Çözüm Türü',
      saas: 'SaaS',
      saasOnPremises: 'Yerinde SaaS',
      emperorPlan: 'İmparator Planı',
      babyPlan: 'Bebek Planı',
      kingsplan: 'Kral Planı',
      broadcastsAndDrips: 'Yayınlar ve Damlalar',
      thirtyDays: '30 Gün',
      ninetyDays: '90 Gün',
      oneMember: '1 Üye',
      threeMembers: '3 Üye',
      one: '1 (Bir)',
      unlimitedMessages: 'Sınırsız Mesajlar',
      unlimitedBots: 'Sınırsız Botlar',
      messagesSend: 'Gönderilen Mesajlar',
      upto500000perMonth: 'Aya Kadar 500,000',
      unlimited: 'Sınırsız',
      onlyAdmin: 'Yalnızca Yönetici',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Kendin Yap',
      weAreHereToHelp: 'Yardım Etmek İçin Buradayız',
      weTakeCareEndToEnd: 'Baştan Sona Bakıyoruz',
      numberOfBots: 'Bot Sayısı',
      conversations: 'Görüşmeler',
      gptMessages: 'GPT Mesajları',
      whatsappNumbers: 'WhatsApp Numaraları',
      subscribersLeads: 'Aboneler / Potansiyel Müşteriler',
      createSupportTeamForLiveChat: 'Canlı Sohbet İçin Destek Ekibi Oluşturun',
      conversationHistory: 'Sohbet Geçmişi',
      chatbotDesigning: 'Sohbet Botu Tasarımı',
      multipleLanguages: 'Çoklu Diller',
      conditionalFlow: 'Koşullu Akış',
      whatsappTemplates: 'WhatsApp Şablonları',
      restartChatFlowTriggers: 'Sohbet Akışı Tetikleyicilerini Yeniden Başlat',
      multipleWhatsAppWidgets: 'Çoklu WhatsApp Widgetleri',
      importWhatsappContacts: 'WhatsApp Kişilerini İçe Aktar',
      whatsappQuickReply: 'WhatsApp Hızlı Yanıt',
      whatsappSmartInbox: 'WhatsApp Akıllı Gelen Kutusu',
      whatsappEcommerceCatalogs: 'WhatsApp E-Ticaret Katalogları',
      whatsappBroadcast: 'WhatsApp Yayını',
      whatsappBroadcastAnalytics: 'WhatsApp Yayın Analitiği',
      dripCampaigns: 'Damla Kampanyaları',
      customerSegmentation: 'Müşteri Segmentasyonu',
      keywordBasedResponse: 'Anahtar Kelime Tabanlı Yanıt',
      whatsappShopManagement: 'WhatsApp Mağaza Yönetimi',
      aiChatbot: 'AI Sohbet Botu',
      chatGPTIntegration: 'ChatGPT Entegrasyonu',
      configureAIChatbotPersonality: 'AI Sohbet Botu Kişiliğini Yapılandır',
      trainChatbotOnYourOwnData: 'Kendi Verilerinizle Sohbet Botunu Eğitin',
      trainChatbotOnWebPages: 'Web Sayfalarında Sohbet Botu Eğitin',
      trainChatbotOnMultipleFiles: 'Çoklu Dosyalarda Sohbet Botu Eğitin',
      trainChatbotOnFAQs: '\'SSS\'lerde Sohbet Botu Eğitin\'',
      dataTrainingStorage: 'Veri Eğitim Depolama',
      useChatGPTModel4or35Turbo: 'ChatGPT Model 4/3.5 Turbo Kullanın',
      uploadMultipleFilesForChatbotTraining: 'Sohbet Botu Eğitimi için Birden Fazla Dosya Yükleyin',
      naturalLanguageProcessing: 'Doğal Dil İşleme',
      naturalLanguageUnderstanding: 'Doğal Dil Anlama',
      answerLength: 'Yanıt Uzunluğu',
      chatTone: 'Sohbet Tonu',
      multiLanguage: 'Çoklu Dil',
      answerFormatting: 'Yanıt Biçimlendirme',
      customPrompt: 'Özel İstek',
      whatsAppSessionManagement: 'WhatsApp Oturum Yönetimi (Etkinlik ve Akışı Yeniden Başlatma Mesajları)',
      integrateBotPenguinLLM: 'BotPenguin LLM Entegrasyonu',
      integrateOwnCustomLLM: 'Kendi Özel LLM Entegrasyonu',
      periodicallySyncDataSources: 'Periyodik Veri Kaynakları Senkronizasyonu',
      aiBasedSuggestedQuestions: 'AI Tabanlı Önerilen Sorular',
      chooseVisibilityForParticularWebpageSitemap: 'Belirli Web Sayfası / Site Haritası için Görünürlüğü Seçin',
      chooseKnowledgeMix: 'Bilgi Karışımını Seçin: Sizin Bilgi Tabanınız ChatGPT Bilgisi "ChatGPT Bilgisi + Sizin Bilgi Tabanınız"',
      faqExtractionFromFileAndURL: 'Dosyadan ve URL\'den SSS Çıkarma',
      analyticsToImproveAI: '\'AI\'yi Geliştirmek İçin Analitik',
      exportQuestionFlow: 'Soru Akışını Dışa Aktar',
      collectFeedbackOnChatbotResponses: 'Sohbet Botu Yanıtları Hakkında Geri Bildirim Toplayın',
      smartAIFollowUpQuestions: 'Akıllı AI Takip Soruları',
      trainOnYourNotionWorkspaceData: 'Notion Çalışma Alanı Verilerinizde Eğitim Yapın',
      trainOnYoutubeVideo: 'YouTube Videosunda Eğitim Yapın',
      trainOnCustomVideoUpload: 'Özel Video Yükleme ile Eğitim Yapın',
      rssFeed: 'RSS Beslemesi',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'İnsan Devri',
      botHandover: 'Bot Devri',
      handoverBetweenAgents: 'Ajanlar Arası Devir',
      emailAndMobileValidation: 'E-posta ve Mobil Doğrulama',
      mobileAppsForAgents: 'Ajanlar için Mobil Uygulamalar',
      appointmentBooking: 'Randevu Rezervasyonu',
      roleBasedAccessControls: 'Rol Tabanlı Erişim Kontrolleri',
      ruleBasedChatRouting: 'Kural Tabanlı Sohbet Yönlendirmesi',
      liveVisitorsDetails: 'Canlı Ziyaretçi Detayları',
      multipleSimultaneousChats: 'Çoklu Eşzamanlı Sohbetler',
      visitorStats: 'Ziyaretçi İstatistikleri',
      addContactsFromWhatsApp: 'WhatsApp\'tan Kişiler Ekleyin',
      applyFilterAndSortConversations: 'Filtre Uygulayın ve Konuşmaları Sıralayın',
      seeChatLogs: 'Sohbet Kayıtlarını Görüntüle',
      quickReplies: 'Hızlı Yanıtlar',
      unifiedCustomerProfile: 'Birleşik Müşteri Profili',
      assignNotesAndChatTags: 'Notlar ve Sohbet Etiketleri Atama',
      exportLeadsInCSV: 'Perspektifleri CSV Olarak Dışa Aktar',
      tagContacts: 'Kişileri Etiketleme',
      customAttributes: 'Özel Özellikler',
      markStatusOfConversations: 'Konuşmaların Durumunu İşaretleme',
      assignConversationToAgents: 'Konuşmayı Ajanlara Atama',
      leadNotificationsByEmail: 'E-posta İle Perspektif Bildirimleri',
      directCallsToLeadsWithTwilio: 'Perspektiflere Twilio ile Doğrudan Aramalar',
      whatsAppTemplates: 'WhatsApp Şablonları',
      promotionalMessageFrequencyControl: 'Promosyonel Mesaj Sıklığı Kontrolü',
      whatsAppWidget: 'WhatsApp Widgeti',
      whatsAppQR: 'WhatsApp QR',
      whatsAppLink: 'WhatsApp Bağlantısı',
      campaignScheduler: 'Kampanya Zamanlayıcısı',
      customTriggers: 'Özel Tetikleyiciler',
      customCampaigns: 'Özel Kampanyalar',
      repeatPurchaseReminders: 'Tekrarlama Satın Alma Hatırlatıcıları',
      campaignOpenRateTracking: 'Kampanya Açılma Oranı İzleme',
      paymentCollectionOnWhatsApp: 'WhatsApp Üzerinden Ödeme Toplama',
      agileCRM: 'Agile CRM',
      capsule: 'Kapsül',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Boru Hattı',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Kapat',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Canlı Ajan',
      ontraport: 'Ontraport',
      frontapp: 'FrontApp',
      webhoper: 'Webhoper',
      engagebay: 'EngageBay',
      helpshift: 'Helpshift',
      copper: 'Bakır',
      autopilot: 'Otamatik Pilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Tablolar',
      googleCalendar: 'Google Takvim',
      googleAnalytics: 'Google Analitik',
      supportTicketingSystem: 'Destek Bilet Sistemi',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Sesli ve Video Çağrı ile Sohbet (Twilio/Agora)',
      courierTracking: 'Kurye Takibi',
      emailIntegration: 'E-posta Entegrasyonu',
      webhooks: 'Webhooks',
      schedulers: 'Zamanlayıcılar',
      paymentGateways: 'Ödeme Geçitleri (Stripe & RazorPay)',
      enterpriseIntegrations: 'Kurumsal Entegrasyonlar',
      customIntegration: 'Özel Entegrasyon',
      freeWhatsAppAPIApprovalAndSetup: 'Ücretsiz WhatsApp API Onayı ve Kurulumu',
      developerAPIsAccess: 'Geliştirici API Erişimi',
      customRateLimit: 'Özel Hız Sınırlama',
      totalNumberOfLeads: 'Toplam Prospect Sayısı',
      averageAgentResponseTime: 'Ortalama Ajan Yanıt Süresi',
      visitorTimingReport: 'Ziyaretçi Zamanlama Raporu',
      customerSegmentBasedReports: 'Müşteri Segment Tabanlı Raporlar',
      tagBasedReports: 'Etiket Tabanlı Raporlar',
      leadGenerationReport: 'Prospect Oluşturma Raporu',
      supportOnWhatsApp: 'WhatsApp Üzerinde Destek',
      knowledgeBase: 'Bilgi Tabanı',
      onboardingAndTraining: 'Kullanıcı Devri ve Eğitimi',
      callAndEmailSupport: 'Arama ve E-posta Desteği',
      freeChatbotCreation: 'Ücretsiz Sohbet Botu Oluşturma',
      customerSuccessOfficer: 'Müşteri Başarı Görevlisi',
      secureConnection: '2048 Bit SSL Güvenli Bağlantı',
      securityAssessment: 'Güvenlik Değerlendirmesi',
      dataCenterLocation: 'Veri Merkezi Konumu (ABD veya AB)',
      twoStepVerification: 'İki Adımlı Doğrulama',
      accessRestrictions: 'IP Temelli Erişim Kısıtlamaları',
      auditLog: 'Talep Üzerine Denetim Günlüğü',
      bannedVisitorsList: 'Yasaklanmış Ziyaretçi Listesi',
      customAPIRate: 'Özel API Hızı',
      trustedDomains: 'Güvenilir Alanlar',
      elementary: 'Temel',
      bots: 'Botlar',
      unlockWhatsapp: 'WhatsApp Kilidini Aç',
      aiChatbotFeatures: 'AI Sohbet Botu Özellikleri',
      dataSourceForTraining: 'Eğitim İçin Veri Kaynağı',
      smartFeatures: 'Akıllı Özellikler',
      liveChat: 'Canlı Sohbet',
      smartInbox: 'Akıllı Gelen Kutusu',
      // broadcastsAndDrips: 'Yayınlar ve Damlalar',
      integrations: 'Entegrasyonlar',
      development: 'Geliştirme',
      reportsAndAnalytics: 'Raporlar ve Analitik',
      customerSupport: 'Müşteri Desteği',
      security: 'Güvenlik',
      whatsappWidgets: 'WhatsApp Widgetleri',
      campaignSchedular: 'Kampanya Zamanlayıcısı',
      repeatPurchaseRemainder: 'Tekrar Satın Alma Hatırlatıcıları',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Ücretsiz planımız',
      TOOLTIP_KING_PLAN: 'En popüler planımız',
      TOOLTIP_EMPEROR_PLAN: 'Sınırsız mesaj, sınırsız veri, sınırsız ekip üyeleri için özelleştirilmiş plan',
      TOOLTIP_CONTACT_US: 'Daha fazla bilgi için WhatsApp veya contact@botpenguin.com adresinden bize ulaşın ve özelleştirilmiş planlarımızı öğrenin',
      TOOLTIP_UPGRADE: 'Mevcut planınızı ek özellikler için yükseltin',
      TOOLTIP_MONTHLY: 'Aylık ödeme döngüsünü seçin',
      TOOLTIP_YEARLY: 'İndirim avantajları için yıllık ödeme döngüsünü seçin',
    },
  }
};
