import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { LanguageService } from 'src/appv2/services/language.service';
import { NetworkService } from 'src/appv2/services/network.service';
import { NotificationService } from 'src/appv2/services/notification.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {
  liveChatNotificationsList: any = [];
  isRender = false;
  staticText: any;
  constructor(
    private notificationSrvc: NotificationService,
    private sharedSrvc: SharedService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().notification.notificationList;
   }

  ngOnInit(): void {
  this.getNotifications();
  }
  getNotifications() {
    this.sharedSrvc.loader('start');
    this.notificationSrvc.getRequestNotifications()
    .then((res: any) => {
      this.liveChatNotificationsList = res?.details;
    })
    .catch(err => {
      this.sharedSrvc.showToast('error', '', err.message || err);
    })
    .finally(() => {
      this.sharedSrvc.loader('stop');
      this.isRender = true;
    });
  }
}
