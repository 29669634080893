export const team = {
  addMember: {
    team: 'Team',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    addAMember: 'Fügen Sie ein Mitglied hinzu',
    addYourTeamMembersText: 'Fügen Sie Ihre Teammitglieder als Support -Agenten hinzu, um mit Bot -Benutzern zu live -Chat zu live.',
    name: 'Name',
    nameIsRequired: 'Name ist erforderlich',
    email: 'Email',
    enterAValidEmail: 'Geben Sie eine gültige E -Mail ein',
    password: 'Passwort',
    passwordMustContain: 'Passwort muss enthalten',
    atleast6Characters: 'mindestens 6 Zeichen,',
    oneUpperCaseLetter: '1 Großbuchstabe',
    oneSpecialCharacter: '1 besonderer Charakter*',
    profileIcon: 'Profil -Symbol',
    whatsappNumber: 'WhatsApp -Nummer',
    leadVisibility: 'Sichtbarkeit von Blei*',
    addMember: 'Mitglied hinzufügen',
    role: 'Rolle',
    createdOn: 'Erstellt am',
    action: 'Aktion',
    updateMember: 'Aktualisieren Sie Mitglied',
    add: 'Hinzufügen',
    cancel: 'Stornieren',
    selectVisibilityOption: 'Wählen Sie die Option Sichtbarkeit',
    all: 'Alle',
    assigned: 'Zugewiesen',
    nA: 'N / A',
    admin: 'Administrator',
    agent: 'Agent',
    placeholders: {
      enterMemberName: 'Geben Sie den Mitgliedsnamen ein',
      enterMemberEmailAddress: 'Geben Sie die E -Mail -Adresse ein',
      createPasswordForMember: 'Erstellen Sie ein Passwort für Mitglied',
      searchCountry: 'Suchland',
      enterWhatsappNumber: 'Geben Sie die WhatsApp -Nummer ein',
      selectLeadsVisibility: 'Wählen Sie die Sichtbarkeit von Leads',
      searchTeamByNameOrEmail: 'Suchen Sie das Teammitglied nach Namen oder E -Mail',
    },
    modal: {
      deleteTeamMember: 'Teammitglied löschen',
      wantToDeleteThisTeamMember: 'Sind Sie sicher, dass Sie dieses Teammitglied löschen möchten?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Klicken Sie hier, um ein neues Teammitglied hinzuzufügen',
      profileIconOfTeamMember: 'Profilsymbol/Bild des Teammitglieds',
      nameOfTeamMember: 'Name des Teammitglieds',
      emailOfTeamMember: 'E -Mail des Teammitglieds',
      whatsappNumberOfTeamMember: 'WhatsApp -Anzahl von Teammitgliedern',
      roleOfTeamMemberAsAdminOrAgent: 'Rolle des Teammitglieds als Administrator/Agent',
      dateAndTimeOfCreatingTeamMemberRole: 'Datum und Uhrzeit der Erstellung von Teammitgliedsrolle',
      editOrDeleteTeamMember: 'Teammitglied bearbeiten/löschen',
      editTeamMembersDetails: 'Bearbeiten Sie die Details des Teammitglieds.',
      removeAccessOfTeamMemberByDeletingAccount: 'Entfernen Sie den Zugriff auf das Teammitglied, indem Sie Konto löschen',
    }
  },
  users: {
    manageMembers: 'Mitglieder verwalten',
    managerMembersWhichCanAccessPanel: 'Verwalten Sie Ihr Partner-Team, das auf Ihr Dashboard zugreifen kann',
    createNewMember: 'Neues Mitglied erstellen',
    createMember: 'Mitglied erstellen',
    updateMember: 'Mitglied aktualisieren',
    deleteTitleMember: 'Mitglied löschen',
    deleteDescriptionMember: 'Sind Sie sicher, dass Sie dieses Mitglied löschen möchten?',
    noMemberFound: 'Kein Mitglied gefunden!',
    titleMember: 'Erstellen Sie ein Mitglied',
    manageUsers: 'Benutzer verwalten',
    createNewUser: 'Neuen Benutzer erstellen',
    managerUserWhichCanAccessPanel: 'Verwalten Sie Benutzer, die auf Ihr Panel zugreifen können',
    phoneNumber: 'Telefonnummer',
    discard: 'Verwerfen',
    createUser: 'Benutzer erstellen',
    updateUser: 'Benutzer aktualisieren',
    noUserFOund: 'Kein Benutzer gefunden!',
    title: 'Einen Benutzer erstellen',
    profileIcon: 'Profilbild*',
    name: 'Name*',
    email: 'E-Mail*',
    password: 'Passwort*',
    selectRole: 'Rolle auswählen*',
    selectARole: 'Selecteer een rol',
    selectLeadVisibility: 'Lead-Sichtbarkeit auswählen*',
    confirmPassword: 'Passwort bestätigen*',
    tableHeadingName: 'Name',
    tableHeadingEmail: 'E-Mail',
    tableHeadingPhoneNumber: 'Telefonnummer',
    tableHeadingRole: 'Rolle',
    tableHeadingStatus: 'Status',
    tableHeadingAction: 'Aktion',
    noUserFound: 'Kein Benutzer Gefunden!',
    deleteTitle: 'Benutzer Löschen',
    deleteDescription: 'Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?',
    placeholders: {
      searchHere: 'Hier suchen',
      enterName: 'Name eingeben',
      enterEmail: 'E-Mail eingeben',
      searchCountry: 'Land suchen',
      enterPhoneNumber: 'Telefonnummer eingeben',
      enterPassword: 'Passwort eingeben',
      enterConfirmPassword: 'Passwort bestätigen'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Benutzer löschen',
      TOOLTIP_DELETE_MEMBERS: 'Mitglieder löschen'
    }
  },
  roles: {
    manageRoles: 'Rollen verwalten',
    manageYourRoles: 'Verwalten Sie Ihre Rollen.',
    name: 'Name*',
    description: 'Beschreibung',
    discard: 'Verwerfen',
    createRole: 'Rolle erstellen',
    creteARole: 'Eine Rolle erstellen',
    updateRole: 'Rolle aktualisieren',
    assignPermissions: 'Berechtigungen zuweisen',
    noUserFound: 'Keine Benutzerrollen gefunden!',
    tableHeadingName: 'Name',
    tableHeadingDescription: 'Beschreibung',
    tableHeadingStatus: 'Status',
    tableHeadingAction: 'Aktion',
    module: 'Modul',
    read: 'Lesen',
    write: 'Schreiben',
    remove: 'Entfernen',
    notes: 'Notizen',
    deleteTitle: 'Rolle löschen',
    deleteDescription: 'Achtung: Wenn Sie diese Rolle löschen, werden auch alle Benutzer, die dieser Rolle zugewiesen sind, gelöscht. \n Sind Sie sicher, dass Sie diese Rolle löschen möchten?',
    deleteMemberDescription: 'Vorsicht: Wenn Sie diese Rolle löschen, werden auch alle Mitglieder, die dieser Rolle zugewiesen sind, gelöscht. Sind Sie sicher, dass Sie diese Rolle löschen möchten?',
    placeholders: {
      enterRoleName: 'Geben Sie den Rollennamen ein',
      searchHere: 'Hier suchen',
      enterDescriptionForRole: 'Geben Sie eine Beschreibung für die Rolle ein'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Abschnitte, für die Sie Benutzern den Zugriff erlauben oder verweigern können',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Klicken Sie hier, um eine neue Rolle hinzuzufügen',
    }
  },
  accessControl: {
    users: 'Benutzer',
    partnerTeam: 'Partner-Team',
    members: 'Mitglieder',
    roles: 'Rollen'
  }
};
