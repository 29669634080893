export const developer = {
  developers: 'Desarrolladores',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Empodera a tus desarrolladores para aprovechar todo el potencial de tu panel de control',
  accessToken: 'Token de Acceso',
  yourAPI: 'Tus APIs',
  api: {
    apiManagement: 'Gestión de APIs',
    apiManagementSubHeading: 'Agrega, configura, prueba y gestiona tus APIs, que puedes usar para desencadenar flujos de chat.',
    addApi: 'Agregar API',
    createApi: 'Crear una API',
    noApiFound: '¡No se encontraron APIs!',
    deleteApis: 'Eliminar APIs',
    areYouSureYouWantToDeleteAPI: '¿Estás seguro de que deseas eliminar las API?',
    placeholders: {
      search: `Buscar por nombre de API`
    },
    tableHead: {
      method: 'Método',
      apiName: 'Nombre de la API',
      url: 'URL',
      actions: 'Acciones'
    },
    parametersTab: 'Parámetros',
    headersTab: 'Encabezados',
    bodyTab: 'Cuerpo',
    manageApiResponses: 'Gestionar respuestas de API',
    chooseVariableToSave: 'Elegir variable para guardar',
    selectCustomAttribute: 'Seleccionar atributo personalizado',
    subTab: {
      key: 'Clave',
      enterKey: 'Ingresar Clave',
      value: 'Valor',
      enterValue: 'Ingresar Valor',
      addAnotherField: 'Agregar otro campo',
      save: 'Guardar',
      useslashToInsertCustomVariables: `Usa '/' para insertar variables personalizadas o valores dinámicos de la respuesta del usuario.`,
      response: 'Respuesta',
      apiRequestBody: 'Cuerpo de Solicitud de API',
      hit: 'Éxito',
      test: 'Prueba',
      buttonToGetAResponse: 'Botón para obtener una respuesta',
      variable: 'Variable',
      saveToCustomAttribute: 'Guardar en Atributo Personalizado'
    },
    apiLogs: {
      apiManagement: 'Gestión de APIs',
      addConfigureTestAndManageYourAPIs: 'Agrega, configura, prueba y gestiona tus APIs, que puedes usar para desencadenar flujos de chat.',
      searchHere: 'Buscar por código de estado',
      deleteApis: 'Eliminar APIs',
      testAnApi: 'Probar una API',
      noAPILogFound: 'No se encontraron registros de API',
      responseCode: 'Código de Respuesta',
      message: 'Mensaje',
      apiResponse: 'Respuesta de API',
      actions: 'Acciones',
      deleteAPIs: 'Eliminar APIs',
      areYouSureYouWantToDelete: '¿Estás seguro de que deseas eliminar esta API?',
      apiLogs: 'Registros de API',
      subTitle: 'Registro detallado de todas las solicitudes y respuestas de API realizadas dentro del flujo de chat del chatbot.'
    }
  },
  configApi: {
    configureApi: `Configurar API`,
    configureApiSubHeading: `Puedes configurar tu API basada en JSON aquí y usarla para enriquecer tu flujo de chat.`,
    apiLogs: `Registros de API`,
    test: `Prueba`,
    manageApiResponse: `Gestionar respuestas de API`,
    body: `Cuerpo`,
    headers: `Encabezados`,
    parameters: `Parámetros`,
    placeholders: {
      enterApiName: `Ingresar nombre de API`,
      enterApiUrl: `Ingresar URL de API`
    }
  }

};
