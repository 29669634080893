import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './ngrx/reducers';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './authentication/new-password/new-password.component';
import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { SharedModule } from './modules/shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfirmEmailComponent } from './authentication/confirm-email/confirm-email.component';
import { ChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { NotificationModule } from './modules/notification/notification.module';
import { GoogleRedirectComponent } from './modules/bot-builder/components/integrations/components/all-integration/components/providers/google-redirect/google-redirect.component';
import { NewSignupComponent } from './authentication/new-signup/new-signup.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { NotifyComponent } from './modules/shared/notify/notify.component';
import { LoginWrapperComponent } from './authentication/login-wrapper/login-wrapper.component';
import { UnsubscribeComponent } from './modules/unsubscribe/unsubscribe.component';
import { environment } from '../environments/environment';

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    VerifyEmailComponent,
    LogoutComponent,
    ConfirmEmailComponent,
    GoogleRedirectComponent,
    NewSignupComponent,
    NotifyComponent,
    LoginWrapperComponent,
    UnsubscribeComponent
  ],
  imports: [
    RouterModule,
    ColorPickerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    SocialLoginModule,
    ChartsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true,
      countDuplicates: true
    }),
    FormsModule,
    ReactiveFormsModule,
    NotificationModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatChipsModule,
    MatRadioModule
  ],
  bootstrap: [AppComponent],
  exports: [],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '593742482865-t7ln3krn11dms37tif7fqfc5tktb9969.apps.googleusercontent.com'
            )
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ]
})
export class AppModule2 {
}
