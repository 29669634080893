export const notification = {
  notification: 'Benachrichtigungen',
  allNotifications: 'Alle Benachrichtigungen',
  general: 'Allgemein',
  settings: 'Einstellungen',
  newChatRequest: {
    youHaveANewChatRequest: 'Sie haben eine neue Chat -Anfrage !!!',
    youHaveTransferredChatRequest: 'Sie haben die Chat -Anfrage übertragen !!!',
    acceptRequest: 'Anfrage annehmen',
    discardRequest: 'Anfrage verwerfen',
  },
  notificationList: {
    name: 'Name',
    message: 'Nachricht',
    noDataFound: {
      notification: 'Benachrichtigung',
      noNotificationsFound: 'Keine Benachrichtigungen gefunden!',
    }
  },
  notificationSetting: {
    userMessage: 'Benutzernachricht',
    receiveNotificationsWhenUserSendMessage: 'Erhalten Sie Benachrichtigungen, wenn ein Benutzer eine Nachricht sendet',
    liveChatRequest: 'Live -Chat -Anfrage',
    receiveNotificationsWhenUserRequestLiveChat: 'Erhalten Sie Benachrichtigungen, wenn eine Benutzeranforderung für Live -Chat anliegt',
    newLead: 'Neue Führung',
    receiveNotificationsWhenNewLeadCreated: 'Erhalten Sie Benachrichtigungen, wenn neue Leads erstellt werden',
    assignedOnly: 'Nur zugewiesen',
    allUsers: 'Alle Nutzer',
    customizeWhichNotificationsYouWantToRecieve: 'Passen Sie an, welche Benachrichtigungen Sie erhalten möchten',
    youCanCustomizeSettingForTheNotifications: 'Sie können die Einstellung für die Benachrichtigungen anpassen',
    receiveNotifications: 'Benachrichtigungen erhalten',
    desktopNotifications: 'Desktop-Benachrichtigungen',
    mobilePushNotifications: 'Mobile Push -Benachrichtigungen',
    discard: 'Verwerfen',
    update: 'Aktualisieren',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Senden Sie Benachrichtigungen über neue Nachricht über den Desktop',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Senden Sie Benachrichtigungen über neue Nachricht über Mobilgeräte',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Senden Sie Benachrichtigungen über neue Leads über den Desktop',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Senden Sie Benachrichtigungen über neue Leads über Mobilgeräte',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Senden Sie Benachrichtigungen über Live -Chat über den Desktop',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Senden Sie Benachrichtigungen über Live -Chat über Mobile',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Wählen Sie aus, für welche Nachrichten Sie benachrichtigt werden möchten',
    }
  }
};
