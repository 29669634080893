const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'عدل تدفق الدردشة الخاص بك',
    seeHowToUse: 'راجع كيفية الاستخدام',
    help: 'المساعدة',
    classicBuilder: 'منشئ كلاسيكي',
    visualiseFlow: 'تصور التدفق',
    testLiveOnTelegramWeb: 'اختبار مباشر على موقع تليجرام الويب',
    testFlow: 'اختبار التدفق',
    importTemplate: 'استيراد القالب',
    landingPageBotReview: 'معاينة بوت صفحة الهبوط',
    install: 'تثبيت',
    testLiveOnMessengerWeb: 'اختبار مباشر على موقع ماسنجر الويب',
    testLiveOnWhatsappWeb: 'اختبار مباشر على موقع واتساب الويب',
    testLiveOnInstagramWeb: 'اختبار مباشر على موقع إنستجرام الويب',
    seeAllChatFlow: 'عرض كل تدفق الدردشة',
    launchDemoModal: 'شريط تمهيد العرض التوضيحي',
    selectATemplateToKickStartYourBot: 'حدد قالبًا لبدء بوتك',
    hireChatBotDesigner: 'توظيف مصمم بوت الدردشة',
    recommendedForYou: 'موصى به لك',
    useTemplate: 'استخدم القالب',
    exportStatus: 'حالة التصدير',
    flowExport: 'تصدير التدفق',
    download: 'تنزيل',
    importFlow: 'استيراد التدفق',
    beforeYouStartUploadPleaseMakeSure: 'قبل أن تبدأ التحميل ، يرجى التأكد من',
    ponit1: '1. الحد الأقصى لحجم الملف المسموح به هو 20 ميجابايت',
    point2: '2. تنسيق الملف يجب أن يكون JSON',
    selectFile: 'حدد الملف',
    preview: 'معاينة',
    test: 'اختبار',
    OopsIntegrationAreNotAvailable: 'عذرًا! التكاملات غير متاحة في ',
    pleaseUpgradeTo: ' خطة. يرجى الترقية إلى ',
    planForIntegration: ' خطة للتكاملات.',
    placeholders: {
      searchTemplateYouNeed: 'ابحث عن القالب الذي تحتاجه',
    },
    noDataFound: {
      botFlow: 'تدفق البوت',
      noBotFlowAddedYet: 'لم يتم إضافة أي تدفق بوت بعد!',
      templates: 'القوالب',
      noTemplatesFound: 'لم يتم العثور على قوالب!',
    },
    appTooltip: {
      exportFlow: 'تصدير التدفق',
      importFlow: 'استيراد التدفق',
      clickToViewChatFlow: 'انقر لعرض تدفق الدردشة في منشئ السحب والإفلات',
      clickToPreviewChatFlow: 'انقر لمعاينة تدفق الدردشة',
      clickToViewChatFlowAsFlowDiagram: 'انقر لعرض تدفق الدردشة كرسم توضيحي للتدفق',
      clickToTestChatFlow: 'انقر لاختبار تدفق الدردشة',
      clickToSelectAndImportTemplate: 'انقر لتحديد واستيراد القالب الذي يناسب أعمالك.',
      clickToInstallYourBotToSelectedPlatform: 'انقر لتثبيت بوتك على المنصة المحددة',
      clickToTestDefaultFlowOfTelegramBot: 'انقر لاختبار تدفق البوت الافتراضي (النشط) على موقع تليجرام على الويب',
      clickToTestDefaultFlowOfFacebookBot: 'انقر لاختبار تدفق البوت الافتراضي (النشط) على موقع فيسبوك على الويب',
      clickToTestDefaultFlowOfWhatsappBot: 'انقر لاختبار تدفق البوت الافتراضي (النشط) على موقع واتساب على الويب',
      clickToTestDefaultFlowOfInstagramBot: 'انقر لاختبار تدفق البوت الافتراضي (النشط) على موقع إنستجرام على الويب',
    }
  },
  createNewBot: {
    createNewBot: 'إنشاء بوت جديد',
    stepOneOfTwo: 'الخطوة 1 من 2',
    selectThePlatform: 'حدد المنصة',
    selectTheBotType: 'حدد نوع البوت',
    aiBasedChatbot: 'بوت الدردشة قائم على الذكاء الاصطناعي',
    ruleBasedChatBot: 'بوت الدردشة قائم على القواعد',
    webPlatforms: 'منصات الويب',
    websiteChatBot: 'بوت الدردشة على الموقع',
    withLiveChat: '(مع الدردشة المباشرة)',
    landingPageBot: 'بوت صفحة الهبوط',
    liveChatWidget: 'مربع الدردشة المباشرة',
    onlyLiveChat: '(فقط الدردشة المباشرة)',
    socialPlatforms: 'منصات التواصل الاجتماعي',
    whatsappChatBot: 'بوت الدردشة على الواتساب',
    telegramChatBot: 'بوت الدردشة على التليجرام',
    facebookChatBot: 'بوت الدردشة على فيسبوك',
    instagramChatBot: 'بوت الدردشة على إنستجرام',
    selectBotType: 'حدد نوع البوت',
    selectProblem: 'اختر المشكلة التي ترغب في حلها',
    leadGeneration: 'جيل العملاء',
    customerSupport: 'دعم العملاء',
    appointmentBooking: 'حجز مواعيد',
    marketingAutomation: 'تسويق آلي',
    ecommerceWebsite: 'موقع التجارة الإلكترونية',
    otherUseCase: 'حالة استخدام أخرى',
    back: 'رجوع',
    typeDomainUrl: 'اكتب عنوان موقع الويب الخاص بك للتحقق.',
    upload: 'رفع',
    uploadDocuments: 'تحميل المستندات',
    uploadDocument: 'تحميل الوثيقة',
    selectFile: 'اختر ملف',
    continue: 'استمرار',
    websiteUrl: 'اكتب عنوان موقع الويب الخاص بك',
    skipNow: 'تخطي الآن',
    completeTheExistingBotSetup: 'اكمل تركيب البوت الحالي',
    youHaveAlreadyCreatedOneWhatsAppBot: 'لقد قمت بإنشاء بوت واتساب واحد بالفعل، يرجى استكمال الإعداد للبوت الحالي لإنشاء واحد جديد.',
    completeSetup: 'اكمل تركيب البوت',
    seeHowToUse: 'راجع كيفية الاستخدام',
    help: 'المساعدة',
    fbBot: {
      createNewBot: 'إنشاء بوت جديد',
      help: 'المساعدة',
      back: 'رجوع',
      step: 'الخطوة ',
      of3: ' من 3',
      loginWithFacebook: 'تسجيل الدخول باستخدام فيسبوك',
      asPerLatestChangesInFacebookPolicy: 'وفقًا لأحدث تغييرات في سياسة فيسبوك، يجب أن يكون مالك بوت فيسبوك لديه وظيفة "مدير" أو أعلى للوصول إلى جميع الميزات المتاحة في صفحة فيسبوك.',
      login: 'تسجيل الدخول',
      connectFacebookBot: 'ربط بوت فيسبوك',
      connectBotWithYourFacebookPagesAnd: 'ربط البوت مع صفحاتك على فيسبوك و ',
      exploreTruePowerOfFacebook: 'استكشاف قوة فيسبوك الحقيقية',
      connect: 'ربط',
      connected: 'متصل',
      createNewPage: 'إنشاء صفحة جديدة',
      refreshPageList: 'تحديث قائمة الصفحات',
      thisPageIsSuccessfullyIntegratedWith: 'تم ربط هذه الصفحة بنجاح مع ',
      yourMessagesFromMessengerWillAppearIn: '. ستظهر رسائلك من Messenger في ',
      inbox: 'البريد الوارد',
      continueToBotConfiguration: 'متابعة تكوين البوت',
      welcomeToBrandNameWeAreHereToHelpYou: `مرحبًا بك في ${brand}. نحن هنا لمساعدتك.`,
      letsTryYourBotAndSeeHowItWorks: `لنجرب بوتك ونرى كيف يعمل! انقر فوق "اختبار على Facebook".`,
      howAreYouDoingToday: 'كيف تشعر اليوم؟',
      thisIsHowSimpleToUseFacebookBot: `هذا كل شيء! هذا مدى بساطة استخدام بوت Facebook`,
      letsCreateYourOwnChatFlowNow: `لنقم الآن بإنشاء تدفق الدردشة الخاص بك`,
    },
    instagramBot: {
      createNewBot: 'إنشاء بوت جديد',
      back: 'رجوع',
      step: 'الخطوة ',
      of3: ' من 3',
      loginWithInstagram: 'تسجيل الدخول باستخدام إنستجرام',
      loginToYourInstagramAccount: 'قم بتسجيل الدخول إلى حسابك على إنستجرام لتمكين بوت الدردشة على مقبضك.',
      managerLevelDescription: 'يجب على مالكي بوت إنستجرام الحصول على مستوى "مدير" على الأقل من الأذونات أو أعلى للوصول إلى جميع الميزات.',
      login: 'تسجيل الدخول',
      connectInstagramBot: 'ربط بوت إنستجرام',
      connectBotWithYourInstagramAnd: 'ربط البوت مع حساب إنستجرام الخاص بك و ',
      exploreTruePowerOfInstagram: 'استكشاف قوة إنستجرام الحقيقية',
      connected: 'متصل',
      connect: 'ربط',
      createNewPage: 'إنشاء صفحة جديدة',
      refreshPageList: 'تحديث قائمة الصفحات',
      thisPageIsSuccessfullyintegratedWith: 'تم ربط هذه الصفحة بنجاح مع ',
      yourMessagesFromMessengerWillAppearIn: '. ستظهر رسائلك من Messenger في ',
      inbox: 'البريد الوارد',
      continueToBotConfiguration: 'متابعة تكوين البوت',
      welcomeToBrandNameWeAreHereToHelpYou: `مرحبًا بك في ${brand}. نحن هنا لمساعدتك.`,
      letsTryYourBotAndSeeHowItWorks: `لنجرب بوتك ونرى كيف يعمل! انقر فوق "اختبار على Instagram".`,
      howAreYouDoingToday: 'كيف تشعر اليوم؟',
      thisIsHowSimpleToUseInstagramBot: `هذا كل شيء! هذه مدى بساطة استخدام بوت إنستجرام`,
      letsCreateYourOwnChatFlowNow: `لنقم الآن بإنشاء تدفق الدردشة الخاص بك`
    },
    telegramBot: {
      back: 'رجوع',
      step: 'الخطوة ',
      of3: ' من 3',
      seeHowToUse: 'انظر كيفية الاستخدام',
      help: 'المساعدة',
      loginToYourTelegramAccount: 'تسجيل الدخول إلى حساب Telegram الخاص بك',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'قم بتسجيل الدخول إلى حسابك لإنشاء بوت جديد أو الاتصال بالبوت الحالي',
      verificationCodeSentOnRegisteredNumber: 'تم إرسال رمز التحقق على الرقم المسجل',
      createYourBot: 'أنشئ بوتك',
      enterYourBotsNameAnd: 'أدخل اسم بوتك، واتصل ولنبدأ المرح مع ',
      botUserNameMustEndWithBotAtTheEnd: 'يجب أن ينتهي اسم المستخدم الخاص بالبوت بـ "bot" في النهاية.',
      terrisBot: 'مثل teris_bot أو terisbot',
      botNameAlreadyTaken: 'اسم البوت هذا مأخوذ بالفعل. يرجى محاولة شيء مختلف',
      connect: 'الاتصال',
      createBot: 'إنشاء بوت',
      codeNotReceivedResend: 'لم يتم استلام الرمز؟ إعادة الإرسال!',
      changeNumber: 'تغيير الرقم',
      telegramPreview: 'معاينة Telegram',
      worldsFastestMessagingApp: 'أسرع تطبيق للرسائل في العالم ومجاني!',
      bot: 'بوت',
      phoneNumber: 'رقم الهاتف',
      userName: 'اسم المستخدم',
      none: 'لا شيء',
      bio: 'السيرة الذاتية',
      fewWordsAboutYourself: 'كلمات قليلة عن نفسك',
      settings: 'الإعدادات',
      notificationsAndSound: 'الإشعارات والصوت',
      privacyAndSecurity: 'الخصوصية والأمان',
      dataAndStorage: 'البيانات والتخزين',
      chatSettings: 'إعدادات الدردشة',
      chatFolders: 'مجلدات الدردشة',
      devices: 'الأجهزة',
      connectYourBot: 'قم بالاتصال ببوتك',
      createNewBotOrConnectWithExistingOne: 'أنشئ بوت جديد أو اتصل بالبوت الحالي',
      createNewBot: 'إنشاء بوت جديد',
      welcomeToBrandNameWeAreHereToHelpYou: `مرحبًا بك في ${brand}. نحن هنا لمساعدتك.`,
      letsTryYourBotAndSeeHowItWorks: `لنجرب بوتك ونرى كيف يعمل! انقر فوق "اختبار على Telegram".`,
      howAreYouDoingToday: 'كيف تشعر اليوم؟',
      thisIsHowSimpleToUseTelegramBot: `هذا كل شيء! هذه مدى بساطة استخدام بوت Telegram`,
      letsCreateYourOwnChatFlowNow: `لنقم الآن بإنشاء تدفق الدردشة الخاص بك`,
      placeholders: {
        searchCountry: 'بحث عن البلد',
        enterTelegramMobileNumber: 'أدخل رقم الهاتف المحمول لـ Telegram',
        enterLoginCodeReceived: 'أدخل رمز تسجيل الدخول الذي تم استلامه على حساب Telegram *',
        twoFactorAuthentication: 'كلمة المرور ذات العاملين (اختياري)',
        botName: 'اسم البوت *',
        botUserName: 'اسم المستخدم للبوت *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'إنشاء بوت جديد',
      seeHowToUse: 'رؤية كيفية الاستخدام',
      help: 'المساعدة',
      back: 'رجوع',
      step: 'الخطوة ',
      of2: ' من 2',
      buildA: 'إنشاء ',
      thatMatchesYourBrandPersonality: ' يتناسب مع شخصيتك العلامية',
      chatBotIcon: 'أيقونة البوت',
      optional: 'اختياري',
      themeColor: 'لون الثيم',
      chatBotName: 'اسم البوت',
      botNameIsRequired: 'اسم البوت مطلوب',
      botNameShouldBeLessThan50Characters: 'يجب أن يكون اسم البوت أقل من 50 حرفًا',
      continue: 'متابعة',
      welcomeToBrandNameWeAreHereToHelpYou: `مرحبًا بك في ${brand}. نحن هنا لمساعدتك.`,
      whatIsYourEmailAddress: 'ما هو عنوان البريد الإلكتروني الخاص بك؟',
      invalidEmailAddress: 'عنوان البريد الإلكتروني غير صالح',
      customizeYourLandingPageBotLook: 'قم بتخصيص مظهر بوت صفحة الهبوط الخاص بك',
      customizeYourWidgetLook: 'قم بتخصيص مظهر الودجت الخاص بك',
      customizeYourBotLook: 'قم بتخصيص مظهر البوت الخاص بك',
      buildYourLandingPageBotAndAutomateInteraction: 'قم بإنشاء بوت صفحة الهبوط الخاص بك وأتمتة التفاعل مع عملائك من خلال مشاركة الرابط الخاص به',
      useLiveChatBotToConnectToCustomers: 'استخدم بوت الدردشة الحية للاتصال بعملائك في الوقت الحقيقي',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'قم بتوصيل بوت الدردشة بموقع الويب الخاص بك وأتمتة التفاعل مع الزوار',
      widget: 'الودجت',
      botInterface: 'واجهة البوت',
      mergedBot: 'بوت الدردشة على الموقع الإلكتروني، بوت صفحة الهبوط، بوت تطبيق الجوال',
      mergedBotToolTip: 'أنشئ صفحة بوت للتقاط انتباه الزوار وجذبهم في محادثات.',
      placeholders: {
        typeANameForYourBot: 'اكتب اسمًا لبوتك الجديد',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'أدخل اسم البوت الخاص بك',
        mergedBotToolTip: 'أنشئ صفحة بوت للتقاط انتباه الزوار وجذبهم في محادثات.',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'حدد لون الثيم لنافذة الدردشة الخاصة بك',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `ستُستخدم هذه الشعار في منصة ${brand ? brand : ''} فقط. نقترح اختيار شعار شركتك. أضف صورة مربعة بامتداد PNG أو JPG وحجم أقل من 1 ميجابايت.`
      }
    },
    whatsappBot: {
      back: 'رجوع',
      justAStepAway: 'على بعد خطوة واحدة فقط',
      seeHowToUse: 'رؤية كيفية الاستخدام',
      help: 'المساعدة',
      createWhatsappChatBot: 'إنشاء بوت واتساب',
      businessNameIsRequired: 'اسم العمل مطلوب.',
      maximumLengthForBusinessName40Characters: 'الحد الأقصى لطول اسم العمل هو 40 حرفًا.',
      businessCategory: 'فئة العمل',
      categoryIsRequired: 'الفئة مطلوبة.',
      descriptionIsRequired: 'الوصف مطلوب.',
      maximumLengthForDescription200Characters: 'الحد الأقصى للوصف هو 200 حرف.',
      pleaseFillAValidEmailAddress: 'الرجاء إدخال عنوان بريد إلكتروني صالح للعمل.',
      businessEmailIsRequired: 'البريد الإلكتروني للعمل مطلوب.',
      phoneNumberShouldHaveMinimum7Digits: 'يجب أن يحتوي رقم الهاتف على الأقل على 7 أرقام',
      phoneNumberIsRequired: 'رقم الهاتف مطلوب',
      websiteIsRequired: 'الموقع الإلكتروني مطلوب.',
      maximumLengthForWebsite40Characters: 'الحد الأقصى لعنوان الموقع الإلكتروني هو 40 حرفًا.',
      maximumLengthForAddress150Characters: 'الحد الأقصى للعنوان هو 150 حرفًا.',
      doYouHaveWhatsappApiKey: 'هل لديك مفتاح واجهة برمجة تطبيقات واتساب؟',
      apiProvider: 'مزود واجهة برمجة التطبيقات',
      three60Dialog: '360 ديالوج',
      whatsappCloud: 'واتساب كلاود',
      netcore: 'نيتكور',
      apiKeyIsRequired: 'المفتاح الخاص بواجهة البرمجة مطلوب.',
      whatsappBusinessAccountIdIsRequired: 'معرف حساب العمل في واتساب مطلوب.',
      whatsappPhoneNumberIdIsRequired: 'معرف رقم الهاتف في واتساب مطلوب.',
      systemAccessTokenIsRequired: 'المفتاح الوصول إلى النظام مطلوب.',
      sourceIdIsRequired: 'معرف المصدر مطلوب.',
      businessName: 'اسم العمل',
      businessAccount: 'حساب العمل',
      businessDescription: 'وصف العمل',
      address: 'العنوان',
      businessEmail: 'البريد الإلكتروني للعمل',
      websiteUrl: 'عنوان الموقع الإلكتروني',
      thankYouForYourInterestIn: 'شكرًا لاهتمامك بـ',
      whatsappChatBot: 'بوت واتساب.',
      weHaveReceivedYourInterests: 'لقد تلقينا استفسارك، وسيتصل فريق المبيعات بك في الـ 24 ساعة القادمة.',
      theyWillProvideYouWhatsappApiKey: 'سيتم تزويدك بمفتاح واجهة برمجة تطبيقات واتساب.',
      readyToCreateWhatsappBot: 'أنا مستعد لإنشاء بوت واتساب',
      exploreWhatsappFeatures: 'استكشاف ميزات واتساب',
      welcomeToBrandNameWeAreHereToHelpYou: `مرحبًا بك في ${brand}. نحن هنا لمساعدتك.`,
      letsTryYourBotAndSeeHowItWorks: `لنجرب بوتك ونرى كيف يعمل! انقر على "اختبار على واتساب".`,
      howAreYouDoingToday: 'كيف حالك اليوم؟',
      thisIsHowSimpleToUseWhatsappBot: `هذا كل ما يلزم! هذه هي البساطة في استخدام بوت واتساب`,
      letsCreateYourOwnChatFlowNow: `لنقم بإنشاء تدفق الدردشة الخاص بك الآن`,
      connectWhatsAppBot: 'اتصل ببوت واتساب',
      proceed: 'متابعة',
      whatsApiProvider: 'مزود واجهة برمجة تطبيقات واتساب',
      cloudApi: 'واجهة برمجة تطبيقات كلاود',
      netcoreApi: 'واجهة برمجة تطبيقات نيتكور',
      placeholders: {
        yourBusinessName: 'اسم عملك',
        businessDescription: 'وصف العمل',
        businessEmail: 'بريد الكتروني للعمل',
        phone: 'الهاتف*',
        website: 'الموقع الإلكتروني (مثال: www.example.com)',
        address: 'العنوان',
        enterApiKey: 'أدخل مفتاح واجهة برمجة التطبيقات',
        enterWhatsappBusinessAccountId: 'أدخل معرف حساب العمل في واتساب',
        enterWhatsappPhoneNumberId: 'أدخل معرف رقم الهاتف في واتساب',
        enterSystemAccessToken: 'أدخل مفتاح الوصول إلى النظام',
        enterSourceId: 'أدخل معرف المصدر',
        businessName: 'اسم العمل',
        businessWebsite: 'موقع الأعمال',
        selectWhatsappApiProvider: 'اختر مزود واجهة برمجة تطبيقات واتساب',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'اختر مزود واجهة برمجة تطبيقات واتساب الخاص بك',
        doYouHaveWhatsAppBusinessAPI: 'هل لديك واجهة برمجة تطبيقات واتساب الخاصة بالأعمال؟',
        yes: 'نعم',
        noIAmNotSure: 'لا، لست متأكدًا',
        selectApiProvider: 'اختر مزود الواجهة البرمجية',
        whatsAppCloudAPI: 'واجهة برمجة تطبيقات واتساب السحابية',
        enterYourPhoneNumberId: 'أدخل معرف رقم هاتفك',
        phoneNumberId: 'معرف رقم الهاتف',
        enterYourWhatsAppBusinessAccountId: 'أدخل معرف حساب الأعمال في واتساب',
        whatsAppBusinessAccountId: 'معرف حساب الأعمال في واتساب',
        enterYourSystemUserToken: 'أدخل مفتاح وصول المستخدم في النظام',
        systemUserToken: 'مفتاح وصول المستخدم في النظام',
        help: 'مساعدة',
        netcoreAPI: 'واجهة برمجة تطبيقات نيتكور',
        enterYourSourceId: 'أدخل معرف المصدر الخاص بك',
        sourceId: 'معرف المصدر',
        enterYourNetcoreApiKey: 'أدخل مفتاح واجهة برمجة تطبيقات نيتكور الخاص بك',
        netcoreApiKey: 'مفتاح واجهة برمجة تطبيقات نيتكور',
        submit: 'إرسال',
        noneOftheAbove: 'لا شيء من ذلك',
        next: 'التالي'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'عمل مسجل قانونيًا',
        validWebsiteWithDataPrivacyPolicy: 'موقع ويب صالح مع سياسة خصوصية البيانات',
        debit: 'بطاقة الخصم',
        creditCard: 'بطاقة الائتمان',
        gstin: 'GSTIN',
        onlyRequiredForIndianResidents: 'مطلوب فقط للمقيمين في الهند',
        onlyRequiredFor: 'مطلوب فقط لـ',
        indianResidents: 'المقيمين في الهند',
        validPaymentMethod: 'وسيلة دفع صالحة',
        enabledWithInternationalPayments: 'ممكن مع المدفوعات الدولية',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'حالة استخدام صالحة وفقًا لسياسة التجارة في واتساب',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'رقم هاتف غير لديه واتساب نشط وقادر على استقبال الرسائل النصية (SMS)',
        discard: 'تجاهل',
        proceed: 'المضي قدمًا',
        whatsAppBotPrerequisites: 'متطلبات الواتساب بوت',
        letUsGetItDoneForYou: 'دعنا نقوم بذلك من أجلك!',
        ifYouAreFacingSomeIssues: 'إذا كنت تواجه مشكلة ما',
        ourTeamCanHelpYouDoTheSame: 'فريقنا يمكن أن يساعدك في القيام بالشيء نفسه',
        requestACallbackNow: 'اطلب مكالمة الآن!'
      },
      stepThree: {
        setupWhatsAppBot: 'إعداد واتساب بوت',
        selectingTheBestBusinessManagerForYourNeeds: 'اختيار أفضل مدير أعمال لاحتياجاتك',
        createMetaApp: 'إنشاء تطبيق Meta',
        createaMetaAppOn: 'إنشاء تطبيق Meta على',
        howToCreate: 'كيفية الإنشاء',
        addWhatsAppNumber: 'إضافة رقم واتساب',
        addYourWhatsAppNumberAndVerifyTheSame: 'أضف رقم واتساب الخاص بك وقم بالتحقق منه',
        subscribe: 'اشتراك',
        copyAndSubscribeWebhook: 'انسخ واشترك في Webhook',
        howToSubscribe: 'كيفية الاشتراك',
        copy: 'نسخ',
        appSecretId: 'معرف التطبيق السري',
        enterYourAppSecretId: 'أدخل معرف تطبيقك السري',
        enterAppSecret: 'أدخل الكود السري للتطبيق',
        phoneNumberId: 'معرف رقم الهاتف',
        whatsAppBusinessId: 'معرف واتساب للأعمال',
        assignSystemUser: 'تخصيص مستخدم النظام',
        assign: 'تعيين',
        createApp: 'إنشاء تطبيق',
        registerAndVerify: 'تسجيل وتحقق',
        add: 'يضيف',
        systemUserToken: 'رمز مستخدم النظام',
        enterYourSystemUserToken: 'أدخل رمز مستخدم النظام الخاص بك',
        getAllNecessaryDetails: 'احصل على جميع التفاصيل اللازمة',
        subscribeWhatsAppWebhook: 'اشترك في WA Webhook',
        configureTheWebhookForWhatsAppAccount: 'قم بتكوين Webhook لحساب WhatsApp',
        upadatePaymentMethod: 'تحديث وسيلة الدفع',
        assignThePaymentMethodToWhatsAppAccount: 'تعيين وسيلة الدفع لحساب واتساب',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'منح التحكم في حساب واتساب للأعمال لمستخدم النظام',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'تفاصيل مهمة تحتاج لنسخها من لوحة Meta',
        updatePayment: 'تحديث الدفع',
        letUsGetItDoneForYou: 'دعنا نقوم بذلك من أجلك!',
        ifYouAreFacingSomeIssues: 'إذا كنت تواجه مشكلة ما',
        ourTeamCanHelpYouDoTheSame: 'فريقنا يمكن أن يساعدك في القيام بالشيء نفسه',
        requestACallbackNow: 'اطلب مكالمة الآن!',
        update: 'تحديث',
        connectNow: 'اتصل الآن'
      },
      stepFive: {
        selectBusinessManager: 'اختيار مدير الأعمال',
        selectingTheBestBusinessManagerForYourNeeds: 'اختيار أفضل مدير أعمال لاحتياجاتك'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'الروبوت الخاص بك مثبت ونشط',
        youhaveSuccessfullyConnected: 'لقد قمت بالاتصال بنجاح',
        withWhatsApp: 'مع واتساب',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'تم تخصيص 100 محادثة لك شهريًا افتراضيًا',
        upgradeYourPlanForMore: 'قم بترقية خطتك للحصول على المزيد.',
        createChatFlow: 'إنشاء تدفق المحادثة',
        upgradePlan: 'ترقية الخطة'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'قم بقراءة QR او ارسال رسالة لتفعيل الروبوت',
        scanTheQrCodeFrom: 'قم بقراءة QR الكود من',
        yourPhoneWillOpen: 'سيفتح الهاتف',
        sendMessageFromYourWhatsAppNumber: 'ارسل رسالة من رقم الواتساب الخاص بك',
        sendUsAWhatsAppMessageOn: 'ارسل رسالة WhatsApp على',
        bySavingInYourContactsAndMessageShouldStartwith: ' حفظ في الاتصالات وسيتم تشغيل الرسالة بدءًا من',
        send: 'ارسل',
        or: 'او',
      }
    }
  },
  gettingStarted: {
    hello: 'مرحبًا،',
    upgrade: 'ترقية',
    gettingStarted: 'البدء -',
    yourFacebookBotToken: 'رمز الروبوت الخاص بك على Facebook',
    hasBeenExpired: 'قد انتهت صلاحيته',
    isExpiringIn: 'ستنتهي صلاحيته خلال',
    days: 'أيام',
    facebookAuthenticationTokenRemainsFor60Days: 'تبقى صلاحية مصادقة Facebook نشطة فقط لمدة 60 يومًا',
    kindlyProceedWithTheRenewalOfYourBotToken: 'يرجى المضي قدمًا في تجديد رمز الروبوت الخاص بك لضمان الوصول المستمر إلى خدمات الروبوت',
    renewYourBotToken: 'قم بتجديد رمز الروبوت الخاص بك',
    editYourChatFlow: 'قم بتحرير تدفق المحادثة الخاص بك',
    buildConversationEngagingBot: 'قم ببناء تدفقات محادثة جذابة لمستخدمي الروبوت الخاص بك',
    takes5minutes: 'يستغرق 5 دقائق',
    previewYourLiveChatWidget: 'اعرض معاينة عنصر واجهة الدردشة المباشرة الخاص بك',
    discoverHowThe: 'اكتشف كيفية',
    liveChatFeatureWorks: ' تعمل ميزة الدردشة المباشرة باستخدام منطقة لعب الدردشة المباشرة الخاصة بنا',
    takes2minutes: 'يستغرق 2 دقيقة',
    takes1minute: 'يستغرق دقيقة واحدة',
    connected: 'متصل',
    customizeYourBotApperance: 'قم بتخصيص مظهر روبوتك',
    takes3minutes: 'يستغرق 3 دقائق',
    thirdPartyAppsIntegration: 'تكامل التطبيقات الطرفية',
    integrateYourBot: 'قم بتكامل الروبوت الخاص بك مع التطبيقات/أنظمة إدارة العلاقات مع العملاء لإنشاء الأتمتة التجارية',
    upgradeYourPlanToReceiveLeadsInEmail: 'قم بترقية خطتك لاستقبال العملاء عبر البريد الإلكتروني',
    getStartedWithUs: 'ابدأ معنا',
    howToBuildAGoodChatFlow: 'كيفية بناء تدفق محادثة جيد',
    howToUseBotPenguinsInboxAndItsFeatures: 'كيفية استخدام صندوق الوارد BotPenguin وميزاته',
    botPenguinsPricing: 'أسعار BotPenguin: استكشاف الخطط والميزات',
    openHelpDesk: 'افتح مكتب المساعدة',
    helpAndSupport: 'المساعدة والدعم',
    extensiveGuides: 'الأدلة الشاملة لجميع استفساراتك وسياقات العمل متاحة في مركز الموارد لدينا',
    resourceCentre: 'المركز الموارد',
    checkoutOurAnswers: 'يمكنك التحقق من إجاباتنا على الاستفسارات الشائعة أدناه',
    createANewBot: 'إنشاء روبوت جديد',
    installYourBot: 'تثبيت الروبوت الخاص بك',
    plansAndPricing: 'الخطط والأسعار',
    previewYourLandingPageBot: 'معاينة روبوت الصفحة الرئيسية الخاص بك',
    installYourLiveChatWidget: 'تثبيت ويدجيت الدردشة الحية الخاص بك',
    installYourWebsiteBot: 'تثبيت بوت الدردشة على موقع الويب الخاص بك أو تطبيق الجوال أو تشغيله كصفحة هبوط أو بوت مضمن',
    integrateBotToLandingPage: 'تكامل البوت مع صفحة الهبوط الخاصة بك',
    installWidgetToWebsite: 'تثبيت الويدجيت على موقع الويب الخاص بك',
    installBotToWebsite: 'تثبيت بوت الدردشة على موقع الويب الخاص بك',
    whatsappAutomation: 'أتمتة واتساب',
    facebookAutomation: 'أتمتة فيسبوك',
    telegramBotSettings: 'إعدادات بوت تليجرام',
    liveChatWidgetSettings: 'إعدادات ويدجيت الدردشة الحية',
    landingPageBotSettings: 'إعدادات بوت الصفحة الهبوطية',
    websiteChatBotSettings: 'إعدادات بوت الدردشة على موقع الويب',
    instagramAutomation: 'أتمتة إنستجرام',
    diveDeeperIntoBotPenguin: 'استكشاف عميق في بوت بينجوين',
    knowWhereWeAreGoing: 'تعرف إلى أين نذهب، أحدث التحديثات، وساعدنا في إصلاح بعض الأخطاء.',
    productUpdates: 'تحديثات المنتج',
    requestAFeature: 'اطلب ميزة',
    reportABug: 'الإبلاغ عن خطأ',
    prodcutRoadmap: 'خريطة المنتج',
    trainYourChatbot: 'قم بتدريب بوت الدردشة الذكي الخاص بك (قاعدة المعرفة)',
    buildEngagingConversation: 'قم ببناء تدفقات دردشة جذابة لمستخدمي بوتك.',
    previewYourAIBot: 'معاينة بوت الذكاء الاصطناعي الخاص بك',
    integrateAIbot: 'قم بتكامل البوت مع بوت الذكاء الاصطناعي الخاص بك',
    aiBotSettings: 'إعدادات بوت الذكاء الاصطناعي',
    CustomizeAIBot: 'قم بتخصيص مظهر ووظائف بوت الذكاء الاصطناعي الخاص بك.',
    gptKeyWarning: 'مرحبًا بك في خدمة ChatGPT API الخاصة بنا! يرجى ملاحظة أن هناك قيدًا يبلغ 100 رسالة لكل روبوت. بعد الوصول إلى الحد الأقصى للرسائل (100 رسالة) ، ستحتاج إلى استخدام مفتاح API ChatGPT الخاص بك للاستمرار في الاستمتاع بخدمتنا. لإدخال مفتاح API ChatGPT ، انتقل إلى تكوين نموذج ChatGPT.',
    whatsappBotSettings: 'إعدادات بوت واتساب',
    automateCustomerEngagementWithWhatsApp: 'أتمتة مشاركة العملاء مع واتساب.',
    connectToYourWhatsappNumber: 'الاتصال برقم واتسابك"',
    connectYourWhatsappBotWithYourWhatsappNumber: 'قم بربط بوت واتسابك مع رقم واتسابك',
    facebookBotSettings: 'إعدادات بوت فيسبوك',
    automateCustomerEngagementWithTelegram: 'أتمم إشراك العملاء مع تيليغرام',
    automateCustomerEngagementWithFacebook: 'أتمم إشراك العملاء مع فيسبوك',
    automateCustomerEngagementWithInstagram: 'أتمم إشراك العملاء مع إنستغرام',
    telegramAutomation: 'أتمتة تيليغرام',
  },
  install: {
    previewYourLandingPageBot: 'معاينة بوت صفحة الهبوط الخاص بك',
    preview: 'معاينة',
    test: 'اختبار',
    squareSpace: 'سكوير سبيس',
    installYourChatBot: 'قم بتثبيت بوت الدردشة الخاص بك',
    seeHowToUse: 'انظر كيفية الاستخدام',
    help: 'مساعدة',
    uniqueLinkToLandingPageBot: 'إليك الرابط الفريد لبوت صفحة الهبوط الخاص بك.',
    shareOnSocialMedia: 'ببساطة قم بمشاركته مع مستخدميك على وسائل التواصل الاجتماعي، صفحات الاتصال أو بطاقات العمل.',
    or: 'أو',
    copyUrl: 'انسخ الرابط',
    selectYourWebsiteCms: 'حدد نظام إدارة المحتوى لموقع الويب الخاص بك',
    selectCms: 'حدد نظام إدارة المحتوى/المنشئ الذي استخدمته لإنشاء موقع الويب الخاص بك',
    custom: 'مخصص',
    wordpress: 'ووردبريس',
    shopify: 'شوبيفاي',
    wix: 'ويكس',
    zoomla: 'زوملا',
    otherCms: 'نظام إدارة المحتوى الآخر',
    howWouldYouLikeToInstall: 'كيف ترغب في تثبيته؟',
    installingWebsiteBot: 'اختر كيف ستقوم بتثبيت البوت على موقع الويب الخاص بك',
    iWillAddMyself: 'سأقوم بذلك بنفسي',
    getChatBotScript: 'احصل على رمز السيناريو الخاص بالبوت أدناه وقم بتثبيته بنفسك',
    getWordpressPlugin: 'احصل على مفتاح واجهة برمجة التطبيقات (API) لمكون ووردبريس',
    getOurOfficialPlugin: 'احصل على مكون البرنامج النصي الرسمي الخاص بنا ',
    fromWordpressPluginStore: 'من متجر مكون ووردبريس',
    getPlugin: 'احصل على المكون',
    quickGuideToInstall: 'دليل سريع للتثبيت',
    placeApiKeyBelowIn: 'قم بلصق مفتاح واجهة برمجة التطبيقات (API) أدناه في ',
    afterDownloadingAndActivatingPlugin: 'بعد تنزيل المكون وتنشيطه',
    copy: 'انسخ',
    enterYourWebsite: 'أدخل موقع الويب الخاص بك ولنرى كيف يبدو بوت الدردشة الخاص بك',
    enterAValidUrl: 'أدخل عنوان URL صالح',
    verifyYourInstallation: 'تحقق مما إذا كان تثبيتك ناجحًا أم لا؟',
    testInstallation: 'اختبار التثبيت',
    getChatBotScriptCode: 'احصل على رمز سيناريو بوت الدردشة',
    copyThisCodeSnippet: 'انسخ مقتطف الكود هذا وقم بلصقه في HTML لكل صفحة ويب حيث ترغب في عرض بوت الدردشة أو الصقه في التذييل قبل إغلاق الوسم ليظهر على كل صفحة.',
    // copy: 'انسخ',
    sendCodeToMyDeveloper: 'أرسل الكود إلى مطوري',
    sendCodeToMyDevelopmentTeam: 'أرسل الكود إلى فريق التطوير الخاص بي',
    sendEmail: 'إرسال البريد الإلكتروني',
    enterAValidEmail: 'أدخل بريد إلكتروني صالح',
    codeHasBeen: 'تم إرسال الكود ',
    toYourDeveloper: 'إلى مطورك ',
    resent: 'مرة أخرى',
    sent: 'تم الإرسال',
    resendEmail: 'إعادة إرسال البريد الإلكتروني',
    ask: 'اسأل ',
    supportTeamToInstall: 'فريق الدعم للمساعدة في التثبيت',
    raiseTheInstallationRequestTo: 'قدّم طلب التثبيت إلى ',
    team: ' الفريق',
    helpMeInstallingChatBot: 'ساعدني في تثبيت بوت الدردشة',
    yourBotInstallationIsNotActive: 'تثبيت بوتك غير نشط!',
    monthlyMessageLimitExceeded: 'تم تجاوز الحد الشهري للرسائل',
    exceededMonthlyMessageLimitUpgradeNow: 'لقد تجاوزت الحد الشهري للرسائل، قم بالترقية الآن.',
    upgradeNow: 'قم بالترقية الآن',
    botInstallationNotActivePleaseFix: 'تثبيت بوتك غير نشط، يرجى إصلاح المشكلة. ',
    incorrectBotScript: 'سيناريو البوت غير صحيح',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'رمز التثبيت الذي تقوم بالتحقق منه لا يتطابق مع الذي على موقعك. يرجى التأكد من تثبيت الرمز الصحيح لهذا البوت.',
    unableToSee: 'غير قادر على رؤية ',
    codeOnYourWebsite: 'الرمز على موقعك',
    unableToFindCodePleaseReinstall: 'نحن غير قادرين على العثور على الرمز على موقعك. يرجى إعادة التثبيت.',
    reinstallBot: 'أعد تثبيت البوت',
    yourBotIsInstalledAndActive: 'تم تثبيت بوتك وهو نشط!',
    noPendingAction: 'لا يوجد إجراء معلق، تم تثبيت البوت وهو نشط',
    youAreAllSet: 'كل شيء جاهز!',
    showMeMyLeads: 'أرني العملاء المحتملين',
    connectingToYourWebsiteToVerifyInstallation: 'الاتصال بموقع الويب الخاص بك للتحقق من تثبيت البوت',
    thisMayTakeUpto30Seconds: 'قد يستغرق ذلك ما يصل إلى 30 ثانية',
    hereIsTheuniqueAILink: 'إليك الرابط الفريد لبوت الذكاء الاصطناعي الخاص بك',
    installHeading: 'قم بتثبيت بوت الدردشة الخاص بك',
    websiteChatbot: 'بوت الدردشة للموقع الإلكتروني',
    aiChatBot: 'بوت الدردشة الذكي',
    landingPageBot: 'بوت صفحة الهبوط',
    mobileChatBot: 'بوت الدردشة للتطبيق المحمول',
    embeddedBot: 'بوت الدردشة المدمج',
    selectMobileDevice: 'حدد جهازك المحمول',
    mobileDeviceSubheading: 'حدد اللغة الأصلية التي استخدمتها لإنشاء تطبيقك المحمول',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'أخرى',
    copyEmbeddedCodeSnippet: 'انسخ مقتطف الكود هذا والصقه في HTML لكل صفحة ويب حيث ترغب في عرض البوت.',
    copyMobileCodeSnippet: 'انسخ مقتطف الكود هذا في كود تطبيقك المحمول حيث ترغب في عرض البوت.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'أدخل البريد الإلكتروني للمطور'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'أرسل التعليمات إلى مطورك',
      notSureHowToDoIt: 'غير متأكد من كيفية القيام بذلك؟ يمكن لمطوريك القيام بذلك بسرعة.',
      developersEmail: 'بريد المطور',
      emailIsRequired: 'البريد الإلكتروني مطلوب',
      subject: 'الموضوع',
      message: 'الرسالة',
      send: 'إرسال',
      placeholders: {
        enterYourWorkEmail: 'أدخل بريدك الإلكتروني الخاص بالعمل',
        createBotUsingBotPenguin: 'متحمس! قم بإنشاء بوت باستخدام BotPenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'إليك الرابط الفريد لصفحة هبوط الروبوت الخاص بك.',
      shareWithUsersOnSocialMedia: 'قم بمشاركته ببساطة مع مستخدميك على وسائل التواصل الاجتماعي، وصفحات الاتصال، أو بطاقات العمل.',
      previewMyLandingPageBot: 'معاينة روبوت صفحة الهبوط الخاص بي',
      or: 'أو',
      copyUrl: 'نسخ الرابط',
      howYouLikeToAddBot: 'اختر كيف ترغب في إضافة رمز الروبوت إلى موقع الويب الخاص بك',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'قم بتثبيت الروبوت على موقع الويب المخصص الخاص بك أو موقع ووردبريس من خلال نسخ ولصق رمز فريد.',
      name: 'الاسم',
      actions: 'الإجراءات',
      javascript: 'جافا سكريبت',
      install: 'تثبيت',
      onYourCustomCodedWebsite: 'على موقع الويب المخصص الخاص بك',
      copyAndPaste: 'انسخ والصق',
      copyThisCodeSnippet: 'انسخ مقتطف الشيفرة هذا وقم بإدراجه في HTML لكل صفحة ويب ترغب في عرض الواجهة بها. لمزيد من التفاصيل،',
      seeGuide: 'راجع الدليل',
      getCode: 'الحصول على الشيفرة',
      howToInstall: 'كيفية التثبيت؟',
      copyCode: 'نسخ الشيفرة',
      wordpress: 'ووردبريس',
      // install: 'Install ',
      onYourWordPressWebiste: 'على موقع الويب الخاص بك على ووردبريس',
      preview: 'معاينة',
      test: 'اختبار',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'معاينة الدردشة المباشرة',
    agentWindow: 'نافذة الوكيل',
    person: 'شخص',
    inOrderToTestTypeHere: 'من أجل الاختبار، اكتب هنا',
    agentWindowCannotBeCustomized: 'ملاحظة: لا يمكن تخصيص نافذة الوكيل',
    customerWindow: 'نافذة العميل',
    clickHereToChat: 'انقر هنا للدردشة',
    customerWillChatHere: 'سيقوم العميل بالدردشة هنا',
    chat: 'دردشة',
    by: 'بواسطة',
    changeYourBotLookFromLeftPanel: 'قم بتغيير مظهر روبوتك من اللوحة الجانبية اليسرى',
    placeholders: {
      typeYourMessage: 'اكتب رسالتك...',
      typeYourAnswer: 'اكتب إجابتك',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'معاينة واجهة الدردشة المباشرة الخاصة بك',
    install: 'تثبيت',
    seeHowToUse: 'راجع كيفية الاستخدام',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'انقر لتثبيت روبوتك على المنصة المحددة',
    }
  },
  trainYourBot: {
    trainYourBot: 'قم بتدريب روبوتك',
    knowledgeBase: 'قاعدة المعرفة',
    faqs: 'الأسئلة الشائعة',
    history: 'السجل',
    faqHeading: 'الأسئلة الشائعة',
    faqSubHeading: 'إجابات على أسئلة تدريب الروبوت الخاص بك',
    knowledgeBaseHeading: 'قم بتدريب روبوتك',
    knowledgeBaseSubheading: 'قم بتدريب روبوتك لزيادة جاذبية المحادثات',
    websiteLink: 'رابط الموقع',
    website: 'موقع إلكتروني',
    uploadFile: 'قم بتحميل ملفك',
    selectFile: 'اختر الملف',
    submit: 'إرسال',
    searchHere: 'ابحث هنا',
    question: 'السؤال',
    answer: 'الإجابة',
    questionPlaceholder: 'اكتب سؤالك هنا',
    answerPlaceholder: 'اكتب إجابتك هنا',
    save: 'حفظ',
    discard: 'تجاهل',
    addFaq: 'إضافة الأسئلة الشائعة',
    createdAt: 'تم الإنشاء في',
    updatedAt: 'تم التحديث الأخير في',
    actions: 'الإجراءات',
    noFaqFound: 'ليس لديك أي أسئلة شائعة تم إنشاؤها حتى الآن',
    noFaqFoundMessage: 'سيظهر كل أسئلتك الشائعة هنا',
    historyTitle: 'سجل قاعدة المعرفة',
    historySubtitle: 'إجابات على أسئلة تدريب روبوت المحادثة الخاص بك',
    fileName: 'اسم الملف',
    status: 'الحالة',
    noHistoryTitle: 'ليس لديك أي نماذج مدربة تم إنشاؤها حتى الآن',
    noHistoryDescription: 'سيظهر كل نماذجك المدربة هنا',
    fileUpload: 'تحميل الملف',
    files: 'الملفات',
    fileSubHeading: 'أضف جميع الملفات التي ترغب في تدريب روبوتك عليها.',
    noFileFoundHeading: 'ليس لديك أي ملفات تم إنشاؤها حتى الآن',
    noFileFoundSubheading: 'سيظهر كل ملفاتك هنا',
    uploadYourFiles: 'قم بتحميل ملفاتك',
    upload: 'رفع',
    fileShouldBe: 'يجب أن يكون الملف بصيغة PDF أو DOC أو DOCX.',
    addFile: 'إضافة ملف',
    otherConfiguration: 'إعدادات الذكاء الاصطناعي',
    deleteFiles: 'انقر لحذف الملفات',
    websiteURL: 'عناوين موقع الويب',
    websiteUrlSubheading: 'أضف جميع عناوين موقع الويب التي ترغب في تدريب روبوتك عليها.',
    enterWebsiteUrl: 'أدخل عنوان الموقع',
    addWebsite: 'إضافة موقع ويب',
    tableWebsiteUrl: 'عنوان موقع الويب',
    trainingStatus: 'حالة التدريب',
    lastTrained: 'تم التدريب الأخير في',
    noWebsitesFound: 'ليس لديك أي مواقع ويب تم إنشاؤها حتى الآن',
    noWebsitesFoundMessage: 'سيظهر كل مواقع الويب الخاصة بك هنا',
    loadMore: 'تحميل المزيد',
    botBehaviour: 'تكوين سلوك الروبوت',
    botBehaviourSubHeading: 'قم بتكوين شخصية الروبوت وتجريب بيانات العملاء وإدارة التدفق ونموذج Chat GPT',
    botPersonality: 'قم بتكوين شخصية الروبوت',
    botPersonalitySubHeading: 'إدارة كيفية استجابة الروبوت من حيث الشخصية',
    selectAnswerLength: 'اختر طول الإجابة',
    answerLength: 'طول الإجابة',
    answerAs: 'الإجابة كـ',
    tone: 'اللهجة',
    language: 'اللغة',
    answerFormatting: 'تنسيق الإجابة',
    customInstructions: 'تعليمات مخصصة',
    customerInstructionPlaceholder: 'كن مهذبًا وودودًا. لا تستخدم اللهجة. لا تستخدم الرموز التعبيرية.',
    redactCustomerData: 'جمع بيانات العملاء',
    redactDataSubheading: 'قم بتكوين معلومات العميل التي ترغب في جمعها',
    collectName: 'جمع الاسم',
    configureQuestion: 'تكوين السؤال',
    maxCharacterLimit: 'الحد الأقصى لعدد الأحرف في الاسم المقدم',
    collectEmail: 'جمع البريد الإلكتروني',
    excludeEmail: 'استبعاد عناوين البريد الإلكتروني مع النطاق',
    collectPhone: 'جمع رقم الهاتف',
    manageUnusualMessages: 'إدارة حالات الدردشة غير المعتادة',
    unusualMessagesSubheading: 'تعامل مع حالات الدردشة غير المجاب عنها والحالات النادرة الأخرى للروبوت',
    startConversation: 'رسالة لبدء المحادثة',
    configureMessage: 'رسالة عندما يكون الروبوت غير قادر على الرد',
    profanityMessage: 'رسالة للرد على استخدام اللغة غير المناسبة',
    chatGptModel: 'تكوين نموذج Chat GPT',
    chatGptModelSubheading: 'إدارة أي نموذج Chat GPT يجب استخدامه وتجنب السوء في الاستخدام',

    chatGptApi: 'مفتاح واجهة برمجة التطبيقات Chat GPT',
    enableDisableRedactData: 'جمع بيانات العملاء',
    triggerToCollectData: 'جمع تفاصيل الاتصال بعد مجموعة من الأسئلة.',
    train: 'تدريب',
    includedSourcesForResponse: 'المصادر المضمنة للرد',
    visibility: 'الظروف',
    analytics: 'تحليلات',
    yourConversation: 'محادثاتك',
    reviewAndSelectConversation: 'مراجعة واختيار المحادثات للتصدير لتدريب الشات بوت',
    exportAiAnalytics: 'تصدير التحليلات',
    startDate: 'تاريخ البدء',
    endDate: 'تاريخ الانتهاء',
    userQuestion: 'سؤال المستخدم',
    botsReply: 'رد البوت',
    dateCreated: 'تاريخ الإنشاء',
    noAnalyticsFound: 'ليس لديك أي تحليلات تم إنشاؤها حتى الآن',
    noAnalyticsFoundMessage: 'ستظهر جميع رسائل التحليل الخاصة بك هنا',
    exportData: 'تصدير البيانات',
    exportCompleteAnalyticsData: 'تصدير بيانات التحليل الكاملة',
    export: 'تصدير',
    integrateWithChatGpt: 'تكامل مع Chat GPT',
    integrated: 'تم تكامله',
    configure: 'تكوين',
    appToolTip: {
      deleteWebsite: 'انقر لحذف المواقع',
      knowledgeBase: 'قم بتدريب روبوتك باستخدام قاعدة المعرفة',
      faqs: 'قم بتدريب روبوتك باستخدام الأسئلة الشائعة',
      knowledgeHistory: 'سجل قاعدة المعرفة',
      uploadFaqs: 'قم بتحميل الأسئلة الشائعة',
      addFaqs: 'انقر لإضافة الأسئلة الشائعة',
      deleteFaqs: 'حذف الأسئلة الشائعة',
      deleteHistory: 'حذف التاريخ',
      uploadFile: 'يجب أن يكون الملف المحمل بصيغة PDF أو DOCX أو DOC أو CSV ويجب أن يكون حجمه أقصى 1 ميجابايت.',
      trainWebsite: 'قم بتدريب روبوتك باستخدام موقع الويب.',
      fileUpload: 'قم بتدريب روبوتك باستخدام ملفات مثل PDFs و DOCs و DOCXs.',
      configureBotBehaviour: 'قم بتخصيص شخصية الروبوت وإدارة التدفق وضبط نموذج Chat GPT.',
      clickToAddWebsite: 'انقر لإضافة موقع ويب',
      clickToDeleteWebsites: 'انقر لحذف المواقع الإلكترونية',
      syncWebsite: 'مزامنة الموقع الإلكتروني',
      copyUrl: 'نسخ عنوان URL',
      weWillBeFetchingAllTheWebpages: 'سنقوم بجلب جميع صفحات الويب وتدريب نموذج ChatGPT عليها.'
    },
    modal: {
      deleteWebsiteUrl: 'حذف عنوان موقع الويب',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'هل أنت متأكد أنك تريد حذف عنوان موقع الويب هذا؟'
    }
  },
  configuration: {
    websiteChatbotSetting: 'إعدادات روبوت الدردشة على الموقع الإلكتروني',
    preview: 'معاينة',
    test: 'اختبار',
    install: 'تثبيت',
    designHeader: 'التصميم',
    generalHeader: 'عام',
    alertsHeader: 'التنبيهات',
    triggerHeader: 'الزناد',
    userAccessHeader: 'وصول المستخدم',
    chatWindowHeader: 'نافذة الدردشة',
    otherConfiguration: 'إعدادات الذكاء الاصطناعي',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'انقر لتثبيت روبوتك على المنصة المحددة',
      customizeAppearanceOfBot: 'قم بتخصيص موقع ومظهر واجهة الروبوت الخاص بك.',
      configureBotsSettingsWhenAgentUnavailable: 'قم بتكوين إعدادات الروبوت عند عدم توفر وكيلك.',
      configureHowBotWillAlertYou: 'قم بتكوين كيفية تنبيه الروبوت لك بوجود عملاء جدد.',
      makeSureYourBotsPopUpProperlyTimed: 'تأكد من أن ظهور نوافذ الروبوت متزامن بشكل مثالي.',
      customizeWhereYouWantYourBotToAppear: 'قم بتخصيص مكان ظهور الروبوت ومكان عدم الظهور.',
      enableChatOptionsForVisitorsToTransferChat: 'قم بتمكين خيارات الدردشة لزوار موقعك لنقل الدردشة والدردشة الحية وتحديث الدردشة.',
      configureBotBehaviour: 'قم بتخصيص شخصية الروبوت وإدارة التدفق وضبط نموذج Chat GPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'قم بتكوين إعدادات التنبيه لروبوتك',
      specifyHowYourBotWillNotify: 'حدد كيف سيخبرك روبوتك بوجود عملاء جدد.',
      subject: 'الموضوع',
      emailNotification: 'التنبيه عبر البريد الإلكتروني',
      emailAddresses: 'عناوين البريد الإلكتروني',
      leadRevisitNotification: 'إشعارات زيارة العميل',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'لقد قمت بالتسجيل بنجاح مع إشعارات سطح المكتب',
      youHaveBlockedYourNotificationsOnBrowser: 'لقد حظرت إشعارات المتصفح الخاص بك.',
      discard: 'تجاهل',
      saveChanges: 'حفظ التغييرات',
      seeHowToUse: 'راجع كيفية الاستخدام',
      placeholders: {
        enterSubjectForYourEmail: 'أدخل موضوع البريد الإلكتروني الخاص بك',
        typeYourEmailAddressAndHitEnter: 'اكتب عنوان بريدك الإلكتروني واضغط على Enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'تخصيص موضوع إشعارات البريد الإلكتروني من الروبوت',
        TOOLTIP_EMAIL_NOTIFICATION: 'تمكين/تعطيل إشعار الروبوت على بريدك الإلكتروني بشأن العملاء الجدد',
        TOOLTIP_EMAIL_ADDRESS: 'إرسال عنوان البريد الإلكتروني الذي ترغب في تلقي إشعارات البريد الإلكتروني عليه. يمكنك إضافة عناوين البريد الإلكتروني المتعددة عن طريق إدخال واحدة ثم الضغط على Enter لإضافة أخرى.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'تمكين/تعطيل إشعار الروبوت على بريدك الإلكتروني إذا تفاعل مستخدم تم التقاطه سابقًا كعميل مع الروبوت مرة أخرى.',
      }
    },
    broadcasts: {
      createBroadcastHeading: 'إنشاء بث',
      youCanCreateMultipleBroadcasts: 'يمكنك إنشاء العديد من البثوث',
      broadcastName: 'اسم البث',
      broadcastNameIsRequired: 'اسم البث مطلوب',
      continue: 'متابعة',
      manageBroadcasts: 'إدارة البثوث',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments:
        'يمكنك إنشاء بث متعدد باستخدام القوالب وفقًا لمجموعة العملاء المختلفة',
      createNewBroadcast: 'إنشاء بث جديد',
      name: 'الاسم',
      segment: 'مجموعة',
      template: 'القالب',
      contacts: 'الاتصالات',
      send: 'إرسال',
      delivered: 'تم التوصيل',
      read: 'تمت القراءة',
      failed: 'فشل',
      broadcastAt: 'البث في',
      status: 'الحالة',
      actions: 'الإجراءات',
      nA: 'N/A',
      seeHowToUse: 'راجع كيفية الاستخدام',
      deleteBroadcast: 'حذف البث',
      wantToDeleteThisBroadcast: 'هل تريد بالتأكيد حذف هذا البث؟',
      placeholders: {
        searchHere: 'ابحث هنا',
        enterBroadcastName: 'أدخل اسم البث'
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'حذف العديد من البثوث',
        broadcastAnalytics: 'تحليلات البث',
        deleteBroadcast: 'حذف البث',
      },
      broadcastAnalytics: {
        broadcastInsights: 'إحصائيات البث',
        broadcastStatus: 'حالة البث',
        youCanFindDetailsOfEachContactBelow: 'يمكنك العثور على تفاصيل كل اتصال أدناه وتصدير تلك التي قد تكون لديها مشاكل.',
        filterApplied: 'تم تطبيق عامل التصفية',
        placeholders: {
          searchByPhoneNumber: 'البحث حسب رقم الهاتف',
          searchByName: 'البحث حسب الاسم',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'تصفية البيانات بناءً على الحالة',
          exportData: 'تصدير البيانات',
        },
        modal: {
          exportData: 'تصدير البيانات',
          exportCompleteBroadcastData: 'تصدير بيانات البث الكاملة',
          export: 'تصدير'
        }
      },
      createBroadcast: {
        broadcastName: 'اسم البث',
        broadcastNameIsRequired: 'اسم البث مطلوب',
        template: 'القالب',
        segments: 'مجموعات',
        whenToSend: 'متى يتم الإرسال',
        now: 'الآن',
        later: 'في وقت لاحق',
        selectBroadcastUsing: 'حدد البث باستخدام',
        usingCsv: 'استخدام CSV',
        usingSegment: 'باستخدام المجموعة',
        allContacts: 'جميع الاتصالات',
        selectDate: 'حدد التاريخ',
        selectTimeSlot: 'حدد الفترة الزمنية',
        assignVariablesToTemplates: 'تعيين المتغيرات للقوالب',
        addHeaderMedia: 'إضافة وسائط العنوان',
        selectFile: 'اختر الملف',
        cancel: 'إلغاء',
        createBroadcast: 'إنشاء بث',
        broadcastUsing: 'بث باستخدام',
        selectCSVFile: 'حدد ملف CSV',
        importContacts: 'استيراد الاتصالات',
        beforeYouStartUpload: 'قبل أن تبدأ في التحميل، يرجى التأكد من',
        point1: '1. تحميل ملف CSV العينة.',
        point2: '2. ابدأ في إدخال البيانات من الصف الثاني فصاعدًا.',
        point3: '3. في كل صف، ستجد بيانات اتصال واحدة.',
        point4: '4. تأكد من أن جميع أرقام الهواتف فريدة.',
        point5: '5. الحد الأقصى لحجم الملف المسموح به للتحميل هو 20 ميجابايت',
        clickHere: 'انقر هنا',
        toDownloadSampleFile: 'لتحميل ملف العينة',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          selectTemplate: 'حدد القالب',
          searchTemplateByName: 'ابحث عن القالب بالاسم',
          selectSegments: 'اختر مجموعة',
          selectTimeSlot: 'حدد الفترة الزمنية',
          selectValueForThisVariable: 'حدد القيمة لهذا المتغير',
          searchHeaderByVariableName: 'ابحث عن متغير العنوان بالاسم',
          variableFallbackValue: 'قيمة الاحتياطية للمتغير',
          searchBodyVariableByName: 'ابحث عن متغير الجسم بالاسم',
          searchSegmentByName: 'مجموعة البحث بالاسم',
          selectBroadcastUsing: 'حدد البث باستخدام',
        },
        indexMergeDatePicker: {
          selectStartDate: 'حدد تاريخ البدء',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'استيراد الاتصالات لبث WhatsApp',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'رقم WhatsApp',
        errorCode: 'رمز الخطأ',
        errorMessage: 'رسالة الخطأ',
        nA: 'NA',
      }
    },
    design: {
      customizeYourLandingPage: 'تخصيص صفحتك الرئيسية',
      customizeYourConversationalBot: 'تخصيص الروبوت الذكي الخاص بك',
      customizeYourBot: 'تخصيص الروبوت الخاص بك',
      createHeadingTextAndTheme: 'قم بإنشاء نص رئيسي وسمة تعبر عن شخصية علامتك التجارية',
      content: 'المحتوى',
      theme: 'السمة',
      layout: 'التخطيط',
      landingPageName: 'اسم الصفحة الرئيسية',
      botName: 'اسم الروبوت',
      charactersRemaining: 'الأحرف المتبقية',
      landingPageHeader: 'عنوان الصفحة الرئيسية',
      landingPageDescription: 'وصف الصفحة الرئيسية',
      hideContent: 'إخفاء المحتوى',
      profilePic: 'صورة الملف الشخصي',
      selectFile: 'اختر ملف',
      socialMediaLinks: 'روابط وسائل التواصل الاجتماعي',
      initialMessage: 'رسالة البداية للروبوت الدردشة',
      flowTriggerDetail: 'زر لجمع تفاصيل الاتصال',
      selectType: 'اختر النوع',
      url: 'رابط',
      delete: 'حذف',
      editLink: 'تعديل الرابط',
      addLink: 'إضافة رابط',
      callToAction: 'نداء للتحرك',
      liveChatWelcomeMessage: 'رسالة الترحيب في الدردشة المباشرة',
      liveChatTriggerMessage: 'رسالة تشغيل الدردشة المباشرة',
      autoHideCallToAction: 'إخفاء النداء للتحرك تلقائيًا',
      maxDelayShouldBe300Seconds: 'يجب أن يكون الحد الأقصى للتأخير 300 ثانية',
      landingPageThemeColor: 'لون سمة الصفحة الرئيسية',
      themeColor: 'لون السمة',
      landingPageBackground: 'خلفية الصفحة الرئيسية',
      chatBackgroundColor: 'لون خلفية الدردشة',
      color: 'لون',
      gradient: 'تدرج',
      image: 'صورة',
      video: 'فيديو',
      gradientStart: 'بداية التدرج',
      gradientEnd: 'نهاية التدرج',
      positionOnWeb: 'الموقع على الويب',
      positionOnMobile: 'الموقع على الجوال',
      windowSize: 'حجم النافذة',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'مخصص',
      height: 'الارتفاع',
      minimum300HeightIsRequired: 'الارتفاع يجب أن يكون على الأقل 300 بكسل',
      heightCannotExceed720: 'الارتفاع لا يجوز أن يتجاوز 720 بكسل',
      width: 'العرض',
      px: 'بكسل',
      minimum200WidthIsRequired: 'العرض يجب أن يكون على الأقل 200 بكسل',
      widthCannotExceed1050: 'العرض لا يجوز أن يتجاوز 1050 بكسل',
      discard: 'تجاهل',
      saveChanges: 'حفظ التغييرات',
      landingPageBot: 'روبوت الصفحة الرئيسية',
      chatBot: 'روبوت الدردشة',
      preview: 'معاينة',
      test: 'اختبار',
      botIcon: 'أيقونة الروبوت',
      websiteBot: 'روبوت الدردشة على الموقع',
      selectFont: 'اختر الخط',
      fontLibrary: 'مكتبة الخطوط',
      uploadFont: 'تحميل الخط',
      selectFontForBot: 'اختر الخط للروبوت',
      uploadedFont: 'الخط المُرفق',
      placeholders: {
        enterName: 'أدخل الاسم',
        enterHeaderText: 'أدخل نص العنوان',
        enterDescription: 'أدخل الوصف',
        enterUrl: 'أدخل الرابط',
        howCanIHelpYou: 'كيف يمكنني مساعدتك؟',
        enterYourWelcomeMessageHere: 'أدخل رسالة الترحيب الخاصة بك هنا',
        enterYourLiveChatMessageHere: 'أدخل رسالة الدردشة المباشرة الخاصة بك هنا',
        enterTimeInSeconds: 'أدخل الوقت بالثواني',
        enterHeight: 'أدخل الارتفاع',
        enterWidth: 'أدخل العرض',
        flowInitialMessage: 'أدخل الرسالة الأولية',
        selectFont: 'اختر الخط',
      },
      appTooltip: {
        standardLayout: 'التخطيط القياسي',
        rightLayout: 'التخطيط الجانب الأيمن',
        mergedLayout: 'التخطيط المدمج',
        enterNameOfYourBot: 'أدخل اسم الروبوت الخاص بك',
        enterNameOfYourLandingPageBot: 'أدخل اسم روبوت صفحة الهبوط الخاص بك',
        headingTextThatAppearOnLandingPageBot: 'أدخل نص العنوان الذي ترغب في ظهوره على روبوت صفحة الهبوط الخاص بك',
        descriptionTextThatAppearOnLandingPageBot: 'أدخل نص الوصف الذي ترغب في ظهوره على روبوت صفحة الهبوط الخاص بك.',
        addLinksAndSocialMediaIcons: 'أضف روابط وأيقونات وسائل التواصل الاجتماعي',
        initialFlowMessage: 'أدخل الرسالة الأولى التي سيقوم بها الروبوت لبدء المحادثة مع العميل',
        anInvitingCatchPhrase: 'عبارة جذابة ستجعل المستخدم يتحدث مع الروبوت',
        setAWelcomeMessageForYourVisitors: 'قم بتعيين رسالة ترحيب لزوار موقعك. ستكون هذه هي أول رسالة في محادثتك.',
        setAMessageToInitiateLiveChat: 'قم بتعيين رسالة لبدء محادثة مباشرة من جانب الزائر. ستتلقى إشعارًا في كل مرة يتم فيها تنشيط هذه الرسالة في إحدى محادثاتك.',
        autoHideCallToAction: 'إخفاء تلقائي لنص "الدعوة للتحرك" بعد الوقت المحدد',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'يمكنك اختيار إخفاء الشعار والنصوص لعرض نافذة الدردشة فقط على الشاشة',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'قم بتحميل شعار لصفحة روبوت الدردشة.',
        TOOLTIP_CHAT_THEME_COLOR: 'اختر لون نافذة الدردشة لروبوتك',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'اختر لون الخلفية لنافذة الدردشة لروبوتك',
        TOOLTIP_WEB_1ST_POSITION: 'قم بتحديد موقع روبوتك في الجزء السفلي الأيسر من سطح المكتب للمستخدم',
        TOOLTIP_WEB_2ND_POSITION: 'قم بتحديد موقع روبوتك في الزاوية اليمنى الوسطى من سطح المكتب للمستخدم',
        TOOLTIP_WEB_3RD_POSITION: 'قم بتحديد موقع روبوتك في الزاوية اليمنى السفلية من سطح المكتب للمستخدم',
        TOOLTIP_MOBILE_1ST_POSITION: 'قم بتحديد موقع روبوتك في الزاوية اليسرى السفلية من هاتف المستخدم',
        TOOLTIP_MOBILE_2ND_POSITION: 'قم بتحديد موقع روبوتك في الزاوية اليمنى الوسطى من هاتف المستخدم',
        TOOLTIP_MOBILE_3RD_POSITION: 'وضع الروبوت الخاص بك في الزاوية اليمنى السفلية لهاتف المستخدم',
        TOOLTIP_WINDOW_HEIGHT: 'تغيير حجم نافذة الدردشة للروبوت',
        TOOLTIP_BOT_ICON: 'اختر أيقونة أو قم بتحميل شعار/صورة لروبوتك',
        flowTriggerMessage: 'أدخل عدد الرسائل التي يجب طرحها قبل جمع معلومات الاتصال مثل الاسم والبريد الإلكتروني ورقم الهاتف',
        TOOLTIP_SELECT_FONT: 'حدد خطًا أو قم بتحميل الخط الخاص بك',
        TOOLTIP_UPLOAD_FONT: 'تحميل أي خط من اختيارك (.ttf، .otf، .woff، .eot)',
        TOOLTIP_NEW_UPLOADED_FONT: 'الخط الذي تم تحميله حديثًا'
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'إنشاء حملة رش',
      youCanCreateMultipleCampaigns: 'يمكنك إنشاء العديد من حملات الرش',
      dripCampaignName: 'اسم حملة الرش',
      dripCampaignNameIsReuired: 'اسم حملة الرش مطلوب',
      continue: 'متابعة',
      manageDripCampaign: 'إدارة حملة الرش',
      youCanCreateMultipleCampaignsWith: 'يمكنك إنشاء العديد من حملات الرش مع رسائل مجدولة تلقائيًا لتحفيز استجابات من فئات المستخدمين المحددة.',
      createNewDripCampaign: 'إنشاء حملة رش جديدة',
      campaignName: 'اسم الحملة',
      appliedOn: 'تم تطبيقها على',
      segment: 'مجموعة',
      template: 'القالب',
      startDate: 'تاريخ البدء',
      action: 'عمل',
      nA: 'غ/م',
      seeHowToUse: 'راجع كيفية الاستخدام',
      deleteDripCampaign: 'حذف حملات الرش',
      wantToDeleteThisDripCampaign: 'هل أنت متأكد أنك تريد حذف حملات الرش هذه؟',
      placeholders: {
        enterDripCampaignName: 'أدخل اسم حملة الرش',
        searchByName: 'البحث حسب الاسم',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'حذف العديد من حملات الرش',
        deleteDripCampaign: 'حذف حملة الرش',
      },
      createDripCampaign: {
        dripCampaignName: 'اسم حملة الرش',
        appliedOn: 'تم تطبيقها على',
        segments: 'مجموعات',
        selectDate: 'حدد التاريخ',
        scheduleYourTemplate: 'جدولة القوالب الخاصة بك',
        schedule: 'جدولة',
        template: 'القالب',
        sendAfter: 'إرسال بعد',
        assignVariablesToTemplate: 'تعيين المتغيرات للقوالب',
        addHeaderMedia: 'إضافة وسائط رأس',
        selectFile: 'حدد الملف',
        createDripCampaign: 'إنشاء حملة الرش',
        newSubscriber: 'مشترك جديد',
        minutes: 'دقائق',
        hours: 'ساعات',
        days: 'أيام',
        placeholders: {
          enterDripName: 'أدخل اسم حملة الرش',
          selectAppliedOn: 'حدد المجال المستخدم عليه',
          selectSegments: 'اختر مجموعة',
          searchByName: 'البحث حسب الاسم',
          selectTemplate: 'حدد القالب',
          searchTemplateByName: 'البحث عن القالب حسب الاسم',
          enterSendAfter: 'أدخل "إرسال بعد"',
          selectValueForThisVariable: 'حدد القيمة لهذا المتغير',
          searchHeaderVariableByName: 'البحث عن المتغير الرأسي حسب الاسم',
          searchBodyVariableByName: 'البحث عن المتغير الجسمي حسب الاسم',
        },
        dateTimePicker: {
          selectStartDate: 'حدد تاريخ البدء',
        },
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'تكوين إعدادات الصفحة الرئيسية العامة',
      configureBotsGeneralSettings: 'تكوين إعدادات البوت العامة',
      selectTheTimeZone: 'حدد المنطقة الزمنية واللغة ومذكرة صغيرة لعملائك عندما ',
      isNotAvailable: 'غير متاح',
      landingPagesBot: 'بوت الصفحة الرئيسية',
      bot: 'البوت',
      waitingMessage: 'رسالة الانتظار',
      landingPageBotStatus: 'حالة بوت الصفحة الرئيسية',
      chatBotStatus: 'حالة البوت',
      unavailabilityMessage: 'رسالة عدم التوفر',
      sendIncompleteResponses: 'إرسال ردود غير مكتملة',
      language: 'اللغة',
      invalidateCache: 'إبطال الذاكرة المؤقتة',
      timezone: 'المنطقة الزمنية',
      chatWindowMessageHistory: 'تاريخ رسائل نافذة الدردشة',
      session: 'جلسة',
      forever: 'إلى الأبد',
      english: 'الإنجليزية',
      spanish: 'الإسبانية',
      french: 'الفرنسية',
      portuguese: 'البرتغالية',
      german: 'الألمانية',
      italian: 'الإيطالية',
      swedish: 'السويدية',
      arabic: 'العربية',
      malay: 'الماليزية',
      russian: 'الروسية',
      discard: 'تجاهل',
      saveChanges: 'حفظ التغييرات',
      seeHowToUse: 'راجع كيفية الاستخدام',
      placeholders: {
        enterYourWaitingMessageHere: 'أدخل رسالة الانتظار هنا',
        // selectLanguage: '',
        searchTimeZone: 'ابحث عن المنطقة الزمنية',
        // selectChatRetentionPolicy: '',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'قم بتخصيص الرسالة كما ترغب بأن تظهر في نافذة الدردشة فورًا بعد أن يقوم مستخدم الروبوت بإرسال طلب للدردشة المباشرة',
        TOOLTIP_CHATBOT_STATUS: 'تنشيط / إلغاء تنشيط الروبوت',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'قم بتخصيص الرسالة كما ترغب بأن تظهر في نافذة الدردشة عندما لا يتوفر أي من وكلائك للدردشة المباشرة مع مستخدم الروبوت',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'إرسال إشعارات البريد الإلكتروني حتى عندما لم يكمل مستخدم الروبوت الحديث.',
        TOOLTIP_LANGUAGE: 'حدد لغة الروبوت',
        TOOLTIP_INVALIDATE_CACHE: 'انقر هنا لمسح الذاكرة المؤقتة عند تحرير تدفق الدردشة أو الإعدادات الخاصة بالروبوت، حيث تنعكس التغييرات فقط بعد 15 دقيقة',
        TOOLTIP_TIME_ZONE: 'حدد المنطقة الزمنية للروبوت الخاص بك',
        TOOLTIP_CHAT_RETENTION: 'حدد ما إذا كنت ترغب في الاحتفاظ بمحادثات مستخدم الروبوت إلى الأبد في نافذة الدردشة أم فقط لجلسة المستعرض.'
      }
    },
    liveChat: {
      customizeYourChatWindow: 'قم بتخصيص نافذة الدردشة الخاصة بك',
      controlAndCustomizeTheChatWindow: 'تحكم وقم بتخصيص نافذة الدردشة التي سيتفاعل معها عملاؤك.',
      allowVisitorsToTransferChatToWhatsapp: 'السماح للزوار بنقل الدردشة إلى WhatsApp',
      allowVisitorsToRefreshChat: 'السماح للزوار بتحديث الدردشة',
      displayNameForTransferringChatToWhatsapp: 'اسم العرض لنقل الدردشة إلى WhatsApp',
      displayNameForTransferringToLiveChat: 'اسم العرض لنقل الدردشة إلى الدردشة المباشرة/الوكيل',
      displayNameForTransferringAChatToBot: 'اسم العرض لنقل الدردشة إلى الروبوت',
      discard: 'تجاهل',
      saveChanges: 'حفظ التغييرات',
      allowVisitorsToTransferChatToBot: 'السماح للزوار بنقل الدردشة إلى الروبوت.',
      allowVisitorsToRequestForLiveChat: 'السماح للزوار بطلب الدردشة المباشرة.',
      indicativeTextForCustomersReply: 'نص توضيحي لرد العملاء',
      displayNameForRestartingChatBot: 'اسم العرض لإعادة تشغيل محادثة الروبوت',
      seeHowToUse: 'راجع كيفية الاستخدام',
      placeholders: {
        enterButtonNameForWhatsapp: 'أدخل اسم الزر لنقل إلى WhatsApp',
        enterButtonNameForLiveChat: 'أدخل اسم الزر لنقل إلى الدردشة المباشرة أو الوكيل',
        enterButtonNameForBot: 'أدخل اسم الزر لنقل إلى الروبوت',
        enterTextDisplayInEmptyReplyArea: 'أدخل النص الذي يظهر في منطقة الرد الفارغة',
        enterButtonForRestartingConversation: 'أدخل اسم الزر لإعادة بدء المحادثة',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'السماح لزوار الموقع بنقل الدردشة إلى رقم WhatsApp الخاص بالوكيل الذي يتصلون به. يجب تمكين ميزة الدردشة المباشرة لتفعيل هذه الميزة.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'السماح لمستخدمي الروبوت بتحديث الدردشة حتى يتمكنوا من بدء المحادثة من جديد.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'السماح لمستخدمي الروبوت بنقل الدردشة إلى الروبوت في أي وقت خلال المحادثة مع وكيل الدردشة المباشرة.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'السماح لمستخدمي الروبوت بطلب الدردشة المباشرة مع الوكيل في أي وقت يرون أنه مناسب.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'إذا كنت قد قمت بتمكين المستخدم من النقل إلى WhatsApp، قم بملء كيفية عرض هذا الخيار.',
        ifYouHaveEnabledUserToTransferToLiveChat: 'إذا كنت قد قمت بتمكين المستخدم من النقل إلى الدردشة المباشرة، قم بملء كيفية عرض هذا الخيار.',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'إذا قمت بتمكين المستخدم من النقل إلى WhatsApp،' + 'املأ كيفية العودة إلى الروبوت.',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'قم بملء كيفية عرض مساحة الرد للعملاء.',
        ifYouHaveEnabledUserToRestartChatBot: 'إذا كنت قد قمت بتمكين المستخدم من إعادة بدء محادثة الروبوت، قم بملء كيفية عرض هذا الخيار.',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'تدفقات الدردشة',
      dripCampaignHeader: 'حملة تسريب',
      widgetsHeader: 'الحاجيات',
      broadcastHeader: 'بث',
      ordersHeader: 'الطلبات',
      manageHeader: 'إدارة',
      inputHeader: 'المدخلات',
      apiKeyHeader: 'مفتاح الواجهة البرمجية',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'إنشاء رحلة تعريف العملاء باستخدام حملة تسريب من تدفقات الدردشة المجدولة.',
        TOOLTIP_CHAT_FLOW_TITLE: 'تخصيص قوالب تدفق الدردشة وإنشاء عدة تدفقات دردشة لجماهير مستهدفة مختلفة.',
        TOOLTIP_WIDGET_TITLE: 'إنشاء حاجيات (واجهات دردشة) وتضمينها في صفحات الويب الترويجية لتوجيه الزوار إلى الروبوت',
        TOOLTIP_BROADCAST_TITLE: 'بث رسائل أو تدفقات إلى جهات اتصالك بنقرة واحدة في الوقت المجدول مسبقًا',
        TOOLTIP_ORDERS_TITLE: 'قائمة الطلبات التي قام بها العميل',
        TOOLTIP_MANAGE_TITLE: 'إدارة وظائف الروبوت لجهات الاتصال/العملاء المحتملين والكلمات الرئيسية',
        TOOLTIP_INPUT_TITLE: 'إنشاء قوالب WhatsApp وصور ونصوص كمواد رد لجهات الاتصال/العملاء المحتملين',
      },
      apiWrapper: {
        whatsappApiCredentials: 'بيانات اعتماد واجهة برمجة التطبيقات لـ WhatsApp',
        generateYourWhatsappApiKey: 'قم بإنشاء مفتاح واجهة برمجة التطبيقات الخاص بك للتكامل مع تطبيقاتك المخصصة المطورة.',
        generateApiKey: 'إنشاء مفتاح واجهة برمجة التطبيقات',
        webhookUrl: 'عنوان URL للويب هوك',
        addHeader: 'إضافة رأس',
        test: 'اختبار',
        webhookNotSet: 'لم يتم تعيين عنوان URL للويب هوك',
        webhookHeaders: 'رؤوس الويب هوك',
        setWebhookUrl: 'تعيين عنوان URL للويب هوك',
        placeholders: {
          yourApiKey: 'مفتاح واجهة برمجة التطبيقات الخاص بك',
          enterWebhookUrl: 'أدخل عنوان URL للويب هوك',
          enterHeaderKey: 'أدخل مفتاح الرأس',
          enterValue: 'أدخل القيمة'
        }
      },
      broadcast: {
        createBroadcast: 'إنشاء بث',
        createMultipleBroadcasts: 'يمكنك إنشاء عدة بثوث بتدفقات/قوالب مجدولة متعددة لجماهير عملاء مختلفة.',
        broadcastName: 'اسم البث',
        nameIsRequired: 'الاسم مطلوب',
        selectType: 'حدد النوع',
        selectYourFlowType: 'حدد نوع التدفق الخاص بك',
        segment: 'مجموعة',
        selectYourSegmentType: 'حدد نوع مجموعة الخاص بك',
        whenToSend: 'متى يجب إرسالها',
        selectDate: 'حدد التاريخ',
        messageTag: 'وسم الرسالة',
        selectMessageTag: 'حدد وسم الرسالة',
        contentType: 'نوع المحتوى',
        nonPromotional: 'غير ترويجي (تطبيق وسوم الرسائل)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'وسم كل رسالة تأتي قبل أي تفاعل أو بعد خطوات التأخير التي تزيد عن 24 ساعة بوسم مناسب. الرسائل غير المُوسومة لن تصل إلى المشتركين الذين تفاعلوا مع الروبوت قبل أكثر من 24 ساعة.',
        promotional: 'ترويجي (تجاهل وسوم الرسائل)',
        theseBroadcasts: 'يمكن أن تحتوي هذه البثوث على عروض ترويجية، ولكن الجمهور المستهدف محدود للمشتركين الذين تفاعلوا مع روبوتك في الـ 24 ساعة الأخيرة.',
        createNewBroadcast: 'إنشاء بث جديد',
        sentMessages: 'رسائل تم إرسالها',
        deliveredMessages: 'رسائل تم توصيلها',
        readMessages: 'رسائل تمت قراءتها',
        failedMessages: 'رسائل فشلت',
        type: 'النوع',
        template: 'القالب',
        sent: 'تم الإرسال',
        delivered: 'تم التوصيل',
        read: 'تمت القراءة',
        failed: 'فشل',
        broadcastAt: 'بث في',
        status: 'الحالة',
        shipmentUpdate: 'تحديث الشحنة',
        flow: 'التدفق',
        resendFailedMessages: 'إعادة إرسال الرسائل الفاشلة',
        goBack: 'العودة',
        number: 'الرقم',
        errorCode: 'كود الخطأ',
        failedAt: 'فشل في',
        badGateway: '502 خادم رديء',
        loadMore: 'تحميل المزيد',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          searchBroadcastByName: 'البحث عن البث حسب الاسم',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'إجمالي عدد الرسائل المُبثَّرة تم إرسالها',
          totalBroadcastMessagesDelivered: 'إجمالي عدد الرسائل المُبثَّرة تم توصيلها',
          totalBroadcastMessagesReadByContacts: 'إجمالي عدد الرسائل المُبثَّرة التي تمت قراءتها من قبل الجهات الاتصال',
          totalFailedBroadcastMessages: 'إجمالي عدد الرسائل المُبثَّرة التي فشلت في الإرسال',
          clickToViewDetailedInformationOfFailedMessages: 'انقر لعرض معلومات مفصلة حول الرسائل الفاشلة',
          clickToCreateNewBroadcast: 'انقر لإنشاء بث جديد',
          templateUsedInBroadcast: 'القالب المستخدم في البث',
          numberOfBroadcastMessagesSent: 'عدد الرسائل المُبثَّرة تم إرسالها',
          numberOfBroadcastMessagesDelivered: 'عدد الرسائل المُبثَّرة تم توصيلها',
          numberOfBroadcastMessagesReadByContacts: 'عدد الرسائل المُبثَّرة التي تمت قراءتها من قبل الجهات الاتصال',
          numberOfTimesBroadcastMessagesHasFailed: 'عدد مرات فشل الرسائل المُبثَّرة',
          contactNumberOnWhichMessageDeliveryFailed: 'رقم الجهة التي فشلت في تسليم الرسالة إليها',
          unDeliveredTemplate: 'قالب غير مُسلَّم',
          errorReceivedForFailedMessage: 'الخطأ الذي تم استلامه للرسالة الفاشلة',
          timeWhenMessageDeliveryFailed: 'الوقت الذي فشل فيه تسليم الرسالة',
          TOOLTIP_ADD_BROADCAST_NAME: 'أدخل اسم بث جديد',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'حدد إذا كنت تقوم ببث تدفق أو رسالة.',
          TOOLTIP_BROADCAST_FLOW: 'حدد تدفق الدردشة الذي ترغب في بثه.',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'حدد مجموعة الجهات التي ترغب في بث الرسائل لها.',
          TOOLTIP_BROADCAST_NAME: 'اسم البث',
          TOOLTIP_BROADCAST_TYPE: 'التدفق أو الرسالة المختارة للبث',
          TOOLTIP_BROADCAST_SEGMENT: 'مجموعة الذي تم إنشاء البث له',
          TOOLTIP_BROADCAST_AT: 'تاريخ ووقت المجدولة للبث',
          TOOLTIP_BROADCAST_STATUS: 'حالة البث كما هو مجدول أو قيد التشغيل حاليًا أو تم الانتهاء منه',
        }
      },
      drip: {
        createDripCampaign: 'إنشاء حملة تسريب',
        youCanCreateMultipleCampaigns: 'يمكنك إنشاء عدة حملات',
        dripCampaignName: 'اسم حملة التسريب',
        dripCampaignNameIsRequired: 'اسم حملة التسريب مطلوب',
        youCanCreateMultipleDripCampaigns: 'يمكنك إنشاء عدة حملات تسريب برسائل مجدولة آليًا لتحفيز استجابات من فئات مستخدمين معينة.',
        campaignName: 'اسم الحملة',
        campaignNameIsRequired: 'اسم الحملة مطلوب',
        after: 'بعد ',
        thisMessageWillBeSendAfter: 'ستتم إرسال هذه الرسالة بعد',
        saveAndUpdate: 'حفظ وتحديث',
        schedule: ' جدولة',
        select: 'اختيار',
        messages: 'الرسائل',
        cancel: 'إلغاء',
        createCampaign: 'إنشاء حملة',
        updateCampaign: 'تحديث الحملة',
        createNewCampaign: 'إنشاء حملة جديدة',
        messagesSent: 'الرسائل المرسلة',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        action: 'إجراء',
        placeholders: {
          nameYourDripCampaign: 'قم بتسمية حملتك التسريبية',
          enterCampaignName: 'أدخل اسم الحملة',
          searchCampaignByName: 'البحث عن الحملة حسب الاسم',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'انقر لإضافة حملة جديدة',
          TOOLTIP_SCHEDULE: 'خطط لمتى وأي تدفق دردشة لإرساله أولاً في هذه الحملة.',
          TOOLTIP_AFTER_DAYS: 'قم بجدولة متى يجب إرسال التدفق الأول للحملة في دقائق أو ساعات أو أيام.',
          TOOLTIP_ADD_MESSAGES: 'انقر لإضافة تدفق جديد إلى الحملة',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'أدخل اسم حملة جديدة',
          TOOLTIP_CAMPAIGN_NAME: 'اسم الحملة',
          TOOLTIP_NO_MESSAGES_SENT: 'عدد الرسائل المُرسلة من قبل الحملة',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'تاريخ ووقت إنشاء الحملة',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'تاريخ ووقت آخر تعديل للحملة',
          TOOLTIP_CAMPAIGN_ACTION: 'تحرير أو حذف الحملة',
          TOOLTIP_UPDATE_CAMPAIGN: 'انقر لحفظ التعديلات التي تم إجراؤها في إعداد الحملة',
        }
      },
      flows: {
        createChatFlow: 'إنشاء تدفق دردشة',
        youCanCreateMultipleConversationsFlow: 'يمكنك إنشاء تدفقات عديدة للمحادثات عبر قنوات متعددة لاختبار A/B والبث.',
        flowName: 'اسم التدفق',
        nameIsRequired: 'الاسم مطلوب',
        cancel: 'إلغاء',
        createFlow: 'إنشاء تدفق',
        createNewFlow: 'إنشاء تدفق جديد',
        ofMessages: 'رسالة',
        triggered: 'تم تفعيله',
        stepsFinsished: 'انتهت الخطوات',
        finished: 'انتهى',
        createdOn: 'تم الإنشاء في',
        lastModified: 'آخر تعديل في',
        defaultFlow: 'تدفق افتراضي',
        action: 'إجراء',
        flowAnalytics: 'تحليلات التدفق',
        placeholders: {
          enterFlowName: 'أدخل اسم التدفق',
          searchFlowByName: 'البحث عن التدفق حسب الاسم',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'عدد مرات تنشيط تدفق الدردشة',
          numberOfCompletedChatFlowStepsWhenTriggered: 'عدد الخطوات المكتملة في تدفق الدردشة عند التنشيط',
          numberOfTimesUsersCompletedChatFlowSteps: 'عدد مرات اكتمال مستخدمين لجميع خطوات تدفق الدردشة',
          dateAndTimeOfCreationOfChatFlow: 'تاريخ ووقت إنشاء تدفق الدردشة',
          dateAndTimeWhenChatFlowLastModified: 'تاريخ ووقت آخر تعديل لتدفق الدردشة',
          TOOLTIP_CREATE_FLOW: 'انقر لإنشاء تدفق دردشة جديد',
          TOOLTIP_FLOW_NAME: 'أدخل اسم تدفق الدردشة',
          TOOLTIP_FLOW_TABLE_NAME: 'اسم تدفق الدردشة',
          TOOLTIP_NO_OF_MESSAGES: 'عدد مكونات الرسائل في تدفق الدردشة',
          TOOLTIP_DEFAULT_FLOW: 'استخدم هذا التبديل لتعيين تدفق الدردشة كافتراضي، الذي سيتم تقديمه لجميع المستخدمين الجدد.',
          TOOLTIP_ACTION: 'تحرير أو حذف تدفق الدردشة',
        }
      },
      manage: {
        leads: 'العملاء المحتملين',
        manageBotsFunctionalityForContacts: 'إدارة وظائف الروبوت لجهات الاتصال/العملاء المحتملين',
        whenToSendLeadInfo: 'متى يتم إرسال معلومات العميل المحتمل',
        smartFlow: 'التدفق الذكي',
        welcomeMessageWhenNoSearchKeywordIsMet: 'رسالة الترحيب عندما لا يتم مطابقة أي كلمة مفتاحية للبحث',
        selectTemplates: 'اختيار القوالب',
        selectFlows: 'اختيار التدفقات',
        selectText: 'اختيار النص',
        selectImages: 'اختيار الصور',
        whenItAlmostReaches24HoursSinceMessage: 'عندما يكاد يصل إلى 24 ساعة منذ آخر رسالة',
        updateChanges: 'حفظ التغييرات',
        manageBotsFunctionalityAccordingToKeywords: 'إدارة وظائف الروبوت وفقًا للكلمات المفتاحية المدخلة من قبل مستخدم الروبوت، في حالة التدفق الذكي',
        addKeywordAction: 'إضافة إجراء الكلمة المفتاحية',
        keywords: 'الكلمات المفتاحية',
        matchingMethod: 'طريقة المطابقة',
        replyMaterial: 'مواد الرد',
        action: 'إجراء',
        select: 'اختيار',
        placeholders: {
          keyword: 'الكلمة المفتاحية'
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'اقترح متى يجب على الروبوت إرسال معلومات العميل/العميل المحتمل إلى نظام CRM المتكامل على الفور، أو بعد 5 دقائق، أو في نهاية التدفق',
          TOOLTIP_LEAD_SMART_FLOW: 'اختر أي تدفق افتراضي لإرساله إلى جميع مستخدمي الروبوت، أو التدفق الذكي (المحدد بواسطة الكلمات المفتاحية المدخلة من قبل مستخدم الروبوت).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'حدد المواد المُرسلة (تدفق، قالب، نص، صورة) لإرسالها إذا لم يتم تطابق أي كلمة مفتاحية مع رد مستخدم الروبوت.',
          TOOLTIP_IT_ALMOST_REACHED: 'حدد المواد المُرسلة للاتصالات/العملاء المحتملين قبل مرور 24 ساعة من آخر رسالة',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'إدارة وظائف الروبوت وفقًا للكلمات المفتاحية المدخلة من قبل مستخدم الروبوت، في حالة التدفق الذكي',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'حدد رد الروبوت كقالب، تدفق، نص أو صورة ضد الكلمات المفتاحية المحددة المُدخلة',
          TOOLTIP_ADD_KEYWORD: 'إضافة كلمات مفتاحية',
          TOOLTIP_MATCHING_METHOD: 'إضافة مطابقة الكلمة المفتاحية على أنها \'دقيقة\' أو \'تحتوي على\'',
          TOOLTIP_REPLY_MATERIAL: 'اختيار قالب، تدفق، نص أو صورة للرد على الكلمة المفتاحية المقترحة',
          TOOLTIP_KEYWORD_ACTIONS: 'حذف إجراء الكلمة المفتاحية',
        },
        socialAlerts: {
          alerts: 'تنبيهات',
          specifyHowYourBotWillNotify: 'حدد كيفية إعلام الروبوت بوجود عملاء محتملين جدد.'
        },
      },
      orders: {
        manageCustomersOrder: 'إدارة طلبات العملاء',
        checkAndManageAllOfYourCustomerOrders: 'تحقق وإدارة جميع طلبات العملاء والتحديثات الخاصة بهم.',
        selectShipmentTemplate: 'اختر قالب الشحن',
        send: 'إرسال',
        export: 'تصدير',
        phoneNumber: 'رقم الهاتف',
        numberOfItems: 'عدد العناصر',
        orderValue: 'قيمة الطلب',
        orderDate: 'تاريخ الطلب',
        status: 'الحالة',
        chat: 'الدردشة',
        item: 'العنصر',
        quantity: 'الكمية',
        amount: 'المبلغ',
        unitPrice: 'سعر الوحدة',
        placeholders: {
          searchOrdersByPhoneNumber: 'البحث في الطلبات بواسطة أرقام الهواتف',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'تصدير بيانات الطلبات في ملف CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'رقم هاتف العميل الذي قام بالطلب',
          TOOLTIP_NO_OF_ITEMS: 'عدد العناصر المطلوبة',
          TOOLTIP_ORDER_VALUE: 'قيمة الطلب',
          TOOLTIP_ORDER_DATE: 'تاريخ تقديم الطلب',
          TOOLTIP_ORDER_STATUS: 'حالة الطلب كمؤكدة، قيد الانتظار، مرفوضة، أو تم شحنها',
          TOOLTIP_ORDER_CHAT: 'جميع المحادثات السابقة المتعلقة بهذا الطلب',
        }
      },
      widgets: {
        installWidgetHeader: 'تثبيت الويدجت',
        createWidgetHeader: 'إنشاء ويدجت',
        copyTheCodeAndPasteItWhereYouWant: 'انسخ الكود والصقه في المكان الذي ترغب فيه في صفحات الويب الخاصة بك',
        youCanCreateMultipleWidgets: 'يمكنك إنشاء عدة ويدجت لإعطاء الروبوت الخاص بك مظهرًا مختلفًا على صفحات الويب المختلفة.',
        installWidget: {
          javascript: 'جافا سكريبت',
          install: 'تثبيت ',
          onYourWebsite: ' على موقعك الإلكتروني',
          copyCode: 'انسخ الكود',
          copyAndPaste: 'انسخ والصق',
          copyThisCodeSnippet: 'انسخ مقتطف الكود هذا وقم بإدراجه في HTML لكل صفحة ويب تريد عرض الويدجت فيها. لمزيد من التفاصيل',
          seeGuide: 'انظر دليل الاستخدام',
        },
        widgetList: {
          widgetName: 'اسم الويدجت',
          nameIsRequired: 'الاسم مطلوب',
          cancel: 'إلغاء',
          createWidget: 'إنشاء ويدجت',
          icon: 'أيقونة',
          name: 'الاسم',
          createdAt: 'تم الإنشاء في',
          action: 'إجراء',
          placeholders: {
            enterWidgetName: 'أدخل اسم الويدجت',
            searchWidgetByName: 'البحث عن ويدجت حسب الاسم',
          },
          appTooltip: {
            widgetIcon: 'أيقونة الويدجت',
            nameOfWidget: 'اسم الويدجت',
            dateAndTimeOfCreationOfWidget: 'تاريخ ووقت إنشاء الويدجت',
            editOrDeleteWidget: 'تحرير أو حذف الويدجت',
          },
        },
        widgetTemplates: {
          widgetName: 'اسم الويدجت',
          nameIsRequired: 'الاسم مطلوب',
          chooseTypeOfWidget: 'اختر نوع الويدجت',
          bar: 'شريط',
          slideIn: 'انزلاق',
          pageTakeOver: 'استيلاء على الصفحة',
          button: 'زر',
          facebookModal: 'نمط فيسبوك',
          comment: 'تعليق',
          direct: 'مباشر',
          whatsappIcon: 'أيقونة واتساب',
          refUrl: 'رابط المرجع',
          qrCode: 'رمز الاستجابة السريعة (QR)',
          backgroundColor: 'لون الخلفية',
          descriptionColor: 'لون الوصف',
          headlineColor: 'لون العنوان',
          buttonBackgroundColor: 'لون خلفية الزر',
          contactNumber: 'رقم الاتصال',
          entryMessage: 'رسالة الدخول',
          buttonText: 'نص الزر',
          selectButtonText: 'حدد نص الزر',
          buttonSize: 'حجم الزر',
          selectOne: 'اختر واحد',
          placement: 'المكان',
          rightBottom: 'أسفل اليمين',
          optInFlow: 'تدفق الموافقة',
          selectFlow: 'حدد التدفق',
          addSubscriberToSequence: 'إضافة مشترك إلى التسلسل',
          selectDrip: 'حدد الرش',
          whenDoesItDisplay: 'متى يتم عرضه (ثواني)',
          showWidgetToSameUserAgainAfter: 'عرض الويدجت لنفس المستخدم مرة أخرى بعد',
          ifManuallyClosedByUser: 'إذا تم إغلاقه يدوياً بواسطة المستخدم، عرضه بعد',
          specificPosts: 'المنشورات المحددة',
          allPosts: 'جميع المنشورات',
          postMessage: 'رسالة المنشور',
          postDate: 'تاريخ المنشور',
          status: 'الحالة',
          action: 'إجراء',
          discard: 'تجاهل',
          saveChanges: 'حفظ التغييرات',
          nextStep: 'الخطوة التالية',
          widgetDescription: 'وصف الويدجت',
          integrateTheBarWidget: `قم بتكامل ويدجت الشريط في صفحة الويب الخاصة بك وزيادة عدد مشتركي صفحتك مع إرسالهم تدفقات رسائل الروبوت المخصصة.`,
          hereIsYourWidgetHeadline: 'إليك عنوان الويدجت الخاص بك، انقر هنا لتغييره.',
          sendUsMessage: 'أرسل لنا رسالة',
          weAlsoPutDefaultTextHere: 'نحن أيضًا نضع نصًا افتراضيًا هنا. تأكد من تحويله إلى رسالة قيمة.',
          selectYourPagePost: 'حدد منشور صفحتك',
          nA: 'غ/م',
          saveRule: 'حفظ القاعدة',
          placeholders: {
            enterWidgetName: 'أدخل اسم الويدجت',
            enterNumber: 'أدخل الرقم',
            enterMessageHere: 'أدخل الرسالة هنا',
            enterTimeInSeconds: 'أدخل الزمن بالثواني',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'تخصيص إعدادات تفعيل الروبوت الخاص بك',
      makeYourBotsTimingPerfect: 'جعل توقيت الروبوت الخاص بك مثاليًا',
      onDesktopTriggerTime: 'وقت التفعيل على سطح المكتب (ثواني)',
      onMobileTriggerTime: 'وقت التفعيل على الهواتف المحمولة (ثواني)',
      chatBubble: 'فتح الدردشة تلقائيًا',
      seconds: 'ثواني',
      discard: 'تجاهل',
      saveChanges: 'حفظ التغييرات',
      autoTriggerOnOrOff: 'التفعيل التلقائي: تشغيل/إيقاف',
      seeHowToUse: 'انظر كيفية الاستخدام',
      alwaysOpenAfter: 'فتح دائمًا بعد الزمن المحدد أعلاه',
      automaticallyAfterSelectedNumberOfTime: 'تلقائيًا بعد عدد محدد من الوقت',
      neverAutomaticallyOpenBot: 'لا تفتح الروبوت تلقائيًا أبدًا',
      placeholders: {
        enterTimeInSeconds: 'أدخل الوقت بالثواني',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'وقت الانتظار في الثواني على أجهزة الكمبيوتر للزوار قبل أن يظهر الروبوت الخاص بك تلقائيًا',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'وقت الانتظار في الثواني على الأجهزة المحمولة للزوار قبل أن يظهر الروبوت الخاص بك تلقائيًا',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'عندما يغلق الزائر نافذة الدردشة، حدد كيف يجب أن يكون سلوك الروبوت في الظهور التلقائي',
        customerWaitingTimeToOpenBubble: 'وقت انتظار الزبون لفتح فقاعة الدردشة',
        wantToDisableAutoTriggering: 'انقر إذا كنت ترغب في تعطيل تفعيل الروبوت تلقائيًا على صفحات الويب',
      },
    },
    userAccess: {
      customizeYourBotsSettings: 'قم بتخصيص إعدادات مستخدم الروبوت الخاص بك',
      decideWhoCanAccessYourBot: 'قرر من يمكن الوصول إلى الروبوت الخاص بك',
      deviceSupport: 'دعم الأجهزة',
      blockIpAddresses: 'حظر عناوين IP',
      hideChatBotOnSpecificPages: 'إخفاء Chatbot على الصفحات المعينة',
      selectCountriesToEnableBot: 'اختر البلدان لتمكين الروبوت',
      discard: 'تجاهل',
      saveChanges: 'حفظ التغييرات',
      seeHowToUse: 'انظر كيفية الاستخدام',
      allDevices: 'جميع الأجهزة',
      mobileOnly: 'الهواتف المحمولة فقط',
      desktopOnly: 'سطح المكتب فقط',
      placeholders: {
        enterIpAddress: 'أدخل عنوان IP (ipv4 أو ipv6) واضغط على "Enter"',
        enterUrl: 'أدخل عنوان URL للصفحات واضغط على "Enter"',
        searchCountry: 'ابحث عن البلد',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'حدد على أي أجهزة يجب أن يظهر الروبوت الخاص بك',
        TOOLTIP_BLOCK_IP_ADDRESS: 'أدخل عناوين IP حيث لا ترغب في ظهور الروبوت الخاص بك',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'أدخل عناوين URL لصفحات الويب التي لا ترغب في ظهور الروبوت الخاص بك عليها',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'حدد المواقع التي ترغب منها في السماح للمستخدمين بالوصول إلى الروبوت الخاص بك',
      },
    },
    facebook: {
      facebookBotSettings: 'إعدادات روبوت Facebook',
      chatFlowsHeader: 'تدفقات الدردشة',
      dripCampaignHeader: 'حملة التسريب',
      broadcastHeader: 'بث',
      widgetsHeader: 'الحاجيات',
      autoReplyHeader: 'الردود التلقائية',
      generalHeader: 'عام',
      facebookAutomation: 'إدارة روبوت Facebook',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'قم بتخصيص قوالب تدفق الدردشة وإنشاء تدفقات دردشة متعددة لجمهور مستهدف مختلف.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'قم بإنشاء رحلة استقبال العملاء باستخدام حملة تسريب تضمن تدفقات دردشة مجدولة.',
      },
      broadcasts: {
        createBroadcast: 'إنشاء بث',
        youCanCreateMultipleBroadcasts: 'يمكنك إنشاء عدة بثوات',
        broadcastName: 'اسم البث',
        broadcastNameIsRequired: 'اسم البث مطلوب',
        continue: 'متابعة',
        manageBroadcasts: 'إدارة البثوات',
        youCanCreateMultipleBroadcastsWith: 'يمكنك إنشاء عدة بثوات مع قوالب مجدولة متعددة لفئات العملاء المختلفة.',
        createNewBroadcast: 'إنشاء بث جديد',
        name: 'الاسم',
        segment: 'مجموعة',
        template: 'القالب',
        contacts: 'جهات الاتصال',
        send: 'إرسال',
        failed: 'فشل',
        broadcastAt: 'البث في',
        status: 'الحالة',
        actions: 'إجراءات',
        nA: 'غير متاح',
        deleteBroadcast: 'حذف البث',
        wantToDeleteThisBroadcast: 'هل أنت متأكد أنك تريد حذف هذا البث؟',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          searchHere: 'ابحث هنا',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'حذف عدة بثوات',
          deleteBroadcast: 'حذف البث'
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'اسم البث',
        broadcastNameIsRequired: 'اسم البث مطلوب',
        flow: 'تدفق',
        segments: 'مجموعات',
        whenToSend: 'متى يجب الإرسال',
        selectDate: 'حدد التاريخ',
        selectTimeSlot: 'حدد الفترة الزمنية',
        createBroadcast: 'إنشاء بث',
        cancel: 'إلغاء',
        now: 'الآن',
        later: 'لاحقًا',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          selectFlow: 'حدد التدفق',
          searchFlowByName: 'ابحث عن تدفق حسب الاسم',
          selectSegments: 'اختر مجموعة',
          searchSegmentByName: 'مجموعة البحث بالاسم',
          selectTimeSlot: 'حدد الفترة الزمنية',
        },
        indexMergeDatePicker: {
          selectStartDate: 'حدد تاريخ البدء',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'إنشاء حملة التسريب',
        youCanCreateMultipleCampaign: 'يمكنك إنشاء حملات تسريب متعددة',
        dripCampaignName: 'اسم حملة التسريب',
        dripCampaignNameIsRequired: 'اسم حملة التسريب مطلوب',
        continue: 'متابعة',
        manageDripCampaign: 'إدارة حملة التسريب',
        youCanCreateMutlipleDripCampaign: 'يمكنك إنشاء حملة تسريب متعددة برسائل مجدولة تلقائيًا' + 'لجذب الاستجابات من شرائح مستخدمين محددة.',
        createNewDripCampaign: 'إنشاء حملة تسريب جديدة',
        campaignName: 'اسم الحملة',
        appliedOn: 'تم التطبيق على',
        segment: 'مجموعة',
        flow: 'التدفق',
        startDate: 'تاريخ البدء',
        action: 'إجراء',
        nA: 'غير متاح',
        deleteDripCampaign: 'حذف حملات التسريب',
        wantToDeleteThisDripCampaign: 'هل أنت متأكد أنك تريد حذف هذه الحملة؟',
        placeholders: {
          enterDripCampaignName: 'أدخل اسم حملة التسريب',
          searchByName: 'البحث بالاسم',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'حذف حملات تسريب متعددة',
          deleteDripCampaign: 'حذف حملة التسريب',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'اسم حملة التسريب',
          appliedOn: 'تم التطبيق على',
          segments: 'مجموعات',
          selectDate: 'حدد التاريخ',
          scheduleYourFlows: 'جدولة التدفقات الخاصة بك',
          schedule: 'الجدول',
          flow: 'تدفق',
          sendAfter: 'إرسال بعد',
          cancel: 'إلغاء',
          newSubscriber: 'مشترك جديد',
          days: 'أيام',
          minutes: 'دقائق',
          hours: 'ساعات',
          createDripCampaign: 'إنشاء حملة تسريب',
          placeholders: {
            enterDripName: 'أدخل اسم حملة التسريب',
            selectAppliedOn: 'حدد المجال التي تم التطبيق عليه',
            selectSegment: 'حدد مجموعة',
            searchByName: 'ابحث بالاسم',
            selectTemplate: 'حدد القالب',
            searchFlowByName: 'ابحث عن تدفق بالاسم',
            enterSendAfter: 'أدخل "إرسال بعد"',
          },
          dateTimePicker: {
            selectStartDate: 'حدد تاريخ البدء',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'معرّف المحادثة',
        errorCode: 'رمز الخطأ',
        errorMessage: 'رسالة الخطأ',
        nA: 'NA',
      },
      flows: {
        chatFlows: 'تدفقات الدردشة',
        createChatFlow: 'إنشاء تدفق دردشة',
        youCanCreateMultipleChatFlows: 'يمكنك إنشاء تدفقات دردشة متعددة عبر عدة قنوات لاختبار A/B والبث الإذاعي.',
        flowName: 'اسم التدفق',
        nameIsRequired: 'الاسم مطلوب',
        cancel: 'إلغاء',
        createFlow: 'إنشاء تدفق',
        importFlow: 'استيراد تدفق',
        createNewFlow: 'إنشاء تدفق جديد',
        ofMessages: 'من الرسائل',
        createdOn: 'تم الإنشاء في',
        lastModified: 'آخر تعديل في',
        defaultFlow: 'التدفق الافتراضي',
        action: 'إجراء',
        exportStatus: 'حالة التصدير',
        flowExport: 'تصدير التدفق',
        download: 'تحميل',
        beforeYouStartUploadPleaseMakeSure: 'قبل أن تبدأ في التحميل، يرجى التحقق من التالي',
        point1: '1. الحد الأقصى لحجم الملف المسموح به للتحميل هو 20 ميغابايت',
        point2: '2. يجب أن يكون تنسيق الملف في JSON',
        selectFile: 'حدد الملف',
        inProgress: 'قيد التنفيذ',
        available: 'متاح',
        initiated: 'تم التفعيل',
        placeholders: {
          enterFlowName: 'أدخل اسم التدفق',
        },
        appTooltip: {
          importFlowForFacebookBot: 'استيراد التدفق للروبوت على Facebook',
          dateAndTimeOfCreationOfChatFlow: 'تاريخ ووقت إنشاء تدفق الدردشة',
          dateAndTimeWhenChatFlowLastModified: 'تاريخ ووقت آخر تعديل في تدفق الدردشة',
          TOOLTIP_CREATE_FLOW: 'انقر لإنشاء تدفق دردشة جديد',
          TOOLTIP_FLOW_NAME: 'أدخل اسم تدفق الدردشة',
          TOOLTIP_FLOW_TABLE_NAME: 'اسم تدفق الدردشة',
          TOOLTIP_NO_OF_MESSAGES: 'عدد عناصر الرسالة في تدفق الدردشة',
          TOOLTIP_DEFAULT_FLOW: 'استخدم هذا التبديل لتعيين تدفق الدردشة كافتراضي، والذي سيتم تقديمه لجميع المستخدمين الجدد.',
          TOOLTIP_ACTION: 'تحرير أو حذف تدفق الدردشة',
        },
        noDataFound: {
          flows: 'تدفقات',
          noFlowsAddedYet: 'لم يتم إضافة أي تدفقات بعد!',
        },
        modal: {
          deleteFlow: 'حذف التدفق',
          wantToDeleteThisFlow: 'هل أنت متأكد أنك تريد حذف هذا التدفق؟',
        }
      },
      widgets: {
        manageWidgets: 'إدارة الودجت',
        widgetsAreRoutingTools: 'الودجت هي أدوات التوجيه لزيادة استخدام الروبوت',
        needHelp: 'بحاجة إلى مساعدة؟',
        wantToMakeChanges: 'ترغب في إجراء تغييرات؟',
        editIcon: 'تحرير الأيقونة',
        discard: 'تجاهل',
        updateWidget: 'تحديث الودجت',
        installChatBotIconOnYourWebsite: 'تثبيت أيقونة الدردشة على موقع الويب الخاص بك',
        shareWhereverYouWantToRedirectUsersTo: 'شارك أينما تريد وأعد توجيه المستخدمين إلى روبوت فيسبوك الخاص بك بنقرة واحدة',
        copyAndAddInYourWebsiteHeaderFile: 'انسخ وأضف إلى ملف رأس موقع الويب الخاص بك',
        copyLinkAndStartSharing: 'انسخ الرابط وابدأ في المشاركة!',
        downloadQrCodeAndStartSharing: 'قم بتنزيل رمز الاستجابة السريعة وابدأ في المشاركة!',
        addCodeAndDeployNewCodeAndDone: 'أضف الكود، نفذ الكود الجديد وانتهى!',
        wantToLearnHowToUseIt: 'ترغب في معرفة كيفية استخدامها؟',
        copyChatBotCode: 'انسخ كود الدردشة',
        copyFacebookLink: 'انسخ رابط فيسبوك',
        downloadQrCode: 'تنزيل رمز الاستجابة السريعة',
        link: 'رابط',
        chatBotIcon: 'أيقونة الدردشة',
        qrcode: 'رمز الاستجابة السريعة',
        small: 'صغير',
        medium: 'متوسط',
        large: 'كبير',
        leftMiddle: 'الوسط الأيسر',
        rightMiddle: 'الوسط الأيمن',
        leftBottom: 'الأسفل الأيسر',
        rightBottom: 'الأسفل الأيمن',
        bpDropDown: {
          selectIcons: 'حدد الأيقونات',
          iconSize: 'حجم الأيقونة',
          iconPlacement: 'توجيه الأيقونة',
        }
      },
      autoReply: {
        autoReplyTitle: 'الرد التلقائي',
        configureYour: 'قم بتكوين ',
        message: ' رسالتك',
        customizeAutoReplyMessagesForYourPostsComments: 'قم بتخصيص رسائل الرد التلقائي لتعليقات منشوراتك',
        wouldYouLikeToApply: 'هل ترغب في تطبيق ',
        autoreply: 'الرد التلقائي',
        onAllPost: ' على جميع المنشورات',
        configureYourMessageForEachPost: 'قم بتكوين رسالتك لكل منشور',
        configureFlowToTriggerAfterOnReply: 'قم بتكوين التدفق للتنشيط بعد الرد',
        whenUserLeavesACommentSendMessageAfter: 'عندما يترك المستخدم تعليقًا، أرسل رسالة بعد',
        triggerFlowOnReply: 'تنشيط التدفق عند الرد',
        add: 'إضافة',
        update: 'تحديث',
        save: 'حفظ',
        cancel: 'إلغاء',
        discard: 'تجاهل',
        bpDropDown: {
          selectPost: 'حدد المنشور',
          triggerFlowOnReply: 'تنشيط التدفق عند الرد',
          immendiately: 'فورًا',
          minutes: 'دقائق'
        },
        bpTable: {
          message: 'الرسالة',
          selectedPost: 'المنشور المحدد',
          triggerFlow: 'تنشيط التدفق',
          actions: 'الإجراءات'
        },
        placeholder: {
          configureYourMessage: 'قم بتكوين رسالتك'
        },
        deleteModel: {
          title: 'حذف الرد التلقائي للمنشور',
          description: 'هل أنت متأكد أنك تريد حذف الرد التلقائي لهذا المنشور؟'
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'انتهاء الجلسة (بالساعات)',
        enterSessionTimeOutWindowInHours: 'أدخل نافذة انتهاء الجلسة بالساعات',
        sendInactivityMessage: 'إرسال رسالة عدم النشاط',
        sessionTimeOutInHoursTooltip: `أدخل المدة (بالساعات) بعد انمجموعة الجلسة الدردشة تلقائيًا
         بسبب عدم النشاط. سيتم إعادة تشغيل تدفق الدردشة إذا قام المستخدم بإرسال أي رسالة بعد انتهاء الجلسة`,
        restartChatFlowTriggers: 'مشغلات إعادة تشغيل تدفق الدردشة',
        enterKeyWordsToRestartChatAndhitEnter: 'أدخل الكلمات الرئيسية لإعادة تشغيل الدردشة واضغط على Enter',
        restartChatFlowTriggersTooltip: `حدد الكلمات الرئيسية هنا التي عند إدخالها من قبل المستخدم وإرسالها، ستقوم بتشغيل إعادة تشغيل تدفق الدردشة. يتيح ذلك للمستخدمين إعادة بدء تدفق الدردشة في أي لحظة.
         اضغط على Enter بعد إضافة كل كلمة رئيسية لإضافة كلمات متعددة.`,
        liveChatTriggers: 'مشغلات الدردشة المباشرة',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'أدخل الكلمات الرئيسية لبدء الدردشة المباشرة واضغط على Enter',
        liveChatTriggersTooltip: `حدد الكلمات الرئيسية التي عند إدخالها من قبل المستخدم وإرسالها، ستبدأ في تحفيز انتقال من الدردشة التلقائية إلى الدردشة المباشرة. يكون ذلك مفيدًا عندما يحتاج المستخدمون إلى المساعدة من وكيل حي.
         اضغط على Enter بعد إضافة كل كلمة رئيسية لإضافة كلمات متعددة.`,
        messageAfterAutomatedFlowCompleted: 'الرسالة بعد اكتمال التدفق التلقائي',
        enterYourMessage: 'أدخل رسالتك',
        messageAfterAutomatedFlowCompletedTooltip: ` أدخل رسالة ستعرض للمستخدم بعد اكتمال تدفق الدردشة التلقائي
            ولكن لم يتم إنهاء الجلسة.`,
        restart: 'إعادة التشغيل',
        liveChat: 'الدردشة المباشرة',
        lineOneForDefaultMessage: 'إذا كان لديك مزيد من الأسئلة أو تحتاج إلى مساعدة، إليك ما يمكنك فعله التالي:',
        to: 'إلى',
        restartTheChat: 'إعادة تشغيل الدردشة',
        simpleTypeRestartAndHitEnter: `قم ببساطة بكتابة 'إعادة التشغيل' واضغط على Enter. سيتم أخذك إلى بداية تدفق محادثتنا مرة أخرى.`,
        lineThreeForDefaultMessagePartOne: `إذا كنت ترغب في التحدث إلى`,
        liveAgent: `وكيل حي،`,
        lineThreeForDefaultMessageLastPart: `اكتب 'الدردشة المباشرة' واضغط على Enter. فريقنا هنا لمساعدتك في الوقت الحقيقي.`,
        saveChanges: 'حفظ التغييرات',
        discard: 'تجاهل',
        configureBotsGeneralSettings: 'قم بتكوين إعدادات الروبوت العامة',
        minutes: 'دقائق',
        hours: 'ساعات',
      }
    },
    instagram: {
      instagramBotSettings: 'إعدادات روبوت Instagram',
      chatFlowsHeader: 'تدفقات الدردشة',
      broadcastHeader: 'بث',
      dripCampaignHeader: 'حملة التنقيط',
      widgetsHeader: 'الودجت',
      alertsHeader: 'التنبيهات',
      alerts: {
        configureYourBotsSettings: 'قم بتكوين إعدادات روبوتك',
        specifyHowYourBotNotifyYou: 'حدد كيف يمكن للروبوت إخطارك بشأن العملاء الجدد.',
        subject: 'الموضوع',
        emailNotification: 'إخطار البريد الإلكتروني',
        emailAddresses: 'عناوين البريد الإلكتروني',
        leadRevisitNotifications: 'إخطارات زيارة العملاء',
        whenToSendLeadInfo: 'متى يجب إرسال معلومات العميل',
        discard: 'تجاهل',
        saveChanges: 'حفظ التغييرات',
        placeholders: {
          enterSubjectForYourEmail: 'أدخل موضوع بريدك الإلكتروني',
          typeYourEmailAddressAndHitEnter: 'اكتب عنوان بريدك الإلكتروني واضغط على Enter',
        }
      },
      broadcasts: {
        createBroadcast: 'إنشاء بث',
        youCanCreateMultipleBroadcasts: 'يمكنك إنشاء عدة بثوات',
        broadcastName: 'اسم البث',
        broadcastNameIsRequired: 'اسم البث مطلوب',
        continue: 'متابعة',
        manageBroadcasts: 'إدارة البثوات',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'يمكنك إنشاء عدة بثوات بقوالب مجدولة متعددة لفئات العملاء المختلفة.',
        createNewBroadcast: 'إنشاء بث جديد',
        segment: 'القسم',
        template: 'القالب',
        contacts: 'جهات الاتصال',
        send: 'إرسال',
        failed: 'فشل',
        broadcastAt: 'جدولة البث في',
        status: 'الحالة',
        actions: 'إجراءات',
        nA: 'غ/م',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          searchHere: 'ابحث هنا',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'حذف عدة بثوات',
          deleteBroadcast: 'حذف البث',
        }
      },
      createBroadcast: {
        broadcastName: 'اسم البث',
        broadcastNameIsRequired: 'اسم البث مطلوب',
        flow: 'سياق',
        segments: 'مجموعات',
        whenToSend: 'متى يتم الإرسال',
        selectDate: 'حدد التاريخ',
        selectTimeSlot: 'حدد الفترة الزمنية',
        cancel: 'إلغاء',
        createBroadcast: 'إنشاء بث',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          selectFlow: 'حدد السياق',
          searchFlowByName: 'البحث بالاسم',
          selectSegments: 'اختر مجموعة',
          searchSegmentByName: 'مجموعة البحث بالاسم',
          selectTimeSlot: 'حدد الفترة الزمنية',
        },
        indexMergeDatePicker: {
          selectStartDate: 'حدد تاريخ البدء',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'إنشاء حملة تحت الجري',
        youCanCreateMultipleCampaigns: 'يمكنك إنشاء عدة حملات',
        dripCampaignName: 'اسم حملة تحت الجري',
        dripCampaignNameIsRequired: 'اسم حملة تحت الجري مطلوب',
        continue: 'متابعة',
        manageDripCampaign: 'إدارة حملة تحت الجري',
        youCanCreateMultipleCampaignsWith: 'يمكنك إنشاء عدة حملات تحت الجري برسائل مجدولة وآلية لتحفيز ردود الفعل من مجموعةات معينة من المستخدمين.',
        createNewDripCampaign: 'إنشاء حملة تحت الجري جديدة',
        campaignName: 'اسم الحملة',
        appliedOn: 'تم تطبيقه على',
        segment: 'مجموعة',
        flow: 'سياق',
        startDate: 'تاريخ البدء',
        action: 'إجراء',
        nA: 'N/A',
        placeholders: {
          enterDripCampaignName: 'أدخل اسم حملة تحت الجري',
          searchByName: 'البحث بالاسم',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'حذف عدة حملات تحت الجري',
          deleteDripCampaign: 'حذف حملة تحت الجري',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'اسم حملة تحت الجري',
          appliedOn: 'تم تطبيقه على',
          segments: 'مجموعات',
          selectDate: 'حدد التاريخ',
          scheduleYourFlows: 'جدولة تياراتك',
          flow: 'سياق',
          sendAfter: 'إرسال بعد',
          cancel: 'إلغاء',
          createDripCampaign: 'إنشاء حملة تحت الجري',
          placeholders: {
            enterDripName: 'أدخل اسم حملة تحت الجري',
            selectAppliedOn: 'حدد تم تطبيقه على',
            selectSegment: 'حدد مجموعة',
            searchByName: 'البحث بالاسم',
            selectTemplate: 'حدد القالب',
            searchFlowByName: 'البحث بالاسم',
            enterSendAfter: 'أدخل إرسال بعد',
          },
          dateTimePicker: {
            selectStartDate: 'حدد تاريخ البدء',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'معرف المحادثة',
        errorCode: 'رمز الخطأ',
        errorMessage: 'رسالة الخطأ',
        nA: 'NA',
      },
      flows: {
        chatFlows: 'تدفقات الدردشة',
        createChatFlow: 'إنشاء تدفق دردشة',
        youCanCreateMultipleChatFlows: 'يمكنك إنشاء العديد من تدفقات الدردشة عبر عدة قنوات لاختبار A/B والبث.',
        flowName: 'اسم التدفق',
        nameIsRequired: 'الاسم مطلوب',
        cancel: 'إلغاء',
        createFlow: 'إنشاء تدفق',
        importFlow: 'استيراد تدفق',
        createNewFlow: 'إنشاء تدفق جديد',
        ofMessages: ' من الرسائل',
        createdOn: 'تم الإنشاء في',
        lastModified: 'آخر تعديل في',
        defaultFlow: 'التدفق الافتراضي',
        action: 'الإجراء',
        exportStatus: 'حالة التصدير',
        flowExport: 'تصدير التدفق',
        download: 'تحميل',
        beforeYouStartUploadPleaseMakeSure: 'قبل أن تبدأ في التحميل، تأكد من',
        point1: '1. الحد الأقصى لحجم الملف المسموح به هو 20 ميجابايت',
        point2: '2. يجب أن يكون تنسيق الملف في صيغة JSON',
        selectFile: 'اختر الملف',
        placeholders: {
          enterFlowName: 'أدخل اسم التدفق',
        },
        appTooltip: {
          importFlowForInstagramBot: 'استيراد تدفق لروبوت Instagram',
          dateAndTimeOfCreationOfChatFlow: 'تاريخ ووقت إنشاء تدفق الدردشة',
          dateAndTimeWhenChatFlowLastModified: 'تاريخ ووقت آخر تعديل في تدفق الدردشة',
        },
        noDataFound: {
          flows: 'تدفقات',
          noFlowsAddedYet: 'لم تتم إضافة تدفقات بعد!',
        },
        modal: {
          deleteFlow: 'حذف التدفق',
          wantToDeleteThisFlow: 'هل أنت متأكد أنك تريد حذف هذا التدفق؟',
        }
      },
      widgets: {
        manageWidgets: 'إدارة الأدوات',
        widgetsAreRoutingTools: 'الأدوات هي أدوات التوجيه لزيادة استخدام الروبوت',
        needHelp: 'بحاجة للمساعدة؟',
        wantToMakeChanges: 'تريد إجراء تغييرات؟',
        editIcon: 'تحرير الأيقونة',
        discard: 'تجاهل',
        updateWidget: 'تحديث الأداة',
        bpDropDown: {
          selectIcons: 'حدد الأيقونات',
          iconSize: 'حجم الأيقونة',
          iconPlacement: 'موقع الأيقونة',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'إعدادات روبوت تيليجرام',
      chatFlowsHeader: 'تدفقات الدردشة',
      telegramProfileHeader: 'ملف تيليجرام',
      widgetsHeader: 'الأدوات',
      broadcastHeader: 'البث',
      dripCampaignHeader: 'حملة التنقيط',
      generalHeader: 'عام',
      telegramAutomation: 'إدارة روبوت تيليجرام',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'إنشاء رحلة استقبال العملاء باستخدام حملة التنقيط التي تحتوي على تدفقات دردشة مجدولة.',
        TOOLTIP_CHAT_FLOW_TITLE: 'تخصيص قوالب تدفق الدردشة وإنشاء تدفقات دردشة متعددة لجماهير مستهدفة مختلفة.',
      },
      broadcasts: {
        createBroadcast: 'إنشاء بث',
        youCanCreateMutlipleBroadcasts: 'يمكنك إنشاء عدة بثوث',
        broadcastName: 'اسم البث',
        broadcastNameIsRequired: 'اسم البث مطلوب',
        continue: 'متابعة',
        manageBroadcasts: 'إدارة البثوث',
        youCanCreateMultipleBroadcastsWith: 'يمكنك إنشاء العديد من البثوث بقوالب مجدولة متعددة لفئات العملاء المختلفة.',
        createNewBroadcast: 'إنشاء بث جديد',
        name: 'الاسم',
        segment: 'مجموعة',
        template: 'القالب',
        contacts: 'الجهات',
        send: 'إرسال',
        failed: 'فشل',
        broadcastAt: 'بث في',
        status: 'الحالة',
        actions: 'الإجراءات',
        nA: 'غ/م',
        deleteBroadcast: 'حذف البث',
        wantToDeleteThisBroadcast: 'هل أنت متأكد أنك تريد حذف هذا البث؟',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          searchHere: 'البحث هنا',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'حذف عدة بثوث',
          deleteBroadcast: 'حذف البث',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'اسم البث',
        broadcastNameIsRequired: 'اسم البث مطلوب',
        flow: 'التدفق',
        segments: 'مجموعات',
        whenToSend: 'متى لإرسال',
        selectDate: 'اختر التاريخ',
        selectTimeSlot: 'اختر الفترة الزمنية',
        cancel: 'إلغاء',
        createBroadcast: 'إنشاء بث',
        now: 'الآن',
        later: 'لاحقًا',
        placeholders: {
          enterBroadcastName: 'أدخل اسم البث',
          selectFlow: 'حدد التدفق',
          searchFlowByName: 'البحث عن طريق اسم التدفق',
          selectSegments: 'اختر مجموعة',
          searchSegmentByName: 'مجموعة البحث بالاسم',
          selectTimeSlot: 'اختر الفترة الزمنية',
        },
        indexMergeDatePicker: {
          selectStartDate: 'حدد تاريخ البدء',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'إنشاء حملة النقاطة',
        youCanCreateMultipleCampaigns: 'يمكنك إنشاء عدة حملات',
        dripCampaignName: 'اسم حملة النقاطة',
        dripCampaignNameIsRequired: 'اسم حملة النقاطة مطلوب',
        continue: 'متابعة',
        manageDripCampaign: 'إدارة حملة النقاطة',
        youCanCreateMultipleDripCampaignsWith: 'يمكنك إنشاء العديد من حملات النقاطة مع رسائل مجدولة وآلية لتحفيز الاستجابات من فئات المستخدمين المحددة.',
        createNewDripCampaign: 'إنشاء حملة نقاطة جديدة',
        campaignName: 'اسم الحملة',
        appliedOn: 'تم تطبيقه على',
        segment: 'مجموعة',
        flow: 'التدفق',
        startDate: 'تاريخ البدء',
        action: 'إجراء',
        nA: 'NA',
        deleteDripCampaign: 'حذف حملات التنقيط',
        wantToDeleteThisDripCampaign: 'هل أنت متأكد أنك تريد حذف حملات النقاطة هذه؟',
        placeholders: {
          enterDripCampaignName: 'أدخل اسم حملة النقاطة',
          searchByName: 'البحث بالاسم',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'حذف العديد من حملات النقاطة',
          deleteDripCampaign: 'حذف حملة النقاطة',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'اسم حملة النقاطة',
          appliedOn: 'تم تطبيقه على',
          segments: 'مجموعات',
          startDate: 'حدد التاريخ',
          scheduleYourFlows: 'جدولة تدفقاتك',
          schedule: 'جدولة',
          flow: 'التدفق',
          sendAfter: 'إرسال بعد',
          cancel: 'إلغاء',
          createDripCampaign: 'إنشاء حملة النقاطة',
          newSubscriber: 'مشترك جديد',
          days: 'أيام',
          minutes: 'دقائق',
          hours: 'ساعات',
          placeholders: {
            enterDripName: 'أدخل اسم حملة النقاطة',
            selectAppliedOn: 'حدد تاريخ التطبيق',
            selectSegment: 'حدد مجموعة',
            searchByName: 'البحث بالاسم',
            selectFlow: 'حدد التدفق',
            searchFlowByName: 'البحث بالاسم',
          },
          dateTimePicker: {
            selectStartDate: 'حدد تاريخ البدء',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'الاسم',
        email: 'البريد الإلكتروني',
        phone: 'الهاتف',
        errorCode: 'كود الخطأ',
        errorMessage: 'رسالة الخطأ',
        nA: 'NA',
      },
      flows: {
        chatFlows: 'تدفقات الدردشة',
        createChatFlow: 'إنشاء تدفق الدردشة',
        youCanCreateMultipleChatFlows: 'يمكنك إنشاء تدفقات دردشة متعددة عبر قنوات متعددة لاختبار A/B والبث',
        flowName: 'اسم التدفق',
        nameIsRequired: 'الاسم مطلوب',
        cancel: 'إلغاء',
        createFlow: 'إنشاء التدفق',
        importFlow: 'استيراد التدفق',
        createNewFlow: 'إنشاء تدفق جديد',
        ofMessages: ' من الرسائل',
        createdOn: 'تم الإنشاء في',
        lastModified: 'تم التعديل في',
        defaultFlow: 'التدفق الافتراضي',
        action: 'الإجراء',
        exportStatus: 'حالة التصدير',
        flowExport: 'تصدير التدفق',
        inProgress: 'قيد التقدم',
        available: 'متاح',
        initiated: 'تم البدء',
        download: 'تحميل',
        beforeYouStartUploadPleaseMakeSure: 'قبل أن تبدأ في الرفع ، يُرجى التأكد من ما يلي',
        point1: '1. الحد الأقصى لحجم الملف المسموح به للرفع هو 20 ميجا بايت',
        point2: '2. يجب أن يكون تنسيق الملف في تنسيق JSON',
        selectFile: 'حدد الملف',
        placeholders: {
          enterFlowName: 'أدخل اسم التدفق',
        },
        appTooltip: {
          importFlowForTelegramBot: 'استيراد التدفق لروبوت إنستغرام',
          dateAndTimeOfCreationOfChatFlow: 'تاريخ ووقت إنشاء تدفق الدردشة',
          dateAndTimeWhenChatFlowLastModified: 'تاريخ ووقت آخر تعديل لتدفق الدردشة',
          TOOLTIP_CREATE_FLOW: 'انقر لإنشاء تدفق دردشة جديد',
          TOOLTIP_FLOW_NAME: 'أدخل اسم تدفق الدردشة',
          TOOLTIP_FLOW_TABLE_NAME: 'اسم تدفق الدردشة',
          TOOLTIP_NO_OF_MESSAGES: 'عدد مكونات الرسالة في تدفق الدردشة',
          TOOLTIP_DEFAULT_FLOW: 'استخدم هذا التبديل لتعيين تدفق الدردشة كافتراضي ، والذي سيتم تقديمه لجميع المستخدمين الجدد.',
          TOOLTIP_ACTION: 'تحرير أو حذف تدفق الدردشة',
        },
        noDataFound: {
          flows: 'تدفقات',
          noFlowsAddedYet: 'لم تتم إضافة أي تدفقات بعد!',
        },
        modal: {
          deleteFlow: 'حذف التدفق',
          wantToDeleteThisFlow: 'هل أنت متأكد أنك تريد حذف هذا التدفق؟',
        }
      },
      telegramProfile: {
        telegramProfile: 'الملف الشخصي للتيليجرام',
        manageYourBotProfile: 'قم بإدارة ملف تعريف الروبوت الخاص بك في أي وقت تريده.',
        allSetNoActionRequired: 'كل شيء جاهز، لا تتطلب أي إجراء!',
        congratulationYourAccountIsLive: 'تهانينا! حسابك نشط بالكامل مع وصول كامل',
        botDetails: 'تفاصيل الروبوت',
        name: 'الاسم',
        nA: 'غ/م',
        b: 'ر',
        botName: 'اسم الروبوت',
        bot: 'روبوت',
        info: 'معلومات',
        none: 'لا شيء',
        username: 'اسم المستخدم',
        notifications: 'الإشعارات',
        on: 'مفعل',
        addToGroupOrChannel: 'إضافة إلى المجموعة أو القناة',
        userName: 'اسم المستخدم',
        botToken: 'رمز الروبوت',
      },
      widgets: {
        manageWidgets: 'إدارة الودجت',
        widgetsAreRoutingTools: 'الودجت هي أدوات توجيه لزيادة استخدام الروبوت',
        needHelp: 'بحاجة إلى مساعدة؟',
        wantToMakeChanges: 'تريد إجراء تغييرات؟',
        editIcon: 'تعديل الأيقونة',
        discard: 'تجاهل',
        updateWidget: 'تحديث الودجت',
        installChatBotIconOnYourWebsite: 'قم بتثبيت أيقونة الروبوت على موقع الويب الخاص بك',
        shareWhereEverYouWantRedirectUsersTo: 'شارك أينما تريد وأعد توجيه المستخدمين إلى روبوت التيليجرام الخاص بك بنقرة واحدة',
        copyAndAddInYourWebsiteHeaderFile: 'انسخ وأضف إلى ملف رأس موقع الويب الخاص بك',
        copyLinkAndStartSharing: 'انسخ الرابط وابدأ المشاركة!',
        downloadQrCodeAndStartSharing: 'قم بتنزيل رمز الاستجابة السريعة وابدأ المشاركة!',
        addCodeAndDeployNewCodeAndDone: 'أضف الكود، نشر الكود الجديد وانتهى!',
        wantToLearnHowToUseIt: 'تريد أن تتعلم كيفية استخدامه؟',
        copyChatBotCode: 'نسخ كود الروبوت',
        downloadQrCode: 'تحميل رمز الاستجابة السريعة',
        copyTelegramLink: 'نسخ رابط التيليجرام',
        link: 'رابط',
        chatBotIcon: 'أيقونة الروبوت',
        qrcode: 'رمز الاستجابة السريعة',
        small: 'صغير',
        medium: 'متوسط',
        large: 'كبير',
        leftMiddle: 'منتصف اليسار',
        rightMiddle: 'منتصف اليمين',
        leftBottom: 'أسفل اليسار',
        rightBottom: 'أسفل اليمين',
        bpDropDown: {
          selectIcons: 'اختر الأيقونات',
          iconSize: 'حجم الأيقونة',
          iconPlacement: 'مكان الأيقونة',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'انتهاء الجلسة (بالساعات)',
        enterSessionTimeOutWindowInHours: 'أدخل نافذة انتهاء الجلسة بالساعات',
        sendInactivityMessage: 'إرسال رسالة عدم النشاط',
        sessionTimeOutInHoursTooltip: `أدخل المدة (بالساعات) بعد انمجموعة الجلسة الدردشة تلقائيًا
         بسبب عدم النشاط. سيتم إعادة تشغيل تدفق الدردشة إذا قام المستخدم بإرسال أي رسالة بعد انتهاء الجلسة`,
        restartChatFlowTriggers: 'مشغلات إعادة تشغيل تدفق الدردشة',
        enterKeyWordsToRestartChatAndhitEnter: 'أدخل الكلمات الرئيسية لإعادة تشغيل الدردشة واضغط على Enter',
        restartChatFlowTriggersTooltip: `حدد الكلمات الرئيسية هنا التي عند إدخالها من قبل المستخدم وإرسالها، ستقوم بتشغيل إعادة تشغيل تدفق الدردشة. يتيح ذلك للمستخدمين إعادة بدء تدفق الدردشة في أي لحظة.
         اضغط على Enter بعد إضافة كل كلمة رئيسية لإضافة كلمات متعددة.`,
        liveChatTriggers: 'مشغلات الدردشة المباشرة',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'أدخل الكلمات الرئيسية لبدء الدردشة المباشرة واضغط على Enter',
        liveChatTriggersTooltip: `حدد الكلمات الرئيسية التي عند إدخالها من قبل المستخدم وإرسالها، ستبدأ في تحفيز انتقال من الدردشة التلقائية إلى الدردشة المباشرة. يكون ذلك مفيدًا عندما يحتاج المستخدمون إلى المساعدة من وكيل حي.
         اضغط على Enter بعد إضافة كل كلمة رئيسية لإضافة كلمات متعددة.`,
        messageAfterAutomatedFlowCompleted: 'الرسالة بعد اكتمال التدفق التلقائي',
        enterYourMessage: 'أدخل رسالتك',
        messageAfterAutomatedFlowCompletedTooltip: ` أدخل رسالة ستعرض للمستخدم بعد اكتمال تدفق الدردشة التلقائي
            ولكن لم يتم إنهاء الجلسة.`,
        restart: 'إعادة التشغيل',
        liveChat: 'الدردشة المباشرة',
        lineOneForDefaultMessage: 'إذا كان لديك مزيد من الأسئلة أو تحتاج إلى مساعدة، إليك ما يمكنك فعله التالي:',
        to: 'إلى',
        restartTheChat: 'إعادة تشغيل الدردشة',
        simpleTypeRestartAndHitEnter: `قم ببساطة بكتابة 'إعادة التشغيل' واضغط على Enter. سيتم أخذك إلى بداية تدفق محادثتنا مرة أخرى.`,
        lineThreeForDefaultMessagePartOne: `إذا كنت ترغب في التحدث إلى`,
        liveAgent: `وكيل حي،`,
        lineThreeForDefaultMessageLastPart: `اكتب 'الدردشة المباشرة' واضغط على Enter. فريقنا هنا لمساعدتك في الوقت الحقيقي.`,
        saveChanges: 'حفظ التغييرات',
        discard: 'تجاهل',
        configureBotsGeneralSettings: 'قم بتكوين إعدادات الروبوت العامة',
        minutes: 'دقائق',
        hours: 'ساعات',
      }
    },
    whatsapp: {
      whatsappAutomation: 'الأتمتة على واتساب',
      whatsappBotSettings: 'إعدادات روبوت واتساب',
      chatFlowsHeader: 'تدفقات الدردشة',
      generalHeader: 'عام',
      whatsappProfileHeader: 'ملف واتساب',
      broadcastHeader: 'بث',
      dripCampaignHeader: 'حملة تنقيط',
      widgetHeader: 'ويدجت',
      templatesHeader: 'القوالب',
      alertsHeader: 'تنبيهات',
      apiKeyHeader: 'مفتاح API',
      catalogHeader: 'الكتالوج',
      otherConfiguration: 'إعدادات الذكاء الاصطناعي',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'قم بتخصيص قوالب تدفق الدردشة وإنشاء تدفقات دردشة متعددة لجماهير مستهدفة مختلفة.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'قم بتكوين إعدادات تنبيهات الروبوت الخاص بك',
        specifyHowYouBotWillNotify: 'حدد كيفية إخطار الروبوت بك عن العملاء الجدد.',
        subject: 'الموضوع',
        emailNotification: 'تنبيه البريد الإلكتروني',
        emailAddresses: 'عناوين البريد الإلكتروني',
        leadRevisitNotifications: 'تنبيهات إعادة زيارة العملاء',
        whenToSendLeadInfo: 'متى إرسال معلومات العملاء',
        discard: 'تجاهل',
        saveChanges: 'حفظ التغييرات',
        seeHowToUse: 'انظر كيفية الاستخدام',
        beginningTheFlow: 'بداية التدفق',
        fiveMinutesLater: 'بعد 5 دقائق',
        endOfFlow: 'نهاية التدفق',
        placeholders: {
          enterSubjectForYourEmail: 'أدخل موضوع البريد الإلكتروني الخاص بك',
          typeEmailAndHitEnter: 'اكتب عنوان البريد الإلكتروني الخاص بك واضغط على Enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'قم بتخصيص موضوع تنبيهات البريد الإلكتروني الخاصة بالروبوت الخاص بك',
          TOOLTIP_EMAIL_NOTIFICATION: 'تمكين/تعطيل إعلام الروبوت بالبريد الإلكتروني عند وجود عملاء جدد',
          TOOLTIP_EMAIL_ADDRESS: 'أدخل عنوان البريد الإلكتروني الذي ترغب في تلقي إشعارات البريد الإلكتروني عليه. يمكنك إضافة عناوين البريد الإلكتروني المتعددة عن طريق إدخال واحد والضغط على Enter لإضافة آخر.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'تمكين/تعطيل إعلام الروبوت بالبريد الإلكتروني إذا تفاعل مستخدم تم التقاطه مسبقًا كعميل مع الروبوت مرة أخرى.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'تحديث روبوت واتساب',
        businessNameIsRequired: 'اسم العمل مطلوب.',
        maxLengthForBusinessName40Characters: 'الحد الأقصى لاسم العمل هو 40 حرفًا.',
        categoryIsRequired: 'الفئة مطلوبة.',
        descriptionIsRequired: 'الوصف مطلوب.',
        maxLengthForDescription200Characters: 'الحد الأقصى للوصف هو 200 حرف.',
        aboutIsRequired: 'حول مطلوب.',
        maxLengthForAbout139Characters: 'الحد الأقصى لـ "حول" هو 139 حرفًا.',
        pleaseFillAValidBusinessEmail: 'يرجى ملء بريد إلكتروني للعمل صالح.',
        businessEmailIsRequired: 'البريد الإلكتروني للعمل مطلوب.',
        websiteIsRequired: 'الموقع الإلكتروني مطلوب.',
        maxLengthForWebsite40Characters: 'الحد الأقصى للموقع الإلكتروني هو 40 حرفًا.',
        maxLengthForAddress150Characters: 'الحد الأقصى للعنوان هو 150 حرفًا.',
        cancel: 'إلغاء',
        update: 'تحديث',
        businessCategory: 'فئة العمل',
        placeholders: {
          yourBusinessName: 'اسم عملك',
          businessDescription: 'وصف العمل',
          about: 'حول',
          businessEmail: 'بريد العمل الإلكتروني',
          website: 'الموقع الإلكتروني (على سبيل المثال، www.example.com)',
          address: 'العنوان',
        }
      },
      flows: {
        chatFlows: 'تدفقات المحادثة',
        createChatFlow: 'إنشاء تدفق محادثة',
        youCanCreateMultipleChatFlows: 'يمكنك إنشاء العديد من تدفقات المحادثة عبر عدة قنوات لاختبار A/B والبث.',
        flowName: 'اسم التدفق',
        nameIsRequired: 'الاسم مطلوب',
        cancel: 'إلغاء',
        createFlow: 'إنشاء تدفق',
        importFlow: 'استيراد تدفق',
        createNewFlow: 'إنشاء تدفق جديد',
        ofMessages: ' من الرسائل',
        createdOn: 'تم إنشاؤه في',
        lastModified: 'آخر تعديل في',
        defaultFlow: 'التدفق الافتراضي',
        revisitFlow: 'التدفق عند العودة',
        action: 'الإجراء',
        exportStatus: 'حالة التصدير',
        flowExport: 'تصدير التدفق',
        beforeYouStartUploadPleaseMakeSure: 'قبل أن تبدأ في التحميل، يرجى التأكد من',
        point1: '1. الحد الأقصى لحجم الملف المسموح به هو 20 ميجابايت',
        point2: '2. يجب أن يكون تنسيق الملف بصيغة JSON',
        selectFile: 'اختر الملف',
        seeHowToUse: 'راجع كيفية الاستخدام',
        download: 'تحميل',
        available: 'متاح',
        initiated: 'تم البدء',
        inProgress: 'قيد التنفيذ',
        placeholders: {
          enterFlowName: 'أدخل اسم التدفق',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'استيراد تدفق لربوت واتساب',
          dateAndTimeOfCreationOfChatFlow: 'تاريخ ووقت إنشاء تدفق المحادثة',
          dateAndTimeWhenChatFlowLastModified: 'تاريخ ووقت آخر تعديل على تدفق المحادثة',
          TOOLTIP_CREATE_FLOW: 'انقر لإنشاء تدفق محادثة جديد',
          TOOLTIP_FLOW_NAME: 'أدخل اسم تدفق المحادثة',
          TOOLTIP_FLOW_TABLE_NAME: 'اسم تدفق المحادثة',
          TOOLTIP_NO_OF_MESSAGES: 'عدد عناصر الرسالة في تدفق المحادثة',
          TOOLTIP_DEFAULT_FLOW: 'استخدم هذا الزر لتعيين تدفق المحادثة كتدفق افتراضي سيُقدم لجميع المستخدمين الجدد.',
          TOOLTIP_REVISIT_FLOW: 'استخدم هذا الزر لتحديد تدفق المحادثة الذي سيُقدم للمستخدمين عندما يعودون إلى روبوت المحادثة الخاص بك.',
          TOOLTIP_ACTION: 'تعديل أو حذف تدفق المحادثة',
        },
        modal: {
          deleteFlow: 'حذف التدفق',
          wantToDeleteThisFlow: 'هل أنت متأكد أنك تريد حذف هذا التدفق؟',
        },
        noDataFound: {
          flows: 'التدفقات',
          noFlowsAddedYet: 'لم يتم إضافة تدفقات بعد!',
        }
      },
      input: {
        manageTemplates: 'إدارة القوالب',
        manageYourTemplatesForMarketingAutomation: 'إدارة القوالب الخاصة بالتسويق الآلي',
        syncTemplates: 'مزامنة القوالب',
        createTemplateHeader: 'إنشاء قالب',
        title: 'العنوان',
        info: 'المعلومات',
        category: 'الفئة',
        status: 'الحالة',
        action: 'الإجراء',
        shipmentUpdate: 'تحديث الشحنة',
        name: 'الاسم',
        headerOptional: 'العنوان (اختياري)',
        bodyRequired: 'النص (مطلوب)',
        footerOptional: 'التذييل (اختياري)',
        buttonsOptional: 'الأزرار (اختياري)',
        makeUpto3Buttons: 'يمكنك إنشاء ما يصل إلى 3 أزرار',
        makeUpto2ButtonsToRedirect: 'يمكنك إنشاء ما يصل إلى 2 زرًا لتوجيه العميل إلى موقع الويب الخاص بك',
        placeholders: {
          nameYourTemplate: 'اسم القالب الخاص بك (استخدم الشرطة السفلية (_) لفصل الكلمات)',
          enterText: 'أدخل النص (بحد أقصى 60 حرفًا)',
          enterImageUrl: 'أدخل عنوان صورة الويب',
          enterVideoUrl: 'أدخل عنوان الفيديو',
          templateMessage: 'رسالة القالب',
          smallFooterAtBottom: 'تذييل صغير في الأسفل',
          buttons: 'الأزرار',
          text: 'النص',
          numberWithCountryCode: 'الرقم مع الرمز الدولي',
          enterButtonText: 'أدخل نص الزر (بحد أقصى 20 حرفًا)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'عنوان القالب على واتساب',
          TOOLTIP_INPUT_TABLE_INFO: 'معلومات مفصلة حول رسائل المؤسسة التي تم إطلاقها',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'نوع رسائل المؤسسة مثل المواعيد، التذكيرات، رسائل خدمة العملاء، تحديثات الشحن، التنبيهات وأكثر من ذلك',
          TOOLTIP_INPUT_TABLE_STATUS: 'حالة القالب هي مرفوضة أو موافق عليها من قبل واتساب. يجب أن تكون القوالب على واتساب موافق عليها أولاً وهذه خدمة مدفوعة.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'حذف القالب',
        },
        noDataFound: {
          templates: 'القوالب',
          noTemplatesCreatedYet: 'لم يتم إنشاء أي قوالب بعد!',
        },
        modal: {
          deleteTemplate: 'حذف القالب',
          wantToDeleteThisTemplate: 'هل أنت متأكد أنك تريد حذف هذا القالب؟',
        },
        createTemplate: {
          createTemplates: 'إنشاء القوالب',
          createTemplatesForMarketingAutomation: 'أنشئ القوالب الخاصة بالتسويق الآلي',
          templateMeta: 'بيانات القالب',
          selectLanguage: 'اختر اللغة',
          header: 'العنوان',
          optional: 'اختياري',
          samplesForHeaderContent: 'أمثلة على محتوى العنوان',
          toHelpMetaReviewYourContent: 'لمساعدة Meta في مراجعة محتواك، قدم أمثلة على المتغيرات أو الوسائط في العنوان. لا تتضمن أي معلومات عميل.',
          body: 'النص',
          addVariable: 'إضافة متغير',
          samplesForBodyContent: 'أمثلة على محتوى النص',
          toHelpUsReviewYourContent: 'لمساعدتنا في مراجعة محتواك، قدم أمثلة على المتغيرات في النص. لا تتضمن أي معلومات عميل.',
          footer: 'التذييل',
          buttons: 'الأزرار',
          typeOfAction: 'نوع الإجراء',
          call: 'اتصال',
          phone: 'الهاتف',
          website: 'الموقع الإلكتروني',
          static: 'ثابت',
          urlType: 'نوع الرابط',
          type: 'النوع',
          customButton: 'زر مخصص',
          addAnotherButton: 'إضافة زر آخر',
          preview: 'معاينة',
          test: 'اختبار',
          create: 'إنشاء',
          addSampleUrl: 'إضافة رابط عينة',
          toHelpMetaReviewYourMessage: 'لمساعدة Meta في مراجعة قالب الرسالة الخاص بك، يرجى إضافة مثال على رابط موقع الويب. لا تستخدم معلومات عميل حقيقية.',
          placeholders: {
            enterMessageTemplateName: 'أدخل اسم قالب الرسالة',
            searchTemplateByName: 'البحث عن القالب بالاسم',
            selectTemplate: 'اختر القالب',
            enterText: 'أدخل النص',
            enterBody: 'أدخل النص',
            enterContentForVariable: 'أدخل المحتوى للمتغير',
            enterFooter: 'أدخل التذييل',
            phone: 'الهاتف*',
            enterWebsiteUrl: 'أدخل عنوان موقع الويب',
          },
          bpInput: {
            name: 'الاسم',
            buttonText: 'نص الزر',
            websiteUrl: 'عنوان موقع الويب',
          },
          bpDropDown: {
            selectCategory: 'اختر الفئة',
          }
        },
        templatePreview: {
          preview: 'معاينة',
          test: 'اختبار',
        },
        templateConfig: {
          saveChanges: 'حفظ التغييرات',
          discard: 'تجاهل',
          triggerChatFlowOnTemplateReply: 'تنشيط تدفق الدردشة عند الرد على القالب',
          templateDetails: 'تفاصيل القالب',
          configureFlows: 'تكوين التدفقات',
          selectFlow: 'حدد التدفق',
          name: 'الاسم'
        }
      },
      shopManagement: {
        shopManagement: 'Shop Management',
        manageECommerceShopFromBotPenguin: 'Manage e-commerce shops connected with BotPenguin',
        comingSoon: '( Coming Soon )',
        wooCommerce: {
          createConfiguration: 'Create Configuration',
          eventConfiguration: {
            createConfiguration: 'Create Configuration',
            templateHeader: 'Template Header',
            selectFile: 'Select File',
            templateMessage: 'Template Message',
            saveChanges: 'Save Changes',
            bpDropDown: {
              selectEvent: 'Select Event',
              selectTemplate: 'Select Template',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'الملف الشخصي لواتساب',
        manageYourBotProfile: 'ادارة ملف تعريف الروبوت الخاص بك في أي وقت تريد.',
        allSetNoActionRequired: 'كل شيء جاهز، لا يتطلب أي إجراء!',
        congratulationsYourAccountIsLive: 'تهانينا! حسابك نشط بالكامل',
        somethingIsWrongReactivateYourBot: 'يبدو أن هناك خطأ، أعد تنشيط الروبوت الخاص بك!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'يبدو أن الروبوت الخاص بك لا يعمل بشكل صحيح، يرجى إعادة تعيين الروبوت الخاص بك',
        reConfigureYourBot: 'إعادة تكوين الروبوت الخاص بك',
        phoneNumberDetails: 'تفاصيل رقم الهاتف',
        phoneNumber: 'رقم الهاتف',
        nA: 'غير متوفر',
        phoneNumberId: 'معرف رقم الهاتف',
        wabaId: 'معرف WABA',
        change: 'تغيير',
        accessToken: 'رمز الوصول',
        messagingLimit: 'حد الرسائل',
        qualityRating: 'تقييم الجودة',
        whatsappBusinessAccountName: 'اسم حساب واتساب الأعمال',
        whatsappPublicProfile: 'الملف العام لواتساب',
        profilePicture: 'صورة الملف الشخصي',
        aboutText: 'نص حول',
        address: 'العنوان',
        description: 'الوصف',
        email: 'البريد الإلكتروني',
        websitePrimary: 'الموقع الإلكتروني (الأساسي)',
        websiteSecondary: 'الموقع الإلكتروني (الثانوي)',
        businessVertical: 'مجموعة التجاري',
        editProfile: 'تحرير الملف الشخصي',
        thisIsABusinessAccount: 'هذا حساب تجاري',
        aboutAndPhoneNumber: 'نبذة ورقم الهاتف',
        yourTrialAccountIsLive: 'تهانينا! حساب التجربة الخاص بك نشط بالكامل على رقمك المتحقق',
        updateWithNewAccessToken: 'التحديث باستخدام رمز الوصول الجديد',
      },
      widgets: {
        manageWidgets: 'إدارة الودجت',
        widgetsAreRoutingTools: 'الودجت هي أدوات توجيه لزيادة استخدام البوت',
        needHelp: 'هل تحتاج إلى مساعدة؟',
        wantToMakeChanges: 'هل ترغب في إجراء تغييرات؟',
        prePopulatedMessage: 'رسالة معبأة مسبقًا',
        discard: 'تجاهل',
        updateWidget: 'تحديث الودجت',
        hiThere: 'مرحبًا هنا ',
        seeHowToUse: 'انظر كيفية الاستخدام',
        editIcon: 'تحرير الأيقونة',
        editPrePopulatedMessage: 'تحرير الرسالة المعبأة مسبقًا',
        small: 'صغير',
        medium: 'متوسط',
        large: 'كبير',
        leftMiddle: 'الأوسط الأيسر',
        rightMiddle: 'الأوسط الأيمن',
        leftBottom: 'الأسفل الأيسر',
        rightBottom: 'الأسفل الأيمن',
        chatBotIcon: 'أيقونة البوت',
        link: 'رابط',
        qrcode: 'رمز الاستجابة السريعة (QR)',
        copyChatBotCode: 'نسخ كود البوت',
        copyWALink: 'نسخ رابط WhatsApp',
        downloadQrCode: 'تحميل رمز الاستجابة السريعة (QR)',
        installChatBotIconOnYourWebsite: 'تثبيت أيقونة البوت على موقع الويب الخاص بك',
        shareWhereEverYouWantRedirectUsersTo: 'مشاركتها أينما تشاء وإعادة توجيه المستخدمين إلى بوت WhatsApp الخاص بك بنقرة واحدة',
        copyAndAddInYourWebsiteHeaderFile: 'نسخ وإضافة في ملف رأس موقع الويب الخاص بك',
        copyLinkAndStartSharing: 'نسخ الرابط وبدء المشاركة!',
        downloadQrCodeAndStartSharing: 'تحميل رمز الاستجابة السريعة (QR) وبدء المشاركة!',
        addCodeAndDeployNewCodeAndDone: 'أضف الكود، نفذ الكود الجديد واكتمل!',
        wantToLearnHowToUseIt: 'تريد أن تتعلم كيفية استخدامها؟',
        placeholders: {
          enterWhatsappNumber: 'أدخل رقم WhatsApp',
          updateYourMessageHere: 'قم بتحديث رسالتك هنا...',
        },
        bpDropDown: {
          selectIcons: 'اختر الأيقونات',
          iconSize: 'حجم الأيقونة',
          iconPlacement: 'موقع الأيقونة',
        },
        bpInput: {
          whatsappNumber: 'رقم WhatsApp',
        }
      },
      catalog: {
        createCatalog: 'إنشاء كتالوج',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'إنشاء كتالوج المنتجات على مدير التجارة الميتا أو ربطه بشوبيفي',
        useYourMetaCommerceManagertoManageCatalogs: 'استخدم مدير التجارة الميتا الخاص بك لإدارة الكتالوجات',
        connectCatalogToWhatsAppAccount: 'ربط الكتالوج بحساب WhatsApp',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'ربط الكتالوجات بحساب WhatsApp باستخدام مدير WhatsApp الخاص بك',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'استخدم مدير WhatsApp الميتا الخاص بك لربط الكتالوجات واستخدمها مع BotPenguin',
        goToMeta: 'انتقل إلى الميتا',
        goToShopify: 'انتقل إلى Shopify',
        next: 'التالي',
        back: 'السابق',
        setupCatalog: 'إعداد الكتالوج',
        completeAllTheStepsToEnableCatalogMessages: 'أكمل جميع الخطوات لتمكين رسائل الكتالوج'
      },
      generalSetting: {
        sessionTimeOutInHours: 'انتهاء الجلسة (بالساعات)',
        enterSessionTimeOutWindowInHours: 'أدخل نافذة انتهاء الجلسة بالساعات',
        sendInactivityMessage: 'إرسال رسالة عدم النشاط',
        sessionTimeOutInHoursTooltip: `أدخل المدة (بالساعات) بعد انمجموعة الجلسة الدردشة تلقائيًا
         بسبب عدم النشاط. سيتم إعادة تشغيل تدفق الدردشة إذا قام المستخدم بإرسال أي رسالة بعد انتهاء الجلسة`,
        restartChatFlowTriggers: 'مشغلات إعادة تشغيل تدفق الدردشة',
        enterKeyWordsToRestartChatAndhitEnter: 'أدخل الكلمات الرئيسية لإعادة تشغيل الدردشة واضغط على Enter',
        restartChatFlowTriggersTooltip: `حدد الكلمات الرئيسية هنا التي عند إدخالها من قبل المستخدم وإرسالها، ستقوم بتشغيل إعادة تشغيل تدفق الدردشة. يتيح ذلك للمستخدمين إعادة بدء تدفق الدردشة في أي لحظة.
         اضغط على Enter بعد إضافة كل كلمة رئيسية لإضافة كلمات متعددة.`,
        liveChatTriggers: 'مشغلات الدردشة المباشرة',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'أدخل الكلمات الرئيسية لبدء الدردشة المباشرة واضغط على Enter',
        liveChatTriggersTooltip: `حدد الكلمات الرئيسية التي عند إدخالها من قبل المستخدم وإرسالها، ستبدأ في تحفيز انتقال من الدردشة التلقائية إلى الدردشة المباشرة. يكون ذلك مفيدًا عندما يحتاج المستخدمون إلى المساعدة من وكيل حي.
         اضغط على Enter بعد إضافة كل كلمة رئيسية لإضافة كلمات متعددة.`,
        messageAfterAutomatedFlowCompleted: 'الرسالة بعد اكتمال التدفق التلقائي',
        enterYourMessage: 'أدخل رسالتك',
        messageAfterAutomatedFlowCompletedTooltip: ` أدخل رسالة ستعرض للمستخدم بعد اكتمال تدفق الدردشة التلقائي
            ولكن لم يتم إنهاء الجلسة.`,
        restart: 'إعادة التشغيل',
        liveChat: 'الدردشة المباشرة',
        lineOneForDefaultMessage: 'إذا كان لديك مزيد من الأسئلة أو تحتاج إلى مساعدة، إليك ما يمكنك فعله التالي:',
        to: 'إلى',
        restartTheChat: 'إعادة تشغيل الدردشة',
        simpleTypeRestartAndHitEnter: `قم ببساطة بكتابة 'إعادة التشغيل' واضغط على Enter. سيتم أخذك إلى بداية تدفق محادثتنا مرة أخرى.`,
        lineThreeForDefaultMessagePartOne: `إذا كنت ترغب في التحدث إلى`,
        liveAgent: `وكيل حي،`,
        lineThreeForDefaultMessageLastPart: `اكتب 'الدردشة المباشرة' واضغط على Enter. فريقنا هنا لمساعدتك في الوقت الحقيقي.`,
        saveChanges: 'حفظ التغييرات',
        discard: 'تجاهل',
        configureBotsGeneralSettings: 'قم بتكوين إعدادات الروبوت العامة',
        minutes: 'دقائق',
        hours: 'ساعات',
      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'على بعد خطوة واحدة فقط',
      seeHowToUse: 'انظر كيفية الاستخدام',
      testWhatsAppTrialBot: 'اختبار WhatsApp Chatbot الخاص بك',
      sendMessage: 'إرسال رسالة',
      sendWhatsAppMessageOn: 'أرسل لنا رسالة WhatsApp على ',
      bySavingInYourContact: 'عن طريق حفظها في جهات اتصالك ويجب أن تبدأ الرسالة بـ ',
      connect: 'اتصال',
      or: 'أو',
      scanQRCode: 'مسح رمز الاستجابة السريعة (QR Code)',
      yourPhoneWillOpen: 'سيتم فتح هاتفك لإرسال رسالة مملوءة مسبقًا إلى رقم الساندبوكس لدينا. انقر على "إرسال" على WhatsApp',
      sendVerificationCode: 'إرسال رمز التحقق',
      copyAndEnterCode: 'انسخ وأدخل الرمز المكون من 6 أرقام',
      shareWhereYouWant: 'شاركها أينما تشاء وقم بتوجيه المستخدمين إلى بوت WhatsApp الخاص بك بنقرة واحدة',
      youShouldHaveReceived: 'يجب أن تكون قد استلمت رمز التحقق المكون من 6 أرقام على هاتفك',
      EnterYourPhoneNumber: 'أدخل رقم هاتفك',
      send: 'إرسال',
      sixDigitCode: 'أدخل الرمز المكون من 6 أرقام',
      pleaseNote: 'يرجى ملاحظة: قد يستغرق رمز التحقق المكون من 6 أرقام ما يصل إلى 3 دقائق، يرجى الانتظار قبل إعادة إرساله.',
      resendVerificationCode: 'إعادة إرسال رمز التحقق',
      verify: 'التحقق',
      providePhoneNumber: 'يرجى تقديم الرقم الذي ترغب في استخدامه للاختبار',
      demoBotisReady: 'رائع! بوت العرض التوضيحي الخاص بك جاهز للاستخدام',
      placeHolder: {
        phone: 'أدخل رقم هاتفك',
        code: 'أدخل رمز التحقق',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'تكامل التطبيقات من الطرف الثالث',
    thirdPartyApps: 'تطبيقات من الطرف الثالث',
    customApps: 'تطبيقات مخصصة',
    preview: 'معاينة',
    test: 'اختبار',
    install: 'تثبيت',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'انقر لتثبيت البوت على المنصة المحددة',
      TOOLTIP_MY_INTEGRATION_TITLE: 'قم بتكامل تطبيقاتك المخصصة مع بوتك',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'قم بربط بوتك مع تطبيقات / أنظمة إدارة العلاقات مع العملاء (CRM) من الطرف الثالث المتاحة',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'تكامل تطبيقات الجهات الخارجية',
        integrateYourBotWithListedCrms: 'قم بتكامل بوتك مع أحد التطبيقات / أنظمة إدارة العلاقات المذكورة.',
        help: 'المساعدة',
        name: 'الاسم',
        status: 'الحالة',
        action: 'الإجراء',
        chatGpt: 'الدردشة مع GPT',
        addOn: 'إضافة',
        purchased: 'تم الشراء',
        readyToRideGptWave: 'هل أنت مستعد لمواجهة موجة GPT؟ قم بالتكامل الآن.',
        purchase: 'شراء',
        notConnected: 'غير متصل',
        integrated: 'تم التكامل',
        configure: 'تكوين',
        connected: 'متصل',
        edit: 'تحرير',
        connect: 'توصيل',
        requestYourIntegration: 'اطلب التكامل الخاص بك',
        toRequestNewIntegration: 'لطلب تكامل جديد، يُرجى إدخال التفاصيل المذكورة أدناه، ثم سنعاود الاتصال بك.',
        emailId: 'عنوان البريد الإلكتروني',
        integrationName: 'اسم التكامل',
        contactNumber: 'رقم الاتصال',
        howSoonYouWantToAddThisIntegration: 'كم من الوقت ترغب في إضافة هذا التكامل',
        sendRequest: 'إرسال الطلب',
        cancel: 'إلغاء',
        requestSent: 'تم إرسال الطلب!',
        yourRequestSuccessfullySent: 'تم إرسال طلب التكامل الجديد بنجاح.',
        goBack: 'العودة',
        disconnect: 'قطع الاتصال',
        OopsIntegrationAreNotAvailable: 'عذرًا! التكاملات غير متاحة في ',
        pleaseUpgradeTo: ' خطة. يرجى الترقية إلى ',
        planForIntegration: ' خطة للتكاملات.',
        placeholders: {
          searchIntegrationByName: 'ابحث عن التكامل بالاسم',
          enterTeamMemberName: 'أدخل اسم عضو الفريق',
          enterEmailId: 'أدخل عنوان البريد الإلكتروني',
          enterIntegrationName: 'أدخل اسم التكامل',
          searchCountry: 'ابحث عن البلد',
          enterContactNumber: 'أدخل رقم الاتصال',
          addComment: 'إضافة تعليق',
        },
        noDataFound: {
          integrations: 'التكاملات',
          noIntegrationsFound: 'لا توجد تكاملات!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'اسم التطبيق / نظام إدارة العلاقات مع العملاء',
          statusAsConnectedOrNotConnected: 'الحالة كمتصل أو غير متصل',
          clickToConnectToAppOrCrm: 'انقر للاتصال بالتطبيق أو نظام إدارة العلاقات مع العملاء',
        },
        feedbackModal: {
          submit: 'إرسال',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          agileCrmDomain: 'نطاق Agile CRM',
          nameIsRequired: 'الاسم مطلوب',
          emailAddress: 'عنوان البريد الإلكتروني',
          apiToken: 'رمز API',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameter: 'إضافة معلمات',
          select: 'تحديد',
          mapWith: 'ربط مع',
          selectParameter: 'تحديد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'example@company.extention',
            enterTokenHere: 'أدخل الرمز هنا',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح API',
          accessKey: 'مفتاح الوصول',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'تحديد',
          mapWith: 'ربط مع',
          selectParameter: 'تحديد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiKeyHere: 'أدخل مفتاح الـ API هنا',
            enterAccessKeyHere: 'أدخل مفتاح الوصول هنا',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'رمز API',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'تحديد',
          mapWith: 'ربط مع',
          selectParameter: 'تحديد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل رمز API هنا',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          nameColon: 'الاسم:',
          inboundWebhookUrl: 'عنوان الويب هوك الوارد',
          nameIsRequired: 'الاسم مطلوب',
          leadTitle: 'عنوان العميل المحتمل',
          source: 'المصدر',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'تحديد',
          mapWith: 'ربط مع',
          selectParameter: 'تحديد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterInboundWebhookUrl: 'أدخل عنوان الويب هوك الوارد...',
            enterTitle: 'أدخل العنوان',
            enterSource: 'أدخل المصدر'
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'رمز API',
          connect: 'اتصال',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'تحديد',
          mapWith: 'ربط مع',
          selectParameter: 'تحديد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل الرمز هنا',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'رمز API',
          nameIsRequired: 'الاسم مطلوب',
          testLead: 'اختبار الرصاصة',
          connect: 'اتصال',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'تحديد',
          mapWith: 'ربط مع',
          selectParameter: 'تحديد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل رمز الـ API هنا',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'رمز API',
          testLead: 'اختبار الرصاصة',
          connect: 'اتصال',
          selectTheQuestion: 'حدد السؤال الذي ترغب في إرسال رد المستخدم كقيمة',
          mapWith: 'ربط مع',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل رمز الـ API هنا',
            attributes: 'السمات (المفتاح)',
            values: 'القيم',
          }
        },
        chatGpt: {
          chatGptApiKey: 'مفتاح API Chat GPT (Open AI)',
          chatGpt: 'Chat GPT (Open AI)',
          readyToRideWave: 'هل أنتم مستعدون للانضمام إلى موجة ChatGPT؟ ادمجوه الآن.',
          doNotHaveAnyApiKeyNeedHelp: 'ليس لديك مفتاح API؟ بحاجة للمساعدة؟',
          authenticate: 'المصادقة',
          nowYouCanAccessChatGpt: 'الآن يمكنك الوصول إلى عنصر Chat GPT في منشئ تدفق الدردشة',
          paymentSuccessful: 'تم الدفع بنجاح!',
          paymentHasBeenCompletedSuccessfully: 'تم الدفع بنجاح. أنتم مستعدون الآن لتدريب البوت الخاص بك.',
          cancel: 'إلغاء',
          trainNow: 'قم بالتدريب الآن',
          paymentInProgress: 'جاري المعالجة...',
          apiKey: 'مفتاح API Chat GPT',
          model: 'نموذج',
          integrateWithChatGPT: 'إنشاء تكامل بChatGPT',
          updateKey: 'تحديث مفتاح',
          connect: 'اتصال',
          update: 'تحديث',
          placeholders: {
            chatGptApiKey: 'مفتاح API Chat GPT (Open AI)',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'رمز API',
          connect: 'اتصال',
          testLead: 'اختبار الرصاصة',
          leadId: 'معرّف الرصاصة',
          selectLeadId: 'حدد معرّف الرصاصة',
          questionColon: 'السؤال:',
          addParameters: ' إضافة معلمات',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل الرمز هنا',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          emailAddress: 'عنوان البريد الإلكتروني',
          leadTitle: 'عنوان الزبون المحتمل',
          apiToken: 'مفتاح API',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصال',
          testLead: 'اختبار الزبون المحتمل',
          questionColon: 'السؤال:',
          addParameters: ' إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            exampleEmail: 'example@company.extention',
            enterYourLeadTitle: 'أدخل عنوان الزبون المحتمل',
            enterTokenHere: 'أدخل الرمز هنا',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          dripCrmAccountId: 'معرّف حساب Drip CRM',
          apiToken: 'مفتاح API',
          connect: 'اتصال',
          testLead: 'اختبار الزبون المحتمل',
          questionColon: 'السؤال:',
          addParameters: ' إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterAccountIdHere: 'أدخل معرّف الحساب هنا',
            enterYourTokenHere: 'أدخل الرمز هنا',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apikey: 'مفتاح API',
          accessToken: 'مفتاح الوصول',
          connect: 'اتصال',
          questionColon: 'السؤال:',
          addParameters: ' إضافة معلمات',
          select: 'اختيار',
          selectParameter: 'حدد المعلمة',
          disconnect: 'قطع الاتصال',
          update: 'تحديث',
          save: 'حفظ',
          placeholders: {
            enterApiKeyHere: 'أدخل مفتاح API هنا',
            enterTokenHere: 'أدخل مفتاح الوصول هنا',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح API',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصال',
          questionColon: 'السؤال:',
          testLead: 'اختبار الزبون المحتمل',
          addParameters: ' إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل مفتاح API هنا',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          authToken: 'الرمز المميز (AuthToken)',
          source: 'المصدر',
          connect: 'اتصال',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterAuthTokenHere: 'أدخل الرمز المميز هنا',
            enterSourceHere: 'أدخل المصدر هنا'
          }
        },
        facebookPixel: {
          facebookPixel: 'بيكسل فيسبوك',
          getInsight: `احصل على رؤى تحول عملك إلى شركة كبيرة باستخدام تكامل Google Analytics لدي BotPenguin`,
          pixelId: 'معرّف بيكسل فيسبوك',
          connect: 'اتصال',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterYourId: 'أدخل معرّف بيكسل فيسبوك الخاص بك'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          companyName: 'اسم الشركة',
          emailAddress: 'عنوان البريد الإلكتروني',
          password: 'كلمة المرور',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapsWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterCompanyName: 'أدخل اسم الشركة',
            enterEmailAddress: 'أدخل عنوان البريد الإلكتروني',
            enterPassword: 'أدخل كلمة المرور',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح API',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiKeyHere: 'أدخل مفتاح API هنا',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          flowluDomain: 'نطاق Flowlu',
          apiToken: 'مفتاح API',
          title: 'العنوان',
          connect: 'اتصال',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'أدخل المفتاح الخاص بالـ API هنا',
            enterYourLeadTitle: 'أدخل عنوانك الرئيسي',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          freshdeskDomain: 'نطاق Freshdesk',
          apiToken: 'مفتاح API',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصال',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'أدخل المفتاح الخاص بالـ API هنا',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          freshworkCrmDomain: 'نطاق Freshwork CRM',
          apiToken: 'مفتاح API',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'أدخل المفتاح الخاص بالـ API هنا'
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح API',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          mapWith: 'ربط مع',
          select: 'اختيار',
          update: 'تحديث',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل مفتاح الـ API هنا',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          campaignList: 'قائمة الحملة',
          selectCampaign: 'اختيار الحملة',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل مفتاح الـ API هنا',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'تحليلات Google',
          getInsights: `احصل على رؤى تحوّل عملك إلى شركة ذات أبعاد مع تكامل Google Analytics لدي BotPenguin`,
          measurementId: 'معرّف القياس',
          connect: 'اتصال',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterYourId: 'أدخل معرّفك هنا',
          }
        },
        googleCalendar: {
          testLead: 'اختبار العميل المحتمل',
          name: 'الاسم:',
          email: 'البريد الإلكتروني:',
          appointmentSummary: 'ملخص الموعد',
          appointmentDescription: 'وصف الموعد',
          addParameters: 'إضافة معلمات',
          questionColon: 'السؤال:',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          pause: 'إيقاف مؤقت',
          resume: 'استئناف',
          disconnect: 'قطع الاتصال',
          reconnect: 'إعادة الاتصال',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          name: 'الاسم:',
          email: 'البريد الإلكتروني:',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
        },
        googleRedirect: {

        },
        googleSheet: {
          testLead: 'اختبار العميل المحتمل',
          title: 'العنوان',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          update: 'تحديث',
          save: 'حفظ',
          pause: 'إيقاف مؤقت',
          resume: 'استئناف',
          disconnect: 'قطع الاتصال',
          reconnect: 'إعادة الاتصال',
          placeholders: {
            mappingValues: 'قيم الربط',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          name: 'الاسم:',
          email: 'البريد الإلكتروني:',
          taskList: 'قائمة المهام',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          pause: 'إيقاف مؤقت',
          resume: 'استئناف',
          disconnect: 'قطع الاتصال',
          reconnect: 'إعادة الاتصال',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          select: 'اختيار',
          addParameters: 'إضافة معلمات',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل المفتاح هنا',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح API:',
          connect: 'اتصل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiHere: 'أدخل المفتاح هنا',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          testLead: 'اختبار العميل المحتمل',
          accountId: 'معرّف الحساب',
          accessToken: 'رمز الوصول',
          connect: 'اتصل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterAccountIdHere: 'أدخل معرّف الحساب هنا',
            enterAccessTokenHere: 'أدخل رمز الوصول هنا',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح API:',
          connect: 'اتصل',
          questionColon: 'السؤال:',
          testLead: 'اختبار العميل المحتمل',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل المفتاح هنا',
          }
        },
        helpLink: {
          help: 'المساعدة',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          helpRaceCrmDomain: 'نطاق Helprace CRM',
          emailAddress: 'عنوان البريد الإلكتروني',
          apiToken: 'مفتاح API:',
          connect: 'اتصل',
          update: 'تحديث',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'example@company.extension',
            token: 'المفتاح',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          domainName: 'اسم النطاق',
          apiKey: 'مفتاح API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل المفتاح هنا',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح API:',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل المفتاح API هنا',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiHere: 'أدخل API هنا',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح API:',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل مفتاح API هنا',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح API:',
          emailAddress: 'عنوان البريد الإلكتروني:',
          apiUrl: 'عنوان API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          selectProjectName: 'حدد اسم المشروع',
          selectIssueType: 'حدد نوع المشكلة',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapsWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل مفتاح هنا',
            enterEmailAddress: 'أدخل عنوان البريد الإلكتروني هنا',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiUrl: 'عنوان API:',
          nameIsRequired: 'الاسم مطلوب',
          apiKey: 'مفتاح API:',
          apiId: 'معرف API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          mapWith: 'ربط مع',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          selectTheQuestion: 'حدد السؤال الذي تريد إرسال إجابة المستخدم عليه كقيمة',
          placeholders: {
            enterApiUrl: 'أدخل عنوان الـ API هنا',
            enterApiKey: 'أدخل مفتاح الـ API هنا',
            enterApiId: 'أدخل معرف الـ API هنا',
            attributes: 'السمات (مفتاح)',
            values: 'القيم',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل مفتاح الـ API هنا',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح API:',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل مفتاح الـ API هنا',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          accessKey: 'مفتاح الوصول:',
          secretKey: 'المفتاح السري:',
          connect: 'اتصل',
          apiLogs: 'سجلات الـ API',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          udpate: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          filterApplied: 'تم تطبيق الفلتر',
          retry: 'إعادة المحاولة',
          back: 'رجوع',
          filterYourLeads: 'قم بتصفية عملائك',
          filterOn: 'تصفية حسب',
          filterBy: 'تصفية حسب',
          discard: 'تجاهل',
          applyFilter: 'تطبيق الفلتر',
          loadMore: 'تحميل المزيد',
          placeholders: {
            enterAccessKeyHere: 'أدخل مفتاح الوصول هنا',
            enterSecretKeyHere: 'أدخل المفتاح السري هنا',
            attributes: 'السمات (مفتاح)',
            values: 'القيم',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'تصفية البيانات استنادًا إلى الاستجابة',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API:',
          apiUrl: 'عنوان الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiHere: 'أدخل الـ API هنا',
            exampleUrl: 'https://اسمالشركة.ladesk.com'
          }
        },
        microsoftTeams: {
          downloadPackage: 'قم بتنزيل حزمة تطبيق مايكروسوفت تيمز',
          downloadApp: 'قم بتنزيل التطبيق',
          wantToLearnHowToUploadIt: 'هل ترغب في معرفة كيفية رفعه؟',
          needHelp: 'هل تحتاج إلى مساعدة؟',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiUrl: 'عنوان الـ API:',
          appId: 'معرف التطبيق:',
          nameIsRequired: 'مطلوب معرف التطبيق',
          dataApiKey: 'مفتاح بيانات الـ API:',
          dataApiKeyIsRequired: 'مطلوب مفتاح بيانات الـ API',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          addParameters: 'إضافة معلمات',
          mapWith: 'ربط مع',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          selectTheQuestion: 'اختر السؤال الذي ترغب في إرسال رد المستخدم عليه كقيمة',
          placeholders: {
            enterApiUrl: 'أدخل عنوان الـ API هنا',
            enterAppId: 'أدخل معرف التطبيق هنا',
            enterDataApiKey: 'أدخل مفتاح بيانات الـ API هنا',
            attributes: 'السمات (مفتاح)',
            pressForMappingQuestion: 'اضغط / لسؤال الربط',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          emailAddress: 'عنوان البريد الإلكتروني:',
          apiKey: 'مفتاح الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          leadId: 'معرف العميل المحتمل:',
          selectLeadId: 'اختر معرف العميل المحتمل:',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterEmailHere: 'أدخل البريد الإلكتروني هنا',
            enterApiKeyHere: 'أدخل مفتاح الـ API هنا',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل مفتاح الـ API هنا',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          noCrmDomain: 'نطاق NoCRM:',
          leadTitle: 'عنوان العميل المحتمل:',
          titleIsRequired: 'العنوان مطلوب',
          token: 'الرمز:',
          tokenIsRequired: 'الرمز مطلوب',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            exampleDomain: 'https://YOUR_SUBDOMAIN_HERE.nocrm.io',
            enterYourLeadTitle: 'أدخل عنوان العميل المحتمل الخاص بك',
            enterYourToken: 'أدخل الرمز الخاص بك',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          emailAddress: 'عنوان البريد الإلكتروني:',
          apiKey: 'مفتاح الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل مفتاح الـ API هنا',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          secretToken: 'الرمز السري:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل الرمز السري هنا',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          appId: 'معرف التطبيق:',
          appIdIsRequired: 'معرف التطبيق مطلوب',
          apiKey: 'مفتاح الـ API:',
          apiKeyIsRequired: 'مفتاح الـ API مطلوب',
          connect: 'اتصل',
          update: 'تحديث',
          disconnect: 'قطع الاتصال',
          placeholders: {
            appId: 'معرف التطبيق',
            apiKey: 'مفتاح الـ API',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          clientId: 'معرف العميل:',
          connect: 'اتصل',
          clientSecret: 'السر الخاص بالعميل:',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          save: 'حفظ',
          update: 'تحديث',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterClientIdHere: 'أدخل معرف العميل هنا',
            enterClientSecretHere: 'أدخل السر الخاص بالعميل هنا',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          pipedriverDomain: 'نطاق Pipedrive:',
          domainIsRequired: 'النطاق مطلوب',
          apiToken: 'رمز الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'أدخل الرمز هنا',
          }
        }, pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API:',
          apiKeyIsRequired: 'مفتاح الـ API مطلوب',
          appKey: 'مفتاح التطبيق:',
          appKeyIsRequired: 'مفتاح التطبيق مطلوب',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiKey: 'أدخل مفتاح الـ API هنا',
            enterAppKey: 'أدخل مفتاح التطبيق هنا',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          authId: 'معرف المصادقة:',
          authToken: 'مفتاح المصادقة:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل مفتاح المصادقة هنا',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiHere: 'أدخل مفتاح الـ API هنا',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiKey: 'أدخل مفتاح الـ API هنا',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          domain: 'النطاق',
          clientId: 'معرف العميل',
          clientSecret: 'السر الخاص بالعميل',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterDomainHere: 'أدخل النطاق هنا',
            enterClientIdHere: 'أدخل معرف العميل هنا',
            enterClientSecretHere: 'أدخل السر الخاص بالعميل هنا',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API:',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          addEventsAndTemplates: 'إضافة الأحداث والقوالب:',
          add: 'إضافة',
          question: 'السؤال',
          reply: 'الرد',
          requestBody: 'جسم الطلب',
          validateJson: 'التحقق من صحة JSON',
          emailAddress: 'عنوان البريد الإلكتروني',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          selectTheQuestion: 'حدد السؤال الذي ترغب في إرسال إجابة المستخدم كقيمة له',
          placeholders: {
            enterApiHere: 'أدخل مفتاح الـ API هنا',
            enterTemplateId: 'أدخل معرف القالب هنا',
            typeYourJsonCodeHere: 'اكتب كود JSON الخاص بك هنا',
          },
          appTooltip: {
            typeYourJsonCode: 'اكتب كود JSON الخاص بك',
            clickToValidateJsonCode: 'انقر للتحقق من صحة كود JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          userName: 'اسم المستخدم',
          licenseKey: 'مفتاح الترخيص',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterNameHere: 'أدخل الاسم هنا',
            enterKeyHere: 'أدخل مفتاح الترخيص هنا',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح الـ API',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل مفتاح الـ API هنا',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          company: 'الشركة',
          loginId: 'معرّف الدخول',
          password: 'كلمة المرور',
          testLead: 'المسؤول التجريبي',
          connect: 'اتصال',
          selectTheQuestion: 'حدد السؤال الذي ترغب في إرسال رد المستخدم عليه كقيمة',
          mapWith: 'تعيين مع',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          selectParameter: 'حدد المعلمة',
          placeholders: {
            enterCompanyHere: 'أدخل اسم الشركة هنا',
            enterLoginIdHere: 'أدخل معرّف الدخول هنا',
            enterPasswordHere: 'أدخل كلمة المرور هنا',
            values: 'القيم',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          applicationKey: 'مفتاح التطبيق',
          authToken: 'رمز المصادقة',
          connect: 'اتصل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApplicationKeyHere: 'أدخل مفتاح التطبيق هنا',
            enterTokenHere: 'أدخل رمز المصادقة هنا',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          accessToken: 'مفتاح الوصول',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          question: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterTokenHere: 'أدخل مفتاح الوصول هنا',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          webhoperDomain: 'نطاق Webhoper',
          domainIsRequired: 'النطاق مطلوب',
          companyName: 'اسم الشركة',
          companyIsRequired: 'الشركة مطلوبة',
          leadSource: 'مصدر العميل المحتمل',
          leadSourceIsRequired: 'مصدر العميل المحتمل مطلوب',
          division: 'القسم',
          divisionIsRequired: 'القسم مطلوب',
          enterPassword: 'أدخل كلمة المرور',
          passwordIsRequired: 'كلمة المرور مطلوبة',
          disconnect: 'قطع الاتصال',
          update: 'تحديث',
          connect: 'اتصل',
          placeholders: {
            exampleDomain: 'http://example.com',
            enterYourCompanyName: 'أدخل اسم شركتك',
            enterLeadSource: 'أدخل مصدر العميل المحتمل',
            enterDivision: 'أدخل القسم',
            enterPassword: 'أدخل كلمة المرور',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          name: 'الاسم:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'عنوان الويب هوك الوارد',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterWebhookUrl: 'أدخل عنوان الويب هوك هنا',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'مفتاح الـ API',
          emailAddress: 'عنوان البريد الإلكتروني',
          apiUrl: 'عنوان الـ API',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterEmailAddress: 'أدخل عنوان البريد الإلكتروني',
            enterUrlHere: 'أدخل العنوان هنا',
            enterTokenHere: 'أدخل مفتاح الـ API هنا',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiKey: 'مفتاح الـ API',
          connect: 'اتصل',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiHere: 'أدخل مفتاح الـ API هنا',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          connect: 'اتصل',
          update: 'تحديث',
          disconnect: 'قطع الاتصال',
          companyName: 'اسم الشركة',
          emailAddress: 'عنوان البريد الإلكتروني',
          phone: 'الهاتف',
          countryCode: 'رمز الدولة',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          save: 'حفظ',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          connect: 'اتصل',
          disconnect: 'قطع الاتصال',
          testLead: 'اختبار العميل المحتمل',
          emailAddress: 'عنوان البريد الإلكتروني',
          selectOrganizationName: 'حدد اسم المؤسسة',
          selectDepartmentName: 'حدد اسم القسم',
          addParameters: 'إضافة معلمات',
          questionColon: 'السؤال:',
          select: 'اختيار',
          mapsWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          testLead: 'اختبار العميل المحتمل',
          connect: 'اتصل',
          disconnect: 'قطع الاتصال',
          projectsList: 'قائمة المشاريع',
          selectProjectsName: 'حدد اسم المشروع',
          projectsTaskList: 'قائمة مهام المشروع',
          selectTaskListName: 'حدد اسم قائمة المهام',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'اختيار',
          mapWith: 'ربط مع',
          selectParameter: 'حدد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
        },
        wooCommerce: {
          configure: 'تكوين',
          automatedMessage: 'رسالة آلية',
          configureTab: {
            wooCommercePluginApiKey: 'مفتاح واجهة برمجة التطبيقات لوحة تحكم WooCommerce',
            downloadWooCommercePlugin: 'تحميل إضافة WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'رسالة استرداد السلة المهجورة',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'قم بتكوين رسائل الاسترداد الآلية لاستعادة العملاء الذين تركوا سلاتهم',
            enableAbandonedCartRecoveryMessaegs: 'تمكين رسائل استرداد السلة المهجورة',
            selectTemplate: 'اختر قالبًا',
            sendMessageAfter: 'إرسال الرسالة بعد',
            templateMessage: 'نص القالب',
            saveChanges: 'حفظ التغييرات',
            selectFile: 'اختر ملفًا',
            orderCompleted: 'تم الطلب',
            templateHeader: 'رأس القالب',
            orderFulfillMent: 'أداء الطلب',
            enableOrderConfirmationMessages: 'تمكين رسائل تأكيد الطلب',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'إرسال رسالة تأكيد الطلب فور قيام العميل بطلب',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'يتم إرسال رسالة شحن الطلب عند أداء الطلب',
            enableOrderFulfillmentMessages: 'تمكين رسائل أداء الطلب',
            returnExchangeRequest: 'طلب الإرجاع / التبديل',
            theMessageIsSentAfterARefundIsRequested: 'يتم إرسال الرسالة بعد طلب استرداد',
            enableOrderRefundMessages: 'تمكين رسائل استرداد الطلب',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'لقد قمت بإنشاء التكامل بنجاح. الآن يمكنك تكوينه.',
          apiToken: 'رمز API',
          nameIsRequired: 'الاسم مطلوب',
          connect: 'اتصال',
          testLead: 'اختبار العميل المحتمل',
          questionColon: 'السؤال:',
          addParameters: 'إضافة معلمات',
          select: 'تحديد',
          mapWith: 'ربط مع',
          selectParameter: 'تحديد المعلمة',
          update: 'تحديث',
          save: 'حفظ',
          disconnect: 'قطع الاتصال',
          placeholders: {
            enterApiToken: 'أدخل رمز API هنا',
          }
      }
      }
    },
    createIntegration: {
      createNewIntegration: 'إنشاء تكامل جديد',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'ليس لديك أي تكامل نشط حتى الآن.',
      pleaseCreateOneToIntegrateYourFavourite: 'يرجى إنشاء تكامل لربط تطبيقك المفضل',
      appsWith: 'التطبيقات مع ',
      integrationName: 'اسم التكامل',
      integrationNameIsRequired: 'اسم التكامل مطلوب',
      continue: 'متابعة',
      addAnImageToRepresentYourIntegration: 'أضف صورة لتمثيل تكاملك.',
      description: 'الوصف',
      submit: 'إرسال',
      youHaveSuccessfullyCreatedIntegration: 'لقد أنشأت بنجاح تكاملًا. يمكنك الآن تكوينه.',
      apiUrl: 'عنوان واجهة برمجة التطبيقات',
      pleaseEnterAValidApiUrl: 'الرجاء إدخال عنوان واجهة برمجة التطبيقات صالح',
      requestMethod: 'طريقة الطلب',
      get: 'احصل',
      post: 'أرسل',
      put: 'ضع',
      patch: 'رصد',
      queryParameter: 'معلمة الاستعلام',
      addParameter: 'إضافة معلمة',
      mapsWith: 'تخصيص مع',
      question: 'السؤال',
      addQuestion: 'إضافة سؤال',
      select: 'اختيار',
      selectParameter: 'حدد المعلمة',
      header: 'رأس الطلب',
      addHeader: 'إضافة رأس',
      value: 'القيمة',
      connect: 'اتصال',
      requestBody: 'جسم الطلب',
      validate: 'تحقق',
      key: 'المفتاح',
      // value: 'القيمة',
      // connect: 'اتصال',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: 'يمكن أيضًا دمجه مع هذه الخدمة المجانية لإدارة العلاقات مع العملاء.',
        apiUrl: 'عنوان واجهة برمجة التطبيقات',
        pleaseEnterAValidApiUrl: 'الرجاء إدخال عنوان واجهة برمجة تطبيقات صالح',
        requestMethod: 'طريقة الطلب:',
        get: 'احصل',
        post: 'أرسل',
        put: 'ضع',
        patch: 'رصد',
        queryParameter: 'معلمة الاستعلام',
        addParameter: 'إضافة معلمة',
        header: 'رأس الطلب',
        addHeader: 'إضافة رأس',
        requestBody: 'جسم الطلب',
        validateJson: 'تحقق من JSON',
        connect: 'اتصال',
        update: 'تحديث',
        delete: 'حذف',
        selectTheQuestion: 'حدد السؤال الذي ترغب في إرسال إجابة المستخدم كقيمة',
        appTooltip: {
          typeYourJsonCode: 'اكتب رمز JSON الخاص بك',
          clickToValidateJsonCode: 'انقر للتحقق من رمز JSON',
        }
      },
      list: {
        customAppsIntegrations: 'دمج التطبيقات المخصصة',
        integrateYourAppsWith: 'قم بدمج تطبيقاتك الخاصة مع ',
        help: 'مساعدة',
        addIntegration: 'إضافة تكامل',
        name: 'الاسم',
        status: 'الحالة',
        action: 'الإجراء',
        insatlled: 'مثبتة',
        edit: 'تحرير',
        connect: 'اتصال',
        requestYourIntegration: 'اطلب تكاملك',
        toRequestNewIntegration: 'لطلب تكامل جديد، يرجى إدخال التفاصيل المذكورة أدناه ثم سنعاود الاتصال بك.',
        emailId: 'عنوان البريد الإلكتروني',
        integrationName: 'اسم التكامل',
        contactNumber: 'رقم الاتصال',
        howSoonYouWantToAddThisIntegration: 'كم سريعًا ترغب في إضافة هذا التكامل',
        sendRequest: 'إرسال الطلب',
        cancel: 'إلغاء',
        delete: 'حذف',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'عذرًا! الاندماجات الخاصة بك مدعومة فقط في',
        plan: 'خطة',
        pleaseUpgradeTo: 'يرجى الترقية إلى',
        toCreateOwnIntegrations: 'لإنشاء الاندماجات الخاصة بك',
        appTooltip: {
          integrations: 'التكاملات',
          noIntegrationsFound: 'لم يتم العثور على تكاملات!',
          nameOfYourApp: 'اسم تطبيقك',
          clickToConnectApp: 'انقر للاتصال بالتطبيق',
          statusAsConnectedOrNotConnected: 'الحالة كمتصل / غير متصل',
        }
      }
    }
  }
};
