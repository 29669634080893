export const inboxMerged = {
  inboxHeader: 'Caixa de entrada',
  chatsHeader: 'Bate -papos',
  contactsHeader: 'Contatos',
  segmentsHeader: 'Grupos',
  ordersHeader: 'Ordens',
  exportStatus: 'Status de exportação',
  tasksExport: 'Tarefas- exportação',
  download: 'Download',
  ourAllNewOmniChannelInbox: 'Nossa nova caixa de entrada omni-canal!',
  allYourMessagesContactsAndLeads: 'Todas as suas mensagens, contatos e leads estão agora em um só lugar. Pronto para ser classificado e grupos, para fazer sua empresa crescer!',
  close: 'Fechar',
  takeALook: 'Dê uma olhada',
  inProgress: 'em andamento',
  appTooltip: {
    manageAllYourLeadsHere: 'Gerencie todos os seus leads/contatos aqui',
    segmentYourUserLeads: 'Grupo seus usuários de leads/bot com base em dados demográficos e interesses. Estes serão usados ​​na criação de transmissões',
    manageAllBotsConversationsHere: 'Gerencie todas as conversas dos bots aqui',
    manageAllYourOrdersHere: 'Gerencie todos os seus pedidos aqui'
  },
  contacts: {
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    visitorName: 'Nome do visitante',
    email: 'E-mail',
    phoneNumber: 'Número de telefone',
    segment: 'Grupo',
    status: 'Status',
    tag: 'Marcação',
    action: 'Ação',
    discard: 'Descartar',
    addTag: 'Adicionar etiqueta',
    loadMore: 'Carregue mais',
    nA: 'N / D',
    assign: 'Atribuir',
    deleteUser: 'Deletar usuário',
    areYouSureToDeleteLead: 'Tem certeza de que deseja excluir esse lead?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'Você ainda não tem nenhum contato!',
      allOfYourContactsAppearHere: 'Todos os seus contatos aparecerão aqui'
    },
    placeholders: {
      searchSegmentByName: 'Grupo de pesquisa por nome',
      searchOrAddTag: 'Pesquise ou adicione uma tag',
    },
    addContacts: {
      name: 'Nome',
      email: 'E-mail',
      enterAValidEmail: 'Entre com um email válido',
      phoneNumber: 'Número de telefone',
      cancel: 'Cancelar',
      addContact: 'Adicionar contato',
      placeholders: {
        enterEmailAddress: 'Insira o endereço de e-mail',
        enterUserrName: 'Insira nome de usuário',
        searchCountry: 'País de pesquisa',
        enterPhoneNumber: 'Insira o número de telefone',
      }
    }
  },
  inboxFilter: {
    applied: 'aplicado',
    assignTags: 'Atribua tags',
    discard: 'Descartar',
    addTag: 'Adicionar etiqueta',
    assignSegments: 'Atribuir Grupos',
    filterYourLeads: 'Filtrar seus leads',
    filters: 'filtros',
    filter: 'filtro',
    resetAllFilters: 'Redefinir todos os filtros',
    importContacts: 'Contatos importantes',
    beforeYouStart: 'Antes de iniciar o upload, certifique -se de',
    point1: '1. Faça o download do arquivo de Excel.',
    point2: '2. Comece a inserir seus dados a partir da 2ª linha em diante.',
    point3: '3. Em cada linha, haverá dados para um contato',
    point4: '4. Verifique se todos os números de telefone são únicos.',
    point5: '5. O limite máximo de upload do tamanho do arquivo é de 1 MB',
    selectFile: 'Selecione o arquivo',
    clickHere: 'Clique aqui',
    toDownloadSampleFile: 'Para baixar o arquivo de amostra',
    modal: {
      closeLeads: 'Protagonistas próximos',
      areYouSureToselectAllLeads: 'Tem certeza de que deseja fechar todos os leads selecionados?',
      assignAgent: 'Atribuir agente',
      toAssignAgentToAllLeads: 'Tem certeza de que deseja atribuir agente a todos os leads selecionados?',
      assignTag: 'Atribuir tag',
      wantToReplacePreviouslyAssignedTagWithNew: 'Tem certeza de que deseja substituir as tags atribuídas anteriormente pela nova tag escolhida para todos os leads selecionados?',
      deleteLeads: 'Excluir leads',
      wantToDeleteSelectedLeads: 'Tem certeza de que deseja excluir todos os leads selecionados?',
      exportData: 'Exportar dados',
      exportDataBasedOn: 'Exportar dados com base no',
      assignSegment: 'Atribuir Grupo',
      wantToAssignSegmentsToSelectedLeads: 'Tem certeza de que deseja atribuir Grupo a todos os leads selecionados?',
      yesGoAhead: 'Sim vá em frente!',
      export: 'Exportar',
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Filtre os dados com base em suas preferências, como tags, bot, status, etc.',
      markLeadAsClosed: 'Marque o usuário/chumbo como fechado',
      assignAgentToLead: 'Atribuir agente ao usuário',
      assignTagToUser: 'Atribuir tag ao usuário',
      exportData: 'Exportar dados',
      importContactsForWhatsappBot: 'Importar contatos para o WhatsApp Bot',
      addWhatsappContact: 'Adicionar contatos do WhatsApp',
      assignSegmentToUser: 'Atribuir Grupo ao usuário',
      deleteUsers: 'Excluir usuários',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Digite o nome, e -mail ou telefone e pressione Enter',
      searchAgentByName: 'Agente de pesquisa por nome',
      searchOrAddTag: 'Pesquise ou adicione uma tag',
      searchSegmentByName: 'Grupo de pesquisa por nome',
    },
    filterContainer: {
      filterOn: 'Filtre ligado',
      filterBy: 'Filtrar por',
      startFrom: 'Começar de',
      tillDate: 'Até a data',
      offlineOrOnline: 'Desligado Ligado',
      liveChat: 'Bate-papo ao vivo',
      userInteractedWithBot: 'O usuário interagiu com o bot',
      discard: 'Descartar',
      applyFilter: 'Aplicar filtro',
      bots: 'Bots',
      tags: 'Tag',
      status: 'Status',
      assignedTo: 'Atribuído a',
      lastMessageBy: 'Última mensagem por',
      onlineOrOffline: 'Conectado desconectado',
      userInteracted: 'O usuário interagiu',
      segments: 'Grupos',
      lastSeenDate: 'Última data vista',
      onboardingDate: 'Data de integração',
      createTags: 'Crie tags',
      createTagsToSortAndManageLeads: 'Crie tags para classificar e gerenciar seus leads facilmente',
      typeYourTagHere: 'Digite sua tag aqui',
      createSegment: 'Criar Grupo',
      manageSegmentForMarketingAutomation: 'Gerencie seus Grupos para a automação de marketing',
      typeYourSegmentHere: 'Digite seu Grupo aqui',
      placeholders: {
        searchFilter: 'Filtro de pesquisa',
        searchByBotName: 'Pesquise por nome de bot',
        searchOrAddTag: 'Pesquise ou adicione uma tag',
        searchByAgentName: 'Pesquise por nome do agente',
        searchBySegments: 'Pesquise por Grupos',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'DATA DE DATA que você deseja encerrar a seleção em',
        dateRangeYouWishToStartSelectionFrom: 'DATA DE DATA que você deseja iniciar a seleção de',
        filterThoseThatAreOfflineOrOnline: 'Filtre os usuários offline/online',
        filterThoseThatAreCurrentlyLiveChatting: 'Filtre os usuários que estão atualmente conversando ao vivo',
        filterThoseThatInteractedWithBot: 'Filtrar os usuários que interagiram com o bot',
      },
      indexMergeDatePicker: {
        selectStartDate: 'Selecione a data de início',
        selectEndDate: 'Selecione a data de término',
      }
    }
  },
  leads: {
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    visitorName: 'Nome do visitante',
    email: 'E-mail',
    phoneNumber: 'Número de telefone',
    assignedTo: 'Atribuído a',
    status: 'Status',
    tag: 'Marcação',
    action: 'Ação',
    discard: 'Descartar',
    addTag: 'Adicionar etiqueta',
    loadMore: 'Carregue mais',
    nA: 'N / D',
    deleteUsers: 'Deletar usuário',
    areYouSureToDeleteLead: 'Tem certeza de que deseja excluir esse lead?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Você ainda não tem bate -papo',
      allOfYourChatsAppearHere: 'Todos os seus bate -papos aparecerão aqui',
    },
    placeholders: {
      searchAgentByName: 'Agente de pesquisa por nome',
      searchStatusByName: 'Status de pesquisa por nome',
      searchOrAddTag: 'Pesquise ou adicione uma tag',
    }
  },
  messages: {
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    editChatFlow: 'Editar o fluxo de conversa',
    connect: 'Conectar',
    chat: {
      photo: 'Foto',
      video: 'Vídeo',
      document: 'Documento',
      location: 'Localização',
      assignedTo: 'Atribuído a',
      selectBot: 'Selecione Bot',
      discard: 'Descartar',
      addTag: 'Adicionar etiqueta',
      yourBrowserDoesNotSupportVideoTag: 'Seu navegador não suporta a tag de vídeo.',
      userHasSharedADocument: 'O usuário compartilhou um documento.',
      clickHereToDownload: 'Clique aqui para baixar',
      thisStoryIsNoLongerAvailable: 'Esta história não está mais disponível',
      yourBrowserDoesNotSupportAudioElement: 'Seu navegador não suporta o elemento de áudio.',
      thisMessageIsNoLongerAvailable: 'Esta mensagem não está mais disponível porque não estava de entrada no remetente',
      userHasSharedHisLocation: 'O usuário compartilhou sua localização. Clique aqui para ver',
      sms: 'SMS',
      failed: 'Fracassado',
      a: 'A',
      i: 'EU',
      r: 'R',
      m: 'M',
      conversationsSelected: 'conversas selecionadas',
      nA: 'N / D',
      assignTagsCloseOrBulkReply: 'Atribuir tags, fechar ou em massa Responder a todos de uma só vez',
      appTooltip: {
        assignAgent: 'Atribuir agente',
        selectBot: 'Selecione Bot',
        assignTagsToManageLeads: 'Atribuir tags para gerenciar leads e conversas',
      },
      placeholders: {
        searchAgentByName: 'Agente de pesquisa por nome',
        searchBotByName: 'Bot de pesquisa por nome',
        searchOrAddTag: 'Pesquise ou adicione uma tag',
        searchStatusByName: 'Status de pesquisa por nome',
      }
    },
    profile: {
      userDetails: 'Detalhes do usuario',
      orderDetails: 'Detalhes do pedido',
      orderedOn: 'Encomendado em',
      showMore:  'Mostrar mais',
      showLess: 'Mostrar menos',
      subTotal:  'Subtotal',
      nA: 'N / D',
      addAWhatsappNumber: 'Adicione o número do WhatsApp',
      addTelegramUserName: 'Adicione o nome de usuário do Telegram',
      addInstagramProfileId: 'Adicionar ID do perfil do Instagram',
      addFacebookProfileId: 'Adicionar ID do perfil do Facebook',
      add: 'Adicionar',
      deviceProperties: 'Propriedades do dispositivo',
      privateNotes: 'Notas particulares',
      urlsVisited: 'URLs visitados',
      source: 'Fonte -',
      sourceNa: 'Fonte - na',
      medium: 'Médio -',
      mediumNa: 'Médio - na',
      campaign: 'Campanha -',
      campaignNa: 'Campanha - NA',
      attributeKey: 'Chave de atributo',
      attributeName: 'Nome do Atributo',
      discard: 'Descartar',
      addAttribute: 'Adicione o atributo',
      editAttribute: 'Editar atributo',
      deleteAttribute: 'Excluir atributo',
      headsUp: 'Atenção',
      areYouSureToDeleteAttribute: 'Tem certeza de que deseja excluir este atributo?',
      okay: 'OK',
      dataAddedToLead: 'Os atributos mapeados para suas APIs ou Fluxos de Bot não serão atualizados. Por favor, atualize as APIs ou Fluxos de Bot conforme necessário.',
      assignedOnly: 'Apenas atribuído',
      allUsers: 'Todos os usuários',
      save: 'Salvar',
      manageCustomAttributes: 'Gerencie seus Atributos',
      placeholders: {
        title: 'Título',
        egServices: 'Por exemplo. Serviços',
        egRequestedServices: 'Por exemplo. Serviços solicitados',
        enterYourNotesHere: 'Digite suas anotações aqui ...',
      },
      bpDropDown: {
        selectBot: 'Selecione Bot',
        selectFlow: 'Selecione Fluxo',
        selectQuestion: 'Selecione Pergunta*',
      }
    },
    sendMessage: {
      send: 'Enviar',
      sendToAllChannels: 'Enviar para todos os canais',
      email: 'E-mail',
      whatsapp: 'Whatsapp',
      telegram: 'Telegrama',
      facebook: 'Facebook',
      sendSms: 'Enviar SMS',
      addNew: 'Adicionar novo',
      addNewQuickReply: 'Adicione nova resposta rápida',
      discard: 'Descartar',
      fromComputer: 'Do computador',
      add: 'Adicionar',
      dragAndDropHereOr: 'Arraste e solte aqui ou',
      browseFiles: 'Procure arquivos',
      caption: 'Rubrica',
      templates: 'Modelos',
      addHeaderMedia: 'Adicione a mídia de cabeçalho',
      selectFile: 'Selecione o arquivo',
      previousConversationClosed: 'Conversação anterior foi fechada!',
      sendTemplateMessage: 'Envie a mensagem de modelo para iniciar a conversa novamente.',
      selectTemplate: 'Selecione modelo',
      conversationLocked: 'Conversação travada!',
      demoBotOnlyAllowConversations: 'O bot de demonstração permite apenas uma conversa com o número que você verificou anteriormente.',
      placeholders: {
        searchQuickReplyByTitle: 'Pesquisar respostas rápidas por título',
        addTitle: 'Adicione o título',
        addYourRepliesHere: 'Adicione suas respostas aqui ...',
        addCaption: 'Adicionar legenda',
        enterValueForThisVariable: 'Insira o valor para esta variável',
      },
      modal: {
        sendBulkMessages: 'Envie mensagens em massa',
        send: 'Enviar',
        description: 'Sua mensagem será higienizada e enviada com base no tipo de plataforma a que um usuário pertence. - Os usuários do site suportam apenas mensagens baseadas em textual - contatos do WhatsApp (não assinados) podem receber apenas mensagens de modelo',
      },
      noDataFound: {
        oops: 'Opa!',
        currentlyDoesNotSupportBulkMessages: 'Atualmente, não suportamos mensagens em massa para bots do WhatsApp. Por favor, use o recurso de transmissão',
        quickReplies: 'Respostas rápidas',
        noQuickRepliesAddedYet: 'Ainda não há respostas rápidas adicionadas!',
      }
    },
    userList: {
      selectAll: 'Selecionar tudo',
      nA: 'N / D',
      loadMore: 'Carregue mais',
    }
  },
  segments: {
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    manageSegment: 'Gerenciar Grupo',
    manageYourSegmentsForMarketingAutomation: 'Gerencie seus Grupos para a automação de marketing',
    createNewSegment: 'Crie novo Grupo',
    segmentName: 'Nome do Grupo',
    totalContacts: 'Contatos totais',
    action: 'Ação',
    createSegmentButton: 'Criar Grupo',
    deleteSegments: 'Excluir Grupos',
    areYouSureToDeleteSegment: 'Tem certeza de que deseja excluir este Grupo?',
    placeholders: {
      searchHere: 'Procure aqui',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'Você não tem nenhum Grupos criados ainda',
      allYourSegmentsAppearHere: 'Todos os seus Grupos aparecerão aqui',
    },
    modal: {
      exportData: 'Exportar dados',
      exportDataBasedOnAppliedFilters: 'Exportar dados com base nos filtros aplicados',
      export: 'Exportar',
    },
    appTooltip: {
      deleteSegments: 'Excluir Grupos',
      editSegment: 'Editar Grupo',
      downloadSegment: 'Download do Grupo',
      deleteSegment: 'Excluir Grupo',
    },
    createSegment: {
      createSegment: 'Criar Grupo',
      manageYourSegmentsForMarketingAutomation: 'Gerencie seus Grupos para a automação de marketing',
      segmentName: 'Nome do Grupo',
      segmentNameIsRequired: 'O nome do Grupo é necessário',
      selectContactsFilterForSegment: 'Selecione Filtros de contato para o Grupo',
      filterYourContacts: 'Filtre seus contatos',
      removeAllFilters: 'Remova todos os filtros',
      addFilters: 'Adicionar filtros',
      discard: 'Descartar',
      filterSelected: 'Filtros selecionados',
      placeholders: {
        enterSegmentName: 'Digite o nome do Grupo',
      }
    }
  },
  orders: {
    visitorName: 'Nome do visitante',
    email: 'Email',
    phoneNumber: 'Número de telefone',
    status: 'Status',
    itemCount: 'Quantidade de itens',
    total: 'Total',
    tag: 'Tag',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'Você não tem nenhum pedido ainda',
      allOfYourOrdersAppearHere: 'Todos os seus pedidos aparecerão aqui'
    },
  }
};
