export const developer = {
  developers: 'Entwickler',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Befähigen Sie Ihre Entwickler, das volle Potenzial Ihres Dashboards zu nutzen',
  accessToken: 'Zugriffstoken',
  yourAPI: 'Ihre APIs',
  api: {
    apiManagement: 'API-Verwaltung',
    apiManagementSubHeading: 'Fügen Sie APIs hinzu, konfigurieren Sie sie, testen Sie sie und verwalten Sie sie, die Sie in Ihren Chatflows auslösen können.',
    addApi: 'API hinzufügen',
    createApi: 'API erstellen',
    noApiFound: 'Keine API gefunden!',
    deleteApis: 'APIs löschen',
    areYouSureYouWantToDeleteAPI: 'Sind Sie sicher, dass Sie die API löschen möchten?',
    placeholders: {
      search: `Nach API-Namen suchen`
    },
    tableHead: {
      method: 'Methode',
      apiName: 'API-Name',
      url: 'URL',
      actions: 'Aktionen'
    },
    parametersTab: 'Parameter',
    headersTab: 'Header',
    bodyTab: 'Body Tab',
    manageApiResponses: 'API-Antworten verwalten',
    chooseVariableToSave: 'Variable zum Speichern auswählen',
    selectCustomAttribute: 'Benutzerdefinierte Eigenschaft auswählen',
    subTab: {
      key: 'Schlüssel',
      enterKey: 'Schlüssel eingeben',
      value: 'Wert',
      enterValue: 'Wert eingeben',
      addAnotherField: 'Ein weiteres Feld hinzufügen',
      save: 'Speichern',
      useslashToInsertCustomVariables: `Verwenden Sie '/' zur Einfügung von benutzerdefinierten Variablen oder dynamischen Werten aus der Benutzerantwort.`,
      response: 'Antwort',
      apiRequestBody: 'API-Anfrage-Body',
      hit: 'Treffer',
      test: 'Test',
      buttonToGetAResponse: 'Schaltfläche, um eine Antwort zu erhalten',
      variable: 'Variable',
      saveToCustomAttribute: 'In benutzerdefinierte Eigenschaft speichern'
    },
    apiLogs: {
      apiManagement: 'API-Verwaltung',
      addConfigureTestAndManageYourAPIs: 'Fügen Sie APIs hinzu, konfigurieren Sie sie, testen Sie sie und verwalten Sie sie, die Sie in Ihren Chatflows auslösen können.',
      searchHere: 'Nach Statuscode suchen',
      deleteApis: 'APIs löschen',
      testAnApi: 'API testen',
      noAPILogFound: 'Kein API-Protokoll gefunden',
      responseCode: 'Antwortcode',
      message: 'Nachricht',
      apiResponse: 'API-Antwort',
      actions: 'Aktionen',
      deleteAPIs: 'APIs löschen',
      areYouSureYouWantToDelete: 'Sind Sie sicher, dass Sie diese API löschen möchten?',
      apiLogs: 'API-Protokolle',
      subTitle: 'Detaillierte Aufzeichnung aller API-Anfragen und -Antworten, die innerhalb des Chatflusses des Chatbots ausgelöst wurden.'
    }
  },
  configApi: {
    configureApi: `API konfigurieren`,
    configureApiSubHeading: `Sie können Ihre JSON-basierte API hier konfigurieren und in Ihrem Chat-Flow verwenden.`,
    apiLogs: `API-Protokolle`,
    test: `Test`,
    manageApiResponse: `API-Antworten verwalten`,
    body: `Body`,
    headers: `Header`,
    parameters: `Parameter`,
    placeholders: {
      enterApiName: `Geben Sie den API-Namen ein`,
      enterApiUrl: `Geben Sie die API-URL ein`
    }
  }

};
