import {Action, createReducer, on} from '@ngrx/store';
import Flow from '../../models/flow';
import {addSocialFlows, loadSocialFlows, removeSocialFlows, updateSocialFlows, updateSocialQuestion} from '../actions/social-flow.actions';
import Question from '../../models/question';


export const flowFeatureKey = 'flow';


export const initialState: Flow[] = [];

const socialFlowReducer = createReducer(
  initialState,
  on(loadSocialFlows, (state, {flows}) => flows),
  on(addSocialFlows, (state, {flow}) => [...state, flow]),
  on(removeSocialFlows, (state, {flow}) => state.filter(item => String(item._id) !== String(flow))),
  on(updateSocialFlows, (state, {flow, update}) => state.map(item => {
    if (String(item._id) === String(flow)) {
      return {...item, ...update};
    } else {
      return item;
    }
  })),
  on(updateSocialQuestion, (state, {flow, question, update}) => state.map((item: Flow) => {
    if (String(item._id) === String(flow)) {
      // @ts-ignore
      return {
        ...item,
        // @ts-ignore
        questions: [...item.questions.map((que: Question) => {
          if (que.id === question) {
            return {...que, ...update};
          }
          return que;
        })]
      };
    }
    return item;
  }))
);

export function reducer(state: Flow[] | undefined, action: Action) {
  return socialFlowReducer(state, action);
}
