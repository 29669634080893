import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../ngrx/reducers';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SharedService } from '../modules/shared/shared.service';
import { SubscriptionService } from './subscription.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  userProfile: any;
  countries: any = [];
  timezones: any = [];
  state: any = {};
  selectedBot: any = false;
  isMobile: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private toggleSubject = new Subject<void>();
  toggleSidebar$: Observable<void> = this.toggleSubject.asObservable();


  lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  constructor(
    private router: Router,
    private store: Store<State>,
    public zone: NgZone,
    private sharedService: SharedService,
    private subscriptionService: SubscriptionService,
    private networkService: NetworkService
  ) {
    store
      .select((state) => state)
      .subscribe((state) => {
        this.state = state;
      });

    fetch('/assets/countries.json')
      .then((res: any) => res.json())
      .then((res: any) => {
        this.countries = res;
      })
      .catch(console.log);
    fetch('/assets/timezones.json')
      .then((res: any) => res.json())
      .then((res: any) => {
        this.timezones = res;
      })
      .catch(console.log);
  }

  verifyAgency() {
    return (
      localStorage.getItem('agencyRole') &&
      localStorage.getItem('agencyRole') === '1012'
    );
  }

  getTimezoneOffset(timezone: string) {
    const tzone = this.timezones.find(
      (t) => t.utc.includes(timezone) || t.value === timezone
    );
    return tzone ? tzone.offset : 0;
  }

  formatDate(date, format = 'MMM Do, YYYY hh:mm A') {
    // returnd date and time
    return moment.utc(date).local().format(format);
  }

  formatDateWithoutLocate(date, format = 'MMM Do, YYYY hh:mm A') {
    return moment.utc(date).format(format);
  }

  formatDateOnly(date, format = 'MMM Do, YYYY') {
    // returns date only
    return moment.utc(date).local().format(format);
  }

  getCeilAmount(amount) {
    return Math.ceil(amount);
  }

  async getCountries() {
    return fetch('/assets/countries.json')
      .then((res: any) => res.json())
      .then((res: any) => {
        return res;
      })
      .catch(console.log);
  }

  checkAgentStatus() {
    if (this.state.profile.type === 'AGENT') {
      this.router.navigate(['/inbox']).then();
    }
  }
  async requestNotificationPermission() {
    return new Promise((resolve, reject) => {
      if ('Notification' in window) {
        if (Notification.permission === 'default') {
          Notification.requestPermission()
            .then((permission) => {
              if (permission === 'granted') {
                resolve(permission);
              } else {
                reject(permission);
              }
            })
            .catch(reject);
        } else {
          reject(Notification.permission);
        }
      } else {
        reject();
      }
    });
  }

  async getTZ() {
    return fetch('/assets/timezones.json')
      .then((res: any) => res.json())
      .then((res: any) => {
        return res;
      })
      .catch(console.log);
  }

  sendNotification(title: string, message: string) {
    if ('Notification' in window && document.visibilityState === 'hidden') {
      const notification = new Notification(title, {
        body: message,
        icon: 'https://cdn.botpenguin.com/assets/assets/icons/botpenguin-avatar.png',
      });

      notification.onclick = (event) => {
        event.preventDefault();
        (window as any).focus();
      };
    }
  }

  nFormatter(num: number, digits: number = 1) {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = this.lookup
      .slice()
      .reverse()
      .find((d) => num >= d.value);
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  }

  getCurrencyIcon(currencyCode: string) {
    switch (currencyCode) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      case 'JPY':
        return '¥';
      case 'CAD':
        return 'CA$';
      case 'AUD':
        return 'A$';
      case 'CHF':
        return 'CHF';
      case 'CNY':
        return '¥';
      case 'SEK':
        return 'kr';
      case 'NZD':
        return 'NZ$';
      case 'MXN':
        return 'Mex$';
      case 'SGD':
        return 'S$';
      case 'HKD':
        return 'HK$';
      case 'NOK':
        return 'kr';
      case 'KRW':
        return '₩';
      case 'TRY':
        return '₺';
      case 'INR':
        return '₹';
      case 'RUB':
        return '₽';
      case 'BRL':
        return 'R$';
      case 'ZAR':
        return 'R';
      case 'SAR':
        return '﷼';
      case 'AED':
        return 'د.إ';
      case 'ARS':
        return '$';
      case 'COP':
        return 'COL$';
      case 'CLP':
        return '$';
      case 'IDR':
        return 'Rp';
      case 'PHP':
        return '₱';
      case 'THB':
        return '฿';
      default:
        return '-';
    }

  }

  /**
   * @param date parameter container the value of current date & time
   * @returns
   * This function checks whether the date that is being emmited
   * is greater than 24 hours or not. If yes it will will return full date
   * otherwise will return only time.
   */
  public convertTime(date: string) {
    const isOneDayCompleted = moment().subtract(1, 'day');
    if (moment(isOneDayCompleted).isAfter(date)) {
      return moment(date)
        .local()
        .format('MMM Do YYYY, hh:mm a');
    } else {
      return moment(date).format('hh:mm a');
    }
  }

  isValidGSTIN = (gstin: string): boolean => {
    // Regular expression pattern for validating GSTIN
    const gstinPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z0-9]{1}$/;
    return gstinPattern.test(gstin);
  }

  isValidEmail = (email: string): boolean => {
    // Regular expression pattern for validating email
    const emailPattern = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    return emailPattern.test(email);
  }

  getMasterUserType(): string {
    const { type, parentRoleType } = this.state?.profile;
    return type === 'CUSTOM_USER' ? parentRoleType : type;
  }

  getIsMobileValue(): any {
    return this.isMobile.getValue();
  }

  updateIsMobileValue(newValue: any): void {
    this.isMobile.next(newValue);
  }

  // for toggle sidebar in mobile view
  triggerToggle() {
    this.toggleSubject.next();
  }

  isBotLimitExceeded(allBots) {
    // const nonDemoBots = allBots.filter((bot) => !bot.isDemoBot);
    if (
      allBots?.length >= 1 && this.subscriptionService.isBabyPlan
    ) {
      setTimeout(() => {
        this.sharedService.openUpgradeModal.next({ isUpgradeModal: true });
      }, 500);

      return true;
    } else if (
      allBots?.length >= 50 && !this.subscriptionService.isBabyPlan
    ) {
      this.sharedService.showToast('warning', '', 'Your current plan supports max 50 bots. Please contact support to increase the limit.');
      return true;
    }
    return false;
  }

  async uploadFont(event) {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append('file', event.target.files[0], event.target.files[0].name);
      this.networkService.httpFormRequest('upload', form, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }
}
