import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import AgencyDetails from 'src/appv2/models/agency-details';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/appv2/services/common.service';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { AmazonLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { Title } from '@angular/platform-browser';
import { LanguageService } from '../../services/language.service';
import { phone } from 'phone';

declare var window: any;
@Component({
  selector: 'app-new-signup',
  templateUrl: './new-signup.component.html',
  styleUrls: ['./new-signup.component.scss']
})
export class NewSignupComponent implements OnInit, OnDestroy, AfterViewInit {

  staticText: any;
  agencyMeta: {
    _id: string;
    name: string;
    logo: string;
  };

  FBUsername: string;
  render = false;
  signUpForm: FormGroup;
  loginForm: FormGroup;
  meta: any;
  referral: any = {};
  emailExists = false;
  showPassword = false;
  environment = environment;
  agencyDetails: AgencyDetails;
  signUpData: any = {
    _agency: environment.rootAgencyId,
    username: (!!localStorage.getItem('last-email') && localStorage.getItem('last-email') !== 'null')
      ? localStorage.getItem('last-email')
      : '',
    password: '',
    cpassword: '',
    number: '',
    prefix: ''
  };
  loginData: any = {
    _agency: environment.rootAgencyId,
    username: (!!localStorage.getItem('last-email') && localStorage.getItem('last-email') !== 'null')
      ? localStorage.getItem('last-email')
      : '',
    password: ''
  };
  isEmailValid = true;
  remember = !!localStorage.getItem('last-email');
  isSignUpFormSubmitted = false;
  isLogin = true;
  isSignUp = false;
  isLoginFormSubmitted = false;
  passwordStrength = {
    hasUpperCase: false,
    hasSpecialCharacter: false,
    hasRequiredLength: false
  };
  languageOptions = [];
  searchLanguage = '';
  languageDropDown = false;

  constructor(
    private router: Router,
    private store: Store<any>,
    private commonService: CommonService,
    private sharedService: SharedService,
    private cookieService: CookieService,
    private socialAuth: SocialAuthService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private languageService: LanguageService
  ) {
    this.reInitializeStaticText();

    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', JSON.stringify({ title: 'English', localValue: 'English', value: 'en' }));
    }
    this.staticText = languageService.getStaticText().authentication.newSignUp;
    try {
      this.referral = JSON.parse(sessionStorage.getItem('referral'));
    } catch (e) {
      this.referral = {};
    }

    this.checkAgency();
    const agencyDetails: any = JSON.parse(localStorage.getItem('bp_parent_meta'));
    if (agencyDetails?.meta?.brand) {
      this.title.setTitle(agencyDetails.meta.brand);
    } else if (environment.rootAgencyDomain.includes(window.location.host)) {
      this.title.setTitle(environment.appName);
    }

    activatedRoute.queryParamMap.subscribe(params => {
      const ifSignUp = params.get('signup');

      if (ifSignUp) {
        this.isSignUp = true;
        this.isLogin = false;
      }
    });
  }

  ngOnInit(): void {
    // commented because viewport meta tag is added in index.html
    // this.addViewportWidth();
    this.checkisLoggedIn();

    this.signUpForm = new FormGroup({
      username: new FormControl('', [Validators.required,
      Validators.email,
      Validators.pattern(/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/)
      ]),
      password: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required)
    });

    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required,
      Validators.email,
      Validators.pattern(/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/)
      ]),
      password: new FormControl('', Validators.required)
    });

  }

  reInitializeStaticText() {
    this.languageOptions = [
      { title: 'English', localValue: 'English', value: 'en' },
      { title: 'Hindi', localValue: 'हिंदी', value: 'hi' },
      { title: 'German', localValue: 'Deutsch', value: 'de' },
      { title: 'French', localValue: 'Français', value: 'fr' },
      { title: 'Portuguese', localValue: 'Português', value: 'pt' },
      { title: 'Spanish', localValue: 'Español', value: 'es' },
      { title: 'Italian', localValue: 'Italiano', value: 'it' },
      { title: 'Russian', localValue: 'Русский', value: 'ru' },
      { title: 'Romanian', localValue: 'română', value: 'ro' },
      { title: 'Dutch', localValue: 'Nederlands', value: 'nl' },
      { title: 'Arabic', localValue: 'العربية', value: 'ar' },
      { title: 'Turkish', localValue: 'Türkçe', value: 'tr' },
      { title: 'Hebrew', localValue: 'עברית', value: 'he' },
      { title: 'Japanese', localValue: '日本語', value: 'jp' }
    ];
  }

  defaultLanguage() {
    const countryCode = JSON.parse(localStorage.getItem('location')).countryCode || {};
    let languageOption = { title: 'English', value: 'en' };
    switch (countryCode.toLowerCase()) {
      case 'in':
        languageOption = this.languageOptions[1];
        break;
      case 'de':
        languageOption = this.languageOptions[2];
        break;
      case 'fr':
        languageOption = this.languageOptions[3];
        break;
      case 'pt':
        languageOption = this.languageOptions[4];
        break;
      case 'es':
        languageOption = this.languageOptions[5];
        break;
      case 'it':
        languageOption = this.languageOptions[6];
        break;
      case 'ru':
        languageOption = this.languageOptions[7];
        break;
      case 'ro':
        languageOption = this.languageOptions[8];
        break;
      case 'nl':
        languageOption = this.languageOptions[9];
        break;
      case 'ar':
        languageOption = this.languageOptions[10];
        break;
      case 'tr':
        languageOption = this.languageOptions[11];
        break;
      case 'he':
        languageOption = this.languageOptions[12];
        break;
      default:
        languageOption = this.languageOptions[0];
    }
    return languageOption;
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.checkAlreadyLoggedInWithFB();
    }, 200);
  }

  ngOnDestroy() {

  }

  get ifRootAgency(): boolean {
    return environment.rootAgencyDomain.includes(window.location.host);
  }

  getLanguage() {
    const lang = JSON.parse(localStorage.getItem('language'));
    if (lang && (lang !== 'undefined' || lang !== 'null')) {
      return lang.localValue || lang.title;
    }
  }

  setLanguage(language) {
    localStorage.setItem('language', JSON.stringify({ ...language } || { title: 'English', value: 'en' }));
    window.location.reload();
  }

  getLanguageList() {
    return this.languageOptions.filter(language => (language.title || '').match(new RegExp(this.searchLanguage, 'gi')));
  }
  checkAgency() {

    if (environment.rootAgencyDomain.includes(window.location.host)) {
      this.agencyMeta = {
        _id: environment.rootAgencyId,
        name: 'BotPenguin',
        logo: '/assets/images/logo-v2.svg'
      };
      return true;
    } else {
      this.sharedService.loader('start');
      this.authService.getAgencyInfo(window.location.host)
        .then(data => {
          this.agencyMeta = {
            _id: data._id,
            name: data.name,
            logo: data.meta.logo
          };

          localStorage.setItem('bp_parent_meta', JSON.stringify(data));
          this.title.setTitle(data.meta.brand);
          document.getElementById('favicon').setAttribute('href', data.meta.logo);
        })
        .catch(error => console.log(error))
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  async checkEmailExists() {
    // if (this.ifRootAgency) {

    let email = '';
    this.isLogin
      ? email = this.loginForm.get('username').value.toLowerCase()
      : email = this.signUpForm.get('username').value.toLowerCase();

    try {
      const exists = await this.authService.checkEmail(email);
      if (exists) {
        this.isLogin = true;
        this.loginForm.get('username').setValue(email);
      } else {
        this.isLogin = false;
        this.signUpForm.get('username').setValue(email);
      }
      return exists;
    } catch (error) {
      return false;
    }
    // }
  }

  isValidPassword() {
    const password = this.signUpForm.get('password').value;
    const checkUpperCase = /(?=.*[A-Z])/;
    const checkSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.passwordStrength.hasSpecialCharacter = checkSpecialChar.test(password);
    this.passwordStrength.hasUpperCase = checkUpperCase.test(password);
    this.passwordStrength.hasRequiredLength = (password && password.length >= 6) ? true : false;
    if (this.passwordStrength.hasRequiredLength && this.passwordStrength.hasUpperCase && this.passwordStrength.hasSpecialCharacter) {
      return true;
    }
    return false;
  }


  signUp = async (form: FormGroup) => {

    this.isSignUpFormSubmitted = true;
    if (!this.signUpForm.valid || !this.isValidPhoneNumber() || !this.isValidPassword()) {
      return;
    }

    if (!this.signUpData.prefix) {
      return this.sharedService.showToast('error', '', 'Please select country code');
    }

    const { username, password, phoneNumber } = form.value;
    if (!this.sharedService.isPhoneNumberValid(this.signUpData.prefix, phoneNumber)) {
      return this.sharedService.showToast('error', '', 'Please enter a valid phone number');
    }

    this.meta = JSON.parse(localStorage.getItem('location')) || {};
    this.meta.offset = this.commonService.getTimezoneOffset(this.meta.timeZone);

    this.signUpData = {
      ...this.signUpData,
      username,
      password,
      number: phoneNumber,
      cpassword: password,
      status: 'INACTIVE',
      verified: false,
      _agency: this.ifRootAgency
        ? environment.rootAgencyId
        : this.agencyMeta._id
    };

    if (this.ifRootAgency) {
      this.authService.checkEmail(username)
        .then(exists => {
          if (exists) {
            this.isLogin = true;
            this.loginForm.get('username').setValue(username);
            this.emailExists = true;
          } else {
            // this.signUpData = {
            //   ...this.signUpData,
            //   username,
            //   password,
            //   number: phoneNumber,
            //   cpassword: password,
            //   status: 'ACTIVE',
            //   verified: true,
            //   _agency: this.ifRootAgency
            //   ? environment.rootAgencyId
            //   : this.agencyMeta._id
            // };
            if (form.valid) {
              this.sharedService.loader('start');
              localStorage.setItem('last-email', this.signUpData.username);

              window.grecaptcha.ready(async () => {
                const captchaToken = await window.grecaptcha.execute(environment.recaptchaSiteKey, { action: 'signup' })
                  .then((token: string) => token);
                this.authService.loginUser({
                  ...this.signUpData,
                  meta: this.meta,
                  remember: this.remember,
                  username: this.signUpData.username.toLowerCase().trim(),
                  referral: this.referral,
                  captchaToken
                })
                  .then((response: any) => {
                    if (response.access_token) {
                      if (environment.production) {
                        (window as any).gr('track', 'conversion', { email: response.email });
                      }
                      localStorage.setItem('token', response.access_token);
                      this.updateLeadSquared()
                        .catch(console.log)
                        .finally(() => {
                          window.location.href = '/bot-builder/onboarding/select-use-case';
                        });
                    }

                    if (response.status === 'INACTIVE') {
                      return this.router.navigate(['/confirm-email'], { queryParams: { id: response._parent } });
                    }
                  })
                  .catch((error) => {
                    this.sharedService.showToast('error', '', (error || error.message).match(new RegExp('invalid password', 'gi'))
                      ? 'The password you have entered is incorrect' : error || error.message);
                  })
                  .finally(() => this.sharedService.loader('stop'));

              });
            }
          }
        });
    } else {
      this.sharedService.loader('start');
      localStorage.setItem('last-email', this.signUpData.username);

      window.grecaptcha.ready(async () => {
        const captchaToken = await window.grecaptcha.execute(environment.recaptchaSiteKey, { action: 'signup' })
          .then((token: string) => token);
        this.authService.loginViaAgency({
          ...this.signUpData,
          meta: this.meta,
          remember: this.remember,
          username: this.signUpData.username.toLowerCase().trim(),
          referral: this.referral,
          captchaToken
        })
          .then((response: any) => {
            if (response.access_token) {
              if (environment.production) {
                (window as any).gr('track', 'conversion', { email: response.email });
              }
              localStorage.setItem('token', response.access_token);
              this.updateLeadSquared()
                .catch(console.log)
                .finally(() => {
                  window.location.href = '/bot-builder/onboarding/select-use-case';
                });
            }

            if (response.status === 'INACTIVE') {
              return this.router.navigate(['/confirm-email'], { queryParams: { id: response._parent } });
            }
          })
          .catch((error) => {
            this.sharedService.showToast('error', '', (error || error.message).match(new RegExp('invalid password', 'gi'))
              ? 'The password you have entered is incorrect' : error || error.message);
          })
          .finally(() => this.sharedService.loader('stop'));

      });

      console.log('Can not signup on agency other than root');
    }
  }

  login(form: FormGroup) {
    this.isLoginFormSubmitted = true;
    if (!form.valid) {
      return;
    }
    const { username, password } = form.value;
    this.meta = JSON.parse(localStorage.getItem('location')) || {};
    this.meta.offset = this.commonService.getTimezoneOffset(this.meta.timeZone);


    this.loginData = {
      ...this.loginData,
      username,
      password,
      meta: this.meta,
      remember: this.remember,
      referral: this.referral,
      _agency: this.ifRootAgency
        ? environment.rootAgencyId
        : this.agencyMeta._id
    };

    if (this.ifRootAgency) {
      if (form.valid) {
        this.sharedService.loader('start');
        this.remember
          ? localStorage.setItem('last-email', this.loginData.username.trim())
          : localStorage.setItem('last-email', '');
        this.authService.loginUser(this.loginData)
          .then((response: any) => {
            if (response.access_token) {
              localStorage.setItem('token', response.access_token);
              this.updateLeadSquared()
                .catch(console.log)
                .finally(() => {
                  if (response.type === 'AGENCY') {
                    window.location.href = '/agency/dashboard';
                    localStorage.setItem('bp-is-agency', 'true');
                  } else if (response.type === 'AGENT') {
                    window.location.href = '/inbox/leads';
                  } else {
                    window.location.href = '/home';
                  }
                });
            } else if (response.status === 'INACTIVE') {
              return this.router.navigate(['/confirm-email'], { queryParams: { id: response._parent } });
            }
          })
          .catch((error) => {
            this.sharedService.showToast('error', '', true
              ? 'Invalid email and passwords' : error || error.message);
          })
          .finally(() => this.sharedService.loader('stop'));
      }
    } else {
      this.sharedService.loader('start');
      this.remember
        ? localStorage.setItem('last-email', this.loginData.username.trim())
        : localStorage.setItem('last-email', '');

      this.authService.loginViaAgency(this.loginData)
        .then((response: any) => {
          if (response.access_token) {
            localStorage.setItem('token', response.access_token);
            if (response.type === 'AGENCY') {
              window.location.href = '/agency/dashboard';
              localStorage.setItem('bp-is-agency', 'true');
            } else if (response.type === 'AGENT') {
              window.location.href = '/inbox/leads';
            } else {
              window.location.href = '/home';
            }
          } else if (response.status === 'INACTIVE') {
            return this.router.navigate(['/confirm-email'], { queryParams: { id: response._parent } });
          }
        })
        .catch((error) => {
          this.sharedService.showToast('error', '', (error || error.message).match(new RegExp('invalid password', 'gi'))
            ? 'The password you have entered is incorrect' : error || error.message);
        })
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  forgotPassword() {
    return this.router.navigate(['/forgot-password']);
  }

  checkAlreadyLoggedInWithFB() {
    (window as any).FB.getLoginStatus((response) => {

      if (response.status === 'connected') {
        (window as any).FB.api('/me', (response2) => {
          this.FBUsername = response2.name;
        });
      }
    });

  }

  signInWithFB(): void {
    if (this.ifRootAgency) {
      (window as any).FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          if (response.authResponse) {
            this.proceedWithFB(response);
          }
        } else {
          (window as any).FB.login((response2) => {
            if (response2.status === 'connected') {
              this.proceedWithFB(response2);
            }
          }, { scope: 'public_profile,email' });
        }
      });
    }

  }

  proceedWithFB(response: any) {

    this.sharedService.loader('start');

    this.meta = JSON.parse(localStorage.getItem('location')) || {};
    this.meta.offset = this.commonService.getTimezoneOffset(this.meta.timeZone);

    this.authService.socialLoginUser({
      type: 'facebook',
      token: response.authResponse.accessToken,
      meta: this.meta,
      _agency: environment.rootAgencyId,
      referral: this.referral
    })
      .then((res: any) => {
        localStorage.setItem('token', res.access_token);
        this.updateLeadSquared()
          .catch(console.log)
          .finally(() => {
            if (res?.onboard) {
              if ((window as any).gr) {
                (window as any).gr('track', 'conversion', { email: res.email });
              }
              const location: any = localStorage.getItem('location');
              if (location && (JSON.parse(location).country_code === 'IN' || JSON.parse(location).country_code === 'GB')) {
                return window.location.href = '/home';
              }
              window.location.href = '/bot-builder/onboarding/select-use-case';
            } else {
              window.location.href = '/home';
            }
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.sharedService.loader('stop');
      });
  }

  signInWithGoogle() {
    if (this.ifRootAgency) {

      /**
       * @author - suraj
       * @description - google one tap login and signup code
       */
      window.google.accounts.id.initialize({
        client_id: environment.googleClientId,
        prompt_parent_id: 'parent_id',
        callback: this.handleGgOneTap.bind(this)
      });
      window.google.accounts.id.prompt((data) => {


        if (data.getNotDisplayedReason() === 'suppressed_by_user') {
          this.cookieService.delete('g_state');
          this.signInWithGoogle();
        } else if (data.getNotDisplayedReason() === 'opt_out_or_no_session') {

          this.sharedService.loader('start');

          this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID)
            .then((res: any) => {

              this.meta = JSON.parse(localStorage.getItem('location')) || {};
              this.meta.offset = this.commonService.getTimezoneOffset(this.meta.timeZone);

              return this.authService.socialLoginUser({
                type: 'google',
                token: res.idToken,
                meta: this.meta,
                _agency: environment.rootAgencyId,
                referral: this.referral
              });
            })
            .then((response: any) => {
              localStorage.setItem('token', response.access_token);
              this.updateLeadSquared()
                .catch(console.log)
                .finally(() => {
                  if (response.onboard) {
                    (window as any).gr('track', 'conversion', { email: response.email });
                    const location: any = localStorage.getItem('location');
                    if (location && (JSON.parse(location).country_code === 'IN' || JSON.parse(location).country_code === 'GB')) {
                      return window.location.href = '/home';
                    }
                    window.location.href = '/bot-builder/onboarding/select-use-case';
                  } else {
                    window.location.href = '/home';
                  }
                });
            })
            .catch((error) => console.log(error))
            .finally(() => {
              this.sharedService.loader('stop');
            });
        } else {
          if (data.getNotDisplayedReason()) {
            this.sharedService.showToast('warning', '', data.getNotDisplayedReason());
          }
        }
      });
    }
  }



  handleGgOneTap(resp) {
    this.meta = JSON.parse(localStorage.getItem('location')) || {};
    this.meta.offset = this.commonService.getTimezoneOffset(this.meta.timeZone);
    this.authService.socialLoginUser({
      type: 'google',
      token: resp.credential,
      meta: this.meta,
      _agency: environment.rootAgencyId,
      referral: this.referral
    })
      .then((response: any) => {
        localStorage.setItem('token', response.access_token);
        this.updateLeadSquared()
          .catch(console.log)
          .finally(() => {
            if (response.onboard) {
              (window as any).gr('track', 'conversion', { email: response.email });
              window.location.href = '/bot-builder/onboarding/select-use-case';
            } else {
              window.location.href = '/home';
            }
          });
      })
      .finally(() => this.sharedService.loader('stop'));
  }

  toggleRememberMe() {
    this.remember = !this.remember;
  }

  chooseCountry(e) {
    this.signUpData.prefix = e.dial_code || '91';
  }

  isValidPhoneNumber() {
    if (String(this.signUpForm.get('phoneNumber').value).length > 6
      && String(this.signUpForm.get('phoneNumber').value).length <= 15) {
      return true;
    }
    return false;
  }

  validatePhoneNumber(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  checkisLoggedIn() {
    const hasToken = localStorage.getItem('token');
    if (hasToken) {
      this.authService.getProfile()
        .then((profile: any) => {
          if (this.commonService.getMasterUserType() === 'AGENCY') {
            return this.router.navigate(['/agency/dashboard']);
          } else {
            return this.router.navigate(['/home']);
          }
        })
        .catch();
    } else {
      this.render = true;
    }
  }

  // commented because viewport meta tag is added in index.html
  // addViewportWidth() {
  //   const metaViewPortTag = document.createElement('meta');
  //   metaViewPortTag.name = 'viewport';
  //   metaViewPortTag.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
  //   document.getElementsByTagName('head')[0].appendChild(metaViewPortTag);
  // }

  updateLeadSquared() {
    while (!localStorage.getItem('token')) {
      console.log('waiting...');
    }
    if (!environment.rootAgencyDomain.includes(window.location.host)) {
      return Promise.resolve() as Promise<any>;
    }
    return fetch(environment.baseUrl + 'customer/leadsquared-lead-update', {
      method: 'POST',
      headers: JSON.parse(JSON.stringify({
        'Content-Type': 'application/json',
        Credentials: 'include',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      })),
      body: JSON.stringify({
        event: 'lastLogin',
        payload: { value: new Date().toISOString().substring(0, 10) + ' ' + new Date().toISOString().substring(11, 19) }
      })
    });
    // .catch();
  }
}
