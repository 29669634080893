export const notification = {
  notification: 'الإشعارات',
  allNotifications: 'جميع الإشعارات',
  general: 'عام',
  settings: 'الإعدادات',
  newChatRequest: {
    youHaveANewChatRequest: 'لديك طلب دردشة جديد!!!',
    youHaveTransferredChatRequest: 'لديك طلب دردشة تم نقله!!!',
    acceptRequest: 'قبول الطلب',
    discardRequest: 'رفض الطلب'
  },
  notificationList: {
    name: 'الاسم',
    message: 'الرسالة',
    noDataFound: {
      notification: 'الإشعار',
      noNotificationsFound: 'لا يوجد إشعارات!',
    }
  },
  notificationSetting: {
    userMessage: 'رسالة المستخدم',
    receiveNotificationsWhenUserSendMessage: 'استقبل الإشعارات عندما يرسل المستخدم أي رسالة',
    liveChatRequest: 'طلب الدردشة الحية',
    receiveNotificationsWhenUserRequestLiveChat: 'استقبل الإشعارات عندما يطلب المستخدم دردشة حية',
    newLead: 'عميل جديد',
    receiveNotificationsWhenNewLeadCreated: 'استقبل الإشعارات عند إنشاء عميل جديد',
    assignedOnly: 'المخصص فقط',
    allUsers: 'جميع المستخدمين',
    customizeWhichNotificationsYouWantToRecieve: 'قم بتخصيص الإشعارات التي ترغب في استقبالها',
    youCanCustomizeSettingForTheNotifications: 'يمكنك تخصيص إعدادات الإشعارات',
    receiveNotifications: 'استقبال الإشعارات',
    desktopNotifications: 'إشعارات سطح المكتب',
    mobilePushNotifications: 'إشعارات الجوال',
    discard: 'رفض',
    update: 'تحديث',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'إرسال إشعارات رسالة جديدة عبر سطح المكتب',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'إرسال إشعارات رسالة جديدة عبر الجوال',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'إرسال إشعارات عميل جديد عبر سطح المكتب',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'إرسال إشعارات عميل جديد عبر الجوال',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'إرسال إشعارات دردشة حية عبر سطح المكتب',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'إرسال إشعارات دردشة حية عبر الجوال',
      TOOLTIP_RECEIVED_NOTIFICATION: 'اختر أي رسائل ترغب في تلقي إشعارات لها'
    }
  }
};
