import {createAction, props} from '@ngrx/store';

export const loadSocialTemplates = createAction(
    '[Templates] Load Social Templates',
    props<{ templates: any[] }>()
);

export const addSocialTemplates =  createAction(
    '[Templates] Add Social Templates',
    props<{ template: any }>()
);

export const removeSocialTemplates =  createAction(
    '[Templates] Delete Social Templates',
    props<{ template: any }>()
);

export const modifySocialTemplate = createAction(
    '[Group] Modify Social Template',
    props<{ template: string, update: any }>()
  );
