export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Bine ai venit la BotPenguin!',
      login: 'Autentificare',
      youAreAllSet: 'Ești pregătit! Puteți utiliza inboxul WhatsApp de pe pagina detaliilor despre leaduri.',
      placeholders: {
        enterEmail: 'Introduceți adresa de email',
        enterPassword: 'Introduceți parola'
      }
    }
  }
};
