import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-google-redirect',
  templateUrl: './google-redirect.component.html',
  styleUrls: ['./google-redirect.component.scss']
})
export class GoogleRedirectComponent implements OnInit {

  staticText: any;
  constructor(
    public router: ActivatedRoute,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().botBuilder.integrations.allIntegration.providers.googleRedirect;
   }

  ngOnInit(): void {
    this.router.queryParamMap.subscribe((param: any) => {
      if (param.params.code) {
        localStorage.setItem('code', param.params.code);
        if (param.params['accounts-server']) {
          localStorage.setItem('accountServer', param.params['accounts-server']);
        }
        window.opener.location.reload(true);
        window.close();
      } else if (param.params.error) {
        window.opener.location.reload(true);
        window.close();
      }
    });
  }


}
