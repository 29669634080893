export const notification = {
  notification: 'Notifications',
  allNotifications: 'Toutes les notifications',
  general: 'Général',
  settings: 'Paramètres',
  newChatRequest: {
    youHaveANewChatRequest: 'Vous avez une nouvelle demande de chat !!!',
    youHaveTransferredChatRequest: 'Vous avez transféré la demande de chat !!!',
    acceptRequest: 'Accepter la requête',
    discardRequest: 'Rejeter la demande',
  },
  notificationList: {
    name: 'Nom',
    message: 'Message',
    noDataFound: {
      notification: 'Notification',
      noNotificationsFound: 'Aucune notification trouvée!',
    }
  },
  notificationSetting: {
    userMessage: 'Message de l`utilisateur',
    receiveNotificationsWhenUserSendMessage: 'Recevoir des notifications chaque fois qu`un utilisateur envoie un message',
    liveChatRequest: 'Demande de chat en direct',
    receiveNotificationsWhenUserRequestLiveChat: 'Recevoir des notifications chaque fois qu`une demande de chat en direct de l`utilisateur',
    newLead: 'Nouvelle tête',
    receiveNotificationsWhenNewLeadCreated: 'Recevoir des notifications chaque fois que de nouveaux prospects sont créés',
    assignedOnly: 'Attribué uniquement',
    allUsers: 'Tous les utilisateurs',
    customizeWhichNotificationsYouWantToRecieve: 'Personnaliser les notifications que vous souhaitez recevoir',
    youCanCustomizeSettingForTheNotifications: 'Vous pouvez personnaliser le paramètre pour les notifications',
    receiveNotifications: 'Recevoir des notifications',
    desktopNotifications: 'Notifications de bureau',
    mobilePushNotifications: 'Notifications push mobiles',
    discard: 'Jeter',
    update: 'Mise à jour',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Envoyer des notifications de nouveau message sur le bureau',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Envoyer des notifications de nouveau message sur mobile',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Envoyer des notifications de nouveau plomb sur le bureau',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Envoyer des notifications de nouveau plomb sur mobile',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Envoyer des notifications de chat en direct sur le bureau',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Envoyer des notifications de chat en direct sur mobile',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Choisissez pour quel messages souhaitez-vous être informé',
    }
  }
};
