import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bp-button',
  templateUrl: './bp-button.component.html',
  styleUrls: ['./bp-button.component.scss']
})
export class BpButtonComponent implements OnInit {

  @Input() label = 'Send';

  @Input() type: 'primary' | 'secondary' = 'primary';

  @Input() height = '40px';

  @Input() width = '100px';

  @Input() disabled = false;

  @Input() borderRadius = '0.20rem';

  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickButton() {
    this.clickEvent.emit('Event');
  }


}
