export const subscription = {
  subscriptions: 'الاشتراكات',
  plan: 'الخطة',
  whatsappPlan: 'خطة واتساب',
  addOns: 'الإضافات',
  billingDetails: 'تفاصيل الفوترة',
  invoices: 'الفواتير',
  wa1Reminder: 'تذكير WA 1',
  wa2Reminder: 'تذكير WA 2',
  waRenewApi: 'تجديد WA API',
  waCheckUsageApi: 'فحص استخدام WA API',
  waResetUsageApi: 'إعادة تعيين استخدام WA API',
  waExceedUsageApi: 'تجاوز استخدام WA API',
  waGetBotsInThisAccount: 'الحصول على البوتات في هذا الحساب',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'لدينا أسعار أكثر ملاءمة لجميع احتياجاتك.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'خطة واتساب',
    TOOLTIP_ADDS_ON_HEADING: 'شراء حزم إضافية للرسائل والبوت الخاص',
    TOOLTIP_ADDS_ON_MESSAGE: 'إذا وصلت إلى حدود الرسائل الشهرية الخاصة بك، يمكنك شراء حزم إضافية.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'تحقق من تفاصيل اشتراكك الحالي.',
    TOOLTIP_HORIZONTAL_BAR: 'تحقق من أسعارنا الشهرية أو السنوية من 3K إلى 500K رسالة عن طريق تحريك الشريط من اليسار إلى اليمين.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'قم بتحديث تفاصيل الفوترة الخاصة بك لإصدار الفواتير.',
    TOOLTIP_INVOICES_HEADING: 'تحقق من الفواتير السابقة الخاصة بك.',
    TOOLTIP_INVOICES_ACTION: 'انقر لاستلام الفاتورة في حساب بريدك الإلكتروني.'
  },
  currentPlan: {
    seeHowToUse: 'شاهد كيفية الاستخدام',
    help: 'المساعدة',
    downgradeRequest1: 'لقد قمت بتقديم طلب للتخفيض من خطتك إلى',
    downgradeRequest2: 'رسائل. يرجى ملاحظة أن خطتك ستتم تخفيضها بحلول نهاية دورة الفوترة الحالية',
    downgradeRequest3: 'يمكنك الاستمتاع ب',
    downgradeRequest4: 'الخدمات حتى ذلك الحين.',
    downgradeRequestMaster1: 'لقد قدمت طلبًا للتخفيض من خطتك ',
    downgradeRequestMaster2: ' إلى',
    downgradeRequestMaster3: ' محادثة. يرجى ملاحظة أن خطتك ستتم تخفيضها بحلول نهاية دورة الفوترة الحالية',
    downgradeRequestMaster4: 'يمكنك الاستمتاع ب',
    downgradeRequestMaster5: 'الخدمات حتى ذلك الحين.',
    active: 'نشط',
    inactive: 'غير نشط',
    month: 'شهر',
    year: 'سنة',
    messagePack: 'حزمة الرسائل',
    king: 'King',
    baby: 'Baby',
    whatsapp: 'WhatsApp',
    youAreOn: 'أنت على',
    plan: 'خطة',
    totalMessagesLimit: 'الحد الإجمالي للرسائل',
    liveAgentLimit: 'الحد الإجمالي للوكلاء الحية',
    perMonth: '/شهر',
    agents: 'وكيل(وكلاء)',
    messagesAddOn: 'رسائل إضافية',
    botWhitelabels: 'بوت وايت ليبل',
    totalAddOnMessages: 'إجمالي الرسائل الإضافية',
    basePrice: 'السعر الأساسي',
    gst: 'GST',
    totalPrice: 'السعر الإجمالي',
    totalAddOnPrice: 'السعر الإجمالي للرسائل الإضافية',
    whitelabelPrice: 'سعر الوايت ليبل',
    startedOn: 'بدأ في',
    nextDueOn: 'الاستحقاق التالي في',
    addOnExpiry: 'انتهاء الرسائل الإضافية',
    website: 'الموقع',
    facebook: 'فيسبوك',
    telegram: 'تيليجرام',
  },
  invoice: {
    invoiceNumber: 'رقم الفاتورة',
    date: 'تاريخ',
    invoiceAmount: 'مبلغ الفاتورة',
    planAmount: 'مبلغ الخطة',
    plan: 'الخطة',
    messages: 'الرسائل',
    action: 'الإجراء',
    nA: 'غ/م',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'انقر لاستلام الفاتورة في حساب بريدك الإلكتروني',
      clickToDownloadInvoice: 'انقر لتحميل الفاتورة بصيغة PDF',
    }
  },
  messageAddOn: {
    validity: 'الصلاحية',
    kingPlanHeader: '👑 King Plan',
    youAreOnThe: 'أنت على',
    asOfNowAndWillGet: ' حتى الآن وستحصل على',
    messages: 'رسائل',
    inThisPlan: 'في هذه الخطة.',
    youCanBuyAdditionalMessaes: 'يمكنك شراء رسائل إضافية حسب احتياجات عملك.',
    plan: 'الخطة',
    baby: 'Baby',
    king: 'King',
    get: 'احصل على',
    babyPlan: 'خطة Baby',
    upgradePlan: 'ترقية الخطة',
    asOfNow: ' حتى الآن.',
    pleaseUpgradeToKingPlan: 'يرجى الترقية إلى خطة King',
    toEnableAddOnsAndWhitelabelPacks: 'لتمكين الإضافات وحزم الوايت ليبل.',
    messageAddOnPacks: 'حزم رسائل إضافية',

    ifYouHaveReachedYourMonthlyMessageLimit: 'إذا وصلت إلى حدود الرسائل الشهرية، قم بشراء باقات ' + ' إضافية واستمتع بخدمات دون انقطاع.',
    pleaseSelectNumberOfExpectedMessages: 'يرجى تحديد عدد الرسائل المتوقعة',
    addOnsValidity: 'صلاحية الإضافات',
    currentBillingCycle: 'دورة الفوترة الحالية',
    oneYear: 'سنة واحدة',
    summary: 'ملخص',
    messageCreditsWithKingPlan: 'رصيد الرسائل مع خطة King',
    messageCreditsWith: 'رصيد الرسائل مع خطة ',
    withPlan: '',
    billingCycle: 'دورة الفوترة',
    payableAmount: 'المبلغ المستحق',
    oneTimePayment: 'مرة واحدة',
    plusTaxes: ' + الضرائب',
    buyNow: 'شراء الآن',
    whiteLabelChatWindow: 'نافذة المحادثة الخاصة بالوايت ليبل',
    noBotsLeftToWhiteLabel: 'لا توجد بوتات متبقية للوايت ليبل.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'يرجى تحديد البوتات التي ترغب في تسميتها بالوايت ليبل.',
    selectBots: 'تحديد البوتات',
    bot: 'بوت',
    bots: 'بوتات',
    toBeWhiteLabelled: 'لتسميتها بالوايت ليبل',
    remove: 'إزالة ',
    brandNameFromChatWindow: ' اسم العلامة التجارية من نافذة الدردشة.',
    willBeValidTill: 'ستكون صالحة حتى',
    ourYearlyPrice: 'سعرنا السنوي',
    directWhiteLabel: 'وايت ليبل مباشر',
    whiteLabelledBots: 'بوتات مسماة بالوايت ليبل',
    nextRenewalColon: 'التجديد التالي: ',
    optOutRequested: 'طلب إلغاء الاشتراك تم تقديمه',
    contactAgency: 'الاتصال بالوكالة',
    optOut: 'إلغاء الاشتراك',
    removeWhiteLabel: 'إزالة الوايت ليبل',
    numberOfPacks: 'عدد البوتات',
    amount: 'المبلغ',
    taxes: 'الضرائب',
    totalAmount: 'المبلغ الإجمالي',
    numOfBots: 'عدد البوتات',
    select: 'تحديد',
    selectTheValidity: 'تحديد الصلاحية',
    messageCount: 'عدد الرسائل',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'تفاصيل الدفع',
    cardNumber: 'رقم البطاقة ',
    cvv: 'CVV ',
    // cardNumber: 'رقم البطاقة',
    month: 'الشهر',
    year: 'السنة',
    save: 'حفظ',
    add: 'إضافة',
    edit: 'تحرير',
    disclaimer: 'تنصيح:',
    disclaimerDescription1: 'ستكون تفاصيل طريقة الدفع أعلاه سارية المفعول فقط للمستخدمين خارج الهند.',
    disclaimerDescription2: 'نحن لا نقوم بحفظ تفاصيل بطاقتك معنا.' +
      ' في حالة رغبتك في تغيير تفاصيل بطاقتك، سيتم تغييرها مباشرةً من خلال بوابة الدفع.',
    billingAddress: 'عنوان الفوترة',
    companyName: 'اسم الشركة ',
    country: 'البلد ',
    selectCountry: 'حدد البلد',
    stateOrRegion: 'الولاية/المنطقة ',
    streetAddress: 'عنوان الشارع ',
    gstIn: 'GSTIN ',
    postalCode: 'الرمز البريدي ',
    cancel: 'إلغاء',
    proceedToPay: 'المتابعة للدفع',
    company: 'الشركة',
    state: 'الولاية',
    masterCard: 'Master Card',
    yourCompanyName: 'اسم شركتك',
    yourCountry: 'بلدك',
    yourRegion: 'منطقتك',
    yourPostalCode: 'الرمز البريدي الخاص بك',
    plusTaxes: ' + الضرائب',
    dropDown: {
      month: 'الشهر *',
      year: 'السنة *',
    },
    placeholders: {
      enterCardNumber: 'أدخل رقم البطاقة',
      cvv: 'CVV',
      enterYourCompanyName: 'أدخل اسم شركتك',
      enterYourStateOrRegion: 'أدخل ولايتك/منطقتك',
      enterYourStreetAddress: 'أدخل عنوان الشارع الخاص بك',
      enterYourGSTIN: 'أدخل GSTIN الخاص بك',
      enterPostalCode: 'أدخل الرمز البريدي',
    }
  },
  plans: {
    viewDetailedPricing: 'عرض تسعير مفصل',
    seeHowToUse: 'راجع كيفية الاستخدام',
    help: 'مساعدة',
    weHaveGotAPricingPlanPerfectForYou: 'لدينا خطة تسعير مثالية بالنسبة لك',
    weBelieve: 'نحن نعتقد أن ',
    shouldBeAccessibleToAllCompanies: 'يجب أن تكون متاحة لجميع الشركات بغض النظر عن الحجم.',
    monthly: 'شهريًا',
    yearly: 'سنويًا',
    twoMonthsFree: '(شهرين مجانًا)',
    babyPlan: 'Baby Plan',
    freeForever: 'مجاناً للأبد',
    messagesPerMonth: 'الرسائل شهرياً',
    currentPlan: 'الخطة الحالية',
    freePlan: 'خطة مجانية',
    kingPlan: 'خطة الملك',
    perYear: 'سنويًا',
    plan: 'خطة',
    toThe: ' إلى',
    plusTaxes: ' + الضرائب',
    perMonth: 'شهريًا',
    upgradePlan: 'ترقية الخطة',
    changePlan: 'تغيير الخطة',
    currentlyActive: 'نشط حاليًا',
    emperorPlan: 'Emperor Plan (Enterprise)',
    sendAsManyMessagesAsYouWant: 'أرسل كمية غير محدودة من الرسائل كما تريد',
    contactUs: 'تواصل معنا',
    features: 'المميزات',
    areYouSureYouWantToShiftFrom: 'هل أنت متأكد أنك تريد الانتقال من',
    messages: 'الرسائل',
    planQuestionMark: 'الخطة؟',
    downgradeToFreePlan: 'الانتقال إلى الخطة المجانية',
    goToBabyPlan: 'الانتقال إلى خطة بيبي',
    thankYouForChoosingBotPenguin: 'شكرًا لاختيارك BotPenguin',
    bots: 'الروبوتات',
    facebookPages: 'صفحات الفيسبوك',
    teamMember: 'عضو الفريق',
    dataStorage: 'تخزين البيانات',
    conditionalFlow: 'تدفق مشروط',
    liveChat: 'محادثة مباشرة',
    bookAppointments: 'حجز المواعيد',
    csvExport: 'تصدير CSV',
    emailAndCallSupport: 'الدعم عبر البريد الإلكتروني والاتصال',
    googleCalendarIntegrations: 'تكامل تقويم Google',
    zohoCrmIntegrations: 'تكامل Zoho CRM',
    reportsAndAnalytics: 'التقارير والتحليلات',
    removeBotPenguinBranding: 'إزالة علامة BotPenguin',
    oneBot: '1 bot',
    twoKMessagesPerMonth: '2,000 رسالة/شهر',
    oneFacebookPage: '1 Facebook Page',
    oneTeamMember: '1 Team Member',
    thirtyDaysOfDataStorage: '30 يومًا من تخزين البيانات',
    unlimitedBot: 'Unlimited Bot',
    threeTo500kMessagesPerMonth: 'من 3000 إلى 500000 رسالة/شهر',
    tenFacebookPages: '10 Facebook Pages',
    threeTeamMembers: '3 Team Members',
    nintyDaysOfDataStorage: '90 يومًا من تخزين البيانات',
    unlimitedMessages: 'رسائل غير محدودة',
    unlimitedFacebookPages: 'صفحات فيسبوك غير محدودة',
    unlimitedTeamMembers: 'أعضاء فريق غير محدودين',
    unlimitedDataStorage: 'تخزين البيانات غير محدود',
    youAreHere: `أنت هنا!`,
    detailedPricing: {
      kingsplan: 'خطة الملك',
      elementary: 'بسيطة',
      solutionType: 'نوع الحلا',
      saas: 'SaaS',
      fbpage1fb: 'صفحة FB 1',
      perMonth100: '100 في الشهر',
      enterprise: 'مؤسسة',
      saasOnPremises: 'SaaS في الموقع',
      aiChatbotFeatures: 'ميزات الدردشة الذكية',
      dataSourceForTraning: 'مصدر البيانات للتدريب',
      smartFeatures: 'ميزات ذكية',
      liveChat: 'الدردشة الحية',
      day30: '30 يومًا',
      day90: '90 يومًا',
      landSquared: 'لاند سكويرد',
      doItYourself: 'افعله بنفسك',
      unifiedInbox: 'صندوق الوارد الموحد',
      platforms: 'المنصات',
      websiteChatbot: 'شات بوت الموقع',
      FacebookMessengerChatbot: 'شات بوت فيسبوك ماسنجر',
      Telegram: 'تليجرام',
      conversationalLandingPage: 'صفحة الهبوط الحوارية',
      integrations: 'التكاملات',
      reportsAndAnalytics: 'التقارير والتحليلات',
      customerSupport: 'دعم العملاء',
      security: 'الأمان',
      bots: 'الروبوتات',
      babyPlan: 'خطة الرضيع',
      kingPlan: 'خطة الملك',
      emperorPlan: 'خطة الإمبراطور',
      noofBots: 'عدد الروبوتات',
      one: '1 (واحد)',
      unlimitedBots: 'روبوتات غير محدودة',
      noOfWebsite: 'عدد المواقع الإلكترونية',
      unlimitedWebsites: 'مواقع غير محدودة',
      unlimitedMessages: 'رسائل غير محدودة',
      messagesSend: 'رسائل مُرسلة',
      perMonth2000: '2000 في الشهر',
      upto500000perMonth: 'تصل إلى 500000 في الشهر',
      facebookMessenger: 'فيسبوك ماسنجر',
      unlimitedPages: 'صفحات غير محدودة',
      telegramNumbers: 'أرقام التليجرام',
      unlimited: 'غير محدود',
      whatsappNumbers: 'أرقام واتساب',
      subscribersLeads: 'المشتركين/الزبائن',
      createSupportTeam: 'إنشاء فريق الدعم للدردشة الحية',
      oneMember: 'عضو واحد',
      threeMembers: '3 أعضاء',
      conversationHistory: 'تاريخ المحادثة',
      thirtyDays: '30 يومًا',
      ninetyDays: '90 يومًا',
      chatbotDesigning: 'تصميم الشات بوت',
      weAreHereToHelp: 'نحن هنا للمساعدة',
      weTakeCareEndToEnd: 'نعتني من البداية إلى النهاية',
      multipleLanguages: 'لغات متعددة',
      conditionalFlow: 'تدفق مشروط',
      removeBotPenguinBranding: 'إزالة علامة بوت بينغوين',
      gptMessages: 'رسائل GPT',
      aiChatbot: 'شات بوت الذكاء الاصطناعي',
      chatGPTIntegration: 'تكامل الشات GPT',
      configureAIChatbotPersonality: 'ضبط شخصية الشات بوت الذكاء الاصطناعي',
      trainChatbotOnYourOwnData: 'تدريب الشات بوت على البيانات الخاصة بك',
      trainChatbotOnWebPages: 'تدريب الشات بوت على صفحات الويب',
      trainChatbotOnMultipleFiles: 'تدريب الشات بوت على ملفات متعددة',
      trainChatbotOnFAQs: 'تدريب الشات بوت على الأسئلة الشائعة',
      dataTrainingStorage: 'تخزين بيانات التدريب',
      useChatGPTModel4_3_5Turbo: 'استخدام نموذج الشات GPT 4/3.5 توربو',
      uploadMultipleFilesForChatbotTraining: 'تحميل ملفات متعددة لتدريب الشات بوت',
      naturalLanguageProcessing: 'معالجة اللغة الطبيعية',
      naturalLanguageUnderstanding: 'فهم اللغة الطبيعية',
      answerLength: 'طول الجواب',
      chatTone: 'نغمة الدردشة',
      multiLanguage: 'متعدد اللغات',
      answerFormatting: 'تنسيق الجواب',
      customPrompt: 'موجه مخصص',
      integrateBotPenguinLLM: 'تكامل بوت بينغوين LLM',
      integrateOwnCustomLLM: 'تكامل LLM المخصص الخاص بك',
      periodicallySyncDataSources: 'مزامنة مصادر البيانات بشكل دوري',
      aiBasedSuggestedQuestions: 'أسئلة مقترحة بناءً على الذكاء الاصطناعي',
      chooseVisibilityForParticularWebpageSitemap: 'اختيار الرؤية لصفحة الويب أو خريطة الموقع معينة',
      chooseKnowledgeMix: 'اختيار مزيج المعرفة:\nقاعدة المعرفة الخاصة بك\nمعرفة الشات GPT\n"معرفة الشات GPT + قاعدة المعرفة الخاصة بك"',
      faqExtractionFromFileAndURL: 'استخراج الأسئلة الشائعة من الملف وعنوان URL',
      analyticsToImproveAI: 'تحليلات لتحسين الذكاء الاصطناعي',
      exportQuestionFlow: 'تصدير تدفق الأسئلة',
      sessionManagement: 'إدارة الجلسة',
      collectFeedbackOnChatbotResponses: 'جمع ملاحظات حول ردود الشات بوت',
      smartAIFollowUpQuestions: 'أسئلة متابعة ذكية للذكاء الاصطناعي',
      trainOnYourNotionWorkspaceData: 'تدريب على بيانات مساحة العمل الخاصة بك في Notion',
      trainOnYoutubeVideo: 'تدريب على فيديو YouTube',
      trainOnCustomVideoUpload: 'تدريب على تحميل الفيديو المخصص',
      rssFeed: 'تغذية RSS',
      googleDrive: 'جوجل درايف',
      dropBox: 'دروب بوكس',
      ipAndRegionBlocking: 'حظر العناوين IP والمناطق',
      emailAndMobileValidation: 'التحقق من البريد الإلكتروني والجوال',
      appointmentsBooking: 'حجز المواعيد',
      humanHandover: 'نقل يدوي إلى إنسان',
      botHandover: 'نقل إلى روبوت',
      handoverBetweenAgents: 'نقل بين الوكلاء',
      mobileAppsForAgents: 'تطبيقات الجوال للوكلاء',
      roleBasedAccessControls: 'ضوابط الوصول على أساس الأدوار',
      apiIntegrationOnChatFlows: 'تكامل واجهة برمجة التطبيقات على تدفقات الدردشة',
      liveChatWidget: 'حاجة الدردشة الحية',
      typingIndicator: 'مؤشر الكتابة',
      liveVisitorsDetails: 'تفاصيل الزوار الحيين',
      multipleSimultaneousChat: 'الدردشة المتزامنة المتعددة',
      liveTypingView: 'عرض الكتابة الحية',
      ruleBasedChatRouting: 'توجيه الدردشة على أساس القواعد',
      chatAutoAssignments: 'تعيين تلقائي للدردشة',
      efficientOmnichannelInterface: 'واجهة أومني كانال فعالة',
      quickReplies: 'ردود سريعة',
      unifiedCustomerProfile: 'ملف العميل الموحد',
      visitorStats: 'إحصائيات الزوار',
      addContactsFromWhatsapp: 'إضافة جهات الاتصال من WhatsApp',
      applyFilterAndSortConversations: 'تطبيق التصفية وترتيب المحادثات',
      seeChatLogs: 'رؤية سجلات الدردشة',
      assignNotesAndChatTags: 'تعيين الملاحظات والعلامات للدردشة',
      directCallsToLeadsWithTwilio: 'المكالمات المباشرة إلى الزبائن باستخدام Twilio',
      customerSegmentation: 'تقسيم الزبائن',
      exportLeadsInCSV: 'تصدير الزبائن بتنسيق CSV',
      leadNotificationsByEmail: 'إشعارات الزبائن عبر البريد الإلكتروني',
      website: 'الموقع الإلكتروني',
      wordPress: 'ووردبريس',
      whatsApp: 'واتساب',
      facebook: 'فيسبوك',
      telegram: 'تليجرام',
      shopify: 'شوبيفاي',
      autoTriggeringOnMobileDesktop: 'التشغيل التلقائي على الهواتف المحمولة وأجهزة الكمبيوتر',
      hideBotOnSpecificPages: 'إخفاء الروبوت على صفحات معينة',
      imageSharing: 'مشاركة الصور',
      readyToUseChatFlowTemplates: 'قوالب تدفق الدردشة جاهزة للاستخدام',
      fileSharing: 'مشاركة الملفات',
      customDesignForChatWindow: 'تصميم مخصص لنافذة الدردشة',
      facebookMessengerBot: 'شات بوت فيسبوك ماسنجر',
      facebookSubheading: 'عنوان فرعي فيسبوك',
      telegramSubheading: 'عنوان فرعي تليجرام',
      conversationalLangdingPageSubheading: 'عنوان فرعي لصفحة الهبوط الحوارية',
      integrationsSubheading: 'عنوان فرعي للتكاملات',
      multipleSmartFlows: 'تدفقات ذكية متعددة',
      dripCampaigns: 'حملات التسرب',
      broadcastFBMessages: 'بث رسائل فيسبوك',
      multipleFacebookBotWidgets: 'أدوات روبوت فيسبوك متعددة',
      facebookAutoReplyOnComments: 'رد تلقائي على تعليقات فيسبوك',
      facebookECommerce: 'التجارة الإلكترونية في فيسبوك',
      broadcastAnalytics: 'تحليلات البث',
      telegramMessengerBot: 'شات بوت تليجرام ماسنجر',
      readyToUseTemplates: 'قوالب جاهزة للاستخدام',
      groupBroadcastTelegramMessages: 'بث رسائل تليجرام للمجموعة',
      multipleTelegramBotWidgets: 'أدوات روبوت تليجرام متعددة',
      conversationalLandingPageBot: 'شات بوت صفحة الهبوط الحوارية',
      smartReplies: 'ردود ذكية',
      interactiveAndCustomizableUI: 'واجهة مستخدم تفاعلية وقابلة للتخصيص',
      leadManagement: 'إدارة الزبائن',
      easyToInstallAndShare: 'سهلة التثبيت والمشاركة',
      agileCRM: 'Agile CRM',
      capsule: 'كبسولة',
      insightly: 'إنسايتلي',
      nimble: 'نيمبل',
      leadsquared: 'ليد سكويرد',
      chakra: 'شاكرا',
      plivo: 'بليفو',
      helpcrunch: 'هيلب كرنش',
      getresponse: 'جيت ريسبونس',
      supportbee: 'دعم بي',
      pipeline: 'الأنبوب',
      zendesk: 'زندسك',
      joget: 'جوجيت',
      firmao: 'فيرماو',
      kylas: 'كيلاس',
      close: 'إغلاق',
      firstpromoter: 'فيرست بروموتر',
      zingtree: 'زينجتري',
      liveagent: 'الوكيل الحي',
      ontraport: 'أونترابورت',
      frontapp: 'فرونتاب',
      webhoper: 'ويبهوبر',
      engagebay: 'إنجيباي',
      helpshift: 'هيلبشفت',
      copper: 'كوبر',
      autopilot: 'أوتوبايلوت',
      flowlu: 'فلوولو',
      freshdesk: 'فريش ديسك',
      salesflare: 'سيلزفلير',
      teamgate: 'تيمجيت',
      shipway: 'شيبواي',
      googleSheets: 'جوجل شيتس',
      googleCalendar: 'تقويم جوجل',
      googleAnalytics: 'تحليلات جوجل',
      supportTicketingSystem: 'نظام تذاكر الدعم',
      zapier: 'زابير',
      pabbly: 'بابلي',
      hubSpot: 'هاب سبوت',
      gitHub: 'جيت هاب',
      salesforce: 'سيلزفورس',
      inChatAudioAndVideoCall: 'مكالمة صوتية ومرئية في الدردشة (Twilio/Agora)',
      courierTracking: 'تتبع البريد',
      emailIntegration: 'تكامل البريد الإلكتروني',
      webhooks: 'السنانير',
      schedulers: 'الجداول الزمنية',
      paymentGateways: 'بوابات الدفع (Stripe & RazorPay)',
      enterpriseIntegrations: 'تكاملات المؤسسات',
      customIntegration: 'تكامل مخصص',
      shopifyIntegration: 'تكامل شوبيفاي',
      totalNumberOfMessages: 'إجمالي عدد الرسائل',
      totalNumberOfLeads: 'إجمالي عدد الزبائن',
      weeklyReportBotwise: 'تقرير أسبوعي حسب الروبوت',
      averageAgentResponeTime: 'متوسط وقت استجابة الوكيل',
      visitorTimingReport: 'تقرير توقيت الزوار',
      customerSegmentBasedReports: 'تقارير استنادًا إلى تقسيم الزبائن',
      tagBasedReports: 'تقارير استنادًا إلى الوسوم',
      leadGenerationReport: 'تقرير تكوين الزبائن',
      supportOnWhatsApp: 'الدعم على واتساب',
      knowledgeBase: 'قاعدة المعرفة',
      onboardingAndTraining: 'التدريب والتعليم',
      callAndEmailSupport: 'الدعم عبر المكالمات والبريد الإلكتروني',
      freeChatbotCreation: 'إنشاء شات بوت مجاني',
      customerSuccessOfficer: 'مسؤول نجاح الزبون',
      secureConnection2048BitSSL: 'اتصال آمن ببت 2048',
      securityAssessment: 'تقييم الأمان',
      dataCenterInUSOrEU: 'مركز بيانات في الولايات المتحدة أو الاتحاد الأوروبي',
      twoStepVerification: 'التحقق من خطوتين',
      accessRestrictionsBasedOnIPs: 'قيود الوصول استنادًا إلى عناوين IP',
      onDemandAuditLog: 'سجل تدقيق حسب الطلب',
      bannedVisitorsList: 'قائمة الزوار المحظورين',
      customAPIRate: 'معدل API مخصص',
      trustedDomains: 'النطاقات الموثوقة',
      Domains: 'النطاقات الموثوقة',
      mb100: 'ميغابايت 100',
      gb1: '1 جيجابايت',
      codingCurious: 'متحمس للبرمجة'
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'خطتنا المجانية',
      TOOLTIP_KING_PLAN: 'أكثر خطتنا شهرة',
      TOOLTIP_EMPEROR_PLAN: 'خطة مخصصة لرسائل غير محدودة وبيانات غير محدودة وأعضاء في الفريق غير محدودين',
      TOOLTIP_CONTACT_US: 'تواصل معنا عبر واتساب أو البريد الإلكتروني على contact@botpenguin.com لمعرفة المزيد حول خطتنا المخصصة',
      TOOLTIP_UPGRADE: 'قم بترقية خطتك الحالية للحصول على ميزات إضافية',
      TOOLTIP_MONTHLY: 'حدد دورة الدفع الشهرية',
      TOOLTIP_YEARLY: 'حدد دورة الدفع السنوية للاستفادة من الخصومات',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'الانتقال إلى خطة البيبي',
    freeForever: 'مجانًا إلى الأبد',
    features: 'الميزات',
    currentPlan: 'الخطة الحالية',
    changePlan: 'تغيير الخطة',
    currentlyActive: 'نشط حاليًا',
    seeHowToUse: 'شاهد كيفية الاستخدام',
    weHaveGotPricingPerfectForYou: 'لدينا خطة تسعير مثالية بالنسبة لك',
    weBelieve: 'نحن نعتقد أن ',
    shouldBeAccessibleToAllCompanies: 'يجب أن تكون متاحة لجميع الشركات، بغض النظر عن الحجم.',
    monthly: 'شهريًا',
    yearly: 'سنويًا',
    twoMonthsFree: '(شهران مجانًا)',
    kingPlan: 'King Plan',
    perYear: 'سنويًا',
    perMonth: 'شهريًا',
    conversationsPerMonth: 'المحادثات شهريًا',
    upgradePlan: 'ترقية الخطة',
    emperorPlan: 'Emperor Plan (Enterprise)',
    haveAsManyConversationsAsYouNeed: 'لديك العديد من المحادثات كما تحتاج',
    contactUs: 'اتصل بنا',
    metaConversationChargesAreAdditional: 'تكاليف المحادثات الفعلية إضافية وتدفع مباشرة من قبلك لشركة Meta، استنادًا إلى استخدامك. لا نقوم بفرض أي رسوم إضافية أو رسوم وساطة.',
    viewDetailedPricing: 'عرض تسعير مفصل',
    conversationPricingModelUsedByMeta: 'نموذج التسعير للمحادثات المستخدمة من قبل Meta',
    whatsappBusinessPlatformConversationsFallInto: 'تنقسم محادثات منصة واتساب بزنس إلى فئتين تتم تسعيرهما بشكل مختلف',
    userInitiatedConversation: 'محادثة بدءها المستخدم',
    userInitiatedConversationDescription: 'محادثة تبدأ ردًا على رسالة من المستخدم. في كل مرة ترد فيها الشركة على المستخدم خلال نافذة خدمة العملاء لمدة 24 ساعة، سيتم ربط ذلك بمحادثة بدءها المستخدم. يمكن للشركات إرسال رسائل بتنسيق حر ضمن نافذة خدمة العملاء لمدة 24 ساعة.',
    businessInitiatedConversation: 'محادثة بدءها الشركة',
    businessInitiatedConversationDescription: 'محادثة تبدأ عندما ترسل الشركة رسالة للمستخدم خارج نافذة خدمة العملاء لمدة 24 ساعة. تتطلب الرسائل التي تبدأ محادثة بدءها الشركة قالب رسالة.',
    areYouSureYouWantTo: 'هل أنت متأكد أنك تريد الانتقال من',
    conversations: ' محادثات ',
    plan: ' خطة ',
    toThe: ' إلى ',
    metaPricingMarkup: 'تسعير Meta Markup',
    numberOfChatFlows: 'عدد تدفقات الدردشة',
    greenTickApplication: 'تطبيق العلامة الخضراء',
    extraPhoneNumberLinking: 'ربط أرقام الهواتف الإضافية',
    teamMembers: 'أعضاء الفريق',
    dataStorage: 'تخزين البيانات',
    conditionalFlow: 'تدفق شرطي',
    liveChat: 'الدردشة المباشرة',
    smartInbox: 'صندوق الوارد الذكي',
    whatsappTemplates: 'قوالب WhatsApp',
    customerSegments: 'مجموعات العملاء',
    broadcastAndDripCampaigns: 'الحملات الإذاعية وحملات التنقيط',
    multipleLanguages: 'عدة لغات',
    bookAppointments: 'حجز المواعيد',
    csvContactsExport: 'تصدير جهات الاتصال بتنسيق CSV',
    googleCalendarIntegrations: 'تكامل تقويم Google',
    nativeApplications: '60+ تكاملًا مباشرًا',
    whatsappSupport: 'دعم واتساب',
    emailAndCallSupport: 'الدعم عبر البريد الإلكتروني والمكالمات الهاتفية',
    customerIntegrations: 'تكامل العملاء',
    nlpAndNluCapabilities: 'NLP and NLU capabilities',
    chatGPTBot: 'Chat GPT chatbot',
    customerSuccessOfficer: 'مسؤول نجاح العملاء',
    noMarkupTransparency: 'شفافية بدون هامش',
    upto500kPerMonth: 'ما يصل إلى 500,000 في الشهر',
    beyond500k: 'أكثر من 500,000',
    unlimited: 'غير محدود',
    oneTeamMember: 'عضو في الفريق واحد',
    unlimitedTeamMember: 'أعضاء الفريق غير المحدودين',
    nintyDaysOfDataStorage: '90 يومًا من تخزين البيانات',
    unlimitedDataStorage: 'تخزين البيانات غير المحدود',
    downgradeToFreePlan: 'التخفيض إلى الخطة المجانية',
    detailedPricing: {
      onDemandAuditLog: 'سجل التدقيق عند الطلب',
      accessRestrictionsBasedOnIPs: 'قيود الوصول بناءً على عناوين IP',
      averageAgentResponeTime: 'متوسط وقت استجابة الوكيل',
      perMonth100: '100 في الشهر',
      solutionType: 'نوع الحلا',
      saas: 'SaaS',
      saasOnPremises: 'SaaS على الأماكن',
      emperorPlan: 'خطة الإمبراطور',
      babyPlan: 'خطة الطفل',
      kingsplan: 'خطة الملوك',
      braodcastsAndDrips: 'بث وتسرب',
      thirtyDays: '30 يومًا',
      ninetyDays: '90 يومًا',
      oneMember: 'عضو واحد',
      threeMembers: '3 أعضاء',
      one: '1 (واحد)',
      unlimitedMessages: 'رسائل غير محدودة',
      unlimitedBots: 'روبوتات غير محدودة',
      messagesSend: 'إرسال الرسائل',
      upto500000perMonth: 'حتى 500,000 في الشهر',
      unlimited: 'غير محدود',
      onlyAdmin: 'المشرف فقط',
      mb100: '100 ميغابايت',
      gb1: '1 غيغابايت',
      doItYourself: 'افعلها بنفسك',
      weAreHereToHelp: 'نحن هنا للمساعدة',
      weTakeCareEndToEnd: 'نحن نعتني من البداية إلى النهاية',
      numberOfBots: 'عدد الروبوتات',
      conversations: 'المحادثات',
      gptMessages: 'رسائل GPT',
      whatsappNumbers: 'أرقام واتساب',
      subscribersLeads: 'المشتركين / العملاء المحتملين',
      createSupportTeamForLiveChat: 'إنشاء فريق الدعم للدردشة المباشرة',
      conversationHistory: 'سجل المحادثة',
      chatbotDesigning: 'تصميم الدردشة الآلية',
      multipleLanguages: 'لغات متعددة',
      conditionalFlow: 'تدفق مشروط',
      whatsappTemplates: 'قوالب واتساب',
      restartChatFlowTriggers: 'مشغلات إعادة تشغيل تدفق الدردشة',
      multipleWhatsAppWidgets: 'أدوات واتساب متعددة',
      importWhatsappContacts: 'استيراد جهات الاتصال واتساب',
      whatsappQuickReply: 'الرد السريع على واتساب',
      whatsappSmartInbox: 'صندوق الوارد الذكي واتساب',
      whatsappEcommerceCatalogs: 'كتالوجات واتساب للتجارة الإلكترونية',
      whatsappBroadcast: 'بث واتساب',
      whatsappBroadcastAnalytics: 'تحليلات البث على واتساب',
      dripCampaigns: 'حملات التسرب',
      customerSegmentation: 'تقسيم العملاء',
      keywordBasedResponse: 'الرد استنادًا إلى الكلمات الرئيسية',
      whatsappShopManagement: 'إدارة متجر واتساب',
      aiChatbot: 'دردشة آلية ذكاء صناعي',
      chatGPTIntegration: 'تكامل ChatGPT',
      configureAIChatbotPersonality: 'ضبط شخصية دردشة آلية ذكاء صناعي',
      trainChatbotOnYourOwnData: 'تدريب الدردشة الآلية على بياناتك الخاصة',
      trainChatbotOnWebPages: 'تدريب الدردشة الآلية على صفحات الويب',
      trainChatbotOnMultipleFiles: 'تدريب الدردشة الآلية على ملفات متعددة',
      trainChatbotOnFAQs: 'تدريب الدردشة الآلية على الأسئلة الشائعة',
      dataTrainingStorage: 'تخزين تدريب البيانات',
      useChatGPTModel4or35Turbo: 'استخدام نموذج ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'تحميل ملفات متعددة لتدريب الدردشة الآلية',
      naturalLanguageProcessing: 'معالجة اللغة الطبيعية',
      naturalLanguageUnderstanding: 'فهم اللغة الطبيعية',
      answerLength: 'طول الإجابة',
      chatTone: 'نبرة الدردشة',
      multiLanguage: 'لغات متعددة',
      answerFormatting: 'تنسيق الإجابة',
      customPrompt: 'موجه مخصص',
      whatsAppSessionManagement: 'إدارة جلسة واتساب (رسائل الخمول وإعادة تشغيل التدفق)',
      integrateBotPenguinLLM: 'تكامل BotPenguin LLM',
      integrateOwnCustomLLM: 'تكامل LLM المخصص الخاص',
      periodicallySyncDataSources: 'مزامنة مصادر البيانات بشكل دوري',
      aiBasedSuggestedQuestions: 'أسئلة مقترحة استنادًا إلى الذكاء الاصطناعي',
      chooseVisibilityForParticularWebpageSitemap: 'اختيار الرؤية لصفحة الويب / موقع الويب معين',
      chooseKnowledgeMix: 'اختيار خليط المعرفة: قاعدة المعرفة ChatGPT + المعرفة الخاصة بك',
      faqExtractionFromFileAndURL: 'استخراج الأسئلة الشائعة من الملف وعنوان URL',
      analyticsToImproveAI: 'تحليلات لتحسين الذكاء الاصطناعي',
      exportQuestionFlow: 'تصدير تدفق الأسئلة',
      collectFeedbackOnChatbotResponses: 'جمع التعليقات حول استجابات الدردشة الآلية',
      smartAIFollowUpQuestions: 'أسئلة متابعة ذكاء صناعي ذكي',
      trainOnYourNotionWorkspaceData: 'التدريب على بيانات مساحة العمل Notion الخاصة بك',
      trainOnYoutubeVideo: 'التدريب على فيديو YouTube',
      trainOnCustomVideoUpload: 'التدريب على تحميل الفيديو المخصص',
      rssFeed: 'تغذية RSS',
      googleDrive: 'قرص Google',
      dropBox: 'الإسقاط',
      humanHandover: 'نقل الإنسان',
      botHandover: 'نقل الروبوت',
      handoverBetweenAgents: 'نقل بين الوكلاء',
      emailAndMobileValidation: 'التحقق من البريد الإلكتروني والجوال',
      mobileAppsForAgents: 'تطبيقات الجوال للوكلاء',
      appointmentBooking: 'حجز المواعيد',
      roleBasedAccessControls: 'ضوابط الوصول على أساس الأدوار',
      ruleBasedChatRouting: 'التوجيه بناءً على القواعد في الدردشة',
      liveVisitorsDetails: 'تفاصيل الزائرين الحيين',
      multipleSimultaneousChats: 'محادثات متعددة متزامنة',
      visitorStats: 'إحصائيات الزائرين',
      addContactsFromWhatsApp: 'إضافة جهات الاتصال من واتساب',
      applyFilterAndSortConversations: 'تطبيق مرشح وفرز المحادثات',
      seeChatLogs: 'رؤية سجلات الدردشة',
      quickReplies: 'ردود سريعة',
      unifiedCustomerProfile: 'ملف العميل الموحد',
      assignNotesAndChatTags: 'تخصيص ملاحظات وعلامات الدردشة',
      exportLeadsInCSV: 'تصدير العملاء المحتملين إلى CSV',
      tagContacts: 'وضع علامات على جهات الاتصال',
      customAttributes: 'سمات مخصصة',
      markStatusOfConversations: 'وضع علامة على حالة المحادثات',
      assignConversationToAgents: 'تخصيص المحادثة للوكلاء',
      leadNotificationsByEmail: 'إشعارات العملاء المحتملين عبر البريد الإلكتروني',
      directCallsToLeadsWithTwilio: 'المكالمات المباشرة إلى العملاء المحتملين باستخدام Twilio',
      whatsAppTemplates: 'قوالب واتساب',
      promotionalMessageFrequencyControl: 'مراقبة تكرار الرسائل الترويجية',
      whatsAppWidget: 'قطعة واتساب',
      whatsAppQR: 'رمز الاستجابة السريعة لواتساب',
      whatsAppLink: 'رابط واتساب',
      campaignScheduler: 'جدول الحملة',
      customTriggers: 'مشغلات مخصصة',
      customCampaigns: 'حملات مخصصة',
      repeatPurchaseReminders: 'تذكيرات بالمشتريات المتكررة',
      campaignOpenRateTracking: 'تتبع معدل الفتح للحملة',
      paymentCollectionOnWhatsApp: 'جمع الدفعات على واتساب',
      agileCRM: 'نظام إدارة العلاقات العملاء المتجدد',
      capsule: 'كبسولة',
      insightly: 'إنسايتلي',
      nimble: 'نمبل',
      leadsquared: 'ليد سكويرد',
      chakra: 'شاكرا',
      plivo: 'بلايفو',
      helpcrunch: 'هيلب كرانش',
      getresponse: 'احصل على رد',
      supportbee: 'سبورت بي',
      pipeline: 'أنابيب',
      zendesk: 'زندسك',
      joget: 'جوجيت',
      firmao: 'فيرماو',
      kylas: 'كايلاس',
      close: 'إغلاق',
      firstpromoter: 'المروج الأول',
      zingtree: 'زينجتري',
      liveagent: 'وكيل حي',
      ontraport: 'أونترابورت',
      frontapp: 'تطبيق الجبهة',
      webhoper: 'ويبهوبر',
      engagebay: 'إنجيباي',
      helpshift: 'شيفت المساعدة',
      copper: 'نحاس',
      autopilot: 'الطائرة التلقائية',
      flowlu: 'فلو لو',
      freshdesk: 'فريش ديسك',
      salesflare: 'فلير المبيعات',
      teamgate: 'بوابة الفريق',
      shipway: 'سفن الطريق',
      googleSheets: 'جداول Google',
      googleCalendar: 'تقويم Google',
      googleAnalytics: 'تحليلات Google',
      supportTicketingSystem: 'نظام تذاكر الدعم',
      zapier: 'زابير',
      pabbly: 'بابلي',
      hubSpot: 'هاب سبوت',
      gitHub: 'جيت هاب',
      salesforce: 'سيلز فورس',
      inChatAudioAndVideoCall: 'مكالمة صوتية وفيديو في الدردشة (Twilio/Agora)',
      courierTracking: 'تتبع البريد',
      emailIntegration: 'تكامل البريد الإلكتروني',
      webhooks: 'ويب هوكس',
      schedulers: 'جداول',
      paymentGateways: 'بوابات الدفع (Stripe و RazorPay)',
      enterpriseIntegrations: 'تكاملات المؤسسات',
      customIntegration: 'تكامل مخصص',
      freeWhatsAppAPIApprovalAndSetup: 'الموافقة وإعداد واتساب API مجاني',
      developerAPIsAccess: 'وصول مطوري واجهات برمجة التطبيقات',
      customRateLimit: 'حد السرعة المخصص',
      totalNumberOfLeads: 'إجمالي عدد العملاء المحتملين',
      averageAgentResponseTime: 'متوسط وقت استجابة الوكيل',
      visitorTimingReport: 'تقرير توقيت الزائرين',
      customerSegmentBasedReports: 'تقارير مستندة إلى قطاع العملاء',
      tagBasedReports: 'تقارير مستندة إلى العلامات',
      leadGenerationReport: 'تقرير توليد العملاء',
      supportOnWhatsApp: 'الدعم على واتساب',
      knowledgeBase: 'قاعدة المعرفة',
      onboardingAndTraining: 'توجيه وتدريب',
      callAndEmailSupport: 'الدعم عبر المكالمات والبريد الإلكتروني',
      freeChatbotCreation: 'إنشاء دردشة آلية مجانية',
      customerSuccessOfficer: 'ضابط نجاح العملاء',
      secureConnection: 'اتصال آمن بتقنية SSL بطول 2048 بت',
      securityAssessment: 'تقييم الأمان',
      dataCenterLocation: 'موقع مركز البيانات (الولايات المتحدة أو الاتحاد الأوروبي)',
      twoStepVerification: 'التحقق من خطوتين',
      accessRestrictions: 'قيود الوصول',
      auditLog: 'سجل التدقيق عند الطلب',
      bannedVisitorsList: 'قائمة الزوار المحظورين',
      customAPIRate: 'سعر الواجهة البرمجية المخصص',
      trustedDomains: 'النطاقات الموثوق بها',
      elementary: 'ابتدائي',
      bots: 'الروبوتات',
      unlockWhatsapp: 'إلغاء قفل واتساب',
      aiChatbotFeatures: 'ميزات الدردشة الآلية ذات الذكاء الاصطناعي',
      dataSourceForTraining: 'مصدر البيانات للتدريب',
      smartFeatures: 'ميزات ذكية',
      liveChat: 'الدردشة المباشرة',
      smartInbox: 'صندوق الوارد الذكي',
      broadcastsAndDrips: 'البث والتسرب',
      integrations: 'التكاملات',
      development: 'التطوير',
      reportsAndAnalytics: 'التقارير والتحليلات',
      customerSupport: 'دعم العملاء',
      security: 'الأمان',
      whatsappWidgets: 'أدوات واتساب',
      campaignSchedular: 'جدول الحملة',
      repeatPurchaseRemainder: 'تذكير بالمشتريات المتكررة',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'خطتنا المجانية',
      TOOLTIP_KING_PLAN: 'خطتنا الأكثر شعبية',
      TOOLTIP_EMPEROR_PLAN: 'خطة مخصصة لرسائل غير محدودة وبيانات غير محدودة وأعضاء فريق غير محدودين',
      TOOLTIP_CONTACT_US: 'تواصل معنا عبر WhatsApp أو البريد الإلكتروني على contact@botpenguin.com لمعرفة المزيد حول خطتنا المخصصة',
      TOOLTIP_UPGRADE: 'قم بترقية خطتك الحالية للحصول على مزايا إضافية',
      TOOLTIP_MONTHLY: 'اختر دورة الدفع الشهرية',
      TOOLTIP_YEARLY: 'اختر دورة الدفع السنوية للاستفادة من تخفيضات السعر',
    }
  }
};

