import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { LanguageService } from 'src/appv2/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  agencyMeta: {
    _id: string;
    name: string;
    logo: string;
  };
  staticText: any;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().authentication.resetPassword;
    this.checkAgency();
   }

  ngOnInit(): void {
  }

  get ifRootAgency(): boolean {
    return environment.rootAgencyDomain.includes(window.location.host);
  }

  checkAgency() {

    if (environment.rootAgencyDomain.includes(window.location.host)) {

      this.agencyMeta = {
        _id: environment.rootAgencyId,
        name: 'BotPenguin',
        logo: '/assets/images/logo-v2.svg'
      };
      return true;
    } else {
      this.sharedService.loader('start');
      this.authService.getAgencyInfo(window.location.host)
        .then(data => {

          this.agencyMeta = {
            _id: data._id,
            name: data.name,
            logo: data.meta.logo
          };
        })
        .catch(error => console.log(error))
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  backToSignUp() {
    return this.router.navigate(['/authentication']);
  }

}
