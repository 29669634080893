export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Suchen',
    }
  },
  bpTable: {
    loadMore: 'Mehr laden',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Suchland',
    }
  },
  createNew: {
    continue: 'Weitermachen',
  },
  datePicker: {
    chooseADate: 'Wählen Sie ein Datum',
  },
  dateRangePicker: {
    choosePeriod: 'Wählen Sie Zeitraum',
    today: 'Heute',
    lastWeek: 'Letzte Woche',
    lastMonth: 'Im vergangenen Monat',
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Bitte geben Sie die Nummer an, die Sie zum Testen verwenden möchten',
    resendOtp: 'Senden Sie OTP erneut',
    enterOtp: 'Geben Sie OTP ein',
    verifyOtp: 'Überprüfen Sie OTP',
    sendOtp: 'Senden Sie OTP',
    cancel: 'Stornieren',
    placeholders: {
      phone: 'Telefon*',
      enterOtp: 'Geben Sie OTP* ein*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Bitte wählen Sie Ihren Grund aus',
    cancel: 'Stornieren',
    submit: 'Einreichen!',
    giveFeedback: 'Feedback geben',
    ratings: 'Bewertungen',
    reasonBehindYourDecision: 'Grund für Ihre Entscheidung',
    shareYourExperience: 'Teile deine Erfahrung',
    reason1: 'Grund1',
    reason2: 'Grund2',
  },
  feedbackModal: {
    paymentIsInProgress: 'Die Zahlung ist im Gange',
    pleaseDoNotCloseThisWindow: 'Bitte schließen Sie dieses Fenster nicht',
    placeholders: {
      name: 'Name',
      email: 'Email*',
      phone: 'Telefon*',
      pleaseShareYourUseCase: 'Bitte teilen Sie Ihre Usecase mit',
    }
  },
  layout: {
    sidebar: {
      notifications: 'Benachrichtigungen',
      myAccount: 'Mein Konto',
      logout: 'Ausloggen',
      goOnline: 'Online gehen',
      manage: 'Verwalten',
      deactivate: 'Deaktivieren',
      activate: 'aktivieren Sie',
      delete: 'Löschen',
      home: 'Heim',
      bots: 'Bots',
      inbox: 'Posteingang',
      analytics: 'Analyse',
      developer: 'Entwickler',
      team: 'Team',
      subscriptions: 'Abonnements',
      more: 'Mehr',
      users: 'Benutzer',
      partnerTeam: 'Partner-Team',
      createNewBot: 'Neuen Bot erstellen',
      dashboard: 'Armaturenbrett',
      customers: 'Kunden',
      plans: 'Pläne',
      activities: 'Aktivitäten',
      feedbacks: 'Feedbacks',
      configurations: 'Konfigurationen',
      copy: 'Kopieren',
      yourFriendWillMissYou: 'Dein Freund wird dich vermissen',
      rateYourBotCreationExperience: 'Bewerten Sie Ihre Bot -Kreationserfahrung',
      whatMadeYouDeleteYourBot: 'Was hat Sie dazu gebracht, Ihren Bot zu löschen?',
      iCreatedByMistake: 'Ich habe versehentlich erstellt',
      iWantToCreateAnotherBot: 'Ich möchte einen weiteren Bot erstellen',
      couldNotFindFeaturesThatINeeded: ',',
      uiWasHardToNavigate: 'UI war schwer zu navigieren',
      iDonotWantToTell: ',',
      other: 'Andere',
      weWillDoOurBestToMakeAutomationFun: 'Wir werden unser Bestes tun, um die Automatisierung für alle Spaß und einfach zu machen.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Umschalten, um Live -Chat -Anfragen zuzulassen oder zu blockieren',
        TOOLTIP_NOTIFICATION: 'Alle Benachrichtigungen',
        TOOLTIP_MY_ACCOUNT: 'Ihr Profil- und Passwortsicherheit',
        TOOLTIP_LOGOUT: 'Melden Sie sich vom Dashboard ab',
      },
      modal: {
        logout: 'Ausloggen',
        botDeleteFeedbackForm: 'Bot Feedback -Formular löschen',
        areYouSureToLogOut: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
        yesIWillBeBack: 'Ja! Ich komme wieder',
        deleteBot: 'Bot löschen',
        areYouSureToDeleteBot: 'Sind Sie sicher, dass Sie diesen Bot löschen möchten?',
        cancel: 'Stornieren',
        saveAndDelete: 'Speichern und löschen',
      }
    }
  },
  modal: {
    cancel: 'Stornieren',
    yesDelete: 'Ja, löschen!',
    continue: 'Weiter'
  },
  unsavedChanges: {
    title: 'Ungespeicherte Änderungen',
    description: 'Es könnten ungespeicherte Änderungen vorliegen. Sind Sie sicher, dass Sie gehen möchten?'
  },
  notify: {
    youHave: 'Du hast',
    new: ' neu',
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Wir empfehlen Ihnen, Ihr Firmenlogo auszuwählen. Fügen Sie ein PNG- oder JPG -quadratisches Bild unter 1 MB hinzu.',
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Hoppla! Babyplan bietet nur einen kostenlosen Bot. Bitte upgrade auf King Plan, um mehr Bots zu erstellen.',
    noIAmGood: 'Nein, ich bin gut',
    iWantToUpgrade: 'Ich möchte upgraden',
    oops: 'Hoppla! ',
    planOfferOnlyOneFreeBot: ' BIETET NUR EINEN KOSTENLOSEN Bot. Bitte wechseln Sie auf den ',
    planToCreateMoreBots: ' , um mehr Bots zu erstellen.'
  },
  webPreview: {
    preview: ' Vorschau',
    botsMessages: 'Bots Nachrichten',
    bot: 'Bot',
    eleven36AM: '11:36 Uhr',
    usersReply: 'Antwort des Benutzers',
    landingPageBot: 'Landing Page Bot',
    chatbot: 'Chatbot',
    chat: 'Plaudern',
    placeholders: {
      typeYourAnswer: 'Gib deine Antwort ein',
    }
  },
  paymentLink: {
    discardPayment: 'Stornieren',
    completePayment: 'Abgeschlossen',
    importantUpdate: 'Wichtiges Update',
    accountUpdate: 'Kontoaktualisierung',
    youHave: 'Du hast',
    exceededTheMessagesLimit: 'Das Nachrichtenlimit wurde überschritten',
    ofYour: 'von deinen',
    planAnd: 'Plan und',
    botsAreNoLongerActive: 'Bots sind nicht mehr aktive',
    onYourWebsite: 'auf deinem.',
    youHaveEitherIncompletePaymentOn: 'Sie haben entweder eine unvollständige Zahlung auf',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'oder Sie haben Ihren Plan nicht erfolgreich aktualisiert.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'oder Sie haben Ihren Bot nicht erfolgreich weggelabelt.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'oder Sie haben die “Messages Add-on” nicht erfolgreich gekauft.',
    please: 'Bitte',
    complete: 'Abgeschlossen',
    upgrade: 'Aktualisierung',
    services: 'Dienste',
    yourPlanToContinueServices: 'Ihr Plan, die Dienste fortzusetzen',
    yourPaymentToContinue: 'Ihre Zahlung, die Dienste fortzusetzen',
    forAnyQueryPleaseWriteUs: 'Für eine Frage bitte schreiben Sie uns.',
    close: 'Schließen',
    writeOn: '',
    upgradePlan: 'Aktualisierung',
    discardModal: {
      title: 'Stornieren',
      description: 'Sind Sie sicher, dass Sie stornieren möchten?',
      confirmText: 'Ja, Zahlung verwerfen'
    }
  },
  bpExport: {
    exportStatus: 'Exportstatus',
    tasksExport: 'Aufgaben- Export',
    download: 'Herunterladen',
    inProgress: 'im Gange',
  }
};
