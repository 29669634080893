export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Arama'
    }
  },
  bpTable: {
    loadMore: 'Daha Fazla Yükle',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Ülke Ara'
    }
  },
  createNew: {
    continue: 'Devam Et',
  },
  datePicker: {
    chooseADate: 'Bir tarih seçin',
  },
  dateRangePicker: {
    choosePeriod: 'Dönemi Seçin',
    today: 'Bugün',
    lastWeek: 'Geçen Hafta',
    lastMonth: 'Geçen Ay'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Lütfen test için kullanmak istediğiniz numarayı girin',
    resendOtp: 'OTP\'yi yeniden gönder',
    enterOtp: 'OTP\'yi girin',
    verifyOtp: 'OTP\'yi Doğrula',
    sendOtp: 'OTP Gönder',
    cancel: 'İptal',
    placeholders: {
      phone: 'Telefon*',
      enterOtp: 'OTP\'yi Girin*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Lütfen nedeninizi seçin',
    cancel: 'İptal',
    submit: 'Gönder!',
    giveFeedback: 'Geribildirim ver',
    ratings: 'Puanlar',
    reasonBehindYourDecision: 'Kararınızın Ardındaki Neden',
    shareYourExperience: 'Deneyiminizi paylaşın',
    reason1: 'neden1',
    reason2: 'neden2'
  },
  feedbackModal: {
    paymentIsInProgress: 'Ödeme işlemi devam ediyor',
    pleaseDoNotCloseThisWindow: 'Lütfen bu pencereyi kapatmayın',
    placeholders: {
      name: 'Ad',
      email: 'Email*',
      phone: 'Telefon*',
      pleaseShareYourUseCase: 'Lütfen kullanım durumunuzu paylaşın'
    }
  },
  layout: {
    sidebar: {
      notifications: 'Bildirimler',
      myAccount: 'Hesabım',
      logout: 'Çıkış Yap',
      goOnline: 'Çevrimiçi Ol',
      manage: 'Yönet',
      deactivate: 'Devre Dışı Bırak',
      activate: 'Etkinleştir',
      delete: 'Sil',
      home: 'Ana Sayfa',
      bots: 'Botlar',
      inbox: 'Gelen Kutusu',
      analytics: 'Analizler',
      developer: 'Geliştirici',
      team: 'Ekip',
      subscriptions: 'Abonelikler',
      more: 'Daha Fazla',
      users: 'Kullanıcılar',
      partnerTeam: 'Ortak ekibi',
      createNewBot: 'Yeni Bot Oluştur',
      dashboard: 'Panel',
      customers: 'Müşteriler',
      plans: 'Planlar',
      activities: 'Etkinlikler',
      feedbacks: 'Geribildirimler',
      configurations: 'Yapılandırmalar',
      copy: 'Kopyala',
      yourFriendWillMissYou: 'Arkadaşın seni özleyecek',
      rateYourBotCreationExperience: 'Bot oluşturma deneyiminizi değerlendirin',
      whatMadeYouDeleteYourBot: 'Botunuzu silmenize ne sebep oldu?',
      iCreatedByMistake: 'Hata ile oluşturdum',
      iWantToCreateAnotherBot: 'Başka bir Bot oluşturmak istiyorum',
      couldNotFindFeaturesThatINeeded: 'İhtiyacım olan özellikleri bulamadım',
      uiWasHardToNavigate: 'UI gezinmesi zordu',
      iDonotWantToTell: 'Söylemek istemiyorum',
      other: 'Diğer',
      weWillDoOurBestToMakeAutomationFun: 'Otomasyonu herkes için eğlenceli ve basit hale getirmek için elimizden geleni yapacağız.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Canlı sohbet isteklerine izin vermek veya engellemek için anahtar',
        TOOLTIP_NOTIFICATION: 'Tüm bildirimler',
        TOOLTIP_MY_ACCOUNT: 'Profiliniz ve şifre güvenliğiniz',
        TOOLTIP_LOGOUT: 'Panelden çıkış yapın'
      },
      modal: {
        logout: 'Çıkış yap',
        botDeleteFeedbackForm: 'Bot Silme Geri Bildirim Formu',
        areYouSureToLogOut: 'Çıkış yapmak istediğinizden emin misiniz?',
        yesIWillBeBack: 'Evet, Geri Döneceğim',
        deleteBot: 'Bot\'u Sil',
        areYouSureToDeleteBot: 'Bu Bot\'u silmek istediğinizden emin misiniz?',
        cancel: 'İptal',
        saveAndDelete: 'Kaydet ve Sil'
      }
    }
  },
  modal: {
    cancel: 'İptal',
    yesDelete: 'Evet, Sil!',
    continue: 'Devam et'
  },
  unsavedChanges: {
    title: 'Kaydedilmemiş Değişiklikler',
    description: 'Kaydedilmemiş değişiklikler olabilir. Çıkmak istediğinizden emin misiniz?'
  },
  notify: {
    youHave: 'Şunlara sahipsiniz: ',
    new: ' yeni '
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Şirket logonuzu kullanmanızı öneririz. 1 MB altında kare bir PNG veya JPG resmi ekleyin.'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Üzgünüz! Baby planı SADECE BİR ÜCRETSİZ bot sunuyor. Daha fazla bot oluşturmak için lütfen King planına yükseltin.',
    noIAmGood: 'Hayır, Ben iyiyim',
    iWantToUpgrade: 'Yükseltmek istiyorum',
    oops: 'Hata! ',
    planOfferOnlyOneFreeBot: ' SADECE BİR ÜCRETSİZ bot SUNAR. Daha fazla bot oluşturmak için lütfen ',
    planToCreateMoreBots: ' planına yükseltme yapın.'
  },
  webPreview: {
    preview: ' Önizleme',
    botsMessages: 'Bot Mesajları',
    bot: 'Bot ',
    eleven36AM: ' 11:36 AM',
    usersReply: 'Kullanıcı Cevabı',
    landingPageBot: 'Landing Sayfası Botu',
    chatbot: 'Sohbet Botu',
    chat: 'Sohbet',
    placeholders: {
      typeYourAnswer: 'Cevabınızı yazın'
    }
  },
  paymentLink: {
    discardPayment: 'Ödemeyi İptal Et',
    completePayment: 'Ödemeyi Tamamla',
    importantUpdate: 'Önemli Güncelleme',
    accountUpdate: 'Hesap Güncellemesi',
    youHave: 'Şunlara sahipsiniz: ',
    exceededTheMessagesLimit: 'mesaj sınırını aştı',
    ofYour: 'of your',
    planAnd: 'plan and',
    botsAreNoLongerActive: 'bots are no longer active',
    onYourWebsite: 'on your website.',
    youHaveEitherIncompletePaymentOn: 'You have either incomplete payment on',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'or you have not successfully upgraded your plan.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'or you have not successfully white-labeled your Bot.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'or you have not successfully purchased the “messages Add-on”.',
    incompletePaymentMessage: 'You have either incomplete payment on or you have not successfully upgraded your plan.',
    please: 'Please',
    complete: 'Complete',
    upgrade: 'Upgrade',
    services: 'services',
    yourPlanToContinueServices: 'your plan to continue services',
    yourPaymentToContinue: 'your payment to continue',
    forAnyQueryPleaseWriteUs: 'For any query please write us on',
    close: 'Kapat',
    writeOn: '',
    upgradePlan: 'Yükseltme Planı',
    discardModal: {
      title: 'Ödemeyi İptal Et',
      description: 'Ödemeyi iptal etmek istediğinizden emin misiniz?',
      confirmText: 'Evet, Ödemeyi İptal Et'
    }
  },
  bpExport: {
    exportStatus: 'Dışa Aktarma Durumu',
    tasksExport: 'Görevler - Dışa Aktar',
    download: 'İndir',
    inProgress: 'devam ediyor',
  }
};
