import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-bp-multi-select-dropdown',
  templateUrl: './bp-multi-select-dropdown.component.html',
  styleUrls: ['./bp-multi-select-dropdown.component.scss']
})

export class BpMultiSelectDropdownComponent implements OnInit {

  @Input() labelText = 'Select';

  @Input() showLabel = true;

  @Input() value = 'Select';

  @Input() showDropDown = false;

  staticText: any;

  @Input() enableToggle = true;

  /**
   * Property to hold option values in object format
   * Title is key used to display the property in dropdown
   */
  @Input() optionList = [{ title: '1', disabled: false }, { title: '2', disabled: false }];

  /**
   * Property to emit selected option value to parent comp
   */
  @Output() clickEvent = new EventEmitter<any>();

  @Output() searchEvent = new EventEmitter<any>();

  @Input() enableSearch = false;

  @Input() isReversed = false;

  @Input() closeDropdown = false;

  @Input() selectedValues = [];

  constructor(
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.bpDropDown;
  }

  ngOnInit(): void {

  }

  searchEventHandler(event) {
    this.searchEvent.emit(event);
  }

  optionMultiSelectHandler(item, index: number) {

    if (item?.disabled) {
      return;
    }
    this.selectedValues = this.selectedValues.includes(item.value)
      ? this.selectedValues.filter(i => i !== item.value)
      : [...this.selectedValues, item.value];
    this.clickEvent.emit({ type: 'select', item, index, selectedValues: this.selectedValues });
  }

  dropDownClickHandler() {
    this.showDropDown = !this.showDropDown;
    this.clickEvent.emit({ type: 'toggle', showDropDown: this.showDropDown });
  }

}
