import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss']
})
export class CountryDropdownComponent implements OnInit {
  searchCountry = '';
  @Output() country: any = { name: '', dial_code: '', code: '' };
  countries = [];
  countriesLoaded = false;
  @Output() selectedCountry = new EventEmitter<string>();
  staticText: any;

  constructor(
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.countryDropDown;
  }

  ngOnInit(): void {
    fetch('/assets/countries.json')
      .then((res: any) => res.json())
      .then((res: any) => {
        if (JSON.parse(localStorage.getItem('location'))?.country || 'false') {
          this.countries = res;
          const code = JSON.parse(localStorage.getItem('location')).country;
          this.country = this.countries.find(con => con.name.toLowerCase() === code.toLowerCase());
          localStorage.setItem('bp_country_code', JSON.stringify(this.country?.dial_code));
          this.countriesLoaded = true;
          this.getSelectedCountry(this.country);
        } else {
          setTimeout(() => {
            this.countries = res;
            const code = JSON.parse(localStorage.getItem('location')).country;
            this.country = this.countries.find(con => con.name.toLowerCase() === code.toLowerCase());
            localStorage.setItem('bp_country_code', JSON.stringify(this.country?.dial_code));
            this.countriesLoaded = true;
            this.getSelectedCountry(this.country);
          }, 1000);
        }
      })
      .catch(console.log);
  }

  getFilteredCountries(countries) {
    return countries.filter(a => ((a.dial_code || '').match(this.searchCountry || ''))
      || ((a.name || '').toLowerCase().match(this.searchCountry.toLowerCase() || ''))
    );
  }

  getSelectedCountry(country: string) {
    this.selectedCountry.emit(country);
    this.country = country;
  }

  toggleVisibility() {
    if (document.getElementById('countryList').style.display === 'block') {
      return document.getElementById('countryList').style.display = 'none';
    }
    document.getElementById('countryList').style.display = 'block';
  }
}
