import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {

  @Input() minDate: string;
  @Input() maxDate: string;
  @Input() selectedDate: Date;
  @Input() label: string;

  @Output() getDate = new EventEmitter<string>();
  constructor(public commonService: CommonService) { }
  public dateTime: Date;

  ngOnInit(): void {
  }

  emitDate(event) {
    this.selectedDate = event.value;
    this.getDate.emit(event.value.toUTCString());
  }

  sanitizeDate(date: Date | string) {
    if (typeof date === 'string') {
      return date
        ? this.commonService.formatDate(new Date(date))
        : '';
    }

    return date
      ? this.commonService.formatDate(date)
        .toLocaleString()
      : '';
  }

  getMaxDate(): Date | string {
    return this.maxDate
      ? new Date(this.maxDate)
      : '';
  }

  getMinDate(): Date | string {
    return this.minDate
      ? new Date(this.minDate)
      : '';
  }

  getSelectedDate(): Date | string {
    return this.selectedDate
      ? new Date(this.selectedDate)
      : '';
  }
}
