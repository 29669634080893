import { HostListener, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FacebookBot } from 'src/appv2/models/bots/facebook-bot';
import { TelegramBot } from 'src/appv2/models/bots/telegram-bots';
import { WhatsAppBot } from 'src/appv2/models/bots/whatsapp-bot';
import { CommonService } from 'src/appv2/services/common.service';
import { NetworkService } from 'src/appv2/services/network.service';
import { SharedService } from '../shared/shared.service';
import { State } from 'src/appv2/ngrx/reducers';
import { LeadsService } from './components/leads/leads.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { DateFilter } from 'src/appv2/models/leads';

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  botDropdown = false;
  selectedBotName: string;
  selectedBotId: string;
  leads: any;
  bots: any = [];
  bot$: any = null;
  isFilterBox = false;
  platformDropDown = false;
  selectedPlatformName: 'website' | 'facebook' | 'whatsapp' | 'telegram' = 'website';
  filter$: BehaviorSubject<{ bot: string, platform: string, tag: string }> = new BehaviorSubject({ bot: '', platform: '', tag: '' });
  platforms = [
    { title: 'Website', value: 'website' },
    { title: 'Facebook', value: 'facebook' },
    { title: 'Whatsapp', value: 'whatsapp' },
    { title: 'Telegram', value: 'telegram' }
  ];
  whatsAppLeads: WhatsAppBot[] = [];
  whatsAppBots: any[] = [];
  facebookLeads: any[] = [];
  facebookBots: any[] = [];
  telegramLeads: any[];
  telegramBots: any[] = [];
  botsLeads: any[] = [];
  botId: string;
  subscriberCount: number;
  botQuickReplies: any[] = [];
  websiteBots: any[] = [];
  isRender = false;

  newMessage$: Subject<any> = new Subject<any>();

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.isFilterBox = false;
  }

  constructor(
    private store: Store<State>,
    private networkService: NetworkService,
    public commonService: CommonService,
    public leadsService: LeadsService,
    private sharedService: SharedService,
  ) {
    store
      .select(state => state)
      .subscribe((state) => {
        this.websiteBots = state.bots.filter(item => item.type === 'website');
        this.whatsAppBots = state.bots.filter(item => item.type === 'whatsapp');
        this.facebookBots = state.bots.filter(item => item.type === 'facebook');
        this.telegramBots = state.bots.filter(item => item.type === 'telegram');
      });
  }

  fetchBotsList(): void {
    this.networkService.httpRequest('bots/list')
      .then((result: Array<{ _id: string, name: string }>) => {
        this.bot$.next(result);
        this.bots = result;
      });
  }

  getBotName(botId: string) {
    this.selectedBotId = botId;
    if (this.selectedPlatformName === 'website') {
      this.selectedBotName = this.websiteBots.find(bot => bot._id === this.selectedBotId)?.name;
    }
    if (this.selectedPlatformName === 'whatsapp') {
      this.selectedBotName = this.whatsAppBots.find(bot => bot._id === this.selectedBotId).bot.name;
    }
    if (this.selectedPlatformName === 'facebook') {
      this.selectedBotName = this.facebookBots.find(bot => bot._id === this.selectedBotId)?.name;
    }
    if (this.selectedPlatformName === 'telegram') {
      this.selectedBotName = this.telegramBots.find(bot => bot._id === this.selectedBotId).data.result?.first_name;
    }
  }

  getPlatformData(value) {
    this.selectedPlatformName = value;
    this.platformDropDown = false;
    this.selectedBotName = '';
    this.selectedBotId = undefined;
    if (this.selectedPlatformName === 'website') {
      this.sharedService.loader('start');
      this.networkService.httpRequest('communication/leads')
        .then((data: any) => {
          this.botsLeads = data;
          this.getWebsiteBots();
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error.message);
        })
        .finally(() => {
          this.sharedService.loader('stop');
          this.isRender = true;
        });
    } else if (this.selectedPlatformName === 'whatsapp') {
      this.sharedService.loader('start');
      this.networkService.httpRequest('whatsapp-automation/subscribers/')
        .then((data: any) => {
          this.whatsAppLeads = data;
          this.getWhatsAppBots();
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error.message);
        })
        .finally(() => {
          this.sharedService.loader('stop');
          this.isRender = true;
        });
    } else if (this.selectedPlatformName === 'facebook') {
      this.sharedService.loader('start');
      this.networkService.httpRequest('facebook-automation/subscribers/')
        .then((data: any) => {
          this.facebookLeads = data;
          this.getFacebookPages();
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error.message);
        })
        .finally(() => {
          this.sharedService.loader('stop');
          this.isRender = true;
        });
    }
    else if (this.selectedPlatformName === 'telegram') {
      this.sharedService.loader('start');
      this.networkService.httpRequest('telegram-automation/subscribers/')
        .then((data: any) => {
          this.telegramLeads = data;
          this.getTelegramBots();
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error.message);
        })
        .finally(() => {
          this.sharedService.loader('stop');
          this.isRender = true;
        });
    }
  }


  getWebsiteBots() {
    this.sharedService.loader('start');
    this.networkService.httpRequest('bots/list')
      .then((data: any) => {
        this.websiteBots = data;
        return data;
      })
      .catch((error: any) => {
        this.sharedService.showToast('error', '', error.message);
      })
      .finally(() => this.sharedService.loader('stop'));
  }
  getWhatsAppBots() {
    this.sharedService.loader('start');
    this.networkService.httpRequest('whatsapp-automation/')
      .then((data: any) => {
        this.whatsAppBots = data;
        this.getQuickReplies();
      })
      .catch((err: any) => {
        this.sharedService.showToast('error', '', err.message);
      })
      .finally(() => this.sharedService.loader('stop'));
  }

  // whatsApp Bot filter Ends here

  // facebook bot filter starts here

  getFacebookPages() {
    this.sharedService.loader('start');
    this.networkService.httpRequest('facebook-automation/bots/')
      .then((data: any) => {
        this.facebookBots = data;
      })
      .catch((err: any) => {
        this.sharedService.showToast('error', '', err.message);
      })
      .finally(() => this.sharedService.loader('stop'));
  }

  // facebook bot filter ends here


  // Telegram Bot filter starts Here

  getTelegramBots() {
    this.sharedService.loader('start');
    this.networkService.httpRequest('telegram-automation/')
      .then((data: any) => {
        this.telegramBots = data;
      })
      .catch((error: any) => {
        this.sharedService.showToast('error', '', error.message);
      })
      .finally(() => this.sharedService.loader('stop'));
  }

  // Telegram bot filter ends here

  applyFilterChanges() {
    if (this.selectedPlatformName === 'website' && this.selectedBotId !== undefined) {
      this.sharedService.loader('start');
      this.networkService.httpRequest(`communication/leads?_bot=${this.selectedBotId}`)
        .then((data: any) => {
          this.botsLeads = data;
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error.message);
        })
        .finally(() => {
          this.isFilterBox = false;
          this.sharedService.loader('stop');
        });
    } else if (this.selectedPlatformName === 'whatsapp' && this.selectedBotId !== undefined) {
      this.sharedService.loader('start');
      this.networkService.httpRequest(`/whatsapp-automation/subscribers/bot/${this.selectedBotId}`)
        .then((data: any) => {
          this.whatsAppLeads = data;
          this.botId = data[0]?._bot;
          this.getSubsCriberCount();
          this.isFilterBox = false;
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error.message);
        })
        .finally(() => this.sharedService.loader('stop'));
    } else if (this.selectedPlatformName === 'facebook' && this.facebookBots.length > 0 && this.selectedBotId !== undefined) {
      this.sharedService.loader('start');
      this.networkService.httpRequest(`facebook-automation/subscribers/bot/${this.selectedBotId}`)
        .then((data: any) => {
          this.facebookLeads = data;
          this.isFilterBox = false;
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error);
        })
        .finally(() => this.sharedService.loader('stop'));
    } else if (this.selectedPlatformName === 'telegram' && this.selectedBotId !== undefined) {
      this.sharedService.loader('start');
      this.networkService.httpRequest(`telegram-automation/subscribers/bot/${this.selectedBotId}`)
        .then((data: any) => {
          this.telegramLeads = data;
          this.isFilterBox = false;
        })
        .catch((error: any) => {
          this.sharedService.showToast('error', '', error);
        })
        .finally(() => this.sharedService.loader('stop'));
    } else {
      this.isFilterBox = false;
    }
  }

  getSubsCriberCount() {
    this.sharedService.loader('start');
    this.networkService.httpRequest(`whatsapp-automation/subscribers/count/${this.selectedBotId}`)
      .then((count: number) => {
        this.subscriberCount = count;
      })
      .catch(err => {
        this.sharedService.showToast('error', '', err);
      })
      .finally(() => this.sharedService.loader('stop'));
  }

  clearAllFilters() {
    this.isFilterBox = false;
    this.selectedPlatformName = 'website';
    this.selectedBotName = '';
    this.selectedBotId = '';
  }

  getQuickReplies() {
    this.networkService.httpRequest(`whatsapp-automation/bot/quick-reply`, null, 'GET', true)
      .then((data: any) => {
        this.botQuickReplies = data;
      });
  }

  saveMessage(payload): void {
    this.networkService.httpRequest('communication/messages', payload, 'POST')
      .finally();
  }

  getAvailableAgents() {
    return new Promise((resolve, reject) => {
      this.sharedService.loader('start');
      this.networkService.httpRequest('agent/available-agents', null, 'GET', true)
        .then(resolve)
        .catch(reject)
        .finally(() => this.sharedService.loader('stop'));
    });
  }

  transferToBot(payload) {
    return new Promise((resolve, reject) => {
      this.sharedService.loader('start');
      this.networkService.httpRequest('communication/messages/transfer/bot', payload, 'PUT', true)
        .then(resolve)
        .catch(reject)
        .finally(() => this.sharedService.loader('stop'));
    });
  }

  transferToWhatsapp(payload) {
    return new Promise((resolve, reject) => {
      this.sharedService.loader('start');
      this.networkService.httpRequest('communication/messages/transfer/whatsapp', payload, 'PUT', true)
        .then(resolve)
        .catch(reject)
        .finally(() => this.sharedService.loader('stop'));
    });
  }

  whatsAppSubscribers(page?) {
    return this.networkService.httpRequest(page ? `/whatsapp-automation/subscribers/?page=${page}` : 'whatsapp-automation/subscribers/');
  }

  telegramSubscribers(page?) {
    return this.networkService.httpRequest(page ? `/telegram-automation/subscribers/?page=${page}` : 'telegram-automation/subscribers/');
  }

  facebookSubscribers(page?) {
    return this.networkService.httpRequest(page ? `/facebook-automation/subscribers/?page=${page}` : 'facebook-automation/subscribers/');
  }

}
