export const inboxMerged = {
  inboxHeader: 'Posteingang',
  chatsHeader: 'Chats',
  contactsHeader: 'Kontakte',
  segmentsHeader: 'Gruppe',
  ordersHeader: 'Aufträge',
  exportStatus: 'Exportstatus',
  tasksExport: 'Aufgaben- Export',
  download: 'Herunterladen',
  ourAllNewOmniChannelInbox: 'Unser ganz neuer Omni-Channel-Posteingang!',
  allYourMessagesContactsAndLeads: 'Alle Ihre Nachrichten, Kontakte und Leads befinden sich jetzt an einem Ort. Bereit, sortiert und Gruppiert zu werden, damit Ihr Geschäft wächst!',
  close: 'Schließen',
  takeALook: 'Schau mal',
  inProgress: 'im Gange',
  appTooltip: {
    manageAllYourLeadsHere: 'Verwalten Sie hier alle Ihre Leads/Kontakte',
    segmentYourUserLeads: 'Gruppe Sie Ihre Leads/Bot -Benutzer basierend auf demografischen Daten und Interessen. Diese werden zum Erstellen von Sendungen verwendet',
    manageAllBotsConversationsHere: 'Verwalten Sie hier alle Gespräche der Bots',
    manageAllYourOrdersHere: 'Verwalten Sie hier alle Ihre Bestellungen'
  },
  contacts: {
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    visitorName: 'Besuchername',
    email: 'Email',
    phoneNumber: 'Telefonnummer',
    segment: 'Gruppe',
    status: 'Status',
    tag: 'Schild',
    action: 'Aktion',
    discard: 'Verwerfen',
    addTag: 'Tag hinzufügen',
    loadMore: 'Mehr laden',
    nA: 'N / A',
    assign: 'Zuordnen',
    deleteUser: 'Benutzer löschen',
    areYouSureToDeleteLead: 'Sind Sie sicher, dass Sie diesen Vorsprung löschen möchten?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'Sie haben noch keine Kontakte!',
      allOfYourContactsAppearHere: 'Alle Ihrer Kontakte werden hier angezeigt'
    },
    placeholders: {
      searchSegmentByName: 'Gruppe nach Namen durchsuchen',
      searchOrAddTag: 'Suchen oder fügen Sie ein Tag hinzu',
    },
    addContacts: {
      name: 'Name',
      email: 'Email',
      enterAValidEmail: 'Geben Sie eine gültige E -Mail ein',
      phoneNumber: 'Telefonnummer',
      cancel: 'Stornieren',
      addContact: 'Kontakt hinzufügen',
      placeholders: {
        enterEmailAddress: 'E-Mail Adresse eingeben',
        enterUserrName: 'Geben Sie den Benutzernamen ein',
        searchCountry: 'Suchland',
        enterPhoneNumber: 'Telefonnummer eingeben',
      }
    }
  },
  inboxFilter: {
    applied: 'angewandt',
    assignTags: 'Tags zuweisen',
    discard: 'Verwerfen',
    addTag: 'Tag hinzufügen',
    assignSegments: 'Gruppe zuweisen',
    filterYourLeads: 'Filtern Sie Ihre Leads',
    filters: 'Filter',
    filter: 'Filter',
    resetAllFilters: 'Alle Filter zurücksetzen',
    importContacts: 'Kontakte importieren',
    beforeYouStart: 'Bevor Sie mit dem Upload beginnen, stellen Sie bitte sicher',
    point1: '1. Laden Sie die Beispiel -Excel -Datei herunter.',
    point2: '2. Geben Sie Ihre Daten ab der 2. Reihe ab.',
    point3: '3. In jeder Zeile gibt es Daten für einen Kontakt',
    point4: '4. Stellen Sie sicher, dass alle Telefonnummern eindeutig sind.',
    point5: '5. Die maximale Upload -Grenze der Dateigröße beträgt 1 MB',
    selectFile: 'Datei aussuchen',
    clickHere: 'klicken Sie hier',
    toDownloadSampleFile: 'Beispieldatei herunterladen',
    modal: {
      closeLeads: 'Enge Leads',
      areYouSureToselectAllLeads: 'Sind Sie sicher, dass Sie alle ausgewählten Leads schließen möchten?',
      assignAgent: 'Agent zuweisen',
      toAssignAgentToAllLeads: 'Sind Sie sicher, dass Sie alle ausgewählten Leads Agent zuweisen möchten?',
      assignTag: 'Tag zuweisen',
      wantToReplacePreviouslyAssignedTagWithNew: 'Sind Sie sicher, dass Sie die zuvor zugewiesenen Tags durch das neue ausgewählte Tag für alle ausgewählten Leads ersetzen möchten?',
      deleteLeads: 'Leads löschen',
      wantToDeleteSelectedLeads: 'Sind Sie sicher, dass Sie alle ausgewählten Leads löschen möchten?',
      exportData: 'Daten exportieren',
      exportDataBasedOn: 'Daten basierend auf dem exportieren',
      assignSegment: 'Gruppe zuweisen',
      wantToAssignSegmentsToSelectedLeads: 'Sind Sie sicher, dass Sie allen ausgewählten Leads Gruppe zuweisen möchten?',
      yesGoAhead: 'Ja, fahre fort!',
      export: 'Export',
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Filterdaten basierend auf Ihren Einstellungen wie Tags, Bot, Status usw.',
      markLeadAsClosed: 'Markieren Sie Benutzer/Lead als geschlossen',
      assignAgentToLead: 'Dem Benutzer Agent zuweisen',
      assignTagToUser: 'Dem Benutzer Tag zuweisen',
      exportData: 'Daten exportieren',
      importContactsForWhatsappBot: 'Importieren Sie Kontakte für WhatsApp -Bot',
      addWhatsappContact: 'Fügen Sie WhatsApp -Kontakte hinzu',
      assignSegmentToUser: 'Dem Benutzer Gruppe zuweisen',
      deleteUsers: 'Benutzer löschen',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Geben Sie Namen, E -Mail oder Telefon ein und drücken Sie die Eingabetaste',
      searchAgentByName: 'Suchagent mit Namen',
      searchOrAddTag: 'Suchen oder fügen Sie ein Tag hinzu',
      searchSegmentByName: 'Gruppe nach Namen durchsuchen',
    },
    filterContainer: {
      filterOn: 'Filter auf',
      filterBy: 'Filtern nach',
      startFrom: 'Beginne am',
      tillDate: 'Bis zum Datum',
      offlineOrOnline: 'Offline Online',
      liveChat: 'Live-Chat',
      userInteractedWithBot: 'Der Benutzer interagierte mit Bot',
      discard: 'Verwerfen',
      applyFilter: 'Filter anwenden',
      bots: 'Bots',
      tags: 'Stichworte',
      status: 'Status',
      assignedTo: 'Zugewiesen an',
      lastMessageBy: 'Zuletzt geschickt von',
      onlineOrOffline: 'Online Offline',
      userInteracted: 'Benutzer interagiert',
      segments: 'Gruppe',
      lastSeenDate: 'Zuletzt gesehenes Datum',
      onboardingDate: 'Onboarding -Datum',
      createTags: 'Tags erstellen',
      createTagsToSortAndManageLeads: 'Erstellen Sie Tags, um Ihre Leads einfach zu sortieren und zu verwalten',
      typeYourTagHere: 'Geben Sie Ihr Tag hier ein',
      createSegment: 'Gruppe erstellen',
      manageSegmentForMarketingAutomation: 'Verwalten Sie Ihre Gruppe für die Marketingautomatisierung',
      typeYourSegmentHere: 'Geben Sie Ihr Gruppe hier ein',
      placeholders: {
        searchFilter: 'Suchfilter',
        searchByBotName: 'Suche nach Bot -Namen',
        searchOrAddTag: 'Suchen oder fügen Sie ein Tag hinzu',
        searchByAgentName: 'Suche nach Agentenname',
        searchBySegments: 'Suche nach Gruppen',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Datumspanne, die Sie aus der Auswahl beenden möchten',
        dateRangeYouWishToStartSelectionFrom: 'Datumsbereich Sie möchten mit der Auswahl beginnen',
        filterThoseThatAreOfflineOrOnline: 'Filtern Sie diejenigen Benutzer, die offline/online sind',
        filterThoseThatAreCurrentlyLiveChatting: 'Filtern Sie diejenigen Benutzer, die derzeit Live -Chat sind',
        filterThoseThatInteractedWithBot: 'Filtern Sie diejenigen Benutzer, die mit dem Bot interagiert haben',
      },
      indexMergeDatePicker: {
        selectStartDate: 'Wählen Sie Startdatum',
        selectEndDate: 'Wählen Sie Enddatum',
      }
    }
  },
  leads: {
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    visitorName: 'Besuchername',
    email: 'Email',
    phoneNumber: 'Telefonnummer',
    assignedTo: 'Zugewiesen an',
    status: 'Status',
    tag: 'Schild',
    action: 'Aktion',
    discard: 'Verwerfen',
    addTag: 'Tag hinzufügen',
    loadMore: 'Mehr laden',
    nA: 'N / A',
    deleteUsers: 'Benutzer löschen',
    areYouSureToDeleteLead: 'Sind Sie sicher, dass Sie diesen Vorsprung löschen möchten?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Sie haben noch keine Chats',
      allOfYourChatsAppearHere: 'Alle Ihre Chats werden hier angezeigt',
    },
    placeholders: {
      searchAgentByName: 'Suchagent mit Namen',
      searchStatusByName: 'Suchstatus mit Namen',
      searchOrAddTag: 'Suchen oder fügen Sie ein Tag hinzu',
    }
  },
  messages: {
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    editChatFlow: 'Chat-Fluss bearbeiten',
    connect: 'Verbinden',
    chat: {
      photo: 'Foto',
      video: 'Video',
      document: 'Dokument',
      location: 'Ort',
      assignedTo: 'Zugewiesen an',
      selectBot: 'Wählen Sie Bot',
      discard: 'Verwerfen',
      addTag: 'Tag hinzufügen',
      yourBrowserDoesNotSupportVideoTag: 'Ihr Browser unterstützt das Video -Tag nicht.',
      userHasSharedADocument: 'Der Benutzer hat ein Dokument geteilt.',
      clickHereToDownload: 'Klicke hier zum herunterladen',
      thisStoryIsNoLongerAvailable: 'Diese Geschichte ist nicht mehr verfügbar',
      yourBrowserDoesNotSupportAudioElement: 'Ihr Browser unterstützt das Audioelement nicht.',
      thisMessageIsNoLongerAvailable: 'Diese Nachricht ist nicht mehr verfügbar, da sie vom Absender nicht abgelehnt wurde',
      userHasSharedHisLocation: 'Der Benutzer hat seinen Standort geteilt. Klicke hier, um anzusehen',
      sms: 'SMS',
      failed: 'Fehlgeschlagen',
      a: 'A',
      i: 'ICH',
      r: 'R',
      m: 'M',
      conversationsSelected: 'Gespräche ausgewählt',
      nA: 'N / A',
      assignTagsCloseOrBulkReply: 'Weisen Sie Tags, schließen oder messen Sie auf alle auf, um alle auf einmal zu antworten',
      appTooltip: {
        assignAgent: 'Agent zuweisen',
        selectBot: 'Wählen Sie Bot',
        assignTagsToManageLeads: 'Weisen Sie Tags zum Verwalten von Leads und Gesprächen zu',
      },
      placeholders: {
        searchAgentByName: 'Suchagent mit Namen',
        searchBotByName: 'Bot nach Namen suchen',
        searchOrAddTag: 'Suchen oder fügen Sie ein Tag hinzu',
        searchStatusByName: 'Suchstatus mit Namen',
      }
    },
    profile: {
      userDetails: 'Nutzerdetails',
      orderDetails: 'Bestelldetails',
      orderedOn: 'Bestellt am',
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger anzeigen',
      subTotal: 'Zwischensumme',
      nA: 'N / A',
      addAWhatsappNumber: 'Fügen Sie WhatsApp -Nummer hinzu',
      addTelegramUserName: 'Fügen Sie Telegram Benutzername hinzu',
      addInstagramProfileId: 'Instagram -Profil -ID hinzufügen',
      addFacebookProfileId: 'Fügen Sie Facebook -Profil -ID hinzu',
      add: 'Hinzufügen',
      deviceProperties: 'Geräteeigenschaften',
      privateNotes: 'Private Notizen',
      urlsVisited: 'URLs besucht',
      source: 'Quelle -',
      sourceNa: 'Quelle - NA',
      medium: 'Mittel -',
      mediumNa: 'Mittel - na',
      campaign: 'Kampagne -',
      campaignNa: 'Kampagne - NA',
      attributeKey: 'Attributschlüssel',
      attributeName: 'Attributname',
      discard: 'Verwerfen',
      addAttribute: 'Attribute hinzufügen',
      editAttribute: 'Attribut bearbeiten',
      deleteAttribute: 'Attribut löschen',
      headsUp: 'Kopf hoch',
      areYouSureToDeleteAttribute: 'Sind Sie sicher, dass Sie dieses Attribut löschen möchten?',
      okay: 'Okay',
      dataAddedToLead: 'Die den APIs oder Bot-Flows zugeordneten Attribute werden nicht aktualisiert. Bitte aktualisieren Sie die APIs oder Bot-Flows entsprechend.',
      assignedOnly: 'Nur zugewiesen',
      allUsers: 'Alle Nutzer',
      save: 'Speichern',
      manageCustomAttributes: 'Attribute verwalten',
      placeholders: {
        title: 'Titel',
        egServices: 'Z.B. Dienstleistungen',
        egRequestedServices: 'Z.B. Angeforderte Dienste',
        enterYourNotesHere: 'Geben Sie hier Ihre Notizen ein ...',
      },
      bpDropDown: {
        selectBot: 'Wählen Sie Bot',
        selectFlow: 'Wählen Sie Flow',
        selectQuestion: 'Wähle eine Frage aus*',
      }
    },
    sendMessage: {
      send: 'Schicken',
      sendToAllChannels: 'Senden Sie an alle Kanäle',
      email: 'Email',
      whatsapp: 'WhatsApp',
      telegram: 'Telegramm',
      facebook: 'Facebook',
      sendSms: 'SMS senden',
      addNew: 'Neue hinzufügen',
      addNewQuickReply: 'Neue schnelle Antwort hinzufügen',
      discard: 'Verwerfen',
      fromComputer: 'Vom Computer',
      add: 'Hinzufügen',
      dragAndDropHereOr: 'Drag & Drop hier oder',
      browseFiles: 'Dateien durchsuchen',
      caption: 'Untertitel',
      templates: 'Vorlagen',
      addHeaderMedia: 'Header Medien hinzufügen',
      selectFile: 'Datei aussuchen',
      previousConversationClosed: 'Vorherige Gespräche geschlossen!',
      sendTemplateMessage: 'Senden Sie die Vorlagennachricht, um die Konversation erneut zu initiieren.',
      selectTemplate: 'Vorlage auswählen',
      conversationLocked: 'Gespräch gesperrt!',
      demoBotOnlyAllowConversations: 'Demo Bot erlaubt nur eine Konversation mit der Nummer, die Sie zuvor überprüft haben.',
      placeholders: {
        searchQuickReplyByTitle: 'Suchen Sie schnelle Antworten nach Titel',
        addTitle: 'Titel hinzufügen',
        addYourRepliesHere: 'Fügen Sie hier Ihre Antworten hinzu ...',
        addCaption: 'Bildunterschrift hinzufügen',
        enterValueForThisVariable: 'Geben Sie den Wert für diese Variable ein',
      },
      modal: {
        sendBulkMessages: 'Senden Sie Massennachrichten',
        send: 'Schicken',
        description: 'Ihre Nachricht wird basierend auf der Art der Plattform, zu der ein Benutzer gehört, saniert und gesendet. - Website -Benutzer unterstützen nur textbasierte Nachrichten - WhatsApp (nicht abonnierte) Kontakte können nur Vorlagennachrichten erhalten',
      },
      noDataFound: {
        oops: 'Hoppla!',
        currentlyDoesNotSupportBulkMessages: 'Wir unterstützen derzeit keine Bulk -Nachrichten für WhatsApp -Bots. Bitte verwenden Sie die Funktionsfunktion',
        quickReplies: 'Schnelle Antworten',
        noQuickRepliesAddedYet: 'Noch keine schnellen Antworten hinzugefügt!',
      }
    },
    userList: {
      selectAll: 'Wählen Sie Alle',
      nA: 'N / A',
      loadMore: 'Mehr laden',
    }
  },
  segments: {
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    manageSegment: 'Gruppe verwalten',
    manageYourSegmentsForMarketingAutomation: 'Verwalten Sie Ihre Gruppe für die Marketingautomatisierung',
    createNewSegment: 'Neues Gruppe erstellen',
    segmentName: 'Gruppename',
    totalContacts: 'Gesamtkontakte',
    action: 'Aktion',
    createSegmentButton: 'Gruppe erstellen',
    deleteSegments: 'Gruppe löschen',
    areYouSureToDeleteSegment: 'Sind Sie sicher, dass Sie dieses Gruppe löschen möchten?',
    placeholders: {
      searchHere: 'Suche hier',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'Sie haben noch keine Gruppe erstellt',
      allYourSegmentsAppearHere: 'Alle Ihre Gruppe werden hier erscheinen',
    },
    modal: {
      exportData: 'Daten exportieren',
      exportDataBasedOnAppliedFilters: 'Exportieren Sie Daten basierend auf den angewandten Filtern',
      export: 'Export',
    },
    appTooltip: {
      deleteSegments: 'Gruppe löschen',
      editSegment: 'Gruppe bearbeiten',
      downloadSegment: 'Download -Gruppe',
      deleteSegment: 'Gruppe löschen',
    },
    createSegment: {
      createSegment: 'Gruppe erstellen',
      manageYourSegmentsForMarketingAutomation: 'Verwalten Sie Ihre Gruppe für die Marketingautomatisierung',
      segmentName: 'Gruppename',
      segmentNameIsRequired: 'Gruppename ist erforderlich',
      selectContactsFilterForSegment: 'Wählen Sie Kontaktfilter für das Gruppe aus',
      filterYourContacts: 'Filtern Sie Ihre Kontakte',
      removeAllFilters: 'Entfernen Sie alle Filter',
      addFilters: 'Filter hinzufügen',
      discard: 'Verwerfen',
      filterSelected: 'Filter ausgewählt',
      placeholders: {
        enterSegmentName: 'Geben Sie den Gruppenamen ein',
      }
    }
  },
  orders: {
    visitorName: 'Besuchername',
    email: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    status: 'Status',
    itemCount: 'Anzahl der Artikel',
    total: 'Gesamt',
    tag: 'Schlagwort',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'Sie haben noch keine Bestellungen erstellt',
      allOfYourOrdersAppearHere: 'Alle Ihre Bestellungen werden hier erscheinen',
    },
  }
};
