import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { reducer as profileReducer } from './profile.reducer';
import { reducer as tokensReducer } from './tokens.reducer';
import { reducer as botReducer } from './bot.reducer';
import { reducer as leadReducer } from './leads.reducer';
import { reducer as messageReducer } from './message.reducer';
import { reducer as activeMessageReducer } from './activeMessage.reducer';
import { reducer as newLiveChatRequestReducer } from './live-chat.reducer';
import { reducer as ownliveChatReducer } from './own-live-chat.reducer';
import { reducer as renderReducer } from './render.reducer';
import Profile from '../../models/profile';
import Tokens from '../../models/tokens';
import Render from 'src/appv2/models/render';
import { BotConfiguration } from 'src/appv2/models/bot';
import { WhatsAppBot } from 'src/appv2/models/bots/whatsapp-bot';
import { FacebookBot } from 'src/appv2/models/bots/facebook-bot';
import { TelegramBot } from 'src/appv2/models/bots/telegram-bots';
import { WebsiteBot } from 'src/appv2/models/bots/website-bot';
import Leads from 'src/appv2/models/leads';
import Message from 'src/appv2/models/message';
import NewLiveChatRequest from 'src/appv2/models/live-chat-request';
import OwnLiveChat from 'src/appv2/models/own-live-chat';

import AgencyDetails from 'src/appv2/models/agency-details';
import { reducer as agencyDetailsReducer } from './agency-details.reducer';
import { MessagesComponent } from 'src/appv2/modules/inbox/components/messages/messages.component';
import Flow from '../../models/flow';
import { reducer as socialFlowReducer } from './social-flow.reducer';
import { reducer as socialDripsReducer } from './social-drip.reducer';
import Drip from 'src/appv2/models/whatsappDripCampaign';
import { reducer as socialToolsReducer } from './social-tool.reducer';
import { reducer as subscriptionReducer } from './subscription.reducer';

import { reducer as whatsAppToolsReducer } from './social-tool.reducer';
import Broadcast from 'src/appv2/models/broadcast';
import { reducer as socialBroadcastReducer } from './social-broadcast.reducer';
import { reducer as socialTemplateBroadcastReducer } from './social-template-broadcast.reducer';
import { reducer as socialGroupsReducer } from './social-groups.reducer';
import { reducer as whatsAppTemplatesReducer } from './whatsapp-templates.reducer';
import { reducer as socialTemplateReducer } from './social-template.reducer';
import { reducer as customerReducer } from './customers.reducer';
import { reducer as socialSubscribersReducer } from './social-subscibers.reducer';
import { reducer as pendingConfigurationsReducer } from './pending-configurations.reducer';
import { reducer as whatsAppReducer } from './whatsapp-subscription.reducer';
export interface State {
  profile: Profile;
  tokens: Tokens;
  leads: Leads[];
  bots: (WhatsAppBot | FacebookBot | TelegramBot | WebsiteBot)[];
  agencyDetails: AgencyDetails;
  socialFlows: Flow[];
  socialDrips: Drip[];
  socialTools: any[];
  whatsappTools: any[];
  socialBroadcasts: Broadcast[];
  socialTemplateBroadcasts: any[];
  socialGroups: any[];
  activeMessages: Message[];
  requests: NewLiveChatRequest[];
  ownLiveChats: OwnLiveChat[];
  subscription: any;
  whatsAppTemplates: any[];
  socialTemplates: any[];
  customers: any[];
  render: Render;
  socialSubscribers: any[];
  pendingConfigurations: string[];
  whatsaAppSubscription: any;
}

export const reducers: ActionReducerMap<State> = {
  profile: profileReducer,
  tokens: tokensReducer,
  bots: botReducer,
  leads: leadReducer,
  agencyDetails: agencyDetailsReducer,
  socialFlows: socialFlowReducer,
  socialDrips: socialDripsReducer,
  socialTools: socialToolsReducer,
  whatsappTools: whatsAppToolsReducer,
  socialBroadcasts: socialBroadcastReducer,
  socialTemplateBroadcasts: socialTemplateBroadcastReducer,
  socialGroups: socialGroupsReducer,
  activeMessages: activeMessageReducer,
  requests: newLiveChatRequestReducer,
  ownLiveChats: ownliveChatReducer,
  subscription: subscriptionReducer,
  whatsAppTemplates: whatsAppTemplatesReducer,
  socialTemplates: socialTemplateReducer,
  customers: customerReducer,
  render: renderReducer,
  socialSubscribers: socialSubscribersReducer,
  pendingConfigurations: pendingConfigurationsReducer,
  whatsaAppSubscription: whatsAppReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
