export const agency = {
  activities: {
    activities: 'गतिविधियाँ',
    upcomingRenewals: 'आगामी नवीकरण',
    subscriptionActivities: 'सदस्यता गतिविधियाँ',
    upcomingDowngradeRequests: 'आगामी डाउनग्रेड अनुरोध',
    serialNumber: 'क्रमांक',
    email: 'ईमेल',
    phoneNumber: 'फोन नंबर',
    currentPlan: 'वर्तमान योजना',
    price: 'कीमत',
    daysLeft: 'दिन शेष',
    noDataFound: {
      upcomingDowngrades: 'आगामी डाउनग्रेड',
      noUpcomingDowngradesFoundYet: 'कोई आगामी डाउनग्रेड अभी तक नहीं मिला!'
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'ग्राहकों के आगामी नवीकरण',
      subscriptionActivities: 'सदस्यता गतिविधियाँ',
      upcomingDowngradeRequestsOfCustomers: 'ग्राहकों के आगामी डाउनग्रेड अनुरोध',
      serialNumber: 'क्रमिक संख्या',
      customerEmail: 'ग्राहक ईमेल',
      customerPhoneNumber: 'ग्राहक फ़ोन नंबर',
      currentPlanOfCustomer: 'ग्राहक की वर्तमान योजना',
      currentPlanPrice: 'वर्तमान योजना मूल्य',
      daysLeft: 'ग्राहक की वर्तमान योजना के दिन की बाईं ओर'
    }
  },
  configuration: {
    configurationsHeader: 'कॉन्फ़िगरेशन',
    emailHeader: 'ईमेल',
    razorPayHeader: 'रेज़रपय',
    invoiceDetailsHeader: 'चालान विवरण',
    stripeHeader: 'स्ट्राइप ',
    appTooltip: {
      integrateYourEmailProviderDetails: 'ग्राहक ईमेल को मूल रूप से भेजने के लिए अपने ईमेल प्रदाता विवरण को एकीकृत करें।',
      enterYourRazorPayAccountDetails: 'निर्बाध ग्राहक भुगतान प्रसंस्करण के लिए अपना रेज़रपय खाता विवरण दर्ज करें।',
      enterYourStripeAccountDetails: 'सहज ग्राहक भुगतान प्रसंस्करण के लिए अपना स्ट्राइप अकाउंट विवरण दर्ज करें।',
      enterYourCompanyDetails: 'सटीक चालान और पेशेवर चालान पीढ़ी के लिए अपनी कंपनी का विवरण दर्ज करें।',
    },
    email: {
      testEmail: 'परीक्षण ईमेल',
      addEmailIntegrationsDetails: 'ईमेल एकीकरण विवरण जोड़ें',
      integrationDescription: 'अपने ईमेल प्रदाता विवरण को एकीकृत करने के लिए, कृपया अपना ईमेल प्रदाता जानकारी प्रदान करें। सभी संचार ईमेल आपके ग्राहकों को इस ईमेल पते का उपयोग करके भेजे जाएंगे, जो महत्वपूर्ण सूचनाओं और अपडेट के सुसंगत और विश्वसनीय डिलीवरी सुनिश्चित करेंगे।',
      awsRegion: 'AWS क्षेत्र',
      awsAccessKeyId: 'AWS एक्सेस कुंजी आईडी',
      awsSecretAccessKey: 'AWS सीक्रेट एक्सेस कुंजी',
      reset: 'रीसेट',
      addDetails: 'विवरण जोड़ें',
      updateDetails: 'अद्यतन विवरण',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'अपना AWS क्षेत्र दर्ज करें। यह क्षेत्र आपके संसाधनों के भौतिक स्थान को निर्धारित करता है और डेटा विलंबता और नियामक अनुपालन जैसे कारकों को प्रभावित करता है।',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'अपनी AWS एक्सेस कुंजी आईडी दर्ज करें। यह कुंजी विशिष्ट रूप से आपके AWS खाते की पहचान करती है और सुरक्षित प्रमाणीकरण और AWS सेवाओं तक पहुंच के लिए आवश्यक है।',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'अपनी AWS सीक्रेट एक्सेस कुंजी दर्ज करें। इस कुंजी का उपयोग सुरक्षित प्रमाणीकरण और AWS सेवाओं तक पहुंच के लिए किया जाता है।',
      },
      placeholders: {
        enterAwsRegion: 'AWS क्षेत्र दर्ज करें',
        enterAwsAccessKeyId: 'AWS एक्सेस कुंजी आईडी दर्ज करें',
        enterAwsSecretAccessKey: 'AWS सीक्रेट एक्सेस कुंजी दर्ज करें',
      },
      modal: {
        deleteEmailIntegration: 'ईमेल एकीकरण हटाएं',
        wantToDeleteEmailIntegration: 'क्या आप सुनिश्चित हैं कि आप इस ईमेल एकीकरण को हटाना चाहते हैं?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'फेसबुक एकीकरण विवरण जोड़ें',
      integrateYourEmailProviderDetails: 'अपने ईमेल प्रदाता विवरण को एकीकृत करें। सभी ईमेल आपके ग्राहकों को इस ईमेल का उपयोग करके भेजे जाएंगे',
      facebookAppId: 'फेसबुक ऐप आईडी',
      facebookSecretAccessKey: 'फेसबुक सीक्रेट एक्सेस कुंजी',
      reset: 'रीसेट',
      cancel: 'रद्द करना',
      addDetails: 'विवरण जोड़ें',
      updateDetails: 'अद्यतन विवरण',
      delete: 'मिटाना',
      testEmail: 'परीक्षण ईमेल',
      modal: {
        deleteFacebookIntegration: 'फेसबुक इंटीग्रेशन डिलीट करें',
        wantToDeleteFacebookIntegration: 'क्या आप सुनिश्चित हैं कि आप इस ईमेल एकीकरण को हटाना चाहते हैं?',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'फेसबुक सीक्रेट एक्सेस कुंजी दर्ज करें',
        enterFacebookAppId: 'फेसबुक ऐप आईडी दर्ज करें',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'संदेश को अनुकूलित करें जैसा कि आप चाहते हैं कि यह एक बॉट उपयोगकर्ता के तुरंत बाद चैट विंडो पर दिखाई दे, लाइव चैट के लिए अनुरोध भेजता है',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'चालान विवरण जोड़ें',
      invoicesDescription: 'अपने ग्राहकों के लिए चालान उत्पन्न करने के लिए, कृपया अपनी कंपनी का विवरण प्रदान करें, जिसमें आपकी कंपनी का नाम, पता, संपर्क जानकारी और सटीक चालान के लिए आवश्यक किसी भी अन्य प्रासंगिक जानकारी शामिल हैं। इस जानकारी का उपयोग प्रत्येक लेनदेन के लिए आपकी कंपनी की ओर से पेशेवर चालान बनाने के लिए किया जाएगा।',
      companyName: 'कंपनी का नाम',
      country: 'देश',
      selectCountry: 'देश चुनें',
      stateOrRegion: 'राज्य/क्षेत्र',
      streetAddress: 'गली का पता',
      gstin: 'गस्टिन',
      postalCode: 'डाक कोड',
      addDetails: 'विवरण जोड़ें',
      updateDetails: 'अद्यतन विवरण',
      reset: 'रीसेट',
      delete: 'मिटाना',
      loadMore: 'और लोड करें',
      time: 'समय',
      phoneNumber: 'फ़ोन नंबर',
      email: 'ईमेल',
      status: 'दर्जा',
      message: 'संदेश',
      response: 'जवाब',
      placeOfSupply: 'उपयोगकर्ता का पता',
      placeholders: {
        enterYourCompanyName: 'अपनी कंपनी का नाम दर्ज करें',
        enterYourStateOrRegion: 'अपना राज्य/क्षेत्र दर्ज करें',
        enterYourStreetAddress: 'अपना स्ट्रीट एड्रेस दर्ज करें',
        enterYourGstin: 'अपना जस्टिन दर्ज करें',
        enterPostalCode: 'डाक कोड दर्ज करें',
        enterPlaceOfSupply: 'उपयोगकर्ता का पता दर्ज करें',
      },
      modal: {
        deleteEmailIntegration: 'ईमेल एकीकरण हटाएं',
        wantToDeleteEmailIntegration: 'क्या आप सुनिश्चित हैं कि आप इस ईमेल एकीकरण को हटाना चाहते हैं?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: 'अपनी कंपनी का नाम दर्ज करें। इस जानकारी का उपयोग चालान में पहचान और ब्रांडिंग उद्देश्यों के लिए किया जाएगा।',
        TOOLTIP_COUNTRY: 'अपने देश का चयन करॊ। इस जानकारी का उपयोग चालान में पहचान और ब्रांडिंग उद्देश्यों के लिए किया जाएगा।',
        TOOLTIP_STATE: 'अपना राज्य दर्ज करें। इस जानकारी का उपयोग चालान में पहचान और ब्रांडिंग उद्देश्यों के लिए किया जाएगा।',
        TOOLTIP_STREET_ADDRESS: 'अपना स्ट्रीट पता दर्ज करें। इस जानकारी का उपयोग चालान में पहचान और ब्रांडिंग उद्देश्यों के लिए किया' +
        + ' जाएगा।',
        TOOLTIP_GSTIN: 'अपना GSTIN दर्ज करें। इस जानकारी का उपयोग चालान में पहचान और ब्रांडिंग उद्देश्यों के लिए किया जाएगा।',
        TOOLTIP_POSTAL_CODE: 'अपना डाक कोड दर्ज करें। इस जानकारी का उपयोग चालान में पहचान और ब्रांडिंग उद्देश्यों के लिए किया जाएगा।',
        TOOLTIP_PLACE_OF_SUPPLY: 'अपना उपयोगकर्ता का पता दर्ज करें। इस जानकारी का उपयोग चालान में पहचान और ब्रांडिंग उद्देश्यों के लिए किया जाएगा।',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'रेज़रपय एकीकरण विवरण जोड़ें',
      integrationDescription: 'अपने रेज़रपय खाते के विवरण को एकीकृत करने के लिए, कृपया अपना रेज़रपय खाता जानकारी प्रदान करें।' +
       ' सभी ग्राहक भुगतान को सहज लेनदेन के लिए आपके रेज़रपय खाते के माध्यम से संसाधित किया जाएगा',
      razorPayAccessKeyId: 'रेज़रपय एक्सेस कुंजी आईडी',
      razorPaySecretAccessKey: 'रेज़रपय सीक्रेट एक्सेस कुंजी',
      reset: 'रीसेट',
      addDetails: 'विवरण जोड़ें',
      updateDetails: 'अद्यतन विवरण',
      loadMore: 'और लोड करें',
      time: 'समय',
      phoneNumber: 'फ़ोन नंबर',
      email: 'ईमेल',
      status: 'दर्जा',
      message: 'संदेश',
      response: 'जवाब',
      placeholders: {
        enterRazorPayAccessKeyId: 'रेज़रपय एक्सेस कुंजी आईडी दर्ज करें',
        enterRazorPaySecretAccessKey: 'रेज़रपय सीक्रेट एक्सेस कुंजी दर्ज करें',
        enterRazorPayAccessId: 'रेज़रपय एक्सेस आईडी दर्ज करें',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'अपना रेज़रपय एक्सेस कुंजी आईडी दर्ज करें। यह कुंजी विशिष्ट रूप से रेज़रपय के साथ आपके एकीकरण की पहचान करती है और सुरक्षित भुगतान लेनदेन के लिए आवश्यक है।',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'अपना रेज़रपय सीक्रेट एक्सेस कुंजी दर्ज करें। यह कुंजी आवेदन और रेज़रपय के बीच सुरक्षित संचार के लिए आवश्यक है, जो सहज लेनदेन प्रसंस्करण और भुगतान प्रबंधन को सक्षम करती है।',
      },
      modal: {
        deleteRazorPayIntegration: 'ईमेल एकीकरण हटाएं',
        wantToDeleteThisIntegrtion: 'क्या आप सुनिश्चित हैं कि आप इस ईमेल एकीकरण को हटाना चाहते हैं?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'स्ट्राइप एकीकरण विवरण जोड़ें',
      integrationDescription: 'अपने स्ट्राइप अकाउंट विवरण को एकीकृत करने के लिए, कृपया अपना स्ट्राइप खाता जानकारी प्रदान करें। सभी ग्राहक भुगतान को सहज लेनदेन के लिए आपके स्ट्रिप खाते के माध्यम से संसाधित किया जाएगा',
      stripeSecretAccessKey: 'स्ट्राइप गुप्त अभिगम कुंजी',
      stripePublicKey: 'स्ट्राइप सार्वजनिक कुंजी',
      reset: 'रीसेट',
      addDetails: 'विवरण जोड़ें',
      updateDetails: 'अद्यतन विवरण',
      cancel: 'रद्द करना',
      loadMore: 'और लोड करें',
      time: 'समय',
      phoneNumber: 'फ़ोन नंबर',
      email: 'ईमेल',
      status: 'दर्जा',
      message: 'संदेश',
      response: 'जवाब',
      placeholders: {
        enterStripeSecretAccessKey: 'स्ट्राइप सीक्रेट एक्सेस कुंजी दर्ज करें',
        enterStripePublicKey: 'स्ट्राइप पब्लिक की दर्ज करें',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'अपनी स्ट्राइप सीक्रेट एक्सेस कुंजी दर्ज करें। इस कुंजी का उपयोग स्ट्रिप के साथ आपके एकीकरण को सुरक्षित रूप से प्रमाणित करने के लिए किया जाता है और ग्राहक भुगतान के लिए आवश्यक है।',
        TOOLTIP_PUBLIC_KEY: 'अपनी स्ट्राइप सार्वजनिक कुंजी दर्ज करें। इस कुंजी का उपयोग क्लाइंट साइड पर स्ट्रिप के साथ आपके एकीकरण को सुरक्षित रूप से प्रमाणित करने के लिए किया जाता है और ग्राहक भुगतान को संसाधित करने के लिए आवश्यक है।',
      },
      modal: {
        deleteStripeIntegration: 'स्ट्राइप एकीकरण हटाएं',
        wantToDeleteStripeIntegration: 'क्या आप सुनिश्चित हैं कि आप इस स्ट्राइप एकीकरण को हटाना चाहते हैं?',
      }
    }
  },
  customer: {
    manageCustomers: 'ग्राहकों का प्रबंधन करें',
    addNewCustomers: 'नए ग्राहक जोड़ें',
    ensureYouHaveAddedAllYourCustomersHere: 'सुनिश्चित करें कि आपने अपने सभी ग्राहकों को यहां जोड़ा है',
    customerName: 'ग्राहक का नाम',
    nameIsRequired: 'नाम आवश्यक है',
    emailId: 'ईमेल आईडी',
    enterAValidEmail: 'एक वैध ईमेल प्रविष्ट करें',
    createPassword: 'पासवर्ड बनाएं',
    enterAStrongPassword: 'एक मजबूत पासवर्ड दर्ज करें',
    confirmPassword: 'पासवर्ड की पुष्टि कीजिये',
    cancel: 'रद्द करना',
    customer: 'ग्राहक',
    searchByNameOrEmail: 'नाम या ईमेल द्वारा खोजें',
    filters: 'फिल्टर',
    all: 'सभी',
    allCustomers: 'सभी ग्राहक',
    paidCustomers: 'भुगतान किए गए ग्राहक',
    freeCustomers: 'नि: शुल्क ग्राहक',
    messageAddOnCustomers: 'संदेश ग्राहकों पर जोड़ें',
    botWhiteLabelCustomers: 'बॉट व्हिटलेबेल ग्राहक',
    sortBy: 'इसके अनुसार क्रमबद्ध करें',
    sortByFrequency: 'आवृत्ति के आधार पर क्रमबद्ध करें',
    joinedDate: 'शामिल हो गया',
    plan: 'योजना',
    lastLogin: 'आखरी लॉगइन',
    email: 'ईमेल',
    nextDue: 'अगला कारण',
    frequency: 'आवृत्ति',
    clearAll: 'सभी साफ करें',
    apply: 'आवेदन करना',
    messageConsumed: 'संदेश दिया गया',
    addCustomer: 'ग्राहक जोड़ें',
    serialNumber: 'क्रमांक',
    name: 'नाम',
    contact: 'संपर्क',
    limit: ' सीमा',
    location: 'जगह',
    actions: 'कार्रवाई',
    joinedOn: 'शामिल हो गया',
    typeOfCustomer: 'ग्राहक का प्रकार',
    exceptWhatsapp: ' (व्हाट्सएप को छोड़कर)',
    exceptWA: '(वा को छोड़कर)',
    wa: '(वा)',
    loadMore: 'और लोड करें',
    conversationsWhatsapp: ' बातचीत (व्हाट्सएप)',
    appTooltip: {
      enterCustomerEmail: 'ग्राहक ईमेल दर्ज करें',
      enterCustomerPassword: 'ग्राहक पासवर्ड दर्ज करें',
      enterCustomerConfirmPassword: 'ग्राहक की पुष्टि करें पासवर्ड दर्ज करें',
      enterCustomerName: 'ग्राहक का नाम दर्ज करें'
    },
    placeholders: {
      enterCustomerEmail: 'ग्राहक ईमेल दर्ज करें',
      searchByNameOrEmail: 'नाम या ईमेल द्वारा खोजें',
      enterPassword: 'पास वर्ड दर्ज करें',
      enterCustomerName: 'ग्राहक का नाम दर्ज करें'
    }
  },
  dashboard: {
    dashboard: 'डैशबोर्ड',
    overview: 'अवलोकन',
    totalCustomers: 'कुल ग्राहक',
    paidCustomers: 'भुगतान किए गए ग्राहक',
    freeCustomers: 'नि: शुल्क ग्राहक',
    thisWeeksSignUp: 'इस सप्ताह का साइन-अप',
    todaysSignUp: 'आज का साइन-अप',
    totalEarnings: 'कुल कमाई',
    nA: '-',
    customerName: 'ग्राहक का नाम',
    nameIsRequired: 'नाम आवश्यक है',
    emailId: 'ईमेल आईडी',
    enterAValidEmail: 'एक वैध ईमेल प्रविष्ट करें',
    editCustomer: 'ग्राहक संपादित करें',
    recentSignUps: 'हाल के साइन अप',
    serialNumber: 'क्रमांक',
    name: 'नाम',
    email: 'ईमेल',
    contact: 'संपर्क',
    plan: 'योजना',
    limit: 'सीमा',
    location: 'जगह',
    lastLogin: 'आखरी लॉगइन',
    joinedOn: 'शामिल हो गया',
    actions: 'कार्रवाई',
    exceptWhatsapp: ' (व्हाट्सएप को छोड़कर)',
    messageConsumed: 'संदेश दिया गया',
    conversationsWhatsapp: ' बातचीत (व्हाट्सएप)',
    placeholders: {
      enterCustomerName: 'ग्राहक का नाम दर्ज करें',
      enterCustomerEmail: 'ग्राहक ईमेल दर्ज करें'
    },
    modal: {
      deleteCustomer: 'ग्राहक हटाएं',
      areYouSure: 'क्या आप सुनिश्चित हैं कि आप इस ग्राहक को हटाना चाहते हैं?'
    }
  },
  downgradeRequests: {
    activites: 'गतिविधियाँ',
    upcomingRenewals: 'आगामी नवीकरण',
    subscriptionActivities: 'सदस्यता गतिविधियाँ',
    upcomingDonwgradeRequests: 'आगामी डाउनग्रेड अनुरोध',
    serialNumber: 'क्रमांक',
    email: 'ईमेल',
    phoneNumber: 'फोन नंबर',
    currentPlan: 'वर्तमान योजना',
    requestedOn: 'पर अनुरोध किया',
    downgradeOn: 'पर गिरना',
    nA: '-',
    commaMonthly: ', महीने के',
    commaYearly: ', वार्षिक',
    noDataFound: {
      downgradeRequests: 'डाउनग्रेड अनुरोध',
      noDowngradeRequestFound: 'कोई डाउनग्रेड अनुरोध अभी तक नहीं मिला!'
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'ग्राहकों के आगामी नवीकरण',
      subscriptionActivities: 'सदस्यता गतिविधियाँ',
      upcomingDowngradeOfCustomers: 'ग्राहकों के आगामी डाउनग्रेड अनुरोध',
      serialNumber: 'क्रमिक संख्या',
      customerEmail: 'ग्राहक ईमेल',
      customerPhoneNumber: 'ग्राहक फ़ोन नंबर',
      currentPlanOfCustomer: 'ग्राहक की वर्तमान योजना',
      currentPlanPrice: 'वर्तमान योजना मूल्य'
    }
  },
  emailIntegration: {
    emailIntegration: 'ईमेल एकीकरण',
    addEmailIntegrationDetails: 'ईमेल एकीकरण विवरण जोड़ें',
    integrateYourEmailProviderDetails: 'अपने ईमेल प्रदाता विवरण को एकीकृत करें' +
      ' सभी ईमेल आपके ग्राहकों को इस ईमेल का उपयोग करके भेजे जाएंगे',
    awsRegion: 'AWS क्षेत्र',
    awsRegionIsRequired: 'AWS क्षेत्र की आवश्यकता है',
    awsAccessKeyId: 'AWS एक्सेस कुंजी आईडी',
    awsAccessKeyIdIsRequired: 'AWS एक्सेस कुंजी आईडी की आवश्यकता है',
    awsSecretAccessKey: 'AWS सीक्रेट एक्सेस कुंजी',
    awsSecretAccessKeyIsRequired: 'AWS सीक्रेट एक्सेस कुंजी की आवश्यकता है',
    cancel: 'रद्द करना',
    test: 'परीक्षा',
    action: 'कार्य',
    testEmail: 'परीक्षण ईमेल',
    addDetails: 'विवरण जोड़ें',
    updateDetails: 'अद्यतन विवरण',
    saveDetails: 'विवरण सहेजें',
    placeholders: {
      enterAwsRegion: 'AWS क्षेत्र दर्ज करें',
      enterAwsAccessKeyId: 'AWS एक्सेस कुंजी आईडी दर्ज करें',
      enterAwsSecretAccessKey: 'AWS सीक्रेट एक्सेस कुंजी दर्ज करें'
    },
    modal: {
      deleteEmailIntegration: 'ईमेल एकीकरण हटाएं',
      areYouSure: 'क्या आप सुनिश्चित हैं कि आप इस ईमेल एकीकरण को हटाना चाहते हैं?'
    },
    noDataFound: {
      emailIntegration: 'ईमेल एकीकरण',
      noEmailIntegrationFoundYet: 'कोई ईमेल एकीकरण अभी तक नहीं मिला!'
    }
  },
  subscription: {
    activities: 'गतिविधियाँ',
    upcomingRenewals: 'आगामी नवीकरण',
    subscriptionActivities: 'सदस्यता गतिविधियाँ',
    upcomingDonwgradeRequests: 'आगामी डाउनग्रेड अनुरोध',
    filters: 'फिल्टर',
    manageActivities: 'गतिविधियों का प्रबंधन करें',
    sortBy: 'इसके अनुसार क्रमबद्ध करें',
    all: 'सभी',
    upgrades: 'उन्नयन',
    donwgrades: 'डाउनग्रेड',
    renewals: 'नवीनीकरण',
    serialNumber: 'क्रमांक',
    email: 'ईमेल',
    phoneNumber: 'फोन नंबर',
    previousPlan: 'पिछली योजना',
    newPlan: 'नई योजना',
    description: 'विवरण',
    date: 'तारीख',
    nA: '-',
    upgraded: 'अपग्रेड',
    commaYearly: ' , वार्षिक',
    commaMonthly: ' , महीने के',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'अभी तक कोई सदस्यता गतिविधियाँ नहीं मिली!',
      subscriptionsActivities: 'सदस्यता गतिविधियाँ'
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'ग्राहकों के आगामी नवीकरण',
      subscriptionActivities: 'सदस्यता गतिविधियाँ',
      upcomingDowngradeRequests: 'ग्राहकों के आगामी डाउनग्रेड अनुरोध',
      serialNumber: 'क्रमिक संख्या',
      customerEmail: 'ग्राहक ईमेल',
      customerPhoneNumber: 'ग्राहक फ़ोन नंबर',
      previousPlan: 'ग्राहक की पिछली योजना',
      newPlan: 'ग्राहक की नई योजना',
      description: 'योजना का विवरण',
      typeOfPayment: 'भुगतान का प्रकार',
    }
  },
  feedbacks: {
    feedbacks: 'फीडबैक',
    serialNumber: 'क्रमांक',
    customer: 'ग्राहक',
    type: 'प्रकार',
    rating: 'रेटिंग',
    reason: 'कारण',
    description: 'विवरण',
    createdAt: 'पर बनाया गया',
    nA: '-',
    loadMore: 'और लोड करें',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'खाता और बॉट विलोपन प्रतिक्रियाएँ',
      noFeedbacksFoundYet: 'अभी तक कोई प्रतिक्रिया नहीं मिली!',
    },
    appTooltip: {
      serialNumber: 'क्रमिक संख्या',
      customerEmail: 'ग्राहक ईमेल',
      feedbackForBotOrAccountDeletion: '(बॉट/खाता विलोपन) के लिए शुल्कबैक',
      customerRating: 'ग्राहक रेटिंग',
      customerReason: 'ग्राहक कारण',
      feedbackCreatedAt: 'पर प्रतिक्रिया दी गई',
      customerFeedback: 'ग्राहक प्रतिक्रिया',
    }
  }
};
