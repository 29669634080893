import {createAction, props} from '@ngrx/store';

export const loadSocialDrips = createAction(
  '[Drips] Load Social Drips',
  props<{ drips: any[] }>()
);
export const addSocialDrips = createAction(
  '[Drips] Add Social Drips',
  props<{ drip: {} | any }>()
);
export const updateSocialDrips = createAction(
  '[Drips] Update Social Drips',
  props<{ drip: string | number, update: {} | any }>()
);
export const deleteSocialDrips = createAction(
  '[Drips] Delete Social Drips',
  props<{ drip: string | number }>()
);




