export const agency = {
  activities: {
    activities: 'Activiteiten',
    upcomingRenewals: 'Komende Verlengingen',
    subscriptionActivities: 'Abonnementsactiviteiten',
    upcomingDowngradeRequests: 'Aankomende Downgrade Aanvragen',
    serialNumber: 'Serienummer',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    currentPlan: 'Huidig Abonnement',
    price: 'Prijs',
    daysLeft: `Dagen Over`,
    noDataFound: {
      upcomingDowngrades: 'Aankomende Downgrades',
      noUpcomingDowngradesFoundYet: 'Nog geen aankomende downgrades gevonden!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Aankomende verlengingen van de klanten',
      subscriptionActivities: 'Abonnementsactiviteiten',
      upcomingDowngradeRequestsOfCustomers: 'Aankomende downgrade aanvragen van de klanten',
      serialNumber: 'Serienummer',
      customerEmail: 'Klant e-mail',
      customerPhoneNumber: 'Klant telefoonnummer',
      currentPlanOfCustomer: 'Huidig abonnement van de klant',
      currentPlanPrice: 'Huidige abonnementsprijs',
      daysLeft: `Dagen over van het huidige abonnement van de klant`
    },
  },
  configuration: {
    configurationsHeader: 'Configuraties',
    emailHeader: 'E-mail',
    razorPayHeader: 'RazorPay',
    invoiceDetailsHeader: 'Factuurgegevens',
    stripeHeader: 'Stripe',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Integreer uw e-mailprovidergegevens om klant e-mails naadloos te verzenden.',
      enterYourRazorPayAccountDetails: 'Voer uw RazorPay-accountgegevens in voor naadloze verwerking van klantbetalingen.',
      enterYourStripeAccountDetails: 'Voer uw Stripe-accountgegevens in voor naadloze verwerking van klantbetalingen.',
      enterYourCompanyDetails: 'Voer uw bedrijfsgegevens in voor nauwkeurige facturering en professionele factuurgeneratie.',
    },
    email: {
      testEmail: 'Test E-mail',
      addEmailIntegrationsDetails: 'Voeg E-mailintegratiegegevens toe',
      integrationDescription: 'Om uw e-mailprovidergegevens te integreren, moet u uw e-mailproviderinformatie verstrekken.' +
        ' Alle communicatie-e-mails worden verzonden naar uw klanten met behulp van dit e-mailadres, zodat er consistente en' +
        ' betrouwbare levering van belangrijke meldingen en updates plaatsvindt.',
      awsRegion: 'AWS-regio',
      awsAccessKeyId: 'AWS-toegangssleutel-ID',
      awsSecretAccessKey: 'AWS-geheime toegangssleutel',
      reset: 'Resetten',
      addDetails: 'Gegevens Toevoegen',
      updateDetails: 'Gegevens Bijwerken',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Voer uw AWS-regio in. De regio bepaalt de fysieke locatie van uw bronnen en heeft invloed op factoren zoals datalatentie en naleving van voorschriften.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'Voer uw AWS-toegangssleutel-ID in. Deze sleutel identificeert uw AWS-account uniek en is vereist voor veilige authenticatie en toegang tot AWS-services.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Voer uw AWS-geheime toegangssleutel in. Deze sleutel wordt gebruikt voor veilige authenticatie en toegang tot AWS-services.'
      },
      placeholders: {
        enterAwsRegion: 'Voer AWS-regio in',
        enterAwsAccessKeyId: 'Voer AWS-toegangssleutel-ID in',
        enterAwsSecretAccessKey: 'Voer AWS-geheime toegangssleutel in',
      },
      modal: {
        deleteEmailIntegration: 'E-mailintegratie verwijderen',
        wantToDeleteEmailIntegration: 'Weet u zeker dat u deze e-mailintegratie wilt verwijderen?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Voeg Facebook-integratiegegevens toe',
      integrateYourEmailProviderDetails: 'Integreer uw e-mailprovidergegevens. Alle e-mails worden met behulp van dit e-mailadres naar uw klanten verzonden',
      facebookAppId: 'Facebook App ID',
      facebookSecretAccessKey: 'Facebook Geheime Toegangssleutel',
      reset: 'Resetten',
      cancel: 'Annuleren',
      addDetails: 'Gegevens Toevoegen',
      updateDetails: 'Gegevens Bijwerken',
      delete: 'Verwijderen',
      testEmail: 'Test E-mail',
      modal: {
        deleteFacebookIntegration: 'Facebook-integratie verwijderen',
        wantToDeleteFacebookIntegration: 'Weet u zeker dat u deze Facebook-integratie wilt verwijderen?'
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Voer Facebook Geheime Toegangssleutel in',
        enterFacebookAppId: 'Voer Facebook App ID in'
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Pas het bericht aan zoals u wilt dat het direct na een verzoek voor Live Chat van een botgebruiker op het chatvenster verschijnt',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Factuurgegevens Toevoegen',
      invoicesDescription: 'Om facturen voor uw klanten te genereren, moet u uw bedrijfsgegevens verstrekken, waaronder de naam van uw bedrijf,' +
        'adres, contactgegevens en alle andere relevante informatie die nodig is voor nauwkeurige facturering.' +
        ' Deze informatie wordt gebruikt om namens uw bedrijf professionele facturen te genereren voor elke transactie.',
      companyName: 'Bedrijfsnaam',
      country: 'Land',
      selectCountry: 'Selecteer Land',
      stateOrRegion: 'Staat/Regio',
      streetAddress: 'Straatadres',
      gstin: 'GSTIN',
      postalCode: 'Postcode',
      addDetails: 'Gegevens Toevoegen',
      updateDetails: 'Gegevens Bijwerken',
      reset: 'Resetten',
      delete: 'Verwijderen',
      loadMore: 'Meer Laden',
      time: 'Tijd',
      phoneNumber: 'Telefoonnummer',
      email: 'E-mail',
      status: 'Status',
      message: 'Bericht',
      response: 'Reactie',
      placeOfSupply: 'Plaats van Levering',
      placeholders: {
        enterYourCompanyName: 'Voer uw bedrijfsnaam in',
        enterYourStateOrRegion: 'Voer uw staat/regio in',
        enterYourStreetAddress: 'Voer uw straatadres in',
        enterYourGstin: 'Voer uw GSTIN in',
        enterPostalCode: 'Voer postcode in',
        enterPlaceOfSupply: 'Voer plaats van levering in'
      },
      modal: {
        deleteEmailIntegration: 'E-mailintegratie verwijderen',
        wantToDeleteEmailIntegration: 'Weet u zeker dat u deze e-mailintegratie wilt verwijderen?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME:
          'Voer uw bedrijfsnaam in. Deze informatie wordt gebruikt voor identificatie en brandingdoeleinden in facturen.',
        TOOLTIP_COUNTRY: 'Selecteer uw land. Deze informatie wordt gebruikt voor identificatie en brandingdoeleinden in facturen.',
        TOOLTIP_STATE: 'Voer uw staat/regio in. Deze informatie wordt gebruikt voor identificatie en brandingdoeleinden in facturen.',
        TOOLTIP_STREET_ADDRESS: 'Voer uw straatadres in. Deze informatie wordt gebruikt voor identificatie en brandingdoeleinden in facturen.',
        TOOLTIP_GSTIN: 'Voer uw GSTIN in. Deze informatie wordt gebruikt voor identificatie en brandingdoeleinden in facturen.',
        TOOLTIP_POSTAL_CODE: 'Voer uw postcode in. Deze informatie wordt gebruikt voor identificatie en brandingdoeleinden in facturen.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Voer uw plaats van levering in. Deze informatie wordt gebruikt voor identificatie en brandingdoeleinden in facturen.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'Voeg RAZORPAY Integratiegegevens toe',
      integrationDescription: 'Om uw RazorPay-accountgegevens te integreren, moet u uw RazorPay-accountinformatie verstrekken.' +
        ' Alle klantbetalingen worden verwerkt via uw RazorPay-account voor naadloze transacties',
      razorPayAccessKeyId: 'RAZORPAY Toegangssleutel-ID',
      razorPaySecretAccessKey: 'RAZORPAY Geheime Toegangssleutel',
      reset: 'Resetten',
      addDetails: 'Gegevens Toevoegen',
      updateDetails: 'Gegevens Bijwerken',
      loadMore: 'Meer Laden',
      time: 'Tijd',
      phoneNumber: 'Telefoonnummer',
      email: 'E-mail',
      status: 'Status',
      message: 'Bericht',
      response: 'Reactie',
      placeholders: {
        enterRazorPayAccessKeyId: 'Voer Razorpay Toegangssleutel-ID in',
        enterRazorPaySecretAccessKey: 'Voer Razorpay Geheime Toegangssleutel in',
        enterRazorPayAccessId: 'Voer RazorPay Toegang Id in',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Voer uw Razorpay Toegangssleutel-ID in. Deze sleutel identificeert uniek uw integratie' +
          ' met Razorpay en is vereist voor veilige betalingstransacties.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Voer uw Razorpay Geheime Toegangssleutel in. Deze sleutel is essentieel voor veilige communicatie tussen' +
          ' de applicatie en Razorpay, waardoor naadloze transactieverwerking en betalingsbeheer mogelijk zijn.',
      },
      modal: {
        deleteRazorPayIntegration: 'E-mailintegratie verwijderen',
        wantToDeleteThisIntegrtion: 'Weet u zeker dat u deze e-mailintegratie wilt verwijderen?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Voeg Stripe Integratiegegevens toe',
      integrationDescription: 'Om uw Stripe-accountgegevens te integreren, moet u uw Stripe-accountinformatie verstrekken.' +
        ' Alle klantbetalingen worden verwerkt via uw Stripe-account voor naadloze transacties',
      stripeSecretAccessKey: 'Stripe Geheime Toegangssleutel',
      stripePublicKey: 'Stripe Openbare Toegangssleutel',
      reset: 'Resetten',
      addDetails: 'Gegevens Toevoegen',
      updateDetails: 'Gegevens Bijwerken',
      cancel: 'Annuleren',
      loadMore: 'Meer Laden',
      time: 'Tijd',
      phoneNumber: 'Telefoonnummer',
      email: 'E-mail',
      status: 'Status',
      message: 'Bericht',
      response: 'Reactie',
      placeholders: {
        enterStripeSecretAccessKey: 'Voer Stripe Geheime Toegangssleutel in',
        enterStripePublicKey: 'Voer Stripe Openbare Toegangssleutel in',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Voer uw Stripe Geheime Toegangssleutel in. Deze sleutel wordt gebruikt voor veilige authenticatie van uw' +
          ' integratie met Stripe en is noodzakelijk voor het verwerken van klantbetalingen.',
        TOOLTIP_PUBLIC_KEY: 'Voer uw Stripe Openbare Toegangssleutel in. Deze sleutel wordt gebruikt voor veilige authenticatie van uw integratie met' +
          ' Stripe aan de clientkant en is noodzakelijk voor het verwerken van klantbetalingen.',
      },
      modal: {
        deleteStripeIntegration: 'Stripe-integratie verwijderen',
        wantToDeleteStripeIntegration: 'Weet u zeker dat u deze Stripe-integratie wilt verwijderen?'
      }
    }
  },
  customer: {
    manageCustomers: 'Beheer Klanten',
    addNewCustomers: 'Nieuwe Klanten Toevoegen',
    ensureYouHaveAddedAllYourCustomersHere: 'Zorg ervoor dat u al uw klanten hier hebt toegevoegd',
    customerName: 'Klantnaam',
    nameIsRequired: 'Naam is verplicht',
    emailId: 'E-mailadres',
    enterAValidEmail: 'Voer een geldig e-mailadres in',
    createPassword: 'Wachtwoord Aanmaken',
    enterAStrongPassword: 'Voer een sterk wachtwoord in',
    confirmPassword: 'Wachtwoord Bevestigen',
    cancel: 'Annuleren',
    customer: 'Klant',
    searchByNameOrEmail: 'Zoeken op naam of e-mail',
    filters: 'Filters',
    allCustomers: 'Alle Klanten',
    paidCustomers: 'Betalende Klanten',
    freeCustomers: 'Gratis Klanten',
    messageAddOnCustomers: 'Bericht Add-on Klanten',
    botWhiteLabelCustomers: 'Bot White-label Klanten',
    sortBy: 'Sorteer op',
    sortByFrequency: 'Sorteer op frequentie',
    all: 'Allemaal',
    joinedDate: 'Aangemeld Op',
    plan: 'Plan',
    lastLogin: 'Laatste Login',
    email: 'E-mail',
    nextDue: 'Volgende Datum',
    frequency: 'Frequentie',
    clearAll: 'Alles wissen',
    apply: 'Toepassen',
    messageConsumed: 'Bericht Verbruikt',
    addCustomer: 'Klant Toevoegen',
    serialNumber: 'Volgnr.',
    name: 'Naam',
    contact: 'Contact',
    limit: 'Limiet',
    location: 'Locatie',
    actions: 'Acties',
    joinedOn: 'Aangemeld op',
    typeOfCustomer: 'Type klant',
    exceptWhatsapp: ' (Behalve Whatsapp)',
    exceptWA: '(Behalve WA)',
    wa: '(WA)',
    loadMore: 'Meer Laden',
    conversationsWhatsapp: 'Gesprekken (Whatsapp)',
    appTooltip: {
      enterCustomerEmail: 'Voer klant e-mail in',
      enterCustomerPassword: 'Voer klant wachtwoord in',
      enterCustomerConfirmPassword: 'Bevestig klant wachtwoord',
      enterCustomerName: 'Voer klantnaam in',
    },
    placeholders: {
      enterCustomerEmail: 'Voer klant e-mail in',
      searchByNameOrEmail: 'Zoeken op naam of e-mail',
      enterPassword: 'Voer wachtwoord in',
      enterCustomerName: 'Voer klantnaam in',
    }
  },
  dashboard: {
    dashboard: 'Dashboard',
    overview: 'Overzicht',
    totalCustomers: 'Totaal Klanten',
    paidCustomers: 'Betalende Klanten',
    freeCustomers: 'Gratis Klanten',
    thisWeeksSignUp: `Aanmeldingen van deze week`,
    todaysSignUp: `Aanmeldingen van vandaag`,
    totalEarnings: 'Totale Verdiensten',
    nA: 'N.v.t.',
    customerName: 'Klantnaam',
    nameIsRequired: 'Naam is verplicht',
    emailId: 'E-mailadres',
    enterAValidEmail: 'Voer een geldig e-mailadres in',
    editCustomer: 'Bewerk Klant',
    recentSignUps: 'Recente Aanmeldingen',
    serialNumber: 'Volgnr.',
    name: 'Naam',
    email: 'E-mail',
    contact: 'Contact',
    plan: 'Plan',
    limit: 'Limiet',
    location: 'Locatie',
    lastLogin: 'Laatste Login',
    joinedOn: 'Aangemeld op',
    actions: 'Acties',
    exceptWhatsapp: ' (Behalve Whatsapp)',
    messageConsumed: 'Bericht Verbruikt',
    conversationsWhatsapp: 'Gesprekken (Whatsapp)',
    placeholders: {
      enterCustomerName: 'Voer klantnaam in',
      enterCustomerEmail: 'Voer klant e-mail in',
    },
    modal: {
      deleteCustomer: 'Verwijder Klant',
      areYouSure: 'Weet u zeker dat u deze klant wilt verwijderen?'
    }
  },
  downgradeRequests: {
    activites: 'Activiteiten',
    upcomingRenewals: 'Komende Verlengingen',
    subscriptionActivities: 'Abonnementsactiviteiten',
    upcomingDonwgradeRequests: 'Komende Downgrade-aanvragen',
    serialNumber: 'Volgnr.',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    currentPlan: 'Huidig Plan',
    requestedOn: 'Aangevraagd op',
    downgradeOn: 'Downgrade op',
    nA: 'N.v.t.',
    commaMonthly: ', Maandelijks',
    commaYearly: ' , Jaarlijks',
    noDataFound: {
      downgradeRequests: `Downgrade-aanvragen`,
      noDowngradeRequestFound: `Geen Downgrade-aanvragen gevonden!`,
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Komende verlengingen van klanten',
      subscriptionActivities: 'Abonnementsactiviteiten',
      upcomingDowngradeOfCustomers: 'Komende downgrade-aanvragen van klanten',
      serialNumber: 'Volgnummer',
      customerEmail: 'E-mail klant',
      customerPhoneNumber: 'Telefoonnummer klant',
      currentPlanOfCustomer: 'Huidig plan van de klant',
      currentPlanPrice: 'Huidige planprijs van de klant',
    }
  },
  emailIntegration: {
    emailIntegration: 'E-mailintegratie',
    addEmailIntegrationDetails: 'Voeg e-mailintegratiedetails toe',
    integrateYourEmailProviderDetails: 'Integreer uw e-mailprovidergegevens. Alle e-mails worden verstuurd via dit e-mailadres naar uw klanten',
    awsRegion: 'AWS-regio',
    awsRegionIsRequired: 'AWS-regio is verplicht',
    awsAccessKeyId: 'AWS-toegangssleutel-id',
    awsAccessKeyIdIsRequired: 'AWS-toegangssleutel-id is verplicht',
    awsSecretAccessKey: 'AWS-geheime toegangssleutel',
    awsSecretAccessKeyIsRequired: 'AWS-geheime toegangssleutel is verplicht',
    cancel: 'Annuleren',
    test: 'Testen',
    action: 'Actie',
    testEmail: 'Test E-mail',
    addDetails: 'Details Toevoegen',
    updateDetails: 'Details Bijwerken',
    saveDetails: 'Details Opslaan',
    placeholders: {
      enterAwsRegion: 'Voer AWS-regio in',
      enterAwsAccessKeyId: 'Voer AWS-toegangssleutel-id in',
      enterAwsSecretAccessKey: 'Voer AWS-geheime toegangssleutel in',
    },
    modal: {
      deleteEmailIntegration: 'E-mailintegratie verwijderen',
      areYouSure: 'Weet u zeker dat u deze e-mailintegratie wilt verwijderen?',
    },
    noDataFound: {
      emailIntegration: 'E-mailintegratie',
      noEmailIntegrationFoundYet: 'Nog geen e-mailintegraties gevonden!',
    }
  },
  subscription: {
    activities: 'Activiteiten',
    upcomingRenewals: 'Komende Verlengingen',
    subscriptionActivities: 'Abonnementsactiviteiten',
    upcomingDonwgradeRequests: 'Komende Downgrade-aanvragen',
    filters: 'Filters',
    manageActivities: 'Activiteiten Beheren',
    sortBy: 'Sorteren op',
    all: 'Alle',
    upgrades: 'Upgrades',
    donwgrades: 'Downgrades',
    renewals: 'Verlengingen',
    serialNumber: 'Volgnr.',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    previousPlan: 'Vorig Plan',
    newPlan: 'Nieuw Plan',
    description: 'Omschrijving',
    date: 'Datum',
    nA: 'N.v.t.',
    upgraded: 'geüpgraded',
    commaYearly: ' , Jaarlijks',
    commaMonthly: ' , Maandelijks',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'Nog geen abonnementsactiviteiten gevonden!',
      subscriptionsActivities: 'Abonnementsactiviteiten',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Komende verlengingen van klanten',
      subscriptionActivities: 'Abonnementsactiviteiten',
      upcomingDowngradeRequests: 'Komende downgrade-aanvragen van klanten',
      serialNumber: 'Volgnummer',
      customerEmail: 'E-mail klant',
      customerPhoneNumber: 'Telefoonnummer klant',
      previousPlan: 'Vorig plan van klant',
      newPlan: 'Nieuw plan van klant',
      description: 'Beschrijving van het plan',
      typeOfPayment: 'Type betaling',
    }
  },
  feedbacks: {
    feedbacks: 'Feedbacks',
    serialNumber: 'Volgnr.',
    customer: 'Klant',
    type: 'Type',
    rating: 'Beoordeling',
    reason: 'Reden',
    description: 'Omschrijving',
    createdAt: 'Aangemaakt Op',
    nA: 'N.v.t.',
    loadMore: 'Meer Laden',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Feedbacks voor Account- en Botverwijdering',
      noFeedbacksFoundYet: 'Nog geen feedbacks gevonden!',
    },
    appTooltip: {
      serialNumber: 'Volgnummer',
      customerEmail: 'E-mail klant',
      feedbackForBotOrAccountDeletion: 'Feedback voor (Bot/Account Verwijdering)',
      customerRating: 'Beoordeling klant',
      customerReason: 'Reden klant',
      feedbackCreatedAt: 'Feedback aangemaakt op',
      customerFeedback: 'Klantfeedback',
    }
  }
};
