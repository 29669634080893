const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Edit your chat flow',
    seeHowToUse: 'See how to use',
    help: 'Help',
    classicBuilder: 'Classic Builder',
    visualiseFlow: 'Visualise Flow',
    testLiveOnTelegramWeb: 'Test Live On Telegram Web',
    testFlow: 'Test flow',
    importTemplate: 'Import template',
    landingPageBotReview: 'Landing Page Bot Preview',
    install: 'Install',
    testLiveOnMessengerWeb: 'Test Live On Messenger Web',
    testLiveOnWhatsappWeb: 'Test Live On WhatsApp Web',
    testLiveOnInstagramWeb: 'Test Live On Instagram Web',
    seeAllChatFlow: 'See All Chat Flows',
    launchDemoModal: 'Launch demo modal',
    selectATemplateToKickStartYourBot: 'Select a template to kick start your bot',
    hireChatBotDesigner: 'Hire Chatbot Designer',
    recommendedForYou: 'Recommended for you',
    useTemplate: 'Use template',
    exportStatus: 'Export Status',
    flowExport: 'Flow- Export ',
    download: 'Download',
    importFlow: 'Import Flow',
    beforeYouStartUploadPleaseMakeSure: 'Before you start upload please make sure',
    ponit1: '1. The maximum file size upload limit is 20MB',
    point2: '2. The File format should be in JSON',
    selectFile: 'Select File',
    preview: 'Preview',
    test: 'Test',
    OopsIntegrationAreNotAvailable: 'Oops! Integrations are not available in ',
    pleaseUpgradeTo: ' Please upgrade to ',
    planForIntegration: ' for integrations.',
    placeholders: {
      searchTemplateYouNeed: 'Search the template you need',
    },
    noDataFound: {
      botFlow: 'Bot Flow',
      noBotFlowAddedYet: 'No bot flow added yet!',
      templates: 'Templates',
      noTemplatesFound: 'No Template Found!',
    },
    appTooltip: {
      exportFlow: 'Export Flow',
      importFlow: 'Import Flow',
      clickToViewChatFlow: 'Click to view the chat flow in drag and drop builder',
      clickToPreviewChatFlow: 'Click to preview the chat flow',
      clickToViewChatFlowAsFlowDiagram: 'Click to view the chat flow as a flow diagram',
      clickToTestChatFlow: 'Click to test the chat flow',
      clickToSelectAndImportTemplate: 'Click to select and import the template that best fits your business.',
      clickToInstallYourBotToSelectedPlatform: 'Click to install your bot to the selected platform',
      clickToTestDefaultFlowOfTelegramBot: `Click to test your bot's default (active) flow on telegram web`,
      clickToTestDefaultFlowOfFacebookBot: `Click to test your bot's default (active) flow on facebook web`,
      clickToTestDefaultFlowOfWhatsappBot: `Click to test your bot's default (active) flow on whatsapp web`,
      clickToTestDefaultFlowOfInstagramBot: `Click to test your bot's default (active) flow on instagram web`,
    }
  },
  createNewBot: {
    createNewBot: 'Create New Bot',
    stepOneOfTwo: 'Step 1 of 2',
    selectThePlatform: 'Select the platform',
    selectTheBotType: 'Select the Bot Type',
    aiBasedChatbot: 'AI based chatbot',
    ruleBasedChatBot: 'Rule Based Chatbot',
    webPlatforms: 'Web Platforms',
    websiteChatBot: 'Website Chatbot',
    withLiveChat: '(With Live Chat)',
    landingPageBot: 'Landing Page Bot',
    liveChatWidget: 'Live Chat Widget',
    onlyLiveChat: '(Only Live Chat)',
    socialPlatforms: 'Social Platforms',
    whatsappChatBot: 'WhatsApp Chatbot',
    telegramChatBot: 'Telegram Chatbot',
    facebookChatBot: 'Facebook Chatbot',
    instagramChatBot: 'Instagram Chatbot',
    selectBotType: 'Select Bot Type',
    selectProblem: 'Select the problem you want to solve',
    leadGeneration: 'Lead Generation',
    customerSupport: 'Customer Support',
    appointmentBooking: 'Appointment Booking',
    marketingAutomation: 'Marketing Automation',
    ecommerceWebsite: 'E-Commerce Website',
    otherUseCase: 'Others',
    back: 'Back',
    typeDomainUrl: 'Type your website URL for verification.',
    dataSourceForChatBotTraining: 'Data source for chat bot training',
    upload: 'Upload',
    uploadDocuments: 'Upload Documents',
    uploadDocument: 'Upload Document',
    selectFile: 'Select File',
    continue: 'Continue',
    websiteUrl: 'Type your website URL',
    skipNow: 'Skip now',
    completeTheExistingBotSetup: 'Complete the existing bot setup',
    youHaveAlreadyCreatedOneWhatsAppBot: 'You have already created one WhatsApp Bot, Please complete the setup for the same to create a new one.',
    completeSetup : 'Complete Setup',
    seeHowToUse: 'See how to use',
    help: 'Help',
    fbBot: {
      createNewBot: 'Create New Bot',
      help: 'Help',
      back: 'Back',
      step: 'Step ',
      of3: ' of 3',
      loginWithFacebook: 'Login with Facebook',
      asPerLatestChangesInFacebookPolicy: 'As per latest changes in Facebook policies, Facebook bot owner must have `manager`' +
        ' or above role to access all the feature of the facebook page.',
      login: 'Login',
      connectFacebookBot: 'Connect Facebook Bot',
      connectBotWithYourFacebookPagesAnd: 'Connect Bot with your Facebook Pages and ',
      exploreTruePowerOfFacebook: 'explore true power of Facebook',
      connect: 'Connect',
      connected: 'Connected',
      createNewPage: 'Create new page',
      refreshPageList: 'Refresh Page List',
      thisPageIsSuccessfullyIntegratedWith: 'This page is successfully integrated with ',
      yourMessagesFromMessengerWillAppearIn: '. Your messages from Messenger will appear in ',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Continue to Bot Configuartion',
      welcomeToBrandNameWeAreHereToHelpYou: `Welcome to ${brand}. We are here to help you.`,
      letsTryYourBotAndSeeHowItWorks: `Let's try your bot and see how it works! Click "Test On Facebook".`,
      howAreYouDoingToday: 'How are you doing today?',
      thisIsHowSimpleToUseFacebookBot: `That's it! This is how simple it is to use facebook bot`,
      letsCreateYourOwnChatFlowNow: `Let's create your own chat flow now`,
    },
    instagramBot: {
      createNewBot: 'Create New Bot',
      back: 'Back',
      step: 'Step ',
      of3: ' of 3',
      loginWithInstagram: 'Login with Instagram',
      loginToYourInstagramAccount: 'Login to your Instagram account to enable the chatbot on your handle.',
      managerLevelDescription: 'Instagram bot owners are required to have at least a "manager" level of permissions or higher to access all of the features.',
      login: 'Login',
      connectInstagramBot: 'Connect Instagram Bot',
      connectBotWithYourInstagramAnd: 'Connect Bot with your Instagram and ',
      exploreTruePowerOfInstagram: ' explore true power of Instagram',
      connected: 'Connected',
      connect: 'Connect',
      createNewPage: 'Create new page',
      refreshPageList: 'Refresh Page List',
      thisPageIsSuccessfullyintegratedWith: 'This page is successfully integrated with ',
      yourMessagesFromMessengerWillAppearIn: '. Your messages from Messenger will appear in ',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Continue to Bot Configuartion',
      welcomeToBrandNameWeAreHereToHelpYou: `Welcome to ${brand}. We are here to help you.`,
      letsTryYourBotAndSeeHowItWorks: `Let's try your bot and see how it works! Click "Test On Instagram".`,
      howAreYouDoingToday: 'How are you doing today?',
      thisIsHowSimpleToUseInstagramBot: `That's it! This is how simple it is to use instagram bot`,
      letsCreateYourOwnChatFlowNow: `Let's create your own chat flow now`,
    },
    telegramBot: {
      back: 'Back',
      step: 'Step ',
      of3: ' of 3',
      seeHowToUse: 'See how to use',
      help: 'Help',
      loginToYourTelegramAccount: 'Login to your Telegram Account',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Login to your account to create new bot or connect the existing bot',
      verificationCodeSentOnRegisteredNumber: 'Verification code sent on the registered number',
      createYourBot: 'Create your Bot',
      enterYourBotsNameAnd: `Enter your bot's name, connect and let the fun begin with `,
      botUserNameMustEndWithBotAtTheEnd: 'Bot username must end with the "bot" at the end.',
      terrisBot: 'E.g. teris_bot or terisbot',
      botNameAlreadyTaken: 'This bot name is already taken. Please try something different',
      connect: 'Connect',
      createBot: 'Create Bot',
      codeNotReceivedResend: 'Code not Received? Resend!',
      changeNumber: 'Change Number',
      telegramPreview: 'Telegram Preview',
      worldsFastestMessagingApp: `World's Fastest Messaging app & Its free!`,
      bot: 'Bot',
      phoneNumber: 'Phone number',
      userName: 'Username',
      none: 'None',
      bio: 'Bio',
      fewWordsAboutYourself: 'Few words about yourself',
      settings: 'Settings',
      notificationsAndSound: 'Notifications and sounds',
      privacyAndSecurity: 'Privacy and Security',
      dataAndStorage: 'Data and Storage',
      chatSettings: 'Chat Settings',
      chatFolders: 'Chat Folders',
      devices: 'Devices',
      connectYourBot: 'Connect your Bot',
      createNewBotOrConnectWithExistingOne: 'Create new Bot or Connect the Existing one',
      createNewBot: 'Create New bot',
      welcomeToBrandNameWeAreHereToHelpYou: `Welcome to ${brand}. We are here to help you.`,
      letsTryYourBotAndSeeHowItWorks: `Let's try your bot and see how it works! Click "Test On Telegram".`,
      howAreYouDoingToday: 'How are you doing today?',
      thisIsHowSimpleToUseTelegramBot: `That's it! This is how simple it is to use telegram bot`,
      letsCreateYourOwnChatFlowNow: `Let's create your own chat flow now`,
      placeholders: {
        searchCountry: 'Search Country',
        enterTelegramMobileNumber: 'Enter Telegram Mobile Number',
        enterLoginCodeReceived: 'Enter login code received on Telegram account *',
        twoFactorAuthentication: '2FA password (optional)',
        botName: 'Bot Name *',
        botUserName: 'Bot User Name *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Create New Bot',
      seeHowToUse: 'See how to use',
      help: 'Help',
      back: 'Back',
      step: 'Step ',
      of2: ' of 2',
      buildA: 'Build a ',
      thatMatchesYourBrandPersonality: ` that matches your brand's personality`,
      chatBotIcon: 'Chatbot Icon ',
      optional: 'Optional',
      themeColor: 'Theme color',
      chatBotName: 'Chatbot Name',
      botNameIsRequired: 'Bot name is required',
      botNameShouldBeLessThan50Characters: 'Bot Name should be less than 50 characters',
      continue: 'Continue',
      welcomeToBrandNameWeAreHereToHelpYou: `Welcome to ${brand}. We are here to help you.`,
      whatIsYourEmailAddress: 'What is your email address?',
      invalidEmailAddress: 'Invalid email address',
      customizeYourLandingPageBotLook: 'Customize your Landing Page Bot look',
      customizeYourWidgetLook: 'Customize your widget look',
      customizeYourBotLook: 'Customize your bot look',
      buildYourLandingPageBotAndAutomateInteraction: 'Build your Landing page bot and automate interaction with your customers by sharing its link',
      useLiveChatBotToConnectToCustomers: 'Use Live Chat Bot to connect to your customers in real time',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Connect ChatBot to your Website and automate interactions with visitors',
      widget: 'widget',
      botInterface: 'bot interface',
      mergedBot: 'Website Chatbot, Landing Page Bot, Mobile App Chatbot',
      mergedBotToolTip: 'Create a bot page to capture visitors\' attention and engage them in conversations.',
      placeholders: {
        typeANameForYourBot: 'Type a name for your new bot',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Enter you Bot name',
        mergedBotToolTip: 'Create a bot page to capture visitors\' attention and engage them in conversations.',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Select a theme color for your chat window',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `This logo will be used in the ${brand ? brand : ''} platform only. We suggest choosing your company logo. Add a PNG or JPG squared image under 1 MB.`
      }
    },
    whatsappBot: {
      back: 'Back',
      justAStepAway: 'Just a Step Away',
      seeHowToUse: 'See how to use',
      help: 'Help',
      createWhatsappChatBot: 'Create WhatsApp Bot',
      businessNameIsRequired: 'Business Name is required.',
      maximumLengthForBusinessName40Characters: 'The maximum length for Business Name is 40 characters.',
      businessCategory: 'Business Category',
      categoryIsRequired: 'Category is required.',
      descriptionIsRequired: 'Description is required.',
      maximumLengthForDescription200Characters: 'The maximum length for description is 200 characters.',
      pleaseFillAValidEmailAddress: 'Please fill a valid business email.',
      businessEmailIsRequired: 'Business email is required.',
      phoneNumberShouldHaveMinimum7Digits: 'Phone number should have minimum 7 digits',
      phoneNumberIsRequired: 'Phone number is required',
      websiteIsRequired: 'Website is required.',
      maximumLengthForWebsite40Characters: 'The maximum length for Website is 40 characters.',
      maximumLengthForAddress150Characters: ' The maximum length for address is 150 characters.',
      doYouHaveWhatsappApiKey: 'Do you have WhatsApp API key?',
      apiProvider: 'API provider',
      three60Dialog: '360 Dialog',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'API Key is required.',
      whatsappBusinessAccountIdIsRequired: 'WhatsApp Businness Account ID is required.',
      whatsappPhoneNumberIdIsRequired: ' WhatsApp Phone Number ID is required.',
      systemAccessTokenIsRequired: 'System Access Token is required.',
      sourceIdIsRequired: 'Source ID is required.',
      // apiKeyIsRequired: 'API Key is required.',
      // createWhatsappChatBot: 'Create WhatsApp Chatbot'
      businessName: 'Business Name',
      businessAccount: 'Business Account',
      // businessCategory: 'Business Category',
      businessDescription: 'Business Description',
      address: 'Address',
      businessEmail: 'Business email',
      websiteUrl: 'Website Url',
      thankYouForYourInterestIn: 'Thank you for your interest in ',
      whatsappChatBot: ' WhatsApp Chatbot.',
      weHaveReceivedYourInterests: 'We have received your interest, and our Sales team will be in touch with you in the next 24 hours.',
      theyWillProvideYouWhatsappApiKey: 'They will provide you WhatsApp API Key.',
      readyToCreateWhatsappBot: `I'm ready to create WhatsApp Bot`,
      exploreWhatsappFeatures: 'Explore WhatsApp features',
      welcomeToBrandNameWeAreHereToHelpYou: `Welcome to ${brand}. We are here to help you.`,
      letsTryYourBotAndSeeHowItWorks: `Let's try your bot and see how it works! Click "Test On WhatsApp".`,
      howAreYouDoingToday: 'How are you doing today?',
      thisIsHowSimpleToUseWhatsappBot: `That's it! This is how simple it is to use whatsapp bot`,
      letsCreateYourOwnChatFlowNow: `Let's create your own chat flow now`,
      connectWhatsAppBot: 'Connect WhatsApp Bot',
      createtWhatsAppBot: 'Create WhatsApp Bot',
      proceed: 'Proceed',
      whatsApiProvider: 'WhatsApp API Provider',
      cloudApi: 'Cloud API',
      netcoreApi: 'Netcore API',
      placeholders: {
        yourBusinessName: 'Your Business Name',
        businessDescription: 'Business Description',
        businessEmail: 'Business Email',
        phone: 'Phone*',
        website: 'Website (e.g. www.example.com)',
        address: 'Address',
        enterApiKey: 'Enter API key',
        enterWhatsappBusinessAccountId: 'Enter WhatsApp Businness Account ID',
        enterWhatsappPhoneNumberId: 'Enter WhatsApp Phone Number ID',
        enterSystemAccessToken: 'Enter System Access Token',
        enterSourceId: 'Enter Source ID',
        businessName: 'Business Name',
        businessWebsite: 'Business Website',
        selectWhatsappApiProvider: 'Select WA API Provider',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'Choose your WA API Provider',
        doYouHaveWhatsAppBusinessAPI: 'Do you have WhatsApp Business API?',
        yes: 'Yes',
        noIAmNotSure: 'No, I am not sure',
        selectApiProvider: 'Select API Provider',
        whatsAppCloudAPI: 'WhatsApp Cloud API',
        enterYourPhoneNumberId: 'Enter your Phone Number ID',
        phoneNumberId: 'Phone Number ID',
        enterYourWhatsAppBusinessAccountId: 'Enter your WhatsApp Business Account ID',
        whatsAppBusinessAccountId: 'WhatsApp Business Account ID',
        enterYourSystemUserToken: 'Enter your System User Token',
        systemUserToken: 'System User Token',
        help: 'Help',
        netcoreAPI: 'Netcore API',
        enterYourSourceId: 'Enter your Source ID',
        sourceId: 'Source ID',
        enterYourNetcoreApiKey: 'Enter your Netcore API Key',
        netcoreApiKey: 'Netcore API Key',
        submit: 'Submit',
        noneOftheAbove: 'None of the Above',
        next: 'Next'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Legally registered business',
        validWebsiteWithDataPrivacyPolicy: 'Valid Website with data privacy policy',
        debit: 'Debit',
        creditCard: 'Credit Card',
        gstin: 'GSTIN',
        onlyRequiredForIndianResidents: 'Only required for Indian Residents',
        onlyRequiredFor: 'Only required for',
        indianResidents: 'Indian Residents',
        validPaymentMethod: 'Valid Payment Method',
        enabledWithInternationalPayments: 'enabled with international Payments',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'Valid use case in compliance with WhatsApp commerce policy',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Phone number which does not have active WhatsApp & capable of receiving the SMS',
        discard: 'Discard',
        proceed: 'Proceed',
        whatsAppBotPrerequisites: 'WhatsApp Bot Pre-requisites',
        letUsGetItDoneForYou: 'Let us get it done for you!',
        ifYouAreFacingSomeIssues: 'If you are facing some issues',
        ourTeamCanHelpYouDoTheSame: 'Our team can help you do the same',
        requestACallbackNow: 'Request a callback now!',
      },
      stepThree: {
        setupWhatsAppBot: 'Setup WhatsApp Bot',
        selectingTheBestBusinessManagerForYourNeeds: 'Selecting the Best Business Manager for Your Needs',
        createMetaApp: 'How to Create Meta App',
        createaMetaAppOn: 'Create a Meta App on',
        howToCreate: 'How to create',
        addWhatsAppNumber: 'How to Add WhatsApp Number',
        addYourWhatsAppNumberAndVerifyTheSame: 'Add your WhatsApp Number & Verify the same',
        subscribe: 'Subscribe',
        copyAndSubscribeWebhook: 'Copy and subscribe Webhook',
        howToSubscribe: 'How to subscribe',
        copy: 'Copy',
        appSecretId: 'App Secret ID',
        enterYourAppSecretId: 'Enter your App secret ID',
        enterAppSecret: 'Enter App Secret',
        phoneNumberId: 'Phone Number Id',
        whatsAppBusinessId: 'WhatsApp Business ID',
        assignSystemUser: 'How to Assign System User',
        assign: 'Assign',
        createApp: 'Create Meta',
        registerAndVerify: 'Register & Verify',
        add: 'Add',
        systemUserToken: 'System User Token',
        enterYourSystemUserToken: 'Enter your System User Token',
        getAllNecessaryDetails: 'How to Get All Necessary Details',
        subscribeWhatsAppWebhook: 'How to Subscribe WA Webhook',
        configureTheWebhookForWhatsAppAccount: 'Configure the Webhook for WhatsApp account',
        upadatePaymentMethod: 'How to Update Payment Method',
        assignThePaymentMethodToWhatsAppAccount: 'Assign the payment method to any WhatsApp Account',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Grant control of a WhatsApp Business Account to a system user',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Key details that you need to copy from Meta Dashboard',
        updatePayment: 'Update Payment',
        letUsGetItDoneForYou: 'Let us get it done for you!',
        ifYouAreFacingSomeIssues: 'If you are facing some issues',
        ourTeamCanHelpYouDoTheSame: 'Our team can help you do the same',
        requestACallbackNow: 'Request a callback now!',
        update: 'Update',
        connectNow: 'Connect Now'

      },
      stepFive: {
        selectBusinessManager: 'Select Business Manager',
        selectingTheBestBusinessManagerForYourNeeds: 'Selecting the Best Business Manager for Your Needs'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Your bot is installed and Active',
        youhaveSuccessfullyConnected: 'You have successfully connected',
        withWhatsApp: 'with WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'You have been allocated 100 conversation per month by default',
        upgradeYourPlanForMore: 'Upgrade your plan for more.',
        createChatFlow: 'Create Chat Flow',
        upgradePlan: 'Upgrade Plan'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Scan QR or Send message to Activate Bot',
        scanTheQrCodeFrom: 'Scan the QR code from',
        yourPhoneWillOpen: 'Your phone will open a pre-filled message to send to our Sandbox number. Hit ‘Send’ on WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Send Message from your WhatsApp Number',
        sendUsAWhatsAppMessageOn: 'Send us a WhatsApp message on ',
        bySavingInYourContactsAndMessageShouldStartwith: ' by saving in your contacts and message should start with ',
        send: 'Send',
        or: 'OR'
      }
    }
  },
  gettingStarted: {
    hello: 'Hello, ',
    upgrade: 'Upgrade',
    gettingStarted: 'Getting Started - ',
    yourFacebookBotToken: 'Your Facebook bot token ',
    hasBeenExpired: 'has been expired',
    isExpiringIn: 'is expiring in ',
    days: ' days',
    facebookAuthenticationTokenRemainsFor60Days: 'Facebook authentication token remains active only for 60 days',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Kindly proceed with the renewal of your bot token to ensure uninterrupted access to chatbot services.',
    renewYourBotToken: 'Renew your bot token',
    editYourChatFlow: 'Edit your chat flow',
    buildConversationEngagingBot: 'Build engaging conversation flows for your bot users.',
    takes5minutes: 'takes 5 mins',
    previewYourLiveChatWidget: 'Preview Your Live Chat Widget',
    discoverHowThe: 'Discover how the ',
    liveChatFeatureWorks: ' Live Chat feature works using our Live Chat playground',
    takes2minutes: 'takes 2 mins',
    takes1minute: 'takes 1 min',
    connected: 'Connected',
    customizeYourBotApperance: `Customize your bot's appearance and functionality.`,
    takes3minutes: 'takes 3 mins',
    thirdPartyAppsIntegration: 'Third-party Apps Integration',
    integrateYourBot: 'Integrate your bot with other Apps/CRMs to create business automation.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Upgrade your plan to receive leads on your email',
    getStartedWithUs: 'Get started with us',
    howToBuildAGoodChatFlow: 'How to build a good chatflow',
    howToUseBotPenguinsInboxAndItsFeatures: `How to use BotPenguin's Inbox and its features`,
    botPenguinsPricing: `BotPenguin's Pricing: Exploring Plans and Features`,
    openHelpDesk: 'Open Help desk',
    helpAndSupport: 'Help and support',
    extensiveGuides: 'Extensive guides for all your queries and workflows are available in our ',
    resourceCentre: 'Resource Centre',
    checkoutOurAnswers: 'You can check out our answers to the common queries below.',
    createANewBot: 'Create a new bot',
    installYourBot: 'Install your bot',
    plansAndPricing: 'Plans and Pricing',
    previewYourLandingPageBot: ' Preview your Landing page bot',
    installYourLiveChatWidget: 'Install your Live Chat widget',
    installYourWebsiteBot: 'Install your chatbot on website, mobile app or launch as landing page or embedded chatbot',
    integrateBotToLandingPage: 'Integrate the bot to your landing page bot',
    installWidgetToWebsite: 'Install the widget to your website',
    installBotToWebsite: 'Install your chatbot',
    whatsappAutomation: 'WhatsApp Automation',
    facebookAutomation: 'Facebook Automation',
    telegramBotSettings: 'Telegram Bot Settings ',
    liveChatWidgetSettings: 'Live Chat Widget Settings',
    landingPageBotSettings: 'Landing Page Bot Settings',
    websiteChatBotSettings: 'Website Chatbot Settings',
    instagramAutomation: 'Instagram Automation',
    diveDeeperIntoBotPenguin: 'Dive Deeper into BotPenguin',
    knowWhereWeAreGoing: 'Know where we are going, the recent updates, and help us fix some bugs.',
    productUpdates: 'Product Updates',
    requestAFeature: 'Request a feature',
    reportABug: 'Report a bug',
    prodcutRoadmap: 'Product Roadmap',
    trainYourChatbot: 'Train your AI Chat Bot (Knowledge base)',
    buildEngagingConversation: 'Build engaging conversation flows for your bot users.',
    previewYourAIBot: 'Preview Your AI Bot',
    integrateAIbot: 'Integrate the bot to your AI bot',
    aiBotSettings: 'AI Bot Settings',
    CustomizeAIBot: 'Customize your bot\'s appearance and functionality.',
    gptKeyWarning: 'Welcome to our ChatGPT API service! Please note that there is a limitation of 100 messages for each bot. After you reach the 100 message limit, you will need to use your own ChatGPT API key to continue enjoying our service. For entering ChatGPT API key, go to ChatGPT Model Configuration.',
    whatsappBotSettings: 'WhatsApp Bot Settings',
    automateCustomerEngagementWithWhatsApp: 'Automate customer engagement with WhatsApp.',
    connectToYourWhatsappNumber: 'Connect to your WhatsApp Number',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Connect your WhatsApp Bot with your WhatsApp number',
    facebookBotSettings: 'Facebook Bot Settings',
    automateCustomerEngagementWithTelegram: 'Automate customer engagement with Telegram',
    automateCustomerEngagementWithFacebook: 'Automate customer engagement with Facebook',
    automateCustomerEngagementWithInstagram: 'Automate customer engagement with Instagram',
    telegramAutomation: 'Telegram Automation',
  },
  install: {
    previewYourLandingPageBot: 'Preview your Landing Page Bot',
    preview: 'Preview',
    test: 'Test',
    squareSpace: 'Squarespace',
    installYourChatBot: 'Install your Chat Bot',
    seeHowToUse: 'See how to use',
    help: 'Help',
    uniqueLinkToLandingPageBot: 'Here is the unique link to your Landing page bot.',
    shareOnSocialMedia: 'Simply share it with your users on social media, contact pages or business cards.',
    or: 'OR',
    copyUrl: 'Copy URL',
    selectYourWebsiteCms: 'Select your Website CMS',
    selectCms: 'Select the CMS/Builder you have used to create your website',
    custom: 'Custom',
    wordpress: 'Wordpress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Other CMS',
    howWouldYouLikeToInstall: 'How would you like to install it?',
    installingWebsiteBot: 'Choose you will be installing the bot on your website',
    iWillAddMyself: `I'll add myself`,
    getChatBotScript: 'Get chatbot script code below & install all it by yourself',
    getWordpressPlugin: 'Get WordPress Plugin API Key',
    getOurOfficialPlugin: 'Get our official plugin ',
    fromWordpressPluginStore: ' from WordPress plugin Store',
    getPlugin: 'Get Plugin',
    quickGuideToInstall: 'Quick Guide To Install',
    placeApiKeyBelowIn: 'Paste the API key below in ',
    afterDownloadingAndActivatingPlugin: ' after downloading and activating plugin',
    copy: 'Copy',
    enterYourWebsite: `Enter your website and let's see how your chatbots looks`,
    enterAValidUrl: 'Enter a valid URL',
    verifyYourInstallation: 'Verify whether your installation is successfull or not?',
    testInstallation: 'Test Installation',
    getChatBotScriptCode: 'Get Chatbot Script Code',
    copyThisCodeSnippet: 'Copy this code snippet and insert it in the HTML of every web page where you want to' +
      ' display the chatbot or paste in footer before tag closes to appear on each page.',
    // copy: 'Copy',
    sendCodeToMyDeveloper: 'Send Code to My Developer',
    sendCodeToMyDevelopmentTeam: 'Send code to my development team',
    sendEmail: 'Send Email',
    enterAValidEmail: 'Enter a valid email',
    codeHasBeen: 'Code has been ',
    toYourDeveloper: ' to your developer ',
    resent: 'resent',
    sent: 'sent',
    resendEmail: 'Resend Email',
    ask: 'Ask ',
    supportTeamToInstall: ' Support Team to install',
    raiseTheInstallationRequestTo: 'Raise the installation request to ',
    team: ' Team',
    helpMeInstallingChatBot: 'Help me in Installing ChatBot',
    yourBotInstallationIsNotActive: 'Your bot Installation is not active!',
    monthlyMessageLimitExceeded: 'Monthly message Limit Exeeded',
    exceededMonthlyMessageLimitUpgradeNow: 'You have exceeded your monthly message limit, Upgrade now.',
    upgradeNow: 'Upgrade Now',
    botInstallationNotActivePleaseFix: 'Your bot installation is not active, Please fix the same. ',
    incorrectBotScript: 'Incorrect Bot Script',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: `The installation code you're verifying doesn't match the one on your site.
      Please make sure you've installed the correct code for this bot.`,
    unableToSee: 'Unable to see ',
    codeOnYourWebsite: ' Code on your website',
    unableToFindCodePleaseReinstall: 'We are unable to find the code on your website, Please re-install.',
    reinstallBot: 'Re-install bot',
    yourBotIsInstalledAndActive: 'Your bot is Installed and Active!',
    noPendingAction: 'No pending action, Your bot is installed and active',
    youAreAllSet: 'You are all set!',
    showMeMyLeads: 'Show me my leads',
    connectingToYourWebsiteToVerifyInstallation: 'Connecting to your website to verify Bot Installation',
    thisMayTakeUpto30Seconds: 'This may take upto 30 seconds',
    hereIsTheuniqueAILink: 'Here is the unique link to your AI Bot',
    installHeading: 'Install Your Chatbot',
    websiteChatbot: 'Website Chatbot',
    aiChatBot: 'AI Chatbot',
    landingPageBot: 'Landing Page Bot',
    mobileChatBot: 'Mobile App Chatbot',
    embeddedBot: 'Embedded Chatbot',
    selectMobileDevice: 'Select your mobile device',
    mobileDeviceSubheading: 'Select the native language you have used to create your mobile app',
    ios: 'IOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Other',
    copyEmbeddedCodeSnippet: 'Copy this code snippet and insert it in the HTML of every web page where you want to display the chatbot.',
    copyMobileCodeSnippet: 'Copy this code snippet in your mobile application code where you want to display the chatbot.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'Enter Developer Email'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Send Instructions to your developer',
      notSureHowToDoIt: 'Not sure how to do it? Your developers can do it in a jiff.',
      developersEmail: `Developer's Email`,
      emailIsRequired: 'Email is required',
      subject: 'Subject',
      message: 'Message',
      send: 'Send',
      placeholders: {
        enterYourWorkEmail: 'Enter your work email',
        createBotUsingBotPenguin: 'Excited! Create bot using BotPenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Here is the unique link to your Landing Page Bot.',
      shareWithUsersOnSocialMedia: 'Simply share it with your users on social media, contact pages or business cards.',
      previewMyLandingPageBot: 'Preview my Landing Page Bot',
      or: 'OR',
      copyUrl: 'Copy URL',
      howYouLikeToAddBot: 'Choose how you’d like to add your Bot code to your website',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Install the Bot to your custom coded or WordPress website by copy-pasting a unique code.',
      name: 'Name',
      actions: 'Actions',
      javascript: 'Javascript',
      install: 'Install ',
      onYourCustomCodedWebsite: ' on your custom coded website',
      copyAndPaste: 'Copy and paste',
      copyThisCodeSnippet: 'Copy this code snippet and insert it in the HTML of every web page' +
        'where you want to display the Widget. For more details',
      seeGuide: 'see guide',
      getCode: 'Get Code',
      howToInstall: 'How to install?',
      copyCode: 'Copy code',
      wordpress: 'Wordpress',
      // install: 'Install ',
      onYourWordPressWebiste: ' on your WordPress website',
      preview: 'Preview',
      test: 'Test',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Live Chat Preview',
    agentWindow: 'Agent Window',
    person: 'person',
    inOrderToTestTypeHere: 'In order to test Type Here',
    agentWindowCannotBeCustomized: `Note: Agent Window can't be customized`,
    customerWindow: 'Customer Window',
    clickHereToChat: 'Click here to Chat',
    customerWillChatHere: 'Customer will chat here',
    chat: 'Chat',
    by: 'by',
    changeYourBotLookFromLeftPanel: 'Change your bot look from the left panel',
    placeholders: {
      typeYourMessage: 'Type your message...',
      typeYourAnswer: 'Type your answer',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Preview your Live Chat Widget',
    install: 'Install',
    seeHowToUse: 'See how to use',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Click to install your bot to the selected platform',
    }
  },
  trainYourBot: {
    trainYourBot: 'Train Your Chatbot',
    knowledgeBase: 'Knowledge Base',
    faqs: 'FAQ\'s',
    history: 'History',
    faqHeading: 'Frequently asked questions',
    faqSubHeading: 'Answers to Your Chatbot Training Questions',
    knowledgeBaseHeading: 'Train your chatbot',
    knowledgeBaseSubheading: 'Train Your Chatbot for Enhanced Conversations',
    websiteLink: 'Website Link',
    uploadFile: 'Upload your file',
    selectFile: 'Select File',
    submit: 'Submit',
    searchHere: 'Search here',
    question: 'Question',
    answer: 'Answer',
    questionPlaceholder: 'Write your question here',
    answerPlaceholder: 'Write your answer here',
    save: 'Save',
    discard: 'Discard',
    addFaq: 'Add FAQ\'s',
    createdAt: 'Created At',
    updatedAt: 'Last Update At',
    actions: 'Actions',
    noFaqFound: 'You do not have any FAQ\'s created yet',
    noFaqFoundMessage: 'All of your FAQ\'s will appear here',
    historyTitle: 'Knowledge base history',
    historySubtitle: 'Answers to Your Chatbot Training Questions',
    fileName: 'File Name',
    status: 'Status',
    noHistoryTitle: 'You do not have any trained models created yet',
    noHistoryDescription: 'All of your trained models will appear here',
    fileUpload: 'File Upload',
    files: 'Files',
    fileSubHeading: 'Add all the files on which you want to train your bot.',
    noFileFoundHeading: 'You do not have any files created yet',
    noFileFoundSubheading: 'All of your files will appear here',
    uploadYourFiles: 'Upload your files',
    upload: 'Upload',
    fileShouldBe: 'File should be PDF, DOC, DOCX, PPTX, TXT.',
    uploadStatus: 'Upload Status',
    uploadStatusSubHeading: 'Files upload status for bot training',
    addFile: 'Add File',
    otherConfiguration: 'AI Settings',
    deleteFiles: 'Click to delete files',
    websiteURL: 'Website URLs',
    websiteUrlSubheading: 'Add all the website URLs on which you want to train your bot.',
    enterWebsiteUrl: 'Enter the URL',
    addWebsite: 'Add Website',
    tableWebsiteUrl: 'Website URL',
    trainingStatus: 'Training Status',
    lastTrained: 'Last Trained',
    noWebsitesFound: 'You do not have any websites created yet',
    noWebsitesFoundMessage: 'All of your websites will appear here',
    loadMore: 'Load More',
    botBehaviour: 'Configure Bot Behaviour',
    botBehaviourSubHeading: 'Configure bot’s personality, redact customer data, handle flow and ChatGPT model',
    botPersonality: 'Configure bot’s personality',
    botPersonalitySubHeading: 'Manage how your bot should respond in terms of personality',
    selectAnswerLength: 'Select Answer Length',
    answerLength: 'Answer Length',
    answerAs: 'Answer as',
    tone: 'Tone',
    language: 'Language',
    answerFormatting: 'Answer Formatting',
    customInstructions: 'Custom Prompt',
    customerInstructionPlaceholder: 'Be polite and friendly. Don’t use slang. Don’t use emojis',
    redactCustomerData: 'Collect Customer Data',
    redactDataSubheading: 'Configure the customer information you want to collect',
    collectName: 'Collect Name',
    configureQuestion: 'Configure Question',
    maxCharacterLimit: 'Max character limit in Provided Name',
    collectEmail: 'Collect Email',
    excludeEmail: 'Exclude Emails with Domain',
    collectPhone: 'Collect Phone',
    manageUnusualMessages: 'Manage Unusual Chat Scenarios',
    unusualMessagesSubheading: 'Handle the un-answered and other rare scenarios for the bot',
    startConversation: 'Message to start conversation',
    configureMessage: 'Message when bot is unable to answer',
    profanityMessage: 'Message to respond Inappropriate language usage',
    chatGptModel: 'ChatGPT Model Configuration',
    chatGptModelSubheading: 'Manage which ChatGPT model to use and avoid mis-use',
    chatGptApi: 'ChatGPT API key',
    enableDisableRedactData: 'Collect Customer Data',
    triggerToCollectData: 'Collect contact details  after set of questions.',
    train: 'Train',
    website: 'Website',
    analytics: 'Analytics',
    includedSourcesForResponse: 'Included sources for response',
    visibility: 'Visibility',
    yourConversation: 'Your Conversations',
    reviewAndSelectConversation: 'Review and Select Conversations to Export for Chatbot Training',
    exportAiAnalytics: 'Export analytics',
    startDate: 'Start Date',
    endDate: 'End Date',
    userQuestion: 'User\'s Question',
    botsReply: 'Bot\'s Reply',
    dateCreated: 'Date Created',
    noAnalyticsFound: 'You do not have any analytics created yet',
    noAnalyticsFoundMessage: 'All of your analytics messages will appear here',
    exportData: 'Export Data',
    exportCompleteAnalyticsData: 'Export complete analytics data',
    export: 'Export',
    integrateWithChatGpt: 'Integrate with ChatGPT',
    integrated: 'Integrated',
    configure: 'Configure',
    appToolTip: {
      deleteWebsite: 'Delete website',
      knowledgeBase: 'Train your bot with knowledge base',
      faqs: 'Train your bot with FAQ\'s',
      knowledgeHistory: 'Knowledge base history',
      uploadFaqs: 'Upload FAQ\'s',
      addFaqs: 'Click to add FAQ\'s',
      deleteFaqs: 'Delete FAQ\'s',
      deleteHistory: 'Delete history',
      uploadFile: 'Uploaded file must be pdf, docx, doc or csv and size should be maximum 1 MB.',
      trainWebsite: 'Train your bot using a website.',
      fileUpload: 'Train your bot by utilizing files such as PDFs, TXTs, PPTXs, DOCs, and DOCXs.',
      configureBotBehaviour: 'Customize the bot\'s personality, manage flow, and adjust the ChatGPT model.',
      clickToAddWebsite: 'Click to add website',
      clickToDeleteWebsites: 'Click to delete websites',
      syncWebsite: 'Sync Website',
      copyUrl: 'Copy URL',
      weWillBeFetchingAllTheWebpages: 'We will be fetching all the webpages and training the ChatGPT model on them.'
    },
    modal: {
      deleteWebsiteUrl: 'Delete Website URL',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Are you sure you want to delete this Website URL?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Website Chatbot Settings',
    preview: 'Preview',
    test: 'Test',
    install: 'Install',
    designHeader: 'Design',
    generalHeader: 'General',
    alertsHeader: 'Alerts',
    triggerHeader: 'Trigger',
    userAccessHeader: 'User Access',
    chatWindowHeader: 'Chat Window',
    otherConfiguration: 'AI Settings',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Click to install your bot to the selected platform',
      customizeAppearanceOfBot: 'Customize positioning and appearance of your bot interface.',
      configureBotsSettingsWhenAgentUnavailable: `Configure bot's settings when your agent is not available.`,
      configureHowBotWillAlertYou: 'Configure how your bot will alert you of new leads.',
      makeSureYourBotsPopUpProperlyTimed: `Make sure your bot's pop-ups are perfectly timed.`,
      customizeWhereYouWantYourBotToAppear: 'Customize where you want your bot to appear and where not.',
      enableChatOptionsForVisitorsToTransferChat: 'Enable the chat options for your visitors to transfer chats, live chat, and refresh chat.',
      configureBotBehaviour: 'Customize the bot\'s personality, manage flow, and adjust the ChatGPT model.',
    },
    alerts: {
      configureYourBotsAlertSettings: `Configure your bot's alert settings`,
      specifyHowYourBotWillNotify: 'Specify how your bot will notify you about new leads.',
      subject: 'Subject',
      emailNotification: 'Email Notification',
      emailAddresses: 'Email Addresses',
      leadRevisitNotification: 'Lead Revisit Notifications',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'You have successfully registered with desktop notifications',
      youHaveBlockedYourNotificationsOnBrowser: 'You have blocked notifications on your browser settings.',
      discard: 'Discard',
      saveChanges: 'Save Changes',
      seeHowToUse: 'See how to use',
      placeholders: {
        enterSubjectForYourEmail: 'Enter Subject for your email',
        typeYourEmailAddressAndHitEnter: 'Type your email address and hit enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Customize subject of your email notifications from the bot',
        TOOLTIP_EMAIL_NOTIFICATION: 'Enable/disable the bot\'s notification on your email for new leads',
        TOOLTIP_EMAIL_ADDRESS: 'Submit email address of the account where you wish to receive email notifications. You can add multiple email addresses by entering one and pressing enter to add another.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Enable/disable bot\'s notification on your email if a user previously captured as a lead interacts with the bot again.',

      }
    },
    broadcasts: {
      createBroadcastHeading: 'Create Broadcast',
      youCanCreateMultipleBroadcasts: 'You can create multiple broadcasts',
      broadcastName: 'Broadcast Name',
      broadcastNameIsRequired: 'Broadcast name is required',
      continue: 'Continue',
      manageBroadcasts: 'Manage Broadcasts',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'You can create multiple broadcasts with multiple' +
        ' scheduled templates for different customer group.',
      createNewBroadcast: 'Create New Broadcast',
      name: 'Name',
      segment: 'Group',
      template: 'Template',
      contacts: 'Contacts',
      send: 'Send',
      delivered: 'Delivered',
      read: 'Read',
      failed: 'Failed',
      broadcastAt: 'Broadcast At',
      status: 'Status',
      actions: 'Actions',
      nA: 'NA',
      seeHowToUse: 'See how to use',
      deleteBroadcast: 'Delete Broadcast',
      wantToDeleteThisBroadcast: 'Are you sure you want to delete this Broadcast?',
      placeholders: {
        searchHere: 'Search here',
        enterBroadcastName: 'Enter broadcast name'
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Delete multiple broadcasts',
        broadcastAnalytics: 'Broadcast Analytics',
        deleteBroadcast: 'Delete Broadcast',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Broadcast Insights',
        broadcastStatus: 'Broadcast Status',
        youCanFindDetailsOfEachContactBelow: 'You can find details of each contact below and export the ones which may have issues.',
        filterApplied: 'Filter Applied',
        placeholders: {
          searchByPhoneNumber: 'Search by Phone Number',
          searchByName: 'Search filter by name',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Filter data based status',
          exportData: 'Export Data',
        },
        modal: {
          exportData: 'Export Data',
          exportCompleteBroadcastData: 'Export complete broadcast data',
          export: 'Export'
        }
      },
      createBroadcast: {
        broadcastName: 'Broadcast name',
        broadcastNameIsRequired: 'Broadcast name is required',
        template: 'Template',
        segments: 'Groups',
        whenToSend: 'When to send',
        now: 'NOW',
        later: 'LATER',
        selectBroadcastUsing: 'Select broadcast using',
        usingCsv: 'Using CSV',
        usingSegment: 'Using Group',
        allContacts: 'All Contacts',
        selectDate: 'Select Date',
        selectTimeSlot: 'Select Time Slot',
        assignVariablesToTemplates: 'Assign variables to templates ',
        addHeaderMedia: 'Add header Media ',
        selectFile: 'Select File',
        cancel: 'Cancel',
        createBroadcast: 'Create Broadcast',
        broadcastUsing: 'Broadcast Using',
        selectCSVFile: 'Select CSV File',
        importContacts: 'Import Contacts',
        beforeYouStartUpload: 'Before you start upload please make sure',
        point1: '1. Download the sample CSV file.',
        point2: '2. Start entering your data from 2nd row onwards.',
        point3: '3. In each row, there will be data for one contact',
        point4: '4. Make sure all the phone numbers are unique.',
        point5: '5. The maximum file size upload limit is 20MB',
        // selectFile: 'Select File',
        clickHere: 'Click here',
        toDownloadSampleFile: 'to Download Sample file',
        placeholders: {
          enterBroadcastName: 'Enter Broadcast name',
          selectTemplate: 'Select Template',
          searchTemplateByName: 'Search template by name',
          selectSegments: 'Select Groups',
          selectTimeSlot: 'Select Time Slot',
          selectValueForThisVariable: 'Select value for this variable',
          searchHeaderByVariableName: 'Search header variable by name',
          variableFallbackValue: 'Variable Fallback Value',
          searchBodyVariableByName: 'Search body variable by name',
          searchSegmentByName: 'Search group by name',
          selectBroadcastUsing: 'Select broadcast using',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Select start date',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Import contacts for whatsapp broadcast',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'WhatsApp Number',
        errorCode: 'Error Code',
        errorMessage: 'Error Message',
        nA: 'NA',
      }
    },
    design: {
      customizeYourLandingPage: 'Customize Your Landing Page',
      customizeYourConversationalBot: 'Customize Your AI Bot',
      customizeYourBot: 'Customize Your Bot',
      createHeadingTextAndTheme: `Create heading text and theme that reflects your brand's personality`,
      content: 'Content',
      theme: 'Theme',
      layout: 'Layout',
      landingPageName: 'Landing Page Name',
      botName: 'Bot Name',
      charactersRemaining: ' characters remaining',
      landingPageHeader: 'Landing Page Header',
      landingPageDescription: 'Landing Page Description',
      hideContent: 'Hide Content',
      profilePic: 'Profile Pic',
      selectFile: 'Select File',
      socialMediaLinks: 'Social Media Links',
      initialMessage: 'Conversational bot initial message',
      flowTriggerDetail: 'Trigger to collect contact details',
      selectType: 'Select Type',
      url: 'URL',
      delete: 'Delete',
      editLink: 'Edit Link',
      addLink: 'Add Link',
      callToAction: 'Call to Action',
      liveChatWelcomeMessage: 'Live Chat Welcome Message',
      liveChatTriggerMessage: 'Live Chat Trigger Message',
      autoHideCallToAction: 'Auto Hide Call to Action',
      maxDelayShouldBe300Seconds: 'Max delay should be of 300 seconds',
      landingPageThemeColor: 'Landing Page Theme Color',
      themeColor: 'Theme Color',
      landingPageBackground: 'Landing Page Background',
      chatBackgroundColor: 'Chat Background Color',
      color: 'Color',
      gradient: 'Gradient',
      image: 'Image',
      video: 'Video',
      gradientStart: 'Gradient Start',
      gradientEnd: 'Gradient End',
      positionOnWeb: 'Position on web',
      positionOnMobile: 'Position on mobile',
      windowSize: 'Window size',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'Custom',
      height: 'Height',
      minimum300HeightIsRequired: 'Minimum 300px height is required',
      heightCannotExceed720: 'Height cannot exceed 720px',
      width: 'Width',
      px: 'px',
      minimum200WidthIsRequired: 'Minimum 200px width is required',
      widthCannotExceed1050: 'Width cannot exceed 1050px',
      discard: 'Discard',
      saveChanges: 'Save Changes',
      landingPageBot: 'Landing Page Bot',
      chatBot: 'Chatbot',
      preview: ' Preview',
      botIcon: 'Bot Icon',
      websiteBot: 'Website Chatbot',
      selectFont: 'Select Font',
      fontLibrary: 'Font Library',
      uploadFont: 'Upload Font',
      selectFontForBot: 'Select font for the bot',
      uploadedFont: 'Uploaded Font',
      placeholders: {
        enterName: 'Enter name',
        enterHeaderText: 'Enter header text',
        enterDescription: 'Enter description',
        enterUrl: 'Enter URL',
        howCanIHelpYou: 'How can I help you?',
        enterYourWelcomeMessageHere: 'Enter your welcome message here',
        enterYourLiveChatMessageHere: 'Enter your live chat message here',
        enterTimeInSeconds: 'Enter time in seconds',
        enterHeight: 'Enter height',
        enterWidth: 'Enter Width',
        flowInitialMessage: 'Enter initial question',
      },
      appTooltip: {
        standardLayout: 'Standard layout',
        rightLayout: 'Right layout',
        mergedLayout: 'Merged layout',
        enterNameOfYourBot: 'Enter name of your bot',
        enterNameOfYourLandingPageBot: 'Enter name of your landing page bot',
        headingTextThatAppearOnLandingPageBot: 'Enter the heading text that you want to appear on your landing page bot',
        descriptionTextThatAppearOnLandingPageBot: 'Enter the description text that you want to appear on your landing page bot.',
        addLinksAndSocialMediaIcons: 'Add links and social media icons',
        initialFlowMessage: 'Enter the first message that bot will send to initiate conversation with customer',
        anInvitingCatchPhrase: 'An inviting catch phrase that will make the user talk to bot',
        setAWelcomeMessageForYourVisitors: 'Set a welcome message for your visitors. This will be the first message in your conversation.',
        setAMessageToInitiateLiveChat: `Set a message to initiate live chat from the visitor's end. You will recieve a notification every time this message is triggered
        in any of your conversations.`,
        autoHideCallToAction: `Auto hide the 'Call To Action' text after specified time`,
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'You can choose to hide the logo and texts to show only the chat window on the screen',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Upload a logo for the chatbot page.',
        TOOLTIP_CHAT_THEME_COLOR: 'Choose color of your bot\'s chat window',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Choose background color of Bot\'s chat window',
        TOOLTIP_WEB_1ST_POSITION: 'Position your bot on the bottom-left of the bot user\'s desktop',
        TOOLTIP_WEB_2ND_POSITION: 'Position your bot on the middle right corner of the bot user\'s desktop',
        TOOLTIP_WEB_3RD_POSITION: 'Position your bot on the bottom right corner of the bot user\'s desktop',
        TOOLTIP_MOBILE_1ST_POSITION: 'Position your bot on the bottom left corner of the bot user\'s mobile',
        TOOLTIP_MOBILE_2ND_POSITION: 'Position your bot on the middle right corner of the bot user\'s mobile',
        TOOLTIP_MOBILE_3RD_POSITION: 'Position your bot on the bottom right corner of the bot user\'s mobile',
        TOOLTIP_WINDOW_HEIGHT: 'Resize bot\'s chat window',
        TOOLTIP_BOT_ICON: 'Select an icon or upload logo/image for your bot',
        flowTriggerMessage: 'Enter number of messages to be asked before collecting the contact details such as Name, Email & Phone',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Create Drip Campaign',
      youCanCreateMultipleCampaigns: 'You can create multiple campaigns',
      dripCampaignName: 'Drip Campaign Name',
      dripCampaignNameIsReuired: 'Drip Campaign Name is required',
      continue: 'Continue',
      manageDripCampaign: 'Manage Drip Campaign',
      youCanCreateMultipleCampaignsWith: 'You can create multiple drip campaigns with scheduled,' +
        ' automated messages to trigger responses from specific user groups.',
      createNewDripCampaign: 'Create New Drip Campaign',
      campaignName: 'Campaign Name',
      appliedOn: 'Applied On',
      segment: 'Group',
      template: 'Template',
      startDate: 'Start Date',
      action: 'Action',
      nA: 'NA',
      seeHowToUse: 'See how to use',
      deleteDripCampaign: 'Delete Drip Campaigns',
      wantToDeleteThisDripCampaign: 'Are you sure you want to delete this Drip Campaigns?',
      placeholders: {
        enterDripCampaignName: 'Enter Drip Campaign Name',
        searchByName: 'Search by name'
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Delete multiple drip campaigns',
        deleteDripCampaign: 'Delete Drip Campaign',
      },
      createDripCampaign: {
        dripCampaignName: 'Drip Campaign Name',
        appliedOn: 'Applied On',
        segments: 'Groups',
        selectDate: 'Select Date',
        scheduleYourTemplate: 'Schedule Your Templates',
        schedule: 'Schedule',
        template: 'Template',
        sendAfter: 'Send After',
        assignVariablesToTemplate: 'Assign variables to templates',
        addHeaderMedia: 'Add header Media ',
        selectFile: 'Select File',
        createDripCampaign: 'Create Drip Campaign',
        newSubscriber: 'New Subscriber',
        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days',
        placeholders: {
          enterDripName: 'Enter Drip name',
          selectAppliedOn: 'Select applied on',
          selectSegments: 'Select Groups',
          searchByName: 'Search by name',
          selectTemplate: 'Select Template',
          searchTemplateByName: 'Search Template By Name',
          enterSendAfter: 'Enter Send After',
          selectValueForThisVariable: 'Select value for this variable',
          searchHeaderVariableByName: 'Search Header Variable By Name',
          searchBodyVariableByName: 'Search body variable by name',
        },
        dateTimePicker: {
          selectStartDate: 'Select start date',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: `Configure your landing page's General settings `,
      configureBotsGeneralSettings: `Configure your bot's General settings`,
      selectTheTimeZone: 'Select the time-zone, language and a little note to your customers when the ',
      isNotAvailable: ` isn't available`,
      landingPagesBot: `landing page's bot`,
      bot: 'bot',
      waitingMessage: 'Waiting Message',
      landingPageBotStatus: 'Landing Page Bot Status',
      chatBotStatus: 'Chatbot Status',
      unavailabilityMessage: 'Un-availability message',
      sendIncompleteResponses: 'Send Incomplete Responses',
      language: 'Language',
      invalidateCache: 'Invalidate Cache',
      timezone: 'Time Zone',
      chatWindowMessageHistory: 'Chat Window Message History',
      session: 'session',
      forever: 'forever',
      english: 'English',
      spanish: 'Spanish',
      french: 'French',
      portuguese: 'Portuguese',
      german: 'German',
      italian: 'Italian',
      swedish: 'Swedish',
      arabic: 'Arabic',
      malay: 'Malay',
      russian: 'Russian',
      discard: 'Discard',
      saveChanges: 'Save Changes',
      seeHowToUse: 'See how to use',
      placeholders: {
        enterYourWaitingMessageHere: 'Enter your waiting message here',
        // selectLanguage: '',
        searchTimeZone: 'Search timezone',
        // selectChatRetentionPolicy: '',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Customize message as you wish it to appear on chat window immediately after a bot user sends request for Live Chat',
        TOOLTIP_CHATBOT_STATUS: 'Activate /deactivate the bot',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Customize message as you wish it to appear on chat window when none of your agents available for Live Chat with the bot user',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Send you email notifications even when the bot user hasn\'t finished the conversation.',
        TOOLTIP_LANGUAGE: 'Select the bot\'s language',
        TOOLTIP_INVALIDATE_CACHE: 'Click here to clear cache as and when you edit bot chat flow or settings as changes reflect only after 15 minutes',
        TOOLTIP_TIME_ZONE: 'Select the time-zone for your bot',
        TOOLTIP_CHAT_RETENTION: 'Select if you wish to retain bot user\'s chats forever on the chat window or only for a browser session.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Customize your chat window',
      controlAndCustomizeTheChatWindow: 'Control and customize the chat window your customers will interact with.',
      allowVisitorsToTransferChatToWhatsapp: 'Allow visitors to transfer chat to WhatsApp',
      allowVisitorsToRefreshChat: 'Allow visitors to Refresh chat',
      displayNameForTransferringChatToWhatsapp: 'Display name for Transferring a chat to Whatsapp',
      displayNameForTransferringToLiveChat: 'Display name for Transferring to Live/Agent chat',
      displayNameForTransferringAChatToBot: 'Display name for Transferring a chat to Bot',
      discard: 'Discard',
      saveChanges: 'Save Changes',
      allowVisitorsToTransferChatToBot: 'Allow visitors to transfer chat to Bot.',
      allowVisitorsToRequestForLiveChat: 'Allow visitors to request for live chat.',
      indicativeTextForCustomersReply: `Indicative Text for Customer's Reply`,
      displayNameForRestartingChatBot: 'Display name for Restarting Chatbot Conversation',
      seeHowToUse: 'See how to use',
      placeholders: {
        enterButtonNameForWhatsapp: 'Enter button name for transferring to WhatsApp',
        enterButtonNameForLiveChat: 'Enter button name for transferring to live chat or agent',
        enterButtonNameForBot: 'Enter button name for transferring to Bot',
        enterTextDisplayInEmptyReplyArea: 'Enter the text displayed in the empty reply area',
        enterButtonForRestartingConversation: 'Enter button name for Restarting Conversation',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Allow website visitors to transfer the chat to WhatsApp no. of the respective agent they\'re connected to. Live Chat feature must be enabled for this feature to work.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Allow the bot users to refresh chat so that they can start conversation afresh.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Allow the bot users to transfer the chat to bot anytime in-between conversation with the live chat agent.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Allow the bot users to request for live chat with the agent whenever they wish to.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'If you have enabled the user to transfer to WhatsApp, fill how you would like to display that option',
        ifYouHaveEnabledUserToTransferToLiveChat: 'If you have enabled the user to transfer to live chat, fill how you would like to display that option',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'If you have enabled the user to transfer to WhatsApp, fill how you would like to transfer back to bot',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Fill how you would like to display the reply space for customers',
        ifYouHaveEnabledUserToRestartChatBot: 'If you have enabled the user to restart the chatbot conversation, fill how you would like to display that option',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Chat Flows',
      dripCampaignHeader: 'Drip campaign',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Broadcast',
      ordersHeader: 'Orders',
      manageHeader: 'Manage',
      inputHeader: 'Input',
      apiKeyHeader: 'API Key',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Create a customer onboarding journey using a drip campaign of scheduled chat flows.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Customize the chat flow templates, and create multiple chat flows for different target audiences.',
        TOOLTIP_WIDGET_TITLE: 'Create widgets (chat interfaces) and embed them in promotional web pages to direct visitors to the bot',
        TOOLTIP_BROADCAST_TITLE: 'Blast messages or flows to your contacts on a single click at the pre-scheduled time',
        TOOLTIP_ORDERS_TITLE: 'List of orders placed by the customer',
        TOOLTIP_MANAGE_TITLE: 'Managing bot functionality for contacts/leads and keywords',
        TOOLTIP_INPUT_TITLE: 'Create WhatsApp templates, images and text as reply material for contacts/leads',
      },
      apiWrapper: {
        whatsappApiCredentials: 'WhatsApp API Credentials',
        generateYourWhatsappApiKey: 'Generate your WhatsApp API key to integrate with your custom developed apps.',
        generateApiKey: 'Generate Api Key',
        webhookUrl: 'Webhook URL',
        addHeader: 'Add Header',
        test: 'Test',
        webhookNotSet: 'Webhook Not Set',
        webhookHeaders: 'Webhook Headers',
        setWebhookUrl: 'Set Webhook Url',
        placeholders: {
          yourApiKey: 'Your Api Key',
          enterWebhookUrl: 'Enter Webhook Url',
          enterHeaderKey: 'Enter Header Key',
          enterValue: 'Enter Value',
        }
      },
      broadcast: {
        createBroadcast: 'Create Broadcast',
        createMultipleBroadcasts: 'You can create multiple broadcasts with multiple scheduled flows/templates for different customer groups.',
        broadcastName: 'Broadcast name',
        nameIsRequired: 'Name is required',
        selectType: 'Select Type',
        selectYourFlowType: 'Select your flow type',
        segment: 'Group',
        selectYourSegmentType: 'Select your group type',
        whenToSend: 'When to send',
        selectDate: 'Select Date',
        messageTag: 'Message Tag',
        selectMessageTag: 'Select message tag',
        contentType: 'Content Type',
        nonPromotional: 'Non-promotional (message tags applied)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Tag each message that comes before any interaction or after delay steps' +
          ' exceeding 24 hours with appropriate tag. untagged message will not reach subscriber' +
          ' who have interacted with the bot over 24 hours ago',
        promotional: 'Promotional (Message tags ignored)',
        theseBroadcasts: 'These broadcasts can contain promotions, but the target audience is limited to subscribers who' +
          'interacted with your bot in the last 24 hours.',
        createNewBroadcast: 'Create New Broadcast',
        sentMessages: 'Sent Messages',
        deliveredMessages: 'Delivered Messages',
        readMessages: 'Read Messages',
        failedMessages: 'Failed Messages',
        // createNewBroadcast: 'Create New Broadcast',
        type: 'Type',
        template: 'Template',
        sent: 'Sent',
        delivered: 'Delivered',
        read: 'Read',
        failed: 'Failed',
        broadcastAt: 'Broadcast At',
        status: 'Status',
        shipmentUpdate: 'Shipment Update',
        // template: 'Template',
        flow: 'Flow',
        resendFailedMessages: 'Resend Failed Messages',
        // sentMessages: 'Sent Messages',
        // deliveredMessages: 'Delivered Messages',
        goBack: 'Go Back',
        number: 'Number',
        errorCode: 'Error Code',
        failedAt: 'Failed At',
        badGateway: '502 bad gateway',
        loadMore: 'Load More',
        placeholders: {
          enterBroadcastName: 'Enter broadcast name',
          searchBroadcastByName: 'Search broadcast by name',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Total broadcast messages sent',
          totalBroadcastMessagesDelivered: 'Total broadcast messages delivered',
          totalBroadcastMessagesReadByContacts: 'Total broadcast messages read by the contacts',
          totalFailedBroadcastMessages: 'Total failed broadcast messages',
          clickToViewDetailedInformationOfFailedMessages: 'click to view detialed information of failed messages',
          clickToCreateNewBroadcast: 'Click to create new Broadcast',
          templateUsedInBroadcast: 'Template used in the broadcast',
          numberOfBroadcastMessagesSent: 'No. of broadcast messages sent',
          numberOfBroadcastMessagesDelivered: 'No. of broadcast messages delivered',
          numberOfBroadcastMessagesReadByContacts: 'No. of broadcast messages read by the contacts',
          numberOfTimesBroadcastMessagesHasFailed: 'No. of times broadcast messaging has failed',
          contactNumberOnWhichMessageDeliveryFailed: 'Contact number on which message delivery failed',
          unDeliveredTemplate: 'Undelivered template',
          errorReceivedForFailedMessage: 'Error received for failed message',
          timeWhenMessageDeliveryFailed: 'Time when message delivery failed',
          TOOLTIP_ADD_BROADCAST_NAME: 'Enter a new broadcast name',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Select if your are broadcasting a flow or a message.',
          TOOLTIP_BROADCAST_FLOW: 'Select the chat flow you wish to broadcast,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Select the contacts group to which you wish to broadcast',
          TOOLTIP_BROADCAST_NAME: 'Name of the broadcast',
          TOOLTIP_BROADCAST_TYPE: 'Flow or message selected for the broadcast',
          TOOLTIP_BROADCAST_SEGMENT: 'Group for which broadcast is created',
          TOOLTIP_BROADCAST_AT: 'Date and time scheduled for the broadcast',
          TOOLTIP_BROADCAST_STATUS: 'Broadcast status as scheduled, currently running or completed',
        }
      },
      drip: {
        createDripCampaign: 'Create Drip Campaign',
        youCanCreateMultipleCampaigns: 'You can create multiple campaigns',
        dripCampaignName: 'Drip Campaign Name',
        dripCampaignNameIsRequired: 'Drip Campaign name is required',
        // createDripCampaign: 'Create Drip campaign',
        youCanCreateMultipleDripCampaigns: 'You can create multiple drip campaigns with scheduled, automated messages to trigger responses from specific user groups.',
        campaignName: 'Campaign name',
        campaignNameIsRequired: 'Campaign name is required',
        after: 'After ',
        thisMessageWillBeSendAfter: 'This message will be send after',
        saveAndUpdate: 'Save & Update',
        schedule: ' Schedule',
        select: 'Select',
        messages: 'Messages',
        cancel: 'Cancel',
        createCampaign: 'Create Campaign',
        updateCampaign: 'Update Campaign',
        createNewCampaign: 'Create new campaign',
        // campaignName: 'Campaign name',
        messagesSent: 'Messages sent',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        action: 'Action',
        placeholders: {
          nameYourDripCampaign: 'Name your drip campaign',
          enterCampaignName: 'Enter campaign name',
          searchCampaignByName: 'Search campaign by name',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Click to add a new campaign',
          TOOLTIP_SCHEDULE: 'Plan when and which chat flow to send first in this campaign.',
          TOOLTIP_AFTER_DAYS: 'Schedule when to send the first flow of the campaign in minutes, hours, or days.',
          TOOLTIP_ADD_MESSAGES: 'Click to add a new flow to the campaign',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Enter a new campaign name',
          TOOLTIP_CAMPAIGN_NAME: 'Name of the campaign',
          TOOLTIP_NO_MESSAGES_SENT: 'No. of messages sent by the campaign',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Date and time of creation of the campaign',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Date and time when the campaign was last modified',
          TOOLTIP_CAMPAIGN_ACTION: 'Edit or delete the campaign',
          TOOLTIP_UPDATE_CAMPAIGN: 'Click to save the modifications made in campaign set-up',
        }
      },
      flows: {
        createChatFlow: 'Create Chat Flow',
        youCanCreateMultipleConversationsFlow: 'You can create multiple conversations flows across multiple channels for A/B testing and broadcasts.',
        flowName: 'Flow name',
        nameIsRequired: 'Name is required',
        cancel: 'Cancel',
        createFlow: 'Create Flow',
        createNewFlow: 'Create New Flow',
        // flowName: 'Flow name',
        ofMessages: 'of messages',
        triggered: 'Triggered',
        stepsFinsished: 'Steps finished',
        finished: 'Finished',
        createdOn: 'Created on',
        lastModified: 'Last modified',
        defaultFlow: 'Default flow',
        action: 'Action',
        flowAnalytics: 'Flow Analytics',
        placeholders: {
          enterFlowName: 'Enter flow name',
          searchFlowByName: 'Search flow by name',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'No. of times the chat flow is triggered',
          numberOfCompletedChatFlowStepsWhenTriggered: 'No. of completed chat flow steps when it is triggered',
          numberOfTimesUsersCompletedChatFlowSteps: 'No. of times users have completed all chat flow steps',
          dateAndTimeOfCreationOfChatFlow: 'Date and time of creation of the chat flow',
          dateAndTimeWhenChatFlowLastModified: 'Date and time when chat flow was last modified',
          TOOLTIP_CREATE_FLOW: 'Click to create a new chat flow',
          TOOLTIP_FLOW_NAME: 'Enter chat flow name',
          TOOLTIP_FLOW_TABLE_NAME: 'Name of the chat flow',
          TOOLTIP_NO_OF_MESSAGES: 'No. of message components in the chat flow',
          TOOLTIP_DEFAULT_FLOW: 'Use this toggle to set a chat flow as default, which will be served to all the new users.',
          TOOLTIP_ACTION: 'Edit or delete the chat flow',
        }
      },
      manage: {
        leads: 'Leads',
        manageBotsFunctionalityForContacts: `Manage bot's functionality for the contacts/leads`,
        whenToSendLeadInfo: 'When to send Lead Info',
        smartFlow: 'Smart Flow',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Welcome message when no keyword search is met',
        selectTemplates: 'Select Templates',
        selectFlows: 'Select Flows',
        selectText: 'Select Text',
        selectImages: 'Select Images',
        whenItAlmostReaches24HoursSinceMessage: 'When it almost reaches 24 hours since last message',
        // selectTemplates: 'Select Templates',
        updateChanges: 'Update Changes',
        manageBotsFunctionalityAccordingToKeywords: `Manage bot's functionality according to keywords input by the bot user, in case of smart flow`,
        addKeywordAction: 'Add Keyword Action',
        keywords: 'Keywords',
        matchingMethod: 'Matching Method',
        replyMaterial: 'Reply Material',
        action: 'Action',
        select: 'Select',
        placeholders: {
          keyword: 'keyword'
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Suggest if bot to send contact/lead information to integration CRM immediately, or after 5 minutes, or at the end of the flow',
          TOOLTIP_LEAD_SMART_FLOW: 'Choose any default flow to send it to all the bot users, or smart flow (determined by the keywords input by the bot user).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Select reply material (flow, template, text, image) to send if bot receives no keyword match from bot user\'s response',
          TOOLTIP_IT_ALMOST_REACHED: 'Select reply material to send to contacts/leads just before 24 hours of last messaging',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Manage bot\'s functionality according to keywords input by the bot user, in case of smart flow',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Select bot\'s reply as template, flow, text or image against specific keywords input',
          TOOLTIP_ADD_KEYWORD: 'Add keywords',
          TOOLTIP_MATCHING_METHOD: 'Add keyword match as \'exact\' or \'contains\'',
          TOOLTIP_REPLY_MATERIAL: 'Select template, flow , text or image to reply back for the suggested keyword',
          TOOLTIP_KEYWORD_ACTIONS: 'Delete the keyword action',
        },
        socialAlerts: {
          alerts: 'Alerts',
          specifyHowYourBotWillNotify: 'Specify how your bot will notify you about new leads.'
        }
      },
      orders: {
        manageCustomersOrder: 'Manage Customer Orders',
        checkAndManageAllOfYourCustomerOrders: 'Check and manage all of your customer orders and updates.',
        selectShipmentTemplate: 'Select Shipment Template',
        send: 'Send',
        export: 'Export',
        phoneNumber: 'Phone Number',
        numberOfItems: 'Number Of Items',
        orderValue: 'Order Value',
        orderDate: 'Order Date',
        status: 'Status',
        chat: 'Chat',
        item: 'Item',
        quantity: 'Quantity',
        amount: 'Amount',
        unitPrice: 'Unit Price',
        placeholders: {
          searchOrdersByPhoneNumber: 'Search orders by phone numbers',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Export orders data in csv file',
          TOOLTIP_ORDERS_PHONE_NO: 'Phone number of the customer who placed the order',
          TOOLTIP_NO_OF_ITEMS: 'No. of items ordered',
          TOOLTIP_ORDER_VALUE: 'Value of the order',
          TOOLTIP_ORDER_DATE: 'The order was placed on this date.',
          TOOLTIP_ORDER_STATUS: 'Status of order as confirmed, pending, rejected. or shipped',
          TOOLTIP_ORDER_CHAT: 'All previous chat conversations regarding this order',

        }
      },
      widgets: {
        installWidgetHeader: 'Install Widget',
        createWidgetHeader: 'Create Widget',
        copyTheCodeAndPasteItWhereYouWant: 'Copy the code and paste it whereever you need in your HTML, website etc.',
        youCanCreateMultipleWidgets: 'You can create multiple widgets to give your bot a different appearance on different web pages.',
        installWidget: {
          javascript: 'Javascript',
          install: 'Install ',
          onYourWebsite: ' on your website',
          copyCode: 'Copy code',
          copyAndPaste: 'Copy and paste',
          copyThisCodeSnippet: 'Copy this code snippet and insert it in the HTML of every web page where you want to'
            + ' display the Widget. For more details',
          seeGuide: 'see guide',
        },
        widgetList: {
          widgetName: 'Widget name',
          nameIsRequired: 'Name is required',
          cancel: 'Cancel',
          createWidget: 'Create Widget',
          icon: 'Icon',
          name: 'Name',
          createdAt: 'Created At',
          action: 'Action',
          placeholders: {
            enterWidgetName: 'Enter widget name',
            searchWidgetByName: 'Search widget by name',
          },
          appTooltip: {
            widgetIcon: 'Widget icon',
            nameOfWidget: 'Name of the widget',
            dateAndTimeOfCreationOfWidget: 'Date and time of creation of the widget',
            editOrDeleteWidget: 'Edit or delete the widget',
          }
        },
        widgetTemplates: {
          widgetName: 'Widget name',
          nameIsRequired: 'Name is required',
          chooseTypeOfWidget: 'Choose type of Widget',
          bar: 'Bar',
          slideIn: 'Slide In',
          pageTakeOver: 'Page Takeover',
          button: 'Button',
          facebookModal: 'Facebook Modal',
          comment: 'Comment',
          direct: 'Direct',
          whatsappIcon: 'WhatsApp Icon',
          refUrl: 'Ref URL',
          qrCode: 'QR Code',
          // refUrl: 'Ref URL',
          backgroundColor: 'Background Color',
          descriptionColor: 'Description Color',
          headlineColor: 'Headline Color',
          buttonBackgroundColor: 'Button Background Color',
          contactNumber: 'Contact Number',
          entryMessage: 'Entry Message',
          buttonText: 'Button Text',
          selectButtonText: 'Select Button text',
          buttonSize: 'Button Size',
          selectOne: 'Select One',
          placement: 'Placement',
          rightBottom: 'Right-Bottom',
          optInFlow: 'Opt-in Flow',
          selectFlow: 'Select Flow',
          addSubscriberToSequence: 'Add Subscriber to Sequence',
          selectDrip: 'Select Drip',
          whenDoesItDisplay: 'When does it display (Seconds)',
          showWidgetToSameUserAgainAfter: 'Show widget to the same user again after',
          ifManuallyClosedByUser: 'If manually closed by user, show after',
          // comment: 'Comment',
          specificPosts: 'Specific Posts',
          allPosts: 'All Posts',
          // entryMessage: 'Entry Message',
          postMessage: 'Post Message',
          postDate: 'Post Date',
          status: 'Status',
          action: 'Action',
          discard: 'Discard',
          saveChanges: 'Save Changes',
          nextStep: 'Next Step',
          widgetDescription: 'Widget Description',
          integrateTheBarWidget: `Integrate the Bar Widget in your website's body and increase your page subscriber` +
            ` along with sending then custom bot messages flow.`,
          hereIsYourWidgetHeadline: 'Here is your widget headline, click here to change it.',
          sendUsMessage: 'Send us Message',
          weAlsoPutDefaultTextHere: 'We also put default text here.Make sure turn it into valuable message',
          selectYourPagePost: 'Select Your Page Post',
          nA: 'NA',
          // entryMessage: 'Entry Message',
          saveRule: 'Save Rule',
          placeholders: {
            enterWidgetName: 'Enter widget name',
            enterNumber: 'Enter Number',
            enterMessageHere: 'Enter message here',
            enterTimeInSeconds: 'Enter time in seconds',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: `Customize your bot's trigger settings`,
      makeYourBotsTimingPerfect: `Make your bot's timing just perfect.`,
      onDesktopTriggerTime: 'On-Desktop Trigger time (seconds)',
      onMobileTriggerTime: 'On-Mobile Trigger time (seconds)',
      chatBubble: 'Chat bubble automatic opening',
      seconds: 'Seconds',
      discard: 'Discard',
      saveChanges: 'Save Changes',
      autoTriggerOnOrOff: 'Auto Trigger: On/Off',
      seeHowToUse: 'See how to use',
      alwaysOpenAfter: 'Always open after on above specified triggers',
      automaticallyAfterSelectedNumberOfTime: 'Automatically after selected amount of time',
      neverAutomaticallyOpenBot: 'Never automatically open the bot',
      placeholders: {
        enterTimeInSeconds: 'Enter time in seconds',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Waiting time in seconds, on visitor\'s desktop devices, before your bot automatically pops up',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Waiting time in seconds, on visitor\'s mobile, before your bot automatically pops up',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'When visitor closes the chat window, select how shall be the bot auto pop-up behavior',
        customerWaitingTimeToOpenBubble: 'Customer waiting time to open chat bubble',
        wantToDisableAutoTriggering: 'click if you want to disable auto triggering of bot on webpages'
      }
    },
    userAccess: {
      customizeYourBotsSettings: `Customize your bot's user settings`,
      decideWhoCanAccessYourBot: 'Decide who can access your bot',
      deviceSupport: 'Device Support',
      blockIpAddresses: 'Block IP Addresses',
      hideChatBotOnSpecificPages: 'Hide Chatbot on specific pages',
      selectCountriesToEnableBot: 'Select Countries to enable bot',
      discard: 'Discard',
      saveChanges: 'Save Changes',
      seeHowToUse: 'See how to use',
      allDevices: 'All Devices',
      mobileOnly: 'Mobile Only',
      desktopOnly: 'Desktop Only',
      placeholders: {
        enterIpAddress: `Enter IP address (ipv4 or ipv6) and press ' Enter '`,
        enterUrl: `Enter url of pages and press ' Enter '`,
        searchCountry: 'Search country',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Select on which devices your bot should appear',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Enter IP addresses where you don\'t wish your bot to appear',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Enter URL of your webpages where you don\'t wish your bot to appear',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Select locations from where you wish users to access your bot',
      }
    },
    facebook: {
      facebookBotSettings: 'Facebook Bot Settings',
      chatFlowsHeader: 'Chat Flows',
      dripCampaignHeader: 'Drip Campaign',
      broadcastHeader: 'Broadcast',
      widgetsHeader: 'Widgets',
      autoReplyHeader: 'Auto-Reply',
      generalHeader: 'General',
      facebookAutomation: 'Facebook Automation',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Customize the chat flow templates, and create multiple chat flows for different target audiences.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Create a customer onboarding journey using a drip campaign of scheduled chat flows.',
      },
      broadcasts: {
        createBroadcast: 'Create Broadcast',
        youCanCreateMultipleBroadcasts: 'You can create multiple broadcasts',
        broadcastName: 'Broadcast Name',
        broadcastNameIsRequired: 'Broadcast name is required',
        continue: 'Continue',
        manageBroadcasts: 'Manage Broadcasts',
        youCanCreateMultipleBroadcastsWith: 'You can create multiple broadcasts with multiple scheduled templates for different customer groups.',
        createNewBroadcast: 'Create New Broadcast',
        name: 'Name',
        segment: 'Group',
        template: 'Template',
        contacts: 'Contacts',
        send: 'Send',
        failed: 'Failed',
        broadcastAt: 'Broadcast At',
        status: 'Status',
        actions: 'Actions',
        nA: 'NA',
        deleteBroadcast: 'Delete Broadcast',
        wantToDeleteThisBroadcast: 'Are you sure you want to delete this Broadcast?',
        placeholders: {
          enterBroadcastName: 'Enter broadcast name',
          searchHere: 'Search here',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Delete multiple broadcasts',
          deleteBroadcast: 'Delete Broadcast'
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Broadcast name',
        broadcastNameIsRequired: 'Broadcast name is required',
        flow: 'Flow',
        segments: 'Groups',
        whenToSend: 'When to send',
        selectDate: 'Select Date',
        selectTimeSlot: 'Select Time Slot',
        createBroadcast: 'Create Broadcast',
        cancel: 'Cancel',
        now: 'Now',
        later: 'Later',
        placeholders: {
          enterBroadcastName: 'Enter Broadcast name',
          selectFlow: 'Select Flow',
          searchFlowByName: 'Search flow by name',
          selectSegments: 'Select Groups',
          searchSegmentByName: 'Search group by name',
          selectTimeSlot: 'Select Time Slot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Select start date',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Create Drip Campaign',
        youCanCreateMultipleCampaign: 'You can create multiple campaigns',
        dripCampaignName: 'Drip Campaign Name',
        dripCampaignNameIsRequired: 'Drip Campaign Name is required',
        continue: 'Continue',
        manageDripCampaign: 'Manage Drip Campaign',
        youCanCreateMutlipleDripCampaign: 'You can create multiple drip campaigns with scheduled, automated messages to trigger responses from specific user goups.',
        createNewDripCampaign: 'Create New Drip Campaign',
        campaignName: 'Campaign Name',
        appliedOn: 'Applied On',
        segment: 'Group',
        flow: 'Flow',
        startDate: 'Start Date',
        action: 'Action',
        nA: 'NA',
        deleteDripCampaign: 'Delete Drip Campaigns',
        wantToDeleteThisDripCampaign: 'Are you sure you want to delete this Drip Campaigns?',
        placeholders: {
          enterDripCampaignName: 'Enter Drip Campaign Name',
          searchByName: 'Search By Name',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Delete multiple drip campaigns',
          deleteDripCampaign: 'Delete Drip Campaign',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Drip Campaign Name',
          appliedOn: 'Applied On',
          segments: 'Groups',
          selectDate: 'Select Date',
          scheduleYourFlows: 'Schedule Your Flows',
          schedule: 'Schedule',
          flow: 'Flow',
          sendAfter: 'Send After',
          cancel: 'Cancel',
          newSubscriber: 'New Subscriber',
          days: 'Days',
          minutes: 'Minutes',
          hours: 'Hours',
          createDripCampaign: 'Create Drip Campaign',
          placeholders: {
            enterDripName: 'Enter Drip Name',
            selectAppliedOn: 'Select Applied On',
            selectSegment: 'Select Group',
            searchByName: 'Search By Name',
            selectTemplate: 'Select Template',
            searchFlowByName: 'Search Flow By Name',
            enterSendAfter: 'Enter Send After',
          },
          dateTimePicker: {
            selectStartDate: 'Select start date',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'Chat Id',
        errorCode: 'Error Code',
        errorMessage: 'Error Message',
        nA: 'NA',

      },
      flows: {
        chatFlows: 'Chat Flows',
        createChatFlow: 'Create Chat Flow',
        youCanCreateMultipleChatFlows: 'You can create multiple conversations flows across multiple channels for A/B testing and broadcasts.',
        flowName: 'Flow name',
        nameIsRequired: 'Name is required',
        cancel: 'Cancel',
        createFlow: 'Create Flow',
        importFlow: 'Import Flow',
        createNewFlow: 'Create New Flow',
        ofMessages: 'of messages',
        createdOn: 'Created on',
        lastModified: 'Last modified',
        defaultFlow: 'Default flow',
        action: 'Action',
        exportStatus: 'Export Status',
        flowExport: 'Flow- Export ',
        download: 'Download',
        // importFlow: 'Import Flow'
        beforeYouStartUploadPleaseMakeSure: 'Before you start upload please make sure',
        point1: '1. The maximum file size upload limit is 20MB',
        point2: '2. The File format should be in JSON',
        selectFile: 'Select File',
        inProgress: 'in progress',
        available: 'Available',
        initiated: 'Initiated',
        placeholders: {
          enterFlowName: 'Enter flow name',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Import flow for facebook bot',
          dateAndTimeOfCreationOfChatFlow: 'Date and time of creation of the chat flow',
          dateAndTimeWhenChatFlowLastModified: 'Date and time when chat flow was last modified',
          TOOLTIP_CREATE_FLOW: 'Click to create a new chat flow',
          TOOLTIP_FLOW_NAME: 'Enter chat flow name',
          TOOLTIP_FLOW_TABLE_NAME: 'Name of the chat flow',
          TOOLTIP_NO_OF_MESSAGES: 'No. of message components in the chat flow',
          TOOLTIP_DEFAULT_FLOW: 'Use this toggle to set a chat flow as default, which will be served to all the new users.',
          TOOLTIP_ACTION: 'Edit or delete the chat flow',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'No Flows added yet!',
        },
        modal: {
          deleteFlow: 'Delete Flow',
          wantToDeleteThisFlow: 'Are you sure you want to delete this Flow?',
        }
      },
      widgets: {
        manageWidgets: 'Manage Widgets',
        widgetsAreRoutingTools: 'Widgets are the routing tools to increase uses of bot',
        needHelp: 'Need Help?',
        wantToMakeChanges: 'Want to make changes?',
        editIcon: 'Edit Icon',
        discard: 'Discard',
        updateWidget: 'Update Widget',
        installChatBotIconOnYourWebsite: 'Install chatbot icon on your website',
        shareWhereEverYouWantToRedirectUsersTo: 'Share wherever you want & redirect users to your Facebook bot in single click',
        copyAndAddInYourWebsiteHeaderFile: 'Copy & add in your website header file',
        copyLinkAndStartSharing: 'Copy link & start sharing!',
        downloadQrCodeAndStartSharing: 'Download QR code & start sharing!',
        addCodeAndDeployNewCodeAndDone: 'Add the code, deploy new code and Done!',
        wantToLearnHowToUseIt: 'Want to learn how to use it?',
        copyChatBotCode: 'Copy Chatbot Code',
        copyFacebookLink: 'Copy Facebook Link',
        downloadQrCode: 'Download QR Code',
        link: 'Link',
        chatBotIcon: 'Chatbot Icon',
        qrcode: 'QR Code',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        leftMiddle: 'Left-Middle',
        rightMiddle: 'Right-Middle',
        leftBottom: 'Left-Bottom',
        rightBottom: 'Right-Bottom',
        bpDropDown: {
          selectIcons: 'Select Icons',
          iconSize: 'Icon Size',
          iconPlacement: 'Icon Placement',
        }
      },
      autoReply: {

        autoReplyTitle: 'Auto-Reply',
        configureYour: 'Configure your ',
        message: ' message',
        customizeAutoReplyMessagesForYourPostsComments: 'Customize auto reply messages for your post’s comments',
        wouldYouLikeToApply: 'Would you like to apply ',
        autoreply: 'Auto-Reply',
        onAllPost: ' on all post',
        configureYourMessageForEachPost: 'Configure your message for each post',
        configureFlowToTriggerAfterOnReply: 'Configure flow to trigger after on reply',
        whenUserLeavesACommentSendMessageAfter: 'When user leaves a comment, send message after',
        triggerFlowOnReply: 'Trigger flow on reply',
        add: 'Add',
        update: 'Update',
        save: 'Save',
        cancel: 'Cancel',
        discard: 'Discard',
        bpDropDown: {
          selectPost: 'Select post',
          triggerFlowOnReply: 'Trigger flow on reply',
          immendiately: 'Immediately',
          minutes: 'minutes'
        },
        bpTable: {
          message: 'Message',
          selectedPost: 'Selected Post',
          triggerFlow: 'Trigger Flow',
          actions: 'Actions'
        },
        placeholder: {
          configureYourMessage: 'Configure your message'
        },
        deleteModel: {
          title: 'Delete Post Auto-Reply',
          description: 'Are you sure you want to delete this post Auto-Reply?'
        }
      },
      generalSetting: {
        sendInactivityMessage: 'User Inactivity Message',
        sessionTimeOutInHours: 'Session Time Out',
        enterSessionTimeOutWindowInHours: 'Enter session time out window in hours',
        sessionTimeOutInHoursTooltip: `Enter the duration after which the chat session will automatically time out
         due to inactivity. Chatflow will restart if the user sends any message after the session time out`,
        restartChatFlowTriggers: 'Restart Chat Flow Triggers',
        enterKeyWordsToRestartChatAndhitEnter: 'Enter keywords to restart chat and hit enter',
        restartChatFlowTriggersTooltip: `Specify keywords here that, when entered by the user and submitted, will trigger a restart of the chat flow. This allows users to restart the conversation flow at any point.
        Hit enter after adding each keyword to add multiple keywords.`,
        liveChatTriggers: 'Live Chat Triggers ',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Enter keywords to trigger live chat and hit enter',
        liveChatTriggersTooltip: `Define keywords that, when entered by the user and submitted, will initiate a transition from automated chat to live chat. This is useful when users require assistance from a l
        ive agent. Hit enter after adding each keyword to add multiple keywords.`,
        messageAfterAutomatedFlowCompleted: 'Message after Automated flow completed',
        enterYourMessage: 'Enter your Message',
        messageAfterAutomatedFlowCompletedTooltip: ` Enter a message that will be displayed to the user after they have completed the automated
        chat flow but the session has not been timed out.`,
        restart: 'Restart',
        liveChat: 'Live Chat',
        lineOneForDefaultMessage: 'If you have more questions or need assistance, here\'s what you can do next:',
        to: 'To',
        restartTheChat: 'restart the chat',
        simpleTypeRestartAndHitEnter: ` simply type 'Restart' and hit enter. This will take you back to the beginning of our conversation flow.`,
        lineThreeForDefaultMessagePartOne: `If you'd like to speak to a`,
        liveAgent: `live agent,`,
        lineThreeForDefaultMessageLastPart: `type 'Live Chat' and hit enter. Our team is here to help you in real time.`,
        saveChanges: 'Save Changes',
        discard: 'Discard',
        configureBotsGeneralSettings: `Configure your bot's General settings`,
        minutes: 'Minutes',
        hours: 'Hours',
      }
    },
    instagram: {
      instagramBotSettings: 'Instagram Bot Settings',
      chatFlowsHeader: 'Chat Flows',
      broadcastHeader: 'Broadcast',
      dripCampaignHeader: 'Drip Campaign',
      widgetsHeader: 'Widgets',
      alertsHeader: 'Alerts',
      alerts: {
        configureYourBotsSettings: `Configure your bot's alert settings`,
        specifyHowYourBotNotifyYou: 'Specify how your bot will notify you about new leads.',
        subject: 'Subject',
        emailNotification: 'Email Notification',
        emailAddresses: 'Email Addresses',
        leadRevisitNotifications: 'Lead Revisit Notifications',
        whenToSendLeadInfo: 'When to send Lead Info',
        discard: 'Discard',
        saveChanges: 'Save Changes',
        placeholders: {
          enterSubjectForYourEmail: 'Enter Subject for your email',
          typeYourEmailAddressAndHitEnter: 'Type your email address and hit enter',
        }
      },
      broadcasts: {
        createBroadcast: 'Create Broadcast',
        youCanCreateMultipleBroadcasts: 'You can create multiple broadcasts',
        broadcastName: 'Broadcast Name',
        broadcastNameIsRequired: 'Broadcast name is required',
        continue: 'Continue',
        manageBroadcasts: 'Manage Broadcasts',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'You can create multiple broadcasts with multiple' +
          ' scheduled templates for different customer groups.',
        createNewBroadcast: 'Create New Broadcast',
        segment: 'Group',
        template: 'Template',
        contacts: 'Contacts',
        send: 'Send',
        failed: 'Failed',
        broadcastAt: 'Broadcast At',
        status: 'Status',
        actions: 'Actions',
        nA: 'NA',
        placeholders: {
          enterBroadcastName: 'Enter broadcast name',
          searchHere: 'Search here',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Delete multiple broadcasts',
          deleteBroadcast: 'Delete Broadcast',
        }
      },
      createBroadcast: {
        broadcastName: 'Broadcast name',
        broadcastNameIsRequired: 'Broadcast name is required',
        flow: 'Flow',
        segments: 'Groups',
        whenToSend: 'When to send',
        selectDate: 'Select Date',
        selectTimeSlot: 'Select Time Slot',
        cancel: 'Cancel',
        createBroadcast: 'Create Broadcast',
        placeholders: {
          enterBroadcastName: 'Enter Broadcast name',
          selectFlow: 'Select Flow',
          searchFlowByName: 'Search flow by name',
          selectSegments: 'Select Groups',
          searchSegmentByName: 'Search group by name',
          selectTimeSlot: 'Select Time Slot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Select start date',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Create Drip Campaign',
        youCanCreateMultipleCampaigns: 'You can create multiple campaigns',
        dripCampaignName: 'Drip Campaign Name',
        dripCampaignNameIsRequired: 'Drip Campaign Name is required',
        continue: 'Continue',
        manageDripCampaign: 'Manage Drip Campaign',
        youCanCreateMultipleCampaignsWith: 'You can create multiple drip campaigns with scheduled, automated messages to' +
          ' trigger responses from specific user groups.',
        createNewDripCampaign: 'Create New Drip Campaign',
        campaignName: 'Campaign Name',
        appliedOn: 'Applied On',
        segment: 'Group',
        flow: 'Flow',
        startDate: 'Start Date',
        action: 'Action',
        nA: 'NA',
        placeholders: {
          enterDripCampaignName: 'Enter Drip Campaign Name',
          searchByName: 'Search By Name',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Delete multiple drip campaigns',
          deleteDripCampaign: 'Delete Drip Campaign',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Drip Campaign Name',
          appliedOn: 'Applied On',
          segments: 'Groups',
          selectDate: 'Select Date',
          scheduleYourFlows: 'Schedule Your Flows',
          flow: 'Flow',
          sendAfter: 'Send After',
          cancel: 'Cancel',
          createDripCampaign: 'Create Drip Campaign',
          placeholders: {
            enterDripName: 'Enter Drip Name',
            selectAppliedOn: 'Select Applied On',
            selectSegment: 'Select Group',
            searchByName: 'Search By Name',
            selectTemplate: 'Select Template',
            searchFlowByName: 'Search Flow By Name',
            enterSendAfter: 'Enter Send After',
          },
          dateTimePicker: {
            selectStartDate: 'Select start date',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'Chat Id',
        errorCode: 'Error Code',
        errorMessage: 'Error Message',
        nA: 'NA',
      },
      flows: {
        chatFlows: 'Chat Flows',
        createChatFlow: 'Create Chat Flow',
        youCanCreateMultipleChatFlows: 'You can create multiple conversations flows across multiple channels for A/B testing and broadcasts.',
        flowName: 'Flow name',
        nameIsRequired: 'Name is required',
        cancel: 'Cancel',
        createFlow: 'Create Flow',
        importFlow: 'Import Flow',
        createNewFlow: 'Create New Flow',
        // flowName: 'Flow name'
        ofMessages: ' of messages',
        createdOn: 'Created on',
        lastModified: 'Last modified',
        defaultFlow: 'Default flow',
        action: 'Action',
        exportStatus: 'Export Status',
        flowExport: 'Flow- Export ',
        download: 'Download',
        // importFlow: 'Import Flow',
        beforeYouStartUploadPleaseMakeSure: 'Before you start upload please make sure',
        point1: '1. The maximum file size upload limit is 20MB',
        point2: '2. The File format should be in JSON',
        selectFile: 'Select File',
        placeholders: {
          enterFlowName: 'Enter flow name',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Import flow for instagram bot',
          dateAndTimeOfCreationOfChatFlow: 'Date and time of creation of the chat flow',
          dateAndTimeWhenChatFlowLastModified: 'Date and time when chat flow was last modified',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'No Flows added yet!',
        },
        modal: {
          deleteFlow: 'Delete Flow',
          wantToDeleteThisFlow: 'Are you sure you want to delete this Flow?',
        }
      },
      widgets: {
        manageWidgets: 'Manage Widgets',
        widgetsAreRoutingTools: 'Widgets are the routing tools to increase uses of bot',
        needHelp: 'Need Help?',
        wantToMakeChanges: 'Want to make changes?',
        editIcon: 'Edit Icon',
        discard: 'Discard',
        updateWidget: 'Update Widget',
        bpDropDown: {
          selectIcons: 'Select Icons',
          iconSize: 'Icon Size',
          iconPlacement: 'Icon Placement',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Telegram Bot Settings',
      chatFlowsHeader: 'Chat Flows',
      telegramProfileHeader: 'Telegram Profile',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Broadcast',
      dripCampaignHeader: 'Drip Campaign',
      generalHeader: 'General',
      telegramAutomation: 'Telegram Automation',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Create a customer onboarding journey using a drip campaign of scheduled chat flows.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Customize the chat flow templates, and create multiple chat flows for different target audiences.',
      },
      broadcasts: {
        createBroadcast: 'Create Broadcast',
        youCanCreateMutlipleBroadcasts: 'You can create multiple broadcasts',
        broadcastName: 'Broadcast Name',
        broadcastNameIsRequired: 'Broadcast name is required',
        continue: 'Continue',
        manageBroadcasts: 'Manage Broadcasts',
        youCanCreateMultipleBroadcastsWith: 'You can create multiple broadcasts with multiple scheduled templates for different customer groups.',
        createNewBroadcast: 'Create New Broadcast',
        name: 'Name',
        segment: 'Group',
        template: 'Template',
        contacts: 'Contacts',
        send: 'Send',
        failed: 'Failed',
        broadcastAt: 'Broadcast At',
        status: 'Status',
        actions: 'Actions',
        nA: 'NA',
        deleteBroadcast: 'Delete Broadcast',
        wantToDeleteThisBroadcast: 'Are you sure you want to delete this Broadcast?',
        placeholders: {
          enterBroadcastName: 'Enter broadcast name',
          searchHere: 'Search here',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Delete multiple broadcasts',
          deleteBroadcast: 'Delete Broadcast',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'Broadcast name',
        broadcastNameIsRequired: 'Broadcast name is required',
        flow: 'Flow',
        segments: 'Groups',
        whenToSend: 'When to send',
        selectDate: 'Select Date',
        selectTimeSlot: 'Select Time Slot',
        cancel: 'Cancel',
        createBroadcast: 'Create Broadcast',
        now: 'Now',
        later: 'Later',
        placeholders: {
          enterBroadcastName: 'Enter Broadcast name',
          selectFlow: 'Select Flow',
          searchFlowByName: 'Search flow by name',
          selectSegments: 'Select Groups',
          searchSegmentByName: 'Search group by name',
          selectTimeSlot: 'Select Time Slot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Select start date',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Create Drip Campaign',
        youCanCreateMultipleCampaigns: 'You can create multiple campaigns',
        dripCampaignName: 'Drip Campaign Name',
        dripCampaignNameIsRequired: 'Drip Campaign Name is required',
        continue: 'Continue',
        manageDripCampaign: 'Manage Drip Campaign',
        youCanCreateMultipleDripCampaignsWith: 'You can create multiple drip campaigns with scheduled, automated messages' +
          ' to trigger responses from specific user groups.',
        createNewDripCampaign: 'Create New Drip Campaign',
        campaignName: 'Campaign Name',
        appliedOn: 'Applied On',
        segment: 'Group',
        flow: 'Flow',
        startDate: 'Start Date',
        action: 'Action',
        nA: 'NA',
        deleteDripCampaign: 'Delete Drip Campaigns',
        wantToDeleteThisDripCampaign: 'Are you sure you want to delete this Drip Campaigns?',
        placeholders: {
          enterDripCampaignName: 'Enter Drip Campaign Name',
          searchByName: 'Search by name',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Delete multiple drip campaigns',
          deleteDripCampaign: 'Delete Drip Campaign',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Drip Campaign Name',
          appliedOn: 'Applied On',
          segments: 'Groups',
          startDate: 'Select Date',
          scheduleYourFlows: 'Schedule Your Flows',
          schedule: 'Schedule',
          flow: 'Flow',
          sendAfter: 'Send After',
          cancel: 'Cancel',
          createDripCampaign: 'Create Drip Campaign',
          newSubscriber: 'New Subscriber',
          days: 'Days',
          minutes: 'Minutes',
          hours: 'Hours',
          placeholders: {
            enterDripName: 'Enter Drip Name',
            selectAppliedOn: 'Select Applied On',
            selectSegment: 'Select Group',
            searchByName: 'Search by name',
            selectFlow: 'Select Flow',
            searchFlowByName: 'Search Flow By Name',
          },
          dateTimePicker: {
            selectStartDate: 'Select start date',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        errorCode: 'Error Code',
        errorMessage: 'Error Message',
        nA: 'NA',
      },
      flows: {
        chatFlows: 'Chat Flows',
        createChatFlow: 'Create Chat Flow',
        youCanCreateMultipleChatFlows: 'You can create multiple conversations flows across multiple channels for A/B testing and broadcasts.',
        flowName: 'Flow name',
        nameIsRequired: 'Name is required',
        cancel: 'Cancel',
        createFlow: 'Create Flow',
        importFlow: 'Import Flow',
        createNewFlow: 'Create New Flow',
        // flowName: 'Flow name',
        ofMessages: ' of messages',
        createdOn: 'Created on',
        lastModified: 'Last modified',
        defaultFlow: 'Default flow',
        action: 'Action',
        exportStatus: 'Export Status',
        flowExport: 'Flow- Export ',
        inProgress: 'in progress',
        available: 'Available',
        initiated: 'Initiated',
        download: 'Download',
        // importFlow: 'Import Flow',
        beforeYouStartUploadPleaseMakeSure: 'Before you start upload please make sure',
        point1: '1. The maximum file size upload limit is 20MB',
        point2: '2. The File format should be in JSON',
        selectFile: 'Select File',
        placeholders: {
          enterFlowName: 'Enter flow name',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Import flow for instagram bot',
          dateAndTimeOfCreationOfChatFlow: 'Date and time of creation of the chat flow',
          dateAndTimeWhenChatFlowLastModified: 'Date and time when chat flow was last modified',
          TOOLTIP_CREATE_FLOW: 'Click to create a new chat flow',
          TOOLTIP_FLOW_NAME: 'Enter chat flow name',
          TOOLTIP_FLOW_TABLE_NAME: 'Name of the chat flow',
          TOOLTIP_NO_OF_MESSAGES: 'No. of message components in the chat flow',
          TOOLTIP_DEFAULT_FLOW: 'Use this toggle to set a chat flow as default, which will be served to all the new users.',
          TOOLTIP_ACTION: 'Edit or delete the chat flow',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'No Flows added yet!',
        },
        modal: {
          deleteFlow: 'Delete Flow',
          wantToDeleteThisFlow: 'Are you sure you want to delete this Flow?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Telegram Profile',
        manageYourBotProfile: 'Manage your Bot Profile as and when you want.',
        allSetNoActionRequired: 'All Set, No Action Required!',
        congratulationYourAccountIsLive: 'Congratulations! Your account is live with full access',
        botDetails: 'Bot Details',
        name: 'Name',
        nA: 'NA',
        b: 'B',
        botName: 'Bot Name',
        bot: 'Bot',
        info: 'Info',
        none: 'None',
        username: 'Username',
        notifications: 'Notifications',
        on: 'On',
        addToGroupOrChannel: 'Add to Group or Channel',
        userName: 'Username',
        botToken: 'Bot Token',
      },
      widgets: {
        manageWidgets: 'Manage Widgets',
        widgetsAreRoutingTools: 'Widgets are the routing tools to increase uses of bot',
        needHelp: 'Need Help?',
        wantToMakeChanges: 'Want to make changes?',
        editIcon: 'Edit Icon',
        discard: 'Discard',
        updateWidget: 'Update Widget',
        installChatBotIconOnYourWebsite: 'Install chatbot icon on your website',
        shareWhereEverYouWantRedirectUsersTo: 'Share wherever you want & redirect users to your Telegram bot in single click',
        copyAndAddInYourWebsiteHeaderFile: 'Copy & add in your website header file',
        copyLinkAndStartSharing: 'Copy link & start sharing!',
        downloadQrCodeAndStartSharing: 'Download QR code & start sharing!',
        addCodeAndDeployNewCodeAndDone: 'Add the code, deploy new code and Done!',
        wantToLearnHowToUseIt: 'Want to learn how to use it?',
        copyChatBotCode: 'Copy Chatbot Code',
        downloadQrCode: 'Download QR Code',
        copyTelegramLink: 'Copy Telegram Link',
        link: 'Link',
        chatBotIcon: 'Chatbot Icon',
        qrcode: 'QR Code',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        leftMiddle: 'Left-Middle',
        rightMiddle: 'Right-Middle',
        leftBottom: 'Left-Bottom',
        rightBottom: 'Right-Bottom',
        bpDropDown: {
          selectIcons: 'Select Icons',
          iconSize: 'Icon Size',
          iconPlacement: 'Icon Placement',
        }
      },
      generalSetting: {
        sendInactivityMessage: 'User Inactivity Message',
        sessionTimeOutInHours: 'Session Time Out',
        enterSessionTimeOutWindowInHours: 'Enter session time out window in hours',
        sessionTimeOutInHoursTooltip: `Enter the duration after which the chat session will automatically time out
         due to inactivity. Chatflow will restart if the user sends any message after the session time out`,
        restartChatFlowTriggers: 'Restart Chat Flow Triggers',
        enterKeyWordsToRestartChatAndhitEnter: 'Enter keywords to restart chat and hit enter',
        restartChatFlowTriggersTooltip: `Specify keywords here that, when entered by the user and submitted, will trigger a restart of the chat flow. This allows users to restart the conversation flow at any point.
        Hit enter after adding each keyword to add multiple keywords.`,
        liveChatTriggers: 'Live Chat Triggers ',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Enter keywords to trigger live chat and hit enter',
        liveChatTriggersTooltip: `Define keywords that, when entered by the user and submitted, will initiate a transition from automated chat to live chat. This is useful when users require assistance from a l
        ive agent. Hit enter after adding each keyword to add multiple keywords.`,
        messageAfterAutomatedFlowCompleted: 'Message after Automated flow completed',
        enterYourMessage: 'Enter your Message',
        messageAfterAutomatedFlowCompletedTooltip: ` Enter a message that will be displayed to the user after they have completed the automated
        chat flow but the session has not been timed out.`,
        restart: 'Restart',
        liveChat: 'Live Chat',
        lineOneForDefaultMessage: 'If you have more questions or need assistance, here\'s what you can do next:',
        to: 'To',
        restartTheChat: 'restart the chat',
        simpleTypeRestartAndHitEnter: ` simply type 'Restart' and hit enter. This will take you back to the beginning of our conversation flow.`,
        lineThreeForDefaultMessagePartOne: `If you'd like to speak to a`,
        liveAgent: `live agent,`,
        lineThreeForDefaultMessageLastPart: `type 'Live Chat' and hit enter. Our team is here to help you in real time.`,
        saveChanges: 'Save Changes',
        discard: 'Discard',
        configureBotsGeneralSettings: `Configure your bot's General settings`,
        minutes: 'Minutes',
        hours: 'Hours',
      }
    },
    whatsapp: {
      whatsappAutomation: 'WhatsApp Automation',
      whatsappBotSettings: 'Whatsapp Bot Settings',
      generalHeader: 'General',
      chatFlowsHeader: 'Chat Flows',
      whatsappProfileHeader: 'WhatsApp profile',
      broadcastHeader: 'Broadcast',
      dripCampaignHeader: 'Drip Campaign',
      widgetHeader: 'Widget',
      templatesHeader: 'Templates',
      alertsHeader: 'Alerts',
      apiKeyHeader: 'API Key',
      catalogHeader: 'Catalog',
      otherConfiguration: 'AI Settings',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Customize the chat flow templates, and create multiple chat flows for different target audiences.',
      },
      alerts: {
        configureYourBotsAlertSettings: `Configure your bot's alert settings`,
        specifyHowYouBotWillNotify: 'Specify how your bot will notify you about new leads.',
        subject: 'Subject',
        emailNotification: 'Email Notification',
        emailAddresses: 'Email Addresses',
        leadRevisitNotifications: 'Lead Revisit Notifications',
        whenToSendLeadInfo: 'When to send Lead Info',
        discard: 'Discard',
        saveChanges: 'Save Changes',
        seeHowToUse: 'See how to use',
        beginningTheFlow: 'Beginning the Flow',
        fiveMinutesLater: '5 Minutes Later',
        endOfFlow: 'End of Flow',
        placeholders: {
          enterSubjectForYourEmail: 'Enter Subject for your email',
          typeEmailAndHitEnter: 'Type your email address and hit enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Customize subject of your email notifications from the bot',
          TOOLTIP_EMAIL_NOTIFICATION: 'Enable/disable the bot\'s notification on your email for new leads',
          TOOLTIP_EMAIL_ADDRESS: 'Submit email address of the account where you wish to receive email notifications. You can add multiple email addresses by entering one and pressing enter to add another.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Enable/disable bot\'s notification on your email if a user previously captured as a lead interacts with the bot again.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Update WhatsApp Chatbot',
        businessNameIsRequired: 'Business Name is required.',
        maxLengthForBusinessName40Characters: 'The maximum length for Business Name is 40 characters.',
        categoryIsRequired: 'Category is required.',
        descriptionIsRequired: 'Description is required.',
        maxLengthForDescription200Characters: 'The maximum length for description is 200 characters.',
        aboutIsRequired: 'About is required.',
        maxLengthForAbout139Characters: 'The maximum length for about is 139 characters.',
        pleaseFillAValidBusinessEmail: 'Please fill a valid business email.',
        businessEmailIsRequired: 'Business email is required.',
        websiteIsRequired: 'Website is required.',
        maxLengthForWebsite40Characters: 'The maximum length for Website is 40 characters.',
        maxLengthForAddress150Characters: 'The maximum length for address is 150 characters.',
        cancel: 'Cancel',
        update: 'Update',
        businessCategory: 'Business Category',
        placeholders: {
          yourBusinessName: 'Your Business Name',
          businessDescription: 'Business Description',
          about: 'About',
          businessEmail: 'Business Email',
          website: 'Website (e.g. www.example.com)',
          address: 'Address',
        }
      },
      flows: {
        chatFlows: 'Chat Flows',
        createChatFlow: 'Create Chat Flow',
        youCanCreateMultipleChatFlows: 'You can create multiple conversations flows across multiple channels for A/B testing and broadcasts.',
        flowName: 'Flow name',
        nameIsRequired: 'Name is required',
        cancel: 'Cancel',
        createFlow: 'Create Flow',
        importFlow: 'Import Flow',
        createNewFlow: 'Create New Flow',
        ofMessages: ' of messages',
        createdOn: 'Created on',
        lastModified: 'Last modified',
        defaultFlow: 'Default flow',
        revisitFlow: 'Revisit flow',
        action: 'Action',
        exportStatus: 'Export Status',
        flowExport: 'Flow- Export ',
        // importFlow: 'Import Flow',
        beforeYouStartUploadPleaseMakeSure: 'Before you start upload please make sure',
        point1: '1. The maximum file size upload limit is 20MB',
        point2: '2. The File format should be in JSON',
        selectFile: 'Select File',
        seeHowToUse: 'See how to use',
        download: 'Download',
        available: 'Available',
        initiated: 'Initiated',
        inProgress: 'in progress',
        placeholders: {
          enterFlowName: 'Enter flow name',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Import flow for whatsapp bot',
          dateAndTimeOfCreationOfChatFlow: 'Date and time of creation of the chat flow',
          dateAndTimeWhenChatFlowLastModified: 'Date and time when chat flow was last modified',
          TOOLTIP_CREATE_FLOW: 'Click to create a new chat flow',
          TOOLTIP_FLOW_NAME: 'Enter chat flow name',
          TOOLTIP_FLOW_TABLE_NAME: 'Name of the chat flow',
          TOOLTIP_NO_OF_MESSAGES: 'No. of message components in the chat flow',
          TOOLTIP_DEFAULT_FLOW: 'Use this toggle to set a chat flow as default, which will be served to all the new users.',
          TOOLTIP_REVISIT_FLOW: 'Use this toggle to select a chat flow to be served to users when they revisit your chatbot.',
          TOOLTIP_ACTION: 'Edit or delete the chat flow',
        },
        modal: {
          deleteFlow: 'Delete Flow',
          wantToDeleteThisFlow: 'Are you sure you want to delete this Flow?',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'No Flows added yet!',
        }
      },
      input: {
        manageTemplates: 'Manage Templates',
        manageYourTemplatesForMarketingAutomation: 'Manage your templates for the marketing automation',
        syncTemplates: 'Sync Templates',
        createTemplateHeader: 'Create Template',
        title: 'Title',
        info: 'Info',
        category: 'Category',
        status: 'Status',
        action: 'Action',
        shipmentUpdate: 'Shipment Update',
        name: 'Name',
        // category: 'Category',
        headerOptional: 'Header (Optional)',
        bodyRequired: 'Body (required)',
        footerOptional: 'Footer (optional)',
        buttonsOptional: 'Buttons (optional)',
        makeUpto3Buttons: 'Make up to 3 buttons',
        makeUpto2ButtonsToRedirect: 'Make up to 2 buttons to redirect customer to your website',
        placeholders: {
          nameYourTemplate: 'Name your template (use underscore to separate words)',
          enterText: 'Enter text (max 60 characters)',
          enterImageUrl: 'Enter image URL',
          enterVideoUrl: 'Enter Video URL',
          templateMessage: 'Template Message',
          smallFooterAtBottom: 'Small footer at the bottom',
          buttons: 'Buttons',
          text: 'Text',
          numberWithCountryCode: 'Number with country code',
          enterButtonText: 'Enter Button Text (max character 20)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Title of the whatsapp template',
          TOOLTIP_INPUT_TABLE_INFO: 'Detailed information of the business initiated messages',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Type of business initiated messages like appointments, reminders, customer care messages, shipping updates, alerts and more',
          TOOLTIP_INPUT_TABLE_STATUS: 'Template status is rejected or approved by Whatsapp. Whatsapp Templates have to be first approved by Whatsapp and this is a paid service.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Delete the template',
        },
        noDataFound: {
          templates: 'Templates',
          noTemplatesCreatedYet: 'No Templates created yet!',
        },
        modal: {
          deleteTemplate: 'Delete Template',
          wantToDeleteThisTemplate: 'Are you sure you want to delete this Template?',
        },
        createTemplate: {
          createTemplates: 'Create Templates',
          createTemplatesForMarketingAutomation: 'Create your templates for the marketing automation',
          templateMeta: 'Template Meta',
          selectLanguage: 'Select language',
          header: 'Header',
          optional: 'Optional',
          samplesForHeaderContent: 'Samples for header content',
          toHelpMetaReviewYourContent: 'To help meta review your content, provide examples of the variables or media in the header. Do not include any customer information.',
          body: 'Body',
          addVariable: 'Add variable',
          samplesForBodyContent: 'Samples for body content',
          toHelpUsReviewYourContent: 'To help us review your content, provide examples of the variables in the body. Do not include any customer information.',
          footer: 'Footer',
          // optional: 'Optional',
          buttons: 'Buttons',
          // optional: 'Optional',
          typeOfAction: 'Type of Action',
          call: 'Call',
          phone: 'Phone',
          website: 'Website',
          static: 'Static',
          urlType: 'URL Type',
          type: 'Type',
          customButton: 'Custom Button',
          addAnotherButton: 'Add another button',
          preview: 'Preview',
          test: 'Test',
          create: 'Create',
          addSampleUrl: 'Add Sample URL',
          toHelpMetaReviewYourMessage: 'To help meta review your message template, please add an example of the website URL. Do not use real customer information.',
          placeholders: {
            enterMessageTemplateName: 'Enter message template name',
            searchTemplateByName: 'Search template by name',
            selectTemplate: 'Select Template',
            enterText: 'Enter Text',
            enterBody: 'Enter Body',
            enterContentForVariable: 'Enter content for variable',
            enterFooter: 'Enter Footer',
            phone: 'Phone*',
            enterWebsiteUrl: 'Enter website url',
          },
          bpInput: {
            name: 'Name',
            buttonText: 'Button Text',
            websiteUrl: 'Website Url',
          },
          bpDropDown: {
            selectCategory: 'Select Category',
          }
        },
        templatePreview: {
          preview: 'Preview',
          test: 'Test',
        },
        templateConfig: {
          saveChanges: 'Save Changes',
          discard: 'Discard',
          triggerChatFlowOnTemplateReply: 'Trigger Chat Flow on Template Reply',
          templateDetails: 'Template Details',
          configureFlows: 'Configure Flows',
          selectFlow: 'Select Flow',
          name: 'Name'
        }
      },
      shopManagement: {
        shopManagement: 'Shop Management',
        manageECommerceShopFromBotPenguin: 'Manage e-commerce shops connected with BotPenguin',
        comingSoon: '( Coming Soon )',
        wooCommerce: {
          createConfiguration: 'Create Configuration',
          eventConfiguration: {
            createConfiguration: 'Create Configuration',
            templateHeader: 'Template Header',
            selectFile: 'Select File',
            templateMessage: 'Template Message',
            saveChanges: 'Save Changes',
            bpDropDown: {
              selectEvent: 'Select Event',
              selectTemplate: 'Select Template',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'WhatsApp Profile',
        manageYourBotProfile: 'Manage your Bot Profile as and when you want.',
        allSetNoActionRequired: 'All Set, No Action Required!',
        congratulationsYourAccountIsLive: 'Congratulations! Your account is live with full access',
        somethingIsWrongReactivateYourBot: 'Something is Wrong, Re-activate your bot!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'It seems like your bot is not working properly, Please reset your bot',
        reConfigureYourBot: 'Re-configure your bot',
        phoneNumberDetails: 'Phone Number Details',
        phoneNumber: 'Phone Number',
        nA: 'NA',
        phoneNumberId: 'Phone Number ID',
        wabaId: 'WABA ID',
        change: 'Change',
        accessToken: 'Access Token',
        // change: 'Change',
        messagingLimit: 'Messaging Limit',
        qualityRating: 'Quality Rating',
        whatsappBusinessAccountName: 'WhatsApp Business Account Name',
        whatsappPublicProfile: 'WhatsApp Public Profile',
        profilePicture: 'Profile Picture',
        aboutText: 'About text',
        address: 'Address',
        description: 'Description',
        email: 'Email',
        websitePrimary: 'Website (Primary)',
        websiteSecondary: 'Website (Secondary)',
        businessVertical: 'Business Vertical',
        editProfile: 'Edit Profile',
        thisIsABusinessAccount: 'This is a business account',
        aboutAndPhoneNumber: 'About and Phone Number',
        yourTrialAccountIsLive: 'Congratulations! Your trial account is live with full access on your verified number',
        updateWithNewAccessToken: 'Update with new accessToken',
      },
      widgets: {
        manageWidgets: 'Manage Widgets',
        widgetsAreRoutingTools: 'Widgets are the routing tools to increase uses of bot',
        needHelp: 'Need Help?',
        wantToMakeChanges: 'Want to make changes?',
        prePopulatedMessage: 'Pre-populated Message',
        discard: 'Discard',
        updateWidget: 'Update Widget',
        hiThere: 'hi there ',
        seeHowToUse: 'See how to use',
        editIcon: 'Edit Icon',
        editPrePopulatedMessage: 'Edit Pre-populated Message',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        leftMiddle: 'Left-Middle',
        rightMiddle: 'Right-Middle',
        leftBottom: 'Left-Bottom',
        rightBottom: 'Right-Bottom',
        chatBotIcon: 'ChatBot Icon',
        link: 'Link',
        qrcode: 'QR Code',
        copyChatBotCode: 'Copy Chatbot Code',
        copyWALink: 'Copy WA Link',
        downloadQrCode: 'Download QR Code',
        installChatBotIconOnYourWebsite: 'Install chatbot icon on your website',
        shareWhereEverYouWantRedirectUsersTo: 'Share wherever you want & redirect users to your WhatsApp bot in single click',
        copyAndAddInYourWebsiteHeaderFile: 'Copy & add in your website header file',
        copyLinkAndStartSharing: 'Copy link & start sharing!',
        downloadQrCodeAndStartSharing: 'Download QR code & start sharing!',
        addCodeAndDeployNewCodeAndDone: 'Add the code, deploy new code and Done!',
        wantToLearnHowToUseIt: 'Want to learn how to use it?',
        placeholders: {
          enterWhatsappNumber: 'Enter Whatsapp number',
          updateYourMessageHere: 'Update your message here...',
        },
        bpDropDown: {
          selectIcons: 'Select Icons',
          iconSize: 'Icon Size',
          iconPlacement: 'Icon Placement',
        },
        bpInput: {
          whatsappNumber: 'WhatsApp Number',
        }
      },
      catalog: {
        createCatalog: 'Create Catalogue',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Create product catalogue on the Meta Commerce Manager or link it with Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Use your Meta commerce manager to manage catalogues',
        connectCatalogToWhatsAppAccount: 'Connect Catalogue to WhatsApp account',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Connect your catalogues to WhatsApp account using your WhatsApp manager',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Use your Meta WhatsApp manager to connect catalogues and use them with BotPenguin',
        goToMeta: 'Go to Meta',
        goToShopify: 'Go to Shopify',
        next: 'Next',
        back: 'Back',
        setupCatalog: 'Setup Catalog',
        completeAllTheStepsToEnableCatalogMessages: 'Complete all steps to enable catalog messages'
      },
      generalSetting: {
        sendInactivityMessage: 'User Inactivity Message',
        sessionTimeOutInHours: 'Session Time Out',
        enterSessionTimeOutWindowInHours: 'Enter session time out window in hours',
        sessionTimeOutInHoursTooltip: `Enter the duration after which the chat session will automatically time out
         due to inactivity. Chatflow will restart if the user sends any message after the session time out`,
        restartChatFlowTriggers: 'Restart Chat Flow Triggers',
        enterKeyWordsToRestartChatAndhitEnter: 'Enter keywords to restart chat and hit enter',
        restartChatFlowTriggersTooltip: `Specify keywords here that, when entered by the user and submitted, will trigger a restart of the chat flow. This allows users to restart the conversation flow at any point.
        Hit enter after adding each keyword to add multiple keywords.`,
        liveChatTriggers: 'Live Chat Triggers ',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Enter keywords to trigger live chat and hit enter',
        liveChatTriggersTooltip: `Define keywords that, when entered by the user and submitted, will initiate a transition from automated chat to live chat. This is useful when users require assistance from a l
        ive agent. Hit enter after adding each keyword to add multiple keywords.`,
        messageAfterAutomatedFlowCompleted: 'Message after Automated flow completed',
        enterYourMessage: 'Enter your Message',
        messageAfterAutomatedFlowCompletedTooltip: ` Enter a message that will be displayed to the user after they have completed the automated
        chat flow but the session has not been timed out.`,
        restart: 'Restart',
        liveChat: 'Live Chat',
        lineOneForDefaultMessage: 'If you have more questions or need assistance, here\'s what you can do next:',
        to: 'To',
        restartTheChat: 'restart the chat',
        simpleTypeRestartAndHitEnter: ` simply type 'Restart' and hit enter. This will take you back to the beginning of our conversation flow.`,
        lineThreeForDefaultMessagePartOne: `If you'd like to speak to a`,
        liveAgent: `live agent,`,
        lineThreeForDefaultMessageLastPart: `type 'Live Chat' and hit enter. Our team is here to help you in real time.`,
        saveChanges: 'Save Changes',
        discard: 'Discard',
        configureBotsGeneralSettings: `Configure your bot's General settings`,
        minutes: 'Minutes',
        hours: 'Hours',
      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'Just a Step Away',
      seeHowToUse: 'See how to use',
      testWhatsAppTrialBot: 'Test your Whatsapp Trial Chatbot',
      sendMessage: 'Send Message',
      sendWhatsAppMessageOn: 'Send us a WhatsApp message on ',
      bySavingInYourContact: 'by saving in your contacts and message should start with ',
      connect: 'Connect',
      or: 'OR',
      scanQRCode: 'Scan the QR code',
      yourPhoneWillOpen: 'Your phone will open a pre-filled message to send to our Sandbox number. Hit ‘Send’ on WhatsApp',
      sendVerificationCode: 'Send Verification Code',
      copyAndEnterCode: 'Copy and enter 6-digit code',
      shareWhereYouWant: 'Share wherever you want & redirect users to your WhatsApp bot in single click',
      youShouldHaveReceived: 'You should have received 6 digit code in your WhatsApp Number',
      EnterYourPhoneNumber: 'Enter your Phone Number',
      send: 'Send',
      sixDigitCode: 'Enter 6-digit code',
      pleaseNote: 'Please note: 6- digit verification code can take upto 3 minutes, Please wait before you resend it.',
      resendVerificationCode: 'Resend verification code',
      verify: 'Verify',
      providePhoneNumber: 'Please provide the number you want to use for testing',
      demoBotisReady: 'Awesome! Your demo bot is ready to use',
      placeHolder: {
        phone: 'Enter your phone number',
        code: 'Enter verification code',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Third-party Apps Integration',
    thirdPartyApps: 'Third-party Apps',
    customApps: 'Custom Apps',
    preview: 'Preview',
    test: 'Test',
    install: 'Install',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Click to install your bot to the selected platform',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Integrate your custom created Apps with your bot',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Connect your bot with available third-party Apps/CRMs',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Third-party Apps Integrations',
        integrateYourBotWithListedCrms: 'Integrate your bot with one of the listed Apps/CRMs.',
        help: 'Help',
        name: 'Name',
        status: 'Status',
        action: 'Action',
        chatGpt: 'ChatGPT',
        addOn: 'Add-on',
        purchased: 'Purchased',
        readyToRideGptWave: 'Ready to ride the GPT wave? Integrate now.',
        purchase: 'Purchase',
        notConnected: 'Not Connected',
        integrated: 'Integrated',
        configure: 'Configure',
        connected: 'Connected',
        edit: 'Edit',
        connect: 'Connect',
        requestYourIntegration: 'Request your Integration',
        toRequestNewIntegration: 'To request new integration, Please enter below mentioned details and' +
          ' then we will get back to you.',
        // name: 'Name',
        emailId: 'Email ID',
        integrationName: 'Integration Name',
        contactNumber: 'Contact Number',
        howSoonYouWantToAddThisIntegration: 'How soon you want to add this Integration',
        sendRequest: 'Send Request',
        cancel: 'Cancel',
        requestSent: 'Request Sent!',
        yourRequestSuccessfullySent: 'Your request for new integration has been sent successfully.',
        goBack: 'Go Back',
        disconnect: 'Disconnect',
        OopsIntegrationAreNotAvailable: 'Oops! Integrations are not available in ',
        pleaseUpgradeTo: ' Please upgrade to ',
        planForIntegration: ' for integrations.',
        placeholders: {
          searchIntegrationByName: 'Search integration by name',
          enterTeamMemberName: 'Enter Team member name',
          enterEmailId: 'Enter Email ID',
          enterIntegrationName: 'Enter a Integration Name',
          searchCountry: 'Search Country',
          enterContactNumber: 'Enter Contact Number',
          addComment: 'Add Comment',
        },
        noDataFound: {
          integrations: 'Integrations',
          noIntegrationsFound: 'No Integrations found!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Name of App/CRM',
          statusAsConnectedOrNotConnected: 'Status as connected / not-connected',
          clickToConnectToAppOrCrm: 'Click to connect the App/CRM',
        },
        feedbackModal: {
          submit: 'Submit',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          agileCrmDomain: 'Agile CRM Domain',
          nameIsRequired: 'Name is required',
          emailAddress: 'Email Address',
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameter: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'example@company.extention',
            enterTokenHere: 'Enter token here',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'Api Key',
          accessKey: 'Access Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiKeyHere: 'Enter Api Key Here',
            enterAccessKeyHere: 'Enter Access Key Here',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          nameColon: 'Name:',
          inboundWebhookUrl: 'Inbound webhook url',
          nameIsRequired: 'Name is required',
          leadTitle: 'Lead Title',
          source: 'Source',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterInboundWebhookUrl: 'Enter Inbound webhook url...',
            enterTitle: 'Enter title',
            enterSource: 'Enter source'
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          testLead: 'Test Lead',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          testLead: 'Test Lead',
          connect: 'Connect',
          selectTheQuestion: 'Select the question whose user reply you want to send as value',
          mapWith: 'map with',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
            attributes: 'Attributes(key)',
            values: 'values',
          }
        },
        chatGpt: {
          chatGptApiKey: 'ChatGPT (Open AI) API key',
          chatGpt: 'ChatGPT (Open AI)',
          readyToRideWave: 'Ready to ride the GPT wave? Integrate now.',
          doNotHaveAnyApiKeyNeedHelp: `Don't have an API key, Need Help?`,
          authenticate: 'Authenticate',
          nowYouCanAccessChatGpt: 'Now you can access Chat GPT component in chat flow builder',
          paymentSuccessful: 'Payment successful!',
          paymentHasBeenCompletedSuccessfully: 'Payment has been completed successfully. You are all set to train your bot',
          cancel: 'Cancel',
          trainNow: 'Train Now',
          paymentInProgress: 'Payment In Progress...',
          apiKey: 'ChatGPT API Key',
          model: 'Model',
          integrateWithChatGPT: 'Integrate with ChatGPT',
          updateKey: 'Update Key',
          connect: 'Connect',
          update: 'Update',
          placeholders: {
            chatGptApiKey: 'ChatGPT (Open AI) API key',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          leadId: 'Lead Id',
          selectLeadId: 'Select LeadId',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter token Here',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          emailAddress: 'Email Address',
          leadTitle: 'Lead Title',
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            exampleEmail: 'example@company.extention',
            enterYourLeadTitle: 'Enter your Lead Title',
            enterTokenHere: 'Enter token here',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          dripCrmAccountId: 'Drip CRM Account Id',
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterAccountIdHere: 'Enter Account Id here',
            enterYourTokenHere: 'Enter your token here',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apikey: 'Api Key',
          accessToken: 'Access Token',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          selectParameter: 'Select Parameter',
          disconnect: 'Disconnect',
          update: 'Update',
          save: 'Save',
          placeholders: {
            enterApiKeyHere: 'Enter Api Key Here',
            enterTokenHere: 'Enter Token Here',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          questionColon: 'Question:',
          testLead: 'Test Lead',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          authToken: 'AuthToken',
          source: 'Source',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterAuthTokenHere: 'Enter AuthToken Here',
            enterSourceHere: 'Enter Source Here'
          }
        },
        facebookPixel: {
          facebookPixel: 'Facebook Pixel',
          getInsight: `Get Insights that turn your business into an enterprise with BotPenguin's Google Analytics Integration`,
          pixelId: 'Facebook Pixel Id',
          connect: 'Connect',
          disconnect: 'Disconnect',
          placeholders: {
            enterYourId: 'Enter Facebook Pixel Id'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          companyName: 'Company name',
          emailAddress: 'Email Address',
          password: 'Password',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapsWith: 'maps with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterCompanyName: 'Enter Company name',
            enterEmailAddress: 'Enter email address',
            enterPassword: 'Enter Password',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiKeyHere: 'Enter apiKey Here',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          flowluDomain: 'Flowlu Domain',
          apiToken: 'API Token',
          title: 'Title',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'Enter Token Here',
            enterYourLeadTitle: 'Enter your Lead Title'
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          freshdeskDomain: 'Freshdesk Domain',
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'Enter Token Here',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          freshworkCrmDomain: 'Freshwork CRM Domain',
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'Enter Token Here'
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          mapWith: 'map with',
          select: 'Select',
          update: 'Update',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          campaignList: 'Campaign List',
          selectCampaign: 'Select Campaign',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          getInsights: `Get Insights that turn your business into an enterprise with BotPenguin's Google Analytics Integration`,
          measurementId: 'Measurement Id ',
          connect: 'Connect',
          disconnect: 'Disconnect',
          placeholders: {
            enterYourId: 'Enter your id',
          }
        },
        googleCalendar: {
          testLead: 'Test Lead',
          name: 'Name:',
          email: 'Email:',
          appointmentSummary: 'Appointment Summary',
          appointmentDescription: 'Appointment Description',
          addParameters: ' Add Parameters',
          questionColon: 'Question:',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          pause: 'Pause',
          resume: 'Resume',
          disconnect: 'Disconnect',
          reconnect: 'Reconnect',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          name: 'Name :',
          email: 'Email : ',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
        },
        googleRedirect: {

        },
        googleSheet: {
          testLead: 'Test Lead',
          title: 'Title',
          questionColon: 'Question:',
          addParameters: 'Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          update: 'Update',
          save: 'Save',
          pause: 'Pause',
          resume: 'Resume',
          disconnect: 'Disconnect',
          reconnect: 'Reconnect',
          placeholders: {
            mappingValues: 'Mapping Values',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          name: 'Name:',
          email: 'Email:',
          taskList: 'Task List',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          pause: 'Pause',
          resume: 'Resume',
          disconnect: 'Disconnect',
          reconnect: 'Reconnect',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          select: 'Select',
          addParameters: ' Add Parameters',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiHere: 'Enter api Here',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          testLead: 'Test Lead',
          accountId: 'Account Id',
          accessToken: 'Access Token',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterAccountIdHere: 'Enter Account-Id Here',
            enterAccessTokenHere: 'Enter Access Token Here',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Key',
          connect: 'Connect',
          questionColon: 'Question:',
          testLead: 'Test Lead',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        helpLink: {
          help: 'Help',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          helpRaceCrmDomain: 'Helprace CRM Domain',
          emailAddress: 'Email Address',
          apiToken: 'API Token',
          connect: 'Connect',
          update: 'Update',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'example@company.extension',
            token: 'Token',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          domainName: 'Domain Name',
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiHere: 'Enter api Here',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          emailAddress: 'Email Address',
          apiUrl: 'API Url',
          connect: 'Connect',
          testLead: 'Test Lead',
          // emailAddress: 'Email Address',
          selectProjectName: 'Select Project Name',
          selectIssueType: 'Select Issue Type',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapsWith: 'maps with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter token Here',
            enterEmailAddress: 'Enter email address',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiUrl: 'API URL',
          nameIsRequired: 'Name is required',
          apiKey: 'API Key',
          apiId: 'API ID',
          // nameIsRequired: 'Name is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          mapWith: 'map with',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          selectTheQuestion: 'Select the question whose user reply you want to send as value',
          placeholders: {
            enterApiUrl: 'Enter the API Url',
            enterApiKey: 'Enter the API Key',
            enterApiId: 'Enter the API Id',
            attributes: 'Attributes(key)',
            values: 'values',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          accessKey: 'Access Key',
          secretKey: 'Secret Key',
          connect: 'Connect',
          apiLogs: 'API Logs',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          udpate: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          filterApplied: 'filter applied',
          retry: 'Retry',
          back: 'Back',
          filterYourLeads: 'Filter your leads',
          filterOn: 'Filter on',
          filterBy: 'Filter by ',
          discard: 'Discard',
          applyFilter: 'Apply Filter',
          loadMore: 'Load More',
          placeholders: {
            enterAccessKeyHere: 'Enter Access Key Here',
            enterSecretKeyHere: 'Enter Secret Key Here',
            attributes: 'Attributes(key)',
            values: 'values',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Filter data based on response',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          apiUrl: 'API Url',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiHere: 'Enter api Here',
            exampleUrl: 'https://comapanyName.ladesk.com'
          }
        },
        microsoftTeams: {
          downloadPackage: 'Download the Microsoft teams app package',
          downloadApp: 'Download App',
          wantToLearnHowToUploadIt: 'Want to learn how to upload it?',
          needHelp: 'Need Help?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiUrl: 'API URL',
          appId: 'APP ID',
          nameIsRequired: 'App ID is required',
          dataApiKey: 'DATA API KEY',
          dataApiKeyIsRequired: 'Data API Key is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          addParameters: ' Add Parameters',
          mapWith: 'map with',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          selectTheQuestion: 'Select the question whose user reply you want to send as value',
          placeholders: {
            enterApiUrl: 'Enter the API URL',
            enterAppId: 'Enter the APP ID',
            enterDataApiKey: 'Enter the DATA API KEY',
            attributes: 'Attributes(key)',
            pressForMappingQuestion: 'Press / For Mapping Question',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          emailAddress: 'Email Address',
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          leadId: 'Lead Id',
          selectLeadId: 'Select LeadId',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterEmailHere: 'Enter email Here',
            enterApiKeyHere: 'Enter apiKey Here',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          noCrmDomain: 'Nocrm Domain',
          leadTitle: 'Lead Title',
          titleIsRequired: 'Title is required',
          token: 'Token',
          tokenIsRequired: 'Token is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            exampleDomain: 'https://YOUR_SUBDOMAIN_HERE.nocrm.io',
            enterYourLeadTitle: 'Enter your Lead Title',
            enterYourToken: 'Enter your Token',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          emailAddress: 'Email Address',
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          secretToken: 'Secret Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          appId: 'App Id',
          appIdIsRequired: 'App Id is required',
          apiKey: 'API Key',
          apiKeyIsRequired: 'API key is required',
          connect: 'Connect',
          update: 'Update',
          disconnect: 'Disconnect',
          placeholders: {
            appId: 'App Id',
            apiKey: 'API Key',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          clientId: 'Client Id',
          connect: 'Connect',
          clientSecret: 'Client Secret',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          save: 'Save',
          update: 'Update',
          disconnect: 'Disconnect',
          placeholders: {
            enterClientIdHere: 'Enter Client ID Here',
            enterClientSecretHere: 'Enter Client Secret Here',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          pipedriverDomain: 'Pipedriver Domain',
          domainIsRequired: 'Domain is required',
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'Enter token here',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          apiKeyIsRequired: 'API key is required',
          appKey: 'APP Key',
          appKeyIsRequired: 'APP key is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiKey: 'Enter the API Key',
            enterAppKey: 'Enter the APP Key',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          authId: 'Auth Id',
          authToken: 'Auth Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiHere: 'Enter api Here',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'API Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiKey: 'Enter the API key',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          domain: 'Domain',
          clientId: 'Client Id',
          clientSecret: 'Client Secret',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterDomainHere: 'Enter Domain Here',
            enterClientIdHere: 'Enter Client ID Here',
            enterClientSecretHere: 'Enter Client Secret Here',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'Api Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          addEventsAndTemplates: 'Add Events And Templates:',
          add: ' Add',
          question: 'Question',
          reply: 'Reply',
          requestBody: 'Request Body',
          validateJson: 'Validate JSON',
          emailAddress: 'Email Address',
          // addParameters: ' Add Parameters'
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          selectTheQuestion: 'Select the question whose user reply you want to send as value',
          placeholders: {
            enterApiHere: 'Enter API Here',
            enterTemplateId: 'Enter Template Id',
            typeYourJsonCodeHere: 'Type your JSON Code here',
          },
          appTooltip: {
            typeYourJsonCode: 'Type your JSON code',
            clickToValidateJsonCode: 'Click to validate JSON code',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          userName: 'User Name',
          licenseKey: 'License Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterNameHere: 'Enter name Here',
            enterKeyHere: 'Enter key Here',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          company: 'Company',
          loginId: 'Login Id',
          password: 'Password',
          testLead: 'Test Lead',
          connect: 'Connect',
          selectTheQuestion: 'Select the question whose user reply you want to send as value',
          mapWith: 'map with',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          selectParameter: 'Select Parameter',
          placeholders: {
            enterCompanyHere: 'Enter Company Name Here',
            enterLoginIdHere: 'Enter Login ID Here',
            enterPasswordHere: 'Enter Password Here',
            values: 'values',
          }
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          applicationKey: 'Application Key',
          authToken: 'Auth Token',
          connect: 'Connect',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApplicationKeyHere: 'Enter application key Here',
            enterTokenHere: 'Enter Token Here',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          accessToken: 'Acess token',
          connect: 'Connect',
          testLead: 'Test Lead',
          question: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterTokenHere: 'Enter Token Here',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          webhoperDomain: 'Webhoper Domain',
          domainIsRequired: 'Domain is required',
          companyName: 'Company Name',
          companyIsRequired: 'Company is required',
          leadSource: 'Lead Source',
          leadSourceIsRequired: 'Lead Source is required',
          division: 'Division',
          divisionIsRequired: 'Division is required',
          enterPassword: 'Enter Password',
          passwordIsRequired: 'Password is required',
          disconnect: 'Disconnect',
          update: 'Update',
          connect: 'Connect',
          placeholders: {
            exampleDomain: 'http://yourcompanydomain',
            enterYourCompanyName: 'Enter your Company Name',
            enterLeadSource: 'Enter Lead Source',
            enterDivision: 'Enter Division',
            enterPassword: 'Enter password',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          name: 'Name:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'Inbound webhook url',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterWebhookUrl: 'Enter the webhook url',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          emailAddress: 'Email Address',
          apiUrl: 'API Url',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterEmailAddress: 'Enter email address',
            enterUrlHere: 'Enter url Here',
            enterTokenHere: 'Enter token Here',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiKey: 'Api Key',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiHere: 'Enter api Here',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          connect: 'Connect',
          update: 'Update',
          disconnect: 'Disconnect',
          companyName: 'Company Name',
          emailAddress: 'Email Address',
          phone: 'Phone',
          countryCode: 'Country Code',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          // update: 'Update',
          save: 'Save',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          connect: 'Connect',
          disconnect: 'Disconnect',
          testLead: 'Test Lead',
          emailAddress: 'Email Address',
          selectOrganizationName: 'Select Organisation Name',
          selectDepartmentName: 'Select department Name',
          addParameters: ' Add Parameters',
          questionColon: 'Question:',
          select: 'Select',
          mapsWith: 'maps with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          testLead: 'Test Lead',
          connect: 'Connect',
          disconnect: 'Disconnect',
          projectsList: 'Projects List',
          selectProjectsName: 'Select Projects Name',
          projectsTaskList: 'Projects TasksList',
          selectTaskListName: 'Select TaskList Name',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
        },
        wooCommerce: {
          configure: 'Configure',
          automatedMessage: 'Automated Message',
          configureTab: {
            wooCommercePluginApiKey: 'WooCommerce Plugin API Key',
            downloadWooCommercePlugin: 'Download WooCommerce Plugin'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Abandoned Recovery Message',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Configure automated recovery messages to win back customers who abandoned their carts',
            enableAbandonedCartRecoveryMessaegs: 'Enable abandoned cart recovery messages',
            selectTemplate: 'Select Template',
            sendMessageAfter: 'Send Message After',
            templateMessage: 'Template Message',
            saveChanges: 'Save Changes',
            selectFile: 'Select File',
            orderCompleted: 'Order Completed',
            templateHeader: 'Template Header',
            orderFulfillMent: 'Order Fulfillment',
            enableOrderConfirmationMessages: 'Enable order confirmation messages',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Send an order confirmation message as soon as a customer places an order.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Order shipment message is sent when you fulfil an order.',
            enableOrderFulfillmentMessages: 'Enable order fulfillment messages',
            returnExchangeRequest: 'Return/Exchange Request',
            theMessageIsSentAfterARefundIsRequested: 'The message is sent after a refund is requested',
            enableOrderRefundMessages: 'Enable order refund messages',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
          apiToken: 'API Token',
          nameIsRequired: 'Name is required',
          connect: 'Connect',
          testLead: 'Test Lead',
          questionColon: 'Question:',
          addParameters: ' Add Parameters',
          select: 'Select',
          mapWith: 'map with',
          selectParameter: 'Select Parameter',
          update: 'Update',
          save: 'Save',
          disconnect: 'Disconnect',
          placeholders: {
            enterApiToken: 'Enter the API Token',
          }
        }
      }
    },
    createIntegration: {
      createNewIntegration: 'Create New Integration',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'You do not have any integration setup as of now.',
      pleaseCreateOneToIntegrateYourFavourite: 'Please create one to integrate your favourite',
      appsWith: 'apps with ',
      integrationName: 'Integration Name',
      integrationNameIsRequired: 'Integration name is required',
      continue: 'Continue',
      addAnImageToRepresentYourIntegration: 'Add an image to represent your integration.',
      description: 'Description',
      submit: 'Submit',
      youHaveSuccessfullyCreatedIntegration: `You've successfully created Integration. Now, you can configure it.`,
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: 'Please enter a valid API URL',
      requestMethod: 'Request Method',
      get: 'Get',
      post: 'Post',
      put: 'Put',
      patch: 'Patch',
      queryParameter: 'Query Parameter',
      addParameter: ' Add Parameter',
      mapsWith: ' maps with',
      question: 'Question',
      addQuestion: ' Add Question',
      select: 'Select',
      selectParameter: 'Select Parameter',
      header: 'Header ',
      addHeader: ' Add Header',
      value: 'Value',
      connect: 'Connect',
      requestBody: 'Request Body',
      validate: 'Validate',
      // select: 'Select',Select Parameter
      key: 'Key',
      // value: 'Value',
      // connect: 'Connect',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' can also be integrated with this free CRM.',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: 'Please enter a valid API URL',
        requestMethod: 'Request Method:',
        get: 'Get',
        post: 'Post',
        put: 'Put',
        patch: 'Patch',
        queryParameter: 'Query Parameter',
        addParameter: ' Add Parameter',
        header: 'Header ',
        addHeader: ' Add Header',
        requestBody: 'Request Body',
        validateJson: 'Validate JSON',
        connect: 'Connect',
        update: 'Update',
        delete: 'Delete',
        selectTheQuestion: 'Select the question whose user reply you want to send as value',
        appTooltip: {
          typeYourJsonCode: 'Type your JSON code',
          clickToValidateJsonCode: 'Click to validate JSON code',
        }
      },
      list: {
        customAppsIntegrations: 'Custom Apps Integrations',
        integrateYourAppsWith: 'Integrate your own apps with ',
        help: 'Help',
        addIntegration: ' Add Integration',
        name: 'Name',
        status: 'Status',
        action: 'Action',
        insatlled: 'Installed',
        edit: 'Edit',
        connect: 'Connect',
        requestYourIntegration: 'Request your Integration',
        toRequestNewIntegration: 'To request new integration, Please enter below mentioned details and' +
          ' then we will get back to you.',
        // name: 'Name'
        emailId: 'Email ID',
        integrationName: 'Integration Name',
        contactNumber: 'Contact Number',
        howSoonYouWantToAddThisIntegration: 'How soon you want to add this Integration',
        sendRequest: 'Send Request',
        cancel: 'Cancel',
        delete: 'Delete',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Oops! Your own integrations are supported only in',
        plan: 'Plan',
        pleaseUpgradeTo: 'Please upgrade to',
        toCreateOwnIntegrations: 'to create Own Integrations',
        appTooltip: {
          integrations: 'Integrations',
          noIntegrationsFound: 'No Integrations found!',
          nameOfYourApp: 'Name of your App',
          clickToConnectApp: 'Click to connect the App',
          statusAsConnectedOrNotConnected: 'Status as connected / not-connected',
        }
      }
    }
  }
};
