export const team = {
  addMember: {
    team: 'الفريق',
    seeHowToUse: 'انظر كيفية الاستخدام',
    help: 'المساعدة',
    addAMember: 'أضف عضوًا',
    addYourTeamMembersText: 'أضف أعضاء فريقك كوكلاء دعم للدردشة المباشرة مع مستخدمي الروبوت.',
    name: 'الاسم',
    nameIsRequired: 'الاسم مطلوب',
    email: 'البريد الإلكتروني',
    enterAValidEmail: 'أدخل بريدًا إلكترونيًا صالحًا',
    password: 'كلمة المرور',
    passwordMustContain: 'يجب أن تحتوي كلمة المرور على',
    atleast6Characters: 'على الأقل 6 أحرف،',
    oneUpperCaseLetter: '1 حرف كبير',
    oneSpecialCharacter: '1 حرف خاص*',
    profileIcon: 'أيقونة الملف الشخصي',
    whatsappNumber: 'رقم الواتساب',
    leadVisibility: 'Lead Visibility*',
    addMember: 'أضف عضوًا',
    role: 'الدور',
    createdOn: 'تم الإنشاء في',
    action: 'الإجراء',
    updateMember: 'تحديث العضو',
    add: 'أضف',
    cancel: 'إلغاء',
    selectVisibilityOption: 'حدد خيار الرؤية',
    all: 'الكل',
    assigned: 'مخصص',
    nA: 'N/A',
    admin: 'Admin',
    agent: 'Agent',
    placeholders: {
      enterMemberName: 'أدخل اسم العضو',
      enterMemberEmailAddress: 'أدخل عنوان البريد الإلكتروني للعضو',
      createPasswordForMember: 'إنشاء كلمة مرور للعضو',
      searchCountry: 'بحث البلد',
      enterWhatsappNumber: 'أدخل رقم الواتساب',
      selectLeadsVisibility: 'حدد رؤية العملاء',
      searchTeamByNameOrEmail: 'البحث عن عضو الفريق حسب الاسم أو البريد الإلكتروني',
    },
    modal: {
      deleteTeamMember: 'حذف عضو الفريق',
      wantToDeleteThisTeamMember: 'هل أنت متأكد أنك تريد حذف عضو الفريق هذا؟',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'انقر لإضافة عضو جديد إلى الفريق',
      profileIconOfTeamMember: 'أيقونة/صورة الملف الشخصي لعضو الفريق',
      nameOfTeamMember: 'اسم عضو الفريق',
      emailOfTeamMember: 'بريد عضو الفريق الإلكتروني',
      whatsappNumberOfTeamMember: 'رقم واتساب عضو الفريق',
      roleOfTeamMemberAsAdminOrAgent: 'دور عضو الفريق كمشرف/وكيل',
      dateAndTimeOfCreatingTeamMemberRole: 'تاريخ ووقت إنشاء دور عضو الفريق',
      editOrDeleteTeamMember: 'تحرير/حذف عضو الفريق',
      editTeamMembersDetails: 'تحرير تفاصيل عضو الفريق.',
      removeAccessOfTeamMemberByDeletingAccount: 'إزالة وصول عضو الفريق عن طريق حذف الحساب',
    }
  },
  users: {
    manageMembers: 'إدارة الأعضاء',
    managerMembersWhichCanAccessPanel: 'إدارة فريق الشركاء الذين يمكنهم الوصول إلى لوحة التحكم الخاصة بك',
    createNewMember: 'إنشاء عضو جديد',
    createMember: 'إنشاء عضو',
    updateMember: 'تحديث العضو',
    deleteTitleMember: 'حذف العضو',
    deleteDescriptionMember: 'هل أنت متأكد من رغبتك في حذف هذا العضو؟',
    noMemberFound: 'لم يتم العثور على أعضاء!',
    titleMember: 'إنشاء عضو',
    manageUsers: 'إدارة المستخدمين',
    createNewUser: 'إنشاء مستخدم جديد',
    managerUserWhichCanAccessPanel: 'إدارة المستخدمين الذين يمكنهم الوصول إلى لوحتك',
    phoneNumber: 'رقم الهاتف',
    discard: 'تجاهل',
    createUser: 'إنشاء مستخدم',
    updateUser: 'تحديث المستخدم',
    noUserFOund: 'لم يتم العثور على مستخدم!',
    title: 'إنشاء مستخدم',
    profileIcon: 'رمز الملف الشخصي*',
    name: 'الاسم*',
    email: 'البريد الإلكتروني*',
    password: 'كلمة المرور*',
    selectRole: 'اختر الدور*',
    selectARole: 'حدد دورًا',
    selectLeadVisibility: 'اختر رؤية العميل*',
    confirmPassword: 'تأكيد كلمة المرور*',
    tableHeadingName: 'الاسم',
    tableHeadingEmail: 'البريد الإلكتروني',
    tableHeadingPhoneNumber: 'رقم الهاتف',
    tableHeadingRole: 'الدور',
    tableHeadingStatus: 'الحالة',
    tableHeadingAction: 'العمل',
    noUserFound: 'لم يتم العثور على مستخدم!',
    deleteTitle: 'حذف المستخدمين',
    deleteDescription: 'هل أنت متأكد أنك تريد حذف هذا المستخدم؟',
    placeholders: {
      searchHere: 'البحث هنا',
      enterName: 'أدخل الاسم',
      enterEmail: 'أدخل البريد الإلكتروني',
      searchCountry: 'ابحث عن البلد',
      enterPhoneNumber: 'أدخل رقم الهاتف',
      enterPassword: 'أدخل كلمة المرور',
      enterConfirmPassword: 'أدخل تأكيد كلمة المرور'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'حذف المستخدمين',
      TOOLTIP_DELETE_MEMBERS: 'حذف الأعضاء'
    }
  },
  roles: {
    manageRoles: 'إدارة الأدوار',
    manageYourRoles: 'إدارة أدوارك.',
    name: 'الاسم*',
    description: 'الوصف',
    discard: 'تجاهل',
    createRole: 'إنشاء دور',
    creteARole: 'إنشاء دور',
    updateRole: 'تحديث الدور',
    noUserFound: 'لم يتم العثور على أدوار المستخدمين!',
    assignPermissions: 'تخصيص الأذونات',
    tableHeadingName: 'الاسم',
    tableHeadingDescription: 'الوصف',
    tableHeadingStatus: 'الحالة',
    tableHeadingAction: 'العمل',
    module: 'الوحدة',
    read: 'قراءة',
    write: 'كتابة',
    remove: 'حذف',
    notes: 'ملاحظات',
    deleteTitle: 'حذف الدور',
    deleteDescription: 'تحذير: عند حذف هذا الدور، سيتم أيضًا حذف جميع المستخدمين المخصصين لهذا الدور. \n هل أنت متأكد أنك تريد حذف هذا الدور؟',
    deleteMemberDescription: 'تحذير: عند حذف هذا الدور، سيتم أيضًا حذف جميع الأعضاء المخصصين لهذا الدور. هل أنت متأكد أنك تريد حذف هذا الدور؟',
    placeholders: {
      enterRoleName: 'أدخل اسم الدور',
      searchHere: 'ابحث هنا',
      enterDescriptionForRole: 'أدخل وصفًا للدور'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'الأقسام التي يمكنك السماح أو منع المستخدمين من الوصول إليها',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'انقر لإضافة دور جديد',
    }
  },
  accessControl: {
    users: 'المستخدمين',
    partnerTeam: 'فريق الشريك',
    members: 'الأعضاء',
    roles: 'الأدوار'
  }
};
