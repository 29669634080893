export const agency = {
  activities: {
    activities: 'Деятельность',
    upcomingRenewals: 'Предстоящие обновления',
    subscriptionActivities: 'Подписная деятельность',
    upcomingDowngradeRequests: 'Предстоящие запросы на понижение',
    serialNumber: 'Старший №',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона.',
    currentPlan: 'Текущий план',
    price: 'Цена',
    daysLeft: 'Дни остались',
    noDataFound: {
      upcomingDowngrades: 'Предстоящие понижения',
      noUpcomingDowngradesFoundYet: 'Еще не найдено предстоящих понижений!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Предстоящие обновления клиентов',
      subscriptionActivities: 'Подписка',
      upcomingDowngradeRequestsOfCustomers: 'Предстоящие запросы на понижение клиентов',
      serialNumber: 'Серийный номер',
      customerEmail: 'Электронная почта клиента',
      customerPhoneNumber: 'Номер телефона клиента',
      currentPlanOfCustomer: 'Текущий план клиента',
      currentPlanPrice: 'Текущая цена плана',
      daysLeft: 'Дни остаются от текущего плана клиентов',
    },
  },
  configuration: {
    configurationsHeader: 'Конфигурации',
    emailHeader: 'Электронная почта',
    razorPayHeader: 'Razorpay',
    invoiceDetailsHeader: 'Детали счета',
    stripeHeader: 'Полоса',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Интегрируйте данные по провайдеру электронной почты, чтобы беспрепятственно отправлять электронные письма клиентов.',
      enterYourRazorPayAccountDetails: 'Введите информацию о своей учетной записи Razorpay для бесшовной обработки платежей клиентов.',
      enterYourStripeAccountDetails: 'Введите данные учетной записи Stripe для бесшовной обработки платежей клиентов.',
      enterYourCompanyDetails: 'Введите подробности вашей компании для точного счетов и профессиональных счетов.',
    },
    email: {
      testEmail: 'Тестировать электронное письмо',
      addEmailIntegrationsDetails: 'Добавить сведения о интеграции по электронной почте',
      integrationDescription: 'Чтобы интегрировать данные вашего поставщика электронной почты, предоставьте информацию о своем поставщике электронной почты. Все электронные письма об связи будут отправлены вашим клиентам с использованием этого адреса электронной почты, обеспечивая последовательную и надежную доставку важных уведомлений и обновлений.',
      awsRegion: 'AWS Регион',
      awsAccessKeyId: 'AWS Access ID ключа',
      awsSecretAccessKey: 'AWS Secret Access Key',
      reset: 'Перезагрузить',
      addDetails: 'Добавьте детали',
      updateDetails: 'Обновить детали',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Войдите в свой регион AWS. Регион определяет физическое местоположение ваших ресурсов и влияет на такие факторы, как задержка данных и соблюдение нормативных требований.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'Введите свой идентификатор ключа AWS Access. Этот ключ уникально идентифицирует вашу учетную запись AWS и требуется для безопасной аутентификации и доступа к службам AWS.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Введите свой ключ AWS Secret Access. Этот ключ используется для безопасной аутентификации и доступа к службам AWS.',
      },
      placeholders: {
        enterAwsRegion: 'Введите регион AWS',
        enterAwsAccessKeyId: 'Введите ID ключа AWS Access',
        enterAwsSecretAccessKey: 'Введите ключ AWS Secret Access',
      },
      modal: {
        deleteEmailIntegration: 'Удалить интеграцию по электронной почте',
        wantToDeleteEmailIntegration: 'Вы уверены, что хотите удалить эту интеграцию по электронной почте?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Добавить данные интеграции Facebook',
      integrateYourEmailProviderDetails: 'Интегрируйте детали вашего поставщика электронной почты. Все электронные письма будут отправлены с использованием этого электронного письма вашим клиентам',
      facebookAppId: 'Facebook идентификатор приложения',
      facebookSecretAccessKey: 'Ключ секретного доступа в Facebook',
      reset: 'Перезагрузить',
      cancel: 'Отмена',
      addDetails: 'Добавьте детали',
      updateDetails: 'Обновить детали',
      delete: 'Удалить',
      testEmail: 'Тестировать электронное письмо',
      modal: {
        deleteFacebookIntegration: 'Удалить интеграцию Facebook',
        wantToDeleteFacebookIntegration: 'Вы уверены, что хотите удалить эту интеграцию по электронной почте?',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Введите ключ Facebook Secret Access',
        enterFacebookAppId: 'Введите идентификатор приложения Facebook',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Настройте сообщение, как вы хотите, чтобы оно появилось в окне чата сразу после того, как пользователь бота отправляет запрос на живой чат',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Добавьте детали счета',
      invoicesDescription: 'Чтобы создать счета -фактуры для ваших клиентов, предоставьте информацию о вашей компании, включая название вашей компании, адрес, контактную информацию и любую другую соответствующую информацию, необходимую для точного выставления счетов. Эта информация будет использоваться для создания профессиональных счетов от имени вашей компании для каждой транзакции.',
      companyName: 'Название компании',
      country: 'Страна',
      selectCountry: 'Выберите страну',
      stateOrRegion: 'Государство/регион',
      streetAddress: 'Адрес улицы',
      gstin: 'GSTIN',
      postalCode: 'Почтовый индекс',
      addDetails: 'Добавьте детали',
      updateDetails: 'Обновить детали',
      reset: 'Перезагрузить',
      delete: 'Удалить',
      loadMore: 'Загрузи больше',
      time: 'Время',
      phoneNumber: 'Номер телефона',
      email: 'Электронная почта',
      status: 'Положение дел',
      message: 'Сообщение',
      response: 'Ответ',
      placeOfSupply: 'Место поставки',
      placeholders: {
        enterYourCompanyName: 'Введите название вашей компании',
        enterYourStateOrRegion: 'Введите свое состояние/регион',
        enterYourStreetAddress: 'Введите свой уличный адрес',
        enterYourGstin: 'Введите свой GSTIN',
        enterPostalCode: 'Введите почтовый код',
        enterPlaceOfSupply: 'Введите место поставки',
      },
      modal: {
        deleteEmailIntegration: 'Удалить интеграцию по электронной почте',
        wantToDeleteEmailIntegration: 'Вы уверены, что хотите удалить эту интеграцию по электронной почте?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: 'Введите название вашей компании. Эта информация будет использоваться в целях идентификации и брендинга в счетах.',
        TOOLTIP_COUNTRY: 'Выберите вашу страну. Эта информация будет использоваться в целях идентификации и брендинга в счетах.',
        TOOLTIP_STATE: 'Введите свое состояние. Эта информация будет использоваться в целях идентификации и брендинга в счетах.',
        TOOLTIP_STREET_ADDRESS: 'Введите свой уличный адрес. Эта информация будет использоваться в целях идентификации и брендинга в счетах.',
        TOOLTIP_GSTIN: 'Введите свой GSTIN. Эта информация будет использоваться в целях идентификации и брендинга в счетах.',
        TOOLTIP_POSTAL_CODE: 'Введите свой почтовый код. Эта информация будет использоваться в целях идентификации и брендинга в счетах.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Введите место поставки. Эта информация будет использоваться в целях идентификации и брендинга в счетах.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'Добавить детали интеграции Razorpay',
      integrationDescription: 'Чтобы интегрировать данные вашей учетной записи Razorpay, предоставьте информацию о вашей учетной записи Razorpay. Все платежи клиентов будут обработаны через вашу учетную запись Razorpay для бесшовных транзакций',
      razorPayAccessKeyId: 'Razorpay Access ID ключа',
      razorPaySecretAccessKey: 'Razorpay Secret Access Key',
      reset: 'Перезагрузить',
      addDetails: 'Добавьте детали',
      updateDetails: 'Обновить детали',
      loadMore: 'Загрузи больше',
      time: 'Время',
      phoneNumber: 'Номер телефона',
      email: 'Электронная почта',
      status: 'Положение дел',
      message: 'Сообщение',
      response: 'Ответ',
      placeholders: {
        enterRazorPayAccessKeyId: 'Введите идентификатор ключа Razorpay Access',
        enterRazorPaySecretAccessKey: 'Введите ключ доступа Razorpay Secret',
        enterRazorPayAccessId: 'Введите ID Access Razorpay',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Введите свой идентификатор ключа Access Razorpay. Этот ключ уникально определяет вашу интеграцию с Razorpay и требуется для безопасных транзакций оплаты.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Введите свой ключ доступа Razorpay Secret. Этот ключ имеет важное значение для безопасной связи между приложением и Razorpay, что позволяет бесшовной обработке транзакций и управлению платежами.',
      },
      modal: {
        deleteRazorPayIntegration: 'Удалить интеграцию по электронной почте',
        wantToDeleteThisIntegrtion: 'Вы уверены, что хотите удалить эту интеграцию по электронной почте?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Добавить детали интеграции полосы',
      integrationDescription: 'Чтобы интегрировать данные учетной записи Stripe, предоставьте информацию о своей учетной записи Stripe. Все платежи клиентов будут обрабатываться через вашу учетную запись полосы для бесшовных транзакций',
      stripeSecretAccessKey: 'Полоса секретного доступа',
      stripePublicKey: 'Полога общественного ключа',
      reset: 'Перезагрузить',
      addDetails: 'Добавьте детали',
      updateDetails: 'Обновить детали',
      cancel: 'Отмена',
      loadMore: 'Загрузи больше',
      time: 'Время',
      phoneNumber: 'Номер телефона',
      email: 'Электронная почта',
      status: 'Положение дел',
      message: 'Сообщение',
      response: 'Ответ',
      placeholders: {
        enterStripeSecretAccessKey: 'Введите клавишу Access stripe Secret',
        enterStripePublicKey: 'Введите Stripe Public Key',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Введите свой ключ Access Access Stripe. Этот ключ используется для безопасной аутентификации вашей интеграции с полосой и необходимо для обработки платежей клиентов.',
        TOOLTIP_PUBLIC_KEY: 'Введите свой открытый ключ полосы. Этот ключ используется для безопасной аутентификации вашей интеграции с полосой на стороне клиента и необходимо для обработки платежей клиентов.',
      },
      modal: {
        deleteStripeIntegration: 'Удалить интеграцию полосы',
        wantToDeleteStripeIntegration: 'Вы уверены, что хотите удалить эту интеграцию полосы?',
      }
    }
  },
  customer: {
    manageCustomers: 'Управлять клиентами',
    addNewCustomers: 'Добавьте новых клиентов',
    ensureYouHaveAddedAllYourCustomersHere: 'Убедитесь, что вы добавили здесь всех своих клиентов',
    customerName: 'Имя Клиента',
    nameIsRequired: 'Имя требуется',
    emailId: 'Email ID',
    enterAValidEmail: 'Введите действующее электронное письмо',
    createPassword: 'Придумайте пароль',
    enterAStrongPassword: 'Введите сильный пароль',
    confirmPassword: 'Подтвердите пароль',
    cancel: 'Отмена',
    customer: 'Клиент',
    searchByNameOrEmail: 'Поиск по имени или по электронной почте',
    filters: 'Фильтры',
    allCustomers: 'Все клиенты',
    paidCustomers: 'Оплачиваемые клиенты',
    freeCustomers: 'Бесплатные клиенты',
    messageAddOnCustomers: 'Сообщение добавить клиентов',
    botWhiteLabelCustomers: 'BOT Whitelabel клиенты',
    sortBy: 'Сортировать по',
    sortByFrequency: 'Сортировать по частоте',
    all: 'Все',
    joinedDate: 'Присоединилась к дате',
    plan: 'План',
    lastLogin: 'Последний Войти',
    email: 'Электронная почта',
    nextDue: 'Далее',
    frequency: 'Частота',
    clearAll: 'Очистить все',
    apply: 'Применять',
    messageConsumed: 'Сообщение потребляется',
    addCustomer: 'Добавить клиента',
    serialNumber: 'Старший №',
    name: 'Имя',
    contact: 'Контакт',
    limit: 'Предел',
    location: 'Расположение',
    actions: 'Действия',
    joinedOn: 'Присоединился к',
    typeOfCustomer: 'Тип клиента',
    exceptWhatsapp: ' (Кроме WhatsApp)',
    exceptWA: '(Кроме WA)',
    wa: '(WA)',
    loadMore: 'Загрузи больше',
    conversationsWhatsapp: ' Разговоры (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'Введите электронную почту клиента',
      enterCustomerPassword: 'Введите пароль клиента',
      enterCustomerConfirmPassword: 'Введите клиент подтвердить пароль',
      enterCustomerName: 'Введите имя клиента',
    },
    placeholders: {
      enterCustomerEmail: 'Введите электронную почту клиента',
      searchByNameOrEmail: 'Поиск по имени или по электронной почте',
      enterPassword: 'Введите пароль',
      enterCustomerName: 'Введите имя клиента',
    }
  },
  dashboard: {
    dashboard: 'Панель приборов',
    overview: 'Обзор',
    totalCustomers: 'Всего клиентов',
    paidCustomers: 'Оплачиваемые клиенты',
    freeCustomers: 'Бесплатные клиенты',
    thisWeeksSignUp: 'Эта неделя регистрируется',
    todaysSignUp: 'Сегодняшние регистрации',
    totalEarnings: 'Общий заработок',
    nA: 'НА',
    customerName: 'Имя Клиента',
    nameIsRequired: 'Имя требуется',
    emailId: 'Email ID',
    enterAValidEmail: 'Введите действующее электронное письмо',
    editCustomer: 'Редактировать клиента',
    recentSignUps: 'Недавние регистрации',
    serialNumber: 'Старший №',
    name: 'Имя',
    email: 'Электронная почта',
    contact: 'Контакт',
    plan: 'План',
    limit: 'Предел',
    location: 'Расположение',
    lastLogin: 'Последний Войти',
    joinedOn: 'Присоединился к',
    actions: 'Действия',
    exceptWhatsapp: ' (Кроме WhatsApp)',
    messageConsumed: 'Сообщение потребляется',
    conversationsWhatsapp: ' Разговоры (WhatsApp)',
    placeholders: {
      enterCustomerName: 'Введите имя клиента',
      enterCustomerEmail: 'Введите электронную почту клиента',
    },
    modal: {
      deleteCustomer: 'Удалить клиента',
      areYouSure: 'Вы уверены, что хотите удалить этого клиента?',
    }
  },
  downgradeRequests: {
    activites: 'Деятельность',
    upcomingRenewals: 'Предстоящие обновления',
    subscriptionActivities: 'Подписная деятельность',
    upcomingDonwgradeRequests: 'Предстоящие запросы на понижение',
    serialNumber: 'Старший №',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона.',
    currentPlan: 'Текущий план',
    requestedOn: 'Запрошен на',
    downgradeOn: 'Понижать',
    nA: 'НА',
    commaMonthly: ', Ежемесячно',
    commaYearly: ' , Ежегодно',
    noDataFound: {
      downgradeRequests: 'Запросы понижения',
      noDowngradeRequestFound: 'Пока не найдено запросов на понижение!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Предстоящие обновления клиентов',
      subscriptionActivities: 'Подписка',
      upcomingDowngradeOfCustomers: 'Предстоящие запросы на понижение клиентов',
      serialNumber: 'Серийный номер',
      customerEmail: 'Электронная почта клиента',
      customerPhoneNumber: 'Номер телефона клиента',
      currentPlanOfCustomer: 'Текущий план клиента',
      currentPlanPrice: 'Текущая цена плана',
    }
  },
  emailIntegration: {
    emailIntegration: 'Интеграция электронной почты',
    addEmailIntegrationDetails: 'Добавить сведения о интеграции по электронной почте',
    integrateYourEmailProviderDetails: 'Интегрируйте детали вашего поставщика электронной почты. Все электронные письма будут отправлены с использованием этого электронного письма вашим клиентам',
    awsRegion: 'AWS Регион',
    awsRegionIsRequired: 'Регион AWS требуется',
    awsAccessKeyId: 'AWS Access ID ключа',
    awsAccessKeyIdIsRequired: 'Требуется идентификатор ключа AWS Access',
    awsSecretAccessKey: 'AWS Secret Access Key',
    awsSecretAccessKeyIsRequired: 'AWS Secret Access требуется',
    cancel: 'Отмена',
    test: 'Тест',
    action: 'Действие',
    testEmail: 'Тестировать электронное письмо',
    addDetails: 'Добавьте детали',
    updateDetails: 'Обновить детали',
    saveDetails: 'Сохраните детали',
    placeholders: {
      enterAwsRegion: 'Введите регион AWS',
      enterAwsAccessKeyId: 'Введите ID ключа AWS Access',
      enterAwsSecretAccessKey: 'Введите ключ AWS Secret Access',
    },
    modal: {
      deleteEmailIntegration: 'Удалить интеграцию по электронной почте',
      areYouSure: 'Вы уверены, что хотите удалить эту интеграцию по электронной почте?',
    },
    noDataFound: {
      emailIntegration: 'Интеграция электронной почты',
      noEmailIntegrationFoundYet: 'Интеграция по электронной почте пока не найдена!',
    }
  },
  subscription: {
    activities: 'Деятельность',
    upcomingRenewals: 'Предстоящие обновления',
    subscriptionActivities: 'Подписная деятельность',
    upcomingDonwgradeRequests: 'Предстоящие запросы на понижение',
    filters: 'Фильтры',
    manageActivities: 'Управлять деятельностью',
    sortBy: 'Сортировать по',
    all: 'Все',
    upgrades: 'Обновления',
    donwgrades: 'Понижение',
    renewals: 'Обновлять',
    serialNumber: 'Старший №',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона.',
    previousPlan: 'Предыдущий план',
    newPlan: 'Новый план',
    description: 'Описание',
    date: 'Дата',
    nA: 'НА',
    upgraded: 'модернизировано',
    commaYearly: ' , Ежегодно',
    commaMonthly: ' , Ежемесячно',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'Пока не было найдено подписки!',
      subscriptionsActivities: 'Подписки',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Предстоящие обновления клиентов',
      subscriptionActivities: 'Подписка',
      upcomingDowngradeRequests: 'Предстоящие запросы на понижение клиентов',
      serialNumber: 'Серийный номер',
      customerEmail: 'Электронная почта клиента',
      customerPhoneNumber: 'Номер телефона клиента',
      previousPlan: 'Предыдущий план клиента',
      newPlan: 'Новый план клиента',
      description: 'Описание плана',
      typeOfPayment: 'Тип платежа',
    }
  },
  feedbacks: {
    feedbacks: 'Отзывы',
    serialNumber: 'Старший №',
    customer: 'Клиент',
    type: 'Тип',
    rating: 'Рейтинг',
    reason: 'Причина',
    description: 'Описание',
    createdAt: 'Создан в',
    nA: 'НА',
    loadMore: 'Загрузи больше',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Отзывы об удалении учетной записи и бота',
      noFeedbacksFoundYet: 'Отзывы еще не найдено!',
    },
    appTooltip: {
      serialNumber: 'Серийный номер',
      customerEmail: 'Электронная почта клиента',
      feedbackForBotOrAccountDeletion: 'Беспокойство для (удаление бота/счета)',
      customerRating: 'Рейтинг клиентов',
      customerReason: 'Клиент разум',
      feedbackCreatedAt: 'Обратная связь, созданная в',
      customerFeedback: 'Обратная связь с клиентами',
    }
  }
};
