export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Recherche',
    }
  },
  bpTable: {
    loadMore: 'Charger plus',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Pays de recherche',
    }
  },
  createNew: {
    continue: 'Continuer',
  },
  datePicker: {
    chooseADate: 'Choisissez une date',
  },
  dateRangePicker: {
    choosePeriod: 'Choisir la période',
    today: 'Aujourd`hui',
    lastWeek: 'La semaine dernière',
    lastMonth: 'Le mois dernier',
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Veuillez fournir le numéro que vous souhaitez utiliser pour tester',
    resendOtp: 'Repenser otp',
    enterOtp: 'Entrez OTP',
    verifyOtp: 'Vérifiez OTP',
    sendOtp: 'Envoyer OTP',
    cancel: 'Annuler',
    placeholders: {
      phone: 'Téléphone*',
      enterOtp: 'Entrez OTP *',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Veuillez sélectionner votre raison',
    cancel: 'Annuler',
    submit: 'Soumettre!',
    giveFeedback: 'Donner des commentaires',
    ratings: 'Notes',
    reasonBehindYourDecision: 'Raison derrière votre décision',
    shareYourExperience: 'Partagez votre expérience',
    reason1: 'Raison1',
    reason2: 'Raison2',
  },
  feedbackModal: {
    paymentIsInProgress: 'Le paiement est en cours',
    pleaseDoNotCloseThisWindow: 'Veuillez ne pas fermer cette fenêtre',
    placeholders: {
      name: 'Nom',
      email: 'E-mail*',
      phone: 'Téléphone*',
      pleaseShareYourUseCase: 'Veuillez partager votre usecase',
    }
  },
  layout: {
    sidebar: {
      notifications: 'Notifications',
      myAccount: 'Mon compte',
      logout: 'Se déconnecter',
      goOnline: 'Aller en ligne',
      manage: 'Gérer',
      deactivate: 'Désactiver',
      activate: 'Activer',
      delete: 'Supprimer',
      home: 'Maison',
      bots: 'Bots',
      inbox: 'Boîte de réception',
      analytics: 'Analytique',
      team: 'Équipe',
      subscriptions: 'Abonnements',
      more: 'Plus',
      developer: 'Développeur',
      users: 'Utilisateurs',
      partnerTeam: 'Équipe partenaire',
      createNewBot: 'Créer un nouveau bot',
      dashboard: 'Tableau de bord',
      customers: 'Clients',
      plans: 'Des plans',
      activities: 'Activités',
      feedbacks: 'Rétroactions',
      configurations: 'Configurations',
      copy: 'Copie',
      yourFriendWillMissYou: 'Votre ami vous manquera',
      rateYourBotCreationExperience: 'Évaluez votre expérience de création de bot',
      whatMadeYouDeleteYourBot: 'Qu`est-ce qui vous a fait supprimer votre bot?',
      iCreatedByMistake: 'J`ai créé par erreur',
      iWantToCreateAnotherBot: 'Je veux créer un autre bot',
      couldNotFindFeaturesThatINeeded: ',',
      uiWasHardToNavigate: 'L`interface utilisateur était difficile à naviguer',
      iDonotWantToTell: ',',
      other: 'Autre',
      weWillDoOurBestToMakeAutomationFun: 'Nous ferons de notre mieux pour rendre l`automatisation amusante et simple pour tout le monde.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Basculer pour autoriser ou bloquer les demandes de chat en direct',
        TOOLTIP_NOTIFICATION: 'Toutes les notifications',
        TOOLTIP_MY_ACCOUNT: 'Votre sécurité de profil et de mot de passe',
        TOOLTIP_LOGOUT: 'Déconnexion du tableau de bord',
      },
      modal: {
        logout: 'Se déconnecter',
        botDeleteFeedbackForm: 'Bot Supprimer le formulaire de rétroaction',
        areYouSureToLogOut: 'Êtes-vous sûr de vouloir vous déconnecter?',
        yesIWillBeBack: 'Oui! Je serai de retour',
        deleteBot: 'Supprimer',
        areYouSureToDeleteBot: 'Êtes-vous sûr de vouloir supprimer ce bot?',
        cancel: 'Annuler',
        saveAndDelete: 'Enregistrer et supprimer',
      }
    }
  },
  modal: {
    cancel: 'Annuler',
    yesDelete: 'Oui, supprimer !',
    continue: 'Continuer'
  },
  unsavedChanges: {
    title: 'Modifications non enregistrées',
    description: 'Il pourrait y avoir des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?'
  },
  notify: {
    youHave: 'Tu as',
    new: ' nouveau',
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Nous vous suggérons de choisir le logo de votre entreprise. Ajoutez une image carrée PNG ou JPG sous 1 Mo.',
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Oops! Baby Plan n`offre qu`un seul bot gratuit. Veuillez passer à King Plan pour créer plus de robots.',
    noIAmGood: 'Non, je suis bon',
    iWantToUpgrade: 'Je veux mettre à niveau',
    oops: 'Oups! ',
    planOfferOnlyOneFreeBot: ' PROPOSE UN SEUL bot gratuit. Veuillez passer à un ',
    planToCreateMoreBots: ' pour créer plus de bots.'
  },
  webPreview: {
    preview: ' Aperçu',
    botsMessages: 'Messages bots',
    bot: 'Bot',
    eleven36AM: '11h36',
    usersReply: 'Réponse de l`utilisateur',
    landingPageBot: 'Page de destination bot',
    chatbot: 'Chatbot',
    chat: 'Discuter',
    placeholders: {
      typeYourAnswer: 'Tapez votre réponse',
    }
  },
  paymentLink: {
    discardPayment: 'Annuler le paiement',
    completePayment: 'Finaliser le paiement',
    importantUpdate: 'Mise à jour importante',
    accountUpdate: 'Mise à jour de compte',
    youHave: 'Vous avez',
    exceededTheMessagesLimit: 'dépassé la limite de messages',
    ofYour: 'de votre',
    planAnd: 'planifier et',
    botsAreNoLongerActive: 'les robots ne sont plus actifs',
    onYourWebsite: 'sur votre site.',
    youHaveEitherIncompletePaymentOn: 'vous avez soit un paiement incomplet sur',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'ou vous n\'avez pas réussi à mettre à niveau votre plan.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'ou vous n\'avez pas réussi à étiqueter votre bot.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'ou vous n\'avez pas réussi à acheter les messages de',
    please: 'Pour',
    complete: 'Complète',
    upgrade: 'Méditer',
    services: 'Services',
    yourPlanToContinueServices: 'votre plan pour continuer les services',
    yourPaymentToContinue: 'votre paiement pour continuer',
    forAnyQueryPleaseWriteUs: 'Pour toute question, écrivez-nous sur',
    close: 'Fermer',
    writeOn: '',
    upgradePlan: 'Méditer le plan',
    discardModal: {
      title: 'Annuler le paiement',
      description: 'Vous êtes sûr de vouloir annuler le paiement?',
      confirmText: 'Annuler',
    }
  },
  bpExport: {
    exportStatus: 'Statut d`exportation',
    tasksExport: 'Tâches',
    download: 'Télécharger',
    inProgress: 'en cours',
  }
};
