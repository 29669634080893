export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Поиск',
    }
  },
  bpTable: {
    loadMore: 'Загрузи больше',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Страна поиска',
    }
  },
  createNew: {
    continue: 'Продолжать',
  },
  datePicker: {
    chooseADate: 'Выберите дату',
  },
  dateRangePicker: {
    choosePeriod: 'Выберите период',
    today: 'Сегодня',
    lastWeek: 'На прошлой неделе',
    lastMonth: 'Прошлый месяц',
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Пожалуйста, предоставьте номер, который вы хотите использовать для тестирования',
    resendOtp: 'Повторный ОТП',
    enterOtp: 'Введите OTP',
    verifyOtp: 'Проверьте OTP',
    sendOtp: 'Отправить OTP',
    cancel: 'Отмена',
    placeholders: {
      phone: 'Телефон*',
      enterOtp: 'Введите OTP*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Пожалуйста, выберите свою причину',
    cancel: 'Отмена',
    submit: 'Представлять на рассмотрение!',
    giveFeedback: 'Дать обратную связь',
    ratings: 'Рейтинги',
    reasonBehindYourDecision: 'Причина вашего решения',
    shareYourExperience: 'Поделитесь своим опытом',
    reason1: 'Причина1',
    reason2: 'Причина2',
  },
  feedbackModal: {
    paymentIsInProgress: 'Платеж осуществляется',
    pleaseDoNotCloseThisWindow: 'Пожалуйста, не закрывайте это окно',
    placeholders: {
      name: 'Имя',
      email: 'Электронная почта*',
      phone: 'Телефон*',
      pleaseShareYourUseCase: 'Пожалуйста, поделитесь вашим использованием',
    }
  },
  layout: {
    sidebar: {
      notifications: 'Уведомления',
      myAccount: 'Мой счет',
      logout: 'Выйти',
      goOnline: 'Выйти в онлайн',
      manage: 'Управлять',
      deactivate: 'Деактивировать',
      activate: 'Активировать',
      delete: 'Удалить',
      home: 'Дом',
      bots: 'Боты',
      inbox: 'Входящие',
      analytics: 'Аналитика',
      team: 'Команда',
      subscriptions: 'Подписки',
      more: 'Более',
      users: 'Пользователи',
      partnerTeam: 'Партнерская команда',
      createNewBot: 'Создать нового бота',
      dashboard: 'Панель приборов',
      developer: 'Разработчик',
      customers: 'Клиенты',
      plans: 'Планы',
      activities: 'Деятельность',
      feedbacks: 'Отзывы',
      configurations: 'Конфигурации',
      copy: 'Копия',
      yourFriendWillMissYou: 'Ваш друг будет скучать по тебе',
      rateYourBotCreationExperience: 'Оцените свой опыт создания бота',
      whatMadeYouDeleteYourBot: 'Что заставило вас удалить своего бота?',
      iCreatedByMistake: 'Я создал по ошибке',
      iWantToCreateAnotherBot: 'Я хочу создать другого бота',
      couldNotFindFeaturesThatINeeded: ',',
      uiWasHardToNavigate: 'Пользовательский интерфейс был трудно ориентироваться',
      iDonotWantToTell: ',',
      other: 'Другой',
      weWillDoOurBestToMakeAutomationFun: 'Мы сделаем все возможное, чтобы сделать автоматизацию забавным и простым для всех.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Переключить, чтобы разрешить или блокировать живые запросы чата',
        TOOLTIP_NOTIFICATION: 'Все уведомления',
        TOOLTIP_MY_ACCOUNT: 'Ваш профиль и безопасность пароля',
        TOOLTIP_LOGOUT: 'Выход с приборной панели',
      },
      modal: {
        logout: 'Выйти',
        botDeleteFeedbackForm: 'Форма обратной связи бота',
        areYouSureToLogOut: 'Вы действительно хотите выйти?',
        yesIWillBeBack: 'Да! я вернусь',
        deleteBot: 'Удалить бот',
        areYouSureToDeleteBot: 'Вы уверены, что хотите удалить этого бота?',
        cancel: 'Отмена',
        saveAndDelete: 'Сохранить и удалить',
      }
    }
  },
  modal: {
    cancel: 'Отмена',
    yesDelete: 'Да, удалить!',
    continue: 'Продолжить'
  },
  unsavedChanges: {
    title: 'Не сохраненные изменения',
    description: 'Могут быть несохраненные изменения. Вы уверены, что хотите уйти?'
  },
  notify: {
    youHave: 'У вас есть',
    new: ' новый',
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Мы предлагаем выбрать логотип вашей компании. Добавьте изображение PNG или JPG в квадрате под 1 МБ.',
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Уп! Baby Plan предлагает только один бесплатный бот.' +
      ' Пожалуйста, перейдите к King Plan, чтобы создать больше ботов.',
    noIAmGood: 'Нет, я в порядке',
    iWantToUpgrade: 'Я хочу обновить',
    oops: 'Упс! ',
    planOfferOnlyOneFreeBot: ' ПРЕДЛАГАЕТ ТОЛЬКО ОДНОГО БЕСПЛАТНОГО бота. Пожалуйста, перейдите на',
    planToCreateMoreBots: ' чтобы создать больше ботов.'
  },
  webPreview: {
    preview: ' Предварительный просмотр',
    botsMessages: 'Ботс сообщения',
    bot: 'Бот',
    eleven36AM: '11:36 утра',
    usersReply: 'Ответ пользователя',
    landingPageBot: 'Целевая страница бот',
    chatbot: 'Чатбот',
    chat: 'Чат',
    placeholders: {
      typeYourAnswer: 'Введите свой ответ',
    }
  },
  paymentLink: {
    discardPayment: 'Отменить платеж',
    completePayment: 'Завершить платеж',
    importantUpdate: 'Важное обновление',
    accountUpdate: 'Аккаунт обновлен',
    youHave: 'У вас есть',
    exceededTheMessagesLimit: 'Вы превысили лимит сообщений',
    ofYour: 'от ваш',
    planAnd: 'плана и',
    botsAreNoLongerActive: 'боты больше не активны',
    onYourWebsite: 'на вашем сайте',
    youHaveEitherIncompletePaymentOn: 'У вас либо неполный платеж на',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'или вы не успешно обновили свой план',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'или вы не успешно белой меткой свой бот',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'или вы не успешно покупали “messages Add-on”.',
    please: 'Пожалуйста',
    complete: 'Завершить',
    upgrade: 'Обновить',
    services: 'сервисы',
    yourPlanToContinueServices: 'ваш план для продолжения услуг',
    yourPaymentToContinue: 'ваш платеж для продолжения услуг',
    forAnyQueryPleaseWriteUs: 'Для любых вопросов пишите нам',
    close: 'Закрыть',
    writeOn: '',
    upgradePlan: 'Обновить план',
    discardModal: {
      title: 'Отменить платеж',
      description: 'Вы уверены, что хотите отменить платеж?',
      confirmText: 'Да, отменить платеж'
    }
  },
  bpExport: {
    exportStatus: 'Статус экспорта',
    tasksExport: 'Задачи- экспорт',
    download: 'Скачать',
    inProgress: 'в ходе выполнения',
  }
};
