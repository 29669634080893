export const agency = {
  activities: {
    activities: 'Actividades',
    upcomingRenewals: 'Próximas renovaciones',
    subscriptionActivities: 'Actividades de suscripción',
    upcomingDowngradeRequests: 'Próximas solicitudes de degradación',
    serialNumber: 'No Señor.',
    email: 'Correo electrónico',
    phoneNumber: 'Telefono no.',
    currentPlan: 'Plan actual',
    price: 'Precio',
    daysLeft: 'Días restantes',
    noDataFound: {
      upcomingDowngrades: 'Próximos rebajas',
      noUpcomingDowngradesFoundYet: '¡No hay rebajas próximas que se encuentren todavía!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Próximas renovaciones de los clientes',
      subscriptionActivities: 'Actividades de suscripción',
      upcomingDowngradeRequestsOfCustomers: 'Próximas solicitudes de degradación de los clientes',
      serialNumber: 'Número de serie',
      customerEmail: 'Correo electrónico del cliente',
      customerPhoneNumber: 'Número de teléfono del cliente',
      currentPlanOfCustomer: 'Plan actual del cliente',
      currentPlanPrice: 'Precio del plan actual',
      daysLeft: 'Los días restantes del plan actual de los clientes',
    },
  },
  configuration: {
    configurationsHeader: 'Configuraciones',
    emailHeader: 'Correo electrónico',
    razorPayHeader: 'Razonpay',
    invoiceDetailsHeader: 'Detalles de la factura',
    stripeHeader: 'Raya',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Integre los detalles de su proveedor de correo electrónico para enviar correos electrónicos de los clientes sin problemas.',
      enterYourRazorPayAccountDetails: 'Ingrese los detalles de su cuenta de Razorpay para un procesamiento de pago sin interrupciones del cliente.',
      enterYourStripeAccountDetails: 'Ingrese los detalles de su cuenta de Stripe para el procesamiento de pago sin interrupciones del cliente.',
      enterYourCompanyDetails: 'Ingrese los detalles de su empresa para la facturación precisa y la generación de facturas profesionales.',
    },
    email: {
      testEmail: 'Email de prueba',
      addEmailIntegrationsDetails: 'Agregar detalles de integración de correo electrónico',
      integrationDescription: 'Para integrar los detalles de su proveedor de correo electrónico, proporcione información de su proveedor de correo electrónico. Todos los correos electrónicos de comunicación se enviarán a sus clientes utilizando esta dirección de correo electrónico, asegurando una entrega consistente y confiable de notificaciones y actualizaciones importantes.',
      awsRegion: 'Región de AWS',
      awsAccessKeyId: 'ID de clave de acceso de AWS',
      awsSecretAccessKey: 'Clave de acceso secreto de AWS',
      reset: 'Reiniciar',
      addDetails: 'Agregar detalles',
      updateDetails: 'Detalles de actualización',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Ingrese su región de AWS. La región determina la ubicación física de sus recursos y afecta factores como la latencia de datos y el cumplimiento regulatorio.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'Ingrese su ID de clave de acceso AWS. Esta clave identifica de manera única su cuenta AWS y es necesaria para la autenticación segura y el acceso a los servicios de AWS.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Ingrese su clave de acceso secreto de AWS. Esta clave se utiliza para la autenticación segura y el acceso a los servicios de AWS.',
      },
      placeholders: {
        enterAwsRegion: 'Ingrese la región de AWS',
        enterAwsAccessKeyId: 'Ingrese la ID de clave de acceso AWS',
        enterAwsSecretAccessKey: 'Ingrese la clave de acceso secreto de AWS',
      },
      modal: {
        deleteEmailIntegration: 'Eliminar la integración del correo electrónico',
        wantToDeleteEmailIntegration: '¿Estás seguro de que quieres eliminar esta integración de correo electrónico?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Agregar detalles de integración de Facebook',
      integrateYourEmailProviderDetails: 'Integre los detalles de su proveedor de correo electrónico. Se enviarán todos los correos electrónicos utilizando este correo electrónico a sus clientes.',
      facebookAppId: 'ID de aplicación de Facebook',
      facebookSecretAccessKey: 'Clave de acceso secreto de Facebook',
      reset: 'Reiniciar',
      cancel: 'Cancelar',
      addDetails: 'Agregar detalles',
      updateDetails: 'Detalles de actualización',
      delete: 'Borrar',
      testEmail: 'Email de prueba',
      modal: {
        deleteFacebookIntegration: 'Eliminar la integración de Facebook',
        wantToDeleteFacebookIntegration: '¿Estás seguro de que quieres eliminar esta integración de correo electrónico?',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Ingrese la clave de acceso secreto de Facebook',
        enterFacebookAppId: 'Ingrese la identificación de la aplicación de Facebook',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalizar el mensaje como lo desea que aparezca en la ventana de chat inmediatamente después de que un usuario de BOT envía una solicitud de chat en vivo',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Agregar detalles de facturas',
      invoicesDescription: 'Para generar facturas para sus clientes, proporcione los detalles de su empresa, incluido el nombre de su empresa, la dirección, la información de contacto y cualquier otra información relevante requerida para la facturación precisa. Esta información se utilizará para crear facturas profesionales en nombre de su empresa para cada transacción.',
      companyName: 'nombre de empresa',
      country: 'País',
      selectCountry: 'Seleccionar país',
      stateOrRegion: 'Estado/región',
      streetAddress: 'Dirección',
      gstin: 'Gstin',
      postalCode: 'Código Postal',
      addDetails: 'Agregar detalles',
      updateDetails: 'Detalles de actualización',
      reset: 'Reiniciar',
      delete: 'Borrar',
      loadMore: 'Carga más',
      time: 'Tiempo',
      phoneNumber: 'Número de teléfono',
      email: 'Correo electrónico',
      status: 'Estado',
      message: 'Mensaje',
      response: 'Respuesta',
      placeOfSupply: 'Lugar de la compra',
      placeholders: {
        enterYourCompanyName: 'Ingrese el nombre de su empresa',
        enterYourStateOrRegion: 'Ingrese su estado/región',
        enterYourStreetAddress: 'Ingrese su dirección de calle',
        enterYourGstin: 'Ingrese su gstin',
        enterPostalCode: 'Ingrese el código postal',
        enterPlaceOfSupply: 'Ingrese el lugar de la compra'
      },
      modal: {
        deleteEmailIntegration: 'Eliminar la integración del correo electrónico',
        wantToDeleteEmailIntegration: '¿Estás seguro de que quieres eliminar esta integración de correo electrónico?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: 'Ingrese el nombre de su empresa. Esta información se utilizará para fines de identificación y marca en facturas.',
        TOOLTIP_COUNTRY: 'Selecciona tu pais. Esta información se utilizará para fines de identificación y marca en facturas.',
        TOOLTIP_STATE: 'Ingrese su estado. Esta información se utilizará para fines de identificación y marca en facturas.',
        TOOLTIP_STREET_ADDRESS: 'Ingrese su dirección de la calle. Esta información se utilizará para fines de identificación y marca en facturas.',
        TOOLTIP_GSTIN: 'Ingrese su gstin. Esta información se utilizará para fines de identificación y marca en facturas.',
        TOOLTIP_POSTAL_CODE: 'Ingrese su código postal. Esta información se utilizará para fines de identificación y marca en facturas.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Ingrese el lugar de la compra. Esta información se utilizará para fines de identificación y marca en facturas.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'Agregar detalles de integración de Razorpay',
      integrationDescription: 'Para integrar los detalles de su cuenta de RazorPay, proporcione la información de su cuenta de RazorPay. Todos los pagos del cliente se procesarán a través de su cuenta de Razorpay para transacciones sin problemas',
      razorPayAccessKeyId: 'ID de clave de acceso Razorpay',
      razorPaySecretAccessKey: 'Clave de acceso secreto de Razorpay',
      reset: 'Reiniciar',
      addDetails: 'Agregar detalles',
      updateDetails: 'Detalles de actualización',
      loadMore: 'Carga más',
      time: 'Tiempo',
      phoneNumber: 'Número de teléfono',
      email: 'Correo electrónico',
      status: 'Estado',
      message: 'Mensaje',
      response: 'Respuesta',
      placeholders: {
        enterRazorPayAccessKeyId: 'Ingrese la ID de clave de acceso Razorpay',
        enterRazorPaySecretAccessKey: 'Ingrese la clave de acceso secreto de Razorpay',
        enterRazorPayAccessId: 'Ingrese la identificación de acceso de Razorpay',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Ingrese su ID de clave de acceso RazorPay. Esta clave identifica de manera única su integración con Razorpay y es necesaria para transacciones de pago seguras.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Ingrese su clave de acceso secreto de Razorpay. Esta clave es esencial para la comunicación segura entre la aplicación y Razorpay, lo que permite el procesamiento de transacciones sin problemas y la gestión de pagos.',
      },
      modal: {
        deleteRazorPayIntegration: 'Eliminar la integración del correo electrónico',
        wantToDeleteThisIntegrtion: '¿Estás seguro de que quieres eliminar esta integración de correo electrónico?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Agregar detalles de integración de rayas',
      integrationDescription: 'Para integrar los detalles de su cuenta de Stripe, proporcione información de su cuenta de Stripe. Todos los pagos del cliente se procesarán a través de su cuenta de rayas para transacciones sin problemas',
      stripeSecretAccessKey: 'Clave de acceso secreto de Stripe',
      stripePublicKey: 'Clave pública de rayas',
      reset: 'Reiniciar',
      addDetails: 'Agregar detalles',
      updateDetails: 'Detalles de actualización',
      cancel: 'Cancelar',
      loadMore: 'Carga más',
      time: 'Tiempo',
      phoneNumber: 'Número de teléfono',
      email: 'Correo electrónico',
      status: 'Estado',
      message: 'Mensaje',
      response: 'Respuesta',
      placeholders: {
        enterStripeSecretAccessKey: 'Ingrese la clave de acceso secreto de Stripe',
        enterStripePublicKey: 'Ingrese la clave pública Stripe',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Ingrese su clave de acceso secreto de Stripe. Esta clave se utiliza para autenticar de forma segura su integración con Stripe y es necesaria para procesar los pagos del cliente.',
        TOOLTIP_PUBLIC_KEY: 'Ingrese su clave pública Stripe. Esta clave se utiliza para autenticar de forma segura su integración con Stripe en el lado del cliente y es necesaria para procesar los pagos del cliente.',
      },
      modal: {
        deleteStripeIntegration: 'Eliminar la integración de rayas',
        wantToDeleteStripeIntegration: '¿Estás seguro de que quieres eliminar esta integración de rayas?',
      }
    }
  },
  customer: {
    manageCustomers: 'Administrar clientes',
    addNewCustomers: 'Agregar nuevos clientes',
    ensureYouHaveAddedAllYourCustomersHere: 'Asegúrese de haber agregado todos sus clientes aquí',
    customerName: 'Nombre del cliente',
    nameIsRequired: 'Se requiere el nombre',
    emailId: 'Identificación de correo',
    enterAValidEmail: 'Introduzca un correo electrónico válido',
    createPassword: 'Crear contraseña',
    enterAStrongPassword: 'Ingrese una contraseña segura',
    confirmPassword: 'confirmar Contraseña',
    cancel: 'Cancelar',
    customer: 'Cliente',
    searchByNameOrEmail: 'Buscar por nombre o correo electrónico',
    filters: 'Filtros',
    allCustomers: 'Todos los clientes',
    paidCustomers: 'Clientes pagados',
    freeCustomers: 'Clientes gratis',
    messageAddOnCustomers: 'Mensaje agregar a los clientes',
    botWhiteLabelCustomers: 'Clientes de Bot Whitelabel',
    sortBy: 'Ordenar por',
    sortByFrequency: 'Ordenar por frecuencia',
    all: 'Todo',
    joinedDate: 'Fecha unida',
    plan: 'Plan',
    lastLogin: 'Último acceso',
    email: 'Correo electrónico',
    nextDue: 'Lo próximo por hacer',
    frequency: 'Frecuencia',
    clearAll: 'Limpiar todo',
    apply: 'Aplicar',
    messageConsumed: 'Mensaje consumido',
    addCustomer: 'Agregar cliente',
    serialNumber: 'No Señor.',
    name: 'Nombre',
    contact: 'Contacto',
    limit: 'Límite',
    location: 'Ubicación',
    actions: 'Comportamiento',
    joinedOn: 'Unido a',
    typeOfCustomer: 'tipo de cliente',
    exceptWhatsapp: ' (Excepto whatsapp)',
    exceptWA: '(Excepto wa)',
    wa: '(WASHINGTON)',
    loadMore: 'Carga más',
    conversationsWhatsapp: ' Conversaciones (whatsapp)',
    appTooltip: {
      enterCustomerEmail: 'Ingrese el correo electrónico del cliente',
      enterCustomerPassword: 'Ingrese la contraseña del cliente',
      enterCustomerConfirmPassword: 'Ingrese la contraseña de confirmación del cliente',
      enterCustomerName: 'Ingrese el nombre del cliente',
    },
    placeholders: {
      enterCustomerEmail: 'Ingrese el correo electrónico del cliente',
      searchByNameOrEmail: 'Buscar por nombre o correo electrónico',
      enterPassword: 'Introducir la contraseña',
      enterCustomerName: 'Ingrese el nombre del cliente',
    }
  },
  dashboard: {
    dashboard: 'Panel',
    overview: 'Descripción general',
    totalCustomers: 'Total de clientes',
    paidCustomers: 'Clientes pagados',
    freeCustomers: 'Clientes gratis',
    thisWeeksSignUp: 'Registro de esta semana',
    todaysSignUp: 'Registros de hoy',
    totalEarnings: 'Ganancias Totales',
    nA: 'N / A',
    customerName: 'Nombre del cliente',
    nameIsRequired: 'Se requiere el nombre',
    emailId: 'Identificación de correo',
    enterAValidEmail: 'Introduzca un correo electrónico válido',
    editCustomer: 'Editar cliente',
    recentSignUps: 'Inscripciones recientes',
    serialNumber: 'No Señor.',
    name: 'Nombre',
    email: 'Correo electrónico',
    contact: 'Contacto',
    plan: 'Plan',
    limit: 'Límite',
    location: 'Ubicación',
    lastLogin: 'Último acceso',
    joinedOn: 'Unido a',
    actions: 'Comportamiento',
    exceptWhatsapp: ' (Excepto whatsapp)',
    messageConsumed: 'Mensaje consumido',
    conversationsWhatsapp: ' Conversaciones (whatsapp)',
    placeholders: {
      enterCustomerName: 'Ingrese el nombre del cliente',
      enterCustomerEmail: 'Ingrese el correo electrónico del cliente',
    },
    modal: {
      deleteCustomer: 'Eliminar al cliente',
      areYouSure: '¿Estás seguro de que quieres eliminar a este cliente?',
    }
  },
  downgradeRequests: {
    activites: 'Actividades',
    upcomingRenewals: 'Próximas renovaciones',
    subscriptionActivities: 'Actividades de suscripción',
    upcomingDonwgradeRequests: 'Próximas solicitudes de degradación',
    serialNumber: 'No Señor.',
    email: 'Correo electrónico',
    phoneNumber: 'Telefono no.',
    currentPlan: 'Plan actual',
    requestedOn: 'Solicitado en',
    downgradeOn: 'Degradar',
    nA: 'N / A',
    commaMonthly: ', Mensual',
    commaYearly: ' , Anualmente',
    noDataFound: {
      downgradeRequests: 'Solicitudes de degradación',
      noDowngradeRequestFound: '¡No hay solicitudes de degradación encontradas todavía!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Próximas renovaciones de los clientes',
      subscriptionActivities: 'Actividades de suscripción',
      upcomingDowngradeOfCustomers: 'Próximas solicitudes de degradación de los clientes',
      serialNumber: 'Número de serie',
      customerEmail: 'Correo electrónico del cliente',
      customerPhoneNumber: 'Número de teléfono del cliente',
      currentPlanOfCustomer: 'Plan actual del cliente',
      currentPlanPrice: 'Precio del plan actual',
    }
  },
  emailIntegration: {
    emailIntegration: 'Integración por correo electrónico',
    addEmailIntegrationDetails: 'Agregar detalles de integración de correo electrónico',
    integrateYourEmailProviderDetails: 'Integre los detalles de su proveedor de correo electrónico. Se enviarán todos los correos electrónicos utilizando este correo electrónico a sus clientes.',
    awsRegion: 'Región de AWS',
    awsRegionIsRequired: 'Se requiere la región de AWS',
    awsAccessKeyId: 'ID de clave de acceso de AWS',
    awsAccessKeyIdIsRequired: 'Se requiere la identificación de la clave de acceso de AWS',
    awsSecretAccessKey: 'Clave de acceso secreto de AWS',
    awsSecretAccessKeyIsRequired: 'Se requiere la clave de acceso secreto de AWS',
    cancel: 'Cancelar',
    test: 'Prueba',
    action: 'Acción',
    testEmail: 'Email de prueba',
    addDetails: 'Agregar detalles',
    updateDetails: 'Detalles de actualización',
    saveDetails: 'Guardar detalles',
    placeholders: {
      enterAwsRegion: 'Ingrese la región de AWS',
      enterAwsAccessKeyId: 'Ingrese la ID de clave de acceso AWS',
      enterAwsSecretAccessKey: 'Ingrese la clave de acceso secreto de AWS',
    },
    modal: {
      deleteEmailIntegration: 'Eliminar la integración del correo electrónico',
      areYouSure: '¿Estás seguro de que quieres eliminar esta integración de correo electrónico?',
    },
    noDataFound: {
      emailIntegration: 'Integración por correo electrónico',
      noEmailIntegrationFoundYet: '¡No hay integración de correo electrónico encontrada todavía!',
    }
  },
  subscription: {
    activities: 'Actividades',
    upcomingRenewals: 'Próximas renovaciones',
    subscriptionActivities: 'Actividades de suscripción',
    upcomingDonwgradeRequests: 'Próximas solicitudes de degradación',
    filters: 'Filtros',
    manageActivities: 'Gestionar actividades',
    sortBy: 'Ordenar por',
    all: 'Todo',
    upgrades: 'Actualización',
    donwgrades: 'Rebajas',
    renewals: 'Renovación',
    serialNumber: 'No Señor.',
    email: 'Correo electrónico',
    phoneNumber: 'Telefono no.',
    previousPlan: 'Plan anterior',
    newPlan: 'Nuevo plan',
    description: 'Descripción',
    date: 'Fecha',
    nA: 'N / A',
    upgraded: 'actualizado',
    commaYearly: ' , Anualmente',
    commaMonthly: ' , Mensual',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: '¡No hay actividades de suscripción encontradas todavía!',
      subscriptionsActivities: 'Actividades de suscripciones',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Próximas renovaciones de los clientes',
      subscriptionActivities: 'Actividades de suscripción',
      upcomingDowngradeRequests: 'Próximas solicitudes de degradación de los clientes',
      serialNumber: 'Número de serie',
      customerEmail: 'Correo electrónico del cliente',
      customerPhoneNumber: 'Número de teléfono del cliente',
      previousPlan: 'Plan anterior del cliente',
      newPlan: 'Nuevo plan del cliente',
      description: 'Descripción del plan',
      typeOfPayment: 'Tipo de pago',
    }
  },
  feedbacks: {
    feedbacks: 'Retroalimentación',
    serialNumber: 'No Señor.',
    customer: 'Cliente',
    type: 'Tipo',
    rating: 'Clasificación',
    reason: 'Razón',
    description: 'Descripción',
    createdAt: 'Creado en',
    nA: 'N / A',
    loadMore: 'Carga más',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Comentarios de eliminación de cuentas y bots',
      noFeedbacksFoundYet: '¡No hay comentarios encontrados todavía!',
    },
    appTooltip: {
      serialNumber: 'Número de serie',
      customerEmail: 'Correo electrónico del cliente',
      feedbackForBotOrAccountDeletion: 'Featback para (eliminación de bot/cuenta)',
      customerRating: 'Puntuación de los clientes',
      customerReason: 'Razón del cliente',
      feedbackCreatedAt: 'Comentarios creados en',
      customerFeedback: 'Comentarios de los clientes',
    }
  }
};
