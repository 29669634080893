import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  staticText: any;
  displayDate: any;
  @Input() selectedDate: Date;
  @Output() getDate = new EventEmitter<any>();
  constructor(
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.datePicker;
   }

  ngOnInit(): void {
  }
  emitDate(event) {
    this.displayDate = event.target.value.toLocaleDateString();
    this.getDate.emit({startDate: event.target.value.toISOString()});
  }
}
