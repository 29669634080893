import {Action, createReducer, on} from '@ngrx/store';
import {addSocialSubscriber, deleteSocialSubscriber, loadSocialSubscribers, modifySocialSubscriber} from '../actions/social-subscribers.actions';


export const subscribersFeatureKey = 'subscribers';

export const initialState: any = [];

const socialSubscribersReducer = createReducer(
  initialState,
  on(loadSocialSubscribers, (state, {subscribers}) => subscribers),
  on(addSocialSubscriber, (state, {subscriber}) => [...state, subscriber]),
  on(deleteSocialSubscriber, (state, {subscriber}) => state.filter(item => item.id !== subscriber)),
  on(modifySocialSubscriber, (state, {subscriber, update}) => state.map(item => {
    if (item._id === subscriber) {
      return {...item, ...update};
    }
    return item;
  })),
);

export function reducer(state: any | undefined, action: Action) {
  return socialSubscribersReducer(state, action);
}
