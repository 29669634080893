const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Il mio conto',
  yourProfileAndPasswordSecurity: 'Il tuo profilo e la sicurezza della password',
  detailsHeader: 'Dettagli',
  passwordHeader: 'Parola d`ordine',
  customAttributeHeader: 'Attributi Personalizzati',
  tagsHeader: 'Etichetta',
  accessTokenHeader: 'Token di accesso',
  thirdPartyIntegrationsHeader: 'Integrazioni di terze parti',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Aggiorna il profilo del tuo account',
    TOOLTIP_PASSWORD_HEADING: 'Cambia la tua password',
    TOOLTIP_TAGS_HEADING: 'Gestisci i tuoi Tag',
    TOOLTIP_THIRD_PARTY_HEADING: 'Integrazioni di terze parti',
    TOOLTIP_CHANGE_PASSWORD_TITLE: 'Una password sicura aiuta a proteggere il tuo account $ {Brand}',
    TOOLTIP_INTEGRATIONS: 'Integrare',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Aggiungi o aggiorna attributi personalizzati',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Gestisci i tuoi attributi personalizzati'
  },
  accessToken: {
    accessToken: 'Token di accesso',
    generateYourAccessTokenForUsingPlatformAPIs: 'Genera il tuo token di accesso per l`utilizzo delle API della piattaforma',
    generateApiToken: 'Generare token API',
    revokeApiToken: 'Revoca token API',
    regenerateApiToken: 'Rigenerare il token API',
    placeholders: {
      yourApiKey: 'La tua chiave API',
    }
  },
  details: {
    name: 'Nome',
    emailId: 'E-mail identificativo utente',
    yourPlan: 'Il tuo piano',
    messagesPerMonth: ' messaggi/mese',
    daysOfStoragePlan: ' Giorni di piano di stoccaggio',
    conditionalFeatures: 'Caratteristiche condizionali',
    upgradeToEnterprisePlanToGetMore: 'Aggiorna al piano aziendale per ottenere di più',
    featuresAndStorageCapacity: 'Capacità di funzionalità e archiviazione',
    upgradePlan: 'Piano di aggiornamento',
    submit: 'Invia',
    selectLanguage: 'Seleziona la lingua',
    baby: 'Bambino',
    king: 'Re',
    conversationsperMonth: 'coperte/mese',
    english: 'Inglese',
    hindi: 'hindi',
    german: 'Tedesco',
    french: 'francese',
    portuguese: 'Portoghese',
    spanish: 'spagnolo',
    italian: 'Italiano',
    russian: 'russo',
    chatBotBrandName: 'Nome della chatbot Brand',
    placeholders: {
      john: 'John',
      email: 'E-mail',
      searchLanguage: 'Lingua di ricerca',
      chatBotBrandName: 'Nome della chatbot Brand'
    },
    appTooltip: {
      TOOLTIP_NAME: 'Inserisci il nome del tuo profilo',
      TOOLTIP_EMAIL_ADDRESS: 'Inserisci il tuo indirizzo email',
    }
  },
  customAttribute: {
    addAttribute: 'Aggiungi Attributo',
    discard: 'Scarta',
    customAttribute: 'Attributi Personalizzati',
    checkOrAddCustomAttribute: 'Qui puoi verificare e aggiungere i tuoi attributi personalizzati.',
    attributeKey: 'Chiave dell\'Attributo',
    attributeName: 'Nome dell\'Attributo',
    bot: 'Bot',
    flow: 'Flusso',
    loadMore: 'Carica Altri',
    question: 'Domanda',
    action: 'Azione',
    search: 'Ricerca',
    selectBot: 'Seleziona Bot',
    selectFlow: 'Seleziona Flusso',
    selectQuestion: 'Seleziona Domanda',
    noDataFoundHeading: 'Non hai ancora creato alcuna attributo',
    noDataFoundSubheading: 'Tutte le tue tributi appariranno qui',
    deleteTitle: 'Elimina Attributo',
    deleteDescription: 'Sei sicuro di voler eliminare questo Attributo?',
    deleteAttributes: 'Elimina Attributi',
    areYouSureYouWantToDeleteThisAttributes: 'Sei sicuro di voler eliminare questo Attributo?',
    editAttribute: 'Modifica Attributo',
    headsUp: 'Dritta',
    okay: 'Va bene',
    dataAddedToLead: 'Gli attributi mappati per le tue API o i tuoi flussi del bot non verranno aggiornati. Si prega di aggiornare le API o i flussi del bot di conseguenza.',
    placeholders: {
      enterAttributeKey: 'Inserisci la Chiave dell\'Attributo',
      enterAttributeName: 'Inserisci il Nome dell\'Attributo',
      selectBot: 'Seleziona Bot',
      selectFlow: 'Seleziona Flusso',
      selectQuestion: 'Seleziona Domanda',
      search: 'Cerca per Nome Attributo'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Inserisci la Chiave dell\'Attributo',
      TOOLTIP_ATTRIBUTE_NAME: 'Inserisci il Nome dell\'Attributo',
      TOOLTIP_SELECT_BOT: 'Seleziona Bot',
      TOOLTIP_SELECT_FLOW: 'Seleziona Flusso',
      TOOLTIP_SELECT_QUESTION: 'Seleziona Domanda',
    }
  },
  password: {
    changePassword: 'Cambiare la password',
    aSecurePasswordHelpsProtectYour: 'Una password sicura aiuta a proteggere il tuo',
    account: ' Account',
    currentPassword: 'password attuale',
    newPassword: 'nuova password',
    confirmPassword: 'Conferma password',
    cancel: 'Annulla',
    updatePassword: 'Aggiorna la password',
    deleteAccount: 'Eliminare l`account',
    placeholders: {
      enterYourCurrentPassword: 'Inserisci la tua password attuale',
      enterYourNewPassword: 'Inserisci la tua nuova password',
      enterYourConfirmPassword: 'Inserisci la tua password di conferma',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Immettere la vecchia password',
      TOOLTIP_NEW_PASSWORD: 'Inserire una nuova password',
      TOOLTIP_CONFIRM_PASSWORD: 'Conferma la tua nuova password',
      TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Gestisci Tag',
    createNewTags: 'Crea Nuovi Tag',
    createNewTagsSubText: 'Gestisci i tuoi tag qui per organizzare meglio i tuoi lead, chat, gruppi, ecc.',
    tagName: 'Nome del Tag',
    updateTag: 'Aggiorna Tag',
    createTag: 'Crea Tag',
    discard: 'Annulla',
    searchTag: 'Cerca Tag per Nome',
    noTagFound: 'Nessun Tag Trovato!',
    createATag: 'Crea un Tag',
    deleteTagModalSubText: 'Sei sicuro di voler eliminare questo tag? L`eliminazione di questo tag rimuoverà anche il tag dagli utenti assegnati!',
    deleteTagModalHeading: 'Elimina Tag',
    deleteOneTagModalHeading: 'Elimina Tag',
    actions: 'Azioni',
    placeholders: {
        enterTag: 'Inserisci il Nome del Tag',
    },
    appTooltip: {
        TOOLTIP_DELETE_TAGS: 'Elimina Tag',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'Nome',
      status: 'Stato',
      action: 'Azione',
      messageBird: 'Messagebird',
      messageBirdForSendingMessagesAndEmails: 'Messagebird per l`invio di messaggi ed e -mail',
      notConnected: 'Non collegata',
      connected: 'Collegato',
      edit: 'Modificare',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo per l`invio di messaggi',
      connect: 'Collegare',
      appTooltip: {
        nameOfAppOrCrm: 'Nome dell`app/CRM',
        statusAsConnectedOrNotConnected: 'Stato come connesso / non connesso',
        clickToConnectAppOrCrm: 'Fare clic per connettere l`app/CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Oops! Le integrazioni non sono disponibili nel piano per bambini. Si prega di aggiornare al piano King per le integrazioni.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'Messagebird',
        integrateWithMessageBird: 'Integrare con MessageBird',
        apiKey: 'Chiave API',
        originator: 'Originatore',
        connect: 'Collegare',
        disconnect: 'Disconnessione',
        seeActivities: 'Vedere le attività',
        sms: 'sms',
        time: 'Tempo',
        phoneNumber: 'Numero di telefono',
        status: 'Stato',
        botName: 'Nome bot',
        message: 'Messaggio',
        error: 'Errore',
        placeholders: {
          apiKey: 'Chiave API',
          messageBirdPhoneNumber: 'Numero di telefono di MessageBird',
        },
        noDataFound: {
          activities: 'Attività',
          noActivitiesFound: 'Nessuna attività trovata!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Integrare con Plivo',
        authId: 'AUTH ID',
        authToken: 'Token di auth',
        yourPlivoPhoneNumber: 'Il tuo numero di telefono Plivo',
        connect: 'Collegare',
        disconnect: 'Disconnessione',
        seeActivities: 'Vedere le attività',
        sms: 'sms',
        time: 'Tempo',
        phoneNumber: 'Numero di telefono',
        status: 'Stato',
        botName: 'Nome bot',
        message: 'Messaggio',
        error: 'Errore',
        noDataFound: {
          activities: 'Attività',
          noActivitiesFound: 'Nessuna attività trovata!',
        },
        placeholders: {
          authId: 'AUTH ID',
          authToken: 'Token di auth',
          yourPlivoPhoneNumber: 'Il tuo numero di telefono Plivo',
        }
      }
    }
  }
};
