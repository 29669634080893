export const team = {
  addMember: {
    team: 'Squadra',
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    addAMember: 'Aggiungi un membro',
    addYourTeamMembersText: 'Aggiungi i membri del tuo team come agenti di supporto a chat dal vivo con gli utenti di bot.',
    name: 'Nome',
    nameIsRequired: 'È richiesto il nome',
    email: 'E-mail',
    enterAValidEmail: 'Inserisci un`e -mail valida',
    password: 'Parola d`ordine',
    passwordMustContain: 'La password deve contenere',
    atleast6Characters: 'almeno 6 caratteri,',
    oneUpperCaseLetter: '1 lettera maiuscola',
    oneSpecialCharacter: '1 carattere speciale*',
    profileIcon: 'Icona del profilo',
    whatsappNumber: 'Numero whatsapp',
    leadVisibility: 'Visibilità del piombo*',
    addMember: 'Aggiungi membro',
    role: 'Ruolo',
    createdOn: 'Creato',
    action: 'Azione',
    updateMember: 'Aggiorna membro',
    add: 'Aggiungere',
    cancel: 'Annulla',
    selectVisibilityOption: 'Seleziona l`opzione di visibilità',
    all: 'Tutto',
    assigned: 'Assegnato',
    nA: 'N / A',
    admin: 'Amministratore',
    agent: 'Agente',
    placeholders: {
      enterMemberName: 'Immettere il nome del membro',
      enterMemberEmailAddress: 'Immettere l`indirizzo e -mail del membro',
      createPasswordForMember: 'Crea una password per il membro',
      searchCountry: 'Cerca paese',
      enterWhatsappNumber: 'Immettere il numero di WhatsApp',
      selectLeadsVisibility: 'Seleziona visibilità dei lead',
      searchTeamByNameOrEmail: 'Cerca membro del team per nome o e -mail',
    },
    modal: {
      deleteTeamMember: 'Elimina membro del team',
      wantToDeleteThisTeamMember: 'Sei sicuro di voler eliminare questo membro del team?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Fare clic per aggiungere un nuovo membro del team',
      profileIconOfTeamMember: 'Icona/immagine del profilo del membro del team',
      nameOfTeamMember: 'Nome del membro del team',
      emailOfTeamMember: 'Email del membro del team',
      whatsappNumberOfTeamMember: 'WhatsApp Numero di membro del team',
      roleOfTeamMemberAsAdminOrAgent: 'Ruolo del membro del team come amministratore/agente',
      dateAndTimeOfCreatingTeamMemberRole: 'Data e ora di creazione del ruolo di membro del team',
      editOrDeleteTeamMember: 'Modifica/Elimina membro del team',
      editTeamMembersDetails: 'Modifica i dettagli del membro del team.',
      removeAccessOfTeamMemberByDeletingAccount: 'Rimuovere l`accesso del membro del team eliminando l`account',
    }
  },
  users: {
    manageMembers: 'Gestisci Membri',
    managerMembersWhichCanAccessPanel: 'Gestisci il tuo team di partner che può accedere al tuo pannello',
    createNewMember: 'Crea Nuovo Membro',
    createMember: 'Crea Membro',
    updateMember: 'Aggiorna Membro',
    deleteTitleMember: 'Elimina Membro',
    deleteDescriptionMember: 'Sei sicuro di voler eliminare questo membro?',
    noMemberFound: 'Nessun Membro Trovato!',
    titleMember: 'Crea un Membro',
    manageUsers: 'Gestisci Utenti',
    createNewUser: 'Crea Nuovo Utente',
    managerUserWhichCanAccessPanel: 'Gestisci gli utenti che possono accedere al tuo pannello',
    phoneNumber: 'Numero di telefono',
    discard: 'Annulla',
    createUser: 'Crea utente',
    updateUser: 'Aggiorna utente',
    noUserFOund: 'Nessun utente trovato!',
    title: 'Crea un Utente',
    profileIcon: 'Icona del Profilo*',
    name: 'Nome*',
    email: 'Email*',
    password: 'Password*',
    selectRole: 'Seleziona Ruolo*',
    selectARole: 'Seleziona un ruolo',
    selectLeadVisibility: 'Seleziona Visibilità Lead*',
    confirmPassword: 'Conferma Password*',
    tableHeadingName: 'Nome',
    tableHeadingEmail: 'Email',
    tableHeadingPhoneNumber: 'Numero di telefono',
    tableHeadingRole: 'Ruolo',
    tableHeadingStatus: 'Stato',
    tableHeadingAction: 'Azione',
    noUserFound: 'Nessun Utente Trovato!',
    deleteTitle: 'Elimina Utenti',
    deleteDescription: 'Sei sicuro di voler eliminare questo utente?',
    placeholders: {
      searchHere: 'Cerca qui',
      enterName: 'Inserisci il nome',
      enterEmail: 'Inserisci l\'indirizzo email',
      searchCountry: 'Cerca il paese',
      enterPhoneNumber: 'Inserisci il numero di t elefono',
      enterPassword: 'Inserisci la password',
      enterConfirmPassword: 'Conferma la password'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Elimina utenti',
      TOOLTIP_DELETE_MEMBERS: 'Elimina membri'
    }
  },
  roles: {
    manageRoles: 'Gestisci Ruoli',
    manageYourRoles: 'Gestisci i tuoi ruoli.',
    name: 'Nome*',
    description: 'Descrizione',
    discard: 'Annulla',
    createRole: 'Crea Ruolo',
    creteARole: 'Crea un Ruolo',
    updateRole: 'Aggiorna Ruolo',
    assignPermissions: 'Assegna Permessi',
    noUserFound: 'Nessun Ruolo Utente Trovato!',
    tableHeadingName: 'Nome',
    tableHeadingDescription: 'Descrizione',
    tableHeadingStatus: 'Stato',
    tableHeadingAction: 'Azione',
    module: 'Modulo',
    read: 'Leggere',
    write: 'Scrivere',
    remove: 'Rimuovere',
    notes: 'Note',
    deleteTitle: 'Elimina Ruolo',
    deleteDescription: 'Attenzione: Quando si elimina questo ruolo, verranno eliminati anche tutti gli utenti assegnati a questo ruolo. \n Sei sicuro di voler eliminare questo ruolo?',
    deleteMemberDescription: 'Attenzione: Se elimini questo ruolo, verranno eliminati anche tutti i membri assegnati a questo ruolo. Sei sicuro di voler eliminare questo ruolo?',
    placeholders: {
      enterRoleName: 'Inserisci il nome del ruolo',
      searchHere: 'Cerca qui',
      enterDescriptionForRole: 'Inserisci una descrizione per il ruolo'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Sezioni a cui è possibile consentire o impedire l\'accesso agli utenti',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Fare clic per aggiungere un nuovo ruolo',
    }
  },
  accessControl: {
    users: 'Utenti',
    members: 'Membri',
    partnerTeam: 'Squadra partner',
    roles: 'Ruoli'
  }
};
