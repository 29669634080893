import { Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChange, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DateFilter } from 'src/appv2/models/leads';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @ViewChild('datepickerFooter', { static: false }) datepickerFooter: ElementRef;
  @ViewChild(MatDateRangePicker, { static: false }) datepicker: MatDateRangePicker<any>;
  minDate: Date;
  maxDate: Date;
  staticText: any;
  @Input() start: Date;
  @Input() end: Date;
  @Output() dateRange = new EventEmitter<any>();

  color = '';
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.dateRangePicker;
  }

  ngOnInit(): void {
    this.maxDate = new Date();

    if (this.start && this.end) {
      this.range.patchValue({
        start: new Date(this.start).toISOString(),
        end: new Date(this.end).toISOString()
      });
    }
  }

  setValidation() {
    this.minDate = new Date(this.range.value.start);
    const startDate = new Date(this.range.value.start).setTime(new Date().getTime());
    this.maxDate = new Date(this.minDate.getTime() + (30 * 24 * 60 * 60 * 1000));
    if (this.maxDate > new Date()) {
      this.maxDate = new Date();
    }
  }

  emitDate() {
    const startDate = this.range.value.start;
    const endDate = this.range.value.end;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    this.dateRange.emit({ startDate: startDate?.toISOString(), endDate: endDate?.toISOString() });
  }

  selectDate(type: string) {
    this.end = new Date(new Date().setHours(23, 59, 59, 999));
    if (type === 'today') {
      this.start = new Date(new Date().setHours(0, 0, 0, 0));

    } else if (type === 'month') {
      this.start = new Date(new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0));
      // this.start = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000));
    } else {
      // this.start = new Date(Date.now() - (7 * 24 * 60 * 60 * 1000));
      this.start = new Date(new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0));
    }

    this.range.patchValue({
      start: this.start,
      end: this.end
    });
    this.dateRange.emit({ startDate: this.start.toISOString(), endDate: this.end.toISOString() });
    this.minDate = this.start;
    this.datepicker.close();
  }

  appendFooter() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }

}
