export const subscription = {
  subscriptions: 'Abbonamenti',
  plan: 'Piano',
  whatsappPlan: 'Piano Whatsapp',
  addOns: 'Componenti aggiuntivi',
  billingDetails: 'Dettagli di fatturazione',
  invoices: 'Fatture',
  wa1Reminder: 'WA 1 ° promemoria',
  wa2Reminder: '2 ° promemoria',
  waRenewApi: 'WA Renew API',
  waCheckUsageApi: 'WA Controlla l`API di utilizzo',
  waResetUsageApi: 'Wa reset API di utilizzo',
  waExceedUsageApi: 'Wa supera l`API di utilizzo',
  waGetBotsInThisAccount: 'Wa ottieni robot in questo account',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Abbiamo i prezzi più convenienti per tutte le tue esigenze.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'Piano Whatsapp',
    TOOLTIP_ADDS_ON_HEADING: 'Acquista pacchetti di massaggi aggiuntivi e bot whitelabel',
    TOOLTIP_ADDS_ON_MESSAGE: 'Se hai raggiunto il limite di messaggi mensili, puoi acquistare pacchetti aggiuntivi.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Controlla i dettagli dell`abbonamento corrente',
    TOOLTIP_HORIZONTAL_BAR: 'Controlla i nostri prezzi mensili o annuali da 3K a 500K messaggi spostando la barra da sinistra a destra.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Aggiorna i dettagli di fatturazione per la fatturazione.',
    TOOLTIP_INVOICES_HEADING: 'Controlla le tue fatture precedenti.',
    TOOLTIP_INVOICES_ACTION: 'Fare clic per ricevere la fattura nel tuo account e -mail',
  },
  currentPlan: {
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    downgradeRequest1: 'Hai effettuato una richiesta per eseguire il downgrade del tuo piano',
    downgradeRequest2: 'messaggi. Si prega di notare, il piano verrà declassato entro la fine dell`attuale ciclo di fatturazione',
    downgradeRequest3: 'Potresti goderti il',
    downgradeRequest4: 'servizi fino ad allora.',
    downgradeRequestMaster1: 'Hai effettuato una richiesta per il downgrade del tuo',
    downgradeRequestMaster2: ' piano per',
    downgradeRequestMaster3: ' conversazioni. Si prega di notare, il piano verrà declassato entro la fine dell`attuale ciclo di fatturazione',
    downgradeRequestMaster4: 'Potresti goderti il',
    downgradeRequestMaster5: 'servizi fino ad allora.',
    active: 'Attivo',
    inactive: 'Inattivo',
    month: 'mese',
    year: 'anno',
    messagePack: 'Pacchetto di messaggi',
    king: 'Re',
    baby: 'Bambino',
    whatsapp: 'WhatsApp',
    youAreOn: 'Siete su',
    plan: 'piano',
    totalMessagesLimit: 'Limite di messaggi totali',
    liveAgentLimit: 'Limite di agente dal vivo',
    perMonth: '/mese',
    agents: 'agente (i)',
    messagesAddOn: 'Messaggi AGGIORNO',
    botWhitelabels: 'Bot whitelabels',
    totalAddOnMessages: 'Messaggi aggiuntivi totali',
    basePrice: 'Prezzo base',
    gst: 'GST',
    totalPrice: 'Prezzo totale',
    totalAddOnPrice: 'Prezzo aggiuntivo totale',
    whitelabelPrice: 'Prezzo Whitelabel',
    startedOn: 'Iniziato',
    nextDueOn: 'Successivamente previsto',
    addOnExpiry: 'ESIDENZA AGGIUNTI',
    website: 'Sito web',
    facebook: 'Facebook',
    telegram: 'Telegram'
  },
  invoice: {
    invoiceNumber: 'Fattura n.',
    date: 'Data',
    invoiceAmount: 'Importo della fattura',
    planAmount: 'Importo del piano',
    plan: 'Piano',
    messages: 'Messaggi',
    action: 'Azione',
    nA: 'N / A',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Fare clic per ricevere la fattura nel tuo account e -mail',
      clickToDownloadInvoice: 'Fare clic per scaricare la fattura nel modulo PDF',
    }
  },
  messageAddOn: {
    validity: 'Validità',
    kingPlanHeader: '👑 Piano re',
    youAreOnThe: 'Sei sul',
    asOfNowAndWillGet: 'A partire da ora, e otterrà',
    messages: 'messaggi',
    inThisPlan: 'in questo piano.',
    youCanBuyAdditionalMessaes: 'Puoi acquistare ulteriori messaggi secondo i requisiti aziendali.',
    plan: ' Piano',
    baby: 'Bambino',
    king: 'Re',
    get: 'Ottenere',
    babyPlan: 'Piano per bambini',
    upgradePlan: 'Piano di aggiornamento',
    asOfNow: ' al momento.',
    pleaseUpgradeToKingPlan: 'Si prega di aggiornare a',
    toEnableAddOnsAndWhitelabelPacks: ' Per abilitare i componenti aggiuntivi e i pacchetti di whitelabel.',
    messageAddOnPacks: 'Messaggi pacchetti aggiuntivi',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Se hai raggiunto il limite di messaggi mensili, acquista pacchetti aggiuntivi e goditi i servizi ininterrotti.',
    pleaseSelectNumberOfExpectedMessages: 'Seleziona il numero di messaggi previsti',
    addOnsValidity: 'Validità dei componenti aggiuntivi',
    currentBillingCycle: 'Ciclo di fatturazione attuale',
    oneYear: 'Un anno',
    summary: 'Riepilogo',
    messageCreditsWithKingPlan: 'Crediti di messaggio con King Plan',
    messageCreditsWith: 'Crediti di messaggio con ',
    withPlan: '',
    billingCycle: 'Ciclo di fatturazione',
    payableAmount: 'Importo pagabile',
    oneTimePayment: 'Pagamento unico',
    plusTaxes: ' + Tasse',
    buyNow: 'Acquista ora',
    whiteLabelChatWindow: 'Finestra di chat con etichetta bianca',
    noBotsLeftToWhiteLabel: 'Nessun robot è rimasto a Whitelabel.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Seleziona i robot che desideri Whitelabel.',
    selectBots: 'Seleziona i robot',
    bot: 'Bot',
    bots: 'Robot',
    toBeWhiteLabelled: ' essere bianco',
    remove: 'Rimuovere',
    brandNameFromChatWindow: ' Brandname dalla finestra della chat.',
    willBeValidTill: 'Sarà valido fino a',
    ourYearlyPrice: 'Il nostro prezzo annuale',
    directWhiteLabel: 'Whitelabel diretto',
    whiteLabelledBots: 'Robot whitelabeld',
    nextRenewalColon: 'Prossimo rinnovamento:',
    optOutRequested: 'Oltrepassati',
    contactAgency: 'Agenzia di contatto',
    optOut: 'Decidere di uscire',
    removeWhiteLabel: 'Rimuovere Whitelabel',
    messageCount: 'Messaggi',
    numberOfPacks: 'Numero di pacchetti',
    amount: 'Importo',
    taxes: 'Tasse',
    totalAmount: 'Importo totale',
    numOfBots: 'N. di Bot',
    select: 'Selezionare',
    selectTheValidity: 'Seleziona la validità',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Dettagli di pagamento',
    cardNumber: 'Numero di carta',
    cvv: 'CVv',
    // cardNumber: 'Numero di carta',
    month: 'Mese',
    year: 'Anno',
    save: 'Salva',
    add: 'Aggiungere',
    edit: 'Modificare',
    disclaimer: 'Disclaimer:',
    disclaimerDescription1: 'I dettagli del metodo di pagamento di cui sopra saranno applicabili solo agli utenti al di fuori dell`India.',
    disclaimerDescription2: 'Non stiamo salvando i dettagli della tua carta con noi. Nel caso in cui desideri modificare i dettagli della tua carta, verrà modificato direttamente con il gateway di pagamento.',
    billingAddress: 'Indirizzo di fatturazione',
    companyName: 'Nome della ditta',
    country: 'Paese',
    selectCountry: 'Seleziona il paese',
    stateOrRegion: 'Stato/regione',
    streetAddress: 'Indirizzo',
    gstIn: 'Gstin',
    postalCode: 'Codice Postale',
    cancel: 'Annulla',
    proceedToPay: 'Procedere a pagare',
    company: 'Azienda',
    state: 'Stato',
    masterCard: 'Master Card',
    yourCompanyName: 'Il nome della tua azienda',
    yourCountry: 'Il tuo paese',
    yourRegion: 'La tua regione',
    yourPostalCode: 'il tuo codice postale',
    plusTaxes: ' + tasse',
    dropDown: {
      month: 'Mese *',
      year: 'Anno *',
    },
    placeholders: {
      enterCardNumber: 'Immettere il numero della scheda',
      cvv: 'CVv',
      enterYourCompanyName: 'Inserisci il nome della tua azienda',
      enterYourStateOrRegion: 'Inserisci il tuo stato/regione',
      enterYourStreetAddress: 'Inserisci il tuo indirizzo di strada',
      enterYourGSTIN: 'Inserisci il tuo GSTIN',
      enterPostalCode: 'Immettere il codice postale',
    }
  },
  plans: {
    viewDetailedPricing: 'Visualizza prezzi dettagliati',
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    weHaveGotAPricingPlanPerfectForYou: 'Abbiamo un piano di prezzi perfetto per te',
    weBelieve: 'Noi crediamo',
    shouldBeAccessibleToAllCompanies: ' dovrebbe essere accessibile a tutte le aziende, non importa le dimensioni.',
    monthly: 'Mensile',
    yearly: 'Annuale',
    twoMonthsFree: '(2 mesi gratuiti)',
    babyPlan: 'Piano per bambini',
    freeForever: 'Libero per sempre',
    messagesPerMonth: 'Messaggi al mese',
    currentPlan: 'Piano attuale',
    freePlan: 'Piano gratuito',
    kingPlan: 'Piano re',
    perYear: 'per anno',
    plan: 'piano',
    toThe: ' al',
    plusTaxes: ' + Tasse',
    perMonth: 'al mese',
    upgradePlan: 'Piano di aggiornamento',
    changePlan: 'Piano di cambiamento',
    currentlyActive: 'Attualmente attivo',
    emperorPlan: 'Piano imperatore (Enterprise)',
    sendAsManyMessagesAsYouWant: 'Invia tutti i messaggi di cui hai bisogno',
    contactUs: 'Contattaci',
    features: 'Caratteristiche',
    areYouSureYouWantToShiftFrom: 'Sei sicuro di voler passare dal',
    messages: 'Messaggi',
    planQuestionMark: 'piano?',
    downgradeToFreePlan: 'Downgrade al piano gratuito',
    goToBabyPlan: 'Vai al piano per bambini',
    thankYouForChoosingBotPenguin: 'Grazie per aver scelto BotPenguin',
    bots: 'Robot',
    facebookPages: 'Pagine di Facebook',
    teamMember: 'Membro della squadra',
    dataStorage: 'Archivio dati',
    conditionalFlow: 'Flusso condizionale',
    liveChat: 'Chat dal vivo',
    bookAppointments: 'Appuntamenti del libro',
    csvExport: 'Esportazione CSV',
    emailAndCallSupport: 'Supporto e -mail e chiamate',
    googleCalendarIntegrations: 'Integrazione del calendario di Google',
    zohoCrmIntegrations: 'Zoho CRM Integrazione',
    reportsAndAnalytics: 'Rapporti e analisi',
    removeBotPenguinBranding: 'Rimuovi il marchio BotPenguin',
    oneBot: '1 bot',
    twoKMessagesPerMonth: 'Messaggi 2K/mese',
    oneFacebookPage: '1 pagina Facebook',
    oneTeamMember: '1 membro del team',
    thirtyDaysOfDataStorage: '30 giorni di archiviazione dei dati',
    unlimitedBot: 'Bot illimitato',
    threeTo500kMessagesPerMonth: 'Messaggi da 3K a 500k/mese',
    tenFacebookPages: '10 pagine di Facebook',
    threeTeamMembers: '3 membri del team',
    nintyDaysOfDataStorage: '90 giorni di archiviazione dei dati',
    unlimitedMessages: 'Messaggi illimitati',
    unlimitedFacebookPages: 'Pagine di Facebook illimitate',
    unlimitedTeamMembers: 'Membri del team illimitati',
    unlimitedDataStorage: 'Archiviazione dati illimitata',
    youAreHere: 'Tu sei qui!',
    detailedPricing: {
      kingsplan: 'Piano King',
      elementary: 'Elementare',
      solutionType: 'Tipo di Soluzione',
      saas: 'SaaS',
      fbpage1fb: '1 Pagina di Facebook',
      perMonth100: '100 al mese',
      enterprise: 'Azienda',
      saasOnPremises: 'SaaS in sede',
      aiChatbotFeatures: 'Funzionalità Chatbot AI',
      dataSourceForTraning: 'Fonte dati per addestramento',
      smartFeatures: 'Funzionalità intelligenti',
      liveChat: 'Chat in tempo reale',
      day30: '30 giorni',
      day90: '90 giorni',
      landSquared: 'LandSquared',
      doItYourself: 'Fallo da te',
      unifiedInbox: 'Casella di posta unificata',
      platforms: 'Piattaforme',
      websiteChatbot: 'Chatbot del sito web',
      FacebookMessengerChatbot: 'Chatbot di Facebook Messenger',
      Telegram: 'Telegram',
      conversationalLandingPage: 'Pagina di atterraggio conversazionale',
      integrations: 'Integrazioni',
      reportsAndAnalytics: 'Rapporti e Analisi',
      customerSupport: 'Assistenza clienti',
      security: 'Sicurezza',
      bots: 'Bot',
      babyPlan: 'Piano Base',
      kingPlan: 'Piano King',
      emperorPlan: 'Piano Imperatore',
      noofBots: 'N. di bot',
      one: '1 (Uno)',
      unlimitedBots: 'Bot illimitati',
      noOfWebsite: 'N. di siti web',
      unlimitedWebsites: 'Siti web illimitati',
      unlimitedMessages: 'Messaggi illimitati',
      messagesSend: 'Messaggi inviati',
      perMonth2000: '2,000 al mese',
      upto500000perMonth: 'Fino a 500.000 al mese',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Pagine illimitate',
      telegramNumbers: 'Numeri di Telegram',
      unlimited: 'Illimitato',
      whatsappNumbers: 'Numeri di WhatsApp',
      subscribersLeads: 'Iscritti/Lead',
      createSupportTeam: 'Crea team di supporto per chat in diretta',
      oneMember: '1 Membro',
      threeMembers: '3 membri',
      conversationHistory: 'Cronologia delle conversazioni',
      thirtyDays: '30 Giorni',
      ninetyDays: '90 Giorni',
      chatbotDesigning: 'Progettazione del chatbot',
      weAreHereToHelp: 'Siamo qui per aiutarti',
      weTakeCareEndToEnd: '\'Ci prendiamo cura dall\'inizio alla fine\'',
      multipleLanguages: 'Lingue multiple',
      conditionalFlow: 'Flusso condizionale',
      removeBotPenguinBranding: 'Rimuovi il marchio BotPenguin',
      gptMessages: 'Messaggi GPT',
      aiChatbot: 'Chatbot AI',
      chatGPTIntegration: 'Integrazione di ChatGPT',
      configureAIChatbotPersonality: 'Configura la personalità del chatbot AI',
      trainChatbotOnYourOwnData: 'Addestra il chatbot con i tuoi dati',
      trainChatbotOnWebPages: 'Addestra il chatbot su pagine web',
      trainChatbotOnMultipleFiles: 'Addestra il chatbot su file multipli',
      trainChatbotOnFAQs: 'Addestra il chatbot su le domande frequenti (FAQ)',
      dataTrainingStorage: 'Archiviazione dei dati di addestramento',
      useChatGPTModel4_3_5Turbo: 'Usa il modello ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: '\'Carica file multipli per l\'addestramento del chatbot\'',
      naturalLanguageProcessing: 'Elaborazione del linguaggio naturale',
      naturalLanguageUnderstanding: 'Comprensione del linguaggio naturale',
      answerLength: 'Lunghezza della risposta',
      chatTone: 'Tono della chat',
      multiLanguage: 'Multilingue',
      answerFormatting: 'Formattazione delle risposte',
      customPrompt: 'Prompts personalizzati',
      integrateBotPenguinLLM: 'Integra BotPenguin LLM',
      integrateOwnCustomLLM: 'Integra LLM personalizzato',
      periodicallySyncDataSources: 'Sincronizza periodicamente le fonti dati',
      aiBasedSuggestedQuestions: 'Domande suggerite basate su AI',
      chooseVisibilityForParticularWebpageSitemap: 'Scegli la visibilità per pagine/sitemap specifiche',
      chooseKnowledgeMix: 'Scegli la miscela di conoscenze:\nLa tua base di conoscenze\nConoscenze di ChatGPT\n"Conoscenze di ChatGPT + La tua base di conoscenze"',
      faqExtractionFromFileAndURL: 'Estrazione FAQ da file e URL',
      analyticsToImproveAI: '\'Analisi per migliorare l\'IA\'',
      exportQuestionFlow: 'Esporta il flusso delle domande',
      sessionManagement: 'Gestione delle sessioni',
      collectFeedbackOnChatbotResponses: 'Raccogli feedback sulle risposte del chatbot',
      smartAIFollowUpQuestions: 'Domande di follow-up intelligenti/AI',
      trainOnYourNotionWorkspaceData: 'Addestra sui tuoi dati nel tuo spazio di lavoro Notion',
      trainOnYoutubeVideo: 'Addestra sui video di YouTube',
      trainOnCustomVideoUpload: 'Addestra con il caricamento di video personalizzati',
      rssFeed: 'Feed RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'Blocco di IP e regione',
      emailAndMobileValidation: 'Validazione di email e telefono cellulare',
      appointmentsBooking: 'Prenotazioni di appuntamenti',
      humanHandover: 'Passaggio a un operatore umano',
      botHandover: 'Passaggio a un bot',
      handoverBetweenAgents: 'Passaggio tra agenti',
      mobileAppsForAgents: 'App mobili per agenti',
      roleBasedAccessControls: 'Controlli di accesso basati sui ruoli',
      apiIntegrationOnChatFlows: 'Integrazione API su flussi di chat',
      liveChatWidget: 'Widget di chat in tempo reale',
      typingIndicator: 'Indicatore di scrittura',
      liveVisitorsDetails: 'Dettagli sui visitatori in tempo reale',
      multipleSimultaneousChat: 'Chat simultanea multipla',
      liveTypingView: 'Vista della scrittura in tempo reale',
      ruleBasedChatRouting: 'Instradamento della chat basato su regole',
      chatAutoAssignments: 'Assegnazioni automatiche della chat',
      efficientOmnichannelInterface: 'Interfaccia omnicanale efficiente',
      quickReplies: 'Risposte rapide',
      unifiedCustomerProfile: 'Profilo unificato del cliente',
      visitorStats: 'Statistiche dei visitatori',
      addContactsFromWhatsapp: 'Aggiungi contatti da WhatsApp',
      applyFilterAndSortConversations: 'Applica filtri e ordina le conversazioni',
      seeChatLogs: 'Visualizza i log delle chat',
      assignNotesAndChatTags: 'Assegna note e tag alle chat',
      directCallsToLeadsWithTwilio: 'Chiamate dirette ai lead con Twilio',
      customerSegmentation: 'Segmentazione dei clienti',
      exportLeadsInCSV: 'Esporta i lead in formato CSV',
      leadNotificationsByEmail: 'Notifiche dei lead via email',
      website: 'Sito web',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegram',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Attivazione automatica su dispositivi mobili/desktop',
      hideBotOnSpecificPages: 'Nascondi il bot su pagine specifiche',
      imageSharing: 'Condivisione di immagini',
      readyToUseChatFlowTemplates: '\'Modelli di flusso di chat pronti all\'uso\'',
      fileSharing: 'Condivisione di file',
      customDesignForChatWindow: 'Design personalizzato per la finestra di chat',
      facebookMessengerBot: 'Bot di Facebook Messenger',
      facebookSubheading: 'Sottotitolo di Facebook',
      telegramSubheading: 'Sottotitolo di Telegram',
      conversationalLangdingPageSubheading: 'Sottotitolo della pagina di atterraggio conversazionale',
      integrationsSubheading: 'Sottotitolo delle integrazioni',
      multipleSmartFlows: 'Flussi intelligenti multipli',
      dripCampaigns: 'Campagne a gocce',
      broadcastFBMessages: 'Messaggi di trasmissione di Facebook',
      multipleFacebookBotWidgets: 'Widget di bot di Facebook multipli',
      facebookAutoReplyOnComments: 'Risposta automatica di Facebook ai commenti',
      facebookECommerce: 'E-commerce di Facebook',
      broadcastAnalytics: 'Analisi delle trasmissioni',
      telegramMessengerBot: 'Bot di Telegram Messenger',
      readyToUseTemplates: '\'Modelli pronti all\'uso\'',
      groupBroadcastTelegramMessages: 'Messaggi di gruppo e trasmissione di Telegram',
      multipleTelegramBotWidgets: 'Diversi Widget del bot Telegram',
      conversationalLandingPageBot: 'Bot di pagina di atterraggio conversazionale',
      smartReplies: 'Risposte intelligenti',
      interactiveAndCustomizableUI: 'Interfaccia interattiva e personalizzabile',
      leadManagement: 'Gestione dei lead',
      easyToInstallAndShare: 'Facile da installare e condividere',
      agileCRM: 'CRM Agile',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Chiudi',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistema di emissione dei biglietti di supporto',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Chiamata audio e video in chat (Twilio/Agora)',
      courierTracking: 'Tracciamento dei corrieri',
      emailIntegration: '\'Integrazione dell\'email\'',
      webhooks: 'Webhooks',
      schedulers: 'Pianificatori',
      paymentGateways: 'Gateway di pagamento (Stripe e RazorPay)',
      enterpriseIntegrations: 'Integrazioni aziendali',
      customIntegration: 'Integrazione personalizzata',
      shopifyIntegration: 'Integrazione con Shopify',
      totalNumberOfMessages: 'Numero totale di messaggi',
      totalNumberOfLeads: 'Numero totale di lead',
      weeklyReportBotwise: 'Report settimanale, per bot',
      averageAgentResponeTime: '\'Tempo medio di risposta dell\'agente\'',
      visitorTimingReport: 'Report sui tempi dei visitatori',
      customerSegmentBasedReports: 'Report basati su segmenti di clienti',
      tagBasedReports: 'Report basati su tag',
      leadGenerationReport: 'Report sulla generazione di lead',
      supportOnWhatsApp: 'Supporto su WhatsApp',
      knowledgeBase: 'Base di conoscenza',
      onboardingAndTraining: 'Incorporazione e formazione',
      callAndEmailSupport: 'Supporto telefonico ed email',
      freeChatbotCreation: 'Creazione gratuita di chatbot',
      customerSuccessOfficer: 'Responsabile del successo del cliente',
      secureConnection2048BitSSL: 'Connessione sicura SSL a 2048 bit',
      securityAssessment: 'Valutazione della sicurezza',
      dataCenterInUSOrEU: '\'Centro dati negli USA o nell\'UE\'',
      twoStepVerification: 'Verifica a due passaggi',
      accessRestrictionsBasedOnIPs: 'Restrizioni di accesso basate su IP',
      onDemandAuditLog: 'Registro di audit su richiesta',
      bannedVisitorsList: 'Elenco dei visitatori bannati',
      customAPIRate: 'Tariffa API personalizzata',
      trustedDomains: 'Domini attendibili',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Curioso della codifica',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Il nostro piano gratuito',
      TOOLTIP_KING_PLAN: 'Il nostro piano più popolare',
      TOOLTIP_EMPEROR_PLAN: 'Piano personalizzato per messaggi illimitati, dati illimitati, membri del team illimitati',
      TOOLTIP_CONTACT_US: 'Raggiungi noi tramite whatsapp o e -mail a contact@botpenguin.com per saperne di più sul nostro piano personalizzato',
      TOOLTIP_UPGRADE: 'Aggiorna il tuo piano attuale per funzionalità aggiuntive',
      TOOLTIP_MONTHLY: 'Seleziona un ciclo di pagamento mensile',
      TOOLTIP_YEARLY: 'Seleziona un ciclo di pagamento annuale per i vantaggi di sconto',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Vai al piano per bambini',
    downgradeToFreePlan: 'Downgrade al piano gratuito',
    freeForever: 'Libero per sempre',
    features: 'Caratteristiche',
    currentPlan: 'Piano attuale',
    changePlan: 'Piano di cambiamento',
    currentlyActive: 'Attualmente attivo',
    seeHowToUse: 'Guarda come usare',
    weHaveGotPricingPerfectForYou: 'Abbiamo un piano di prezzi perfetto per te',
    weBelieve: 'Noi crediamo',
    shouldBeAccessibleToAllCompanies: ' dovrebbe essere accessibile a tutte le aziende, non importa le dimensioni.',
    monthly: 'Mensile',
    yearly: 'Annuale',
    twoMonthsFree: '(2 mesi gratuiti)',
    kingPlan: 'Piano re',
    perYear: 'per anno',
    perMonth: 'al mese',
    conversationsPerMonth: 'Conversazioni al mese',
    upgradePlan: 'Piano di aggiornamento',
    emperorPlan: 'Piano imperatore (Enterprise)',
    haveAsManyConversationsAsYouNeed: 'Avere tutte le conversazioni di cui hai bisogno',
    contactUs: 'Contattaci',
    metaConversationChargesAreAdditional: 'Le spese di meta conversazione sono aggiuntive e pagate direttamente da te a Meta, in base al tuo utilizzo. Non addebitiamo tasse di markup o facilitazione.',
    viewDetailedPricing: 'Visualizza prezzi dettagliati',
    conversationPricingModelUsedByMeta: 'Modello di prezzi delle conversazioni utilizzato dalla meta',
    whatsappBusinessPlatformConversationsFallInto: 'Le conversazioni della piattaforma aziendale di WhatsApp rientrano in due categorie che hanno un prezzo diverso',
    userInitiatedConversation: 'Conversazione iniziata dall`utente',
    userInitiatedConversationDescription: 'Una conversazione che avvia in risposta a un messaggio utente. Ogni volta che un`azienda risponde a un utente all`interno della finestra del servizio clienti di 24 ore, tale messaggio sarà associato a una conversazione iniziata dall`utente.Le aziende possono inviare messaggi a forma libera entro questa finestra del servizio clienti di 24 ore.',
    businessInitiatedConversation: 'Conversazione iniziata dal business',
    businessInitiatedConversationDescription: 'Una conversazione che avvia da un`azienda che invia a un utente un messaggio al di fuori della finestra del servizio clienti di 24 ore.I messaggi che avviano una conversazione avviata dal business richiederanno un modello di messaggio.',
    areYouSureYouWantTo: 'Sei sicuro di voler passare dal',
    conversations: ' Conversazioni',
    plan: ' piano',
    toThe: ' al',
    metaPricingMarkup: 'Markup di meta prezzi',
    numberOfChatFlows: 'Numero di flussi di chat',
    greenTickApplication: 'Applicazione di spunta verde',
    extraPhoneNumberLinking: 'Collegamento del numero di telefono extra',
    teamMembers: 'Membri del team',
    dataStorage: 'Archivio dati',
    conditionalFlow: 'Flusso condizionale',
    liveChat: 'Chat dal vivo',
    smartInbox: 'Posta in arrivo intelligente',
    whatsappTemplates: 'Modelli WhatsApp',
    customerSegments: 'Gruppi di clientela',
    broadcastAndDripCampaigns: 'Campagne di trasmissione e gocciolamento',
    multipleLanguages: 'Più lingue',
    bookAppointments: 'Appuntamenti del libro',
    csvContactsExport: 'CSV Contatti Esporta',
    googleCalendarIntegrations: 'Integrazione del calendario di Google',
    nativeApplications: 'Oltre 60 integrazione nativa',
    whatsappSupport: 'Supporto WhatsApp',
    emailAndCallSupport: 'Supporto e -mail e chiamate',
    customerIntegrations: 'Integrazioni dei clienti',
    nlpAndNluCapabilities: 'Funzionalità NLP e NLU',
    chatGPTBot: 'Chatgpt Chatbot',
    customerSuccessOfficer: 'Ufficiale di successo del cliente',
    noMarkupTransparency: 'Nessuna trasparenza di markup',
    upto500kPerMonth: 'Fino a 500.000 al mese',
    beyond500k: 'Oltre 500.000',
    unlimited: 'Illimitato',
    oneTeamMember: '1 membro del team',
    unlimitedTeamMember: 'Membri del team illimitati',
    nintyDaysOfDataStorage: '90 giorni di archiviazione dei dati',
    unlimitedDataStorage: 'Archiviazione dati illimitata',
    detailedPricing: {
      onDemandAuditLog: 'Registro di audit su richiesta',
      accessRestrictionsBasedOnIPs: 'Restrizioni di accesso basate sugli IP',
      averageAgentResponeTime: '\'Tempo medio di risposta dell\'agente\'',
      perMonth100: '100 al mese',
      solutionType: 'Tipo di soluzione',
      saas: 'SaaS',
      saasOnPremises: 'SaaS in loco',
      emperorPlan: 'Piano Imperatore',
      babyPlan: 'Piano Base',
      kingsplan: 'Piano dei Re',
      braodcastsAndDrips: 'Trasmissioni e Gocce',
      thirtyDays: '30 Giorni',
      ninetyDays: '90 Giorni',
      oneMember: '1 Membro',
      threeMembers: '3 membri',
      one: '1 (Uno)',
      unlimitedMessages: 'Messaggi illimitati',
      unlimitedBots: 'Bots illimitati',
      messagesSend: 'Messaggi Inviati',
      upto500000perMonth: 'Fino a 500,000 al mese',
      unlimited: 'Illimitato',
      onlyAdmin: 'Solo Amministratore',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Fallo da te',
      weAreHereToHelp: 'Siamo qui per aiutarti',
      weTakeCareEndToEnd: 'Ci prendiamo cura da capo a fondo',
      numberOfBots: 'Numero di bots',
      conversations: 'Conversazioni',
      gptMessages: 'Messaggi GPT',
      whatsappNumbers: 'Numeri di WhatsApp',
      subscribersLeads: 'Iscritti/Lead',
      createSupportTeamForLiveChat: 'Crea un team di supporto per la chat dal vivo',
      conversationHistory: 'Storico delle conversazioni',
      chatbotDesigning: 'Progettazione del chatbot',
      multipleLanguages: 'Lingue multiple',
      conditionalFlow: 'Flusso condizionale',
      whatsappTemplates: 'Modelli di WhatsApp',
      restartChatFlowTriggers: 'Attivatori per il riavvio del flusso di chat',
      multipleWhatsAppWidgets: 'Widget di WhatsApp multipli',
      importWhatsappContacts: 'Importa contatti WhatsApp',
      whatsappQuickReply: 'Risposta rapida di WhatsApp',
      whatsappSmartInbox: 'Casella di posta intelligente di WhatsApp',
      whatsappEcommerceCatalogs: 'Cataloghi di e-commerce di WhatsApp',
      whatsappBroadcast: 'Trasmissione di WhatsApp',
      whatsappBroadcastAnalytics: 'Analisi di trasmissione di WhatsApp',
      dripCampaigns: 'Campagne di gocciolamento',
      customerSegmentation: 'Segmentazione dei clienti',
      keywordBasedResponse: 'Risposta basata su parole chiave',
      whatsappShopManagement: 'Gestione del negozio WhatsApp',
      aiChatbot: 'Chatbot AI',
      chatGPTIntegration: 'Integrazione di ChatGPT',
      configureAIChatbotPersonality: 'Configura la personalità del chatbot AI',
      trainChatbotOnYourOwnData: 'Addestra il chatbot con i tuoi dati',
      trainChatbotOnWebPages: 'Addestra il chatbot sulle pagine web',
      trainChatbotOnMultipleFiles: 'Addestra il chatbot su file multipli',
      trainChatbotOnFAQs: 'Addestra il chatbot sulle FAQ',
      dataTrainingStorage: 'Archiviazione dei dati di addestramento',
      useChatGPTModel4or35Turbo: 'Usa il modello ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: '\'Carica file multipli per l\'addestramento del chatbot\'',
      naturalLanguageProcessing: 'Elaborazione del linguaggio naturale',
      naturalLanguageUnderstanding: 'Comprensione del linguaggio naturale',
      answerLength: 'Lunghezza della risposta',
      chatTone: 'Tono della chat',
      multiLanguage: 'Multilingue',
      answerFormatting: 'Formattazione della risposta',
      customPrompt: 'Promemoria personalizzato',
      whatsAppSessionManagement: 'Gestione delle sessioni di WhatsApp (messaggi di inattività e riavvio del flusso)',
      integrateBotPenguinLLM: 'Integra BotPenguin LLM',
      integrateOwnCustomLLM: 'Integra il proprio LLM personalizzato',
      periodicallySyncDataSources: 'Sincronizza periodicamente le fonti di dati',
      aiBasedSuggestedQuestions: 'Domande suggerite basate su AI',
      chooseVisibilityForParticularWebpageSitemap: 'Scegli la visibilità per una pagina web o mappa del sito specifica',
      chooseKnowledgeMix: 'Scegli la miscela di conoscenze: Base di conoscenze ChatGPT "Base di conoscenze ChatGPT + Tua base di conoscenze"',
      faqExtractionFromFileAndURL: 'Estrazione FAQ da file e URL',
      analyticsToImproveAI: '\'Analytics per migliorare l\'AI\'',
      exportQuestionFlow: 'Esporta il flusso delle domande',
      collectFeedbackOnChatbotResponses: 'Raccogli feedback sulle risposte del chatbot',
      smartAIFollowUpQuestions: 'Domande di follow-up intelligenti basate su AI',
      trainOnYourNotionWorkspaceData: 'Addestra sui dati del tuo spazio di lavoro in Notion',
      trainOnYoutubeVideo: 'Addestra su video di YouTube',
      trainOnCustomVideoUpload: 'Addestra su caricamento video personalizzato',
      rssFeed: 'Feed RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'Consegna umana',
      botHandover: 'Consegna del bot',
      handoverBetweenAgents: 'Consegna tra agenti',
      emailAndMobileValidation: '\'Convalida dell\'e-mail e del cellulare\'',
      mobileAppsForAgents: 'App per cellulare per agenti',
      appointmentBooking: 'Prenotazione appuntamenti',
      roleBasedAccessControls: 'Controlli di accesso basati sui ruoli',
      ruleBasedChatRouting: 'Instradamento della chat basato su regole',
      liveVisitorsDetails: 'Dettagli dei visitatori in tempo reale',
      multipleSimultaneousChats: 'Chat simultanee multiple',
      visitorStats: 'Statistiche dei visitatori',
      addContactsFromWhatsApp: 'Aggiungi contatti da WhatsApp',
      applyFilterAndSortConversations: 'Applica filtri e ordina le conversazioni',
      seeChatLogs: 'Visualizza i log delle chat',
      quickReplies: 'Risposte rapide',
      unifiedCustomerProfile: 'Profilo del cliente unificato',
      assignNotesAndChatTags: 'Assegna note e tag alle chat',
      exportLeadsInCSV: 'Esporta lead in formato CSV',
      tagContacts: 'Etichetta i contatti',
      customAttributes: 'Attributi personalizzati',
      markStatusOfConversations: 'Segna lo stato delle conversazioni',
      assignConversationToAgents: 'Assegna la conversazione agli agenti',
      leadNotificationsByEmail: 'Notifiche sui lead tramite e-mail',
      directCallsToLeadsWithTwilio: 'Chiamate dirette ai lead con Twilio',
      whatsAppTemplates: 'Modelli di WhatsApp',
      promotionalMessageFrequencyControl: 'Controllo della frequenza dei messaggi promozionali',
      whatsAppWidget: 'Widget di WhatsApp',
      whatsAppQR: 'QR di WhatsApp',
      whatsAppLink: 'Link di WhatsApp',
      campaignScheduler: 'Pianificatore di campagne',
      customTriggers: 'Trigger personalizzati',
      customCampaigns: 'Campagne personalizzate',
      repeatPurchaseReminders: 'Promemoria per gli acquisti ripetuti',
      campaignOpenRateTracking: 'Monitoraggio del tasso di apertura delle campagne',
      paymentCollectionOnWhatsApp: 'Raccolta dei pagamenti su WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Chiudi',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Agente dal vivo',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Rame',
      autopilot: 'Pilota automatico',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Calendario di Google',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistema di ticketing per il supporto',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Chiamata audio e video in chat (Twilio/Agora)',
      courierTracking: 'Tracciamento del corriere',
      emailIntegration: '\'Integrazione dell\'e-mail\'',
      webhooks: 'Webhooks',
      schedulers: 'Pianificatori',
      paymentGateways: 'Gateway di pagamento (Stripe e RazorPay)',
      enterpriseIntegrations: 'Integrazioni aziendali',
      customIntegration: 'Integrazione personalizzata',
      freeWhatsAppAPIApprovalAndSetup: '\'Approvazione e configurazione gratuita dell\'API di WhatsApp\'',
      developerAPIsAccess: 'Accesso alle API per sviluppatori',
      customRateLimit: 'Limite di velocità personalizzato',
      totalNumberOfLeads: 'Numero totale di lead',
      averageAgentResponseTime: '\'Tempo medio di risposta dell\'agente\'',
      visitorTimingReport: 'Report sul timing dei visitatori',
      customerSegmentBasedReports: 'Report basati su segmenti di clienti',
      tagBasedReports: 'Report basati su tag',
      leadGenerationReport: 'Report sulla generazione di lead',
      supportOnWhatsApp: 'Supporto su WhatsApp',
      knowledgeBase: 'Base di conoscenza',
      onboardingAndTraining: 'Onboarding e formazione',
      callAndEmailSupport: 'Supporto telefonico ed e-mail',
      freeChatbotCreation: 'Creazione gratuita del chatbot',
      customerSuccessOfficer: 'Responsabile del successo del cliente',
      secureConnection: 'Connessione sicura SSL a 2048 bit',
      securityAssessment: 'Valutazione della sicurezza',
      dataCenterLocation: 'Posizione del data center (US o UE)',
      twoStepVerification: 'Verifica a due passaggi',
      accessRestrictions: 'Restrizioni di accesso',
      auditLog: 'Registro di audit su richiesta',
      bannedVisitorsList: 'Elenco dei visitatori banditi',
      customAPIRate: '\'Tariffa personalizzata dell\'API\'',
      trustedDomains: 'Domini attendibili',
      elementary: 'Elementare',
      bots: 'Bots',
      unlockWhatsapp: 'Sblocca WhatsApp',
      aiChatbotFeatures: 'Funzionalità del chatbot AI',
      dataSourceForTraining: '\'Sorgente dati per l\'addestramento\'',
      smartFeatures: 'Funzionalità intelligenti',
      liveChat: 'Chat dal vivo',
      smartInbox: 'Posta in arrivo intelligente',
      broadcastsAndDrips: 'Trasmissioni e Gocce',
      integrations: 'Integrazioni',
      development: 'Sviluppo',
      reportsAndAnalytics: 'Report e analisi',
      customerSupport: 'Supporto al cliente',
      security: 'Sicurezza',
      whatsappWidgets: 'Widget di WhatsApp',
      campaignSchedular: 'Programmatore di campagne',
      repeatPurchaseRemainder: 'Promemoria per gli acquisti ripetuti',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Il nostro piano gratuito',
      TOOLTIP_KING_PLAN: 'Il nostro piano più popolare',
      TOOLTIP_EMPEROR_PLAN: 'Piano personalizzato per messaggi illimitati, dati illimitati, membri del team illimitati',
      TOOLTIP_CONTACT_US: 'Raggiungi noi tramite whatsapp o e -mail a contact@botpenguin.com per saperne di più sul nostro piano personalizzato',
      TOOLTIP_UPGRADE: 'Aggiorna il tuo piano attuale per funzionalità aggiuntive',
      TOOLTIP_MONTHLY: 'Seleziona un ciclo di pagamento mensile',
      TOOLTIP_YEARLY: 'Seleziona un ciclo di pagamento annuale per i vantaggi di sconto',
    }
  }
};
