import { createAction, props } from '@ngrx/store';

export const loadBots = createAction(
  '[Bot] Load Bots',
  props<{ bots: any[] }>()
);
export const addBot = createAction(
  '[Bot] Add Bot',
  props<{ bot: {} | any }>()
);
export const updateBot = createAction(
  '[Bot] Update Bot',
  props<{ bot: number | string, update: {} | any }>()
);
export const deleteBot = createAction(
  '[Bot] Delete Bot',
  props<{ bot: number | string }>()
);

export const deleteSocialBot = createAction(
  '[Bot] Delete Social Bot',
  props<{ bot: number | string }>()
);

export const moveBotToTop = createAction(
  '[Bot] Move Bot',
  props<{ botId: string }>()
);
