import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { NetworkService } from '../../services/network.service';
import { SharedService } from '../shared/shared.service';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
  environment = environment;
  unsubscribing = true;
  email = '';
  subscribe$: any;
  brandName = '';

  staticText: any;
  agencyMeta: {
    _id: string;
    name: string;
    logo: string;
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private networkService: NetworkService,
    private store: Store<any>,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private languageService: LanguageService,
  ) {
    this.staticText = languageService.getStaticText().authentication.unsubscribe;
    this.checkAgency();
    activatedRoute.queryParamMap.subscribe(params => {
      const code = params.get('code');
      if (!code) {
        return this.router.navigate(['/login']);
      } else {
        this.sharedService.loader('start');
        this.networkService.httpRequest('unsubscribe?code=' + code)
          .then((email: string) => {
            this.unsubscribing = false;
            this.email = email;
          })
          .catch((error) => {
            this.sharedService.showToast('error', '', error?.message || 'We are experiencing technical issues. Please try again later.');
            return this.router.navigate(['/login']);
          })
          .finally(() => this.sharedService.loader('stop'));
      }
    });

  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    try {
      this.subscribe$?.unsubscribe();
    } catch (e) { }
  }

  checkAgency() {

    if (environment.rootAgencyDomain.includes(window.location.host)) {

      this.agencyMeta = {
        _id: environment.rootAgencyId,
        name: 'BotPenguin',
        logo: '/assets/images/logo-v2.svg'
      };
      return true;
    } else {
      this.sharedService.loader('start');
      this.authService.getAgencyInfo(window.location.host)
        .then(data => {

          this.agencyMeta = {
            _id: data._id,
            name: data.name,
            logo: data.meta.logo
          };
        })
        .catch(error => console.log(error))
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  getLanguage() {
    const lang = JSON.parse(localStorage.getItem('language'));
    if (lang && (lang !== 'undefined' || lang !== 'null')) {
      return lang.localValue || lang.title;
    }
  }

  handleSignUpBtn() {
    return this.router.navigate(['/authentication']);
  }

}

