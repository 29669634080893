export const analytics = {
  botWise: {
    botWiseAnalytics: 'Bot-Wise Analytics',
    currentBillingCycle: 'Current Billing Cycle',
    currentMonth: 'Current Month',
    sinceAccountCreation: 'Since Account Creation',
    messages: 'Messages',
    leads: 'Leads',
    noDataFound: {
      botwiseAnalytics: 'Bot-Wise Analytics',
      noBotHasBeenCreatedYet: 'No bot has been created yet!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Summarize data from messages and contacts/leads for specific bots for the current billing cycle, current month, or since account creation (Get updated every midnight)',
      TOOLTIP_FILTERS: 'Filter data for selected bot',
      TOOLTIP_MESSAGES: 'Total messages consumed by your account since account creation',
      TOOLTIP_LEADS: 'Total leads captured by bots for your account since account creation.',
    }

  },
  overview: {
    analytics: 'Analytics',
    seeHowToUse: 'See how to use',
    help: 'Help',
    overview: 'Overview',
    messages: 'Messages',
    leads: 'Leads',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Analytics for all your bots messages and leads. (Data updated every midnight)',
    }
  },
  summary: {
    analyticsSummary: 'Analytics Summary',
    allBots: 'All Bots',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    messages: 'Messages',
    leads: 'Leads',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Summarize daily, weekly, or monthly data from messages and contacts/leads for specific and all bots (Get updated every midnight)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp Analytics',
      month: 'Month',
      week: 'Week',
      today: 'Today',
      filters: 'Filters',
      clearAll: 'Clear all',
      apply: 'Apply',
      totalConversations: 'Total Conversations',
      freeConversations: 'Free Conversations',
      paidConversations: 'Paid Conversations',
      messageSent: 'Message Sent',
      messageDelivered: 'Message Delivered',
      bpDropDown: {
        selectBot: 'Select Bot',
      },
      indexMergeDatePicker: {
        startDate: 'Start Date',
        endDate: 'End date',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Conversation Analytics',
      month: 'Month',
      week: 'Week',
      today: 'Today',
      totalConversations: 'Total Conversations',
      paidConversations: 'Paid Conversations',
      freeConversations: 'Free Conversations',
      indexMergeDatePicker: {
        startDate: 'Start Date',
        endDate: 'End date',
      },
      bpDropDown: {
        allBots: 'All bots'
      }
    },
    messageAnalytics: {
      whatsappMessages: 'WhatsApp Messages',
      sent: 'Sent',
      delivered: 'Delivered',
      indexMergeDatePicker: {
        startDate: 'Start Date',
        endDate: 'End date',
      },
      bpDropDown: {
        allBots: 'All bots'
      },
      month: 'Month',
      week: 'Week',
      today: 'Today',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'WhatsApp Messages By Status',
      sent: 'Sent',
      delivered: 'Delivered',
      indexMergeDatePicker: {
        startDate: 'Start Date',
        endDate: 'End date',
      },
      bpDropDown: {
        allBots: 'All bots'
      },
      month: 'Month',
      week: 'Week',
      today: 'Today',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'All Subscribers',
      whatsappSubscribers: 'WhatsApp Subscribers'
    }
  }
};
