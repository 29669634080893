const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Hola,',
  greetingHand: '👋',
  upgrade: 'Mejora',
  integrate: 'Integrar',
  integrate2: 'Integrar',
  chatGPT: 'Chatgpt',
  forYourBusiness: 'para tu negocio',
  insights: 'Perspectivas',
  message: 'Mensajes',
  addon: 'Añadir:',
  leads: 'Dirige',
  yourBots: 'Tus bots',
  botName: 'Nombre de bot',
  platform: 'Plataforma',
  contactOrLeads: 'Contactos/cables',
  actions: 'Comportamiento',
  edit: 'editar',
  delete: 'borrar',
  createA: 'Crear un',
  whatsappChatbot: 'Chatbot de whatsapp',
  connect: 'Conectar',
  getStartedWithUs: 'Empiece con nosotros 👏',
  howToBuildAGoodChatFlow: ' Cómo construir un buen flujo de chat',
  howToUseBotPenguinInboxAndItsFeatures: ' Cómo usar la bandeja de entrada de Botpenguin y sus características',
  botPenguinPricing: ' Precios de botpenguin: explorar planes y características',
  openHelpDesk: ' Abrir la mesa de ayuda',
  helpAndSupport: 'Ayuda y apoyo',
  extensiveGuides: 'Guías extensas para todas sus consultas y flujos de trabajo están disponibles en nuestro',
  resourceCentre: 'Centro de Recursos.',
  checkoutOurAnswers: 'Puede consultar nuestras respuestas a las consultas comunes a continuación.',
  createANewBot: 'Crea un nuevo bot',
  installYourBot: 'Instale su bot',
  plansAndPricing: 'Planes y precios',
  searchLanguage: 'Lenguaje de búsqueda',
  whatsappConversations: 'Conversaciones de WhatsApp',
  diveDeeperIntoBotPenguin: 'Sumergirse más en botpenguin',
  knowWhereWeAreGoing: 'Sepa a dónde vamos, las actualizaciones recientes y nos ayudamos a solucionar algunos errores.',
  productUpdates: 'Actualizaciones de Producto',
  requestAFeature: 'Solicitar una función',
  reportABug: 'Reportar un error',
  productRoadmap: 'Hoja de ruta de productos',
  contactsOrLeads: 'Contactos/cables',
  english: 'Inglés',
  hindi: 'hindi',
  german: 'Alemán',
  french: 'Francés',
  portuguese: 'Portugués',
  spanish: 'Español',
  italian: 'italiano',
  russian: 'ruso',
  pleaseSelectYourReason: 'Seleccione su razón',
  cancel: 'Cancelar',
  submit: '¡Entregar!',
  giveFeedback: 'Dar opinion',
  ratings: 'Calificaciones',
  reasonBehindYourDecision: 'Razón detrás de su decisión',
  shareYourExperience: 'Comparte tu experiencia',
  reason1: 'Razón1',
  reason2: 'Razón2',
  appTooltip: {
    HOME_INSIGHT: 'Proporcione información sobre todos los mensajes y clientes potenciales de su bot (datos actualizados cada medianoche)',
    HOME_MESSAGES: 'Mensajes consumidos por su cuenta durante este ciclo de facturación. (Se excluyen los mensajes de WhatsApp, Facebook y Telegram Bot).',
    HOME_TOTAL_MESSAGES: 'Se excluyen los mensajes totales consumidos por su cuenta desde la creación de la cuenta (se excluyen los mensajes de WhatsApp, Facebook y Telegram Bot).',
    LEADS: 'Leades capturados por bots para su cuenta durante este ciclo de facturación.',
    TOTAL_LEADS: 'Contactos/clientes potenciales totales capturados por bots para su cuenta desde la creación de la cuenta (datos actualizados cada medianoche).',
    YOUR_BOT: 'Descripción general de todos sus bots y su uso durante este ciclo de facturación.',
    NAME: 'Nombre de bot',
    PLATFORM: 'Plataforma de bot',
    MESSAGES: 'Mensajes totales de BOT desde la creación de cuenta',
    CONTACTS: 'Contactos/cables totales de BOT',
    ACTIONS: 'Editar/eliminar el bot',
    EDIT_ICON: 'Editar el flujo de chat de bot y la configuración',
    DELETE_ICON: 'Eliminar el bot',
    TOOL_TIP_CLASSIC_VERSION: 'Versión anterior de $ {brand} ui',
  },
  modal: {
    deleteBot: 'Eliminar bot!',
    areYouSure: '¿Estás seguro de que quieres eliminar este bot?',
    enquiryForm: 'Formulario de Consulta',
    payNow: '¡Pagar ahora!',
    saveAndDelete: 'Guardar y eliminar',
    submit: 'Entregar',
  },
  noDataFound: {
    bots: 'Bots',
    noBotCreatedYet: '¡No hay bots creados todavía!',
  },
  yourFriendWillMissYou: 'Tu amigo te extrañará',
  rateYourBotCreationExperience: 'Califica tu experiencia de creación de bot',
  whatMadeYouDeleteYourBot: '¿Qué te hizo borrar tu bot?',
  iCreatedByMistake: 'Creé por error',
  iWantToCreateAnotherBot: 'Quiero crear otro bot',
  couldNotFindFeaturesThatINeeded: ',',
  uiWasHardToNavigate: 'UI fue difícil de navegar',
  iDonotWantToTell: 'No quiero hablar',
  other: 'Otro',
  weWillDoOurBestToMakeAutomationFun: 'Haremos todo lo posible para que la automatización sea divertida y simple para todos.',
};
