import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  IMAGE_UPDATE_URL: any = 'upload';
  constructor(private networkService: NetworkService) { }

  updateImage(update) {
    return new Promise((resolve, reject) => {
      this.networkService.httpFormRequest(this.IMAGE_UPDATE_URL, update)
        .then(resolve)
        .catch(reject);
    });
  }
  invalidateBotCache(botId, isOld) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`bots/invalidate-cache/${botId}?isOld=${isOld} `, {}, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }
  getBot() {
    return this.networkService.httpRequest(`whatsapp-automation`);
  }

  updateBot(payload, id) {
    return this.networkService.httpRequest(`whatsapp-automation/bot/${id}`, payload, 'PUT');
  }
  getPage(id) {
    return this.networkService.httpRequest(`facebook-automation/bots/bot/${id}`);
  }

  // deleteTool(id) {
  //   return this.networkService.httpRequest(`${this.currentBot.type}-automation/tools/tool/${id}`, {}, 'DELETE' );
  // }

  public updateLeadSquared(event: string, payload: { value: any }) {

    if (!environment.rootAgencyDomain.includes(window.location.host)) {
      return Promise.resolve();
    }

    return this.networkService.httpRequest('customer/leadsquared-lead-update', { event, payload }, 'POST');
  }
}

