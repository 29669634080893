export const inboxMerged = {
  inboxHeader: 'דואר נכנס',
  chatsHeader: 'שיחות',
  contactsHeader: 'אנשי קשר',
  segmentsHeader: 'קְבוּצָה',
  ordersHeader: 'הזמנות',
  exportStatus: 'סטטוס ייצוא',
  tasksExport: 'יצוא משימות',
  download: 'הורד',
  ourAllNewOmniChannelInbox: 'דואר נכנס אומני חדש לגמרי!',
  allYourMessagesContactsAndLeads: 'כל ההודעות, אנשי הקשר והלידים שלך כעת במקום אחד. מוכנים לסינון ולקיטון, לצורך גידול העסק שלך!',
  close: 'סגור',
  takeALook: 'תסתכל',
  inProgress: 'בתהליך',
  appTooltip: {
    manageAllYourLeadsHere: 'ניהול כל הלידים/אנשי הקשר שלך כאן',
    segmentYourUserLeads: 'קיטון הלידים/משתמשי הבוט על פי מאפיינים דמוגרפיים ותחומי עניין. אלו ישמשו ביצירת שידורים',
    manageAllBotsConversationsHere: 'ניהול כל השיחות של הבוטים כאן',
    manageAllYourOrdersHere: 'ניהול כל ההזמנות שלך כאן',
  },
  contacts: {
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    visitorName: 'שם מבקר',
    email: 'דוא"ל',
    phoneNumber: 'מספר טלפון',
    segment: 'קְבוּצָה',
    status: 'סטטוס',
    tag: 'תג',
    action: 'פעולה',
    discard: 'מחיקה',
    addTag: 'הוסף תג',
    loadMore: 'טען עוד',
    nA: 'לא זמין',
    assign: 'שייך',
    deleteUser: 'מחק משתמש',
    areYouSureToDeleteLead: 'האם אתה בטוח שברצונך למחוק את הליד הזה?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'אין לך עדיין אנשי קשר!',
      allOfYourContactsAppearHere: 'כל אנשי הקשר שלך יופיעו כאן'
    },
    placeholders: {
      searchSegmentByName: 'חפש לפי שם הקְבוּצָה',
      searchOrAddTag: 'חפש או הוסף תג',
    },
    addContacts: {
      name: 'שם',
      email: 'דוא"ל',
      enterAValidEmail: 'הזן כתובת דוא"ל חוקית',
      phoneNumber: 'מספר טלפון',
      cancel: 'ביטול',
      addContact: 'הוסף איש קשר',
      placeholders: {
        enterEmailAddress: 'הזן כתובת דוא"ל',
        enterUserrName: 'הזן שם משתמש',
        searchCountry: 'חפש מדינה',
        enterPhoneNumber: 'הזן מספר טלפון',
      }
    }
  },
  inboxFilter: {
    applied: 'מוחזק',
    assignTags: 'הקצה תגים',
    discard: 'בטל',
    addTag: 'הוסף תג',
    assignSegments: 'הקצה קְבוּצָהים',
    filterYourLeads: 'סנן את הלידים שלך',
    filters: 'מסננים',
    filter: 'סנן',
    resetAllFilters: 'אפס את כל המסננים',
    importContacts: 'ייבוא אנשי קשר',
    beforeYouStart: 'לפני שתתחיל בהעלאה, וודא ש',
    point1: '1. הורד את קובץ האקסל לדוגמה.',
    point2: '2. התחל להזין את הנתונים שלך מהשורה השנייה והלאה.',
    point3: '3. בכל שורה יהיו נתונים של איש קשר אחד',
    point4: '4. וודא שכל מספרי הטלפון הם ייחודיים.',
    point5: '5. מגבלת הגודל להעלאת קובץ האקסל היא 20 מגה-בייט',
    selectFile: 'בחר קובץ',
    clickHere: 'לחץ כאן',
    toDownloadSampleFile: 'להורדת קובץ לדוגמה',
    modal: {
      closeLeads: 'סגור לידים',
      areYouSureToselectAllLeads: 'האם אתה בטוח שברצונך לסגור את כל הלידים שנבחרו?',
      assignAgent: 'הקצה סוכן',
      toAssignAgentToAllLeads: 'האם אתה בטוח שברצונך להקצות סוכן לכל הלידים שנבחרו?',
      assignTag: 'הקצה תג',
      wantToReplacePreviouslyAssignedTagWithNew: 'האם אתה בטוח שברצונך להחליף את התגים המוקצים מראש בתג החדש שנבחר לכל הלידים שנבחרו?',
      deleteLeads: 'מחק לידים',
      wantToDeleteSelectedLeads: 'האם אתה בטוח שברצונך למחוק את כל הלידים שנבחרו?',
      exportData: 'ייצוא נתונים',
      exportDataBasedOn: 'ייצוא נתונים בהתבסס על',
      assignSegment: 'הקצה קְבוּצָה',
      wantToAssignSegmentsToSelectedLeads: 'האם אתה בטוח שברצונך להקצות קְבוּצָה לכל הלידים שנבחרו?',
      yesGoAhead: 'כן, המשך!',
      export: 'ייצוא'
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'סנן נתונים בהתבסס על העדפותיך, כמו תגים, בוט, סטטוס ועוד.',
      markLeadAsClosed: 'סמן משתמש/ליד כסגור',
      assignAgentToLead: 'הקצה סוכן למשתמש',
      assignTagToUser: 'הקצה תג למשתמש',
      exportData: 'ייצוא נתונים',
      importContactsForWhatsappBot: 'ייבוא אנשי קשר לבוט WhatsApp',
      addWhatsappContact: 'הוסף אנשי קשר ל-WhatsApp',
      assignSegmentToUser: 'הקצה קְבוּצָה למשתמש',
      deleteUsers: 'מחק משתמשים',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'הזן שם, דוא"ל או מספר טלפון ולחץ Enter',
      searchAgentByName: 'חפש לפי שם סוכן',
      searchOrAddTag: 'חפש או הוסף תג',
      searchSegmentByName: 'חפש לפי שם הקְבוּצָה',
    },
    filterContainer: {
      filterOn: 'סנן לפי',
      filterBy: 'סנן לפי',
      startFrom: 'התחל מ',
      tillDate: 'עד תאריך',
      offlineOrOnline: 'לא מחובר/מחובר',
      liveChat: 'שיחה חיה',
      userInteractedWithBot: 'המשתמש ניגע בבוט',
      discard: 'בטל',
      applyFilter: 'החל מסנן',
      bots: 'בוטים',
      tags: 'תגים',
      status: 'סטטוס',
      assignedTo: 'הוקצה ל',
      lastMessageBy: 'נשלח על ידי',
      onlineOrOffline: 'מחובר/לא מחובר',
      userInteracted: 'המשתמש ניגע',
      segments: 'קְבוּצָהים',
      lastSeenDate: 'תאריך ראות אחרון',
      onboardingDate: 'תאריך התחלה',
      createTags: 'צור תגים',
      createTagsToSortAndManageLeads: 'צור תגים כדי לסנן ולנהל את הלידים שלך בקלות',
      typeYourTagHere: 'הזן את התג שלך כאן',
      createSegment: 'צור קְבוּצָה',
      manageSegmentForMarketingAutomation: 'ניהול הקְבוּצָהים שלך לשיווק אוטומטי',
      typeYourSegmentHere: 'הזן את הקְבוּצָה שלך כאן',
      placeholders: {
        searchFilter: 'חיפוש במסנן',
        searchByBotName: 'חפש לפי שם בוט',
        searchOrAddTag: 'חפש או הוסף תג',
        searchByAgentName: 'חפש לפי שם סוכן',
        searchBySegments: 'חפש לפי קְבוּצָהים',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'טווח תאריכים שאתה רוצה לסיים בו את הבחירה שלך',
        dateRangeYouWishToStartSelectionFrom: 'טווח תאריכים שאתה רוצה להתחיל ממנו את הבחירה שלך',
        filterThoseThatAreOfflineOrOnline: 'סנן משתמשים שלא מחוברים/מחוברים',
        filterThoseThatAreCurrentlyLiveChatting: 'סנן משתמשים שכרגע נמצאים בשיחה חיה',
        filterThoseThatInteractedWithBot: 'סנן משתמשים שניגעו בבוט'
      },
      indexMergeDatePicker: {
        selectStartDate: 'בחר תאריך התחלה',
        selectEndDate: 'בחר תאריך סיום',
      }
    }
  },
  leads: {
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    visitorName: 'שם מבקר',
    email: 'דוא"ל',
    phoneNumber: 'מספר טלפון',
    assignedTo: 'משוייך ל',
    status: 'סטטוס',
    tag: 'תג',
    action: 'פעולה',
    discard: 'בטל',
    addTag: 'הוסף תג',
    loadMore: 'טען עוד',
    nA: 'לא זמין',
    deleteUsers: 'מחק משתמש',
    areYouSureToDeleteLead: 'האם אתה בטוח שברצונך למחוק ליד זו?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'אין לך צ\'אטים כרגע',
      allOfYourChatsAppearHere: 'כל הצ\'אטים שלך יופיעו כאן',
    },
    placeholders: {
      searchAgentByName: 'חיפוש לפי שם סוכן',
      searchStatusByName: 'חיפוש לפי שם סטטוס',
      searchOrAddTag: 'חיפוש או הוספת תג',
    }
  },
  messages: {
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    chat: {
      photo: 'תמונה',
      video: 'וידאו',
      document: 'מסמך',
      location: 'מיקום',
      assignedTo: 'משוייך ל',
      selectBot: 'בחר בוט',
      discard: 'בטל',
      addTag: 'הוסף תג',
      yourBrowserDoesNotSupportVideoTag: 'הדפדפן שלך אינו תומך בתג וידאו.',
      userHasSharedADocument: 'המשתמש שיתף מסמך.',
      clickHereToDownload: 'לחץ כאן להורדה',
      thisStoryIsNoLongerAvailable: 'הסיפור הזה אינו זמין יותר',
      yourBrowserDoesNotSupportAudioElement: 'הדפדפן שלך אינו תומך באלמנט קול.',
      thisMessageIsNoLongerAvailable: 'ההודעה הזו אינה זמינה יותר מאחר והיא נמחקה על ידי השולח',
      userHasSharedHisLocation: 'המשתמש שיתף את המיקום שלו. לחץ כאן לצפייה',
      sms: 'SMS',
      failed: 'נכשל',
      a: 'A',
      i: 'אני',
      r: 'R',
      m: 'M',
      conversationsSelected: 'שיחות שנבחרו',
      nA: 'לא זמין',
      assignTagsCloseOrBulkReply: 'הקצה תגים, סגור או תשובה אוטומטית לכולם בפעם אחת',
      appTooltip: {
        assignAgent: 'הקצה סוכן',
        selectBot: 'בחר בוט',
        assignTagsToManageLeads: 'הקצה תגים לניהול לידים ושיחות',
      },
      placeholders: {
        searchAgentByName: 'חיפוש לפי שם סוכן',
        searchBotByName: 'חיפוש לפי שם בוט',
        searchOrAddTag: 'חיפוש או הוספת תג',
        searchStatusByName: 'חיפוש לפי שם סטטוס',
      }
    },
    profile: {
      userDetails: 'פרטי המשתמש',
      orderDetails: 'פרטי ההזמנה',
      orderedOn: 'הוזמן בתאריך',
      showMore: 'הצג עוד',
      showLess: 'הצג פחות',
      subTotal: 'סכום חלקי',
      nA: 'לא זמין',
      addAWhatsappNumber: 'הוסף מספר WhatsApp',
      addTelegramUserName: 'הוסף שם משתמש בטלגרם',
      addInstagramProfileId: 'הוסף מזהה פרופיל Instagram',
      addFacebookProfileId: 'הוסף מזהה פרופיל Facebook',
      add: 'הוסף',
      deviceProperties: 'מאפייני מכשיר',
      privateNotes: 'הערות פרטיות',
      urlsVisited: 'כתובות URL שנצפו',
      source: 'מקור - ',
      sourceNa: 'מקור - לא זמין',
      medium: 'אמצעי - ',
      mediumNa: 'אמצעי - לא זמין',
      campaign: 'קמפיין - ',
      campaignNa: 'קמפיין - לא זמין',
      attributeKey: 'מפתח מאפיין',
      attributeName: 'שם מאפיין',
      discard: 'בטל',
      addAttribute: 'הוסף מאפיין',
      editAttribute: 'ערוך מאפיין',
      deleteAttribute: 'מחק מאפיין',
      headsUp: 'הזהרה',
      areYouSureToDeleteAttribute: 'האם אתה בטוח שברצונך למחוק מאפיין זה?',
      okay: 'אישור',
      dataAddedToLead: 'המאפיינים שממופים ל-API שלך או לזרימות הבוט לא יתעדכנו. בבקשה עדכן את ה-API או את זרימות הבוט בהתאם.',
      assignedOnly: 'רק למשוייכים',
      allUsers: 'כל המשתמשים',
      save: 'שמור',
      manageCustomAttributes: 'ניהול מאפיינים',
      placeholders: {
        title: 'כותרת',
        egServices: 'לדוגמה, שירותים',
        egRequestedServices: 'לדוגמה, שירותים שנדרשו',
        enterYourNotesHere: 'הזן הערות פה...',
      },
      bpDropDown: {
        selectBot: 'בחר בוט',
        selectFlow: 'בחר זרימה',
        selectQuestion: 'בחר שאלה*',
      }
    },
    sendMessage: {
      send: 'שלח',
      sendToAllChannels: 'שלח לכל הערוצים',
      email: 'דוא"ל',
      whatsapp: 'וואטסאפ',
      telegram: 'טלגרם',
      facebook: 'פייסבוק',
      sendSms: 'שלח SMS',
      addNew: 'הוסף חדש',
      addNewQuickReply: 'הוסף תגובה מהירה חדשה',
      discard: 'בטל',
      fromComputer: 'מהמחשב',
      add: 'הוסף',
      dragAndDropHereOr: 'גרור ושחרר כאן או',
      browseFiles: 'עיין בקבצים',
      caption: 'כיתוב',
      templates: 'תבניות',
      addHeaderMedia: 'הוסף מדיה עליונה',
      selectFile: 'בחר קובץ',
      previousConversationClosed: 'השיחה הקודמת נסגרה!',
      sendTemplateMessage: 'שלח הודעת תבנית לפתיחת השיחה מחדש.',
      selectTemplate: 'בחר תבנית',
      conversationLocked: 'השיחה ננעלה!',
      demoBotOnlyAllowConversations: 'בוט הדגמה מתיר הודעות עם המספר שאימתנת בהקדם.',
      placeholders: {
        searchQuickReplyByTitle: 'חפש תגובות מהירות לפי כותרת',
        addTitle: 'הוסף כותרת',
        addYourRepliesHere: 'הוסף את התגובות שלך כאן... ',
        addCaption: 'הוסף כיתוב',
        enterValueForThisVariable: 'הזן ערך עבור משתנה זה',
      },
      modal: {
        sendBulkMessages: 'שלח הודעות גורמות',
        send: 'שלח',
        description: 'ההודעה שלך תינקה ותישלח בהתאם לסוג הפלטפורמה שמשתמש בה המשתמש.' +
          '\n - משתמשים באתר יוכלו לקבל הודעות בסגנון טקסט \n - אנשי קשר ב-WhatsApp שלא נרשמו יכולים לקבל רק הודעות מתבניות'
      },
      noDataFound: {
        oops: 'אופס!',
        currentlyDoesNotSupportBulkMessages: 'אנו כרגע לא תומכים בשליחת הודעות גורמות לבוטים של WhatsApp. יש להשתמש בתכלית השידורים.',
        quickReplies: 'תגובות מהירות',
        noQuickRepliesAddedYet: 'טרם הוספת תגובות מהירות!',
      }
    },
    userList: {
      selectAll: 'בחר הכל',
      nA: 'לא זמין',
      loadMore: 'טען עוד',
    }
  },
  segments: {
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    manageSegment: 'נהל את הקטע',
    manageYourSegmentsForMarketingAutomation: 'נהל את הקטעים שלך לצורך אוטומציה שיווקית',
    createNewSegment: 'צור קטע חדש',
    segmentName: 'שם הקטע',
    totalContacts: 'סך הכל אנשי קשר',
    action: 'פעולה',
    createSegmentButton: 'צור קטע',
    deleteSegments: 'מחק קטעים',
    areYouSureToDeleteSegment: 'האם אתה בטוח שברצונך למחוק את הקטע?',
    placeholders: {
      searchHere: 'חפש כאן',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'כרגע אין לך שום קטעים שנוצרו',
      allYourSegmentsAppearHere: 'כל הקטעים שלך יופיעו כאן'
    },
    modal: {
      exportData: 'ייצא נתונים',
      exportDataBasedOnAppliedFilters: 'ייצא נתונים בהתבסס על הסינונים שהוחלו',
      export: 'ייצא',
    },
    appTooltip: {
      deleteSegments: 'מחק קטעים',
      editSegment: 'ערוך קטע',
      downloadSegment: 'הורד את הקטע',
      deleteSegment: 'מחק קטע'
    },
    createSegment: {
      createSegment: 'צור קטע',
      manageYourSegmentsForMarketingAutomation: 'נהל את הקטעים שלך לצורך אוטומציה שיווקית',
      segmentName: 'שם הקטע',
      segmentNameIsRequired: 'שם הקטע הוא שדה חובה',
      selectContactsFilterForSegment: 'בחר סנני אנשי קשר עבור הקטע',
      filterYourContacts: 'סנן את אנשי הקשר שלך',
      removeAllFilters: 'הסר את כל הסננים',
      addFilters: 'הוסף סננים',
      discard: 'בטל',
      filterSelected: 'סננים שנבחרו',
      placeholders: {
        enterSegmentName: 'הזן שם לקטע',
      }
    }
  },
  orders: {
    visitorName: 'שם המבקר',
    email: 'דוא"ל',
    phoneNumber: 'מספר טלפון',
    status: 'סטטוס',
    itemCount: 'מספר פריטים',
    total: 'סה"כ',
    tag: 'תג',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'כרגע אין לך הזמנות',
      allOfYourOrdersAppearHere: 'כל ההזמנות שלך יופיעו כאן'
    },
  },
};
