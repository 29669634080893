import { createAction, props } from '@ngrx/store';

export const loadRender = createAction(
    '[Render] Load Render'
);

export const addRender = createAction(
    '[Render] Add Render',
    props<{ render: {} | any }>()
);

export const updateRender = createAction(
    '[Render] Update Render',
    props<{ render: {} | any }>()
);
