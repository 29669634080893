import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { InboxService } from 'src/appv2/modules/inbox/inbox.service';
import { removeLiveChatRequests } from 'src/appv2/ngrx/actions/live-chat.action';
import { LayoutService } from 'src/appv2/services/layout.service';
import { NetworkService } from 'src/appv2/services/network.service';
import { WhatsAppService } from 'src/appv2/services/whatsApp.service';
import { InboxService as InboxService2 } from 'src/appv2/services/inbox.service';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-new-chat-request',
  templateUrl: './new-chat-request.component.html',
  styleUrls: ['./new-chat-request.component.scss']
})
export class NewChatRequestComponent implements OnInit, OnChanges {
  @Input() requestData: any = {};
  acceptRequestData: any = {
    uuid: '',
    _bot: '',
    _customer: '',
    _user: '',
    _id: ''
  };
  staticText: any;
  messages: any[] = [];
  showchatRequestDiv = true;
  profile: any;
  private subscription$: any;

  constructor(
    private store: Store<any>,
    private inboxService: InboxService,
    private inboxService2: InboxService2,
    private sharedService: SharedService,
    private router: Router,
    private languageService: LanguageService,
    private layoutService: LayoutService,
  ) {
    this.staticText = languageService.getStaticText().notification.newChatRequest;
    this.subscription$ = this.store.select(state => state)
      .subscribe(state => {
        this.messages = state.messages || [];
        this.profile = state.profile;
      });
  }

  ngOnInit(): void {
    this.acceptRequestData = this.requestData;

  }

  ngOnChanges(): void {
    this.acceptRequestData = this.requestData;
  }

  acceptRequest() {
    this.sharedService.loader('start');
    this.inboxService.filter$.next({ bot: '', platform: '', tag: '' });
    this.subscription$ = this.store.dispatch(removeLiveChatRequests({ requestId: this.acceptRequestData.requestId }));
    this.layoutService.acceptLiveChatRequest(this.acceptRequestData)
      .then(() => {

        this.inboxService.saveMessage({
          uuid: this.acceptRequestData.uuid,
          _bot: this.acceptRequestData._bot,
          payload: {
            text: `Live chat request has been accepted by the Agent`,
            messagedBy: 'system',
          },
        });

        return this.inboxService2.getInboxUser(this.acceptRequestData.uuid);
      })
      .then((inboxUser: any) => {
        this.inboxService2.preSelectedInboxUser.next(inboxUser);
        setTimeout(() => {
          this.inboxService2.liveChatRequestAccepted.next(true);
        }, 100);
        this.router.navigateByUrl('/inbox-merged/sessions');
      })
      .finally(() => this.sharedService.loader('stop'))
      .catch((error) => {
        console.log(error);
      });

  }

  discardRequest() {
    this.subscription$ = this.store.dispatch(removeLiveChatRequests({ requestId: this.requestData.requestId }));
    if (this.requestData.agentId) {
      this.layoutService.rejectTransferredRequest(this.requestData.requestId);
    } else {
      this.layoutService.rejectLiveChatRequest(this.requestData.requestId);
    }
  }

  ignoreRequest() {
    this.subscription$ = this.store.dispatch(removeLiveChatRequests({ requestId: this.requestData.requestId }));
  }

}
