export const notification = {
  notification: 'Notificări',
  allNotifications: 'Toate notificările',
  general: 'General',
  settings: 'Setări',
  newChatRequest: {
    youHaveANewChatRequest: 'Ai un nou cerere de chat!!!',
    youHaveTransferredChatRequest: 'Ai primit o cerere de chat transferat!!!',
    acceptRequest: 'Acceptă Cererea',
    discardRequest: 'Respinge Cererea'
  },
  notificationList: {
    name: 'Nume',
    message: 'Mesaj',
    noDataFound: {
      notification: 'Notificare',
      noNotificationsFound: 'Nicio notificare găsită!',
    }
  },
  notificationSetting: {
    userMessage: 'Mesaj Utilizator',
    receiveNotificationsWhenUserSendMessage: 'Primește notificări atunci când un utilizator trimite un mesaj',
    liveChatRequest: 'Cerere Chat Live',
    receiveNotificationsWhenUserRequestLiveChat: 'Primește notificări atunci când un utilizator solicită un chat live',
    newLead: 'Lead Nou',
    receiveNotificationsWhenNewLeadCreated: 'Primește notificări atunci când se creează un lead nou',
    assignedOnly: 'Doar Atribuit',
    allUsers: 'Toți Utilizatorii',
    customizeWhichNotificationsYouWantToRecieve: 'Personalizează ce notificări dorești să primești',
    youCanCustomizeSettingForTheNotifications: 'Poți personaliza setările pentru notificări',
    receiveNotifications: 'Primește Notificări',
    desktopNotifications: 'Notificări Desktop',
    mobilePushNotifications: 'Notificări Push Mobile',
    discard: 'Renunță',
    update: 'Actualizează',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Trimite notificări de mesaj nou pe desktop',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Trimite notificări de mesaj nou pe mobil',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Trimite notificări de lead nou pe desktop',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Trimite notificări de lead nou pe mobil',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Trimite notificări de chat live pe desktop',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Trimite notificări de chat live pe mobil',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Alege pentru ce mesaje dorești să primești notificări'
    }
  }
};
