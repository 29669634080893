export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'تم إرسال رابط التفعيل، يرجى التحقق من بريدك الإلكتروني',
    weHaveSentActivationLinkToEmailAddress: 'لقد قمنا بإرسال رابط التفعيل إلى عنوان البريد الإلكتروني',
    toVerifyYourAccount: 'للتحقق من حسابك.',
    onceVerifiedAutomaticallyRedirected: 'بمجرد التحقق، سيتم توجيهك تلقائيًا إلى اللوحة.',
    didNotReceiveAnEmail: 'لم تستلم البريد الإلكتروني؟ تحقق من مجلد البريد العشوائي',
    resendIn: 'إعادة الإرسال في ',
    seconds: ' ثواني',
    resend: 'إعادة الإرسال',
    backToSignUp: 'العودة إلى التسجيل',
    greatPleaseCheckYourMessage: 'رائع، يرجى التحقق من بريدك الإلكتروني',
    weHaveSentEmailTo: 'لقد قمنا بإرسال بريد إلكتروني إلى',
    withALinkToReset: ' مع رابط لإعادة تعيين حسابك. بمجرد التنشيط، ستكون قادرًا على استكمال إنشاء حسابك وبدء الفترة التجريبية المجانية الخاصة بك.',
    resent: 'إعادة الإرسال',
    backToLogin: 'العودة إلى تسجيل الدخول',
  },
  forgotPassword: {
    forgotPassword: 'هل نسيت كلمة المرور؟',
    noWorries: 'لا تقلق،',
    weWillSendYouResetPasswordInstructions: 'سنرسل لك تعليمات إعادة تعيين كلمة المرور',
    workEmail: 'البريد الإلكتروني للعمل',
    continue: 'استمر',
    backToLogin: 'العودة إلى تسجيل الدخول',
    placeholders: {
      enterYourEmail: 'أدخل بريدك الإلكتروني',
    }
  },
  logout: {
    logout: 'تسجيل الخروج',
    wantToLogOut: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
    yesIWillBeBack: 'نعم، سأعود',
    cancel: 'إلغاء',
  },
  newPassword: {
    createNewPassword: 'إنشاء كلمة مرور جديدة',
    yourNewPasswordMustBeDifferent: 'يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور المستخدمة سابقًا.',
    password: 'كلمة المرور',
    confirmPassword: 'تأكيد كلمة المرور',
    setPassword: 'تعيين كلمة المرور',
    signUp: 'التسجيل',
    login: 'تسجيل الدخول',
    backTo: 'العودة إلى ',
    setAPassword: 'تعيين كلمة مرور',
    setNewPasswordToYourAccount: 'قم بتعيين كلمة مرور جديدة لحسابك لأغراض الأمان والخصوصية',
    backToSignUp: 'العودة إلى التسجيل',
    placeholders: {
      enterYourPassword: 'أدخل كلمة المرور الخاصة بك',
    }
  },
  resetPassword: {
    passwordReset: 'إعادة تعيين كلمة المرور',
    yourPasswordHasBeenSuccessfullyReset: 'تم إعادة تعيين كلمة المرور الخاصة بك بنجاح.',
    clickBelowToLogIn: 'انقر أدناه لتسجيل الدخول',
    continueToLogin: 'المتابعة لتسجيل الدخول',
  },
  newSignUp: {
    welcomeBack: 'مرحبًا مرة أخرى ',
    to: 'إلى',
    logIn: 'تسجيل الدخول ',
    as: 'كـ ',
    withFacebook: 'مع فيسبوك',
    logInWithGoogle: 'تسجيل الدخول باستخدام جوجل',
    or: 'أو',
    pleaseFillAValidWorkEmail: 'يرجى ملء بريد إلكتروني للعمل صالح*',
    workEmailIsRequired: 'البريد الإلكتروني للعمل مطلوب*',
    pleaseFillAValidPassword: 'يرجى ملء كلمة مرور صالحة*',
    passwordIsRequired: 'كلمة المرور مطلوبة*',
    rememberMe: 'تذكرني',
    forgotPassword: 'هل نسيت كلمة المرور؟',
    login: 'تسجيل الدخول',
    doNotHaveAnAccount: 'ليس لديك حساب؟',
    setUpAFreeAccount: 'إنشاء حساب مجاني',
    noCreditCardRequired: 'لا يلزم بطاقة ائتمان',
    cancelAnytime: 'إلغاء في أي وقت',
    signUp: 'التسجيل',
    // as: 'كـ ',
    // withFacebook: 'مع فيسبوك',
    signUpWithGoogle: 'سجل مع جوجل',
    // or: 'أو',
    emailIsAlreadyRegistered: 'البريد الإلكتروني مسجل بالفعل لدينا، يرجى النقر على تسجيل الدخول للمتابعة',
    phoneNumberShouldHaveMinimum7Digits: 'يجب أن يحتوي رقم الهاتف على الأقل على 7 أرقام*',
    phoneNumberIsRequired: 'رقم الهاتف مطلوب*',
    passwordMustContain: 'يجب أن تحتوي كلمة المرور على',
    atleast6Characters: 'على الأقل 6 أحرف، ',
    oneUpperCaseLetter: 'حرف كبير واحد ',
    oneSpecialCharacter: 'حرف خاص واحد*',
    alreadyHaveAccount: 'لديك حساب بالفعل؟',
    signingUpToBotPenguinIsAConsentToOur: 'الاشتراك في حساب BotPenguin هو موافقتك على',
    privacyPolicy: 'سياسة الخصوصية',
    termsAndConditions: 'الأحكام والشروط',
    and: ' و ',
    english: 'الإنجليزية',
    hindi: 'الهندية',
    german: 'الألمانية',
    french: 'الفرنسية',
    portuguese: 'البرتغالية',
    spanish: 'الإسبانية',
    italian: 'الإيطالية',
    russian: 'الروسية',
    placeholders: {
      workEmail: 'البريد الإلكتروني للعمل*',
      password: 'كلمة المرور*',
      phone: 'رقم الهاتف*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'تم إلغاء الاشتراك بنجاح',
    yourEmail: 'بريدك الالكتروني ',
    haveBeenSuccessfullyUnsubscribedFrom: ' تم إلغاء الاشتراك بنجاح من',
    backToLogin: 'العودة إلى تسجيل الدخول'
  }
};
