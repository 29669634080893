export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'Ein Aktivierungslink wurde gesendet. Überprüfen Sie bitte Ihre E -Mail',
    weHaveSentActivationLinkToEmailAddress: 'Wir haben einen Aktivierungslink an E -Mail -Adresse gesendet',
    toVerifyYourAccount: 'um Ihr Konto zu überprüfen.',
    onceVerifiedAutomaticallyRedirected: 'Nach der Überprüfung werden Sie automatisch zum Panel weitergeleitet.',
    didNotReceiveAnEmail: 'Haben Sie keine E -Mail erhalten? Kontrolliere deinen Spam ordner',
    resendIn: 'Wieder in',
    seconds: ' Sekunden',
    resend: 'Erneut senden',
    backToSignUp: 'Zurück, um sich anzumelden',
    greatPleaseCheckYourMessage: 'Großartig, bitte überprüfen Sie Ihre E -Mails',
    weHaveSentEmailTo: 'Wir haben eine E -Mail an gesendet an',
    withALinkToReset: ' mit einem Link zum Zurücksetzen Ihres Kontos. Sobald Sie aktiviert sind, können Sie Ihre Kontoerstellung abschließen und Ihre kostenlose Testversion beginnen.',
    resent: 'Übelnehmen',
    backToLogin: 'Zurück zur Anmeldung',
  },
  forgotPassword: {
    forgotPassword: 'Passwort vergessen?',
    noWorries: 'Kein Problem,',
    weWillSendYouResetPasswordInstructions: 'Wir senden Ihnen die Anweisung des Zurücksetzens für den Zurücksetzen',
    workEmail: 'Arbeits Email',
    continue: 'Weitermachen',
    backToLogin: 'Zurück zur Anmeldung',
    placeholders: {
      enterYourEmail: 'Geben sie ihre E-Mail Adresse ein',
    }
  },
  logout: {
    logout: 'Ausloggen',
    wantToLogOut: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
    yesIWillBeBack: 'Ja, ich werde zurück sein',
    cancel: 'Stornieren',
  },
  newPassword: {
    createNewPassword: 'Neues Passwort erstellen',
    yourNewPasswordMustBeDifferent: 'Ihr neues Passwort muss sich von zuvor verwendeten Passwörtern unterscheiden.',
    password: 'Passwort',
    confirmPassword: 'Bestätige das Passwort',
    setPassword: 'Passwort festlegen',
    signUp: 'Anmeldung',
    login: 'Anmeldung',
    backTo: 'Zurück zu',
    setAPassword: 'Setzen Sie ein Passwort',
    setNewPasswordToYourAccount: 'Legen Sie ein neues Passwort für Ihr Konto für Sicherheits- und Datenschutzzwecke fest',
    backToSignUp: 'Zurück, um sich anzumelden',
    placeholders: {
      enterYourPassword: 'Geben Sie Ihr Passwort ein',
    }
  },
  resetPassword: {
    passwordReset: 'Passwort zurücksetzen',
    yourPasswordHasBeenSuccessfullyReset: 'Ihr Passwort wurde erfolgreich zurückgesetzt.',
    clickBelowToLogIn: 'Klicken Sie unten, um sich anzumelden, um sich anzumelden',
    continueToLogin: 'Sich weiter anmelden',
  },
  newSignUp: {
    welcomeBack: 'Willkommen zurück',
    to: 'Zu',
    logIn: 'Anmeldung',
    as: 'als',
    withFacebook: 'mit Facebook',
    logInWithGoogle: 'Melden Sie sich mit Google an',
    or: 'ODER',
    pleaseFillAValidWorkEmail: 'Bitte füllen Sie eine gültige Arbeits -E -Mail*',
    workEmailIsRequired: 'Arbeite -E -Mail ist erforderlich*',
    pleaseFillAValidPassword: 'Bitte füllen Sie ein gültiges Passwort* aus*',
    passwordIsRequired: 'Passwort wird benötigt*',
    rememberMe: 'Mich erinnern',
    forgotPassword: 'Passwort vergessen?',
    login: 'Anmeldung',
    doNotHaveAnAccount: 'Haben Sie kein Konto?',
    setUpAFreeAccount: 'Richten Sie ein kostenloses Konto ein',
    noCreditCardRequired: 'Keine Kreditkarte benötigt',
    cancelAnytime: 'Jederzeit abbrechen',
    signUp: 'Anmeldung',
    signUpWithGoogle: 'Melden Sie sich bei Google an',
    emailIsAlreadyRegistered: 'E -Mail ist bereits bei uns registriert. Bitte klicken Sie auf Anmeldung, um fortzufahren, um fortzufahren',
    phoneNumberShouldHaveMinimum7Digits: 'Die Telefonnummer sollte mindestens 7 Ziffern haben*',
    phoneNumberIsRequired: 'Telefonnummer ist erforderlich*',
    passwordMustContain: 'Passwort muss enthalten',
    atleast6Characters: 'mindestens 6 Zeichen,',
    oneUpperCaseLetter: '1 Großbuchstabe',
    oneSpecialCharacter: '1 besonderer Charakter*',
    alreadyHaveAccount: 'Sie haben bereits ein Konto?',
    signingUpToBotPenguinIsAConsentToOur: 'Die Anmeldung für ein Botpenguin -Konto ist Ihre Zustimmung zu unserer Zustimmung',
    privacyPolicy: 'Datenschutz-Bestimmungen',
    termsAndConditions: 'Geschäftsbedingungen',
    and: ' Und',
    english: 'Englisch',
    hindi: 'Hindi',
    german: 'Deutsch',
    french: 'Französisch',
    portuguese: 'Portugiese',
    spanish: 'Spanisch',
    italian: 'Italienisch',
    russian: 'Russisch',
    placeholders: {
      workEmail: 'Arbeits Email*',
      password: 'Passwort*',
      phone: 'Telefon*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Erfolgreich abgemeldet',
    yourEmail: 'Ihre E-Mail ',
    haveBeenSuccessfullyUnsubscribedFrom: ' wurde erfolgreich abgemeldet von',
    backToLogin: 'Zurück zur Anmeldung'
  }
};
