import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appToolTip]'
})
export class TooltipDirective implements OnDestroy {

  @Input() appToolTip = ''; // The text for the tooltip to display
  // @Input() delay = 0; // Optional delay input, in ms

  private myPopup;
  private timer;

  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) { this.myPopup.remove(); }
  }

  @HostListener('mouseenter') onMouseEnter() {
      // Get the middle of the element
      const x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2;
      // Get the bottom of the element, plus a little extra
      const y = this.el.nativeElement.getBoundingClientRect().top + this.el.nativeElement.offsetHeight + 10;
      this.createTooltipPopup(x, y);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.myPopup) {
      this.myPopup.remove();
    }
  }

  private createTooltipPopup(x: number, y: number) {
    const popup = document.createElement('div');
    popup.innerHTML = this.appToolTip;
    popup.setAttribute('class', 'tooltip-container');
    popup.style.top = y.toString() + 'px';
    popup.style.left = x.toString() + 'px';
    popup.setAttribute('z-index', '9999999');
    document.body.appendChild(popup);
    this.myPopup = popup;
    // setTimeout(() => {
    //   if (this.myPopup) {
    //     this.myPopup.remove();
    //   }
    // }, 25000); // Remove tooltip after 15 seconds
  }

}
