import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { InboxService } from 'src/appv2/services/inbox.service';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-bp-export',
  templateUrl: './bp-export.component.html',
  styleUrls: ['./bp-export.component.scss']
})
export class BpExportComponent implements OnInit, OnDestroy {

  staticText: any;
  exportAvailable: any;
  csvExport: Export;
  cvsPopup$: Subscription;
  constructor(
    private inboxService: InboxService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.bpExport;
    this.cvsPopup$ = this.inboxService.isExportStatusPopup.subscribe(isExport => {
      if (isExport) {
        this.getAvailableExports();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clearExportInterval();
    this.cvsPopup$.unsubscribe();
  }

  getAvailableExports(): void {
    if (!this.exportAvailable) {
      this.debounceExports();
    }

    this.clearExportInterval();
    this.exportAvailable = setInterval(() => {
      this.debounceExports();
    }, 2000);
  }

  debounceExports(): void {
    this.inboxService.getActiveExports()
      .then((exportReq: Export) => {
        if (!exportReq || exportReq.status !== 'INITIATED') {
          this.clearExportInterval();
        }

        this.csvExport = exportReq;
      })
      .catch(err => {
        console.log(err);
        this.clearExportInterval();
      });
  }

  clearExportInterval() {
    if (this.exportAvailable) {
      clearInterval(this.exportAvailable);
    }
  }

  downloadCSV() {
    if (this.csvExport && this.csvExport.exportSource) {
      window.open(this.csvExport.exportSource, '_blank');

      this.inboxService.removeActiveExports()
        .then(() => this.csvExport = undefined)
        .catch(err => console.log(err));
    }
  }

  removeExports() {
    this.inboxService.removeActiveExports()
      .then(() => this.csvExport = undefined)
      .catch(err => console.log(err));
  }

}

interface Export {
  _user: string;
  _agency: string;
  error: string;
  description: string;
  exportSource: string;
  status: 'INITIATED' | 'AVAILABLE' | 'EXPIRED' | 'FAILED';
  createdAt: string;
  updatedAt: string;
}
