import {createAction, props} from '@ngrx/store';

export const loadSocialTemplateBroadcast = createAction(
    '[Templates] Load Template Broadcast ',
    props<{ broadcasts: any[] }>()
);

export const addSocialTemplateBroadcast =  createAction(
    '[Templates] Add Template Broadcast',
    props<{ broadcast: any }>()
);

export const removeSocialTemplateBroadcast =  createAction(
    '[Templates] Delete Template Broadcast',
    props<{ broadcast: any }>()
);
