export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'बॉटपेंग्विन में आपका स्वागत है!',
      login: 'लॉग इन करें',
      youAreAllSet: 'आप सब तैयार हैं! आप लीड विवरण पृष्ठ से व्हाट्सएप इनबॉक्स का उपयोग कर सकते हैं।',
      placeholders: {
        enterEmail: 'ईमेल दर्ज करें',
        enterPassword: 'पास वर्ड दर्ज करें'
      }
    }
  }
};
