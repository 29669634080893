const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Edite seu fluxo de bate -papo',
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    classicBuilder: 'Construtor clássico',
    visualiseFlow: 'Visualize o fluxo',
    testLiveOnTelegramWeb: 'Teste ao vivo na Web do Telegram',
    testFlow: 'Fluxo de teste',
    importTemplate: 'Modelo de importação',
    landingPageBotReview: 'Visualização de bot da página de destino',
    install: 'Instalar',
    testLiveOnMessengerWeb: 'Teste ao vivo na web do messenger',
    testLiveOnWhatsappWeb: 'Teste ao vivo na web do WhatsApp',
    testLiveOnInstagramWeb: 'Teste ao vivo na web do Instagram',
    seeAllChatFlow: 'Veja todos os fluxos de bate -papo',
    launchDemoModal: 'Lançar a demonstração modal',
    selectATemplateToKickStartYourBot: 'Selecione um modelo para começar seu bot',
    hireChatBotDesigner: 'Contrate Designer de Chatbot',
    recommendedForYou: 'Recomendado para você',
    useTemplate: 'Use modelo',
    exportStatus: 'Status de exportação',
    flowExport: 'Exportação de fluxo',
    download: 'Download',
    importFlow: 'Fluxo de importação',
    beforeYouStartUploadPleaseMakeSure: 'Antes de iniciar o upload, certifique -se de',
    ponit1: '1. O limite máximo de upload do tamanho do arquivo é de 1 MB',
    point2: '2. O formato do arquivo deve estar em JSON',
    selectFile: 'Selecione o arquivo',
    preview: 'Visualização',
    test: 'Teste',
    OopsIntegrationAreNotAvailable: 'Ops! As integrações não estão disponíveis em ',
    pleaseUpgradeTo: '  Por favor, atualize para o ',
    planForIntegration: ' para as integrações.',
    placeholders: {
      searchTemplateYouNeed: 'Pesquise o modelo que você precisa',
    },
    noDataFound: {
      botFlow: 'Fluxo de bot',
      noBotFlowAddedYet: 'Nenhum fluxo de bot ainda é adicionado!',
      templates: 'Modelos',
      noTemplatesFound: 'Nenhum modelo encontrado!',
    },
    appTooltip: {
      exportFlow: 'Fluxo de exportação',
      importFlow: 'Fluxo de importação',
      clickToViewChatFlow: 'Clique para ver o fluxo de bate -papo no construtor de arrastar e soltar',
      clickToPreviewChatFlow: 'Clique para visualizar o fluxo de bate -papo',
      clickToViewChatFlowAsFlowDiagram: 'Clique para ver o fluxo de bate -papo como um diagrama de fluxo',
      clickToTestChatFlow: 'Clique para testar o fluxo de bate -papo',
      clickToSelectAndImportTemplate: 'Clique para selecionar e importar o modelo que melhor se encaixa no seu negócio.',
      clickToInstallYourBotToSelectedPlatform: 'Clique para instalar seu bot na plataforma selecionada',
      clickToTestDefaultFlowOfTelegramBot: 'Clique para testar o fluxo padrão do seu bots (ativo) na web do telegrama',
      clickToTestDefaultFlowOfFacebookBot: 'Clique para testar o seu fluxo de brots (ativo) na web do Facebook',
      clickToTestDefaultFlowOfWhatsappBot: 'Clique para testar o fluxo padrão do seu bots (ativo) na web do WhatsApp',
      clickToTestDefaultFlowOfInstagramBot: 'Clique para testar o fluxo padrão do seu bots (ativo) na web do Instagram',
    }
  },
  createNewBot: {
    createNewBot: 'Crie novo bot',
    stepOneOfTwo: 'Etapa 1 de 2',
    selectThePlatform: 'Selecione a plataforma',
    selectTheBotType: 'Selecione o tipo de bot',
    aiBasedChatbot: 'chatbot baseado em IA',
    ruleBasedChatBot: 'Chatbot baseado em regras',
    webPlatforms: 'Plataformas da Web',
    websiteChatBot: 'Site chatbot',
    withLiveChat: '(Com chat ao vivo)',
    landingPageBot: 'Página de destino Bot',
    liveChatWidget: 'Widget de bate -papo ao vivo',
    onlyLiveChat: '(Apenas bate -papo ao vivo)',
    socialPlatforms: 'Plataformas sociais',
    whatsappChatBot: 'Whatsapp chatbot',
    telegramChatBot: 'Telegram Chatbot',
    facebookChatBot: 'Facebook Chatbot',
    instagramChatBot: 'Instagram chatbot',
    selectBotType: 'Selecione o tipo de bot',
    selectProblem: 'Selecione o problema que deseja resolver',
    leadGeneration: 'Geração de leads',
    customerSupport: 'Suporte ao cliente',
    appointmentBooking: 'Agendamento de compromissos',
    marketingAutomation: 'Automatização de marketing',
    ecommerceWebsite: 'Site de comércio eletrônico',
    otherUseCase: 'Outros',
    back: 'Voltar',
    typeDomainUrl: 'Digite a URL do seu site para verificação.',
    dataSourceForChatBotTraining: `Fonte de dados para treinamento do chatbot`,
    upload: 'Carregar',
    uploadDocuments: 'Enviar documentos',
    uploadDocument: 'Carregar documento',
    selectFile: 'Selecionar arquivo',
    continue: 'Continuar',
    websiteUrl: 'Digite a URL do seu site',
    skipNow: 'Pular agora',
    completeTheExistingBotSetup: 'Complete a configuração do bot existente',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Você já criou um bot do WhatsApp. Por favor, complete a configuração para criar um novo',
    completeSetup: 'Instellingen voltooid',
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    fbBot: {
      createNewBot: 'Crie novo bot',
      help: 'Ajuda',
      back: 'Voltar',
      step: 'Etapa',
      of3: ' de 3',
      loginWithFacebook: 'Entrar com o Facebook',
      asPerLatestChangesInFacebookPolicy: 'De acordo com as últimas alterações nas políticas do Facebook, o proprietário do Facebook Bot deve ter um função "gerente" ou acima para acessar todo o recurso da página do Facebook.',
      login: 'Conecte-se',
      connectFacebookBot: 'Conecte o Facebook Bot',
      connectBotWithYourFacebookPagesAnd: 'Conecte o bot com suas páginas do Facebook e',
      exploreTruePowerOfFacebook: 'Explore o verdadeiro poder do Facebook',
      connect: 'Conectar',
      connected: 'Conectado',
      createNewPage: 'Crie uma nova página',
      refreshPageList: 'Lista de páginas de atualização',
      thisPageIsSuccessfullyIntegratedWith: 'Esta página é integrada com sucesso com',
      yourMessagesFromMessengerWillAppearIn: '. Suas mensagens do Messenger aparecerão em',
      inbox: ' Caixa de entrada',
      continueToBotConfiguration: 'Continue para a configuração de botão',
      welcomeToBrandNameWeAreHereToHelpYou: `Bem -vindo ao ${brand}. Estamos aqui para ajudá-lo.`,
      letsTryYourBotAndSeeHowItWorks: 'Vamos experimentar o seu bot e ver como funciona! Clique em "Teste no Facebook".',
      howAreYouDoingToday: 'Como você está hoje?',
      thisIsHowSimpleToUseFacebookBot: 'É isso! É assim que é simples usar o BOT do Facebook',
      letsCreateYourOwnChatFlowNow: 'Vamos criar seu próprio fluxo de bate -papo agora',
    },
    instagramBot: {
      createNewBot: 'Crie novo bot',
      back: 'Voltar',
      step: 'Etapa',
      of3: ' de 3',
      loginWithInstagram: 'Faça login com o Instagram',
      loginToYourInstagramAccount: 'Faça login na sua conta do Instagram para ativar o chatbot no seu identificador.',
      managerLevelDescription: 'Os proprietários de bots do Instagram devem ter pelo menos um nível de "gerente" de permissões ou superior para acessar todos os recursos.',
      login: 'Conecte-se',
      connectInstagramBot: 'Conecte o Instagram Bot',
      connectBotWithYourInstagramAnd: 'Conecte o bot com seu Instagram e',
      exploreTruePowerOfInstagram: ' Explore o verdadeiro poder do Instagram',
      connected: 'Conectado',
      connect: 'Conectar',
      createNewPage: 'Crie uma nova página',
      refreshPageList: 'Lista de páginas de atualização',
      thisPageIsSuccessfullyintegratedWith: 'Esta página é integrada com sucesso com',
      yourMessagesFromMessengerWillAppearIn: '. Suas mensagens do Messenger aparecerão em',
      inbox: ' Caixa de entrada',
      continueToBotConfiguration: 'Continue para a configuração de botão',
      welcomeToBrandNameWeAreHereToHelpYou: `Bem -vindo ao ${brand}. Estamos aqui para ajudá-lo.`,
      letsTryYourBotAndSeeHowItWorks: 'Vamos experimentar o seu bot e ver como funciona! Clique em "Teste no Instagram".',
      howAreYouDoingToday: 'Como você está hoje?',
      thisIsHowSimpleToUseInstagramBot: 'É isso! É assim que é simples usar o Instagram Bot',
      letsCreateYourOwnChatFlowNow: 'Vamos criar seu próprio fluxo de bate -papo agora',
    },
    telegramBot: {
      back: 'Voltar',
      step: 'Etapa',
      of3: ' de 3',
      seeHowToUse: 'Veja como usar',
      help: 'Ajuda',
      loginToYourTelegramAccount: 'Faça login na sua conta do Telegram',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Faça login na sua conta para criar um novo bot ou conectar o bot existente',
      verificationCodeSentOnRegisteredNumber: 'Código de verificação enviado no número registrado',
      createYourBot: 'Crie seu bot',
      enterYourBotsNameAnd: 'Digite o nome do seu bot, conecte -se e deixe a diversão começar com',
      botUserNameMustEndWithBotAtTheEnd: 'O nome de usuário do bot deve terminar com o "bot" no final.',
      terrisBot: 'Por exemplo. Teris_bot ou Terisbot',
      botNameAlreadyTaken: 'Este nome de bot já foi tomado. Por favor, tente algo diferente',
      connect: 'Conectar',
      createBot: 'Criar bot',
      codeNotReceivedResend: 'Código não recebido? Reenviar!',
      changeNumber: 'Altere o número',
      telegramPreview: 'Visualização do telegrama',
      worldsFastestMessagingApp: 'Aplicativo de mensagens mais rápido do mundo e é grátis!',
      bot: 'Robô',
      phoneNumber: 'Número de telefone',
      userName: 'Nome de usuário',
      none: 'Nenhum',
      bio: 'Bio',
      fewWordsAboutYourself: 'Poucas palavras sobre você',
      settings: 'Configurações',
      notificationsAndSound: 'Notificações e sons',
      privacyAndSecurity: 'Privacidade e segurança',
      dataAndStorage: 'Dados e armazenamento',
      chatSettings: 'Configurações de bate -papo',
      chatFolders: 'Pastas de bate -papo',
      devices: 'Dispositivos',
      connectYourBot: 'Conecte seu bot',
      createNewBotOrConnectWithExistingOne: 'Crie novo bot ou conecte o existente',
      createNewBot: 'Crie novo bot',
      welcomeToBrandNameWeAreHereToHelpYou: `Bem -vindo ao ${brand}. Estamos aqui para ajudá-lo.`,
      letsTryYourBotAndSeeHowItWorks: 'Vamos experimentar o seu bot e ver como funciona! Clique em "Teste no telegrama".',
      howAreYouDoingToday: 'Como você está hoje?',
      thisIsHowSimpleToUseTelegramBot: 'É isso! É assim que é simples usar o Telegram Bot',
      letsCreateYourOwnChatFlowNow: 'Vamos criar seu próprio fluxo de bate -papo agora',
      placeholders: {
        searchCountry: 'País de pesquisa',
        enterTelegramMobileNumber: 'Digite o número de celular do Telegram',
        enterLoginCodeReceived: 'Digite o código de login recebido na conta do Telegram *',
        twoFactorAuthentication: 'Senha 2FA (opcional)',
        botName: 'Nome do bot *',
        botUserName: 'Nome de usuário do bot *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Crie novo bot',
      seeHowToUse: 'Veja como usar',
      help: 'Ajuda',
      back: 'Voltar',
      step: 'Etapa',
      of2: ' de 2',
      buildA: 'Construir um',
      thatMatchesYourBrandPersonality: ' que corresponde à personalidade da sua marca',
      chatBotIcon: 'Ícone de chatbot',
      optional: 'Opcional',
      themeColor: 'Cor do tema',
      chatBotName: 'Nome do chatbot',
      botNameIsRequired: 'O nome do bot é necessário',
      botNameShouldBeLessThan50Characters: 'O nome do bot deve ser inferior a 50 caracteres',
      continue: 'Continuar',
      welcomeToBrandNameWeAreHereToHelpYou: `Bem -vindo ao ${brand}. Estamos aqui para ajudá-lo.`,
      whatIsYourEmailAddress: 'Qual é o seu endereço de e-mail?',
      invalidEmailAddress: 'Endereço de email invalido',
      customizeYourLandingPageBotLook: 'Personalize seu look de bots de destino',
      customizeYourWidgetLook: 'Personalize sua aparência de widget',
      customizeYourBotLook: 'Personalize sua aparência de bot',
      buildYourLandingPageBotAndAutomateInteraction: 'Crie seu bot da página de destino e automatize a interação com seus clientes compartilhando seu link',
      useLiveChatBotToConnectToCustomers: 'Use Bot de bate -papo ao vivo para se conectar aos seus clientes em tempo real',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Conecte o chatbot ao seu site e automatize as interações com os visitantes',
      widget: 'ferramenta',
      botInterface: 'interface bot',
      mergedBot: 'Chatbot do site, Chatbot da página de destino, Chatbot do aplicativo móvel',
      mergedBotToolTip: 'Crie uma página do bot para capturar a atenção dos visitantes e envolvê-los em conversas.',
      placeholders: {
        typeANameForYourBot: 'Digite um nome para o seu novo bot',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Digite seu nome de bot',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Selecione uma cor do tema para sua janela de bate -papo',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Este logotipo será usado apenas na plataforma ${brand ? brand : ''}. Sugerimos escolher o logotipo da sua empresa. Adicione uma imagem quadrada PNG ou JPG sob 1 MB.`,
      }
    },
    whatsappBot: {
      back: 'Voltar',
      step: 'Etapa',
      justAStepAway: 'A apenas um passo',
      seeHowToUse: 'Veja como usar',
      help: 'Ajuda',
      createWhatsappChatBot: 'Crie o WhatsApp Chatbot',
      businessNameIsRequired: 'O nome comercial é necessário.',
      maximumLengthForBusinessName40Characters: 'O comprimento máximo para o nome comercial é de 40 caracteres.',
      businessCategory: 'categoria de negócio',
      categoryIsRequired: 'Categoria é necessária.',
      descriptionIsRequired: 'Descrição é necessária.',
      maximumLengthForDescription200Characters: 'O comprimento máximo para descrição é de 200 caracteres.',
      pleaseFillAValidEmailAddress: 'Por favor, preencha um e -mail comercial válido.',
      businessEmailIsRequired: 'O email comercial é necessário.',
      phoneNumberShouldHaveMinimum7Digits: 'O número de telefone deve ter no mínimo 7 dígitos',
      phoneNumberIsRequired: 'O número de telefone é necessário',
      websiteIsRequired: 'O site é necessário.',
      maximumLengthForWebsite40Characters: 'O comprimento máximo para o site é de 40 caracteres.',
      maximumLengthForAddress150Characters: ' O comprimento máximo para o endereço é de 150 caracteres.',
      doYouHaveWhatsappApiKey: 'Você tem a chave da API do WhatsApp?',
      apiProvider: 'Provedor de API',
      three60Dialog: '360 diálogo',
      whatsappCloud: 'Whatsapp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'A chave da API é necessária.',
      whatsappBusinessAccountIdIsRequired: 'O ID da conta do WhatsApp Businness é necessário.',
      whatsappPhoneNumberIdIsRequired: ' O ID do número de telefone do WhatsApp é necessário.',
      systemAccessTokenIsRequired: 'O token de acesso ao sistema é necessário.',
      sourceIdIsRequired: 'O ID de origem é necessário.',
      // apiKeyIsRequired: 'A chave da API é necessária.',
      // createWhatsappChatBot: 'Crie o WhatsApp Chatbot',
      businessName: 'Nome da empresa',
      businessAccount: 'Conta de negócios',
      // businessCategory: 'categoria de negócio',
      businessDescription: 'descrição do negócio',
      address: 'Endereço',
      businessEmail: 'Email Comercial',
      websiteUrl: 'URL do site',
      thankYouForYourInterestIn: 'Obrigado pelo seu interesse em',
      whatsappChatBot: ' Whatsapp chatbot.',
      weHaveReceivedYourInterests: 'Recebemos seu interesse e nossa equipe de vendas entrará em contato com você nas próximas 24 horas.',
      theyWillProvideYouWhatsappApiKey: 'Eles fornecerão a chave da API do WhatsApp.',
      readyToCreateWhatsappBot: 'Estou pronto para criar o BOT WhatsApp',
      exploreWhatsappFeatures: 'Explore os recursos do WhatsApp',
      welcomeToBrandNameWeAreHereToHelpYou: `Bem -vindo ao ${brand}. Estamos aqui para ajudá-lo.`,
      letsTryYourBotAndSeeHowItWorks: 'Vamos experimentar o seu bot e ver como funciona! Clique em "Teste no WhatsApp".',
      howAreYouDoingToday: 'Como você está hoje?',
      thisIsHowSimpleToUseWhatsappBot: 'É isso! É assim que é simples usar o Bot WhatsApp',
      letsCreateYourOwnChatFlowNow: 'Vamos criar seu próprio fluxo de bate -papo agora',
      connectWhatsAppBot: 'Conectar Bot do WhatsApp',
      createtWhatsAppBot: 'Criar Bot do WhatsApp',
      proceed: 'Continuar',
      whatsApiProvider: 'Fornecedor de API do WhatsApp',
      cloudApi: 'API na Nuvem',
      netcoreApi: 'API Netcore',
      placeholders: {
        yourBusinessName: 'Seu nome comercial',
        businessDescription: 'descrição do negócio',
        businessEmail: 'Email Comercial',
        phone: 'Telefone*',
        website: 'Site (por exemplo, www.example.com)',
        address: 'Endereço',
        enterApiKey: 'Digite a tecla API',
        enterWhatsappBusinessAccountId: 'Digite ID da conta do WhatsApp Businness',
        enterWhatsappPhoneNumberId: 'Digite ID do número de telefone do WhatsApp',
        enterSystemAccessToken: 'Digite o token de acesso ao sistema',
        enterSourceId: 'Digite ID de origem',
        businessName: 'Nome da Empresa',
        businessWebsite: 'Website da Empresa',
        selectWhatsappApiProvider: 'Escolha o seu Provedor de API do WhatsApp',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'Escolha o seu Provedor de API do WhatsApp',
        doYouHaveWhatsAppBusinessAPI: 'Você possui o WhatsApp Business API?',
        yes: 'Sim',
        noIAmNotSure: 'Não, não tenho certeza',
        selectApiProvider: 'Selecione o Provedor de API',
        whatsAppCloudAPI: 'API de Nuvem do WhatsApp',
        enterYourPhoneNumberId: 'Digite o ID do seu número de telefone',
        phoneNumberId: 'ID do número de telefone',
        enterYourWhatsAppBusinessAccountId: 'Digite o ID da sua conta de negócios do WhatsApp',
        whatsAppBusinessAccountId: 'ID da conta de negócios do WhatsApp',
        enterYourSystemUserToken: 'Digite o token do usuário do sistema',
        systemUserToken: 'Token do usuário do sistema',
        help: 'Ajuda',
        netcoreAPI: 'API Netcore',
        enterYourSourceId: 'Digite o ID da fonte',
        sourceId: 'ID da fonte',
        enterYourNetcoreApiKey: 'Digite a chave da API Netcore',
        netcoreApiKey: 'Chave da API Netcore',
        submit: 'Enviar',
        noneOftheAbove: 'Nenhuma das opções acima',
        next: 'Próximo'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Negócio legalmente registrado',
        validWebsiteWithDataPrivacyPolicy: 'Site válido com política de privacidade de dados',
        gstin: 'GSTIN',
        debit: 'Débito',
        creditCard: 'Cartão de crédito',
        onlyRequiredFor: 'Apenas necessário para',
        indianResidents: 'Residentes Indianos',
        onlyRequiredForIndianResidents: 'Apenas necessário para residentes indianos',
        validPaymentMethod: 'Método de pagamento válido',
        enabledWithInternationalPayments: 'Ativado com pagamentos internacionais',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'Caso de uso válido em conformidade com a política de comércio do WhatsApp',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Número de telefone que não tem WhatsApp ativo e é capaz de receber o SMS',
        discard: 'Descartar',
        proceed: 'Prosseguir',
        whatsAppBotPrerequisites: 'Pré-requisitos do bot do WhatsApp',
        letUsGetItDoneForYou: 'Deixe-nos fazer por você!',
        ifYouAreFacingSomeIssues: `Se você está enfrentando alguns problemas`,
        ourTeamCanHelpYouDoTheSame: `Nossa equipe pode te ajudar a fazer o mesmo`,
        requestACallbackNow: `Solicite uma ligação de volta agora!`,
      },
      stepThree: {
        setupWhatsAppBot: 'Configurar Bot do WhatsApp',
        selectingTheBestBusinessManagerForYourNeeds: 'Selecionando o Melhor Gerente Comercial para Suas Necessidades',
        createMetaApp: 'Hoe maak je een Meta-app',
        createaMetaAppOn: 'Criar um Meta App em',
        howToCreate: 'Como criar',
        addWhatsAppNumber: 'Hoe voeg je een WhatsApp-nummer toe',
        addYourWhatsAppNumberAndVerifyTheSame: 'Adicionar seu Número do WhatsApp e Verificar o mesmo',
        subscribe: 'Inscrever-se',
        copyAndSubscribeWebhook: 'Copiar e Inscrever-se no Webhook',
        howToSubscribe: 'Como se inscrever',
        copy: 'Copiar',
        appSecretId: 'ID do Segredo do Aplicativo',
        enterYourAppSecretId: 'Digite seu ID do Segredo do Aplicativo',
        enterAppSecret: 'Digite o Segredo do Aplicativo',
        phoneNumberId: 'ID do Número de Telefone',
        whatsAppBusinessId: 'ID do Negócio WhatsApp',
        assignSystemUser: 'Hoe een systeemgebruiker toewijzen',
        assign: 'Toewijzen',
        createApp: 'Meta aanmaken',
        registerAndVerify: 'Registrar e Verificar',
        add: 'Adicionar',
        systemUserToken: 'Token do Usuário do Sistema',
        enterYourSystemUserToken: 'Digite seu Token do Usuário do Sistema',
        getAllNecessaryDetails: 'Cum să obțineți toate detaliile necesare',
        subscribeWhatsAppWebhook: 'Hoe u zich kunt abonneren op de WA-webhook',
        configureTheWebhookForWhatsAppAccount: 'Configurar o Webhook para a conta do WhatsApp',
        upadatePaymentMethod: 'Cum să actualizați metoda de plată',
        assignThePaymentMethodToWhatsAppAccount: 'Atribuir o Método de Pagamento a qualquer conta do WhatsApp',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Conceder controle de uma conta do WhatsApp Business a um usuário do sistema',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Detalhes-chave que você precisa copiar do Painel de Controle Meta',
        updatePayment: 'Atualizar Pagamento',
        letUsGetItDoneForYou: 'Deixe-nos fazer por você!',
        ifYouAreFacingSomeIssues: `Se você está enfrentando alguns problemas`,
        ourTeamCanHelpYouDoTheSame: `Nossa equipe pode te ajudar a fazer o mesmo`,
        requestACallbackNow: `Solicite uma ligação de volta agora!`,
        update: 'Bijwerken',
        connectNow: 'Nu verbinden'
      },
      stepFive: {
        selectBusinessManager: 'Selecionar Gerente Comercial',
        selectingTheBestBusinessManagerForYourNeeds: 'Selecionar o Melhor Gerente Comercial para Suas Necessidades'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Ваш бот установлен и активен',
        youhaveSuccessfullyConnected: 'Вы успешно подключились',
        withWhatsApp: 'с WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'Вам предоставлено 100 разговоров в месяц по умолчанию',
        upgradeYourPlanForMore: 'Улучшите свой план для большего.',
        createChatFlow: 'Criar Fluxo de Chat',
        upgradePlan: 'Atualizar Plano'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Escanear o QR ou Enviar mensagem para ativar o Bot',
        scanTheQrCodeFrom: 'Escanear o código QR de',
        yourPhoneWillOpen: 'Seu telefone abrirá uma mensagem pré-preenchida para enviar para nosso número de Sandbox. Toque em "Enviar" no WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Enviar mensagem do seu número de WhatsApp',
        sendUsAWhatsAppMessageOn: 'Nos envie uma mensagem no WhatsApp em ',
        bySavingInYourContactsAndMessageShouldStartWith: ' salvando nos seus contatos e a mensagem deve começar com ',
        send: 'Enviar',
        or: 'OU'
      }
    }
  },
  gettingStarted: {
    hello: 'Olá,',
    upgrade: 'Atualizar',
    gettingStarted: 'Começando -',
    yourFacebookBotToken: 'Seu token de bot do Facebook',
    hasBeenExpired: 'Expirou',
    isExpiringIn: 'está expirando',
    days: ' dias',
    facebookAuthenticationTokenRemainsFor60Days: 'O token de autenticação do Facebook permanece ativo apenas por 60 dias',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Por favor, proceda com a renovação do token do seu bot para garantir acesso contínuo aos serviços de chatbot.',
    renewYourBotToken: 'Renove seu token de bot',
    editYourChatFlow: 'Edite seu fluxo de bate -papo',
    buildConversationEngagingBot: 'Crie fluxos de conversação envolventes para seus usuários de bot.',
    takes5minutes: 'leva 5 minutos',
    previewYourLiveChatWidget: 'Visualize seu widget de bate -papo ao vivo',
    discoverHowThe: 'Descubra como o',
    liveChatFeatureWorks: ' Funcionários de bate -papo ao vivo funcionam usando nosso playground ao vivo de bate -papo',
    takes2minutes: 'leva 2 minutos',
    takes1minute: 'leva 1 min',
    connected: 'Conectado',
    customizeYourBotApperance: 'Personalize a aparência e a funcionalidade do seu bot.',
    takes3minutes: 'leva 3 minutos',
    thirdPartyAppsIntegration: 'Integração de aplicativos de terceiros',
    integrateYourBot: 'Integre seu bot com outros aplicativos/CRMs para criar automação de negócios.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Atualize seu plano de receber leads em seu e -mail',
    getStartedWithUs: 'Comece conosco',
    howToBuildAGoodChatFlow: 'Como construir um bom fluxo de bate -papo',
    howToUseBotPenguinsInboxAndItsFeatures: 'Como usar a caixa de entrada do BotPenguin e seus recursos',
    botPenguinsPricing: 'Preços de BotPenguin: Explorando planos e recursos',
    openHelpDesk: 'Abra o Help Desk',
    helpAndSupport: 'Ajuda e suporte',
    extensiveGuides: 'Guias extensos para todas as suas consultas e fluxos de trabalho estão disponíveis em nosso',
    resourceCentre: 'Centro de recursos',
    checkoutOurAnswers: 'Você pode conferir nossas respostas para as consultas comuns abaixo.',
    createANewBot: 'Crie um novo bot',
    installYourBot: 'Instale seu bot',
    plansAndPricing: 'Planos e preços',
    previewYourLandingPageBot: ' Visualize seu bot da página de destino',
    installYourLiveChatWidget: 'Instale seu widget de bate -papo ao vivo',
    installYourWebsiteBot: 'Instale o bot em seu dispositivo',
    integrateBotToLandingPage: 'Integre o bot ao seu bot da página de destino',
    installWidgetToWebsite: 'Instale o widget no seu site',
    installBotToWebsite: 'Instale seu chatbot',
    whatsappAutomation: 'Automação do WhatsApp',
    facebookAutomation: 'Automação do Facebook',
    telegramBotSettings: 'Configurações do Bot Telegram',
    liveChatWidgetSettings: 'Configurações de widgets de bate -papo ao vivo',
    landingPageBotSettings: 'Configurações de bot da página de destino',
    websiteChatBotSettings: 'Configurações de chatbot do site',
    instagramAutomation: 'Automação do Instagram',
    diveDeeperIntoBotPenguin: 'Mergulhe mais fundo no botpenguin',
    knowWhereWeAreGoing: 'Saiba para onde estamos indo, as atualizações recentes e nos ajude a consertar alguns bugs.',
    productUpdates: 'Atualizações do produto',
    requestAFeature: 'Solicite um recurso',
    reportABug: 'Reportar um erro',
    prodcutRoadmap: 'Roteiro de produtos',
    trainYourChatbot: 'Treine seu Chatbot de IA (base de conhecimento)',
    buildEngagingConversation: 'Crie fluxos de conversa envolventes para seus usuários de bot.',
    previewYourAIBot: 'Visualize seu bot de IA',
    integrateAIbot: 'Integre o bot ao seu bot AI',
    aiBotSettings: 'Configurações do bot de IA',
    CustomizeAIBot: 'Personalize a aparência e a funcionalidade do seu bot.',
    gptKeyWarning: 'Bem-vindo ao nosso serviço de API ChatGPT! Por favor, note que há uma limitação de 100 mensagens para cada bot. Após atingir o limite de 100 mensagens, será necessário usar sua própria chave de API ChatGPT para continuar desfrutando de nosso serviço. Para inserir a chave de API ChatGPT, vá para a Configuração do Modelo ChatGPT.',
    whatsappBotSettings: 'Whatsapp Bot Configurações',
    automateCustomerEngagementWithWhatsApp: 'Automatização do envolvimento com o cliente através do WhatsApp',
    connectToYourWhatsappNumber: 'Conectar ao seu número de WhatsApp',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Conectar seu Bot do WhatsApp com seu número de WhatsApp',
    facebookBotSettings: 'Configurações do Bot Facebook',
    automateCustomerEngagementWithTelegram: 'Automatize o envolvimento do cliente com o Telegram',
    automateCustomerEngagementWithFacebook: 'Automatize o envolvimento do cliente com o Facebook',
    automateCustomerEngagementWithInstagram: 'Automatize o envolvimento do Cliente com o Instagram',
    telegramAutomation: 'Automatização do Telegram',
  },
  install: {
    previewYourLandingPageBot: 'Visualize seu bot da página de destino',
    preview: 'Visualização',
    test: 'Teste',
    squareSpace: 'Squarespace',
    installYourChatBot: 'Instale seu chatbot no site, no aplicativo móvel ou inicie como página de destino ou chatbot incorporado',
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    uniqueLinkToLandingPageBot: 'Aqui está o link exclusivo para o seu bot da página de destino.',
    shareOnSocialMedia: 'Basta compartilhá -lo com seus usuários nas mídias sociais, páginas de contato ou cartões de visita.',
    or: 'OU',
    copyUrl: 'Copiar URL',
    selectYourWebsiteCms: 'Selecione seu site CMS',
    selectCms: 'Selecione o CMS/Builder que você usou para criar seu site',
    custom: 'Personalizado',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Outros cms',
    howWouldYouLikeToInstall: 'Como você gostaria de instalá -lo?',
    installingWebsiteBot: 'Escolha você estará instalando o bot em seu site',
    iWillAddMyself: 'Eu vou me adicionar',
    getChatBotScript: 'Obtenha o código de script do chatbot abaixo e instale tudo sozinho',
    getWordpressPlugin: 'Obtenha a tecla API do Plugin WordPress',
    getOurOfficialPlugin: 'Obtenha nosso plugin oficial',
    fromWordpressPluginStore: ' da loja de plugins do WordPress',
    getPlugin: 'Obtenha plugin',
    quickGuideToInstall: 'Guia rápido para instalar',
    placeApiKeyBelowIn: 'Cole a chave da API abaixo em',
    afterDownloadingAndActivatingPlugin: ' Depois de baixar e ativar o plugin',
    copy: 'cópia de',
    enterYourWebsite: 'Digite seu site e vamos ver como seus chatbots parecem',
    enterAValidUrl: 'Insira um URL válido',
    verifyYourInstallation: 'Verifique se sua instalação é bem -sucedida ou não?',
    testInstallation: 'Instalação de teste',
    getChatBotScriptCode: 'Obtenha o código de script de chatbot',
    copyThisCodeSnippet: 'Copie este snippet de código e insira -o no HTML de todas as páginas da web, onde deseja exibir o chatbot ou colar no rodapé antes que a tag seja fechada para aparecer em cada página.',
    // copy: 'cópia de',
    sendCodeToMyDeveloper: 'Enviar código para meu desenvolvedor',
    sendCodeToMyDevelopmentTeam: 'Enviar código para minha equipe de desenvolvimento',
    sendEmail: 'Enviar email',
    enterAValidEmail: 'Entre com um email válido',
    codeHasBeen: 'O código foi',
    toYourDeveloper: ' para o seu desenvolvedor',
    resent: 'ressentir -se',
    sent: 'enviado',
    resendEmail: 'Reenviar email',
    ask: 'Perguntar',
    supportTeamToInstall: ' Equipe de suporte para instalar',
    raiseTheInstallationRequestTo: 'Aumente a solicitação de instalação para',
    team: ' Equipe',
    helpMeInstallingChatBot: 'Ajude -me a instalar o chatbot',
    yourBotInstallationIsNotActive: 'Sua instalação de bot não está ativa!',
    monthlyMessageLimitExceeded: 'Limite mensal de mensagem Exededed',
    exceededMonthlyMessageLimitUpgradeNow: 'Você excedeu seu limite mensal de mensagem, atualize agora.',
    upgradeNow: 'Atualize agora',
    botInstallationNotActivePleaseFix: 'Sua instalação de bot não está ativa, corrija o mesmo.',
    incorrectBotScript: 'Script de bot incorreto',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'O código de instalação que você verifica não corresponde ao do seu site. Certifique -se de instalar o código correto para este bot.',
    unableToSee: 'Incapaz de ver',
    codeOnYourWebsite: ' Code em seu site',
    unableToFindCodePleaseReinstall: 'Não podemos encontrar o código em seu site, reinstale novamente.',
    reinstallBot: 'Reinstale o bot',
    yourBotIsInstalledAndActive: 'Seu bot está instalado e ativo!',
    noPendingAction: 'Sem ação pendente, seu bot está instalado e ativo',
    youAreAllSet: 'Estás pronto!',
    showMeMyLeads: 'Mostre -me meus leads',
    connectingToYourWebsiteToVerifyInstallation: 'Conectando ao seu site para verificar a instalação do bot',
    thisMayTakeUpto30Seconds: 'Isso pode levar até 30 segundos',
    hereIsTheuniqueAILink: 'Aqui está o link exclusivo para o seu AI Bot',
    installHeading: 'Instale seu chatbot',
    websiteChatbot: 'Chatbot do site',
    aiChatBot: 'Chatbot de IA',
    landingPageBot: 'Chatbot da página de destino',
    mobileChatBot: 'Chatbot do aplicativo móvel',
    embeddedBot: 'Chatbot incorporado',
    selectMobileDevice: 'Selecione seu dispositivo móvel',
    mobileDeviceSubheading: 'Selecione o idioma nativo que você usou para criar seu aplicativo móvel',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Outro',
    copyEmbeddedCodeSnippet: 'Copie este trecho de código e insira-o no HTML de cada página da web onde você deseja exibir o chatbot.',
    copyMobileCodeSnippet: 'Copie este trecho de código em seu código de aplicativo móvel onde você deseja exibir o chatbot.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'Digite email do desenvolvedor',
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Envie instruções ao seu desenvolvedor',
      notSureHowToDoIt: 'Não tem certeza de como fazer isso? Seus desenvolvedores podem fazer isso em um instante.',
      developersEmail: 'Email do desenvolvedor',
      emailIsRequired: 'O email é necessário',
      subject: 'Assunto',
      message: 'Mensagem',
      send: 'Enviar',
      placeholders: {
        enterYourWorkEmail: 'Digite seu e -mail de trabalho',
        createBotUsingBotPenguin: 'Excitado! Crie bot usando BotPenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Aqui está o link exclusivo para o seu bot da página de destino.',
      shareWithUsersOnSocialMedia: 'Basta compartilhá -lo com seus usuários nas mídias sociais, páginas de contato ou cartões de visita.',
      previewMyLandingPageBot: 'Visualizar meu bot da página de destino',
      or: 'OU',
      copyUrl: 'Copiar URL',
      howYouLikeToAddBot: 'Escolha como você deseja adicionar seu código de bot ao seu site',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Instale o bot no seu site Codificado ou WordPress personalizado, copiando-se um código exclusivo.',
      name: 'Nome',
      actions: 'Ações',
      javascript: 'JavaScript',
      install: 'Instalar',
      onYourCustomCodedWebsite: ' No seu site de codificação personalizada',
      copyAndPaste: 'Copiar e colar',
      copyThisCodeSnippet: 'Copie este snippet de código e insira -o no HTML de todas as páginas da web em que deseja exibir o widget. Para mais detalhes',
      seeGuide: 'Veja o guia',
      getCode: 'Obter código',
      howToInstall: 'Como instalar?',
      copyCode: 'Código de cópia',
      wordpress: 'WordPress',
      // install: 'Instalar',
      onYourWordPressWebiste: ' No seu site WordPress',
      preview: 'Visualização',
      test: 'Teste',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Visualização de bate -papo ao vivo',
    agentWindow: 'Janela do agente',
    person: 'pessoa',
    inOrderToTestTypeHere: 'Para testar o tipo de teste aqui',
    agentWindowCannotBeCustomized: 'Nota: A janela do agente não pode ser personalizada',
    customerWindow: 'Janela do cliente',
    clickHereToChat: 'Clique aqui para conversar',
    customerWillChatHere: 'Cliente vai conversar aqui',
    chat: 'Bater papo',
    by: 'por',
    changeYourBotLookFromLeftPanel: 'Altere sua aparência de bot do painel esquerdo',
    placeholders: {
      typeYourMessage: 'Digite sua mensagem...',
      typeYourAnswer: 'Digite sua resposta',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Visualize seu widget de bate -papo ao vivo',
    install: 'Instalar',
    seeHowToUse: 'Veja como usar',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Clique para instalar seu bot na plataforma selecionada',
    }
  },
  trainYourBot: {
    trainYourBot: 'Treine seu Chatbot',
    knowledgeBase: 'Base de Conhecimento',
    faqs: 'Perguntas Frequentes',
    history: 'História',
    faqHeading: 'Perguntas Frequentes',
    faqSubHeading: 'Respostas para suas perguntas sobre treinamento de Chatbot',
    knowledgeBaseHeading: 'Treine seu Chatbot',
    knowledgeBaseSubheading: 'Treine seu Chatbot para Conversas Aprimoradas',
    websiteLink: 'Link do Website',
    uploadFile: 'Enviar seu arquivo',
    selectFile: 'Selecionar arquivo',
    submit: 'Enviar',
    searchHere: 'Pesquisar aqui',
    question: 'Pergunta',
    answer: 'Resposta',
    questionPlaceholder: 'Escreva sua pergunta aqui',
    answerPlaceholder: 'Escreva sua resposta aqui',
    save: 'Salvar',
    discard: 'Descartar',
    addFaq: 'Adicionar Perguntas Frequentes',
    createdAt: 'Criado em',
    updatedAt: 'Última atualização em',
    actions: 'Ações',
    noFaqFound: 'Você ainda não tem nenhuma pergunta frequente criada',
    noFaqFoundMessage: 'Todas as suas perguntas frequentes aparecerão aqui',
    historyTitle: 'Histórico da base de conhecimento',
    historySubtitle: 'Respostas para suas perguntas sobre treinamento de Chatbot',
    fileName: 'Nome do arquivo',
    status: 'Status',
    noHistoryTitle: 'Você ainda não tem nenhum modelo treinado criado',
    noHistoryDescription: 'Todos os seus modelos treinados aparecerão aqui',
    fileUpload: 'Enviar arquivo',
    files: 'Arquivos',
    fileSubHeading: 'Adicione todos os arquivos nos quais deseja treinar seu bot.',
    noFileFoundHeading: 'Você ainda não criou nenhum arquivo',
    noFileFoundSubheading: 'Todos os seus arquivos aparecerão aqui',
    uploadYourFiles: 'Carregue seus arquivos',
    upload: 'Carregar',
    fileShouldBe: 'O arquivo deve ser PDF, DOC, DOCX, PPTX, TXT.',
    uploadStatus: 'Status de upload',
    uploadStatusSubHeading: 'Status de upload de arquivos para treinamento do bot',
    addFile: 'Adicionar arquivo',
    otherConfiguration: 'Configurações de IA',
    deleteFiles: 'Clique para excluir arquivos',
    websiteURL: 'URLs do site',
    websiteUrlSubheading: 'Adicione todas as URLs do site nas quais deseja treinar o seu bot.',
    enterWebsiteUrl: 'Inserir URL',
    addWebsite: 'Adicionar site',
    tableWebsiteUrl: 'URL do site',
    trainingStatus: 'Status de treinamento',
    lastTrained: 'Último treinamento',
    noWebsitesFound: 'Você ainda não criou nenhum site',
    noWebsitesFoundMessage: 'Todos os seus sites aparecerão aqui',
    loadMore: 'Carregar mais',
    botBehaviour: 'Configurar o comportamento do bot',
    botBehaviourSubHeading: 'Configure a personalidade do bot, edite os dados do cliente, gerencie o fluxo e o modelo ChatGPT',
    botPersonality: 'Configurar a personalidade do bot',
    botPersonalitySubHeading: 'Gerenciar como o seu bot deve responder em termos de personalidade',
    selectAnswerLength: 'Selecione o comprimento da resposta',
    answerLength: 'Comprimento da resposta',
    answerAs: 'Responder como',
    tone: 'Tom',
    language: 'Idioma',
    answerFormatting: 'Formatação da resposta',
    customInstructions: 'Prompt Personalizado',
    customerInstructionPlaceholder: 'Seja educado e amigável. Não use gírias. Não use emojis.',
    redactCustomerData: 'Coletar dados do cliente',
    redactDataSubheading: 'Configure as informações do cliente que deseja coletar',
    collectName: 'Coletar nome',
    configureQuestion: 'Configurar pergunta',
    maxCharacterLimit: 'Limite máximo de caracteres no nome fornecido',
    collectEmail: 'Coletar e-mail',
    excludeEmail: 'Excluir e-mails com domínio',
    collectPhone: 'Coletar telefone',
    manageUnusualMessages: 'Gerenciar cenários de chat incomuns',
    unusualMessagesSubheading: 'Lidar com cenários raros e sem resposta para o bot',
    startConversation: 'Mensagem para iniciar a conversa',
    configureMessage: 'Mensagem quando o bot não consegue responder',
    profanityMessage: 'Mensagem para responder ao uso de linguagem inadequada',
    chatGptModel: 'Configuração do modelo ChatGPT',
    chatGptModelSubheading: 'Gerenciar qual modelo ChatGPT usar e evitar mau uso',
    enableDisableRedactData: 'Coletar dados do cliente',
    triggerToCollectData: 'Coletar detalhes de contato após um conjunto de perguntas.',
    chatGptApi: 'Chave de API do ChatGPT',
    train: 'Treinar',
    website: 'site',
    includedSourcesForResponse: 'Fontes incluídas para a resposta',
    visibility: 'Visibilidade',
    analytics: 'Análises',
    yourConversation: 'Suas conversas',
    reviewAndSelectConversation: 'Revisar e selecionar conversas para exportar para o treinamento do chatbot',
    exportAiAnalytics: 'Exportar análises',
    startDate: 'Data de início',
    endDate: 'Data de término',
    userQuestion: 'Pergunta do usuário',
    botsReply: 'Resposta do bot',
    dateCreated: 'Data de criação',
    noAnalyticsFound: 'Você ainda não possui nenhuma análise criada',
    noAnalyticsFoundMessage: 'Todas as suas mensagens de análise aparecerão aqui',
    exportData: 'Exportar dados',
    exportCompleteAnalyticsData: 'Exportar dados completos de análise',
    export: 'Exportar',
    integrateWithChatGpt: 'Integração com ChatGPT',
    integrated: 'Integrado',
    configure: 'Configurar',
    appToolTip: {
      deleteWebsite: 'Excluir site',
      knowledgeBase: 'Treine seu bot com a base de conhecimento',
      faqs: 'Treine seu bot com perguntas frequentes',
      knowledgeHistory: 'Histórico da base de conhecimento',
      uploadFaqs: 'Enviar perguntas frequentes',
      addFaqs: 'Clique para adicionar perguntas frequentes',
      deleteFaqs: 'Excluir perguntas frequentes',
      deleteHistory: 'Excluir histórico',
      uploadFile: 'O arquivo enviado deve ser em formato PDF, DOCX, DOC ou CSV e ter tamanho máximo de 1 MB.',
      trainWebsite: 'Treine o seu bot utilizando um website.',
      fileUpload: 'Treine seu bot utilizando arquivos como PDFs, TXTs, PPTXs, DOCs e DOCXs.',
      configureBotBehaviour: 'Personalize a personalidade do bot, gerencie o fluxo e ajuste o modelo ChatGPT.',
      clickToAddWebsite: 'Clique para adicionar um site',
      clickToDeleteWebsites: 'Clique para excluir sites',
      syncWebsite: 'Sincronizar site',
      copyUrl: 'Copiar URL',
      weWillBeFetchingAllTheWebpages: 'Vamos buscar todas as páginas da web e treinar o modelo ChatGPT nelas.'
    },
    modal: {
      deleteWebsiteUrl: 'Excluir URL do site',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Tem certeza de que deseja excluir esta URL do site?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Configurações do chatbot do site',
    preview: 'Visualização',
    test: 'Teste',
    install: 'Instalar',
    designHeader: 'Projeto',
    generalHeader: 'Em geral',
    alertsHeader: 'Alertas',
    triggerHeader: 'Acionar',
    userAccessHeader: 'Acesso ao usuário',
    chatWindowHeader: 'Janela de bate -papo',
    otherConfiguration: 'Configurações de IA',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Clique para instalar seu bot na plataforma selecionada',
      customizeAppearanceOfBot: 'Personalize o posicionamento e a aparência da sua interface bot.',
      configureBotsSettingsWhenAgentUnavailable: 'Defina as configurações do BOT quando seu agente não estiver disponível.',
      configureHowBotWillAlertYou: 'Configure como seu bot alertará você sobre novos leads.',
      makeSureYourBotsPopUpProperlyTimed: 'Certifique-se de que os pop-ups de seus bots estejam perfeitamente cronometrados.',
      customizeWhereYouWantYourBotToAppear: 'Personalize onde deseja que seu bot apareça e onde não.',
      enableChatOptionsForVisitorsToTransferChat: 'Habilite as opções de bate -papo para seus visitantes transferirem bate -papos, bate -papo ao vivo e atualizar bate -papo.',
      configureBotBehaviour: 'Personalize a personalidade do bot, gerencie o fluxo e ajuste o modelo ChatGPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Definir as configurações de alerta do seu bot',
      specifyHowYourBotWillNotify: 'Especifique como seu bot notificará você sobre novos leads.',
      subject: 'Assunto',
      emailNotification: 'Notificação de Email',
      emailAddresses: 'Endereço de e-mail',
      leadRevisitNotification: 'Notificações de revisitar liderar',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'Você se registrou com sucesso com notificações de desktop',
      youHaveBlockedYourNotificationsOnBrowser: 'Você bloqueou as notificações nas configurações do seu navegador.',
      discard: 'Descartar',
      saveChanges: 'Salvar alterações',
      seeHowToUse: 'Veja como usar',
      placeholders: {
        enterSubjectForYourEmail: 'Insira o assunto para o seu e -mail',
        typeYourEmailAddressAndHitEnter: 'Digite seu endereço de e -mail e pressione Enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Personalize o assunto de suas notificações de e -mail do bot',
        TOOLTIP_EMAIL_NOTIFICATION: 'Ativar/desativar a notificação do bot em seu e -mail para novos leads',
        TOOLTIP_EMAIL_ADDRESS: 'Envie o endereço de email da conta, onde deseja receber notificações por email. Você pode adicionar vários endereços de email digitando um e pressionando Enter para adicionar outro.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Ativar/desativar a notificação do BOT em seu email se um usuário capturado anteriormente como um lead interage com o bot novamente.',
      }
    },
    broadcasts: {
      createBroadcastHeading: 'Crie transmissão',
      youCanCreateMultipleBroadcasts: 'Você pode criar várias transmissões',
      broadcastName: 'Nome da transmissão',
      broadcastNameIsRequired: 'O nome de transmissão é necessário',
      continue: 'Continuar',
      manageBroadcasts: 'Gerenciar transmissões',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'Você pode criar várias transmissões com vários modelos agendados para diferentes Grupoos de clientes.',
      createNewBroadcast: 'Crie uma nova transmissão',
      name: 'Nome',
      segment: 'Grupo',
      template: 'Modelo',
      contacts: 'Contatos',
      send: 'Enviar',
      delivered: 'Entregue',
      read: 'Ler',
      failed: 'Fracassado',
      broadcastAt: 'Transmissão em',
      status: 'Status',
      actions: 'Ações',
      nA: 'N / D',
      seeHowToUse: 'Veja como usar',
      deleteBroadcast: 'Exclua transmissão',
      wantToDeleteThisBroadcast: 'Tem certeza de que deseja excluir esta transmissão?',
      placeholders: {
        searchHere: 'Procure aqui',
        enterBroadcastName: 'Digite o nome da transmissão',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Exclua várias transmissões',
        broadcastAnalytics: 'Broadcast Analytics',
        deleteBroadcast: 'Exclua transmissão',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Insights de transmissão',
        broadcastStatus: 'Status de transmissão',
        youCanFindDetailsOfEachContactBelow: 'Você pode encontrar detalhes de cada contato abaixo e exportar os que podem ter problemas.',
        filterApplied: 'Filtro aplicado',
        placeholders: {
          searchByPhoneNumber: 'Pesquise por número de telefone',
          searchByName: 'Filtro de pesquisa por nome',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Status baseado em dados de filtro',
          exportData: 'Exportar dados',
        },
        modal: {
          exportData: 'Exportar dados',
          exportCompleteBroadcastData: 'Exportar dados completos de transmissão',
          export: 'Exportar',
        }
      },
      createBroadcast: {
        broadcastName: 'Nome da transmissão',
        broadcastNameIsRequired: 'O nome de transmissão é necessário',
        template: 'Modelo',
        segments: 'Grupos',
        whenToSend: 'Quando enviar',
        now: 'AGORA',
        later: 'MAIS TARDE',
        selectBroadcastUsing: 'Selecione Broadcast usando',
        usingCsv: 'Usando CSV',
        usingSegment: 'Usando Grupo',
        allContacts: 'Todos os contatos',
        selectDate: 'Selecione a data',
        selectTimeSlot: 'Selecione o slot de tempo',
        assignVariablesToTemplates: 'Atribuir variáveis ​​aos modelos',
        addHeaderMedia: 'Adicione a mídia de cabeçalho',
        selectFile: 'Selecione o arquivo',
        cancel: 'Cancelar',
        createBroadcast: 'Crie transmissão',
        broadcastUsing: 'Transmissão usando',
        selectCSVFile: 'Selecione o arquivo CSV',
        importContacts: 'Contatos importantes',
        beforeYouStartUpload: 'Antes de iniciar o upload, certifique -se de',
        point1: '1. Faça o download do arquivo CSV de amostra.',
        point2: '2. Comece a inserir seus dados a partir da 2ª linha em diante.',
        point3: '3. Em cada linha, haverá dados para um contato',
        point4: '4. Verifique se todos os números de telefone são únicos.',
        point5: '5. O limite máximo de upload do tamanho do arquivo é de 1 MB',
        // selectFile: 'Selecione o arquivo',
        clickHere: 'Clique aqui',
        toDownloadSampleFile: 'Para baixar o arquivo de amostra',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          selectTemplate: 'Selecione modelo',
          searchTemplateByName: 'Modelo de pesquisa por nome',
          selectSegments: 'Selecione Grupos',
          selectTimeSlot: 'Selecione o slot de tempo',
          selectValueForThisVariable: 'Selecione o valor para esta variável',
          searchHeaderByVariableName: 'Variável de cabeçalho de pesquisa por nome',
          variableFallbackValue: 'Valor variável de fallback',
          searchBodyVariableByName: 'Variável do corpo de pesquisa por nome',
          searchSegmentByName: 'Grupo de pesquisa por nome',
          selectBroadcastUsing: 'Selecione Broadcast usando',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecione a data de início',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Importar contatos para transmissão do WhatsApp',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'Número do WhatsApp',
        errorCode: 'Erro de código',
        errorMessage: 'Mensagem de erro',
        nA: 'N / D',
      }
    },
    design: {
      customizeYourLandingPage: 'Personalize sua página de destino',
      customizeYourConversationalBot: 'Personalize seu bot conversacional',
      customizeYourBot: 'Personalize seu bot',
      createHeadingTextAndTheme: 'Crie texto e tema do cabeçalho que reflita a personalidade da sua marca',
      content: 'Contente',
      theme: 'Tema',
      layout: 'Layout',
      landingPageName: 'Nome da página de desembarque',
      botName: 'Nome do bot',
      charactersRemaining: ' caracteres restantes',
      landingPageHeader: 'Cabeçalho da página de destino',
      landingPageDescription: 'Descrição da página de destino',
      hideContent: 'Ocultar conteúdo',
      profilePic: 'Imagem de perfil',
      selectFile: 'Selecione o arquivo',
      socialMediaLinks: 'Links de mídia social',
      initialMessage: 'Mensagem inicial do bot conversacional',
      selectType: 'Selecione o tipo',
      url: 'Url',
      delete: 'Excluir',
      editLink: 'Editar link',
      addLink: 'Adicione link',
      callToAction: 'Chamado à ação',
      liveChatWelcomeMessage: 'Mensagem de boas -vindas de chat ao vivo',
      liveChatTriggerMessage: 'Mensagem de gatilho de bate -papo ao vivo',
      autoHideCallToAction: 'Ocultar automaticamente o chamado à ação',
      maxDelayShouldBe300Seconds: 'O atraso máximo deve ser de 300 segundos',
      landingPageThemeColor: 'Cor do tema da página de destino cor',
      themeColor: 'Cor tema',
      landingPageBackground: 'Página de pouso',
      chatBackgroundColor: 'Chat Background Color',
      color: 'Cor',
      gradient: 'Gradiente',
      image: 'Imagem',
      video: 'Vídeo',
      gradientStart: 'Início do gradiente',
      gradientEnd: 'Fim gradiente',
      // selectFile: 'Selecione o arquivo',
      positionOnWeb: 'Posição na web',
      positionOnMobile: 'Posição no celular',
      windowSize: 'Tamanho da janela',
      s: 'S',
      m: 'M',
      l: 'eu',
      xl: 'Xl',
      xxl: 'Xxl',
      custom: 'Personalizado',
      height: 'Altura',
      minimum300HeightIsRequired: 'É necessária altura mínima de 300px',
      heightCannotExceed720: 'A altura não pode exceder 720px',
      width: 'Largura',
      px: 'px',
      minimum200WidthIsRequired: 'É necessária uma largura mínima de 200px',
      widthCannotExceed1050: 'A largura não pode exceder 1050px',
      discard: 'Descartar',
      saveChanges: 'Salvar alterações',
      landingPageBot: 'Página de destino Bot',
      chatBot: 'Chatbot',
      preview: ' Visualização',
      botIcon: 'Ícone de bot',
      websiteBot: 'Chatbot do site',
      selectFont: 'Selecionar Fonte',
      fontLibrary: 'Biblioteca de Fontes',
      uploadFont: 'Carregar Fonte',
      selectFontForBot: 'Selecionar fonte para o bot',
      uploadedFont: 'Fonte Carregada',
      flowTriggerDetail: 'Gatilho para coletar detalhes de contato',
      placeholders: {
        enterName: 'Insira o nome',
        enterHeaderText: 'Digite o texto do cabeçalho',
        enterDescription: 'Digite a descrição',
        enterUrl: 'Digite URL',
        howCanIHelpYou: 'Como posso ajudá-lo?',
        enterYourWelcomeMessageHere: 'Digite sua mensagem de boas -vindas aqui',
        enterYourLiveChatMessageHere: 'Digite sua mensagem de bate -papo ao vivo aqui',
        enterTimeInSeconds: 'Digite o tempo em segundos',
        enterHeight: 'Digite a altura',
        enterWidth: 'Entre na largura',
        flowInitialMessage: 'Insira a pergunta inicial',
      },
      appTooltip: {
        standardLayout: 'Layout padrão',
        rightLayout: 'Layout direito',
        mergedLayout: 'Layout mesclado',
        enterNameOfYourBot: 'Digite o nome do seu bot',
        enterNameOfYourLandingPageBot: 'Digite o nome do seu bot da página de destino',
        headingTextThatAppearOnLandingPageBot: 'Digite o texto do cabeçalho que deseja aparecer em seu bot da página de destino',
        descriptionTextThatAppearOnLandingPageBot: 'Digite o texto da descrição que deseja aparecer no seu bot da página de destino.',
        addLinksAndSocialMediaIcons: 'Adicione links e ícones de mídia social',
        initialFlowMessage: 'Digite a primeira mensagem que o bot enviará para iniciar a conversa com o cliente',
        anInvitingCatchPhrase: 'Uma frase de captura convidativa que fará o usuário falar com o bot',
        setAWelcomeMessageForYourVisitors: 'Defina uma mensagem de boas -vindas para seus visitantes. Esta será a primeira mensagem em sua conversa.',
        setAMessageToInitiateLiveChat: 'Defina uma mensagem para iniciar o bate -papo ao vivo no final do visitante. Você receberá uma notificação toda vez que esta mensagem for acionada em qualquer uma de suas conversas',
        autoHideCallToAction: 'Ocultar automaticamente o texto `Call to Action` após o tempo especificado',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Você pode optar por ocultar o logotipo e os textos para mostrar apenas a janela de bate -papo na tela',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Carregue um logotipo para a página Chatbot.',
        TOOLTIP_CHAT_THEME_COLOR: 'Escolha a cor da sua janela de bate -papo de bots',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Escolha a cor de fundo da janela de bate -papo de bots',
        TOOLTIP_WEB_1ST_POSITION: 'Posicione seu bot na parte inferior esquerda da área de trabalho dos usuários de bot',
        TOOLTIP_WEB_2ND_POSITION: 'Posicione seu bot no canto do meio direito da área de trabalho dos usuários de bot',
        TOOLTIP_WEB_3RD_POSITION: 'Posicione seu bot no canto inferior direito da área de trabalho dos usuários de bot',
        TOOLTIP_MOBILE_1ST_POSITION: 'Posicione seu bot no canto inferior esquerdo do celular dos usuários de bot',
        TOOLTIP_MOBILE_2ND_POSITION: 'Posicione seu bot no canto do meio direito do celular dos usuários de bot',
        TOOLTIP_MOBILE_3RD_POSITION: 'Posicione seu bot no canto inferior direito do celular dos usuários de bot',
        TOOLTIP_WINDOW_HEIGHT: 'Janela de bate -papo redimensionada de bots',
        TOOLTIP_BOT_ICON: 'Selecione um ícone ou logotipo/imagem de upload para o seu bot',
        flowTriggerMessage: 'Digite o número de mensagens a serem solicitadas antes de coletar os detalhes de contato, como nome, e-mail e telefone',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Crie campanha de gotejamento',
      youCanCreateMultipleCampaigns: 'Você pode criar várias campanhas',
      dripCampaignName: 'Nome da campanha de gotejamento',
      dripCampaignNameIsReuired: 'O nome da campanha de gotejamento é necessário',
      continue: 'Continuar',
      manageDripCampaign: 'Gerenciar campanha de gotejamento',
      youCanCreateMultipleCampaignsWith: 'Você pode criar várias campanhas de gotejamento com mensagens automatizadas programadas para acionar respostas de Grupos de usuários específicos.',
      createNewDripCampaign: 'Crie uma nova campanha de gotejamento',
      campaignName: 'Nome da campanha',
      appliedOn: 'Aplicado em',
      segment: 'Grupo',
      template: 'Modelo',
      startDate: 'Data de início',
      action: 'Ação',
      nA: 'N / D',
      seeHowToUse: 'Veja como usar',
      deleteDripCampaign: 'Exclua campanhas de gotejamento',
      wantToDeleteThisDripCampaign: 'Tem certeza de que deseja excluir essas campanhas de gotejamento?',
      placeholders: {
        enterDripCampaignName: 'Digite o nome da campanha de gotejamento',
        searchByName: 'Procura por nome',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Exclua várias campanhas de gotejamento',
        deleteDripCampaign: 'Excluir campanha de gotejamento',
      },
      createDripCampaign: {
        dripCampaignName: 'Nome da campanha de gotejamento',
        appliedOn: 'Aplicado em',
        segments: 'Grupos',
        selectDate: 'Selecione a data',
        scheduleYourTemplate: 'Agende seus modelos',
        schedule: 'Agendar',
        template: 'Modelo',
        sendAfter: 'Enviar depois',
        assignVariablesToTemplate: 'Atribuir variáveis ​​aos modelos',
        addHeaderMedia: 'Adicione a mídia de cabeçalho',
        selectFile: 'Selecione o arquivo',
        createDripCampaign: 'Crie campanha de gotejamento',
        newSubscriber: 'Novo assinante',
        minutes: 'Minutos',
        hours: 'Horas',
        days: 'Dias',
        placeholders: {
          enterDripName: 'Digite o nome do gotejamento',
          selectAppliedOn: 'Selecione aplicado em',
          selectSegments: 'Selecione Grupos',
          searchByName: 'Procura por nome',
          selectTemplate: 'Selecione modelo',
          searchTemplateByName: 'Modelo de pesquisa por nome',
          enterSendAfter: 'Digite send após',
          selectValueForThisVariable: 'Selecione o valor para esta variável',
          searchHeaderVariableByName: 'Variável de cabeçalho de pesquisa por nome',
          searchBodyVariableByName: 'Variável do corpo de pesquisa por nome',
        },
        dateTimePicker: {
          selectStartDate: 'Selecione a data de início',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Configurar as configurações gerais da sua página de destino',
      configureBotsGeneralSettings: 'Definir as configurações gerais do seu bot',
      selectTheTimeZone: 'Selecione a zona de tempo, o idioma e uma pequena nota para seus clientes quando o',
      isNotAvailable: ' não está disponível',
      landingPagesBot: 'Páginas de desembarque Bot',
      bot: 'robô',
      waitingMessage: 'Mensagem de espera',
      landingPageBotStatus: 'Status do Bot da página de destino',
      chatBotStatus: 'Status do chatbot',
      unavailabilityMessage: 'Mensagem de não disponibilidade',
      sendIncompleteResponses: 'Envie respostas incompletas',
      language: 'Linguagem',
      invalidateCache: 'Invalidar cache',
      timezone: 'Fuso horário',
      chatWindowMessageHistory: 'Histórico da mensagem da janela de bate -papo',
      session: 'sessão',
      forever: 'para sempre',
      english: 'Inglês',
      spanish: 'Espanhol',
      french: 'Francês',
      portuguese: 'Português',
      german: 'Alemão',
      italian: 'italiano',
      swedish: 'sueco',
      arabic: 'árabe',
      malay: 'malaio',
      russian: 'russo',
      discard: 'Descartar',
      saveChanges: 'Salvar alterações',
      seeHowToUse: 'Veja como usar',
      placeholders: {
        enterYourWaitingMessageHere: 'Digite sua mensagem de espera aqui',
        // selectLanguage: ', Assim,',
        searchTimeZone: 'Pesquisar fuso horário',
        // selectChatRetentionPolicy: ', Assim,',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalize a mensagem como você deseja que ela apareça na janela de bate -papo imediatamente depois que um usuário de bot envia uma solicitação de chat ao vivo',
        TOOLTIP_CHATBOT_STATUS: 'Ativar /desativar o bot',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Personalize a mensagem como você deseja que ela apareça na janela de bate -papo quando nenhum de seus agentes disponíveis para bate -papo ao vivo com o usuário do bot',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Envie as notificações por e -mail, mesmo quando o usuário do bot não terminou a conversa.',
        TOOLTIP_LANGUAGE: 'Selecione o idioma do bot',
        TOOLTIP_INVALIDATE_CACHE: 'Clique aqui para limpar o cache como e quando você edita o fluxo de bate -papo ou as configurações do BOT, pois as alterações refletem somente após 15 minutos',
        TOOLTIP_TIME_ZONE: 'Selecione a zona de tempo para o seu bot',
        TOOLTIP_CHAT_RETENTION: 'Selecione se você deseja reter os bate -papos dos usuários de bot para sempre na janela de bate -papo ou apenas para uma sessão do navegador.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Personalize sua janela de bate -papo',
      controlAndCustomizeTheChatWindow: 'Controle e personalize a janela de bate -papo com os quais seus clientes interagem.',
      allowVisitorsToTransferChatToWhatsapp: 'Permitir que os visitantes transfiram o bate -papo para o WhatsApp',
      allowVisitorsToRefreshChat: 'Permitir que os visitantes atualizem o bate -papo',
      displayNameForTransferringChatToWhatsapp: 'Nome de exibição para transferir um bate -papo para o WhatsApp',
      displayNameForTransferringToLiveChat: 'Nome da exibição para transferência para o bate -papo ao vivo/agente',
      displayNameForTransferringAChatToBot: 'Nome da exibição para transferir um bate -papo para o bot',
      discard: 'Descartar',
      saveChanges: 'Salvar alterações',
      allowVisitorsToTransferChatToBot: 'Permita que os visitantes transfira o bate -papo para o bot.',
      allowVisitorsToRequestForLiveChat: 'Permita que os visitantes solicitem bate -papo ao vivo.',
      indicativeTextForCustomersReply: 'Texto indicativo para a resposta do cliente',
      displayNameForRestartingChatBot: 'Exibir o nome para reiniciar a conversa de chatbot',
      seeHowToUse: 'Veja como usar',
      placeholders: {
        enterButtonNameForWhatsapp: 'Digite o nome do botão para transferir para o WhatsApp',
        enterButtonNameForLiveChat: 'Digite o nome do botão para transferir para o bate -papo ou agente ao vivo',
        enterButtonNameForBot: 'Digite o nome do botão para transferir para o bot',
        enterTextDisplayInEmptyReplyArea: 'Digite o texto exibido na área de resposta vazia',
        enterButtonForRestartingConversation: 'Digite o nome do botão para reiniciar a conversa',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Permita que os visitantes do site transfiram o bate -papo para o WhatsApp no. do respectivo agente ao qual estão conectados. O recurso de bate -papo ao vivo deve estar ativado para que esse recurso funcione.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Permita que os usuários de bots atualizem o bate -papo para que eles possam começar a conversar novamente.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Permita que os usuários do BOT transfiram o bate-papo para o BOT sempre entre a conversa com o agente de bate-papo ao vivo.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Permita que os usuários do BOT solicitem bate -papo ao vivo com o agente sempre que desejarem.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Se você permitiu ao usuário transferir para o WhatsApp, preencha como você gostaria de exibir essa opção',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Se você permitiu ao usuário transferir para o bate -papo ao vivo, preencha como você gostaria de exibir essa opção',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Se você permitiu ao usuário transferir para o WhatsApp, preencha como você gostaria de transferir de volta para o bot',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Preencha como você gostaria de exibir o espaço de resposta para os clientes',
        ifYouHaveEnabledUserToRestartChatBot: 'Se você permitiu ao usuário reiniciar a conversa do chatbot, preencha como você gostaria de exibir essa opção',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Fluxos de bate -papo',
      dripCampaignHeader: 'Campanha de gotejamento',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Transmissão',
      ordersHeader: 'Ordens',
      manageHeader: 'Gerenciar',
      inputHeader: 'Entrada',
      apiKeyHeader: 'Chave API',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Crie uma jornada de integração de clientes usando uma campanha de gotejamento de fluxos de bate -papo programados.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalize os modelos de fluxo de bate -papo e crie vários fluxos de bate -papo para diferentes públicos -alvo.',
        TOOLTIP_WIDGET_TITLE: 'Crie widgets (interfaces de bate -papo) e incorpore -os em páginas da web promocionais para direcionar os visitantes ao bot',
        TOOLTIP_BROADCAST_TITLE: 'Mensagens de explosão ou fluxos para seus contatos em um único clique no tempo pré-agendado',
        TOOLTIP_ORDERS_TITLE: 'Lista de pedidos feitos pelo cliente',
        TOOLTIP_MANAGE_TITLE: 'Gerenciando a funcionalidade de bot para contatos/leads e palavras -chave',
        TOOLTIP_INPUT_TITLE: 'Crie modelos, imagens e texto do WhatsApp como material de resposta para contatos/leads',
      },
      apiWrapper: {
        whatsappApiCredentials: 'Credenciais da API do WhatsApp',
        generateYourWhatsappApiKey: 'Gere sua chave da API do WhatsApp para se integrar aos seus aplicativos desenvolvidos personalizados.',
        generateApiKey: 'Gerar a chave da API',
        webhookUrl: 'Webhook URL',
        addHeader: 'Adicione o cabeçalho',
        test: 'Teste',
        webhookNotSet: 'Webhook não definido',
        webhookHeaders: 'Cabeçalhos webhook',
        setWebhookUrl: 'Defina o URL da webhook',
        placeholders: {
          yourApiKey: 'Sua chave da API',
          enterWebhookUrl: 'Digite URL do WebHook',
          enterHeaderKey: 'Digite a chave do cabeçalho',
          enterValue: 'Insira valor',
        }
      },
      broadcast: {
        createBroadcast: 'Crie transmissão',
        createMultipleBroadcasts: 'Você pode criar várias transmissões com vários fluxos/modelos agendados para diferentes Grupos de clientes.',
        broadcastName: 'Nome da transmissão',
        nameIsRequired: 'O nome é necessário',
        selectType: 'Selecione o tipo',
        selectYourFlowType: 'Selecione seu tipo de fluxo',
        segment: 'Grupo',
        selectYourSegmentType: 'Selecione o seu tipo de Grupo',
        whenToSend: 'Quando enviar',
        selectDate: 'Selecione a data',
        messageTag: 'Tag de mensagem',
        selectMessageTag: 'Selecione a tag de mensagem',
        contentType: 'Tipo de conteúdo',
        nonPromotional: 'Não-promotional (tags de mensagem aplicadas)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Marque cada mensagem que vem antes de qualquer interação ou após as etapas de atraso superiores a 24 horas com a tag apropriada. Mensagem não marcada não chegará ao assinante que interagiu com o bot há mais de 24 horas',
        promotional: 'Promocional (tags de mensagem ignoradas)',
        theseBroadcasts: 'Essas transmissões podem conter promoções, mas o público -alvo é limitado a assinantes que interagiram com seu bot nas últimas 24 horas.',
        createNewBroadcast: 'Crie uma nova transmissão',
        sentMessages: 'Mensagens enviadas',
        deliveredMessages: 'Mensagens entregues',
        readMessages: 'Mensagens lidas',
        failedMessages: 'Mensagens falhadas',
        // createNewBroadcast: 'Crie uma nova transmissão',
        type: 'Tipo',
        template: 'Modelo',
        sent: 'Enviado',
        delivered: 'Entregue',
        read: 'Ler',
        failed: 'Fracassado',
        broadcastAt: 'Transmissão em',
        status: 'Status',
        shipmentUpdate: 'Atualização de remessa',
        // template: 'Modelo',
        flow: 'Fluxo',
        resendFailedMessages: 'Reenviar mensagens com falha',
        // sentMessages: 'Mensagens enviadas',
        // deliveredMessages: 'Mensagens entregues',
        goBack: 'Volte',
        number: 'Número',
        errorCode: 'Erro de código',
        failedAt: 'Falhou em',
        badGateway: '502 Bad Gateway',
        loadMore: 'Carregue mais',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          searchBroadcastByName: 'Pesquisar transmissão por nome',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Total de mensagens de transmissão enviadas',
          totalBroadcastMessagesDelivered: 'Total de mensagens de transmissão entregues',
          totalBroadcastMessagesReadByContacts: 'Total de mensagens de transmissão lidas pelos contatos',
          totalFailedBroadcastMessages: 'Mensagens de transmissão fracassadas totais',
          clickToViewDetailedInformationOfFailedMessages: 'Clique para visualizar informações detectadas de mensagens com falha',
          clickToCreateNewBroadcast: 'Clique para criar uma nova transmissão',
          templateUsedInBroadcast: 'Modelo usado na transmissão',
          numberOfBroadcastMessagesSent: 'No. de mensagens de transmissão enviadas',
          numberOfBroadcastMessagesDelivered: 'No. de mensagens de transmissão entregues',
          numberOfBroadcastMessagesReadByContacts: 'No. de mensagens de transmissão lidas pelos contatos',
          numberOfTimesBroadcastMessagesHasFailed: 'Não. Of Times Broadcast Messaging falhou',
          contactNumberOnWhichMessageDeliveryFailed: 'Número de contato em que entrega de mensagens falhou',
          unDeliveredTemplate: 'Modelo não entregue',
          errorReceivedForFailedMessage: 'Erro recebido para falha na mensagem',
          timeWhenMessageDeliveryFailed: 'Tempo quando a entrega da mensagem falhou',
          TOOLTIP_ADD_BROADCAST_NAME: 'Digite um novo nome de transmissão',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Selecione se você está transmitindo um fluxo ou uma mensagem.',
          TOOLTIP_BROADCAST_FLOW: 'Selecione o fluxo de bate -papo que você deseja transmitir,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Selecione o grupo de contatos para o qual deseja transmitir',
          TOOLTIP_BROADCAST_NAME: 'Nome da transmissão',
          TOOLTIP_BROADCAST_TYPE: 'Fluxo ou mensagem selecionada para a transmissão',
          TOOLTIP_BROADCAST_SEGMENT: 'Grupo para o qual a transmissão é criada',
          TOOLTIP_BROADCAST_AT: 'Data e hora agendadas para a transmissão',
          TOOLTIP_BROADCAST_STATUS: 'Status de transmissão conforme programado, atualmente em execução ou concluído',
        }
      },
      drip: {
        createDripCampaign: 'Crie campanha de gotejamento',
        youCanCreateMultipleCampaigns: 'Você pode criar várias campanhas',
        dripCampaignName: 'Nome da campanha de gotejamento',
        dripCampaignNameIsRequired: 'O nome da campanha de gotejamento é necessário',
        // createDripCampaign: 'Crie campanha de gotejamento',
        youCanCreateMultipleDripCampaigns: 'Você pode criar várias campanhas de gotejamento com mensagens automatizadas programadas para acionar respostas de Grupos de usuários específicos.',
        campaignName: 'Nome da campanha',
        campaignNameIsRequired: 'O nome da campanha é necessário',
        after: 'Depois',
        thisMessageWillBeSendAfter: 'Esta mensagem será enviada depois',
        saveAndUpdate: 'Salvar e atualizar',
        schedule: ' Agendar',
        select: 'Selecione',
        messages: 'Mensagens',
        cancel: 'Cancelar',
        createCampaign: 'Criar campanha',
        updateCampaign: 'Campanha de atualização',
        createNewCampaign: 'Crie uma nova campanha',
        // campaignName: 'Nome da campanha',
        messagesSent: 'Mensagens enviadas',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        action: 'Ação',
        placeholders: {
          nameYourDripCampaign: 'Nomeie sua campanha de gotejamento',
          enterCampaignName: 'Digite o nome da campanha',
          searchCampaignByName: 'Campanha de pesquisa por nome',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Clique para adicionar uma nova campanha',
          TOOLTIP_SCHEDULE: 'Planeje quando e qual bate -papo fluir para enviar primeiro nesta campanha.',
          TOOLTIP_AFTER_DAYS: 'Programe quando enviar o primeiro fluxo da campanha em minutos, horas ou dias.',
          TOOLTIP_ADD_MESSAGES: 'Clique para adicionar um novo fluxo à campanha',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Digite um novo nome de campanha',
          TOOLTIP_CAMPAIGN_NAME: 'Nome da campanha',
          TOOLTIP_NO_MESSAGES_SENT: 'No. de mensagens enviadas pela campanha',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Data e hora da criação da campanha',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Data e hora em que a campanha foi modificada pela última vez',
          TOOLTIP_CAMPAIGN_ACTION: 'Editar ou excluir a campanha',
          TOOLTIP_UPDATE_CAMPAIGN: 'Clique para salvar as modificações feitas na configuração da campanha',
        }
      },
      flows: {
        createChatFlow: 'Criar fluxo de bate -papo',
        youCanCreateMultipleConversationsFlow: 'Você pode criar vários fluxos de conversas em' +
          ' vários canais para testes e transmissões A/B.',
        flowName: 'Nome do fluxo',
        nameIsRequired: 'O nome é necessário',
        cancel: 'Cancelar',
        createFlow: 'Criar fluxo',
        createNewFlow: 'Criar novo fluxo',
        // flowName: 'Nome do fluxo',
        ofMessages: 'de mensagens',
        triggered: 'Provocado',
        stepsFinsished: 'Etapas terminadas',
        finished: 'Finalizado',
        createdOn: 'Criado em',
        lastModified: 'Última modificação',
        defaultFlow: 'Fluxo padrão',
        action: 'Ação',
        flowAnalytics: 'Flow Analytics',
        placeholders: {
          enterFlowName: 'Digite o nome do fluxo',
          searchFlowByName: 'Fluxo de pesquisa por nome',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'Não. Das vezes o fluxo de bate -papo é acionado',
          numberOfCompletedChatFlowStepsWhenTriggered: 'Nº de etapas de fluxo de bate -papo concluídas quando são acionadas',
          numberOfTimesUsersCompletedChatFlowSteps: 'Não. De vezes os usuários concluíram todas as etapas de fluxo de bate -papo',
          dateAndTimeOfCreationOfChatFlow: 'Data e hora da criação do fluxo de bate -papo',
          dateAndTimeWhenChatFlowLastModified: 'Data e hora em que o fluxo de bate -papo foi modificado pela última vez',
          TOOLTIP_CREATE_FLOW: 'Clique para criar um novo fluxo de bate -papo',
          TOOLTIP_FLOW_NAME: 'Digite o nome do fluxo de bate -papo',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome do fluxo de bate -papo',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensagem no fluxo de bate -papo',
          TOOLTIP_DEFAULT_FLOW: 'Use essa alternância para definir um fluxo de bate -papo como padrão, que será servido a todos os novos usuários.',
          TOOLTIP_ACTION: 'Editar ou excluir o fluxo de bate -papo',
        }
      },
      manage: {
        leads: 'Pistas',
        manageBotsFunctionalityForContacts: 'Gerenciar a funcionalidade do BOT para os contatos/leads',
        whenToSendLeadInfo: 'Quando enviar informações de lead',
        smartFlow: 'Fluxo inteligente',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Mensagem de boas -vindas quando nenhuma pesquisa de palavras -chave for cumprida',
        selectTemplates: 'Selecione modelos',
        selectFlows: 'Selecione fluxos',
        selectText: 'Selecionar texto',
        selectImages: 'Selecione imagens',
        whenItAlmostReaches24HoursSinceMessage: 'Quando quase atinge 24 horas desde a última mensagem',
        // selectTemplates: 'Selecione modelos',
        updateChanges: 'Alterações de atualização',
        manageBotsFunctionalityAccordingToKeywords: 'Gerencie a funcionalidade do BOT de acordo com a entrada de palavras -chave pelo usuário do bot, em caso de fluxo inteligente',
        addKeywordAction: 'Adicione ação de palavra -chave',
        keywords: 'Palavras-chave',
        matchingMethod: 'Método de correspondência',
        replyMaterial: 'Material de resposta',
        action: 'Ação',
        select: 'Selecione',
        placeholders: {
          keyword: 'palavra -chave',
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Sugira se o bot enviar informações sobre contato/lead para integração CRM imediatamente, ou após 5 minutos, ou no final do fluxo',
          TOOLTIP_LEAD_SMART_FLOW: 'Escolha qualquer fluxo padrão para enviá -lo a todos os usuários do bot ou fluxo inteligente (determinado pela entrada de palavras -chave pelo usuário do bot).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Selecione Material de resposta (fluxo, modelo, texto, imagem) para enviar se o bot não receber nenhuma correspondência de palavras -chave da resposta do usuário do bot',
          TOOLTIP_IT_ALMOST_REACHED: 'Selecione Material de resposta para enviar para contatos/leads pouco antes de 24 horas das últimas mensagens',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Gerencie a funcionalidade do BOT de acordo com a entrada de palavras -chave pelo usuário do bot, em caso de fluxo inteligente',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Selecione a resposta de Bot como modelo, fluxo, texto ou imagem contra palavras -chave específicas de entrada',
          TOOLTIP_ADD_KEYWORD: 'Adicione palavras -chave',
          TOOLTIP_MATCHING_METHOD: 'Adicione a correspondência de palavras -chave como exato ou contém',
          TOOLTIP_REPLY_MATERIAL: 'Selecione modelo, fluxo, texto ou imagem para responder para a palavra -chave sugerida',
          TOOLTIP_KEYWORD_ACTIONS: 'Exclua a ação da palavra -chave',
        },
        socialAlerts: {
          alerts: 'Alertas',
          specifyHowYourBotWillNotify: 'Especifique como seu bot notificará você sobre novos leads.',
        }
      },
      orders: {
        manageCustomersOrder: 'Gerenciar pedidos de clientes',
        checkAndManageAllOfYourCustomerOrders: 'Verifique e gerencie todos os seus pedidos e atualizações de clientes.',
        selectShipmentTemplate: 'Selecione Modelo de Remessa',
        send: 'Enviar',
        export: 'Exportar',
        phoneNumber: 'Número de telefone',
        numberOfItems: 'Número de ítens',
        orderValue: 'Valor do pedido',
        orderDate: 'Data do pedido',
        status: 'Status',
        chat: 'Bater papo',
        item: 'Item',
        quantity: 'Quantidade',
        amount: 'Quantia',
        unitPrice: 'Preço unitário',
        placeholders: {
          searchOrdersByPhoneNumber: 'Pedidos de pesquisa por números de telefone',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Exportar dados de pedidos de dados no arquivo CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'Número de telefone do cliente que fez o pedido',
          TOOLTIP_NO_OF_ITEMS: 'No. de itens encomendados',
          TOOLTIP_ORDER_VALUE: 'Valor da ordem',
          TOOLTIP_ORDER_DATE: 'O pedido foi feito nesta data.',
          TOOLTIP_ORDER_STATUS: 'Status da ordem, conforme confirmado, pendente, rejeitado. ou enviado',
          TOOLTIP_ORDER_CHAT: 'Todas as conversas anteriores de bate -papo sobre este pedido',
        }
      },
      widgets: {
        installWidgetHeader: 'Instale o widget',
        createWidgetHeader: 'Criar widget',
        copyTheCodeAndPasteItWhereYouWant: 'Copie o código e cole -o sempre que precisar em seu HTML, site etc.',
        youCanCreateMultipleWidgets: 'Você pode criar vários widgets para dar ao seu bot uma aparência diferente em diferentes páginas da web.',
        installWidget: {
          javascript: 'JavaScript',
          install: 'Instalar',
          onYourWebsite: ' em seu site',
          copyCode: 'Código de cópia',
          copyAndPaste: 'Copiar e colar',
          copyThisCodeSnippet: 'Copie este snippet de código e insira -o no html de todas as páginas da web onde você deseja',
          seeGuide: 'Veja o guia',
        },
        widgetList: {
          widgetName: 'Nome do widget',
          nameIsRequired: 'O nome é necessário',
          cancel: 'Cancelar',
          createWidget: 'Criar widget',
          icon: 'Ícone',
          name: 'Nome',
          createdAt: 'Criado em',
          action: 'Ação',
          placeholders: {
            enterWidgetName: 'Digite o nome do widget',
            searchWidgetByName: 'Widget de pesquisa por nome',
          },
          appTooltip: {
            widgetIcon: 'Ícone do widget',
            nameOfWidget: 'Nome do widget',
            dateAndTimeOfCreationOfWidget: 'Data e hora da criação do widget',
            editOrDeleteWidget: 'Editar ou excluir o widget',
          }
        },
        widgetTemplates: {
          widgetName: 'Nome do widget',
          nameIsRequired: 'O nome é necessário',
          chooseTypeOfWidget: 'Escolha o tipo de widget',
          bar: 'Bar',
          slideIn: 'Deslize',
          pageTakeOver: 'Aquisição da página',
          button: 'Botão',
          facebookModal: 'Modal do Facebook',
          comment: 'Comente',
          direct: 'Direto',
          whatsappIcon: 'Ícone do WhatsApp',
          refUrl: 'Ref url',
          qrCode: 'Código QR',
          // refUrl: 'Ref url',
          backgroundColor: 'Cor de fundo',
          descriptionColor: 'Descrição cor',
          headlineColor: 'Cor da manchete',
          buttonBackgroundColor: 'Button Background Color',
          contactNumber: 'Número de contato',
          entryMessage: 'Mensagem de entrada',
          buttonText: 'Botão de texto',
          selectButtonText: 'Selecione o texto do botão',
          buttonSize: 'Tamanho do botão',
          selectOne: 'Selecione um',
          placement: 'Colocação',
          rightBottom: 'Inferior direito',
          optInFlow: 'Fluxo de opção',
          selectFlow: 'Selecione Fluxo',
          addSubscriberToSequence: 'Adicionar assinante à sequência',
          selectDrip: 'Selecione gotejamento',
          whenDoesItDisplay: 'Quando ele é exibido (segundos)',
          showWidgetToSameUserAgainAfter: 'Mostre o widget para o mesmo usuário novamente depois',
          ifManuallyClosedByUser: 'Se fechado manualmente pelo usuário, mostre depois',
          // comment: 'Comente',
          specificPosts: 'Postagens específicas',
          allPosts: 'Todas as publicações',
          // entryMessage: 'Mensagem de entrada',
          postMessage: 'Postar mensagem',
          postDate: 'Data de postagem',
          status: 'Status',
          action: 'Ação',
          discard: 'Descartar',
          saveChanges: 'Salvar alterações',
          nextStep: 'Próxima Etapa',
          widgetDescription: 'Descrição do widget',
          integrateTheBarWidget: 'Integre o widget de barra no corpo do seu site e aumente o assinante da página junto com o envio do fluxo de mensagens de bot personalizado.',
          hereIsYourWidgetHeadline: 'Aqui está o título do seu widget, clique aqui para alterá -lo.',
          sendUsMessage: 'Envie -nos uma mensagem',
          weAlsoPutDefaultTextHere: 'Também colocamos texto padrão aqui.',
          selectYourPagePost: 'Selecione sua página post',
          nA: 'N / D',
          // entryMessage: 'Mensagem de entrada',
          saveRule: 'Salvar regra',
          placeholders: {
            enterWidgetName: 'Digite o nome do widget',
            enterNumber: 'Insira numeros',
            enterMessageHere: 'Digite a mensagem aqui',
            enterTimeInSeconds: 'Digite o tempo em segundos',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'Personalize as configurações de gatilho do seu bot',
      makeYourBotsTimingPerfect: 'Torne o tempo do seu bot apenas perfeito.',
      onDesktopTriggerTime: 'Tempo de gatilho no desktop (segundos)',
      onMobileTriggerTime: 'Tempo de gatilho na mobile (segundos)',
      chatBubble: 'Abertura automática da bolha de bate -papo',
      seconds: 'Segundos',
      discard: 'Descartar',
      saveChanges: 'Salvar alterações',
      autoTriggerOnOrOff: 'Gatilho automático: ligado/desativado',
      seeHowToUse: 'Veja como usar',
      alwaysOpenAfter: 'Sempre aberto depois de gatilhos especificados acima',
      automaticallyAfterSelectedNumberOfTime: 'Automaticamente após quantidade selecionada de tempo',
      neverAutomaticallyOpenBot: 'Nunca abra automaticamente o bot',
      placeholders: {
        enterTimeInSeconds: 'Digite o tempo em segundos',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Tempo de espera em segundos, nos dispositivos de desktop do visitante, antes que seu bot apareça automaticamente',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Tempo de espera em segundos, no visitante móvel, antes que seu bot apareça automaticamente',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Quando o visitante fechar a janela de bate-papo, selecione como deve ser o comportamento pop-up de bot auto',
        customerWaitingTimeToOpenBubble: 'Cliente de espera para abrir bolha de bate -papo',
        wantToDisableAutoTriggering: 'Clique se desejar desativar o acionamento automático de bots nas páginas da web',
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'Personalize as configurações do usuário do seu bot',
      decideWhoCanAccessYourBot: 'Decida quem pode acessar seu bot',
      deviceSupport: 'Suporte ao dispositivo',
      blockIpAddresses: 'Bloquear endereços IP',
      hideChatBotOnSpecificPages: 'Ocultar chatbot em páginas específicas',
      selectCountriesToEnableBot: 'Selecione países para ativar o bot',
      discard: 'Descartar',
      saveChanges: 'Salvar alterações',
      seeHowToUse: 'Veja como usar',
      allDevices: 'Todos os dispositivos',
      mobileOnly: 'Somente móvel',
      desktopOnly: 'Apenas para desktop',
      placeholders: {
        enterIpAddress: 'Digite o endereço IP (IPv4 ou IPv6) e pressione `Enter`',
        enterUrl: 'Digite o URL das páginas e pressione `Enter`',
        searchCountry: 'País de pesquisa',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Selecione em quais dispositivos seu bot deve aparecer',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Digite endereços IP onde você não deseja que seu bot apareça',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Digite o URL de suas páginas da web onde você não deseja que seu bot apareça',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Selecione Locais de onde você deseja que os usuários acessem seu bot',
      }
    },
    facebook: {
      facebookBotSettings: 'Configurações de bot do Facebook',
      chatFlowsHeader: 'Fluxos de bate -papo',
      dripCampaignHeader: 'Campanha de gotejamento',
      broadcastHeader: 'Transmissão',
      widgetsHeader: 'Widgets',
      autoReplyHeader: 'Resposta automática',
      generalHeader: 'Geral',
      facebookAutomation: 'Automação do Facebook',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalize os modelos de fluxo de bate -papo e crie vários fluxos de bate -papo para diferentes públicos -alvo.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Crie uma jornada de integração de clientes usando uma campanha de gotejamento de fluxos de bate -papo programados.',
      },
      broadcasts: {
        createBroadcast: 'Crie transmissão',
        youCanCreateMultipleBroadcasts: 'Você pode criar várias transmissões',
        broadcastName: 'Nome da transmissão',
        broadcastNameIsRequired: 'O nome de transmissão é necessário',
        continue: 'Continuar',
        manageBroadcasts: 'Gerenciar transmissões',
        youCanCreateMultipleBroadcastsWith: 'Você pode criar várias transmissões com vários modelos agendados para diferentes Grupos de clientes.',
        createNewBroadcast: 'Crie uma nova transmissão',
        name: 'Nome',
        segment: 'Grupo',
        template: 'Modelo',
        contacts: 'Contatos',
        send: 'Enviar',
        failed: 'Fracassado',
        broadcastAt: 'Transmissão em',
        status: 'Status',
        actions: 'Ações',
        nA: 'N / D',
        deleteBroadcast: 'Exclua transmissão',
        wantToDeleteThisBroadcast: 'Tem certeza de que deseja excluir esta transmissão?',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          searchHere: 'Procure aqui',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Exclua várias transmissões',
          deleteBroadcast: 'Exclua transmissão',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Nome da transmissão',
        broadcastNameIsRequired: 'O nome de transmissão é necessário',
        flow: 'Fluxo',
        segments: 'Grupos',
        whenToSend: 'Quando enviar',
        selectDate: 'Selecione a data',
        selectTimeSlot: 'Selecione o slot de tempo',
        createBroadcast: 'Crie transmissão',
        cancel: 'Cancelar',
        now: 'Agora',
        later: 'Mais tarde',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          selectFlow: 'Selecione Fluxo',
          searchFlowByName: 'Fluxo de pesquisa por nome',
          selectSegments: 'Selecione Grupo',
          searchSegmentByName: 'Grupo de pesquisa por nome',
          selectTimeSlot: 'Selecione o slot de tempo',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecione a data de início',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crie campanha de gotejamento',
        youCanCreateMultipleCampaign: 'Você pode criar várias campanhas',
        dripCampaignName: 'Nome da campanha de gotejamento',
        dripCampaignNameIsRequired: 'O nome da campanha de gotejamento é necessário',
        continue: 'Continuar',
        manageDripCampaign: 'Gerenciar campanha de gotejamento',
        youCanCreateMutlipleDripCampaign: 'Você pode criar várias campanhas de gotejamento com mensagens automatizadas programadas para acionar respostas de Grupos de usuários específicos.',
        createNewDripCampaign: 'Crie uma nova campanha de gotejamento',
        campaignName: 'Nome da campanha',
        appliedOn: 'Aplicado em',
        segment: 'Grupo',
        flow: 'Fluxo',
        startDate: 'Data de início',
        action: 'Ação',
        nA: 'N / D',
        deleteDripCampaign: 'Exclua campanhas de gotejamento',
        wantToDeleteThisDripCampaign: 'Tem certeza de que deseja excluir essas campanhas de gotejamento?',
        placeholders: {
          enterDripCampaignName: 'Digite o nome da campanha de gotejamento',
          searchByName: 'Procura por nome',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Exclua várias campanhas de gotejamento',
          deleteDripCampaign: 'Excluir campanha de gotejamento',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Nome da campanha de gotejamento',
          appliedOn: 'Aplicado em',
          segments: 'Grupos',
          selectDate: 'Selecione a data',
          scheduleYourFlows: 'Agende seus fluxos',
          schedule: 'Agendar',
          flow: 'Fluxo',
          sendAfter: 'Enviar depois',
          cancel: 'Cancelar',
          newSubscriber: 'Novo assinante',
          days: 'Dias',
          minutes: 'Minutos',
          hours: 'Horas',
          createDripCampaign: 'Crie campanha de gotejamento',
          placeholders: {
            enterDripName: 'Digite o nome do gotejamento',
            selectAppliedOn: 'Selecione aplicado em',
            selectSegment: 'Selecione Grupo',
            searchByName: 'Procura por nome',
            selectTemplate: 'Selecione modelo',
            searchFlowByName: 'Fluxo de pesquisa por nome',
            enterSendAfter: 'Digite send após',
          },
          dateTimePicker: {
            selectStartDate: 'Selecione a data de início',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'ID do bate -papo',
        errorCode: 'Erro de código',
        errorMessage: 'Mensagem de erro',
        nA: 'N / D',
      },
      flows: {
        chatFlows: 'Fluxos de bate -papo',
        createChatFlow: 'Criar fluxo de bate -papo',
        youCanCreateMultipleChatFlows: 'Você pode criar vários fluxos de conversas em vários canais para testes e transmissões A/B.',
        flowName: 'Nome do fluxo',
        nameIsRequired: 'O nome é necessário',
        cancel: 'Cancelar',
        createFlow: 'Criar fluxo',
        importFlow: 'Fluxo de importação',
        createNewFlow: 'Criar novo fluxo',
        ofMessages: 'de mensagens',
        createdOn: 'Criado em',
        lastModified: 'Última modificação',
        defaultFlow: 'Fluxo padrão',
        action: 'Ação',
        exportStatus: 'Status de exportação',
        flowExport: 'Exportação de fluxo',
        download: 'Download',
        // importFlow: 'Fluxo de importação',
        beforeYouStartUploadPleaseMakeSure: 'Antes de iniciar o upload, certifique -se de',
        point1: '1. O limite máximo de upload do tamanho do arquivo é de 1 MB',
        point2: '2. O formato do arquivo deve estar em JSON',
        selectFile: 'Selecione o arquivo',
        inProgress: 'em andamento',
        available: 'Disponível',
        initiated: 'Iniciado',
        placeholders: {
          enterFlowName: 'Digite o nome do fluxo',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Fluxo de importação para o Facebook Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e hora da criação do fluxo de bate -papo',
          dateAndTimeWhenChatFlowLastModified: 'Data e hora em que o fluxo de bate -papo foi modificado pela última vez',
          TOOLTIP_CREATE_FLOW: 'Clique para criar um novo fluxo de bate -papo',
          TOOLTIP_FLOW_NAME: 'Digite o nome do fluxo de bate -papo',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome do fluxo de bate -papo',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensagem no fluxo de bate -papo',
          TOOLTIP_DEFAULT_FLOW: 'Use essa alternância para definir um fluxo de bate -papo como padrão, que será servido a todos os novos usuários.',
          TOOLTIP_ACTION: 'Editar ou excluir o fluxo de bate -papo',
        },
        noDataFound: {
          flows: 'Flui',
          noFlowsAddedYet: 'Nenhum fluxo adicionado ainda!',
        },
        modal: {
          deleteFlow: 'Excluir fluxo',
          wantToDeleteThisFlow: 'Tem certeza de que deseja excluir esse fluxo?',
        }
      },
      widgets: {
        manageWidgets: 'Gerenciar widgets',
        widgetsAreRoutingTools: 'Widgets são as ferramentas de roteamento para aumentar os usos do bot',
        needHelp: 'Preciso de ajuda?',
        wantToMakeChanges: 'Quer fazer mudanças?',
        editIcon: 'Editar ícone',
        discard: 'Descartar',
        updateWidget: 'Atualizar widget',
        installChatBotIconOnYourWebsite: 'Instale o ícone do chatbot no seu site',
        shareWhereEverYouWantToRedirectUsersTo: 'Compartilhe onde quiser e redirecionar usuários para o seu bot do Facebook em um único clique',
        copyAndAddInYourWebsiteHeaderFile: 'Copie e adicione o arquivo de cabeçalho do seu site',
        copyLinkAndStartSharing: 'Copie o link e comece a compartilhar!',
        downloadQrCodeAndStartSharing: 'Baixe o código QR e comece a compartilhar!',
        addCodeAndDeployNewCodeAndDone: 'Adicione o código, implante novo código e feito!',
        wantToLearnHowToUseIt: 'Quer aprender a usá -lo?',
        copyChatBotCode: 'Copie o código do chatbot',
        copyFacebookLink: 'Copie o link do Facebook',
        downloadQrCode: 'Baixe o código QR',
        link: 'Link',
        chatBotIcon: 'Ícone de chatbot',
        qrcode: 'Código QR',
        small: 'Pequeno',
        medium: 'Médio',
        large: 'Grande',
        leftMiddle: 'Middle esquerdo',
        rightMiddle: 'Middle direito',
        leftBottom: 'Inferior esquerdo',
        rightBottom: 'Inferior direito',
        bpDropDown: {
          selectIcons: 'Selecione ícones',
          iconSize: 'Tamanho do ícone',
          iconPlacement: 'Posicionamento do ícone',
        }
      },
      autoReply: {
        autoReplyTitle: 'Resposta Automática',
        configureYour: 'Configure a sua Mensagem de ',
        message: '',
        customizeAutoReplyMessagesForYourPostsComments: 'Personalize mensagens de resposta automática para comentários em suas postagens',
        wouldYouLikeToApply: 'Gostaria de aplicar a',
        autoreply: 'Resposta Automática',
        onAllPost: ' em todas as postagens?',
        configureYourMessageForEachPost: 'Configure a sua Mensagem para Cada Postagem',
        configureFlowToTriggerAfterOnReply: 'Configure o fluxo para ser acionado após a resposta',
        whenUserLeavesACommentSendMessageAfter: 'Quando o usuário deixa um comentário, envie a mensagem após',
        triggerFlowOnReply: 'Acionar Fluxo na Resposta',
        add: 'Adicionar',
        update: 'Atualizar',
        save: 'Salvar',
        cancel: 'Cancelar',
        discard: 'Descartar',
        bpDropDown: {
          selectPost: 'Selecione a Postagem',
          triggerFlowOnReply: 'Acionar Fluxo na Resposta',
          immendiately: 'Imediatamente',
          minutes: 'minutos'
        },
        bpTable: {
          message: 'Mensagem',
          selectedPost: 'Postagem selecionada',
          triggerFlow: 'Acionar Fluxo',
          actions: 'Ações'
        },
        placeholder: {
          configureYourMessage: 'Configure a sua mensagem'
        },
        deleteModel: {
          title: 'Excluir Resposta Automática da Postagem',
          description: 'Tem certeza de que deseja excluir esta resposta automática da postagem?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'Tempo Limite da Sessão',
        sendInactivityMessage: 'Enviar mensagem de inatividade',
        enterSessionTimeOutWindowInHours: 'Digite o período de tempo limite da sessão em horas',
        sessionTimeOutInHoursTooltip: `Digite a duração após a qual a sessão de chat será automaticamente encerrada devido à inatividade. O fluxo de chat será reiniciado se o usuário enviar qualquer mensagem após o tempo limite da sessão.`,
        restartChatFlowTriggers: 'Disparadores para Reiniciar o Fluxo de Chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Digite palavras-chave para reiniciar o chat e pressione Enter',
        restartChatFlowTriggersTooltip: `Especifique aqui palavras-chave que, quando inseridas pelo usuário e enviadas, acionarão o reinício do fluxo de chat. Isso permite que os usuários reiniciem o fluxo de conversa a qualquer momento.
         Pressione Enter após adicionar cada palavra-chave para adicionar várias palavras-chave.`,
        liveChatTriggers: 'Disparadores para o Chat Ao Vivo',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Digite palavras-chave para acionar o chat ao vivo e pressione Enter',
        liveChatTriggersTooltip: `Defina palavras-chave que, quando inseridas pelo usuário e enviadas, iniciarão uma transição do chat automatizado para o chat ao vivo. Isso é útil quando os usuários precisam de assistência de um agente em tempo real.
         Pressione Enter após adicionar cada palavra-chave para adicionar várias palavras-chave.`,
        messageAfterAutomatedFlowCompleted: 'Mensagem Após o Término do Fluxo Automatizado',
        enterYourMessage: 'Digite sua Mensagem',
        messageAfterAutomatedFlowCompletedTooltip: `Digite uma mensagem que será exibida ao usuário após ele ter concluído o fluxo de chat automatizado, mas a sessão não foi encerrada.`,
        restart: 'Reiniciar',
        liveChat: 'Chat Ao Vivo',
        lineOneForDefaultMessage: 'Se você tiver mais perguntas ou precisar de ajuda, aqui está o que você pode fazer:',
        to: 'Para',
        restartTheChat: 'reiniciar o chat',
        simpleTypeRestartAndHitEnter: `simplesmente digite 'Reiniciar' e pressione Enter. Isso o levará de volta ao início do nosso fluxo de conversa.`,
        lineThreeForDefaultMessagePartOne: `Se você desejar falar com um`,
        liveAgent: `agente ao vivo,`,
        lineThreeForDefaultMessageLastPart: `digite 'Chat Ao Vivo' e pressione Enter. Nossa equipe está aqui para ajudá-lo em tempo real.`,
        saveChanges: 'Salvar Alterações',
        discard: 'Descartar',
        configureBotsGeneralSettings: 'Configure as configurações gerais do seu bot',
        minutes: 'Minutos',
        hours: 'Horas',
      }
    },
    instagram: {
      instagramBotSettings: 'Configurações do BOT do Instagram',
      chatFlowsHeader: 'Fluxos de bate -papo',
      broadcastHeader: 'Transmissão',
      dripCampaignHeader: 'Campanha de gotejamento',
      widgetsHeader: 'Widgets',
      alertsHeader: 'Alertas',
      alerts: {
        configureYourBotsSettings: 'Definir as configurações de alerta do seu bot',
        specifyHowYourBotNotifyYou: 'Especifique como seu bot notificará você sobre novos leads.',
        subject: 'Assunto',
        emailNotification: 'Notificação de Email',
        emailAddresses: 'Endereço de e-mail',
        leadRevisitNotifications: 'Notificações de revisitar liderar',
        whenToSendLeadInfo: 'Quando enviar informações de lead',
        discard: 'Descartar',
        saveChanges: 'Salvar alterações',
        placeholders: {
          enterSubjectForYourEmail: 'Insira o assunto para o seu e -mail',
          typeYourEmailAddressAndHitEnter: 'Digite seu endereço de e -mail e pressione Enter',
        }
      },
      broadcasts: {
        createBroadcast: 'Crie transmissão',
        youCanCreateMultipleBroadcasts: 'Você pode criar várias transmissões',
        broadcastName: 'Nome da transmissão',
        broadcastNameIsRequired: 'O nome de transmissão é necessário',
        continue: 'Continuar',
        manageBroadcasts: 'Gerenciar transmissões',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Você pode criar várias transmissões com vários modelos agendados para diferentes Grupos de clientes',
        createNewBroadcast: 'Crie uma nova transmissão',
        segment: 'Grupo',
        template: 'Modelo',
        contacts: 'Contatos',
        send: 'Enviar',
        failed: 'Fracassado',
        broadcastAt: 'Transmissão em',
        status: 'Status',
        actions: 'Ações',
        nA: 'N / D',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          searchHere: 'Procure aqui',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Exclua várias transmissões',
          deleteBroadcast: 'Exclua transmissão',
        }
      },
      createBroadcast: {
        broadcastName: 'Nome da transmissão',
        broadcastNameIsRequired: 'O nome de transmissão é necessário',
        flow: 'Fluxo',
        segments: 'Grupos',
        whenToSend: 'Quando enviar',
        selectDate: 'Selecione a data',
        selectTimeSlot: 'Selecione o slot de tempo',
        cancel: 'Cancelar',
        createBroadcast: 'Crie transmissão',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          selectFlow: 'Selecione Fluxo',
          searchFlowByName: 'Fluxo de pesquisa por nome',
          selectSegments: 'Selecione Grupos',
          searchSegmentByName: 'Grupo de pesquisa por nome',
          selectTimeSlot: 'Selecione o slot de tempo',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecione a data de início',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crie campanha de gotejamento',
        youCanCreateMultipleCampaigns: 'Você pode criar várias campanhas',
        dripCampaignName: 'Nome da campanha de gotejamento',
        dripCampaignNameIsRequired: 'O nome da campanha de gotejamento é necessário',
        continue: 'Continuar',
        manageDripCampaign: 'Gerenciar campanha de gotejamento',
        youCanCreateMultipleCampaignsWith: 'Você pode criar várias campanhas de gotejamento com mensagens automatizadas programadas para acionar respostas de Grupo de usuários específicos',
        createNewDripCampaign: 'Crie uma nova campanha de gotejamento',
        campaignName: 'Nome da campanha',
        appliedOn: 'Aplicado em',
        segment: 'Grupo',
        flow: 'Fluxo',
        startDate: 'Data de início',
        action: 'Ação',
        nA: 'N / D',
        placeholders: {
          enterDripCampaignName: 'Digite o nome da campanha de gotejamento',
          searchByName: 'Procura por nome',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Exclua várias campanhas de gotejamento',
          deleteDripCampaign: 'Excluir campanha de gotejamento',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Nome da campanha de gotejamento',
          appliedOn: 'Aplicado em',
          segments: 'Grupos',
          selectDate: 'Selecione a data',
          scheduleYourFlows: 'Agende seus fluxos',
          flow: 'Fluxo',
          sendAfter: 'Enviar depois',
          cancel: 'Cancelar',
          createDripCampaign: 'Crie campanha de gotejamento',
          placeholders: {
            enterDripName: 'Digite o nome do gotejamento',
            selectAppliedOn: 'Selecione aplicado em',
            selectSegment: 'Selecione Grupo',
            searchByName: 'Procura por nome',
            selectTemplate: 'Selecione modelo',
            searchFlowByName: 'Fluxo de pesquisa por nome',
            enterSendAfter: 'Digite send após',
          },
          dateTimePicker: {
            selectStartDate: 'Selecione a data de início',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'ID do bate -papo',
        errorCode: 'Erro de código',
        errorMessage: 'Mensagem de erro',
        nA: 'N / D',
      },
      flows: {
        chatFlows: 'Fluxos de bate -papo',
        createChatFlow: 'Criar fluxo de bate -papo',
        youCanCreateMultipleChatFlows: 'Você pode criar vários fluxos de conversas em vários canais para testes e transmissões A/B.',
        flowName: 'Nome do fluxo',
        nameIsRequired: 'O nome é necessário',
        cancel: 'Cancelar',
        createFlow: 'Criar fluxo',
        importFlow: 'Fluxo de importação',
        createNewFlow: 'Criar novo fluxo',
        // flowName: 'Nome do fluxo',
        ofMessages: ' de mensagens',
        createdOn: 'Criado em',
        lastModified: 'Última modificação',
        defaultFlow: 'Fluxo padrão',
        action: 'Ação',
        exportStatus: 'Status de exportação',
        flowExport: 'Exportação de fluxo',
        download: 'Download',
        // importFlow: 'Fluxo de importação',
        beforeYouStartUploadPleaseMakeSure: 'Antes de iniciar o upload, certifique -se de',
        point1: '1. O limite máximo de upload do tamanho do arquivo é de 1 MB',
        point2: '2. O formato do arquivo deve estar em JSON',
        selectFile: 'Selecione o arquivo',
        placeholders: {
          enterFlowName: 'Digite o nome do fluxo',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Fluxo de importação para o Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e hora da criação do fluxo de bate -papo',
          dateAndTimeWhenChatFlowLastModified: 'Data e hora em que o fluxo de bate -papo foi modificado pela última vez',
        },
        noDataFound: {
          flows: 'Flui',
          noFlowsAddedYet: 'Nenhum fluxo adicionado ainda!',
        },
        modal: {
          deleteFlow: 'Excluir fluxo',
          wantToDeleteThisFlow: 'Tem certeza de que deseja excluir esse fluxo?',
        }
      },
      widgets: {
        manageWidgets: 'Gerenciar widgets',
        widgetsAreRoutingTools: 'Widgets são as ferramentas de roteamento para aumentar os usos do bot',
        needHelp: 'Preciso de ajuda?',
        wantToMakeChanges: 'Quer fazer mudanças?',
        editIcon: 'Editar ícone',
        discard: 'Descartar',
        updateWidget: 'Atualizar widget',
        bpDropDown: {
          selectIcons: 'Selecione ícones',
          iconSize: 'Tamanho do ícone',
          iconPlacement: 'Posicionamento do ícone',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Configurações do Bot Telegram',
      chatFlowsHeader: 'Fluxos de bate -papo',
      telegramProfileHeader: 'Perfil de telegrama',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Transmissão',
      dripCampaignHeader: 'Campanha de gotejamento',
      generalHeader: 'Geral',
      telegramAutomation: 'Automação do Telegram',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Crie uma jornada de integração de clientes usando uma campanha de gotejamento de fluxos de bate -papo programados.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalize os modelos de fluxo de bate -papo e crie vários fluxos de bate -papo para diferentes públicos -alvo.',
      },
      broadcasts: {
        createBroadcast: 'Crie transmissão',
        youCanCreateMutlipleBroadcasts: 'Você pode criar várias transmissões',
        broadcastName: 'Nome da transmissão',
        broadcastNameIsRequired: 'O nome de transmissão é necessário',
        continue: 'Continuar',
        manageBroadcasts: 'Gerenciar transmissões',
        youCanCreateMultipleBroadcastsWith: 'Você pode criar várias transmissões com vários modelos agendados para diferentes Grupos de clientes.',
        createNewBroadcast: 'Crie uma nova transmissão',
        name: 'Nome',
        segment: 'Grupo',
        template: 'Modelo',
        contacts: 'Contatos',
        send: 'Enviar',
        failed: 'Fracassado',
        broadcastAt: 'Transmissão em',
        status: 'Status',
        actions: 'Ações',
        nA: 'N / D',
        deleteBroadcast: 'Exclua transmissão',
        wantToDeleteThisBroadcast: 'Tem certeza de que deseja excluir esta transmissão?',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          searchHere: 'Procure aqui',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Exclua várias transmissões',
          deleteBroadcast: 'Exclua transmissão',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'Nome da transmissão',
        broadcastNameIsRequired: 'O nome de transmissão é necessário',
        flow: 'Fluxo',
        segments: 'Grupo',
        whenToSend: 'Quando enviar',
        selectDate: 'Selecione a data',
        selectTimeSlot: 'Selecione o slot de tempo',
        cancel: 'Cancelar',
        createBroadcast: 'Crie transmissão',
        now: 'Agora',
        later: 'Mais tarde',
        placeholders: {
          enterBroadcastName: 'Digite o nome da transmissão',
          selectFlow: 'Selecione Fluxo',
          searchFlowByName: 'Fluxo de pesquisa por nome',
          selectSegments: 'Selecione Grupos',
          searchSegmentByName: 'Grupo de pesquisa por nome',
          selectTimeSlot: 'Selecione o slot de tempo',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecione a data de início',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crie campanha de gotejamento',
        youCanCreateMultipleCampaigns: 'Você pode criar várias campanhas',
        dripCampaignName: 'Nome da campanha de gotejamento',
        dripCampaignNameIsRequired: 'O nome da campanha de gotejamento é necessário',
        continue: 'Continuar',
        manageDripCampaign: 'Gerenciar campanha de gotejamento',
        youCanCreateMultipleDripCampaignsWith: 'Você pode criar várias campanhas de gotejamento com mensagens automatizadas programadas para acionar respostas de Grupo de usuários específicos.',
        createNewDripCampaign: 'Crie uma nova campanha de gotejamento',
        campaignName: 'Nome da campanha',
        appliedOn: 'Aplicado em',
        segment: 'Grupo',
        flow: 'Fluxo',
        startDate: 'Data de início',
        action: 'Ação',
        nA: 'N / D',
        deleteDripCampaign: 'Exclua campanhas de gotejamento',
        wantToDeleteThisDripCampaign: 'Tem certeza de que deseja excluir essas campanhas de gotejamento?',
        placeholders: {
          enterDripCampaignName: 'Digite o nome da campanha de gotejamento',
          searchByName: 'Procura por nome',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Exclua várias campanhas de gotejamento',
          deleteDripCampaign: 'Excluir campanha de gotejamento',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Nome da campanha de gotejamento',
          appliedOn: 'Aplicado em',
          segments: 'Grupos',
          startDate: 'Selecione a data',
          scheduleYourFlows: 'Agende seus fluxos',
          schedule: 'Agendar',
          flow: 'Fluxo',
          sendAfter: 'Enviar depois',
          cancel: 'Cancelar',
          createDripCampaign: 'Crie campanha de gotejamento',
          newSubscriber: 'Novo assinante',
          days: 'Dias',
          minutes: 'Minutos',
          hours: 'Horas',
          placeholders: {
            enterDripName: 'Digite o nome do gotejamento',
            selectAppliedOn: 'Selecione aplicado em',
            selectSegment: 'Selecione Grupo',
            searchByName: 'Procura por nome',
            selectFlow: 'Selecione Fluxo',
            searchFlowByName: 'Fluxo de pesquisa por nome',
          },
          dateTimePicker: {
            selectStartDate: 'Selecione a data de início',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Nome',
        email: 'E-mail',
        phone: 'Telefone',
        errorCode: 'Erro de código',
        errorMessage: 'Mensagem de erro',
        nA: 'N / D',
      },
      flows: {
        chatFlows: 'Fluxos de bate -papo',
        createChatFlow: 'Criar fluxo de bate -papo',
        youCanCreateMultipleChatFlows: 'Você pode criar vários fluxos de conversas em vários canais para testes e transmissões A/B.',
        flowName: 'Nome do fluxo',
        nameIsRequired: 'O nome é necessário',
        cancel: 'Cancelar',
        createFlow: 'Criar fluxo',
        importFlow: 'Fluxo de importação',
        createNewFlow: 'Criar novo fluxo',
        // flowName: 'Nome do fluxo',
        ofMessages: ' de mensagens',
        createdOn: 'Criado em',
        lastModified: 'Última modificação',
        defaultFlow: 'Fluxo padrão',
        action: 'Ação',
        exportStatus: 'Status de exportação',
        flowExport: 'Exportação de fluxo',
        inProgress: 'em andamento',
        available: 'Disponível',
        initiated: 'Iniciado',
        download: 'Download',
        // importFlow: 'Fluxo de importação',
        beforeYouStartUploadPleaseMakeSure: 'Antes de iniciar o upload, certifique -se de',
        point1: '1. O limite máximo de upload do tamanho do arquivo é de 1 MB',
        point2: '2. O formato do arquivo deve estar em JSON',
        selectFile: 'Selecione o arquivo',
        placeholders: {
          enterFlowName: 'Digite o nome do fluxo',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Fluxo de importação para o Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e hora da criação do fluxo de bate -papo',
          dateAndTimeWhenChatFlowLastModified: 'Data e hora em que o fluxo de bate -papo foi modificado pela última vez',
          TOOLTIP_CREATE_FLOW: 'Clique para criar um novo fluxo de bate -papo',
          TOOLTIP_FLOW_NAME: 'Digite o nome do fluxo de bate -papo',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome do fluxo de bate -papo',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensagem no fluxo de bate -papo',
          TOOLTIP_DEFAULT_FLOW: 'Use essa alternância para definir um fluxo de bate -papo como padrão, que será servido a todos os novos usuários.',
          TOOLTIP_ACTION: 'Editar ou excluir o fluxo de bate -papo',
        },
        noDataFound: {
          flows: 'Flui',
          noFlowsAddedYet: 'Nenhum fluxo adicionado ainda!',
        },
        modal: {
          deleteFlow: 'Excluir fluxo',
          wantToDeleteThisFlow: 'Tem certeza de que deseja excluir esse fluxo?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Perfil de telegrama',
        manageYourBotProfile: 'Gerencie seu perfil de bot como e quando quiser.',
        allSetNoActionRequired: 'Todo o conjunto, nenhuma ação necessária!',
        congratulationYourAccountIsLive: 'Parabéns! Sua conta está ao vivo com acesso total',
        botDetails: 'Detalhes do bot',
        name: 'Nome',
        nA: 'N / D',
        b: 'B',
        botName: 'Nome do bot',
        bot: 'Robô',
        info: 'Info',
        none: 'Nenhum',
        username: 'Nome de usuário',
        notifications: 'Notificações',
        on: 'Sobre',
        addToGroupOrChannel: 'Adicionar ao grupo ou canal',
        userName: 'Nome de usuário',
        botToken: 'Token de bot',
      },
      widgets: {
        manageWidgets: 'Gerenciar widgets',
        widgetsAreRoutingTools: 'Widgets são as ferramentas de roteamento para aumentar os usos do bot',
        needHelp: 'Preciso de ajuda?',
        wantToMakeChanges: 'Quer fazer mudanças?',
        editIcon: 'Editar ícone',
        discard: 'Descartar',
        updateWidget: 'Atualizar widget',
        installChatBotIconOnYourWebsite: 'Instale o ícone do chatbot no seu site',
        shareWhereEverYouWantRedirectUsersTo: 'Compartilhe onde quiser e redirecionar usuários para o seu bot de telegrama em um único clique',
        copyAndAddInYourWebsiteHeaderFile: 'Copie e adicione o arquivo de cabeçalho do seu site',
        copyLinkAndStartSharing: 'Copie o link e comece a compartilhar!',
        downloadQrCodeAndStartSharing: 'Baixe o código QR e comece a compartilhar!',
        addCodeAndDeployNewCodeAndDone: 'Adicione o código, implante novo código e feito!',
        wantToLearnHowToUseIt: 'Quer aprender a usá -lo?',
        copyChatBotCode: 'Copie o código do chatbot',
        downloadQrCode: 'Baixe o código QR',
        copyTelegramLink: 'Copie o link do telegrama',
        link: 'Link',
        chatBotIcon: 'Ícone de chatbot',
        qrcode: 'Código QR',
        small: 'Pequeno',
        medium: 'Médio',
        large: 'Grande',
        leftMiddle: 'Middle esquerdo',
        rightMiddle: 'Middle direito',
        leftBottom: 'Inferior esquerdo',
        rightBottom: 'Inferior direito',
        bpDropDown: {
          selectIcons: 'Selecione ícones',
          iconSize: 'Tamanho do ícone',
          iconPlacement: 'Posicionamento do ícone',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Tempo Limite da Sessão',
        sendInactivityMessage: 'Enviar mensagem de inatividade',
        enterSessionTimeOutWindowInHours: 'Digite o período de tempo limite da sessão em horas',
        sessionTimeOutInHoursTooltip: `Digite a duração após a qual a sessão de chat será automaticamente encerrada devido à inatividade. O fluxo de chat será reiniciado se o usuário enviar qualquer mensagem após o tempo limite da sessão.`,
        restartChatFlowTriggers: 'Disparadores para Reiniciar o Fluxo de Chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Digite palavras-chave para reiniciar o chat e pressione Enter',
        restartChatFlowTriggersTooltip: `Especifique aqui palavras-chave que, quando inseridas pelo usuário e enviadas, acionarão o reinício do fluxo de chat. Isso permite que os usuários reiniciem o fluxo de conversa a qualquer momento.
         Pressione Enter após adicionar cada palavra-chave para adicionar várias palavras-chave.`,
        liveChatTriggers: 'Disparadores para o Chat Ao Vivo',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Digite palavras-chave para acionar o chat ao vivo e pressione Enter',
        liveChatTriggersTooltip: `Defina palavras-chave que, quando inseridas pelo usuário e enviadas, iniciarão uma transição do chat automatizado para o chat ao vivo. Isso é útil quando os usuários precisam de assistência de um agente em tempo real.
         Pressione Enter após adicionar cada palavra-chave para adicionar várias palavras-chave.`,
        messageAfterAutomatedFlowCompleted: 'Mensagem Após o Término do Fluxo Automatizado',
        enterYourMessage: 'Digite sua Mensagem',
        messageAfterAutomatedFlowCompletedTooltip: `Digite uma mensagem que será exibida ao usuário após ele ter concluído o fluxo de chat automatizado, mas a sessão não foi encerrada.`,
        restart: 'Reiniciar',
        liveChat: 'Chat Ao Vivo',
        lineOneForDefaultMessage: 'Se você tiver mais perguntas ou precisar de ajuda, aqui está o que você pode fazer:',
        to: 'Para',
        restartTheChat: 'reiniciar o chat',
        simpleTypeRestartAndHitEnter: `simplesmente digite 'Reiniciar' e pressione Enter. Isso o levará de volta ao início do nosso fluxo de conversa.`,
        lineThreeForDefaultMessagePartOne: `Se você desejar falar com um`,
        liveAgent: `agente ao vivo,`,
        lineThreeForDefaultMessageLastPart: `digite 'Chat Ao Vivo' e pressione Enter. Nossa equipe está aqui para ajudá-lo em tempo real.`,
        saveChanges: 'Salvar Alterações',
        discard: 'Descartar',
        configureBotsGeneralSettings: 'Configure as configurações gerais do seu bot',
        minutes: 'Minutos',
        hours: 'Horas',
      }
    },
    whatsapp: {
      whatsappAutomation: 'Automação do WhatsApp',
      whatsappBotSettings: 'Whatsapp Bot Configurações',
      chatFlowsHeader: 'Fluxos de bate -papo',
      whatsappProfileHeader: 'Perfil do WhatsApp',
      broadcastHeader: 'Transmissão',
      dripCampaignHeader: 'Campanha de gotejamento',
      widgetHeader: 'Ferramenta',
      templatesHeader: 'Modelos',
      alertsHeader: 'Alertas',
      apiKeyHeader: 'Chave API',
      catalogHeader: 'Catálogo',
      generalHeader: 'Geral',
      otherConfiguration: 'Configurações de IA',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalize os modelos de fluxo de bate -papo e crie vários fluxos de bate -papo para diferentes públicos -alvo.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Definir as configurações de alerta do seu bot',
        specifyHowYouBotWillNotify: 'Especifique como seu bot notificará você sobre novos leads.',
        subject: 'Assunto',
        emailNotification: 'Notificação de Email',
        emailAddresses: 'Endereço de e-mail',
        leadRevisitNotifications: 'Notificações de revisitar liderar',
        whenToSendLeadInfo: 'Quando enviar informações de lead',
        discard: 'Descartar',
        saveChanges: 'Salvar alterações',
        seeHowToUse: 'Veja como usar',
        beginningTheFlow: 'Começando o fluxo',
        fiveMinutesLater: '5 minutos depois',
        endOfFlow: 'Fim do fluxo',
        placeholders: {
          enterSubjectForYourEmail: 'Insira o assunto para o seu e -mail',
          typeEmailAndHitEnter: 'Digite seu endereço de e -mail e pressione Enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Personalize o assunto de suas notificações de e -mail do bot',
          TOOLTIP_EMAIL_NOTIFICATION: 'Ativar/desativar a notificação do bot em seu e -mail para novos leads',
          TOOLTIP_EMAIL_ADDRESS: 'Envie o endereço de email da conta, onde deseja receber notificações por email. Você pode adicionar vários endereços de email digitando um e pressionando Enter para adicionar outro.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Ativar/desativar a notificação do BOT em seu email se um usuário capturado anteriormente como um lead interage com o bot novamente.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Atualize o whatsapp chatbot',
        businessNameIsRequired: 'O nome comercial é necessário.',
        maxLengthForBusinessName40Characters: 'O comprimento máximo para o nome comercial é de 40 caracteres.',
        categoryIsRequired: 'Categoria é necessária.',
        descriptionIsRequired: 'Descrição é necessária.',
        maxLengthForDescription200Characters: 'O comprimento máximo para descrição é de 200 caracteres.',
        aboutIsRequired: 'Sobre é necessário.',
        maxLengthForAbout139Characters: 'O comprimento máximo para cerca de 139 caracteres.',
        pleaseFillAValidBusinessEmail: 'Por favor, preencha um e -mail comercial válido.',
        businessEmailIsRequired: 'O email comercial é necessário.',
        websiteIsRequired: 'O site é necessário.',
        maxLengthForWebsite40Characters: 'O comprimento máximo para o site é de 40 caracteres.',
        maxLengthForAddress150Characters: 'O comprimento máximo para o endereço é de 150 caracteres.',
        cancel: 'Cancelar',
        update: 'Atualizar',
        businessCategory: 'categoria de negócio',
        placeholders: {
          yourBusinessName: 'Seu nome comercial',
          businessDescription: 'descrição do negócio',
          about: 'Sobre',
          businessEmail: 'Email Comercial',
          website: 'Site (por exemplo, www.example.com)',
          address: 'Endereço',
        }
      },
      flows: {
        chatFlows: 'Fluxos de bate -papo',
        createChatFlow: 'Criar fluxo de bate -papo',
        youCanCreateMultipleChatFlows: 'Você pode criar vários fluxos de conversas em vários canais para testes e transmissões A/B.',
        flowName: 'Nome do fluxo',
        nameIsRequired: 'O nome é necessário',
        cancel: 'Cancelar',
        createFlow: 'Criar fluxo',
        importFlow: 'Fluxo de importação',
        createNewFlow: 'Criar novo fluxo',
        ofMessages: ' de mensagens',
        createdOn: 'Criado em',
        lastModified: 'Última modificação',
        defaultFlow: 'Fluxo padrão',
        revisitFlow: 'Revisitar o fluxo',
        action: 'Ação',
        exportStatus: 'Status de exportação',
        flowExport: 'Exportação de fluxo',
        // importFlow: 'Fluxo de importação',
        beforeYouStartUploadPleaseMakeSure: 'Antes de iniciar o upload, certifique -se de',
        point1: '1. O limite máximo de upload do tamanho do arquivo é de 1 MB',
        point2: '2. O formato do arquivo deve estar em JSON',
        selectFile: 'Selecione o arquivo',
        seeHowToUse: 'Veja como usar',
        download: 'Download',
        available: 'Disponível',
        initiated: 'Iniciado',
        inProgress: 'em andamento',
        placeholders: {
          enterFlowName: 'Digite o nome do fluxo',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Fluxo de importação para Whatsapp Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e hora da criação do fluxo de bate -papo',
          dateAndTimeWhenChatFlowLastModified: 'Data e hora em que o fluxo de bate -papo foi modificado pela última vez',
          TOOLTIP_CREATE_FLOW: 'Clique para criar um novo fluxo de bate -papo',
          TOOLTIP_FLOW_NAME: 'Digite o nome do fluxo de bate -papo',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome do fluxo de bate -papo',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensagem no fluxo de bate -papo',
          TOOLTIP_DEFAULT_FLOW: 'Use essa alternância para definir um fluxo de bate -papo como padrão, que será servido a todos os novos usuários.',
          TOOLTIP_REVISIT_FLOW: 'Use essa alternância para selecionar um fluxo de bate -papo para ser servido aos usuários quando eles revisitarem seu chatbot.',
          TOOLTIP_ACTION: 'Editar ou excluir o fluxo de bate -papo',
        },
        modal: {
          deleteFlow: 'Excluir fluxo',
          wantToDeleteThisFlow: 'Tem certeza de que deseja excluir esse fluxo?',
        },
        noDataFound: {
          flows: 'Flui',
          noFlowsAddedYet: 'Nenhum fluxo adicionado ainda!',
        }
      },
      input: {
        manageTemplates: 'Gerenciar modelos',
        manageYourTemplatesForMarketingAutomation: 'Gerencie seus modelos para a automação de marketing',
        syncTemplates: 'Modelos de sincronização',
        createTemplateHeader: 'Criar modelo',
        title: 'Título',
        info: 'Info',
        category: 'Categoria',
        status: 'Status',
        action: 'Ação',
        shipmentUpdate: 'Atualização de remessa',
        name: 'Nome',
        // category: 'Categoria',
        headerOptional: 'Cabeçalho (opcional)',
        bodyRequired: 'Corpo (necessário)',
        footerOptional: 'Footeiro (opcional)',
        buttonsOptional: 'Botões (opcionais)',
        makeUpto3Buttons: 'Compre até 3 botões',
        makeUpto2ButtonsToRedirect: 'Compre até 2 botões para redirecionar o cliente para o seu site',
        placeholders: {
          nameYourTemplate: 'Nomeie seu modelo (use sublinhado para separar palavras)',
          enterText: 'Digite o texto (máximo 60 caracteres)',
          enterImageUrl: 'Digite o URL da imagem',
          enterVideoUrl: 'Digite URL de vídeo',
          templateMessage: 'Mensagem de modelo',
          smallFooterAtBottom: 'Rodapé pequeno no fundo',
          buttons: 'Botões',
          text: 'Texto',
          numberWithCountryCode: 'Número com código de país',
          enterButtonText: 'Digite o texto do botão (caractere máximo 20)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Título do modelo do WhatsApp',
          TOOLTIP_INPUT_TABLE_INFO: 'Informações detalhadas das mensagens iniciadas por negócios',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Tipo de mensagens iniciadas por negócios, como compromissos, lembretes, mensagens de atendimento ao cliente, atualizações de remessa, alertas e muito mais',
          TOOLTIP_INPUT_TABLE_STATUS: 'O status do modelo é rejeitado ou aprovado pelo WhatsApp. Os modelos do WhatsApp precisam ser aprovados pela primeira vez pelo WhatsApp e este é um serviço pago.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Exclua o modelo',
        },
        noDataFound: {
          templates: 'Modelos',
          noTemplatesCreatedYet: 'Não há modelos criados ainda!',
        },
        modal: {
          deleteTemplate: 'Excluir modelo',
          wantToDeleteThisTemplate: 'Tem certeza de que deseja excluir este modelo?',
        },
        createTemplate: {
          createTemplates: 'Criar modelos',
          createTemplatesForMarketingAutomation: 'Crie seus modelos para a automação de marketing',
          templateMeta: 'META META',
          selectLanguage: 'Selecione o idioma',
          header: 'Cabeçalho',
          optional: 'Opcional',
          samplesForHeaderContent: 'Amostras para conteúdo de cabeçalho',
          toHelpMetaReviewYourContent: 'Para ajudar a meta revisar seu conteúdo, forneça exemplos de variáveis ​​ou mídias no cabeçalho. Não inclua nenhuma informação do cliente.',
          body: 'Corpo',
          addVariable: 'Adicione variável',
          samplesForBodyContent: 'Amostras para conteúdo corporal',
          toHelpUsReviewYourContent: 'Para nos ajudar a revisar seu conteúdo, forneça exemplos das variáveis ​​no corpo. Não inclua nenhuma informação do cliente.',
          footer: 'Rodapé',
          // optional: 'Opcional',
          buttons: 'Botões',
          // optional: 'Opcional',
          typeOfAction: 'Tipo de ação',
          call: 'Chamar',
          phone: 'Telefone',
          website: 'Local na rede Internet',
          static: 'Estático',
          urlType: 'Tipo de URL',
          type: 'Tipo',
          customButton: 'Botão personalizado',
          addAnotherButton: 'Adicione outro botão',
          preview: 'Visualização',
          test: 'Teste',
          create: 'Criar',
          addSampleUrl: 'Adicione o URL da amostra',
          toHelpMetaReviewYourMessage: 'Para ajudar a meta revisar seu modelo de mensagem, adicione um exemplo do URL do site. Não use informações reais do cliente.',
          placeholders: {
            enterMessageTemplateName: 'Insira o nome do modelo de mensagem',
            searchTemplateByName: 'Modelo de pesquisa por nome',
            selectTemplate: 'Selecione modelo',
            enterText: 'Digite o texto',
            enterBody: 'Entre no corpo',
            enterContentForVariable: 'Insira o conteúdo da variável',
            enterFooter: 'Digite rodapé',
            phone: 'Telefone*',
            enterWebsiteUrl: 'Digite URL do site',
          },
          bpInput: {
            name: 'Nome',
            buttonText: 'Botão de texto',
            websiteUrl: 'URL do site',
          },
          bpDropDown: {
            selectCategory: 'Selecione a Categoria',
          }
        },
        templatePreview: {
          preview: 'Visualização',
          test: 'Teste',
        },
        templateConfig: {
          discard: 'Descartar',
          saveChanges: 'Salvar alterações',
          triggerChatFlowOnTemplateReply: 'Ativar Fluxo de Chat na Resposta do Modelo',
          templateDetails: 'Detalhes do Modelo',
          configureFlows: 'Configurar Fluxos',
          selectFlow: 'Selecionar Fluxo',
          name: 'Nome'
        }
      },
      shopManagement: {
        shopManagement: 'Gestao de lojas',
        manageECommerceShopFromBotPenguin: 'Gerenciar lojas de comércio eletrônico conectadas com BotPenguin',
        comingSoon: '( Em breve )',
        wooCommerce: {
          createConfiguration: 'Crie configuração',
          eventConfiguration: {
            createConfiguration: 'Crie configuração',
            templateHeader: 'Cabeçalho de modelo',
            selectFile: 'Selecione o arquivo',
            templateMessage: 'Mensagem de modelo',
            saveChanges: 'Salvar alterações',
            bpDropDown: {
              selectEvent: 'Selecione o evento',
              selectTemplate: 'Selecione modelo',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'Perfil do WhatsApp',
        manageYourBotProfile: 'Gerencie seu perfil de bot como e quando quiser.',
        allSetNoActionRequired: 'Todo o conjunto, nenhuma ação necessária!',
        congratulationsYourAccountIsLive: 'Parabéns! Sua conta está ao vivo com acesso total',
        somethingIsWrongReactivateYourBot: 'Algo está errado, reative seu bot!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Parece que seu bot não está funcionando corretamente, redefina seu bot',
        reConfigureYourBot: 'Configure novamente seu bot',
        phoneNumberDetails: 'Detalhes do número de telefone',
        phoneNumber: 'Número de telefone',
        nA: 'N / D',
        phoneNumberId: 'ID do número de telefone',
        wabaId: 'WABA ID',
        change: 'Mudar',
        accessToken: 'Acesso token',
        // change: 'Mudar',
        messagingLimit: 'Limite de mensagens',
        qualityRating: 'Classificação de qualidade',
        whatsappBusinessAccountName: 'Nome da conta comercial do WhatsApp',
        whatsappPublicProfile: 'Whatsapp Public Profile',
        profilePicture: 'Foto do perfil',
        aboutText: 'Sobre texto',
        address: 'Endereço',
        description: 'Descrição',
        email: 'E-mail',
        websitePrimary: 'Site (primário)',
        websiteSecondary: 'Site (secundário)',
        businessVertical: 'Business Vertical',
        editProfile: 'Editar Perfil',
        thisIsABusinessAccount: 'Esta é uma conta comercial',
        aboutAndPhoneNumber: 'Sobre e número de telefone',
        yourTrialAccountIsLive: 'Parabéns! Sua conta de avaliação está ativa com acesso total em seu número verificado',
        updateWithNewAccessToken: 'Atualizar com o novo AccessToken',
      },
      widgets: {
        manageWidgets: 'Gerenciar widgets',
        widgetsAreRoutingTools: 'Widgets são as ferramentas de roteamento para aumentar os usos do bot',
        needHelp: 'Preciso de ajuda?',
        wantToMakeChanges: 'Quer fazer mudanças?',
        prePopulatedMessage: 'Mensagem pré-populada',
        discard: 'Descartar',
        updateWidget: 'Atualizar widget',
        hiThere: 'olá',
        seeHowToUse: 'Veja como usar',
        editIcon: 'Editar ícone',
        editPrePopulatedMessage: 'Editar mensagem pré-populada',
        small: 'Pequeno',
        medium: 'Médio',
        large: 'Grande',
        leftMiddle: 'Middle esquerdo',
        rightMiddle: 'Middle direito',
        leftBottom: 'Inferior esquerdo',
        rightBottom: 'Inferior direito',
        chatBotIcon: 'Ícone de chatbot',
        link: 'Link',
        qrcode: 'Código QR',
        copyChatBotCode: 'Copie o código do chatbot',
        copyWALink: 'Copie o link WA',
        downloadQrCode: 'Baixe o código QR',
        installChatBotIconOnYourWebsite: 'Instale o ícone do chatbot no seu site',
        shareWhereEverYouWantRedirectUsersTo: 'Compartilhe onde quiser e redirecionar usuários para o seu bot WhatsApp em um único clique',
        copyAndAddInYourWebsiteHeaderFile: 'Copie e adicione o arquivo de cabeçalho do seu site',
        copyLinkAndStartSharing: 'Copie o link e comece a compartilhar!',
        downloadQrCodeAndStartSharing: 'Baixe o código QR e comece a compartilhar!',
        addCodeAndDeployNewCodeAndDone: 'Adicione o código, implante novo código e feito!',
        wantToLearnHowToUseIt: 'Quer aprender a usá -lo?',
        placeholders: {
          enterWhatsappNumber: 'Digite o número do WhatsApp',
          updateYourMessageHere: 'Atualize sua mensagem aqui ...',
        },
        bpDropDown: {
          selectIcons: 'Selecione ícones',
          iconSize: 'Tamanho do ícone',
          iconPlacement: 'Posicionamento do ícone',
        },
        bpInput: {
          whatsappNumber: 'Número do WhatsApp',
        }
      },
      catalog: {
        createCatalog: 'Criar Catálogo',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Criar catálogo de produtos no Gerenciador de Comércio Meta ou vinculá-lo ao Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Usar o seu Gerenciador de Comércio Meta para gerenciar catálogos',
        connectCatalogToWhatsAppAccount: 'Conectar catálogo à conta do WhatsApp',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Conectar seus catálogos à conta do WhatsApp usando o seu gerenciador do WhatsApp',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Usar o seu Gerenciador do WhatsApp Meta para conectar catálogos e usá-los com o BotPenguin',
        goToMeta: 'Ir para a Meta',
        goToShopify: 'Ir para o Shopify',
        next: 'Próximo',
        back: 'Voltar',
        setupCatalog: 'Configurar Catálogo',
        completeAllTheStepsToEnableCatalogMessages: 'Complete todos os passos para habilitar mensagens de catálogo'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Tempo Limite da Sessão',
        sendInactivityMessage: 'Enviar mensagem de inatividade',
        enterSessionTimeOutWindowInHours: 'Digite o período de tempo limite da sessão em horas',
        sessionTimeOutInHoursTooltip: `Digite a duração após a qual a sessão de chat será automaticamente encerrada devido à inatividade. O fluxo de chat será reiniciado se o usuário enviar qualquer mensagem após o tempo limite da sessão.`,
        restartChatFlowTriggers: 'Disparadores para Reiniciar o Fluxo de Chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Digite palavras-chave para reiniciar o chat e pressione Enter',
        restartChatFlowTriggersTooltip: `Especifique aqui palavras-chave que, quando inseridas pelo usuário e enviadas, acionarão o reinício do fluxo de chat. Isso permite que os usuários reiniciem o fluxo de conversa a qualquer momento.
         Pressione Enter após adicionar cada palavra-chave para adicionar várias palavras-chave.`,
        liveChatTriggers: 'Disparadores para o Chat Ao Vivo',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Digite palavras-chave para acionar o chat ao vivo e pressione Enter',
        liveChatTriggersTooltip: `Defina palavras-chave que, quando inseridas pelo usuário e enviadas, iniciarão uma transição do chat automatizado para o chat ao vivo. Isso é útil quando os usuários precisam de assistência de um agente em tempo real.
         Pressione Enter após adicionar cada palavra-chave para adicionar várias palavras-chave.`,
        messageAfterAutomatedFlowCompleted: 'Mensagem Após o Término do Fluxo Automatizado',
        enterYourMessage: 'Digite sua Mensagem',
        messageAfterAutomatedFlowCompletedTooltip: `Digite uma mensagem que será exibida ao usuário após ele ter concluído o fluxo de chat automatizado, mas a sessão não foi encerrada.`,
        restart: 'Reiniciar',
        liveChat: 'Chat Ao Vivo',
        lineOneForDefaultMessage: 'Se você tiver mais perguntas ou precisar de ajuda, aqui está o que você pode fazer:',
        to: 'Para',
        restartTheChat: 'reiniciar o chat',
        simpleTypeRestartAndHitEnter: `simplesmente digite 'Reiniciar' e pressione Enter. Isso o levará de volta ao início do nosso fluxo de conversa.`,
        lineThreeForDefaultMessagePartOne: `Se você desejar falar com um`,
        liveAgent: `agente ao vivo,`,
        lineThreeForDefaultMessageLastPart: `digite 'Chat Ao Vivo' e pressione Enter. Nossa equipe está aqui para ajudá-lo em tempo real.`,
        saveChanges: 'Salvar Alterações',
        discard: 'Descartar',
        configureBotsGeneralSettings: 'Configure as configurações gerais do seu bot',
        minutes: 'Minutos',
        hours: 'Horas',

      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'Apenas a um passo',
      seeHowToUse: 'Veja como usar',
      testWhatsAppTrialBot: 'Teste seu Chatbot de teste do WhatsApp',
      sendMessage: 'Enviar mensagem',
      sendWhatsAppMessageOn: 'Envie-nos uma mensagem no WhatsApp para ',
      bySavingInYourContact: 'salvando nos seus contatos e a mensagem deve começar com ',
      connect: 'Conectar',
      or: 'OU',
      scanQRCode: 'Escanear o código QR',
      yourPhoneWillOpen: 'Seu telefone abrirá uma mensagem pré-preenchida para enviar para o nosso número Sandbox. Toque em "Enviar" no WhatsApp',
      sendVerificationCode: 'Enviar código de verificação',
      copyAndEnterCode: 'Copie e insira o código de 6 dígitos',
      shareWhereYouWant: 'Compartilhe onde quiser e redirecione os usuários para seu bot do WhatsApp com um único clique',
      youShouldHaveReceived: 'Você deve ter recebido um código de 6 dígitos em seu número de WhatsApp',
      EnterYourPhoneNumber: 'Digite seu número de telefone',
      send: 'Enviar',
      sixDigitCode: 'Digite o código de 6 dígitos',
      pleaseNote: 'Observação: o código de verificação de 6 dígitos pode levar até 3 minutos. Aguarde antes de reenviá-lo.',
      resendVerificationCode: 'Reenviar código de verificação',
      verify: 'Verificar',
      providePhoneNumber: 'Forneça o número que deseja usar para testar',
      demoBotisReady: 'Ótimo! Seu bot de demonstração está pronto para ser usado',
      placeHolder: {
        phone: 'Digite o número do seu telefone',
        code: 'Digite o código de verificação',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Integração de aplicativos de terceiros',
    thirdPartyApps: 'Aplicativos de terceiros',
    customApps: 'Aplicativos personalizados',
    preview: 'Visualização',
    test: 'Teste',
    install: 'Instalar',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Clique para instalar seu bot na plataforma selecionada',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Integre seus aplicativos criados personalizados ao seu bot',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Conecte seu bot com aplicativos/CRMs de terceiros disponíveis',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Integrações de aplicativos de terceiros',
        integrateYourBotWithListedCrms: 'Integre seu bot com um dos aplicativos/CRMs listados.',
        help: 'Ajuda',
        name: 'Nome',
        status: 'Status',
        action: 'Ação',
        chatGpt: 'Chatgpt',
        addOn: 'Adicionar',
        purchased: 'Comprado',
        readyToRideGptWave: 'Pronto para montar a onda GPT? Integrar agora.',
        purchase: 'Comprar',
        notConnected: 'Não conectado',
        integrated: 'Integrado',
        configure: 'Configure',
        connected: 'Conectado',
        edit: 'Editar',
        connect: 'Conectar',
        requestYourIntegration: 'Solicite sua integração',
        toRequestNewIntegration: 'Para solicitar uma nova integração, digite detalhes abaixo mencionados e então entraremos em contato com você',
        // name: 'Nome',
        emailId: 'Identificação do email',
        integrationName: 'Nome de integração',
        contactNumber: 'Número de contato',
        howSoonYouWantToAddThisIntegration: 'Em quanto tempo você deseja adicionar esta integração',
        sendRequest: 'Enviar pedido',
        cancel: 'Cancelar',
        requestSent: 'Solicitação enviada!',
        yourRequestSuccessfullySent: 'Seu pedido de nova integração foi enviado com sucesso.',
        goBack: 'Volte',
        disconnect: 'desconectar',
        OopsIntegrationAreNotAvailable: 'Ops! As integrações não estão disponíveis em ',
        pleaseUpgradeTo: '  Por favor, atualize para o ',
        planForIntegration: ' para as integrações.',
        placeholders: {
          searchIntegrationByName: 'Pesquisar integração por nome',
          enterTeamMemberName: 'Digite o nome do membro da equipe',
          enterEmailId: 'Digite ID de email',
          enterIntegrationName: 'Digite um nome de integração',
          searchCountry: 'País de pesquisa',
          enterContactNumber: 'Digite o número do contato',
          addComment: 'Adicionar comentário',
        },
        noDataFound: {
          integrations: 'Integrações',
          noIntegrationsFound: 'Nenhuma integração encontrada!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Nome de App/CRM',
          statusAsConnectedOrNotConnected: 'Status como conectado / não conectado',
          clickToConnectToAppOrCrm: 'Clique para conectar o aplicativo/CRM',
        },
        feedbackModal: {
          submit: 'Enviar',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          agileCrmDomain: 'Domínio ágil de CRM',
          nameIsRequired: 'O nome é necessário',
          emailAddress: 'Endereço de email',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameter: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'exemplo@company.extention',
            enterTokenHere: 'Entre no token aqui',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          accessKey: 'Chave de acesso',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiKeyHere: 'Digite a chave da API aqui',
            enterAccessKeyHere: 'Insira a chave de acesso aqui',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          nameColon: 'Nome:',
          inboundWebhookUrl: 'URL da webhook de entrada',
          nameIsRequired: 'O nome é necessário',
          leadTitle: 'Título principal',
          source: 'Fonte',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterInboundWebhookUrl: 'Digite URL da WebHook de entrada ...',
            enterTitle: 'Insira o título',
            enterSource: 'Digite a fonte',
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          testLead: 'Líder de teste',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          testLead: 'Líder de teste',
          connect: 'Conectar',
          selectTheQuestion: 'Selecione a pergunta cuja resposta do usuário você deseja enviar como valor',
          mapWith: 'mapear com',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
            attributes: 'Atributos (chave)',
            values: 'valores',
          }
        },
        chatGpt: {
          chatGptApiKey: 'Chave da API Chatgpt (aberta IA)',
          chatGpt: 'Chatgpt (aberto ai)',
          readyToRideWave: 'Pronto para montar a onda GPT? Integrar agora.',
          doNotHaveAnyApiKeyNeedHelp: 'Não tem uma chave de API, precisa de ajuda?',
          authenticate: 'Autenticar',
          nowYouCanAccessChatGpt: 'Agora você pode acessar o componente GPT de bate -papo no Flow Builder',
          paymentSuccessful: 'Pagamento bem -sucedido!',
          paymentHasBeenCompletedSuccessfully: 'O pagamento foi concluído com sucesso. Você está pronto para treinar seu bot',
          cancel: 'Cancelar',
          trainNow: 'Treine agora',
          paymentInProgress: 'Pagamento em andamento ...',
          apiKey: 'Chave da API',
          model: 'Modelo',
          integrateWithChatGPT: 'Integração com Chatgpt',
          updateKey: 'Atualizar Chave',
          connect: 'Conectar',
          update: 'Atualizar',
          placeholders: {
            chatGptApiKey: 'Chave da API Chatgpt (aberta IA)',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          leadId: 'ID do chumbo',
          selectLeadId: 'Selecione LeadID',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          emailAddress: 'Endereço de email',
          leadTitle: 'Título principal',
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            exampleEmail: 'exemplo@company.extention',
            enterYourLeadTitle: 'Digite seu título principal',
            enterTokenHere: 'Entre no token aqui',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          dripCrmAccountId: 'ID da conta CRM CRM',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterAccountIdHere: 'Digite ID da conta aqui',
            enterYourTokenHere: 'Entre no seu token aqui',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apikey: 'Chave API',
          accessToken: 'Acesso token',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          selectParameter: 'Selecione o parâmetro',
          disconnect: 'desconectar',
          update: 'Atualizar',
          save: 'Salvar',
          placeholders: {
            enterApiKeyHere: 'Digite a chave da API aqui',
            enterTokenHere: 'Entre no token aqui',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          testLead: 'Líder de teste',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          authToken: 'AuthToken',
          source: 'Fonte',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterAuthTokenHere: 'Digite AuthToken aqui',
            enterSourceHere: 'Digite a fonte aqui',
          }
        },
        facebookPixel: {
          facebookPixel: 'Pixel do Facebook',
          getInsight: `Obtenha insights que transformam seu negócio em uma empresa com a integração do Facebook Pixel da BotPenguin.`,
          pixelId: 'ID do pixel do Facebook',
          connect: 'conectar',
          disconnect: 'desconectar',
          placeholders: {
            enterYourId: 'ID do pixel do Facebook'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          companyName: 'Nome da empresa',
          emailAddress: 'Endereço de email',
          password: 'Senha',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapsWith: 'mapas com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterCompanyName: 'Digite o nome da empresa',
            enterEmailAddress: 'Insira o endereço de e-mail',
            enterPassword: 'Digite a senha',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiKeyHere: 'Entre no Apikey aqui',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          flowluDomain: 'Domínio FlowluLU',
          apiToken: 'Token da API',
          title: 'Título',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'Entre no token aqui',
            enterYourLeadTitle: 'Digite seu título principal',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          freshdeskDomain: 'Domínio Freshdesk',
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'Entre no token aqui',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          freshworkCrmDomain: 'Domínio CRM de trabalho fresco',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'Entre no token aqui',
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          mapWith: 'mapear com',
          select: 'Selecione',
          update: 'Atualizar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          campaignList: 'Lista de campanha',
          selectCampaign: 'Selecione a campanha',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          measurementId: 'ID da medição',
          connect: 'Conectar',
          disconnect: 'desconectar',
          placeholders: {
            enterYourId: 'Digite seu ID',
          }
        },
        googleCalendar: {
          testLead: 'Líder de teste',
          name: 'Nome:',
          email: 'E-mail:',
          appointmentSummary: 'Resumo da nomeação',
          appointmentDescription: 'Descrição da nomeação',
          addParameters: ' Adicione parâmetros',
          questionColon: 'Pergunta:',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          pause: 'Pausa',
          resume: 'Retomar',
          disconnect: 'desconectar',
          reconnect: 'Reconectar',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          name: 'Nome :',
          email: 'E-mail :',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
        },
        googleRedirect: {
        },
        googleSheet: {
          testLead: 'Líder de teste',
          title: 'Título',
          questionColon: 'Pergunta:',
          addParameters: 'Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          update: 'Atualizar',
          save: 'Salvar',
          pause: 'Pausa',
          resume: 'Retomar',
          disconnect: 'desconectar',
          reconnect: 'Reconectar',
          placeholders: {
            mappingValues: 'Valores de mapeamento',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          name: 'Nome:',
          email: 'E-mail:',
          taskList: 'Lista de tarefas',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          pause: 'Pausa',
          resume: 'Retomar',
          disconnect: 'desconectar',
          reconnect: 'Reconectar',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          select: 'Selecione',
          addParameters: ' Adicione parâmetros',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiHere: 'Digite API aqui',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          testLead: 'Líder de teste',
          accountId: 'ID da conta',
          accessToken: 'Acesso token',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterAccountIdHere: 'Digite a conta-ID aqui',
            enterAccessTokenHere: 'Insira o Token de acesso aqui',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Chave API',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          testLead: 'Líder de teste',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        helpLink: {
          help: 'Ajuda',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          helpRaceCrmDomain: 'Domínio HelPrace CRM',
          emailAddress: 'Endereço de email',
          apiToken: 'Token da API',
          connect: 'Conectar',
          update: 'Atualizar',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'exemplo@company.extension',
            token: 'Símbolo',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          domainName: 'Nome do domínio',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiHere: 'Digite API aqui',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          emailAddress: 'Endereço de email',
          apiUrl: 'API URL',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          // emailAddress: 'Endereço de email',
          selectProjectName: 'Selecione o nome do projeto',
          selectIssueType: 'Selecione Tipo de edição',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapsWith: 'mapas com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
            enterEmailAddress: 'Insira o endereço de e-mail',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiUrl: 'API URL',
          nameIsRequired: 'O nome é necessário',
          apiKey: 'Chave API',
          apiId: 'API ID',
          // nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          mapWith: 'mapear com',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          selectTheQuestion: 'Selecione a pergunta cuja resposta do usuário você deseja enviar como valor',
          placeholders: {
            enterApiUrl: 'Digite o URL da API',
            enterApiKey: 'Digite a chave da API',
            enterApiId: 'Digite o ID da API',
            attributes: 'Atributos (chave)',
            values: 'valores',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          accessKey: 'Chave de acesso',
          secretKey: 'Chave secreta',
          connect: 'Conectar',
          apiLogs: 'Logs da API',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          udpate: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          filterApplied: 'filtro aplicado',
          retry: 'Tente novamente',
          back: 'Voltar',
          filterYourLeads: 'Filtrar seus leads',
          filterOn: 'Filtre ligado',
          filterBy: 'Filtrar por',
          discard: 'Descartar',
          applyFilter: 'Aplicar filtro',
          loadMore: 'Carregue mais',
          placeholders: {
            enterAccessKeyHere: 'Insira a chave de acesso aqui',
            enterSecretKeyHere: 'Digite a chave secreta aqui',
            attributes: 'Atributos (chave)',
            values: 'valores',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Filtrar dados com base na resposta',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          apiUrl: 'API URL',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiHere: 'Digite API aqui',
            exampleUrl: 'https://comapanyname.ladesk.com',
          }
        },
        microsoftTeams: {
          downloadPackage: 'Baixe o pacote de aplicativos do Microsoft Teams',
          downloadApp: 'Baixar aplicativo',
          wantToLearnHowToUploadIt: 'Quer aprender a fazer upload?',
          needHelp: 'Preciso de ajuda?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiUrl: 'API URL',
          appId: 'ID do aplicativo',
          nameIsRequired: 'ID do aplicativo é necessário',
          dataApiKey: 'Chave da API de dados',
          dataApiKeyIsRequired: 'A chave da API de dados é necessária',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          addParameters: ' Adicione parâmetros',
          mapWith: 'mapear com',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          selectTheQuestion: 'Selecione a pergunta cuja resposta do usuário você deseja enviar como valor',
          placeholders: {
            enterApiUrl: 'Digite o URL da API',
            enterAppId: 'Digite o ID do aplicativo',
            enterDataApiKey: 'Digite a chave da API de dados',
            attributes: 'Atributos (chave)',
            pressForMappingQuestion: 'Pressione / para o mapeamento da pergunta',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          emailAddress: 'Endereço de email',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          leadId: 'ID do chumbo',
          selectLeadId: 'Selecione LeadID',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterEmailHere: 'Digite um email aqui',
            enterApiKeyHere: 'Entre no Apikey aqui',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          noCrmDomain: 'Domínio nocrm',
          leadTitle: 'Título principal',
          titleIsRequired: 'O título é necessário',
          token: 'Símbolo',
          tokenIsRequired: 'É necessário token',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            exampleDomain: 'https://your_subdomain_here.nocrm.io',
            enterYourLeadTitle: 'Digite seu título principal',
            enterYourToken: 'Digite seu token',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          emailAddress: 'Endereço de email',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          secretToken: 'Token secreto',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          appId: 'ID do aplicativo',
          appIdIsRequired: 'ID do aplicativo é necessário',
          apiKey: 'Chave API',
          apiKeyIsRequired: 'A chave da API é necessária',
          connect: 'Conectar',
          update: 'Atualizar',
          disconnect: 'desconectar',
          placeholders: {
            appId: 'ID do aplicativo',
            apiKey: 'Chave API',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          clientId: 'ID do Cliente',
          connect: 'Conectar',
          clientSecret: 'Segredo do cliente',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          save: 'Salvar',
          update: 'Atualizar',
          disconnect: 'desconectar',
          placeholders: {
            enterClientIdHere: 'Digite ID do cliente aqui',
            enterClientSecretHere: 'Digite o segredo do cliente aqui',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          pipedriverDomain: 'Domínio de Pipedriver',
          domainIsRequired: 'É necessário domínio',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'Entre no token aqui',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          apiKeyIsRequired: 'A chave da API é necessária',
          appKey: 'Chave do aplicativo',
          appKeyIsRequired: 'A chave de aplicativo é necessária',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiKey: 'Digite a chave da API',
            enterAppKey: 'Digite a chave do aplicativo',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          authId: 'ID de autenticação',
          authToken: 'Token de autenticação',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiHere: 'Digite API aqui',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiKey: 'Digite a chave da API',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          domain: 'Domínio',
          clientId: 'ID do Cliente',
          clientSecret: 'Segredo do cliente',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterDomainHere: 'Digite o domínio aqui',
            enterClientIdHere: 'Digite ID do cliente aqui',
            enterClientSecretHere: 'Digite o segredo do cliente aqui',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          addEventsAndTemplates: 'Adicione eventos e modelos:',
          add: ' Adicionar',
          question: 'Pergunta',
          reply: 'Responder',
          requestBody: 'Solicitar corpo',
          validateJson: 'Validar JSON',
          emailAddress: 'Endereço de email',
          // addParameters: ' Adicione parâmetros',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          selectTheQuestion: 'Selecione a pergunta cuja resposta do usuário você deseja enviar como valor',
          placeholders: {
            enterApiHere: 'Digite API aqui',
            enterTemplateId: 'Digite ID do modelo',
            typeYourJsonCodeHere: 'Digite seu código JSON aqui',
          },
          appTooltip: {
            typeYourJsonCode: 'Digite seu código JSON',
            clickToValidateJsonCode: 'Clique para validar o código JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          userName: 'Nome de usuário',
          licenseKey: 'Chave de licença',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterNameHere: 'Digite o nome aqui',
            enterKeyHere: 'Digite a tecla aqui',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          company: 'Empresa',
          loginId: 'ID de login',
          password: 'Senha',
          testLead: 'Líder de teste',
          connect: 'Conectar',
          selectTheQuestion: 'Selecione a pergunta cuja resposta do usuário você deseja enviar como valor',
          mapWith: 'Mapear com',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'Desconectar',
          selectParameter: 'Selecione o parâmetro',
          placeholders: {
            enterCompanyHere: 'Digite o nome da empresa aqui',
            enterLoginIdHere: 'Digite o ID de login aqui',
            enterPasswordHere: 'Digite a senha aqui',
            values: 'valores',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          applicationKey: 'Chave de aplicação',
          authToken: 'Token de autenticação',
          connect: 'Conectar',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApplicationKeyHere: 'Digite a chave do aplicativo aqui',
            enterTokenHere: 'Entre no token aqui',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          accessToken: 'Token de acess',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          question: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterTokenHere: 'Entre no token aqui',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          webhoperDomain: 'Domínio WebHoper',
          domainIsRequired: 'É necessário domínio',
          companyName: 'nome da empresa',
          companyIsRequired: 'A empresa é necessária',
          leadSource: 'Fonte de chumbo',
          leadSourceIsRequired: 'A fonte principal é necessária',
          division: 'Divisão',
          divisionIsRequired: 'Divisão é necessária',
          enterPassword: 'Digite a senha',
          passwordIsRequired: 'Senha requerida',
          disconnect: 'desconectar',
          update: 'Atualizar',
          connect: 'Conectar',
          placeholders: {
            exampleDomain: 'http: // yourcompanydomain',
            enterYourCompanyName: 'Digite o nome da sua empresa',
            enterLeadSource: 'Digite a fonte do chumbo',
            enterDivision: 'Entrar na divisão',
            enterPassword: 'Digite a senha',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          name: 'Nome:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'URL da webhook de entrada',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterWebhookUrl: 'Digite o URL da webhook',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiToken: 'Token da API',
          emailAddress: 'Endereço de email',
          apiUrl: 'API URL',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterEmailAddress: 'Insira o endereço de e-mail',
            enterUrlHere: 'Digite o URL aqui',
            enterTokenHere: 'Entre no token aqui',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          apiKey: 'Chave API',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiHere: 'Digite API aqui',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          connect: 'Conectar',
          update: 'Atualizar',
          disconnect: 'desconectar',
          companyName: 'nome da empresa',
          emailAddress: 'Endereço de email',
          phone: 'Telefone',
          countryCode: 'Código do país',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          // update: 'Atualizar',
          save: 'Salvar',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          connect: 'Conectar',
          disconnect: 'desconectar',
          testLead: 'Líder de teste',
          emailAddress: 'Endereço de email',
          selectOrganizationName: 'Selecione o nome da organização',
          selectDepartmentName: 'Selecione o nome do departamento',
          addParameters: ' Adicione parâmetros',
          questionColon: 'Pergunta:',
          select: 'Selecione',
          mapsWith: 'mapas com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
          testLead: 'Líder de teste',
          connect: 'Conectar',
          disconnect: 'desconectar',
          projectsList: 'Lista de projetos',
          selectProjectsName: 'Selecione o nome dos projetos',
          projectsTaskList: 'Projetos TaskSlist',
          selectTaskListName: 'Selecione o nome da lista de tarefas',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
        },
        wooCommerce: {
          configure: 'Configurar',
          automatedMessage: 'Mensagem Automatizada',
          configureTab: {
            wooCommercePluginApiKey: 'Chave API do Plugin WooCommerce',
            downloadWooCommercePlugin: 'Baixar Plugin WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Mensagem de Recuperação de Carrinhos Abandonados',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Configure mensagens automatizadas de recuperação para reconquistar clientes que abandonaram seus carrinhos',
            enableAbandonedCartRecoveryMessaegs: 'Ativar mensagens de recuperação de carrinho abandonado',
            selectTemplate: 'Selecionar Modelo',
            sendMessageAfter: 'Enviar Mensagem Após',
            templateHeader: 'Cabeçalho de modelo',
            templateMessage: 'Mensagem de Modelo',
            saveChanges: 'Salvar Alterações',
            selectFile: 'Selecione o arquivo',
            orderCompleted: 'Pedido Concluído',
            orderFulfillMent: 'Execução do Pedido',
            enableOrderConfirmationMessages: 'Ativar mensagens de confirmação de pedido',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Enviar uma mensagem de confirmação de pedido assim que um cliente fizer um pedido.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'A mensagem de envio do pedido é enviada quando você completa um pedido.',
            enableOrderFulfillmentMessages: 'Ativar mensagens de cumprimento de pedido',
            returnExchangeRequest: 'Solicitação de Devolução/Troca',
            theMessageIsSentAfterARefundIsRequested: 'A mensagem é enviada após uma solicitação de reembolso ser feita.',
            enableOrderRefundMessages: 'Ativar mensagens de reembolso de pedido',
          }
        },
        goHighLevel : {
          apiToken: 'Token da API',
          nameIsRequired: 'O nome é necessário',
          connect: 'Conectar',
          testLead: 'Líder de teste',
          questionColon: 'Pergunta:',
          addParameters: ' Adicione parâmetros',
          select: 'Selecione',
          mapWith: 'mapear com',
          selectParameter: 'Selecione o parâmetro',
          update: 'Atualizar',
          save: 'Salvar',
          disconnect: 'desconectar',
          placeholders: {
            enterApiToken: 'Entre no token da API',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Criar nova integração',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'Você não tem nenhuma configuração de integração a partir de agora.',
      pleaseCreateOneToIntegrateYourFavourite: 'Por favor, crie um para integrar o seu favorito',
      appsWith: 'aplicativos com',
      integrationName: 'Nome de integração',
      integrationNameIsRequired: 'O nome da integração é necessário',
      continue: 'Continuar',
      addAnImageToRepresentYourIntegration: 'Adicione uma imagem para representar sua integração.',
      description: 'Descrição',
      submit: 'Enviar',
      youHaveSuccessfullyCreatedIntegration: 'Você criou com sucesso a integração. Agora, você pode configurá -lo.',
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: 'Por favor, insira um URL válido de API',
      requestMethod: 'Método de solicitação',
      get: 'Pegar',
      post: 'Publicar',
      put: 'Colocar',
      patch: 'Correção',
      queryParameter: 'Parâmetro de consulta',
      addParameter: ' Adicione o parâmetro',
      mapsWith: ' mapas com',
      question: 'Pergunta',
      addQuestion: ' Adicione a pergunta',
      select: 'Selecione',
      selectParameter: 'Selecione o parâmetro',
      header: 'Cabeçalho',
      addHeader: ' Adicione o cabeçalho',
      value: 'Valor',
      connect: 'Conectar',
      requestBody: 'Solicitar corpo',
      validate: 'Validar',
      // select: 'Selecione',
      key: 'Chave',
      // value: 'Valor',
      // connect: 'Conectar',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' Também pode ser integrado a este CRM gratuito.',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: 'Por favor, insira um URL válido de API',
        requestMethod: 'Método de solicitação:',
        get: 'Pegar',
        post: 'Publicar',
        put: 'Colocar',
        patch: 'Correção',
        queryParameter: 'Parâmetro de consulta',
        addParameter: ' Adicione o parâmetro',
        header: 'Cabeçalho',
        addHeader: ' Adicione o cabeçalho',
        requestBody: 'Solicitar corpo',
        validateJson: 'Validar JSON',
        connect: 'Conectar',
        update: 'Atualizar',
        delete: 'Excluir',
        selectTheQuestion: 'Selecione a pergunta cuja resposta do usuário você deseja enviar como valor',
        appTooltip: {
          typeYourJsonCode: 'Digite seu código JSON',
          clickToValidateJsonCode: 'Clique para validar o código JSON',
        }
      },
      list: {
        customAppsIntegrations: 'Integrações de aplicativos personalizados',
        integrateYourAppsWith: 'Integrar seus próprios aplicativos com',
        help: 'Ajuda',
        addIntegration: ' Adicione integração',
        name: 'Nome',
        status: 'Status',
        action: 'Ação',
        insatlled: 'Instalado',
        edit: 'Editar',
        connect: 'Conectar',
        requestYourIntegration: 'Solicite sua integração',
        toRequestNewIntegration: 'Para solicitar uma nova integração, digite detalhes abaixo mencionados e então entraremos em contato com você',
        // name: 'Nome',
        emailId: 'Identificação do email',
        integrationName: 'Nome de integração',
        contactNumber: 'Número de contato',
        howSoonYouWantToAddThisIntegration: 'Em quanto tempo você deseja adicionar esta integração',
        sendRequest: 'Enviar pedido',
        cancel: 'Cancelar',
        delete: 'Excluir',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Ops! Suas próprias integrações são suportadas apenas em',
        plan: 'Plano',
        pleaseUpgradeTo: 'Por favor, faça upgrade para',
        toCreateOwnIntegrations: 'para criar Integrações Próprias',
        appTooltip: {
          integrations: 'Integrações',
          noIntegrationsFound: 'Nenhuma integração encontrada!',
          nameOfYourApp: 'Nome do seu aplicativo',
          clickToConnectApp: 'Clique para conectar o aplicativo',
          statusAsConnectedOrNotConnected: 'Status como conectado / não conectado',
        }
      }
    }
  }
};
