const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'שלום, ',
  greetingHand: '👋',
  upgrade: 'שדרג',
  integrate: 'שלב',
  integrate2: 'שלב',
  chatGPT: 'ChatGPT ',
  forYourBusiness: 'לעסק שלך',
  insights: 'תובנות',
  message: 'הודעות',
  addon: 'תוספת: ',
  leads: 'לידים',
  yourBots: 'הבוטים שלך',
  botName: 'שם הבוט',
  platform: 'פלטפורמה',
  contactOrLeads: 'אנשי קשר/לידים',
  actions: 'פעולות',
  edit: 'ערוך',
  delete: 'מחק',
  createA: 'צור ',
  whatsappChatbot: 'WhatsApp בוט צ\'אט ',
  connect: 'התחבר',
  getStartedWithUs: 'התחל עם נווט בפנינו 👏',
  howToBuildAGoodChatFlow: 'איך לבנות זרימת צ\'אט טובה',
  howToUseBotPenguinInboxAndItsFeatures: 'איך להשתמש באינבוקס של BotPenguin ובתכונותיו',
  botPenguinPricing: 'מחירי BotPenguin: גלישה בתוכניות ותכונות',
  openHelpDesk: 'פתח מוקד עזרה',
  helpAndSupport: 'עזרה ותמיכה',
  extensiveGuides: 'מדריכים מרובים לכל שאלותיך ותהליכי העבודה זמינים ב',
  resourceCentre: 'מרכז המשאבים.',
  checkoutOurAnswers: 'ניתן לבדוק את התשובות שלנו לשאלות הנפוצות למטה.',
  createANewBot: 'צור בוט חדש',
  installYourBot: 'התקן את הבוט שלך',
  plansAndPricing: 'תוכניות ומחירים',
  searchLanguage: 'חיפוש שפה',
  whatsappConversations: 'שיחות ב-WhatsApp',
  diveDeeperIntoBotPenguin: 'חפש עומקים נוספים ב-BotPenguin',
  knowWhereWeAreGoing: 'דע לאן אנחנו הולכים, העדכונים האחרונים, ועזור לנו לתקן באגים.',
  productUpdates: 'עדכוני מוצר',
  requestAFeature: 'בקשת תכונה',
  reportABug: 'דווח על באג',
  productRoadmap: 'מפת הדרכה של מוצר',
  contactsOrLeads: 'אנשי קשר/לידים',
  english: 'אנגלית',
  hindi: 'הינדי',
  german: 'גרמנית',
  french: 'צרפתית',
  portuguese: 'פורטוגזית',
  spanish: 'ספרדית',
  italian: 'איטלקית',
  russian: 'רוסית',
  pleaseSelectYourReason: 'אנא בחר את הסיבה שלך',
  cancel: 'ביטול',
  submit: 'שליחה!',
  giveFeedback: 'תן משוב',
  ratings: 'דירוגים',
  reasonBehindYourDecision: 'הסיבה מאחורי ההחלטה שלך',
  shareYourExperience: 'שתף את החוויה שלך',
  reason1: 'סיבה 1',
  reason2: 'סיבה 2',
  appTooltip: {
    HOME_INSIGHT: 'ספק תובנות לכל הודעות הבוטים שלך וללידים (הנתונים מתעדכנים כל לילה)',
    HOME_MESSAGES: 'הודעות שנצרפו לחשבונך במהלך מחזור החיוב הזה. (הודעות מבוטי WhatsApp, פייסבוק, וטלגרם אינן כלולות.)',
    HOME_TOTAL_MESSAGES: 'סה"כ הודעות שנצרפו לחשבונך מאז יצירת החשבון (הודעות מבוטי WhatsApp, פייסבוק, וטלגרם אינן כלולות.)',
    LEADS: 'לידים שנתפסו על ידי הבוטים עבור החשבון שלך במהלך מחזור החיוב הזה.',
    TOTAL_LEADS: 'סה"כ אנשי קשר/לידים שנתפסו על ידי הבוטים עבור החשבון שלך מאז יצירת החשבון (הנתונים מתעדכנים כל לילה).',
    YOUR_BOT: 'סקירה כללית של כל הבוטים שלך ושימושם במהלך מחזור החיוב הזה.',
    NAME: 'שם הבוט',
    PLATFORM: 'פלטפורמת הבוט',
    MESSAGES: 'סה"כ הודעות שנשלחו על ידי הבוט מאז יצירת החשבון',
    CONTACTS: 'סה"כ אנשי קשר/לידים שנתפסו על ידי הבוט',
    ACTIONS: 'ערוך/מחק את הבוט',
    EDIT_ICON: 'ערוך את זרימת הצ\'אט וההגדרות של הבוט',
    DELETE_ICON: 'מחק את הבוט',
    TOOL_TIP_CLASSIC_VERSION: `גרסה הישנה של ממשק המשתמש של ${brand}`
  },
  modal: {
    deleteBot: 'מחק בוט!',
    areYouSure: 'האם אתה בטוח שברצונך למחוק את הבוט הזה?',
    enquiryForm: 'טופס פנייה',
    payNow: 'שלם עכשיו!',
    saveAndDelete: 'שמור ומחק',
    submit: 'שלח',
  },
  noDataFound: {
    bots: 'בוטים',
    noBotCreatedYet: 'טרם נוצרו בוטים!',
  },
  yourFriendWillMissYou: 'החבר שלך יתגעגע אליך',
  rateYourBotCreationExperience: 'דרג את חוויית יצירת הבוט שלך',
  whatMadeYouDeleteYourBot: 'מה גרם לך למחוק את הבוט שלך?',
  iCreatedByMistake: 'יצרתי בטעות',
  iWantToCreateAnotherBot: 'אני רוצה ליצור בוט נוסף',
  couldNotFindFeaturesThatINeeded: 'לא מצאתי את התכונות שצריך',
  uiWasHardToNavigate: 'הממשק היה קשה לניווט בו',
  iDonotWantToTell: 'אני לא רוצה לספר',
  other: 'אחר',
  weWillDoOurBestToMakeAutomationFun: 'נעשה את מיטב המאמצים להפוך את האוטומציה לכיף ופשוטה לכולם.'

};
