const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Bewerk je chatflow',
    seeHowToUse: 'Zie hoe te gebruiken',
    help: 'Help',
    classicBuilder: 'Klassieke Bouwer',
    visualiseFlow: 'Visualiseer Flow',
    testLiveOnTelegramWeb: 'Test Live op Telegram Web',
    testFlow: 'Test flow',
    importTemplate: 'Sjabloon importeren',
    landingPageBotReview: 'Voorbeeld Landingspagina Bot',
    install: 'Installeren',
    testLiveOnMessengerWeb: 'Test Live op Messenger Web',
    testLiveOnWhatsappWeb: 'Test Live op WhatsApp Web',
    testLiveOnInstagramWeb: 'Test Live op Instagram Web',
    seeAllChatFlow: 'Bekijk alle chatflows',
    launchDemoModal: 'Start demo modal',
    selectATemplateToKickStartYourBot: 'Selecteer een sjabloon om je bot op gang te brengen',
    hireChatBotDesigner: 'Huur Chatbot Designer in',
    recommendedForYou: 'Aanbevolen voor jou',
    useTemplate: 'Gebruik sjabloon',
    exportStatus: 'Exporteer Status',
    flowExport: 'Flow-export',
    download: 'Download',
    importFlow: 'Importeer Flow',
    beforeYouStartUploadPleaseMakeSure: 'Voordat je begint met uploaden, zorg ervoor dat',
    ponit1: '1. De maximale bestandsgrootte is 1 MB',
    point2: '2. Het bestandsformaat moet JSON zijn',
    selectFile: 'Selecteer Bestand',
    preview: 'Voorbeeld',
    test: 'Test',
    OopsIntegrationAreNotAvailable: 'Oeps! Integraties zijn niet beschikbaar in ',
    pleaseUpgradeTo: '  Upgrade naar ',
    planForIntegration: ' voor integraties.',
    placeholders: {
      searchTemplateYouNeed: 'Zoek het sjabloon dat je nodig hebt',
    },
    noDataFound: {
      botFlow: 'Bot Flow',
      noBotFlowAddedYet: 'Nog geen botflow toegevoegd!',
      templates: 'Sjablonen',
      noTemplatesFound: 'Geen sjablonen gevonden!',
    },
    appTooltip: {
      exportFlow: 'Exporteer Flow',
      importFlow: 'Importeer Flow',
      clickToViewChatFlow: 'Klik om de chatflow te bekijken in de slepen en neerzetten bouwer',
      clickToPreviewChatFlow: 'Klik om de chatflow te bekijken',
      clickToViewChatFlowAsFlowDiagram: 'Klik om de chatflow te bekijken als een flowdiagram',
      clickToTestChatFlow: 'Klik om de chatflow te testen',
      clickToSelectAndImportTemplate: 'Klik om het sjabloon te selecteren en importeren dat het beste past bij jouw bedrijf.',
      clickToInstallYourBotToSelectedPlatform: 'Klik om je bot te installeren op het geselecteerde platform',
      clickToTestDefaultFlowOfTelegramBot: `Klik om de standaard (actieve) flow van je bot te testen op Telegram Web`,
      clickToTestDefaultFlowOfFacebookBot: `Klik om de standaard (actieve) flow van je bot te testen op Facebook Web`,
      clickToTestDefaultFlowOfWhatsappBot: `Klik om de standaard (actieve) flow van je bot te testen op WhatsApp Web`,
      clickToTestDefaultFlowOfInstagramBot: `Klik om de standaard (actieve) flow van je bot te testen op Instagram Web`,
    }
  },
  createNewBot: {
    createNewBot: 'Nieuwe Bot Creëren',
    stepOneOfTwo: 'Stap 1 van 2',
    selectThePlatform: 'Selecteer het platform',
    selectTheBotType: 'Selecteer het bottype',
    aiBasedChatbot: 'AI-gebaseerde chatbot',
    ruleBasedChatBot: 'Regelgebaseerde Chatbot',
    webPlatforms: 'Webplatforms',
    websiteChatBot: 'Website Chatbot',
    withLiveChat: '(Met Live Chat)',
    landingPageBot: 'Landing Page Bot',
    liveChatWidget: 'Live Chat Widget',
    onlyLiveChat: '(Alleen Live Chat)',
    socialPlatforms: 'Sociale Platforms',
    whatsappChatBot: 'WhatsApp Chatbot',
    telegramChatBot: 'Telegram Chatbot',
    facebookChatBot: 'Facebook Chatbot',
    instagramChatBot: 'Instagram Chatbot',
    selectBotType: 'Selecteer Bottype',
    selectProblem: 'Selecteer het probleem dat u wilt oplossen',
    leadGeneration: 'Leadgeneratie',
    customerSupport: 'Klantenservice',
    appointmentBooking: 'Afsprakenplanning',
    marketingAutomation: 'Marketingautomatisering',
    ecommerceWebsite: 'E-commerce website',
    otherUseCase: 'Overige',
    back: 'Terug',
    typeDomainUrl: 'Typ uw website URL voor verificatie.',
    dataSourceForChatBotTraining: `Gegevensbron voor chatbot-training`,
    upload: 'Uploaden',
    uploadDocuments: 'Documenten uploaden',
    uploadDocument: 'Document uploaden',
    selectFile: 'Bestand selecteren',
    continue: 'Doorgaan',
    websiteUrl: 'Typ uw website URL',
    skipNow: 'Nu overslaan',
    completeTheExistingBotSetup: 'Voltooi de bestaande configuratie van de bot',
    youHaveAlreadyCreatedOneWhatsAppBot: 'U heeft al een WhatsApp-bot gemaakt. Voltooi de instellingen om een nieuwe te maken.',
    completeSetup: 'Instellingen voltooid',
    seeHowToUse: 'Zie hoe te gebruiken',
    help: 'Help',
    fbBot: {
      createNewBot: 'Nieuwe Bot Creëren',
      help: 'Help',
      back: 'Terug',
      step: 'Stap ',
      of3: ' van 3',
      loginWithFacebook: 'Inloggen met Facebook',
      asPerLatestChangesInFacebookPolicy: 'Volgens de laatste wijzigingen in het beleid van Facebook moet de eigenaar van een Facebook-bot de rol van `manager` of hoger hebben om toegang te krijgen tot alle functies van de Facebook-pagina.',
      login: 'Inloggen',
      connectFacebookBot: 'Facebook Bot Verbinden',
      connectBotWithYourFacebookPagesAnd: 'Verbind de Bot met je Facebook-pagina\'s en ',
      exploreTruePowerOfFacebook: 'ontdek de ware kracht van Facebook',
      connect: 'Verbinden',
      connected: 'Verbonden',
      createNewPage: 'Nieuwe pagina creëren',
      refreshPageList: 'Vernieuw Paginalijst',
      thisPageIsSuccessfullyIntegratedWith: 'Deze pagina is succesvol geïntegreerd met ',
      yourMessagesFromMessengerWillAppearIn: '. Je berichten van Messenger zullen verschijnen in ',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Doorgaan naar Bot Configuratie',
      welcomeToBrandNameWeAreHereToHelpYou: `Welkom bij ${brand}. We staan voor je klaar.`,
      letsTryYourBotAndSeeHowItWorks: `Laten we je bot proberen en zien hoe het werkt! Klik op "Testen op Facebook".`,
      howAreYouDoingToday: 'Hoe gaat het vandaag?',
      thisIsHowSimpleToUseFacebookBot: `Dat is het! Zo eenvoudig is het om een Facebook-bot te gebruiken.`,
      letsCreateYourOwnChatFlowNow: `Laten we nu je eigen chatflow maken.`,
    },
    instagramBot: {
      createNewBot: 'Nieuwe Bot Aanmaken',
      back: 'Terug',
      step: 'Stap ',
      of3: ' van 3',
      loginWithInstagram: 'Inloggen met Instagram',
      loginToYourInstagramAccount: 'Log in op je Instagram-account om de chatbot op je profiel te activeren.',
      managerLevelDescription: 'Eigenaren van Instagram-bots moeten minimaal het niveau "manager" hebben of hoger om toegang te krijgen tot alle functies.',
      login: 'Inloggen',
      connectInstagramBot: 'Verbind Instagram Bot',
      connectBotWithYourInstagramAnd: 'Verbind de bot met je Instagram en ',
      exploreTruePowerOfInstagram: 'verken de ware kracht van Instagram',
      connected: 'Verbonden',
      connect: 'Verbinden',
      createNewPage: 'Nieuwe pagina aanmaken',
      refreshPageList: 'Ververs Paginalijst',
      thisPageIsSuccessfullyintegratedWith: 'Deze pagina is succesvol geïntegreerd met ',
      yourMessagesFromMessengerWillAppearIn: '. Je berichten van Messenger zullen verschijnen in ',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Ga verder met Bot-configuratie',
      welcomeToBrandNameWeAreHereToHelpYou: `Welkom bij ${brand}. We staan voor je klaar om je te helpen.`,
      letsTryYourBotAndSeeHowItWorks: `Laten we je bot proberen en kijken hoe het werkt! Klik op "Test Op Instagram".`,
      howAreYouDoingToday: 'Hoe gaat het vandaag met je?',
      thisIsHowSimpleToUseInstagramBot: `Dat is alles! Zo eenvoudig is het om een Instagram-bot te gebruiken.`,
      letsCreateYourOwnChatFlowNow: `Laten we nu je eigen chatflow maken.`,
    },
    telegramBot: {
      back: 'Terug',
      step: 'Stap ',
      of3: ' van 3',
      seeHowToUse: 'Bekijk hoe te gebruiken',
      help: 'Hulp',
      loginToYourTelegramAccount: 'Log in op je Telegram-account',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Log in op je account om een nieuwe bot te maken of een bestaande bot te verbinden',
      verificationCodeSentOnRegisteredNumber: 'Verificatiecode verzonden naar het geregistreerde nummer',
      createYourBot: 'Maak je bot aan',
      enterYourBotsNameAnd: `Voer de naam van je bot in, verbind en laat het plezier beginnen met `,
      botUserNameMustEndWithBotAtTheEnd: 'De gebruikersnaam van de bot moet eindigen met "bot" aan het einde.',
      terrisBot: 'Bijv. teris_bot of terisbot',
      botNameAlreadyTaken: 'Deze botnaam is al in gebruik. Probeer iets anders',
      connect: 'Verbinden',
      createBot: 'Bot aanmaken',
      codeNotReceivedResend: 'Code niet ontvangen? Opnieuw verzenden!',
      changeNumber: 'Nummer wijzigen',
      telegramPreview: 'Telegram Voorbeeld',
      worldsFastestMessagingApp: `Snelste berichtenapp ter wereld & Het is gratis!`,
      bot: 'Bot',
      phoneNumber: 'Telefoonnummer',
      userName: 'Gebruikersnaam',
      none: 'Geen',
      bio: 'Bio',
      fewWordsAboutYourself: 'Een paar woorden over jezelf',
      settings: 'Instellingen',
      notificationsAndSound: 'Meldingen en geluiden',
      privacyAndSecurity: 'Privacy en beveiliging',
      dataAndStorage: 'Gegevens en opslag',
      chatSettings: 'Chatinstellingen',
      chatFolders: 'Chatmappen',
      devices: 'Apparaten',
      connectYourBot: 'Verbind je Bot',
      createNewBotOrConnectWithExistingOne: 'Maak een nieuwe bot aan of verbind met een bestaande',
      createNewBot: 'Nieuwe bot aanmaken',
      welcomeToBrandNameWeAreHereToHelpYou: `Welkom bij ${brand}. We staan voor je klaar om je te helpen.`,
      letsTryYourBotAndSeeHowItWorks: `Laten we je bot proberen en kijken hoe het werkt! Klik op "Test op Telegram".`,
      howAreYouDoingToday: 'Hoe gaat het vandaag met je?',
      thisIsHowSimpleToUseTelegramBot: `Dat is alles! Zo eenvoudig is het om een Telegram-bot te gebruiken.`,
      letsCreateYourOwnChatFlowNow: `Laten we nu je eigen chatflow maken.`,
      placeholders: {
        searchCountry: 'Zoek Land',
        enterTelegramMobileNumber: 'Voer je Telegram Mobiele Nummer in',
        enterLoginCodeReceived: 'Voer de ontvangen inlogcode in voor je Telegram-account *',
        twoFactorAuthentication: 'Tweestapsverificatie (optioneel)',
        botName: 'Bot Naam *',
        botUserName: 'Gebruikersnaam van de Bot *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Nieuwe Bot Aanmaken',
      seeHowToUse: 'Zie hoe het te gebruiken',
      help: 'Hulp',
      back: 'Terug',
      step: 'Stap ',
      of2: ' van 2',
      buildA: 'Bouw een ',
      thatMatchesYourBrandPersonality: ` die past bij de persoonlijkheid van jouw merk`,
      chatBotIcon: 'Chatbot Icoon ',
      optional: 'Optioneel',
      themeColor: 'Themakleur',
      chatBotName: 'Chatbot Naam',
      botNameIsRequired: 'Chatbot naam is vereist',
      botNameShouldBeLessThan50Characters: 'Chatbot Naam moet minder dan 50 tekens zijn',
      continue: 'Doorgaan',
      welcomeToBrandNameWeAreHereToHelpYou: `Welkom bij ${brand}. We staan klaar om je te helpen.`,
      whatIsYourEmailAddress: 'Wat is jouw e-mailadres?',
      invalidEmailAddress: 'Ongeldig e-mailadres',
      customizeYourLandingPageBotLook: 'Pas het uiterlijk van je Landingspagina Bot aan',
      customizeYourWidgetLook: 'Pas het uiterlijk van je widget aan',
      customizeYourBotLook: 'Pas het uiterlijk van je chatbot aan',
      buildYourLandingPageBotAndAutomateInteraction: 'Bouw je Landingspagina bot en automatiseer interactie met jouw klanten door de link te delen',
      useLiveChatBotToConnectToCustomers: 'Gebruik de Live Chat Bot om in realtime verbinding te maken met je klanten',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Verbind de ChatBot met jouw website en automatiseer interacties met bezoekers',
      widget: 'widget',
      botInterface: 'chatbot interface',
      mergedBot: 'Website Chatbot, Landingspagina Bot, Mobiele App Chatbot',
      mergedBotToolTip: 'Creëer een bot-pagina om de aandacht van bezoekers te trekken en hen in gesprekken te betrekken.',
      placeholders: {
        typeANameForYourBot: 'Typ een naam voor je nieuwe bot',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Voer de naam van je bot in',
        mergedBotToolTip: 'Creëer een bot-pagina om de aandacht van bezoekers te trekken en hen in gesprekken te betrekken.',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Kies een themakleur voor je chatvenster',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Dit logo wordt alleen gebruikt in het ${brand ? brand : ''} platform. We raden aan om het bedrijfslogo te kiezen. Voeg een PNG- of JPG-afbeelding van maximaal 1 MB toe.`
      }
    },
    whatsappBot: {
      back: 'Terug',
      justAStepAway: 'Slechts één stap verwijderd',
      seeHowToUse: 'Zie hoe te gebruiken',
      help: 'Help',
      createWhatsappChatBot: 'WhatsApp Bot Creëren',
      businessNameIsRequired: 'Bedrijfsnaam is verplicht.',
      maximumLengthForBusinessName40Characters: 'De maximale lengte voor de bedrijfsnaam is 40 tekens.',
      businessCategory: 'Bedrijfscategorie',
      categoryIsRequired: 'Categorie is verplicht.',
      descriptionIsRequired: 'Beschrijving is verplicht.',
      maximumLengthForDescription200Characters: 'De maximale lengte voor de beschrijving is 200 tekens.',
      pleaseFillAValidEmailAddress: 'Vul alstublieft een geldig zakelijk e-mailadres in.',
      businessEmailIsRequired: 'Bedrijfse-mail is verplicht.',
      phoneNumberShouldHaveMinimum7Digits: 'Telefoonnummer moet minimaal 7 cijfers hebben.',
      phoneNumberIsRequired: 'Telefoonnummer is verplicht.',
      websiteIsRequired: 'Website is verplicht.',
      maximumLengthForWebsite40Characters: 'De maximale lengte voor de website is 40 tekens.',
      maximumLengthForAddress150Characters: 'De maximale lengte voor het adres is 150 tekens.',
      doYouHaveWhatsappApiKey: 'Heeft u een WhatsApp API-sleutel?',
      apiProvider: 'API-provider',
      three60Dialog: '360 Dialog',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'API-sleutel is verplicht.',
      whatsappBusinessAccountIdIsRequired: 'WhatsApp Bedrijfsaccount ID is verplicht.',
      whatsappPhoneNumberIdIsRequired: 'WhatsApp Telefoonnummer ID is verplicht.',
      systemAccessTokenIsRequired: 'Toegangstoken systeem is verplicht.',
      sourceIdIsRequired: 'Bron-ID is verplicht.',
      businessName: 'Bedrijfsnaam',
      businessAccount: 'Bedrijfsaccount',
      businessDescription: 'Bedrijfsbeschrijving',
      address: 'Adres',
      businessEmail: 'Bedrijfse-mail',
      websiteUrl: 'Website URL',
      thankYouForYourInterestIn: 'Bedankt voor uw interesse in ',
      whatsappChatBot: ' WhatsApp Chatbot.',
      weHaveReceivedYourInterests: 'We hebben uw interesse ontvangen, en ons verkoopteam zal binnen 24 uur contact met u opnemen.',
      theyWillProvideYouWhatsappApiKey: 'Ze zullen u de WhatsApp API-sleutel verstrekken.',
      readyToCreateWhatsappBot: `Ik ben klaar om een WhatsApp Bot te creëren`,
      exploreWhatsappFeatures: 'Ontdek WhatsApp functies',
      welcomeToBrandNameWeAreHereToHelpYou: `Welkom bij ${brand}. We zijn hier om u te helpen.`,
      letsTryYourBotAndSeeHowItWorks: `Laten we uw bot proberen en zien hoe het werkt! Klik op "Testen op WhatsApp".`,
      howAreYouDoingToday: 'Hoe gaat het vandaag met u?',
      thisIsHowSimpleToUseWhatsappBot: `Dat is het! Zo eenvoudig is het om een WhatsApp-bot te gebruiken.`,
      letsCreateYourOwnChatFlowNow: `Laten we nu uw eigen chatstroom creëren`,
      connectWhatsAppBot: 'Verbind WhatsApp Bot',
      createtWhatsAppBot: 'WhatsApp-bot maken',
      proceed: 'Doorgaan',
      whatsApiProvider: 'WhatsApp API-provider',
      cloudApi: 'Cloud API',
      netcoreApi: 'Netcore API',
      placeholders: {
        yourBusinessName: 'Uw Bedrijfsnaam',
        businessDescription: 'Bedrijfsbeschrijving',
        businessEmail: 'Bedrijfs E-mail',
        phone: 'Telefoon*',
        website: 'Website (bijv. www.example.com)',
        address: 'Adres',
        enterApiKey: 'Voer API-sleutel in',
        enterWhatsappBusinessAccountId: 'Voer WhatsApp Bedrijfsaccount ID in',
        enterWhatsappPhoneNumberId: 'Voer WhatsApp Telefoonnummer ID in',
        enterSystemAccessToken: 'Voer Toegangstoken Systeem in',
        enterSourceId: 'Voer Bron-ID in',
        businessName: 'Bedrijfsnaam',
        businessWebsite: 'Bedrijfswebsite',
        selectWhatsappApiProvider: 'Selecteer WhatsApp API-provider',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'Kies uw WA API-provider',
        doYouHaveWhatsAppBusinessAPI: 'Heeft u WhatsApp Business API?',
        yes: 'Ja',
        noIAmNotSure: 'Nee, ik weet het niet zeker',
        selectApiProvider: 'Selecteer API-provider',
        whatsAppCloudAPI: 'WhatsApp Cloud API',
        enterYourPhoneNumberId: 'Voer uw telefoonnummer ID in',
        phoneNumberId: 'Telefoonnummer ID',
        enterYourWhatsAppBusinessAccountId: 'Voer uw WhatsApp Bedrijfsaccount ID in',
        whatsAppBusinessAccountId: 'WhatsApp Bedrijfsaccount ID',
        enterYourSystemUserToken: 'Voer uw Systeemgebruiker Token in',
        systemUserToken: 'Systeemgebruiker Token',
        help: 'Help',
        netcoreAPI: 'Netcore API',
        enterYourSourceId: 'Voer uw Bron-ID in',
        sourceId: 'Bron-ID',
        enterYourNetcoreApiKey: 'Voer uw Netcore API-sleutel in',
        netcoreApiKey: 'Netcore API-sleutel',
        submit: 'Verzenden',
        noneOftheAbove: 'Geen van bovenstaande',
        next: 'Volgende'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Wettelijk geregistreerd bedrijf',
        validWebsiteWithDataPrivacyPolicy: 'Geldige website met gegevensprivacybeleid',
        debit: 'Debit',
        creditCard: 'Creditcard',
        gstin: 'GSTIN',
        onlyRequiredForIndianResidents: 'Alleen vereist voor Indiase inwoners',
        onlyRequiredFor: 'Alleen vereist voor',
        indianResidents: 'Indiase inwoners',
        validPaymentMethod: 'Geldige betaalmethode',
        enabledWithInternationalPayments: 'Ingeschakeld voor internationale betalingen',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'Geldige use case in overeenstemming met het WhatsApp commerce beleid',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Telefoonnummer dat geen actieve WhatsApp heeft en in staat is om SMS te ontvangen',
        discard: 'Verwijderen',
        proceed: 'Doorgaan',
        whatsAppBotPrerequisites: 'Vereisten voor WhatsApp Bot',
        letUsGetItDoneForYou: 'Laat ons het voor u regelen!',
        ifYouAreFacingSomeIssues: 'Als u problemen ondervindt',
        ourTeamCanHelpYouDoTheSame: 'Ons team kan u helpen hetzelfde te doen',
        requestACallbackNow: 'Vraag nu een terugbelverzoek aan!',
      },
      stepThree: {
        setupWhatsAppBot: 'WhatsApp Bot Instellen',
        selectingTheBestBusinessManagerForYourNeeds: 'Selecteer de beste bedrijfsmanager voor uw behoeften',
        createMetaApp: 'Hoe maak je een Meta-app',
        createaMetaAppOn: 'Maak een Meta App aan op',
        howToCreate: 'Hoe te maken',
        addWhatsAppNumber: 'Hoe voeg je een WhatsApp-nummer toe',
        addYourWhatsAppNumberAndVerifyTheSame: 'Voeg uw WhatsApp-nummer toe en verifieer hetzelfde',
        subscribe: 'Abonneren',
        copyAndSubscribeWebhook: 'Kopieer en abonneer Webhook',
        howToSubscribe: 'Hoe te abonneren',
        copy: 'Kopiëren',
        appSecretId: 'App Geheim ID',
        enterYourAppSecretId: 'Voer uw Geheime ID van de App in',
        enterAppSecret: 'Voer App Geheim in',
        phoneNumberId: 'Telefoonnummer ID',
        whatsAppBusinessId: 'WhatsApp Bedrijfs ID',
        assignSystemUser: 'Hoe een systeemgebruiker toewijzen',
        assign: 'Toewijzen',
        createApp: 'Meta aanmaken',
        registerAndVerify: 'Registreren en verifiëren',
        add: 'Toevoegen',
        systemUserToken: 'Systeemgebruiker Token',
        enterYourSystemUserToken: 'Voer uw Systeemgebruiker Token in',
        getAllNecessaryDetails: 'Cum să obțineți toate detaliile necesare',
        subscribeWhatsAppWebhook: 'Hoe u zich kunt abonneren op de WA-webhook',
        configureTheWebhookForWhatsAppAccount: 'Configureer de Webhook voor WhatsApp-account',
        upadatePaymentMethod: 'Cum să actualizați metoda de plată',
        assignThePaymentMethodToWhatsAppAccount: 'Wijs de betaalmethode toe aan een WhatsApp-account',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Geef controle over een WhatsApp Bedrijfsaccount aan een systeemgebruiker',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Belangrijke details die u moet kopiëren vanuit het Meta-dashboard',
        updatePayment: 'Betalingsupdate',
        letUsGetItDoneForYou: 'Laat ons het voor u regelen!',
        ifYouAreFacingSomeIssues: 'Als u problemen ondervindt',
        ourTeamCanHelpYouDoTheSame: 'Ons team kan u helpen hetzelfde te doen',
        requestACallbackNow: 'Vraag nu een terugbelverzoek aan!',
        update: 'Bijwerken',
        connectNow: 'Nu verbinden'
      },
      stepFive: {
        selectBusinessManager: 'Selecteer Bedrijfsmanager',
        selectingTheBestBusinessManagerForYourNeeds: 'Selecteer de beste bedrijfsmanager voor uw behoeften'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Uw bot is geïnstalleerd en actief',
        youhaveSuccessfullyConnected: 'U heeft succesvol verbinding gemaakt',
        withWhatsApp: 'met WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'U heeft standaard 100 gesprekken per maand toegewezen gekregen',
        upgradeYourPlanForMore: 'Upgrade uw plan voor meer.',
        createChatFlow: 'Maak Chatstroom aan',
        upgradePlan: 'Plan Upgraden'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Scan de QR-code of Stuur een bericht om de Bot te activeren',
        scanTheQrCodeFrom: 'Scan de QR-code vanaf',
        yourPhoneWillOpen: 'Uw telefoon opent een vooraf ingevuld bericht om naar ons Sandbox-nummer te sturen. Druk op "Versturen" op WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Stuur een bericht vanaf uw WhatsApp-nummer',
        sendUsAWhatsAppMessageOn: 'Stuur ons een WhatsApp-bericht naar ',
        bySavingInYourContactsAndMessageShouldStartWith: ' door deze op te slaan in uw contacten en het bericht moet beginnen met ',
        send: 'Versturen',
        or: 'OF'
      }
    }
  },
  gettingStarted: {
    hello: 'Hallo, ',
    upgrade: 'Upgraden',
    gettingStarted: 'Aan de slag - ',
    yourFacebookBotToken: 'Uw Facebook bot-token ',
    hasBeenExpired: 'is verlopen',
    isExpiringIn: 'verloopt over ',
    days: ' dagen',
    connected: 'Verbonden',
    facebookAuthenticationTokenRemainsFor60Days: 'Het Facebook authenticatietoken blijft slechts 60 dagen actief',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Gelieve de vernieuwing van uw bot-token voort te zetten om ononderbroken toegang tot chatbot-diensten te waarborgen.',
    renewYourBotToken: 'Vernieuw uw bot-token',
    editYourChatFlow: 'Bewerk uw chatflow',
    buildConversationEngagingBot: 'Bouw boeiende gespreksstromen voor uw botgebruikers.',
    takes5minutes: 'duurt 5 minuten',
    previewYourLiveChatWidget: 'Bekijk uw Live Chat-widget',
    discoverHowThe: 'Ontdek hoe de ',
    liveChatFeatureWorks: ' Live Chat-functie werkt met behulp van onze Live Chat-spelomgeving',
    takes2minutes: 'duurt 2 minuten',
    takes1minute: 'duurt 1 minuut',
    customizeYourBotApperance: 'Pas het uiterlijk en de functionaliteit van uw bot aan.',
    takes3minutes: 'duurt 3 minuten',
    thirdPartyAppsIntegration: 'Integratie van apps van derden',
    integrateYourBot: 'Integreer uw bot met andere apps/CRM\'s om bedrijfsautomatisering te creëren.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Upgrade uw plan om leads via e-mail te ontvangen',
    getStartedWithUs: 'Begin met ons',
    howToBuildAGoodChatFlow: 'Hoe een goede chatflow te bouwen',
    howToUseBotPenguinsInboxAndItsFeatures: 'Hoe BotPenguin\'s Inbox te gebruiken en de functies ervan',
    botPenguinsPricing: 'BotPenguin\'s Prijzen: Verkennen van plannen en functies',
    openHelpDesk: 'Open Helpdesk',
    helpAndSupport: 'Hulp en ondersteuning',
    extensiveGuides: 'Uitgebreide handleidingen voor al uw vragen en workflows zijn beschikbaar in onze ',
    resourceCentre: 'Kenniscentrum',
    checkoutOurAnswers: 'U kunt onze antwoorden op veelgestelde vragen hieronder bekijken.',
    createANewBot: 'Maak een nieuwe bot',
    installYourBot: 'Installeer uw bot',
    plansAndPricing: 'Plannen en prijzen',
    previewYourLandingPageBot: 'Bekijk uw bot voor landingspagina',
    installYourLiveChatWidget: 'Installeer uw Live Chat-widget',
    installYourWebsiteBot: 'Installeer uw chatbot op uw website, mobiele app of lanceer als landingspagina of ingesloten chatbot',
    integrateBotToLandingPage: 'Integreer de bot in uw bot voor landingspagina',
    installWidgetToWebsite: 'Installeer de widget op uw website',
    installBotToWebsite: 'Installeer uw chatbot',
    whatsappAutomation: 'WhatsApp Automatisering',
    facebookAutomation: 'Facebook Automatisering',
    telegramBotSettings: 'Instellingen voor Telegram Bot',
    liveChatWidgetSettings: 'Instellingen voor Live Chat-widget',
    landingPageBotSettings: 'Instellingen voor bot op landingspagina',
    websiteChatBotSettings: 'Instellingen voor chatbot op website',
    instagramAutomation: 'Instagram Automatisering',
    diveDeeperIntoBotPenguin: 'Duik dieper in BotPenguin',
    knowWhereWeAreGoing: 'Weet waar we naartoe gaan, de recente updates en help ons enkele bugs op te lossen.',
    productUpdates: 'Productupdates',
    requestAFeature: 'Vraag een functie aan',
    reportABug: 'Meld een bug',
    prodcutRoadmap: 'Product Roadmap',
    trainYourChatbot: 'Train uw AI Chatbot (kennisbasis)',
    buildEngagingConversation: 'Bouw boeiende gespreksstromen voor uw botgebruikers.',
    previewYourAIBot: 'Bekijk uw AI-bot',
    integrateAIbot: 'Integreer de bot in uw AI-bot',
    aiBotSettings: 'Instellingen voor AI-bot',
    CustomizeAIBot: 'Pas het uiterlijk en de functionaliteit van uw AI-bot aan.',
    gptKeyWarning: 'Welkom bij onze ChatGPT API-service! Let op dat er een beperking is van 100 berichten voor elke bot. Nadat je de limiet van 100 berichten hebt bereikt, moet je je eigen ChatGPT API-sleutel gebruiken om van onze service te blijven genieten. Ga naar ChatGPT Model Configuratie om je ChatGPT API-sleutel in te voeren.',
    connectToYourWhatsappNumber: 'Verbinden met je WhatsApp-nummer',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Verbind je WhatsApp-bot met je WhatsApp-nummer',
    whatsappBotSettings: 'WhatsApp Bot Instellingen',
    automateCustomerEngagementWithWhatsApp: 'Automatiseer klantbetrokkenheid met WhatsApp.',
    facebookBotSettings: 'Facebook Bot Instellingen',
    automateCustomerEngagementWithTelegram: 'Automatiseer klantbetrokkenheid met Telegram',
    automateCustomerEngagementWithFacebook: 'Automatiseer klantbetrokkenheid met Facebook',
    automateCustomerEngagementWithInstagram: 'Automatiseer klantbetrokkenheid met Instagram',
    telegramAutomation: 'Telegram Automatisering',
  },
  install: {
    previewYourLandingPageBot: 'Voorbeeld van uw Landingspagina Bot',
    preview: 'Voorbeeld',
    test: 'Test',
    squareSpace: 'Squarespace',
    installYourChatBot: 'Installeer uw Chatbot',
    seeHowToUse: 'Bekijk hoe te gebruiken',
    help: 'Help',
    uniqueLinkToLandingPageBot: 'Hier is de unieke link naar uw Landingspagina bot.',
    shareOnSocialMedia: 'Deel het eenvoudig met uw gebruikers op sociale media, contactpagina\'s of visitekaartjes.',
    or: 'OF',
    copyUrl: 'Kopieer URL',
    selectYourWebsiteCms: 'Selecteer uw Website CMS',
    selectCms: 'Selecteer de CMS/Builder die u hebt gebruikt om uw website te maken',
    custom: 'Aangepast',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Andere CMS',
    howWouldYouLikeToInstall: 'Hoe wilt u dit installeren?',
    installingWebsiteBot: 'Kies hoe u de bot op uw website gaat installeren',
    iWillAddMyself: 'Ik voeg het zelf toe',
    getChatBotScript: 'Krijg chatbot script code hieronder en installeer het zelf',
    getWordpressPlugin: 'Krijg WordPress Plugin API Key',
    getOurOfficialPlugin: 'Krijg onze officiële plugin ',
    fromWordpressPluginStore: ' van de WordPress plugin Store',
    getPlugin: 'Krijg Plugin',
    quickGuideToInstall: 'Snelgids voor installatie',
    placeApiKeyBelowIn: 'Plak de API-sleutel hieronder in ',
    afterDownloadingAndActivatingPlugin: ' nadat u de plugin heeft gedownload en geactiveerd',
    copy: 'Kopiëren',
    enterYourWebsite: 'Voer uw website in en laten we zien hoe uw chatbots eruitzien',
    enterAValidUrl: 'Voer een geldige URL in',
    verifyYourInstallation: 'Verifieer of uw installatie succesvol is of niet?',
    testInstallation: 'Test Installatie',
    getChatBotScriptCode: 'Krijg Chatbot Script Code',
    copyThisCodeSnippet: 'Kopieer dit codefragment en voeg het in in de HTML van elke webpagina' +
      'waar u de chatbot wilt weergeven of plak het in de footer voordat de tag sluit om op elke pagina te verschijnen.',
    // copy: 'Copy',
    sendCodeToMyDeveloper: 'Stuur code naar mijn ontwikkelaar',
    sendCodeToMyDevelopmentTeam: 'Stuur code naar mijn ontwikkelingsteam',
    sendEmail: 'Stuur e-mail',
    enterAValidEmail: 'Voer een geldig e-mailadres in',
    codeHasBeen: 'De code is ',
    toYourDeveloper: ' naar uw ontwikkelaar ',
    resent: 'opnieuw verzonden',
    sent: 'verzonden',
    resendEmail: 'E-mail opnieuw verzenden',
    ask: 'Vraag ',
    supportTeamToInstall: 'Support Team om te installeren',
    raiseTheInstallationRequestTo: 'Verzoek om de installatie te starten bij ',
    team: ' team',
    helpMeInstallingChatBot: 'Help me bij het installeren van de Chatbot',
    yourBotInstallationIsNotActive: 'Uw botinstallatie is niet actief!',
    monthlyMessageLimitExceeded: 'Maandelijks berichtlimiet overschreden',
    exceededMonthlyMessageLimitUpgradeNow: 'U heeft uw maandelijkse berichtlimiet overschreden, Upgrade nu.',
    upgradeNow: 'Upgrade Nu',
    botInstallationNotActivePleaseFix: 'Uw botinstallatie is niet actief, Los dit alstublieft op. ',
    incorrectBotScript: 'Onjuist Bot-script',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'Het installatiecode die u verifieert, komt niet overeen ' +
      'met degene op uw site. Zorg ervoor dat u de juiste code voor deze bot heeft geïnstalleerd.',
    unableToSee: 'Niet in staat om te zien ',
    codeOnYourWebsite: ' Code op uw website',
    unableToFindCodePleaseReinstall: 'We kunnen de code niet vinden op uw website, Installeer het alstublieft opnieuw.',
    reinstallBot: 'Installeer de bot opnieuw',
    yourBotIsInstalledAndActive: 'Uw bot is geïnstalleerd en actief!',
    noPendingAction: 'Geen uitstaande actie, Uw bot is geïnstalleerd en actief',
    youAreAllSet: 'U bent helemaal klaar!',
    showMeMyLeads: 'Laat me mijn leads zien',
    connectingToYourWebsiteToVerifyInstallation: 'Verbinding maken met uw website om de installatie van de Bot te verifiëren',
    thisMayTakeUpto30Seconds: 'Dit kan tot 30 seconden duren',
    hereIsTheuniqueAILink: 'Hier is de unieke link naar uw AI Bot',
    installHeading: 'Installeer uw Chatbot',
    websiteChatbot: 'Website Chatbot',
    aiChatBot: 'AI Chatbot',
    landingPageBot: 'Landingspagina Bot',
    mobileChatBot: 'Mobiele App Chatbot',
    embeddedBot: 'Ingebedde Chatbot',
    selectMobileDevice: 'Selecteer uw mobiele apparaat',
    mobileDeviceSubheading: 'Selecteer de inheemse taal die u heeft gebruikt om uw mobiele app te maken',
    ios: 'IOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Andere',
    copyEmbeddedCodeSnippet: 'Kopieer dit codefragment en voeg het in in de HTML van elke webpagina waar u de chatbot wilt weergeven.',
    copyMobileCodeSnippet: 'Kopieer dit codefragment in uw mobiele applicatiecode waar u de chatbot wilt weergeven.',
    placeholders: {
      exampleSite: 'https://www.uwsite.com',
      enterDeveloperEmail: 'Voer het e-mailadres van de ontwikkelaar in'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Stuur instructies naar uw ontwikkelaar',
      notSureHowToDoIt: 'Weet niet zeker hoe het moet? Uw ontwikkelaars kunnen het in een mum van tijd doen.',
      developersEmail: 'E-mail van de ontwikkelaar',
      emailIsRequired: 'E-mail is verplicht',
      subject: 'Onderwerp',
      message: 'Bericht',
      send: 'Verstuur',
      placeholders: {
        enterYourWorkEmail: 'Voer uw zakelijke e-mailadres in',
        createBotUsingBotPenguin: 'Opgetogen! Maak een bot met behulp van BotPenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Hier is de unieke link naar uw Landingspagina Bot.',
      shareWithUsersOnSocialMedia: 'Deel het eenvoudig met uw gebruikers op sociale media, contactpagina\'s of visitekaartjes.',
      previewMyLandingPageBot: 'Voorbeeld van mijn Landingspagina Bot',
      or: 'OF',
      copyUrl: 'Kopieer URL',
      howYouLikeToAddBot: 'Kies hoe u uw Bot-code aan uw website wilt toevoegen',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Installeer de Bot op uw op maat gemaakte code of WordPress-website door een unieke code te kopiëren en te plakken.',
      name: 'Naam',
      actions: 'Acties',
      javascript: 'Javascript',
      install: 'Installeer ',
      onYourCustomCodedWebsite: ' op uw op maat gemaakte website',
      copyAndPaste: 'Kopiëren en plakken',
      copyThisCodeSnippet: 'Kopieer dit codefragment en voeg het in in de HTML van elke webpagina' +
        'waar u de Widget wilt weergeven. Voor meer details',
      seeGuide: 'zie handleiding',
      getCode: 'Krijg Code',
      howToInstall: 'Hoe te installeren?',
      copyCode: 'Kopieer code',
      wordpress: 'WordPress',
      // install: 'Installeer ',
      onYourWordPressWebiste: ' op uw WordPress-website',
      preview: 'Voorbeeld',
      test: 'Test',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Live Chat Voorbeeld',
    agentWindow: 'Venster van Medewerker',
    person: 'persoon',
    inOrderToTestTypeHere: 'Om te testen, typ hier',
    agentWindowCannotBeCustomized: 'Let op: het venster van de medewerker kan niet worden aangepast',
    customerWindow: 'Venster van Klant',
    clickHereToChat: 'Klik hier om te chatten',
    customerWillChatHere: 'De klant zal hier chatten',
    chat: 'Chatten',
    by: 'door',
    changeYourBotLookFromLeftPanel: 'Verander het uiterlijk van uw bot vanuit het linkerpaneel',
    placeholders: {
      typeYourMessage: 'Typ uw bericht...',
      typeYourAnswer: 'Typ uw antwoord',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Bekijk de voorvertoning van uw Live Chat-widget',
    install: 'Installeren',
    seeHowToUse: 'Zie hoe u het kunt gebruiken',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Klik om uw bot op het geselecteerde platform te installeren',
    }
  },
  trainYourBot: {
    trainYourBot: 'Train uw Chatbot',
    knowledgeBase: 'Kennisbank',
    faqs: 'Veelgestelde vragen',
    history: 'Geschiedenis',
    faqHeading: 'Veelgestelde vragen',
    faqSubHeading: 'Antwoorden op uw vragen over het trainen van de chatbot',
    knowledgeBaseHeading: 'Train uw chatbot',
    knowledgeBaseSubheading: 'Train uw chatbot voor verbeterde conversaties',
    websiteLink: 'Website Link',
    uploadFile: 'Upload uw bestand',
    selectFile: 'Selecteer bestand',
    submit: 'Verzenden',
    searchHere: 'Hier zoeken',
    question: 'Vraag',
    answer: 'Antwoord',
    questionPlaceholder: 'Schrijf hier uw vraag',
    answerPlaceholder: 'Schrijf hier uw antwoord',
    save: 'Opslaan',
    discard: 'Afwijzen',
    addFaq: 'Voeg FAQ\'s toe',
    createdAt: 'Aangemaakt op',
    updatedAt: 'Laatst bijgewerkt op',
    actions: 'Acties',
    noFaqFound: 'U heeft nog geen FAQ\'s aangemaakt',
    noFaqFoundMessage: 'Al uw FAQ\'s worden hier weergegeven',
    historyTitle: 'Geschiedenis van kennisbank',
    historySubtitle: 'Antwoorden op uw vragen over het trainen van de chatbot',
    fileName: 'Bestandsnaam',
    status: 'Status',
    noHistoryTitle: 'U heeft nog geen getrainde modellen aangemaakt',
    noHistoryDescription: 'Al uw getrainde modellen worden hier weergegeven',
    fileUpload: 'Bestand uploaden',
    files: 'Bestanden',
    fileSubHeading: 'Voeg alle bestanden toe waarop u uw bot wilt trainen.',
    noFileFoundHeading: 'U heeft nog geen bestanden aangemaakt',
    noFileFoundSubheading: 'Al uw bestanden worden hier weergegeven',
    uploadYourFiles: 'Upload uw bestanden',
    upload: 'Uploaden',
    fileShouldBe: 'Het bestand moet PDF, DOC, DOCX, PPTX, TXT zijn.',
    uploadStatus: 'Uploadstatus',
    uploadStatusSubHeading: 'Uploadstatus van bestanden voor bot-training',
    addFile: 'Bestand toevoegen',
    otherConfiguration: 'AI-instellingen',
    deleteFiles: 'Klik om bestanden te verwijderen',
    websiteURL: 'Website URL\'s',
    websiteUrlSubheading: 'Voeg alle website URL\'s toe waarop u uw bot wilt trainen.',
    enterWebsiteUrl: 'Voer de URL in',
    addWebsite: 'Website toevoegen',
    tableWebsiteUrl: 'Website URL',
    trainingStatus: 'Trainingsstatus',
    lastTrained: 'Laatst getraind',
    noWebsitesFound: 'U heeft nog geen websites aangemaakt',
    noWebsitesFoundMessage: 'Al uw websites worden hier weergegeven',
    loadMore: 'Meer laden',
    botBehaviour: 'Configureer botgedrag',
    botBehaviourSubHeading: 'Configureer de persoonlijkheid van de bot, redigeer klantgegevens, beheer stroom en ChatGPT-model',
    botPersonality: 'Configureer de persoonlijkheid van de bot',
    botPersonalitySubHeading: 'Beheer hoe uw bot moet reageren op basis van persoonlijkheid',
    selectAnswerLength: 'Selecteer antwoordlengte',
    answerLength: 'Antwoordlengte',
    answerAs: 'Antwoord als',
    tone: 'Toon',
    language: 'Taal',
    answerFormatting: 'Antwoordopmaak',
    customInstructions: 'Aangepaste Prompt',
    customerInstructionPlaceholder: 'Wees beleefd en vriendelijk. Gebruik geen straattaal. Gebruik geen emoji.',
    redactCustomerData: 'Klantgegevens verzamelen',
    redactDataSubheading: 'Configureer de klantgegevens die je wilt verzamelen',
    collectName: 'Naam verzamelen',
    configureQuestion: 'Vraag configureren',
    maxCharacterLimit: 'Maximale tekengrens in verstrekte naam',
    collectEmail: 'E-mail verzamelen',
    excludeEmail: 'E-mails met domein uitsluiten',
    collectPhone: 'Telefoon verzamelen',
    manageUnusualMessages: 'Beheer ongebruikelijke chat scenario\'s',
    unusualMessagesSubheading: 'Beheer onbeantwoorde en andere zeldzame scenario\'s voor de bot',
    startConversation: 'Bericht om het gesprek te starten',
    configureMessage: 'Bericht wanneer de bot geen antwoord kan geven',
    profanityMessage: 'Bericht om te reageren op het gebruik van ongepast taalgebruik',
    chatGptModel: 'ChatGPT Model Configuratie',
    chatGptModelSubheading: 'Beheer welk ChatGPT-model te gebruiken en misbruik te voorkomen',
    chatGptApi: 'ChatGPT API-sleutel',
    enableDisableRedactData: 'Klantgegevens verzamelen',
    triggerToCollectData: 'Contactgegevens verzamelen na een reeks vragen.',
    train: 'Trainen',
    website: 'website',
    includedSourcesForResponse: 'Inbegrepen bronnen voor het antwoord',
    visibility: 'Zichtbaarheid',
    analytics: 'Analyse',
    yourConversation: 'Uw Gesprekken',
    reviewAndSelectConversation: 'Recenseer en Selecteer Gesprekken om te Exporteren voor Chatbot Training',
    exportAiAnalytics: 'Exporteer analytics',
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    userQuestion: 'Vraag van Gebruiker',
    botsReply: 'Antwoord van Bot',
    dateCreated: 'Datum Aangemaakt',
    noAnalyticsFound: 'U heeft nog geen enkele analyse gemaakt',
    noAnalyticsFoundMessage: 'Alle uw analytische berichten zullen hier verschijnen',
    exportData: 'Gegevens Exporteren',
    exportCompleteAnalyticsData: 'Volledige analytische gegevens exporteren',
    export: 'Exporteren',
    integrateWithChatGpt: 'Integreren met ChatGPT',
    integrated: 'Integratie',
    configure: 'Configuratie',
    appToolTip: {
      deleteWebsite: 'Klik om websites te verwijderen',
      knowledgeBase: 'Train uw bot met kennisbank',
      faqs: 'Train uw bot met veelgestelde vragen',
      knowledgeHistory: 'Geschiedenis van kennisbank',
      uploadFaqs: 'Veelgestelde vragen uploaden',
      addFaqs: 'Klik om veelgestelde vragen toe te voegen',
      deleteFaqs: 'Veelgestelde vragen verwijderen',
      deleteHistory: 'Geschiedenis verwijderen',
      uploadFile: 'Het geüploade bestand moet pdf, docx, doc of csv zijn en de maximale grootte is 1 MB.',
      trainWebsite: 'Train uw bot met behulp van een website.',
      fileUpload: 'Train uw bot met bestanden zoals PDF\'s, TXT\'s, PPTX\'s, DOC\'s en DOCX\'s.',
      configureBotBehaviour: 'Pas de persoonlijkheid van de bot aan, beheer de stroom en pas het ChatGPT-model aan.',
      clickToAddWebsite: 'Klik om een website toe te voegen',
      clickToDeleteWebsites: 'Klik om websites te verwijderen',
      syncWebsite: 'Synchroniseer website',
      copyUrl: 'Kopieer URL',
      weWillBeFetchingAllTheWebpages: `We zullen alle webpagina's ophalen en het ChatGPT-model erop trainen.`
    },
    modal: {
      deleteWebsiteUrl: 'Website-URL verwijderen',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Weet u zeker dat u deze Website-URL wilt verwijderen?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Website Chatbot Instelling',
    preview: 'Voorbeeld',
    test: 'Test',
    install: 'Installeren',
    designHeader: 'Ontwerp',
    generalHeader: 'Algemeen',
    alertsHeader: 'Meldingen',
    triggerHeader: 'Trigger',
    userAccessHeader: 'Gebruikerstoegang',
    chatWindowHeader: 'Chatvenster',
    otherConfiguration: 'AI-instellingen',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Klik om uw bot op het geselecteerde platform te installeren',
      customizeAppearanceOfBot: 'Pas de positionering en het uiterlijk van uw bot-interface aan.',
      configureBotsSettingsWhenAgentUnavailable: 'Configureer de instellingen van de bot wanneer uw agent niet beschikbaar is.',
      configureHowBotWillAlertYou: 'Configureer hoe uw bot u op de hoogte zal stellen van nieuwe leads.',
      makeSureYourBotsPopUpProperlyTimed: 'Zorg ervoor dat de pop-ups van uw bot perfect getimed zijn.',
      customizeWhereYouWantYourBotToAppear: 'Pas aan waar u wilt dat uw bot verschijnt en waar niet.',
      enableChatOptionsForVisitorsToTransferChat: 'Activeer de chatopties voor uw bezoekers om chats over te dragen, live chat en chat vernieuwen.',
      configureBotBehaviour: 'Pas de persoonlijkheid van de bot aan, beheer de stroom en pas het ChatGPT-model aan.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Configureer de meldingsinstellingen van uw bot',
      specifyHowYourBotWillNotify: 'Geef aan hoe uw bot u op de hoogte zal stellen van nieuwe leads.',
      subject: 'Onderwerp',
      emailNotification: 'E-mailmelding',
      emailAddresses: 'E-mailadressen',
      leadRevisitNotification: 'Meldingen voor herbezoek van leads',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'U hebt zich succesvol geregistreerd bij desktopmeldingen',
      youHaveBlockedYourNotificationsOnBrowser: 'U heeft meldingen geblokkeerd in uw browserinstellingen.',
      discard: 'Verwerpen',
      saveChanges: 'Wijzigingen opslaan',
      seeHowToUse: 'Bekijk hoe u het kunt gebruiken',
      placeholders: {
        enterSubjectForYourEmail: 'Voer het onderwerp in voor uw e-mail',
        typeYourEmailAddressAndHitEnter: 'Typ uw e-mailadres en druk op enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Pas het onderwerp van uw e-mailmeldingen van de bot aan',
        TOOLTIP_EMAIL_NOTIFICATION: 'Schakel de melding van de bot in/uit op uw e-mail voor nieuwe leads',
        TOOLTIP_EMAIL_ADDRESS: 'Voer het e-mailadres in van het account waar u e-mailmeldingen wilt ontvangen. U kunt meerdere e-mailadressen toevoegen door er een in te voeren en op enter te drukken om er nog een toe te voegen.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'Schakel de melding van de bot in/uit op uw e-mail als een gebruiker die eerder als lead is vastgelegd, opnieuw met de bot communiceert.',

      }
    },
    broadcasts: {
      createBroadcastHeading: 'Uitzending maken',
      youCanCreateMultipleBroadcasts: 'U kunt meerdere uitzendingen maken',
      broadcastName: 'Uitzendnaam',
      broadcastNameIsRequired: 'Uitzendnaam is vereist',
      continue: 'Doorgaan',
      manageBroadcasts: 'Beheer uitzendingen',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'U kunt meerdere uitzendingen maken met meerdere geplande sjablonen voor ' +
        'verschillende klantengrorpen.',
      createNewBroadcast: 'Nieuwe uitzending maken',
      name: 'Naam',
      segment: 'Groep',
      template: 'Sjabloon',
      contacts: 'Contacten',
      send: 'Verzenden',
      delivered: 'Afgeleverd',
      read: 'Gelezen',
      failed: 'Mislukt',
      broadcastAt: 'Uitzenden om',
      status: 'Status',
      actions: 'Acties',
      nA: 'N/B',
      seeHowToUse: 'Bekijk hoe u het kunt gebruiken',
      deleteBroadcast: 'Uitzending verwijderen',
      wantToDeleteThisBroadcast: 'Weet u zeker dat u deze uitzending wilt verwijderen?',
      placeholders: {
        searchHere: 'Hier zoeken',
        enterBroadcastName: 'Voer uitzendnaam in',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Meerdere uitzendingen verwijderen',
        broadcastAnalytics: 'Uitzendanalyse',
        deleteBroadcast: 'Uitzending verwijderen',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Uitzendinzichten',
        broadcastStatus: 'Uitzendstatus',
        youCanFindDetailsOfEachContactBelow: 'U kunt hieronder details van elk contact vinden en degenen exporteren die mogelijk problemen hebben.',
        filterApplied: 'Filter toegepast',
        placeholders: {
          searchByPhoneNumber: 'Zoeken op telefoonnummer',
          searchByName: 'Filter zoeken op naam',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Gegevens filteren op status',
          exportData: 'Gegevens exporteren',
        },
        modal: {
          exportData: 'Gegevens exporteren',
          exportCompleteBroadcastData: 'Volledige uitzendgegevens exporteren',
          export: 'Exporteren'
        }
      },
      createBroadcast: {
        broadcastName: 'Uitzendnaam',
        broadcastNameIsRequired: 'Uitzendnaam is vereist',
        template: 'Sjabloon',
        segments: 'groepen',
        whenToSend: 'Wanneer te verzenden',
        now: 'NU',
        later: 'LATER',
        selectBroadcastUsing: 'Selecteer uitzending met behulp van',
        usingCsv: 'Met behulp van CSV',
        usingSegment: 'Met behulp van groepen',
        allContacts: 'Alle contacten',
        selectDate: 'Selecteer datum',
        selectTimeSlot: 'Selecteer tijdsinterval',
        assignVariablesToTemplates: 'Variabelen toewijzen aan sjablonen',
        addHeaderMedia: 'Koptekstmedia toevoegen',
        selectFile: 'Selecteer bestand',
        cancel: 'Annuleren',
        createBroadcast: 'Uitzending maken',
        broadcastUsing: 'Uitzenden met behulp van',
        selectCSVFile: 'Selecteer CSV-bestand',
        importContacts: 'Contacten importeren',
        beforeYouStartUpload: 'Voordat u begint met uploaden, zorg ervoor dat',
        point1: '1. Het voorbeeld CSV-bestand te downloaden.',
        point2: '2. Begin met het invoeren van uw gegevens vanaf de 2e rij.',
        point3: '3. In elke rij is er gegevens voor één contactpersoon',
        point4: '4. Zorg ervoor dat alle telefoonnummers uniek zijn.',
        point5: '5. De maximale bestandsgrootte voor uploaden is 1 MB',
        //
        clickHere: 'Klik hier',
        toDownloadSampleFile: 'om een voorbeeldbestand te downloaden',
        placeholders: {
          enterBroadcastName: 'Voer uitzendnaam in',
          selectTemplate: 'Selecteer sjabloon',
          searchTemplateByName: 'Sjabloon zoeken op naam',
          selectSegments: 'Selecteer groepen',
          selectTimeSlot: 'Selecteer tijdsinterval',
          selectValueForThisVariable: 'Selecteer waarde voor deze variabele',
          searchHeaderByVariableName: 'Zoeken naar koptekstvariabele op naam',
          variableFallbackValue: 'Variabele fallback-waarde',
          searchBodyVariableByName: 'Zoeken naar lichaamsvariabele op naam',
          searchSegmentByName: 'Zoeken naar groep op naam',
          selectBroadcastUsing: 'Selecteer uitzending met behulp van',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecteer startdatum',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Contacten importeren voor WhatsApp-uitzending',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'WhatsApp-nummer',
        errorCode: 'Foutcode',
        errorMessage: 'Foutmelding',
        nA: 'N/B',
      }
    },
    design: {
      customizeYourLandingPage: 'Pas uw landingspagina aan',
      customizeYourConversationalBot: 'Pas uw AI-bot aan',
      customizeYourBot: 'Pas uw bot aan',
      createHeadingTextAndTheme: 'Maak een kopregel en thema die de persoonlijkheid van uw merk weerspiegelen',
      content: 'Inhoud',
      theme: 'Thema',
      layout: 'Indeling',
      landingPageName: 'Naam landingspagina',
      botName: 'Naam bot',
      charactersRemaining: ' tekens over',
      landingPageHeader: 'Kopregel landingspagina',
      landingPageDescription: 'Omschrijving landingspagina',
      hideContent: 'Verberg inhoud',
      profilePic: 'Profielfoto',
      selectFile: 'Selecteer bestand',
      socialMediaLinks: 'Links naar sociale media',
      initialMessage: 'Initiële boodschap van de conversatiebot',
      flowTriggerDetail: 'Trigger om contactgegevens te verzamelen',
      selectType: 'Selecteer Type',
      url: 'URL',
      delete: 'Verwijderen',
      editLink: 'Link bewerken',
      addLink: 'Link toevoegen',
      callToAction: 'Oproep tot actie',
      liveChatWelcomeMessage: 'Welkomstbericht livechat',
      liveChatTriggerMessage: 'Bericht dat livechat activeert',
      autoHideCallToAction: 'Automatisch verbergen van oproep tot actie',
      maxDelayShouldBe300Seconds: 'Maximale vertraging moet 300 seconden zijn',
      landingPageThemeColor: 'Kleur thema landingspagina',
      themeColor: 'Themakleur',
      landingPageBackground: 'Achtergrond landingspagina',
      chatBackgroundColor: 'Achtergrondkleur chat',
      color: 'Kleur',
      gradient: 'Gradiënt',
      image: 'Afbeelding',
      video: 'Video',
      gradientStart: 'Begin gradiënt',
      gradientEnd: 'Einde gradiënt',
      //
      positionOnWeb: 'Positie op web',
      positionOnMobile: 'Positie op mobiel',
      windowSize: 'Venstergrootte',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'Aangepast',
      height: 'Hoogte',
      minimum300HeightIsRequired: 'Minimale hoogte van 300 pixels is vereist',
      heightCannotExceed720: 'Hoogte mag niet meer dan 720 pixels zijn',
      width: 'Breedte',
      px: 'px',
      minimum200WidthIsRequired: 'Minimale breedte van 200 pixels is vereist',
      widthCannotExceed1050: 'Breedte mag niet meer dan 1050 pixels zijn',
      discard: 'Annuleren',
      saveChanges: 'Wijzigingen opslaan',
      landingPageBot: 'Landingspagina Bot',
      chatBot: 'Chatbot',
      preview: 'Voorbeeld',
      test: 'Test',
      botIcon: 'Boticoon',
      websiteBot: 'Website Chatbot',
      selectFont: 'Selecteer lettertype',
      fontLibrary: 'Lettertype bibliotheek',
      uploadFont: 'Lettertype uploaden',
      selectFontForBot: 'Selecteer lettertype voor bot',
      uploadedFont: 'Geüpload lettertype',
      placeholders: {
        enterName: 'Vul naam in',
        enterHeaderText: 'Voer kopregeltekst in',
        enterDescription: 'Voer omschrijving in',
        enterUrl: 'Voer URL in',
        howCanIHelpYou: 'Hoe kan ik u helpen?',
        enterYourWelcomeMessageHere: 'Voer hier uw welkomstbericht in',
        enterYourLiveChatMessageHere: 'Voer hier uw livechatbericht in',
        enterTimeInSeconds: 'Voer tijd in seconden in',
        enterHeight: 'Voer hoogte in',
        enterWidth: 'Voer breedte in',
        flowInitialMessage: 'Voer initiële vraag in',
      },
      appTooltip: {
        standardLayout: 'Standaardindeling',
        rightLayout: 'Rechterindeling',
        mergedLayout: 'Samengevoegde indeling',
        enterNameOfYourBot: 'Voer de naam van uw bot in',
        enterNameOfYourLandingPageBot: 'Voer de naam van uw landingspagina-bot in',
        headingTextThatAppearOnLandingPageBot: 'Voer de kopregeltekst in die op uw landingspagina-bot moet verschijnen',
        descriptionTextThatAppearOnLandingPageBot: 'Voer de omschrijvingstekst in die op uw landingspagina-bot moet verschijnen',
        addLinksAndSocialMediaIcons: 'Voeg links en pictogrammen van sociale media toe',
        initialFlowMessage: 'Voer het eerste bericht in dat de bot zal sturen om het gesprek met de klant te starten',
        anInvitingCatchPhrase: 'Een uitnodigende pakkende zin die de gebruiker met de bot laat praten',
        setAWelcomeMessageForYourVisitors: 'Stel een welkomstbericht in voor uw bezoekers. Dit wordt het eerste bericht in uw gesprek.',
        setAMessageToInitiateLiveChat: 'Stel een bericht in om livechat vanaf de kant van de bezoeker te starten. ' +
          'U ontvangt een melding telkens wanneer dit bericht wordt geactiveerd in een van uw gesprekken.',
        autoHideCallToAction: 'Automatisch verbergen van de "Oproep tot actie" tekst na de opgegeven tijd',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'U kunt ervoor kiezen om het logo en de tekst te verbergen en alleen het chatvenster op het scherm te tonen',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Upload een logo voor de chatbot-pagina.',
        TOOLTIP_CHAT_THEME_COLOR: 'Kies de kleur van het chatvenster van uw bot',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Kies de achtergrondkleur van het chatvenster van uw bot',
        TOOLTIP_WEB_1ST_POSITION: 'Plaats uw bot linksonder op het bureaublad van de gebruiker',
        TOOLTIP_WEB_2ND_POSITION: 'Plaats uw bot rechtsboven in de hoek van het bureaublad van de gebruiker',
        TOOLTIP_WEB_3RD_POSITION: 'Plaats uw bot rechtsonder in de hoek van het bureaublad van de gebruiker',
        TOOLTIP_MOBILE_1ST_POSITION: 'Plaats uw bot linksonder in de hoek van het mobiele apparaat van de gebruiker',
        TOOLTIP_MOBILE_2ND_POSITION: 'Plaats uw bot rechtsboven in de hoek van het mobiele apparaat van de gebruiker',
        TOOLTIP_MOBILE_3RD_POSITION: 'Plaats uw bot rechtsonder in de hoek van het mobiele apparaat van de gebruiker',
        TOOLTIP_WINDOW_HEIGHT: 'Verander de grootte van het chatvenster van de bot',
        TOOLTIP_BOT_ICON: 'Selecteer een pictogram of upload een logo/afbeelding voor uw bot',
        flowTriggerMessage: 'Voer het aantal berichten in dat moet worden gevraagd voordat contactgegevens zoals naam, e-mail en telefoonnummer worden verzameld',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Drip Campagne Creëren',
      youCanCreateMultipleCampaigns: 'U kunt meerdere campagnes aanmaken',
      dripCampaignName: 'Naam Drip Campagne',
      dripCampaignNameIsReuired: 'Naam Drip Campagne is verplicht',
      continue: 'Doorgaan',
      manageDripCampaign: 'Beheer Drip Campagne',
      youCanCreateMultipleCampaignsWith: 'U kunt meerdere drip campagnes aanmaken met geplande, geautomatiseerde berichten om ' +
        'reacties van specifieke gebruikersgroepen te activeren.',
      createNewDripCampaign: 'Nieuwe Drip Campagne Aanmaken',
      campaignName: 'Campagnenaam',
      appliedOn: 'Toegepast Op',
      segment: 'Groep',
      template: 'Sjabloon',
      startDate: 'Startdatum',
      action: 'Actie',
      nA: 'N.v.t.',
      seeHowToUse: 'Zie hoe te gebruiken',
      deleteDripCampaign: 'Drip Campagnes Verwijderen',
      wantToDeleteThisDripCampaign: 'Weet u zeker dat u deze Drip Campagnes wilt verwijderen?',
      placeholders: {
        enterDripCampaignName: 'Voer Naam Drip Campagne In',
        searchByName: 'Zoeken op naam'
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Verwijder meerdere drip campagnes',
        deleteDripCampaign: 'Drip Campagne Verwijderen',
      },
      createDripCampaign: {
        dripCampaignName: 'Naam Drip Campagne',
        appliedOn: 'Toegepast Op',
        segments: 'Goepen',
        selectDate: 'Selecteer Datum',
        scheduleYourTemplate: 'Plan Uw Sjablonen In',
        schedule: 'Inplannen',
        template: 'Sjabloon',
        sendAfter: 'Verstuur Na',
        assignVariablesToTemplate: 'Variabelen toewijzen aan sjablonen',
        addHeaderMedia: 'Voeg koptekst media toe ',
        selectFile: 'Selecteer Bestand',
        createDripCampaign: 'Drip Campagne Aanmaken',
        newSubscriber: 'Nieuwe Abonnee',
        minutes: 'Minuten',
        hours: 'Uren',
        days: 'Dagen',
        placeholders: {
          enterDripName: 'Voer Drip naam in',
          selectAppliedOn: 'Selecteer toegepast op',
          selectSegments: 'Selecteer groepen',
          searchByName: 'Zoeken op naam',
          selectTemplate: 'Selecteer Sjabloon',
          searchTemplateByName: 'Zoeken op Sjabloon Naam',
          enterSendAfter: 'Voer Verstuur Na in',
          selectValueForThisVariable: 'Selecteer waarde voor deze variabele',
          searchHeaderVariableByName: 'Zoeken op Koptekst Variabele Naam',
          searchBodyVariableByName: 'Zoeken op lichaamsvariabele op naam',
        },
        dateTimePicker: {
          selectStartDate: 'Selecteer startdatum',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Configureer de Algemene instellingen van uw landingspagina',
      configureBotsGeneralSettings: 'Configureer de Algemene instellingen van uw bot',
      selectTheTimeZone: 'Selecteer de tijdzone, taal en een kleine opmerking voor uw klanten wanneer de ',
      isNotAvailable: ` niet beschikbaar is`,
      landingPagesBot: `landingspagina's bot`,
      bot: 'bot',
      waitingMessage: 'Wachtbericht',
      landingPageBotStatus: 'Status Landingspagina Bot',
      chatBotStatus: 'Status Chatbot',
      unavailabilityMessage: 'Bericht van Niet-beschikbaarheid',
      sendIncompleteResponses: 'Stuur Onvolledige Reacties',
      language: 'Taal',
      invalidateCache: 'Cache Ongeldig Maken',
      timezone: 'Tijdzone',
      chatWindowMessageHistory: 'Geschiedenis Chatvenster Berichten',
      session: 'sessie',
      forever: 'voor altijd',
      english: 'Engels',
      spanish: 'Spaans',
      french: 'Frans',
      portuguese: 'Portugees',
      german: 'Duits',
      italian: 'Italiaans',
      swedish: 'Zweeds',
      arabic: 'Arabisch',
      malay: 'Maleis',
      russian: 'Russisch',
      discard: 'Verwerpen',
      saveChanges: 'Wijzigingen Opslaan',
      seeHowToUse: 'Zie hoe te gebruiken',
      placeholders: {
        enterYourWaitingMessageHere: 'Voer hier uw wachtbericht in',
        //
        searchTimeZone: 'Tijdzone zoeken',
        //
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Pas het bericht aan zoals u wilt dat het direct verschijnt op het chatvenster nadat een botgebruiker een verzoek heeft ingediend voor Live Chat',
        TOOLTIP_CHATBOT_STATUS: 'Activeer / deactiveer de bot',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Pas het bericht aan zoals u wilt dat het verschijnt op het chatvenster wanneer geen van uw medewerkers beschikbaar is voor Live Chat met de botgebruiker',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Stuur e-mailmeldingen, zelfs wanneer de botgebruiker het gesprek niet heeft afgerond.',
        TOOLTIP_LANGUAGE: 'Selecteer de taal van de bot',
        TOOLTIP_INVALIDATE_CACHE: 'Klik hier om de cache ongeldig te maken wanneer u bot-chatflow of instellingen bewerkt, omdat wijzigingen pas na 15 minuten van kracht worden',
        TOOLTIP_TIME_ZONE: 'Selecteer de tijdzone voor uw bot',
        TOOLTIP_CHAT_RETENTION: 'Selecteer of u de chatgesprekken van botgebruikers voor altijd in het chatvenster wilt behouden, of alleen voor een browsersessie.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Pas uw chatvenster aan',
      controlAndCustomizeTheChatWindow: 'Beheer en pas het chatvenster aan waarmee uw klanten zullen communiceren.',
      allowVisitorsToTransferChatToWhatsapp: 'Sta bezoekers toe om de chat over te zetten naar WhatsApp',
      allowVisitorsToRefreshChat: 'Sta bezoekers toe om de chat te vernieuwen',
      displayNameForTransferringChatToWhatsapp: 'Weergavenaam voor het overzetten van een chat naar WhatsApp',
      displayNameForTransferringToLiveChat: 'Weergavenaam voor het overzetten naar Live/Agent-chat',
      displayNameForTransferringAChatToBot: 'Weergavenaam voor het overzetten van een chat naar de Bot',
      discard: 'Afwijzen',
      saveChanges: 'Wijzigingen opslaan',
      allowVisitorsToTransferChatToBot: 'Sta bezoekers toe om de chat over te zetten naar de Bot.',
      allowVisitorsToRequestForLiveChat: 'Sta bezoekers toe om een live chat aan te vragen.',
      indicativeTextForCustomersReply: 'Indicatieve tekst voor het antwoord van de klant',
      displayNameForRestartingChatBot: 'Weergavenaam voor het herstarten van de chatbot-conversatie',
      seeHowToUse: 'Bekijk hoe u het moet gebruiken',
      placeholders: {
        enterButtonNameForWhatsapp: 'Voer de knopnaam in voor het overzetten naar WhatsApp',
        enterButtonNameForLiveChat: 'Voer de knopnaam in voor het overzetten naar live chat of agent',
        enterButtonNameForBot: 'Voer de knopnaam in voor het overzetten naar de Bot',
        enterTextDisplayInEmptyReplyArea: 'Voer de tekst in die wordt weergegeven in het lege antwoordgebied',
        enterButtonForRestartingConversation: 'Voer de knopnaam in voor het herstarten van de conversatie',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Sta websitebezoekers toe om de chat over te zetten naar WhatsApp van de betreffende agent waarmee ze zijn verbonden. De Live Chat-functie moet zijn ingeschakeld voor deze functie om te werken.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Sta de botgebruikers toe om de chat te vernieuwen, zodat ze het gesprek opnieuw kunnen beginnen.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Sta de botgebruikers toe om de chat op elk moment tijdens het gesprek met de live chat-agent over te zetten naar de bot.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Sta de botgebruikers toe om een live chat aan te vragen bij de agent wanneer ze dat willen.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Als u de gebruiker hebt toegestaan om over te zetten naar WhatsApp, vul dan in hoe u die optie wilt weergeven',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Als u de gebruiker hebt toegestaan om over te zetten naar live chat, vul dan in hoe u die optie wilt weergeven',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Als u de gebruiker hebt toegestaan om over te zetten naar WhatsApp, vul dan in hoe u terug wilt overstappen naar de bot',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Vul in hoe u de antwoordruimte voor klanten wilt weergeven',
        ifYouHaveEnabledUserToRestartChatBot: 'Als u de gebruiker hebt toegestaan om de chatbot-conversatie te herstarten, vul dan in hoe u die optie wilt weergeven',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Chatflows',
      dripCampaignHeader: 'Drip-campagne',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Uitzending',
      ordersHeader: 'Bestellingen',
      manageHeader: 'Beheren',
      inputHeader: 'Invoer',
      apiKeyHeader: 'API-sleutel',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Maak een klantboordingsproces aan met behulp van een drip-campagne van geplande chatflows.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Pas de chatflow-sjablonen aan en maak meerdere chatflows voor verschillende doelgroepen.',
        TOOLTIP_WIDGET_TITLE: 'Maak widgets (chatinterfaces) en integreer ze in promotionele webpagina\'s om bezoekers naar de bot te leiden.',
        TOOLTIP_BROADCAST_TITLE: 'Verstuur berichten of flows naar uw contacten met één klik op het vooraf ingestelde tijdstip.',
        TOOLTIP_ORDERS_TITLE: 'Lijst met bestellingen geplaatst door de klant.',
        TOOLTIP_MANAGE_TITLE: 'Beheer de botfunctionaliteit voor contacten/leads en zoekwoorden.',
        TOOLTIP_INPUT_TITLE: 'Maak WhatsApp-sjablonen, afbeeldingen en tekst als antwoordmateriaal voor contacten/leads.',
      },
      apiWrapper: {
        whatsappApiCredentials: 'WhatsApp API-gegevens',
        generateYourWhatsappApiKey: 'Genereer uw WhatsApp API-sleutel om te integreren met uw aangepaste ontwikkelde apps.',
        generateApiKey: 'Genereer API-sleutel',
        webhookUrl: 'Webhook-URL',
        addHeader: 'Koptekst toevoegen',
        test: 'Testen',
        webhookNotSet: 'Webhook niet ingesteld',
        webhookHeaders: 'Webhook-kopteksten',
        setWebhookUrl: 'Webhook-URL instellen',
        placeholders: {
          yourApiKey: 'Uw API-sleutel',
          enterWebhookUrl: 'Voer Webhook-URL in',
          enterHeaderKey: 'Voer Koptekst Sleutel in',
          enterValue: 'Voer Waarde in',
        }
      },
      broadcast: {
        createBroadcast: 'Creëer Uitzending',
        createMultipleBroadcasts: 'U kunt meerdere uitzendingen maken met meerdere geplande stromen/sjablonen voor verschillende klantgroepen.',
        broadcastName: 'Uitzendnaam',
        nameIsRequired: 'Naam is verplicht',
        selectType: 'Selecteer Type',
        selectYourFlowType: 'Selecteer uw flowtype',
        segment: 'groep',
        selectYourSegmentType: 'Selecteer uw groep type',
        whenToSend: 'Wanneer te verzenden',
        selectDate: 'Selecteer Datum',
        messageTag: 'Bericht Tag',
        selectMessageTag: 'Selecteer berichttag',
        contentType: 'Inhoudstype',
        nonPromotional: 'Niet-promotioneel (berichttags toegepast)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Tag elk bericht dat voorafgaat aan een interactie of na vertragingstappen van meer dan 24 uur met de juiste tag. ' +
          'Niet-getagde berichten bereiken geen abonnees die meer dan 24 uur ' +
          'geleden met de bot hebben geïnteracteerd.',
        promotional: 'Promotioneel (berichttags genegeerd)',
        theseBroadcasts: 'Deze uitzendingen kunnen promoties bevatten, maar de ' +
          'doelgroep is beperkt tot abonnees die de afgelopen 24 uur met uw bot hebben geïnteracteerd.',
        createNewBroadcast: 'Nieuwe uitzending maken',
        sentMessages: 'Verzonden berichten',
        deliveredMessages: 'Geleverde berichten',
        readMessages: 'Gelezen berichten',
        failedMessages: 'Mislukte berichten',
        //
        type: 'Type',
        template: 'Sjabloon',
        sent: 'Verzonden',
        delivered: 'Geleverd',
        read: 'Gelezen',
        failed: 'Mislukt',
        broadcastAt: 'Uitzending op',
        status: 'Status',
        shipmentUpdate: 'Verzendingsupdate',
        //
        flow: 'Flow',
        resendFailedMessages: 'Mislukte berichten opnieuw verzenden',
        //
        //
        goBack: 'Terug',
        number: 'Nummer',
        errorCode: 'Foutcode',
        failedAt: 'Mislukt op',
        badGateway: '502 slechte gateway',
        loadMore: 'Meer laden',
        placeholders: {
          enterBroadcastName: 'Voer uitzendnaam in',
          searchBroadcastByName: 'Zoek uitzending op naam',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Totaal aantal verzonden uitzendberichten',
          totalBroadcastMessagesDelivered: 'Totaal aantal geleverde uitzendberichten',
          totalBroadcastMessagesReadByContacts: 'Totaal aantal uitzendberichten dat door de contacten is gelezen',
          totalFailedBroadcastMessages: 'Totaal aantal mislukte uitzendberichten',
          clickToViewDetailedInformationOfFailedMessages: 'klik om gedetailleerde informatie over mislukte berichten te bekijken',
          clickToCreateNewBroadcast: 'Klik om een nieuwe uitzending te maken',
          templateUsedInBroadcast: 'Sjabloon gebruikt in de uitzending',
          numberOfBroadcastMessagesSent: 'Aantal verzonden uitzendberichten',
          numberOfBroadcastMessagesDelivered: 'Aantal geleverde uitzendberichten',
          numberOfBroadcastMessagesReadByContacts: 'Aantal uitzendberichten dat door de contacten is gelezen',
          numberOfTimesBroadcastMessagesHasFailed: 'Aantal keren dat uitzendberichtgeving is mislukt',
          contactNumberOnWhichMessageDeliveryFailed: 'Contactnummer waarop bezorging van het bericht is mislukt',
          unDeliveredTemplate: 'Niet-afgeleverd sjabloon',
          errorReceivedForFailedMessage: 'Fout ontvangen voor mislukt bericht',
          timeWhenMessageDeliveryFailed: 'Tijd waarop bezorging van het bericht is mislukt',
          TOOLTIP_ADD_BROADCAST_NAME: 'Voer een nieuwe uitzendnaam in',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Selecteer of u een flow of een bericht uitzendt.',
          TOOLTIP_BROADCAST_FLOW: 'Selecteer de chatflow die u wilt uitzenden.',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Selecteer de contactgroep waarvoor u wilt uitzenden',
          TOOLTIP_BROADCAST_NAME: 'Naam van de uitzending',
          TOOLTIP_BROADCAST_TYPE: 'Flow of bericht geselecteerd voor de uitzending',
          TOOLTIP_BROADCAST_SEGMENT: 'Groep waarvoor de uitzending is gemaakt',
          TOOLTIP_BROADCAST_AT: 'Datum en tijd gepland voor de uitzending',
          TOOLTIP_BROADCAST_STATUS: 'Uitzendstatus als gepland, momenteel bezig of voltooid',
        }
      },
      drip: {
        createDripCampaign: 'Maak Druppelcampagne',
        youCanCreateMultipleCampaigns: 'Je kunt meerdere campagnes aanmaken',
        dripCampaignName: 'Naam Druppelcampagne',
        dripCampaignNameIsRequired: 'Naam van druppelcampagne is verplicht',
        //
        youCanCreateMultipleDripCampaigns: 'Je kunt meerdere druppelcampagnes aanmaken met geplande, geautomatiseerde berichten om reacties te triggeren van specifieke gebruikersgroepenen.',
        campaignName: 'Campagnenaam',
        campaignNameIsRequired: 'Campagnenaam is verplicht',
        after: 'Na ',
        thisMessageWillBeSendAfter: 'Dit bericht wordt verstuurd na',
        saveAndUpdate: 'Opslaan en bijwerken',
        schedule: 'Inplannen',
        select: 'Selecteren',
        messages: 'Berichten',
        cancel: 'Annuleren',
        createCampaign: 'Campagne aanmaken',
        updateCampaign: 'Campagne bijwerken',
        createNewCampaign: 'Nieuwe campagne aanmaken',
        //
        messagesSent: 'Berichten verstuurd',
        createdAt: 'Aangemaakt op',
        updatedAt: 'Bijgewerkt op',
        action: 'Actie',
        placeholders: {
          nameYourDripCampaign: 'Geef je druppelcampagne een naam',
          enterCampaignName: 'Voer campagnenaam in',
          searchCampaignByName: 'Zoek campagne op naam',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Klik om een nieuwe campagne toe te voegen',
          TOOLTIP_SCHEDULE: 'Plan wanneer en welk chatflow als eerste in deze campagne moet worden verzonden.',
          TOOLTIP_AFTER_DAYS: 'Plan wanneer de eerste flow van de campagne moet worden verzonden, in minuten, uren of dagen.',
          TOOLTIP_ADD_MESSAGES: 'Klik om een nieuwe flow aan de campagne toe te voegen',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Voer een nieuwe campagnenaam in',
          TOOLTIP_CAMPAIGN_NAME: 'Naam van de campagne',
          TOOLTIP_NO_MESSAGES_SENT: 'Aantal berichten verstuurd door de campagne',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Datum en tijd van aanmaak van de campagne',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Datum en tijd van laatste wijziging aan de campagne',
          TOOLTIP_CAMPAIGN_ACTION: 'Bewerk of verwijder de campagne',
          TOOLTIP_UPDATE_CAMPAIGN: 'Klik om de wijzigingen in de campagneset-up op te slaan',
        }
      },
      flows: {
        chatFlows: 'Chatflows',
        createChatFlow: 'Maak Chatflow',
        youCanCreateMultipleConversationsFlow: 'Je kunt meerdere chatflows aanmaken over meerdere kanalen voor A/B-testen en uitzendingen.',
        flowName: 'Flow naam',
        nameIsRequired: 'Naam is verplicht',
        cancel: 'Annuleren',
        createFlow: 'Flow aanmaken',
        createNewFlow: 'Nieuwe flow aanmaken',
        //
        ofMessages: 'van berichten',
        triggered: 'Getriggerd',
        stepsFinsished: 'Stappen voltooid',
        finished: 'Voltooid',
        createdOn: 'Aangemaakt op',
        lastModified: 'Laatst gewijzigd',
        defaultFlow: 'Standaardflow',
        action: 'Actie',
        flowAnalytics: 'Flow-analyse',
        placeholders: {
          enterFlowName: 'Voer flow naam in',
          searchFlowByName: 'Zoek flow op naam',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'Aantal keren dat de chatflow is getriggerd',
          numberOfCompletedChatFlowStepsWhenTriggered: 'Aantal voltooide chatflowstappen wanneer deze wordt getriggerd',
          numberOfTimesUsersCompletedChatFlowSteps: 'Aantal keren dat gebruikers alle chatflowstappen hebben voltooid',
          dateAndTimeOfCreationOfChatFlow: 'Datum en tijd van aanmaak van de chatflow',
          dateAndTimeWhenChatFlowLastModified: 'Datum en tijd van laatste wijziging aan de chatflow',
          TOOLTIP_CREATE_FLOW: 'Klik om een nieuwe chatflow aan te maken',
          TOOLTIP_FLOW_NAME: 'Voer de naam van de chatflow in',
          TOOLTIP_FLOW_TABLE_NAME: 'Naam van de chatflow',
          TOOLTIP_NO_OF_MESSAGES: 'Aantal berichtcomponenten in de chatflow',
          TOOLTIP_DEFAULT_FLOW: 'Gebruik deze schakelaar om een chatflow als standaard in te stellen, die aan alle nieuwe gebruikers wordt geserveerd.',
          TOOLTIP_ACTION: 'Bewerk of verwijder de chatflow',
        }
      },
      manage: {
        leads: 'Leads',
        manageBotsFunctionalityForContacts: 'Beheer de functionaliteit van de bot voor contacten/leads',
        whenToSendLeadInfo: 'Wanneer om leadinformatie te versturen',
        smartFlow: 'Slimme Flow',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Welkomstbericht wanneer er geen zoekwoordovereenkomst is',
        selectTemplates: 'Selecteer Sjablonen',
        selectFlows: 'Selecteer Flows',
        selectText: 'Selecteer Tekst',
        selectImages: 'Selecteer Afbeeldingen',
        whenItAlmostReaches24HoursSinceMessage: 'Wanneer het bijna 24 uur is sinds het laatste bericht',
        //
        updateChanges: 'Wijzigingen bijwerken',
        manageBotsFunctionalityAccordingToKeywords: 'Beheer de functionaliteit van de bot op basis van zoekwoorden ingevoerd door de bot-gebruiker, in geval van slimme flow',
        addKeywordAction: 'Zoekwoordactie toevoegen',
        keywords: 'Zoekwoorden',
        matchingMethod: 'Overeenkomende methode',
        replyMaterial: 'Antwoordmateriaal',
        action: 'Actie',
        select: 'Selecteer',
        placeholders: {
          keyword: 'zoekwoord'
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Stel voor wanneer de bot direct contact-/leadinformatie naar het integratie-CRM moet sturen, of na 5 minuten, of aan het einde van de flow',
          TOOLTIP_LEAD_SMART_FLOW: 'Kies een standaardflow om naar alle botgebruikers te sturen, of slimme flow (bepaald door de zoekwoorden ingevoerd door de bot-gebruiker).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Selecteer antwoordmateriaal (flow, sjabloon, tekst, afbeelding) om te sturen als de bot geen zoekwoordovereenkomst van de bot-gebruikersrespons ontvangt',
          TOOLTIP_IT_ALMOST_REACHED: 'Selecteer antwoordmateriaal om naar contacten/leads te sturen net voor 24 uur na het laatste bericht',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Beheer de functionaliteit van de bot op basis van zoekwoorden ingevoerd door de bot-gebruiker, in geval van slimme flow',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Selecteer bot-antwoord als sjabloon, flow, tekst of afbeelding tegen specifieke ingevoerde zoekwoorden',
          TOOLTIP_ADD_KEYWORD: 'Zoekwoorden toevoegen',
          TOOLTIP_MATCHING_METHOD: 'Voeg zoekwoordovereenkomst toe als \'exact\' of \'bevat\'',
          TOOLTIP_REPLY_MATERIAL: 'Selecteer sjabloon, flow, tekst of afbeelding om terug te antwoorden voor het voorgestelde zoekwoord',
          TOOLTIP_KEYWORD_ACTIONS: 'Verwijder de zoekwoordactie',
        },
        socialAlerts: {
          alerts: 'Meldingen',
          specifyHowYourBotWillNotify: 'Geef aan hoe je bot je op de hoogte zal stellen van nieuwe leads.'
        }
      },
      orders: {
        manageCustomersOrder: 'Beheer klantbestellingen',
        checkAndManageAllOfYourCustomerOrders: 'Controleer en beheer al uw klantbestellingen en updates.',
        selectShipmentTemplate: 'Selecteer Verzendingsjabloon',
        send: 'Verzenden',
        export: 'Exporteren',
        phoneNumber: 'Telefoonnummer',
        numberOfItems: 'Aantal Artikelen',
        orderValue: 'Bestelwaarde',
        orderDate: 'Besteldatum',
        status: 'Status',
        chat: 'Chat',
        item: 'Artikel',
        quantity: 'Hoeveelheid',
        amount: 'Bedrag',
        unitPrice: 'Eenheidsprijs',
        placeholders: {
          searchOrdersByPhoneNumber: 'Zoek bestellingen op telefoonnummer',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Exporteer bestelgegevens in csv-bestand',
          TOOLTIP_ORDERS_PHONE_NO: 'Telefoonnummer van de klant die de bestelling heeft geplaatst',
          TOOLTIP_NO_OF_ITEMS: 'Aantal bestelde artikelen',
          TOOLTIP_ORDER_VALUE: 'Waarde van de bestelling',
          TOOLTIP_ORDER_DATE: 'De bestelling is geplaatst op deze datum.',
          TOOLTIP_ORDER_STATUS: 'Status van de bestelling als bevestigd, in behandeling, geweigerd of verzonden',
          TOOLTIP_ORDER_CHAT: 'Alle vorige chatgesprekken met betrekking tot deze bestelling',

        }
      },
      widgets: {
        installWidgetHeader: 'Installeer Widget',
        createWidgetHeader: 'Maak Widget',
        copyTheCodeAndPasteItWhereYouWant: 'Kopieer de code en plak deze waar u deze nodig heeft in uw HTML, website, enz.',
        youCanCreateMultipleWidgets: 'U kunt meerdere widgets maken om uw bot een andere uitstraling te geven op verschillende webpagina\'s.',
        installWidget: {
          javascript: 'JavaScript',
          install: 'Installeren',
          onYourWebsite: ' op uw website',
          copyCode: 'Kopieer code',
          copyAndPaste: 'Kopieer en plak',
          copyThisCodeSnippet: 'Kopieer dit codefragment en voeg het in de HTML van elke webpagina in waar ' +
            'u de Widget wilt weergeven. Voor meer details',
          seeGuide: 'zie handleiding',
        },
        widgetList: {
          widgetName: 'Widgetnaam',
          nameIsRequired: 'Naam is verplicht',
          cancel: 'Annuleren',
          createWidget: 'Widget Maken',
          icon: 'Icoon',
          name: 'Naam',
          createdAt: 'Gemaakt Op',
          action: 'Actie',
          placeholders: {
            enterWidgetName: 'Voer widgetnaam in',
            searchWidgetByName: 'Zoek widget op naam',
          },
          appTooltip: {
            widgetIcon: 'Widget-icoon',
            nameOfWidget: 'Naam van de widget',
            dateAndTimeOfCreationOfWidget: 'Datum en tijd van creatie van de widget',
            editOrDeleteWidget: 'Bewerk of verwijder de widget',
          }
        },
        widgetTemplates: {
          widgetName: 'Widgetnaam',
          nameIsRequired: 'Naam is verplicht',
          chooseTypeOfWidget: 'Kies het type Widget',
          bar: 'Balk',
          slideIn: 'Inschuiven',
          pageTakeOver: 'Paginaovername',
          button: 'Knop',
          facebookModal: 'Facebook Modaal',
          comment: 'Reactie',
          direct: 'Direct',
          whatsappIcon: 'WhatsApp Icoon',
          refUrl: 'Ref URL',
          qrCode: 'QR-code',
          //
          backgroundColor: 'Achtergrondkleur',
          descriptionColor: 'Beschrijvingskleur',
          headlineColor: 'Koptekstkleur',
          buttonBackgroundColor: 'Knop Achtergrondkleur',
          contactNumber: 'Contactnummer',
          entryMessage: 'Toegangsbericht',
          buttonText: 'Tekst Knop',
          selectButtonText: 'Selecteer Knop Tekst',
          buttonSize: 'Knopgrootte',
          selectOne: 'Selecteer Een',
          placement: 'Plaatsing',
          rightBottom: 'Rechts-Onder',
          optInFlow: 'Opt-in Flow',
          selectFlow: 'Selecteer Flow',
          addSubscriberToSequence: 'Abonnee Toevoegen aan Volgorde',
          selectDrip: 'Selecteer Drip',
          whenDoesItDisplay: 'Wanneer wordt het weergegeven (seconden)',
          showWidgetToSameUserAgainAfter: 'Toon de widget opnieuw aan dezelfde gebruiker na',
          ifManuallyClosedByUser: 'Als handmatig gesloten door gebruiker, toon na',
          //
          specificPosts: 'Specifieke Berichten',
          allPosts: 'Alle Berichten',
          //
          postMessage: 'Bericht bij Bericht',
          postDate: 'Berichtdatum',
          status: 'Status',
          action: 'Actie',
          discard: 'Afbreken',
          saveChanges: 'Wijzigingen Opslaan',
          nextStep: 'Volgende Stap',
          widgetDescription: 'Widget Beschrijving',
          integrateTheBarWidget: 'Integreer de Balk Widget in de body van uw website en verhoog uw paginageabonneerde' +
            ' samen met het sturen van aangepaste botberichtenflow.',
          hereIsYourWidgetHeadline: 'Hier is uw widget kop, klik hier om het te wijzigen.',
          sendUsMessage: 'Stuur ons een Bericht',
          weAlsoPutDefaultTextHere: 'We plaatsen hier ook standaardtekst. Zorg ervoor dat u het in een waardevol bericht verandert',
          selectYourPagePost: 'Selecteer Uw Paginabericht',
          nA: 'N.v.t.',
          //
          saveRule: 'Regel Opslaan',
          placeholders: {
            enterWidgetName: 'Voer widgetnaam in',
            enterNumber: 'Voer Nummer in',
            enterMessageHere: 'Voer hier een bericht in',
            enterTimeInSeconds: 'Voer tijd in seconden in',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: `Pas de triggerinstellingen van uw bot aan`,
      makeYourBotsTimingPerfect: `Maak de timing van uw bot perfect.`,
      onDesktopTriggerTime: 'Op-desktop trigger tijd (seconden)',
      onMobileTriggerTime: 'Op-mobiele trigger tijd (seconden)',
      chatBubble: 'Automatisch openen van chatballon',
      seconds: 'Seconden',
      discard: 'Afbreken',
      saveChanges: 'Wijzigingen Opslaan',
      autoTriggerOnOrOff: 'Auto Trigger: Aan/Uit',
      seeHowToUse: 'Zie hoe het te gebruiken',
      alwaysOpenAfter: 'Altijd openen na bovenstaande gespecificeerde triggers',
      automaticallyAfterSelectedNumberOfTime: 'Automatisch na geselecteerde tijdsduur',
      neverAutomaticallyOpenBot: 'De bot nooit automatisch openen',
      placeholders: {
        enterTimeInSeconds: 'Voer tijd in seconden in',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Wachttijd in seconden op de desktopapparaten van bezoekers, voordat uw bot automatisch verschijnt',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Wachttijd in seconden op de mobiele apparaten van bezoekers, voordat uw bot automatisch verschijnt',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Wanneer de bezoeker het chatvenster sluit, selecteer hoe het gedrag van de automatische pop-up van de bot moet zijn',
        customerWaitingTimeToOpenBubble: 'Wachttijd van de klant om de chatballon te openen',
        wantToDisableAutoTriggering: 'klik hier als u de automatische triggering van de bot op webpagina\'s wilt uitschakelen'
      }
    },
    userAccess: {
      customizeYourBotsSettings: `Pas de gebruikersinstellingen van uw bot aan`,
      decideWhoCanAccessYourBot: 'Beslis wie toegang heeft tot uw bot',
      deviceSupport: 'Apparaatondersteuning',
      blockIpAddresses: 'Blokkeer IP-adressen',
      hideChatBotOnSpecificPages: 'Verberg Chatbot op specifieke pagina\'s',
      selectCountriesToEnableBot: 'Selecteer landen om de bot in te schakelen',
      discard: 'Afbreken',
      saveChanges: 'Wijzigingen Opslaan',
      seeHowToUse: 'Zie hoe het te gebruiken',
      allDevices: 'Alle Apparaten',
      mobileOnly: 'Alleen Mobiel',
      desktopOnly: 'Alleen Desktop',
      placeholders: {
        enterIpAddress: `Voer IP-adres (ipv4 of ipv6) in en druk op 'Enter'`,
        enterUrl: `Voer de URL van pagina's in en druk op 'Enter'`,
        searchCountry: 'Zoek land',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Selecteer op welke apparaten uw bot moet verschijnen',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Voer IP-adressen in waar u niet wilt dat uw bot verschijnt',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Voer URL\'s van uw webpagina\'s in waar u niet wilt dat uw bot verschijnt',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Selecteer locaties van waaruit u wilt dat gebruikers toegang hebben tot uw bot',
      }
    },
    facebook: {
      facebookBotSettings: 'Facebook Bot Instellingen',
      chatFlowsHeader: 'Chat Flows',
      dripCampaignHeader: 'Drip Campagne',
      broadcastHeader: 'Uitzending',
      widgetsHeader: 'Widgets',
      autoReplyHeader: 'Automatisch antwoord',
      generalHeader: 'Algemeen',
      facebookAutomation: 'Facebook Automatisering',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Pas de chatflow-sjablonen aan en maak meerdere chatflows voor verschillende doelgroepen.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Maak een klant-onboardingtraject met behulp van een druppelcampagne van geplande chatflows.',
      },
      broadcasts: {
        createBroadcast: 'Uitzending Maken',
        youCanCreateMultipleBroadcasts: 'Je kunt meerdere uitzendingen maken',
        broadcastName: 'Uitzendingsnaam',
        broadcastNameIsRequired: 'Uitzendingsnaam is verplicht',
        continue: 'Doorgaan',
        manageBroadcasts: 'Uitzendingen Beheren',
        youCanCreateMultipleBroadcastsWith: 'Je kunt meerdere uitzendingen maken met meerdere geplande sjablonen voor verschillende klantengroepenen.',
        createNewBroadcast: 'Nieuwe Uitzending Maken',
        name: 'Naam',
        segment: 'Groep',
        template: 'Sjabloon',
        contacts: 'Contacten',
        send: 'Verstuur',
        failed: 'Mislukt',
        broadcastAt: 'Uitzenden op',
        status: 'Status',
        actions: 'Acties',
        nA: 'NA',
        deleteBroadcast: 'Uitzending Verwijderen',
        wantToDeleteThisBroadcast: 'Weet je zeker dat je deze Uitzending wilt verwijderen?',
        placeholders: {
          enterBroadcastName: 'Voer uitzendingsnaam in',
          searchHere: 'Hier zoeken',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Verwijder meerdere uitzendingen',
          deleteBroadcast: 'Uitzending Verwijderen',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Uitzendingsnaam',
        broadcastNameIsRequired: 'Uitzendingsnaam is verplicht',
        flow: 'Flow',
        segments: 'Groepen',
        whenToSend: 'Wanneer versturen',
        selectDate: 'Selecteer Datum',
        selectTimeSlot: 'Selecteer Tijdslot',
        createBroadcast: 'Uitzending Maken',
        cancel: 'Annuleren',
        now: 'Nu',
        later: 'Later',
        placeholders: {
          enterBroadcastName: 'Voer uitzendingsnaam in',
          selectFlow: 'Selecteer Flow',
          searchFlowByName: 'Zoek flow op naam',
          selectSegments: 'Selecteer Groepen',
          searchSegmentByName: 'Zoek Groep op naam',
          selectTimeSlot: 'Selecteer Tijdslot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecteer startdatum',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Maak Drip Campagne',
        youCanCreateMultipleCampaign: 'Je kunt meerdere campagnes maken',
        dripCampaignName: 'Naam Drip Campagne',
        dripCampaignNameIsRequired: 'Naam Drip Campagne is verplicht',
        continue: 'Doorgaan',
        manageDripCampaign: 'Drip Campagnes Beheren',
        youCanCreateMutlipleDripCampaign: 'Je kunt meerdere druppelcampagnes maken met geplande, geautomatiseerde berichten om reacties uit specifieke gebruikersgroepenen te triggeren.',
        createNewDripCampaign: 'Nieuwe Drip Campagne Maken',
        campaignName: 'Campagnenaam',
        appliedOn: 'Toegepast op',
        segment: 'Groep',
        flow: 'Flow',
        startDate: 'Startdatum',
        action: 'Actie',
        nA: 'NA',
        deleteDripCampaign: 'Drip Campagnes Verwijderen',
        wantToDeleteThisDripCampaign: 'Weet je zeker dat je deze Drip Campagnes wilt verwijderen?',
        placeholders: {
          enterDripCampaignName: 'Voer Drip Campagnenaam in',
          searchByName: 'Zoek Op Naam',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Verwijder meerdere druppelcampagnes',
          deleteDripCampaign: 'Drip Campagne Verwijderen',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Naam Drip Campagne',
          appliedOn: 'Toegepast op',
          segments: 'Groepen',
          selectDate: 'Selecteer Datum',
          scheduleYourFlows: 'Plan Je Flows',
          schedule: 'Planning',
          flow: 'Flow',
          sendAfter: 'Verstuur Na',
          cancel: 'Annuleren',
          newSubscriber: 'Nieuwe Abonnee',
          days: 'Dagen',
          minutes: 'Minuten',
          hours: 'Uren',
          createDripCampaign: 'Maak Drip Campagne',
          placeholders: {
            enterDripName: 'Voer Drip Naam in',
            selectAppliedOn: 'Selecteer Toegepast Op',
            selectSegment: 'Selecteer Groep',
            searchByName: 'Zoek Op Naam',
            selectTemplate: 'Selecteer Sjabloon',
            searchFlowByName: 'Zoek Flow Op Naam',
            enterSendAfter: 'Voer Verstuur Na in',
          },
          dateTimePicker: {
            selectStartDate: 'Selecteer startdatum',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'Chat ID',
        errorCode: 'Foutcode',
        errorMessage: 'Foutmelding',
        nA: 'NA',

      },
      flows: {
        chatFlows: 'Chatflows',
        createChatFlow: 'Maak Chat Flow',
        youCanCreateMultipleChatFlows: 'Je kunt meerdere gespreksflows maken over meerdere kanalen voor A/B-testen en uitzendingen.',
        flowName: 'Flow-naam',
        nameIsRequired: 'Naam is verplicht',
        cancel: 'Annuleren',
        createFlow: 'Flow Maken',
        importFlow: 'Flow Importeren',
        createNewFlow: 'Nieuwe Flow Maken',
        ofMessages: 'berichten',
        createdOn: 'Aangemaakt op',
        lastModified: 'Laatst gewijzigd',
        defaultFlow: 'Standaardflow',
        action: 'Actie',
        exportStatus: 'Exportstatus',
        flowExport: 'Flow-Export',
        download: 'Downloaden',
        // importFlow: 'Import Flow'
        beforeYouStartUploadPleaseMakeSure: 'Voordat je begint met uploaden, zorg ervoor dat',
        point1: '1. De maximale bestandsgrootte voor upload is 1 MB',
        point2: '2. Het bestandsformaat moet in JSON zijn',
        selectFile: 'Selecteer Bestand',
        inProgress: 'bezig',
        available: 'Beschikbaar',
        initiated: 'Gestart',
        placeholders: {
          enterFlowName: 'Voer flow-naam in',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Importeer flow voor Facebook-bot',
          dateAndTimeOfCreationOfChatFlow: 'Datum en tijd van aanmaak van de chatflow',
          dateAndTimeWhenChatFlowLastModified: 'Datum en tijd van laatste wijziging van de chatflow',
          TOOLTIP_CREATE_FLOW: 'Klik om een nieuwe chatflow te maken',
          TOOLTIP_FLOW_NAME: 'Voer de naam van de chatflow in',
          TOOLTIP_FLOW_TABLE_NAME: 'Naam van de chatflow',
          TOOLTIP_NO_OF_MESSAGES: 'Aantal berichtonderdelen in de chatflow',
          TOOLTIP_DEFAULT_FLOW: 'Gebruik deze schakelaar om een chatflow als standaard in te stellen, die aan alle nieuwe gebruikers wordt geserveerd.',
          TOOLTIP_ACTION: 'Bewerk of verwijder de chatflow',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'Nog geen Flows toegevoegd!',
        },
        modal: {
          deleteFlow: 'Flow Verwijderen',
          wantToDeleteThisFlow: 'Weet je zeker dat je deze Flow wilt verwijderen?',
        }
      },
      widgets: {
        manageWidgets: 'Widgets Beheren',
        widgetsAreRoutingTools: 'Widgets zijn de routingsinstrumenten om het gebruik van de bot te vergroten',
        needHelp: 'Hulp Nodig?',
        wantToMakeChanges: 'Wil je wijzigingen aanbrengen?',
        editIcon: 'Pictogram Bewerken',
        discard: 'Verwerpen',
        updateWidget: 'Widget Bijwerken',
        installChatBotIconOnYourWebsite: 'Installeer het chatbot-pictogram op je website',
        shareWhereEverYouWantToRedirectUsersTo: 'Deel waar je maar wilt en stuur gebruikers in één klik door naar je Facebook-bot',
        copyAndAddInYourWebsiteHeaderFile: 'Kopieer en voeg toe aan je website-headerbestand',
        copyLinkAndStartSharing: 'Kopieer de link en begin met delen!',
        downloadQrCodeAndStartSharing: 'Download de QR-code en begin met delen!',
        addCodeAndDeployNewCodeAndDone: 'Voeg de code toe, implementeer nieuwe code en klaar!',
        wantToLearnHowToUseIt: 'Wil je leren hoe je het moet gebruiken?',
        copyChatBotCode: 'Kopieer Chatbot Code',
        copyFacebookLink: 'Kopieer Facebook Link',
        downloadQrCode: 'Download QR-code',
        link: 'Link',
        chatBotIcon: 'Chatbot Pictogram',
        qrcode: 'QR-code',
        small: 'Klein',
        medium: 'Gemiddeld',
        large: 'Groot',
        leftMiddle: 'Links-Midden',
        rightMiddle: 'Rechts-Midden',
        leftBottom: 'Links-Onder',
        rightBottom: 'Rechts-Onder',
        bpDropDown: {
          selectIcons: 'Pictogrammen Selecteren',
          iconSize: 'Pictogramgrootte',
          iconPlacement: 'Pictogram Plaatsing',
        }
      },
      autoReply: {
        autoReplyTitle: 'Automatisch Antwoord',
        configureYour: 'Configureer je ',
        message: 'Bericht',
        customizeAutoReplyMessagesForYourPostsComments: 'Pas automatische antwoordberichten aan voor reacties op je berichten',
        wouldYouLikeToApply: 'Wil je het',
        autoreply: 'automatisch antwoord',
        onAllPost: ' toepassen op alle berichten?',
        configureYourMessageForEachPost: 'Configureer je Bericht voor Elk Bericht',
        configureFlowToTriggerAfterOnReply: 'Configureer de stroom om te activeren na een antwoord',
        whenUserLeavesACommentSendMessageAfter: 'Wanneer een gebruiker een reactie achterlaat, stuur bericht daarna',
        triggerFlowOnReply: 'Activeer Stroom bij Antwoord',
        add: 'Toevoegen',
        update: 'Bijwerken',
        save: 'Sla op',
        cancel: 'Annuleren',
        discard: 'Verwerpen',
        bpDropDown: {
          selectPost: 'Selecteer Bericht',
          triggerFlowOnReply: 'Activeer Stroom bij Antwoord',
          immendiately: 'Onmiddellijk',
          minutes: 'minuti'
        },
        bpTable: {
          message: 'Bericht',
          selectedPost: 'Geselecteerde Post',
          triggerFlow: 'Trigger Flow',
          actions: 'Acties'
        },
        placeholder: {
          configureYourMessage: 'Configureer je bericht'
        },
        deleteModel: {
          title: 'Verwijder Auto-Antwoord op Bericht',
          description: 'Weet u zeker dat u dit auto-antwoord op het bericht wilt verwijderen?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'Sessie Time-out',
        enterSessionTimeOutWindowInHours: 'Voer de time-out van de sessie in uren in',
        sendInactivityMessage: 'Stuur inactiviteitsbericht',
        sessionTimeOutInHoursTooltip: `Voer de duur in na welke de chat-sessie automatisch wordt beëindigd
         wegens inactiviteit. De chatstroom zal herstarten als de gebruiker een bericht stuurt nadat de sessietijd is verstreken.`,
        restartChatFlowTriggers: 'Triggers voor het herstarten van de chatstroom',
        enterKeyWordsToRestartChatAndhitEnter: 'Voer trefwoorden in om de chat te herstarten en druk op Enter',
        restartChatFlowTriggersTooltip: `Specificeer hier trefwoorden die, wanneer ze door de gebruiker worden ingevoerd en verzonden, een herstart van de chatstroom zullen activeren. Dit stelt gebruikers in staat om het gesprek op elk moment te herstarten.
         Druk op Enter na het toevoegen van elk trefwoord om meerdere trefwoorden toe te voegen.`,
        liveChatTriggers: 'Triggers voor live chat',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Voer trefwoorden in om live chat te activeren en druk op Enter',
        liveChatTriggersTooltip: `Definieer trefwoorden die, wanneer ze door de gebruiker worden ingevoerd en verzonden, een overgang van geautomatiseerde chat naar live chat zullen initiëren. Dit is handig wanneer gebruikers hulp nodig hebben van een live agent.
         Druk op Enter na het toevoegen van elk trefwoord om meerdere trefwoorden toe te voegen.`,
        messageAfterAutomatedFlowCompleted: 'Bericht na voltooiing van geautomatiseerde stroom',
        enterYourMessage: 'Voer uw bericht in',
        messageAfterAutomatedFlowCompletedTooltip: `Voer een bericht in dat aan de gebruiker wordt getoond nadat ze de geautomatiseerde chatstroom hebben voltooid, maar de sessie niet is verlopen.`,
        restart: 'Herstarten',
        liveChat: 'Live Chat',
        lineOneForDefaultMessage: 'Als u meer vragen heeft of hulp nodig heeft, is hier wat u kunt doen:',
        to: 'Naar',
        restartTheChat: 'herstart de chat',
        simpleTypeRestartAndHitEnter: `typ eenvoudig 'Herstart' en druk op Enter. Dit brengt u terug naar het begin van onze gespreksstroom.`,
        lineThreeForDefaultMessagePartOne: `Als u met een`,
        liveAgent: `live agent wilt spreken,`,
        lineThreeForDefaultMessageLastPart: `typ 'Live Chat' en druk op Enter. Ons team staat hier klaar om u in realtime te helpen.`,
        saveChanges: 'Wijzigingen Opslaan',
        discard: 'Negeren',
        configureBotsGeneralSettings: 'Configureer de algemene instellingen van uw bot',
        minutes: 'Minuten',
        hours: 'Uren',
      }
    },
    instagram: {
      instagramBotSettings: 'Instagram Bot Instellingen',
      chatFlowsHeader: 'Chat Gesprekken',
      broadcastHeader: 'Uitzending',
      dripCampaignHeader: 'Druppelcampagne',
      widgetsHeader: 'Widgets',
      alertsHeader: 'Meldingen',
      alerts: {
        configureYourBotsSettings: `Configureer de meldingsinstellingen van je bot`,
        specifyHowYourBotNotifyYou: 'Specificeer hoe je bot je op de hoogte zal stellen van nieuwe leads.',
        subject: 'Onderwerp',
        emailNotification: 'E-mailmelding',
        emailAddresses: 'E-mailadressen',
        leadRevisitNotifications: 'Meldingen voor herbezoeken van leads',
        whenToSendLeadInfo: 'Wanneer om leadinformatie te verzenden',
        discard: 'Verwerpen',
        saveChanges: 'Wijzigingen Opslaan',
        placeholders: {
          enterSubjectForYourEmail: 'Voer het onderwerp in voor je e-mail',
          typeYourEmailAddressAndHitEnter: 'Typ je e-mailadres in en druk op Enter',
        }
      },
      broadcasts: {
        createBroadcast: 'Uitzending Maken',
        youCanCreateMultipleBroadcasts: 'Je kunt meerdere uitzendingen maken',
        broadcastName: 'Naam Uitzending',
        broadcastNameIsRequired: 'Naam van uitzending is verplicht',
        continue: 'Doorgaan',
        manageBroadcasts: 'Uitzendingen Beheren',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Je kunt meerdere uitzendingen maken met ' + 'meerdere geplande sjablonen voor verschillende klantgroepen.',
        createNewBroadcast: 'Nieuwe Uitzending Maken',
        segment: 'Groep',
        template: 'Sjabloon',
        contacts: 'Contacten',
        send: 'Versturen',
        failed: 'Mislukt',
        broadcastAt: 'Uitzenden Op',
        status: 'Status',
        actions: 'Acties',
        nA: 'N.v.t.',
        placeholders: {
          enterBroadcastName: 'Voer de naam van de uitzending in',
          searchHere: 'Zoek hier',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Meerdere uitzendingen verwijderen',
          deleteBroadcast: 'Uitzending Verwijderen',
        }
      },
      createBroadcast: {
        broadcastName: 'Naam Uitzending',
        broadcastNameIsRequired: 'Naam van uitzending is verplicht',
        flow: 'Flow',
        segments: 'Groepen',
        whenToSend: 'Wanneer te verzenden',
        selectDate: 'Selecteer Datum',
        selectTimeSlot: 'Selecteer Tijdslot',
        cancel: 'Annuleren',
        createBroadcast: 'Uitzending Maken',
        placeholders: {
          enterBroadcastName: 'Voer de naam van de uitzending in',
          selectFlow: 'Selecteer Flow',
          searchFlowByName: 'Zoek op flow-naam',
          selectSegments: 'Selecteer Groepen',
          searchSegmentByName: 'Zoek op Groep-naam',
          selectTimeSlot: 'Selecteer Tijdslot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecteer startdatum',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Maak Drip Campagne',
        youCanCreateMultipleCampaigns: 'Je kunt meerdere campagnes maken',
        dripCampaignName: 'Naam Drip Campagne',
        dripCampaignNameIsRequired: 'Naam van de Drip Campagne is verplicht',
        continue: 'Doorgaan',
        manageDripCampaign: 'Beheer Drip Campagne',
        youCanCreateMultipleCampaignsWith: 'Je kunt meerdere druppelcampagnes maken met gepland, ' +
          'geautomatiseerde berichten om reacties van specifieke gebruikersgroepen te activeren.',
        createNewDripCampaign: 'Maak Nieuwe Drip Campagne',
        campaignName: 'Naam Campagne',
        appliedOn: 'Toegepast Op',
        segment: 'Groep',
        flow: 'Flow',
        startDate: 'Startdatum',
        action: 'Actie',
        nA: 'N.v.t.',
        placeholders: {
          enterDripCampaignName: 'Voer Naam Drip Campagne in',
          searchByName: 'Zoeken op Naam',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Verwijder meerdere drip campagnes',
          deleteDripCampaign: 'Verwijder Drip Campagne',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Naam Drip Campagne',
          appliedOn: 'Toegepast Op',
          segments: 'Groepen',
          selectDate: 'Selecteer Datum',
          scheduleYourFlows: 'Plan Je Flows',
          flow: 'Flow',
          sendAfter: 'Verstuur Na',
          cancel: 'Annuleren',
          createDripCampaign: 'Maak Drip Campagne',
          placeholders: {
            enterDripName: 'Voer Drip Naam in',
            selectAppliedOn: 'Selecteer Toegepast Op',
            selectSegment: 'Selecteer Groep',
            searchByName: 'Zoeken op Naam',
            selectTemplate: 'Selecteer Sjabloon',
            searchFlowByName: 'Zoeken op Flow Naam',
            enterSendAfter: 'Voer Verstuur Na in',
          },
          dateTimePicker: {
            selectStartDate: 'Selecteer startdatum',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'Chat ID',
        errorCode: 'Foutcode',
        errorMessage: 'Foutmelding',
        nA: 'N.v.t.',
      },
      flows: {
        chatFlows: 'Chatflows',
        createChatFlow: 'Maak Chat Flow',
        youCanCreateMultipleChatFlows: 'Je kunt meerdere gespreksflows maken via verschillende kanalen voor A/B-testen en uitzendingen.',
        flowName: 'Flow Naam',
        nameIsRequired: 'Naam is verplicht',
        cancel: 'Annuleren',
        createFlow: 'Maak Flow',
        importFlow: 'Importeer Flow',
        createNewFlow: 'Maak Nieuwe Flow',
        //
        ofMessages: ' van berichten',
        createdOn: 'Gemaakt op',
        lastModified: 'Laatst gewijzigd',
        defaultFlow: 'Standaard flow',
        action: 'Actie',
        exportStatus: 'Export Status',
        flowExport: 'Flow Exporteren',
        download: 'Downloaden',
        //
        beforeYouStartUploadPleaseMakeSure: 'Voordat je begint met uploaden, zorg ervoor dat',
        point1: '1. De maximale bestandsgrootte voor upload is 1MB',
        point2: '2. Het bestandsformaat moet JSON zijn',
        selectFile: 'Selecteer Bestand',
        placeholders: {
          enterFlowName: 'Voer flow naam in',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Importeer flow voor Instagram bot',
          dateAndTimeOfCreationOfChatFlow: 'Datum en tijd van creatie van de chat flow',
          dateAndTimeWhenChatFlowLastModified: 'Datum en tijd wanneer de chat flow voor het laatst gewijzigd is',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'Nog geen flows toegevoegd!',
        },
        modal: {
          deleteFlow: 'Flow Verwijderen',
          wantToDeleteThisFlow: 'Weet je zeker dat je deze flow wilt verwijderen?',
        }
      },
      widgets: {
        manageWidgets: 'Beheer Widgets',
        widgetsAreRoutingTools: 'Widgets zijn routeringstools om het gebruik van de bot te vergroten',
        needHelp: 'Hulp nodig?',
        wantToMakeChanges: 'Wilt wijzigingen aanbrengen?',
        editIcon: 'Pictogram bewerken',
        discard: 'Afbreken',
        updateWidget: 'Widget bijwerken',
        bpDropDown: {
          selectIcons: 'Selecteer Pictogrammen',
          iconSize: 'Pictogramgrootte',
          iconPlacement: 'Pictogramplaatsing',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Telegram Bot Instellingen',
      chatFlowsHeader: 'Chatflows',
      telegramProfileHeader: 'Telegram Profiel',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Uitzending',
      dripCampaignHeader: 'Drip Campagne',
      generalHeader: 'Algemeen',
      telegramAutomation: 'Telegram Automatisering',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Creëer een klant-onboardingtraject met behulp van een drip campagne van geplande chatflows.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Pas de sjablonen voor de chatflow aan en maak meerdere chatflows voor verschillende doelgroepen.',
      },
      broadcasts: {
        createBroadcast: 'Uitzending maken',
        youCanCreateMutlipleBroadcasts: 'Je kunt meerdere uitzendingen maken',
        broadcastName: 'Uitzendnaam',
        broadcastNameIsRequired: 'Uitzendnaam is verplicht',
        continue: 'Doorgaan',
        manageBroadcasts: 'Uitzendingen beheren',
        youCanCreateMultipleBroadcastsWith: 'U kunt meerdere uitzendingen maken met meerdere geplande sjablonen voor verschillende klantgroepen.',
        createNewBroadcast: 'Nieuwe uitzending maken',
        name: 'Naam',
        segment: 'Groep',
        template: 'Sjabloon',
        contacts: 'Contacten',
        send: 'Verstuur',
        failed: 'Mislukt',
        broadcastAt: 'Uitzending op',
        status: 'Status',
        actions: 'Acties',
        nA: 'NA',
        deleteBroadcast: 'Verwijder uitzending',
        wantToDeleteThisBroadcast: 'Weet je zeker dat je deze uitzending wilt verwijderen?',
        placeholders: {
          enterBroadcastName: 'Voer uitzendnaam in',
          searchHere: 'Zoek hier',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Verwijder meerdere uitzendingen',
          deleteBroadcast: 'Verwijder uitzending',
        },
      },
      createBroadcastTelegram: {
        broadcastName: 'Uitzendnaam',
        broadcastNameIsRequired: 'Uitzendnaam is verplicht',
        flow: 'Flow',
        segments: 'Groepen',
        whenToSend: 'Wanneer verzenden',
        selectDate: 'Selecteer Datum',
        selectTimeSlot: 'Selecteer Tijdslot',
        cancel: 'Annuleren',
        createBroadcast: 'Maak uitzending',
        now: 'Nu',
        later: 'Later',
        placeholders: {
          enterBroadcastName: 'Voer uitzendnaam in',
          selectFlow: 'Selecteer Flow',
          searchFlowByName: 'Zoek op naam van flow',
          selectSegments: 'Selecteer Groepen',
          searchSegmentByName: 'Zoek op naam van Groep',
          selectTimeSlot: 'Selecteer Tijdslot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Selecteer startdatum',
        },
      },
      dripCampaigns: {
        createDripCampaign: 'Maak Drip Campagne',
        youCanCreateMultipleCampaigns: 'Je kunt meerdere campagnes aanmaken',
        dripCampaignName: 'Naam Drip Campagne',
        dripCampaignNameIsRequired: 'Naam Drip Campagne is verplicht',
        continue: 'Doorgaan',
        manageDripCampaign: 'Beheer Drip Campagne',
        youCanCreateMultipleDripCampaignsWith: 'Je kunt meerdere Drip Campagnes aanmaken met geplande, geautomatiseerde berichten' +
          ' om reacties uit specifieke gebruikersgroepen te triggeren.',
        createNewDripCampaign: 'Nieuwe Drip Campagne aanmaken',
        campaignName: 'Campagne Naam',
        appliedOn: 'Toegepast op',
        segment: 'Groep',
        flow: 'Flow',
        startDate: 'Startdatum',
        action: 'Actie',
        nA: 'Niet beschikbaar',
        deleteDripCampaign: 'Verwijder Drip Campagnes',
        wantToDeleteThisDripCampaign: 'Weet je zeker dat je deze Drip Campagnes wilt verwijderen?',
        placeholders: {
          enterDripCampaignName: 'Voer Naam Drip Campagne in',
          searchByName: 'Zoek op naam',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Verwijder meerdere drip campagnes',
          deleteDripCampaign: 'Verwijder Drip Campagne',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Naam Drip Campagne',
          appliedOn: 'Toegepast op',
          segments: 'Groepen',
          startDate: 'Selecteer Datum',
          scheduleYourFlows: 'Plan Je Flows In',
          schedule: 'Plannen',
          flow: 'Flow',
          sendAfter: 'Verstuur Na',
          cancel: 'Annuleren',
          createDripCampaign: 'Maak Drip Campagne aan',
          newSubscriber: 'Nieuwe Abonnee',
          days: 'Dagen',
          minutes: 'Minuten',
          hours: 'Uren',
          placeholders: {
            enterDripName: 'Voer Drip Naam in',
            selectAppliedOn: 'Selecteer Toegepast Op',
            selectSegment: 'Selecteer Groep',
            searchByName: 'Zoek op naam',
            selectFlow: 'Selecteer Flow',
            searchFlowByName: 'Zoek Flow op Naam',
          },
          dateTimePicker: {
            selectStartDate: 'Selecteer startdatum',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Naam',
        email: 'E-mail',
        phone: 'Telefoon',
        errorCode: 'Foutcode',
        errorMessage: 'Foutmelding',
        nA: 'Niet beschikbaar',
      },
      flows: {
        chatFlows: 'Chatflows',
        createChatFlow: 'Maak Chat Flow aan',
        youCanCreateMultipleChatFlows: 'Je kunt meerdere gespreksflows aanmaken over meerdere kanalen voor A/B-testen en uitzendingen.',
        flowName: 'Flow naam',
        nameIsRequired: 'Naam is verplicht',
        cancel: 'Annuleren',
        createFlow: 'Maak Flow aan',
        importFlow: 'Flow Importeren',
        createNewFlow: 'Nieuwe Flow aanmaken',
        //
        ofMessages: ' van berichten',
        createdOn: 'Aangemaakt op',
        lastModified: 'Laatst gewijzigd',
        defaultFlow: 'Standaard flow',
        action: 'Actie',
        exportStatus: 'Exportstatus',
        flowExport: 'Flow- Exporteren ',
        inProgress: 'bezig',
        available: 'Beschikbaar',
        initiated: 'Gestart',
        download: 'Downloaden',
        //
        beforeYouStartUploadPleaseMakeSure: 'Voordat je begint met uploaden, zorg ervoor dat',
        point1: '1. De maximale bestandsgrootte voor upload is 1MB',
        point2: '2. Het bestandsformaat moet JSON zijn',
        selectFile: 'Selecteer Bestand',
        placeholders: {
          enterFlowName: 'Voer flow naam in',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Importeer flow voor Telegram bot',
          dateAndTimeOfCreationOfChatFlow: 'Datum en tijd van aanmaak van de chat flow',
          dateAndTimeWhenChatFlowLastModified: 'Datum en tijd van laatste wijziging van de chat flow',
          TOOLTIP_CREATE_FLOW: 'Klik om een nieuwe chat flow aan te maken',
          TOOLTIP_FLOW_NAME: 'Voer chat flow naam in',
          TOOLTIP_FLOW_TABLE_NAME: 'Naam van de chat flow',
          TOOLTIP_NO_OF_MESSAGES: 'Aantal berichtcomponenten in de chat flow',
          TOOLTIP_DEFAULT_FLOW: 'Gebruik deze schakelaar om een chat flow als standaard in te stellen, die aan alle nieuwe gebruikers wordt geserveerd.',
          TOOLTIP_ACTION: 'Bewerk of verwijder de chat flow',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'Nog geen flows toegevoegd!',
        },
        modal: {
          deleteFlow: 'Flow Verwijderen',
          wantToDeleteThisFlow: 'Weet je zeker dat je deze Flow wilt verwijderen?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Telegram Profiel',
        manageYourBotProfile: 'Beheer je Bot Profiel wanneer je maar wilt.',
        allSetNoActionRequired: 'Alles ingesteld, geen actie vereist!',
        congratulationYourAccountIsLive: 'Gefeliciteerd! Je account is live met volledige toegang',
        botDetails: 'Bot Details',
        name: 'Naam',
        nA: 'Niet beschikbaar',
        b: 'B',
        botName: 'Bot Naam',
        bot: 'Bot',
        info: 'Info',
        none: 'Geen',
        username: 'Gebruikersnaam',
        notifications: 'Meldingen',
        on: 'Aan',
        addToGroupOrChannel: 'Toevoegen aan Groep of Kanaal',
        userName: 'Gebruikersnaam',
        botToken: 'Bot Token',
      },
      widgets: {
        manageWidgets: 'Beheer Widgets',
        widgetsAreRoutingTools: 'Widgets zijn routingshulpmiddelen om het gebruik van de bot te vergroten',
        needHelp: 'Hulp nodig?',
        wantToMakeChanges: 'Wil je wijzigingen aanbrengen?',
        editIcon: 'Pictogram bewerken',
        discard: 'Afbreken',
        updateWidget: 'Widget bijwerken',
        installChatBotIconOnYourWebsite: 'Installeer het chatbot-pictogram op je website',
        shareWhereEverYouWantRedirectUsersTo: 'Deel waar je maar wilt en stuur gebruikers met één klik naar je Telegram-bot',
        copyAndAddInYourWebsiteHeaderFile: 'Kopieer en voeg toe aan het koptekstbestand van je website',
        copyLinkAndStartSharing: 'Kopieer de link en begin met delen!',
        downloadQrCodeAndStartSharing: 'Download de QR-code en begin met delen!',
        addCodeAndDeployNewCodeAndDone: 'Voeg de code toe, implementeer nieuwe code en klaar!',
        wantToLearnHowToUseIt: 'Wil je leren hoe je het moet gebruiken?',
        copyChatBotCode: 'Kopieer Chatbot Code',
        downloadQrCode: 'Download QR Code',
        copyTelegramLink: 'Kopieer Telegram Link',
        link: 'Link',
        chatBotIcon: 'Chatbot Pictogram',
        qrcode: 'QR Code',
        small: 'Klein',
        medium: 'Middelgroot',
        large: 'Groot',
        leftMiddle: 'Links-Midden',
        rightMiddle: 'Rechts-Midden',
        leftBottom: 'Links-Onder',
        rightBottom: 'Rechts-Onder',
        bpDropDown: {
          selectIcons: 'Selecteer Pictogrammen',
          iconSize: 'Pictogram Grootte',
          iconPlacement: 'Pictogram Plaatsing',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Sessie Time-out',
        enterSessionTimeOutWindowInHours: 'Voer de time-out van de sessie in uren in',
        sendInactivityMessage: 'Stuur inactiviteitsbericht',
        sessionTimeOutInHoursTooltip: `Voer de duur in na welke de chat-sessie automatisch wordt beëindigd
         wegens inactiviteit. De chatstroom zal herstarten als de gebruiker een bericht stuurt nadat de sessietijd is verstreken.`,
        restartChatFlowTriggers: 'Triggers voor het herstarten van de chatstroom',
        enterKeyWordsToRestartChatAndhitEnter: 'Voer trefwoorden in om de chat te herstarten en druk op Enter',
        restartChatFlowTriggersTooltip: `Specificeer hier trefwoorden die, wanneer ze door de gebruiker worden ingevoerd en verzonden, een herstart van de chatstroom zullen activeren. Dit stelt gebruikers in staat om het gesprek op elk moment te herstarten.
         Druk op Enter na het toevoegen van elk trefwoord om meerdere trefwoorden toe te voegen.`,
        liveChatTriggers: 'Triggers voor live chat',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Voer trefwoorden in om live chat te activeren en druk op Enter',
        liveChatTriggersTooltip: `Definieer trefwoorden die, wanneer ze door de gebruiker worden ingevoerd en verzonden, een overgang van geautomatiseerde chat naar live chat zullen initiëren. Dit is handig wanneer gebruikers hulp nodig hebben van een live agent.
         Druk op Enter na het toevoegen van elk trefwoord om meerdere trefwoorden toe te voegen.`,
        messageAfterAutomatedFlowCompleted: 'Bericht na voltooiing van geautomatiseerde stroom',
        enterYourMessage: 'Voer uw bericht in',
        messageAfterAutomatedFlowCompletedTooltip: `Voer een bericht in dat aan de gebruiker wordt getoond nadat ze de geautomatiseerde chatstroom hebben voltooid, maar de sessie niet is verlopen.`,
        restart: 'Herstarten',
        liveChat: 'Live Chat',
        lineOneForDefaultMessage: 'Als u meer vragen heeft of hulp nodig heeft, is hier wat u kunt doen:',
        to: 'Naar',
        restartTheChat: 'herstart de chat',
        simpleTypeRestartAndHitEnter: `typ eenvoudig 'Herstart' en druk op Enter. Dit brengt u terug naar het begin van onze gespreksstroom.`,
        lineThreeForDefaultMessagePartOne: `Als u met een`,
        liveAgent: `live agent wilt spreken,`,
        lineThreeForDefaultMessageLastPart: `typ 'Live Chat' en druk op Enter. Ons team staat hier klaar om u in realtime te helpen.`,
        saveChanges: 'Wijzigingen Opslaan',
        discard: 'Negeren',
        configureBotsGeneralSettings: 'Configureer de algemene instellingen van uw bot',
        minutes: 'Minuten',
        hours: 'Uren',
      }
    },
    whatsapp: {
      whatsappAutomation: 'WhatsApp Automatisering',
      whatsappBotSettings: 'WhatsApp Bot Instellingen',
      chatFlowsHeader: 'Chatflows',
      whatsappProfileHeader: 'WhatsApp profiel',
      broadcastHeader: 'Uitzending',
      dripCampaignHeader: 'Drip Campagne',
      widgetHeader: 'Widget',
      templatesHeader: 'Sjablonen',
      generalHeader: 'Algemeen',
      alertsHeader: 'Meldingen',
      apiKeyHeader: 'API Sleutel',
      catalogHeader: 'Catalogus',
      otherConfiguration: 'AI-instellingen',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Pas de chatflow-sjablonen aan en maak meerdere chatflows voor verschillende doelgroepen.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Configureer de meldingsinstellingen van je bot',
        specifyHowYouBotWillNotify: 'Geef aan hoe je bot je op de hoogte zal stellen van nieuwe leads.',
        subject: 'Onderwerp',
        emailNotification: 'E-mailmelding',
        emailAddresses: 'E-mailadressen',
        leadRevisitNotifications: 'Meldingen voor herhaalde leads',
        whenToSendLeadInfo: 'Wanneer leadinformatie te verzenden',
        discard: 'Annuleren',
        saveChanges: 'Wijzigingen opslaan',
        seeHowToUse: 'Bekijk hoe je het kunt gebruiken',
        beginningTheFlow: 'Begin van de Flow',
        fiveMinutesLater: '5 Minuten Later',
        endOfFlow: 'Einde van de Flow',
        placeholders: {
          enterSubjectForYourEmail: 'Voer het onderwerp voor je e-mail in',
          typeEmailAndHitEnter: 'Typ je e-mailadres en druk op enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Pas het onderwerp van je e-mailmeldingen van de bot aan',
          TOOLTIP_EMAIL_NOTIFICATION: 'Schakel de melding van de bot in/uit op je e-mail voor nieuwe leads',
          TOOLTIP_EMAIL_ADDRESS: 'Voer het e-mailadres in van het account waar je e-mailmeldingen wilt ontvangen. Je kunt meerdere e-mailadressen toevoegen door er een in te voeren en op enter te drukken om een andere toe te voegen.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'Schakel de melding van de bot in/uit op je e-mail als een eerder vastgelegde lead opnieuw met de bot interageert.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'WhatsApp Chatbot Bijwerken',
        businessNameIsRequired: 'Bedrijfsnaam is verplicht.',
        maxLengthForBusinessName40Characters: 'De maximale lengte voor de bedrijfsnaam is 40 tekens.',
        categoryIsRequired: 'Categorie is verplicht.',
        descriptionIsRequired: 'Beschrijving is verplicht.',
        maxLengthForDescription200Characters: 'De maximale lengte voor de beschrijving is 200 tekens.',
        aboutIsRequired: 'Over is verplicht.',
        maxLengthForAbout139Characters: 'De maximale lengte voor "over" is 139 tekens.',
        pleaseFillAValidBusinessEmail: 'Vul een geldig bedrijfse-mailadres in.',
        businessEmailIsRequired: 'Bedrijfse-mailadres is verplicht.',
        websiteIsRequired: 'Website is verplicht.',
        maxLengthForWebsite40Characters: 'De maximale lengte voor de website is 40 tekens.',
        maxLengthForAddress150Characters: 'De maximale lengte voor het adres is 150 tekens.',
        cancel: 'Annuleren',
        update: 'Bijwerken',
        businessCategory: 'Bedrijfscategorie',
        placeholders: {
          yourBusinessName: 'Je Bedrijfsnaam',
          businessDescription: 'Bedrijfsbeschrijving',
          about: 'Over',
          businessEmail: 'Bedrijfse-mail',
          website: 'Website (bijv. www.voorbeeld.com)',
          address: 'Adres',
        }
      },
      flows: {
        chatFlows: 'Chat Flows',
        createChatFlow: 'Chatflow Creëren',
        youCanCreateMultipleChatFlows: 'Je kunt meerdere chatflows creëren voor verschillende kanalen om A/B-testen en uitzendingen uit te voeren.',
        flowName: 'Naam van de flow',
        nameIsRequired: 'Naam is verplicht',
        cancel: 'Annuleren',
        createFlow: 'Flow Creëren',
        importFlow: 'Flow Importeren',
        createNewFlow: 'Nieuwe Flow Creëren',
        ofMessages: ' van berichten',
        createdOn: 'Aangemaakt op',
        lastModified: 'Laatst gewijzigd',
        defaultFlow: 'Standaard flow',
        revisitFlow: 'Flow voor herhaalbezoek',
        action: 'Actie',
        exportStatus: 'Exportstatus',
        flowExport: 'Flow - Exporteren ',
        //
        beforeYouStartUploadPleaseMakeSure: 'Voordat je begint met uploaden, zorg ervoor dat',
        point1: '1. De maximale bestandsgrootte voor upload is 1 MB',
        point2: '2. Het bestandsformaat moet JSON zijn',
        selectFile: 'Bestand Selecteren',
        seeHowToUse: 'Bekijk hoe je het kunt gebruiken',
        download: 'Downloaden',
        available: 'Beschikbaar',
        initiated: 'Gestart',
        inProgress: 'Bezig',
        placeholders: {
          enterFlowName: 'Voer de naam van de flow in',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Flow importeren voor WhatsApp-bot',
          dateAndTimeOfCreationOfChatFlow: 'Datum en tijd van aanmaak van de chatflow',
          dateAndTimeWhenChatFlowLastModified: 'Datum en tijd van laatste wijziging van de chatflow',
          TOOLTIP_CREATE_FLOW: 'Klik om een nieuwe chatflow te creëren',
          TOOLTIP_FLOW_NAME: 'Voer de naam van de chatflow in',
          TOOLTIP_FLOW_TABLE_NAME: 'Naam van de chatflow',
          TOOLTIP_NO_OF_MESSAGES: 'Aantal berichtcomponenten in de chatflow',
          TOOLTIP_DEFAULT_FLOW: 'Gebruik deze schakelaar om een chatflow als standaard in te stellen, die aan alle nieuwe gebruikers wordt geserveerd.',
          TOOLTIP_REVISIT_FLOW: 'Gebruik deze schakelaar om een chatflow te selecteren die aan gebruikers wordt geserveerd wanneer ze je chatbot opnieuw bezoeken.',
          TOOLTIP_ACTION: 'Bewerk of verwijder de chatflow',
        },
        modal: {
          deleteFlow: 'Flow Verwijderen',
          wantToDeleteThisFlow: 'Weet je zeker dat je deze Flow wilt verwijderen?',
        },
        noDataFound: {
          flows: 'Flows',
          noFlowsAddedYet: 'Nog geen Flows toegevoegd!',
        }
      },
      input: {
        manageTemplates: 'Beheer Sjablonen',
        manageYourTemplatesForMarketingAutomation: 'Beheer uw sjablonen voor marketingautomatisering',
        syncTemplates: 'Synchroniseer Sjablonen',
        createTemplateHeader: 'Sjabloon Maken',
        title: 'Titel',
        info: 'Informatie',
        category: 'Categorie',
        status: 'Status',
        action: 'Actie',
        shipmentUpdate: 'Verzendingsupdate',
        name: 'Naam',
        //
        headerOptional: 'Kop (Optioneel)',
        bodyRequired: 'Berichttekst (vereist)',
        footerOptional: 'Voettekst (optioneel)',
        buttonsOptional: 'Knoppen (optioneel)',
        makeUpto3Buttons: 'Maak tot 3 knoppen',
        makeUpto2ButtonsToRedirect: 'Maak tot 2 knoppen om klanten door te verwijzen naar uw website',
        placeholders: {
          nameYourTemplate: 'Geef uw sjabloon een naam (gebruik een underscore om woorden te scheiden)',
          enterText: 'Voer tekst in (max. 60 tekens)',
          enterImageUrl: 'Voer de URL van de afbeelding in',
          enterVideoUrl: 'Voer de URL van de video in',
          templateMessage: 'Sjabloonbericht',
          smallFooterAtBottom: 'Kleine voettekst onderaan',
          buttons: 'Knoppen',
          text: 'Tekst',
          numberWithCountryCode: 'Nummer met landcode',
          enterButtonText: 'Voer knoptekst in (maximaal 20 tekens)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Titel van het WhatsApp-sjabloon',
          TOOLTIP_INPUT_TABLE_INFO: 'Gedetailleerde informatie over zakelijke initiatie-berichten',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Type zakelijke initiatie-berichten zoals afspraken, herinneringen, klantenzorgberichten, verzendingsupdates, meldingen en meer',
          TOOLTIP_INPUT_TABLE_STATUS: 'Status van het sjabloon wordt door WhatsApp afgekeurd of goedgekeurd. WhatsApp-sjablonen moeten eerst worden goedgekeurd door WhatsApp en dit is een betaalde service.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Verwijder het sjabloon',
        },
        noDataFound: {
          templates: 'Sjablonen',
          noTemplatesCreatedYet: 'Nog geen sjablonen gemaakt!',
        },
        modal: {
          deleteTemplate: 'Sjabloon Verwijderen',
          wantToDeleteThisTemplate: 'Weet u zeker dat u dit sjabloon wilt verwijderen?',
        },
        createTemplate: {
          createTemplates: 'Sjablonen Maken',
          createTemplatesForMarketingAutomation: 'Maak uw sjablonen voor marketingautomatisering',
          templateMeta: 'Sjabloon Meta',
          selectLanguage: 'Selecteer taal',
          header: 'Kop',
          optional: 'Optioneel',
          samplesForHeaderContent: 'Voorbeelden voor kopinhoud',
          toHelpMetaReviewYourContent: 'Om Meta te helpen uw inhoud te beoordelen, geeft u voorbeelden van de variabelen of media in de kop. Voeg geen klantinformatie toe.',
          body: 'Lichaam',
          addVariable: 'Variabele toevoegen',
          samplesForBodyContent: 'Voorbeelden voor lichaamsinhoud',
          toHelpUsReviewYourContent: 'Om ons te helpen uw inhoud te beoordelen, geeft u voorbeelden van de variabelen in het lichaam. Voeg geen klantinformatie toe.',
          footer: 'Voettekst',
          //
          buttons: 'Knoppen',
          //
          typeOfAction: 'Soort actie',
          call: 'Bellen',
          phone: 'Telefoon',
          website: 'Website',
          static: 'Statisch',
          urlType: 'URL-type',
          type: 'Type',
          customButton: 'Aangepaste knop',
          addAnotherButton: 'Voeg nog een knop toe',
          preview: 'Voorbeeld',
          test: 'Test',
          create: 'Maken',
          addSampleUrl: 'Voorbeeld-URL toevoegen',
          toHelpMetaReviewYourMessage: 'Om Meta te helpen uw berichtssjabloon te beoordelen, voegt u een voorbeeld van de website-URL toe. Gebruik geen echte klantinformatie.',
          placeholders: {
            enterMessageTemplateName: 'Voer de naam van het berichtssjabloon in',
            searchTemplateByName: 'Zoek sjabloon op naam',
            selectTemplate: 'Selecteer Sjabloon',
            enterText: 'Voer Tekst In',
            enterBody: 'Voer Lichaam In',
            enterContentForVariable: 'Voer inhoud voor variabele in',
            enterFooter: 'Voer Voettekst In',
            phone: 'Telefoon*',
            enterWebsiteUrl: 'Voer website-URL in',
          },
          bpInput: {
            name: 'Naam',
            buttonText: 'Knop Tekst',
            websiteUrl: 'Website-URL',
          },
          bpDropDown: {
            selectCategory: 'Selecteer Categorie',
          },
        },
        templatePreview: {
          preview: 'Voorbeeld',
          test: 'Test',
        },
        templateConfig: {
          saveChanges: 'Wijzigingen Opslaan',
          discard: 'Afwijzen',
          triggerChatFlowOnTemplateReply: 'Activeer Chatstroom bij Sjabloonantwoord',
          templateDetails: 'Sjabloon Details',
          configureFlows: 'Configureer Stroom',
          selectFlow: 'Selecteer Stroom',
          name: 'Naam',
        },
      },
      shopManagement: {
        shopManagement: 'Winkelbeheer',
        manageECommerceShopFromBotPenguin: 'Beheer e-commerce winkels die zijn aangesloten bij BotPenguin',
        comingSoon: '( Binnenkort beschikbaar )',
        wooCommerce: {
          createConfiguration: 'Configuratie Creëren',
          eventConfiguration: {
            createConfiguration: 'Configuratie Creëren',
            templateHeader: 'Sjabloon Koptekst',
            selectFile: 'Selecteer Bestand',
            templateMessage: 'Sjabloon Bericht',
            saveChanges: 'Wijzigingen Opslaan',
            bpDropDown: {
              selectEvent: 'Selecteer Evenement',
              selectTemplate: 'Selecteer Sjabloon',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'WhatsApp Profiel',
        manageYourBotProfile: 'Beheer uw Bot Profiel wanneer u wilt.',
        allSetNoActionRequired: 'Alles ingesteld, geen actie vereist!',
        congratulationsYourAccountIsLive: 'Gefeliciteerd! Uw account is live met volledige toegang',
        somethingIsWrongReactivateYourBot: 'Er is iets mis, heractiveer uw bot!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Het lijkt erop dat uw bot niet goed werkt. Reset uw bot alstublieft.',
        reConfigureYourBot: 'Herconfigureer uw bot',
        phoneNumberDetails: 'Telefoonnummer Details',
        phoneNumber: 'Telefoonnummer',
        nA: 'N.v.t.',
        phoneNumberId: 'Telefoonnummer ID',
        wabaId: 'WABA ID',
        change: 'Wijzigen',
        accessToken: 'Toegangstoken',
        // change: 'Change',
        messagingLimit: 'Berichtenlimiet',
        qualityRating: 'Kwaliteitsbeoordeling',
        whatsappBusinessAccountName: 'WhatsApp Bedrijfsnaam',
        whatsappPublicProfile: 'WhatsApp Openbaar Profiel',
        profilePicture: 'Profielfoto',
        aboutText: 'Over Tekst',
        address: 'Adres',
        description: 'Beschrijving',
        email: 'E-mail',
        websitePrimary: 'Website (Primair)',
        websiteSecondary: 'Website (Secundair)',
        businessVertical: 'Bedrijfssector',
        editProfile: 'Profiel Bewerken',
        thisIsABusinessAccount: 'Dit is een bedrijfsaccount',
        aboutAndPhoneNumber: 'Over en Telefoonnummer',
        yourTrialAccountIsLive: 'Gefeliciteerd! Uw proefaccount is live met volledige toegang op uw geverifieerde nummer',
        updateWithNewAccessToken: 'Bijwerken met nieuw toegangstoken',
      },
      widgets: {
        manageWidgets: 'Widgets Beheren',
        widgetsAreRoutingTools: 'Widgets zijn routeringsinstrumenten om het gebruik van de bot te vergroten',
        needHelp: 'Hulp nodig?',
        wantToMakeChanges: 'Wilt u wijzigingen aanbrengen?',
        prePopulatedMessage: 'Voorgevuld Bericht',
        discard: 'Verwijderen',
        updateWidget: 'Widget Bijwerken',
        hiThere: 'Hallo daar ',
        seeHowToUse: 'Bekijk hoe u het kunt gebruiken',
        editIcon: 'Icoon Bewerken',
        editPrePopulatedMessage: 'Voorgevuld Bericht Bewerken',
        small: 'Klein',
        medium: 'Gemiddeld',
        large: 'Groot',
        leftMiddle: 'Links-Midden',
        rightMiddle: 'Rechts-Midden',
        leftBottom: 'Links-Onder',
        rightBottom: 'Rechts-Onder',
        chatBotIcon: 'ChatBot Icoon',
        link: 'Link',
        qrcode: 'QR-code',
        copyChatBotCode: 'Kopieer Chatbot Code',
        copyWALink: 'Kopieer WA Link',
        downloadQrCode: 'Download QR-code',
        installChatBotIconOnYourWebsite: 'Installeer de chatbot-icoon op uw website',
        shareWhereEverYouWantRedirectUsersTo: 'Deel waar u maar wilt en stuur gebruikers door naar uw WhatsApp bot in één klik',
        copyAndAddInYourWebsiteHeaderFile: 'Kopieer en voeg toe aan het kopbestand van uw website',
        copyLinkAndStartSharing: 'Kopieer link en begin met delen!',
        downloadQrCodeAndStartSharing: 'Download QR-code en begin met delen!',
        addCodeAndDeployNewCodeAndDone: 'Voeg de code toe, implementeer nieuwe code en klaar!',
        wantToLearnHowToUseIt: 'Wilt u leren hoe u het moet gebruiken?',
        placeholders: {
          enterWhatsappNumber: 'Voer WhatsApp-nummer in',
          updateYourMessageHere: 'Werk uw bericht hier bij...',
        },
        bpDropDown: {
          selectIcons: 'Selecteer Iconen',
          iconSize: 'Icoon Grootte',
          iconPlacement: 'Icoon Plaatsing',
        },
        bpInput: {
          whatsappNumber: 'WhatsApp-nummer',
        }
      },
      catalog: {
        createCatalog: 'Catalogus Maken',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Maak een productcatalogus aan op de Meta Commerce Manager of koppel deze aan Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Gebruik uw Meta Commerce Manager om catalogi te beheren',
        connectCatalogToWhatsAppAccount: 'Verbind Catalogus met WhatsApp-account',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Verbind uw catalogi met uw WhatsApp-account met behulp van uw WhatsApp Manager',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Gebruik uw Meta WhatsApp Manager om catalogi te verbinden en ze te gebruiken met BotPenguin',
        goToMeta: 'Ga naar Meta',
        goToShopify: 'Ga naar Shopify',
        next: 'Volgende',
        back: 'Terug',
        setupCatalog: 'Catalogus Instellen',
        completeAllTheStepsToEnableCatalogMessages: 'Voltooi alle stappen om catalogusberichten mogelijk te maken'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Sessie Time-out',
        enterSessionTimeOutWindowInHours: 'Voer de time-out van de sessie in uren in',
        sendInactivityMessage: 'Stuur inactiviteitsbericht',
        sessionTimeOutInHoursTooltip: `Voer de duur in na welke de chat-sessie automatisch wordt beëindigd
         wegens inactiviteit. De chatstroom zal herstarten als de gebruiker een bericht stuurt nadat de sessietijd is verstreken.`,
        restartChatFlowTriggers: 'Triggers voor het herstarten van de chatstroom',
        enterKeyWordsToRestartChatAndhitEnter: 'Voer trefwoorden in om de chat te herstarten en druk op Enter',
        restartChatFlowTriggersTooltip: `Specificeer hier trefwoorden die, wanneer ze door de gebruiker worden ingevoerd en verzonden, een herstart van de chatstroom zullen activeren. Dit stelt gebruikers in staat om het gesprek op elk moment te herstarten.
         Druk op Enter na het toevoegen van elk trefwoord om meerdere trefwoorden toe te voegen.`,
        liveChatTriggers: 'Triggers voor live chat',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Voer trefwoorden in om live chat te activeren en druk op Enter',
        liveChatTriggersTooltip: `Definieer trefwoorden die, wanneer ze door de gebruiker worden ingevoerd en verzonden, een overgang van geautomatiseerde chat naar live chat zullen initiëren. Dit is handig wanneer gebruikers hulp nodig hebben van een live agent.
         Druk op Enter na het toevoegen van elk trefwoord om meerdere trefwoorden toe te voegen.`,
        messageAfterAutomatedFlowCompleted: 'Bericht na voltooiing van geautomatiseerde stroom',
        enterYourMessage: 'Voer uw bericht in',
        messageAfterAutomatedFlowCompletedTooltip: `Voer een bericht in dat aan de gebruiker wordt getoond nadat ze de geautomatiseerde chatstroom hebben voltooid, maar de sessie niet is verlopen.`,
        restart: 'Herstarten',
        liveChat: 'Live Chat',
        lineOneForDefaultMessage: 'Als u meer vragen heeft of hulp nodig heeft, is hier wat u kunt doen:',
        to: 'Naar',
        restartTheChat: 'herstart de chat',
        simpleTypeRestartAndHitEnter: `typ eenvoudig 'Herstart' en druk op Enter. Dit brengt u terug naar het begin van onze gespreksstroom.`,
        lineThreeForDefaultMessagePartOne: `Als u met een`,
        liveAgent: `live agent wilt spreken,`,
        lineThreeForDefaultMessageLastPart: `typ 'Live Chat' en druk op Enter. Ons team staat hier klaar om u in realtime te helpen.`,
        saveChanges: 'Wijzigingen Opslaan',
        discard: 'Negeren',
        configureBotsGeneralSettings: 'Configureer de algemene instellingen van uw bot',
        minutes: 'Minuten',
        hours: 'Uren',

      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'Slechts een stap verwijderd',
      seeHowToUse: 'Bekijk hoe je het gebruikt',
      testWhatsAppTrialBot: 'Test je WhatsApp Trial Chatbot',
      sendMessage: 'Stuur Bericht',
      sendWhatsAppMessageOn: 'Stuur ons een WhatsApp-bericht op ',
      bySavingInYourContact: 'door ons nummer op te slaan in je contacten en het bericht moet beginnen met ',
      connect: 'Verbinden',
      or: 'OF',
      scanQRCode: 'Scan de QR-code',
      yourPhoneWillOpen: 'Je telefoon zal een voorgevuld bericht openen om naar ons sandbox-nummer te sturen. Druk op \'Verzenden\' op WhatsApp',
      sendVerificationCode: 'Verzend Verificatiecode',
      copyAndEnterCode: 'Kopieer en voer de 6-cijferige code in',
      shareWhereYouWant: 'Deel waar je maar wilt en stuur gebruikers met één klik naar je WhatsApp-bot',
      youShouldHaveReceived: 'Je zou een 6-cijferige code moeten hebben ontvangen op je WhatsApp-nummer',
      EnterYourPhoneNumber: 'Voer je Telefoonnummer in',
      send: 'Verstuur',
      sixDigitCode: 'Voer de 6-cijferige code in',
      pleaseNote: 'Let op: de 6-cijferige verificatiecode kan tot 3 minuten duren voordat je deze opnieuw kunt verzenden.',
      resendVerificationCode: 'Verzend verificatiecode opnieuw',
      verify: 'Verifiëren',
      providePhoneNumber: 'Geef het nummer op dat je voor testen wilt gebruiken',
      demoBotisReady: 'Geweldig! Je demo-bot is klaar voor gebruik',
      placeHolder: {
        phone: 'Voer je telefoonnummer in',
        code: 'Voer verificatiecode in',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Integratie van Apps van Derden',
    thirdPartyApps: 'Apps van Derden',
    customApps: 'Aangepaste Apps',
    preview: 'Voorbeeld',
    test: 'Test',
    install: 'Installeren',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Klik om je bot te installeren op het geselecteerde platform',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Integreer je aangepaste gemaakte Apps met je bot',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Verbind je bot met beschikbare Apps van Derden/CRMs',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Integraties van Apps van Derden',
        integrateYourBotWithListedCrms: 'Integreer je bot met een van de vermelde Apps/CRMs.',
        help: 'Hulp',
        name: 'Naam',
        status: 'Status',
        action: 'Actie',
        chatGpt: 'ChatGPT',
        addOn: 'Toevoeging',
        purchased: 'Gekocht',
        readyToRideGptWave: 'Klaar om de GPT-golf te berijden? Integreer nu.',
        purchase: 'Kopen',
        notConnected: 'Niet Verbonden',
        integrated: 'Geïntegreerd',
        configure: 'Configureren',
        connected: 'Verbonden',
        edit: 'Bewerken',
        connect: 'Verbinden',
        requestYourIntegration: 'Vraag je Integratie aan',
        toRequestNewIntegration: 'Om een nieuwe integratie aan te vragen, vul dan de onderstaande gegevens ' +
          'in en dan nemen we contact met je op.',
        //
        emailId: 'E-mailadres',
        integrationName: 'Naam Integratie',
        contactNumber: 'Contactnummer',
        howSoonYouWantToAddThisIntegration: 'Hoe snel wil je deze Integratie toevoegen',
        sendRequest: 'Verzend Aanvraag',
        cancel: 'Annuleren',
        requestSent: 'Aanvraag Verzonden!',
        yourRequestSuccessfullySent: 'Je aanvraag voor een nieuwe integratie is succesvol verzonden.',
        goBack: 'Ga Terug',
        disconnect: 'Verbreek Verbinding',
        OopsIntegrationAreNotAvailable: 'Oeps! Integraties zijn niet beschikbaar in ',
        pleaseUpgradeTo: '  Upgrade naar ',
        planForIntegration: ' voor integraties.',
        placeholders: {
          searchIntegrationByName: 'Zoek integratie op naam',
          enterTeamMemberName: 'Voer naam van teamlid in',
          enterEmailId: 'Voer E-mailadres in',
          enterIntegrationName: 'Voer een Integratienaam in',
          searchCountry: 'Zoek Land',
          enterContactNumber: 'Voer Contactnummer in',
          addComment: 'Voeg Opmerking toe',
        },
        noDataFound: {
          integrations: 'Integraties',
          noIntegrationsFound: 'Geen integraties gevonden!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Naam van App/CRM',
          statusAsConnectedOrNotConnected: 'Status als verbonden / niet-verbonden',
          clickToConnectToAppOrCrm: 'Klik om de App/CRM te verbinden',
        },
        feedbackModal: {
          submit: 'Verzenden',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          agileCrmDomain: 'Agile CRM Domein',
          nameIsRequired: 'Naam is vereist',
          emailAddress: 'E-mailadres',
          apiToken: 'API-token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameter: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            exampleSite: 'https://bedrijf.agilecrm.com',
            exampleEmail: 'voorbeeld@bedrijf.extensie',
            enterTokenHere: 'Voer hier token in',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiKey: 'API-sleutel',
          accessKey: 'Toegangssleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterApiKeyHere: 'Voer hier API-sleutel in',
            enterAccessKeyHere: 'Voer hier Toegangssleutel in',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiToken: 'API-token',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterApiToken: 'Voer API-token in',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          nameColon: 'Naam:',
          inboundWebhookUrl: 'Inbound webhook-url',
          nameIsRequired: 'Naam is vereist',
          leadTitle: 'Leidende Titel',
          source: 'Bron',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterInboundWebhookUrl: 'Voer Inbound webhook-url in...',
            enterTitle: 'Voer titel in',
            enterSource: 'Voer bron in'
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiToken: 'API-token',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterTokenHere: 'Voer hier token in',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiToken: 'API-token',
          nameIsRequired: 'Naam is vereist',
          testLead: 'Test Lead',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterApiToken: 'Voer API-token in',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiToken: 'API-token',
          testLead: 'Test Lead',
          connect: 'Verbinden',
          selectTheQuestion: 'Selecteer de vraag waarvan je het gebruikersantwoord als waarde wilt verzenden',
          mapWith: 'koppelen met',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterApiToken: 'Voer API-token in',
            attributes: 'Attributen(sleutel)',
            values: 'waarden',
          }
        },
        chatGpt: {
          chatGptApiKey: 'ChatGPT (Open AI) API-sleutel',
          chatGpt: 'ChatGPT (Open AI)',
          readyToRideWave: 'Klaar om de GPT-golf te berijden? Integreer nu.',
          doNotHaveAnyApiKeyNeedHelp: 'Heb je geen API-sleutel, Hulp nodig?',
          authenticate: 'Authenticeren',
          nowYouCanAccessChatGpt: 'Nu kun je de Chat GPT-component gebruiken in de chatflow-builder',
          paymentSuccessful: 'Betaling geslaagd!',
          paymentHasBeenCompletedSuccessfully: 'De betaling is succesvol afgerond. Je bent klaar om je bot te trainen',
          cancel: 'Annuleren',
          trainNow: 'Train Nu',
          paymentInProgress: 'Betaling In Uitvoering...',
          apiKey: 'ChatGPT API-sleutel',
          model: 'Model',
          integrateWithChatGPT: 'Integreren met ChatGPT',
          updateKey: 'API-sleutel bijwerken',
          connect: 'Verbinden',
          update: 'Bijwerken',
          placeholders: {
            chatGptApiKey: 'ChatGPT (Open AI) API-sleutel',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiToken: 'API Token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          leadId: 'Lead Id',
          selectLeadId: 'Selecteer LeadId',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterTokenHere: 'Voer token hier in',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          emailAddress: 'E-mailadres',
          leadTitle: 'Lead Titel',
          apiToken: 'API Token',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            exampleEmail: 'voorbeeld@bedrijf.extensie',
            enterYourLeadTitle: 'Voer je Lead Titel in',
            enterTokenHere: 'Voer token hier in',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          dripCrmAccountId: 'Drip CRM Account Id',
          apiToken: 'API Token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterAccountIdHere: 'Voer Account Id hier in',
            enterYourTokenHere: 'Voer je token hier in',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apikey: 'Api Key',
          accessToken: 'Toegangssleutel',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          selectParameter: 'Selecteer Parameter',
          disconnect: 'Verbreek Verbinding',
          update: 'Bijwerken',
          save: 'Opslaan',
          placeholders: {
            enterApiKeyHere: 'Voer Api Key hier in',
            enterTokenHere: 'Voer Toegangssleutel hier in',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiKey: 'API Key',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          testLead: 'Test Lead',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterApiToken: 'Voer de API Key in',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          authToken: 'AuthToken',
          source: 'Bron',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterAuthTokenHere: 'Voer AuthToken hier in',
            enterSourceHere: 'Voer Bron hier in'
          }
        },
        facebookPixel: {
          facebookPixel: 'Facebook Pixel',
          getInsight: `Krijg inzichten die van jouw bedrijf een onderneming maken met BotPenguin's Google Analytics-integratie`,
          pixelId: 'Facebook Pixel Id',
          connect: 'Verbinden',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterYourId: 'Voer Facebook Pixel Id in'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          companyName: 'Bedrijfsnaam',
          emailAddress: 'E-mailadres',
          password: 'Wachtwoord',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapsWith: 'koppelt met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterCompanyName: 'Voer bedrijfsnaam in',
            enterEmailAddress: 'Voer e-mailadres in',
            enterPassword: 'Voer wachtwoord in',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiKey: 'API Key',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterApiKeyHere: 'Voer Api Key hier in',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          flowluDomain: 'Flowlu Domein',
          apiToken: 'API Token',
          title: 'Titel',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            exampleDomain: 'https://jouwbedrijf.flowlu.com',
            enterTokenHere: 'Voer Token hier in',
            enterYourLeadTitle: 'Voer je Lead Titel in'
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          freshdeskDomain: 'Freshdesk Domein',
          apiToken: 'API Token',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            exampleDomain: 'https://jouwsubdomein.freshdesk.com',
            enterTokenHere: 'Voer Token hier in',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je deze configureren.',
          freshworkCrmDomain: 'Freshwork CRM-domein',
          apiToken: 'API-token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'toewijzen aan',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            exampleDomain: 'https://jouwbedrijf.myfreshworks.com',
            enterToken: 'Voer token hier in'
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je deze configureren.',
          apiToken: 'API-token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          mapWith: 'toewijzen aan',
          select: 'Selecteren',
          update: 'Bijwerken',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiToken: 'Voer het API-token in',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je deze configureren.',
          apiKey: 'API-sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          campaignList: 'Campagnelijst',
          selectCampaign: 'Selecteer Campagne',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'toewijzen aan',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer token hier in',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          getInsights: `Krijg inzichten die van jouw bedrijf een onderneming maken met de Google Analytics-integratie van BotPenguin`,
          measurementId: 'Meet-ID ',
          connect: 'Verbinden',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterYourId: 'Voer jouw ID in',
          }
        },
        googleCalendar: {
          testLead: 'Test Lead',
          name: 'Naam:',
          email: 'E-mail:',
          appointmentSummary: 'Samenvatting van afspraak',
          appointmentDescription: 'Beschrijving van afspraak',
          addParameters: ' Parameters toevoegen',
          questionColon: 'Vraag:',
          select: 'Selecteren',
          mapWith: 'mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          pause: 'Pauze',
          resume: 'Hervatten',
          disconnect: 'Ontkoppelen',
          reconnect: 'Opnieuw verbinden',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          name: 'Naam :',
          email: 'E-mail : ',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
        },
        googleRedirect: {

        },
        googleSheet: {
          testLead: 'Test Lead',
          title: 'Titel',
          questionColon: 'Vraag:',
          addParameters: 'Paramaters toevoegen',
          select: 'Selecteren',
          mapWith: 'mappen met',
          update: 'Bijwerken',
          save: 'Opslaan',
          pause: 'Pauze',
          resume: 'Hervatten',
          disconnect: 'Ontkoppelen',
          reconnect: 'Opnieuw verbinden',
          placeholders: {
            mappingValues: 'Toewijzingswaarden',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          name: 'Naam:',
          email: 'E-mail:',
          taskList: 'Takenlijst',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          pause: 'Pauze',
          resume: 'Hervatten',
          disconnect: 'Ontkoppelen',
          reconnect: 'Opnieuw verbinden',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiToken: 'API Token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          select: 'Selecteren',
          addParameters: ' Parameters toevoegen',
          mapWith: 'mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer token hier in',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiKey: 'API Sleutel',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiHere: 'Voer API hier in',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          testLead: 'Test Lead',
          accountId: 'Account ID',
          accessToken: 'Toegangstoken',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterAccountIdHere: 'Voer Account-ID hier in',
            enterAccessTokenHere: 'Voer Toegangstoken hier in',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiToken: 'API Token',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          testLead: 'Test Lead',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer token hier in',
          }
        },
        helpLink: {
          help: 'Help',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          helpRaceCrmDomain: 'Helprace CRM-domein',
          emailAddress: 'E-mailadres',
          apiToken: 'API-token',
          connect: 'Verbinden',
          update: 'Bijwerken',
          placeholders: {
            exampleDomain: 'https://bedrijf.helprace.com',
            exampleEmail: 'voorbeeld@bedrijf.extensie',
            token: 'Token'
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          domainName: 'Domeinnaam',
          apiKey: 'API-sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer token hier in'
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiToken: 'API-token',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiToken: 'Voer de API-token in'
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiKey: 'API-sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiHere: 'Voer hier de API in'
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiToken: 'API-token',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiToken: 'Voer de API-token in'
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiToken: 'API-token',
          emailAddress: 'E-mailadres',
          apiUrl: 'API-url',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          //
          selectProjectName: 'Selecteer projectnaam',
          selectIssueType: 'Selecteer probleemtype',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapsWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer hier de token in',
            enterEmailAddress: 'Voer het e-mailadres in',
            exampleUrl: 'https://voorbeeld.atlassian.net'
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiUrl: 'API-url',
          nameIsRequired: 'Naam is vereist',
          apiKey: 'API-sleutel',
          apiId: 'API-ID',
          //
          connect: 'Verbinden',
          testLead: 'Test Lead',
          mapWith: 'Mappen met',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          selectTheQuestion: 'Selecteer de vraag waarvan je het antwoord van de gebruiker wilt verzenden als waarde',
          placeholders: {
            enterApiUrl: 'Voer de API-url in',
            enterApiKey: 'Voer de API-sleutel in',
            enterApiId: 'Voer de API-ID in',
            attributes: 'Attributen(sleutel)',
            values: 'waarden',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiKey: 'API-sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer hier de token in'
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiToken: 'API-token',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiToken: 'Voer de API-token in'
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          accessKey: 'Toegangssleutel',
          secretKey: 'Geheime sleutel',
          connect: 'Verbinden',
          apiLogs: 'API-logboeken',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          udpate: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          filterApplied: 'filter toegepast',
          retry: 'Opnieuw proberen',
          back: 'Terug',
          filterYourLeads: 'Filter je leads',
          filterOn: 'Filter op',
          filterBy: 'Filteren op ',
          discard: 'Verwerpen',
          applyFilter: 'Filter toepassen',
          loadMore: 'Meer laden',
          placeholders: {
            enterAccessKeyHere: 'Voer hier de toegangssleutel in',
            enterSecretKeyHere: 'Voer hier de geheime sleutel in',
            attributes: 'Kenmerken(sleutel)',
            values: 'waarden',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Gegevens filteren op basis van respons',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiKey: 'API-sleutel',
          apiUrl: 'API-url',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Parameter selecteren',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiHere: 'Voer hier de API in',
            exampleUrl: 'https://comapanyName.ladesk.com'
          }
        },
        microsoftTeams: {
          downloadPackage: 'Download de Microsoft Teams app-pakket',
          downloadApp: 'Download App',
          wantToLearnHowToUploadIt: 'Wil je leren hoe je het kunt uploaden?',
          needHelp: 'Hulp nodig?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiUrl: 'API URL',
          appId: 'APP ID',
          nameIsRequired: 'App ID is verplicht',
          dataApiKey: 'DATA API KEY',
          dataApiKeyIsRequired: 'Data API Key is verplicht',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          addParameters: ' Parameters toevoegen',
          mapWith: 'Mappen met',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          selectTheQuestion: 'Selecteer de vraag waarvan je het antwoord van de gebruiker wilt verzenden als waarde',
          placeholders: {
            enterApiUrl: 'Voer de API URL in',
            enterAppId: 'Voer de APP ID in',
            enterDataApiKey: 'Voer de DATA API KEY in',
            attributes: 'Attributen(sleutel)',
            pressForMappingQuestion: 'Druk / voor Mappen met vraag',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          emailAddress: 'E-mailadres',
          apiKey: 'API-sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          leadId: 'Lead Id',
          selectLeadId: 'Selecteer LeadId',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterEmailHere: 'Voer e-mail hier in',
            enterApiKeyHere: 'Voer API-sleutel hier in',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          apiKey: 'API-sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiToken: 'Voer de API-sleutel in',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          noCrmDomain: 'Nocrm Domein',
          leadTitle: 'Lead Titel',
          titleIsRequired: 'Titel is verplicht',
          token: 'Token',
          tokenIsRequired: 'Token is verplicht',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            exampleDomain: 'https://JOUW_SUBDOMEIN_HIER.nocrm.io',
            enterYourLeadTitle: 'Voer je Lead Titel in',
            enterYourToken: 'Voer je Token in',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          emailAddress: 'E-mailadres',
          apiKey: 'API-sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiToken: 'Voer de API-sleutel in',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          secretToken: 'Geheime Token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer Token hier in',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          appId: 'App Id',
          appIdIsRequired: 'App Id is verplicht',
          apiKey: 'API-sleutel',
          apiKeyIsRequired: 'API-sleutel is verplicht',
          connect: 'Verbinden',
          update: 'Bijwerken',
          disconnect: 'Ontkoppelen',
          placeholders: {
            appId: 'App Id',
            apiKey: 'API-sleutel',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt succesvol een integratie gemaakt. Nu kun je deze configureren.`,
          clientId: 'Client Id',
          connect: 'Verbinden',
          clientSecret: 'Client Geheim',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Parameters toevoegen',
          select: 'Selecteren',
          mapWith: 'Mappen met',
          selectParameter: 'Selecteer Parameter',
          save: 'Opslaan',
          update: 'Bijwerken',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterClientIdHere: 'Voer Client ID hier in',
            enterClientSecretHere: 'Voer Client Geheim hier in',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          pipedriverDomain: 'Pipedriver Domein',
          domainIsRequired: 'Domein is verplicht',
          apiToken: 'API Token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            exampleDomain: 'https://jouwbedrijf.pipedrive.com',
            enterTokenHere: 'Voer hier je token in',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          apiKey: 'API Sleutel',
          apiKeyIsRequired: 'API sleutel is verplicht',
          appKey: 'APP Sleutel',
          appKeyIsRequired: 'APP sleutel is verplicht',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiKey: 'Voer de API Sleutel in',
            enterAppKey: 'Voer de APP Sleutel in',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          authId: 'Authenticatie ID',
          authToken: 'Authenticatie Token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer Token hier in',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          apiKey: 'API Sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiHere: 'Voer API hier in',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          apiKey: 'API Sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiKey: 'Voer de API Sleutel in',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          domain: 'Domein',
          clientId: 'Client ID',
          clientSecret: 'Client Geheim',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterDomainHere: 'Voer hier het domein in',
            enterClientIdHere: 'Voer hier de Client ID in',
            enterClientSecretHere: 'Voer hier het Client Geheim in',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          apiKey: 'API Sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          addEventsAndTemplates: 'Voeg Evenementen en Sjablonen Toe:',
          add: ' Voeg Toe',
          question: 'Vraag',
          reply: 'Antwoord',
          requestBody: 'Verzoek Body',
          validateJson: 'Valideer JSON',
          emailAddress: 'E-mailadres',
          //
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          selectTheQuestion: 'Selecteer de vraag waarvan je het antwoord van de gebruiker wilt sturen als waarde',
          placeholders: {
            enterApiHere: 'Voer hier de API in',
            enterTemplateId: 'Voer de Sjabloon ID in',
            typeYourJsonCodeHere: 'Typ hier je JSON Code',
          },
          appTooltip: {
            typeYourJsonCode: 'Typ je JSON code',
            clickToValidateJsonCode: 'Klik om JSON code te valideren',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          userName: 'Gebruikersnaam',
          licenseKey: 'Licentiesleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterNameHere: 'Voer naam in',
            enterKeyHere: 'Voer sleutel in',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          apiToken: 'API Token',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer Token hier in',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je deze configureren.',
          company: 'Bedrijf',
          loginId: 'Inlog-ID',
          password: 'Wachtwoord',
          testLead: 'Testleider',
          connect: 'Verbinden',
          selectTheQuestion: 'Selecteer de vraag waarvan je het antwoord van gebruikers als waarde wilt verzenden',
          mapWith: 'Mappen met',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek de verbinding',
          selectParameter: 'Parameter selecteren',
          placeholders: {
            enterCompanyHere: 'Voer hier de naam van het bedrijf in',
            enterLoginIdHere: 'Voer hier het Inlog-ID in',
            enterPasswordHere: 'Voer hier het wachtwoord in',
            values: 'waarden',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          applicationKey: 'Toepassingscode',
          authToken: 'Authenticatietoken',
          connect: 'Verbinden',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApplicationKeyHere: 'Voer toepassingscode hier in',
            enterTokenHere: 'Voer Token hier in',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          accessToken: 'Toegangstoken',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          question: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterTokenHere: 'Voer Token hier in',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          webhoperDomain: 'Webhoper Domein',
          domainIsRequired: 'Domein is verplicht',
          companyName: 'Bedrijfsnaam',
          companyIsRequired: 'Bedrijf is verplicht',
          leadSource: 'Leadbron',
          leadSourceIsRequired: 'Leadbron is verplicht',
          division: 'Afdeling',
          divisionIsRequired: 'Afdeling is verplicht',
          enterPassword: 'Voer wachtwoord in',
          passwordIsRequired: 'Wachtwoord is verplicht',
          disconnect: 'Ontkoppelen',
          update: 'Bijwerken',
          connect: 'Verbinden',
          placeholders: {
            exampleDomain: 'http://jouwbedrijfsdomein',
            enterYourCompanyName: 'Voer je Bedrijfsnaam in',
            enterLeadSource: 'Voer Leadbron in',
            enterDivision: 'Voer Afdeling in',
            enterPassword: 'Voer wachtwoord in',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          name: 'Naam:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'URL voor inkomende webhook',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterWebhookUrl: 'Voer de webhook-url in',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          apiToken: 'API Token',
          emailAddress: 'E-mailadres',
          apiUrl: 'API URL',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterEmailAddress: 'Voer e-mailadres in',
            enterUrlHere: 'Voer URL hier in',
            enterTokenHere: 'Voer Token hier in',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          apiKey: 'API Sleutel',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Ontkoppelen',
          placeholders: {
            enterApiHere: 'Voer API hier in',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          connect: 'Verbinden',
          update: 'Bijwerken',
          disconnect: 'Ontkoppelen',
          companyName: 'Bedrijfsnaam',
          emailAddress: 'E-mailadres',
          phone: 'Telefoon',
          countryCode: 'Landcode',
          questionColon: 'Vraag:',
          addParameters: ' Voeg Parameters Toe',
          select: 'Selecteren',
          mapWith: 'koppelen aan',
          selectParameter: 'Selecteer Parameter',
          //
          save: 'Opslaan',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          connect: 'Verbinden',
          disconnect: 'Ontkoppelen',
          testLead: 'Test Lead',
          emailAddress: 'E-mailadres',
          selectOrganizationName: 'Selecteer Organisatienaam',
          selectDepartmentName: 'Selecteer Afdelingsnaam',
          addParameters: ' Parameters Toevoegen',
          questionColon: 'Vraag:',
          select: 'Selecteren',
          mapsWith: 'koppelt met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
          testLead: 'Test Lead',
          connect: 'Verbinden',
          disconnect: 'Ontkoppelen',
          projectsList: 'Projectenlijst',
          selectProjectsName: 'Selecteer Projectnaam',
          projectsTaskList: 'Takenlijst van Projecten',
          selectTaskListName: 'Selecteer Taaklijstnaam',
          questionColon: 'Vraag:',
          addParameters: ' Parameters Toevoegen',
          select: 'Selecteren',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
        },
        wooCommerce: {
          configure: 'Configureren',
          automatedMessage: 'Geautomatiseerd Bericht',
          configureTab: {
            wooCommercePluginApiKey: 'WooCommerce Plugin API-sleutel',
            downloadWooCommercePlugin: 'Download WooCommerce Plugin'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Bericht voor Terugwinning van Verlaten Winkelwagens',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Configureer geautomatiseerde herstelberichten om klanten terug te winnen die hun winkelwagens hebben verlaten',
            enableAbandonedCartRecoveryMessaegs: 'Geef verlaten winkelwagen-herstelberichten weer',
            selectTemplate: 'Selecteer Sjabloon',
            sendMessageAfter: 'Stuur Bericht Na',
            templateMessage: 'Sjabloon Bericht',
            saveChanges: 'Wijzigingen Opslaan',
            selectFile: 'Selecteer Bestand',
            templateHeader: 'Sjabloon Koptekst',
            orderFulfillMent: 'Orderverwerking',
            orderCompleted: 'Bestelling Voltooid',
            enableOrderConfirmationMessages: 'Schakel bestelbevestigingsberichten in',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Stuur een bestelbevestigingsbericht zodra een klant een bestelling plaatst.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Het bericht over orderverzending wordt verzonden wanneer je een bestelling vervult.',
            enableOrderFulfillmentMessages: 'Schakel berichten over ordervervulling in',
            returnExchangeRequest: 'Retour-/Ruilaanvraag',
            theMessageIsSentAfterARefundIsRequested: 'Het bericht wordt verzonden nadat een teruggave is aangevraagd.',
            enableOrderRefundMessages: 'Schakel berichten over orderteruggave in',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Je hebt de integratie succesvol aangemaakt. Nu kun je het configureren.',
          apiToken: 'API-token',
          nameIsRequired: 'Naam is vereist',
          connect: 'Verbinden',
          testLead: 'Test Lead',
          questionColon: 'Vraag:',
          addParameters: 'Parameters Toevoegen',
          select: 'Selecteer',
          mapWith: 'koppelen met',
          selectParameter: 'Selecteer Parameter',
          update: 'Bijwerken',
          save: 'Opslaan',
          disconnect: 'Verbreek Verbinding',
          placeholders: {
            enterApiToken: 'Voer API-token in',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Nieuwe Integratie Maken',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'Je hebt momenteel geen integraties ingesteld.',
      pleaseCreateOneToIntegrateYourFavourite: 'Maak er een om je favorieten te integreren',
      appsWith: 'apps met ',
      integrationName: 'Integratienaam',
      integrationNameIsRequired: 'Integratienaam is verplicht',
      continue: 'Doorgaan',
      addAnImageToRepresentYourIntegration: 'Voeg een afbeelding toe om je integratie te vertegenwoordigen.',
      description: 'Beschrijving',
      submit: 'Verzenden',
      youHaveSuccessfullyCreatedIntegration: `Je hebt de integratie succesvol gemaakt. Nu kun je het configureren.`,
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: 'Voer een geldige API URL in',
      requestMethod: 'Verzoekmethode',
      get: 'Get',
      post: 'Post',
      put: 'Put',
      patch: 'Patch',
      queryParamter: 'Queryparameter',
      addParameter: ' Parameter Toevoegen',
      mapsWith: ' koppelt met',
      question: 'Vraag',
      addQuestion: ' Vraag Toevoegen',
      select: 'Selecteren',
      selectParameter: 'Selecteer Parameter',
      header: 'Header',
      addHeader: ' Header Toevoegen',
      value: 'Waarde',
      connect: 'Verbinden',
      requestBody: 'Verzoekbody',
      validate: 'Valideren',
      //
      key: 'Sleutel',
      //
      //
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' kan ook worden geïntegreerd met deze gratis CRM.',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: 'Voer een geldige API URL in',
        requestMethod: 'Verzoekmethode:',
        get: 'Get',
        post: 'Post',
        put: 'Put',
        patch: 'Patch',
        queryParameter: 'Queryparameter',
        addParameter: ' Parameter Toevoegen',
        header: 'Header',
        addHeader: ' Header Toevoegen',
        requestBody: 'Verzoekbody',
        validateJson: 'Valideer JSON',
        connect: 'Verbinden',
        update: 'Bijwerken',
        delete: 'Verwijderen',
        selectTheQuestion: 'Selecteer de vraag waarvan je het antwoord van de gebruiker als waarde wilt sturen',
        appTooltip: {
          typeYourJsonCode: 'Typ je JSON-code',
          clickToValidateJsonCode: 'Klik om JSON-code te valideren',
        }
      },
      list: {
        customAppsIntegrations: 'Aangepaste App-integraties',
        integrateYourAppsWith: 'Integreer je eigen apps met ',
        help: 'Help',
        addIntegration: ' Integratie Toevoegen',
        name: 'Naam',
        status: 'Status',
        action: 'Actie',
        insatlled: 'Geïnstalleerd',
        edit: 'Bewerken',
        connect: 'Verbinden',
        requestYourIntegration: 'Vraag je Integratie aan',
        toRequestNewIntegration: 'Om een nieuwe integratie aan te vragen, ' +
          'vul dan de onderstaande gegevens in en we nemen contact met je op.',
        //
        emailId: 'E-mailadres',
        integrationName: 'Integratienaam',
        contactNumber: 'Contactnummer',
        howSoonYouWantToAddThisIntegration: 'Hoe snel wil je deze Integratie toevoegen',
        sendRequest: 'Verzoek verzenden',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Oeps! Uw eigen integraties worden alleen ondersteund in',
        plan: 'Plan',
        pleaseUpgradeTo: 'Upgrade naar',
        toCreateOwnIntegrations: `om Eigen Integraties te maken`,
        appTooltip: {
          integrations: 'Integraties',
          noIntegrationsFound: 'Geen integraties gevonden!',
          nameOfYourApp: 'Naam van je App',
          clickToConnectApp: 'Klik om de App te verbinden',
          statusAsConnectedOrNotConnected: 'Status als verbonden / niet-verbonden',
        }
      }
    }
  }
};
