import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-chat-requests-list',
  templateUrl: './chat-requests-list.component.html',
  styleUrls: ['./chat-requests-list.component.scss']
})
export class ChatRequestsListComponent implements OnInit, OnDestroy {
  private subscription$: any;
  liveChatRequestList: any[] = [];
  constructor(
    private store: Store<any>,
  ) { }

  ngOnInit(): void {
    this.subscription$ = this.store.select(state => state)
    .subscribe(state => {
    this.liveChatRequestList = state.requests;
  });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

}
