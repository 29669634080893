export const notification = {
  notification: 'Notificaciones',
  allNotifications: 'Todas las notificaciones',
  general: 'General',
  settings: 'Ajustes',
  newChatRequest: {
    youHaveANewChatRequest: 'Tienes una nueva solicitud de chat !!!',
    youHaveTransferredChatRequest: 'Ha transferido la solicitud de chat',
    acceptRequest: 'Aceptar petición',
    discardRequest: 'Descartar solicitud',
  },
  notificationList: {
    name: 'Nombre',
    message: 'Mensaje',
    noDataFound: {
      notification: 'Notificación',
      noNotificationsFound: '¡No se encontraron notificaciones!',
    }
  },
  notificationSetting: {
    userMessage: 'Mensaje de usuario',
    receiveNotificationsWhenUserSendMessage: 'Recibir notificaciones siempre que algún usuario envíe algún mensaje',
    liveChatRequest: 'Solicitud de chat en vivo',
    receiveNotificationsWhenUserRequestLiveChat: 'Recibir notificaciones cada vez que cualquier solicitud de usuario de chat en vivo',
    newLead: 'Nuevo plomo',
    receiveNotificationsWhenNewLeadCreated: 'Recibir notificaciones cada vez que se crean nuevos clientes potenciales',
    assignedOnly: 'Solo asignado',
    allUsers: 'Todos los usuarios',
    customizeWhichNotificationsYouWantToRecieve: 'Personalizar qué notificaciones desea recibir',
    youCanCustomizeSettingForTheNotifications: 'Puede personalizar la configuración para las notificaciones',
    receiveNotifications: 'Recibir notificaciones',
    desktopNotifications: 'Notificaciones de escritorio',
    mobilePushNotifications: 'Notificaciones de push móviles',
    discard: 'Desechar',
    update: 'Actualizar',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Enviar notificaciones de un nuevo mensaje en el escritorio',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Enviar notificaciones de un nuevo mensaje a través de Mobile',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Enviar notificaciones de nuevo plomo sobre el escritorio',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Enviar notificaciones de nuevo plomo a través de Mobile',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Enviar notificaciones de chat en vivo sobre escritorio',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Enviar notificaciones de chat en vivo a través de móvil',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Elija para qué mensajes desea recibir una notificación',
    }
  }
};
