const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'My Account',
  yourProfileAndPasswordSecurity: 'Your profile and password security',
  detailsHeader: 'Details',
  passwordHeader: 'Password',
  customAttributeHeader: 'Custom Attribute',
  tagsHeader: 'Tags',
  accessTokenHeader: 'Access Token',
  thirdPartyIntegrationsHeader: 'Third Party Integrations',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Update your account profile',
    TOOLTIP_PASSWORD_HEADING: 'Change your password',
    TOOLTIP_TAGS_HEADING: 'Manage your tags',
    TOOLTIP_THIRD_PARTY_HEADING: 'Third Party Integrations',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `A secure password helps protect your ${brand} account`,
    TOOLTIP_INTEGRATIONS: 'Integrate',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Add or update custom attributes',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Manage your custom attributes'
  },
  accessToken: {
    accessToken: 'Access Token',
    generateYourAccessTokenForUsingPlatformAPIs: `Generate your access token for using platform API's`,
    generateApiToken: 'Generate API Token',
    revokeApiToken: 'Revoke API Token',
    regenerateApiToken: 'Regenerate API Token',
    placeholders: {
      yourApiKey: 'Your Api Key',
    }
  },
  details: {
    name: 'Name',
    emailId: 'Email ID ',
    yourPlan: 'Your Plan',
    messagesPerMonth: ' messages/month',
    daysOfStoragePlan: ' days of storage plan',
    conditionalFeatures: 'conditional features',
    upgradeToEnterprisePlanToGetMore: 'Upgrade to Enterprise plan to get more',
    featuresAndStorageCapacity: 'feature and storage capacity',
    upgradePlan: 'Upgrade Plan',
    submit: 'Submit',
    selectLanguage: 'Select Language',
    baby: 'Baby',
    king: 'King',
    conversationsperMonth: 'coversations/month',
    english: 'English',
    hindi: 'Hindi',
    german: 'German',
    french: 'French',
    portuguese: 'Portugese',
    spanish: 'Spanish',
    italian: 'Italian',
    russian: 'Russian',
    chatBotBrandName: 'Chat Bot Brand Name',
    placeholders: {
      john: 'John',
      email: 'Email',
      searchLanguage: 'Search Language',
      chatBotBrandName: 'Chat Bot Brand Name',
    },
    appTooltip: {
      TOOLTIP_NAME: 'Enter your profile name',
      TOOLTIP_EMAIL_ADDRESS: 'Enter your email address',
    },
    updateProfile: 'Update Profile',
    updateProfileAndDetail: 'Update your Profile and personal detail',

  },
  customAttribute: {
    addAttribute: 'Add Attribute',
    discard: 'Discard',
    customAttribute: 'Custom Attributes',
    checkOrAddCustomAttribute: 'Here you can check and add your custom attributes.',
    attributeKey: 'Attribute Key',
    attributeName: 'Attribute Name',
    bot: 'Bot',
    flow: 'Flow',
    loadMore: 'Load More',
    question: 'Question',
    action: 'Action',
    search: 'Search',
    selectBot: 'Select Bot',
    selectFlow: 'Select Flow',
    selectQuestion: 'Select Question',
    noDataFoundHeading: 'You do not have any attributes created yet',
    noDataFoundSubheading: 'All of your attributes will appear here',
    deleteTitle: 'Delete Attribute',
    deleteDescription: 'Are you sure you want to delete this Attribute?',
    deleteAttributes: 'Delete Attributes',
    areYouSureYouWantToDeleteThisAttributes: 'Are you sure you want to delete this Attributes?',
    editAttribute: 'Edit Attribute',
    headsUp: 'Heads Up',
    okay: 'Okay',
    dataAddedToLead:
      'The attributes mapped to your APIs or Bot Flows will not be updated. Please update the APIs or Bot Flows accordingly.',
    placeholders: {
      enterAttributeKey: 'Enter Attribute Key',
      enterAttributeName: 'Enter Attribute Name',
      selectBot: 'Select Bot',
      selectFlow: 'Select Flow',
      selectQuestion: 'Select Question',
      search: 'Search by Attribute Name',
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Enter Attribute Key',
      TOOLTIP_ATTRIBUTE_NAME: 'Enter Attribute Name',
      TOOLTIP_SELECT_BOT: 'Select Bot',
      TOOLTIP_SELECT_FLOW: 'Select Flow',
      TOOLTIP_SELECT_QUESTION: 'Select Question',

    }
  },
  password: {
    changePassword: 'Change Password',
    aSecurePasswordHelpsProtectYour: 'A secure password helps protect your ',
    account: ' Account',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    cancel: 'Cancel',
    updatePassword: 'Update Password',
    deleteAccount: 'Delete Account',
    placeholders: {
      enterYourCurrentPassword: 'Enter Your Current Password',
      enterYourNewPassword: 'Enter your new password',
      enterYourConfirmPassword: 'Enter your confirm password',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Enter old password',
      TOOLTIP_NEW_PASSWORD: 'Enter new password',
      TOOLTIP_CONFIRM_PASSWORD: 'Confirm your new password',
      TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Manage Tags',
    createNewTags: 'Create New Tags',
    createNewTagsSubText: 'Manage your tags here to use them better organize your leads, chats, groups  etc',
    tagName: 'Tag Name',
    updateTag: 'Update Tag',
    createTag: 'Create Tag',
    discard: 'Discard',
    searchTag: 'Search Tag by Name',
    noTagFound: 'No Tag Found!',
    createATag: 'Create a Tag',
    deleteTagModalSubText: 'Are you sure you want to delete this tag? Deleting this tag will remove this tag from the assigned users as well!',
    deleteTagModalHeading: 'Delete Tags',
    deleteOneTagModalHeading: 'Delete Tag',
    actions: 'Actions',
    placeholders: {
      enterTag: 'Enter Tag Name',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'Delete Tags',
      TOOLTIP_MANAGE_TAGS: 'Manage your Tags',
    }
  },
  thirdPartyIntegration: {
    list: {
      name: 'Name',
      status: 'Status',
      action: 'Action',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird for Sending Messages and Emails',
      notConnected: 'Not Connected',
      connected: 'Connected',
      edit: 'Edit',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo for Sending Messages',
      connect: 'Connect',
      appTooltip: {
        nameOfAppOrCrm: 'Name of App/CRM',
        statusAsConnectedOrNotConnected: 'Status as connected / not-connected',
        clickToConnectAppOrCrm: 'Click to connect the App/CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Oops! Integrations are not available in baby plan. Please upgrade to King plan for integrations.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'messageBird',
        integrateWithMessageBird: 'Integrate with MessageBird',
        apiKey: 'API Key',
        originator: 'Originator',
        connect: 'Connect',
        disconnect: 'Disconnect',
        seeActivities: 'See Activities',
        sms: 'SMS ',
        time: 'Time',
        phoneNumber: 'Phone Number',
        status: 'Status',
        botName: 'Bot Name',
        message: 'Message',
        error: 'Error',
        placeholders: {
          apiKey: 'API Key',
          messageBirdPhoneNumber: 'MessageBird Phone Number',
        },
        noDataFound: {
          activities: 'Activities',
          noActivitiesFound: 'No activities found!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Integrate with Plivo',
        authId: 'Auth ID',
        authToken: 'Auth Token',
        yourPlivoPhoneNumber: 'Your Plivo Phone Number',
        connect: 'Connect',
        disconnect: 'Disconnect',
        seeActivities: 'See Activities',
        sms: 'SMS',
        time: 'Time',
        phoneNumber: 'Phone Number',
        status: 'Status',
        botName: 'Bot Name',
        message: 'Message',
        error: 'Error',
        noDataFound: {
          activities: 'Activities',
          noActivitiesFound: 'No activities found!',
        },
        placeholders: {
          authId: 'Auth ID',
          authToken: 'Auth Token',
          yourPlivoPhoneNumber: 'Your Plivo Phone Number',
        }
      }
    }
  }
};
