import { Action, createReducer, on } from '@ngrx/store';
import NewLiveChatRequest from '../../models/live-chat-request';
import { loadNewLiveChatRequests, removeLiveChatRequests } from '../actions/live-chat.action';

export const initialState: NewLiveChatRequest[] = [];

const newLiveChatRequestReducer = createReducer(
    initialState,
    on(loadNewLiveChatRequests, (state, { requests }) => [...state, requests]),
    on(removeLiveChatRequests, (state, { requestId }) => state.filter(elem => elem.requestId !== requestId))
);



export function reducer(state: NewLiveChatRequest[], action: Action) {
    return newLiveChatRequestReducer(state, action);
}
