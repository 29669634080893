export const analytics = {
  botWise: {
    botWiseAnalytics: 'נתוני בוט לפי בוט',
    currentBillingCycle: 'מחזור החיוב הנוכחי',
    currentMonth: 'החודש הנוכחי',
    sinceAccountCreation: 'מאז יצירת החשבון',
    messages: 'הודעות',
    leads: 'לידים',
    noDataFound: {
      botwiseAnalytics: 'נתוני בוט לפי בוט',
      noBotHasBeenCreatedYet: 'טרם נוצר בוט!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'סיכום נתונים מהודעות ומגעים/לידים עבור בוטים מסוימים למחזור החיוב הנוכחי, לחודש הנוכחי או מאז יצירת החשבון (נתונים מתעדכנים כל לילה)',
      TOOLTIP_FILTERS: 'סנן נתונים עבור בוט שנבחר',
      TOOLTIP_MESSAGES: 'סך כל הודעות שנצרפו לחשבונך מאז יצירת החשבון',
      TOOLTIP_LEADS: 'סך כל לידים שנתפסו על ידי הבוטים שלך מאז יצירת החשבון.',
    }
  },
  overview: {
    analytics: 'נתוני',
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    overview: 'סקירה כללית',
    messages: 'הודעות',
    leads: 'לידים',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'נתוני הודעות ולידים לכל הבוטים שלך. (נתונים מתעדכנים כל לילה)',
    }
  },
  summary: {
    analyticsSummary: 'סיכום נתונים',
    allBots: 'כל הבוטים',
    daily: 'יומי',
    weekly: 'שבועי',
    monthly: 'חודשי',
    messages: 'הודעות',
    leads: 'לידים',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'סיכם נתונים יומיים, שבועיים או חודשיים מהודעות ומגעים/לידים לבוטים מסוימים ולכל הבוטים (נתונים מתעדכנים כל לילה)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'נתוני WhatsApp',
      month: 'חודש',
      week: 'שבוע',
      today: 'היום',
      filters: 'סננים',
      clearAll: 'נקה הכל',
      apply: 'החל',
      totalConversations: 'סך כל שיחות',
      freeConversations: 'שיחות חינם',
      paidConversations: 'שיחות בתשלום',
      messageSent: 'הודעות נשלחו',
      messageDelivered: 'הודעות נמסרו',
      bpDropDown: {
        selectBot: 'בחר בוט',
      },
      indexMergeDatePicker: {
        startDate: 'תאריך התחלה',
        endDate: 'תאריך סיום',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'נתוני שיחה',
      month: 'חודש',
      week: 'שבוע',
      today: 'היום',
      totalConversations: 'סך כל שיחות',
      paidConversations: 'שיחות בתשלום',
      freeConversations: 'שיחות חינם',
      indexMergeDatePicker: {
        startDate: 'תאריך התחלה',
        endDate: 'תאריך סיום',
      },
      bpDropDown: {
        allBots: 'כל הבוטים'
      }
    },
    messageAnalytics: {
      whatsappMessages: 'הודעות WhatsApp',
      sent: 'נשלחו',
      delivered: 'נמסרו',
      indexMergeDatePicker: {
        startDate: 'תאריך התחלה',
        endDate: 'תאריך סיום',
      },
      bpDropDown: {
        allBots: 'כל הבוטים'
      },
      month: 'חודש',
      week: 'שבוע',
      today: 'היום',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'הודעות WhatsApp לפי סטטוס',
      sent: 'נשלחו',
      delivered: 'נמסרו',
      indexMergeDatePicker: {
        startDate: 'תאריך התחלה',
        endDate: 'תאריך סיום',
      },
      bpDropDown: {
        allBots: 'כל הבוטים'
      },
      month: 'חודש',
      week: 'שבוע',
      today: 'היום',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'כל המנויים',
      whatsappSubscribers: 'מנויי WhatsApp'
    }
  }
};
