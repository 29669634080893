const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Contul Meu',
  yourProfileAndPasswordSecurity: 'Profilul tău și securitatea parolei',
  detailsHeader: 'Detalii',
  passwordHeader: 'Parolă',
  customAttributeHeader: 'Atribute Personalizate',
  tagsHeader: 'Etichetă',
  accessTokenHeader: 'Token de Acces',
  thirdPartyIntegrationsHeader: 'Integrări Terțe',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Actualizează-ți profilul de cont',
    TOOLTIP_PASSWORD_HEADING: 'Schimbă-ți parola',
    TOOLTIP_TAGS_HEADING: 'Gestionați-vă Etichetele',
    TOOLTIP_THIRD_PARTY_HEADING: 'Integrări Terțe',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `O parolă sigură ajută la protejarea contului ${brand} al tău`,
    TOOLTIP_INTEGRATIONS: 'Integrează',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Adăugați sau actualizați atribute personalizate',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Gestionați-vă atributele personalizate'
  },
  accessToken: {
    accessToken: 'Token de Acces',
    generateYourAccessTokenForUsingPlatformAPIs: `Generează-ți tokenul de acces pentru utilizarea API-urilor platformei`,
    generateApiToken: 'Generează Token API',
    revokeApiToken: 'Revocă Token API',
    regenerateApiToken: 'Regenerează Token API',
    placeholders: {
      yourApiKey: 'Cheia API a Ta',
    }
  },
  details: {
    name: 'Nume',
    emailId: 'Adresă de Email ',
    yourPlan: 'Planul Tău',
    messagesPerMonth: ' mesaje/lună',
    daysOfStoragePlan: ' zile de plan de stocare',
    conditionalFeatures: 'funcții condiționale',
    upgradeToEnterprisePlanToGetMore: 'Upgradează la planul Enterprise pentru mai mult',
    featuresAndStorageCapacity: 'caracteristici și capacitate de stocare',
    upgradePlan: 'Upgradează Planul',
    submit: 'Trimite',
    selectLanguage: 'Selectează Limba',
    baby: 'Bebeluș',
    king: 'Rege',
    conversationsperMonth: 'conversații/lună',
    english: 'Engleză',
    hindi: 'Hindi',
    german: 'Germană',
    french: 'Franceză',
    portuguese: 'Portugheză',
    spanish: 'Spaniolă',
    italian: 'Italiană',
    russian: 'Rusă',
    chatBotBrandName: 'Numele ChatBot-ului',
    placeholders: {
      john: 'John',
      email: 'Email',
      searchLanguage: 'Caută Limba',
      chatBotBrandName: 'Numele ChatBot-ului'
    },
    appTooltip: {
      TOOLTIP_NAME: 'Introdu Numele tău de profil',
      TOOLTIP_EMAIL_ADDRESS: 'Introdu Adresa ta de Email',
    }
  },
  customAttribute: {
    addAttribute: 'Adaugă Atribut',
    discard: 'Renunță',
    customAttribute: 'Atribute Personalizate',
    checkOrAddCustomAttribute: 'Aici puteți verifica și adăuga atributele personalizate.',
    attributeKey: 'Cheie Atribut',
    attributeName: 'Nume Atribut',
    bot: 'Robot',
    flow: 'Flux',
    loadMore: 'Încarcă Mai Mult',
    question: 'Întrebare',
    action: 'Acțiune',
    search: 'Caută',
    selectBot: 'Selectează Robot',
    selectFlow: 'Selectează Flux',
    selectQuestion: 'Selectează Întrebare',
    noDataFoundHeading: 'Nu aveți încă nicio atribut creată',
    noDataFoundSubheading: 'Toate tributurile dvs. vor apărea aici',
    deleteTitle: 'Șterge Atributul',
    deleteDescription: 'Sunteți sigur că doriți să ștergeți acest atribut?',
    deleteAttributes: 'Șterge Atribute',
    areYouSureYouWantToDeleteThisAttributes: 'Ștergeți acest atribut?',
    editAttribute: 'Editează Atributul',
    headsUp: 'Atenție',
    okay: 'Ok',
    dataAddedToLead: 'Atributele mapeate către API-urile sau fluxurile tale de bot nu vor fi actualizate. Te rog actualizează API-urile sau fluxurile de bot în consecință.',
    placeholders: {
      enterAttributeKey: 'Introdu Cheia Atributului',
      enterAttributeName: 'Introdu Numele Atributului',
      selectBot: 'Selectează Robot',
      selectFlow: 'Selectează Flux',
      selectQuestion: 'Selectează Întrebare',
      search: 'Caută după numele atributului'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Introdu Cheia Atributului',
      TOOLTIP_ATTRIBUTE_NAME: 'Introdu Numele Atributului',
      TOOLTIP_SELECT_BOT: 'Selectează Robot',
      TOOLTIP_SELECT_FLOW: 'Selectează Flux',
      TOOLTIP_SELECT_QUESTION: 'Selectează Întrebare',
    }
  },
  password: {
    changePassword: 'Schimbă Parola',
    aSecurePasswordHelpsProtectYour: 'O parolă sigură ajută la protejarea ',
    account: ' Contului tău',
    currentPassword: 'Parola Curentă',
    newPassword: 'Parolă Nouă',
    confirmPassword: 'Confirmă Parola',
    cancel: 'Anulează',
    updatePassword: 'Actualizează Parola',
    deleteAccount: 'Șterge Contul',
    placeholders: {
      enterYourCurrentPassword: 'Introdu Parola Curentă',
      enterYourNewPassword: 'Introdu Parola Nouă',
      enterYourConfirmPassword: 'Introdu Parola Nouă din nou',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Introdu parola veche',
      TOOLTIP_NEW_PASSWORD: 'Introdu parola nouă',
      TOOLTIP_CONFIRM_PASSWORD: 'Confirmă noua ta parolă',
      TOOLTIP_DELETE_ACCOUNT: `Șterge-ți contul ${brand} curent`,
    }
  },
  tags: {
    manageTags: 'Gestionați Etichetele',
    createNewTags: 'Creați Etichete Noi',
    createNewTagsSubText: 'Gestionați-vă etichetele aici pentru a le utiliza pentru a organiza mai bine lead-urile, discuțiile, grupurile, etc.',
    tagName: 'Numele Etichetei',
    updateTag: 'Actualizați Eticheta',
    createTag: 'Creați Eticheta',
    discard: 'Renunțați',
    searchTag: 'Căutați Eticheta după Nume',
    noTagFound: 'Nicio Etichetă Găsită!',
    createATag: 'Creați o Etichetă',
    deleteTagModalSubText: 'Sunteți sigur că doriți să ștergeți această etichetă? Ștergerea acestei etichete va elimina eticheta și de la utilizatorii asignați!',
    deleteTagModalHeading: 'Ștergeți Etichetele',
    deleteOneTagModalHeading: 'Ștergeți Eticheta',
    actions: 'Acțiuni',
    placeholders: {
      enterTag: 'Introduceți Numele Etichetei',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'Ștergeți Etichetele',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'Nume',
      status: 'Status',
      action: 'Acțiune',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird pentru trimiterea mesajelor și emailurilor',
      notConnected: 'Neconectat',
      connected: 'Conectat',
      edit: 'Editează',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo pentru trimiterea mesajelor',
      connect: 'Conectează',
      appTooltip: {
        nameOfAppOrCrm: 'Numele Aplicației/CRM-ului',
        statusAsConnectedOrNotConnected: 'Status ca conectat / neconectat',
        clickToConnectAppOrCrm: 'Apasă pentru a conecta Aplicația/CRM-ul',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Oops! Integrările nu sunt disponibile în planul Baby. Te rugăm să upgradezi la planul King pentru integrări.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'Integrează cu MessageBird',
        apiKey: 'Cheie API',
        originator: 'Origine',
        connect: 'Conectează',
        disconnect: 'Deconectează',
        seeActivities: 'Vezi Activități',
        sms: 'SMS',
        time: 'Timp',
        phoneNumber: 'Număr de Telefon',
        status: 'Status',
        botName: 'Nume Bot',
        message: 'Mesaj',
        error: 'Eroare',
        placeholders: {
          apiKey: 'Cheie API',
          messageBirdPhoneNumber: 'Număr de Telefon MessageBird',
        },
        noDataFound: {
          activities: 'Activități',
          noActivitiesFound: 'Nu s-au găsit activități!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Integrează cu Plivo',
        authId: 'ID Autentificare',
        authToken: 'Token Autentificare',
        yourPlivoPhoneNumber: 'Numărul tău de Telefon Plivo',
        connect: 'Conectează',
        disconnect: 'Deconectează',
        seeActivities: 'Vezi Activități',
        sms: 'SMS',
        time: 'Timp',
        phoneNumber: 'Număr de Telefon',
        status: 'Status',
        botName: 'Nume Bot',
        message: 'Mesaj',
        error: 'Eroare',
        noDataFound: {
          activities: 'Activități',
          noActivitiesFound: 'Nu s-au găsit activități!',
        },
        placeholders: {
          authId: 'ID Autentificare',
          authToken: 'Token Autentificare',
          yourPlivoPhoneNumber: 'Numărul tău de Telefon Plivo',
        }
      }
    }
  }
};
