import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { SharedService } from '../modules/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  accessToken: any;
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private store: Store<any>) {
    this.store.select(state => state.tokens)
      .subscribe(tokens => {
        this.accessToken = tokens.accessToken;
      });
  }

  httpRequest(
    url: string,
    body: any = null,
    method: string = 'GET',
    authenticated: boolean = true,
    headers = {},
    tokenKey = 'accessToken'
  ) {
    return new Promise((resolve, reject) => {
      fetch(environment.baseUrl + url, {
        method,
        headers: JSON.parse(JSON.stringify({
          'Content-Type': 'application/json',
          Credentials: 'include',
          Authorization: authenticated
            ? `Bearer ${this.accessToken}`
            : undefined,
          ...headers,
        })),
        body: body
          ? JSON.stringify(body)
          : undefined
      })
        .then((response: any) => {
          return response.json();
        })
        .then((response: any) => {
          if (response.code === 401) {
            reject(response.data);
            this.redirectToLogin();
          } else if (response.code === 403) {
            if (response.message.toLowerCase().match(/inactive/gi)) {
              localStorage.removeItem('token');
              localStorage.removeItem('impersonate');

              reject(response.data);
              this.router.navigate(['/confirm-email'], { queryParams: { id: response.data._id } })
                .then();
            } else if (response.message.toLowerCase().match(/you don\'t have permission to access this resource/gi)) {
              console.log('You don\'t have permission to access this resource. Page url', window.location.href);
              // this.sharedService.showToast('error', '', 'You don\'t have permission to access this resource');
              reject(response.data);
            } else {
              // Handling 403 in UI
              // this.sharedService.showToast('error', '', response.message);
              // setTimeout(() => {
              //   this.redirectToLogin();
              // }, 1000);
              reject(undefined);
            }
          } else {
            if (!response.success) {
              return reject(response.data || response.message);
            }
            return resolve(response.data);
          }
        })
        .catch(error => {
          this.sharedService.showToast('error', '', error.message);
        });
    });
  }

  httpFormRequest(url: string, body: any = null, method: string = 'POST', authenticated: boolean = true) {
    return new Promise((resolve, reject) => {
      fetch(environment.baseUrl + url, {
        method,
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: authenticated
            ? `Bearer ${localStorage.getItem('token')}`
            : undefined
        },
        body: body
          ? body
          : undefined
      })
        .then((response: any) => {
          return response.json();
        })
        .then((response: any) => {
          // if (!(response.status)) {
          //   this.sharedService.showToast('success', '', response.message);
          // }

          return resolve(response.data);
        })
        .catch(error => {
          this.sharedService.showToast('error', '', error || error.message);
        });
    });
  }

  redirectToLogin(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('impersonate');
    window.location.href = '/authentication';
  }

}
