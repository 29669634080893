import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ChatgptService {

  constructor(
    public networkService: NetworkService
  ) { }

  public sendChatgptEnquiry(payload) {
    return this.networkService.httpRequest(`notification/chatgpt-enquiry`, payload, 'POST');
  }

  public checkPaymentStatus() {
    return this.networkService.httpRequest(`payment/chatGPT`, undefined, 'GET');
  }

  customerSupportFlow(brandName: string) {
    return [
      {
        label: `Hey, I am conversational chatbot trained using Generative AI. How can I help you today?`,
        icon: '',
        source: '',
        example: '',
        required: false,
        skip: false,
        skipIfAnswered: false,
        sendNotifications: true,
        back: false,
        message: {
          configureDomains: {
            domainAction: 'exclude',
            selectedDomainsList: [],
            domainErrorMessage: 'This domain is not Acceptable'
          },
          error: '',
          success: '',
          unavailable: ''
        },
        minRange: 0,
        maxRange: 10,
        rangePrefix: 'From',
        showTime: false,
        delay: 1,
        rangeSuffix: 'To',
        rangeStep: 1,
        includeInLeads: false,
        validation: false,
        next: {},
        id: uuid(),
        type: 'AI',
        name: 'AI',
        options: [],
        queriesToHandle: 500000,
        links: []
      }
    ];
  }
}
