import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/appv2/services/common.service';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})

export class NewPasswordComponent implements OnInit {
  staticText: any;
  signUpData: any = {
    _agency: environment.rootAgencyId,
    username: localStorage.getItem('last-email'),
    remember: false,
    number: localStorage.getItem('bp-mob'),
    prefix: localStorage.getItem('bp-prefix')
  };
  code: string;
  showDualPassword = false;
  showPassword = false;
  showcPassword = false;
  meta: any;
  referral: any = {};
  loginPassword: any = {
    password: '',
    cpassword: ''
  };
  agencyMeta: {
    _id: string;
    name: string;
    logo: string;
  };

  constructor(
    private store: Store<any>,
    private cos: CommonService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().authentication.newPassword;
    this.loginPassword = new FormGroup({
      password: new FormControl(''),
      cpassword: new FormControl('')
    });
    this.activeRoute.queryParamMap.subscribe(param => {
      // if (!param.get('code')) {
      //   // window.location.href = '/authentication';
      // } else {
      this.code = param.get('code');
      // }
    });

    this.checkAgency();
  }

  ngOnInit(): void {
  }

  get ifRootAgency(): boolean {
    return environment.rootAgencyDomain.includes(window.location.host);
  }

  checkAgency() {

    if (environment.rootAgencyDomain.includes(window.location.host)) {

      this.agencyMeta = {
        _id: environment.rootAgencyId,
        name: 'BotPenguin',
        logo: '/assets/images/logo-v2.svg'
      };
      return true;
    } else {
      this.sharedService.loader('start');
      this.authService.getAgencyInfo(window.location.host)
        .then(data => {

          this.agencyMeta = {
            _id: data._id,
            name: data.name,
            logo: data.meta.logo
          };
        })
        .catch(error => console.log(error))
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  signUp() {
    if (this.loginPassword.value.password !== this.loginPassword.value.cpassword) {
      return this.sharedService.showToast('error', '', 'Password and Confirm password not matched!');
    }

    this.meta = JSON.parse(localStorage.getItem('location')) || {};
    this.meta.offset = this.cos.getTimezoneOffset(this.meta.timeZone);
    if (!this.loginPassword.value.password) {
      this.sharedService.showToast('error', '', 'Password required');

    } else if (this.loginPassword.value.password.length < 6) {
      this.sharedService.showToast('error', '', 'Password must be the length of 6 or greater');
    } else {
      this.signUpData = {
        ...this.signUpData,
        password: this.loginPassword.value.password,
        cpassword: this.loginPassword.value.cpassword
      };
      this.sharedService.loader('start');
      this.authService.loginUser({
        ...this.signUpData,
        meta: this.meta,
        username: this.signUpData.username.toLowerCase().trim(),
        referral: this.referral
      })
        .then((response: any) => {
          if (response.access_token) {
            localStorage.setItem('token', response.access_token);
            window.location.reload();
          } else if (response.status === 'INACTIVE') {
            return this.router.navigate(['/confirm-email'], { queryParams: { id: response._parent } });
          }
        })
        .catch(err => this.sharedService.showToast('error', '', err))
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  backToSignup() {
    return this.router.navigate(['/authentication']);
  }


  submitPassword() {

    if (!this.loginPassword.value.password || !this.loginPassword.value.cpassword) {
      this.sharedService.showToast('error', '', 'Password required');
    } else if (this.loginPassword.value.password.length < 6 || this.loginPassword.value.cpassword < 6) {
      this.sharedService.showToast('error', '', 'Password length must be greater then 6');
    } else {
      if (this.loginPassword.value.password === this.loginPassword.value.cpassword) {
        const data = {
          password: this.loginPassword.value.password
        };
        this.sharedService.loader('start');
        this.authService.resetPassword(this.code, data)
          .then((res: any) => {
            this.sharedService.showToast('success', '', 'Password has been changed successfully');
            setTimeout(() => window.location.href = '/reset-password', 1000);
          })
          .catch(err => {
            this.sharedService.showToast('error', '', err.message || err);
          })
          .finally(() =>
            this.sharedService.loader('stop')
          );
      } else {
        this.sharedService.showToast('error', '', 'Password Doesn\'t Match');
      }
    }
  }
}
