import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { AuthenticationService } from 'src/appv2/services/authentication.service';
import { LanguageService } from 'src/appv2/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {

  staticText: any;
  userEmail: string;
  isEmailExist: string;
  interval: any;
  sent = false;
  resetBtnClicked = false;
  init: number = null;
  counter = 30;
  minutes: number;
  seconds: number;
  id: string;
  email: string;
  isActivationLinkSend = false;
  agencyMeta: {
    _id: string;
    name: string;
    logo: string;
  };
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().authentication.confirmEmail;
    this.checkAgency();
    this.authService.checkEmail((localStorage.getItem('email')))
      .then(async (exists: any) => {
        if (exists) {
          this.isEmailExist = 'EmailExist';
        } else {
          this.isEmailExist = 'EmailNotExist';
        }
      });
    this.userEmail = localStorage.getItem('email');
    this.email = localStorage.getItem('last-email');
  }



  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
    });
    if (this.id) {
      this.resend();
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem('email');
  }

  get ifRootAgency(): boolean {
    return environment.rootAgencyDomain.includes(window.location.host);
  }

  checkAgency() {

    if (environment.rootAgencyDomain.includes(window.location.host)) {

      this.agencyMeta = {
        _id: environment.rootAgencyId,
        name: 'BotPenguin',
        logo: '/assets/images/logo-v2.svg'
      };
      return true;
    } else {
      this.sharedService.loader('start');
      this.authService.getAgencyInfo(window.location.host)
        .then(data => {

          this.agencyMeta = {
            _id: data._id,
            name: data.name,
            logo: data.meta.logo
          };
        })
        .catch(error => console.log(error))
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  handleSignUpBtn() {
    return this.router.navigate(['/authentication']);
  }

  countDown() {
    this.interval = setInterval(() => {
      this.counter = this.counter - 1;

      if (this.counter === 0) {
        this.resetBtnClicked = false;
        this.counter = 30;
        clearInterval(this.interval);
        return;
      }
    }, 1000);
  }

  forgotPasswordResend() {
    this.authService.forgetPassword({ email: this.userEmail, _agency: this.agencyMeta._id })
      .then((res: any) => {
        this.resetBtnClicked = true;
        this.sharedService.showToast('success', '', `Email has been sent to your registered Email`);
      })
      .catch(err => {
        this.sharedService.showToast('error', '', err.message);
      });
  }

  resend() {
    // this.sharedService.loader('start');
    this.authService.resendEmail(this.id)
      .then(data => {
        clearTimeout(this.interval);
        this.resetBtnClicked = true;
        this.sharedService.showToast('success', '', 'Email sent!, please check your email and activate your account');
        this.countDown();
        return;
      })
      .catch(error => {
        return this.sharedService.showToast('error', '', error);
      });
    // .finally(() => this.sharedService.loader('stop'));
  }
}
