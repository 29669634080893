const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'ערוך את זרימת השיחה שלך',
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    classicBuilder: 'בונה קלאסי',
    visualiseFlow: 'חזותי זרימה',
    testLiveOnTelegramWeb: 'בדוק באופן חי באתר טלגרם',
    testFlow: 'בדוק את הזרימה',
    importTemplate: 'ייבא תבנית',
    landingPageBotReview: 'סקירת בוט של עמוד הנחיתה',
    install: 'התקן',
    testLiveOnMessengerWeb: 'בדוק באופן חי באתר מסנג\'ר',
    testLiveOnWhatsappWeb: 'בדוק באופן חי באתר וואטסאפ',
    testLiveOnInstagramWeb: 'בדוק באופן חי באתר אינסטגרם',
    seeAllChatFlow: 'ראה את כל זרימות השיחה',
    launchDemoModal: 'הפעל מודל הדגמה',
    selectATemplateToKickStartYourBot: 'בחר תבנית כדי להתחיל את הבוט שלך',
    hireChatBotDesigner: 'שכור מעצב בוט צ\'אט',
    recommendedForYou: 'מומלץ עבורך',
    useTemplate: 'השתמש בתבנית',
    exportStatus: 'מצב יצוא',
    flowExport: 'ייצוא זרימה',
    download: 'הורד',
    importFlow: 'ייבא זרימה',
    beforeYouStartUploadPleaseMakeSure: 'לפני שתתחיל, אנא הקפד לוודא ש:',
    ponit1: '1. הגודל המרבי להעלאת קובץ הוא 20 מ״ב',
    point2: '2. הפורמט של הקובץ יהיה JSON',
    selectFile: 'בחר קובץ',
    preview: 'תצוגה מקדימה',
    test: 'מבחן',
    OopsIntegrationAreNotAvailable: 'אופס! השתלות לא זמינות ב-',
    pleaseUpgradeTo: ' תוכנית. אנא שדרג אל ',
    planForIntegration: ' תוכנית לשתלות.',
    placeholders: {
      searchTemplateYouNeed: 'חפש את התבנית שאתה צריך',
    },
    noDataFound: {
      botFlow: 'זרימת הבוט',
      noBotFlowAddedYet: 'עדיין לא הוספת זרימת בוט!',
      templates: 'תבניות',
      noTemplatesFound: 'לא נמצאו תבניות!',
    },
    appTooltip: {
      exportFlow: 'ייצוא זרימה',
      importFlow: 'ייבוא זרימה',
      clickToViewChatFlow: 'לחץ לצפיה בזרימת השיחה בבונה גרירה ושחרור',
      clickToPreviewChatFlow: 'לחץ לתצוגה מקדימה של זרימת השיחה',
      clickToViewChatFlowAsFlowDiagram: 'לחץ לתצוגה של זרימת השיחה כתרשים',
      clickToTestChatFlow: 'לחץ לבדיקת הזרימה של השיחה',
      clickToSelectAndImportTemplate: 'לחץ לבחירה וייבוא של התבנית המתאימה ביותר לעסק שלך.',
      clickToInstallYourBotToSelectedPlatform: 'לחץ להתקנת הבוט שלך על הפלטפורמה שנבחרה',
      clickToTestDefaultFlowOfTelegramBot: 'לחץ לבדיקת הזרימה הברירת מחדל (פעילה) של הבוט שלך באתר טלגרם',
      clickToTestDefaultFlowOfFacebookBot: 'לחץ לבדיקת הזרימה הברירת מחדל (פעילה) של הבוט שלך באתר פייסבוק',
      clickToTestDefaultFlowOfWhatsappBot: 'לחץ לבדיקת הזרימה הברירת מחדל (פעילה) של הבוט שלך באתר וואטסאפ',
      clickToTestDefaultFlowOfInstagramBot: 'לחץ לבדיקת הזרימה הברירת מחדל (פעילה) של הבוט שלך באתר אינסטגרם',
    }
  },
  createNewBot: {
    createNewBot: 'צור בוט חדש',
    stepOneOfTwo: 'שלב 1 מתוך 2',
    selectThePlatform: 'בחר את הפלטפורמה',
    selectTheBotType: 'בחר את סוג הבוט',
    aiBasedChatbot: 'בוט דיבור מבינה מלאכותית',
    ruleBasedChatBot: 'בוט דיבור מבוסס חוקים',
    webPlatforms: 'פלטפורמות אינטרנט',
    websiteChatBot: 'בוט דיבור לאתר',
    withLiveChat: '(עם צ\'אט חי)',
    landingPageBot: 'בוט לדף הנחיתה',
    liveChatWidget: 'ווידג\'ט צ\'אט חי',
    onlyLiveChat: '(רק צ\'אט חי)',
    socialPlatforms: 'פלטפורמות חברתיות',
    whatsappChatBot: 'בוט דיבור לוואטסאפ',
    telegramChatBot: 'בוט דיבור לטלגרם',
    facebookChatBot: 'בוט דיבור לפייסבוק',
    instagramChatBot: 'בוט דיבור לאינסטגרם',
    selectBotType: 'בחר סוג בוט',
    selectProblem: 'בחר את הבעיה שברצונך לפתור',
    leadGeneration: 'יצירת לידים',
    customerSupport: 'שירות לקוחות',
    appointmentBooking: 'תיאום פגישות',
    marketingAutomation: 'אוטומציה בשיווק',
    ecommerceWebsite: 'אתר מסחר אלקטרוני',
    otherUseCase: 'שימושים אחרים',
    back: 'חזור',
    typeDomainUrl: 'הזן את כתובת האתר שלך לצורך אימות.',
    dataSourceForChatBotTraining: 'מקור נתונים לאימון הבוט',
    upload: 'העלה',
    uploadDocuments: 'העלה מסמכים',
    uploadDocument: 'העלה מסמך',
    selectFile: 'בחר קובץ',
    continue: 'המשך',
    websiteUrl: 'הזן את כתובת האתר שלך',
    skipNow: 'דלג כעת',
    completeTheExistingBotSetup: 'השלם את הגדרות הבוט הקיים',
    youHaveAlreadyCreatedOneWhatsAppBot: 'כבר יצרת בוט של WhatsApp אחד, אנא השלם את ההגדרות שלו כדי ליצור אחד חדש.',
    completeSetup: 'השלם את ההגדרות',
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    fbBot: {
      createNewBot: 'צור בוט חדש',
      help: 'עזרה',
      back: 'חזור',
      step: 'שלב ',
      of3: ' מתוך 3',
      loginWithFacebook: 'התחבר עם פייסבוק',
      asPerLatestChangesInFacebookPolicy: 'על פי שינויים אחרונים במדיניות הפייסבוק, בעלי הבוטים בפייסבוק חייבים להיות בעלי הרשאה "מנהל" או מעלה כדי לגשת לכל התכונות של הדף בפייסבוק.',
      login: 'התחבר',
      connectFacebookBot: 'חבר בוט לפייסבוק',
      connectBotWithYourFacebookPagesAnd: 'חבר את הבוט לדפי הפייסבוק שלך ו',
      exploreTruePowerOfFacebook: ' גלה את היכולות האמיתיות של פייסבוק',
      connect: 'חבר',
      connected: 'מחובר',
      createNewPage: 'צור דף חדש',
      refreshPageList: 'רענן את רשימת הדפים',
      thisPageIsSuccessfullyIntegratedWith: 'הדף הוא משולב בהצלחה עם ',
      yourMessagesFromMessengerWillAppearIn: '. הודעות מהמסנג\'ר יופיעו בתיבת הדואר הנכנס שלך',
      inbox: 'תיק דואר נכנס',
      continueToBotConfiguration: 'המשך לתצורת הבוט',
      welcomeToBrandNameWeAreHereToHelpYou: `ברוך הבא אל ${brand}. אנחנו כאן כדי לעזור.`,
      letsTryYourBotAndSeeHowItWorks: `בואו ננסה את הבוט שלך ונראה איך הוא פועל! לחץ "בדוק על פייסבוק".`,
      howAreYouDoingToday: 'איך אתה מרגיש היום?',
      thisIsHowSimpleToUseFacebookBot: `זהו! זה כמה פשוט להשתמש בבוט של פייסבוק`,
      letsCreateYourOwnChatFlowNow: `בוא ניצור את הזרימת הצ\'אט שלך כעת`,
    },
    instagramBot: {
      createNewBot: 'צור בוט חדש',
      back: 'חזור',
      step: 'שלב ',
      of3: ' מתוך 3',
      loginWithInstagram: 'התחבר עם אינסטגרם',
      loginToYourInstagramAccount: 'התחבר לחשבון האינסטגרם שלך כדי להפעיל את הבוט בחשבונך.',
      managerLevelDescription: 'בעלי הבוט שבאינסטגרם נדרשים לסיים לפחות רמת "מנהל" או יותר גבוהה כדי לגשת לכל התכונות.',
      login: 'התחבר',
      connectInstagramBot: 'חבר בוט לאינסטגרם',
      connectBotWithYourInstagramAnd: 'חבר את הבוט לחשבון האינסטגרם שלך ו',
      exploreTruePowerOfInstagram: ' גלה את היכולות האמיתיות של אינסטגרם',
      connected: 'מחובר',
      connect: 'חבר',
      createNewPage: 'צור דף חדש',
      refreshPageList: 'רענן את רשימת הדפים',
      thisPageIsSuccessfullyintegratedWith: 'הדף הוא משולב בהצלחה עם ',
      yourMessagesFromMessengerWillAppearIn: '. הודעות מהמסנג\'ר יופיעו בתיק הדואר הנכנס שלך',
      inbox: 'תיק דואר נכנס',
      continueToBotConfiguration: 'המשך לתצורת הבוט',
      welcomeToBrandNameWeAreHereToHelpYou: `ברוך הבא אל ${brand}. אנחנו כאן כדי לעזור.`,
      letsTryYourBotAndSeeHowItWorks: `בואו ננסה את הבוט שלך ונראה איך הוא פועל! לחץ "בדוק על אינסטגרם".`,
      howAreYouDoingToday: 'איך אתה מרגיש היום?',
      thisIsHowSimpleToUseInstagramBot: `זהו! זה כמה פשוט להשתמש בבוט של אינסטגרם`,
      letsCreateYourOwnChatFlowNow: `בוא ניצור את הזרימת הצ'אט שלך כעת`,
    },

    telegramBot: {
      back: 'חזרה',
      step: 'שלב ',
      of3: ' מתוך 3',
      seeHowToUse: 'ראה איך להשתמש',
      help: 'עזרה',
      loginToYourTelegramAccount: 'התחבר לחשבון הטלגרם שלך',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'התחבר לחשבון שלך כדי ליצור בוט חדש או לחבר בוט קיים',
      verificationCodeSentOnRegisteredNumber: 'קוד אימות נשלח למספר הרשום',
      createYourBot: 'צור את הבוט שלך',
      enterYourBotsNameAnd: 'הזן את שם הבוט שלך, התחבר והתחל את הכיף עם ',
      botUserNameMustEndWithBotAtTheEnd: 'שם המשתמש של הבוט חייב לסיים ב-"bot" בסופו.',
      terrisBot: 'לדוגמה: teris_bot או terisbot',
      botNameAlreadyTaken: 'שם הבוט הזה כבר תפוס. אנא נסה משהו אחר',
      connect: 'חבר',
      createBot: 'צור בוט',
      codeNotReceivedResend: 'לא קיבלת את הקוד? שלח שוב!',
      changeNumber: 'שנה מספר',
      telegramPreview: 'תצוגה מקדימה בטלגרם',
      worldsFastestMessagingApp: 'האפליקציה לשליחת הודעות הכי מהירה בעולם והיא גם חינם!',
      bot: 'בוט',
      phoneNumber: 'מספר טלפון',
      userName: 'שם משתמש',
      none: 'אין',
      bio: 'ביוגרפיה',
      fewWordsAboutYourself: 'מספר מילים על עצמך',
      settings: 'הגדרות',
      notificationsAndSound: 'התראות וצלילים',
      privacyAndSecurity: 'פרטיות ואבטחה',
      dataAndStorage: 'נתונים ואחסון',
      chatSettings: 'הגדרות צ\'אט',
      chatFolders: 'תיקי צ\'אט',
      devices: 'התקנים',
      connectYourBot: 'חבר את הבוט שלך',
      createNewBotOrConnectWithExistingOne: 'צור בוט חדש או חבר את הבוט הקיים',
      createNewBot: 'צור בוט חדש',
      welcomeToBrandNameWeAreHereToHelpYou: `ברוך הבא אל ${brand}. אנחנו כאן כדי לעזור לך.`,
      letsTryYourBotAndSeeHowItWorks: `בואו ננסה את הבוט שלך ונראה איך זה עובד! לחץ על "בדוק בטלגרם".`,
      howAreYouDoingToday: 'איך אתה מרגיש היום?',
      thisIsHowSimpleToUseTelegramBot: `זהו! זה פשוט כל כך להשתמש בבוט של טלגרם`,
      letsCreateYourOwnChatFlowNow: `בוא ניצור את הזרימת הצ'אט שלך כעת`,
      placeholders: {
        searchCountry: 'חפש מדינה',
        enterTelegramMobileNumber: 'הזן מספר טלפון של טלגרם',
        enterLoginCodeReceived: 'הזן את קוד ההתחברות שהתקבל בחשבון הטלגרם *',
        twoFactorAuthentication: 'סיסמת 2FA (אופציונלית)',
        botName: 'שם הבוט *',
        botUserName: 'שם משתמש של הבוט *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'צור בוט חדש',
      seeHowToUse: 'ראה איך להשתמש',
      help: 'עזרה',
      back: 'חזרה',
      step: 'שלב ',
      of2: ' מתוך 2',
      buildA: 'צור ',
      thatMatchesYourBrandPersonality: ` שמתאים לאישיות המותג שלך`,
      chatBotIcon: 'צלמית הבוט',
      optional: 'אופציונלי',
      themeColor: 'צבע הערכת הבוט',
      chatBotName: 'שם הבוט',
      botNameIsRequired: 'שם הבוט הוא שדה חובה',
      botNameShouldBeLessThan50Characters: 'שם הבוט חייב להיות באורך של פחות מ-50 תווים',
      continue: 'המשך',
      welcomeToBrandNameWeAreHereToHelpYou: `ברוך הבא אל ${brand}. אנחנו כאן כדי לעזור.`,
      whatIsYourEmailAddress: 'מהו כתובת האימייל שלך?',
      invalidEmailAddress: 'כתובת האימייל אינה חוקית',
      customizeYourLandingPageBotLook: 'התאם אישית את המראה של בוט דף הנחיתה שלך',
      customizeYourWidgetLook: 'התאם אישית את המראה של הווידג\'ט שלך',
      customizeYourBotLook: 'התאם אישית את המראה של הבוט שלך',
      buildYourLandingPageBotAndAutomateInteraction: 'בנה את בוט דף הנחיתה שלך ואוטומטית יצור אינטראקציה עם לקוחותיך על ידי שיתוף הקישור שלו',
      useLiveChatBotToConnectToCustomers: 'השתמש בבוט צ\'אט חי כדי להתחבר ללקוחותיך בזמן אמת',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'חבר את בוט הצ\'אט לאתר האינטרנט שלך ואוטומט ריאקציות עם מבקרים',
      widget: 'ווידג\'ט',
      botInterface: 'ממשק הבוט',
      mergedBot: 'בוט דף האינטרנט, בוט דף הנחיתה, בוט לאפליקצייה ניידת',
      mergedBotToolTip: 'צור דף בוט כדי לתפוס את תשומת הלב של המבקרים ולהתעסק איתם בשיחות.',
      placeholders: {
        typeANameForYourBot: 'הקלד שם לבוט החדש שלך',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'הזן את שם הבוט שלך',
        mergedBotToolTip: 'צור דף בוט כדי לתפוס את תשומת הלב של המבקרים ולהתעסק איתם בשיחות.',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'בחר צבע ערכת הבוט שלך',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `לוגו זה ישמש רק בפלטפורמת ${brand ? brand : ''}. אנו ממליצים לבחור את לוגו החברה שלך. הוסף תמונת PNG או JPG מרובעת במשקל של פחות מ-1 MB.`
      }
    },
    whatsappBot: {
      back: 'חזרה',
      justAStepAway: 'רק צעד אחד הרחק',
      seeHowToUse: 'ראה איך להשתמש',
      help: 'עזרה',
      createWhatsappChatBot: 'צור בוט WhatsApp',
      businessNameIsRequired: 'שם העסק הוא שדה חובה.',
      maximumLengthForBusinessName40Characters: 'האורך המרבי לשם העסק הוא 40 תווים.',
      businessCategory: 'קטגורית העסק',
      categoryIsRequired: 'הקְבוּצָה היא שדה חובה.',
      descriptionIsRequired: 'התיאור הוא שדה חובה.',
      maximumLengthForDescription200Characters: 'האורך המרבי לתיאור הוא 200 תווים.',
      pleaseFillAValidEmailAddress: 'אנא הזן כתובת דוא"ל תקנית.',
      businessEmailIsRequired: 'הדוא"ל של העסק הוא שדה חובה.',
      phoneNumberShouldHaveMinimum7Digits: 'מספר הטלפון צריך לכלול מינימום 7 ספרות',
      phoneNumberIsRequired: 'מספר הטלפון הוא שדה חובה',
      websiteIsRequired: 'האתר הוא שדה חובה.',
      maximumLengthForWebsite40Characters: 'האורך המרבי לאתר הוא 40 תווים.',
      maximumLengthForAddress150Characters: 'האורך המרבי לכתובת הוא 150 תווים.',
      doYouHaveWhatsappApiKey: 'האם יש לך מפתח API של WhatsApp?',
      apiProvider: 'ספק API',
      three60Dialog: '360 דיאלוג',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'המפתח API הוא שדה חובה.',
      whatsappBusinessAccountIdIsRequired: 'מזהה חשבון העסק ב-WhatsApp הוא שדה חובה.',
      whatsappPhoneNumberIdIsRequired: 'מזהה מספר הטלפון של WhatsApp הוא שדה חובה.',
      systemAccessTokenIsRequired: 'מזהה גישה למערכת הוא שדה חובה.',
      sourceIdIsRequired: 'מזהה מקור הוא שדה חובה.',
      businessName: 'שם העסק',
      businessAccount: 'חשבון העסק',
      businessDescription: 'תיאור העסק',
      address: 'כתובת',
      businessEmail: 'דוא"ל העסק',
      websiteUrl: 'כתובת האתר',
      thankYouForYourInterestIn: 'תודה על העניין שלך ב-',
      whatsappChatBot: ' בוט WhatsApp.',
      weHaveReceivedYourInterests: 'קיבלנו את העניין שלך, צוות המכירות שלנו ייצור איתך קשר בעוד 24 שעות.',
      theyWillProvideYouWhatsappApiKey: 'הם יספקו לך את מפתח ה-API של WhatsApp.',
      readyToCreateWhatsappBot: 'אני מוכן ליצור בוט WhatsApp',
      exploreWhatsappFeatures: 'חקור את יכולות WhatsApp',
      welcomeToBrandNameWeAreHereToHelpYou: `ברוך הבא ל-${brand}. אנחנו כאן כדי לעזור לך.`,
      letsTryYourBotAndSeeHowItWorks: `בוא ננסה את הבוט שלך ונראה איך זה עובד! לחץ על "בדוק ב-WhatsApp".`,
      howAreYouDoingToday: 'איך אתה מרגיש היום?',
      thisIsHowSimpleToUseWhatsappBot: 'זהו! כל כך פשוט להשתמש בבוט WhatsApp',
      letsCreateYourOwnChatFlowNow: 'בוא ניצור את הזרימת השיח שלך עכשיו',
      connectWhatsAppBot: 'התחבר לבוט WhatsApp',
      createtWhatsAppBot: 'צור בוט WhatsApp',
      proceed: 'המשך',
      whatsApiProvider: 'ספק API של WhatsApp',
      cloudApi: 'Cloud API',
      netcoreApi: 'Netcore API',
      placeholders: {
        yourBusinessName: 'שם העסק שלך',
        businessDescription: 'תיאור העסק',
        businessEmail: 'דוא"ל העסק',
        phone: 'טלפון*',
        website: 'אתר (לדוגמה www.example.com)',
        address: 'כתובת',
        enterApiKey: 'הזן מפתח API',
        enterWhatsappBusinessAccountId: 'הזן מזהה חשבון העסק ב-WhatsApp',
        enterWhatsappPhoneNumberId: 'הזן מזהה מספר הטלפון של WhatsApp',
        enterSystemAccessToken: 'הזן מזהה גישה למערכת',
        enterSourceId: 'הזן מזהה מקור',
        businessName: 'שם העסק שלך',
        businessWebsite: 'אתר העסק שלך',
        selectWhatsappApiProvider: 'בחר ספק API של WA',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'בחר את ספק ה-API של WhatsApp שלך',
        doYouHaveWhatsAppBusinessAPI: 'האם יש לך API של WhatsApp Business?',
        yes: 'כן',
        noIAmNotSure: 'לא, אני לא בטוח',
        selectApiProvider: 'בחר ספק API',
        whatsAppCloudAPI: 'API של WhatsApp Cloud',
        enterYourPhoneNumberId: 'הזן את מזהה מספר הטלפון שלך',
        phoneNumberId: 'מזהה מספר הטלפון',
        enterYourWhatsAppBusinessAccountId: 'הזן את מזהה חשבון העסק של WhatsApp שלך',
        whatsAppBusinessAccountId: 'מזהה חשבון העסק של WhatsApp',
        enterYourSystemUserToken: 'הזן את מזהה המשתמש שלך במערכת',
        systemUserToken: 'מזהה המשתמש במערכת',
        help: 'עזרה',
        netcoreAPI: 'API של Netcore',
        enterYourSourceId: 'הזן את מזהה המקור שלך',
        sourceId: 'מזהה המקור',
        enterYourNetcoreApiKey: 'הזן את מפתח ה-API של Netcore שלך',
        netcoreApiKey: 'מפתח ה-API של Netcore',
        submit: 'שלח',
        noneOftheAbove: 'אף אחד מהנ"ל',
        next: 'הבא'
      },

      stepTwo: {
        legallyRegisteredBusiness: 'עסק מורשה משפטית',
        validWebsiteWithDataPrivacyPolicy: 'אתר חוקי עם מדיניות פרטיות לנתונים',
        debit: 'כרטיס חיוב',
        creditCard: 'כרטיס אשראי',
        gstin: 'GSTIN',
        onlyRequiredForIndianResidents: 'נדרש רק לתושבי הודו',
        onlyRequiredFor: 'נדרש רק ל',
        indianResidents: 'תושבי הודו',
        validPaymentMethod: 'אמצעי תשלום חוקי',
        enabledWithInternationalPayments: 'מאופשר עם תשלומים בינלאומיים',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'מקרה שימוש חוקי בהתאם למדיניות המסחר של WhatsApp',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'מספר טלפון שאין לו WhatsApp פעיל ויכול לקבל SMS',
        discard: 'מחק',
        proceed: 'המשך',
        whatsAppBotPrerequisites: 'דרישות מוקדמות לבוט WhatsApp',
        letUsGetItDoneForYou: 'אנחנו נעשה זאת עבורך!',
        ifYouAreFacingSomeIssues: 'אם אתה נתקל בבעיות',
        ourTeamCanHelpYouDoTheSame: 'הצוות שלנו יכול לעזור לך לעשות אותו הדבר',
        requestACallbackNow: 'הזמן בקשה להתקשרות עכשיו!',
      },
      stepThree: {
        setupWhatsAppBot: 'הגדרת בוט WhatsApp',
        selectingTheBestBusinessManagerForYourNeeds: 'בחירת המנהל העסקי הטוב ביותר עבור צרכיך',
        createMetaApp: 'איך ליצור אפליקציית Meta',
        createaMetaAppOn: 'צור אפליקציית Meta ב-',
        howToCreate: 'איך ליצור',
        addWhatsAppNumber: 'כיצד להוסיף מספר WhatsApp',
        addYourWhatsAppNumberAndVerifyTheSame: 'הוסף את מספר ה-WhatsApp שלך ואמת אותו',
        subscribe: 'הירשם',
        copyAndSubscribeWebhook: 'העתק והירשם ל-Webhook',
        howToSubscribe: 'איך להירשם',
        copy: 'העתק',
        appSecretId: 'מזהה סודי של האפליקציה',
        enterYourAppSecretId: 'הזן את מזהה הסוד של האפליקציה שלך',
        enterAppSecret: 'הזן את מזהה הסוד',
        phoneNumberId: 'מזהה מספר הטלפון',
        whatsAppBusinessId: 'מזהה העסק ב-WhatsApp',
        assignSystemUser: 'כיצד להקצות משתמש מערכת',
        assign: 'הקצה',
        createApp: 'צור אפליקציה',
        registerAndVerify: 'הירשם ואמת',
        add: 'הוסף',
        systemUserToken: 'אסימון משתמש מערכת',
        enterYourSystemUserToken: 'הזן את אסימון המשתמש המערכת שלך',
        getAllNecessaryDetails: 'איך לקבל את כל הפרטים הדרושים',
        subscribeWhatsAppWebhook: 'איך להירשם ל-Webhook של WhatsApp',
        configureTheWebhookForWhatsAppAccount: 'הגדר את ה-Webhook לחשבון ה-WhatsApp שלך',
        upadatePaymentMethod: 'איך לעדכן את אמצעי התשלום',
        assignThePaymentMethodToWhatsAppAccount: 'הקצה את אמצעי התשלום לחשבון ה-WhatsApp שלך',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'הענק שליטה בחשבון העסקי של WhatsApp למשתמש מערכת',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'פרטי מפתח שצריך להעתיק מלוח המחוונים של Meta',
        updatePayment: 'עדכן את התשלום',
        letUsGetItDoneForYou: 'אנחנו נעשה זאת עבורך!',
        ifYouAreFacingSomeIssues: 'אם אתה נתקל בבעיות',
        ourTeamCanHelpYouDoTheSame: 'הצוות שלנו יכול לעזור לך לעשות אותו הדבר',
        requestACallbackNow: 'הזמן בקשה להתקשרות עכשיו!',
        update: 'עדכן',
        connectNow: 'התחבר כעת'
      },
      stepFive: {
        selectBusinessManager: 'בחר מנהל עסקי',
        selectingTheBestBusinessManagerForYourNeeds: 'בחירת המנהל העסקי הטוב ביותר עבור צרכיך'
      },

      stepSix: {
        yourBotIsInstalledAndActive: 'הבוט שלך מותקן ופעיל',
        youhaveSuccessfullyConnected: 'הצלחת להתחבר בהצלחה',
        withWhatsApp: 'עם WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'ניתן לך 100 שיחות לחודש כברירת מחדל',
        upgradeYourPlanForMore: 'שדרג את התוכנית שלך לקבל יותר.',
        createChatFlow: 'צור זרימת צ\'אט',
        upgradePlan: 'שדרג תוכנית'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'סרוק את ה-QR או שלח הודעה להפעלת הבוט',
        scanTheQrCodeFrom: 'סרוק את קוד ה-QR מ',
        yourPhoneWillOpen: `הטלפון שלך יפתח הודעה מראש לשליחה למספר ה-Sandbox שלנו. לחץ 'שלח' ב-WhatsApp`,
        sendMessageFromYourWhatsAppNumber: 'שלח הודעה ממספר ה-WhatsApp שלך',
        sendUsAWhatsAppMessageOn: 'שלח לנו הודעת WhatsApp אל',
        bySavingInYourContactsAndMessageShouldStartwith: 'על ידי שמירה באנשי הקשר שלך וההודעה צריכה להתחיל עם',
        send: 'שלח',
        or: 'או'
      }
    }
  },
  gettingStarted: {
    hello: 'שלום, ',
    upgrade: 'שדרג',
    gettingStarted: 'התחילו - ',
    yourFacebookBotToken: 'טוקן הפייסבוק של הבוט שלך ',
    hasBeenExpired: 'פג תוקף',
    isExpiringIn: 'מתבצע פג תוקף בעוד ',
    days: ' ימים',
    facebookAuthenticationTokenRemainsFor60Days: 'טוקן הפייסבוק נשמר בתוקף למשך 60 יום בלבד',
    kindlyProceedWithTheRenewalOfYourBotToken: 'אנא המשיכו לחדש את טוקן הבוט שלך כדי להבטיח גישה בלתי מוגנת לשירותי הצ\'אטבוט.',
    renewYourBotToken: 'חדש טוקן הבוט שלך',
    editYourChatFlow: 'ערוך את זרימת השיחה שלך',
    buildConversationEngagingBot: 'צור זרימות שיחה מעוררות עניין עבור משתמשי הבוט שלך.',
    takes5minutes: 'זה מתבצע ב-5 דקות',
    previewYourLiveChatWidget: 'תצפה בווידג\'ט הצ\'אט החי שלך',
    discoverHowThe: 'גלה איך ',
    liveChatFeatureWorks: 'תכונת הצ\'אט החיה עובדת באמצעות משחק הצ\'אט החי שלנו',
    takes2minutes: 'זה מתבצע ב-2 דקות',
    takes1minute: 'זה מתבצע בדקה אחת',
    connected: 'מחובר',
    customizeYourBotApperance: 'התאם אישית את המראה והפונקציות של הבוט שלך.',
    takes3minutes: 'זה מתבצע ב-3 דקות',
    thirdPartyAppsIntegration: 'אינטגרציה של אפליקציות צד שלישי',
    integrateYourBot: 'אינטגרציה של הבוט שלך עם אפליקציות / CRM אחרות כדי ליצור אוטומציה לעסקים.',
    upgradeYourPlanToReceiveLeadsInEmail: 'שדרג את התוכנית שלך כדי לקבל לידים בדוא"ל',
    getStartedWithUs: 'התחילו איתנו',
    howToBuildAGoodChatFlow: 'איך לבנות זרימת צ\'אט טובה',
    howToUseBotPenguinsInboxAndItsFeatures: 'איך להשתמש בתיבת הדוא"ל של BotPenguin ובתכונותיה',
    botPenguinsPricing: 'מחירון BotPenguin: חקר תוכניות ותכונות',
    openHelpDesk: 'פתח את תיק העזרה',
    helpAndSupport: 'עזרה ותמיכה',
    extensiveGuides: 'מדריכים מפורטים לכל שאלותיך וזרמיך זמינים במרכז המשאבים שלנו',
    resourceCentre: 'מרכז המשאבים',
    checkoutOurAnswers: 'ניתן לבדוק את התשובות שלנו לשאלות הנפוצות למטה.',
    createANewBot: 'צור בוט חדש',
    installYourBot: 'התקן את הבוט שלך',
    plansAndPricing: 'תוכניות ומחירים',
    previewYourLandingPageBot: 'תצפה בבוט של דף הנחיתה שלך',
    installYourLiveChatWidget: 'התקן את וידג\'ט הצ\'אט החי שלך',
    installYourWebsiteBot: 'התקן את הבוט שלך באתר האינטרנט, באפליקציה הניידת או השק כבוט נחיתה או בוט מוטבע',
    integrateBotToLandingPage: 'אינטגרציה של הבוט לבוט נחיתה שלך',
    installWidgetToWebsite: 'התקן את וידג\'ט הצ\'אט לאתר האינטרנט שלך',
    installBotToWebsite: 'התקן את הבוט שלך באתר האינטרנט שלך',
    whatsappAutomation: 'אוטומציה ב-WhatsApp',
    facebookAutomation: 'אוטומציה בפייסבוק',
    telegramBotSettings: 'הגדרות הבוט בטלגרם',
    liveChatWidgetSettings: 'הגדרות וידג\'ט הצ\'אט החי',
    landingPageBotSettings: 'הגדרות בוט דף הנחיתה',
    websiteChatBotSettings: 'הגדרות בוט האתר האינטרנטי',
    instagramAutomation: 'אוטומציה באינסטגרם',
    diveDeeperIntoBotPenguin: 'עקוב על מה שקורה ב-BotPenguin',
    knowWhereWeAreGoing: 'דע לאן אנחנו הולכים, העדכונים האחרונים ועזרו לנו לתקן חסרונות.',
    productUpdates: 'עדכוני מוצר',
    requestAFeature: 'בקשת תכונה',
    reportABug: 'דווח על באג',
    prodcutRoadmap: 'מפת דרכים של המוצר',
    trainYourChatbot: 'הכשר את בוט השיחה שלך באמצעות הבסיס נתונים',
    buildEngagingConversation: 'בנה זרימות שיחה מעוררות עניין למשתמשי הבוט שלך.',
    previewYourAIBot: 'תצפה בבוט המבוסס על הבינה המלאכותית שלך',
    integrateAIbot: 'אינטגרציה של הבוט לבוט הבינה המלאכותית שלך',
    aiBotSettings: 'הגדרות הבוט הבינה המלאכותית',
    CustomizeAIBot: 'התאם אישית את המראה והפונקציות של הבוט הבינה המלאכותית שלך.',
    gptKeyWarning: 'ברוכים הבאים לשירות ה-API של ChatGPT שלנו! נא לקחת בחשבון שיש הגבלה של 100 הודעות לכל בוט. לאחר שתגיעו להגבלת 100 ההודעות, תצטרכו להשתמש במפתח ה-API של ChatGPT שלכם כדי להמשיך ליהנות מהשירות שלנו. להזנת מפת',
    whatsappBotSettings: 'הגדרות הבוט ב-WhatsApp',
    automateCustomerEngagementWithWhatsApp: 'אוטומציה בעבודת הלקוחות עם WhatsApp.',
    connectToYourWhatsappNumber: 'התחבר למספר ה-WhatsApp שלך',
    connectYourWhatsappBotWithYourWhatsappNumber: 'התחבר את הבוט שלך ב-WhatsApp למספר ה-WhatsApp שלך',
    facebookBotSettings: 'הגדרות הבוט בפייסבוק',
    automateCustomerEngagementWithTelegram: 'אוטומציה של מעורר הלקוח באמצעות טלגרם',
    automateCustomerEngagementWithFacebook: 'אוטומציה של מעורר הלקוח באמצעות פייסבוק',
    automateCustomerEngagementWithInstagram: 'אוטומציה שלמעורר הלקוחבאמצעות אינסטגרם',
    telegramAutomation: 'אוטומציה ב-Telegram',
  },
  install: {
    previewYourLandingPageBot: 'תצפה בבוט של דף הנחיתה שלך',
    preview: 'תצפה',
    test: 'מבחן',
    squareSpace: 'Squarespace',
    installYourChatBot: 'התקן את הבוט שלך',
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    uniqueLinkToLandingPageBot: 'זהו הקישור היחודי לבוט דף הנחיתה שלך.',
    shareOnSocialMedia: 'פשוט שתפה עם המשתמשים שלך ברשתות החברתיות, בדפי הקשר או בכרטיסי ביקור.',
    or: 'או',
    copyUrl: 'העתק URL',
    selectYourWebsiteCms: 'בחר את מערכת ניהול התוכנית (CMS) שבה נבנה האתר שלך',
    selectCms: 'בחר את מערכת הניהול שהשתמשת בה ליצירת האתר שלך',
    custom: 'מותאם אישית',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'מערכת ניהול אחרת',
    howWouldYouLikeToInstall: 'איך תרצה להתקין את הבוט?',
    installingWebsiteBot: 'בחר איך תרצה להתקין את הבוט באתר האינטרנט שלך',
    iWillAddMyself: 'אני אוסיף בעצמי',
    getChatBotScript: 'קבל את קוד סקריפט הבוט למטה והתקן אותו לבד.',
    getWordpressPlugin: 'קבל מפתח API לתוסף WordPress',
    getOurOfficialPlugin: 'קבל את התוסף הרשמי שלנו ',
    fromWordpressPluginStore: ' מחנות התוספים של WordPress',
    getPlugin: 'קבל תוסף',
    quickGuideToInstall: 'מדריך מהיר להתקנה',
    placeApiKeyBelowIn: 'הדבק את מפתח ה-API למטה ב-',
    afterDownloadingAndActivatingPlugin: ' לאחר הורדת התוסף והפעלתו',
    copy: 'העתק',
    enterYourWebsite: 'הזן את כתובת האתר שלך ונראה איך הבוט שלך נראה',
    enterAValidUrl: 'הזן URL תקין',
    verifyYourInstallation: 'אמת את ההתקנה שלך',
    testInstallation: 'בדוק את ההתקנה',
    getChatBotScriptCode: 'קבל את קוד סקריפט הבוט',
    copyThisCodeSnippet: 'העתק את קטע הקוד הזה והדבק אותו ב-HTML של כל דף אינטרנט בו ברצונך להציג את הבוט או הדבק אותו בתחתית הדף לפני התגית הסוגרת כדי שהוא יופיע בכל דף.',
    sendCodeToMyDeveloper: 'שלח את הקוד למפתח שלי',
    sendCodeToMyDevelopmentTeam: 'שלח את הקוד לצוות הפיתוח שלי',
    sendEmail: 'שלח אימייל',
    enterAValidEmail: 'הזן כתובת אימייל תקינה',
    codeHasBeen: 'הקוד נשלח ',
    toYourDeveloper: 'למפתח שלך ',
    resent: 'שוב',
    sent: 'נשלח',
    resendEmail: 'שלח מחדש',
    ask: 'שאל את ',
    supportTeamToInstall: ' צוות התמיכה שלנו להתקנה',
    raiseTheInstallationRequestTo: 'שלח בקשת התקנה ל',
    team: ' צוות',
    helpMeInstallingChatBot: 'עזור לי להתקין את הבוט',
    yourBotInstallationIsNotActive: 'ההתקנה של הבוט שלך אינה פעילה!',
    monthlyMessageLimitExceeded: 'חרגת מהגבול החודשי של הודעות',
    exceededMonthlyMessageLimitUpgradeNow: 'חרגת מהגבול החודשי של הודעות, שדרג עכשיו.',
    upgradeNow: 'שדרג עכשיו',
    botInstallationNotActivePleaseFix: 'ההתקנה של הבוט שלך אינה פעילה, תוקן זאת בבקשה.',
    incorrectBotScript: 'הסקריפט של הבוט אינו נכון',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'קוד ההתקנה שאתה מאמת אינו תואם לאתר שלך. ' +
      'נא לוודא שהתקנת את הקוד הנכון עבור הבוט הזה.',
    unableToSee: 'לא ניתן לראות את ',
    codeOnYourWebsite: ' הקוד באתר שלך',
    unableToFindCodePleaseReinstall: 'לא ניתן למצוא את הקוד באתר שלך. נא להתקין מחדש את הבוט.',
    reinstallBot: 'התקן מחדש את הבוט',
    yourBotIsInstalledAndActive: 'הבוט שלך הותקן ופעיל!',
    noPendingAction: 'אין פעולה ממתינה, הבוט שלך הותקן ופעיל',
    youAreAllSet: 'אתה מוכן!',
    showMeMyLeads: 'הצג לי את הלידים שלי',
    connectingToYourWebsiteToVerifyInstallation: 'מתחבר לאתר שלך כדי לאמת התקנה',
    thisMayTakeUpto30Seconds: 'זה עשוי לקחת עד 30 שניות',
    hereIsTheuniqueAILink: 'הנה הקישור היחודי לבוט המבוסס על תובנת מכונה שלך',
    installHeading: 'התקן את הבוט שלך',
    websiteChatbot: 'בוט שיחת אתר אינטרנט',
    aiChatBot: 'בוט מבוסס תובנת מכונה',
    landingPageBot: 'בוט של דף הנחיתה',
    mobileChatBot: 'בוט שיחת אפליקציה ניידת',
    embeddedBot: 'בוט מוטבע',
    selectMobileDevice: 'בחר את ההתקן הנייד שלך',
    mobileDeviceSubheading: 'בחר את השפה האזרחית שבה יוצרה האפליקציה הניידת שלך',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'התקן נייד אחר',
    copyEmbeddedCodeSnippet: 'העתק את קטע הקוד הזה והדבק אותו ב-HTML של כל דף אינטרנט בו ברצונך להציג את הבוט.',
    copyMobileCodeSnippet: 'העתק את קטע הקוד הזה לקוד האפליקציה הניידת שלך במקום בו ברצונך להציג את הבוט.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'הזן את כתובת האימייל של המפתח שלך'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'שלח הוראות למפתח שלך',
      notSureHowToDoIt: 'לא בטוח איך לעשות זאת? המפתחים שלך יכולים לעשות זאת ברגע.',
      developersEmail: 'אימייל של המפתח',
      emailIsRequired: 'יש להזין את האימייל',
      subject: 'נושא',
      message: 'הודעה',
      send: 'שלח',
      placeholders: {
        enterYourWorkEmail: 'הזן את כתובת האימייל שלך',
        createBotUsingBotPenguin: 'מתרגש! צור בוט באמצעות BotPenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'הנה הקישור היחודי לבוט של דף הנחיתה שלך.',
      shareWithUsersOnSocialMedia: 'פשוט שתפה עם המשתמשים שלך ברשתות החברתיות, בדפי הקשר או בכרטיסי ביקור.',
      previewMyLandingPageBot: 'תצפה בבוט של דף הנחיתה שלי',
      or: 'או',
      copyUrl: 'העתק URL',
      howYouLikeToAddBot: 'בחר כיצד תרצה להוסיף את קוד הבוט לאתר שלך',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'התקן את הבוט באתר האינטרנט שלך שנכתב בקוד מותאם או WordPress על ידי העתקה והדבקה של קוד ייחודי.',
      name: 'שם',
      actions: 'פעולות',
      javascript: 'JavaScript',
      install: 'התקן ',
      onYourCustomCodedWebsite: ' באתר האינטרנט הכתוב מראש שלך',
      copyAndPaste: 'העתק והדבק',
      copyThisCodeSnippet: 'העתק את קטע הקוד הזה והדבק אותו ב-HTML של כל דף בו ברצונך להציג את הווידג׳ט. לפרטים נוספים',
      seeGuide: 'ראה מדריך',
      getCode: 'קבל קוד',
      howToInstall: 'איך להתקין?',
      copyCode: 'העתק קוד',
      wordpress: 'WordPress',
      onYourWordPressWebiste: ' באתר ה-WordPress שלך',
      preview: 'תצפה',
      test: 'מבחן',
    }

  },
  liveChatPreview: {
    liveChatPreview: 'תצוגה מקדימה של הצ׳אט החי',
    agentWindow: 'חלון הסוכן',
    person: 'אדם',
    inOrderToTestTypeHere: 'כדי לבדוק, הקלד כאן',
    agentWindowCannotBeCustomized: 'הערה: חלון הסוכן אינו ניתן להתאמה אישית',
    customerWindow: 'חלון הלקוח',
    clickHereToChat: 'לחץ כאן לצ׳אט',
    customerWillChatHere: 'הלקוח יצ׳אט כאן',
    chat: 'צ׳אט',
    by: 'על ידי',
    changeYourBotLookFromLeftPanel: 'שנה את מראה הבוט שלך מהפאנל השמאלי',
    placeholders: {
      typeYourMessage: 'הקלד את ההודעה שלך...',
      typeYourAnswer: 'הקלד את התשובה שלך',
    }

  },
  previews: {
    previewYourLiveChatWidget: 'הצג את ווידג׳ט הצ׳אט החי שלך',
    install: 'התקן',
    seeHowToUse: 'ראה איך להשתמש',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'לחץ כדי להתקין את הבוט שלך לפלטפורמה שנבחרה',
    }

  },
  trainYourBot: {
    trainYourBot: 'אימון הרובוט השיחה שלך',
    knowledgeBase: 'בסיס ידע',
    faqs: 'שאלות נפוצות',
    history: 'היסטוריה',
    faqHeading: 'שאלות נפוצות',
    faqSubHeading: 'תשובות לשאלות הכשרת הרובוט שלך',
    knowledgeBaseHeading: 'אימון הרובוט שלך',
    knowledgeBaseSubheading: 'אימון הרובוט שלך לשיחות משופרות',
    websiteLink: 'קישור לאתר',
    uploadFile: 'העלה את הקובץ שלך',
    selectFile: 'בחר קובץ',
    submit: 'שלח',
    searchHere: 'חפש כאן',
    question: 'שאלה',
    answer: 'תשובה',
    questionPlaceholder: 'כתוב את השאלה שלך כאן',
    answerPlaceholder: 'כתוב את התשובה שלך כאן',
    save: 'שמור',
    discard: 'בטל',
    addFaq: 'הוסף שאלות נפוצות',
    createdAt: 'נוצר בתאריך',
    updatedAt: 'עודכן לאחרונה בתאריך',
    actions: 'פעולות',
    noFaqFound: 'אין לך שאלות נפוצות שנוצרו עדיין',
    noFaqFoundMessage: 'כל השאלות הנפוצות שלך יופיעו כאן',
    historyTitle: 'היסטוריית בסיס הידע',
    historySubtitle: 'תשובות לשאלות הכשרת הרובוט שלך',
    fileName: 'שם הקובץ',
    status: 'סטטוס',
    noHistoryTitle: 'אין לך דגמים מאומנים שנוצרו עדיין',
    noHistoryDescription: 'כל הדגמים המאומנים שלך יופיעו כאן',
    fileUpload: 'העלה קובץ',
    files: 'קבצים',
    fileSubHeading: 'הוסף את כל הקבצים עליהם ברצונך להכשיר את הרובוט שלך',
    noFileFoundHeading: 'אין לך קבצים שנוצרו עדיין',
    noFileFoundSubheading: 'כל הקבצים שלך יופיעו כאן',
    uploadYourFiles: 'העלה את הקבצים שלך',
    upload: 'העלה',
    fileShouldBe: 'הקובץ צריך להיות בפורמט PDF, DOC, DOCX, PPTX או TXT',
    uploadStatus: 'סטטוס העלאה',
    uploadStatusSubHeading: 'סטטוס העלאת הקבצים לצורך הכשרת הרובוט',
    addFile: 'הוסף קובץ',
    otherConfiguration: 'הגדרות AI',
    deleteFiles: 'לחץ כדי למחוק קבצים',
    websiteURL: 'כתובות האתרים',
    websiteUrlSubheading: 'הוסף את כל כתובות האתרים שברצונך להכשיר את הרובוט שלך עליהם',
    enterWebsiteUrl: 'הזן את כתובת ה-URL',
    addWebsite: 'הוסף אתר',
    tableWebsiteUrl: 'כתובת האתר',
    trainingStatus: 'סטטוס הכשרה',
    lastTrained: 'הוכשר לאחרונה בתאריך',
    noWebsitesFound: 'אין לך אתרים שנוצרו עדיין',
    noWebsitesFoundMessage: 'כל האתרים שלך יופיעו כאן',
    loadMore: 'טען עוד',
    botBehaviour: 'הגדר את התנהגות הרובוט',
    botBehaviourSubHeading: 'הגדר את אישיות הרובוט, הסתר מידע של לקוחות, טפל בזרימה ובדוגמה של ChatGPT',
    botPersonality: 'הגדר את אישיות הרובוט',
    botPersonalitySubHeading: 'נהל איך הרובוט שלך יגיב בנוגע לאישיות',
    selectAnswerLength: 'בחר אורך תשובה',
    answerLength: 'אורך התשובה',
    answerAs: 'תשובה כ',
    tone: 'טון',
    language: 'שפה',
    answerFormatting: 'עיצוב התשובה',
    customInstructions: 'הנחיה מותאמת אישית',
    customerInstructionPlaceholder: 'היו מנומסים וידידותיים. אל תשתמש בסלנג. אל תשתמש באימוג',
    redactCustomerData: 'אגור מידע מלקוחות',
    redactDataSubheading: 'הגדר את המידע שאתה רוצה לאגור מהלקוחות',
    collectName: 'אגור שם',
    configureQuestion: 'הגדר שאלה',
    maxCharacterLimit: 'מגבלת תווים מרבית בשם שסופק',
    collectEmail: 'אגור דוא"ל',
    excludeEmail: 'אל תכלול דוא"ל עם תחום',
    collectPhone: 'אגור מספר טלפון',
    manageUnusualMessages: 'נהל הודעות חריגות בצ\'אט',
    unusualMessagesSubheading: 'טפל בתרחישים שאינם נענים ובתרחישים רגילים אחרים עבור הרובוט',
    startConversation: 'הודעה להתחלת השיחה',
    configureMessage: 'הודעה כאשר הרובוט לא מסוגל לענות',
    profanityMessage: 'הודעה להגברת שימוש לא הולם בשפה',
    chatGptModel: 'הגדרת מודל ChatGPT',
    chatGptModelSubheading: 'נהל איזה מודל ChatGPT להשתמש בו ואיך למנוע שימוש שלא הולם',
    chatGptApi: 'מפתח API של ChatGPT',
    enableDisableRedactData: 'אגור מידע מלקוחות',
    triggerToCollectData: 'אגור פרטי יצירת קשר לאחר סט שאלות.',
    train: 'אמן',
    website: 'אתר',
    includedSourcesForResponse: 'מקורות מוכלים לתשובה',
    visibility: 'נראות',
    analytics: 'ניתוחים',
    yourConversation: 'שיחותיך',
    reviewAndSelectConversation: 'בדוק ובחר שיחות לייצוא להכשרת הבוט',
    exportAiAnalytics: 'ייצא נתונים סטטיסטיים',
    startDate: 'תאריך התחלה',
    endDate: 'תאריך סיום',
    userQuestion: 'שאלת המשתמש',
    botsReply: 'תשובת הבוט',
    dateCreated: 'תאריך יצירה',
    noAnalyticsFound: 'אין לך כרגע שום נתונים סטטיסטיים שנוצרו',
    noAnalyticsFoundMessage: 'כל הודעות הניתוח שלך יוצגו כאן',
    exportData: 'ייצוא נתונים',
    exportCompleteAnalyticsData: 'ייצוא נתונים סטטיסטיים מלאים',
    export: 'ייצוא',
    integrateWithChatGpt: 'התחבר עם ChatGPT',
    integrated: 'משותף',
    configure: 'הגדר',
    appToolTip: {
      deleteWebsite: 'לחץ כדי למחוק אתרים',
      knowledgeBase: 'אמן את הרובוט שלך עם בסיס הידע',
      faqs: 'אמן את הרובוט שלך עם שאלות נפוצות',
      knowledgeHistory: 'היסטוריית בסיס הידע',
      uploadFaqs: 'העלה שאלות נפוצות',
      addFaqs: 'לחץ כדי להוסיף שאלות נפוצות',
      deleteFaqs: 'מחק שאלות נפוצות',
      deleteHistory: 'מחק היסטוריה',
      uploadFile: 'הקובץ שהועלה צריך להיות בפורמט PDF, DOCX, DOC או CSV וגודלו לא ישנה את 1 מגה-בייט.',
      trainWebsite: 'הכשר את הרובוט שלך באמצעות אתר.',
      fileUpload: 'הכשר את הרובוט שלך באמצעות קבצים כמו PDF, TXT, PPTX, DOC ו- DOCX.',
      configureBotBehaviour: 'התאם אישית את אישיות הרובוט, נהל זרימה והתאם את מודל ChatGPT.',
      clickToAddWebsite: 'לחץ להוספת אתר אינטרנט',
      clickToDeleteWebsites: 'לחץ למחיקת אתרי אינטרנט',
      syncWebsite: 'סנכרן אתר אינטרנט',
      copyUrl: 'העתק כתובת URL',
      weWillBeFetchingAllTheWebpages: 'נחלוץ את כל דפי האינטרנט ונאמן את דגם ChatGPT עליהם.'
    },
    modal: {
      deleteWebsiteUrl: 'מחיקת כתובת האתר',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'האם אתה בטוח שברצונך למחוק כתובת אתר זו?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'הגדרות צ\'אטבוט לאתר האינטרנט',
    preview: 'תצוגה מקדימה',
    test: 'מבחן',
    install: 'התקנה',
    designHeader: 'עיצוב',
    generalHeader: 'כללי',
    alertsHeader: 'התראות',
    triggerHeader: 'טריגר',
    userAccessHeader: 'גישת משתמש',
    chatWindowHeader: 'חלון הצ\'אט',
    otherConfiguration: 'הגדרות AI',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'לחץ כדי להתקין את הבוט שלך בפלטפורמה הנבחרת',
      customizeAppearanceOfBot: 'התאם אישית את מיקום ומראה ממשק הבוט שלך.',
      configureBotsSettingsWhenAgentUnavailable: 'הגדר את הגדרות הבוט כאשר הסוכן שלך אינו זמין.',
      configureHowBotWillAlertYou: 'הגדר איך הבוט שלך יתריע לך על לידים חדשים.',
      makeSureYourBotsPopUpProperlyTimed: 'וודא שהחלונות הקופצים של הבוט שלך זמנים בצורה מושלמת.',
      customizeWhereYouWantYourBotToAppear: 'התאם אישית איפה אתה רוצה שהבוט שלך יופיע ואיפה לא.',
      enableChatOptionsForVisitorsToTransferChat: 'הפעל את אפשרויות הצ\'אט כדי להעביר צ\'אטים, צ\'אט חי ורענון צ\'אט למבקרים שלך.',
      configureBotBehaviour: 'התאם אישית את אישיות הרובוט, נהל זרימה והתאם את מודל ChatGPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'הגדר את התרומות הבוט שלך',
      specifyHowYourBotWillNotify: 'ציין איך הבוט שלך יודיע לך על לידים חדשים.',
      subject: 'נושא',
      emailNotification: 'התראת דוא"ל',
      emailAddresses: 'כתובות דוא"ל',
      leadRevisitNotification: 'התראות על ביקור חוזר של לידים',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'נרשמת בהצלחה עם התראות לשולחן העבודה',
      youHaveBlockedYourNotificationsOnBrowser: 'חסמת את ההתראות שלך בהגדרות הדפדפן שלך.',
      discard: 'בטל',
      saveChanges: 'שמור שינויים',
      seeHowToUse: 'ראה איך להשתמש',
      placeholders: {
        enterSubjectForYourEmail: 'הזן נושא להתרות הדוא"ל שלך',
        typeYourEmailAddressAndHitEnter: 'הזן את כתובת הדוא"ל שלך והקש על Enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'התאם אישית את נושאי התרות הדוא"ל שלך מהבוט',
        TOOLTIP_EMAIL_NOTIFICATION: 'הפעל/השבת את ההתראות של הבוט בדוא"ל שלך לגבי לידים חדשים',
        TOOLTIP_EMAIL_ADDRESS: 'הזן את כתובות הדוא"ל שברצונך לקבל התראות בדוא"ל. תוכל להוסיף כמה כתובות דוא"ל על ידי הזנת אחת ולחיצה על Enter כדי להוסיף נוספת.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'הפעל/השבת התראות הבוט בדוא"ל שלך אם משתמש שכבר נרשם כליד משתמש אינו פועל בשוב.',
      }
    },
    broadcasts: {
      createBroadcastHeading: 'צור שידור יחיד',
      youCanCreateMultipleBroadcasts: 'תוכל ליצור מספר שידורים יחידים',
      broadcastName: 'שם השידור',
      broadcastNameIsRequired: 'נדרש שם לשידור',
      continue: 'המשך',
      manageBroadcasts: 'נהל שידורים',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'תוכל ליצור מספר שידורים עם מספר תבניות מתוזמנות לקבוצות לקוחות שונות.',
      createNewBroadcast: 'צור שידור חדש',
      name: 'שם',
      segment: 'קְבוּצָה',
      template: 'תבנית',
      contacts: 'אנשי קשר',
      send: 'שלח',
      delivered: 'נשלח',
      read: 'נקרא',
      failed: 'נכשל',
      broadcastAt: 'שידור בתאריך',
      status: 'סטטוס',
      actions: 'פעולות',
      nA: 'N/A',
      seeHowToUse: 'ראה איך להשתמש',
      deleteBroadcast: 'מחק שידור',
      wantToDeleteThisBroadcast: 'האם אתה בטוח שברצונך למחוק שידור זה?',
      placeholders: {
        searchHere: 'חפש כאן',
        enterBroadcastName: 'הזן את שם השידור',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'מחק מספר שידורים',
        broadcastAnalytics: 'נתוני שידור',
        deleteBroadcast: 'מחק שידור',
      },
      broadcastAnalytics: {
        broadcastInsights: 'נתוני שידור',
        broadcastStatus: 'סטטוס השידור',
        youCanFindDetailsOfEachContactBelow: 'תוכל למצוא פרטים של כל איש קשר למטה ולייצא את אלו שיש להם בעיות.',
        filterApplied: 'פילטר מופעל',
        placeholders: {
          searchByPhoneNumber: 'חפש לפי מספר טלפון',
          searchByName: 'חפש לפי שם',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'סנן נתונים לפי סטטוס',
          exportData: 'ייצא נתונים',
        },
        modal: {
          exportData: 'ייצא נתונים',
          exportCompleteBroadcastData: 'ייצא את נתוני השידור המלאים',
          export: 'ייצא',
        },
      },
      createBroadcast: {
        broadcastName: 'שם השידור',
        broadcastNameIsRequired: 'נדרש שם לשידור',
        template: 'תבנית',
        segments: 'קבוצות',
        whenToSend: 'מתי לשלוח',
        now: 'כעת',
        later: 'מאוחר יותר',
        selectBroadcastUsing: 'בחר שידור באמצעות',
        usingCsv: 'באמצעות CSV',
        usingSegment: 'דרך הקבוצה',
        allContacts: 'כל אנשי הקשר',
        selectDate: 'בחר תאריך',
        selectTimeSlot: 'בחר חריג זמן',
        assignVariablesToTemplates: 'הקצה משתנים לתבניות',
        addHeaderMedia: 'הוסף מדיה לכותרת',
        selectFile: 'בחר קובץ',
        cancel: 'בטל',
        createBroadcast: 'צור שידור',
        broadcastUsing: 'שידור באמצעות',
        selectCSVFile: 'בחר קובץ CSV',
        importContacts: 'ייבא אנשי קשר',
        beforeYouStartUpload: 'לפני שתתחיל בהעלאה, נא לוודא:',
        point1: '1. הורד את קובץ ה־CSV הדוגמי.',
        point2: '2. התחל להזין את הנתונים מהשורה השנייה והלאה.',
        point3: '3. בכל שורה, ישנם נתונים של איש קשר אחד.',
        point4: '4. וודא שכל מספרי הטלפון הם ייחודיים.',
        point5: '5. הגודל המרבי להעלאת קובץ הוא 20 מגה־בייט.',
        clickHere: 'לחץ כאן',
        toDownloadSampleFile: 'להורדת קובץ הדוגמה',
        placeholders: {
          enterBroadcastName: 'הזן שם לשידור',
          selectTemplate: 'בחר תבנית',
          searchTemplateByName: 'חפש תבנית לפי שם',
          selectSegments: 'בחר קבוצות',
          selectTimeSlot: 'בחר חריג זמן',
          selectValueForThisVariable: 'בחר ערך עבור משתנה זה',
          searchHeaderByVariableName: 'חפש משתנה כותרת לפי שם',
          variableFallbackValue: 'ערך חילופי למשתנה',
          searchBodyVariableByName: 'חפש משתנה גוף לפי שם',
          searchSegmentByName: 'קבץ לפי שם',
          selectBroadcastUsing: 'בחר שידור באמצעות',
        },
        indexMergeDatePicker: {
          selectStartDate: 'בחר תאריך התחלה',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'ייבא אנשי קשר לשידור בוואטסאפ',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'מספר וואטסאפ',
        errorCode: 'קוד שגיאה',
        errorMessage: 'הודעת שגיאה',
        nA: 'N/A',
      }
    },
    design: {
      customizeYourLandingPage: 'התאמה אישית של עמוד הנחיתה שלך',
      customizeYourConversationalBot: 'התאמת הבוט המשוחח שלך',
      customizeYourBot: 'התאמת הבוט שלך',
      createHeadingTextAndTheme: 'יצירת טקסט כותרת וערכת נושא שמשקפת את אישיות המותג שלך',
      content: 'תוכן',
      theme: 'ערכת נושא',
      layout: 'פריסה',
      landingPageName: 'שם עמוד הנחיתה',
      botName: 'שם הבוט',
      charactersRemaining: ' תווים נותרו',
      landingPageHeader: 'כותרת עמוד הנחיתה',
      landingPageDescription: 'תיאור עמוד הנחיתה',
      hideContent: 'הסתר תוכן',
      profilePic: 'תמונת פרופיל',
      selectFile: 'בחר קובץ',
      socialMediaLinks: 'קישורים לרשתות חברתיות',
      initialMessage: 'הודעת התחלה של הבוט השיחה',
      flowTriggerDetail: 'טריגר לאיסוף פרטי יצירת קשר',
      selectType: 'בחר סוג',
      url: 'כתובת URL',
      delete: 'מחק',
      editLink: 'ערוך קישור',
      addLink: 'הוסף קישור',
      callToAction: 'קריאה לפעולה',
      liveChatWelcomeMessage: 'הודעת ברוך הבא לצ\'אט החי',
      liveChatTriggerMessage: 'הודעת טריגר לצ\'אט החי',
      autoHideCallToAction: 'הסתר אוטומטית קריאה לפעולה',
      maxDelayShouldBe300Seconds: 'העיכוב המרבי צריך להיות 300 שניות',
      landingPageThemeColor: 'צבע ערכת הנושא של עמוד הנחיתה',
      themeColor: 'צבע ערכת הנושא',
      landingPageBackground: 'רקע עמוד הנחיתה',
      chatBackgroundColor: 'צבע רקע הצ\'אט',
      color: 'צבע',
      gradient: 'גרדיאנט',
      image: 'תמונה',
      video: 'וידאו',
      gradientStart: 'התחלת הגרדיאנט',
      gradientEnd: 'סיום הגרדיאנט',
      positionOnWeb: 'מיקום ברשת',
      positionOnMobile: 'מיקום בנייד',
      windowSize: 'גודל החלון',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'אישי',
      height: 'גובה',
      minimum300HeightIsRequired: 'נדרש גובה מינימלי של 300 פיקסלים',
      heightCannotExceed720: 'הגובה לא יכול לחרוג מ-720 פיקסלים',
      width: 'רוחב',
      px: 'פיקסלים',
      minimum200WidthIsRequired: 'נדרש רוחב מינימלי של 200 פיקסלים',
      widthCannotExceed1050: 'הרוחב לא יכול לחרוג מ-1050 פיקסלים',
      discard: 'מחק',
      saveChanges: 'שמור שינויים',
      landingPageBot: 'בוט עמוד הנחיתה',
      chatBot: 'בוט צ\'אט',
      preview: 'תצוגה מקדימה',
      test: 'מבחן',
      botIcon: 'סמל הבוט',
      websiteBot: 'בוט צ\'אט לאתר האינטרנט',
      selectFont: 'בחר גופן',
      fontLibrary: 'ספריית גופנים',
      uploadFont: 'העלה גופן',
      selectFontForBot: 'בחר גופן לבוט',
      uploadedFont: 'גופן שהועלה',
      placeholders: {
        enterName: 'הזן שם',
        enterHeaderText: 'הזן טקסט כותרת',
        enterDescription: 'הזן תיאור',
        enterUrl: 'הזן כתובת URL',
        howCanIHelpYou: 'איך אני יכול לעזור לך?',
        enterYourWelcomeMessageHere: 'הזן את ההודעת ברוך הבא שלך כאן',
        enterYourLiveChatMessageHere: 'הזן את ההודעה שלך לצ\'אט החי כאן',
        enterTimeInSeconds: 'הזן זמן בשניות',
        enterHeight: 'הזן גובה',
        enterWidth: 'הזן רוחב',
        flowInitialMessage: 'הזן שאלה ראשונית',
      },
      appTooltip: {
        standardLayout: 'תצוגה סטנדרטית',
        rightLayout: 'תצוגה מימין',
        mergedLayout: 'תצוגה ממוזגת',
        enterNameOfYourBot: 'הזן את שם הבוט שלך',
        enterNameOfYourLandingPageBot: 'הזן את שם בוט דף הנחיתה שלך',
        headingTextThatAppearOnLandingPageBot: 'הזן את הטקסט הראשי שברצונך שיופיע על הבוט של דף הנחיתה שלך',
        descriptionTextThatAppearOnLandingPageBot: 'הזן את הטקסט התיאור שברצונך שיופיע על הבוט של דף הנחיתה שלך',
        addLinksAndSocialMediaIcons: 'הוסף קישורים וסמלי מדיה חברתית',
        initialFlowMessage: 'הזן את ההודעה הראשונית שהבוט ישלח כדי להתחיל בשיחה עם לקוח',
        anInvitingCatchPhrase: 'בחר משפט מפתיע שיעורר את הלקוח לשוחח עם הבוט',
        setAWelcomeMessageForYourVisitors: 'הגדר הודעת ברוך הבא למבקרים שלך. זו תהיה ההודעה הראשונית בשיחה שלך',
        setAMessageToInitiateLiveChat: 'הגדר הודעה להתחלת שיחה חיה מצד המבקר. תקבל הודעה כל פעם שההודעה הזו מפעילה תגובה בשיחתך.',
        autoHideCallToAction: 'הסתר אוטומטית את ההודעה "פניו אלי".',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'אפשרות להסתיר את הלוגו והטקסט כך שיהיה גלוי רק החלון של השיחה',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'העלה לוגו לדף הבוט שלך.',
        TOOLTIP_CHAT_THEME_COLOR: 'בחר את צבע חלון השיחה של הבוט',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'בחר צבע רקע לחלון השיחה של הבוט',
        TOOLTIP_WEB_1ST_POSITION: 'מיקום של הבוט בחלקו התחתון של מסך המחשב של הלקוח',
        TOOLTIP_WEB_2ND_POSITION: 'מיקום של הבוט בפינת הימין התיקנית של מסך המחשב של הלקוח',
        TOOLTIP_WEB_3RD_POSITION: 'מיקום של הבוט בפינת הימין התחתונה של מסך המחשב של הלקוח',
        TOOLTIP_MOBILE_1ST_POSITION: 'מיקום של הבוט בפינת השמאל התחתונה של המסך של המובייל של הלקוח',
        TOOLTIP_MOBILE_2ND_POSITION: 'מיקום של הבוט בפינת הימין התיקנית של המסך של המובייל של הלקוח',
        TOOLTIP_MOBILE_3RD_POSITION: 'מיקום של הבוט בפינת הימין התחתונה של המסך של המובייל של הלקוח',
        TOOLTIP_WINDOW_HEIGHT: 'שנה את גובה חלון השיחה של הבוט',
        TOOLTIP_BOT_ICON: 'בחר סמל או העלה לוגו/תמונה לבוט שלך',
        flowTriggerMessage: 'הזן את מספר ההודעות שיושאלו לפני איסוף פרטי הקשר כמו שם, דוא"ל וטלפון',
      }

    },
    dripCampaigns: {
      createDripCampaignHeader: 'צור מסע טיפות',
      youCanCreateMultipleCampaigns: 'אתה יכול ליצור מסע טיפות מרובים',
      dripCampaignName: 'שם מסע הטיפות',
      dripCampaignNameIsReuired: 'נדרש שם מסע הטיפות',
      continue: 'המשך',
      manageDripCampaign: 'נהל את מסעי הטיפות שלך',
      youCanCreateMultipleCampaignsWith: 'אתה יכול ליצור מסעי טיפות מרובים עם הודעות מתוזמנות,' +
        ' אוטומטיות להפעלת תגובות מקטגוריות משתמשים ספציפיות.',
      createNewDripCampaign: 'צור מסע טיפות חדש',
      campaignName: 'שם מסע טיפות',
      appliedOn: 'הוחל על',
      segment: 'קְבוּצָה',
      template: 'תבנית',
      startDate: 'תאריך התחלה',
      action: 'פעולה',
      nA: 'N/A',
      seeHowToUse: 'ראה איך להשתמש',
      deleteDripCampaign: 'מחק מסעי טיפות',
      wantToDeleteThisDripCampaign: 'האם אתה בטוח שברצונך למחוק מסעי טיפות אלו?',
      placeholders: {
        enterDripCampaignName: 'הזן שם למסע הטיפות',
        searchByName: 'חפש לפי שם'
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'מחק מסעי טיפות מרובים',
        deleteDripCampaign: 'מחק מסע טיפות',
      },

      createDripCampaign: {
        dripCampaignName: 'שם קמפיין טפיחה',
        appliedOn: 'הוחל על',
        segments: 'קבוצות',
        selectDate: 'בחר תאריך',
        scheduleYourTemplate: 'תזמן את התבנית שלך',
        schedule: 'לוח זמנים',
        template: 'תבנית',
        sendAfter: 'שלח אחרי',
        assignVariablesToTemplate: 'הקצה משתנים לתבניות',
        addHeaderMedia: 'הוסף מדיה לכותרת',
        selectFile: 'בחר קובץ',
        createDripCampaign: 'צור קמפיין טפיחה',
        newSubscriber: 'מנוי חדש',
        minutes: 'דקות',
        hours: 'שעות',
        days: 'ימים',
        placeholders: {
          enterDripName: 'הזן שם קמפיין טפיחה',
          selectAppliedOn: 'בחר איפה הוחל',
          selectSegments: 'בחר קבוצות',
          searchByName: 'חפש לפי שם',
          selectTemplate: 'בחר תבנית',
          searchTemplateByName: 'חפש לפי שם תבנית',
          enterSendAfter: 'הזן שלח אחרי',
          selectValueForThisVariable: 'בחר ערך עבור משתנה זה',
          searchHeaderVariableByName: 'חפש לפי שם משתנה כותרת',
          searchBodyVariableByName: 'חפש לפי שם משתנה גוף',
        },
        dateTimePicker: {
          selectStartDate: 'בחר תאריך התחלה',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'הגדר את הגדרות הכלליות של דף הנחיתה שלך',
      configureBotsGeneralSettings: 'הגדר את הגדרות הכלליות של הבוט שלך',
      selectTheTimeZone: 'בחר את אזור הזמן, השפה והערה קטנה ללקוחות שלך כאשר ה',
      isNotAvailable: 'אינו זמין',
      landingPagesBot: 'בוט של דף הנחיתה',
      bot: 'בוט',
      waitingMessage: 'הודעת המתנה',
      landingPageBotStatus: 'מצב בוט דף הנחיתה',
      chatBotStatus: 'מצב הבוט',
      unavailabilityMessage: 'הודעת אי אפשרות',
      sendIncompleteResponses: 'שלח תגובות לא שלמות',
      language: 'שפה',
      invalidateCache: 'בטל מטמון',
      timezone: 'אזור זמן',
      chatWindowMessageHistory: 'היסטוריית הודעות בחלון הצ׳אט',
      session: 'סשן',
      forever: 'לעד',
      english: 'אנגלית',
      spanish: 'ספרדית',
      french: 'צרפתית',
      portuguese: 'פורטוגזית',
      german: 'גרמנית',
      italian: 'איטלקית',
      swedish: 'שוודית',
      arabic: 'ערבית',
      malay: 'מלאית',
      russian: 'רוסית',
      discard: 'בטל',
      saveChanges: 'שמור שינויים',
      seeHowToUse: 'ראה איך להשתמש',
      placeholders: {
        enterYourWaitingMessageHere: 'הזן את הודעת ההמתנה שלך כאן',
        // selectLanguage: '',
        searchTimeZone: 'חפש אזור זמן',
        // selectChatRetentionPolicy: '',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'התאם את ההודעה לפי רצונך להופיע בחלון הצ׳אט מייד לאחר שמשתמש בוט שולח בקשה לצ׳אט חי',
        TOOLTIP_CHATBOT_STATUS: 'הפעל / השבת את הבוט',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'התאם את ההודעה לפי רצונך להופיע בחלון הצ׳אט כאשר אף אחד מסוכנייך אינם זמינים לצ׳אט חי עם משתמשי הבוט',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'שלח הודעות דוא"ל אפילו כאשר משתמש הבוט לא סיים את השיחה',
        TOOLTIP_LANGUAGE: 'בחר את שפת הבוט',
        TOOLTIP_INVALIDATE_CACHE: 'לחץ כאן כדי לנקות את המטמון כאשר אתה עורך את זרימת הצ׳אט של הבוט או את ההגדרות שלו, שינויים יתפרסמו רק לאחר 15 דקות',
        TOOLTIP_TIME_ZONE: 'בחר את אזור הזמן של הבוט',
        TOOLTIP_CHAT_RETENTION: 'בחר אם ברצונך לשמור צ׳אטים של משתמשי הבוט לעדיין בחלון הצ׳אט או רק לסשן של הדפדפן.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'התאם אישית את חלון הצ\'אט שלך',
      controlAndCustomizeTheChatWindow: 'שליטה והתאמה אישית של חלון הצ\'אט שבו הלקוחות שלך יתקשו.',
      allowVisitorsToTransferChatToWhatsapp: 'אפשר למבקרים להעביר צ\'אט ל-WhatsApp',
      allowVisitorsToRefreshChat: 'אפשר למבקרים לרענן את הצ\'אט',
      displayNameForTransferringChatToWhatsapp: 'שם תצוגה להעברת צ\'אט ל-WhatsApp',
      displayNameForTransferringToLiveChat: 'שם תצוגה להעברה לצ\'אט חי/סוכן',
      displayNameForTransferringAChatToBot: 'שם תצוגה להעברת צ\'אט לבוט',
      discard: 'בטל',
      saveChanges: 'שמור שינויים',
      allowVisitorsToTransferChatToBot: 'אפשר למבקרים להעביר צ\'אט לבוט.',
      allowVisitorsToRequestForLiveChat: 'אפשר למבקרים לבקש צ\'אט חי.',
      indicativeTextForCustomersReply: 'טקסט מפתח לתשובת הלקוח',
      displayNameForRestartingChatBot: 'שם תצוגה לאתחול שיחת הבוט מחדש',
      seeHowToUse: 'ראה איך להשתמש',
      placeholders: {
        enterButtonNameForWhatsapp: 'הזן שם לכפתור להעברה ל-WhatsApp',
        enterButtonNameForLiveChat: 'הזן שם לכפתור להעברה לצ\'אט חי או לסוכן',
        enterButtonNameForBot: 'הזן שם לכפתור להעברה לבוט',
        enterTextDisplayInEmptyReplyArea: 'הזן את הטקסט המוצג באזור התשובה הריקה',
        enterButtonForRestartingConversation: 'הזן שם לכפתור לאתחול שיחת הבוט מחדש',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'אפשר למבקרי האתר להעביר את הצ\'אט ל-WhatsApp של הסוכן הרלוונטי שאליו הם מחוברים. יש להפעיל את אפשרות הצ\'אט החי לפני שהאפשרות הזו תעבוד.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'אפשר למשתמשי הבוט לרענן את הצ\'אט כך שיכולים להתחיל שיחה מחדש.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'אפשר למשתמשי הבוט להעביר את הצ\'אט לבוט בכל רגע במהלך השיחה עם הסוכן בצ\'אט החי.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'אפשר למשתמשי הבוט לבקש צ\'אט חי עם הסוכן בכל רגע במהלך השיחה.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'אם הפעלת את האפשרות להעברת המשתמש ל-WhatsApp, מלא כיצד תרצה להציג את האפשרות הזו',
        ifYouHaveEnabledUserToTransferToLiveChat: 'אם הפעלת את האפשרות להעברת המשתמש לצ\'אט חי, מלא כיצד תרצה להציג את האפשרות הזו',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'אם הפעלת את האפשרות להעברת המשתמש ל-WhatsApp, מלא כיצד תרצה להעביר אותו בחזרה לבוט',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'מלא כיצד תרצה להציג את אזור התשובה עבור הלקוחות',
        ifYouHaveEnabledUserToRestartChatBot: 'אם הפעלת את האפשרות לאתחול מחדש של שיחת הבוט, מלא כיצד תרצה להציג את האפשרות הזו',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'זרימות צ\'אט',
      dripCampaignHeader: 'מעגלי טיפול',
      widgetsHeader: 'ווידג\'טים',
      broadcastHeader: 'שידורים',
      ordersHeader: 'הזמנות',
      manageHeader: 'ניהול',
      inputHeader: 'קלט',
      apiKeyHeader: 'מפתח API',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'צור מסע עם מערך מבוסס זמנים של זרימות צ\'אט ללקוחות.',
        TOOLTIP_CHAT_FLOW_TITLE: 'התאם אישית את תבניות זרימות הצ\'אט וצור זרימות צ\'אט מרובות לקהלים שונים.',
        TOOLTIP_WIDGET_TITLE: 'צור ווידג\'טים (ממשקי צ\'אט) והטמע אותם בדפי אינטרנט פרסומיים כדי לנתב מבקרים לבוט',
        TOOLTIP_BROADCAST_TITLE: 'שדר הודעות או זרימות לאנשי קשר שלך בלחיצה אחת בזמן שמתוזמן מראש',
        TOOLTIP_ORDERS_TITLE: 'רשימת הזמנות שהוזמנו על ידי הלקוח',
        TOOLTIP_MANAGE_TITLE: 'ניהול פונקציונליות של הבוט ליצירת קשרים/לידים ומילות מפתח',
        TOOLTIP_INPUT_TITLE: 'צור תבניות WhatsApp, תמונות וטקסט כחומר תגובה לאנשי קשר/לידים',
      },
      apiWrapper: {
        whatsappApiCredentials: 'אישורי API של WhatsApp',
        generateYourWhatsappApiKey: 'צור מפתח API של WhatsApp כדי לאפשר אינטגרציה עם האפליקציות שלך המותאמות אישית.',
        generateApiKey: 'צור מפתח API',
        webhookUrl: 'כתובת URL של Webhook',
        addHeader: 'הוסף כותרת',
        test: 'בדוק',
        webhookNotSet: 'Webhook לא הוגדר',
        webhookHeaders: 'כותרות Webhook',
        setWebhookUrl: 'הגדר כתובת URL של Webhook',
        placeholders: {
          yourApiKey: 'מפתח API שלך',
          enterWebhookUrl: 'הזן כתובת URL של Webhook',
          enterHeaderKey: 'הזן מפתח כותרת',
          enterValue: 'הזן ערך',
        }
      },
      broadcast: {
        createBroadcast: 'צור שידור',
        createMultipleBroadcasts: 'תוכל ליצור שידורים מרובים עם זרימות/תבניות מתוזמנות שונות לקהלים שונים.',
        broadcastName: 'שם השידור',
        nameIsRequired: 'שם השידור נדרש',
        selectType: 'בחר סוג',
        selectYourFlowType: 'בחר את סוג הזרימה שלך',
        segment: 'קטע',
        selectYourSegmentType: 'בחר את סוג הקטע שלך',
        whenToSend: 'מתי לשלוח',
        selectDate: 'בחר תאריך',
        messageTag: 'תג הודעה',
        selectMessageTag: 'בחר תג הודעה',
        contentType: 'סוג תוכן',
        nonPromotional: 'לא פרסומי (תגי הודעה מחוברים)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'תייג כל הודעה שמגיעה לפני השיחה או לאחר שלבי השהייה שחורגים מ-24 שעות עם התג המתאים. הודעות שאינן מתוייגות לא יגיעו למנויים שהתקשרו עם הבוט לפני יותר מ-24 שעות',
        promotional: 'פרסומי (תגי הודעה אינם נשמרים)',
        theseBroadcasts: 'בשידורים אלו ניתן לכלול קידום מכירות, אך הקהל היעד מוגבל למנויים שהתקשרו עם הבוט שלך ביום האחרון.',
        createNewBroadcast: 'צור שידור חדש',
        sentMessages: 'הודעות שנשלחו',
        deliveredMessages: 'הודעות שהגיעו',
        readMessages: 'הודעות שנקראו',
        failedMessages: 'הודעות שנכשלו',
        type: 'סוג',
        template: 'תבנית',
        sent: 'נשלח',
        delivered: 'נמסר',
        read: 'נקרא',
        failed: 'נכשל',
        broadcastAt: 'שידור בתאריך',
        status: 'סטטוס',
        shipmentUpdate: 'עדכון משלוח',
        flow: 'זרימה',
        resendFailedMessages: 'שלח מחדש הודעות שנכשלו',
        goBack: 'חזור אחורה',
        number: 'מספר',
        errorCode: 'קוד שגיאה',
        failedAt: 'נכשל בתאריך',
        badGateway: '502 שער רע',
        loadMore: 'טען עוד',
        placeholders: {
          enterBroadcastName: 'הזן שם שידור',
          searchBroadcastByName: 'חפש שידור לפי השם',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'סה"כ הודעות שנשלחו בשידור',
          totalBroadcastMessagesDelivered: 'סה"כ הודעות שהגיעו בשידור',
          totalBroadcastMessagesReadByContacts: 'סה"כ הודעות שנקראו על ידי אנשי קשר',
          totalFailedBroadcastMessages: 'סה"כ הודעות שנכשלו בשידור',
          clickToViewDetailedInformationOfFailedMessages: 'לחץ כדי לראות מידע מפורט על הודעות שנכשלו',
          clickToCreateNewBroadcast: 'לחץ כדי ליצור שידור חדש',
          templateUsedInBroadcast: 'תבנית שנמצאת בשידור',
          numberOfBroadcastMessagesSent: 'מספר הודעות שנשלחו בשידור',
          numberOfBroadcastMessagesDelivered: 'מספר הודעות שהגיעו בשידור',
          numberOfBroadcastMessagesReadByContacts: 'מספר הודעות שנקראו על ידי אנשי קשר בשידור',
          numberOfTimesBroadcastMessagesHasFailed: 'מספר הפעמים ששידור הודעות נכשל',
          contactNumberOnWhichMessageDeliveryFailed: 'מספר אנשי קשר בו נכשלה משלוח ההודעה',
          unDeliveredTemplate: 'תבנית של הודעה שלא נמסרה',
          errorReceivedForFailedMessage: 'שגיאה שהתקבלה במשלוח ההודעה שנכשל',
          timeWhenMessageDeliveryFailed: 'זמן בו נכשל משלוח ההודעה',
          TOOLTIP_ADD_BROADCAST_NAME: 'הזן שם חדש לשידור',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'בחר אם אתה משדר זרימת צ\'אט או הודעה.',
          TOOLTIP_BROADCAST_FLOW: 'בחר את זרימת הצ\'אט שברצונך לשדר',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'בחר את סוג הקבוצות אליו ברצונך לשדר',
          TOOLTIP_BROADCAST_NAME: 'שם השידור',
          TOOLTIP_BROADCAST_TYPE: 'סוג הודעה או זרימה שנבחרו לשידור',
          TOOLTIP_BROADCAST_SEGMENT: 'קטע לו השידור מופנה',
          TOOLTIP_BROADCAST_AT: 'תאריך ושעה שבהם השידור מתוכנן',
          TOOLTIP_BROADCAST_STATUS: 'סטטוס השידור כגון מתוכנן, פועל כרגע או הושלם',
        }

      },
      drip: {
        createDripCampaign: 'צור מערכת טיפול',
        youCanCreateMultipleCampaigns: 'באפשרותך ליצור מספר מערכות טיפול שונות',
        dripCampaignName: 'שם מערכת הטיפול',
        dripCampaignNameIsRequired: 'יש להזין שם מערכת הטיפול',
        youCanCreateMultipleDripCampaigns: 'באפשרותך ליצור מספר מערכות טיפול בהודעות מתוזמנות ואוטומטיות לגרום לתגובות מקבוצות מסוימות של משתמשים.',
        campaignName: 'שם מערכת הטיפול',
        campaignNameIsRequired: 'יש להזין שם מערכת הטיפול',
        after: 'לאחר ',
        thisMessageWillBeSendAfter: 'ההודעה הזו תישלח לאחר',
        saveAndUpdate: 'שמור ועדכן',
        schedule: ' לוח זמנים',
        select: 'בחר',
        messages: 'הודעות',
        cancel: 'ביטול',
        createCampaign: 'צור מערכת טיפול',
        updateCampaign: 'עדכן מערכת טיפול',
        createNewCampaign: 'צור מערכת טיפול חדשה',
        messagesSent: 'הודעות שנשלחו',
        createdAt: 'נוצר בתאריך',
        updatedAt: 'עודכן בתאריך',
        action: 'פעולה',
        placeholders: {
          nameYourDripCampaign: 'בחר שם למערכת הטיפול',
          enterCampaignName: 'הזן שם למערכת הטיפול',
          searchCampaignByName: 'חיפוש לפי שם מערכת הטיפול',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'לחץ ליצירת מערכת טיפול חדשה',
          TOOLTIP_SCHEDULE: 'תכנן מתי ואיזו זרימת צ\'אט לשלוח ראשונה במערכת הטיפול זו.',
          TOOLTIP_AFTER_DAYS: 'קבע מתי לשלוח את הזרימה הראשונה של המערכת בדקות, שעות או ימים.',
          TOOLTIP_ADD_MESSAGES: 'לחץ להוספת זרימה חדשה למערכת הטיפול',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'הזן שם חדש למערכת הטיפול',
          TOOLTIP_CAMPAIGN_NAME: 'שם המערכת הטיפול',
          TOOLTIP_NO_MESSAGES_SENT: 'מספר הודעות שנשלחו על ידי המערכת',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'תאריך ושעת יצירת המערכת הטיפול',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'תאריך ושעת עדכון אחרונים של המערכת הטיפול',
          TOOLTIP_CAMPAIGN_ACTION: 'ערוך או מחק את המערכת הטיפול',
          TOOLTIP_UPDATE_CAMPAIGN: 'לחץ לשמירת השינויים שביצעת בהגדרת המערכת הטיפול.',
        }
      },

      flows: {
        createChatFlow: 'צור זרימת צ\'אט',
        youCanCreateMultipleConversationsFlow: 'באפשרותך ליצור מספר זרימות צ\'אט במספר ערוצים שונים לצורך בדיקות A/B ושידורים.',
        flowName: 'שם הזרימה',
        nameIsRequired: 'יש להזין שם',
        cancel: 'ביטול',
        createFlow: 'צור זרימה',
        createNewFlow: 'צור זרימה חדשה',
        ofMessages: 'מהודעות',
        triggered: 'הופעלה',
        stepsFinsished: 'שלבים הושלמו',
        finished: 'הושלמה',
        createdOn: 'נוצר בתאריך',
        lastModified: 'שונה לאחרונה',
        defaultFlow: 'זרימת ברירת מחדל',
        action: 'פעולה',
        flowAnalytics: 'נתוני זרימה',
        placeholders: {
          enterFlowName: 'הזן שם לזרימה',
          searchFlowByName: 'חיפוש לפי שם הזרימה',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'מספר הפעמים שבהן הזרימה הופעלה',
          numberOfCompletedChatFlowStepsWhenTriggered: 'מספר השלבים הושלמו בזרימת הצ\'אט בכל פעם שהופעלה',
          numberOfTimesUsersCompletedChatFlowSteps: 'מספר הפעמים בהם משתמשים השלימו את כל שלבי זרימת הצ\'אט',
          dateAndTimeOfCreationOfChatFlow: 'תאריך ושעת יצירת הזרימה',
          dateAndTimeWhenChatFlowLastModified: 'תאריך ושעת שינוי אחרונים שבוצעו בזרימת הצ\'אט',
          TOOLTIP_CREATE_FLOW: 'לחץ כאן כדי ליצור זרימת צ\'אט חדשה',
          TOOLTIP_FLOW_NAME: 'הזן שם לזרימת הצ\'אט',
          TOOLTIP_FLOW_TABLE_NAME: 'שם הזרימה בטבלה',
          TOOLTIP_NO_OF_MESSAGES: 'מספר הודעות בזרימת הצ\'אט',
          TOOLTIP_DEFAULT_FLOW: 'השתמש במתג זה כדי להגדיר את הזרימה כברירת מחדל, שתשמש לכל המשתמשים החדשים.',
          TOOLTIP_ACTION: 'ערוך או מחק את הזרימה',
        }
      },
      manage: {
        leads: 'לידים',
        manageBotsFunctionalityForContacts: 'נהל את פעולת הבוט עבור אנשי הקשר/לידים',
        whenToSendLeadInfo: 'מתי לשלוח את מידע הליד',
        smartFlow: 'זרימה חכמה',
        welcomeMessageWhenNoSearchKeywordIsMet: 'הודעת ברוך הבא כאשר לא נמצא מילת מפתח',
        selectTemplates: 'בחר תבניות',
        selectFlows: 'בחר זרימות',
        selectText: 'בחר טקסט',
        selectImages: 'בחר תמונות',
        whenItAlmostReaches24HoursSinceMessage: 'כאשר מתקרבים ל-24 שעות מאז הודעה',
        updateChanges: 'עדכן שינויים',
        manageBotsFunctionalityAccordingToKeywords: 'נהל את פעולת הבוט בהתאם למילות המפתח שנקלטו על ידי משתמשי הבוט, במקרה של זרימה חכמה',
        addKeywordAction: 'הוסף פעולת מילת מפתח',
        keywords: 'מילות מפתח',
        matchingMethod: 'שיטת התאמה',
        replyMaterial: 'חומר מענה',
        action: 'פעולה',
        select: 'בחר',
        placeholders: {
          keyword: 'מילת מפתח'
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'הצע אם הבוט ישלח מידע אודות אנשי הקשר/הלידים למערכת CRM המשויכת, באופן מיידי, או אחרי 5 דקות, או בסיום הזרימה',
          TOOLTIP_LEAD_SMART_FLOW: 'בחר זרימת ברירת מחדל כלשהי כדי לשלוח אותה לכל משתמשי הבוט, או זרימת חכמה (שתיקבע על ידי המילות המפתח שנקלטו על ידי משתמשי הבוט).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'בחר חומר מענה (זרימה, תבנית, טקסט, תמונה) לשלוח במקרה שבו הבוט לא מצליח למצוא התאמה למילת מפתח מצד המשתמש בבוט',
          TOOLTIP_IT_ALMOST_REACHED: 'בחר חומר מענה לשליחה לאנשי הקשר/הלידים עוד לפני שעוברות 24 שעות מאז הודעה אחרונה',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'נהל את פעולת הבוט בהתאם למילות מפתח שנקלטו על ידי משתמשי הבוט, במקרה של זרימה חכמה',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'בחר מגון בתשובת הבוט בתור תבנית, זרימה, טקסט או תמונה עבור מילות המפתח המסוימות',
          TOOLTIP_ADD_KEYWORD: 'הוסף מילות מפתח',
          TOOLTIP_MATCHING_METHOD: 'הוסף התאמת מילה מפתח כ \'מדויקה\' או \'מכילה\'',
          TOOLTIP_REPLY_MATERIAL: 'בחר תבנית, זרימה, טקסט או תמונה למענה עבור מילת המפתח המוצעת',
          TOOLTIP_KEYWORD_ACTIONS: 'מחק את הפעולה של מילת המפתח',
        },
        socialAlerts: {
          alerts: 'התראות',
          specifyHowYourBotWillNotify: 'ציין איך הבוט יפרט לך על לידים חדשים.'
        }
      },

      orders: {
        manageCustomersOrder: 'ניהול הזמנות לקוחות',
        checkAndManageAllOfYourCustomerOrders: 'בדוק ונהל את כל ההזמנות של לקוחותיך ועדכוניהם.',
        selectShipmentTemplate: 'בחר תבנית משלוח',
        send: 'שלח',
        export: 'ייצא',
        phoneNumber: 'מספר טלפון',
        numberOfItems: 'מספר פריטים',
        orderValue: 'ערך הזמנה',
        orderDate: 'תאריך הזמנה',
        status: 'סטטוס',
        chat: 'צ\'אט',
        item: 'פריט',
        quantity: 'כמות',
        amount: 'סכום',
        unitPrice: 'מחיר יחידה',
        placeholders: {
          searchOrdersByPhoneNumber: 'חפש הזמנות לפי מספרי טלפון',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'ייצא נתוני הזמנות בקובץ CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'מספר הטלפון של הלקוח שביצע את ההזמנה',
          TOOLTIP_NO_OF_ITEMS: 'מספר הפריטים שהוזמנו',
          TOOLTIP_ORDER_VALUE: 'ערך ההזמנה',
          TOOLTIP_ORDER_DATE: 'ההזמנה בוצעה בתאריך זה',
          TOOLTIP_ORDER_STATUS: 'סטטוס ההזמנה, כגון מאושר, בהמתנה, נדחתה או נשלחה',
          TOOLTIP_ORDER_CHAT: 'כל השיחות הקודמות בנוגע להזמנה זו',
        }
      },
      widgets: {
        installWidgetHeader: 'התקן ווידג\'ט',
        createWidgetHeader: 'צור ווידג\'ט',
        copyTheCodeAndPasteItWhereYouWant: 'העתק את הקוד והדבק אותו בכל מקום שבו ברצונך להציג את הווידג\'ט ב-HTML, באתר וכו\'.',
        youCanCreateMultipleWidgets: 'באפשרותך ליצור מספר ווידג\'טים כדי להעניק לבוט שלך מראה שונה בעמודי האינטרנט השונים.',
        installWidget: {
          javascript: 'ג\'אווה סקריפט',
          install: 'התקן',
          onYourWebsite: ' באתר שלך',
          copyCode: 'העתק קוד',
          copyAndPaste: 'העתק והדבק',
          copyThisCodeSnippet: 'העתק את קטע הקוד הזה והדבק אותו ב-HTML של כל דף בו ברצונך להציג את הווידג\'ט. למידע נוסף,',
          seeGuide: 'ראה מדריך',
        },
        widgetList: {
          widgetName: 'שם הווידג\'ט',
          nameIsRequired: 'יש להזין שם',
          cancel: 'ביטול',
          createWidget: 'צור ווידג\'ט',
          icon: 'סמל',
          name: 'שם',
          createdAt: 'נוצר בתאריך',
          action: 'פעולה',
          placeholders: {
            enterWidgetName: 'הזן שם לווידג\'ט',
            searchWidgetByName: 'חפש ווידג\'ט לפי שם',
          },
          appTooltip: {
            widgetIcon: 'סמל הווידג\'ט',
            nameOfWidget: 'שם הווידג\'ט',
            dateAndTimeOfCreationOfWidget: 'תאריך ושעת יצירת הווידג\'ט',
            editOrDeleteWidget: 'ערוך או מחק את הווידג\'ט',
          }
        },
        widgetTemplates: {
          widgetName: 'שם הווידג\'ט',
          nameIsRequired: 'יש להזין שם',
          chooseTypeOfWidget: 'בחר סוג של ווידג\'ט',
          bar: 'סרגל',
          slideIn: 'החלקה',
          pageTakeOver: 'Page Takeover',
          button: 'כפתור',
          facebookModal: 'Facebook Modal',
          comment: 'תגובה',
          direct: 'ישיר',
          whatsappIcon: 'WhatsApp Icon',
          refUrl: 'כתובת URL מפניה',
          qrCode: 'קוד QR',
          backgroundColor: 'צבע רקע',
          descriptionColor: 'צבע תיאור',
          headlineColor: 'צבע כותרת',
          buttonBackgroundColor: 'צבע רקע לכפתור',
          contactNumber: 'מספר טלפון',
          entryMessage: 'הודעת כניסה',
          buttonText: 'טקסט לכפתור',
          selectButtonText: 'בחר טקסט לכפתור',
          buttonSize: 'גודל כפתור',
          selectOne: 'בחר אחת',
          placement: 'מיקום',
          rightBottom: 'ימין-תחתית',
          optInFlow: 'זרימת הרשמה',
          selectFlow: 'בחר זרימה',
          addSubscriberToSequence: 'הוסף מנוי לרצפים',
          selectDrip: 'בחר רצף',
          whenDoesItDisplay: 'מתי הווידג\'ט יוצג (בשניות)',
          showWidgetToSameUserAgainAfter: 'הצג את הווידג\'ט לאותו משתמש שוב לאחר',
          ifManuallyClosedByUser: 'אם נסגר באופן ידני על ידי המשתמש, הצג שוב לאחר',
          specificPosts: 'פרסומים מסוימים',
          allPosts: 'כל הפרסומים',
          postMessage: 'הודעת פרסום',
          postDate: 'תאריך פרסום',
          status: 'סטטוס',
          action: 'פעולה',
          discard: 'סגור ללא שמירה',
          saveChanges: 'שמור שינויים',
          nextStep: 'שלב הבא',
          widgetDescription: 'תיאור הווידג\'ט',
          integrateTheBarWidget: 'שבט את ווידג\'ט הסרגל בתוך גוף האתר שלך והגבר את מספר המנויים לעמוד. ניתן גם לשלוח להם הודעות מותאמות אישית של הבוט.',
          hereIsYourWidgetHeadline: 'הנה הכותרת של הווידג\'ט שלך, לחץ כאן כדי לשנות אותה.',
          sendUsMessage: 'שלח לנו הודעה',
          weAlsoPutDefaultTextHere: 'גם אנחנו הוספנו טקסט ברירת מחדל כאן. ודא שהוא מתהפך להודעה משמעותית.',
          selectYourPagePost: 'בחר את הפרסום שלך',
          nA: 'N/A',
          saveRule: 'שמור כלל',
          placeholders: {
            enterWidgetName: 'הזן שם לווידג\'ט',
            enterNumber: 'הזן מספר',
            enterMessageHere: 'הזן הודעה כאן',
            enterTimeInSeconds: 'הזן זמן בשניות',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'התאם את הגדרות ההפעלה של הבוט',
      makeYourBotsTimingPerfect: 'הפוך את הזמנים של הבוט שלך למושלמים',
      onDesktopTriggerTime: 'זמן הפעלה במחשב האישי (שניות)',
      onMobileTriggerTime: 'זמן הפעלה במכשיר נייד (שניות)',
      chatBubble: 'פתיחה אוטומטית של בועת הצ\'אט',
      seconds: 'שניות',
      discard: 'בטל',
      saveChanges: 'שמור שינויים',
      autoTriggerOnOrOff: 'הפעלת/כיבוי אוטומטית',
      seeHowToUse: 'ראה איך להשתמש',
      alwaysOpenAfter: 'תמיד פתוח לאחר מספר הפקודות הנבחר',
      automaticallyAfterSelectedNumberOfTime: 'אוטומטית לאחר מספר הפקודות הנבחר',
      neverAutomaticallyOpenBot: 'לעולם אל תפתח את הבוט אוטומטית',
      placeholders: {
        enterTimeInSeconds: 'הזן זמן בשניות',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'זמן המתנה בשניות במחשב האישי של המבקר, לפני שהבוט שלך יופיע באופן אוטומטי',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'זמן המתנה בשניות במכשיר הנייד של המבקר, לפני שהבוט שלך יופיע באופן אוטומטי',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'כאשר המבקר סוגר את חלון הצ\'אט, בחר איך יהיה ההתנהגות האוטומטית של פתיחת הבוט',
        customerWaitingTimeToOpenBubble: 'זמן המתנה של הלקוח עד לפתיחת בועת הצ\'אט',
        wantToDisableAutoTriggering: 'לחץ אם ברצונך להשבית את ההפעלה האוטומטית של הבוט בדפי האינטרנט'
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'התאם את הגדרות המשתמשים של הבוט שלך',
      decideWhoCanAccessYourBot: 'החלט מי יכול לגשת לבוט שלך',
      deviceSupport: 'תמיכת מכשיר',
      blockIpAddresses: 'חסום כתובות IP',
      hideChatBotOnSpecificPages: 'הסתר את הבוט בדפים מסוימים',
      selectCountriesToEnableBot: 'בחר מדינות לאפשרות הבוט',
      discard: 'בטל',
      saveChanges: 'שמור שינויים',
      seeHowToUse: 'ראה איך להשתמש',
      allDevices: 'כל המכשירים',
      mobileOnly: 'רק בנייד',
      desktopOnly: 'רק במחשב האישי',
      placeholders: {
        enterIpAddress: 'הזן כתובת IP (IPv4 או IPv6) ולחץ Enter',
        enterUrl: 'הזן את כתובת ה-URL של הדפים ולחץ Enter',
        searchCountry: 'חפש מדינה',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'בחר על אילו מכשירים ברצונך שהבוט שלך יופיע',
        TOOLTIP_BLOCK_IP_ADDRESS: 'הזן כתובות IP בהן אתה לא רוצה שהבוט יופיע',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'הזן את כתובות ה-URL של הדפים בהם אתה לא רוצה שהבוט יופיע',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'בחר מיקומים ממנה אתה רוצה שמשתמשים יוכלו לגשת לבוט שלך',
      }
    },
    facebook: {
      facebookBotSettings: 'הגדרות בוט פייסבוק',
      chatFlowsHeader: 'זרימי צ\'אט',
      dripCampaignHeader: 'קמפיין טיפול',
      broadcastHeader: 'שידור',
      widgetsHeader: 'ווידג\'טים',
      autoReplyHeader: 'תשובה אוטומטית',
      generalHeader: 'כללי',
      facebookAutomation: 'הגדרות בוט פייסבוק',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'התאם את תבניות זרימת הצ\'אט וצרוף מספר זרימות שונות לקהלים שונים.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'צור מסלול קמפיין להכנסת לקוחות על ידי שידור זרימות צ\'אט מתוכנת.',
      },
      broadcasts: {
        createBroadcast: 'צור שידור',
        youCanCreateMultipleBroadcasts: 'באפשרותך ליצור מספר שידורים עם תבניות מתוכנות שונות לקהלי לקוח שונים.',
        broadcastName: 'שם השידור',
        broadcastNameIsRequired: 'שם השידור נדרש',
        continue: 'המשך',
        manageBroadcasts: 'ניהול שידורים',
        youCanCreateMultipleBroadcastsWith: 'באפשרותך ליצור מספר שידורים עם מספר תבניות מתוכנות שונות לקהלי לקוח שונים.',
        createNewBroadcast: 'צור שידור חדש',
        name: 'שם',
        segment: 'קְבוּצָה',
        template: 'תבנית',
        contacts: 'אנשי קשר',
        send: 'שלח',
        failed: 'נכשל',
        broadcastAt: 'שידור בתאריך',
        status: 'מצב',
        actions: 'פעולות',
        nA: 'לא זמין',
        deleteBroadcast: 'מחק שידור',
        wantToDeleteThisBroadcast: 'האם אתה בטוח שברצונך למחוק את השידור הזה?',
        placeholders: {
          enterBroadcastName: 'הזן שם לשידור',
          searchHere: 'חפש כאן',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'מחק מספר שידורים',
          deleteBroadcast: 'מחק שידור'
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'שם השידור',
        broadcastNameIsRequired: 'שם השידור חובה',
        flow: 'זרם',
        segments: 'קטגוריות',
        whenToSend: 'מתי לשלוח',
        selectDate: 'בחר תאריך',
        selectTimeSlot: 'בחר משבצת זמן',
        createBroadcast: 'צור שידור',
        cancel: 'ביטול',
        now: 'עכשיו',
        later: 'מאוחר יותר',
        placeholders: {
          enterBroadcastName: 'הזן שם לשידור',
          selectFlow: 'בחר זרם',
          searchFlowByName: 'חפש לפי שם זרם',
          selectSegments: 'בחר קטגוריות',
          searchSegmentByName: 'קבץ לפי שם',
          selectTimeSlot: 'בחר משבצת זמן',
        },
        indexMergeDatePicker: {
          selectStartDate: 'בחר תאריך התחלה',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'צור קמפיין טיפול',
        youCanCreateMultipleCampaign: 'באפשרותך ליצור מספר קמפיינים',
        dripCampaignName: 'שם קמפיין טיפול',
        dripCampaignNameIsRequired: 'שם קמפיין טיפול הוא שדה חובה',
        continue: 'המשך',
        manageDripCampaign: 'ניהול קמפיין טיפול',
        youCanCreateMutlipleDripCampaign: 'באפשרותך ליצור מספר קמפיינים טיפול עם הודעות מתוכנתות, אוטומטיות כדי להפעיל תגובות מהמשתמשים הרצויים.',
        createNewDripCampaign: 'צור קמפיין טיפול חדש',
        campaignName: 'שם הקמפיין',
        appliedOn: 'ניתן על',
        segment: 'קְבוּצָה',
        flow: 'זרם',
        startDate: 'תאריך התחלה',
        action: 'פעולה',
        nA: 'לא זמין',
        deleteDripCampaign: 'מחק קמפיין טיפול',
        wantToDeleteThisDripCampaign: 'האם אתה בטוח שברצונך למחוק קמפיין טיפול זה?',
        placeholders: {
          enterDripCampaignName: 'הזן שם לקמפיין טיפול',
          searchByName: 'חפש לפי שם',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'מחק מספר קמפיינים טיפול',
          deleteDripCampaign: 'מחק קמפיין טיפול',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'שם קמפיין טיפול',
          appliedOn: 'ניתן על',
          segments: 'קטגוריות',
          selectDate: 'בחר תאריך',
          scheduleYourFlows: 'קבע את הזרמים שלך',
          schedule: 'לוח זמנים',
          flow: 'זרם',
          sendAfter: 'שלח לאחר',
          cancel: 'ביטול',
          newSubscriber: 'מנוי חדש',
          days: 'ימים',
          minutes: 'דקות',
          hours: 'שעות',
          createDripCampaign: 'צור קמפיין טיפול',
          placeholders: {
            enterDripName: 'הזן את שם הקמפיין',
            selectAppliedOn: 'בחר בתקנה',
            selectSegment: 'בחר קְבוּצָה',
            searchByName: 'חפש לפי שם',
            selectTemplate: 'בחר תבנית',
            searchFlowByName: 'חפש לפי שם זרם',
            enterSendAfter: 'הזן את השליחה לאחר',
          },
          dateTimePicker: {
            selectStartDate: 'בחר תאריך התחלה',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'מזהה צ\'אט',
        errorCode: 'קוד שגיאה',
        errorMessage: 'הודעת שגיאה',
        nA: 'לא זמין',
      },
      flows: {
        chatFlows: 'זרמי צ\'אט',
        createChatFlow: 'צור זרם צ\'אט',
        youCanCreateMultipleChatFlows: 'ניתן ליצור מספר זרמי צ\'אט שונים במספר ערוצים למעשה A/B טסטים ושידורים.',
        flowName: 'שם הזרם',
        nameIsRequired: 'שם נדרש',
        cancel: 'ביטול',
        createFlow: 'צור זרם',
        importFlow: 'ייבא זרם',
        createNewFlow: 'צור זרם חדש',
        ofMessages: 'הודעות',
        createdOn: 'נוצר בתאריך',
        lastModified: 'שונה לאחרונה',
        defaultFlow: 'זרם ברירת המחדל',
        action: 'פעולה',
        exportStatus: 'סטטוס ייצוא',
        flowExport: 'יצוא זרם',
        download: 'הורדה',
        beforeYouStartUploadPleaseMakeSure: 'לפני שתתחיל להעלות, אנא וודא ש:',
        point1: '1. הגודל המרבי להעלאת קובץ הוא 20MB',
        point2: '2. פורמט הקובץ צריך להיות JSON',
        selectFile: 'בחר קובץ',
        inProgress: 'בתהליך',
        available: 'זמין',
        initiated: 'נפתח',
        placeholders: {
          enterFlowName: 'הזן שם לזרם',
        },
        appTooltip: {
          importFlowForFacebookBot: 'ייבא זרם עבור הבוט של Facebook',
          dateAndTimeOfCreationOfChatFlow: 'תאריך ושעת יצירת הזרם',
          dateAndTimeWhenChatFlowLastModified: 'תאריך ושעת שינוי הזרם האחרונים',
          TOOLTIP_CREATE_FLOW: 'לחץ כדי ליצור זרם צ\'אט חדש',
          TOOLTIP_FLOW_NAME: 'הזן את שם הזרם',
          TOOLTIP_FLOW_TABLE_NAME: 'שם הזרם',
          TOOLTIP_NO_OF_MESSAGES: 'מספר מרכיבי ההודעה בזרם הצ\'אט',
          TOOLTIP_DEFAULT_FLOW: 'השתמש במתג זה כדי להגדיר זרם צ\'אט כברירת מחדל, שישמש את כל המשתמשים החדשים.',
          TOOLTIP_ACTION: 'ערוך או מחק את הזרם הצ\'אט',
        },
        noDataFound: {
          flows: 'זרמים',
          noFlowsAddedYet: 'לא הוספת זרמים עדיין!',
        },
        modal: {
          deleteFlow: 'מחק זרם',
          wantToDeleteThisFlow: 'האם אתה בטוח שברצונך למחוק את הזרם הזה?',
        }
      },
      widgets: {
        manageWidgets: 'נהל ווידג\'טים',
        widgetsAreRoutingTools: 'הווידג\'טים הם כלי להפנית משתמשים לבוט',
        needHelp: 'זקוק לעזרה?',
        wantToMakeChanges: 'רוצה לבצע שינויים?',
        editIcon: 'ערוך סמל',
        discard: 'בטל',
        updateWidget: 'עדכן ווידג\'ט',
        installChatBotIconOnYourWebsite: 'התקן סמל בוט צ\'אט באתר האינטרנט שלך',
        shareWhereEverYouWantToRedirectUsersTo: 'שתף איפה שתרצה והפנה משתמשים לבוט הפייסבוק שלך בלחיצה אחת',
        copyAndAddInYourWebsiteHeaderFile: 'העתק והוסף לקובץ הכותרת של אתר האינטרנט שלך',
        copyLinkAndStartSharing: 'העתק את הקישור והתחל לשתף!',
        downloadQrCodeAndStartSharing: 'הורד קוד QR והתחל לשתף!',
        addCodeAndDeployNewCodeAndDone: 'הוסף את הקוד, הפעל קוד חדש וסיים!',
        wantToLearnHowToUseIt: 'רוצה ללמוד איך להשתמש בזה?',
        copyChatBotCode: 'העתק קוד בוט צ\'אט',
        copyFacebookLink: 'העתק קישור לפייסבוק',
        downloadQrCode: 'הורד קוד QR',
        link: 'קישור',
        chatBotIcon: 'סמל בוט צ\'אט',
        qrcode: 'קוד QR',
        small: 'קטן',
        medium: 'בינוני',
        large: 'גדול',
        leftMiddle: 'משמאל למרכז',
        rightMiddle: 'מימין למרכז',
        leftBottom: 'משמאל לתחתית',
        rightBottom: 'מימין לתחתית',
        bpDropDown: {
          selectIcons: 'בחר סמלים',
          iconSize: 'גודל הסמל',
          iconPlacement: 'מיקום הסמל',
        }
      },
      autoReply: {
        autoReplyTitle: 'תשובה אוטומטית',
        configureYour: 'הגדר את ה',
        message: ' ההודעה',
        customizeAutoReplyMessagesForYourPostsComments: 'התאם את ההודעות לתגובות האוטומטיות לפוסטים שלך',
        wouldYouLikeToApply: 'האם ברצונך להחיל את ',
        autoreply: 'התשובה האוטומטית',
        onAllPost: ' על כל הפוסטים',
        configureYourMessageForEachPost: 'הגדר את ההודעה שלך עבור כל פוסט',
        configureFlowToTriggerAfterOnReply: 'הגדר זרימה להפעלה לאחר תגובה',
        whenUserLeavesACommentSendMessageAfter: 'כאשר המשתמש משאיר תגובה, שלח הודעה לאחר',
        triggerFlowOnReply: 'הפעל זרימה לאחר תגובה',
        add: 'הוסף',
        update: 'עדכן',
        save: 'שמור',
        cancel: 'בטל',
        discard: 'בטל',
        bpDropDown: {
          selectPost: 'בחר פוסט',
          triggerFlowOnReply: 'הפעל זרימה לאחר תגובה',
          immendiately: 'מיידי',
          minutes: 'דקות'
        },
        bpTable: {
          message: 'הודעה',
          selectedPost: 'פוסט נבחר',
          triggerFlow: 'הפעל זרימה',
          actions: 'פעולות'
        },
        placeholder: {
          configureYourMessage: 'הגדר את ההודעה שלך'
        },
        deleteModel: {
          title: 'מחק תשובה אוטומטית לפוסט',
          description: 'האם אתה בטוח שברצונך למחוק תשובה אוטומטית לפוסט זה?'
        }
      },
      generalSetting: {
        sendInactivityMessage: 'הודעת חוסר פעילות של המשתמש',
        sessionTimeOutInHours: 'זמן תפוגה של הסשן בשעות',
        enterSessionTimeOutWindowInHours: 'הזן חלון זמן תפוגה בשעות',
        sessionTimeOutInHoursTooltip: 'הזן את המשך הזמן לאחרו הסשן יפסיק באופן אוטומטי עקב חוסר פעילות. הזמן ייחשב מחדש אם המשתמש שולח הודעה אחרי שהסשן פסק.',
        restartChatFlowTriggers: 'טריגרים לאיפוס זרימת השיחה',
        enterKeyWordsToRestartChatAndhitEnter: 'הזן מילות מפתח לאיפוס השיחה ולחץ Enter',
        restartChatFlowTriggersTooltip: 'ציין מילות מפתח כאן שכאשר המשתמש מזין אותן ומגיב, זה יגרום לאיפוס של זרימת השיחה. זה מאפשר למשתמשים לאפס את השיחה בכל שלב. לחץ על Enter לאחר כל מילת מפתח כדי להוסיף מילות מפתח מרובות.',
        liveChatTriggers: 'טריגרים לצ\'אט חי',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'הזן מילות מפתח לטריגר צ\'אט חי ולחץ Enter',
        liveChatTriggersTooltip: 'הגדר מילות מפתח שכאשר המשתמש מזין אותן ומגיב, זה יפעיל מעבר מצ\'אט אוטומטי לצ\'אט חי. זה שימושי כאשר המשתמשים זקוקים לעזרה מסוכן חי. לחץ על Enter לאחר כל מילת מפתח כדי להוסיף מילות מפתח מרובות.',
        messageAfterAutomatedFlowCompleted: 'הודעה לאחר השלמת זרימת השיחה האוטומטית',
        enterYourMessage: 'הזן את ההודעה שלך',
        messageAfterAutomatedFlowCompletedTooltip: 'הזן הודעה שתוצג למשתמש לאחר שהוא השלים את זרימת השיחה האוטומטית אך הסשן לא התפג. ',
        restart: 'אפס',
        liveChat: 'צ\'אט חי',
        lineOneForDefaultMessage: 'אם יש לך שאלות נוספות או צריך עזרה, הנה מה שאתה יכול לעשות:',
        to: 'כדי ל',
        restartTheChat: 'אפס את השיחה',
        simpleTypeRestartAndHitEnter: 'פשוט הקלד "אפס" ולחץ Enter. זה יחזיר אותך להתחלת זרימת השיחה שלנו.',
        lineThreeForDefaultMessagePartOne: 'אם תרצה לדבר עם',
        liveAgent: 'סוכן חי,',
        lineThreeForDefaultMessageLastPart: 'הקלד "צ\'אט חי" ולחץ Enter. צוותנו כאן לעזור לך בזמן אמת.',
        saveChanges: 'שמור שינויים',
        discard: 'בטל',
        configureBotsGeneralSettings: 'הגדר את הגדרות הגנרל של הבוט שלך',
        minutes: 'דקות',
        hours: 'שעות',
      }
    },
    instagram: {
      instagramBotSettings: 'הגדרות בוט אינסטגרם',
      chatFlowsHeader: 'זרימות צ\'אט',
      broadcastHeader: 'שידור',
      dripCampaignHeader: 'מבצע טפ חוט',
      widgetsHeader: 'ווידג\'טים',
      alertsHeader: 'התראות',
      alerts: {
        configureYourBotsSettings: 'הגדר את הגדרות ההתראה של הבוט',
        specifyHowYourBotNotifyYou: 'ציין איך הבוט יתריע לך על לידים חדשים.',
        subject: 'נושא',
        emailNotification: 'התראת אימייל',
        emailAddresses: 'כתובות אימייל',
        leadRevisitNotifications: 'התראות על ביקורים חוזרים של לידים',
        whenToSendLeadInfo: 'מתי לשלוח מידע על ליד',
        discard: 'בטל',
        saveChanges: 'שמור שינויים',
        placeholders: {
          enterSubjectForYourEmail: 'הזן נושא לדוא"ל שלך',
          typeYourEmailAddressAndHitEnter: 'הזן את כתובת הדוא"ל שלך ולחץ על Enter',
        }
      },
      broadcasts: {
        createBroadcast: 'צור שידור',
        youCanCreateMultipleBroadcasts: 'אפשר ליצור מספר שידורים',
        broadcastName: 'שם השידור',
        broadcastNameIsRequired: 'שם השידור הוא שדה חובה',
        continue: 'המשך',
        manageBroadcasts: 'ניהול שידורים',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'אפשר ליצור מספר שידורים עם מספר תבניות מתוזמנות לקטגוריות לקוחות שונות.',
        createNewBroadcast: 'צור שידור חדש',
        segment: 'קְבוּצָה',
        template: 'תבנית',
        contacts: 'אנשי קשר',
        send: 'שלח',
        failed: 'נכשל',
        broadcastAt: 'שידור בתאריך',
        status: 'מצב',
        actions: 'פעולות',
        nA: 'לא זמין',
        placeholders: {
          enterBroadcastName: 'הזן שם לשידור',
          searchHere: 'חפש כאן',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'מחק מספר שידורים',
          deleteBroadcast: 'מחק שידור',
        }
      },
      createBroadcast: {
        broadcastName: 'שם השידור',
        broadcastNameIsRequired: 'שם השידור הוא שדה חובה',
        flow: 'זרימה',
        segments: 'קטגוריות',
        whenToSend: 'מתי לשלוח',
        selectDate: 'בחר תאריך',
        selectTimeSlot: 'בחר חלון זמן',
        cancel: 'ביטול',
        createBroadcast: 'צור שידור',
        placeholders: {
          enterBroadcastName: 'הזן שם לשידור',
          selectFlow: 'בחר זרימה',
          searchFlowByName: 'חפש זרימה לפי שם',
          selectSegments: 'בחר קטגוריות',
          searchSegmentByName: 'קבץ לפי שם',
          selectTimeSlot: 'בחר חלון זמן',
        },
        indexMergeDatePicker: {
          selectStartDate: 'בחר תאריך התחלה',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'צור מסע טפטוף',
        youCanCreateMultipleCampaigns: 'אתה יכול ליצור מספר מסעות טפטוף',
        dripCampaignName: 'שם מסע הטפטוף',
        dripCampaignNameIsRequired: 'שם מסע הטפטוף הוא שדה חובה',
        continue: 'המשך',
        manageDripCampaign: 'ניהול מסעות טפטוף',
        youCanCreateMultipleCampaignsWith: 'אתה יכול ליצור מספר מסעות טפטוף עם הודעות מתוזמנות ואוטומטיות לגרום לתגובות מקטגוריות משתמשים מסוימות.',
        createNewDripCampaign: 'צור מסע טפטוף חדש',
        campaignName: 'שם הקמפיין',
        appliedOn: 'נמצא בתוך',
        segment: 'קְבוּצָה',
        flow: 'זרימה',
        startDate: 'תאריך התחלה',
        action: 'פעולה',
        nA: 'לא זמין',
        placeholders: {
          enterDripCampaignName: 'הזן שם למסע הטפטוף',
          searchByName: 'חיפוש לפי שם',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'מחק מספר מסעות טפטוף',
          deleteDripCampaign: 'מחק מסע טפטוף',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'שם מסע הטפטוף',
          appliedOn: 'נמצא בתוך',
          segments: 'קטגוריות',
          selectDate: 'בחר תאריך',
          scheduleYourFlows: 'תזמן את הזרמותיך',
          flow: 'זרימה',
          sendAfter: 'שלח אחרי',
          cancel: 'ביטול',
          createDripCampaign: 'צור מסע טפטוף',
          placeholders: {
            enterDripName: 'הזן שם למסע הטפטוף',
            selectAppliedOn: 'בחר את המקום בו יישמע',
            selectSegment: 'בחר קְבוּצָה',
            searchByName: 'חיפוש לפי שם',
            selectTemplate: 'בחר תבנית',
            searchFlowByName: 'חיפוש לפי שם הזרימה',
            enterSendAfter: 'הזן שלח אחרי',
          },
          dateTimePicker: {
            selectStartDate: 'בחר תאריך התחלה',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'זיהוי צ\'אט',
        errorCode: 'קוד שגיאה',
        errorMessage: 'הודעת שגיאה',
        nA: 'לא זמין',
      },
      flows: {
        chatFlows: 'זרמי צ\'אט',
        createChatFlow: 'צור זרם צ\'אט',
        youCanCreateMultipleChatFlows: 'אתה יכול ליצור מספר זרמי שיחה במספר ערוצים שונים לצורך בדיקות A/B ושידורים.',
        flowName: 'שם הזרם',
        nameIsRequired: 'שם הוא שדה חובה',
        cancel: 'ביטול',
        createFlow: 'צור זרם',
        importFlow: 'ייבא זרם',
        createNewFlow: 'צור זרם חדש',
        ofMessages: ' מהודעות',
        createdOn: 'נוצר בתאריך',
        lastModified: 'שונה לאחרונה בתאריך',
        defaultFlow: 'זרם ברירת מחדל',
        action: 'פעולה',
        exportStatus: 'סטטוס ייצוא',
        flowExport: 'ייצוא זרם',
        download: 'הורד',
        beforeYouStartUploadPleaseMakeSure: 'לפני שתתחיל להעלות, אנא וודא ש:',
        point1: '1. הגודל המרבי להעלאת קובץ הוא 20 מגה-בייט',
        point2: '2. הפורמט של הקובץ צריך להיות JSON',
        selectFile: 'בחר קובץ',
        placeholders: {
          enterFlowName: 'הזן שם לזרם',
        },
        appTooltip: {
          importFlowForInstagramBot: 'ייבא זרם לבוט האינסטגרם',
          dateAndTimeOfCreationOfChatFlow: 'תאריך ושעת יצירת זרם הצ\'אט',
          dateAndTimeWhenChatFlowLastModified: 'תאריך ושעת שינוי אחרון בזרם הצ\'אט',
        },
        noDataFound: {
          flows: 'זרמים',
          noFlowsAddedYet: 'טרם הוספת זרמים!',
        },
        modal: {
          deleteFlow: 'מחק זרם',
          wantToDeleteThisFlow: 'האם אתה בטוח שברצונך למחוק את הזרם הזה?',
        }
      },
      widgets: {
        manageWidgets: 'נהל ווידג\'טים',
        widgetsAreRoutingTools: 'הווידג\'טים הם הכלים לניתוב שמגדילים את שימושיות הבוט',
        needHelp: 'זקוק לעזרה?',
        wantToMakeChanges: 'רוצה לבצע שינויים?',
        editIcon: 'ערוך סמל',
        discard: 'מאסר',
        updateWidget: 'עדכן ווידג\'ט',
        bpDropDown: {
          selectIcons: 'בחר סמלים',
          iconSize: 'גודל סמל',
          iconPlacement: 'מיקום הסמל',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'הגדרות בוט טלגרם',
      chatFlowsHeader: 'זרמי צ\'אט',
      telegramProfileHeader: 'פרופיל טלגרם',
      widgetsHeader: 'ווידג\'טים',
      broadcastHeader: 'שידור',
      dripCampaignHeader: 'מסע טפטוף',
      generalHeader: 'כללי',
      telegramAutomation: 'הגדרות בוט טלגרם',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'צור מסע טפטוף להתנחלות לקוחות על ידי שידור זרמי צ\'אט מתוזמנים.',
        TOOLTIP_CHAT_FLOW_TITLE: 'התאם את תבניות זרמי הצ\'אט וצור מספר זרמי צ\'אט שונים לקהלי יעד שונים.',
      },
      broadcasts: {
        createBroadcast: 'צור שידור',
        youCanCreateMutlipleBroadcasts: 'אפשר ליצור מספר שידורים',
        broadcastName: 'שם השידור',
        broadcastNameIsRequired: 'שם השידור הוא שדה חובה',
        continue: 'המשך',
        manageBroadcasts: 'ניהול שידורים',
        youCanCreateMultipleBroadcastsWith: 'אפשר ליצור מספר שידורים עם מספר תבניות מתוזמנות לקטגוריות לקוחות שונות.',
        createNewBroadcast: 'צור שידור חדש',
        name: 'שם',
        segment: 'קְבוּצָה',
        template: 'תבנית',
        contacts: 'אנשי קשר',
        send: 'שלח',
        failed: 'נכשל',
        broadcastAt: 'שידור בתאריך',
        status: 'מצב',
        actions: 'פעולות',
        nA: 'לא זמין',
        deleteBroadcast: 'מחק שידור',
        wantToDeleteThisBroadcast: 'האם אתה בטוח שברצונך למחוק את השידור הזה?',
        placeholders: {
          enterBroadcastName: 'הזן שם לשידור',
          searchHere: 'חפש כאן',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'מחק מספר שידורים',
          deleteBroadcast: 'מחק שידור',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'שם השידור',
        broadcastNameIsRequired: 'שם השידור הוא שדה חובה',
        flow: 'זרימה',
        segments: 'קטגוריות',
        whenToSend: 'מתי לשלוח',
        selectDate: 'בחר תאריך',
        selectTimeSlot: 'בחר חלון זמן',
        cancel: 'ביטול',
        createBroadcast: 'צור שידור',
        now: 'כעת',
        later: 'מאוחר יותר',
        placeholders: {
          enterBroadcastName: 'הזן שם לשידור',
          selectFlow: 'בחר זרימה',
          searchFlowByName: 'חפש זרימה לפי שם',
          selectSegments: 'בחר קטגוריות',
          searchSegmentByName: 'קבץ לפי שם',
          selectTimeSlot: 'בחר חלון זמן',
        },
        indexMergeDatePicker: {
          selectStartDate: 'בחר תאריך התחלה',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'צור מסע טפטוף',
        youCanCreateMultipleCampaigns: 'אתה יכול ליצור מספר מסעות טפטוף',
        dripCampaignName: 'שם מסע הטפטוף',
        dripCampaignNameIsRequired: 'שם מסע הטפטוף הוא שדה חובה',
        continue: 'המשך',
        manageDripCampaign: 'ניהול מסעות טפטוף',
        youCanCreateMultipleDripCampaignsWith: 'אתה יכול ליצור מספר מסעות טפטוף עם הודעות מתוזמנות ואוטומטיות לגרום לתגובות מקטגוריות משתמשים מסוימות.',
        createNewDripCampaign: 'צור מסע טפטוף חדש',
        campaignName: 'שם הקמפיין',
        appliedOn: 'נמצא בתוך',
        segment: 'קְבוּצָה',
        flow: 'זרימה',
        startDate: 'תאריך התחלה',
        action: 'פעולה',
        nA: 'לא זמין',
        deleteDripCampaign: 'מחק מסעי טפטוף',
        wantToDeleteThisDripCampaign: 'האם אתה בטוח שברצונך למחוק מסעי טפטוף אלו?',
        placeholders: {
          enterDripCampaignName: 'הזן שם למסע הטפטוף',
          searchByName: 'חפש לפי שם',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'מחק מספר מסעות טפטוף',
          deleteDripCampaign: 'מחק מסע טפטוף',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'שם מסע הטפטוף',
          appliedOn: 'נמצא בתוך',
          segments: 'קטגוריות',
          startDate: 'בחר תאריך',
          scheduleYourFlows: 'תזמן את זרמי הצ\'אט שלך',
          schedule: 'זמנן',
          flow: 'זרימה',
          sendAfter: 'שלח אחרי',
          cancel: 'ביטול',
          createDripCampaign: 'צור מסע טפטוף',
          newSubscriber: 'מנוי חדש',
          days: 'ימים',
          minutes: 'דקות',
          hours: 'שעות',
          placeholders: {
            enterDripName: 'הזן שם למסע הטפטוף',
            selectAppliedOn: 'בחר את המקום בו יישמע',
            selectSegment: 'בחר קְבוּצָה',
            searchByName: 'חפש לפי שם',
            selectFlow: 'בחר זרימה',
            searchFlowByName: 'חפש לפי שם הזרימה',
          },
          dateTimePicker: {
            selectStartDate: 'בחר תאריך התחלה',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'שם',
        email: 'אימייל',
        phone: 'טלפון',
        errorCode: 'קוד שגיאה',
        errorMessage: 'הודעת שגיאה',
        nA: 'לא זמין',
      },
      flows: {
        chatFlows: 'זרמי צ\'אט',
        createChatFlow: 'צור זרם צ\'אט',
        youCanCreateMultipleChatFlows: 'ניתן ליצור מספר זרמי שיחה במספר ערוצים לצורך בדיקות A/B ושידורים.',
        flowName: 'שם הזרם',
        nameIsRequired: 'שדה השם הוא שדה חובה',
        cancel: 'ביטול',
        createFlow: 'צור זרם',
        importFlow: 'ייבא זרם',
        createNewFlow: 'צור זרם חדש',
        ofMessages: ' מהודעות',
        createdOn: 'נוצר בתאריך',
        lastModified: 'שונה לאחרונה בתאריך',
        defaultFlow: 'זרם ברירת מחדל',
        action: 'פעולה',
        exportStatus: 'סטטוס ייצוא',
        flowExport: 'ייצוא זרם',
        inProgress: 'בתהליך',
        available: 'זמין',
        initiated: 'הופעל',
        download: 'הורדה',
        beforeYouStartUploadPleaseMakeSure: 'לפני שתתחיל בהעלאה, אנא וודא ש:',
        point1: '1. הגודל המרבי להעלאת קובץ הוא 20 מגה-בייט',
        point2: '2. פורמט הקובץ צריך להיות בפורמט JSON',
        selectFile: 'בחר קובץ',
        placeholders: {
          enterFlowName: 'הזן שם לזרם',
        },
        appTooltip: {
          importFlowForTelegramBot: 'ייבא זרם לבוט האינסטגרם',
          dateAndTimeOfCreationOfChatFlow: 'תאריך ושעת יצירת הזרם הצ\'אט',
          dateAndTimeWhenChatFlowLastModified: 'תאריך ושעת שינוי אחרון בזרם הצ\'אט',
          TOOLTIP_CREATE_FLOW: 'לחץ כדי ליצור זרם צ\'אט חדש',
          TOOLTIP_FLOW_NAME: 'הזן את שם הזרם הצ\'אט',
          TOOLTIP_FLOW_TABLE_NAME: 'שם הזרם הצ\'אט',
          TOOLTIP_NO_OF_MESSAGES: 'מספר מרכיבי ההודעות בזרם הצ\'אט',
          TOOLTIP_DEFAULT_FLOW: 'השתמש במתג זה כדי להגדיר זרם צ\'אט כברירת מחדל, שישמש את כל המשתמשים החדשים.',
          TOOLTIP_ACTION: 'ערוך או מחק את זרם הצ\'אט',
        },
        noDataFound: {
          flows: 'זרמים',
          noFlowsAddedYet: 'טרם הוספת זרמים!',
        },
        modal: {
          deleteFlow: 'מחק זרם',
          wantToDeleteThisFlow: 'האם אתה בטוח שברצונך למחוק את הזרם הזה?',
        }
      },
      telegramProfile: {
        telegramProfile: 'פרופיל Telegram',
        manageYourBotProfile: 'ניהול פרופיל הבוט שלך בכל עת',
        allSetNoActionRequired: 'הכל מוכן, אין פעולות נדרשות!',
        congratulationYourAccountIsLive: 'מזל טוב! החשבון שלך פעיל ויש לך גישה מלאה',
        botDetails: 'פרטי הבוט',
        name: 'שם',
        nA: 'לא זמין',
        b: 'B',
        botName: 'שם הבוט',
        bot: 'בוט',
        info: 'מידע',
        none: 'אין',
        username: 'שם משתמש',
        notifications: 'התראות',
        on: 'פתח',
        addToGroupOrChannel: 'הוסף לקְבוּצָה או ערוץ',
        userName: 'שם משתמש',
        botToken: 'אסימון הבוט',
      },
      widgets: {
        manageWidgets: 'ניהול ווידג\'טים',
        widgetsAreRoutingTools: 'הווידג\'טים הם כלי ניתוב להגברת השימוש בבוט',
        needHelp: 'זקוק לעזרה?',
        wantToMakeChanges: 'רוצה לבצע שינויים?',
        editIcon: 'ערוך סמל',
        discard: 'בטל',
        updateWidget: 'עדכן ווידג\'ט',
        installChatBotIconOnYourWebsite: 'התקן אייקון שיחת בוט באתר האינטרנט שלך',
        shareWhereEverYouWantRedirectUsersTo: 'שתף את הלינק בכל מקום והפנה משתמשים לבוט שלך בלחיצה אחת',
        copyAndAddInYourWebsiteHeaderFile: 'העתק והוסף לקובץ כותרת האתר שלך',
        copyLinkAndStartSharing: 'העתק את הלינק והתחל לשתף!',
        downloadQrCodeAndStartSharing: 'הורד את קוד ה-QR והתחל לשתף!',
        addCodeAndDeployNewCodeAndDone: 'הוסף את הקוד, השתמש בקוד חדש וסיים!',
        wantToLearnHowToUseIt: 'רוצה לדעת איך להשתמש בזה?',
        copyChatBotCode: 'העתק קוד הבוט',
        downloadQrCode: 'הורד קוד QR',
        copyTelegramLink: 'העתק לינק לטלגרם',
        link: 'לינק',
        chatBotIcon: 'אייקון שיחת הבוט',
        qrcode: 'קוד QR',
        small: 'קטן',
        medium: 'בינוני',
        large: 'גדול',
        leftMiddle: 'שמאל-אמצע',
        rightMiddle: 'ימין-אמצע',
        leftBottom: 'שמאל-תחתון',
        rightBottom: 'ימין-תחתון',
        bpDropDown: {
          selectIcons: 'בחר סמלים',
          iconSize: 'גודל האייקון',
          iconPlacement: 'מיקום האייקון',
        }
      },
      generalSetting: {
        sendInactivityMessage: 'הודעת חוסר פעילות של המשתמש',
        sessionTimeOutInHours: 'זמן תפוגה של הסשן בשעות',
        enterSessionTimeOutWindowInHours: 'הזן חלון זמן תפוגה בשעות',
        sessionTimeOutInHoursTooltip: 'הזן את המשך הזמן לאחרו הסשן יפסיק באופן אוטומטי עקב חוסר פעילות. הזמן ייחשב מחדש אם המשתמש שולח הודעה אחרי שהסשן פסק.',
        restartChatFlowTriggers: 'טריגרים לאיפוס זרימת השיחה',
        enterKeyWordsToRestartChatAndhitEnter: 'הזן מילות מפתח לאיפוס השיחה ולחץ Enter',
        restartChatFlowTriggersTooltip: 'ציין מילות מפתח כאן שכאשר המשתמש מזין אותן ומגיב, זה יגרום לאיפוס של זרימת השיחה. זה מאפשר למשתמשים לאפס את השיחה בכל שלב. לחץ על Enter לאחר כל מילת מפתח כדי להוסיף מילות מפתח מרובות.',
        liveChatTriggers: 'טריגרים לצ\'אט חי',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'הזן מילות מפתח לטריגר צ\'אט חי ולחץ Enter',
        liveChatTriggersTooltip: 'הגדר מילות מפתח שכאשר המשתמש מזין אותן ומגיב, זה יפעיל מעבר מצ\'אט אוטומטי לצ\'אט חי. זה שימושי כאשר המשתמשים זקוקים לעזרה מסוכן חי. לחץ על Enter לאחר כל מילת מפתח כדי להוסיף מילות מפתח מרובות.',
        messageAfterAutomatedFlowCompleted: 'הודעה לאחר השלמת זרימת השיחה האוטומטית',
        enterYourMessage: 'הזן את ההודעה שלך',
        messageAfterAutomatedFlowCompletedTooltip: 'הזן הודעה שתוצג למשתמש לאחר שהוא השלים את זרימת השיחה האוטומטית אך הסשן לא התפג. ',
        restart: 'אפס',
        liveChat: 'צ\'אט חי',
        lineOneForDefaultMessage: 'אם יש לך שאלות נוספות או צריך עזרה, הנה מה שאתה יכול לעשות:',
        to: 'כדי ל',
        restartTheChat: 'אפס את השיחה',
        simpleTypeRestartAndHitEnter: 'פשוט הקלד "אפס" ולחץ Enter. זה יחזיר אותך להתחלת זרימת השיחה שלנו.',
        lineThreeForDefaultMessagePartOne: 'אם תרצה לדבר עם',
        liveAgent: 'סוכן חי,',
        lineThreeForDefaultMessageLastPart: 'הקלד "צ\'אט חי" ולחץ Enter. צוותנו כאן לעזור לך בזמן אמת.',
        saveChanges: 'שמור שינויים',
        discard: 'בטל',
        configureBotsGeneralSettings: 'הגדר את הגדרות הגנרל של הבוט שלך',
        minutes: 'דקות',
        hours: 'שעות',
      }
    },
    whatsapp: {
      whatsappAutomation: 'אוטומציה ב-WhatsApp',
      whatsappBotSettings: 'הגדרות הבוט ב-WhatsApp',
      generalHeader: 'כללי',
      chatFlowsHeader: 'זרמי שיחה',
      whatsappProfileHeader: 'פרופיל WhatsApp',
      broadcastHeader: 'שידור',
      dripCampaignHeader: 'קמפיין טיפטוף',
      widgetHeader: 'ווידג\'ט',
      templatesHeader: 'תבניות',
      alertsHeader: 'התראות',
      apiKeyHeader: 'מפתח API',
      catalogHeader: 'קטלוג',
      otherConfiguration: 'הגדרות AI',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'התאם את תבניות זרמי השיחה וצור זרמי שיחה מרובים לקהלים שונים.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'הגדר את התצורה של ההתראות של הבוט',
        specifyHowYouBotWillNotify: 'ציין איך הבוט יודיע לך על לידים חדשים.',
        subject: 'נושא',
        emailNotification: 'התראת דוא"ל',
        emailAddresses: 'כתובות דוא"ל',
        leadRevisitNotifications: 'התראות לידים שחוזרים',
        whenToSendLeadInfo: 'מתי לשלוח מידע על ליד',
        discard: 'בטל',
        saveChanges: 'שמור שינויים',
        seeHowToUse: 'ראה איך להשתמש',
        beginningTheFlow: 'תחילת הזרם',
        fiveMinutesLater: '5 דקות מאוחר יותר',
        endOfFlow: 'סיום הזרם',
        placeholders: {
          enterSubjectForYourEmail: 'הזן נושא לדוא"ל שלך',
          typeEmailAndHitEnter: 'הזן את כתובת הדוא"ל שלך ולחץ Enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'התאמת נושא ההתראות בדוא"ל מהבוט',
          TOOLTIP_EMAIL_NOTIFICATION: 'הפעל/בטל את התראת הדוא"ל של הבוט על לידים חדשים',
          TOOLTIP_EMAIL_ADDRESS: 'הזן כתובת דוא"ל לחשבון שברצונך לקבל בו התראות. ניתן להוסיף יותר מכתובת דוא"ל אחת על ידי הזנה ולחיצה על Enter להוספת כתובת נוספת.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'הפעל/בטל התראת הדוא"ל של הבוט אם משתמש שכבר הוצבר כליד משתמש בו שוב בבוט.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'עדכן בוט שיחת WhatsApp',
        businessNameIsRequired: 'נדרש שם עסק.',
        maxLengthForBusinessName40Characters: 'האורך המרבי לשם העסק הוא 40 תווים.',
        categoryIsRequired: 'נדרש קְבוּצָה.',
        descriptionIsRequired: 'נדרשת תיאור.',
        maxLengthForDescription200Characters: 'האורך המרבי לתיאור הוא 200 תווים.',
        aboutIsRequired: 'נדרש "אודות".',
        maxLengthForAbout139Characters: 'האורך המרבי לשדה "אודות" הוא 139 תווים.',
        pleaseFillAValidBusinessEmail: 'אנא הזן כתובת דוא"ל עסקית תקנית.',
        businessEmailIsRequired: 'נדרשת כתובת דוא"ל עסקית.',
        websiteIsRequired: 'נדרש אתר אינטרנט.',
        maxLengthForWebsite40Characters: 'האורך המרבי לכתובת האתר הוא 40 תווים.',
        maxLengthForAddress150Characters: 'האורך המרבי לכתובת הוא 150 תווים.',
        cancel: 'בטל',
        update: 'עדכן',
        businessCategory: 'קטגוריית עסק',
        placeholders: {
          yourBusinessName: 'שם העסק שלך',
          businessDescription: 'תיאור העסק',
          about: 'אודות',
          businessEmail: 'דוא"ל עסקי',
          website: 'אתר אינטרנט (לדוגמה www.example.com)',
          address: 'כתובת',
        }
      },
      flows: {
        chatFlows: 'זרמי שיחה',
        createChatFlow: 'צור זרם שיחה',
        youCanCreateMultipleChatFlows: 'ניתן ליצור מספר זרמי שיחה במספר ערוצים שונים לצורך בדיקות A/B ושידורים.',
        flowName: 'שם הזרם',
        nameIsRequired: 'שדה השם הוא שדה חובה',
        cancel: 'ביטול',
        createFlow: 'צור זרם',
        importFlow: 'ייבא זרם',
        createNewFlow: 'צור זרם חדש',
        ofMessages: ' מסרים',
        createdOn: 'נוצר בתאריך',
        lastModified: 'שונה לאחרונה',
        defaultFlow: 'זרם ברירת מחדל',
        revisitFlow: 'זרם למבקרים חוזרים',
        action: 'פעולה',
        exportStatus: 'סטטוס ייצוא',
        flowExport: 'ייצוא זרם',
        // importFlow: 'ייבוא זרם',
        beforeYouStartUploadPleaseMakeSure: 'לפני שתתחיל בהעלאה, אנא וודא ש:',
        point1: '1. הגודל המרבי להעלאת קובץ הוא 20 מגה-בייט',
        point2: '2. פורמט הקובץ צריך להיות בפורמט JSON',
        selectFile: 'בחר קובץ',
        seeHowToUse: 'ראה איך להשתמש',
        download: 'הורדה',
        available: 'זמין',
        initiated: 'נשלח',
        inProgress: 'בתהליך',
        placeholders: {
          enterFlowName: 'הזן את שם הזרם',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'ייבא זרם עבור בוט WhatsApp',
          dateAndTimeOfCreationOfChatFlow: 'תאריך ושעת יצירת הזרם השיחה',
          dateAndTimeWhenChatFlowLastModified: 'תאריך ושעת שינוי הזרם השיחה האחרון',
          TOOLTIP_CREATE_FLOW: 'לחץ כאן כדי ליצור זרם שיחה חדש',
          TOOLTIP_FLOW_NAME: 'הזן שם לזרם השיחה',
          TOOLTIP_FLOW_TABLE_NAME: 'שם הזרם בטבלה',
          TOOLTIP_NO_OF_MESSAGES: 'מספר הרכיבים בזרם השיחה',
          TOOLTIP_DEFAULT_FLOW: 'השתמש בטוגל הזה כדי להגדיר את הזרם השיחה כזרם ברירת המחדל, שישמש לכל המשתמשים החדשים.',
          TOOLTIP_REVISIT_FLOW: 'השתמש בטוגל הזה כדי לבחור זרם שיחה שישמש למשתמשים כאשר הם מבקרים בבוט שוב.',
          TOOLTIP_ACTION: 'ערוך או מחק את זרם השיחה',
        },
        modal: {
          deleteFlow: 'מחק זרם',
          wantToDeleteThisFlow: 'האם אתה בטוח שברצונך למחוק את זרם השיחה הזה?',
        },
        noDataFound: {
          flows: 'זרמים',
          noFlowsAddedYet: 'לא נוספו עדיין זרמים!',
        }
      },
      input: {
        manageTemplates: 'ניהול תבניות',
        manageYourTemplatesForMarketingAutomation: 'ניהול התבניות שלך לאוטומציה שיווקית',
        syncTemplates: 'סנכרן תבניות',
        createTemplateHeader: 'צור תבנית',
        title: 'כותרת',
        info: 'מידע',
        category: 'קְבוּצָה',
        status: 'סטטוס',
        action: 'פעולה',
        shipmentUpdate: 'עדכון משלוח',
        name: 'שם',
        headerOptional: 'כותרת (אופציונלי)',
        bodyRequired: 'גוף (חובה)',
        footerOptional: 'כותרת תחתונה (אופציונלי)',
        buttonsOptional: 'כפתורים (אופציונלי)',
        makeUpto3Buttons: 'אפשר ליצור עד 3 כפתורים',
        makeUpto2ButtonsToRedirect: 'אפשר ליצור עד 2 כפתורים להפנית לקוח לאתר האינטרנט שלך',
        placeholders: {
          nameYourTemplate: 'הזן שם לתבנית שלך (השתמש בקו תחתון להבחנה בין מילים)',
          enterText: 'הזן טקסט (עד 60 תווים)',
          enterImageUrl: 'הזן כתובת URL לתמונה',
          enterVideoUrl: 'הזן כתובת URL לסרטון',
          templateMessage: 'הודעת התבנית',
          smallFooterAtBottom: 'תחתית קטנה בתחתית',
          buttons: 'כפתורים',
          text: 'טקסט',
          numberWithCountryCode: 'מספר עם קוד מדינה',
          enterButtonText: 'הזן טקסט לכפתור (עד 20 תווים)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'כותרת של התבנית ב-WhatsApp',
          TOOLTIP_INPUT_TABLE_INFO: 'מידע מפורט על הודעות שנשלחות מהעסק',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'סוג הודעה מהעסק, כמו פגישות, תזכורות, הודעות שירות לקוחות, עדכוני משלוח ועוד',
          TOOLTIP_INPUT_TABLE_STATUS: 'סטטוס התבנית - האם התבנית אושרה או נדחתה על ידי WhatsApp. האישור של התבניות על ידי WhatsApp הוא שירות בתשלום.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'מחק את התבנית',
        },
        noDataFound: {
          templates: 'תבניות',
          noTemplatesCreatedYet: 'לא נוצרו עדיין תבניות!',
        },
        modal: {
          deleteTemplate: 'מחק תבנית',
          wantToDeleteThisTemplate: 'האם אתה בטוח שברצונך למחוק את התבנית הזו?',
        },
        createTemplate: {
          createTemplates: 'צור תבניות',
          createTemplatesForMarketingAutomation: 'צור תבניות לאוטומציה שיווקית',
          templateMeta: 'תבנית מטא',
          selectLanguage: 'בחר שפה',
          header: 'כותרת',
          optional: 'אופציונלי',
          samplesForHeaderContent: 'דוגמאות לתוכן הכותרת',
          toHelpMetaReviewYourContent: 'כדי לסייע ל-Meta לבדוק את התוכן שלך, ספק דוגמאות למשתנים או מדיה בכותרת. אל תכלול מידע אישי של הלקוח.',
          body: 'גוף',
          addVariable: 'הוסף משתנה',
          samplesForBodyContent: 'דוגמאות לתוכן הגוף',
          toHelpUsReviewYourContent: 'כדי לסייע לנו לבדוק את התוכן שלך, ספק דוגמאות למשתנים בגוף ההודעה. אל תכלול מידע אישי של הלקוח.',
          footer: 'כותרת תחתונה',
          buttons: 'כפתורים',
          typeOfAction: 'סוג פעולה',
          call: 'טלפון',
          phone: 'טלפון',
          website: 'אתר אינטרנט',
          static: 'סטטי',
          urlType: 'סוג URL',
          type: 'סוג',
          customButton: 'כפתור מותאם',
          addAnotherButton: 'הוסף כפתור נוסף',
          preview: 'תצוגה מקדימה',
          test: 'מבחן',
          create: 'צור',
          addSampleUrl: 'הוסף כתובת URL לדוגמה',
          toHelpMetaReviewYourMessage: 'כדי לסייע ל-Meta לבדוק את תבנית ההודעה שלך, אנא הוסף דוגמא לכתובת האתר. אל תשתמש במידע אישי אמיתי של הלקוח.',
          placeholders: {
            enterMessageTemplateName: 'הזן שם לתבנית ההודעה שלך',
            searchTemplateByName: 'חפש תבנית לפי שם',
            selectTemplate: 'בחר תבנית',
            enterText: 'הזן טקסט',
            enterBody: 'הזן גוף',
            enterContentForVariable: 'הזן תוכן למשתנה',
            enterFooter: 'הזן כותרת תחתונה',
            phone: 'טלפון*',
            enterWebsiteUrl: 'הזן כתובת URL לאתר האינטרנט',
          },
          bpInput: {
            name: 'שם',
            buttonText: 'טקסט לכפתור',
            websiteUrl: 'כתובת אתר האינטרנט',
          },
          bpDropDown: {
            selectCategory: 'בחר קְבוּצָה',
          }
        },
        templatePreview: {
          preview: 'תצוגה מקדימה',
          test: 'מבחן',
        },
        templateConfig: {
          saveChanges: 'שמור שינויים',
          discard: 'סגור',
          triggerChatFlowOnTemplateReply: 'הפעל סירוב צ\'אט על תבנית מענה',
          templateDetails: 'פרטי התבנית',
          configureFlows: 'הגדרת הזרימות',
          selectFlow: 'בחר הזרמה',
          name: 'שם'
        }
      },
      shopManagement: {
        shopManagement: 'ניהול חנות',
        manageECommerceShopFromBotPenguin: 'ניהול חנויות מקוונות מחוברות ל-BotPenguin',
        comingSoon: '( בקרוב )',
        wooCommerce: {
          createConfiguration: 'צור הגדרה',
          eventConfiguration: {
            createConfiguration: 'צור הגדרה',
            templateHeader: 'כותרת התבנית',
            selectFile: 'בחר קובץ',
            templateMessage: 'הודעת התבנית',
            saveChanges: 'שמור שינויים',
            bpDropDown: {
              selectEvent: 'בחר אירוע',
              selectTemplate: 'בחר תבנית',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'פרופיל WhatsApp',
        manageYourBotProfile: 'ניהול פרופיל הבוט שלך בכל עת.',
        allSetNoActionRequired: 'הכל מוכן, אין צורך בפעולה!',
        congratulationsYourAccountIsLive: 'ברכותינו! החשבון שלך פעיל ונכנס מלא.',
        somethingIsWrongReactivateYourBot: 'משהו לא בסדר, הפעל מחדש את הבוט שלך!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'נראה כי הבוט שלך לא עובד כראוי, נא לאפשר מחדש את הבוט שלך',
        reConfigureYourBot: 'הגדר את הבוט מחדש',
        phoneNumberDetails: 'פרטי מספר הטלפון',
        phoneNumber: 'מספר הטלפון',
        nA: 'N/A',
        phoneNumberId: 'מזהה מספר הטלפון',
        wabaId: ' מזהה וואבא',
        change: ' שנה',
        accessToken: 'טוקן גישה',
        messagingLimit: 'הגבלת הודעות',
        qualityRating: 'דירוג איכות',
        whatsappBusinessAccountName: 'שם חשבון עסקי ב-WhatsApp',
        whatsappPublicProfile: 'פרופיל ציבורי ב-WhatsApp',
        profilePicture: 'תמונת פרופיל',
        aboutText: 'טקסט אודות',
        address: 'כתובת',
        description: 'תיאור',
        email: 'אימייל',
        websitePrimary: 'אתר (ראשי)',
        websiteSecondary: 'אתר (משני)',
        businessVertical: 'סוג עסק',
        editProfile: 'ערוך פרופיל',
        thisIsABusinessAccount: 'זהו חשבון עסקי',
        aboutAndPhoneNumber: 'אודות ומספר טלפון',
        yourTrialAccountIsLive: 'ברכותינו! החשבון הניסי שלך פעיל ונכנס מלא על מספר הטלפון המאומת שלך',
        updateWithNewAccessToken: 'עדכן עם טוקן גישה חדש',
      },
      widgets: {
        manageWidgets: 'נהל ווידג\'טים',
        widgetsAreRoutingTools: 'הווידג\'טים הם כלי הניתוב להגברת השימוש בבוט',
        needHelp: 'זקוק לעזרה?',
        wantToMakeChanges: 'רוצה לבצע שינויים?',
        prePopulatedMessage: 'הודעה מוכנה מראש',
        discard: 'בטל',
        updateWidget: 'עדכן ווידג\'ט',
        hiThere: 'שלום, יש לך שאלה?',
        seeHowToUse: 'ראה איך להשתמש',
        editIcon: 'ערוך אייקון',
        editPrePopulatedMessage: 'ערוך הודעה מוכנה מראש',
        small: 'קטן',
        medium: 'בינוני',
        large: 'גדול',
        leftMiddle: 'מרכז שמאלי',
        rightMiddle: 'מרכז ימני',
        leftBottom: 'תחתית שמאלית',
        rightBottom: 'תחתית ימנית',
        chatBotIcon: 'אייקון הבוט',
        link: 'קישור',
        qrcode: 'קוד QR',
        copyChatBotCode: 'העתק קוד הבוט',
        copyWALink: 'העתק קישור WhatsApp',
        downloadQrCode: 'הורד קוד QR',
        installChatBotIconOnYourWebsite: 'התקן אייקון הבוט באתר האינטרנט שלך',
        shareWhereEverYouWantRedirectUsersTo: 'שתף את הבוט שלך בכל מקום והפנה משתמשים לבוט WhatsApp שלך בלחיצה אחת',
        copyAndAddInYourWebsiteHeaderFile: 'העתק והוסף בקובץ הכותרת של האתר שלך',
        copyLinkAndStartSharing: 'העתק את הקישור והתחל לשתף!',
        downloadQrCodeAndStartSharing: 'הורד קוד QR והתחל לשתף!',
        addCodeAndDeployNewCodeAndDone: 'הוסף את הקוד, הפרסם את הקוד החדש וסיים!',
        wantToLearnHowToUseIt: 'רוצה ללמוד איך להשתמש בזה?',
        placeholders: {
          enterWhatsappNumber: 'הזן מספר WhatsApp',
          updateYourMessageHere: 'עדכן את ההודעה שלך כאן...',
        },
        bpDropDown: {
          selectIcons: 'בחר אייקונים',
          iconSize: 'גודל האייקון',
          iconPlacement: 'מיקום האייקון',
        },
        bpInput: {
          whatsappNumber: 'מספר WhatsApp',
        }
      },

      catalog: {
        createCatalog: 'צור מצגת',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'צור מצגת מוצרים ב- Meta Commerce Manager או התחבר אליה דרך Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'השתמש ב- Meta Commerce Manager שלך כדי לנהל מצגות',
        connectCatalogToWhatsAppAccount: 'הקשר מצגת לחשבון WhatsApp',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'הקשר את המצגות שלך לחשבון WhatsApp דרך מנהל ה-WhatsApp שלך',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'השתמש ב- Meta WhatsApp Manager שלך כדי להתחבר למצגות ולהשתמש בהן עם BotPenguin',
        goToMeta: 'עבור ל-Meta',
        goToShopify: 'עבור ל-Shopify',
        next: 'הבא',
        back: 'חזור',
        setupCatalog: 'הגדר מצגת',
        completeAllTheStepsToEnableCatalogMessages: 'השלם את כל השלבים כדי לאפשר הודעות מצגות',
      }, generalSetting: {
        sendInactivityMessage: 'הודעת חוסר פעילות של המשתמש',
        sessionTimeOutInHours: 'זמן תפוגה של הסשן בשעות',
        enterSessionTimeOutWindowInHours: 'הזן חלון זמן תפוגה בשעות',
        sessionTimeOutInHoursTooltip: 'הזן את המשך הזמן לאחרו הסשן יפסיק באופן אוטומטי עקב חוסר פעילות. הזמן ייחשב מחדש אם המשתמש שולח הודעה אחרי שהסשן פסק.',
        restartChatFlowTriggers: 'טריגרים לאיפוס זרימת השיחה',
        enterKeyWordsToRestartChatAndhitEnter: 'הזן מילות מפתח לאיפוס השיחה ולחץ Enter',
        restartChatFlowTriggersTooltip: 'ציין מילות מפתח כאן שכאשר המשתמש מזין אותן ומגיב, זה יגרום לאיפוס של זרימת השיחה. זה מאפשר למשתמשים לאפס את השיחה בכל שלב. לחץ על Enter לאחר כל מילת מפתח כדי להוסיף מילות מפתח מרובות.',
        liveChatTriggers: 'טריגרים לצ\'אט חי',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'הזן מילות מפתח לטריגר צ\'אט חי ולחץ Enter',
        liveChatTriggersTooltip: 'הגדר מילות מפתח שכאשר המשתמש מזין אותן ומגיב, זה יפעיל מעבר מצ\'אט אוטומטי לצ\'אט חי. זה שימושי כאשר המשתמשים זקוקים לעזרה מסוכן חי. לחץ על Enter לאחר כל מילת מפתח כדי להוסיף מילות מפתח מרובות.',
        messageAfterAutomatedFlowCompleted: 'הודעה לאחר השלמת זרימת השיחה האוטומטית',
        enterYourMessage: 'הזן את ההודעה שלך',
        messageAfterAutomatedFlowCompletedTooltip: 'הזן הודעה שתוצג למשתמש לאחר שהוא השלים את זרימת השיחה האוטומטית אך הסשן לא התפג. ',
        restart: 'אפס',
        liveChat: 'צ\'אט חי',
        lineOneForDefaultMessage: 'אם יש לך שאלות נוספות או צריך עזרה, הנה מה שאתה יכול לעשות:',
        to: 'כדי ל',
        restartTheChat: 'אפס את השיחה',
        simpleTypeRestartAndHitEnter: 'פשוט הקלד "אפס" ולחץ Enter. זה יחזיר אותך להתחלת זרימת השיחה שלנו.',
        lineThreeForDefaultMessagePartOne: 'אם תרצה לדבר עם',
        liveAgent: 'סוכן חי,',
        lineThreeForDefaultMessageLastPart: 'הקלד "צ\'אט חי" ולחץ Enter. צוותנו כאן לעזור לך בזמן אמת.',
        saveChanges: 'שמור שינויים',
        discard: 'בטל',
        configureBotsGeneralSettings: 'הגדר את הגדרות הגנרל של הבוט שלך',
        minutes: 'דקות',
        hours: 'שעות',
      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'רק צעד אחד',
      seeHowToUse: 'ראה איך להשתמש',
      testWhatsAppTrialBot: 'בדוק את הצ\'אט בוט שלך בוואטסאפ',
      sendMessage: 'שלח הודעה',
      sendWhatsAppMessageOn: 'שלח לנו הודעה בוואטסאפ למספר ',
      bySavingInYourContact: 'על ידי שמירה באנשי הקשר שלך וההודעה צריכה להתחיל ב-',
      connect: 'התחבר',
      or: 'או',
      scanQRCode: 'סרוק את קוד ה-QR',
      yourPhoneWillOpen: 'הטלפון שלך יפתח הודעה מוכנה לשליחה למספר הסנדבוק שלנו. לחץ על "שלח" בוואטסאפ',
      sendVerificationCode: 'שלח קוד אימות',
      copyAndEnterCode: 'העתק והזן קוד שש ספרות',
      shareWhereYouWant: 'שתף בכל מקום והפנה משתמשים לבוט הוואטסאפ שלך בלחיצה אחת',
      youShouldHaveReceived: 'אתה צריך לקבל קוד אימות של שש ספרות במספר הוואטסאפ שלך',
      EnterYourPhoneNumber: 'הזן את מספר הטלפון שלך',
      send: 'שלח',
      sixDigitCode: 'הזן קוד שש ספרות',
      pleaseNote: 'שימו לב: קוד אימות בן שש ספרות עשוי לקחת עד שלוש דקות, אנא המתינו לפני שאתם שולחים אותו שוב.',
      resendVerificationCode: 'שלח מחדש קוד אימות',
      verify: 'אמת',
      providePhoneNumber: 'אנא הזן את המספר שבו ברצונך לבדוק',
      demoBotisReady: 'נהדר! הבוט הדמו שלך מוכן לשימוש',
      placeHolder: {
        phone: 'הזן את מספר הטלפון שלך',
        code: 'הזן את קוד האימות',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'אינטגרציה עם אפליקציות צד שלישי',
    thirdPartyApps: 'אפליקציות צד שלישי',
    customApps: 'אפליקציות מותאמות אישית',
    preview: 'תצוגה מקדימה',
    test: 'מבחן',
    install: 'התקן',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'לחץ כאן כדי להתקין את הבוט שלך בפלטפורמה הנבחרת',
      TOOLTIP_MY_INTEGRATION_TITLE: 'אינטגרציה של אפליקציות מותאמות אישית עם הבוט שלך',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'חבר את הבוט שלך לאפליקציות / מערכות CRM מוכרות',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'אינטגרציות לאפליקציות צד שלישי',
        integrateYourBotWithListedCrms: 'אינטגרציה של הבוט שלך עם אחת מהאפליקציות / מערכות CRM הרשומות.',
        help: 'עזרה',
        name: 'שם',
        status: 'סטטוס',
        action: 'פעולה',
        chatGpt: 'ChatGPT',
        addOn: 'תוספת',
        purchased: 'נרכש',
        readyToRideGptWave: 'מוכן לשוטט בגל ה-GPT? השתלב כעת.',
        purchase: 'רכוש',
        notConnected: 'לא מחובר',
        integrated: 'משולב',
        configure: 'קבע הגדרות',
        connected: 'מחובר',
        edit: 'ערוך',
        connect: 'התחבר',
        requestYourIntegration: 'בקש את האינטגרציה שלך',
        toRequestNewIntegration: 'כדי לבקש אינטגרציה חדשה, הזן את הפרטים שלך למטה ונחזור אליך בקרוב.',
        emailId: 'כתובת דוא"ל',
        integrationName: 'שם האינטגרציה',
        contactNumber: 'מספר טלפון',
        howSoonYouWantToAddThisIntegration: 'כמה מהר ברצונך להוסיף את האינטגרציה הזו',
        sendRequest: 'שלח בקשה',
        cancel: 'ביטול',
        requestSent: 'בקשה נשלחה!',
        yourRequestSuccessfullySent: 'הבקשה שלך לאינטגרציה חדשה נשלחה בהצלחה.',
        goBack: 'חזור',
        disconnect: 'נתק',
        OopsIntegrationAreNotAvailable: 'אופס! השתלות לא זמינות ב-',
        pleaseUpgradeTo: ' תוכנית. אנא שדרג אל ',
        planForIntegration: ' תוכנית לשתלות.',
        placeholders: {
          searchIntegrationByName: 'חפש אינטגרציה לפי שם',
          enterTeamMemberName: 'הזן שם חבר צוות',
          enterEmailId: 'הזן כתובת דוא"ל',
          enterIntegrationName: 'הזן שם אינטגרציה',
          searchCountry: 'חפש מדינה',
          enterContactNumber: 'הזן מספר טלפון',
          addComment: 'הוסף הערה',
        },
        noDataFound: {
          integrations: 'אינטגרציות',
          noIntegrationsFound: 'לא נמצאו אינטגרציות!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'שם האפליקציה / CRM',
          statusAsConnectedOrNotConnected: 'סטטוס כמחובר / לא מחובר',
          clickToConnectToAppOrCrm: 'לחץ כאן כדי להתחבר לאפליקציה או ל-MRM',
        },
        feedbackModal: {
          submit: 'שלח',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, אתה יכול להגדיר אותה.',
          agileCrmDomain: 'דומיין של Agile CRM',
          nameIsRequired: 'שם נדרש',
          emailAddress: 'כתובת דוא"ל',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          testLead: 'טסט ליד',
          questionColon: 'שאלה:',
          addParameter: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'example@company.extention',
            enterTokenHere: 'הזן כאן את האסימון',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, אתה יכול להגדיר אותה.',
          apiKey: 'מפתח API',
          accessKey: 'מפתח גישה',
          connect: 'התחבר',
          testLead: 'טסט ליד',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiKeyHere: 'הזן כאן את מפתח ה-API',
            enterAccessKeyHere: 'הזן כאן את מפתח הגישה',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          nameIsRequired: 'שם הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'ליד לבדוק',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          nameColon: 'שם:',
          inboundWebhookUrl: 'כתובת URL של Inbound Webhook',
          nameIsRequired: 'שם הוא שדה חובה',
          leadTitle: 'כותרת ליד',
          source: 'מקור',
          connect: 'התחבר',
          testLead: 'ליד לבדוק',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterInboundWebhookUrl: 'הזן את כתובת URL של Inbound Webhook...',
            enterTitle: 'הזן כותרת',
            enterSource: 'הזן מקור'
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון הכא',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          nameIsRequired: 'שם הוא שדה חובה',
          testLead: 'ליד לבדוק',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          testLead: 'ליד לבדוק',
          connect: 'התחבר',
          selectTheQuestion: 'בחר את השאלה שברצונך לשלוח תשובת משתמש שלה כערך',
          mapWith: 'מפה עם',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API',
            attributes: 'תכונות (מפתח)',
            values: 'ערכים',
          }
        },
        chatGpt: {
          chatGptApiKey: 'מפתח API של ChatGPT (Open AI)',
          chatGpt: 'ChatGPT (Open AI)',
          readyToRideWave: 'מוכן לרכוב על הגל של GPT? תשתלב כעת.',
          doNotHaveAnyApiKeyNeedHelp: 'אין לך מפתח API, צריך עזרה?',
          authenticate: 'אמת',
          nowYouCanAccessChatGpt: 'כעת תוכל לגשת לרכיב Chat GPT בבונה זרם השיחות',
          paymentSuccessful: 'התשלום הצליח!',
          paymentHasBeenCompletedSuccessfully: 'התשלום הושלם בהצלחה. אתה מוכן לאמן את הבוט שלך',
          cancel: 'בטל',
          trainNow: 'אמן עכשיו',
          paymentInProgress: 'תשלום בתהליך...',
          apiKey: 'ChatGPT מפתח API',
          model: 'מוכן',
          integrateWithChatGPT: 'התחבר עם ChatGPT',
          updateKey: 'עדכן מפתח API',
          connect: 'התחבר',
          update: 'עדכן',
          placeholders: {
            chatGptApiKey: 'מפתח API של ChatGPT (Open AI)',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          testLead: 'ליד לבדוק',
          leadId: 'מזהה ליד',
          selectLeadId: 'בחר מזהה ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          emailAddress: 'כתובת דוא"ל',
          leadTitle: 'כותרת הליד',
          apiToken: 'אסימון API',
          nameIsRequired: 'שם הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            exampleEmail: 'דוגמה@חברה.סיומת',
            enterYourLeadTitle: 'הזן את כותרת הליד שלך',
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          dripCrmAccountId: 'מזהה חשבון Drip CRM',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterAccountIdHere: 'הזן מזהה חשבון כאן',
            enterYourTokenHere: 'הזן את האסימון שלך כאן',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apikey: 'מפתח API',
          accessToken: 'אסימון גישה',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          selectParameter: 'בחר פרמטר',
          disconnect: 'נתק',
          update: 'עדכן',
          save: 'שמור',
          placeholders: {
            enterApiKeyHere: 'הזן מפתח API כאן',
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          nameIsRequired: 'שם הוא שדה חובה',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          testLead: 'בדוק ליד',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          authToken: 'אסימון אימות',
          source: 'מקור',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterAuthTokenHere: 'הזן את אסימון האימות כאן',
            enterSourceHere: 'הזן מקור כאן'
          }
        },
        facebookPixel: {
          facebookPixel: 'פייסבוק פיקסל',
          getInsight: 'קבל תובנות שיעשו את עסקך לעסק אנטרפרייז עם אינטגרציית Google Analytics של BotPenguin',
          pixelId: 'מזהה פייסבוק פיקסל',
          connect: 'התחבר',
          disconnect: 'נתק',
          placeholders: {
            enterYourId: 'הזן מזהה פייסבוק פיקסל'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          companyName: 'שם החברה',
          emailAddress: 'כתובת דוא"ל',
          password: 'סיסמה',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapsWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterCompanyName: 'הזן את שם החברה',
            enterEmailAddress: 'הזן כתובת דוא"ל',
            enterPassword: 'הזן סיסמה',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiKeyHere: 'הזן מפתח API כאן',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          flowluDomain: 'דומיין Flowlu',
          apiToken: 'אסימון API',
          title: 'כותרת',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'הזן את אסימון ה-API כאן',
            enterYourLeadTitle: 'הזן את כותרת הליד שלך'
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          freshdeskDomain: 'דומיין Freshdesk',
          apiToken: 'אסימון API',
          nameIsRequired: 'שם נדרש',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'הזן את אסימון ה-API כאן',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          freshworkCrmDomain: 'דומיין Freshwork CRM',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'הזן את אסימון ה-API כאן'
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          mapWith: 'מפתה עם',
          select: 'בחר',
          update: 'עדכן',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API כאן',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          campaignList: 'רשימת קמפיינים',
          selectCampaign: 'בחר קמפיין',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את המפתח כאן',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          getInsights: 'קבל תובנות שיכולות להפוך את העסק שלך לעסק רב-לאומי עם אינטגרציית Google Analytics של BotPenguin',
          measurementId: 'מזהה מדידה',
          connect: 'התחבר',
          disconnect: 'נתק',
          placeholders: {
            enterYourId: 'הזן את המזהה שלך',
          }
        },
        googleCalendar: {
          testLead: 'בדוק ליד',
          name: 'שם:',
          email: 'אימייל:',
          appointmentSummary: 'סיכום פגישה',
          appointmentDescription: 'תיאור פגישה',
          addParameters: 'הוסף פרמטרים',
          questionColon: 'שאלה:',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          pause: 'השהייה',
          resume: 'המשך',
          disconnect: 'נתק',
          reconnect: 'התחבר מחדש',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          name: 'שם:',
          email: 'אימייל:',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
        },
        googleRedirect: {

        },
        googleSheet: {
          testLead: 'בדוק ליד',
          title: 'כותרת',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          update: 'עדכן',
          save: 'שמור',
          pause: 'השהייה',
          resume: 'המשך',
          disconnect: 'נתק',
          reconnect: 'התחבר מחדש',
          placeholders: {
            mappingValues: 'ערכי מיפוי',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          name: 'שם:',
          email: 'אימייל:',
          taskList: 'רשימת משימות',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          pause: 'השהייה',
          resume: 'המשך',
          disconnect: 'נתק',
          reconnect: 'התחבר מחדש',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          select: 'בחר',
          addParameters: 'הוסף פרמטרים',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiHere: 'הזן את ה-API כאן',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          testLead: 'בדוק ליד',
          accountId: 'מזהה חשבון',
          accessToken: 'אסימון גישה',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterAccountIdHere: 'הזן את מזהה החשבון כאן',
            enterAccessTokenHere: 'הזן את אסימון הגישה כאן',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'מפתח API',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          testLead: 'בדוק ליד',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את המפתח כאן',
          }
        },
        helpLink: {
          help: 'עזרה',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          helpRaceCrmDomain: 'מתחום Helprace CRM',
          emailAddress: 'כתובת האימייל',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          update: 'עדכן',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'example@company.extension',
            token: 'אסימון',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          domainName: 'שם הדומיין',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          nameIsRequired: 'שם הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API כאן',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiHere: 'הזן את ה-API כאן',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          nameIsRequired: 'שם הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'בדוק ליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API כאן',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          emailAddress: 'כתובת האימייל',
          apiUrl: 'כתובת ה-URL של ה-API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          selectProjectName: 'בחר שם פרויקט',
          selectIssueType: 'בחר סוג בעיה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapsWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון כאן',
            enterEmailAddress: 'הזן את כתובת האימייל',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiUrl: 'כתובת ה-URL של ה-API',
          nameIsRequired: 'שם הוא שדה חובה',
          apiKey: 'מפתח ה-API',
          apiId: 'זיהוי ה-API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          mapWith: 'מפתה עם',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          selectTheQuestion: 'בחר את השאלה שברצונך לשלוח כערך בתשובת המשתמש',
          placeholders: {
            enterApiUrl: 'הזן את כתובת ה-URL של ה-API כאן',
            enterApiKey: 'הזן את מפתח ה-API כאן',
            enterApiId: 'הזן את זיהוי ה-API כאן',
            attributes: 'מאפיינים (מפתח)',
            values: 'ערכים',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח ה-API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון ה-API',
          nameIsRequired: 'שם הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API כאן',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          accessKey: 'מפתח גישה',
          secretKey: 'מפתח סודי',
          connect: 'התחבר',
          apiLogs: 'לוגי API',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          udpate: 'עדכן', // Typo in the English text; should be 'update'
          save: 'שמור',
          disconnect: 'נתק',
          filterApplied: 'פילטר הוחל',
          retry: 'נסה שוב',
          back: 'חזרה',
          filterYourLeads: 'סנן את הלידים שלך',
          filterOn: 'סנן לפי',
          filterBy: 'סנן לפי',
          discard: 'סגור',
          applyFilter: 'החל פילטר',
          loadMore: 'טען עוד',
          placeholders: {
            enterAccessKeyHere: 'הזן את מפתח הגישה כאן',
            enterSecretKeyHere: 'הזן את המפתח הסודי כאן',
            attributes: 'מאפיינים (מפתח)',
            values: 'ערכים',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'סנן נתונים בהתבסס על התגובה',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח ה-API',
          apiUrl: 'כתובת ה-URL של ה-API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiHere: 'הזן את ה-API כאן',
            exampleUrl: 'https://comapanyName.ladesk.com'
          }
        },
        microsoftTeams: {
          downloadPackage: 'הורד את חבילת האפליקציה של Microsoft Teams',
          downloadApp: 'הורד אפליקציה',
          wantToLearnHowToUploadIt: 'רוצה לדעת איך להעלות אותה?',
          needHelp: 'זקוק לעזרה?'
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'הינך מחובר בהצלחה לאינטגרציה. כעת, תוכל להגדיר אותה.',
          apiUrl: 'כתובת ה- API',
          appId: 'מזהה האפליקציה',
          nameIsRequired: 'מזהה האפליקציה הוא שדה חובה',
          dataApiKey: 'מפתח API של נתונים',
          dataApiKeyIsRequired: 'מפתח API של נתונים הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          addParameters: 'הוסף פרמטרים',
          mapWith: 'מפתה עם',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          selectTheQuestion: 'בחר את השאלה שברצונך לשלוח כערך מהמשתמש',
          placeholders: {
            enterApiUrl: 'הזן את כתובת ה- API כאן',
            enterAppId: 'הזן את מזהה האפליקציה כאן',
            enterDataApiKey: 'הזן את מפתח API של נתונים כאן',
            attributes: 'מאפיינים (מפתח)',
            pressForMappingQuestion: 'לחץ / לשאלה המופקדת למיפוי',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'הינך מחובר בהצלחה לאינטגרציה. כעת, תוכל להגדיר אותה.',
          emailAddress: 'כתובת האימייל',
          apiKey: 'מפתח ה- API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          leadId: 'מזהה הליד',
          selectLeadId: 'בחר את מזהה הליד',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterEmailHere: 'הזן את האימייל כאן',
            enterApiKeyHere: 'הזן את מפתח ה- API כאן',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'הינך מחובר בהצלחה לאינטגרציה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח ה- API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפתה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את מפתח ה- API כאן',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          noCrmDomain: 'דומיין NoCRM',
          leadTitle: 'כותרת הליד',
          titleIsRequired: 'שדה הכותרת הוא שדה חובה',
          token: 'אסימון',
          tokenIsRequired: 'שדה האסימון הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'ממפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            exampleDomain: 'https://כתובת_ה_תת-דומיין_שלך.nocrm.io',
            enterYourLeadTitle: 'הזן את כותרת הליד שלך',
            enterYourToken: 'הזן את האסימון שלך',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          emailAddress: 'כתובת האימייל',
          apiKey: 'מפתח ה- API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'ממפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה- API שלך',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          secretToken: 'אסימון סודי',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'ממפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          appId: 'מזהה אפליקציה',
          appIdIsRequired: 'יש להזין מזהה אפליקציה',
          apiKey: 'מפתח API',
          apiKeyIsRequired: 'יש להזין מפתח API',
          connect: 'התחבר',
          update: 'עדכן',
          disconnect: 'התנתק',
          placeholders: {
            appId: 'מזהה אפליקציה',
            apiKey: 'מפתח API',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          clientId: 'מזהה לקוח',
          connect: 'התחבר',
          clientSecret: 'סוד לקוח',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          save: 'שמור',
          update: 'עדכן',
          disconnect: 'התנתק',
          placeholders: {
            enterClientIdHere: 'הזן מזהה לקוח כאן',
            enterClientSecretHere: 'הזן סוד לקוח כאן',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          pipedriverDomain: 'דומיין Pipedriver',
          domainIsRequired: 'יש להזין דומיין',
          apiToken: 'מפתח API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            exampleDomain: 'https://company.pipedrive.com',
            enterTokenHere: 'הזן את המפתח כאן',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          apiKeyIsRequired: 'יש להזין מפתח API',
          appKey: 'מפתח APP',
          appKeyIsRequired: 'יש להזין מפתח APP',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterApiKey: 'הזן מפתח API כאן',
            enterAppKey: 'הזן מפתח APP כאן',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          authId: 'מזהה אימות',
          authToken: 'מפתח אימות',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterTokenHere: 'הזן את המפתח כאן',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterApiHere: 'הזן את ה- API כאן',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterApiKey: 'הזן מפתח API כאן',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          domain: 'דומיין',
          clientId: 'מזהה לקוח',
          clientSecret: 'סוד לקוח',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: ' הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterDomainHere: 'הזן דומיין כאן',
            enterClientIdHere: 'הזן מזהה לקוח כאן',
            enterClientSecretHere: 'הזן סוד לקוח כאן',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          addEventsAndTemplates: 'הוסף אירועים ותבניות:',
          add: 'הוסף',
          question: 'שאלה',
          reply: 'תשובה',
          requestBody: 'גוף הבקשה',
          validateJson: 'אמת JSON',
          emailAddress: 'כתובת דוא"ל',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          selectTheQuestion: 'בחר את השאלה שברצונך לשלוח כערך בתשובת המשתמש',
          placeholders: {
            enterApiHere: 'הזן את ה- API כאן',
            enterTemplateId: 'הזן מזהה תבנית כאן',
            typeYourJsonCodeHere: 'הזן את קוד ה-JSON שלך כאן',
          },
          appTooltip: {
            typeYourJsonCode: 'הזן את קוד ה-JSON שלך',
            clickToValidateJsonCode: 'לחץ לאמת את קוד ה-JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          userName: 'שם משתמש',
          licenseKey: 'מפתח רישיון',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterNameHere: 'הזן את השם כאן',
            enterKeyHere: 'הזן את המפתח כאן',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת את האינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          company: 'חברה',
          loginId: 'זיהוי התחברות',
          password: 'סיסמה',
          testLead: 'מנהל מבחן',
          connect: 'התחבר',
          selectTheQuestion: 'בחר את השאלה שאתה רוצה לשלוח כערך',
          mapWith: 'הקשר את',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          selectParameter: 'בחר פרמטר',
          placeholders: {
            enterCompanyHere: 'הזן את שם החברה כאן',
            enterLoginIdHere: 'הזן את מזהה ההתחברות כאן',
            enterPasswordHere: 'הזן את הסיסמה כאן',
            values: 'ערכים',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          applicationKey: 'מפתח אפליקציה',
          authToken: 'אסימון אימות',
          connect: 'התחבר',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterApplicationKeyHere: 'הזן את מפתח האפליקציה כאן',
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          accessToken: 'טוקן גישה',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          question: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterTokenHere: 'הזן את הטוקן כאן',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          webhoperDomain: 'דומיין Webhoper',
          domainIsRequired: 'נדרש דומיין',
          companyName: 'שם החברה',
          companyIsRequired: 'נדרש שם החברה',
          leadSource: 'מקור הלידים',
          leadSourceIsRequired: 'נדרש מקור הלידים',
          division: 'תחום',
          divisionIsRequired: 'נדרש תחום',
          enterPassword: 'הזן סיסמה',
          passwordIsRequired: 'נדרשת סיסמה',
          disconnect: 'התנתק',
          update: 'עדכן',
          connect: 'התחבר',
          placeholders: {
            exampleDomain: 'http://thedomainofyourcompany',
            enterYourCompanyName: 'הזן את שם החברה שלך',
            enterLeadSource: 'הזן מקור הלידים',
            enterDivision: 'הזן תחום',
            enterPassword: 'הזן סיסמה',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          name: 'שם:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'כתובת URL לקליטת Webhook',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterWebhookUrl: 'הזן את כתובת ה-Webhook כאן',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiToken: 'אסימון API',
          emailAddress: 'כתובת דוא"ל',
          apiUrl: 'כתובת URL של ה-API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterEmailAddress: 'הזן כתובת דוא"ל',
            enterUrlHere: 'הזן את ה-URL כאן',
            enterTokenHere: 'הזן את האסימון כאן',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          apiKey: 'מפתח API',
          connect: 'התחבר',
          testLead: 'ליד לבדיקה',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'התנתק',
          placeholders: {
            enterApiHere: 'הזן את ה- API כאן',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          connect: 'התחבר',
          update: 'עדכן',
          disconnect: 'התנתק',
          companyName: 'שם החברה',
          emailAddress: 'כתובת דוא"ל',
          phone: 'טלפון',
          countryCode: 'קידומת טלפון',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          save: 'שמור',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          connect: 'התחבר',
          disconnect: 'התנתק',
          testLead: 'ליד לבדיקה',
          emailAddress: 'כתובת דוא"ל',
          selectOrganizationName: 'בחר שם ארגון',
          selectDepartmentName: 'בחר שם מחלקה',
          addParameters: 'הוסף פרמטרים',
          questionColon: 'שאלה:',
          select: 'בחר',
          mapsWith: 'מתאים ל',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, תוכל להגדיר אותה.',
          testLead: 'ליד לבדיקה',
          connect: 'התחבר',
          disconnect: 'התנתק',
          projectsList: 'רשימת פרויקטים',
          selectProjectsName: 'בחר שם פרויקט',
          projectsTaskList: 'רשימת משימות בפרויקטים',
          selectTaskListName: 'בחר שם רשימת משימות',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
        },
        wooCommerce: {
          configure: 'הגדר',
          automatedMessage: 'הודעה אוטומטית',
          configureTab: {
            wooCommercePluginApiKey: 'מפתח API של תוסף WooCommerce',
            downloadWooCommercePlugin: 'הורד תוסף WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'הודעת שחזור לסלילים נטושים',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'הגדר הודעות שחזור אוטומטיות לפרטי לקוח שנטשו את סליהם',
            enableAbandonedCartRecoveryMessaegs: 'הפעל הודעות שחזור לסלילים נטושים',
            selectTemplate: 'בחר תבנית',
            sendMessageAfter: 'שלח הודעה לאחר',
            templateMessage: 'הודעת תבנית',
            saveChanges: 'שמור שינויים',
            selectFile: 'בחר קובץ',
            orderCompleted: 'הזמנה הושלמה',
            templateHeader: 'כותרת התבנית',
            orderFulfillMent: 'מימוש הזמנה',
            enableOrderConfirmationMessages: 'הפעל הודעות אישור הזמנה',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'שלח הודעת אישור הזמנה לאחר שהלקוח מבצע הזמנה.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'הודעת משלוח הזמנה נשלחת כאשר אתה מממש הזמנה.',
            enableOrderFulfillmentMessages: 'הפעל הודעות למימוש הזמנה',
            returnExchangeRequest: 'בקשת החזר/החלפה',
            theMessageIsSentAfterARefundIsRequested: 'ההודעה נשלחת לאחר בקשת החזר',
            enableOrderRefundMessages: 'הפעל הודעות החזר הזמנה',
          }
        },
        goHighLevel : {
          apiToken: 'אסימון API',
          nameIsRequired: 'שם הוא שדה חובה',
          connect: 'התחבר',
          testLead: 'ליד לבדוק',
          questionColon: 'שאלה:',
          addParameters: 'הוסף פרמטרים',
          select: 'בחר',
          mapWith: 'מפה עם',
          selectParameter: 'בחר פרמטר',
          update: 'עדכן',
          save: 'שמור',
          disconnect: 'נתק',
          placeholders: {
            enterApiToken: 'הזן את אסימון ה-API',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'צור אינטגרציה חדשה',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'כרגע אין לך שום אינטגרציה מוגדרת.',
      pleaseCreateOneToIntegrateYourFavourite: 'אנא צור אחת כדי לשדרג את האפליקציות האהובות עליך',
      appsWith: 'אפליקציות עם',
      integrationName: 'שם האינטגרציה',
      integrationNameIsRequired: 'שם האינטגרציה הוא שדה חובה',
      continue: 'המשך',
      addAnImageToRepresentYourIntegration: 'הוסף תמונה לייצוג האינטגרציה',
      description: 'תיאור',
      submit: 'שלח',
      youHaveSuccessfullyCreatedIntegration: 'יצרת אינטגרציה בהצלחה. כעת, אתה יכול להגדיר אותה.',
      apiUrl: 'כתובת API',
      pleaseEnterAValidApiUrl: 'אנא הזן כתובת API חוקית',
      requestMethod: 'שיטת הבקשה',
      get: 'GET',
      post: 'POST',
      put: 'PUT',
      patch: 'PATCH',
      queryParameter: 'פרמטר שאילתה',
      addParameter: 'הוסף פרמטר',
      mapsWith: 'ממפה ל',
      question: 'שאלה',
      addQuestion: 'הוסף שאלה',
      select: 'בחר',
      selectParameter: 'בחר פרמטר',
      header: 'כותרת',
      addHeader: 'הוסף כותרת',
      value: 'ערך',
      connect: 'התחבר',
      requestBody: 'גוף הבקשה',
      validate: 'אמת',
      key: 'מפתח',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: 'ניתן גם לאחד זאת עם מערכת ה-CRM החינמית הזו.',
        apiUrl: 'כתובת API',
        pleaseEnterAValidApiUrl: 'אנא הזן כתובת API חוקית',
        requestMethod: 'שיטת הבקשה:',
        get: 'GET',
        post: 'POST',
        put: 'PUT',
        patch: 'PATCH',
        queryParameter: 'פרמטר שאילתה',
        addParameter: 'הוסף פרמטר',
        header: 'כותרת',
        addHeader: 'הוסף כותרת',
        requestBody: 'גוף הבקשה',
        validateJson: 'אמת JSON',
        connect: 'התחבר',
        update: 'עדכן',
        delete: 'מחק',
        selectTheQuestion: 'בחר את השאלה שברצונך לשלוח כערך מהמשתמש',
        appTooltip: {
          typeYourJsonCode: 'הזן קוד JSON שלך',
          clickToValidateJsonCode: 'לחץ לאימות קוד JSON',
        }
      },
      list: {
        customAppsIntegrations: 'אינטגרציות של אפליקציות מותאמות אישית',
        integrateYourAppsWith: 'אינטגרט מערכות מותאמות אישית עם',
        help: 'עזרה',
        addIntegration: 'הוסף אינטגרציה',
        name: 'שם',
        status: 'סטטוס',
        action: 'פעולה',
        insatlled: 'מותקן',
        edit: 'ערוך',
        connect: 'התחבר',
        requestYourIntegration: 'בקש את האינטגרציה שלך',
        toRequestNewIntegration: 'כדי לבקש אינטגרציה חדשה, אנא הזן את הפרטים הבאים ונחזור אליך.',
        emailId: 'כתובת דוא"ל',
        integrationName: 'שם האינטגרציה',
        contactNumber: 'מספר טלפון',
        howSoonYouWantToAddThisIntegration: 'כמה מהר ברצונך להוסיף את האינטגרציה הזו',
        sendRequest: 'שלח בקשה',
        cancel: 'בטל',
        delete: 'מחק',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'אופס! האינטגרציות שלך נתמכות רק ב',
        plan: 'תוכנית',
        pleaseUpgradeTo: 'עדכן אל',
        toCreateOwnIntegrations: 'כדי ליצור אינטגרציות משל ך',
        appTooltip: {
          integrations: 'אינטגרציות',
          noIntegrationsFound: 'לא נמצאו אינטגרציות!',
          nameOfYourApp: 'שם האפליקציה שלך',
          clickToConnectApp: 'לחץ להתחברות לאפליקציה',
          statusAsConnectedOrNotConnected: 'סטטוס כמחובר / לא מחובר',
        }
      }
    }
  }
};
