export const whitelabel = {
  plans: 'Планы',
  managePlan: 'Управлять планами',
  whitelabelCharges: 'Уайтлеаблевая заряда',
  chargesOrTaxesConfiguration: 'Конфигурация сборов/налогов',
  websitePlans: 'Планы веб -сайта',
  whatsappPlansHeader: 'WhatsApp планы',
  addOnPlans: {
    createAddOnPlans: 'Создать дополнительные планы',
    checkoutYourCurrentMessage: 'Проверьте свои текущие планы дополнения сообщений и новые планы для ваших клиентов',
    choosePlanValidity: 'Выберите достоверность плана',
    chooseValidity: 'Выберите достоверность',
    oneYear: 'Один год',
    billingCycle: 'Платежный цикл',
    numberOfMessages: 'Количество сообщений',
    pleaseEnterNumberOfMessages: 'Пожалуйста, введите количество сообщений',
    setCurrency: 'Установить валюту',
    select: 'Выбирать',
    inr: 'Инфекция',
    usd: 'доллар США',
    cad: 'Атмосфера',
    clearAll: 'Очистить все',
    apply: 'Применять',
    currency: 'Валюта',
    amount: 'Количество',
    validity: 'Период действия',
    upgradePlan: 'Обновить план',
    addPlan: 'Добавить план',
    serialNumber: 'Старший №',
    messages: 'Сообщения',
    // validity: 'Период действия',
    active: 'Активный',
    priceDetails: 'Цена детали',
    action: 'Действие',
    addOnChanges: 'Дополнительные сборы:',
    // messages: ' Сообщения',
    perYear: 'в год',
    taxes: 'Налоги',
    placeholders: {
      enterNumberOfMesasges: 'Введите количество сообщений',
      enterAmount: 'Введите сумму',
      enterValidity: 'Введите достоверность',
      searchByMessages: 'Поиск по сообщениям',
    },
    modal: {
      deleteAddOnsPlan: 'Удалить план дополнений',
      wantToDeleteThisAddOnsPlan: 'Вы уверены, что хотите удалить этот план дополнений?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Добавить заряды белой абоненты',
    checkoutExistingCharges: 'Проверьте существующие сборы по различным способам оплаты и добавьте новые платы за белую',
    currency: 'Валюта',
    chooseCurrency: 'Выберите Валюту',
    amountPerBotPerMonth: 'Сумма на бот в месяц',
    cancel: 'Отмена',
    upgradeChanges: 'Обновление платы',
    addCharges: 'Добавить обвинения',
    serialNumber: 'Старший №',
    // currency: 'Валюта',
    action: 'Действие',
    taxes: 'Налоги',
    placeholders: {
      enterAmountBotPerMonth: 'Введите сумму бота в месяц',
      searchByCurrency: 'Поиск по валюте',
    },
    modal: {
      deleteCharges: 'Удалить заряды',
      wantToDeleteThisCharge: 'Вы уверены, что хотите удалить этот заряд?',
    }
  },
  planManagement: {
    createNewPlans: 'Создайте новые планы',
    checkoutYourCurrentPlan: 'Проверьте свои текущие планы и добавьте новые планы для ваших клиентов',
    choosePlan: 'Выберите план',
    selectPlan: 'Выберите план',
    baby: 'Малыш',
    king: 'Король',
    displayName: 'Отображаемое имя',
    messageLimit: 'Предел сообщения',
    enterAValidEmail: 'Введите действующее электронное письмо',
    setCurrency: 'Установить валюту',
    select: 'Выбирать',
    inr: 'Инфекция',
    usd: 'доллар США',
    cad: 'Атмосфера',
    clearAll: 'Очистить все',
    apply: 'Применять',
    perMonth: '/Месяц',
    perYear: '/Год',
    cancel: 'Отмена',
    upgradePlan: 'Обновить план',
    addPlan: 'Добавить план',
    serialNumber: 'Старший №',
    plan: ' План',
    // displayName: 'Отображаемое имя',
    messages: 'Сообщения',
    pricing: 'Ценообразование',
    actions: 'Действия',
    // plan: ' План',
    perMonthText: 'В месяц',
    perYearText: 'В год',
    placeholders: {
      enterDisplayName: 'Введите отображаемое имя',
      enterMessageLimit: 'Введите предел сообщения',
      searchByPlanName: 'Поиск по названию плана или сообщениям'
    },
    modal: {
      deletePlan: 'Удалить План',
      wantToDeleteThisBabyPlan: 'Удаление Плана "Baby" повлияет на всех клиентов. Вы уверены, что хотите удалить этот план?',
      wantToDeleteThisPlan: 'Вы уверены, что хотите удалить этот план?',
    },
    appTooltip: {
      chooseYourPlanType: 'Выберите свой тип плана',
      pleaseInputYourPlanDisplayName: 'Пожалуйста, введите имя отображения вашего плана',
      typeMessageLimitForCustomer: 'Введите ограничение сообщения для клиента',
      selectYourPreferredCurrencies: 'Выберите предпочтительные валюты',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Добавить налоги',
    checkoutExistingTaxes: 'Проверьте существующие налоги на различные методы оплаты и новые налоги',
    taxName: 'Налоговое название',
    applicableOn: 'Применимо на',
    choosePlans: 'Выберите планы',
    chooseCurrency: 'Выберите Валюту',
    percentage: 'Процент',
    cancel: 'Отмена',
    // addTaxes: 'Добавить налоги',
    serialNumber: 'Старший №',
    name: 'имя',
    // percentage: 'Процент',
    currency: 'Валюта',
    updateTaxes: 'Обновить налоги',
    actions: 'Действия',
    modal: {
      deleteTaxes: 'Удалить налоги',
      wantToDeleteThisTax: 'Вы уверены, что хотите удалить эти налоги?',
    },
    placeholders: {
      taxName: 'Налоговое название',
      taxPercentage: 'Налоговый процент',
      searchByCurrency: 'Поиск по валюте',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Создайте новые планы',
    checkoutYourCurrentAndNewPlans: 'Проверьте свои текущие планы и добавьте новые планы для ваших клиентов',
    choosePlan: 'Выберите план',
    selectPlan: 'Выберите план',
    baby: 'Малыш',
    king: 'Король',
    displayName: 'Отображаемое имя',
    messageLimit: 'Лимит беседы',
    enterAValidMessageLimit: 'Введите допустимый лимит беседы',
    messages: 'Беседы',
    setCurrency: 'Установить валюту',
    select: 'Выбирать',
    inr: 'Инфекция',
    usd: 'доллар США',
    cad: 'Атмосфера',
    clearAll: 'Очистить все',
    apply: 'Применять',
    perMonth: '/Месяц',
    perYear: '/Год',
    cancel: 'Отмена',
    addPlan: 'Добавить план',
    serialNumber: 'Старший №',
    plan: 'План',
    pricing: 'Ценообразование',
    actions: 'Действия',
    perMonthText: 'В месяц',
    perYearText: 'В год',
    updatePlan: 'Обновить план',
    // addPlan: 'Добавить план',
    placeholders: {
      enterDisplayName: 'Введите отображаемое имя',
      enterMessageLimit: 'Введите лимит беседы',
      searchByPlanName: 'Поиск по названию плана или беседам',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Введите лимит беседы для клиента',
      chooseYourPlanType: 'Выберите свой тип плана',
      selectYourPreferredCurrencies: 'Выберите предпочтительные валюты',
      pleaseInputYourPlanDisplayName: 'Пожалуйста, введите имя отображения вашего плана',
    },
    modal: {
      deletePlan: 'Удалить План',
      wantToDeleteThisBabyPlan: 'Удаление Плана "Baby" повлияет на всех клиентов. Вы уверены, что хотите удалить этот план?',
      areYouSureYouWantToDeleteThisPlan: 'Вы уверены, что хотите удалить этот план?',
    }
  }
};
