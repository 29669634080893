export const developer = {
  developers: 'Разработчики',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Дайте вашим разработчикам возможность раскрыть полный потенциал вашей панели управления',
  accessToken: 'Токен доступа',
  yourAPI: 'Ваши API',
  api: {
    apiManagement: 'Управление API',
    apiManagementSubHeading: 'Добавляйте, настраивайте, тестируйте и управляйте вашими API, которые вы можете активировать в ваших чат-потоках.',
    addApi: 'Добавить API',
    createApi: 'Создать API',
    noApiFound: 'API не найдены!',
    deleteApis: 'Удалить API',
    areYouSureYouWantToDeleteAPI: 'Вы уверены, что хотите удалить API?',
    placeholders: {
      search: 'Поиск по имени API'
    },
    tableHead: {
      method: 'Метод',
      apiName: 'Название API',
      url: 'URL',
      actions: 'Действия'
    },
    parametersTab: 'Параметры',
    headersTab: 'Заголовки',
    bodyTab: 'Тело запроса',
    manageApiResponses: 'Управление ответами API',
    chooseVariableToSave: 'Выберите переменную для сохранения',
    selectCustomAttribute: 'Выберите пользовательский атрибут',
    subTab: {
      key: 'Ключ',
      enterKey: 'Введите ключ',
      value: 'Значение',
      enterValue: 'Введите значение',
      addAnotherField: 'Добавить еще одно поле',
      save: 'Сохранить',
      useslashToInsertCustomVariables: `Используйте '/' для вставки пользовательских переменных или динамических значений из ответа пользователя.`,
      response: 'Ответ',
      apiRequestBody: 'Тело запроса API',
      hit: 'Успех',
      test: 'Тест',
      buttonToGetAResponse: 'Кнопка для получения ответа',
      variable: 'Переменная',
      saveToCustomAttribute: 'Сохранить в пользовательский атрибут'
    },
    apiLogs: {
      apiManagement: 'Управление API',
      addConfigureTestAndManageYourAPIs: 'Добавляйте, настраивайте, тестируйте и управляйте вашими API, которые вы можете активировать в ваших чат-потоках.',
      searchHere: 'Поиск по коду статуса',
      deleteApis: 'Удалить API',
      testAnApi: 'Тестировать API',
      noAPILogFound: 'Журналов API не найдено',
      responseCode: 'Код ответа',
      message: 'Сообщение',
      apiResponse: 'Ответ API',
      actions: 'Действия',
      deleteAPIs: 'Удалить API',
      areYouSureYouWantToDelete: 'Вы уверены, что хотите удалить это API?',
      apiLogs: `Журналы API`,
      subTitle: `Подробная запись всех запросов и ответов API, сгенерированных внутри чатового потока чат-бота.`
    }
  },
  configApi: {
    configureApi: 'Настроить API',
    configureApiSubHeading: 'Вы можете настроить ваш API на основе JSON здесь и использовать его для обогащения вашего чат-потока.',
    apiLogs: 'Журналы API',
    test: 'Тестирование',
    manageApiResponse: 'Управление ответами API',
    body: 'Тело',
    headers: 'Заголовки',
    parameters: 'Параметры',
    placeholders: {
      enterApiName: 'Введите название API',
      enterApiUrl: 'Введите URL API'
    }
  }
};
