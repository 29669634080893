import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit, OnChanges {

  @Input() title = '';
  @Input() image = '';
  @Output() userInput: EventEmitter<any> = new EventEmitter();
  @Input() showCloseButton = true;
  @Input() showModal = false;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
    if (this.showModal) {
      return $(document.getElementById('imageModal')).modal('show');
    } else {
      return $(document.getElementById('imageModal')).modal('hide');
    }
  }
  action(status: boolean): void {
    this.userInput.emit(status);
    // this.image = '';
  }

}
