export const team = {
  addMember: {
    team: 'Team',
    seeHowToUse: 'See how to use',
    help: 'Help',
    addAMember: 'Add a Member',
    addYourTeamMembersText: 'Add your team members as support agents to live chat with bot users.',
    name: 'Name',
    nameIsRequired: 'Name is required',
    email: 'Email',
    enterAValidEmail: 'Enter a valid email',
    password: 'Password',
    passwordMustContain: 'Password must contain',
    atleast6Characters: 'atleast 6 characters,',
    oneUpperCaseLetter: '1 uppercase letter',
    oneSpecialCharacter: '1 special character*',
    profileIcon: 'Profile Icon',
    whatsappNumber: 'Whatsapp Number',
    leadVisibility: 'Lead Visibility*',
    addMember: 'Add Member',
    role: 'Role',
    createdOn: 'Created On',
    action: 'Action',
    updateMember: 'Update Member',
    add: 'Add',
    cancel: 'Cancel',
    selectVisibilityOption: 'Select visibility option',
    all: 'All',
    assigned: 'Assigned',
    nA: 'NA',
    admin: 'Admin',
    agent: 'Agent',
    placeholders: {
      enterMemberName: 'Enter member name',
      enterMemberEmailAddress: 'Enter member email address',
      createPasswordForMember: 'Create a password for Member',
      searchCountry: 'Search Country',
      enterWhatsappNumber: 'Enter WhatsApp Number',
      selectLeadsVisibility: 'Select leads visibility',
      searchTeamByNameOrEmail: 'Search team member by name or email',
    },
    modal: {
      deleteTeamMember: 'Delete team member',
      wantToDeleteThisTeamMember: 'Are you sure you want to delete this team member?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Click to add a new team member',
      profileIconOfTeamMember: 'Profile icon/image of the team member',
      nameOfTeamMember: 'Name of the team member',
      emailOfTeamMember: 'Email of team member',
      whatsappNumberOfTeamMember: 'Whatsapp number of team member',
      roleOfTeamMemberAsAdminOrAgent: 'Role of team member as admin/agent',
      dateAndTimeOfCreatingTeamMemberRole: 'Date and time of creating team member role',
      editOrDeleteTeamMember: 'Edit/delete team member',
      editTeamMembersDetails: `Edit team member\'s details.`,
      removeAccessOfTeamMemberByDeletingAccount: 'Remove access of team member by deleting account',
    }
  },
  users: {
    manageMembers: 'Manage Members',
    managerMembersWhichCanAccessPanel: 'Manage your partner team which can access your panel',
    createNewMember: 'Create New Member',
    createMember: 'Create Member',
    updateMember: 'Update Member',
    deleteTitleMember: 'Delete Member',
    deleteDescriptionMember: 'Are you sure you want to delete this member ?',
    noMemberFound: 'No Member Found!',
    titleMember: 'Create a Member',
    manageUsers: 'Manage Users',
    createNewUser: 'Create New User',
    managerUserWhichCanAccessPanel: 'Manage your user which can access your panel',
    phoneNumber: 'Phone Number',
    discard: 'Discard',
    createUser: 'Create user',
    updateUser: 'Update User',
    noUserFOund: 'No User Found!',
    title: 'Create a User',
    profileIcon: 'Profile Icon*',
    name: 'Name*',
    email: 'Email*',
    password: 'Password*',
    selectRole: 'Select Role*',
    selectARole: 'Select Role',
    selectLeadVisibility: 'Select Lead Visibility*',
    confirmPassword: 'Confirm Password*',
    tableHeadingName: 'Name',
    tableHeadingEmail: 'Email',
    tableHeadingPhoneNumber: 'Phone Number',
    tableHeadingRole: 'Role',
    tableHeadingStatus: 'Status',
    tableHeadingAction: 'Action',
    deleteTitle: 'Delete Users',
    deleteDescription: 'Are you sure you want to delete this user ?',
    placeholders: {
      searchHere: 'Search here',
      enterName: 'Enter Name',
      enterEmail: 'Enter email',
      searchCountry: 'Search country',
      enterPhoneNumber: 'Enter Phone Number',
      enterPassword: 'Enter password',
      enterConfirmPassword: 'Enter confirm password'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Delete users',
      TOOLTIP_DELETE_MEMBERS: 'Delete members'
    }
  },
  roles: {
    manageRoles: 'Manage Roles',
    manageYourRoles: 'Manage your roles.',
    name: 'Name*',
    description: 'Description',
    discard: 'Discard',
    createRole: 'Create Role',
    creteARole: 'Create a Role',
    updateRole: 'Update Role',
    noUserFound: 'No User Roles Found!',
    assignPermissions: 'Assign Permissions*',
    tableHeadingName: 'Name',
    tableHeadingDescription: 'Description',
    tableHeadingStatus: 'Status',
    tableHeadingAction: 'Action',
    module: 'Module',
    read: 'Read',
    write: 'Write',
    remove: 'Delete',
    notes: 'Notes',
    deleteTitle: 'Delete Role',
    deleteDescription: 'Caution: When you delete this role, it will also delete all the users assigned this role. \n Are you sure you want to delete this role?',
    deleteMemberDescription: 'Caution: When you delete this role, it will also delete all the members assigned this role. \n Are you sure you want to delete this role?',
    placeholders: {
      enterRoleName: 'Enter name of role',
      searchHere: 'Search here',
      enterDescriptionForRole: 'Enter a description for the role'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Sections you can allow or block users from accessing',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Click to add a new Role',
    }
  },
  accessControl: {
    users: 'Users',
    partnerTeam: 'Partner Team',
    members: 'Members',
    roles: 'Roles'
  }
};
