import {Action, createReducer, on} from '@ngrx/store';
import { loadSocialDrips, addSocialDrips, deleteSocialDrips, updateSocialDrips } from '../actions/social-drip.actions';


export const dripsFeatureKey = 'drips';


export const initialState: any [] = [];

const socialDripsReducer = createReducer(
  initialState,
  on(loadSocialDrips, (state, {drips}) => drips),
  on(addSocialDrips, (state, {drip}) => [...state, drip]),
  on(deleteSocialDrips, (state, {drip}) =>
    state.filter(item => String(item._id) !== String(drip))),
  on(updateSocialDrips, (state, {drip, update}) => state.map(item => {
    if (String(item._id) === String(drip)) {
      return {...item, ...update};
    } else {
      return item;
    }
  }))
);

export function reducer(state: any[] | undefined, action: Action) {
  return socialDripsReducer(state, action);
}
