import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { CommonService } from './common.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  LOGIN: any = 'auth/login/';
  LOGIN_VIA_AGENCY: any = 'auth/login/via-agency';
  SOCIAL_LOGIN: any = 'auth/social-login';
  PROFILE: any = 'customer/';
  // AGENCY_DETAILS = 'admin/details/';
  RESEND = 'auth/send-email-verification-code/';
  COUNT: any = 'communication/count';
  CREATING_BOT: any = 'bots';
  GET_BOTS: any = 'bots';
  GET_AGENCY: any = 'auth/agency';

  constructor(
    private router: Router,
    public networkService: NetworkService,
    private cos: CommonService
  ) { }

  async loginUser(data) {
    return this.networkService.httpRequest(this.LOGIN, data, 'POST', false);
  }

  async loginViaAgency(data) {
    return this.networkService.httpRequest(this.LOGIN_VIA_AGENCY, data, 'POST', false);
  }

  async socialLoginUser<T>(data: T) {
    return this.networkService.httpRequest(this.SOCIAL_LOGIN, data, 'POST', false);
  }

  getProfile() {
    return this.networkService.httpRequest(this.PROFILE, null, 'GET', true);
  }

  // getAgencyDetails(id: string) {
  //   return this.networkService.httpRequest(this.AGENCY_DETAILS + id, null, 'GET', true);
  // }

  forgetPassword(data): Promise<any> {
    return this.networkService.httpRequest('auth/reset-password', data, 'POST', false);
  }

  resetPassword(code, data) {
    return this.networkService.httpRequest('auth/reset-password/' + code, data, 'PUT', false);
  }

  checkEmail(email: string) {
    return this.networkService.httpRequest('auth/check-email?email=' + email, undefined, 'GET');
  }

  signUpPage() {
    return this.router.navigate(['/authentication']);
  }

  async resendEmail(id: string) {
    return this.networkService.httpRequest(this.RESEND + id, {}, 'POST', false);
  }

  async setAgentAvailabilty(profileType: string, liveAvailability: 'ONLINE' | 'OFFLINE') {
    return this.networkService.httpRequest(profileType, { liveAvailability }, 'PUT');
  }

  async verifyEmail(code: string) {
    return this.networkService.httpRequest('auth/account-verify/' + code, {}, 'PUT', false);
  }

  async verifyChangedEmail(code: string, type: string) {
    return this.networkService.httpRequest('auth/change-email-verify/' + code + '/' + type, {}, 'PUT', false);
  }

  async currentCount() {
    return this.networkService.httpRequest(this.COUNT + '/current-usage/v2', null, 'GET', true);
  }

  createBot(data) {
    return this.networkService.httpRequest(this.CREATING_BOT, data, 'POST', true);
  }

  getBots(page = 1) {
    return this.networkService.httpRequest(this.GET_BOTS + '?page=' + page);
  }
  deleteBot(id) {
    return this.networkService.httpRequest(this.CREATING_BOT + `/${id}`, {}, 'DELETE', true);
  }

  getAgencyInfo(domain: string): Promise<{
    name: string;
    meta: {
      domains: string[];
      logo: string;
      brand: string;
      websiteUrl: string;
      _id: string;
    };
    _id: string;
  }> {
    return (this.networkService.httpRequest(`${this.GET_AGENCY}?domain=${domain}`, undefined, 'GET', true) as any);
  }

  changePassword(data) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('auth/change-password/', data, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async createDeveloperApi() {
    return this.networkService.httpRequest('customer/permanent-token', {}, 'POST');
  }

  async revokeDeveloperApi() {
    return this.networkService.httpRequest('customer/permanent-token', {}, 'PUT');
  }

  async createAgencyDeveloperApi() {
    return this.networkService.httpRequest('agencies/permanent-token', {}, 'POST');
  }

  async revokeAgencyDeveloperApi() {
    return this.networkService.httpRequest('agencies/permanent-token', {}, 'PUT');
  }

  createFeedback(data){
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('feedback', data, 'POST')
        .then(resolve)
        .catch(error => {
          console.error('Error occurred while saving feedback:', error);
          reject(error);
        });
    });
  }

  isUserFeedback(){
    return this.networkService.httpRequest('feedback');
  }
}
