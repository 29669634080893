export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'An activation link has been sent, please verify your email',
    weHaveSentActivationLinkToEmailAddress: `We've sent an activation link to email address`,
    toVerifyYourAccount:  `to 'Verify your account'.`,
    onceVerifiedAutomaticallyRedirected: 'Once verified, you shall be automatically redirected to the panel.',
    didNotReceiveAnEmail: `Didn't receive an email? Check your spam folder`,
    resendIn: 'Resend in ',
    seconds: ' seconds',
    resend: 'Resend',
    backToSignUp: 'Back to Sign up',
    greatPleaseCheckYourMessage: 'Great, please check your email',
    weHaveSentEmailTo: `We've sent an email to`,
    withALinkToReset: ` with a link to reset your account. Once activated you'll be able to finalize your account creation and start your free trial.`,
    resent: 'Resent',
    backToLogin: 'Back to Login',
  },
  forgotPassword: {
    forgotPassword: 'Forgot Password?',
    noWorries: 'No worries,',
    weWillSendYouResetPasswordInstructions: `we'll send you reset password instruction`,
    workEmail: 'Work Email',
    continue: 'Continue',
    backToLogin: 'Back to Login',
    placeholders: {
      enterYourEmail: 'Enter Your Email',
    }
  },
  logout: {
    logout: 'Log out',
    wantToLogOut: 'Are you sure you want to Log out?',
    yesIWillBeBack: `Yes, I'll be back`,
    cancel: 'Cancel',
  },
  newPassword: {
    createNewPassword: 'Create new Password',
    yourNewPasswordMustBeDifferent: 'Your new password must be different to previously used passwords.',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    setPassword: 'Set Password',
    signUp: 'Sign up',
    login: 'Login',
    backTo: 'Back to ',
    setAPassword: 'Set a Password',
    setNewPasswordToYourAccount: 'Set a new password to your account for security and privacy purpose',
    backToSignUp: 'Back to Sign up',
    placeholders: {
      enterYourPassword: 'Enter Your Password',
    }
  },
  resetPassword: {
    passwordReset: 'Password reset',
    yourPasswordHasBeenSuccessfullyReset: 'Your password has been successfully reset.',
    clickBelowToLogIn: 'Click below to log in',
    continueToLogin: 'Continue to Login',
  },
  newSignUp: {
    welcomeBack: 'Welcome Back ',
    to: 'to',
    logIn: 'Log In ',
    as: 'as ',
    withFacebook: 'with Facebook',
    logInWithGoogle: 'Log In with Google',
    or: 'OR',
    pleaseFillAValidWorkEmail: 'Please fill a valid work email*',
    workEmailIsRequired: 'Work email is required*',
    pleaseFillAValidPassword: 'Please fill a valid password*',
    passwordIsRequired: 'Password is required*',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot Password?',
    login: 'Login',
    doNotHaveAnAccount: `Don't have an account?`,
    setUpAFreeAccount: 'Set up a FREE Account',
    noCreditCardRequired: 'No Credit Card required',
    cancelAnytime: 'Cancel anytime',
    signUp: 'Sign Up ',
    // as: 'as ',
    // withFacebook: 'with Facebook',
    signUpWithGoogle: 'Sign Up with Google',
    // or: 'OR',
    emailIsAlreadyRegistered: 'Email is already Registered with us, Kindly click on Log In to continue',
    phoneNumberShouldHaveMinimum7Digits: 'Phone number should have minimum 7 digits*',
    phoneNumberIsRequired: 'Phone number is required*',
    passwordMustContain: 'Password must contain',
    atleast6Characters: 'atleast 6 characters, ',
    oneUpperCaseLetter: '1 uppercase letter ',
    oneSpecialCharacter: '1 special character*',
    alreadyHaveAccount: 'Already have an account?',
    signingUpToBotPenguinIsAConsentToOur: 'Signing up for a BotPenguin account is your consent to our ',
    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms and Conditions',
    and: ' and ',
    english: 'English',
    hindi: 'Hindi',
    german: 'German',
    french: 'French',
    portuguese: 'Portugese',
    spanish: 'Spanish',
    italian: 'Italian',
    russian: 'Russian',
    placeholders: {
      workEmail: 'Work Email*',
      password: 'Password*',
      phone: 'Phone*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Successfully Unsubscribed',
    yourEmail: 'Your email ',
    haveBeenSuccessfullyUnsubscribedFrom: ' have been successfully unsubscribed from',
    backToLogin: 'Back to Login'
  }
};
