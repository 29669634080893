import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { environment } from 'src/environments/environment';
import { NetworkService } from 'src/appv2/services/network.service';
import { Store } from '@ngrx/store';
import { LanguageService } from 'src/appv2/services/language.service';

declare const window: any;

@Component({
  selector: 'app-web-preview',
  templateUrl: './web-preview.component.html',
  styleUrls: ['./web-preview.component.scss']
})
export class WebPreviewComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  staticText: any;
  frameObject = new (window as any).BotPenguin();
  bot: any = {};
  welcomeMessage = `👋 I'm here to help you.`;
  botPlatform = 'website';
  avatar = '';
  @Input() botName = '';
  @Input() themeColor = '#118cfc';
  @Input() botIcon = 'https://cdn.botpenguin.com/assets/avatars/profile-28.svg';
  @Input() botType = '';
  @Input() selectedFlow: any;
  @Input() lastStep = false;
  subscribe$: any;
  agency: any;
  brandName: string;

  constructor(
    private sharedService: SharedService,
    private networkService: NetworkService,
    private store: Store<any>,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.webPreview;
    window.addEventListener('message', (msg) => {
      const data = msg.data;
      this.sharedService.loader('start');
      if (data.origin === 'BotPenguin') {
        if (data.action === 'rendered') {
          const icon = document.getElementById('botpenguin-launcher-12');
          icon.style.visibility = 'visible';
          icon.style.opacity = '1';
          this.openWindow('design');
          this.sharedService.loader('stop');
        }
      }
      this.sharedService.loader('stop');
    });
  }

  ngOnInit(): void {
    this.subscribe$ = this.store.select(state => state)
      .subscribe(store => {
        this.agency = store?.profile?.agency;
        this.brandName = store.profile.agency?.meta?.brand || '';
      });

    this.setBotData();

    this.frameObject.setOpener('design');
    if (this.agency) {
      this.botName = this.agency.type === 'WHITE_LABEL' ? this.agency?.meta.brand : 'BotPenguin';
      window.BotPenguinData = {
        ...this.bot,
        name: this.botName,
        preview: true,
        isLanding: this.botType === 'landing-page' ? true : false,
        agency: this.agency,
      };

    }
    // this.importFlow('5fed62fbb3053e15ba12c4a1');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.startPreview();
    }, 1500);
  }

  ngOnChanges(): void {
    if (window.BotPenguinData) {
      window.BotPenguinData = {
        ...window.BotPenguinData,
        name: this.agency?.type === 'WHITE_LABEL' ? this.agency?.meta.brand : this.botName,
        design: {
          ...window.BotPenguinData.design,
          themeColor: this.themeColor,
          avatar: this.botIcon
        },
      };
      if (this.selectedFlow) {
        window.BotPenguinData = {
          ...window.BotPenguinData,
          flows: this.selectedFlow
        };
      }
      setTimeout(() => {
        this.startPreview();
      }, 1500);
    }
  }

  ngOnDestroy() {
    this.subscribe$.unsubscribe();
    this.frameObject.setOpener('build');
    try {
      window.removeEventListener('message', () => {
      });
      document.querySelector('#BotPenguin').remove();
      document.querySelector('botpenguin-root').remove();
    } catch (e) {
    }
  }

  openWindow(opener): void {
    this.frameObject.openWindow('design');
    document.getElementById('BotPenguin-messenger').style.display = 'block';

  }

  startPreview() {
    this.frameObject.closeWindow();
    const icon = document.getElementById('botpenguin-launcher-12');
    if (icon) {
      icon.style.visibility = 'hidden';
      icon.style.opacity = '0';
      const iframe = (window as any).document.getElementById('BotPenguin-messenger').contentWindow.document;
      iframe.open();
      document.getElementById('BotPenguin-messenger').style.display = 'block';
      fetch(`${environment.chatWindowUrl}/index.html`)
        .then((response: any) => response.text())
        .then(html => {
          iframe.write(html);
          iframe.close();
        })
        .catch(console.log);
    }
  }

  setBotData() {
    this.sharedService.loader('start');
    this.networkService.httpRequest(`bots/${this.getBotId()}`)
      .then((response: any) => {
        let flowQuestions = [];
        if (response && response.flows && response.flows[0]
          && response.flows[0].questions && response.flows[0].questions.length) {
          flowQuestions = response.flows[0].questions;
        }

        flowQuestions = this.sharedService.getWhiteLabelledFlow(flowQuestions, this.agency?.meta.brand || '');

        this.avatar = response.design.avatar;
        this.sharedService.loader('stop');
        window.BotPenguinData = {
          ...response,
          name: this.agency?.type === 'WHITE_LABEL' ? this.agency?.meta.brand : this.botName,
          preview: true,
          flows: [{ ...response.flows[0], questions: flowQuestions }],
          isLanding: this.botType === 'landing-page' ? true : false,
          agency: this.agency,
        };
      })
      .catch((error) => {
        console.log('Error in importing template', error);
        // this.sharedService.showToast('error', '', 'Error Occured');
        this.sharedService.loader('stop');
      });
  }

  getBotId() {
    if (this.botType === 'live-chat') {
      return environment.liveChatBotId;
    }
    else {
      return environment.leadGenerationBotId;
    }
  }
}
