export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'ברוכים הבאים ל-BotPenguin!',
      login: 'כניסה',
      youAreAllSet: 'הכל מוכן! תוכל להשתמש בתיבת הדוא"ל של WhatsApp מדף פרטי הליד.',
      placeholders: {
        enterEmail: 'הזן את האימייל',
        enterPassword: 'הזן סיסמה'
      }
    }
  }
};
