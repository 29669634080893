export const team = {
  addMember: {
    team: 'Team',
    seeHowToUse: 'Bekijk hoe te gebruiken',
    help: 'Hulp',
    addAMember: 'Voeg een Lid Toe',
    addYourTeamMembersText: 'Voeg uw teamleden toe als ondersteuningsagenten om live te chatten met botgebruikers.',
    name: 'Naam',
    nameIsRequired: 'Naam is verplicht',
    email: 'E-mail',
    enterAValidEmail: 'Voer een geldig e-mailadres in',
    password: 'Wachtwoord',
    passwordMustContain: 'Wachtwoord moet bevatten',
    atleast6Characters: 'minstens 6 tekens,',
    oneUpperCaseLetter: '1 hoofdletter',
    oneSpecialCharacter: '1 speciaal teken*',
    profileIcon: 'Profiel Pictogram',
    whatsappNumber: 'WhatsApp-nummer',
    leadVisibility: 'Zichtbaarheid Prospecten*',
    addMember: 'Voeg Lid Toe',
    role: 'Rol',
    createdOn: 'Aangemaakt op',
    action: 'Actie',
    updateMember: 'Werk Lid Bij',
    add: 'Toevoegen',
    cancel: 'Annuleren',
    selectVisibilityOption: 'Selecteer zichtbaarheidsoptie',
    all: 'Alles',
    assigned: 'Toegewezen',
    nA: 'N.v.t.',
    admin: 'Beheerder',
    agent: 'Agent',
    placeholders: {
      enterMemberName: 'Voer de naam van het lid in',
      enterMemberEmailAddress: 'Voer het e-mailadres van het lid in',
      createPasswordForMember: 'Maak een wachtwoord aan voor het lid',
      searchCountry: 'Zoek Land',
      enterWhatsappNumber: 'Voer WhatsApp-nummer in',
      selectLeadsVisibility: 'Selecteer zichtbaarheid van prospecten',
      searchTeamByNameOrEmail: 'Zoek teamlid op naam of e-mail',
    },
    modal: {
      deleteTeamMember: 'Verwijder teamlid',
      wantToDeleteThisTeamMember: 'Weet u zeker dat u dit teamlid wilt verwijderen?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Klik om een nieuw teamlid toe te voegen',
      profileIconOfTeamMember: 'Profiel pictogram/afbeelding van het teamlid',
      nameOfTeamMember: 'Naam van het teamlid',
      emailOfTeamMember: 'E-mail van het teamlid',
      whatsappNumberOfTeamMember: 'WhatsApp-nummer van het teamlid',
      roleOfTeamMemberAsAdminOrAgent: 'Rol van het teamlid als beheerder/agent',
      dateAndTimeOfCreatingTeamMemberRole: 'Datum en tijd van het aanmaken van de rol van het teamlid',
      editOrDeleteTeamMember: 'Teamlid bewerken/verwijderen',
      editTeamMembersDetails: 'Bewerk details van teamlid.',
      removeAccessOfTeamMemberByDeletingAccount: 'Verwijder toegang van teamlid door het account te verwijderen',
    }
  },
  users: {
    manageMembers: 'Beheer Leden',
    managerMembersWhichCanAccessPanel: 'Beheer uw partner team dat toegang kan krijgen tot uw dashboard',
    createNewMember: 'Maak Nieuw Lid',
    createMember: 'Maak Lid',
    updateMember: 'Werk Lid Bij',
    deleteTitleMember: 'Verwijder Lid',
    deleteDescriptionMember: 'Bent u zeker dat u dit lid wilt verwijderen?',
    noMemberFound: 'Geen Lid Gevonden!',
    titleMember: 'Maak een Lid',
    manageUsers: 'Gebruikers beheren',
    createNewUser: 'Nieuwe gebruiker aanmaken',
    managerUserWhichCanAccessPanel: 'Beheer gebruikers die toegang hebben tot uw paneel',
    phoneNumber: 'Telefoonnummer',
    discard: 'Annuleren',
    createUser: 'Gebruiker aanmaken',
    updateUser: 'Gebruiker bijwerken',
    noUserFOund: 'Geen gebruiker gevonden!',
    title: 'Maak een Gebruiker aan',
    profileIcon: 'Profielicoon*',
    name: 'Naam*',
    email: 'E-mail*',
    password: 'Wachtwoord*',
    selectRole: 'Selecteer Rol*',
    selectARole: 'Selecteer een rol',
    selectLeadVisibility: 'Selecteer Lead Zichtbaarheid*',
    confirmPassword: 'Bevestig Wachtwoord*',
    tableHeadingName: 'Naam',
    tableHeadingEmail: 'E-mail',
    tableHeadingPhoneNumber: 'Telefoonnummer',
    tableHeadingRole: 'Rol',
    tableHeadingStatus: 'Status',
    tableHeadingAction: 'Actie',
    noUserFound: 'Geen Gebruiker Gevonden!',
    deleteTitle: 'Gebruikers Verwijderen',
    deleteDescription: 'Bent u zeker dat u deze gebruiker wilt verwijderen?',
    placeholders: {
      searchHere: 'Zoek hier',
      enterName: 'Voer naam in',
      enterEmail: 'Voer e-mailadres in',
      searchCountry: 'Zoek land',
      enterPhoneNumber: 'Voer telefoonnummer in',
      enterPassword: 'Voer wachtwoord in',
      enterConfirmPassword: 'Bevestig wachtwoord'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Gebruikers verwijderen',
      TOOLTIP_DELETE_MEMBERS: 'Leden Verwijderen'
    }
  },
  roles: {
    manageRoles: 'Beheer Rollen',
    manageYourRoles: 'Beheer uw rollen.',
    name: 'Naam*',
    description: 'Beschrijving',
    discard: 'Afbreken',
    createRole: 'Maak Rol',
    creteARole: 'Maak een Rol',
    updateRole: 'Rol Bijwerken',
    assignPermissions: 'Toewijzen van Toestemmingen',
    noUserFound: 'Geen Gebruikersrollen Gevonden!',
    tableHeadingName: 'Naam',
    tableHeadingDescription: 'Beschrijving',
    tableHeadingStatus: 'Status',
    tableHeadingAction: 'Actie',
    module: 'Module',
    read: 'Lezen',
    write: 'Schrijven',
    remove: 'Verwijderen',
    notes: 'Notities',
    deleteTitle: 'Rol Verwijderen',
    deleteDescription: 'Let op: Wanneer u deze rol verwijdert, worden ook alle gebruikers die aan deze rol zijn toegewezen, verwijderd. \n Weet u zeker dat u deze rol wilt verwijderen?',
    deleteMemberDescription: 'Let op: Als je deze rol verwijdert, worden ook alle leden die aan deze rol zijn toegewezen verwijderd. Weet je zeker dat je deze rol wilt verwijderen?',
    placeholders: {
      enterRoleName: 'Voer de naam van de rol in',
      searchHere: 'Zoek hier',
      enterDescriptionForRole: 'Voer een beschrijving in voor de rol'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Secties waarvoor u gebruikers toegang kunt toestaan of blokkeren',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Klik om een nieuwe rol toe te voegen',
    }
  },
  accessControl: {
    users: 'Gebruikers',
    members: 'Leden',
    partnerTeam: 'Partner Team',
    roles: 'Rollen'
  }
};
