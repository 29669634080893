export const agency = {
  activities: {
    activities: 'Attività',
    upcomingRenewals: 'I prossimi rinnovi',
    subscriptionActivities: 'Attività di abbonamento',
    upcomingDowngradeRequests: 'Imminenti richieste di downgrade',
    serialNumber: 'Sr. No.',
    email: 'E-mail',
    phoneNumber: 'Telefono n.',
    currentPlan: 'Piano attuale',
    price: 'Prezzo',
    daysLeft: 'Giorni rimasti',
    noDataFound: {
      upcomingDowngrades: 'I prossimi downgrade',
      noUpcomingDowngradesFoundYet: 'Non sono ancora stati trovati i prossimi downgrade!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'I prossimi rinnovi dei clienti',
      subscriptionActivities: 'Attività di abbonamento',
      upcomingDowngradeRequestsOfCustomers: 'Imminenti richieste di downgrade dei clienti',
      serialNumber: 'Numero di serie',
      customerEmail: 'Email del cliente',
      customerPhoneNumber: 'Numero di telefono del cliente',
      currentPlanOfCustomer: 'Piano attuale del cliente',
      currentPlanPrice: 'Prezzo del piano attuale',
      daysLeft: 'Giorni rimasti del piano attuale dei clienti',
    },
  },
  configuration: {
    configurationsHeader: 'Configurazioni',
    emailHeader: 'E-mail',
    razorPayHeader: 'Razorpay',
    invoiceDetailsHeader: 'Dettagli della fattura',
    stripeHeader: 'Banda',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Integrare i dettagli del tuo provider di posta elettronica per inviare e -mail al cliente perfettamente.',
      enterYourRazorPayAccountDetails: `Inserisci i dettagli dell'account RazorPay per l'elaborazione dei pagamenti con soluzione di continuità.`,
      enterYourStripeAccountDetails: `Inserisci i dettagli dell'account Stripe per l'elaborazione dei pagamenti senza soluzione di continuità.`,
      enterYourCompanyDetails: 'Inserisci i dettagli della tua azienda per fatturazione accurata e generazione di fatture professionali.',
    },
    email: {
      testEmail: 'Email di prova',
      addEmailIntegrationsDetails: `Aggiungi dettagli sull'integrazione e -mail`,
      integrationDescription: 'Per integrare i dettagli del tuo provider di posta elettronica, fornire le informazioni sul tuo provider di posta elettronica. Tutte le e -mail di comunicazione verranno inviate ai tuoi clienti utilizzando questo indirizzo e -mail, garantendo una consegna coerente e affidabile di notifiche e aggiornamenti importanti.',
      awsRegion: 'Regione AWS',
      awsAccessKeyId: 'ID chiave di accesso AWS',
      awsSecretAccessKey: 'Chiave di accesso segreto AWS',
      reset: 'Ripristina',
      addDetails: 'Aggiungi dettagli',
      updateDetails: 'Aggiorna i dettagli',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Inserisci la tua regione AWS. La regione determina la posizione fisica delle risorse e influenza fattori come la latenza dei dati e la conformità normativa.',
        TOOLTIP_AWS_ACCESS_KEY_ID: `Inserisci il tuo ID chiave di accesso AWS. Questa chiave identifica in modo univoco il tuo account AWS ed è richiesta per l'autenticazione sicura e l'accesso ai servizi AWS.`,
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: `Inserisci il tuo tasto di accesso segreto AWS. Questa chiave viene utilizzata per l'autenticazione sicura e l'accesso ai servizi AWS.`,
      },
      placeholders: {
        enterAwsRegion: 'Inserisci la regione AWS',
        enterAwsAccessKeyId: `Immettere l'ID chiave di accesso AWS`,
        enterAwsSecretAccessKey: 'Immettere la chiave di accesso segreto AWS',
      },
      modal: {
        deleteEmailIntegration: `Elimina l'integrazione e -mail`,
        wantToDeleteEmailIntegration: 'Sei sicuro di voler eliminare questa integrazione e -mail?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: `Aggiungi i dettagli dell'integrazione di Facebook`,
      integrateYourEmailProviderDetails: 'Integra i dettagli del tuo provider di posta elettronica. Tutte le e -mail verranno inviate utilizzando questa e -mail ai tuoi clienti',
      facebookAppId: 'ID app di Facebook',
      facebookSecretAccessKey: 'Chiave di accesso segreto di Facebook',
      reset: 'Ripristina',
      cancel: 'Annulla',
      addDetails: 'Aggiungi dettagli',
      updateDetails: 'Aggiorna i dettagli',
      delete: 'Eliminare',
      testEmail: 'Email di prova',
      modal: {
        deleteFacebookIntegration: `Elimina l'integrazione di Facebook`,
        wantToDeleteFacebookIntegration: 'Sei sicuro di voler eliminare questa integrazione e -mail?',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Inserisci la chiave di accesso segreto di Facebook',
        enterFacebookAppId: `Inserisci l'ID app di Facebook`,
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalizza il messaggio come desideri che venga visualizzato sulla finestra della chat immediatamente dopo che un utente di bot invia una richiesta di chat live',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Aggiungi dettagli sulla fattura',
      invoicesDescription: `Per generare fatture per i tuoi clienti, fornisci i dettagli della tua azienda, inclusi il nome della tua azienda,
   l'indirizzo, le informazioni di contatto e qualsiasi altra informazione pertinente richiesta per una fatturazione accurata.
    Queste informazioni verranno utilizzate per creare fatture professionali per conto della tua azienda per ogni transazione.`,
      companyName: 'Nome della ditta',
      country: 'Paese',
      selectCountry: 'Seleziona il paese',
      stateOrRegion: 'Stato/regione',
      streetAddress: 'Indirizzo',
      gstin: 'Gstin',
      postalCode: 'Codice Postale',
      addDetails: 'Aggiungi dettagli',
      updateDetails: 'Aggiorna i dettagli',
      reset: 'Ripristina',
      delete: 'Eliminare',
      loadMore: 'Carica di più',
      time: 'Tempo',
      phoneNumber: 'Numero di telefono',
      email: 'E-mail',
      status: 'Stato',
      message: 'Messaggio',
      response: 'Risposta',
      placeOfSupply: 'Posizione di fornitura',
      placeholders: {
        enterYourCompanyName: 'Inserisci il nome della tua azienda',
        enterYourStateOrRegion: 'Inserisci il tuo stato/regione',
        enterYourStreetAddress: 'Inserisci il tuo indirizzo di strada',
        enterYourGstin: 'Inserisci il tuo gstin',
        enterPostalCode: 'Immettere il codice postale',
        enterPlaceOfSupply: 'Inserisci la posizione di fornitura'
      },
      modal: {
        deleteEmailIntegration: `Elimina l'integrazione e -mail`,
        wantToDeleteEmailIntegration: 'Sei sicuro di voler eliminare questa integrazione e -mail?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: 'Inserisci il nome della tua azienda. Queste informazioni verranno utilizzate per scopi di identificazione e branding nelle fatture.',
        TOOLTIP_COUNTRY: 'Seleziona il tuo paese. Queste informazioni verranno utilizzate per scopi di identificazione e branding nelle fatture.',
        TOOLTIP_STATE: 'Inserisci il tuo stato. Queste informazioni verranno utilizzate per scopi di identificazione e branding nelle fatture.',
        TOOLTIP_STREET_ADDRESS: 'Inserisci il tuo indirizzo di strada. Queste informazioni verranno utilizzate per scopi di identificazione e branding nelle fatture.',
        TOOLTIP_GSTIN: 'Inserisci il tuo GSTIN. Queste informazioni verranno utilizzate per scopi di identificazione e branding nelle fatture.',
        TOOLTIP_POSTAL_CODE: 'Inserisci il tuo codice postale. Queste informazioni verranno utilizzate per scopi di identificazione e branding nelle fatture.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Inserisci la posizione di fornitura. Queste informazioni verranno utilizzate per scopi di identificazione e branding nelle fatture.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: `Aggiungi i dettagli dell'integrazione di Razorpay`,
      integrationDescription: 'Per integrare i dettagli del tuo account RazorPay, fornisci le informazioni sul tuo account Razorpay. Tutti i pagamenti dei clienti verranno elaborati tramite il tuo account Razorpay per transazioni senza soluzione di continuità',
      razorPayAccessKeyId: 'ID chiave di accesso Razorpay',
      razorPaySecretAccessKey: 'Chiave di accesso segreto Razorpay',
      reset: 'Ripristina',
      addDetails: 'Aggiungi dettagli',
      updateDetails: 'Aggiorna i dettagli',
      loadMore: 'Carica di più',
      time: 'Tempo',
      phoneNumber: 'Numero di telefono',
      email: 'E-mail',
      status: 'Stato',
      message: 'Messaggio',
      response: 'Risposta',
      placeholders: {
        enterRazorPayAccessKeyId: `Immettere l'ID chiave di accesso RazorPay`,
        enterRazorPaySecretAccessKey: 'Immettere la chiave di accesso segreto Razorpay',
        enterRazorPayAccessId: `Immettere l'ID Accesso Razorpay`,
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Inserisci il tuo ID chiave di accesso RazorPay. Questa chiave identifica in modo univoco la tua integrazione con RazorPay ed è richiesta per le transazioni di pagamento sicure.',
        TOOLTIP_RAZORPAY_SECRET_KEY: `Inserisci la chiave di accesso segreto RazorPay. Questa chiave è essenziale per la comunicazione sicura tra applicazione e razorpay,
   consentendo l'elaborazione delle transazioni senza soluzione di continuità e la gestione dei pagamenti.`,
      },
      modal: {
        deleteRazorPayIntegration: `Elimina l'integrazione e -mail`,
        wantToDeleteThisIntegrtion: 'Sei sicuro di voler eliminare questa integrazione e -mail?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: `Aggiungi dettagli sull'integrazione delle strisce`,
      integrationDescription: `Per integrare i dettagli dell'account Stripe, fornire le informazioni sul tuo account Stripe.
   Tutti i pagamenti dei clienti verranno elaborati tramite il tuo account Stripe per transazioni senza soluzione di continuità`,
      stripeSecretAccessKey: 'Chiave di accesso segreto a strisce',
      stripePublicKey: 'Stripe Public Key',
      reset: 'Ripristina',
      addDetails: 'Aggiungi dettagli',
      updateDetails: 'Aggiorna i dettagli',
      cancel: 'Annulla',
      loadMore: 'Carica di più',
      time: 'Tempo',
      phoneNumber: 'Numero di telefono',
      email: 'E-mail',
      status: 'Stato',
      message: 'Messaggio',
      response: 'Risposta',
      placeholders: {
        enterStripeSecretAccessKey: 'Immettere la chiave di accesso segreto a strisce',
        enterStripePublicKey: 'Inserisci la chiave pubblica Stripe',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: `Inserisci la chiave di accesso segreto Stripe. Questa chiave viene utilizzata per autenticare in modo sicuro la tua integrazione con Stripe ed è necessaria per l'elaborazione dei pagamenti dei clienti.`,
        TOOLTIP_PUBLIC_KEY: `Inserisci la tua chiave pubblica a strisce. Questa chiave viene utilizzata per autenticare in modo sicuro la tua integrazione con Stripe sul lato client ed è necessaria per l'elaborazione dei pagamenti dei clienti.`,
      },
      modal: {
        deleteStripeIntegration: `Elimina l'integrazione delle strisce`,
        wantToDeleteStripeIntegration: 'Sei sicuro di voler eliminare questa integrazione a strisce?',
      }
    }
  },
  customer: {
    manageCustomers: 'Gestisci i clienti',
    addNewCustomers: 'Aggiungi nuovi clienti',
    ensureYouHaveAddedAllYourCustomersHere: 'Assicurati di aver aggiunto tutti i tuoi clienti qui',
    customerName: 'Nome del cliente',
    nameIsRequired: 'È richiesto il nome',
    emailId: 'E-mail identificativo utente',
    enterAValidEmail: `Inserisci un'e -mail valida`,
    createPassword: 'Crea password',
    enterAStrongPassword: 'Immettere una password forte',
    confirmPassword: 'Conferma password',
    cancel: 'Annulla',
    customer: 'Cliente',
    searchByNameOrEmail: 'Cerca per nome o e -mail',
    filters: 'Filtri',
    allCustomers: 'Tutti i clienti',
    paidCustomers: 'Clienti a pagamento',
    freeCustomers: 'Clienti gratuiti',
    messageAddOnCustomers: 'Messaggio Aggiungi ai clienti',
    botWhiteLabelCustomers: 'Clienti di bot whitelabel',
    sortBy: 'Ordina per',
    sortByFrequency: 'Ordina per frequenza',
    all: 'Tutto',
    joinedDate: 'Data unita',
    plan: 'Piano',
    lastLogin: 'Ultimo accesso',
    email: 'E-mail',
    nextDue: 'Successivamente',
    frequency: 'Frequenza',
    clearAll: 'Cancella tutto',
    apply: 'Fare domanda a',
    messageConsumed: 'Messaggio consumato',
    addCustomer: 'Aggiungi il cliente',
    serialNumber: 'Sr. No.',
    name: 'Nome',
    contact: 'Contatto',
    limit: 'Limite',
    location: 'Posizione',
    actions: 'Azioni',
    joinedOn: 'Uniti',
    typeOfCustomer: 'Tipo di cliente',
    exceptWhatsapp: ' (Tranne WhatsApp)',
    exceptWA: '(Tranne WA)',
    wa: '(WA)',
    loadMore: 'Carica di più',
    conversationsWhatsapp: ' Conversazioni (whatsapp)',
    appTooltip: {
      enterCustomerEmail: `Inserisci l'e -mail del cliente`,
      enterCustomerPassword: 'Immettere la password del cliente',
      enterCustomerConfirmPassword: 'Immettere la password di conferma del cliente',
      enterCustomerName: 'Immettere il nome del cliente',
    },
    placeholders: {
      enterCustomerEmail: `Inserisci l'e -mail del cliente`,
      searchByNameOrEmail: 'Cerca per nome o e -mail',
      enterPassword: 'Inserire la password',
      enterCustomerName: 'Immettere il nome del cliente',
    }
  },
  dashboard: {
    dashboard: 'Pannello di controllo',
    overview: 'Panoramica',
    totalCustomers: 'Clienti totali',
    paidCustomers: 'Clienti a pagamento',
    freeCustomers: 'Clienti gratuiti',
    thisWeeksSignUp: 'Iscriviti a questa settimana',
    todaysSignUp: 'Le iscrizioni di oggi',
    totalEarnings: 'Guadagni complessivi',
    nA: 'N / A',
    customerName: 'Nome del cliente',
    nameIsRequired: 'È richiesto il nome',
    emailId: 'E-mail identificativo utente',
    enterAValidEmail: `Inserisci un'e -mail valida`,
    editCustomer: 'Modifica il cliente',
    recentSignUps: 'Recenti iscritti',
    serialNumber: 'Sr. No.',
    name: 'Nome',
    email: 'E-mail',
    contact: 'Contatto',
    plan: 'Piano',
    limit: 'Limite',
    location: 'Posizione',
    lastLogin: 'Ultimo accesso',
    joinedOn: 'Uniti',
    actions: 'Azioni',
    exceptWhatsapp: ' (Tranne WhatsApp)',
    messageConsumed: 'Messaggio consumato',
    conversationsWhatsapp: ' Conversazioni (whatsapp)',
    placeholders: {
      enterCustomerName: 'Immettere il nome del cliente',
      enterCustomerEmail: `Inserisci l'e -mail del cliente`,
    },
    modal: {
      deleteCustomer: 'Elimina il cliente',
      areYouSure: 'Sei sicuro di voler eliminare questo cliente?',
    }
  },
  downgradeRequests: {
    activites: 'Attività',
    upcomingRenewals: 'I prossimi rinnovi',
    subscriptionActivities: 'Attività di abbonamento',
    upcomingDonwgradeRequests: 'Imminenti richieste di downgrade',
    serialNumber: 'Sr. No.',
    email: 'E-mail',
    phoneNumber: 'Telefono n.',
    currentPlan: 'Piano attuale',
    requestedOn: 'Richiesto',
    downgradeOn: 'Downgrade',
    nA: 'N / A',
    commaMonthly: ', Mensile',
    commaYearly: ' , Annuale',
    noDataFound: {
      downgradeRequests: 'Richieste di downgrade',
      noDowngradeRequestFound: 'Nessuna richiesta di downgrade ancora trovata!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'I prossimi rinnovi dei clienti',
      subscriptionActivities: 'Attività di abbonamento',
      upcomingDowngradeOfCustomers: 'Imminenti richieste di downgrade dei clienti',
      serialNumber: 'Numero di serie',
      customerEmail: 'Email del cliente',
      customerPhoneNumber: 'Numero di telefono del cliente',
      currentPlanOfCustomer: 'Piano attuale del cliente',
      currentPlanPrice: 'Prezzo del piano attuale',
    }
  },
  emailIntegration: {
    emailIntegration: 'Integrazione e -mail',
    addEmailIntegrationDetails: `Aggiungi dettagli sull'integrazione e -mail`,
    integrateYourEmailProviderDetails: 'Integra i dettagli del tuo provider di posta elettronica. Tutte le e -mail verranno inviate utilizzando questa e -mail ai tuoi clienti',
    awsRegion: 'Regione AWS',
    awsRegionIsRequired: 'È richiesta la regione AWS',
    awsAccessKeyId: 'ID chiave di accesso AWS',
    awsAccessKeyIdIsRequired: `È richiesto l'ID chiave di accesso AWS`,
    awsSecretAccessKey: 'Chiave di accesso segreto AWS',
    awsSecretAccessKeyIsRequired: 'È richiesta la chiave di accesso segreto AWS',
    cancel: 'Annulla',
    test: 'Test',
    action: 'Azione',
    testEmail: 'Email di prova',
    addDetails: 'Aggiungi dettagli',
    updateDetails: 'Aggiorna i dettagli',
    saveDetails: 'Salva i dettagli',
    placeholders: {
      enterAwsRegion: 'Inserisci la regione AWS',
      enterAwsAccessKeyId: `Immettere l'ID chiave di accesso AWS`,
      enterAwsSecretAccessKey: 'Immettere la chiave di accesso segreto AWS',
    },
    modal: {
      deleteEmailIntegration: `Elimina l'integrazione e -mail`,
      areYouSure: 'Sei sicuro di voler eliminare questa integrazione e -mail?',
    },
    noDataFound: {
      emailIntegration: 'Integrazione e -mail',
      noEmailIntegrationFoundYet: 'Nessuna integrazione e -mail ancora trovata!',
    }
  },
  subscription: {
    activities: 'Attività',
    upcomingRenewals: 'I prossimi rinnovi',
    subscriptionActivities: 'Attività di abbonamento',
    upcomingDonwgradeRequests: 'Imminenti richieste di downgrade',
    filters: 'Filtri',
    manageActivities: 'Gestisci le attività',
    sortBy: 'Ordina per',
    all: 'Tutto',
    upgrades: 'Aggiornamenti',
    donwgrades: 'Downgrade',
    renewals: 'Rinnovo',
    serialNumber: 'Sr. No.',
    email: 'E-mail',
    phoneNumber: 'Telefono n.',
    previousPlan: 'Piano precedente',
    newPlan: 'Nuovo piano',
    description: 'Descrizione',
    date: 'Data',
    nA: 'N / A',
    upgraded: 'aggiornato',
    commaYearly: ' , Annuale',
    commaMonthly: ' , Mensile',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'Nessuna attività di abbonamento ancora trovata!',
      subscriptionsActivities: 'Attività di abbonamenti',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'I prossimi rinnovi dei clienti',
      subscriptionActivities: 'Attività di abbonamento',
      upcomingDowngradeRequests: 'Imminenti richieste di downgrade dei clienti',
      serialNumber: 'Numero di serie',
      customerEmail: 'Email del cliente',
      customerPhoneNumber: 'Numero di telefono del cliente',
      previousPlan: 'Piano precedente del cliente',
      newPlan: 'Nuovo piano del cliente',
      description: 'Descrizione del piano',
      typeOfPayment: 'Tipo di pagamento',
    }
  },
  feedbacks: {
    feedbacks: 'Feedback',
    serialNumber: 'Sr. No.',
    customer: 'Cliente',
    type: 'Tipo',
    rating: 'Valutazione',
    reason: 'Motivo',
    description: 'Descrizione',
    createdAt: 'Creato a',
    nA: 'N / A',
    loadMore: 'Carica di più',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: `Feedback di cancellazione dell'account e bot`,
      noFeedbacksFoundYet: 'Nessun feedback ancora trovato!',
    },
    appTooltip: {
      serialNumber: 'Numero di serie',
      customerEmail: 'Email del cliente',
      feedbackForBotOrAccountDeletion: 'Commissione per (eliminazione bot/account)',
      customerRating: 'Valutazione del cliente',
      customerReason: 'Motivo del cliente',
      feedbackCreatedAt: 'Feedback creato a',
      customerFeedback: 'Feedback del cliente',
    }
  }
};
