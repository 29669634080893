export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'Se ha enviado un enlace de activación, verifique su correo electrónico',
    weHaveSentActivationLinkToEmailAddress: 'Hemos enviado un enlace de activación a la dirección de correo electrónico',
    toVerifyYourAccount: 'para verificar su cuenta.',
    onceVerifiedAutomaticallyRedirected: 'Una vez verificado, serás redirigido automáticamente al panel.',
    didNotReceiveAnEmail: '¿No recibió un correo electrónico? Revise su carpeta de spam',
    resendIn: 'Reiniciar',
    seconds: ' segundos',
    resend: 'Reenviar',
    backToSignUp: 'Volver para inscribirse',
    greatPleaseCheckYourMessage: 'Genial, por favor revise su correo electrónico',
    weHaveSentEmailTo: 'Hemos enviado un correo electrónico a',
    withALinkToReset: ' con un enlace para restablecer su cuenta. Una vez activado, podrá finalizar la creación de su cuenta y comenzar su prueba gratuita.',
    resent: 'Resentirse de',
    backToLogin: 'Atrás para iniciar sesión',
  },
  forgotPassword: {
    forgotPassword: '¿Has olvidado tu contraseña?',
    noWorries: 'No hay problema,',
    weWillSendYouResetPasswordInstructions: 'Le enviaremos la instrucción de contraseña de restablecimiento',
    workEmail: 'Correo electrónico del trabajo',
    continue: 'Continuar',
    backToLogin: 'Atrás para iniciar sesión',
    placeholders: {
      enterYourEmail: 'Introduce tu correo electrónico',
    }
  },
  logout: {
    logout: 'Cerrar sesión',
    wantToLogOut: '¿Estás seguro de que quieres cerrar sesión?',
    yesIWillBeBack: 'Si, volveré',
    cancel: 'Cancelar',
  },
  newPassword: {
    createNewPassword: 'Crear nueva contraseña',
    yourNewPasswordMustBeDifferent: 'Su nueva contraseña debe ser diferente a las contraseñas utilizadas anteriormente.',
    password: 'Contraseña',
    confirmPassword: 'confirmar Contraseña',
    setPassword: 'Configurar la clave',
    signUp: 'Inscribirse',
    login: 'Acceso',
    backTo: 'De regreso',
    setAPassword: 'Establecer una contraseña',
    setNewPasswordToYourAccount: 'Establezca una nueva contraseña en su cuenta para fines de seguridad y privacidad',
    backToSignUp: 'Volver para inscribirse',
    placeholders: {
      enterYourPassword: 'Ingresa tu contraseña',
    }
  },
  resetPassword: {
    passwordReset: 'Restablecimiento de contraseña',
    yourPasswordHasBeenSuccessfullyReset: 'Su contraseña se ha restablecido correctamente.',
    clickBelowToLogIn: 'Haga clic a continuación para iniciar sesión',
    continueToLogin: 'Continuar iniciando sesión',
  },
  newSignUp: {
    welcomeBack: 'Bienvenido de nuevo',
    to: 'a',
    logIn: 'Acceso',
    as: 'como',
    withFacebook: 'con Facebook',
    logInWithGoogle: 'Inicie sesión con Google',
    or: 'O',
    pleaseFillAValidWorkEmail: 'Por favor, complete un correo electrónico de trabajo válido*',
    workEmailIsRequired: 'Se requiere correo electrónico de trabajo*',
    pleaseFillAValidPassword: 'Por favor, complete una contraseña válida*',
    passwordIsRequired: 'Se requiere contraseña*',
    rememberMe: 'Acuérdate de mí',
    forgotPassword: '¿Has olvidado tu contraseña?',
    login: 'Acceso',
    doNotHaveAnAccount: '¿No tiene una cuenta?',
    setUpAFreeAccount: 'Configurar una cuenta gratuita',
    noCreditCardRequired: 'No se requiere tarjeta de crédito',
    cancelAnytime: 'Cancelar en cualquier momento',
    signUp: 'Inscribirse',
    signUpWithGoogle: 'Regístrese con Google',
    emailIsAlreadyRegistered: 'El correo electrónico ya está registrado con nosotros, haga clic en Iniciar sesión para continuar',
    phoneNumberShouldHaveMinimum7Digits: 'El número de teléfono debe tener un mínimo de 7 dígitos*',
    phoneNumberIsRequired: 'Se requiere número de teléfono*',
    passwordMustContain: 'La contraseña debe contener',
    atleast6Characters: 'al menos 6 caracteres,',
    oneUpperCaseLetter: '1 letra mayúscula',
    oneSpecialCharacter: '1 personaje especial*',
    alreadyHaveAccount: '¿Ya tienes una cuenta?',
    signingUpToBotPenguinIsAConsentToOur: 'Registrarse en una cuenta de botpenguin es su consentimiento para nuestro',
    privacyPolicy: 'política de privacidad',
    termsAndConditions: 'Términos y condiciones',
    and: ' y',
    english: 'Inglés',
    hindi: 'hindi',
    german: 'Alemán',
    french: 'Francés',
    portuguese: 'Portugués',
    spanish: 'Español',
    italian: 'italiano',
    russian: 'ruso',
    placeholders: {
      workEmail: 'Correo electrónico del trabajo*',
      password: 'Contraseña*',
      phone: 'Teléfono*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Desuscripción exitosa',
    yourEmail: 'Tu correo electrónico ',
    haveBeenSuccessfullyUnsubscribedFrom: ' ha sido desuscrito con éxito de',
    backToLogin: 'Volver a Iniciar sesión'
  }
};
