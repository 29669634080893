const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Редактировать поток чата',
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    classicBuilder: 'Классический строитель',
    visualiseFlow: 'Визуализировать поток',
    testLiveOnTelegramWeb: 'Тестировать в прямом эфире на телеграмме.',
    testFlow: 'Тестовый поток',
    importTemplate: 'Импорт шаблон',
    landingPageBotReview: 'Предварительный просмотр целевой страницы',
    install: 'Установить',
    testLiveOnMessengerWeb: 'Тестировать в прямом эфире на сети Messenger',
    testLiveOnWhatsappWeb: 'Тестировать в прямом эфире на WhatsApp Web',
    testLiveOnInstagramWeb: 'Тестировать в прямом эфире в Интернете Instagram',
    seeAllChatFlow: 'Смотрите все потоки чата',
    launchDemoModal: 'Запустить демонстрацию модала',
    selectATemplateToKickStartYourBot: 'Выберите шаблон, чтобы начать свой бот',
    hireChatBotDesigner: 'Нанять дизайнера чатбота',
    recommendedForYou: 'Рекомендуется для вас',
    useTemplate: 'Используйте шаблон',
    exportStatus: 'Статус экспорта',
    flowExport: 'Поток- экспорт',
    download: 'Скачать',
    importFlow: 'Импортный поток',
    beforeYouStartUploadPleaseMakeSure: 'Прежде чем начать загрузку, пожалуйста, убедитесь',
    ponit1: '1. Максимальный предел загрузки размера файла составляет 1 МБ',
    point2: '2. Формат файла должен быть в JSON',
    selectFile: 'Выберите файл',
    preview: 'Предварительный просмотр',
    test: 'Тест',
    OopsIntegrationAreNotAvailable: 'Ой! Интеграции не доступны в ',
    pleaseUpgradeTo: ' плане. Пожалуйста, перейдите на ',
    planForIntegration: ' план для интеграций.',
    placeholders: {
      searchTemplateYouNeed: 'Ищите шаблон, который вам нужен',
    },
    noDataFound: {
      botFlow: 'Ботс поток',
      noBotFlowAddedYet: 'Поток бота пока не добавлен!',
      templates: 'Шаблоны',
      noTemplatesFound: 'Шаблон не найдено!',
    },
    appTooltip: {
      exportFlow: 'Экспортный поток',
      importFlow: 'Импортный поток',
      clickToViewChatFlow: 'Нажмите, чтобы просмотреть поток чата в застройке перетаскивания',
      clickToPreviewChatFlow: 'Нажмите, чтобы предварительно просмотреть поток чата',
      clickToViewChatFlowAsFlowDiagram: 'Нажмите, чтобы просмотреть поток чата в виде потоковой диаграммы',
      clickToTestChatFlow: 'Нажмите, чтобы проверить поток чата',
      clickToSelectAndImportTemplate: 'Нажмите, чтобы выбрать и импортировать шаблон, который лучше всего подходит для вашего бизнеса.',
      clickToInstallYourBotToSelectedPlatform: 'Нажмите, чтобы установить свой бот на выбранную платформу',
      clickToTestDefaultFlowOfTelegramBot: 'Нажмите, чтобы протестировать свой бот по умолчанию (активный) поток на телеграмме.',
      clickToTestDefaultFlowOfFacebookBot: 'Нажмите, чтобы протестировать свой бот по умолчанию (активный) поток в Интернете Facebook',
      clickToTestDefaultFlowOfWhatsappBot: 'Нажмите, чтобы протестировать свой бот по умолчанию (активный) поток в WhatsApp Web',
      clickToTestDefaultFlowOfInstagramBot: 'Нажмите, чтобы протестировать свой бот по умолчанию (активный) поток в Интернете Instagram',
    }
  },
  createNewBot: {
    createNewBot: 'Создать нового бота',
    stepOneOfTwo: 'Шаг 1 из 2',
    selectThePlatform: 'Выберите платформу',
    selectTheBotType: 'Выберите тип бота',
    aiBasedChatbot: 'Чат-бот на базе ИИ',
    ruleBasedChatBot: 'Чат-бот на основе правил',
    webPlatforms: 'Веб -платформы',
    websiteChatBot: 'Веб -сайт чат -бот',
    withLiveChat: '(С живым чатом)',
    landingPageBot: 'Целевая страница бот',
    liveChatWidget: 'Виджет живого чата',
    onlyLiveChat: '(Только живой чат)',
    socialPlatforms: 'Социальные платформы',
    whatsappChatBot: 'WhatsApp Chatbot',
    telegramChatBot: 'Телеграмма Чатбот',
    facebookChatBot: 'Facebook Chatbot',
    instagramChatBot: 'Instagram Chatbot',
    selectBotType: 'Выберите тип бота',
    selectProblem: 'Выберите проблему, которую вы хотите решить',
    leadGeneration: 'Генерация лидов',
    customerSupport: 'Поддержка клиентов',
    appointmentBooking: 'Бронирование приемов',
    marketingAutomation: 'Маркетинговая автоматизация',
    ecommerceWebsite: 'Сайт электронной коммерции',
    otherUseCase: 'Другое',
    back: 'Назад',
    typeDomainUrl: 'Введите URL вашего веб-сайта для проверки.',
    dataSourceForChatBotTraining: `Источник данных для обучения чат-бота`,
    upload: 'Загрузить',
    uploadDocuments: 'Загрузить документы',
    uploadDocument: 'Загрузить документ',
    selectFile: 'Выберите файл',
    continue: 'Продолжить',
    websiteUrl: 'Введите URL вашего сайта',
    skipNow: 'Пропустить сейчас',
    completeTheExistingBotSetup: 'Завершить настройку существующего бота',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Вы уже создали бота WhatsApp. Пожалуйста, завершите настройку, чтобы создать нового.',
    completeSetup: 'Завершить настройку',
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    fbBot: {
      createNewBot: 'Создать нового бота',
      help: 'Помощь',
      back: 'Назад',
      step: 'Шаг',
      of3: ' 3',
      loginWithFacebook: 'Войти с Facebook',
      asPerLatestChangesInFacebookPolicy: 'Согласно последним изменениям в политиках Facebook, владелец бота Facebook должен иметь роль «менеджера» или выше, чтобы получить доступ к всей функции страницы Facebook.',
      login: 'Авторизоваться',
      connectFacebookBot: 'Подключить Facebook Bot',
      connectBotWithYourFacebookPagesAnd: 'Подключите бот со страницами на Facebook и',
      exploreTruePowerOfFacebook: 'Исследуйте истинную силу Facebook',
      connect: 'Соединять',
      connected: 'Связанный',
      createNewPage: 'Создайте новую страницу',
      refreshPageList: 'Список страниц обновления',
      thisPageIsSuccessfullyIntegratedWith: 'Эта страница успешно интегрирована с',
      yourMessagesFromMessengerWillAppearIn: 'Полем Ваши сообщения от Messenger появятся в',
      inbox: ' Входящие',
      continueToBotConfiguration: 'Продолжить конфигурацию бота',
      welcomeToBrandNameWeAreHereToHelpYou: `Добро пожаловать в ${brand}. Мы здесь чтобы помочь вам.`,
      letsTryYourBotAndSeeHowItWorks: 'Давайте попробуем свой бот и посмотрим, как он работает! Нажмите «Тест на Facebook».',
      howAreYouDoingToday: 'Как у тебя сегодня дела?',
      thisIsHowSimpleToUseFacebookBot: 'Вот это! Вот как просто использовать бот Facebook',
      letsCreateYourOwnChatFlowNow: 'Давайте сейчас создаем свой собственный поток чата',
    },
    instagramBot: {
      createNewBot: 'Создать нового бота',
      back: 'Назад',
      step: 'Шаг',
      of3: ' 3',
      loginWithInstagram: 'Войдите в Instagram',
      loginToYourInstagramAccount: 'Войдите в свою учетную запись Instagram, чтобы включить чат -бот на вашей ручке.',
      managerLevelDescription: 'Владельцы бота в Instagram должны иметь как минимум уровень разрешений на «менеджера» или выше, чтобы получить доступ ко всем функциям.',
      login: 'Авторизоваться',
      connectInstagramBot: 'Подключить Instagram Bot',
      connectBotWithYourInstagramAnd: 'Подключите бот с вашим Instagram и',
      exploreTruePowerOfInstagram: ' Исследуйте истинную силу Instagram',
      connected: 'Связанный',
      connect: 'Соединять',
      createNewPage: 'Создайте новую страницу',
      refreshPageList: 'Список страниц обновления',
      thisPageIsSuccessfullyintegratedWith: 'Эта страница успешно интегрирована с',
      yourMessagesFromMessengerWillAppearIn: 'Полем Ваши сообщения от Messenger появятся в',
      inbox: ' Входящие',
      continueToBotConfiguration: 'Продолжить конфигурацию бота',
      welcomeToBrandNameWeAreHereToHelpYou: `Добро пожаловать в ${brand}. Мы здесь чтобы помочь вам.`,
      letsTryYourBotAndSeeHowItWorks: 'Давайте попробуем свой бот и посмотрим, как он работает! Нажмите «Тест в Instagram».',
      howAreYouDoingToday: 'Как у тебя сегодня дела?',
      thisIsHowSimpleToUseInstagramBot: 'Вот и все! Вот как просто использовать бот Instagram',
      letsCreateYourOwnChatFlowNow: 'Давайте сейчас создаем свой собственный поток чата',
    },
    telegramBot: {
      back: 'Назад',
      step: 'Шаг',
      of3: ' 3',
      seeHowToUse: 'Посмотрите, как использовать',
      help: 'Помощь',
      loginToYourTelegramAccount: 'Войдите в свою учетную запись Telegram',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Войдите в свою учетную запись, чтобы создать нового бота или подключить существующий бот',
      verificationCodeSentOnRegisteredNumber: 'Код проверки, отправленный по зарегистрированному номеру',
      createYourBot: 'Создайте своего бота',
      enterYourBotsNameAnd: 'Введите имя вашего бота, подключитесь и пусть веселье начнется с',
      botUserNameMustEndWithBotAtTheEnd: 'Имя пользователя BOT должно закончиться «ботом» в конце.',
      terrisBot: 'Например. teris_bot или terisbot',
      botNameAlreadyTaken: 'Это имя бота уже взято. Пожалуйста, попробуйте что -нибудь другое',
      connect: 'Соединять',
      createBot: 'Создать бот',
      codeNotReceivedResend: 'Код не получен? Отправить!',
      changeNumber: 'Изменить номер',
      telegramPreview: 'Предварительный просмотр телеграммы',
      worldsFastestMessagingApp: 'Самое быстрое приложение для обмена сообщениями мира и его бесплатно!',
      bot: 'Бот',
      phoneNumber: 'Номер телефона',
      userName: 'Имя пользователя',
      none: 'Никто',
      bio: 'Биография',
      fewWordsAboutYourself: 'Несколько слов о себе',
      settings: 'Настройки',
      notificationsAndSound: 'Уведомления и звуки',
      privacyAndSecurity: 'Конфиденциальность и безопасность',
      dataAndStorage: 'Данные и хранение',
      chatSettings: 'настройки чата',
      chatFolders: 'Папки чата',
      devices: 'Устройства',
      connectYourBot: 'Подключите своего бота',
      createNewBotOrConnectWithExistingOne: 'Создайте новый бот или подключите существующий',
      createNewBot: 'Создать нового бота',
      welcomeToBrandNameWeAreHereToHelpYou: `Добро пожаловать в ${brand}. Мы здесь чтобы помочь вам.`,
      letsTryYourBotAndSeeHowItWorks: 'Давайте попробуем свой бот и посмотрим, как он работает! Нажмите «Тест на телеграмме».',
      howAreYouDoingToday: 'Как у тебя сегодня дела?',
      thisIsHowSimpleToUseTelegramBot: 'Вот это! Вот как просто использовать Telegram Bot',
      letsCreateYourOwnChatFlowNow: 'Давайте сейчас создаем свой собственный поток чата',
      placeholders: {
        searchCountry: 'Страна поиска',
        enterTelegramMobileNumber: 'Введите номер мобильного телеграммы',
        enterLoginCodeReceived: 'Введите код входа в систему, полученный в учетной записи Telegram *',
        twoFactorAuthentication: '2FA пароль (необязательно)',
        botName: 'Название бота *',
        botUserName: 'Имя пользователя бота *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Создать нового бота',
      seeHowToUse: 'Посмотрите, как использовать',
      help: 'Помощь',
      back: 'Назад',
      step: 'Шаг',
      of2: ' 2',
      buildA: 'Построить',
      thatMatchesYourBrandPersonality: ' это соответствует личности вашего бренда',
      chatBotIcon: 'Значок чат -бот',
      optional: 'Необязательный',
      themeColor: 'Цвет темы',
      chatBotName: 'Имя чат -бота',
      botNameIsRequired: 'Требуется имя бота',
      botNameShouldBeLessThan50Characters: 'Имя бота должно быть менее 50 символов',
      continue: 'Продолжать',
      welcomeToBrandNameWeAreHereToHelpYou: `Добро пожаловать в ${brand}. Мы здесь чтобы помочь вам.`,
      whatIsYourEmailAddress: 'Какой твой почтовый адрес?',
      invalidEmailAddress: 'Неверный адрес электронной почты',
      customizeYourLandingPageBotLook: 'Настройте свой вид целевой страницы',
      customizeYourWidgetLook: 'Настройте внешний вид виджета',
      customizeYourBotLook: 'Настройте образ своего бота',
      buildYourLandingPageBotAndAutomateInteraction: 'Создайте свою целевую страницу и автоматизируйте взаимодействие с вашими клиентами, поделившись его ссылкой',
      useLiveChatBotToConnectToCustomers: 'Используйте живой чат -бот, чтобы подключиться к своим клиентам в режиме реального времени',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Подключите чат -бот к вашему веб -сайту и автоматизируйте взаимодействие с посетителями',
      widget: 'виджет',
      botInterface: 'Интерфейс бота',
      mergedBot: 'Чатбот для веб-сайта, Чатбот для посадочной страницы, Чатбот для мобильного приложения',
      mergedBotToolTip: 'Создайте страницу бота, чтобы привлечь внимание посетителей и вовлечь их в разговоры.',
      placeholders: {
        typeANameForYourBot: 'Введите имя для своего нового бота',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Введите свое имя бота',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Выберите цвет темы для окна чата',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Этот логотип будет использоваться только на платформе ${brand ? brand : ''}. Мы предлагаем выбрать логотип вашей компании. Добавьте изображение PNG или JPG в квадрате под 1 МБ.`,
      }
    },
    whatsappBot: {
      back: 'Назад',
      step: 'Шаг',
      justAStepAway: 'Всего в шаге',
      seeHowToUse: 'Посмотрите, как использовать',
      help: 'Помощь',
      createWhatsappChatBot: 'Создайте чат WhatsApp',
      businessNameIsRequired: 'Название бизнеса требуется.',
      maximumLengthForBusinessName40Characters: 'Максимальная длина для названия бизнеса составляет 40 символов.',
      businessCategory: 'Бизнес -категория',
      categoryIsRequired: 'Категория требуется.',
      descriptionIsRequired: 'Описание требуется.',
      maximumLengthForDescription200Characters: 'Максимальная длина для описания составляет 200 символов.',
      pleaseFillAValidEmailAddress: 'Пожалуйста, заполните действительное деловое электронное письмо.',
      businessEmailIsRequired: 'Деловая электронная почта требуется.',
      phoneNumberShouldHaveMinimum7Digits: 'Номер телефона должен иметь минимум 7 цифр',
      phoneNumberIsRequired: 'Требуется номер телефона',
      websiteIsRequired: 'Сайт требуется.',
      maximumLengthForWebsite40Characters: 'Максимальная длина для веб -сайта составляет 40 символов.',
      maximumLengthForAddress150Characters: ' Максимальная длина для адреса составляет 150 символов.',
      doYouHaveWhatsappApiKey: 'У вас есть ключ API WhatsApp?',
      apiProvider: 'Поставщик API',
      three60Dialog: '360 диалог',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'Ключ API требуется.',
      whatsappBusinessAccountIdIsRequired: 'Требуется идентификатор учетной записи Businness WhatsApp.',
      whatsappPhoneNumberIdIsRequired: ' Требуется идентификатор номера телефона WhatsApp.',
      systemAccessTokenIsRequired: 'Требуется токен доступа к системе.',
      sourceIdIsRequired: 'Требуется идентификатор источника.',
      // apiKeyIsRequired: 'Ключ API требуется.',
      // createWhatsappChatBot: 'Создайте чат WhatsApp',
      businessName: 'Наименование фирмы',
      businessAccount: 'Бизнес аккаунт',
      // businessCategory: 'Бизнес -категория',
      businessDescription: 'Описание деятельности',
      address: 'Адрес',
      businessEmail: 'Деловое письмо',
      websiteUrl: 'URL веб-сайта',
      thankYouForYourInterestIn: 'Спасибо за ваш интерес к',
      whatsappChatBot: ' WhatsApp Chatbot.',
      weHaveReceivedYourInterests: 'Мы получили ваш интерес, и наша команда по продажам свяжется с вами в ближайшие 24 часа.',
      theyWillProvideYouWhatsappApiKey: 'Они предоставят вам ключ WhatsApp API.',
      readyToCreateWhatsappBot: 'Я готов создать бот WhatsApp',
      exploreWhatsappFeatures: 'Изучите функции WhatsApp',
      welcomeToBrandNameWeAreHereToHelpYou: `Добро пожаловать в ${brand}. Мы здесь чтобы помочь вам.`,
      letsTryYourBotAndSeeHowItWorks: 'Давайте попробуем свой бот и посмотрим, как он работает! Нажмите «Тест на WhatsApp».',
      howAreYouDoingToday: 'Как у тебя сегодня дела?',
      thisIsHowSimpleToUseWhatsappBot: 'Вот это! Вот как просто использовать бот WhatsApp',
      letsCreateYourOwnChatFlowNow: 'Давайте сейчас создаем свой собственный поток чата',
      onnectWhatsAppBot: 'Подключить WhatsApp бота',
      proceed: 'Продолжить',
      whatsApiProvider: 'Провайдер WhatsApp API',
      cloudApi: 'Cloud API',
      netcoreApi: 'Netcore API',
      createtWhatsAppBot: 'Создать бота WhatsApp',
      placeholders: {
        yourBusinessName: 'Название вашей компании',
        businessDescription: 'Описание деятельности',
        businessEmail: 'Деловое письмо',
        phone: 'Телефон*',
        website: 'Веб -сайт (например, www.example.com)',
        address: 'Адрес',
        enterApiKey: 'Введите ключ API',
        enterWhatsappBusinessAccountId: 'Введите идентификатор учетной записи WhatsApp Businness',
        enterWhatsappPhoneNumberId: 'Введите идентификатор номера телефона WhatsApp',
        enterSystemAccessToken: 'Введите токен доступа к системе',
        enterSourceId: 'Введите идентификатор источника',
        businessName: 'Название компании',
        businessWebsite: 'Веб-сайт компании',
        selectWhatsappApiProvider: 'Выберите провайдера WhatsApp API',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'Выберите своего провайдера WhatsApp API',
        doYouHaveWhatsAppBusinessAPI: 'У вас есть WhatsApp Business API?',
        yes: 'Да',
        noIAmNotSure: 'Нет, не уверен(а)',
        selectApiProvider: 'Выберите провайдера API',
        whatsAppCloudAPI: 'WhatsApp Cloud API',
        enterYourPhoneNumberId: 'Введите ваш идентификатор номера телефона',
        phoneNumberId: 'Идентификатор номера телефона',
        enterYourWhatsAppBusinessAccountId: 'Введите идентификатор вашего бизнес-аккаунта WhatsApp',
        whatsAppBusinessAccountId: 'Идентификатор бизнес-аккаунта WhatsApp',
        enterYourSystemUserToken: 'Введите токен системного пользователя',
        systemUserToken: 'Токен системного пользователя',
        help: 'Помощь',
        netcoreAPI: 'Netcore API',
        enterYourSourceId: 'Введите идентификатор источника',
        sourceId: 'Идентификатор источника',
        enterYourNetcoreApiKey: 'Введите ваш ключ API Netcore',
        netcoreApiKey: 'Ключ API Netcore',
        submit: 'Отправить',
        noneOftheAbove: 'Ничего из вышеперечисленного',
        next: 'Далее'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Юридически зарегистрированный бизнес',
        validWebsiteWithDataPrivacyPolicy: 'Действительный веб-сайт с политикой конфиденциальности данных',
        gstin: 'GSTIN',
        debit: 'Дебетовая карта',
        creditCard: 'Кредитная карта',
        onlyRequiredForIndianResidents: 'Требуется только для жителей Индии',
        validPaymentMethod: 'Действительный способ оплаты',
        onlyRequiredFor: 'Требуется только для',
        indianResidents: 'жителей Индии',
        enabledWithInternationalPayments: 'Включено с международными платежами',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'Действительный сценарий использования в соответствии с политикой WhatsApp для коммерции',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Номер телефона без активного WhatsApp, способный получать SMS',
        discard: 'Отклонить',
        proceed: 'Продолжить',
        whatsAppBotPrerequisites: 'Предварительные условия для WhatsApp-бота',
        letUsGetItDoneForYou: 'Давайте сделаем это за вас!',
        ifYouAreFacingSomeIssues: 'Если у вас возникли некоторые проблемы',
        ourTeamCanHelpYouDoTheSame: 'Наша команда может помочь вам сделать то же самое',
        requestACallbackNow: 'Запросите обратный звонок прямо сейчас!',
      },
      stepThree: {
        setupWhatsAppBot: 'Настройка WhatsApp-бота',
        selectingTheBestBusinessManagerForYourNeeds: 'Выбор лучшего менеджера для вашего бизнеса',
        createMetaApp: 'Как создать приложение Meta',
        createaMetaAppOn: 'Создать мета-приложение на',
        howToCreate: 'Как создать',
        addWhatsAppNumber: 'Как добавить номер WhatsApp',
        addYourWhatsAppNumberAndVerifyTheSame: 'Добавьте свой номер WhatsApp и проверьте его',
        subscribe: 'Подписаться',
        copyAndSubscribeWebhook: 'Скопировать и подписаться на Webhook',
        howToSubscribe: 'Как подписаться',
        copy: 'Копировать',
        appSecretId: 'ID секретного приложения',
        enterYourAppSecretId: 'Введите ваш ID секретного приложения',
        enterAppSecret: 'Введите секрет приложения',
        phoneNumberId: 'ID номера телефона',
        whatsAppBusinessId: 'ID бизнеса WhatsApp',
        assignSystemUser: 'Как назначить системного пользователя',
        assign: 'Назначить',
        createApp: 'Создать мета',
        registerAndVerify: 'Зарегистрироваться и проверить',
        add: 'Добавить',
        systemUserToken: 'Токен системного пользователя',
        enterYourSystemUserToken: 'Введите свой токен системного пользователя',
        getAllNecessaryDetails: 'Как получить все необходимые детали',
        subscribeWhatsAppWebhook: 'Как подписаться на вебхук WhatsApp',
        configureTheWebhookForWhatsAppAccount: 'Настроить вебхук для учетной записи WhatsApp',
        upadatePaymentMethod: 'Как обновить способ оплаты',
        assignThePaymentMethodToWhatsAppAccount: 'Назначить метод оплаты любому аккаунту WhatsApp',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Предоставить контроль над аккаунтом WhatsApp Business пользователю системы',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Ключевые детали, которые вам нужно скопировать с панели инструментов Meta',
        updatePayment: 'Обновить платеж',
        letUsGetItDoneForYou: 'Давайте сделаем это за вас!',
        ifYouAreFacingSomeIssues: 'Если у вас возникли некоторые проблемы',
        ourTeamCanHelpYouDoTheSame: 'Наша команда может помочь вам сделать то же самое',
        requestACallbackNow: 'Запросите обратный звонок прямо сейчас!',
        update: 'Обновить',
        connectNow: 'Подключиться сейчас'
      },
      stepFive: {
        selectBusinessManager: 'Выбрать менеджера по бизнесу',
        selectingTheBestBusinessManagerForYourNeeds: 'Выбрать лучшего менеджера по бизнесу для ваших потребностей'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Il tuo bot è installato e attivo',
        youhaveSuccessfullyConnected: 'Ti sei connesso con successo',
        withWhatsApp: 'con WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'Ti sono state assegnate 100 conversazioni al mese per impostazione predefinita',
        createChatFlow: 'Создать поток чата',
        upgradePlan: 'Обновить план'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Отсканируйте QR-код или отправьте сообщение для активации бота',
        scanTheQrCodeFrom: 'Отсканируйте QR-код с',
        yourPhoneWillOpen: 'Ваш телефон откроет предзаполненное сообщение для отправки на наш номер песочницы. Нажмите "Отправить" в WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Отправьте сообщение с вашего номера WhatsApp',
        sendUsAWhatsAppMessageOn: 'Отправьте нам сообщение в WhatsApp по номеру ',
        bySavingInYourContactsAndMessageShouldStartWith: ' путем сохранения их в ваших контактах, и сообщение должно начинаться с ',
        send: 'Отправить',
        or: 'ИЛИ'
      }

    }
  },
  gettingStarted: {
    hello: 'Привет,',
    upgrade: 'Обновление',
    gettingStarted: 'Начиная -',
    yourFacebookBotToken: 'Ваш токен бота в Facebook',
    hasBeenExpired: 'истек',
    isExpiringIn: 'истекает',
    days: ' дни',
    facebookAuthenticationTokenRemainsFor60Days: 'Токен аутентификации Facebook остается активным только в течение 60 дней',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Просим продлить срок действия токена вашего бота, чтобы обеспечить бесперебойный доступ к услугам чат-бота.',
    renewYourBotToken: 'Обновите свой токен бота',
    editYourChatFlow: 'Редактировать поток чата',
    buildConversationEngagingBot: 'Создайте привлекательные потоки разговоров для пользователей вашего бота.',
    takes5minutes: 'занимает 5 минут',
    previewYourLiveChatWidget: 'Предварительный просмотр своего живого виджета чата',
    discoverHowThe: 'Узнайте, как',
    liveChatFeatureWorks: ' Функция чата с использованием нашей игровой площадки в чате',
    takes2minutes: 'занимает 2 минуты',
    takes1minute: 'занимает 1 мин',
    connected: 'Подключено',
    customizeYourBotApperance: 'Настройте внешний вид и функциональность вашего бота.',
    takes3minutes: 'занимает 3 минуты',
    thirdPartyAppsIntegration: 'Сторонние приложения интеграция',
    integrateYourBot: 'Интегрируйте свой бот с другими приложениями/CRM для создания автоматизации бизнеса.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Обновите свой план по получению потенциальных клиентов по электронной почте',
    getStartedWithUs: 'Начните с нами',
    howToBuildAGoodChatFlow: 'Как построить хороший чат -поток',
    howToUseBotPenguinsInboxAndItsFeatures: 'Как использовать почтовые ящики Botpenguin и его функции',
    botPenguinsPricing: 'Цена Botpenguin: изучение планов и функций',
    openHelpDesk: 'Открыть службу поддержки',
    helpAndSupport: 'Помощь и поддержка',
    extensiveGuides: 'Обширные руководства для всех ваших запросов и рабочих процессов доступны в наших',
    resourceCentre: 'Ресурсный центр',
    checkoutOurAnswers: 'Вы можете проверить наши ответы на общие запросы ниже.',
    createANewBot: 'Создать нового бота',
    installYourBot: 'Установите свой бот',
    plansAndPricing: 'Планы и цены',
    previewYourLandingPageBot: ' Предварительный просмотр своей целевой страницы бот',
    installYourLiveChatWidget: 'Установите свой виджет чата в прямом эфире',
    installYourWebsiteBot: 'Установите бота на ваше устройство',
    integrateBotToLandingPage: 'Интегрируйте бот в свой бот целевой страницы',
    installWidgetToWebsite: 'Установите виджет на свой сайт',
    installBotToWebsite: 'Установите свой чатбот',
    whatsappAutomation: 'Автоматизация WhatsApp',
    facebookAutomation: 'Автоматизация Facebook',
    telegramBotSettings: 'Телеграмма настройки бота',
    liveChatWidgetSettings: 'Настройки виджета в чате',
    landingPageBotSettings: 'Настройки бота целевой страницы',
    websiteChatBotSettings: 'Настройки чат -бота на веб -сайте',
    instagramAutomation: 'Автоматизация Instagram',
    diveDeeperIntoBotPenguin: 'Погрузиться в ботпенгуин',
    knowWhereWeAreGoing: 'Знайте, куда мы идем, недавние обновления, и помогите нам исправить некоторые ошибки.',
    productUpdates: 'Обновления продукта',
    requestAFeature: 'Запросить функцию',
    reportABug: 'Сообщить об ошибке',
    prodcutRoadmap: 'Дорожная карта продукта',
    trainYourChatbot: 'Обучите своего чат-бота ИИ (база знаний)',
    buildEngagingConversation: 'Создавайте увлекательные диалоги для ваших пользователей-ботов.',
    previewYourAIBot: 'Предварительный просмотр вашего ИИ-бота',
    integrateAIbot: 'Интегрируйте бота в свой ИИ-бот',
    aiBotSettings: 'Настройки ИИ-бота',
    CustomizeAIBot: 'Настройте внешний вид и функциональность вашего бота.',
    gptKeyWarning: 'Добро пожаловать в наш сервис ChatGPT API! Обратите внимание, что есть ограничение в 100 сообщений для каждого бота. После достижения лимита в 100 сообщений вам потребуется использовать свой собственный ключ API ChatGPT, чтобы продолжить пользоваться нашим сервисом. Для ввода ключа API ChatGPT перейдите в конфигурацию модели ChatGPT.',
    whatsappBotSettings: 'Настройки бота WhatsApp',
    automateCustomerEngagementWithWhatsApp: 'Автоматизация взаимодействия с клиентами через WhatsApp',
    connectToYourWhatsappNumber: 'Подключиться к вашему номеру WhatsApp',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Подключите вашего бота WhatsApp к вашему номеру WhatsApp',
    facebookBotSettings: 'Настройки Facebook',
    automateCustomerEngagementWithTelegram: 'Автоматизация взаимодействия с клиентами в Telegram',
    automateCustomerEngagementWithFacebook: 'Автоматизация взаимодействия с клиентами в Facebook',
    automateCustomerEngagementWithInstagram: 'Автоматизация взаимодействия с клиентами в Instagram',
    telegramAutomation: 'Автоматизация Telegram',
  },
  install: {
    previewYourLandingPageBot: 'Предварительный просмотр своей целевой страницы бот',
    preview: 'Предварительный просмотр',
    test: 'Тест',
    squareSpace: 'Squarespace',
    installYourChatBot: 'Установите своего чатбота на веб-сайт, мобильное приложение или запустите как посадочную страницу или встроенного чатбота',
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    uniqueLinkToLandingPageBot: 'Вот уникальная ссылка на вашу целевую страницу бота.',
    shareOnSocialMedia: 'Просто поделитесь им со своими пользователями в социальных сетях, свяжитесь с страницами или визитками.',
    or: 'ИЛИ',
    copyUrl: 'Копия URL',
    selectYourWebsiteCms: 'Выберите свой сайт CMS',
    selectCms: 'Выберите CMS/Builder, который вы использовали для создания вашего сайта',
    custom: 'Обычай',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Викс',
    zoomla: 'Zoomla',
    otherCms: 'Другие CMS',
    howWouldYouLikeToInstall: 'Как бы вы хотели его установить?',
    installingWebsiteBot: 'Выберите, вы будете устанавливать бот на своем сайте',
    iWillAddMyself: 'Я добавлю себя',
    getChatBotScript: 'Получите код сценария чатбота ниже и установите все это самостоятельно',
    getWordpressPlugin: 'Получите ключ API плагина WordPress',
    getOurOfficialPlugin: 'Получите наш официальный плагин',
    fromWordpressPluginStore: ' из магазина плагинов WordPress',
    getPlugin: 'Получите плагин',
    quickGuideToInstall: 'Быстрое руководство по установке',
    placeApiKeyBelowIn: 'Вставьте ключ API ниже в',
    afterDownloadingAndActivatingPlugin: ' После загрузки и активации плагина',
    copy: 'Копия',
    enterYourWebsite: 'Введите свой сайт и давайте посмотрим, как выглядит ваши чат -боты',
    enterAValidUrl: 'Введите действительный URL',
    verifyYourInstallation: 'Убедитесь, что ваша установка успешной или нет?',
    testInstallation: 'Тестовая установка',
    getChatBotScriptCode: 'Получить код сценария чат -бот',
    copyThisCodeSnippet: 'Скопируйте этот фрагмент кода и вставьте его в HTML на каждой веб -странице, где вы хотите отобразить чат -бот или вставить в нижний колонтитул, прежде чем тег закрывается, чтобы появиться на каждой странице.',
    // copy: 'Копия',
    sendCodeToMyDeveloper: 'Отправить код моему разработчику',
    sendCodeToMyDevelopmentTeam: 'Отправить код моей команде разработчиков',
    sendEmail: 'Отправить электронное письмо',
    enterAValidEmail: 'Введите действующее электронное письмо',
    codeHasBeen: 'Код был',
    toYourDeveloper: ' вашему разработчику',
    resent: 'возмущаться',
    sent: 'отправил',
    resendEmail: 'Отправить электронное письмо',
    ask: 'Просить',
    supportTeamToInstall: ' Команда поддержки для установки',
    raiseTheInstallationRequestTo: 'Поднять запрос на установку на',
    team: ' Команда',
    helpMeInstallingChatBot: 'Помогите мне установить чат -бот',
    yourBotInstallationIsNotActive: 'Установка вашего бота не активна!',
    monthlyMessageLimitExceeded: 'Ежемесячный лимит сообщений Exeeeded',
    exceededMonthlyMessageLimitUpgradeNow: 'Вы превысили свой ежемесячный лимит сообщений, обновите сейчас.',
    upgradeNow: 'Обнови сейчас',
    botInstallationNotActivePleaseFix: 'Установка вашего бота не активна, пожалуйста, исправьте то же самое.',
    incorrectBotScript: 'Неправильный бот сценарий',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'Код установки, который вы проверяете, не соответствует тому, что на вашем сайте. Пожалуйста, убедитесь, что вы установили правильный код для этого бота.',
    unableToSee: 'Не в состоянии увидеть',
    codeOnYourWebsite: ' Код на вашем сайте',
    unableToFindCodePleaseReinstall: 'Мы не можем найти код на вашем сайте, пожалуйста, переустановите.',
    reinstallBot: 'Re-установка бот',
    yourBotIsInstalledAndActive: 'Ваш бот установлен и активен!',
    noPendingAction: 'Нет ожидающих действий, ваш бот установлен и активен',
    youAreAllSet: 'У вас все настроено!',
    showMeMyLeads: 'Покажи мне мои лидеры',
    connectingToYourWebsiteToVerifyInstallation: 'Подключение к вашему веб -сайту для проверки установки бота',
    thisMayTakeUpto30Seconds: 'Это может занять до 30 секунд',
    hereIsTheuniqueAILink: 'Вот уникальная ссылка на вашего ИИ-бота',
    installHeading: 'Установите своего чатбота',
    websiteChatbot: 'Чатбот для веб-сайта',
    aiChatBot: 'Чатбот с искусственным интеллектом',
    landingPageBot: 'Чатбот для посадочной страницы',
    mobileChatBot: 'Чатбот для мобильного приложения',
    embeddedBot: 'Встроенный чатбот',
    selectMobileDevice: 'Выберите свое мобильное устройство',
    mobileDeviceSubheading: 'Выберите язык, на котором вы создали свое мобильное приложение',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Другое',
    copyEmbeddedCodeSnippet: 'Скопируйте этот фрагмент кода и вставьте его в HTML каждой веб-страницы, где вы хотите отобразить чатбота.',
    copyMobileCodeSnippet: 'Скопируйте этот фрагмент кода в код вашего мобильного приложения, где вы хотите отображать чатбота.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'Введите электронную почту разработчика',
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Отправить инструкции своему разработчику',
      notSureHowToDoIt: 'Не уверены, как это сделать? Ваши разработчики могут сделать это в Jiff.',
      developersEmail: 'Электронная почта разработчика',
      emailIsRequired: 'Электронная почта требуется',
      subject: 'Предмет',
      message: 'Сообщение',
      send: 'Отправлять',
      placeholders: {
        enterYourWorkEmail: 'Введите свое рабочее письмо',
        createBotUsingBotPenguin: 'Взволнованный! Создать бота с помощью botpenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Вот уникальная ссылка на вашу целевую страницу бота.',
      shareWithUsersOnSocialMedia: 'Просто поделитесь им со своими пользователями в' +
        ' социальных сетях, свяжитесь с страницами или визитками.',
      previewMyLandingPageBot: 'Предварительный просмотр моей целевой страницы бот',
      or: 'ИЛИ',
      copyUrl: 'Копия URL',
      howYouLikeToAddBot: 'Выберите, как вы хотели бы добавить свой код бота на свой сайт',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Установите бот на свой пользовательский веб-сайт или WordPress, чтобы копировать уникальный код.',
      name: 'Имя',
      actions: 'Действия',
      javascript: 'JavaScript',
      install: 'Установить',
      onYourCustomCodedWebsite: ' на вашем веб -сайте, кодированном',
      copyAndPaste: 'Скопировать и вставить',
      copyThisCodeSnippet: 'Скопируйте этот фрагмент кода и вставьте его в HTML на каждой веб -странице, где вы хотите отобразить виджет. Больше подробностей',
      seeGuide: 'Смотрите гид',
      getCode: 'Получить код',
      howToInstall: 'Как установить?',
      copyCode: 'Копировать код',
      wordpress: 'WordPress',
      // install: 'Установить',
      onYourWordPressWebiste: ' на вашем сайте WordPress',
      preview: 'Предварительный просмотр',
      test: 'Тест',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Предварительный просмотр чата',
    agentWindow: 'Агент окно',
    person: 'человек',
    inOrderToTestTypeHere: 'Чтобы проверить тип здесь',
    agentWindowCannotBeCustomized: 'Примечание: окно агента не может быть настроено',
    customerWindow: 'Клиент окно',
    clickHereToChat: 'Нажмите здесь, чтобы поболтать',
    customerWillChatHere: 'Заказчик будет болтать здесь',
    chat: 'Чат',
    by: 'к',
    changeYourBotLookFromLeftPanel: 'Измените образ своего бота с левой панели',
    placeholders: {
      typeYourMessage: 'Введите свое сообщение ...',
      typeYourAnswer: 'Введите свой ответ',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Предварительный просмотр своего живого виджета чата',
    install: 'Установить',
    seeHowToUse: 'Посмотрите, как использовать',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Нажмите, чтобы установить свой бот на выбранную платформу',
    }
  },
  trainYourBot: {
    trainYourBot: 'Обучите своего чатбота',
    knowledgeBase: 'База знаний',
    faqs: 'Часто задаваемые вопросы',
    history: 'История',
    faqHeading: 'Часто задаваемые вопросы',
    faqSubHeading: 'Ответы на ваши вопросы по обучению чатбота',
    knowledgeBaseHeading: 'Обучите своего чатбота',
    knowledgeBaseSubheading: 'Обучите своего чатбота для улучшенных разговоров',
    websiteLink: 'Ссылка на веб-сайт',
    uploadFile: 'Загрузите свой файл',
    selectFile: 'Выбрать файл',
    submit: 'Отправить',
    searchHere: 'Искать здесь',
    question: 'Вопрос',
    answer: 'Ответ',
    questionPlaceholder: 'Напишите свой вопрос здесь',
    answerPlaceholder: 'Напишите свой ответ здесь',
    save: 'Сохранить',
    discard: 'Отменить',
    addFaq: 'Добавить ЧаВо',
    createdAt: 'Создано',
    updatedAt: 'Последнее обновление',
    actions: 'Действия',
    noFaqFound: 'У вас пока нет созданных ЧаВо',
    noFaqFoundMessage: 'Здесь будут отображаться все ваши ЧаВо',
    historyTitle: 'История базы знаний',
    historySubtitle: 'Ответы на ваши вопросы по обучению чатбота',
    fileName: 'Имя файла',
    status: 'Статус',
    noHistoryTitle: 'У вас пока нет созданных обученных моделей',
    noHistoryDescription: 'Здесь будут отображаться все ваши обученные модели',
    fileUpload: 'Загрузка файла',
    files: 'Файлы',
    fileSubHeading: 'Добавьте все файлы, на которых вы хотите обучить своего бота.',
    noFileFoundHeading: 'У вас пока нет созданных файлов',
    noFileFoundSubheading: 'Здесь будут отображены все ваши файлы',
    uploadYourFiles: 'Загрузите ваши файлы',
    upload: 'Загрузить',
    fileShouldBe: 'Файл должен быть в форматах PDF, DOC, DOCX, PPTX, TXT.',
    uploadStatus: 'Статус загрузки',
    uploadStatusSubHeading: 'Статус загрузки файлов для обучения бота',
    addFile: 'Добавить файл',
    otherConfiguration: 'Настройки ИИ',
    deleteFiles: 'Нажмите, чтобы удалить файлы',
    websiteURL: 'URL веб-сайтов',
    websiteUrlSubheading: 'Добавьте все URL веб-сайтов, на которых вы хотите обучить своего бота.',
    enterWebsiteUrl: 'Введите URL',
    addWebsite: 'Добавить веб-сайт',
    tableWebsiteUrl: 'URL веб-сайта',
    trainingStatus: 'Статус обучения',
    lastTrained: 'Последнее обучение',
    noWebsitesFound: 'У вас пока нет созданных веб-сайтов',
    noWebsitesFoundMessage: 'Здесь будут отображены все ваши веб-сайты',
    loadMore: 'Загрузить еще',
    botBehaviour: 'Настроить поведение бота',
    botBehaviourSubHeading: 'Настройте личность бота, удаляйте данные клиентов, управляйте потоком и моделью ChatGPT',
    botPersonality: 'Настроить личность бота',
    botPersonalitySubHeading: 'Управляйте тем, как ваш бот должен реагировать с точки зрения личности',
    selectAnswerLength: 'Выберите длину ответа',
    answerLength: 'Длина ответа',
    answerAs: 'Ответ как',
    tone: 'Тон',
    language: 'Язык',
    answerFormatting: 'Форматирование ответа',
    customInstructions: 'Пользовательский запрос',
    customerInstructionPlaceholder: 'Будьте вежливы и дружелюбны. Не используйте сленг. Не используйте эмодзи.',
    redactCustomerData: 'Собрать данные клиента',
    redactDataSubheading: 'Настройте информацию о клиенте, которую вы хотите собрать',
    collectName: 'Собрать имя',
    configureQuestion: 'Настроить вопрос',
    maxCharacterLimit: 'Максимальное ограничение символов в предоставленном имени',
    collectEmail: 'Собрать электронную почту',
    excludeEmail: 'Исключить электронные письма с доменом',
    collectPhone: 'Собрать номер телефона',
    manageUnusualMessages: 'Управлять необычными сценариями чата',
    unusualMessagesSubheading: 'Обработайте неразрешенные и другие редкие сценарии для бота',
    startConversation: 'Сообщение для начала разговора',
    configureMessage: 'Сообщение, когда бот не может ответить',
    profanityMessage: 'Сообщение для ответа на использование неприемлемого языка',
    chatGptModel: 'Настройка модели ChatGPT',
    chatGptModelSubheading: 'Управление тем, какую модель ChatGPT использовать и избегание злоупотребления',
    chatGptApi: 'Ключ API ChatGPT',
    enableDisableRedactData: 'Собрать данные клиента',
    triggerToCollectData: 'Собрать контактные данные после набора вопросов.',
    train: 'Обучить',
    website: 'сайт',
    includedSourcesForResponse: 'Включенные источники для ответа',
    visibility: 'Видимость',
    analytics: 'Аналитика',
    yourConversation: 'Ваши беседы',
    reviewAndSelectConversation: 'Просмотр и выбор бесед для экспорта для обучения чатбота',
    exportAiAnalytics: 'Экспорт аналитики',
    startDate: 'Дата начала',
    endDate: 'Дата окончания',
    userQuestion: 'Вопрос пользователя',
    botsReply: 'Ответ бота',
    dateCreated: 'Дата создания',
    noAnalyticsFound: 'У вас пока нет созданных аналитических данных',
    noAnalyticsFoundMessage: 'Все ваши аналитические сообщения появятся здесь',
    exportData: 'Экспорт данных',
    exportCompleteAnalyticsData: 'Экспорт полных данных аналитики',
    export: 'Экспорт',
    integrateWithChatGpt: 'Интегрировать с ChatGPT',
    integrated: 'Интегрирован',
    configure: 'Настроить',
    appToolTip: {
      deleteWebsite: 'Удалить веб-сайт',
      knowledgeBase: 'Обучите своего бота с помощью базы знаний',
      faqs: 'Обучите своего бота с помощью ЧаВо',
      knowledgeHistory: 'История базы знаний',
      uploadFaqs: 'Загрузить ЧаВо',
      addFaqs: 'Нажмите, чтобы добавить ЧаВо',
      deleteFaqs: 'Удалить ЧаВо',
      deleteHistory: 'Удалить историю',
      uploadFile: 'Загружаемый файл должен быть формата PDF, DOCX, DOC или CSV и иметь размер не более 1 МБ.',
      trainWebsite: 'Обучите вашего бота, используя веб-сайт.',
      fileUpload: 'Обучайте своего бота, используя файлы такие как PDF, TXT, PPTX, DOC и DOCX.',
      configureBotBehaviour: 'Настройте личность бота, управляйте потоком и моделью ChatGPT.',
      clickToAddWebsite: 'Щелкните, чтобы добавить веб-сайт',
      clickToDeleteWebsites: 'Щелкните, чтобы удалить веб-сайты',
      syncWebsite: 'Синхронизировать веб-сайт',
      copyUrl: 'Копировать URL',
      weWillBeFetchingAllTheWebpages: 'Мы будем извлекать все веб-страницы и обучать модель ChatGPT на них.'
    },
    modal: {
      deleteWebsiteUrl: 'Удалить URL веб-сайта',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Вы уверены, что хотите удалить этот URL веб-сайта?'
    }
  },
  configuration: {
    websiteChatBotSettings: 'Настройки чатбота для веб-сайта',
    preview: 'Предварительный просмотр',
    test: 'Тест',
    install: 'Установить',
    designHeader: 'Дизайн',
    generalHeader: 'Общий',
    alertsHeader: 'Оповещения',
    triggerHeader: 'Курок',
    userAccessHeader: 'Доступ к пользователю',
    chatWindowHeader: 'Чат окно',
    otherConfiguration: 'Настройки ИИ',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Нажмите, чтобы установить свой бот на выбранную платформу',
      customizeAppearanceOfBot: 'Настройте позиционирование и внешний вид вашего бота -интерфейса.',
      configureBotsSettingsWhenAgentUnavailable: 'Настройте параметры BOT, когда ваш агент недоступен.',
      configureHowBotWillAlertYou: 'Настройте, как ваш бот предупредит вас о новых потенциальных клиентах.',
      makeSureYourBotsPopUpProperlyTimed: 'Убедитесь, что всплывающие окна вашего бота идеально подходят.',
      customizeWhereYouWantYourBotToAppear: 'Настройте, где вы хотите, чтобы ваш бот появился, а где нет.',
      enableChatOptionsForVisitorsToTransferChat: 'Включите варианты чата, чтобы ваши посетители' +
        ' перевели в чаты, живой чат и обновить чат.',
      configureBotBehaviour: 'Настройте личность бота, управляйте потоком и моделью ChatGPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Настройте настройки оповещения вашего бота',
      specifyHowYourBotWillNotify: 'Укажите, как ваш бот уведомит вас о новых потенциальных клиентах.',
      subject: 'Предмет',
      emailNotification: 'Уведомление по электронной почте',
      emailAddresses: 'Адрес электронной почты',
      leadRevisitNotification: 'Ведущие уведомления о пересмотре',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'Вы успешно зарегистрировались в уведомлениях на рабочем столе',
      youHaveBlockedYourNotificationsOnBrowser: 'Вы заблокировали уведомления в настройках браузера.',
      discard: 'Отказаться',
      saveChanges: 'Сохранить изменения',
      seeHowToUse: 'Посмотрите, как использовать',
      placeholders: {
        enterSubjectForYourEmail: 'Введите тему для вашего электронного письма',
        typeYourEmailAddressAndHitEnter: 'Введите свой адрес электронной почты и нажмите Enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Настройте тему ваших уведомлений по электронной почте от бота',
        TOOLTIP_EMAIL_NOTIFICATION: 'Включите/отключите уведомление бота на своем электронном письме для новых лидов',
        TOOLTIP_EMAIL_ADDRESS: 'Отправьте адрес электронной почты учетной записи, где вы хотите получить уведомления по электронной почте. Вы можете добавить несколько адресов электронной почты, введя один и нажав Enter, чтобы добавить другой.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Включите/отключите уведомление BOT на вашем электронном письме, если пользователь, ранее захваченный в качестве свинца, снова взаимодействует с ботом.',
      }
    },
    broadcasts: {
      createBroadcastHeading: 'Создать трансляцию',
      youCanCreateMultipleBroadcasts: 'Вы можете создать несколько трансляций',
      broadcastName: 'Название вещания',
      broadcastNameIsRequired: 'Требуется имя вещания',
      continue: 'Продолжать',
      manageBroadcasts: 'Управление трансляциями',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'Вы можете создать несколько рассылок с различными шаблонами для разных групп клиентов.',
      createNewBroadcast: 'Создайте новую трансляцию',
      name: 'Имя',
      segment: 'Группа',
      template: 'Шаблон',
      contacts: 'Контакты',
      send: 'Отправлять',
      delivered: 'Доставленный',
      read: 'Читать',
      failed: 'Неуспешный',
      broadcastAt: 'Трансляция в',
      status: 'Положение дел',
      actions: 'Действия',
      nA: 'НА',
      seeHowToUse: 'Посмотрите, как использовать',
      deleteBroadcast: 'Удалить трансляцию',
      wantToDeleteThisBroadcast: 'Вы уверены, что хотите удалить эту трансляцию?',
      placeholders: {
        searchHere: 'Поищи здесь',
        enterBroadcastName: 'Введите имя вещания',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Удалить несколько трансляций',
        broadcastAnalytics: 'Вещательная аналитика',
        deleteBroadcast: 'Удалить трансляцию',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Проверка понимания',
        broadcastStatus: 'Статус трансляции',
        youCanFindDetailsOfEachContactBelow: 'Вы можете найти подробную информацию о каждом контакте ниже и экспортировать те, которые могут иметь проблемы.',
        filterApplied: 'Фильтр применяется',
        placeholders: {
          searchByPhoneNumber: 'Поиск по номеру телефона',
          searchByName: 'Поиск фильтра по имени',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Фильтруя статус на основе данных на основе данных',
          exportData: 'Экспорт данных',
        },
        modal: {
          exportData: 'Экспорт данных',
          exportCompleteBroadcastData: 'Экспорт полных данных трансляции',
          export: 'Экспорт',
        }
      },
      createBroadcast: {
        broadcastName: 'Название вещания',
        broadcastNameIsRequired: 'Требуется имя вещания',
        template: 'Шаблон',
        segments: 'Группы',
        whenToSend: 'Когда отправить',
        now: 'СЕЙЧАС',
        later: 'ПОЗЖЕ',
        selectBroadcastUsing: 'Выберите трансляцию с помощью',
        usingCsv: 'Использование CSV',
        usingSegment: 'Использование сегмента',
        allContacts: 'Все контакты',
        selectDate: 'Выберите дату',
        selectTimeSlot: 'Выберите временной интервал',
        assignVariablesToTemplates: 'Назначьте переменные шаблонам',
        addHeaderMedia: 'Добавить заголовок СМИ',
        selectFile: 'Выберите файл',
        cancel: 'Отмена',
        createBroadcast: 'Создать трансляцию',
        broadcastUsing: 'Трансляция с использованием',
        selectCSVFile: 'Выберите файл CSV',
        importContacts: 'Импортировать контакты',
        beforeYouStartUpload: 'Прежде чем начать загрузку, пожалуйста, убедитесь',
        point1: '1. Загрузите образец файла CSV.',
        point2: '2. Начните вводить ваши данные с 2 -го ряда.',
        point3: '3. В каждой строке будут данные для одного контакта',
        point4: '4. Убедитесь, что все телефонные номера уникальны.',
        point5: '5. Максимальный предел загрузки размера файла составляет 1 МБ',
        // selectFile: 'Выберите файл',
        clickHere: 'кликните сюда',
        toDownloadSampleFile: 'Чтобы загрузить пример файла',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          selectTemplate: 'Выберите шаблон',
          searchTemplateByName: 'Поиск шаблона по имени',
          selectSegments: 'Выберите Группы',
          selectTimeSlot: 'Выберите временной интервал',
          selectValueForThisVariable: 'Выберите значение для этой переменной',
          searchHeaderByVariableName: 'Поиск переменной заголовка по имени',
          variableFallbackValue: 'Переменное значение резерва',
          searchBodyVariableByName: 'Поиск переменной тела по имени',
          searchSegmentByName: 'Группа поиска по имени',
          selectBroadcastUsing: 'Выберите трансляцию с помощью',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Выберите дату начала',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Импортные контакты для трансляции WhatsApp',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'Номер WhatsApp',
        errorCode: 'Код ошибки',
        errorMessage: 'Сообщение об ошибке',
        nA: 'НА',
      }
    },
    design: {
      customizeYourLandingPage: 'Настройте целевую страницу',
      customizeYourConversationalBot: 'Настройте бота',
      customizeYourBot: 'Настройте свой бот',
      createHeadingTextAndTheme: 'Создайте заголовок текст и тему, которая отражает личность вашего бренда',
      content: 'Содержание',
      theme: 'Тема',
      layout: 'Макет',
      landingPageName: 'Название целевой страницы',
      botName: 'Название бота',
      charactersRemaining: ' персонажи остаются',
      landingPageHeader: 'Целевая страница заголовок',
      landingPageDescription: 'Целевая страница Описание',
      hideContent: 'Скрыть контент',
      profilePic: 'Картинка профиля',
      selectFile: 'Выберите файл',
      socialMediaLinks: 'Ссылки социальных сетей',
      initialMessage: 'Начальное сообщение диалогового бота',
      selectType: 'Выберите тип',
      url: 'URL',
      delete: 'Удалить',
      editLink: 'Редактировать ссылку',
      addLink: 'Добавить ссылку',
      callToAction: 'Призыв к действию',
      liveChatWelcomeMessage: 'Приветственное сообщение в чате',
      liveChatTriggerMessage: 'Сообщение за триггер живого чата',
      autoHideCallToAction: 'Автомальный призыв к действию',
      maxDelayShouldBe300Seconds: 'Максимальная задержка должна составлять 300 секунд',
      landingPageThemeColor: 'Целевой страницы Цвет темы',
      themeColor: 'Цвет темы',
      landingPageBackground: 'Целевая страница',
      chatBackgroundColor: 'Цвет фона чата',
      color: 'Цвет',
      gradient: 'Градиент',
      image: 'Изображение',
      video: 'видео',
      gradientStart: 'Градиент старт',
      gradientEnd: 'Градиент конец',
      // selectFile: 'Выберите файл',
      positionOnWeb: 'Позиция в Интернете',
      positionOnMobile: 'Позиция на мобильном телефоне',
      windowSize: 'Размер окна',
      s: 'С',
      m: 'М',
      l: 'Л',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'Обычай',
      height: 'Высота',
      minimum300HeightIsRequired: 'Требуется минимальная высота 300 пиксеров',
      heightCannotExceed720: 'Высота не может превышать 720px',
      width: 'Ширина',
      px: 'пк',
      minimum200WidthIsRequired: 'Требуется минимальная ширина 200 пиксеров',
      widthCannotExceed1050: 'Ширина не может превышать 1050px',
      discard: 'Отказаться',
      saveChanges: 'Сохранить изменения',
      landingPageBot: 'Целевая страница бот',
      chatBot: 'Чатбот',
      preview: ' Предварительный просмотр',
      botIcon: 'Иконка бота',
      websiteBot: 'Чатбот для веб-сайта',
      selectFont: 'Выбрать шрифт',
      fontLibrary: 'Библиотека шрифтов',
      uploadFont: 'Загрузить шрифт',
      selectFontForBot: 'Выбрать шрифт для бота',
      uploadedFont: 'Загруженный шрифт',
      flowTriggerDetail: 'Триггер для сбора контактных данных',
      placeholders: {
        enterName: 'Введите имя',
        enterHeaderText: 'Введите текст заголовка',
        enterDescription: 'Введите описание',
        enterUrl: 'Введите URL',
        howCanIHelpYou: 'Могу я чем-нибудь помочь?',
        enterYourWelcomeMessageHere: 'Введите свое приветственное сообщение здесь',
        enterYourLiveChatMessageHere: 'Введите здесь свое живое сообщение в чате',
        enterTimeInSeconds: 'Введите время в считанные секунды',
        enterHeight: 'Введите высоту',
        enterWidth: 'Введите ширину',
        flowInitialMessage: 'Введите начальный вопрос',
      },
      appTooltip: {
        standardLayout: 'Стандартный макет',
        rightLayout: 'Правильный макет',
        mergedLayout: 'Объединенный макет',
        enterNameOfYourBot: 'Введите имя своего бота',
        enterNameOfYourLandingPageBot: 'Введите имя своей целевой страницы бот',
        headingTextThatAppearOnLandingPageBot: 'Введите текст заголовка, который вы хотите отобразить на своей целевой странице бот',
        descriptionTextThatAppearOnLandingPageBot: 'Введите текст описания, который вы хотите отобразить на своей целевой странице.',
        addLinksAndSocialMediaIcons: 'Добавить ссылки и значки в социальных сетях',
        initialFlowMessage: 'Введите первое сообщение, которое бот отправит, чтобы начать разговор с клиентом.',
        anInvitingCatchPhrase: 'Приглашенная фраза, которая заставит пользователя поговорить с ботом',
        setAWelcomeMessageForYourVisitors: 'Установите приветственное сообщение для ваших посетителей. Это будет первое сообщение в вашем разговоре.',
        setAMessageToInitiateLiveChat: 'Установите сообщение, чтобы инициировать живой чат с конца посетителя. Вы получите уведомление каждый раз, когда это сообщение запускается в любом из ваших разговоров',
        autoHideCallToAction: 'Авто скрыть текст «Призыв к действию» после указанного времени',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Вы можете скрыть логотип и тексты, чтобы показать только окно чата на экране',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Загрузите логотип для страницы Chatbot.',
        TOOLTIP_CHAT_THEME_COLOR: 'Выберите цвет своего окна чата ботов',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Выберите цвет фона ботов окно чата',
        TOOLTIP_WEB_1ST_POSITION: 'Поместите свой бот на левом лево настольного рабочего стола для бота',
        TOOLTIP_WEB_2ND_POSITION: 'Поместите свой бот в среднем правом углу рабочего стола пользователей бота',
        TOOLTIP_WEB_3RD_POSITION: 'Поместите свой бот в правом нижнем углу рабочего стола пользователей бота',
        TOOLTIP_MOBILE_1ST_POSITION: 'Поместите свой бот в левом нижнем углу мобильного пользователя бота',
        TOOLTIP_MOBILE_2ND_POSITION: 'Поместите свой бот в среднем правом углу мобильного пользователя бота',
        TOOLTIP_MOBILE_3RD_POSITION: 'Поместите свой бот в правом нижнем углу мобильного пользователя бота',
        TOOLTIP_WINDOW_HEIGHT: 'Изменить размер окна чата ботов',
        TOOLTIP_BOT_ICON: 'Выберите значок или загрузите логотип/изображение для вашего бота',
        flowTriggerMessage: 'Введите количество сообщений, которые будут запрошены перед сбором контактных данных, таких как имя, электронная почта и телефон',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Создать капельную кампанию',
      youCanCreateMultipleCampaigns: 'Вы можете создать несколько кампаний',
      dripCampaignName: 'Капельное название кампании',
      dripCampaignNameIsReuired: 'Требуется название капельной кампании',
      continue: 'Продолжать',
      manageDripCampaign: 'Управление капельной кампанией',
      youCanCreateMultipleCampaignsWith: 'Вы можете создать несколько капельных кампаний с запланированными автоматизированными сообщениями для запуска ответов из конкретных сегментов пользователей.',
      createNewDripCampaign: 'Создать новую капельную кампанию',
      campaignName: 'Название кампании',
      appliedOn: 'Применяется на',
      segment: 'Группа',
      template: 'Шаблон',
      startDate: 'Дата начала',
      action: 'Действие',
      nA: 'НА',
      seeHowToUse: 'Посмотрите, как использовать',
      deleteDripCampaign: 'Удалить капельные кампании',
      wantToDeleteThisDripCampaign: 'Вы уверены, что хотите удалить эти капельные кампании?',
      placeholders: {
        enterDripCampaignName: 'Введите название капельной кампании',
        searchByName: 'Поиск по имени',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Удалить несколько капельных кампаний',
        deleteDripCampaign: 'Удалить капельную кампанию',
      },
      createDripCampaign: {
        dripCampaignName: 'Капельное название кампании',
        appliedOn: 'Применяется на',
        segments: 'Группы',
        selectDate: 'Выберите дату',
        scheduleYourTemplate: 'Запланируйте свои шаблоны',
        schedule: 'Расписание',
        template: 'Шаблон',
        sendAfter: 'Отправить после',
        assignVariablesToTemplate: 'Назначьте переменные шаблонам',
        addHeaderMedia: 'Добавить заголовок СМИ',
        selectFile: 'Выберите файл',
        createDripCampaign: 'Создать капельную кампанию',
        newSubscriber: 'Новый подписчик',
        minutes: 'Минуты',
        hours: 'Часы',
        days: 'Дни',
        placeholders: {
          enterDripName: 'Введите имя капельницы',
          selectAppliedOn: 'Выберите «Применить»',
          selectSegments: 'Выберите Группы',
          searchByName: 'Поиск по имени',
          selectTemplate: 'Выберите шаблон',
          searchTemplateByName: 'Поиск шаблона по имени',
          enterSendAfter: 'Введите отправить после',
          selectValueForThisVariable: 'Выберите значение для этой переменной',
          searchHeaderVariableByName: 'Поиск переменной заголовка по имени',
          searchBodyVariableByName: 'Поиск переменной тела по имени',
        },
        dateTimePicker: {
          selectStartDate: 'Выберите дату начала',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Настройте общие настройки целевой страницы',
      configureBotsGeneralSettings: 'Настройте общие настройки вашего бота',
      selectTheTimeZone: 'Выберите зону времени, язык и небольшую заметку для ваших клиентов, когда',
      isNotAvailable: ' не доступен',
      landingPagesBot: 'целевые страницы бот',
      bot: 'бот',
      waitingMessage: 'Ожидающее сообщение',
      landingPageBotStatus: 'Статус бота целевой страницы',
      chatBotStatus: 'Статус чат -бот',
      unavailabilityMessage: 'Сообщение о недоступности',
      sendIncompleteResponses: 'Отправить неполные ответы',
      language: 'Язык',
      invalidateCache: 'Недействительность кеша',
      timezone: 'Часовой пояс',
      chatWindowMessageHistory: 'История сообщений в чате',
      session: 'сессия',
      forever: 'навсегда',
      english: 'Английский',
      spanish: 'испанский',
      french: 'Французский',
      portuguese: 'португальский',
      german: 'Немецкий',
      italian: 'Итальянский',
      swedish: 'Шведский',
      arabic: 'арабский',
      malay: 'малайский',
      russian: 'Русский',
      discard: 'Отказаться',
      saveChanges: 'Сохранить изменения',
      seeHowToUse: 'Посмотрите, как использовать',
      placeholders: {
        enterYourWaitingMessageHere: 'Введите здесь свое ожидающее сообщение',
        // selectLanguage: 'В',
        searchTimeZone: 'Поиск часового пояса',
        // selectChatRetentionPolicy: 'В',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Настройте сообщение, как вы хотите, чтобы оно появилось в окне чата сразу после того, как пользователь бота отправляет запрос на живой чат',
        TOOLTIP_CHATBOT_STATUS: 'Активировать /деактивировать бота',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Настройте сообщение, как вы хотите, чтобы оно появилось в окне чата, когда ни один из ваших агентов не доступен для живого чата с пользователем бота',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Отправьте вам уведомления по электронной почте, даже если пользователь бота не закончил разговор.',
        TOOLTIP_LANGUAGE: 'Выберите язык бота',
        TOOLTIP_INVALIDATE_CACHE: 'Нажмите здесь, чтобы очистить кэш, как и когда вы редактируете поток или настройки чата бота, так как изменения отражаются только через 15 минут',
        TOOLTIP_TIME_ZONE: 'Выберите зону времени для вашего бота',
        TOOLTIP_CHAT_RETENTION: 'Выберите, если вы хотите сохранить чаты пользователя бота навсегда в окне чата или только для сеанса браузера.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Настройте окно чата',
      controlAndCustomizeTheChatWindow: 'Управляйте и настраивайте окно чата, с которыми будут взаимодействовать ваши клиенты.',
      allowVisitorsToTransferChatToWhatsapp: 'Позвольте посетителям перенести чат в WhatsApp',
      allowVisitorsToRefreshChat: 'Позвольте посетителям обновлять чат',
      displayNameForTransferringChatToWhatsapp: 'Отображать имя для передачи чата в WhatsApp',
      displayNameForTransferringToLiveChat: 'Отображение имени для передачи в чат в прямом эфире/агенте',
      displayNameForTransferringAChatToBot: 'Отображать имя для передачи чата на бот',
      discard: 'Отказаться',
      saveChanges: 'Сохранить изменения',
      allowVisitorsToTransferChatToBot: 'Позвольте посетителям перенести чат в бот.',
      allowVisitorsToRequestForLiveChat: 'Позвольте посетителям запросить живой чат.',
      indicativeTextForCustomersReply: 'Индикативный текст для ответа клиента',
      displayNameForRestartingChatBot: 'Отображать имя для перезапуска беседы чат -бота',
      seeHowToUse: 'Посмотрите, как использовать',
      placeholders: {
        enterButtonNameForWhatsapp: 'Введите имя кнопки для передачи в WhatsApp',
        enterButtonNameForLiveChat: 'Введите имя кнопки для передачи в чат или агент',
        enterButtonNameForBot: 'Введите имя кнопки для передачи в бот',
        enterTextDisplayInEmptyReplyArea: 'Введите текст, отображаемый в пустой зоне ответа',
        enterButtonForRestartingConversation: 'Введите имя кнопки для перезапуска разговора',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Позвольте посетителям веб -сайта перевести чат в WhatsApp no. соответствующего агента, к которому они связаны. Функция живого чата должна быть включена для работы этой функции.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Позвольте пользователям бота обновлять чат, чтобы они могли начать разговор заново.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Позвольте пользователям бота перенести чат в бот в любое время между разговором с агентом в чате.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Позвольте пользователям бота запросить живой чат с агентом, когда они захотят.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Если вы позволили пользователю перенести в WhatsApp, заполните, как вы хотели бы отобразить эту опцию',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Если вы позволили пользователю перенести в живой чат, заполните, как вы хотели бы отобразить эту опцию',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Если вы позволили пользователю перенести в WhatsApp, заполните, как вы хотели бы перенести обратно в бот',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Заполните, как вы хотели бы отобразить ответное пространство для клиентов',
        ifYouHaveEnabledUserToRestartChatBot: 'Если вы позволили пользователю перезагрузить разговор чат -бот, заполните, как вы хотели бы отобразить эту опцию',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Чат потоки',
      dripCampaignHeader: 'Капельная кампания',
      widgetsHeader: 'Виджеты',
      broadcastHeader: 'Транслировать',
      ordersHeader: 'Приказ',
      manageHeader: 'Управлять',
      inputHeader: 'Вход',
      apiKeyHeader: 'API -ключ',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Создайте путешествие по борьбе с клиентом, используя' +
          ' капельную кампанию запланированных потоков чата.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Настройте шаблоны потока чата и создайте несколько потоков чата для разных целевых аудиторий.',
        TOOLTIP_WIDGET_TITLE: 'Создать виджеты (интерфейсы чата) и встроить их в рекламные веб -страницы, чтобы направить посетителей бота',
        TOOLTIP_BROADCAST_TITLE: 'Взрывные сообщения или потоки в ваши контакты за один щелчок в предварительно запланированное время',
        TOOLTIP_ORDERS_TITLE: 'Список заказов, размещенных клиентом',
        TOOLTIP_MANAGE_TITLE: 'Управление функциональностью бота для контактов/потенциальных клиентов и ключевых слов',
        TOOLTIP_INPUT_TITLE: 'Создать шаблоны WhatsApp, изображения и текст в качестве ответного материала для контактов/свинц',
      },
      apiWrapper: {
        whatsappApiCredentials: 'Условия WhatsApp API',
        generateYourWhatsappApiKey: 'Создайте свой ключ WhatsApp API для интеграции с вашими пользовательскими приложениями.',
        generateApiKey: 'Генерировать ключ API',
        webhookUrl: 'Webhook URL',
        addHeader: 'Добавить заголовок',
        test: 'Тест',
        webhookNotSet: 'Webhook не установлен',
        webhookHeaders: 'Заголовки Webhook',
        setWebhookUrl: 'Установите URL Webhook',
        placeholders: {
          yourApiKey: 'Ваш ключ API',
          enterWebhookUrl: 'Введите URL',
          enterHeaderKey: 'Введите ключ заголовка',
          enterValue: 'Введите значение',
        }
      },
      broadcast: {
        createBroadcast: 'Создать трансляцию',
        createMultipleBroadcasts: 'Вы можете создать несколько трансляций с несколькими запланированными потоками/шаблонами для разных сегментов клиентов.',
        broadcastName: 'Название вещания',
        nameIsRequired: 'Имя требуется',
        selectType: 'Выберите тип',
        selectYourFlowType: 'Выберите свой тип потока',
        segment: 'Группа',
        selectYourSegmentType: 'Выберите тип вашей группы',
        whenToSend: 'Когда отправить',
        selectDate: 'Выберите дату',
        messageTag: 'Тег сообщения',
        selectMessageTag: 'Выберите тег сообщения',
        contentType: 'Тип содержимого',
        nonPromotional: 'Не предназначенные (применяются теги сообщения)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Отметьте каждое сообщение, которое идет до любого взаимодействия или после шагов задержки, превышающих 24 часа с соответствующим тегом. Незагнутое сообщение не достигнет подписчика, который взаимодействовал с ботом более 24 часов назад',
        promotional: 'Промосовые (теги сообщения игнорируются)',
        theseBroadcasts: 'Эти трансляции могут содержать рекламные акции, но целевая аудитория ограничена подписчиками, которые взаимодействовали с вашим ботом за последние 24 часа.',
        createNewBroadcast: 'Создайте новую трансляцию',
        sentMessages: 'Отправленные сообщения',
        deliveredMessages: 'Доставленные сообщения',
        readMessages: 'Читать сообщения',
        failedMessages: 'Неудачные сообщения',
        // createNewBroadcast: 'Создайте новую трансляцию',
        type: 'Тип',
        template: 'Шаблон',
        sent: 'Отправил',
        delivered: 'Доставленный',
        read: 'Читать',
        failed: 'Неуспешный',
        broadcastAt: 'Трансляция в',
        status: 'Положение дел',
        shipmentUpdate: 'Обновление отгрузки',
        // template: 'Шаблон',
        flow: 'Поток',
        resendFailedMessages: 'Отправить неудачные сообщения',
        // sentMessages: 'Отправленные сообщения',
        // deliveredMessages: 'Доставленные сообщения',
        goBack: 'Возвращаться',
        number: 'Число',
        errorCode: 'Код ошибки',
        failedAt: 'Не удалось в',
        badGateway: '502 Неверный шлюз',
        loadMore: 'Загрузи больше',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          searchBroadcastByName: 'Поиск трансляции по имени',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Полное вещательное сообщение отправлено',
          totalBroadcastMessagesDelivered: 'Общая передача передачи вещательных сообщений',
          totalBroadcastMessagesReadByContacts: 'Общие передачи сообщений прочитаны контактами',
          totalFailedBroadcastMessages: 'Общая не удаленная передача сообщений',
          clickToViewDetailedInformationOfFailedMessages: 'Нажмите, чтобы просмотреть информацию об ухудшении неудачных сообщений',
          clickToCreateNewBroadcast: 'Нажмите, чтобы создать новую трансляцию',
          templateUsedInBroadcast: 'Шаблон, используемый в трансляции',
          numberOfBroadcastMessagesSent: 'Количество вещательных сообщений, отправленных',
          numberOfBroadcastMessagesDelivered: 'Количество вещательных сообщений, доставленных',
          numberOfBroadcastMessagesReadByContacts: 'Количество вещательных сообщений, прочитанных контактами',
          numberOfTimesBroadcastMessagesHasFailed: 'Количество раздача сообщений провалилось',
          contactNumberOnWhichMessageDeliveryFailed: 'Контактный номер, по которому не удалась доставка сообщения',
          unDeliveredTemplate: 'Недоставленный шаблон',
          errorReceivedForFailedMessage: 'Ошибка получена для неудачного сообщения',
          timeWhenMessageDeliveryFailed: 'Время, когда доставка сообщений не удалась',
          TOOLTIP_ADD_BROADCAST_NAME: 'Введите новое имя трансляции',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Выберите, если вы транслируете поток или сообщение.',
          TOOLTIP_BROADCAST_FLOW: 'Выберите поток чата, который вы хотите транслировать,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Выберите группу контактов, для которой вы хотите провести рассылку',
          TOOLTIP_BROADCAST_SEGMENT: 'Группа, для которой создана рассылка',
          TOOLTIP_BROADCAST_NAME: 'Название трансляции',
          TOOLTIP_BROADCAST_TYPE: 'Поток или сообщение, выбранное для трансляции',
          TOOLTIP_BROADCAST_AT: 'Дата и время запланированы на трансляцию',
          TOOLTIP_BROADCAST_STATUS: 'Статус трансляции в соответствии с запланированием, в настоящее время работает или завершена',
        }
      },
      drip: {
        createDripCampaign: 'Создать капельную кампанию',
        youCanCreateMultipleCampaigns: 'Вы можете создать несколько кампаний',
        dripCampaignName: 'Капельное название кампании',
        dripCampaignNameIsRequired: 'Требуется название капельной кампании',
        // createDripCampaign: 'Создать капельную кампанию',
        youCanCreateMultipleDripCampaigns: 'Вы можете создать несколько капельных кампаний с запланированными автоматизированными сообщениями для запуска ответов из конкретных сегментов пользователей.',
        campaignName: 'Название кампании',
        campaignNameIsRequired: 'Название кампании требуется',
        after: 'После',
        thisMessageWillBeSendAfter: 'Это сообщение будет отправлено после',
        saveAndUpdate: 'Сохранить и обновить',
        schedule: ' Расписание',
        select: 'Выбирать',
        messages: 'Сообщения',
        cancel: 'Отмена',
        createCampaign: 'Создать кампанию',
        updateCampaign: 'Обновить кампанию',
        createNewCampaign: 'Создать новую кампанию',
        // campaignName: 'Название кампании',
        messagesSent: 'Сообщения отправлены',
        createdAt: 'Создан в',
        updatedAt: 'Обновлено в',
        action: 'Действие',
        placeholders: {
          nameYourDripCampaign: 'Назови свою капельную кампанию',
          enterCampaignName: 'Введите название кампании',
          searchCampaignByName: 'Поисковая кампания по имени',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Нажмите, чтобы добавить новую кампанию',
          TOOLTIP_SCHEDULE: 'Планируйте, когда и какой поток чата отправит первым в этой кампании.',
          TOOLTIP_AFTER_DAYS: 'Запланируйте, когда отправить первый поток кампании в минуты, часы или дни.',
          TOOLTIP_ADD_MESSAGES: 'Нажмите, чтобы добавить новый поток в кампанию',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Введите новое название кампании',
          TOOLTIP_CAMPAIGN_NAME: 'Название кампании',
          TOOLTIP_NO_MESSAGES_SENT: 'Количество сообщений, отправленных кампанией',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Дата и время создания кампании',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Дата и время, когда кампания была последней изменением',
          TOOLTIP_CAMPAIGN_ACTION: 'Изменить или удалить кампанию',
          TOOLTIP_UPDATE_CAMPAIGN: 'Нажмите, чтобы сохранить модификации, сделанные в настройке кампании',
        }
      },
      flows: {
        createChatFlow: 'Создайте поток чата',
        youCanCreateMultipleConversationsFlow: 'Вы можете создать несколько потоков разговоров по нескольким каналам для A/B -тестирования и трансляций.',
        flowName: 'Имя потока',
        nameIsRequired: 'Имя требуется',
        cancel: 'Отмена',
        createFlow: 'Создать поток',
        createNewFlow: 'Создайте новый поток',
        // flowName: 'Имя потока',
        ofMessages: 'сообщений',
        triggered: 'Запускается',
        stepsFinsished: 'Шаги завершены',
        finished: 'Законченный',
        createdOn: 'Создано на',
        lastModified: 'Последнее изменение',
        defaultFlow: 'Поток по умолчанию',
        action: 'Действие',
        flowAnalytics: 'Аналитика потока',
        placeholders: {
          enterFlowName: 'Введите имя потока',
          searchFlowByName: 'Поток поиска по имени',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'Количество раз поток чата запускается',
          numberOfCompletedChatFlowStepsWhenTriggered: 'Количество завершенных шагов поток чата, когда он запускается',
          numberOfTimesUsersCompletedChatFlowSteps: 'Количество пользователей выполняло все шаги по потоку чата',
          dateAndTimeOfCreationOfChatFlow: 'Дата и время создания потока чата',
          dateAndTimeWhenChatFlowLastModified: 'Дата и время, когда поток чата был в последний раз изменен',
          TOOLTIP_CREATE_FLOW: 'Нажмите, чтобы создать новый поток чата',
          TOOLTIP_FLOW_NAME: 'Введите имя потока чата',
          TOOLTIP_FLOW_TABLE_NAME: 'Имя потока чата',
          TOOLTIP_NO_OF_MESSAGES: 'Количество компонентов сообщения в потоке чата',
          TOOLTIP_DEFAULT_FLOW: 'Используйте этот переключатель, чтобы установить поток чата по умолчанию, который будет обслуживаться всем новым пользователям.',
          TOOLTIP_ACTION: 'Изменить или удалить поток чата',
        }
      },
      manage: {
        leads: 'Лидеры',
        manageBotsFunctionalityForContacts: 'Управление функциональностью бота для контактов/потенциальных клиентов',
        whenToSendLeadInfo: 'Когда отправлять информацию о свинце',
        smartFlow: 'Умный поток',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Приветственное сообщение, когда поиск по ключевым словам не выполняется',
        selectTemplates: 'Выберите шаблоны',
        selectFlows: 'Выберите потоки',
        selectText: 'Выберите текст',
        selectImages: 'Выберите изображения',
        whenItAlmostReaches24HoursSinceMessage: 'Когда он почти достигает 24 часа с момента последнего сообщения',
        // selectTemplates: 'Выберите шаблоны',
        updateChanges: 'Обновление изменений',
        manageBotsFunctionalityAccordingToKeywords: 'Управление функциональностью BOT в соответствии с вводом ключевых слов пользователем BOT, в случае Smart Flow',
        addKeywordAction: 'Добавить действие ключевого слова',
        keywords: 'Ключевые слова',
        matchingMethod: 'Соответствие метода',
        replyMaterial: 'Ответить материал',
        action: 'Действие',
        select: 'Выбирать',
        placeholders: {
          keyword: 'ключевое слово',
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Предложить, если бот отправить информацию о контакте/лидере в интеграцию CRM немедленно, или через 5 минут, или в конце потока',
          TOOLTIP_LEAD_SMART_FLOW: 'Выберите любой поток по умолчанию, чтобы отправить его всем пользователям бота, или Smart Flow (определяется вводом ключевых слов пользователем BOT).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Выберите «Ответить материал» (поток, шаблон, текст, изображение), чтобы отправить, если бот не получает ключевого слова, от ответа пользователя бота',
          TOOLTIP_IT_ALMOST_REACHED: 'Выберите ответный материал, чтобы отправить на контакты/лиды незадолго до 24 часов последнего обмена сообщениями',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Управление функциональностью BOT в соответствии с вводом ключевых слов пользователем BOT, в случае Smart Flow',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Выберите «Ответ бота» в качестве шаблона, потока, текста или изображения против конкретных ключевых слов ввода',
          TOOLTIP_ADD_KEYWORD: 'Добавить ключевые слова',
          TOOLTIP_MATCHING_METHOD: 'Добавить ключевое слово соответствует точному или содержит',
          TOOLTIP_REPLY_MATERIAL: 'Выберите шаблон, поток, текст или изображение, чтобы ответить на предложенное ключевое слово',
          TOOLTIP_KEYWORD_ACTIONS: 'Удалить действие ключевого слова',
        },
        socialAlerts: {
          alerts: 'Оповещения',
          specifyHowYourBotWillNotify: 'Укажите, как ваш бот уведомит вас о новых потенциальных клиентах.',
        }
      },
      orders: {
        manageCustomersOrder: 'Управлять заказами клиентов',
        checkAndManageAllOfYourCustomerOrders: 'Проверьте и управляйте всеми вашими заказом и обновлениями клиентов.',
        selectShipmentTemplate: 'Выберите шаблон отгрузки',
        send: 'Отправлять',
        export: 'Экспорт',
        phoneNumber: 'Номер телефона',
        numberOfItems: 'Количество предметов',
        orderValue: 'Ценность заказа',
        orderDate: 'Дата заказа',
        status: 'Положение дел',
        chat: 'Чат',
        item: 'Элемент',
        quantity: 'Количество',
        amount: 'Количество',
        unitPrice: 'Цена за единицу товара',
        placeholders: {
          searchOrdersByPhoneNumber: 'Заказы на поиск по номерам телефонов',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Экспортные заказы данных в файле CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'Номер телефона клиента, который разместил заказ',
          TOOLTIP_NO_OF_ITEMS: 'Количество заказанных предметов',
          TOOLTIP_ORDER_VALUE: 'Ценность заказа',
          TOOLTIP_ORDER_DATE: 'Заказ был размещен в эту дату.',
          TOOLTIP_ORDER_STATUS: 'Статус порядка, как подтверждено, в ожидании, отвергнуто. или отправлено',
          TOOLTIP_ORDER_CHAT: 'Все предыдущие разговоры в чате относительно этого порядка',
        }
      },
      widgets: {
        installWidgetHeader: 'Установить виджет',
        createWidgetHeader: 'Создать виджет',
        copyTheCodeAndPasteItWhereYouWant: 'Скопируйте код и вставьте его везде, где вам нужно в HTML, веб -сайт и т. Д.',
        youCanCreateMultipleWidgets: 'Вы можете создать несколько виджетов, чтобы придать вашему боту другой внешний вид на разных веб -страницах.',
        installWidget: {
          javascript: 'JavaScript',
          install: 'Установить',
          onYourWebsite: ' на вашем сайте',
          copyCode: 'Копировать код',
          copyAndPaste: 'Скопировать и вставить',
          copyThisCodeSnippet: 'Скопируйте этот фрагмент кода и вставьте его в HTML на каждой веб -странице, где вы хотите',
          seeGuide: 'Смотрите гид',
        },
        widgetList: {
          widgetName: 'Название виджета',
          nameIsRequired: 'Имя требуется',
          cancel: 'Отмена',
          createWidget: 'Создать виджет',
          icon: 'Икона',
          name: 'Имя',
          createdAt: 'Создан в',
          action: 'Действие',
          placeholders: {
            enterWidgetName: 'Введите имя виджета',
            searchWidgetByName: 'Поиск виджета по имени',
          },
          appTooltip: {
            widgetIcon: 'Значок виджета',
            nameOfWidget: 'Название виджета',
            dateAndTimeOfCreationOfWidget: 'Дата и время создания виджет',
            editOrDeleteWidget: 'Изменить или удалить виджет',
          }
        },
        widgetTemplates: {
          widgetName: 'Название виджета',
          nameIsRequired: 'Имя требуется',
          chooseTypeOfWidget: 'Выберите тип виджета',
          bar: 'Бар',
          slideIn: 'Скользить в',
          pageTakeOver: 'Поглощение страницы',
          button: 'Кнопка',
          facebookModal: 'Facebook Modal',
          comment: 'Комментарий',
          direct: 'Прямой',
          whatsappIcon: 'Значок WhatsApp',
          refUrl: 'Ref url',
          qrCode: 'QR код',
          // refUrl: 'Ref url',
          backgroundColor: 'Фоновый цвет',
          descriptionColor: 'Описание Цвет',
          headlineColor: 'Цвет заголовка',
          buttonBackgroundColor: 'Кнопкий цвет фона',
          contactNumber: 'Контактный номер',
          entryMessage: 'Входное сообщение',
          buttonText: 'Кнопка текст',
          selectButtonText: 'Выберите текст кнопки',
          buttonSize: 'Размер кнопки',
          selectOne: 'Выбери один',
          placement: 'Размещение',
          rightBottom: 'Правый',
          optInFlow: 'Поток',
          selectFlow: 'Выберите поток',
          addSubscriberToSequence: 'Добавить подписчика к последовательности',
          selectDrip: 'Выберите Drip',
          whenDoesItDisplay: 'Когда он отображается (секунды)',
          showWidgetToSameUserAgainAfter: 'Покажите виджет снова к тому же пользователю после',
          ifManuallyClosedByUser: 'Если пользователь вручную закрыт, покажите после',
          // comment: 'Комментарий',
          specificPosts: 'Конкретные сообщения',
          allPosts: 'Все посты',
          // entryMessage: 'Входное сообщение',
          postMessage: 'Опубликовать сообщение',
          postDate: 'Дата публикации',
          status: 'Положение дел',
          action: 'Действие',
          discard: 'Отказаться',
          saveChanges: 'Сохранить изменения',
          nextStep: 'Следующий шаг',
          widgetDescription: 'Описание виджета',
          integrateTheBarWidget: 'Интегрируйте виджет стержня в тело вашего сайта и увеличьте подписчика вашего страницы вместе с отправкой, затем пользовательскими сообщениями бота.',
          hereIsYourWidgetHeadline: 'Вот ваш заголовок виджета, нажмите здесь, чтобы изменить его.',
          sendUsMessage: 'Отправьте нам сообщение',
          weAlsoPutDefaultTextHere: 'Мы также размещаем здесь текст по умолчанию. Обязательно превратите его в ценное сообщение',
          selectYourPagePost: 'Выберите свою страницу Post',
          nA: 'НА',
          // entryMessage: 'Входное сообщение',
          saveRule: 'Сохранить правило',
          placeholders: {
            enterWidgetName: 'Введите имя виджета',
            enterNumber: 'Введите номер',
            enterMessageHere: 'Введите сообщение здесь',
            enterTimeInSeconds: 'Введите время в считанные секунды',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'Настройте настройки триггера вашего бота',
      makeYourBotsTimingPerfect: 'Сделайте время вашего бота просто идеальным.',
      onDesktopTriggerTime: 'Время триггера на Desktop (секунды)',
      onMobileTriggerTime: 'Время триггера на мобиле (секунды)',
      chatBubble: 'Автоматическое отверстие пузырька чата',
      seconds: 'Секунды',
      discard: 'Отказаться',
      saveChanges: 'Сохранить изменения',
      autoTriggerOnOrOff: 'Авто триггер: включен/выключен',
      seeHowToUse: 'Посмотрите, как использовать',
      alwaysOpenAfter: 'Всегда открывается после указанных указанных триггеров',
      automaticallyAfterSelectedNumberOfTime: 'Автоматически после выбранного количества времени',
      neverAutomaticallyOpenBot: 'Никогда не открывайте бот',
      placeholders: {
        enterTimeInSeconds: 'Введите время в считанные секунды',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Время ожидания за считанные секунды, на настольных устройствах для посетителей, прежде чем ваш бот автоматически появится',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Время ожидания за считанные секунды, на мобильных устройствах для посетителей, прежде чем ваш бот автоматически появится',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Когда посетитель закрывает окно чата, выберите, как будет всплывающее поведение BOT Auto',
        customerWaitingTimeToOpenBubble: 'Время ожидания клиента, чтобы открыть пузырь чата',
        wantToDisableAutoTriggering: 'Нажмите, если вы хотите отключить автоматическую запуска бота на веб -страницах',
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'Настройте настройки пользователя вашего бота',
      decideWhoCanAccessYourBot: 'Решите, кто может получить доступ к вашему боту',
      deviceSupport: 'Поддержка устройства',
      blockIpAddresses: 'Блок IP -адреса',
      hideChatBotOnSpecificPages: 'Скрыть чат -бот на определенных страницах',
      selectCountriesToEnableBot: 'Выберите страны, чтобы включить бот',
      discard: 'Отказаться',
      saveChanges: 'Сохранить изменения',
      seeHowToUse: 'Посмотрите, как использовать',
      allDevices: 'Все устройства',
      mobileOnly: 'Только мобильный',
      desktopOnly: 'Только для рабочего стола',
      placeholders: {
        enterIpAddress: 'Введите IP -адрес (IPv4 или IPv6) и нажмите «Введите»',
        enterUrl: 'Введите URL -адрес страниц и нажмите «Введите»',
        searchCountry: 'Страна поиска',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Выберите, на каких устройствах должен появиться ваш бот',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Введите IP -адреса, где вы не хотите, чтобы ваш бот появился',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Введите URL своих веб -страниц, где вы не хотите, чтобы ваш бот появился',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Выберите местоположения, где вы хотите получить доступ к вашему боту',
      }
    },
    facebook: {
      facebookBotSettings: 'Настройки бота в Facebook',
      chatFlowsHeader: 'Чат потоки',
      dripCampaignHeader: 'Капельная кампания',
      broadcastHeader: 'Транслировать',
      widgetsHeader: 'Виджеты',
      autoReplyHeader: 'Авто-ответ',
      generalHeader: 'Общий',
      facebookAutomation: 'Автоматизация Facebook',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Настройте шаблоны потока чата и создайте несколько потоков чата для разных целевых аудиторий.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Создайте путешествие по борьбе с клиентом, используя капельную' +
          ' кампанию запланированных потоков чата.',
      },
      broadcasts: {
        createBroadcast: 'Создать трансляцию',
        youCanCreateMultipleBroadcasts: 'Вы можете создать несколько трансляций',
        broadcastName: 'Название вещания',
        broadcastNameIsRequired: 'Требуется имя вещания',
        continue: 'Продолжать',
        manageBroadcasts: 'Управление трансляциями',
        youCanCreateMultipleBroadcastsWith: 'Вы можете создать несколько трансляций с несколькими запланированными шаблонами для различных сегментов клиентов.',
        createNewBroadcast: 'Создайте новую трансляцию',
        name: 'Имя',
        segment: 'Группа',
        template: 'Шаблон',
        contacts: 'Контакты',
        send: 'Отправлять',
        failed: 'Неуспешный',
        broadcastAt: 'Трансляция в',
        status: 'Положение дел',
        actions: 'Действия',
        nA: 'НА',
        deleteBroadcast: 'Удалить трансляцию',
        wantToDeleteThisBroadcast: 'Вы уверены, что хотите удалить эту трансляцию?',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          searchHere: 'Поищи здесь',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Удалить несколько трансляций',
          deleteBroadcast: 'Удалить трансляцию',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Название вещания',
        broadcastNameIsRequired: 'Требуется имя вещания',
        flow: 'Поток',
        segments: 'Группы',
        whenToSend: 'Когда отправить',
        selectDate: 'Выберите дату',
        selectTimeSlot: 'Выберите временной интервал',
        createBroadcast: 'Создать трансляцию',
        cancel: 'Отмена',
        now: 'Сейчас',
        later: 'Позже',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          selectFlow: 'Выберите поток',
          searchFlowByName: 'Поток поиска по имени',
          selectSegments: 'Выберите Группы',
          searchSegmentByName: 'Группа поиска по имени',
          selectTimeSlot: 'Выберите временной интервал',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Выберите дату начала',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Создать капельную кампанию',
        youCanCreateMultipleCampaign: 'Вы можете создать несколько кампаний',
        dripCampaignName: 'Капельное название кампании',
        dripCampaignNameIsRequired: 'Требуется название капельной кампании',
        continue: 'Продолжать',
        manageDripCampaign: 'Управление капельной кампанией',
        youCanCreateMutlipleDripCampaign: 'Вы можете создать несколько капельных кампаний с запланированными автоматизированными сообщениями для запуска ответов из конкретных сегментов пользователей.',
        createNewDripCampaign: 'Создать новую капельную кампанию',
        campaignName: 'Название кампании',
        appliedOn: 'Применяется на',
        segment: 'Группа',
        flow: 'Поток',
        startDate: 'Дата начала',
        action: 'Действие',
        nA: 'НА',
        deleteDripCampaign: 'Удалить капельные кампании',
        wantToDeleteThisDripCampaign: 'Вы уверены, что хотите удалить эти капельные кампании?',
        placeholders: {
          enterDripCampaignName: 'Введите название капельной кампании',
          searchByName: 'Поиск по имени',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Удалить несколько капельных кампаний',
          deleteDripCampaign: 'Удалить капельную кампанию',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Капельное название кампании',
          appliedOn: 'Применяется на',
          segments: 'Группы',
          selectDate: 'Выберите дату',
          scheduleYourFlows: 'Запланируйте свои потоки',
          schedule: 'Расписание',
          flow: 'Поток',
          sendAfter: 'Отправить после',
          cancel: 'Отмена',
          newSubscriber: 'Новый подписчик',
          days: 'Дни',
          minutes: 'Минуты',
          hours: 'Часы',
          createDripCampaign: 'Создать капельную кампанию',
          placeholders: {
            enterDripName: 'Введите имя капельницы',
            selectAppliedOn: 'Выберите «Применить»',
            selectSegment: 'Выберите Группа',
            searchByName: 'Поиск по имени',
            selectTemplate: 'Выберите шаблон',
            searchFlowByName: 'Поток поиска по имени',
            enterSendAfter: 'Введите отправить после',
          },
          dateTimePicker: {
            selectStartDate: 'Выберите дату начала',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'Чат идентификатор',
        errorCode: 'Код ошибки',
        errorMessage: 'Сообщение об ошибке',
        nA: 'НА',
      },
      flows: {
        chatFlows: 'Чат потоки',
        createChatFlow: 'Создайте поток чата',
        youCanCreateMultipleChatFlows: 'Вы можете создать несколько потоков разговоров по нескольким каналам для A/B -тестирования и трансляций.',
        flowName: 'Имя потока',
        nameIsRequired: 'Имя требуется',
        cancel: 'Отмена',
        createFlow: 'Создать поток',
        importFlow: 'Импортный поток',
        createNewFlow: 'Создайте новый поток',
        ofMessages: 'сообщений',
        createdOn: 'Создано на',
        lastModified: 'Последнее изменение',
        defaultFlow: 'Поток по умолчанию',
        action: 'Действие',
        exportStatus: 'Статус экспорта',
        flowExport: 'Поток- экспорт',
        download: 'Скачать',
        // importFlow: 'Импортный поток',
        beforeYouStartUploadPleaseMakeSure: 'Прежде чем начать загрузку, пожалуйста, убедитесь',
        point1: '1. Максимальный предел загрузки размера файла составляет 1 МБ',
        point2: '2. Формат файла должен быть в JSON',
        selectFile: 'Выберите файл',
        inProgress: 'в ходе выполнения',
        available: 'Доступный',
        initiated: 'Инициировано',
        placeholders: {
          enterFlowName: 'Введите имя потока',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Импортный поток для бота в Facebook',
          dateAndTimeOfCreationOfChatFlow: 'Дата и время создания потока чата',
          dateAndTimeWhenChatFlowLastModified: 'Дата и время, когда поток чата был в последний раз изменен',
          TOOLTIP_CREATE_FLOW: 'Нажмите, чтобы создать новый поток чата',
          TOOLTIP_FLOW_NAME: 'Введите имя потока чата',
          TOOLTIP_FLOW_TABLE_NAME: 'Имя потока чата',
          TOOLTIP_NO_OF_MESSAGES: 'Количество компонентов сообщения в потоке чата',
          TOOLTIP_DEFAULT_FLOW: 'Используйте этот переключатель, чтобы установить поток чата по умолчанию, который будет обслуживаться всем новым пользователям.',
          TOOLTIP_ACTION: 'Изменить или удалить поток чата',
        },
        noDataFound: {
          flows: 'Потоки',
          noFlowsAddedYet: 'Пока еще не добавили!',
        },
        modal: {
          deleteFlow: 'Удалить поток',
          wantToDeleteThisFlow: 'Вы уверены, что хотите удалить этот поток?',
        }
      },
      widgets: {
        manageWidgets: 'Управлять виджетами',
        widgetsAreRoutingTools: 'Виджеты - это инструменты маршрутизации для увеличения использования бота',
        needHelp: 'Нужна помощь?',
        wantToMakeChanges: 'Хотите внести изменения?',
        editIcon: 'Редактировать значок',
        discard: 'Отказаться',
        updateWidget: 'Обновить виджет',
        installChatBotIconOnYourWebsite: 'Установите значок Chatbot на свой сайт',
        shareWhereEverYouWantToRedirectUsersTo: 'Поделиться, где вы хотите, и перенаправить пользователей в свой бот Facebook в однокомзвоните',
        copyAndAddInYourWebsiteHeaderFile: 'Скопировать и добавить в файл заголовка вашего сайта',
        copyLinkAndStartSharing: 'Скопируйте ссылку и начните поделиться!',
        downloadQrCodeAndStartSharing: 'Скачать QR -код и начать обмен!',
        addCodeAndDeployNewCodeAndDone: 'Добавьте код, разверните новый код и сделанный!',
        wantToLearnHowToUseIt: 'Хотите узнать, как его использовать?',
        copyChatBotCode: 'Скопируйте код чатбота',
        copyFacebookLink: 'Скопируйте ссылку на Facebook',
        downloadQrCode: 'Скачать QR -код',
        link: 'Связь',
        chatBotIcon: 'Значок чат -бот',
        qrcode: 'QR код',
        small: 'Маленький',
        medium: 'Середина',
        large: 'Большой',
        leftMiddle: 'Левая средняя',
        rightMiddle: 'Правая средняя',
        leftBottom: 'Левый блок',
        rightBottom: 'Правый',
        bpDropDown: {
          selectIcons: 'Выберите значки',
          iconSize: 'Размер значков',
          iconPlacement: 'Расположение значков',
        }
      },
      autoReply: {
        autoReplyTitle: 'Автоответ',
        configureYourAutoReplyMessage: 'Настройте ваше сообщение автоответа',
        configureYour: 'Настройте ваше сообщение ',
        message: '',
        customizeAutoReplyMessagesForYourPostsComments: 'Настройте автоответы для комментариев к вашим постам',
        wouldYouLikeToApply: 'Хотели бы вы применить',
        autoreply: 'автоответ',
        onAllPost: ' ко всем постам?',
        configureYourMessageForEachPost: 'Настройте ваше сообщение для каждого поста',
        configureFlowToTriggerAfterOnReply: 'Настройте поток для запуска после ответа',
        whenUserLeavesACommentSendMessageAfter: 'Когда пользователь оставляет комментарий, отправьте сообщение после',
        triggerFlowOnReply: 'Запустить поток при ответе',
        add: 'Добавить',
        update: 'Обновить',
        save: 'Сохранить',
        cancel: 'Отмена',
        discard: 'Удалить',
        bpDropDown: {
          selectPost: 'Выберите пост',
          triggerFlowOnReply: 'Запустить поток при ответе',
          immendiately: 'Сразу',
          minutes: 'минуты'
        },
        bpTable: {
          message: 'Сообщение',
          selectedPost: 'Выбранный пост',
          triggerFlow: 'Запустить поток',
          actions: 'Действия'
        },
        placeholder: {
          configureYourMessage: 'Настройте ваше сообщение'
        },
        deleteModel: {
          title: 'Удалить автоответ на пост',
          description: 'Вы уверены, что хотите удалить этот автоответ на пост?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'Время завершения сеанса',
        sendInactivityMessage: 'Отправить сообщение об отсутствии активности',
        enterSessionTimeOutWindowInHours: 'Введите окно завершения сеанса в часах',
        sessionTimeOutInHoursTooltip: `Введите продолжительность, после которой сеанс чата автоматически завершится из-за бездействия. После завершения времени сеанса чат будет перезапущен, если пользователь отправит любое сообщение.`,
        restartChatFlowTriggers: 'Триггеры для перезапуска чата',
        enterKeyWordsToRestartChatAndhitEnter: 'Введите ключевые слова для перезапуска чата и нажмите Enter',
        restartChatFlowTriggersTooltip: `Укажите ключевые слова, которые, когда их вводит пользователь и отправляет, вызовут перезапуск чата. Это позволяет пользователям перезапускать беседу в любой момент.
         Нажимайте Enter после добавления каждого ключевого слова, чтобы добавить несколько ключевых слов.`,
        liveChatTriggers: 'Триггеры для онлайн-чата',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Введите ключевые слова для запуска онлайн-чата и нажмите Enter',
        liveChatTriggersTooltip: `Определите ключевые слова, которые, когда их вводит пользователь и отправляет, инициируют переход от автоматизированного чата к онлайн-чату. Это полезно, когда пользователи нуждаются в помощи живого агента.
         Нажимайте Enter после добавления каждого ключевого слова, чтобы добавить несколько ключевых слов.`,
        messageAfterAutomatedFlowCompleted: 'Сообщение после завершения автоматизированного потока',
        enterYourMessage: 'Введите ваше сообщение',
        messageAfterAutomatedFlowCompletedTooltip: `Введите сообщение, которое будет отображаться пользователю после завершения им автоматизированного потока чата, но сеанс не завершен.`,
        restart: 'Перезапуск',
        liveChat: 'Онлайн-чат',
        lineOneForDefaultMessage: 'Если у вас есть еще вопросы или вам нужна помощь, вот что вы можете сделать далее:',
        to: 'Для',
        restartTheChat: 'перезапустить чат',
        simpleTypeRestartAndHitEnter: `просто введите 'Перезапуск' и нажмите Enter. Это вернет вас в начало нашего разговора.`,
        lineThreeForDefaultMessagePartOne: `Если вы хотите поговорить с `,
        liveAgent: `живым агентом,`,
        lineThreeForDefaultMessageLastPart: `введите 'Онлайн-чат' и нажмите Enter.
        Наша команда здесь, чтобы помочь вам в реальном времени.`,
        saveChanges: 'Сохранить изменения',
        discard: 'Отменить',
        configureBotsGeneralSettings: 'Настройте общие параметры вашего бота',
        minutes: 'Минуты',
        hours: 'Часы',
      }
    },
    instagram: {
      instagramBotSettings: 'Настройки бота в Instagram',
      chatFlowsHeader: 'Чат потоки',
      broadcastHeader: 'Транслировать',
      dripCampaignHeader: 'Капельная кампания',
      widgetsHeader: 'Виджеты',
      alertsHeader: 'Оповещения',
      alerts: {
        configureYourBotsSettings: 'Настройте настройки оповещения вашего бота',
        specifyHowYourBotNotifyYou: 'Укажите, как ваш бот уведомит вас о новых потенциальных клиентах.',
        subject: 'Предмет',
        emailNotification: 'Уведомление по электронной почте',
        emailAddresses: 'Адрес электронной почты',
        leadRevisitNotifications: 'Ведущие уведомления о пересмотре',
        whenToSendLeadInfo: 'Когда отправлять информацию о свинце',
        discard: 'Отказаться',
        saveChanges: 'Сохранить изменения',
        placeholders: {
          enterSubjectForYourEmail: 'Введите тему для вашего электронного письма',
          typeYourEmailAddressAndHitEnter: 'Введите свой адрес электронной почты и нажмите Enter',
        }
      },
      broadcasts: {
        createBroadcast: 'Создать трансляцию',
        youCanCreateMultipleBroadcasts: 'Вы можете создать несколько трансляций',
        broadcastName: 'Название вещания',
        broadcastNameIsRequired: 'Требуется имя вещания',
        continue: 'Продолжать',
        manageBroadcasts: 'Управление трансляциями',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Вы можете создать несколько трансляций с несколькими запланированными шаблонами для разных сегментов клиентов',
        createNewBroadcast: 'Создайте новую трансляцию',
        segment: 'Группа',
        template: 'Шаблон',
        contacts: 'Контакты',
        send: 'Отправлять',
        failed: 'Неуспешный',
        broadcastAt: 'Трансляция в',
        status: 'Положение дел',
        actions: 'Действия',
        nA: 'НА',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          searchHere: 'Поищи здесь',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Удалить несколько трансляций',
          deleteBroadcast: 'Удалить трансляцию',
        }
      },
      createBroadcast: {
        broadcastName: 'Название вещания',
        broadcastNameIsRequired: 'Требуется имя вещания',
        flow: 'Поток',
        segments: 'Группы',
        whenToSend: 'Когда отправить',
        selectDate: 'Выберите дату',
        selectTimeSlot: 'Выберите временной интервал',
        cancel: 'Отмена',
        createBroadcast: 'Создать трансляцию',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          selectFlow: 'Выберите поток',
          searchFlowByName: 'Поток поиска по имени',
          selectSegments: 'Выберите Группы',
          searchSegmentByName: 'Группа поиска по имени',
          selectTimeSlot: 'Выберите временной интервал',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Выберите дату начала',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Создать капельную кампанию',
        youCanCreateMultipleCampaigns: 'Вы можете создать несколько кампаний',
        dripCampaignName: 'Капельное название кампании',
        dripCampaignNameIsRequired: 'Требуется название капельной кампании',
        continue: 'Продолжать',
        manageDripCampaign: 'Управление капельной кампанией',
        youCanCreateMultipleCampaignsWith: 'Вы можете создать несколько капельных кампаний с запланированными автоматизированными сообщениями для запуска ответов из конкретных сегментов пользователей',
        createNewDripCampaign: 'Создать новую капельную кампанию',
        campaignName: 'Название кампании',
        appliedOn: 'Применяется на',
        segment: 'Группа',
        flow: 'Поток',
        startDate: 'Дата начала',
        action: 'Действие',
        nA: 'НА',
        placeholders: {
          enterDripCampaignName: 'Введите название капельной кампании',
          searchByName: 'Поиск по имени',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Удалить несколько капельных кампаний',
          deleteDripCampaign: 'Удалить капельную кампанию',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Капельное название кампании',
          appliedOn: 'Применяется на',
          segments: 'Группы',
          selectDate: 'Выберите дату',
          scheduleYourFlows: 'Запланируйте свои потоки',
          flow: 'Поток',
          sendAfter: 'Отправить после',
          cancel: 'Отмена',
          createDripCampaign: 'Создать капельную кампанию',
          placeholders: {
            enterDripName: 'Введите имя капельницы',
            selectAppliedOn: 'Выберите «Применить»',
            selectSegment: 'Выберите Группа',
            searchByName: 'Поиск по имени',
            selectTemplate: 'Выберите шаблон',
            searchFlowByName: 'Поток поиска по имени',
            enterSendAfter: 'Введите отправить после',
          },
          dateTimePicker: {
            selectStartDate: 'Выберите дату начала',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'Чат идентификатор',
        errorCode: 'Код ошибки',
        errorMessage: 'Сообщение об ошибке',
        nA: 'НА',
      },
      flows: {
        chatFlows: 'Чат потоки',
        createChatFlow: 'Создайте поток чата',
        youCanCreateMultipleChatFlows: 'Вы можете создать несколько потоков разговоров по нескольким каналам для A/B -тестирования и трансляций.',
        flowName: 'Имя потока',
        nameIsRequired: 'Имя требуется',
        cancel: 'Отмена',
        createFlow: 'Создать поток',
        importFlow: 'Импортный поток',
        createNewFlow: 'Создайте новый поток',
        // flowName: 'Имя потока',
        ofMessages: ' сообщений',
        createdOn: 'Создано на',
        lastModified: 'Последнее изменение',
        defaultFlow: 'Поток по умолчанию',
        action: 'Действие',
        exportStatus: 'Статус экспорта',
        flowExport: 'Поток- экспорт',
        download: 'Скачать',
        // importFlow: 'Импортный поток',
        beforeYouStartUploadPleaseMakeSure: 'Прежде чем начать загрузку, пожалуйста, убедитесь',
        point1: '1. Максимальный предел загрузки размера файла составляет 1 МБ',
        point2: '2. Формат файла должен быть в JSON',
        selectFile: 'Выберите файл',
        placeholders: {
          enterFlowName: 'Введите имя потока',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Импортный поток для Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Дата и время создания потока чата',
          dateAndTimeWhenChatFlowLastModified: 'Дата и время, когда поток чата был в последний раз изменен',
        },
        noDataFound: {
          flows: 'Потоки',
          noFlowsAddedYet: 'Пока еще не добавили!',
        },
        modal: {
          deleteFlow: 'Удалить поток',
          wantToDeleteThisFlow: 'Вы уверены, что хотите удалить этот поток?',
        }
      },
      widgets: {
        manageWidgets: 'Управлять виджетами',
        widgetsAreRoutingTools: 'Виджеты - это инструменты маршрутизации для увеличения использования бота',
        needHelp: 'Нужна помощь?',
        wantToMakeChanges: 'Хотите внести изменения?',
        editIcon: 'Редактировать значок',
        discard: 'Отказаться',
        updateWidget: 'Обновить виджет',
        bpDropDown: {
          selectIcons: 'Выберите значки',
          iconSize: 'Размер значков',
          iconPlacement: 'Расположение значков',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Телеграмма настройки бота',
      chatFlowsHeader: 'Чат потоки',
      telegramProfileHeader: 'Телеграмма профиль',
      widgetsHeader: 'Виджеты',
      broadcastHeader: 'Транслировать',
      dripCampaignHeader: 'Капельная кампания',
      generalHeader: 'Общий',
      telegramAutomation: 'Телеграмма автоматизация',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Создайте путешествие по борьбе с клиентом, используя капельную' +
          ' кампанию запланированных потоков чата.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Настройте шаблоны потока чата и создайте несколько потоков чата для разных целевых аудиторий.',
      },
      broadcasts: {
        createBroadcast: 'Создать трансляцию',
        youCanCreateMutlipleBroadcasts: 'Вы можете создать несколько трансляций',
        broadcastName: 'Название вещания',
        broadcastNameIsRequired: 'Требуется имя вещания',
        continue: 'Продолжать',
        manageBroadcasts: 'Управление трансляциями',
        youCanCreateMultipleBroadcastsWith: 'Вы можете создать несколько трансляций с несколькими запланированными шаблонами для различных сегментов клиентов.',
        createNewBroadcast: 'Создайте новую трансляцию',
        name: 'Имя',
        segment: 'Группа',
        template: 'Шаблон',
        contacts: 'Контакты',
        send: 'Отправлять',
        failed: 'Неуспешный',
        broadcastAt: 'Трансляция в',
        status: 'Положение дел',
        actions: 'Действия',
        nA: 'НА',
        deleteBroadcast: 'Удалить трансляцию',
        wantToDeleteThisBroadcast: 'Вы уверены, что хотите удалить эту трансляцию?',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          searchHere: 'Поищи здесь',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Удалить несколько трансляций',
          deleteBroadcast: 'Удалить трансляцию',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'Название вещания',
        broadcastNameIsRequired: 'Требуется имя вещания',
        flow: 'Поток',
        segments: 'Группы',
        whenToSend: 'Когда отправить',
        selectDate: 'Выберите дату',
        selectTimeSlot: 'Выберите временной интервал',
        cancel: 'Отмена',
        createBroadcast: 'Создать трансляцию',
        now: 'Сейчас',
        later: 'Позже',
        placeholders: {
          enterBroadcastName: 'Введите имя вещания',
          selectFlow: 'Выберите поток',
          searchFlowByName: 'Поток поиска по имени',
          selectSegments: 'Выберите Группы',
          searchSegmentByName: 'Группа поиска по имени',
          selectTimeSlot: 'Выберите временной интервал',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Выберите дату начала',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Создать капельную кампанию',
        youCanCreateMultipleCampaigns: 'Вы можете создать несколько кампаний',
        dripCampaignName: 'Капельное название кампании',
        dripCampaignNameIsRequired: 'Требуется название капельной кампании',
        continue: 'Продолжать',
        manageDripCampaign: 'Управление капельной кампанией',
        youCanCreateMultipleDripCampaignsWith: 'Вы можете создать несколько капельных кампаний с запланированными автоматизированными сообщениями для запуска ответов из конкретных сегментов пользователей.',
        createNewDripCampaign: 'Создать новую капельную кампанию',
        campaignName: 'Название кампании',
        appliedOn: 'Применяется на',
        segment: 'Группа',
        flow: 'Поток',
        startDate: 'Дата начала',
        action: 'Действие',
        nA: 'НА',
        deleteDripCampaign: 'Удалить капельные кампании',
        wantToDeleteThisDripCampaign: 'Вы уверены, что хотите удалить эти капельные кампании?',
        placeholders: {
          enterDripCampaignName: 'Введите название капельной кампании',
          searchByName: 'Поиск по имени',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Удалить несколько капельных кампаний',
          deleteDripCampaign: 'Удалить капельную кампанию',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Капельное название кампании',
          appliedOn: 'Применяется на',
          segments: 'Группы',
          startDate: 'Выберите дату',
          scheduleYourFlows: 'Запланируйте свои потоки',
          schedule: 'Расписание',
          flow: 'Поток',
          sendAfter: 'Отправить после',
          cancel: 'Отмена',
          createDripCampaign: 'Создать капельную кампанию',
          newSubscriber: 'Новый подписчик',
          days: 'Дни',
          minutes: 'Минуты',
          hours: 'Часы',
          placeholders: {
            enterDripName: 'Введите имя капельницы',
            selectAppliedOn: 'Выберите «Применить»',
            selectSegment: 'Выберите Группа',
            searchByName: 'Поиск по имени',
            selectFlow: 'Выберите поток',
            searchFlowByName: 'Поток поиска по имени',
          },
          dateTimePicker: {
            selectStartDate: 'Выберите дату начала',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Имя',
        email: 'Электронная почта',
        phone: 'Телефон',
        errorCode: 'Код ошибки',
        errorMessage: 'Сообщение об ошибке',
        nA: 'НА',
      },
      flows: {
        chatFlows: 'Чат потоки',
        createChatFlow: 'Создайте поток чата',
        youCanCreateMultipleChatFlows: 'Вы можете создать несколько потоков разговоров по нескольким каналам для A/B -тестирования и трансляций.',
        flowName: 'Имя потока',
        nameIsRequired: 'Имя требуется',
        cancel: 'Отмена',
        createFlow: 'Создать поток',
        importFlow: 'Импортный поток',
        createNewFlow: 'Создайте новый поток',
        // flowName: 'Имя потока',
        ofMessages: ' сообщений',
        createdOn: 'Создано на',
        lastModified: 'Последнее изменение',
        defaultFlow: 'Поток по умолчанию',
        action: 'Действие',
        exportStatus: 'Статус экспорта',
        flowExport: 'Поток- экспорт',
        inProgress: 'в ходе выполнения',
        available: 'Доступный',
        initiated: 'Инициировано',
        download: 'Скачать',
        // importFlow: 'Импортный поток',
        beforeYouStartUploadPleaseMakeSure: 'Прежде чем начать загрузку, пожалуйста, убедитесь',
        point1: '1. Максимальный предел загрузки размера файла составляет 1 МБ',
        point2: '2. Формат файла должен быть в JSON',
        selectFile: 'Выберите файл',
        placeholders: {
          enterFlowName: 'Введите имя потока',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Импортный поток для Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Дата и время создания потока чата',
          dateAndTimeWhenChatFlowLastModified: 'Дата и время, когда поток чата был в последний раз изменен',
          TOOLTIP_CREATE_FLOW: 'Нажмите, чтобы создать новый поток чата',
          TOOLTIP_FLOW_NAME: 'Введите имя потока чата',
          TOOLTIP_FLOW_TABLE_NAME: 'Имя потока чата',
          TOOLTIP_NO_OF_MESSAGES: 'Количество компонентов сообщения в потоке чата',
          TOOLTIP_DEFAULT_FLOW: 'Используйте этот переключатель, чтобы установить поток чата по умолчанию, который будет обслуживаться всем новым пользователям.',
          TOOLTIP_ACTION: 'Изменить или удалить поток чата',
        },
        noDataFound: {
          flows: 'Потоки',
          noFlowsAddedYet: 'Пока еще не добавили!',
        },
        modal: {
          deleteFlow: 'Удалить поток',
          wantToDeleteThisFlow: 'Вы уверены, что хотите удалить этот поток?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Телеграмма профиль',
        manageYourBotProfile: 'Управляйте своим профилем бота, как и когда вы хотите.',
        allSetNoActionRequired: 'Все установлено, никаких действий не требуется!',
        congratulationYourAccountIsLive: 'Поздравляю! Ваша учетная запись живет с полным доступом',
        botDetails: 'Детали бота',
        name: 'Имя',
        nA: 'НА',
        b: 'Беременный',
        botName: 'Название бота',
        bot: 'Бот',
        info: 'Информация',
        none: 'Никто',
        username: 'Имя пользователя',
        notifications: 'Уведомления',
        on: 'На',
        addToGroupOrChannel: 'Добавить в группу или канал',
        userName: 'Имя пользователя',
        botToken: 'Токен бота',
      },
      widgets: {
        manageWidgets: 'Управлять виджетами',
        widgetsAreRoutingTools: 'Виджеты - это инструменты маршрутизации для увеличения использования бота',
        needHelp: 'Нужна помощь?',
        wantToMakeChanges: 'Хотите внести изменения?',
        editIcon: 'Редактировать значок',
        discard: 'Отказаться',
        updateWidget: 'Обновить виджет',
        installChatBotIconOnYourWebsite: 'Установите значок Chatbot на свой сайт',
        shareWhereEverYouWantRedirectUsersTo: 'Поделиться, где вы хотите, и перенаправить пользователей в свой бот Telegram в одном щелчке',
        copyAndAddInYourWebsiteHeaderFile: 'Скопировать и добавить в файл заголовка вашего сайта',
        copyLinkAndStartSharing: 'Скопируйте ссылку и начните поделиться!',
        downloadQrCodeAndStartSharing: 'Скачать QR -код и начать обмен!',
        addCodeAndDeployNewCodeAndDone: 'Добавьте код, разверните новый код и сделанный!',
        wantToLearnHowToUseIt: 'Хотите узнать, как его использовать?',
        copyChatBotCode: 'Скопируйте код чатбота',
        downloadQrCode: 'Скачать QR -код',
        copyTelegramLink: 'Ссылка на копию телеграммы',
        link: 'Связь',
        chatBotIcon: 'Значок чат -бот',
        qrcode: 'QR код',
        small: 'Маленький',
        medium: 'Середина',
        large: 'Большой',
        leftMiddle: 'Левая средняя',
        rightMiddle: 'Правая средняя',
        leftBottom: 'Левый блок',
        rightBottom: 'Правый',
        bpDropDown: {
          selectIcons: 'Выберите значки',
          iconSize: 'Размер значков',
          iconPlacement: 'Расположение значков',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Время завершения сеанса',
        sendInactivityMessage: 'Отправить сообщение об отсутствии активности',
        enterSessionTimeOutWindowInHours: 'Введите окно завершения сеанса в часах',
        sessionTimeOutInHoursTooltip: `Введите продолжительность, после которой сеанс чата автоматически завершится из-за бездействия. После завершения времени сеанса чат будет перезапущен, если пользователь отправит любое сообщение.`,
        restartChatFlowTriggers: 'Триггеры для перезапуска чата',
        enterKeyWordsToRestartChatAndhitEnter: 'Введите ключевые слова для перезапуска чата и нажмите Enter',
        restartChatFlowTriggersTooltip: `Укажите ключевые слова, которые, когда их вводит пользователь и отправляет, вызовут перезапуск чата. Это позволяет пользователям перезапускать беседу в любой момент.
         Нажимайте Enter после добавления каждого ключевого слова, чтобы добавить несколько ключевых слов.`,
        liveChatTriggers: 'Триггеры для онлайн-чата',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Введите ключевые слова для запуска онлайн-чата и нажмите Enter',
        liveChatTriggersTooltip: `Определите ключевые слова, которые, когда их вводит пользователь и отправляет, инициируют переход от автоматизированного чата к онлайн-чату. Это полезно, когда пользователи нуждаются в помощи живого агента.
         Нажимайте Enter после добавления каждого ключевого слова, чтобы добавить несколько ключевых слов.`,
        messageAfterAutomatedFlowCompleted: 'Сообщение после завершения автоматизированного потока',
        enterYourMessage: 'Введите ваше сообщение',
        messageAfterAutomatedFlowCompletedTooltip: `Введите сообщение, которое будет отображаться пользователю после завершения им автоматизированного потока чата, но сеанс не завершен.`,
        restart: 'Перезапуск',
        liveChat: 'Онлайн-чат',
        lineOneForDefaultMessage: 'Если у вас есть еще вопросы или вам нужна помощь, вот что вы можете сделать далее:',
        to: 'Для',
        restartTheChat: 'перезапустить чат',
        simpleTypeRestartAndHitEnter: `просто введите 'Перезапуск' и нажмите Enter. Это вернет вас в начало нашего разговора.`,
        lineThreeForDefaultMessagePartOne: `Если вы хотите поговорить с `,
        liveAgent: `живым агентом,`,
        lineThreeForDefaultMessageLastPart: `введите 'Онлайн-чат' и нажмите Enter.
        Наша команда здесь, чтобы помочь вам в реальном времени.`,
        saveChanges: 'Сохранить изменения',
        discard: 'Отменить',
        configureBotsGeneralSettings: 'Настройте общие параметры вашего бота',
        minutes: 'Минуты',
        hours: 'Часы',
      }
    },
    whatsapp: {
      whatsappAutomation: 'Автоматизация WhatsApp',
      whatsappBotSettings: 'Настройки бота WhatsApp',
      chatFlowsHeader: 'Чат потоки',
      generalHeader: 'Общие',
      whatsappProfileHeader: 'Профиль WhatsApp',
      broadcastHeader: 'Транслировать',
      dripCampaignHeader: 'Капельная кампания',
      widgetHeader: 'Виджет',
      templatesHeader: 'Шаблоны',
      alertsHeader: 'Оповещения',
      apiKeyHeader: 'API -ключ',
      catalogHeader: 'Каталог',
      otherConfiguration: 'Настройки ИИ',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Настройте шаблоны потока чата и создайте несколько потоков чата для разных целевых аудиторий.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Настройте настройки оповещения вашего бота',
        specifyHowYouBotWillNotify: 'Укажите, как ваш бот уведомит вас о новых потенциальных клиентах.',
        subject: 'Предмет',
        emailNotification: 'Уведомление по электронной почте',
        emailAddresses: 'Адрес электронной почты',
        leadRevisitNotifications: 'Ведущие уведомления о пересмотре',
        whenToSendLeadInfo: 'Когда отправлять информацию о свинце',
        discard: 'Отказаться',
        saveChanges: 'Сохранить изменения',
        seeHowToUse: 'Посмотрите, как использовать',
        beginningTheFlow: 'Начало потока',
        fiveMinutesLater: '5 минут спустя',
        endOfFlow: 'Конец потока',
        placeholders: {
          enterSubjectForYourEmail: 'Введите тему для вашего электронного письма',
          typeEmailAndHitEnter: 'Введите свой адрес электронной почты и нажмите Enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Настройте тему ваших уведомлений по электронной почте от бота',
          TOOLTIP_EMAIL_NOTIFICATION: 'Включите/отключите уведомление бота на своем электронном письме для новых лидов',
          TOOLTIP_EMAIL_ADDRESS: 'Отправьте адрес электронной почты учетной записи, где вы хотите получить уведомления по электронной почте. Вы можете добавить несколько адресов электронной почты, введя один и нажав Enter, чтобы добавить другой.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Включите/отключите уведомление BOT на вашем электронном письме, если пользователь, ранее захваченный в качестве свинца, снова взаимодействует с ботом.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Обновите WhatsApp Chatbot',
        businessNameIsRequired: 'Название бизнеса требуется.',
        maxLengthForBusinessName40Characters: 'Максимальная длина для названия бизнеса составляет 40 символов.',
        categoryIsRequired: 'Категория требуется.',
        descriptionIsRequired: 'Описание требуется.',
        maxLengthForDescription200Characters: 'Максимальная длина для описания составляет 200 символов.',
        aboutIsRequired: 'О ТРЕБУЕМЫЕ.',
        maxLengthForAbout139Characters: 'Максимальная длина для около 139 символов.',
        pleaseFillAValidBusinessEmail: 'Пожалуйста, заполните действительное деловое электронное письмо.',
        businessEmailIsRequired: 'Деловая электронная почта требуется.',
        websiteIsRequired: 'Сайт требуется.',
        maxLengthForWebsite40Characters: 'Максимальная длина для веб -сайта составляет 40 символов.',
        maxLengthForAddress150Characters: 'Максимальная длина для адреса составляет 150 символов.',
        cancel: 'Отмена',
        update: 'Обновлять',
        businessCategory: 'Бизнес -категория',
        placeholders: {
          yourBusinessName: 'Название вашей компании',
          businessDescription: 'Описание деятельности',
          about: 'О',
          businessEmail: 'Деловое письмо',
          website: 'Веб -сайт (например, www.example.com)',
          address: 'Адрес',
        }
      },
      flows: {
        chatFlows: 'Чат потоки',
        createChatFlow: 'Создайте поток чата',
        youCanCreateMultipleChatFlows: 'Вы можете создать несколько потоков разговоров по нескольким каналам для A/B -тестирования и трансляций.',
        flowName: 'Имя потока',
        nameIsRequired: 'Имя требуется',
        cancel: 'Отмена',
        createFlow: 'Создать поток',
        importFlow: 'Импортный поток',
        createNewFlow: 'Создайте новый поток',
        ofMessages: ' сообщений',
        createdOn: 'Создано на',
        lastModified: 'Последнее изменение',
        defaultFlow: 'Поток по умолчанию',
        revisitFlow: 'Повторный поток',
        action: 'Действие',
        exportStatus: 'Статус экспорта',
        flowExport: 'Поток- экспорт',
        // importFlow: 'Импортный поток',
        beforeYouStartUploadPleaseMakeSure: 'Прежде чем начать загрузку, пожалуйста, убедитесь',
        point1: '1. Максимальный предел загрузки размера файла составляет 1 МБ',
        point2: '2. Формат файла должен быть в JSON',
        selectFile: 'Выберите файл',
        seeHowToUse: 'Посмотрите, как использовать',
        download: 'Скачать',
        available: 'Доступный',
        initiated: 'Инициировано',
        inProgress: 'в ходе выполнения',
        placeholders: {
          enterFlowName: 'Введите имя потока',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Импорт потока для WhatsApp Bot',
          dateAndTimeOfCreationOfChatFlow: 'Дата и время создания потока чата',
          dateAndTimeWhenChatFlowLastModified: 'Дата и время, когда поток чата был в последний раз изменен',
          TOOLTIP_CREATE_FLOW: 'Нажмите, чтобы создать новый поток чата',
          TOOLTIP_FLOW_NAME: 'Введите имя потока чата',
          TOOLTIP_FLOW_TABLE_NAME: 'Имя потока чата',
          TOOLTIP_NO_OF_MESSAGES: 'Количество компонентов сообщения в потоке чата',
          TOOLTIP_DEFAULT_FLOW: 'Используйте этот переключатель, чтобы установить поток чата по умолчанию, который будет обслуживаться всем новым пользователям.',
          TOOLTIP_REVISIT_FLOW: 'Используйте этот переключатель, чтобы выбрать поток чата, который будет обслуживаться пользователям, когда они пересматривают ваш чат -бот.',
          TOOLTIP_ACTION: 'Изменить или удалить поток чата',
        },
        modal: {
          deleteFlow: 'Удалить поток',
          wantToDeleteThisFlow: 'Вы уверены, что хотите удалить этот поток?',
        },
        noDataFound: {
          flows: 'Потоки',
          noFlowsAddedYet: 'Пока еще не добавили!',
        }
      },
      input: {
        manageTemplates: 'Управление шаблонами',
        manageYourTemplatesForMarketingAutomation: 'Управляйте своими шаблонами для автоматизации маркетинга',
        syncTemplates: 'Шаблоны синхронизации',
        createTemplateHeader: 'Создать шаблон',
        title: 'Заголовок',
        info: 'Информация',
        category: 'Категория',
        status: 'Положение дел',
        action: 'Действие',
        shipmentUpdate: 'Обновление отгрузки',
        name: 'Имя',
        // category: 'Категория',
        headerOptional: 'Заголовок (необязательно)',
        bodyRequired: 'Тело (требуется)',
        footerOptional: 'Нижний колонтитул (необязательно)',
        buttonsOptional: 'Кнопки (необязательно)',
        makeUpto3Buttons: 'Макияж до 3 кнопок',
        makeUpto2ButtonsToRedirect: 'Сделайте до 2 кнопок, чтобы перенаправить клиента на ваш сайт',
        placeholders: {
          nameYourTemplate: 'Назовите свой шаблон (используйте подчеркивание, чтобы отделить слова)',
          enterText: 'Введите текст (максимум 60 символов)',
          enterImageUrl: 'Введите URL -адрес',
          enterVideoUrl: 'Введите видео URL',
          templateMessage: 'Шаблон сообщения',
          smallFooterAtBottom: 'Маленький нижний колонтитул внизу',
          buttons: 'Кнопки',
          text: 'Текст',
          numberWithCountryCode: 'Номер с кодом страны',
          enterButtonText: 'Введите текст кнопки (максимальный символ 20)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Название шаблона WhatsApp',
          TOOLTIP_INPUT_TABLE_INFO: 'Подробная информация о сообщениях инициировала бизнес',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Тип бизнес -инициированных сообщений, таких как встречи, напоминания, сообщения о обслуживании клиентов, обновления доставки, оповещения и многое другое',
          TOOLTIP_INPUT_TABLE_STATUS: 'Статус шаблона отклоняется или утверждается WhatsApp. Шаблоны WhatsApp должны быть сначала утверждены WhatsApp, и это платная услуга.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Удалить шаблон',
        },
        noDataFound: {
          templates: 'Шаблоны',
          noTemplatesCreatedYet: 'Шаблоны пока не созданы!',
        },
        modal: {
          deleteTemplate: 'Удалить шаблон',
          wantToDeleteThisTemplate: 'Вы уверены, что хотите удалить этот шаблон?',
        },
        createTemplate: {
          createTemplates: 'Создать шаблоны',
          createTemplatesForMarketingAutomation: 'Создайте свои шаблоны для автоматизации маркетинга',
          templateMeta: 'Шаблон мета',
          selectLanguage: 'Выберите язык',
          header: 'Заголовок',
          optional: 'Необязательный',
          samplesForHeaderContent: 'Образцы для содержания заголовка',
          toHelpMetaReviewYourContent: 'Чтобы помочь Meta просмотреть ваш контент, приведите примеры переменных или носителей в заголовке. Не включайте информацию о клиенте.',
          body: 'Тело',
          addVariable: 'Добавить переменную',
          samplesForBodyContent: 'Образцы для содержания тела',
          toHelpUsReviewYourContent: 'Чтобы помочь нам просмотреть ваш контент, приведите примеры переменных в организме. Не включайте информацию о клиенте.',
          footer: 'Нижний колонтитул',
          // optional: 'Необязательный',
          buttons: 'Кнопки',
          // optional: 'Необязательный',
          typeOfAction: 'Тип действия',
          call: 'Вызов',
          phone: 'Телефон',
          website: 'Веб-сайт',
          static: 'Статический',
          urlType: 'URL -тип',
          type: 'Тип',
          customButton: 'Пользовательская кнопка',
          addAnotherButton: 'Добавьте еще одну кнопку',
          preview: 'Предварительный просмотр',
          test: 'Тест',
          create: 'Создавать',
          addSampleUrl: 'Добавить пример URL',
          toHelpMetaReviewYourMessage: 'Чтобы помочь Meta просмотреть шаблон вашего сообщения, добавьте пример URL -адреса веб -сайта. Не используйте реальную информацию о клиентах.',
          placeholders: {
            enterMessageTemplateName: 'Введите имя шаблона сообщения',
            searchTemplateByName: 'Поиск шаблона по имени',
            selectTemplate: 'Выберите шаблон',
            enterText: 'Введите текст',
            enterBody: 'Введите тело',
            enterContentForVariable: 'Введите контент для переменной',
            enterFooter: 'Введите нижний колонтитул',
            phone: 'Телефон*',
            enterWebsiteUrl: 'Введите URL веб -сайта',
          },
          bpInput: {
            name: 'Имя',
            buttonText: 'Кнопка текст',
            websiteUrl: 'URL веб-сайта',
          },
          bpDropDown: {
            selectCategory: 'выберите категорию',
          }
        },
        templatePreview: {
          preview: 'Предварительный просмотр',
          test: 'Тест',
        },
        templateConfig: {
          discard: 'Отказаться',
          saveChanges: 'Сохранить изменения',
          triggerChatFlowOnTemplateReply: 'Запустить чат-поток при ответе на шаблон',
          templateDetails: 'Детали шаблона',
          configureFlows: 'Настроить потоки',
          selectFlow: 'Выберите поток',
          name: 'имя'
        }
      },
      shopManagement: {
        shopManagement: 'Управление магазином',
        manageECommerceShopFromBotPenguin: 'Управление магазинами электронной коммерции, связанных с Botpenguin',
        comingSoon: '( Вскоре )',
        wooCommerce: {
          createConfiguration: 'Создать конфигурацию',
          eventConfiguration: {
            createConfiguration: 'Создать конфигурацию',
            templateHeader: 'Заголовок шаблона',
            selectFile: 'Выберите файл',
            templateMessage: 'Шаблон сообщения',
            saveChanges: 'Сохранить изменения',
            bpDropDown: {
              selectEvent: 'Выберите событие',
              selectTemplate: 'Выберите шаблон',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'Профиль WhatsApp',
        manageYourBotProfile: 'Управляйте своим профилем бота, как и когда вы хотите.',
        allSetNoActionRequired: 'Все установлено, никаких действий не требуется!',
        congratulationsYourAccountIsLive: 'Поздравляю! Ваша учетная запись живет с полным доступом',
        somethingIsWrongReactivateYourBot: 'Что-то не так, повторно активируйте своего бота!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Кажется, что ваш бот не работает должным образом, пожалуйста, сбросьте свой бот',
        reConfigureYourBot: 'Повторно настроить своего бота',
        phoneNumberDetails: 'Детали номера телефона',
        phoneNumber: 'Номер телефона',
        nA: 'НА',
        phoneNumberId: 'Идентификатор номера телефона',
        wabaId: 'Waba Id',
        change: 'Изменять',
        accessToken: 'Токен доступа',
        // change: 'Изменять',
        messagingLimit: 'Предел обмена сообщениями',
        qualityRating: 'Качественный рейтинг',
        whatsappBusinessAccountName: 'Название учетной записи WhatsApp',
        whatsappPublicProfile: 'WhatsApp Public Profile',
        profilePicture: 'Изображение профиля',
        aboutText: 'О тексте',
        address: 'Адрес',
        description: 'Описание',
        email: 'Электронная почта',
        websitePrimary: 'Веб -сайт (первичный)',
        websiteSecondary: 'Веб -сайт (второстепенный)',
        businessVertical: 'Бизнес вертикаль',
        editProfile: 'Редактировать профиль',
        thisIsABusinessAccount: 'Это бизнес -аккаунт',
        aboutAndPhoneNumber: 'О номере телефона',
        yourTrialAccountIsLive: 'Поздравляю! Ваш пробный аккаунт живет с полным доступом по вашему подтвержденному номеру',
        updateWithNewAccessToken: 'Обновление с новым AccessToken',
      },
      widgets: {
        manageWidgets: 'Управлять виджетами',
        widgetsAreRoutingTools: 'Виджеты - это инструменты маршрутизации для увеличения использования бота',
        needHelp: 'Нужна помощь?',
        wantToMakeChanges: 'Хотите внести изменения?',
        prePopulatedMessage: 'Предварительно заполненное сообщение',
        discard: 'Отказаться',
        updateWidget: 'Обновить виджет',
        hiThere: 'всем привет',
        seeHowToUse: 'Посмотрите, как использовать',
        editIcon: 'Редактировать значок',
        editPrePopulatedMessage: 'Редактировать предварительно заполненное сообщение',
        small: 'Маленький',
        medium: 'Середина',
        large: 'Большой',
        leftMiddle: 'Левая средняя',
        rightMiddle: 'Правая средняя',
        leftBottom: 'Левый блок',
        rightBottom: 'Правый',
        chatBotIcon: 'Значок чат -бот',
        link: 'Связь',
        qrcode: 'QR код',
        copyChatBotCode: 'Скопируйте код чатбота',
        copyWALink: 'Скопируйте ссылку WA',
        downloadQrCode: 'Скачать QR -код',
        installChatBotIconOnYourWebsite: 'Установите значок Chatbot на свой сайт',
        shareWhereEverYouWantRedirectUsersTo: 'Поделиться, где вы хотите, и перенаправить пользователей' +
          ' на свой бот WhatsApp в одном щелчке',
        copyAndAddInYourWebsiteHeaderFile: 'Скопировать и добавить в файл заголовка вашего сайта',
        copyLinkAndStartSharing: 'Скопируйте ссылку и начните поделиться!',
        downloadQrCodeAndStartSharing: 'Скачать QR -код и начать обмен!',
        addCodeAndDeployNewCodeAndDone: 'Добавьте код, разверните новый код и сделанный!',
        wantToLearnHowToUseIt: 'Хотите узнать, как его использовать?',
        placeholders: {
          enterWhatsappNumber: 'Введите номер WhatsApp',
          updateYourMessageHere: 'Обновите свое сообщение здесь ...',
        },
        bpDropDown: {
          selectIcons: 'Выберите значки',
          iconSize: 'Размер значков',
          iconPlacement: 'Расположение значков',
        },
        bpInput: {
          whatsappNumber: 'Номер WhatsApp',
        }
      },
      catalog: {
        createCatalog: 'Создать каталог',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Создать каталог продуктов в Менеджере мета-коммерции или связать его с Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Используйте ваш Менеджер мета-коммерции для управления каталогами',
        connectCatalogToWhatsAppAccount: 'Подключить каталог к учетной записи WhatsApp',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Подключите ваши каталоги к учетной записи WhatsApp, используя ваш менеджер WhatsApp',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Используйте ваш Менеджер WhatsApp Meta, чтобы подключить каталоги и использовать их с BotPenguin',
        goToMeta: 'Перейти к Meta',
        goToShopify: 'Перейти к Shopify',
        next: 'Далее',
        back: 'Назад',
        setupCatalog: 'Настройка каталога',
        completeAllTheStepsToEnableCatalogMessages: 'Завершите все шаги, чтобы включить сообщения каталога'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Время завершения сеанса',
        sendInactivityMessage: 'Отправить сообщение об отсутствии активности',
        enterSessionTimeOutWindowInHours: 'Введите окно завершения сеанса в часах',
        sessionTimeOutInHoursTooltip: `Введите продолжительность, после которой сеанс чата автоматически завершится из-за бездействия. После завершения времени сеанса чат будет перезапущен, если пользователь отправит любое сообщение.`,
        restartChatFlowTriggers: 'Триггеры для перезапуска чата',
        enterKeyWordsToRestartChatAndhitEnter: 'Введите ключевые слова для перезапуска чата и нажмите Enter',
        restartChatFlowTriggersTooltip: `Укажите ключевые слова, которые, когда их вводит пользователь и отправляет, вызовут перезапуск чата. Это позволяет пользователям перезапускать беседу в любой момент.
         Нажимайте Enter после добавления каждого ключевого слова, чтобы добавить несколько ключевых слов.`,
        liveChatTriggers: 'Триггеры для онлайн-чата',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Введите ключевые слова для запуска онлайн-чата и нажмите Enter',
        liveChatTriggersTooltip: `Определите ключевые слова, которые, когда их вводит пользователь и отправляет, инициируют переход от автоматизированного чата к онлайн-чату. Это полезно, когда пользователи нуждаются в помощи живого агента.
         Нажимайте Enter после добавления каждого ключевого слова, чтобы добавить несколько ключевых слов.`,
        messageAfterAutomatedFlowCompleted: 'Сообщение после завершения автоматизированного потока',
        enterYourMessage: 'Введите ваше сообщение',
        messageAfterAutomatedFlowCompletedTooltip: `Введите сообщение, которое будет отображаться пользователю после завершения им автоматизированного потока чата, но сеанс не завершен.`,
        restart: 'Перезапуск',
        liveChat: 'Онлайн-чат',
        lineOneForDefaultMessage: 'Если у вас есть еще вопросы или вам нужна помощь, вот что вы можете сделать далее:',
        to: 'Для',
        restartTheChat: 'перезапустить чат',
        simpleTypeRestartAndHitEnter: `просто введите 'Перезапуск' и нажмите Enter. Это вернет вас в начало нашего разговора.`,
        lineThreeForDefaultMessagePartOne: `Если вы хотите поговорить с `,
        liveAgent: `живым агентом,`,
        lineThreeForDefaultMessageLastPart: `введите 'Онлайн-чат' и нажмите Enter.
        Наша команда здесь, чтобы помочь вам в реальном времени.`,
        saveChanges: 'Сохранить изменения',
        discard: 'Отменить',
        configureBotsGeneralSettings: 'Настройте общие параметры вашего бота',
        minutes: 'Минуты',
        hours: 'Часы',

      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'Всего в одном шаге',
      seeHowToUse: 'Посмотреть, как использовать',
      testWhatsAppTrialBot: 'Проверьте своего пробного чатбота WhatsApp',
      sendMessage: 'Отправить сообщение',
      sendWhatsAppMessageOn: 'Отправьте нам сообщение в WhatsApp на номер ',
      bySavingInYourContact: 'сохраните его в ваших контактах, и сообщение должно начинаться с ',
      connect: 'Подключение',
      or: 'ИЛИ',
      scanQRCode: 'Отсканируйте QR-код',
      yourPhoneWillOpen: 'Ваш телефон откроет предзаполненное сообщение для отправки на наш номер Sandbox. Нажмите «Отправить» в WhatsApp',
      sendVerificationCode: 'Отправить код подтверждения',
      copyAndEnterCode: 'Скопируйте и введите 6-значный код',
      shareWhereYouWant: 'Поделитесь где угодно и перенаправьте пользователей к вашему боту WhatsApp в один клик',
      youShouldHaveReceived: 'Вы должны были получить 6-значный код на вашем номере WhatsApp',
      EnterYourPhoneNumber: 'Введите ваш номер телефона',
      send: 'Отправить',
      sixDigitCode: 'Введите 6-значный код',
      pleaseNote: 'Обратите внимание: 6-значный код подтверждения может занять до 3 минут. Подождите, прежде чем повторно отправить его.',
      resendVerificationCode: 'Отправить код подтверждения повторно',
      verify: 'Подтвердить',
      providePhoneNumber: 'Пожалуйста, предоставьте номер, который вы хотите использовать для тестирования',
      demoBotisReady: 'Отлично! Ваш демонстрационный чатбот готов к использованию',
      placeHolder: {
        phone: 'Введите ваш номер телефона',
        code: 'Введите код подтверждения',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Сторонние приложения интеграция',
    thirdPartyApps: 'Сторонние приложения',
    customApps: 'Пользовательские приложения',
    preview: 'Предварительный просмотр',
    test: 'Тест',
    install: 'Установить',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Нажмите, чтобы установить свой бот на выбранную платформу',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Интегрируйте ваши пользовательские приложения с вашим ботом',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Подключите свой бот с доступными сторонними приложениями/CRMS',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Сторонние интеграции приложений',
        integrateYourBotWithListedCrms: 'Интегрируйте своего бота с одним из перечисленных приложений/CRM.',
        help: 'Помощь',
        name: 'Имя',
        status: 'Положение дел',
        action: 'Действие',
        chatGpt: 'Чатгпт',
        addOn: 'Добавить',
        purchased: 'Куплен',
        readyToRideGptWave: 'Готовы ездить на волне GPT? Интегрируйте сейчас.',
        purchase: 'Покупка',
        notConnected: 'Не подключен',
        integrated: 'Интегрированный',
        configure: 'Настройка',
        connected: 'Связанный',
        edit: 'Редактировать',
        connect: 'Соединять',
        requestYourIntegration: 'Запросить вашу интеграцию',
        toRequestNewIntegration: 'Чтобы запросить новую интеграцию, введите ниже указанные данные, а затем мы вернемся к вам',
        // name: 'Имя',
        emailId: 'Email ID',
        integrationName: 'Имя интеграции',
        contactNumber: 'Контактный номер',
        howSoonYouWantToAddThisIntegration: 'Как скоро вы хотите добавить эту интеграцию',
        sendRequest: 'Послать запрос',
        cancel: 'Отмена',
        requestSent: 'Запрос отправлен!',
        yourRequestSuccessfullySent: 'Ваш запрос на новую интеграцию была успешно отправлена.',
        goBack: 'Возвращаться',
        disconnect: 'Отключить',
        OopsIntegrationAreNotAvailable: 'Ой! Интеграции не доступны в ',
        pleaseUpgradeTo: ' плане. Пожалуйста, перейдите на ',
        planForIntegration: ' план для интеграций.',
        placeholders: {
          searchIntegrationByName: 'Интеграция поиска по имени',
          enterTeamMemberName: 'Введите имя члена команды',
          enterEmailId: 'Введите идентификатор электронной почты',
          enterIntegrationName: 'Введите имя интеграции',
          searchCountry: 'Страна поиска',
          enterContactNumber: 'Введите контактный номер',
          addComment: 'Добавить комментарий',
        },
        noDataFound: {
          integrations: 'Интеграции',
          noIntegrationsFound: 'Интеграции не найдено!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Имя приложения/CRM',
          statusAsConnectedOrNotConnected: 'Статус подключенного / не подключенного',
          clickToConnectToAppOrCrm: 'Нажмите, чтобы подключить приложение/CRM',
        },
        feedbackModal: {
          submit: 'Представлять на рассмотрение',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          agileCrmDomain: 'Agile CRM -домен',
          nameIsRequired: 'Имя требуется',
          emailAddress: 'Адрес электронной почты',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameter: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'Пример @company.extention',
            enterTokenHere: 'Введите токен здесь',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          accessKey: 'Ключ доступа',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiKeyHere: 'Введите ключ API здесь',
            enterAccessKeyHere: 'Введите ключ доступа здесь',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          nameColon: 'Имя:',
          inboundWebhookUrl: 'URL -URL входящего веб -крюка',
          nameIsRequired: 'Имя требуется',
          leadTitle: 'Ведущий заголовок',
          source: 'Источник',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterInboundWebhookUrl: 'Введите URL -адрес Webhook ...',
            enterTitle: 'Введите заголовок',
            enterSource: 'Введите источник',
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          testLead: 'Тестовый лидерство',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          testLead: 'Тестовый лидерство',
          connect: 'Соединять',
          selectTheQuestion: 'Выберите вопрос, чей ответ пользователя вы хотите отправить в качестве значения',
          mapWith: 'карта с',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
            attributes: 'Атрибуты (ключ)',
            values: 'ценности',
          }
        },
        chatGpt: {
          chatGptApiKey: 'CHATGPT (Open AI) API -ключ',
          chatGpt: 'Chatgpt (Open AI)',
          readyToRideWave: 'Готовы ездить на волне GPT? Интегрируйте сейчас.',
          doNotHaveAnyApiKeyNeedHelp: 'У вас нет ключа API, нужна помощь?',
          authenticate: 'Аутентификация',
          nowYouCanAccessChatGpt: 'Теперь вы можете получить доступ к компоненту чата GPT в Builder Chat Clow',
          paymentSuccessful: 'Оплата успешно!',
          paymentHasBeenCompletedSuccessfully: 'Оплата была завершена успешно. Вы все готовы тренировать своего бота',
          cancel: 'Отмена',
          trainNow: 'Тренируйся сейчас',
          paymentInProgress: 'Оплата в процессе ...',
          apiKey: 'API -ключ',
          model: 'Модель',
          integrateWithChatGPT: 'Интегрировать с ChatGPT',
          updateKey: 'Обновить ключ',
          connect: 'Соединять',
          update: 'Обновлять',
          placeholders: {
            chatGptApiKey: 'CHATGPT (Open AI) API -ключ',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          leadId: 'Идентификатор свинца',
          selectLeadId: 'Выберите Leadid',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          emailAddress: 'Адрес электронной почты',
          leadTitle: 'Ведущий заголовок',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            exampleEmail: 'Пример @company.extention',
            enterYourLeadTitle: 'Введите свой ведущий заголовок',
            enterTokenHere: 'Введите токен здесь',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          dripCrmAccountId: 'Идентификатор учетной записи CRM Drip',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterAccountIdHere: 'Введите идентификатор учетной записи здесь',
            enterYourTokenHere: 'Введите свой токен здесь',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apikey: 'API -ключ',
          accessToken: 'Токен доступа',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          selectParameter: 'Выберите параметр',
          disconnect: 'Отключить',
          update: 'Обновлять',
          save: 'Сохранять',
          placeholders: {
            enterApiKeyHere: 'Введите ключ API здесь',
            enterTokenHere: 'Введите токен здесь',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          testLead: 'Тестовый лидерство',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          authToken: 'Authtoken',
          source: 'Источник',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterAuthTokenHere: 'Введите Authtoken здесь',
            enterSourceHere: 'Введите источник здесь',
          }
        },
        facebookPixel: {
          facebookPixel: 'Пиксель Facebooka',
          getInsight: `Получите представления, превращающие ваш бизнес в предприятие с интеграцией Facebook Pixel от BotPenguin.`,
          pixelId: 'Идентификатор пикселя Facebooka',
          connect: 'подключить',
          disconnect: 'отключить',
          placeholders: {
            enterYourId: 'Идентификатор пикселя Facebook'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          companyName: 'Название компании',
          emailAddress: 'Адрес электронной почты',
          password: 'Пароль',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapsWith: 'Карты с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterCompanyName: 'Введите название компании',
            enterEmailAddress: 'Введите адрес электронной почты',
            enterPassword: 'Введите пароль',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiKeyHere: 'Введите Apikey здесь',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          flowluDomain: 'Flowlu Domain',
          apiToken: 'Токен API',
          title: 'Заголовок',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'Введите токен здесь',
            enterYourLeadTitle: 'Введите свой ведущий заголовок',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          freshdeskDomain: 'Freshdesk Domain',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'Введите токен здесь',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          freshworkCrmDomain: 'Свежий домен CRM',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'Введите токен здесь',
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          mapWith: 'карта с',
          select: 'Выбирать',
          update: 'Обновлять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          campaignList: 'Список кампании',
          selectCampaign: 'Выберите кампанию',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Гугл Аналитика',
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          measurementId: 'Идентификатор измерения',
          connect: 'Соединять',
          disconnect: 'Отключить',
          placeholders: {
            enterYourId: 'Введите свой удостоверение личности',
          }
        },
        googleCalendar: {
          testLead: 'Тестовый лидерство',
          name: 'Имя:',
          email: 'Электронная почта:',
          appointmentSummary: 'Резюме назначения',
          appointmentDescription: 'Описание назначения',
          addParameters: ' Добавьте параметры',
          questionColon: 'Вопрос:',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          pause: 'Пауза',
          resume: 'Резюме',
          disconnect: 'Отключить',
          reconnect: 'Восстановить связь',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          name: 'Имя :',
          email: 'Электронная почта :',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
        },
        googleRedirect: {
        },
        googleSheet: {
          testLead: 'Тестовый лидерство',
          title: 'Заголовок',
          questionColon: 'Вопрос:',
          addParameters: 'Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          update: 'Обновлять',
          save: 'Сохранять',
          pause: 'Пауза',
          resume: 'Резюме',
          disconnect: 'Отключить',
          reconnect: 'Восстановить связь',
          placeholders: {
            mappingValues: 'Картирование значений',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          name: 'Имя:',
          email: 'Электронная почта:',
          taskList: 'Список заданий',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          pause: 'Пауза',
          resume: 'Резюме',
          disconnect: 'Отключить',
          reconnect: 'Восстановить связь',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          select: 'Выбирать',
          addParameters: ' Добавьте параметры',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiHere: 'Введите API здесь',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          testLead: 'Тестовый лидерство',
          accountId: 'Идентификатор учетной записи',
          accessToken: 'Токен доступа',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterAccountIdHere: 'Введите учетную запись здесь',
            enterAccessTokenHere: 'Введите токен доступа здесь',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'API -ключ',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          testLead: 'Тестовый лидерство',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        helpLink: {
          help: 'Помощь',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          helpRaceCrmDomain: 'Helprace CRM домен',
          emailAddress: 'Адрес электронной почты',
          apiToken: 'Токен API',
          connect: 'Соединять',
          update: 'Обновлять',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'Пример @company.extension',
            token: 'Токен',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          domainName: 'Доменное имя',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiHere: 'Введите API здесь',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          emailAddress: 'Адрес электронной почты',
          apiUrl: 'API URL',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          // emailAddress: 'Адрес электронной почты',
          selectProjectName: 'Выберите имя проекта',
          selectIssueType: 'Выберите тип выпуска',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapsWith: 'Карты с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
            enterEmailAddress: 'Введите адрес электронной почты',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiUrl: 'API URL',
          nameIsRequired: 'Имя требуется',
          apiKey: 'API -ключ',
          apiId: 'API ID',
          // nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          mapWith: 'карта с',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          selectTheQuestion: 'Выберите вопрос, чей ответ пользователя вы хотите отправить в качестве значения',
          placeholders: {
            enterApiUrl: 'Введите URL -адрес API',
            enterApiKey: 'Введите ключ API',
            enterApiId: 'Введите ID API',
            attributes: 'Атрибуты (ключ)',
            values: 'ценности',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          accessKey: 'Ключ доступа',
          secretKey: 'Секретный ключ',
          connect: 'Соединять',
          apiLogs: 'Журналы API',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          udpate: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          filterApplied: 'Фильтр применяется',
          retry: 'Повторно',
          back: 'Назад',
          filterYourLeads: 'Отфильтруйте свои потенциальные клиенты',
          filterOn: 'Фильтр на',
          filterBy: 'Сортировать по',
          discard: 'Отказаться',
          applyFilter: 'Применить фильтр',
          loadMore: 'Загрузи больше',
          placeholders: {
            enterAccessKeyHere: 'Введите ключ доступа здесь',
            enterSecretKeyHere: 'Введите секретный ключ здесь',
            attributes: 'Атрибуты (ключ)',
            values: 'ценности',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Фильтрующие данные на основе ответа',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          apiUrl: 'API URL',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiHere: 'Введите API здесь',
            exampleUrl: 'https://comapanyname.ladesk.com',
          }
        },
        microsoftTeams: {
          downloadPackage: 'Скачать пакет приложений Microsoft Teams',
          downloadApp: 'Скачать приложение',
          wantToLearnHowToUploadIt: 'Хотите узнать, как его загрузить?',
          needHelp: 'Нужна помощь?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiUrl: 'API URL',
          appId: 'Приложение идентификатор',
          nameIsRequired: 'Требуется идентификатор приложения',
          dataApiKey: 'Данные API -ключ',
          dataApiKeyIsRequired: 'Требуется ключ API данных',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          addParameters: ' Добавьте параметры',
          mapWith: 'карта с',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          selectTheQuestion: 'Выберите вопрос, чей ответ пользователя вы хотите отправить в качестве значения',
          placeholders: {
            enterApiUrl: 'Введите URL -адрес API',
            enterAppId: 'Введите идентификатор приложения',
            enterDataApiKey: 'Введите ключ API данных',
            attributes: 'Атрибуты (ключ)',
            pressForMappingQuestion: 'Нажмите / для картирования вопроса',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          emailAddress: 'Адрес электронной почты',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          leadId: 'Идентификатор свинца',
          selectLeadId: 'Выберите Leadid',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterEmailHere: 'Введите электронное письмо здесь',
            enterApiKeyHere: 'Введите Apikey здесь',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          noCrmDomain: 'Домен NOCRM',
          leadTitle: 'Ведущий заголовок',
          titleIsRequired: 'Название требуется',
          token: 'Токен',
          tokenIsRequired: 'Токен требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            exampleDomain: 'https://your_subdomain_here.nocrm.io',
            enterYourLeadTitle: 'Введите свой ведущий заголовок',
            enterYourToken: 'Введите свой токен',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          emailAddress: 'Адрес электронной почты',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          secretToken: 'Секретный токен',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          appId: 'Приложение идентификатор',
          appIdIsRequired: 'Требуется идентификатор приложения',
          apiKey: 'API -ключ',
          apiKeyIsRequired: 'Требуется ключ API',
          connect: 'Соединять',
          update: 'Обновлять',
          disconnect: 'Отключить',
          placeholders: {
            appId: 'Приложение идентификатор',
            apiKey: 'API -ключ',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          clientId: 'ID клиента',
          connect: 'Соединять',
          clientSecret: 'Клиентский секрет',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          save: 'Сохранять',
          update: 'Обновлять',
          disconnect: 'Отключить',
          placeholders: {
            enterClientIdHere: 'Введите идентификатор клиента здесь',
            enterClientSecretHere: 'Введите секрет клиента здесь',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          pipedriverDomain: 'Pipedriver Domain',
          domainIsRequired: 'Домен требуется',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'Введите токен здесь',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          apiKeyIsRequired: 'Требуется ключ API',
          appKey: 'Ключ приложения',
          appKeyIsRequired: 'Ключ приложения требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiKey: 'Введите ключ API',
            enterAppKey: 'Введите ключ приложения',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          authId: 'Auth id',
          authToken: 'АВТОРНЫЙ ПЕРЕДАЧИ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiHere: 'Введите API здесь',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiKey: 'Введите ключ API',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          domain: 'Домен',
          clientId: 'ID клиента',
          clientSecret: 'Клиентский секрет',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterDomainHere: 'Введите домен здесь',
            enterClientIdHere: 'Введите идентификатор клиента здесь',
            enterClientSecretHere: 'Введите секрет клиента здесь',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          addEventsAndTemplates: 'Добавить события и шаблоны:',
          add: ' Добавлять',
          question: 'Вопрос',
          reply: 'Отвечать',
          requestBody: 'Запросить тело',
          validateJson: 'Подтвердить JSON',
          emailAddress: 'Адрес электронной почты',
          // addParameters: ' Добавьте параметры',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          selectTheQuestion: 'Выберите вопрос, чей ответ пользователя вы хотите отправить в качестве значения',
          placeholders: {
            enterApiHere: 'Введите API здесь',
            enterTemplateId: 'Введите идентификатор шаблона',
            typeYourJsonCodeHere: 'Введите здесь свой код JSON',
          },
          appTooltip: {
            typeYourJsonCode: 'Введите свой код JSON',
            clickToValidateJsonCode: 'Нажмите, чтобы проверить код JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          userName: 'Имя пользователя',
          licenseKey: 'Лицензионный ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterNameHere: 'Введите имя здесь',
            enterKeyHere: 'Введите ключ здесь',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить её.',
          company: 'Компания',
          loginId: 'Идентификатор входа',
          password: 'Пароль',
          testLead: 'Тестовый лидер',
          connect: 'Подключить',
          selectTheQuestion: 'Выберите вопрос, ответ пользователя на который вы хотите отправить как значение',
          mapWith: 'Сопоставить с',
          update: 'Обновить',
          save: 'Сохранить',
          disconnect: 'Отключить',
          selectParameter: 'Выберите параметр',
          placeholders: {
            enterCompanyHere: 'Введите название компании здесь',
            enterLoginIdHere: 'Введите идентификатор входа здесь',
            enterPasswordHere: 'Введите пароль здесь',
            values: 'значения',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          applicationKey: 'Ключ приложения',
          authToken: 'АВТОРНЫЙ ПЕРЕДАЧИ',
          connect: 'Соединять',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApplicationKeyHere: 'Введите ключ приложения здесь',
            enterTokenHere: 'Введите токен здесь',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          accessToken: 'Токен Acess',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          question: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterTokenHere: 'Введите токен здесь',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          webhoperDomain: 'Webhoper Domain',
          domainIsRequired: 'Домен требуется',
          companyName: 'Название компании',
          companyIsRequired: 'Компания требуется',
          leadSource: 'Ведущий источник',
          leadSourceIsRequired: 'Требуется источник свинца',
          division: 'Разделение',
          divisionIsRequired: 'Подразделение требуется',
          enterPassword: 'Введите пароль',
          passwordIsRequired: 'Необходим пароль',
          disconnect: 'Отключить',
          update: 'Обновлять',
          connect: 'Соединять',
          placeholders: {
            exampleDomain: 'http: // yourcompanydomain',
            enterYourCompanyName: 'Введите название вашей компании',
            enterLeadSource: 'Введите источник ведущего',
            enterDivision: 'Ввести дивизион',
            enterPassword: 'Введите пароль',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          name: 'Имя:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'URL -URL входящего веб -крюка',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterWebhookUrl: 'Введите URL -адрес webhook',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          emailAddress: 'Адрес электронной почты',
          apiUrl: 'API URL',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterEmailAddress: 'Введите адрес электронной почты',
            enterUrlHere: 'Введите URL здесь',
            enterTokenHere: 'Введите токен здесь',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiKey: 'API -ключ',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiHere: 'Введите API здесь',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          connect: 'Соединять',
          update: 'Обновлять',
          disconnect: 'Отключить',
          companyName: 'Название компании',
          emailAddress: 'Адрес электронной почты',
          phone: 'Телефон',
          countryCode: 'Код страны',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          // update: 'Обновлять',
          save: 'Сохранять',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          connect: 'Соединять',
          disconnect: 'Отключить',
          testLead: 'Тестовый лидерство',
          emailAddress: 'Адрес электронной почты',
          selectOrganizationName: 'Выберите название организации',
          selectDepartmentName: 'Выберите название отдела',
          addParameters: ' Добавьте параметры',
          questionColon: 'Вопрос:',
          select: 'Выбирать',
          mapsWith: 'Карты с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          testLead: 'Тестовый лидерство',
          connect: 'Соединять',
          disconnect: 'Отключить',
          projectsList: 'Список проектов',
          selectProjectsName: 'Выберите название проектов',
          projectsTaskList: 'Проекты Taskslist',
          selectTaskListName: 'Выберите имя списка задач',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
        },
        wooCommerce: {
          configure: 'Настроить',
          automatedMessage: 'Автоматическое сообщение',
          configureTab: {
            wooCommercePluginApiKey: 'Ключ API плагина WooCommerce',
            downloadWooCommercePlugin: 'Загрузить плагин WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Сообщение восстановления заброшенных корзин',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Настроить автоматические сообщения о восстановлении, чтобы вернуть клиентов, которые оставили свои корзины',
            enableAbandonedCartRecoveryMessaegs: 'Включить сообщения о восстановлении заброшенных корзин',
            selectTemplate: 'Выбрать шаблон',
            sendMessageAfter: 'Отправить сообщение после',
            templateHeader: 'Заголовок шаблона',
            templateMessage: 'Шаблон сообщения',
            saveChanges: 'Сохранить изменения',
            selectFile: 'Выберите файл',
            orderFulfillMent: 'Обработка заказа',
            orderCompleted: 'Заказ завершен',
            enableOrderConfirmationMessages: 'Включить сообщения о подтверждении заказа',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Отправить сообщение о подтверждении заказа сразу после размещения заказа клиентом.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Сообщение о доставке заказа отправляется, когда вы выполняете заказ.',
            enableOrderFulfillmentMessages: 'Включить сообщения о выполнении заказа',
            returnExchangeRequest: 'Запрос на возврат/обмен',
            theMessageIsSentAfterARefundIsRequested: 'Сообщение отправляется после запроса на возврат средств.',
            enableOrderRefundMessages: 'Включить сообщения о возврате заказа',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
          apiToken: 'Токен API',
          nameIsRequired: 'Имя требуется',
          connect: 'Соединять',
          testLead: 'Тестовый лидерство',
          questionColon: 'Вопрос:',
          addParameters: ' Добавьте параметры',
          select: 'Выбирать',
          mapWith: 'карта с',
          selectParameter: 'Выберите параметр',
          update: 'Обновлять',
          save: 'Сохранять',
          disconnect: 'Отключить',
          placeholders: {
            enterApiToken: 'Введите токен API',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Создайте новую интеграцию',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'У вас нет настройки интеграции на данный момент.',
      pleaseCreateOneToIntegrateYourFavourite: 'Пожалуйста, создайте один, чтобы интегрировать ваш любимый',
      appsWith: 'приложения с',
      integrationName: 'Имя интеграции',
      integrationNameIsRequired: 'Имя интеграции требуется',
      continue: 'Продолжать',
      addAnImageToRepresentYourIntegration: 'Добавьте изображение, чтобы представить вашу интеграцию.',
      description: 'Описание',
      submit: 'Представлять на рассмотрение',
      youHaveSuccessfullyCreatedIntegration: 'Вы успешно создали интеграцию. Теперь вы можете настроить его.',
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: 'Пожалуйста, введите действующий URL -адрес API',
      requestMethod: 'Метод запроса',
      get: 'Получать',
      post: 'Почта',
      put: 'Помещать',
      patch: 'Пластырь',
      queryParameter: 'Параметр запроса',
      addParameter: ' Добавить параметр',
      mapsWith: ' Карты с',
      question: 'Вопрос',
      addQuestion: ' Добавить вопрос',
      select: 'Выбирать',
      selectParameter: 'Выберите параметр',
      header: 'Заголовок',
      addHeader: ' Добавить заголовок',
      value: 'Ценить',
      connect: 'Соединять',
      requestBody: 'Запросить тело',
      validate: 'Проверять',
      // select: 'Выбирать',
      key: 'Ключ',
      // value: 'Ценить',
      // connect: 'Соединять',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' Также может быть интегрирован с этим бесплатным CRM.',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: 'Пожалуйста, введите действующий URL -адрес API',
        requestMethod: 'Метод запроса:',
        get: 'Получать',
        post: 'Почта',
        put: 'Помещать',
        patch: 'Пластырь',
        queryParameter: 'Параметр запроса',
        addParameter: ' Добавить параметр',
        header: 'Заголовок',
        addHeader: ' Добавить заголовок',
        requestBody: 'Запросить тело',
        validateJson: 'Подтвердить JSON',
        connect: 'Соединять',
        update: 'Обновлять',
        delete: 'Удалить',
        selectTheQuestion: 'Выберите вопрос, чей ответ пользователя вы хотите отправить в качестве значения',
        appTooltip: {
          typeYourJsonCode: 'Введите свой код JSON',
          clickToValidateJsonCode: 'Нажмите, чтобы проверить код JSON',
        }
      },
      list: {
        customAppsIntegrations: 'Интеграции пользовательских приложений',
        integrateYourAppsWith: 'Интегрировать свои собственные приложения с',
        help: 'Помощь',
        addIntegration: ' Добавить интеграцию',
        name: 'Имя',
        status: 'Положение дел',
        action: 'Действие',
        insatlled: 'Установлен',
        edit: 'Редактировать',
        connect: 'Соединять',
        requestYourIntegration: 'Запросить вашу интеграцию',
        toRequestNewIntegration: 'Чтобы запросить новую интеграцию, введите ниже указанные данные, а затем мы вернемся к вам',
        // name: 'Имя',
        emailId: 'Email ID',
        integrationName: 'Имя интеграции',
        contactNumber: 'Контактный номер',
        howSoonYouWantToAddThisIntegration: 'Как скоро вы хотите добавить эту интеграцию',
        sendRequest: 'Послать запрос',
        cancel: 'Отмена',
        delete: 'Удалить',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Упс! Ваши собственные интеграции поддерживаются только в',
        plan: 'План',
        pleaseUpgradeTo: 'Пожалуйста, перейдите на',
        toCreateOwnIntegrations: 'чтобы создать собственные интеграции',
        appTooltip: {
          integrations: 'Интеграции',
          noIntegrationsFound: 'Интеграции не найдено!',
          nameOfYourApp: 'Название вашего приложения',
          clickToConnectApp: 'Нажмите, чтобы подключить приложение',
          statusAsConnectedOrNotConnected: 'Статус подключенного / не подключенного',
        }
      }
    }
  }
};
