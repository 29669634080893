import { Action, createReducer, on } from '@ngrx/store';
import { addBot, loadBots, updateBot, deleteBot, deleteSocialBot, moveBotToTop } from '../actions/bot.actions';

export const botFeatureKey = 'bot';


export const initialState: any[] = [];

const botReducer = createReducer(
  initialState,
  on(addBot, (state, { bot }) => [bot, ...state]),
  on(loadBots, (state, { bots }) => [...state, ...bots]),
  on(deleteBot, (state, { bot }) => state.filter(item => item._id !== bot)),
  on(deleteSocialBot, (state, { bot }) => state.filter(item => (item?.bot?._id || item?._id) !== bot)),
  on(updateBot, (state, { bot, update }) => state.map(item => {
    if (item._id === bot || item?.bot?._id === bot) {
      return { ...item, ...update };
    } else {
      return item;
    }
  })),
  on(moveBotToTop, (state, { botId }) => {
    const selectedBot = state.find(b => b._id === botId);
    if (selectedBot) {
      return [selectedBot, ...state.filter(b => b._id !== botId)];
    }

    return state;
  })
);

export function reducer(state: any, action: Action) {
  return botReducer(state, action);
}
