export const notification = {
  notification: 'Notifications',
  allNotifications: 'All notifications',
  general: 'General',
  settings: 'Settings',
  newChatRequest: {
    youHaveANewChatRequest: 'You have new chat request!!!',
    youHaveTransferredChatRequest: 'You have transferred chat request!!!',
    acceptRequest: 'Accept Request',
    discardRequest: 'Discard Request'
  },
  notificationList: {
    name: 'Name',
    message: 'Message',
    noDataFound: {
      notification: 'Notification',
      noNotificationsFound: 'No Notifications found!',
    }
  },
  notificationSetting: {
    userMessage: 'User Message',
    receiveNotificationsWhenUserSendMessage: 'Receive notifications whenever any user sends any message',
    liveChatRequest: 'Live Chat Request',
    receiveNotificationsWhenUserRequestLiveChat: 'Receive notifications whenever any user request for live chat',
    newLead: 'New Lead',
    receiveNotificationsWhenNewLeadCreated: 'Receive notifications whenever new leads gets created',
    assignedOnly: 'Assigned Only',
    allUsers: 'All Users',
    customizeWhichNotificationsYouWantToRecieve: 'Customize which Notifications you want to receive',
    youCanCustomizeSettingForTheNotifications: 'You can customise setting for the notifications',
    receiveNotifications: 'Receive Notifications',
    desktopNotifications: 'Desktop Notifications',
    mobilePushNotifications: 'Mobile Push Notifications',
    discard: 'Discard',
    update: 'Update',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Send Notifications of New Message Over Desktop',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Send Notifications of New Message Over Mobile',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Send Notifications of New Lead Over Desktop',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Send Notifications of New Lead Over Mobile',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Send Notifications of Live Chat  Over Desktop',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Send Notifications of Live Chat Over Mobile',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Choose Which Messages Do You Wish To Be Notified For'
    }
  }
};
