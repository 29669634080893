export const subscription = {
  subscriptions: 'Suscripciones',
  plan: 'Plan',
  whatsappPlan: 'Plan de whatsapp',
  addOns: 'Complementos',
  billingDetails: 'Detalles de facturación',
  invoices: 'Facturas',
  wa1Reminder: 'WA 1st Recordation',
  wa2Reminder: 'WA 2do recordatorio',
  waRenewApi: 'WA Renew API',
  waCheckUsageApi: 'WA Check API de uso',
  waResetUsageApi: 'API de uso de restablecimiento de WA',
  waExceedUsageApi: 'Wa excede la API de uso',
  waGetBotsInThisAccount: 'Obtiene bots en esta cuenta',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Tenemos los precios más asequibles para todas sus necesidades.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'Plan de whatsapp',
    TOOLTIP_ADDS_ON_HEADING: 'Compre paquetes de masajes adicionales y bottelabel',
    TOOLTIP_ADDS_ON_MESSAGE: 'Si ha alcanzado su límite mensual de mensajes, puede comprar paquetes adicionales.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Consulte los detalles de sus suscripción actuales',
    TOOLTIP_HORIZONTAL_BAR: 'Consulte nuestros precios mensuales o anuales de 3K a 500k mensajes moviendo la barra de izquierda a derecha.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Actualice sus detalles de facturación para la facturación.',
    TOOLTIP_INVOICES_HEADING: 'Revise sus facturas anteriores.',
    TOOLTIP_INVOICES_ACTION: 'Haga clic para recibir la factura en su cuenta de correo electrónico',
  },
  currentPlan: {
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    downgradeRequest1: 'Ha realizado una solicitud para degradar su plan a',
    downgradeRequest2: 'mensajes. Tenga en cuenta que su plan será degradado al final del ciclo de facturación actual',
    downgradeRequest3: 'Puedes disfrutar el',
    downgradeRequest4: 'servicios hasta entonces.',
    downgradeRequestMaster1: 'Ha realizado una solicitud para degradar su',
    downgradeRequestMaster2: ' planear',
    downgradeRequestMaster3: ' conversaciones. Tenga en cuenta que su plan será degradado al final del ciclo de facturación actual',
    downgradeRequestMaster4: 'Puedes disfrutar el',
    downgradeRequestMaster5: 'servicios hasta entonces.',
    active: 'Activo',
    inactive: 'Inactivo',
    month: 'mes',
    year: 'año',
    messagePack: 'Paquete de mensajes',
    king: 'Rey',
    baby: 'Bebé',
    whatsapp: 'Whatsapp',
    youAreOn: 'Usted está en',
    plan: 'plan',
    totalMessagesLimit: 'Límite de mensajes totales',
    liveAgentLimit: 'Límite de agente en vivo',
    perMonth: '/mes',
    agents: 'agente (s)',
    messagesAddOn: 'Complemento de mensajes',
    botWhitelabels: 'Bot Whitelabels',
    totalAddOnMessages: 'Mensajes adicionales totales',
    basePrice: 'Precio base',
    gst: 'GST',
    totalPrice: 'Precio total',
    totalAddOnPrice: 'Precio de complemento total',
    whitelabelPrice: 'Precio Whitelabel',
    startedOn: 'Comenzó en',
    nextDueOn: 'El siguiente debido a',
    addOnExpiry: 'Vestimenta complementaria',
    website: 'Sitio web',
    facebook: 'Facebook',
    telegram: 'Telegram',
  },
  invoice: {
    invoiceNumber: 'Factura no.',
    date: 'Fecha',
    invoiceAmount: 'Monto de la factura',
    planAmount: 'Monto del plan',
    plan: 'Plan',
    messages: 'Mensajes',
    action: 'Acción',
    nA: 'N / A',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Haga clic para recibir la factura en su cuenta de correo electrónico',
      clickToDownloadInvoice: 'Haga clic para descargar factura en formulario PDF',
    }
  },
  messageAddOn: {
    validity: 'Validez',
    kingPlanHeader: '👑 Plan King',
    youAreOnThe: 'Estás en el',
    asOfNowAndWillGet: 'a partir de ahora, y obtendrá',
    messages: 'mensajes',
    inThisPlan: 'en este plan.',
    youCanBuyAdditionalMessaes: 'Puede comprar mensajes adicionales según sus requisitos comerciales.',
    plan: ' Plan',
    baby: 'Bebé',
    king: 'Rey',
    get: 'Conseguir',
    babyPlan: 'Plan de bebé',
    upgradePlan: 'Plan de actualización',
    asOfNow: ' a partir de ahora.',
    pleaseUpgradeToKingPlan: 'Actualice al ',
    toEnableAddOnsAndWhitelabelPacks: ' Para habilitar complementos y paquetes de Whitelabel.',
    messageAddOnPacks: 'Paquetes adicionales de mensajes',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Si ha alcanzado su límite mensual de mensajes, compre paquetes adicionales y disfrute de servicios ininterrumpidos.',
    pleaseSelectNumberOfExpectedMessages: 'Seleccione el número de mensajes esperados',
    addOnsValidity: 'Validez de complementos',
    currentBillingCycle: 'Ciclo de facturación actual',
    oneYear: 'Un año',
    summary: 'Resumen',
    messageCreditsWithKingPlan: 'Créditos de mensajes con el plan King',
    messageCreditsWith: 'Créditos de mensajes con el ',
    withPlan: '',
    billingCycle: 'Ciclo de facturación',
    payableAmount: 'Cantidad a pagar',
    oneTimePayment: 'Pago único',
    plusTaxes: ' + Impuestos',
    buyNow: 'Comprar ahora',
    whiteLabelChatWindow: 'Ventana de chat de etiqueta blanca',
    noBotsLeftToWhiteLabel: 'No quedan bots a Whitelabel.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Seleccione los bots que desea blanquear.',
    selectBots: 'Seleccionar bots',
    bot: 'Bot',
    bots: 'Bots',
    toBeWhiteLabelled: ' ser blanqueado',
    remove: 'Eliminar',
    brandNameFromChatWindow: ' Brandname desde la ventana de chat.',
    willBeValidTill: 'Será válido hasta',
    ourYearlyPrice: 'Nuestro precio anual',
    directWhiteLabel: 'Whitelabel directo',
    whiteLabelledBots: 'Bots blancos',
    nextRenewalColon: 'Siguiente renovación:',
    optOutRequested: 'Opción de exclusión solicitada',
    contactAgency: 'Agencia de contacto',
    optOut: 'Optar por no',
    removeWhiteLabel: 'Retire el blanqueador',
    messageCount: 'Mensajes',
    numberOfPacks: 'Número de paquetes',
    amount: 'Importe',
    taxes: 'Impuestos',
    totalAmount: 'Importe total',
    numOfBots: 'N.º de Bots',
    select: 'Seleccionar',
    selectTheValidity: 'Seleccione la validez',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Detalles del pago',
    cardNumber: 'Número de tarjeta',
    cvv: 'CVV',
    // cardNumber: 'Número de tarjeta',
    month: 'Mes',
    year: 'Año',
    save: 'Ahorrar',
    add: 'Agregar',
    edit: 'Editar',
    disclaimer: 'Descargo de responsabilidad:',
    disclaimerDescription1: 'Los detalles del método de pago anterior solo serán aplicables a usuarios fuera de la India.',
    disclaimerDescription2: 'No estamos guardando los detalles de su tarjeta con nosotros. En caso de que desee cambiar los detalles de su tarjeta, se cambiará directamente con la pasarela de pago.',
    billingAddress: 'Dirección de Envio',
    companyName: 'nombre de empresa',
    country: 'País',
    selectCountry: 'Seleccionar país',
    stateOrRegion: 'Estado/región',
    streetAddress: 'Dirección',
    gstIn: 'Gstin',
    postalCode: 'Código Postal',
    cancel: 'Cancelar',
    proceedToPay: 'Proceda a pagar',
    company: 'Compañía',
    state: 'Estado',
    masterCard: 'Tarjeta maestra',
    yourCompanyName: 'El nombre de tu compañía',
    yourCountry: 'Tu país',
    yourRegion: 'Tu región',
    yourPostalCode: 'Tu código postal',
    plusTaxes: ' + impuestos',
    dropDown: {
      month: 'Mes *',
      year: 'Año *',
    },
    placeholders: {
      enterCardNumber: 'Ingrese el número de tarjeta',
      cvv: 'CVV',
      enterYourCompanyName: 'Ingrese el nombre de su empresa',
      enterYourStateOrRegion: 'Ingrese su estado/región',
      enterYourStreetAddress: 'Ingrese su dirección de calle',
      enterYourGSTIN: 'Ingrese su gstin',
      enterPostalCode: 'Ingrese el código postal',
    }
  },
  plans: {
    viewDetailedPricing: 'Ver precios detallados',
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    weHaveGotAPricingPlanPerfectForYou: 'Tenemos un plan de precios que es perfecto para ti',
    weBelieve: 'Creemos',
    shouldBeAccessibleToAllCompanies: ' Debe ser accesible para todas las empresas, no importa el tamaño.',
    monthly: 'Mensual',
    yearly: 'Anual',
    twoMonthsFree: '(2 meses gratis)',
    babyPlan: 'Plan de bebé',
    freeForever: 'Siempre libre',
    messagesPerMonth: 'Mensajes por mes',
    currentPlan: 'Plan actual',
    freePlan: 'Plan gratuito',
    kingPlan: 'Plan King',
    perYear: 'por año',
    plan: 'plan',
    toThe: ' hacia',
    plusTaxes: ' + Impuestos',
    perMonth: 'por mes',
    upgradePlan: 'Plan de actualización',
    changePlan: 'Cambio de plan',
    currentlyActive: 'Actualmente activo',
    emperorPlan: 'Plan Emperador (Enterprise)',
    sendAsManyMessagesAsYouWant: 'Envía tantos mensajes como necesite',
    contactUs: 'Contáctenos',
    features: 'Características',
    areYouSureYouWantToShiftFrom: '¿Estás seguro de que quieres cambiar del',
    messages: 'Mensajes',
    planQuestionMark: '¿plan?',
    downgradeToFreePlan: 'Degradar al plan gratuito',
    goToBabyPlan: 'Ir al plan para bebés',
    thankYouForChoosingBotPenguin: 'Gracias por elegir botpenguin',
    bots: 'Bots',
    facebookPages: 'Páginas de Facebook',
    teamMember: 'Miembro del equipo',
    dataStorage: 'Almacenamiento de datos',
    conditionalFlow: 'Flujo condicional',
    liveChat: 'Chat en vivo',
    bookAppointments: 'Citas de libros',
    csvExport: 'Exportación de CSV',
    emailAndCallSupport: 'Soporte de correo electrónico y llamadas',
    googleCalendarIntegrations: 'Integración del calendario de Google',
    zohoCrmIntegrations: 'Integración de Zoho CRM',
    reportsAndAnalytics: 'Informes y análisis',
    removeBotPenguinBranding: 'Eliminar la marca Botpenguin',
    oneBot: '1 bot',
    twoKMessagesPerMonth: '2k mensajes/mes',
    oneFacebookPage: '1 página de Facebook',
    oneTeamMember: '1 miembro del equipo',
    thirtyDaysOfDataStorage: '30 días de almacenamiento de datos',
    unlimitedBot: 'Bot ilimitado',
    threeTo500kMessagesPerMonth: '3k a 500k mensajes/mes',
    tenFacebookPages: '10 páginas de Facebook',
    threeTeamMembers: '3 miembros del equipo',
    nintyDaysOfDataStorage: '90 días de almacenamiento de datos',
    unlimitedMessages: 'Mensajes ilimitados',
    unlimitedFacebookPages: 'Páginas ilimitadas de Facebook',
    unlimitedTeamMembers: 'Miembros del equipo ilimitados',
    unlimitedDataStorage: 'Almacenamiento de datos ilimitado',
    youAreHere: '¡Usted está aquí!',
    detailedPricing: {
      kingsplan: 'Plan Rey',
      elementary: 'Elemental',
      solutionType: 'Tipo de Solución',
      saas: 'SaaS',
      fbpage1fb: '1 Página de Facebook',
      perMonth100: '100 al mes',
      enterprise: 'Empresa',
      saasOnPremises: 'SaaS en las instalaciones',
      aiChatbotFeatures: 'Funciones de Chatbot de IA',
      dataSourceForTraning: 'Fuente de datos para entrenamiento',
      smartFeatures: 'Funciones inteligentes',
      liveChat: 'Chat en vivo',
      day30: '30 días',
      day90: '90 días',
      landSquared: 'Landsquared',
      doItYourself: 'Hazlo tú mismo',
      unifiedInbox: 'Bandeja de entrada unificada',
      platforms: 'Plataformas',
      websiteChatbot: 'Chatbot de sitio web',
      FacebookMessengerChatbot: 'Chatbot de Facebook Messenger',
      Telegram: 'Telegram',
      conversationalLandingPage: 'Página de aterrizaje conversacional',
      integrations: 'Integraciones',
      reportsAndAnalytics: 'Informes y Analíticas',
      customerSupport: 'Soporte al cliente',
      security: 'Seguridad',
      bots: 'Bots',
      babyPlan: 'Plan Básico',
      kingPlan: 'Plan Rey',
      emperorPlan: 'Plan Emperador',
      noofBots: 'N.º de bots',
      one: '1 (Uno)',
      unlimitedBots: 'Bots ilimitados',
      noOfWebsite: 'N.º de sitios web',
      unlimitedWebsites: 'Sitios web ilimitados',
      unlimitedMessages: 'Mensajes ilimitados',
      messagesSend: 'Mensajes enviados',
      perMonth2000: '2,000 al mes',
      upto500000perMonth: 'Hasta 500,000 al mes',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Páginas ilimitadas',
      telegramNumbers: 'Números de Telegram',
      unlimited: 'Ilimitado',
      whatsappNumbers: 'Números de WhatsApp',
      subscribersLeads: 'Suscriptores/Leads',
      createSupportTeam: 'Crear equipo de soporte para chat en vivo',
      oneMember: '1 Miembro',
      threeMembers: '3 miembros',
      conversationHistory: 'Historial de conversaciones',
      thirtyDays: '30 Días',
      ninetyDays: '90 Días',
      chatbotDesigning: 'Diseño de chatbot',
      weAreHereToHelp: 'Estamos aquí para ayudarte',
      weTakeCareEndToEnd: 'Nos encargamos de todo de principio a fin',
      multipleLanguages: 'Múltiples idiomas',
      conditionalFlow: 'Flujo condicional',
      removeBotPenguinBranding: 'Eliminar la marca BotPenguin',
      gptMessages: 'Mensajes GPT',
      aiChatbot: 'Chatbot de IA',
      chatGPTIntegration: 'Integración de ChatGPT',
      configureAIChatbotPersonality: 'Configurar la personalidad del chatbot de IA',
      trainChatbotOnYourOwnData: 'Entrenar el chatbot con tus propios datos',
      trainChatbotOnWebPages: 'Entrenar el chatbot en páginas web',
      trainChatbotOnMultipleFiles: 'Entrenar el chatbot en múltiples archivos',
      trainChatbotOnFAQs: 'Entrenar el chatbot en preguntas frecuentes (FAQs)',
      dataTrainingStorage: 'Almacenamiento de entrenamiento de datos',
      useChatGPTModel4_3_5Turbo: 'Usar el modelo ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Subir múltiples archivos para el entrenamiento del chatbot',
      naturalLanguageProcessing: 'Procesamiento de lenguaje natural',
      naturalLanguageUnderstanding: 'Comprensión de lenguaje natural',
      answerLength: 'Longitud de la respuesta',
      chatTone: 'Tono del chat',
      multiLanguage: 'Multi-idioma',
      answerFormatting: 'Formato de respuesta',
      customPrompt: 'Mensaje personalizado',
      integrateBotPenguinLLM: 'Integrar BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrar LLM propio/personalizado',
      periodicallySyncDataSources: 'Sincronizar periódicamente fuentes de datos',
      aiBasedSuggestedQuestions: 'Preguntas sugeridas basadas en IA',
      chooseVisibilityForParticularWebpageSitemap: 'Elegir visibilidad para página/sitemap específicos',
      chooseKnowledgeMix: 'Elegir mezcla de conocimientos:\nTu base de conocimiento\nConocimiento de ChatGPT\n"Conocimiento de ChatGPT + Tu base de conocimiento"',
      faqExtractionFromFileAndURL: 'Extracción de FAQs de archivo y URL',
      analyticsToImproveAI: 'Analíticas para mejorar la IA',
      exportQuestionFlow: 'Exportar flujo de preguntas',
      sessionManagement: 'Gestión de sesiones',
      collectFeedbackOnChatbotResponses: 'Recopilar comentarios sobre las respuestas del chatbot',
      smartAIFollowUpQuestions: 'Preguntas de seguimiento inteligentes/AI',
      trainOnYourNotionWorkspaceData: 'Entrenar con tus datos en el espacio de trabajo de Notion',
      trainOnYoutubeVideo: 'Entrenar con videos de YouTube',
      trainOnCustomVideoUpload: 'Entrenar con la carga de videos personalizados',
      rssFeed: 'Fuente RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'Bloqueo de IP y región',
      emailAndMobileValidation: 'Validación de correo electrónico y móvil',
      appointmentsBooking: 'Reservas de citas',
      humanHandover: 'Transferencia a atención humana',
      botHandover: 'Transferencia a bot',
      handoverBetweenAgents: 'Transferencia entre agentes',
      mobileAppsForAgents: 'Aplicaciones móviles para agentes',
      roleBasedAccessControls: 'Controles de acceso basados en roles',
      apiIntegrationOnChatFlows: 'Integración de API en flujos de chat',
      liveChatWidget: 'Widget de chat en vivo',
      typingIndicator: 'Indicador de escritura',
      liveVisitorsDetails: 'Detalles de visitantes en vivo',
      multipleSimultaneousChat: 'Chat simultáneo múltiple',
      liveTypingView: 'Vista de escritura en vivo',
      ruleBasedChatRouting: 'Enrutamiento de chat basado en reglas',
      chatAutoAssignments: 'Asignaciones automáticas de chat',
      efficientOmnichannelInterface: 'Interfaz omnicanal eficiente',
      quickReplies: 'Respuestas rápidas',
      unifiedCustomerProfile: 'Perfil de cliente unificado',
      visitorStats: 'Estadísticas de visitantes',
      addContactsFromWhatsapp: 'Agregar contactos desde WhatsApp',
      applyFilterAndSortConversations: 'Aplicar filtros y ordenar conversaciones',
      seeChatLogs: 'Ver registros de chat',
      assignNotesAndChatTags: 'Asignar notas y etiquetas de chat',
      directCallsToLeadsWithTwilio: 'Llamadas directas a leads con Twilio',
      customerSegmentation: 'Segmentación de clientes',
      exportLeadsInCSV: 'Exportar leads en formato CSV',
      leadNotificationsByEmail: 'Notificaciones de leads por correo electrónico',
      website: 'Sitio web',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegram',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Activación automática en dispositivos móviles/escritorio',
      hideBotOnSpecificPages: 'Ocultar el bot en páginas específicas',
      imageSharing: 'Compartir imágenes',
      readyToUseChatFlowTemplates: 'Plantillas de flujo de chat listas para usar',
      fileSharing: 'Compartir archivos',
      customDesignForChatWindow: 'Diseño personalizado para la ventana de chat',
      facebookMessengerBot: 'Bot de Facebook Messenger',
      facebookSubheading: 'Subtítulo de Facebook',
      telegramSubheading: 'Subtítulo de Telegram',
      conversationalLangdingPageSubheading: 'Subtítulo de la página de aterrizaje conversacional',
      integrationsSubheading: 'Subtítulo de integraciones',
      multipleSmartFlows: 'Flujos inteligentes múltiples',
      dripCampaigns: 'Campañas por goteo',
      broadcastFBMessages: 'Mensajes de transmisión de Facebook',
      multipleFacebookBotWidgets: 'Múltiples widgets de bot de Facebook',
      facebookAutoReplyOnComments: 'Respuesta automática de Facebook a comentarios',
      facebookECommerce: 'E-commerce de Facebook',
      broadcastAnalytics: 'Análisis de transmisión',
      telegramMessengerBot: 'Bot de Telegram Messenger',
      readyToUseTemplates: 'Plantillas listas para usar',
      groupBroadcastTelegramMessages: 'Mensajes de grupo y transmisión de Telegram',
      multipleTelegramBotWidgets: 'Múltiples Widgets de bot de Telegram',
      conversationalLandingPageBot: 'Bot de página de aterrizaje conversacional',
      smartReplies: 'Respuestas inteligentes',
      interactiveAndCustomizableUI: 'Interfaz interactiva y personalizable',
      leadManagement: 'Gestión de leads',
      easyToInstallAndShare: 'Fácil de instalar y compartir',
      agileCRM: 'Agile CRM',
      capsule: 'Cápsula',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Cerrar',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistema de emisión de boletos de soporte',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Llamada de audio y video en el chat (Twilio/Agora)',
      courierTracking: 'Seguimiento de mensajería',
      emailIntegration: 'Integración de correo electrónico',
      webhooks: 'Webhooks',
      schedulers: 'Programadores',
      paymentGateways: 'Pasarelas de pago (Stripe y RazorPay)',
      enterpriseIntegrations: 'Integraciones empresariales',
      customIntegration: 'Integración personalizada',
      shopifyIntegration: 'Integración con Shopify',
      totalNumberOfMessages: 'Número total de mensajes',
      totalNumberOfLeads: 'Número total de leads',
      weeklyReportBotwise: 'Informe semanal, por bot',
      averageAgentResponeTime: 'Tiempo promedio de respuesta del agente',
      visitorTimingReport: 'Informe de horarios de visita',
      customerSegmentBasedReports: 'Informes basados en segmentos de clientes',
      tagBasedReports: 'Informes basados en etiquetas',
      leadGenerationReport: 'Informe de generación de leads',
      supportOnWhatsApp: 'Soporte en WhatsApp',
      knowledgeBase: 'Base de conocimientos',
      onboardingAndTraining: 'Integración y capacitación',
      callAndEmailSupport: 'Soporte por llamada y correo electrónico',
      freeChatbotCreation: 'Creación gratuita de chatbots',
      customerSuccessOfficer: 'Oficial de éxito del cliente',
      secureConnection2048BitSSL: 'Conexión segura de 2048 bits SSL',
      securityAssessment: 'Evaluación de seguridad',
      dataCenterInUSOrEU: 'Centro de datos en EE. UU. o UE',
      twoStepVerification: 'Verificación en dos pasos',
      accessRestrictionsBasedOnIPs: 'Restricciones de acceso basadas en IPs',
      onDemandAuditLog: 'Registro de auditoría bajo demanda',
      bannedVisitorsList: 'Lista de visitantes prohibidos',
      customAPIRate: 'Tarifa personalizada de API',
      trustedDomains: 'Dominios de confianza',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Curioso de la codificación',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Nuestro plan gratuito',
      TOOLTIP_KING_PLAN: 'Nuestro plan más popular',
      TOOLTIP_EMPEROR_PLAN: 'Plan personalizado para mensajes ilimitados, datos ilimitados, miembros del equipo ilimitados',
      TOOLTIP_CONTACT_US: 'Comuníquese con nosotros a través de whatsapp o envíe un correo electrónico a contact@botpenguin.com para obtener más información sobre nuestro plan personalizado',
      TOOLTIP_UPGRADE: 'Actualice su plan actual para funciones adicionales',
      TOOLTIP_MONTHLY: 'Seleccione un ciclo de pago mensual',
      TOOLTIP_YEARLY: 'Seleccione un ciclo de pago anual para beneficios de descuento',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Ir al plan para bebés',
    downgradeToFreePlan: 'Degradar al plan gratuito',
    freeForever: 'Siempre libre',
    features: 'Características',
    currentPlan: 'Plan actual',
    changePlan: 'Cambio de plan',
    currentlyActive: 'Actualmente activo',
    seeHowToUse: 'Vea cómo usar',
    weHaveGotPricingPerfectForYou: 'Tenemos un plan de precios que es perfecto para ti',
    weBelieve: 'Creemos',
    shouldBeAccessibleToAllCompanies: ' Debe ser accesible para todas las empresas, no importa el tamaño.',
    monthly: 'Mensual',
    yearly: 'Anual',
    twoMonthsFree: '(2 meses gratis)',
    kingPlan: 'Plan King',
    perYear: 'por año',
    perMonth: 'por mes',
    conversationsPerMonth: 'Conversaciones por mes',
    upgradePlan: 'Plan de actualización',
    emperorPlan: 'Plan Emperador (Enterprise)',
    haveAsManyConversationsAsYouNeed: 'Tener tantas conversaciones como necesite',
    contactUs: 'Contáctenos',
    metaConversationChargesAreAdditional: 'Los cargos de meta conversación son adicionales y pagan directamente por usted a Meta, según su uso. No cobramos ninguna tarifa de marcado o facilitación.',
    viewDetailedPricing: 'Ver precios detallados',
    conversationPricingModelUsedByMeta: 'Modelo de precios de conversaciones utilizado por Meta',
    whatsappBusinessPlatformConversationsFallInto: 'Las conversaciones de la plataforma de negocios de WhatsApp se encuentran en dos categorías que tienen un precio diferente',
    userInitiatedConversation: 'Conversación iniciada por el usuario',
    userInitiatedConversationDescription: 'Una conversación que se inicia en respuesta a un mensaje de usuario. Cada vez que un negocio responde a un usuario dentro de la ventana de servicio al cliente de 24 horas, ese mensaje se asociará con una conversación iniciada por el usuario. Las empresas pueden enviar mensajes de forma gratuita dentro de esta ventana de servicio al cliente de 24 horas.',
    businessInitiatedConversation: 'Conversación iniciada por el negocio',
    businessInitiatedConversationDescription: 'Una conversación que se inicia de una empresa que envía a un usuario un mensaje fuera de la ventana de servicio al cliente de 24 horas. Los mensajes que inicien una conversación iniciada por el negocio requerirán una plantilla de mensaje.',
    areYouSureYouWantTo: '¿Estás seguro de que quieres cambiar del',
    conversations: ' Conversaciones',
    plan: ' plan',
    toThe: ' hacia',
    metaPricingMarkup: 'Marcado de metaeting',
    numberOfChatFlows: 'Número de flujos de chat',
    greenTickApplication: 'Aplicación de garrapata verde',
    extraPhoneNumberLinking: 'Enlace de número de teléfono adicional',
    teamMembers: 'Miembros del equipo',
    dataStorage: 'Almacenamiento de datos',
    conditionalFlow: 'Flujo condicional',
    liveChat: 'Chat en vivo',
    smartInbox: 'Bandeja de entrada inteligente',
    whatsappTemplates: 'Plantillas de whatsapp',
    customerSegments: 'Grupos de clientes',
    broadcastAndDripCampaigns: 'Campañas de transmisión y goteo',
    multipleLanguages: 'Múltiples idiomas',
    bookAppointments: 'Citas de libros',
    csvContactsExport: 'Exportación de contactos de CSV',
    googleCalendarIntegrations: 'Integración del calendario de Google',
    nativeApplications: 'Integración nativa de más de 60',
    whatsappSupport: 'Soporte de WhatsApp',
    emailAndCallSupport: 'Soporte de correo electrónico y llamadas',
    customerIntegrations: 'Integraciones de clientes',
    nlpAndNluCapabilities: 'Capacidades NLP y NLU',
    chatGPTBot: 'Chatgpt chatbot',
    customerSuccessOfficer: 'Oficial de éxito del cliente',
    noMarkupTransparency: 'Sin transparencia de marcado',
    upto500kPerMonth: 'Hasta 500,000 por mes',
    beyond500k: 'Más allá de 500,000',
    unlimited: 'Ilimitado',
    oneTeamMember: '1 miembro del equipo',
    unlimitedTeamMember: 'Miembros del equipo ilimitados',
    nintyDaysOfDataStorage: '90 días de almacenamiento de datos',
    unlimitedDataStorage: 'Almacenamiento de datos ilimitado',
    detailedPricing: {
      onDemandAuditLog: 'Registro de auditoría bajo demanda',
      accessRestrictionsBasedOnIPs: 'Restricciones de acceso basadas en IP',
      averageAgentResponeTime: 'Tiempo promedio de respuesta del agente',
      perMonth100: '100 al mes',
      solutionType: 'Tipo de solución',
      saas: 'SaaS',
      saasOnPremises: 'SaaS en las instalaciones',
      emperorPlan: 'Plan Emperador',
      babyPlan: 'Plan Básico',
      kingsplan: 'Plan Reyes',
      braodcastsAndDrips: 'Transmisiones y Goteos',
      thirtyDays: '30 Días',
      ninetyDays: '90 Días',
      oneMember: '1 Miembro',
      threeMembers: '3 miembros',
      one: '1 (Uno)',
      unlimitedMessages: 'Mensajes ilimitados',
      unlimitedBots: 'Bots ilimitados',
      messagesSend: 'Mensajes enviados',
      upto500000perMonth: 'Hasta 500,000 por mes',
      unlimited: 'Ilimitado',
      onlyAdmin: 'Solo Admin',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Hazlo tú mismo',
      weAreHereToHelp: 'Estamos aquí para ayudarte',
      weTakeCareEndToEnd: 'Cuidamos de principio a fin',
      numberOfBots: 'Número de bots',
      conversations: 'Conversaciones',
      gptMessages: 'Mensajes GPT',
      whatsappNumbers: 'Números de WhatsApp',
      subscribersLeads: 'Suscriptores/Leads',
      createSupportTeamForLiveChat: 'Crear equipo de soporte para chat en vivo',
      conversationHistory: 'Historial de conversación',
      chatbotDesigning: 'Diseño de chatbot',
      multipleLanguages: 'Múltiples Idiomas',
      conditionalFlow: 'Flujo condicional',
      whatsappTemplates: 'Plantillas de WhatsApp',
      restartChatFlowTriggers: 'Desencadenadores para reiniciar el flujo de chat',
      multipleWhatsAppWidgets: 'Múltiples widgets de WhatsApp',
      importWhatsappContacts: 'Importar contactos de WhatsApp',
      whatsappQuickReply: 'Respuesta rápida de WhatsApp',
      whatsappSmartInbox: 'Bandeja de entrada inteligente de WhatsApp',
      whatsappEcommerceCatalogs: 'Catálogos de comercio electrónico de WhatsApp',
      whatsappBroadcast: 'Transmisión de WhatsApp',
      whatsappBroadcastAnalytics: 'Análisis de transmisión de WhatsApp',
      dripCampaigns: 'Campañas de goteo',
      customerSegmentation: 'Segmentación de clientes',
      keywordBasedResponse: 'Respuesta basada en palabras clave',
      whatsappShopManagement: 'Gestión de tienda de WhatsApp',
      aiChatbot: 'Chatbot de IA',
      chatGPTIntegration: 'Integración de ChatGPT',
      configureAIChatbotPersonality: 'Configurar la personalidad del chatbot de IA',
      trainChatbotOnYourOwnData: 'Entrenar el chatbot con tus propios datos',
      trainChatbotOnWebPages: 'Entrenar el chatbot en páginas web',
      trainChatbotOnMultipleFiles: 'Entrenar el chatbot en múltiples archivos',
      trainChatbotOnFAQs: 'Entrenar el chatbot en Preguntas Frecuentes',
      dataTrainingStorage: 'Almacenamiento de entrenamiento de datos',
      useChatGPTModel4or35Turbo: 'Usar el modelo ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Subir múltiples archivos para el entrenamiento del chatbot',
      naturalLanguageProcessing: 'Procesamiento de lenguaje natural',
      naturalLanguageUnderstanding: 'Comprensión de lenguaje natural',
      answerLength: 'Longitud de la respuesta',
      chatTone: 'Tono del chat',
      multiLanguage: 'Multilingüe',
      answerFormatting: 'Formato de respuesta',
      customPrompt: 'Indicación personalizada',
      whatsAppSessionManagement: 'Gestión de sesiones de WhatsApp (mensajes de inactividad y reinicio de flujo)',
      integrateBotPenguinLLM: 'Integrar BotPenguin LLM',
      integrateOwnCustomLLM: 'Integrar LLM personalizado',
      periodicallySyncDataSources: 'Sincronizar periódicamente fuentes de datos',
      aiBasedSuggestedQuestions: 'Preguntas sugeridas basadas en IA',
      chooseVisibilityForParticularWebpageSitemap: 'Elegir visibilidad para una página web o mapa de sitio específico',
      chooseKnowledgeMix: 'Elegir Mezcla de Conocimientos: Base de Conocimientos ChatGPT "Base de Conocimientos ChatGPT + Tu Base de Conocimientos"',
      faqExtractionFromFileAndURL: 'Extracción de FAQ desde Archivo y URL',
      analyticsToImproveAI: 'Análisis para mejorar la IA',
      exportQuestionFlow: 'Exportar el flujo de preguntas',
      collectFeedbackOnChatbotResponses: 'Recopilar comentarios sobre las respuestas del chatbot',
      smartAIFollowUpQuestions: 'Preguntas de seguimiento inteligentes basadas en IA',
      trainOnYourNotionWorkspaceData: 'Entrenar con los datos de tu espacio de trabajo en Notion',
      trainOnYoutubeVideo: 'Entrenar con vídeo de YouTube',
      trainOnCustomVideoUpload: 'Entrenar con carga de vídeo personalizado',
      rssFeed: 'Fuente RSS',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'Entrega a humano',
      botHandover: 'Entrega a bot',
      handoverBetweenAgents: 'Entrega entre agentes',
      emailAndMobileValidation: 'Validación de correo electrónico y móvil',
      mobileAppsForAgents: 'Aplicaciones móviles para agentes',
      appointmentBooking: 'Reserva de citas',
      roleBasedAccessControls: 'Controles de acceso basados en roles',
      ruleBasedChatRouting: 'Enrutamiento de chat basado en reglas',
      liveVisitorsDetails: 'Detalles de visitantes en vivo',
      multipleSimultaneousChats: 'Múltiples chats simultáneos',
      visitorStats: 'Estadísticas de visitantes',
      addContactsFromWhatsApp: 'Agregar contactos desde WhatsApp',
      applyFilterAndSortConversations: 'Aplicar filtros y ordenar conversaciones',
      seeChatLogs: 'Ver registros de chat',
      quickReplies: 'Respuestas rápidas',
      unifiedCustomerProfile: 'Perfil de cliente unificado',
      assignNotesAndChatTags: 'Asignar notas y etiquetas de chat',
      exportLeadsInCSV: 'Exportar leads en formato CSV',
      tagContacts: 'Etiquetar contactos',
      customAttributes: 'Atributos personalizados',
      markStatusOfConversations: 'Marcar estado de conversaciones',
      assignConversationToAgents: 'Asignar conversación a agentes',
      leadNotificationsByEmail: 'Notificaciones de leads por correo electrónico',
      directCallsToLeadsWithTwilio: 'Llamadas directas a leads con Twilio',
      whatsAppTemplates: 'Plantillas de WhatsApp',
      promotionalMessageFrequencyControl: 'Control de frecuencia de mensajes promocionales',
      whatsAppWidget: 'Widget de WhatsApp',
      whatsAppQR: 'Código QR de WhatsApp',
      whatsAppLink: 'Enlace de WhatsApp',
      campaignScheduler: 'Programador de campañas',
      customTriggers: 'Desencadenadores personalizados',
      customCampaigns: 'Campañas personalizadas',
      repeatPurchaseReminders: 'Recordatorios de compra repetida',
      campaignOpenRateTracking: 'Seguimiento de la tasa de apertura de campañas',
      paymentCollectionOnWhatsApp: 'Cobro de pagos en WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Cápsula',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Cerrar',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Agente en vivo',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Cobre',
      autopilot: 'Piloto automático',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Calendario de Google',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Sistema de emisión de boletos de soporte',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Llamada de audio y video en el chat (Twilio/Agora)',
      courierTracking: 'Seguimiento de mensajería',
      emailIntegration: 'Integración de correo electrónico',
      webhooks: 'Webhooks',
      schedulers: 'Programadores',
      paymentGateways: 'Pasarelas de pago (Stripe y RazorPay)',
      enterpriseIntegrations: 'Integraciones empresariales',
      customIntegration: 'Integración personalizada',
      freeWhatsAppAPIApprovalAndSetup: 'Aprobación y configuración gratuita de la API de WhatsApp',
      developerAPIsAccess: 'Acceso a las APIs de desarrollador',
      customRateLimit: 'Límite de tasa personalizado',
      totalNumberOfLeads: 'Número total de leads',
      averageAgentResponseTime: 'Tiempo promedio de respuesta del agente',
      visitorTimingReport: 'Informe de sincronización de visitantes',
      customerSegmentBasedReports: 'Informes basados en segmentos de clientes',
      tagBasedReports: 'Informes basados en etiquetas',
      leadGenerationReport: 'Informe de generación de leads',
      supportOnWhatsApp: 'Soporte en WhatsApp',
      knowledgeBase: 'Base de conocimientos',
      onboardingAndTraining: 'Integración y capacitación',
      callAndEmailSupport: 'Soporte telefónico y por correo electrónico',
      freeChatbotCreation: 'Creación gratuita de chatbot',
      customerSuccessOfficer: 'Oficial de éxito del cliente',
      secureConnection: 'Conexión segura SSL de 2048 bits',
      securityAssessment: 'Evaluación de seguridad',
      dataCenterLocation: 'Ubicación del centro de datos (EE. UU. o UE)',
      twoStepVerification: 'Verificación de dos pasos',
      accessRestrictions: 'Restricciones de acceso',
      auditLog: 'Registro de auditoría bajo demanda',
      bannedVisitorsList: 'Lista de visitantes bloqueados',
      customAPIRate: 'Tarifa personalizada de API',
      trustedDomains: 'Dominios de confianza',
      elementary: 'Elemental',
      bots: 'Bots',
      unlockWhatsapp: 'Desbloquear WhatsApp',
      aiChatbotFeatures: 'Funciones de chatbot de IA',
      dataSourceForTraining: 'Fuente de datos para entrenamiento',
      smartFeatures: 'Funciones inteligentes',
      liveChat: 'Chat en vivo',
      smartInbox: 'Bandeja de entrada inteligente',
      broadcastsAndDrips: 'Transmisiones y Goteos',
      integrations: 'Integraciones',
      development: 'Desarrollo',
      reportsAndAnalytics: 'Informes y análisis',
      customerSupport: 'Soporte al cliente',
      security: 'Seguridad',
      whatsappWidgets: 'Widgets de WhatsApp',
      campaignSchedular: 'Programador de campañas',
      repeatPurchaseRemainder: 'Recordatorio de compra repetida',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Nuestro plan gratuito',
      TOOLTIP_KING_PLAN: 'Nuestro plan más popular',
      TOOLTIP_EMPEROR_PLAN: 'Plan personalizado para mensajes ilimitados, datos ilimitados, miembros del equipo ilimitados',
      TOOLTIP_CONTACT_US: 'Comuníquese con nosotros a través de whatsapp o envíe un correo electrónico a contact@botpenguin.com para obtener más información sobre nuestro plan personalizado',
      TOOLTIP_UPGRADE: 'Actualice su plan actual para funciones adicionales',
      TOOLTIP_MONTHLY: 'Seleccione un ciclo de pago mensual',
      TOOLTIP_YEARLY: 'Seleccione un ciclo de pago anual para beneficios de descuento',
    }
  }
};
