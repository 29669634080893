export const inboxMerged = {
  inboxHeader: 'Inbox',
  chatsHeader: 'Chat',
  contactsHeader: 'Contatti',
  segmentsHeader: 'Gruppi',
  ordersHeader: 'Ordini',
  exportStatus: 'Stato di esportazione',
  tasksExport: 'Compiti: esportazione',
  download: 'Scaricamento',
  ourAllNewOmniChannelInbox: 'La nostra nuova posta in arrivo omni-channel!',
  allYourMessagesContactsAndLeads: 'Tutti i tuoi messaggi, contatti e lead sono ora in un unico posto. Pronto per essere ordinato e gruppo, per far crescere la tua attività!',
  close: 'Vicino',
  takeALook: 'Guarda',
  inProgress: 'in corso',
  appTooltip: {
    manageAllYourLeadsHere: 'Gestisci tutti i tuoi lead/contatti qui',
    segmentYourUserLeads: 'Gruppo gli utenti di lead/bot in base a dati demografici e interessi. Questi verranno utilizzati nella creazione di trasmissioni',
    manageAllBotsConversationsHere: 'Gestisci tutte le conversazioni dei robot qui',
    manageAllYourOrdersHere: 'Gestisci tutti i tuoi ordini qui'
  },
  contacts: {
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    visitorName: 'Nome del visitatore',
    email: 'E-mail',
    phoneNumber: 'Numero di telefono',
    segment: 'Gruppo',
    status: 'Stato',
    tag: 'Etichetta',
    action: 'Azione',
    discard: 'Scartare',
    addTag: 'Aggiungi Tag',
    loadMore: 'Carica di più',
    nA: 'N / A',
    assign: 'Assegnare',
    deleteUser: 'Elimina l`utente',
    areYouSureToDeleteLead: 'Sei sicuro di voler eliminare questo lead?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'Non hai ancora nessun contatto!',
      allOfYourContactsAppearHere: 'Tutti i tuoi contatti vengono visualizzati qui'
    },
    placeholders: {
      searchSegmentByName: 'Gruppo di ricerca per nome',
      searchOrAddTag: 'Cerca o aggiungi un tag',
    },
    addContacts: {
      name: 'Nome',
      email: 'E-mail',
      enterAValidEmail: 'Inserisci un`e -mail valida',
      phoneNumber: 'Numero di telefono',
      cancel: 'Annulla',
      addContact: 'Aggiungi contatto',
      placeholders: {
        enterEmailAddress: 'Inserisci l`indirizzo email',
        enterUserrName: 'Inserire username',
        searchCountry: 'Cerca paese',
        enterPhoneNumber: 'Inserisci il numero di telefono',
      }
    }
  },
  inboxFilter: {
    applied: 'applicato',
    assignTags: 'Assegna i tag',
    discard: 'Scartare',
    addTag: 'Aggiungi Tag',
    assignSegments: 'Assegnare gruppi',
    filterYourLeads: 'Filtra i tuoi contatti',
    filters: 'filtri',
    filter: 'filtro',
    resetAllFilters: 'Ripristina tutti i filtri',
    importContacts: 'Importa contatti',
    beforeYouStart: 'Prima di iniziare il caricamento, assicurati',
    point1: '1. Scarica il file Esempio di Excel.',
    point2: '2. Inizia a inserire i dati dalla seconda riga in poi.',
    point3: '3. In ogni riga, ci saranno dati per un contatto',
    point4: '4. Assicurarsi che tutti i numeri di telefono siano unici.',
    point5: '5. Il limite di caricamento della dimensione del file massima è 1 MB',
    selectFile: 'Seleziona il file',
    clickHere: 'clicca qui',
    toDownloadSampleFile: 'Per scaricare il file di esempio',
    modal: {
      closeLeads: 'Close lead',
      areYouSureToselectAllLeads: 'Sei sicuro di voler chiudere tutti i lead selezionati?',
      assignAgent: 'Assegna lagente',
      toAssignAgentToAllLeads: 'Sei sicuro di voler assegnare l`agente a tutti i lead selezionati?',
      assignTag: 'Assegna il tag',
      wantToReplacePreviouslyAssignedTagWithNew: 'Sei sicuro di voler sostituire i tag precedentemente assegnati con il nuovo tag scelto per tutti i lead selezionati?',
      deleteLeads: 'Elimina lead',
      wantToDeleteSelectedLeads: 'Sei sicuro di voler eliminare tutti i lead selezionati?',
      exportData: 'Dati di esportazione',
      exportDataBasedOn: 'Dati di esportazione in base al file',
      assignSegment: 'Assegnare il gruppo',
      wantToAssignSegmentsToSelectedLeads: 'Sei sicuro di voler assegnare il gruppo a tutti i lead selezionati?',
      yesGoAhead: 'Si Fai pure!',
      export: 'Esportare',
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Filtro dati in base alle preferenze come, tag, bot, stato, ecc.',
      markLeadAsClosed: 'Segna l`utente/lead come chiuso',
      assignAgentToLead: 'Assegna l`agente all`utente',
      assignTagToUser: 'Assegna il tag all`utente',
      exportData: 'Dati di esportazione',
      importContactsForWhatsappBot: 'Importa contatti per WhatsApp Bot',
      addWhatsappContact: 'Aggiungi i contatti WhatsApp',
      assignSegmentToUser: 'Assegna il gruppo all`utente',
      deleteUsers: 'Elimina gli utenti',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Digita nome, e -mail o telefono e premere Invio',
      searchAgentByName: 'Cerca agente per nome',
      searchOrAddTag: 'Cerca o aggiungi un tag',
      searchSegmentByName: 'Gruppo di ricerca per nome',
    },
    filterContainer: {
      filterOn: 'Filtrare',
      filterBy: 'Filtro di',
      startFrom: 'Inizia da',
      tillDate: 'Fino alla data',
      offlineOrOnline: 'Off-line/Online',
      liveChat: 'Chat dal vivo',
      userInteractedWithBot: 'L`utente ha interagito con Bot',
      discard: 'Scartare',
      applyFilter: 'Applica il filtro',
      bots: 'Robot',
      tags: 'Tag',
      status: 'Stato',
      assignedTo: 'Assegnato a',
      lastMessageBy: 'Ultimo messaggio di',
      onlineOrOffline: 'In linea non in linea',
      userInteracted: 'L`utente ha interagito',
      segments: 'Gruppi',
      lastSeenDate: 'Ultima data vista',
      onboardingDate: 'Data di onboarding',
      createTags: 'Crea tag',
      createTagsToSortAndManageLeads: 'Crea tag per ordinare e gestire facilmente i tuoi lead',
      typeYourTagHere: 'Digita il tuo tag qui',
      createSegment: 'Crea gruppo',
      manageSegmentForMarketingAutomation: 'Gestisci i tuoi gruppi per l`automazione del marketing',
      typeYourSegmentHere: 'Digita il tuo gruppo qui',
      placeholders: {
        searchFilter: 'Filtro di ricerca',
        searchByBotName: 'Cerca per nome del bot',
        searchOrAddTag: 'Cerca o aggiungi un tag',
        searchByAgentName: 'Cerca per nome dell`agente',
        searchBySegments: 'Cerca per gruppi',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Intervallo di date che desideri terminare la selezione a',
        dateRangeYouWishToStartSelectionFrom: 'Intervallo di date che desideri iniziare la selezione',
        filterThoseThatAreOfflineOrOnline: 'Filtra quegli utenti offline/online',
        filterThoseThatAreCurrentlyLiveChatting: 'Filtra quegli utenti che stanno attualmente chattando',
        filterThoseThatInteractedWithBot: 'Filtra quegli utenti che hanno interagito con il bot',
      },
      indexMergeDatePicker: {
        selectStartDate: 'Seleziona Data di inizio',
        selectEndDate: 'Seleziona Data di fine',
      }
    }
  },
  leads: {
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    visitorName: 'Nome del visitatore',
    email: 'E-mail',
    phoneNumber: 'Numero di telefono',
    assignedTo: 'Assegnato a',
    status: 'Stato',
    tag: 'Etichetta',
    action: 'Azione',
    discard: 'Scartare',
    addTag: 'Aggiungi Tag',
    loadMore: 'Carica di più',
    nA: 'N / A',
    deleteUsers: 'Elimina l`utente',
    areYouSureToDeleteLead: 'Sei sicuro di voler eliminare questo lead?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Non hai ancora chat',
      allOfYourChatsAppearHere: 'Tutte le tue chat appariranno qui',
    },
    placeholders: {
      searchAgentByName: 'Cerca agente per nome',
      searchStatusByName: 'Stato di ricerca per nome',
      searchOrAddTag: 'Cerca o aggiungi un tag',
    }
  },
  messages: {
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    editChatFlow: 'Modifica flusso di chat',
    connect: 'Connetti',
    chat: {
      photo: 'Foto',
      video: 'Video',
      document: 'Documento',
      location: 'Posizione',
      assignedTo: 'Assegnato a',
      selectBot: 'Seleziona Bot',
      discard: 'Scartare',
      addTag: 'Aggiungi Tag',
      yourBrowserDoesNotSupportVideoTag: 'Il tuo browser non supporta il video tag.',
      userHasSharedADocument: 'L`utente ha condiviso un documento.',
      clickHereToDownload: 'Clicca qui per scaricare',
      thisStoryIsNoLongerAvailable: 'Questa storia non è più disponibile',
      yourBrowserDoesNotSupportAudioElement: 'Il tuo browser non supporta l`elemento audio.',
      thisMessageIsNoLongerAvailable: 'Questo messaggio non è più disponibile perché non era insentito dal mittente',
      userHasSharedHisLocation: 'L`utente ha condiviso la sua posizione. Clicca qui per visualizzare',
      sms: 'sms',
      failed: 'Fallito',
      a: 'UN',
      i: 'IO',
      r: 'R',
      m: 'M',
      conversationsSelected: 'conversazioni selezionate',
      nA: 'N / A',
      assignTagsCloseOrBulkReply: 'Assegna i tag, chiudi o una risposta sfusa a tutti in una volta',
      appTooltip: {
        assignAgent: 'Assegna l`agente',
        selectBot: 'Seleziona Bot',
        assignTagsToManageLeads: 'Assegna i tag per gestire i lead e le conversazioni',
      },
      placeholders: {
        searchAgentByName: 'Cerca agente per nome',
        searchBotByName: 'Cerca bot per nome',
        searchOrAddTag: 'Cerca o aggiungi un tag',
        searchStatusByName: 'Stato di ricerca per nome',
      }
    },
    profile: {
      userDetails: 'Dettagli dell`utente',
      orderDetails: 'Dettagli dell`ordine',
      orderedOn: 'Ordinato il',
      showMore: 'Mostra di più',
      showLess: 'Mostra meno',
      subTotal:  'Totale parziale',
      nA: 'N / A',
      addAWhatsappNumber: 'Aggiungi il numero di WhatsApp',
      addTelegramUserName: 'Aggiungi il nome utente del telegramma',
      addInstagramProfileId: 'Aggiungi ID profilo Instagram',
      addFacebookProfileId: 'Aggiungi ID profilo Facebook',
      add: 'Aggiungere',
      deviceProperties: 'Proprietà del dispositivo',
      privateNotes: 'Note private',
      urlsVisited: 'URL Visitati',
      source: 'Fonte -',
      sourceNa: 'Fonte - Na',
      medium: 'Medio -',
      mediumNa: 'Medio - Na',
      campaign: 'Campagna -',
      campaignNa: 'Campagna - NA',
      attributeKey: 'Chiave di attributo',
      attributeName: 'nome attributo',
      discard: 'Scartare',
      addAttribute: 'Aggiungi attributo',
      editAttribute: 'Attributo di modifica',
      deleteAttribute: 'Elimina attributo',
      headsUp: 'Dritta',
      areYouSureToDeleteAttribute: 'Sei sicuro di voler eliminare questo attributo?',
      okay: 'Va bene',
      dataAddedToLead: 'Gli attributi mappati per le tue API o i tuoi flussi del bot non verranno aggiornati. Si prega di aggiornare le API o i flussi del bot di conseguenza.',
      assignedOnly: 'Solo assegnato',
      allUsers: 'Tutti gli utenti',
      save: 'Salva',
      manageCustomAttributes: 'Gestisci attributi personalizzati',
      placeholders: {
        title: 'Titolo',
        egServices: 'Per esempio. Servizi',
        egRequestedServices: 'Per esempio. Servizi richiesti',
        enterYourNotesHere: 'Inserisci le tue note qui ...',
      },
      bpDropDown: {
        selectBot: 'Seleziona Bot',
        selectFlow: 'Seleziona il flusso',
        selectQuestion: 'Seleziona Domanda*',
      }
    },
    sendMessage: {
      send: 'Inviare',
      sendToAllChannels: 'Invia a tutti i canali',
      email: 'E-mail',
      whatsapp: 'WhatsApp',
      telegram: 'Telegramma',
      facebook: 'Facebook',
      sendSms: 'Inviare SMS',
      addNew: 'Aggiungere nuova',
      addNewQuickReply: 'Aggiungi nuova risposta rapida',
      discard: 'Scartare',
      fromComputer: 'Dal computer',
      add: 'Aggiungere',
      dragAndDropHereOr: 'Trascina e lascia cadere qui o',
      browseFiles: 'Sfoglia i file',
      caption: 'Didascalia',
      templates: 'Modelli',
      addHeaderMedia: 'Aggiungi supporti di intestazione',
      selectFile: 'Seleziona il file',
      previousConversationClosed: 'Conversazione precedente chiusa!',
      sendTemplateMessage: 'Invia un messaggio modello per ricominciare la conversazione.',
      selectTemplate: 'Seleziona il modello',
      conversationLocked: 'Conversazione bloccata!',
      demoBotOnlyAllowConversations: 'Demo Bot consente solo la conversazione con il numero che hai verificato in precedenza.',
      placeholders: {
        searchQuickReplyByTitle: 'Cerca risposte rapide per titolo',
        addTitle: 'Aggiungi titolo',
        addYourRepliesHere: 'Aggiungi le tue risposte qui ...',
        addCaption: 'Aggiungi didascalia',
        enterValueForThisVariable: 'Immettere il valore per questa variabile',
      },
      modal: {
        sendBulkMessages: 'Invia messaggi di massa',
        send: 'Inviare',
        description: 'Il tuo messaggio verrà disinfettato e inviato in base al tipo di piattaforma a cui appartiene un utente. - Gli utenti del sito Web supportano solo messaggi basati su testuali - I contatti WhatsApp (non abbonati) possono ricevere solo messaggi di modello',
      },
      noDataFound: {
        oops: 'Oops!',
        currentlyDoesNotSupportBulkMessages: 'Attualmente non supportiamo i messaggi in blocco per i robot whatsapp. Si prega di utilizzare la funzione di trasmissione',
        quickReplies: 'Risposte rapide',
        noQuickRepliesAddedYet: 'Nessuna risposta rapida ancora aggiunta!',
      }
    },
    userList: {
      selectAll: 'Seleziona tutto',
      nA: 'N / A',
      loadMore: 'Carica di più',
    }
  },
  segments: {
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    manageSegment: 'Gestisci il Gruppo',
    manageYourSegmentsForMarketingAutomation: 'Gestisci i tuoi gruppi per l`automazione del marketing',
    createNewSegment: 'Crea nuovo Gruppo',
    segmentName: 'Nome del Gruppo',
    totalContacts: 'Contatti totali',
    action: 'Azione',
    createSegmentButton: 'Crea Gruppo',
    deleteSegments: 'Elimina Gruppo',
    areYouSureToDeleteSegment: 'Sei sicuro di voler eliminare questo gruppo?',
    placeholders: {
      searchHere: 'Cerca qui',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'Non hai ancora creamenti creati',
      allYourSegmentsAppearHere: 'Tutti i tuoi gruppi appariranno qui',
    },
    modal: {
      exportData: 'Dati di esportazione',
      exportDataBasedOnAppliedFilters: 'Dati di esportazione in base ai filtri applicati',
      export: 'Esportare',
    },
    appTooltip: {
      deleteSegments: 'Elimina Gruppi',
      editSegment: 'Modifica Gruppo',
      downloadSegment: 'Scarica il Gruppo',
      deleteSegment: 'Elimina il Gruppo',
    },
    createSegment: {
      createSegment: 'Crea Gruppo',
      manageYourSegmentsForMarketingAutomation: 'Gestisci i tuoi gruppi per l`automazione del marketing',
      segmentName: 'Nome del Gruppo',
      segmentNameIsRequired: 'È richiesto il nome del Gruppo',
      selectContactsFilterForSegment: 'Selezionare i filtri di contatto per il Gruppo',
      filterYourContacts: 'Filtra i tuoi contatti',
      removeAllFilters: 'Rimuovere tutti i filtri',
      addFilters: 'Aggiungi filtri',
      discard: 'Scartare',
      filterSelected: 'Filtri selezionati',
      placeholders: {
        enterSegmentName: 'Immettere il nome del Gruppo',
      }
    }
  },
  orders: {
    visitorName: 'Nome del visitatore',
    email: 'Email',
    phoneNumber: 'Numero di telefono',
    status: 'Stato',
    itemCount: 'Numero di articoli',
    total: 'Totale',
    tag: 'Tag',
    noDataFound: {
      youDoNotHaveAnyOrdersYet:  'Non hai ancora ordini',
      allOfYourOrdersAppearHere:  'Tutti gli ordini appariranno qui'
    },
  }
};
