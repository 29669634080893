export const analytics = {
  botWise: {
    botWiseAnalytics: 'Analize pe Boturi',
    currentBillingCycle: 'Ciclu de Facturare Curent',
    currentMonth: 'Luna Curentă',
    sinceAccountCreation: 'De la Crearea Contului',
    messages: 'Mesaje',
    leads: 'Leads-uri',
    noDataFound: {
      botwiseAnalytics: 'Analize pe Boturi',
      noBotHasBeenCreatedYet: 'Niciun bot nu a fost creat încă!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Rezumat al datelor din mesaje și contacte/leads-uri pentru boturile specifice pentru ciclul de facturare curent, luna curentă sau de la crearea contului (Se actualizează la miezul nopții)',
      TOOLTIP_FILTERS: 'Filtrează datele pentru botul selectat',
      TOOLTIP_MESSAGES: 'Total mesaje consumate de contul dvs. de la crearea contului',
      TOOLTIP_LEADS: 'Total leads-uri capturate de boturi pentru contul dvs. de la crearea contului.',
    }

  },
  overview: {
    analytics: 'Analize',
    seeHowToUse: 'Află cum să folosești',
    help: 'Ajutor',
    overview: 'Vizualizare Generală',
    messages: 'Mesaje',
    leads: 'Leads-uri',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Analize pentru toate mesajele și leads-urile boturilor dvs. (Date actualizate la miezul nopții)',
    }
  },
  summary: {
    analyticsSummary: 'Rezumat Analize',
    allBots: 'Toate Boturile',
    daily: 'Zilnic',
    weekly: 'Săptămânal',
    monthly: 'Lunar',
    messages: 'Mesaje',
    leads: 'Leads-uri',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Rezumat al datelor zilnice, săptămânale sau lunare din mesaje și contacte/leads-uri pentru boturile specifice și pentru toate boturile (Se actualizează la miezul nopții)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'Analize WhatsApp',
      month: 'Lună',
      week: 'Săptămână',
      today: 'Astăzi',
      filters: 'Filtre',
      clearAll: 'Șterge Tot',
      apply: 'Aplică',
      totalConversations: 'Total Conversații',
      freeConversations: 'Conversații Gratuite',
      paidConversations: 'Conversații Plătite',
      messageSent: 'Mesaj Trimis',
      messageDelivered: 'Mesaj Livrat',
      bpDropDown: {
        selectBot: 'Selectează Botul',
      },
      indexMergeDatePicker: {
        startDate: 'Data de început',
        endDate: 'Data de sfârșit',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Analize Conversații',
      month: 'Lună',
      week: 'Săptămână',
      today: 'Astăzi',
      totalConversations: 'Total Conversații',
      paidConversations: 'Conversații Plătite',
      freeConversations: 'Conversații Gratuite',
      indexMergeDatePicker: {
        startDate: 'Data de început',
        endDate: 'Data de sfârșit',
      },
      bpDropDown: {
        allBots: 'Toate boturile'
      }
    },
    messageAnalytics: {
      whatsappMessages: 'Mesaje WhatsApp',
      sent: 'Trimise',
      delivered: 'Livrate',
      indexMergeDatePicker: {
        startDate: 'Data de început',
        endDate: 'Data de sfârșit',
      },
      bpDropDown: {
        allBots: 'Toate boturile'
      },
      month: 'Lună',
      week: 'Săptămână',
      today: 'Astăzi',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'Mesaje WhatsApp După Status',
      sent: 'Trimise',
      delivered: 'Livrate',
      indexMergeDatePicker: {
        startDate: 'Data de început',
        endDate: 'Data de sfârșit',
      },
      bpDropDown: {
        allBots: 'Toate boturile'
      },
      month: 'Lună',
      week: 'Săptămână',
      today: 'Astăzi',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Toți Abonații',
      whatsappSubscribers: 'Abonații WhatsApp'
    }
  }
};
