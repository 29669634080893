export const inboxMerged = {
  inboxHeader: 'Inbox',
  chatsHeader: 'Conversații',
  contactsHeader: 'Contacte',
  segmentsHeader: 'Grup',
  ordersHeader: 'Comenzi',
  exportStatus: 'Stare Export',
  tasksExport: 'Export Task-uri',
  download: 'Descarcă',
  ourAllNewOmniChannelInbox: 'Noul nostru Inbox Omni-canal!',
  allYourMessagesContactsAndLeads: 'Toate mesajele, contactele și lead-urile dvs. sunt acum într-un singur loc. Gata să fie sortate și grup, pentru a face afacerea să crească!',
  close: 'Închide',
  takeALook: 'Aruncă o privire',
  inProgress: 'în curs de desfășurare',
  appTooltip: {
    manageAllYourLeadsHere: 'Gestionați toate lead-urile/detalii de contact aici',
    segmentYourUserLeads: 'Grup lead-urile/utilizatorii botului pe baza demografiei și intereselor. Acestea vor fi utilizate în crearea transmisiilor',
    manageAllBotsConversationsHere: 'Gestionați toate conversațiile boturilor aici',
    manageAllYourOrdersHere: 'Gestionați toate comenzile dvs. aici',
  },
  contacts: {
    seeHowToUse: 'Află cum să folosești',
    help: 'Ajutor',
    visitorName: 'Nume Vizitator',
    email: 'E-mail',
    phoneNumber: 'Număr de Telefon',
    segment: 'Grup',
    status: 'Stare',
    tag: 'Etichetă',
    action: 'Acțiune',
    discard: 'Renunță',
    addTag: 'Adaugă Etichetă',
    loadMore: 'Încarcă Mai Mult',
    nA: 'N/A',
    assign: 'Atribuie',
    deleteUser: 'Șterge utilizatorul',
    areYouSureToDeleteLead: 'Sigur doriți să ștergeți acest lead?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'Nu aveți încă contacte!',
      allOfYourContactsAppearHere: 'Toate contactele dvs. vor apărea aici'
    },
    placeholders: {
      searchSegmentByName: 'Caută grup după nume',
      searchOrAddTag: 'Caută sau Adaugă o Etichetă',
    },
    addContacts: {
      name: 'Nume',
      email: 'E-mail',
      enterAValidEmail: 'Introduceți o adresă de e-mail validă',
      phoneNumber: 'Număr de Telefon',
      cancel: 'Anulează',
      addContact: 'Adaugă Contact',
      placeholders: {
        enterEmailAddress: 'Introduceți adresa de e-mail',
        enterUserrName: 'Introduceți numele utilizatorului',
        searchCountry: 'Caută Țara',
        enterPhoneNumber: 'Introduceți Numărul de Telefon',
      }
    }
  },
  inboxFilter: {
    applied: 'aplicat',
    assignTags: 'Atribuie Etichete',
    discard: 'Anulează',
    addTag: 'Adaugă Etichetă',
    assignSegments: 'Atribuie grup',
    filterYourLeads: 'Filtrează lead-urile tale',
    filters: 'filtre',
    filter: 'filtru',
    resetAllFilters: 'Resetează toate filtrele',
    importContacts: 'Importă Contacte',
    beforeYouStart: 'Înainte de a începe încărcarea, asigurați-vă că',
    point1: '1. Descărcați fișierul excel de exemplu.',
    point2: '2. Începeți să introduceți datele dvs. de la a doua linie înainte.',
    point3: '3. În fiecare linie, vor exista date pentru un contact',
    point4: '4. Asigurați-vă că toate numerele de telefon sunt unice.',
    point5: '5. Limita maximă de dimensiune a fișierului pentru încărcare este de 1MB',
    selectFile: 'Selectează Fișierul',
    clickHere: 'Apasă aici',
    toDownloadSampleFile: 'pentru a descărca fișierul de exemplu',
    modal: {
      closeLeads: 'Închide Lead-urile',
      areYouSureToselectAllLeads: 'Sunteți sigur că doriți să închideți toate lead-urile selectate?',
      assignAgent: 'Atribuie Agent',
      toAssignAgentToAllLeads: 'Sunteți sigur că doriți să atribuiți un agent tuturor lead-urilor selectate?',
      assignTag: 'Atribuie Etichetă',
      wantToReplacePreviouslyAssignedTagWithNew: 'Sunteți sigur că doriți să înlocuiți etichetele atribuite anterior cu noua etichetă aleasă pentru toate lead-urile selectate?',
      deleteLeads: 'Șterge Lead-urile',
      wantToDeleteSelectedLeads: 'Sunteți sigur că doriți să ștergeți toate lead-urile selectate?',
      exportData: 'Exportă Datele',
      exportDataBasedOn: 'Exportați datele pe baza ',
      assignSegment: 'Atribuie grup',
      wantToAssignSegmentsToSelectedLeads: 'Sunteți sigur că doriți să atribuiți grup tuturor lead-urilor selectate?',
      yesGoAhead: 'Da, Continuă!',
      export: 'Exportă'
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Filtrează datele în funcție de preferințele dvs., cum ar fi etichetele, bot-ul, starea, etc.',
      markLeadAsClosed: 'Marchează utilizatorul/lead-ul ca închis',
      assignAgentToLead: 'Atribuie agentul utilizatorului',
      assignTagToUser: 'Atribuie eticheta utilizatorului',
      exportData: 'Exportă Datele',
      importContactsForWhatsappBot: 'Importă contacte pentru bot-ul WhatsApp',
      addWhatsappContact: 'Adaugă contacte WhatsApp',
      assignSegmentToUser: 'Atribuie grup utilizatorului',
      deleteUsers: 'Ștergeți utilizatorii',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Tastați numele, e-mailul sau numărul de telefon și apăsați Enter',
      searchAgentByName: 'Căutați agentul după nume',
      searchOrAddTag: 'Căutare sau Adăugare Etichetă',
      searchSegmentByName: 'Căutați grup după nume',
    },
    filterContainer: {
      filterOn: 'Filtrează pe',
      filterBy: 'Filtrează după',
      startFrom: 'Porniți de la',
      tillDate: 'Până la dată',
      offlineOrOnline: 'Offline/Online',
      liveChat: 'Chat Live',
      userInteractedWithBot: 'Utilizatorul a interacționat cu Bot-ul',
      discard: 'Anulează',
      applyFilter: 'Aplică Filtru',
      bots: 'Bot-uri',
      tags: 'Etichete',
      status: 'Stare',
      assignedTo: 'Atribuit către',
      lastMessageBy: 'Ultimul Mesaj De',
      onlineOrOffline: 'Online/Offline',
      userInteracted: 'Utilizator a interacționat',
      segments: 'Grup',
      lastSeenDate: 'Data Ultimei Vizualizări',
      onboardingDate: 'Data Înregistrării',
      createTags: 'Creați Etichete',
      createTagsToSortAndManageLeads: 'Creați etichete pentru a sorta și gestiona lead-urile cu ușurință',
      typeYourTagHere: 'Tastați eticheta dvs. aici',
      createSegment: 'Creați grup',
      manageSegmentForMarketingAutomation: 'Gestionați grup pentru automatizarea marketingului',
      typeYourSegmentHere: 'Tastați grup dvs. aici',
      placeholders: {
        searchFilter: 'Căutare filtru',
        searchByBotName: 'Căutare după numele bot-ului',
        searchOrAddTag: 'Căutare sau Adăugare Etichetă',
        searchByAgentName: 'Căutare după numele agentului',
        searchBySegments: 'Căutare după grup',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Intervalul de dată la care doriți să încheiați selecția',
        dateRangeYouWishToStartSelectionFrom: 'Intervalul de dată de la care doriți să începeți selecția',
        filterThoseThatAreOfflineOrOnline: 'Filtrează utilizatorii care sunt offline/online',
        filterThoseThatAreCurrentlyLiveChatting: 'Filtrează utilizatorii care sunt în prezent în chat live',
        filterThoseThatInteractedWithBot: 'Filtrează utilizatorii care au interacționat cu Bot-ul'
      },
      indexMergeDatePicker: {
        selectStartDate: 'Selectați data de început',
        selectEndDate: 'Selectați data de sfârșit',
      }
    }
  },
  leads: {
    seeHowToUse: 'Vizualizați cum să utilizați',
    help: 'Ajutor',
    visitorName: 'Nume Vizitator',
    email: 'E-mail',
    phoneNumber: 'Număr de Telefon',
    assignedTo: 'Atribuit către',
    status: 'Stare',
    tag: 'Etichetă',
    action: 'Acțiune',
    discard: 'Anulare',
    addTag: 'Adăugare Etichetă',
    loadMore: 'Încarcă Mai Mult',
    nA: 'NA',
    deleteUsers: 'Ștergeți utilizatorul',
    areYouSureToDeleteLead: 'Sunteți sigur că doriți să ștergeți acest lead?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Nu aveți încă nicio conversație',
      allOfYourChatsAppearHere: 'Toate conversațiile dvs. vor apărea aici',
    },
    placeholders: {
      searchAgentByName: 'Căutați agent după nume',
      searchStatusByName: 'Căutați stare după nume',
      searchOrAddTag: 'Căutare sau Adăugare Etichetă',
    }
  },
  messages: {
    seeHowToUse: 'Vizualizați cum să utilizați',
    help: 'Ajutor',
    editChatFlow: 'Editează fluxul de conversație',
    connect: 'Conectează',
    chat: {
      photo: 'Fotografie',
      video: 'Video',
      document: 'Document',
      location: 'Locație',
      assignedTo: 'Atribuit Către',
      selectBot: 'Selectați Bot-ul',
      discard: 'Anulare',
      addTag: 'Adăugare Etichetă',
      yourBrowserDoesNotSupportVideoTag: 'Browser-ul dvs. nu acceptă eticheta video.',
      userHasSharedADocument: 'Utilizatorul a partajat un document.',
      clickHereToDownload: 'Faceți clic aici pentru a descărca',
      thisStoryIsNoLongerAvailable: 'Această poveste nu mai este disponibilă',
      yourBrowserDoesNotSupportAudioElement: 'Browser-ul dvs. nu acceptă elementul audio.',
      thisMessageIsNoLongerAvailable: 'Acest mesaj nu mai este disponibil deoarece a fost retras de expeditor',
      userHasSharedHisLocation: 'Utilizatorul a partajat locația sa. Faceți clic aici pentru a vizualiza',
      sms: 'SMS',
      failed: 'Eșuat',
      a: 'A',
      i: 'Eu',
      r: 'R',
      m: 'M',
      conversationsSelected: 'conversații selectate',
      nA: 'NA',
      assignTagsCloseOrBulkReply: 'Atribuiți etichete, închideți sau răspundeți în vrac într-o singură acțiune',
      appTooltip: {
        assignAgent: 'Atribuiți agent',
        selectBot: 'Selectați Bot-ul',
        assignTagsToManageLeads: 'Atribuiți etichete pentru a gestiona lead-urile și conversațiile',
      },
      placeholders: {
        searchAgentByName: 'Căutați agent după nume',
        searchBotByName: 'Căutați Bot după nume',
        searchOrAddTag: 'Căutare sau Adăugare Etichetă',
        searchStatusByName: 'Căutați stare după nume',
      }
    },
    profile: {
      userDetails: 'Detalii Utilizator',
      orderDetails: 'Detalii Comandă',
      orderedOn: 'Comandată la',
      showMore: 'Afișează Mai Mult',
      showLess: 'Afișează Mai Puțin',
      subTotal: 'Subtotal',
      nA: 'NA',
      addAWhatsappNumber: 'Adaugă Număr Whatsapp',
      addTelegramUserName: 'Adaugă Nume Telegram',
      addInstagramProfileId: 'Adaugă ID Profil Instagram',
      addFacebookProfileId: 'Adaugă ID Profil Facebook',
      add: 'Adaugă',
      deviceProperties: 'Proprietăți Dispozitiv',
      privateNotes: 'Note Private',
      urlsVisited: 'URL-uri Vizitate',
      source: 'Sursă - ',
      sourceNa: 'Sursă - NA',
      medium: 'Mediu - ',
      mediumNa: 'Mediu - NA',
      campaign: 'Campanie - ',
      campaignNa: 'Campanie - NA',
      attributeKey: 'Cheie Atribut',
      attributeName: 'Nume Atribut',
      discard: 'Renunță',
      addAttribute: 'Adaugă Atribut',
      editAttribute: 'Editează Atribut',
      deleteAttribute: 'Șterge Atribut',
      headsUp: 'Atenție',
      areYouSureToDeleteAttribute: 'Sunteți sigur că doriți să ștergeți acest atribut?',
      okay: 'Ok',
      dataAddedToLead: 'Atributele mapeate către API-urile sau fluxurile tale de bot nu vor fi actualizate. Te rog actualizează API-urile sau fluxurile de bot în consecință.',
      assignedOnly: 'Numai Atribuit',
      allUsers: 'Toți Utilizatorii',
      save: 'Salvează',
      manageCustomAttributes: 'Gestiona Atributurile',
      placeholders: {
        title: 'Titlu',
        egServices: 'Exemplu: Servicii',
        egRequestedServices: 'Exemplu: Servicii Solicitate',
        enterYourNotesHere: 'Introduceți notele dvs. aici ...'
      },
      bpDropDown: {
        selectBot: 'Selectează Bot-ul',
        selectFlow: 'Selectează Fluxul',
        selectQuestion: 'Selectează Întrebarea*'
      }
    },
    sendMessage: {
      send: 'Trimite',
      sendToAllChannels: 'Trimite la toate canalele',
      email: 'Email',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
      facebook: 'Facebook',
      sendSms: 'Trimite SMS',
      addNew: 'Adaugă Nou',
      addNewQuickReply: 'Adaugă Răspuns Rapid Nou',
      discard: 'Renunță',
      fromComputer: 'DE PE CALCULATOR',
      add: 'Adaugă',
      dragAndDropHereOr: 'Trage și Plasează aici sau',
      browseFiles: 'Răsfoiește fișiere',
      caption: 'Legendă',
      templates: 'Șabloane',
      addHeaderMedia: 'Adaugă media pentru antet',
      selectFile: 'Selectează Fișierul',
      previousConversationClosed: 'Conversația anterioară închisă!',
      sendTemplateMessage: 'Trimite mesaj șablon pentru a iniția conversația din nou.',
      selectTemplate: 'Selectează Șablonul',
      conversationLocked: 'Conversația blocată!',
      demoBotOnlyAllowConversations: 'Botul demo permite doar conversații cu numărul pe care l-ați verificat anterior.',
      placeholders: {
        searchQuickReplyByTitle: 'Caută Răspunsuri Rapide după titlu',
        addTitle: 'Adaugă Titlu',
        addYourRepliesHere: 'Adaugă răspunsurile tale aici... ',
        addCaption: 'Adaugă Legendă',
        enterValueForThisVariable: 'Introduceți valoarea pentru această variabilă',
      },
      modal: {
        sendBulkMessages: 'Trimite Mesaje în Bloc',
        send: 'Trimite',
        description: 'Mesajul dvs. va fi curățat și trimis în funcție de tipul platformei la care utilizatorul aparține.' +
          '\n - Utilizatorii site-ului web acceptă doar mesaje textuale \n - Contactele Whatsapp (neabonate) pot primi doar mesaje șablon'
      },
      noDataFound: {
        oops: 'Ups!',
        currentlyDoesNotSupportBulkMessages: 'În prezent, nu suportăm mesajele în bloc pentru roboții WhatsApp. Utilizați funcția de transmitere',
        quickReplies: 'Răspunsuri Rapide',
        noQuickRepliesAddedYet: 'Niciun răspuns rapid adăugat încă!',
      }
    },
    userList: {
      selectAll: 'Selectează Tot',
      nA: 'NA',
      loadMore: 'Încarcă Mai Mult',
    }
  },
  segments: {
    seeHowToUse: 'Vezi cum să utilizezi',
    help: 'Ajutor',
    manageSegment: 'Administrează Grup',
    manageYourSegmentsForMarketingAutomation: 'Administrează Grup tale pentru automatizarea marketingului',
    createNewSegment: 'Creează grup Nou',
    segmentName: 'Nume grup',
    totalContacts: 'Total Contacte',
    action: 'Acțiune',
    createSegmentButton: 'Creează Grup',
    deleteSegments: 'Șterge Grup',
    areYouSureToDeleteSegment: 'Sigur doriți să ștergeți acest Grup?',
    placeholders: {
      searchHere: 'Caută aici',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'Nu aveți încă niciun Grup creat',
      allYourSegmentsAppearHere: 'Toate Grup tale vor apărea aici',
    },
    modal: {
      exportData: 'Exportă Date',
      exportDataBasedOnAppliedFilters: 'Exportă date bazate pe filtrele aplicate',
      export: 'Exportă',
    },
    appTooltip: {
      deleteSegments: 'Ștergeți grupurile',
      editSegment: 'Editează Grup',
      downloadSegment: 'Descarcă Grup',
      deleteSegment: 'Șterge Grup',
    },
    createSegment: {
      createSegment: 'Creează Grup',
      manageYourSegmentsForMarketingAutomation: 'Administrează Grup tale pentru automatizarea marketingului',
      segmentName: 'Nume Grup',
      segmentNameIsRequired: 'Nume Grup este obligatoriu',
      selectContactsFilterForSegment: 'Selectează filtrele de contacte pentru Grup',
      filterYourContacts: 'Filtrează-ți contactele',
      removeAllFilters: 'Elimină Toate Filtrele',
      addFilters: 'Adaugă Filtre',
      discard: 'Renunță',
      filterSelected: 'Filtre Selectate',
      placeholders: {
        enterSegmentName: 'Introduceți Nume Grup',
      },
    },
  },
  orders: {
    visitorName: 'Nume Vizitator',
    email: 'E-mail',
    phoneNumber: 'Număr de Telefon',
    status: 'Status',
    itemCount: 'Număr Articole',
    total: 'Total',
    tag: 'Tag',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'Nu aveți încă nicio comandă',
      allOfYourOrdersAppearHere: 'Toate comenzile tale vor apărea aici',
    },
  }
};
