import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private networkService: NetworkService) { }
  getRequestNotifications() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('notification', null, 'GET', true)
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  markNotificationAsRead() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('notification/read', {}, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  updateNotificationSetting(payload) {
  return  this.networkService.httpRequest('customer/alert-settings', payload, 'PUT', true);
  }
}
