export const whitelabel = {
  plans: 'योजनाओं',
  managePlan: 'योजनाओं का प्रबंधन करें',
  whitelabelCharges: 'व्हिटलेबेल प्रभार',
  chargesOrTaxesConfiguration: 'प्रभार/कर विन्यास',
  websitePlans: 'वेबसाइट की योजना',
  whatsappPlansHeader: 'व्हाट्सएप की योजना है',
  addOnPlans: {
    createAddOnPlans: 'ऐड-ऑन प्लान बनाएं',
    checkoutYourCurrentMessage: 'अपने वर्तमान संदेश ऐड-ऑन योजनाओं और अपने ग्राहकों के लिए नई योजनाओं की जाँच करें',
    choosePlanValidity: 'योजना की वैधता चुनें',
    chooseValidity: 'वैधता चुनें',
    oneYear: 'एक वर्ष',
    billingCycle: 'बिलिंग चक्र',
    numberOfMessages: 'संदेशों की संख्या',
    pleaseEnterNumberOfMessages: 'कृपया संदेशों की संख्या दर्ज करें',
    setCurrency: 'मुद्रा निर्धारित करें',
    select: 'चुनना',
    inr: 'इनर',
    usd: 'USD',
    cad: 'पाजी',
    clearAll: 'सभी साफ करें',
    apply: 'आवेदन करना',
    currency: 'मुद्रा',
    amount: 'मात्रा',
    validity: 'वैधता',
    upgradePlan: 'अद्यतन योजना',
    addPlan: 'जोड़ें',
    serialNumber: 'क्रमांक',
    messages: 'संदेशों',
    active: 'सक्रिय',
    priceDetails: 'मूल्य विवरण',
    action: 'कार्य',
    addOnChanges: 'ऐड-ऑन शुल्क:',
    perYear: 'प्रति वर्ष',
    taxes: 'करों',
    placeholders: {
      enterNumberOfMesasges: 'संदेशों की संख्या दर्ज करें',
      enterAmount: 'राशि डालें',
      enterValidity: 'वैधता दर्ज करें',
      searchByMessages: 'संदेशों द्वारा खोजें'
    },
    modal: {
      deleteAddOnsPlan: 'ऐड-ऑन प्लान डिलीट करें',
      wantToDeleteThisAddOnsPlan: 'क्या आप सुनिश्चित हैं कि आप इस ऐड-ऑन प्लान को हटाना चाहते हैं?'
    }
  },
  charges: {
    addWhitelabelCharges: 'व्हिटेलैबेल चार्ज जोड़ें',
    checkoutExistingCharges: 'विभिन्न भुगतान विधियों पर मौजूदा शुल्क देखें और नए व्हाइटलेबेल शुल्क जोड़ें',
    currency: 'मुद्रा',
    chooseCurrency: 'मुद्रा चुनिये',
    amountPerBotPerMonth: 'प्रति माह प्रति बॉट राशि',
    cancel: 'रद्द करना',
    upgradeChanges: 'अद्यतन प्रभार',
    addCharges: 'प्रभार जोड़ें',
    serialNumber: 'क्रमांक',
    action: 'कार्य',
    taxes: 'करों',
    placeholders: {
      enterAmountBotPerMonth: 'प्रति माह राशि बॉट दर्ज करें',
      searchByCurrency: 'मुद्रा द्वारा खोज'
    },
    modal: {
      deleteCharges: 'प्रभार हटाएं',
      wantToDeleteThisCharge: 'क्या आप सुनिश्चित हैं कि आप इस शुल्क को हटाना चाहते हैं?'
    }
  },
  planManagement: {
    createNewPlans: 'नई योजनाएँ बनाएं',
    checkoutYourCurrentPlan: 'अपनी वर्तमान योजनाओं की जाँच करें और अपने ग्राहकों के लिए नई योजनाएं जोड़ें',
    choosePlan: 'योजना चुनें',
    selectPlan: 'प्लान का चयन करें',
    baby: 'बेबी ',
    king: 'किंग ',
    displayName: 'प्रदर्शित होने वाला नाम',
    messageLimit: 'संदेश सीमा',
    enterAValidEmail: 'एक वैध ईमेल प्रविष्ट करें',
    setCurrency: 'मुद्रा निर्धारित करें',
    select: 'चुनना',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'सभी साफ करें',
    apply: 'आवेदन करना',
    perMonth: '/महीना',
    perYear: '/वर्ष',
    cancel: 'रद्द करना',
    upgradePlan: 'अद्यतन योजना',
    addPlan: 'जोड़ें',
    serialNumber: 'क्रमांक',
    plan: ' योजना',
    messages: 'संदेशों',
    pricing: 'मूल्य निर्धारण',
    actions: 'कार्रवाई',
    perMonthText: 'प्रति महीने',
    perYearText: 'प्रति वर्ष',
    appTooltip: {
      chooseYourPlanType: 'आप योजना प्रकार चुनें',
      pleaseInputYourPlanDisplayName: 'कृपया अपना प्लान डिस्प्ले नाम इनपुट करें',
      typeMessageLimitForCustomer: 'ग्राहक के लिए संदेश सीमा टाइप करें',
      selectYourPreferredCurrencies: 'अपनी पसंदीदा मुद्राओं का चयन करें',
    },
    placeholders: {
      enterDisplayName: 'प्रदर्शन नाम दर्ज करें',
      enterMessageLimit: 'संदेश सीमा दर्ज करें',
      searchByPlanName: 'योजना नाम या संदेश द्वारा खोजें'
    },
    modal: {
      deletePlan: 'योजना मिटाएं',
      wantToDeleteThisBabyPlan: 'बेबी प्लान को मिटाने से सभी ग्राहकों पर प्रभाव पड़ेगा। क्या आप इस योजना को मिटाना चाहते हैं?',
      wantToDeleteThisPlan: 'क्या आप सुनिश्चित हैं कि आप इस योजना को हटाना चाहते हैं?'
    }
  },
  taxesConfiguration: {
    addTaxes: 'कर जोड़ें',
    checkoutExistingTaxes: 'विभिन्न भुगतान विधियों और नए करों पर मौजूदा करों की जाँच करें',
    taxName: 'कर नाम',
    applicableOn: 'पर लागू',
    choosePlans: 'योजनाएँ चुनें',
    chooseCurrency: 'मुद्रा चुनिये',
    percentage: 'प्रतिशत',
    cancel: 'रद्द करना',
    serialNumber: 'क्रमांक',
    name: 'नाम',
    currency: 'मुद्रा',
    updateTaxes: 'अद्यतन कर',
    actions: 'कार्रवाई',
    modal: {
      deleteTaxes: 'करों को हटा दें',
      wantToDeleteThisTax: 'क्या आप सुनिश्चित हैं कि आप इस कर को हटाना चाहते हैं?'
    },
    placeholders: {
      taxName: 'कर नाम',
      taxPercentage: 'कर प्रतिशत',
      searchByCurrency: 'मुद्रा द्वारा खोज'
    }
  },
  whatsappPlans: {
    createNewPlans: 'नई योजनाएँ बनाएं',
    checkoutYourCurrentAndNewPlans: 'अपनी वर्तमान योजनाओं की जाँच करें और अपने ग्राहकों के लिए नई योजनाएं जोड़ें',
    choosePlan: 'योजना चुनें',
    selectPlan: 'प्लान का चयन करें',
    baby: 'बेबी ',
    king: 'किंग ',
    displayName: 'प्रदर्शित होने वाला नाम',
    messageLimit: 'बातचीत सीमा',
    enterAValidMessageLimit: 'एक वैध बातचीत सीमा दर्ज करें',
    messages: 'बातचीतें',
    setCurrency: 'मुद्रा निर्धारित करें',
    select: 'चुनना',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'सभी साफ करें',
    apply: 'आवेदन करना',
    perMonth: '/महीना',
    perYear: '/वर्ष',
    cancel: 'रद्द करना',
    addPlan: 'जोड़ें',
    serialNumber: 'क्रमांक',
    plan: 'योजना',
    pricing: 'मूल्य निर्धारण',
    actions: 'कार्रवाई',
    perMonthText: 'प्रति महीने',
    perYearText: 'प्रति वर्ष',
    updatePlan: 'अद्यतन योजना',
    placeholders: {
      enterDisplayName: 'प्रदर्शन नाम दर्ज करें',
      enterMessageLimit: 'बातचीत सीमा दर्ज करें',
      searchByPlanName: 'योजना का नाम या बातचीत के द्वारा खोजें',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'ग्राहक के लिए बातचीत सीमा टाइप करें',
      chooseYourPlanType: 'आप योजना प्रकार चुनें',
      selectYourPreferredCurrencies: 'अपनी पसंदीदा मुद्राओं का चयन करें',
      pleaseInputYourPlanDisplayName: 'कृपया अपना प्लान डिस्प्ले नाम इनपुट करें',
    },
    modal: {
      deletePlan: 'योजना मिटाएं',
      wantToDeleteThisBabyPlan: 'बेबी प्लान को मिटाने से सभी ग्राहकों पर प्रभाव पड़ेगा। क्या आप इस योजना को मिटाना चाहते हैं?',
      areYouSureYouWantToDeleteThisPlan: 'क्या आप सुनिश्चित हैं कि आप इस योजना को हटाना चाहते हैं?',
    }
  }
};
