import {Action, createReducer, on} from '@ngrx/store';
import { addSocialTemplateBroadcast, loadSocialTemplateBroadcast, removeSocialTemplateBroadcast } from '../actions/social-template-broadcast.actions';


export const initialState: any[] = [];

const socialTemplateBroadcastReducer =  createReducer(
    initialState,
    on(loadSocialTemplateBroadcast, (state, {broadcasts}) => broadcasts),
    on(addSocialTemplateBroadcast,  (state, {broadcast}) => [...state, broadcast]),
    on(removeSocialTemplateBroadcast, (state, {broadcast}) => state.filter(item => item._id !== broadcast))
);

export function reducer(state: any | undefined, action: Action) {
    return socialTemplateBroadcastReducer(state, action);
  }
