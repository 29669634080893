export const inboxMerged = {
  inboxHeader: 'Gelen Kutusu',
  chatsHeader: 'Sohbetler',
  contactsHeader: 'İletişim',
  segmentsHeader: 'Grup',
  ordersHeader: 'Siparişler',
  exportStatus: 'Dışa Aktarma Durumu',
  tasksExport: 'Görevler- Dışa Aktar',
  download: 'İndir',
  ourAllNewOmniChannelInbox: 'Yepyeni Omni-Kanal Gelen Kutumuz!',
  allYourMessagesContactsAndLeads: 'Tüm mesajlarınız, iletişimleriniz ve potansiyel müşterileriniz artık tek bir yerde. İşinizi büyütmek için tasnif edilmeye ve gruplandırılmaya hazır!',
  close: 'Kapat',
  takeALook: 'Bir göz at',
  inProgress: 'Devam ediyor',
  appTooltip: {
    manageAllYourLeadsHere: 'Tüm potansiyel müşterilerinizi buradan yönetin',
    segmentYourUserLeads: 'Kullanıcı müşterilerinizi demografik bilgilere ve ilgi alanlarına göre gruplandırın. Bunlar gönderiler oluşturulurken kullanılacaktır.',
    manageAllBotsConversationsHere: 'Tüm bot sohbetlerinizi buradan yönetin',
    manageAllYourOrdersHere: 'Tüm siparişlerinizi buradan yönetin',
  },
  contacts: {
    seeHowToUse: 'Nasıl kullanılacağını görün',
    help: 'Yardım',
    visitorName: 'Ziyaretçi Adı',
    email: 'E-posta',
    phoneNumber: 'Telefon Numarası',
    segment: 'Grup',
    status: 'Durum',
    tag: 'Etiket',
    action: 'Eylem',
    discard: 'Reddet',
    addTag: 'Etiket Ekle',
    loadMore: 'Daha Fazla Yükle',
    nA: 'Yok',
    assign: 'Ata',
    deleteUser: 'Kullanıcıyı Sil',
    areYouSureToDeleteLead: 'Bu potansiyel müşteriyi silmek istediğinizden emin misiniz?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'Henüz iletişim bilgileriniz yok!',
      allOfYourContactsAppearHere: 'Tüm iletişimleriniz burada görünecektir.'
    },
    placeholders: {
      searchSegmentByName: 'İsme göre Grup ara',
      searchOrAddTag: 'Etiket ara veya ekle',
    },
    addContacts: {
      name: 'Ad',
      email: 'E-posta',
      enterAValidEmail: 'Geçerli bir e-posta adresi girin',
      phoneNumber: 'Telefon Numarası',
      cancel: 'İptal',
      addContact: 'İletişim Ekle',
      placeholders: {
        enterEmailAddress: 'E-posta adresini girin',
        enterUserrName: 'Kullanıcı adını girin',
        searchCountry: 'Ülke Ara',
        enterPhoneNumber: 'Telefon Numarasını Girin',
      }
    }
  },
  inboxFilter: {
    applied: 'uygulandı',
    assignTags: 'Etiket Ata',
    discard: 'Reddet',
    addTag: 'Etiket Ekle',
    assignSegments: 'Grup Ata',
    filterYourLeads: 'Potansiyel müşterilerinizi filtreleyin',
    filters: 'filtreler',
    filter: 'filtre',
    resetAllFilters: 'Tüm filtreleri sıfırla',
    importContacts: 'İletişim Bilgilerini İçe Aktar',
    beforeYouStart: 'Başlamadan önce lütfen aşağıdakilere dikkat edin',
    point1: '1. Örnek Excel dosyasını indirin.',
    point2: '2. Verilerinizi ikinci sıradan itibaren girmeye başlayın.',
    point3: '3. Her satırda bir iletişim bilgisi bulunacak',
    point4: '4. Tüm telefon numaralarının benzersiz olduğundan emin olun.',
    point5: '5. Maksimum dosya boyutu yükleme sınırı 20MB\'dir',
    selectFile: 'Dosya Seç',
    clickHere: 'Buraya tıklayın',
    toDownloadSampleFile: 'örnek dosyayı indirmek için',
    modal: {
      closeLeads: 'Potansiyel Müşterileri Kapat',
      areYouSureToselectAllLeads: 'Tüm seçilen potansiyel müşterileri kapatmak istediğinizden emin misiniz?',
      assignAgent: 'Ajan Ata',
      toAssignAgentToAllLeads: 'Tüm seçilen potansiyel müşterilere ajan atamak istediğinizden emin misiniz?',
      assignTag: 'Etiket Ata',
      wantToReplacePreviouslyAssignedTagWithNew: 'Daha önce atanan etiketleri seçilen tüm potansiyel müşteriler için yeni seçilen etiketle değiştirmek istediğinizden emin misiniz?',
      deleteLeads: 'Potansiyel Müşterileri Sil',
      wantToDeleteSelectedLeads: 'Tüm seçilen potansiyel müşterileri silmek istediğinizden emin misiniz?',
      exportData: 'Veriyi Dışa Aktar',
      exportDataBasedOn: 'Veriyi şuna göre dışa aktar: ',
      assignSegment: 'Grup Ata',
      wantToAssignSegmentsToSelectedLeads: 'Tüm seçilen potansiyel müşterilere Grup atamak istediğinizden emin misiniz?',
      yesGoAhead: 'Evet, Devam Et!',
      export: 'Dışa Aktar'
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Etiketler, botlar, durum vb. gibi tercihlerinize göre verileri filtreleyin.',
      markLeadAsClosed: 'Kullanıcı/potansiyel müşteriyi kapat olarak işaretle',
      assignAgentToLead: 'Kullanıcıya ajan atayın',
      assignTagToUser: 'Kullanıcıya etiket atayın',
      exportData: 'Veriyi Dışa Aktar',
      importContactsForWhatsappBot: 'Whatsapp bot için iletişimleri içe aktarın',
      addWhatsappContact: 'Whatsapp iletişimleri ekleyin',
      assignSegmentToUser: 'Kullanıcıya Grup atayın',
      deleteUsers: 'Kullanıcıları sil',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Adı, e-postayı veya telefon numarasını yazın ve Enter tuşuna basın',
      searchAgentByName: 'Ajan adına göre ara',
      searchOrAddTag: 'Etiket ara veya ekle',
      searchSegmentByName: 'Grup adına göre ara',
    },
    filterContainer: {
      filterOn: 'Filtrele',
      filterBy: 'Tarafından Filtrele',
      startFrom: 'Başlangıç',
      tillDate: 'Tarih',
      offlineOrOnline: 'Çevrimdışı/Çevrimiçi',
      liveChat: 'Canlı Sohbet',
      userInteractedWithBot: 'Kullanıcı Bot ile Etkileşimde Bulundu',
      discard: 'Reddet',
      applyFilter: 'Filtreyi Uygula',
      bots: 'Botlar',
      tags: 'Etiketler',
      status: 'Durum',
      assignedTo: 'Atanan',
      lastMessageBy: 'Son Mesaj Gönderen',
      onlineOrOffline: 'Çevrimiçi/Çevrimdışı',
      userInteracted: 'Kullanıcı Etkileşimi',
      segments: 'Gruplar',
      lastSeenDate: 'Son Görüntülenme Tarihi',
      onboardingDate: 'Onboarding Tarihi',
      createTags: 'Etiket Oluştur',
      createTagsToSortAndManageLeads: 'Potansiyel müşterilerinizi kolayca sıralamak ve yönetmek için etiketler oluşturun',
      typeYourTagHere: 'Etiketinizi buraya yazın',
      createSegment: 'Grup Oluştur',
      manageSegmentForMarketingAutomation: 'Pazarlama otomasyonu için grubunuzu yönetin',
      typeYourSegmentHere: 'Grubunuzu buraya yazın',
      placeholders: {
        searchFilter: 'Filtre ara',
        searchByBotName: 'Bot adına göre ara',
        searchOrAddTag: 'Etiket ara veya ekle',
        searchByAgentName: 'Ajan adına göre ara',
        searchBySegments: 'Grup adına göre ara',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Seçimi bitirmek istediğiniz tarih aralığı',
        dateRangeYouWishToStartSelectionFrom: 'Seçimi başlatmak istediğiniz tarih aralığı',
        filterThoseThatAreOfflineOrOnline: 'Çevrimdışı/Çevrimiçi olan kullanıcıları filtreleyin',
        filterThoseThatAreCurrentlyLiveChatting: 'Şu anda canlı sohbet yapan kullanıcıları filtreleyin',
        filterThoseThatInteractedWithBot: 'Bot ile etkileşimde bulunan kullanıcıları filtreleyin'
      },
      indexMergeDatePicker: {
        selectStartDate: 'Başlangıç tarihini seçin',
        selectEndDate: 'Bitiş tarihini seçin',
      }
    }
  },
  leads: {
    seeHowToUse: 'Nasıl kullanılacağını gör',
    help: 'Yardım',
    visitorName: 'Ziyaretçi Adı',
    email: 'E-posta',
    phoneNumber: 'Telefon Numarası',
    assignedTo: 'Atanan Kişi',
    status: 'Durum',
    tag: 'Etiket',
    action: 'Eylem',
    discard: 'At',
    addTag: 'Etiket Ekle',
    loadMore: 'Daha Fazla Yükle',
    nA: 'Yok',
    deleteUsers: 'Kullanıcıyı Sil',
    areYouSureToDeleteLead: 'Bu kişiyi silmek istediğinizden emin misiniz?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Henüz hiç sohbetiniz yok',
      allOfYourChatsAppearHere: 'Tüm sohbetleriniz burada görünecektir',
    },
    placeholders: {
      searchAgentByName: 'İsimle arama yapın',
      searchStatusByName: 'Durum adına göre arama yapın',
      searchOrAddTag: 'Etiket arayın veya ekleyin',
    }
  },
  messages: {
    seeHowToUse: 'Nasıl kullanılacağını gör',
    help: 'Yardım',
    chat: {
      photo: 'Fotoğraf',
      video: 'Video',
      document: 'Belge',
      location: 'Konum',
      assignedTo: 'Atanan Kişi',
      selectBot: 'Bot Seçin',
      discard: 'At',
      addTag: 'Etiket Ekle',
      yourBrowserDoesNotSupportVideoTag: 'Tarayıcınız video etiketini desteklemiyor.',
      userHasSharedADocument: 'Kullanıcı bir belge paylaştı.',
      clickHereToDownload: 'İndirmek için buraya tıklayın',
      thisStoryIsNoLongerAvailable: 'Bu hikaye artık kullanılabilir değil',
      yourBrowserDoesNotSupportAudioElement: 'Tarayıcınız ses öğesini desteklemiyor.',
      thisMessageIsNoLongerAvailable: 'Bu mesaj, gönderen tarafından geri alındığı için artık mevcut değil',
      userHasSharedHisLocation: 'Kullanıcı konumunu paylaştı. Görüntülemek için buraya tıklayın',
      sms: 'SMS',
      failed: 'Başarısız',
      a: 'A',
      i: 'Ben',
      r: 'R',
      m: 'M',
      conversationsSelected: 'konuşma seçildi',
      nA: 'Yok',
      assignTagsCloseOrBulkReply: 'Etiketleri atayın, kapatın veya toplu yanıt verin',
      appTooltip: {
        assignAgent: 'Atama yap',
        selectBot: 'Bot Seçin',
        assignTagsToManageLeads: 'Leads ve konuşmaları yönetmek için etiket atayın',
      },
      placeholders: {
        searchAgentByName: 'İsimle arama yapın',
        searchBotByName: 'Bot adına göre arama yapın',
        searchOrAddTag: 'Etiket arayın veya ekleyin',
        searchStatusByName: 'Durum adına göre arama yapın',
      }
    },
    profile: {
      userDetails: 'Kullanıcı Detayları',
      orderDetails: 'Sipariş Detayları',
      orderedOn: 'Şunun Üzerine Sipariş Verildi',
      showMore: 'Daha Fazla Göster',
      showLess: 'Daha Az Göster',
      subTotal: 'Ara Toplam',
      nA: 'Yok',
      addAWhatsappNumber: 'Whatsapp Numarası Ekle',
      addTelegramUserName: 'Telegram Kullanıcı Adı Ekleyin',
      addInstagramProfileId: 'Instagram Profil Kimliği Ekleyin',
      addFacebookProfileId: 'Facebook Profil Kimliği Ekleyin',
      add: 'Ekle',
      deviceProperties: 'Cihaz Özellikleri',
      privateNotes: 'Kişisel Notlar',
      urlsVisited: 'Ziyaret Edilen URL\'ler',
      source: 'Kaynak - ',
      sourceNa: 'Kaynak - Yok',
      medium: 'Orta - ',
      mediumNa: 'Orta - Yok',
      campaign: 'Kampanya - ',
      campaignNa: 'Kampanya - Yok',
      attributeKey: 'Özellik Anahtarı',
      attributeName: 'Özellik Adı',
      discard: 'At',
      addAttribute: 'Özellik Ekle',
      editAttribute: 'Özelliği Düzenle',
      deleteAttribute: 'Özelliği Sil',
      headsUp: 'Dikkat',
      areYouSureToDeleteAttribute: 'Bu özelliği silmek istediğinizden emin misiniz?',
      okay: 'Tamam',
      dataAddedToLead: `API'lerinize veya Bot Akışlarınıza eşlenen özellikler güncellenmeyecektir. Lütfen API'leri veya Bot Akışlarını buna `,
      assignedOnly: 'Yalnızca Atananlar',
      allUsers: 'Tüm Kullanıcılar',
      save: 'Kaydet',
      manageCustomAttributes: 'Özellikleri yönetmek',
      placeholders: {
        title: 'Başlık',
        egServices: 'Örnek: Hizmetler',
        egRequestedServices: 'Örnek: Talep Edilen Hizmetler',
        enterYourNotesHere: 'Notlarınızı buraya girin...',
      },
      bpDropDown: {
        selectBot: 'Bot Seçin',
        selectFlow: 'Akış Seçin',
        selectQuestion: 'Soru Seçin*',
      }
    },
    sendMessage: {
      send: 'Gönder',
      sendToAllChannels: 'Tüm kanallara gönder',
      email: 'E-posta',
      whatsapp: 'Whatsapp',
      telegram: 'Telegram',
      facebook: 'Facebook',
      sendSms: 'SMS Gönder',
      addNew: 'Yeni Ekle',
      addNewQuickReply: 'Yeni Hızlı Yanıt Ekle',
      discard: 'At',
      fromComputer: 'BİLGİSAYARDAN',
      add: 'Ekle',
      dragAndDropHereOr: 'Buraya sürükleyip bırakın veya',
      browseFiles: 'Dosyaları Gözat',
      caption: 'Başlık',
      templates: 'Şablonlar',
      addHeaderMedia: 'Başlık medyası ekle',
      selectFile: 'Dosya Seç',
      previousConversationClosed: 'Önceki konuşma kapatıldı!',
      sendTemplateMessage: 'Konuşmayı tekrar başlatmak için şablon mesajı gönderin.',
      selectTemplate: 'Şablon Seçin',
      conversationLocked: 'Konuşma kilitlendi!',
      demoBotOnlyAllowConversations: 'Demo bot, yalnızca önceden doğruladığınız numara ile konuşmaya izin verir.',
      placeholders: {
        searchQuickReplyByTitle: 'Başlıkla Hızlı Yanıt Ara',
        addTitle: 'Başlık Ekle',
        addYourRepliesHere: 'Yanıtlarınızı buraya ekleyin... ',
        addCaption: 'Başlık ekle',
        enterValueForThisVariable: 'Bu değişken için değer girin',
      },
      modal: {
        sendBulkMessages: 'Toplu Mesaj Gönder',
        send: 'Gönder',
        description: 'Mesajınız, kullanıcının hangi platforma ait olduğuna bağlı olarak temizlenecek ve gönderilecektir.' +
          '\n - Web sitesi kullanıcıları yalnızca metin tabanlı mesajları destekler \n - Whatsapp (abone olmayan) kişiler yalnızca şablon mesajlar alabilir'
      },
      noDataFound: {
        oops: 'Üzgünüz!',
        currentlyDoesNotSupportBulkMessages: 'Şu anda whatsapp botları için toplu mesajları desteklemiyoruz. Lütfen yayın özelliğini kullanın',
        quickReplies: 'Hızlı Yanıtlar',
        noQuickRepliesAddedYet: 'Henüz hızlı yanıt eklenmedi!',
      }
    },
    userList: {
      selectAll: 'Hepsini Seç',
      nA: 'Yok',
      loadMore: 'Daha Fazla Yükle',
    }
  },
  segments: {
    seeHowToUse: 'Nasıl kullanılacağını gör',
    help: 'Yardım',
    manageSegment: 'Grupi Yönet',
    manageYourSegmentsForMarketingAutomation: 'Pazarlama otomasyonu için gruplar yönetin',
    createNewSegment: 'Yeni Grup Oluştur',
    segmentName: 'Grup Adı',
    totalContacts: 'Toplam Kişiler',
    action: 'Eylem',
    createSegmentButton: 'Grup Oluştur',
    deleteSegments: 'Grupleri Sil',
    areYouSureToDeleteSegment: 'Bu Grupi silmek istediğinizden emin misiniz?',
    placeholders: {
      searchHere: 'Burada arayın',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'Henüz oluşturulmuş bir Grupiniz yok',
      allYourSegmentsAppearHere: 'Tüm Grupleriniz burada görünecektir'
    },
    modal: {
      exportData: 'Veriyi Dışa Aktar',
      exportDataBasedOnAppliedFilters: 'Uygulanan filtreler temelinde veriyi dışa aktar',
      export: 'Dışa Aktar',
    },
    appTooltip: {
      deleteSegments: 'Gruplar sil',
      editSegment: 'Grup Düzenle',
      downloadSegment: 'Grup İndir',
      deleteSegment: 'Grup Sil'
    },
    createSegment: {
      createSegment: 'Grup Oluştur',
      manageYourSegmentsForMarketingAutomation: 'Pazarlama otomasyonu için Gruplar yönetin',
      segmentName: 'Grup Adı',
      segmentNameIsRequired: 'Grup Adı gereklidir',
      selectContactsFilterForSegment: 'Grup için iletişim filtrelerini seçin',
      filterYourContacts: 'İletişimlerinizi filtreleyin',
      removeAllFilters: 'Tüm filtreleri kaldır',
      addFilters: 'Filtreler Ekle',
      discard: 'At',
      filterSelected: 'Seçilen Filtreler',
      placeholders: {
        enterSegmentName: 'Grup Adını Girin',
      }
    }
  },
  orders: {
    visitorName: 'Ziyaretçi Adı',
    email: 'E-posta',
    phoneNumber: 'Telefon Numarası',
    status: 'Durum',
    itemCount: 'Ürün Sayısı',
    total: 'Toplam',
    tag: 'Etiket',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'Henüz hiç siparişiniz yok',
      allOfYourOrdersAppearHere: 'Tüm siparişleriniz burada görünecektir'
    },
  }
};
