import { createAction, props } from '@ngrx/store';

export const loadOwnLiveChats = createAction(
  '[Live Chat] Load Live Chats',
  props<{ ownLiveChats: any[] }>()
);
export const loadMoreOwnLiveChats = createAction(
  '[Live Chat] Load More Live Chats',
  props<{ ownLiveChats: any[] }>()
);

export const removeOwnLiveChats = createAction(
  '[Live Chat] Remove Live Chats',
  props<{ uuid: string }>()
);

