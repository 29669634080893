export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Search'
    }
  },
  bpTable: {
    loadMore: 'Load More',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Search Country'
    }
  },
  createNew: {
    continue: 'Continue',
  },
  datePicker: {
    chooseADate: 'Choose a date',
  },
  dateRangePicker: {
    choosePeriod: 'Choose Period',
    today: 'Today',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Please provide the number you want to use for testing',
    resendOtp: 'Resend OTP',
    enterOtp: 'Enter OTP',
    verifyOtp: 'Verify OTP',
    sendOtp: 'Send OTP',
    cancel: 'Cancel',
    placeholders: {
      phone: 'Phone*',
      enterOtp: 'Enter OTP*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Please select your reason',
    cancel: 'Cancel',
    submit: 'Submit!',
    giveFeedback: 'Give feedback',
    ratings: 'Ratings',
    reasonBehindYourDecision: 'Reason behind your decision',
    shareYourExperience: 'Share your experience',
    reason1: 'reason1',
    reason2: 'reason2'
  },
  feedbackModal: {
    paymentIsInProgress: 'Payment is in progress',
    pleaseDoNotCloseThisWindow: 'Please do not close this window',
    placeholders: {
      name: 'Name',
      email: 'Email*',
      phone: 'Phone*',
      pleaseShareYourUseCase: 'Please share your usecase'
    }
  },
  layout: {
    sidebar: {
      notifications: 'Notifications',
      myAccount: 'My Account',
      logout: 'Logout',
      goOnline: 'Go Online',
      manage: 'Manage',
      deactivate: 'Deactivate',
      activate: 'Activate',
      delete: 'Delete',
      home: 'Home',
      bots: 'Bots',
      inbox: 'Inbox',
      analytics: 'Analytics',
      team: 'Team',
      subscriptions: 'Subscriptions',
      more: 'More',
      users: 'Users',
      partnerTeam: 'Partner Team',
      createNewBot: 'Create New Bot',
      dashboard: 'Dashboard',
      customers: 'Customers',
      plans: 'Plans',
      activities: 'Activities',
      feedbacks: 'Feedbacks',
      configurations: 'Configurations',
      copy: 'Copy',
      developer: 'Developers',
      yourFriendWillMissYou: 'Your friend will miss you',
      rateYourBotCreationExperience: 'Rate your bot creation experience',
      whatMadeYouDeleteYourBot: 'What made you delete your bot?',
      iCreatedByMistake: 'I created by mistake',
      iWantToCreateAnotherBot: 'I want to create another Bot',
      couldNotFindFeaturesThatINeeded: 'Couldn\'t find features that I need',
      uiWasHardToNavigate: 'UI was hard to navigate',
      iDonotWantToTell: 'I don\'t want to tell',
      other: 'Other',
      weWillDoOurBestToMakeAutomationFun: 'We will do our best to make automation fun and simple for everyone.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Toggle to allow or block live chat requests',
        TOOLTIP_NOTIFICATION: 'All notifications',
        TOOLTIP_MY_ACCOUNT: 'Your profile and password security',
        TOOLTIP_LOGOUT: 'Logout from the dashboard'
      },
      modal: {
        logout: 'Log out',
        botDeleteFeedbackForm: 'Bot Delete Feedback Form',
        areYouSureToLogOut: 'Are you sure you want to log out?',
        yesIWillBeBack: `Yes! I'll be back`,
        deleteBot: 'Delete Bot',
        areYouSureToDeleteBot: 'Are you sure you want to delete this Bot?',
        cancel: 'Cancel',
        saveAndDelete: 'Save and Delete'
      }
    }
  },
  modal: {
    cancel: 'Cancel',
    yesDelete: 'Yes, Delete!',
    continue: 'Continue'
  },
  unsavedChanges: {
    title: 'Unsaved changes',
    description: 'There might be unsaved changes. Are you sure you want to leave?'
  },
  notify: {
    youHave: 'You have ',
    new: ' new '
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'We suggest choosing your company logo. Add a PNG or JPG squared image under 1 MB.'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Oops! Baby plan offers ONLY ONE FREE bot. Please upgrade to King plan to create more bots.',
    noIAmGood: 'No, I am good',
    iWantToUpgrade: 'I want to Upgrade',
    oops: 'Oops! ',
    planOfferOnlyOneFreeBot: ' offers ONLY ONE FREE bot. Please upgrade to ',
    planToCreateMoreBots: ' to create more bots.'
  },
  webPreview: {
    preview: ' Preview',
    botsMessages: `Bot's Messages`,
    bot: 'Bot ',
    eleven36AM: ' 11:36 AM',
    usersReply: `User's Reply`,
    landingPageBot: 'Landing Page Bot',
    chatbot: 'Chatbot',
    chat: 'Chat',
    placeholders: {
      typeYourAnswer: 'Type your answer'
    }
  },
  paymentLink: {
    discardPayment: 'Discard Payment',
    completePayment: 'Complete Payment',
    importantUpdate: 'Important Update',
    accountUpdate: 'Account Update',
    youHave: 'You have',
    exceededTheMessagesLimit: 'exceeded the messages limit',
    ofYour: 'of your',
    planAnd: 'plan and',
    botsAreNoLongerActive: 'bots are no longer active',
    onYourWebsite: 'on your website.',
    youHaveEitherIncompletePaymentOn: 'You have either incomplete payment on',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'or you have not successfully upgraded your plan.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'or you have not successfully white-labeled your Bot.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'or you have not successfully purchased the “messages Add-on”.',
    incompletePaymentMessage: 'You have either incomplete payment on or you have not successfully upgraded your plan.',
    please: 'Please',
    complete: 'Complete',
    upgrade: 'Upgrade',
    services: 'services',
    yourPlanToContinueServices: 'your plan to continue services',
    yourPaymentToContinue: 'your payment to continue',
    forAnyQueryPleaseWriteUs: 'For any query please write us on',
    close: 'Close',
    writeOn: '',
    upgradePlan: 'Upgrade Plan',
    discardModal: {
      title: 'Discard Payment',
      description: 'Are you sure you want to discard your payment?',
      confirmText: 'Yes, Discard Payment'
    }
  },
  bpExport: {
    exportStatus: 'Export Status',
    tasksExport: 'Tasks- Export',
    download: 'Download',
    inProgress: 'in progress',
  }
};
