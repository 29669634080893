import { Action, createReducer, on } from '@ngrx/store';
import { loadSocialTools, addSocialTool, deleteSocialTool, updateSocialTool } from '../actions/social-tool.actions';
import { WhatsAppTool } from '../../models/whatsapp-tool';
import { Widget } from 'src/appv2/models/tool';


export const toolsFeatureKey = 'tools';


export const initialState: Widget[] = [];

const socialToolsReducer = createReducer(
  initialState,
  on(loadSocialTools, (state, { tools }) => tools),
  on(addSocialTool, (state, { tool }) => [...state, tool]),
  on(deleteSocialTool, (state, { tool }) => state.filter(item => item._id !== tool)),
  on(updateSocialTool, (state, { tool, update }) => state.map(item => {
    if (item._id === tool) {
      return { ...item, ...update };
    } else {
      return item;
    }
  })),
);

export function reducer(state: Widget[], action: Action) {
  return socialToolsReducer(state, action);
}
