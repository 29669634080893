export const analytics = {
  botWise: {
    botWiseAnalytics: 'Bot-Wise Analytics',
    currentBillingCycle: 'Huidige Factureringscyclus',
    currentMonth: 'Huidige Maand',
    sinceAccountCreation: 'Sinds Accountcreatie',
    messages: 'Berichten',
    leads: 'Leads',
    noDataFound: {
      botwiseAnalytics: 'Bot-Wise Analytics',
      noBotHasBeenCreatedYet: 'Er is nog geen bot aangemaakt!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Samenvatting van gegevens uit berichten en contacten/leads voor specifieke bots voor de huidige factureringscyclus, huidige maand of sinds accountcreatie (Wordt elke middernacht bijgewerkt)',
      TOOLTIP_FILTERS: 'Gegevens filteren voor geselecteerde bot',
      TOOLTIP_MESSAGES: 'Totaal aantal berichten dat door uw account is verbruikt sinds accountcreatie',
      TOOLTIP_LEADS: 'Totaal aantal leads vastgelegd door bots voor uw account sinds accountcreatie.',
    }

  },
  overview: {
    analytics: 'Analytics',
    seeHowToUse: 'Bekijk hoe te gebruiken',
    help: 'Help',
    overview: 'Overzicht',
    messages: 'Berichten',
    leads: 'Leads',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Analytics voor alle berichten en leads van uw bots. (Gegevens worden elke middernacht bijgewerkt)',
    }
  },
  summary: {
    analyticsSummary: 'Analytics Samenvatting',
    allBots: 'Alle Bots',
    daily: 'Dagelijks',
    weekly: 'Wekelijks',
    monthly: 'Maandelijks',
    messages: 'Berichten',
    leads: 'Leads',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Samenvatting van dagelijkse, wekelijkse of maandelijkse gegevens uit berichten en contacten/leads voor specifieke en alle bots (Wordt elke middernacht bijgewerkt)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp Analytics',
      month: 'Maand',
      week: 'Week',
      today: 'Vandaag',
      filters: 'Filters',
      clearAll: 'Alles wissen',
      apply: 'Toepassen',
      totalConversations: 'Totaal Gesprekken',
      freeConversations: 'Gratis Gesprekken',
      paidConversations: 'Betaalde Gesprekken',
      messageSent: 'Bericht Verzonden',
      messageDelivered: 'Bericht Afgeleverd',
      bpDropDown: {
        selectBot: 'Selecteer Bot',
      },
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Einddatum',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Gespreksanalyse',
      month: 'Maand',
      week: 'Week',
      today: 'Vandaag',
      totalConversations: 'Totaal Gesprekken',
      paidConversations: 'Betaalde Gesprekken',
      freeConversations: 'Gratis Gesprekken',
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Einddatum',
      },
      bpDropDown: {
        allBots: 'Alle bots'
      }
    },
    messageAnalytics: {
      whatsappMessages: 'WhatsApp Berichten',
      sent: 'Verzonden',
      delivered: 'Afgeleverd',
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Einddatum',
      },
      bpDropDown: {
        allBots: 'Alle bots'
      },
      month: 'Maand',
      week: 'Week',
      today: 'Vandaag',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'WhatsApp Berichten per Status',
      sent: 'Verzonden',
      delivered: 'Afgeleverd',
      indexMergeDatePicker: {
        startDate: 'Startdatum',
        endDate: 'Einddatum',
      },
      bpDropDown: {
        allBots: 'Alle bots'
      },
      month: 'Maand',
      week: 'Week',
      today: 'Vandaag',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Alle Abonnees',
      whatsappSubscribers: 'WhatsApp Abonnees'
    }
  }
};
