import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared.service';
import { BpInputComponent } from '../bp-input/bp-input.component';
import { LanguageService } from 'src/appv2/services/language.service';
declare let $: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnChanges, OnDestroy {

  @Input() title = '';
  @Input() showModal = false;
  @Input() showCloseButton = true;
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Submit!';
  @Input() firstHeading = 'Give feedback';
  @Input() seconHeading = 'Ratings';
  @Input() dropdownLabelText = 'Reason behind your decision';
  @Input() deletionReasons = [{ title: 'reason1' }, { title: 'reason2' }];
  @Input() textAreaPlaceholder = 'Share your experience';
  @Output() userInput: EventEmitter<any> = new EventEmitter();

  timeoutId: NodeJS.Timeout;
  ratingPoint = -1;
  description = '';
  reason = '';
  dropdownText = '';
  descriptionText = '';

  ratingSentiments = [
    'sentiment_very_dissatisfied',
    'sentiment_dissatisfied',
    'sentiment_neutral',
    'sentiment_satisfied',
    'sentiment_very_satisfied'
  ];

  @ViewChild(BpInputComponent, { static: false }) bpInputComponent: BpInputComponent;

  textArea: any;

  userId: any;
  paymentWindow: any;
  timer: any;
  connectFormErrors = {
    email: false,
    phone: false
  };
  staticText: any;

  subscribe$: Subscription;
  constructor(
    private sharedService: SharedService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.feedback;
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal?.currentValue) {
      return $(document.getElementById(this.title)).modal('show');
    }
    this.textArea = this.bpInputComponent?.textAreaRef?.nativeElement;
    return $(document.getElementById(this.title)).modal('hide');
  }

  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  reInitializeStaticText() {
    this.cancelText = this.staticText.cancel;
    this.confirmText = this.staticText.submit;
    this.firstHeading = this.staticText.giveFeedback;
    this.seconHeading = this.staticText.rating;
    this.dropdownLabelText = this.staticText.reasonBehindYourDecision;
    this.textAreaPlaceholder = this.staticText.shareYourExperience;
    this.deletionReasons = [{ title: this.staticText.reason1 }, { title: this.staticText.reason2 }];
  }

  action(status: boolean): void {
    if (((!this.descriptionText && this.dropdownText === 'Other') && status || (this.ratingPoint === -1) && status)) {
      this.sharedService.showToast('warning', '', 'Please give us your valuable feedback');
    } else if ((!this.dropdownText) && status) {
      this.sharedService.showToast('warning', '', 'Please tell us why you want to delete');
    } else {

      if (!status) {
        this.userInput.emit(status);
      } else {
        this.reason = this.dropdownText;
        this.description = this.descriptionText;
        this.userInput.emit(
          {
            rating: this.ratingPoint,
            description: this.description,
            reason: this.reason
          }
        );
      }
    }
  }

  inputChangeHandler(event: any) {
    this.descriptionText = event.target.value;
  }

  dropdownValue(event) {
    const data = event.item.title;
    if (data === 'Other') {
      this.dropdownText = data;
      this.focusTextArea();
    } else {
      this.dropdownText = data;
    }
  }

  focusTextArea() {
    this.timeoutId = setTimeout(() => {
      this.textArea = this.bpInputComponent?.textAreaRef?.nativeElement;
      this.textArea.focus();
    }, 200);
  }
}

