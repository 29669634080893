export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Buscar',
    }
  },
  bpTable: {
    loadMore: 'Carga más',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Búsqueda del país',
    }
  },
  createNew: {
    continue: 'Continuar',
  },
  datePicker: {
    chooseADate: 'Elija una fecha',
  },
  dateRangePicker: {
    choosePeriod: 'Elegir el período',
    today: 'Hoy',
    lastWeek: 'La semana pasada',
    lastMonth: 'El mes pasado',
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Proporcione el número que desea utilizar para las pruebas',
    resendOtp: 'Reiniciar OTP',
    enterOtp: 'Ingrese OTP',
    verifyOtp: 'Verificar OTP',
    sendOtp: 'Enviar OTP',
    cancel: 'Cancelar',
    placeholders: {
      phone: 'Teléfono*',
      enterOtp: 'Ingrese OTP*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Seleccione su razón',
    cancel: 'Cancelar',
    submit: '¡Entregar!',
    giveFeedback: 'Dar opinion',
    ratings: 'Calificaciones',
    reasonBehindYourDecision: 'Razón detrás de su decisión',
    shareYourExperience: 'Comparte tu experiencia',
    reason1: 'Razón1',
    reason2: 'Razón2',
  },
  feedbackModal: {
    paymentIsInProgress: 'El pago está en progreso',
    pleaseDoNotCloseThisWindow: 'Por favor no cierre esta ventana',
    placeholders: {
      name: 'Nombre',
      email: 'Correo electrónico*',
      phone: 'Teléfono*',
      pleaseShareYourUseCase: 'Por favor comparta su USECase',
    }
  },
  layout: {
    sidebar: {
      notifications: 'Notificaciones',
      myAccount: 'Mi cuenta',
      logout: 'Cerrar sesión',
      goOnline: 'Ir en línea',
      manage: 'Administrar',
      deactivate: 'Desactivar',
      activate: 'Activar',
      delete: 'Borrar',
      home: 'Hogar',
      bots: 'Bots',
      inbox: 'Bandeja de entrada',
      analytics: 'Analítica',
      team: 'Equipo',
      developer: 'Desarrollador',
      subscriptions: 'Suscripciones',
      more: 'Más',
      users: 'Usuarios',
      partnerTeam: 'Equipo de socios',
      createNewBot: 'Crea un nuevo bot',
      dashboard: 'Panel',
      customers: 'Clientes',
      plans: 'Planes',
      activities: 'Actividades',
      feedbacks: 'Retroalimentación',
      configurations: 'Configuraciones',
      copy: 'Copiar',
      yourFriendWillMissYou: 'Tu amigo te extrañará',
      rateYourBotCreationExperience: 'Califica tu experiencia de creación de bot',
      whatMadeYouDeleteYourBot: '¿Qué te hizo borrar tu bot?',
      iCreatedByMistake: 'Creé por error',
      iWantToCreateAnotherBot: 'Quiero crear otro bot',
      couldNotFindFeaturesThatINeeded: ',',
      uiWasHardToNavigate: 'UI fue difícil de navegar',
      iDonotWantToTell: ',',
      other: 'Otro',
      weWillDoOurBestToMakeAutomationFun: 'Haremos todo lo posible para que la automatización sea divertida y simple para todos.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Alternar para permitir o bloquear las solicitudes de chat en vivo',
        TOOLTIP_NOTIFICATION: 'Todas las notificaciones',
        TOOLTIP_MY_ACCOUNT: 'Su perfil y seguridad de contraseña',
        TOOLTIP_LOGOUT: 'Registro desde el tablero',
      },
      modal: {
        logout: 'Cerrar sesión',
        botDeleteFeedbackForm: 'BOT Eliminar el formulario de retroalimentación',
        areYouSureToLogOut: '¿Estás seguro de que quieres cerrar sesión?',
        yesIWillBeBack: '¡Sí! estaré detrás',
        deleteBot: 'Eliminar bot',
        areYouSureToDeleteBot: '¿Estás seguro de que quieres eliminar este bot?',
        cancel: 'Cancelar',
        saveAndDelete: 'Guardar y eliminar',
      }
    }
  },
  modal: {
    cancel: 'Cancelar',
    yesDelete: 'Sí, ¡Eliminar!',
    continue: 'Continuar'
  },
  unsavedChanges: {
    title: 'Cambios no guardados',
    description: 'Puede haber cambios no guardados. ¿Está seguro de que desea salir?'
  },
  notify: {
    youHave: 'Tienes',
    new: ' nuevo',
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Sugerimos elegir el logotipo de su empresa. Agregue una imagen cuadrada PNG o JPG bajo 1 MB.',
    }
  },
  upgradeModal: {
    upgradeToKingPlan: '¡Ups! Baby Plan ofrece solo un bot gratis. Actualice a King Plan para crear más bots.',
    noIAmGood: 'No, soy bueno',
    iWantToUpgrade: 'Quiero actualizar',
    oops: '¡Ups! ',
    planOfferOnlyOneFreeBot: ' OFRECE SOLO UN BOT GRATUITO. Por favor, actualice a ',
    planToCreateMoreBots: ' para crear más bots.'
  },
  webPreview: {
    preview: ' Avance',
    botsMessages: 'Mensajes de bots',
    bot: 'Bot',
    eleven36AM: '11:36 am',
    usersReply: 'Respuesta del usuario',
    landingPageBot: 'Bot de la página de destino',
    chatbot: 'Chatbot',
    chat: 'Charlar',
    placeholders: {
      typeYourAnswer: 'Escribe tu respuesta',
    }
  },
  paymentLink: {
    discardPayment: 'Descartar pago',
    completePayment: 'Finalizar pago',
    importantUpdate: 'Importante',
    accountUpdate: 'Actualización de cuenta',
    youHave: 'du hast',
    exceededTheMessagesLimit: 'excedido el límite de mensajes',
    ofYour: 'de tu',
    planAnd: 'planificar y',
    botsAreNoLongerActive: 'los bots ya no están activos',
    onYourWebsite: 'en tu sitio web.',
    youHaveEitherIncompletePaymentOn: 'Tienes un pago incompleto en',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'o no ha actualizado correctamente su plan.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'o no ha etiquetado correctamente su Bot.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'o no ha comprado con éxito el "Complemento de mensajes".',
    please: 'Por favor',
    complete: 'Completo',
    upgrade: 'Mejora',
    services: 'Servicios',
    yourPlanToContinueServices: 'Tu plan para continuar los servicios',
    yourPaymentToContinue: 'Tu pago para continuar',
    forAnyQueryPleaseWriteUs: 'Para cualquier consulta por favor escríbanos',
    close: 'Cerrar',
    writeOn: '',
    upgradePlan: 'Mejora el plan',
    discardModal: {
      title: 'Descartar pago',
      description: '¿Estás seguro de que quieres descartar el pago?',
      confirmText: 'Descartar',
    }
  },
  bpExport: {
    exportStatus: 'Estado de exportación',
    tasksExport: 'Tareas: exportación',
    download: 'Descargar',
    inProgress: 'en curso',
  }
};
