const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Olá,',
  greetingHand: '👋',
  upgrade: 'Atualizar',
  integrate: 'Integrar',
  integrate2: 'Integrar',
  chatGPT: 'Chatgpt',
  forYourBusiness: 'para seu negócio',
  insights: 'Percepções',
  message: 'Mensagens',
  addon: 'Adicionar:',
  leads: 'Pistas',
  yourBots: 'Seus bots',
  botName: 'Nome do bot',
  platform: 'Plataforma',
  contactOrLeads: 'Contatos/leads',
  actions: 'Ações',
  edit: 'editar',
  delete: 'excluir',
  createA: 'Criar uma',
  whatsappChatbot: 'Whatsapp chatbot',
  connect: 'Conectar',
  getStartedWithUs: 'Comece conosco 👏',
  howToBuildAGoodChatFlow: ' Como construir um bom fluxo de bate -papo',
  howToUseBotPenguinInboxAndItsFeatures: ' Como usar a caixa de entrada do BotPenguin e seus recursos',
  botPenguinPricing: ' Preços de BotPenguin: Explorando planos e recursos',
  openHelpDesk: ' Abra o Help Desk',
  helpAndSupport: 'Ajuda e suporte',
  extensiveGuides: 'Guias extensos para todas as suas consultas e fluxos de trabalho estão disponíveis em nosso',
  resourceCentre: 'Centro de recursos.',
  checkoutOurAnswers: 'Você pode conferir nossas respostas para as consultas comuns abaixo.',
  createANewBot: 'Crie um novo bot',
  installYourBot: 'Instale seu bot',
  plansAndPricing: 'Planos e preços',
  searchLanguage: 'Linguagem de pesquisa',
  whatsappConversations: 'Conversas do WhatsApp',
  diveDeeperIntoBotPenguin: 'Mergulhe mais fundo no botpenguin',
  knowWhereWeAreGoing: 'Saiba para onde estamos indo, as atualizações recentes e nos ajude a consertar alguns bugs.',
  productUpdates: 'Atualizações do produto',
  requestAFeature: 'Solicite um recurso',
  reportABug: 'Reportar um erro',
  productRoadmap: 'Roteiro de produtos',
  contactsOrLeads: 'Contatos/leads',
  english: 'Inglês',
  hindi: 'hindi',
  german: 'Alemão',
  french: 'Francês',
  portuguese: 'Português',
  spanish: 'Espanhol',
  italian: 'italiano',
  russian: 'russo',
  pleaseSelectYourReason: 'Selecione seu motivo',
  cancel: 'Cancelar',
  submit: 'Enviar!',
  giveFeedback: 'Dê retorno',
  ratings: 'Classificações',
  reasonBehindYourDecision: 'Razão por trás de sua decisão',
  shareYourExperience: 'Compartilhe sua experiência',
  reason1: 'Razão1',
  reason2: 'Razão2',
  appTooltip: {
    HOME_INSIGHT: 'Forneça informações sobre todas as mensagens e leads do seu bot (dados atualizados a cada meia -noite)',
    HOME_MESSAGES: 'Mensagens consumidas por sua conta durante esse ciclo de cobrança. (Mensagens do WhatsApp, Facebook e Bot Telegram são excluídas.)',
    HOME_TOTAL_MESSAGES: 'Total de mensagens consumidas por sua conta desde a criação da conta (as mensagens do WhatsApp, Facebook e Telegram Bot são excluídas.',
    LEADS: 'Leads capturados por bots para sua conta durante esse ciclo de cobrança.',
    TOTAL_LEADS: 'Total de contatos/leads capturados por bots para sua conta desde a criação da conta (dados atualizados a cada meia -noite).',
    YOUR_BOT: 'Visão geral de todos os seus bots e seu uso durante esse ciclo de cobrança.',
    NAME: 'Nome de Bot',
    PLATFORM: 'Plataforma de Bot',
    MESSAGES: 'As mensagens totais do BOT desde a criação de contas',
    CONTACTS: 'Total de contatos/leads do BOT',
    ACTIONS: 'Editar/excluir o bot',
    EDIT_ICON: 'Edite o fluxo de bate -papo de bot e as configurações',
    DELETE_ICON: 'Exclua o bot',
    TOOL_TIP_CLASSIC_VERSION: 'Versão antiga de $ {Brand} ui',
  },
  modal: {
    deleteBot: 'Exclua bot!',
    areYouSure: 'Tem certeza de que deseja excluir este bot?',
    enquiryForm: 'Formulário de consulta',
    payNow: 'Pague agora!',
    saveAndDelete: 'Salvar e excluir',
    submit: 'Enviar',
  },
  noDataFound: {
    bots: 'Bots',
    noBotCreatedYet: 'Não há bots criados ainda!',
  },
  yourFriendWillMissYou: 'Seu amigo vai sentir sua falta',
  rateYourBotCreationExperience: 'Avalie sua experiência de criação de bot',
  whatMadeYouDeleteYourBot: 'O que fez você excluir seu bot?',
  iCreatedByMistake: 'Eu criei por engano',
  iWantToCreateAnotherBot: 'Eu quero criar outro bot',
  couldNotFindFeaturesThatINeeded: ',',
  uiWasHardToNavigate: 'Ui era difícil de navegar',
  iDonotWantToTell: 'Não quero dizer',
  other: 'Outro',
  weWillDoOurBestToMakeAutomationFun: 'Faremos o possível para tornar a automação divertida e simples para todos.',
};
