export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'Un link de activare a fost trimis, vă rugăm să vă verificați adresa de email',
    weHaveSentActivationLinkToEmailAddress: `Am trimis un link de activare la adresa de email`,
    toVerifyYourAccount: `pentru a 'Verifica contul'.`,
    onceVerifiedAutomaticallyRedirected: 'Odată ce ați verificat, veți fi redirecționat automat către panoul de control.',
    didNotReceiveAnEmail: `Nu ați primit un email? Verificați folderul spam`,
    resendIn: 'Retrimiteți în ',
    seconds: ' secunde',
    resend: 'Retrimite',
    backToSignUp: 'Înapoi la Înregistrare',
    greatPleaseCheckYourMessage: 'Foarte bine, vă rugăm să verificați emailul',
    weHaveSentEmailTo: `Am trimis un email la`,
    withALinkToReset: ` cu un link pentru resetarea contului. Odată activat, veți putea finaliza crearea contului și începeți perioada gratuită.`,
    resent: 'Retrimis',
    backToLogin: 'Înapoi la Autentificare',
  },
  forgotPassword: {
    forgotPassword: 'Ați uitat parola?',
    noWorries: 'Nicio grijă,',
    weWillSendYouResetPasswordInstructions: `vă vom trimite instrucțiuni pentru resetarea parolei`,
    workEmail: 'Email de Serviciu',
    continue: 'Continuați',
    backToLogin: 'Înapoi la Autentificare',
    placeholders: {
      enterYourEmail: 'Introduceți Adresa de Email',
    }
  },
  logout: {
    logout: 'Deconectare',
    wantToLogOut: 'Sunteți sigur că doriți să vă deconectați?',
    yesIWillBeBack: `Da, voi reveni`,
    cancel: 'Anulare',
  },
  newPassword: {
    createNewPassword: 'Creați o nouă Parolă',
    yourNewPasswordMustBeDifferent: 'Noua dvs. parolă trebuie să fie diferită față de parolele anterioare utilizate.',
    password: 'Parolă',
    confirmPassword: 'Confirmare Parolă',
    setPassword: 'Setare Parolă',
    signUp: 'Înregistrare',
    login: 'Autentificare',
    backTo: 'Înapoi la ',
    setAPassword: 'Setați o Parolă',
    setNewPasswordToYourAccount: 'Setați o parolă nouă pentru contul dvs. în scopuri de securitate și confidențialitate',
    backToSignUp: 'Înapoi la Înregistrare',
    placeholders: {
      enterYourPassword: 'Introduceți Parola Dvs.',
    }
  },
  resetPassword: {
    passwordReset: 'Resetare Parolă',
    yourPasswordHasBeenSuccessfullyReset: 'Parola dvs. a fost resetată cu succes.',
    clickBelowToLogIn: 'Faceți clic mai jos pentru a vă autentifica',
    continueToLogin: 'Continuați la Autentificare',
  },
  newSignUp: {
    welcomeBack: 'Bun venit înapoi ',
    to: 'la',
    logIn: 'Autentificare ',
    as: 'ca ',
    withFacebook: 'cu Facebook',
    logInWithGoogle: 'Autentificare cu Google',
    or: 'SAU',
    pleaseFillAValidWorkEmail: 'Vă rugăm să completați o adresă de email validă de serviciu*',
    workEmailIsRequired: 'Emailul de serviciu este obligatoriu*',
    pleaseFillAValidPassword: 'Vă rugăm să completați o parolă validă*',
    passwordIsRequired: 'Parola este obligatorie*',
    rememberMe: 'Ține-mă minte',
    forgotPassword: 'Ați uitat parola?',
    login: 'Autentificare',
    doNotHaveAnAccount: `Nu aveți un cont?`,
    setUpAFreeAccount: 'Configurați un Cont GRATUIT',
    noCreditCardRequired: 'Nu este necesară o carte de credit',
    cancelAnytime: 'Anulați oricând',
    signUp: 'Înregistrare ',
    // as: 'as ',
    // withFacebook: 'with Facebook',
    signUpWithGoogle: 'Înregistrare cu Google',
    // or: 'OR',
    emailIsAlreadyRegistered: 'Adresa de email este deja înregistrată la noi, Vă rugăm să faceți clic pe Autentificare pentru a continua',
    phoneNumberShouldHaveMinimum7Digits: 'Numărul de telefon trebuie să aibă cel puțin 7 cifre*',
    phoneNumberIsRequired: 'Numărul de telefon este obligatoriu*',
    passwordMustContain: 'Parola trebuie să conțină',
    atleast6Characters: 'cel puțin 6 caractere, ',
    oneUpperCaseLetter: '1 literă majusculă ',
    oneSpecialCharacter: '1 caracter special*',
    alreadyHaveAccount: 'Deja aveți un cont?',
    signingUpToBotPenguinIsAConsentToOur: 'Înregistrarea pentru un cont BotPenguin este consimțământul dvs. la ',
    privacyPolicy: 'Politica de Confidențialitate',
    termsAndConditions: 'Termeni și Condiții',
    and: ' și ',
    english: 'Engleză',
    hindi: 'Hindi',
    german: 'Germană',
    french: 'Franceză',
    portuguese: 'Portugheză',
    spanish: 'Spaniolă',
    italian: 'Italiană',
    russian: 'Rusă',
    placeholders: {
      workEmail: 'Email de Serviciu*',
      password: 'Parolă*',
      phone: 'Telefon*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Dezabonare cu succes',
    yourEmail: 'Adresa ta de email ',
    haveBeenSuccessfullyUnsubscribedFrom: ' a fost dezabonată cu succes de la',
    backToLogin: 'Înapoi la autentificare'
  }
};
