export const notification = {
  notification: 'Уведомления',
  allNotifications: 'Все уведомления',
  general: 'Общий',
  settings: 'Настройки',
  newChatRequest: {
    youHaveANewChatRequest: 'У вас есть новый запрос в чате !!!',
    youHaveTransferredChatRequest: 'Вы передали запрос на чат !!!',
    acceptRequest: 'Принять запрос',
    discardRequest: 'Отбросить запрос',
  },
  notificationList: {
    name: 'Имя',
    message: 'Сообщение',
    noDataFound: {
      notification: 'Уведомление',
      noNotificationsFound: 'Уведомлений не найдено!',
    }
  },
  notificationSetting: {
    userMessage: 'Пользовательское сообщение',
    receiveNotificationsWhenUserSendMessage: 'Получать уведомления всякий раз, когда какой -либо пользователь отправляет какое -либо сообщение',
    liveChatRequest: 'Запрос в чате',
    receiveNotificationsWhenUserRequestLiveChat: 'Получать уведомления всякий раз, когда какой -либо запрос пользователя на живой чат',
    newLead: 'Новое лидерство',
    receiveNotificationsWhenNewLeadCreated: 'Получать уведомления всякий раз, когда создаются новые лидеры',
    assignedOnly: 'Назначен только',
    allUsers: 'Все пользователи',
    customizeWhichNotificationsYouWantToRecieve: 'Настройте, какие уведомления вы хотите получить',
    youCanCustomizeSettingForTheNotifications: 'Вы можете настроить настройку для уведомлений',
    receiveNotifications: 'Получить уведомления',
    desktopNotifications: 'Уведомления на рабочем столе',
    mobilePushNotifications: 'Мобильные уведомления Push',
    discard: 'Отказаться',
    update: 'Обновлять',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Отправить уведомления о новом сообщении по настольному столу',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Отправить уведомления о новом сообщении по мобильному',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Отправить уведомления о новом лидере по настольному столу',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Отправить уведомления о новом лидере по мобильному',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Отправить уведомления о живом чате по настольному столу',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Отправить уведомления о живом чате по мобильному',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Выберите, какие сообщения вы хотите получить уведомление',
    }
  }
};
