import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from 'src/appv2/services/language.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-bp-table',
  templateUrl: './bp-table.component.html',
  styleUrls: ['./bp-table.component.scss']
})
export class BpTableComponent implements OnInit {

  staticText: any;
  /**
   * Property to display checkboxes in table
   */
  @Input() public showCheckBox = false;

  /**
   * Property to emit event any action event is clicked
   */
  @Output() actionEvent = new EventEmitter();

  /**
   * Property to emit event any action event is clicked
   */
  @Output() toggleEvent = new EventEmitter();

  /**
   * Property to emit event any action event is clicked
   */
  @Output() checkboxEvent = new EventEmitter();

  /**
   * Property to emit event any action event is change
   */
  @Output() checkboxClickHandler = new EventEmitter();

  /**
   * Property to emit event when load more is clicked
   */
  @Output() loadMoreEvent = new EventEmitter();
  /**
   * Property to show load more button for pagination
   */
  @Input() isPaginationVisible = false;

  @Input() isTagsComponent = false;

  /**
   * Property to hold dummy values
   */
  @Input() tableData: any =
    {
      thead: [
        {
          id: 'name',
          text: 'Name',
          isSortable: true,
          type: 'inputText',
          isToolTip: false
        },
        {
          id: 'email',
          text: 'Email',
          isSortable: true,
          type: 'inputText',
          isToolTip: true
        },
        {
          id: 'read',
          text: 'Read',
          type: 'checkbox',
          isSortable: false,
        },
        {
          id: 'phoneNumber',
          text: 'Phone Number',
          isSortable: true,
          type: 'inputText'
        },
        {
          id: 'toggle',
          text: 'Active/Inactive',
          isSortable: true,
          type: 'toggle'
        },
        {
          id: 'actions',
          text: 'Actions',
          isSortable: false,
          type: 'actions',
          actions: ['edit', 'delete']
        }
      ],
      tbody: [
        {
          name: 'abc',
          email: 'abc@gmail.com',
          phoneNumber: '84823473',
          toggle: '',
          status: 'INACTIVE',
          read: true,
        },
        {
          name: 'xyz',
          email: 'zysj@gmail.com',
          phoneNumber: '1234567',
          toggle: '',
          status: 'ACTIVE',
          read: false
        },
        {
          name: 'kafj',
          email: 'lkajf@gmail.com',
          phoneNumber: '34567898765',
          toggle: '',
          status: 'ACTIVE',
          read: true
        },
      ],
    };
  /**
   * Property to hold dummy values for checkbox
   */
  @Input() selectedElements: string[] = [];

  constructor(
    private languageService: LanguageService,
    private sharedService: SharedService
  ) {
    this.staticText = languageService.getStaticText().shared.bpTable;
  }

  ngOnInit(): void {
  }

  public actionEventHandler(type, index) {
    this.actionEvent.emit({ type, index });
  }

  public toggleActionHandler(event, value, index) {
    this.toggleEvent.emit({ event, value, index });
  }

  public checkboxActionHandler(event, value, index) {
    this.checkboxEvent.emit({ event, value, index });
  }

  public checkboxHandler(id: string, type: string) {
    if (type === 'single') {
      if (this.selectedElements.includes(id)) {
        this.selectedElements = this.selectedElements.filter(i => i !== id);
      } else {
        this.selectedElements.push(id);
      }
    } else {
      if (this.selectedElements.length === this.tableData.tbody.length) {
        this.selectedElements = [];
      } else {
        this.selectedElements = [];
        if (this.isTagsComponent) {
          this.tableData.tbody.map((item: any) => this.selectedElements.push(item.tag));
        }
        else{
          this.tableData.tbody.map((item: any) => this.selectedElements.push(item._id));
        }
      }
    }
    this.checkboxClickHandler.emit(this.selectedElements);
  }

  paginateUsers() {
    this.loadMoreEvent.emit(true);
  }

  getActionIcon(action, i) {
    const { disabled } = this.tableData.tbody[i];
    switch (action) {
      case 'edit':
        return disabled ? 'assets/icons/disabled_picon.svg' : 'assets/icons/edit_icon.svg';
      case 'editTag':
        return 'assets/images/edit-tag.svg';
      case 'delete':
        return disabled ? 'assets/icons/delete_disable.svg' : 'assets/icons/delete_red.svg';
      case 'copy':
        return 'assets/icons/copy.svg';
      case 'settings':
        return 'assets/icons/Setting.svg';
      default:
        return '';
    }
  }
}
