export const team = {
  addMember: {
    team: 'チーム',
    seeHowToUse: '使い方を確認',
    help: 'ヘルプ',
    addAMember: 'メンバーを追加',
    addYourTeamMembersText: 'チームメンバーをサポートエージェントとして追加し、ボットユーザーとライブチャットできます。',
    name: '名前',
    nameIsRequired: '名前は必須です',
    email: 'メール',
    enterAValidEmail: '有効なメールアドレスを入力してください',
    password: 'パスワード',
    passwordMustContain: 'パスワードには含まれる必要があります',
    atleast6Characters: '少なくとも6文字、',
    oneUpperCaseLetter: '1つの大文字の文字',
    oneSpecialCharacter: '1つの特殊文字*',
    profileIcon: 'プロフィールアイコン',
    whatsappNumber: 'WhatsApp番号',
    leadVisibility: 'リードの可視性*',
    addMember: 'メンバーを追加',
    role: 'ロール',
    createdOn: '作成日',
    action: 'アクション',
    updateMember: 'メンバーを更新',
    add: '追加',
    cancel: 'キャンセル',
    selectVisibilityOption: '可視性オプションを選択',
    all: 'すべて',
    assigned: '割り当て済み',
    nA: 'N/A',
    admin: '管理者',
    agent: 'エージェント',
    placeholders: {
      enterMemberName: 'メンバー名を入力してください',
      enterMemberEmailAddress: 'メンバーのメールアドレスを入力してください',
      createPasswordForMember: 'メンバーのためにパスワードを作成',
      searchCountry: '国を検索',
      enterWhatsappNumber: 'WhatsApp番号を入力してください',
      selectLeadsVisibility: 'リードの可視性を選択',
      searchTeamByNameOrEmail: '名前またはメールアドレスでチームメンバーを検索',
    },
    modal: {
      deleteTeamMember: 'チームメンバーを削除',
      wantToDeleteThisTeamMember: 'このチームメンバーを削除してもよろしいですか？',
    },
    appTooltip: {
      clickToAddNewTeamMember: '新しいチームメンバーを追加するにはクリック',
      profileIconOfTeamMember: 'チームメンバーのプロフィールアイコン/画像',
      nameOfTeamMember: 'チームメンバーの名前',
      emailOfTeamMember: 'チームメンバーのメール',
      whatsappNumberOfTeamMember: 'チームメンバーのWhatsApp番号',
      roleOfTeamMemberAsAdminOrAgent: '管理者/エージェントとしてのチームメンバーの役割',
      dateAndTimeOfCreatingTeamMemberRole: 'チームメンバー役割の作成日時',
      editOrDeleteTeamMember: 'チームメンバーを編集または削除',
      editTeamMembersDetails: 'チームメンバーの詳細を編集します。',
      removeAccessOfTeamMemberByDeletingAccount: 'アカウントを削除してチームメンバーのアクセスを削除します',
    }
  },
  users: {
    manageMembers: 'メンバーを管理',
    managerMembersWhichCanAccessPanel: 'パートナーチームを管理し、パネルにアクセスできるようにします',
    createNewMember: '新しいメンバーを作成',
    createMember: 'メンバーを作成',
    updateMember: 'メンバーを更新',
    deleteTitleMember: 'メンバーを削除',
    deleteDescriptionMember: 'このメンバーを削除してもよろしいですか？',
    noMemberFound: 'メンバーが見つかりません！',
    titleMember: 'メンバーを作成',
    manageUsers: 'ユーザーを管理',
    createNewUser: '新しいユーザーを作成',
    managerUserWhichCanAccessPanel: 'パネルにアクセスできるユーザーを管理します',
    phoneNumber: '電話番号',
    discard: '破棄',
    createUser: 'ユーザーを作成',
    updateUser: 'ユーザーを更新',
    noUserFOund: 'ユーザーが見つかりません！',
    title: 'ユーザーを作成',
    profileIcon: 'プロフィールアイコン*',
    name: '名前*',
    email: 'メール*',
    password: 'パスワード*',
    selectRole: '役割を選択*',
    selectARole: '役割を選択',
    selectLeadVisibility: 'リードの可視性を選択*',
    confirmPassword: 'パスワードの確認*',
    tableHeadingName: '名前',
    tableHeadingEmail: 'メール',
    tableHeadingPhoneNumber: '電話番号',
    tableHeadingRole: '役割',
    tableHeadingStatus: 'ステータス',
    tableHeadingAction: 'アクション',
    deleteTitle: 'ユーザーを削除',
    deleteDescription: 'このユーザーを削除してもよろしいですか？',
    placeholders: {
      searchHere: 'ここで検索',
      enterName: '名前を入力してください',
      enterEmail: 'メールを入力してください',
      searchCountry: '国を検索',
      enterPhoneNumber: '電話番号を入力してください',
      enterPassword: 'パスワードを入力してください',
      enterConfirmPassword: 'パスワードの確認を入力してください'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'ユーザーを削除',
      TOOLTIP_DELETE_MEMBERS: 'メンバーを削除'
    }
  },
  roles: {
    manageRoles: '役割を管理',
    manageYourRoles: 'あなたの役割を管理します。',
    name: '名前*',
    description: '説明',
    discard: '破棄',
    createRole: '役割を作成',
    creteARole: '役割を作成',
    updateRole: '役割を更新',
    noUserFound: 'ユーザー役割が見つかりません！',
    assignPermissions: 'アクセス権を割り当てる*',
    tableHeadingName: '名前',
    tableHeadingDescription: '説明',
    tableHeadingStatus: 'ステータス',
    tableHeadingAction: 'アクション',
    module: 'モジュール',
    read: '読む',
    write: '書く',
    remove: '削除',
    notes: 'メモ',
    deleteTitle: '役割を削除',
    deleteDescription: '注意: この役割を削除すると、この役割が割り当てられたすべてのユーザーも削除されます。\nこの役割を削除してもよろしいですか？',
    deleteMemberDescription: '注意: この役割を削除すると、この役割が割り当てられたすべてのメンバーも削除されます。\nこの役割を削除してもよろしいですか？',
    placeholders: {
      enterRoleName: '役割の名前を入力してください',
      searchHere: 'ここで検索',
      enterDescriptionForRole: '役割の説明を入力してください'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'ユーザーのアクセスを許可またはブロックできるセクション',
      TOOLTIP_CLICK_TO_ADD_ROLE: '新しい役割を追加するにはクリック',
    }
  },
  accessControl: {
    users: 'ユーザー',
    partnerTeam: 'パートナーチーム',
    members: 'メンバー',
    roles: '役割'
  }
};
