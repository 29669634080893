export const subscription = {
  subscriptions: 'מנויים',
  plan: 'תוכנית',
  whatsappPlan: 'תוכנית WhatsApp',
  addOns: 'תוספות',
  billingDetails: 'פרטי חיוב',
  invoices: 'חשבוניות',
  wa1Reminder: 'תזכורת WA ראשונה',
  wa2Reminder: 'תזכורת WA שנייה',
  waRenewApi: 'רענון API של WA',
  waCheckUsageApi: 'בדיקת שימוש API של WA',
  waResetUsageApi: 'איפוס שימוש API של WA',
  waExceedUsageApi: 'חריגת שימוש ב-API של WA',
  waGetBotsInThisAccount: 'קבלת בוטים בחשבון זה',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'יש לנו את המחירים הכי זולים לכל צורכיך.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'תוכנית WhatsApp',
    TOOLTIP_ADDS_ON_HEADING: 'רכוש חבילות הודעות נוספות ובוטים מבריקים',
    TOOLTIP_ADDS_ON_MESSAGE: 'אם הגעת למגבלת ההודעות החודשית שלך, אפשר לרכוש חבילות הודעות נוספות.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'בדוק את פרטי המנוי הנוכחיים שלך',
    TOOLTIP_HORIZONTAL_BAR: 'בדוק את מחירי החודשיים או השנתיים שלנו לבין 3,000 עד 500,000 הודעות על ידי הזזת הבר משמאל לימין.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'עדכן את פרטי החיוב שלך לצורך הפקת חשבוניות.',
    TOOLTIP_INVOICES_HEADING: 'בדוק את החשבוניות הקודמות שלך.',
    TOOLTIP_INVOICES_ACTION: 'לחץ כדי לקבל את החשבונית בחשבון האימייל שלך'
  },
  currentPlan: {
    seeHowToUse: 'ראה כיצד להשתמש',
    help: 'עזרה',
    downgradeRequest1: 'הגשת בקשה לשיפור תוכנית המנוי שלך ל-',
    downgradeRequest2: 'הודעות. שים לב, תוכניתך תישנה עד סיום מחזור החיוב הנוכחי',
    downgradeRequest3: 'תוכל ליהנות מהשירותים הנוכחיים עד אז.',
    downgradeRequestMaster1: 'הגשת בקשה לשיפור התוכנית המקוצרת שלך ל-',
    downgradeRequestMaster2: ' שיחות. שים לב, התוכנית שלך תישנה עד סיום מחזור החיוב הנוכחי',
    downgradeRequestMaster3: 'תוכל ליהנות מהשירותים הנוכחיים עד אז.',
    downgradeRequestMaster4: 'הגשת בקשה לשיפור ה',
    downgradeRequestMaster5: ' שיחות. שים לב, התוכנית שלך תישנה עד סיום מחזור החיוב הנוכחי',
    active: 'פעיל',
    inactive: 'לא פעיל',
    month: 'חודש',
    year: 'שנה',
    messagePack: 'חבילת הודעות',
    baby: 'Baby',
    king: 'King',
    whatsapp: 'WhatsApp',
    youAreOn: 'אתה נמצא על',
    plan: 'תוכנית',
    totalMessagesLimit: 'גבול כללי של הודעות',
    liveAgentLimit: 'גבול של סוכנים חיים',
    perMonth: '/חודש',
    agents: 'סוכנים',
    messagesAddOn: 'תוספות להודעות',
    botWhitelabels: 'בוטים מותאמים',
    totalAddOnMessages: 'סך הכל הודעות נוספות',
    basePrice: 'מחיר בסיס',
    gst: 'מע"מ',
    totalPrice: 'מחיר כולל',
    totalAddOnPrice: 'מחיר כולל לתוספות',
    whitelabelPrice: 'מחיר לבוטים מותאמים',
    startedOn: 'התחיל בתאריך',
    nextDueOn: 'התשלום הבא בתאריך',
    addOnExpiry: 'תוקף התוסף',
    website: 'אתר אינטרנט',
    facebook: 'פייסבוק',
    telegram: 'טלגרם'
  },
  invoice: {
    invoiceNumber: 'מספר חשבונית',
    date: 'תאריך',
    invoiceAmount: 'סכום חשבונית',
    planAmount: 'סכום תוכנית',
    plan: 'תוכנית',
    messages: 'הודעות',
    action: 'פעולה',
    nA: 'N/A',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'לחץ לקבלת החשבונית בחשבון האימייל שלך',
      clickToDownloadInvoice: 'לחץ להורדת החשבונית בפורמט PDF',
    }
  },
  messageAddOn: {
    validity: 'תוקף',
    kingPlanHeader: '👑 תוכנית מלך',
    youAreOnThe: 'אתה נמצא על',
    asOfNowAndWillGet: 'עד כה ותקבל',
    messages: 'הודעות',
    inThisPlan: 'בתוכנית זו.',
    youCanBuyAdditionalMessaes: 'תוכל לרכוש הודעות נוספות על פי דרישות העסק שלך.',
    plan: 'תוכנית',
    baby: 'Baby',
    king: 'King',
    get: 'קבל ',
    babyPlan: 'Baby Plan ',
    upgradePlan: 'שדרג תוכנית',
    asOfNow: ' כעת.',
    pleaseUpgradeToKingPlan: 'נא לשדרג ל- ',
    toEnableAddOnsAndWhitelabelPacks: ' כדי לאפשר תוספות וחבילות בוט מותאמות.',
    messageAddOnPacks: 'חבילות תוספות להודעות',
    ifYouHaveReachedYourMonthlyMessageLimit: `אם הגעת למגבלת הודעות החודשית שלך, רכוש חבילות תוספות ותהנה משירותים בלתי מוגזמים.`,
    pleaseSelectNumberOfExpectedMessages: 'בחר את מספר ההודעות המצופות',
    addOnsValidity: 'תוקף התוסף',
    currentBillingCycle: 'מחזור חיוב נוכחי',
    oneYear: 'שנה אחת',
    summary: 'סיכום',
    messageCreditsWithKingPlan: 'אישורי הודעה עם תוכנית מלך',
    messageCreditsWith: 'הודעה קרדיטים עם',
    withPlan: '',
    billingCycle: 'מחזור חיוב',
    payableAmount: 'סכום לתשלום ',
    oneTimePayment: 'תשלום חד פעמי',
    plusTaxes: ' + מיסים',
    buyNow: 'רכוש עכשיו',
    whiteLabelChatWindow: 'חלון צ\'אט בלייבלים לבוטים',
    noBotsLeftToWhiteLabel: 'אין עוד בוטים להיות מצויינים בלייבלים.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'בחר את הבוטים שברצונך להיות מצויינים בלייבלים.',
    selectBots: 'בחר בוטים',
    bot: 'בוט',
    bots: 'בוטים',
    toBeWhiteLabelled: ' להיות מצויינים בלייבלים',
    remove: 'הסר את ',
    brandNameFromChatWindow: ' ם המותג מחלון הצ\'אט.',
    willBeValidTill: 'יתקיים תוך ',
    ourYearlyPrice: 'המחיר השנתי שלנו ',
    directWhiteLabel: 'מתווה לייבלים ישירים',
    whiteLabelledBots: 'בוטים מצויינים בלייבלים',
    nextRenewalColon: 'התחדשות הבאה: ',
    optOutRequested: 'התבקשה התנתקות',
    contactAgency: 'יצירת קשר עם סוכנות',
    optOut: 'התנתקות',
    removeWhiteLabel: 'הסר מציון בלייבל',
    numOfBots: 'מספר הבוטים',
    amount: 'סכום',
    taxes: 'מסים',
    totalAmount: 'סכום כולל',
    select: 'בחר',
    selectTheValidity: 'בחר תוקף',
    messageCount: 'כמות ההודעות',
    numberOfPacks: 'חבילות',
  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'פרטי תשלום',
    cardNumber: 'מספר כרטיס',
    cvv: 'CVV',
    month: 'חודש',
    year: 'שנה',
    save: 'שמור',
    add: 'הוסף',
    edit: 'ערוך',
    disclaimer: 'כתב ועד',
    disclaimerDescription1: 'פרטי תשלום זיהויים אלו יחולו רק למשתמשים מחוץ להודו.',
    disclaimerDescription2: 'אנו לא שומרים פרטי כרטיס האשראי שלך.' +
      'במקרה שתרצה לשנות את פרטי הכרטיס שלך, השינוי יתבצע ישירות בשער התשלום.',
    billingAddress: 'כתובת לחיוב',
    companyName: 'שם החברה',
    country: 'מדינה',
    selectCountry: 'בחר מדינה',
    stateOrRegion: 'מדינה/אזור',
    streetAddress: 'כתובת',
    gstIn: 'מספר ת.מ.ע',
    postalCode: 'מיקוד',
    cancel: 'ביטול',
    proceedToPay: 'המשך לתשלום',
    company: 'חברה',
    state: 'מדינה',
    masterCard: 'Master Card',
    yourCompanyName: 'שם החברה שלך',
    yourCountry: 'המדינה שלך',
    yourRegion: 'אזורך',
    yourPostalCode: 'מיקודך',
    plusTaxes: ' + מסים',
    dropDown: {
      month: 'חודש *',
      year: 'שנה *',
    },
    placeholders: {
      enterCardNumber: 'הזן מספר כרטיס',
      cvv: 'CVV',
      enterYourCompanyName: 'הזן את שם החברה שלך',
      enterYourStateOrRegion: 'הזן את המדינה/האזור שלך',
      enterYourStreetAddress: 'הזן את כתובת הרחוב שלך',
      enterYourGSTIN: 'הזן את מספר הת.מ.ע שלך',
      enterPostalCode: 'הזן את המיקוד שלך',
    }
  },
  plans: {
    viewDetailedPricing: 'צפייה במחירון מפורט',
    seeHowToUse: 'ראה איך להשתמש',
    help: 'עזרה',
    weHaveGotAPricingPlanPerfectForYou: 'יש לנו תוכנית מחירים שמושלמת עבורך',
    weBelieve: 'אנחנו מאמינים',
    shouldBeAccessibleToAllCompanies: 'צריך להיות זמין לכל החברות, לא משנה בגודלן.',
    monthly: 'חודשי',
    yearly: 'שנתי',
    twoMonthsFree: '(2 חודשים בחינם)',
    babyPlan: 'תוכנית תינוק',
    freeForever: 'חינם לצמיתות',
    messagesPerMonth: 'הודעות לחודש',
    currentPlan: 'תוכנית נוכחית',
    freePlan: 'תוכנית חינמית',
    kingPlan: 'תוכנית מלך',
    perYear: 'לשנה',
    plan: 'תוכנית',
    toThe: ' אל ',
    plusTaxes: ' + מיסים',
    perMonth: 'לחודש',
    upgradePlan: 'שדרוג תוכנית',
    changePlan: 'שינוי תוכנית',
    currentlyActive: 'פעיל כרגע',
    emperorPlan: 'תוכנית הקיסר (עסקים)',
    sendAsManyMessagesAsYouWant: 'שלח כמה הודעות שתרצה',
    contactUs: 'צור קשר',
    features: 'תכונות',
    areYouSureYouWantToShiftFrom: 'האם אתה בטוח שברצונך להחליף מה',
    messages: 'הודעות',
    planQuestionMark: 'תוכנית?',
    downgradeToFreePlan: 'שימור לתוכנית חינמית',
    goToBabyPlan: 'עבור לתוכנית תינוק',
    thankYouForChoosingBotPenguin: 'תודה שבחרת ב-BotPenguin',
    bots: 'בוטים',
    facebookPages: 'דפים בפייסבוק',
    teamMember: 'חבר צוות',
    dataStorage: 'אחסון נתונים',
    conditionalFlow: 'זרימת תנאי',
    liveChat: 'צ\'אט חי',
    bookAppointments: 'קביעת פגישות',
    csvExport: 'ייצוא CSV',
    emailAndCallSupport: 'תמיכת דואר אלקטרוני ושיחות',
    googleCalendarIntegrations: 'אינטגרציה עם לוח שנה של Google',
    zohoCrmIntegrations: 'אינטגרציה עם Zoho CRM',
    reportsAndAnalytics: 'דוחות ונתוני ניתוב',
    removeBotPenguinBranding: 'הסרת הסימון של BotPenguin',
    oneBot: 'בוט אחד',
    twoKMessagesPerMonth: '2,000 הודעות לחודש',
    oneFacebookPage: 'דף פייסבוק אחד',
    oneTeamMember: 'חבר צוות אחד',
    thirtyDaysOfDataStorage: '30 ימי אחסון נתונים',
    unlimitedBot: 'בוט בלתי מוגבל',
    threeTo500kMessagesPerMonth: '3,000 עד 500,000 הודעות לחודש',
    tenFacebookPages: '10 דפים בפייסבוק',
    threeTeamMembers: 'שלושה חברי צוות',
    nintyDaysOfDataStorage: '90 ימי אחסון נתונים',
    unlimitedMessages: 'הודעות בלתי מוגבלות',
    unlimitedFacebookPages: 'דפים בפייסבוק בלתי מוגבלים',
    unlimitedTeamMembers: 'חברי צוות בלתי מוגבלים',
    unlimitedDataStorage: 'אחסון נתונים בלתי מוגבל',
    youAreHere: 'אתה כאן!',
    detailedPricing: {
      kingsplan: 'תוכנית מלך',
      elementary: 'יסודי',
      solutionType: 'סוג פתרון',
      saas: 'SaaS',
      fbpage1fb: 'דף FB אחד',
      perMonth100: '100 לחודש',
      enterprise: 'עסקי',
      saasOnPremises: 'SaaS באתר',
      aiChatbotFeatures: '\'תכונות בוט צ\'אט ברמת AI\'',
      dataSourceForTraning: 'מקור נתונים לאימון',
      smartFeatures: 'תכונות חכמות',
      liveChat: 'צ׳אט חי',
      day30: '30 ימים',
      day90: '90 ימים',
      landSquared: 'Landsquared',
      doItYourself: 'עשה זאת בעצמך',
      unifiedInbox: 'תיק דואר מאוחד',
      platforms: 'פלטפורמות',
      websiteChatbot: '\'בוט צ\'אט לאתר אינטרנט\'',
      FacebookMessengerChatbot: '\'בוט צ\'אט בפייסבוק מסנג\'ר\'',
      Telegram: 'טלגרם',
      conversationalLandingPage: 'עמוד נחיתה שיחתי',
      integrations: 'אינטגרציות',
      reportsAndAnalytics: 'דוחות ונתונים ניתוביים',
      customerSupport: 'תמיכת לקוחות',
      security: 'אבטחה',
      bots: 'בוטים',
      babyPlan: 'תוכנית תינוק',
      kingPlan: 'תוכנית מלך',
      emperorPlan: 'תוכנית הקיסר',
      noofBots: 'מספר בוטים',
      one: '1 (אחד)',
      unlimitedBots: 'בוטים בלתי מוגבלים',
      noOfWebsite: 'מספר אתרים',
      unlimitedWebsites: 'אתרים בלתי מוגבלים',
      unlimitedMessages: 'הודעות בלתי מוגבלות',
      messagesSend: 'שליחת הודעות',
      perMonth2000: '2,000 לחודש',
      upto500000perMonth: 'עד 500,000 לחודש',
      facebookMessenger: '\'פייסבוק מסנג\'ר\'',
      unlimitedPages: 'דפים בלתי מוגבלים',
      telegramNumbers: 'מספרי טלגרם',
      unlimited: 'בלתי מוגבל',
      whatsappNumbers: 'מספרי WhatsApp',
      subscribersLeads: 'מנויים/לידים',
      createSupportTeam: 'יצירת צוות תמיכה לצ\'אט חי',
      oneMember: 'חבר צוות 1',
      threeMembers: 'חברי צוות 3',
      conversationHistory: 'היסטוריית השיחה',
      thirtyDays: '30 ימים',
      ninetyDays: '90 ימים',
      chatbotDesigning: 'עיצוב בוט צ\'אט',
      weAreHereToHelp: 'אנחנו כאן כדי לעזור',
      weTakeCareEndToEnd: 'אנחנו טופלים מקצה לקצה',
      multipleLanguages: 'שפות מרובות',
      conditionalFlow: 'זרימת תנאי',
      removeBotPenguinBranding: 'הסרת הסימון של BotPenguin',
      gptMessages: 'הודעות GPT',
      aiChatbot: 'בוט צ\'אט AI',
      chatGPTIntegration: 'אינטגרציה של ChatGPT',
      configureAIChatbotPersonality: 'הגדרת האישיות של בוט צ\'אט AI',
      trainChatbotOnYourOwnData: 'אימון של בוט צ\'אט על נתונים שלך',
      trainChatbotOnWebPages: 'אימון בוט צ\'אט על עמודי רשת',
      trainChatbotOnMultipleFiles: 'אימון בוט צ\'אט על קבצים מרובים',
      trainChatbotOnFAQs: 'אימון בוט צ\'אט על שאלות נפוצות',
      dataTrainingStorage: 'אחסון נתוני אימון',
      useChatGPTModel4_3_5Turbo: 'שימוש במודל ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'העלאת קבצים מרובים לאימון בוט צ\'אט',
      naturalLanguageProcessing: 'עיבוד שפה טבעית',
      naturalLanguageUnderstanding: 'הבנת שפה טבעית',
      answerLength: 'אורך התשובה',
      chatTone: 'טון הצ\'אט',
      multiLanguage: 'שפות מרובות',
      answerFormatting: 'עיצוב תשובה',
      customPrompt: 'שאלה מותאמת אישית',
      integrateBotPenguinLLM: 'אינטגרציה של BotPenguin LLM',
      integrateOwnCustomLLM: 'אינטגרציה מותאמת אישית של LLM המשתמש',
      periodicallySyncDataSources: 'סנכרון תדיר של מקורות נתונים',
      aiBasedSuggestedQuestions: 'שאלות מומלצות בשימוש בהם AI',
      chooseVisibilityForParticularWebpageSitemap: 'בחר גילויות לעמוד וסיכום מפה מסוימים',
      chooseKnowledgeMix: 'בחר במיקס של ידע:\nבסיס הידע שלך\nידע של ChatGPT\n"ידע של ChatGPT + בסיס הידע שלך"',
      faqExtractionFromFileAndURL: 'חילוץ שאלות נפוצות מקובץ וכתובת URL',
      analyticsToImproveAI: 'נתוני ניתוב לשיפור התוכנה המבוססת על AI',
      exportQuestionFlow: 'יצוא זרימת שאלות',
      sessionManagement: 'ניהול ישיבות',
      collectFeedbackOnChatbotResponses: 'איסוף משוב על תגובות של הבוט',
      smartAIFollowUpQuestions: 'שאלות פרטיות אחריות מבוססות AI',
      trainOnYourNotionWorkspaceData: 'אימון בהתבסס על נתוני משרד העבודה שלך',
      trainOnYoutubeVideo: 'אימון על סרטוני YouTube',
      trainOnCustomVideoUpload: 'אימון על העלאת סרטון מותאמת אישית',
      rssFeed: 'הזנת RSS',
      googleDrive: 'גוגל דרייב',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'חסימת IP ואזור',
      emailAndMobileValidation: 'אימות אימייל וטלפון נייד',
      appointmentsBooking: 'קביעת פגישות',
      humanHandover: 'העברת אנושיות',
      botHandover: 'העברת בוט',
      handoverBetweenAgents: 'העברה בין סוכנים',
      mobileAppsForAgents: 'אפליקציות ניידות לסוכנים',
      roleBasedAccessControls: 'בקרת גישה בהתבסס על תפקידים',
      apiIntegrationOnChatFlows: 'אינטגרציה ב- API על הזרמים של הצ\'אט',
      liveChatWidget: 'ווידג\'ט של צ\'אט חי',
      typingIndicator: 'אינדיקטור להקלדה',
      liveVisitorsDetails: 'פרטי מבקרים חיים',
      multipleSimultaneousChat: 'צ\'אט רב-זמני מרובה',
      liveTypingView: 'תצוגת הקלדה חיה',
      ruleBasedChatRouting: 'ניתוב שיחות בהתבסס על כללים',
      chatAutoAssignments: 'הקצאות אוטומטיות בצ\'אט',
      efficientOmnichannelInterface: 'ממשק Omnichannel יעיל',
      quickReplies: 'תגובות מהירות',
      unifiedCustomerProfile: 'פרופיל לקוח מאוחד',
      visitorStats: 'סטטיסטיקות מבקרים',
      addContactsFromWhatsapp: 'הוסף אנשי קשר מ-WhatsApp',
      applyFilterAndSortConversations: 'החל מסננים ומיין שיחות',
      seeChatLogs: 'צפה בלוגי השיחה',
      assignNotesAndChatTags: 'הקצה הערות ותגי שיחה',
      directCallsToLeadsWithTwilio: 'שיחות ישירות ללידים עם Twilio',
      customerSegmentation: 'סינון לקוחות',
      exportLeadsInCSV: 'ייצוא לידים בפורמט CSV',
      leadNotificationsByEmail: 'הודעות לידים באימייל',
      website: 'אתר אינטרנט',
      wordPress: 'וורדפרס',
      whatsApp: 'WhatsApp',
      facebook: 'פייסבוק',
      telegram: 'טלגרם',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'הפעלה אוטומטית במובייל ובמחשב שולחני',
      hideBotOnSpecificPages: 'הסתרת הבוט בעמודים מסוימים',
      imageSharing: 'שיתוף תמונות',
      readyToUseChatFlowTemplates: 'תבניות זרימות צ\'אט מוכנות לשימוש',
      fileSharing: 'שיתוף קבצים',
      customDesignForChatWindow: 'עיצוב מותאם אישית לחלון הצ\'אט',
      facebookMessengerBot: '\'בוט בפייסבוק מסנג\'ר\'',
      facebookSubheading: 'כותרת משנית של פייסבוק',
      telegramSubheading: 'כותרת משנית של טלגרם',
      conversationalLangdingPageSubheading: 'כותרת משנית של דף נחיתה שיחתי',
      integrationsSubheading: 'כותרת משנית של אינטגרציות',
      multipleSmartFlows: 'זרמים חכמים מרובים',
      dripCampaigns: 'קמפיינים בשטיפה',
      broadcastFBMessages: 'שידור הודעות בפייסבוק',
      multipleFacebookBotWidgets: 'ווידג\'טים מרובים לבוט בפייסבוק',
      facebookAutoReplyOnComments: 'תגובה אוטומטית לתגובות בפייסבוק',
      facebookECommerce: 'מסחר אלקטרוני בפייסבוק',
      broadcastAnalytics: 'נתוני ניתוב לשידור',
      telegramMessengerBot: 'בוט בטלגרם מסנג\'ר',
      readyToUseTemplates: 'תבניות מוכנות לשימוש',
      groupBroadcastTelegramMessages: 'שידור הודעות טלגרם בקבוצה',
      multipleTelegramBotWidgets: 'ווידג\'טים מרובים לבוט בטלגרם',
      conversationalLandingPageBot: 'בוט על דף נחיתה שיחתי',
      smartReplies: 'תגובות חכמות',
      interactiveAndCustomizableUI: 'ממשק אינטראקטיבי וניתן להתאמה',
      leadManagement: 'ניהול לידים',
      easyToInstallAndShare: 'התקנה פשוטה ושיתוף',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'Leadsquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'סגור',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'גוגל Sheets',
      googleCalendar: 'לוח שנה של גוגל',
      googleAnalytics: 'גוגל Analytics',
      supportTicketingSystem: 'מערכת לניהול כרטיסי תמיכה',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'שיחות אודיו ווידאו בצ\'אט',
      courierTracking: 'מעקב אחר משלוחים',
      emailIntegration: 'אינטגרציה עם אימייל',
      webhooks: 'וובהוקס',
      schedulers: 'זמנים',
      paymentGateways: 'שערי תשלום (Stripe & RazorPay)',
      enterpriseIntegrations: 'אינטגרציות לעסקיים',
      customIntegration: 'אינטגרציה מותאמת אישית',
      shopifyIntegration: 'אינטגרציה עם Shopify',
      totalNumberOfMessages: 'סך הכל הודעות',
      totalNumberOfLeads: 'סך הכל לידים',
      weeklyReportBotwise: 'דוח שבועי, לפי בוטים',
      averageAgentResponeTime: 'זמן תגובה ממוצע של הסוכנים',
      visitorTimingReport: 'דוח זמני המבקרים',
      customerSegmentBasedReports: 'דוחות לפי קבוצות לקוחות',
      tagBasedReports: 'דוחות לפי תגיות',
      leadGenerationReport: 'דוח לגבי יצירת לידים',
      supportOnWhatsApp: 'תמיכה ב-WhatsApp',
      knowledgeBase: 'בסיס ידע',
      onboardingAndTraining: 'הכשרת העובדים והכנסתם',
      callAndEmailSupport: 'תמיכה בשיחות ובאימייל',
      freeChatbotCreation: 'יצירת בוט בחינם',
      customerSuccessOfficer: 'איש הצלחת הלקוח',
      secureConnection2048BitSSL: 'חיבור מאובטח ברזולוציה של 2048 ביט SSL',
      securityAssessment: 'מדידת אבטחה',
      dataCenterInUSOrEU: 'מרכז נתונים בארצות הברית או באיחוד האירופי',
      twoStepVerification: 'וודאות בשני צעדים',
      accessRestrictionsBasedOnIPs: 'הגבלות גישה בהתבסס על כתובות IP',
      onDemandAuditLog: 'יומן רישון לפי דרישה',
      bannedVisitorsList: 'רשימת מבקרים מושבתים',
      customAPIRate: 'שיעור API מותאם אישית',
      trustedDomains: 'תחומים אמינים',
      mb100: '100 מגה-בייט',
      gb1: '1 גיגה-בייט',
      codingCurious: 'קוריאוזיות תכנות',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: 'המסלול החינמי שלנו',
      TOOLTIP_KING_PLAN: 'המסלול הכי פופולרי שלנו',
      TOOLTIP_EMPEROR_PLAN: 'מסלול אישי להודעות בלתי מוגבלות, אחסון מידע בלתי מוגבל וחברי צוות בלתי מוגבלים',
      TOOLTIP_CONTACT_US: 'צור קשר איתנו דרך WhatsApp או באמייל contact@botpenguin.com כדי לקבל פרטים נוספים על המסלול האישי שלנו',
      TOOLTIP_UPGRADE: 'שדרג את המסלול הנוכחי שלך כדי לקבל תכונות נוספות',
      TOOLTIP_MONTHLY: 'בחר מחזור חודשי לתשלומים',
      TOOLTIP_YEARLY: 'בחר מחזור שנתי לתשלומים כדי לקבל הנחה',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'עבור למסלול Baby',
    freeForever: 'חינם לנצח',
    features: 'תכונות',
    currentPlan: 'מסלול נוכחי',
    changePlan: 'שנה מסלול',
    currentlyActive: 'פעיל כרגע',
    seeHowToUse: 'ראה איך להשתמש',
    weHaveGotPricingPerfectForYou: `יש לנו מסלול מחירים המושלם עבורך`,
    weBelieve: 'אנחנו מאמינים ש-',
    shouldBeAccessibleToAllCompanies: 'צריך להיות נגיע לכל החברות, לא משנה בגודלן.',
    monthly: 'חודשי',
    yearly: 'שנתי',
    twoMonthsFree: '(2 חודשים חינם)',
    kingPlan: 'מסלול מלכה',
    perYear: 'לשנה',
    perMonth: 'לחודש',
    conversationsPerMonth: 'שיחות לחודש',
    upgradePlan: 'שדרג מסלול',
    emperorPlan: 'מסלול אימפריה (עסקים)',
    haveAsManyConversationsAsYouNeed: 'יש לך את כמות השיחות שאתה צריך',
    contactUs: 'צור קשר',
    metaConversationChargesAreAdditional: 'תשלום נוסף לשיחות מטא (Meta) ישירות ממך על פי השימוש שלך. אנחנו לא גובים תוספת או עמלות.',
    viewDetailedPricing: 'הצג מחירים מפורטים',
    conversationPricingModelUsedByMeta: 'דוגמת מחיר השיחות בשימוש על ידי מטא (Meta)',
    whatsappBusinessPlatformConversationsFallInto: 'השיחות בפלטפורמת WhatsApp Business נכנסות לשתי קטגוריות שמתבצעות בהן במחירים שונים',
    userInitiatedConversation: 'שיחה שמתבצעת בתגובה להודעה של משתמש. בכל פעם שעסק עונה להודעה של משתמש בתוך חלון השירות לקוח 24 שעות, ההודעה תתייחס כשיחה שמתבצעת בתגובה למשתמש. עסקים יכולים לשלוח הודעות חופשיות במסגרת חלון השירות לקוח של 24 שעות.',
    businessInitiatedConversation: 'שיחה שמתבצעת על ידי העסק במשליחת הודעה למשתמש מחוץ לחלון השירות לקוח 24 שעות. הודעות שמתבצעות במסגרת שיחה שמתבצעת באמצעות העסק דורשות שימוש בתבנית הודעה.',
    areYouSureYouWantTo: 'האם אתה בטוח שברוצך לעבור מ-',
    conversations: ' שיחות ',
    plan: ' מסלול',
    toThe: ' ל-',
    metaPricingMarkup: 'תיווך מחיר מטא (Meta)',
    numberOfChatFlows: 'מספר שיחות מרובות',
    greenTickApplication: 'הגשת בקשת סימון ירוק',
    extraPhoneNumberLinking: 'קישור טלפון נוסף',
    teamMembers: 'חברי צוות',
    dataStorage: 'אחסון מידע',
    conditionalFlow: 'זרימה תנאיתית',
    liveChat: 'צ\'אט חי',
    smartInbox: 'דואר נכנס חכם',
    whatsappTemplates: 'תבניות WhatsApp',
    customerSegments: 'קבוצה של לקוחות',
    broadcastAndDripCampaigns: 'שדרוג וקמפיינים טפטפה',
    multipleLanguages: 'שפות מרובות',
    bookAppointments: 'קביעת פגישות',
    csvContactsExport: 'ייצוא אנשי קשר בפורמט CSV',
    googleCalendarIntegrations: 'אינטגרציה עם לוח שנה של Google',
    nativeApplications: '60+ אינטגרציות מותאמות אישית',
    whatsappSupport: 'תמיכה ב-WhatsApp',
    emailAndCallSupport: 'תמיכה בדוא"ל ובשיחות',
    customerIntegrations: 'אינטגרציות לקוח',
    nlpAndNluCapabilities: 'יכולות NLP ו-NLU',
    chatGPTBot: 'רובוט ChatGPT',
    customerSuccessOfficer: 'סוחר תמיכה בלקוח',
    noMarkupTransparency: 'גיוון ללא שימור',
    upto500kPerMonth: 'עד 500,000 לחודש',
    beyond500k: 'מעל 500,000',
    unlimited: 'בלתי מוגבל',
    oneTeamMember: '1 חבר צוות',
    unlimitedTeamMember: 'חברי צוות בלתי מוגבלים',
    nintyDaysOfDataStorage: '90 יום אחסון מידע',
    unlimitedDataStorage: 'אחסון מידע בלתי מוגבל',
    downgradeToFreePlan: 'שדרג למסלול חינם',
    detailedPricing: {
      onDemandAuditLog: 'לוג חשבון בדרישה',
      accessRestrictionsBasedOnIPs: 'הגבלות גישה בהתבסס על כתובות IP',
      averageAgentResponeTime: 'זמן תגובת הסוכן הממוצע',
      perMonth100: '100 לחודש',
      solutionType: 'סוג הפתרון',
      saas: 'SaaS',
      saasOnPremises: 'SaaS באתר',
      emperorPlan: 'תוכנית הקיסר',
      babyPlan: 'תוכנית התינוק',
      kingsplan: 'תוכנית המלכים',
      braodcastsAndDrips: 'שידורים ונטילות',
      thirtyDays: '30 ימים',
      ninetyDays: '90 ימים',
      oneMember: 'חבר אחד',
      threeMembers: '3 חברים',
      one: '1 (אחד)',
      unlimitedMessages: 'הודעות בלתי מוגבלות',
      unlimitedBots: 'בוטים בלתי מוגבלים',
      messagesSend: 'שליחת הודעות',
      upto500000perMonth: 'עד 500,000 לחודש',
      unlimited: 'בלתי מוגבל',
      onlyAdmin: 'מנהל בלבד',
      mb100: '100 מגה-בייט',
      gb1: '1 גיגה-בייט',
      doItYourself: 'עשה זאת בעצמך',
      weAreHereToHelp: 'אנחנו כאן לעזור',
      weTakeCareEndToEnd: 'אנחנו דואגים מראש ועד סוף',
      numberOfBots: 'מספר בוטים',
      conversations: 'שיחות',
      gptMessages: 'הודעות GPT',
      whatsappNumbers: 'מספרי WhatsApp',
      subscribersLeads: 'מנויים / לידים',
      createSupportTeamForLiveChat: '\'יצירת צוות תמיכה לצ\'אט חי\'',
      conversationHistory: 'היסטוריית השיחה',
      chatbotDesigning: 'עיצוב צ\'אטבוט',
      multipleLanguages: 'שפות מרובות',
      conditionalFlow: 'זרימה תנאיתית',
      whatsappTemplates: 'תבניות WhatsApp',
      restartChatFlowTriggers: 'מפעילי נגיעה לשוב לשוב הזרימה',
      multipleWhatsAppWidgets: 'ווידג\'טים מרובים של WhatsApp',
      importWhatsappContacts: 'ייבוא אנשי קשר מ-WhatsApp',
      whatsappQuickReply: 'תשובה מהירה ב-WhatsApp',
      whatsappSmartInbox: 'תיבת הדואר החכמה של WhatsApp',
      whatsappEcommerceCatalogs: 'מסמרי E-commerce של WhatsApp עם קטלוגים',
      whatsappBroadcast: 'שידור WhatsApp',
      whatsappBroadcastAnalytics: 'ניתוחי שידור WhatsApp',
      dripCampaigns: 'קמפיינים טיפה',
      customerSegmentation: 'פיצול לקוח',
      keywordBasedResponse: 'תגובה מבוססת מילות מפתח',
      whatsappShopManagement: 'ניהול חנות WhatsApp',
      aiChatbot: 'צ\'אטבוט מל"כ',
      chatGPTIntegration: 'אינטגרציה ChatGPT',
      configureAIChatbotPersonality: 'הגדרת אישיות צ\'אטבוט מל"כ',
      trainChatbotOnYourOwnData: 'אימון צ\'אטבוט מל"כ על נתונים שלך',
      trainChatbotOnWebPages: 'אימון צ\'אטבוט מל"כ על דפי אינטרנט',
      trainChatbotOnMultipleFiles: 'אימון צ\'אטבוט מל"כ על קבצים מרובים',
      trainChatbotOnFAQs: 'אימון צ\'אטבוט מל"כ על שאלות נפוצות',
      dataTrainingStorage: 'אחסון לימוד נתונים',
      useChatGPTModel4or35Turbo: 'שימוש בדגם ChatGPT 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'העלאת קבצים מרובים לאימון צ\'אטבוט',
      naturalLanguageProcessing: 'עיבוד שפה טבעית',
      naturalLanguageUnderstanding: 'הבנת שפה טבעית',
      answerLength: 'אורך התשובה',
      chatTone: 'טון הצ\'אט',
      multiLanguage: 'רב-שפתיות',
      answerFormatting: 'תצורת התשובה',
      customPrompt: 'הוראה מותאמת אישית',
      whatsAppSessionManagement: 'ניהול ישיבת WhatsApp (הפסקת פעילות והתחלת זרימה מחדש)',
      integrateBotPenguinLLM: 'אינטגרציה של BotPenguin LLM',
      integrateOwnCustomLLM: 'אינטגרציה של LLM מותאמת אישית',
      periodicallySyncDataSources: 'סנכרון מתוך פרק זמן קבוע ממקורות נתונים',
      aiBasedSuggestedQuestions: 'שאלות מוצעות בהתבסס על אינטיליגנציה מל"כ',
      chooseVisibilityForParticularWebpageSitemap: 'בחירת נראות לדף אינטרנט / אתר מסוים',
      chooseKnowledgeMix: 'בחירת מרכיבי מידע: בסיס הידע של ChatGPT + הידע שלך',
      faqExtractionFromFileAndURL: 'חילוץ שאלות נפוצות מקובץ וכתובת URL',
      analyticsToImproveAI: 'ניתוח לשיפור המודלים של המוח',
      exportQuestionFlow: 'ייצוא זרימת שאלות',
      collectFeedbackOnChatbotResponses: 'איסוף משוב על תגובות הצ\'אטבוט',
      smartAIFollowUpQuestions: 'שאלות תמיכת AI במעקב',
      trainOnYourNotionWorkspaceData: 'הכשרה במידע ממרחב העבודה שלך ב-Notion',
      trainOnYoutubeVideo: 'הכשרה בסרטון YouTube',
      trainOnCustomVideoUpload: 'הכשרה בהעלאת סרטון מותאמת אישית',
      rssFeed: 'סדרת RSS',
      googleDrive: 'גוגל דרייב',
      dropBox: 'דרופבוקס',
      humanHandover: 'העברת בני אדם',
      botHandover: 'העברת רובוט',
      handoverBetweenAgents: 'העברה בין סוכנים',
      emailAndMobileValidation: 'אימות אימייל ונייד',
      mobileAppsForAgents: 'אפליקציות סלולריות לסוכנים',
      appointmentBooking: 'קביעת פגישות',
      roleBasedAccessControls: 'פקדי גישה המבוססים על תפקיד',
      ruleBasedChatRouting: 'הפניה בצ\'אט המבוססת על כללים',
      liveVisitorsDetails: 'פרטי מבקרים חיים',
      multipleSimultaneousChats: 'צ\'אטים מרובים בו-זמנית',
      visitorStats: 'נתוני מבקרים',
      addContactsFromWhatsApp: 'הוסף אנשי קשר מ-WhatsApp',
      applyFilterAndSortConversations: 'יישום פילטרים ומיון שיחות',
      seeChatLogs: 'הצג יומני צ\'אט',
      quickReplies: 'תשובות מהירות',
      unifiedCustomerProfile: 'פרופיל לקוח מאוחד',
      assignNotesAndChatTags: 'הקצאת הערות ותגי צ\'אט',
      exportLeadsInCSV: 'ייצוא לידים בפורמט CSV',
      tagContacts: 'תייג אנשי קשר',
      customAttributes: 'תכונות מותאמות אישית',
      markStatusOfConversations: 'סימון מצב שיחות',
      assignConversationToAgents: 'הקצאת שיחה לסוכנים',
      leadNotificationsByEmail: 'התראות על לידים באמייל',
      directCallsToLeadsWithTwilio: 'שיחות ישירות ללידים עם Twilio',
      whatsAppTemplates: 'תבניות WhatsApp',
      promotionalMessageFrequencyControl: 'שליטה בתדר הודעות פרסום',
      whatsAppWidget: 'ווידג\'ט WhatsApp',
      whatsAppQR: 'WhatsApp QR',
      whatsAppLink: 'קישור WhatsApp',
      campaignScheduler: 'מתזמן קמפיינים',
      customTriggers: 'מפעילים מותאמים אישית',
      customCampaigns: 'קמפיינים מותאמים אישית',
      repeatPurchaseReminders: 'תזכורות לרכישות חוזרות',
      campaignOpenRateTracking: 'מעקב אחר תערובת הצפיה בקמפיינים',
      paymentCollectionOnWhatsApp: 'אגרת תשלום ב-WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'קפסולה',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'צ\'אקרה',
      plivo: 'פליבו',
      helpcrunch: 'Helpcrunch',
      getresponse: 'גט רספונס',
      supportbee: 'Supportbee',
      pipeline: 'צינור',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'פירמאו',
      kylas: 'קיילס',
      close: 'סגור',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'סוכן חי',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'טיסה אוטומטית',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'גוגל גליונות',
      googleCalendar: 'יומן Google',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'מערכת לניהול כרטיסי תמיכה',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'שיחת וידאו ושיחת קול בצ\'אט (Twilio/Agora)',
      courierTracking: 'מעקב אחר מסרים',
      emailIntegration: 'אינטגרציה של אימייל',
      webhooks: 'ווב-הוקס',
      schedulers: 'מתזמני השמות',
      paymentGateways: 'שערי תשלום (Stripe ו- RazorPay)',
      enterpriseIntegrations: 'אינטגרציות עסקיות',
      customIntegration: 'אינטגרציה מותאמת אישית',
      freeWhatsAppAPIApprovalAndSetup: 'אישור והגדרת API של WhatsApp בחינם',
      developerAPIsAccess: 'גישה לממשקי התכנות למפתחים',
      customRateLimit: 'הגבלת קצב מותאמת אישית',
      totalNumberOfLeads: 'סה"כ מספר הלידים',
      averageAgentResponseTime: 'זמן תגובת הסוכן הממוצע',
      visitorTimingReport: 'דו"ח זמן המבקר',
      customerSegmentBasedReports: 'דו"חות מבוססי סקטור הלקוח',
      tagBasedReports: 'דו"חות מבוססי תגיות',
      leadGenerationReport: 'דו"ח יצירת הלידים',
      supportOnWhatsApp: 'תמיכה ב-WhatsApp',
      knowledgeBase: 'בסיס ידע',
      onboardingAndTraining: 'שלבי הכנסה והכשרה',
      callAndEmailSupport: 'תמיכה בטלפון ובדוא"ל',
      freeChatbotCreation: 'יצירת צ\'אטבוט חינם',
      customerSuccessOfficer: 'קצין הצלחת לקוח',
      secureConnection: 'חיבור מאובטח בסלע 2048',
      securityAssessment: 'הערכת אבטחה',
      dataCenterLocation: 'מיקום מרכז מידע בארה"ב או באירופה',
      twoStepVerification: 'אימות שני שלבים',
      accessRestrictions: 'הגבלות גישה בהתבסס על כתובות IP',
      auditLog: 'לוג חשבון בדרישה',
      bannedVisitorsList: 'רשימת מבקרים נעצרים',
      customAPIRate: 'שיעור API מותאם אישית',
      trustedDomains: 'תחומים מהימנים',
      elementary: 'יסודי',
      bots: 'בוטים',
      unlockWhatsapp: 'פתיחת WhatsApp',
      aiChatbotFeatures: 'תכונות של צ\'אטבוט מל"כ',
      dataSourceForTraining: 'מקור נתונים לאימון',
      smartFeatures: 'תכונות חכמות',
      liveChat: 'צ\'אט חי',
      smartInbox: 'תיק דואר חכם',
      broadcastsAndDrips: 'שידורים ונטילות',
      integrations: 'אינטגרציות',
      development: 'פיתוח',
      reportsAndAnalytics: 'דוחות וניתוח',
      customerSupport: 'תמיכת לקוחות',
      security: 'אבטחה',
      whatsappWidgets: 'ווידג\'טים של WhatsApp',
      campaignSchedular: 'מתזמן קמפיינים',
      repeatPurchaseRemainder: 'תזכורת לרכישות חוזרות',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'המסלול החינמי שלנו',
      TOOLTIP_KING_PLAN: 'המסלול הפופולרי ביותר שלנו',
      TOOLTIP_EMPEROR_PLAN: 'מסלול מותאם אישית עבור הודעות בלתי מוגבלות, נתונים בלתי מוגבלים, חברי צוות בלתי מוגבלים',
      TOOLTIP_CONTACT_US: 'תיצור איתנו קשר דרך WhatsApp או דוא"ל בכתובת contact@botpenguin.com כדי לקבל מידע נוסף אודות המסלול המותאם אישית שלנו',
      TOOLTIP_UPGRADE: 'שדרג את המסלול הנוכחי שלך לקבלת תכונות נוספות',
      TOOLTIP_MONTHLY: 'בחר מחזור תשלום חודשי',
      TOOLTIP_YEARLY: 'בחר מחזור תשלום שנתי וקבל הנחות'
    }
  }
};
