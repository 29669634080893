import { Directive, ElementRef, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { State } from '../../../ngrx/reducers/index';


@Directive({
  selector: '[appBpIsRootAgency]'
})
export class BpIsRootAgencyDirective {

  constructor(
    public elRef: ElementRef,
    private store: Store<State>,
    ) {

    this.store.select(state => state.profile)
    .subscribe((profile: any) => {
      if (profile._parent && profile._parent === environment.rootAgencyId) {
        this.elRef.nativeElement.style.display = 'block';
      } else {
        this.elRef.nativeElement.style.display = 'none';
      }
    });
  }
}
