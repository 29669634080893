import { Action, createReducer, on } from '@ngrx/store';
import { loadWhatsAppPlans } from '../actions/whatsapp-subscription.action';

export const initialState: any = {
    masterPlans: []
};

const whatsAppReducer = createReducer(
    initialState,
    on(loadWhatsAppPlans, (state, { masterPlans }) => ({ ...state, masterPlans }))
);

export function reducer(state: any[], action: Action) {
    return whatsAppReducer(state, action);
}
