import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NetworkService } from './network.service';
import { State } from '../ngrx/reducers';
import Profile from '../models/profile';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  tokens: any = {};
  profile: Profile;
  isPaymentLink = new BehaviorSubject<false | true>(false);

  constructor(
    private networkService: NetworkService,
    private store: Store<State>
  ) {

    this.store.select(
      state => state
    )
      .subscribe(state => {
        this.tokens = state.tokens;
        this.profile = state.profile;
      });
  }

  getPlansForCustomer() {
    return new Promise<any>((resolve, reject) => {
      let currency = localStorage.getItem('default-currency');
      if (this.tokens.agencyToken) {
        currency = localStorage.getItem('original-default-currency');
      }
      this.networkService.httpRequest(`payment/plans?currency=${currency || 'USD'}`)
        .then((plans: any[]) => {
          resolve(plans);
        })
        .catch(reject);
    });

  }

  getCard() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`payment/cards`)
        .then(resolve)
        .catch(reject);
    });
  }

  removeAllCards() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`payment/cards`, {}, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  addNewCard(card: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`payment/cards`, card, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  updatePlan(plan: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`payment/subscription`, plan, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  updatePlanWhitelabelCustomer(plan: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`payment/subscription/whitelabel`, plan, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  updateWAPlanWhitelabelCustomer(plan: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`payment/subscription-master/whitelabel`, plan, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  getSubscription() {
    return this.networkService.httpRequest(`payment/subscription`);
  }

  getMasterSubscription() {
    return this.networkService.httpRequest(`payment/subscription-master`);
  }

  getDowngradeRequest() {
    return this.networkService.httpRequest('payment/subscription/downgrade-request');
  }

  getPendingPayments() {
    return this.networkService.httpRequest(`payment/subscription/pending-payments`);
  }

  getTransactions() {
    return this.networkService.httpRequest(`payment/transactions/list`);
  }

  getAddress() {
    return this.networkService.httpRequest(`payment/billing-address`);
  }

  updateAddress(payload: any) {
    return this.networkService.httpRequest(`payment/billing-address`, payload, 'PUT');
  }

  applyCoupon(code, payload) {
    return this.networkService.httpRequest(`payment/coupons/${code}`, payload, 'POST');
  }

  /** Purchasing Add-on Plans */
  purchaseAddonPack(payload) {
    return this.networkService.httpRequest(`payment/subscription-addon`, payload, 'POST');
  }

  purchaseWhiteLabelPack(payload) {
    return this.networkService.httpRequest('payment/subscription-whitelabel', payload, 'POST');
  }

  /** Direct WhiteLabel by Agency */
  directWhiteLabelBots(payload) {
    return this.networkService.httpRequest('bots/direct-whitelabel', payload, 'PUT');
  }

  optOutFromWhiteLabel(payload) {
    return this.networkService.httpRequest('payment/subscription-whitelabel/opt-out', payload, 'POST');
  }

  getOptOutFromWhiteLabel() {
    return this.networkService.httpRequest('payment/subscription-whitelabel/opt-out');
  }

  /** Development APIs Starts */

  reminderWA(type) {
    return this.networkService.httpRequest('payment/subscription-master/reminder?type=' + type);
  }

  reminderWeb(type) {
    return this.networkService.httpRequest('payment/subscription/reminder?type=' + type);
  }

  renew() {
    return this.networkService.httpRequest('payment/subscription-master/renew-subscription');
  }

  renewWeb() {
    return this.networkService.httpRequest('payment/subscription/renew-subscription');
  }

  checkUsage() {
    return this.networkService.httpRequest('whatsapp-automation/internal/check-plan-usage');
  }

  exceedUsage() {
    return this.networkService.httpRequest('whatsapp-automation/internal/exceed-usage');
  }

  resetUsage() {
    return this.networkService.httpRequest('whatsapp-automation/internal/reset-usage');
  }

  getBots() {
    return this.networkService.httpRequest('whatsapp-automation/internal/get-bots');
  }


  /** Development APIs Ends */
  /** Whatsapp Plans */
  updateWhatsappPlan(plan: any) {
    return new Promise((resolve, reject) => {
      // TODO Add Whatsapp API
      this.networkService.httpRequest(`payment/subscription-master`, plan, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  get isBabyPlan() {
    return this.profile?.plan?._plan?.name === 'baby' && !this.profile?.planMaster?.plans?.whatsapp?.finalAmount?.value;
  }

  getStripePublicKey() {
    return this.networkService.httpRequest('integrations/agency-configuration/stripe-public-key');
  }

  getAvailablePricing(wa = false) {
    return this.networkService.httpRequest(`payment/plans/available-pricing?currency=${localStorage.getItem('default-currency') || 'USD'}&type=${wa ? 'WA' : 'exceptWA'}`);
  }
  discardPaymentLink(payload) {
    return this.networkService.httpRequest('payment/subscription/discard-payment-link', payload, 'POST');
  }

  getBotsCount() {
    return this.networkService.httpRequest('payment/plans/get-bots-count');
  }

  getPlanDisplayName(): Promise<any> {
    return this.networkService.httpRequest('payment/plans/plan-display-name');
  }
}
