export const whitelabel = {
  plans: 'Planuri',
  managePlan: 'Gestionează Planuri',
  whitelabelCharges: 'Taxe Whitelabel',
  chargesOrTaxesConfiguration: 'Configurare Taxe/Impozite',
  websitePlans: 'Planuri pentru Site',
  whatsappPlansHeader: 'Planuri WhatsApp',
  addOnPlans: {
    createAddOnPlans: 'Creează Planuri Add-On',
    checkoutYourCurrentMessage: 'Verifică-ți Planurile Add-On curente pentru mesaje și planuri noi pentru clienții tăi',
    choosePlanValidity: 'Alege Valabilitatea Planului',
    chooseValidity: 'Alege Valabilitate',
    oneYear: 'Un An',
    billingCycle: 'Ciclu de Facturare',
    numberOfMessages: 'Număr de Mesaje',
    pleaseEnterNumberOfMessages: 'Vă rugăm să introduceți numărul de mesaje',
    setCurrency: 'Setează Moneda',
    select: 'Selectează',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Șterge Tot',
    apply: 'Aplică',
    currency: 'Monedă',
    amount: 'Sumă',
    validity: 'Valabilitate',
    upgradePlan: 'Actualizează Planul',
    addPlan: 'Adaugă Plan',
    serialNumber: 'Nr. Ser.',
    messages: 'Mesaje',
    //
    active: 'Activ',
    priceDetails: 'Detalii Preț',
    action: 'Acțiune',
    addOnChanges: 'Taxe Add-On: ',
    //
    perYear: 'pe An',
    taxes: 'Impozite',
    placeholders: {
      enterNumberOfMesasges: 'Introduceți numărul de mesaje',
      enterAmount: 'Introduceți Suma',
      enterValidity: 'Introduceți Valabilitatea',
      searchByMessages: 'Căutare după Mesaje'
    },
    modal: {
      deleteAddOnsPlan: 'Șterge Planul Add-ons',
      wantToDeleteThisAddOnsPlan: 'Sigur doriți să ștergeți acest Plan Add-ons?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Adaugă Taxe Whitelabel',
    checkoutExistingCharges: 'Verifică taxele existente pentru diferite metode de plată și adaugă taxe whitelabel noi',
    currency: 'Monedă',
    chooseCurrency: 'Alege Moneda',
    amountPerBotPerMonth: 'Sumă pe bot pe lună',
    cancel: 'Anulează',
    upgradeChanges: 'Actualizează Taxele',
    addCharges: 'Adaugă Taxe',
    serialNumber: 'Nr. Ser.',
    //
    action: 'Acțiune',
    taxes: 'Impozite',
    placeholders: {
      enterAmountBotPerMonth: 'Introduceți suma pe bot pe lună',
      searchByCurrency: 'Căutare după Monedă',
    },
    modal: {
      deleteCharges: 'Șterge Taxele',
      wantToDeleteThisCharge: 'Sigur doriți să ștergeți aceste Taxe?',
    }
  },
  planManagement: {
    createNewPlans: 'Creează Planuri Noi',
    checkoutYourCurrentPlan: 'Verifică-ți Planurile curente și adaugă planuri noi pentru Clienții tăi',
    choosePlan: 'Alege Plan',
    selectPlan: 'Selectează Planul',
    baby: 'Baby',
    king: 'King',
    displayName: 'Nume Afișat',
    messageLimit: 'Limită Mesaje',
    enterAValidEmail: 'Introduceți un e-mail valid',
    setCurrency: 'Setează Moneda',
    select: 'Selectează',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Șterge Tot',
    apply: 'Aplică',
    perMonth: '/Lună',
    perYear: '/An',
    cancel: 'Anulează',
    upgradePlan: 'Actualizează Planul',
    addPlan: 'Adaugă Plan',
    serialNumber: 'Nr. Ser.',
    plan: ' Plan',
    //
    messages: 'Mesaje',
    pricing: 'Prețuri',
    actions: 'Acțiuni',
    //
    perMonthText: 'Pe Lună',
    perYearText: 'Pe An',
    placeholders: {
      enterDisplayName: 'Introduceți numele afișat',
      enterMessageLimit: 'Introduceți limita de mesaje',
      searchByPlanName: 'Caută după numele planului sau mesaje'
    },
    modal: {
      deletePlan: 'Excluir Plano',
      wantToDeleteThisBabyPlan: 'Excluir o Plano Baby afetará todos os clientes. Tem certeza de que deseja excluir este plano?',
      wantToDeleteThisPlan: 'Sigur doriți să ștergeți acest plan?',
    },
    appTooltip: {
      chooseYourPlanType: 'Alegeți tipul planului',
      pleaseInputYourPlanDisplayName: 'Vă rugăm să introduceți numele afișat al planului',
      typeMessageLimitForCustomer: 'Introduceți limita de mesaje pentru client',
      selectYourPreferredCurrencies: 'Selectați monedele preferate',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Adaugă Impozite',
    checkoutExistingTaxes: 'Verifică impozitele existente pentru diferite metode de plată și impozite noi',
    taxName: 'Nume Impozit',
    applicableOn: 'Aplicabil pe',
    choosePlans: 'Alegeți Planuri',
    chooseCurrency: 'Alege Moneda',
    percentage: 'Procentaj',
    cancel: 'Anulează',
    //
    serialNumber: 'Nr. Ser.',
    name: 'nume',
    //
    currency: 'Monedă',
    updateTaxes: 'Actualizează Impozitele',
    actions: 'Acțiuni',
    modal: {
      deleteTaxes: 'Șterge Impozitele',
      wantToDeleteThisTax: 'Sigur doriți să ștergeți aceste impozite?'
    },
    placeholders: {
      taxName: 'Nume Impozit',
      taxPercentage: 'Procentaj Impozit',
      searchByCurrency: 'Căutare după Monedă',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Creează planuri noi',
    checkoutYourCurrentAndNewPlans: 'Verifică-ți planurile curente și adaugă planuri noi pentru clienții tăi',
    choosePlan: 'Alege Plan',
    selectPlan: 'Selectează Planul',
    baby: 'Baby',
    king: 'King',
    displayName: 'Nume Afișat',
    messageLimit: 'Limita Conversației',
    enterAValidMessageLimit: 'Introduceți o limită de conversație validă',
    messages: 'Conversații',
    setCurrency: 'Setează Moneda',
    select: 'Selectează',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Șterge Tot',
    apply: 'Aplică',
    perMonth: '/Lună',
    perYear: '/An',
    cancel: 'Anulează',
    addPlan: 'Adaugă Plan',
    serialNumber: 'Nr. ',
    plan: 'Plan',
    pricing: 'Prețuri',
    actions: 'Acțiuni',
    perMonthText: 'Pe Lună',
    perYearText: 'Pe An',
    updatePlan: 'Actualizează Planul',
    //
    placeholders: {
      enterDisplayName: 'Introduceți numele afișat',
      enterMessageLimit: 'Introduceți limita de conversație',
      searchByPlanName: 'Căutați după numele planului sau Conversații',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Introduceți limita de conversație pentru client',
      chooseYourPlanType: 'Alegeți tipul planului',
      selectYourPreferredCurrencies: 'Selectați monedele preferate',
      pleaseInputYourPlanDisplayName: 'Vă rugăm să introduceți numele afișat al planului',
    },
    modal: {
      deletePlan: 'Excluir Plano',
      wantToDeleteThisBabyPlan: 'Excluir o Plano Baby afetará todos os clientes. Tem certeza de que deseja excluir este plano?',
      areYouSureYouWantToDeleteThisPlan: 'Sigur doriți să ștergeți acest plan?',
    }
  }
};
