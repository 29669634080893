export const inboxMerged = {
  inboxHeader: 'Inbox',
  chatsHeader: 'Chats',
  contactsHeader: 'Contacts',
  segmentsHeader: 'Groups',
  ordersHeader: 'Orders',
  exportStatus: 'Export Status',
  tasksExport: 'Tasks- Export',
  download: 'Download',
  ourAllNewOmniChannelInbox: 'Our All New Omni-channel Inbox!',
  allYourMessagesContactsAndLeads: 'All your messages, contacts, and leads are now in one place. Ready to be sorted and grouped, to make your business grow!',
  close: 'Close',
  takeALook: 'Take a look',
  inProgress: 'in progress',
  appTooltip: {
    manageAllYourLeadsHere: 'Manage all your leads/contacts here',
    segmentYourUserLeads: `Group your leads/bot users based on demographics, and interests. These will be used in creating broadcasts'`,
    manageAllBotsConversationsHere: 'Manage all the bots’ conversations here',
    manageAllYourOrdersHere: 'Manage all your orders here',
  },
  contacts: {
    seeHowToUse: 'See how to use',
    help: 'Help',
    visitorName: 'Visitor Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    segment: 'Group',
    status: 'Status',
    tag: 'Tag',
    action: 'Action',
    discard: 'Discard',
    addTag: 'Add Tag',
    loadMore: 'Load More',
    nA: 'NA',
    assign: 'Assign',
    deleteUser: 'Delete user',
    areYouSureToDeleteLead: 'Are you sure you want to delete this lead?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'You have no contacts yet!',
      allOfYourContactsAppearHere: 'All your contacts will appear here'
    },
    placeholders: {
      searchSegmentByName: 'Search group by name',
      searchOrAddTag: 'Search or Add a Tag',
    },
    addContacts: {
      name: 'Name',
      email: 'Email',
      enterAValidEmail: 'Enter a valid email',
      phoneNumber: 'Phone Number',
      cancel: 'Cancel',
      addContact: 'Add Contact',
      placeholders: {
        enterEmailAddress: 'Enter email address',
        enterUserrName: 'Enter user name',
        searchCountry: 'Search Country',
        enterPhoneNumber: 'Enter Phone Number',
      }
    }
  },
  inboxFilter: {
    applied: 'applied',
    assignTags: 'Assign Tags',
    discard: 'Discard',
    addTag: 'Add Tag',
    assignSegments: 'Assign Groups',
    filterYourLeads: 'Filter your leads',
    filters: 'filters',
    filter: 'filter',
    resetAllFilters: 'Reset all filters',
    importContacts: 'Import Contacts',
    beforeYouStart: 'Before you start upload please make sure',
    point1: '1. Download the sample excel file.',
    point2: '2. Start entering your data from 2nd row onwards.',
    point3: '3. In each row, there will be data for one contact',
    point4: '4. Make sure all the phone numbers are unique.',
    point5: '5. The maximum file size upload limit is 20MB',
    selectFile: 'Select File',
    clickHere: 'Click here',
    toDownloadSampleFile: 'to Download Sample file',
    modal: {
      closeLeads: 'Close Leads',
      areYouSureToselectAllLeads: 'Are you sure you want to close all the selected leads?',
      assignAgent: 'Assign Agent',
      toAssignAgentToAllLeads: 'Are you sure you want to assign agent to all the selected leads?',
      assignTag: 'Assign Tag',
      wantToReplacePreviouslyAssignedTagWithNew: 'Are you sure you want to replace the previously assigned tags with the new chosen tag for all the selected leads?',
      deleteLeads: 'Delete Leads',
      wantToDeleteSelectedLeads: 'Are you sure you want to delete all the selected leads?',
      exportData: 'Export Data',
      exportDataBasedOn: 'Export data based on the ',
      assignSegment: 'Assign Grou',
      wantToAssignSegmentsToSelectedLeads: 'Are you sure you want to assign group to all the selected leads?',
      yesGoAhead: 'Yes, Go Ahead!',
      export: 'Export',
      deleteActionable: 'Delete Record',
      wantToDeleteActionable: 'Are you sure you want to delete all the selected records?',
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Filter data based on your preferences like, tags, bot, status, etc.',
      markLeadAsClosed: 'Mark user/lead as closed',
      assignAgentToLead: 'Assign agent to user',
      assignTagToUser: 'Assign tag to user',
      exportData: 'Export Data',
      importContactsForWhatsappBot: 'Import contacts for whatsapp bot',
      addWhatsappContact: 'Add Whatsapp contacts',
      assignSegmentToUser: 'Assign group to user',
      deleteUsers: 'Delete users',
      deleteRecords: 'Delete records',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Type name, email or phone, and press enter',
      searchAgentByName: 'Search agent by name',
      searchOrAddTag: 'Search or Add a Tag',
      searchSegmentByName: 'Search group by name',
    },
    filterContainer: {
      filterOn: 'Filter on',
      filterBy: 'Filter by',
      startFrom: 'Start from',
      tillDate: 'Till date',
      offlineOrOnline: 'Off-line/Online',
      liveChat: 'Live Chat',
      userInteractedWithBot: 'User Interacted With Bot',
      discard: 'Discard',
      applyFilter: 'Apply Filter',
      bots: 'Bots',
      tags: 'Tags',
      status: 'Status',
      assignedTo: 'Assigned to',
      lastMessageBy: 'Last Messaged By',
      onlineOrOffline: 'Online/Off-line',
      userInteracted: 'User interacted',
      segments: 'Groups',
      lastSeenDate: 'Last seen date',
      onboardingDate: 'Onboarding date',
      createTags: 'Create Tags',
      createTagsToSortAndManageLeads: 'Create tags to sort and manage your leads easily',
      typeYourTagHere: 'Type your tag here',
      createSegment: 'Create Group',
      manageSegmentForMarketingAutomation: 'Manage your groups for the marketing automation',
      typeYourSegmentHere: 'Type your group here',
      placeholders: {
        searchFilter: 'Search filter',
        searchByBotName: 'Search by bot name',
        searchOrAddTag: 'Search or Add a Tag',
        searchByAgentName: 'Search by agent name',
        searchBySegments: 'Search by groups',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Date range you wish to end selection at',
        dateRangeYouWishToStartSelectionFrom: 'Date range you wish to start selection from',
        filterThoseThatAreOfflineOrOnline: 'Filter those users that are offline/online',
        filterThoseThatAreCurrentlyLiveChatting: 'Filter those users that are currently live chatting',
        filterThoseThatInteractedWithBot: 'Filter those users that interacted with the bot'
      },
      indexMergeDatePicker: {
        selectStartDate: 'Select start date',
        selectEndDate: 'Select end date',
      }
    }
  },
  leads: {
    seeHowToUse: 'See how to use',
    help: 'Help',
    visitorName: 'Visitor Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    assignedTo: 'Assigned to',
    status: 'Status',
    tag: 'Tag',
    action: 'Action',
    discard: 'Discard',
    addTag: 'Add Tag',
    loadMore: 'Load More',
    nA: 'NA',
    deleteUsers: 'Delete user',
    areYouSureToDeleteLead: 'Are you sure you want to delete this lead?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'You do not have any chats yet',
      allOfYourChatsAppearHere: 'All of your chats will appear here',
    },
    placeholders: {
      searchAgentByName: 'Search agent by name',
      searchStatusByName: 'Search status by name',
      searchOrAddTag: 'Search or Add a Tag',
    }
  },
  messages: {
    seeHowToUse: 'See how to use',
    help: 'Help',
    editChatFlow: 'Edit Chat Flow',
    connect: 'Connect',
    chat: {
      photo: 'Photo',
      video: 'Video',
      document: 'Document',
      location: 'Location',
      assignedTo: 'Assigned To',
      selectBot: 'Select Bot',
      discard: 'Discard',
      addTag: 'Add Tag',
      yourBrowserDoesNotSupportVideoTag: 'Your browser does not support the video tag.',
      userHasSharedADocument: 'User has shared a document.',
      clickHereToDownload: 'Click here to Download',
      thisStoryIsNoLongerAvailable: 'This story is no longer available',
      yourBrowserDoesNotSupportAudioElement: 'Your browser does not support the audio element.',
      thisMessageIsNoLongerAvailable: 'This message is no longer available because it was unsent by the sender',
      userHasSharedHisLocation: 'User has shared his location. Click here to view',
      sms: 'SMS',
      failed: 'Failed',
      a: 'A',
      i: 'I',
      r: 'R',
      m: 'M',
      conversationsSelected: 'conversations selected',
      nA: 'NA',
      assignTagsCloseOrBulkReply: 'Assign tags, close or bulk reply to all in one go',
      appTooltip: {
        assignAgent: 'Assign agent',
        selectBot: 'Select Bot',
        assignTagsToManageLeads: 'Assign tags to manage leads and conversations',
      },
      placeholders: {
        searchAgentByName: 'Search agent by name',
        searchBotByName: 'Search bot by name',
        searchOrAddTag: 'Search or Add a Tag',
        searchStatusByName: 'Search status by name',
      }
    },
    profile: {
      userDetails: 'User Details',
      orderDetails: 'Order Details',
      orderedOn: 'Ordered on',
      showMore: 'Show More',
      showLess:  'Show Less',
      subTotal: 'Sub Total',
      nA: 'NA',
      addAWhatsappNumber: 'Add Whatsapp Number',
      addTelegramUserName: 'Add Telegram Username',
      addInstagramProfileId: 'Add Instagram Profile ID',
      addFacebookProfileId: 'Add Facebook Profile ID',
      add: 'Add',
      deviceProperties: 'Device Properties',
      privateNotes: 'Private Notes',
      urlsVisited: 'URLs Visited',
      source: 'Source - ',
      sourceNa: 'Source - NA',
      medium: 'Medium - ',
      mediumNa: 'Medium - NA',
      campaign: 'Campaign - ',
      campaignNa: 'Campaign - NA',
      attributeKey: 'Attribute Key',
      attributeName: 'Attribute Name',
      discard: 'Discard',
      addAttribute: 'Add Attribute',
      editAttribute: 'Edit Attribute',
      deleteAttribute: 'Delete Attribute',
      headsUp: 'Heads Up',
      areYouSureToDeleteAttribute: 'Are you sure you want to delete this Attribute?',
      okay: 'Okay',
      dataAddedToLead: 'The attributes mapped to your APIs will not be updated. Please update the APIs accordingly.',
      assignedOnly: 'Assigned Only',
      allUsers: 'All Users',
      save: 'Save',
      manageCustomAttributes: 'Manage Custom Attributes',
      placeholders: {
        title: 'Title',
        egServices: 'E.g. Services',
        egRequestedServices: 'E.g. Requested Services',
        enterYourNotesHere: 'Enter your notes here ...'
      },
      bpDropDown: {
        selectBot: 'Select Bot',
        selectFlow: 'Select Flow',
        selectQuestion: 'Select Question*'
      }
    },
    sendMessage: {
      send: 'Send',
      sendToAllChannels: 'Send To all channels',
      email: 'Email',
      whatsapp: 'Whatsapp',
      telegram: 'Telegram',
      facebook: 'Facebook',
      sendSms: 'Send SMS',
      addNew: 'Add New',
      addNewQuickReply: 'Add New Quick Reply',
      discard: 'Discard',
      fromComputer: 'FROM COMPUTER',
      add: 'Add',
      dragAndDropHereOr: 'Drag & Drop here or',
      browseFiles: 'Browse files',
      caption: 'Caption',
      templates: 'Templates',
      addHeaderMedia: 'Add header media',
      selectFile: 'Select File',
      previousConversationClosed: 'Previous conversation closed!',
      sendTemplateMessage: 'Send template message to initiate the conversation again.',
      selectTemplate: 'Select Template',
      conversationLocked: 'Conversation locked!',
      demoBotOnlyAllowConversations: 'Demo bot only allows conversation with the number you have verified earlier.',
      placeholders: {
        searchQuickReplyByTitle: 'Search Quick Replies by title',
        addTitle: 'Add Title',
        addYourRepliesHere: 'Add your replies here... ',
        addCaption: 'Add caption',
        enterValueForThisVariable: 'Enter value for this variable',
      },
      modal: {
        sendBulkMessages: 'Send Bulk Messages',
        send: 'Send',
        description: 'Your message will be sanitized and sent based on the type of platform a user belongs to.' +
        '\n - Website users only support textual based messages \n - Whatsapp (non subscribed) contacts can only recieve template messages'
      },
      noDataFound: {
        oops: 'Oops!',
        currentlyDoesNotSupportBulkMessages: 'We currently do not support bulk messages for whatsapp bots. Please use broadcasting feature',
        quickReplies: 'Quick Replies',
        noQuickRepliesAddedYet: 'No quick replies added yet!',
      }
    },
    userList: {
      selectAll: 'Select All',
      nA: 'NA',
      loadMore: 'Load More',
    }
  },
  segments: {
    seeHowToUse: 'See how to use',
    help: 'Help',
    manageSegment: 'Manage Group',
    manageYourSegmentsForMarketingAutomation: 'Manage your groups for the marketing automation',
    createNewSegment: 'Create New Group',
    segmentName: 'Group Name',
    totalContacts: 'Total Contacts',
    action: 'Action',
    createSegmentButton: 'Create Group',
    deleteSegments: 'Delete Groups',
    areYouSureToDeleteSegment: 'Are you sure you want to delete this group?',
    placeholders: {
      searchHere: 'Search here',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'You do not have any groups created yet',
      allYourSegmentsAppearHere: 'All of your groups will appear here'
    },
    modal: {
      exportData: 'Export Data',
      exportDataBasedOnAppliedFilters: 'Export data based on the applied filters',
      export: 'Export',
    },
    appTooltip: {
      deleteSegments: 'Delete groups',
      editSegment: 'Edit Group',
      downloadSegment: 'Download Group',
      deleteSegment: 'Delete Group'
    },
    createSegment: {
      createSegment: 'Create group',
      manageYourSegmentsForMarketingAutomation: 'Manage your groups for the marketing automation',
      segmentName: 'Group Name',
      segmentNameIsRequired: 'Group Name is required',
      selectContactsFilterForSegment: 'Select contact filters for the Group',
      filterYourContacts: 'Filter your contacts',
      removeAllFilters: 'Remove all filters',
      addFilters: 'Add Filters',
      discard: 'Discard',
      filterSelected: 'Filters Selected',
      placeholders: {
        enterSegmentName: 'Enter Group Name',
      }
    }
  },
  orders: {
    visitorName: 'Visitor Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    status: 'Status',
    itemCount: 'Item Count',
    total: 'Total',
    tag: 'Tag',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: 'You do not have any orders yet',
      allOfYourOrdersAppearHere: 'All of your orders will appear here'
    },
  }
};
