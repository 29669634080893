export const team = {
  addMember: {
    team: 'Equipe',
    seeHowToUse: 'Veja como usar',
    help: 'Ajuda',
    addAMember: 'Adicione um membro',
    addYourTeamMembersText: 'Adicione os membros da sua equipe como agentes de suporte ao bate -papo ao vivo com usuários de bot.',
    name: 'Nome',
    nameIsRequired: 'O nome é necessário',
    email: 'E-mail',
    enterAValidEmail: 'Entre com um email válido',
    password: 'Senha',
    passwordMustContain: 'A senha deve conter',
    atleast6Characters: 'pelo menos 6 caracteres,',
    oneUpperCaseLetter: '1 letra maiúscula',
    oneSpecialCharacter: '1 personagem especial*',
    profileIcon: 'Ícone do perfil',
    whatsappNumber: 'Número do WhatsApp',
    leadVisibility: 'Visibilidade do chumbo*',
    addMember: 'Adicionar membro',
    role: 'Papel',
    createdOn: 'Criado em',
    action: 'Ação',
    updateMember: 'Membro de atualização',
    add: 'Adicionar',
    cancel: 'Cancelar',
    selectVisibilityOption: 'Selecione a opção de visibilidade',
    all: 'Todos',
    assigned: 'Atribuído',
    nA: 'N / D',
    admin: 'Admin',
    agent: 'Agente',
    placeholders: {
      enterMemberName: 'Digite o nome do membro',
      enterMemberEmailAddress: 'Insira o endereço de e -mail do membro',
      createPasswordForMember: 'Crie uma senha para o membro',
      searchCountry: 'País de pesquisa',
      enterWhatsappNumber: 'Digite o número do WhatsApp',
      selectLeadsVisibility: 'Selecione a visibilidade dos leads',
      searchTeamByNameOrEmail: 'Membro da equipe de pesquisa por nome ou e -mail',
    },
    modal: {
      deleteTeamMember: 'Excluir membro da equipe',
      wantToDeleteThisTeamMember: 'Tem certeza de que deseja excluir este membro da equipe?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Clique para adicionar um novo membro da equipe',
      profileIconOfTeamMember: 'Ícone de perfil/imagem do membro da equipe',
      nameOfTeamMember: 'Nome do membro da equipe',
      emailOfTeamMember: 'Email de membro da equipe',
      whatsappNumberOfTeamMember: 'Número do WhatsApp de membro da equipe',
      roleOfTeamMemberAsAdminOrAgent: 'Papel do membro da equipe como administrador/agente',
      dateAndTimeOfCreatingTeamMemberRole: 'Data e hora de criar função de membro da equipe',
      editOrDeleteTeamMember: 'Editar/excluir membro da equipe',
      editTeamMembersDetails: 'Editar detalhes do membro da equipe.',
      removeAccessOfTeamMemberByDeletingAccount: 'Remova o acesso do membro da equipe por excluir a conta',
    }
  },
  users: {
    manageMembers: 'Gerenciar Membros',
    managerMembersWhichCanAccessPanel: 'Gerencie sua equipe de parceiros que pode acessar seu painel',
    createNewMember: 'Criar Novo Membro',
    createMember: 'Criar Membro',
    updateMember: 'Atualizar Membro',
    deleteTitleMember: 'Excluir Membro',
    deleteDescriptionMember: 'Tem certeza de que deseja excluir este membro?',
    noMemberFound: 'Nenhum Membro Encontrado!',
    titleMember: 'Criar um Membro',
    manageUsers: 'Gerenciar Usuários',
    createNewUser: 'Criar Novo Usuário',
    managerUserWhichCanAccessPanel: 'Gerenciar os usuários que podem acessar o seu painel',
    phoneNumber: 'Número de telefone',
    discard: 'Descartar',
    createUser: 'Criar usuário',
    updateUser: 'Atualizar usuário',
    noUserFOund: 'Nenhum usuário encontrado!',
    title: 'Criar um Usuário',
    profileIcon: 'Ícone do Perfil*',
    name: 'Nome*',
    email: 'E-mail*',
    password: 'Senha*',
    selectRole: 'Selecione a Função*',
    selectARole: 'Selecione uma função',
    selectLeadVisibility: 'Selecione a Visibilidade de Leads*',
    confirmPassword: 'Confirme a Senha*',
    tableHeadingName: 'Nome',
    tableHeadingEmail: 'Email',
    tableHeadingPhoneNumber: 'Número de telefone',
    tableHeadingRole: 'Função',
    tableHeadingStatus: 'Estado',
    tableHeadingAction: 'Ação',
    noUserFound: 'Nenhum Usuário Encontrado!',
    deleteTitle: 'Excluir Usuários',
    deleteDescription: 'Tem certeza de que deseja excluir este usuário?',
    placeholders: {
      searchHere: 'Pesquisar aqui',
      enterName: 'Digite o nome',
      enterEmail: 'Digite o e-mail',
      searchCountry: 'Pesquisar país',
      enterPhoneNumber: 'Digite o número de telefone',
      enterPassword: 'Digite a senha',
      enterConfirmPassword: 'Digite a confirmação da senha'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Excluir usuários',
      TOOLTIP_DELETE_MEMBERS: 'Excluir membros'
    }
  },
  roles: {
    manageRoles: 'Gerenciar Funções',
    manageYourRoles: 'Gerencie suas funções.',
    name: 'Nome*',
    description: 'Descrição',
    discard: 'Descartar',
    createRole: 'Criar Função',
    creteARole: 'Criar um Função',
    updateRole: 'Atualizar Função',
    assignPermissions: 'Atribuir Permissões',
    noUserFound: 'Nenhuma Função de Usuário Encontrada!',
    tableHeadingName: 'Nome',
    tableHeadingDescription: 'Descrição',
    tableHeadingStatus: 'Estado',
    tableHeadingAction: 'Ação',
    module: 'Módulo',
    read: 'Ler',
    write: 'Escrever',
    remove: 'Remover',
    notes: 'Notas',
    deleteTitle: 'Excluir Função',
    deleteDescription: 'Aviso: Ao excluir esta função, todos os usuários atribuídos a esta função também serão excluídos. \n Tem certeza de que deseja excluir esta função?',
    deleteMemberDescription: 'Aviso: Ao excluir esta função, todos os membros atribuídos a esta função também serão excluídos. Tem certeza de que deseja excluir esta função?',
    placeholders: {
      enterRoleName: 'Digite o nome da função',
      searchHere: 'Pesquise aqui',
      enterDescriptionForRole: 'Digite uma descrição para a função'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Seções em que você pode permitir ou bloquear o acesso dos usuários',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Clique para adicionar uma nova função',
    }
  },
  accessControl: {
    users: 'Usuários',
    members: 'Membros',
    partnerTeam: 'Equipe Parceira',
    roles: 'Funções'
  }
};
