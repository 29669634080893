export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Bem -vindo ao BotPenguin!',
      login: 'Conecte-se',
      youAreAllSet: 'Estás pronto! Você pode usar a caixa de entrada do WhatsApp na página Detalhes do Lead.',
      placeholders: {
        enterEmail: 'Digite email',
        enterPassword: 'Digite a senha',
      }
    }
  }
};
