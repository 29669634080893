const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'チャットフローを編集',
    seeHowToUse: '使用方法を見る',
    help: 'ヘルプ',
    classicBuilder: 'クラシックビルダー',
    visualiseFlow: 'フローを視覚化',
    testLiveOnTelegramWeb: 'Telegram Webでライブテスト',
    testFlow: 'フローテスト',
    importTemplate: 'テンプレートをインポート',
    landingPageBotReview: 'ランディングページボットプレビュー',
    install: 'インストール',
    testLiveOnMessengerWeb: 'Messenger Webでライブテスト',
    testLiveOnWhatsappWeb: 'WhatsApp Webでライブテスト',
    testLiveOnInstagramWeb: 'Instagram Webでライブテスト',
    seeAllChatFlow: 'すべてのチャットフローを見る',
    launchDemoModal: 'デモモーダルを起動',
    selectATemplateToKickStartYourBot: 'ボットを始めるためのテンプレートを選択',
    hireChatBotDesigner: 'チャットボットデザイナーを雇う',
    recommendedForYou: 'あなたにおすすめ',
    useTemplate: 'テンプレートを使用',
    exportStatus: 'エクスポートステータス',
    flowExport: 'フローエクスポート ',
    download: 'ダウンロード',
    importFlow: 'フローをインポート',
    beforeYouStartUploadPleaseMakeSure: 'アップロードを開始する前に確認してください',
    ponit1: '1. ファイルの最大アップロードサイズ制限は20MBです',
    point2: '2. ファイル形式はJSONである必要があります',
    selectFile: 'ファイルを選択',
    preview: 'プレビュー',
    test: 'テスト',
    OopsIntegrationAreNotAvailable: 'おっと、統合は利用できません。',
    pleaseUpgradeTo: 'アップグレードしてください。',
    planForIntegration: ' 統合のためのプラン。',
    placeholders: {
      searchTemplateYouNeed: '必要なテンプレートを検索',
    },
    noDataFound: {
      botFlow: 'ボットフロー',
      noBotFlowAddedYet: 'まだボットフローは追加されていません！',
      templates: 'テンプレート',
      noTemplatesFound: 'テンプレートが見つかりません！',
    },
    appTooltip: {
      exportFlow: 'フローをエクスポート',
      importFlow: 'フローをインポート',
      clickToViewChatFlow: 'ドラッグアンドドロップビルダーでチャットフローを表示するにはクリック',
      clickToPreviewChatFlow: 'チャットフローをプレビューするにはクリック',
      clickToViewChatFlowAsFlowDiagram: 'チャットフローをフローダイアグラムとして表示するにはクリック',
      clickToTestChatFlow: 'チャットフローをテストするにはクリック',
      clickToSelectAndImportTemplate: 'ビジネスに最適なテンプレートを選択してインポートするにはクリック',
      clickToInstallYourBotToSelectedPlatform: '選択したプラットフォームにボットをインストールするにはクリック',
      clickToTestDefaultFlowOfTelegramBot: 'Telegram Webでボットのデフォルト（アクティブ）フローをテストするにはクリック',
      clickToTestDefaultFlowOfFacebookBot: 'Facebook Webでボットのデフォルト（アクティブ）フローをテストするにはクリック',
      clickToTestDefaultFlowOfWhatsappBot: 'WhatsApp Webでボットのデフォルト（アクティブ）フローをテストするにはクリック',
      clickToTestDefaultFlowOfInstagramBot: 'Instagram Webでボットのデフォルト（アクティブ）フローをテストするにはクリック',
    }
  },
  createNewBot: {
    createNewBot: '新しいボットを作成',
    stepOneOfTwo: 'ステップ1 / 2',
    selectThePlatform: 'プラットフォームを選択',
    selectTheBotType: 'ボットの種類を選択',
    aiBasedChatbot: 'AIベースのチャットボット',
    ruleBasedChatBot: 'ルールベースのチャットボット',
    webPlatforms: 'Webプラットフォーム',
    websiteChatBot: 'ウェブサイトチャットボット',
    withLiveChat: '(ライブチャット付き)',
    landingPageBot: 'ランディングページボット',
    liveChatWidget: 'ライブチャットウィジェット',
    onlyLiveChat: '(ライブチャットのみ)',
    socialPlatforms: 'ソーシャルプラットフォーム',
    whatsappChatBot: 'WhatsAppチャットボット',
    telegramChatBot: 'Telegramチャットボット',
    facebookChatBot: 'Facebookチャットボット',
    instagramChatBot: 'Instagramチャットボット',
    selectBotType: 'ボットの種類を選択',
    selectProblem: '解決したい問題を選択',
    leadGeneration: 'リード生成',
    customerSupport: 'カスタマーサポート',
    appointmentBooking: '予約の予約',
    marketingAutomation: 'マーケティングオートメーション',
    ecommerceWebsite: 'Eコマースウェブサイト',
    otherUseCase: 'その他',
    back: '戻る',
    typeDomainUrl: '確認のためにウェブサイトのURLを入力してください。',
    dataSourceForChatBotTraining: 'チャットボットトレーニングのデータソース',
    upload: 'アップロード',
    uploadDocuments: '文書のアップロード',
    uploadDocument: '文書をアップロード',
    selectFile: 'ファイルを選択',
    continue: '続行',
    websiteUrl: 'ウェブサイトのURLを入力',
    skipNow: '今スキップ',
    completeTheExistingBotSetup: '既存のボットのセットアップを完了',
    youHaveAlreadyCreatedOneWhatsAppBot: 'すでにWhatsAppボットを1つ作成しています。新しいボットを作成するには、同じセットアップを完了してください。',
    completeSetup: 'セットアップを完了',
    fbBot: {
      createNewBot: '新しいボットを作成',
      help: 'ヘルプ',
      back: '戻る',
      step: 'ステップ ',
      of3: ' / 3',
      loginWithFacebook: 'Facebookでログイン',
      asPerLatestChangesInFacebookPolicy: 'Facebookポリシーの最新の変更によれば、Facebookボットの所有者はすべての機能にアクセスするために「マネージャー」またはそれ以上の役割を持っている必要があります。',
      login: 'ログイン',
      connectFacebookBot: 'Facebookボットを接続',
      connectBotWithYourFacebookPagesAnd: 'Facebookページとボットを接続して、Facebookの真の力を探索します。',
      exploreTruePowerOfFacebook: 'Facebookの真の力を探索します',
      connect: '接続',
      connected: '接続済み',
      createNewPage: '新しいページを作成',
      refreshPageList: 'ページリストを更新',
      thisPageIsSuccessfullyIntegratedWith: 'このページは正常に次と統合されています ',
      yourMessagesFromMessengerWillAppearIn: '. Messengerからのメッセージは',
      inbox: ' インボックス',
      continueToBotConfiguration: 'ボットの設定を続行',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand}へようこそ。私たちはあなたをサポートします。`,
      letsTryYourBotAndSeeHowItWorks: 'ボットを試して、どのように動作するかを確認しましょう！「Facebookでテスト」をクリックしてください。',
      howAreYouDoingToday: '今日はどうですか？',
      thisIsHowSimpleToUseFacebookBot: 'それだけです！これがFacebookボットを使用する方法です。',
      letsCreateYourOwnChatFlowNow: 'さあ、今あなた自身のチャットフローを作成しましょう。',
    },
    instagramBot: {
      createNewBot: '新しいボットを作成',
      back: '戻る',
      step: 'ステップ ',
      of3: ' / 3',
      loginWithInstagram: 'Instagramでログイン',
      loginToYourInstagramAccount: 'チャットボットを有効にするためにInstagramアカウントにログインしてください。',
      managerLevelDescription: 'Instagramボットの所有者は、すべての機能にアクセスするために少なくとも「マネージャー」レベルのアクセス許可が必要です。',
      login: 'ログイン',
      connectInstagramBot: 'Instagramボットを接続',
      connectBotWithYourInstagramAnd: 'BotをInstagramと接続し、 ',
      exploreTruePowerOfInstagram: 'Instagramの真の力を探索',
      connected: '接続済み',
      connect: '接続',
      createNewPage: '新しいページを作成',
      refreshPageList: 'ページリストを更新',
      thisPageIsSuccessfullyintegratedWith: 'このページは正常に次と統合されています ',
      yourMessagesFromMessengerWillAppearIn: '. Messengerからのメッセージは表示されます ',
      inbox: ' インボックス',
      continueToBotConfiguration: 'ボットの設定を続行',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand}へようこそ。私たちはあなたをサポートします。`,
      letsTryYourBotAndSeeHowItWorks: 'ボットを試して、どのように動作するかを確認しましょう！「Instagramでテスト」をクリックしてください。',
      howAreYouDoingToday: '今日はどうですか？',
      thisIsHowSimpleToUseInstagramBot: 'それだけです！これがInstagramボットを使用する方法です。',
      letsCreateYourOwnChatFlowNow: 'さあ、今あなた自身のチャットフローを作成しましょう。',
    },

    telegramBot: {
      back: '戻る',
      step: 'ステップ ',
      of3: ' / 3',
      seeHowToUse: '使用方法を見る',
      help: 'ヘルプ',
      loginToYourTelegramAccount: 'Telegramアカウントにログイン',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: '新しいボットを作成または既存のボットを接続するには、アカウントにログインしてください',
      verificationCodeSentOnRegisteredNumber: '登録された番号に確認コードが送信されました',
      createYourBot: 'ボットを作成',
      enterYourBotsNameAnd: `ボットの名前を入力し、接続して楽しんでください `,
      botUserNameMustEndWithBotAtTheEnd: 'ボットのユーザー名は最後に "bot" で終わる必要があります。',
      terrisBot: '例: teris_bot または terisbot',
      botNameAlreadyTaken: 'このボット名は既に使用されています。異なる名前を試してみてください。',
      connect: '接続',
      createBot: 'ボットを作成',
      codeNotReceivedResend: 'コードが受信されていませんか？ 再送信！',
      changeNumber: '番号を変更',
      telegramPreview: 'Telegramプレビュー',
      worldsFastestMessagingApp: `世界で最速のメッセージングアプリ ＆ それは無料です！`,
      bot: 'ボット',
      phoneNumber: '電話番号',
      userName: 'ユーザー名',
      none: 'なし',
      bio: 'バイオ',
      fewWordsAboutYourself: '自己紹介',
      settings: '設定',
      notificationsAndSound: '通知とサウンド',
      privacyAndSecurity: 'プライバシーとセキュリティ',
      dataAndStorage: 'データとストレージ',
      chatSettings: 'チャット設定',
      chatFolders: 'チャットフォルダ',
      devices: 'デバイス',
      connectYourBot: 'ボットを接続',
      createNewBotOrConnectWithExistingOne: '新しいボットを作成または既存のボットを接続',
      createNewBot: '新しいボットを作成',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand}へようこそ。私たちはあなたをサポートします。`,
      letsTryYourBotAndSeeHowItWorks: 'ボットを試して、どのように動作するかを確認しましょう！「Telegramでテスト」をクリックしてください。',
      howAreYouDoingToday: '今日はどうですか？',
      thisIsHowSimpleToUseTelegramBot: 'それだけです！これがTelegramボットを使用する方法です。',
      letsCreateYourOwnChatFlowNow: 'さあ、今あなた自身のチャットフローを作成しましょう。',
      placeholders: {
        searchCountry: '国を検索',
        enterTelegramMobileNumber: 'Telegramの携帯番号を入力',
        enterLoginCodeReceived: 'Telegramアカウントで受信したログインコードを入力してください *',
        twoFactorAuthentication: '2段階認証パスワード（オプション）',
        botName: 'ボット名 *',
        botUserName: 'ボットユーザー名 *',
      }
    },

    websiteLandingPageLiveChatBot: {
      createNewBot: '新しいボットを作成',
      seeHowToUse: '使用方法を見る',
      help: 'ヘルプ',
      back: '戻る',
      step: 'ステップ ',
      of2: ' / 2',
      buildA: '以下を構築:',
      thatMatchesYourBrandPersonality: 'ブランドの個性に合ったもの',
      chatBotIcon: 'チャットボットアイコン ',
      optional: 'オプション',
      themeColor: 'テーマカラー',
      chatBotName: 'チャットボット名',
      botNameIsRequired: 'ボット名は必須です',
      botNameShouldBeLessThan50Characters: 'ボット名は50文字未満である必要があります',
      continue: '続行',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand}へようこそ。私たちはあなたをサポートします。`,
      whatIsYourEmailAddress: 'あなたのメールアドレスは何ですか？',
      invalidEmailAddress: '無効なメールアドレス',
      customizeYourLandingPageBotLook: 'ランディングページボットの外観をカスタマイズ',
      customizeYourWidgetLook: 'ウィジェットの外観をカスタマイズ',
      customizeYourBotLook: 'ボットの外観をカスタマイズ',
      buildYourLandingPageBotAndAutomateInteraction: 'ランディングページボットを構築し、そのリンクを共有して顧客との対話を自動化します',
      useLiveChatBotToConnectToCustomers: 'ライブチャットボットを使用してリアルタイムで顧客に接続します',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'ChatBotをウェブサイトに接続し、訪問者との対話を自動化します',
      widget: 'ウィジェット',
      botInterface: 'ボットインターフェース',
      mergedBot: 'ウェブサイトチャットボット、ランディングページボット、モバイルアプリチャットボット',
      mergedBotToolTip: '訪問者の注意を引き付け、会話を活性化するためのボットページを作成します。',
      placeholders: {
        typeANameForYourBot: '新しいボットの名前を入力',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'ボット名を入力',
        mergedBotToolTip: '訪問者の注意を引き付け、会話を活性化するためのボットページを作成します。',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'チャットウィンドウのテーマカラーを選択',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `このロゴは ${brand ? brand : ''} プラットフォームでのみ使用されます。会社のロゴを選択することをお勧めします。1MB未満のPNGまたはJPGの正方形の画像を追加してください。`
      }
    },

    whatsappBot: {
      back: '戻る',
      justAStepAway: 'あと一歩',
      seeHowToUse: '使い方を見る',
      help: 'ヘルプ',
      createWhatsappChatBot: 'WhatsApp ボットを作成',
      businessNameIsRequired: 'ビジネス名は必須です。',
      maximumLengthForBusinessName40Characters: 'ビジネス名の最大長は40文字です。',
      businessCategory: 'ビジネスカテゴリ',
      categoryIsRequired: 'カテゴリは必須です。',
      descriptionIsRequired: '説明は必須です。',
      maximumLengthForDescription200Characters: '説明の最大長は200文字です。',
      pleaseFillAValidEmailAddress: '有効なビジネスメールアドレスを入力してください。',
      businessEmailIsRequired: 'ビジネスメールは必須です。',
      phoneNumberShouldHaveMinimum7Digits: '電話番号は最低7桁である必要があります',
      phoneNumberIsRequired: '電話番号は必須です。',
      websiteIsRequired: 'ウェブサイトは必須です。',
      maximumLengthForWebsite40Characters: 'ウェブサイトの最大長は40文字です。',
      maximumLengthForAddress150Characters: '住所の最大長は150文字です。',
      doYouHaveWhatsappApiKey: 'WhatsApp APIキーを持っていますか？',
      apiProvider: 'APIプロバイダ',
      three60Dialog: '360ダイアログ',
      whatsappCloud: 'WhatsAppクラウド',
      netcore: 'Netcore',
      apiKeyIsRequired: 'APIキーは必須です。',
      whatsappBusinessAccountIdIsRequired: 'WhatsApp Businness アカウントIDは必須です。',
      whatsappPhoneNumberIdIsRequired: 'WhatsApp Phone Number IDは必須です。',
      systemAccessTokenIsRequired: 'システムアクセストークンは必須です。',
      sourceIdIsRequired: 'ソースIDは必須です。',
      businessName: 'ビジネス名',
      businessAccount: 'ビジネスアカウント',
      businessDescription: 'ビジネス説明',
      address: '住所',
      businessEmail: 'ビジネスメール',
      websiteUrl: 'ウェブサイトURL',
      thankYouForYourInterestIn: 'にご興味を持っていただき、ありがとうございます',
      whatsappChatBot: ' WhatsApp チャットボット。',
      weHaveReceivedYourInterests: 'お問い合わせいただき、当社のセールスチームが24時間以内にご連絡いたします。',
      theyWillProvideYouWhatsappApiKey: 'WhatsApp APIキーを提供します。',
      readyToCreateWhatsappBot: 'WhatsAppボットの作成の準備ができました',
      exploreWhatsappFeatures: 'WhatsAppの機能を探索',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand}へようこそ。私たちはあなたをサポートします。`,
      letsTryYourBotAndSeeHowItWorks: 'ボットを試してみて、その動作を確認しましょう！ "WhatsAppでテスト"をクリックします。',
      howAreYouDoingToday: '今日はどうですか？',
      thisIsHowSimpleToUseWhatsappBot: 'これがWhatsAppボットの使用がどれほど簡単かです',
      letsCreateYourOwnChatFlowNow: 'さあ、独自のチャットフローを作成しましょう',
      connectWhatsAppBot: 'WhatsAppボットに接続',
      createtWhatsAppBot: 'WhatsAppボットを作成',
      proceed: '進む',
      whatsApiProvider: 'WhatsApp APIプロバイダ',
      cloudApi: 'クラウドAPI',
      netcoreApi: 'Netcore API',
      placeholders: {
        yourBusinessName: 'ビジネス名を入力',
        businessDescription: 'ビジネス説明',
        businessEmail: 'ビジネスメール',
        phone: '電話*',
        website: 'ウェブサイト（例：www.example.com）',
        address: '住所',
        enterApiKey: 'APIキーを入力',
        enterWhatsappBusinessAccountId: 'WhatsApp Business Account IDを入力',
        enterWhatsappPhoneNumberId: 'WhatsApp Phone Number IDを入力',
        enterSystemAccessToken: 'システムアクセストークンを入力',
        enterSourceId: 'ソースIDを入力',
        businessName: 'ビジネス名',
        businessWebsite: 'ビジネスウェブサイト',
        selectWhatsappApiProvider: 'WA APIプロバイダを選択',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'WhatsApp APIプロバイダを選択',
        doYouHaveWhatsAppBusinessAPI: 'WhatsApp Business APIをお持ちですか？',
        yes: 'はい',
        noIAmNotSure: 'いいえ、よくわかりません',
        selectApiProvider: 'APIプロバイダを選択',
        whatsAppCloudAPI: 'WhatsAppクラウドAPI',
        enterYourPhoneNumberId: '電話番号IDを入力',
        phoneNumberId: '電話番号ID',
        enterYourWhatsAppBusinessAccountId: 'WhatsApp Business Account IDを入力',
        whatsAppBusinessAccountId: 'WhatsApp Business Account ID',
        enterYourSystemUserToken: 'システムユーザートークンを入力',
        systemUserToken: 'システムユーザートークン',
        help: 'ヘルプ',
        netcoreAPI: 'Netcore API',
        enterYourSourceId: 'ソースIDを入力',
        sourceId: 'ソースID',
        enterYourNetcoreApiKey: 'Netcore APIキーを入力',
        netcoreApiKey: 'Netcore APIキー',
        submit: '送信',
        noneOftheAbove: 'どれでもない',
        next: '次へ'
      },
      stepTwo: {
        legallyRegisteredBusiness: '法的に登録されたビジネス',
        validWebsiteWithDataPrivacyPolicy: 'データプライバシーポリシーを備えた有効なウェブサイト',
        debit: 'デビット',
        creditCard: 'クレジットカード',
        gstin: 'GSTIN',
        onlyRequiredForIndianResidents: 'インドの居住者のみ必要',
        onlyRequiredFor: '必要なのは',
        indianResidents: 'インドの居住者',
        validPaymentMethod: '有効な支払方法',
        enabledWithInternationalPayments: '国際決済を有効にする',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'WhatsApp商取引ポリシーに準拠した有効なユースケース',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'WhatsAppがアクティブでないかつSMSを受信できる電話番号',
        discard: '破棄',
        proceed: '進む',
        whatsAppBotPrerequisites: 'WhatsAppボットの前提条件',
        letUsGetItDoneForYou: '私たちがやってしまいましょう！',
        ifYouAreFacingSomeIssues: '問題がある場合',
        ourTeamCanHelpYouDoTheSame: '私たちのチームが同じことを手伝ってくれます',
        requestACallbackNow: '今すぐコールバックをリクエスト！',
      },
      stepThree: {
        setupWhatsAppBot: 'WhatsAppボットのセットアップ',
        selectingTheBestBusinessManagerForYourNeeds: 'ニーズに合った最適なビジネスマネージャーの選択',
        createMetaApp: 'Metaアプリの作成方法',
        createaMetaAppOn: 'Metaアプリの作成方法',
        howToCreate: '作成方法',
        addWhatsAppNumber: 'WhatsApp番号の追加方法',
        addYourWhatsAppNumberAndVerifyTheSame: 'WhatsApp番号を追加し、同じ番号を確認する方法',
        subscribe: '登録',
        copyAndSubscribeWebhook: 'Webhookをコピーして登録',
        howToSubscribe: '登録方法',
        copy: 'コピー',
        appSecretId: 'アプリシークレットID',
        enterYourAppSecretId: 'アプリシークレットIDを入力',
        enterAppSecret: 'アプリシークレットを入力',
        phoneNumberId: '電話番号ID',
        whatsAppBusinessId: 'WhatsApp Business ID',
        assignSystemUser: 'システムユーザーの割り当て方法',
        assign: '割り当て',
        createApp: 'アプリを作成',
        registerAndVerify: '登録と確認',
        add: '追加',
        systemUserToken: 'システムユーザートークン',
        enterYourSystemUserToken: 'システムユーザートークンを入力',
        getAllNecessaryDetails: '必要なすべての詳細情報の取得方法',
        subscribeWhatsAppWebhook: 'WhatsAppウェブフックの登録方法',
        configureTheWebhookForWhatsAppAccount: 'WhatsAppアカウントのWebhookの設定方法',
        upadatePaymentMethod: '支払方法の更新方法',
        assignThePaymentMethodToWhatsAppAccount: '支払方法をWhatsAppアカウントに割り当てる方法',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'WhatsAppビジネスアカウントの制御権をシステムユーザーに付与する方法',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Metaダッシュボードからコピーする必要がある重要な詳細情報',
        updatePayment: '支払いの更新',
        letUsGetItDoneForYou: '私たちがやってしまいましょう！',
        ifYouAreFacingSomeIssues: '問題がある場合',
        ourTeamCanHelpYouDoTheSame: '私たちのチームが同じことを手伝ってくれます',
        requestACallbackNow: '今すぐコールバックをリクエスト！',
        update: '更新',
        connectNow: '今すぐ接続'
      },
      stepFive: {
        selectBusinessManager: 'ビジネスマネージャーを選択',
        selectingTheBestBusinessManagerForYourNeeds: 'ニーズに合った最適なビジネスマネージャーの選択'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'ボットがインストールされ、アクティブです',
        youhaveSuccessfullyConnected: '正常に接続されました',
        withWhatsApp: 'WhatsAppと',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'デフォルトで月に100の会話が割り当てられています',
        upgradeYourPlanForMore: '詳細についてはプランをアップグレードしてください。',
        createChatFlow: 'チャットフローを作成',
        upgradePlan: 'プランをアップグレード'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'QRコードをスキャンまたはボットをアクティブにするためにメッセージを送信',
        scanTheQrCodeFrom: 'QRコードをスキャンします',
        yourPhoneWillOpen: 'あなたの電話が、サンドボックス番号に送信するための事前記入メッセージを開きます。 WhatsAppで "送信" をクリックします',
        sendMessageFromYourWhatsAppNumber: 'WhatsApp番号からメッセージを送信',
        sendUsAWhatsAppMessageOn: 'WhatsAppメッセージを以下の番号に送信してください：',
        bySavingInYourContactsAndMessageShouldStartwith: '連絡先に保存し、メッセージは次で始まる必要があります：',
        send: '送信',
        or: 'または'
      }
    }

  },
  gettingStarted: {
    hello: 'こんにちは、',
    upgrade: 'アップグレード',
    gettingStarted: 'はじめに - ',
    yourFacebookBotToken: 'Facebookボットトークン ',
    hasBeenExpired: 'の期限が切れました',
    isExpiringIn: '有効期限が',
    days: '日後に切れます',
    facebookAuthenticationTokenRemainsFor60Days: 'Facebook認証トークンは60日間だけ有効です',
    kindlyProceedWithTheRenewalOfYourBotToken: 'ボットトークンの更新をお願いいたします。これにより、チャットボットサービスへの中断のないアクセスが確保されます。',
    renewYourBotToken: 'ボットトークンを更新',
    editYourChatFlow: 'チャットフローを編集',
    buildConversationEngagingBot: 'ユーザー向けの魅力的な会話フローを構築します。',
    takes5minutes: '5分かかります',
    previewYourLiveChatWidget: 'ライブチャットウィジェットをプレビュー',
    discoverHowThe: '以下は、',
    liveChatFeatureWorks: ' ライブチャット機能が動作する方法を確認します',
    takes2minutes: '2分かかります',
    takes1minute: '1分かかります',
    connected: '接続済み',
    customizeYourBotApperance: 'ボットの外観と機能をカスタマイズ',
    takes3minutes: '3分かかります',
    thirdPartyAppsIntegration: 'サードパーティのアプリの統合',
    integrateYourBot: 'ボットを他のアプリ/CRMと統合してビジネス自動化を作成',
    upgradeYourPlanToReceiveLeadsInEmail: '電子メールでリードを受け取るためにプランをアップグレード',
    getStartedWithUs: '当社と一緒に始めましょう',
    howToBuildAGoodChatFlow: '良いチャットフローを構築する方法',
    howToUseBotPenguinsInboxAndItsFeatures: 'BotPenguin Inboxとその機能の使用方法',
    botPenguinsPricing: 'BotPenguinの価格：プランと機能を探る',
    openHelpDesk: 'ヘルプデスクを開く',
    helpAndSupport: 'ヘルプとサポート',
    extensiveGuides: 'すべての疑問やワークフローに対する包括的なガイドは、当社の',
    resourceCentre: 'リソースセンター',
    checkoutOurAnswers: '以下で一般的なクエリへの回答を確認できます。',
    createANewBot: '新しいボットを作成',
    installYourBot: 'ボットをインストール',
    plansAndPricing: 'プランと価格',
    previewYourLandingPageBot: 'ランディングページボットをプレビュー',
    installYourLiveChatWidget: 'ライブチャットウィジェットをインストール',
    installYourWebsiteBot: 'ウェブサイト、モバイルアプリにボットをインストールまたはランディングページまたは組み込みボットとして起動',
    integrateBotToLandingPage: 'ボットをランディングページボットに統合',
    installWidgetToWebsite: 'ウェブサイトにウィジェットをインストール',
    installBotToWebsite: 'チャットボットをインストール',
    whatsappAutomation: 'WhatsApp自動化',
    facebookAutomation: 'Facebook自動化',
    telegramBotSettings: 'Telegramボット設定',
    liveChatWidgetSettings: 'ライブチャットウィジェット設定',
    landingPageBotSettings: 'ランディングページボット設定',
    websiteChatBotSettings: 'ウェブサイトチャットボット設定',
    instagramAutomation: 'Instagram自動化',
    diveDeeperIntoBotPenguin: 'BotPenguinをより詳しく探る',
    knowWhereWeAreGoing: '進む方向、最新のアップデート、バグの修正にご協力ください。',
    productUpdates: '製品のアップデート',
    requestAFeature: '機能のリクエスト',
    reportABug: 'バグの報告',
    prodcutRoadmap: '製品のロードマップ',
    trainYourChatbot: 'チャットボットのトレーニング',
    buildEngagingConversation: 'ユーザー向けの魅力的な会話フローを構築',
    previewYourAIBot: 'AIボットをプレビュー',
    integrateAIbot: 'ボットをAIボットに統合',
    aiBotSettings: 'AIボットの設定',
    CustomizeAIBot: 'ボットの外観と機能をカスタマイズ',
    gptKeyWarning: 'ChatGPT APIサービスへようこそ！各ボットには100のメッセージの制限があることに注意してください。100のメッセージ制限に達した場合は、引き続き当サービスをご利用いただくために独自のChatGPT APIキーを使用する必要があります。ChatGPT APIキーを入力するには、ChatGPTモデル構成に移動してください。',
    whatsappBotSettings: 'WhatsAppボット設定',
    automateCustomerEngagementWithWhatsApp: 'WhatsAppで顧客のエンゲージメントを自動化',
    connectToYourWhatsappNumber: 'WhatsApp番号に接続',
    connectYourWhatsappBotWithYourWhatsappNumber: 'WhatsAppボットをWhatsApp番号に接続',
    telegramAutomation: 'テレグラム自動化',
    automateCustomerEngagementWithTelegram: 'テレグラムで顧客のエンゲージメントを自動化',
    automateCustomerEngagementWithFacebook: 'Facebookを使用して顧客のエンゲージメントを自動化',
    facebookBotSettings: 'Facebookボット設定',
  },

  install: {
    previewYourLandingPageBot: 'ランディングページボットをプレビュー',
    preview: 'プレビュー',
    test: 'テスト',
    squareSpace: 'Squarespace',
    installYourChatBot: 'チャットボットをインストール',
    seeHowToUse: '使用方法を確認',
    help: 'ヘルプ',
    uniqueLinkToLandingPageBot: 'こちらはあなたのランディングページボットへのユニークなリンクです。',
    shareOnSocialMedia: '単にそれをソーシャルメディア、連絡先ページ、または名刺でユーザーと共有します。',
    or: 'または',
    copyUrl: 'URLをコピー',
    selectYourWebsiteCms: 'ウェブサイトのCMSを選択',
    selectCms: 'ウェブサイトを作成するために使用したCMS/ビルダーを選択します',
    custom: 'カスタム',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'その他のCMS',
    howWouldYouLikeToInstall: 'どのようにインストールしますか？',
    installingWebsiteBot: 'ウェブサイトにボットをインストールする方法を選択します',
    iWillAddMyself: '自分で追加します',
    getChatBotScript: '以下のチャットボットスクリプトコードを取得し、自分ですべてをインストールします',
    getWordpressPlugin: 'WordPressプラグインAPIキーを取得',
    getOurOfficialPlugin: '公式プラグインを取得 ',
    fromWordpressPluginStore: ' WordPressプラグインストアから',
    getPlugin: 'プラグインを取得',
    quickGuideToInstall: 'インストールのクイックガイド',
    placeApiKeyBelowIn: 'プラグインをダウンロードしてアクティブ化した後、以下のAPIキーを貼り付けます。',
    afterDownloadingAndActivatingPlugin: 'プラグインをダウンロードしてアクティブ化した後',
    copy: 'コピー',
    enterYourWebsite: 'ウェブサイトを入力し、チャットボットの外観を確認します',
    enterAValidUrl: '有効なURLを入力してください',
    verifyYourInstallation: 'インストールが成功したかどうかを確認しますか？',
    testInstallation: 'インストールをテスト',
    getChatBotScriptCode: 'チャットボットスクリプトコードを取得',
    copyThisCodeSnippet: 'このコードスニペットをコピーし、チャットボットを表示したいすべてのウェブページのHTMLに挿入します。',
    sendCodeToMyDeveloper: 'コードを開発者に送信',
    sendCodeToMyDevelopmentTeam: 'コードを開発チームに送信',
    sendEmail: 'メールを送信',
    enterAValidEmail: '有効なメールアドレスを入力してください',
    codeHasBeen: 'コードが ',
    toYourDeveloper: ' 開発者に送信されました ',
    resent: '再送信',
    sent: '送信済み',
    resendEmail: 'メールを再送信',
    ask: '問い合わせる ',
    supportTeamToInstall: ' サポートチームにインストールを依頼',
    raiseTheInstallationRequestTo: 'インストールリクエストを ',
    team: ' チームに提出',
    helpMeInstallingChatBot: 'チャットボットのインストールを支援してください',
    yourBotInstallationIsNotActive: 'ボットのインストールがアクティブではありません！',
    monthlyMessageLimitExceeded: '月間メッセージ制限を超過しました',
    exceededMonthlyMessageLimitUpgradeNow: '月間メッセージ制限を超過しました。今すぐアップグレードしてください。',
    upgradeNow: '今すぐアップグレード',
    botInstallationNotActivePleaseFix: 'ボットのインストールがアクティブではありません。修正してください。 ',
    incorrectBotScript: '不正確なボットスクリプト',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: '確認しているインストールコードがサイト上のコードと一致しません。正しいボットコードをインストールしたことを確認してください。',
    unableToSee: '以下が表示できません: ',
    codeOnYourWebsite: ' ウェブサイトのコード',
    unableToFindCodePleaseReinstall: 'ウェブサイト上でコードを見つけることができませんでした。再インストールしてください。',
    reinstallBot: 'ボットを再インストール',
    yourBotIsInstalledAndActive: 'ボットがインストールされ、アクティブです！',
    noPendingAction: '保留中のアクションはありません、ボットがインストールされ、アクティブです',
    youAreAllSet: '準備が整いました！',
    showMeMyLeads: 'リードを表示',
    connectingToYourWebsiteToVerifyInstallation: 'ウェブサイトに接続してボットのインストールを確認中',
    thisMayTakeUpto30Seconds: 'これには最大30秒かかる場合があります',
    hereIsTheuniqueAILink: 'こちらはあなたのAIボットへのユニークなリンクです',
    installHeading: 'チャットボットをインストール',
    websiteChatbot: 'ウェブサイトチャットボット',
    aiChatBot: 'AIチャットボット',
    landingPageBot: 'ランディングページボット',
    mobileChatBot: 'モバイルアプリチャットボット',
    embeddedBot: '埋め込みチャットボット',
    selectMobileDevice: 'モバイルデバイスを選択',
    mobileDeviceSubheading: 'モバイルアプリを作成するために使用したネイティブ言語を選択します',
    ios: 'IOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'その他',
    copyEmbeddedCodeSnippet: 'このコードスニペットをコピーし、チャットボットを表示したいすべてのウェブページに挿入します。',
    copyMobileCodeSnippet: 'このコードスニペットを、チャットボットを表示したいモバイルアプリケーションコードにコピーします。',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: '開発者のメールアドレスを入力'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: '開発者に指示を送信',
      notSureHowToDoIt: '方法がわからない？開発者がすぐに対応できます。',
      developersEmail: '開発者のメール',
      emailIsRequired: 'メールが必要です',
      subject: '件名',
      message: 'メッセージ',
      send: '送信',
      placeholders: {
        enterYourWorkEmail: '職場のメールアドレスを入力',
        createBotUsingBotPenguin: '興奮して！BotPenguinを使用してボットを作成'
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'こちらはあなたのランディングページボットへのユニークなリンクです。',
      shareWithUsersOnSocialMedia: '単にそれをソーシャルメディア、連絡先ページ、または名刺でユーザーと共有します。',
      previewMyLandingPageBot: 'ランディングページボットをプレビュー',
      or: 'または',
      copyUrl: 'URLをコピー',
      howYouLikeToAddBot: 'ボットコードをウェブサイトに追加する方法を選択します',
      installTheBotToYourCustomCodedOrWordPressWebsite: '独自のコード化されたウェブサイトまたはWordPressウェブサイトにボットをインストールして、一意のコードをコピーして貼り付けます。',
      name: '名前',
      actions: 'アクション',
      javascript: 'Javascript',
      install: 'インストール ',
      onYourCustomCodedWebsite: ' 独自のコード化されたウェブサイトに',
      copyAndPaste: 'コピーして貼り付け',
      copyThisCodeSnippet: 'このコードスニペットをコピーし、ウィジェットを表示したいすべてのウェブページのHTMLに挿入します。',
      seeGuide: 'ガイドを確認',
      getCode: 'コードを取得',
      howToInstall: 'インストール方法',
      copyCode: 'コードをコピー',
      wordpress: 'WordPress',
      // install: 'インストール ',
      onYourWordPressWebiste: ' WordPressウェブサイトに',
      preview: 'プレビュー',
      test: 'テスト',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'ライブチャット プレビュー',
    agentWindow: 'エージェントウィンドウ',
    person: '人',
    inOrderToTestTypeHere: 'テストするためにここに入力してください',
    agentWindowCannotBeCustomized: '注意: エージェントウィンドウはカスタマイズできません',
    customerWindow: '顧客ウィンドウ',
    clickHereToChat: 'チャットを開始するにはここをクリック',
    customerWillChatHere: '顧客はここでチャットします',
    chat: 'チャット',
    by: 'by',
    changeYourBotLookFromLeftPanel: '左パネルからボットの外観を変更します',
    placeholders: {
      typeYourMessage: 'メッセージを入力...',
      typeYourAnswer: '回答を入力',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'ライブチャットウィジェットをプレビュー',
    install: 'インストール',
    seeHowToUse: '使用方法を確認',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: '選択したプラットフォームにボットをインストールするにはクリック',
    }
  },

  trainYourBot: {
    trainYourBot: 'ボットを訓練する',
    knowledgeBase: 'ナレッジベース',
    faqs: 'FAQ',
    history: '履歴',
    faqHeading: 'よくある質問',
    faqSubHeading: 'ボット訓練に関する質問への回答',
    knowledgeBaseHeading: 'ボットの訓練',
    knowledgeBaseSubheading: 'より良い会話のためにボットを訓練する',
    websiteLink: 'ウェブサイトリンク',
    uploadFile: 'ファイルをアップロード',
    selectFile: 'ファイルを選択',
    submit: '送信',
    searchHere: 'ここで検索',
    question: '質問',
    answer: '回答',
    questionPlaceholder: 'ここに質問を入力',
    answerPlaceholder: 'ここに回答を入力',
    save: '保存',
    discard: '破棄',
    addFaq: 'FAQを追加',
    createdAt: '作成日',
    updatedAt: '最終更新日',
    actions: 'アクション',
    noFaqFound: 'まだ作成したFAQはありません',
    noFaqFoundMessage: 'すべてのFAQはここに表示されます',
    historyTitle: 'ナレッジベースの履歴',
    historySubtitle: 'ボット訓練に関する質問への回答',
    fileName: 'ファイル名',
    status: 'ステータス',
    noHistoryTitle: 'まだ訓練済みモデルがありません',
    noHistoryDescription: 'すべての訓練済みモデルはここに表示されます',
    fileUpload: 'ファイルアップロード',
    files: 'ファイル',
    fileSubHeading: 'ボットを訓練するために使用したいすべてのファイルを追加します。',
    noFileFoundHeading: 'まだ作成したファイルはありません',
    noFileFoundSubheading: 'すべてのファイルはここに表示されます',
    uploadYourFiles: 'ファイルをアップロード',
    upload: 'アップロード',
    fileShouldBe: 'ファイルはPDF、DOC、DOCX、PPTX、TXTである必要があります。',
    uploadStatus: 'アップロードステータス',
    uploadStatusSubHeading: 'ボットの訓練用のファイルのアップロードステータス',
    addFile: 'ファイルを追加',
    otherConfiguration: 'AI設定',
    deleteFiles: 'ファイルの削除',
    websiteURL: 'ウェブサイトURL',
    websiteUrlSubheading: 'ボットを訓練するために使用したいすべてのウェブサイトURLを追加します。',
    enterWebsiteUrl: 'URLを入力',
    addWebsite: 'ウェブサイトを追加',
    tableWebsiteUrl: 'ウェブサイトURL',
    trainingStatus: '訓練ステータス',
    lastTrained: '最終訓練',
    noWebsitesFound: 'まだ作成したウェブサイトはありません',
    noWebsitesFoundMessage: 'すべてのウェブサイトはここに表示されます',
    loadMore: 'もっと読み込む',
    botBehaviour: 'ボットの動作設定',
    botBehaviourSubHeading: 'ボットのパーソナリティを設定し、顧客データを非表示にし、フローとChatGPTモデルを処理します',
    botPersonality: 'ボットのパーソナリティを設定',
    botPersonalitySubHeading: 'ボットのパーソナリティに関する応答方法を管理します',
    selectAnswerLength: '回答の長さを選択',
    answerLength: '回答の長さ',
    answerAs: '回答方法',
    tone: 'トーン',
    language: '言語',
    answerFormatting: '回答のフォーマット',
    customInstructions: 'カスタムプロンプト',
    customerInstructionPlaceholder: '礼儀正しくフレンドリーであること。俗語は使用しないでください。絵文字は使用しないでください。',
    redactCustomerData: '顧客データの収集',
    redactDataSubheading: '収集したい顧客情報を設定します',
    collectName: '名前を収集',
    configureQuestion: '質問を設定',
    maxCharacterLimit: '提供された名前の最大文字制限',
    collectEmail: 'メールアドレスを収集',
    excludeEmail: 'メールアドレスのドメインを除外',
    collectPhone: '電話番号を収集',
    manageUnusualMessages: '異常なチャットシナリオの管理',
    unusualMessagesSubheading: 'ボットが回答できない場合やその他の珍しいシナリオを処理します',
    startConversation: '会話を開始するメッセージ',
    configureMessage: 'ボットが回答できない場合のメッセージ',
    profanityMessage: '不適切な言語の使用に対するメッセージ',
    chatGptModel: 'ChatGPTモデルの設定',
    chatGptModelSubheading: '使用するChatGPTモデルを管理し、誤用を防ぎます',
    chatGptApi: 'ChatGPT APIキー',
    enableDisableRedactData: '顧客データの収集',
    triggerToCollectData: '質問のセットの後に連絡先情報を収集します。',
    train: '訓練',
    website: 'ウェブサイト',
    analytics: 'アナリティクス',
    includedSourcesForResponse: 'レスポンスに含まれるソース',
    visibility: '可視性',
    yourConversation: 'あなたの会話',
    reviewAndSelectConversation: 'Chatbotトレーニング用にエクスポートする会話を確認して選択する',
    exportAiAnalytics: '分析データのエクスポート',
    startDate: '開始日',
    endDate: '終了日',
    userQuestion: 'ユーザーの質問',
    botsReply: 'ボットの返答',
    dateCreated: '作成日',
    noAnalyticsFound: 'まだ分析データが作成されていません',
    noAnalyticsFoundMessage: 'すべての分析データがここに表示されます',
    exportData: 'データのエクスポート',
    exportCompleteAnalyticsData: '完全な分析データのエクスポート',
    export: 'エクスポート',
    integrateWithChatGpt: 'ChatGPTの統合',
    integrated: '統合',
    configure: '設定',
    appToolTip: {
      deleteWebsite: 'ウェブサイトを削除',
      knowledgeBase: 'ナレッジベースでボットを訓練',
      faqs: 'FAQでボットを訓練',
      knowledgeHistory: 'ナレッジベースの履歴',
      uploadFaqs: 'FAQのアップロード',
      addFaqs: 'FAQを追加するにはクリック',
      deleteFaqs: 'FAQの削除',
      deleteHistory: '履歴の削除',
      uploadFile: 'アップロードされるファイルはPDF、DOCX、DOC、またはCSVである必要があり、サイズは最大1MBである必要があります。',
      trainWebsite: 'ウェブサイトを使用してボットを訓練します。',
      fileUpload: 'PDF、TXT、PPTX、DOC、DOCXなどのファイルを使用してボットを訓練します。',
      configureBotBehaviour: 'ボットのパーソナリティをカスタマイズし、フローを管理し、ChatGPTモデルを調整します。',
      clickToAddWebsite: 'ウェブサイトを追加するにはクリック',
      clickToDeleteWebsites: 'ウェブサイトを削除するにはクリック',
      syncWebsite: 'ウェブサイトの同期',
      copyUrl: 'URLをコピー',
      weWillBeFetchingAllTheWebpages: 'すべてのウェブページを取得し、それらを使用してChatGPTモデルを訓練します。'
    },
    modal: {
      deleteWebsiteUrl: 'ウェブサイトURLの削除',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'このウェブサイトURLを削除してもよろしいですか？'
    }
  },

  configuration: {
    websiteChatbotSetting: 'ウェブサイトチャットボットの設定',
    preview: 'プレビュー',
    test: 'テスト',
    install: 'インストール',
    designHeader: 'デザイン',
    generalHeader: '一般',
    alertsHeader: 'アラート',
    triggerHeader: 'トリガー',
    userAccessHeader: 'ユーザーアクセス',
    chatWindowHeader: 'チャットウィンドウ',
    otherConfiguration: 'AI設定',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: '選択したプラットフォームにボットをインストールする',
      customizeAppearanceOfBot: 'ボットの外観と位置をカスタマイズ',
      configureBotsSettingsWhenAgentUnavailable: 'エージェントが利用できない場合のボットの設定を構成する',
      configureHowBotWillAlertYou: '新しいリードの通知方法を設定',
      makeSureYourBotsPopUpProperlyTimed: 'ボットのポップアップが適切なタイミングで表示されることを確認',
      customizeWhereYouWantYourBotToAppear: 'ボットの表示場所と非表示場所をカスタマイズ',
      enableChatOptionsForVisitorsToTransferChat: '訪問者がチャットを転送、ライブチャット、チャットを更新できるようにチャットオプションを有効化',
      configureBotBehaviour: 'ボットのパーソナリティをカスタマイズし、フローを管理し、ChatGPTモデルを調整します。',
    },
    alerts: {
      configureYourBotsAlertSettings: 'ボットのアラート設定を構成',
      specifyHowYourBotWillNotify: '新しいリードについてのボットの通知方法を指定',
      subject: '件名',
      emailNotification: 'メール通知',
      emailAddresses: 'メールアドレス',
      leadRevisitNotification: 'リード再訪通知',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'デスクトップ通知の登録が成功しました',
      youHaveBlockedYourNotificationsOnBrowser: 'ブラウザの通知をブロックしました',
      discard: '破棄',
      saveChanges: '変更を保存',
      seeHowToUse: '使用方法を見る',
      placeholders: {
        enterSubjectForYourEmail: 'メールの件名を入力',
        typeYourEmailAddressAndHitEnter: 'メールアドレスを入力してEnterキーを押して追加',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'ボットからのメール通知の件名をカスタマイズ',
        TOOLTIP_EMAIL_NOTIFICATION: '新しいリードのためのボットのメール通知を有効/無効にする',
        TOOLTIP_EMAIL_ADDRESS: 'メール通知を受け取りたいアカウントのメールアドレスを送信します。Enterを押して別のメールアドレスを追加できます。',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'ユーザーが再びボットと対話した場合の新しいリードのボット通知を有効/無効にする',
      },
    },
    broadcasts: {
      createBroadcastHeading: 'ブロードキャストの作成',
      youCanCreateMultipleBroadcasts: '複数のブロードキャストを作成できます',
      broadcastName: 'ブロードキャスト名',
      broadcastNameIsRequired: 'ブロードキャスト名は必須です',
      continue: '続行',
      manageBroadcasts: 'ブロードキャストの管理',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: '異なる顧客グループ向けに複数のスケジュールされたテンプレートを持つ複数のブロードキャストを作成できます',
      createNewBroadcast: '新しいブロードキャストを作成',
      name: '名前',
      segment: 'グループ',
      template: 'テンプレート',
      contacts: '連絡先',
      send: '送信',
      delivered: '配信済み',
      read: '既読',
      failed: '失敗',
      broadcastAt: 'ブロードキャスト日時',
      status: 'ステータス',
      actions: 'アクション',
      nA: 'N/A',
      seeHowToUse: '使用方法を見る',
      deleteBroadcast: 'ブロードキャストを削除',
      wantToDeleteThisBroadcast: 'このブロードキャストを削除してもよろしいですか？',
      placeholders: {
        searchHere: 'ここで検索',
        enterBroadcastName: 'ブロードキャスト名を入力',
      },
      appTooltip: {
        deleteMultipleBroadcasts: '複数のブロードキャストを削除',
        broadcastAnalytics: 'ブロードキャスト分析',
        deleteBroadcast: 'ブロードキャストを削除',
      },
      broadcastAnalytics: {
        broadcastInsights: 'ブロードキャストインサイト',
        broadcastStatus: 'ブロードキャストステータス',
        youCanFindDetailsOfEachContactBelow: '各連絡先の詳細情報を以下で確認し、問題がある場合にエクスポートできます。',
        filterApplied: '適用されたフィルター',
        placeholders: {
          searchByPhoneNumber: '電話番号で検索',
          searchByName: '名前でフィルターを検索',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'ステータスに基づいてデータをフィルタリング',
          exportData: 'データのエクスポート',
        },
        modal: {
          exportData: 'データのエクスポート',
          exportCompleteBroadcastData: '完全なブロードキャストデータをエクスポート',
          export: 'エクスポート',
        }
      },
      createBroadcast: {
        broadcastName: 'ブロードキャスト名',
        broadcastNameIsRequired: 'ブロードキャスト名は必須です',
        template: 'テンプレート',
        segments: 'グループ',
        whenToSend: '送信時期',
        now: '今すぐ',
        later: '後で',
        selectBroadcastUsing: 'ブロードキャストの選択方法',
        usingCsv: 'CSVを使用',
        usingSegment: 'グループを使用',
        allContacts: 'すべての連絡先',
        selectDate: '日付を選択',
        selectTimeSlot: '時間帯を選択',
        assignVariablesToTemplates: 'テンプレートに変数を割り当てる',
        addHeaderMedia: 'ヘッダーメディアを追加',
        selectFile: 'ファイルを選択',
        cancel: 'キャンセル',
        createBroadcast: 'ブロードキャストを作成',
        broadcastUsing: 'ブロードキャストの使用',
        selectCSVFile: 'CSVファイルを選択',
        importContacts: '連絡先をインポート',
        beforeYouStartUpload: 'アップロードを開始する前に、以下を確認してください',
        point1: '1. サンプルCSVファイルをダウンロードします。',
        point2: '2. 2行目からデータを入力し始めます。',
        point3: '3. 各行には1つの連絡先のデータが含まれます',
        point4: '4. すべての電話番号が一意であることを確認してください。',
        point5: '5. ファイルの最大サイズ制限は20MBです',
        clickHere: 'ここをクリック',
        toDownloadSampleFile: 'サンプルファイルをダウンロード',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          selectTemplate: 'テンプレートを選択',
          searchTemplateByName: '名前でテンプレートを検索',
          selectSegments: 'グループを選択',
          selectTimeSlot: '時間帯を選択',
          selectValueForThisVariable: 'この変数の値を選択',
          searchHeaderByVariableName: '名前でヘッダー変数を検索',
          variableFallbackValue: '変数のフォールバック値',
          searchBodyVariableByName: '名前で本文変数を検索',
          searchSegmentByName: '名前でグループを検索',
          selectBroadcastUsing: 'ブロードキャストの選択方法',
        },
        indexMergeDatePicker: {
          selectStartDate: '開始日を選択',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'WhatsAppブロードキャスト用の連絡先をインポート',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'WhatsApp番号',
        errorCode: 'エラーコード',
        errorMessage: 'エラーメッセージ',
        nA: 'N/A',
      },
    },

    design: {
      customizeYourLandingPage: 'ランディングページのカスタマイズ',
      customizeYourConversationalBot: '対話型ボットのカスタマイズ',
      customizeYourBot: 'ボットのカスタマイズ',
      createHeadingTextAndTheme: 'ブランドの個性を反映する見出しテキストとテーマを作成',
      content: 'コンテンツ',
      theme: 'テーマ',
      layout: 'レイアウト',
      landingPageName: 'ランディングページ名',
      botName: 'ボット名',
      charactersRemaining: '文字 残り',
      landingPageHeader: 'ランディングページヘッダー',
      landingPageDescription: 'ランディングページの説明',
      hideContent: 'コンテンツを非表示',
      profilePic: 'プロフィール画像',
      selectFile: 'ファイルを選択',
      socialMediaLinks: 'ソーシャルメディアリンク',
      initialMessage: '対話型ボットの初期メッセージ',
      flowTriggerDetail: '連絡先の詳細情報を収集するためのトリガー',
      selectType: 'タイプを選択',
      url: 'URL',
      delete: '削除',
      editLink: 'リンクを編集',
      addLink: 'リンクを追加',
      callToAction: '行動を促す',
      liveChatWelcomeMessage: 'ライブチャットウェルカムメッセージ',
      liveChatTriggerMessage: 'ライブチャットトリガーメッセージ',
      autoHideCallToAction: '指定時間後に「行動を促す」テキストを自動的に非表示にする',
      maxDelayShouldBe300Seconds: '最大遅延時間は300秒である必要があります',
      landingPageThemeColor: 'ランディングページのテーマカラー',
      themeColor: 'テーマカラー',
      landingPageBackground: 'ランディングページの背景',
      chatBackgroundColor: 'チャットの背景カラー',
      color: 'カラー',
      gradient: 'グラデーション',
      image: '画像',
      video: 'ビデオ',
      gradientStart: 'グラデーション開始',
      gradientEnd: 'グラデーション終了',
      positionOnWeb: 'Web上の位置',
      positionOnMobile: 'モバイル上の位置',
      windowSize: 'ウィンドウサイズ',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'カスタム',
      height: '高さ',
      minimum300HeightIsRequired: '最小300pxの高さが必要です',
      heightCannotExceed720: '高さは720pxを超えることはできません',
      width: '幅',
      px: 'px',
      minimum200WidthIsRequired: '最小200pxの幅が必要です',
      widthCannotExceed1050: '幅は1050pxを超えることはできません',
      discard: '破棄',
      saveChanges: '変更を保存',
      landingPageBot: 'ランディングページボット',
      chatBot: 'チャットボット',
      preview: ' プレビュー',
      botIcon: 'ボットアイコン',
      websiteBot: 'ウェブサイトチャットボット',
      selectFont: 'フォントを選択',
      fontLibrary: 'フォントライブラリ',
      uploadFont: 'フォントをアップロード',
      selectFontForBot: 'ボットのフォントを選択',
      uploadedFont: 'アップロード済みフォント',
      placeholders: {
        enterName: '名前を入力',
        enterHeaderText: 'ヘッダーテキストを入力',
        enterDescription: '説明を入力',
        enterUrl: 'URLを入力',
        howCanIHelpYou: 'どのようにお手伝いできますか？',
        enterYourWelcomeMessageHere: 'ここにウェルカムメッセージを入力',
        enterYourLiveChatMessageHere: 'ここにライブチャットメッセージを入力',
        enterTimeInSeconds: '秒単位で時間を入力',
        enterHeight: '高さを入力',
        enterWidth: '幅を入力',
        flowInitialMessage: '初期質問を入力',
      },
      appTooltip: {
        standardLayout: '標準のレイアウト',
        rightLayout: '右側のレイアウト',
        mergedLayout: '統合されたレイアウト',
        enterNameOfYourBot: 'ボットの名前を入力',
        enterNameOfYourLandingPageBot: 'ランディングページボットの名前を入力',
        headingTextThatAppearOnLandingPageBot: 'ランディングページボットに表示される見出しテキストを入力',
        descriptionTextThatAppearOnLandingPageBot: 'ランディングページボットに表示される説明テキストを入力',
        addLinksAndSocialMediaIcons: 'リンクとソーシャルメディアアイコンを追加',
        initialFlowMessage: 'ボットが顧客との対話を開始する最初のメッセージを入力',
        anInvitingCatchPhrase: 'ユーザーをボットと対話させる誘い文句',
        setAWelcomeMessageForYourVisitors: '訪問者向けのウェルカムメッセージを設定します。これは対話の最初のメッセージです。',
        setAMessageToInitiateLiveChat: 'ライブチャットを開始するメッセージを設定します。このメッセージがトリガーされるたびに通知が届きます。',
        autoHideCallToAction: '指定した時間後に「行動を促す」テキストを自動的に非表示にする',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'ロゴとテキストを非表示にして、画面にチャットウィンドウのみを表示できます',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'チャットボットページのためのロゴをアップロードします。',
        TOOLTIP_CHAT_THEME_COLOR: 'ボットのチャットウィンドウのカラーを選択します',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'ボットのチャットウィンドウの背景色を選択します',
        TOOLTIP_WEB_1ST_POSITION: 'ボットをユーザーのデスクトップ画面の左下に配置します',
        TOOLTIP_WEB_2ND_POSITION: 'ボットをユーザーのデスクトップ画面の右中央に配置します',
        TOOLTIP_WEB_3RD_POSITION: 'ボットをユーザーのデスクトップ画面の右下に配置します',
        TOOLTIP_MOBILE_1ST_POSITION: 'ボットをユーザーのモバイル画面の左下に配置します',
        TOOLTIP_MOBILE_2ND_POSITION: 'ボットをユーザーのモバイル画面の右中央に配置します',
        TOOLTIP_MOBILE_3RD_POSITION: 'ボットをユーザーのモバイル画面の右下に配置します',
        TOOLTIP_WINDOW_HEIGHT: 'ボットのチャットウィンドウのサイズを調整します',
        TOOLTIP_BOT_ICON: 'ボットのアイコンまたはロゴ/画像を選択またはアップロードします',
        flowTriggerMessage: '連絡先の詳細情報を収集する前に質問するメッセージ数を入力します（名前、メールアドレス、電話番号など）',
      }
    },

    dripCampaigns: {
      createDripCampaignHeader: 'ドリップキャンペーンを作成',
      youCanCreateMultipleCampaigns: '複数のキャンペーンを作成できます',
      dripCampaignName: 'ドリップキャンペーン名',
      dripCampaignNameIsReuired: 'ドリップキャンペーン名は必須です',
      continue: '続行',
      manageDripCampaign: 'ドリップキャンペーンを管理',
      youCanCreateMultipleCampaignsWith: '特定のユーザーグループからの応答をトリガーするためのスケジュールされた自動メッセージを備えた複数のドリップキャンペーンを作成できます。',
      createNewDripCampaign: '新しいドリップキャンペーンを作成',
      campaignName: 'キャンペーン名',
      appliedOn: '適用',
      segment: 'グループ',
      template: 'テンプレート',
      startDate: '開始日',
      action: 'アクション',
      nA: '該当なし',
      seeHowToUse: '使用方法を見る',
      deleteDripCampaign: 'ドリップキャンペーンを削除',
      wantToDeleteThisDripCampaign: 'このドリップキャンペーンを削除してもよろしいですか？',
      placeholders: {
        enterDripCampaignName: 'ドリップキャンペーン名を入力',
        searchByName: '名前で検索',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: '複数のドリップキャンペーンを削除',
        deleteDripCampaign: 'ドリップキャンペーンを削除',
      },
      createDripCampaign: {
        dripCampaignName: 'ドリップキャンペーン名',
        appliedOn: '適用',
        segments: 'グループ',
        selectDate: '日付を選択',
        scheduleYourTemplate: 'テンプレートをスケジュール',
        schedule: 'スケジュール',
        template: 'テンプレート',
        sendAfter: '送信後',
        assignVariablesToTemplate: 'テンプレートに変数を割り当てる',
        addHeaderMedia: 'ヘッダーメディアを追加',
        selectFile: 'ファイルを選択',
        createDripCampaign: 'ドリップキャンペーンを作成',
        newSubscriber: '新しい購読者',
        minutes: '分',
        hours: '時間',
        days: '日',
        placeholders: {
          enterDripName: 'ドリップ名を入力',
          selectAppliedOn: '適用を選択',
          selectSegments: 'グループを選択',
          searchByName: '名前で検索',
          selectTemplate: 'テンプレートを選択',
          searchTemplateByName: '名前でテンプレートを検索',
          enterSendAfter: '送信後を入力',
          selectValueForThisVariable: 'この変数の値を選択',
          searchHeaderVariableByName: '名前でヘッダー変数を検索',
          searchBodyVariableByName: '名前で本文変数を検索',
        },
        dateTimePicker: {
          selectStartDate: '開始日を選択',
        }
      }
    },

    general: {
      configureLandingPageGeneralSettings: 'ランディングページの一般設定を構成します',
      configureBotsGeneralSettings: 'ボットの一般設定を構成します',
      selectTheTimeZone: 'タイムゾーン、言語、およびカスタマーへの少しのメモを選択してください',
      isNotAvailable: 'は利用できません',
      landingPagesBot: 'ランディングページのボット',
      bot: 'ボット',
      waitingMessage: '待機メッセージ',
      landingPageBotStatus: 'ランディングページボットのステータス',
      chatBotStatus: 'チャットボットのステータス',
      unavailabilityMessage: '利用不可メッセージ',
      sendIncompleteResponses: '不完全な応答を送信',
      language: '言語',
      invalidateCache: 'キャッシュを無効にする',
      timezone: 'タイムゾーン',
      chatWindowMessageHistory: 'チャットウィンドウメッセージ履歴',
      session: 'セッション',
      forever: '永遠',
      english: '英語',
      spanish: 'スペイン語',
      french: 'フランス語',
      portuguese: 'ポルトガル語',
      german: 'ドイツ語',
      italian: 'イタリア語',
      swedish: 'スウェーデン語',
      arabic: 'アラビア語',
      malay: 'マレー語',
      russian: 'ロシア語',
      discard: '破棄',
      saveChanges: '変更を保存',
      seeHowToUse: '使用方法を確認',
      placeholders: {
        enterYourWaitingMessageHere: 'ここに待機メッセージを入力',
        searchTimeZone: 'タイムゾーンを検索',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'ライブチャットのリクエストを送信した後にチャットウィンドウに表示されるメッセージをカスタマイズします',
        TOOLTIP_CHATBOT_STATUS: 'ボットのアクティベート/非アクティベート',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'ボットユーザー向けのライブチャット用にエージェントが利用できない場合に表示されるメッセージをカスタマイズします',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'ボットユーザーが会話を完了していない場合でも、メール通知を送信します',
        TOOLTIP_LANGUAGE: 'ボットの言語を選択します',
        TOOLTIP_INVALIDATE_CACHE: 'ボットのチャットフローや設定を編集する際にキャッシュをクリアするにはここをクリックします。変更は15分後に反映されます。',
        TOOLTIP_TIME_ZONE: 'ボットのタイムゾーンを選択します',
        TOOLTIP_CHAT_RETENTION: 'ボットユーザーのチャットをチャットウィンドウにブラウザーセッションの間または永久に保持するかどうかを選択します',
      }
    },

    liveChat: {
      customizeYourChatWindow: 'チャットウィンドウをカスタマイズ',
      controlAndCustomizeTheChatWindow: '顧客が対話するチャットウィンドウを制御およびカスタマイズします。',
      allowVisitorsToTransferChatToWhatsapp: '訪問者にチャットをWhatsAppに転送する許可を与える',
      allowVisitorsToRefreshChat: '訪問者にチャットのリフレッシュを許可する',
      displayNameForTransferringChatToWhatsapp: 'WhatsAppにチャットを転送する際の表示名',
      displayNameForTransferringToLiveChat: 'ライブ/エージェントチャットへの転送の表示名',
      displayNameForTransferringAChatToBot: 'ボットにチャットを転送する際の表示名',
      discard: '破棄',
      saveChanges: '変更を保存',
      allowVisitorsToTransferChatToBot: '訪問者にチャットをボットに転送する許可を与える',
      allowVisitorsToRequestForLiveChat: '訪問者にライブチャットをリクエストする許可を与える',
      indicativeTextForCustomersReply: `顧客の返信の指示テキスト`,
      displayNameForRestartingChatBot: 'チャットボット会話を再開する際の表示名',
      seeHowToUse: '使用方法を見る',
      placeholders: {
        enterButtonNameForWhatsapp: 'WhatsAppへの転送ボタン名を入力',
        enterButtonNameForLiveChat: 'ライブチャットまたはエージェントへの転送ボタン名を入力',
        enterButtonNameForBot: 'ボットへの転送ボタン名を入力',
        enterTextDisplayInEmptyReplyArea: '空の返信エリアに表示するテキストを入力',
        enterButtonForRestartingConversation: '会話を再開するためのボタン名を入力',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'ウェブサイト訪問者が接続している対応するエージェントのWhatsAppにチャットを転送できるようにします。この機能を使用するにはライブチャット機能を有効にする必要があります。',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'ユーザーがチャットをリフレッシュできるようにし、新しい会話を開始できるようにします。',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'ユーザーがライブチャットエージェントとの会話中にいつでもチャットをボットに転送できるようにします。',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'ユーザーが希望するときにライブチャットエージェントとのライブチャットをリクエストできるようにします。',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'ユーザーがWhatsAppに転送するオプションを有効にした場合、そのオプションを表示する方法を入力してください。',
        ifYouHaveEnabledUserToTransferToLiveChat: 'ユーザーがライブチャットに転送するオプションを有効にした場合、そのオプションを表示する方法を入力してください。',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'ユーザーがWhatsAppに転送するオプションを有効にした場合、ボットに転送する方法を入力してください。',
        howWouldYouLikeToDisplayReplySpaceForCustomers: '顧客向けの返信スペースを表示する方法を入力してください。',
        ifYouHaveEnabledUserToRestartChatBot: 'ユーザーがチャットボットの会話を再開するオプションを有効にした場合、そのオプションを表示する方法を入力してください。',
      }
    },

    socialPlatforms: {
      chatFlowsHeader: 'チャットフロー',
      dripCampaignHeader: 'ドリップキャンペーン',
      widgetsHeader: 'ウィジェット',
      broadcastHeader: 'ブロードキャスト',
      ordersHeader: '注文',
      manageHeader: '管理',
      inputHeader: '入力',
      apiKeyHeader: 'APIキー',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'スケジュールされたチャットフローのドリップキャンペーンを使用して、顧客のオンボーディングジャーニーを作成します。',
        TOOLTIP_CHAT_FLOW_TITLE: 'チャットフローテンプレートをカスタマイズし、異なるターゲットオーディエンス向けに複数のチャットフローを作成します。',
        TOOLTIP_WIDGET_TITLE: 'ウィジェット（チャットインターフェース）を作成し、訪問者をボットに誘導するためにプロモーションウェブページに埋め込みます。',
        TOOLTIP_BROADCAST_TITLE: '予定時刻に1クリックで連絡先にメッセージまたはフローを一斉送信します。',
        TOOLTIP_ORDERS_TITLE: '顧客が注文した注文のリスト',
        TOOLTIP_MANAGE_TITLE: '連絡先/リードおよびキーワードのためのボット機能を管理します',
        TOOLTIP_INPUT_TITLE: '連絡先/リード向けのWhatsAppテンプレート、画像、テキストを作成します。',
      },
      apiWrapper: {
        whatsappApiCredentials: 'WhatsApp API認証情報',
        generateYourWhatsappApiKey: 'カスタム開発アプリと統合するためにWhatsApp APIキーを生成します。',
        generateApiKey: 'APIキーを生成',
        webhookUrl: 'Webhook URL',
        addHeader: 'ヘッダーを追加',
        test: 'テスト',
        webhookNotSet: 'Webhookが設定されていません',
        webhookHeaders: 'Webhookヘッダー',
        setWebhookUrl: 'Webhook URLを設定',
        placeholders: {
          yourApiKey: 'APIキーを入力',
          enterWebhookUrl: 'Webhook URLを入力',
          enterHeaderKey: 'ヘッダーキーを入力',
          enterValue: '値を入力',
        }
      },
      broadcast: {
        createBroadcast: 'ブロードキャストの作成',
        createMultipleBroadcasts: '異なる顧客グループ向けに複数のブロードキャストとスケジュールされたフロー/テンプレートを作成できます。',
        broadcastName: 'ブロードキャスト名',
        nameIsRequired: '名前は必須です',
        selectType: 'タイプを選択',
        selectYourFlowType: 'フロータイプを選択',
        segment: 'グループ',
        selectYourSegmentType: 'グループタイプを選択',
        whenToSend: '送信タイミング',
        selectDate: '日付を選択',
        messageTag: 'メッセージタグ',
        selectMessageTag: 'メッセージタグを選択',
        contentType: 'コンテンツタイプ',
        nonPromotional: '非プロモーション（メッセージタグが適用）',
        tagEachMessgeThatComesBeforeAnyInteraction: 'インタラクションまたは24時間を超える遅延ステップの前に送信されるメッセージを適切なタグでタグ付けします。タグのないメッセージは、24時間以上前にボットと対話したサブスクライバーに届かないでしょう。',
        promotional: 'プロモーション（メッセージタグは無視）',
        theseBroadcasts: 'これらのブロードキャストにはプロモーションが含まれる場合がありますが、対象の受信者は最後の24時間以内にボットと対話したサブスクライバーに限られます。',
        createNewBroadcast: '新しいブロードキャストを作成',
        sentMessages: '送信済みメッセージ',
        deliveredMessages: '配信済みメッセージ',
        readMessages: '読み取り済みメッセージ',
        failedMessages: '送信失敗メッセージ',
        type: 'タイプ',
        template: 'テンプレート',
        sent: '送信済み',
        delivered: '配信済み',
        read: '読み取り済み',
        failed: '送信失敗',
        broadcastAt: 'ブロードキャスト日時',
        status: 'ステータス',
        shipmentUpdate: '出荷更新',
        flow: 'フロー',
        resendFailedMessages: '送信失敗メッセージを再送信',
        goBack: '戻る',
        number: '番号',
        errorCode: 'エラーコード',
        failedAt: '送信失敗日時',
        badGateway: '502 Bad Gateway',
        loadMore: 'さらに読み込む',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          searchBroadcastByName: 'ブロードキャスト名で検索',
        },
        appTooltip: {
          totalBroadcastMessagesSent: '送信された合計ブロードキャストメッセージ数',
          totalBroadcastMessagesDelivered: '配信された合計ブロードキャストメッセージ数',
          totalBroadcastMessagesReadByContacts: '受信者によって読まれた合計ブロードキャストメッセージ数',
          totalFailedBroadcastMessages: '送信失敗したブロードキャストメッセージの合計数',
          clickToViewDetailedInformationOfFailedMessages: '詳細な送信失敗メッセージ情報を表示するにはクリック',
          clickToCreateNewBroadcast: '新しいブロードキャストを作成するにはクリック',
          templateUsedInBroadcast: 'ブロードキャストで使用されるテンプレート',
          numberOfBroadcastMessagesSent: '送信されたブロードキャストメッセージ数',
          numberOfBroadcastMessagesDelivered: '配信されたブロードキャストメッセージ数',
          numberOfBroadcastMessagesReadByContacts: '受信者によって読まれたブロードキャストメッセージ数',
          numberOfTimesBroadcastMessagesHasFailed: 'ブロードキャストメッセージの送信に失敗した回数',
          contactNumberOnWhichMessageDeliveryFailed: 'メッセージ配信に失敗した連絡先番号',
          unDeliveredTemplate: '未配信のテンプレート',
          errorReceivedForFailedMessage: '送信失敗したメッセージのエラー情報',
          timeWhenMessageDeliveryFailed: 'メッセージ配信が失敗した時刻',
          TOOLTIP_ADD_BROADCAST_NAME: '新しいブロードキャスト名を入力',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'フローまたはメッセージのブロードキャストを選択',
          TOOLTIP_BROADCAST_FLOW: 'ブロードキャストするチャットフローを選択',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'ブロードキャスト対象の連絡先グループを選択',
          TOOLTIP_BROADCAST_NAME: 'ブロードキャスト名',
          TOOLTIP_BROADCAST_TYPE: 'ブロードキャストに選択されたフローまたはメッセージ',
          TOOLTIP_BROADCAST_SEGMENT: 'ブロードキャストが作成されたグループ',
          TOOLTIP_BROADCAST_AT: 'ブロードキャストがスケジュールされた日時',
          TOOLTIP_BROADCAST_STATUS: 'ブロードキャストのステータス（スケジュール済み、実行中、完了）',
        }
      },
      drip: {
        createDripCampaign: 'ドリップキャンペーンの作成',
        youCanCreateMultipleCampaigns: 'スケジュールされた自動メッセージをトリガーし、特定のユーザーグループからの応答を引き起こすために、複数のドリップキャンペーンを作成できます。',
        dripCampaignName: 'ドリップキャンペーン名',
        dripCampaignNameIsRequired: 'ドリップキャンペーン名は必須です',
        campaignName: 'キャンペーン名',
        campaignNameIsRequired: 'キャンペーン名は必須です',
        after: '次の条件を満たした場合',
        thisMessageWillBeSendAfter: 'このメッセージは次の条件を満たした後に送信されます',
        saveAndUpdate: '保存して更新',
        schedule: 'スケジュール',
        select: '選択',
        messages: 'メッセージ',
        cancel: 'キャンセル',
        createCampaign: 'キャンペーンの作成',
        updateCampaign: 'キャンペーンの更新',
        createNewCampaign: '新しいキャンペーンの作成',
        ofMessages: 'メッセージ数',
        triggered: 'トリガーされました',
        stepsFinsished: 'ステップ完了',
        finished: '完了',
        createdOn: '作成日',
        lastModified: '最終更新日',
        defaultFlow: 'デフォルトフロー',
        action: 'アクション',
        placeholders: {
          nameYourDripCampaign: 'ドリップキャンペーン名を入力',
          enterCampaignName: 'キャンペーン名を入力',
          searchCampaignByName: 'キャンペーン名で検索',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: '新しいキャンペーンを追加するにはクリック',
          TOOLTIP_SCHEDULE: 'このキャンペーンの最初の送信時期と送信するチャットフローを計画します。',
          TOOLTIP_AFTER_DAYS: 'キャンペーンの最初のフローを何分、何時間、または何日後に送信するかをスケジュールします。',
          TOOLTIP_ADD_MESSAGES: 'キャンペーンに新しいフローを追加するにはクリック',
          TOOLTIP_ADD_CAMPAIGN_NAME: '新しいキャンペーン名を入力します',
          TOOLTIP_CAMPAIGN_NAME: 'キャンペーン名',
          TOOLTIP_NO_MESSAGES_SENT: 'キャンペーンによって送信されたメッセージ数',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'キャンペーンの作成日時',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'キャンペーンの最終更新日時',
          TOOLTIP_CAMPAIGN_ACTION: 'キャンペーンを編集または削除します',
          TOOLTIP_UPDATE_CAMPAIGN: 'キャンペーン設定で行った変更を保存するにはクリック',
        }
      },
      flows: {
        createChatFlow: 'チャットフローの作成',
        youCanCreateMultipleConversationsFlow: '異なるチャネルにわたる複数の会話フローを作成してA/Bテストやブロードキャストを行うことができます。',
        flowName: 'フロー名',
        nameIsRequired: '名前は必須です',
        cancel: 'キャンセル',
        createFlow: 'フローの作成',
        createNewFlow: '新しいフローの作成',
        ofMessages: 'メッセージ数',
        triggered: 'トリガー',
        stepsFinsished: 'ステップ完了',
        finished: '完了',
        createdOn: '作成日',
        lastModified: '最終更新日',
        defaultFlow: 'デフォルトフロー',
        action: 'アクション',
        flowAnalytics: 'フローアナリティクス',
        placeholders: {
          enterFlowName: 'フロー名を入力',
          searchFlowByName: 'フロー名で検索',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'チャットフローがトリガーされた回数',
          numberOfCompletedChatFlowStepsWhenTriggered: 'トリガーされた際の完了したチャットフローステップの回数',
          numberOfTimesUsersCompletedChatFlowSteps: 'ユーザーがチャットフローステップを完了した回数',
          dateAndTimeOfCreationOfChatFlow: 'チャットフローの作成日時',
          dateAndTimeWhenChatFlowLastModified: 'チャットフローが最後に変更された日時',
          TOOLTIP_CREATE_FLOW: '新しいチャットフローを作成するにはクリック',
          TOOLTIP_FLOW_NAME: 'チャットフロー名を入力します',
          TOOLTIP_FLOW_TABLE_NAME: 'チャットフローの名前',
          TOOLTIP_NO_OF_MESSAGES: 'チャットフロー内のメッセージコンポーネントの数',
          TOOLTIP_DEFAULT_FLOW: 'このトグルを使用して、デフォルトのチャットフローを設定し、新しいユーザーに提供します。',
          TOOLTIP_ACTION: 'チャットフローを編集または削除します',
        }
      },
      manage: {
        leads: 'リード',
        manageBotsFunctionalityForContacts: '連絡先/リードのボット機能を管理します',
        whenToSendLeadInfo: 'リード情報を送信するタイミング',
        smartFlow: 'スマートフロー',
        welcomeMessageWhenNoSearchKeywordIsMet: 'キーワード検索が満たされない場合のウェルカムメッセージ',
        selectTemplates: 'テンプレートを選択',
        selectFlows: 'フローを選択',
        selectText: 'テキストを選択',
        selectImages: '画像を選択',
        whenItAlmostReaches24HoursSinceMessage: '最後のメッセージから24時間が経過する直前',
        updateChanges: '変更を保存',
        manageBotsFunctionalityAccordingToKeywords: 'キーワードに基づいてボットの機能を管理します（スマートフローの場合）',
        addKeywordAction: 'キーワードアクションを追加',
        keywords: 'キーワード',
        matchingMethod: '一致方法',
        replyMaterial: '返信素材',
        action: 'アクション',
        select: '選択',
        placeholders: {
          keyword: 'キーワード'
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'ボットが連絡先/リード情報を統合CRMにすぐに送信するか、5分後に送信するか、フローの最後に送信するかを提案します。',
          TOOLTIP_LEAD_SMART_FLOW: 'すべてのボットユーザーに送信するためのデフォルトのフローを選択するか、ボットユーザーの入力によって決まるスマートフローを選択します。',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'ボットがボットユーザーの応答にキーワードの一致がない場合に送信するリプライ素材（フロー、テンプレート、テキスト、画像）を選択します。',
          TOOLTIP_IT_ALMOST_REACHED: '最後のメッセージから24時間前に連絡先/リードに送信するリプライ素材を選択します。',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'スマートフローの場合、ボットユーザーの入力に基づいてボットの機能を管理します。',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: '特定のキーワードに対してテンプレート、フロー、テキスト、または画像としてボットの応答を選択します。',
          TOOLTIP_ADD_KEYWORD: 'キーワードを追加',
          TOOLTIP_MATCHING_METHOD: 'キーワードの一致方法を「完全一致」または「含む」で追加します。',
          TOOLTIP_REPLY_MATERIAL: '提案されたキーワードに対するテンプレート、フロー、テキスト、または画像を選択します。',
          TOOLTIP_KEYWORD_ACTIONS: 'キーワードアクションを削除します。',
        },

        socialAlerts: {
          alerts: 'アラート',
          specifyHowYourBotWillNotify: '新しいリードに関してボットが通知する方法を指定してください。'
        },
      },
      orders: {
        manageCustomersOrder: '顧客注文の管理',
        checkAndManageAllOfYourCustomerOrders: 'すべての顧客注文とアップデートを確認および管理します。',
        selectShipmentTemplate: '出荷テンプレートを選択',
        send: '送信',
        export: 'エクスポート',
        phoneNumber: '電話番号',
        numberOfItems: 'アイテム数',
        orderValue: '注文金額',
        orderDate: '注文日',
        status: 'ステータス',
        chat: 'チャット',
        item: 'アイテム',
        quantity: '数量',
        amount: '金額',
        unitPrice: '単価',
        placeholders: {
          searchOrdersByPhoneNumber: '電話番号で注文を検索',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'CSVファイルで注文データをエクスポート',
          TOOLTIP_ORDERS_PHONE_NO: '注文を行った顧客の電話番号',
          TOOLTIP_NO_OF_ITEMS: '注文されたアイテムの数',
          TOOLTIP_ORDER_VALUE: '注文の金額',
          TOOLTIP_ORDER_DATE: '注文が行われた日付。',
          TOOLTIP_ORDER_STATUS: '確認済み、保留中、拒否された、または発送済みの注文のステータス',
          TOOLTIP_ORDER_CHAT: 'この注文に関するすべての以前のチャット会話',

        },
      },
      widgets: {
        installWidgetHeader: 'Install Widget',
        createWidgetHeader: 'Create Widget',
        copyTheCodeAndPasteItWhereYouWant: 'Copy the code and paste it whereever you need in your HTML, website etc.',
        youCanCreateMultipleWidgets: 'You can create multiple widgets to give your bot a different appearance on different web pages.',
        installWidget: {
          javascript: 'Javascript',
          install: 'Install ',
          onYourWebsite: ' on your website',
          copyCode: 'Copy code',
          copyAndPaste: 'Copy and paste',
          copyThisCodeSnippet: 'Copy this code snippet and insert it in the HTML of every web page where you want to'
            + ' display the Widget. For more details',
          seeGuide: 'see guide',
        },
        widgetList: {
          widgetName: 'ウィジェット名',
          nameIsRequired: '名前は必須です',
          cancel: 'キャンセル',
          createWidget: 'ウィジェットを作成',
          icon: 'アイコン',
          name: '名前',
          createdAt: '作成日時',
          action: 'アクション',
          placeholders: {
            enterWidgetName: 'ウィジェット名を入力してください',
            searchWidgetByName: 'ウィジェットを名前で検索',
          },
          appTooltip: {
            widgetIcon: 'ウィジェットアイコン',
            nameOfWidget: 'ウィジェットの名前',
            dateAndTimeOfCreationOfWidget: 'ウィジェットの作成日時',
            editOrDeleteWidget: 'ウィジェットを編集または削除',
          }
        },
        widgetTemplates: {
          widgetName: 'ウィジェット名',
          nameIsRequired: '名前は必須です',
          chooseTypeOfWidget: 'ウィジェットの種類を選択',
          bar: 'バー',
          slideIn: 'スライドイン',
          pageTakeOver: 'ページ占拠',
          button: 'ボタン',
          facebookModal: 'Facebook モーダル',
          comment: 'コメント',
          direct: '直接',
          whatsappIcon: 'WhatsApp アイコン',
          refUrl: '参照 URL',
          qrCode: 'QR コード',
          backgroundColor: '背景色',
          descriptionColor: '説明文の色',
          headlineColor: '見出しの色',
          buttonBackgroundColor: 'ボタンの背景色',
          contactNumber: '連絡先番号',
          entryMessage: 'エントリーメッセージ',
          buttonText: 'ボタンテキスト',
          selectButtonText: 'ボタンテキストを選択',
          buttonSize: 'ボタンサイズ',
          selectOne: '選択してください',
          placement: '配置',
          rightBottom: '右下',
          optInFlow: 'オプトインフロー',
          selectFlow: 'フローを選択',
          addSubscriberToSequence: 'サブスクライバーをシーケンスに追加',
          selectDrip: 'ドリップを選択',
          whenDoesItDisplay: '表示タイミング（秒）',
          showWidgetToSameUserAgainAfter: '同じユーザーに再表示する時間',
          ifManuallyClosedByUser: 'ユーザーが手動で閉じた場合、再表示する時間',
          specificPosts: '特定の投稿',
          allPosts: 'すべての投稿',
          postMessage: '投稿メッセージ',
          postDate: '投稿日',
          status: 'ステータス',
          action: 'アクション',
          discard: '破棄',
          saveChanges: '変更を保存',
          nextStep: '次のステップ',
          widgetDescription: 'ウィジェットの説明',
          integrateTheBarWidget: `バーウィジェットをウェブサイトの本文に統合し、ページのサブスクライバーを増やし、カスタムボットメッセージフローを送信します。`,
          hereIsYourWidgetHeadline: 'こちらがウィジェットの見出しです。クリックして変更してください。',
          sendUsMessage: 'メッセージを送信',
          weAlsoPutDefaultTextHere: 'こちらにもデフォルトのテキストが入っています。それを有用なメッセージに変えてください。',
          selectYourPagePost: 'ページの投稿を選択',
          nA: 'N/A',
          saveRule: 'ルールを保存',
          placeholders: {
            enterWidgetName: 'ウィジェット名を入力してください',
            enterNumber: '番号を入力してください',
            enterMessageHere: 'ここにメッセージを入力してください',
            enterTimeInSeconds: '秒単位で時間を入力してください',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'ボットのトリガー設定をカスタマイズ',
      makeYourBotsTimingPerfect: 'ボットのタイミングを完璧に設定',
      onDesktopTriggerTime: 'デスクトップのトリガータイム（秒）',
      onMobileTriggerTime: 'モバイルのトリガータイム（秒）',
      chatBubble: 'チャットバブルの自動オープン',
      seconds: '秒',
      discard: '破棄',
      saveChanges: '変更を保存',
      autoTriggerOnOrOff: '自動トリガー：オン/オフ',
      seeHowToUse: '使用方法を見る',
      alwaysOpenAfter: '上記の指定されたトリガー後に常に開く',
      automaticallyAfterSelectedNumberOfTime: '選択した時間が経過後に自動的に開く',
      neverAutomaticallyOpenBot: 'ボットを自動的に開かない',
      placeholders: {
        enterTimeInSeconds: '秒単位で時間を入力',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: '訪問者のデスクトップデバイスで、ボットが自動的にポップアップする前の待機時間（秒）',
        TOOLTIP_MOBILE_TRIGGER_TIME: '訪問者のモバイルデバイスで、ボットが自動的にポップアップする前の待機時間（秒）',
        TOOLTIP_CHAT_BUBBLE_OPENING: '訪問者がチャットウィンドウを閉じた場合、ボットの自動ポップアップ動作をどのようにするかを選択',
        customerWaitingTimeToOpenBubble: 'チャットバブルを開くための顧客待機時間',
        wantToDisableAutoTriggering: 'ボットの自動トリガーを無効にしたい場合はクリックしてください'
      }
    },

    userAccess: {
      customizeYourBotsSettings: 'ボットのユーザー設定をカスタマイズ',
      decideWhoCanAccessYourBot: '誰がボットにアクセスできるかを決定',
      deviceSupport: 'デバイスサポート',
      blockIpAddresses: 'IPアドレスをブロック',
      hideChatBotOnSpecificPages: '特定のページでチャットボットを非表示にする',
      selectCountriesToEnableBot: 'ボットを有効にする国を選択',
      discard: '破棄',
      saveChanges: '変更を保存',
      seeHowToUse: '使用方法を見る',
      allDevices: 'すべてのデバイス',
      mobileOnly: 'モバイルのみ',
      desktopOnly: 'デスクトップのみ',
      placeholders: {
        enterIpAddress: 'IPアドレス（IPv4またはIPv6）を入力し、「Enter」を押してください',
        enterUrl: 'ページのURLを入力し、「Enter」を押してください',
        searchCountry: '国を検索',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'ボットが表示されるデバイスを選択します',
        TOOLTIP_BLOCK_IP_ADDRESS: 'ボットが表示されないIPアドレスを入力してください',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'ボットが表示されないウェブページのURLを入力してください',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'ユーザーがボットにアクセスできる場所を選択します',
      }
    },

    facebook: {
      facebookBotSettings: 'Facebookボット設定',
      chatFlowsHeader: 'チャットフロー',
      dripCampaignHeader: 'ドリップキャンペーン',
      broadcastHeader: 'ブロードキャスト',
      widgetsHeader: 'ウィジェット',
      autoReplyHeader: '自動返信',
      generalHeader: '一般',
      facebookAutomation: 'Facebook自動化',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'チャットフローテンプレートをカスタマイズし、異なるターゲットオーディエンス向けに複数のチャットフローを作成します。',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'スケジュールされたチャットフローのドリップキャンペーンを使用して、顧客のオンボーディングジャーニーを作成します。',
      },
      broadcasts: {
        createBroadcast: 'ブロードキャストを作成',
        youCanCreateMultipleBroadcasts: '複数のブロードキャストを作成できます',
        broadcastName: 'ブロードキャスト名',
        broadcastNameIsRequired: 'ブロードキャスト名は必須です',
        continue: '続行',
        manageBroadcasts: 'ブロードキャストの管理',
        youCanCreateMultipleBroadcastsWith: 'さまざまな顧客グループ向けに複数のスケジュールされたテンプレートを備えた複数のブロードキャストを作成できます。',
        createNewBroadcast: '新しいブロードキャストを作成',
        name: '名前',
        segment: 'グループ',
        template: 'テンプレート',
        contacts: '連絡先',
        send: '送信',
        failed: '失敗',
        broadcastAt: 'ブロードキャスト時刻',
        status: 'ステータス',
        actions: 'アクション',
        nA: '該当なし',
        deleteBroadcast: 'ブロードキャストを削除',
        wantToDeleteThisBroadcast: 'このブロードキャストを削除してもよろしいですか？',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          searchHere: 'ここで検索',
        },
        appTooltip: {
          deleteMultipleBroadcasts: '複数のブロードキャストを削除',
          deleteBroadcast: 'ブロードキャストを削除',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'ブロードキャスト名',
        broadcastNameIsRequired: 'ブロードキャスト名は必須です',
        flow: 'フロー',
        segments: 'グループ',
        whenToSend: '送信タイミング',
        selectDate: '日付を選択',
        selectTimeSlot: '時間帯を選択',
        createBroadcast: 'ブロードキャストを作成',
        cancel: 'キャンセル',
        now: '今すぐ',
        later: '後で',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          selectFlow: 'フローを選択',
          searchFlowByName: '名前でフローを検索',
          selectSegments: 'グループを選択',
          searchSegmentByName: '名前でグループを検索',
          selectTimeSlot: '時間帯を選択',
        },
        indexMergeDatePicker: {
          selectStartDate: '開始日を選択',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'ドリップキャンペーンを作成',
        youCanCreateMultipleCampaign: '複数のキャンペーンを作成できます',
        dripCampaignName: 'ドリップキャンペーン名',
        dripCampaignNameIsRequired: 'ドリップキャンペーン名は必須です',
        continue: '続行',
        manageDripCampaign: 'ドリップキャンペーンの管理',
        youCanCreateMutlipleDripCampaign: '特定のユーザーグループからの応答をトリガーするために、スケジュールされた自動メッセージを備えた複数のドリップキャンペーンを作成できます。',
        createNewDripCampaign: '新しいドリップキャンペーンを作成',
        campaignName: 'キャンペーン名',
        appliedOn: '適用日',
        segment: 'グループ',
        flow: 'フロー',
        startDate: '開始日',
        action: 'アクション',
        nA: '該当なし',
        deleteDripCampaign: 'ドリップキャンペーンを削除',
        wantToDeleteThisDripCampaign: 'このドリップキャンペーンを削除してもよろしいですか？',
        placeholders: {
          enterDripCampaignName: 'ドリップキャンペーン名を入力',
          searchByName: '名前で検索',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: '複数のドリップキャンペーンを削除',
          deleteDripCampaign: 'ドリップキャンペーンを削除',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'ドリップキャンペーン名',
          appliedOn: '適用日',
          segments: 'グループ',
          selectDate: '日付を選択',
          scheduleYourFlows: 'フローをスケジュール',
          schedule: 'スケジュール',
          flow: 'フロー',
          sendAfter: '送信後',
          cancel: 'キャンセル',
          newSubscriber: '新しい購読者',
          days: '日',
          minutes: '分',
          hours: '時間',
          createDripCampaign: 'ドリップキャンペーンを作成',
          placeholders: {
            enterDripName: 'ドリップ名を入力',
            selectAppliedOn: '適用日を選択',
            selectSegment: 'グループを選択',
            searchByName: '名前で検索',
            selectTemplate: 'テンプレートを選択',
            searchFlowByName: '名前でフローを検索',
            enterSendAfter: '送信後を入力',
          },
          dateTimePicker: {
            selectStartDate: '開始日を選択',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'チャットID',
        errorCode: 'エラーコード',
        errorMessage: 'エラーメッセージ',
        nA: '該当なし',
      },
      flows: {
        chatFlows: 'チャットフロー',
        createChatFlow: 'チャットフローを作成',
        youCanCreateMultipleChatFlows: 'A/Bテストとブロードキャストのために複数のチャンネルで複数の会話フローを作成できます。',
        flowName: 'フロー名',
        nameIsRequired: '名前は必須です',
        cancel: 'キャンセル',
        createFlow: 'フローを作成',
        importFlow: 'フローをインポート',
        createNewFlow: '新しいフローを作成',
        ofMessages: 'メッセージ数',
        createdOn: '作成日',
        lastModified: '最終変更日',
        defaultFlow: 'デフォルトフロー',
        action: 'アクション',
        exportStatus: 'エクスポートステータス',
        flowExport: 'フローエクスポート',
        download: 'ダウンロード',
        beforeYouStartUploadPleaseMakeSure: 'アップロードを開始する前に、以下を確認してください',
        point1: '1. ファイルの最大サイズ制限は20MBです',
        point2: '2. ファイル形式はJSONである必要があります',
        selectFile: 'ファイルを選択',
        inProgress: '進行中',
        available: '利用可能',
        initiated: '開始済み',
        placeholders: {
          enterFlowName: 'フロー名を入力',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Facebookボットのフローをインポート',
          dateAndTimeOfCreationOfChatFlow: 'チャットフローの作成日時',
          dateAndTimeWhenChatFlowLastModified: 'チャットフローの最終変更日時',
          TOOLTIP_CREATE_FLOW: '新しいチャットフローを作成するにはクリック',
          TOOLTIP_FLOW_NAME: 'チャットフロー名を入力',
          TOOLTIP_FLOW_TABLE_NAME: 'チャットフローの名前',
          TOOLTIP_NO_OF_MESSAGES: 'チャットフロー内のメッセージコンポーネント数',
          TOOLTIP_DEFAULT_FLOW: 'このトグルを使用して、デフォルトのチャットフローを設定し、すべての新しいユーザーに提供します。',
          TOOLTIP_ACTION: 'チャットフローを編集または削除します',
        },
        noDataFound: {
          flows: 'チャットフロー',
          noFlowsAddedYet: 'まだチャットフローが追加されていません！',
        },
        modal: {
          deleteFlow: 'フローを削除',
          wantToDeleteThisFlow: 'このフローを削除してもよろしいですか？',
        }
      },
      widgets: {
        manageWidgets: 'ウィジェットの管理',
        widgetsAreRoutingTools: 'ウィジェットはボットの利用を増やすためのルーティングツールです',
        needHelp: 'ヘルプが必要ですか？',
        wantToMakeChanges: '変更を加えたいですか？',
        editIcon: 'アイコンを編集',
        discard: '破棄',
        updateWidget: 'ウィジェットを更新',
        installChatBotIconOnYourWebsite: 'ウェブサイトにチャットボットアイコンをインストール',
        shareWhereverYouWantToRedirectUsersTo: '共有して、ユーザーをFacebookボットにリダイレクトできます',
        copyAndAddInYourWebsiteHeaderFile: 'コピーしてウェブサイトのヘッダーファイルに追加',
        copyLinkAndStartSharing: 'リンクをコピーして共有を開始！',
        downloadQrCodeAndStartSharing: 'QRコードをダウンロードして共有を開始！',
        addCodeAndDeployNewCodeAndDone: 'コードを追加し、新しいコードを展開して完了！',
        wantToLearnHowToUseIt: '使用方法を学びたいですか？',
        copyChatBotCode: 'チャットボットコードをコピー',
        copyFacebookLink: 'Facebookリンクをコピー',
        downloadQrCode: 'QRコードをダウンロード',
        link: 'リンク',
        chatBotIcon: 'チャットボットアイコン',
        qrcode: 'QRコード',
        small: '小',
        medium: '中',
        large: '大',
        leftMiddle: '左-中央',
        rightMiddle: '右-中央',
        leftBottom: '左-下部',
        rightBottom: '右-下部',
        shareWhereEverYouWantToRedirectUsersTo: 'どこでも共有し、ユーザーをFacebookボットにリダイレクトすることができます',
        bpDropDown: {
          selectIcons: 'アイコンを選択',
          iconSize: 'アイコンサイズ',
          iconPlacement: 'アイコン配置',
        }
      },
      autoReply: {
        autoReplyTitle: '自動返信',
        configureYour: '自動返信の設定',
        message: 'メッセージ',
        customizeAutoReplyMessagesForYourPostsComments: '投稿のコメント用に自動返信メッセージをカスタマイズ',
        wouldYouLikeToApply: '適用しますか',
        autoreply: '自動返信',
        onAllPost: 'すべての投稿',
        configureYourMessageForEachPost: '各投稿のメッセージを設定',
        configureFlowToTriggerAfterOnReply: 'リプライ後にトリガーフローを設定',
        whenUserLeavesACommentSendMessageAfter: 'ユーザーがコメントを残すと、次にメッセージを送信',
        triggerFlowOnReply: 'リプライ時にフローをトリガー',
        add: '追加',
        update: '更新',
        save: '保存',
        cancel: 'キャンセル',
        discard: '破棄',
        bpDropDown: {
          selectPost: '投稿を選択',
          triggerFlowOnReply: 'リプライ時にトリガーフロー',
          immendiately: '即時',
          minutes: '分'
        },
        bpTable: {
          message: 'メッセージ',
          selectedPost: '選択した投稿',
          triggerFlow: 'トリガーフロー',
          actions: 'アクション'
        },
        placeholder: {
          configureYourMessage: 'メッセージを設定'
        },
        deleteModel: {
          title: '投稿自動返信を削除',
          description: 'この投稿の自動返信を削除してもよろしいですか？'
        }
      },
      generalSetting: {
        sendInactivityMessage: 'ユーザーの非アクティビティメッセージ',
        sessionTimeOutInHours: 'セッションのタイムアウト',
        enterSessionTimeOutWindowInHours: '時間でセッションタイムアウトウィンドウを入力',
        sessionTimeOutInHoursTooltip: `非アクティビティにより、チャットセッションが自動的にタイムアウトするまでの期間を入力します。セッションがタイムアウトした後、ユーザーがメッセージを送信すると、チャットフローが再起動します`,
        restartChatFlowTriggers: 'チャットフロートリガーの再起動',
        enterKeyWordsToRestartChatAndhitEnter: 'チャットを再起動するキーワードを入力してEnterキーを押してください',
        restartChatFlowTriggersTooltip: `ここでユーザーが入力し、送信したときにチャットフローを再起動するキーワードを指定します。これにより、ユーザーは会話フローを任意の地点で再起動できます。複数のキーワードを追加するには、各キーワードを追加した後にEnterキーを押します。`,
        liveChatTriggers: 'ライブチャットトリガー ',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'ライブチャットをトリガーするキーワードを入力してEnterキーを押してください',
        liveChatTriggersTooltip: `ユーザーが入力し、送信したときに自動チャットからライブチャットに移行を開始するキーワードを定義します。これは、ユーザーがライブエージェントの支援を必要とする場合に便利です。複数のキーワードを追加するには、各キーワードを追加した後にEnterキーを押します。`,
        messageAfterAutomatedFlowCompleted: '自動フローの完了後のメッセージ',
        enterYourMessage: 'メッセージを入力してください',
        messageAfterAutomatedFlowCompletedTooltip: `ユーザーが自動チャットフローを完了した後、セッションがタイムアウトしていない場合にユーザーに表示されるメッセージを入力します`,
        restart: '再起動',
        liveChat: 'ライブチャット',
        lineOneForDefaultMessage: `さらに質問があるか、サポートが必要な場合、次にできることは次のとおりです:`,
        to: 'に',
        restartTheChat: 'チャットを再起動',
        simpleTypeRestartAndHitEnter: `単に '再起動' と入力してEnterキーを押すだけで、会話フローの最初に戻ります。`,
        saveChanges: '変更を保存',
        discard: '破棄',
        configureBotsGeneralSettings: `ボットの一般設定を構成します`,
        minutes: '分',
        hours: '時間',
      }
    },
    instagram: {
      instagramBotSettings: 'Instagramボットの設定',
      chatFlowsHeader: 'チャットフロー',
      broadcastHeader: 'ブロードキャスト',
      dripCampaignHeader: 'ドリップキャンペーン',
      widgetsHeader: 'ウィジェット',
      alertsHeader: 'アラート',
      alerts: {
        configureYourBotsSettings: `ボットのアラート設定を構成する`,
        specifyHowYourBotNotifyYou: '新しいリードに関する通知方法を指定します。',
        subject: '主題',
        emailNotification: 'メール通知',
        emailAddresses: 'メールアドレス',
        leadRevisitNotifications: 'リード再訪通知',
        whenToSendLeadInfo: 'リード情報を送信するタイミング',
        discard: '破棄',
        saveChanges: '変更を保存',
        placeholders: {
          enterSubjectForYourEmail: 'メールの件名を入力',
          typeYourEmailAddressAndHitEnter: 'メールアドレスを入力してEnterキーを押してください',
        }
      },
      broadcasts: {
        createBroadcast: 'ブロードキャストを作成',
        youCanCreateMultipleBroadcasts: '複数のブロードキャストを作成できます',
        broadcastName: 'ブロードキャスト名',
        broadcastNameIsRequired: 'ブロードキャスト名は必須です',
        continue: '続行',
        manageBroadcasts: 'ブロードキャストの管理',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'さまざまな顧客グループのために複数のブロードキャストと複数のスケジュールされたテンプレートを作成できます。',
        createNewBroadcast: '新しいブロードキャストを作成',
        segment: 'グループ',
        template: 'テンプレート',
        contacts: '連絡先',
        send: '送信',
        failed: '失敗',
        broadcastAt: 'ブロードキャスト日時',
        status: 'ステータス',
        actions: 'アクション',
        nA: 'N/A',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          searchHere: 'ここで検索',
        },
        appTooltip: {
          deleteMultipleBroadcasts: '複数のブロードキャストを削除',
          deleteBroadcast: 'ブロードキャストを削除',
        }
      },
      createBroadcast: {
        broadcastName: 'ブロードキャスト名',
        broadcastNameIsRequired: 'ブロードキャスト名は必須です',
        flow: 'フロー',
        segments: 'グループ',
        whenToSend: '送信タイミング',
        selectDate: '日付を選択',
        selectTimeSlot: '時間帯を選択',
        cancel: 'キャンセル',
        createBroadcast: 'ブロードキャストを作成',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          selectFlow: 'フローを選択',
          searchFlowByName: '名前でフローを検索',
          selectSegments: 'グループを選択',
          searchSegmentByName: '名前でグループを検索',
          selectTimeSlot: '時間帯を選択',
        },
        indexMergeDatePicker: {
          selectStartDate: '開始日を選択',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'ドリップキャンペーンを作成',
        youCanCreateMultipleCampaigns: '複数のキャンペーンを作成できます',
        dripCampaignName: 'ドリップキャンペーン名',
        dripCampaignNameIsRequired: 'ドリップキャンペーン名は必須です',
        continue: '続行',
        manageDripCampaign: 'ドリップキャンペーンの管理',
        youCanCreateMultipleCampaignsWith: '特定のユーザーグループからの応答をトリガーするために、スケジュールされた自動メッセージを備えた複数のドリップキャンペーンを作成できます。',
        createNewDripCampaign: '新しいドリップキャンペーンを作成',
        campaignName: 'キャンペーン名',
        appliedOn: '適用日',
        segment: 'グループ',
        flow: 'フロー',
        startDate: '開始日',
        action: 'アクション',
        nA: 'N/A',
        placeholders: {
          enterDripCampaignName: 'ドリップキャンペーン名を入力',
          searchByName: '名前で検索',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: '複数のドリップキャンペーンを削除',
          deleteDripCampaign: 'ドリップキャンペーンを削除',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'ドリップキャンペーン名',
          appliedOn: '適用日',
          segments: 'グループ',
          selectDate: '日付を選択',
          scheduleYourFlows: 'フローをスケジュール',
          flow: 'フロー',
          sendAfter: '送信後',
          cancel: 'キャンセル',
          createDripCampaign: 'ドリップキャンペーンを作成',
          placeholders: {
            enterDripName: 'ドリップ名を入力',
            selectAppliedOn: '適用日を選択',
            selectSegment: 'グループを選択',
            searchByName: '名前で検索',
            selectTemplate: 'テンプレートを選択',
            searchFlowByName: '名前でフローを検索',
            enterSendAfter: '送信後を入力',
          },
          dateTimePicker: {
            selectStartDate: '開始日を選択',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'チャットID',
        errorCode: 'エラーコード',
        errorMessage: 'エラーメッセージ',
        nA: 'N/A',
      },
      flows: {
        chatFlows: 'チャットフロー',
        createChatFlow: 'チャットフローを作成',
        youCanCreateMultipleChatFlows: 'A/Bテストとブロードキャスト用に、複数のチャネルで複数の会話フローを作成できます。',
        flowName: 'フロー名',
        nameIsRequired: '名前は必須です',
        cancel: 'キャンセル',
        createFlow: 'フローを作成',
        importFlow: 'フローをインポート',
        createNewFlow: '新しいフローを作成',
        ofMessages: 'メッセージの数',
        createdOn: '作成日',
        lastModified: '最終変更日',
        defaultFlow: 'デフォルトフロー',
        action: 'アクション',
        exportStatus: 'エクスポートステータス',
        flowExport: 'フローエクスポート',
        download: 'ダウンロード',
        beforeYouStartUploadPleaseMakeSure: 'アップロードを開始する前に、以下を確認してください',
        point1: '1. ファイルサイズの上限は20MBです',
        point2: '2. ファイル形式はJSONである必要があります',
        selectFile: 'ファイルを選択',
        placeholders: {
          enterFlowName: 'フロー名を入力',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Instagramボットのフローをインポート',
          dateAndTimeOfCreationOfChatFlow: 'チャットフローの作成日時',
          dateAndTimeWhenChatFlowLastModified: 'チャットフローの最終変更日時',
        },
        noDataFound: {
          flows: 'フロー',
          noFlowsAddedYet: 'まだフローが追加されていません！',
        },
        modal: {
          deleteFlow: 'フローを削除',
          wantToDeleteThisFlow: 'このフローを削除してもよろしいですか？',
        }
      },
      widgets: {
        manageWidgets: 'ウィジェットを管理',
        widgetsAreRoutingTools: 'ウィジェットはボットのルーティングツールです',
        needHelp: 'ヘルプが必要ですか？',
        wantToMakeChanges: '変更を加えたいですか？',
        editIcon: 'アイコンを編集',
        discard: '破棄',
        updateWidget: 'ウィジェットを更新',
        bpDropDown: {
          selectIcons: 'アイコンを選択',
          iconSize: 'アイコンのサイズ',
          iconPlacement: 'アイコンの配置',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Telegramボットの設定',
      chatFlowsHeader: 'チャットフロー',
      telegramProfileHeader: 'Telegramプロファイル',
      widgetsHeader: 'ウィジェット',
      broadcastHeader: 'ブロードキャスト',
      dripCampaignHeader: 'ドリップキャンペーン',
      generalHeader: '一般',
      telegramAutomation: 'Telegram自動化',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'スケジュールされたチャットフローのドリップキャンペーンを使用して、顧客のオンボーディングジャーニーを作成します。',
        TOOLTIP_CHAT_FLOW_TITLE: 'チャットフローテンプレートをカスタマイズし、異なるターゲットオーディエンス向けに複数のチャットフローを作成します。',
      },
      broadcasts: {
        createBroadcast: 'ブロードキャストを作成',
        youCanCreateMutlipleBroadcasts: '複数のブロードキャストを作成できます',
        broadcastName: 'ブロードキャスト名',
        broadcastNameIsRequired: 'ブロードキャスト名は必須です',
        continue: '続行',
        manageBroadcasts: 'ブロードキャストの管理',
        youCanCreateMultipleBroadcastsWith: 'さまざまな顧客グループ向けに複数のスケジュールされたテンプレートを使用して複数のブロードキャストを作成できます。',
        createNewBroadcast: '新しいブロードキャストを作成',
        name: '名前',
        segment: 'グループ',
        template: 'テンプレート',
        contacts: '連絡先',
        send: '送信',
        failed: '失敗',
        broadcastAt: 'ブロードキャスト日時',
        status: 'ステータス',
        actions: 'アクション',
        nA: 'N/A',
        deleteBroadcast: 'ブロードキャストを削除',
        wantToDeleteThisBroadcast: 'このブロードキャストを削除してもよろしいですか？',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          searchHere: 'ここで検索',
        },
        appTooltip: {
          deleteMultipleBroadcasts: '複数のブロードキャストを削除',
          deleteBroadcast: 'ブロードキャストを削除',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'ブロードキャスト名',
        broadcastNameIsRequired: 'ブロードキャスト名は必須です',
        flow: 'フロー',
        segments: 'グループ',
        whenToSend: '送信タイミング',
        selectDate: '日付を選択',
        selectTimeSlot: '時間帯を選択',
        cancel: 'キャンセル',
        createBroadcast: 'ブロードキャストを作成',
        now: '今すぐ',
        later: '後で',
        placeholders: {
          enterBroadcastName: 'ブロードキャスト名を入力',
          selectFlow: 'フローを選択',
          searchFlowByName: '名前でフローを検索',
          selectSegments: 'グループを選択',
          searchSegmentByName: '名前でグループを検索',
          selectTimeSlot: '時間帯を選択',
        },
        indexMergeDatePicker: {
          selectStartDate: '開始日を選択',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'ドリップキャンペーンを作成',
        youCanCreateMultipleCampaigns: '複数のキャンペーンを作成できます',
        dripCampaignName: 'ドリップキャンペーン名',
        dripCampaignNameIsRequired: 'ドリップキャンペーン名は必須です',
        continue: '続行',
        manageDripCampaign: 'ドリップキャンペーンの管理',
        youCanCreateMultipleDripCampaignsWith: '特定のユーザーグループからの応答をトリガーするために、スケジュールされた自動メッセージを備えた複数のドリップキャンペーンを作成できます。',
        createNewDripCampaign: '新しいドリップキャンペーンを作成',
        campaignName: 'キャンペーン名',
        appliedOn: '適用日',
        segment: 'グループ',
        flow: 'フロー',
        startDate: '開始日',
        action: 'アクション',
        nA: 'N/A',
        deleteDripCampaign: 'ドリップキャンペーンを削除',
        wantToDeleteThisDripCampaign: 'このドリップキャンペーンを削除してもよろしいですか？',
        placeholders: {
          enterDripCampaignName: 'ドリップキャンペーン名を入力',
          searchByName: '名前で検索',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: '複数のドリップキャンペーンを削除',
          deleteDripCampaign: 'ドリップキャンペーンを削除',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'ドリップキャンペーン名',
          appliedOn: '適用日',
          segments: 'グループ',
          startDate: '日付を選択',
          scheduleYourFlows: 'フローをスケジュール',
          schedule: 'スケジュール',
          flow: 'フロー',
          sendAfter: '送信後',
          cancel: 'キャンセル',
          createDripCampaign: 'ドリップキャンペーンを作成',
          newSubscriber: '新しい購読者',
          days: '日',
          minutes: '分',
          hours: '時間',
          placeholders: {
            enterDripName: 'ドリップ名を入力',
            selectAppliedOn: '適用日を選択',
            selectSegment: 'グループを選択',
            searchByName: '名前で検索',
            selectFlow: 'フローを選択',
            searchFlowByName: '名前でフローを検索',
          },
          dateTimePicker: {
            selectStartDate: '開始日を選択',
          }
        }
      },
      failedBroadcastTelegram: {
        name: '名前',
        email: 'メール',
        phone: '電話',
        errorCode: 'エラーコード',
        errorMessage: 'エラーメッセージ',
        nA: 'N/A',
      },
      flows: {
        chatFlows: 'チャットフロー',
        createChatFlow: 'チャットフローを作成',
        youCanCreateMultipleChatFlows: 'A/Bテストとブロードキャストのために、複数のチャネルで複数の会話フローを作成できます。',
        flowName: 'フロー名',
        nameIsRequired: '名前は必須です',
        cancel: 'キャンセル',
        createFlow: 'フローを作成',
        importFlow: 'フローをインポート',
        createNewFlow: '新しいフローを作成',
        // flowName: 'フロー名',
        ofMessages: 'メッセージの数',
        createdOn: '作成日',
        lastModified: '最終変更日',
        defaultFlow: 'デフォルトフロー',
        action: 'アクション',
        exportStatus: 'エクスポートステータス',
        flowExport: 'フローエクスポート',
        inProgress: '進行中',
        available: '利用可能',
        initiated: '開始済み',
        download: 'ダウンロード',
        // importFlow: 'フローをインポート',
        beforeYouStartUploadPleaseMakeSure: 'アップロードを開始する前に、以下を確認してください',
        point1: '1. ファイルサイズの上限は20MBです',
        point2: '2. ファイル形式はJSONである必要があります',
        selectFile: 'ファイルを選択',
        placeholders: {
          enterFlowName: 'フロー名を入力',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Instagramボットのフローをインポート',
          dateAndTimeOfCreationOfChatFlow: 'チャットフローの作成日時',
          dateAndTimeWhenChatFlowLastModified: 'チャットフローの最終変更日時',
          TOOLTIP_CREATE_FLOW: '新しいチャットフローを作成するにはクリック',
          TOOLTIP_FLOW_NAME: 'チャットフロー名を入力',
          TOOLTIP_FLOW_TABLE_NAME: 'チャットフローの名前',
          TOOLTIP_NO_OF_MESSAGES: 'チャットフロー内のメッセージコンポーネントの数',
          TOOLTIP_DEFAULT_FLOW: 'このトグルを使用して、すべての新規ユーザーに提供されるデフォルトのチャットフローを設定します。',
          TOOLTIP_ACTION: 'チャットフローを編集または削除します',
        },
        noDataFound: {
          flows: 'フロー',
          noFlowsAddedYet: 'まだフローが追加されていません！',
        },
        modal: {
          deleteFlow: 'フローを削除',
          wantToDeleteThisFlow: 'このフローを削除してもよろしいですか？',
        }
      },
      telegramProfile: {
        telegramProfile: 'Telegramプロファイル',
        manageYourBotProfile: 'Botプロファイルを必要に応じて管理します。',
        allSetNoActionRequired: 'すべて設定済み、アクションは不要です！',
        congratulationYourAccountIsLive: 'おめでとうございます！アカウントがフルアクセスで稼働中です',
        botDetails: 'Botの詳細',
        name: '名前',
        nA: 'N/A',
        b: 'B',
        botName: 'ボット名',
        bot: 'ボット',
        info: '情報',
        none: 'なし',
        username: 'ユーザー名',
        notifications: '通知',
        on: 'オン',
        addToGroupOrChannel: 'グループまたはチャンネルに追加',
        userName: 'ユーザー名',
        botToken: 'ボットトークン',
      },
      widgets: {
        manageWidgets: 'ウィジェットを管理',
        widgetsAreRoutingTools: 'ウィジェットはボットのルーティングツールです',
        needHelp: 'ヘルプが必要ですか？',
        wantToMakeChanges: '変更を加えたいですか？',
        editIcon: 'アイコンを編集',
        discard: '破棄',
        updateWidget: 'ウィジェットを更新',
        installChatBotIconOnYourWebsite: 'ウェブサイトにチャットボットアイコンをインストール',
        shareWhereEverYouWantRedirectUsersTo: '共有場所を選択し、ユーザーを1クリックでTelegramボットにリダイレクト',
        copyAndAddInYourWebsiteHeaderFile: 'コピーしてウェブサイトのヘッダーファイルに追加',
        copyLinkAndStartSharing: 'リンクをコピーして共有を開始！',
        downloadQrCodeAndStartSharing: 'QRコードをダウンロードして共有を開始！',
        addCodeAndDeployNewCodeAndDone: 'コードを追加し、新しいコードをデプロイして完了！',
        wantToLearnHowToUseIt: '使い方を学びたいですか？',
        copyChatBotCode: 'チャットボットコードをコピー',
        downloadQrCode: 'QRコードをダウンロード',
        copyTelegramLink: 'Telegramリンクをコピー',
        link: 'リンク',
        chatBotIcon: 'チャットボットアイコン',
        qrcode: 'QRコード',
        small: '小',
        medium: '中',
        large: '大',
        leftMiddle: '左中央',
        rightMiddle: '右中央',
        leftBottom: '左下',
        rightBottom: '右下',
        bpDropDown: {
          selectIcons: 'アイコンを選択',
          iconSize: 'アイコンサイズ',
          iconPlacement: 'アイコンの配置',
        }
      },
      generalSetting: {
        sendInactivityMessage: 'ユーザーの非アクティビティメッセージ',
        sessionTimeOutInHours: 'セッションのタイムアウト',
        enterSessionTimeOutWindowInHours: '時間でセッションタイムアウトウィンドウを入力',
        sessionTimeOutInHoursTooltip: `非アクティビティにより、チャットセッションが自動的にタイムアウトするまでの期間を入力します。セッションがタイムアウトした後、ユーザーがメッセージを送信すると、チャットフローが再起動します`,
        restartChatFlowTriggers: 'チャットフロートリガーの再起動',
        enterKeyWordsToRestartChatAndhitEnter: 'チャットを再起動するキーワードを入力してEnterキーを押してください',
        restartChatFlowTriggersTooltip: `ここでユーザーが入力し、送信したときにチャットフローを再起動するキーワードを指定します。これにより、ユーザーは会話フローを任意の地点で再起動できます。複数のキーワードを追加するには、各キーワードを追加した後にEnterキーを押します。`,
        liveChatTriggers: 'ライブチャットトリガー ',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'ライブチャットをトリガーするキーワードを入力してEnterキーを押してください',
        liveChatTriggersTooltip: `ユーザーが入力し、送信したときに自動チャットからライブチャットに移行を開始するキーワードを定義します。これは、ユーザーがライブエージェントの支援を必要とする場合に便利です。複数のキーワードを追加するには、各キーワードを追加した後にEnterキーを押します。`,
        messageAfterAutomatedFlowCompleted: '自動フローの完了後のメッセージ',
        enterYourMessage: 'メッセージを入力してください',
        messageAfterAutomatedFlowCompletedTooltip: `ユーザーが自動チャットフローを完了した後、セッションがタイムアウトしていない場合にユーザーに表示されるメッセージを入力します`,
        restart: '再起動',
        liveChat: 'ライブチャット',
        lineOneForDefaultMessage: `さらに質問があるか、サポートが必要な場合、次にできることは次のとおりです:`,
        to: 'に',
        restartTheChat: 'チャットを再起動',
        simpleTypeRestartAndHitEnter: `単に '再起動' と入力してEnterキーを押すだけで、会話フローの最初に戻ります。`,
        saveChanges: '変更を保存',
        discard: '破棄',
        configureBotsGeneralSettings: `ボットの一般設定を構成します`,
        minutes: '分',
        hours: '時間',
      }
    },
    whatsapp: {
      whatsappAutomation: 'WhatsApp自動化',
      whatsappBotSettings: 'WhatsAppボット設定',
      generalHeader: '一般',
      chatFlowsHeader: 'チャットフロー',
      whatsappProfileHeader: 'WhatsAppプロファイル',
      broadcastHeader: 'ブロードキャスト',
      dripCampaignHeader: 'ドリップキャンペーン',
      widgetHeader: 'ウィジェット',
      templatesHeader: 'テンプレート',
      alertsHeader: 'アラート',
      apiKeyHeader: 'APIキー',
      catalogHeader: 'カタログ',
      otherConfiguration: 'AI設定',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'チャットフローテンプレートをカスタマイズし、異なるターゲットオーディエンス向けに複数のチャットフローを作成します。',
      },
      alerts: {
        configureYourBotsAlertSettings: 'ボットのアラート設定を構成します。',
        specifyHowYouBotWillNotify: '新しいリードに関する通知方法を指定します。',
        subject: '主題',
        emailNotification: 'メール通知',
        emailAddresses: 'メールアドレス',
        leadRevisitNotifications: 'リード再訪通知',
        whenToSendLeadInfo: 'リード情報を送信するタイミング',
        discard: '破棄',
        saveChanges: '変更を保存',
        seeHowToUse: '使用方法を見る',
        beginningTheFlow: 'フローの開始',
        fiveMinutesLater: '5分後',
        endOfFlow: 'フローの終了',
        placeholders: {
          enterSubjectForYourEmail: 'メールの件名を入力',
          typeEmailAndHitEnter: 'メールアドレスを入力し、Enterキーを押してください',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'ボットからのメール通知の件名をカスタマイズします。',
          TOOLTIP_EMAIL_NOTIFICATION: '新しいリードに関するボットのメール通知を有効または無効にします。',
          TOOLTIP_EMAIL_ADDRESS: 'メール通知を受け取りたいアカウントのメールアドレスを入力します。Enterを押して他のメールアドレスを追加できます。',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: '以前にリードとしてキャプチャされたユーザーがボットと再度対話した場合に、ボットのメール通知を有効または無効にします。',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'WhatsAppチャットボットを更新',
        businessNameIsRequired: 'ビジネス名は必須です。',
        maxLengthForBusinessName40Characters: 'ビジネス名の最大長は40文字です。',
        categoryIsRequired: 'カテゴリーは必須です。',
        descriptionIsRequired: '説明は必須です。',
        maxLengthForDescription200Characters: '説明の最大長は200文字です。',
        aboutIsRequired: '情報欄は必須です。',
        maxLengthForAbout139Characters: '情報欄の最大長は139文字です。',
        pleaseFillAValidBusinessEmail: '有効なビジネスメールアドレスを入力してください。',
        businessEmailIsRequired: 'ビジネスメールアドレスは必須です。',
        websiteIsRequired: 'ウェブサイトは必須です。',
        maxLengthForWebsite40Characters: 'ウェブサイトの最大長は40文字です。',
        maxLengthForAddress150Characters: '住所の最大長は150文字です。',
        cancel: 'キャンセル',
        update: '更新',
        businessCategory: 'ビジネスカテゴリー',
        placeholders: {
          yourBusinessName: 'ビジネス名を入力',
          businessDescription: 'ビジネス説明',
          about: '情報欄',
          businessEmail: 'ビジネスメールアドレス',
          website: 'ウェブサイト（例：www.example.com）',
          address: '住所',
        }
      },
      flows: {
        chatFlows: 'チャットフロー',
        createChatFlow: 'チャットフローを作成',
        youCanCreateMultipleChatFlows: 'A/Bテストとブロードキャストのために複数の会話フローを複数のチャンネルで作成できます。',
        flowName: 'フロー名',
        nameIsRequired: '名前は必須です',
        cancel: 'キャンセル',
        createFlow: 'フローを作成',
        importFlow: 'フローをインポート',
        createNewFlow: '新しいフローを作成',
        ofMessages: 'メッセージの数',
        createdOn: '作成日',
        lastModified: '最終変更日',
        defaultFlow: 'デフォルトフロー',
        revisitFlow: '再訪フロー',
        action: 'アクション',
        exportStatus: 'エクスポートステータス',
        flowExport: 'フローのエクスポート',
        beforeYouStartUploadPleaseMakeSure: 'アップロードを開始する前に、以下を確認してください。',
        point1: '1. 最大ファイルサイズのアップロード制限は20MBです',
        point2: '2. ファイル形式はJSONである必要があります',
        selectFile: 'ファイルを選択',
        seeHowToUse: '使用方法を見る',
        download: 'ダウンロード',
        available: '利用可能',
        initiated: '開始',
        inProgress: '進行中',
        placeholders: {
          enterFlowName: 'フロー名を入力',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'WhatsAppボットのためのフローをインポートします。',
          dateAndTimeOfCreationOfChatFlow: 'チャットフローの作成日時',
          dateAndTimeWhenChatFlowLastModified: 'チャットフローが最後に変更された日時',
          TOOLTIP_CREATE_FLOW: '新しいチャットフローを作成するにはクリックします',
          TOOLTIP_FLOW_NAME: 'チャットフローの名前を入力します',
          TOOLTIP_FLOW_TABLE_NAME: 'チャットフローの名前',
          TOOLTIP_NO_OF_MESSAGES: 'チャットフロー内のメッセージコンポーネントの数',
          TOOLTIP_DEFAULT_FLOW: 'このトグルを使用して、デフォルトのチャットフローを設定します。これは新しいユーザーに提供されるチャットフローです。',
          TOOLTIP_REVISIT_FLOW: 'このトグルを使用して、ユーザーがチャットボットを再訪した際に提供されるチャットフローを選択します。',
          TOOLTIP_ACTION: 'チャットフローを編集または削除します',
        },
        modal: {
          deleteFlow: 'フローを削除',
          wantToDeleteThisFlow: 'このフローを削除してもよろしいですか？',
        },
        noDataFound: {
          flows: 'フロー',
          noFlowsAddedYet: 'まだフローが追加されていません！',
        }
      },
      input: {
        manageTemplates: 'テンプレートを管理',
        manageYourTemplatesForMarketingAutomation: 'マーケティングオートメーションのためのテンプレートを管理します',
        syncTemplates: 'テンプレートを同期',
        createTemplateHeader: 'テンプレートを作成',
        title: 'タイトル',
        info: '情報',
        category: 'カテゴリー',
        status: 'ステータス',
        action: 'アクション',
        shipmentUpdate: '配送更新',
        name: '名前',
        headerOptional: 'ヘッダー（オプション）',
        bodyRequired: '本文（必須）',
        footerOptional: 'フッター（オプション）',
        buttonsOptional: 'ボタン（オプション）',
        makeUpto3Buttons: '最大3つのボタンを作成',
        makeUpto2ButtonsToRedirect: '最大2つのボタンを作成してカスタマーをウェブサイトにリダイレクト',
        placeholders: {
          nameYourTemplate: 'テンプレート名を入力（単語を区切るためにアンダースコアを使用）',
          enterText: 'テキストを入力（最大60文字）',
          enterImageUrl: '画像のURLを入力',
          enterVideoUrl: 'ビデオのURLを入力',
          templateMessage: 'テンプレートメッセージ',
          smallFooterAtBottom: '下部に小さなフッター',
          buttons: 'ボタン',
          text: 'テキスト',
          numberWithCountryCode: '国コード付きの番号',
          enterButtonText: 'ボタンテキストを入力（最大20文字）',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'WhatsAppテンプレートのタイトル',
          TOOLTIP_INPUT_TABLE_INFO: 'ビジネスが開始したメッセージの詳細情報',
          TOOLTIP_INPUT_TABLE_CATEGORY: '予約、リマインダー、カスタマーケアメッセージ、出荷更新、アラートなどのビジネスが開始したメッセージのタイプ',
          TOOLTIP_INPUT_TABLE_STATUS: 'テンプレートのステータスはWhatsAppによって承認または拒否されます。WhatsAppテンプレートは最初にWhatsAppによって承認される必要があり、これは有料サービスです。',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'テンプレートを削除します',
        },
        noDataFound: {
          templates: 'テンプレート',
          noTemplatesCreatedYet: 'まだテンプレートが作成されていません！',
        },
        modal: {
          deleteTemplate: 'テンプレートを削除',
          wantToDeleteThisTemplate: 'このテンプレートを削除してもよろしいですか？',
        },
        createTemplate: {
          createTemplates: 'テンプレートを作成',
          createTemplatesForMarketingAutomation: 'マーケティングオートメーションのためのテンプレートを作成します',
          templateMeta: 'テンプレートメタ',
          selectLanguage: '言語を選択',
          header: 'ヘッダー',
          optional: 'オプション',
          samplesForHeaderContent: 'ヘッダーコンテンツのサンプル',
          toHelpMetaReviewYourContent: 'メタがコンテンツを確認するのを助けるために、ヘッダー内の変数またはメディアの例を提供します。顧客情報は含めないでください。',
          body: '本文',
          addVariable: '変数を追加',
          samplesForBodyContent: '本文コンテンツのサンプル',
          toHelpUsReviewYourContent: 'コンテンツを確認するのを助けるために、本文内の変数の例を提供します。顧客情報は含めないでください。',
          footer: 'フッター',
          buttons: 'ボタン',
          typeOfAction: 'アクションの種類',
          call: 'コール',
          phone: '電話',
          website: 'ウェブサイト',
          static: '静的',
          urlType: 'URLタイプ',
          type: 'タイプ',
          customButton: 'カスタムボタン',
          addAnotherButton: '別のボタンを追加',
          preview: 'プレビュー',
          test: 'テスト',
          create: '作成',
          addSampleUrl: 'サンプルURLを追加',
          toHelpMetaReviewYourMessage: 'メタがメッセージテンプレートを確認するのを助けるために、ウェブサイトのURLの例を追加してください。実際の顧客情報は使用しないでください。',
          placeholders: {
            enterMessageTemplateName: 'メッセージテンプレート名を入力',
            searchTemplateByName: '名前でテンプレートを検索',
            selectTemplate: 'テンプレートを選択',
            enterText: 'テキストを入力',
            enterBody: '本文を入力',
            enterContentForVariable: '変数のコンテンツを入力',
            enterFooter: 'フッターを入力',
            phone: '電話*',
            enterWebsiteUrl: 'ウェブサイトURLを入力',
          },
          bpInput: {
            name: '名前',
            buttonText: 'ボタンテキスト',
            websiteUrl: 'ウェブサイトURL',
          },
          bpDropDown: {
            selectCategory: 'カテゴリーを選択',
          }
        },
        templatePreview: {
          preview: 'プレビュー',
          test: 'テスト',
        },
        templateConfig: {
          saveChanges: '変更を保存',
          discard: '破棄',
          triggerChatFlowOnTemplateReply: 'テンプレートの返信時にチャットフローをトリガー',
          templateDetails: 'テンプレートの詳細',
          configureFlows: 'フローを設定',
          selectFlow: 'フローを選択',
          name: '名前'
        }
      },
      shopManagement: {
        shopManagement: 'ショップ管理',
        manageECommerceShopFromBotPenguin: 'BotPenguinから接続されたeコマースショップを管理します',
        comingSoon: '(近日公開予定)',
        wooCommerce: {
          createConfiguration: '構成を作成',
          eventConfiguration: {
            createConfiguration: '構成を作成',
            templateHeader: 'テンプレートヘッダー',
            selectFile: 'ファイルを選択',
            templateMessage: 'テンプレートメッセージ',
            saveChanges: '変更を保存',
            bpDropDown: {
              selectEvent: 'イベントを選択',
              selectTemplate: 'テンプレートを選択',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'WhatsAppプロフィール',
        manageYourBotProfile: 'Botプロフィールを必要な時に管理できます。',
        allSetNoActionRequired: 'すべて設定済み、アクションは必要ありません！',
        congratulationsYourAccountIsLive: 'おめでとうございます！アカウントはフルアクセスで稼働しています',
        somethingIsWrongReactivateYourBot: '何か問題が発生しています、ボットを再起動してください！',
        itSeemsLikeYourBotIsNotWorkingProperly: 'ボットが正しく動作していないようです。ボットをリセットしてください。',
        reConfigureYourBot: 'ボットを再構成',
        phoneNumberDetails: '電話番号の詳細',
        phoneNumber: '電話番号',
        nA: 'NA',
        phoneNumberId: '電話番号ID',
        wabaId: 'WABA ID',
        change: '変更',
        accessToken: 'アクセストークン',
        messagingLimit: 'メッセージ制限',
        qualityRating: '品質評価',
        whatsappBusinessAccountName: 'WhatsAppビジネスアカウント名',
        whatsappPublicProfile: 'WhatsApp公開プロフィール',
        profilePicture: 'プロフィール写真',
        aboutText: '紹介文',
        address: '住所',
        description: '説明',
        email: 'メール',
        websitePrimary: 'ウェブサイト（プライマリ）',
        websiteSecondary: 'ウェブサイト（セカンダリー）',
        businessVertical: 'ビジネス分野',
        editProfile: 'プロフィールを編集',
        thisIsABusinessAccount: 'これはビジネスアカウントです',
        aboutAndPhoneNumber: '紹介文と電話番号',
        yourTrialAccountIsLive: 'おめでとうございます！試用アカウントが確認済みの番号でフルアクセスで稼働しています',
        updateWithNewAccessToken: '新しいアクセストークンで更新',
      },
      widgets: {
        manageWidgets: 'ウィジェットを管理',
        widgetsAreRoutingTools: 'ウィジェットはボットの使用を増やすためのルーティングツールです',
        needHelp: 'ヘルプが必要ですか？',
        wantToMakeChanges: '変更を加えたいですか？',
        prePopulatedMessage: '事前設定されたメッセージ',
        discard: '破棄',
        updateWidget: 'ウィジェットを更新',
        hiThere: 'こんにちは',
        seeHowToUse: '使用方法を確認',
        editIcon: 'アイコンを編集',
        editPrePopulatedMessage: '事前設定されたメッセージを編集',
        small: '小',
        medium: '中',
        large: '大',
        leftMiddle: '左-中央',
        rightMiddle: '右-中央',
        leftBottom: '左-下部',
        rightBottom: '右-下部',
        chatBotIcon: 'チャットボットアイコン',
        link: 'リンク',
        qrcode: 'QRコード',
        copyChatBotCode: 'チャットボットコードをコピー',
        copyWALink: 'WAリンクをコピー',
        downloadQrCode: 'QRコードをダウンロード',
        installChatBotIconOnYourWebsite: 'ウェブサイトにチャットボットアイコンをインストール',
        shareWhereEverYouWantRedirectUsersTo: 'どこでも共有し、ユーザーをWhatsAppボットにリダイレクトする',
        copyAndAddInYourWebsiteHeaderFile: 'コピーしてウェブサイトのヘッダーファイルに追加',
        copyLinkAndStartSharing: 'リンクをコピーして共有を開始！',
        downloadQrCodeAndStartSharing: 'QRコードをダウンロードして共有を開始！',
        addCodeAndDeployNewCodeAndDone: 'コードを追加し、新しいコードをデプロイして完了！',
        wantToLearnHowToUseIt: '使い方を学びたいですか？',
        placeholders: {
          enterWhatsappNumber: 'WhatsApp番号を入力',
          updateYourMessageHere: 'メッセージをここで更新...',
        },
        bpDropDown: {
          selectIcons: 'アイコンを選択',
          iconSize: 'アイコンサイズ',
          iconPlacement: 'アイコン配置',
        },
        bpInput: {
          whatsappNumber: 'WhatsApp番号',
        }
      },
      catalog: {
        createCatalog: 'カタログを作成',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Meta Commerce Managerで製品カタログを作成するか、Shopifyとリンクします',
        useYourMetaCommerceManagertoManageCatalogs: 'Meta Commerce Managerを使用してカタログを管理します',
        connectCatalogToWhatsAppAccount: 'カタログをWhatsAppアカウントに接続',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'WhatsAppマネージャーを使用してカタログをWhatsAppアカウントに接続します',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Meta WhatsAppマネージャーを使用してカタログを接続し、BotPenguinで使用します',
        goToMeta: 'Metaに移動',
        goToShopify: 'Shopifyに移動',
        next: '次へ',
        back: '戻る',
        setupCatalog: 'カタログの設定',
        completeAllTheStepsToEnableCatalogMessages: 'カタログメッセージを有効にするためにすべてのステップを完了します'
      },
      generalSetting: {
        sendInactivityMessage: 'ユーザー非アクティビティメッセージ',
        sessionTimeOutInHours: 'セッションタイムアウト',
        enterSessionTimeOutWindowInHours: 'セッションのタイムアウトウィンドウを時間単位で入力',
        sessionTimeOutInHoursTooltip: `ユーザーの非アクティビティにより、チャットセッションが自動的にタイムアウトするまでの期間を入力します。
         ユーザーがセッションタイムアウト後にメッセージを送信すると、チャットフローが再開されます。`,
        restartChatFlowTriggers: 'チャットフローの再開トリガー',
        enterKeyWordsToRestartChatAndhitEnter: 'チャットを再開するためのキーワードを入力し、Enterキーを押します',
        restartChatFlowTriggersTooltip: `ここにユーザーが入力して送信すると、チャットフローを再開するキーワードを指定します。これにより、ユーザーはいつでも会話フローを最初からやり直すことができます。
        複数のキーワードを追加するには、キーワードを追加した後にEnterキーを押します。`,
        liveChatTriggers: 'ライブチャットトリガー',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'ライブチャットをトリガーするためのキーワードを入力し、Enterキーを押します',
        liveChatTriggersTooltip: `ユーザーが入力して送信すると、自動チャットからライブチャットに移行するキーワードを定義します。ユーザーがライブエージェントの支援が必要な場合に役立ちます。
         複数のキーワードを追加するには、キーワードを追加した後にEnterキーを押します。`,
        messageAfterAutomatedFlowCompleted: '自動フローが完了した後のメッセージ',
        enterYourMessage: 'メッセージを入力してください',
        messageAfterAutomatedFlowCompletedTooltip: `自動チャットフローが完了したが、セッションがタイムアウトしていない場合にユーザーに表示されるメッセージを入力します。`,
        restart: '再起動',
        liveChat: 'ライブチャット',
        lineOneForDefaultMessage: '質問がある場合、またはサポートが必要な場合、次にできることは次のとおりです：',
        to: 'To',
        restartTheChat: 'チャットを再開',
        simpleTypeRestartAndHitEnter: `単純に「再起動」と入力してEnterキーを押します。これにより、会話フローが最初からやり直されます。`,
        lineThreeForDefaultMessagePartOne: `ライブエージェントにお話しいただく場合は、`,
        liveAgent: `ライブエージェントに`,
        lineThreeForDefaultMessageLastPart: `入力してEnterキーを押してください。私たちのチームがリアルタイムでお手伝いします。`,
        saveChanges: '変更を保存',
        discard: '破棄',
        configureBotsGeneralSettings: `ボットの一般設定を構成します`,
        minutes: '分',
        hours: '時間',
      }

    },
    activateWhatsAppBot: {
      justAStepAway: 'あと少しです',
      seeHowToUse: '使い方を確認する',
      testWhatsAppTrialBot: 'WhatsAppトライアルチャットボットをテストする',
      sendMessage: 'メッセージを送信',
      sendWhatsAppMessageOn: 'WhatsAppメッセージを送信してください：',
      bySavingInYourContact: '連絡先に保存し、メッセージは以下から始めてください：',
      connect: '接続',
      or: 'または',
      scanQRCode: 'QRコードをスキャン',
      yourPhoneWillOpen: 'あなたの電話が事前に入力されたメッセージを開きます。 WhatsAppで「送信」を押してください',
      sendVerificationCode: '確認コードを送信',
      copyAndEnterCode: 'コードをコピーして6桁のコードを入力してください',
      shareWhereYouWant: '共有して、1クリックでWhatsAppボットにユーザーをリダイレクト',
      youShouldHaveReceived: '6桁のコードがWhatsApp番号に送信されました',
      EnterYourPhoneNumber: '電話番号を入力してください',
      send: '送信',
      sixDigitCode: '6桁のコードを入力してください',
      pleaseNote: '注意：6桁の確認コードは最大で3分かかることがあります。再送信する前にお待ちください。',
      resendVerificationCode: '確認コードを再送信',
      verify: '確認',
      providePhoneNumber: 'テストに使用したい番号を提供してください',
      demoBotisReady: '素晴らしい！デモボットが使用可能になりました',
      placeHolder: {
        phone: '電話番号を入力してください',
        code: '確認コードを入力してください',
      }
    }

  },
  integrations: {
    thirdPartyAppsIntegration: 'サードパーティーアプリの統合',
    thirdPartyApps: 'サードパーティーアプリ',
    customApps: 'カスタムアプリ',
    preview: 'プレビュー',
    test: 'テスト',
    install: 'インストール',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: '選択したプラットフォームにボットをインストールするにはクリックしてください',
      TOOLTIP_MY_INTEGRATION_TITLE: 'カスタムに作成したアプリをボットと統合',
      TOOLTIP_ALL_INTEGRATION_TITLE: '利用可能なサードパーティアプリ/CRMとボットを接続',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'サードパーティーアプリの統合',
        integrateYourBotWithListedCrms: 'ボットを一覧のアプリ/CRMと統合します。',
        help: 'ヘルプ',
        name: '名前',
        status: 'ステータス',
        action: 'アクション',
        chatGpt: 'ChatGPT',
        addOn: 'アドオン',
        purchased: '購入済み',
        readyToRideGptWave: 'GPTの波に乗りたいですか？今すぐ統合します。',
        purchase: '購入',
        notConnected: '未接続',
        integrated: '統合済み',
        configure: '設定',
        connected: '接続済み',
        edit: '編集',
        connect: '接続',
        requestYourIntegration: '統合のリクエスト',
        toRequestNewIntegration: '新しい統合のリクエストには、以下の詳細を入力してください。',
        // name: '名前',
        emailId: 'メールアドレス',
        integrationName: '統合名',
        contactNumber: '連絡先番号',
        howSoonYouWantToAddThisIntegration: 'この統合をどれくらい早く追加したいですか',
        sendRequest: 'リクエストを送信',
        cancel: 'キャンセル',
        requestSent: 'リクエスト送信済み！',
        yourRequestSuccessfullySent: '新しい統合のリクエストが正常に送信されました。',
        goBack: '戻る',
        disconnect: '切断',
        OopsIntegrationAreNotAvailable: 'おっと、統合は利用できません。',
        pleaseUpgradeTo: 'アップグレードしてください。',
        planForIntegration: ' 統合のためのプラン。',
        placeholders: {
          searchIntegrationByName: '名前で統合を検索',
          enterTeamMemberName: 'チームメンバーの名前を入力',
          enterEmailId: 'メールアドレスを入力',
          enterIntegrationName: '統合名を入力',
          searchCountry: '国を検索',
          enterContactNumber: '連絡先番号を入力',
          addComment: 'コメントを追加',
        },
        noDataFound: {
          integrations: '統合',
          noIntegrationsFound: '統合が見つかりません！',
        },
        appTooltip: {
          nameOfAppOrCrm: 'アプリ/CRMの名前',
          statusAsConnectedOrNotConnected: '接続済み/未接続のステータス',
          clickToConnectToAppOrCrm: 'アプリ/CRMに接続するにはクリック',
        },
        feedbackModal: {
          submit: '送信',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          agileCrmDomain: 'Agile CRM ドメイン',
          nameIsRequired: '名前は必須です',
          emailAddress: 'メールアドレス',
          apiToken: 'API トークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameter: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'example@company.extention',
            enterTokenHere: 'ここにトークンを入力',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiKey: 'API キー',
          accessKey: 'アクセスキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiKeyHere: 'ここに API キーを入力',
            enterAccessKeyHere: 'ここにアクセスキーを入力',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiToken: 'API トークン',
          nameIsRequired: '名前は必須です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          nameColon: '名前：',
          inboundWebhookUrl: 'インバウンド Webhook URL',
          nameIsRequired: '名前は必須です',
          leadTitle: 'リードタイトル',
          source: 'ソース',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterInboundWebhookUrl: 'インバウンド Webhook URL を入力...',
            enterTitle: 'タイトルを入力',
            enterSource: 'ソースを入力',
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiToken: 'API トークン',
          connect: '接続',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'ここにトークンを入力',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiToken: 'API トークン',
          nameIsRequired: '名前は必須です',
          testLead: 'テストリード',
          connect: '接続',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiToken: 'API トークン',
          testLead: 'テストリード',
          connect: '接続',
          selectTheQuestion: '値として送信するユーザーの返信を選択してください',
          mapWith: 'とマップ',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
            attributes: '属性（キー）',
            values: '値',
          }
        },
        chatGpt: {
          chatGptApiKey: 'ChatGPT（Open AI）API キー',
          chatGpt: 'ChatGPT（Open AI）',
          readyToRideWave: 'GPTの波に乗りたいですか？今すぐ統合します。',
          doNotHaveAnyApiKeyNeedHelp: 'API キーを持っていない場合、ヘルプが必要ですか？',
          authenticate: '認証',
          nowYouCanAccessChatGpt: 'これで、チャットフロービルダーで Chat GPT コンポーネントにアクセスできます。',
          paymentSuccessful: '支払い成功！',
          paymentHasBeenCompletedSuccessfully: '支払いが正常に完了しました。ボットのトレーニングの準備が整いました',
          cancel: 'キャンセル',
          trainNow: '今すぐトレーニング',
          paymentInProgress: '支払い進行中...',
          apiKey: 'ChatGPT（Open API キー',
          model: 'モデル',
          integrateWithChatGPT: 'ChatGPT で統合',
          updateKey: 'キーを更新',
          connect: '接続',
          update: '更新',
          placeholders: {
            chatGptApiKey: 'ChatGPT（Open AI）API キー',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiToken: 'API トークン',
          connect: '接続',
          testLead: 'テストリード',
          leadId: 'リード ID',
          selectLeadId: 'リード ID を選択',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'ここにトークンを入力',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          emailAddress: 'メールアドレス',
          leadTitle: 'リードタイトル',
          apiToken: 'API トークン',
          nameIsRequired: '名前は必須です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            exampleEmail: 'example@company.extention',
            enterYourLeadTitle: 'リードタイトルを入力',
            enterTokenHere: 'ここにトークンを入力',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          dripCrmAccountId: 'Drip CRM アカウント ID',
          apiToken: 'API トークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterAccountIdHere: 'ここにアカウント ID を入力',
            enterYourTokenHere: 'ここにトークンを入力',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apikey: 'API キー',
          accessToken: 'アクセス トークン',
          connect: '接続',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          selectParameter: 'パラメータを選択',
          disconnect: '切断',
          update: '更新',
          save: '保存',
          placeholders: {
            enterApiKeyHere: 'ここに API キーを入力',
            enterTokenHere: 'ここにトークンを入力',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiKey: 'API キー',
          nameIsRequired: '名前は必須です',
          connect: '接続',
          questionColon: '質問：',
          testLead: 'テストリード',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          authToken: 'Auth トークン',
          source: 'ソース',
          connect: '接続',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterAuthTokenHere: 'ここに Auth トークンを入力',
            enterSourceHere: 'ここにソースを入力'
          }
        },
        facebookPixel: {
          facebookPixel: 'Facebook ピクセル',
          getInsight: 'BotPenguinのGoogle Analyticsインテグレーションでビジネスをエンタープライズに変えるインサイトを入手',
          pixelId: 'Facebook ピクセル ID',
          connect: '接続',
          disconnect: '切断',
          placeholders: {
            enterYourId: 'Facebook ピクセル ID を入力'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          companyName: '会社名',
          emailAddress: 'メールアドレス',
          password: 'パスワード',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapsWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterCompanyName: '会社名を入力',
            enterEmailAddress: 'メールアドレスを入力',
            enterPassword: 'パスワードを入力',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          apiKey: 'API キー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          mapWith: 'とマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiKeyHere: 'ここに API キーを入力',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: '統合を成功裏に作成しました。これを設定できます。',
          flowluDomain: 'Flowlu ドメイン',
          apiToken: 'API トークン',
          title: 'タイトル',
          connect: '接続',
          questionColon: '質問：',
          addParameters: ' パラメータを追加',
          select: '選択',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'ここにトークンを入力',
            enterYourLeadTitle: 'リードタイトルを入力'
          }
        },

        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          freshdeskDomain: 'Freshdesk ドメイン',
          apiToken: 'API トークン',
          nameIsRequired: '名前は必須です',
          connect: '接続',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'トークンをここに入力',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          freshworkCrmDomain: 'Freshwork CRM ドメイン',
          apiToken: 'API トークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'トークンをここに入力',
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiToken: 'API トークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          mapWith: 'とマッピング',
          select: '選択',
          update: '更新',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiKey: 'API キー',
          connect: '接続',
          testLead: 'テストリード',
          campaignList: 'キャンペーンリスト',
          selectCampaign: 'キャンペーンを選択',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google アナリティクス',
          getInsights: 'BotPenguinのGoogle Analyticsインテグレーションでビジネスをエンタープライズに変えるインサイトを入手',
          measurementId: '計測 ID',
          connect: '接続',
          disconnect: '切断',
          placeholders: {
            enterYourId: 'IDを入力',
          }
        },
        googleCalendar: {
          testLead: 'テストリード',
          name: '名前：',
          email: 'メール：',
          appointmentSummary: 'アポイントメントの概要',
          appointmentDescription: 'アポイントメントの説明',
          addParameters: 'パラメータを追加',
          questionColon: '質問：',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          pause: '一時停止',
          resume: '再開',
          disconnect: '切断',
          reconnect: '再接続',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          name: '名前：',
          email: 'メール：',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
        },
        googleRedirect: {
          // Placeholder for Google Redirect, translation not provided.
        },
        googleSheet: {
          testLead: 'テストリード',
          title: 'タイトル',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          update: '更新',
          save: '保存',
          pause: '一時停止',
          resume: '再開',
          disconnect: '切断',
          reconnect: '再接続',
          placeholders: {
            mappingValues: 'マッピング値',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          name: '名前：',
          email: 'メール：',
          taskList: 'タスクリスト',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          pause: '一時停止',
          resume: '再開',
          disconnect: '切断',
          reconnect: '再接続',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiToken: 'API トークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          select: '選択',
          addParameters: 'パラメータを追加',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiKey: 'API キー',
          connect: '接続',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiHere: 'API をここに入力',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          testLead: 'テストリード',
          accountId: 'アカウント ID',
          accessToken: 'アクセス トークン',
          connect: '接続',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterAccountIdHere: 'アカウント ID をここに入力',
            enterAccessTokenHere: 'アクセス トークンをここに入力',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiToken: 'API キー',
          connect: '接続',
          questionColon: '質問：',
          testLead: 'テストリード',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        helpLink: {
          help: 'ヘルプ',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          helpRaceCrmDomain: 'Helprace CRM ドメイン',
          emailAddress: 'メールアドレス',
          apiToken: 'API トークン',
          connect: '接続',
          update: '更新',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'example@company.extension',
            token: 'トークン',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          domainName: 'ドメイン名',
          apiKey: 'API キー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiToken: 'API トークン',
          nameIsRequired: '名前は必須です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiKey: 'API キー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiHere: 'API をここに入力',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiToken: 'API トークン',
          nameIsRequired: '名前は必須です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: '統合の作成が成功しました。これを設定できます。',
          apiToken: 'API トークン',
          emailAddress: 'メールアドレス',
          apiUrl: 'API URL',
          connect: '接続',
          testLead: 'テストリード',
          selectProjectName: 'プロジェクト名を選択',
          selectIssueType: '課題タイプを選択',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapsWith: 'とマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
            enterEmailAddress: 'メールアドレスを入力',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiUrl: 'API URL',
          nameIsRequired: '名前が必要です',
          apiKey: 'APIキー',
          apiId: 'API ID',
          connect: '接続',
          testLead: 'テストリード',
          mapWith: 'でマッピング',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          selectTheQuestion: '値として送信したいユーザーの返信を選択してください',
          placeholders: {
            enterApiUrl: 'API URLを入力',
            enterApiKey: 'APIキーを入力',
            enterApiId: 'API IDを入力',
            attributes: '属性（キー）',
            values: '値',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiToken: 'APIトークン',
          nameIsRequired: '名前が必要です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'APIトークンを入力',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          accessKey: 'アクセスキー',
          secretKey: 'シークレットキー',
          connect: '接続',
          apiLogs: 'APIログ',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          udpate: '更新',
          save: '保存',
          disconnect: '切断',
          filterApplied: 'フィルタが適用されました',
          retry: '再試行',
          back: '戻る',
          filterYourLeads: 'リードをフィルタリング',
          filterOn: 'フィルタリング',
          filterBy: 'フィルタリング',
          discard: '破棄',
          applyFilter: 'フィルタを適用',
          loadMore: 'さらに読み込む',
          placeholders: {
            enterAccessKeyHere: 'アクセスキーを入力',
            enterSecretKeyHere: 'シークレットキーを入力',
            attributes: '属性（キー）',
            values: '値',
          },
          appTooltip: {
            filterDataBasedOnResponse: '応答に基づいてデータをフィルタリング',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          apiUrl: 'API URL',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiHere: 'APIをここに入力',
            exampleUrl: 'https://comapanyName.ladesk.com'
          }
        },
        microsoftTeams: {
          downloadPackage: 'Microsoft Teamsアプリパッケージをダウンロード',
          downloadApp: 'アプリをダウンロード',
          wantToLearnHowToUploadIt: 'アップロード方法を学びたいですか？',
          needHelp: 'ヘルプが必要ですか？',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiUrl: 'API URL',
          appId: 'APP ID',
          nameIsRequired: 'App IDが必要です',
          dataApiKey: 'データAPIキー',
          dataApiKeyIsRequired: 'データAPIキーが必要です',
          connect: '接続',
          testLead: 'テストリード',
          addParameters: 'パラメータを追加',
          mapWith: 'でマッピング',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          selectTheQuestion: '値として送信したいユーザーの返信を選択してください',
          placeholders: {
            enterApiUrl: 'API URLを入力',
            enterAppId: 'APP IDを入力',
            enterDataApiKey: 'データAPIキーを入力',
            attributes: '属性（キー）',
            pressForMappingQuestion: 'マッピング質問のプレス /',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          emailAddress: 'メールアドレス',
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          leadId: 'リードID',
          selectLeadId: 'リードIDを選択',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterEmailHere: 'ここにメールを入力',
            enterApiKeyHere: 'APIキーを入力',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'APIトークンを入力',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          noCrmDomain: 'Nocrmドメイン',
          leadTitle: 'リードタイトル',
          titleIsRequired: 'タイトルが必要です',
          token: 'トークン',
          tokenIsRequired: 'トークンが必要です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            exampleDomain: 'https://YOUR_SUBDOMAIN_HERE.nocrm.io',
            enterYourLeadTitle: 'リードタイトルを入力',
            enterYourToken: 'トークンを入力',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          emailAddress: 'メールアドレス',
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'APIトークンを入力',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          secretToken: 'シークレットトークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          appId: 'アプリID',
          appIdIsRequired: 'アプリIDが必要です',
          apiKey: 'APIキー',
          apiKeyIsRequired: 'APIキーが必要です',
          connect: '接続',
          update: '更新',
          disconnect: '切断',
          placeholders: {
            appId: 'アプリID',
            apiKey: 'APIキー',
          }
        },

        outlook: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          clientId: 'クライアントID',
          connect: '接続',
          clientSecret: 'クライアントシークレット',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          save: '保存',
          update: '更新',
          disconnect: '切断',
          placeholders: {
            enterClientIdHere: 'ここにクライアントIDを入力',
            enterClientSecretHere: 'ここにクライアントシークレットを入力',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          pipedriverDomain: 'Pipedriverドメイン',
          domainIsRequired: 'ドメインが必要です',
          apiToken: 'APIトークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'トークンをここに入力',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          apiKeyIsRequired: 'APIキーが必要です',
          appKey: 'APPキー',
          appKeyIsRequired: 'APPキーが必要です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiKey: 'APIキーを入力',
            enterAppKey: 'APPキーを入力',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          authId: '認証ID',
          authToken: '認証トークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiHere: 'APIをここに入力',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiKey: 'APIキーを入力',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          domain: 'ドメイン',
          clientId: 'クライアントID',
          clientSecret: 'クライアントシークレット',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterDomainHere: 'ここにドメインを入力',
            enterClientIdHere: 'ここにクライアントIDを入力',
            enterClientSecretHere: 'ここにクライアントシークレットを入力',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          addEventsAndTemplates: 'イベントとテンプレートを追加：',
          add: '追加',
          question: '質問',
          reply: '返信',
          requestBody: 'リクエストボディ',
          validateJson: 'JSONを検証',
          emailAddress: 'メールアドレス',
          // addParameters: ' Add Parameters'
          update: '更新',
          save: '保存',
          disconnect: '切断',
          selectTheQuestion: '値として送信するユーザーの返信を含む質問を選択',
          placeholders: {
            enterApiHere: 'APIをここに入力',
            enterTemplateId: 'テンプレートIDを入力',
            typeYourJsonCodeHere: 'ここにJSONコードを入力',
          },
          appTooltip: {
            typeYourJsonCode: 'JSONコードを入力',
            clickToValidateJsonCode: 'JSONコードを検証するにはクリック',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          userName: 'ユーザー名',
          licenseKey: 'ライセンスキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterNameHere: 'ここに名前を入力',
            enterKeyHere: 'ここにキーを入力',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiToken: 'APIトークン',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問：',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'トークンをここに入力',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'インテグレーションの作成に成功しました。これで構成できます。',
          company: '会社',
          loginId: 'ログイン ID',
          password: 'パスワード',
          testLead: 'テスト リード',
          connect: '接続',
          selectTheQuestion: '値として送信したいユーザーの返信を選択してください',
          mapWith: 'マップ',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          selectParameter: 'パラメータを選択',
          placeholders: {
            enterCompanyHere: 'ここに会社名を入力してください',
            enterLoginIdHere: 'ここにログイン ID を入力してください',
            enterPasswordHere: 'ここにパスワードを入力してください',
            values: '値',
          }
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          applicationKey: 'アプリケーションキー',
          authToken: '認証トークン',
          connect: '接続',
          questionColon: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApplicationKeyHere: 'ここにアプリケーションキーを入力',
            enterTokenHere: 'ここにトークンを入力',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          accessToken: 'アクセストークン',
          connect: '接続',
          testLead: 'テストリード',
          question: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterTokenHere: 'ここにトークンを入力',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          webhoperDomain: 'Webhoperドメイン',
          domainIsRequired: 'ドメインが必要です',
          companyName: '会社名',
          companyIsRequired: '会社が必要です',
          leadSource: 'リードソース',
          leadSourceIsRequired: 'リードソースが必要です',
          division: '部門',
          divisionIsRequired: '部門が必要です',
          enterPassword: 'パスワードを入力',
          passwordIsRequired: 'パスワードが必要です',
          disconnect: '切断',
          update: '更新',
          connect: '接続',
          placeholders: {
            exampleDomain: 'http://yourcompanydomain',
            enterYourCompanyName: '会社名を入力',
            enterLeadSource: 'リードソースを入力',
            enterDivision: '部門を入力',
            enterPassword: 'パスワードを入力',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          name: '名前:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'インバウンドウェブフックURL',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterWebhookUrl: 'ウェブフックURLを入力',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiToken: 'APIトークン',
          emailAddress: 'メールアドレス',
          apiUrl: 'API URL',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterEmailAddress: 'メールアドレスを入力',
            enterUrlHere: 'URLを入力',
            enterTokenHere: 'トークンを入力',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          apiKey: 'APIキー',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiHere: 'APIをここに入力',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          connect: '接続',
          update: '更新',
          disconnect: '切断',
          companyName: '会社名',
          emailAddress: 'メールアドレス',
          phone: '電話',
          countryCode: '国コード',
          questionColon: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          save: '保存',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          connect: '接続',
          disconnect: '切断',
          testLead: 'テストリード',
          emailAddress: 'メールアドレス',
          selectOrganizationName: '組織名を選択',
          selectDepartmentName: '部門名を選択',
          addParameters: 'パラメータを追加',
          questionColon: '質問:',
          select: '選択',
          mapsWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: `Integrationを正常に作成しました。これを設定できます。`,
          testLead: 'テストリード',
          connect: '接続',
          disconnect: '切断',
          projectsList: 'プロジェクトリスト',
          selectProjectsName: 'プロジェクト名を選択',
          projectsTaskList: 'プロジェクトタスクリスト',
          selectTaskListName: 'タスクリスト名を選択',
          questionColon: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'でマッピング',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
        },

        wooCommerce: {
          configure: '設定',
          automatedMessage: '自動メッセージ',
          configureTab: {
            wooCommercePluginApiKey: 'WooCommerceプラグインAPIキー',
            downloadWooCommercePlugin: 'WooCommerceプラグインをダウンロード'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: '放棄回収メッセージ',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: '放棄カートを取り戻すための自動回収メッセージを設定',
            enableAbandonedCartRecoveryMessaegs: '放棄カート回収メッセージを有効にする',
            selectTemplate: 'テンプレートを選択',
            sendMessageAfter: 'メッセージを送信後',
            templateMessage: 'テンプレートメッセージ',
            saveChanges: '変更を保存',
            selectFile: 'ファイルを選択',
            orderCompleted: '注文完了',
            templateHeader: 'テンプレートヘッダー',
            orderFulfillMent: '注文の履行',
            enableOrderConfirmationMessages: '注文確認メッセージを有効にする',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'お客様が注文をするとすぐに注文確認メッセージを送信します。',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: '注文の発送メッセージは、注文を履行すると送信されます。',
            enableOrderFulfillmentMessages: '注文履行メッセージを有効にする',
            returnExchangeRequest: '返品/交換リクエスト',
            theMessageIsSentAfterARefundIsRequested: '返金リクエストの後にメッセージが送信されます',
            enableOrderRefundMessages: '注文返金メッセージを有効にする',
          }
        },
        goHighLevel: {
          youHaveSuccessfullyCreatedIntegration: '統合が正常に作成されました。これを構成できます。',
          apiToken: 'API トークン',
          nameIsRequired: '名前は必須です',
          connect: '接続',
          testLead: 'テストリード',
          questionColon: '質問:',
          addParameters: 'パラメータを追加',
          select: '選択',
          mapWith: 'にマップ',
          selectParameter: 'パラメータを選択',
          update: '更新',
          save: '保存',
          disconnect: '切断',
          placeholders: {
            enterApiToken: 'API トークンを入力',
          }
        }
      },
      createIntegration: {
        createNewIntegration: '新しい統合を作成',
        youDoNotHaveAnyIntegrationSetupAsOfNow: '現在、統合が設定されていません。',
        pleaseCreateOneToIntegrateYourFavourite: 'お気に入りを統合するには、統合を作成してください。',
        appsWith: 'でアプリケーションと統合',
        integrationName: '統合名',
        integrationNameIsRequired: '統合名は必須です',
        continue: '続行',
        addAnImageToRepresentYourIntegration: '統合を表す画像を追加',
        description: '説明',
        submit: '送信',
        youHaveSuccessfullyCreatedIntegration: '統合を正常に作成しました。これを設定できます。',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: '有効なAPI URLを入力してください',
        requestMethod: 'リクエスト方法',
        get: 'GET',
        post: 'POST',
        put: 'PUT',
        patch: 'PATCH',
        queryParameter: 'クエリパラメータ',
        addParameter: 'パラメータを追加',
        mapsWith: 'とマッピング',
        question: '質問',
        addQuestion: '質問を追加',
        select: '選択',
        selectParameter: 'パラメータを選択',
        header: 'ヘッダー',
        addHeader: 'ヘッダーを追加',
        value: '値',
        connect: '接続',
        requestBody: 'リクエストボディ',
        validate: '検証',
        key: 'キー',
        // value: '値',
        // connect: '接続',
      }
    },
    createIntegration: {
      createNewIntegration: '新しい統合を作成',
      youDoNotHaveAnyIntegrationSetupAsOfNow: '現在、統合は設定されていません。',
      pleaseCreateOneToIntegrateYourFavourite: 'お気に入りを統合するには、統合を作成してください。',
      appsWith: 'アプリケーションの',
      integrationName: '統合名',
      integrationNameIsRequired: '統合名は必須です',
      continue: '続行',
      addAnImageToRepresentYourIntegration: '統合を表す画像を追加してください。',
      description: '説明',
      submit: '提出',
      youHaveSuccessfullyCreatedIntegration: '統合を作成しました。これを設定できます。',
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: '有効なAPI URLを入力してください',
      requestMethod: 'リクエストメソッド',
      get: '取得',
      post: 'ポスト',
      put: '置く',
      patch: 'パッチ',
      queryParameter: 'クエリパラメータ',
      addParameter: 'パラメータを追加',
      mapsWith: 'にマップする',
      question: '質問',
      addQuestion: '質問を追加',
      select: '選択',
      selectParameter: 'パラメータを選択',
      header: 'ヘッダー',
      addHeader: 'ヘッダーを追加',
      value: '値',
      connect: '接続',
      requestBody: 'リクエストボディ',
      validate: '検証',
      key: 'キー',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: 'はこの無料CRMとも統合できます。',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: '有効なAPI URLを入力してください',
        requestMethod: 'リクエスト方法:',
        get: 'GET',
        post: 'POST',
        put: 'PUT',
        patch: 'PATCH',
        queryParameter: 'クエリパラメータ',
        addParameter: 'パラメータを追加',
        header: 'ヘッダー ',
        addHeader: 'ヘッダーを追加',
        requestBody: 'リクエストボディ',
        validateJson: 'JSONを検証',
        connect: '接続',
        update: '更新',
        delete: '削除',
        selectTheQuestion: '値として送信したいユーザーの回答を選択',
        appTooltip: {
          typeYourJsonCode: 'JSONコードを入力',
          clickToValidateJsonCode: 'JSONコードを検証するにはクリック',
        }
      },
      list: {
        customAppsIntegrations: 'カスタムアプリケーション統合',
        integrateYourAppsWith: '独自のアプリケーションを統合',
        help: 'ヘルプ',
        addIntegration: ' 統合を追加',
        name: '名前',
        status: 'ステータス',
        action: 'アクション',
        insatlled: 'インストール済み',
        edit: '編集',
        connect: '接続',
        requestYourIntegration: '統合をリクエスト',
        toRequestNewIntegration: '新しい統合をリクエストするには、以下の詳細を入力してください。その後、お知らせします。',
        emailId: 'メールアドレス',
        integrationName: '統合名',
        contactNumber: '連絡先番号',
        howSoonYouWantToAddThisIntegration: 'この統合を追加したいタイミング',
        sendRequest: 'リクエストを送信',
        cancel: 'キャンセル',
        delete: '削除',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'おっと！独自の統合は以下のプランでのみサポートされています。',
        plan: 'プラン',
        pleaseUpgradeTo: 'アップグレードしてください',
        toCreateOwnIntegrations: '独自の統合を作成するために',
        appTooltip: {
          integrations: '統合',
          noIntegrationsFound: '統合が見つかりませんでした！',
          nameOfYourApp: 'アプリ名',
          clickToConnectApp: 'アプリを接続するにはクリック',
          statusAsConnectedOrNotConnected: '接続済み/未接続のステータス',
        }
      }
    },
  },
};
