import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AccessControlService } from 'src/appv2/services/access-control.service';

@Directive({
  selector: '[appAcl]'
})
export class AclDirective implements OnInit {
  @Input() permission = '';

  constructor(
    private element: ElementRef,
    private aclService: AccessControlService
  ) {

  }

  ngOnInit(): void {
    this.aclService.permissions
      .subscribe((permissions: { fetched: boolean, resources: string[] }) => {
        if (permissions.fetched) {
          const canAccess = permissions.resources.find(resource => resource === this.permission);

          let requiredFlag = false;
          let canAccessParent = false;
          const optionalValues = (this.permission || '').split('||');
          const requiredValues = (this.permission || '').split('&&');

          requiredFlag = requiredValues.every((data: any) =>
            permissions.resources.find(resource => resource === data));

          if (requiredValues.length > 1 && !requiredFlag) {
            return this.element.nativeElement.remove();
          }

          optionalValues.forEach((data: any) => {
            if (permissions.resources.find(resource => resource === data)) {
              canAccessParent = true;
            }
          });

          if (!canAccess && !canAccessParent) {
            this.element.nativeElement.remove();
          }
        }
      });
  }

}
