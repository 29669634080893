import {createAction, props} from '@ngrx/store';

export const loadCustomers = createAction(
  '[Customer] Load Customers',
  props<{ customers: any[] }>()
);

export const loadMoreCustomers = createAction(
  '[Customer] Load More Customers',
  props<{ customers: any[] }>()
);
export const addCustomer = createAction(
  '[Customer] Add Customer',
  props<{ customer: {} | any }>()
);
export const deleteCustomer = createAction(
  '[Customer] Delete Customer',
  props<{ customer: number | string }>()
);
export const updateCustomer = createAction(
  '[Customer] Update Customer',
  props<{ customer: number | string, update: {} | any }>()
);
