
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmEmailComponent } from './authentication/confirm-email/confirm-email.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { LoginWrapperComponent } from './authentication/login-wrapper/login-wrapper.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { NewPasswordComponent } from './authentication/new-password/new-password.component';
import { NewSignupComponent } from './authentication/new-signup/new-signup.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { VerifyEmailComponent } from './authentication/verify-email/verify-email.component';
import { AgencyGuard } from './guards/agency.guard';
import { AuthGuard } from './guards/auth.guard';
import { LoginRegisterGuard } from './guards/login-register.guard';
import { WidgetGuard } from './guards/widget.guard';
import { GoogleRedirectComponent } from './modules/bot-builder/components/integrations/components/all-integration/components/providers/google-redirect/google-redirect.component';
import { UnsubscribeComponent } from './modules/unsubscribe/unsubscribe.component';
import { UnsavedWarningGuard } from './guards/unsaved-warning.guard';


const routes: Routes = [
  { path: 'login', redirectTo: '/authentication', pathMatch: 'full' },
  { path: 'authentication', component: NewSignupComponent, canActivate: [LoginRegisterGuard], },

  {
    path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard, UnsavedWarningGuard],
  },
  // {
  //   path: 'bot-builder', loadChildren: () => import('./modules/bot-builder/bot-builder.module').then(m => m.BotBuilderModule),
  //   canLoad: [AuthGuard],
  //   canActivate: [UnsavedWarningGuard]
  // },
  {
    path: 'inbox', loadChildren: () => import('./modules/inbox/inbox.module').then(m => m.InboxModule),
    canLoad: [AuthGuard],
    canActivate: [UnsavedWarningGuard]

  },
  {
    path: 'inbox-merged', loadChildren: () => import('./modules/inbox-merged/inbox-merged.module').then(m => m.InboxMergedModule),
    canLoad: [AuthGuard],
    canActivate: [UnsavedWarningGuard]

  },
  // {
  //   path: 'bot-builder/onboarding', loadChildren: () => import('./modules/bot-builder/bot-builder.module').then(m => m.BotBuilderModule),
  //   canLoad: [AuthGuard],
  // },
  // {
  //   path: 'analytics', loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule),
  //   canLoad: [AuthGuard],
  //   canActivate: [UnsavedWarningGuard]

  // },
  {
    path: 'team', loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule),
    canLoad: [AuthGuard],
    canActivate: [UnsavedWarningGuard]
  },
  // {
  //   path: 'subscription', loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule),
  //   canLoad: [AuthGuard],
  //   canActivate: [UnsavedWarningGuard]
  // },
  // {
  //   path: 'notification', loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
  //   canLoad: [AuthGuard],
  //   canActivate: [UnsavedWarningGuard]
  // },
  {
    path: 'settings', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canLoad: [AuthGuard],
    canActivate: [UnsavedWarningGuard]

  },
  // {
  //   path: 'developer', loadChildren: () => import('./modules/developer/developer.module').then(m => m.DeveloperModule),
  //   canLoad: [AuthGuard],
  //   canActivate: [UnsavedWarningGuard]

  // },
  // {
  //   path: 'agency', loadChildren: () => import('./modules/agency/agency.module').then(m => m.AgencyModule),
  //   canLoad: [AuthGuard, AgencyGuard],
  //   canActivate: [UnsavedWarningGuard]
  // },
  // {
  //   path: 'whitelabel', loadChildren: () => import('./modules/whitelabel/whitelabel.module').then(m => m.WhitelabelModule),
  //   canLoad: [AuthGuard],
  //   canActivate: [UnsavedWarningGuard]
  // },
  // {
  //   path: 'widgets', loadChildren: () => import('./modules/widgets/widgets.module').then(m => m.WidgetsModule)
  // },
  {
    path: 'access-control', loadChildren: () => import('./modules/access-control/access-control.module').then(m => m.AccessControlModule),
    canLoad: [AuthGuard],
    canActivate: [UnsavedWarningGuard]
  },
  { path: 'new-password', component: NewPasswordComponent, canActivate: [LoginRegisterGuard], },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [LoginRegisterGuard], },
  { path: 'confirm-email', component: ConfirmEmailComponent, canActivate: [LoginRegisterGuard], },
  { path: 'verify-email', component: VerifyEmailComponent, canActivate: [LoginRegisterGuard], },
  { path: 'api-login', component: LoginWrapperComponent, canActivate: [LoginRegisterGuard] },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'zoho-desk.html', component: GoogleRedirectComponent },
  { path: 'zoho-projects.html', component: GoogleRedirectComponent },
  { path: 'zoho-crm.html', component: GoogleRedirectComponent },
  { path: 'google-redirect.html', component: GoogleRedirectComponent },
  { path: 'outlook.html', component: GoogleRedirectComponent },
  { path: 'salesforce-crm.html', component: GoogleRedirectComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  { path: '**', redirectTo: '/authentication', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      enableTracing: false,
      onSameUrlNavigation: 'reload'
    }),
  ],
  exports: [RouterModule],
})



export class AppRoutingModule {
}
