const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Bearbeiten Sie Ihren Chat -Fluss',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    classicBuilder: 'Klassischer Baumeister',
    visualiseFlow: 'Visualisieren Sie den Fluss',
    testLiveOnTelegramWeb: 'Testen Sie live im Telegramm -Web',
    testFlow: 'Testfluss',
    importTemplate: 'Vorlage importieren',
    landingPageBotReview: 'Zielseite Bot Vorschau',
    install: 'Installieren',
    testLiveOnMessengerWeb: 'Testen Sie live im Messenger -Web',
    testLiveOnWhatsappWeb: 'Testen Sie live auf WhatsApp -Web',
    testLiveOnInstagramWeb: 'Testen Sie live auf Instagram Web',
    seeAllChatFlow: 'Sehen Sie alle Chat -Flüsse',
    launchDemoModal: 'Starten Sie Demo Modal',
    selectATemplateToKickStartYourBot: 'Wählen Sie eine Vorlage aus, um Ihren Bot zu starten',
    hireChatBotDesigner: 'Mieten Sie Chatbot Designer',
    recommendedForYou: 'für dich empfohlen',
    useTemplate: 'Benutze Template',
    exportStatus: 'Exportstatus',
    flowExport: 'Flow-Export',
    download: 'Herunterladen',
    importFlow: 'Importfluss',
    beforeYouStartUploadPleaseMakeSure: 'Bevor Sie mit dem Upload beginnen, stellen Sie bitte sicher',
    ponit1: '1. Das Upload -Grenzwert der maximalen Dateigröße beträgt 1 MB',
    point2: '2. Das Dateiformat sollte in JSON vorliegen',
    selectFile: 'Datei aussuchen',
    preview: 'Vorschau',
    test: 'Test',
    OopsIntegrationAreNotAvailable: '¡Ups! Las integraciones no están disponibles en el ',
    pleaseUpgradeTo: ' plan. Por favor, actualice al ',
    planForIntegration: ' para las integraciones.',
    placeholders: {
      searchTemplateYouNeed: 'Suchen Sie die benötigte Vorlage, die Sie benötigen',
    },
    noDataFound: {
      botFlow: 'Bot Flow',
      noBotFlowAddedYet: 'Noch kein Bot Flow hinzugefügt!',
      templates: 'Vorlagen',
      noTemplatesFound: 'Keine Vorlage gefunden!',
    },
    appTooltip: {
      exportFlow: 'Exportabfluss',
      importFlow: 'Importfluss',
      clickToViewChatFlow: 'Klicken Sie hier, um den Chat -Fluss im Drag & Drop Builder anzuzeigen',
      clickToPreviewChatFlow: 'Klicken Sie hier, um den Chat -Fluss voranzutreiben',
      clickToViewChatFlowAsFlowDiagram: 'Klicken Sie hier, um den Chat -Fluss als Flussdiagramm anzuzeigen',
      clickToTestChatFlow: 'Klicken Sie hier, um den Chat -Fluss zu testen',
      clickToSelectAndImportTemplate: 'Klicken Sie, um die Vorlage auszuwählen und zu importieren, die am besten zu Ihrem Geschäft passt.',
      clickToInstallYourBotToSelectedPlatform: 'Klicken Sie hier, um Ihren Bot auf der ausgewählten Plattform zu installieren',
      clickToTestDefaultFlowOfTelegramBot: 'Klicken Sie hier',
      clickToTestDefaultFlowOfFacebookBot: 'Klicken Sie hier',
      clickToTestDefaultFlowOfWhatsappBot: 'Klicken Sie hier',
      clickToTestDefaultFlowOfInstagramBot: 'Klicken Sie hier',
    }
  },
  createNewBot: {
    createNewBot: 'Neuen Bot erstellen',
    stepOneOfTwo: 'Schritt 1 von 2',
    selectThePlatform: 'Wählen Sie die Plattform aus',
    selectTheBotType: 'Wählen Sie den Bot-Typ aus',
    aiBasedChatbot: 'KI-basierter Chatbot',
    ruleBasedChatBot: 'Regelbasierter Chatbot',
    webPlatforms: 'Webplattformen',
    websiteChatBot: 'Website Chatbot',
    withLiveChat: '(Mit Live -Chat)',
    landingPageBot: 'Landing Page Bot',
    liveChatWidget: 'Live -Chat -Widget',
    onlyLiveChat: '(Nur Live -Chat)',
    socialPlatforms: 'Soziale Plattformen',
    whatsappChatBot: 'WhatsApp Chatbot',
    telegramChatBot: 'Telegramm -Chatbot',
    facebookChatBot: 'Facebook Chatbot',
    instagramChatBot: 'Instagram Chatbot',
    selectBotType: 'Seleccionar tipo de bot',
    selectProblem: 'Wählen Sie das Problem aus, das Sie lösen möchten',
    leadGeneration: 'Lead-Generierung',
    customerSupport: 'Kundensupport',
    appointmentBooking: 'Terminbuchung',
    marketingAutomation: 'Marketingautomatisierung',
    ecommerceWebsite: 'E-Commerce-Website',
    otherUseCase: 'Andere',
    back: 'Zurück',
    typeDomainUrl: 'Geben Sie Ihre Website-URL zur Überprüfung ein.',
    dataSourceForChatBotTraining: 'Datenquelle für Chatbot-Training',
    upload: 'Hochladen',
    uploadDocuments: 'Dokumente hochladen',
    uploadDocument: 'Dokument hochladen',
    selectFile: 'Datei auswählen',
    continue: 'Weiter',
    websiteUrl: 'Geben Sie Ihre Website-URL ein',
    skipNow: 'Jetzt überspringen',
    completeTheExistingBotSetup: 'Komplettieren Sie den vorhandenen Bot-Setup',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Sie haben bereits einen WhatsApp -Bot erstellt, melden Sie sich bitte an, um einen neuen zu erstellen.',
    completeSetup: 'Komplettieren Sie den Setup',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    fbBot: {
      createNewBot: 'Neuen Bot erstellen',
      help: 'Hilfe',
      back: 'Zurück',
      step: 'Schritt',
      of3: ' von 3',
      loginWithFacebook: 'Mit Facebook einloggen',
      asPerLatestChangesInFacebookPolicy: 'Laut den neuesten Änderungen in den Facebook -Richtlinien muss der Facebook -Bot -Eigentümer über "Manager" oder höhere Rolle verfügen, um auf die gesamte Funktion der Facebook -Seite zuzugreifen.',
      login: 'Anmeldung',
      connectFacebookBot: 'Verbinden Sie Facebook Bot',
      connectBotWithYourFacebookPagesAnd: 'Verbinden Sie Bot mit Ihren Facebook -Seiten und',
      exploreTruePowerOfFacebook: 'Erforschen Sie die wahre Kraft von Facebook',
      connect: 'Verbinden',
      connected: 'In Verbindung gebracht',
      createNewPage: 'Neue Seite erstellen',
      refreshPageList: 'Seitenliste aktualisieren',
      thisPageIsSuccessfullyIntegratedWith: 'Diese Seite ist erfolgreich integriert in',
      yourMessagesFromMessengerWillAppearIn: '. Ihre Nachrichten von Messenger erscheinen in',
      inbox: ' Posteingang',
      continueToBotConfiguration: 'Fahren Sie weiter mit der Bot -Konfiguration',
      welcomeToBrandNameWeAreHereToHelpYou: `Willkommen bei ${brand}. Wir sind hier, um dir zu helfen.`,
      letsTryYourBotAndSeeHowItWorks: 'Versuchen wir Ihren Bot und sehen Sie, wie es funktioniert! Klicken Sie auf "Test auf Facebook".',
      howAreYouDoingToday: 'Wie geht es dir heute?',
      thisIsHowSimpleToUseFacebookBot: 'Das ist es! So einfach ist es, Facebook Bot zu verwenden',
      letsCreateYourOwnChatFlowNow: 'Erstellen Sie jetzt Ihren eigenen Chat -Fluss',
    },
    instagramBot: {
      createNewBot: 'Neuen Bot erstellen',
      back: 'Zurück',
      step: 'Schritt',
      of3: ' von 3',
      loginWithInstagram: 'Melden Sie sich mit Instagram an',
      loginToYourInstagramAccount: 'Melden Sie sich in Ihrem Instagram -Konto an, um den Chatbot in Ihrem Griff zu aktivieren.',
      managerLevelDescription: 'Instagram -Bot -Eigentümer müssen mindestens über einen "Manager" -Niveau oder höher verfügen, um auf alle Funktionen zuzugreifen.',
      login: 'Anmeldung',
      connectInstagramBot: 'Verbinden Sie Instagram Bot',
      connectBotWithYourInstagramAnd: 'Verbinden Sie Bot mit Ihrem Instagram und',
      exploreTruePowerOfInstagram: ' Erforschen Sie die wahre Kraft von Instagram',
      connected: 'In Verbindung gebracht',
      connect: 'Verbinden',
      createNewPage: 'Neue Seite erstellen',
      refreshPageList: 'Seitenliste aktualisieren',
      thisPageIsSuccessfullyintegratedWith: 'Diese Seite ist erfolgreich integriert in',
      yourMessagesFromMessengerWillAppearIn: '. Ihre Nachrichten von Messenger erscheinen in',
      inbox: ' Posteingang',
      continueToBotConfiguration: 'Fahren Sie weiter mit der Bot -Konfiguration',
      welcomeToBrandNameWeAreHereToHelpYou: `Willkommen bei ${brand}. Wir sind hier, um dir zu helfen.`,
      letsTryYourBotAndSeeHowItWorks: 'Versuchen wir Ihren Bot und sehen Sie, wie es funktioniert! Klicken Sie auf "Test auf Instagram".',
      howAreYouDoingToday: 'Wie geht es dir heute?',
      thisIsHowSimpleToUseInstagramBot: 'Das ist es! So einfach ist es, Instagram Bot zu verwenden',
      letsCreateYourOwnChatFlowNow: 'Erstellen Sie jetzt Ihren eigenen Chat -Fluss',
    },
    telegramBot: {
      back: 'Zurück',
      step: 'Schritt',
      of3: ' von 3',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      help: 'Hilfe',
      loginToYourTelegramAccount: 'Melden Sie sich in Ihrem Telegrammkonto an',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Melden Sie sich in Ihrem Konto an, um einen neuen Bot zu erstellen oder den vorhandenen Bot zu verbinden',
      verificationCodeSentOnRegisteredNumber: 'Überprüfungscode auf der registrierten Nummer gesendet',
      createYourBot: 'Erstellen Sie Ihren Bot',
      enterYourBotsNameAnd: 'Geben Sie den Namen Ihres Bots ein, verbinden Sie sich und lassen Sie den Spaß beginnen',
      botUserNameMustEndWithBotAtTheEnd: 'Bot Benutzername muss am Ende mit dem "Bot" enden.',
      terrisBot: 'Z.B. teris_bot oder terisbot',
      botNameAlreadyTaken: 'Dieser Bot -Name ist bereits genommen. Bitte probieren Sie etwas anderes aus',
      connect: 'Verbinden',
      createBot: 'Bot erstellen',
      codeNotReceivedResend: 'Code nicht empfangen? Erneut senden!',
      changeNumber: 'Nummer wechseln',
      telegramPreview: 'Telegrammvorschau',
      worldsFastestMessagingApp: 'Die schnellste Messaging -App der Welt und es ist kostenlos!',
      bot: 'Bot',
      phoneNumber: 'Telefonnummer',
      userName: 'Nutzername',
      none: 'Keiner',
      bio: 'Bio',
      fewWordsAboutYourself: 'Ein paar Worte über sich selbst',
      settings: 'Einstellungen',
      notificationsAndSound: 'Benachrichtigungen und Geräusche',
      privacyAndSecurity: 'Privatsphäre und Sicherheit',
      dataAndStorage: 'Daten und Speicher',
      chatSettings: 'Chat Einstellungen',
      chatFolders: 'Chatordner',
      devices: 'Geräte',
      connectYourBot: 'Verbinden Sie Ihren Bot',
      createNewBotOrConnectWithExistingOne: 'Erstellen Sie einen neuen Bot oder verbinden Sie den vorhandenen',
      createNewBot: 'Neuen Bot erstellen',
      welcomeToBrandNameWeAreHereToHelpYou: `Willkommen bei ${brand}. Wir sind hier, um dir zu helfen.`,
      letsTryYourBotAndSeeHowItWorks: 'Versuchen wir Ihren Bot und sehen Sie, wie es funktioniert! Klicken Sie auf "Test auf Telegramm".',
      howAreYouDoingToday: 'Wie geht es dir heute?',
      thisIsHowSimpleToUseTelegramBot: 'Das ist es! So einfach ist es, Telegram Bot zu verwenden',
      letsCreateYourOwnChatFlowNow: 'Erstellen Sie jetzt Ihren eigenen Chat -Fluss',
      placeholders: {
        searchCountry: 'Suchland',
        enterTelegramMobileNumber: 'Geben Sie Telegram Handynummer ein',
        enterLoginCodeReceived: 'Geben Sie den auf Telegrammkonto empfangenen Anmeldecode * ein *',
        twoFactorAuthentication: '2fa Passwort (optional)',
        botName: 'Bot Name *',
        botUserName: 'Bot Benutzername *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Neuen Bot erstellen',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      help: 'Hilfe',
      back: 'Zurück',
      step: 'Schritt',
      of2: ' von 2',
      buildA: 'Bau ein',
      thatMatchesYourBrandPersonality: ' Das entspricht der Persönlichkeit Ihrer Marke',
      chatBotIcon: 'Chatbot -Symbol',
      optional: 'Optional',
      themeColor: 'Themenfarbe',
      chatBotName: 'Chatbot -Name',
      botNameIsRequired: 'Bot Name ist erforderlich',
      botNameShouldBeLessThan50Characters: 'Der Name des Bots sollte weniger als 50 Zeichen betragen',
      continue: 'Weitermachen',
      welcomeToBrandNameWeAreHereToHelpYou: `Willkommen bei ${brand}. Wir sind hier, um dir zu helfen.`,
      whatIsYourEmailAddress: 'Wie lautet deine E-Mail Adresse?',
      invalidEmailAddress: 'Ungültige E-Mail-Adresse',
      customizeYourLandingPageBotLook: 'Passen Sie Ihren Zielseiten -Bot -Look an',
      customizeYourWidgetLook: 'Passen Sie Ihren Widget -Look an',
      customizeYourBotLook: 'Passen Sie Ihren Bot -Look an',
      buildYourLandingPageBotAndAutomateInteraction: 'Erstellen Sie Ihren Zielseiten -Bot und automatisieren Sie die Interaktion mit Ihren Kunden, indem Sie seinen Link teilen',
      useLiveChatBotToConnectToCustomers: 'Verwenden Sie Live -Chat -Bot, um sich in Echtzeit mit Ihren Kunden zu verbinden',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Schließen Sie Chatbot mit Ihrer Website an und automatisieren Sie die Interaktionen mit Besuchern',
      widget: 'Widget',
      botInterface: 'Bot -Schnittstelle',
      mergedBot: 'Website-Chatbot, Landingpage-Bot, Chatbot für mobile Apps',
      mergedBotToolTip: 'Erstellen Sie eine Bot-Seite, um die Aufmerksamkeit der Besucher zu erregen und sie in Gespräche einzubeziehen.',
      placeholders: {
        typeANameForYourBot: 'Geben Sie einen Namen für Ihren neuen Bot ein',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Geben Sie Ihren Botnamen ein',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Wählen Sie eine Themenfarbe für Ihr Chat -Fenster aus',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Dieses Logo wird nur in der ${brand ? brand : ''} -Plattform verwendet. Wir empfehlen Ihnen, Ihr Firmenlogo auszuwählen. Fügen Sie ein PNG- oder JPG -quadratisches Bild unter 1 MB hinzu.`,
      }
    },
    whatsappBot: {
      back: 'Zurück',
      justAStepAway: 'Nur einen Schritt entfernt',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      help: 'Hilfe',
      createWhatsappChatBot: 'Erstellen Sie WhatsApp -Chatbot',
      businessNameIsRequired: 'Firmenname ist erforderlich.',
      maximumLengthForBusinessName40Characters: 'Die maximale Länge für den Firmennamen beträgt 40 Zeichen.',
      businessCategory: 'Geschäftskategorie',
      categoryIsRequired: 'Kategorie ist erforderlich.',
      descriptionIsRequired: 'Beschreibung ist erforderlich.',
      maximumLengthForDescription200Characters: 'Die maximale Länge für die Beschreibung beträgt 200 Zeichen.',
      pleaseFillAValidEmailAddress: 'Bitte füllen Sie eine gültige Geschäfts -E -Mail aus.',
      businessEmailIsRequired: 'Business -E -Mail ist erforderlich.',
      phoneNumberShouldHaveMinimum7Digits: 'Die Telefonnummer sollte mindestens 7 Ziffern haben',
      phoneNumberIsRequired: 'Telefonnummer ist erforderlich',
      websiteIsRequired: 'Website ist erforderlich.',
      maximumLengthForWebsite40Characters: 'Die maximale Länge für die Website beträgt 40 Zeichen.',
      maximumLengthForAddress150Characters: ' Die maximale Länge für die Adresse beträgt 150 Zeichen.',
      doYouHaveWhatsappApiKey: 'Haben Sie WhatsApp -API -Schlüssel?',
      apiProvider: 'API -Anbieter',
      three60Dialog: '360 Dialog',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'NetCore',
      apiKeyIsRequired: 'API -Schlüssel ist erforderlich.',
      whatsappBusinessAccountIdIsRequired: 'WhatsApp Business Account ID ist erforderlich.',
      whatsappPhoneNumberIdIsRequired: ' WhatsApp -Telefonnummer -ID ist erforderlich.',
      systemAccessTokenIsRequired: 'Systemzugang ist erforderlich.',
      sourceIdIsRequired: 'Quell -ID ist erforderlich.',
      // apiKeyIsRequired: 'API -Schlüssel ist erforderlich.',
      // createWhatsappChatBot: 'Erstellen Sie WhatsApp -Chatbot',
      businessName: 'Firmenname',
      businessAccount: 'Geschäftskonto',
      // businessCategory: 'Geschäftskategorie',
      businessDescription: 'Geschäftsbeschreibung',
      address: 'Adresse',
      businessEmail: 'Geschäftliche E-Mail',
      websiteUrl: 'Webadresse',
      thankYouForYourInterestIn: 'Vielen Dank für Ihr Interesse an',
      whatsappChatBot: ' WhatsApp Chatbot.',
      weHaveReceivedYourInterests: 'Wir haben Ihr Interesse erhalten, und unser Verkaufsteam wird in den nächsten 24 Stunden mit Ihnen in Verbindung stehen.',
      theyWillProvideYouWhatsappApiKey: 'Sie bieten Ihnen WhatsApp -API -Schlüssel.',
      readyToCreateWhatsappBot: 'Ich bin bereit, WhatsApp -Bot zu erstellen',
      exploreWhatsappFeatures: 'Entdecken Sie WhatsApp -Funktionen',
      welcomeToBrandNameWeAreHereToHelpYou: `Willkommen bei ${brand}. Wir sind hier, um dir zu helfen.`,
      letsTryYourBotAndSeeHowItWorks: 'Versuchen wir Ihren Bot und sehen Sie, wie es funktioniert! Klicken Sie auf "Test auf WhatsApp".',
      howAreYouDoingToday: 'Wie geht es dir heute?',
      thisIsHowSimpleToUseWhatsappBot: 'Das ist es! So einfach ist es, WhatsApp -Bot zu verwenden',
      letsCreateYourOwnChatFlowNow: 'Erstellen Sie jetzt Ihren eigenen Chat -Fluss',
      connectWhatsAppBot: 'WhatsApp-Bot verbinden',
      createtWhatsAppBot: 'WhatsApp-Bot erstellen',
      proceed: 'Fortfahren',
      whatsApiProvider: 'WhatsApp-API-Anbieter',
      cloudApi: 'Cloud-API',
      netcoreApi: 'Netcore-API',
      placeholders: {
        yourBusinessName: 'Ihr Firmenname',
        businessDescription: 'Geschäftsbeschreibung',
        businessEmail: 'Geschäftliche E-Mail',
        phone: 'Telefon*',
        website: 'Website (z. B. www.example.com)',
        address: 'Adresse',
        enterApiKey: 'Geben Sie den API -Schlüssel ein',
        enterWhatsappBusinessAccountId: 'Geben Sie WhatsApp Business Account ID ein',
        enterWhatsappPhoneNumberId: 'Geben Sie die WhatsApp -Telefonnummer -ID ein',
        enterSystemAccessToken: 'Geben Sie Systemzugriffstoken ein',
        enterSourceId: 'Geben Sie die Quell -ID ein',
        step: 'Schritt',
        businessName: 'Unternehmensname',
        businessWebsite: 'Unternehmenswebsite',
        selectWhatsappApiProvider: 'Wählen Sie WhatsApp-API-Anbieter',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'Wählen Sie Ihren WA-API-Anbieter',
        doYouHaveWhatsAppBusinessAPI: 'Haben Sie WhatsApp Business API?',
        yes: 'Ja',
        noIAmNotSure: 'Nein, ich bin mir nicht sicher',
        selectApiProvider: 'Wählen Sie den API-Anbieter',
        whatsAppCloudAPI: 'WhatsApp Cloud API',
        enterYourPhoneNumberId: 'Geben Sie Ihre Telefonnummer-ID ein',
        phoneNumberId: 'Telefonnummer-ID',
        enterYourWhatsAppBusinessAccountId: 'Geben Sie Ihre WhatsApp Business Account ID ein',
        whatsAppBusinessAccountId: 'WhatsApp Business Account ID',
        enterYourSystemUserToken: 'Geben Sie Ihren Systembenutzer-Token ein',
        systemUserToken: 'Systembenutzer-Token',
        help: 'Hilfe',
        netcoreAPI: 'Netcore API',
        enterYourSourceId: 'Geben Sie Ihre Quellen-ID ein',
        sourceId: 'Quellen-ID',
        enterYourNetcoreApiKey: 'Geben Sie Ihren Netcore API-Schlüssel ein',
        netcoreApiKey: 'Netcore API-Schlüssel',
        submit: 'Senden',
        noneOftheAbove: 'Keine der oben genannten',
        next: 'Nächste'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Rechtlich registriertes Unternehmen',
        validWebsiteWithDataPrivacyPolicy: 'Gültige Website mit Datenschutzrichtlinie',
        gstin: 'GSTIN',
        debit: 'Lastschrift',
        creditCard: 'Kreditkarte',
        onlyRequiredForIndianResidents: 'Nur für indische Bewohner erforderlich',
        onlyRequiredFor: 'Nur erforderlich für',
        indianResidents: 'indische Einwohner',
        validPaymentMethod: 'Gültige Zahlungsmethode',
        enabledWithInternationalPayments: 'Mit internationalen Zahlungen aktiviert',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'Gültiger Anwendungsfall gemäß WhatsApp-Handelsrichtlinien',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Telefonnummer, die kein aktives WhatsApp hat und in der Lage ist, die SMS zu empfangen',
        discard: 'Verwerfen',
        proceed: 'Weiter',
        whatsAppBotPrerequisites: 'WhatsApp-Bot-Voraussetzungen',
        letUsGetItDoneForYou: 'Lassen Sie uns das für Sie erledigen!',
        ifYouAreFacingSomeIssues: 'Wenn Sie einige Probleme haben',
        ourTeamCanHelpYouDoTheSame: 'Unser Team kann Ihnen dabei helfen',
        requestACallbackNow: 'Jetzt einen Rückruf anfordern!',
      },
      stepThree: {
        setupWhatsAppBot: 'WhatsApp-Bot einrichten',
        selectingTheBestBusinessManagerForYourNeeds: 'Den besten Business Manager für Ihre Bedürfnisse auswählen',
        createMetaApp: 'Meta-App erstellen',
        createaMetaAppOn: 'Eine Meta-App erstellen auf',
        howToCreate: 'Wie man erstellt',
        addWhatsAppNumber: 'Wie man eine WhatsApp-Nummer hinzufügt',
        addYourWhatsAppNumberAndVerifyTheSame: 'Fügen Sie Ihre WhatsApp-Nummer hinzu und überprüfen Sie sie',
        subscribe: 'Abonnieren',
        copyAndSubscribeWebhook: 'Webhook kopieren und abonnieren',
        howToSubscribe: 'Wie man abonniert',
        copy: 'Kopieren',
        appSecretId: 'App Secret ID',
        enterYourAppSecretId: 'Geben Sie Ihre App Secret ID ein',
        enterAppSecret: 'App Secret eingeben',
        phoneNumberId: 'Telefonnummern-ID',
        whatsAppBusinessId: 'WhatsApp Business ID',
        assignSystemUser: 'Wie man einem Systembenutzer zuweist',
        assign: 'Zuweisen',
        createApp: 'App erstellen',
        registerAndVerify: 'Registrieren & Verifizieren',
        add: 'Hinzufügen',
        systemUserToken: 'Systembenutzer-Token',
        enterYourSystemUserToken: 'Geben Sie Ihr Systembenutzer-Token ein',
        getAllNecessaryDetails: 'Wie man alle notwendigen Details erhält',
        subscribeWhatsAppWebhook: 'Wie man den WA-Webhook abonniert',
        configureTheWebhookForWhatsAppAccount: 'Webhook für das WhatsApp-Konto konfigurieren',
        upadatePaymentMethod: 'Wie man die Zahlungsmethode aktualisiert',
        assignThePaymentMethodToWhatsAppAccount: 'Zahlungsmethode einem WhatsApp-Konto zuweisen',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Kontrolle über ein WhatsApp Business-Konto einem Systembenutzer gewähren',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Schlüsseldetails, die Sie aus dem Meta-Dashboard kopieren müssen',
        updatePayment: 'Zahlung aktualisieren',
        letUsGetItDoneForYou: 'Lassen Sie uns das für Sie erledigen!',
        ifYouAreFacingSomeIssues: 'Wenn Sie einige Probleme haben',
        ourTeamCanHelpYouDoTheSame: 'Unser Team kann Ihnen dabei helfen',
        requestACallbackNow: 'Jetzt einen Rückruf anfordern!',
        update: 'Aktualisieren',
        connectNow: 'Jetzt verbinden'
      },
      stepFive: {
        selectBusinessManager: 'Geschäftsmanager auswählen',
        selectingTheBestBusinessManagerForYourNeeds: 'Den besten Geschäftsmanager für Ihre Bedürfnisse auswählen'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Ihr Bot ist installiert und aktiv',
        youhaveSuccessfullyConnected: 'Sie haben erfolgreich verbunden',
        withWhatsApp: 'mit WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'Ihnen wurden standardmäßig 100 Unterhaltungen pro Monat zugewiesen',
        upgradeYourPlanForMore: 'Aktualisieren Sie Ihren Plan für mehr.',
        createChatFlow: 'Chat-Fluss erstellen',
        upgradePlan: 'Plan erweitern'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'QR-Code scannen oder Nachricht senden, um den Bot zu aktivieren',
        scanTheQrCodeFrom: 'Scannen Sie den QR-Code von',
        yourPhoneWillOpen: 'Ihr Telefon öffnet eine vorausgefüllte Nachricht, die an unsere Sandbox-Nummer gesendet werden soll. Drücken Sie auf WhatsApp auf "Senden"',
        sendMessageFromYourWhatsAppNumber: 'Senden Sie eine Nachricht von Ihrer WhatsApp-Nummer',
        sendUsAWhatsAppMessageOn: 'Senden Sie uns eine WhatsApp-Nachricht an ',
        bySavingInYourContactsAndMessageShouldStartWith: ' indem Sie sie in Ihren Kontakten speichern und die Nachricht sollte mit beginnen ',
        send: 'Senden',
        or: 'ODER'
      }
    }
  },
  gettingStarted: {
    hello: 'Hallo,',
    upgrade: 'Aktualisierung',
    gettingStarted: 'Einstieg -',
    yourFacebookBotToken: 'Ihr Facebook -Bot -Token',
    hasBeenExpired: 'ist abgelaufen',
    isExpiringIn: 'läuft in',
    days: ' Tage',
    facebookAuthenticationTokenRemainsFor60Days: 'Facebook Authentication Token bleibt nur 60 Tage lang aktiv',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Bitte führen Sie die Verlängerung Ihres Bot-Tokens durch, um den ununterbrochenen Zugriff auf Chatbot-Dienste sicherzustellen.',
    renewYourBotToken: 'Erneuere dein Bot -Token',
    editYourChatFlow: 'Bearbeiten Sie Ihren Chat -Fluss',
    buildConversationEngagingBot: 'Erstellen Sie ansprechende Gesprächsströme für Ihre Bot -Benutzer.',
    takes5minutes: 'dauert 5 Minuten',
    previewYourLiveChatWidget: 'Vorschau Ihres Live -Chat -Widgets',
    discoverHowThe: 'Entdecken Sie, wie das',
    liveChatFeatureWorks: ' Live -Chat -Feature funktioniert auf unserem Live -Chat -Spielplatz',
    takes2minutes: 'dauert 2 Minuten',
    takes1minute: 'dauert 1 min',
    connected: 'Verbunden',
    customizeYourBotApperance: 'Passen Sie das Aussehen und die Funktionalität Ihres Bots an.',
    takes3minutes: 'dauert 3 Minuten',
    thirdPartyAppsIntegration: 'Apps Integration von Drittanbietern',
    integrateYourBot: 'Integrieren Sie Ihren Bot in andere Apps/CRMs, um eine Geschäftsautomatisierung zu erstellen.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Aktualisieren Sie Ihren Plan, um Leads in Ihrer E -Mail zu erhalten',
    getStartedWithUs: 'Beginnen Sie mit uns',
    howToBuildAGoodChatFlow: 'So erstellen Sie einen guten Chatflow',
    howToUseBotPenguinsInboxAndItsFeatures: 'So verwenden Sie den Posteingang von Botpenguin und seine Funktionen',
    botPenguinsPricing: 'Preise von Botpenguin: Pläne und Funktionen erkunden',
    openHelpDesk: 'Helpdesk öffnen',
    helpAndSupport: 'Hilfe und Unterstützung',
    extensiveGuides: 'Umfangreiche Leitfäden für alle Ihre Fragen und Arbeitsabläufe sind in unserem verfügbar',
    resourceCentre: 'Ressourcenzentrum',
    checkoutOurAnswers: 'Sie können unsere Antworten auf die gängigen Fragen unten überprüfen.',
    createANewBot: 'Erstellen Sie einen neuen Bot',
    installYourBot: 'Installieren Sie Ihren Bot',
    plansAndPricing: 'Pläne und Preisgestaltung',
    previewYourLandingPageBot: ' Vorschau Ihres Zielseiten -Bots',
    installYourLiveChatWidget: 'Installieren Sie Ihr Live -Chat -Widget',
    installYourWebsiteBot: 'Installieren Sie Ihren Chatbot auf der Website, in der mobilen App oder starten Sie ihn als Landing Page oder eingebetteten Chatbot',
    integrateBotToLandingPage: 'Integrieren Sie den Bot in Ihren Zielseiten -Bot',
    installWidgetToWebsite: 'Installieren Sie das Widget auf Ihrer Website',
    installBotToWebsite: 'Installieren Sie Ihren Chatbot',
    whatsappAutomation: 'WhatsApp -Automatisierung',
    facebookAutomation: 'Facebook -Automatisierung',
    telegramBotSettings: 'Einstellungen für Telegramm -Bot',
    liveChatWidgetSettings: 'Live -Chat -Widget -Einstellungen',
    landingPageBotSettings: 'Zielseiten -Bot -Einstellungen',
    websiteChatBotSettings: 'Website -Chatbot -Einstellungen',
    instagramAutomation: 'Instagram -Automatisierung',
    diveDeeperIntoBotPenguin: 'Tauchen Sie tiefer in Botpenguin ein',
    knowWhereWeAreGoing: 'Wissen Sie, wohin wir gehen, die jüngsten Updates und helfen Sie uns, einige Fehler zu beheben.',
    productUpdates: 'Produktaktualisierungen',
    requestAFeature: 'Fordern Sie eine Funktion an',
    reportABug: 'Melde einen technischen Fehler',
    prodcutRoadmap: 'Produkt-Roadmap',
    trainYourChatbot: 'Trainieren Sie Ihren KI-Chatbot (Wissensbasis)',
    buildEngagingConversation: 'Erstellen Sie ansprechende Gesprächsabläufe für Ihre Bot-Benutzer.',
    previewYourAIBot: 'Sehen Sie sich Ihren KI-Bot in der Vorschau an',
    integrateAIbot: 'Integrieren Sie den Bot in Ihren KI-Bot',
    aiBotSettings: 'AI-Bot-Einstellungen',
    CustomizeAIBot: 'Passen Sie das Erscheinungsbild und die Funktionalität Ihres Bots an.',
    gptKeyWarning: 'Willkommen bei unserem ChatGPT API-Service! Bitte beachten Sie, dass es eine Beschränkung von 100 Nachrichten für jeden Bot gibt. Nachdem Sie das Limit von 100 Nachrichten erreicht haben, müssen Sie Ihren eigenen ChatGPT API-Schlüssel verwenden, um unseren Service weiterhin nutzen zu können. Um Ihren ChatGPT API-Schlüssel einzugeben, gehen Sie zur ChatGPT-Modellkonfiguration.',
    whatsappBotSettings: 'WhatsApp -Bot -Einstellungen',
    automateCustomerEngagementWithWhatsApp: 'Kundenengagement mit WhatsApp automatisieren',
    connectToYourWhatsappNumber: 'Mit Ihrer WhatsApp-Nummer verbinden',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Verbinden Sie Ihren WhatsApp-Bot mit Ihrer WhatsApp-Nummer',
    facebookBotSettings: 'Facebook Bot Einstellungen',
    automateCustomerEngagementWithTelegram: 'Automatisieren Sie die Kundenbindung mit Telegram',
    automateCustomerEngagementWithFacebook: 'Automatisieren Sie die Kundenbindung mit Facebook',
    automateCustomerEngagementWithInstagram: 'Automatisieren Sie die Kundenbindung mit Instagram',
    telegramAutomation: 'Telegram -Automatisierung'
  },
  install: {
    previewYourLandingPageBot: 'Vorschau Ihres Zielseiten -Bots',
    preview: 'Vorschau',
    test: 'Test',
    squareSpace: 'Quadrat',
    installYourChatBot: 'Installieren Sie Ihren Chat -Bot',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    help: 'Hilfe',
    uniqueLinkToLandingPageBot: 'Hier ist der eindeutige Link zu Ihrem Zielseite Bot.',
    shareOnSocialMedia: 'Teilen Sie es einfach mit Ihren Benutzern in sozialen Medien, Kontaktseiten oder Visitenkarten.',
    or: 'ODER',
    copyUrl: 'URL kopieren',
    selectYourWebsiteCms: 'Wählen Sie Ihre Website CMS aus',
    selectCms: 'Wählen Sie den CMS/Builder aus, mit dem Sie Ihre Website erstellt haben',
    custom: 'Brauch',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Andere CMS',
    howWouldYouLikeToInstall: 'Wie möchten Sie es installieren?',
    installingWebsiteBot: 'Wählen Sie, Sie werden den Bot auf Ihrer Website installieren',
    iWillAddMyself: 'Ich werde mich hinzufügen',
    getChatBotScript: 'Holen Sie sich unten Chatbot -Skriptcode und installieren Sie alles selbst',
    getWordpressPlugin: 'Holen Sie sich WordPress Plugin -API -Schlüssel',
    getOurOfficialPlugin: 'Holen Sie sich unser offizielles Plugin',
    fromWordpressPluginStore: ' aus dem WordPress Plugin Store',
    getPlugin: 'Holen Sie sich Plugin',
    quickGuideToInstall: 'Schnelle Anleitung zur Installation',
    placeApiKeyBelowIn: 'Fügen Sie die API -Taste unten in ein',
    afterDownloadingAndActivatingPlugin: ' Nach dem Herunterladen und Aktivieren von Plugin',
    copy: 'Kopieren',
    enterYourWebsite: 'Geben Sie Ihre Website ein und lassen Sie sich sehen, wie Ihre Chatbots aussehen',
    enterAValidUrl: 'Geben Sie eine gültige URL ein',
    verifyYourInstallation: 'Überprüfen Sie, ob Ihre Installation erfolgreich ist oder nicht?',
    testInstallation: 'Testinstallation',
    getChatBotScriptCode: 'Holen Sie sich Chatbot -Skriptcode',
    copyThisCodeSnippet: 'Kopieren Sie diesen Code -Snippet und fügen Sie ihn in die HTML jeder Webseite ein, auf der Sie den Chatbot anzeigen oder in Fußzeile in die Fußzeile einfügen möchten, bevor das Tag auf jeder Seite angezeigt wird.',
    // copy: 'Kopieren',
    sendCodeToMyDeveloper: 'Senden Sie Code an meinen Entwickler',
    sendCodeToMyDevelopmentTeam: 'Senden Sie Code an mein Entwicklungsteam',
    sendEmail: 'E-Mail senden',
    enterAValidEmail: 'Geben Sie eine gültige E -Mail ein',
    codeHasBeen: 'Code war',
    toYourDeveloper: ' an Ihren Entwickler',
    resent: 'übelnehmen',
    sent: 'gesendet',
    resendEmail: 'E-Mail zurücksenden',
    ask: 'Fragen',
    supportTeamToInstall: ' Support -Team zur Installation',
    raiseTheInstallationRequestTo: 'Erhöhen Sie die Installationsanfrage an',
    team: ' Team',
    helpMeInstallingChatBot: 'Hilf mir bei der Installation von Chatbot',
    yourBotInstallationIsNotActive: 'Ihre Bot -Installation ist nicht aktiv!',
    monthlyMessageLimitExceeded: 'Monatliches Nachrichtenlimit ausgerichtet',
    exceededMonthlyMessageLimitUpgradeNow: 'Sie haben Ihr monatliches Nachrichtenlimit jetzt überschritten, ein Upgrade.',
    upgradeNow: 'Jetzt upgraden',
    botInstallationNotActivePleaseFix: 'Ihre Bot -Installation ist nicht aktiv. Bitte beheben Sie dasselbe.',
    incorrectBotScript: 'Falsches Bot -Skript',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'Der von Ihnen überprüfende Installationscode stimmt nicht mit dem auf Ihrer Website überein. Bitte stellen Sie sicher, dass Sie den richtigen Code für diesen Bot installiert haben.',
    unableToSee: 'Unfähig zu sehen',
    codeOnYourWebsite: ' Code auf Ihrer Website',
    unableToFindCodePleaseReinstall: 'Wir können den Code auf Ihrer Website nicht finden, bitte installieren Sie sich erneut.',
    reinstallBot: 'Bot neu installieren',
    yourBotIsInstalledAndActive: 'Ihr Bot ist installiert und aktiv!',
    noPendingAction: 'Keine anhängige Aktion, Ihr Bot ist installiert und aktiv',
    youAreAllSet: 'Sie sind alle eingestellt!',
    showMeMyLeads: 'Zeig mir meine Leads',
    connectingToYourWebsiteToVerifyInstallation: 'Verbinden Sie eine Verbindung zu Ihrer Website, um die Bot -Installation zu überprüfen',
    thisMayTakeUpto30Seconds: 'Dies kann bis zu 30 Sekunden dauern',
    hereIsTheuniqueAILink: 'Hier ist der einzigartige Link zu Ihrem AI Bot',
    installHeading: 'Installieren Sie Ihren Chatbot',
    websiteChatbot: 'Website-Chatbot',
    aiChatBot: 'KI-Chatbot',
    landingPageBot: 'Landingpage-Bot',
    mobileChatBot: 'Chatbot für mobile Apps',
    embeddedBot: 'Eingebetteter Chatbot',
    selectMobileDevice: 'Wählen Sie Ihr Mobilgerät aus',
    mobileDeviceSubheading: 'Wählen Sie die verwendete Muttersprache aus, um Ihre mobile App zu erstellen',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Andere',
    copyEmbeddedCodeSnippet: 'Kopieren Sie diesen Code-Ausschnitt und fügen Sie ihn in den HTML-Code jeder Webseite ein, auf der Sie den Chatbot anzeigen möchten.',
    copyMobileCodeSnippet: 'Kopieren Sie diesen Code-Ausschnitt in Ihren mobilen Anwendungscode, wo Sie den Chatbot anzeigen möchten.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'Geben Sie Entwickler -E -Mails ein',
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Senden Sie Anweisungen an Ihren Entwickler',
      notSureHowToDoIt: 'Nicht sicher, wie es geht? Ihre Entwickler können es in einem Jiff tun.',
      developersEmail: 'Entwickler -E -Mail',
      emailIsRequired: 'E-Mail ist erforderlich',
      subject: 'Thema',
      message: 'Nachricht',
      send: 'Schicken',
      placeholders: {
        enterYourWorkEmail: 'Geben Sie Ihre Arbeits -E -Mail ein',
        createBotUsingBotPenguin: 'Aufgeregt! Erstellen Sie Bot mit Botpenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Hier ist der eindeutige Link zu Ihrem Zielseite Bot.',
      shareWithUsersOnSocialMedia: 'Teilen Sie es einfach mit Ihren Benutzern in sozialen Medien, Kontaktseiten oder Visitenkarten.',
      previewMyLandingPageBot: 'Vorschau meines Zielseiten -Bots',
      or: 'ODER',
      copyUrl: 'URL kopieren',
      howYouLikeToAddBot: 'Wählen Sie, wie Sie Ihren Bot -Code zu Ihrer Website hinzufügen möchten',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Installieren Sie den Bot auf Ihrer benutzerdefinierten codierten oder WordPress-Website, indem Sie einen eindeutigen Code kopieren.',
      name: 'Name',
      actions: 'Aktionen',
      javascript: 'JavaScript',
      install: 'Installieren',
      onYourCustomCodedWebsite: ' Auf Ihrer benutzerdefinierten codierten Website',
      copyAndPaste: 'Kopieren und Einfügen',
      copyThisCodeSnippet: 'Kopieren Sie diesen Code -Snippet und fügen Sie ihn in die HTML jeder Webseite ein, auf der Sie das Widget anzeigen möchten. Für mehr Details',
      seeGuide: 'Siehe Leitfaden',
      getCode: 'Code holen',
      howToInstall: 'Wie installiert man?',
      copyCode: 'Code kopieren',
      wordpress: 'WordPress',
      // install: 'Installieren',
      onYourWordPressWebiste: ' Auf Ihrer WordPress -Website',
      preview: 'Vorschau',
      test: 'Test',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Live -Chat -Vorschau',
    agentWindow: 'Agentenfenster',
    person: 'Person',
    inOrderToTestTypeHere: 'Um den Typ hier zu testen',
    agentWindowCannotBeCustomized: 'Hinweis: Das Agentenfenster kann nicht angepasst werden',
    customerWindow: 'Kundenfenster',
    clickHereToChat: 'Klicken Sie hier, um zu plaudern',
    customerWillChatHere: 'Der Kunde wird hier chatten',
    chat: 'Plaudern',
    by: 'von',
    changeYourBotLookFromLeftPanel: 'Ändern Sie Ihren Bot -Look aus dem linken Feld',
    placeholders: {
      typeYourMessage: 'Geben Sie Ihre Nachricht ein ...',
      typeYourAnswer: 'Gib deine Antwort ein',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Vorschau Ihres Live -Chat -Widgets',
    install: 'Installieren',
    seeHowToUse: 'Sehen Sie, wie man benutzt',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Klicken Sie hier, um Ihren Bot auf der ausgewählten Plattform zu installieren',
    }
  },
  trainYourBot: {
    trainYourBot: 'Trainieren Sie Ihren Chatbot',
    knowledgeBase: 'Wissensdatenbank',
    faqs: 'FAQs',
    history: 'Geschichte',
    faqHeading: 'Häufig gestellte Fragen',
    faqSubHeading: 'Antworten auf Ihre Chatbot-Training-Fragen',
    knowledgeBaseHeading: 'Trainieren Sie Ihren Chatbot',
    knowledgeBaseSubheading: 'Trainieren Sie Ihren Chatbot für verbesserte Gespräche',
    websiteLink: 'Website-Link',
    uploadFile: 'Laden Sie Ihre Datei hoch',
    selectFile: 'Datei auswählen',
    submit: 'Absenden',
    searchHere: 'Hier suchen',
    question: 'Frage',
    answer: 'Antwort',
    questionPlaceholder: 'Schreiben Sie hier Ihre Frage',
    answerPlaceholder: 'Schreiben Sie hier Ihre Antwort',
    save: 'Speichern',
    discard: 'Verwerfen',
    addFaq: 'FAQs hinzufügen',
    createdAt: 'Erstellt am',
    updatedAt: 'Zuletzt aktualisiert am',
    actions: 'Aktionen',
    noFaqFound: 'Sie haben noch keine FAQs erstellt',
    noFaqFoundMessage: 'Hier werden alle Ihre FAQs angezeigt',
    historyTitle: 'Verlauf der Wissensbasis',
    historySubtitle: 'Antworten auf Ihre Chatbot-Trainingsfragen',
    fileName: 'Dateiname',
    status: 'Status',
    noHistoryTitle: 'Sie haben noch keine trainierten Modelle erstellt',
    noHistoryDescription: 'Hier werden alle Ihre trainierten Modelle angezeigt',
    fileUpload: 'Datei hochladen',
    files: 'Dateien',
    fileSubHeading: 'Fügen Sie alle Dateien hinzu, auf denen Sie Ihren Bot trainieren möchten.',
    noFileFoundHeading: 'Sie haben noch keine Dateien erstellt',
    noFileFoundSubheading: 'Alle Ihre Dateien werden hier angezeigt',
    uploadYourFiles: 'Laden Sie Ihre Dateien hoch',
    upload: 'Hochladen',
    fileShouldBe: 'Die Datei sollte PDF, DOC, DOCX, PPTX, TXT sein.',
    uploadStatus: 'Upload-Status',
    uploadStatusSubHeading: 'Datei-Upload-Status für das Bot-Training',
    addFile: 'Datei hinzufügen',
    otherConfiguration: 'KI-Einstellungen',
    deleteFiles: 'Klicken Sie zum Löschen von Dateien',
    websiteURL: 'Website-URLs',
    websiteUrlSubheading: 'Fügen Sie alle Website-URLs hinzu, auf denen Sie Ihren Bot trainieren möchten.',
    enterWebsiteUrl: 'URL eingeben',
    addWebsite: 'Website hinzufügen',
    tableWebsiteUrl: 'Website-URL',
    trainingStatus: 'Trainingsstatus',
    lastTrained: 'Zuletzt trainiert',
    noWebsitesFound: 'Sie haben noch keine Websites erstellt',
    noWebsitesFoundMessage: 'Alle Ihre Websites werden hier angezeigt',
    loadMore: 'Mehr laden',
    botBehaviour: 'Bot-Verhalten konfigurieren',
    botBehaviourSubHeading: 'Bot-Persönlichkeit, Kundeninformationen ausblenden, Ablauf steuern und ChatGPT-Modell konfigurieren',
    botPersonality: 'Bot-Persönlichkeit konfigurieren',
    botPersonalitySubHeading: 'Verwalten, wie Ihr Bot in Bezug auf Persönlichkeit antworten soll',
    selectAnswerLength: 'Antwortlänge auswählen',
    answerLength: 'Antwortlänge',
    answerAs: 'Antwort als',
    tone: 'Ton',
    language: 'Sprache',
    answerFormatting: 'Antwortformatierung',
    customInstructions: 'Benutzerdefinierte Aufforderung',
    customerInstructionPlaceholder: 'Seien Sie höflich und freundlich. Verwenden Sie keine Umgangssprache. Verwenden Sie keine Emojis.',
    redactCustomerData: 'Kundendaten sammeln',
    redactDataSubheading: 'Konfigurieren Sie die Kundendaten, die Sie sammeln möchten',
    collectName: 'Namen erfassen',
    configureQuestion: 'Frage konfigurieren',
    maxCharacterLimit: 'Maximale Zeichenbegrenzung im bereitgestellten Namen',
    collectEmail: 'E-Mail erfassen',
    excludeEmail: 'E-Mails mit Domäne ausschließen',
    collectPhone: 'Telefonnummer erfassen',
    manageUnusualMessages: 'Ungewöhnliche Chat-Szenarien verwalten',
    unusualMessagesSubheading: 'Umgang mit unbeantworteten und anderen seltenen Szenarien für den Bot',
    startConversation: 'Nachricht, um das Gespräch zu beginnen',
    configureMessage: 'Nachricht, wenn der Bot keine Antwort geben kann',
    profanityMessage: 'Nachricht zur Antwort auf den unsachgemäßen Sprachgebrauch',
    chatGptModel: 'ChatGPT-Modellkonfiguration',
    chatGptModelSubheading: 'Verwalten, welches ChatGPT-Modell verwendet werden soll und Missbrauch vermeiden',
    chatGptApi: 'ChatGPT-API-Schlüssel',
    enableDisableRedactData: 'Kundendaten sammeln',
    triggerToCollectData: 'Kontaktdaten nach einer Reihe von Fragen sammeln.',
    train: 'Trainieren',
    website: 'Webseite',
    includedSourcesForResponse: 'Enthaltene Quellen für die Antwort',
    visibility: 'Sichtbarkeit',
    analytics: 'Analysen',
    yourConversation: 'Ihre Konversationen',
    reviewAndSelectConversation: 'Überprüfen und Auswählen von Konversationen zum Exportieren für das Chatbot-Training',
    exportAiAnalytics: 'Analytik exportieren',
    startDate: 'Startdatum',
    endDate: 'Einddatum',
    userQuestion: 'Benutzerfrage',
    botsReply: 'Bot-Antwort',
    dateCreated: 'Erstellungsdatum',
    noAnalyticsFound: 'Sie haben noch keine Analytik erstellt',
    noAnalyticsFoundMessage: 'Alle Ihre Analytik-Nachrichten werden hier angezeigt',
    exportData: 'Daten exportieren',
    exportCompleteAnalyticsData: 'Vollständige Analytikdaten exportieren',
    export: 'Exportieren',
    integrateWithChatGpt: 'ChatGPT integrieren',
    integrated: 'Integriert',
    configure: 'Konfigurieren',
    appToolTip: {
      deleteWebsite: 'Website löschen',
      knowledgeBase: 'Trainieren Sie Ihren Bot mit der Wissensdatenbank',
      faqs: 'Trainieren Sie Ihren Bot mit FAQ',
      knowledgeHistory: 'Verlauf der Wissensbasis',
      uploadFaqs: 'FAQs hochladen',
      addFaqs: 'Klicken Sie hier, um FAQs hinzuzufügen',
      deleteFaqs: 'FAQs löschen',
      deleteHistory: 'Verlauf löschen',
      uploadFile: 'Die hochgeladene Datei muss im PDF-, DOCX-, DOC-, oder CSV-Format sein und maximal 1 MB groß sein.',
      trainWebsite: 'Trainieren Sie Ihren Bot mit einer Website.',
      fileUpload: 'Bilden Sie Ihren Bot mit Dateien wie PDFs, TXTs, PPTXs, DOCs und DOCXs aus.',
      configureBotBehaviour: 'Passen Sie die Persönlichkeit des Bots an, verwalten Sie den Ablauf und passen Sie das ChatGPT-Modell an.',
      clickToAddWebsite: 'Zum Hinzufügen einer Website klicken',
      clickToDeleteWebsites: 'Zum Löschen von Websites klicken',
      syncWebsite: 'Website synchronisieren',
      copyUrl: 'URL kopieren',
      weWillBeFetchingAllTheWebpages: 'Wir werden alle Webseiten abrufen und das ChatGPT-Modell auf ihnen trainieren.'
    },
    modal: {
      deleteWebsiteUrl: 'Website-URL löschen',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Sind Sie sicher, dass Sie diese Website-URL löschen möchten?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Einstellungen für den Website-Chatbot',
    preview: 'Vorschau',
    test: 'Test',
    install: 'Installieren',
    designHeader: 'Design',
    generalHeader: 'Allgemein',
    alertsHeader: 'Warnungen',
    triggerHeader: 'Abzug',
    userAccessHeader: 'Benutzerzugriff',
    chatWindowHeader: 'Chatfenster',
    otherConfiguration: 'KI-Einstellungen',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Klicken Sie hier, um Ihren Bot auf der ausgewählten Plattform zu installieren',
      customizeAppearanceOfBot: 'Passen Sie die Positionierung und das Aussehen Ihrer Bot -Schnittstelle an.',
      configureBotsSettingsWhenAgentUnavailable: 'Konfigurieren Sie die Einstellungen von Bots, wenn Ihr Agent nicht verfügbar ist.',
      configureHowBotWillAlertYou: 'Konfigurieren Sie, wie Ihr Bot Sie über neue Leads aufmerksam macht.',
      makeSureYourBotsPopUpProperlyTimed: 'Stellen Sie sicher, dass die Pop-ups Ihres Bots perfekt abgestimmt sind.',
      customizeWhereYouWantYourBotToAppear: 'Passen Sie an, wo Ihr Bot erscheint und wo nicht.',
      enableChatOptionsForVisitorsToTransferChat: 'Aktivieren Sie die Chat -Optionen für Ihre Besucher, um Chats zu übertragen, Live -Chat zu live und den Chat zu aktualisieren.',
      configureBotBehaviour: 'Passen Sie die Persönlichkeit des Bots an, verwalten Sie den Ablauf und passen Sie das ChatGPT-Modell an.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Konfigurieren Sie die Warneinstellungen Ihres Bots',
      specifyHowYourBotWillNotify: 'Geben Sie an, wie Ihr Bot Sie über neue Leads informiert.',
      subject: 'Thema',
      emailNotification: 'Email Benachrichtigung',
      emailAddresses: 'E-mailadressen',
      leadRevisitNotification: 'Lead -Revision Benachrichtigungen',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'Sie haben sich erfolgreich bei Desktop -Benachrichtigungen registriert',
      youHaveBlockedYourNotificationsOnBrowser: 'Sie haben Benachrichtigungen über Ihre Browsereinstellungen blockiert.',
      discard: 'Verwerfen',
      saveChanges: 'Änderungen speichern',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      placeholders: {
        enterSubjectForYourEmail: 'Geben Sie Betreff für Ihre E -Mail ein',
        typeYourEmailAddressAndHitEnter: 'Geben Sie Ihre E -Mail -Adresse ein und drücken Sie die Eingabetaste',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Passen Sie das Thema Ihrer E -Mail -Benachrichtigungen aus dem Bot an',
        TOOLTIP_EMAIL_NOTIFICATION: 'Aktivieren/deaktivieren Sie die Benachrichtigung des Bots in Ihrer E -Mail für neue Leads',
        TOOLTIP_EMAIL_ADDRESS: 'Senden Sie eine E -Mail -Adresse des Kontos, in dem Sie E -Mail -Benachrichtigungen erhalten möchten. Sie können mehrere E -Mail -Adressen hinzufügen, indem Sie eine eingeben und die Eingabetaste drücken, um ein weiteres hinzuzufügen.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Aktivieren/deaktivieren Sie die Benachrichtigung von Bot in Ihrer E -Mail, wenn ein Benutzer zuvor wieder mit dem Bot mit dem Bot erfasst wurde.',

      }
    },
    broadcasts: {
      createBroadcastHeading: 'Sendung erstellen',
      youCanCreateMultipleBroadcasts: 'Sie können mehrere Sendungen erstellen',
      broadcastName: 'Rundfunkname',
      broadcastNameIsRequired: 'Sendungsname ist erforderlich',
      continue: 'Weitermachen',
      manageBroadcasts: 'Sendungen verwalten',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'Sie können mehrere Sendungen mit mehreren geplanten Vorlagen für verschiedene KundenGruppe erstellen.',
      createNewBroadcast: 'Neue Sendung erstellen',
      name: 'Name',
      segment: 'Gruppe',
      template: 'Vorlage',
      contacts: 'Kontakte',
      send: 'Schicken',
      delivered: 'Geliefert',
      read: 'Lesen',
      failed: 'Fehlgeschlagen',
      broadcastAt: 'Sendung bei',
      status: 'Status',
      actions: 'Aktionen',
      nA: 'N / A',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      deleteBroadcast: 'Sendung löschen',
      wantToDeleteThisBroadcast: 'Sind Sie sicher, dass Sie diese Sendung löschen möchten?',
      placeholders: {
        searchHere: 'Suche hier',
        enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Mehrere Sendungen löschen',
        broadcastAnalytics: 'Rundfunkanalyse',
        deleteBroadcast: 'Sendung löschen',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Sendung Erkenntnisse',
        broadcastStatus: 'Sendungsstatus',
        youCanFindDetailsOfEachContactBelow: 'Sie finden Details zu jedem Kontakt unten und exportieren Sie diejenigen, die Probleme haben können.',
        filterApplied: 'Filter angewendet',
        placeholders: {
          searchByPhoneNumber: 'Suche nach Telefonnummer',
          searchByName: 'Suchfilter mit Namen',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Datenbasierte Filterstatus filtern',
          exportData: 'Daten exportieren',
        },
        modal: {
          exportData: 'Daten exportieren',
          exportCompleteBroadcastData: 'Vollständige Broadcast -Daten exportieren',
          export: 'Export',
        }
      },
      createBroadcast: {
        broadcastName: 'Rundfunkname',
        broadcastNameIsRequired: 'Sendungsname ist erforderlich',
        template: 'Vorlage',
        segments: 'Gruppe',
        whenToSend: 'Wann zu senden',
        now: 'JETZT',
        later: 'SPÄTER',
        selectBroadcastUsing: 'Wählen Sie die Sendung mit',
        usingCsv: 'Mit CSV',
        usingSegment: 'Verwenden von Gruppe',
        allContacts: 'Alle Kontakte',
        selectDate: 'Datum auswählen',
        selectTimeSlot: 'Wählen Sie Zeitschlitz',
        assignVariablesToTemplates: 'Templates Variablen zuweisen',
        addHeaderMedia: 'Header Medien hinzufügen',
        selectFile: 'Datei aussuchen',
        cancel: 'Stornieren',
        createBroadcast: 'Sendung erstellen',
        broadcastUsing: 'Sendung mit',
        selectCSVFile: 'Wählen Sie CSV -Datei',
        importContacts: 'Kontakte importieren',
        beforeYouStartUpload: 'Bevor Sie mit dem Upload beginnen, stellen Sie bitte sicher',
        point1: '1. Laden Sie die Beispiel -CSV -Datei herunter.',
        point2: '2. Geben Sie Ihre Daten ab der 2. Reihe ab.',
        point3: '3. In jeder Zeile gibt es Daten für einen Kontakt',
        point4: '4. Stellen Sie sicher, dass alle Telefonnummern eindeutig sind.',
        point5: '5. Die maximale Upload -Grenze der Dateigröße beträgt 1 MB',
        // selectFile: 'Datei aussuchen',
        clickHere: 'klicken Sie hier',
        toDownloadSampleFile: 'Beispieldatei herunterladen',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          selectTemplate: 'Vorlage auswählen',
          searchTemplateByName: 'Suchvorlage nach Namen',
          selectSegments: 'Gruppe auswählen',
          selectTimeSlot: 'Wählen Sie Zeitschlitz',
          selectValueForThisVariable: 'Wählen Sie den Wert für diese Variable aus',
          searchHeaderByVariableName: 'Such -Header -Variable nach Namen',
          variableFallbackValue: 'Variabler Fallbackwert',
          searchBodyVariableByName: 'Suchkörpervariable nach Namen',
          searchSegmentByName: 'Gruppe nach Namen durchsuchen',
          selectBroadcastUsing: 'Wählen Sie die Sendung mit',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Wählen Sie Startdatum',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Kontakte für die WhatsApp -Sendung importieren',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'WhatsApp -Nummer',
        errorCode: 'Fehlercode',
        errorMessage: 'Fehlermeldung',
        nA: 'N / A',
      }
    },
    design: {
      customizeYourLandingPage: 'Passen Sie Ihre Zielseite an',
      customizeYourConversationalBot: 'Pas uw gespreksbot aan',
      customizeYourBot: 'Passen Sie Ihren Bot an',
      createHeadingTextAndTheme: 'Erstellen Sie Heading -Text und -Thema, das die Persönlichkeit Ihrer Marke widerspiegelt',
      content: 'Inhalt',
      theme: 'Thema',
      layout: 'Layout',
      landingPageName: 'Zielseitenname',
      botName: 'Bot Name',
      charactersRemaining: ' verbleibende Zeichen',
      landingPageHeader: 'Landing Page Header',
      landingPageDescription: 'Zielseite Beschreibung',
      hideContent: 'Inhalt verbergen',
      profilePic: 'Profilbild',
      selectFile: 'Datei aussuchen',
      socialMediaLinks: 'Social Media Links',
      initialMessage: 'Initial Message',
      selectType: 'Art auswählen',
      url: 'URL',
      delete: 'Löschen',
      editLink: 'Link bearbeiten',
      addLink: 'Link hinzufügen',
      callToAction: 'Aufruf zum Handeln',
      liveChatWelcomeMessage: 'Live -Chat Willkommensnachricht',
      liveChatTriggerMessage: 'Live -Chat -Triggernachricht',
      autoHideCallToAction: 'Automatischer Aufruf zum Handeln verstecken',
      maxDelayShouldBe300Seconds: 'Die maximale Verzögerung sollte 300 Sekunden betragen',
      landingPageThemeColor: 'Zielseitenthema Farbe',
      themeColor: 'Themenfarbe',
      landingPageBackground: 'Landing Page -Hintergrund',
      chatBackgroundColor: 'Chat -Hintergrundfarbe',
      color: 'Farbe',
      gradient: 'Gradient',
      image: 'Bild',
      video: 'Video',
      gradientStart: 'Gradientenstart',
      gradientEnd: 'Gradientenende',
      // selectFile: 'Datei aussuchen',
      positionOnWeb: 'Position im Web',
      positionOnMobile: 'Position auf dem Handy',
      windowSize: 'Fenstergröße',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'Xl',
      xxl: 'Xxl',
      custom: 'Brauch',
      height: 'Höhe',
      minimum300HeightIsRequired: 'Mindestens 300px Höhe ist erforderlich',
      heightCannotExceed720: 'Höhe darf 720px nicht überschreiten',
      width: 'Breite',
      px: 'px',
      minimum200WidthIsRequired: 'Mindestens 200px Breite ist erforderlich',
      widthCannotExceed1050: 'Die Breite darf 1050px nicht überschreiten',
      discard: 'Verwerfen',
      saveChanges: 'Änderungen speichern',
      landingPageBot: 'Landing Page Bot',
      chatBot: 'Chatbot',
      preview: ' Vorschau',
      botIcon: 'Bot -Ikone',
      websiteBot: 'Website-Chatbot',
      selectFont: 'Schriftart auswählen',
      fontLibrary: 'Schriftartenbibliothek',
      uploadFont: 'Schriftart hochladen',
      selectFontForBot: 'Schriftart für Bot auswählen',
      uploadedFont: 'Hochgeladene Schriftart',
      flowTriggerDetail: 'Trigger om contactgegevens te verzamelen',
      placeholders: {
        enterName: 'Name eingeben',
        enterHeaderText: 'Geben Sie den Header -Text ein',
        enterDescription: 'Beschreibung eingeben',
        enterUrl: 'URL eingeben',
        howCanIHelpYou: 'Womit kann ich Ihnen behilflich sein?',
        enterYourWelcomeMessageHere: 'Geben Sie hier Ihre Begrüßungsnachricht ein',
        enterYourLiveChatMessageHere: 'Geben Sie hier Ihre Live -Chat -Nachricht ein',
        enterTimeInSeconds: 'Geben Sie die Zeit in Sekunden ein',
        enterHeight: 'Höhe eingeben',
        enterWidth: 'Breite eingeben',
        flowInitialMessage: 'Voer de beginvraag in',
      },
      appTooltip: {
        standardLayout: 'Standardentwurf',
        rightLayout: 'Richtiges Layout',
        mergedLayout: 'Zusammengeführtes Layout',
        enterNameOfYourBot: 'Geben Sie den Namen Ihres Bots ein',
        enterNameOfYourLandingPageBot: 'Geben Sie den Namen Ihres Zielseitenbots ein',
        headingTextThatAppearOnLandingPageBot: 'Geben Sie den Übergangstext ein, den Sie auf Ihrem Zielseite Bot erscheinen möchten',
        descriptionTextThatAppearOnLandingPageBot: 'Geben Sie den Beschreibungstext ein, den Sie auf Ihrem Zielseite Bot ansehen möchten.',
        addLinksAndSocialMediaIcons: 'Fügen Sie Links und Social -Media -Symbole hinzu',
        initialFlowMessage: 'Voer het eerste bericht in dat de bot zal sturen om een ​​gesprek met de klant te beginnen',
        anInvitingCatchPhrase: 'Ein einladender Fangausdruck, der den Benutzer mit Bot sprechen lässt',
        setAWelcomeMessageForYourVisitors: 'Setzen Sie eine willkommene Nachricht für Ihre Besucher. Dies ist die erste Nachricht in Ihrem Gespräch.',
        setAMessageToInitiateLiveChat: 'Legen Sie eine Nachricht fest, um Live -Chat vom Ende des Besuchers zu initiieren. Sie erhalten jedes Mal, wenn diese Nachricht in einem Ihrer Gespräche ausgelöst wird, eine Benachrichtigung',
        autoHideCallToAction: 'Automatisch den Text "Call to Action" nach fester Zeit automatisch ausblenden',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Sie können das Logo und die Texte ausblenden, um nur das Chat -Fenster auf dem Bildschirm anzuzeigen',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Laden Sie ein Logo für die Chatbot -Seite hoch.',
        TOOLTIP_CHAT_THEME_COLOR: 'Wählen Sie die Farbe Ihres Bots -Chat -Fensters',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Wählen Sie Hintergrundfarbe der Bots Chat -Fenster',
        TOOLTIP_WEB_1ST_POSITION: 'Positionieren Sie Ihren Bot auf der unteren linken Links des BOT-Benutzerdesktops',
        TOOLTIP_WEB_2ND_POSITION: 'Positionieren Sie Ihren Bot in der mittleren rechten Ecke des Bot -Benutzer -Desktops',
        TOOLTIP_WEB_3RD_POSITION: 'Positionieren Sie Ihren Bot in der unteren rechten Ecke des BOT -Benutzer -Desktops',
        TOOLTIP_MOBILE_1ST_POSITION: 'Positionieren Sie Ihren Bot in der unteren linken Ecke des Bot -Benutzers Mobile',
        TOOLTIP_MOBILE_2ND_POSITION: 'Positionieren Sie Ihren Bot in der mittleren rechten Ecke des Bot -Benutzers Mobile',
        TOOLTIP_MOBILE_3RD_POSITION: 'Positionieren Sie Ihren Bot in der unteren rechten Ecke des Bot -Benutzers Mobile',
        TOOLTIP_WINDOW_HEIGHT: 'Größen Sie die Größe des Bots Chat -Fenster',
        TOOLTIP_BOT_ICON: 'Wählen Sie ein Symbol oder laden Sie Logo/Bild für Ihren Bot hoch',
        flowTriggerMessage: 'Geben Sie die Anzahl der Nachrichten ein, die vor dem Sammeln der Kontaktdaten wie Name, E-Mail und Telefonnummer gefragt werden sollen',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Tropfkampagne erstellen',
      youCanCreateMultipleCampaigns: 'Sie können mehrere Kampagnen erstellen',
      dripCampaignName: 'Drip -Kampagnenname',
      dripCampaignNameIsReuired: 'Der Drip -Kampagnenname ist erforderlich',
      continue: 'Weitermachen',
      manageDripCampaign: 'Tropfkampagne verwalten',
      youCanCreateMultipleCampaignsWith: 'Sie können mehrere Tropfkampagnen mit geplanten, automatisierten Nachrichten erstellen, um Antworten aus bestimmten BenutzerGruppen auszulösen.',
      createNewDripCampaign: 'Erstellen Sie eine neue Tropfkampagne',
      campaignName: 'Kampagnenname',
      appliedOn: 'Aufgetragen auf',
      segment: 'Gruppe',
      template: 'Vorlage',
      startDate: 'Startdatum',
      action: 'Aktion',
      nA: 'N / A',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      deleteDripCampaign: 'Tropfkampagnen löschen',
      wantToDeleteThisDripCampaign: 'Sind Sie sicher, dass Sie diese Tropfkampagnen löschen möchten?',
      placeholders: {
        enterDripCampaignName: 'Geben Sie den Namen des Drip -Kampagnens ein',
        searchByName: 'Suche mit Name',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Mehrere Tropfkampagnen löschen',
        deleteDripCampaign: 'Tropfkampagne löschen',
      },
      createDripCampaign: {
        dripCampaignName: 'Drip -Kampagnenname',
        appliedOn: 'Aufgetragen auf',
        segments: 'Gruppe',
        selectDate: 'Datum auswählen',
        scheduleYourTemplate: 'Planen Sie Ihre Vorlagen',
        schedule: 'Zeitplan',
        template: 'Vorlage',
        sendAfter: 'Nachfragen',
        assignVariablesToTemplate: 'Templates Variablen zuweisen',
        addHeaderMedia: 'Header Medien hinzufügen',
        selectFile: 'Datei aussuchen',
        createDripCampaign: 'Tropfkampagne erstellen',
        newSubscriber: 'Neuer Abonnent',
        minutes: 'Protokoll',
        hours: 'Std',
        days: 'Tage',
        placeholders: {
          enterDripName: 'Geben Sie den Tropfennamen ein',
          selectAppliedOn: 'Wählen Sie angewendet auf',
          selectSegments: 'Gruppe auswählen',
          searchByName: 'Suche mit Name',
          selectTemplate: 'Vorlage auswählen',
          searchTemplateByName: 'Suchvorlage nach Namen',
          enterSendAfter: 'Geben Sie Senden nach',
          selectValueForThisVariable: 'Wählen Sie den Wert für diese Variable aus',
          searchHeaderVariableByName: 'Such -Header -Variable nach Namen',
          searchBodyVariableByName: 'Suchkörpervariable nach Namen',
        },
        dateTimePicker: {
          selectStartDate: 'Wählen Sie Startdatum',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Konfigurieren Sie die allgemeinen Einstellungen Ihrer Zielseite',
      configureBotsGeneralSettings: 'Konfigurieren Sie die allgemeinen Einstellungen Ihres Bots',
      selectTheTimeZone: 'Wählen Sie die Zeitzone, die Sprache und einen kleinen Hinweis für Ihre Kunden aus, wenn die',
      isNotAvailable: ' ist nicht verfügbar',
      landingPagesBot: 'Landing Pages Bot',
      bot: 'Bot',
      waitingMessage: 'Wartenachricht',
      landingPageBotStatus: 'Zielseite Bot Status',
      chatBotStatus: 'Chatbot -Status',
      unavailabilityMessage: 'Nichtverfügbarkeitsnachricht',
      sendIncompleteResponses: 'Senden Sie unvollständige Antworten',
      language: 'Sprache',
      invalidateCache: 'Cache ungültig',
      timezone: 'Zeitzone',
      chatWindowMessageHistory: 'Chat -Fenster -Nachrichtenverlauf',
      session: 'Sitzung',
      forever: 'für immer',
      english: 'Englisch',
      spanish: 'Spanisch',
      french: 'Französisch',
      portuguese: 'Portugiesisch',
      german: 'Deutsch',
      italian: 'Italienisch',
      swedish: 'Schwedisch',
      arabic: 'Arabisch',
      malay: 'malaiisch',
      russian: 'Russisch',
      discard: 'Verwerfen',
      saveChanges: 'Änderungen speichern',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      placeholders: {
        enterYourWaitingMessageHere: 'Geben Sie hier Ihre Wartenachricht ein',
        // selectLanguage: 'Anwesend',
        searchTimeZone: 'Suchen Sie Timezone',
        // selectChatRetentionPolicy: 'Anwesend',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Passen Sie die Nachricht an, wie Sie möchten, dass sie im Chat -Fenster sofort angezeigt werden',
        TOOLTIP_CHATBOT_STATUS: 'Aktivieren /deaktivieren Sie den Bot',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Passen Sie die Nachricht an, wie Sie möchten, dass sie im Chat -Fenster angezeigt werden, wenn keiner Ihrer Agenten für Live -Chat mit dem Bot -Benutzer zur Verfügung steht',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Senden Sie Ihnen E -Mail -Benachrichtigungen auch, wenn der Bot -Benutzer die Konversation nicht beendet hat.',
        TOOLTIP_LANGUAGE: 'Wählen Sie die Sprache des Bots aus',
        TOOLTIP_INVALIDATE_CACHE: 'Klicken Sie hier, um den Cache zu löschen, wenn Sie den Bot -Chat -Fluss oder die Einstellungen bearbeiten, da Änderungen erst nach 15 Minuten widerspiegeln',
        TOOLTIP_TIME_ZONE: 'Wählen Sie die Zeitzone für Ihren Bot aus',
        TOOLTIP_CHAT_RETENTION: 'Wählen Sie aus, wenn Sie im Chat -Fenster oder nur für eine Browser -Sitzung die Chats des Bot -Benutzers für immer behalten möchten.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Passen Sie Ihr Chat -Fenster an',
      controlAndCustomizeTheChatWindow: 'Steuern und passen Sie das Chat -Fenster an, mit dem Ihre Kunden interagieren.',
      allowVisitorsToTransferChatToWhatsapp: 'Erlauben Sie den Besuchern, den Chat auf WhatsApp zu übertragen',
      allowVisitorsToRefreshChat: 'Erlauben Sie den Besuchern, den Chat zu aktualisieren',
      displayNameForTransferringChatToWhatsapp: 'Anzeigenname für die Übertragung eines Chats an WhatsApp anzeigen',
      displayNameForTransferringToLiveChat: 'Anzeigename für die Übertragung zum Live/Agent -Chat',
      displayNameForTransferringAChatToBot: 'Anzeigename für die Übertragung eines Chats an Bot',
      discard: 'Verwerfen',
      saveChanges: 'Änderungen speichern',
      allowVisitorsToTransferChatToBot: 'Erlauben Sie den Besuchern, den Chat mit Bot zu übertragen.',
      allowVisitorsToRequestForLiveChat: 'Erlauben Sie den Besuchern, Live -Chat zu beantragen.',
      indicativeTextForCustomersReply: 'Hinweise Text für die Antwort des Kunden',
      displayNameForRestartingChatBot: 'Anzeigen Sie den Namen zum Neustart des Chatbot -Gesprächs',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      placeholders: {
        enterButtonNameForWhatsapp: 'Geben Sie den Schaltflächennamen für die Übertragung zu WhatsApp ein',
        enterButtonNameForLiveChat: 'Geben Sie den Schaltflächennamen zum Übergang zum Live -Chat oder zum Agenten ein',
        enterButtonNameForBot: 'Geben Sie den Schaltflächennamen zum Übertragen zum Bot ein',
        enterTextDisplayInEmptyReplyArea: 'Geben Sie den im leeren Antwortbereich angezeigten Text ein',
        enterButtonForRestartingConversation: 'Geben Sie den Schaltflächennamen zum Neustart der Konversation ein',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Ermöglichen Sie den Besuchern der Website, den Chat auf WhatsApp Nr. des jeweiligen Agenten sind sie verbunden. Die Live -Chat -Funktion muss aktiviert sein, damit diese Funktion funktioniert.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Erlauben Sie den Bot -Benutzern, den Chat zu aktualisieren, damit sie neu unterhalten können.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Ermöglichen Sie den Bot-Benutzern, den Chat jederzeit in Gespräch mit dem Live-Chat-Agenten in Bot zu übertragen.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Erlauben Sie den Bot -Benutzern, nach Belieben einen Live -Chat mit dem Agenten anzufordern.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Wenn Sie dem Benutzer ermöglicht haben, auf WhatsApp zu übertragen, füllen Sie, wie Sie diese Option anzeigen möchten',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Wenn Sie dem Benutzer in Live -Chat übertragen haben, füllen Sie, wie Sie diese Option anzeigen möchten',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Wenn Sie dem Benutzer ermöglicht haben, auf WhatsApp zu übertragen, füllen Sie, wie Sie zu BOT zurückkehren möchten',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Füllen Sie, wie Sie den Antwortraum für Kunden anzeigen möchten',
        ifYouHaveEnabledUserToRestartChatBot: 'Wenn Sie dem Benutzer ermöglicht haben, die Chatbot -Konversation neu zu starten, füllen Sie, wie Sie diese Option anzeigen möchten',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Chat fließt',
      dripCampaignHeader: 'Tropfkampagne',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Übertragen',
      ordersHeader: 'Aufträge',
      manageHeader: 'Verwalten',
      inputHeader: 'Eingang',
      apiKeyHeader: 'API-Schlüssel',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Erstellen Sie einen Kunden auf der Boarding mit einer DRIP -Kampagne geplanter Chat -Flows.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Passen Sie die Chat -Flow -Vorlagen an und erstellen Sie mehrere Chat -Flows für verschiedene Zielgruppen.',
        TOOLTIP_WIDGET_TITLE: 'Erstellen Sie Widgets (CHAT -Schnittstellen) und setzen Sie sie in Werbe -Webseiten ein, um Besucher zum Bot zu leiten',
        TOOLTIP_BROADCAST_TITLE: 'Sprengen Sie Nachrichten oder Flüsse zu Ihren Kontakten bei einem einzigen Klick in der vorab geplanten Zeit',
        TOOLTIP_ORDERS_TITLE: 'Liste der vom Kunden aufgegebenen Bestellungen',
        TOOLTIP_MANAGE_TITLE: 'Verwalten der Bot -Funktionalität für Kontakte/Leads und Keywords',
        TOOLTIP_INPUT_TITLE: 'Erstellen Sie WhatsApp -Vorlagen, Bilder und Text als Antwortmaterial für Kontakte/Leads',
      },
      apiWrapper: {
        whatsappApiCredentials: 'WhatsApp -API -Anmeldeinformationen',
        generateYourWhatsappApiKey: 'Generieren Sie Ihren WhatsApp -API -Schlüssel, um sich in Ihre benutzerdefinierten Apps zu integrieren.',
        generateApiKey: 'Generieren Sie den API -Schlüssel',
        webhookUrl: 'Webhook URL',
        addHeader: 'Header hinzufügen',
        test: 'Prüfen',
        webhookNotSet: 'Webhook nicht gesetzt',
        webhookHeaders: 'Webhook Header',
        setWebhookUrl: 'Setzen Sie die Webhook -URL',
        placeholders: {
          yourApiKey: 'Ihr API -Schlüssel',
          enterWebhookUrl: 'Geben Sie die Webhook -URL ein',
          enterHeaderKey: 'Geben Sie die Header -Taste ein',
          enterValue: 'Wert eingeben',
        }
      },
      broadcast: {
        createBroadcast: 'Sendung erstellen',
        createMultipleBroadcasts: 'Sie können mehrere Sendungen mit mehreren geplanten Flows/Vorlagen für verschiedene KundenGruppe erstellen.',
        broadcastName: 'Rundfunkname',
        nameIsRequired: 'Name ist erforderlich',
        selectType: 'Art auswählen',
        selectYourFlowType: 'Wählen Sie Ihren Flusstyp aus',
        segment: 'Gruppe',
        selectYourSegmentType: 'Wählen Sie Ihren Gruppetyp aus',
        whenToSend: 'Wann zu senden',
        selectDate: 'Datum auswählen',
        messageTag: 'Nachrichten -Tag',
        selectMessageTag: 'Wählen Sie Message Tag',
        contentType: 'Inhaltstyp',
        nonPromotional: 'Nicht-Promotion (Nachrichten-Tags angewendet)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Tags mit jeder Meldung, die vor einer Interaktion oder nach Verzögerungsschritten von mehr als 24 Stunden mit entsprechendem Tag erhältlich ist. Nicht getagierte Nachricht erreicht den Abonnenten nicht',
        promotional: 'Werbung (Nachrichten -Tags ignoriert)',
        theseBroadcasts: 'Diese Sendungen können Werbeaktionen enthalten, aber die Zielgruppe ist auf Abonnenten beschränkt, die in den letzten 24 Stunden mit Ihrem Bot interagiert haben.',

        createNewBroadcast: 'Neue Sendung erstellen',
        sentMessages: 'Gesendeten Nachrichten',
        deliveredMessages: 'Zustellte Nachrichten',
        readMessages: 'Nachrichten lesen',
        failedMessages: 'Fehlgeschlagene Nachrichten',
        // createNewBroadcast: 'Neue Sendung erstellen',
        type: 'Typ',
        template: 'Vorlage',
        sent: 'Gesendet',
        delivered: 'Geliefert',
        read: 'Lesen',
        failed: 'Fehlgeschlagen',
        broadcastAt: 'Sendung bei',
        status: 'Status',
        shipmentUpdate: 'Versandaktualisierung',
        // template: 'Vorlage',
        flow: 'Fluss',
        resendFailedMessages: 'Fehlgeschlagene Nachrichten wiederholen',
        // sentMessages: 'Gesendeten Nachrichten',
        // deliveredMessages: 'Zustellte Nachrichten',
        goBack: 'Geh zurück',
        number: 'Nummer',
        errorCode: 'Fehlercode',
        failedAt: 'Fehlgeschlagen bei',
        badGateway: '502 Bad Gateway',
        loadMore: 'Mehr laden',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          searchBroadcastByName: 'Senden nach Namensuche suchen',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Gesamtübertragungsnachrichten gesendet',
          totalBroadcastMessagesDelivered: 'Gesamtübertragungsmeldungen geliefert',
          totalBroadcastMessagesReadByContacts: 'Gesamtübertragungsmeldungen, die von den Kontakten gelesen wurden',
          totalFailedBroadcastMessages: 'Gesamtfehlerne Broadcast -Nachrichten',
          clickToViewDetailedInformationOfFailedMessages: 'Klicken Sie hier, um festgelegte Informationen von fehlgeschlagenen Nachrichten anzuzeigen',
          clickToCreateNewBroadcast: 'Klicken Sie hier, um eine neue Sendung zu erstellen',
          templateUsedInBroadcast: 'Vorlage in der Sendung verwendet',
          numberOfBroadcastMessagesSent: 'Anzahl der gesendeten Sendungsnachrichten',
          numberOfBroadcastMessagesDelivered: 'Anzahl der ausgelieferten Broadcast -Nachrichten',
          numberOfBroadcastMessagesReadByContacts: 'Anzahl der von den Kontakten gelesenen Broadcast -Nachrichten',
          numberOfTimesBroadcastMessagesHasFailed: 'Anzahl der Messaging von Broadcast -Messaging ist gescheitert',
          contactNumberOnWhichMessageDeliveryFailed: 'Kontaktnummer, auf der die Zustellung der Nachrichten fehlgeschlagen ist',
          unDeliveredTemplate: 'Unerträgliche Vorlage',
          errorReceivedForFailedMessage: 'Fehler für fehlgeschlagene Nachricht empfangen',
          timeWhenMessageDeliveryFailed: 'Zeit, in der die Zustellung der Nachrichten fehlgeschlagen ist',
          TOOLTIP_ADD_BROADCAST_NAME: 'Geben Sie einen neuen Rundfunknamen ein',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Wählen Sie, ob Sie einen Fluss oder eine Nachricht übertragen.',
          TOOLTIP_BROADCAST_FLOW: 'Wählen Sie den Chat -Fluss aus, den Sie übertragen möchten.',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Wählen Sie das KontaktGruppe aus, auf das Sie ausstrahlen möchten',
          TOOLTIP_BROADCAST_NAME: 'Name der Sendung',
          TOOLTIP_BROADCAST_TYPE: 'Flow oder Meldung für die Sendung ausgewählt',
          TOOLTIP_BROADCAST_SEGMENT: 'Gruppe, für die die Sendung erstellt wird',
          TOOLTIP_BROADCAST_AT: 'Datum und Uhrzeit für die Sendung geplant',
          TOOLTIP_BROADCAST_STATUS: 'Sendungsstatus als geplant, derzeit ausgeführt oder abgeschlossen',
        }
      },
      drip: {
        createDripCampaign: 'Tropfkampagne erstellen',
        youCanCreateMultipleCampaigns: 'Sie können mehrere Kampagnen erstellen',
        dripCampaignName: 'Drip -Kampagnenname',
        dripCampaignNameIsRequired: 'Der Drip -Kampagnenname ist erforderlich',
        // createDripCampaign: 'Tropfkampagne erstellen',
        youCanCreateMultipleDripCampaigns: 'Sie können mehrere Tropfkampagnen mit geplanten, automatisierten Nachrichten erstellen, um Antworten aus bestimmten BenutzerGruppen auszulösen.',
        campaignName: 'Kampagnenname',
        campaignNameIsRequired: 'Kampagnenname ist erforderlich',
        after: 'Nach',
        thisMessageWillBeSendAfter: 'Diese Nachricht wird nach dem Senden',
        saveAndUpdate: 'Speichern und aktualisieren',
        schedule: ' Zeitplan',
        select: 'Wählen',
        messages: 'Mitteilungen',
        cancel: 'Stornieren',
        createCampaign: 'Kampagne erstellen',
        updateCampaign: 'Aktualisierungskampagne',
        createNewCampaign: 'Neue Kampagne erstellen',
        // campaignName: 'Kampagnenname',
        messagesSent: 'Nachrichten gesendet',
        createdAt: 'Hergestellt in',
        updatedAt: 'Aktualisiert am',
        action: 'Aktion',
        placeholders: {
          nameYourDripCampaign: 'Nennen Sie Ihre Tropfkampagne',
          enterCampaignName: 'Geben Sie den Kampagnennamen ein',
          searchCampaignByName: 'Suchkampagne mit Namen',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Klicken Sie hier, um eine neue Kampagne hinzuzufügen',
          TOOLTIP_SCHEDULE: 'Planen Sie, wann und welcher Chat in dieser Kampagne zuerst sendet.',
          TOOLTIP_AFTER_DAYS: 'Planen Sie, wann der erste Fluss der Kampagne in Minuten, Stunden oder Tagen gesendet werden soll.',
          TOOLTIP_ADD_MESSAGES: 'Klicken Sie hier, um der Kampagne einen neuen Fluss hinzuzufügen',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Geben Sie einen neuen Kampagnennamen ein',
          TOOLTIP_CAMPAIGN_NAME: 'Name der Kampagne',
          TOOLTIP_NO_MESSAGES_SENT: 'Anzahl der von der Kampagne gesendeten Nachrichten',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Datum und Uhrzeit der Schaffung der Kampagne',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Datum und Uhrzeit, als die Kampagne zuletzt geändert wurde',
          TOOLTIP_CAMPAIGN_ACTION: 'Bearbeiten oder löschen Sie die Kampagne',
          TOOLTIP_UPDATE_CAMPAIGN: 'Klicken Sie hier, um die Änderungen in der Kampagneneinrichtung zu speichern',
        }
      },
      flows: {
        chatFlows: 'Chat fließt',
        createChatFlow: 'Chat -Fluss erstellen',
        youCanCreateMultipleConversationsFlow: 'Sie können mehrere Konversationen über mehrere Kanäle für A/B -Tests und -übertragungen erstellen.',
        flowName: 'Flussname',
        nameIsRequired: 'Name ist erforderlich',
        cancel: 'Stornieren',
        createFlow: 'Fluss erstellen',
        createNewFlow: 'Neuen Fluss erstellen',
        // flowName: 'Flussname',
        ofMessages: 'von Nachrichten',
        triggered: 'Ausgelöst',
        stepsFinsished: 'Schritte abgeschlossen',
        finished: 'Fertig',
        createdOn: 'Erstellt am',
        lastModified: 'Zuletzt bearbeitet',
        defaultFlow: 'Standardfluss',
        action: 'Aktion',
        flowAnalytics: 'Flow Analytics',
        placeholders: {
          enterFlowName: 'Geben Sie den Flussnamen ein',
          searchFlowByName: 'Suchfluss nach Namen',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'Anzahl wird der Chat -Fluss ausgelöst',
          numberOfCompletedChatFlowStepsWhenTriggered: 'Anzahl der abgeschlossenen Chat -Flussschritte, wenn es ausgelöst wird',
          numberOfTimesUsersCompletedChatFlowSteps: 'Anzahl der Zeiten haben Benutzer alle Chat -Flussschritte ausgeschlossen',
          dateAndTimeOfCreationOfChatFlow: 'Datum und Uhrzeit der Erstellung des Chat -Flusses',
          dateAndTimeWhenChatFlowLastModified: 'Datum und Uhrzeit, als der Chat -Fluss zuletzt geändert wurde',
          TOOLTIP_CREATE_FLOW: 'Klicken Sie hier, um einen neuen Chat -Fluss zu erstellen',
          TOOLTIP_FLOW_NAME: 'Geben Sie den Chat -Flow -Namen ein',
          TOOLTIP_FLOW_TABLE_NAME: 'Name des Chat -Flusses',
          TOOLTIP_NO_OF_MESSAGES: 'Anzahl der Nachrichtenkomponenten im Chat -Fluss',
          TOOLTIP_DEFAULT_FLOW: 'Verwenden Sie diesen Umschalter, um einen Chat -Fluss als Standard festzulegen, der allen neuen Benutzern zugestellt wird.',
          TOOLTIP_ACTION: 'Bearbeiten oder löschen Sie den Chat -Fluss',
        }
      },
      manage: {
        leads: 'Führt',
        manageBotsFunctionalityForContacts: 'Verwalten Sie die Funktionalität von Bot für die Kontakte/Leads',
        whenToSendLeadInfo: 'Wann senden Sie Lead -Informationen',
        smartFlow: 'Smart Flow',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Willkommensnachricht, wenn keine Keyword -Suche erfüllt ist',
        selectTemplates: 'Wählen Sie Vorlagen aus',
        selectFlows: 'Wählen Sie Flows',
        selectText: 'Text auswählen',
        selectImages: 'Wählen Sie Bilder aus',
        whenItAlmostReaches24HoursSinceMessage: 'Wenn es seit der letzten Nachricht fast 24 Stunden erreicht',
        // selectTemplates: 'Wählen Sie Vorlagen aus',
        updateChanges: 'Änderungen aktualisieren',
        manageBotsFunctionalityAccordingToKeywords: 'Verwalten Sie die Funktionalität von BOT gemäß den Schlüsselwörtern,',
        addKeywordAction: 'Keyword -Aktion hinzufügen',
        keywords: 'Schlüsselwörter',
        matchingMethod: 'Matching -Methode',
        replyMaterial: 'Antwortmaterial',
        action: 'Aktion',
        select: 'Wählen',
        placeholders: {
          keyword: 'Stichwort',
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Schlagen Sie vor, wenn Bot sofort oder nach 5 Minuten oder am Ende des Flusses Kontakt/Lead' +
            ' -Informationen an die Integration CRM senden soll',
          TOOLTIP_LEAD_SMART_FLOW: 'Wählen Sie einen Standardfluss aus, um es an alle Bot -Benutzer oder in Smart Flow zu senden ' +
            '(bestimmt durch die Schlüsselwörter, die vom BOT -Benutzer eingegeben werden).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Wählen Sie das Antwortmaterial (Fluss, Vorlage, Text, Bild) zum Senden, wenn Bot kein Schlüsselwort' +
            ' übereinstimmt, die von der Antwort des Bot -Benutzers übereinstimmen.',
          TOOLTIP_IT_ALMOST_REACHED: 'Wählen Sie das Antwortmaterial aus, um kurz vor 24 Stunden Last Messaging an Kontakte/Leads zu' +
            ' senden',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Verwalten Sie die Funktionalität von BOT gemäß den Schlüsselwörtern,',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Wählen Sie Bot`s Antwort als Vorlage, Fluss, Text oder Bild gegen bestimmte Schlüsselwörtereingaben',
          TOOLTIP_ADD_KEYWORD: 'Keywords hinzufügen',
          TOOLTIP_MATCHING_METHOD: 'Fügen Sie die Schlüsselwort -Übereinstimmung als genau oder enthält hinzu',
          TOOLTIP_REPLY_MATERIAL: 'Wählen Sie Vorlage, Fluss, Text oder Bild aus, um für das vorgeschlagene Schlüsselwort zurückzufordern',
          TOOLTIP_KEYWORD_ACTIONS: 'Löschen Sie die Schlüsselwortaktion',
        },
        socialAlerts: {
          alerts: 'Warnungen',
          specifyHowYourBotWillNotify: 'Geben Sie an, wie Ihr Bot Sie über neue Leads informiert.',
        }
      },
      orders: {
        manageCustomersOrder: 'Kundenbestellungen verwalten',
        checkAndManageAllOfYourCustomerOrders: 'Überprüfen und verwalten Sie alle Ihre Kundenbestellungen und Updates.',
        selectShipmentTemplate: 'Wählen Sie die Versandvorlage',
        send: 'Schicken',
        export: 'Export',
        phoneNumber: 'Telefonnummer',
        numberOfItems: 'Anzahl der Teile',
        orderValue: 'Bestellwert',
        orderDate: 'Auftragsdatum',
        status: 'Status',
        chat: 'Plaudern',
        item: 'Artikel',
        quantity: 'Menge',
        amount: 'Menge',
        unitPrice: 'Einzelpreis',
        placeholders: {
          searchOrdersByPhoneNumber: 'Suchbestellungen nach Telefonnummern',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Exportieren Sie Daten in der CSV -Datei an',
          TOOLTIP_ORDERS_PHONE_NO: 'Telefonnummer des Kunden, der die Bestellung aufteilte',
          TOOLTIP_NO_OF_ITEMS: 'Anzahl der bestellten Artikel',
          TOOLTIP_ORDER_VALUE: 'Wert der Reihenfolge',
          TOOLTIP_ORDER_DATE: 'Die Bestellung wurde an diesem Datum aufgegeben.',
          TOOLTIP_ORDER_STATUS: 'Status der Bestellung, wie bestätigt, ausstehend, abgelehnt. oder versendet',
          TOOLTIP_ORDER_CHAT: 'Alle früheren Chat -Gespräche zu dieser Bestellung',

        }
      },
      widgets: {
        installWidgetHeader: 'Installieren Sie Widget',
        createWidgetHeader: 'Widget erstellen',
        copyTheCodeAndPasteItWhereYouWant: 'Kopieren Sie den Code und fügen Sie ihn ein, wo Sie in Ihrer HTML, Ihrer Website usw. benötigen.',
        youCanCreateMultipleWidgets: 'Sie können mehrere Widgets erstellen, um Ihrem Bot ein anderes Erscheinungsbild auf verschiedenen Webseiten zu verleihen.',
        installWidget: {
          javascript: 'JavaScript',
          install: 'Installieren',
          onYourWebsite: ' Auf deiner Webseite',
          copyCode: 'Code kopieren',
          copyAndPaste: 'Kopieren und Einfügen',
          copyThisCodeSnippet: 'Kopieren Sie diesen Code -Snippet und fügen Sie ihn in die HTML jeder Webseite ein, auf der Sie möchten',
          seeGuide: 'Siehe Leitfaden',
        },
        widgetList: {
          widgetName: 'Widget Name',
          nameIsRequired: 'Name ist erforderlich',
          cancel: 'Stornieren',
          createWidget: 'Widget erstellen',
          icon: 'Symbol',
          name: 'Name',
          createdAt: 'Hergestellt in',
          action: 'Aktion',
          placeholders: {
            enterWidgetName: 'Geben Sie den Widget -Namen ein',
            searchWidgetByName: 'Suchen Sie Widget nach Namen',
          },
          appTooltip: {
            widgetIcon: 'Widget -Symbol',
            nameOfWidget: 'Name des Widgets',
            dateAndTimeOfCreationOfWidget: 'Datum und Uhrzeit der Schaffung des Widgets',
            editOrDeleteWidget: 'Bearbeiten oder löschen Sie das Widget',
          }
        },
        widgetTemplates: {
          widgetName: 'Widget Name',
          nameIsRequired: 'Name ist erforderlich',
          chooseTypeOfWidget: 'Wählen Sie Widget -Art des Widgets',
          bar: 'Bar',
          slideIn: 'Reinschieben',
          pageTakeOver: 'Seite Übernahme',
          button: 'Taste',
          facebookModal: 'Facebook Modal',
          comment: 'Kommentar',
          direct: 'Direkte',
          whatsappIcon: 'WhatsApp -Symbol',
          refUrl: 'Ref URL',
          qrCode: 'QR-Code',
          // refUrl: 'Ref URL',
          backgroundColor: 'Hintergrundfarbe',
          descriptionColor: 'Beschreibung Farbe',
          headlineColor: 'Schlagzeilenfarbe',
          buttonBackgroundColor: 'Button -Hintergrundfarbe',
          contactNumber: 'Kontakt Nummer',
          entryMessage: 'Eingabenachricht',
          buttonText: 'Schaltflächentext',
          selectButtonText: 'Schaltfläche Text auswählen',
          buttonSize: 'Knopfgröße',
          selectOne: 'Wähle eins',
          placement: 'Platzierung',
          rightBottom: 'Rechts unten',
          optInFlow: 'Einlösen Fluss',
          selectFlow: 'Wählen Sie Flow',
          addSubscriberToSequence: 'Fügen Sie Abonnent in die Sequenz hinzu',
          selectDrip: 'Wählen Sie Drip',
          whenDoesItDisplay: 'Wann wird es angezeigt (Sekunden)',
          showWidgetToSameUserAgainAfter: 'Zeigen Sie demselben Benutzer danach erneut Widget',
          ifManuallyClosedByUser: 'Wenn man von dem Benutzer manuell geschlossen wird, zeigen Sie danach',
          // comment: 'Kommentar',
          specificPosts: 'Spezifische Beiträge',
          allPosts: 'Alle Artikel',
          // entryMessage: 'Eingabenachricht',
          postMessage: 'POST-Meldung',
          postDate: 'Nach Datum',
          status: 'Status',
          action: 'Aktion',
          discard: 'Verwerfen',
          saveChanges: 'Änderungen speichern',
          nextStep: 'Nächster Schritt',
          widgetDescription: 'Widget Beschreibung',
          integrateTheBarWidget: 'Integrieren Sie das BAR -Widget in den Körper Ihrer Website und erhöhen Sie Ihren Seitenabonnenten zusammen mit dem Senden und dem benutzerdefinierten Bot -Nachrichten fließen.',
          hereIsYourWidgetHeadline: 'Hier ist Ihre Widget -Überschrift. Klicken Sie hier, um sie zu ändern.',
          sendUsMessage: 'Senden Sie uns eine Nachricht',
          weAlsoPutDefaultTextHere: 'Wir haben hier auch Standardtext eingesetzt. Machen Sie sicher, dass Sie ihn in eine wertvolle Nachricht verwandeln',
          selectYourPagePost: 'Wählen Sie Ihren Seitenbeitrag aus',
          nA: 'N / A',
          // entryMessage: 'Eingabenachricht',
          saveRule: 'Regel speichern',
          placeholders: {
            enterWidgetName: 'Geben Sie den Widget -Namen ein',
            enterNumber: 'Nummer eingeben',
            enterMessageHere: 'Geben Sie hier eine Nachricht ein',
            enterTimeInSeconds: 'Geben Sie die Zeit in Sekunden ein',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'Passen Sie die Triggereinstellungen des Bots an',
      makeYourBotsTimingPerfect: 'Machen Sie das Timing Ihres Bots einfach perfekt.',
      onDesktopTriggerTime: 'Auslöserzeit auf dem Desktop (Sekunden)',
      onMobileTriggerTime: 'Auslösungszeit auf mobiler (Sekunden)',
      chatBubble: 'Chat Blase Automatische Öffnung',
      seconds: 'Sekunden',
      discard: 'Verwerfen',
      saveChanges: 'Änderungen speichern',
      autoTriggerOnOrOff: 'Autoauslöser: Ein/Aus',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      alwaysOpenAfter: 'Immer nach oben angegebenen Auslöser geöffnet',
      automaticallyAfterSelectedNumberOfTime: 'Automatisch nach ausgewählter Zeitspanne',
      neverAutomaticallyOpenBot: 'Öffnen Sie den Bot niemals automatisch automatisch',
      placeholders: {
        enterTimeInSeconds: 'Geben Sie die Zeit in Sekunden ein',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Wartezeit in Sekunden, auf den Desktop -Geräten des Besuchers, bevor Ihr Bot automatisch auftaucht',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Wartezeit in Sekunden, auf dem Handy des Besuchers, bevor Ihr Bot automatisch auftaucht',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Wenn Besucher das Chat-Fenster schließt',
        customerWaitingTimeToOpenBubble: 'Wartezeit für Kunden, um Chat -Blasen zu öffnen',
        wantToDisableAutoTriggering: 'Klicken Sie auf, wenn Sie die automatische Auslösen von Bot auf Webseiten deaktivieren möchten',
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'Passen Sie die Benutzereinstellungen Ihres Bots an',
      decideWhoCanAccessYourBot: 'Entscheiden Sie, wer auf Ihren Bot zugreifen kann',
      deviceSupport: 'Geräteunterstützung',
      blockIpAddresses: 'Block IP -Adressen',
      hideChatBotOnSpecificPages: 'Chatbot auf bestimmten Seiten verstecken',
      selectCountriesToEnableBot: 'Wählen Sie Länder aus, um Bot zu aktivieren',
      discard: 'Verwerfen',
      saveChanges: 'Änderungen speichern',
      seeHowToUse: 'Sehen Sie, wie man benutzt',
      allDevices: 'Alle Geräte',
      mobileOnly: 'Nur mobil',
      desktopOnly: 'Nur Desktop',
      placeholders: {
        enterIpAddress: 'Geben Sie die IP -Adresse (IPv4 oder IPv6) ein und drücken Sie `Enter`',
        enterUrl: 'Geben Sie die URL von Seiten ein und drücken Sie `ENGERN`',
        searchCountry: 'Suchland',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Wählen Sie auf welchen Geräten Ihr Bot angezeigt werden sollte',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Geben Sie IP -Adressen ein, bei denen Sie nicht wünschen, dass Ihr Bot erscheinen soll',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Geben Sie die URL Ihrer Webseiten ein, auf denen Sie nicht möchten, dass Ihr Bot erscheinen',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Wählen Sie Positionen aus, aus denen Benutzer auf Ihren Bot zugreifen möchten',
      }
    },
    facebook: {
      facebookBotSettings: 'Facebook -Bot -Einstellungen',
      chatFlowsHeader: 'Chat fließt',
      dripCampaignHeader: 'Tropfkampagne',
      broadcastHeader: 'Übertragen',
      widgetsHeader: 'Widgets',
      autoReplyHeader: 'Automatische-Antwort',
      generalHeader: 'Allgemein',
      facebookAutomation: 'Facebook -Automatisierung',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Passen Sie die Chat -Flow -Vorlagen an und erstellen Sie mehrere Chat -Flows für verschiedene Zielgruppen.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Erstellen Sie einen Kunden auf der Boarding mit einer DRIP -Kampagne geplanter Chat -Flows.',
      },
      broadcasts: {
        createBroadcast: 'Sendung erstellen',
        youCanCreateMultipleBroadcasts: 'Sie können mehrere Sendungen erstellen',
        broadcastName: 'Rundfunkname',
        broadcastNameIsRequired: 'Sendungsname ist erforderlich',
        continue: 'Weitermachen',
        manageBroadcasts: 'Sendungen verwalten',
        youCanCreateMultipleBroadcastsWith: 'Sie können mehrere Sendungen mit mehreren geplanten Vorlagen für verschiedene KundenGruppe erstellen.',
        createNewBroadcast: 'Neue Sendung erstellen',
        name: 'Name',
        segment: 'Gruppe',
        template: 'Vorlage',
        contacts: 'Kontakte',
        send: 'Schicken',
        failed: 'Fehlgeschlagen',
        broadcastAt: 'Sendung bei',
        status: 'Status',
        actions: 'Aktionen',
        nA: 'N / A',
        deleteBroadcast: 'Sendung löschen',
        wantToDeleteThisBroadcast: 'Sind Sie sicher, dass Sie diese Sendung löschen möchten?',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          searchHere: 'Suche hier',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Mehrere Sendungen löschen',
          deleteBroadcast: 'Sendung löschen',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Rundfunkname',
        broadcastNameIsRequired: 'Sendungsname ist erforderlich',
        flow: 'Fluss',
        segments: 'Gruppe',
        whenToSend: 'Wann zu senden',
        selectDate: 'Datum auswählen',
        selectTimeSlot: 'Wählen Sie Zeitschlitz',
        createBroadcast: 'Sendung erstellen',
        cancel: 'Stornieren',
        now: 'Jetzt',
        later: 'Später',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          selectFlow: 'Wählen Sie Flow',
          searchFlowByName: 'Suchfluss nach Namen',
          selectSegments: 'Gruppe auswählen',
          searchSegmentByName: 'Gruppe nach Namen durchsuchen',
          selectTimeSlot: 'Wählen Sie Zeitschlitz',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Wählen Sie Startdatum',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Tropfkampagne erstellen',
        youCanCreateMultipleCampaign: 'Sie können mehrere Kampagnen erstellen',
        dripCampaignName: 'Drip -Kampagnenname',
        dripCampaignNameIsRequired: 'Der Drip -Kampagnenname ist erforderlich',
        continue: 'Weitermachen',
        manageDripCampaign: 'Tropfkampagne verwalten',
        youCanCreateMutlipleDripCampaign: 'Sie können mehrere Tropfkampagnen mit geplanten, automatisierten Nachrichten erstellen, um Antworten aus bestimmten BenutzerGruppen auszulösen.',
        createNewDripCampaign: 'Erstellen Sie eine neue Tropfkampagne',
        campaignName: 'Kampagnenname',
        appliedOn: 'Aufgetragen auf',
        segment: 'Gruppe',
        flow: 'Fluss',
        startDate: 'Startdatum',
        action: 'Aktion',
        nA: 'N / A',
        deleteDripCampaign: 'Tropfkampagnen löschen',
        wantToDeleteThisDripCampaign: 'Sind Sie sicher, dass Sie diese Tropfkampagnen löschen möchten?',
        placeholders: {
          enterDripCampaignName: 'Geben Sie den Namen des Drip -Kampagnens ein',
          searchByName: 'Suche mit Name',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Mehrere Tropfkampagnen löschen',
          deleteDripCampaign: 'Tropfkampagne löschen',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Drip -Kampagnenname',
          appliedOn: 'Aufgetragen auf',
          segments: 'Gruppe',
          selectDate: 'Datum auswählen',
          scheduleYourFlows: 'Planen Sie Ihre Ströme',
          schedule: 'Zeitplan',
          flow: 'Fluss',
          sendAfter: 'Nachfragen',
          cancel: 'Stornieren',
          newSubscriber: 'Neuer Abonnent',
          days: 'Tage',
          minutes: 'Protokoll',
          hours: 'Std',
          createDripCampaign: 'Tropfkampagne erstellen',
          placeholders: {
            enterDripName: 'Geben Sie den Tropfennamen ein',
            selectAppliedOn: 'Wählen Sie angewendet auf',
            selectSegment: 'Wählen Sie Gruppe',
            searchByName: 'Suche mit Name',
            selectTemplate: 'Vorlage auswählen',
            searchFlowByName: 'Suchfluss nach Namen',
            enterSendAfter: 'Geben Sie Senden nach',
          },
          dateTimePicker: {
            selectStartDate: 'Wählen Sie Startdatum',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'Chat -ID',
        errorCode: 'Fehlercode',
        errorMessage: 'Fehlermeldung',
        nA: 'N / A',

      },
      flows: {
        chatFlows: 'Chat fließt',
        createChatFlow: 'Chat -Fluss erstellen',
        youCanCreateMultipleChatFlows: 'Sie können mehrere Konversationen über mehrere Kanäle für A/B -Tests und -übertragungen erstellen.',
        flowName: 'Flussname',
        nameIsRequired: 'Name ist erforderlich',
        cancel: 'Stornieren',
        createFlow: 'Fluss erstellen',
        importFlow: 'Importfluss',
        createNewFlow: 'Neuen Fluss erstellen',
        ofMessages: 'von Nachrichten',
        createdOn: 'Erstellt am',
        lastModified: 'Zuletzt bearbeitet',
        defaultFlow: 'Standardfluss',
        action: 'Aktion',
        exportStatus: 'Exportstatus',
        flowExport: 'Flow-Export',
        download: 'Herunterladen',
        // importFlow: 'Importfluss',
        beforeYouStartUploadPleaseMakeSure: 'Bevor Sie mit dem Upload beginnen, stellen Sie bitte sicher',
        point1: '1. Das Upload -Grenzwert der maximalen Dateigröße beträgt 1 MB',
        point2: '2. Das Dateiformat sollte in JSON vorliegen',
        selectFile: 'Datei aussuchen',
        inProgress: 'im Gange',
        available: 'Verfügbar',
        initiated: 'Initiiert',
        placeholders: {
          enterFlowName: 'Geben Sie den Flussnamen ein',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Flow für Facebook Bot importieren',
          dateAndTimeOfCreationOfChatFlow: 'Datum und Uhrzeit der Erstellung des Chat -Flusses',
          dateAndTimeWhenChatFlowLastModified: 'Datum und Uhrzeit, als der Chat -Fluss zuletzt geändert wurde',
          TOOLTIP_CREATE_FLOW: 'Klicken Sie hier, um einen neuen Chat -Fluss zu erstellen',
          TOOLTIP_FLOW_NAME: 'Geben Sie den Chat -Flow -Namen ein',
          TOOLTIP_FLOW_TABLE_NAME: 'Name des Chat -Flusses',
          TOOLTIP_NO_OF_MESSAGES: 'Anzahl der Nachrichtenkomponenten im Chat -Fluss',
          TOOLTIP_DEFAULT_FLOW: 'Verwenden Sie diesen Umschalter, um einen Chat -Fluss als Standard festzulegen, der allen neuen Benutzern zugestellt wird.',
          TOOLTIP_ACTION: 'Bearbeiten oder löschen Sie den Chat -Fluss',
        },
        noDataFound: {
          flows: 'Fließt',
          noFlowsAddedYet: 'Noch keine Ströme hinzugefügt!',
        },
        modal: {
          deleteFlow: 'Fluss löschen',
          wantToDeleteThisFlow: 'Sind Sie sicher, dass Sie diesen Fluss löschen möchten?',
        }
      },
      widgets: {
        manageWidgets: 'Widgets verwalten',
        widgetsAreRoutingTools: 'Widgets sind die Routing -Tools, um die Verwendung von Bot zu erhöhen',
        needHelp: 'Brauchen Sie Hilfe?',
        wantToMakeChanges: 'Möchten Sie Änderungen vornehmen?',
        editIcon: 'Ikone bearbeiten',
        discard: 'Verwerfen',
        updateWidget: 'Aktualisieren Sie Widget',
        installChatBotIconOnYourWebsite: 'Installieren Sie das Chatbot -Symbol auf Ihrer Website',
        shareWhereEverYouWantToRedirectUsersTo: 'Teilen Sie, wo immer Sie möchten, und leiten Sie Benutzer in Ihrem Facebook -Bot in Single -Klick um und leiten Sie sie weiter.',
        copyAndAddInYourWebsiteHeaderFile: 'Kopieren und fügen Sie in Ihrer Website -Header -Datei zu und fügen Sie hinzu',
        copyLinkAndStartSharing: 'Link kopieren und mit der Freigabe beginnen!',
        downloadQrCodeAndStartSharing: 'Laden Sie QR Code herunter und starten Sie die Freigabe!',
        addCodeAndDeployNewCodeAndDone: 'Fügen Sie den Code hinzu, geben Sie neuen Code bereit und erledigt!',
        wantToLearnHowToUseIt: 'Möchten Sie lernen, wie man es benutzt?',
        copyChatBotCode: 'Kopieren Sie den Chatbot -Code',
        copyFacebookLink: 'Facebook -Link kopieren',
        downloadQrCode: 'Laden Sie den QR -Code herunter',
        link: 'Verknüpfung',
        chatBotIcon: 'Chatbot -Symbol',
        qrcode: 'QR-Code',
        small: 'Klein',
        medium: 'Mittel',
        large: 'Groß',
        leftMiddle: 'Links',
        rightMiddle: 'Rechts aushindig',
        leftBottom: 'Links unten',
        rightBottom: 'Rechts unten',
        bpDropDown: {
          selectIcons: 'Wählen Sie Symbole',
          iconSize: 'Symbolgröße',
          iconPlacement: 'Icon -Platzierung',
        }
      },
      autoReply: {
        autoReplyTitle: 'Auto-Antwort',
        configureYour: 'Konfigurieren Sie Ihre ',
        message: ' Nachricht',
        customizeAutoReplyMessagesForYourPostsComments: 'Passen Sie Auto-Antwort-Nachrichten für die Kommentare zu Ihrem Beitrag an',
        wouldYouLikeToApply: 'Möchten Sie die ',
        autoreply: 'Auto-Antwort',
        onAllPost: ' auf alle Beiträge anwenden?',
        configureYourMessageForEachPost: 'Konfigurieren Sie Ihre Nachricht für jeden Beitrag',
        configureFlowToTriggerAfterOnReply: 'Konfigurieren Sie den Ablauf, um nach der Antwort ausgelöst zu werden',
        whenUserLeavesACommentSendMessageAfter: 'Wenn ein Benutzer einen Kommentar hinterlässt, senden Sie die Nachricht danach',
        triggerFlowOnReply: 'Ablauf bei Antwort auslösen',
        add: 'Hinzufügen',
        update: 'Aktualisieren',
        cancel: 'Abbrechen',
        discard: 'Verwerfen',
        bpDropDown: {
          selectPost: 'Beitrag auswählen',
          triggerFlowOnReply: 'Ablauf bei Antwort auslösen',
          immendiately: 'Sofort',
          minutes: 'Protokoll'
        },
        bpTable: {
          message: 'Nachricht',
          selectedPost: 'Ausgewählter Beitrag',
          triggerFlow: 'Flow auslösen',
          actions: 'Aktionen'
        },
        placeholder: {
          configureYourMessage: 'Konfigurieren Sie Ihre Nachricht'
        },
        deleteModel: {
          title: 'Auto-Antwort für Beitrag löschen',
          description: 'Sind Sie sicher, dass Sie diese Auto-Antwort für den Beitrag löschen möchten?'
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Sitzungszeitüberschreitung',
        sendInactivityMessage: 'Inaktivitätsnachricht senden',
        enterSessionTimeOutWindowInHours: 'Geben Sie das Zeitfenster für die Sitzungszeitüberschreitung in Stunden ein',
        sessionTimeOutInHoursTooltip: `Geben Sie die Dauer ein, nach der die Chat-Sitzung aufgrund von Inaktivität automatisch abläuft. Der Chatverlauf wird neu gestartet, wenn der Benutzer nach Ablauf der Sitzungszeit eine Nachricht sendet.`,
        restartChatFlowTriggers: 'Trigger zum Neustart des Chatverlaufs',
        enterKeyWordsToRestartChatAndhitEnter: 'Geben Sie Schlüsselwörter ein, um den Chat neu zu starten, und drücken Sie Enter',
        restartChatFlowTriggersTooltip: `Geben Sie hier Schlüsselwörter an, die, wenn sie vom Benutzer eingegeben und gesendet werden, einen Neustart des Chatverlaufs auslösen. Dies ermöglicht es Benutzern, das Gespräch zu jedem Zeitpunkt neu zu starten.
         Drücken Sie nach dem Hinzufügen jedes Schlüsselworts Enter, um mehrere Schlüsselwörter hinzuzufügen.`,
        liveChatTriggers: 'Live-Chat-Auslöser',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Geben Sie Schlüsselwörter ein, um den Live-Chat auszulösen, und drücken Sie Enter',
        liveChatTriggersTooltip: `Definieren Sie Schlüsselwörter, die, wenn sie vom Benutzer eingegeben und gesendet werden, den Übergang von automatisiertem Chat zu Live-Chat initiieren. Dies ist nützlich, wenn Benutzer Unterstützung von einem Live-Agenten benötigen.
         Drücken Sie nach dem Hinzufügen jedes Schlüsselworts Enter, um mehrere Schlüsselwörter hinzuzufügen.`,
        messageAfterAutomatedFlowCompleted: 'Nachricht nach Abschluss des automatisierten Ablaufs',
        enterYourMessage: 'Geben Sie Ihre Nachricht ein',
        messageAfterAutomatedFlowCompletedTooltip: `Geben Sie eine Nachricht ein, die dem Benutzer angezeigt wird, nachdem er den automatisierten Chatverlauf abgeschlossen hat, aber die Sitzung nicht abgelaufen ist.`,
        restart: 'Neustart',
        liveChat: 'Live-Chat',
        lineOneForDefaultMessage: 'Wenn Sie weitere Fragen haben oder Unterstützung benötigen, hier ist, was Sie als Nächstes tun können:',
        to: 'An',
        restartTheChat: 'den Chat neu starten',
        simpleTypeRestartAndHitEnter: `geben Sie einfach 'Neustart' ein und drücken Sie Enter. Dies bringt Sie zurück zum Anfang unseres Gesprächsverlaufs.`,
        lineThreeForDefaultMessagePartOne: `Wenn Sie mit einem`,
        liveAgent: `Live-Agenten`,
        lineThreeForDefaultMessageLastPart: `sprechen möchten, geben Sie 'Live-Chat' ein und drücken Sie Enter. Unser Team steht Ihnen in Echtzeit zur Verfügung.`,
        saveChanges: 'Änderungen speichern',
        discard: 'Verwerfen',
        configureBotsGeneralSettings: 'Konfigurieren Sie die allgemeinen Einstellungen Ihres Bots',
        minutes: 'Protokoll',
        hours: 'Std',
      }
    },
    instagram: {
      instagramBotSettings: 'Instagram Bot -Einstellungen',
      chatFlowsHeader: 'Chat fließt',
      broadcastHeader: 'Übertragen',
      dripCampaignHeader: 'Tropfkampagne',
      widgetsHeader: 'Widgets',
      alertsHeader: 'Warnungen',
      alerts: {
        configureYourBotsSettings: 'Konfigurieren Sie die Warneinstellungen Ihres Bots',
        specifyHowYourBotNotifyYou: 'Geben Sie an, wie Ihr Bot Sie über neue Leads informiert.',
        subject: 'Thema',
        emailNotification: 'Email Benachrichtigung',
        emailAddresses: 'E-mailadressen',
        leadRevisitNotifications: 'Lead -Revision Benachrichtigungen',
        whenToSendLeadInfo: 'Wann senden Sie Lead -Informationen',
        discard: 'Verwerfen',
        saveChanges: 'Änderungen speichern',
        placeholders: {
          enterSubjectForYourEmail: 'Geben Sie Betreff für Ihre E -Mail ein',
          typeYourEmailAddressAndHitEnter: 'Geben Sie Ihre E -Mail -Adresse ein und drücken Sie die Eingabetaste',
        }
      },
      broadcasts: {
        createBroadcast: 'Sendung erstellen',
        youCanCreateMultipleBroadcasts: 'Sie können mehrere Sendungen erstellen',
        broadcastName: 'Rundfunkname',
        broadcastNameIsRequired: 'Sendungsname ist erforderlich',
        continue: 'Weitermachen',
        manageBroadcasts: 'Sendungen verwalten',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Sie können mehrere Sendungen mit mehreren geplanten Vorlagen für verschiedene KundenGruppe erstellen',
        createNewBroadcast: 'Neue Sendung erstellen',
        segment: 'Gruppe',
        template: 'Vorlage',
        contacts: 'Kontakte',
        send: 'Schicken',
        failed: 'Fehlgeschlagen',
        broadcastAt: 'Sendung bei',
        status: 'Status',
        actions: 'Aktionen',
        nA: 'N / A',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          searchHere: 'Suche hier',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Mehrere Sendungen löschen',
          deleteBroadcast: 'Sendung löschen',
        }
      },
      createBroadcast: {
        broadcastName: 'Rundfunkname',
        broadcastNameIsRequired: 'Sendungsname ist erforderlich',
        flow: 'Fluss',
        segments: 'Gruppe',
        whenToSend: 'Wann zu senden',
        selectDate: 'Datum auswählen',
        selectTimeSlot: 'Wählen Sie Zeitschlitz',
        cancel: 'Stornieren',
        createBroadcast: 'Sendung erstellen',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          selectFlow: 'Wählen Sie Flow',
          searchFlowByName: 'Suchfluss nach Namen',
          selectSegments: 'Gruppe auswählen',
          searchSegmentByName: 'Gruppe nach Namen durchsuchen',
          selectTimeSlot: 'Wählen Sie Zeitschlitz',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Wählen Sie Startdatum',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Tropfkampagne erstellen',
        youCanCreateMultipleCampaigns: 'Sie können mehrere Kampagnen erstellen',
        dripCampaignName: 'Drip -Kampagnenname',
        dripCampaignNameIsRequired: 'Der Drip -Kampagnenname ist erforderlich',
        continue: 'Weitermachen',
        manageDripCampaign: 'Tropfkampagne verwalten',
        youCanCreateMultipleCampaignsWith: 'Sie können mehrere Tropfkampagnen mit geplanten, automatisierten Nachrichten erstellen, um Antworten von bestimmten BenutzerGruppen auszulösen',
        createNewDripCampaign: 'Erstellen Sie eine neue Tropfkampagne',
        campaignName: 'Kampagnenname',
        appliedOn: 'Aufgetragen auf',
        segment: 'Gruppe',
        flow: 'Fluss',
        startDate: 'Startdatum',
        action: 'Aktion',
        nA: 'N / A',
        placeholders: {
          enterDripCampaignName: 'Geben Sie den Namen des Drip -Kampagnens ein',
          searchByName: 'Suche mit Name',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Mehrere Tropfkampagnen löschen',
          deleteDripCampaign: 'Tropfkampagne löschen',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Drip -Kampagnenname',
          appliedOn: 'Aufgetragen auf',
          segments: 'Gruppe',
          selectDate: 'Datum auswählen',
          scheduleYourFlows: 'Planen Sie Ihre Ströme',
          flow: 'Fluss',
          sendAfter: 'Nachfragen',
          cancel: 'Stornieren',
          createDripCampaign: 'Tropfkampagne erstellen',
          placeholders: {
            enterDripName: 'Geben Sie den Tropfennamen ein',
            selectAppliedOn: 'Wählen Sie angewendet auf',
            selectSegment: 'Wählen Sie Gruppe',
            searchByName: 'Suche mit Name',
            selectTemplate: 'Vorlage auswählen',
            searchFlowByName: 'Suchfluss nach Namen',
            enterSendAfter: 'Geben Sie Senden nach',
          },
          dateTimePicker: {
            selectStartDate: 'Wählen Sie Startdatum',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'Chat -ID',
        errorCode: 'Fehlercode',
        errorMessage: 'Fehlermeldung',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Chat fließt',
        createChatFlow: 'Chat -Fluss erstellen',
        youCanCreateMultipleChatFlows: 'Sie können mehrere Konversationen über mehrere Kanäle für A/B -Tests und -übertragungen erstellen.',
        flowName: 'Flussname',
        nameIsRequired: 'Name ist erforderlich',
        cancel: 'Stornieren',
        createFlow: 'Fluss erstellen',
        importFlow: 'Importfluss',
        createNewFlow: 'Neuen Fluss erstellen',
        // flowName: 'Flussname',
        ofMessages: ' von Nachrichten',
        createdOn: 'Erstellt am',
        lastModified: 'Zuletzt bearbeitet',
        defaultFlow: 'Standardfluss',
        action: 'Aktion',
        exportStatus: 'Exportstatus',
        flowExport: 'Flow-Export',
        download: 'Herunterladen',
        // importFlow: 'Importfluss',
        beforeYouStartUploadPleaseMakeSure: 'Bevor Sie mit dem Upload beginnen, stellen Sie bitte sicher',
        point1: '1. Das Upload -Grenzwert der maximalen Dateigröße beträgt 1 MB',
        point2: '2. Das Dateiformat sollte in JSON vorliegen',
        selectFile: 'Datei aussuchen',
        placeholders: {
          enterFlowName: 'Geben Sie den Flussnamen ein',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Flow für Instagram Bot importieren',
          dateAndTimeOfCreationOfChatFlow: 'Datum und Uhrzeit der Erstellung des Chat -Flusses',
          dateAndTimeWhenChatFlowLastModified: 'Datum und Uhrzeit, als der Chat -Fluss zuletzt geändert wurde',
        },
        noDataFound: {
          flows: 'Fließt',
          noFlowsAddedYet: 'Noch keine Ströme hinzugefügt!',
        },
        modal: {
          deleteFlow: 'Fluss löschen',
          wantToDeleteThisFlow: 'Sind Sie sicher, dass Sie diesen Fluss löschen möchten?',
        }
      },
      widgets: {
        manageWidgets: 'Widgets verwalten',
        widgetsAreRoutingTools: 'Widgets sind die Routing -Tools, um die Verwendung von Bot zu erhöhen',
        needHelp: 'Brauchen Sie Hilfe?',
        wantToMakeChanges: 'Möchten Sie Änderungen vornehmen?',
        editIcon: 'Ikone bearbeiten',
        discard: 'Verwerfen',
        updateWidget: 'Aktualisieren Sie Widget',
        bpDropDown: {
          selectIcons: 'Wählen Sie Symbole',
          iconSize: 'Symbolgröße',
          iconPlacement: 'Icon -Platzierung',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Einstellungen für Telegramm -Bot',
      chatFlowsHeader: 'Chat fließt',
      telegramProfileHeader: 'Telegrammprofil',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Übertragen',
      dripCampaignHeader: 'Tropfkampagne',
      generalHeader: 'Allgemein',
      telegramAutomation: 'Telegramm -Automatisierung',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Erstellen Sie einen Kunden auf der Boarding mit einer DRIP -Kampagne geplanter Chat -Flows.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Passen Sie die Chat -Flow -Vorlagen an und erstellen Sie mehrere Chat -Flows für verschiedene Zielgruppen.',
      },
      broadcasts: {
        createBroadcast: 'Sendung erstellen',
        youCanCreateMutlipleBroadcasts: 'Sie können mehrere Sendungen erstellen',
        broadcastName: 'Rundfunkname',
        broadcastNameIsRequired: 'Sendungsname ist erforderlich',
        continue: 'Weitermachen',
        manageBroadcasts: 'Sendungen verwalten',
        youCanCreateMultipleBroadcastsWith: 'Sie können mehrere Sendungen mit mehreren geplanten Vorlagen für verschiedene KundenGruppe erstellen.',
        createNewBroadcast: 'Neue Sendung erstellen',
        name: 'Name',
        segment: 'Gruppe',
        template: 'Vorlage',
        contacts: 'Kontakte',
        send: 'Schicken',
        failed: 'Fehlgeschlagen',
        broadcastAt: 'Sendung bei',
        status: 'Status',
        actions: 'Aktionen',
        nA: 'N / A',
        deleteBroadcast: 'Sendung löschen',
        wantToDeleteThisBroadcast: 'Sind Sie sicher, dass Sie diese Sendung löschen möchten?',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          searchHere: 'Suche hier',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Mehrere Sendungen löschen',
          deleteBroadcast: 'Sendung löschen',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'Rundfunkname',
        broadcastNameIsRequired: 'Sendungsname ist erforderlich',
        flow: 'Fluss',
        segments: 'Gruppe',
        whenToSend: 'Wann zu senden',
        selectDate: 'Datum auswählen',
        selectTimeSlot: 'Wählen Sie Zeitschlitz',
        cancel: 'Stornieren',
        createBroadcast: 'Sendung erstellen',
        now: 'Jetzt',
        later: 'Später',
        placeholders: {
          enterBroadcastName: 'Geben Sie den Rundfunknamen ein',
          selectFlow: 'Wählen Sie Flow',
          searchFlowByName: 'Suchfluss nach Namen',
          selectSegments: 'Gruppe auswählen',
          searchSegmentByName: 'Gruppe nach Namen durchsuchen',
          selectTimeSlot: 'Wählen Sie Zeitschlitz',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Wählen Sie Startdatum',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Tropfkampagne erstellen',
        youCanCreateMultipleCampaigns: 'Sie können mehrere Kampagnen erstellen',
        dripCampaignName: 'Drip -Kampagnenname',
        dripCampaignNameIsRequired: 'Der Drip -Kampagnenname ist erforderlich',
        continue: 'Weitermachen',
        manageDripCampaign: 'Tropfkampagne verwalten',
        youCanCreateMultipleDripCampaignsWith: 'Sie können mehrere Tropfkampagnen mit geplanten, automatisierten Nachrichten erstellen, um Antworten aus bestimmten BenutzerGruppen auszulösen.',
        createNewDripCampaign: 'Erstellen Sie eine neue Tropfkampagne',
        campaignName: 'Kampagnenname',
        appliedOn: 'Aufgetragen auf',
        segment: 'Gruppe',
        flow: 'Fluss',
        startDate: 'Startdatum',
        action: 'Aktion',
        nA: 'N / A',
        deleteDripCampaign: 'Tropfkampagnen löschen',
        wantToDeleteThisDripCampaign: 'Sind Sie sicher, dass Sie diese Tropfkampagnen löschen möchten?',
        placeholders: {
          enterDripCampaignName: 'Geben Sie den Namen des Drip -Kampagnens ein',
          searchByName: 'Suche mit Name',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Mehrere Tropfkampagnen löschen',
          deleteDripCampaign: 'Tropfkampagne löschen',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Drip -Kampagnenname',
          appliedOn: 'Aufgetragen auf',
          segments: 'Gruppe',
          startDate: 'Datum auswählen',
          scheduleYourFlows: 'Planen Sie Ihre Ströme',
          schedule: 'Zeitplan',
          flow: 'Fluss',
          sendAfter: 'Nachfragen',
          cancel: 'Stornieren',
          createDripCampaign: 'Tropfkampagne erstellen',
          newSubscriber: 'Neuer Abonnent',
          days: 'Tage',
          minutes: 'Protokoll',
          hours: 'Std',
          placeholders: {
            enterDripName: 'Geben Sie den Tropfennamen ein',
            selectAppliedOn: 'Wählen Sie angewendet auf',
            selectSegment: 'Wählen Sie Gruppe',
            searchByName: 'Suche mit Name',
            selectFlow: 'Wählen Sie Flow',
            searchFlowByName: 'Suchfluss nach Namen',
          },
          dateTimePicker: {
            selectStartDate: 'Wählen Sie Startdatum',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Name',
        email: 'Email',
        phone: 'Telefon',
        errorCode: 'Fehlercode',
        errorMessage: 'Fehlermeldung',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Chat fließt',
        createChatFlow: 'Chat -Fluss erstellen',
        youCanCreateMultipleChatFlows: 'Sie können mehrere Konversationen über mehrere Kanäle für A/B -Tests und -übertragungen erstellen.',
        flowName: 'Flussname',
        nameIsRequired: 'Name ist erforderlich',
        cancel: 'Stornieren',
        createFlow: 'Fluss erstellen',
        importFlow: 'Importfluss',
        createNewFlow: 'Neuen Fluss erstellen',
        // flowName: 'Flussname',
        ofMessages: ' von Nachrichten',
        createdOn: 'Erstellt am',
        lastModified: 'Zuletzt bearbeitet',
        defaultFlow: 'Standardfluss',
        action: 'Aktion',
        exportStatus: 'Exportstatus',
        flowExport: 'Flow-Export',
        inProgress: 'im Gange',
        available: 'Verfügbar',
        initiated: 'Initiiert',
        download: 'Herunterladen',
        // importFlow: 'Importfluss',
        beforeYouStartUploadPleaseMakeSure: 'Bevor Sie mit dem Upload beginnen, stellen Sie bitte sicher',
        point1: '1. Das Upload -Grenzwert der maximalen Dateigröße beträgt 1 MB',
        point2: '2. Das Dateiformat sollte in JSON vorliegen',
        selectFile: 'Datei aussuchen',
        placeholders: {
          enterFlowName: 'Geben Sie den Flussnamen ein',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Flow für Instagram Bot importieren',
          dateAndTimeOfCreationOfChatFlow: 'Datum und Uhrzeit der Erstellung des Chat -Flusses',
          dateAndTimeWhenChatFlowLastModified: 'Datum und Uhrzeit, als der Chat -Fluss zuletzt geändert wurde',
          TOOLTIP_CREATE_FLOW: 'Klicken Sie hier, um einen neuen Chat -Fluss zu erstellen',
          TOOLTIP_FLOW_NAME: 'Geben Sie den Chat -Flow -Namen ein',
          TOOLTIP_FLOW_TABLE_NAME: 'Name des Chat -Flusses',
          TOOLTIP_NO_OF_MESSAGES: 'Anzahl der Nachrichtenkomponenten im Chat -Fluss',
          TOOLTIP_DEFAULT_FLOW: 'Verwenden Sie diesen Umschalter, um einen Chat -Fluss als Standard festzulegen, der allen neuen Benutzern zugestellt wird.',
          TOOLTIP_ACTION: 'Bearbeiten oder löschen Sie den Chat -Fluss',
        },
        noDataFound: {
          flows: 'Fließt',
          noFlowsAddedYet: 'Noch keine Ströme hinzugefügt!',
        },
        modal: {
          deleteFlow: 'Fluss löschen',
          wantToDeleteThisFlow: 'Sind Sie sicher, dass Sie diesen Fluss löschen möchten?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Telegrammprofil',
        manageYourBotProfile: 'Verwalten Sie Ihr Bot -Profil so und wann Sie möchten.',
        allSetNoActionRequired: 'Alle gesetzt, keine Aktion erforderlich!',
        congratulationYourAccountIsLive: 'Glückwunsch! Ihr Konto ist live mit vollem Zugriff',
        botDetails: 'Bot Details',
        name: 'Name',
        nA: 'N / A',
        b: 'B',
        botName: 'Bot Name',
        bot: 'Bot',
        info: 'Die Info',
        none: 'Keiner',
        username: 'Nutzername',
        notifications: 'Benachrichtigungen',
        on: 'An',
        addToGroupOrChannel: 'Zu Gruppen oder Kanal hinzufügen',
        userName: 'Nutzername',
        botToken: 'Bot -Token',
      },
      widgets: {
        manageWidgets: 'Widgets verwalten',
        widgetsAreRoutingTools: 'Widgets sind die Routing -Tools, um die Verwendung von Bot zu erhöhen',
        needHelp: 'Brauchen Sie Hilfe?',
        wantToMakeChanges: 'Möchten Sie Änderungen vornehmen?',
        editIcon: 'Ikone bearbeiten',
        discard: 'Verwerfen',
        updateWidget: 'Aktualisieren Sie Widget',
        installChatBotIconOnYourWebsite: 'Installieren Sie das Chatbot -Symbol auf Ihrer Website',
        shareWhereEverYouWantRedirectUsersTo: 'Teilen Sie überall, wo Sie möchten und Benutzer in Ihrem Telegramm -Bot in Einzelklick umleiten und umleiten',
        copyAndAddInYourWebsiteHeaderFile: 'Kopieren und fügen Sie in Ihrer Website -Header -Datei zu und fügen Sie hinzu',
        copyLinkAndStartSharing: 'Link kopieren und mit der Freigabe beginnen!',
        downloadQrCodeAndStartSharing: 'Laden Sie QR Code herunter und starten Sie die Freigabe!',
        addCodeAndDeployNewCodeAndDone: 'Fügen Sie den Code hinzu, geben Sie neuen Code bereit und erledigt!',
        wantToLearnHowToUseIt: 'Möchten Sie lernen, wie man es benutzt?',
        copyChatBotCode: 'Kopieren Sie den Chatbot -Code',
        downloadQrCode: 'Laden Sie den QR -Code herunter',
        copyTelegramLink: 'Telegrammlink kopieren',
        link: 'Verknüpfung',
        chatBotIcon: 'Chatbot -Symbol',
        qrcode: 'QR-Code',
        small: 'Klein',
        medium: 'Mittel',
        large: 'Groß',
        leftMiddle: 'Links',
        rightMiddle: 'Rechts aushindig',
        leftBottom: 'Links unten',
        rightBottom: 'Rechts unten',
        bpDropDown: {
          selectIcons: 'Wählen Sie Symbole',
          iconSize: 'Symbolgröße',
          iconPlacement: 'Icon -Platzierung',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Sitzungszeitüberschreitung',
        sendInactivityMessage: 'Inaktivitätsnachricht senden',
        enterSessionTimeOutWindowInHours: 'Geben Sie das Zeitfenster für die Sitzungszeitüberschreitung in Stunden ein',
        sessionTimeOutInHoursTooltip: `Geben Sie die Dauer ein, nach der die Chat-Sitzung aufgrund von Inaktivität automatisch abläuft. Der Chatverlauf wird neu gestartet, wenn der Benutzer nach Ablauf der Sitzungszeit eine Nachricht sendet.`,
        restartChatFlowTriggers: 'Trigger zum Neustart des Chatverlaufs',
        enterKeyWordsToRestartChatAndhitEnter: 'Geben Sie Schlüsselwörter ein, um den Chat neu zu starten, und drücken Sie Enter',
        restartChatFlowTriggersTooltip: `Geben Sie hier Schlüsselwörter an, die, wenn sie vom Benutzer eingegeben und gesendet werden, einen Neustart des Chatverlaufs auslösen. Dies ermöglicht es Benutzern, das Gespräch zu jedem Zeitpunkt neu zu starten.
         Drücken Sie nach dem Hinzufügen jedes Schlüsselworts Enter, um mehrere Schlüsselwörter hinzuzufügen.`,
        liveChatTriggers: 'Live-Chat-Auslöser',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Geben Sie Schlüsselwörter ein, um den Live-Chat auszulösen, und drücken Sie Enter',
        liveChatTriggersTooltip: `Definieren Sie Schlüsselwörter, die, wenn sie vom Benutzer eingegeben und gesendet werden, den Übergang von automatisiertem Chat zu Live-Chat initiieren. Dies ist nützlich, wenn Benutzer Unterstützung von einem Live-Agenten benötigen.
         Drücken Sie nach dem Hinzufügen jedes Schlüsselworts Enter, um mehrere Schlüsselwörter hinzuzufügen.`,
        messageAfterAutomatedFlowCompleted: 'Nachricht nach Abschluss des automatisierten Ablaufs',
        enterYourMessage: 'Geben Sie Ihre Nachricht ein',
        messageAfterAutomatedFlowCompletedTooltip: `Geben Sie eine Nachricht ein, die dem Benutzer angezeigt wird, nachdem er den automatisierten Chatverlauf abgeschlossen hat, aber die Sitzung nicht abgelaufen ist.`,
        restart: 'Neustart',
        liveChat: 'Live-Chat',
        lineOneForDefaultMessage: 'Wenn Sie weitere Fragen haben oder Unterstützung benötigen, hier ist, was Sie als Nächstes tun können:',
        to: 'An',
        restartTheChat: 'den Chat neu starten',
        simpleTypeRestartAndHitEnter: `geben Sie einfach 'Neustart' ein und drücken Sie Enter. Dies bringt Sie zurück zum Anfang unseres Gesprächsverlaufs.`,
        lineThreeForDefaultMessagePartOne: `Wenn Sie mit einem`,
        liveAgent: `Live-Agenten`,
        lineThreeForDefaultMessageLastPart: `sprechen möchten, geben Sie 'Live-Chat' ein und drücken Sie Enter. Unser Team steht Ihnen in Echtzeit zur Verfügung.`,
        saveChanges: 'Änderungen speichern',
        discard: 'Verwerfen',
        configureBotsGeneralSettings: 'Konfigurieren Sie die allgemeinen Einstellungen Ihres Bots',
        minutes: 'Protokoll',
        hours: 'Std',
      }
    },
    whatsapp: {
      whatsappAutomation: 'WhatsApp -Automatisierung',
      whatsappBotSettings: 'WhatsApp -Bot -Einstellungen',
      chatFlowsHeader: 'Chat fließt',
      generalHeader: 'Allgemein',
      whatsappProfileHeader: 'WhatsApp -Profil',
      broadcastHeader: 'Übertragen',
      dripCampaignHeader: 'Tropfkampagne',
      widgetHeader: 'Widget',
      templatesHeader: 'Vorlagen',
      alertsHeader: 'Warnungen',
      apiKeyHeader: 'API-Schlüssel',
      catalogHeader: 'Katalog',
      otherConfiguration: 'KI-Einstellungen',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Passen Sie die Chat -Flow -Vorlagen an und erstellen Sie mehrere Chat -Flows für verschiedene Zielgruppen.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Konfigurieren Sie die Warneinstellungen Ihres Bots',
        specifyHowYouBotWillNotify: 'Geben Sie an, wie Ihr Bot Sie über neue Leads informiert.',
        subject: 'Thema',
        emailNotification: 'Email Benachrichtigung',
        emailAddresses: 'E-mailadressen',
        leadRevisitNotifications: 'Lead -Revision Benachrichtigungen',
        whenToSendLeadInfo: 'Wann senden Sie Lead -Informationen',
        discard: 'Verwerfen',
        saveChanges: 'Änderungen speichern',
        seeHowToUse: 'Sehen Sie, wie man benutzt',
        beginningTheFlow: 'Mit dem Fluss beginnen',
        fiveMinutesLater: '5 Minuten später',
        endOfFlow: 'Ende des Flusses',
        placeholders: {
          enterSubjectForYourEmail: 'Geben Sie Betreff für Ihre E -Mail ein',
          typeEmailAndHitEnter: 'Geben Sie Ihre E -Mail -Adresse ein und drücken Sie die Eingabetaste',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Passen Sie das Thema Ihrer E -Mail -Benachrichtigungen aus dem Bot an',
          TOOLTIP_EMAIL_NOTIFICATION: 'Aktivieren/deaktivieren Sie die Benachrichtigung des Bots in Ihrer E -Mail für neue Leads',
          TOOLTIP_EMAIL_ADDRESS: 'Senden Sie eine E -Mail -Adresse des Kontos, in dem Sie E -Mail -Benachrichtigungen erhalten möchten. Sie können mehrere E -Mail -Adressen hinzufügen, indem Sie eine eingeben und die Eingabetaste drücken, um ein weiteres hinzuzufügen.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Aktivieren/deaktivieren Sie die Benachrichtigung von Bot in Ihrer E -Mail, wenn ein Benutzer zuvor wieder mit dem Bot mit dem Bot erfasst wurde.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Aktualisieren Sie WhatsApp Chatbot',
        businessNameIsRequired: 'Firmenname ist erforderlich.',
        maxLengthForBusinessName40Characters: 'Die maximale Länge für den Firmennamen beträgt 40 Zeichen.',
        categoryIsRequired: 'Kategorie ist erforderlich.',
        descriptionIsRequired: 'Beschreibung ist erforderlich.',
        maxLengthForDescription200Characters: 'Die maximale Länge für die Beschreibung beträgt 200 Zeichen.',
        aboutIsRequired: 'Ungefähr ist erforderlich.',
        maxLengthForAbout139Characters: 'Die maximale Länge für etwa 139 Zeichen beträgt.',
        pleaseFillAValidBusinessEmail: 'Bitte füllen Sie eine gültige Geschäfts -E -Mail aus.',
        businessEmailIsRequired: 'Business -E -Mail ist erforderlich.',
        websiteIsRequired: 'Website ist erforderlich.',
        maxLengthForWebsite40Characters: 'Die maximale Länge für die Website beträgt 40 Zeichen.',
        maxLengthForAddress150Characters: 'Die maximale Länge für die Adresse beträgt 150 Zeichen.',
        cancel: 'Stornieren',
        update: 'Aktualisieren',
        businessCategory: 'Geschäftskategorie',
        placeholders: {
          yourBusinessName: 'Ihr Firmenname',
          businessDescription: 'Geschäftsbeschreibung',
          about: 'Um',
          businessEmail: 'Geschäftliche E-Mail',
          website: 'Website (z. B. www.example.com)',
          address: 'Adresse',
        }
      },
      flows: {
        chatFlows: 'Chat fließt',
        createChatFlow: 'Chat -Fluss erstellen',
        youCanCreateMultipleChatFlows: 'Sie können mehrere Konversationen über mehrere Kanäle für A/B -Tests und -übertragungen erstellen.',
        flowName: 'Flussname',
        nameIsRequired: 'Name ist erforderlich',
        cancel: 'Stornieren',
        createFlow: 'Fluss erstellen',
        importFlow: 'Importfluss',
        createNewFlow: 'Neuen Fluss erstellen',
        ofMessages: ' von Nachrichten',
        createdOn: 'Erstellt am',
        lastModified: 'Zuletzt bearbeitet',
        defaultFlow: 'Standardfluss',
        revisitFlow: 'Überprüfen Sie den Fluss',
        action: 'Aktion',
        exportStatus: 'Exportstatus',
        flowExport: 'Flow-Export',
        // importFlow: 'Importfluss',
        beforeYouStartUploadPleaseMakeSure: 'Bevor Sie mit dem Upload beginnen, stellen Sie bitte sicher',
        point1: '1. Das Upload -Grenzwert der maximalen Dateigröße beträgt 1 MB',
        point2: '2. Das Dateiformat sollte in JSON vorliegen',
        selectFile: 'Datei aussuchen',
        seeHowToUse: 'Sehen Sie, wie man benutzt',
        download: 'Herunterladen',
        available: 'Verfügbar',
        initiated: 'Initiiert',
        inProgress: 'im Gange',
        placeholders: {
          enterFlowName: 'Geben Sie den Flussnamen ein',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Flow für WhatsApp -Bot importieren',
          dateAndTimeOfCreationOfChatFlow: 'Datum und Uhrzeit der Erstellung des Chat -Flusses',
          dateAndTimeWhenChatFlowLastModified: 'Datum und Uhrzeit, als der Chat -Fluss zuletzt geändert wurde',
          TOOLTIP_CREATE_FLOW: 'Klicken Sie hier, um einen neuen Chat -Fluss zu erstellen',
          TOOLTIP_FLOW_NAME: 'Geben Sie den Chat -Flow -Namen ein',
          TOOLTIP_FLOW_TABLE_NAME: 'Name des Chat -Flusses',
          TOOLTIP_NO_OF_MESSAGES: 'Anzahl der Nachrichtenkomponenten im Chat -Fluss',
          TOOLTIP_DEFAULT_FLOW: 'Verwenden Sie diesen Umschalter, um einen Chat -Fluss als Standard festzulegen, der allen neuen Benutzern zugestellt wird.',
          TOOLTIP_REVISIT_FLOW: 'Verwenden Sie diesen Umschalter, um einen Chat -Fluss auszuwählen, der den Benutzern beim Überprüfen Ihres Chatbots zugestellt wird.',
          TOOLTIP_ACTION: 'Bearbeiten oder löschen Sie den Chat -Fluss',
        },
        modal: {
          deleteFlow: 'Fluss löschen',
          wantToDeleteThisFlow: 'Sind Sie sicher, dass Sie diesen Fluss löschen möchten?',
        },
        noDataFound: {
          flows: 'Fließt',
          noFlowsAddedYet: 'Noch keine Ströme hinzugefügt!',
        }
      },
      input: {
        manageTemplates: 'Vorlagen verwalten',
        manageYourTemplatesForMarketingAutomation: 'Verwalten Sie Ihre Vorlagen für die Marketing -Automatisierung',
        syncTemplates: 'Synchronisierungsvorlagen',
        createTemplateHeader: 'Vorlage erstellen',
        title: 'Titel',
        info: 'Die Info',
        category: 'Kategorie',
        status: 'Status',
        action: 'Aktion',
        shipmentUpdate: 'Versandaktualisierung',
        name: 'Name',
        // category: 'Kategorie',
        headerOptional: 'Header (optional)',
        bodyRequired: 'Körper (erforderlich)',
        footerOptional: 'Fußzeile (optional)',
        buttonsOptional: 'Knöpfe (optional)',
        makeUpto3Buttons: 'Machen Sie bis zu 3 Knöpfe zusammen',
        makeUpto2ButtonsToRedirect: 'Machen Sie bis zu 2 Schaltflächen, um den Kunden auf Ihre Website umzuleiten',
        placeholders: {
          nameYourTemplate: 'Nennen Sie Ihre Vorlage (Verwenden Sie Unterstiche, um Wörter zu trennen).',
          enterText: 'Text eingeben (max. 60 Zeichen)',
          enterImageUrl: 'Geben Sie Bild -URL ein',
          enterVideoUrl: 'Geben Sie Video -URL ein',
          templateMessage: 'Vorlagennachricht',
          smallFooterAtBottom: 'Kleine Fußzeile unten',
          buttons: 'Tasten',
          text: 'Text',
          numberWithCountryCode: 'Nummer mit Ländercode',
          enterButtonText: 'Schalttenschalttext eingeben (max. MACCAME 20)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Titel der WhatsApp -Vorlage',
          TOOLTIP_INPUT_TABLE_INFO: 'Detaillierte Informationen der geschäftlichen initiierten Nachrichten',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Art der geschäftlichen initiierten Nachrichten wie Termine, Erinnerungen, Kundenbetreuungsnachrichten, Versandaktualisierungen, Warnungen und mehr',
          TOOLTIP_INPUT_TABLE_STATUS: 'Der Vorlagenstatus wird von WhatsApp abgelehnt oder genehmigt. WhatsApp -Vorlagen müssen zuerst von WhatsApp genehmigt werden, und dies ist ein bezahlter Service.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Löschen Sie die Vorlage',
        },
        noDataFound: {
          templates: 'Vorlagen',
          noTemplatesCreatedYet: 'Noch keine Vorlagen erstellt!',
        },
        modal: {
          deleteTemplate: 'Vorlage löschen',
          wantToDeleteThisTemplate: 'Sind Sie sicher, dass Sie diese Vorlage löschen möchten?',
        },
        createTemplate: {
          createTemplates: 'Vorlagen erstellen',
          createTemplatesForMarketingAutomation: 'Erstellen Sie Ihre Vorlagen für die Marketing -Automatisierung',
          templateMeta: 'Vorlage Meta',
          selectLanguage: 'Sprache auswählen',
          header: 'Header',
          optional: 'Optional',
          samplesForHeaderContent: 'Beispiele für den Headerinhalt',
          toHelpMetaReviewYourContent: 'Um die Meta -Inhalte zu überprüfen, geben Sie Beispiele für die Variablen oder Medien im Header an. Fügen Sie keine Kundeninformationen hinzu.',
          body: 'Körper',
          addVariable: 'Variable hinzufügen',
          samplesForBodyContent: 'Proben für den Körpergehalt',
          toHelpUsReviewYourContent: 'Um uns bei der Überprüfung Ihrer Inhalte zu unterstützen, geben Sie Beispiele für die Variablen im Körper an. Fügen Sie keine Kundeninformationen hinzu.',
          footer: 'Fusszeile',
          // optional: 'Optional',
          buttons: 'Tasten',
          // optional: 'Optional',
          typeOfAction: 'Art der Aktion',
          call: 'Forderung',
          phone: 'Telefon',
          website: 'Webseite',
          static: 'Statisch',
          urlType: 'URL -Typ',
          type: 'Typ',
          customButton: 'Benutzerdefinierte Schaltfläche',
          addAnotherButton: 'Fügen Sie eine weitere Taste hinzu',
          preview: 'Vorschau',
          test: 'Test',
          create: 'Erstellen',
          addSampleUrl: 'Probe URL hinzufügen',
          toHelpMetaReviewYourMessage: 'Um Meta zu helfen, Ihre Nachrichtenvorlage zu überprüfen, fügen Sie bitte ein Beispiel für die URL der Website hinzu. Verwenden Sie keine echten Kundeninformationen.',
          placeholders: {
            enterMessageTemplateName: 'Geben Sie den Namen der Nachrichtenvorlage ein',
            searchTemplateByName: 'Suchvorlage nach Namen',
            selectTemplate: 'Vorlage auswählen',
            enterText: 'Text eingeben',
            enterBody: 'Körper betreten',
            enterContentForVariable: 'Geben Sie Inhalte für Variable ein',
            enterFooter: 'Geben Sie Fußzeile ein',
            phone: 'Telefon*',
            enterWebsiteUrl: 'Geben Sie die Website der Website ein',
          },
          bpInput: {
            name: 'Name',
            buttonText: 'Schaltflächentext',
            websiteUrl: 'Webadresse',
          },
          bpDropDown: {
            selectCategory: 'Kategorie wählen',
          }
        },
        templatePreview: {
          preview: 'Vorschau',
          test: 'Test',
        },
        templateConfig: {
          discard: 'Verwerfen',
          saveChanges: 'Änderungen speichern',
          triggerChatFlowOnTemplateReply: 'Chat-Flow bei Vorlagenantwort auslösen',
          templateDetails: 'Vorlagendetails',
          configureFlows: 'Flows konfigurieren',
          selectFlow: 'Fluss auswählen',
          name: 'Name'
        }
      },
      shopManagement: {
        shopManagement: 'Geschäftsführung',
        manageECommerceShopFromBotPenguin: 'Verwalten Sie E-Commerce-Shops, die mit Botpenguin verbunden sind',
        comingSoon: '( Demnächst )',
        wooCommerce: {
          createConfiguration: 'Konfiguration erstellen',
          eventConfiguration: {
            createConfiguration: 'Konfiguration erstellen',
            templateHeader: 'Vorlagenheader',
            selectFile: 'Datei aussuchen',
            templateMessage: 'Vorlagennachricht',
            saveChanges: 'Änderungen speichern',
            bpDropDown: {
              selectEvent: 'Wählen Sie Ereignis',
              selectTemplate: 'Vorlage auswählen',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'WhatsApp -Profil',
        manageYourBotProfile: 'Verwalten Sie Ihr Bot -Profil so und wann Sie möchten.',
        allSetNoActionRequired: 'Alle gesetzt, keine Aktion erforderlich!',
        congratulationsYourAccountIsLive: 'Glückwunsch! Ihr Konto ist live mit vollem Zugriff',
        somethingIsWrongReactivateYourBot: 'Etwas stimmt nicht, aktiviere deinen Bot wieder!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Es scheint, als ob Ihr Bot nicht richtig funktioniert. Bitte setzen Sie Ihren Bot zurück',
        reConfigureYourBot: 'Konfigurieren Sie Ihren Bot erneut',
        phoneNumberDetails: 'Telefonnummerdetails',
        phoneNumber: 'Telefonnummer',
        nA: 'N / A',
        phoneNumberId: 'Telefonnummer -ID',
        wabaId: 'Waba id',
        change: 'Ändern',
        accessToken: 'Zugangstoken',
        // change: 'Ändern',
        messagingLimit: 'Messaging -Grenze',
        qualityRating: 'Qualitätsbewertung',
        whatsappBusinessAccountName: 'WhatsApp Business -Kontoname',
        whatsappPublicProfile: 'WhatsApp öffentliches Profil',
        profilePicture: 'Profilbild',
        aboutText: 'Über Text',
        address: 'Adresse',
        description: 'Beschreibung',
        email: 'Email',
        websitePrimary: 'Website (primär)',
        websiteSecondary: 'Website (sekundär)',
        businessVertical: 'Geschäft vertikal',
        editProfile: 'Profil bearbeiten',
        thisIsABusinessAccount: 'Dies ist ein Geschäftskonto',
        aboutAndPhoneNumber: 'Über und Telefonnummer',
        yourTrialAccountIsLive: 'Glückwunsch! Ihr Testkonto ist live mit vollem Zugriff auf Ihre überprüfte Nummer',
        updateWithNewAccessToken: 'Aktualisieren Sie mit neuem AccessToken',
      },
      widgets: {
        manageWidgets: 'Widgets verwalten',
        widgetsAreRoutingTools: 'Widgets sind die Routing -Tools, um die Verwendung von Bot zu erhöhen',
        needHelp: 'Brauchen Sie Hilfe?',
        wantToMakeChanges: 'Möchten Sie Änderungen vornehmen?',
        prePopulatedMessage: 'Vorgepopulierte Nachricht',
        discard: 'Verwerfen',
        updateWidget: 'Aktualisieren Sie Widget',
        hiThere: 'Hi',
        seeHowToUse: 'Sehen Sie, wie man benutzt',
        editIcon: 'Ikone bearbeiten',
        editPrePopulatedMessage: 'Bearbeiten Sie die vorgepopulierte Nachricht',
        small: 'Klein',
        medium: 'Mittel',
        large: 'Groß',
        leftMiddle: 'Links',
        rightMiddle: 'Rechts aushindig',
        leftBottom: 'Links unten',
        rightBottom: 'Rechts unten',
        chatBotIcon: 'Chatbot -Symbol',
        link: 'Verknüpfung',
        qrcode: 'QR-Code',
        copyChatBotCode: 'Kopieren Sie den Chatbot -Code',
        copyWALink: 'Kopieren Sie WA Link',
        downloadQrCode: 'Laden Sie den QR -Code herunter',
        installChatBotIconOnYourWebsite: 'Installieren Sie das Chatbot -Symbol auf Ihrer Website',
        shareWhereEverYouWantRedirectUsersTo: 'Teilen Sie, wo immer Sie möchten, und leiten Sie Benutzer in Ihrem WhatsApp -Bot in Single -Klick um und leiten Sie sie weiter.',
        copyAndAddInYourWebsiteHeaderFile: 'Kopieren und fügen Sie in Ihrer Website -Header -Datei zu und fügen Sie hinzu',
        copyLinkAndStartSharing: 'Link kopieren und mit der Freigabe beginnen!',
        downloadQrCodeAndStartSharing: 'Laden Sie QR Code herunter und starten Sie die Freigabe!',
        addCodeAndDeployNewCodeAndDone: 'Fügen Sie den Code hinzu, geben Sie neuen Code bereit und erledigt!',
        wantToLearnHowToUseIt: 'Möchten Sie lernen, wie man es benutzt?',
        placeholders: {
          enterWhatsappNumber: 'Geben Sie die WhatsApp -Nummer ein',
          updateYourMessageHere: 'Aktualisieren Sie Ihre Nachricht hier ...',
        },
        bpDropDown: {
          selectIcons: 'Wählen Sie Symbole',
          iconSize: 'Symbolgröße',
          iconPlacement: 'Icon -Platzierung',
        },
        bpInput: {
          whatsappNumber: 'WhatsApp -Nummer',
        }
      },
      catalog: {
        manageCatalog: 'Katalog verwalten',
        completeStepsBelowToEnableCatalog: 'Beenden Sie die Schritte unten, um das Katalog zu aktivieren',
        createCatalog: 'Katalog erstellen',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Erstellen Sie einen Produktkatalog im Meta Commerce Manager oder verknüpfen Sie ihn mit Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Verwenden Sie Ihren Meta Commerce Manager, um Kataloge zu verwalten',
        connectCatalogToWhatsAppAccount: 'Katalog mit WhatsApp-Konto verbinden',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Verbinden Sie Ihre Kataloge mit Ihrem WhatsApp-Konto über Ihren WhatsApp-Manager',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Verwenden Sie Ihren Meta WhatsApp-Manager, um Kataloge zu verbinden und mit BotPenguin zu verwenden',
        goToMeta: 'Zu Meta gehen',
        goToShopify: 'Zu Shopify gehen',
        next: 'Weiter',
        back: 'Zurück',
        setupCatalog: 'Katalog einrichten',
        completeAllTheStepsToEnableCatalogMessages: 'Alle Schritte abschließen, um Katalognachrichten zu aktivieren'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Sitzungszeitüberschreitung',
        sendInactivityMessage: 'Inaktivitätsnachricht senden',
        enterSessionTimeOutWindowInHours: 'Geben Sie das Zeitfenster für die Sitzungszeitüberschreitung in Stunden ein',
        sessionTimeOutInHoursTooltip: `Geben Sie die Dauer ein, nach der die Chat-Sitzung aufgrund von Inaktivität automatisch abläuft. Der Chatverlauf wird neu gestartet, wenn der Benutzer nach Ablauf der Sitzungszeit eine Nachricht sendet.`,
        restartChatFlowTriggers: 'Trigger zum Neustart des Chatverlaufs',
        enterKeyWordsToRestartChatAndhitEnter: 'Geben Sie Schlüsselwörter ein, um den Chat neu zu starten, und drücken Sie Enter',
        restartChatFlowTriggersTooltip: `Geben Sie hier Schlüsselwörter an, die, wenn sie vom Benutzer eingegeben und gesendet werden, einen Neustart des Chatverlaufs auslösen. Dies ermöglicht es Benutzern, das Gespräch zu jedem Zeitpunkt neu zu starten.
         Drücken Sie nach dem Hinzufügen jedes Schlüsselworts Enter, um mehrere Schlüsselwörter hinzuzufügen.`,
        liveChatTriggers: 'Live-Chat-Auslöser',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Geben Sie Schlüsselwörter ein, um den Live-Chat auszulösen, und drücken Sie Enter',
        liveChatTriggersTooltip: `Definieren Sie Schlüsselwörter, die, wenn sie vom Benutzer eingegeben und gesendet werden, den Übergang von automatisiertem Chat zu Live-Chat initiieren. Dies ist nützlich, wenn Benutzer Unterstützung von einem Live-Agenten benötigen.
         Drücken Sie nach dem Hinzufügen jedes Schlüsselworts Enter, um mehrere Schlüsselwörter hinzuzufügen.`,
        messageAfterAutomatedFlowCompleted: 'Nachricht nach Abschluss des automatisierten Ablaufs',
        enterYourMessage: 'Geben Sie Ihre Nachricht ein',
        messageAfterAutomatedFlowCompletedTooltip: `Geben Sie eine Nachricht ein, die dem Benutzer angezeigt wird, nachdem er den automatisierten Chatverlauf abgeschlossen hat, aber die Sitzung nicht abgelaufen ist.`,
        restart: 'Neustart',
        liveChat: 'Live-Chat',
        lineOneForDefaultMessage: 'Wenn Sie weitere Fragen haben oder Unterstützung benötigen, hier ist, was Sie als Nächstes tun können:',
        to: 'An',
        restartTheChat: 'den Chat neu starten',
        simpleTypeRestartAndHitEnter: `geben Sie einfach 'Neustart' ein und drücken Sie Enter. Dies bringt Sie zurück zum Anfang unseres Gesprächsverlaufs.`,
        lineThreeForDefaultMessagePartOne: `Wenn Sie mit einem`,
        liveAgent: `Live-Agenten`,
        lineThreeForDefaultMessageLastPart: `sprechen möchten, geben Sie 'Live-Chat' ein und drücken Sie Enter. Unser Team steht Ihnen in Echtzeit zur Verfügung.`,
        saveChanges: 'Änderungen speichern',
        discard: 'Verwerfen',
        configureBotsGeneralSettings: 'Konfigurieren Sie die allgemeinen Einstellungen Ihres Bots',
        minutes: 'Protokoll',
        hours: 'Std',
      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'Nur einen Schritt entfernt',
      seeHowToUse: 'Sehen Sie, wie man es benutzt',
      testWhatsAppTrialBot: 'Testen Sie Ihren WhatsApp Test-Chatbot',
      sendMessage: 'Nachricht senden',
      sendWhatsAppMessageOn: 'Senden Sie uns eine WhatsApp-Nachricht an ',
      bySavingInYourContact: 'durch Speichern in Ihren Kontakten und die Nachricht sollte mit ',
      connect: 'Verbinden',
      or: 'ODER',
      scanQRCode: 'Scannen Sie den QR-Code',
      yourPhoneWillOpen: 'Ihr Telefon öffnet eine vorausgefüllte Nachricht, die an unsere Sandbox-Nummer gesendet werden soll. Tippen Sie auf „Senden“ auf WhatsApp',
      sendVerificationCode: 'Bestätigungscode senden',
      copyAndEnterCode: '6-stelligen Code kopieren und eingeben',
      shareWhereYouWant: 'Teilen Sie es, wo immer Sie möchten, und leiten Sie Benutzer mit einem einzigen Klick zu Ihrem WhatsApp-Bot weiter',
      youShouldHaveReceived: 'Sie sollten einen 6-stelligen Code auf Ihrer WhatsApp-Nummer erhalten haben',
      EnterYourPhoneNumber: 'Geben Sie Ihre Telefonnummer ein',
      send: 'Senden',
      sixDigitCode: '6-stelligen Code eingeben',
      pleaseNote: 'Bitte beachten Sie: Der 6-stellige Bestätigungscode kann bis zu 3 Minuten dauern. Bitte warten Sie, bevor Sie ihn erneut senden.',
      resendVerificationCode: 'Bestätigungscode erneut senden',
      verify: 'Bestätigen',
      providePhoneNumber: 'Bitte geben Sie die Nummer an, die Sie für den Test verwenden möchten',
      demoBotisReady: 'Fantastisch! Ihr Demo-Bot ist bereit zur Verwendung',
      placeHolder: {
        phone: 'Geben Sie Ihre Telefonnummer ein',
        code: 'Geben Sie den Bestätigungscode ein',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Apps Integration von Drittanbietern',
    thirdPartyApps: 'Apps von Drittanbietern',
    customApps: 'Benutzerdefinierte Apps',
    preview: 'Vorschau',
    test: 'Test',
    install: 'Installieren',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Klicken Sie hier, um Ihren Bot auf der ausgewählten Plattform zu installieren',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Integrieren Sie Ihre benutzerdefinierten erstellten Apps in Ihren Bot',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Schließen Sie Ihren Bot mit verfügbaren Apps/CRMs von Drittanbietern an',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Apps-Integrationen von Drittanbietern',
        integrateYourBotWithListedCrms: 'Integrieren Sie Ihren Bot in eine der aufgelisteten Apps/CRMs.',
        help: 'Hilfe',
        name: 'Name',
        status: 'Status',
        action: 'Aktion',
        chatGpt: 'Chatgpt',
        addOn: 'Hinzufügen Auf',
        purchased: 'Gekauft',
        readyToRideGptWave: 'Bereit, die GPT -Welle zu fahren? Integrieren Sie jetzt.',
        purchase: 'Kaufen',
        notConnected: 'Nicht verbunden',
        integrated: 'Integriert',
        configure: 'Konfigurieren',
        connected: 'In Verbindung gebracht',
        edit: 'Bearbeiten',
        connect: 'Verbinden',
        requestYourIntegration: 'Fordern Sie Ihre Integration an',
        toRequestNewIntegration: 'Um eine neue Integration anzufordern, geben Sie die folgenden genannten Details ein und dann werden wir uns bei Ihnen melden',
        // name: 'Name',
        emailId: 'E -Mail -ID',
        integrationName: 'Integrationsname',
        contactNumber: 'Kontakt Nummer',
        howSoonYouWantToAddThisIntegration: 'Wie schnell möchten Sie diese Integration hinzufügen',
        sendRequest: 'Anfrage senden',
        cancel: 'Stornieren',
        requestSent: 'Anfrage geschickt!',
        yourRequestSuccessfullySent: 'Ihre Anfrage nach neuer Integration wurde erfolgreich gesendet.',
        goBack: 'Geh zurück',
        disconnect: 'Trennen',
        OopsIntegrationAreNotAvailable: '¡Ups! Las integraciones no están disponibles en el ',
        pleaseUpgradeTo: ' plan. Por favor, actualice al ',
        planForIntegration: ' para las integraciones.',
        placeholders: {
          searchIntegrationByName: 'Suchintegration nach Namen',
          enterTeamMemberName: 'Geben Sie den Namen des Teammitglieds ein',
          enterEmailId: 'Geben Sie eine E -Mail -ID ein',
          enterIntegrationName: 'Geben Sie einen Integrationsnamen ein',
          searchCountry: 'Suchland',
          enterContactNumber: 'Geben Sie die Kontaktnummer ein',
          addComment: 'Einen Kommentar hinzufügen',
        },
        noDataFound: {
          integrations: 'Integrationen',
          noIntegrationsFound: 'Keine Integrationen gefunden!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Name von App/CRM',
          statusAsConnectedOrNotConnected: 'Status als verbunden / nicht verbunden',
          clickToConnectToAppOrCrm: 'Klicken Sie hier, um die App/CRM zu verbinden',
        },
        feedbackModal: {
          submit: 'Einreichen',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          agileCrmDomain: 'Agile CRM -Domäne',
          nameIsRequired: 'Name ist erforderlich',
          emailAddress: 'E-Mail-Addresse',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameter: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'Beispiel@company.extention',
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          accessKey: 'Zugangsschlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiKeyHere: 'Geben Sie hier den API -Schlüssel ein',
            enterAccessKeyHere: 'Geben Sie hier den Zugriffsteil ein',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          nameColon: 'Name:',
          inboundWebhookUrl: 'Inbound Webhook URL',
          nameIsRequired: 'Name ist erforderlich',
          leadTitle: 'Leadtitel',
          source: 'Quelle',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterInboundWebhookUrl: 'Geben Sie in eingehende Webhook -URL ein ...',
            enterTitle: 'Titel eingeben',
            enterSource: 'Quelle eingeben',
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          testLead: 'Messleitung',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          testLead: 'Messleitung',
          connect: 'Verbinden',
          selectTheQuestion: 'Wählen Sie die Frage aus, deren Benutzeraktion Sie als Wert senden möchten',
          mapWith: 'Karte mit',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
            attributes: 'Attribute (Schlüssel)',
            values: 'Werte',
          }
        },
        chatGpt: {
          chatGptApiKey: 'CHATGPT (Open AI) API -Schlüssel',
          chatGpt: 'Chatgpt (Öffnen Sie AI)',
          readyToRideWave: 'Bereit, die GPT -Welle zu fahren? Integrieren Sie jetzt.',
          doNotHaveAnyApiKeyNeedHelp: 'Haben Sie keinen API -Schlüssel, brauchen Sie Hilfe?',
          authenticate: 'Authentifizieren',
          nowYouCanAccessChatGpt: 'Jetzt können Sie im Chat Flow Builder auf die Chat -GPT -Komponente zugreifen',
          paymentSuccessful: 'Bezahlung erfolgreich!',
          paymentHasBeenCompletedSuccessfully: 'Die Zahlung wurde erfolgreich abgeschlossen. Sie sind bereit, Ihren Bot zu trainieren',
          cancel: 'Stornieren',
          trainNow: 'Trainieren Sie jetzt',
          paymentInProgress: 'Zahlung in Arbeit ...',
          apiKey: 'ChatGPT API -Schlüssel',
          model: 'Modell',
          integrateWithChatGPT: 'Integrieren mit ChatGPT',
          updateKey: 'API -Schlüssel aktualisieren',
          connect: 'Verbinden',
          update: 'Aktualisieren',
          placeholders: {
            chatGptApiKey: 'CHATGPT (Open AI) API -Schlüssel',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          leadId: 'Lead ID',
          selectLeadId: 'Wählen Sie LeadID',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          emailAddress: 'E-Mail-Addresse',
          leadTitle: 'Leadtitel',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            exampleEmail: 'Beispiel@company.extention',
            enterYourLeadTitle: 'Geben Sie Ihren Lead -Titel ein',
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          dripCrmAccountId: 'DRIP CRM -Konto -ID',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterAccountIdHere: 'Geben Sie hier die Konto -ID ein',
            enterYourTokenHere: 'Geben Sie hier Ihr Token ein',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apikey: 'API-Schlüssel',
          accessToken: 'Zugangstoken',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          selectParameter: 'Wählen Sie Parameter',
          disconnect: 'Trennen',
          update: 'Aktualisieren',
          save: 'Speichern',
          placeholders: {
            enterApiKeyHere: 'Geben Sie hier den API -Schlüssel ein',
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          testLead: 'Messleitung',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          authToken: 'AuthToken',
          source: 'Quelle',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterAuthTokenHere: 'Geben Sie hier authToken ein',
            enterSourceHere: 'Geben Sie die Quelle hier ein',
          }
        },
        facebookPixel: {
          facebookPixel: 'Facebook-Pixel',
          getInsight: `Erhalten Sie Einblicke, die Ihr Unternehmen mit der Facebook Pixel Integration von BotPenguin in ein Unternehmen verwandeln.`,
          pixelId: 'Facebook-Pixel-ID',
          connect: 'verbinden',
          disconnect: 'trennen',
          placeholders: {
            enterYourId: 'Facebook-Pixel-ID'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          companyName: 'Name der Firma',
          emailAddress: 'E-Mail-Addresse',
          password: 'Passwort',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapsWith: 'Karten mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterCompanyName: 'Firmennamen eingeben',
            enterEmailAddress: 'E-Mail Adresse eingeben',
            enterPassword: 'Passwort eingeben',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiKeyHere: 'Geben Sie hier Apikey ein',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          flowluDomain: 'Flowlu -Domäne',
          apiToken: 'API -Token',
          title: 'Titel',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'Geben Sie hier Token ein',
            enterYourLeadTitle: 'Geben Sie Ihren Lead -Titel ein',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          freshdeskDomain: 'Freshdesk Domain',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          freshworkCrmDomain: 'Freshwork CRM Domain',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshWorks.com',
            enterToken: 'Geben Sie hier Token ein',
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          mapWith: 'Karte mit',
          select: 'Wählen',
          update: 'Aktualisieren',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          campaignList: 'Kampagnenliste',
          selectCampaign: 'Kampagne auswählen',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          measurementId: 'Mess -ID',
          connect: 'Verbinden',
          disconnect: 'Trennen',
          placeholders: {
            enterYourId: 'Geben Sie Ihre ID ein',
          }
        },
        googleCalendar: {
          testLead: 'Messleitung',
          name: 'Name:',
          email: 'Email:',
          appointmentSummary: 'Terminzusammenfassung',
          appointmentDescription: 'Terminbeschreibung',
          addParameters: ' Parameter hinzufügen',
          questionColon: 'Frage:',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          pause: 'Pause',
          resume: 'Fortsetzen',
          disconnect: 'Trennen',
          reconnect: 'Wieder verbinden',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          name: 'Name :',
          email: 'Email :',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
        },
        googleRedirect: {
        },
        googleSheet: {
          testLead: 'Messleitung',
          title: 'Titel',
          questionColon: 'Frage:',
          addParameters: 'Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          update: 'Aktualisieren',
          save: 'Speichern',
          pause: 'Pause',
          resume: 'Fortsetzen',
          disconnect: 'Trennen',
          reconnect: 'Wieder verbinden',
          placeholders: {
            mappingValues: 'Mapping -Werte',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          name: 'Name:',
          email: 'Email:',
          taskList: 'Aufgabenliste',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          pause: 'Pause',
          resume: 'Fortsetzen',
          disconnect: 'Trennen',
          reconnect: 'Wieder verbinden',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          select: 'Wählen',
          addParameters: ' Parameter hinzufügen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiHere: 'Geben Sie hier die API ein',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          testLead: 'Messleitung',
          accountId: 'Konto -ID',
          accessToken: 'Zugangstoken',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterAccountIdHere: 'Geben Sie hier Account-ID ein',
            enterAccessTokenHere: 'Geben Sie hier Access -Token ein',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API-Schlüssel',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          testLead: 'Messleitung',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        helpLink: {
          help: 'Hilfe',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          helpRaceCrmDomain: 'Helprace CRM Domain',
          emailAddress: 'E-Mail-Addresse',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          update: 'Aktualisieren',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'Beispiel@company.extension',
            token: 'Zeichen',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          domainName: 'Domain -Name',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiHere: 'Geben Sie hier die API ein',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          emailAddress: 'E-Mail-Addresse',
          apiUrl: 'API URL',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          // emailAddress: 'E-Mail-Addresse',
          selectProjectName: 'Wählen Sie den Projektnamen',
          selectIssueType: 'Wählen Sie den Ausgabetyp',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapsWith: 'Karten mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
            enterEmailAddress: 'E-Mail Adresse eingeben',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiUrl: 'API URL',
          nameIsRequired: 'Name ist erforderlich',
          apiKey: 'API-Schlüssel',
          apiId: 'API -ID',
          // nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          mapWith: 'Karte mit',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          selectTheQuestion: 'Wählen Sie die Frage aus, deren Benutzeraktion Sie als Wert senden möchten',
          placeholders: {
            enterApiUrl: 'Geben Sie die API -URL ein',
            enterApiKey: 'Geben Sie die API -Taste ein',
            enterApiId: 'Geben Sie die API -ID ein',
            attributes: 'Attribute (Schlüssel)',
            values: 'Werte',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          accessKey: 'Zugangsschlüssel',
          secretKey: 'Geheimer Schlüssel',
          connect: 'Verbinden',
          apiLogs: 'API -Protokolle',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          udpate: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          filterApplied: 'Filter angewendet',
          retry: 'Wiederholen',
          back: 'Zurück',
          filterYourLeads: 'Filtern Sie Ihre Leads',
          filterOn: 'Filter auf',
          filterBy: 'Filtern nach',
          discard: 'Verwerfen',
          applyFilter: 'Filter anwenden',
          loadMore: 'Mehr laden',
          placeholders: {
            enterAccessKeyHere: 'Geben Sie hier den Zugriffsteil ein',
            enterSecretKeyHere: 'Geben Sie hier den Secret -Schlüssel ein',
            attributes: 'Attribute (Schlüssel)',
            values: 'Werte',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Filterdaten basierend auf der Antwort',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          apiUrl: 'API URL',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiHere: 'Geben Sie hier die API ein',
            exampleUrl: 'https://comapanyname.ladesk.com',
          }
        },
        microsoftTeams: {
          downloadPackage: 'Laden Sie das Microsoft Teams App -Paket herunter',
          downloadApp: 'Lade App herunter',
          wantToLearnHowToUploadIt: 'Möchten Sie lernen, wie man es hochladen?',
          needHelp: 'Brauchen Sie Hilfe?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiUrl: 'API URL',
          appId: 'App ID',
          nameIsRequired: 'App -ID ist erforderlich',
          dataApiKey: 'Daten -API -Schlüssel',
          dataApiKeyIsRequired: 'Daten -API -Schlüssel ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          addParameters: ' Parameter hinzufügen',
          mapWith: 'Karte mit',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          selectTheQuestion: 'Wählen Sie die Frage aus, deren Benutzeraktion Sie als Wert senden möchten',
          placeholders: {
            enterApiUrl: 'Geben Sie die API -URL ein',
            enterAppId: 'Geben Sie die App -ID ein',
            enterDataApiKey: 'Geben Sie die Daten -API -Taste ein',
            attributes: 'Attribute (Schlüssel)',
            pressForMappingQuestion: 'Drücken Sie / zur Zuordnung von Frage',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          emailAddress: 'E-Mail-Addresse',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          leadId: 'Lead ID',
          selectLeadId: 'Wählen Sie LeadID',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterEmailHere: 'Geben Sie hier eine E -Mail ein',
            enterApiKeyHere: 'Geben Sie hier Apikey ein',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          noCrmDomain: 'NOCRM -Domäne',
          leadTitle: 'Leadtitel',
          titleIsRequired: 'Der Titel ist erforderlich',
          token: 'Zeichen',
          tokenIsRequired: 'Token ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            exampleDomain: 'https://your_subdomain_here.nocrm.io',
            enterYourLeadTitle: 'Geben Sie Ihren Lead -Titel ein',
            enterYourToken: 'Geben Sie Ihr Token ein',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          emailAddress: 'E-Mail-Addresse',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          secretToken: 'Geheimnis Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          appId: 'App ID',
          appIdIsRequired: 'App -ID ist erforderlich',
          apiKey: 'API-Schlüssel',
          apiKeyIsRequired: 'API -Schlüssel ist erforderlich',
          connect: 'Verbinden',
          update: 'Aktualisieren',
          disconnect: 'Trennen',
          placeholders: {
            appId: 'App ID',
            apiKey: 'API-Schlüssel',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          clientId: 'Kunden ID',
          connect: 'Verbinden',
          clientSecret: 'Kundengeheimnis',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          save: 'Speichern',
          update: 'Aktualisieren',
          disconnect: 'Trennen',
          placeholders: {
            enterClientIdHere: 'Geben Sie hier die Client -ID ein',
            enterClientSecretHere: 'Geben Sie hier das Client -Geheimnis ein',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          pipedriverDomain: 'Pipedriver -Domäne',
          domainIsRequired: 'Domäne ist erforderlich',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          apiKeyIsRequired: 'API -Schlüssel ist erforderlich',
          appKey: 'App -Schlüssel',
          appKeyIsRequired: 'App -Schlüssel ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiKey: 'Geben Sie die API -Taste ein',
            enterAppKey: 'Geben Sie die App -Taste ein',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          authId: 'Auth id',
          authToken: 'Auth Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiHere: 'Geben Sie hier die API ein',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiKey: 'Geben Sie die API -Taste ein',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          domain: 'Domain',
          clientId: 'Kunden ID',
          clientSecret: 'Kundengeheimnis',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterDomainHere: 'Geben Sie hier die Domäne ein',
            enterClientIdHere: 'Geben Sie hier die Client -ID ein',
            enterClientSecretHere: 'Geben Sie hier das Client -Geheimnis ein',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          addEventsAndTemplates: 'Fügen Sie Ereignisse und Vorlagen hinzu:',
          add: ' Hinzufügen',
          question: 'Frage',
          reply: 'Antwort',
          requestBody: 'Körperbefragung',
          validateJson: 'JSON validieren',
          emailAddress: 'E-Mail-Addresse',
          // addParameters: ' Parameter hinzufügen',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          selectTheQuestion: 'Wählen Sie die Frage aus, deren Benutzeraktion Sie als Wert senden möchten',
          placeholders: {
            enterApiHere: 'Geben Sie hier die API ein',
            enterTemplateId: 'Geben Sie die Vorlagen -ID ein',
            typeYourJsonCodeHere: 'Geben Sie hier Ihren JSON -Code ein',
          },
          appTooltip: {
            typeYourJsonCode: 'Geben Sie Ihren JSON -Code ein',
            clickToValidateJsonCode: 'Klicken Sie hier, um den JSON -Code zu validieren',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          userName: 'Nutzername',
          licenseKey: 'Lizenzschlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterNameHere: 'Namen hier eingeben',
            enterKeyHere: 'Geben Sie hier den Schlüssel ein',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben die Integration erfolgreich erstellt. Jetzt können Sie sie konfigurieren.',
          company: 'Unternehmen',
          loginId: 'Login-ID',
          password: 'Passwort',
          testLead: 'Messleitung',
          connect: 'Verbinden',
          selectTheQuestion: 'Wählen Sie die Frage aus, deren Benutzerantwort Sie als Wert senden möchten',
          mapWith: 'Zuordnen mit',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          selectParameter: 'Wählen Sie Parameter',
          placeholders: {
            enterCompanyHere: 'Geben Sie hier den Unternehmensnamen ein',
            enterLoginIdHere: 'Geben Sie hier die Login-ID ein',
            enterPasswordHere: 'Geben Sie hier das Passwort ein',
            values: 'Werte',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          applicationKey: 'Anwendungsschlüssel',
          authToken: 'Auth Token',
          connect: 'Verbinden',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApplicationKeyHere: 'Geben Sie hier den Anwendungsschlüssel ein',
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          accessToken: 'ACESS -Token',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          question: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          webhoperDomain: 'Weboper -Domain',
          domainIsRequired: 'Domäne ist erforderlich',
          companyName: 'Name der Firma',
          companyIsRequired: 'Firma ist erforderlich',
          leadSource: 'Hauptquelle',
          leadSourceIsRequired: 'Leitquelle ist erforderlich',
          division: 'Aufteilung',
          divisionIsRequired: 'Division ist erforderlich',
          enterPassword: 'Passwort eingeben',
          passwordIsRequired: 'Passwort wird benötigt',
          disconnect: 'Trennen',
          update: 'Aktualisieren',
          connect: 'Verbinden',
          placeholders: {
            exampleDomain: 'http: // companydomain',
            enterYourCompanyName: 'Geben Sie Ihren Firmennamen ein',
            enterLeadSource: 'Geben Sie die Lead -Quelle ein',
            enterDivision: 'Division eingeben',
            enterPassword: 'Passwort eingeben',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          name: 'Name:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'Inbound Webhook URL',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterWebhookUrl: 'Geben Sie die Webhook -URL ein',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          emailAddress: 'E-Mail-Addresse',
          apiUrl: 'API URL',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterEmailAddress: 'E-Mail Adresse eingeben',
            enterUrlHere: 'Geben Sie hier URL ein',
            enterTokenHere: 'Geben Sie hier Token ein',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiKey: 'API-Schlüssel',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiHere: 'Geben Sie hier die API ein',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          connect: 'Verbinden',
          update: 'Aktualisieren',
          disconnect: 'Trennen',
          companyName: 'Name der Firma',
          emailAddress: 'E-Mail-Addresse',
          phone: 'Telefon',
          countryCode: 'Landesvorwahl',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          // update: 'Aktualisieren',
          save: 'Speichern',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          connect: 'Verbinden',
          disconnect: 'Trennen',
          testLead: 'Messleitung',
          emailAddress: 'E-Mail-Addresse',
          selectOrganizationName: 'Wählen Sie den Organisationsnamen',
          selectDepartmentName: 'Wählen Sie Abteilungsname',
          addParameters: ' Parameter hinzufügen',
          questionColon: 'Frage:',
          select: 'Wählen',
          mapsWith: 'Karten mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          testLead: 'Messleitung',
          connect: 'Verbinden',
          disconnect: 'Trennen',
          projectsList: 'Projektliste',
          selectProjectsName: 'Wählen Sie Projektnamen',
          projectsTaskList: 'Projekte Taskslist',
          selectTaskListName: 'Wählen Sie den Namen der Taskliste',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
        },
        wooCommerce: {
          configure: 'Konfigurieren',
          automatedMessage: 'Automatisierte Nachricht',
          configureTab: {
            wooCommercePluginApiKey: 'WooCommerce Plugin API-Schlüssel',
            downloadWooCommercePlugin: 'WooCommerce Plugin herunterladen'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Verlassene Warenkorb-Wiederherstellungsnachricht',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Konfigurieren Sie automatisierte Wiederherstellungsnachrichten, um Kunden zurückzugewinnen, die ihren Warenkorb verlassen haben',
            enableAbandonedCartRecoveryMessaegs: 'Verlassene Warenkorb-Wiederherstellungsnachrichten aktivieren',
            selectTemplate: 'Vorlage auswählen',
            sendMessageAfter: 'Nachricht senden nach',
            orderFulfillMent: 'Auftragsabwicklung',
            templateHeader: 'Vorlagenheader',
            selectFile: 'Datei aussuchen',
            templateMessage: 'Vorlagen-Nachricht',
            saveChanges: 'Änderungen speichern',
            orderCompleted: 'Bestellung abgeschlossen',
            enableOrderConfirmationMessages: 'Bestellbestätigungsnachrichten aktivieren',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Senden Sie eine Bestellbestätigungsnachricht, sobald ein Kunde eine Bestellung aufgibt.',
            // tslint:disable-next-line: max-line-length
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: `Die Bestellversandnachricht wird gesendet, wenn Sie eine Bestellung erfüllen.`,
            enableOrderFulfillmentMessages: 'Bestellversandnachrichten aktivieren',
            returnExchangeRequest: 'Rückgabe-/Umtauschanfrage',
            theMessageIsSentAfterARefundIsRequested: 'Die Nachricht wird gesendet, nachdem eine Rückerstattung beantragt wurde.',
            enableOrderRefundMessages: 'Bestellrückerstattungsnachrichten aktivieren',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
          apiToken: 'API -Token',
          nameIsRequired: 'Name ist erforderlich',
          connect: 'Verbinden',
          testLead: 'Messleitung',
          questionColon: 'Frage:',
          addParameters: ' Parameter hinzufügen',
          select: 'Wählen',
          mapWith: 'Karte mit',
          selectParameter: 'Wählen Sie Parameter',
          update: 'Aktualisieren',
          save: 'Speichern',
          disconnect: 'Trennen',
          placeholders: {
            enterApiToken: 'Geben Sie das API -Token ein',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Neue Integration erstellen',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'Sie haben ab sofort kein Integrationsaufbau.',
      pleaseCreateOneToIntegrateYourFavourite: 'Bitte erstellen Sie einen, um Ihren Favoriten zu integrieren',
      appsWith: 'Apps mit',
      integrationName: 'Integrationsname',
      integrationNameIsRequired: 'Integrationsname ist erforderlich',
      continue: 'Weitermachen',
      addAnImageToRepresentYourIntegration: 'Fügen Sie ein Bild hinzu, um Ihre Integration darzustellen.',
      description: 'Beschreibung',
      submit: 'Einreichen',
      youHaveSuccessfullyCreatedIntegration: 'Sie haben erfolgreich Integration erstellt. Jetzt können Sie es konfigurieren.',
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: 'Bitte geben Sie eine gültige API -URL ein',
      requestMethod: 'Anforderungsmethode',
      get: 'Erhalten',
      post: 'Post',
      put: 'Setzen',
      patch: 'Patch',
      queryParameter: 'Abfrageparameter',
      addParameter: ' Parameter hinzufügen',
      mapsWith: ' Karten mit',
      question: 'Frage',
      addQuestion: ' Frage hinzufügen',
      select: 'Wählen',
      selectParameter: 'Wählen Sie Parameter',
      header: 'Header',
      addHeader: ' Header hinzufügen',
      value: 'Wert',
      connect: 'Verbinden',
      requestBody: 'Körperbefragung',
      validate: 'Bestätigen',
      // select: 'Wählen',
      key: 'Taste',
      // value: 'Wert',
      // connect: 'Verbinden',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' kann auch in dieses kostenlose CRM integriert werden.',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: 'Bitte geben Sie eine gültige API -URL ein',
        requestMethod: 'Anforderungsmethode:',
        get: 'Erhalten',
        post: 'Post',
        put: 'Setzen',
        patch: 'Patch',
        queryParameter: 'Abfrageparameter',
        addParameter: ' Parameter hinzufügen',
        header: 'Header',
        addHeader: ' Header hinzufügen',
        requestBody: 'Körperbefragung',
        validateJson: 'JSON validieren',
        connect: 'Verbinden',
        update: 'Aktualisieren',
        delete: 'Löschen',
        selectTheQuestion: 'Wählen Sie die Frage aus, deren Benutzeraktion Sie als Wert senden möchten',
        appTooltip: {
          typeYourJsonCode: 'Geben Sie Ihren JSON -Code ein',
          clickToValidateJsonCode: 'Klicken Sie hier, um den JSON -Code zu validieren',
        }
      },
      list: {
        customAppsIntegrations: 'Benutzerdefinierte Apps Integrationen',
        integrateYourAppsWith: 'Integrieren Sie Ihre eigenen Apps in',
        help: 'Hilfe',
        addIntegration: ' Integration hinzufügen',
        name: 'Name',
        status: 'Status',
        action: 'Aktion',
        insatlled: 'Eingerichtet',
        edit: 'Bearbeiten',
        connect: 'Verbinden',
        requestYourIntegration: 'Fordern Sie Ihre Integration an',
        toRequestNewIntegration: 'Um eine neue Integration anzufordern, geben Sie die folgenden genannten Details ein und dann werden wir uns bei Ihnen melden',
        // name: 'Name',
        emailId: 'E -Mail -ID',
        integrationName: 'Integrationsname',
        contactNumber: 'Kontakt Nummer',
        howSoonYouWantToAddThisIntegration: 'Wie schnell möchten Sie diese Integration hinzufügen',
        sendRequest: 'Anfrage senden',
        cancel: 'Stornieren',
        delete: 'Löschen',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Oops! Deine eigenen Integrationen werden nur in unterstützt',
        plan: 'Plan',
        pleaseUpgradeTo: 'Bitte erweitern Sie auf',
        toCreateOwnIntegrations: 'um eigene Integrationen zu erstellen',
        appTooltip: {
          integrations: 'Integrationen',
          noIntegrationsFound: 'Keine Integrationen gefunden!',
          nameOfYourApp: 'Name Ihrer App',
          clickToConnectApp: 'Klicken Sie hier, um die App zu verbinden',
          statusAsConnectedOrNotConnected: 'Status als verbunden / nicht verbunden',
        }
      }
    }
  }
};
