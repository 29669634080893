export const whitelabel = {
  plans: 'Plans',
  managePlan: 'Manage Plans',
  whitelabelCharges: 'Whitelabel Charges',
  chargesOrTaxesConfiguration: 'Charges/Taxes Configuration',
  websitePlans: 'Website Plans',
  whatsappPlansHeader: 'Whatsapp Plans',
  addOnPlans: {
    createAddOnPlans: 'Create Add-On plans',
    checkoutYourCurrentMessage: 'Check out your current message Add-On Plans and new plans for your customers',
    choosePlanValidity: 'Choose Plan Validity',
    chooseValidity: 'Choose Validity',
    oneYear: 'One Year',
    billingCycle: 'Billing Cycle',
    numberOfMessages: 'Number of Messages',
    pleaseEnterNumberOfMessages: 'Please enter number of messages',
    setCurrency: 'Set Currency',
    select: 'Select',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Clear all',
    apply: 'Apply',
    currency: 'Currency',
    amount: 'Amount',
    validity: 'Validity',
    upgradePlan: 'Update Plan',
    addPlan: 'Add Plan',
    serialNumber: 'Sr. No.',
    messages: 'Messages',
    // validity: 'Validity',
    active: 'Active',
    priceDetails: 'Price Details',
    action: 'Action',
    addOnChanges: 'Add-On Charges: ',
    // messages: ' Messages',
    perYear: 'per Year',
    taxes: 'Taxes',
    placeholders: {
      enterNumberOfMesasges: 'Enter number of messages',
      enterAmount: 'Enter Amount',
      enterValidity: 'Enter Validity',
      searchByMessages: 'Search by Messages'
    },
    modal: {
      deleteAddOnsPlan: 'Delete Add-ons Plan',
      wantToDeleteThisAddOnsPlan: 'Are you sure you want to delete this Add-ons Plan?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Add WhiteLabel Charges',
    checkoutExistingCharges: 'Check out existing charges on different payment methods and add new whitelabel charges',
    currency: 'Currency',
    chooseCurrency: 'Choose Currency',
    amountPerBotPerMonth: 'Amount per bot per month',
    cancel: 'Cancel',
    upgradeChanges: 'Update Charges',
    addCharges: 'Add Charges',
    serialNumber: 'Sr. No.',
    // currency: 'Currency',
    action: 'Action',
    taxes: 'Taxes',
    placeholders: {
      enterAmountBotPerMonth: 'Enter amount bot per month',
      searchByCurrency: 'Search by Currency',
    },
    modal: {
      deleteCharges: 'Delete Charges',
      wantToDeleteThisCharge: 'Are you sure you want to delete this Charge?',
    }
  },
  planManagement: {
    createNewPlans: 'Create new plans',
    checkoutYourCurrentPlan: 'Check out your current plans and add new plans for your Customers',
    choosePlan: 'Choose Plan',
    selectPlan: 'Select Plan',
    baby: 'Baby',
    king: 'King',
    displayName: 'Display Name',
    messageLimit: 'Message Limit',
    enterAValidEmail: 'Enter a valid email',
    setCurrency: 'Set Currency',
    select: 'Select',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Clear all',
    apply: 'Apply',
    perMonth: '/Month',
    perYear: '/Year',
    cancel: 'Cancel',
    upgradePlan: 'Update Plan',
    addPlan: 'Add Plan',
    serialNumber: 'Sr. No.',
    plan: ' Plan',
    // displayName: 'Display Name',
    messages: 'Messages',
    pricing: 'Pricing',
    actions: 'Actions',
    // plan: ' Plan',
    perMonthText: 'Per Month',
    perYearText: 'Per Year',
    placeholders: {
      enterDisplayName: 'Enter display name',
      enterMessageLimit: 'Enter message limit',
      searchByPlanName: 'Search by Plan Name or Messages',
    },
    modal: {
      deletePlan: 'Delete Plan',
      wantToDeleteThisBabyPlan : 'Deleting the Baby Plan will have an impact on all Customers. Are you sure you want to delete this plan?',
      wantToDeleteThisPlan: 'Are you sure you want to delete this plan?'
    },
    appTooltip: {
      chooseYourPlanType: 'Choose you plan type',
      pleaseInputYourPlanDisplayName: 'Please input your plan display name',
      typeMessageLimitForCustomer: 'Type message limit for customer',
      selectYourPreferredCurrencies: 'Select your preferred Currencies',
    }
  },
  taxesConfiguration: {
    addTaxes: 'Add Taxes',
    checkoutExistingTaxes: 'Check out existing taxes on different payment methods and new taxes',
    taxName: 'Tax Name',
    applicableOn: 'Applicable on',
    choosePlans: 'Choose Plans',
    chooseCurrency: 'Choose Currency',
    percentage: 'Percentage',
    cancel: 'Cancel',
    // addTaxes: 'Add Taxes',
    serialNumber: 'Sr. No.',
    name: 'name',
    // percentage: 'Percentage',
    currency: 'Currency',
    updateTaxes: 'Update Taxes',
    actions: 'Actions',
    modal: {
      deleteTaxes: 'Delete Taxes',
      wantToDeleteThisTax: 'Are you sure you want to delete this taxes?'
    },
    placeholders: {
      taxName: 'Tax Name',
      taxPercentage: 'Tax Percentage',
      searchByCurrency: 'Search by Currency',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Create new plans',
    checkoutYourCurrentAndNewPlans: 'Check out your current plans and add new plans for your Customers',
    choosePlan: 'Choose Plan',
    selectPlan: 'Select Plan',
    baby: 'Baby',
    king: 'King',
    displayName: 'Display Name',
    messageLimit: 'Conversation Limit',
    enterAValidMessageLimit: 'Enter a valid conversation limit',
    setCurrency: 'Set Currency',
    select: 'Select',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Clear all',
    apply: 'Apply',
    perMonth: '/Month',
    perYear: '/Year',
    cancel: 'Cancel',
    addPlan: 'Add Plan',
    serialNumber: 'Sr. No.',
    plan: 'Plan',
    messages: 'Conversations',
    pricing: 'Pricing',
    actions: 'Actions',
    perMonthText: 'Per Month',
    perYearText: 'Per Year',
    updatePlan: 'Update Plan',
    // addPlan: 'Add Plan',
    placeholders: {
      enterDisplayName: 'Enter display name',
      enterMessageLimit: 'Enter conversation limit',
      searchByPlanName: 'Search by Plan Name or Conversations',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Type conversation limit for customer',
      chooseYourPlanType: 'Choose you plan type',
      selectYourPreferredCurrencies: 'Select your preferred Currencies',
      pleaseInputYourPlanDisplayName: 'Please input your plan display name',
    },
    modal: {
      deletePlan: 'Delete Plan',
      areYouSureYouWantToDeleteThisPlan: 'Are you sure you want to delete this plan?',
      wantToDeleteThisBabyPlan : 'Deleting the Baby Plan will have an impact on all Customers. Are you sure you want to delete this plan?'
    }
  }
};
