export const notification = {
  notification: 'Notificações',
  allNotifications: 'Todas as notificações',
  general: 'Em geral',
  settings: 'Configurações',
  newChatRequest: {
    youHaveANewChatRequest: 'Você tem um novo pedido de bate -papo !!!',
    youHaveTransferredChatRequest: 'Você transferiu o pedido de bate -papo !!!',
    acceptRequest: 'Aceitar pedido',
    discardRequest: 'Descartar solicitação',
  },
  notificationList: {
    name: 'Nome',
    message: 'Mensagem',
    noDataFound: {
      notification: 'Notificação',
      noNotificationsFound: 'Nenhuma notificação encontrada!',
    }
  },
  notificationSetting: {
    userMessage: 'Mensagem do usuário',
    receiveNotificationsWhenUserSendMessage: 'Receba notificações sempre que qualquer usuário envia alguma mensagem',
    liveChatRequest: 'Solicitação de bate -papo ao vivo',
    receiveNotificationsWhenUserRequestLiveChat: 'Receba notificações sempre que qualquer solicitação de usuário para bate -papo ao vivo',
    newLead: 'Novo líder',
    receiveNotificationsWhenNewLeadCreated: 'Receba notificações sempre que novos leads são criados',
    assignedOnly: 'Apenas atribuído',
    allUsers: 'Todos os usuários',
    customizeWhichNotificationsYouWantToRecieve: 'Personalize quais notificações você deseja receber',
    youCanCustomizeSettingForTheNotifications: 'Você pode personalizar a configuração das notificações',
    receiveNotifications: 'Receber notificações',
    desktopNotifications: 'Notificações da área de trabalho',
    mobilePushNotifications: 'Notificações de push móvel',
    discard: 'Descartar',
    update: 'Atualizar',
    appTooltip: {
      TOOLTIP_NEW_MESSAGE_DESKTOP_NOTIFICATION: 'Envie notificações de nova mensagem sobre o desktop',
      TOOLTIP_NEW_MESSAGE_MOBILE_NOTIFICATION: 'Envie notificações de nova mensagem sobre o celular',
      TOOLTIP_NEW_LEAD_DESKTOP_NOTIFICATION: 'Envie notificações de novo chumbo sobre o desktop',
      TOOLTIP_NEW_LEAD_MOBILE_NOTIFICATION: 'Envie notificações de novo lead em celular',
      TOOLTIP_LIVE_CHAT_DESKTOP_NOTIFICATION: 'Envie notificações de bate -papo ao vivo sobre o desktop',
      TOOLTIP_LIVE_CHAT_MOBILE_NOTIFICATION: 'Envie notificações de bate -papo ao vivo sobre celular',
      TOOLTIP_RECEIVED_NOTIFICATION: 'Escolha para quais mensagens você deseja ser notificado',
    }
  }
};
