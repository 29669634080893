import {createAction, props} from '@ngrx/store';

export const loadSocialSubscribers = createAction(
  '[Subscriber] Load Social Subscribers',
  props<{ subscribers: any[] }>()
);
export const addSocialSubscriber = createAction(
  '[Subscriber] Add Social Subscriber',
  props<{ subscriber: any }>()
);
export const deleteSocialSubscriber = createAction(
  '[Subscriber] Delete Social Subscriber',
  props<{ subscriber: string }>()
);
export const modifySocialSubscriber = createAction(
  '[Subscriber] Modify Social Subscriber',
  props<{ subscriber: string, update: any }>()
);





