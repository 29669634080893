export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'BotPenguinへようこそ！',
      login: 'ログイン',
      youAreAllSet: '設定が完了しました！リード詳細ページからWhatsAppの受信ボックスを使用できます。',
      placeholders: {
        enterEmail: 'メールアドレスを入力してください',
        enterPassword: 'パスワードを入力してください'
      }
    }
  }
};
