export const developer = {
  developers: 'Développeurs',
  enpowerYourDevelopersToHarnessTheFullPotential: `Donnez à vos développeurs les moyens d'exploiter pleinement le potentiel de votre tableau de bord`,
  accessToken: `Jeton d'accès`,
  yourAPI: 'Vos API',
  api: {
    apiManagement: 'Gestion des API',
    apiManagementSubHeading: 'Ajoutez, configurez, testez et gérez vos API que vous pouvez déclencher dans vos flux de discussion.',
    addApi: 'Ajouter une API',
    createApi: 'Créer une API',
    noApiFound: 'Aucune API trouvée !',
    deleteApis: 'Supprimer les API',
    areYouSureYouWantToDeleteAPI: 'Êtes-vous sûr de vouloir supprimer les API ?',
    placeholders: {
      search: `Rechercher par nom d'API`
    },
    tableHead: {
      method: 'Méthode',
      apiName: `Nom de l'API`,
      url: 'URL',
      actions: 'Actions'
    },
    parametersTab: 'Paramètres',
    headersTab: 'En-têtes',
    bodyTab: 'Corps',
    manageApiResponses: `Gérer les réponses de l'API`,
    chooseVariableToSave: `Choisir la variable à enregistrer`,
    selectCustomAttribute: `Sélectionner l'attribut personnalisé`,
    subTab: {
      key: 'Clé',
      enterKey: 'Entrer une clé',
      value: 'Valeur',
      enterValue: 'Entrer une valeur',
      addAnotherField: 'Ajouter un autre champ',
      save: 'Enregistrer',
      useslashToInsertCustomVariables: `Utilisez '/' pour insérer des variables personnalisées ou des valeurs dynamiques de la réponse de l'utilisateur.`,
      response: 'Réponse',
      apiRequestBody: 'Corps de la requête API',
      hit: 'Succès',
      test: 'Test',
      buttonToGetAResponse: 'Bouton pour obtenir une réponse',
      variable: 'Variable',
      saveToCustomAttribute: 'Enregistrer dans un attribut personnalisé'
    },
    apiLogs: {
      apiManagement: 'Gestion des API',
      addConfigureTestAndManageYourAPIs: 'Ajoutez, configurez, testez et gérez vos API que vous pouvez déclencher dans vos flux de discussion.',
      searchHere: 'Rechercher par code de statut',
      deleteApis: 'Supprimer les API',
      testAnApi: 'Tester une API',
      noAPILogFound: 'Aucun journal API trouvé',
      responseCode: 'Code de réponse',
      message: 'Message',
      apiResponse: `Réponse de l'API`,
      actions: 'Actions',
      deleteAPIs: 'Supprimer les API',
      areYouSureYouWantToDelete: 'Êtes-vous sûr de vouloir supprimer cette API ?',
      apiLogs: `Journaux de l'API`,
      subTitle: `Enregistrement détaillé de toutes les demandes et réponses API déclenchées dans le flux de chat du chatbot.`
    }
  },
  configApi: {
    configureApi: `Configurer une API`,
    configureApiSubHeading: `Vous pouvez configurer votre API basée sur JSON ici et l'utiliser pour enrichir votre flux de discussion.`,
    apiLogs: `Journaux de l'API`,
    test: `Test`,
    manageApiResponse: `Gérer les réponses de l'API`,
    body: `Corps`,
    headers: `En-têtes`,
    parameters: `Paramètres`,
    placeholders: {
      enterApiName: `Entrer le nom de l'API`,
      enterApiUrl: `Entrer l'URL de l'API`
    }
  }
};

