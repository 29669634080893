import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { Store } from '@ngrx/store';
import { SubscriptionService } from 'src/appv2/services/subscription.service';
import { CommonService } from 'src/appv2/services/common.service';
import { LanguageService } from 'src/appv2/services/language.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  isSideBarCollapsed = false;
  staticText: any;
  sidebar$: Subscription;
  storeSubscription$: Subscription;
  profileType: string;
  planName: string;

  constructor(
    private sharedService: SharedService,
    private subscriptionService: SubscriptionService,
    private store: Store<any>,
    private languageService: LanguageService,
    public commonService: CommonService,
  ) {

    this.staticText = languageService.getStaticText().notification;
    this.sidebar$ = this.sharedService.sideBarStatus.subscribe(value => {
      this.isSideBarCollapsed = value === 'collapsed' ? true : false;
    });
    this.storeSubscription$ = this.store.select(state => state?.profile)
      .subscribe((profile) => {
        this.profileType = profile.type;
        this.planName = profile?.plan?._plan?.name;
      });

  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.sidebar$.unsubscribe();
    this.storeSubscription$.unsubscribe();
  }

  get isBaby() {
    return this.subscriptionService.isBabyPlan;
  }
}
