export const developer = {
  developers: 'Developers',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Empower your developers to harness the full potential of your dashboard',
  accessToken: 'Access Token',
  yourAPI: 'Your APIs',
  api: {
    apiManagement: 'APIs Management',
    apiManagementSubHeading: 'Add, configure, test and manage your APIs which you can use trigger in your chat flows.',
    addApi: 'Add API',
    createApi: 'Create an API',
    noApiFound: 'No API Found!',
    deleteApis: 'Delete APIs',
    areYouSureYouWantToDeleteAPI: `Are you sure you want to delete API's`,
    placeholders: {
      search: `Search by API name`
    },
    tableHead: {
      method: 'Method',
      apiName: 'API Name',
      url: 'URL',
      actions: 'Actions'
    },
    parametersTab: 'Parameters',
    headersTab: 'Headers',
    bodyTab: 'Body Tab',
    manageApiResponses: 'Manage API responses',
    chooseVariableToSave: 'Choose variable to save',
    selectCustomAttribute: 'Select custom attribute',
    subTab: {
      key: 'Key',
      enterKey: 'Enter Key',
      value: 'Value',
      enterValue: 'Enter Value',
      addAnotherField: 'Add another field',
      save: 'Save',
      useslashToInsertCustomVariables: `Use ‘/’ to insert the custom variables or dynamic values from the user’s response.`,
      response: 'Response',
      apiRequestBody: 'API Request Body',
      hit: 'Hit',
      test: 'Test',
      buttonToGetAResponse: 'button to get a response',
      variable: 'Variable',
      saveToCustomAttribute: 'Save to Custom Attribute'
    },
    apiLogs: {
      apiManagement: 'APIs Management',
      addConfigureTestAndManageYourAPIs: 'Add, configure, test and manage your APIs which you can use trigger in your chat flows.',
      searchHere: 'Search by status code',
      deleteApis: 'Delete APIs',
      testAnApi: 'Test an API',
      noAPILogFound: 'No API Log Found',
      responseCode: 'Response Code',
      message: 'Message',
      apiResponse: 'API Response',
      actions: 'Actions',
      deleteAPIs: 'Delete API Logs',
      areYouSureYouWantToDelete: 'Are you sure you want to delete this API Log?',
      apiLogs: 'API Logs',
      subTitle: 'Detailed record of all API requests & responses triggered within the chatflow of the chatbot.'
    }
  },
  configApi: {
    configureApi: `Configure API`,
    configureApiSubHeading: `You can configure your JSON based API here and use in enriching your chat flow`,
    apiLogs: `API Logs`,
    test: `Test`,
    manageApiResponse: `Manage API responses`,
    body: `Body`,
    headers: `Headers`,
    parameters: `Parameters`,
    placeholders: {
      enterApiName: `Enter API Name`,
      enterApiUrl: `Enter API URL`
    }
  }
};
