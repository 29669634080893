export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'Ссылка активации была отправлена, проверьте свою электронную почту',
    weHaveSentActivationLinkToEmailAddress: 'Мы отправили ссылку на активацию на адрес электронной почты',
    toVerifyYourAccount: 'Чтобы проверить свою учетную запись.',
    onceVerifiedAutomaticallyRedirected: 'После проверки вы будете автоматически перенаправлены на панель.',
    didNotReceiveAnEmail: 'Не получили электронное письмо? Проверьте папку со спамом',
    resendIn: 'Повесьте в',
    seconds: ' секунды',
    resend: 'Отправить',
    backToSignUp: 'Вернуться, чтобы зарегистрироваться',
    greatPleaseCheckYourMessage: 'Отлично, пожалуйста, проверьте свою электронную почту',
    weHaveSentEmailTo: 'Мы отправили электронное письмо',
    withALinkToReset: ' С ссылкой для сброса вашей учетной записи. После активации вы сможете завершить создание вашей учетной записи и начать бесплатную пробную версию.',
    resent: 'Возмущаться',
    backToLogin: 'Вернуться на страницу авторизации',
  },
  forgotPassword: {
    forgotPassword: 'Забыли пароль?',
    noWorries: 'Не беспокойся,',
    weWillSendYouResetPasswordInstructions: 'Мы отправим вам инструкцию с сбросом пароля',
    workEmail: 'Рабочая электронная почта',
    continue: 'Продолжать',
    backToLogin: 'Вернуться на страницу авторизации',
    placeholders: {
      enterYourEmail: 'Введите адрес электронной почты',
    }
  },
  logout: {
    logout: 'Выйти',
    wantToLogOut: 'Вы действительно хотите выйти?',
    yesIWillBeBack: 'Да, я вернусь',
    cancel: 'Отмена',
  },
  newPassword: {
    createNewPassword: 'Создайте новый пароль',
    yourNewPasswordMustBeDifferent: 'Ваш новый пароль должен отличаться от ранее используемых паролей.',
    password: 'Пароль',
    confirmPassword: 'Подтвердите пароль',
    setPassword: 'Установка пароля',
    signUp: 'Зарегистрироваться',
    login: 'Авторизоваться',
    backTo: 'Вернуться к',
    setAPassword: 'Установите пароль',
    setNewPasswordToYourAccount: 'Установите новый пароль в свою учетную запись для целей безопасности и конфиденциальности',
    backToSignUp: 'Вернуться, чтобы зарегистрироваться',
    placeholders: {
      enterYourPassword: 'Введите ваш пароль',
    }
  },
  resetPassword: {
    passwordReset: 'Восстановление пароля',
    yourPasswordHasBeenSuccessfullyReset: 'Ваш пароль был успешно сброшен.',
    clickBelowToLogIn: 'Нажмите ниже, чтобы войти в систему',
    continueToLogin: 'Продолжайте войти в систему',
  },
  newSignUp: {
    welcomeBack: 'Добро пожаловать',
    to: 'к',
    logIn: 'Авторизоваться',
    as: 'как',
    withFacebook: 'с Facebook',
    logInWithGoogle: 'Войдите с Google',
    or: 'ИЛИ',
    pleaseFillAValidWorkEmail: 'Пожалуйста, заполните действительное рабочее письмо*',
    workEmailIsRequired: 'Рабочая электронная почта требуется*',
    pleaseFillAValidPassword: 'Пожалуйста, заполните действительный пароль*',
    passwordIsRequired: 'Необходим пароль*',
    rememberMe: 'Запомнить меня',
    forgotPassword: 'Забыли пароль?',
    login: 'Авторизоваться',
    doNotHaveAnAccount: 'Еще не регистрировались?',
    setUpAFreeAccount: 'Настройка бесплатной учетной записи',
    noCreditCardRequired: 'Не требуется кредитная карта',
    cancelAnytime: 'Отменить в любое время',
    signUp: 'Зарегистрироваться',
    signUpWithGoogle: 'Зарегистрируйтесь в Google',
    emailIsAlreadyRegistered: 'Электронная почта уже зарегистрирована у нас, пожалуйста, нажмите «Войти», чтобы продолжить',
    phoneNumberShouldHaveMinimum7Digits: 'Номер телефона должен иметь минимум 7 цифр*',
    phoneNumberIsRequired: 'Требуется номер телефона*',
    passwordMustContain: 'Пароль должен содержать',
    atleast6Characters: 'по крайней мере 6 символов,',
    oneUpperCaseLetter: '1 заглавная буква',
    oneSpecialCharacter: '1 Специальный персонаж*',
    alreadyHaveAccount: 'Уже есть аккаунт?',
    signingUpToBotPenguinIsAConsentToOur: 'Зарегистрироваться на учетную запись Botpenguin - это ваше согласие на наш',
    privacyPolicy: 'политика конфиденциальности',
    termsAndConditions: 'Условия и положения',
    and: ' и',
    english: 'Английский',
    hindi: 'хинди',
    german: 'Немецкий',
    french: 'Французский',
    portuguese: 'Португальский',
    spanish: 'испанский',
    italian: 'Итальянский',
    russian: 'Русский',
    placeholders: {
      workEmail: 'Работать электронное письмо*',
      password: 'Пароль*',
      phone: 'Телефон*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Успешно отписан',
    yourEmail: 'Ваш email ',
    haveBeenSuccessfullyUnsubscribedFrom: ' успешно отписан от',
    backToLogin: 'Вернуться к входу'
  }
};
