import { Action, createReducer, on } from '@ngrx/store';
import { loadOwnLiveChats, loadMoreOwnLiveChats, removeOwnLiveChats } from '../actions/own-live-chat.action';
import OwnLiveChat from 'src/appv2/models/own-live-chat';

export const initialState: OwnLiveChat[] = [];

const ownLiveChatReducer = createReducer(
    initialState,
    on(loadOwnLiveChats, (state, { ownLiveChats }) => ownLiveChats),
    on(loadMoreOwnLiveChats, (state, { ownLiveChats }) => [...state, ...ownLiveChats]),
    on(removeOwnLiveChats, (state, { uuid }) => state.filter(elem => elem.uuid !== uuid))
);
export function reducer(state: OwnLiveChat[], action: Action) {
    return ownLiveChatReducer(state, action);
}
