import { Injectable } from '@angular/core';
import { Call, Device } from '@twilio/voice-sdk';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from '../modules/shared/shared.service';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  caller: { name?: string, phone: string, dialCode: string } = { name: '', dialCode: '', phone: '' };
  call: Call;
  device: Device;
  isCallOngoing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dialCode = '+91';

  constructor(
    private networkService: NetworkService,
    private sharedService: SharedService
  ) { }

  getTwilioToken() {
    return this.networkService.httpRequest('/integrations/twilio/token', undefined, 'GET');
  }

  setCaller(meta: any) {
    this.caller = { name: meta.name, phone: meta.phone, dialCode: meta.dialCode || this.dialCode };
  }

  async callTwilio() {
    if (!this.caller.dialCode) {
      return this.sharedService.showToast('warning', '', 'Dial code is required');
    }

    try {
      // this.ngxService.start();

      const token = await this.getTwilioToken();
      if (!token) {

        return this.sharedService.showToast('warning', '', 'Error In Twilio Authentication');
      }

      this.device = new Device(String(token));

      this.device.on('error', (twilioError, call) => {

        this.sharedService.showToast('warning', '', 'Invalid Twilio Credentials');
        this.disconnectTwilio('Invalid Twilio Credentials');
      });

      this.device.on('unregistered', call => {

        this.sharedService.showToast('warning', '', 'Invalid Twilio Credentials');
        this.disconnectTwilio('Invalid Twilio Credentials');
      });

      this.device.on('disconnect', (call) => {

        this.sharedService.showToast('warning', '', 'Twilio Device Disconnected!');
      });

      this.device.on('tokenWillExpire', async () => {

        const newToken = await this.getTwilioToken();
        this.device.updateToken(String(newToken));
      });
      // this.ngxService.start();

      // this.isCallOngoing.next(true);
      // console.log(`+${this.caller.dialCode}${this.caller.phone}`);
      this.call = await this.device.connect({
        params: {
          To: `+${this.caller.dialCode}${this.caller.phone}`
        }
      });

      this.isCallOngoing.next(true);

      this.call.on('cancel', () => {

        this.disconnectTwilio('Call Cancelled');
      });

      this.call.on('disconnect', call => {

        this.disconnectTwilio('Call Disconnected');
      });

      this.call.on('error', (error) => {
        // console.log('this.call.on error', error);
        this.disconnectTwilio('Error With Twilio Call');
      });

      this.call.on('reject', () => {

        this.disconnectTwilio('Call has been rejected!');
      });
    } catch (err) {
      this.disconnectTwilio('Invalid Twilio Credentials');
    }

    // this.ngxService.stop();
  }

  disconnectTwilio(message: string) {
    this.call?.disconnect();
    if (this.device) {
      this.isCallOngoing.next(false);
      this.device.destroy();
    }

    this.call = undefined;
    if (message) {
      this.sharedService.showToast('warning', '', message);
    }
  }
}
