export const subscription = {
  subscriptions: 'Abonnementen',
  plan: 'Plan',
  whatsappPlan: 'WhatsApp Plan',
  addOns: 'Add-ons',
  billingDetails: 'Facturatiegegevens',
  invoices: 'Facturen',
  wa1Reminder: 'WA 1e herinnering',
  wa2Reminder: 'WA 2e herinnering',
  waRenewApi: 'WA Vernieuw API',
  waCheckUsageApi: 'WA controleer gebruik API',
  waResetUsageApi: 'WA Reset Gebruik API',
  waExceedUsageApi: 'WA Overschrijd Gebruik API',
  waGetBotsInThisAccount: 'WA Haal bots op in dit account',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'Wij bieden de meest betaalbare prijzen voor al uw behoeften.',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'WhatsApp-plan',
    TOOLTIP_ADDS_ON_HEADING: 'Koop aanvullende berichtenpakketten en merk uw bot aan',
    TOOLTIP_ADDS_ON_MESSAGE: 'Als u uw maandelijkse berichtenlimiet heeft bereikt, kunt u aanvullende pakketten kopen.',
    TOOLTIP_SUBSCRIPTION_HEADING: 'Bekijk uw huidige abonnementsgegevens',
    TOOLTIP_HORIZONTAL_BAR: 'Bekijk onze maandelijkse of jaarlijkse prijzen van 3K tot 500K berichten door de balk van links naar rechts te verplaatsen.',
    TOOLTIP_BILLING_ADDRESS_HEADING: 'Werk uw facturatiegegevens bij voor facturering.',
    TOOLTIP_INVOICES_HEADING: 'Bekijk uw eerdere facturen.',
    TOOLTIP_INVOICES_ACTION: 'Klik om de factuur in uw e-mailaccount te ontvangen'
  },
  currentPlan: {
    seeHowToUse: 'Bekijk hoe te gebruiken',
    help: 'Hulp',
    downgradeRequest1: 'U heeft een verzoek ingediend om uw plan te degraderen naar',
    downgradeRequest2: 'berichten. Houd er rekening mee dat uw plan wordt gedegradeerd aan het einde van de huidige factureringscyclus',
    downgradeRequest3: 'U kunt genieten van de',
    downgradeRequest4: 'diensten tot die tijd.',
    downgradeRequestMaster1: 'U heeft een verzoek ingediend om uw ',
    downgradeRequestMaster2: ' plan te degraderen naar',
    downgradeRequestMaster3: ' gesprekken. Houd er rekening mee dat uw plan wordt gedegradeerd aan het einde van de huidige factureringscyclus',
    downgradeRequestMaster4: 'U kunt genieten van de ',
    downgradeRequestMaster5: 'diensten tot die tijd.',
    active: 'Actief',
    inactive: 'Inactief',
    month: 'maand',
    year: 'jaar',
    messagePack: 'Berichtenpakket',
    king: 'King',
    baby: 'Baby',
    whatsapp: 'WhatsApp',
    youAreOn: 'U bevindt zich op',
    plan: 'plan',
    totalMessagesLimit: 'Totaal berichtenlimiet',
    liveAgentLimit: 'Limiet Live Agent',
    perMonth: '/maand',
    agents: 'agent(en)',
    messagesAddOn: 'Berichten Add-on',
    botWhitelabels: 'Bot Whitelabels',
    totalAddOnMessages: 'Totaal Add-on Berichten',
    basePrice: 'Basisprijs',
    gst: 'BTW',
    totalPrice: 'Totale prijs',
    totalAddOnPrice: 'Totale Add-on prijs',
    whitelabelPrice: 'Whitelabel prijs',
    startedOn: 'Begonnen op',
    nextDueOn: 'Volgende vervaldag',
    addOnExpiry: 'Add-on Vervaldatum',
    website: 'Website',
    facebook: 'Facebook',
    telegram: 'Telegram',
  },
  invoice: {
    invoiceNumber: 'Factuurnummer',
    date: 'Datum',
    invoiceAmount: 'Factuurbedrag',
    planAmount: 'Planbedrag',
    plan: 'Plan',
    messages: 'Berichten',
    action: 'Actie',
    nA: 'N.v.t.',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'Klik om de factuur in uw e-mailaccount te ontvangen',
      clickToDownloadInvoice: 'Klik om de factuur te downloaden in PDF-formaat',
    }
  },
  messageAddOn: {
    validity: 'Geldigheid',
    kingPlanHeader: '👑 King Plan',
    youAreOnThe: 'U bevindt zich momenteel op het ',
    asOfNowAndWillGet: 'vanaf nu, en u krijgt ',
    messages: 'berichten ',
    inThisPlan: 'in dit plan. ',
    youCanBuyAdditionalMessaes: 'U kunt aanvullende berichten kopen volgens uw zakelijke behoeften.',
    plan: ' Plan',
    baby: 'Baby',
    king: 'King',
    get: 'Krijg ',
    babyPlan: 'Baby Plan ',
    upgradePlan: 'Plan Upgraden',
    asOfNow: ' vanaf nu.',
    pleaseUpgradeToKingPlan: 'Upgrade alstublieft naar het ',
    toEnableAddOnsAndWhitelabelPacks: ' om Add-ons & Whitelabel Packs in te schakelen.',
    messageAddOnPacks: 'Berichten Add-on Pakketten',
    ifYouHaveReachedYourMonthlyMessageLimit: 'Als u uw maandelijkse berichtenlimiet heeft bereikt, koop dan extra pakketten en geniet van ononderbroken diensten.',
    pleaseSelectNumberOfExpectedMessages: 'Selecteer het aantal verwachte berichten',
    addOnsValidity: 'Geldigheid van Add-ons',
    currentBillingCycle: 'Huidige Factureringscyclus',
    oneYear: 'Eén jaar',
    summary: 'Samenvatting',
    messageCreditsWithKingPlan: 'berichtcredits met king plan',
    messageCreditsWith: 'berichtcredits met ',
    withPlan: '',
    billingCycle: 'Factureringscyclus',
    payableAmount: 'Te betalen bedrag',
    oneTimePayment: 'Eénmalige betaling',
    plusTaxes: ' + Belastingen',
    buyNow: 'Nu kopen',
    whiteLabelChatWindow: 'Whitelabel Chatvenster',
    noBotsLeftToWhiteLabel: 'Geen bots meer om te Whitelabelen.',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'Selecteer de bots die u wilt Whitelabelen.',
    selectBots: 'Selecteer Bots',
    bot: 'Bot',
    bots: 'Bots',
    toBeWhiteLabelled: ' om te worden Whitelabelen',
    remove: 'Verwijder ',
    brandNameFromChatWindow: ' merknaam van het chatvenster.',
    willBeValidTill: 'Geldig tot ',
    ourYearlyPrice: 'Onze jaarlijkse prijs ',
    directWhiteLabel: 'Directe Whitelabel',
    whiteLabelledBots: 'Whitelabel Bots',
    nextRenewalColon: 'Volgende Vernieuwing: ',
    optOutRequested: 'Opt-Out Aangevraagd',
    contactAgency: 'Neem contact op met het Agentschap',
    optOut: 'Opt-Out',
    removeWhiteLabel: 'Whitelabel Verwijderen',
    numOfBots: 'Aantal Bots',
    amount: 'Bedrag',
    taxes: 'Belastingen',
    totalAmount: 'Totaalbedrag',
    select: 'Selecteer',
    selectTheValidity: 'Selecteer de geldigheid',
    messageCount: 'Berichten',
    numberOfPacks: 'Aantal Pakketten',

  },
  payment: {
  },
  paymentMethod: {
    paymentDetails: 'Betalingsgegevens',
    cardNumber: 'Kaartnummer ',
    cvv: 'CVV ',
    //
    month: 'Maand',
    year: 'Jaar',
    save: 'Opslaan',
    add: 'Toevoegen',
    edit: 'Bewerken',
    disclaimer: 'Disclaimer: ',
    disclaimerDescription1: 'De bovenstaande betalingsgegevens zijn alleen van toepassing op gebruikers buiten India.',
    disclaimerDescription2: 'We slaan uw kaartgegevens niet op. In het geval dat u uw kaartgegevens wilt ' +
      'wijzigen, worden deze rechtstreeks gewijzigd bij de betaalgateway.',
    billingAddress: 'Factuuradres',
    companyName: 'Bedrijfsnaam ',
    country: 'Land ',
    selectCountry: 'Selecteer land',
    stateOrRegion: 'Staat/Regio ',
    streetAddress: 'Straatadres ',
    gstIn: 'GSTIN ',
    postalCode: 'Postcode ',
    cancel: 'Annuleren',
    proceedToPay: 'Doorgaan met betalen',
    company: 'Bedrijf',
    state: 'Staat',
    masterCard: 'Mastercard',
    yourCompanyName: 'Uw bedrijfsnaam',
    yourCountry: 'Uw land',
    yourRegion: 'Uw regio',
    yourPostalCode: 'Uw postcode',
    plusTaxes: ' + belastingen',
    dropDown: {
      month: 'Maand *',
      year: 'Jaar *',
    },
    placeholders: {
      enterCardNumber: 'Voer kaartnummer in',
      cvv: 'CVV',
      enterYourCompanyName: 'Voer uw bedrijfsnaam in',
      enterYourStateOrRegion: 'Voer uw staat/regio in',
      enterYourStreetAddress: 'Voer uw straatadres in',
      enterYourGSTIN: 'Voer uw GSTIN in',
      enterPostalCode: 'Voer postcode in',
    }
  },
  plans: {
    viewDetailedPricing: 'Bekijk gedetailleerde prijzen',
    seeHowToUse: 'Zie hoe te gebruiken',
    help: 'Help',
    weHaveGotAPricingPlanPerfectForYou: 'We hebben een prijsplan dat perfect voor jou is',
    weBelieve: 'We geloven dat ',
    shouldBeAccessibleToAllCompanies: ' toegankelijk moet zijn voor alle bedrijven, ongeacht de grootte.',
    monthly: 'Maandelijks',
    yearly: 'Jaarlijks',
    twoMonthsFree: '(2 maanden GRATIS)',
    babyPlan: 'Babyplan',
    freeForever: 'Altijd gratis',
    messagesPerMonth: 'Berichten per maand',
    currentPlan: 'Huidig plan',
    freePlan: 'GRATIS plan',
    kingPlan: 'King-plan',
    perYear: 'per jaar',
    plan: 'plan',
    toThe: ' naar de',
    plusTaxes: ' + Belastingen',
    perMonth: 'per maand',
    upgradePlan: 'Upgrade plan',
    changePlan: 'Plan wijzigen',
    currentlyActive: 'Momenteel actief',
    emperorPlan: 'Keizerlijk plan (Onderneming)',
    sendAsManyMessagesAsYouWant: 'Stuur zoveel berichten als je wilt',
    contactUs: 'Neem contact met ons op',
    features: 'Functies',
    areYouSureYouWantToShiftFrom: 'Weet je zeker dat je wilt overstappen van het',
    messages: 'Berichten',
    planQuestionMark: 'plan?',
    downgradeToFreePlan: 'Downgraden naar het GRATIS plan',
    goToBabyPlan: 'Ga naar het Babyplan',
    thankYouForChoosingBotPenguin: 'Bedankt voor het kiezen van BotPenguin',
    bots: 'Bots',
    facebookPages: 'Facebook-pagina\'s',
    teamMember: 'Teamlid',
    dataStorage: 'Gegevensopslag',
    conditionalFlow: 'Voorwaardelijke stroom',
    liveChat: 'Livechat',
    bookAppointments: 'Afspraken boeken',
    csvExport: 'CSV-export',
    emailAndCallSupport: 'E-mail- en belondersteuning',
    googleCalendarIntegrations: 'Google Calendar-integraties',
    zohoCrmIntegrations: 'Zoho CRM-integraties',
    reportsAndAnalytics: 'Rapporten en analyses',
    removeBotPenguinBranding: 'BotPenguin-branding verwijderen',
    oneBot: '1 Bot',
    twoKMessagesPerMonth: '2.000 berichten per maand',
    oneFacebookPage: '1 Facebook-pagina',
    oneTeamMember: '1 Teamlid',
    thirtyDaysOfDataStorage: '30 dagen gegevensopslag',
    unlimitedBot: 'Onbeperkte Bot',
    threeTo500kMessagesPerMonth: '3.000 tot 500.000 berichten per maand',
    tenFacebookPages: '10 Facebook-pagina\'s',
    threeTeamMembers: '3 Teamleden',
    nintyDaysOfDataStorage: '90 dagen gegevensopslag',
    unlimitedMessages: 'Onbeperkte berichten',
    unlimitedFacebookPages: 'Onbeperkte Facebook-pagina\'s',
    unlimitedTeamMembers: 'Onbeperkte Teamleden',
    unlimitedDataStorage: 'Onbeperkte gegevensopslag',
    youAreHere: `Je bent hier!`,
    detailedPricing: {
      kingsplan: 'King-plan',
      elementary: 'Elementair',
      solutionType: 'Oplossingstype',
      saas: 'SaaS',
      fbpage1fb: '1 FB-pagina',
      perMonth100: '100 per maand',
      enterprise: 'Onderneming',
      saasOnPremises: 'SaaS ter plaatse',
      aiChatbotFeatures: 'AI Chatbot-functies',
      dataSourceForTraning: 'Gegevensbron voor training',
      smartFeatures: 'Slimme functies',
      liveChat: 'Livechat',
      day30: '30 dagen',
      day90: '90 dagen',
      landSquared: 'LandSquared',
      doItYourself: 'Doe-het-zelf',
      unifiedInbox: 'Gedeelde inbox',
      platforms: 'Platforms',
      websiteChatbot: 'Website Chatbot',
      FacebookMessengerChatbot: 'Facebook Messenger Chatbot',
      Telegram: 'Telegram',
      conversationalLandingPage: 'Gesprekslandingspagina',
      integrations: 'Integraties',
      reportsAndAnalytics: 'Rapporten en analyses',
      customerSupport: 'Klantenondersteuning',
      security: 'Beveiliging',
      bots: 'Bots',
      babyPlan: 'Babyplan',
      kingPlan: 'King-plan',
      emperorPlan: 'Keizerlijk plan',
      noofBots: 'Aantal bots',
      one: '1 (Eén)',
      unlimitedBots: 'Onbeperkte Bots',
      noOfWebsite: 'Aantal websites',
      unlimitedWebsites: 'Onbeperkte websites',
      unlimitedMessages: 'Onbeperkte berichten',
      messagesSend: 'Berichten verzenden',
      perMonth2000: '2.000 per maand',
      upto500000perMonth: 'Tot 500.000 per maand',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: 'Onbeperkte pagina\'s',
      telegramNumbers: 'Telegram-nummers',
      unlimited: 'Onbeperkt',
      whatsappNumbers: 'WhatsApp-nummers',
      subscribersLeads: 'Abonnees/Leads',
      createSupportTeam: 'Supportteam voor livechat aanmaken',
      oneMember: '1 Lid',
      threeMembers: '3 leden',
      conversationHistory: 'Gespreksgeschiedenis',
      thirtyDays: '30 Dagen',
      ninetyDays: '90 Dagen',
      chatbotDesigning: 'Chatbot-ontwerp',
      weAreHereToHelp: 'We zijn hier om te helpen',
      weTakeCareEndToEnd: 'We nemen de volledige zorg',
      multipleLanguages: 'Meerdere talen',
      conditionalFlow: 'Voorwaardelijke stroom',
      removeBotPenguinBranding: 'BotPenguin-branding verwijderen',
      gptMessages: 'GPT-berichten',
      aiChatbot: 'AI Chatbot',
      chatGPTIntegration: 'ChatGPT-integratie',
      configureAIChatbotPersonality: 'Configureer AI Chatbot-persoonlijkheid',
      trainChatbotOnYourOwnData: 'Train Chatbot op je eigen gegevens',
      trainChatbotOnWebPages: 'Train Chatbot op webpagina\'s',
      trainChatbotOnMultipleFiles: 'Train Chatbot op meerdere bestanden',
      trainChatbotOnFAQs: 'Train Chatbot op veelgestelde vragen',
      dataTrainingStorage: 'Gegevenstraining en opslag',
      useChatGPTModel4_3_5Turbo: 'Gebruik ChatGPT Model 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Upload meerdere bestanden voor Chatbot-training',
      naturalLanguageProcessing: 'Natuurlijke taalverwerking',
      naturalLanguageUnderstanding: 'Natuurlijke taalbegrip',
      answerLength: 'Antwoordlengte',
      chatTone: 'Gesprekstoon',
      multiLanguage: 'Meertalig',
      answerFormatting: 'Antwoordopmaak',
      customPrompt: 'Aangepaste prompt',
      integrateBotPenguinLLM: 'Integreer BotPenguin LLM',
      integrateOwnCustomLLM: 'Integreer Eigen/Aangepaste LLM',
      periodicallySyncDataSources: 'Periodiek synchroniseren van gegevensbronnen',
      aiBasedSuggestedQuestions: 'AI-gebaseerde voorgestelde vragen',
      chooseVisibilityForParticularWebpageSitemap: 'Kies zichtbaarheid voor specifieke webpagina/sitemap',
      chooseKnowledgeMix: 'Kies kennismix:\nUw kennisbank\nChatGPT-kennis\n"ChatGPT-kennis + Uw kennisbank"',
      faqExtractionFromFileAndURL: 'FAQ-extractie uit bestand en URL',
      analyticsToImproveAI: 'Analytics om AI te verbeteren',
      exportQuestionFlow: 'Vraagstroom exporteren',
      sessionManagement: 'Sessiebeheer',
      collectFeedbackOnChatbotResponses: 'Feedback verzamelen over chatbotreacties',
      smartAIFollowUpQuestions: 'Slimme/AI-vervolgvragen',
      trainOnYourNotionWorkspaceData: 'Trainen op uw Notion-werkruimtegegevens',
      trainOnYoutubeVideo: 'Trainen op YouTube-video',
      trainOnCustomVideoUpload: 'Trainen op aangepaste videoupload',
      rssFeed: 'RSS-feed',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'IP- en regioblokkering',
      emailAndMobileValidation: 'E-mail- en mobiele validatie',
      appointmentsBooking: 'Afspraken boeken',
      humanHandover: 'Overdracht aan mens',
      botHandover: 'Overdracht aan bot',
      handoverBetweenAgents: 'Overdracht tussen agenten',
      mobileAppsForAgents: 'Mobiele apps voor agenten',
      roleBasedAccessControls: 'Toegangscontroles op basis van rollen',
      apiIntegrationOnChatFlows: 'API-integratie op chatstromen',
      liveChatWidget: 'Livechat-widget',
      typingIndicator: 'Typing-indicator',
      liveVisitorsDetails: 'Details van live bezoekers',
      multipleSimultaneousChat: 'Meerdere gelijktijdige chats',
      liveTypingView: 'Live typweergave',
      ruleBasedChatRouting: 'Regelgebaseerde chatroutering',
      chatAutoAssignments: 'Chattoewijzingen',
      efficientOmnichannelInterface: 'Efficiënte omnichannel-interface',
      quickReplies: 'Snelle antwoorden',
      unifiedCustomerProfile: 'Geïntegreerd klantenprofiel',
      visitorStats: 'Bezoekersstatistieken',
      addContactsFromWhatsapp: 'Contacten toevoegen van WhatsApp',
      applyFilterAndSortConversations: 'Filter toepassen en gesprekken sorteren',
      seeChatLogs: 'Gesprekslogboeken bekijken',
      assignNotesAndChatTags: 'Notities en chatlabels toewijzen',
      directCallsToLeadsWithTwilio: 'Directe oproepen naar leads (met Twilio)',
      customerSegmentation: 'Klantsegmentatie',
      exportLeadsInCSV: 'Leads exporteren in CSV',
      leadNotificationsByEmail: 'Leadmeldingen per e-mail',
      website: 'Website',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'Telegram',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'Automatische activering op mobiel/desktop',
      hideBotOnSpecificPages: 'Bot verbergen op specifieke pagina\'s',
      imageSharing: 'Afbeeldingen delen',
      readyToUseChatFlowTemplates: 'Kant-en-klare chatflow-sjablonen',
      fileSharing: 'Bestandsdeling',
      customDesignForChatWindow: 'Aangepast ontwerp voor chatvenster',
      facebookMessengerBot: 'Facebook Messenger Bot',
      facebookSubheading: 'Facebook subtekst',
      telegramSubheading: 'Telegram subtekst',
      conversationalLangdingPageSubheading: 'Subtekst voor gesprekslandingspagina',
      integrationsSubheading: 'Subtekst voor integraties',
      multipleSmartFlows: 'Meerdere slimme stromen',
      dripCampaigns: 'Drip-campagnes',
      broadcastFBMessages: 'Uitzenden van FB-berichten',
      multipleFacebookBotWidgets: 'Meerdere Facebook-bot-widgets',
      facebookAutoReplyOnComments: 'Automatisch antwoorden op opmerkingen op Facebook',
      facebookECommerce: 'Facebook e-commerce',
      broadcastAnalytics: 'Uitzendanalyses',
      telegramMessengerBot: 'Telegram Messenger Bot',
      readyToUseTemplates: 'Kant-en-klare sjablonen',
      groupBroadcastTelegramMessages: 'Groeps- en uitzendberichten op Telegram',
      multipleTelegramBotWidgets: 'Meerdere Telegram-bot-widgets',
      conversationalLandingPageBot: 'Gesprekslandingspagina Bot',
      smartReplies: 'Slimme antwoorden',
      interactiveAndCustomizableUI: 'Interactieve en aanpasbare UI',
      leadManagement: 'Leadbeheer',
      easyToInstallAndShare: 'Eenvoudig te installeren en te delen',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'Leadsquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Sluiten',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google Calendar',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Support Ticketing System',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'In-chat audio- en videogesprek (Twilio/Agora)',
      courierTracking: 'Koeriertracering',
      emailIntegration: 'E-mailintegratie',
      webhooks: 'Webhooks',
      schedulers: 'Planners',
      paymentGateways: 'Betalingsgateways (Stripe & RazorPay)',
      enterpriseIntegrations: 'Ondernemingsintegraties',
      customIntegration: 'Aangepaste integratie',
      shopifyIntegration: 'Shopify-integratie',
      totalNumberOfMessages: 'Totaal aantal berichten',
      totalNumberOfLeads: 'Totaal aantal leads',
      weeklyReportBotwise: 'Wekelijks rapport, per bot',
      averageAgentResponeTime: 'Gemiddelde responstijd van agenten',
      visitorTimingReport: 'Rapportage over bezoekersmoment',
      customerSegmentBasedReports: 'Rapporten op basis van klantsegment',
      tagBasedReports: 'Rapporten op basis van tags',
      leadGenerationReport: 'Rapport over leadgeneratie',
      supportOnWhatsApp: 'Ondersteuning op WhatsApp',
      knowledgeBase: 'Kennisbank',
      onboardingAndTraining: 'Onboarding en training',
      callAndEmailSupport: 'Telefonische en e-mailondersteuning',
      freeChatbotCreation: 'Gratis chatbotcreatie',
      customerSuccessOfficer: 'Klantensuccesfunctionaris',
      secureConnection2048BitSSL: 'Veilige verbinding met 2048-bits SSL',
      securityAssessment: 'Beveiligingsbeoordeling',
      dataCenterInUSOrEU: 'Gegevenscentrum in de VS of de EU',
      twoStepVerification: 'Tweestapsverificatie',
      accessRestrictionsBasedOnIPs: 'Toegangsbeperkingen op basis van IP-adressen',
      onDemandAuditLog: 'Auditlogboek op verzoek',
      bannedVisitorsList: 'Lijst van verbannen bezoekers',
      customAPIRate: 'Aangepaste API-snelheid',
      trustedDomains: 'Vertrouwde domeinen',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'Coderingsnieuwsgierig',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Ons gratis plan',
      TOOLTIP_KING_PLAN: 'Ons populairste plan',
      TOOLTIP_EMPEROR_PLAN: 'Aangepast plan voor onbeperkte berichten, onbeperkte gegevens, onbeperkte teamleden',
      TOOLTIP_CONTACT_US: 'Neem contact met ons op via WhatsApp of e-mail op contact@botpenguin.com om meer te weten te komen over ons aangepaste plan',
      TOOLTIP_UPGRADE: 'Upgrade je huidige plan voor extra functies',
      TOOLTIP_MONTHLY: 'Kies voor een maandelijkse betalingscyclus',
      TOOLTIP_YEARLY: 'Kies voor een jaarlijkse betalingscyclus voor kortingsvoordelen',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'Ga naar Baby Plan',
    freeForever: 'VOOR ALTIJD Gratis',
    features: 'Kenmerken',
    currentPlan: 'Huidig Plan',
    changePlan: 'Verander Plan',
    currentlyActive: 'Momenteel Actief',
    seeHowToUse: 'Zie hoe te gebruiken',
    weHaveGotPricingPerfectForYou: 'We hebben een prijsplan dat perfect voor jou is',
    weBelieve: 'Wij geloven dat ',
    shouldBeAccessibleToAllCompanies: ' toegankelijk zou moeten zijn voor alle bedrijven, ongeacht de grootte.',
    monthly: 'Maandelijks',
    yearly: 'Jaarlijks',
    twoMonthsFree: '(2 Maanden GRATIS)',
    kingPlan: 'King Plan',
    perYear: 'per jaar',
    perMonth: 'per maand',
    conversationsPerMonth: 'Gesprekken per maand',
    upgradePlan: 'Upgrade Plan',
    emperorPlan: 'Emperor Plan (Onderneming)',
    haveAsManyConversationsAsYouNeed: 'Heb zoveel gesprekken als je nodig hebt',
    contactUs: 'Neem contact op',
    metaConversationChargesAreAdditional: 'Meta-gesprekskosten zijn aanvullend en worden rechtstreeks door u aan Meta betaald, ' +
      'op basis van uw gebruik. Wij rekenen geen markup of faciliteringskosten aan.',
    viewDetailedPricing: 'Bekijk Gedetailleerde Prijzen',
    conversationPricingModelUsedByMeta: 'Het Conversatie Prijzen Model dat door Meta wordt gebruikt',
    whatsappBusinessPlatformConversationsFallInto: 'WhatsApp Business Platform-gesprekken vallen in twee categorieën die verschillend geprijsd zijn',
    userInitiatedConversation: 'Door de gebruiker geïnitieerd Gesprek',
    userInitiatedConversationDescription: 'Een gesprek dat begint als reactie op een bericht van de gebruiker. Telkens wanneer een bedrijf binnen het ' +
      'klantenservicevenster van 24 uur op een gebruiker reageert, wordt dat bericht geassocieerd met een door de gebruiker geïnitieerd gesprek. Bedrijven ' +
      'kunnen vrije-tekstberichten verzenden binnen dit klantenservicevenster van 24 uur.',
    businessInitiatedConversation: 'Door het bedrijf geïnitieerd Gesprek',
    businessInitiatedConversationDescription: 'Een gesprek dat begint doordat een bedrijf een bericht naar een gebruiker stuurt buiten het klantenservicevenster van 24 uur. ' +
      'Berichten die een door het bedrijf geïnitieerd gesprek starten, vereisen een berichtsjabloon.',
    areYouSureYouWantTo: 'Weet je zeker dat je wilt overschakelen van het',
    conversations: ' Gesprekken ',
    plan: ' plan',
    toThe: ' naar het',
    metaPricingMarkup: 'Meta Prijstoeslag',
    numberOfChatFlows: 'Aantal chatflows',
    greenTickApplication: 'Green Tick Applicatie',
    extraPhoneNumberLinking: 'Extra koppeling van telefoonnummer',
    teamMembers: 'Teamleden',
    dataStorage: 'Gegevensopslag',
    conditionalFlow: 'Voorwaardelijke Flow',
    liveChat: 'Live Chat',
    smartInbox: 'Slimme Inbox',
    whatsappTemplates: 'WhatsApp Sjablonen',
    customerSegments: 'Klantengroep',
    broadcastAndDripCampaigns: 'Uitzendingen en Drip-campagnes',
    multipleLanguages: 'Meerdere Talen',
    bookAppointments: 'Afspraken Boeken',
    csvContactsExport: 'CSV Contacten Exporteren',
    googleCalendarIntegrations: 'Google Calendar Integratie',
    nativeApplications: '60+ Native Integratie',
    whatsappSupport: 'WhatsApp Ondersteuning',
    emailAndCallSupport: 'E-mail en Bel Ondersteuning',
    customerIntegrations: 'Klantintegraties',
    nlpAndNluCapabilities: 'NLP en NLU-mogelijkheden',
    chatGPTBot: 'ChatGPT chatbot',
    customerSuccessOfficer: 'Klantsuccesbegeleider',
    noMarkupTransparency: 'Geen markup-transparantie',
    upto500kPerMonth: 'Tot 500.000 per maand',
    beyond500k: 'Meer dan 500.000',
    unlimited: 'Onbeperkt',
    oneTeamMember: '1 Teamlid',
    unlimitedTeamMember: 'Onbeperkte Teamleden',
    nintyDaysOfDataStorage: '90 dagen gegevensopslag',
    unlimitedDataStorage: 'Onbeperkte gegevensopslag',
    downgradeToFreePlan: 'Downgrade Naar GRATIS Plan',
    detailedPricing: {
      onDemandAuditLog: 'Auditlog op aanvraag',
      accessRestrictionsBasedOnIPs: 'Toegangsbeperkingen op basis van IP-adressen',
      averageAgentResponeTime: 'Gemiddelde responstijd van de agent',
      perMonth100: '100 per maand',
      solutionType: 'Soort oplossing',
      saas: 'SaaS',
      saasOnPremises: 'SaaS On-Premises',
      emperorPlan: 'Keizerlijk Plan',
      babyPlan: 'Baby Plan',
      kingsplan: 'Kings Plan',
      braodcastsAndDrips: 'Uitzendingen en Druppels',
      thirtyDays: '30 Dagen',
      ninetyDays: '90 Dagen',
      oneMember: '1 Lid',
      threeMembers: '3 Leden',
      one: '1 (Eén)',
      unlimitedMessages: 'Onbeperkte berichten',
      unlimitedBots: 'Onbeperkte Bots',
      messagesSend: 'Berichten versturen',
      upto500000perMonth: 'Tot 500.000 per maand',
      unlimited: 'Onbeperkt',
      onlyAdmin: 'Alleen Beheerder',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: 'Doe-het-zelf',
      weAreHereToHelp: 'Wij zijn hier om te helpen',
      weTakeCareEndToEnd: 'Wij zorgen van begin tot eind',
      numberOfBots: 'Aantal bots',
      conversations: 'Gesprekken',
      gptMessages: 'GPT-berichten',
      whatsappNumbers: 'WhatsApp-nummers',
      subscribersLeads: 'Abonnees/Leads',
      createSupportTeamForLiveChat: 'Creëer een ondersteuningsteam voor livechat',
      conversationHistory: 'Geschiedenis van gesprekken',
      chatbotDesigning: 'Chatbot-ontwerp',
      multipleLanguages: 'Meerdere talen',
      conditionalFlow: 'Voorwaardelijke stroom',
      whatsappTemplates: 'WhatsApp-sjablonen',
      restartChatFlowTriggers: 'Herstart Chatflow-triggers',
      multipleWhatsAppWidgets: 'Meerdere WhatsApp-widgets',
      importWhatsappContacts: 'Importeer WhatsApp-contacten',
      whatsappQuickReply: 'WhatsApp-snelle reactie',
      whatsappSmartInbox: 'WhatsApp Smart Inbox',
      whatsappEcommerceCatalogs: 'WhatsApp E-commerce met Catalogi',
      whatsappBroadcast: 'WhatsApp-uitzending',
      whatsappBroadcastAnalytics: 'WhatsApp-uitzending Analytics',
      dripCampaigns: 'Druppelcampagnes',
      customerSegmentation: 'Klantsegmentatie',
      keywordBasedResponse: 'Respons op basis van trefwoorden',
      whatsappShopManagement: 'WhatsApp-winkelbeheer',
      aiChatbot: 'AI-chatbot',
      chatGPTIntegration: 'ChatGPT-integratie',
      configureAIChatbotPersonality: 'Configureer de persoonlijkheid van de AI-chatbot',
      trainChatbotOnYourOwnData: 'Train de chatbot op je eigen gegevens',
      trainChatbotOnWebPages: '\'Train de chatbot op webpagina\'s\'',
      trainChatbotOnMultipleFiles: 'Train de chatbot op meerdere bestanden',
      trainChatbotOnFAQs: 'Train de chatbot op veelgestelde vragen',
      dataTrainingStorage: 'Gegevenstraining en opslag',
      useChatGPTModel4or35Turbo: 'Gebruik ChatGPT Model 4/3.5 Turbo',
      uploadMultipleFilesForChatbotTraining: 'Upload meerdere bestanden voor chatbottraining',
      naturalLanguageProcessing: 'Natuurlijke taalverwerking',
      naturalLanguageUnderstanding: 'Natuurlijke taalbegrip',
      answerLength: 'Lengte van het antwoord',
      chatTone: 'Chattoon',
      multiLanguage: 'Meertalig',
      answerFormatting: 'Opmaak van het antwoord',
      customPrompt: 'Aangepaste prompt',
      whatsAppSessionManagement: 'WhatsApp-sessiebeheer (Inactiviteit en Flow-herstartberichten)',
      integrateBotPenguinLLM: 'Integreer BotPenguin LLM',
      integrateOwnCustomLLM: 'Integreer Eigen/Aangepaste LLM',
      periodicallySyncDataSources: 'Periodiek synchroniseren van gegevensbronnen',
      aiBasedSuggestedQuestions: 'AI-gebaseerde voorgestelde vragen',
      chooseVisibilityForParticularWebpageSitemap: 'Kies zichtbaarheid voor een bepaalde webpagina/sitemap',
      chooseKnowledgeMix: 'Kies Kennismix: Uw kennisbank ChatGPT-kennis "ChatGPT-kennis + Uw kennisbank"',
      faqExtractionFromFileAndURL: 'FAQ-extractie uit Bestand en URL',
      analyticsToImproveAI: 'Analytics om AI te verbeteren',
      exportQuestionFlow: 'Exporteer Vraagstroom',
      collectFeedbackOnChatbotResponses: 'Verzamel feedback over chatbotreacties',
      smartAIFollowUpQuestions: 'Slimme/AI-vervolgvragen',
      trainOnYourNotionWorkspaceData: 'Trainen op uw Notion Workspace-gegevens',
      trainOnYoutubeVideo: 'Trainen op YouTube-video',
      trainOnCustomVideoUpload: 'Trainen op aangepaste videoupload',
      rssFeed: 'RSS-feed',
      googleDrive: 'Google Drive',
      dropBox: 'DropBox',
      humanHandover: 'Overdracht naar mens',
      botHandover: 'Overdracht naar bot',
      handoverBetweenAgents: 'Overdracht tussen agenten',
      emailAndMobileValidation: 'E-mail- en mobiele validatie',
      mobileAppsForAgents: 'Mobiele apps voor agenten',
      appointmentBooking: 'Afspraak boeken',
      roleBasedAccessControls: 'Rolgebaseerde toegangscontroles',
      ruleBasedChatRouting: 'Regelgebaseerde chatroutering',
      liveVisitorsDetails: 'Details van live bezoekers',
      multipleSimultaneousChats: 'Meerdere gelijktijdige chats',
      visitorStats: 'Bezoekersstatistieken',
      addContactsFromWhatsApp: 'Add Contacts from WhatsApp',
      applyFilterAndSortConversations: 'Filter en sorteert gesprekken',
      seeChatLogs: 'Bekijk chatlogs',
      quickReplies: 'Snelle antwoorden',
      unifiedCustomerProfile: 'Geprofileerde klantprofiel',
      assignNotesAndChatTags: 'Wijs notities en chat-tags toe',
      exportLeadsInCSV: 'Leads exporteren naar CSV',
      tagContacts: 'Contacten taggen',
      customAttributes: 'Aangepaste attributen',
      markStatusOfConversations: 'Status van gesprekken markeren',
      assignConversationToAgents: 'Gesprek toewijzen aan agenten',
      leadNotificationsByEmail: 'Leadmeldingen via e-mail',
      directCallsToLeadsWithTwilio: 'Directe oproepen naar leads met Twilio',
      whatsAppTemplates: 'WhatsApp-sjablonen',
      promotionalMessageFrequencyControl: 'Frequentieregeling voor promotionele berichten',
      whatsAppWidget: 'WhatsApp-widget',
      whatsAppQR: 'WhatsApp QR',
      whatsAppLink: 'WhatsApp-koppeling',
      campaignScheduler: 'Campagneplanner',
      customTriggers: 'Aangepaste triggers',
      customCampaigns: 'Aangepaste campagnes',
      repeatPurchaseReminders: 'Herinneringen voor herhaalde aankopen',
      campaignOpenRateTracking: 'Tracking van openingspercentage van campagnes',
      paymentCollectionOnWhatsApp: 'Betalingen innen via WhatsApp',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'LeadSquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'GetResponse',
      supportbee: 'SupportBee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: 'Sluiten',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Koper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Spreadsheets',
      googleCalendar: 'Google Agenda',
      googleAnalytics: 'Google Analytics',
      supportTicketingSystem: 'Ondersteuningsticketssysteem',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'Audio- en videogesprek in chat (Twilio/Agora)',
      courierTracking: 'Koeriersvolging',
      emailIntegration: 'E-mailintegratie',
      webhooks: 'Webhooks',
      schedulers: 'Planners',
      paymentGateways: 'Betalingsgateways (Stripe & RazorPay)',
      enterpriseIntegrations: 'Enterprise-integraties',
      customIntegration: 'Aangepaste integratie',
      freeWhatsAppAPIApprovalAndSetup: 'Goedkeuring en installatie van gratis WhatsApp API',
      developerAPIsAccess: '\'Toegang tot ontwikkelaars-API\'s\'',
      customRateLimit: 'Aangepaste snelheidslimiet',
      totalNumberOfLeads: 'Totaal aantal leads',
      averageAgentResponseTime: 'Gemiddelde responstijd van de agent',
      visitorTimingReport: 'Rapportage over bezoekertijden',
      customerSegmentBasedReports: 'Rapporten op basis van klantsegmenten',
      tagBasedReports: 'Rapporten op basis van tags',
      leadGenerationReport: 'Leadgeneratierapport',
      supportOnWhatsApp: 'Ondersteuning via WhatsApp',
      knowledgeBase: 'Kennisbank',
      onboardingAndTraining: 'Inwerken en training',
      callAndEmailSupport: 'Ondersteuning via oproepen en e-mail',
      freeChatbotCreation: 'Gratis chatbot-creatie',
      customerSuccessOfficer: 'Klantensuccesmedewerker',
      secureConnection: 'Beveiligde verbinding van 2048 bits SSL',
      securityAssessment: 'Beveiligingsbeoordeling',
      dataCenterLocation: 'Locatie van het gegevenscentrum (VS of EU)',
      twoStepVerification: 'Tweestapsverificatie',
      accessRestrictions: 'Toegangsbeperkingen',
      auditLog: 'Auditlog op aanvraag',
      bannedVisitorsList: 'Lijst van verbannen bezoekers',
      customAPIRate: 'Aangepaste API-snelheid',
      trustedDomains: 'Vertrouwde domeinen',
      elementary: 'Elementair',
      bots: 'Bots',
      unlockWhatsapp: 'Ontgrendel WhatsApp',
      aiChatbotFeatures: 'AI-chatbot-functies',
      dataSourceForTraining: 'Gegevensbron voor training',
      smartFeatures: 'Slimme functies',
      liveChat: 'Livechat',
      smartInbox: 'Slimme inbox',
      broadcastsAndDrips: 'Uitzendingen en Druppels',
      integrations: 'Integraties',
      development: 'Ontwikkeling',
      reportsAndAnalytics: 'Rapporten en analyses',
      customerSupport: 'Klantenondersteuning',
      security: 'Beveiliging',
      whatsappWidgets: 'WhatsApp-widgets',
      campaignSchedular: 'Campagneplanner',
      repeatPurchaseRemainder: 'Herhaalaankoopherinnering',
    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: 'Ons gratis plan',
      TOOLTIP_KING_PLAN: 'Ons meest populaire plan',
      TOOLTIP_EMPEROR_PLAN: 'Aangepast plan voor onbeperkte berichten, onbeperkte gegevens, onbeperkte teamleden',
      TOOLTIP_CONTACT_US: 'Bereik ons via WhatsApp of e-mail op contact@botpenguin.com voor meer informatie over ons aangepaste plan',
      TOOLTIP_UPGRADE: 'Upgrade je huidige plan voor extra functies',
      TOOLTIP_MONTHLY: 'Selecteer een maandelijkse betalingscyclus',
      TOOLTIP_YEARLY: 'Selecteer een jaarlijkse betalingscyclus voor kortingsvoordelen',
    }
  }
};
