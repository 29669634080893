import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LanguageService } from 'src/appv2/services/language.service';
import { SharedService } from '../shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bp-dropdown',
  templateUrl: './bp-dropdown.component.html',
  styleUrls: ['./bp-dropdown.component.scss']
})
export class BpDropdownComponent implements OnInit, OnDestroy {

  @Input() labelText = 'Select';

  @Input() showLabel = true;

  @Input() value = 'Select';

  showDropDown = false;

  staticText: any;

  @Input() enableToggle = true;

  /**
   * Property to hold option values in object format
   * Title is key used to display the property in dropdown
   */
  @Input() optionList = [{ title: '1', disabled: false }, { title: '2', disabled: false }];

  /**
   * Property to emit selected option value to parent comp
   */
  @Output() clickEvent = new EventEmitter<any>();

  @Output() searchEvent = new EventEmitter<any>();

  @Input() enableSearch = false;

  @Input() isReversed = false;
  isDarkMode = false;
  isDarkMode$: Subscription;

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.showDropDown = false;
  }

  constructor(
    private languageService: LanguageService,
    public sharedService: SharedService
  ) {
    this.staticText = languageService.getStaticText().shared.bpDropDown;

    this.isDarkMode$ = this.sharedService.isDarkMode.subscribe(isDarkMode => {
      this.isDarkMode = isDarkMode;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.isDarkMode$.unsubscribe();
  }

  optionSelectHandler(item, index: number) {

    if (item?.disabled) {
      return;
    }

    this.showDropDown = false;
    this.value = item.title;
    this.clickEvent.emit({ item, index });
  }

  searchEventHandler(event) {
    this.searchEvent.emit(event);
  }

}
