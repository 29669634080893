const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Mon compte',
  yourProfileAndPasswordSecurity: 'Votre sécurité de profil et de mot de passe',
  detailsHeader: 'Détails',
  passwordHeader: 'Mot de passe',
  customAttributeHeader: 'Attributs personnalisés',
  tagsHeader: 'Mots clés',
  accessTokenHeader: 'Jeton d`accès',
  thirdPartyIntegrationsHeader: 'Intégrations tiers',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Mettez à jour le profil de votre compte',
    TOOLTIP_PASSWORD_HEADING: 'changez votre mot de passe',
    TOOLTIP_TAGS_HEADING: 'Gérez vos étiquettes',
    TOOLTIP_THIRD_PARTY_HEADING: 'Intégrations tiers',
    TOOLTIP_CHANGE_PASSWORD_TITLE: 'Un mot de passe sécurisé aide à protéger votre compte $ {marque}',
    TOOLTIP_INTEGRATIONS: 'Intégrer',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Ajouter ou mettre à jour des attributs personnalisés',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Gérez vos attributs personnalisés'
  },
  accessToken: {
    accessToken: 'Jeton d`accès',
    generateYourAccessTokenForUsingPlatformAPIs: 'Générez votre jeton d`accès pour l`utilisation d`API de plate- forme',
    generateApiToken: 'Générer un jeton API',
    revokeApiToken: 'REVOCER TOKEN API',
    regenerateApiToken: 'Régénérer le jeton API',
    placeholders: {
      yourApiKey: 'Votre clé API',
    }
  },
  details: {
    name: 'Nom',
    emailId: 'ID de messagerie',
    yourPlan: 'Votre plan',
    messagesPerMonth: ' Messages / mois',
    daysOfStoragePlan: ' Plan de stockage des jours',
    conditionalFeatures: 'caractéristiques conditionnelles',
    upgradeToEnterprisePlanToGetMore: 'Mettre à niveau vers l`entreprise Plan pour obtenir plus',
    featuresAndStorageCapacity: 'caractéristique et capacité de stockage',
    upgradePlan: 'Plan de mise à niveau',
    submit: 'Soumettre',
    selectLanguage: 'Choisir la langue',
    baby: 'Bébé',
    king: 'Roi',
    conversationsperMonth: 'Coversations / mois',
    english: 'Anglais',
    hindi: 'hindi',
    german: 'Allemand',
    french: 'Français',
    portuguese: 'Portugais',
    spanish: 'Espagnol',
    italian: 'italien',
    russian: 'russe',
    chatBotBrandName: 'Nom de marque de chatbot',
    placeholders: {
      john: 'John',
      email: 'E-mail',
      searchLanguage: 'Langue de recherche',
      chatBotBrandName: 'Nom de marque de chatbot'
    },
    appTooltip: {
      TOOLTIP_NAME: 'Entrez le nom de votre profil',
      TOOLTIP_EMAIL_ADDRESS: 'Entrez votre adresse email',
    }
  },
  customAttribute: {
    addAttribute: 'Ajouter un attribut',
    discard: 'Ignorer',
    customAttribute: 'Attributs personnalisés',
    checkOrAddCustomAttribute: 'Ici, vous pouvez vérifier et ajouter vos attributs personnalisés.',
    attributeKey: 'Clé de l\'attribut',
    attributeName: 'Nom de l\'attribut',
    bot: 'Robot',
    flow: 'Flux',
    loadMore: 'Charger plus',
    question: 'Question',
    action: 'Action',
    search: 'Rechercher',
    selectBot: 'Sélectionner un robot',
    selectFlow: 'Sélectionner un flux',
    selectQuestion: 'Sélectionner une question',
    noDataFoundHeading: 'Vous n\'avez pas encore créé d\'attribut',
    noDataFoundSubheading: 'Toutes vos contributions apparaîtront ici',
    deleteTitle: 'Supprimer l\'Attribut',
    deleteDescription: 'Êtes-vous sûr de vouloir supprimer cet attribut ?',
    deleteAttributes: 'Supprimer les Attributs',
    areYouSureYouWantToDeleteThisAttributes: 'Êtes-vous sûr de vouloir supprimer ces attributs ?',
    editAttribute: 'Modifier l\'Attribut',
    headsUp: 'La tête haute',
    okay: 'D`accord',
    dataAddedToLead: 'Les attributs associés à vos APIs ou flux de bot ne seront pas mis à jour. Veuillez mettre à jour les APIs ou flux de bot en conséquence.',
    placeholders: {
      enterAttributeKey: 'Entrez la clé de l\'attribut',
      enterAttributeName: 'Entrez le nom de l\'attribut',
      selectBot: 'Sélectionnez un robot',
      selectFlow: 'Sélectionnez un flux',
      selectQuestion: 'Sélectionnez une question',
      search: 'Rechercher par nom d\'attribut'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Entrez la clé de l\'attribut',
      TOOLTIP_ATTRIBUTE_NAME: 'Entrez le nom de l\'attribut',
      TOOLTIP_SELECT_BOT: 'Sélectionnez un robot',
      TOOLTIP_SELECT_FLOW: 'Sélectionnez un flux',
      TOOLTIP_SELECT_QUESTION: 'Sélectionnez une question',
    }
  },
  password: {
    changePassword: 'Changer le mot de passe',
    aSecurePasswordHelpsProtectYour: 'Un mot de passe sécurisé aide à protéger votre',
    account: ' Compte',
    currentPassword: 'Mot de passe actuel',
    newPassword: 'nouveau mot de passe',
    confirmPassword: 'Confirmez le mot de passe',
    cancel: 'Annuler',
    updatePassword: 'Mettre à jour le mot de passe',
    deleteAccount: 'Supprimer le compte',
    placeholders: {
      enterYourCurrentPassword: 'Entrer votre mot de passe actuel',
      enterYourNewPassword: 'Entrez votre nouveau mot de passe',
      enterYourConfirmPassword: 'Entrez votre mot de passe Confirmer',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Entrez l`ancien mot de passe',
      TOOLTIP_NEW_PASSWORD: 'Entrez un nouveau mot de passe',
      TOOLTIP_CONFIRM_PASSWORD: 'Confirmez votre nouveau mot de passe',
      TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Gérer les étiquettes',
    createNewTags: 'Créer de nouvelles étiquettes',
    createNewTagsSubText: 'Gérez vos étiquettes ici pour mieux organiser vos prospects, discussions, groupes, etc.',
    tagName: 'Nom de l`étiquette',
    updateTag: 'Mettre à jour l`étiquette',
    createTag: 'Créer une étiquette',
    discard: 'Abandonner',
    searchTag: 'Rechercher une étiquette par nom',
    noTagFound: 'Aucune étiquette trouvée !',
    createATag: 'Créer une étiquette',
    deleteTagModalSubText: 'Êtes-vous sûr de vouloir supprimer cette étiquette ? La suppression de cette étiquette la retirera également des utilisateurs assignés !',
    deleteTagModalHeading: 'Supprimer les étiquettes',
    deleteOneTagModalHeading: 'Supprimer l`étiquette',
    actions: 'Actions',
    placeholders: {
      enterTag: 'Entrer le nom de l`étiquette',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'Supprimer les étiquettes',
    }
  },
  thirdPartyIntegration: {
    list: {
      name: 'Nom',
      status: 'Statut',
      action: 'Action',
      messageBird: 'Oiseau de messagerie',
      messageBirdForSendingMessagesAndEmails: 'Messagebird pour envoyer des messages et des e-mails',
      notConnected: 'Pas connecté',
      connected: 'Connecté',
      edit: 'Modifier',
      plivo: 'Plivo',
      plivoForSendingMessages: 'PLIVO pour envoyer des messages',
      connect: 'Connecter',
      appTooltip: {
        nameOfAppOrCrm: 'Nom de l`application / CRM',
        statusAsConnectedOrNotConnected: 'Statut comme connecté / non connecté',
        clickToConnectAppOrCrm: 'Cliquez pour connecter l`application / CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Oops! Les intégrations ne sont pas disponibles dans le plan bébé. Veuillez passer à King Plan for Instomations.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'oiseau de messagerie',
        integrateWithMessageBird: 'Intégrer avec messagebird',
        apiKey: 'clé API',
        originator: 'Auteur',
        connect: 'Connecter',
        disconnect: 'Se déconnecter',
        seeActivities: 'Voir les activités',
        sms: 'SMS',
        time: 'Temps',
        phoneNumber: 'Numéro de téléphone',
        status: 'Statut',
        botName: 'Nom de bot',
        message: 'Message',
        error: 'Erreur',
        placeholders: {
          apiKey: 'clé API',
          messageBirdPhoneNumber: 'Numéro de téléphone Messagebird',
        },
        noDataFound: {
          activities: 'Activités',
          noActivitiesFound: 'Aucune activité trouvée!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Intégrer avec PLIVO',
        authId: 'ID d`authe',
        authToken: 'Token Auth',
        yourPlivoPhoneNumber: 'Votre numéro de téléphone PLIVO',
        connect: 'Connecter',
        disconnect: 'Se déconnecter',
        seeActivities: 'Voir les activités',
        sms: 'SMS',
        time: 'Temps',
        phoneNumber: 'Numéro de téléphone',
        status: 'Statut',
        botName: 'Nom de bot',
        message: 'Message',
        error: 'Erreur',
        noDataFound: {
          activities: 'Activités',
          noActivitiesFound: 'Aucune activité trouvée!',
        },
        placeholders: {
          authId: 'ID d`authe',
          authToken: 'Token Auth',
          yourPlivoPhoneNumber: 'Votre numéro de téléphone PLIVO',
        }
      }
    }
  }
};
