import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-bp-input',
  templateUrl: './bp-input.component.html',
  styleUrls: ['./bp-input.component.scss']
})
export class BpInputComponent implements OnInit {

  public hide = true;
  /**
   * Property to decide the type of input
   */
  @Input() public type = 'name';

  @Input() public disabled = false;

  /**
   * Property to decide the required input
   */
  @Input() public required = false;

  @Input() public name = 'name';


  /**
   * Property to decide the height of input bar
   */
  @Input() public height = '60';

  /**
   * Property to handle chnage event on input
   */
  @Output() inputChange = new EventEmitter<any>();

  /**
   * Property to handle key up event on input
   */
  @Output() keyupEvent = new EventEmitter<any>();

  /**
   * Property to handle chnage event on input
   */
  @Output() isError = new EventEmitter<any>();

  /**
   * Property to decide the id of input container
   */
  @Input() public id = '';

  /**
   * Property to hold the placeholder
   */
  @Input() public placeholder = '';

  /**
   * Property to display the error message if the input is wrong
   */
  public errorMessage = 'Required';

  /**
   * Property to decide whether to display the error message or not
   */
  public showErrorMessage = false;

  /**
   * Property to decide whether to display the text area or not
   */
  public showTextArea = false;

  /**
   * Property to show check icon
   */
  public isInputCorrect = false;

  /**
   * Property to show image in input field
   */
  public showImage = true;

  /**
   * Property to display label
   */
  @Input() public showLabel = true;

  /**
   * Property to display label
   */
  @Input() public showErrorIcons = true;

  /**
   * Property to hold label text
   */
  @Input() labelText = 'Name';

  /**
   * Property to hold label text
   */
  @Input() showInputField = true;

  /**
   * Property for Regex
   */
  private emailRegex =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{6,})/gm;
  private urlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;

  /**
   * Property to bind value to input
   */
  @Input() public value = '';

  /**
   * Property to show counter in input icon
   */
  @Input() public showCounter = false;

  /**
   * Property to hold counter max value
   */
  @Input() public maxLength = 4096;

  /**
   * Property to show password visibility icon in input
   */
  @Input() public showPasswordIcon = false;


  /**
   * Proeprty to hold input value length
   */
  inputCurrentLength = 0;
  icon = 'visibility_off';

  @Input() minLength = 0;

  /**
   * Property to show tooltip
   */
  @Input() showTooltip = false;

  /**
   * Propert to configure tooltip text
   */
  @Input() tooltipText = '';


  /**
   * Propert to configure tooltip text
   */
  @Input() resetOnChange = false;

  @ViewChild('textAreaRef', { static: false }) textAreaRef: ElementRef<HTMLTextAreaElement>;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
  }

  /**
   * Property to emit onvalue change event
   */
  public inputValueHandler(event: any) {
    if (this.validateInput((event.target.value || '').trim())) {
      this.showErrorMessage = true;
      this.isError.emit(true);
    } else {
      this.showErrorMessage = false;
      this.isInputCorrect = true;
      this.isError.emit(false);
      this.inputChange.emit(event);

      if (this.resetOnChange) {
        setTimeout(() => {
          this.value = '';
        }, 200);
      }
    }
  }

  /**
   * Property to emit onvalue keyup event
   */
  public inputKeyEventHandler(event: any) {
    if (this.type === 'number') {
      this.validateInputNumber(event);
    }
    this.inputCurrentLength = event.target.value.length;
    this.keyupEvent.emit(event);
  }

  private validateInput(inputValue: string) {
    let isError = false;
    switch (this.type) {
      case 'email':
        if (!inputValue.match(new RegExp(this.emailRegex))) {
          isError = true;
          this.errorMessage = 'Please enter a valid email';
        } else {
          isError = false;
          this.isInputCorrect = true;
        }
        break;
      case 'phone':
        isError = true;
        break;
      case 'password':
        if (!inputValue.match(new RegExp(this.passwordRegex))) {
          isError = true;
          this.errorMessage = 'Password must contain atleast 6 characters, 1 uppercase letter & 1 special character*';
        } else {
          isError = false;
          this.isInputCorrect = true;
        }
        break;
      case 'url':
        if (!inputValue.match(new RegExp(this.urlRegex))) {
          isError = true;
          this.errorMessage = 'Please enter a valid url';
        } else {
          isError = false;
          this.isInputCorrect = true;
        }
        break;
      case 'name':
        if (inputValue.length < 2 || inputValue.length > 50) {
          isError = true;
          this.errorMessage = 'Please enter a valid name';
        } else {
          isError = false;
          this.isInputCorrect = true;
        }
        break;
      default:
        break;
    }
    return isError;
  }

  public showPassword() {
    if (this.type === 'password') {
      this.type = 'text';
      this.icon = 'visibility_off';
    }
    else {
      this.type = 'password';
      this.icon = 'visibility';
    }
  }

  validateInputNumber(event) {
    return this.sharedService.checkInput(event, 'number', 2);
  }
}
