export const subscription = {
  subscriptions: 'サブスクリプション',
  plan: 'プラン',
  whatsappPlan: 'WhatsAppプラン',
  addOns: 'アドオン',
  billingDetails: '請求詳細',
  invoices: '請求書',
  wa1Reminder: 'WA 1回目のリマインダー',
  wa2Reminder: 'WA 2回目のリマインダー',
  waRenewApi: 'WA更新API',
  waCheckUsageApi: 'WA利用状況チェックAPI',
  waResetUsageApi: 'WAリセット利用API',
  waExceedUsageApi: 'WA超過利用API',
  waGetBotsInThisAccount: 'このアカウントのボットを取得する',
  appTooltip: {
    TOOLTIP_PLAN_HEADING: 'すべてのニーズに最適な価格を提供しています。',
    TOOLTIP_WHATSAPP_PLAN_HEADING: 'WhatsAppプラン',
    TOOLTIP_ADDS_ON_HEADING: '追加のメッセージパックとホワイトラベルボットを購入',
    TOOLTIP_ADDS_ON_MESSAGE: '月間メッセージ制限に達した場合、追加のパックを購入できます。',
    TOOLTIP_SUBSCRIPTION_HEADING: '現在のサブスクリプション詳細を確認',
    TOOLTIP_HORIZONTAL_BAR: 'バーを左から右に移動して、3,000から50万メッセージまでの月次または年次価格を確認します。',
    TOOLTIP_BILLING_ADDRESS_HEADING: '請求書のための請求詳細を更新',
    TOOLTIP_INVOICES_HEADING: '以前の請求書を確認',
    TOOLTIP_INVOICES_ACTION: '請求書をメールアカウントで受け取るにはクリック'
  },
  currentPlan: {
    seeHowToUse: '使用方法を確認',
    help: 'ヘルプ',
    downgradeRequest1: 'プランをダウングレードするリクエストを出しました',
    downgradeRequest2: 'メッセージです。現在の請求サイクルの終了までにプランがダウングレードされます。',
    downgradeRequest3: 'その間、',
    downgradeRequest4: 'をお楽しみいただけます。',
    downgradeRequestMaster1: 'プランをダウングレードするリクエストを出しました',
    downgradeRequestMaster2: '会話への',
    downgradeRequestMaster3: ' プランへのダウングレードリクエストを出しました。現在の請求サイクルの終了までにプランがダウングレードされます。',
    downgradeRequestMaster4: 'その間、',
    downgradeRequestMaster5: 'をお楽しみいただけます。',
    active: 'アクティブ',
    inactive: '非アクティブ',
    month: '月',
    year: '年',
    messagePack: 'メッセージパック',
    king: 'キング',
    baby: 'ベビー',
    whatsapp: 'WhatsApp',
    youAreOn: 'あなたは',
    plan: 'プラン',
    totalMessagesLimit: '合計メッセージ制限',
    liveAgentLimit: 'ライブエージェント制限',
    perMonth: '/月',
    agents: 'エージェント',
    messagesAddOn: 'メッセージアドオン',
    botWhitelabels: 'ボットホワイトラベル',
    totalAddOnMessages: '合計アドオンメッセージ',
    basePrice: 'ベース価格',
    gst: 'GST',
    totalPrice: '合計金額',
    totalAddOnPrice: '合計アドオン価格',
    whitelabelPrice: 'ホワイトラベル価格',
    startedOn: '開始日',
    nextDueOn: '次回の支払い期限',
    addOnExpiry: 'アドオンの有効期限',
    website: 'ウェブサイト',
    facebook: 'Facebook',
    telegram: 'Telegram'
  },
  invoice: {
    invoiceNumber: '請求書番号',
    date: '日付',
    invoiceAmount: '請求額',
    planAmount: 'プラン金額',
    plan: 'プラン',
    messages: 'メッセージ',
    action: 'アクション',
    nA: 'NA',
    appTooltip: {
      clickToReceiveInvoiceInYourEmailAccount: 'メールアカウントで請求書を受け取るにはクリック',
      clickToDownloadInvoice: 'PDF形式で請求書をダウンロードするにはクリック',
    }
  },
  messageAddOn: {
    cancel: 'キャンセル',
    validity: '有効期限',
    kingPlanHeader: '👑 キングプラン',
    youAreOnThe: 'あなたは',
    asOfNowAndWillGet: '現在のところ、',
    messages: 'メッセージ ',
    inThisPlan: 'このプランで受け取る予定です。',
    youCanBuyAdditionalMessaes: 'ビジネス要件に応じて追加のメッセージを購入できます。',
    plan: ' プラン',
    baby: 'ベビー',
    king: 'キング',
    get: '取得 ',
    babyPlan: 'ベビープラン ',
    upgradePlan: 'プランをアップグレード',
    asOfNow: ' 現在。',
    pleaseUpgradeToKingPlan: 'アドオン＆ホワイトラベルパックを有効にするには、',
    toEnableAddOnsAndWhitelabelPacks: 'にアップグレードしてください。',
    messageAddOnPacks: 'メッセージアドオンパック',
    ifYouHaveReachedYourMonthlyMessageLimit: '月間のメッセージ制限に達した場合、追加のパックを購入してサービスを中断せずにお楽しみいただけます。',
    pleaseSelectNumberOfExpectedMessages: '予想されるメッセージ数を選択してください',
    addOnsValidity: 'アドオンの有効期限',
    currentBillingCycle: '現在の請求サイクル',
    oneYear: '1年',
    summary: 'サマリー',
    messageCreditsWithKingPlan: 'キングプランでのメッセージクレジット',
    messageCreditsWith: 'でのメッセージクレジット ',
    withPlan: ' プランで',
    billingCycle: '請求サイクル',
    payableAmount: '支払金額 ',
    oneTimePayment: '一回の支払い',
    plusTaxes: ' + 税金',
    buyNow: '今すぐ購入',
    whiteLabelChatWindow: 'ホワイトラベルチャットウィンドウ',
    noBotsLeftToWhiteLabel: 'ホワイトラベルに利用可能なボットがありません。',
    pleaseSelectTheBotsYouWantToWhiteLabel: 'ホワイトラベルにしたいボットを選択してください。',
    selectBots: 'ボットを選択',
    bot: 'ボット',
    bots: 'ボット',
    toBeWhiteLabelled: ' ホワイトラベルになります',
    remove: '削除 ',
    brandNameFromChatWindow: ' チャットウィンドウからのブランド名。',
    willBeValidTill: '有効期限は',
    ourYearlyPrice: '当社の年間価格 ',
    directWhiteLabel: '直接ホワイトラベル',
    whiteLabelledBots: 'ホワイトラベルボット',
    nextRenewalColon: '次回更新: ',
    optOutRequested: 'オプトアウトリクエスト',
    contactAgency: 'エージェンシーに連絡',
    optOut: 'オプトアウト',
    removeWhiteLabel: 'ホワイトラベルを削除',
    messageCount: 'メッセージ',
    numberOfPacks: 'パックの数',
    amount: '金額',
    taxes: '税金',
    totalAmount: '合計金額',
    numOfBots: 'ボットの数',
    select: '選択',
    selectTheValidity: '有効期間を選択',

  },
  payment: {

  },
  paymentMethod: {
    paymentDetails: '支払詳細',
    cardNumber: 'カード番号 ',
    cvv: 'CVV ',
    month: '月',
    year: '年',
    save: '保存',
    add: '追加',
    edit: '編集',
    disclaimer: '免責事項: ',
    disclaimerDescription1: '上記の支払方法の詳細は、インド国外のユーザーにのみ適用されます。',
    disclaimerDescription2: 'お客様のカード詳細は当社で保存されていません。カード詳細を変更する場合、直接支払いゲートウェイで変更されます。',
    billingAddress: '請求先住所',
    companyName: '会社名 ',
    country: '国 ',
    selectCountry: '国を選択',
    stateOrRegion: '州/地域 ',
    streetAddress: '住所 ',
    gstIn: 'GSTIN ',
    postalCode: '郵便番号 ',
    cancel: 'キャンセル',
    proceedToPay: '支払いへ進む',
    company: '会社',
    state: '州',
    masterCard: 'Master Card',
    yourCompanyName: 'あなたの会社名',
    yourCountry: 'あなたの国',
    yourRegion: 'あなたの地域',
    yourPostalCode: 'あなたの郵便番号',
    plusTaxes: ' + 税金',
    dropDown: {
      month: '月 *',
      year: '年 *',
    },
    placeholders: {
      enterCardNumber: 'カード番号を入力',
      cvv: 'CVV',
      enterYourCompanyName: '会社名を入力',
      enterYourStateOrRegion: '州/地域を入力',
      enterYourStreetAddress: '住所を入力',
      enterYourGSTIN: 'GSTINを入力',
      enterPostalCode: '郵便番号を入力',
    }
  },
  plans: {
    viewDetailedPricing: '詳細価格を表示',
    seeHowToUse: '使い方を見る',
    help: 'ヘルプ',
    weHaveGotAPricingPlanPerfectForYou: '私たちはあなたにぴったりの価格プランを持っています',
    weBelieve: '私たちは信じています ',
    shouldBeAccessibleToAllCompanies: ' すべての企業にアクセス可能であるべきです、サイズに関係なく。',
    monthly: '月額',
    yearly: '年額',
    twoMonthsFree: '(2ヶ月無料)',
    babyPlan: 'ベビープラン',
    freeForever: '永久無料',
    messagesPerMonth: 'メッセージ数/月',
    currentPlan: '現在のプラン',
    freePlan: '無料プラン',
    kingPlan: 'キングプラン',
    perYear: '年ごと',
    plan: 'プラン',
    toThe: ' to the',
    plusTaxes: ' + 税金',
    perMonth: '月額',
    upgradePlan: 'アップグレードプラン',
    changePlan: 'プラン変更',
    currentlyActive: '現在アクティブ',
    emperorPlan: 'エンペラープラン（エンタープライズ）',
    sendAsManyMessagesAsYouWant: '必要なだけメッセージを送信できます',
    contactUs: 'お問い合わせ',
    features: '特徴',
    areYouSureYouWantToShiftFrom: '本当に',
    messages: ' メッセージ ',
    planQuestionMark: 'プランに変更しますか？',
    downgradeToFreePlan: '無料プランにダウングレード',
    goToBabyPlan: 'ベビープランに移動',
    thankYouForChoosingBotPenguin: 'BotPenguinを選んでいただきありがとうございます',
    bots: 'ボット',
    facebookPages: 'Facebookページ',
    teamMember: 'チームメンバー',
    dataStorage: 'データストレージ',
    conditionalFlow: '条件付きフロー',
    liveChat: 'ライブチャット',
    bookAppointments: '予約',
    csvExport: 'CSVエクスポート',
    emailAndCallSupport: 'メールと電話サポート',
    googleCalendarIntegrations: 'Googleカレンダー統合',
    zohoCrmIntegrations: 'Zoho CRM統合',
    reportsAndAnalytics: 'レポートと分析',
    removeBotPenguinBranding: 'BotPenguinのブランディングを削除',
    oneBot: '1ボット',
    twoKMessagesPerMonth: '2,000メッセージ/月',
    oneFacebookPage: '1 Facebookページ',
    oneTeamMember: '1チームメンバー',
    thirtyDaysOfDataStorage: '30日間のデータストレージ',
    unlimitedBot: '無制限ボット',
    threeTo500kMessagesPerMonth: '3,000から500,000メッセージ/月',
    tenFacebookPages: '10 Facebookページ',
    threeTeamMembers: '3チームメンバー',
    nintyDaysOfDataStorage: '90日間のデータストレージ',
    unlimitedMessages: '無制限メッセージ',
    unlimitedFacebookPages: '無制限のFacebookページ',
    unlimitedTeamMembers: '無制限のチームメンバー',
    unlimitedDataStorage: '無制限データストレージ',
    youAreHere: 'ここにいます！',
    detailedPricing: {
      kingsplan: 'キングプラン',
      elementary: 'エレメンタリー',
      solutionType: 'ソリューションタイプ',
      saas: 'SaaS',
      fbpage1fb: '1 FB ページ',
      perMonth100: '月額 100',
      enterprise: 'エンタープライズ',
      saasOnPremises: 'オンプレミス SaaS',
      aiChatbotFeatures: 'AI チャットボットの機能',
      dataSourceForTraning: 'トレーニングのデータソース',
      smartFeatures: 'スマート機能',
      liveChat: 'ライブチャット',
      day30: '30 日',
      day90: '90 日',
      landSquared: 'ランドスクエアド',
      doItYourself: '自分でやる',
      unifiedInbox: '統合インボックス',
      platforms: 'プラットフォーム',
      websiteChatbot: 'ウェブサイトチャットボット',
      FacebookMessengerChatbot: 'Facebook Messenger チャットボット',
      Telegram: 'テレグラム',
      conversationalLandingPage: '会話型ランディングページ',
      integrations: '統合',
      reportsAndAnalytics: 'レポートと分析',
      customerSupport: 'カスタマーサポート',
      security: 'セキュリティ',
      bots: 'ボット',
      babyPlan: 'ベビープラン',
      kingPlan: 'キングプラン',
      emperorPlan: 'エンペラープラン',
      noofBots: 'ボットの数',
      one: '1 (ひとつ)',
      unlimitedBots: '無制限のボット',
      noOfWebsite: 'ウェブサイトの数',
      unlimitedWebsites: '無制限のウェブサイト',
      unlimitedMessages: '無制限のメッセージ',
      messagesSend: '送信されるメッセージ',
      perMonth2000: '月額 2,000',
      upto500000perMonth: '月額 50万まで',
      facebookMessenger: 'Facebook Messenger',
      unlimitedPages: '無制限のページ',
      telegramNumbers: 'テレグラム番号',
      unlimited: '無制限',
      whatsappNumbers: 'WhatsApp 番号',
      subscribersLeads: '購読者/リード',
      createSupportTeam: 'ライブチャット用のサポートチームを作成',
      oneMember: '1 メンバー',
      threeMembers: '3 メンバー',
      conversationHistory: '会話履歴',
      thirtyDays: '30 日',
      ninetyDays: '90 日',
      chatbotDesigning: 'チャットボットのデザイン',
      weAreHereToHelp: '私たちはここにいます',
      weTakeCareEndToEnd: 'エンドツーエンドでお手伝い',
      multipleLanguages: '複数言語',
      conditionalFlow: '条件付きフロー',
      removeBotPenguinBranding: 'BotPenguin ブランディングの削除',
      gptMessages: 'GPT メッセージ',
      aiChatbot: 'AI チャットボット',
      chatGPTIntegration: 'ChatGPT 統合',
      configureAIChatbotPersonality: 'AI チャットボットのパーソナリティ設定',
      trainChatbotOnYourOwnData: '独自のデータでチャットボットをトレーニング',
      trainChatbotOnWebPages: 'ウェブページでチャットボットをトレーニング',
      trainChatbotOnMultipleFiles: '複数のファイルでチャットボットをトレーニング',
      trainChatbotOnFAQs: 'FAQでチャットボットをトレーニング',
      dataTrainingStorage: 'データトレーニングのストレージ',
      useChatGPTModel4_3_5Turbo: 'ChatGPT Model 4/3.5 Turbo の使用',
      uploadMultipleFilesForChatbotTraining: 'チャットボットトレーニングのために複数のファイルをアップロード',
      naturalLanguageProcessing: '自然言語処理',
      naturalLanguageUnderstanding: '自然言語理解',
      answerLength: '回答の長さ',
      chatTone: 'チャットのトーン',
      multiLanguage: 'マルチ言語',
      answerFormatting: '回答のフォーマット',
      customPrompt: 'カスタムプロンプト',
      integrateBotPenguinLLM: 'BotPenguin LLM の統合',
      integrateOwnCustomLLM: '独自のカスタム LLM の統合',
      periodicallySyncDataSources: 'データソースの定期的な同期',
      aiBasedSuggestedQuestions: 'AI に基づく提案質問',
      chooseVisibilityForParticularWebpageSitemap: '特定のウェブページ/サイトマップの表示を選択',
      chooseKnowledgeMix: 'ナレッジミックスの選択:\nあなたのナレッジベース\nChatGPT ナレッジ\n"ChatGPT ナレッジ + あなたのナレッジベース"',
      faqExtractionFromFileAndURL: 'ファイルと URL からの FAQ 抽出',
      analyticsToImproveAI: 'AI を改善するためのアナリティクス',
      exportQuestionFlow: '質問フローのエクスポート',
      sessionManagement: 'セッション管理',
      collectFeedbackOnChatbotResponses: 'チャットボットの応答にフィードバックを収集',
      smartAIFollowUpQuestions: 'スマート/AI フォローアップ質問',
      trainOnYourNotionWorkspaceData: 'Notion ワークスペースデータでトレーニング',
      trainOnYoutubeVideo: 'YouTube ビデオでトレーニング',
      trainOnCustomVideoUpload: 'カスタムビデオのアップロードでトレーニング',
      rssFeed: 'RSS フィード',
      googleDrive: 'Google ドライブ',
      dropBox: 'DropBox',
      ipAndRegionBlocking: 'IP および地域のブロッキング',
      emailAndMobileValidation: 'メールおよびモバイルの検証',
      appointmentsBooking: 'アポイントメントの予約',
      humanHandover: '人間の引き継ぎ',
      botHandover: 'ボットの引き継ぎ',
      handoverBetweenAgents: 'エージェント間の引き継ぎ',
      mobileAppsForAgents: 'エージェント向けのモバイルアプリ',
      roleBasedAccessControls: '役割ベースのアクセスコントロール',
      apiIntegrationOnChatFlows: 'チャットフロー上の API 統合',
      liveChatWidget: 'ライブチャットウィジェット',
      typingIndicator: '入力インジケーター',
      liveVisitorsDetails: 'ライブ訪問者の詳細',
      multipleSimultaneousChat: '複数の同時チャット',
      liveTypingView: 'ライブ入力ビュー',
      ruleBasedChatRouting: 'ルールに基づいたチャットルーティング',
      chatAutoAssignments: 'チャットの自動割り当て',
      efficientOmnichannelInterface: '効率的なオムニチャネルインターフェース',
      quickReplies: 'クイックリプライ',
      unifiedCustomerProfile: '統一された顧客プロファイル',
      visitorStats: '訪問者統計',
      addContactsFromWhatsapp: 'WhatsApp からの連絡先の追加',
      applyFilterAndSortConversations: 'フィルターの適用と会話のソート',
      seeChatLogs: 'チャットログの表示',
      assignNotesAndChatTags: 'ノートとチャットタグの割り当て',
      directCallsToLeadsWithTwilio: 'Twilio を使用したリードへの直接通話',
      customerSegmentation: '顧客セグメンテーション',
      exportLeadsInCSV: 'CSV でリードをエクスポート',
      leadNotificationsByEmail: 'メールによるリードの通知',
      website: 'ウェブサイト',
      wordPress: 'WordPress',
      whatsApp: 'WhatsApp',
      facebook: 'Facebook',
      telegram: 'テレグラム',
      shopify: 'Shopify',
      autoTriggeringOnMobileDesktop: 'モバイル/デスクトップでの自動トリガー',
      hideBotOnSpecificPages: '特定のページでボットを非表示にする',
      imageSharing: '画像の共有',
      readyToUseChatFlowTemplates: '使いやすいチャットフローテンプレート',
      fileSharing: 'ファイルの共有',
      customDesignForChatWindow: 'チャットウィンドウのカスタムデザイン',
      facebookMessengerBot: 'Facebook Messenger ボット',
      facebookSubheading: 'Facebook サブヘッディング',
      telegramSubheading: 'Telegram サブヘッディング',
      conversationalLangdingPageSubheading: '会話型ランディングページのサブヘッディング',
      integrationsSubheading: '統合のサブヘッディング',
      multipleSmartFlows: '複数のスマートフロー',
      dripCampaigns: 'ドリップキャンペーン',
      broadcastFBMessages: 'FB メッセージのブロードキャスト',
      multipleFacebookBotWidgets: '複数の Facebook ボットウィジェット',
      facebookAutoReplyOnComments: 'コメントへの自動返信 (Facebook)',
      facebookECommerce: 'Facebook 電子商取引',
      broadcastAnalytics: 'ブロードキャストアナリティクス',
      telegramMessengerBot: 'Telegram Messenger ボット',
      readyToUseTemplates: '使いやすいテンプレート',
      groupBroadcastTelegramMessages: 'グループおよびブロードキャストテレグラムメッセージ',
      multipleTelegramBotWidgets: '複数のTelegramボットウィジェット',
      conversationalLandingPageBot: '会話型ランディングページボット',
      smartReplies: 'スマート返信',
      interactiveAndCustomizableUI: 'インタラクティブでカスタマイズ可能な UI',
      leadManagement: 'リード管理',
      easyToInstallAndShare: '簡単にインストールおよび共有',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'Leadsquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: '閉じる',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Google Sheets',
      googleCalendar: 'Google カレンダー',
      googleAnalytics: 'Google アナリティクス',
      supportTicketingSystem: 'サポートチケットシステム',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'チャット内の音声およびビデオ通話 (Twilio/Agora)',
      courierTracking: 'クーリエの追跡',
      emailIntegration: 'メール統合',
      webhooks: 'Webhooks',
      schedulers: 'スケジューラ',
      paymentGateways: '支払いゲートウェイ (Stripe & RazorPay)',
      enterpriseIntegrations: 'エンタープライズ統合',
      customIntegration: 'カスタム統合',
      shopifyIntegration: 'Shopify 統合',
      totalNumberOfMessages: 'メッセージの総数',
      totalNumberOfLeads: 'リードの総数',
      weeklyReportBotwise: '週次レポート、ボットごと',
      averageAgentResponeTime: '平均エージェントの応答時間',
      visitorTimingReport: '訪問者のタイミングレポート',
      customerSegmentBasedReports: '顧客セグメントベースのレポート',
      tagBasedReports: 'タグベースのレポート',
      leadGenerationReport: 'リード生成レポート',
      supportOnWhatsApp: 'WhatsApp でのサポート',
      knowledgeBase: 'ナレッジベース',
      onboardingAndTraining: 'オンボーディングとトレーニング',
      callAndEmailSupport: '電話およびメールサポート',
      freeChatbotCreation: '無料チャットボットの作成',
      customerSuccessOfficer: 'カスタマーサクセスオフィサー',
      secureConnection2048BitSSL: 'セキュアな接続 (2048 ビット SSL)',
      securityAssessment: 'セキュリティアセスメント',
      dataCenterInUSOrEU: 'US または EU のデータセンター',
      twoStepVerification: '2 段階認証',
      accessRestrictionsBasedOnIPs: 'IP に基づくアクセス制限',
      onDemandAuditLog: 'オンデマンド監査ログ',
      bannedVisitorsList: '禁止訪問者リスト',
      customAPIRate: 'カスタム API レート',
      trustedDomains: '信頼されたドメイン',
      mb100: '100 MB',
      gb1: '1 GB',
      codingCurious: 'コーディング好き',
    },

    appTooltip: {
      TOOLTIP_BABY_PLAN: '私たちの無料プラン',
      TOOLTIP_KING_PLAN: '私たちの最も人気のあるプラン',
      TOOLTIP_EMPEROR_PLAN: '無制限のメッセージ、無制限のデータ、無制限のチームメンバー向けのカスタマイズプラン',
      TOOLTIP_CONTACT_US: 'お問い合わせやカスタマイズプランについての詳細については、contact@botpenguin.comまでWhatsAppまたはメールでお問い合わせください',
      TOOLTIP_UPGRADE: '現在のプランをアップグレードして追加の機能を利用できます',
      TOOLTIP_MONTHLY: '月間支払いサイクルを選択します',
      TOOLTIP_YEARLY: '割引特典のために年間支払いサイクルを選択します',
    }
  },
  whatsappPlans: {
    goToBabyPlan: 'ベビープランに移動',
    freeForever: '永久無料',
    features: '特徴',
    currentPlan: '現在のプラン',
    changePlan: 'プラン変更',
    currentlyActive: '現在アクティブ',
    seeHowToUse: '使い方を見る',
    weHaveGotPricingPerfectForYou: '私たちはあなたにぴったりの価格プランを持っています',
    weBelieve: '私たちは信じています ',
    shouldBeAccessibleToAllCompanies: ' すべての企業にアクセス可能であるべきです、サイズに関係なく。',
    monthly: '月額',
    yearly: '年額',
    twoMonthsFree: '(2ヶ月無料)',
    kingPlan: 'キングプラン',
    perYear: '年ごと',
    perMonth: '月額',
    conversationsPerMonth: '月間の会話数',
    upgradePlan: 'アップグレードプラン',
    emperorPlan: 'エンペラープラン（エンタープライズ）',
    haveAsManyConversationsAsYouNeed: '必要なだけ多くの会話を行ってください',
    contactUs: 'お問い合わせ',
    metaConversationChargesAreAdditional: 'メタ会話の料金は追加料金であり、使用に応じて直接Metaに支払われます。私たちは料金の追加や手続き料を請求しません。',
    viewDetailedPricing: '詳細価格を表示',
    conversationPricingModelUsedByMeta: 'Metaが使用する会話価格モデル',
    whatsappBusinessPlatformConversationsFallInto: 'WhatsApp Business Platformの会話は、異なる価格設定がされている2つのカテゴリに分かれます',
    userInitiatedConversation: 'ユーザー起動型会話',
    userInitiatedConversationDescription: 'ユーザーメッセージへの応答として開始する会話。24時間以内の顧客サービスウィンドウ内でビジネスがユーザーに返信するたびに、そのメッセージはユーザー起動型会話に関連付けられます。ビジネスはこの24時間以内の顧客サービスウィンドウ内で自由な形式のメッセージを送信できます。',
    businessInitiatedConversation: 'ビジネス起動型会話',
    businessInitiatedConversationDescription: 'ビジネスがユーザーに24時間以内の顧客サービスウィンドウ外でメッセージを送信することから始まる会話。ビジネス起動型会話を開始するメッセージには、メッセージテンプレートが必要です。',
    areYouSureYouWantTo: '本当に',
    conversations: ' 会話 ',
    plan: ' プラン ',
    toThe: ' to the',
    metaPricingMarkup: 'メタ価格のマークアップ',
    numberOfChatFlows: 'チャットフローの数',
    greenTickApplication: 'グリーンティックアプリケーション',
    extraPhoneNumberLinking: '追加の電話番号リンキング',
    teamMembers: 'チームメンバー',
    dataStorage: 'データストレージ',
    conditionalFlow: '条件付きフロー',
    liveChat: 'ライブチャット',
    smartInbox: 'スマートインボックス',
    whatsappTemplates: 'WhatsAppテンプレート',
    customerSegments: '顧客グループ',
    broadcastAndDripCampaigns: 'ブロードキャストとドリップキャンペーン',
    multipleLanguages: '複数言語',
    bookAppointments: '予約',
    csvContactsExport: 'CSV連絡先エクスポート',
    googleCalendarIntegrations: 'Googleカレンダー統合',
    nativeApplications: '60以上のネイティブ統合',
    whatsappSupport: 'WhatsAppサポート',
    emailAndCallSupport: 'メールと電話サポート',
    customerIntegrations: '顧客統合',
    nlpAndNluCapabilities: 'NLPおよびNLU機能',
    chatGPTBot: 'ChatGPTチャットボット',
    customerSuccessOfficer: 'カスタマーサクセスオフィサー',
    noMarkupTransparency: 'マークアップ透明性なし',
    upto500kPerMonth: '月間最大50万',
    beyond500k: '50万以上',
    unlimited: '無制限',
    oneTeamMember: '1チームメンバー',
    unlimitedTeamMember: '無制限のチームメンバー',
    nintyDaysOfDataStorage: '90日間のデータストレージ',
    unlimitedDataStorage: '無制限データストレージ',
    downgradeToFreePlan: '無料プランにダウングレード',
    detailedPricing: {
      onDemandAuditLog: 'オンデマンド監査ログ',
      accessRestrictionsBasedOnIPs: 'IPに基づくアクセス制限',
      averageAgentResponeTime: '平均エージェント応答時間',
      perMonth100: '月間100',
      solutionType: 'ソリューションの種類',
      saas: 'SaaS',
      saasOnPremises: 'オンプレミスSaaS',
      emperorPlan: 'エンペラープラン',
      babyPlan: 'ベビープラン',
      kingsplan: 'キングスプラン',
      braodcastsAndDrips: 'ブロードキャストとドリップ',
      thirtyDays: '30日',
      ninetyDays: '90日',
      oneMember: '1メンバー',
      threeMembers: '3メンバー',
      one: '1 (ワン)',
      unlimitedMessages: '無制限のメッセージ',
      unlimitedBots: '無制限のボット',
      messagesSend: 'メッセージ送信',
      upto500000perMonth: '月に最大50万まで',
      unlimited: '無制限',
      onlyAdmin: '管理者のみ',
      mb100: '100 MB',
      gb1: '1 GB',
      doItYourself: '自分でやる',
      weAreHereToHelp: 'お手伝いさせていただきます',
      weTakeCareEndToEnd: 'エンドツーエンドでお世話をします',
      numberOfBots: 'ボットの数',
      conversations: '会話',
      gptMessages: 'GPTメッセージ',
      whatsappNumbers: 'WhatsApp番号',
      subscribersLeads: '購読者/リード',
      createSupportTeamForLiveChat: 'ライブチャット用のサポートチームを作成',
      conversationHistory: '会話履歴',
      chatbotDesigning: 'チャットボットの設計',
      multipleLanguages: '複数の言語',
      conditionalFlow: '条件付きフロー',
      whatsappTemplates: 'WhatsAppテンプレート',
      restartChatFlowTriggers: 'チャットフローの再起動トリガー',
      multipleWhatsAppWidgets: '複数のWhatsAppウィジェット',
      importWhatsappContacts: 'WhatsApp連絡先のインポート',
      whatsappQuickReply: 'WhatsAppクイックリプライ',
      whatsappSmartInbox: 'WhatsAppスマートインボックス',
      whatsappEcommerceCatalogs: 'WhatsApp eコマースカタログ',
      whatsappBroadcast: 'WhatsAppブロードキャスト',
      whatsappBroadcastAnalytics: 'WhatsAppブロードキャスト分析',
      dripCampaigns: 'ドリップキャンペーン',
      customerSegmentation: '顧客セグメンテーション',
      keywordBasedResponse: 'キーワードベースの応答',
      whatsappShopManagement: 'WhatsAppショップ管理',
      aiChatbot: 'AIチャットボット',
      chatGPTIntegration: 'ChatGPT統合',
      configureAIChatbotPersonality: 'AIチャットボットのパーソナリティを設定',
      trainChatbotOnYourOwnData: '独自のデータでチャットボットをトレーニング',
      trainChatbotOnWebPages: 'Webページ上でチャットボットをトレーニング',
      trainChatbotOnMultipleFiles: '複数のファイルでチャットボットをトレーニング',
      trainChatbotOnFAQs: 'FAQでチャットボットをトレーニング',
      dataTrainingStorage: 'データトレーニングストレージ',
      useChatGPTModel4or35Turbo: 'ChatGPTモデル4/3.5ターボを使用',
      uploadMultipleFilesForChatbotTraining: 'チャットボットトレーニング用の複数のファイルをアップロード',
      naturalLanguageProcessing: '自然言語処理',
      naturalLanguageUnderstanding: '自然言語理解',
      answerLength: '回答の長さ',
      chatTone: 'チャットトーン',
      multiLanguage: '多言語',
      answerFormatting: '回答のフォーマット',
      customPrompt: 'カスタムプロンプト',
      whatsAppSessionManagement: 'WhatsAppセッション管理（非アクティブとフロー再開メッセージ）',
      integrateBotPenguinLLM: 'BotPenguin LLMの統合',
      integrateOwnCustomLLM: '独自のカスタムLLMの統合',
      periodicallySyncDataSources: 'データソースの定期的な同期',
      aiBasedSuggestedQuestions: 'AIベースの提案質問',
      chooseVisibilityForParticularWebpageSitemap: '特定のウェブページ/サイトマップの表示を選択',
      chooseKnowledgeMix: 'ナレッジベースとChatGPTナレッジの選択：ナレッジベースChatGPTナレッジ+',
      faqExtractionFromFileAndURL: 'ファイルおよびURLからのFAQ抽出',
      analyticsToImproveAI: 'AIの改善のための分析',
      exportQuestionFlow: '質問フローのエクスポート',
      collectFeedbackOnChatbotResponses: 'チャットボットの応答に関するフィードバックの収集',
      smartAIFollowUpQuestions: 'スマート/AIフォローアップの質問',
      trainOnYourNotionWorkspaceData: 'Notionワークスペースデータでトレーニング',
      trainOnYoutubeVideo: 'YouTubeビデオでトレーニング',
      trainOnCustomVideoUpload: 'カスタムビデオのアップロードでトレーニング',
      rssFeed: 'RSSフィード',
      googleDrive: 'Googleドライブ',
      dropBox: 'DropBox',
      humanHandover: 'ヒューマンハンドオーバー',
      botHandover: 'ボットハンドオーバー',
      handoverBetweenAgents: 'エージェント間のハンドオーバー',
      emailAndMobileValidation: 'メールとモバイルの検証',
      mobileAppsForAgents: 'エージェント向けのモバイルアプリ',
      appointmentBooking: 'アポイントメント予約',
      roleBasedAccessControls: '役割ベースのアクセスコントロール',
      ruleBasedChatRouting: 'ルールベースのチャットルーティング',
      liveVisitorsDetails: 'ライブ訪問者の詳細',
      multipleSimultaneousChats: '複数の同時チャット',
      visitorStats: '訪問者統計',
      addContactsFromWhatsApp: 'WhatsAppから連絡先を追加',
      applyFilterAndSortConversations: 'フィルターの適用と会話のソート',
      seeChatLogs: 'チャットログの表示',
      quickReplies: 'クイックリプライ',
      unifiedCustomerProfile: '統一された顧客プロファイル',
      assignNotesAndChatTags: 'ノートとチャットタグの割り当て',
      exportLeadsInCSV: 'CSVでリードをエクスポート',
      tagContacts: '連絡先のタグ付け',
      customAttributes: 'カスタム属性',
      markStatusOfConversations: '会話のステータスをマーク',
      assignConversationToAgents: 'エージェントに会話を割り当てる',
      leadNotificationsByEmail: 'メールでのリード通知',
      directCallsToLeadsWithTwilio: 'Twilioを使用したリードへの直接通話',
      whatsAppTemplates: 'WhatsAppテンプレート',
      promotionalMessageFrequencyControl: 'プロモーションメッセージの頻度制御',
      whatsAppWidget: 'WhatsAppウィジェット',
      whatsAppQR: 'WhatsApp QR',
      whatsAppLink: 'WhatsAppリンク',
      campaignScheduler: 'キャンペーンスケジューラー',
      customTriggers: 'カスタムトリガー',
      customCampaigns: 'カスタムキャンペーン',
      repeatPurchaseReminders: 'リピート購入リマインダー',
      campaignOpenRateTracking: 'キャンペーンオープンレートの追跡',
      paymentCollectionOnWhatsApp: 'WhatsAppでの支払い収集',
      agileCRM: 'Agile CRM',
      capsule: 'Capsule',
      insightly: 'Insightly',
      nimble: 'Nimble',
      leadsquared: 'Leadsquared',
      chakra: 'Chakra',
      plivo: 'Plivo',
      helpcrunch: 'Helpcrunch',
      getresponse: 'Getresponse',
      supportbee: 'Supportbee',
      pipeline: 'Pipeline',
      zendesk: 'Zendesk',
      joget: 'Joget',
      firmao: 'Firmao',
      kylas: 'Kylas',
      close: '閉じる',
      firstpromoter: 'Firstpromoter',
      zingtree: 'Zingtree',
      liveagent: 'Liveagent',
      ontraport: 'Ontraport',
      frontapp: 'Frontapp',
      webhoper: 'Webhoper',
      engagebay: 'Engagebay',
      helpshift: 'Helpshift',
      copper: 'Copper',
      autopilot: 'Autopilot',
      flowlu: 'Flowlu',
      freshdesk: 'Freshdesk',
      salesflare: 'Salesflare',
      teamgate: 'Teamgate',
      shipway: 'Shipway',
      googleSheets: 'Googleシート',
      googleCalendar: 'Googleカレンダー',
      googleAnalytics: 'Googleアナリティクス',
      supportTicketingSystem: 'サポートチケットシステム',
      zapier: 'Zapier',
      pabbly: 'Pabbly',
      hubSpot: 'HubSpot',
      gitHub: 'GitHub',
      salesforce: 'Salesforce',
      inChatAudioAndVideoCall: 'チャット内のオーディオおよびビデオ通話（Twilio/Agora）',
      courierTracking: '宅配便の追跡',
      emailIntegration: 'メールの統合',
      webhooks: 'Webフック',
      schedulers: 'スケジューラー',
      paymentGateways: '支払いゲートウェイ（Stripe & RazorPay）',
      enterpriseIntegrations: 'エンタープライズ統合',
      customIntegration: 'カスタム統合',
      freeWhatsAppAPIApprovalAndSetup: '無料のWhatsApp API承認とセットアップ',
      developerAPIsAccess: '開発者APIへのアクセス',
      customRateLimit: 'カスタムレートリミット',
      totalNumberOfLeads: 'リードの総数',
      averageAgentResponseTime: '平均エージェント応答時間',
      visitorTimingReport: '訪問者のタイミングレポート',
      customerSegmentBasedReports: '顧客セグメントベースのレポート',
      tagBasedReports: 'タグベースのレポート',
      leadGenerationReport: 'リード生成レポート',
      supportOnWhatsApp: 'WhatsAppでのサポート',
      knowledgeBase: 'ナレッジベース',
      onboardingAndTraining: 'オンボーディングとトレーニング',
      callAndEmailSupport: 'コールおよびメールサポート',
      freeChatbotCreation: '無料のチャットボット作成',
      customerSuccessOfficer: 'カスタマーサクセスオフィサー',
      secureConnection: '2048ビットSSLセキュア接続',
      securityAssessment: 'セキュリティアセスメント',
      dataCenterLocation: 'USまたはEUのデータセンター',
      twoStepVerification: '2段階認証',
      accessRestrictions: 'IPに基づくアクセス制限',
      auditLog: 'オンデマンド監査ログ',
      bannedVisitorsList: '禁止された訪問者リスト',
      customAPIRate: 'カスタムAPIレート',
      trustedDomains: '信頼されたドメイン',
      elementary: '初級',
      bots: 'ボット',
      unlockWhatsapp: 'WhatsAppのロック解除',
      aiChatbotFeatures: 'AIチャットボットの機能',
      dataSourceForTraining: 'トレーニング用のデータソース',
      smartFeatures: 'スマート機能',
      liveChat: 'ライブチャット',
      smartInbox: 'スマートインボックス',
      broadcastsAndDrips: 'ブロードキャストとドリップ',
      integrations: '統合',
      development: '開発',
      reportsAndAnalytics: 'レポートと分析',
      customerSupport: 'カスタマーサポート',
      security: 'セキュリティ',
      whatsappWidgets: 'WhatsAppウィジェット',
      campaignSchedular: 'キャンペーンスケジューラー',
      repeatPurchaseRemainder: 'リピート購入リマインダー',

    },
    appTooltip: {
      TOOLTIP_BABY_PLAN: '私たちの無料プラン',
      TOOLTIP_KING_PLAN: '私たちの最も人気のあるプラン',
      TOOLTIP_EMPEROR_PLAN: '無制限のメッセージ、無制限のデータ、無制限のチームメンバー向けのカスタマイズプラン',
      TOOLTIP_CONTACT_US: 'お問い合わせやカスタマイズプランについての詳細については、contact@botpenguin.comまでWhatsAppまたはメールでお問い合わせください',
      TOOLTIP_UPGRADE: '現在のプランをアップグレードして追加の機能を利用できます',
      TOOLTIP_MONTHLY: '月間支払いサイクルを選択します',
      TOOLTIP_YEARLY: '割引特典のために年間支払いサイクルを選択します',
    }
  }
};

