const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Edite su flujo de chat',
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    classicBuilder: 'Constructor clásico',
    visualiseFlow: 'Visualizar el flujo',
    testLiveOnTelegramWeb: 'Prueba en vivo en la web de Telegram',
    testFlow: 'Flujo de prueba',
    importTemplate: 'Plantilla de importación',
    landingPageBotReview: 'Vista previa de bote de la página de destino',
    install: 'Instalar',
    testLiveOnMessengerWeb: 'Prueba en vivo en Messenger Web',
    testLiveOnWhatsappWeb: 'Prueba en vivo en WhatsApp Web',
    testLiveOnInstagramWeb: 'Prueba en vivo en la web de Instagram',
    seeAllChatFlow: 'Ver todos los flujos de chat',
    launchDemoModal: 'Lanzar Demo Modal',
    selectATemplateToKickStartYourBot: 'Seleccione una plantilla para comenzar su bot',
    hireChatBotDesigner: 'Contratar diseñador de chatbot',
    recommendedForYou: 'Recomendado para ti',
    useTemplate: 'Usar plantilla',
    exportStatus: 'Estado de exportación',
    flowExport: 'Flujo-exportación',
    download: 'Descargar',
    importFlow: 'Flujo de importación',
    beforeYouStartUploadPleaseMakeSure: 'Antes de comenzar a subir por favor asegúrese de',
    ponit1: '1. El límite máximo de carga de tamaño de archivo es 1 MB',
    point2: '2. El formato de archivo debe estar en JSON',
    selectFile: 'Seleccione Archivo',
    preview: 'Avance',
    test: 'Prueba',
    OopsIntegrationAreNotAvailable: '¡Ups! Las integraciones no están disponibles en el ',
    pleaseUpgradeTo: ' Por favor, actualice al ',
    planForIntegration: ' para las integraciones.',
    placeholders: {
      searchTemplateYouNeed: 'Busque en la plantilla que necesita',
    },
    noDataFound: {
      botFlow: 'Flujo de bot',
      noBotFlowAddedYet: '¡No se agregó el flujo de bots!',
      templates: 'Plantillas',
      noTemplatesFound: '¡No se encontró plantilla!',
    },
    appTooltip: {
      exportFlow: 'Flujo de exportación',
      importFlow: 'Flujo de importación',
      clickToViewChatFlow: 'Haga clic para ver el flujo de chat en el constructor de arrastrar y soltar',
      clickToPreviewChatFlow: 'Haga clic para obtener una vista previa del flujo de chat',
      clickToViewChatFlowAsFlowDiagram: 'Haga clic para ver el flujo de chat como un diagrama de flujo',
      clickToTestChatFlow: 'Haga clic para probar el flujo de chat',
      clickToSelectAndImportTemplate: 'Haga clic para seleccionar e importar la plantilla que mejor se adapte a su negocio.',
      clickToInstallYourBotToSelectedPlatform: 'Haga clic para instalar su bot en la plataforma seleccionada',
      clickToTestDefaultFlowOfTelegramBot: 'Haga clic para probar su flujo de bots predeterminado (activo) en la web de telegrama',
      clickToTestDefaultFlowOfFacebookBot: 'Haga clic para probar su flujo de bots predeterminado (activo) en la web de Facebook',
      clickToTestDefaultFlowOfWhatsappBot: 'Haga clic para probar su flujo de bots predeterminado (activo) en WhatsApp Web',
      clickToTestDefaultFlowOfInstagramBot: 'Haga clic para probar su flujo de bots predeterminado (activo) en la web de Instagram',
    }
  },
  createNewBot: {
    createNewBot: 'Crea un nuevo bot',
    stepOneOfTwo: 'Paso 1 de 2',
    selectThePlatform: 'Seleccione la plataforma',
    selectTheBotType: 'Seleccione el tipo de bot',
    aiBasedChatbot: 'Chatbot basado en IA',
    ruleBasedChatBot: 'Chatbot basado en reglas',
    webPlatforms: 'Plataformas web',
    websiteChatBot: 'Sitio web de chatbot',
    withLiveChat: '(Con chat en vivo)',
    landingPageBot: 'Bot de la página de destino',
    liveChatWidget: 'Widget de chat en vivo',
    onlyLiveChat: '(Solo chat en vivo)',
    socialPlatforms: 'Plataformas sociales',
    whatsappChatBot: 'Chatbot de whatsapp',
    telegramChatBot: 'Telegram chatbot',
    facebookChatBot: 'Chatbot de Facebook',
    instagramChatBot: 'Chatbot de Instagram',
    selectBotType: 'Seleccionar tipo de bot',
    selectProblem: 'Seleccione el problema que desea resolver',
    leadGeneration: 'Generación de clientes potenciales',
    customerSupport: 'Soporte al cliente',
    appointmentBooking: 'Reserva de citas',
    marketingAutomation: 'Automatización de marketing',
    ecommerceWebsite: 'Sitio web de comercio electrónico',
    otherUseCase: 'Otros',
    back: 'Atrás',
    typeDomainUrl: 'Escriba la URL de su sitio web para verificar.',
    dataSourceForChatBotTraining: 'Fuente de datos para el entrenamiento del chatbot',
    upload: 'Subir',
    uploadDocuments: 'Cargar documentos',
    uploadDocument: 'Subir documento',
    selectFile: 'Seleccionar archivo',
    continue: 'Continuar',
    websiteUrl: 'Escriba la URL de su sitio web',
    skipNow: 'Saltar ahora',
    completeTheExistingBotSetup: 'Completar la configuración del bot existente',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Ya has creado un bot de WhatsApp. Por favor, completa la configuración para crear uno nuevo.',
    completeSetup: 'Configuración completa',
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    fbBot: {
      createNewBot: 'Crea un nuevo bot',
      help: 'Ayuda',
      back: 'Atrás',
      step: 'Paso',
      of3: ' de 3',
      loginWithFacebook: 'Iniciar sesión con Facebook',
      asPerLatestChangesInFacebookPolicy: 'Según los últimos cambios en las políticas de Facebook, el propietario de Facebook Bot debe tener `gerente` o por encima del rol para acceder a todas las funciones de la página de Facebook.',
      login: 'Acceso',
      connectFacebookBot: 'Conecte Facebook Bot',
      connectBotWithYourFacebookPagesAnd: 'Conecte Bot con sus páginas de Facebook y',
      exploreTruePowerOfFacebook: 'Explore el verdadero poder de Facebook',
      connect: 'Conectar',
      connected: 'Conectado',
      createNewPage: 'Crear nueva página',
      refreshPageList: 'Actualizar la lista de páginas',
      thisPageIsSuccessfullyIntegratedWith: 'Esta página está integrada con éxito con',
      yourMessagesFromMessengerWillAppearIn: '. Tus mensajes de Messenger aparecerán en',
      inbox: ' Bandeja de entrada',
      continueToBotConfiguration: 'Continúa con Bot Configuartion',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenido a ${brand}. Estamos aquí para ayudarte.`,
      letsTryYourBotAndSeeHowItWorks: '¡Probemos tu bot y veamos cómo funciona! Haga clic en "Prueba en Facebook".',
      howAreYouDoingToday: '¿Cómo está hoy?',
      thisIsHowSimpleToUseFacebookBot: '¡Eso es todo! Así de simple es usar Facebook Bot',
      letsCreateYourOwnChatFlowNow: 'Creemos su propio flujo de chat ahora',
    },
    instagramBot: {
      createNewBot: 'Crea un nuevo bot',
      back: 'Atrás',
      step: 'Paso',
      of3: ' de 3',
      loginWithInstagram: 'Iniciar sesión con Instagram',
      loginToYourInstagramAccount: 'Inicie sesión en su cuenta de Instagram para habilitar el chatbot en su mango.',
      managerLevelDescription: 'Los propietarios de Bot de Instagram deben tener al menos un nivel de permisos de "gerente" o más para acceder a todas las características.',
      login: 'Acceso',
      connectInstagramBot: 'Conecte Instagram Bot',
      connectBotWithYourInstagramAnd: 'Conecte Bot con su Instagram y',
      exploreTruePowerOfInstagram: ' Explore el verdadero poder de Instagram',
      connected: 'Conectado',
      connect: 'Conectar',
      createNewPage: 'Crear nueva página',
      refreshPageList: 'Actualizar la lista de páginas',
      thisPageIsSuccessfullyintegratedWith: 'Esta página está integrada con éxito con',
      yourMessagesFromMessengerWillAppearIn: '. Tus mensajes de Messenger aparecerán en',
      inbox: ' Bandeja de entrada',
      continueToBotConfiguration: 'Continúa con Bot Configuartion',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenido a ${brand}. Estamos aquí para ayudarte.`,
      letsTryYourBotAndSeeHowItWorks: '¡Probemos tu bot y veamos cómo funciona! Haga clic en "Prueba en Instagram".',
      howAreYouDoingToday: '¿Cómo está hoy?',
      thisIsHowSimpleToUseInstagramBot: '¡Eso es todo! Así de simple es usar Instagram Bot',
      letsCreateYourOwnChatFlowNow: 'Creemos su propio flujo de chat ahora',
    },
    telegramBot: {
      back: 'Atrás',
      step: 'Paso',
      of3: ' de 3',
      seeHowToUse: 'Vea cómo usar',
      help: 'Ayuda',
      loginToYourTelegramAccount: 'Inicie sesión en su cuenta de Telegram',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Inicie sesión en su cuenta para crear un nuevo bot o conectar' +
        ' el bot existente',
      verificationCodeSentOnRegisteredNumber: 'Código de verificación enviado en el número registrado',
      createYourBot: 'Crea tu bot',
      enterYourBotsNameAnd: 'Ingrese el nombre de su bot, conéctese y deje que la diversión comience con',
      botUserNameMustEndWithBotAtTheEnd: 'El nombre de usuario de BOT debe terminar con el "bot" al final.',
      terrisBot: 'P.ej. teris_bot o terisbot',
      botNameAlreadyTaken: 'Este nombre de bot ya está tomado. Por favor prueba algo diferente',
      connect: 'Conectar',
      createBot: 'Crear bot',
      codeNotReceivedResend: 'Código no recibido? ¡Reense!',
      changeNumber: 'Cambie el número',
      telegramPreview: 'Vista previa de telegrama',
      worldsFastestMessagingApp: '¡La aplicación de mensajería más rápida del mundo y es gratis!',
      bot: 'Bot',
      phoneNumber: 'Número de teléfono',
      userName: 'Nombre de usuario',
      none: 'Ninguno',
      bio: 'Biografía',
      fewWordsAboutYourself: 'Pocas palabras sobre ti',
      settings: 'Ajustes',
      notificationsAndSound: 'Notificaciones y sonidos',
      privacyAndSecurity: 'Privacidad y seguridad',
      dataAndStorage: 'Datos y almacenamiento',
      chatSettings: 'configuraciones de chat',
      chatFolders: 'Carpetas de chat',
      devices: 'Dispositivos',
      connectYourBot: 'Conecte su bot',
      createNewBotOrConnectWithExistingOne: 'Crear nuevo bot o conectar el existente',
      createNewBot: 'Crea un nuevo bot',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenido a ${brand}. Estamos aquí para ayudarte.`,
      letsTryYourBotAndSeeHowItWorks: '¡Probemos tu bot y veamos cómo funciona! Haga clic en "Prueba en Telegram".',
      howAreYouDoingToday: '¿Cómo está hoy?',
      thisIsHowSimpleToUseTelegramBot: '¡Eso es todo! Así de simple es usar Telegram Bot',
      letsCreateYourOwnChatFlowNow: 'Creemos su propio flujo de chat ahora',
      placeholders: {
        searchCountry: 'Búsqueda del país',
        enterTelegramMobileNumber: 'Ingrese el número de móvil Telegram',
        enterLoginCodeReceived: 'Ingrese el código de inicio de sesión recibido en la cuenta de Telegram *',
        twoFactorAuthentication: 'Contraseña de 2FA (opcional)',
        botName: 'Nombre de bot *',
        botUserName: 'Nombre de usuario de BOT *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Crea un nuevo bot',
      seeHowToUse: 'Vea cómo usar',
      help: 'Ayuda',
      back: 'Atrás',
      step: 'Paso',
      of2: ' de 2',
      buildA: 'Construir un',
      thatMatchesYourBrandPersonality: ' que coincide con la personalidad de tu marca',
      chatBotIcon: 'Icono de chatbot',
      optional: 'Opcional',
      themeColor: 'Color del tema',
      chatBotName: 'Nombre de chatbot',
      botNameIsRequired: 'Se requiere el nombre de bots',
      botNameShouldBeLessThan50Characters: 'El nombre de bot debe tener menos de 50 caracteres',
      continue: 'Continuar',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenido a ${brand}. Estamos aquí para ayudarte.`,
      whatIsYourEmailAddress: '¿Cuál es tu dirección de correo electrónico?',
      invalidEmailAddress: 'Dirección de correo electrónico no válida',
      customizeYourLandingPageBotLook: 'Personaliza el aspecto de la página de tu página de destino',
      customizeYourWidgetLook: 'Personaliza tu aspecto de widget',
      customizeYourBotLook: 'Personaliza tu look de bot',
      buildYourLandingPageBotAndAutomateInteraction: 'Cree el bot de su página de destino y automatice la interacción con sus' +
        ' clientes compartiendo su enlace',
      useLiveChatBotToConnectToCustomers: 'Use el bot de chat en vivo para conectarse a sus clientes en tiempo real',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Conecte chatbot a su sitio web y automatice las interacciones con' +
        ' los visitantes',
      widget: 'widget',
      botInterface: 'interfaz BOT',
      mergedBot: 'Chatbot del sitio web, Chatbot de la página de aterrizaje, Chatbot de la aplicación móvil',
      mergedBotToolTip: 'Crea una página de bot para captar la atención de los visitantes y entablar conversaciones con ellos.',
      placeholders: {
        typeANameForYourBot: 'Escriba un nombre para su nuevo bot',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Ingrese su nombre de bot',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Seleccione un color de tema para su ventana de chat',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Este logotipo se utilizará solo en la plataforma ${brand ? brand : ''}. Sugerimos elegir el logotipo de su empresa. Agregue una imagen cuadrada PNG o JPG bajo 1 MB.`,
      }
    },
    whatsappBot: {
      back: 'Atrás',
      justAStepAway: 'A solo un paso de distancia',
      seeHowToUse: 'Vea cómo usar',
      help: 'Ayuda',
      createWhatsappChatBot: 'Crear chatbot de whatsapp',
      businessNameIsRequired: 'Se requiere nombre comercial.',
      maximumLengthForBusinessName40Characters: 'La longitud máxima para el nombre comercial es de 40 caracteres.',
      businessCategory: 'categoría de negocios',
      categoryIsRequired: 'La categoria es requerida.',
      descriptionIsRequired: 'Se requiere descripción.',
      maximumLengthForDescription200Characters: 'La longitud máxima para la descripción es de 200 caracteres.',
      pleaseFillAValidEmailAddress: 'Complete un correo electrónico comercial válido.',
      businessEmailIsRequired: 'Se requiere correo electrónico comercial.',
      phoneNumberShouldHaveMinimum7Digits: 'El número de teléfono debe tener un mínimo de 7 dígitos',
      phoneNumberIsRequired: 'Se requiere número de teléfono',
      websiteIsRequired: 'Se requiere sitio web.',
      maximumLengthForWebsite40Characters: 'La longitud máxima para el sitio web es de 40 caracteres.',
      maximumLengthForAddress150Characters: ' La longitud máxima para la dirección es de 150 caracteres.',
      doYouHaveWhatsappApiKey: '¿Tienes la tecla API de WhatsApp?',
      apiProvider: 'Proveedor de API',
      three60Dialog: 'Diálogo 360',
      whatsappCloud: 'Nube de whatsapp',
      netcore: 'Netcore',
      apiKeyIsRequired: 'Se requiere la clave API.',
      whatsappBusinessAccountIdIsRequired: 'Se requiere una ID de cuenta de WhatsApp Businness.',
      whatsappPhoneNumberIdIsRequired: ' Se requiere una identificación de número de teléfono de WhatsApp.',
      systemAccessTokenIsRequired: 'Se requiere el token de acceso al sistema.',
      sourceIdIsRequired: 'Se requiere ID de origen.',
      // apiKeyIsRequired: 'Se requiere la clave API.',
      // createWhatsappChatBot: 'Crear chatbot de whatsapp',
      businessName: 'Nombre del Negocio',
      businessAccount: 'Cuenta de negocios',
      // businessCategory: 'categoría de negocios',
      businessDescription: 'descripción del negocio',
      address: 'DIRECCIÓN',
      businessEmail: 'Email de negocios',
      websiteUrl: 'URL del sitio web',
      thankYouForYourInterestIn: 'Gracias por su interés en',
      whatsappChatBot: ' WhatsApp Chatbot.',
      weHaveReceivedYourInterests: 'Hemos recibido su interés y nuestro equipo de ventas se pondrá en contacto con usted en las próximas 24 horas.',
      theyWillProvideYouWhatsappApiKey: 'Te proporcionarán la tecla API de WhatsApp.',
      readyToCreateWhatsappBot: 'Estoy listo para crear WhatsApp Bot',
      exploreWhatsappFeatures: 'Explorar las características de WhatsApp',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenido a ${brand}. Estamos aquí para ayudarte.`,
      letsTryYourBotAndSeeHowItWorks: '¡Probemos tu bot y veamos cómo funciona! Haga clic en "Prueba en WhatsApp".',
      howAreYouDoingToday: '¿Cómo está hoy?',
      thisIsHowSimpleToUseWhatsappBot: '¡Eso es todo! Así de simple es usar WhatsApp Bot',
      letsCreateYourOwnChatFlowNow: 'Creemos su propio flujo de chat ahora',
      connectWhatsAppBot: 'Conectar Bot de WhatsApp',
      createtWhatsAppBot: 'Crear Bot de WhatsApp',
      proceed: 'Continuar',
      whatsApiProvider: 'Proveedor de API de WhatsApp',
      cloudApi: 'API en la Nube',
      netcoreApi: 'API Netcore',
      placeholders: {
        yourBusinessName: 'Tu nombre comercial',
        businessDescription: 'descripción del negocio',
        businessEmail: 'email de negocios',
        phone: 'Teléfono*',
        website: 'Sitio web (por ejemplo, www.example.com)',
        address: 'DIRECCIÓN',
        enterApiKey: 'Ingrese la clave API',
        enterWhatsappBusinessAccountId: 'Ingrese la ID de cuenta de WhatsApp Businness',
        enterWhatsappPhoneNumberId: 'Ingrese la identificación del número de teléfono de WhatsApp',
        enterSystemAccessToken: 'Ingrese el token de acceso al sistema',
        enterSourceId: 'Ingrese la ID de origen',
        businessName: 'Nombre de la Empresa',
        businessWebsite: 'Sitio web de la Empresa',
        selectWhatsappApiProvider: 'Seleccione el proveedor de API de WhatsApp',

      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'Elige tu proveedor de API de WhatsApp',
        doYouHaveWhatsAppBusinessAPI: '¿Tienes WhatsApp Business API?',
        yes: 'Sí',
        noIAmNotSure: 'No, no estoy seguro/a',
        selectApiProvider: 'Seleccionar proveedor de API',
        whatsAppCloudAPI: 'WhatsApp Cloud API',
        enterYourPhoneNumberId: 'Ingresa tu ID de número de teléfono',
        phoneNumberId: 'ID de número de teléfono',
        enterYourWhatsAppBusinessAccountId: 'Ingresa tu ID de cuenta de WhatsApp Business',
        whatsAppBusinessAccountId: 'ID de cuenta de WhatsApp Business',
        enterYourSystemUserToken: 'Ingresa tu token de usuario del sistema',
        systemUserToken: 'Token de usuario del sistema',
        help: 'Ayuda',
        netcoreAPI: 'API de Netcore',
        enterYourSourceId: 'Ingresa tu ID de origen',
        sourceId: 'ID de origen',
        enterYourNetcoreApiKey: 'Ingresa tu clave de API de Netcore',
        netcoreApiKey: 'Clave de API de Netcore',
        submit: 'Enviar',
        noneOftheAbove: 'Ninguno de los anteriores',
        next: 'Siguiente'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Negocio legalmente registrado',
        validWebsiteWithDataPrivacyPolicy: 'Sitio web válido con política de privacidad de datos',
        gstin: 'GSTIN',
        debit: 'Débito',
        creditCard: 'Tarjeta de crédito',
        onlyRequiredForIndianResidents: 'Solo requerido para residentes indios',
        onlyRequiredFor: 'Solo requerido para',
        indianResidents: 'Residentes de la India',
        validPaymentMethod: 'Método de pago válido',
        enabledWithInternationalPayments: 'habilitado con pagos internacionales',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'Caso de uso válido cumpliendo la política de comercio de WhatsApp',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Número de teléfono que no tiene WhatsApp activo y es capaz de recibir el SMS',
        discard: 'Descartar',
        proceed: 'Continuar',
        whatsAppBotPrerequisites: 'Requisitos previos para el bot de WhatsApp',
        letUsGetItDoneForYou: '¡Deja que lo hagamos por ti!',
        ifYouAreFacingSomeIssues: 'Si estás enfrentando algunos problemas',
        ourTeamCanHelpYouDoTheSame: 'Nuestro equipo puede ayudarte a hacer lo mismo',
        requestACallbackNow: '¡Solicita una llamada ahora!',
      },
      stepThree: {
        setupWhatsAppBot: 'Configurar Bot de WhatsApp',
        selectingTheBestBusinessManagerForYourNeeds: 'Seleccionar el Mejor Gerente Comercial para sus Necesidades',
        createMetaApp: 'Cómo crear una aplicación de Meta',
        createaMetaAppOn: 'Crear una Meta App en',
        howToCreate: 'Cómo crear',
        addWhatsAppNumber: 'Cómo agregar un número de WhatsApp',
        addYourWhatsAppNumberAndVerifyTheSame: 'Agregar su Número de WhatsApp y Verificarlo',
        subscribe: 'Suscribirse',
        copyAndSubscribeWebhook: 'Copiar y suscribirse al Webhook',
        howToSubscribe: 'Cómo suscribirse',
        copy: 'Copiar',
        appSecretId: 'ID de Secreto de la App',
        enterYourAppSecretId: 'Ingrese su ID de Secreto de la App',
        enterAppSecret: 'Ingresar Secreto de la App',
        phoneNumberId: 'ID de Número de Teléfono',
        whatsAppBusinessId: 'ID de WhatsApp Business',
        assignSystemUser: 'Cómo asignar un usuario del sistema',
        assign: 'Asignar',
        createApp: 'Crear Meta',
        registerAndVerify: 'Registrarse y Verificar',
        add: 'Agregar',
        systemUserToken: 'Token de Usuario del Sistema',
        enterYourSystemUserToken: 'Ingrese su Token de Usuario del Sistema',
        getAllNecessaryDetails: 'Cómo obtener todos los detalles necesarios',
        subscribeWhatsAppWebhook: 'Cómo suscribirse al Webhook WA',
        configureTheWebhookForWhatsAppAccount: 'Configurar el Webhook para la cuenta de WhatsApp',
        upadatePaymentMethod: 'Cómo actualizar el método de pago',
        assignThePaymentMethodToWhatsAppAccount: 'Asignar el Método de Pago a cualquier cuenta de WhatsApp',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Conceder control de una cuenta de WhatsApp Business a un usuario del sistema',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Detalles clave que necesitas copiar desde el Panel de Meta',
        updatePayment: 'Actualizar Pago',
        letUsGetItDoneForYou: '¡Deja que lo hagamos por ti!',
        ifYouAreFacingSomeIssues: 'Si estás enfrentando algunos problemas',
        ourTeamCanHelpYouDoTheSame: 'Nuestro equipo puede ayudarte a hacer lo mismo',
        requestACallbackNow: '¡Solicita una llamada ahora!',
        update: 'Actualizar',
        connectNow: 'Conectar ahora'
      },
      stepFive: {
        selectBusinessManager: 'Seleccionar Gerente Comercial',
        selectingTheBestBusinessManagerForYourNeeds: 'Seleccionar el Mejor Gerente Comercial para sus Necesidades'

      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Su bot está instalado y activo',
        youhaveSuccessfullyConnected: 'Se ha conectado con éxito',
        withWhatsApp: 'con WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'Se le han asignado 100 conversaciones por mes de forma predeterminada',
        upgradeYourPlanForMore: 'Actualice su plan para obtener más.'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Escanear el código QR o Enviar un mensaje para activar el Bot',
        scanTheQrCodeFrom: 'Escanear el código QR desde',
        yourPhoneWillOpen:
          'Tu teléfono abrirá un mensaje prellenado para enviar a nuestro número de Sandbox. Presiona "Enviar" en WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Enviar mensaje desde tu número de WhatsApp',
        sendUsAWhatsAppMessageOn: 'Envíanos un mensaje de WhatsApp en ',
        bySavingInYourContactsAndMessageShouldStartWith: ' al guardar en tus contactos y el mensaje debe comenzar con ',
        send: 'Enviar',
        or: 'O'
      }
    }
  },
  gettingStarted: {
    hello: 'Hola,',
    upgrade: 'Mejora',
    gettingStarted: 'Empezando -',
    yourFacebookBotToken: 'Tu token de bot de Facebook',
    hasBeenExpired: 'ha expirado',
    isExpiringIn: 'está expirando en',
    days: ' días',
    facebookAuthenticationTokenRemainsFor60Days: 'El token de autenticación de Facebook permanece activo solo durante 60 días',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Por favor, proceda con la renovación de su token de bot para asegurar el acceso ininterrumpido a los servicios del chatbot.',
    renewYourBotToken: 'Renovar su token de bot',
    editYourChatFlow: 'Edite su flujo de chat',
    buildConversationEngagingBot: 'Cree flujos de conversación atractivos para los usuarios de sus bot.',
    takes5minutes: 'Toma 5 minutos',
    previewYourLiveChatWidget: 'Vista previa de su widget de chat en vivo',
    discoverHowThe: 'Descubre cómo el',
    liveChatFeatureWorks: ' La función de chat en vivo funciona usando nuestro patio de juegos en vivo',
    takes2minutes: 'Toma 2 minutos',
    takes1minute: 'Toma 1 min',
    connected: 'Conectado',
    customizeYourBotApperance: 'Personaliza la apariencia y la funcionalidad de tu bot.',
    takes3minutes: 'Toma 3 minutos',
    thirdPartyAppsIntegration: 'Integración de aplicaciones de terceros',
    integrateYourBot: 'Integre su bot con otras aplicaciones/CRMS para crear automatización de negocios.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Actualice su plan para recibir clientes potenciales en su correo electrónico',
    getStartedWithUs: 'Empiece con nosotros',
    howToBuildAGoodChatFlow: 'Cómo construir un buen flujo de chat',
    howToUseBotPenguinsInboxAndItsFeatures: 'Cómo usar la bandeja de entrada de Botpenguin y sus características',
    botPenguinsPricing: 'Precios de botpenguin: explorar planes y características',
    openHelpDesk: 'Abrir la mesa de ayuda',
    helpAndSupport: 'Ayuda y apoyo',
    extensiveGuides: 'Guías extensas para todas sus consultas y flujos de trabajo están disponibles en nuestro',
    resourceCentre: 'Centro de Recursos',
    checkoutOurAnswers: 'Puede consultar nuestras respuestas a las consultas comunes a continuación.',
    createANewBot: 'Crea un nuevo bot',
    installYourBot: 'Instale su bot',
    plansAndPricing: 'Planes y precios',
    previewYourLandingPageBot: ' Vista previa de su página de la página de destino',
    installYourLiveChatWidget: 'Instale su widget de chat en vivo',
    installYourWebsiteBot: 'Instala el bot en tu dispositivo',
    integrateBotToLandingPage: 'Integre el bot a su página de la página de destino',
    installWidgetToWebsite: 'Instale el widget en su sitio web',
    installBotToWebsite: 'Instala tu chatbot',
    whatsappAutomation: 'Automatización de whatsapp',
    facebookAutomation: 'Automatización de Facebook',
    telegramBotSettings: 'Configuración de bot de telegrama',
    liveChatWidgetSettings: 'Configuración de widget de chat en vivo',
    landingPageBotSettings: 'Configuración de bot de página de destino',
    websiteChatBotSettings: 'Configuración de chatbot del sitio web',
    instagramAutomation: 'Automatización de Instagram',
    diveDeeperIntoBotPenguin: 'Sumergirse más en botpenguin',
    knowWhereWeAreGoing: 'Sepa a dónde vamos, las actualizaciones recientes y nos ayudamos a solucionar algunos errores.',
    productUpdates: 'Actualizaciones de Producto',
    requestAFeature: 'Solicitar una función',
    reportABug: 'Reportar un error',
    prodcutRoadmap: 'Hoja de ruta de productos',
    trainYourChatbot: 'Entrena a tu Chatbot de IA (base de conocimientos)',
    buildEngagingConversation: 'Cree flujos de conversación atractivos para los usuarios de su bot.',
    previewYourAIBot: 'Obtenga una vista previa de su bot de IA',
    integrateAIbot: 'Integre el bot a su bot de IA',
    aiBotSettings: 'Configuración de bots de IA',
    CustomizeAIBot: 'Personaliza la apariencia y la funcionalidad de tu bot.',
    gptKeyWarning: '¡Bienvenido a nuestro servicio ChatGPT API! Ten en cuenta que hay una limitación de 100 mensajes para cada bot. Después de alcanzar el límite de 100 mensajes, deberás usar tu propia clave de API ChatGPT para seguir disfrutando de nuestro servicio. Para ingresar la clave de API ChatGPT, ve a la Configuración del Modelo ChatGPT.',
    whatsappBotSettings: 'Configuración de Bot de WhatsApp',
    automateCustomerEngagementWithWhatsApp: 'Automatización del compromiso con el cliente mediante WhatsApp',
    connectToYourWhatsappNumber: 'Conectar a tu número de WhatsApp',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Conectar tu Bot de WhatsApp con tu número de WhatsApp',
    facebookBotSettings: 'Configuración del Bot de Facebook',
    automateCustomerEngagementWithTelegram: 'Automatizar la participación del cliente con Telegram',
    automateCustomerEngagementWithFacebook: 'Automatizar la participación del cliente con Facebook',
    automateCustomerEngagementWithInstagram: 'Automatizar la participación del cliente con Instagram',
    telegramAutomation: 'Automatización de Telegram',
  },
  install: {
    previewYourLandingPageBot: 'Vista previa de su página de la página de destino',
    preview: 'Avance',
    test: 'Prueba',
    squareSpace: 'Squarespace',
    installYourChatBot: 'Instala tu chatbot en el sitio web, en la aplicación móvil o lánzalo como página de destino o chatbot incrustado',
    seeHowToUse: 'Vea cómo usar',
    help: 'Ayuda',
    uniqueLinkToLandingPageBot: 'Aquí está el enlace único a la página de su página de destino.',
    shareOnSocialMedia: 'Simplemente compártelo con sus usuarios en las redes sociales, las páginas de contacto o las tarjetas de presentación.',
    or: 'O',
    copyUrl: 'Copiar URL',
    selectYourWebsiteCms: 'Seleccione su sitio web CMS',
    selectCms: 'Seleccione el CMS/Builder que ha utilizado para crear su sitio web',
    custom: 'Costumbre',
    wordpress: 'WordPress',
    shopify: 'Shop',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Otros CMS',
    howWouldYouLikeToInstall: '¿Cómo le gustaría instalarlo?',
    installingWebsiteBot: 'Elija Instalar el bot en su sitio web',
    iWillAddMyself: 'Me agregaré',
    getChatBotScript: 'Obtenga el código de script de chatbot a continuación e instale todo lo mismo',
    getWordpressPlugin: 'Obtener la tecla API de complemento de WordPress',
    getOurOfficialPlugin: 'Obtenga nuestro complemento oficial',
    fromWordpressPluginStore: ' de la tienda de complementos de WordPress',
    getPlugin: 'Obtener complemento',
    quickGuideToInstall: 'Guía rápida para instalar',
    placeApiKeyBelowIn: 'Pega la tecla API a continuación en',
    afterDownloadingAndActivatingPlugin: ' Después de descargar y activar el complemento',
    copy: 'Copiar',
    enterYourWebsite: 'Ingrese su sitio web y veamos cómo se ven sus chatbots',
    enterAValidUrl: 'Ingrese una URL válida',
    verifyYourInstallation: 'Verifique si su instalación es exitosa o no?',
    testInstallation: 'Instalación de pruebas',
    getChatBotScriptCode: 'Obtenga el código de script de chatbot',
    copyThisCodeSnippet: 'Copie este fragmento de código e insértelo en el HTML de cada página web donde desea mostrar el chatbot o pegar en el pie de página antes de que la etiqueta se cierre para aparecer en cada página.',
    // copy: 'Copiar',
    sendCodeToMyDeveloper: 'Enviar código a mi desarrollador',
    sendCodeToMyDevelopmentTeam: 'Enviar código a mi equipo de desarrollo',
    sendEmail: 'Enviar correo electrónico',
    enterAValidEmail: 'Introduzca un correo electrónico válido',
    codeHasBeen: 'El código ha sido',
    toYourDeveloper: ' a tu desarrollador',
    resent: 'resentirse de',
    sent: 'enviado',
    resendEmail: 'Reenviar email',
    ask: 'Preguntar',
    supportTeamToInstall: ' Equipo de apoyo para instalar',
    raiseTheInstallationRequestTo: 'Elevar la solicitud de instalación a',
    team: ' Equipo',
    helpMeInstallingChatBot: 'Ayúdame a instalar chatbot',
    yourBotInstallationIsNotActive: '¡La instalación de su bot no está activa!',
    monthlyMessageLimitExceeded: 'Límite mensual de mensajes exeidados',
    exceededMonthlyMessageLimitUpgradeNow: 'Ha excedido su límite mensual de mensajes, actualización ahora.',
    upgradeNow: 'Actualizar ahora',
    botInstallationNotActivePleaseFix: 'Su instalación de BOT no está activa, solucione lo mismo.',
    incorrectBotScript: 'Script de bot incorrecto',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'El código de instalación que está verificando no coincide con el de su sitio. Asegúrese de haber instalado el código correcto para este bot.',
    unableToSee: 'Incapaz de ver',
    codeOnYourWebsite: ' Código en su sitio web',
    unableToFindCodePleaseReinstall: 'No podemos encontrar el código en su sitio web, por favor reinstale.',
    reinstallBot: 'Reinstalar BOT',
    yourBotIsInstalledAndActive: '¡Tu bot está instalado y activo!',
    noPendingAction: 'No hay acción pendiente, su bot está instalado y activo',
    youAreAllSet: '¡Estas listo!',
    showMeMyLeads: 'Muéstrame mis leads',
    connectingToYourWebsiteToVerifyInstallation: 'Conectarse a su sitio web para verificar la instalación de BOT',
    thisMayTakeUpto30Seconds: 'Esto puede demorar hasta 30 segundos',
    hereIsTheuniqueAILink: 'Aquí está el enlace único a su AI Bot',
    installHeading: 'Instala tu chatbot',
    selectMobileDevice: 'Selecciona tu dispositivo móvil',
    mobileDeviceSubheading: 'Selecciona el idioma nativo que has utilizado para crear tu aplicación móvil',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Otro',
    copyEmbeddedCodeSnippet: 'Copia este fragmento de código e insértalo en el HTML de cada página web donde quieras mostrar el chatbot.',
    copyMobileCodeSnippet: 'Copia este fragmento de código en el código de tu aplicación móvil donde quieras mostrar el chatbot.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'Ingrese el correo electrónico del desarrollador',
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Enviar instrucciones a su desarrollador',
      notSureHowToDoIt: '¿No estas seguro de cómo hacerlo? Tus desarrolladores pueden hacerlo en un Jiff.',
      developersEmail: 'Correo electrónico del desarrollador',
      emailIsRequired: 'correo electronico es requerido',
      subject: 'Sujeto',
      message: 'Mensaje',
      send: 'Enviar',
      placeholders: {
        enterYourWorkEmail: 'Ingrese su correo electrónico de trabajo',
        createBotUsingBotPenguin: '¡Entusiasmado! Crear bot usando botpenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Aquí está el enlace único a la página de su página de destino.',
      shareWithUsersOnSocialMedia: 'Simplemente compártelo con sus usuarios en las redes sociales, las páginas de contacto o las tarjetas de presentación.',
      previewMyLandingPageBot: 'Vista previa de mi página de la página de destino',
      or: 'O',
      copyUrl: 'Copiar URL',
      howYouLikeToAddBot: 'Elija cómo le gustaría agregar su código BOT a su sitio web',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Instale el bot en su sitio web personalizado codificado o de WordPress mediante la copia de un código único.',
      name: 'Nombre',
      actions: 'Comportamiento',
      javascript: 'Javascript',
      install: 'Instalar',
      onYourCustomCodedWebsite: ' En su sitio web codificado personalizado',
      copyAndPaste: 'Copiar y pegar',
      copyThisCodeSnippet: 'Copie este fragmento de código e insértelo en el HTML de cada página web donde desea mostrar el widget. Para más detalles',
      seeGuide: 'Ver guía',
      getCode: 'Obtener código',
      howToInstall: '¿Cómo instalar?',
      copyCode: 'Copiar código',
      wordpress: 'WordPress',
      // install: 'Instalar',
      onYourWordPressWebiste: ' En su sitio web de WordPress',
      preview: 'Avance',
      test: 'Prueba',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Vista previa del chat en vivo',
    agentWindow: 'Ventana de agente',
    person: 'persona',
    inOrderToTestTypeHere: 'Para probar el tipo aquí',
    agentWindowCannotBeCustomized: 'Nota: la ventana del agente no se puede personalizar',
    customerWindow: 'Ventana del cliente',
    clickHereToChat: 'Presione aqui para chatear',
    customerWillChatHere: 'El cliente chateará aquí',
    chat: 'Charlar',
    by: 'por',
    changeYourBotLookFromLeftPanel: 'Cambie su aspecto de bot desde el panel izquierdo',
    placeholders: {
      typeYourMessage: 'Escribe tu mensaje...',
      typeYourAnswer: 'Escribe tu respuesta',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Vista previa de su widget de chat en vivo',
    install: 'Instalar',
    seeHowToUse: 'Vea cómo usar',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Haga clic para instalar su bot en la plataforma seleccionada',
    }
  },
  trainYourBot: {
    trainYourBot: 'Train Your Chatbot',
    knowledgeBase: 'Knowledge Base',
    faqs: 'FAQ\'s',
    history: 'History',
    faqHeading: 'Frequently asked questions',
    faqSubHeading: 'Answers to Your Chatbot Training Questions',
    knowledgeBaseHeading: 'Entrena a tu Chatbot',
    knowledgeBaseSubheading: 'Entrena a tu Chatbot para Conversaciones Mejoradas',
    websiteLink: 'Enlace del Sitio Web',
    uploadFile: 'Cargar tu archivo',
    selectFile: 'Seleccionar archivo',
    submit: 'Enviar',
    searchHere: 'Buscar aquí',
    question: 'Pregunta',
    answer: 'Respuesta',
    questionPlaceholder: 'Escribe tu pregunta aquí',
    answerPlaceholder: 'Escribe tu respuesta aquí',
    save: 'Guardar',
    discard: 'Descartar',
    addFaq: 'Agregar Preguntas Frecuentes',
    createdAt: 'Creado en',
    updatedAt: 'Última actualización en',
    actions: 'Acciones',
    noFaqFound: 'Aún no tienes ninguna pregunta frecuente creada',
    noFaqFoundMessage: 'Todas tus preguntas frecuentes aparecerán aquí',
    historyTitle: 'Historial de la base de conocimientos',
    historySubtitle: 'Respuestas a tus preguntas sobre entrenamiento de Chatbot',
    fileName: 'Nombre del archivo',
    status: 'Estado',
    noHistoryTitle: 'Aún no tienes ningún modelo entrenado creado',
    noHistoryDescription: 'Todos tus modelos entrenados aparecerán aquí',
    fileUpload: 'Subir archivo',
    files: 'Archivos',
    fileSubHeading: 'Agrega todos los archivos en los que deseas entrenar tu bot.',
    noFileFoundHeading: 'Aún no tienes ningún archivo creado',
    noFileFoundSubheading: 'Todos tus archivos aparecerán aquí',
    uploadYourFiles: 'Sube tus archivos',
    upload: 'Subir',
    fileShouldBe: 'El archivo debe ser en formato PDF, DOC, DOCX, PPTX, TXT.',
    uploadStatus: 'Estado de carga',
    uploadStatusSubHeading: 'Estado de carga de archivos para el entrenamiento del bot',
    addFile: 'Agregar archivo',
    otherConfiguration: 'Configuración de IA',
    deleteFiles: 'Haz clic para eliminar archivos',
    websiteURL: 'URLs del sitio web',
    websiteUrlSubheading: 'Agregue todas las URLs del sitio web en las que desea entrenar a su bot.',
    enterWebsiteUrl: 'Ingresar la URL',
    addWebsite: 'Agregar sitio web',
    tableWebsiteUrl: 'URL del sitio web',
    trainingStatus: 'Estado del entrenamiento',
    lastTrained: 'Último entrenamiento',
    noWebsitesFound: 'Todavía no tiene ningún sitio web creado',
    noWebsitesFoundMessage: 'Todos sus sitios web aparecerán aquí',
    loadMore: 'Cargar más',
    botBehaviour: 'Configurar el comportamiento del bot',
    botBehaviourSubHeading: 'Configurar la personalidad del bot, redactar datos del cliente, manejar el flujo y el modelo ChatGPT',
    botPersonality: 'Configurar la personalidad del bot',
    botPersonalitySubHeading: 'Administrar cómo debería responder su bot en términos de personalidad',
    selectAnswerLength: 'Seleccionar longitud de respuesta',
    answerLength: 'Longitud de respuesta',
    answerAs: 'Respuesta como',
    tone: 'Tono',
    language: 'Idioma',
    answerFormatting: 'Formato de respuesta',
    customInstructions: 'Instrucción Personalizada',
    customerInstructionPlaceholder: 'Sea cortés y amable. No utilice jerga. No utilice emojis.',
    redactCustomerData: 'Recopilar datos del cliente',
    redactDataSubheading: 'Configure la información del cliente que desea recopilar',
    collectName: 'Recopilar nombre',
    configureQuestion: 'Configurar pregunta',
    maxCharacterLimit: 'Límite máximo de caracteres en el nombre proporcionado',
    collectEmail: 'Recopilar correo electrónico',
    excludeEmail: 'Excluir correos electrónicos con dominio',
    collectPhone: 'Recopilar número de teléfono',
    manageUnusualMessages: 'Gestionar escenarios de chat inusuales',
    unusualMessagesSubheading: 'Manejar los escenarios raros y sin respuesta para el bot',
    startConversation: 'Mensaje para iniciar la conversación',
    configureMessage: 'Mensaje cuando el bot no puede responder',
    profanityMessage: 'Mensaje para responder al uso de lenguaje inapropiado',
    chatGptModel: 'Configuración del modelo ChatGPT',
    chatGptModelSubheading: 'Administrar qué modelo ChatGPT utilizar y evitar el mal uso',
    chatGptApi: 'Clave de API de ChatGPT',
    enableDisableRedactData: 'Recopilar datos del cliente',
    triggerToCollectData: 'Recopilar detalles de contacto después de un conjunto de preguntas.',
    train: 'Entrenar',
    website: 'sitio web',
    includedSourcesForResponse: 'Fuentes incluidas para la respuesta',
    visibility: 'Visibilidad',
    analytics: 'Análisis',
    yourConversation: 'Tus conversaciones',
    reviewAndSelectConversation: 'Revisar y seleccionar conversaciones para exportar para el entrenamiento del chatbot',
    exportAiAnalytics: 'Exportar análisis',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    userQuestion: 'Pregunta del usuario',
    botsReply: 'Respuesta del bot',
    dateCreated: 'Fecha de creación',
    noAnalyticsFound: 'Todavía no has creado ninguna analítica',
    noAnalyticsFoundMessage: 'Todas tus mensajes de análisis aparecerán aquí',
    exportData: 'Exportar datos',
    exportCompleteAnalyticsData: 'Exportar datos completos de análisis',
    export: 'Exportar',
    integrateWithChatGpt: 'Integrar con ChatGPT',
    integrated: 'Integrado',
    configure: 'Configurar',
    appToolTip: {
      deleteWebsite: 'Eliminar sitio web',
      knowledgeBase: 'Entrena a tu bot con la base de conocimientos',
      faqs: 'Entrena a tu bot con preguntas frecuentes',
      knowledgeHistory: 'Historial de la base de conocimientos',
      uploadFaqs: 'Subir preguntas frecuentes',
      addFaqs: 'Haz clic para añadir preguntas frecuentes',
      deleteFaqs: 'Eliminar preguntas frecuentes',
      deleteHistory: 'Eliminar historial',
      uploadFile: 'El archivo subido debe ser en formato PDF, DOCX, DOC o CSV y tener un tamaño máximo de 1 MB.',
      trainWebsite: 'Entrene a su bot utilizando un sitio web.',
      fileUpload: 'Entrene a su bot utilizando archivos como PDFs, TXTs, PPTXs, DOCs y DOCXs.',
      configureBotBehaviour: 'Personalice la personalidad del bot, maneje el flujo y ajuste el modelo ChatGPT.',
      clickToAddWebsite: 'Haga clic para agregar un sitio web',
      clickToDeleteWebsites: 'Haga clic para eliminar sitios web',
      syncWebsite: 'Sincronizar sitio web',
      copyUrl: 'Copiar URL',
      weWillBeFetchingAllTheWebpages: 'Vamos a obtener todas las páginas web y entrenar el modelo ChatGPT en ellas.'
    },
    modal: {
      deleteWebsiteUrl: 'Eliminar URL del sitio web',
      areYouSureYouWantToDeleteThisWebsiteUrl: '¿Está seguro de que desea eliminar esta URL del sitio web?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Configuración del chatbot del sitio web',
    preview: 'Vista previa',
    install: 'Instalar',
    test: 'Prueba',
    designHeader: 'Diseño',
    generalHeader: 'General',
    alertsHeader: 'Alertas',
    triggerHeader: 'Desencadenar',
    userAccessHeader: 'Acceso de usuario',
    chatWindowHeader: 'Ventana de chat',
    otherConfiguration: 'Configuración de IA',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Haga clic para instalar su bot en la plataforma seleccionada',
      customizeAppearanceOfBot: 'Personalice el posicionamiento y la apariencia de su interfaz BOT.',
      configureBotsSettingsWhenAgentUnavailable: 'Configure la configuración de Bot cuando su agente no esté disponible.',
      configureHowBotWillAlertYou: 'Configure cómo su bot le alertará sobre nuevos clientes potenciales.',
      makeSureYourBotsPopUpProperlyTimed: 'Asegúrese de que las ventanas emergentes de su bot estén perfectamente cronometradas.',
      customizeWhereYouWantYourBotToAppear: 'Personaliza dónde quieres que aparezca tu bot y dónde no.',
      enableChatOptionsForVisitorsToTransferChat: 'Habilite las opciones de chat para que sus visitantes transfieran chats, chat en vivo y actualizar el chat.',
      configureBotBehaviour: 'Personalice la personalidad del bot, maneje el flujo y ajuste el modelo ChatGPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Configurar la configuración de alerta de su bot',
      specifyHowYourBotWillNotify: 'Especifique cómo su bot le notificará sobre nuevos clientes potenciales.',
      subject: 'Sujeto',
      emailNotification: 'Notificación de correo electrónico',
      emailAddresses: 'Correos electrónicos',
      leadRevisitNotification: 'Notificaciones de Revisit para liderar',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'Se ha registrado con éxito con notificaciones de escritorio',
      youHaveBlockedYourNotificationsOnBrowser: 'Ha bloqueado notificaciones en la configuración de su navegador.',
      discard: 'Desechar',
      saveChanges: 'Guardar cambios',
      seeHowToUse: 'Vea cómo usar',
      placeholders: {
        enterSubjectForYourEmail: 'Ingrese el tema para su correo electrónico',
        typeYourEmailAddressAndHitEnter: 'Escriba su dirección de correo electrónico y presione Enter',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Personalice el tema de su correo electrónico de notificaciones desde el bot',
        TOOLTIP_EMAIL_NOTIFICATION: 'Habilitar/deshabilitar la notificación del bot en su correo electrónico para nuevos clientes potenciales',
        TOOLTIP_EMAIL_ADDRESS: 'Envíe la dirección de correo electrónico de la cuenta donde desea recibir notificaciones por correo electrónico. Puede agregar varias direcciones de correo electrónico ingresando una y presionando Enter para agregar otra.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Habilitar/deshabilitar la notificación de BOT en su correo electrónico si un usuario previamente capturado como plomo interactúa nuevamente con el bot.',

      }
    },
    broadcasts: {
      createBroadcastHeading: 'Crear transmisión',
      youCanCreateMultipleBroadcasts: 'Puedes crear múltiples transmisiones',
      broadcastName: 'Nombre de transmisión',
      broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
      continue: 'Continuar',
      manageBroadcasts: 'Administrar transmisiones',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'Puede crear múltiples transmisiones con múltiples plantillas programadas para diferentes Grupoos de clientes.',
      createNewBroadcast: 'Crea una nueva transmisión',
      name: 'Nombre',
      segment: 'Grupoo',
      template: 'Plantilla',
      contacts: 'Contactos',
      send: 'Enviar',
      delivered: 'Entregado',
      read: 'Leer',
      failed: 'Fallido',
      broadcastAt: 'Transmitir a',
      status: 'Estado',
      actions: 'Comportamiento',
      nA: 'N / A',
      seeHowToUse: 'Vea cómo usar',
      deleteBroadcast: 'Eliminar la transmisión',
      wantToDeleteThisBroadcast: '¿Estás seguro de que quieres eliminar esta transmisión?',
      placeholders: {
        searchHere: 'Busca aquí',
        enterBroadcastName: 'Ingrese el nombre de la transmisión',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Eliminar múltiples transmisiones',
        broadcastAnalytics: 'Análisis de transmisión',
        deleteBroadcast: 'Eliminar la transmisión',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Insights de transmisión',
        broadcastStatus: 'Estado de transmisión',
        youCanFindDetailsOfEachContactBelow: 'Puede encontrar detalles de cada contacto a continuación y exportar los que pueden tener problemas.',
        filterApplied: 'Filtro aplicado',
        placeholders: {
          searchByPhoneNumber: 'Buscar por número de teléfono',
          searchByName: 'Filtro de búsqueda por nombre',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Filtrar el estado basado en datos',
          exportData: 'Exportar datos',
        },
        modal: {
          exportData: 'Exportar datos',
          exportCompleteBroadcastData: 'Exportar datos completos de transmisión',
          export: 'Exportar',
        }
      },
      createBroadcast: {
        broadcastName: 'Nombre de transmisión',
        broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
        template: 'Plantilla',
        segments: 'Grupoos',
        whenToSend: 'Cuando enviar',
        now: 'AHORA',
        later: 'MÁS TARDE',
        selectBroadcastUsing: 'Seleccione la transmisión usando',
        usingCsv: 'Usando CSV',
        usingSegment: 'Usando Grupoo',
        allContacts: 'Todos los contactos',
        selectDate: 'Seleccione fecha',
        selectTimeSlot: 'Seleccione la ranura de tiempo',
        assignVariablesToTemplates: 'Asignar variables a las plantillas',
        addHeaderMedia: 'Agregar medios de encabezado',
        selectFile: 'Seleccione Archivo',
        cancel: 'Cancelar',
        createBroadcast: 'Crear transmisión',
        broadcastUsing: 'Transmitir usando',
        selectCSVFile: 'Seleccione el archivo CSV',
        importContacts: 'Importar contactos',
        beforeYouStartUpload: 'Antes de comenzar a subir por favor asegúrese de',
        point1: '1. Descargue el archivo CSV de muestra.',
        point2: '2. Comience a ingresar sus datos desde la segunda fila en adelante.',
        point3: '3. En cada fila, habrá datos para un contacto',
        point4: '4. Asegúrese de que todos los números de teléfono sean únicos.',
        point5: '5. El límite máximo de carga de tamaño de archivo es 1 MB',
        // selectFile: 'Seleccione Archivo',
        clickHere: 'haga clic aquí',
        toDownloadSampleFile: 'Para descargar el archivo de muestra',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          selectTemplate: 'Seleccionar plantilla',
          searchTemplateByName: 'Plantilla de búsqueda por nombre',
          selectSegments: 'Seleccionar Grupoos',
          selectTimeSlot: 'Seleccione la ranura de tiempo',
          selectValueForThisVariable: 'Seleccione el valor para esta variable',
          searchHeaderByVariableName: 'Variable del encabezado de búsqueda por nombre',
          variableFallbackValue: 'Valor de retroceso variable',
          searchBodyVariableByName: 'Buscar variable de cuerpo por nombre',
          searchSegmentByName: 'Grupoo de búsqueda por nombre',
          selectBroadcastUsing: 'Seleccione la transmisión usando',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleccione la fecha de inicio',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Importar contactos para la transmisión de WhatsApp',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'Número de whatsapp',
        errorCode: 'Código de error',
        errorMessage: 'Mensaje de error',
        nA: 'N / A',
      }
    },
    design: {
      customizeYourLandingPage: 'Personaliza tu página de destino',
      customizeYourConversationalBot: 'Personaliza tu bot conversacional',
      customizeYourBot: 'Personaliza tu bot',
      createHeadingTextAndTheme: 'Cree texto y tema de encabezado que refleje la personalidad de su marca',
      content: 'Contenido',
      theme: 'Tema',
      layout: 'Disposición',
      landingPageName: 'Nombre de la página de destino',
      botName: 'Nombre de bot',
      charactersRemaining: ' caracteres restantes',
      landingPageHeader: 'Encabezado de la página de destino',
      landingPageDescription: 'Descripción de la página de destino',
      hideContent: 'Ocultar contenido',
      profilePic: 'Foto de perfil',
      selectFile: 'Seleccione Archivo',
      socialMediaLinks: 'Enlaces de redes sociales',
      initialMessage: 'Mensaje inicial',
      selectType: 'Seleccione tipo',
      url: 'Url',
      delete: 'Borrar',
      editLink: 'Enlace de edición',
      addLink: 'Añadir enlace',
      callToAction: 'Llamada a la acción',
      liveChatWelcomeMessage: 'Mensaje de bienvenida de chat en vivo',
      liveChatTriggerMessage: 'Mensaje de activación de chat en vivo',
      autoHideCallToAction: 'Auto ocultar llamadas a la acción',
      maxDelayShouldBe300Seconds: 'El retraso máximo debe ser de 300 segundos',
      landingPageThemeColor: 'Color del tema de la página de destino',
      themeColor: 'Color del tema',
      landingPageBackground: 'Antecedentes de la página de destino',
      chatBackgroundColor: 'Color de fondo de chat',
      color: 'Color',
      gradient: 'Degradado',
      image: 'Imagen',
      video: 'Video',
      gradientStart: 'Comienzo de gradiente',
      gradientEnd: 'Final de gradiente',
      // selectFile: 'Seleccione Archivo',
      positionOnWeb: 'Posición en la web',
      positionOnMobile: 'Posición en el móvil',
      windowSize: 'Tamaño de ventana',
      s: 'S',
      m: 'METRO',
      l: 'L',
      xl: 'SG',
      xxl: 'Xxl',
      custom: 'Costumbre',
      height: 'Altura',
      minimum300HeightIsRequired: 'Se requiere una altura mínima de 300px',
      heightCannotExceed720: 'La altura no puede exceder 720px',
      width: 'Ancho',
      px: 'px',
      minimum200WidthIsRequired: 'Se requiere un ancho mínimo de 200px',
      widthCannotExceed1050: 'El ancho no puede exceder 1050px',
      discard: 'Desechar',
      saveChanges: 'Guardar cambios',
      landingPageBot: 'Bot de la página de destino',
      chatBot: 'Chatbot',
      preview: ' Avance',
      test: 'Prueba',
      botIcon: 'Icono de bot',
      websiteBot: 'Chatbot del sitio web',
      selectFont: 'Seleccionar fuente',
      fontLibrary: 'Biblioteca de fuentes',
      uploadFont: 'Subir fuente',
      selectFontForBot: 'Seleccionar fuente para el bot',
      uploadedFont: 'Fuente cargada',
      flowTriggerDetail: ' Disparador para recopilar detalles de contacto',
      placeholders: {
        enterName: 'Ingrese su nombre',
        enterHeaderText: 'Ingrese el texto del encabezado',
        enterDescription: 'Ingrese la descripción',
        enterUrl: 'Introducir URL',
        howCanIHelpYou: '¿Le puedo ayudar en algo?',
        enterYourWelcomeMessageHere: 'Ingrese su mensaje de bienvenida aquí',
        enterYourLiveChatMessageHere: 'Ingrese su mensaje de chat en vivo aquí',
        enterTimeInSeconds: 'Ingrese el tiempo en segundos',
        enterHeight: 'Entrar en altura',
        enterWidth: 'Entrar ancho',
        flowInitialMessage: 'Introduce la pregunta inicial',
      },
      appTooltip: {
        standardLayout: 'Diseño estándar',
        rightLayout: 'Diseño correcto',
        mergedLayout: 'Diseño fusionado',
        enterNameOfYourBot: 'Ingrese el nombre de su bot',
        enterNameOfYourLandingPageBot: 'Ingrese el nombre de su página de la página de destino',
        headingTextThatAppearOnLandingPageBot: 'Ingrese el texto de encabezado que desea aparecer en su página de la página de destino',
        descriptionTextThatAppearOnLandingPageBot: 'Ingrese el texto de descripción que desea aparecer en el bot de su página de destino.',
        addLinksAndSocialMediaIcons: 'Agregar enlaces e íconos de redes sociales',
        initialFlowMessage: 'Ingrese el primer mensaje que enviará el bot para iniciar la conversación con el cliente',
        anInvitingCatchPhrase: 'Una frase de captura acogedora que hará que el usuario hable con Bot',
        setAWelcomeMessageForYourVisitors: 'Establezca un mensaje de bienvenida para sus visitantes. Este será el primer mensaje en su conversación.',
        setAMessageToInitiateLiveChat: 'Establezca un mensaje para iniciar el chat en vivo desde el final del visitante. Recibirá una notificación cada vez que se active este mensaje en cualquiera de sus conversaciones.',
        autoHideCallToAction: 'Ocultar automáticamente el texto `Call to Action` después de un tiempo especificado',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Puede elegir ocultar el logotipo y los textos para mostrar solo la ventana de chat en la pantalla',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Sube un logotipo para la página de chatbot.',
        TOOLTIP_CHAT_THEME_COLOR: 'Elija el color de la ventana de chat de sus bots',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Elija el color de fondo de la ventana de chat de bots',
        TOOLTIP_WEB_1ST_POSITION: 'Coloque su bot en la parte inferior izquierda del escritorio de los usuarios de BOT',
        TOOLTIP_WEB_2ND_POSITION: 'Coloque su bot en la esquina central derecha del escritorio de los usuarios de botes',
        TOOLTIP_WEB_3RD_POSITION: 'Coloque su bot en la esquina inferior derecha del escritorio de los usuarios de BOT',
        TOOLTIP_MOBILE_1ST_POSITION: 'Coloque su bot en la esquina inferior izquierda del móvil de los usuarios de BOT',
        TOOLTIP_MOBILE_2ND_POSITION: 'Coloque su bot en la esquina central derecha del móvil de los usuarios de BOT',
        TOOLTIP_MOBILE_3RD_POSITION: 'Coloque su bot en la esquina inferior derecha del móvil de los usuarios de BOT',
        TOOLTIP_WINDOW_HEIGHT: 'Ventana de chat de redimentación de bots',
        TOOLTIP_BOT_ICON: 'Seleccione un icono o un logotipo/imagen para su bot para su bot',
        flowTriggerMessage: 'Ingrese el número de mensajes que se deben preguntar antes de recopilar los detalles de contacto, como nombre, correo electrónico y teléfono',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Crear campaña de goteo',
      youCanCreateMultipleCampaigns: 'Puedes crear múltiples campañas',
      dripCampaignName: 'Nombre de la campaña de goteo',
      dripCampaignNameIsReuired: 'Se requiere el nombre de la campaña de goteo',
      continue: 'Continuar',
      manageDripCampaign: 'Gestionar la campaña de goteo',
      youCanCreateMultipleCampaignsWith: 'Puede crear múltiples campañas de goteo con mensajes automatizados programados para activar respuestas de Grupoos de usuario específicos.',
      createNewDripCampaign: 'Crear una nueva campaña de goteo',
      campaignName: 'Nombre de campaña',
      appliedOn: 'Aplicado en',
      segment: 'Grupoo',
      template: 'Plantilla',
      startDate: 'Fecha de inicio',
      action: 'Acción',
      nA: 'N / A',
      seeHowToUse: 'Vea cómo usar',
      deleteDripCampaign: 'Eliminar campañas de goteo',
      wantToDeleteThisDripCampaign: '¿Estás seguro de que quieres eliminar estas campañas de goteo?',
      placeholders: {
        enterDripCampaignName: 'Ingrese el nombre de la campaña de goteo',
        searchByName: 'Buscar por nombre',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Eliminar múltiples campañas de goteo',
        deleteDripCampaign: 'Eliminar campaña de goteo',
      },
      createDripCampaign: {
        dripCampaignName: 'Nombre de la campaña de goteo',
        appliedOn: 'Aplicado en',
        segments: 'Grupoos',
        selectDate: 'Seleccione fecha',
        scheduleYourTemplate: 'Programe sus plantillas',
        schedule: 'Cronograma',
        template: 'Plantilla',
        sendAfter: 'Enviar a',
        assignVariablesToTemplate: 'Asignar variables a las plantillas',
        addHeaderMedia: 'Agregar medios de encabezado',
        selectFile: 'Seleccione Archivo',
        createDripCampaign: 'Crear campaña de goteo',
        newSubscriber: 'Nuevo suscriptor',
        minutes: 'Minutos',
        hours: 'Horas',
        days: 'Días',
        placeholders: {
          enterDripName: 'Ingrese el nombre de goteo',
          selectAppliedOn: 'Seleccionar aplicado en',
          selectSegments: 'Seleccionar Grupoos',
          searchByName: 'Buscar por nombre',
          selectTemplate: 'Seleccionar plantilla',
          searchTemplateByName: 'Plantilla de búsqueda por nombre',
          enterSendAfter: 'Ingresar enviar después',
          selectValueForThisVariable: 'Seleccione el valor para esta variable',
          searchHeaderVariableByName: 'Variable del encabezado de búsqueda por nombre',
          searchBodyVariableByName: 'Buscar variable de cuerpo por nombre',
        },
        dateTimePicker: {
          selectStartDate: 'Seleccione la fecha de inicio',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Configurar la configuración general de su página de destino',
      configureBotsGeneralSettings: 'Configurar la configuración general de su bot',
      selectTheTimeZone: 'Seleccione la zona de tiempo, el idioma y una pequeña nota para sus clientes cuando el',
      isNotAvailable: ' no está disponible',
      landingPagesBot: 'Páginas de destino Bot',
      bot: 'bot',
      waitingMessage: 'Mensaje de espera',
      landingPageBotStatus: 'Estado de bot de la página de destino',
      chatBotStatus: 'Estado de chatbot',
      unavailabilityMessage: 'Mensaje de falta de disponibilidad',
      sendIncompleteResponses: 'Enviar respuestas incompletas',
      language: 'Idioma',
      invalidateCache: 'Invalidar el caché',
      timezone: 'Zona horaria',
      chatWindowMessageHistory: 'Historial de mensajes de la ventana de chat',
      session: 'sesión',
      forever: 'para siempre',
      english: 'Inglés',
      spanish: 'Español',
      french: 'Francés',
      portuguese: 'portugués',
      german: 'Alemán',
      italian: 'italiano',
      swedish: 'sueco',
      arabic: 'Arábica',
      malay: 'malayo',
      russian: 'ruso',
      discard: 'Desechar',
      saveChanges: 'Guardar cambios',
      seeHowToUse: 'Vea cómo usar',
      placeholders: {
        enterYourWaitingMessageHere: 'Ingrese su mensaje de espera aquí',
        // selectLanguage: ',',
        searchTimeZone: 'Búsqueda de tiempo de búsqueda',
        // selectChatRetentionPolicy: ',',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalizar el mensaje como lo desea que aparezca en la ventana de chat inmediatamente después de que un usuario de BOT envía una solicitud de chat en vivo',
        TOOLTIP_CHATBOT_STATUS: 'Activar /desactivar el bot',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Personalice el mensaje como desea que aparezca en la ventana de chat cuando ninguno de sus agentes esté disponible para el chat en vivo con el usuario de Bot',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Envíe notificaciones por correo electrónico incluso cuando el usuario de BOT no ha terminado la conversación.',
        TOOLTIP_LANGUAGE: 'Seleccione el lenguaje del bot',
        TOOLTIP_INVALIDATE_CACHE: 'Haga clic aquí para borrar el caché cuando edita el flujo de chat de bot o la configuración como los cambios se reflejan solo después de 15 minutos',
        TOOLTIP_TIME_ZONE: 'Seleccione la zona de tiempo para su bot',
        TOOLTIP_CHAT_RETENTION: 'Seleccione si desea retener los chats de los usuarios de BOT para siempre en la ventana de chat o solo para una sesión de navegador.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Personaliza tu ventana de chat',
      controlAndCustomizeTheChatWindow: 'Controle y personalice la ventana de chat con la que interactuarán sus clientes.',
      allowVisitorsToTransferChatToWhatsapp: 'Permitir que los visitantes transfieran el chat a whatsapp',
      allowVisitorsToRefreshChat: 'Permitir que los visitantes refrescen el chat',
      displayNameForTransferringChatToWhatsapp: 'Nombre de la pantalla para transferir un chat a WhatsApp',
      displayNameForTransferringToLiveChat: 'Nombre de la pantalla para transferir al chat en vivo/agente',
      displayNameForTransferringAChatToBot: 'Nombre de la pantalla para transferir un chat a Bot',
      discard: 'Desechar',
      saveChanges: 'Guardar cambios',
      allowVisitorsToTransferChatToBot: 'Permita que los visitantes transfieran el chat a Bot.',
      allowVisitorsToRequestForLiveChat: 'Permita que los visitantes soliciten chat en vivo.',
      indicativeTextForCustomersReply: 'Texto indicativo para la respuesta del cliente',
      displayNameForRestartingChatBot: 'Nombre de la pantalla para reiniciar la conversación de chatbot',
      seeHowToUse: 'Vea cómo usar',
      placeholders: {
        enterButtonNameForWhatsapp: 'Ingrese el nombre del botón para transferir a WhatsApp',
        enterButtonNameForLiveChat: 'Ingrese el nombre del botón para transferir al chat o agente en vivo',
        enterButtonNameForBot: 'Ingrese el nombre del botón para transferir a Bot',
        enterTextDisplayInEmptyReplyArea: 'Ingrese el texto que se muestra en el área de respuesta vacía',
        enterButtonForRestartingConversation: 'Ingrese el nombre del botón para reiniciar la conversación',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Permita que los visitantes del sitio web transfieran el chat a WhatsApp no. del agente respectivo al que están conectados. La función de chat en vivo debe estar habilitada para que esta función funcione.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Permita que los usuarios de BOT actualicen el chat para que puedan comenzar la conversación de nuevo.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Permita que los usuarios de BOT transfieran el chat a Bot en cualquier momento entre la conversación con el agente de chat en vivo.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Permita que los usuarios de BOT soliciten chat en vivo con el agente cuando lo deseen.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Si ha habilitado el usuario para transferir a WhatsApp, complete cómo le gustaría mostrar esa opción',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Si ha habilitado el usuario para transferir al chat en vivo, complete cómo le gustaría mostrar esa opción',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Si ha habilitado el usuario para transferir a WhatsApp, complete cómo le gustaría transferir de regreso a Bot',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Complete cómo le gustaría mostrar el espacio de respuesta para los clientes',
        ifYouHaveEnabledUserToRestartChatBot: 'Si ha habilitado el usuario para reiniciar la conversación de chatbot, complete cómo le gustaría mostrar esa opción',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Flujos de chat',
      dripCampaignHeader: 'Campaña de goteo',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Transmisión',
      ordersHeader: 'Pedidos',
      manageHeader: 'Administrar',
      inputHeader: 'Aporte',
      apiKeyHeader: 'Clave API',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Cree un viaje de incorporación al cliente utilizando una campaña de goteo de flujos de chat programados.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalice las plantillas de flujo de chat y cree múltiples flujos de chat para diferentes audiencias de destino.',
        TOOLTIP_WIDGET_TITLE: 'Crear widgets (interfaces de chat) e insertarlos en páginas web promocionales para dirigir a los visitantes al bot',
        TOOLTIP_BROADCAST_TITLE: 'Mensajes de explosión o fluye a sus contactos en un solo clic en la hora previa al scheduled',
        TOOLTIP_ORDERS_TITLE: 'Lista de pedidos realizados por el cliente',
        TOOLTIP_MANAGE_TITLE: 'Administración de la funcionalidad de BOT para contactos/leads y palabras clave',
        TOOLTIP_INPUT_TITLE: 'Crear plantillas, imágenes y texto de WhatsApp como material de respuesta para contactos/cables',
      },
      apiWrapper: {
        whatsappApiCredentials: 'Credenciales de la API de WhatsApp',
        generateYourWhatsappApiKey: 'Genere su tecla API de WhatsApp para integrarse con sus aplicaciones desarrolladas personalizadas.',
        generateApiKey: 'Generar la tecla API',
        webhookUrl: 'URL webhook',
        addHeader: 'Agregar encabezado',
        test: 'Prueba',
        webhookNotSet: 'Webhook no establecido',
        webhookHeaders: 'Encabezados de webhook',
        setWebhookUrl: 'Establecer URL webhook',
        placeholders: {
          yourApiKey: 'Tu clave API',
          enterWebhookUrl: 'Ingrese la URL de Webhook',
          enterHeaderKey: 'Ingrese la tecla de encabezado',
          enterValue: 'Ingresar valor',
        }
      },
      broadcast: {
        createBroadcast: 'Crear transmisión',
        createMultipleBroadcasts: 'Puede crear múltiples transmisiones con múltiples flujos/plantillas programados para diferentes Grupoos de clientes.',
        broadcastName: 'Nombre de transmisión',
        nameIsRequired: 'Se requiere el nombre',
        selectType: 'Seleccione tipo',
        selectYourFlowType: 'Seleccione su tipo de flujo',
        segment: 'Grupoo',
        selectYourSegmentType: 'Seleccione su tipo de Grupoo',
        whenToSend: 'Cuando enviar',
        selectDate: 'Seleccione fecha',
        messageTag: 'Etiqueta de mensaje',
        selectMessageTag: 'Seleccionar etiqueta de mensaje',
        contentType: 'Tipo de contenido',
        nonPromotional: 'No promocional (etiquetas de mensaje aplicadas)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Etiquete cada mensaje que viene antes de cualquier interacción o después de los pasos de retraso que exceden las 24 horas con la etiqueta apropiada. El mensaje sin etiquetar no llegará al suscriptor que ha interactuado con el bot hace más de 24 horas',
        promotional: 'Promocional (etiquetas de mensaje ignoradas)',
        theseBroadcasts: 'Estas transmisiones pueden contener promociones, pero el público objetivo está limitado a los suscriptores que interactuaron con su bot en las últimas 24 horas.',
        createNewBroadcast: 'Crea una nueva transmisión',
        sentMessages: 'Mensajes enviados',
        deliveredMessages: 'Mensajes entregados',
        readMessages: 'Leer mensajes',
        failedMessages: 'Mensajes fallidos',
        // createNewBroadcast: 'Crea una nueva transmisión',
        type: 'Tipo',
        template: 'Plantilla',
        sent: 'Enviado',
        delivered: 'Entregado',
        read: 'Leer',
        failed: 'Fallido',
        broadcastAt: 'Transmitir a',
        status: 'Estado',
        shipmentUpdate: 'Actualización de envío',
        // template: 'Plantilla',
        flow: 'Fluir',
        resendFailedMessages: 'Reenviar mensajes fallidos',
        // sentMessages: 'Mensajes enviados',
        // deliveredMessages: 'Mensajes entregados',
        goBack: 'Regresa',
        number: 'Número',
        errorCode: 'Código de error',
        failedAt: 'Fracasado en',
        badGateway: '502 Puerta de enlace no válida',
        loadMore: 'Carga más',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          searchBroadcastByName: 'Búsqueda de transmisión por nombre',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Mensajes de transmisión totales enviados',
          totalBroadcastMessagesDelivered: 'Mensajes de transmisión totales entregados',
          totalBroadcastMessagesReadByContacts: 'Mensajes de transmisión totales leídos por los contactos',
          totalFailedBroadcastMessages: 'Mensajes de transmisión totales fallidos',
          clickToViewDetailedInformationOfFailedMessages: 'Haga clic para ver la información detenida de los mensajes fallidos',
          clickToCreateNewBroadcast: 'Haga clic para crear una nueva transmisión',
          templateUsedInBroadcast: 'Plantilla utilizada en la transmisión',
          numberOfBroadcastMessagesSent: 'No. de mensajes de transmisión enviados',
          numberOfBroadcastMessagesDelivered: 'No. de mensajes de transmisión entregados',
          numberOfBroadcastMessagesReadByContacts: 'No. de mensajes de transmisión leídos por los contactos',
          numberOfTimesBroadcastMessagesHasFailed: 'No. De Mensajes de transmisión de tiempos ha fallado',
          contactNumberOnWhichMessageDeliveryFailed: 'Número de contacto en el que falló la entrega del mensaje',
          unDeliveredTemplate: 'Plantilla no entregada',
          errorReceivedForFailedMessage: 'Error recibido por mensaje fallido',
          timeWhenMessageDeliveryFailed: 'Hora en que falló la entrega de mensajes',
          TOOLTIP_ADD_BROADCAST_NAME: 'Ingrese un nuevo nombre de transmisión',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Seleccione si está transmitiendo un flujo o un mensaje.',
          TOOLTIP_BROADCAST_FLOW: 'Seleccione el flujo de chat que desea transmitir,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Seleccione el Grupoo de contactos al que desea transmitir',
          TOOLTIP_BROADCAST_NAME: 'Nombre de la transmisión',
          TOOLTIP_BROADCAST_TYPE: 'Flujo o mensaje seleccionado para la transmisión',
          TOOLTIP_BROADCAST_SEGMENT: 'Grupoo para el cual se crea la transmisión',
          TOOLTIP_BROADCAST_AT: 'Fecha y hora programadas para la transmisión',
          TOOLTIP_BROADCAST_STATUS: 'Estado de transmisión según lo programado, actualmente en ejecución o completado',
        }
      },
      drip: {
        createDripCampaign: 'Crear campaña de goteo',
        youCanCreateMultipleCampaigns: 'Puedes crear múltiples campañas',
        dripCampaignName: 'Nombre de la campaña de goteo',
        dripCampaignNameIsRequired: 'Se requiere el nombre de la campaña de goteo',
        // createDripCampaign: 'Crear campaña de goteo',
        youCanCreateMultipleDripCampaigns: 'Puede crear múltiples campañas de goteo con mensajes automatizados programados para activar respuestas de Grupoos de usuario específicos.',
        campaignName: 'Nombre de campaña',
        campaignNameIsRequired: 'Se requiere el nombre de la campaña',
        after: 'Después',
        thisMessageWillBeSendAfter: 'Este mensaje se enviará después',
        saveAndUpdate: 'Guardar y actualizar',
        schedule: ' Cronograma',
        select: 'Seleccionar',
        messages: 'Mensajes',
        cancel: 'Cancelar',
        createCampaign: 'Crear campaña',
        updateCampaign: 'Campaña de actualización',
        createNewCampaign: 'Crea una nueva campaña',
        // campaignName: 'Nombre de campaña',
        messagesSent: 'Mensajes enviados',
        createdAt: 'Creado en',
        updatedAt: 'Actualizado en',
        action: 'Acción',
        placeholders: {
          nameYourDripCampaign: 'Nombra tu campaña de goteo',
          enterCampaignName: 'Ingrese el nombre de la campaña',
          searchCampaignByName: 'Campaña de búsqueda por nombre',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Haga clic para agregar una nueva campaña',
          TOOLTIP_SCHEDULE: 'Planifique cuándo y qué flujo de chat para enviar primero en esta campaña.',
          TOOLTIP_AFTER_DAYS: 'Programe cuándo enviar el primer flujo de la campaña en minutos, horas o días.',
          TOOLTIP_ADD_MESSAGES: 'Haga clic para agregar un nuevo flujo a la campaña',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Ingrese un nuevo nombre de campaña',
          TOOLTIP_CAMPAIGN_NAME: 'Nombre de la campaña',
          TOOLTIP_NO_MESSAGES_SENT: 'No. de mensajes enviados por la campaña',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Fecha y hora de la creación de la campaña',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Fecha y hora cuando la campaña se modificó por última vez',
          TOOLTIP_CAMPAIGN_ACTION: 'Editar o eliminar la campaña',
          TOOLTIP_UPDATE_CAMPAIGN: 'Haga clic para guardar las modificaciones realizadas en la configuración de la campaña',
        }
      },
      flows: {
        createChatFlow: 'Crear flujo de chat',
        youCanCreateMultipleConversationsFlow: 'Puede crear múltiples flujos de conversaciones a través de múltiples canales para pruebas y transmisiones A/B.',
        flowName: 'Nombre de flujo',
        nameIsRequired: 'Se requiere el nombre',
        cancel: 'Cancelar',
        createFlow: 'Crear flujo',
        createNewFlow: 'Crear un nuevo flujo',
        // flowName: 'Nombre de flujo',
        ofMessages: 'de mensajes',
        triggered: 'Motivado',
        stepsFinsished: 'Pasos terminados',
        finished: 'Finalizado',
        createdOn: 'Creado en',
        lastModified: 'Última modificación',
        defaultFlow: 'Flujo predeterminado',
        action: 'Acción',
        flowAnalytics: 'Análisis de flujo',
        placeholders: {
          enterFlowName: 'Ingrese el nombre de flujo',
          searchFlowByName: 'Flujo de búsqueda por nombre',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'No. de veces el flujo de chat se activa',
          numberOfCompletedChatFlowStepsWhenTriggered: 'No. de pasos de flujo de chat completos cuando se activa',
          numberOfTimesUsersCompletedChatFlowSteps: 'No. De veces los usuarios han completado todos los pasos de flujo de chat',
          dateAndTimeOfCreationOfChatFlow: 'Fecha y hora de creación del flujo de chat',
          dateAndTimeWhenChatFlowLastModified: 'Fecha y hora cuando el flujo de chat se modificó por última vez',
          TOOLTIP_CREATE_FLOW: 'Haga clic para crear un nuevo flujo de chat',
          TOOLTIP_FLOW_NAME: 'Ingrese el nombre del flujo de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nombre del flujo de chat',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensaje en el flujo de chat',
          TOOLTIP_DEFAULT_FLOW: 'Use este alternar para establecer un flujo de chat como predeterminado, que se servirá a todos los nuevos usuarios.',
          TOOLTIP_ACTION: 'Editar o eliminar el flujo de chat',
        }
      },
      manage: {
        leads: 'Dirige',
        manageBotsFunctionalityForContacts: 'Administrar la funcionalidad de Bot para los contactos/cables',
        whenToSendLeadInfo: 'Cuándo enviar información de plomo',
        smartFlow: 'Flujo inteligente',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Mensaje de bienvenida cuando no se cumple la búsqueda de palabras clave',
        selectTemplates: 'Seleccionar plantillas',
        selectFlows: 'Seleccionar flujos',
        selectText: 'Elegir texto',
        selectImages: 'Seleccionar imágenes',
        whenItAlmostReaches24HoursSinceMessage: 'Cuando casi llega a las 24 horas desde el último mensaje',
        // selectTemplates: 'Seleccionar plantillas',
        updateChanges: 'Actualizar cambios',
        manageBotsFunctionalityAccordingToKeywords: 'Administrar la funcionalidad de BOT de acuerdo con las palabras clave de entrada por parte del usuario de BOT, en caso de flujo inteligente',
        addKeywordAction: 'Agregar acción de palabras clave',
        keywords: 'Palabras clave',
        matchingMethod: 'Método de coincidencia',
        replyMaterial: 'Responder material',
        action: 'Acción',
        select: 'Seleccionar',
        placeholders: {
          keyword: 'palabra clave',
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Sugerir si Bot enviar información de contacto/plomo a la integración CRM inmediatamente, o después de 5 minutos, o al final del flujo',
          TOOLTIP_LEAD_SMART_FLOW: 'Elija cualquier flujo predeterminado para enviarlo a todos los usuarios de BOT, o flujo inteligente (determinado por la entrada de palabras clave por el usuario de BOT).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Seleccione Material de respuesta (flujo, plantilla, texto, imagen) para enviar si Bot no recibe ninguna coincidencia de palabras clave de la respuesta del usuario de BOT',
          TOOLTIP_IT_ALMOST_REACHED: 'Seleccione el material de respuesta para enviar a los contactos/cables justo antes de las 24 horas de la última mensajería',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Administrar la funcionalidad de BOT de acuerdo con las palabras clave de entrada por parte del usuario de BOT, en caso de flujo inteligente',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Seleccione la respuesta de Bot como plantilla, flujo, texto o imagen contra la entrada de palabras clave específicas',
          TOOLTIP_ADD_KEYWORD: 'Agregar palabras clave',
          TOOLTIP_MATCHING_METHOD: 'Agregar coincidencia de palabras clave como exacta o contiene',
          TOOLTIP_REPLY_MATERIAL: 'Seleccione la plantilla, el flujo, el texto o la imagen para responder a la palabra clave sugerida',
          TOOLTIP_KEYWORD_ACTIONS: 'Eliminar la acción de la palabra clave',
        },
        socialAlerts: {
          alerts: 'Alertas',
          specifyHowYourBotWillNotify: 'Especifique cómo su bot le notificará sobre nuevos clientes potenciales.',
        }
      },
      orders: {
        manageCustomersOrder: 'Administrar pedidos de clientes',
        checkAndManageAllOfYourCustomerOrders: 'Verifique y administre todos los pedidos y actualizaciones de sus clientes.',
        selectShipmentTemplate: 'Seleccionar plantilla de envío',
        send: 'Enviar',
        export: 'Exportar',
        phoneNumber: 'Número de teléfono',
        numberOfItems: 'Número de items',
        orderValue: 'Valor del pedido',
        orderDate: 'Fecha de orden',
        status: 'Estado',
        chat: 'Charlar',
        item: 'Artículo',
        quantity: 'Cantidad',
        amount: 'Cantidad',
        unitPrice: 'Precio unitario',
        placeholders: {
          searchOrdersByPhoneNumber: 'Pedidos de búsqueda por números de teléfono',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Exportar pedidos de datos en el archivo CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'Número de teléfono del cliente que realizó el pedido',
          TOOLTIP_NO_OF_ITEMS: 'No. de artículos ordenados',
          TOOLTIP_ORDER_VALUE: 'Valor del orden',
          TOOLTIP_ORDER_DATE: 'El pedido se realizó en esta fecha.',
          TOOLTIP_ORDER_STATUS: 'Estado del orden según lo confirmado, pendiente, rechazado. o enviado',
          TOOLTIP_ORDER_CHAT: 'Todas las conversaciones de chat anteriores sobre este pedido',
        }
      },
      widgets: {
        installWidgetHeader: 'Widget de instalación',
        createWidgetHeader: 'Crear widget',
        copyTheCodeAndPasteItWhereYouWant: 'Copie el código y péguelo donde sea necesario en su HTML, sitio web, etc.',
        youCanCreateMultipleWidgets: 'Puede crear múltiples widgets para darle a su bot una apariencia diferente' +
          ' en diferentes páginas web.',
        installWidget: {
          javascript: 'Javascript',
          install: 'Instalar',
          onYourWebsite: ' en tu sitio web',
          copyCode: 'Copiar código',
          copyAndPaste: 'Copiar y pegar',
          copyThisCodeSnippet: 'Copie este fragmento de código e insértelo en el HTML de cada página web donde desee',
          seeGuide: 'Ver guía',
        },
        widgetList: {
          widgetName: 'Nombre del widget',
          nameIsRequired: 'Se requiere el nombre',
          cancel: 'Cancelar',
          createWidget: 'Crear widget',
          icon: 'Icono',
          name: 'Nombre',
          createdAt: 'Creado en',
          action: 'Acción',
          placeholders: {
            enterWidgetName: 'Ingrese el nombre del widget',
            searchWidgetByName: 'Búsqueda de widget por nombre',
          },
          appTooltip: {
            widgetIcon: 'Icono de widget',
            nameOfWidget: 'Nombre del widget',
            dateAndTimeOfCreationOfWidget: 'Fecha y hora de creación del widget',
            editOrDeleteWidget: 'Editar o eliminar el widget',
          }
        },
        widgetTemplates: {
          widgetName: 'Nombre del widget',
          nameIsRequired: 'Se requiere el nombre',
          chooseTypeOfWidget: 'Elija tipo de widget',
          bar: 'Bar',
          slideIn: 'En este lado',
          pageTakeOver: 'Adquisición de la página',
          button: 'Botón',
          facebookModal: 'Modal de Facebook',
          comment: 'Comentario',
          direct: 'Directo',
          whatsappIcon: 'Icono de whatsapp',
          refUrl: 'URL de referencia',
          qrCode: 'Código QR',
          // refUrl: 'URL de referencia',
          backgroundColor: 'Color de fondo',
          descriptionColor: 'Descripción Color',
          headlineColor: 'Color principal',
          buttonBackgroundColor: 'Color de fondo del botón',
          contactNumber: 'Número de contacto',
          entryMessage: 'Mensaje de entrada',
          buttonText: 'Botón de texto',
          selectButtonText: 'Seleccionar texto del botón',
          buttonSize: 'Tamaño del botón',
          selectOne: 'Seleccione uno',
          placement: 'Colocación',
          rightBottom: 'Boton derecho',
          optInFlow: 'Opción',
          selectFlow: 'Seleccionar flujo',
          addSubscriberToSequence: 'Agregar suscriptor a la secuencia',
          selectDrip: 'Seleccione Drip',
          whenDoesItDisplay: '¿Cuándo se muestra (segundos)',
          showWidgetToSameUserAgainAfter: 'Mostrar widget al mismo usuario nuevamente después',
          ifManuallyClosedByUser: 'Si el usuario se cierra manualmente, muestra después',
          // comment: 'Comentario',
          specificPosts: 'Publicaciones específicas',
          allPosts: 'Todos los mensajes',
          // entryMessage: 'Mensaje de entrada',
          postMessage: 'Mensaje de publicación',
          postDate: 'Fecha posterior',
          status: 'Estado',
          action: 'Acción',
          discard: 'Desechar',
          saveChanges: 'Guardar cambios',
          nextStep: 'Próximo paso',
          widgetDescription: 'Descripción del widget',
          integrateTheBarWidget: 'Integre el widget de la barra en el cuerpo de su sitio web y aumente el suscriptor de su página junto con el envío de mensajes BOT personalizados.',
          hereIsYourWidgetHeadline: 'Aquí está el titular de su widget, haga clic aquí para cambiarlo.',
          sendUsMessage: 'Enviarnos un mensaje',
          weAlsoPutDefaultTextHere: 'También ponemos el texto predeterminado aquí. Conviértalo en un mensaje valioso',
          selectYourPagePost: 'Seleccione la publicación de su página',
          nA: 'N / A',
          // entryMessage: 'Mensaje de entrada',
          saveRule: 'Regla de guardar',
          placeholders: {
            enterWidgetName: 'Ingrese el nombre del widget',
            enterNumber: 'Ingresar número',
            enterMessageHere: 'Ingrese el mensaje aquí',
            enterTimeInSeconds: 'Ingrese el tiempo en segundos',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'Personalice la configuración de activación de su bot',
      makeYourBotsTimingPerfect: 'Haga que el tiempo de su bot sea perfecto.',
      onDesktopTriggerTime: 'Tiempo de activación en desktop (segundos)',
      onMobileTriggerTime: 'Tiempo de activación en mobile (segundos)',
      chatBubble: 'Abertura automática de burbujas de chat',
      seconds: 'Segundos',
      discard: 'Desechar',
      saveChanges: 'Guardar cambios',
      autoTriggerOnOrOff: 'Disparador automático: encendido/apagado',
      seeHowToUse: 'Vea cómo usar',
      alwaysOpenAfter: 'Siempre abra después de los desencadenantes especificados anteriormente',
      automaticallyAfterSelectedNumberOfTime: 'Automáticamente después de la cantidad de tiempo seleccionada',
      neverAutomaticallyOpenBot: 'Nunca abra automáticamente el bot',
      placeholders: {
        enterTimeInSeconds: 'Ingrese el tiempo en segundos',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Tiempo de espera en segundos, en dispositivos de escritorio de los visitantes, antes de que aparezca su bot automáticamente',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Tiempo de espera en segundos, en el móvil de los visitantes, antes de que aparezca su bot automáticamente',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Cuando el visitante cierra la ventana de chat, seleccione cómo será el comportamiento emergente de los botes automáticos',
        customerWaitingTimeToOpenBubble: 'Tiempo de espera del cliente para abrir la burbuja de chat',
        wantToDisableAutoTriggering: 'Haga clic si desea deshabilitar la activación automática de BOT en las páginas web',
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'Personalizar la configuración de usuario de su bot',
      decideWhoCanAccessYourBot: 'Decide quién puede acceder a tu bot',
      deviceSupport: 'Soporte del dispositivo',
      blockIpAddresses: 'Bloquear direcciones IP',
      hideChatBotOnSpecificPages: 'Ocultar chatbot en páginas específicas',
      selectCountriesToEnableBot: 'Seleccionar países para habilitar BOT',
      discard: 'Desechar',
      saveChanges: 'Guardar cambios',
      seeHowToUse: 'Vea cómo usar',
      allDevices: 'Todos los dispositivos',
      mobileOnly: 'Solo móvil',
      desktopOnly: 'Solo escritorio',
      placeholders: {
        enterIpAddress: 'Ingrese la dirección IP (IPv4 o IPv6) y presione `Enter` `',
        enterUrl: 'Ingrese la URL de las páginas y presione `Enter`',
        searchCountry: 'Búsqueda de país',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Seleccione en qué dispositivos debe aparecer su bot',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Ingrese direcciones IP donde no desea que aparezca su bot',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Ingrese la URL de sus páginas web donde no desea que aparezca su bot',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Seleccione ubicaciones desde donde desea que los usuarios accedan a su bot',
      }
    },
    facebook: {
      facebookBotSettings: 'Configuración de Bot de Facebook',
      chatFlowsHeader: 'Flujos de chat',
      dripCampaignHeader: 'Campaña de goteo',
      broadcastHeader: 'Transmisión',
      widgetsHeader: 'Widgets',
      autoReplyHeader: 'Respuesta automática',
      generalHeader: 'General',
      facebookAutomation: 'Automatización de Facebook',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalice las plantillas de flujo de chat y cree múltiples flujos de chat para diferentes audiencias de destino.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Cree un viaje de incorporación al cliente utilizando una campaña de goteo de flujos de chat programados.',
      },
      broadcasts: {
        createBroadcast: 'Crear transmisión',
        youCanCreateMultipleBroadcasts: 'Puedes crear múltiples transmisiones',
        broadcastName: 'Nombre de transmisión',
        broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
        continue: 'Continuar',
        manageBroadcasts: 'Administrar transmisiones',
        youCanCreateMultipleBroadcastsWith: 'Puede crear múltiples transmisiones con múltiples plantillas programadas para diferentes Grupoos de clientes.',
        createNewBroadcast: 'Crea una nueva transmisión',
        name: 'Nombre',
        segment: 'Grupoo',
        template: 'Plantilla',
        contacts: 'Contactos',
        send: 'Enviar',
        failed: 'Fallido',
        broadcastAt: 'Transmitir a',
        status: 'Estado',
        actions: 'Comportamiento',
        nA: 'N / A',
        deleteBroadcast: 'Eliminar la transmisión',
        wantToDeleteThisBroadcast: '¿Estás seguro de que quieres eliminar esta transmisión?',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          searchHere: 'Busca aquí',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Eliminar múltiples transmisiones',
          deleteBroadcast: 'Eliminar la transmisión',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Nombre de transmisión',
        broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
        flow: 'Fluir',
        segments: 'Grupoos',
        whenToSend: 'Cuando enviar',
        selectDate: 'Seleccione fecha',
        selectTimeSlot: 'Seleccione la ranura de tiempo',
        createBroadcast: 'Crear transmisión',
        cancel: 'Cancelar',
        now: 'Ahora',
        later: 'Más tarde',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          selectFlow: 'Seleccionar flujo',
          searchFlowByName: 'Flujo de búsqueda por nombre',
          selectSegments: 'Seleccionar Grupoos',
          searchSegmentByName: 'Grupoo de búsqueda por nombre',
          selectTimeSlot: 'Seleccione la ranura de tiempo',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleccione la fecha de inicio',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crear campaña de goteo',
        youCanCreateMultipleCampaign: 'Puedes crear múltiples campañas',
        dripCampaignName: 'Nombre de la campaña de goteo',
        dripCampaignNameIsRequired: 'Se requiere el nombre de la campaña de goteo',
        continue: 'Continuar',
        manageDripCampaign: 'Gestionar la campaña de goteo',
        youCanCreateMutlipleDripCampaign: 'Puede crear múltiples campañas de goteo con mensajes automatizados programados para activar respuestas de Grupoos de usuario específicos.',
        createNewDripCampaign: 'Crear una nueva campaña de goteo',
        campaignName: 'Nombre de campaña',
        appliedOn: 'Aplicado en',
        segment: 'Grupoo',
        flow: 'Fluir',
        startDate: 'Fecha de inicio',
        action: 'Acción',
        nA: 'N / A',
        deleteDripCampaign: 'Eliminar campañas de goteo',
        wantToDeleteThisDripCampaign: '¿Estás seguro de que quieres eliminar estas campañas de goteo?',
        placeholders: {
          enterDripCampaignName: 'Ingrese el nombre de la campaña de goteo',
          searchByName: 'Buscar por nombre',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Eliminar múltiples campañas de goteo',
          deleteDripCampaign: 'Eliminar campaña de goteo',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Nombre de la campaña de goteo',
          appliedOn: 'Aplicado en',
          segments: 'Grupoos',
          selectDate: 'Seleccione fecha',
          scheduleYourFlows: 'Programe sus flujos',
          schedule: 'Cronograma',
          flow: 'Fluir',
          sendAfter: 'Enviar a',
          cancel: 'Cancelar',
          newSubscriber: 'Nuevo suscriptor',
          days: 'Días',
          minutes: 'Minutos',
          hours: 'Horas',
          createDripCampaign: 'Crear campaña de goteo',
          placeholders: {
            enterDripName: 'Ingrese el nombre de goteo',
            selectAppliedOn: 'Seleccionar aplicado en',
            selectSegment: 'SELECCIÓN Grupoo',
            searchByName: 'Buscar por nombre',
            selectTemplate: 'Seleccionar plantilla',
            searchFlowByName: 'Flujo de búsqueda por nombre',
            enterSendAfter: 'Ingresar enviar después',
          },
          dateTimePicker: {
            selectStartDate: 'Seleccione la fecha de inicio',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'ID de chat',
        errorCode: 'Código de error',
        errorMessage: 'Mensaje de error',
        nA: 'N / A',

      },
      flows: {
        chatFlows: 'Flujos de chat',
        createChatFlow: 'Crear flujo de chat',
        youCanCreateMultipleChatFlows: 'Puede crear múltiples flujos de conversaciones a través de múltiples canales para pruebas y transmisiones A/B.',
        flowName: 'Nombre de flujo',
        nameIsRequired: 'Se requiere el nombre',
        cancel: 'Cancelar',
        createFlow: 'Crear flujo',
        importFlow: 'Flujo de importación',
        createNewFlow: 'Crear un nuevo flujo',
        ofMessages: 'de mensajes',
        createdOn: 'Creado en',
        lastModified: 'Última modificación',
        defaultFlow: 'Flujo predeterminado',
        action: 'Acción',
        exportStatus: 'Estado de exportación',
        flowExport: 'Flujo-exportación',
        download: 'Descargar',
        // importFlow: 'Flujo de importación',
        beforeYouStartUploadPleaseMakeSure: 'Antes de comenzar a subir por favor asegúrese de',
        point1: '1. El límite máximo de carga de tamaño de archivo es 1 MB',
        point2: '2. El formato de archivo debe estar en JSON',
        selectFile: 'Seleccione Archivo',
        inProgress: 'en curso',
        available: 'Disponible',
        initiated: 'Iniciado',
        placeholders: {
          enterFlowName: 'Ingrese el nombre de flujo',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Importar flujo para bot de Facebook',
          dateAndTimeOfCreationOfChatFlow: 'Fecha y hora de creación del flujo de chat',
          dateAndTimeWhenChatFlowLastModified: 'Fecha y hora cuando el flujo de chat se modificó por última vez',
          TOOLTIP_CREATE_FLOW: 'Haga clic para crear un nuevo flujo de chat',
          TOOLTIP_FLOW_NAME: 'Ingrese el nombre del flujo de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nombre del flujo de chat',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensaje en el flujo de chat',
          TOOLTIP_DEFAULT_FLOW: 'Use este alternar para establecer un flujo de chat como predeterminado, que se servirá a todos los nuevos usuarios.',
          TOOLTIP_ACTION: 'Editar o eliminar el flujo de chat',
        },
        noDataFound: {
          flows: 'Flujos',
          noFlowsAddedYet: '¡No hay flujos agregados todavía!',
        },
        modal: {
          deleteFlow: 'Eliminar flujo',
          wantToDeleteThisFlow: '¿Estás seguro de que quieres eliminar este flujo?',
        }
      },
      widgets: {
        manageWidgets: 'Administrar widgets',
        widgetsAreRoutingTools: 'Los widgets son las herramientas de enrutamiento para aumentar los usos de Bot',
        needHelp: '¿Necesitas ayuda?',
        wantToMakeChanges: '¿Quieres hacer cambios?',
        editIcon: 'Editar icono',
        discard: 'Desechar',
        updateWidget: 'Actualizar widget',
        installChatBotIconOnYourWebsite: 'Instale el icono de chatbot en su sitio web',
        shareWhereEverYouWantToRedirectUsersTo: 'Comparta donde quiera y redirige a los usuarios a su bot de Facebook en un solo clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copie y agregue el archivo de encabezado de su sitio web',
        copyLinkAndStartSharing: '¡Copiar enlace y comenzar a compartir!',
        downloadQrCodeAndStartSharing: '¡Descargue el código QR y comience a compartir!',
        addCodeAndDeployNewCodeAndDone: 'Agregue el código, implementa un nuevo código y termine.',
        wantToLearnHowToUseIt: '¿Quieres aprender a usarlo?',
        copyChatBotCode: 'Copiar código de chatbot',
        copyFacebookLink: 'Copiar enlace de Facebook',
        downloadQrCode: 'Descargar código QR',
        link: 'Enlace',
        chatBotIcon: 'Icono de chatbot',
        qrcode: 'Código QR',
        small: 'Pequeño',
        medium: 'Medio',
        large: 'Grande',
        leftMiddle: 'Medio izquierdo',
        rightMiddle: 'Diestro',
        leftBottom: 'Abajo a la izquierda',
        rightBottom: 'Boton derecho',
        bpDropDown: {
          selectIcons: 'Seleccionar iconos',
          iconSize: 'Tamaño de ícono',
          iconPlacement: 'Colocación de iconos',
        }
      },
      autoReply: {
        autoReplyTitle: 'Respuesta Automática',
        configureYour: 'Configura tu Mensaje de ',
        message: ' ',
        customizeAutoReplyMessagesForYourPostsComments: 'Personaliza los mensajes de respuesta automática para los comentarios en tus publicaciones',
        wouldYouLikeToApply: '¿Te gustaría aplicar la ',
        autoreply: 'Respuesta Automática',
        onAllPost: ' a todas las publicaciones?',
        configureYourMessageForEachPost: 'Configura tu Mensaje para Cada Publicación',
        configureFlowToTriggerAfterOnReply: 'Configura el flujo para activarse después de una respuesta',
        whenUserLeavesACommentSendMessageAfter: 'Cuando un usuario deja un comentario, envía el mensaje después',
        triggerFlowOnReply: 'Activar Flujo en la Respuesta',
        add: 'Agregar',
        update: 'Actualizar',
        save: 'Guardar',
        cancel: 'Cancelar',
        discard: 'Descartar',
        bpDropDown: {
          selectPost: 'Seleccionar Publicación',
          triggerFlowOnReply: 'Activar Flujo en la Respuesta',
          immendiately: 'Inmediatamente',
          minutes: 'minutos'
        },
        bpTable: {
          message: 'Mensaje',
          selectedPost: 'Publicación seleccionada',
          triggerFlow: 'Activar Flujo',
          actions: 'Acciones'
        },
        placeholder: {
          configureYourMessage: 'Configura tu mensaje'
        },
        deleteModel: {
          title: 'Eliminar Respuesta Automática de la Publicación',
          description: '¿Estás seguro de que deseas eliminar esta respuesta automática de la publicación?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'Tiempo de sesión ',
        sendInactivityMessage: 'Enviar mensaje de inactividad',
        enterSessionTimeOutWindowInHours: 'Ingrese la ventana de tiempo de cierre de sesión en horas',
        sessionTimeOutInHoursTooltip: `Ingrese la duración después de la cual la sesión de chat se cerrará automáticamente debido a la inactividad. El flujo de chat se reiniciará si el usuario envía algún mensaje después de que se agote el tiempo de sesión.`,
        restartChatFlowTriggers: 'Disparadores para reiniciar el flujo de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Ingrese palabras clave para reiniciar el chat y presione Enter',
        restartChatFlowTriggersTooltip: `Especifique aquí las palabras clave que, cuando el usuario las ingrese y las envíe, activarán un reinicio del flujo de chat. Esto permite a los usuarios reiniciar la conversación en cualquier momento.
         Presione Enter después de agregar cada palabra clave para agregar varias palabras clave.`,
        liveChatTriggers: 'Disparadores de chat en vivo',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Ingrese palabras clave para activar el chat en vivo y presione Enter',
        liveChatTriggersTooltip: `Defina palabras clave que, cuando el usuario las ingrese y las envíe, iniciarán una transición del chat automatizado al chat en vivo. Esto es útil cuando los usuarios necesitan ayuda de un agente en vivo.
         Presione Enter después de agregar cada palabra clave para agregar varias palabras clave.`,
        messageAfterAutomatedFlowCompleted: 'Mensaje después de completar el flujo automatizado',
        enterYourMessage: 'Ingrese su mensaje',
        messageAfterAutomatedFlowCompletedTooltip: `Ingrese un mensaje que se mostrará al usuario después de que haya completado el flujo de chat automatizado pero la sesión no ha caducado.`,
        restart: 'Reiniciar',
        liveChat: 'Chat en Vivo',
        lineOneForDefaultMessage: 'Si tiene más preguntas o necesita ayuda, aquí tiene lo que puede hacer a continuación:',
        to: 'A',
        restartTheChat: 'reiniciar el chat',
        simpleTypeRestartAndHitEnter: `simplemente escriba 'Reiniciar' y presione Enter. Esto lo llevará de vuelta al inicio de nuestro flujo de conversación.`,
        lineThreeForDefaultMessagePartOne: `Si desea hablar con un`,
        liveAgent: `agente en vivo,`,
        lineThreeForDefaultMessageLastPart: `escriba 'Chat en Vivo' y presione Enter. Nuestro equipo está aquí para ayudarle en tiempo real.`,
        saveChanges: 'Guardar Cambios',
        discard: 'Descartar',
        configureBotsGeneralSettings: 'Configurar las configuraciones generales de tu bot',
        minutes: 'Minutos',
        hours: 'Horas',
      }
    },
    instagram: {
      instagramBotSettings: 'Configuración de Bot de Instagram',
      chatFlowsHeader: 'Flujos de chat',
      broadcastHeader: 'Transmisión',
      dripCampaignHeader: 'Campaña de goteo',
      widgetsHeader: 'Widgets',
      alertsHeader: 'Alertas',
      alerts: {
        configureYourBotsSettings: 'Configurar la configuración de alerta de su bot',
        specifyHowYourBotNotifyYou: 'Especifique cómo su bot le notificará sobre nuevos clientes potenciales.',
        subject: 'Sujeto',
        emailNotification: 'Notificación de correo electrónico',
        emailAddresses: 'Correos electrónicos',
        leadRevisitNotifications: 'Notificaciones de Revisit para liderar',
        whenToSendLeadInfo: 'Cuándo enviar información de plomo',
        discard: 'Desechar',
        saveChanges: 'Guardar cambios',
        placeholders: {
          enterSubjectForYourEmail: 'Ingrese el tema para su correo electrónico',
          typeYourEmailAddressAndHitEnter: 'Escriba su dirección de correo electrónico y presione Enter',
        }
      },
      broadcasts: {
        createBroadcast: 'Crear transmisión',
        youCanCreateMultipleBroadcasts: 'Puedes crear múltiples transmisiones',
        broadcastName: 'Nombre de transmisión',
        broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
        continue: 'Continuar',
        manageBroadcasts: 'Administrar transmisiones',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Puede crear múltiples transmisiones con múltiples plantillas programadas para diferentes Grupoos de clientes',
        createNewBroadcast: 'Crea una nueva transmisión',
        segment: 'Grupoo',
        template: 'Plantilla',
        contacts: 'Contactos',
        send: 'Enviar',
        failed: 'Fallido',
        broadcastAt: 'Transmitir a',
        status: 'Estado',
        actions: 'Comportamiento',
        nA: 'N / A',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          searchHere: 'Busca aquí',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Eliminar múltiples transmisiones',
          deleteBroadcast: 'Eliminar la transmisión',
        }
      },
      createBroadcast: {
        broadcastName: 'Nombre de transmisión',
        broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
        flow: 'Fluir',
        segments: 'Grupoos',
        whenToSend: 'Cuando enviar',
        selectDate: 'Seleccione fecha',
        selectTimeSlot: 'Seleccione la ranura de tiempo',
        cancel: 'Cancelar',
        createBroadcast: 'Crear transmisión',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          selectFlow: 'Seleccionar flujo',
          searchFlowByName: 'Flujo de búsqueda por nombre',
          selectSegments: 'Seleccionar Grupoos',
          searchSegmentByName: 'Grupoo de búsqueda por nombre',
          selectTimeSlot: 'Seleccione la ranura de tiempo',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleccione la fecha de inicio',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crear campaña de goteo',
        youCanCreateMultipleCampaigns: 'Puedes crear múltiples campañas',
        dripCampaignName: 'Nombre de la campaña de goteo',
        dripCampaignNameIsRequired: 'Se requiere el nombre de la campaña de goteo',
        continue: 'Continuar',
        manageDripCampaign: 'Gestionar la campaña de goteo',
        youCanCreateMultipleCampaignsWith: 'Puede crear múltiples campañas de goteo con mensajes automatizados programados para activar respuestas de Grupoos de usuario específicos',
        createNewDripCampaign: 'Crear una nueva campaña de goteo',
        campaignName: 'Nombre de campaña',
        appliedOn: 'Aplicado en',
        segment: 'Grupoo',
        flow: 'Fluir',
        startDate: 'Fecha de inicio',
        action: 'Acción',
        nA: 'N / A',
        placeholders: {
          enterDripCampaignName: 'Ingrese el nombre de la campaña de goteo',
          searchByName: 'Buscar por nombre',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Eliminar múltiples campañas de goteo',
          deleteDripCampaign: 'Eliminar campaña de goteo',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Nombre de la campaña de goteo',
          appliedOn: 'Aplicado en',
          segments: 'Grupoos',
          selectDate: 'Seleccione fecha',
          scheduleYourFlows: 'Programe sus flujos',
          flow: 'Fluir',
          sendAfter: 'Enviar a',
          cancel: 'Cancelar',
          createDripCampaign: 'Crear campaña de goteo',
          placeholders: {
            enterDripName: 'Ingrese el nombre de goteo',
            selectAppliedOn: 'Seleccionar aplicado en',
            selectSegment: 'SELECCIÓN Grupoo',
            searchByName: 'Buscar por nombre',
            selectTemplate: 'Seleccionar plantilla',
            searchFlowByName: 'Flujo de búsqueda por nombre',
            enterSendAfter: 'Ingresar enviar después',
          },
          dateTimePicker: {
            selectStartDate: 'Seleccione la fecha de inicio',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'ID de chat',
        errorCode: 'Código de error',
        errorMessage: 'Mensaje de error',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flujos de chat',
        createChatFlow: 'Crear flujo de chat',
        youCanCreateMultipleChatFlows: 'Puede crear múltiples flujos de conversaciones a través de múltiples canales para pruebas y transmisiones A/B.',
        flowName: 'Nombre de flujo',
        nameIsRequired: 'Se requiere el nombre',
        cancel: 'Cancelar',
        createFlow: 'Crear flujo',
        importFlow: 'Flujo de importación',
        createNewFlow: 'Crear un nuevo flujo',
        // flowName: 'Nombre de flujo',
        ofMessages: ' de mensajes',
        createdOn: 'Creado en',
        lastModified: 'Última modificación',
        defaultFlow: 'Flujo predeterminado',
        action: 'Acción',
        exportStatus: 'Estado de exportación',
        flowExport: 'Flujo-exportación',
        download: 'Descargar',
        // importFlow: 'Flujo de importación',
        beforeYouStartUploadPleaseMakeSure: 'Antes de comenzar a subir por favor asegúrese de',
        point1: '1. El límite máximo de carga de tamaño de archivo es 1 MB',
        point2: '2. El formato de archivo debe estar en JSON',
        selectFile: 'Seleccione Archivo',
        placeholders: {
          enterFlowName: 'Ingrese el nombre de flujo',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Flujo de importación para Bot Instagram',
          dateAndTimeOfCreationOfChatFlow: 'Fecha y hora de creación del flujo de chat',
          dateAndTimeWhenChatFlowLastModified: 'Fecha y hora cuando el flujo de chat se modificó por última vez',
        },
        noDataFound: {
          flows: 'Flujos',
          noFlowsAddedYet: '¡No hay flujos agregados todavía!',
        },
        modal: {
          deleteFlow: 'Eliminar flujo',
          wantToDeleteThisFlow: '¿Estás seguro de que quieres eliminar este flujo?',
        }
      },
      widgets: {
        manageWidgets: 'Administrar widgets',
        widgetsAreRoutingTools: 'Los widgets son las herramientas de enrutamiento para aumentar los usos de Bot',
        needHelp: '¿Necesitas ayuda?',
        wantToMakeChanges: '¿Quieres hacer cambios?',
        editIcon: 'Editar icono',
        discard: 'Desechar',
        updateWidget: 'Actualizar widget',
        bpDropDown: {
          selectIcons: 'Seleccionar iconos',
          iconSize: 'Tamaño de ícono',
          iconPlacement: 'Colocación de iconos',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Configuración de bot de telegrama',
      chatFlowsHeader: 'Flujos de chat',
      telegramProfileHeader: 'Perfil de telegrama',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Transmisión',
      dripCampaignHeader: 'Campaña de goteo',
      generalHeader: 'General',
      telegramAutomation: 'Automatización de telegrama',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Cree un viaje de incorporación al cliente utilizando una campaña de goteo de flujos de chat programados.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalice las plantillas de flujo de chat y cree múltiples flujos de chat para diferentes audiencias de destino.',
      },
      broadcasts: {
        createBroadcast: 'Crear transmisión',
        youCanCreateMutlipleBroadcasts: 'Puedes crear múltiples transmisiones',
        broadcastName: 'Nombre de transmisión',
        broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
        continue: 'Continuar',
        manageBroadcasts: 'Administrar transmisiones',
        youCanCreateMultipleBroadcastsWith: 'Puede crear múltiples transmisiones con múltiples plantillas programadas para diferentes Grupos de clientes.',
        createNewBroadcast: 'Crea una nueva transmisión',
        name: 'Nombre',
        segment: 'Grupo',
        template: 'Plantilla',
        contacts: 'Contactos',
        send: 'Enviar',
        failed: 'Fallido',
        broadcastAt: 'Transmitir a',
        status: 'Estado',
        actions: 'Comportamiento',
        nA: 'N / A',
        deleteBroadcast: 'Eliminar la transmisión',
        wantToDeleteThisBroadcast: '¿Estás seguro de que quieres eliminar esta transmisión?',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          searchHere: 'Busca aquí',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Eliminar múltiples transmisiones',
          deleteBroadcast: 'Eliminar la transmisión',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'Nombre de transmisión',
        broadcastNameIsRequired: 'Se requiere el nombre de la transmisión',
        flow: 'Fluir',
        segments: 'Grupos',
        whenToSend: 'Cuando enviar',
        selectDate: 'Seleccione fecha',
        selectTimeSlot: 'Seleccione la ranura de tiempo',
        cancel: 'Cancelar',
        createBroadcast: 'Crear transmisión',
        now: 'Ahora',
        later: 'Más tarde',
        placeholders: {
          enterBroadcastName: 'Ingrese el nombre de la transmisión',
          selectFlow: 'Seleccionar flujo',
          searchFlowByName: 'Flujo de búsqueda por nombre',
          selectSegments: 'Seleccionar Grupos',
          searchSegmentByName: 'Grupo de búsqueda por nombre',
          selectTimeSlot: 'Seleccione la ranura de tiempo',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleccione la fecha de inicio',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crear campaña de goteo',
        youCanCreateMultipleCampaigns: 'Puedes crear múltiples campañas',
        dripCampaignName: 'Nombre de la campaña de goteo',
        dripCampaignNameIsRequired: 'Se requiere el nombre de la campaña de goteo',
        continue: 'Continuar',
        manageDripCampaign: 'Gestionar la campaña de goteo',
        youCanCreateMultipleDripCampaignsWith: 'Puede crear múltiples campañas de goteo con mensajes automatizados programados para activar respuestas de Grupos de usuario específicos.',
        createNewDripCampaign: 'Crear una nueva campaña de goteo',
        campaignName: 'Nombre de campaña',
        appliedOn: 'Aplicado en',
        segment: 'Grupo',
        flow: 'Fluir',
        startDate: 'Fecha de inicio',
        action: 'Acción',
        nA: 'N / A',
        deleteDripCampaign: 'Eliminar campañas de goteo',
        wantToDeleteThisDripCampaign: '¿Estás seguro de que quieres eliminar estas campañas de goteo?',
        placeholders: {
          enterDripCampaignName: 'Ingrese el nombre de la campaña de goteo',
          searchByName: 'Buscar por nombre',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Eliminar múltiples campañas de goteo',
          deleteDripCampaign: 'Eliminar campaña de goteo',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Nombre de la campaña de goteo',
          appliedOn: 'Aplicado en',
          segments: 'Grupos',
          startDate: 'Seleccione fecha',
          scheduleYourFlows: 'Programe sus flujos',
          schedule: 'Cronograma',
          flow: 'Fluir',
          sendAfter: 'Enviar a',
          cancel: 'Cancelar',
          createDripCampaign: 'Crear campaña de goteo',
          newSubscriber: 'Nuevo suscriptor',
          days: 'Días',
          minutes: 'Minutos',
          hours: 'Horas',
          placeholders: {
            enterDripName: 'Ingrese el nombre de goteo',
            selectAppliedOn: 'Seleccionar aplicado en',
            selectSegment: 'SELECCIÓN Grupo',
            searchByName: 'Buscar por nombre',
            selectFlow: 'Seleccionar flujo',
            searchFlowByName: 'Flujo de búsqueda por nombre',
          },
          dateTimePicker: {
            selectStartDate: 'Seleccione la fecha de inicio',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Nombre',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        errorCode: 'Código de error',
        errorMessage: 'Mensaje de error',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flujos de chat',
        createChatFlow: 'Crear flujo de chat',
        youCanCreateMultipleChatFlows: 'Puede crear múltiples flujos de conversaciones a través de múltiples canales para pruebas y transmisiones A/B.',
        flowName: 'Nombre de flujo',
        nameIsRequired: 'Se requiere el nombre',
        cancel: 'Cancelar',
        createFlow: 'Crear flujo',
        importFlow: 'Flujo de importación',
        createNewFlow: 'Crear un nuevo flujo',
        // flowName: 'Nombre de flujo',
        ofMessages: ' de mensajes',
        createdOn: 'Creado en',
        lastModified: 'Última modificación',
        defaultFlow: 'Flujo predeterminado',
        action: 'Acción',
        exportStatus: 'Estado de exportación',
        flowExport: 'Flujo-exportación',
        inProgress: 'en curso',
        available: 'Disponible',
        initiated: 'Iniciado',
        download: 'Descargar',
        // importFlow: 'Flujo de importación',
        beforeYouStartUploadPleaseMakeSure: 'Antes de comenzar a subir por favor asegúrese de',
        point1: '1. El límite máximo de carga de tamaño de archivo es 1 MB',
        point2: '2. El formato de archivo debe estar en JSON',
        selectFile: 'Seleccione Archivo',
        placeholders: {
          enterFlowName: 'Ingrese el nombre de flujo',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Flujo de importación para Bot Instagram',
          dateAndTimeOfCreationOfChatFlow: 'Fecha y hora de creación del flujo de chat',
          dateAndTimeWhenChatFlowLastModified: 'Fecha y hora cuando el flujo de chat se modificó por última vez',
          TOOLTIP_CREATE_FLOW: 'Haga clic para crear un nuevo flujo de chat',
          TOOLTIP_FLOW_NAME: 'Ingrese el nombre del flujo de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nombre del flujo de chat',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensaje en el flujo de chat',
          TOOLTIP_DEFAULT_FLOW: 'Use este alternar para establecer un flujo de chat como predeterminado, que se servirá a todos los nuevos usuarios.',
          TOOLTIP_ACTION: 'Editar o eliminar el flujo de chat',
        },
        noDataFound: {
          flows: 'Flujos',
          noFlowsAddedYet: '¡No hay flujos agregados todavía!',
        },
        modal: {
          deleteFlow: 'Eliminar flujo',
          wantToDeleteThisFlow: '¿Estás seguro de que quieres eliminar este flujo?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Perfil de telegrama',
        manageYourBotProfile: 'Administre su perfil de bot cuando lo desee.',
        allSetNoActionRequired: '¡Todo establecido, no se requiere acción!',
        congratulationYourAccountIsLive: '¡Felicidades! Su cuenta está en vivo con acceso completo',
        botDetails: 'Detalles del bot',
        name: 'Nombre',
        nA: 'N / A',
        b: 'B',
        botName: 'Nombre de bot',
        bot: 'Bot',
        info: 'Información',
        none: 'Ninguno',
        username: 'Nombre de usuario',
        notifications: 'Notificaciones',
        on: 'En',
        addToGroupOrChannel: 'Agregar al grupo o canal',
        userName: 'Nombre de usuario',
        botToken: 'Token de bot',
      },
      widgets: {
        manageWidgets: 'Administrar widgets',
        widgetsAreRoutingTools: 'Los widgets son las herramientas de enrutamiento para aumentar los usos de Bot',
        needHelp: '¿Necesitas ayuda?',
        wantToMakeChanges: '¿Quieres hacer cambios?',
        editIcon: 'Editar icono',
        discard: 'Desechar',
        updateWidget: 'Actualizar widget',
        installChatBotIconOnYourWebsite: 'Instale el icono de chatbot en su sitio web',
        shareWhereEverYouWantRedirectUsersTo: 'Comparta donde quiera y redirige a los usuarios a su bot de telegrama en un solo clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copie y agregue el archivo de encabezado de su sitio web',
        copyLinkAndStartSharing: '¡Copiar enlace y comenzar a compartir!',
        downloadQrCodeAndStartSharing: '¡Descargue el código QR y comience a compartir!',
        addCodeAndDeployNewCodeAndDone: 'Agregue el código, implementa un nuevo código y termine.',
        wantToLearnHowToUseIt: '¿Quieres aprender a usarlo?',
        copyChatBotCode: 'Copiar código de chatbot',
        downloadQrCode: 'Descargar código QR',
        copyTelegramLink: 'Copiar enlace de telegrama',
        link: 'Enlace',
        chatBotIcon: 'Icono de chatbot',
        qrcode: 'Código QR',
        small: 'Pequeño',
        medium: 'Medio',
        large: 'Grande',
        leftMiddle: 'Medio izquierdo',
        rightMiddle: 'Diestro',
        leftBottom: 'Abajo a la izquierda',
        rightBottom: 'Boton derecho',
        bpDropDown: {
          selectIcons: 'Seleccionar iconos',
          iconSize: 'Tamaño de ícono',
          iconPlacement: 'Colocación de iconos',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Tiempo de sesión ',
        sendInactivityMessage: 'Enviar mensaje de inactividad',
        enterSessionTimeOutWindowInHours: 'Ingrese la ventana de tiempo de cierre de sesión en horas',
        sessionTimeOutInHoursTooltip: `Ingrese la duración después de la cual la sesión de chat se cerrará automáticamente debido a la inactividad. El flujo de chat se reiniciará si el usuario envía algún mensaje después de que se agote el tiempo de sesión.`,
        restartChatFlowTriggers: 'Disparadores para reiniciar el flujo de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Ingrese palabras clave para reiniciar el chat y presione Enter',
        restartChatFlowTriggersTooltip: `Especifique aquí las palabras clave que, cuando el usuario las ingrese y las envíe, activarán un reinicio del flujo de chat. Esto permite a los usuarios reiniciar la conversación en cualquier momento.
         Presione Enter después de agregar cada palabra clave para agregar varias palabras clave.`,
        liveChatTriggers: 'Disparadores de chat en vivo',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Ingrese palabras clave para activar el chat en vivo y presione Enter',
        liveChatTriggersTooltip: `Defina palabras clave que, cuando el usuario las ingrese y las envíe, iniciarán una transición del chat automatizado al chat en vivo. Esto es útil cuando los usuarios necesitan ayuda de un agente en vivo.
         Presione Enter después de agregar cada palabra clave para agregar varias palabras clave.`,
        messageAfterAutomatedFlowCompleted: 'Mensaje después de completar el flujo automatizado',
        enterYourMessage: 'Ingrese su mensaje',
        messageAfterAutomatedFlowCompletedTooltip: `Ingrese un mensaje que se mostrará al usuario después de que haya completado el flujo de chat automatizado pero la sesión no ha caducado.`,
        restart: 'Reiniciar',
        liveChat: 'Chat en Vivo',
        lineOneForDefaultMessage: 'Si tiene más preguntas o necesita ayuda, aquí tiene lo que puede hacer a continuación:',
        to: 'A',
        restartTheChat: 'reiniciar el chat',
        simpleTypeRestartAndHitEnter: `simplemente escriba 'Reiniciar' y presione Enter. Esto lo llevará de vuelta al inicio de nuestro flujo de conversación.`,
        lineThreeForDefaultMessagePartOne: `Si desea hablar con un`,
        liveAgent: `agente en vivo,`,
        lineThreeForDefaultMessageLastPart: `escriba 'Chat en Vivo' y presione Enter. Nuestro equipo está aquí para ayudarle en tiempo real.`,
        saveChanges: 'Guardar Cambios',
        discard: 'Descartar',
        configureBotsGeneralSettings: 'Configurar las configuraciones generales de tu bot',
        minutes: 'Minutos',
        hours: 'Horas',
      }
    },
    whatsapp: {
      whatsappAutomation: 'Automatización de whatsapp',
      whatsappBotSettings: 'Configuración de Bot de WhatsApp',
      chatFlowsHeader: 'Flujos de chat',
      whatsappProfileHeader: 'Perfil de WhatsApp',
      broadcastHeader: 'Transmisión',
      dripCampaignHeader: 'Campaña de goteo',
      widgetHeader: 'Widget',
      templatesHeader: 'Plantillas',
      alertsHeader: 'Alertas',
      apiKeyHeader: 'Clave API',
      catalogHeader: 'Catalogo',
      generalHeader: 'General',
      otherConfiguration: 'Configuración de IA',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalice las plantillas de flujo de chat y cree múltiples flujos de chat para diferentes audiencias de destino.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Configurar la configuración de alerta de su bot',
        specifyHowYouBotWillNotify: 'Especifique cómo su bot le notificará sobre nuevos clientes potenciales.',
        subject: 'Sujeto',
        emailNotification: 'Notificación de correo electrónico',
        emailAddresses: 'Correos electrónicos',
        leadRevisitNotifications: 'Notificaciones de Revisit para liderar',
        whenToSendLeadInfo: 'Cuándo enviar información de plomo',
        discard: 'Desechar',
        saveChanges: 'Guardar cambios',
        seeHowToUse: 'Vea cómo usar',
        beginningTheFlow: 'Comenzando el flujo',
        fiveMinutesLater: '5 minutos después',
        endOfFlow: 'Fin del flujo',
        placeholders: {
          enterSubjectForYourEmail: 'Ingrese el tema para su correo electrónico',
          typeEmailAndHitEnter: 'Escriba su dirección de correo electrónico y presione Enter',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Personalice el tema de su correo electrónico de notificaciones desde el bot',
          TOOLTIP_EMAIL_NOTIFICATION: 'Habilitar/deshabilitar la notificación del bot en su correo electrónico para nuevos clientes potenciales',
          TOOLTIP_EMAIL_ADDRESS: 'Envíe la dirección de correo electrónico de la cuenta donde desea recibir notificaciones por correo electrónico. Puede agregar varias direcciones de correo electrónico ingresando una y presionando Enter para agregar otra.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Habilitar/deshabilitar la notificación de BOT en su correo electrónico si un usuario previamente capturado como plomo interactúa nuevamente con el bot.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Actualizar WhatsApp Chatbot',
        businessNameIsRequired: 'Se requiere nombre comercial.',
        maxLengthForBusinessName40Characters: 'La longitud máxima para el nombre comercial es de 40 caracteres.',
        categoryIsRequired: 'La categoria es requerida.',
        descriptionIsRequired: 'Se requiere descripción.',
        maxLengthForDescription200Characters: 'La longitud máxima para la descripción es de 200 caracteres.',
        aboutIsRequired: 'Se requiere sobre.',
        maxLengthForAbout139Characters: 'La longitud máxima para aproximadamente es de 139 caracteres.',
        pleaseFillAValidBusinessEmail: 'Complete un correo electrónico comercial válido.',
        businessEmailIsRequired: 'Se requiere correo electrónico comercial.',
        websiteIsRequired: 'Se requiere sitio web.',
        maxLengthForWebsite40Characters: 'La longitud máxima para el sitio web es de 40 caracteres.',
        maxLengthForAddress150Characters: 'La longitud máxima para la dirección es de 150 caracteres.',
        cancel: 'Cancelar',
        update: 'Actualizar',
        businessCategory: 'categoría de negocios',
        placeholders: {
          yourBusinessName: 'Tu nombre comercial',
          businessDescription: 'descripción del negocio',
          about: 'Acerca de',
          businessEmail: 'email de negocios',
          website: 'Sitio web (por ejemplo, www.example.com)',
          address: 'DIRECCIÓN',
        }
      },
      flows: {
        chatFlows: 'Flujos de chat',
        createChatFlow: 'Crear flujo de chat',
        youCanCreateMultipleChatFlows: 'Puede crear múltiples flujos de conversaciones a través de múltiples canales para pruebas y transmisiones A/B.',
        flowName: 'Nombre de flujo',
        nameIsRequired: 'Se requiere el nombre',
        cancel: 'Cancelar',
        createFlow: 'Crear flujo',
        importFlow: 'Flujo de importación',
        createNewFlow: 'Crear un nuevo flujo',
        ofMessages: ' de mensajes',
        createdOn: 'Creado en',
        lastModified: 'Última modificación',
        defaultFlow: 'Flujo predeterminado',
        revisitFlow: 'Revise el flujo',
        action: 'Acción',
        exportStatus: 'Estado de exportación',
        flowExport: 'Flujo-exportación',
        // importFlow: 'Flujo de importación',
        beforeYouStartUploadPleaseMakeSure: 'Antes de comenzar a subir por favor asegúrese de',
        point1: '1. El límite máximo de carga de tamaño de archivo es 1 MB',
        point2: '2. El formato de archivo debe estar en JSON',
        selectFile: 'Seleccione Archivo',
        seeHowToUse: 'Vea cómo usar',
        download: 'Descargar',
        available: 'Disponible',
        initiated: 'Iniciado',
        inProgress: 'en curso',
        placeholders: {
          enterFlowName: 'Ingrese el nombre de flujo',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Flujo de importación para Bot de WhatsApp',
          dateAndTimeOfCreationOfChatFlow: 'Fecha y hora de creación del flujo de chat',
          dateAndTimeWhenChatFlowLastModified: 'Fecha y hora cuando el flujo de chat se modificó por última vez',
          TOOLTIP_CREATE_FLOW: 'Haga clic para crear un nuevo flujo de chat',
          TOOLTIP_FLOW_NAME: 'Ingrese el nombre del flujo de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nombre del flujo de chat',
          TOOLTIP_NO_OF_MESSAGES: 'No. de componentes de mensaje en el flujo de chat',
          TOOLTIP_DEFAULT_FLOW: 'Use este alternar para establecer un flujo de chat como predeterminado, que se servirá a todos los nuevos usuarios.',
          TOOLTIP_REVISIT_FLOW: 'Use este alternar para seleccionar un flujo de chat para servir a los usuarios cuando vuelvan a visitar su chatbot.',
          TOOLTIP_ACTION: 'Editar o eliminar el flujo de chat',
        },
        modal: {
          deleteFlow: 'Eliminar flujo',
          wantToDeleteThisFlow: '¿Estás seguro de que quieres eliminar este flujo?',
        },
        noDataFound: {
          flows: 'Flujos',
          noFlowsAddedYet: '¡No hay flujos agregados todavía!',
        }
      },
      input: {
        manageTemplates: 'Administrar plantillas',
        manageYourTemplatesForMarketingAutomation: 'Administre sus plantillas para la automatización de marketing',
        syncTemplates: 'Plantillas de sincronización',
        createTemplateHeader: 'Crear plantilla',
        title: 'Título',
        info: 'Información',
        category: 'Categoría',
        status: 'Estado',
        action: 'Acción',
        shipmentUpdate: 'Actualización de envío',
        name: 'Nombre',
        // category: 'Categoría',
        headerOptional: 'Encabezado (opcional)',
        bodyRequired: 'Cuerpo (requerido)',
        footerOptional: 'Pie de página (opcional)',
        buttonsOptional: 'Botones (opcional)',
        makeUpto3Buttons: 'Configurar hasta 3 botones',
        makeUpto2ButtonsToRedirect: 'Realice hasta 2 botones para redirigir al cliente a su sitio web',
        placeholders: {
          nameYourTemplate: 'Nombra tu plantilla (use un bajo para separar palabras)',
          enterText: 'Ingrese texto (máximo 60 caracteres)',
          enterImageUrl: 'Ingrese la URL de la imagen',
          enterVideoUrl: 'Ingrese URL de video',
          templateMessage: 'Mensaje de plantilla',
          smallFooterAtBottom: 'Pie de página pequeño en la parte inferior',
          buttons: 'Botones',
          text: 'Texto',
          numberWithCountryCode: 'Número con código de país',
          enterButtonText: 'Ingrese el texto del botón (Carácter máximo 20)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Título de la plantilla de WhatsApp',
          TOOLTIP_INPUT_TABLE_INFO: 'Información detallada de los mensajes iniciados por el negocio',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Tipo de mensajes iniciados por el negocio como citas, recordatorios, mensajes de atención al cliente, actualizaciones de envío, alertas y más',
          TOOLTIP_INPUT_TABLE_STATUS: 'El estado de la plantilla es rechazado o aprobado por WhatsApp. Las plantillas de WhatsApp deben ser aprobadas primero por WhatsApp y este es un servicio pagado.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Eliminar la plantilla',
        },
        noDataFound: {
          templates: 'Plantillas',
          noTemplatesCreatedYet: '¡No hay plantillas creadas todavía!',
        },
        modal: {
          deleteTemplate: 'Plantilla eliminar',
          wantToDeleteThisTemplate: '¿Estás seguro de que quieres eliminar esta plantilla?',
        },
        createTemplate: {
          createTemplates: 'Crear plantillas',
          createTemplatesForMarketingAutomation: 'Crea tus plantillas para la automatización de marketing',
          templateMeta: 'Plantilla meta',
          selectLanguage: 'Seleccione el idioma',
          header: 'Encabezamiento',
          optional: 'Opcional',
          samplesForHeaderContent: 'Muestras para contenido de encabezado',
          toHelpMetaReviewYourContent: 'Para ayudar a revisar su contenido, proporcionar ejemplos de las variables o medios en el encabezado. No incluya ninguna información del cliente.',
          body: 'Cuerpo',
          addVariable: 'Agregar variable',
          samplesForBodyContent: 'Muestras para el contenido del cuerpo',
          toHelpUsReviewYourContent: 'Para ayudarnos a revisar su contenido, proporcione ejemplos de las variables en el cuerpo. No incluya ninguna información del cliente.',
          footer: 'Pie de página',
          // optional: 'Opcional',
          buttons: 'Botones',
          // optional: 'Opcional',
          typeOfAction: 'Tipo de acción',
          call: 'Llamar',
          phone: 'Teléfono',
          website: 'Sitio web',
          static: 'Estático',
          urlType: 'Tipo de URL',
          type: 'Tipo',
          customButton: 'Botón personalizado',
          addAnotherButton: 'Agregar otro botón',
          preview: 'Avance',
          test: 'Prueba',
          create: 'Crear',
          addSampleUrl: 'Agregar URL de muestra',
          toHelpMetaReviewYourMessage: 'Para ayudar a revisar la plantilla de su mensaje, agregue un ejemplo de la URL del sitio web. No use información real del cliente.',
          placeholders: {
            enterMessageTemplateName: 'Ingrese el nombre de la plantilla de mensaje',
            searchTemplateByName: 'Plantilla de búsqueda por nombre',
            selectTemplate: 'Seleccionar plantilla',
            enterText: 'Ingrese texto',
            enterBody: 'Entrar en el cuerpo',
            enterContentForVariable: 'Ingrese el contenido para la variable',
            enterFooter: 'Ingrese el pie de página',
            phone: 'Teléfono*',
            enterWebsiteUrl: 'Ingrese la URL del sitio web',
          },
          bpInput: {
            name: 'Nombre',
            buttonText: 'Botón de texto',
            websiteUrl: 'URL del sitio web',
          },
          bpDropDown: {
            selectCategory: 'selecciona una categoría',
          }
        },
        templatePreview: {
          preview: 'Avance',
          test: 'Prueba',
        },
        templateConfig: {
          discard: 'Desechar',
          saveChanges: 'Guardar cambios',
          triggerChatFlowOnTemplateReply: 'Activar flujo de chat en respuesta de plantilla',
          templateDetails: 'Detalles de la plantilla',
          configureFlows: 'Configurar flujos',
          selectFlow: 'Seleccionar Flujo',
          name: 'Nombre'
        }
      },
      shopManagement: {
        shopManagement: 'Gerencia de la tienda',
        manageECommerceShopFromBotPenguin: 'Administrar tiendas de comercio electrónico conectadas con botpenguin',
        comingSoon: '( Muy pronto )',
        wooCommerce: {
          createConfiguration: 'Crear configuración',
          eventConfiguration: {
            createConfiguration: 'Crear configuración',
            templateHeader: 'Encabezado de plantilla',
            selectFile: 'Seleccione Archivo',
            templateMessage: 'Mensaje de plantilla',
            saveChanges: 'Guardar cambios',
            bpDropDown: {
              selectEvent: 'Seleccionar evento',
              selectTemplate: 'Seleccionar plantilla',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'Perfil de WhatsApp',
        manageYourBotProfile: 'Administre su perfil de bot cuando lo desee.',
        allSetNoActionRequired: '¡Todo establecido, no se requiere acción!',
        congratulationsYourAccountIsLive: '¡Felicidades! Su cuenta está en vivo con acceso completo',
        somethingIsWrongReactivateYourBot: '¡Algo anda mal, reactiva tu bot!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Parece que su bot no funciona correctamente, restablezca su bot',
        reConfigureYourBot: 'Vuelva a configure su bot',
        phoneNumberDetails: 'Detalles del número de teléfono',
        phoneNumber: 'Número de teléfono',
        nA: 'N / A',
        phoneNumberId: 'ID de número de teléfono',
        wabaId: 'ID de WABA',
        change: 'Cambiar',
        accessToken: 'Token de acceso',
        // change: 'Cambiar',
        messagingLimit: 'Límite de mensajería',
        qualityRating: 'Calificación de calidad',
        whatsappBusinessAccountName: 'Nombre de la cuenta comercial de WhatsApp',
        whatsappPublicProfile: 'Perfil público de WhatsApp',
        profilePicture: 'Foto de perfil',
        aboutText: 'Acerca del texto',
        address: 'DIRECCIÓN',
        description: 'Descripción',
        email: 'Correo electrónico',
        websitePrimary: 'Sitio web (primario)',
        websiteSecondary: 'Sitio web (secundario)',
        businessVertical: 'Vertical de negocios',
        editProfile: 'Editar perfil',
        thisIsABusinessAccount: 'Esta es una cuenta comercial',
        aboutAndPhoneNumber: 'Acerca y número de teléfono',
        yourTrialAccountIsLive: '¡Felicidades! Su cuenta de prueba está en vivo con acceso completo en su número verificado',
        updateWithNewAccessToken: 'Actualizar con nuevo AccessToken',
      },
      widgets: {
        manageWidgets: 'Administrar widgets',
        widgetsAreRoutingTools: 'Los widgets son las herramientas de enrutamiento para aumentar los usos de Bot',
        needHelp: '¿Necesitas ayuda?',
        wantToMakeChanges: '¿Quieres hacer cambios?',
        prePopulatedMessage: 'Mensaje previamente poblado',
        discard: 'Desechar',
        updateWidget: 'Actualizar widget',
        hiThere: 'hola',
        seeHowToUse: 'Vea cómo usar',
        editIcon: 'Editar icono',
        editPrePopulatedMessage: 'Editar un mensaje previamente poblado',
        small: 'Pequeño',
        medium: 'Medio',
        large: 'Grande',
        leftMiddle: 'Medio izquierdo',
        rightMiddle: 'Diestro',
        leftBottom: 'Abajo a la izquierda',
        rightBottom: 'Boton derecho',
        chatBotIcon: 'Icono de chatbot',
        link: 'Enlace',
        qrcode: 'Código QR',
        copyChatBotCode: 'Copiar código de chatbot',
        copyWALink: 'Copiar enlace WA',
        downloadQrCode: 'Descargar código QR',
        installChatBotIconOnYourWebsite: 'Instale el icono de chatbot en su sitio web',
        shareWhereEverYouWantRedirectUsersTo: 'Comparta donde desee y redirige a los usuarios a su bot de WhatsApp en un solo clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copie y agregue el archivo de encabezado de su sitio web',
        copyLinkAndStartSharing: '¡Copiar enlace y comenzar a compartir!',
        downloadQrCodeAndStartSharing: '¡Descargue el código QR y comience a compartir!',
        addCodeAndDeployNewCodeAndDone: 'Agregue el código, implementa un nuevo código y termine.',
        wantToLearnHowToUseIt: '¿Quieres aprender a usarlo?',
        placeholders: {
          enterWhatsappNumber: 'Ingrese el número de whatsapp',
          updateYourMessageHere: 'Actualice su mensaje aquí ...',
        },
        bpDropDown: {
          selectIcons: 'Seleccionar iconos',
          iconSize: 'Tamaño de ícono',
          iconPlacement: 'Colocación de iconos',
        },
        bpInput: {
          whatsappNumber: 'Número de whatsapp',
        }
      },
      catalog: {
        manageCatalog: 'Administrar catálogo',
        completeStepsBelowToEnableCatalog: 'Completa los pasos a continuación para habilitar el catálogo',
        createCatalog: 'Crear Catálogo',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Crear un catálogo de productos en el Meta Commerce Manager o vincularlo con Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Usar el Meta Commerce Manager para gestionar catálogos',
        connectCatalogToWhatsAppAccount: 'Conectar Catálogo a la cuenta de WhatsApp',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Conectar tus catálogos a la cuenta de WhatsApp usando tu administrador de WhatsApp',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Usar tu administrador de Meta WhatsApp para conectar catálogos y usarlos con BotPenguin',
        goToMeta: 'Ir a Meta',
        goToShopify: 'Ir a Shopify',
        next: 'Siguiente',
        back: 'Atrás',
        setupCatalog: 'Configurar Catálogo',
        completeAllTheStepsToEnableCatalogMessages: 'Completa todos los pasos para habilitar mensajes de catálogo'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Tiempo de sesión ',
        sendInactivityMessage: 'Enviar mensaje de inactividad',
        enterSessionTimeOutWindowInHours: 'Ingrese la ventana de tiempo de cierre de sesión en horas',
        sessionTimeOutInHoursTooltip: `Ingrese la duración después de la cual la sesión de chat se cerrará automáticamente debido a la inactividad. El flujo de chat se reiniciará si el usuario envía algún mensaje después de que se agote el tiempo de sesión.`,
        restartChatFlowTriggers: 'Disparadores para reiniciar el flujo de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Ingrese palabras clave para reiniciar el chat y presione Enter',
        restartChatFlowTriggersTooltip: `Especifique aquí las palabras clave que, cuando el usuario las ingrese y las envíe, activarán un reinicio del flujo de chat. Esto permite a los usuarios reiniciar la conversación en cualquier momento.
         Presione Enter después de agregar cada palabra clave para agregar varias palabras clave.`,
        liveChatTriggers: 'Disparadores de chat en vivo',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Ingrese palabras clave para activar el chat en vivo y presione Enter',
        liveChatTriggersTooltip: `Defina palabras clave que, cuando el usuario las ingrese y las envíe, iniciarán una transición del chat automatizado al chat en vivo. Esto es útil cuando los usuarios necesitan ayuda de un agente en vivo.
         Presione Enter después de agregar cada palabra clave para agregar varias palabras clave.`,
        messageAfterAutomatedFlowCompleted: 'Mensaje después de completar el flujo automatizado',
        enterYourMessage: 'Ingrese su mensaje',
        messageAfterAutomatedFlowCompletedTooltip: `Ingrese un mensaje que se mostrará al usuario después de que haya completado el flujo de chat automatizado pero la sesión no ha caducado.`,
        restart: 'Reiniciar',
        liveChat: 'Chat en Vivo',
        lineOneForDefaultMessage: 'Si tiene más preguntas o necesita ayuda, aquí tiene lo que puede hacer a continuación:',
        to: 'A',
        restartTheChat: 'reiniciar el chat',
        simpleTypeRestartAndHitEnter: `simplemente escriba 'Reiniciar' y presione Enter. Esto lo llevará de vuelta al inicio de nuestro flujo de conversación.`,
        lineThreeForDefaultMessagePartOne: `Si desea hablar con un`,
        liveAgent: `agente en vivo,`,
        lineThreeForDefaultMessageLastPart: `escriba 'Chat en Vivo' y presione Enter. Nuestro equipo está aquí para ayudarle en tiempo real.`,
        saveChanges: 'Guardar Cambios',
        discard: 'Descartar',
        configureBotsGeneralSettings: 'Configurar las configuraciones generales de tu bot',
        minutes: 'Minutos',
        hours: 'Horas',
      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'A solo un paso',
      seeHowToUse: 'Ver cómo usar',
      testWhatsAppTrialBot: 'Prueba tu Chatbot de prueba de WhatsApp',
      sendMessage: 'Enviar mensaje',
      sendWhatsAppMessageOn: 'Envíanos un mensaje en WhatsApp al ',
      bySavingInYourContact: 'guardándolo en tus contactos y el mensaje debe comenzar con ',
      connect: 'Conectar',
      or: 'O',
      scanQRCode: 'Escanear el código QR',
      yourPhoneWillOpen: 'Tu teléfono abrirá un mensaje prellenado para enviar a nuestro número de Sandbox. Haz clic en \'Enviar\' en WhatsApp',
      sendVerificationCode: 'Enviar código de verificación',
      copyAndEnterCode: 'Copia y introduce el código de 6 dígitos',
      shareWhereYouWant: 'Comparte donde quieras y redirige a los usuarios a tu bot de WhatsApp en un solo clic',
      youShouldHaveReceived: 'Deberías haber recibido un código de 6 dígitos en tu número de WhatsApp',
      EnterYourPhoneNumber: 'Ingresa tu número de teléfono',
      send: 'Enviar',
      sixDigitCode: 'Ingresa el código de 6 dígitos',
      pleaseNote: 'Por favor nota: El código de verificación de 6 dígitos puede tomar hasta 3 minutos. Por favor espera antes de reenviarlo.',
      resendVerificationCode: 'Reenviar código de verificación',
      verify: 'Verificar',
      providePhoneNumber: 'Por favor proporciona el número que deseas utilizar para las pruebas',
      demoBotisReady: '¡Genial! Tu bot de demostración está listo para usar',
      placeHolder: {
        phone: 'Ingrese su número de teléfono',
        code: 'Ingrese el código de verificación',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Integración de aplicaciones de terceros',
    thirdPartyApps: 'Aplicaciones de terceros',
    customApps: 'Aplicaciones personalizadas',
    preview: 'Vista previa',
    test: 'Prueba',
    install: 'Instalar',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Haga clic para instalar su bot en la plataforma seleccionada',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Integre sus aplicaciones creadas a medida con su bot',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Conecte su bot con aplicaciones/CRM de terceros disponibles',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Integraciones de aplicaciones de terceros',
        integrateYourBotWithListedCrms: 'Integre su bot con una de las aplicaciones/CRM en la lista.',
        help: 'Ayuda',
        name: 'Nombre',
        status: 'Estado',
        action: 'Acción',
        chatGpt: 'Chatgpt',
        addOn: 'Añadir',
        purchased: 'Comprado',
        readyToRideGptWave: '¿Listo para montar la ola GPT? Integrar ahora.',
        purchase: 'Compra',
        notConnected: 'No conectado',
        integrated: 'Integrado',
        configure: 'Configurar',
        connected: 'Conectado',
        edit: 'Editar',
        connect: 'Conectar',
        requestYourIntegration: 'Solicite su integración',
        toRequestNewIntegration: 'Para solicitar una nueva integración, ingrese los detalles mencionados a continuación y luego nos comunicaremos con usted',
        // name: 'Nombre',
        emailId: 'Identificación de correo',
        integrationName: 'Nombre de integración',
        contactNumber: 'Número de contacto',
        howSoonYouWantToAddThisIntegration: 'Qué tan pronto desea agregar esta integración',
        sendRequest: 'Enviar petición',
        cancel: 'Cancelar',
        requestSent: '¡Solicitud enviada!',
        yourRequestSuccessfullySent: 'Su solicitud de nueva integración se ha enviado con éxito.',
        goBack: 'Regresa',
        disconnect: 'Desconectar',
        OopsIntegrationAreNotAvailable: '¡Ups! Las integraciones no están disponibles en el ',
        pleaseUpgradeTo: ' Por favor, actualice al ',
        planForIntegration: ' para las integraciones.',
        placeholders: {
          searchIntegrationByName: 'Integración de búsqueda por nombre',
          enterTeamMemberName: 'Ingrese el nombre del miembro del equipo',
          enterEmailId: 'Ingrese ID de correo electrónico',
          enterIntegrationName: 'Ingrese un nombre de integración',
          searchCountry: 'Búsqueda del país',
          enterContactNumber: 'Ingrese el número de contacto',
          addComment: 'Agregar comentario',
        },
        noDataFound: {
          integrations: 'Integración',
          noIntegrationsFound: '¡No se encontraron integraciones!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Nombre de la aplicación/CRM',
          statusAsConnectedOrNotConnected: 'Estado conectado / no conectado',
          clickToConnectToAppOrCrm: 'Haga clic para conectar la aplicación/CRM',
        },
        feedbackModal: {
          submit: 'Entregar',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          agileCrmDomain: 'Dominio CRM ágil',
          nameIsRequired: 'Se requiere el nombre',
          emailAddress: 'Dirección de correo electrónico',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameter: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'ejemplo@company.extention',
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          accessKey: 'Llave de acceso',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiKeyHere: 'Ingrese la clave API aquí',
            enterAccessKeyHere: 'Ingrese la clave de acceso aquí',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          nameColon: 'Nombre:',
          inboundWebhookUrl: 'URL de webhook entrante',
          nameIsRequired: 'Se requiere el nombre',
          leadTitle: 'Título principal',
          source: 'Fuente',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterInboundWebhookUrl: 'Ingrese la URL de Webhook de Inbound ...',
            enterTitle: 'Ingrese el título',
            enterSource: 'Ingresar fuente',
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          testLead: 'Cable de prueba',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          testLead: 'Cable de prueba',
          connect: 'Conectar',
          selectTheQuestion: 'Seleccione la pregunta cuya respuesta del usuario desea enviar como valor',
          mapWith: 'mapear',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
            attributes: 'Atributos (clave)',
            values: 'valores',
          }
        },
        chatGpt: {
          chatGptApiKey: 'Chatgpt (abrir AI) Clave de API',
          chatGpt: 'Chatgpt (abierto ai)',
          readyToRideWave: '¿Listo para montar la ola GPT? Integrar ahora.',
          doNotHaveAnyApiKeyNeedHelp: '¿No tiene una clave API, necesitas ayuda?',
          authenticate: 'Autenticar',
          nowYouCanAccessChatGpt: 'Ahora puede acceder al componente GPT de chat en el constructor de flujo de chat',
          paymentSuccessful: '¡Pago exitoso!',
          paymentHasBeenCompletedSuccessfully: 'El pago se ha completado con éxito. Están listo para entrenar a su bot',
          cancel: 'Cancelar',
          trainNow: 'Entrenar ahora',
          paymentInProgress: 'Pago en progreso ...',
          apiKey: 'ChatGPT API -Schlüssel',
          model: 'Modell',
          integrateWithChatGPT: 'Integración con ChatGPT',
          updateKey: 'Actualizar clave',
          connect: 'Conectar',
          update: 'Actualizar',
          placeholders: {
            chatGptApiKey: 'Chatgpt (abrir AI) Clave de API',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          leadId: 'ID de plomo',
          selectLeadId: 'Seleccionar LeadID',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          emailAddress: 'Dirección de correo electrónico',
          leadTitle: 'Título principal',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            exampleEmail: 'ejemplo@company.extention',
            enterYourLeadTitle: 'Ingrese su título principal',
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          dripCrmAccountId: 'ID de cuenta de goteo CRM',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterAccountIdHere: 'Ingrese la ID de cuenta aquí',
            enterYourTokenHere: 'Ingrese su token aquí',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apikey: 'Clave API',
          accessToken: 'Token de acceso',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          selectParameter: 'Seleccionar parámetro',
          disconnect: 'Desconectar',
          update: 'Actualizar',
          save: 'Ahorrar',
          placeholders: {
            enterApiKeyHere: 'Ingrese la clave API aquí',
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          testLead: 'Cable de prueba',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          authToken: 'Authtoken',
          source: 'Fuente',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterAuthTokenHere: 'Ingrese AuthToken aquí',
            enterSourceHere: 'Ingrese la fuente aquí',
          }
        },
        facebookPixel: {
          facebookPixel: 'Píxel de Facebook',
          getInsight: `Obtén conocimientos que convierten tu negocio en una empresa de primer nivel con la integración del Facebook Pixel de BotPenguin.`,
          pixelId: 'ID del píxel de Facebook',
          connect: 'conectar',
          disconnect: 'desconectar',
          placeholders: {
            enterYourId: 'ID del píxel de Facebook'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          companyName: 'Nombre de empresa',
          emailAddress: 'Dirección de correo electrónico',
          password: 'Contraseña',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapsWith: 'mapas con',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterCompanyName: 'Ingrese el nombre de la empresa',
            enterEmailAddress: 'Introducir la dirección de correo electrónico',
            enterPassword: 'Introducir la contraseña',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiKeyHere: 'Entra a apikey aquí',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          flowluDomain: 'Dominio de flujo',
          apiToken: 'Token API',
          title: 'Título',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'Ingrese token aquí',
            enterYourLeadTitle: 'Ingrese su título principal',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          freshdeskDomain: 'Dominio Freshdesk',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          freshworkCrmDomain: 'Dominio FreshWork CRM',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'Ingrese token aquí',
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          mapWith: 'mapear',
          select: 'Seleccionar',
          update: 'Actualizar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          campaignList: 'Lista de campañas',
          selectCampaign: 'Seleccionar campaña',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google analitico',
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          measurementId: 'ID de medición',
          connect: 'Conectar',
          disconnect: 'Desconectar',
          placeholders: {
            enterYourId: 'Ingresa tu ID',
          }
        },
        googleCalendar: {
          testLead: 'Cable de prueba',
          name: 'Nombre:',
          email: 'Correo electrónico:',
          appointmentSummary: 'Resumen de la cita',
          appointmentDescription: 'Descripción de la cita',
          addParameters: ' Agregar parámetros',
          questionColon: 'Pregunta:',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          pause: 'Pausa',
          resume: 'Reanudar',
          disconnect: 'Desconectar',
          reconnect: 'Volver a conectar',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          name: 'Nombre :',
          email: 'Correo electrónico :',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
        },
        googleRedirect: {
        },
        googleSheet: {
          testLead: 'Cable de prueba',
          title: 'Título',
          questionColon: 'Pregunta:',
          addParameters: 'Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          update: 'Actualizar',
          save: 'Ahorrar',
          pause: 'Pausa',
          resume: 'Reanudar',
          disconnect: 'Desconectar',
          reconnect: 'Volver a conectar',
          placeholders: {
            mappingValues: 'Valores de mapeo',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          name: 'Nombre:',
          email: 'Correo electrónico:',
          taskList: 'Lista de tareas',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          pause: 'Pausa',
          resume: 'Reanudar',
          disconnect: 'Desconectar',
          reconnect: 'Volver a conectar',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          select: 'Seleccionar',
          addParameters: ' Agregar parámetros',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiHere: 'Ingrese API aquí',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          testLead: 'Cable de prueba',
          accountId: 'ID de la cuenta',
          accessToken: 'Token de acceso',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterAccountIdHere: 'Ingrese la cuenta de la cuenta aquí',
            enterAccessTokenHere: 'Ingrese el token de acceso aquí',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Clave API',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          testLead: 'Cable de prueba',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        helpLink: {
          help: 'Ayuda',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          helpRaceCrmDomain: 'Dominio CRM de Helprace',
          emailAddress: 'Dirección de correo electrónico',
          apiToken: 'Token API',
          connect: 'Conectar',
          update: 'Actualizar',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'ejemplo@company.extension',
            token: 'Simbólico',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          domainName: 'Nombre de dominio',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiHere: 'Ingrese API aquí',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          emailAddress: 'Dirección de correo electrónico',
          apiUrl: 'URL API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          // emailAddress: 'Dirección de correo electrónico',
          selectProjectName: 'Seleccionar el nombre del proyecto',
          selectIssueType: 'Seleccione Tipo de emisión',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapsWith: 'mapas con',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
            enterEmailAddress: 'Introducir la dirección de correo electrónico',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiUrl: 'URL API',
          nameIsRequired: 'Se requiere el nombre',
          apiKey: 'Clave API',
          apiId: 'ID de API',
          // nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          mapWith: 'mapear',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          selectTheQuestion: 'Seleccione la pregunta cuya respuesta del usuario desea enviar como valor',
          placeholders: {
            enterApiUrl: 'Ingrese la URL API',
            enterApiKey: 'Ingrese la clave API',
            enterApiId: 'Ingrese la ID de API',
            attributes: 'Atributos (clave)',
            values: 'valores',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          accessKey: 'Llave de acceso',
          secretKey: 'Llave secreta',
          connect: 'Conectar',
          apiLogs: 'Registros de API',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          udpate: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          filterApplied: 'Filtro aplicado',
          retry: 'Rever',
          back: 'Atrás',
          filterYourLeads: 'Filtra tus cables',
          filterOn: 'Filtrar',
          filterBy: 'Filtrado por',
          discard: 'Desechar',
          applyFilter: 'Aplicar filtro',
          loadMore: 'Carga más',
          placeholders: {
            enterAccessKeyHere: 'Ingrese la clave de acceso aquí',
            enterSecretKeyHere: 'Ingrese la clave secreta aquí',
            attributes: 'Atributos (clave)',
            values: 'valores',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Filtrar datos basados ​​en la respuesta',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          apiUrl: 'URL API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiHere: 'Ingrese API aquí',
            exampleUrl: 'https://companyname.ladesk.com',
          }
        },
        microsoftTeams: {
          downloadPackage: 'Descargue el paquete de aplicaciones de Microsoft Teams',
          downloadApp: 'Descargar aplicación',
          wantToLearnHowToUploadIt: '¿Quieres aprender a subirlo?',
          needHelp: '¿Necesitas ayuda?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiUrl: 'URL API',
          appId: 'ID de aplicación',
          nameIsRequired: 'Se requiere la identificación de la aplicación',
          dataApiKey: 'Clave de API de datos',
          dataApiKeyIsRequired: 'Se requiere la clave API de datos',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          addParameters: ' Agregar parámetros',
          mapWith: 'mapear',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          selectTheQuestion: 'Seleccione la pregunta cuya respuesta del usuario desea enviar como valor',
          placeholders: {
            enterApiUrl: 'Ingrese la URL API',
            enterAppId: 'Ingrese la identificación de la aplicación',
            enterDataApiKey: 'Ingrese la clave API de datos',
            attributes: 'Atributos (clave)',
            pressForMappingQuestion: 'Presione / para la pregunta de mapeo',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          emailAddress: 'Dirección de correo electrónico',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          leadId: 'ID de plomo',
          selectLeadId: 'Seleccionar LeadID',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterEmailHere: 'Ingrese el correo electrónico aquí',
            enterApiKeyHere: 'Entra a apikey aquí',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          noCrmDomain: 'Dominio de Nocrm',
          leadTitle: 'Título principal',
          titleIsRequired: 'Se requiere título',
          token: 'Simbólico',
          tokenIsRequired: 'Se requiere token',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            exampleDomain: 'https://your_subdomain_here.nocrm.io',
            enterYourLeadTitle: 'Ingrese su título principal',
            enterYourToken: 'Ingrese su token',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          emailAddress: 'Dirección de correo electrónico',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          secretToken: 'Ficha secreta',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          appId: 'ID de aplicación',
          appIdIsRequired: 'Se requiere la identificación de la aplicación',
          apiKey: 'Clave API',
          apiKeyIsRequired: 'Se requiere la clave API',
          connect: 'Conectar',
          update: 'Actualizar',
          disconnect: 'Desconectar',
          placeholders: {
            appId: 'ID de aplicación',
            apiKey: 'Clave API',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          clientId: 'Identificación del cliente',
          connect: 'Conectar',
          clientSecret: 'Secreto del cliente',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          save: 'Ahorrar',
          update: 'Actualizar',
          disconnect: 'Desconectar',
          placeholders: {
            enterClientIdHere: 'Ingrese la identificación del cliente aquí',
            enterClientSecretHere: 'Ingrese el secreto del cliente aquí',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          pipedriverDomain: 'Dominio de pipeedriver',
          domainIsRequired: 'Se requiere dominio',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          apiKeyIsRequired: 'Se requiere la clave API',
          appKey: 'Llave de aplicación',
          appKeyIsRequired: 'Se requiere la clave de la aplicación',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiKey: 'Ingrese la clave API',
            enterAppKey: 'Ingrese la clave de la aplicación',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          authId: 'Auth ID',
          authToken: 'Token de autenticación',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiHere: 'Ingrese API aquí',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiKey: 'Ingrese la clave API',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          domain: 'Dominio',
          clientId: 'Identificación del cliente',
          clientSecret: 'Secreto del cliente',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterDomainHere: 'Ingrese el dominio aquí',
            enterClientIdHere: 'Ingrese la identificación del cliente aquí',
            enterClientSecretHere: 'Ingrese el secreto del cliente aquí',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          addEventsAndTemplates: 'Agregar eventos y plantillas:',
          add: ' Agregar',
          question: 'Pregunta',
          reply: 'Responder',
          requestBody: 'Cuerpo de solicitud',
          validateJson: 'Validar JSON',
          emailAddress: 'Dirección de correo electrónico',
          // addParameters: ' Agregar parámetros',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          selectTheQuestion: 'Seleccione la pregunta cuya respuesta del usuario desea enviar como valor',
          placeholders: {
            enterApiHere: 'Ingrese API aquí',
            enterTemplateId: 'Ingrese la ID de plantilla',
            typeYourJsonCodeHere: 'Escriba su código JSON aquí',
          },
          appTooltip: {
            typeYourJsonCode: 'Escriba su código JSON',
            clickToValidateJsonCode: 'Haga clic para validar el código JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          userName: 'Nombre de usuario',
          licenseKey: 'Clave de licencia',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterNameHere: 'Introduzca nombre aquí',
            enterKeyHere: 'Ingrese la tecla aquí',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado la integración con éxito. Ahora puedes configurarla.',
          company: 'Empresa',
          loginId: 'ID de inicio de sesión',
          password: 'Contraseña',
          testLead: 'Líder de prueba',
          connect: 'Conectar',
          selectTheQuestion: 'Selecciona la pregunta cuya respuesta de usuario deseas enviar como valor',
          mapWith: 'Mapear con',
          update: 'Actualizar',
          save: 'Guardar',
          disconnect: 'Desconectar',
          selectParameter: 'Seleccionar parámetro',
          placeholders: {
            enterCompanyHere: 'Ingresa el nombre de la empresa aquí',
            enterLoginIdHere: 'Ingresa la ID de inicio de sesión aquí',
            enterPasswordHere: 'Ingresa la contraseña aquí',
            values: 'Valores',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          applicationKey: 'Clave de aplicación',
          authToken: 'Token de autenticación',
          connect: 'Conectar',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApplicationKeyHere: 'Ingrese la clave de aplicación aquí',
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          accessToken: 'Token de acceso',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          question: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          webhoperDomain: 'Dominio de la web',
          domainIsRequired: 'Se requiere dominio',
          companyName: 'nombre de empresa',
          companyIsRequired: 'Se requiere la empresa',
          leadSource: 'Fuente principal',
          leadSourceIsRequired: 'Se requiere una fuente de plomo',
          division: 'División',
          divisionIsRequired: 'Se requiere división',
          enterPassword: 'Introducir la contraseña',
          passwordIsRequired: 'se requiere contraseña',
          disconnect: 'Desconectar',
          update: 'Actualizar',
          connect: 'Conectar',
          placeholders: {
            exampleDomain: 'http: // YourCompanyDomain',
            enterYourCompanyName: 'Ingrese el nombre de su empresa',
            enterLeadSource: 'Ingrese la fuente de plomo',
            enterDivision: 'Entrar en la división',
            enterPassword: 'Introducir la contraseña',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          name: 'Nombre:',
          zapier: 'Con más zapas',
          inboundWebhookUrl: 'URL de webhook entrante',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterWebhookUrl: 'Ingrese la URL de Webhook',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          emailAddress: 'Dirección de correo electrónico',
          apiUrl: 'URL API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterEmailAddress: 'Introducir la dirección de correo electrónico',
            enterUrlHere: 'Ingrese URL aquí',
            enterTokenHere: 'Ingrese token aquí',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiKey: 'Clave API',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiHere: 'Ingrese API aquí',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          connect: 'Conectar',
          update: 'Actualizar',
          disconnect: 'Desconectar',
          companyName: 'nombre de empresa',
          emailAddress: 'Dirección de correo electrónico',
          phone: 'Teléfono',
          countryCode: 'Código de país',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          // update: 'Actualizar',
          save: 'Ahorrar',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          connect: 'Conectar',
          disconnect: 'Desconectar',
          testLead: 'Cable de prueba',
          emailAddress: 'Dirección de correo electrónico',
          selectOrganizationName: 'Seleccionar nombre de organización',
          selectDepartmentName: 'Seleccionar el nombre del departamento',
          addParameters: ' Agregar parámetros',
          questionColon: 'Pregunta:',
          select: 'Seleccionar',
          mapsWith: 'mapas con',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          testLead: 'Cable de prueba',
          connect: 'Conectar',
          disconnect: 'Desconectar',
          projectsList: 'Lista de proyectos',
          selectProjectsName: 'Seleccionar el nombre de los proyectos',
          projectsTaskList: 'Fraslista de tareas de proyectos',
          selectTaskListName: 'Seleccionar el nombre de la lista de tareas',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
        },
        wooCommerce: {
          configure: 'Configurar',
          automatedMessage: 'Mensaje Automatizado',
          configureTab: {
            wooCommercePluginApiKey: 'Clave API del Plugin de WooCommerce',
            downloadWooCommercePlugin: 'Descargar Plugin de WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Mensaje de Recuperación de Carritos Abandonados',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Configurar mensajes automatizados de recuperación para recuperar a los clientes que abandonaron sus carritos',
            enableAbandonedCartRecoveryMessaegs: 'Habilitar mensajes de recuperación de carritos abandonados',
            selectTemplate: 'Seleccionar Plantilla',
            sendMessageAfter: 'Enviar mensaje después de',
            templateMessage: 'Mensaje de Plantilla',
            orderFulfillMent: 'Cumplimiento del Pedido',
            saveChanges: 'Guardar Cambios',
            selectFile: 'Seleccione Archivo',
            templateHeader: 'Encabezado de plantilla',
            orderCompleted: 'Orden Completada',
            enableOrderConfirmationMessages: 'Habilitar mensajes de confirmación de orden',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Enviar un mensaje de confirmación de orden tan pronto como un cliente realiza una orden.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Se envía un mensaje de envío de orden cuando se cumple una orden.',
            enableOrderFulfillmentMessages: 'Habilitar mensajes de cumplimiento de orden',
            returnExchangeRequest: 'Solicitud de Devolución/Intercambio',
            theMessageIsSentAfterARefundIsRequested: 'El mensaje se envía después de que se solicite un reembolso.',
            enableOrderRefundMessages: 'Habilitar mensajes de reembolso de orden',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'Se requiere el nombre',
          connect: 'Conectar',
          testLead: 'Cable de prueba',
          questionColon: 'Pregunta:',
          addParameters: ' Agregar parámetros',
          select: 'Seleccionar',
          mapWith: 'mapear',
          selectParameter: 'Seleccionar parámetro',
          update: 'Actualizar',
          save: 'Ahorrar',
          disconnect: 'Desconectar',
          placeholders: {
            enterApiToken: 'Ingrese el token API',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Crear una nueva integración',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'No tiene ninguna configuración de integración a partir de ahora.',
      pleaseCreateOneToIntegrateYourFavourite: 'Crea uno para integrar tu favorito',
      appsWith: 'aplicaciones con',
      integrationName: 'Nombre de integración',
      integrationNameIsRequired: 'Se requiere el nombre de la integración',
      continue: 'Continuar',
      addAnImageToRepresentYourIntegration: 'Agregue una imagen para representar su integración.',
      description: 'Descripción',
      submit: 'Entregar',
      youHaveSuccessfullyCreatedIntegration: 'Has creado con éxito la integración. Ahora, puede configurarlo.',
      apiUrl: 'URL API',
      pleaseEnterAValidApiUrl: 'Ingrese una URL de API válida',
      requestMethod: 'Método de solicitud',
      get: 'Conseguir',
      post: 'Correo',
      put: 'Poner',
      patch: 'Parche',
      queryParameter: 'Parámetro de consulta',
      addParameter: ' Agregar parámetro',
      mapsWith: ' mapas con',
      question: 'Pregunta',
      addQuestion: ' Agregar pregunta',
      select: 'Seleccionar',
      selectParameter: 'Seleccionar parámetro',
      header: 'Encabezamiento',
      addHeader: ' Agregar encabezado',
      value: 'Valor',
      connect: 'Conectar',
      requestBody: 'Cuerpo de solicitud',
      validate: 'Validar',
      // select: 'Seleccionar',
      key: 'Llave',
      // value: 'Valor',
      // connect: 'Conectar',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' También se puede integrar con este CRM gratuito.',
        apiUrl: 'URL API',
        pleaseEnterAValidApiUrl: 'Ingrese una URL de API válida',
        requestMethod: 'Método de solicitud:',
        get: 'Conseguir',
        post: 'Correo',
        put: 'Poner',
        patch: 'Parche',
        queryParameter: 'Parámetro de consulta',
        addParameter: ' Agregar parámetro',
        header: 'Encabezamiento',
        addHeader: ' Agregar encabezado',
        requestBody: 'Cuerpo de solicitud',
        validateJson: 'Validar JSON',
        connect: 'Conectar',
        update: 'Actualizar',
        delete: 'Borrar',
        selectTheQuestion: 'Seleccione la pregunta cuya respuesta del usuario desea enviar como valor',
        appTooltip: {
          typeYourJsonCode: 'Escriba su código JSON',
          clickToValidateJsonCode: 'Haga clic para validar el código JSON',
        }
      },
      list: {
        customAppsIntegrations: 'Integraciones de aplicaciones personalizadas',
        integrateYourAppsWith: 'Integre sus propias aplicaciones con',
        help: 'Ayuda',
        addIntegration: ' Agregar integración',
        name: 'Nombre',
        status: 'Estado',
        action: 'Acción',
        insatlled: 'Instalado',
        edit: 'Editar',
        connect: 'Conectar',
        requestYourIntegration: 'Solicite su integración',
        toRequestNewIntegration: 'Para solicitar una nueva integración, ingrese los detalles mencionados a continuación y luego nos comunicaremos con usted',
        // name: 'Nombre',
        emailId: 'Identificación de correo',
        integrationName: 'Nombre de integración',
        contactNumber: 'Número de contacto',
        howSoonYouWantToAddThisIntegration: 'Qué tan pronto desea agregar esta integración',
        sendRequest: 'Enviar petición',
        cancel: 'Cancelar',
        delete: 'Borrar',
        oopsOwnIntegrationsAreSupportedOnlyIn: '¡Ups! Sus propias integraciones solo son compatibles en',
        plan: 'Plan',
        pleaseUpgradeTo: 'Por favor, actualice a',
        toCreateOwnIntegrations: 'para crear sus propias integraciones',
        appTooltip: {
          integrations: 'Integración',
          noIntegrationsFound: '¡No se encontraron integraciones!',
          nameOfYourApp: 'Nombre de tu aplicación',
          clickToConnectApp: 'Haga clic para conectar la aplicación',
          statusAsConnectedOrNotConnected: 'Estado conectado / no conectado',
        }
      }
    }
  }
};
