import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NetworkService } from './network.service';
import { WebsiteBot } from 'src/appv2/models/bots/website-bot';
import { FacebookBot } from 'src/appv2/models/bots/facebook-bot';
import { TelegramBot } from 'src/appv2/models/telegramBot';
import { WhatsAppBot } from 'src/appv2/models/whatsapp-bot';
import { environment } from 'src/environments/environment';
import { TrainBotData } from '../models/bot';

@Injectable({
  providedIn: 'root'
})
export class BotsService {
  selectedBot: BehaviorSubject<undefined | WebsiteBot | TelegramBot | FacebookBot | WhatsAppBot> = new BehaviorSubject(undefined);
  botDomainVerification: BehaviorSubject<TrainBotData> = new BehaviorSubject({domain: '', fileUrl: '', fileName: ''});

  constructor(
    private router: Router,
    public networkService: NetworkService
  ) { }

  getBots(page = 1) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('bots')
        .then(resolve)
        .catch(reject);
    });
  }

  createBot(data) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('bots', data, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  updateBot(id, data) {
    return this.networkService.httpRequest('bots' + `/${id}`, data, 'PUT', true);
  }

  deleteBot(id) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('bots' + `/${id}`, {}, 'DELETE', true)
        .then(resolve)
        .catch(reject);
    });
  }

  getTelegramBots() {
    return this.networkService.httpRequest('telegram-automation');
  }

  getFacebookBots() {
    return this.networkService.httpRequest('facebook-automation/bots');
  }

  getWhatsappBots() {
    return this.networkService.httpRequest('whatsapp-automation');
  }

  getInstagramBots() {
    return this.networkService.httpRequest('instagram-automation/bots');
  }

  getInstagramBotFlows(botId: string) {
    return this.networkService.httpRequest('instagram-automation/flows/bot/' + botId);
  }

  getTelegramBotFlows(botId: string) {
    return this.networkService.httpRequest('telegram-automation/flows/bot/' + botId);
  }

  getWhatsappBotFlows(botId: string) {
    return this.networkService.httpRequest('whatsapp-automation/flows/bot/' + botId);
  }

  getFacebookBotFlows(botId: string) {
    return this.networkService.httpRequest('facebook-automation/flows/bot/' + botId);
  }

  getLiveChatBots() {
    return this.networkService.httpRequest(`bots/${environment.liveChatBotId}`);
  }

  getWebisteBotFlows(botId: string) {
    return this.networkService.httpRequest('bots/flow/' + botId);
  }

  verifyBotInstallation(botId: string, payload: { installVerificationStatus: 'STARTED' | 'FAILED' | 'OTHER_SCRIPT' | 'SUCCESS'}) {
    return this.networkService.httpRequest(`bots/verify/installation/${botId}`, payload, 'PUT');
  }

  sendEmailToDeveloper(payload) {
    return this.networkService.httpRequest('email-notification/install/developer', payload, 'POST');
  }

  async createElasticSearchIndex(_bot: string, payload: any) {
    return this.networkService.httpRequest(`bots/e-index/${_bot}`, payload, 'POST');
  }

  async getBotById(id: string) {
    return this.networkService.httpRequest(`bots/${id}`);
  }
}
