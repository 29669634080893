import { Injectable } from '@angular/core';
import { SharedService } from '../modules/shared/shared.service';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  picture = '';

  constructor(
    private networkService: NetworkService,
    private sharedService: SharedService,
  ) { }

  async uploadImage(event) {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append('file', event.target.files[0], event.target.files[0].name);

      if (!['.png', '.jpg', '.jpeg'].includes(`.${event.target.files[0].name.split('.').pop()}`)) {
        return this.sharedService.showToast('warning', '', 'Please provide valid image type');
      }
      if ((event.target.files[0].size / 1024) / 1000 > 20) {
        return this.sharedService.showToast('warning', '', 'File size should be less than 20MB');
      }

      this.sharedService.loader('start');
      this.networkService.httpFormRequest('upload', form, 'POST')
        .then((imageUrl: string) => {
          setTimeout(async () => {
            this.picture = imageUrl;
            this.sharedService.loader('stop');
            this.sharedService.showToast('success', '', 'Image Uploaded');
          }, 3000);
          return resolve(imageUrl);
        })
        .catch((err: any) => {
          this.sharedService.loader('stop');
          return this.sharedService.showToast('error', '', err.message || err);
        });
    });
  }

}
