import { Action, createReducer, on } from '@ngrx/store';
import { addRender, loadRender, updateRender } from '../actions/render.actions';
import Render from '../../models/render';

export const renderFeatureKey = 'render';

export const initialState: Render | {} = {};

const renderReducer = createReducer(
    initialState,
    on(loadRender, (state) => state),
    on(addRender, (state, { render }) => render),
    on(updateRender, (state,   update  ) => ({  ...state, ...update }))
);

export function reducer(state: Render, action: Action) {
    return renderReducer(state, action);
  }
