export const agency = {
  activities: {
    activities: 'Activités',
    upcomingRenewals: 'Renouveaux à venir',
    subscriptionActivities: `Activités d'abonnement`,
    upcomingDowngradeRequests: 'Demandes de déclassement à venir',
    serialNumber: 'Sr. Non',
    email: 'E-mail',
    phoneNumber: 'Pas de téléphone.',
    currentPlan: 'Plan actuel',
    price: 'Prix',
    daysLeft: 'Jours restants',
    noDataFound: {
      upcomingDowngrades: 'Prochaine de rétrogradation',
      noUpcomingDowngradesFoundYet: 'Aucune descente à venir trouvé encore!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Renouvellements à venir des clients',
      subscriptionActivities: `Activités d'abonnement`,
      upcomingDowngradeRequestsOfCustomers: 'Demandes de déclassement à venir des clients',
      serialNumber: 'Numéro de série',
      customerEmail: 'Email client',
      customerPhoneNumber: 'Numéro de téléphone client',
      currentPlanOfCustomer: 'Plan actuel du client',
      currentPlanPrice: 'Prix ​​du plan actuel',
      daysLeft: 'Jours restants du plan actuel des clients',
    },
  },
  configuration: {
    configurationsHeader: 'Configurations',
    emailHeader: 'E-mail',
    razorPayHeader: 'Razorpay',
    invoiceDetailsHeader: 'Détails de la facture',
    stripeHeader: 'Bande',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Intégrez les détails de votre fournisseur de messagerie pour envoyer des e-mails clients de manière transparente.',
      enterYourRazorPayAccountDetails: 'Entrez les détails de votre compte Razorpay pour le traitement de paiement client transparent.',
      enterYourStripeAccountDetails: 'Entrez les détails de votre compte Stripe pour le traitement de paiement client transparent.',
      enterYourCompanyDetails: 'Entrez les détails de votre entreprise pour une facturation précise et une génération de factures professionnelles.',
    },
    email: {
      testEmail: 'Tester le courrier électronique',
      addEmailIntegrationsDetails: `Ajouter les détails de l'intégration des e-mails`,
      integrationDescription: 'Pour intégrer les détails de vos fournisseurs de messagerie, veuillez fournir vos informations de fournisseur de messagerie. Tous les e-mails de communication seront envoyés à vos clients en utilisant cette adresse e-mail, garantissant une livraison cohérente et fiable de notifications et de mises à jour importantes.',
      awsRegion: 'Région AWS',
      awsAccessKeyId: 'ID de clé AWS Access',
      awsSecretAccessKey: `Clé d'accès secret AWS`,
      reset: 'Réinitialiser',
      addDetails: 'Ajouter des détails',
      updateDetails: 'Mettre à jour les détails',
      appTooltip: {
        TOOLTIP_AWS_REGION: `Entrez votre région AWS. La région détermine l'emplacement physique de vos ressources et affecte des facteurs tels que la latence des données et la conformité réglementaire.`,
        TOOLTIP_AWS_ACCESS_KEY_ID: `Entrez votre ID de clé d'accès AWS. Cette clé identifie de manière unique votre compte AWS et est requis pour l'authentification sécurisée et l'accès aux services AWS.`,
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: `Entrez votre clé d'accès secret AWS. Cette clé est utilisée pour l'authentification sécurisée et l'accès aux services AWS.`,
      },
      placeholders: {
        enterAwsRegion: 'Entrez la région AWS',
        enterAwsAccessKeyId: `Entrez l'ID de clé d'accès AWS`,
        enterAwsSecretAccessKey: `Entrez la clé d'accès secret AWS`,
      },
      modal: {
        deleteEmailIntegration: `Supprimer l'intégration des e-mails`,
        wantToDeleteEmailIntegration: 'Êtes-vous sûr de vouloir supprimer cette intégration par e-mail?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: `Ajouter les détails de l'intégration Facebook`,
      integrateYourEmailProviderDetails: 'Intégrez les détails de votre fournisseur de messagerie. Tous les e-mails seront envoyés en utilisant cet e-mail à vos clients',
      facebookAppId: `ID d'application Facebook`,
      facebookSecretAccessKey: `Clé d'accès secret Facebook`,
      reset: 'Réinitialiser',
      cancel: 'Annuler',
      addDetails: 'Ajouter des détails',
      updateDetails: 'Mettre à jour les détails',
      delete: 'Supprimer',
      testEmail: 'Tester le courrier électronique',
      modal: {
        deleteFacebookIntegration: `Supprimer l'intégration Facebook`,
        wantToDeleteFacebookIntegration: 'Êtes-vous sûr de vouloir supprimer cette intégration par e-mail?',
      },
      placeholders: {
        enterFacebookAccessKeyId: `Entrez la clé d'accès secret Facebook`,
        enterFacebookAppId: `Entrez l'ID d'application Facebook`,
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: `Personnalisez le message comme vous souhaitez qu'il apparaisse sur la fenêtre de chat immédiatement après qu'un utilisateur de BOT envoie une demande de chat en direct`,
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Ajouter les détails de la facture',
      invoicesDescription: `Pour générer des factures pour vos clients, veuillez fournir les coordonnées de votre entreprise,
   y compris le nom de votre entreprise, l'adresse, les informations de contact et toutes les autres informations pertinentes requises pour une facturation précise. Ces informations seront utilisées pour créer des factures professionnelles au nom de votre entreprise pour chaque transaction.`,
      companyName: `Nom de l'entreprise`,
      country: 'Pays',
      selectCountry: 'Choisissez le pays',
      stateOrRegion: 'État / région',
      streetAddress: 'Adresse de la rue',
      gstin: 'Gstin',
      postalCode: 'Code Postal',
      addDetails: 'Ajouter des détails',
      updateDetails: 'Mettre à jour les détails',
      reset: 'Réinitialiser',
      delete: 'Supprimer',
      loadMore: 'Charger plus',
      time: 'Temps',
      phoneNumber: 'Numéro de téléphone',
      email: 'E-mail',
      status: 'Statut',
      message: 'Message',
      response: 'Réponse',
      placeOfSupply: 'Lieu de l\'achat',
      placeholders: {
        enterYourCompanyName: 'Entrez le nom de votre entreprise',
        enterYourStateOrRegion: 'Entrez votre état / région',
        enterYourStreetAddress: 'Entrez votre adresse de rue',
        enterYourGstin: 'Entrez votre Gstin',
        enterPostalCode: 'Entrez le code postal',
        enterPlaceOfSupply: 'Entrez le lieu de l\'achat',
      },
      modal: {
        deleteEmailIntegration: `Supprimer l'intégration des e-mails`,
        wantToDeleteEmailIntegration: 'Êtes-vous sûr de vouloir supprimer cette intégration par e-mail?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME: `Entrez le nom de votre entreprise. Ces informations seront utilisées à des fins d'identification et de marque dans les factures.`,
        TOOLTIP_COUNTRY: `Sélectionnez votre pays. Ces informations seront utilisées à des fins d'identification et de marque dans les factures.`,
        TOOLTIP_STATE: `Entrez votre état. Ces informations seront utilisées à des fins d'identification et de marque dans les factures.`,
        TOOLTIP_STREET_ADDRESS: `Entrez votre adresse de rue. Ces informations seront utilisées à des fins d'identification et de marque dans les factures.`,
        TOOLTIP_GSTIN: `Entrez votre Gstin. Ces informations seront utilisées à des fins d'identification et de marque dans les factures.`,
        TOOLTIP_POSTAL_CODE: `Entrez votre code postal. Ces informations seront utilisées à des fins d'identification et de marque dans les factures.`,
        TOOLTIP_PLACE_OF_SUPPLY: 'Entrez le lieu de l\'achat. Ces informations seront utilisées à des fins d\'identification et de marque dans les factures.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: `Ajouter les détails de l'intégration Razorpay`,
      integrationDescription: 'Pour intégrer les détails de votre compte Razorpay, veuillez fournir les informations de votre compte Razorpay. Tous les paiements du client seront traités via votre compte Razorpay pour les transactions transparentes',
      razorPayAccessKeyId: `ID de clé d'accès Razorpay`,
      razorPaySecretAccessKey: `Clé d'accès secret Razorpay`,
      reset: 'Réinitialiser',
      addDetails: 'Ajouter des détails',
      updateDetails: 'Mettre à jour les détails',
      loadMore: 'Charger plus',
      time: 'Temps',
      phoneNumber: 'Numéro de téléphone',
      email: 'E-mail',
      status: 'Statut',
      message: 'Message',
      response: 'Réponse',
      placeholders: {
        enterRazorPayAccessKeyId: `Entrez l'ID de clé d'accès Razorpay`,
        enterRazorPaySecretAccessKey: `Entrez la clé d'accès secret Razorpay`,
        enterRazorPayAccessId: `Entrez l'ID d'accès Razorpay`,
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: `Entrez votre ID de clé d'accès Razorpay. Cette clé identifie de manière unique votre intégration avec Razorpay et est requise pour les transactions de paiement sécurisées.`,
        TOOLTIP_RAZORPAY_SECRET_KEY: `Entrez votre clé d'accès secret Razorpay. Cette clé est essentielle pour la communication sécurisée entre l'application et Razorpay,
   permettant un traitement de transaction et une gestion de paiement transparents et des paiements.`,
      },
      modal: {
        deleteRazorPayIntegration: `Supprimer l'intégration des e-mails`,
        wantToDeleteThisIntegrtion: 'Êtes-vous sûr de vouloir supprimer cette intégration par e-mail?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: `Ajouter les détails de l'intégration de la bande`,
      integrationDescription: 'Pour intégrer les détails de votre compte Stripe, veuillez fournir les informations de votre compte Stripe. Tous les paiements du client seront traités via votre compte Stripe pour les transactions transparentes',
      stripeSecretAccessKey: `Clé d'accès secret Stripe`,
      stripePublicKey: 'Clé publique',
      reset: 'Réinitialiser',
      addDetails: 'Ajouter des détails',
      updateDetails: 'Mettre à jour les détails',
      cancel: 'Annuler',
      loadMore: 'Charger plus',
      time: 'Temps',
      phoneNumber: 'Numéro de téléphone',
      email: 'E-mail',
      status: 'Statut',
      message: 'Message',
      response: 'Réponse',
      placeholders: {
        enterStripeSecretAccessKey: `Entrez la clé d'accès secret Stripe`,
        enterStripePublicKey: 'Entrez la clé publique Stripe',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: `Entrez votre clé d'accès secret Stripe. Cette clé est utilisée pour authentifier en toute sécurité votre intégration avec Stripe et est nécessaire pour traiter les paiements des clients.`,
        TOOLTIP_PUBLIC_KEY: 'Entrez votre clé publique Stripe. Cette clé est utilisée pour authentifier en toute sécurité votre intégration avec la bande du côté client et est nécessaire pour traiter les paiements des clients.',
      },
      modal: {
        deleteStripeIntegration: `Supprimer l'intégration de la bande`,
        wantToDeleteStripeIntegration: 'Êtes-vous sûr de vouloir supprimer cette intégration Stripe?',
      }
    }
  },
  customer: {
    manageCustomers: 'Gérer les clients',
    addNewCustomers: 'Ajouter de nouveaux clients',
    ensureYouHaveAddedAllYourCustomersHere: 'Assurez-vous que vous avez ajouté tous vos clients ici',
    customerName: 'Nom du client',
    nameIsRequired: 'Le nom est requis',
    emailId: 'ID de messagerie',
    enterAValidEmail: 'Entrer un email valide',
    createPassword: 'Créer un mot de passe',
    enterAStrongPassword: 'Entrez un mot de passe fort',
    confirmPassword: 'Confirmez le mot de passe',
    cancel: 'Annuler',
    customer: 'Client',
    searchByNameOrEmail: 'Recherche par nom ou e-mail',
    filters: 'Filtres',
    allCustomers: 'Tous les clients',
    paidCustomers: 'Clients payés',
    freeCustomers: 'Clients gratuits',
    messageAddOnCustomers: 'Ajouter un message sur les clients',
    botWhiteLabelCustomers: 'Bot WhiteLabel Clients',
    sortBy: 'Trier par',
    sortByFrequency: 'Trier par fréquence',
    all: 'Tous',
    joinedDate: 'Joint Date',
    plan: 'Plan',
    lastLogin: 'Dernière connexion',
    email: 'E-mail',
    nextDue: `Suivant d'échéance`,
    frequency: 'Fréquence',
    clearAll: 'Tout effacer',
    apply: 'Appliquer',
    messageConsumed: 'Message consommé',
    addCustomer: 'Ajouter le client',
    serialNumber: 'Sr. Non',
    name: 'Nom',
    contact: 'Contact',
    limit: 'Limite',
    location: 'Emplacement',
    actions: 'Actions',
    joinedOn: 'Joint',
    typeOfCustomer: 'type de client',
    exceptWhatsapp: ' (Sauf WhatsApp)',
    exceptWA: '(Sauf wa)',
    wa: '(WASHINGTON)',
    loadMore: 'Charger plus',
    conversationsWhatsapp: ' Conversations (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'Entrez le courrier électronique client',
      enterCustomerPassword: 'Entrez le mot de passe du client',
      enterCustomerConfirmPassword: 'Entrez le mot de passe de confirmation du client',
      enterCustomerName: 'Entrez le nom du client',
    },
    placeholders: {
      enterCustomerEmail: 'Entrez le courrier électronique client',
      searchByNameOrEmail: 'Recherche par nom ou e-mail',
      enterPassword: 'Entrer le mot de passe',
      enterCustomerName: 'Entrez le nom du client',
    }
  },
  dashboard: {
    dashboard: 'Tableau de bord',
    overview: 'Aperçu',
    totalCustomers: 'Total des clients',
    paidCustomers: 'Clients payés',
    freeCustomers: 'Clients gratuits',
    thisWeeksSignUp: `L'inscription de cette semaine`,
    todaysSignUp: `Inscriptions d'aujourd'hui`,
    totalEarnings: 'Total des gains',
    nA: 'N / A',
    customerName: 'Nom du client',
    nameIsRequired: 'Le nom est requis',
    emailId: 'ID de messagerie',
    enterAValidEmail: 'Entrer un email valide',
    editCustomer: 'Modifier le client',
    recentSignUps: 'Inscriptions récentes',
    serialNumber: 'Sr. Non',
    name: 'Nom',
    email: 'E-mail',
    contact: 'Contact',
    plan: 'Plan',
    limit: 'Limite',
    location: 'Emplacement',
    lastLogin: 'Dernière connexion',
    joinedOn: 'Joint',
    actions: 'Actions',
    exceptWhatsapp: ' (Sauf WhatsApp)',
    messageConsumed: 'Message consommé',
    conversationsWhatsapp: ' Conversations (WhatsApp)',
    placeholders: {
      enterCustomerName: 'Entrez le nom du client',
      enterCustomerEmail: 'Entrez le courrier électronique client',
    },
    modal: {
      deleteCustomer: 'Supprimer le client',
      areYouSure: 'Êtes-vous sûr de vouloir supprimer ce client?',
    }
  },
  downgradeRequests: {
    activites: 'Activités',
    upcomingRenewals: 'Renouveaux à venir',
    subscriptionActivities: `Activités d'abonnement`,
    upcomingDonwgradeRequests: 'Demandes de déclassement à venir',
    serialNumber: 'Sr. Non',
    email: 'E-mail',
    phoneNumber: 'Pas de téléphone.',
    currentPlan: 'Plan actuel',
    requestedOn: 'Demandé sur',
    downgradeOn: 'Rétrograder',
    nA: 'N / A',
    commaMonthly: ', Mensuel',
    commaYearly: ' , Annuel',
    noDataFound: {
      downgradeRequests: 'Demandes de rétrogradation',
      noDowngradeRequestFound: 'Aucune demande de rétrogradation trouvée encore!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Renouvellements à venir des clients',
      subscriptionActivities: `Activités d'abonnement`,
      upcomingDowngradeOfCustomers: 'Demandes de déclassement à venir des clients',
      serialNumber: 'Numéro de série',
      customerEmail: 'Email client',
      customerPhoneNumber: 'Numéro de téléphone client',
      currentPlanOfCustomer: 'Plan actuel du client',
      currentPlanPrice: 'Prix ​​du plan actuel',
    }
  },
  emailIntegration: {
    emailIntegration: 'Intégration par e-mail',
    addEmailIntegrationDetails: `Ajouter les détails de l'intégration des e-mails`,
    integrateYourEmailProviderDetails: 'Intégrez les détails de votre fournisseur de messagerie. Tous les e-mails seront envoyés en utilisant cet e-mail à vos clients',
    awsRegion: 'Région AWS',
    awsRegionIsRequired: 'La région AWS est requise',
    awsAccessKeyId: 'ID de clé AWS Access',
    awsAccessKeyIdIsRequired: `L'ID de clé d'accès AWS est requis`,
    awsSecretAccessKey: `Clé d'accès secret AWS`,
    awsSecretAccessKeyIsRequired: `La clé d'accès secret AWS est requise`,
    cancel: 'Annuler',
    test: 'Test',
    action: 'Action',
    testEmail: 'Tester le courrier électronique',
    addDetails: 'Ajouter des détails',
    updateDetails: 'Mettre à jour les détails',
    saveDetails: 'Enregistrer les détails',
    placeholders: {
      enterAwsRegion: 'Entrez la région AWS',
      enterAwsAccessKeyId: `Entrez l'ID de clé d'accès AWS`,
      enterAwsSecretAccessKey: `Entrez la clé d'accès secret AWS`,
    },
    modal: {
      deleteEmailIntegration: `Supprimer l'intégration des e-mails`,
      areYouSure: 'Êtes-vous sûr de vouloir supprimer cette intégration par e-mail?',
    },
    noDataFound: {
      emailIntegration: 'Intégration par e-mail',
      noEmailIntegrationFoundYet: 'Aucune intégration par e-mail ne trouvée!',
    }
  },
  subscription: {
    activities: 'Activités',
    upcomingRenewals: 'Renouveaux à venir',
    subscriptionActivities: `Activités d'abonnement`,
    upcomingDonwgradeRequests: 'Demandes de déclassement à venir',
    filters: 'Filtres',
    manageActivities: 'Gérer les activités',
    sortBy: 'Trier par',
    all: 'Tous',
    upgrades: 'Mises à niveau',
    donwgrades: 'Rétrogradation',
    renewals: 'Renouvellement',
    serialNumber: 'Sr. Non',
    email: 'E-mail',
    phoneNumber: 'Pas de téléphone.',
    previousPlan: 'Plan précédent',
    newPlan: 'Nouveau plan',
    description: 'Description',
    date: 'Date',
    nA: 'N / A',
    upgraded: 'amélioré',
    commaYearly: ' , Annuel',
    commaMonthly: ' , Mensuel',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: `Aucune activité d'abonnement trouvé encore!`,
      subscriptionsActivities: `Activités d'abonnements`,
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Renouvellements à venir des clients',
      subscriptionActivities: `Activités d'abonnement`,
      upcomingDowngradeRequests: 'Demandes de déclassement à venir des clients',
      serialNumber: 'Numéro de série',
      customerEmail: 'Email client',
      customerPhoneNumber: 'Numéro de téléphone client',
      previousPlan: 'Plan précédent du client',
      newPlan: 'Nouveau plan du client',
      description: 'Description du plan',
      typeOfPayment: 'Type de paiement',
    }
  },
  feedbacks: {
    feedbacks: 'Rétroactions',
    serialNumber: 'Sr. Non',
    customer: 'Client',
    type: 'Taper',
    rating: 'Notation',
    reason: 'Raison',
    description: 'Description',
    createdAt: 'Créé à',
    nA: 'N / A',
    loadMore: 'Charger plus',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Rétroactions de suppression des comptes et des bot',
      noFeedbacksFoundYet: 'Aucun retour trouvé encore!',
    },
    appTooltip: {
      serialNumber: 'Numéro de série',
      customerEmail: 'Email client',
      feedbackForBotOrAccountDeletion: 'Frais de redexe pour (suppression de bot / compte)',
      customerRating: 'Évaluation du client',
      customerReason: 'Raison du client',
      feedbackCreatedAt: 'Commentaires créés à',
      customerFeedback: 'Commentaires des clients',
    }
  }
};
