import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  /**
   * Using array to handle situation where multiple methods starts their loader in a component
   * but the first ones output stop the loader (while others were still in process).
   */
  isLoaderVisible = [];
  showLoader$: any;
  constructor(private sharedSrvc: SharedService) { }

  ngOnInit(): void {
    this.showLoader();
  }
  showLoader() {
    this.showLoader$ = this.sharedSrvc.showLoader.subscribe((show: boolean) => {
      if (show) {
        this.isLoaderVisible.push('show');
      } else {
        this.isLoaderVisible.pop();
      }
    });
  }
  ngOnDestroy(): void {
    this.showLoader$.unsubscribe();
  }
}
