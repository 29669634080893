import { Injectable } from '@angular/core';
import * as staticEngText from '../../assets/static-text/en';
import * as staticHindiText from '../../assets/static-text/hi';
import * as staticGermanText from '../../assets/static-text/de';
import * as staticFrenchText from '../../assets/static-text/fr';
import * as staticPortugeseText from '../../assets/static-text/pt';
import * as staticSpanishText from '../../assets/static-text/es';
import * as staticItalianText from '../../assets/static-text/it';
import * as staticRussianText from '../../assets/static-text/ru';
import * as staticRomanianText from '../../assets/static-text/ro';
import * as staticDutchText from '../../assets/static-text/nl';
import * as staticArabicText from '../../assets/static-text/ar';
import * as staticTurkishText from '../../assets/static-text/tr';
import * as staticHebrewText from '../../assets/static-text/he';
import * as staticjapaneseText from '../../assets/static-text/jp';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: string;

  constructor() {
    const language = localStorage.getItem('language')
      ? JSON.parse(localStorage.getItem('language'))
      : { title: 'English', value: 'en' };
    this.language = language.value;
  }

  getStaticText() {
    switch (this.language) {
      case 'hi':
        return staticHindiText;
      case 'de':
        return staticGermanText;
      case 'fr':
        return staticFrenchText;
      case 'pt':
        return staticPortugeseText;
      case 'es':
        return staticSpanishText;
      case 'it':
        return staticItalianText;
      case 'ru':
        return staticRussianText;
      case 'ro':
        return staticRomanianText;
      case 'nl':
        return staticDutchText;
      case 'ar':
        return staticArabicText;
      case 'tr':
        return staticTurkishText;
      case 'he':
        return staticHebrewText;
      case 'jp':
        return staticjapaneseText;
      default:
        return staticEngText;
    }
    // if (this.language === 'en') {
    //   return staticEngText;
    // } else if (this.language === 'hi') {
    //   return staticHindiText;
    // } else if (this.language === 'de') {
    //   return staticGermanText;
    // } else if (this.language === 'fr') {
    //   return staticFrenchText;
    // } else if (this.language === 'pt') {
    //   return staticPortugeseText;
    // } else if (this.language === 'es') {
    //   return staticSpanishText;
    // } else if (this.language === 'it') {
    //   return staticItalianText;
    // } else if (this.language === 'ru') {
    //   return staticRussianText;
    // } else {
    //   return;
    // }
  }
}
