export const analytics = {
  botWise: {
    botWiseAnalytics: 'Analytique bot-sage',
    currentBillingCycle: 'Cycle de facturation actuel',
    currentMonth: 'Mois en cours',
    sinceAccountCreation: 'Depuis la création de compte',
    messages: 'messages',
    leads: 'Fils',
    noDataFound: {
      botwiseAnalytics: 'Analytique bot-sage',
      noBotHasBeenCreatedYet: `Aucun bot n'a encore été créé!`,
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Résumez les données des messages et contacts / prospects pour des robots spécifiques pour le cycle de facturation actuel, le mois en cours ou depuis la création de compte (mettez à jour chaque minuit)',
      TOOLTIP_FILTERS: 'Filtre les données pour le bot sélectionné',
      TOOLTIP_MESSAGES: 'Messages totaux consommés par votre compte depuis la création de compte',
      TOOLTIP_LEADS: 'Total des prospects capturés par des bots pour votre compte depuis la création de compte.',
    }
  },
  overview: {
    analytics: 'Analytique',
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    overview: 'Aperçu',
    messages: 'messages',
    leads: 'Fils',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Analyse pour tous vos messages et pistes de bots. (Données mises à jour chaque minuit)',
    }
  },
  summary: {
    analyticsSummary: `Résumé de l'analyse`,
    allBots: 'Tous les bots',
    daily: 'Quotidien',
    weekly: 'Hebdomadaire',
    monthly: 'Mensuel',
    messages: 'messages',
    leads: 'Fils',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Résumez les données quotidiennes, hebdomadaires ou mensuelles à partir de messages et de contacts / prospects pour spécifiques et tous les robots (mettez-vous à jour chaque minuit)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp Analytics',
      month: 'Mois',
      week: 'Semaine',
      today: `Aujourd'hui`,
      filters: 'Filtres',
      clearAll: 'Tout effacer',
      apply: 'Appliquer',
      totalConversations: 'Conversations totales',
      freeConversations: 'Conversations gratuites',
      paidConversations: 'Conversations payantes',
      messageSent: 'Message envoyé',
      messageDelivered: 'Message délivré',
      bpDropDown: {
        selectBot: 'Sélectionner le bot',
      },
      indexMergeDatePicker: {
        startDate: 'Date de début',
        endDate: 'Date de fin',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Analyse des conversations',
      month: 'Mois',
      week: 'Semaine',
      today: `Aujourd'hui`,
      totalConversations: 'Conversations totales',
      paidConversations: 'Conversations payantes',
      freeConversations: 'Conversations gratuites',
      indexMergeDatePicker: {
        startDate: 'Date de début',
        endDate: 'Date de fin',
      },
      bpDropDown: {
        allBots: 'Tous les bots',
      }
    },
    messageAnalytics: {
      whatsappMessages: 'Messages WhatsApp',
      sent: 'Envoyé',
      delivered: 'Livré',
      indexMergeDatePicker: {
        startDate: 'Date de début',
        endDate: 'Date de fin',
      },
      bpDropDown: {
        allBots: 'Tous les bots',
      },
      month: 'Mois',
      week: 'Semaine',
      today: `Aujourd'hui`,
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'Messages WhatsApp par statut',
      sent: 'Envoyé',
      delivered: 'Livré',
      indexMergeDatePicker: {
        startDate: 'Date de début',
        endDate: 'Date de fin',
      },
      bpDropDown: {
        allBots: 'Tous les bots',
      },
      month: 'Mois',
      week: 'Semaine',
      today: `Aujourd'hui`,
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Tous les abonnés',
      whatsappSubscribers: 'Abonnés WhatsApp',
    }
  }
};
