import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  constructor(
    public networkService: NetworkService
  ) { }

  async addEmailIntegration(payload: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('integrations/email-integration', payload, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async getEmailIntegration() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('integrations/email-integration', null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async updateEmailIntegration(payload: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('integrations/email-integration', payload, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async deleteEmailIntegration(id: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('integrations/email-integration', { _id: id }, 'DELETE', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async sendTestEmail(payload: any) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('email-notification/test-email', payload, 'POST', true)
        .then(resolve)
        .catch(reject);
    });
  }

  // For Whitelabel agency configuration

  async getWhitelabelAgencyConfiguration(integration: string) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`integrations/agency-configuration/${integration}`, null, 'GET', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async updateWhitelabelAgencyConfiguration(payload: any, integration: string) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`integrations/agency-configuration/${integration}`, payload, 'PUT', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async deleteWhitelabelAgencyConfiguration(integration: string) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`integrations/agency-configuration/${integration}`, '', 'DELETE', true)
        .then(resolve)
        .catch(reject);
    });
  }

  async getApiLogs(integration: string, page: number) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(`payment/subscription-whitelabel-activity?ispagination=true&page=${page}&type=${integration}`)
        .then(resolve)
        .catch(reject);
    });
  }

  async getPendingConfigurations() {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest('integrations/agency-configuration/pending-configuration')
        .then(resolve)
        .catch(reject);
    });
  }
}
