export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Ricerca',
    }
  },
  bpTable: {
    loadMore: 'Carica di più',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Cerca paese',
    }
  },
  createNew: {
    continue: 'Continua',
  },
  datePicker: {
    chooseADate: 'Scegli una data',
  },
  dateRangePicker: {
    choosePeriod: 'Scegli il periodo',
    today: 'Oggi',
    lastWeek: 'La settimana scorsa',
    lastMonth: 'Lo scorso mese',
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Fornisci il numero che desideri utilizzare per il test',
    resendOtp: 'REPEND OTP',
    enterOtp: 'Immettere OTP',
    verifyOtp: 'Verificare OTP',
    sendOtp: 'Invia OTP',
    cancel: 'Annulla',
    placeholders: {
      phone: 'Telefono*',
      enterOtp: 'Immettere OTP*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Seleziona il motivo',
    cancel: 'Annulla',
    submit: 'Invia!',
    giveFeedback: 'Dare un feedback',
    ratings: 'Giudizi',
    reasonBehindYourDecision: 'Motivo dietro la tua decisione',
    shareYourExperience: 'Condividi la tua esperienza',
    reason1: 'Motivo1',
    reason2: 'motivo2',
  },
  feedbackModal: {
    paymentIsInProgress: 'Il pagamento è in corso',
    pleaseDoNotCloseThisWindow: 'Si prega di non chiudere questa finestra',
    placeholders: {
      name: 'Nome',
      email: 'E-mail*',
      phone: 'Telefono*',
      pleaseShareYourUseCase: 'Si prega di condividere la tua usecase',
    }
  },
  layout: {
    sidebar: {
      notifications: 'Notifiche',
      myAccount: 'Il mio conto',
      logout: 'Disconnettersi',
      goOnline: 'Vai online',
      manage: 'Maneggio',
      deactivate: 'Disattivare',
      activate: 'Attivare',
      delete: 'Eliminare',
      home: 'Casa',
      bots: 'Robot',
      inbox: 'Inbox',
      developer: 'Sviluppatore',
      analytics: 'Analisi',
      team: 'Squadra',
      subscriptions: 'Abbonamenti',
      more: 'Di più',
      users: 'Utenti',
      partnerTeam: 'Squadra Partner',
      createNewBot: 'Crea nuovo bot',
      dashboard: 'Pannello di controllo',
      customers: 'Clienti',
      plans: 'Piani',
      activities: 'Attività',
      feedbacks: 'Feedback',
      configurations: 'Configurazioni',
      copy: 'copia',
      yourFriendWillMissYou: 'Il tuo amico mancherai',
      rateYourBotCreationExperience: 'Valuta la tua esperienza di creazione di bot',
      whatMadeYouDeleteYourBot: 'Cosa ti ha fatto eliminare il tuo bot?',
      iCreatedByMistake: 'Ho creato per errore',
      iWantToCreateAnotherBot: 'Voglio creare un altro bot',
      couldNotFindFeaturesThatINeeded: ',',
      uiWasHardToNavigate: 'L`interfaccia utente era difficile da navigare',
      iDonotWantToTell: ',',
      other: 'Altro',
      weWillDoOurBestToMakeAutomationFun: 'Faremo del nostro meglio per rendere l`automazione divertente e semplice per tutti.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Attiva alterni per consentire o bloccare le richieste di chat live',
        TOOLTIP_NOTIFICATION: 'Tutte le notifiche',
        TOOLTIP_MY_ACCOUNT: 'Il tuo profilo e la sicurezza della password',
        TOOLTIP_LOGOUT: 'Logout dalla dashboard',
      },
      modal: {
        logout: 'Disconnettersi',
        botDeleteFeedbackForm: 'Bot elimina il modulo di feedback',
        areYouSureToLogOut: 'Sei sicuro di voler uscire?',
        yesIWillBeBack: 'SÌ! tornerò',
        deleteBot: 'Elimina bot',
        areYouSureToDeleteBot: 'Sei sicuro di voler eliminare questo bot?',
        cancel: 'Annulla',
        saveAndDelete: 'Salva ed elimina',
      }
    }
  },
  modal: {
    cancel: 'Annulla',
    yesDelete: 'Sì, elimina!',
    continue: 'Continua'
  },
  unsavedChanges: {
    title: 'Modifiche non salvate',
    description: 'Potrebbero esserci modifiche non salvate. Sei sicuro di voler uscire?'
  },
  notify: {
    youHave: 'Hai',
    new: ' nuovo',
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Suggeriamo di scegliere il logo della tua azienda. Aggiungi un`immagine al quadrata PNG o JPG sotto 1 MB.',
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Oops! Il piano per bambini offre un solo bot gratuito. Aggiorna il piano King per creare più robot.',
    noIAmGood: 'No, sono bravo',
    iWantToUpgrade: 'Voglio aggiornare',
    oops: 'Ops! ',
    planOfferOnlyOneFreeBot: ' OFFRE SOLO UN BOT GRATUITO. Effettua l\'upgrade a ',
    planToCreateMoreBots: ' per creare più bots.'
  },
  webPreview: {
    preview: ' Anteprima',
    botsMessages: 'Messaggi di robot',
    bot: 'Bot',
    eleven36AM: '11:36 AM',
    usersReply: 'Risposta dell`utente',
    landingPageBot: 'Bot di landing page',
    chatbot: 'Chatbot',
    chat: 'Chiacchierata',
    placeholders: {
      typeYourAnswer: 'Scrivi la tua risposta',
    }
  },
  paymentLink: {
    discardPayment: 'Annulla il pagamento',
    completePayment: 'Completa il pagamento',
    importantUpdate: 'Aggiornamento importante',
    accountUpdate: 'Aggiornamento account',
    youHave: 'Hai',
    exceededTheMessagesLimit: 'Hai superato il limite di messaggi',
    ofYour: 'del tuo',
    planAnd: 'piano e',
    botsAreNoLongerActive: 'i bot non sono più attivi',
    onYourWebsite: 'sul tuo sito web.',
    youHaveEitherIncompletePaymentOn: 'hai un pagamento incompleto attivo',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'o non hai aggiornato correttamente il tuo piano.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'o non hai aggiornato correttamente il tuo bot.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'o non hai aggiornato correttamente il tuo piano.',
    please: 'Per',
    complete: 'Completa',
    upgrade: 'Aggiorna',
    services: 'Servizi',
    yourPlanToContinueServices: 'il tuo piano per continuare i servizi.',
    yourPaymentToContinue: 'il pagamento per continuare',
    forAnyQueryPleaseWriteUs: 'Per qualsiasi domanda scrivici su',
    close: 'Chiudi',
    writeOn: '',
    upgradePlan: 'Aggiorna il piano',
    discardModal: {
      title: 'Annulla il pagamento',
      description: 'Sei sicuro di voler annullare il pagamento?',
      confirmText: 'Sì, scarta il pagamento'
    }
  },
  bpExport: {
    exportStatus: 'Stato di esportazione',
    tasksExport: 'Compiti: esportazione',
    download: 'Scaricamento',
    inProgress: 'in corso',
  }
};
