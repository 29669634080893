import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LanguageService } from 'src/appv2/services/language.service';
import { v4 as uuid } from 'uuid';

declare let $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges, OnInit {

  uuid: string;
  @Input() title = '';
  @Input() description = '';
  @Input() showModal = false;
  @Input() showCloseButton = true;
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Yes, Delete!';
  @Input() face: 'happy' | 'sad' = 'sad';
  @Output() userInput: EventEmitter<any> = new EventEmitter();
  staticText: any;

  constructor(private languageService: LanguageService) {
    this.staticText = languageService.getStaticText().shared;
    // console.log(this.staticText.modal);
    this.reInitializeStaticText();
  }

  ngOnInit(): void {
  }

  reInitializeStaticText() {
    if (this.cancelText === 'Cancel' ) {
      this.cancelText = this.staticText.modal.cancel;
    }
    if (this.confirmText === 'Yes, Delete!') {
      this.confirmText = this.staticText.modal.yesDelete;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal?.currentValue) {
      this.uuid = uuid();
      setTimeout(() => {
        return $(document.getElementById(this.uuid)).modal('show');
      }, 100);
    }
    return $(document.getElementById(this.uuid)).modal('hide');
  }

  action(status: boolean): void {
    this.userInput.emit(status);
  }
}
