export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'खोज'
    }
  },
  bpTable: {
    loadMore: 'और लोड करें',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'खोज देश'
    }
  },
  createNew: {
    continue: 'जारी रखना'
  },
  datePicker: {
    chooseADate: 'एक तिथि चुनें'
  },
  dateRangePicker: {
    choosePeriod: 'अवधि चुनें',
    today: 'आज',
    lastWeek: 'पिछले सप्ताह',
    lastMonth: 'पिछला महीना'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'कृपया वह नंबर प्रदान करें जिसे आप परीक्षण के लिए उपयोग करना चाहते हैं',
    resendOtp: 'ओटीपी पुनः भेजें',
    enterOtp: 'OTP दर्ज करें',
    verifyOtp: 'OTP सत्यापित करें',
    sendOtp: 'OTP भेजें',
    cancel: 'रद्द करना',
    placeholders: {
      phone: 'फ़ोन*',
      enterOtp: 'OTP दर्ज करें*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'कृपया अपना कारण चुनें',
    cancel: 'रद्द करना',
    submit: 'जमा करना!',
    giveFeedback: 'प्रतिक्रिया दें',
    ratings: 'रेटिंग',
    shareYourExperience: 'अपने अनुभव को साझा करें',
    reason1: 'कारण 1',
    reason2: 'कारण 2',
    reasonBehindYourDecision: 'आपके निर्णय के पीछे का कारण',
  },
  feedbackModal: {
    paymentIsInProgress: 'भुगतान प्रगति पर है',
    pleaseDoNotCloseThisWindow: 'कृपया इस विंडो को बंद न करें',
    placeholders: {
      name: 'नाम',
      email: 'ईमेल*',
      phone: 'फ़ोन*',
      pleaseShareYourUseCase: 'कृपया अपना USECASE साझा करें'
    }
  },
  layout: {
    sidebar: {
      notifications: 'अधिसूचना',
      myAccount: 'मेरा खाता',
      logout: 'लॉग आउट',
      goOnline: 'ऑनलाइन जाओ',
      manage: 'प्रबंधित करें',
      deactivate: 'निष्क्रिय करें',
      activate: 'सक्रिय',
      delete: 'मिटाना',
      home: 'होम',
      bots: 'बॉट',
      inbox: 'इनबॉक्स',
      analytics: 'एनालिटिक्स',
      team: 'टीम',
      subscriptions: 'सुब्स्क्रिप्शन्स',
      more: 'अधिक',
      partnerTeam: 'साथी टीम',
      createNewBot: 'नया बॉट बनाएं',
      dashboard: 'डैशबोर्ड',
      customers: 'ग्राहकों',
      developer: 'डेवलपर',
      plans: 'योजनाओं',
      activities: 'गतिविधियाँ',
      users: 'उपयोगकर्ताओं',
      feedbacks: 'फीडबैक',
      configurations: 'कॉन्फ़िगरेशन',
      copy: 'कॉपी ',
      yourFriendWillMissYou: 'आपका दोस्त आपको याद करेगा',
      rateYourBotCreationExperience: 'अपने बॉट निर्माण अनुभव को दर करें',
      whatMadeYouDeleteYourBot: 'क्या आपने अपना बॉट हटा दिया?',
      iCreatedByMistake: 'मैंने गलती से बनाया',
      iWantToCreateAnotherBot: 'मैं एक और बॉट बनाना चाहता हूं',
      couldNotFindFeaturesThatINeeded: 'मुझे ऐसी विशेषताएं नहीं मिलीं जिनकी मुझे जरूरत है',
      uiWasHardToNavigate: 'UI नेविगेट करना मुश्किल था',
      iDonotWantToTell: 'मैं नहीं बताना चाहता',
      other: 'अन्य',
      weWillDoOurBestToMakeAutomationFun: 'हम सभी के लिए स्वचालन को मजेदार और सरल बनाने की पूरी कोशिश करेंगे।',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'लाइव चैट अनुरोधों को अनुमति देने या ब्लॉक करने के लिए टॉगल करें',
        TOOLTIP_NOTIFICATION: 'सभी अधिसूचनाएं',
        TOOLTIP_MY_ACCOUNT: 'आपकी प्रोफ़ाइल और पासवर्ड सुरक्षा',
        TOOLTIP_LOGOUT: 'डैशबोर्ड से लॉगआउट'
      },
      modal: {
        logout: 'लॉग आउट',
        botDeleteFeedbackForm: 'बॉट डिलीट फीडबैक फॉर्म',
        areYouSureToLogOut: 'क्या आप लॉग आउट करना चाहते हैं?',
        yesIWillBeBack: 'हाँ! मैं वापस आऊंगा',
        deleteBot: 'बॉट हटाएं',
        areYouSureToDeleteBot: 'क्या आप सुनिश्चित हैं कि आप इस बॉट को हटाना चाहते हैं?',
        cancel: 'रद्द करना',
        saveAndDelete: 'सहेजें और हटाएं',
      }
    }
  },
  modal: {
    cancel: 'रद्द करें',
    yesDelete: 'हां, हटाएं!',
    continue: 'जारी रखें'
  },
  unsavedChanges: {
    title: 'असुरक्षित परिवर्तन',
    description: 'असुरक्षित परिवर्तन हो सकते हैं। क्या आप निकलना चाहते हैं?'
  },
  notify: {
    youHave: 'आपके पास',
    new: ' नया'
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'हम आपकी कंपनी का लोगो चुनने का सुझाव देते हैं। 1 एमबी से कम की पीएनजी या जेपीजी वर्गाकार छवि जोड़ें'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'उफ़! बेबी प्लान केवल एक मुफ्त बॉट प्रदान करता है। कृपया अधिक बॉट बनाने के लिए किंग प्लान में अपग्रेड करें।',
    noIAmGood: 'नहीं, मैं अच्छा हूं',
    iWantToUpgrade: 'मैं अपग्रेड करना चाहता हूं',
    oops: 'ओह! ',
    planOfferOnlyOneFreeBot: ' केवल मुफ्त बॉट प्रदान करती है। कृपया ',
    planToCreateMoreBots: ' पर अपग्रेड करें और और बॉट बनाएं।'
  },
  webPreview: {
    preview: ' पूर्व दर्शन',
    botsMessages: 'बॉट के संदेश',
    bot: 'बीओटी',
    eleven36AM: '11:36 पूर्वाह्न',
    usersReply: 'उपयोगकर्ता का उत्तर',
    landingPageBot: 'लैंडिंग पेज बॉट',
    chatbot: 'चैटबोट',
    chat: 'बात करना',
    placeholders: {
      typeYourAnswer: 'अपना उत्तर लिखें'
    }
  },
  paymentLink: {
    discardPayment: 'भुगतान रद्द करना',
    completePayment: 'भुगतान पूरा करें',
    importantUpdate: 'महत्वपूर्ण अद्यतन',
    accountUpdate: 'खाता अद्यतन',
    youHave: 'आपके पास',
    exceededTheMessagesLimit: 'संदेश सीमा पार हो गई',
    ofYour: 'आपके',
    planAnd: 'योजना और',
    botsAreNoLongerActive: 'बॉट अब सक्रिय नहीं हैं',
    onYourWebsite: 'आपकी वेबसाइट पर.',
    youHaveEitherIncompletePaymentOn: '',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'पर या तो आपका भुगतान अधूरा है या आपने अपनी योजना को सफलतापूर्वक अपग्रेड नहीं किया है।',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'पर या तो आपका भुगतान अधूरा है या आपने अपने बॉट को सफलतापूर्वक व्हाइट-लेबल नहीं किया है',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'पर या तो आपका भुगतान अधूरा है या आपने "संदेश ऐड-ऑन" सफलतापूर्वक नहीं खरीदा है।',
    please: 'कृपया अपना भुगतान',
    complete: 'पूरा करें',
    upgrade: 'अपग्रेड करें',
    services: 'सेवाएं जारी रखने के लिए',
    yourPlanToContinueServices: 'आपका योजना की जरूरत है',
    yourPaymentToContinue: '',
    forAnyQueryPleaseWriteUs: ' किसी भी प्रश्न के लिए कृपया हमें',
    writeOn: 'पर लिखें',
    close: 'बंद करें',
    upgradePlan: 'अपग्रेड करें',
    discardModal: {
      title: 'भुगतान रद्द करें',
      description: 'क्या आप वाकई अपना भुगतान छोड़ना चाहते हैं?',
      confirmText: 'हाँ, भुगतान रद्द करें',
    }
  },
  bpExport: {
    exportStatus: 'निर्यात की स्थिति',
    tasksExport: 'कार्य- निर्यात',
    download: 'डाउनलोड करना',
    inProgress: 'जारी',
  }

};
