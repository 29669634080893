export const developer = {
  developers: 'Dezvoltatori',
  enpowerYourDevelopersToHarnessTheFullPotential: `Dă puterea dezvoltatorilor tăi să exploateze întregul
  potențial al tabloului tău de bord`,
  accessToken: 'Token de Acces',
  yourAPI: 'API-urile tale',
  api: {
    apiManagement: 'Managementul API-urilor',
    apiManagementSubHeading: 'Adaugă, configurează, testează și administrează API-urile tale pe care le poți declanșa în fluxurile tale de chat.',
    addApi: 'Adaugă API',
    createApi: 'Creează un API',
    noApiFound: 'Niciun API găsit!',
    deleteApis: 'Șterge API-uri',
    areYouSureYouWantToDeleteAPI: 'Sunteți sigur că doriți să ștergeți API-urile?',
    placeholders: {
      search: 'Căutare după numele API-ului'
    },
    tableHead: {
      method: 'Metodă',
      apiName: 'Numele API-ului',
      url: 'URL',
      actions: 'Acțiuni'
    },
    parametersTab: 'Parametri',
    headersTab: 'Antete',
    bodyTab: 'Corp',
    manageApiResponses: 'Administrează răspunsurile API',
    chooseVariableToSave: 'Alegeți variabila pentru a salva',
    selectCustomAttribute: 'Selectați atributul personalizat',
    subTab: {
      key: 'Cheie',
      enterKey: 'Introdu cheia',
      value: 'Valoare',
      enterValue: 'Introdu valoarea',
      addAnotherField: 'Adaugă un alt câmp',
      save: 'Salvează',
      useslashToInsertCustomVariables: `Folosește '/' pentru a insera variabile personalizate sau valori dinamice din răspunsul utilizatorului.`,
      response: 'Răspuns',
      apiRequestBody: 'Corpul cererii API',
      hit: 'Succes',
      test: 'Test',
      buttonToGetAResponse: 'Buton pentru a obține un răspuns',
      variable: 'Variabilă',
      saveToCustomAttribute: 'Salvați în Atribut Personalizat'
    },
    apiLogs: {
      apiManagement: 'Managementul API-urilor',
      addConfigureTestAndManageYourAPIs: 'Adaugă, configurează, testează și administrează API-urile tale pe care le poți declanșa în fluxurile tale de chat.',
      searchHere: 'Căutare după codul de stare',
      deleteApis: 'Șterge API-uri',
      testAnApi: 'Testează un API',
      noAPILogFound: 'Niciun jurnal API găsit',
      responseCode: 'Codul de Răspuns',
      message: 'Mesaj',
      apiResponse: 'Răspunsul API',
      actions: 'Acțiuni',
      deleteAPIs: 'Șterge API-uri',
      areYouSureYouWantToDelete: 'Ești sigur că vrei să ștergi acest API?',
      apiLogs: 'Jurnale API',
      subTitle: `Înregistrare detaliată a tuturor cererilor și răspunsurilor API declanșate în fluxul de chat al chatbot-ului.`
    }
  },
  configApi: {
    configureApi: 'Configurează un API',
    configureApiSubHeading: 'Poți configura aici API-ul tău bazat pe JSON și îl poți folosi pentru a îmbogăți fluxul tău de chat.',
    apiLogs: 'Jurnalele API',
    test: 'Test',
    manageApiResponse: 'Administrează răspunsurile API',
    body: 'Corp',
    headers: 'Antete',
    parameters: 'Parametri',
    placeholders: {
      enterApiName: 'Introdu numele API-ului',
      enterApiUrl: 'Introdu URL-ul API-ului'
    }
  }
};
