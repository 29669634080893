export const developer = {
  developers: 'डेवलपर्स',
  enpowerYourDevelopersToHarnessTheFullPotential: 'अपने डेवलपर्स को आपके डैशबोर्ड की पूरी क्षमता का लाभ उठाने का साधन करें',
  accessToken: 'पहुँच टोकन',
  yourAPI: 'आपकी API',
  api: {
    apiManagement: 'API प्रबंधन',
    apiManagementSubHeading: 'आप अपने चैट फ़्लो में ट्रिगर करने के लिए उपयोग कर सकते हैं अपनी API जोड़ें, कॉन्फ़िगर करें, टेस्ट करें और प्रबंधित करें।',
    addApi: 'API जोड़ें',
    createApi: 'API बनाएं',
    noApiFound: 'कोई API नहीं मिली!',
    deleteApis: 'API हटाएं',
    areYouSureYouWantToDeleteAPI: 'क्या आप निश्चित हैं कि आप API को हटाना चाहते हैं?',
    placeholders: {
      search: `API नाम से खोजें`
    },
    tableHead: {
      method: 'विधि',
      apiName: 'API का नाम',
      url: 'URL',
      actions: 'क्रियाएँ'
    },
    parametersTab: 'पैरामीटर',
    headersTab: 'हैडर्स',
    bodyTab: 'बॉडी',
    manageApiResponses: 'API प्रतिसाद प्रबंधन',
    chooseVariableToSave: 'सहेजने के लिए चयन करें',
    selectCustomAttribute: 'कस्टम विशेषता का चयन करें',
    subTab: {
      key: 'कुंजी',
      enterKey: 'कुंजी दर्ज करें',
      value: 'मूल्य',
      enterValue: 'मूल्य दर्ज करें',
      addAnotherField: 'एक और फ़ील्ड जोड़ें',
      save: 'सहेजें',
      useslashToInsertCustomVariables: `उपयोग करें '/' वाली प्रतिसाद से उपयोगकर्ता की प्रतिक्रिया से अनुकूलन या गतिशील मूल्य डालने के लिए।`,
      response: 'प्रतिसाद',
      apiRequestBody: 'API अनुरोध बॉडी',
      hit: 'हिट',
      test: 'परीक्षण',
      buttonToGetAResponse: 'रिस्पॉन्स प्राप्त करने के लिए बटन',
      variable: 'चर',
      saveToCustomAttribute: 'कस्टम विशेषता में सहेजें'
    },
    apiLogs: {
      apiManagement: 'API प्रबंधन',
      addConfigureTestAndManageYourAPIs: 'आप अपने चैट फ़्लो में ट्रिगर करने के लिए उपयोग कर सकते हैं अपनी API जोड़ें, कॉन्फ़िगर करें, टेस्ट करें और प्रबंधित करें।',
      searchHere: 'स्थिति कोड द्वारा खोजें',
      deleteApis: 'API हटाएं',
      testAnApi: 'एक API का परीक्षण करें',
      noAPILogFound: 'कोई API लॉग नहीं मिला',
      responseCode: 'प्रतिसाद कोड',
      message: 'संदेश',
      apiResponse: 'API प्रतिसाद',
      actions: 'क्रियाएँ',
      deleteAPIs: 'API लॉग हटाएं',
      areYouSureYouWantToDelete: 'क्या आप वाकई इस API लॉग को हटाना चाहते हैं?',
      apiLogs: 'एपीआई लॉग',
      subTitle: 'चैटबॉट के चैट फ़्लो में त्रिगर होने वाली सभी एपीआई अनुरोधों और प्रतिक्रियाओं का विस्तार रिकॉर्ड।'
    }
  },
  configApi: {
    configureApi: `API कॉन्फ़िगर करें`,
    configureApiSubHeading: `आप अपने चैट फ़्लो को समृद्ध करने के लिए अपनी JSON आधारित API को यहाँ कॉन्फ़िगर कर सकते हैं और उसका उपयोग कर सकते हैं।`,
    apiLogs: `API लॉग`,
    test: `परीक्षण`,
    manageApiResponse: `API प्रतिसाद प्रबंधन`,
    body: `बॉडी`,
    headers: `हैडर्स`,
    parameters: `पैरामीटर्स`,
    placeholders: {
      enterApiName: `API का नाम दर्ज करें`,
      enterApiUrl: `API का URL दर्ज करें`
    }
  }
};
