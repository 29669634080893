export const whitelabel = {
  plans: 'תוכניות',
  managePlan: 'ניהול תוכניות',
  whitelabelCharges: 'חיובים לברנד',
  chargesOrTaxesConfiguration: 'הגדרת חיובים/מסים',
  websitePlans: 'תוכניות לאתר',
  whatsappPlansHeader: 'תוכניות ל-WhatsApp',
  addOnPlans: {
    createAddOnPlans: 'צור תוכניות תוספות',
    checkoutYourCurrentMessage: 'בדוק את תוכניות התוספות הנוכחיות שלך ותוכניות חדשות ללקוחותיך',
    choosePlanValidity: 'בחר את תוקף התוכנית',
    chooseValidity: 'בחר תוקף',
    oneYear: 'שנה אחת',
    billingCycle: 'מחזור חיוב',
    numberOfMessages: 'מספר הודעות',
    pleaseEnterNumberOfMessages: 'נא להזין את מספר ההודעות',
    setCurrency: 'הגדר מטבע',
    select: 'בחר',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'נקה הכל',
    apply: 'החל',
    currency: 'מטבע',
    amount: 'סכום',
    validity: 'תוקף',
    upgradePlan: 'שדרג תוכנית',
    addPlan: 'הוסף תוכנית',
    serialNumber: 'מספר סידורי',
    messages: 'הודעות',
    active: 'פעיל',
    priceDetails: 'פרטי המחיר',
    action: 'פעולה',
    addOnChanges: 'תוספות לחיובים: ',
    perYear: 'לשנה',
    taxes: 'מסים',
    placeholders: {
      enterNumberOfMesasges: 'הזן את מספר ההודעות',
      enterAmount: 'הזן סכום',
      enterValidity: 'הזן תוקף',
      searchByMessages: 'חפש לפי מספר הודעות'
    },
    modal: {
      deleteAddOnsPlan: 'מחק תוכנית תוספות',
      wantToDeleteThisAddOnsPlan: 'האם אתה בטוח שברצונך למחוק תוכנית תוספות זו?',
    }
  },
  charges: {
    addWhitelabelCharges: 'הוסף חיובים לברנד',
    checkoutExistingCharges: 'בדוק את החיובים הקיימים בשיטות התשלום השונות והוסף חיובים חדשים לברנד',
    currency: 'מטבע',
    chooseCurrency: 'בחר מטבע',
    amountPerBotPerMonth: 'סכום לבוט לחודש',
    cancel: 'בטל',
    upgradeChanges: 'שדרג חיובים',
    addCharges: 'הוסף חיובים',
    serialNumber: 'מספר סידורי',
    action: 'פעולה',
    taxes: 'מסים',
    placeholders: {
      enterAmountBotPerMonth: 'הזן סכום לבוט לחודש',
      searchByCurrency: 'חפש לפי מטבע',
    },
    modal: {
      deleteCharges: 'מחק חיובים',
      wantToDeleteThisCharge: 'האם אתה בטוח שברצונך למחוק חיוב זה?',
    }
  },
  planManagement: {
    createNewPlans: 'צור תוכניות חדשות',
    checkoutYourCurrentPlan: 'בדוק את התוכניות הנוכחיות שלך והוסף תוכניות חדשות ללקוחותיך',
    choosePlan: 'בחר תוכנית',
    selectPlan: 'בחר תוכנית',
    baby: 'בייבי',
    king: 'קינג',
    displayName: 'שם תצוגה',
    messageLimit: 'הגבלת הודעות',
    enterAValidEmail: 'הזן כתובת אימייל תקינה',
    setCurrency: 'הגדר מטבע',
    select: 'בחר',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'נקה הכל',
    apply: 'החל',
    perMonth: 'לחודש',
    perYear: 'לשנה',
    cancel: 'בטל',
    upgradePlan: 'שדרג תוכנית',
    addPlan: 'הוסף תוכנית',
    serialNumber: 'מספר סידורי',
    plan: 'תוכנית',
    messages: 'הודעות',
    pricing: 'מחירון',
    actions: 'פעולות',
    perMonthText: 'לחודש',
    perYearText: 'לשנה',
    placeholders: {
      enterDisplayName: 'הזן שם תצוגה',
      enterMessageLimit: 'הזן הגבלת הודעות',
      searchByPlanName: 'חיפוש לפי שם התוכנית או הודעות'
    },
    modal: {
      deletePlan: 'מחק תוכנית',
      wantToDeleteThisBabyPlan: 'מחיקת תוכנית התינוק תשפיע על כל הלקוחות. האם אתה בטוח שברצונך למחוק את התוכנית הזו?',
      wantToDeleteThisPlan: 'האם אתה בטוח שברצונך למחוק תוכנית זו?',
    },
    appTooltip: {
      chooseYourPlanType: 'בחר את סוג התוכנית שלך',
      pleaseInputYourPlanDisplayName: 'נא להזין את שם התצוגה של התוכנית שלך',
      typeMessageLimitForCustomer: 'הזן הגבלת הודעות ללקוח',
      selectYourPreferredCurrencies: 'בחר מטבעות מועדפים',
    }
  },
  taxesConfiguration: {
    addTaxes: 'הוסף מסים',
    checkoutExistingTaxes: 'בדוק את המסים הקיימים בשיטות התשלום השונות והוסף מסים חדשים',
    taxName: 'שם מס',
    applicableOn: 'נכון ל',
    choosePlans: 'בחר תוכניות',
    chooseCurrency: 'בחר מטבע',
    percentage: 'אחוז',
    cancel: 'בטל',
    serialNumber: 'מספר סידורי',
    name: 'שם',
    currency: 'מטבע',
    updateTaxes: 'שדרג מסים',
    actions: 'פעולות',
    modal: {
      deleteTaxes: 'מחק מסים',
      wantToDeleteThisTax: 'האם אתה בטוח שברצונך למחוק מס זה?',
    },
    placeholders: {
      taxName: 'שם מס',
      taxPercentage: 'אחוז מס',
      searchByCurrency: 'חפש לפי מטבע',
    }
  },
  whatsappPlans: {
    createNewPlans: 'צור תוכניות חדשות',
    checkoutYourCurrentAndNewPlans: 'בדוק את התוכניות הנוכחיות שלך והוסף תוכניות חדשות ללקוחותיך',
    choosePlan: 'בחר תוכנית',
    selectPlan: 'בחר תוכנית',
    baby: 'בייבי',
    king: 'קינג',
    messageLimit: 'הגבלת שיחה',
    enterAValidMessageLimit: 'הזן הגבלת שיחה תקינה',
    messages: 'שיחות',
    setCurrency: 'הגדר מטבע',
    select: 'בחר',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'נקה הכל',
    apply: 'החל',
    perMonth: 'לחודש',
    perYear: 'לשנה',
    cancel: 'בטל',
    addPlan: 'הוסף תוכנית',
    serialNumber: 'מספר סידורי',
    plan: 'תוכנית',
    displayName: 'שם תצוגה',
    pricing: 'מחירון',
    actions: 'פעולות',
    perMonthText: 'לחודש',
    perYearText: 'לשנה',
    updatePlan: 'שדרג תוכנית',
    placeholders: {
      enterDisplayName: 'הזן שם תצוגה',
      enterMessageLimit: 'הזן הגבלת שיחה',
      searchByPlanName: 'חיפוש לפי שם התוכנית או שיחות',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'הזן הגבלת שיחה ללקוח',
      chooseYourPlanType: 'בחר את סוג התוכנית שלך',
      selectYourPreferredCurrencies: 'בחר מטבעות מועדפים',
      pleaseInputYourPlanDisplayName: 'נא להזין את שם התצוגה של התוכנית שלך',
    },
    modal: {
      deletePlan: 'מחק תוכנית',
      wantToDeleteThisBabyPlan: 'מחיקת תוכנית התינוק תשפיע על כל הלקוחות. האם אתה בטוח שברצונך למחוק את התוכנית הזו?',
      areYouSureYouWantToDeleteThisPlan: 'האם אתה בטוח שברצונך למחוק תוכנית זו?',
    }
  }
};
