const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Modifica il tuo flusso di chat',
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    classicBuilder: 'Builder classico',
    visualiseFlow: 'Visualizza il flusso',
    testLiveOnTelegramWeb: 'Test in diretta sul Web Telegram',
    testFlow: 'Flusso di prova',
    importTemplate: 'Modello di importazione',
    landingPageBotReview: 'Pagina di destinazione Anteprima del bot',
    install: 'Installare',
    testLiveOnMessengerWeb: 'Test live su Messenger Web',
    testLiveOnWhatsappWeb: 'Test in diretta sul web di WhatsApp',
    testLiveOnInstagramWeb: 'Test live su Instagram Web',
    seeAllChatFlow: 'Vedi tutti i flussi di chat',
    launchDemoModal: 'Lancia la demo modale',
    selectATemplateToKickStartYourBot: 'Seleziona un modello per calciare il tuo bot',
    hireChatBotDesigner: 'Assumi Chatbot Designer',
    recommendedForYou: 'raccomandato per te',
    useTemplate: 'Usa il modello',
    exportStatus: 'Stato di esportazione',
    flowExport: 'Flusso- esportazione',
    download: 'Scaricamento',
    importFlow: 'Flusso di importazione',
    beforeYouStartUploadPleaseMakeSure: 'Prima di iniziare il caricamento, assicurati',
    ponit1: '1. Il limite di caricamento della dimensione del file massima è 1 MB',
    point2: '2. Il formato del file dovrebbe essere in JSON',
    selectFile: 'Seleziona il file',
    preview: 'Anteprima',
    test: 'Test',
    OopsIntegrationAreNotAvailable: 'Ops! Le integrazioni non sono disponibili in ',
    pleaseUpgradeTo: ' piano. Effettua l\'upgrade al ',
    planForIntegration: ' piano per le integrazioni.',
    placeholders: {
      searchTemplateYouNeed: 'Cerca nel modello di cui hai bisogno',
    },
    noDataFound: {
      botFlow: 'Flusso di bot',
      noBotFlowAddedYet: 'Nessun flusso di bot ancora aggiunto!',
      templates: 'Modelli',
      noTemplatesFound: 'Nessun modello trovato!',
    },
    appTooltip: {
      exportFlow: 'Flusso di esportazione',
      importFlow: 'Flusso di importazione',
      clickToViewChatFlow: 'Fare clic per visualizzare il flusso di chat in Drag and Drop Builder',
      clickToPreviewChatFlow: 'Fare clic per visualizzare in anteprima il flusso di chat',
      clickToViewChatFlowAsFlowDiagram: 'Fare clic per visualizzare il flusso di chat come diagramma di flusso',
      clickToTestChatFlow: 'Fare clic per testare il flusso di chat',
      clickToSelectAndImportTemplate: 'Fare clic per selezionare e importare il modello che si adatta meglio alla tua attività.',
      clickToInstallYourBotToSelectedPlatform: 'Fare clic per installare il tuo bot sulla piattaforma selezionata',
      clickToTestDefaultFlowOfTelegramBot: 'Fai clic per testare il flusso predefinito (attivo) sul Web Telegram',
      clickToTestDefaultFlowOfFacebookBot: 'Fai clic per testare il flusso predefinito (attivo) sul Web di Facebook',
      clickToTestDefaultFlowOfWhatsappBot: 'Fai clic per testare il flusso predefinito (attivo) su WhatsApp Web',
      clickToTestDefaultFlowOfInstagramBot: 'Fare clic per testare il flusso predefinito (attivo) su Instagram Web',
    }
  },
  createNewBot: {
    createNewBot: 'Crea nuovo bot',
    stepOneOfTwo: 'Passaggio 1 di 2',
    selectThePlatform: 'Seleziona la piattaforma',
    selectTheBotType: 'Seleziona il tipo di bot',
    aiBasedChatbot: 'Chatbot basato sull\'intelligenza artificiale',
    ruleBasedChatBot: 'Chatbot basato su regole',
    webPlatforms: 'Piattaforme web',
    websiteChatBot: 'Web Web Chatbot',
    withLiveChat: '(Con chat dal vivo)',
    landingPageBot: 'Bot di landing page',
    liveChatWidget: 'Widget di chat dal vivo',
    onlyLiveChat: '(Solo chat dal vivo)',
    socialPlatforms: 'Piattaforme sociali',
    whatsappChatBot: 'WhatsApp Chatbot',
    telegramChatBot: 'Telegram Chatbot',
    facebookChatBot: 'Chatbot di Facebook',
    instagramChatBot: 'Instagram Chatbot',
    selectBotType: 'Seleziona Tipo di bot',
    selectProblem: 'Seleziona il problema che desideri risolvere',
    leadGeneration: 'Generazione di lead',
    customerSupport: 'Supporto clienti',
    appointmentBooking: 'Prenotazione di appuntamenti',
    marketingAutomation: 'Automazione del marketing',
    ecommerceWebsite: 'Sito web di e-commerce',
    otherUseCase: 'Altro',
    back: 'Indietro',
    typeDomainUrl: 'Inserisci l\'URL del tuo sito web per la verifica.',
    dataSourceForChatBotTraining: `Sorgente dati per l'addestramento del chatbot`,
    upload: 'Carica',
    uploadDocuments: 'Carica documenti',
    uploadDocument: 'Carica documento',
    selectFile: 'Seleziona file',
    continue: 'Continua',
    websiteUrl: 'Inserisci l\'URL del tuo sito web',
    skipNow: 'Salta ora',
    completeTheExistingBotSetup: 'Completa la configurazione del bot esistente',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Hai già creato un bot WhatsApp. Completa la configurazione per crearne uno nuovo.',
    completeSetup: 'Configurazione completa',
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    fbBot: {
      createNewBot: 'Crea nuovo bot',
      help: 'Aiuto',
      back: 'Indietro',
      step: 'Fare un passo',
      of3: ' di 3',
      loginWithFacebook: 'Fai il login con facebook',
      asPerLatestChangesInFacebookPolicy: 'Secondo le ultime modifiche alle politiche di Facebook, il proprietario del bot di Facebook deve avere un ruolo di "manager" per accedere a tutte le funzionalità della pagina Facebook.',
      login: 'Login',
      connectFacebookBot: 'Connetti Facebook Bot',
      connectBotWithYourFacebookPagesAnd: 'Collega Bot con le tue pagine di Facebook e',
      exploreTruePowerOfFacebook: 'Esplora il vero potere di Facebook',
      connect: 'Collegare',
      connected: 'Collegato',
      createNewPage: 'Crea nuova pagina',
      refreshPageList: 'Aggiorna elenco di pagina',
      thisPageIsSuccessfullyIntegratedWith: 'Questa pagina è integrata con successo con',
      yourMessagesFromMessengerWillAppearIn: '. I tuoi messaggi da Messenger appariranno',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Continua a bot configuarion',
      welcomeToBrandNameWeAreHereToHelpYou: `Benvenuti a ${brand}. Siamo qui per aiutarvi.`,
      letsTryYourBotAndSeeHowItWorks: 'Proviamo il tuo bot e vediamo come funziona! Fai clic su "Test su Facebook".',
      howAreYouDoingToday: 'Come stai oggi?',
      thisIsHowSimpleToUseFacebookBot: 'Questo è tutto! Questo è quanto è semplice usare Facebook Bot',
      letsCreateYourOwnChatFlowNow: 'Creiamo ora il tuo flusso di chat',
    },
    instagramBot: {
      createNewBot: 'Crea nuovo bot',
      back: 'Indietro',
      step: 'Fare un passo',
      of3: ' di 3',
      loginWithInstagram: 'Accedi con Instagram',
      loginToYourInstagramAccount: 'Accedi al tuo account Instagram per abilitare il chatbot sul manico.',
      managerLevelDescription: 'I proprietari di bot di Instagram devono avere almeno un livello di "manager" di autorizzazioni o superiore per accedere a tutte le funzionalità.',
      login: 'Login',
      connectInstagramBot: 'Connetti Instagram Bot',
      connectBotWithYourInstagramAnd: 'Collega Bot con il tuo Instagram e',
      exploreTruePowerOfInstagram: ' Esplora il vero potere di Instagram',
      connected: 'Collegato',
      connect: 'Collegare',
      createNewPage: 'Crea nuova pagina',
      refreshPageList: 'Aggiorna elenco di pagina',
      thisPageIsSuccessfullyintegratedWith: 'Questa pagina è integrata con successo con',
      yourMessagesFromMessengerWillAppearIn: '. I tuoi messaggi da Messenger appariranno',
      inbox: ' Inbox',
      continueToBotConfiguration: 'Continua a bot configuarion',
      welcomeToBrandNameWeAreHereToHelpYou: `Benvenuti a ${brand}. Siamo qui per aiutarvi.`,
      letsTryYourBotAndSeeHowItWorks: 'Proviamo il tuo bot e vediamo come funziona! Fai clic su "Test su Instagram".',
      howAreYouDoingToday: 'Come stai oggi?',
      thisIsHowSimpleToUseInstagramBot: 'Questo è tutto! Questo è quanto è semplice utilizzare Instagram Bot',
      letsCreateYourOwnChatFlowNow: 'Creiamo ora il tuo flusso di chat',
    },
    telegramBot: {
      back: 'Indietro',
      step: 'Fare un passo',
      of3: ' di 3',
      seeHowToUse: 'Guarda come usare',
      help: 'Aiuto',
      loginToYourTelegramAccount: 'Accedi al tuo account Telegram',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Accedi al tuo account per creare un nuovo bot o collegare il bot esistente',
      verificationCodeSentOnRegisteredNumber: 'Codice di verifica inviata sul numero registrato',
      createYourBot: 'Crea il tuo bot',
      enterYourBotsNameAnd: 'Inserisci il nome del tuo bot, connettiti e lascia che il divertimento inizi',
      botUserNameMustEndWithBotAtTheEnd: 'Il nome utente del bot deve finire con il "bot" alla fine.',
      terrisBot: 'Per esempio. teris_bot o terisbot',
      botNameAlreadyTaken: 'Questo nome bot è già stato preso. Per favore prova qualcosa di diverso',
      connect: 'Collegare',
      createBot: 'Crea bot',
      codeNotReceivedResend: 'Codice non ricevuto? RECEND!',
      changeNumber: 'Numero di modifica',
      telegramPreview: 'Anteprima del telegramma',
      worldsFastestMessagingApp: 'L`app di messaggistica più veloce del mondo ed è gratuita!',
      bot: 'Bot',
      phoneNumber: 'Numero di telefono',
      userName: 'Nome utente',
      none: 'Nessuno',
      bio: 'Bio',
      fewWordsAboutYourself: 'Poche parole su te stesso',
      settings: 'Impostazioni',
      notificationsAndSound: 'Notifiche e suoni',
      privacyAndSecurity: 'Privacy e sicurezza',
      dataAndStorage: 'Dati e archiviazione',
      chatSettings: 'Impostazioni della Chat',
      chatFolders: 'Cartelle di chat',
      devices: 'Dispositivi',
      connectYourBot: 'Collega il tuo bot',
      createNewBotOrConnectWithExistingOne: 'Crea nuovo bot o collega quello esistente',
      createNewBot: 'Crea nuovo bot',
      welcomeToBrandNameWeAreHereToHelpYou: `Benvenuti a ${brand}. Siamo qui per aiutarvi.`,
      letsTryYourBotAndSeeHowItWorks: 'Proviamo il tuo bot e vediamo come funziona! Fai clic su "Test su Telegram".',
      howAreYouDoingToday: 'Come stai oggi?',
      thisIsHowSimpleToUseTelegramBot: 'Questo è tutto! Questo è quanto è semplice utilizzare Telegram Bot',
      letsCreateYourOwnChatFlowNow: 'Creiamo ora il tuo flusso di chat',
      placeholders: {
        searchCountry: 'Cerca paese',
        enterTelegramMobileNumber: 'Immettere il numero di cellulare Telegram',
        enterLoginCodeReceived: 'Immettere il codice di accesso ricevuto sull`account Telegram *',
        twoFactorAuthentication: 'Password 2FA (facoltativo)',
        botName: 'Nome bot *',
        botUserName: 'Nome utente Bot *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Crea nuovo bot',
      seeHowToUse: 'Guarda come usare',
      help: 'Aiuto',
      back: 'Indietro',
      step: 'Fare un passo',
      of2: ' di 2',
      buildA: 'Costruire un',
      thatMatchesYourBrandPersonality: ' che corrisponde alla personalità del tuo marchio',
      chatBotIcon: 'Icona di chatbot',
      optional: 'Opzionale',
      themeColor: 'Colore del tema',
      chatBotName: 'Nome chatbot',
      botNameIsRequired: 'È richiesto il nome del bot',
      botNameShouldBeLessThan50Characters: 'Il nome del bot dovrebbe essere inferiore a 50 caratteri',
      continue: 'Continua',
      welcomeToBrandNameWeAreHereToHelpYou: `Benvenuti a ${brand}. Siamo qui per aiutarvi.`,
      whatIsYourEmailAddress: 'Qual è il tuo indirizzo e-mail?',
      invalidEmailAddress: 'indirizzo email non valido',
      customizeYourLandingPageBotLook: 'Personalizza la tua pagina di destinazione Bot Look',
      customizeYourWidgetLook: 'Personalizza il tuo look widget',
      customizeYourBotLook: 'Personalizza il tuo look bot',
      buildYourLandingPageBotAndAutomateInteraction: 'Costruisci la tua pagina di destinazione bot e automatizza l`interazione con i tuoi clienti condividendo il suo link',
      useLiveChatBotToConnectToCustomers: 'Usa Live Chat Bot per connettersi ai tuoi clienti in tempo reale',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Collega Chatbot al tuo sito Web e automatizza le interazioni con i visitatori',
      widget: 'aggeggio',
      botInterface: 'Interfaccia bot',
      mergedBot: 'Chatbot del sito web, Chatbot della pagina di destinazione, Chatbot dell\'app mobile',
      mergedBotToolTip: 'Crea una pagina del bot per catturare l\'attenzione dei visitatori e coinvolgerli in conversazioni.',
      placeholders: {
        typeANameForYourBot: 'Digita un nome per il tuo nuovo bot',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Inserisci il tuo nome bot',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Seleziona un colore del tema per la finestra della tua chat',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Questo logo verrà utilizzato solo nella piattaforma ${brand ? brand : ''}. Suggeriamo di scegliere il logo della tua azienda. Aggiungi un'immagine al quadrata PNG o JPG sotto 1 MB.`,
      }
    },
    whatsappBot: {
      back: 'Indietro',
      step: 'Fare un passo',
      justAStepAway: 'Solo un passo avanti',
      seeHowToUse: 'Guarda come usare',
      help: 'Aiuto',
      createWhatsappChatBot: 'Crea whatsapp chatbot',
      businessNameIsRequired: 'È richiesto il nome aziendale.',
      maximumLengthForBusinessName40Characters: 'La lunghezza massima per il nome aziendale è di 40 caratteri.',
      businessCategory: 'Categoria di Business',
      categoryIsRequired: 'È richiesta la categoria.',
      descriptionIsRequired: 'È richiesta una descrizione.',
      maximumLengthForDescription200Characters: 'La lunghezza massima per la descrizione è di 200 caratteri.',
      pleaseFillAValidEmailAddress: 'Si prega di compilare un`e - mail aziendale valida.',
      businessEmailIsRequired: 'È richiesta un`e - mail aziendale.',
      phoneNumberShouldHaveMinimum7Digits: 'Il numero di telefono dovrebbe avere almeno 7 cifre',
      phoneNumberIsRequired: 'È richiesto il numero di telefono',
      websiteIsRequired: 'È richiesto il sito Web.',
      maximumLengthForWebsite40Characters: 'La lunghezza massima per il sito Web è di 40 caratteri.',
      maximumLengthForAddress150Characters: ' La lunghezza massima per l`indirizzo è di 150 caratteri.',
      doYouHaveWhatsappApiKey: 'Hai la chiave API di WhatsApp?',
      apiProvider: 'Provider API',
      three60Dialog: 'Dialog 360',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'È richiesta la chiave API.',
      whatsappBusinessAccountIdIsRequired: 'WhatsApp Businness Account ID è richiesto.',
      whatsappPhoneNumberIdIsRequired: ' È richiesto l`ID del numero di telefono di WhatsApp.',
      systemAccessTokenIsRequired: 'È richiesto il token di accesso al sistema.',
      sourceIdIsRequired: 'È richiesto l`ID sorgente.',
      // apiKeyIsRequired: 'È richiesta la chiave API.',
      // createWhatsappChatBot: 'Crea whatsapp chatbot',
      businessName: 'Nome commerciale',
      businessAccount: 'Conto commerciale',
      // businessCategory: 'Categoria di Business',
      businessDescription: 'Descrizione dell`attività',
      address: 'Indirizzo',
      businessEmail: 'Email di lavoro',
      websiteUrl: 'URL del sito',
      thankYouForYourInterestIn: 'Grazie per il tuo interesse a',
      whatsappChatBot: ' WhatsApp Chatbot.',
      weHaveReceivedYourInterests: 'Abbiamo ricevuto il tuo interesse e il nostro team di vendita sarà in contatto con te nelle prossime 24 ore.',
      theyWillProvideYouWhatsappApiKey: 'Ti forniranno la chiave API WhatsApp.',
      readyToCreateWhatsappBot: 'Sono pronto a creare WhatsApp Bot',
      exploreWhatsappFeatures: 'Esplora le funzionalità di WhatsApp',
      welcomeToBrandNameWeAreHereToHelpYou: `Benvenuti a ${brand}. Siamo qui per aiutarvi.`,
      letsTryYourBotAndSeeHowItWorks: 'Proviamo il tuo bot e vediamo come funziona! Fai clic su "Test su WhatsApp".',
      howAreYouDoingToday: 'Come stai oggi?',
      thisIsHowSimpleToUseWhatsappBot: 'Questo è tutto! Questo è quanto è semplice usare WhatsApp Bot',
      letsCreateYourOwnChatFlowNow: 'Creiamo ora il tuo flusso di chat',
      connectWhatsAppBot: 'Connetti Bot WhatsApp',
      createtWhatsAppBot: 'Crea un Bot WhatsApp',
      proceed: 'Procedi',
      whatsApiProvider: 'Provider API WhatsApp',
      cloudApi: 'API Cloud',
      netcoreApi: 'API Netcore',
      placeholders: {
        yourBusinessName: 'Il tuo nome commerciale',
        businessDescription: 'Descrizione dell`attività',
        businessEmail: 'Email di lavoro',
        phone: 'Telefono*',
        website: 'Sito Web (ad es. Www.example.com)',
        address: 'Indirizzo',
        enterApiKey: 'Immettere la chiave API',
        enterWhatsappBusinessAccountId: 'Inserisci l`ID account Businness WhatsApp',
        enterWhatsappPhoneNumberId: 'Immettere l`ID numero di telefono di WhatsApp',
        enterSystemAccessToken: 'Immettere il token di accesso al sistema',
        enterSourceId: 'Immettere l`ID sorgente',
        businessName: `Nome dell'azienda`,
        businessWebsite: `Sito Web dell'azienda`,
        selectWhatsappApiProvider: 'Scegli il provider di API WhatsApp',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: `Scegli il tuo provider di API WhatsApp`,
        doYouHaveWhatsAppBusinessAPI: `Hai WhatsApp Business API?`,
        yes: `Sì`,
        noIAmNotSure: `No, non sono sicuro/a`,
        selectApiProvider: `Seleziona il provider di API`,
        whatsAppCloudAPI: `API Cloud di WhatsApp`,
        enterYourPhoneNumberId: `Inserisci il tuo ID numero di telefono`,
        phoneNumberId: `ID numero di telefono`,
        enterYourWhatsAppBusinessAccountId: `Inserisci il tuo ID account WhatsApp Business`,
        whatsAppBusinessAccountId: `ID account WhatsApp Business`,
        enterYourSystemUserToken: `Inserisci il tuo token utente di sistema`,
        systemUserToken: `Token utente di sistema`,
        help: `Aiuto`,
        netcoreAPI: `API Netcore`,
        enterYourSourceId: `Inserisci il tuo ID origine`,
        sourceId: `ID origine`,
        enterYourNetcoreApiKey: `Inserisci la tua chiave API Netcore`,
        netcoreApiKey: `Chiave API Netcore`,
        submit: `Invia`,
        noneOftheAbove: `Nessuno dei precedenti`,
        next: 'Successivo'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Attività legalmente registrata',
        validWebsiteWithDataPrivacyPolicy: 'Sito web valido con politica sulla privacy dei dati',
        gstin: 'GSTIN',
        debit: 'Debito',
        creditCard: 'Carta di credito',
        onlyRequiredForIndianResidents: 'Obbligatorio solo per i residenti indiani',
        validPaymentMethod: 'Metodo di pagamento valido',
        onlyRequiredFor: 'Richiesto solo per',
        indianResidents: 'Residenti indiani',
        enabledWithInternationalPayments: 'Abilitato con pagamenti internazionali',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: `Caso d'uso valido in conformità con la politica commerciale di WhatsApp`,
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Numero di telefono che non ha WhatsApp attivo e capace di ricevere gli SMS',
        discard: 'Scarta',
        proceed: 'Procedi',
        whatsAppBotPrerequisites: 'Prerequisiti del bot WhatsApp',
        letUsGetItDoneForYou: 'Lasciaci fare per te!',
        ifYouAreFacingSomeIssues: 'Se stai affrontando alcuni problemi',
        ourTeamCanHelpYouDoTheSame: 'Il nostro team può aiutarti a fare lo stesso',
        requestACallbackNow: 'Richiedi una chiamata indietro adesso!',
      },
      stepThree: {
        setupWhatsAppBot: 'Configura Bot WhatsApp',
        selectingTheBestBusinessManagerForYourNeeds: 'Selezione del Miglior Gestore Aziendale per le Tue Esigenze',
        createMetaApp: `Come creare un'app Meta`,
        createaMetaAppOn: 'Crea una Meta App su',
        howToCreate: 'Come creare',
        addWhatsAppNumber: 'Come aggiungere un numero WhatsApp',
        addYourWhatsAppNumberAndVerifyTheSame: 'Aggiungi il tuo Numero WhatsApp e verifica lo stesso',
        subscribe: 'Iscriviti',
        copyAndSubscribeWebhook: 'Copia e iscriviti al Webhook',
        howToSubscribe: 'Come iscriversi',
        copy: 'Copia',
        appSecretId: 'ID Segreto App',
        enterYourAppSecretId: 'Inserisci il tuo ID Segreto App',
        enterAppSecret: 'Inserisci il Segreto App',
        phoneNumberId: 'ID Numero di Telefono',
        whatsAppBusinessId: 'ID WhatsApp Business',
        assignSystemUser: 'Cómo asignar un usuario del sistema',
        assign: 'Assegnare',
        createApp: 'Crea Meta',
        registerAndVerify: 'Registrati e Verifica',
        add: 'Aggiungi',
        systemUserToken: 'Token Utente di Sistema',
        enterYourSystemUserToken: 'Inserisci il tuo Token Utente di Sistema',
        getAllNecessaryDetails: 'Come ottenere tutti i dettagli necessari',
        subscribeWhatsAppWebhook: 'Come iscriversi al Webhook WhatsApp',
        configureTheWebhookForWhatsAppAccount: `Configura il Webhook per l'account WhatsApp`,
        upadatePaymentMethod: 'Come aggiornare il metodo di pagamento',
        assignThePaymentMethodToWhatsAppAccount: 'Assegna il Metodo di Pagamento a qualsiasi account WhatsApp',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'Concedi il controllo di un account WhatsApp Business a un utente di sistema',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Dettagli chiave che devi copiare dal Pannello di Controllo Meta',
        updatePayment: 'Aggiorna Pagamento',
        letUsGetItDoneForYou: 'Lasciaci fare per te!',
        ifYouAreFacingSomeIssues: 'Se stai affrontando alcuni problemi',
        ourTeamCanHelpYouDoTheSame: 'Il nostro team può aiutarti a fare lo stesso',
        requestACallbackNow: 'Richiedi una chiamata indietro adesso!',
        update: 'Aggiornare',
        connectNow: 'Connetti ora'
      },
      stepFive: {
        selectBusinessManager: 'Seleziona il Gestore Aziendale',
        selectingTheBestBusinessManagerForYourNeeds: 'Selezione del Miglior Gestore Aziendale per le Tue Esigenze'

      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Il tuo bot è installato e attivo',
        youhaveSuccessfullyConnected: 'Ti sei connesso con successo',
        withWhatsApp: 'con WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'Ti sono state assegnate 100 conversazioni al mese per impostazione predefinita',
        upgradeYourPlanForMore: 'Aggiorna il tuo piano per ottenere di più.',
        createChatFlow: 'Crea Flusso di Chat',
        upgradePlan: 'Aggiorna Piano'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Scansiona il QR o Invia un messaggio per attivare il Bot',
        scanTheQrCodeFrom: 'Scansiona il codice QR da',
        yourPhoneWillOpen:
          'Il tuo telefono aprirà un messaggio precompilato da inviare al nostro numero Sandbox. Premi "Invia" su WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Invia un messaggio dal tuo numero WhatsApp',
        sendUsAWhatsAppMessageOn: 'Invia un messaggio WhatsApp a ',
        bySavingInYourContactsAndMessageShouldStartWith: ' salvandoli nei tuoi contatti e il messaggio dovrebbe iniziare con ',
        send: 'Invia',
        or: 'O'
      }
    }
  },
  gettingStarted: {
    hello: 'Ciao,',
    upgrade: 'Aggiornamento',
    gettingStarted: 'Iniziare -',
    yourFacebookBotToken: 'Il tuo token bot di Facebook',
    hasBeenExpired: 'è stato scaduto',
    isExpiringIn: 'sta scadendo in',
    days: ' giorni',
    facebookAuthenticationTokenRemainsFor60Days: 'Il token di autenticazione di Facebook rimane attivo solo per 60 giorni',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Si prega di procedere al rinnovo del token del tuo bot per garantire un accesso ininterrotto ai servizi del chatbot.',
    renewYourBotToken: 'Rinnova il tuo token bot',
    editYourChatFlow: 'Modifica il tuo flusso di chat',
    buildConversationEngagingBot: 'Costruisci flussi di conversazione coinvolgenti per gli utenti del tuo bot.',
    takes5minutes: 'richiede 5 minuti',
    previewYourLiveChatWidget: 'Anteprima il tuo widget di chat live',
    discoverHowThe: 'Scopri come il',
    liveChatFeatureWorks: ' La funzione di chat dal vivo funziona utilizzando il nostro parco giochi di chat dal vivo',
    takes2minutes: 'impiega 2 minuti',
    takes1minute: 'richiede 1 min',
    connected: 'Connesso',
    customizeYourBotApperance: 'Personalizza l`aspetto e la funzionalità del tuo bot.',
    takes3minutes: 'richiede 3 minuti',
    thirdPartyAppsIntegration: 'Integrazione delle app di terze parti',
    integrateYourBot: 'Integra il tuo bot con altre app/CRM per creare automazione aziendale.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Aggiorna il tuo piano per ricevere lead sulla tua email',
    getStartedWithUs: 'Inizia con noi',
    howToBuildAGoodChatFlow: 'Come costruire un buon chatflow',
    howToUseBotPenguinsInboxAndItsFeatures: 'Come utilizzare la posta in arrivo di BotPenguin e le sue caratteristiche',
    botPenguinsPricing: 'Prezzi di BotPenguin`s: esplorare i piani e le funzionalità',
    openHelpDesk: 'Apri help desk',
    helpAndSupport: 'Aiuto e supporto',
    extensiveGuides: 'Guide estese per tutte le tue domande e flussi di lavoro sono disponibili nel nostro',
    resourceCentre: 'Centro di risorse',
    checkoutOurAnswers: 'Puoi controllare le nostre risposte alle domande comuni di seguito.',
    createANewBot: 'Crea un nuovo bot',
    installYourBot: 'Installa il tuo bot',
    plansAndPricing: 'Piani e prezzi',
    previewYourLandingPageBot: ' Anteprima la tua pagina di destinazione bot',
    installYourLiveChatWidget: 'Installa il tuo widget di chat live',
    installYourWebsiteBot: 'Installa il bot sul tuo dispositivo',
    integrateBotToLandingPage: 'Integra il bot nel tuo bot di landing page',
    installWidgetToWebsite: 'Installa il widget sul tuo sito web',
    installBotToWebsite: 'Installa il tuo chatbot',
    whatsappAutomation: 'Automazione di WhatsApp',
    facebookAutomation: 'Automazione di Facebook',
    telegramBotSettings: 'Impostazioni del bot telegramma',
    liveChatWidgetSettings: 'Impostazioni del widget di chat dal vivo',
    landingPageBotSettings: 'Impostazioni del bot page di landing',
    websiteChatBotSettings: 'Impostazioni chatbot del sito Web',
    instagramAutomation: 'Automazione di Instagram',
    diveDeeperIntoBotPenguin: 'Immergiti più in profondità nel botpenguin',
    knowWhereWeAreGoing: 'Sapere dove stiamo andando, i recenti aggiornamenti e aiutaci a correggere alcuni bug.',
    productUpdates: 'Aggiornamenti del prodotto',
    requestAFeature: 'Richiedere una funzione',
    reportABug: 'Segnalare un bug',
    prodcutRoadmap: 'Roadmap del prodotto',
    trainYourChatbot: 'Addestra il tuo Chatbot IA (base di conoscenza)',
    buildEngagingConversation: 'Crea flussi di conversazione coinvolgenti per i tuoi utenti bot.',
    previewYourAIBot: 'Visualizza l\'anteprima del tuo robot AI',
    integrateAIbot: 'Integra il bot nel tuo robot AI',
    aiBotSettings: 'Impostazioni robot AI',
    CustomizeAIBot: 'Personalizza l\'aspetto e la funzionalità del tuo bot.',
    gptKeyWarning: 'Benvenuto nel nostro servizio API ChatGPT! Nota che c\'è un limite di 100 messaggi per ogni bot. Dopo aver raggiunto il limite di 100 messaggi, dovrai utilizzare la tua chiave API ChatGPT per continuare a usufruire del nostro servizio. Per inserire la chiave API ChatGPT, vai alla Configurazione del Modello ChatGPT.',
    whatsappBotSettings: 'Impostazioni bot whatsapp',
    automateCustomerEngagementWithWhatsApp: 'Automatizzazione dell\'interazione con i clienti tramite WhatsApp',
    connectToYourWhatsappNumber: 'Connetti al tuo numero WhatsApp',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Collega il tuo Bot WhatsApp al tuo numero WhatsApp',
    facebookBotSettings: 'Impostazioni del bot di Facebook',
    automateCustomerEngagementWithTelegram: `Automatizza l'interazione con il cliente tramite Telegram`,
    automateCustomerEngagementWithFacebook: `Automatizza l'interazione con il cliente tramite Facebook`,
    automateCustomerEngagementWithInstagram: `Automatizza l'interazione con il cliente tramite Instagram`,
    telegramAutomation: 'Automazione di Telegram',
  },
  install: {
    previewYourLandingPageBot: 'Anteprima la tua pagina di destinazione bot',
    preview: 'Anteprima',
    test: 'Test',
    squareSpace: 'Squarespace',
    installYourChatBot: 'Installare il tuo chatbot sul sito web, nell\'app mobile o lanciarlo come pagina di destinazione o chatbot incorporato',
    seeHowToUse: 'Guarda come usare',
    help: 'Aiuto',
    uniqueLinkToLandingPageBot: 'Ecco il link unico al tuo bot di landing page.',
    shareOnSocialMedia: 'Basta condividerlo con i tuoi utenti sui social media, le pagine di contatto o i biglietti da visita.',
    or: 'O',
    copyUrl: 'Copia URL',
    selectYourWebsiteCms: 'Seleziona il tuo sito Web CMS',
    selectCms: 'Seleziona il CMS/Builder che hai utilizzato per creare il tuo sito Web',
    custom: 'Costume',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Altri CM',
    howWouldYouLikeToInstall: 'Come vorresti installarlo?',
    installingWebsiteBot: 'Scegli che installerai il bot sul tuo sito Web',
    iWillAddMyself: 'Mi aggiungerò',
    getChatBotScript: 'Ottieni il codice di script di chatbot qui sotto e installa tutto da solo',
    getWordpressPlugin: 'Ottieni chiave API plugin WordPress',
    getOurOfficialPlugin: 'Ottieni il nostro plugin ufficiale',
    fromWordpressPluginStore: ' da WordPress Plugin Store',
    getPlugin: 'Ottieni il plugin',
    quickGuideToInstall: 'Guida rapida da installare',
    placeApiKeyBelowIn: 'Incollare la chiave API di seguito in',
    afterDownloadingAndActivatingPlugin: ' Dopo aver scaricato e attivato il plugin',
    copy: 'copia',
    enterYourWebsite: 'Inserisci il tuo sito web e vediamo come sono i tuoi chatbot',
    enterAValidUrl: 'Immettere un URL valido',
    verifyYourInstallation: 'Verifica se la tua installazione ha successo o no?',
    testInstallation: 'Installazione di prova',
    getChatBotScriptCode: 'Ottieni il codice di script chatbot',
    copyThisCodeSnippet: 'Copia questo frammento di codice e inseriscilo nell`HTML di ogni pagina Web in cui si desidera visualizzare il chatbot o incollare nel piè di pagina prima che il tag si chiuda per apparire su ogni pagina.',
    // copy: 'copia',
    sendCodeToMyDeveloper: 'Invia codice al mio sviluppatore',
    sendCodeToMyDevelopmentTeam: 'Invia codice al mio team di sviluppo',
    sendEmail: 'Invia una email',
    enterAValidEmail: 'Inserisci un`e - mail valida',
    codeHasBeen: 'Il codice è stato',
    toYourDeveloper: ' al tuo sviluppatore',
    resent: 'risentirsi di',
    sent: 'inviato',
    resendEmail: 'Rinvia l`e - mail',
    ask: 'Chiedere',
    supportTeamToInstall: ' Team di supporto da installare',
    raiseTheInstallationRequestTo: 'Sollevare la richiesta di installazione a',
    team: ' Squadra',
    helpMeInstallingChatBot: 'Aiutami a installare Chatbot',
    yourBotInstallationIsNotActive: 'La tua installazione di bot non è attiva!',
    monthlyMessageLimitExceeded: 'Limite di messaggio mensile Exeded',
    exceededMonthlyMessageLimitUpgradeNow: 'Hai superato il limite di messaggi mensili, aggiornamento ora.',
    upgradeNow: 'Aggiorna ora',
    botInstallationNotActivePleaseFix: 'L`installazione del bot non è attiva, fissa lo stesso.',
    incorrectBotScript: 'Script bot errato',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'Il codice di installazione che stai verificando non corrisponde a quello sul tuo sito. Assicurati di aver installato il codice corretto per questo bot.',
    unableToSee: 'Incapace di vedere',
    codeOnYourWebsite: ' Codice sul tuo sito web',
    unableToFindCodePleaseReinstall: 'Non siamo in grado di trovare il codice sul tuo sito Web, ti preghiamo di reinstallare.',
    reinstallBot: 'Reinstallare bot',
    yourBotIsInstalledAndActive: 'Il tuo bot è installato e attivo!',
    noPendingAction: 'Nessuna azione in sospeso, il tuo bot è installato e attivo',
    youAreAllSet: 'Siete a posto!',
    showMeMyLeads: 'Mostrami i miei protagonisti',
    connectingToYourWebsiteToVerifyInstallation: 'Connessione al tuo sito Web per verificare l`installazione di bot',
    thisMayTakeUpto30Seconds: 'Questo potrebbe richiedere fino a 30 secondi',
    hereIsTheuniqueAILink: 'Ecco il collegamento univoco al tuo AI Bot',
    installHeading: 'Installa il tuo chatbot',
    websiteChatbot: 'Chatbot del sito web',
    aiChatBot: 'Chatbot AI',
    landingPageBot: 'Chatbot della pagina di destinazione',
    mobileChatBot: 'Chatbot dell\'app mobile',
    embeddedBot: 'Chatbot integrato',
    selectMobileDevice: 'Seleziona il tuo dispositivo mobile',
    mobileDeviceSubheading: 'Seleziona la lingua madre che hai utilizzato per creare la tua app mobile',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Altro',
    copyEmbeddedCodeSnippet: 'Copia questo frammento di codice e inseriscilo nell\'HTML di ogni pagina web in cui desideri visualizzare il chatbot.',
    copyMobileCodeSnippet: 'Copia questo frammento di codice nella tua app mobile dove desideri visualizzare il chatbot.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'Inserisci e -mail per sviluppatori',
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Invia istruzioni al tuo sviluppatore',
      notSureHowToDoIt: 'Non sei sicuro di come farlo? I tuoi sviluppatori possono farlo in un jiff.',
      developersEmail: 'Email dello sviluppatore',
      emailIsRequired: 'È richiesta l`e - mail',
      subject: 'Soggetto',
      message: 'Messaggio',
      send: 'Inviare',
      placeholders: {
        enterYourWorkEmail: 'Inserisci la tua email di lavoro',
        createBotUsingBotPenguin: 'Eccitato! Crea bot usando botpenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Ecco il link unico al tuo bot di landing page.',
      shareWithUsersOnSocialMedia: 'Basta condividerlo con i tuoi utenti sui social media, le pagine di contatto o i biglietti da visita.',
      previewMyLandingPageBot: 'Anteprima la mia pagina di destinazione bot',
      or: 'O',
      copyUrl: 'Copia URL',
      howYouLikeToAddBot: 'Scegli come desideri aggiungere il tuo codice bot al tuo sito web',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Installa il bot sul tuo sito Web codificato o WordPress personalizzato eliminando un codice univoco.',
      name: 'Nome',
      actions: 'Azioni',
      javascript: 'JavaScript',
      install: 'Installare',
      onYourCustomCodedWebsite: ' sul tuo sito Web codificato personalizzato',
      copyAndPaste: 'Copia e incolla',
      copyThisCodeSnippet: 'Copia questo frammento di codice e inseriscilo nell`HTML di ogni pagina Web in cui si desidera visualizzare il widget.Per ulteriori dettagli',
      seeGuide: 'Vedi Guida',
      getCode: 'Ottenere il codice',
      howToInstall: 'Come installare?',
      copyCode: 'Codice di copia',
      wordpress: 'WordPress',
      // install: 'Installare',
      onYourWordPressWebiste: ' Sul tuo sito web di WordPress',
      preview: 'Anteprima',
      test: 'Test',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Anteprima della chat dal vivo',
    agentWindow: 'Finestra dell`agente',
    person: 'persona',
    inOrderToTestTypeHere: 'Per testare il tipo qui',
    agentWindowCannotBeCustomized: 'Nota: la finestra dell`agente non può essere personalizzata',
    customerWindow: 'Finestra del cliente',
    clickHereToChat: 'Clicca qui per chattare',
    customerWillChatHere: 'Il cliente chatterà qui',
    chat: 'Chiacchierata',
    by: 'di',
    changeYourBotLookFromLeftPanel: 'Cambia il tuo look bot dal pannello sinistro',
    placeholders: {
      typeYourMessage: 'Digita il tuo messaggio ...',
      typeYourAnswer: 'Scrivi la tua risposta',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Anteprima il tuo widget di chat live',
    install: 'Installare',
    seeHowToUse: 'Guarda come usare',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Fare clic per installare il tuo bot sulla piattaforma selezionata',
    }
  },
  trainYourBot: {
    trainYourBot: 'Allenare il tuo Chatbot',
    knowledgeBase: 'Base di conoscenza',
    faqs: 'Domande frequenti',
    history: 'Storia',
    faqHeading: 'Domande frequenti',
    faqSubHeading: 'Risposte alle tue domande sul training del Chatbot',
    knowledgeBaseHeading: 'Allenare il tuo Chatbot',
    knowledgeBaseSubheading: 'Allenare il tuo Chatbot per Conversazioni Migliorate',
    websiteLink: 'Link al Sito Web',
    uploadFile: 'Carica il tuo file',
    selectFile: 'Seleziona file',
    submit: 'Invia',
    searchHere: 'Cerca qui',
    question: 'Domanda',
    answer: 'Risposta',
    questionPlaceholder: 'Scrivi la tua domanda qui',
    answerPlaceholder: 'Scrivi la tua risposta qui',
    save: 'Salva',
    discard: 'Annulla',
    addFaq: 'Aggiungi FAQ',
    createdAt: 'Creato il',
    updatedAt: 'Ultimo aggiornamento il',
    actions: 'Azioni',
    noFaqFound: 'Non hai ancora creato nessuna FAQ',
    noFaqFoundMessage: 'Qui compariranno tutte le tue FAQ',
    historyTitle: 'Cronologia della base di conoscenza',
    historySubtitle: 'Risposte alle tue domande sul training del Chatbot',
    fileName: 'Nome del file',
    status: 'Stato',
    noHistoryTitle: 'Non hai ancora creato nessun modello addestrato',
    noHistoryDescription: 'Tutti i tuoi modelli addestrati appariranno qui',
    fileUpload: 'Carica file',
    files: 'File',
    fileSubHeading: 'Aggiungi tutti i file su cui desideri addestrare il tuo bot.',
    noFileFoundHeading: 'Non hai ancora creato nessun file',
    noFileFoundSubheading: 'Tutti i tuoi file appariranno qui',
    uploadYourFiles: 'Carica i tuoi files',
    upload: 'Carica',
    fileShouldBe: 'Il file deve essere in formato PDF, DOC, DOCX, PPTX, TXT.',
    uploadStatus: 'Stato di caricamento',
    uploadStatusSubHeading: 'Stato di caricamento dei file per l\'addestramento del bot',
    addFile: 'Aggiungi file',
    otherConfiguration: 'Altre configurazioni',
    deleteFiles: 'Clicca per eliminare i file',
    websiteURL: 'URL del sito web',
    websiteUrlSubheading: 'Aggiungi tutte le URL del sito web su cui desideri addestrare il tuo bot.',
    enterWebsiteUrl: 'Inserisci l\'URL',
    addWebsite: 'Aggiungi sito web',
    tableWebsiteUrl: 'URL del sito web',
    trainingStatus: 'Stato dell\'addestramento',
    lastTrained: 'Ultimo addestramento',
    noWebsitesFound: 'Non hai ancora creato alcun sito web',
    noWebsitesFoundMessage: 'Tutti i tuoi siti web appariranno qui',
    loadMore: 'Carica altro',
    botBehaviour: 'Configura il comportamento del bot',
    botBehaviourSubHeading: 'Configura la personalità del bot, redige i dati dei clienti, gestisce il flusso e il modello ChatGPT',
    botPersonality: 'Configura la personalità del bot',
    botPersonalitySubHeading: 'Gestisci come il tuo bot dovrebbe rispondere in termini di personalità',
    selectAnswerLength: 'Seleziona la lunghezza della risposta',
    answerLength: 'Lunghezza della risposta',
    answerAs: 'Risposta come',
    tone: 'Tono',
    language: 'Lingua',
    answerFormatting: 'Formattazione della risposta',
    customInstructions: 'Promemoria Personalizzato',
    customerInstructionPlaceholder: 'Sii educato e gentile. Non usare gergo. Non usare emoji.',
    redactCustomerData: 'Raccogli dati del cliente',
    redactDataSubheading: 'Configura le informazioni del cliente che desideri raccogliere',
    collectName: 'Raccogli il nome',
    configureQuestion: 'Configura la domanda',
    maxCharacterLimit: 'Limite massimo di caratteri nel nome fornito',
    collectEmail: 'Raccogli l\'email',
    excludeEmail: 'Escludi gli email con dominio',
    collectPhone: 'Raccogli il numero di telefono',
    manageUnusualMessages: 'Gestisci scenari di chat insoliti',
    unusualMessagesSubheading: 'Gestisci le situazioni insolite e senza risposta per il bot',
    startConversation: 'Messaggio per iniziare la conversazione',
    configureMessage: 'Messaggio quando il bot non è in grado di rispondere',
    profanityMessage: 'Messaggio per rispondere all\'uso di linguaggio inappropriato',
    chatGptModel: 'Configurazione del modello ChatGPT',
    chatGptModelSubheading: 'Gestisci quale modello ChatGPT utilizzare ed evitare abusi',
    chatGptApi: 'Chiave API di ChatGPT',
    enableDisableRedactData: 'Raccogli dati del cliente',
    triggerToCollectData: 'Raccogli i dettagli di contatto dopo una serie di domande.',
    train: 'Allenare',
    website: 'sito web',
    includedSourcesForResponse: 'Fonti incluse per la risposta',
    visibility: 'Visibilità',
    analytics: 'Analisi',
    yourConversation: 'Le tue conversazioni',
    reviewAndSelectConversation: 'Rivedi e seleziona le conversazioni da esportare per la formazione del chatbot',
    exportAiAnalytics: 'Esporta analisi',
    startDate: 'Data di inizio',
    endDate: 'Data di fine',
    userQuestion: 'Domanda dell\'utente',
    botsReply: 'Risposta del bot',
    dateCreated: 'Data di creazione',
    noAnalyticsFound: 'Non hai ancora creato alcuna analisi',
    noAnalyticsFoundMessage: 'Tutti i tuoi messaggi di analisi appariranno qui',
    exportData: 'Esporta dati',
    exportCompleteAnalyticsData: 'Esporta dati completi di analisi',
    export: 'Esporta',
    integrateWithChatGpt: 'Integra con ChatGPT',
    integrated: 'Integrato',
    configure: 'Configura',
    appToolTip: {
      deleteWebsite: 'Elimina il sito web',
      knowledgeBase: 'Allenare il tuo bot con la base di conoscenza',
      faqs: 'Allenare il tuo bot con le domande frequenti',
      knowledgeHistory: 'Cronologia della base di conoscenza',
      uploadFaqs: 'Carica le domande frequenti',
      addFaqs: 'Clicca per aggiungere domande frequenti',
      deleteFaqs: 'Elimina domande frequenti',
      deleteHistory: 'Elimina cronologia',
      uploadFile: 'Il file caricato deve essere in formato PDF, DOCX, DOC o CSV e avere una dimensione massima di 1 MB.',
      trainWebsite: 'Allenare il tuo bot utilizzando un sito web.',
      fileUpload: 'Allenare il tuo bot utilizzando file come PDF, TXT, PPTX, DOC e DOCX.',
      configureBotBehaviour: 'Personalizza la personalità del bot, gestisci il flusso e modifica il modello ChatGPT.',
      clickToAddWebsite: 'Fai clic per aggiungere un sito web',
      clickToDeleteWebsites: 'Fai clic per eliminare siti web',
      syncWebsite: 'Sincronizza il sito web',
      copyUrl: 'Copia URL',
      weWillBeFetchingAllTheWebpages: 'Recupereremo tutte le pagine web e addestreremo il modello ChatGPT su di esse.'
    },
    modal: {
      deleteWebsiteUrl: 'Elimina URL del sito web',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Sei sicuro di voler eliminare questa URL del sito web?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Impostazioni del chatbot del sito web',
    preview: 'Anteprima',
    test: 'Test',
    install: 'Installare',
    designHeader: 'Progetto',
    generalHeader: 'Generale',
    alertsHeader: 'Avvisi',
    triggerHeader: 'Grilletto',
    userAccessHeader: 'Accesso all`utente',
    chatWindowHeader: 'Finestra di chat',
    otherConfiguration: 'Impostazioni IA',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Fare clic per installare il tuo bot sulla piattaforma selezionata',
      customizeAppearanceOfBot: 'Personalizza il posizionamento e l`aspetto dell`interfaccia bot.',
      configureBotsSettingsWhenAgentUnavailable: 'Configurare le impostazioni di Bot quando il tuo agente non è disponibile.',
      configureHowBotWillAlertYou: 'Configura come il tuo bot ti avviserà di nuovi lead.',
      makeSureYourBotsPopUpProperlyTimed: 'Assicurati che i pop-up dei tuoi bot siano perfettamente cronometrati.',
      customizeWhereYouWantYourBotToAppear: 'Personalizza dove vuoi che appaia il tuo bot e dove no.',
      enableChatOptionsForVisitorsToTransferChat: 'Abilita le opzioni di chat per i tuoi visitatori di trasferire chat, chat dal vivo e aggiorna la chat.',
      configureBotBehaviour: 'Personalizza la personalità del bot, gestisci il flusso e modifica il modello ChatGPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Configurare le impostazioni di avviso del tuo bot',
      specifyHowYourBotWillNotify: 'Specifica come il tuo bot ti avviserà dei nuovi lead.',
      subject: 'Soggetto',
      emailNotification: 'notifica per email',
      emailAddresses: 'Indirizzi email',
      leadRevisitNotification: 'Lead Revisit Notifiche',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'Ti sei registrato con successo con le notifiche desktop',
      youHaveBlockedYourNotificationsOnBrowser: 'Hai bloccato notifiche sulle impostazioni del browser.',
      discard: 'Scartare',
      saveChanges: 'Salvare le modifiche',
      seeHowToUse: 'Guarda come usare',
      placeholders: {
        enterSubjectForYourEmail: 'Inserisci l`oggetto per la tua email',
        typeYourEmailAddressAndHitEnter: 'Digita il tuo indirizzo email e premi Invio',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Personalizza l`oggetto delle notifiche e - mail dal bot',
        TOOLTIP_EMAIL_NOTIFICATION: 'Abilita/disabilita la notifica del bot sulla tua e -mail per nuovi lead',
        TOOLTIP_EMAIL_ADDRESS: 'Invia l`indirizzo e - mail dell`account in cui si desidera ricevere notifiche e -mail. È possibile aggiungere più indirizzi e -mail inserendo uno e premendo Invio per aggiungerne un altro.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Abilita/disabilita la notifica bot sulla tua e -mail se un utente precedentemente catturato come lead interagisce di nuovo con il bot.',
      }
    },
    broadcasts: {
      createBroadcastHeading: 'Crea trasmissione',
      youCanCreateMultipleBroadcasts: 'Puoi creare più trasmissioni',
      broadcastName: 'Nome della trasmissione',
      broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
      continue: 'Continua',
      manageBroadcasts: 'Gestisci le trasmissioni',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'È possibile creare più trasmissioni con più modelli programmati per diversi Gruppi di clienti.',
      createNewBroadcast: 'Crea una nuova trasmissione',
      name: 'Nome',
      segment: 'Gruppo',
      template: 'Modello',
      contacts: 'Contatti',
      send: 'Inviare',
      delivered: 'Consegnato',
      read: 'Leggere',
      failed: 'Fallito',
      broadcastAt: 'Trasmesso a',
      status: 'Stato',
      actions: 'Azioni',
      nA: 'N / A',
      seeHowToUse: 'Guarda come usare',
      deleteBroadcast: 'Elimina la trasmissione',
      wantToDeleteThisBroadcast: 'Sei sicuro di voler eliminare questa trasmissione?',
      placeholders: {
        searchHere: 'Cerca qui',
        enterBroadcastName: 'Immettere il nome della trasmissione',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Elimina più trasmissioni',
        broadcastAnalytics: 'Analisi trasmessa',
        deleteBroadcast: 'Elimina la trasmissione',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Trasmesse approfondimenti',
        broadcastStatus: 'Stato di trasmissione',
        youCanFindDetailsOfEachContactBelow: 'Puoi trovare i dettagli di ciascun contatto di seguito ed esportare quelli che potrebbero avere problemi.',
        filterApplied: 'Filtro applicato',
        placeholders: {
          searchByPhoneNumber: 'Cerca per numero di telefono',
          searchByName: 'Cerca filtro per nome',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Filtro Stato basato sui dati',
          exportData: 'Dati di esportazione',
        },
        modal: {
          exportData: 'Dati di esportazione',
          exportCompleteBroadcastData: 'Esporta dati di trasmissione completi',
          export: 'Esportare',
        }
      },
      createBroadcast: {
        broadcastName: 'Nome della trasmissione',
        broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
        template: 'Modello',
        segments: 'Gruppi',
        whenToSend: 'Quando inviare',
        now: 'ORA',
        later: 'DOPO',
        selectBroadcastUsing: 'Seleziona la trasmissione utilizzando',
        usingCsv: 'Usando CSV',
        usingSegment: 'Usando il Gruppo',
        allContacts: 'Tutti i contatti',
        selectDate: 'Seleziona Data',
        selectTimeSlot: 'Seleziona Time Slot',
        assignVariablesToTemplates: 'Assegna variabili ai modelli',
        addHeaderMedia: 'Aggiungi supporti di intestazione',
        selectFile: 'Seleziona il file',
        cancel: 'Annulla',
        createBroadcast: 'Crea trasmissione',
        broadcastUsing: 'Trasmissione usando',
        selectCSVFile: 'Seleziona il file CSV',
        importContacts: 'Importa contatti',
        beforeYouStartUpload: 'Prima di iniziare il caricamento, assicurati',
        point1: '1. Scarica il file CSV di esempio.',
        point2: '2. Inizia a inserire i dati dalla seconda riga in poi.',
        point3: '3. In ogni riga, ci saranno dati per un contatto',
        point4: '4. Assicurarsi che tutti i numeri di telefono siano unici.',
        point5: '5. Il limite di caricamento della dimensione del file massima è 1 MB',
        // selectFile: 'Seleziona il file',
        clickHere: 'clicca qui',
        toDownloadSampleFile: 'Per scaricare il file di esempio',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          selectTemplate: 'Seleziona il modello',
          searchTemplateByName: 'Modello di ricerca per nome',
          selectSegments: 'Seleziona Gruppi',
          selectTimeSlot: 'Seleziona Time Slot',
          selectValueForThisVariable: 'Seleziona il valore per questa variabile',
          searchHeaderByVariableName: 'Cerca la variabile dell`intestazione per nome',
          variableFallbackValue: 'Valore di fallback variabile',
          searchBodyVariableByName: 'Cerca la variabile del corpo per nome',
          searchSegmentByName: 'Gruppo di ricerca per nome',
          selectBroadcastUsing: 'Seleziona la trasmissione utilizzando',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleziona Data di inizio',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Importa contatti per la trasmissione di whatsapp',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'Numero whatsapp',
        errorCode: 'Codice di errore',
        errorMessage: 'Messaggio di errore',
        nA: 'N / A',
      }
    },
    design: {
      customizeYourLandingPage: 'Personalizza la tua pagina di destinazione',
      customizeYourConversationalBot: 'Personalizza il tuo bot',
      customizeYourBot: 'Personalizza il tuo bot',
      createHeadingTextAndTheme: 'Crea testo e tema che riflette la personalità del tuo marchio',
      content: 'Contenuto',
      theme: 'Tema',
      layout: 'Disposizione',
      landingPageName: 'Nome della pagina di destinazione',
      botName: 'Nome bot',
      charactersRemaining: ' caratteri rimanenti',
      landingPageHeader: 'Testa di landing Page',
      landingPageDescription: 'Descrizione della pagina di destinazione',
      hideContent: 'Nascondere il contenuto',
      profilePic: 'Foto del profilo',
      selectFile: 'Seleziona il file',
      socialMediaLinks: 'Collegamenti sui social media',
      initialMessage: 'Messaggio iniziale del bot conversazionale',
      selectType: 'Selezionare il tipo',
      url: 'URL',
      delete: 'Eliminare',
      editLink: 'Modifica link',
      addLink: 'Aggiungi collegamento',
      callToAction: 'Chiamare all`azione',
      liveChatWelcomeMessage: 'Chat Live Charge Messaggio',
      liveChatTriggerMessage: 'Messaggio di trigger di chat dal vivo',
      autoHideCallToAction: 'Auto nascondi Call to Action',
      maxDelayShouldBe300Seconds: 'Il ritardo massimo dovrebbe essere di 300 secondi',
      landingPageThemeColor: 'Colore del tema pagina di landing page',
      themeColor: 'Colore del tema',
      landingPageBackground: 'Sfondo di landing page',
      chatBackgroundColor: 'Colore di sfondo della chat',
      color: 'Colore',
      gradient: 'Pendenza',
      image: 'Immagine',
      video: 'video',
      gradientStart: 'Inizio gradiente',
      gradientEnd: 'Fine gradiente',
      // selectFile: 'Seleziona il file',
      positionOnWeb: 'Posizione sul web',
      positionOnMobile: 'Posizione sul cellulare',
      windowSize: 'Dimensione della finestra',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'Xxl',
      custom: 'Costume',
      height: 'Altezza',
      minimum300HeightIsRequired: 'È richiesta un`altezza minima di 300px',
      heightCannotExceed720: 'L`altezza non può superare 720px',
      width: 'Larghezza',
      px: 'Px',
      minimum200WidthIsRequired: 'È richiesta una larghezza minima di 200px',
      widthCannotExceed1050: 'La larghezza non può superare i 1050px',
      discard: 'Scartare',
      saveChanges: 'Salvare le modifiche',
      landingPageBot: 'Bot di landing page',
      chatBot: 'Chatbot',
      preview: ' Anteprima',
      test: 'Test',
      botIcon: 'Icona bot',
      websiteBot: 'Chatbot del sito web',
      selectFont: 'Seleziona il carattere',
      fontLibrary: 'Libreria dei caratteri',
      uploadFont: 'Carica carattere',
      selectFontForBot: 'Seleziona il carattere per il bot',
      uploadedFont: 'Carattere caricato',
      flowTriggerDetail: 'Trigger per raccogliere i dettagli di contatto',
      placeholders: {
        enterName: 'Inserisci il nome',
        enterHeaderText: 'Immettere il testo dell`intestazione',
        enterDescription: 'Inserisci la descrizione',
        enterUrl: 'Inserisci l`URL',
        howCanIHelpYou: 'Come posso aiutarla?',
        enterYourWelcomeMessageHere: 'Inserisci il tuo messaggio di benvenuto qui',
        enterYourLiveChatMessageHere: 'Inserisci il tuo messaggio di chat dal vivo qui',
        enterTimeInSeconds: 'Inserisci il tempo in secondi',
        enterHeight: 'Inserisci l`altezza',
        enterWidth: 'Inserisci la larghezza',
        flowInitialMessage: 'Inserisci la domanda iniziale',
      },
      appTooltip: {
        standardLayout: 'Layout standard',
        rightLayout: 'Layout giusto',
        mergedLayout: 'Layout unito',
        enterNameOfYourBot: 'Inserisci il nome del tuo bot',
        enterNameOfYourLandingPageBot: 'Inserisci il nome del tuo bot di landing page',
        headingTextThatAppearOnLandingPageBot: 'Inserisci il testo dell`intestazione che vuoi apparire sul tuo bot di landing page',
        descriptionTextThatAppearOnLandingPageBot: 'Inserisci il testo di descrizione che si desidera apparire sul bot della pagina di destinazione.',
        addLinksAndSocialMediaIcons: 'Aggiungi collegamenti e icone di social media',
        initialFlowMessage: 'Inserisci il primo messaggio che il bot invierà per avviare la conversazione con il cliente',
        anInvitingCatchPhrase: 'Una frase di cattura invitante che farà parlare l`utente con Bot',
        setAWelcomeMessageForYourVisitors: 'Imposta un messaggio di benvenuto per i tuoi visitatori. Questo sarà il primo messaggio nella tua conversazione.',
        setAMessageToInitiateLiveChat: 'Imposta un messaggio per avviare la chat dal vivo dalla fine del visitatore. Riceverai una notifica ogni volta che questo messaggio viene attivato in una qualsiasi delle tue conversazioni',
        autoHideCallToAction: 'Auto nascondi il testo `Call to Action` dopo il tempo specificato',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Puoi scegliere di nascondere il logo e i messaggi per mostrare solo la finestra di chat sullo schermo',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Carica un logo per la pagina Chatbot.',
        TOOLTIP_CHAT_THEME_COLOR: 'Scegli il colore della tua finestra di chat di robot',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Scegli il colore di sfondo della finestra di chat bot',
        TOOLTIP_WEB_1ST_POSITION: 'Posiziona il tuo bot in basso a sinistra del desktop per utenti bot',
        TOOLTIP_WEB_2ND_POSITION: 'Posiziona il tuo bot nell`angolo centrale a destra del desktop per utenti bot',
        TOOLTIP_WEB_3RD_POSITION: 'Posiziona il tuo bot nell`angolo in basso a destra del desktop per utenti bot',
        TOOLTIP_MOBILE_1ST_POSITION: 'Posiziona il tuo bot nell`angolo in basso a sinistra degli utenti di bot mobile',
        TOOLTIP_MOBILE_2ND_POSITION: 'Posiziona il tuo bot nell`angolo centrale a destra degli utenti di bot mobile',
        TOOLTIP_MOBILE_3RD_POSITION: 'Posiziona il tuo bot nell`angolo in basso a destra degli utenti di bot mobile',
        TOOLTIP_WINDOW_HEIGHT: 'Ridimensiona la finestra di chat di robot',
        TOOLTIP_BOT_ICON: 'Seleziona un`icona o carica il logo / immagine per il tuo bot',
        flowTriggerMessage: 'Inserisci il numero di messaggi da chiedere prima di raccogliere i dettagli di contatto come nome, email e telefono',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Crea una campagna gocciolante',
      youCanCreateMultipleCampaigns: 'Puoi creare più campagne',
      dripCampaignName: 'Nome della campagna gocciolante',
      dripCampaignNameIsReuired: 'È richiesto il nome della campagna gocciolante',
      continue: 'Continua',
      manageDripCampaign: 'Gestisci la campagna gocciolante',
      youCanCreateMultipleCampaignsWith: 'È possibile creare più campagne di gocciolamento con messaggi pianificati e automatizzati per attivare risposte da specifici Gruppi utente.',
      createNewDripCampaign: 'Crea una nuova campagna DRIP',
      campaignName: 'Nome della campagna',
      appliedOn: 'Applicato su',
      segment: 'Gruppo',
      template: 'Modello',
      startDate: 'Data d`inizio',
      action: 'Azione',
      nA: 'N / A',
      seeHowToUse: 'Guarda come usare',
      deleteDripCampaign: 'Elimina campagne gocciolanti',
      wantToDeleteThisDripCampaign: 'Sei sicuro di voler eliminare queste campagne gocciolanti?',
      placeholders: {
        enterDripCampaignName: 'Immettere il nome della campagna gocciolante',
        searchByName: 'Ricerca per nome',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Elimina più campagne di gocciolamento',
        deleteDripCampaign: 'Elimina la campagna gocciolante',
      },
      createDripCampaign: {
        dripCampaignName: 'Nome della campagna gocciolante',
        appliedOn: 'Applicato su',
        segments: 'Gruppi',
        selectDate: 'Seleziona Data',
        scheduleYourTemplate: 'Pianifica i tuoi modelli',
        schedule: 'Programma',
        template: 'Modello',
        sendAfter: 'Invia dopo',
        assignVariablesToTemplate: 'Assegna variabili ai modelli',
        addHeaderMedia: 'Aggiungi supporti di intestazione',
        selectFile: 'Seleziona il file',
        createDripCampaign: 'Crea una campagna gocciolante',
        newSubscriber: 'Nuovo abbonato',
        minutes: 'Minuti',
        hours: 'Ore',
        days: 'Giorni',
        placeholders: {
          enterDripName: 'Immettere il nome della goccia',
          selectAppliedOn: 'Seleziona applicato su',
          selectSegments: 'Seleziona Gruppi',
          searchByName: 'Ricerca per nome',
          selectTemplate: 'Seleziona il modello',
          searchTemplateByName: 'Modello di ricerca per nome',
          enterSendAfter: 'Inserisci Invia dopo',
          selectValueForThisVariable: 'Seleziona il valore per questa variabile',
          searchHeaderVariableByName: 'Cerca la variabile dell`intestazione per nome',
          searchBodyVariableByName: 'Cerca la variabile del corpo per nome',
        },
        dateTimePicker: {
          selectStartDate: 'Seleziona Data di inizio',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Configurare le impostazioni generali della pagina di destinazione',
      configureBotsGeneralSettings: 'Configurare le impostazioni generali del tuo bot',
      selectTheTimeZone: 'Seleziona la zona temporale, la lingua e una piccola nota per i tuoi clienti quando il',
      isNotAvailable: ' Non è disponibile',
      landingPagesBot: 'Pagine di atterraggio Bot',
      bot: 'bot',
      waitingMessage: 'Messaggio di attesa',
      landingPageBotStatus: 'Pagina di landing Status di bot',
      chatBotStatus: 'Stato di chatbot',
      unavailabilityMessage: 'Messaggio di disponibilità non-disponibilità',
      sendIncompleteResponses: 'Invia risposte incomplete',
      language: 'Lingua',
      invalidateCache: 'Cache invalidata',
      timezone: 'Fuso orario',
      chatWindowMessageHistory: 'Cronologia dei messaggi della finestra di chat',
      session: 'sessione',
      forever: 'per sempre',
      english: 'Inglese',
      spanish: 'spagnolo',
      french: 'francese',
      portuguese: 'portoghese',
      german: 'Tedesco',
      italian: 'Italiano',
      swedish: 'svedese',
      arabic: 'Arabo',
      malay: 'malese',
      russian: 'russo',
      discard: 'Scartare',
      saveChanges: 'Salvare le modifiche',
      seeHowToUse: 'Guarda come usare',
      placeholders: {
        enterYourWaitingMessageHere: 'Inserisci il tuo messaggio in attesa qui',
        // selectLanguage: ',',
        searchTimeZone: 'Cerca il fuso orario',
        // selectChatRetentionPolicy: ',',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalizza il messaggio come desideri che venga visualizzato sulla finestra della chat immediatamente dopo che un utente di bot invia una richiesta di chat live',
        TOOLTIP_CHATBOT_STATUS: 'Attivare /disattivare il bot',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Personalizza il messaggio come desideri che appaia sulla finestra della chat quando nessuno dei tuoi agenti è disponibile per la chat dal vivo con l`utente del bot',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Invia le notifiche e -mail anche quando l`utente del bot non ha terminato la conversazione.',
        TOOLTIP_LANGUAGE: 'Seleziona la lingua bot',
        TOOLTIP_INVALIDATE_CACHE: 'Fai clic qui per cancellare la cache come e quando si modifica il flusso di chat bot o le impostazioni mentre le modifiche si riflettono solo dopo 15 minuti',
        TOOLTIP_TIME_ZONE: 'Seleziona la zona temporale per il tuo bot',
        TOOLTIP_CHAT_RETENTION: 'Seleziona se si desidera conservare le chat dell`utente bot per sempre nella finestra della chat o solo per una sessione del browser.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Personalizza la finestra della tua chat',
      controlAndCustomizeTheChatWindow: 'Controlla e personalizza la finestra di chat con cui i clienti interagiranno.',
      allowVisitorsToTransferChatToWhatsapp: 'Consenti ai visitatori di trasferire la chat a WhatsApp',
      allowVisitorsToRefreshChat: 'Consenti ai visitatori di aggiornare la chat',
      displayNameForTransferringChatToWhatsapp: 'Nome visualizza per il trasferimento di una chat a WhatsApp',
      displayNameForTransferringToLiveChat: 'Nome visualizzato per il trasferimento in chat live/agente',
      displayNameForTransferringAChatToBot: 'Nome visualizza per il trasferimento di una chat a Bot',
      discard: 'Scartare',
      saveChanges: 'Salvare le modifiche',
      allowVisitorsToTransferChatToBot: 'Consenti ai visitatori di trasferire la chat a Bot.',
      allowVisitorsToRequestForLiveChat: 'Consenti ai visitatori di richiedere chat dal vivo.',
      indicativeTextForCustomersReply: 'Testo indicativo per la risposta del cliente',
      displayNameForRestartingChatBot: 'Visualizza nome per il riavvio della conversazione di chatbot',
      seeHowToUse: 'Guarda come usare',
      placeholders: {
        enterButtonNameForWhatsapp: 'Inserisci il nome del pulsante per il trasferimento a WhatsApp',
        enterButtonNameForLiveChat: 'Immettere il nome del pulsante per il trasferimento in chat o agente in diretta',
        enterButtonNameForBot: 'Inserisci il nome del pulsante per il trasferimento in bot',
        enterTextDisplayInEmptyReplyArea: 'Immettere il testo visualizzato nell`area di risposta vuota',
        enterButtonForRestartingConversation: 'Inserisci il nome del pulsante per il riavvio della conversazione',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Consenti ai visitatori del sito Web di trasferire la chat a WhatsApp n. del rispettivo agente a cui sono collegati. La funzione di chat live deve essere abilitata per funzionare questa funzione.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Consenti agli utenti di bot di aggiornare la chat in modo che possano ricominciare' +
          ' da capo.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Consenti agli utenti del bot di trasferire la chat a Bot in qualsiasi' +
          ' momento tra la conversazione con l`agente di chat dal vivo.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Consenti agli utenti di bot di richiedere chat dal vivo con l`agente ogni' +
          ' volta che lo desiderano.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Se hai abilitato l`utente a trasferire a WhatsApp, riempi come si desidera visualizzare quell`opzione',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Se hai abilitato all`utente di trasferire in chat live, riempi come si desidera visualizzare quell`opzione',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Se hai abilitato l`utente a trasferire a WhatsApp, riempi come si desidera trasferire su Bot',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Riempire come vorresti visualizzare lo spazio di risposta per i clienti',
        ifYouHaveEnabledUserToRestartChatBot: 'Se hai abilitato all`utente di riavviare la conversazione di chatbot, riempi come si desidera visualizzare quell`opzione',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Flussi di chat',
      dripCampaignHeader: 'Campagna gocciolante',
      widgetsHeader: 'Widget',
      broadcastHeader: 'Trasmissione',
      ordersHeader: 'Ordini',
      manageHeader: 'Maneggio',
      inputHeader: 'Ingresso',
      apiKeyHeader: 'Chiave API',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Crea un percorso di onboarding del cliente utilizzando una campagna gocciolante di flussi di chat programmati.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizza i modelli di flusso di chat e crea più flussi di chat per un pubblico target diverso.',
        TOOLTIP_WIDGET_TITLE: 'Crea widget (interfacce di chat) e incorporarli nelle pagine Web promozionali per indirizzare i visitatori al bot',
        TOOLTIP_BROADCAST_TITLE: 'Blast Messaggi o fluisce sui contatti con un singolo clic al tempo pre-schedato',
        TOOLTIP_ORDERS_TITLE: 'Elenco degli ordini effettuati dal cliente',
        TOOLTIP_MANAGE_TITLE: 'Gestione della funzionalità del bot per contatti/lead e parole chiave',
        TOOLTIP_INPUT_TITLE: 'Crea modelli WhatsApp, immagini e testo come materiale di risposta per contatti/lead',
      },
      apiWrapper: {
        whatsappApiCredentials: 'Credenziali API di WhatsApp',
        generateYourWhatsappApiKey: 'Genera la chiave API WhatsApp per integrare con le app sviluppate personalizzate.',
        generateApiKey: 'Genera la chiave API',
        webhookUrl: 'Webhook URL',
        addHeader: 'Aggiungi intestazione',
        test: 'Test',
        webhookNotSet: 'Webhook non impostato',
        webhookHeaders: 'Webhook Headers',
        setWebhookUrl: 'Imposta URL Webhook',
        placeholders: {
          yourApiKey: 'La tua chiave API',
          enterWebhookUrl: 'Inserisci l`URL Webhook',
          enterHeaderKey: 'Immettere la chiave di intestazione',
          enterValue: 'Immettere il valore',
        }
      },
      broadcast: {
        createBroadcast: 'Crea trasmissione',
        createMultipleBroadcasts: 'È possibile creare più trasmissioni con più flussi/modelli pianificati per diversi gruppi di clienti.',
        broadcastName: 'Nome della trasmissione',
        nameIsRequired: 'È richiesto il nome',
        selectType: 'Selezionare il tipo',
        selectYourFlowType: 'Seleziona il tuo tipo di flusso',
        segment: 'Gruppo',
        selectYourSegmentType: 'Seleziona il tuo tipo di gruppo',
        whenToSend: 'Quando inviare',
        selectDate: 'Seleziona Data',
        messageTag: 'Tag del messaggio',
        selectMessageTag: 'Seleziona il tag messaggio',
        contentType: 'Tipo di contenuto',
        nonPromotional: 'Non promozionale (tag messaggi applicati)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Tag ogni messaggio che viene prima di qualsiasi interazione o dopo passaggi di ritardo superiori a 24 ore con tag appropriato. Il messaggio senza tag non raggiungerà gli abbonati che hanno interagito con il bot oltre 24 ore fa',
        promotional: 'Promozionale (tag messaggi ignorati)',
        theseBroadcasts: 'Queste trasmissioni possono contenere promozioni, ma il pubblico target è limitato agli abbonati che hanno interagito con il tuo bot nelle ultime 24 ore.',
        createNewBroadcast: 'Crea una nuova trasmissione',
        sentMessages: 'Messaggi inviati',
        deliveredMessages: 'Messaggi consegnati',
        readMessages: 'Leggi i messaggi',
        failedMessages: 'Messaggi non riusciti',
        // createNewBroadcast: 'Crea una nuova trasmissione',
        type: 'Tipo',
        template: 'Modello',
        sent: 'Inviato',
        delivered: 'Consegnato',
        read: 'Leggere',
        failed: 'Fallito',
        broadcastAt: 'Trasmesso a',
        status: 'Stato',
        shipmentUpdate: 'Aggiornamento della spedizione',
        // template: 'Modello',
        flow: 'Fluire',
        resendFailedMessages: 'Resendisci messaggi non riusciti',
        // sentMessages: 'Messaggi inviati',
        // deliveredMessages: 'Messaggi consegnati',
        goBack: 'Torna indietro',
        number: 'Numero',
        errorCode: 'Codice di errore',
        failedAt: 'Fallito a',
        badGateway: 'errore di connessione 502 Bad Gateway',
        loadMore: 'Carica di più',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          searchBroadcastByName: 'Cerca trasmissione per nome',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Messaggi di trasmissione totale inviati',
          totalBroadcastMessagesDelivered: 'Messaggi di trasmissione totali consegnati',
          totalBroadcastMessagesReadByContacts: 'Messaggi totali di trasmissione letti dai contatti',
          totalFailedBroadcastMessages: 'Messaggi di trasmissione totale non riusciti',
          clickToViewDetailedInformationOfFailedMessages: 'Fare clic per visualizzare le informazioni distese dei messaggi non riusciti',
          clickToCreateNewBroadcast: 'Fai clic per creare una nuova trasmissione',
          templateUsedInBroadcast: 'Modello utilizzato nella trasmissione',
          numberOfBroadcastMessagesSent: 'N. di messaggi di trasmissione inviati',
          numberOfBroadcastMessagesDelivered: 'N. di messaggi di trasmissione consegnati',
          numberOfBroadcastMessagesReadByContacts: 'N. di messaggi di trasmissione letti dai contatti',
          numberOfTimesBroadcastMessagesHasFailed: 'Il numero di tempi di trasmissione della messaggistica è fallita',
          contactNumberOnWhichMessageDeliveryFailed: 'Numero di contatto su quale consegna del messaggio non è riuscito',
          unDeliveredTemplate: 'Modello non consegnato',
          errorReceivedForFailedMessage: 'Errore ricevuto per il messaggio non riuscito',
          timeWhenMessageDeliveryFailed: 'Tempo in cui la consegna dei messaggi non è riuscita',
          TOOLTIP_ADD_BROADCAST_NAME: 'Immettere un nuovo nome di trasmissione',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Seleziona se stai trasmettendo un flusso o un messaggio.',
          TOOLTIP_BROADCAST_FLOW: 'Seleziona il flusso di chat che desideri trasmettere,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Seleziona il gruppo dei contatti a cui si desidera trasmettere',
          TOOLTIP_BROADCAST_NAME: 'Nome della trasmissione',
          TOOLTIP_BROADCAST_TYPE: 'Flusso o messaggio selezionato per la trasmissione',
          TOOLTIP_BROADCAST_SEGMENT: 'Gruppo per il quale viene creata la trasmissione',
          TOOLTIP_BROADCAST_AT: 'Data e ora prevista per la trasmissione',
          TOOLTIP_BROADCAST_STATUS: 'Stato di trasmissione come previsto, attualmente in esecuzione o completato',
        }
      },
      drip: {
        createDripCampaign: 'Crea una campagna gocciolante',
        youCanCreateMultipleCampaigns: 'Puoi creare più campagne',
        dripCampaignName: 'Nome della campagna gocciolante',
        dripCampaignNameIsRequired: 'È richiesto il nome della campagna gocciolante',
        // createDripCampaign: 'Crea una campagna gocciolante',
        youCanCreateMultipleDripCampaigns: 'È possibile creare più campagne di gocciolamento con messaggi pianificati e automatizzati per attivare risposte da specifici gruppi utente.',
        campaignName: 'nome della campagna',
        campaignNameIsRequired: 'È richiesto il nome della campagna',
        after: 'Dopo',
        thisMessageWillBeSendAfter: 'Questo messaggio verrà inviato dopo',
        saveAndUpdate: 'Salva e aggiorna',
        schedule: ' Programma',
        select: 'Selezionare',
        messages: 'Messaggi',
        cancel: 'Annulla',
        createCampaign: 'Crea una campagna',
        updateCampaign: 'Campagna di aggiornamento',
        createNewCampaign: 'Crea nuova campagna',
        // campaignName: 'nome della campagna',
        messagesSent: 'Messaggi inviati',
        createdAt: 'Creato a',
        updatedAt: 'Aggiornato a',
        action: 'Azione',
        placeholders: {
          nameYourDripCampaign: 'Nomina la tua campagna DRIP',
          enterCampaignName: 'Immettere il nome della campagna',
          searchCampaignByName: 'Campagna di ricerca per nome',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Fare clic per aggiungere una nuova campagna',
          TOOLTIP_SCHEDULE: 'Pianifica quando e quale flusso di chat per inviare per primo in questa campagna.',
          TOOLTIP_AFTER_DAYS: 'Pianifica quando inviare il primo flusso della campagna in minuti, ore o giorni.',
          TOOLTIP_ADD_MESSAGES: 'Fare clic per aggiungere un nuovo flusso alla campagna',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Immettere un nuovo nome da campagna',
          TOOLTIP_CAMPAIGN_NAME: 'Nome della campagna',
          TOOLTIP_NO_MESSAGES_SENT: 'No. di messaggi inviati dalla campagna',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Data e ora della creazione della campagna',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Data e ora in cui la campagna è stata modificata l`ultima volta',
          TOOLTIP_CAMPAIGN_ACTION: 'Modifica o elimina la campagna',
          TOOLTIP_UPDATE_CAMPAIGN: 'Fare clic per salvare le modifiche apportate nella configurazione della campagna',
        }
      },
      flows: {
        createChatFlow: 'Crea flusso di chat',
        youCanCreateMultipleConversationsFlow: 'È possibile creare più flussi di conversazioni su più canali per test A/B e trasmissioni.',
        flowName: 'Nome del flusso',
        nameIsRequired: 'È richiesto il nome',
        cancel: 'Annulla',
        createFlow: 'Crea flusso',
        createNewFlow: 'Crea nuovo flusso',
        // flowName: 'Nome del flusso',
        ofMessages: 'di messaggi',
        triggered: 'Innescato',
        stepsFinsished: 'Passi finiti',
        finished: 'Finito',
        createdOn: 'Creato',
        lastModified: 'Ultima modifica',
        defaultFlow: 'Flusso predefinito',
        action: 'Azione',
        flowAnalytics: 'Analisi del flusso',
        placeholders: {
          enterFlowName: 'Immettere il nome del flusso',
          searchFlowByName: 'Flusso di ricerca per nome',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'No. di volte il flusso di chat viene attivato',
          numberOfCompletedChatFlowStepsWhenTriggered: 'N. delle fasi del flusso di chat completate quando viene attivato',
          numberOfTimesUsersCompletedChatFlowSteps: 'N. di volte gli utenti hanno completato tutte le fasi del flusso di chat',
          dateAndTimeOfCreationOfChatFlow: 'Data e ora della creazione del flusso di chat',
          dateAndTimeWhenChatFlowLastModified: 'Data e ora in cui il flusso di chat è stato modificato l`ultima volta',
          TOOLTIP_CREATE_FLOW: 'Fare clic per creare un nuovo flusso di chat',
          TOOLTIP_FLOW_NAME: 'Immettere il nome del flusso di chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome del flusso di chat',
          TOOLTIP_NO_OF_MESSAGES: 'N. di componenti del messaggio nel flusso di chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilizzare questo intervento per impostare un flusso di chat come predefinito, che verrà servito a tutti i nuovi utenti.',
          TOOLTIP_ACTION: 'Modifica o elimina il flusso di chat',
        }
      },
      manage: {
        leads: 'Conduce',
        manageBotsFunctionalityForContacts: 'Gestisci la funzionalità di Bot per i contatti/lead',
        whenToSendLeadInfo: 'Quando inviare informazioni sul lead',
        smartFlow: 'Flusso intelligente',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Messaggio di benvenuto quando non viene soddisfatta nessuna ricerca di parole chiave',
        selectTemplates: 'Seleziona modelli',
        selectFlows: 'Seleziona i flussi',
        selectText: 'Seleziona il testo',
        selectImages: 'Seleziona immagini',
        whenItAlmostReaches24HoursSinceMessage: 'Quando raggiunge quasi 24 ore dall`ultimo messaggio',
        // selectTemplates: 'Seleziona modelli',
        updateChanges: 'Aggiorna le modifiche',
        manageBotsFunctionalityAccordingToKeywords: 'Gestisci la funzionalità di Bot in base alle parole chiave input da parte dell`utente del bot, in caso di flusso intelligente',
        addKeywordAction: 'Aggiungi azione di parole chiave',
        keywords: 'Parole chiave',
        matchingMethod: 'Metodo di abbinamento',
        replyMaterial: 'Materiale di risposta',
        action: 'Azione',
        select: 'Selezionare',
        placeholders: {
          keyword: 'parola chiave',
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Suggerire se Bot per inviare informazioni di contatto/lead all`integrazione CRM immediatamente o dopo 5 minuti o alla fine del flusso',
          TOOLTIP_LEAD_SMART_FLOW: 'Scegli qualsiasi flusso predefinito per inviarlo a tutti gli utenti del bot o Smart Flow (determinato dall`input delle parole chiave dall`utente del bot).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Seleziona Materiale di risposta (flusso, modello, testo, immagine) da inviare se Bot non riceve una parola chiave corrispondente dalla risposta dell`utente del bot',
          TOOLTIP_IT_ALMOST_REACHED: 'Seleziona Materiale di risposta da inviare a contatti/lead appena prima di 24 ore di ultima messaggistica',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Gestisci la funzionalità di Bot in base alle parole chiave input da parte dell`utente del bot, in caso di flusso intelligente',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Seleziona la risposta bot come modello, flusso, testo o immagine contro le parole chiave specifiche input',
          TOOLTIP_ADD_KEYWORD: 'Aggiungi parole chiave',
          TOOLTIP_MATCHING_METHOD: 'Aggiungi le parole chiave corrispondente come esatto o contiene',
          TOOLTIP_REPLY_MATERIAL: 'Seleziona modello, flusso, testo o immagine per rispondere alla parola chiave suggerita',
          TOOLTIP_KEYWORD_ACTIONS: 'Elimina l`azione della parola chiave',
        },
        socialAlerts: {
          alerts: 'Avvisi',
          specifyHowYourBotWillNotify: 'Specifica come il tuo bot ti avviserà dei nuovi lead.',
        }
      },
      orders: {
        manageCustomersOrder: 'Gestisci gli ordini dei clienti',
        checkAndManageAllOfYourCustomerOrders: 'Controlla e gestisci tutti gli ordini e gli aggiornamenti dei clienti.',
        selectShipmentTemplate: 'Seleziona il modello di spedizione',
        send: 'Inviare',
        export: 'Esportare',
        phoneNumber: 'Numero di telefono',
        numberOfItems: 'Numero di articoli',
        orderValue: 'Valore dell`ordine',
        orderDate: 'Data dell`ordine',
        status: 'Stato',
        chat: 'Chiacchierata',
        item: 'Articolo',
        quantity: 'Quantità',
        amount: 'Quantità',
        unitPrice: 'Prezzo unitario',
        placeholders: {
          searchOrdersByPhoneNumber: 'Ordini di ricerca per numeri di telefono',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Export ordina i dati nel file CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'Numero di telefono del cliente che ha effettuato l`ordine',
          TOOLTIP_NO_OF_ITEMS: 'N. di articoli ordinati',
          TOOLTIP_ORDER_VALUE: 'Valore dell`ordine',
          TOOLTIP_ORDER_DATE: 'L`ordine è stato effettuato in questa data.',
          TOOLTIP_ORDER_STATUS: 'Stato dell`ordine come confermato, in sospeso, rifiutato.o spedito',
          TOOLTIP_ORDER_CHAT: 'Tutte le precedenti conversazioni di chat riguardanti questo ordine',
        }
      },
      widgets: {
        installWidgetHeader: 'Installa widget',
        createWidgetHeader: 'Crea widget',
        copyTheCodeAndPasteItWhereYouWant: 'Copia il codice e incollalo dove hai bisogno nel tuo html, sito Web ecc.',
        youCanCreateMultipleWidgets: 'Puoi creare più widget per dare al tuo bot un aspetto diverso su diverse pagine Web.',
        installWidget: {
          javascript: 'JavaScript',
          install: 'Installare',
          onYourWebsite: ' sul tuo sito web',
          copyCode: 'Codice di copia',
          copyAndPaste: 'Copia e incolla',
          copyThisCodeSnippet: 'Copia questo frammento di codice e inseriscilo nell`HTML di ogni pagina Web in cui si desidera',
          seeGuide: 'Vedi Guida',
        },
        widgetList: {
          widgetName: 'Nome del widget',
          nameIsRequired: 'È richiesto il nome',
          cancel: 'Annulla',
          createWidget: 'Crea widget',
          icon: 'Icona',
          name: 'Nome',
          createdAt: 'Creato a',
          action: 'Azione',
          placeholders: {
            enterWidgetName: 'Immettere il nome del widget',
            searchWidgetByName: 'Widget di ricerca per nome',
          },
          appTooltip: {
            widgetIcon: 'Icona del widget',
            nameOfWidget: 'Nome del widget',
            dateAndTimeOfCreationOfWidget: 'Data e ora della creazione del widget',
            editOrDeleteWidget: 'Modifica o elimina il widget',
          }
        },
        widgetTemplates: {
          widgetName: 'Nome del widget',
          nameIsRequired: 'È richiesto il nome',
          chooseTypeOfWidget: 'Scegli il tipo di widget',
          bar: 'Sbarra',
          slideIn: 'Scivolare dentro',
          pageTakeOver: 'Pagina Takeover',
          button: 'Pulsante',
          facebookModal: 'Facebook Modal',
          comment: 'Commento',
          direct: 'Diretto',
          whatsappIcon: 'Icona WhatsApp',
          refUrl: 'REF URL',
          qrCode: 'QR Code',
          // refUrl: 'REF URL',
          backgroundColor: 'Colore di sfondo',
          descriptionColor: 'Descrizione Colore',
          headlineColor: 'Colore principale',
          buttonBackgroundColor: 'Colore di sfondo del pulsante',
          contactNumber: 'Numero di contatto',
          entryMessage: 'Messaggio di iscrizione',
          buttonText: 'Testo del pulsante',
          selectButtonText: 'Seleziona il testo del pulsante',
          buttonSize: 'Dimensione del pulsante',
          selectOne: 'Seleziona uno',
          placement: 'Posizionamento',
          rightBottom: 'In basso a destra',
          optInFlow: 'Opt-in Flow',
          selectFlow: 'Seleziona il flusso',
          addSubscriberToSequence: 'Aggiungi abbonato alla sequenza',
          selectDrip: 'Seleziona gocciolamento',
          whenDoesItDisplay: 'Quando viene visualizzato (secondi)',
          showWidgetToSameUserAgainAfter: 'Mostra di nuovo il widget allo stesso utente dopo',
          ifManuallyClosedByUser: 'Se chiuso manualmente dall`utente, mostra dopo',
          // comment: 'Commento',
          specificPosts: 'Post specifici',
          allPosts: 'Tutti i post',
          // entryMessage: 'Messaggio di iscrizione',
          postMessage: 'Messaggio post',
          postDate: 'Data di pubblicazione',
          status: 'Stato',
          action: 'Azione',
          discard: 'Scartare',
          saveChanges: 'Salvare le modifiche',
          nextStep: 'Passo successivo',
          widgetDescription: 'Descrizione del widget',
          integrateTheBarWidget: 'Integra il widget a barre nel corpo del tuo sito Web e aumenta l`abbonato della tua pagina insieme all`invio di un flusso di messaggi bot personalizzati.',
          hereIsYourWidgetHeadline: 'Ecco il tuo titolo di widget, fai clic qui per cambiarlo.',
          sendUsMessage: 'Inviaci messaggio',
          weAlsoPutDefaultTextHere: 'Abbiamo anche messo qui il testo predefinito.',
          selectYourPagePost: 'Seleziona il tuo post di pagina',
          nA: 'N / A',
          // entryMessage: 'Messaggio di iscrizione',
          saveRule: 'Salva regola',
          placeholders: {
            enterWidgetName: 'Immettere il nome del widget',
            enterNumber: 'Inserisci numero',
            enterMessageHere: 'Inserisci il messaggio qui',
            enterTimeInSeconds: 'Inserisci il tempo in secondi',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'Personalizza le impostazioni del trigger del tuo bot',
      makeYourBotsTimingPerfect: 'Rendi perfetta i tempi del tuo bot.',
      onDesktopTriggerTime: 'Tempo di trigger di Desktop (secondi)',
      onMobileTriggerTime: 'Tempo di trigger on-mobile (secondi)',
      chatBubble: 'Apertura automatica della bolla di chat',
      seconds: 'Secondi',
      discard: 'Scartare',
      saveChanges: 'Salvare le modifiche',
      autoTriggerOnOrOff: 'Trigger automatico: Accensione/OFF',
      seeHowToUse: 'Guarda come usare',
      alwaysOpenAfter: 'Apri sempre dopo i trigger sopra specificati',
      automaticallyAfterSelectedNumberOfTime: 'Automaticamente dopo la quantità di tempo selezionata',
      neverAutomaticallyOpenBot: 'Non aprire mai automaticamente il bot',
      placeholders: {
        enterTimeInSeconds: 'Inserisci il tempo in secondi',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Tempo di attesa in secondi, sui dispositivi desktop' +
          ' dei visitatori, prima che appaia automaticamente',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Tempo di attesa in secondi, sul cellulare dei visitatori, prima che si apra automaticamente',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Quando il visitatore chiude la finestra di chat, selezionare come sarà il comportamento pop-up di bot auto',
        customerWaitingTimeToOpenBubble: 'Tempo di attesa del cliente per aprire la bolla di chat',
        wantToDisableAutoTriggering: 'Fare clic se si desidera disabilitare l`attivazione automatica di bot su pagine Web',
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'Personalizza le impostazioni dell`utente del tuo bot',
      decideWhoCanAccessYourBot: 'Decidi chi può accedere al tuo bot',
      deviceSupport: 'Supporto dispositivo',
      blockIpAddresses: 'Blocca gli indirizzi IP',
      hideChatBotOnSpecificPages: 'Nascondere il chatbot su pagine specifiche',
      selectCountriesToEnableBot: 'Seleziona i paesi per abilitare il bot',
      discard: 'Scartare',
      saveChanges: 'Salvare le modifiche',
      seeHowToUse: 'Guarda come usare',
      allDevices: 'Tutti i dispositivi',
      mobileOnly: 'Solo mobile',
      desktopOnly: 'Solo desktop',
      placeholders: {
        enterIpAddress: 'Immettere l`indirizzo IP(IPv4 o IPv6) e premere `Enter`',
        enterUrl: 'Immettere l`URL delle pagine e premere `Immettere`',
        searchCountry: 'Cerca paese',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Seleziona su quali dispositivi dovrebbe apparire il tuo bot',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Inserisci gli indirizzi IP in cui non desideri che il tuo bot appaia',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Inserisci l`URL delle tue pagine Web in cui non desideri che il tuo bot appaia',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Seleziona le posizioni da dove desideri che gli utenti accedano al tuo bot',
      }
    },
    facebook: {
      facebookBotSettings: 'Impostazioni di bot di Facebook',
      chatFlowsHeader: 'Flussi di chat',
      dripCampaignHeader: 'Campagna gocciolante',
      broadcastHeader: 'Trasmissione',
      widgetsHeader: 'Widget',
      autoReplyHeader: 'Risposta automatica',
      generalHeader: 'Generale',
      facebookAutomation: 'Automazione di Facebook',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizza i modelli di flusso di chat e crea più flussi di chat per un pubblico target diverso.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Crea un percorso di onboarding del cliente utilizzando una campagna gocciolante di flussi di chat programmati.',
      },
      broadcasts: {
        createBroadcast: 'Crea trasmissione',
        youCanCreateMultipleBroadcasts: 'Puoi creare più trasmissioni',
        broadcastName: 'Nome della trasmissione',
        broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
        continue: 'Continua',
        manageBroadcasts: 'Gestisci le trasmissioni',
        youCanCreateMultipleBroadcastsWith:
          'È possibile creare più trasmissioni con più modelli programmati per diversi gruppi di clienti.',
        createNewBroadcast: 'Crea una nuova trasmissione',
        name: 'Nome',
        segment: 'Gruppo',
        template: 'Modello',
        contacts: 'Contatti',
        send: 'Inviare',
        failed: 'Fallito',
        broadcastAt: 'Trasmesso a',
        status: 'Stato',
        actions: 'Azioni',
        nA: 'N / A',
        deleteBroadcast: 'Elimina la trasmissione',
        wantToDeleteThisBroadcast: 'Sei sicuro di voler eliminare questa trasmissione?',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          searchHere: 'Cerca qui',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Elimina più trasmissioni',
          deleteBroadcast: 'Elimina la trasmissione',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Nome della trasmissione',
        broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
        flow: 'Fluire',
        segments: 'Gruppi',
        whenToSend: 'Quando inviare',
        selectDate: 'Seleziona Data',
        selectTimeSlot: 'Seleziona Time Slot',
        createBroadcast: 'Crea trasmissione',
        cancel: 'Annulla',
        now: 'Ora',
        later: 'Dopo',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          selectFlow: 'Seleziona il flusso',
          searchFlowByName: 'Flusso di ricerca per nome',
          selectSegments: 'Seleziona gruppi',
          searchSegmentByName: 'Gruppo di ricerca per nome',
          selectTimeSlot: 'Seleziona Time Slot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleziona Data di inizio',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crea una campagna gocciolante',
        youCanCreateMultipleCampaign: 'Puoi creare più campagne',
        dripCampaignName: 'Nome della campagna gocciolante',
        dripCampaignNameIsRequired: 'È richiesto il nome della campagna gocciolante',
        continue: 'Continua',
        manageDripCampaign: 'Gestisci la campagna gocciolante',
        youCanCreateMutlipleDripCampaign: 'È possibile creare più campagne di gocciolamento con messaggi pianificati e automatizzati per attivare risposte da specifici gruppi utente.',
        createNewDripCampaign: 'Crea una nuova campagna DRIP',
        campaignName: 'Nome della campagna',
        appliedOn: 'Applicato su',
        segment: 'Gruppo',
        flow: 'Fluire',
        startDate: 'Data d`inizio',
        action: 'Azione',
        nA: 'N / A',
        deleteDripCampaign: 'Elimina campagne gocciolanti',
        wantToDeleteThisDripCampaign: 'Sei sicuro di voler eliminare queste campagne gocciolanti?',
        placeholders: {
          enterDripCampaignName: 'Immettere il nome della campagna gocciolante',
          searchByName: 'Ricerca per nome',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Elimina più campagne di gocciolamento',
          deleteDripCampaign: 'Elimina la campagna gocciolante',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Nome della campagna gocciolante',
          appliedOn: 'Applicato su',
          segments: 'Gruppi',
          selectDate: 'Seleziona Data',
          scheduleYourFlows: 'Pianifica i tuoi flussi',
          schedule: 'Programma',
          flow: 'Fluire',
          sendAfter: 'Invia dopo',
          cancel: 'Annulla',
          newSubscriber: 'Nuovo abbonato',
          days: 'Giorni',
          minutes: 'Minuti',
          hours: 'Ore',
          createDripCampaign: 'Crea una campagna gocciolante',
          placeholders: {
            enterDripName: 'Immettere il nome della goccia',
            selectAppliedOn: 'Seleziona applicato su',
            selectSegment: 'Seleziona gruppo',
            searchByName: 'Ricerca per nome',
            selectTemplate: 'Seleziona il modello',
            searchFlowByName: 'Flusso di ricerca per nome',
            enterSendAfter: 'Inserisci Invia dopo',
          },
          dateTimePicker: {
            selectStartDate: 'Seleziona Data di inizio',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'Chat id',
        errorCode: 'Codice di errore',
        errorMessage: 'Messaggio di errore',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flussi di chat',
        createChatFlow: 'Crea flusso di chat',
        youCanCreateMultipleChatFlows: 'È possibile creare più flussi di conversazioni su più canali per test A/B e trasmissioni.',
        flowName: 'Nome del flusso',
        nameIsRequired: 'È richiesto il nome',
        cancel: 'Annulla',
        createFlow: 'Crea flusso',
        importFlow: 'Flusso di importazione',
        createNewFlow: 'Crea nuovo flusso',
        ofMessages: 'di messaggi',
        createdOn: 'Creato',
        lastModified: 'Ultima modifica',
        defaultFlow: 'Flusso predefinito',
        action: 'Azione',
        exportStatus: 'Stato di esportazione',
        flowExport: 'Flusso- esportazione',
        download: 'Scaricamento',
        // importFlow: 'Flusso di importazione',
        beforeYouStartUploadPleaseMakeSure: 'Prima di iniziare il caricamento, assicurati',
        point1: '1. Il limite di caricamento della dimensione del file massima è 1 MB',
        point2: '2. Il formato del file dovrebbe essere in JSON',
        selectFile: 'Seleziona il file',
        inProgress: 'in corso',
        available: 'Disponibile',
        initiated: 'Iniziato',
        placeholders: {
          enterFlowName: 'Immettere il nome del flusso',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Flusso di importazione per Facebook Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e ora della creazione del flusso di chat',
          dateAndTimeWhenChatFlowLastModified: 'Data e ora in cui il flusso di chat è stato modificato l`ultima volta',
          TOOLTIP_CREATE_FLOW: 'Fare clic per creare un nuovo flusso di chat',
          TOOLTIP_FLOW_NAME: 'Immettere il nome del flusso di chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome del flusso di chat',
          TOOLTIP_NO_OF_MESSAGES: 'N. di componenti del messaggio nel flusso di chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilizzare questo intervento per impostare un flusso di chat come predefinito, che verrà servito a tutti i nuovi utenti.',
          TOOLTIP_ACTION: 'Modifica o elimina il flusso di chat',
        },
        noDataFound: {
          flows: 'Flussi',
          noFlowsAddedYet: 'Nessun flusso aggiunto ancora!',
        },
        modal: {
          deleteFlow: 'Elimina il flusso',
          wantToDeleteThisFlow: 'Sei sicuro di voler eliminare questo flusso?',
        }
      },
      widgets: {
        manageWidgets: 'Gestisci i widget',
        widgetsAreRoutingTools: 'I widget sono gli strumenti di routing per aumentare gli usi del bot',
        needHelp: 'Ho bisogno di aiuto?',
        wantToMakeChanges: 'Vuoi apportare modifiche?',
        editIcon: 'Modifica icona',
        discard: 'Scartare',
        updateWidget: 'Widget di aggiornamento',
        installChatBotIconOnYourWebsite: 'Installa l`icona Chatbot sul tuo sito Web',
        shareWhereEverYouWantToRedirectUsersTo: 'Condividi dove desideri e reindirizza gli utenti sul tuo bot di Facebook in singolo clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copia e aggiungi il file di intestazione del tuo sito web',
        copyLinkAndStartSharing: 'Copia link e inizia a condividere!',
        downloadQrCodeAndStartSharing: 'Scarica il codice QR e inizia a condividere!',
        addCodeAndDeployNewCodeAndDone: 'Aggiungi il codice, distribuisci il nuovo codice e fatto!',
        wantToLearnHowToUseIt: 'Vuoi imparare a usarlo?',
        copyChatBotCode: 'Copia il codice chatbot',
        copyFacebookLink: 'Copia Link Facebook',
        downloadQrCode: 'Scarica il codice QR',
        link: 'Collegamento',
        chatBotIcon: 'Icona di chatbot',
        qrcode: 'QR Code',
        small: 'Piccolo',
        medium: 'medio',
        large: 'Grande',
        leftMiddle: 'Middle sinistro',
        rightMiddle: 'Middle destro',
        leftBottom: 'Fondo sinistro',
        rightBottom: 'In basso a destra',
        bpDropDown: {
          selectIcons: 'Seleziona icone',
          iconSize: 'Dimensione dell`icona',
          iconPlacement: 'Posizionamento dell`icona',
        }
      },
      autoReply: {
        autoReplyTitle: 'Risposta Automatica',
        configureYour: 'Configura il tuo Messaggio di  ',
        message: '',
        customizeAutoReplyMessagesForYourPostsComments: 'Personalizza i messaggi di risposta automatica per i commenti ai tuoi post',
        wouldYouLikeToApply: 'Desideri applicare la ',
        autoreply: 'Risposta Automatica',
        onAllPost: ' a tutti i post?',
        configureYourMessageForEachPost: 'Configura il tuo Messaggio per Ogni Post',
        configureFlowToTriggerAfterOnReply: 'Configura il flusso per essere attivato dopo una risposta',
        whenUserLeavesACommentSendMessageAfter: 'Quando un utente lascia un commento, invia il messaggio successivamente',
        triggerFlowOnReply: 'Attiva Flusso alla Risposta',
        add: 'Aggiungi',
        update: 'Aggiorna',
        save: 'Salva',
        cancel: 'Annulla',
        discard: 'Scarta',
        bpDropDown: {
          selectPost: 'Seleziona Post',
          triggerFlowOnReply: 'Attiva Flusso alla Risposta',
          immendiately: 'Immediatamente',
          minutes: 'minuti'
        },
        bpTable: {
          message: 'Messaggio',
          selectedPost: 'Post selezionato',
          triggerFlow: 'Attiva Flusso',
          actions: 'Azioni'
        },
        placeholder: {
          configureYourMessage: 'Configura il tuo messaggio'
        },
        deleteModel: {
          title: 'Elimina Risposta Automatica della Pubblicazione',
          description: 'Sei sicuro di voler eliminare questa risposta automatica della pubblicazione?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'Timeout sessione',
        enterSessionTimeOutWindowInHours: 'Inserisci finestra timeout sessione in ore',
        sendInactivityMessage: 'Invia messaggio di inattività',
        sessionTimeOutInHoursTooltip: `Inserisci la durata dopo la quale la sessione di chat scadrà automaticamente a causa dell'inattività. Il flusso di chat verrà riavviato se l'utente invia un messaggio dopo il timeout della sessione.`,
        restartChatFlowTriggers: 'Trigger per riavviare il flusso di chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Inserisci le parole chiave per riavviare la chat e premi Invio',
        restartChatFlowTriggersTooltip: `Specifica qui le parole chiave che, quando inserite dall'utente e inviate, attiveranno un riavvio del flusso di chat. Ciò consente agli utenti di riavviare la conversazione in qualsiasi momento.
         Premi Invio dopo aver aggiunto ogni parola chiave per aggiungerne più di una.`,
        liveChatTriggers: 'Trigger per la chat live',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Inserisci le parole chiave per attivare la chat live e premi Invio',
        liveChatTriggersTooltip: `Definisci le parole chiave che, quando inserite dall'utente e inviate, avvieranno una transizione dalla chat automatizzata alla chat live. Questo è utile quando gli utenti hanno bisogno di assistenza da un agente in diretta.
         Premi Invio dopo aver aggiunto ogni parola chiave per aggiungerne più di una.`,
        messageAfterAutomatedFlowCompleted: 'Messaggio dopo il completamento del flusso automatizzato',
        enterYourMessage: 'Inserisci il tuo messaggio',
        messageAfterAutomatedFlowCompletedTooltip: `Inserisci un messaggio che verrà visualizzato all'utente dopo aver completato il flusso di chat automatizzato, ma la sessione non è scaduta.`,
        restart: 'Riavvia',
        liveChat: 'Chat live',
        lineOneForDefaultMessage: 'Se hai altre domande o hai bisogno di assistenza, ecco cosa puoi fare:',
        to: 'A',
        restartTheChat: 'riavvia la chat',
        simpleTypeRestartAndHitEnter: `scrivi semplicemente 'Riavvia' e premi Invio. Questo ti riporterà all'inizio del nostro flusso di conversazione.`,
        lineThreeForDefaultMessagePartOne: `Se desideri parlare con un`,
        liveAgent: `agente in diretta,`,
        lineThreeForDefaultMessageLastPart: `digita 'Chat live' e premi Invio. Il nostro team è qui per aiutarti in tempo reale.`,
        saveChanges: 'Salva Modifiche',
        discard: 'Annulla',
        configureBotsGeneralSettings: 'Configura le impostazioni generali del tuo bot',
        minutes: 'Minuti',
        hours: 'Ore',
      }
    },
    instagram: {
      instagramBotSettings: 'Impostazioni bot di Instagram',
      chatFlowsHeader: 'Flussi di chat',
      broadcastHeader: 'Trasmissione',
      dripCampaignHeader: 'Campagna gocciolante',
      widgetsHeader: 'Widget',
      alertsHeader: 'Avvisi',
      alerts: {
        configureYourBotsSettings: 'Configurare le impostazioni di avviso del tuo bot',
        specifyHowYourBotNotifyYou: 'Specifica come il tuo bot ti avviserà dei nuovi lead.',
        subject: 'Soggetto',
        emailNotification: 'notifica per email',
        emailAddresses: 'Indirizzi email',
        leadRevisitNotifications: 'Lead Revisit Notifiche',
        whenToSendLeadInfo: 'Quando inviare informazioni sul lead',
        discard: 'Scartare',
        saveChanges: 'Salvare le modifiche',
        placeholders: {
          enterSubjectForYourEmail: 'Inserisci l`oggetto per la tua email',
          typeYourEmailAddressAndHitEnter: 'Digita il tuo indirizzo email e premi Invio',
        }
      },
      broadcasts: {
        createBroadcast: 'Crea trasmissione',
        youCanCreateMultipleBroadcasts: 'Puoi creare più trasmissioni',
        broadcastName: 'Nome della trasmissione',
        broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
        continue: 'Continua',
        manageBroadcasts: 'Gestisci le trasmissioni',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'È possibile creare più trasmissioni con più modelli programmati per diversi gruppo di clienti',
        createNewBroadcast: 'Crea una nuova trasmissione',
        segment: 'Gruppo',
        template: 'Modello',
        contacts: 'Contatti',
        send: 'Inviare',
        failed: 'Fallito',
        broadcastAt: 'Trasmesso a',
        status: 'Stato',
        actions: 'Azioni',
        nA: 'N / A',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          searchHere: 'Cerca qui',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Elimina più trasmissioni',
          deleteBroadcast: 'Elimina la trasmissione',
        }
      },
      createBroadcast: {
        broadcastName: 'Nome della trasmissione',
        broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
        flow: 'Fluire',
        segments: 'Gruppi',
        whenToSend: 'Quando inviare',
        selectDate: 'Seleziona Data',
        selectTimeSlot: 'Seleziona Time Slot',
        cancel: 'Annulla',
        createBroadcast: 'Crea trasmissione',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          selectFlow: 'Seleziona il flusso',
          searchFlowByName: 'Flusso di ricerca per nome',
          selectSegments: 'Seleziona gruppi',
          searchSegmentByName: 'Gruppo di ricerca per nome',
          selectTimeSlot: 'Seleziona Time Slot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleziona Data di inizio',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crea una campagna gocciolante',
        youCanCreateMultipleCampaigns: 'Puoi creare più campagne',
        dripCampaignName: 'Nome della campagna gocciolante',
        dripCampaignNameIsRequired: 'È richiesto il nome della campagna gocciolante',
        continue: 'Continua',
        manageDripCampaign: 'Gestisci la campagna gocciolante',
        youCanCreateMultipleCampaignsWith: 'È possibile creare più campagne di gocciolamento con messaggi pianificati e automatizzati per attivare risposte da specifici gruppi utente',
        createNewDripCampaign: 'Crea una nuova campagna DRIP',
        campaignName: 'Nome della campagna',
        appliedOn: 'Applicato su',
        segment: 'Gruppo',
        flow: 'Fluire',
        startDate: 'Data d`inizio',
        action: 'Azione',
        nA: 'N / A',
        placeholders: {
          enterDripCampaignName: 'Immettere il nome della campagna gocciolante',
          searchByName: 'Ricerca per nome',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Elimina più campagne di gocciolamento',
          deleteDripCampaign: 'Elimina la campagna gocciolante',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Nome della campagna gocciolante',
          appliedOn: 'Applicato su',
          segments: 'Gruppi',
          selectDate: 'Seleziona Data',
          scheduleYourFlows: 'Pianifica i tuoi flussi',
          flow: 'Fluire',
          sendAfter: 'Invia dopo',
          cancel: 'Annulla',
          createDripCampaign: 'Crea una campagna gocciolante',
          placeholders: {
            enterDripName: 'Immettere il nome della goccia',
            selectAppliedOn: 'Seleziona applicato su',
            selectSegment: 'Seleziona gruppo',
            searchByName: 'Ricerca per nome',
            selectTemplate: 'Seleziona il modello',
            searchFlowByName: 'Flusso di ricerca per nome',
            enterSendAfter: 'Inserisci Invia dopo',
          },
          dateTimePicker: {
            selectStartDate: 'Seleziona Data di inizio',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'Chat id',
        errorCode: 'Codice di errore',
        errorMessage: 'Messaggio di errore',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flussi di chat',
        createChatFlow: 'Crea flusso di chat',
        youCanCreateMultipleChatFlows: 'È possibile creare più flussi di conversazioni su più canali per test A/B e trasmissioni.',
        flowName: 'Nome del flusso',
        nameIsRequired: 'È richiesto il nome',
        cancel: 'Annulla',
        createFlow: 'Crea flusso',
        importFlow: 'Flusso di importazione',
        createNewFlow: 'Crea nuovo flusso',
        // flowName: 'Nome del flusso',
        ofMessages: ' di messaggi',
        createdOn: 'Creato',
        lastModified: 'Ultima modifica',
        defaultFlow: 'Flusso predefinito',
        action: 'Azione',
        exportStatus: 'Stato di esportazione',
        flowExport: 'Flusso- esportazione',
        download: 'Scaricamento',
        // importFlow: 'Flusso di importazione',
        beforeYouStartUploadPleaseMakeSure: 'Prima di iniziare il caricamento, assicurati',
        point1: '1. Il limite di caricamento della dimensione del file massima è 1 MB',
        point2: '2. Il formato del file dovrebbe essere in JSON',
        selectFile: 'Seleziona il file',
        placeholders: {
          enterFlowName: 'Immettere il nome del flusso',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Flusso di importazione per Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e ora della creazione del flusso di chat',
          dateAndTimeWhenChatFlowLastModified: 'Data e ora in cui il flusso di chat è stato modificato l`ultima volta',
        },
        noDataFound: {
          flows: 'Flussi',
          noFlowsAddedYet: 'Nessun flusso aggiunto ancora!',
        },
        modal: {
          deleteFlow: 'Elimina il flusso',
          wantToDeleteThisFlow: 'Sei sicuro di voler eliminare questo flusso?',
        }
      },
      widgets: {
        manageWidgets: 'Gestisci i widget',
        widgetsAreRoutingTools: 'I widget sono gli strumenti di routing per aumentare gli usi del bot',
        needHelp: 'Ho bisogno di aiuto?',
        wantToMakeChanges: 'Vuoi apportare modifiche?',
        editIcon: 'Modifica icona',
        discard: 'Scartare',
        updateWidget: 'Widget di aggiornamento',
        bpDropDown: {
          selectIcons: 'Seleziona icone',
          iconSize: 'Dimensione dell`icona',
          iconPlacement: 'Posizionamento dell`icona',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Impostazioni del bot telegramma',
      chatFlowsHeader: 'Flussi di chat',
      telegramProfileHeader: 'Profilo telegramma',
      widgetsHeader: 'Widget',
      broadcastHeader: 'Trasmissione',
      dripCampaignHeader: 'Campagna gocciolante',
      generalHeader: 'Generale',
      telegramAutomation: 'Automazione telegramma',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Crea un percorso di onboarding del cliente utilizzando una campagna gocciolante di flussi di chat programmati.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizza i modelli di flusso di chat e crea più flussi di chat per un pubblico target diverso.',
      },
      broadcasts: {
        createBroadcast: 'Crea trasmissione',
        youCanCreateMutlipleBroadcasts: 'Puoi creare più trasmissioni',
        broadcastName: 'Nome della trasmissione',
        broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
        continue: 'Continua',
        manageBroadcasts: 'Gestisci le trasmissioni',
        youCanCreateMultipleBroadcastsWith:
          'È possibile creare più trasmissioni con più modelli programmati per diversi gruppi di clienti.',
        createNewBroadcast: 'Crea una nuova trasmissione',
        name: 'Nome',
        segment: 'Gruppo',
        template: 'Modello',
        contacts: 'Contatti',
        send: 'Inviare',
        failed: 'Fallito',
        broadcastAt: 'Trasmesso a',
        status: 'Stato',
        actions: 'Azioni',
        nA: 'N / A',
        deleteBroadcast: 'Elimina la trasmissione',
        wantToDeleteThisBroadcast: 'Sei sicuro di voler eliminare questa trasmissione?',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          searchHere: 'Cerca qui',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Elimina più trasmissioni',
          deleteBroadcast: 'Elimina la trasmissione',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'Nome della trasmissione',
        broadcastNameIsRequired: 'È richiesto il nome della trasmissione',
        flow: 'Fluire',
        segments: 'Gruppi',
        whenToSend: 'Quando inviare',
        selectDate: 'Seleziona Data',
        selectTimeSlot: 'Seleziona Time Slot',
        cancel: 'Annulla',
        createBroadcast: 'Crea trasmissione',
        now: 'Ora',
        later: 'Dopo',
        placeholders: {
          enterBroadcastName: 'Immettere il nome della trasmissione',
          selectFlow: 'Seleziona il flusso',
          searchFlowByName: 'Flusso di ricerca per nome',
          selectSegments: 'Seleziona gruppi',
          searchSegmentByName: 'Gruppo di ricerca per nome',
          selectTimeSlot: 'Seleziona Time Slot',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Seleziona Data di inizio',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Crea una campagna gocciolante',
        youCanCreateMultipleCampaigns: 'Puoi creare più campagne',
        dripCampaignName: 'Nome della campagna gocciolante',
        dripCampaignNameIsRequired: 'È richiesto il nome della campagna gocciolante',
        continue: 'Continua',
        manageDripCampaign: 'Gestisci la campagna gocciolante',
        youCanCreateMultipleDripCampaignsWith: 'È possibile creare più campagne di gocciolamento con messaggi pianificati e automatizzati per attivare risposte da specifici gruppi utente.',
        createNewDripCampaign: 'Crea una nuova campagna DRIP',
        campaignName: 'Nome della campagna',
        appliedOn: 'Applicato su',
        segment: 'Gruppo',
        flow: 'Fluire',
        startDate: 'Data d`inizio',
        action: 'Azione',
        nA: 'N / A',
        deleteDripCampaign: 'Elimina campagne gocciolanti',
        wantToDeleteThisDripCampaign: 'Sei sicuro di voler eliminare queste campagne gocciolanti?',
        placeholders: {
          enterDripCampaignName: 'Immettere il nome della campagna gocciolante',
          searchByName: 'Ricerca per nome',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Elimina più campagne di gocciolamento',
          deleteDripCampaign: 'Elimina la campagna gocciolante',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Nome della campagna gocciolante',
          appliedOn: 'Applicato su',
          segments: 'Gruppi',
          startDate: 'Seleziona Data',
          scheduleYourFlows: 'Pianifica i tuoi flussi',
          schedule: 'Programma',
          flow: 'Fluire',
          sendAfter: 'Invia dopo',
          cancel: 'Annulla',
          createDripCampaign: 'Crea una campagna gocciolante',
          newSubscriber: 'Nuovo abbonato',
          days: 'Giorni',
          minutes: 'Minuti',
          hours: 'Ore',
          placeholders: {
            enterDripName: 'Immettere il nome della goccia',
            selectAppliedOn: 'Seleziona applicato su',
            selectSegment: 'Seleziona gruppo',
            searchByName: 'Ricerca per nome',
            selectFlow: 'Seleziona il flusso',
            searchFlowByName: 'Flusso di ricerca per nome',
          },
          dateTimePicker: {
            selectStartDate: 'Seleziona Data di inizio',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Nome',
        email: 'E-mail',
        phone: 'Telefono',
        errorCode: 'Codice di errore',
        errorMessage: 'Messaggio di errore',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flussi di chat',
        createChatFlow: 'Crea flusso di chat',
        youCanCreateMultipleChatFlows: 'È possibile creare più flussi di conversazioni su più canali per test A/B e trasmissioni.',
        flowName: 'Nome del flusso',
        nameIsRequired: 'È richiesto il nome',
        cancel: 'Annulla',
        createFlow: 'Crea flusso',
        importFlow: 'Flusso di importazione',
        createNewFlow: 'Crea nuovo flusso',
        // flowName: 'Nome del flusso',
        ofMessages: ' di messaggi',
        createdOn: 'Creato',
        lastModified: 'Ultima modifica',
        defaultFlow: 'Flusso predefinito',
        action: 'Azione',
        exportStatus: 'Stato di esportazione',
        flowExport: 'Flusso- esportazione',
        inProgress: 'in corso',
        available: 'Disponibile',
        initiated: 'Iniziato',
        download: 'Scaricamento',
        // importFlow: 'Flusso di importazione',
        beforeYouStartUploadPleaseMakeSure: 'Prima di iniziare il caricamento, assicurati',
        point1: '1. Il limite di caricamento della dimensione del file massima è 1 MB',
        point2: '2. Il formato del file dovrebbe essere in JSON',
        selectFile: 'Seleziona il file',
        placeholders: {
          enterFlowName: 'Immettere il nome del flusso',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Flusso di importazione per Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e ora della creazione del flusso di chat',
          dateAndTimeWhenChatFlowLastModified: 'Data e ora in cui il flusso di chat è stato modificato l`ultima volta',
          TOOLTIP_CREATE_FLOW: 'Fare clic per creare un nuovo flusso di chat',
          TOOLTIP_FLOW_NAME: 'Immettere il nome del flusso di chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome del flusso di chat',
          TOOLTIP_NO_OF_MESSAGES: 'N. di componenti del messaggio nel flusso di chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilizzare questo intervento per impostare un flusso di chat come predefinito, che verrà servito a tutti i nuovi utenti.',
          TOOLTIP_ACTION: 'Modifica o elimina il flusso di chat',
        },
        noDataFound: {
          flows: 'Flussi',
          noFlowsAddedYet: 'Nessun flusso aggiunto ancora!',
        },
        modal: {
          deleteFlow: 'Elimina il flusso',
          wantToDeleteThisFlow: 'Sei sicuro di voler eliminare questo flusso?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Profilo telegramma',
        manageYourBotProfile: 'Gestisci il tuo profilo bot come e quando vuoi.',
        allSetNoActionRequired: 'Tutto impostato, nessuna azione richiesta!',
        congratulationYourAccountIsLive: 'Congratulazioni! Il tuo account è in diretta con pieno accesso',
        botDetails: 'Dettagli del bot',
        name: 'Nome',
        nA: 'N / A',
        b: 'B',
        botName: 'Nome bot',
        bot: 'Bot',
        info: 'Informazioni',
        none: 'Nessuno',
        username: 'Nome utente',
        notifications: 'Notifiche',
        on: 'SU',
        addToGroupOrChannel: 'Aggiungi al gruppo o al canale',
        userName: 'Nome utente',
        botToken: 'Token bot',
      },
      widgets: {
        manageWidgets: 'Gestisci i widget',
        widgetsAreRoutingTools: 'I widget sono gli strumenti di routing per aumentare gli usi del bot',
        needHelp: 'Ho bisogno di aiuto?',
        wantToMakeChanges: 'Vuoi apportare modifiche?',
        editIcon: 'Modifica icona',
        discard: 'Scartare',
        updateWidget: 'Widget di aggiornamento',
        installChatBotIconOnYourWebsite: 'Installa l`icona Chatbot sul tuo sito Web',
        shareWhereEverYouWantRedirectUsersTo: 'Condividi dove desideri e reindirizza gli utenti sul tuo bot Telegram in singolo clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copia e aggiungi il file di intestazione del tuo sito web',
        copyLinkAndStartSharing: 'Copia link e inizia a condividere!',
        downloadQrCodeAndStartSharing: 'Scarica il codice QR e inizia a condividere!',
        addCodeAndDeployNewCodeAndDone: 'Aggiungi il codice, distribuisci il nuovo codice e fatto!',
        wantToLearnHowToUseIt: 'Vuoi imparare a usarlo?',
        copyChatBotCode: 'Copia il codice chatbot',
        downloadQrCode: 'Scarica il codice QR',
        copyTelegramLink: 'Copia Link Telegram',
        link: 'Collegamento',
        chatBotIcon: 'Icona di chatbot',
        qrcode: 'QR Code',
        small: 'Piccolo',
        medium: 'medio',
        large: 'Grande',
        leftMiddle: 'Middle sinistro',
        rightMiddle: 'Middle destro',
        leftBottom: 'Fondo sinistro',
        rightBottom: 'In basso a destra',
        bpDropDown: {
          selectIcons: 'Seleziona icone',
          iconSize: 'Dimensione dell`icona',
          iconPlacement: 'Posizionamento dell`icona',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Timeout sessione',
        enterSessionTimeOutWindowInHours: 'Inserisci finestra timeout sessione in ore',
        sendInactivityMessage: 'Invia messaggio di inattività',
        sessionTimeOutInHoursTooltip: `Inserisci la durata dopo la quale la sessione di chat scadrà automaticamente a causa dell'inattività. Il flusso di chat verrà riavviato se l'utente invia un messaggio dopo il timeout della sessione.`,
        restartChatFlowTriggers: 'Trigger per riavviare il flusso di chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Inserisci le parole chiave per riavviare la chat e premi Invio',
        restartChatFlowTriggersTooltip: `Specifica qui le parole chiave che, quando inserite dall'utente e inviate, attiveranno un riavvio del flusso di chat. Ciò consente agli utenti di riavviare la conversazione in qualsiasi momento.
         Premi Invio dopo aver aggiunto ogni parola chiave per aggiungerne più di una.`,
        liveChatTriggers: 'Trigger per la chat live',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Inserisci le parole chiave per attivare la chat live e premi Invio',
        liveChatTriggersTooltip: `Definisci le parole chiave che, quando inserite dall'utente e inviate, avvieranno una transizione dalla chat automatizzata alla chat live. Questo è utile quando gli utenti hanno bisogno di assistenza da un agente in diretta.
         Premi Invio dopo aver aggiunto ogni parola chiave per aggiungerne più di una.`,
        messageAfterAutomatedFlowCompleted: 'Messaggio dopo il completamento del flusso automatizzato',
        enterYourMessage: 'Inserisci il tuo messaggio',
        messageAfterAutomatedFlowCompletedTooltip: `Inserisci un messaggio che verrà visualizzato all'utente dopo aver completato il flusso di chat automatizzato, ma la sessione non è scaduta.`,
        restart: 'Riavvia',
        liveChat: 'Chat live',
        lineOneForDefaultMessage: 'Se hai altre domande o hai bisogno di assistenza, ecco cosa puoi fare:',
        to: 'A',
        restartTheChat: 'riavvia la chat',
        simpleTypeRestartAndHitEnter: `scrivi semplicemente 'Riavvia' e premi Invio. Questo ti riporterà all'inizio del nostro flusso di conversazione.`,
        lineThreeForDefaultMessagePartOne: `Se desideri parlare con un`,
        liveAgent: `agente in diretta,`,
        lineThreeForDefaultMessageLastPart: `digita 'Chat live' e premi Invio. Il nostro team è qui per aiutarti in tempo reale.`,
        saveChanges: 'Salva Modifiche',
        discard: 'Annulla',
        configureBotsGeneralSettings: 'Configura le impostazioni generali del tuo bot',
        minutes: 'Minuti',
        hours: 'Ore',
      }
    },
    whatsapp: {
      whatsappAutomation: 'Automazione di WhatsApp',
      whatsappBotSettings: 'Impostazioni bot whatsapp',
      chatFlowsHeader: 'Flussi di chat',
      whatsappProfileHeader: 'Profilo whatsapp',
      broadcastHeader: 'Trasmissione',
      dripCampaignHeader: 'Campagna gocciolante',
      widgetHeader: 'Aggeggio',
      templatesHeader: 'Modelli',
      alertsHeader: 'Avvisi',
      generalHeader: 'Generale',
      apiKeyHeader: 'Chiave API',
      catalogHeader: 'Catalogo',
      otherConfiguration: 'Impostazioni IA',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personalizza i modelli di flusso di chat e crea più flussi di chat per un pubblico target diverso.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Configurare le impostazioni di avviso del tuo bot',
        specifyHowYouBotWillNotify: 'Specifica come il tuo bot ti avviserà dei nuovi lead.',
        subject: 'Soggetto',
        emailNotification: 'notifica per email',
        emailAddresses: 'Indirizzi email',
        leadRevisitNotifications: 'Lead Revisit Notifiche',
        whenToSendLeadInfo: 'Quando inviare informazioni sul lead',
        discard: 'Scartare',
        saveChanges: 'Salvare le modifiche',
        seeHowToUse: 'Guarda come usare',
        beginningTheFlow: 'Iniziando il flusso',
        fiveMinutesLater: '5 minuti più tardi',
        endOfFlow: 'Fine del flusso',
        placeholders: {
          enterSubjectForYourEmail: 'Inserisci l`oggetto per la tua email',
          typeEmailAndHitEnter: 'Digita il tuo indirizzo email e premi Invio',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Personalizza l`oggetto delle notifiche e - mail dal bot',
          TOOLTIP_EMAIL_NOTIFICATION: 'Abilita/disabilita la notifica del bot sulla tua e -mail per nuovi lead',
          TOOLTIP_EMAIL_ADDRESS: 'Invia l`indirizzo e - mail dell`account in cui si desidera ricevere notifiche e -mail. È possibile aggiungere più indirizzi e -mail inserendo uno e premendo Invio per aggiungerne un altro.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Abilita/disabilita la notifica bot sulla tua e -mail se un utente precedentemente catturato come lead interagisce di nuovo con il bot.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Aggiorna WhatsApp Chatbot',
        businessNameIsRequired: 'È richiesto il nome aziendale.',
        maxLengthForBusinessName40Characters: 'La lunghezza massima per il nome aziendale è di 40 caratteri.',
        categoryIsRequired: 'È richiesta la categoria.',
        descriptionIsRequired: 'È richiesta una descrizione.',
        maxLengthForDescription200Characters: 'La lunghezza massima per la descrizione è di 200 caratteri.',
        aboutIsRequired: 'È richiesto.',
        maxLengthForAbout139Characters: 'La lunghezza massima per circa 139 caratteri.',
        pleaseFillAValidBusinessEmail: 'Si prega di compilare un`e - mail aziendale valida.',
        businessEmailIsRequired: 'È richiesta un`e - mail aziendale.',
        websiteIsRequired: 'È richiesto il sito Web.',
        maxLengthForWebsite40Characters: 'La lunghezza massima per il sito Web è di 40 caratteri.',
        maxLengthForAddress150Characters: 'La lunghezza massima per l`indirizzo è di 150 caratteri.',
        cancel: 'Annulla',
        update: 'Aggiornamento',
        businessCategory: 'Categoria di Business',
        placeholders: {
          yourBusinessName: 'Il tuo nome commerciale',
          businessDescription: 'Descrizione dell`attività',
          about: 'Di',
          businessEmail: 'Email di lavoro',
          website: 'Sito Web (ad es. Www.example.com)',
          address: 'Indirizzo',
        }
      },
      flows: {
        chatFlows: 'Flussi di chat',
        createChatFlow: 'Crea flusso di chat',
        youCanCreateMultipleChatFlows: 'È possibile creare più flussi di conversazioni su più canali per test A/B e trasmissioni.',
        flowName: 'Nome del flusso',
        nameIsRequired: 'È richiesto il nome',
        cancel: 'Annulla',
        createFlow: 'Crea flusso',
        importFlow: 'Flusso di importazione',
        createNewFlow: 'Crea nuovo flusso',
        ofMessages: ' di messaggi',
        createdOn: 'Creato',
        lastModified: 'Ultima modifica',
        defaultFlow: 'Flusso predefinito',
        revisitFlow: 'Rivisitare il flusso',
        action: 'Azione',
        exportStatus: 'Stato di esportazione',
        flowExport: 'Flusso- esportazione',
        // importFlow: 'Flusso di importazione',
        beforeYouStartUploadPleaseMakeSure: 'Prima di iniziare il caricamento, assicurati',
        point1: '1. Il limite di caricamento della dimensione del file massima è 1 MB',
        point2: '2. Il formato del file dovrebbe essere in JSON',
        selectFile: 'Seleziona il file',
        seeHowToUse: 'Guarda come usare',
        download: 'Scaricamento',
        available: 'Disponibile',
        initiated: 'Iniziato',
        inProgress: 'in corso',
        placeholders: {
          enterFlowName: 'Immettere il nome del flusso',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Flusso di importazione per WhatsApp Bot',
          dateAndTimeOfCreationOfChatFlow: 'Data e ora della creazione del flusso di chat',
          dateAndTimeWhenChatFlowLastModified: 'Data e ora in cui il flusso di chat è stato modificato l`ultima volta',
          TOOLTIP_CREATE_FLOW: 'Fare clic per creare un nuovo flusso di chat',
          TOOLTIP_FLOW_NAME: 'Immettere il nome del flusso di chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nome del flusso di chat',
          TOOLTIP_NO_OF_MESSAGES: 'N. di componenti del messaggio nel flusso di chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilizzare questo intervento per impostare un flusso di chat come predefinito, che verrà servito a tutti i nuovi utenti.',
          TOOLTIP_REVISIT_FLOW: 'Usa questo intervento per selezionare un flusso di chat da servire agli utenti quando rivisitano il chatbot.',
          TOOLTIP_ACTION: 'Modifica o elimina il flusso di chat',
        },
        modal: {
          deleteFlow: 'Elimina il flusso',
          wantToDeleteThisFlow: 'Sei sicuro di voler eliminare questo flusso?',
        },
        noDataFound: {
          flows: 'Flussi',
          noFlowsAddedYet: 'Nessun flusso aggiunto ancora!',
        }
      },
      input: {
        manageTemplates: 'Gestisci modelli',
        manageYourTemplatesForMarketingAutomation: 'Gestisci i tuoi modelli per l`automazione del marketing',
        syncTemplates: 'Modelli di sincronizzazione',
        createTemplateHeader: 'Crea modello',
        title: 'Titolo',
        info: 'Informazioni',
        category: 'Categoria',
        status: 'Stato',
        action: 'Azione',
        shipmentUpdate: 'Aggiornamento della spedizione',
        name: 'Nome',
        // category: 'Categoria',
        headerOptional: 'Intestazione (opzionale)',
        bodyRequired: 'Corpo (richiesto)',
        footerOptional: 'Footer (opzionale)',
        buttonsOptional: 'Pulsanti (opzionale)',
        makeUpto3Buttons: 'Crea fino a 3 pulsanti',
        makeUpto2ButtonsToRedirect: 'Crea fino a 2 pulsanti per reindirizzare il cliente al tuo sito Web',
        placeholders: {
          nameYourTemplate: 'Nomina il tuo modello (usa sottolineare le parole separate)',
          enterText: 'Immettere il testo (max 60 caratteri)',
          enterImageUrl: 'Immettere l`URL dell`immagine',
          enterVideoUrl: 'Inserisci l`URL video',
          templateMessage: 'Messaggio modello',
          smallFooterAtBottom: 'Piccolo piè di pagina in basso',
          buttons: 'Pulsanti',
          text: 'Testo',
          numberWithCountryCode: 'Numero con il codice del paese',
          enterButtonText: 'Inserisci il testo del pulsante (carattere massimo 20)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Titolo del modello WhatsApp',
          TOOLTIP_INPUT_TABLE_INFO: 'Informazioni dettagliate dei messaggi avviati dal business',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Tipo di messaggi avviati dal business come appuntamenti, promemoria, messaggi di assistenza clienti, aggiornamenti di spedizione, avvisi e altro ancora',
          TOOLTIP_INPUT_TABLE_STATUS: 'Lo stato del modello viene respinto o approvato da WhatsApp. I modelli WhatsApp devono essere approvati per la prima volta da WhatsApp e questo è un servizio a pagamento.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Elimina il modello',
        },
        noDataFound: {
          templates: 'Modelli',
          noTemplatesCreatedYet: 'Nessun modello creato ancora!',
        },
        modal: {
          deleteTemplate: 'Elimina modello',
          wantToDeleteThisTemplate: 'Sei sicuro di voler eliminare questo modello?',
        },
        createTemplate: {
          createTemplates: 'Crea modelli',
          createTemplatesForMarketingAutomation: 'Crea i tuoi modelli per l`automazione del marketing',
          templateMeta: 'Modello Meta',
          selectLanguage: 'Seleziona la lingua',
          header: 'Intestazione',
          optional: 'Opzionale',
          samplesForHeaderContent: 'Campioni per contenuto di intestazione',
          toHelpMetaReviewYourContent: 'Per aiutare Meta a rivedere i tuoi contenuti, fornire esempi delle variabili o dei media nell`intestazione.Non includere alcuna informazione del cliente.',
          body: 'Corpo',
          addVariable: 'Aggiungi variabile',
          samplesForBodyContent: 'Campioni per il contenuto del corpo',
          toHelpUsReviewYourContent: 'Per aiutarci a rivedere i tuoi contenuti, fornire esempi delle variabili nel corpo. Non includere alcuna informazione del cliente.',
          footer: 'Piè di pagina',
          // optional: 'Opzionale',
          buttons: 'Pulsanti',
          // optional: 'Opzionale',
          typeOfAction: 'Tipo di azione',
          call: 'Chiamata',
          phone: 'Telefono',
          website: 'Sito web',
          static: 'Statico',
          urlType: 'Tipo URL',
          type: 'Tipo',
          customButton: 'Pulsante personalizzato',
          addAnotherButton: 'Aggiungi un altro pulsante',
          preview: 'Anteprima',
          test: 'Test',
          create: 'Creare',
          addSampleUrl: 'Aggiungi URL di esempio',
          toHelpMetaReviewYourMessage: 'Per aiutare Meta a rivedere il tuo modello di messaggio, aggiungi un esempio dell`URL del sito Web.Non utilizzare informazioni reali del cliente.',
          placeholders: {
            enterMessageTemplateName: 'Immettere il nome del modello di messaggio',
            searchTemplateByName: 'Modello di ricerca per nome',
            selectTemplate: 'Seleziona il modello',
            enterText: 'Inserire il testo',
            enterBody: 'Inserisci il corpo',
            enterContentForVariable: 'Immettere il contenuto per la variabile',
            enterFooter: 'Inserisci il piè di pagina',
            phone: 'Telefono*',
            enterWebsiteUrl: 'Inserisci l`URL del sito web',
          },
          bpInput: {
            name: 'Nome',
            buttonText: 'Testo del pulsante',
            websiteUrl: 'URL del sito',
          },
          bpDropDown: {
            selectCategory: 'Seleziona categoria',
          }
        },
        templatePreview: {
          preview: 'Anteprima',
          test: 'Test',
        },
        templateConfig: {
          discard: 'Scartare',
          saveChanges: 'Salvare le modifiche',
          triggerChatFlowOnTemplateReply: 'Attiva il flusso di chat alla risposta del modello',
          templateDetails: 'Dettagli del modello',
          configureFlows: 'Configura i flussi',
          selectFlow: 'Seleziona Flusso',
          name: 'Nome'
        }
      },
      shopManagement: {
        shopManagement: 'Gestione dei negozi',
        manageECommerceShopFromBotPenguin: 'Gestisci negozi di e-commerce connessi con BotPenguin',
        comingSoon: '( Prossimamente )',
        wooCommerce: {
          createConfiguration: 'Crea configurazione',
          eventConfiguration: {
            createConfiguration: 'Crea configurazione',
            templateHeader: 'Testa del modello',
            selectFile: 'Seleziona il file',
            templateMessage: 'Messaggio modello',
            saveChanges: 'Salvare le modifiche',
            bpDropDown: {
              selectEvent: 'Seleziona evento',
              selectTemplate: 'Seleziona il modello',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'Profilo whatsapp',
        manageYourBotProfile: 'Gestisci il tuo profilo bot come e quando vuoi.',
        allSetNoActionRequired: 'Tutto impostato, nessuna azione richiesta!',
        congratulationsYourAccountIsLive: 'Congratulazioni! Il tuo account è in diretta con pieno accesso',
        somethingIsWrongReactivateYourBot: 'Qualcosa non va, riattiva il tuo bot!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Sembra che il tuo bot non funzioni correttamente, ripristina il tuo bot',
        reConfigureYourBot: 'Riconfigurare il tuo bot',
        phoneNumberDetails: 'Dettagli del numero di telefono',
        phoneNumber: 'Numero di telefono',
        nA: 'N / A',
        phoneNumberId: 'ID numero di telefono',
        wabaId: 'Waba Id',
        change: 'Modifica',
        accessToken: 'Token di accesso',
        // change: 'Modifica',
        messagingLimit: 'Limite di messaggistica',
        qualityRating: 'Valutazione di qualità',
        whatsappBusinessAccountName: 'Nome account aziendale di WhatsApp',
        whatsappPublicProfile: 'Profilo pubblico di WhatsApp',
        profilePicture: 'Immagine del profilo',
        aboutText: 'Sul testo',
        address: 'Indirizzo',
        description: 'Descrizione',
        email: 'E-mail',
        websitePrimary: 'Sito web (primario)',
        websiteSecondary: 'Sito web (secondario)',
        businessVertical: 'Business Vertical',
        editProfile: 'Modifica Profilo',
        thisIsABusinessAccount: 'Questo è un account aziendale',
        aboutAndPhoneNumber: 'Circa e numero di telefono',
        yourTrialAccountIsLive: 'Congratulazioni! Il tuo account di prova è in diretta con il pieno accesso sul tuo numero verificato',
        updateWithNewAccessToken: 'Aggiornamento con nuovo AccessToken',
      },
      widgets: {
        manageWidgets: 'Gestisci i widget',
        widgetsAreRoutingTools: 'I widget sono gli strumenti di routing per aumentare gli usi del bot',
        needHelp: 'Ho bisogno di aiuto?',
        wantToMakeChanges: 'Vuoi apportare modifiche?',
        prePopulatedMessage: 'Messaggio pre-popolato',
        discard: 'Scartare',
        updateWidget: 'Widget di aggiornamento',
        hiThere: 'Ciao',
        seeHowToUse: 'Guarda come usare',
        editIcon: 'Modifica icona',
        editPrePopulatedMessage: 'Modificare il messaggio pre-popolato',
        small: 'Piccolo',
        medium: 'medio',
        large: 'Grande',
        leftMiddle: 'Middle sinistro',
        rightMiddle: 'Middle destro',
        leftBottom: 'Fondo sinistro',
        rightBottom: 'In basso a destra',
        chatBotIcon: 'Icona di chatbot',
        link: 'Collegamento',
        qrcode: 'QR Code',
        copyChatBotCode: 'Copia il codice chatbot',
        copyWALink: 'Copia collegamento WA',
        downloadQrCode: 'Scarica il codice QR',
        installChatBotIconOnYourWebsite: 'Installa l`icona Chatbot sul tuo sito Web',
        shareWhereEverYouWantRedirectUsersTo: 'Condividi dove desideri e reindirizza gli utenti sul tuo bot whatsapp in singolo clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copia e aggiungi il file di intestazione del tuo sito web',
        copyLinkAndStartSharing: 'Copia link e inizia a condividere!',
        downloadQrCodeAndStartSharing: 'Scarica il codice QR e inizia a condividere!',
        addCodeAndDeployNewCodeAndDone: 'Aggiungi il codice, distribuisci il nuovo codice e fatto!',
        wantToLearnHowToUseIt: 'Vuoi imparare a usarlo?',
        placeholders: {
          enterWhatsappNumber: 'Immettere il numero di WhatsApp',
          updateYourMessageHere: 'Aggiorna il tuo messaggio qui ...',
        },
        bpDropDown: {
          selectIcons: 'Seleziona icone',
          iconSize: 'Dimensione dell`icona',
          iconPlacement: 'Posizionamento dell`icona',
        },
        bpInput: {
          whatsappNumber: 'Numero whatsapp',
        }
      },
      catalog: {
        createCatalog: 'Creare Catalogo',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Creare un catalogo prodotti sul Meta Commerce Manager o collegarlo a Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Usa il tuo Meta Commerce Manager per gestire i cataloghi',
        connectCatalogToWhatsAppAccount: `Collega il catalogo all'account WhatsApp`,
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: `Collega i tuoi cataloghi all'account WhatsApp usando il tuo gestore di WhatsApp`,
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: `Usa il tuo Meta WhatsApp Manager per collegare i cataloghi e utilizzarli con BotPenguin`,
        goToMeta: 'Vai a Meta',
        goToShopify: 'Vai a Shopify',
        next: 'Avanti',
        back: 'Indietro',
        setupCatalog: 'Configurare il Catalogo',
        completeAllTheStepsToEnableCatalogMessages: 'Completa tutti i passaggi per abilitare i messaggi del catalogo'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Timeout sessione',
        enterSessionTimeOutWindowInHours: 'Inserisci finestra timeout sessione in ore',
        sendInactivityMessage: 'Invia messaggio di inattività',
        sessionTimeOutInHoursTooltip: `Inserisci la durata dopo la quale la sessione di chat scadrà automaticamente a causa dell'inattività. Il flusso di chat verrà riavviato se l'utente invia un messaggio dopo il timeout della sessione.`,
        restartChatFlowTriggers: 'Trigger per riavviare il flusso di chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Inserisci le parole chiave per riavviare la chat e premi Invio',
        restartChatFlowTriggersTooltip: `Specifica qui le parole chiave che, quando inserite dall'utente e inviate, attiveranno un riavvio del flusso di chat. Ciò consente agli utenti di riavviare la conversazione in qualsiasi momento.
         Premi Invio dopo aver aggiunto ogni parola chiave per aggiungerne più di una.`,
        liveChatTriggers: 'Trigger per la chat live',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Inserisci le parole chiave per attivare la chat live e premi Invio',
        liveChatTriggersTooltip: `Definisci le parole chiave che, quando inserite dall'utente e inviate, avvieranno una transizione dalla chat automatizzata alla chat live. Questo è utile quando gli utenti hanno bisogno di assistenza da un agente in diretta.
         Premi Invio dopo aver aggiunto ogni parola chiave per aggiungerne più di una.`,
        messageAfterAutomatedFlowCompleted: 'Messaggio dopo il completamento del flusso automatizzato',
        enterYourMessage: 'Inserisci il tuo messaggio',
        messageAfterAutomatedFlowCompletedTooltip: `Inserisci un messaggio che verrà visualizzato all'utente dopo aver completato il flusso di chat automatizzato, ma la sessione non è scaduta.`,
        restart: 'Riavvia',
        liveChat: 'Chat live',
        lineOneForDefaultMessage: 'Se hai altre domande o hai bisogno di assistenza, ecco cosa puoi fare:',
        to: 'A',
        restartTheChat: 'riavvia la chat',
        simpleTypeRestartAndHitEnter: `scrivi semplicemente 'Riavvia' e premi Invio. Questo ti riporterà all'inizio del nostro flusso di conversazione.`,
        lineThreeForDefaultMessagePartOne: `Se desideri parlare con un`,
        liveAgent: `agente in diretta,`,
        lineThreeForDefaultMessageLastPart: `digita 'Chat live' e premi Invio. Il nostro team è qui per aiutarti in tempo reale.`,
        saveChanges: 'Salva Modifiche',
        discard: 'Annulla',
        configureBotsGeneralSettings: 'Configura le impostazioni generali del tuo bot',
        minutes: 'Minuti',
        hours: 'Ore',

      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'A un solo passo',
      seeHowToUse: 'Scopri come utilizzare',
      testWhatsAppTrialBot: 'Prova il tuo Chatbot di prova di WhatsApp',
      sendMessage: 'Invia messaggio',
      sendWhatsAppMessageOn: 'Invia un messaggio su WhatsApp a ',
      bySavingInYourContact: 'salvandolo nei tuoi contatti e il messaggio dovrebbe iniziare con ',
      connect: 'Collegare',
      or: 'O',
      scanQRCode: 'Scansiona il codice QR',
      yourPhoneWillOpen: 'Il tuo telefono aprirà un messaggio precompilato da inviare al nostro numero Sandbox. Premi "Invia" su WhatsApp',
      sendVerificationCode: 'Invia codice di verifica',
      copyAndEnterCode: 'Copia e inserisci il codice a 6 cifre',
      shareWhereYouWant: 'Condividi ovunque tu voglia e reindirizza gli utenti al tuo bot WhatsApp con un solo clic',
      youShouldHaveReceived: 'Dovresti aver ricevuto un codice a 6 cifre sul tuo numero di WhatsApp',
      EnterYourPhoneNumber: 'Inserisci il tuo numero di telefono',
      send: 'Invia',
      sixDigitCode: 'Inserisci il codice a 6 cifre',
      pleaseNote: 'Si prega di notare: Il codice di verifica a 6 cifre può richiedere fino a 3 minuti, attendi prima di inviarlo nuovamente.',
      resendVerificationCode: 'Invia nuovamente il codice di verifica',
      verify: 'Verifica',
      providePhoneNumber: 'Fornisci il numero che desideri utilizzare per i test',
      demoBotisReady: 'Fantastico! Il tuo bot demo è pronto per l\'uso',
      placeHolder: {
        phone: 'Inserisci il tuo numero di telefono',
        code: 'Inserisci il codice di verifica',
      }

    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Integrazione delle app di terze parti',
    thirdPartyApps: 'App di terze parti',
    customApps: 'App personalizzate',
    preview: 'Anteprima',
    test: 'Test',
    install: 'Installare',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Fare clic per installare il tuo bot sulla piattaforma selezionata',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Integra le tue app create personalizzate con il tuo bot',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Collega il tuo bot con app/CRMS di terze parti disponibili',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Integrazioni di app di terze parti',
        integrateYourBotWithListedCrms: 'Integra il tuo bot con una delle app/CRM elencate.',
        help: 'Aiuto',
        name: 'Nome',
        status: 'Stato',
        action: 'Azione',
        chatGpt: 'Chatgpt',
        addOn: 'Aggiungi su',
        purchased: 'Acquistato',
        readyToRideGptWave: 'Pronto a cavalcare l`onda GPT ? Integra ora.',
        purchase: 'Acquistare',
        notConnected: 'Non collegata',
        integrated: 'Integrato',
        configure: 'Configurare',
        connected: 'Collegato',
        edit: 'Modificare',
        connect: 'Collegare',
        requestYourIntegration: 'Richiedi la tua integrazione',
        toRequestNewIntegration: 'Per richiedere una nuova integrazione, inserisci i dettagli indicati di seguito e poi torneremo da te',
        // name: 'Nome',
        emailId: 'E-mail identificativo utente',
        integrationName: 'Nome dell`integrazione',
        contactNumber: 'Numero di contatto',
        howSoonYouWantToAddThisIntegration: 'Quanto presto vuoi aggiungere questa integrazione',
        sendRequest: 'Invia richiesta',
        cancel: 'Annulla',
        requestSent: 'Richiesta inviata!',
        yourRequestSuccessfullySent: 'La tua richiesta di nuova integrazione è stata inviata con successo.',
        goBack: 'Torna indietro',
        disconnect: 'Disconnessione',
        OopsIntegrationAreNotAvailable: 'Ops! Le integrazioni non sono disponibili in ',
        pleaseUpgradeTo: ' piano. Effettua l\'upgrade al ',
        planForIntegration: ' piano per le integrazioni.',
        placeholders: {
          searchIntegrationByName: 'Cerca l`integrazione per nome',
          enterTeamMemberName: 'Immettere il nome del membro del team',
          enterEmailId: 'Inserisci l`ID e - mail',
          enterIntegrationName: 'Immettere un nome di integrazione',
          searchCountry: 'Cerca paese',
          enterContactNumber: 'Immettere il numero di contatto',
          addComment: 'Aggiungi un commento',
        },
        noDataFound: {
          integrations: 'Integrazioni',
          noIntegrationsFound: 'Nessuna integrazione trovata!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Nome dell`app / CRM',
          statusAsConnectedOrNotConnected: 'Stato come connesso / non connesso',
          clickToConnectToAppOrCrm: 'Fare clic per connettere l`app / CRM',
        },
        feedbackModal: {
          submit: 'Invia',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          agileCrmDomain: 'Dominio Agile CRM',
          nameIsRequired: 'È richiesto il nome',
          emailAddress: 'Indirizzo e-mail',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameter: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'Esempio@company.extent',
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          accessKey: 'Chiave di accesso',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiKeyHere: 'Immettere la chiave API qui',
            enterAccessKeyHere: 'Inserisci la chiave di accesso qui',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          nameColon: 'Nome:',
          inboundWebhookUrl: 'URL Webhook in entrata',
          nameIsRequired: 'È richiesto il nome',
          leadTitle: 'Titolo principale',
          source: 'Fonte',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterInboundWebhookUrl: 'Immettere l`URL Webhook in entrata ...',
            enterTitle: 'Inserisci il titolo',
            enterSource: 'Immettere la fonte',
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          testLead: 'Test Lead',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          testLead: 'Test Lead',
          connect: 'Collegare',
          selectTheQuestion: 'Seleziona la domanda la cui risposta utente si desidera inviare come valore',
          mapWith: 'mappa con',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
            attributes: 'Attributi (chiave)',
            values: 'valori',
          }
        },
        chatGpt: {
          chatGptApiKey: 'Chiave API di Chatgpt (Open AI)',
          chatGpt: 'CHATGPT (apertura AI)',
          readyToRideWave: 'Pronto a cavalcare l`onda GPT ? Integra ora.',
          doNotHaveAnyApiKeyNeedHelp: 'Non hai una chiave API, hai bisogno di aiuto?',
          authenticate: 'Autenticare',
          nowYouCanAccessChatGpt: 'Ora puoi accedere al componente GPT Chat in Chat Flow Builder',
          paymentSuccessful: 'Pagamento di successo!',
          paymentHasBeenCompletedSuccessfully: 'Il pagamento è stato completato con successo. Siete tutti pronti ad allenare il tuo bot',
          cancel: 'Annulla',
          trainNow: 'Allenati adesso',
          paymentInProgress: 'Pagamento in corso ...',
          apiKey: 'ChatGPT API di',
          model: 'Modello',
          integrateWithChatGPT: 'Integra con ChatGPT',
          updateKey: 'Aggiorna chiave',
          connect: 'Collegare',
          update: 'Aggiornamento',
          placeholders: {
            chatGptApiKey: 'Chiave API di Chatgpt (Open AI)',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          leadId: 'ID principale',
          selectLeadId: 'Seleziona LeadId',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          emailAddress: 'Indirizzo e-mail',
          leadTitle: 'Titolo principale',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            exampleEmail: 'Esempio@company.extent',
            enterYourLeadTitle: 'Inserisci il tuo titolo principale',
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          dripCrmAccountId: 'DRIP CRM Account ID',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterAccountIdHere: 'Immettere l`ID account qui',
            enterYourTokenHere: 'Inserisci il tuo gettone qui',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apikey: 'Chiave API',
          accessToken: 'Token di accesso',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          selectParameter: 'Seleziona parametro',
          disconnect: 'Disconnessione',
          update: 'Aggiornamento',
          save: 'Salva',
          placeholders: {
            enterApiKeyHere: 'Immettere la chiave API qui',
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          testLead: 'Test Lead',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          authToken: 'Authtoken',
          source: 'Fonte',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterAuthTokenHere: 'Inserisci l`authtoken qui',
            enterSourceHere: 'Inserisci la fonte qui',
          }
        },
        facebookPixel: {
          facebookPixel: 'Pixel di Facebook',
          getInsight: `Ottieni approfondimenti che trasformano la tua attività in un'azienda con l'integrazione di Facebook Pixel di BotPenguin.`,
          pixelId: 'ID del pixel di Facebook',
          connect: 'collegare',
          disconnect: 'disconnettere',
          placeholders: {
            enterYourId: 'ID del pixel di Facebook'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          companyName: 'Nome della ditta',
          emailAddress: 'Indirizzo e-mail',
          password: 'Parola d`ordine',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapsWith: 'mappe con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterCompanyName: 'Immettere il nome dell`azienda',
            enterEmailAddress: 'Inserisci l`indirizzo email',
            enterPassword: 'Inserire la password',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiKeyHere: 'Inserisci Apikey qui',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          flowluDomain: 'Dominio Flowlu',
          apiToken: 'Token API',
          title: 'Titolo',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'Inserisci il token qui',
            enterYourLeadTitle: 'Inserisci il tuo titolo principale',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          freshdeskDomain: 'Dominio FreshDesk',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          freshworkCrmDomain: 'Dominio CRM di Freshwork',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'Inserisci il token qui',
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          mapWith: 'mappa con',
          select: 'Selezionare',
          update: 'Aggiornamento',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          campaignList: 'Elenco delle campagne',
          selectCampaign: 'Seleziona la campagna',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'statistiche di Google',
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          measurementId: 'ID di misurazione',
          connect: 'Collegare',
          disconnect: 'Disconnessione',
          placeholders: {
            enterYourId: 'Inserisci il tuo ID',
          }
        },
        googleCalendar: {
          testLead: 'Test Lead',
          name: 'Nome:',
          email: 'E-mail:',
          appointmentSummary: 'Riepilogo degli appuntamenti',
          appointmentDescription: 'Descrizione dell`appuntamento',
          addParameters: ' Aggiungi parametri',
          questionColon: 'Domanda:',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          pause: 'Pausa',
          resume: 'Riprendere',
          disconnect: 'Disconnessione',
          reconnect: 'Riconnettersi',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          name: 'Nome :',
          email: 'E-mail :',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
        },
        googleRedirect: {
        },
        googleSheet: {
          testLead: 'Test Lead',
          title: 'Titolo',
          questionColon: 'Domanda:',
          addParameters: 'Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          update: 'Aggiornamento',
          save: 'Salva',
          pause: 'Pausa',
          resume: 'Riprendere',
          disconnect: 'Disconnessione',
          reconnect: 'Riconnettersi',
          placeholders: {
            mappingValues: 'Valori di mappatura',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          name: 'Nome:',
          email: 'E-mail:',
          taskList: 'Elenco delle attività',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          pause: 'Pausa',
          resume: 'Riprendere',
          disconnect: 'Disconnessione',
          reconnect: 'Riconnettersi',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          select: 'Selezionare',
          addParameters: ' Aggiungi parametri',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiHere: 'Inserisci API qui',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          testLead: 'Test Lead',
          accountId: 'Account ID',
          accessToken: 'Token di accesso',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterAccountIdHere: 'Inserisci qui Account-ID',
            enterAccessTokenHere: 'Inserisci il token di accesso qui',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Chiave API',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          testLead: 'Test Lead',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        helpLink: {
          help: 'Aiuto',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          helpRaceCrmDomain: 'Dominio CRM Helprace',
          emailAddress: 'Indirizzo e-mail',
          apiToken: 'Token API',
          connect: 'Collegare',
          update: 'Aggiornamento',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'Esempio@Company.Extension',
            token: 'Gettone',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          domainName: 'Nome del dominio',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiHere: 'Inserisci API qui',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          emailAddress: 'Indirizzo e-mail',
          apiUrl: 'API URL',
          connect: 'Collegare',
          testLead: 'Test Lead',
          // emailAddress: 'Indirizzo e-mail',
          selectProjectName: 'Seleziona il nome del progetto',
          selectIssueType: 'Seleziona il tipo di problema',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapsWith: 'mappe con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
            enterEmailAddress: 'Inserisci l`indirizzo email',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiUrl: 'API URL',
          nameIsRequired: 'È richiesto il nome',
          apiKey: 'Chiave API',
          apiId: 'API ID',
          // nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          testLead: 'Test Lead',
          mapWith: 'mappa con',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          selectTheQuestion: 'Seleziona la domanda la cui risposta utente si desidera inviare come valore',
          placeholders: {
            enterApiUrl: 'Inserisci l`URL API',
            enterApiKey: 'Immettere la chiave API',
            enterApiId: 'Immettere l`ID API',
            attributes: 'Attributi (chiave)',
            values: 'valori',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          accessKey: 'Chiave di accesso',
          secretKey: 'Chiave segreta',
          connect: 'Collegare',
          apiLogs: 'Registri API',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          udpate: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          filterApplied: 'filtro applicato',
          retry: 'Riprovare',
          back: 'Indietro',
          filterYourLeads: 'Filtra i tuoi contatti',
          filterOn: 'Filtrare',
          filterBy: 'Filtro di',
          discard: 'Scartare',
          applyFilter: 'Applica il filtro',
          loadMore: 'Carica di più',
          placeholders: {
            enterAccessKeyHere: 'Inserisci la chiave di accesso qui',
            enterSecretKeyHere: 'Inserisci la chiave segreta qui',
            attributes: 'Attributi (chiave)',
            values: 'valori',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Filtro dati in base alla risposta',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          apiUrl: 'API URL',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiHere: 'Inserisci API qui',
            exampleUrl: 'https://comapanyname.ladesk.com',
          }
        },
        microsoftTeams: {
          downloadPackage: 'Scarica il pacchetto App Microsoft Teams',
          downloadApp: 'Scarica l`app',
          wantToLearnHowToUploadIt: 'Vuoi imparare a caricarlo?',
          needHelp: 'Ho bisogno di aiuto?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiUrl: 'API URL',
          appId: 'APP ID',
          nameIsRequired: 'È richiesto l`ID dell`app',
          dataApiKey: 'Chiave API di dati',
          dataApiKeyIsRequired: 'È richiesta la chiave API di dati',
          connect: 'Collegare',
          testLead: 'Test Lead',
          addParameters: ' Aggiungi parametri',
          mapWith: 'mappa con',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          selectTheQuestion: 'Seleziona la domanda la cui risposta utente si desidera inviare come valore',
          placeholders: {
            enterApiUrl: 'Inserisci l`URL API',
            enterAppId: 'Immettere l`ID app',
            enterDataApiKey: 'Immettere la chiave API di dati',
            attributes: 'Attributi (chiave)',
            pressForMappingQuestion: 'Premere / Per la mappatura della domanda',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          emailAddress: 'Indirizzo e-mail',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          leadId: 'ID principale',
          selectLeadId: 'Seleziona LeadId',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterEmailHere: 'Inserisci e -mail qui',
            enterApiKeyHere: 'Inserisci Apikey qui',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          noCrmDomain: 'Dominio Nocrm',
          leadTitle: 'Titolo principale',
          titleIsRequired: 'Il titolo è richiesto',
          token: 'Gettone',
          tokenIsRequired: 'È richiesto il token',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            exampleDomain: 'https://your_subdomain_here.nocrm.io',
            enterYourLeadTitle: 'Inserisci il tuo titolo principale',
            enterYourToken: 'Inserisci il tuo token',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          emailAddress: 'Indirizzo e-mail',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          secretToken: 'Token segreto',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          appId: 'APP ID',
          appIdIsRequired: 'È richiesto l`ID dell`app',
          apiKey: 'Chiave API',
          apiKeyIsRequired: 'È richiesta la chiave API',
          connect: 'Collegare',
          update: 'Aggiornamento',
          disconnect: 'Disconnessione',
          placeholders: {
            appId: 'APP ID',
            apiKey: 'Chiave API',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          clientId: 'Identificativo cliente',
          connect: 'Collegare',
          clientSecret: 'Segreto del cliente',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          save: 'Salva',
          update: 'Aggiornamento',
          disconnect: 'Disconnessione',
          placeholders: {
            enterClientIdHere: 'Immettere l`ID client qui',
            enterClientSecretHere: 'Inserisci il segreto del cliente qui',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          pipedriverDomain: 'Dominio Pipedriver',
          domainIsRequired: 'Il dominio è richiesto',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          apiKeyIsRequired: 'È richiesta la chiave API',
          appKey: 'Chiave di app',
          appKeyIsRequired: 'È richiesta la chiave di app',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiKey: 'Immettere la chiave API',
            enterAppKey: 'Immettere la chiave dell`app',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          authId: 'AUTH ID',
          authToken: 'Token di auth',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiHere: 'Inserisci API qui',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiKey: 'Immettere la chiave API',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          domain: 'Dominio',
          clientId: 'Identificativo cliente',
          clientSecret: 'Segreto del cliente',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterDomainHere: 'Inserisci il dominio qui',
            enterClientIdHere: 'Immettere l`ID client qui',
            enterClientSecretHere: 'Inserisci il segreto del cliente qui',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          addEventsAndTemplates: 'Aggiungi eventi e modelli:',
          add: ' Aggiungere',
          question: 'Domanda',
          reply: 'Rispondere',
          requestBody: 'Corpo richiesto',
          validateJson: 'Convalida JSON',
          emailAddress: 'Indirizzo e-mail',
          // addParameters: ' Aggiungi parametri',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          selectTheQuestion: 'Seleziona la domanda la cui risposta utente si desidera inviare come valore',
          placeholders: {
            enterApiHere: 'Inserisci API qui',
            enterTemplateId: 'Immettere ID modello',
            typeYourJsonCodeHere: 'Digita il tuo codice JSON qui',
          },
          appTooltip: {
            typeYourJsonCode: 'Digita il tuo codice JSON',
            clickToValidateJsonCode: 'Fare clic per convalidare il codice JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          userName: 'Nome utente',
          licenseKey: 'Chiave di licenza',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterNameHere: 'Immettere il nome qui',
            enterKeyHere: 'Inserisci la chiave qui',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          company: 'Azienda',
          loginId: 'ID Accesso',
          password: 'Password',
          testLead: 'Responsabile di Test',
          connect: 'Connetti',
          selectTheQuestion: 'Seleziona la domanda di cui desideri inviare la risposta degli utenti come valore',
          mapWith: 'Mappa con',
          update: 'Aggiorna',
          save: 'Salva',
          disconnect: 'Disconnetti',
          selectParameter: 'Seleziona parametro',
          placeholders: {
            enterCompanyHere: 'Inserisci il nome dell\'azienda qui',
            enterLoginIdHere: 'Inserisci l\'ID Accesso qui',
            enterPasswordHere: 'Inserisci la Password qui',
            values: 'valori',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          applicationKey: 'Chiave dell`applicazione',
          authToken: 'Token di auth',
          connect: 'Collegare',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApplicationKeyHere: 'Immettere la chiave dell`applicazione qui',
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          accessToken: 'Token Acess',
          connect: 'Collegare',
          testLead: 'Test Lead',
          question: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          webhoperDomain: 'Dominio Webhoper',
          domainIsRequired: 'Il dominio è richiesto',
          companyName: 'Nome della ditta',
          companyIsRequired: 'La società è richiesta',
          leadSource: 'Fonte di piombo',
          leadSourceIsRequired: 'È necessaria la fonte di lead',
          division: 'Divisione',
          divisionIsRequired: 'È richiesta la divisione',
          enterPassword: 'Inserire la password',
          passwordIsRequired: 'E `richiesta la password',
          disconnect: 'Disconnessione',
          update: 'Aggiornamento',
          connect: 'Collegare',
          placeholders: {
            exampleDomain: 'http: // yourcompanydomain',
            enterYourCompanyName: 'Inserisci il nome della tua azienda',
            enterLeadSource: 'Immettere la fonte di lead',
            enterDivision: 'Inserisci la divisione',
            enterPassword: 'Inserire la password',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          name: 'Nome:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'URL Webhook in entrata',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterWebhookUrl: 'Immettere l`URL Webhook',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          emailAddress: 'Indirizzo e-mail',
          apiUrl: 'API URL',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterEmailAddress: 'Inserisci l`indirizzo email',
            enterUrlHere: 'Inserisci URL qui',
            enterTokenHere: 'Inserisci il token qui',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiKey: 'Chiave API',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiHere: 'Inserisci API qui',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          connect: 'Collegare',
          update: 'Aggiornamento',
          disconnect: 'Disconnessione',
          companyName: 'Nome della ditta',
          emailAddress: 'Indirizzo e-mail',
          phone: 'Telefono',
          countryCode: 'Prefisso internazionale',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          // update: 'Aggiornamento',
          save: 'Salva',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          connect: 'Collegare',
          disconnect: 'Disconnessione',
          testLead: 'Test Lead',
          emailAddress: 'Indirizzo e-mail',
          selectOrganizationName: 'Seleziona il nome dell`organizzazione',
          selectDepartmentName: 'Seleziona il nome del dipartimento',
          addParameters: ' Aggiungi parametri',
          questionColon: 'Domanda:',
          select: 'Selezionare',
          mapsWith: 'mappe con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          testLead: 'Test Lead',
          connect: 'Collegare',
          disconnect: 'Disconnessione',
          projectsList: 'Elenco dei progetti',
          selectProjectsName: 'Seleziona il nome dei progetti',
          projectsTaskList: 'Progetti Taskslist',
          selectTaskListName: 'Seleziona il nome della lista delle task',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
        },
        wooCommerce: {
          configure: 'Configurare',
          automatedMessage: 'Messaggio Automatico',
          configureTab: {
            wooCommercePluginApiKey: 'Chiave API del Plugin WooCommerce',
            downloadWooCommercePlugin: 'Scarica il Plugin WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Messaggio di Recupero Carrelli Abbandonati',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Configura i messaggi di recupero automatico per riconquistare i clienti che hanno abbandonato i loro carrelli',
            enableAbandonedCartRecoveryMessaegs: 'Abilita i messaggi di recupero carrelli abbandonati',
            selectTemplate: 'Seleziona il Modello',
            sendMessageAfter: 'Invia il Messaggio Dopo',
            templateMessage: 'Messaggio del Modello',
            orderFulfillMent: 'Evasione dell\'Ordine',
            saveChanges: 'Salva le Modifiche',
            orderCompleted: 'Ordine Completato',
            templateHeader: 'Testa del modello',
            selectFile: 'Seleziona il file',
            enableOrderConfirmationMessages: 'Abilita i messaggi di conferma dell\'ordine',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Invia un messaggio di conferma dell\'ordine non appena un cliente effettua un ordine.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Il messaggio di spedizione dell\'ordine viene inviato quando soddisfi un ordine.',
            enableOrderFulfillmentMessages: 'Abilita i messaggi di evasione dell\'ordine',
            returnExchangeRequest: 'Richiesta di Reso/Scambio',
            theMessageIsSentAfterARefundIsRequested: 'Il messaggio viene inviato dopo che è stata richiesta un rimborso.',
            enableOrderRefundMessages: 'Abilita i messaggi di rimborso dell\'ordine',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
          apiToken: 'Token API',
          nameIsRequired: 'È richiesto il nome',
          connect: 'Collegare',
          testLead: 'Test Lead',
          questionColon: 'Domanda:',
          addParameters: ' Aggiungi parametri',
          select: 'Selezionare',
          mapWith: 'mappa con',
          selectParameter: 'Seleziona parametro',
          update: 'Aggiornamento',
          save: 'Salva',
          disconnect: 'Disconnessione',
          placeholders: {
            enterApiToken: 'Inserisci il token API',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Crea nuova integrazione',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'Al momento non hai alcuna configurazione di integrazione.',
      pleaseCreateOneToIntegrateYourFavourite: 'Crea uno per integrare il tuo preferito',
      appsWith: 'app con',
      integrationName: 'Nome dell`integrazione',
      integrationNameIsRequired: 'È richiesto il nome dell`integrazione',
      continue: 'Continua',
      addAnImageToRepresentYourIntegration: 'Aggiungi un`immagine per rappresentare la tua integrazione.',
      description: 'Descrizione',
      submit: 'Invia',
      youHaveSuccessfullyCreatedIntegration: 'Hai creato con successo l`integrazione.Ora puoi configurarlo.',
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: 'Inserisci un URL API valido',
      requestMethod: 'Metodo di richiesta',
      get: 'Ottenere',
      post: 'Inviare',
      put: 'Mettere',
      patch: 'Toppa',
      queryParameter: 'Parametro di query',
      addParameter: ' Aggiungi parametro',
      mapsWith: ' mappe con',
      question: 'Domanda',
      addQuestion: ' Aggiungi domanda',
      select: 'Selezionare',
      selectParameter: 'Seleziona parametro',
      header: 'Intestazione',
      addHeader: ' Aggiungi intestazione',
      value: 'Valore',
      connect: 'Collegare',
      requestBody: 'Corpo richiesto',
      validate: 'Convalidare',
      // select: 'Selezionare',
      key: 'Chiave',
      // value: 'Valore',
      // connect: 'Collegare',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' Può anche essere integrato con questo CRM gratuito.',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: 'Inserisci un URL API valido',
        requestMethod: 'Metodo di richiesta:',
        get: 'Ottenere',
        post: 'Inviare',
        put: 'Mettere',
        patch: 'Toppa',
        queryParameter: 'Parametro di query',
        addParameter: ' Aggiungi parametro',
        header: 'Intestazione',
        addHeader: ' Aggiungi intestazione',
        requestBody: 'Corpo richiesto',
        validateJson: 'Convalida JSON',
        connect: 'Collegare',
        update: 'Aggiornamento',
        delete: 'Eliminare',
        selectTheQuestion: 'Seleziona la domanda la cui risposta utente si desidera inviare come valore',
        appTooltip: {
          typeYourJsonCode: 'Digita il tuo codice JSON',
          clickToValidateJsonCode: 'Fare clic per convalidare il codice JSON',
        }
      },
      list: {
        customAppsIntegrations: 'Integrazioni di app personalizzate',
        integrateYourAppsWith: 'Integrare le tue app con',
        help: 'Aiuto',
        addIntegration: ' Aggiungi integrazione',
        name: 'Nome',
        status: 'Stato',
        action: 'Azione',
        insatlled: 'Installato',
        edit: 'Modificare',
        connect: 'Collegare',
        requestYourIntegration: 'Richiedi la tua integrazione',
        toRequestNewIntegration: 'Per richiedere una nuova integrazione, inserisci i dettagli indicati di seguito e poi torneremo da te',
        // name: 'Nome',
        emailId: 'E-mail identificativo utente',
        integrationName: 'Nome dell`integrazione',
        contactNumber: 'Numero di contatto',
        howSoonYouWantToAddThisIntegration: 'Quanto presto vuoi aggiungere questa integrazione',
        sendRequest: 'Invia richiesta',
        cancel: 'Annulla',
        delete: 'Eliminare',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Ops! Le tue integrazioni personali sono supportate solo in',
        plan: 'Piano',
        pleaseUpgradeTo: `Effettua l'upgrade a`,
        toCreateOwnIntegrations: `per creare Integrazioni Personalizzate`,
        appTooltip: {
          integrations: 'Integrazioni',
          noIntegrationsFound: 'Nessuna integrazione trovata!',
          nameOfYourApp: 'Nome della tua app',
          clickToConnectApp: 'Fare clic per connettere l`app',
          statusAsConnectedOrNotConnected: 'Stato come connesso / non connesso',
        }
      }
    }
  }
};
