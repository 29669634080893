export const agency = {
  activities: {
    activities: 'Activități',
    upcomingRenewals: 'Renovări viitoare',
    subscriptionActivities: 'Activități de abonament',
    upcomingDowngradeRequests: 'Solicitări de degradare viitoare',
    serialNumber: 'Nr. crt.',
    email: 'Email',
    phoneNumber: 'Număr de telefon',
    currentPlan: 'Plan curent',
    price: 'Preț',
    daysLeft: `Zile rămase`,
    noDataFound: {
      upcomingDowngrades: 'Degradări viitoare',
      noUpcomingDowngradesFoundYet: 'Nu s-au găsit degradări viitoare încă!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Renovări viitoare ale clienților',
      subscriptionActivities: 'Activități de abonament',
      upcomingDowngradeRequestsOfCustomers: 'Solicitări de degradare viitoare ale clienților',
      serialNumber: 'Număr de serie',
      customerEmail: 'Email client',
      customerPhoneNumber: 'Număr de telefon al clientului',
      currentPlanOfCustomer: 'Plan curent al clientului',
      currentPlanPrice: 'Prețul planului curent',
      daysLeft: `Zile rămase din planul curent al clientului`
    },
  },
  configuration: {
    configurationsHeader: 'Configurări',
    emailHeader: 'Email',
    razorPayHeader: 'RazorPay',
    invoiceDetailsHeader: 'Detalii Factură',
    stripeHeader: 'Stripe',
    appTooltip: {
      integrateYourEmailProviderDetails: 'Integrați detaliile furnizorului dvs. de email pentru a trimite ' +
      'emailuri către clienți fără probleme.',
      enterYourRazorPayAccountDetails: 'Introduceți detaliile contului dvs. RazorPay pentru procesarea fără ' +
      'probleme a plăților clienților.',
      enterYourStripeAccountDetails: 'Introduceți detaliile contului dvs. Stripe pentru procesarea fără probleme a plăților clienților.',
      enterYourCompanyDetails: 'Introduceți detaliile companiei dvs. pentru facturare precisă și generare de facturi profesionale.',
    },
    email: {
      testEmail: 'Test Email',
      addEmailIntegrationsDetails: 'Adăugați Detalii Integrație Email',
      integrationDescription: 'Pentru a integra detaliile furnizorului dvs. de email, vă rugăm să furnizați informațiile furnizorului dvs. de email.' +
        'Toate emailurile de comunicare vor fi trimise clienților dvs. folosind această adresă de email, asigurând livrarea constantă și fiabilă a notificărilor și actualizărilor importante.',
      awsRegion: 'Regiune AWS',
      awsAccessKeyId: 'ID cheie de acces AWS',
      awsSecretAccessKey: 'Cheie secretă de acces AWS',
      reset: 'Resetare',
      addDetails: 'Adăugare Detalii',
      updateDetails: 'Actualizare Detalii',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'Introduceți regiunea AWS. Regiunea determină locația fizică a resurselor dvs. și afectează factori precum latenta datelor și conformitatea reglementărilor.',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'Introduceți ID-ul cheii de acces AWS. Această cheie identifică în mod unic contul dvs. AWS și este necesară pentru autentificarea și accesul securizat la serviciile AWS.',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'Introduceți cheia secretă de acces AWS. Această cheie este utilizată pentru autentificarea securizată și accesul la serviciile AWS.',
      },
      placeholders: {
        enterAwsRegion: 'Introduceți regiunea AWS',
        enterAwsAccessKeyId: 'Introduceți ID-ul cheii de acces AWS',
        enterAwsSecretAccessKey: 'Introduceți cheia secretă de acces AWS',
      },
      modal: {
        deleteEmailIntegration: 'Ștergeți Integrarea Email',
        wantToDeleteEmailIntegration: 'Sunteți sigur că doriți să ștergeți această Integrare Email?',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Adăugați Detalii Integrație Facebook',
      integrateYourEmailProviderDetails: 'Integrați detaliile furnizorului dvs. de email. Toate emailurile vor fi trimise folosind această adresă de email către clienți',
      facebookAppId: 'ID Aplicație Facebook',
      facebookSecretAccessKey: 'Cheie Secretă de Acces Facebook',
      reset: 'Resetare',
      cancel: 'Anulare',
      addDetails: 'Adăugare Detalii',
      updateDetails: 'Actualizare Detalii',
      delete: 'Ștergere',
      testEmail: 'Test Email',
      modal: {
        deleteFacebookIntegration: 'Ștergeți Integrarea Facebook',
        wantToDeleteFacebookIntegration: 'Sunteți sigur că doriți să ștergeți această Integrare Email?',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Introduceți Cheia Secretă de Acces Facebook',
        enterFacebookAppId: 'Introduceți ID-ul Aplicației Facebook',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personalizați mesajul pentru a apărea în fereastra de chat imediat ce un utilizator bot trimite o solicitare pentru Chat Live',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: 'Adăugați Detalii Factură',
      invoicesDescription: 'Pentru a genera facturi pentru clienții dvs., vă rugăm să furnizați detaliile companiei dvs., inclusiv numele companiei,' +
        'adresa, informațiile de contact și orice alte informații relevante necesare pentru facturare precisă.' +
        'Aceste informații vor fi utilizate pentru a crea facturi profesionale în numele companiei dvs. pentru fiecare tranzacție.',
      companyName: 'Nume Companie',
      country: 'Țară',
      selectCountry: 'Selectați Țara',
      stateOrRegion: 'Stat/Regiune',
      streetAddress: 'Adresa Străzii',
      gstin: 'GSTIN',
      postalCode: 'Cod Poștal',
      addDetails: 'Adăugare Detalii',
      updateDetails: 'Actualizare Detalii',
      reset: 'Resetare',
      delete: 'Ștergere',
      loadMore: 'Încărcați Mai Multe',
      time: 'Oră',
      phoneNumber: 'Număr de Telefon',
      email: 'Email',
      status: 'Stare',
      message: 'Mesaj',
      response: 'Răspuns',
      placeOfSupply: 'Loc de Furnizare',
      placeholders: {
        enterYourCompanyName: 'Introduceți numele companiei dvs.',
        enterYourStateOrRegion: 'Introduceți statul/regiunea dvs.',
        enterYourStreetAddress: 'Introduceți adresa străzii dvs.',
        enterYourGstin: 'Introduceți GSTIN-ul dvs.',
        enterPostalCode: 'Introduceți codul poștal',
        enterPlaceOfSupply: 'Introduceți locul de furnizare',
      },
      modal: {
        deleteEmailIntegration: 'Ștergeți Integrarea Email',
        wantToDeleteEmailIntegration: 'Sunteți sigur că doriți să ștergeți această Integrare Email?',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME:
          'Introduceți numele companiei dvs. Aceste informații vor fi utilizate în scopuri de identificare și brand în facturi.',
        TOOLTIP_COUNTRY: 'Selectați țara dvs. Aceste informații vor fi utilizate în scopuri de identificare și brand în facturi.',
        TOOLTIP_STATE: 'Introduceți statul/regiunea dvs. Aceste informații vor fi utilizate în scopuri de identificare și brand ' +
        'în facturi.',
        TOOLTIP_STREET_ADDRESS: 'Introduceți adresa străzii dvs. Aceste informații vor fi utilizate în scopuri de identificare ' +
        'și brand în facturi.',
        TOOLTIP_GSTIN: 'Introduceți GSTIN-ul dvs. Aceste informații vor fi utilizate în scopuri de identificare și brand în facturi.',
        TOOLTIP_POSTAL_CODE: 'Introduceți codul poștal. Aceste informații vor fi utilizate în scopuri de identificare și brand ' +
        'în facturi.',
        TOOLTIP_PLACE_OF_SUPPLY: 'Introduceți locul de furnizare. Aceste informații vor fi utilizate în scopuri de identificare ' +
        'și brand în facturi.',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'Adăugați Detalii Integrare RAZORPAY',
      integrationDescription: 'Pentru a integra detaliile contului dvs. RazorPay, vă rugăm să furnizați informațiile contului dvs. RazorPay.' +
        'Toate plățile ale clienților vor fi procesate prin contul dvs. RazorPay pentru tranzacții fără probleme.',
      razorPayAccessKeyId: 'ID Cheie de Acces RAZORPAY',
      razorPaySecretAccessKey: 'Cheie Secretă de Acces RAZORPAY',
      reset: 'Resetare',
      addDetails: 'Adăugare Detalii',
      updateDetails: 'Actualizare Detalii',
      loadMore: 'Încărcați Mai Multe',
      time: 'Oră',
      phoneNumber: 'Număr de Telefon',
      email: 'Email',
      status: 'Stare',
      message: 'Mesaj',
      response: 'Răspuns',
      placeholders: {
        enterRazorPayAccessKeyId: 'Introduceți ID-ul Cheii de Acces Razorpay',
        enterRazorPaySecretAccessKey: 'Introduceți Cheia Secretă de Acces Razorpay',
        enterRazorPayAccessId: 'Introduceți ID-ul de Acces RazorPay',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'Introduceți ID-ul Cheii de Acces Razorpay. Această cheie identifică în mod unic integrarea dvs. cu Razorpay și este necesară pentru tranzacțiile securizate de plată.',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Introduceți Cheia Secretă de Acces Razorpay. Această cheie este esențială pentru comunicarea securizată între aplicație și Razorpay, permițând procesarea fără probleme a tranzacțiilor și gestionarea plăților.',
      },
      modal: {
        deleteRazorPayIntegration: 'Ștergeți Integrarea Email',
        wantToDeleteThisIntegrtion: 'Sunteți sigur că doriți să ștergeți această Integrare Email?',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Adăugați Detalii Integrare Stripe',
      integrationDescription: 'Pentru a integra detaliile contului dvs. Stripe, vă rugăm să furnizați informațiile contului dvs. Stripe.' +
        'Toate plățile ale clienților vor fi procesate prin contul dvs. Stripe pentru tranzacții fără probleme.',
      stripeSecretAccessKey: 'Cheie Secretă de Acces Stripe',
      stripePublicKey: 'Cheie Publică Stripe',
      reset: 'Resetare',
      addDetails: 'Adăugare Detalii',
      updateDetails: 'Actualizare Detalii',
      cancel: 'Anulare',
      loadMore: 'Încărcați Mai Multe',
      time: 'Oră',
      phoneNumber: 'Număr de Telefon',
      email: 'Email',
      status: 'Stare',
      message: 'Mesaj',
      response: 'Răspuns',
      placeholders: {
        enterStripeSecretAccessKey: 'Introduceți Cheia Secretă de Acces Stripe',
        enterStripePublicKey: 'Introduceți Cheia Publică Stripe',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Introduceți Cheia Secretă de Acces Stripe. Această cheie este folosită pentru autentificarea securizată a integrării dvs. cu Stripe și este necesară pentru procesarea plăților clienților.',
        TOOLTIP_PUBLIC_KEY: 'Introduceți Cheia Publică Stripe. Această cheie este folosită pentru autentificarea securizată a integrării dvs. cu Stripe pe partea clientului și este necesară pentru procesarea plăților clienților.',
      },
      modal: {
        deleteStripeIntegration: 'Ștergeți Integrarea Stripe',
        wantToDeleteStripeIntegration: 'Sunteți sigur că doriți să ștergeți această Integrare Stripe?',
      }
    }
  },

  customer: {
    manageCustomers: 'Gestionați Clienții',
    addNewCustomers: 'Adăugați Clienți Noi',
    ensureYouHaveAddedAllYourCustomersHere: 'Asigurați-vă că ați adăugat toți clienții dvs. aici',
    customerName: 'Nume Client',
    nameIsRequired: 'Numele este obligatoriu',
    emailId: 'ID Email',
    enterAValidEmail: 'Introduceți o adresă de email validă',
    createPassword: 'Creați Parola',
    enterAStrongPassword: 'Introduceți o parolă puternică',
    confirmPassword: 'Confirmați Parola',
    cancel: 'Anulare',
    customer: 'Client',
    searchByNameOrEmail: 'Căutați după nume sau email',
    filters: 'Filtre',
    allCustomers: 'Toți Clienții',
    paidCustomers: 'Clienții Plătitori',
    freeCustomers: 'Clienții Gratuiti',
    messageAddOnCustomers: 'Clienți cu Funcționalitate Adăugată Mesaj',
    botWhiteLabelCustomers: 'Clienți Whitelabel pentru Bot',
    sortBy: 'Sortare după',
    sortByFrequency: 'Sortare după frecvență',
    all: 'Toate',
    joinedDate: 'Dată Înregistrare',
    plan: 'Plan',
    lastLogin: 'Ultima Conexiune',
    email: 'Email',
    nextDue: 'Următoarea Scadență',
    frequency: 'Frecvență',
    clearAll: 'Ștergeți Toate',
    apply: 'Aplică',
    messageConsumed: 'Mesaj Consumat',
    addCustomer: 'Adăugați Client',
    serialNumber: 'Nr. Crt.',
    name: 'Nume',
    contact: 'Contact',
    limit: 'Limită',
    location: 'Locație',
    actions: 'Acțiuni',
    joinedOn: 'Înregistrat pe',
    typeOfCustomer: 'Tip de Client',
    exceptWhatsapp: ' (Cu excepția WhatsApp)',
    exceptWA: '(Cu excepția WA)',
    wa: '(WA)',
    loadMore: 'Încărcați Mai Multe',
    conversationsWhatsapp: ' Conversații (WhatsApp)',
    appTooltip: {
      enterCustomerEmail: 'Introduceți emailul clientului',
      enterCustomerPassword: 'Introduceți parola clientului',
      enterCustomerConfirmPassword: 'Introduceți confirmarea parolei clientului',
      enterCustomerName: 'Introduceți numele clientului',
    },
    placeholders: {
      enterCustomerEmail: 'Introduceți emailul clientului',
      searchByNameOrEmail: 'Căutați după nume sau email',
      enterPassword: 'Introduceți parola',
      enterCustomerName: 'Introduceți numele clientului',
    }
  },
  dashboard: {
    dashboard: 'Tablou de bord',
    overview: 'Prezentare generală',
    totalCustomers: 'Total Clienți',
    paidCustomers: 'Clienți Plătitori',
    freeCustomers: 'Clienți Gratuiți',
    thisWeeksSignUp: 'Înregistrări din această săptămână',
    todaysSignUp: 'Înregistrări din astăzi',
    totalEarnings: 'Venituri Totale',
    nA: 'N/A',
    customerName: 'Nume Client',
    nameIsRequired: 'Numele este obligatoriu',
    emailId: 'ID Email',
    enterAValidEmail: 'Introduceți o adresă de email validă',
    editCustomer: 'Editează Clientul',
    recentSignUps: 'Înregistrări Recente',
    serialNumber: 'Nr. Crt.',
    name: 'Nume',
    email: 'Email',
    contact: 'Contact',
    plan: 'Plan',
    limit: 'Limită',
    location: 'Locație',
    lastLogin: 'Ultima Conexiune',
    joinedOn: 'Înregistrat pe',
    actions: 'Acțiuni',
    exceptWhatsapp: ' (Cu excepția WhatsApp)',
    messageConsumed: 'Mesaj Consumat',
    conversationsWhatsapp: ' Conversații (WhatsApp)',
    placeholders: {
      enterCustomerName: 'Introduceți numele clientului',
      enterCustomerEmail: 'Introduceți emailul clientului',
    },
    modal: {
      deleteCustomer: 'Ștergeți Clientul',
      areYouSure: 'Sigur doriți să ștergeți acest client?',
    }
  },
  downgradeRequests: {
    activites: 'Activități',
    upcomingRenewals: 'Renovări Viitoare',
    subscriptionActivities: 'Activități Abonament',
    upcomingDonwgradeRequests: 'Cereri Viitoare de Degradare',
    serialNumber: 'Nr. Crt.',
    email: 'Email',
    phoneNumber: 'Număr de Telefon',
    currentPlan: 'Plan Curent',
    requestedOn: 'Solicitat La',
    downgradeOn: 'Degradare La',
    nA: 'N/A',
    commaMonthly: ', Lunar',
    commaYearly: ' , Anual',
    noDataFound: {
      downgradeRequests: 'Cereri de Degradare',
      noDowngradeRequestFound: 'Nu s-au găsit Cereri de Degradare încă!',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Renovări viitoare ale clienților',
      subscriptionActivities: 'Activități de abonament',
      upcomingDowngradeOfCustomers: 'Cereri viitoare de degradare ale clienților',
      serialNumber: 'Număr de serie',
      customerEmail: 'Email client',
      customerPhoneNumber: 'Număr de telefon al clientului',
      currentPlanOfCustomer: 'Plan curent al clientului',
      currentPlanPrice: 'Prețul planului curent',
    }
  },
  emailIntegration: {
    emailIntegration: 'Integrare Email',
    addEmailIntegrationDetails: 'Adaugă Detalii Integrare Email',
    integrateYourEmailProviderDetails: 'Integrează detaliile furnizorului tău de email. Toate emailurile vor fi trimise folosind acest email către clienții tăi',
    awsRegion: 'Regiune AWS',
    awsRegionIsRequired: 'Regiunea AWS este necesară',
    awsAccessKeyId: 'Cheie de Acces AWS Id',
    awsAccessKeyIdIsRequired: 'Cheia de Acces AWS Id este necesară',
    awsSecretAccessKey: 'Cheie Secretă de Acces AWS',
    awsSecretAccessKeyIsRequired: 'Cheia Secretă de Acces AWS este necesară',
    cancel: 'Anulează',
    test: 'Testează',
    action: 'Acțiune',
    testEmail: 'Testează Email',
    addDetails: 'Adaugă Detalii',
    updateDetails: 'Actualizează Detalii',
    saveDetails: 'Salvează Detalii',
    placeholders: {
      enterAwsRegion: 'Introdu Regiunea AWS',
      enterAwsAccessKeyId: 'Introdu Cheia de Acces AWS Id',
      enterAwsSecretAccessKey: 'Introdu Cheia Secretă de Acces AWS',
    },
    modal: {
      deleteEmailIntegration: 'Șterge Integrare Email',
      areYouSure: 'Sigur vrei să ștergi această Integrare Email?',
    },
    noDataFound: {
      emailIntegration: 'Integrare Email',
      noEmailIntegrationFoundYet: 'Nu s-au găsit încă Integrări Email!',
    }
  },
  subscription: {
    activities: 'Activități',
    upcomingRenewals: 'Renovări Viitoare',
    subscriptionActivities: 'Activități Abonament',
    upcomingDonwgradeRequests: 'Cereri Viitoare de Degradare',
    filters: 'Filtre',
    manageActivities: 'Administrează Activități',
    sortBy: 'Sortează după',
    all: 'Toate',
    upgrades: 'Upgrade-uri',
    donwgrades: 'Degradări',
    renewals: 'Renovare',
    serialNumber: 'Nr. Crt.',
    email: 'Email',
    phoneNumber: 'Număr de Telefon',
    previousPlan: 'Plan Anterior',
    newPlan: 'Plan Nou',
    description: 'Descriere',
    date: 'Dată',
    nA: 'N/A',
    upgraded: 'upgradat',
    commaYearly: ' , Anual',
    commaMonthly: ' , Lunar',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'Nu s-au găsit încă Activități Abonament!',
      subscriptionsActivities: 'Activități Abonament',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'Renovări viitoare ale clienților',
      subscriptionActivities: 'Activități de abonament',
      upcomingDowngradeRequests: 'Cereri viitoare de degradare ale clienților',
      serialNumber: 'Număr de serie',
      customerEmail: 'Email client',
      customerPhoneNumber: 'Număr de telefon al clientului',
      previousPlan: 'Planul anterior al clientului',
      newPlan: 'Planul nou al clientului',
      description: 'Descrierea planului',
      typeOfPayment: 'Tipul de plată',
    }
  },
  feedbacks: {
    feedbacks: 'Feedback-uri',
    serialNumber: 'Nr. Crt.',
    customer: 'Client',
    type: 'Tip',
    rating: 'Evaluare',
    reason: 'Motiv',
    description: 'Descriere',
    createdAt: 'Creat La',
    nA: 'N/A',
    loadMore: 'Încarcă Mai Mult',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'Feedback-uri pentru Ștergerea Contului și a Bot-ului',
      noFeedbacksFoundYet: 'Nu s-au găsit încă Feedback-uri!',
    },
    appTooltip: {
      serialNumber: 'Număr de serie',
      customerEmail: 'Email client',
      feedbackForBotOrAccountDeletion: 'Feedback pentru (Ștergere Bot/Cont)',
      customerRating: 'Evaluarea clientului',
      customerReason: 'Motivul clientului',
      feedbackCreatedAt: 'Feedback creat la',
      customerFeedback: 'Feedback de la Client',
    }
  }
};
