export const team = {
  addMember: {
    team: 'Команда',
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    addAMember: 'Добавить член',
    addYourTeamMembersText: 'Добавьте членов вашей команды в качестве агентов поддержки в чат с пользователями ботов.',
    name: 'Имя',
    nameIsRequired: 'Имя требуется',
    email: 'Электронная почта',
    enterAValidEmail: 'Введите действующее электронное письмо',
    password: 'Пароль',
    passwordMustContain: 'Пароль должен содержать',
    atleast6Characters: 'По крайней мере 6 символов,',
    oneUpperCaseLetter: '1 верхняя буква',
    oneSpecialCharacter: '1 Специальный персонаж*',
    profileIcon: 'Значок профиля',
    whatsappNumber: 'Номер WhatsApp',
    leadVisibility: 'Ведущая видимость*',
    addMember: 'Добавить участников',
    role: 'Роль',
    createdOn: 'Создано на',
    action: 'Действие',
    updateMember: 'Обновление участника',
    add: 'Добавлять',
    cancel: 'Отмена',
    selectVisibilityOption: 'Выберите опцию видимости',
    all: 'Все',
    assigned: 'Назначенный',
    nA: 'НА',
    admin: 'Администратор',
    agent: 'Агент',
    placeholders: {
      enterMemberName: 'Введите имя участника',
      enterMemberEmailAddress: 'Введите адрес электронной почты участника',
      createPasswordForMember: 'Создайте пароль для участника',
      searchCountry: 'Страна поиска',
      enterWhatsappNumber: 'Введите номер WhatsApp',
      selectLeadsVisibility: 'Выберите «Следует видимость»',
      searchTeamByNameOrEmail: 'Поиск члена команды по имени или электронной почте',
    },
    modal: {
      deleteTeamMember: 'Удалить член команды',
      wantToDeleteThisTeamMember: 'Вы уверены, что хотите удалить этого члена команды?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Нажмите, чтобы добавить нового члена команды',
      profileIconOfTeamMember: 'Значок профиля/изображение члена команды',
      nameOfTeamMember: 'Имя члена команды',
      emailOfTeamMember: 'Электронная почта члена команды',
      whatsappNumberOfTeamMember: 'WhatsApp Количество члена команды',
      roleOfTeamMemberAsAdminOrAgent: 'Роль члена команды в качестве администратора/агента',
      dateAndTimeOfCreatingTeamMemberRole: 'Дата и время создания роли члена команды',
      editOrDeleteTeamMember: 'Редактировать/удалить член команды',
      editTeamMembersDetails: 'Редактировать подробности члена команды.',
      removeAccessOfTeamMemberByDeletingAccount: 'Удалить доступ члена команды, удалив учетную запись',
    }
  },
  users: {
    manageMembers: 'Управление участниками',
    managerMembersWhichCanAccessPanel: 'Управляйте вашей партнерской командой, которая может получить доступ к вашей панели',
    createNewMember: 'Создать нового участника',
    createMember: 'Создать участника',
    updateMember: 'Обновить участника',
    deleteTitleMember: 'Удалить участника',
    deleteDescriptionMember: 'Вы уверены, что хотите удалить этого участника?',
    noMemberFound: 'Участник не найден!',
    titleMember: 'Создать участника',
    manageUsers: 'Управление пользователями',
    createNewUser: 'Создать нового пользователя',
    managerUserWhichCanAccessPanel: 'Управление пользователями, которые могут получить доступ к вашей панели',
    phoneNumber: 'Номер телефона',
    discard: 'Отменить',
    createUser: 'Создать пользователя',
    updateUser: 'Обновить пользователя',
    noUserFOund: 'Пользователь не найден!',
    title: 'Создать пользователя',
    profileIcon: 'Иконка профиля*',
    name: 'Имя*',
    email: 'Email*',
    password: 'Пароль*',
    selectRole: 'Выберите Роль*',
    selectARole: 'Выберите роль',
    selectLeadVisibility: 'Выберите Видимость Лида*',
    confirmPassword: 'Подтвердите Пароль*',
    tableHeadingName: 'Имя',
    tableHeadingEmail: 'Эл. почта',
    tableHeadingPhoneNumber: 'Номер телефона',
    tableHeadingRole: 'Роль',
    tableHeadingStatus: 'Статус',
    tableHeadingAction: 'Действие',
    noUserFound: 'Пользователи не найдены!',
    deleteTitle: 'Удалить Пользователей',
    deleteDescription: 'Вы уверены, что хотите удалить этого пользователя?',
    placeholders: {
      searchHere: 'Искать здесь',
      enterName: 'Введите имя',
      enterEmail: 'Введите адрес электронной почты',
      searchCountry: 'Искать страну',
      enterPhoneNumber: 'Введите номер телефона',
      enterPassword: 'Введите пароль',
      enterConfirmPassword: 'Введите подтверждение пароля'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Удалить пользователей',
      TOOLTIP_DELETE_MEMBERS: 'Удалить участников'
    }
  },
  roles: {
    manageRoles: 'Управление ролями',
    manageYourRoles: 'Управление вашими ролями.',
    name: 'Имя*',
    description: 'Описание',
    discard: 'Отмена',
    createRole: 'Создать роль',
    creteARole: 'Создать роль',
    updateRole: 'Обновить роль',
    assignPermissions: 'Назначить разрешения',
    noUserFound: 'Роли пользователей не найдены!',
    tableHeadingName: 'Название',
    tableHeadingDescription: 'Описание',
    tableHeadingStatus: 'Статус',
    tableHeadingAction: 'Действие',
    module: 'Модуль',
    read: 'Читать',
    write: 'Писать',
    remove: 'Удалить',
    notes: 'Заметки',
    deleteTitle: 'Удалить Роль',
    deleteDescription: 'Внимание: При удалении этой роли также будут удалены все пользователи, назначенные на эту роль. \n Вы уверены, что хотите удалить эту роль?',
    deleteMemberDescription: 'Предупреждение: При удалении этой роли будут также удалены все участники, назначенные на эту роль. Вы уверены, что хотите удалить эту роль?',
    placeholders: {
      enterRoleName: 'Введите имя роли',
      searchHere: 'Искать здесь',
      enterDescriptionForRole: 'Введите описание для роли'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Разделы, к которым можно разрешить или запретить доступ пользователям',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Нажмите, чтобы добавить новую роль',
    }
  },
  accessControl: {
    users: 'Пользователи',
    members: 'Участники',
    partnerTeam: 'Партнерская команда',
    roles: 'Роли'
  }
};
