const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'मेरा खाता',
  yourProfileAndPasswordSecurity: 'आपकी प्रोफ़ाइल और पासवर्ड सुरक्षा',
  detailsHeader: 'विवरण',
  passwordHeader: 'पासवर्ड',
  customAttributeHeader: 'कस्टम विशेषताएँ',
  tagsHeader: 'टैग्स',
  accessTokenHeader: 'एक्सेस टोकन',
  thirdPartyIntegrationsHeader: 'तृतीय पक्ष एकीकरण',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'अपना खाता प्रोफ़ाइल अपडेट करें',
    TOOLTIP_PASSWORD_HEADING: 'अपना पासवर्ड बदलें',
    TOOLTIP_TAGS_HEADING: 'अपने टैग प्रबंधित करें',
    TOOLTIP_THIRD_PARTY_HEADING: 'तृतीय पक्ष एकीकरण',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `एक सुरक्षित पासवर्ड आपके ${brand} खाते की सुरक्षा में मदद करता है`,
    TOOLTIP_INTEGRATIONS: 'एकीकृत',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'कस्टम विशेषताएँ जोड़ें या अपडेट करें',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'अपने कस्टम गुणों का प्रबंधन करें'
  },
  accessToken: {
    accessToken: 'एक्सेस टोकन',
    generateYourAccessTokenForUsingPlatformAPIs: 'प्लेटफ़ॉर्म एपीआई का उपयोग करने के लिए अपनी एक्सेस टोकन उत्पन्न करें',
    generateApiToken: 'एपीआई टोकन उत्पन्न करें',
    revokeApiToken: 'एपीआई टोकन को रद्द करें',
    regenerateApiToken: 'एपीआई टोकन को पुनर्जीवित करें',
    placeholders: {
      yourApiKey: 'आपकी एपीआई कुंजी'
    }
  },
  details: {
    name: 'नाम',
    emailId: 'ईमेल आईडी',
    yourPlan: 'तुम्हारी योजना',
    messagesPerMonth: ' संदेश/महीना',
    daysOfStoragePlan: ' भंडारण योजना के दिन',
    conditionalFeatures: 'सशर्त विशेषताएं',
    upgradeToEnterprisePlanToGetMore: 'अधिक प्राप्त करने के लिए एंटरप्राइज़ प्लान में अपग्रेड करें',
    featuresAndStorageCapacity: 'फ़ीचर और भंडारण क्षमता',
    upgradePlan: 'अपग्रेड योजना',
    submit: 'जमा करना',
    selectLanguage: 'भाषा चुने',
    baby: 'बेबी',
    king: 'किंग',
    conversationsperMonth: 'अनुवांशिक/महीना',
    english: 'अंग्रेज़ी',
    hindi: 'हिंदी',
    german: 'जर्मन',
    french: 'फ्रांसीसी',
    portuguese: 'पुर्तगाली',
    spanish: 'स्पैनिश',
    italian: 'इतालवी',
    russian: 'रूसी',
    chatBotBrandName: 'चैट बॉट ब्रांड नाम',
    placeholders: {
      john: 'जॉन',
      email: 'ईमेल',
      searchLanguage: 'खोज भाषा',
      chatBotBrandName: 'चैट बॉट ब्रांड नाम'
    },
    appTooltip: {
      TOOLTIP_NAME: 'अपना प्रोफ़ाइल नाम दर्ज करें',
      TOOLTIP_EMAIL_ADDRESS: 'अपना ईमेल पता दर्ज करें',
    }
  },
  customAttribute: {
    addAttribute: 'विशेषता जोड़ें',
    discard: 'छोड़ें',
    customAttribute: 'कस्टम विशेषताएँ',
    checkOrAddCustomAttribute: 'यहां आप अपनी कस्टम विशेषताओं की जाँच और जोड़ सकते हैं।',
    attributeKey: 'विशेषता कुंजी',
    attributeName: 'विशेषता नाम',
    bot: 'बोट',
    flow: 'फ्लो',
    loadMore: 'और लोड करें',
    question: 'सवाल',
    action: 'क्रिया',
    search: 'खोजें',
    selectBot: 'बोट चुनें',
    selectFlow: 'फ्लो चुनें',
    selectQuestion: 'सवाल चुनें',
    noDataFoundHeading: 'आपके पास अभी तक कोई विशेषता नहीं बनाई गई है',
    noDataFoundSubheading: 'आपकी सभी उपासना यहाँ दिखाई देगी',
    deleteTitle: 'विशेषता हटाएं',
    deleteDescription: 'क्या आप निश्चित रूप से इस विशेषता को हटाना चाहते हैं?',
    deleteAttributes: 'विशेषताएँ हटाएं',
    areYouSureYouWantToDeleteThisAttributes: 'क्या आप वाकई इस विशेषता को हटाना चाहते हैं?',
    editAttribute: 'विशेषता संपादित करें',
    headsUp: 'सचेत',
    okay: 'ठीक',
    dataAddedToLead: 'आपके एपीआई को मैप किए गए गुणगतियाँ अपडेट नहीं की जाएंगी। कृपया एपीआई को इसके अनुसार अपडेट करें',
    placeholders: {
      enterAttributeKey: 'विशेषता कुंजी दर्ज करें',
      enterAttributeName: 'विशेषता नाम दर्ज करें',
      selectBot: 'बोट चुनें',
      selectFlow: 'फ्लो चुनें',
      selectQuestion: 'सवाल चुनें',
      search: 'विशेषता नाम से खोजें'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'विशेषता कुंजी दर्ज करें',
      TOOLTIP_ATTRIBUTE_NAME: 'विशेषता नाम दर्ज करें',
      TOOLTIP_SELECT_BOT: 'बोट चुनें',
      TOOLTIP_SELECT_FLOW: 'फ्लो चुनें',
      TOOLTIP_SELECT_QUESTION: 'सवाल चुनें',
    }
  },
  password: {
    changePassword: 'पासवर्ड बदलें',
    aSecurePasswordHelpsProtectYour: 'एक सुरक्षित पासवर्ड आपकी सुरक्षा में मदद करता है',
    account: ' खाता',
    currentPassword: 'वर्तमान पासवर्ड',
    newPassword: 'नया पासवर्ड',
    confirmPassword: 'पासवर्ड की पुष्टि कीजिये',
    cancel: 'रद्द करना',
    updatePassword: 'पासवर्ड अपडेट करें',
    deleteAccount: 'खाता हटा दो',
    placeholders: {
      enterYourCurrentPassword: 'अपने वर्तमान पासवर्ड को दर्ज करें',
      enterYourNewPassword: 'अपना नया पासवर्ड दर्ज करें',
      enterYourConfirmPassword: 'अपना पुष्टि पासवर्ड दर्ज करें'
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'पुराना पासवर्ड दर्ज करें',
      TOOLTIP_NEW_PASSWORD: 'नया पासवर्ड दर्ज करें',
      TOOLTIP_CONFIRM_PASSWORD: 'अपने नए पासवर्ड की पुष्टि करें',
      TOOLTIP_DELETE_ACCOUNT: `अपना चालू ${brand} खाता हटाएं`,
    }
  },
  tags: {
    manageTags: 'टैग प्रबंधन',
    createNewTags: 'नए टैग बनाएं',
    createNewTagsSubText: 'अपने टैग को बेहतर तरीके से आपके लीड्स, चैट्स, समूह आदि को व्यवस्थित करने के लिए यहां प्रबंधित करें',
    tagName: 'टैग का नाम',
    updateTag: 'टैग अपडेट करें',
    createTag: 'टैग बनाएं',
    discard: 'खारिज करें',
    searchTag: 'नाम से टैग खोजें',
    noTagFound: 'कोई टैग नहीं मिला!',
    createATag: 'टैग बनाएं',
    deleteTagModalSubText: 'क्या आप वाकई इस टैग को हटाना चाहते हैं? इस टैग को हटाने से इसे निर्धारित उपयोगकर्ताओं से भी हटा दिया जाएगा!',
    deleteTagModalHeading: 'टैग हटाएं',
    deleteOneTagModalHeading: 'टैग हटाएं',
    actions: 'क्रियाएँ',
    placeholders: {
        enterTag: 'टैग का नाम दर्ज करें',
    },
    appTooltip: {
        TOOLTIP_DELETE_TAGS: 'टैग हटाएं',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'नाम',
      status: 'दर्जा',
      action: 'कार्य',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'संदेश और ईमेल भेजने के लिए MessageBird',
      notConnected: 'जुड़ा नहीं',
      connected: 'जुड़ा हुआ',
      edit: 'संपादन करना',
      plivo: 'Plivo',
      plivoForSendingMessages: 'संदेश भेजने के लिए plivo',
      connect: 'जोड़ना',
      appTooltip: {
        nameOfAppOrCrm: 'ऐप/सीआरएम का नाम',
        statusAsConnectedOrNotConnected: 'जुड़े / न-जुड़े के रूप में स्थिति',
        clickToConnectAppOrCrm: 'ऐप/सीआरएम कनेक्ट करने के लिए क्लिक करें'
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'उफ़! बेबी प्लान में इंटीग्रेशन उपलब्ध नहीं हैं। कृपया एकीकरण के लिए किंग प्लान में अपग्रेड करें।',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'MessageBird के साथ एकीकृत करें',
        apiKey: 'एपीआई कुंजी',
        originator: 'ऑरिजइनटर ',
        connect: 'जोड़ना',
        disconnect: 'डिस्कनेक्ट',
        seeActivities: 'गतिविधियाँ देखें',
        sms: 'एसएमएस',
        time: 'समय',
        phoneNumber: 'फ़ोन नंबर',
        status: 'दर्जा',
        botName: 'बॉट नाम',
        message: 'संदेश',
        error: 'एरर',
        placeholders: {
          apiKey: 'एपीआई कुंजी',
          messageBirdPhoneNumber: 'MessageBird फ़ोन नंबर'
        },
        noDataFound: {
          activities: 'गतिविधियाँ',
          noActivitiesFound: 'कोई गतिविधियाँ नहीं मिली!'
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Plivo के साथ एकीकृत',
        authId: 'प्रामाणिक आईडी',
        authToken: 'प्रामाणिक टोकन',
        yourPlivoPhoneNumber: 'आपका प्लिवो फोन नंबर',
        connect: 'जोड़ना',
        disconnect: 'डिस्कनेक्ट',
        seeActivities: 'गतिविधियाँ देखें',
        sms: 'एसएमएस',
        time: 'समय',
        phoneNumber: 'फ़ोन नंबर',
        status: 'दर्जा',
        botName: 'बॉट नाम',
        message: 'संदेश',
        error: 'एरर',
        noDataFound: {
          activities: 'गतिविधियाँ',
          noActivitiesFound: 'कोई गतिविधियाँ नहीं मिली!'
        },
        placeholders: {
          authId: 'प्रामाणिक आईडी',
          authToken: 'प्रामाणिक टोकन',
          yourPlivoPhoneNumber: 'आपका प्लिवो फोन नंबर'
        }
      }
    }
  }
};
