export const developer = {
  developers: 'מפתחים',
  enpowerYourDevelopersToHarnessTheFullPotential: 'אפשר לצוות הפיתוח שלך לנצל את הפוטנציאל המלא של לוח הבקרה שלך',
  accessToken: 'אסימון גישה',
  yourAPI: 'ה-API שלך',
  api: {
    apiManagement: 'ניהול API',
    apiManagementSubHeading: 'הוסף, הגדר, בדוק ונהל את ה-API שלך שבאפשרותך להשתמש בהם להפעלת זרימות השיחה שלך.',
    addApi: 'הוסף API',
    createApi: 'צור API',
    noApiFound: 'לא נמצאו API!',
    deleteApis: 'מחק את ה-API שלך',
    areYouSureYouWantToDeleteAPI: 'האם אתה בטוח שברצונך למחוק את ה- API?',
    placeholders: {
      search: `חיפוש לפי שם ה-API`,
    },
    tableHead: {
      method: 'שיטה',
      apiName: 'שם ה-API',
      url: 'URL',
      actions: 'פעולות'
    },
    parametersTab: 'פרמטרים',
    headersTab: 'כותרות',
    bodyTab: 'גוף ה-API',
    manageApiResponses: 'נהל תגובות ל-API',
    chooseVariableToSave: `בחר משתנה לשמירה`,
    selectCustomAttribute: `בחר מאפיין מותאם אישית`,
    subTab: {
      key: 'מפתח',
      enterKey: 'הזן מפתח',
      value: 'ערך',
      enterValue: 'הזן ערך',
      addAnotherField: 'הוסף שדה נוסף',
      save: 'שמור',
      useslashToInsertCustomVariables: 'השתמש ב- \'/\' להוספת משתנים מותאמים אישית או ערכים דינמיים מתגובת המשתמש.',
      response: 'תגובה',
      apiRequestBody: 'גוף הבקשה ל-API',
      hit: 'הצלחה',
      test: 'בדיקה',
      buttonToGetAResponse: 'לחצן לקבלת תשובה',
      variable: 'משתנה',
      saveToCustomAttribute: 'שמור במאפיין מותאם אישית'
    },
    apiLogs: {
      apiManagement: 'ניהול API',
      addConfigureTestAndManageYourAPIs: 'הוסף, הגדר, בדוק ונהל את ה-API שלך שבאפשרותך להשתמש בהם להפעלת זרימות השיחה שלך.',
      searchHere: 'חיפוש לפי קוד מצב',
      deleteApis: 'מחק את ה-API שלך',
      testAnApi: 'בדוק את ה-API שלך',
      noAPILogFound: 'לא נמצאו יומני API',
      responseCode: 'קוד תגובה',
      message: 'הודעה',
      apiResponse: 'תגובת ה-API',
      actions: 'פעולות',
      deleteAPIs: 'מחק יומני API',
      areYouSureYouWantToDelete: 'האם אתה בטוח שברצונך למחוק את יומן ה-API הזה?',
      apiLogs: `רישומי API`,
      subTitle: `רישום מפורט של כל בקשות ותגובות API שנגרמו בתוך זרם השיחה של הצ'אטבוט.`
    }
  },
  configApi: {
    configureApi: 'הגדר את ה-API',
    configureApiSubHeading: 'אתה יכול להגדיר את ה-API המבוסס על JSON שלך כאן ולהשתמש בו להעשרת זרימת השיחה שלך',
    apiLogs: 'יומני ה-API',
    test: 'בדוק',
    manageApiResponse: 'נהל תגובות ל-API',
    body: 'גוף',
    headers: 'כותרות',
    parameters: 'פרמטרים',
    placeholders: {
      enterApiName: 'הזן שם ל-API',
      enterApiUrl: 'הזן כתובת ל-API'
    }
  }
};
