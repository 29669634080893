export const shared = {
  bpDropDown: {
    placeholders: {
      search: 'Caută'
    }
  },
  bpTable: {
    loadMore: 'Încarcă mai mult',
  },
  countryDropDown: {
    placeholders: {
      searchCountry: 'Caută țară'
    }
  },
  createNew: {
    continue: 'Continuă',
  },
  datePicker: {
    chooseADate: 'Alege o dată',
  },
  dateRangePicker: {
    choosePeriod: 'Alege perioada',
    today: 'Astăzi',
    lastWeek: 'Săptămâna trecută',
    lastMonth: 'Luna trecută'
  },
  demoBotModal: {
    pleaseProvideNumberForTesting: 'Te rugăm să furnizezi numărul pe care dorești să-l folosești pentru testare',
    resendOtp: 'Retrimite OTP',
    enterOtp: 'Introdu OTP',
    verifyOtp: 'Verifică OTP',
    sendOtp: 'Trimite OTP',
    cancel: 'Anulare',
    placeholders: {
      phone: 'Telefon*',
      enterOtp: 'Introdu OTP*',
    }
  },
  feedback: {
    pleaseSelectYourReason: 'Te rugăm să selectezi motivul',
    cancel: 'Anulare',
    submit: 'Trimite!',
    giveFeedback: 'Oferă feedback',
    ratings: 'Evaluări',
    reasonBehindYourDecision: 'Motivul deciziei tale',
    shareYourExperience: 'Împărtășește experiența ta',
    reason1: 'motiv1',
    reason2: 'motiv2'
  },
  feedbackModal: {
    paymentIsInProgress: 'Plata este în curs',
    pleaseDoNotCloseThisWindow: 'Te rugăm să nu închizi această fereastră',
    placeholders: {
      name: 'Nume',
      email: 'E-mail*',
      phone: 'Telefon*',
      pleaseShareYourUseCase: 'Te rugăm să împărtășești cazul tău de utilizare'
    }
  },
  layout: {
    sidebar: {
      notifications: 'Notificări',
      myAccount: 'Contul Meu',
      logout: 'Deconectare',
      goOnline: 'Treci Online',
      manage: 'Administrează',
      deactivate: 'Dezactivează',
      activate: 'Activează',
      delete: 'Șterge',
      home: 'Acasă',
      developer: 'Dezvoltator',
      bots: 'Bots',
      inbox: 'Inbox',
      analytics: 'Analize',
      team: 'Echipa',
      subscriptions: 'Abonamente',
      more: 'Mai mult',
      users: 'Utilizatori',
      partnerTeam: 'Echipa de parteneri',
      createNewBot: 'Creează Bot Nou',
      dashboard: 'Panou de Control',
      customers: 'Clienți',
      plans: 'Planuri',
      activities: 'Activități',
      feedbacks: 'Feedback-uri',
      configurations: 'Configurări',
      copy: 'Copiați',
      yourFriendWillMissYou: 'Prietena ta te va simți lipsind',
      rateYourBotCreationExperience: 'Evaluează-ți experiența de creare a botului',
      whatMadeYouDeleteYourBot: 'Ce te-a determinat să ștergi botul?',
      iCreatedByMistake: 'L-am creat din greșeală',
      iWantToCreateAnotherBot: 'Vreau să creez un alt bot',
      couldNotFindFeaturesThatINeeded: 'Nu am putut găsi funcțiile de care aveam nevoie',
      uiWasHardToNavigate: 'Interfața era dificil de navigat',
      iDonotWantToTell: 'Nu vreau să spun',
      other: 'Altele',
      weWillDoOurBestToMakeAutomationFun: 'Vom face tot posibilul să facem automatizarea distractivă și simplă pentru toți.',
      appTooltip: {
        TOOLTIP_GO_ONLINE: 'Comută pentru a permite sau bloca cererile de chat live',
        TOOLTIP_NOTIFICATION: 'Toate notificările',
        TOOLTIP_MY_ACCOUNT: 'Profilul tău și securitatea parolei',
        TOOLTIP_LOGOUT: 'Deconectare din panoul de control'
      },
      modal: {
        logout: 'Deconectare',
        botDeleteFeedbackForm: 'Formular de Feedback pentru Ștergerea Botului',
        areYouSureToLogOut: 'Ești sigur că vrei să te deconectezi?',
        yesIWillBeBack: 'Da! Voi reveni',
        deleteBot: 'Șterge Botul',
        areYouSureToDeleteBot: 'Ești sigur că vrei să ștergi acest bot?',
        cancel: 'Anulare',
        saveAndDelete: 'Salvează și Șterge'
      }
    }
  },
  modal: {
    cancel: 'Anulare',
    yesDelete: 'Da, șterge!',
    continue: 'Continuare'
  },
  unsavedChanges: {
    title: 'Modificări nesalvate',
    description: 'S-ar putea să existe modificări nesalvate. Sigur vrei să pleci?'
  },
  notify: {
    youHave: 'Ai ',
    new: ' noi '
  },
  profileIcon: {
    appTooltip: {
      weSuggestUsingYourCompanyLogo: 'Recomandăm să alegi logo-ul companiei tale. Adaugă o imagine PNG sau JPG pătrată, cu dimensiunea sub 1 MB.'
    }
  },
  upgradeModal: {
    upgradeToKingPlan: 'Ups! Planul Baby oferă DOAR O SINGURĂ bot GRATUITĂ. Te rugăm să upgradezi la planul King pentru a crea mai multe boți.',
    noIAmGood: 'Nu, sunt bine',
    iWantToUpgrade: 'Vreau să Fac Upgrade',
    oops: 'Ups! ',
    planOfferOnlyOneFreeBot: ' OFERĂ DOAR UN SINGUR Bot GRATUIT. Vă rugăm să faceți upgrade la ',
    planToCreateMoreBots: ' pentru a crea mai multe roboți.'
  },
  webPreview: {
    preview: ' Previzualizare',
    botsMessages: 'Mesaje ale Botului',
    bot: 'Bot ',
    eleven36AM: ' 11:36 AM',
    usersReply: 'Răspuns al Utilizatorului',
    landingPageBot: 'Bot Pagină de Aterizare',
    chatbot: 'Chatbot',
    chat: 'Chat',
    placeholders: {
      typeYourAnswer: 'Scrie-ți răspunsul'
    }
  },
  paymentLink: {
    discardPayment: 'Anulează Plata',
    completePayment: 'Finalizează Plata',
    importantUpdate: 'Actualizare Importantă',
    accountUpdate: 'Actualizare Cont',
    youHave: 'Ai',
    exceededTheMessagesLimit: 'depășit limita de mesaje',
    ofYour: 'din planul tău',
    planAnd: 'și',
    botsAreNoLongerActive: 'boții nu mai sunt activi',
    onYourWebsite: 'pe site-ul tău web.',
    youHaveEitherIncompletePaymentOn: 'Ai fie o plată incompletă pe',
    orYouHaveNotSuccessfullyUpgradedYourPlan: 'fie nu ai upgrade-uit cu succes planul tău.',
    orYouHaveNotSuccessfullyWhiteLabeledYourBot: 'fie nu ai white-labelizat cu succes botul tău.',
    orYouHaveNotSuccessfullyPurchasedTheMessagesAddOn: 'fie nu ai achiziționat cu succes "Ad-onul pentru Mesaje".',
    incompletePaymentMessage: 'Ai fie o plată incompletă pe sau nu ai upgrade-uit cu succes planul tău.',
    please: 'Te rugăm să',
    complete: 'Finalizează',
    upgrade: 'Upgrade',
    services: 'servicii',
    yourPlanToContinueServices: 'planul tău pentru a continua serviciile',
    yourPaymentToContinue: 'plata ta pentru a continua',
    forAnyQueryPleaseWriteUs: 'Pentru orice întrebare, te rugăm să ne scrii la',
    close: 'Închide',
    writeOn: '',
    upgradePlan: 'Upgradează Planul',
    discardModal: {
      title: 'Anulează Plata',
      description: 'Ești sigur că vrei să anulezi plata?',
      confirmText: 'Da, Anulează Plata'
    }
  },
  bpExport: {
    exportStatus: 'Statusul exportării',
    tasksExport: 'Exportarea sarcinilor',
    download: 'Descărcare',
    inProgress: 'în curs de execuție',
  }
};
