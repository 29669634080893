export const developer = {
  developers: 'Desenvolvedores',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Capacite seus desenvolvedores a aproveitar todo o potencial do seu painel',
  accessToken: 'Token de Acesso',
  yourAPI: 'Suas APIs',
  api: {
    apiManagement: 'Gestão de APIs',
    apiManagementSubHeading: 'Adicione, configure, teste e gerencie suas APIs que você pode acionar em seus fluxos de chat.',
    addApi: 'Adicionar API',
    createApi: 'Criar uma API',
    noApiFound: 'Nenhuma API encontrada!',
    deleteApis: 'Excluir APIs',
    areYouSureYouWantToDeleteAPI: 'Tem certeza de que deseja excluir as APIs?',
    placeholders: {
      search: `Procurar por nome da API`
    },
    tableHead: {
      method: 'Método',
      apiName: 'Nome da API',
      url: 'URL',
      actions: 'Ações'
    },
    parametersTab: 'Parâmetros',
    headersTab: 'Headers',
    bodyTab: 'Corpo',
    manageApiResponses: 'Gerenciar respostas de API',
    chooseVariableToSave: `Escolha a variável para salvar`,
    selectCustomAttribute: `Selecione o atributo personalizado`,
    subTab: {
      key: 'Chave',
      enterKey: 'Digite a Chave',
      value: 'Valor',
      enterValue: 'Digite o Valor',
      addAnotherField: 'Adicionar outro campo',
      save: 'Salvar',
      useslashToInsertCustomVariables: `Use '/' para inserir variáveis personalizadas ou valores dinâmicos da resposta do usuário.`,
      response: 'Resposta',
      apiRequestBody: 'Corpo da Solicitação de API',
      hit: 'Sucesso',
      test: 'Teste',
      buttonToGetAResponse: 'Botão para obter uma resposta',
      variable: 'Variável',
      saveToCustomAttribute: 'Guardar em Atributo Personalizado'
    },
    apiLogs: {
      apiManagement: 'Gestão de APIs',
      addConfigureTestAndManageYourAPIs: 'Adicione, configure, teste e gerencie suas APIs que você pode acionar em seus fluxos de chat.',
      searchHere: 'Buscar por código de status',
      deleteApis: 'Excluir APIs',
      testAnApi: 'Testar uma API',
      noAPILogFound: 'Nenhum Log de API Encontrado',
      responseCode: 'Código de Resposta',
      message: 'Mensagem',
      apiResponse: 'Resposta de API',
      actions: 'Ações',
      deleteAPIs: 'Excluir APIs',
      areYouSureYouWantToDelete: 'Você tem certeza de que deseja excluir esta API?',
      apiLogs: 'Registros de API',
      subTitle: 'Registro detalhado de todas as solicitações e respostas de API acionadas no fluxo de chat do chatbot.'
    }
  },
  configApi: {
    configureApi: `Configurar API`,
    configureApiSubHeading: `Você pode configurar sua API baseada em JSON aqui e usá-la para enriquecer seu fluxo de chat.`,
    apiLogs: `Logs de API`,
    test: `Teste`,
    manageApiResponse: `Gerenciar respostas de API`,
    body: `Corpo`,
    headers: `Headers`,
    parameters: `Parâmetros`,
    placeholders: {
      enterApiName: `Digite o nome da API`,
      enterApiUrl: `Digite a URL da API`
    }
  }
};
