import {createAction, props} from '@ngrx/store';

export const loadSocialGroups = createAction(
  '[Group] Load Social Groups',
  props<{ groups: any[] }>()
);

export const addSocialGroup = createAction(
  '[Group] Add Social Group',
  props<{ group: any }>()
);

export const deleteSocialGroup = createAction(
  '[Group] Delete Social Group',
  props<{ group: string }>()
);

export const modifySocialGroup = createAction(
  '[Group] Modify Social Group',
  props<{ group: string, update: any }>()
);




