export const analytics = {
  botWise: {
    botWiseAnalytics: 'ボット別分析',
    currentBillingCycle: '現在の課金サイクル',
    currentMonth: '現在の月',
    sinceAccountCreation: 'アカウント作成時から',
    messages: 'メッセージ',
    leads: 'リード',
    noDataFound: {
      botwiseAnalytics: 'ボット別分析',
      noBotHasBeenCreatedYet: 'ボットはまだ作成されていません！',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: '現在の課金サイクル、現在の月、またはアカウント作成時からの特定のボットのメッセージとコンタクト/リードのデータを要約します（毎晩更新）',
      TOOLTIP_FILTERS: '選択したボットのデータをフィルタリング',
      TOOLTIP_MESSAGES: 'アカウント作成時からのアカウントで消費された総メッセージ数',
      TOOLTIP_LEADS: 'アカウントのボットによってキャプチャされた総リード数。',
    }
  },
  overview: {
    analytics: '分析',
    seeHowToUse: '使用方法を見る',
    help: 'ヘルプ',
    overview: '概要',
    messages: 'メッセージ',
    leads: 'リード',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'すべてのボットのメッセージとリードに関する分析（毎晩更新）',
    }
  },
  summary: {
    analyticsSummary: '分析の概要',
    allBots: 'すべてのボット',
    daily: '日次',
    weekly: '週次',
    monthly: '月次',
    messages: 'メッセージ',
    leads: 'リード',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: '特定のボットとすべてのボットのメッセージとコンタクト/リードに関するデータを日次、週次、または月次で要約します（毎晩更新）',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp分析',
      month: '月',
      week: '週',
      today: '今日',
      filters: 'フィルター',
      clearAll: 'すべてクリア',
      apply: '適用',
      totalConversations: '総会話数',
      freeConversations: '無料会話数',
      paidConversations: '有料会話数',
      messageSent: '送信されたメッセージ',
      messageDelivered: 'メッセージが配信されました',
      bpDropDown: {
        selectBot: 'ボットを選択',
      },
      indexMergeDatePicker: {
        startDate: '開始日',
        endDate: '終了日',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: '会話分析',
      month: '月',
      week: '週',
      today: '今日',
      totalConversations: '総会話数',
      paidConversations: '有料会話数',
      freeConversations: '無料会話数',
      indexMergeDatePicker: {
        startDate: '開始日',
        endDate: '終了日',
      },
      bpDropDown: {
        allBots: 'すべてのボット'
      }
    },
    messageAnalytics: {
      whatsappMessages: 'WhatsAppメッセージ',
      sent: '送信済み',
      delivered: '配信済み',
      indexMergeDatePicker: {
        startDate: '開始日',
        endDate: '終了日',
      },
      bpDropDown: {
        allBots: 'すべてのボット'
      },
      month: '月',
      week: '週',
      today: '今日',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'WhatsAppメッセージステータス別',
      sent: '送信済み',
      delivered: '配信済み',
      indexMergeDatePicker: {
        startDate: '開始日',
        endDate: '終了日',
      },
      bpDropDown: {
        allBots: 'すべてのボット'
      },
      month: '月',
      week: '週',
      today: '今日',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'すべてのサブスクライバー',
      whatsappSubscribers: 'WhatsAppサブスクライバー'
    }
  }
};
