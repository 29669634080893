import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, HostListener, OnDestroy, OnChanges } from '@angular/core';
import { SharedService } from '../shared.service';
import { WhatsAppService } from 'src/appv2/services/whatsApp.service';
import { Router } from '@angular/router';
import { updateBot } from 'src/appv2/ngrx/actions/bot.actions';
import { WhatsAppBot } from 'src/appv2/models/bots/whatsapp-bot';
import { Store } from '@ngrx/store';
import { log } from 'console';
import { InboxService } from 'src/appv2/services/inbox.service';
import { LanguageService } from 'src/appv2/services/language.service';
declare let $: any;
@Component({
  selector: 'app-demo-bot-modal',
  templateUrl: './demo-bot-modal.component.html',
  styleUrls: ['./demo-bot-modal.component.scss']
})
export class DemoBotModalComponent implements OnInit, OnDestroy, OnChanges {

  @Input() title = 'demo-modal';
  @Input() description = '';
  @Output() userInput: EventEmitter<any> = new EventEmitter();
  @Input() showModal = true;
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Send OTP';
  time = {
    minutes: 0,
    seconds: 59
  };
  timerElement: any;
  staticText: any;

  currentBotIdAndType: any;
  connectForm = {
    contact: {
      prefix: '+91',
      phone: ''
    },
    otp: ''
  };
  step = 1;
  bot: WhatsAppBot;

  constructor(
    private sharedService: SharedService,
    private whatsappService: WhatsAppService,
    private router: Router,
    private store: Store,
    private inboxService: InboxService,
    private languageService: LanguageService
  ) {
    this.staticText = languageService.getStaticText().shared.demoBotModal;
    this.reInitializeStaticText();
   }

  ngOnInit(): void {
    this.currentBotIdAndType = this.sharedService.selectedBotMeta;
    this.store.select(state => state)
      .subscribe((state: any) => {
        this.bot = state.bots.find(bot => bot._id === this.currentBotIdAndType._id);
      });
    this.resetTimer();
  }

  ngOnDestroy(): void {
    if (this.timerElement) {
      clearInterval(this.timerElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal?.currentValue) {
      return $(document.getElementById(this.title)).modal('show');
    }
    return $(document.getElementById(this.title)).modal('hide');
  }

  reInitializeStaticText() {
    this.cancelText = this.staticText.cancel;
    this.confirmText = this.staticText.sendOtp;
  }

  validatePhoneNumber(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  action(status: boolean): void {
    if (!this.connectForm.contact.phone && status) {
      this.sharedService.showToast('warning', '', 'Please provide the number you want to use for testing');
    } else {

      if (!status) {
        this.userInput.emit(status);
        return $(document.getElementById(this.title)).modal('hide');
      } else {
        this.userInput.emit();

        this.sharedService.loader('start');
        this.whatsappService.updateDemoBot({ ...this.connectForm.contact }, this.currentBotIdAndType._id)
          .then((result: any) => {
            this.step = 2;
            this.resetTimer();

            this.timerElement = setInterval(() => {
              this.time = this.showTimer(this.time);
              if (this.time && this.time.seconds === 0 && this.time.minutes === 0) {
                clearInterval(this.timerElement);
              }
            }, 1000);

            this.sharedService.showToast('success', '', result);
          })
          .catch(err => {
            this.sharedService.showToast('error', '', err.message || err);
          })
          .finally(() => this.sharedService.loader('stop'));
      }
    }
  }

  resend() {
    if (this.time && (this.time.seconds || this.time.minutes)) {
      return;
    } else {
      this.sharedService.loader('start');
      this.resetTimer();
      this.timerElement = setInterval(() => {
        this.time = this.showTimer(this.time);
        if (this.time.seconds === 0 && this.time.minutes === 0) {
          clearInterval(this.timerElement);
        }
      }, 1000);
      this.whatsappService.resendOTP(this.currentBotIdAndType._id)
        .then((result: any) => {
          this.step = 2;
          this.sharedService.showToast('success', '', result);
        })
        .catch(err => {
          this.sharedService.showToast('error', '', err.message || err);
        })
        .finally(() => this.sharedService.loader('stop'));
    }
  }

  cancel() {
    this.showModal = false;
    this.router.navigate(['/home']);
  }

  inputChangeHandler(event, name: string) {
    switch (name) {
      case 'otp':
        this.connectForm = {
          ...this.connectForm,
          [name]: event.target.value
        };
        break;
      case 'phone':
        this.validatePhoneNumber(event);
        this.connectForm.contact.phone = event.target.value;
        break;
      default:
        break;
    }
  }

  chooseCountry(e) {
    this.connectForm.contact.prefix = e.dial_code || '91';
    console.log(this.connectForm.contact.prefix);

  }

  verifyOTP() {
    if (!this.connectForm.otp) {
      this.sharedService.showToast('warning', '', 'Please enter OTP');
    } else {
      this.sharedService.loader('start');
      this.whatsappService.verifyOTP({ otp: String(this.connectForm.otp) }, this.currentBotIdAndType._id)
        .then((result: any) => {

          this.store.dispatch(updateBot({
            bot: this.bot._id, update: {
              ...this.bot,
              profile: {
                ...this.bot.profile,
                verified: true,
                phoneNumber: this.connectForm.contact.prefix + this.connectForm.contact.phone
              }
            }
          }));

          const usersToImport = [{
            status: 'OPEN',
            profile: {
              userDetails: {
                name: '',
                contact: {
                  email: '',
                  phone: {
                    prefix: this.connectForm.contact.prefix,
                    number: this.connectForm.contact.phone
                  }
                },
                tags: [],
                attributes: []
              }
            }
          }];
          this.inboxService.addContact(usersToImport)
            .catch(err => { console.log(err); });

        })
        .then((result: any) => {

          this.sharedService.showToast('success', '', 'Awesome! Your demo bot is ready to use');
          $(document.getElementById(this.title)).modal('hide');

          this.router.navigate(['/bot-builder/whatsapp/flows']);
        })
        .catch((err: any) => {
          this.sharedService.showToast('error', '', err.message || err);
        })
        .finally(() => {
          this.sharedService.loader('stop');
        });
    }
  }

  resetTimer() {
    this.time = {
      minutes: 0,
      seconds: 59
    };
  }

  showTimer(time: { minutes: number, seconds: number }) {
    if (!time || (!time.minutes && !time.seconds)) {
      return;
    }
    if (time.seconds === 0) {
      time.seconds = 60;
    }
    if (time.seconds === 60) {
      time.minutes--;
      time.seconds--;
    } else {
      time.seconds--;
    }
    return time;
  }
}
