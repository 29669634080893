import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { CommonService } from 'src/appv2/services/common.service';
import { NetworkService } from 'src/appv2/services/network.service';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  GET_CONVERSATION = 'conversation/leads/';
  GET_LEADS = 'communication/leads';
  bots: Array<{ _id: string, name: string }> = [];
  bot$ = new Subject<Array<{ _id: string, name: string }>>();
  filter$: BehaviorSubject<{ bot: string, platform: string, tag: string }> = new BehaviorSubject({ bot: '', platform: '', tag: '' });

  constructor(
    public commonService: CommonService,
    public networkService: NetworkService,
    private sharedService: SharedService,
  ) { }

  fetchBotsList(): void {
    this.networkService.httpRequest('bots/list')
      .then((result: Array<{ _id: string, name: string }>) => {
        this.bot$.next(result);
        this.bots = result;
      });
  }

  getLeads(offset = 0, platform, bot = this.commonService.selectedBot) {
    return new Promise((resolve, reject) => {
      this.networkService.httpRequest(this.GET_CONVERSATION + '?bot_id=' + bot + '&page_offset=' + offset + '&platform=' + platform + '&limit=15')
        .then(resolve)
        .catch(reject);
    });
  }

  blockIp(data) {
    return this.networkService.httpRequest('conversation/block/', { ...data, bot_id: this.commonService.selectedBot }, 'POST');
  }

  getComments(offset): Promise<any> {
    return this.networkService.httpRequest('social/get-fbcounter/?bot=' + this.commonService.selectedBot + '&page_offset=' + offset);
  }


  getNLeads(bot?: string | undefined | null, page?: any, dateFilter?: any, search?: string | undefined | null, route?: string) {
    return new Promise((resolve, reject) => {
      let leadUrl = 'communication/leads?';
      let filterUrl = '';

      // Listing Of Active Chats or Live Chats on the basis of active route
      if (route === 'active') {
        leadUrl = 'communication/chats/active?';
      } else if (route === 'own-live') {
        leadUrl = 'communication/chats/own-live?';
      }
      // Appending filters to pass in query params on the basis of whether they are being passed as argument or not
      if (bot) {
        filterUrl += '_bot=' + bot;
      }
      if (page) {
        filterUrl += '&page=' + page;
      }
      if (search) {
        filterUrl += `&search=${search}`;
      }
      if (dateFilter?.startDate) {
        filterUrl += `&startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}`;
      }

      this.sharedService.loader('start');
      this.networkService.httpRequest(leadUrl + filterUrl, null, 'GET', true)
        .then(data => {
          resolve(data);
        })
        .catch(reject)
        .finally(() => this.sharedService.loader('stop'));
    });
  }


  exportLeads(bot: string | undefined | null, start: string, end: string) {
    return new Promise((resolve, reject) => {
      const url = bot
        ? 'communication/leads/export?_bot=' + bot + '&start=' + start + '&end=' + end
        : 'communication/leads/export?start=' + start + '&end=' + end;
      // this.cos.ngxService.start();
      this.networkService.httpRequest(url, null, 'GET', true)
        .then(data => {
          resolve(data);
          // this.cos.ngxService.stop();
        })
        .catch(reject);
    });
  }
}
