import {Action, createReducer, on} from '@ngrx/store';
import { addSocialTemplates, loadSocialTemplates, removeSocialTemplates, modifySocialTemplate } from '../actions/social-templates.actions';


export const initialState: any[] = [];

const socialTemplatesReducer =  createReducer(
    initialState,
    on(loadSocialTemplates, (state, {templates}) => templates),
    on(addSocialTemplates,  (state, {template}) => [...state, template]),
    on(removeSocialTemplates, (state, {template}) => state.filter(item => item._id !== template)),
    on(modifySocialTemplate, (state, {template, update}) => state.map(item => {
        if (String(item._id) === String(template)) {
          return {...item, ...update};
        }
        return item;
      })),
);

export function reducer(state: any | undefined, action: Action) {
    return socialTemplatesReducer(state, action);
  }
