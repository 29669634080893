const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Ciao,',
  greetingHand: '👋',
  upgrade: 'Aggiornamento',
  integrate: 'Integrare',
  integrate2: 'Integrare',
  chatGPT: 'Chatgpt',
  forYourBusiness: 'per la tua attività',
  insights: 'Intuizioni',
  message: 'Messaggi',
  addon: 'Aggiungi su:',
  leads: 'Conduce',
  yourBots: 'I tuoi robot',
  botName: 'Nome bot',
  platform: 'piattaforma',
  contactOrLeads: 'Contatti/lead',
  actions: 'Azioni',
  edit: 'modificare',
  delete: 'eliminare',
  createA: 'Creare un',
  whatsappChatbot: 'WhatsApp Chatbot',
  connect: 'Collegare',
  getStartedWithUs: 'Inizia con noi 👏',
  howToBuildAGoodChatFlow: ' Come costruire un buon chatflow',
  howToUseBotPenguinInboxAndItsFeatures: ' Come utilizzare la posta in arrivo di BotPenguin e le sue caratteristiche',
  botPenguinPricing: ` Prezzi di BotPenguin's: esplorare i piani e le funzionalità`,
  openHelpDesk: ' Apri help desk',
  helpAndSupport: 'Aiuto e supporto',
  extensiveGuides: 'Guide estese per tutte le tue domande e flussi di lavoro sono disponibili nel nostro',
  resourceCentre: 'Centro di risorse.',
  checkoutOurAnswers: 'Puoi controllare le nostre risposte alle domande comuni di seguito.',
  createANewBot: 'Crea un nuovo bot',
  installYourBot: 'Installa il tuo bot',
  plansAndPricing: 'Piani e prezzi',
  searchLanguage: 'Lingua di ricerca',
  whatsappConversations: 'Conversazioni di whatsapp',
  diveDeeperIntoBotPenguin: 'Immergiti più in profondità nel botpenguin',
  knowWhereWeAreGoing: 'Sapere dove stiamo andando, i recenti aggiornamenti e aiutaci a correggere alcuni bug.',
  productUpdates: 'Aggiornamenti del prodotto',
  requestAFeature: 'Richiedere una funzione',
  reportABug: 'Segnalare un bug',
  productRoadmap: 'Roadmap del prodotto',
  contactsOrLeads: 'Contatti/lead',
  english: 'Inglese',
  hindi: 'hindi',
  german: 'Tedesco',
  french: 'francese',
  portuguese: 'Portoghese',
  spanish: 'spagnolo',
  italian: 'Italiano',
  russian: 'russo',
  pleaseSelectYourReason: 'Seleziona il motivo',
  cancel: 'Annulla',
  submit: 'Invia!',
  giveFeedback: 'Dare un feedback',
  ratings: 'Giudizi',
  reasonBehindYourDecision: 'Motivo dietro la tua decisione',
  shareYourExperience: 'Condividi la tua esperienza',
  reason1: 'Motivo1',
  reason2: 'motivo2',
  appTooltip: {
    HOME_INSIGHT: 'Fornisci informazioni su tutti i messaggi e i lead dei tuoi bot (dati aggiornati ogni mezzanotte)',
    HOME_MESSAGES: 'Messaggi consumati dal tuo account durante questo ciclo di fatturazione. (I messaggi di WhatsApp, Facebook e Telegram Bot sono esclusi.)',
    HOME_TOTAL_MESSAGES: 'I messaggi totali consumati dal tuo account dalla creazione di account (i messaggi di WhatsApp, Facebook e Telegram Bot sono esclusi.)',
    LEADS: 'Lead catturati dai robot per il tuo account durante questo ciclo di fatturazione.',
    TOTAL_LEADS: 'Contatti/lead totali acquisiti dai bot per il tuo account dalla creazione dell`account' +
    ' (dati aggiornati ogni mezzanotte).',
    YOUR_BOT: 'Panoramica di tutti i tuoi robot e del loro utilizzo durante questo ciclo di fatturazione.',
    NAME: 'Nome bot',
    PLATFORM: 'Piattaforma bot',
    MESSAGES: `I messaggi totali di Bot dalla creazione dell'account`,
    CONTACTS: 'I contatti/lead totali di bot',
    ACTIONS: 'Modifica/Elimina il bot',
    EDIT_ICON: 'Modifica il flusso di chat bot e le impostazioni',
    DELETE_ICON: 'Elimina il bot',
    TOOL_TIP_CLASSIC_VERSION: 'Vecchia versione di $ {Brand} Ui',
  },
  modal: {
    deleteBot: 'Elimina Bot!',
    areYouSure: 'Sei sicuro di voler eliminare questo bot?',
    enquiryForm: 'Modulo di richiesta',
    payNow: 'Paga ora!',
    saveAndDelete: 'Salva ed elimina',
    submit: 'Invia',
  },
  noDataFound: {
    bots: 'Robot',
    noBotCreatedYet: 'Nessun robot creato ancora!',
  },
  yourFriendWillMissYou: 'Il tuo amico mancherai',
  rateYourBotCreationExperience: 'Valuta la tua esperienza di creazione di bot',
  whatMadeYouDeleteYourBot: 'Cosa ti ha fatto eliminare il tuo bot?',
  iCreatedByMistake: 'Ho creato per errore',
  iWantToCreateAnotherBot: 'Voglio creare un altro bot',
  couldNotFindFeaturesThatINeeded: ',',
  uiWasHardToNavigate: 'L`interfaccia utente era difficile da navigare',
  iDonotWantToTell: 'Non voglio parlare',
  other: 'Altro',
  weWillDoOurBestToMakeAutomationFun: 'Faremo del nostro meglio per rendere l`automazione divertente e semplice per tutti.',
};
