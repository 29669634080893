export const widgets = {
  zohoCrm: {
    settings: {
      welcomeToBotPenguin: 'Добро пожаловать в Botpenguin!',
      login: 'Авторизоваться',
      youAreAllSet: 'У вас все настроено! Вы можете использовать почтовый ящик WhatsApp со страницы сведений о ведущих.',
      placeholders: {
        enterEmail: 'Введите адрес электронной почты',
        enterPassword: 'Введите пароль',
      }
    }
  }
};
