import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-bp-logo',
  templateUrl: './bp-logo.component.html',
  styleUrls: ['./bp-logo.component.scss']
})
export class BpLogoComponent implements OnInit, OnDestroy {
  store$: any;
  agencyLogo: string;
  agencyPermission: string;
  agencyDetailsInLocal: any;
  profileType: string;

  constructor(private store: Store<any>) {
    this.agencyDetailsInLocal = JSON.parse(localStorage.getItem('bp_parent_meta'));
    this.store$ = this.store.select(state => state)
      .subscribe((state: any) => {
        this.profileType = state.profile?.type;
        this.agencyPermission = state.agencyDetails?.permission;
        this.agencyLogo = state.agencyDetails?.meta?.logo || this.agencyDetailsInLocal?.meta?.logo;
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.store$.unsubscribe();
  }

  get logoUrl(): string {
    if (this.agencyPermission === 'WHITE_LABEL' || (this.agencyDetailsInLocal && this.profileType === 'AGENT')) {
      return this.agencyLogo;
    } else {
      return 'assets/images/logo-v2.svg';
    }
  }
}
