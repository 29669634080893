export const inboxMerged = {
  inboxHeader: 'Boîte de réception',
  chatsHeader: 'Chat',
  contactsHeader: 'Contacts',
  segmentsHeader: 'Groupes',
  ordersHeader: 'Ordres',
  exportStatus: 'Statut d`exportation',
  tasksExport: 'Tâches',
  download: 'Télécharger',
  ourAllNewOmniChannelInbox: 'Notre toute nouvelle boîte de réception omni-canal!',
  allYourMessagesContactsAndLeads: 'Tous vos messages, contacts et prospects sont maintenant au même endroit. Prêt à être trié et Groupeé, pour faire croître votre entreprise!',
  close: 'Fermer',
  takeALook: 'Regarde',
  inProgress: 'en cours',
  connectToYourWhatsappNumber: 'Se connecter à votre numéro WhatsApp',
  connectYourWhatsappBotWithYourWhatsappNumber: 'Connectez votre bot WhatsApp avec votre numéro WhatsApp',
  connect: 'Connecter',
  appTooltip: {
    manageAllYourLeadsHere: 'Gérez tous vos prospects / contacts ici',
    segmentYourUserLeads: 'Groupeez vos utilisateurs de prospects / bot en fonction de la démographie et des intérêts. Ceux-ci seront utilisés pour créer des émissions',
    manageAllBotsConversationsHere: 'Gérez toutes les conversations des bots ici',
    manageAllYourOrdersHere: 'Gérez toutes vos commandes ici'
  },
  contacts: {
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    visitorName: 'Nom du visiteur',
    email: 'E-mail',
    phoneNumber: 'Numéro de téléphone',
    segment: 'Groupe',
    status: 'Statut',
    tag: 'Étiqueter',
    action: 'Action',
    discard: 'Jeter',
    addTag: 'Ajouter une étiquette',
    loadMore: 'Charger plus',
    nA: 'N / A',
    assign: 'Attribuer',
    deleteUser: 'Supprimer l`utilisateur',
    areYouSureToDeleteLead: 'Êtes-vous sûr de vouloir supprimer cet avance?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'Vous n`avez pas encore de contacts!',
      allOfYourContactsAppearHere: 'Vos contacts apparaîtront ici'
    },
    placeholders: {
      searchSegmentByName: 'Rechercher le Groupe par nom',
      searchOrAddTag: 'Rechercher ou ajouter une balise',
    },
    addContacts: {
      name: 'Nom',
      email: 'E-mail',
      enterAValidEmail: 'Entrer un email valide',
      phoneNumber: 'Numéro de téléphone',
      cancel: 'Annuler',
      addContact: 'Ajouter le contact',
      placeholders: {
        enterEmailAddress: 'Entrer l`adresse e- mail',
        enterUserrName: 'Saisissez votre nom d`utilisateur',
        searchCountry: 'Pays de recherche',
        enterPhoneNumber: 'Entrez le numéro de téléphone',
      }
    }
  },
  inboxFilter: {
    applied: 'appliqué',
    assignTags: 'Affecter les balises',
    discard: 'Jeter',
    addTag: 'Ajouter une étiquette',
    assignSegments: 'Attribuer des Groupes',
    filterYourLeads: 'Filtrez vos pistes',
    filters: 'filtres',
    filter: 'filtre',
    resetAllFilters: 'Réinitialisez tous les filtres',
    importContacts: 'Contacts d`importation',
    beforeYouStart: 'Avant de démarrer le téléchargement, assurez-vous',
    point1: '1. Téléchargez l`exemple de fichier Excel.',
    point2: '2. Commencez à saisir vos données à partir de la 2e ligne.',
    point3: '3. Dans chaque ligne, il y aura des données pour un contact',
    point4: '4. Assurez-vous que tous les numéros de téléphone sont uniques.',
    point5: '5. La limite de téléchargement de la taille du fichier maximum est de 1 Mo',
    selectFile: 'Choisir le dossier',
    clickHere: 'Cliquez ici',
    toDownloadSampleFile: 'Pour télécharger un exemple de fichier',
    modal: {
      closeLeads: 'Fermer les pistes',
      areYouSureToselectAllLeads: 'Êtes-vous sûr de vouloir fermer tous les fils sélectionnés?',
      assignAgent: 'Affecter l`agent',
      toAssignAgentToAllLeads: 'Êtes-vous sûr de vouloir attribuer un agent à tous les fils sélectionnés?',
      assignTag: 'Affecter la balise',
      wantToReplacePreviouslyAssignedTagWithNew: 'Êtes-vous sûr de souhaiter remplacer les balises précédemment affectées par la nouvelle balise choisie pour tous les fils sélectionnés?',
      deleteLeads: 'Supprimer les pistes',
      wantToDeleteSelectedLeads: 'Êtes-vous sûr de vouloir supprimer tous les fils sélectionnés?',
      exportData: 'Exporter des données',
      exportDataBasedOn: 'Exporter des données basées sur le',
      assignSegment: 'Affecter le Groupe',
      wantToAssignSegmentsToSelectedLeads: 'Êtes-vous sûr d`attribuer un Groupe à tous les fils sélectionnés ? ',
      yesGoAhead: 'Oui, vas y!',
      export: 'Exporter',
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Filtrez des données en fonction de vos préférences comme, des balises, du bot, du statut, etc.',
      markLeadAsClosed: 'Marquez l`utilisateur / lead comme fermé',
      assignAgentToLead: 'Affecter l`agent à l`utilisateur',
      assignTagToUser: 'Affecter la balise à l`utilisateur',
      exportData: 'Exporter des données',
      importContactsForWhatsappBot: 'Importer des contacts pour WhatsApp Bot',
      addWhatsappContact: 'Ajouter les contacts WhatsApp',
      assignSegmentToUser: 'Affecter le Groupe à l`utilisateur',
      deleteUsers: 'Supprimer les utilisateurs',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Tapez le nom, l`e - mail ou le téléphone et appuyez sur Entrée',
      searchAgentByName: 'Agent de recherche par nom',
      searchOrAddTag: 'Rechercher ou ajouter une balise',
      searchSegmentByName: 'Rechercher le Groupe par nom',
    },
    filterContainer: {
      filterOn: 'Filtrer',
      filterBy: 'Filtrer par',
      startFrom: 'Commencer à partir de',
      tillDate: 'Jusqu`à la date',
      offlineOrOnline: 'Déconnecté en ligne',
      liveChat: 'Chat en direct',
      userInteractedWithBot: 'L`utilisateur a interagi avec le bot',
      discard: 'Jeter',
      applyFilter: 'Appliquer le filtre',
      bots: 'Bots',
      tags: 'Mots clés',
      status: 'Statut',
      assignedTo: 'Assigné à',
      lastMessageBy: 'Dernière message',
      onlineOrOffline: 'Connecté déconnecté',
      userInteracted: 'L`utilisateur a interagi',
      segments: 'Groupes',
      lastSeenDate: 'Dernière date vue',
      onboardingDate: 'Date d`intégration',
      createTags: 'Créer des balises',
      createTagsToSortAndManageLeads: 'Créez des balises pour trier et gérer facilement vos prospects',
      typeYourTagHere: 'Tapez votre balise ici',
      createSegment: 'Créer un Groupe',
      manageSegmentForMarketingAutomation: 'Gérez vos Groupes pour l`automatisation marketing',
      typeYourSegmentHere: 'Tapez votre Groupe ici',
      placeholders: {
        searchFilter: 'Rechercher le filtre',
        searchByBotName: 'Recherche par nom de bot',
        searchOrAddTag: 'Rechercher ou ajouter une balise',
        searchByAgentName: 'Recherche par nom d`agent',
        searchBySegments: 'Recherche par Groupes',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'DATE RANDE Vous souhaitez mettre fin à la sélection à',
        dateRangeYouWishToStartSelectionFrom: 'Date Range Vous souhaitez commencer la sélection à partir de',
        filterThoseThatAreOfflineOrOnline: 'Filtrez les utilisateurs hors ligne / en ligne',
        filterThoseThatAreCurrentlyLiveChatting: 'Filtrez les utilisateurs qui discutent actuellement en direct',
        filterThoseThatInteractedWithBot: 'Filtrer les utilisateurs qui ont interagi avec le bot',
      },
      indexMergeDatePicker: {
        selectStartDate: 'Sélectionnez la date de début',
        selectEndDate: 'Sélectionnez la date de fin',
      }
    }
  },
  leads: {
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    visitorName: 'Nom du visiteur',
    email: 'E-mail',
    phoneNumber: 'Numéro de téléphone',
    assignedTo: 'Assigné à',
    status: 'Statut',
    tag: 'Étiqueter',
    action: 'Action',
    discard: 'Jeter',
    addTag: 'Ajouter une étiquette',
    loadMore: 'Charger plus',
    nA: 'N / A',
    deleteUsers: 'Supprimer l`utilisateur',
    areYouSureToDeleteLead: 'Êtes-vous sûr de vouloir supprimer cet avance?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'Vous n`avez pas encore de conversations',
      allOfYourChatsAppearHere: 'Tous vos chats apparaîtront ici',
    },
    placeholders: {
      searchAgentByName: 'Agent de recherche par nom',
      searchStatusByName: 'Statut de recherche par nom',
      searchOrAddTag: 'Rechercher ou ajouter une balise',
    }
  },
  messages: {
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    editChatFlow: 'Modifier le flux de discussion',
    connect: 'Connecter',
    chat: {
      photo: 'Photo',
      video: 'Vidéo',
      document: 'Document',
      location: 'Emplacement',
      assignedTo: 'Assigné à',
      selectBot: 'Sélectionner le bot',
      discard: 'Jeter',
      addTag: 'Ajouter une étiquette',
      yourBrowserDoesNotSupportVideoTag: 'Votre navigateur ne prend pas en charge la balise vidéo.',
      userHasSharedADocument: 'L`utilisateur a partagé un document.',
      clickHereToDownload: 'Cliquez ici pour télécharger',
      thisStoryIsNoLongerAvailable: 'Cette histoire n`est plus disponible',
      yourBrowserDoesNotSupportAudioElement: 'Votre navigateur ne supporte pas l`élément audio.',
      thisMessageIsNoLongerAvailable: 'Ce message n`est plus disponible car il n`a pas été envoyé par l`expéditeur',
      userHasSharedHisLocation: 'L`utilisateur a partagé son emplacement.Cliquez ici pour afficher',
      sms: 'SMS',
      failed: 'Échoué',
      a: 'UN',
      i: 'je',
      r: 'R',
      m: 'M',
      conversationsSelected: 'Conversations sélectionnées',
      nA: 'N / A',
      assignTagsCloseOrBulkReply: 'Attribuez des balises, des réponses fermées ou en vrac à tous en un coup',
      appTooltip: {
        assignAgent: 'Affecter l`agent',
        selectBot: 'Sélectionner le bot',
        assignTagsToManageLeads: 'Affecter des balises pour gérer les prospects et les conversations',
      },
      placeholders: {
        searchAgentByName: 'Agent de recherche par nom',
        searchBotByName: 'Rechercher le bot par nom',
        searchOrAddTag: 'Rechercher ou ajouter une balise',
        searchStatusByName: 'Statut de recherche par nom',
      }
    },
    profile: {
      userDetails: 'Coordonnées utilisateur',
      orderDetails: 'Détails de la commande',
      orderedOn: 'Commandé le',
      showMore: 'Montre plus',
      showLess:  'Montre moins',
      subTotal:  'Sous-total',
      nA: 'N / A',
      addAWhatsappNumber: 'Ajouter le numéro WhatsApp',
      addTelegramUserName: 'Ajouter le nom d`utilisateur télégramme',
      addInstagramProfileId: 'Ajouter l`ID de profil Instagram',
      addFacebookProfileId: 'Ajouter l`ID de profil Facebook',
      add: 'Ajouter',
      deviceProperties: 'Propriétés de l`appareil',
      privateNotes: 'Notes privées',
      urlsVisited: 'URL visitées',
      source: 'Source -',
      sourceNa: 'Source - Na',
      medium: 'Moyen -',
      mediumNa: 'Moyen - Na',
      campaign: 'Campagne -',
      campaignNa: 'Campagne - Na',
      attributeKey: 'Clé d`attribut',
      attributeName: 'Nom d`attribut',
      discard: 'Jeter',
      addAttribute: 'Ajouter un attribut',
      editAttribute: 'Modifier l`attribut',
      deleteAttribute: 'Supprimer l`attribut',
      headsUp: 'La tête haute',
      areYouSureToDeleteAttribute: 'Êtes-vous sûr de vouloir supprimer cet attribut?',
      okay: 'D`accord',
      dataAddedToLead: 'Les attributs associés à vos APIs ou flux de bot ne seront pas mis à jour. Veuillez mettre à jour les APIs ou flux de bot en conséquence.',
      assignedOnly: 'Attribué uniquement',
      allUsers: 'Tous les utilisateurs',
      save: 'Enregistrer',
      manageCustomAttributes: 'Gestion des attributs personnalisés',
      placeholders: {
        title: 'Titre',
        egServices: 'Par exemple. Prestations de service',
        egRequestedServices: 'Par exemple. Services demandés',
        enterYourNotesHere: 'Entrez vos notes ici ...',
      },
      bpDropDown: {
        selectBot: 'Sélectionner le bot',
        selectFlow: 'Sélectionner le flux',
        selectQuestion: 'Sélectionnez la question *',
      }
    },
    sendMessage: {
      send: 'Envoyer',
      sendToAllChannels: 'Envoyer à tous les canaux',
      email: 'E-mail',
      whatsapp: 'Whatsapp',
      telegram: 'Télégramme',
      facebook: 'Facebook',
      sendSms: 'Envoyer un SMS',
      addNew: 'Ajouter un nouveau',
      addNewQuickReply: 'Ajouter une nouvelle réponse rapide',
      discard: 'Jeter',
      fromComputer: 'De l`ordinateur',
      add: 'Ajouter',
      dragAndDropHereOr: 'Glisser et tomber ici ou',
      browseFiles: 'Parcourir les fichiers',
      caption: 'Légende',
      templates: 'Modèles',
      addHeaderMedia: 'Ajouter des supports d`en - tête',
      selectFile: 'Choisir le dossier',
      previousConversationClosed: 'Conversation précédente fermée!',
      sendTemplateMessage: 'Envoyez à nouveau le message du modèle pour lancer la conversation.',
      selectTemplate: 'Sélectionner le modèle',
      conversationLocked: 'Conversation verrouillée!',
      demoBotOnlyAllowConversations: 'Demo Bot permet uniquement la conversation avec le numéro que vous avez vérifié plus tôt.',
      placeholders: {
        searchQuickReplyByTitle: 'Recherchez les réponses rapides par titre',
        addTitle: 'Ajouter un titre',
        addYourRepliesHere: 'Ajoutez vos réponses ici ...',
        addCaption: 'Ajouter une légende',
        enterValueForThisVariable: 'Entrez la valeur de cette variable',
      },
      modal: {
        sendBulkMessages: 'Envoyer des messages en vrac',
        send: 'Envoyer',
        description: 'Votre message sera désinfecté et envoyé en fonction du type de plate-forme à laquelle appartient un utilisateur. - Les utilisateurs du site Web ne prennent en charge que les messages textuels - WhatsApp (non abonné) Les contacts ne peuvent recevoir que des messages de modèle',
      },
      noDataFound: {
        oops: 'Oops!',
        currentlyDoesNotSupportBulkMessages: 'Nous ne prenons actuellement pas en charge les messages en vrac pour les robots WhatsApp. Veuillez utiliser la fonction de diffusion',
        quickReplies: 'Réponses rapides',
        noQuickRepliesAddedYet: 'Aucune réponse rapide encore ajoutée!',
      }
    },
    userList: {
      selectAll: 'Tout sélectionner',
      nA: 'N / A',
      loadMore: 'Charger plus',
    }
  },
  segments: {
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    manageSegment: 'Gérer le Groupe',
    manageYourSegmentsForMarketingAutomation: 'Gérez vos Groupes pour l`automatisation marketing',
    createNewSegment: 'Créer un nouveau Groupe',
    segmentName: 'Nom du Groupe',
    totalContacts: 'Total contacts',
    action: 'Action',
    createSegmentButton: 'Créer un Groupe',
    deleteSegments: 'Supprimer les Groupes',
    areYouSureToDeleteSegment: 'Êtes-vous sûr de vouloir supprimer ce Groupe?',
    placeholders: {
      searchHere: 'Cherche ici',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: `Vous n'avez pas encore de Groupes créés`,
      allYourSegmentsAppearHere: 'Tous vos Groupes apparaîtront ici',
    },
    modal: {
      exportData: 'Exporter des données',
      exportDataBasedOnAppliedFilters: 'Exporter des données basées sur les filtres appliqués',
      export: 'Exporter',
    },
    appTooltip: {
      deleteSegments: 'Supprimer les Groupes',
      editSegment: 'Modifier le Groupe',
      downloadSegment: 'Télécharger le Groupe',
      deleteSegment: 'Supprimer le Groupe',
    },
    createSegment: {
      createSegment: 'Créer un Groupe',
      manageYourSegmentsForMarketingAutomation: `Gérez vos Groupes pour l'automatisation marketing`,
      segmentName: 'Nom du Groupe',
      segmentNameIsRequired: 'Le nom du Groupe est requis',
      selectContactsFilterForSegment: 'Sélectionnez des filtres de contact pour le Groupe',
      filterYourContacts: 'Filtrez vos contacts',
      removeAllFilters: 'Supprimer tous les filtres',
      addFilters: 'Ajouter des filtres',
      discard: 'Jeter',
      filterSelected: 'Filtres sélectionnés',
      placeholders: {
        enterSegmentName: 'Entrez le nom du Groupe',
      }
    }
  },
  orders: {
    visitorName: 'Nom du visiteur',
    email: 'Email',
    phoneNumber: 'Numéro de téléphone',
    status: 'Statut',
    itemCount: 'Nombre d\'articles',
    total: 'Total',
    tag: 'Étiquette',
    noDataFound: {
      youDoNotHaveAnyOrdersYet: `Vous n'avez pas encore de commandes`,
      allOfYourOrdersAppearHere: 'Tous vos commandes apparaîtront ici',
    },
  }
};
