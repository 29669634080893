export const team = {
  addMember: {
    team: 'Takım',
    seeHowToUse: 'Nasıl kullanılacağını görün',
    help: 'Yardım',
    addAMember: 'Üye Ekle',
    addYourTeamMembersText: 'Takım üyelerinizi destek temsilcileri olarak ekleyin ve bot kullanıcılarıyla canlı sohbet yapın.',
    name: 'Ad',
    nameIsRequired: 'Ad gereklidir',
    email: 'E-posta',
    enterAValidEmail: 'Geçerli bir e-posta adresi girin',
    password: 'Parola',
    passwordMustContain: 'Parola içermelidir',
    atleast6Characters: 'en az 6 karakter,',
    oneUpperCaseLetter: '1 büyük harf',
    oneSpecialCharacter: '1 özel karakter*',
    profileIcon: 'Profil Simgesi',
    whatsappNumber: 'Whatsapp Numarası',
    leadVisibility: 'Potansiyel Müşteri Görünürlüğü*',
    addMember: 'Üye Ekle',
    role: 'Rol',
    createdOn: 'Oluşturulma Tarihi',
    action: 'Eylem',
    updateMember: 'Üyeyi Güncelle',
    add: 'Ekle',
    cancel: 'İptal',
    selectVisibilityOption: 'Görünürlük seçeneği seçin',
    all: 'Tümü',
    assigned: 'Atandı',
    nA: 'UY',
    admin: 'Yönetici',
    agent: 'Ajan',
    placeholders: {
      enterMemberName: 'Üye adını girin',
      enterMemberEmailAddress: 'Üye e-posta adresini girin',
      createPasswordForMember: 'Üye için bir şifre oluşturun',
      searchCountry: 'Ülke Ara',
      enterWhatsappNumber: 'WhatsApp Numarasını Girin',
      selectLeadsVisibility: 'Potansiyel müşteri görünürlüğü seçin',
      searchTeamByNameOrEmail: 'Takım üyesini ad veya e-posta ile arayın',
    },
    modal: {
      deleteTeamMember: 'Takım üyesini sil',
      wantToDeleteThisTeamMember: 'Bu takım üyesini silmek istediğinizden emin misiniz?',
    },
    appTooltip: {
      clickToAddNewTeamMember: 'Yeni bir takım üyesi eklemek için tıklayın',
      profileIconOfTeamMember: 'Takım üyesinin profil simgesi / resmi',
      nameOfTeamMember: 'Takım üyesinin adı',
      emailOfTeamMember: 'Takım üyesi e-postası',
      whatsappNumberOfTeamMember: 'Takım üyesi WhatsApp numarası',
      roleOfTeamMemberAsAdminOrAgent: 'Yönetici/Ajan olarak takım üyesi rolü',
      dateAndTimeOfCreatingTeamMemberRole: 'Takım üyesi rolünün oluşturulma tarihi ve saati',
      editOrDeleteTeamMember: 'Takım üyesini düzenle/sil',
      editTeamMembersDetails: 'Takım üyesinin ayrıntılarını düzenle.',
      removeAccessOfTeamMemberByDeletingAccount: 'Hesabı silerek takım üyesinin erişimini kaldırın',
    }
  },
  users: {
    manageMembers: 'Üyeleri Yönet',
    managerMembersWhichCanAccessPanel: 'Panonuza erişebilen partner takımınızı yönetin',
    createNewMember: 'Yeni Üye Oluştur',
    createMember: 'Üye Oluştur',
    updateMember: 'Üyeyi Güncelle',
    deleteTitleMember: 'Üyeyi Sil',
    deleteDescriptionMember: 'Bu üyeyi silmek istediğinizden emin misiniz?',
    noMemberFound: 'Üye Bulunamadı!',
    titleMember: 'Bir Üye Oluştur',
    manageUsers: 'Kullanıcıları Yönet',
    createNewUser: 'Yeni Kullanıcı Oluştur',
    managerUserWhichCanAccessPanel: 'Panelinize erişebilen kullanıcıları yönetin',
    phoneNumber: 'Telefon Numarası',
    discard: 'At',
    createUser: 'Kullanıcı Oluştur',
    updateUser: 'Kullanıcıyı Güncelle',
    noUserFOund: 'Kullanıcı Bulunamadı!',
    title: 'Kullanıcı Oluştur',
    profileIcon: 'Profil Simgesi*',
    name: 'Ad*',
    email: 'E-posta*',
    password: 'Parola*',
    selectRole: 'Rol Seçin*',
    selectARole: 'Rol Seçin',
    selectLeadVisibility: 'Potansiyel Müşteri Görünürlüğünü Seçin*',
    confirmPassword: 'Parolayı Onayla*',
    tableHeadingName: 'Ad',
    tableHeadingEmail: 'E-posta',
    tableHeadingPhoneNumber: 'Telefon Numarası',
    tableHeadingRole: 'Rol',
    tableHeadingStatus: 'Durum',
    tableHeadingAction: 'Eylem',
    deleteTitle: 'Rolü Sil',
    deleteDescription: 'Uyarı: Bu rolü sildiğinizde, bu role atanan tüm kullanıcılar da silinecektir. \n Bu rolü silmek istediğinizden emin misiniz?',
    placeholders: {
      searchHere: 'Burada Ara',
      enterName: 'Adı Girin',
      enterEmail: 'E-postayı girin',
      searchCountry: 'Ülke ara',
      enterPhoneNumber: 'Telefon numarasını girin',
      enterPassword: 'Parolayı girin',
      enterConfirmPassword: 'Parolayı onaylayın'
    },
    appTooltip: {
      TOOLTIP_DELETE_USERS: 'Kullanıcıları sil',
      TOOLTIP_DELETE_MEMBERS: 'Üyeleri Sil'
    }
  },
  roles: {
    manageRoles: 'Rolleri Yönet',
    manageYourRoles: 'Rollerinizi yönetin.',
    name: 'Ad*',
    description: 'Açıklama',
    discard: 'At',
    createRole: 'Rol Oluştur',
    creteARole: 'Rol Oluştur',
    updateRole: 'Rolü Güncelle',
    noUserFound: 'Kullanıcı Rolleri Bulunamadı!',
    assignPermissions: 'İzinleri Ata*',
    tableHeadingName: 'Ad',
    tableHeadingDescription: 'Açıklama',
    tableHeadingStatus: 'Durum',
    tableHeadingAction: 'Eylem',
    module: 'Modül',
    read: 'Oku',
    write: 'Yaz',
    remove: 'Kaldır',
    notes: 'Notlar',
    deleteTitle: 'Rolü Sil',
    deleteDescription: 'Dikkat: Bu rolü sildiğinizde, bu role atanan tüm kullanıcılar da silinecektir. \n Bu rolü silmek istediğinizden emin misiniz?',
    deleteMemberDescription: 'Dikkat: Bu rolü sildiğinizde, bu role atanmış tüm üyeler de silinecektir. Bu rolü silmek istediğinizden emin misiniz?',
    placeholders: {
      enterRoleName: 'Rol adını girin',
      searchHere: 'Burada Ara',
      enterDescriptionForRole: 'Rol için bir açıklama girin'
    },
    appTooltip: {
      TOOLTIP_SECTION_TO_ALLOW_BLOCK: 'Kullanıcılara erişimi engellemek veya izin vermek için bölümler',
      TOOLTIP_CLICK_TO_ADD_ROLE: 'Yeni bir Rol eklemek için tıklayın',
    }
  },
  accessControl: {
    users: 'Kullanıcılar',
    members: 'Üyeler',
    partnerTeam: 'Ortak ekibi',
    roles: 'Roller'
  }
};
