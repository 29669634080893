export const inboxMerged = {
  inboxHeader: 'Входящие',
  chatsHeader: 'Чаты',
  contactsHeader: 'Контакты',
  segmentsHeader: 'Группы',
  ordersHeader: 'Заказы',
  exportStatus: 'Статус экспорта',
  tasksExport: 'Задачи- экспорт',
  download: 'Скачать',
  ourAllNewOmniChannelInbox: 'Наш новый Omni-Channel Входящие!',
  allYourMessagesContactsAndLeads: 'Все ваши сообщения, контакты и потенциальные клиенты теперь находятся в одном месте. Готовы быть отсортированы и Группыированы, чтобы заставить ваш бизнес расти!',
  close: 'Закрывать',
  takeALook: 'Взглянем',
  inProgress: 'в ходе выполнения',
  appTooltip: {
    manageAllYourLeadsHere: 'Управляйте всеми вашими потенциальными клиентами/контактами здесь',
    segmentYourUserLeads: 'Группировка свои потенциальные клиенты/пользователи бота на основе демографии и интересов. Они будут использоваться при создании трансляций',
    manageAllBotsConversationsHere: 'Управляйте всеми разговорами ботов здесь',
    manageAllYourOrdersHere: 'Управляйте всеми вашими заказами здесь'
  },
  contacts: {
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    visitorName: 'Название посетителя',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    segment: 'Группы',
    status: 'Статус',
    tag: 'Ярлык',
    action: 'Действие',
    discard: 'Отказаться',
    addTag: 'Добавить метку',
    loadMore: 'Загрузи больше',
    nA: 'НА',
    assign: 'Назначать',
    deleteUser: 'Удалить пользователя',
    areYouSureToDeleteLead: 'Вы уверены, что хотите удалить это лидерство?',
    noDataFound: {
      youDoNotHaveAnyContactsYet: 'Вы еще не имеете контактов!',
      allOfYourContactsAppearHere: 'Все ваш контакты будут отображены здесь'
    },
    placeholders: {
      searchSegmentByName: 'Группы поиска по имени',
      searchOrAddTag: 'Поиск или добавьте тег',
    },
    addContacts: {
      name: 'Имя',
      email: 'Электронная почта',
      enterAValidEmail: 'Введите действующее электронное письмо',
      phoneNumber: 'Номер телефона',
      cancel: 'Отмена',
      addContact: 'Добавить контакт',
      placeholders: {
        enterEmailAddress: 'Введите адрес электронной почты',
        enterUserrName: 'Введите имя пользователя',
        searchCountry: 'Страна поиска',
        enterPhoneNumber: 'Введите номер телефона',
      }
    }
  },
  inboxFilter: {
    applied: 'применяемый',
    assignTags: 'Назначить теги',
    discard: 'Отказаться',
    addTag: 'Добавить метку',
    assignSegments: 'Назначить Группы',
    filterYourLeads: 'Отфильтруйте свои потенциальные клиенты',
    filters: 'фильтры',
    filter: 'фильтр',
    resetAllFilters: 'Сбросить все фильтры',
    importContacts: 'Импортировать контакты',
    beforeYouStart: 'Прежде чем начать загрузку, пожалуйста, убедитесь',
    point1: '1. Загрузите образец файла Excel.',
    point2: '2. Начните вводить ваши данные с 2 -го ряда.',
    point3: '3. В каждой строке будут данные для одного контакта',
    point4: '4. Убедитесь, что все телефонные номера уникальны.',
    point5: '5. Максимальный предел загрузки размера файла составляет 1 МБ',
    selectFile: 'Выберите файл',
    clickHere: 'кликните сюда',
    toDownloadSampleFile: 'Чтобы загрузить пример файла',
    modal: {
      closeLeads: 'Близкие свинки',
      areYouSureToselectAllLeads: 'Вы уверены, что хотите закрыть все выбранные лиды?',
      assignAgent: 'Назначить агент',
      toAssignAgentToAllLeads: 'Вы уверены, что хотите назначить агента всем выбранным потенциальным клиентам?',
      assignTag: 'Назначить тег',
      wantToReplacePreviouslyAssignedTagWithNew: 'Вы уверены, что хотите заменить ранее назначенные теги на новую выбранную тег для всех выбранных лидов?',
      deleteLeads: 'Удалить лиды',
      wantToDeleteSelectedLeads: 'Вы уверены, что хотите удалить все выбранные потенциальные клиенты?',
      exportData: 'Экспорт данных',
      exportDataBasedOn: 'Экспорт данных на основе',
      assignSegment: 'Назначить Группы',
      wantToAssignSegmentsToSelectedLeads: 'Вы уверены, что хотите назначить Группы всем выбранным потенциальным клиентам?',
      yesGoAhead: 'Да, начинай!',
      export: 'Экспорт',
    },
    appTooltip: {
      filterDataBasedOnPreferences: 'Фильтруйте данные на основе ваших предпочтений, таких как теги, бот, статус и т. Д.',
      markLeadAsClosed: 'Отметьте пользователя/свинец как закрытый',
      assignAgentToLead: 'Назначить агента пользователю',
      assignTagToUser: 'Назначьте тег пользователю',
      exportData: 'Экспорт данных',
      importContactsForWhatsappBot: 'Импорт контактов для WhatsApp Bot',
      addWhatsappContact: 'Добавить контакты WhatsApp',
      assignSegmentToUser: 'Назначить Группы пользователю',
      deleteUsers: 'Удалить пользователей',
    },
    placeholders: {
      typeNameEmailPhoneNumberAndPressEnter: 'Введите имя, электронную почту или телефон и нажмите Enter',
      searchAgentByName: 'Поисковый агент по имени',
      searchOrAddTag: 'Поиск или добавьте тег',
      searchSegmentByName: 'Группы поиска по имени',
    },
    filterContainer: {
      filterOn: 'Фильтр на',
      filterBy: 'Сортировать по',
      startFrom: 'Начать с',
      tillDate: 'До даты',
      offlineOrOnline: 'Офтезона/онлайн',
      liveChat: 'Живой чат',
      userInteractedWithBot: 'Пользователь взаимодействовал с ботом',
      discard: 'Отказаться',
      applyFilter: 'Применить фильтр',
      bots: 'Боты',
      tags: 'Теги',
      status: 'Положение дел',
      assignedTo: 'Назначен',
      lastMessageBy: 'Последнее сообщество',
      onlineOrOffline: 'Онлайн/в автономном режиме',
      userInteracted: 'Пользователь взаимодействовал',
      segments: 'Группы',
      lastSeenDate: 'Последний вид даты',
      onboardingDate: 'Дата насаждения',
      createTags: 'Создать теги',
      createTagsToSortAndManageLeads: 'Создайте теги, чтобы легко сортировать и управлять своими потенциальными клиентами',
      typeYourTagHere: 'Введите свой тег здесь',
      createSegment: 'Создать Группы',
      manageSegmentForMarketingAutomation: 'Управляйте своими Группыами для автоматизации маркетинга',
      typeYourSegmentHere: 'Введите свой Группы здесь',
      placeholders: {
        searchFilter: 'Поиск фильтра',
        searchByBotName: 'Поиск по имени бота',
        searchOrAddTag: 'Поиск или добавьте тег',
        searchByAgentName: 'Поиск по имени агента',
        searchBySegments: 'Поиск по Группыам',
      },
      appTooltip: {
        dateRangeYouWishToEndSelectionAt: 'Диапазон даты, который вы хотите закончить',
        dateRangeYouWishToStartSelectionFrom: 'Диапазон даты вы хотите начать выбор',
        filterThoseThatAreOfflineOrOnline: 'Отфильтруйте тех пользователей, которые в автономном режиме/онлайн',
        filterThoseThatAreCurrentlyLiveChatting: 'Отфильтруйте тех пользователей, которые в настоящее время живут в чате',
        filterThoseThatInteractedWithBot: 'Отфильтруйте тех пользователей, которые взаимодействовали с ботом',
      },
      indexMergeDatePicker: {
        selectStartDate: 'Выберите дату начала',
        selectEndDate: 'Выберите дату окончания',
      }
    }
  },
  leads: {
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    visitorName: 'Название посетителя',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    assignedTo: 'Назначен',
    status: 'Положение дел',
    tag: 'Ярлык',
    action: 'Действие',
    discard: 'Отказаться',
    addTag: 'Добавить метку',
    loadMore: 'Загрузи больше',
    nA: 'НА',
    deleteUsers: 'Удалить пользователя',
    areYouSureToDeleteLead: 'Вы уверены, что хотите удалить это лидерство?',
    noDataFound: {
      youDoNotHaveAnyChatsYet: 'У тебя еще нет чатов',
      allOfYourChatsAppearHere: 'Все ваши чаты появятся здесь',
    },
    placeholders: {
      searchAgentByName: 'Поисковый агент по имени',
      searchStatusByName: 'Статус поиска по имени',
      searchOrAddTag: 'Поиск или добавьте тег',
    }
  },
  messages: {
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    editChatFlow: 'Редактировать чат-поток',
    connect: 'Подключить',
    chat: {
      photo: 'Фото',
      video: 'Видео',
      document: 'Документ',
      location: 'Местоположение',
      assignedTo: 'Назначен',
      selectBot: 'Выберите бот',
      discard: 'Отказаться',
      addTag: 'Добавить метку',
      yourBrowserDoesNotSupportVideoTag: 'Ваш браузер не поддерживает видео тег.',
      userHasSharedADocument: 'Пользователь поделился документом.',
      clickHereToDownload: 'Нажмите здесь, чтобы загрузить',
      thisStoryIsNoLongerAvailable: 'Эта история больше не доступна',
      yourBrowserDoesNotSupportAudioElement: 'Ваш браузер не поддерживает аудио элементы.',
      thisMessageIsNoLongerAvailable: 'Это сообщение больше не доступно, потому что оно не было',
      userHasSharedHisLocation: 'Пользователь поделился своим местоположением. Нажмите здесь, чтобы просмотреть',
      sms: 'SMS',
      failed: 'Неуспешный',
      a: 'А',
      i: 'я',
      r: 'р',
      m: 'М',
      conversationsSelected: 'разговоры выбраны',
      nA: 'НА',
      assignTagsCloseOrBulkReply: 'Назначьте теги, закрытие или объемный ответ всем за один ход',
      appTooltip: {
        assignAgent: 'Назначить агент',
        selectBot: 'Выберите бот',
        assignTagsToManageLeads: 'Назначьте теги для управления потенциальными клиентами и разговорами',
      },
      placeholders: {
        searchAgentByName: 'Поисковый агент по имени',
        searchBotByName: 'Поиск бота по имени',
        searchOrAddTag: 'Поиск или добавьте тег',
        searchStatusByName: 'Статус поиска по имени',
      }
    },
    profile: {
      userDetails: 'Данные пользователя',
      orderDetails: 'Детали заказа',
      orderedOn: 'Заказано на',
      showMore:  'Показать больше',
      showLess: 'Показать меньше',
      subTotal:  'Подытог',
      nA: 'НА',
      addAWhatsappNumber: 'Добавить номер WhatsApp',
      addTelegramUserName: 'Добавить имя пользователя Telegram',
      addInstagramProfileId: 'Добавить идентификатор профиля в Instagram',
      addFacebookProfileId: 'Добавить идентификатор профиля Facebook',
      add: 'Добавлять',
      deviceProperties: 'Свойства устройства',
      privateNotes: 'Частные заметки',
      urlsVisited: 'URL -адреса посещались',
      source: 'Источник -',
      sourceNa: 'Источник - на',
      medium: 'Середина -',
      mediumNa: 'Средний - на',
      campaign: 'Кампания -',
      campaignNa: 'Кампания - на',
      attributeKey: 'Ключ атрибута',
      attributeName: 'имя атрибута',
      discard: 'Отказаться',
      addAttribute: 'Добавить атрибут',
      editAttribute: 'Редактировать атрибут',
      deleteAttribute: 'Удалить атрибут',
      headsUp: 'Берегись',
      areYouSureToDeleteAttribute: 'Вы уверены, что хотите удалить этот атрибут?',
      okay: 'Хорошо',
      dataAddedToLead: 'Атрибуты, сопоставленные с вашими API или потоками ботов, не будут обновлены. Пожалуйста, обновите API или потоки ботов соответственно.',
      assignedOnly: 'Назначен только',
      allUsers: 'Все пользователи',
      save: 'Сохранить',
      manageCustomAttributes: 'Управление пользовательскими атрибутами',
      placeholders: {
        title: 'Заголовок',
        egServices: 'Например. Услуги',
        egRequestedServices: 'Например. Запрашиваемые услуги',
        enterYourNotesHere: 'Введите заметки здесь ...',
      },
      bpDropDown: {
        selectBot: 'Выберите бот',
        selectFlow: 'Выберите поток',
        selectQuestion: 'Выберите вопрос*',
      }
    },
    sendMessage: {
      send: 'Отправлять',
      sendToAllChannels: 'Отправить на все каналы',
      email: 'Электронная почта',
      whatsapp: 'WhatsApp',
      telegram: 'Телеграмма',
      facebook: 'Facebook',
      sendSms: 'Отправить смс',
      addNew: 'Добавить новое',
      addNewQuickReply: 'Добавить новый быстрый ответ',
      discard: 'Отказаться',
      fromComputer: 'От компьютера',
      add: 'Добавлять',
      dragAndDropHereOr: 'Перетаскивать здесь или',
      browseFiles: 'Просмотреть файлы',
      caption: 'Подпись',
      templates: 'Шаблоны',
      addHeaderMedia: 'Добавить заголовок СМИ',
      selectFile: 'Выберите файл',
      previousConversationClosed: 'Предыдущий разговор закрыт!',
      sendTemplateMessage: 'Отправьте шаблон, чтобы снова начать разговор.',
      selectTemplate: 'Выберите шаблон',
      conversationLocked: 'Разговор заперт!',
      demoBotOnlyAllowConversations: 'Демо -бот разрешает разговор только с номером, который вы проверили ранее.',
      placeholders: {
        searchQuickReplyByTitle: 'Поиск быстрых ответов по заголовке',
        addTitle: 'Добавить заголовок',
        addYourRepliesHere: 'Добавьте свои ответы здесь ...',
        addCaption: 'Добавить подпись',
        enterValueForThisVariable: 'Введите значение для этой переменной',
      },
      modal: {
        sendBulkMessages: 'Отправить объемные сообщения',
        send: 'Отправлять',
        description: 'Ваше сообщение будет дезинфицировано и отправлено на основе типа платформы, которой принадлежит пользователь. - Пользователи веб -сайта поддерживают только текстовые сообщения - контакты WhatsApp (не подписанные) могут получить только получение шаблонных сообщений',
      },
      noDataFound: {
        oops: 'Уп!',
        currentlyDoesNotSupportBulkMessages: 'В настоящее время мы не поддерживаем массовые сообщения для ботов WhatsApp. Пожалуйста, используйте функцию вещания',
        quickReplies: 'Быстрые ответы',
        noQuickRepliesAddedYet: 'Пока не добавлены быстрые ответы!',
      }
    },
    userList: {
      selectAll: 'Выбрать все',
      nA: 'НА',
      loadMore: 'Загрузи больше',
    }
  },
  segments: {
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    manageSegment: 'Управление Группыом',
    manageYourSegmentsForMarketingAutomation: 'Управляйте своими Группыами для автоматизации маркетинга',
    createNewSegment: 'Создайте новый Группы',
    segmentName: 'Название Группыа',
    totalContacts: 'Всего контактов',
    action: 'Действие',
    createSegmentButton: 'Создать Группы',
    deleteSegments: 'Удалить Группы',
    areYouSureToDeleteSegment: 'Вы уверены, что хотите удалить этот Группы?',
    placeholders: {
      searchHere: 'Поищи здесь',
    },
    noDataFound: {
      youDoNotHaveAnySegmentYet: 'У вас еще нет Группыов',
      allYourSegmentsAppearHere: 'Все ваши Группы появятся здесь',
    },
    modal: {
      exportData: 'Экспорт данных',
      exportDataBasedOnAppliedFilters: 'Экспортные данные на основе приложенных фильтров',
      export: 'Экспорт',
    },
    appTooltip: {
      deleteSegments: 'Удалить Группы',
      editSegment: 'Редактировать Группы',
      downloadSegment: 'Скачать Группы',
      deleteSegment: 'Удалить Группы',
    },
    createSegment: {
      createSegment: 'Создать Группы',
      manageYourSegmentsForMarketingAutomation: 'Управляйте своими Группыами для автоматизации маркетинга',
      segmentName: 'Название Группыа',
      segmentNameIsRequired: 'Имя Группыа требуется',
      selectContactsFilterForSegment: 'Выберите контактные фильтры для Группыа',
      filterYourContacts: 'Отфильтруйте свои контакты',
      removeAllFilters: 'Снимите все фильтры',
      addFilters: 'Добавить фильтры',
      discard: 'Отказаться',
      filterSelected: 'Фильтры выбраны',
      placeholders: {
        enterSegmentName: 'Введите имя Группыа',
      }
    }
  },
  orders: {
      visitorName: 'Имя посетителя',
      email: 'Электронная почта',
      phoneNumber: 'Номер телефона',
      status: 'Статус',
      itemCount: 'Количество товаров',
      total: 'Итого',
      tag: 'Тег',
      noDataFound: {
        youDoNotHaveAnyOrdersYet: 'У вас еще нет заказов',
        allOfYourOrdersAppearHere: 'Все ваши заказы появятся здесь'
      },
  }
};
