export const agency = {
  activities: {
    activities: 'アクティビティ',
    upcomingRenewals: '来月の更新',
    subscriptionActivities: 'サブスクリプションのアクティビティ',
    upcomingDowngradeRequests: '来月のダウングレードリクエスト',
    serialNumber: 'シリアル番号',
    email: 'メール',
    phoneNumber: '電話番号',
    currentPlan: '現在のプラン',
    price: '価格',
    daysLeft: `残り日数`,
    noDataFound: {
      upcomingDowngrades: '来月のダウングレード',
      noUpcomingDowngradesFoundYet: 'まだ来月のダウングレードが見つかりません！',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: '顧客の来月の更新',
      subscriptionActivities: 'サブスクリプションのアクティビティ',
      upcomingDowngradeRequestsOfCustomers: '顧客の来月のダウングレードリクエスト',
      serialNumber: 'シリアル番号',
      customerEmail: '顧客のメール',
      customerPhoneNumber: '顧客の電話番号',
      currentPlanOfCustomer: '顧客の現在のプラン',
      currentPlanPrice: '現在のプラン価格',
      daysLeft: `顧客の現在のプランの残り日数`
    },
  },
  configuration: {
    configurationsHeader: '設定',
    emailHeader: 'メール',
    razorPayHeader: 'RazorPay',
    invoiceDetailsHeader: '請求書の詳細',
    stripeHeader: 'Stripe',
    appTooltip: {
      integrateYourEmailProviderDetails: 'メールプロバイダーの詳細を統合して、顧客へのメールをシームレスに送信します。',
      enterYourRazorPayAccountDetails: 'RazorPayアカウントの詳細を入力して、顧客の支払い処理をシームレスに行います。',
      enterYourStripeAccountDetails: 'Stripeアカウントの詳細を入力して、顧客の支払い処理をシームレスに行います。',
      enterYourCompanyDetails: '正確な請求書の生成とプロの請求書の作成に必要な会社の詳細情報を入力してください。',
    },
    email: {
      testEmail: 'テストメール',
      addEmailIntegrationsDetails: 'メール統合の詳細を追加',
      integrationDescription: 'メールプロバイダーの詳細を統合するには、メールプロバイダー情報を提供してください。' +
      ' すべてのコミュニケーションメールは、このメールアドレスを使用して顧客に送信され、重要な通知と更新の信頼性のある配信が確保されます。',
      awsRegion: 'AWSリージョン',
      awsAccessKeyId: 'AWSアクセスキーID',
      awsSecretAccessKey: 'AWSシークレットアクセスキー',
      reset: 'リセット',
      addDetails: '詳細を追加',
      updateDetails: '詳細を更新',
      appTooltip: {
        TOOLTIP_AWS_REGION: 'AWSリージョンを入力してください。リージョンはリソースの物理的な場所を決定し、データの遅延や規制の遵守などの要因に影響を与えます。',
        TOOLTIP_AWS_ACCESS_KEY_ID: 'AWSアクセスキーIDを入力してください。このキーはAWSアカウントを一意に識別し、セキュアな認証とAWSサービスへのアクセスに必要です。',
        TOOLTIP_AWS_SECRET_ACCESS_KEY_ID: 'AWSシークレットアクセスキーを入力してください。このキーはセキュアな認証とAWSサービスへのアクセスに使用されます。'
      },
      placeholders: {
        enterAwsRegion: 'AWSリージョンを入力',
        enterAwsAccessKeyId: 'AWSアクセスキーIDを入力',
        enterAwsSecretAccessKey: 'AWSシークレットアクセスキーを入力',
      },
      modal: {
        deleteEmailIntegration: 'メール統合を削除',
        wantToDeleteEmailIntegration: 'このメール統合を削除してもよろしいですか？',
      }
    },
    facebook: {
      addFacebookIntegrationsDetails: 'Facebook統合の詳細を追加',
      integrateYourEmailProviderDetails: 'メールプロバイダーの詳細を統合します。すべてのメールはこのメールを使用して顧客に送信されます',
      facebookAppId: 'Facebook App ID',
      facebookSecretAccessKey: 'Facebookシークレットアクセスキー',
      reset: 'リセット',
      cancel: 'キャンセル',
      addDetails: '詳細を追加',
      updateDetails: '詳細を更新',
      delete: '削除',
      testEmail: 'テストメール',
      modal: {
        deleteFacebookIntegration: 'Facebook統合を削除',
        wantToDeleteFacebookIntegration: 'このFacebook統合を削除してもよろしいですか？',
      },
      placeholders: {
        enterFacebookAccessKeyId: 'Facebookシークレットアクセスキーを入力',
        enterFacebookAppId: 'Facebook App IDを入力'
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'ボットユーザーがライブチャットのリクエストを送信した直後に、チャットウィンドウに表示されるメッセージを自由にカスタマイズします。',
      }
    },
    invoiceDetails: {
      addInvoiceDetails: '請求書の詳細を追加',
      invoicesDescription: '顧客の請求書を生成するには、会社名、住所、連絡先情報などの会社詳細情報を提供してください。' +
      ' この情報は、各取引の代理でプロの請求書を作成するために使用されます。',
      companyName: '会社名',
      country: '国',
      selectCountry: '国を選択',
      stateOrRegion: '州/地域',
      streetAddress: '住所',
      gstin: 'GSTIN',
      postalCode: '郵便番号',
      addDetails: '詳細を追加',
      updateDetails: '詳細を更新',
      reset: 'リセット',
      delete: '削除',
      loadMore: 'さらに読み込む',
      time: '時間',
      phoneNumber: '電話番号',
      email: 'メール',
      status: 'ステータス',
      message: 'メッセージ',
      response: '応答',
      placeOfSupply: '供給地',
      placeholders: {
        enterYourCompanyName: '会社名を入力',
        enterYourStateOrRegion: '州または地域を入力',
        enterYourStreetAddress: '住所を入力',
        enterYourGstin: 'GSTINを入力',
        enterPostalCode: '郵便番号を入力',
        enterPlaceOfSupply: '供給地を入力'
      },
      modal: {
        deleteEmailIntegration: 'メール統合を削除',
        wantToDeleteEmailIntegration: 'このメール統合を削除してもよろしいですか？',
      },
      appTooltip: {
        TOOLTIP_COMPANY_NAME:
         '会社名を入力してください。この情報は請求書での識別とブランディングの目的で使用されます。',
        TOOLTIP_COUNTRY: '国を選択してください。この情報は請求書での識別とブランディングの目的で使用されます。',
        TOOLTIP_STATE: '州を入力してください。この情報は請求書での識別とブランディングの目的で使用されます。',
        TOOLTIP_STREET_ADDRESS: '住所を入力してください。この情報は請求書での識別とブランディングの目的で使用されます。',
        TOOLTIP_GSTIN: 'GSTINを入力してください。この情報は請求書での識別とブランディングの目的で使用されます。',
        TOOLTIP_POSTAL_CODE: '郵便番号を入力してください。この情報は請求書での識別とブランディングの目的で使用されます。',
        TOOLTIP_PLACE_OF_SUPPLY: '供給地を入力してください。この情報は請求書での識別とブランディングの目的で使用されます。',
      }
    },
    razorPay: {
      addRazorPayIntegrationsDetails: 'RazorPay統合の詳細を追加',
      integrationDescription: 'RazorPayアカウントの詳細を統合するには、RazorPayアカウント情報を提供してください。' +
      ' すべての顧客支払いは、RazorPayアカウントを介して処理されます。',
      razorPayAccessKeyId: 'RAZORPAYアクセスキーID',
      razorPaySecretAccessKey: 'RAZORPAYシークレットアクセスキー',
      reset: 'リセット',
      addDetails: '詳細を追加',
      updateDetails: '詳細を更新',
      loadMore: 'さらに読み込む',
      time: '時間',
      phoneNumber: '電話番号',
      email: 'メール',
      status: 'ステータス',
      message: 'メッセージ',
      response: '応答',
      placeholders: {
        enterRazorPayAccessKeyId: 'RazorpayアクセスキーIDを入力',
        enterRazorPaySecretAccessKey: 'Razorpayシークレットアクセスキーを入力',
        enterRazorPayAccessId: 'RazorPayアクセスIDを入力',
      },
      appTooltip: {
        TOOLTIP_RAZORPAY_ID: 'RazorpayアクセスキーIDを入力してください。このキーはRazorpayとの統合を一意に識別し、安全な支払いトランザクションに必要です。',
        TOOLTIP_RAZORPAY_SECRET_KEY: 'Razorpayシークレットアクセスキーを入力してください。このキーはアプリケーションとRazorpayの間での安全な通信に必要で、トランザクションの処理と支払い管理を可能にします。',
      },
      modal: {
        deleteRazorPayIntegration: 'メール統合を削除',
        wantToDeleteThisIntegrtion: 'このメール統合を削除してもよろしいですか？',
      }
    },
    stripe: {
      addStripeIntegrationDetails: 'Stripe統合の詳細を追加',
      integrationDescription: 'Stripeアカウントの詳細を統合するには、Stripeアカウント情報を提供してください。' +
      ' すべての顧客支払いは、Stripeアカウントを介して処理されます。',
      stripeSecretAccessKey: 'Stripeシークレットアクセスキー',
      stripePublicKey: 'Stripeパブリックキー',
      reset: 'リセット',
      addDetails: '詳細を追加',
      updateDetails: '詳細を更新',
      cancel: 'キャンセル',
      loadMore: 'さらに読み込む',
      time: '時間',
      phoneNumber: '電話番号',
      email: 'メール',
      status: 'ステータス',
      message: 'メッセージ',
      response: '応答',
      placeholders: {
        enterStripeSecretAccessKey: 'Stripeシークレットアクセスキーを入力',
        enterStripePublicKey: 'Stripeパブリックキーを入力',
      },
      appTooltip: {
        TOOLTIP_STRIPE_SECRET_KEY: 'Stripeシークレットアクセスキーを入力してください。このキーは顧客支払いの処理に安全に認証され、必要です。',
        TOOLTIP_PUBLIC_KEY: 'Stripeパブリックキーを入力してください。このキーは顧客支払いの処理に安全に認証され、必要です。',
      },
      modal: {
        deleteStripeIntegration: 'Stripe統合を削除',
        wantToDeleteStripeIntegration: 'このStripe統合を削除してもよろしいですか？',
      }
    }
  },
  customer: {
    manageCustomers: '顧客管理',
    addNewCustomers: '新しい顧客を追加',
    ensureYouHaveAddedAllYourCustomersHere: 'すべての顧客をここに追加したことを確認してください',
    customerName: '顧客名',
    nameIsRequired: '名前は必須です',
    emailId: 'メールID',
    enterAValidEmail: '有効なメールを入力',
    createPassword: 'パスワードを作成',
    enterAStrongPassword: '強力なパスワードを入力',
    confirmPassword: 'パスワードを確認',
    cancel: 'キャンセル',
    customer: '顧客',
    searchByNameOrEmail: '名前またはメールで検索',
    filters: 'フィルター',
    allCustomers: 'すべての顧客',
    paidCustomers: '有料顧客',
    freeCustomers: '無料顧客',
    messageAddOnCustomers: 'メッセージアドオン顧客',
    botWhiteLabelCustomers: 'ボットホワイトラベル顧客',
    sortBy: '並び替え',
    sortByFrequency: '頻度で並べ替え',
    all: 'すべて',
    joinedDate: '参加日',
    plan: 'プラン',
    lastLogin: '最終ログイン',
    email: 'メール',
    nextDue: '次回の支払い',
    frequency: '頻度',
    clearAll: 'すべてクリア',
    apply: '適用',
    messageConsumed: 'メッセージ消費',
    addCustomer: '顧客を追加',
    serialNumber: 'シリアル番号',
    name: '名前',
    contact: 'コンタクト',
    limit: '制限',
    location: '場所',
    actions: 'アクション',
    joinedOn: '参加日',
    typeOfCustomer: '顧客の種類',
    exceptWhatsapp: '（WhatsAppを除く）',
    exceptWA: '（WAを除く）',
    wa: '（WA）',
    loadMore: 'さらに読み込む',
    conversationsWhatsapp: '会話（WhatsApp）',
    appTooltip: {
      enterCustomerEmail: '顧客のメールを入力',
      enterCustomerPassword: '顧客のパスワードを入力',
      enterCustomerConfirmPassword: '顧客のパスワードを確認',
      enterCustomerName: '顧客の名前を入力',
    },
    placeholders: {
      enterCustomerEmail: '顧客のメールを入力',
      searchByNameOrEmail: '名前またはメールで検索',
      enterPassword: 'パスワードを入力',
      enterCustomerName: '顧客の名前を入力',
    }
  },
  dashboard: {
    dashboard: 'ダッシュボード',
    overview: '概要',
    totalCustomers: '総顧客数',
    paidCustomers: '有料顧客',
    freeCustomers: '無料顧客',
    thisWeeksSignUp: '今週のサインアップ',
    todaysSignUp: '今日のサインアップ',
    totalEarnings: '総収益',
    nA: '該当なし',
    customerName: '顧客名',
    nameIsRequired: '名前は必須です',
    emailId: 'メールID',
    enterAValidEmail: '有効なメールを入力',
    editCustomer: '顧客を編集',
    recentSignUps: '最近のサインアップ',
    serialNumber: 'シリアル番号',
    name: '名前',
    email: 'メール',
    contact: 'コンタクト',
    plan: 'プラン',
    limit: '制限',
    location: '場所',
    lastLogin: '最終ログイン',
    joinedOn: '参加日',
    actions: 'アクション',
    exceptWhatsapp: '（WhatsAppを除く）',
    messageConsumed: 'メッセージ消費',
    conversationsWhatsapp: '会話（WhatsApp）',
    placeholders: {
      enterCustomerName: '顧客の名前を入力',
      enterCustomerEmail: '顧客のメールを入力',
    },
    modal: {
      deleteCustomer: '顧客を削除',
      areYouSure: 'この顧客を削除してもよろしいですか？',
    }
  },
  downgradeRequests: {
    activites: 'アクティビティ',
    upcomingRenewals: '今後の更新',
    subscriptionActivities: 'サブスクリプション アクティビティ',
    upcomingDonwgradeRequests: '今後のダウングレードリクエスト',
    serialNumber: 'シリアル番号',
    email: 'メール',
    phoneNumber: '電話番号',
    currentPlan: '現在のプラン',
    requestedOn: 'リクエスト日',
    downgradeOn: 'ダウングレード日',
    nA: '該当なし',
    commaMonthly: '、毎月',
    commaYearly: '、年間',
    noDataFound: {
      downgradeRequests: `ダウングレードリクエスト`,
      noDowngradeRequestFound: `ダウングレードリクエストがまだありません！`,
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'お客様の今後の更新',
      subscriptionActivities: 'サブスクリプション アクティビティ',
      upcomingDowngradeOfCustomers: 'お客様の今後のダウングレードリクエスト',
      serialNumber: 'シリアル番号',
      customerEmail: '顧客のメール',
      customerPhoneNumber: '顧客の電話番号',
      currentPlanOfCustomer: '顧客の現在のプラン',
      currentPlanPrice: '現在のプラン価格',
    }
  },
  emailIntegration: {
    emailIntegration: 'メール統合',
    addEmailIntegrationDetails: 'メール統合の詳細を追加',
    integrateYourEmailProviderDetails: 'メールプロバイダーの詳細を統合します。すべてのメールは、このメールを使用して顧客に送信されます。',
    awsRegion: 'AWSリージョン',
    awsRegionIsRequired: 'AWSリージョンが必要です',
    awsAccessKeyId: 'AWSアクセスキーID',
    awsAccessKeyIdIsRequired: 'AWSアクセスキーIDが必要です',
    awsSecretAccessKey: 'AWSシークレットアクセスキー',
    awsSecretAccessKeyIsRequired: 'AWSシークレットアクセスキーが必要です',
    cancel: 'キャンセル',
    test: 'テスト',
    action: 'アクション',
    testEmail: 'テストメール',
    addDetails: '詳細を追加',
    updateDetails: '詳細を更新',
    saveDetails: '詳細を保存',
    placeholders: {
      enterAwsRegion: 'AWSリージョンを入力',
      enterAwsAccessKeyId: 'AWSアクセスキーIDを入力',
      enterAwsSecretAccessKey: 'AWSシークレットアクセスキーを入力',
    },
    modal: {
      deleteEmailIntegration: 'メール統合を削除',
      areYouSure: '本当にこのメール統合を削除してもよろしいですか？',
    },
    noDataFound: {
      emailIntegration: 'メール統合',
      noEmailIntegrationFoundYet: 'まだメール統合が見つかりませんでした！'
    }
  },
  subscription: {
    activities: 'アクティビティ',
    upcomingRenewals: '今後の更新',
    subscriptionActivities: 'サブスクリプション アクティビティ',
    upcomingDonwgradeRequests: '今後のダウングレードリクエスト',
    filters: 'フィルター',
    manageActivities: 'アクティビティを管理',
    sortBy: '並び替え',
    all: 'すべて',
    upgrades: 'アップグレード',
    donwgrades: 'ダウングレード',
    renewals: '更新',
    serialNumber: 'シリアル番号',
    email: 'メール',
    phoneNumber: '電話番号',
    previousPlan: '前のプラン',
    newPlan: '新しいプラン',
    description: '説明',
    date: '日付',
    nA: '該当なし',
    upgraded: 'アップグレード済み',
    commaYearly: '、年間',
    commaMonthly: '、毎月',
    noDataFound: {
      noSubscriptionActivitiesFoundYet: 'まだサブスクリプションアクティビティが見つかりませんでした！',
      subscriptionsActivities: 'サブスクリプション アクティビティ',
    },
    appTooltip: {
      upcomingRenewalsOfCustomers: 'お客様の今後の更新',
      subscriptionActivities: 'サブスクリプション アクティビティ',
      upcomingDowngradeRequests: 'お客様の今後のダウングレードリクエスト',
      serialNumber: 'シリアル番号',
      customerEmail: '顧客メール',
      customerPhoneNumber: '顧客電話番号',
      previousPlan: '顧客の前のプラン',
      newPlan: '顧客の新しいプラン',
      description: 'プランの説明',
      typeOfPayment: '支払いの種類',
    }
  },
  feedbacks: {
    feedbacks: 'フィードバック',
    serialNumber: 'シリアル番号',
    customer: '顧客',
    type: 'タイプ',
    rating: '評価',
    reason: '理由',
    description: '説明',
    createdAt: '作成日時',
    nA: '該当なし',
    loadMore: 'さらに読み込む',
    dataNotFound: {
      accountAndBotDeletionFeedbacks: 'アカウントおよびボット削除のフィードバック',
      noFeedbacksFoundYet: 'まだフィードバックが見つかりませんでした！',
    },
    appTooltip: {
      serialNumber: 'シリアル番号',
      customerEmail: '顧客メール',
      feedbackForBotOrAccountDeletion: '（ボット/アカウント削除用）フィードバック',
      customerRating: '顧客評価',
      customerReason: '顧客理由',
      feedbackCreatedAt: 'フィードバック作成日時',
      customerFeedback: '顧客フィードバック',
    }
  }
};

