export const analytics = {
  botWise: {
    botWiseAnalytics: 'Аналитика бота',
    currentBillingCycle: 'Текущий цикл выставления счетов',
    currentMonth: 'Текущий месяц',
    sinceAccountCreation: 'С момента создания аккаунта',
    messages: 'Сообщения',
    leads: 'Лидеры',
    noDataFound: {
      botwiseAnalytics: 'Аналитика бота',
      noBotHasBeenCreatedYet: 'Бот еще не был создан!',
    },
    appTooltip: {
      TOOLTIP_BOTWISE_SUMMARY: 'Суммируйте данные из сообщений и контактов/потенциальных клиентов для конкретных ботов для текущего цикла выставления счетов, текущего месяца или с момента создания учетной записи (обновляйтесь каждую полуночью)',
      TOOLTIP_FILTERS: 'Фильтрующие данные для выбранного бота',
      TOOLTIP_MESSAGES: 'Общее количество сообщений, потребляемых вашей учетной записью с момента создания учетной записи',
      TOOLTIP_LEADS: 'Общее количество лидов, полученных ботами для вашей учетной записи с момента создания учетной записи.',
    }
  },
  overview: {
    analytics: 'Аналитика',
    seeHowToUse: 'Посмотрите, как использовать',
    help: 'Помощь',
    overview: 'Обзор',
    messages: 'Сообщения',
    leads: 'Лидеры',
    appTooltip: {
      TOOLTIP_OVERVIEW_TITLE: 'Аналитика для всех ваших сообщений и лидеров ваших ботов. (Данные обновляются каждую полуночью)',
    }
  },
  summary: {
    analyticsSummary: 'Аналитическое резюме',
    allBots: 'Все боты',
    daily: 'Ежедневно',
    weekly: 'Еженедельно',
    monthly: 'Ежемесячно',
    messages: 'Сообщения',
    leads: 'Лидеры',
    appTooltip: {
      TOOLTIP_ANALYTICS_SUMMARY: 'Суммить ежедневные, еженедельные или ежемесячные данные из сообщений и контактов/лидов для конкретных и всех ботов (обновляйтесь каждую полуночью)',
    }
  },
  whatsapp: {
    analyticsCount: {
      whatsappAnalytics: 'WhatsApp Analytics',
      month: 'Месяц',
      week: 'Неделя',
      today: 'Сегодня',
      filters: 'Фильтры',
      clearAll: 'Очистить все',
      apply: 'Применять',
      totalConversations: 'Общие разговоры',
      freeConversations: 'Бесплатные разговоры',
      paidConversations: 'Оплачиваемые разговоры',
      messageSent: 'Сообщение отправлено',
      messageDelivered: 'Сообщение доставлено',
      bpDropDown: {
        selectBot: 'Выберите бот',
      },
      indexMergeDatePicker: {
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
      }
    },
    conversationAnalytics: {
      conversationAnalytics: 'Аналитика разговора',
      month: 'Месяц',
      week: 'Неделя',
      today: 'Сегодня',
      totalConversations: 'Общие разговоры',
      paidConversations: 'Оплачиваемые разговоры',
      freeConversations: 'Бесплатные разговоры',
      indexMergeDatePicker: {
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
      },
      bpDropDown: {
        allBots: 'Все боты',
      }
    },
    messageAnalytics: {
      whatsappMessages: 'Сообщения WhatsApp',
      sent: 'Отправил',
      delivered: 'Доставленный',
      indexMergeDatePicker: {
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
      },
      bpDropDown: {
        allBots: 'Все боты',
      },
      month: 'Месяц',
      week: 'Неделя',
      today: 'Сегодня',
    },
    messageStatusAnalytics: {
      whatsappMessagesByStatus: 'Сообщения WhatsApp по статусу',
      sent: 'Отправил',
      delivered: 'Доставленный',
      indexMergeDatePicker: {
        startDate: 'Дата начала',
        endDate: 'Дата окончания',
      },
      bpDropDown: {
        allBots: 'Все боты',
      },
      month: 'Месяц',
      week: 'Неделя',
      today: 'Сегодня',
    },
    newWhatsappAnalytics: {
      allSubscribers: 'Все подписчики',
      whatsappSubscribers: 'Подписчики WhatsApp',
    }
  }
};
