const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'مرحبًا، ',
  greetingHand: '👋',
  upgrade: 'الترقية',
  integrate: 'التكامل',
  integrate2: 'التكامل',
  chatGPT: 'Chat GPT ',
  forYourBusiness: 'لعملك',
  insights: 'التحليلات',
  message: 'الرسائل',
  addon: 'إضافة: ',
  leads: 'العملاء المتوقعين',
  yourBots: 'الروبوتات الخاصة بك',
  botName: 'اسم الروبوت',
  platform: 'المنصة',
  contactOrLeads: 'الأتصالات/العملاء المتوقعين',
  actions: 'الإجراءات',
  edit: 'تعديل',
  delete: 'حذف',
  createA: 'إنشاء ',
  whatsappChatbot: 'روبوت واتساب ',
  connect: 'ربط',
  getStartedWithUs: 'ابدأ معنا 👏',
  howToBuildAGoodChatFlow: 'كيفية بناء تدفق دردشة جيد',
  howToUseBotPenguinInboxAndItsFeatures: 'كيفية استخدام صندوق الوارد في BotPenguin وميزاته',
  botPenguinPricing: 'أسعار BotPenguin: استكشاف الخطط والميزات',
  openHelpDesk: 'فتح مكتب المساعدة',
  helpAndSupport: 'المساعدة والدعم',
  extensiveGuides: 'أدلة شاملة لجميع استفساراتك وأدوار العمل متاحة في مركز الموارد الخاص بنا',
  resourceCentre: 'المركز الإستشاري',
  checkoutOurAnswers: 'يمكنك التحقق من إجاباتنا على الاستفسارات الشائعة أدناه.',
  createANewBot: 'إنشاء روبوت جديد',
  installYourBot: 'تثبيت الروبوت الخاص بك',
  plansAndPricing: 'الخطط والأسعار',
  searchLanguage: 'بحث باللغة',
  whatsappConversations: 'محادثات واتساب',
  diveDeeperIntoBotPenguin: 'استكشاف BotPenguin بعمق',
  knowWhereWeAreGoing: 'تعرف على الاتجاه الذي نسلكه، والتحديثات الأخيرة، وساعدنا في إصلاح بعض الأخطاء.',
  productUpdates: 'تحديثات المنتج',
  requestAFeature: 'طلب ميزة',
  reportABug: 'الإبلاغ عن علة',
  productRoadmap: 'خريطة المنتج',
  contactsOrLeads: 'الأتصالات/العملاء المتوقعين',
  english: 'الإنجليزية',
  hindi: 'الهندية',
  german: 'الألمانية',
  french: 'الفرنسية',
  portuguese: 'البرتغالية',
  spanish: 'الإسبانية',
  italian: 'الإيطالية',
  russian: 'الروسية',
  pleaseSelectYourReason: 'يرجى اختيار سببك',
  cancel: 'إلغاء',
  submit: 'إرسال!',
  giveFeedback: 'قدم ملاحظاتك',
  ratings: 'التقييمات',
  reasonBehindYourDecision: 'السبب وراء قرارك',
  shareYourExperience: 'شارك تجربتك',
  reason1: 'السبب 1',
  reason2: 'السبب 2',
  appTooltip: {
    HOME_INSIGHT: 'تقديم نظرة عامة على جميع رسائل وعمليات الاتصال/العملاء المتوقعين لجميع الروبوتات خلال هذه الفترة الدورية للفوترة (البيانات تحدث كل منتصف الليل)',
    HOME_MESSAGES: 'الرسائل التي استهلكتها حسابك خلال هذه الفترة الدورية للفوترة. (الرسائل من واتساب وفيسبوك وروبوت تيليجرام مستبعدة.)',
    HOME_TOTAL_MESSAGES: 'إجمالي الرسائل التي استهلكها حسابك منذ إنشاء الحساب (الرسائل من واتساب وفيسبوك وروبوت تيليجرام مستبعدة.)',
    LEADS: 'العملاء المتوقعين الذين تم التقاطهم بواسطة الروبوتات لحسابك خلال هذه الفترة الدورية للفوترة.',
    TOTAL_LEADS: 'إجمالي جهات الاتصال/العملاء المتوقعين الذين تم التقاطهم بواسطة الروبوتات لحسابك منذ إنشاء الحساب (البيانات تحدث كل منتصف الليل).',
    YOUR_BOT: 'نظرة عامة على جميع روبوتاتك واستخدامها خلال هذه الفترة الدورية للفوترة.',
    NAME: 'اسم الروبوت',
    PLATFORM: 'منصة الروبوت',
    MESSAGES: 'إجمالي رسائل الروبوت منذ إنشاء الحساب',
    CONTACTS: 'إجمالي جهات الاتصال/العملاء للروبوت',
    ACTIONS: 'تعديل/حذف الروبوت',
    EDIT_ICON: 'تعديل تدفق الدردشة والإعدادات للروبوت',
    DELETE_ICON: 'حذف الروبوت',
    TOOL_TIP_CLASSIC_VERSION: `الإصدار القديم لواجهة ${brand}`
  },
  modal: {
    deleteBot: 'حذف الروبوت!',
    areYouSure: 'هل أنت متأكد أنك تريد حذف هذا الروبوت؟',
    enquiryForm: 'نموذج الاستفسار',
    payNow: 'ادفع الآن!',
    saveAndDelete: 'حفظ وحذف',
    submit: 'إرسال',
  },
  noDataFound: {
    bots: 'الروبوتات',
    noBotCreatedYet: 'لم يتم إنشاء أي روبوت حتى الآن!',
  },
  yourFriendWillMissYou: 'سيفتقدك صديقك',
  rateYourBotCreationExperience: 'قيم تجربتك في إنشاء الروبوت',
  whatMadeYouDeleteYourBot: 'ما الذي دفعك لحذف الروبوت؟',
  iCreatedByMistake: 'لقد أنشأته بالخطأ',
  iWantToCreateAnotherBot: 'أريد إنشاء روبوت آخر',
  couldNotFindFeaturesThatINeeded: 'لم أتمكن من العثور على الميزات التي أحتاجها',
  uiWasHardToNavigate: 'كان من الصعب التنقل في واجهة المستخدم',
  iDonotWantToTell: 'لا أريد أن أخبر',
  other: 'آخر',
  weWillDoOurBestToMakeAutomationFun: 'سنبذل قصارى جهدنا لجعل الأتمتة ممتعة وبسيطة للجميع.'
};
