import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ChatgptService } from 'src/appv2/services/chatgpt.service';
import { SharedService } from '../shared.service';
import { LanguageService } from 'src/appv2/services/language.service';
import { AgencyService } from 'src/appv2/services/agency.service';
import { updateProfile } from '../../../ngrx/actions/profile.actions';
declare let $: any;

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() title = '';
  @Input() description = '';
  @Input() showModal = false;
  @Input() showCloseButton = true;
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Submit!';
  @Output() userInput: EventEmitter<any> = new EventEmitter();
  staticText: any;
  userId: any;
  paymentWindow: any;
  timer: any;
  connectFormErrors = {
    email: false,
    phone: false
  };
  connectForm = {
    name: '',
    email: '',
    contact: {
      prefix: '',
      phone: ''
    },
    description: ''
  };
  subscribe$: Subscription;
  agencyMeta: any;
  isWhitelabel: boolean;
  constructor(
    private sharedService: SharedService,
    private chatgptService: ChatgptService,
    private store: Store,
    private languageService: LanguageService,
    private agencyService: AgencyService
  ) {
    this.staticText = languageService.getStaticText().shared.feedbackModal;
    this.subscribe$ = this.store.select(data => data)
      .subscribe((result: any) => {
        if (result.profile) {
          this.connectForm.email = result.profile?.contact?.email;
          this.userId = result.profile._id;
        }
        if (result.agencyDetails) {
          this.isWhitelabel = result.agencyDetails.permission === 'WHITE_LABEL' ? true : false;
          this.agencyMeta = result?.agencyDetails?.meta || JSON.parse(localStorage.getItem('bp_parent_meta'))?.meta;
        }
      });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.subscribe$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal?.currentValue) {
      return $(document.getElementById(this.title)).modal('show');
    }
    return $(document.getElementById(this.title)).modal('hide');
  }

  async action(status: boolean) {
    if (!this.connectForm.email && status || (this.connectFormErrors.email || !this.connectForm.contact.phone && status)) {
      this.sharedService.showToast('warning', '', 'Please fill all contact details');
    } else if (!this.connectForm.contact.prefix && status) {
      this.sharedService.showToast('error', '', 'Please select country code');
    } else if (!this.sharedService.isPhoneNumberValid(this.connectForm.contact.prefix, this.connectForm.contact.phone) && status) {
      this.sharedService.showToast('warning', '', 'Please enter a valid phone number');
    } else {

      if (!status) {
        this.userInput.emit(status);
      } else {

        try {
          this.sharedService.loader('start');
          await this.chatgptService.sendChatgptEnquiry(this.connectForm);

          const customer = await this.agencyService.editCustomer(this.userId, { isChatGPTEnquiryFormFilled: true });
          this.store.dispatch(updateProfile({ update: customer }));
          // commented, payment after chatGpt feedback
          // this.pay();
          this.userInput.emit(status);
          this.sharedService.showToast('success', '', 'Submitted successfully');
        } catch (error) {
          this.sharedService.showToast('error', '', 'An error occurred. Please try again later');
        } finally {
          this.sharedService.loader('stop');
        }
      }
    }
  }

  validatePhoneNumber(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  chooseCountry(e) {
    this.connectForm.contact.prefix = e.dial_code || '91';
  }

  inputChangeHandler(event, name: string) {
    switch (name) {
      case 'name':
      case 'email':
      case 'description':
        this.connectForm = {
          ...this.connectForm,
          [name]: event.target.value
        };
        break;
      case 'phone':
        this.validatePhoneNumber(event);
        this.connectForm.contact.phone = event.target.value;
        break;
      default:
        break;
    }
  }

  public handleIsError(event: boolean, name: string) {
    this.connectFormErrors = {
      ...this.connectFormErrors,
      [name]: event
    };
  }

  get logoUrl(): string {
    if (this.isWhitelabel) {
      return this.agencyMeta?.logo || '';
    } else {
      return 'assets/images/logo-v2.svg';
    }
  }

  // pay(): void {
  //   this.modalActions(true);
  //   this.paymentWindow = window.open(`https://buy.stripe.com/cN22aa4hggeV9DG4gq?
  //   client_reference_id=${this.userId}&prefilled_email=${this.connectForm.email}`,
  //   '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=500, top=50, width=20000, height=20000, visible=none');
  //   this.verifyPayment();
  // }

  // modalActions(show: boolean): void {
  //   $(document.getElementById('payment-verify')).modal(show ? 'show' : 'hide');
  // }

  // verifyPayment() {
  //   if (this.timer) {
  //     clearTimeout(this.timer);
  //   }

  //   this.timer = setInterval(() => {
  //     this.chatgptService.checkPaymentStatus()
  //       .then(response => {
  //         this.paymentWindow.close();
  //         window.location.href = '/bot-builder/integration/all-integrations/chat-gpt';
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         if (this.paymentWindow && this.paymentWindow.closed) {
  //           this.redirect();
  //         }
  //       });

  //   }, 5 * 1000);
  // }

  // redirect() {
  //   clearInterval(this.timer);
  //   window.location.reload();
  // }
}
