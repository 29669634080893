import { Action, createReducer, on } from '@ngrx/store';
import { addLead, deleteLead, loadLeads, loadMoreLeads, updateLead } from '../actions/leads.actions';
import Leads from '../../models/leads';

export const leadFeatureKey = 'lead';


export const initialState: Leads[] = [];

const leadReducer = createReducer(
  initialState,
  on(loadLeads, (state, { leads }) => leads),
  on(loadMoreLeads, (state, { leads }) => [...state, ...leads]),
  on(addLead, (state, { lead }) => [lead, ...state]),
  on(deleteLead, (state, { lead }) => state.filter(item => item._id !== lead)),
  on(updateLead, (state, { lead, update }) => state.map(item => {
    if (item.id === lead) {
      return { ...item, ...update };
    } else {
      return item;
    }
  })),
);

export function reducer(state: Leads[], action: Action) {
  return leadReducer(state, action);
}
