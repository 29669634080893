import {createAction, props} from '@ngrx/store';

export const loadWhatsAppTemplates = createAction(
    '[Templates] Load WhatsApp Templates',
    props<{ templates: any[] }>()
);

export const addWhatAppTemplates =  createAction(
    '[Templates] Add WhatsApp Templates',
    props<{ template: any }>()
);

export const removeWhatsAppTemplates =  createAction(
    '[Templates] Delete WhatsApp Templates',
    props<{ template: any }>()
);

export const modifyWhatsAppTemplate = createAction(
    '[Group] Modify WhatsApp Template',
    props<{ template: string, update: any }>()
  );
