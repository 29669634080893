export const whitelabel = {
  plans: 'Planlar',
  managePlan: 'Planları Yönet',
  whitelabelCharges: 'Beyaz Etiket Ücretleri',
  chargesOrTaxesConfiguration: 'Ücretler / Vergiler Yapılandırması',
  websitePlans: 'Web Sitesi Planları',
  whatsappPlansHeader: 'WhatsApp Planları',
  addOnPlans: {
    createAddOnPlans: 'Ek Planlar Oluştur',
    checkoutYourCurrentMessage: 'Mevcut Mesaj Ek Planlarınızı kontrol edin ve müşterileriniz için yeni planları seçin',
    choosePlanValidity: 'Plan Geçerliliğini Seçin',
    chooseValidity: 'Geçerlilik Seçin',
    oneYear: 'Bir Yıl',
    billingCycle: 'Fatura Döngüsü',
    numberOfMessages: 'Mesaj Sayısı',
    pleaseEnterNumberOfMessages: 'Lütfen mesaj sayısını girin',
    setCurrency: 'Para Birimini Ayarla',
    select: 'Seç',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Hepsini Temizle',
    apply: 'Uygula',
    currency: 'Para Birimi',
    amount: 'Tutar',
    validity: 'Geçerlilik',
    upgradePlan: 'Planı Yükselt',
    addPlan: 'Plan Ekle',
    serialNumber: 'Sıra No.',
    messages: 'Mesajlar',
    active: 'Aktif',
    priceDetails: 'Fiyat Detayları',
    action: 'Eylem',
    addOnChanges: 'Ek Ücretler: ',
    perYear: 'Yılda bir',
    taxes: 'Vergiler',
    placeholders: {
      enterNumberOfMesasges: 'Mesaj sayısını girin',
      enterAmount: 'Tutarı girin',
      enterValidity: 'Geçerliliği girin',
      searchByMessages: 'Mesajlara göre arama yapın'
    },
    modal: {
      deleteAddOnsPlan: 'Ek Planları Sil',
      wantToDeleteThisAddOnsPlan: 'Bu Ek Planı silmek istediğinizden emin misiniz?',
    }
  },
  charges: {
    addWhitelabelCharges: 'Beyaz Etiket Ücretleri Ekle',
    checkoutExistingCharges: 'Farklı ödeme yöntemlerindeki mevcut ücretleri kontrol edin ve yeni beyaz etiket ücretleri ekleyin',
    currency: 'Para Birimi',
    chooseCurrency: 'Para Birimi Seçin',
    amountPerBotPerMonth: 'Aylık başına bot başına tutar',
    cancel: 'İptal',
    upgradeChanges: 'Ücretleri Güncelle',
    addCharges: 'Ücret Ekle',
    serialNumber: 'Sıra No.',
    action: 'Eylem',
    taxes: 'Vergiler',
    placeholders: {
      enterAmountBotPerMonth: 'Aylık başına bot başına tutarı girin',
      searchByCurrency: 'Para Birimine Göre Ara',
    },
    modal: {
      deleteCharges: 'Ücretleri Sil',
      wantToDeleteThisCharge: 'Bu ücreti silmek istediğinizden emin misiniz?',
    }
  },
  planManagement: {
    createNewPlans: 'Yeni planlar oluştur',
    checkoutYourCurrentPlan: 'Mevcut planlarınızı kontrol edin ve müşterileriniz için yeni planlar ekleyin',
    choosePlan: 'Plan Seç',
    selectPlan: 'Plan Seçin',
    baby: 'Baby',
    king: 'King',
    displayName: 'Görüntü Adı',
    messageLimit: 'Mesaj Sınırı',
    enterAValidEmail: 'Geçerli bir e-posta adresi girin',
    setCurrency: 'Para Birimini Ayarla',
    select: 'Seç',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Hepsini Temizle',
    apply: 'Uygula',
    perMonth: '/Ay',
    perYear: '/Yıl',
    cancel: 'İptal',
    upgradePlan: 'Planı Güncelle',
    addPlan: 'Plan Ekle',
    serialNumber: 'Sıra No.',
    plan: ' Plan',
    messages: 'Mesajlar',
    pricing: 'Fiyatlandırma',
    actions: 'Eylemler',
    // plan: ' Plan',
    perMonthText: 'Aylık',
    perYearText: 'Yıllık',
    placeholders: {
      enterDisplayName: 'Görüntü adını girin',
      enterMessageLimit: 'Mesaj sınırını girin',
      searchByPlanName: 'Plan Adı veya Mesajlara Göre Ara'
    },
    modal: {
      deletePlan: 'Planı Sil',
      wantToDeleteThisBabyPlan: 'Bebek Planını silmek, tüm müşterilere etki edecektir. Bu planı silmek istediğinizden emin misiniz?',
      wantToDeleteThisPlan: 'Bu planı silmek istediğinizden emin misiniz?',
    },
    appTooltip: {
      chooseYourPlanType: 'Plan türünüzü seçin',
      pleaseInputYourPlanDisplayName: 'Lütfen plan görüntü adınızı girin',
      typeMessageLimitForCustomer: 'Müşteri için mesaj sınırını girin',
      selectYourPreferredCurrencies: 'Tercih ettiğiniz para birimini seçin',
    },
  },
  taxesConfiguration: {
    addTaxes: 'Vergi Ekleyin',
    checkoutExistingTaxes: 'Farklı ödeme yöntemlerindeki mevcut vergileri kontrol edin ve yeni vergiler ekleyin',
    taxName: 'Vergi Adı',
    applicableOn: 'Uygulanabilir',
    choosePlans: 'Planları Seçin',
    chooseCurrency: 'Para Birimi Seçin',
    percentage: 'Yüzde',
    cancel: 'İptal',
    serialNumber: 'Sıra No.',
    name: 'Ad',
    currency: 'Para Birimi',
    updateTaxes: 'Vergileri Güncelle',
    actions: 'Eylemler',
    modal: {
      deleteTaxes: 'Vergileri Sil',
      wantToDeleteThisTax: 'Bu vergiyi silmek istediğinizden emin misiniz?'
    },
    placeholders: {
      taxName: 'Vergi Adı',
      taxPercentage: 'Vergi Yüzdesi',
      searchByCurrency: 'Para Birimine Göre Ara',
    }
  },
  whatsappPlans: {
    createNewPlans: 'Yeni planlar oluştur',
    checkoutYourCurrentAndNewPlans: 'Mevcut planlarınızı kontrol edin ve müşterileriniz için yeni planlar ekleyin',
    choosePlan: 'Plan Seçin',
    selectPlan: 'Plan Seçin',
    baby: 'Baby',
    king: 'King',
    displayName: 'Görüntü Adı',
    messageLimit: 'Sohbet Sınırı',
    enterAValidMessageLimit: 'Geçerli bir sohbet sınırı girin',
    messages: 'Sohbetler',
    setCurrency: 'Para Birimini Ayarla',
    select: 'Seç',
    inr: 'INR',
    usd: 'USD',
    cad: 'CAD',
    clearAll: 'Hepsini Temizle',
    apply: 'Uygula',
    perMonth: '/Ay',
    perYear: '/Yıl',
    cancel: 'İptal',
    addPlan: 'Plan Ekle',
    serialNumber: 'Sıra No.',
    plan: 'Plan',
    pricing: 'Fiyatlandırma',
    actions: 'Eylemler',
    perMonthText: 'Aylık',
    perYearText: 'Yıllık',
    updatePlan: 'Planı Güncelle',
    typeMessageLimitForCustomer: 'Müşteri için sohbet sınırını girin',
    placeholders: {
      enterDisplayName: 'Görüntü adını girin',
      enterMessageLimit: 'Mesaj sınırını girin',
      searchByPlanName: 'Plan Adına Göre Ara',
    },
    appTooltip: {
      typeMessageLimitForCustomer: 'Müşteri için sohbet sınırını girin',
      chooseYourPlanType: 'Plan türünüzü seçin',
      selectYourPreferredCurrencies: 'Tercih ettiğiniz para birimini seçin',
      pleaseInputYourPlanDisplayName: 'Lütfen plan görüntü adınızı girin',
    },
    modal: {
      deletePlan: 'Planı Sil',
      wantToDeleteThisBabyPlan: 'Bebek Planını silmek, tüm müşterilere etki edecektir. Bu planı silmek istediğinizden emin misiniz?',
      areYouSureYouWantToDeleteThisPlan: 'Bu planı silmek istediğinizden emin misiniz?',
    }
  }
};
