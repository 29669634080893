export const developer = {
  developers: 'Ontwikkelaars',
  enpowerYourDevelopersToHarnessTheFullPotential: 'Geef uw ontwikkelaars de mogelijkheid om het volledige potentieel van uw dashboard te benutten',
  accessToken: 'Toegangstoken',
  yourAPI: `Uw API's`,
  api: {
    apiManagement: 'API-beheer',
    apiManagementSubHeading: `Voeg, configureer, test en beheer uw API's toe die u kunt activeren in uw chatstromen.`,
    addApi: `Voeg API toe`,
    createApi: 'Maak een API',
    noApiFound: 'Geen API gevonden!',
    deleteApis: `API's verwijderen`,
    areYouSureYouWantToDeleteAPI: `Bent u zeker dat u de API's wilt verwijderen?`,
    placeholders: {
      search: 'Zoeken op API-naam'
    },
    tableHead: {
      method: 'Methode',
      apiName: 'API-naam',
      url: 'URL',
      actions: 'Acties'
    },
    parametersTab: 'Parameters',
    headersTab: 'Headers',
    bodyTab: 'Body',
    manageApiResponses: 'Beheer API-reacties',
    chooseVariableToSave: 'Kies variabele om op te slaan',
    selectCustomAttribute: 'Selecteer aangepaste attribuut',
    subTab: {
      key: 'Sleutel',
      enterKey: 'Voer sleutel in',
      value: 'Waarde',
      enterValue: 'Voer waarde in',
      addAnotherField: 'Voeg een ander veld toe',
      save: 'Opslaan',
      useslashToInsertCustomVariables: `Gebruik '/' om aangepaste variabelen of dynamische waarden uit de reactie van de gebruiker in te voegen.`,
      response: 'Reactie',
      apiRequestBody: 'API-verzoekbody',
      hit: 'Succes',
      test: 'Test',
      buttonToGetAResponse: 'Knop voor een reactie te krijgen',
      variable: 'Variabele',
      saveToCustomAttribute: 'Opslaan in Aangepaste Eigenschap'
    },
    apiLogs: {
      apiManagement: 'API-beheer',
      addConfigureTestAndManageYourAPIs: `Voeg, configureer, test en beheer uw API's toe die u kunt activeren in uw chatstromen.`,
      searchHere: 'Zoeken op statuscode',
      deleteApis: `API's verwijderen`,
      testAnApi: 'Test een API',
      noAPILogFound: 'Geen API-logboek gevonden',
      responseCode: 'Reactiecode',
      message: 'Bericht',
      apiResponse: 'API-reactie',
      actions: 'Acties',
      deleteAPIs: `API's verwijderen`,
      areYouSureYouWantToDelete: 'Weet u zeker dat u deze API wilt verwijderen?',
      apiLogs: 'API-logboeken',
      subTitle: 'Gedetailleerde registratie van alle API-verzoeken en -reacties die zijn geactiveerd binnen de chatstroom van de chatbot.'
    }
  },
  configApi: {
    configureApi: 'Configureer API',
    configureApiSubHeading: 'U kunt hier uw op JSON gebaseerde API configureren en gebruiken om uw chatstroom te verrijken.',
    apiLogs: 'API-logboeken',
    test: 'Test',
    manageApiResponse: 'Beheer API-reacties',
    body: 'Body',
    headers: 'Headers',
    parameters: 'Parameters',
    placeholders: {
      enterApiName: 'Voer API-naam in',
      enterApiUrl: 'Voer API-URL in'
    }
  }

};
