import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/appv2/services/common.service';

@Component({
  selector: 'app-sidebar-toggle',
  templateUrl: './sidebar-toggle.component.html',
  styleUrls: ['./sidebar-toggle.component.scss']
})
export class SidebarToggleComponent implements OnInit {

  constructor(
    public commonService: CommonService
    ) { }

  ngOnInit(): void {
  }

  onClickMenuIcon() {
    this.commonService.triggerToggle();
  }

}
