import {Action, createReducer, on} from '@ngrx/store';
import {loadSocialGroups, addSocialGroup, deleteSocialGroup, modifySocialGroup} from '../actions/social-groups.actions';


export const groupsFeatureKey = 'groups';


export const initialState: any[] = [];

const socialGroupsReducer = createReducer(
  initialState,
  on(loadSocialGroups, (state, {groups}) => groups),
  on(addSocialGroup, (state, {group}) => [...state, group]),
  on(deleteSocialGroup, (state, {group}) => state.filter(item => item._id !== group)),
  on(modifySocialGroup, (state, {group, update}) => state.map(item => {
    if (String(item._id) === String(group)) {
      return {...item, ...update};
    }
    return item;
  })),
);

export function reducer(state: any | undefined, action: Action) {
  return socialGroupsReducer(state, action);
}
