import { Action, createReducer, on } from '@ngrx/store';
import { loadPlans } from '../actions/subscription.actions';

export const initialState: any = {
  plans: []
};

const websiteReducer = createReducer(
  initialState,
  on(loadPlans, (state, { plans }) => ({ ...state, plans }))
);

export function reducer(state: any[], action: Action) {
  return websiteReducer(state, action);
}
