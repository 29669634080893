export const authentication = {
  confirmEmail: {
    anActivationEmailHasBeenSent: 'Er is een activeringslink verzonden, controleer uw e-mail',
    weHaveSentActivationLinkToEmailAddress: 'We hebben een activeringslink naar het e-mailadres gestuurd',
    toVerifyYourAccount: 'om uw account te verifiëren.',
    onceVerifiedAutomaticallyRedirected: 'Zodra geverifieerd, wordt u automatisch doorverwezen naar het paneel.',
    didNotReceiveAnEmail: 'Geen e-mail ontvangen? Controleer uw spam-map',
    resendIn: 'Opnieuw verzenden in ',
    seconds: ' seconden',
    resend: 'Opnieuw verzenden',
    backToSignUp: 'Terug naar Aanmelden',
    greatPleaseCheckYourMessage: 'Geweldig, controleer uw e-mail',
    weHaveSentEmailTo: 'We hebben een e-mail gestuurd naar',
    withALinkToReset: ' met een link om uw account te resetten. Zodra geactiveerd, kunt u uw accountcreatie voltooien en uw gratis proefperiode starten.',
    resent: 'Opnieuw verzonden',
    backToLogin: 'Terug naar Inloggen',
  },
  forgotPassword: {
    forgotPassword: 'Wachtwoord vergeten?',
    noWorries: 'Geen zorgen,',
    weWillSendYouResetPasswordInstructions: 'we sturen u instructies om uw wachtwoord opnieuw in te stellen',
    workEmail: 'Werk E-mail',
    continue: 'Doorgaan',
    backToLogin: 'Terug naar Inloggen',
    placeholders: {
      enterYourEmail: 'Voer uw e-mailadres in',
    }
  },
  logout: {
    logout: 'Uitloggen',
    wantToLogOut: 'Weet u zeker dat u wilt uitloggen?',
    yesIWillBeBack: 'Ja, ik kom terug',
    cancel: 'Annuleren',
  },
  newPassword: {
    createNewPassword: 'Creëer een nieuw wachtwoord',
    yourNewPasswordMustBeDifferent: 'Uw nieuwe wachtwoord moet verschillend zijn van eerder gebruikte wachtwoorden.',
    password: 'Wachtwoord',
    confirmPassword: 'Bevestig Wachtwoord',
    setPassword: 'Wachtwoord Instellen',
    signUp: 'Aanmelden',
    login: 'Inloggen',
    backTo: 'Terug naar ',
    setAPassword: 'Stel een wachtwoord in',
    setNewPasswordToYourAccount: 'Stel een nieuw wachtwoord in voor uw account voor beveiligings- en privacydoeleinden',
    backToSignUp: 'Terug naar Aanmelden',
    placeholders: {
      enterYourPassword: 'Voer uw wachtwoord in',
    }
  },
  resetPassword: {
    passwordReset: 'Wachtwoord resetten',
    yourPasswordHasBeenSuccessfullyReset: 'Uw wachtwoord is succesvol gereset.',
    clickBelowToLogIn: 'Klik hieronder om in te loggen',
    continueToLogin: 'Doorgaan naar Inloggen',
  },
  newSignUp: {
    welcomeBack: 'Welkom Terug ',
    to: 'naar',
    logIn: 'Inloggen ',
    as: 'als ',
    withFacebook: 'met Facebook',
    logInWithGoogle: 'Inloggen met Google',
    or: 'OF',
    pleaseFillAValidWorkEmail: 'Vul alstublieft een geldig werk e-mailadres in*',
    workEmailIsRequired: 'Werk e-mail is verplicht*',
    pleaseFillAValidPassword: 'Vul alstublieft een geldig wachtwoord in*',
    passwordIsRequired: 'Wachtwoord is verplicht*',
    rememberMe: 'Onthoud mij',
    forgotPassword: 'Wachtwoord vergeten?',
    login: 'Inloggen',
    doNotHaveAnAccount: 'Heeft u nog geen account?',
    setUpAFreeAccount: 'Stel een GRATIS Account in',
    noCreditCardRequired: 'Geen creditcard vereist',
    cancelAnytime: 'Altijd annuleren',
    signUp: 'Aanmelden ',
    signUpWithGoogle: 'Aanmelden met Google',
    emailIsAlreadyRegistered: 'E-mail is al geregistreerd bij ons, klik op Inloggen om door te gaan',
    phoneNumberShouldHaveMinimum7Digits: 'Telefoonnummer moet minimaal 7 cijfers hebben*',
    phoneNumberIsRequired: 'Telefoonnummer is verplicht*',
    passwordMustContain: 'Wachtwoord moet bevatten',
    atleast6Characters: 'minstens 6 tekens, ',
    oneUpperCaseLetter: '1 hoofdletter ',
    oneSpecialCharacter: '1 speciaal teken*',
    alreadyHaveAccount: 'Heeft u al een account?',
    signingUpToBotPenguinIsAConsentToOur: 'Aanmelden voor een BotPenguin-account is uw toestemming voor ons ',
    privacyPolicy: 'Privacybeleid',
    termsAndConditions: 'Algemene Voorwaarden',
    and: ' en ',
    english: 'Engels',
    hindi: 'Hindi',
    german: 'Duits',
    french: 'Frans',
    portuguese: 'Portugees',
    spanish: 'Spaans',
    italian: 'Italiaans',
    russian: 'Russisch',
    placeholders: {
      workEmail: 'Werk E-mail*',
      password: 'Wachtwoord*',
      phone: 'Telefoon*',
    }
  },
  unsubscribe: {
    successfullyUnsubscribed: 'Succesvol afgemeld',
    yourEmail: 'Uw e-mail ',
    haveBeenSuccessfullyUnsubscribedFrom: ' is succesvol afgemeld van',
    backToLogin: 'Terug naar Aanmelden'
  }
};
