import { createAction, props } from '@ngrx/store';
import Flow from '../../models/flow';

export const loadSocialFlows = createAction(
  '[Flow] Load Social Flows',
  props<{ flows: Flow[] | [] }>()
);

export const addSocialFlows = createAction(
  '[Flow] Add Social Flows',
  props<{ flow: Flow }>()
);

export const removeSocialFlows = createAction(
  '[Flow] Remove Social Flows',
  props<{ flow: string | number }>()
);

export const updateSocialFlows = createAction(
  '[Flow] Update Social Flows',
  props<{ flow: string | number, update: object | {} }>()
);

export const updateSocialQuestion = createAction(
  '[Flow] Update Social Question',
  props<{ flow: string | number, question: string, update: object | {} }>()
);





