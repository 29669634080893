import {Action, createReducer, on} from '@ngrx/store';
import { loadPendingConfigurations } from '../actions/pending-configurations.action';

export const configurationsFeatureKey = 'configuration';

export const initialState: string[] = [];

const pendingConfigurationsReducer = createReducer(
  initialState,
  on(loadPendingConfigurations, (state, {configurations}) => configurations)
);

export function reducer(state: string[], action: Action) {
  return pendingConfigurationsReducer(state, action);
}
