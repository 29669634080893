const brand = sessionStorage.getItem('brand');
export const home = {
  greetingText: 'Bună, ',
  greetingHand: '👋',
  upgrade: 'Upgrade',
  integrate: 'Integrează',
  integrate2: 'Integrează',
  chatGPT: 'ChatGPT ',
  forYourBusiness: 'pentru afacerea ta',
  insights: 'Insight-uri',
  message: 'Mesaje',
  addon: 'Add-on: ',
  leads: 'Lead-uri',
  yourBots: 'Bot-urile tale',
  botName: 'Numele bot-ului',
  platform: 'Platforma',
  contactOrLeads: 'Contacte/Lead-uri',
  actions: 'Acțiuni',
  edit: 'editează',
  delete: 'șterge',
  createA: 'Creează un ',
  whatsappChatbot: 'Chatbot WhatsApp ',
  connect: 'Conectează',
  getStartedWithUs: 'Începeți cu noi 👏',
  howToBuildAGoodChatFlow: ' Cum să construiești un flux de conversație bun',
  howToUseBotPenguinInboxAndItsFeatures: ' Cum să folosești Inbox-ul BotPenguin și funcțiunile sale',
  botPenguinPricing: ' Prețurile BotPenguin: Explorarea planurilor și funcțiunilor',
  openHelpDesk: ' Deschideți ghișeul de ajutor',
  helpAndSupport: 'Ajutor și suport',
  extensiveGuides: 'Ghiduri extinse pentru toate întrebările și fluxurile dvs. de lucru sunt disponibile în cadrul nostru',
  resourceCentre: 'Centrul de Resurse.',
  checkoutOurAnswers: 'Puteți verifica răspunsurile noastre la întrebările comune de mai jos.',
  createANewBot: 'Creează un bot nou',
  installYourBot: 'Instalează-ți bot-ul',
  plansAndPricing: 'Planuri și Prețuri',
  searchLanguage: 'Caută limbă',
  whatsappConversations: 'Conversații WhatsApp',
  diveDeeperIntoBotPenguin: 'Aprofundați-vă cunoștințele despre BotPenguin',
  knowWhereWeAreGoing: 'Știți unde mergem, actualizările recente și ajutați-ne să rezolvăm unele erori.',
  productUpdates: 'Actualizări ale Produsului',
  requestAFeature: 'Solicitați o funcționalitate',
  reportABug: 'Raportați o eroare',
  productRoadmap: 'Harta produsului',
  contactsOrLeads: 'Contacte/Lead-uri',
  english: 'Engleză',
  hindi: 'Hindi',
  german: 'Germană',
  french: 'Franceză',
  portuguese: 'Portugheză',
  spanish: 'Spaniolă',
  italian: 'Italiană',
  russian: 'Rusă',
  pleaseSelectYourReason: 'Vă rugăm să selectați motivul dumneavoastră',
  cancel: 'Anulează',
  submit: 'Trimite!',
  giveFeedback: 'Furnizați feedback',
  ratings: 'Evaluări',
  reasonBehindYourDecision: 'Motivul deciziei dumneavoastră',
  shareYourExperience: 'Împărtășiți-vă experiența',
  reason1: 'motivul1',
  reason2: 'motivul2',
  appTooltip: {
    HOME_INSIGHT: 'Oferă insight-uri în toate mesajele și lead-urile bot-urilor tale (Date actualizate în fiecare noapte)',
    HOME_MESSAGES: 'Mesaje consumate de contul dvs. în acest ciclu de facturare. (Mesajele de la botul WhatsApp, Facebook și Telegram sunt excluse.)',
    HOME_TOTAL_MESSAGES: 'Totalul mesajelor consumate de contul dvs. de la crearea contului (Mesajele de la botul WhatsApp, Facebook și Telegram sunt excluse.)',
    LEADS: 'Lead-uri capturate de bot-uri pentru contul dvs. în acest ciclu de facturare.',
    TOTAL_LEADS: 'Totalul contactelor/lead-urilor capturate de bot-uri pentru contul dvs. de la crearea contului (Date actualizate în fiecare noapte).',
    YOUR_BOT: 'Prezentare generală a tuturor bot-urilor tale și a utilizării lor în acest ciclu de facturare.',
    NAME: 'Numele bot-ului',
    PLATFORM: 'Platforma bot-ului',
    MESSAGES: 'Totalul mesajelor bot-ului de la crearea contului',
    CONTACTS: 'Totalul contactelor/lead-urilor bot-ului',
    ACTIONS: 'Editează/Șterge bot-ul',
    EDIT_ICON: 'Editează fluxul de conversație și setările bot-ului',
    DELETE_ICON: 'Șterge bot-ul',
    TOOL_TIP_CLASSIC_VERSION: `versiunea veche a interfeței utilizator a ${brand}-ului`
  },
  modal: {
    deleteBot: 'Șterge Bot-ul!',
    areYouSure: 'Sunteți sigur că doriți să ștergeți acest Bot?',
    enquiryForm: 'Formular de Întrebare',
    payNow: 'Plătește acum!',
    saveAndDelete: 'Salvează și Șterge',
    submit: 'Trimite',
  },
  noDataFound: {
    bots: 'Bot-uri',
    noBotCreatedYet: 'Niciun bot creat încă!',
  },
  yourFriendWillMissYou: 'Prietenul tău te va simți lipsindu-te',
  rateYourBotCreationExperience: 'Evaluează-ți experiența de creare a bot-ului',
  whatMadeYouDeleteYourBot: 'Ce te-a determinat să ștergi bot-ul?',
  iCreatedByMistake: 'L-am creat din greșeală',
  iWantToCreateAnotherBot: 'Vreau să creez alt Bot',
  couldNotFindFeaturesThatINeeded: 'Nu am putut găsi funcționalitățile de care aveam nevoie',
  uiWasHardToNavigate: 'Interfața utilizator a fost greu de navigat',
  iDonotWantToTell: 'Nu vreau să spun',
  other: 'Alt motiv',
  weWillDoOurBestToMakeAutomationFun: 'Vom face tot posibilul să facem automatizarea distractivă și simplă pentru toți.',
};
