const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Mijn Account',
  yourProfileAndPasswordSecurity: 'Je profiel en wachtwoordbeveiliging',
  detailsHeader: 'Gegevens',
  passwordHeader: 'Wachtwoord',
  customAttributeHeader: 'Aangepaste Attributen',
  tagsHeader: 'Label',
  accessTokenHeader: 'Toegangstoken',
  thirdPartyIntegrationsHeader: 'Integraties van derden',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Werk je accountprofiel bij',
    TOOLTIP_PASSWORD_HEADING: 'Verander je wachtwoord',
    TOOLTIP_TAGS_HEADING: 'Beheer je Tags',
    TOOLTIP_THIRD_PARTY_HEADING: 'Integraties van derden',
    TOOLTIP_CHANGE_PASSWORD_TITLE: `Een veilig wachtwoord helpt je ${brand} account te beschermen`,
    TOOLTIP_INTEGRATIONS: 'Integreren',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Voeg of werk aangepaste eigenschappen bij',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Beheer aangepaste eigenschappen van je account'
  },
  accessToken: {
    accessToken: 'Toegangstoken',
    generateYourAccessTokenForUsingPlatformAPIs: `Genereer je toegangstoken voor het gebruik van platform API's`,
    generateApiToken: 'Genereer API Token',
    revokeApiToken: 'Herroep API Token',
    regenerateApiToken: 'Regenereer API Token',
    placeholders: {
      yourApiKey: 'Jouw API-sleutel',
    }
  },
  details: {
    name: 'Naam',
    emailId: 'E-mailadres ',
    yourPlan: 'Je Plan',
    messagesPerMonth: ' berichten/maand',
    daysOfStoragePlan: ' dagen opslagplan',
    conditionalFeatures: 'voorwaardelijke functies',
    upgradeToEnterprisePlanToGetMore: 'Upgrade naar het Enterprise-plan voor meer',
    featuresAndStorageCapacity: 'functies en opslagcapaciteit',
    upgradePlan: 'Upgrade Plan',
    submit: 'Verzenden',
    selectLanguage: 'Selecteer Taal',
    baby: 'Baby',
    king: 'Koning',
    conversationsperMonth: 'gesprekken/maand',
    english: 'Engels',
    hindi: 'Hindi',
    german: 'Duits',
    french: 'Frans',
    portuguese: 'Portugees',
    spanish: 'Spaans',
    italian: 'Italiaans',
    russian: 'Russisch',
    chatBotBrandName: 'ChatBot-merknaam',
    placeholders: {
      john: 'John',
      email: 'E-mail',
      searchLanguage: 'Zoek Taal',
      chatBotBrandName: 'ChatBot-merknaam'
    },
    appTooltip: {
      TOOLTIP_NAME: 'Voer je profielnaam in',
      TOOLTIP_EMAIL_ADDRESS: 'Voer je e-mailadres in',
    }
  },
  customAttribute: {
    addAttribute: 'Attribuut Toevoegen',
    discard: 'Afwijzen',
    customAttribute: 'Aangepaste Attributen',
    checkOrAddCustomAttribute: 'Hier kunt u uw aangepaste attributen controleren en toevoegen.',
    attributeKey: 'Attribuut Sleutel',
    attributeName: 'Attribuut Naam',
    bot: 'Bot',
    flow: 'Stroom',
    loadMore: 'Meer Laden',
    question: 'Vraag',
    action: 'Actie',
    search: 'Zoeken',
    selectBot: 'Selecteer Bot',
    selectFlow: 'Selecteer Stroom',
    selectQuestion: 'Selecteer Vraag',
    noDataFoundHeading: 'U heeft nog geen eigenschap aangemaakt',
    noDataFoundSubheading: 'Al uw eerbetuigingen worden hier weergegeven',
    deleteTitle: 'Verwijder Eigenschap',
    deleteDescription: 'Bent u zeker dat u deze Eigenschap wilt verwijderen?',
    deleteAttributes: 'Verwijder Eigenschappen',
    areYouSureYouWantToDeleteThisAttributes: 'Weet u zeker dat u deze Eigenschappen wilt verwijderen?',
    editAttribute: 'Bewerk Eigenschap',
    headsUp: 'Let op',
    okay: 'Oké',
    dataAddedToLead: `De aan de API's of Bot Flows gekoppelde attributen worden niet bijgewerkt. Werk de API's of Bot Flows dienovereenkomstig bij`,
    placeholders: {
      enterAttributeKey: 'Voer Attribuut Sleutel In',
      enterAttributeName: 'Voer Attribuut Naam In',
      selectBot: 'Selecteer Bot',
      selectFlow: 'Selecteer Stroom',
      selectQuestion: 'Selecteer Vraag',
      search: 'Zoek hier',
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Voer Attribuut Sleutel In',
      TOOLTIP_ATTRIBUTE_NAME: 'Voer Attribuut Naam In',
      TOOLTIP_SELECT_BOT: 'Selecteer Bot',
      TOOLTIP_SELECT_FLOW: 'Selecteer Stroom',
      TOOLTIP_SELECT_QUESTION: 'Selecteer Vraag',
    }
  },
  password: {
    changePassword: 'Wachtwoord wijzigen',
    aSecurePasswordHelpsProtectYour: 'Een veilig wachtwoord helpt je ',
    account: ' Account te beschermen',
    currentPassword: 'Huidig Wachtwoord',
    newPassword: 'Nieuw Wachtwoord',
    confirmPassword: 'Bevestig Wachtwoord',
    cancel: 'Annuleren',
    updatePassword: 'Wachtwoord bijwerken',
    deleteAccount: 'Account Verwijderen',
    placeholders: {
      enterYourCurrentPassword: 'Voer je huidige wachtwoord in',
      enterYourNewPassword: 'Voer je nieuwe wachtwoord in',
      enterYourConfirmPassword: 'Voer je nieuwe wachtwoord opnieuw in',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Voer het oude wachtwoord in',
      TOOLTIP_NEW_PASSWORD: 'Voer het nieuwe wachtwoord in',
      TOOLTIP_CONFIRM_PASSWORD: 'Bevestig je nieuwe wachtwoord',
      TOOLTIP_DELETE_ACCOUNT: `Verwijder je huidige ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Beheer Tags',
    createNewTags: 'Maak Nieuwe Tags',
    createNewTagsSubText: 'Beheer hier je tags om ze beter te gebruiken voor het organiseren van leads, chats, groepen, enz.',
    tagName: 'Tag Naam',
    updateTag: 'Update Tag',
    createTag: 'Maak Tag',
    discard: 'Afbreken',
    searchTag: 'Zoek Tag op Naam',
    noTagFound: 'Geen Tag Gevonden!',
    createATag: 'Maak een Tag',
    deleteTagModalSubText: 'Weet je zeker dat je deze tag wilt verwijderen? Het verwijderen van deze tag zal deze ook verwijderen bij de toegewezen gebruikers!',
    deleteTagModalHeading: 'Verwijder Tags',
    deleteOneTagModalHeading: 'Verwijder Tag',
    actions: 'Acties',
    placeholders: {
      enterTag: 'Voer Tag Naam in',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'Verwijder Tags',
    }
}
,
  thirdPartyIntegration: {
    list: {
      name: 'Naam',
      status: 'Status',
      action: 'Actie',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird voor het verzenden van berichten en e-mails',
      notConnected: 'Niet Verbonden',
      connected: 'Verbonden',
      edit: 'Bewerken',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo voor het verzenden van berichten',
      connect: 'Verbinden',
      appTooltip: {
        nameOfAppOrCrm: 'Naam van App/CRM',
        statusAsConnectedOrNotConnected: 'Status als verbonden / niet-verbonden',
        clickToConnectAppOrCrm: 'Klik om de App/CRM te verbinden',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Oeps! Integraties zijn niet beschikbaar in het Baby-plan. Upgrade naar het King-plan voor integraties.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'Integreer met MessageBird',
        apiKey: 'API-sleutel',
        originator: 'Afzender',
        connect: 'Verbinden',
        disconnect: 'Ontkoppelen',
        seeActivities: 'Activiteiten bekijken',
        sms: 'SMS',
        time: 'Tijd',
        phoneNumber: 'Telefoonnummer',
        status: 'Status',
        botName: 'Bot Naam',
        message: 'Bericht',
        error: 'Fout',
        placeholders: {
          apiKey: 'API-sleutel',
          messageBirdPhoneNumber: 'MessageBird Telefoonnummer',
        },
        noDataFound: {
          activities: 'Activiteiten',
          noActivitiesFound: 'Geen activiteiten gevonden!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Integreer met Plivo',
        authId: 'Auth ID',
        authToken: 'Auth Token',
        yourPlivoPhoneNumber: 'Je Plivo Telefoonnummer',
        connect: 'Verbinden',
        disconnect: 'Ontkoppelen',
        seeActivities: 'Activiteiten bekijken',
        sms: 'SMS',
        time: 'Tijd',
        phoneNumber: 'Telefoonnummer',
        status: 'Status',
        botName: 'Bot Naam',
        message: 'Bericht',
        error: 'Fout',
        noDataFound: {
          activities: 'Activiteiten',
          noActivitiesFound: 'Geen activiteiten gevonden!',
        },
        placeholders: {
          authId: 'Auth ID',
          authToken: 'Auth Token',
          yourPlivoPhoneNumber: 'Je Plivo Telefoonnummer',
        }
      }
    }
  }
};
