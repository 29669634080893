const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Modifiez votre flux de chat',
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    classicBuilder: 'Constructeur classique',
    visualiseFlow: 'Visualiser le flux',
    testLiveOnTelegramWeb: 'Tester en direct sur le Web Telegram',
    testFlow: 'Flux de test',
    importTemplate: 'Modèle d`importation',
    landingPageBotReview: 'Page de destination Aperçu du bot',
    install: 'Installer',
    testLiveOnMessengerWeb: 'Tester en direct sur le Web Messenger',
    testLiveOnWhatsappWeb: 'Tester en direct sur whatsapp web',
    testLiveOnInstagramWeb: 'Tester en direct sur Instagram Web',
    seeAllChatFlow: 'Voir tous les flux de chat',
    launchDemoModal: 'Lancement de démo modal',
    selectATemplateToKickStartYourBot: 'Sélectionnez un modèle pour démarrer votre bot',
    hireChatBotDesigner: 'Embaucher le concepteur de chatbot',
    recommendedForYou: 'Recommandé pour vous',
    useTemplate: 'Utilise le modèle',
    exportStatus: 'Statut d`exportation',
    flowExport: 'Exportation',
    download: 'Télécharger',
    importFlow: 'Flux d`importation',
    beforeYouStartUploadPleaseMakeSure: 'Avant de démarrer le téléchargement, assurez-vous',
    ponit1: '1. La limite de téléchargement de taille de fichier maximale est de 1 Mo',
    point2: '2. Le format de fichier doit être en JSON',
    selectFile: 'Choisir le dossier',
    preview: 'Aperçu',
    test: 'Test',
    OopsIntegrationAreNotAvailable: 'Oups ! Les intégrations ne sont pas disponibles dans le ',
    pleaseUpgradeTo: ' Veuillez passer au ',
    planForIntegration: ' pour les intégrations.',
    placeholders: {
      searchTemplateYouNeed: 'Recherchez le modèle dont vous avez besoin',
    },
    noDataFound: {
      botFlow: 'Flux de bot',
      noBotFlowAddedYet: 'Aucun flux de bot ajouté encore!',
      templates: 'Modèles',
      noTemplatesFound: 'Aucun modèle trouvé!',
    },
    appTooltip: {
      exportFlow: 'Flux d`exportation',
      importFlow: 'Flux d`importation',
      clickToViewChatFlow: 'Cliquez pour afficher le flux de chat dans Drag and Drop Builder',
      clickToPreviewChatFlow: 'Cliquez pour prévisualiser le flux de chat',
      clickToViewChatFlowAsFlowDiagram: 'Cliquez pour afficher le flux de chat en tant que diagramme de flux',
      clickToTestChatFlow: 'Cliquez pour tester le flux de chat',
      clickToSelectAndImportTemplate: 'Cliquez pour sélectionner et importer le modèle qui correspond le mieux à votre entreprise.',
      clickToInstallYourBotToSelectedPlatform: 'Cliquez pour installer votre bot sur la plate-forme sélectionnée',
      clickToTestDefaultFlowOfTelegramBot: 'Cliquez pour tester le flux par défaut de vos bots (actif) sur le Web télégramme',
      clickToTestDefaultFlowOfFacebookBot: 'Cliquez pour tester le flux par défaut de vos robots (actif) sur Facebook Web',
      clickToTestDefaultFlowOfWhatsappBot: 'Cliquez pour tester vos bots par défaut (actif) sur WhatsApp Web',
      clickToTestDefaultFlowOfInstagramBot: 'Cliquez pour tester le flux par défaut de vos robots (actif) sur Instagram Web',
    }
  },
  createNewBot: {
    createNewBot: 'Créer un nouveau bot',
    stepOneOfTwo: 'Étape 1 sur 2',
    selectThePlatform: 'Sélectionnez la plate-forme',
    selectTheBotType: 'Sélectionnez le type de robot',
    aiBasedChatbot: 'Chatbot basé sur l\'IA',
    ruleBasedChatBot: 'Chatbot basé sur des règles',
    webPlatforms: 'Plates-formes Web',
    websiteChatBot: 'Chatbot de site Web',
    withLiveChat: '(Avec chat en direct)',
    landingPageBot: 'Page de destination bot',
    liveChatWidget: 'Widget de chat en direct',
    onlyLiveChat: '(Seulement chat en direct)',
    socialPlatforms: 'Plateformes sociales',
    whatsappChatBot: 'Chatbot whatsapp',
    telegramChatBot: 'Chatbot télégramme',
    facebookChatBot: 'Chatbot facebook',
    instagramChatBot: 'Chatbot Instagram',
    selectBotType: 'Sélectionnez le type de robot',
    selectProblem: 'Sélectionnez le problème que vous souhaitez résoudre',
    leadGeneration: 'Génération de leads',
    customerSupport: 'Support client',
    appointmentBooking: 'Réservation de rendez-vous',
    marketingAutomation: 'Automatisation marketing',
    ecommerceWebsite: 'Site Web de commerce électronique',
    otherUseCase: 'Autre',
    back: 'Retour',
    typeDomainUrl: 'Saisissez l\'URL de votre site Web pour vérification.',
    dataSourceForChatBotTraining: 'Source de données pour la formation du chatbot',
    upload: 'Télécharger',
    uploadDocuments: 'Télécharger des documents',
    uploadDocument: 'Télécharger un document',
    selectFile: 'Sélectionner un fichier',
    continue: 'Continuer',
    websiteUrl: 'Saisissez l\'URL de votre site Web',
    skipNow: 'Passer maintenant',
    completeTheExistingBotSetup: 'Complétez la configuration du bot existant',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Vous avez déjà créé un bot WhatsApp. Veuillez terminer configuration pour en créer un nouveau.',
    completeSetup : 'Configuration complète',
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    fbBot: {
      createNewBot: 'Créer un nouveau bot',
      help: 'Aider',
      back: 'Dos',
      step: 'Marcher',
      of3: ' de 3',
      loginWithFacebook: 'Se connecter avec Facebook',
      asPerLatestChangesInFacebookPolicy: 'Selon les derniers changements dans les politiques Facebook, le propriétaire de Facebook Bot doit avoir un rôle de «gestionnaire» ou supérieur pour accéder à toute la fonctionnalité de la page Facebook.',
      login: 'Connexion',
      connectFacebookBot: 'Connecter le bot Facebook',
      connectBotWithYourFacebookPagesAnd: 'Connectez Bot à vos pages Facebook et',
      exploreTruePowerOfFacebook: 'Explorez le vrai pouvoir de Facebook',
      connect: 'Connecter',
      connected: 'Connecté',
      createNewPage: 'Créer une nouvelle page',
      refreshPageList: 'Refreindre la liste des pages',
      thisPageIsSuccessfullyIntegratedWith: 'Cette page est intégrée avec succès avec',
      yourMessagesFromMessengerWillAppearIn: '. Vos messages de Messenger apparaîtront dans',
      inbox: ' Boîte de réception',
      continueToBotConfiguration: 'Continuez à la configuration de bot',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenue à ${brand}. Nous sommes là pour vous aider.`,
      letsTryYourBotAndSeeHowItWorks: 'Essayons votre bot et voyons comment cela fonctionne! Cliquez sur "Tester sur Facebook".',
      howAreYouDoingToday: 'Comment allez-vous aujourd`hui?',
      thisIsHowSimpleToUseFacebookBot: 'C`est ça! C`est à quel point il est simple d`utiliser Facebook Bot',
      letsCreateYourOwnChatFlowNow: 'Créons votre propre flux de chat maintenant',
    },
    instagramBot: {
      createNewBot: 'Créer un nouveau bot',
      back: 'Dos',
      step: 'Marcher',
      of3: ' de 3',
      loginWithInstagram: 'Connectez-vous avec Instagram',
      loginToYourInstagramAccount: 'Connectez-vous à votre compte Instagram pour activer le chatbot sur votre poignée.',
      managerLevelDescription: 'Les propriétaires de robots Instagram sont tenus d`avoir au moins un niveau d`autorisations "manager" ou plus pour accéder à toutes les fonctionnalités.',
      login: 'Connexion',
      connectInstagramBot: 'Connecter le bot Instagram',
      connectBotWithYourInstagramAnd: 'Connectez Bot à votre Instagram et',
      exploreTruePowerOfInstagram: ' Explorez le vrai pouvoir d`Instagram',
      connected: 'Connecté',
      connect: 'Connecter',
      createNewPage: 'Créer une nouvelle page',
      refreshPageList: 'Refreindre la liste des pages',
      thisPageIsSuccessfullyintegratedWith: 'Cette page est intégrée avec succès avec',
      yourMessagesFromMessengerWillAppearIn: '. Vos messages de Messenger apparaîtront dans',
      inbox: ' Boîte de réception',
      continueToBotConfiguration: 'Continuez à la configuration de bot',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenue à ${brand}. Nous sommes là pour vous aider.`,
      letsTryYourBotAndSeeHowItWorks: 'Essayons votre bot et voyons comment cela fonctionne! Cliquez sur "Tester sur Instagram".',
      howAreYouDoingToday: 'Comment allez-vous aujourd`hui?',
      thisIsHowSimpleToUseInstagramBot: 'C`est ça! C`est à quel point il est simple d`utiliser Instagram Bot',
      letsCreateYourOwnChatFlowNow: 'Créons votre propre flux de chat maintenant',
    },
    telegramBot: {
      back: 'Dos',
      step: 'Marcher',
      of3: ' de 3',
      seeHowToUse: 'Voir comment utiliser',
      help: 'Aider',
      loginToYourTelegramAccount: 'Connectez-vous à votre compte télégramme',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Connectez-vous à votre compte pour créer un nouveau bot ou connecter le bot existant',
      verificationCodeSentOnRegisteredNumber: 'Code de vérification envoyé sur le numéro enregistré',
      createYourBot: 'Créez votre bot',
      enterYourBotsNameAnd: 'Entrez le nom de votre bot, connectez-vous et laissez le plaisir commencer par',
      botUserNameMustEndWithBotAtTheEnd: 'Le nom d`utilisateur de bot doit se terminer avec le "bot" à la fin.',
      terrisBot: 'Par exemple. teris_bot ou terisbot',
      botNameAlreadyTaken: 'Ce nom de bot est déjà pris. Veuillez essayer quelque chose de différent',
      connect: 'Connecter',
      createBot: 'Créer un bot',
      codeNotReceivedResend: 'Code non reçu? RENNIVERSE!',
      changeNumber: 'Numéro de modification',
      telegramPreview: 'Aperçu du télégramme',
      worldsFastestMessagingApp: 'Application de messagerie la plus rapide du monde et c`est gratuit!',
      bot: 'Bot',
      phoneNumber: 'Numéro de téléphone',
      userName: 'Nom d`utilisateur',
      none: 'Aucun',
      bio: 'Bio',
      fewWordsAboutYourself: 'Quelques mots sur vous',
      settings: 'Paramètres',
      notificationsAndSound: 'Notifications et sons',
      privacyAndSecurity: 'Confidentialité et sécurité',
      dataAndStorage: 'Données et stockage',
      chatSettings: 'paramètres de discussion',
      chatFolders: 'Dossiers de discussion',
      devices: 'Dispositifs',
      connectYourBot: 'Connectez votre bot',
      createNewBotOrConnectWithExistingOne: 'Créer un nouveau bot ou connecter celui existant',
      createNewBot: 'Créer un nouveau bot',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenue à ${brand}. Nous sommes là pour vous aider.`,
      letsTryYourBotAndSeeHowItWorks: 'Essayons votre bot et voyons comment cela fonctionne! Cliquez sur "Tester sur Telegram".',
      howAreYouDoingToday: 'Comment allez-vous aujourd`hui?',
      thisIsHowSimpleToUseTelegramBot: 'C`est ça! C`est à quel point il est simple d`utiliser le bot télégramme',
      letsCreateYourOwnChatFlowNow: 'Créons votre propre flux de chat maintenant',
      placeholders: {
        searchCountry: 'Pays de recherche',
        enterTelegramMobileNumber: 'Entrez le numéro de mobile Telegram',
        enterLoginCodeReceived: 'Entrez le code de connexion reçu sur le compte télégramme *',
        twoFactorAuthentication: 'Mot de passe 2FA (facultatif)',
        botName: 'Nom du bot *',
        botUserName: 'Nom d`utilisateur bot *',
      }
    },
    websiteLandingPageLiveChatBot: {
      createNewBot: 'Créer un nouveau bot',
      seeHowToUse: 'Voir comment utiliser',
      help: 'Aider',
      back: 'Dos',
      step: 'Marcher',
      of2: ' de 2',
      buildA: 'Construire un',
      thatMatchesYourBrandPersonality: ' qui correspond à la personnalité de votre marque',
      chatBotIcon: 'Icône de chatbot',
      optional: 'Facultatif',
      themeColor: 'Couleur du thème',
      chatBotName: 'Nom de chatte',
      botNameIsRequired: 'Le nom de bot est requis',
      botNameShouldBeLessThan50Characters: 'Le nom du bot doit être inférieur à 50 caractères',
      continue: 'Continuer',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenue à ${brand}. Nous sommes là pour vous aider.`,
      whatIsYourEmailAddress: 'Quelle est ton adresse email?',
      invalidEmailAddress: 'Adresse e-mail invalide',
      customizeYourLandingPageBotLook: 'Personnalisez votre page de destination bot look',
      customizeYourWidgetLook: 'Personnalisez votre look widget',
      customizeYourBotLook: 'Personnalisez votre look bot',
      buildYourLandingPageBotAndAutomateInteraction: 'Créez votre page de destination Bot et automatisant l`interaction avec vos clients en partageant son lien',
      useLiveChatBotToConnectToCustomers: 'Utilisez le bot de chat en direct pour vous connecter à vos clients en temps réel',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Connectez le chatbot à votre site Web et automatisant les interactions avec les visiteurs',
      widget: 'widget',
      botInterface: 'interface bot',
      mergedBot: 'Chatbot du site web, Chatbot de la page de destination, Chatbot de l\'application mobile',
      mergedBotToolTip: 'Créez une page de bot pour capturer l\'attention des visiteurs et les engager dans des conversations.',
      placeholders: {
        typeANameForYourBot: 'Tapez un nom pour votre nouveau bot',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Entrez votre nom de bot',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Sélectionnez une couleur de thème pour votre fenêtre de chat',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Ce logo sera utilisé uniquement dans la plate-forme ${brand ? brand : ''}. Nous vous suggérons de choisir le logo de votre entreprise. Ajoutez une image carrée PNG ou JPG sous 1 Mo.`,
      }
    },
    whatsappBot: {
      back: 'Dos',
      justAStepAway: 'Juste un pas',
      seeHowToUse: 'Voir comment utiliser',
      help: 'Aider',
      createWhatsappChatBot: 'Créer un chatbot WhatsApp',
      businessNameIsRequired: 'Le nom de l`entreprise est requis.',
      maximumLengthForBusinessName40Characters: 'La longueur maximale pour le nom de l`entreprise est de 40 caractères.',
      businessCategory: 'Catégorie Business',
      categoryIsRequired: 'Une catégorie est requise.',
      descriptionIsRequired: 'Description est requise.',
      maximumLengthForDescription200Characters: 'La longueur maximale pour la description est de 200 caractères.',
      pleaseFillAValidEmailAddress: 'Veuillez remplir un e-mail commercial valide.',
      businessEmailIsRequired: 'Un e-mail d`entreprise est requis.',
      phoneNumberShouldHaveMinimum7Digits: 'Le numéro de téléphone doit avoir un minimum de 7 chiffres',
      phoneNumberIsRequired: 'Le numéro de téléphone est requis',
      websiteIsRequired: 'Le site Web est requis.',
      maximumLengthForWebsite40Characters: 'La longueur maximale pour le site Web est de 40 caractères.',
      maximumLengthForAddress150Characters: ' La longueur maximale pour l`adresse est de 150 caractères.',
      doYouHaveWhatsappApiKey: 'Avez-vous une clé API WhatsApp?',
      apiProvider: 'Fournisseur d`API',
      three60Dialog: 'Boîte de dialogue 360',
      whatsappCloud: 'Cloud WhatsApp',
      netcore: 'Netcore',
      apiKeyIsRequired: 'La clé API est requise.',
      whatsappBusinessAccountIdIsRequired: 'L`ID du compte Businness WhatsApp est requis.',
      whatsappPhoneNumberIdIsRequired: ' L`ID de numéro de téléphone WhatsApp est requis.',
      systemAccessTokenIsRequired: 'Le jeton d`accès système est requis.',
      sourceIdIsRequired: 'L`ID de source est requis.',
      // apiKeyIsRequired: 'La clé API est requise.',
      // createWhatsappChatBot: 'Créer un chatbot WhatsApp',
      businessName: 'Nom d`entreprise',
      businessAccount: 'Compte commercial',
      // businessCategory: 'Catégorie Business',
      businessDescription: 'Description de l`activité',
      address: 'Adresse',
      businessEmail: 'E-mail d`entreprise',
      websiteUrl: 'URL de site web',
      thankYouForYourInterestIn: 'Merci pour votre intérêt pour',
      whatsappChatBot: ' Chatbot WhatsApp.',
      weHaveReceivedYourInterests: 'Nous avons reçu votre intérêt et notre équipe de vente vous contactera dans les prochaines 24 heures.',
      theyWillProvideYouWhatsappApiKey: 'Ils vous fourniront une clé API WhatsApp.',
      readyToCreateWhatsappBot: 'Je suis prêt à créer un bot WhatsApp',
      exploreWhatsappFeatures: 'Explorez les fonctionnalités de WhatsApp',
      welcomeToBrandNameWeAreHereToHelpYou: `Bienvenue à ${brand}. Nous sommes là pour vous aider.`,
      letsTryYourBotAndSeeHowItWorks: 'Essayons votre bot et voyons comment cela fonctionne! Cliquez sur "Tester sur WhatsApp".',
      howAreYouDoingToday: 'Comment allez-vous aujourd`hui?',
      thisIsHowSimpleToUseWhatsappBot: 'C`est ça! C`est à quel point il est simple d`utiliser WhatsApp Bot',
      letsCreateYourOwnChatFlowNow: 'Créons votre propre flux de chat maintenant',
      connectWhatsAppBot: 'Connecter le Bot WhatsApp',
      createtWhatsAppBot: 'Créer un bot WhatsApp',
      proceed: 'Continuer',
      whatsApiProvider: `Fournisseur d'API WhatsApp`,
      cloudApi: 'API Cloud',
      netcoreApi: 'API Netcore',
      placeholders: {
        yourBusinessName: 'Nom de votre entreprise',
        businessDescription: 'Description de l`activité',
        businessEmail: 'E-mail d`entreprise',
        phone: 'Téléphone*',
        website: 'Site Web (par exemple www.example.com)',
        address: 'Adresse',
        enterApiKey: 'Entrez la clé de l`API',
        enterWhatsappBusinessAccountId: 'Entrez l`ID du compte Businness WhatsApp',
        enterWhatsappPhoneNumberId: 'Entrez l`ID de numéro de téléphone WhatsApp',
        enterSystemAccessToken: 'Entrez le jeton d`accès système',
        enterSourceId: 'Entrez l`ID source',
        businessName: `Nom de l'entreprise`,
        businessWebsite: `Site Web de l'entreprise`,
        selectWhatsappApiProvider: 'Sélectionnez le fournisseur d`API WhatsApp',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: `Choisissez votre fournisseur d'API WhatsApp`,
        doYouHaveWhatsAppBusinessAPI: `Avez-vous WhatsApp Business API ?`,
        yes: `Oui`,
        noIAmNotSure: `Non je ne suis pas sûr(e)`,
        selectApiProvider: `Sélectionner le fournisseur d'API`,
        whatsAppCloudAPI: `WhatsApp Cloud API`,
        enterYourPhoneNumberId: `Entrez votre ID de numéro de téléphone`,
        phoneNumberId: `ID de numéro de téléphone`,
        enterYourWhatsAppBusinessAccountId: `Entrez votre ID de compte WhatsApp Business`,
        whatsAppBusinessAccountId: `ID de compte WhatsApp Business`,
        enterYourSystemUserToken: `Entrez votre jeton d'utilisateur système`,
        systemUserToken: `Jeton d'utilisateur système`,
        help: `Aide`,
        netcoreAPI: `API Netcore`,
        enterYourSourceId: `Entrez votre ID de source`,
        sourceId: `ID de source`,
        enterYourNetcoreApiKey: `Entrez votre clé API Netcore`,
        netcoreApiKey: `Clé API Netcore`,
        submit: `Soumettre`,
        noneOftheAbove: `Aucun des éléments ci-dessus`,
        next: 'Suivant'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Entreprise légalement enregistrée',
        validWebsiteWithDataPrivacyPolicy: 'Site web valide avec politique de confidentialité des données',
        gstin: 'GSTIN',
        debit: 'Débito',
        creditCard: 'Tarjeta de crédito',
        onlyRequiredForIndianResidents: 'Uniquement requis pour les résidents indiens',
        onlyRequiredFor: 'Uniquement requis pour',
        indianResidents: 'Résidents indiens',
        validPaymentMethod: 'Méthode de paiement valide',
        enabledWithInternationalPayments: 'Activé avec les paiements internationaux',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: `Cas d'utilisation valide conforme à la politique commerciale de WhatsApp`,
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: `Numéro de téléphone qui n'a pas WhatsApp actif et capable de recevoir les SMS`,
        discard: 'Rejeter',
        proceed: 'Continuer',
        whatsAppBotPrerequisites: 'Prérequis du bot WhatsApp',
        letUsGetItDoneForYou: 'Laissez-nous le faire pour vous !',
        ifYouAreFacingSomeIssues: 'Si vous rencontrez des problèmes',
        ourTeamCanHelpYouDoTheSame: 'Notre équipe peut vous aider à faire de même',
        requestACallbackNow: 'Demandez un rappel maintenant !',
      },
      stepThree: {
        setupWhatsAppBot: 'Configurer le Bot WhatsApp',
        selectingTheBestBusinessManagerForYourNeeds: `Choisir le Meilleur Gestionnaire d'Entreprise selon vos Besoins`,
        createMetaApp: 'Comment créer une application Meta',
        createaMetaAppOn: 'Créer une Meta App sur',
        howToCreate: 'Comment créer',
        addWhatsAppNumber: 'Comment ajouter un numéro WhatsApp',
        addYourWhatsAppNumberAndVerifyTheSame: `Ajouter votre Numéro WhatsApp et Vérifier le même`,
        subscribe: `S'abonner`,
        copyAndSubscribeWebhook: `Copier et s'abonner au Webhook`,
        howToSubscribe: `Comment s'abonner`,
        copy: 'Copier',
        appSecretId: `ID Secret de l'Application`,
        enterYourAppSecretId: `Entrez votre ID Secret de l'Application`,
        enterAppSecret: `Entrer le Secret de l'Application`,
        phoneNumberId: `ID du Numéro de Téléphone`,
        whatsAppBusinessId: `ID de l'Entreprise WhatsApp`,
        assignSystemUser: 'Comment attribuer un utilisateur système',
        assign: 'Assigner',
        createApp: 'Créer une Meta',
        registerAndVerify: `S'inscrire et vérifier`,
        add: 'Ajouter',
        systemUserToken: `Jeton d'utilisateur du système`,
        enterYourSystemUserToken: `Entrez votre jeton d'utilisateur du système`,
        getAllNecessaryDetails: `Comment obtenir tous les détails nécessaires`,
        subscribeWhatsAppWebhook: `Comment s'abonner au webhook WA`,
        configureTheWebhookForWhatsAppAccount: `Configurer le webhook pour le compte WhatsApp`,
        upadatePaymentMethod: 'Comment mettre à jour la méthode de paiement',
        assignThePaymentMethodToWhatsAppAccount: `Assigner le mode de paiement à n'importe quel compte WhatsApp`,
        grantControlOfWhatsAppBusinessAccountToASystemUser: `Accorder le contrôle d'un compte WhatsApp Business à un utilisateur système`,
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Détails clés que vous devez copier depuis le tableau de bord Meta',
        updatePayment: 'Mettre à jour le paiement',
        letUsGetItDoneForYou: 'Laissez-nous le faire pour vous !',
        ifYouAreFacingSomeIssues: 'Si vous rencontrez des problèmes',
        ourTeamCanHelpYouDoTheSame: 'Notre équipe peut vous aider à faire de même',
        requestACallbackNow: 'Demandez un rappel maintenant !',
        update: 'Mettre à jour',
        connectNow: 'Connectez-vous maintenant'
      },
      stepFive: {
        selectBusinessManager: 'Sélectionner un Gestionnaire Commercial',
        selectingTheBestBusinessManagerForYourNeeds: 'Choisir le Meilleur Gestionnaire Commercial selon Vos Besoins'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Votre bot est installé et actif',
        youhaveSuccessfullyConnected: 'Vous avez été connecté avec succès',
        withWhatsApp: 'avec WhatsApp',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'Vous avez été attribué 100 conversations par mois par défaut',
        upgradeYourPlanForMore: 'Mettez à niveau votre plan pour en obtenir plus.',
        createChatFlow: 'Créer un Flux de Discussion',
        upgradePlan: 'Mettre à niveau le Plan'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Scanner le QR ou Envoyer un message pour activer le bot',
        scanTheQrCodeFrom: 'Scanner le code QR depuis',
        yourPhoneWillOpen: 'Votre téléphone ouvrira un message pré-rempli à envoyer à notre numéro Sandbox. Appuyez sur "Envoyer" sur WhatsApp',
        sendMessageFromYourWhatsAppNumber: 'Envoyer un message depuis votre numéro WhatsApp',
        sendUsAWhatsAppMessageOn: 'Envoyez-nous un message WhatsApp sur ',
        bySavingInYourContactsAndMessageShouldStartWith: ' en les enregistrant dans vos contacts et le message doit commencer par ',
        send: 'Envoyer',
        or: 'OU'
      }

    }
  },
  gettingStarted: {
    hello: 'Bonjour,',
    upgrade: 'Améliorer',
    gettingStarted: 'Commencer -',
    yourFacebookBotToken: 'Votre jeton de bot Facebook',
    hasBeenExpired: 'A expiré',
    isExpiringIn: 'Expire dans',
    days: ' jours',
    facebookAuthenticationTokenRemainsFor60Days: 'Le jeton d`authentification Facebook ne reste actif que pendant 60 jours',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Veuillez procéder au renouvellement de votre jeton de bot pour garantir un accès ininterrompu aux services du chatbot.',
    renewYourBotToken: 'Renouveler votre jeton de bot',
    editYourChatFlow: 'Modifiez votre flux de chat',
    buildConversationEngagingBot: 'Construisez des flux de conversation engageants pour vos utilisateurs de bot.',
    takes5minutes: 'prend 5 minutes',
    previewYourLiveChatWidget: 'Aperçu de votre widget de chat en direct',
    discoverHowThe: 'Découvrir comment le',
    liveChatFeatureWorks: ' La fonction de chat en direct fonctionne en utilisant notre terrain de jeu de chat en direct',
    takes2minutes: 'prend 2 minutes',
    takes1minute: 'prend 1 min',
    connected: 'Connecté',
    customizeYourBotApperance: 'Personnalisez l`apparence et les fonctionnalités de vos bot.',
    takes3minutes: 'prend 3 minutes',
    thirdPartyAppsIntegration: 'Intégration des applications tierces',
    integrateYourBot: 'Intégrez votre bot avec d`autres applications / CRM pour créer une automatisation commerciale.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Mettez à niveau votre plan pour recevoir des prospects sur votre e-mail',
    getStartedWithUs: 'Commencez avec nous',
    howToBuildAGoodChatFlow: 'Comment construire un bon chatflow',
    howToUseBotPenguinsInboxAndItsFeatures: 'Comment utiliser la boîte de réception de BotPenguin et ses fonctionnalités',
    botPenguinsPricing: 'Prix ​​de BotPenguin: Explorer les plans et les fonctionnalités',
    openHelpDesk: 'Open Help Desk',
    helpAndSupport: 'Aide et soutien',
    extensiveGuides: 'Des guides étendus pour toutes vos requêtes et flux de travail sont disponibles dans notre',
    resourceCentre: 'Centre de ressources',
    checkoutOurAnswers: 'Vous pouvez consulter nos réponses aux requêtes communes ci-dessous.',
    createANewBot: 'Créer un nouveau bot',
    installYourBot: 'Installez votre bot',
    plansAndPricing: 'Plans et prix',
    previewYourLandingPageBot: ' Aperçu de votre page de destination bot',
    installYourLiveChatWidget: 'Installez votre widget de chat en direct',
    installYourWebsiteBot: 'Installez le bot sur votre appareil',
    integrateBotToLandingPage: 'Intégrez le bot à votre page de destination bot',
    installWidgetToWebsite: 'Installez le widget sur votre site Web',
    installBotToWebsite: 'Installez votre chatbot',
    whatsappAutomation: 'Automatisation WhatsApp',
    facebookAutomation: 'Automatisation Facebook',
    telegramBotSettings: 'Paramètres de bot télégramme',
    liveChatWidgetSettings: 'Paramètres du widget de chat en direct',
    landingPageBotSettings: 'Page de destination Paramètres de bot',
    websiteChatBotSettings: 'Paramètres de chatbot de site Web',
    instagramAutomation: 'Automatisation Instagram',
    diveDeeperIntoBotPenguin: 'Plongez plus profondément dans le botpenguin',
    knowWhereWeAreGoing: 'Sachez où nous allons, les mises à jour récentes et nous aidez à corriger certains bogues.',
    productUpdates: 'Mises à jour du produit',
    requestAFeature: 'Demander une fonctionnalité',
    reportABug: 'Signaler un bug',
    prodcutRoadmap: 'Feuille de route du produit',
    trainYourChatbot: 'Formez votre chatbot IA (base de connaissances)',
    buildEngagingConversation: 'Créez des flux de conversation engageants pour les utilisateurs de votre bot.',
    previewYourAIBot: 'Prévisualisez votre robot IA',
    integrateAIbot: 'Intégrez le bot à votre IA bot',
    aiBotSettings: 'Paramètres du robot IA',
    CustomizeAIBot: 'Personnalisez l\'apparence et les fonctionnalités de votre bot.',
    gptKeyWarning: 'Bienvenue sur notre service ChatGPT API ! Veuillez noter qu\'il y a une limite de 100 messages pour chaque bot. Après avoir atteint la limite de 100 messages, vous devrez utiliser votre propre clé API ChatGPT pour continuer à profiter de notre service. Pour entrer la clé API ChatGPT, rendez-vous dans la configuration du modèle ChatGPT.',
    whatsappBotSettings: 'Paramètres de bot WhatsApp',
    automateCustomerEngagementWithWhatsApp: 'Automatisation de l\'engagement client avec WhatsApp',
    connectToYourWhatsappNumber: 'Se connecter à votre numéro WhatsApp',
    connectYourWhatsappBotWithYourWhatsappNumber: 'Connectez votre bot WhatsApp avec votre numéro WhatsApp',
    facebookBotSettings: 'Paramètres de bot Facebook',
    automateCustomerEngagementWithTelegram: `Automatiser l'engagement client avec Telegram`,
    automateCustomerEngagementWithFacebook: `Automatiser l'engagement client avec Facebook`,
    automateCustomerEngagementWithInstagram: `Automatiser l'engagement client avec Instagram`,
    telegramAutomation: 'Automatisation Telegram',
  },
  install: {
    previewYourLandingPageBot: 'Aperçu de votre page de destination bot',
    preview: 'Aperçu',
    test: 'Test',
    squareSpace: 'Espace-citer',
    installYourChatBot: 'Installez votre chatbot sur le site web, l\'application mobile ou lancez-le en tant que page de destination ou chatbot intégré',
    seeHowToUse: 'Voir comment utiliser',
    help: 'Aider',
    uniqueLinkToLandingPageBot: 'Voici le lien unique vers votre bot de page de destination.',
    shareOnSocialMedia: 'Partagez-le simplement avec vos utilisateurs sur les réseaux sociaux, les pages de contact ou les cartes de visite.',
    or: 'OU',
    copyUrl: 'Copier le lien',
    selectYourWebsiteCms: 'Sélectionnez votre site Web CMS',
    selectCms: 'Sélectionnez le CMS / Builder que vous avez utilisé pour créer votre site Web',
    custom: 'Coutume',
    wordpress: 'Wordpress',
    shopify: 'Faire des achats',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Autres CM',
    howWouldYouLikeToInstall: 'Comment aimeriez-vous l`installer?',
    installingWebsiteBot: 'Choisissez que vous installerez le bot sur votre site Web',
    iWillAddMyself: 'Je vais m`ajouter',
    getChatBotScript: 'Obtenez le code de script chatbot ci-dessous et installez tout celle-ci par vous-même',
    getWordpressPlugin: 'Obtenez la clé API du plugin WordPress',
    getOurOfficialPlugin: 'Obtenez notre plugin officiel',
    fromWordpressPluginStore: ' à partir du magasin de plugin wordpress',
    getPlugin: 'Obtenir le plugin',
    quickGuideToInstall: 'Guide rapide pour installer',
    placeApiKeyBelowIn: 'Collez la clé API ci-dessous dans',
    afterDownloadingAndActivatingPlugin: ' Après avoir téléchargé et activé le plugin',
    copy: 'Copie',
    enterYourWebsite: 'Entrez votre site Web et voyons à quoi ressemble vos chatbots',
    enterAValidUrl: 'Entrez une URL valide',
    verifyYourInstallation: 'Vérifiez si votre installation est réussie ou non?',
    testInstallation: 'Installation de test',
    getChatBotScriptCode: 'Obtenez le code de script chatbot',
    copyThisCodeSnippet: 'Copiez cet extrait de code et insérez-le dans le HTML de chaque page Web où vous souhaitez afficher le chatbot ou coller dans le pied de page avant que la balise ne se ferme pour apparaître sur chaque page.',
    // copy: 'Copie',
    sendCodeToMyDeveloper: 'Envoyer du code à mon développeur',
    sendCodeToMyDevelopmentTeam: 'Envoyer du code à mon équipe de développement',
    sendEmail: 'Envoyer un e-mail',
    enterAValidEmail: 'Entrer un email valide',
    codeHasBeen: 'Le code a été',
    toYourDeveloper: ' à votre développeur',
    resent: 'en vouloir',
    sent: 'envoyé',
    resendEmail: 'Ré-envoyer l`email',
    ask: 'Demander',
    supportTeamToInstall: ' Équipe d`assistance à installer',
    raiseTheInstallationRequestTo: 'Soulever la demande d`installation pour',
    team: ' Équipe',
    helpMeInstallingChatBot: 'Aidez-moi à installer le chatbot',
    yourBotInstallationIsNotActive: 'Votre installation de bot n`est pas active!',
    monthlyMessageLimitExceeded: 'Limite de message mensuelle exodé',
    exceededMonthlyMessageLimitUpgradeNow: 'Vous avez dépassé votre limite de messages mensuelle, mise à niveau maintenant.',
    upgradeNow: 'Mettre à jour maintenant',
    botInstallationNotActivePleaseFix: 'Votre installation de bot n`est pas active, veuillez réparer la même chose.',
    incorrectBotScript: 'Script de bot incorrect',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'Le code d`installation que vous vérifiez ne correspond pas à celui de votre site. Veuillez vous assurer que vous avez installé le code correct pour ce bot.',
    unableToSee: 'Incapable de voir',
    codeOnYourWebsite: ' Code sur votre site Web',
    unableToFindCodePleaseReinstall: 'Nous ne pouvons pas trouver le code sur votre site Web, veuillez réinstaller.',
    reinstallBot: 'Réinstaller le bot',
    yourBotIsInstalledAndActive: 'Votre bot est installé et actif!',
    noPendingAction: 'Aucune action en attente, votre bot est installé et actif',
    youAreAllSet: 'Vous êtes prêt!',
    showMeMyLeads: 'Montre-moi mes pistes',
    connectingToYourWebsiteToVerifyInstallation: 'Connexion à votre site Web pour vérifier l`installation de bot',
    thisMayTakeUpto30Seconds: 'Cela peut prendre jusqu`à 30 secondes',
    hereIsTheuniqueAILink: 'Voici le lien unique vers votre AI Bot',
    installHeading: 'Installez votre chatbot',
    websiteChatbot: 'Chatbot du site web',
    aiChatBot: 'Chatbot IA',
    landingPageBot: 'Chatbot de la page de destination',
    mobileChatBot: 'Chatbot de l\'application mobile',
    embeddedBot: 'Chatbot intégré',
    selectMobileDevice: 'Sélectionnez votre appareil mobile',
    mobileDeviceSubheading: 'Sélectionnez la langue native que vous avez utilisée pour créer votre application mobile',
    ios: 'iOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Autre',
    copyEmbeddedCodeSnippet: 'Copiez ce fragment de code et insérez-le dans le code HTML de chaque page web où vous souhaitez afficher le chatbot.',
    copyMobileCodeSnippet: 'Copiez ce fragment de code dans le code de votre application mobile là où vous souhaitez afficher le chatbot.',
    placeholders: {
      exampleSite: 'https://www.yoursite.com',
      enterDeveloperEmail: 'Entrez le courrier électronique du développeur',
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Envoyez des instructions à votre développeur',
      notSureHowToDoIt: 'Vous ne savez pas comment le faire? Vos développeurs peuvent le faire dans un tour.',
      developersEmail: 'E-mail du développeur',
      emailIsRequired: 'Un e-mail est requis',
      subject: 'Sujet',
      message: 'Message',
      send: 'Envoyer',
      placeholders: {
        enterYourWorkEmail: 'Entrez votre e-mail de travail',
        createBotUsingBotPenguin: 'Excité! Créer un bot à l`aide de BotPenguin',
      }
    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'Voici le lien unique vers votre bot de page de destination.',
      shareWithUsersOnSocialMedia: 'Partagez-le simplement avec vos utilisateurs sur les réseaux sociaux, les pages de contact ou les cartes de visite.',
      previewMyLandingPageBot: 'Aperçu ma page de destination bot',
      or: 'OU',
      copyUrl: 'Copier le lien',
      howYouLikeToAddBot: 'Choisissez comment vous souhaitez ajouter votre code de robot à votre site Web',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Installez le bot sur votre site Web codé ou WordPress personnalisé en copier un code unique.',
      name: 'Nom',
      actions: 'Actions',
      javascript: 'Javascrip',
      install: 'Installer',
      onYourCustomCodedWebsite: ' Sur votre site Web codé personnalisé',
      copyAndPaste: 'Copier et coller',
      copyThisCodeSnippet: 'Copiez cet extrait de code et insérez-le dans le HTML de chaque page Web où vous souhaitez afficher le widget. Pour plus de détails',
      seeGuide: 'voir guide',
      getCode: 'Obtenir le code',
      howToInstall: 'Comment installer?',
      copyCode: 'Copier de code',
      wordpress: 'Wordpress',
      // install: 'Installer',
      onYourWordPressWebiste: ' Sur votre site Web WordPress',
      preview: 'Aperçu',
      test: 'Test',
    }
  },
  liveChatPreview: {
    liveChatPreview: 'Aperçu du chat en direct',
    agentWindow: 'Fenêtre',
    person: 'personne',
    inOrderToTestTypeHere: 'Afin de tester le type ici',
    agentWindowCannotBeCustomized: 'Remarque: la fenêtre d`agent ne peut pas être personnalisée',
    customerWindow: 'Fenêtre du client',
    clickHereToChat: 'Clique ici pour discuter',
    customerWillChatHere: 'Le client discutera ici',
    chat: 'Discuter',
    by: 'par',
    changeYourBotLookFromLeftPanel: 'Changez votre look bot du panneau de gauche',
    placeholders: {
      typeYourMessage: 'Tapez votre message ...',
      typeYourAnswer: 'Tapez votre réponse',
    }
  },
  previews: {
    previewYourLiveChatWidget: 'Aperçu de votre widget de chat en direct',
    install: 'Installer',
    seeHowToUse: 'Voir comment utiliser',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Cliquez pour installer votre bot sur la plate-forme sélectionnée',
    }
  },
  trainYourBot: {
    trainYourBot: 'Entraînez votre Chatbot',
    knowledgeBase: 'Base de connaissances',
    faqs: 'FAQ',
    history: 'Histoire',
    faqHeading: 'Questions fréquemment posées',
    faqSubHeading: 'Réponses à vos questions sur la formation du Chatbot',
    knowledgeBaseHeading: 'Entraînez votre Chatbot',
    knowledgeBaseSubheading: 'Entraînez votre Chatbot pour des conversations améliorées',
    websiteLink: 'Lien du site web',
    uploadFile: 'Téléchargez votre fichier',
    selectFile: 'Sélectionner un fichier',
    submit: 'Soumettre',
    searchHere: 'Rechercher ici',
    question: 'Question',
    answer: 'Réponse',
    questionPlaceholder: 'Écrivez votre question ici',
    answerPlaceholder: 'Écrivez votre réponse ici',
    save: 'Enregistrer',
    discard: 'Annuler',
    addFaq: 'Ajouter des FAQ',
    createdAt: 'Créé le',
    updatedAt: 'Dernière mise à jour le',
    actions: 'Actions',
    noFaqFound: 'Vous n\'avez pas encore créé de FAQ',
    noFaqFoundMessage: 'Toutes vos FAQ apparaîtront ici',
    historyTitle: 'Historique de la base de connaissances',
    historySubtitle: 'Réponses à vos questions sur la formation du Chatbot',
    fileName: 'Nom du fichier',
    status: 'Statut',
    noHistoryTitle: 'Vous n\'avez encore créé aucun modèle entraîné',
    noHistoryDescription: 'Tous vos modèles entraînés apparaîtront ici',
    fileUpload: 'Téléchargement de fichier',
    files: 'Fichiers',
    fileSubHeading: 'Ajoutez tous les fichiers sur lesquels vous souhaitez entraîner votre bot.',
    noFileFoundHeading: 'Vous n\'avez encore créé aucun fichier',
    noFileFoundSubheading: 'Tous vos fichiers apparaîtront ici',
    uploadYourFiles: 'Téléchargez vos fichiers',
    upload: 'Télécharger',
    fileShouldBe: 'Le fichier doit être au format PDF, DOC, DOCX, PPTX, TXT.',
    uploadStatus: 'État de téléchargement',
    uploadStatusSubHeading: 'État de téléchargement des fichiers pour la formation du bot',
    addFile: 'Ajouter un fichier',
    otherConfiguration: 'Paramètres IA',
    deleteFiles: 'Cliquez pour supprimer les fichiers',
    websiteURL: 'URL du site web',
    websiteUrlSubheading: 'Ajoutez toutes les URLs du site web sur lesquelles vous souhaitez entraîner votre bot.',
    enterWebsiteUrl: 'Entrer l\'URL',
    addWebsite: 'Ajouter un site web',
    tableWebsiteUrl: 'URL du site web',
    trainingStatus: 'Statut de l\'entraînement',
    lastTrained: 'Dernier entraînement',
    noWebsitesFound: 'Vous n\'avez pas encore créé de site web',
    noWebsitesFoundMessage: 'Tous vos sites web apparaîtront ici',
    loadMore: 'Charger plus',
    botBehaviour: 'Configurer le comportement du bot',
    botBehaviourSubHeading: 'Configurer la personnalité du bot, rédiger les données des clients, gérer le flux et le modèle ChatGPT',
    botPersonality: 'Configurer la personnalité du bot',
    botPersonalitySubHeading: 'Gérer la façon dont votre bot devrait répondre en termes de personnalité',
    selectAnswerLength: 'Sélectionner la longueur de la réponse',
    answerLength: 'Longueur de la réponse',
    answerAs: 'Réponse en tant que',
    tone: 'Ton',
    language: 'Langue',
    answerFormatting: 'Formatage de la réponse',
    customInstructions: 'Invite personnalisée',
    customerInstructionPlaceholder: 'Soyez poli et amical. N\'utilisez pas d\'argot. N\'utilisez pas d\'emojis.',
    redactCustomerData: 'Collecter les données client',
    redactDataSubheading: 'Configurez les informations client que vous souhaitez collecter',
    collectName: 'Collecter le nom',
    configureQuestion: 'Configurer la question',
    maxCharacterLimit: 'Limite de caractères maximale dans le nom fourni',
    collectEmail: 'Collecter l\'e-mail',
    excludeEmail: 'Exclure les e-mails avec domaine',
    collectPhone: 'Collecter le téléphone',
    manageUnusualMessages: 'Gérer les scénarios de chat inhabituels',
    unusualMessagesSubheading: 'Gérer les scénarios rares ou sans réponse pour le bot',
    startConversation: 'Message pour commencer la conversation',
    configureMessage: 'Message lorsque le bot ne peut pas répondre',
    profanityMessage: 'Message pour répondre à l\'utilisation d\'un langage inapproprié',
    chatGptModel: 'Configuration du modèle ChatGPT',
    chatGptModelSubheading: 'Gérer quel modèle ChatGPT utiliser et éviter les abus',
    chatGptApi: 'Clé API ChatGPT',
    enableDisableRedactData: 'Collecter les données client',
    triggerToCollectData: 'Collecter les coordonnées de contact après une série de questions.',
    train: 'Entraîner',
    website: 'site Web',
    includedSourcesForResponse: 'Sources incluses pour la réponse',
    visibility: 'Visibilité',
    analytics: 'Analytique',
    yourConversation: 'Vos conversations',
    reviewAndSelectConversation: 'Examinez et sélectionnez les conversations à exporter pour la formation du chatbot',
    exportAiAnalytics: 'Exporter l\'analyse',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    userQuestion: 'Question de l\'utilisateur',
    botsReply: 'Réponse du bot',
    dateCreated: 'Date de création',
    noAnalyticsFound: 'Vous n\'avez pas encore créé d\'analyse',
    noAnalyticsFoundMessage: 'Tous vos messages d\'analyse apparaîtront ici',
    exportData: 'Exporter les données',
    exportCompleteAnalyticsData: 'Exporter les données d\'analyse complètes',
    export: 'Exporter',
    integrateWithChatGpt: 'Intégrer avec ChatGPT',
    integrated: 'Integrate',
    configure: 'Configurer',
    appToolTip: {
      deleteWebsite: 'Supprimer le site Web',
      knowledgeBase: 'Entraînez votre bot avec une base de connaissances',
      faqs: 'Entraînez votre bot avec des questions fréquentes',
      knowledgeHistory: 'Historique de la base de connaissances',
      uploadFaqs: 'Importer des questions fréquentes',
      addFaqs: 'Cliquez pour ajouter des questions fréquentes',
      deleteFaqs: 'Supprimer des questions fréquentes',
      deleteHistory: 'Supprimer l\'historique',
      uploadFile: 'Le fichier téléchargé doit être au format PDF, DOCX, DOC ou CSV et sa taille doit être inférieure à 1 Mo.',
      trainWebsite: 'Entraînez votre bot en utilisant un site web.',
      fileUpload: 'Formez votre bot en utilisant des fichiers tels que des PDF, des TXT, des PPTX, des DOC et des DOCX.',
      configureBotBehaviour: 'Personnalisez la personnalité du bot, gérez le flux et ajustez le modèle ChatGPT.',
      clickToAddWebsite: 'Cliquez pour ajouter un site web',
      clickToDeleteWebsites: 'Cliquez pour supprimer des sites web',
      syncWebsite: 'Synchroniser le site web',
      copyUrl: `Copier l'URL`,
      weWillBeFetchingAllTheWebpages: 'Nous allons récupérer toutes les pages Web et former le modèle ChatGPT sur celles-ci.'
    },
    modal: {
      deleteWebsiteUrl: 'Supprimer l\'URL du site Web',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Êtes-vous sûr de vouloir supprimer cette URL de site Web ?'
    }
  },
  configuration: {
    websiteChatbotSetting: 'Paramètres du chatbot du site web',
    preview: 'Aperçu',
    test: 'Test',
    install: 'Installer',
    designHeader: 'Conception',
    generalHeader: 'Général',
    alertsHeader: 'Alertes',
    triggerHeader: 'Déclencher',
    userAccessHeader: 'Accès utilisateur',
    chatWindowHeader: 'Fenêtre de discussion',
    otherConfiguration: 'Paramètres IA',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Cliquez pour installer votre bot sur la plate-forme sélectionnée',
      customizeAppearanceOfBot: 'Personnalisez le positionnement et l`apparence de votre interface bot.',
      configureBotsSettingsWhenAgentUnavailable: 'Configurez les paramètres de Bot lorsque votre agent n`est pas disponible.',
      configureHowBotWillAlertYou: 'Configurez la façon dont votre bot vous alertera de nouveaux prospects.',
      makeSureYourBotsPopUpProperlyTimed: 'Assurez-vous que vos pop-ups de bot sont parfaitement chronométrés.',
      customizeWhereYouWantYourBotToAppear: 'Personnalisez où vous voulez que votre bot apparaisse et où non.',
      enableChatOptionsForVisitorsToTransferChat: 'Activez les options de chat pour que vos visiteurs transfèrent des chats, le chat en direct et la rafraîchissement du chat.',
      configureBotBehaviour: 'Personnalisez la personnalité du bot, gérez le flux et ajustez le modèle ChatGPT.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Configurez les paramètres d`alerte de votre bot',
      specifyHowYourBotWillNotify: 'Spécifiez comment votre bot vous informera des nouveaux prospects.',
      subject: 'Sujet',
      emailNotification: 'Notification par e-mail',
      emailAddresses: 'Adresses mail',
      leadRevisitNotification: 'Notifications de révision du lead',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'Vous vous êtes inscrit avec succès avec des notifications de bureau',
      youHaveBlockedYourNotificationsOnBrowser: 'Vous avez bloqué les notifications sur les paramètres de votre navigateur.',
      discard: 'Jeter',
      saveChanges: 'Sauvegarder les modifications',
      seeHowToUse: 'Voir comment utiliser',
      placeholders: {
        enterSubjectForYourEmail: 'Entrez le sujet pour votre e-mail',
        typeYourEmailAddressAndHitEnter: 'Tapez votre adresse e-mail et appuyez sur Entrée',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Personnalisez le sujet de vos notifications par e-mail à partir du bot',
        TOOLTIP_EMAIL_NOTIFICATION: 'Activer / désactiver la notification du bot sur votre e-mail pour de nouvelles prospects',
        TOOLTIP_EMAIL_ADDRESS: 'Soumettez l`adresse e-mail du compte où vous souhaitez recevoir des notifications par e-mail. Vous pouvez ajouter plusieurs adresses e-mail en entrant une et en appuyant sur Entrée pour en ajouter une autre.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Activer / désactiver la notification de Bot sur votre e-mail si un utilisateur précédemment capturé comme un lead interagit à nouveau avec le bot.',
      }
    },
    broadcasts: {
      createBroadcastHeading: 'Créer une diffusion',
      youCanCreateMultipleBroadcasts: 'Vous pouvez créer plusieurs émissions',
      broadcastName: 'Nom de diffusion',
      broadcastNameIsRequired: 'Le nom de diffusion est requis',
      continue: 'Continuer',
      manageBroadcasts: 'Gérer les émissions',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'Vous pouvez créer plusieurs diffusions avec plusieurs modèles planifiés pour différents Groupes de clientèle.',
      createNewBroadcast: 'Créer une nouvelle émission',
      name: 'Nom',
      segment: 'Groupe',
      template: 'Modèle',
      contacts: 'Contacts',
      send: 'Envoyer',
      delivered: 'Livré',
      read: 'Lire',
      failed: 'Échoué',
      broadcastAt: 'Diffuser',
      status: 'Statut',
      actions: 'Actions',
      nA: 'N / A',
      seeHowToUse: 'Voir comment utiliser',
      deleteBroadcast: 'Supprimer la diffusion',
      wantToDeleteThisBroadcast: 'Êtes-vous sûr de vouloir supprimer cette diffusion?',
      placeholders: {
        searchHere: 'Cherche ici',
        enterBroadcastName: 'Entrez le nom de la diffusion',
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Supprimer plusieurs émissions',
        broadcastAnalytics: 'Analyse diffusée',
        deleteBroadcast: 'Supprimer la diffusion',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Instructions de diffusion',
        broadcastStatus: 'Statut de diffusion',
        youCanFindDetailsOfEachContactBelow: 'Vous pouvez trouver les détails de chaque contact ci-dessous et exporter ceux qui peuvent avoir des problèmes.',
        filterApplied: 'Filtre appliqué',
        placeholders: {
          searchByPhoneNumber: 'Recherche par numéro de téléphone',
          searchByName: 'Rechercher le filtre par nom',
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Filtre l`état basé sur les données',
          exportData: 'Exporter des données',
        },
        modal: {
          exportData: 'Exporter des données',
          exportCompleteBroadcastData: 'Exporter des données de diffusion complètes',
          export: 'Exporter',
        }
      },
      createBroadcast: {
        broadcastName: 'Nom de diffusion',
        broadcastNameIsRequired: 'Le nom de diffusion est requis',
        template: 'Modèle',
        segments: 'Groupes',
        whenToSend: 'Quand envoyer',
        now: 'MAINTENANT',
        later: 'PLUS TARD',
        selectBroadcastUsing: 'Sélectionner la diffusion en utilisant',
        usingCsv: 'Utilisation de CSV',
        usingSegment: 'Utilisation du Groupe',
        allContacts: 'Tous les contacts',
        selectDate: 'Sélectionner une date',
        selectTimeSlot: 'Sélectionnez le plage horaire',
        assignVariablesToTemplates: 'Attribuer des variables aux modèles',
        addHeaderMedia: 'Ajouter des supports d`en-tête',
        selectFile: 'Choisir le dossier',
        cancel: 'Annuler',
        createBroadcast: 'Créer une diffusion',
        broadcastUsing: 'Diffusion en utilisant',
        selectCSVFile: 'Sélectionnez le fichier CSV',
        importContacts: 'Contacts d`importation',
        beforeYouStartUpload: 'Avant de démarrer le téléchargement, assurez-vous',
        point1: '1. Téléchargez l`exemple de fichier CSV.',
        point2: '2. Commencez à saisir vos données à partir de la 2e ligne.',
        point3: '3. Dans chaque ligne, il y aura des données pour un contact',
        point4: '4. Assurez-vous que tous les numéros de téléphone sont uniques.',
        point5: '5. La limite de téléchargement de la taille du fichier maximum est de 1 Mo',
        // selectFile: 'Choisir le dossier',
        clickHere: 'Cliquez ici',
        toDownloadSampleFile: 'Pour télécharger un exemple de fichier',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          selectTemplate: 'Sélectionner le modèle',
          searchTemplateByName: 'Rechercher le modèle par nom',
          selectSegments: 'Sélectionner des Groupes',
          selectTimeSlot: 'Sélectionnez le plage horaire',
          selectValueForThisVariable: 'Sélectionnez la valeur de cette variable',
          searchHeaderByVariableName: 'Variable d`en-tête de recherche par nom',
          variableFallbackValue: 'Valeur de secours variable',
          searchBodyVariableByName: 'Recherchez la variable corporelle par nom',
          searchSegmentByName: 'Rechercher le Groupe par nom',
          selectBroadcastUsing: 'Sélectionner la diffusion en utilisant',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Sélectionnez la date de début',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Importer des contacts pour la diffusion de WhatsApp',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'Numéro WhatsApp',
        errorCode: 'Code d`erreur',
        errorMessage: 'Message d`erreur',
        nA: 'N / A',
      }
    },
    design: {
      customizeYourLandingPage: 'Personnalisez votre page de destination',
      customizeYourConversationalBot: 'Personnalisez votre bot convivial',
      customizeYourBot: 'Personnalisez votre bot',
      createHeadingTextAndTheme: 'Créez du texte et du thème qui reflète la personnalité de votre marque',
      content: 'Contenu',
      theme: 'Thème',
      layout: 'Mise en page',
      landingPageName: 'Nom de page de destination',
      botName: 'Nom de bot',
      charactersRemaining: ' caractères restants',
      landingPageHeader: 'En-tête de la page de destination',
      landingPageDescription: 'Description de la page de destination',
      hideContent: 'Cacher le contenu',
      profilePic: 'Photo de profil',
      selectFile: 'Choisir le dossier',
      socialMediaLinks: 'Liens de médias sociaux',
      initialMessage: 'Premier message',
      selectType: 'Sélectionner le genre',
      url: 'URL',
      delete: 'Supprimer',
      editLink: 'Modifier le lien',
      addLink: 'Ajouter un lien',
      callToAction: 'Appel à l`action',
      liveChatWelcomeMessage: 'Message de bienvenue du chat en direct',
      liveChatTriggerMessage: 'Message de déclenchement de chat en direct',
      autoHideCallToAction: 'Auto Masquer l`appel à l`action',
      maxDelayShouldBe300Seconds: 'Le retard maximum doit être de 300 secondes',
      landingPageThemeColor: 'Couleur du thème de la page d`atterrissage',
      themeColor: 'Couleur du thème',
      landingPageBackground: 'Contexte de la page de destination',
      chatBackgroundColor: 'Couleur d`arrière-plan de chat',
      color: 'Couleur',
      gradient: 'Pente',
      image: 'Image',
      video: 'Vidéo',
      gradientStart: 'Début du gradient',
      gradientEnd: 'Fin de dégradé',
      // selectFile: 'Choisir le dossier',
      positionOnWeb: 'Position sur le Web',
      positionOnMobile: 'Position sur mobile',
      windowSize: 'La taille de la fenêtre',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'Xl',
      xxl: 'Xxl',
      custom: 'Coutume',
      height: 'Hauteur',
      minimum300HeightIsRequired: 'Une hauteur minimum de 300px est requise',
      heightCannotExceed720: 'La hauteur ne peut pas dépasser 720px',
      width: 'Largeur',
      px: 'px',
      minimum200WidthIsRequired: 'Une largeur minimum de 200px est requise',
      widthCannotExceed1050: 'La largeur ne peut pas dépasser 1050px',
      discard: 'Jeter',
      saveChanges: 'Sauvegarder les modifications',
      landingPageBot: 'Page de destination bot',
      chatBot: 'Chatbot',
      preview: ' Aperçu',
      test: 'Test',
      botIcon: 'Icône de bot',
      websiteBot: 'Chatbot du site web',
      selectFont: 'Sélectionner la police',
      fontLibrary: 'Bibliothèque de polices',
      uploadFont: 'Télécharger la police',
      selectFontForBot: 'Sélectionner la police pour le bot',
      uploadedFont: 'Police téléchargée',
      flowTriggerDetail: 'Déclencheur pour collecter les coordonnées de contact',
      placeholders: {
        enterName: 'Entrez le nom',
        enterHeaderText: 'Entrez le texte de l`en-tête',
        enterDescription: 'Entrez description',
        enterUrl: 'Entrer l`URL',
        howCanIHelpYou: 'Comment puis-je t`aider?',
        enterYourWelcomeMessageHere: 'Entrez votre message de bienvenue ici',
        enterYourLiveChatMessageHere: 'Entrez votre message de chat en direct ici',
        enterTimeInSeconds: 'Entrez du temps en quelques secondes',
        enterHeight: 'Entrer la hauteur',
        enterWidth: 'Entrez la largeur',
        flowInitialMessage: 'Saisir la question initiale',
      },
      appTooltip: {
        standardLayout: 'Disposition standard',
        rightLayout: 'Disposition droite',
        mergedLayout: 'Disposition fusionnée',
        enterNameOfYourBot: 'Entrez le nom de votre bot',
        enterNameOfYourLandingPageBot: 'Entrez le nom de votre page de destination bot',
        headingTextThatAppearOnLandingPageBot: 'Entrez le texte d`en-tête que vous souhaitez apparaître sur votre page de destination bot',
        descriptionTextThatAppearOnLandingPageBot: 'Entrez le texte de description que vous souhaitez apparaître sur votre bot de page de destination.',
        addLinksAndSocialMediaIcons: 'Ajouter des liens et des icônes de médias sociaux',
        initialFlowMessage: 'Entrez le premier message que le bot enverra pour engager la conversation avec le client',
        anInvitingCatchPhrase: 'Un slogan invitant qui fera parler de l`utilisateur à Bot',
        setAWelcomeMessageForYourVisitors: 'Définissez un message de bienvenue pour vos visiteurs. Ce sera le premier message de votre conversation.',
        setAMessageToInitiateLiveChat: 'Définissez un message pour lancer le chat en direct à partir de la fin du visiteur. Vous recevrez une notification chaque fois que ce message est déclenché dans l`une de vos conversations',
        autoHideCallToAction: 'Masquer automatiquement le texte de «Appel à l`action» après l`heure spécifiée',
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Vous pouvez choisir de masquer le logo et les textes pour afficher uniquement la fenêtre de chat à l`écran',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Téléchargez un logo pour la page du chatbot.',
        TOOLTIP_CHAT_THEME_COLOR: 'Choisissez la couleur de la fenêtre de chat de vos bots',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Choisissez la couleur de l`arrière-plan de la fenêtre de chat des bots',
        TOOLTIP_WEB_1ST_POSITION: 'Positionnez votre bot en bas à gauche du bureau des utilisateurs de bot',
        TOOLTIP_WEB_2ND_POSITION: 'Positionnez votre bot dans le coin moyen droit du bureau des utilisateurs de bot',
        TOOLTIP_WEB_3RD_POSITION: 'Positionnez votre bot dans le coin inférieur droit du bureau des utilisateurs de bot',
        TOOLTIP_MOBILE_1ST_POSITION: 'Positionnez votre bot dans le coin inférieur gauche des utilisateurs de bot mobile',
        TOOLTIP_MOBILE_2ND_POSITION: 'Positionnez votre bot dans le coin moyen droit des utilisateurs de bot mobiles',
        TOOLTIP_MOBILE_3RD_POSITION: 'Positionnez votre bot dans le coin inférieur droit des utilisateurs de bot mobiles',
        TOOLTIP_WINDOW_HEIGHT: 'Resize Bots Chat Window',
        TOOLTIP_BOT_ICON: 'Sélectionnez une icône ou téléchargez un logo / image pour votre bot',
        flowTriggerMessage: 'Entrez le nombre de messages à demander avant de collecter les coordonnées de contact telles que le nom, l\'e-mail et le téléphone',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Créer une campagne d`égouttement',
      youCanCreateMultipleCampaigns: 'Vous pouvez créer plusieurs campagnes',
      dripCampaignName: 'Nom de la campagne goutte à goutte',
      dripCampaignNameIsReuired: 'Le nom de campagne goutte à goutte est requis',
      continue: 'Continuer',
      manageDripCampaign: 'Gérer la campagne d`égouttement',
      youCanCreateMultipleCampaignsWith: 'Vous pouvez créer plusieurs campagnes DRIP avec des messages automatisés planifiés pour déclencher des réponses à partir de Groupes d`utilisateurs spécifiques.',
      createNewDripCampaign: 'Créer une nouvelle campagne d`égouttement',
      campaignName: 'Nom de la campagne',
      appliedOn: 'Appliqué sur',
      segment: 'Groupe',
      template: 'Modèle',
      startDate: 'Date de début',
      action: 'Action',
      nA: 'N / A',
      seeHowToUse: 'Voir comment utiliser',
      deleteDripCampaign: 'Supprimer les campagnes DRIP',
      wantToDeleteThisDripCampaign: 'Êtes-vous sûr de vouloir supprimer ces campagnes goutte à goutte?',
      placeholders: {
        enterDripCampaignName: 'Entrez le nom de la campagne goutte à goutte',
        searchByName: 'Recherche par nom',
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Supprimer plusieurs campagnes DRIP',
        deleteDripCampaign: 'Supprimer la campagne d`égouttement',
      },
      createDripCampaign: {
        dripCampaignName: 'Nom de la campagne goutte à goutte',
        appliedOn: 'Appliqué sur',
        segments: 'Groupes',
        selectDate: 'Sélectionner une date',
        scheduleYourTemplate: 'Planifiez vos modèles',
        schedule: 'Calendrier',
        template: 'Modèle',
        sendAfter: 'Envoyer après',
        assignVariablesToTemplate: 'Attribuer des variables aux modèles',
        addHeaderMedia: 'Ajouter des supports d`en-tête',
        selectFile: 'Choisir le dossier',
        createDripCampaign: 'Créer une campagne d`égouttement',
        newSubscriber: 'Nouvel abonné',
        minutes: 'Minutes',
        hours: 'Heures',
        days: 'Jours',
        placeholders: {
          enterDripName: 'Entrez le nom de goutte',
          selectAppliedOn: 'Sélectionner appliqué sur',
          selectSegments: 'Sélectionner des Groupes',
          searchByName: 'Recherche par nom',
          selectTemplate: 'Sélectionner le modèle',
          searchTemplateByName: 'Rechercher le modèle par nom',
          enterSendAfter: 'Entrez envoyer après',
          selectValueForThisVariable: 'Sélectionnez la valeur de cette variable',
          searchHeaderVariableByName: 'Variable d`en-tête de recherche par nom',
          searchBodyVariableByName: 'Recherchez la variable corporelle par nom',
        },
        dateTimePicker: {
          selectStartDate: 'Sélectionnez la date de début',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: 'Configurez les paramètres généraux de votre page de destination',
      configureBotsGeneralSettings: 'Configurez les paramètres généraux de votre bot',
      selectTheTimeZone: 'Sélectionnez la zone temporelle, la langue et une petite note à vos clients lorsque',
      isNotAvailable: ' n`est pas disponible',
      landingPagesBot: 'pages de destination bot',
      bot: 'bot',
      waitingMessage: 'Message d`attente',
      landingPageBotStatus: 'Page de destination Statut de bot',
      chatBotStatus: 'Statut de chatbot',
      unavailabilityMessage: 'Message d`adaptation',
      sendIncompleteResponses: 'Envoyer des réponses incomplètes',
      language: 'Langue',
      invalidateCache: 'Invalider le cache',
      timezone: 'Fuseau horaire',
      chatWindowMessageHistory: 'Historique des messages de la fenêtre de chat',
      session: 'session',
      forever: 'pour toujours',
      english: 'Anglais',
      spanish: 'Espagnol',
      french: 'Français',
      portuguese: 'Portugais',
      german: 'Allemand',
      italian: 'italien',
      swedish: 'suédois',
      arabic: 'arabe',
      malay: 'malais',
      russian: 'russe',
      discard: 'Jeter',
      saveChanges: 'Sauvegarder les modifications',
      seeHowToUse: 'Voir comment utiliser',
      placeholders: {
        enterYourWaitingMessageHere: 'Entrez votre message d`attente ici',
        // selectLanguage: ',',
        searchTimeZone: 'Recherchez le fuseau horaire',
        // selectChatRetentionPolicy: ',',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Personnalisez le message comme vous souhaitez qu`il apparaisse sur la fenêtre de chat immédiatement après qu`un utilisateur de BOT envoie une demande de chat en direct',
        TOOLTIP_CHATBOT_STATUS: 'Activer / désactiver le bot',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Personnalisez le message comme vous souhaitez qu`il apparaisse sur la fenêtre de chat quand aucun de vos agents disponible pour le chat en direct avec l`utilisateur de BOT',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Envoyez-vous des notifications par e-mail même lorsque l`utilisateur de BOT n`a pas terminé la conversation.',
        TOOLTIP_LANGUAGE: 'Sélectionnez la langue du bot',
        TOOLTIP_INVALIDATE_CACHE: 'Cliquez ici pour effacer le cache au fur et à mesure que vous modifiez le flux de chat bot ou les paramètres car les modifications ne se reflètent qu`après 15 minutes',
        TOOLTIP_TIME_ZONE: 'Sélectionnez la zone temporelle de votre bot',
        TOOLTIP_CHAT_RETENTION: 'Sélectionnez si vous souhaitez conserver les chats de l`utilisateur BOT pour toujours sur la fenêtre de chat ou uniquement pour une session de navigateur.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Personnalisez votre fenêtre de chat',
      controlAndCustomizeTheChatWindow: 'Contrôlez et personnalisez la fenêtre de chat avec laquelle vos clients interagiront.',
      allowVisitorsToTransferChatToWhatsapp: 'Permettez aux visiteurs de transférer la discussion sur WhatsApp',
      allowVisitorsToRefreshChat: 'Permettre aux visiteurs de rafraîchir le chat',
      displayNameForTransferringChatToWhatsapp: 'Afficher le nom pour transférer une conversation sur WhatsApp',
      displayNameForTransferringToLiveChat: 'Nom d`affichage pour le transfert vers le chat en direct / agent',
      displayNameForTransferringAChatToBot: 'Nom d`affichage pour transférer une conversation sur bot',
      discard: 'Jeter',
      saveChanges: 'Sauvegarder les modifications',
      allowVisitorsToTransferChatToBot: 'Permettez aux visiteurs de transférer la discussion sur bot.',
      allowVisitorsToRequestForLiveChat: 'Permettez aux visiteurs de demander un chat en direct.',
      indicativeTextForCustomersReply: 'Texte indicatif pour la réponse du client',
      displayNameForRestartingChatBot: 'Nom d`affichage pour redémarrer la conversation du chatbot',
      seeHowToUse: 'Voir comment utiliser',
      placeholders: {
        enterButtonNameForWhatsapp: 'Entrez le nom du bouton pour le transfert vers WhatsApp',
        enterButtonNameForLiveChat: 'Entrez le nom du bouton pour le transfert vers le chat ou l`agent en direct',
        enterButtonNameForBot: 'Entrez le nom du bouton pour le transfert vers le bot',
        enterTextDisplayInEmptyReplyArea: 'Entrez le texte affiché dans la zone de réponse vide',
        enterButtonForRestartingConversation: 'Entrez le nom du bouton pour redémarrer la conversation',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Autoriser les visiteurs du site Web à transférer le chat vers WhatsApp no. de l`agent respectif auquel ils sont connectés. La fonction de chat en direct doit être activée pour que cette fonctionnalité fonctionne.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Permettez aux utilisateurs de bot de rafraîchir le chat afin qu`ils puissent recommencer la conversation.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Permettez aux utilisateurs de bot de transférer le chat sur Bot à tout moment entre la conversation avec l`agent de chat en direct.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Permettez aux utilisateurs de BOT de demander une conversation en direct avec l`agent quand ils le souhaitent.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Si vous avez permis à l`utilisateur de transférer sur WhatsApp, remplissez comment vous souhaitez afficher cette option',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Si vous avez permis à l`utilisateur de transférer au chat en direct, remplissez comment vous souhaitez afficher cette option',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Si vous avez permis à l`utilisateur de transférer sur WhatsApp, remplissez comment vous souhaitez transférer à Bot',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Remplissez comment vous souhaitez afficher l`espace de réponse aux clients',
        ifYouHaveEnabledUserToRestartChatBot: 'Si vous avez permis à l`utilisateur de redémarrer la conversation de chatbot, remplissez comment vous souhaitez afficher cette option',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Flux de discussion',
      dripCampaignHeader: 'Campagne goutte à goutte',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Diffuser',
      ordersHeader: 'Ordres',
      manageHeader: 'Gérer',
      inputHeader: 'Saisir',
      apiKeyHeader: 'clé API',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Créez un parcours d`intégration du client à l`aide d`une campagne goutte à goutte de flux de discussion planifiés.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personnalisez les modèles de flux de chat et créez plusieurs flux de chat pour différents' +
          ' publics cibles.',
        TOOLTIP_WIDGET_TITLE: 'Créez des widgets (interfaces de chat) et intégrez-les dans des pages Web promotionnelles pour diriger les visiteurs du bot',
        TOOLTIP_BROADCAST_TITLE: 'Jetez des messages ou coulez vers vos contacts en un seul clic au moment pré-téléducteur',
        TOOLTIP_ORDERS_TITLE: 'Liste des commandes passées par le client',
        TOOLTIP_MANAGE_TITLE: 'Gestion des fonctionnalités de bot pour les contacts / leads et les mots clés',
        TOOLTIP_INPUT_TITLE: 'Créez des modèles WhatsApp, des images et du texte comme matériel de réponse pour les contacts / leads',
      },
      apiWrapper: {
        whatsappApiCredentials: 'Contaliens API WhatsApp',
        generateYourWhatsappApiKey: 'Générez votre clé API WhatsApp pour intégrer à vos applications développées personnalisées.',
        generateApiKey: 'Générer une clé API',
        webhookUrl: 'URL de webhook',
        addHeader: 'Ajouter',
        test: 'Test',
        webhookNotSet: 'Webhook pas réglé',
        webhookHeaders: 'Tonnes webhook',
        setWebhookUrl: 'Définir l`URL de la webhook',
        placeholders: {
          yourApiKey: 'Votre clé API',
          enterWebhookUrl: 'Entrez l`URL du webhook',
          enterHeaderKey: 'Entrez la clé d`en-tête',
          enterValue: 'Entrer la valeur',
        }
      },
      broadcast: {
        createBroadcast: 'Créer une diffusion',
        createMultipleBroadcasts: 'Vous pouvez créer plusieurs diffusions avec plusieurs flux / modèles planifiés pour différents Groupes de clientèle.',
        broadcastName: 'Nom de diffusion',
        nameIsRequired: 'Le nom est requis',
        selectType: 'Sélectionner le genre',
        selectYourFlowType: 'Sélectionnez votre type de flux',
        segment: 'Groupe',
        selectYourSegmentType: 'Sélectionnez votre type de Groupe',
        whenToSend: 'Quand envoyer',
        selectDate: 'Sélectionner une date',
        messageTag: 'Étiquette de message',
        selectMessageTag: 'Sélectionner la balise de message',
        contentType: 'Type de contenu',
        nonPromotional: 'Non promotionnel (étiquettes de message appliquées)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Tag chaque message qui précède avant toute interaction ou après des étapes de retard dépassent 24 heures avec une balise appropriée. Un message non marqué n`atteindra pas l`abonné qui a interagi avec le bot il y a plus de 24 heures',
        promotional: 'Promotionnel (étiquettes de message ignorées)',
        theseBroadcasts: 'Ces émissions peuvent contenir des promotions, mais le public cible est limité aux abonnés qui ont interagi avec votre bot au cours des dernières 24 heures.',
        createNewBroadcast: 'Créer une nouvelle émission',
        sentMessages: 'Messages envoyés',
        deliveredMessages: 'Messages livrés',
        readMessages: 'Lire des messages',
        failedMessages: 'Messages échoués',
        // createNewBroadcast: 'Créer une nouvelle émission',
        type: 'Taper',
        template: 'Modèle',
        sent: 'Envoyé',
        delivered: 'Livré',
        read: 'Lire',
        failed: 'Échoué',
        broadcastAt: 'Diffuser',
        status: 'Statut',
        shipmentUpdate: 'Mise à jour de l`expédition',
        // template: 'Modèle',
        flow: 'Couler',
        resendFailedMessages: 'Revoir les messages échoués',
        // sentMessages: 'Messages envoyés',
        // deliveredMessages: 'Messages livrés',
        goBack: 'Retourner',
        number: 'Nombre',
        errorCode: 'Code d`erreur',
        failedAt: 'Échoué à',
        badGateway: '502 Mauvaise passerelle',
        loadMore: 'Charger plus',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          searchBroadcastByName: 'Rechercher la diffusion par nom',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Messages de diffusion totaux envoyés',
          totalBroadcastMessagesDelivered: 'Messages de diffusion totaux livrés',
          totalBroadcastMessagesReadByContacts: 'Messages de diffusion totaux lus par les contacts',
          totalFailedBroadcastMessages: 'Messages de diffusion totale échoué',
          clickToViewDetailedInformationOfFailedMessages: 'Cliquez pour afficher les informations détirées des messages échoués',
          clickToCreateNewBroadcast: 'Cliquez pour créer une nouvelle diffusion',
          templateUsedInBroadcast: 'Modèle utilisé dans la diffusion',
          numberOfBroadcastMessagesSent: 'Nombre de messages de diffusion envoyés',
          numberOfBroadcastMessagesDelivered: 'Nombre de messages de diffusion livrés',
          numberOfBroadcastMessagesReadByContacts: 'Nombre de messages de diffusion lus par les contacts',
          numberOfTimesBroadcastMessagesHasFailed: 'Nombre de fois la messagerie diffusée a échoué',
          contactNumberOnWhichMessageDeliveryFailed: 'Numéro de contact sur lequel la livraison de messages a échoué',
          unDeliveredTemplate: 'Modèle non livré',
          errorReceivedForFailedMessage: 'Erreur reçue pour le message échoué',
          timeWhenMessageDeliveryFailed: 'Heure où la livraison des messages a échoué',
          TOOLTIP_ADD_BROADCAST_NAME: 'Entrez un nouveau nom de diffusion',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Sélectionnez si vous diffusez un flux ou un message.',
          TOOLTIP_BROADCAST_FLOW: 'Sélectionnez le flux de chat que vous souhaitez diffuser,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Sélectionnez le Groupe des contacts auquel vous souhaitez diffuser',
          TOOLTIP_BROADCAST_NAME: 'Nom de la diffusion',
          TOOLTIP_BROADCAST_TYPE: 'Flux ou message sélectionné pour la diffusion',
          TOOLTIP_BROADCAST_SEGMENT: 'Groupe pour lequel la diffusion est créée',
          TOOLTIP_BROADCAST_AT: 'Date et heure prévues pour la diffusion',
          TOOLTIP_BROADCAST_STATUS: 'Diffuser le statut comme prévu, en cours d`exécution ou achevé',
        }
      },
      drip: {
        createDripCampaign: 'Créer une campagne d`égouttement',
        youCanCreateMultipleCampaigns: 'Vous pouvez créer plusieurs campagnes',
        dripCampaignName: 'Nom de la campagne goutte à goutte',
        dripCampaignNameIsRequired: 'Le nom de campagne goutte à goutte est requis',
        // createDripCampaign: 'Créer une campagne d'égouttement',
        youCanCreateMultipleDripCampaigns: 'Vous pouvez créer plusieurs campagnes DRIP avec des messages automatisés planifiés pour déclencher des réponses à partir de Groupes d`utilisateurs spécifiques.',
        campaignName: 'Nom de la campagne',
        campaignNameIsRequired: 'Le nom de la campagne est requis',
        after: 'Après',
        thisMessageWillBeSendAfter: 'Ce message sera envoyé après',
        saveAndUpdate: 'Enregistrer et mettre à jour',
        schedule: ' Calendrier',
        select: 'Sélectionner',
        messages: 'messages',
        cancel: 'Annuler',
        createCampaign: 'Créer une campagne',
        updateCampaign: 'Mettre à jour la campagne',
        createNewCampaign: 'Créer une nouvelle campagne',
        // campaignName: 'Nom de la campagne',
        messagesSent: 'Messages envoyés',
        createdAt: 'Créé à',
        updatedAt: 'Mis à jour à',
        action: 'Action',
        placeholders: {
          nameYourDripCampaign: 'Nommez votre campagne d`égouttement',
          enterCampaignName: 'Entrez le nom de la campagne',
          searchCampaignByName: 'Campagne de recherche par nom',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Cliquez pour ajouter une nouvelle campagne',
          TOOLTIP_SCHEDULE: 'Planifiez quand et quel flux de chat à envoyer en premier dans cette campagne.',
          TOOLTIP_AFTER_DAYS: 'Planifiez quand envoyer le premier flux de la campagne en quelques minutes, heures ou jours.',
          TOOLTIP_ADD_MESSAGES: 'Cliquez pour ajouter un nouveau flux à la campagne',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Entrez un nouveau nom de campagne',
          TOOLTIP_CAMPAIGN_NAME: 'Nom de la campagne',
          TOOLTIP_NO_MESSAGES_SENT: 'Nombre de messages envoyés par la campagne',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Date et heure de la création de la campagne',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Date et heure où la campagne a été modifiée pour la dernière fois',
          TOOLTIP_CAMPAIGN_ACTION: 'Modifier ou supprimer la campagne',
          TOOLTIP_UPDATE_CAMPAIGN: 'Cliquez pour enregistrer les modifications apportées dans la configuration de la campagne',
        }
      },
      flows: {
        createChatFlow: 'Créer un flux de discussion',
        youCanCreateMultipleConversationsFlow: 'Vous pouvez créer plusieurs flux de conversations sur plusieurs canaux pour les tests et diffusions A / B.',
        flowName: 'Nom de flux',
        nameIsRequired: 'Le nom est requis',
        cancel: 'Annuler',
        createFlow: 'Créer un flux',
        createNewFlow: 'Créer un nouveau flux',
        // flowName: 'Nom de flux',
        ofMessages: 'des messages',
        triggered: 'Déclenché',
        stepsFinsished: 'Étapes terminées',
        finished: 'Fini',
        createdOn: 'Créé sur',
        lastModified: 'Dernière modifié',
        defaultFlow: 'Flux par défaut',
        action: 'Action',
        flowAnalytics: 'Analyse de flux',
        placeholders: {
          enterFlowName: 'Entrer le nom du flux',
          searchFlowByName: 'Rechercher le flux par nom',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'Nombre de fois le flux de chat est déclenché',
          numberOfCompletedChatFlowStepsWhenTriggered: 'Nombre d`étapes de flux de chat terminées lorsqu`elle est déclenchée',
          numberOfTimesUsersCompletedChatFlowSteps: 'Nombre de fois que les utilisateurs ont terminé toutes les étapes de flux de chat',
          dateAndTimeOfCreationOfChatFlow: 'Date et heure de la création du flux de chat',
          dateAndTimeWhenChatFlowLastModified: 'Date et heure où le flux de chat a été modifié pour la dernière fois',
          TOOLTIP_CREATE_FLOW: 'Cliquez pour créer un nouveau flux de chat',
          TOOLTIP_FLOW_NAME: 'Entrez le nom du flux de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nom du flux de chat',
          TOOLTIP_NO_OF_MESSAGES: 'Nombre de composants de message dans le flux de chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilisez cette bascule pour définir un flux de chat par défaut, qui sera servi à tous les nouveaux utilisateurs.',
          TOOLTIP_ACTION: 'Modifier ou supprimer le flux de chat',
        }
      },
      manage: {
        leads: 'Fils',
        manageBotsFunctionalityForContacts: 'Gérer les fonctionnalités de Bot pour les contacts / leads',
        whenToSendLeadInfo: 'Quand envoyer des informations de plomb',
        smartFlow: 'Flux',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Message de bienvenue lorsqu`aucune recherche de mots clés n`est remplie',
        selectTemplates: 'Sélectionner des modèles',
        selectFlows: 'Sélectionner les flux',
        selectText: 'Sélectionnez le texte',
        selectImages: 'Sélectionner des images',
        whenItAlmostReaches24HoursSinceMessage: 'Quand il atteint presque 24 heures depuis le dernier message',
        // selectTemplates: 'Sélectionner des modèles',
        updateChanges: 'Mettre à jour les modifications',
        manageBotsFunctionalityAccordingToKeywords: 'Gérer les fonctionnalités de Bot en fonction des mots clés entrés par l`utilisateur de Bot, en cas de flux intelligent',
        addKeywordAction: 'Ajouter une action de mot-clé',
        keywords: 'Mots clés',
        matchingMethod: 'Méthode de correspondance',
        replyMaterial: 'Matériel de réponse',
        action: 'Action',
        select: 'Sélectionner',
        placeholders: {
          keyword: 'mot-clé',
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Suggérer si le bot pour envoyer des informations de contact / plomb à l`intégration CRM immédiatement, ou après 5 minutes, ou à la fin du flux',
          TOOLTIP_LEAD_SMART_FLOW: 'Choisissez n`importe quel flux par défaut pour l`envoyer à tous les utilisateurs de bot ou Smart Flow (déterminé par les mots clés l`entrée par l`utilisateur BOT).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Sélectionnez le matériel de réponse (flux, modèle, texte, image) à envoyer si le bot ne reçoit aucune correspondance de mots clés à partir de la réponse de l`utilisateur du bot',
          TOOLTIP_IT_ALMOST_REACHED: 'Sélectionnez le matériel de réponse à envoyer aux contacts / fils juste avant 24 heures de dernière messagerie',
          TOOLTIP_KEYWORD_ACTION_TITLE: 'Gérer les fonctionnalités de Bot en fonction des mots clés entrés par l`utilisateur de Bot, en cas de flux intelligent',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING: 'Sélectionnez la réponse du bot comme modèle, flux, texte ou image par rapport aux mots clés spécifiques',
          TOOLTIP_ADD_KEYWORD: 'Ajouter des mots clés',
          TOOLTIP_MATCHING_METHOD: 'Ajouter la correspondance des mots clés comme exact ou contient',
          TOOLTIP_REPLY_MATERIAL: 'Sélectionnez le modèle, le flux, le texte ou l`image pour répondre pour le mot clé suggéré',
          TOOLTIP_KEYWORD_ACTIONS: 'Supprimer l`action du mot-clé',
        },
        socialAlerts: {
          alerts: 'Alertes',
          specifyHowYourBotWillNotify: 'Spécifiez comment votre bot vous informera des nouveaux prospects.',
        }
      },
      orders: {
        manageCustomersOrder: 'Gérer les commandes des clients',
        checkAndManageAllOfYourCustomerOrders: 'Vérifiez et gérez toutes vos commandes et mises à jour des clients.',
        selectShipmentTemplate: 'Sélectionner le modèle d`expédition',
        send: 'Envoyer',
        export: 'Exporter',
        phoneNumber: 'Numéro de téléphone',
        numberOfItems: 'Nombre d`objets',
        orderValue: 'Valeur de la commande',
        orderDate: 'Date de commande',
        status: 'Statut',
        chat: 'Discuter',
        item: 'Article',
        quantity: 'Quantité',
        amount: 'Montant',
        unitPrice: 'Prix ​​unitaire',
        placeholders: {
          searchOrdersByPhoneNumber: 'Commandes de recherche par numéros de téléphone',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Exporter les données des commandes dans le fichier CSV',
          TOOLTIP_ORDERS_PHONE_NO: 'Numéro de téléphone du client qui a passé la commande',
          TOOLTIP_NO_OF_ITEMS: 'Nombre d`articles commandés',
          TOOLTIP_ORDER_VALUE: 'Valeur de la commande',
          TOOLTIP_ORDER_DATE: 'La commande a été passée à cette date.',
          TOOLTIP_ORDER_STATUS: 'Statut de commande tel que confirmé, en attente, rejeté. ou expédié',
          TOOLTIP_ORDER_CHAT: 'Toutes les conversations de chat précédentes concernant cette commande',
        }
      },
      widgets: {
        installWidgetHeader: 'Installer le widget',
        createWidgetHeader: 'Créer un widget',
        copyTheCodeAndPasteItWhereYouWant: 'Copiez le code et collez-le chaque fois que vous avez besoin dans votre HTML, votre site Web, etc.',
        youCanCreateMultipleWidgets: 'Vous pouvez créer plusieurs widgets pour donner à votre bot une apparence différente sur différentes pages Web.',
        installWidget: {
          javascript: 'Javascrip',
          install: 'Installer',
          onYourWebsite: ' sur votre site Web',
          copyCode: 'Copier de code',
          copyAndPaste: 'Copier et coller',
          copyThisCodeSnippet: 'Copiez cet extrait de code et insérez-le dans le HTML de chaque page Web où vous souhaitez',
          seeGuide: 'voir guide',
        },
        widgetList: {
          widgetName: 'Nom du widget',
          nameIsRequired: 'Le nom est requis',
          cancel: 'Annuler',
          createWidget: 'Créer un widget',
          icon: 'Icône',
          name: 'Nom',
          createdAt: 'Créé à',
          action: 'Action',
          placeholders: {
            enterWidgetName: 'Entrez le nom du widget',
            searchWidgetByName: 'Rechercher le widget par nom',
          },
          appTooltip: {
            widgetIcon: 'Icône du widget',
            nameOfWidget: 'Nom du widget',
            dateAndTimeOfCreationOfWidget: 'Date et heure de la création du widget',
            editOrDeleteWidget: 'Modifier ou supprimer le widget',
          }
        },
        widgetTemplates: {
          widgetName: 'Nom du widget',
          nameIsRequired: 'Le nom est requis',
          chooseTypeOfWidget: 'Choisissez le type de widget',
          bar: 'Bar',
          slideIn: 'Glisser',
          pageTakeOver: 'Take-over',
          button: 'Bouton',
          facebookModal: 'Facebook Modal',
          comment: 'Commentaire',
          direct: 'Direct',
          whatsappIcon: 'Icône WhatsApp',
          refUrl: 'URL de la référence',
          qrCode: 'QR Code',
          // refUrl: 'URL de la référence',
          backgroundColor: 'Couleur de l`arrière plan',
          descriptionColor: 'Description Couleur',
          headlineColor: 'Couleur des titres',
          buttonBackgroundColor: 'Couleur de fond du bouton',
          contactNumber: 'Numéro de contact',
          entryMessage: 'Message d`entrée',
          buttonText: 'Texte du bouton',
          selectButtonText: 'Sélectionnez le texte du bouton',
          buttonSize: 'Taille du bouton',
          selectOne: 'En sélectionner un',
          placement: 'Placement',
          rightBottom: 'Fondateur',
          optInFlow: 'Flux d`opt-in',
          selectFlow: 'Sélectionner le flux',
          addSubscriberToSequence: 'Ajouter l`abonné à la séquence',
          selectDrip: 'Sélectionner une goutte',
          whenDoesItDisplay: 'Quand s`affiche-t-il (secondes)',
          showWidgetToSameUserAgainAfter: 'Afficher à nouveau le widget au même utilisateur après',
          ifManuallyClosedByUser: 'S`il est fermé manuellement par l`utilisateur, montrez',
          // comment: 'Commentaire',
          specificPosts: 'Publications spécifiques',
          allPosts: 'Tous les messages',
          // entryMessage: 'Message d'entrée',
          postMessage: 'Message de publication',
          postDate: 'Après la date',
          status: 'Statut',
          action: 'Action',
          discard: 'Jeter',
          saveChanges: 'Sauvegarder les modifications',
          nextStep: 'L`étape suivante',
          widgetDescription: 'Description du widget',
          integrateTheBarWidget: 'Intégrez le widget de la barre dans le corps de votre site Web et augmentez votre abonné de page ainsi que l`envoi, puis les messages BOT personnalisés.',
          hereIsYourWidgetHeadline: 'Voici votre titre de widget, cliquez ici pour le changer.',
          sendUsMessage: 'Envoyez-nous un message',
          weAlsoPutDefaultTextHere: 'Nous mettons également le texte par défaut ici. Assurez-vous que le transformant en message précieux',
          selectYourPagePost: 'Sélectionnez votre publication de page',
          nA: 'N / A',
          // entryMessage: 'Message d'entrée',
          saveRule: 'Économiser la règle',
          placeholders: {
            enterWidgetName: 'Entrez le nom du widget',
            enterNumber: 'Entrer un nombre',
            enterMessageHere: 'Entrez le message ici',
            enterTimeInSeconds: 'Entrez du temps en quelques secondes',
          }
        }
      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'Personnalisez les paramètres de déclenchement de votre bot',
      makeYourBotsTimingPerfect: 'Rendez le timing de votre bot parfait.',
      onDesktopTriggerTime: 'Temps de déclenchement sur desktop (secondes)',
      onMobileTriggerTime: 'Temps de déclenchement sur mobile (secondes)',
      chatBubble: 'Chat Bubble Automatic Opening',
      seconds: 'Secondes',
      discard: 'Jeter',
      saveChanges: 'Sauvegarder les modifications',
      autoTriggerOnOrOff: 'Déclencheur automatique: ON / OFF',
      seeHowToUse: 'Voir comment utiliser',
      alwaysOpenAfter: 'Toujours ouvert après les déclencheurs ci-dessus spécifiés',
      automaticallyAfterSelectedNumberOfTime: 'Automatiquement après une durée sélectionnée',
      neverAutomaticallyOpenBot: 'N`ouvrez jamais automatiquement le bot',
      placeholders: {
        enterTimeInSeconds: 'Entrez du temps en quelques secondes',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Temps d`attente en quelques secondes, sur les appareils de bureau des visiteurs, avant que votre bot n`apparaît automatiquement',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Temps d`attente en quelques secondes, sur le mobile des visiteurs, avant que votre bot n`apparaît automatiquement',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Lorsque le visiteur ferme la fenêtre de chat, sélectionnez comment être le comportement pop-up automatique du bot',
        customerWaitingTimeToOpenBubble: 'Temps d`attente du client pour ouvrir la bulle de chat',
        wantToDisableAutoTriggering: 'Cliquez sur si vous souhaitez désactiver le déclenchement automatique du bot sur les pages Web',
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'Personnalisez les paramètres des utilisateurs de votre bot',
      decideWhoCanAccessYourBot: 'Décidez qui peut accéder à votre bot',
      deviceSupport: 'Prise en charge de l`appareil',
      blockIpAddresses: 'Bloquer les adresses IP',
      hideChatBotOnSpecificPages: 'Masquer le chatbot sur des pages spécifiques',
      selectCountriesToEnableBot: 'Sélectionnez des pays pour permettre au bot',
      discard: 'Jeter',
      saveChanges: 'Sauvegarder les modifications',
      seeHowToUse: 'Voir comment utiliser',
      allDevices: 'Tous les dispositifs',
      mobileOnly: 'Mobile uniquement',
      desktopOnly: 'Bureau uniquement',
      placeholders: {
        enterIpAddress: 'Entrez l`adresse IP (IPv4 ou IPv6) et appuyez sur `ENTER `',
        enterUrl: 'Entrez l`URL des pages et appuyez sur «Entrez»',
        searchCountry: 'Pays de recherche',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Sélectionnez sur quels appareils devraient apparaître',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Entrez les adresses IP où vous ne souhaitez pas que votre bot apparaisse',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Entrez l`URL de vos pages Web où vous ne souhaitez pas que votre bot apparaisse',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Sélectionnez des emplacements à partir de l`endroit où vous souhaitez que les utilisateurs accédent à votre bot',
      }
    },
    facebook: {
      facebookBotSettings: 'Paramètres de bot Facebook',
      chatFlowsHeader: 'Flux de discussion',
      dripCampaignHeader: 'Campagne goutte à goutte',
      broadcastHeader: 'Diffuser',
      widgetsHeader: 'Widgets',
      autoReplyHeader: 'Repliquer automatiquement',
      generalHeader: 'Général',
      facebookAutomation: 'Automatisation Facebook',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personnalisez les modèles de flux de chat et créez plusieurs flux de chat' +
          ' pour différents publics cibles.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Créez un parcours d`intégration du client à l`aide d`une campagne goutte à goutte' +
          ' de flux de discussion planifiés.',
      },
      broadcasts: {
        createBroadcast: 'Créer une diffusion',
        youCanCreateMultipleBroadcasts: 'Vous pouvez créer plusieurs émissions',
        broadcastName: 'Nom de diffusion',
        broadcastNameIsRequired: 'Le nom de diffusion est requis',
        continue: 'Continuer',
        manageBroadcasts: 'Gérer les émissions',
        youCanCreateMultipleBroadcastsWith: 'Vous pouvez créer plusieurs diffusions avec plusieurs modèles planifiés pour différents Groupes de clientèle.',
        createNewBroadcast: 'Créer une nouvelle émission',
        name: 'Nom',
        segment: 'Groupe',
        template: 'Modèle',
        contacts: 'Contacts',
        send: 'Envoyer',
        failed: 'Échoué',
        broadcastAt: 'Diffuser',
        status: 'Statut',
        actions: 'Actions',
        nA: 'N / A',
        deleteBroadcast: 'Supprimer la diffusion',
        wantToDeleteThisBroadcast: 'Êtes-vous sûr de vouloir supprimer cette diffusion?',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          searchHere: 'Cherche ici',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Supprimer plusieurs émissions',
          deleteBroadcast: 'Supprimer la diffusion',
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Nom de diffusion',
        broadcastNameIsRequired: 'Le nom de diffusion est requis',
        flow: 'Couler',
        segments: 'Groupes',
        whenToSend: 'Quand envoyer',
        selectDate: 'Sélectionner une date',
        selectTimeSlot: 'Sélectionnez le plage horaire',
        createBroadcast: 'Créer une diffusion',
        cancel: 'Annuler',
        now: 'Maintenant',
        later: 'Plus tard',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          selectFlow: 'Sélectionner le flux',
          searchFlowByName: 'Rechercher le flux par nom',
          selectSegments: 'Sélectionner des Groupes',
          searchSegmentByName: 'Rechercher le Groupe par nom',
          selectTimeSlot: 'Sélectionnez le plage horaire',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Sélectionnez la date de début',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Créer une campagne d`égouttement',
        youCanCreateMultipleCampaign: 'Vous pouvez créer plusieurs campagnes',
        dripCampaignName: 'Nom de la campagne goutte à goutte',
        dripCampaignNameIsRequired: 'Le nom de campagne goutte à goutte est requis',
        continue: 'Continuer',
        manageDripCampaign: 'Gérer la campagne d`égouttement',
        youCanCreateMutlipleDripCampaign: 'Vous pouvez créer plusieurs campagnes DRIP avec des messages automatisés planifiés pour déclencher des réponses à partir de Groupes d`utilisateurs spécifiques.',
        createNewDripCampaign: 'Créer une nouvelle campagne d`égouttement',
        campaignName: 'Nom de la campagne',
        appliedOn: 'Appliqué sur',
        segment: 'Groupe',
        flow: 'Couler',
        startDate: 'Date de début',
        action: 'Action',
        nA: 'N / A',
        deleteDripCampaign: 'Supprimer les campagnes DRIP',
        wantToDeleteThisDripCampaign: 'Êtes-vous sûr de vouloir supprimer ces campagnes goutte à goutte?',
        placeholders: {
          enterDripCampaignName: 'Entrez le nom de la campagne goutte à goutte',
          searchByName: 'Recherche par nom',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Supprimer plusieurs campagnes DRIP',
          deleteDripCampaign: 'Supprimer la campagne d`égouttement',
        },
        createDripCampaignFacebook: {
          dripCampaignName: 'Nom de la campagne goutte à goutte',
          appliedOn: 'Appliqué sur',
          segments: 'Groupes',
          selectDate: 'Sélectionner une date',
          scheduleYourFlows: 'Planifiez vos flux',
          schedule: 'Calendrier',
          flow: 'Couler',
          sendAfter: 'Envoyer après',
          cancel: 'Annuler',
          newSubscriber: 'Nouvel abonné',
          days: 'Jours',
          minutes: 'Minutes',
          hours: 'Heures',
          createDripCampaign: 'Créer une campagne d`égouttement',
          placeholders: {
            enterDripName: 'Entrez le nom de goutte',
            selectAppliedOn: 'Sélectionner appliqué sur',
            selectSegment: 'Sélectionner le Groupe',
            searchByName: 'Recherche par nom',
            selectTemplate: 'Sélectionner le modèle',
            searchFlowByName: 'Rechercher le flux par nom',
            enterSendAfter: 'Entrez envoyer après',
          },
          dateTimePicker: {
            selectStartDate: 'Sélectionnez la date de début',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'Identifiant de chat',
        errorCode: 'Code d`erreur',
        errorMessage: 'Message d`erreur',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flux de discussion',
        createChatFlow: 'Créer un flux de discussion',
        youCanCreateMultipleChatFlows: 'Vous pouvez créer plusieurs flux de conversations sur plusieurs canaux pour les tests et diffusions A / B.',
        flowName: 'Nom de flux',
        nameIsRequired: 'Le nom est requis',
        cancel: 'Annuler',
        createFlow: 'Créer un flux',
        importFlow: 'Flux d`importation',
        createNewFlow: 'Créer un nouveau flux',
        ofMessages: 'des messages',
        createdOn: 'Créé sur',
        lastModified: 'Dernière modifié',
        defaultFlow: 'Flux par défaut',
        action: 'Action',
        exportStatus: 'Statut d`exportation',
        flowExport: 'Exportation',
        download: 'Télécharger',
        // importFlow: 'Flux d'importation',
        beforeYouStartUploadPleaseMakeSure: 'Avant de démarrer le téléchargement, assurez-vous',
        point1: '1. La limite de téléchargement de taille de fichier maximale est de 1 Mo',
        point2: '2. Le format de fichier doit être en JSON',
        selectFile: 'Choisir le dossier',
        inProgress: 'en cours',
        available: 'Disponible',
        initiated: 'Initié',
        placeholders: {
          enterFlowName: 'Entrer le nom du flux',
        },
        appTooltip: {
          importFlowForFacebookBot: 'Importer le flux pour Facebook Bot',
          dateAndTimeOfCreationOfChatFlow: 'Date et heure de la création du flux de chat',
          dateAndTimeWhenChatFlowLastModified: 'Date et heure où le flux de chat a été modifié pour la dernière fois',
          TOOLTIP_CREATE_FLOW: 'Cliquez pour créer un nouveau flux de chat',
          TOOLTIP_FLOW_NAME: 'Entrez le nom du flux de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nom du flux de chat',
          TOOLTIP_NO_OF_MESSAGES: 'Nombre de composants de message dans le flux de chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilisez cette bascule pour définir un flux de chat par défaut, qui sera servi à tous les nouveaux utilisateurs.',
          TOOLTIP_ACTION: 'Modifier ou supprimer le flux de chat',
        },
        noDataFound: {
          flows: 'Les flux',
          noFlowsAddedYet: 'Aucun flux ajouté encore!',
        },
        modal: {
          deleteFlow: 'Supprimer le flux',
          wantToDeleteThisFlow: 'Êtes-vous sûr de vouloir supprimer ce flux?',
        }
      },
      widgets: {
        manageWidgets: 'Gérer les widgets',
        widgetsAreRoutingTools: 'Les widgets sont les outils de routage pour augmenter les utilisations du bot',
        needHelp: 'Besoin d`aide?',
        wantToMakeChanges: 'Vous voulez apporter des modifications?',
        editIcon: 'Icône de modification',
        discard: 'Jeter',
        updateWidget: 'Mettre à jour le widget',
        installChatBotIconOnYourWebsite: 'Installez l`icône chatbot sur votre site Web',
        shareWhereEverYouWantToRedirectUsersTo: 'Partagez où vous voulez et redirigez les utilisateurs vers votre bot Facebook en un seul clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copiez et ajoutez le fichier d`en-tête de votre site Web',
        copyLinkAndStartSharing: 'Copiez le lien et commencez à partager!',
        downloadQrCodeAndStartSharing: 'Téléchargez le code QR et commencez à partager!',
        addCodeAndDeployNewCodeAndDone: 'Ajoutez le code, déployez un nouveau code et fait!',
        wantToLearnHowToUseIt: 'Vous voulez apprendre à l`utiliser?',
        copyChatBotCode: 'Copier le code du chatbot',
        copyFacebookLink: 'Copier le lien Facebook',
        downloadQrCode: 'Télécharger le code QR',
        link: 'Lien',
        chatBotIcon: 'Icône de chatbot',
        qrcode: 'QR Code',
        small: 'Petit',
        medium: 'Moyen',
        large: 'Grand',
        leftMiddle: 'Gauche à gauche',
        rightMiddle: 'À droite',
        leftBottom: 'En bas à gauche',
        rightBottom: 'Fondateur',
        bpDropDown: {
          selectIcons: 'Sélectionner les icônes',
          iconSize: 'Taille de l`icône',
          iconPlacement: 'Placement d`icône',
        }
      },
      autoReply: {
        autoReplyTitle: 'Réponse automatique',
        configureYour: 'Configurez votre message de ',
        message: '',
        customizeAutoReplyMessagesForYourPostsComments: 'Personnalisez les messages de réponse automatique pour les commentaires de votre publication',
        wouldYouLikeToApply: 'Souhaitez-vous appliquer une ',
        autoreply: 'réponse automatique',
        onAllPost: ' à tous les messages ?',
        configureYourMessageForEachPost: 'Configurez votre message pour chaque publication',
        configureFlowToTriggerAfterOnReply: 'Configurez le flux à déclencher après une réponse',
        whenUserLeavesACommentSendMessageAfter: 'Lorsqu\'un utilisateur laisse un commentaire, envoyez un message ensuite',
        triggerFlowOnReply: 'Déclencher le flux lors d\'une réponse',
        add: 'Ajouter',
        update: 'Mettre à jour',
        save: 'Sauvegarder',
        cancel: 'Annuler',
        discard: 'Rejeter',
        bpDropDown: {
          selectPost: 'Sélectionner une publication',
          triggerFlowOnReply: 'Déclencher le flux lors d\'une réponse',
          immendiately: 'Immédiatement',
          minutes: 'minutes'
        },
        bpTable: {
          message: 'Message',
          selectedPost: 'Post sélectionné',
          triggerFlow: 'Déclencher le flux',
          actions: 'Actions'
        },
        placeholder: {
          configureYourMessage: 'Configurez votre message'
        },
        deleteModel: {
          title: 'Supprimer la réponse automatique de la publication',
          description: 'Êtes-vous sûr de vouloir supprimer cette réponse automatique de la publication ?'
        },
      },
      generalSetting: {
        sessionTimeOutInHours: 'Expiration de session',
        sendInactivityMessage: 'Envoyer un message d\'inactivité',
        enterSessionTimeOutWindowInHours: `Entrez la fenêtre d'expiration de session en heures`,
        sessionTimeOutInHoursTooltip: `Entrez la durée après laquelle la session de chat expirera automatiquement en raison de l'inactivité. Le flux de chat redémarrera si l'utilisateur envoie un message après l'expiration de la session.`,
        restartChatFlowTriggers: 'Déclencheurs de redémarrage du flux de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Entrez des mots-clés pour redémarrer le chat et appuyez sur Entrée',
        restartChatFlowTriggersTooltip: `Spécifiez ici les mots-clés qui, lorsqu'ils sont saisis par l'utilisateur et soumis, déclencheront le redémarrage du flux de chat. Cela permet aux utilisateurs de redémarrer la conversation à tout moment.
         Appuyez sur Entrée après avoir ajouté chaque mot-clé pour en ajouter plusieurs.`,
        liveChatTriggers: 'Déclencheurs de chat en direct',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Entrez des mots-clés pour déclencher le chat en direct et appuyez sur Entrée',
        liveChatTriggersTooltip: `Définissez les mots-clés qui, lorsqu'ils sont saisis par l'utilisateur et soumis, initieront une transition du chat automatisé vers le chat en direct. C'est utile lorsque les utilisateurs ont besoin de l'aide d'un agent en direct.
         Appuyez sur Entrée après avoir ajouté chaque mot-clé pour en ajouter plusieurs.`,
        messageAfterAutomatedFlowCompleted: 'Message après la fin du flux automatisé',
        enterYourMessage: 'Entrez votre message',
        messageAfterAutomatedFlowCompletedTooltip: `Saisissez un message qui sera affiché à l'utilisateur après avoir terminé le flux de chat automatisé, mais que la session n'a pas expirée.`,
        restart: 'Redémarrer',
        liveChat: 'Chat en Direct',
        lineOneForDefaultMessage: `Si vous avez plus de questions ou avez besoin d'aide, voici ce que vous pouvez faire ensuite:`,
        to: 'À',
        restartTheChat: 'redémarrer le chat',
        simpleTypeRestartAndHitEnter: `il suffit de taper 'Redémarrer' et d'appuyer sur Entrée. Cela vous ramènera au début de notre conversation.`,
        lineThreeForDefaultMessagePartOne: `Si vous souhaitez parler à un`,
        liveAgent: `agent en direct,`,
        lineThreeForDefaultMessageLastPart: `tapez 'Chat en Direct' et appuyez sur Entrée. Notre équipe est là pour vous aider en temps réel.`,
        saveChanges: 'Enregistrer les modifications',
        discard: 'Annuler',
        configureBotsGeneralSettings: 'Configurez les paramètres généraux de votre bot',
        minutes: 'Minutes',
        hours: 'Heures',
      }
    },
    instagram: {
      instagramBotSettings: 'Paramètres de bot Instagram',
      chatFlowsHeader: 'Flux de discussion',
      broadcastHeader: 'Diffuser',
      dripCampaignHeader: 'Campagne goutte à goutte',
      widgetsHeader: 'Widgets',
      alertsHeader: 'Alertes',
      alerts: {
        configureYourBotsSettings: 'Configurez les paramètres d`alerte de votre bot',
        specifyHowYourBotNotifyYou: 'Spécifiez comment votre bot vous informera des nouveaux prospects.',
        subject: 'Sujet',
        emailNotification: 'Notification par e-mail',
        emailAddresses: 'Adresses mail',
        leadRevisitNotifications: 'Notifications de révision du lead',
        whenToSendLeadInfo: 'Quand envoyer des informations de plomb',
        discard: 'Jeter',
        saveChanges: 'Sauvegarder les modifications',
        placeholders: {
          enterSubjectForYourEmail: 'Entrez le sujet pour votre e-mail',
          typeYourEmailAddressAndHitEnter: 'Tapez votre adresse e-mail et appuyez sur Entrée',
        }
      },
      broadcasts: {
        createBroadcast: 'Créer une diffusion',
        youCanCreateMultipleBroadcasts: 'Vous pouvez créer plusieurs émissions',
        broadcastName: 'Nom de diffusion',
        broadcastNameIsRequired: 'Le nom de diffusion est requis',
        continue: 'Continuer',
        manageBroadcasts: 'Gérer les émissions',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Vous pouvez créer plusieurs émissions avec plusieurs modèles planifiés pour différents Groupes de clients',
        createNewBroadcast: 'Créer une nouvelle émission',
        segment: 'Groupe',
        template: 'Modèle',
        contacts: 'Contacts',
        send: 'Envoyer',
        failed: 'Échoué',
        broadcastAt: 'Diffuser',
        status: 'Statut',
        actions: 'Actions',
        nA: 'N / A',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          searchHere: 'Cherche ici',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Supprimer plusieurs émissions',
          deleteBroadcast: 'Supprimer la diffusion',
        }
      },
      createBroadcast: {
        broadcastName: 'Nom de diffusion',
        broadcastNameIsRequired: 'Le nom de diffusion est requis',
        flow: 'Couler',
        segments: 'Groupes',
        whenToSend: 'Quand envoyer',
        selectDate: 'Sélectionner une date',
        selectTimeSlot: 'Sélectionnez le plage horaire',
        cancel: 'Annuler',
        createBroadcast: 'Créer une diffusion',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          selectFlow: 'Sélectionner le flux',
          searchFlowByName: 'Rechercher le flux par nom',
          selectSegments: 'Sélectionner des Groupes',
          searchSegmentByName: 'Rechercher le Groupe par nom',
          selectTimeSlot: 'Sélectionnez le plage horaire',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Sélectionnez la date de début',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Créer une campagne d`égouttement',
        youCanCreateMultipleCampaigns: 'Vous pouvez créer plusieurs campagnes',
        dripCampaignName: 'Nom de la campagne goutte à goutte',
        dripCampaignNameIsRequired: 'Le nom de campagne goutte à goutte est requis',
        continue: 'Continuer',
        manageDripCampaign: 'Gérer la campagne d`égouttement',
        youCanCreateMultipleCampaignsWith: 'Vous pouvez créer plusieurs campagnes DRIP avec des messages automatisés planifiés pour déclencher des réponses à partir de Groupes d`utilisateurs spécifiques',
        createNewDripCampaign: 'Créer une nouvelle campagne d`égouttement',
        campaignName: 'Nom de la campagne',
        appliedOn: 'Appliqué sur',
        segment: 'Groupe',
        flow: 'Couler',
        startDate: 'Date de début',
        action: 'Action',
        nA: 'N / A',
        placeholders: {
          enterDripCampaignName: 'Entrez le nom de la campagne goutte à goutte',
          searchByName: 'Recherche par nom',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Supprimer plusieurs campagnes DRIP',
          deleteDripCampaign: 'Supprimer la campagne d`égouttement',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Nom de la campagne goutte à goutte',
          appliedOn: 'Appliqué sur',
          segments: 'Groupes',
          selectDate: 'Sélectionner une date',
          scheduleYourFlows: 'Planifiez vos flux',
          flow: 'Couler',
          sendAfter: 'Envoyer après',
          cancel: 'Annuler',
          createDripCampaign: 'Créer une campagne d`égouttement',
          placeholders: {
            enterDripName: 'Entrez le nom de goutte',
            selectAppliedOn: 'Sélectionner appliqué sur',
            selectSegment: 'Sélectionner le Groupe',
            searchByName: 'Recherche par nom',
            selectTemplate: 'Sélectionner le modèle',
            searchFlowByName: 'Rechercher le flux par nom',
            enterSendAfter: 'Entrez envoyer après',
          },
          dateTimePicker: {
            selectStartDate: 'Sélectionnez la date de début',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'Identifiant de chat',
        errorCode: 'Code d`erreur',
        errorMessage: 'Message d`erreur',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flux de discussion',
        createChatFlow: 'Créer un flux de discussion',
        youCanCreateMultipleChatFlows: 'Vous pouvez créer plusieurs flux de conversations sur plusieurs canaux pour les tests et diffusions A / B.',
        flowName: 'Nom de flux',
        nameIsRequired: 'Le nom est requis',
        cancel: 'Annuler',
        createFlow: 'Créer un flux',
        importFlow: 'Flux d`importation',
        createNewFlow: 'Créer un nouveau flux',
        // flowName: 'Nom de flux',
        ofMessages: ' des messages',
        createdOn: 'Créé sur',
        lastModified: 'Dernière modifié',
        defaultFlow: 'Flux par défaut',
        action: 'Action',
        exportStatus: 'Statut d`exportation',
        flowExport: 'Exportation',
        download: 'Télécharger',
        // importFlow: 'Flux d'importation',
        beforeYouStartUploadPleaseMakeSure: 'Avant de démarrer le téléchargement, assurez-vous',
        point1: '1. La limite de téléchargement de taille de fichier maximale est de 1 Mo',
        point2: '2. Le format de fichier doit être en JSON',
        selectFile: 'Choisir le dossier',
        placeholders: {
          enterFlowName: 'Entrer le nom du flux',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Importer le flux pour Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Date et heure de la création du flux de chat',
          dateAndTimeWhenChatFlowLastModified: 'Date et heure où le flux de chat a été modifié pour la dernière fois',
        },
        noDataFound: {
          flows: 'Les flux',
          noFlowsAddedYet: 'Aucun flux ajouté encore!',
        },
        modal: {
          deleteFlow: 'Supprimer le flux',
          wantToDeleteThisFlow: 'Êtes-vous sûr de vouloir supprimer ce flux?',
        }
      },
      widgets: {
        manageWidgets: 'Gérer les widgets',
        widgetsAreRoutingTools: 'Les widgets sont les outils de routage pour augmenter les utilisations du bot',
        needHelp: 'Besoin d`aide?',
        wantToMakeChanges: 'Vous voulez apporter des modifications?',
        editIcon: 'Icône de modification',
        discard: 'Jeter',
        updateWidget: 'Mettre à jour le widget',
        bpDropDown: {
          selectIcons: 'Sélectionner les icônes',
          iconSize: 'Taille de l`icône',
          iconPlacement: 'Placement d`icône',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Paramètres de bot télégramme',
      chatFlowsHeader: 'Flux de discussion',
      telegramProfileHeader: 'Profil de télégramme',
      widgetsHeader: 'Widgets',
      broadcastHeader: 'Diffuser',
      dripCampaignHeader: 'Campagne goutte à goutte',
      generalHeader: 'Général',
      telegramAutomation: 'Telegram Automation',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Créez un parcours d`intégration du client à l`aide d`une campagne goutte à goutte' +
          ' de flux de discussion planifiés.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Personnalisez les modèles de flux de chat et créez plusieurs flux de chat' +
          ' pour différents publics cibles.',
      },
      broadcasts: {
        createBroadcast: 'Créer une diffusion',
        youCanCreateMutlipleBroadcasts: 'Vous pouvez créer plusieurs émissions',
        broadcastName: 'Nom de diffusion',
        broadcastNameIsRequired: 'Le nom de diffusion est requis',
        continue: 'Continuer',
        manageBroadcasts: 'Gérer les émissions',
        youCanCreateMultipleBroadcastsWith: 'Vous pouvez créer plusieurs diffusions avec plusieurs modèles planifiés pour différents Groupes de clientèle.',
        createNewBroadcast: 'Créer une nouvelle émission',
        name: 'Nom',
        segment: 'Groupe',
        template: 'Modèle',
        contacts: 'Contacts',
        send: 'Envoyer',
        failed: 'Échoué',
        broadcastAt: 'Diffuser',
        status: 'Statut',
        actions: 'Actions',
        nA: 'N / A',
        deleteBroadcast: 'Supprimer la diffusion',
        wantToDeleteThisBroadcast: 'Êtes-vous sûr de vouloir supprimer cette diffusion?',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          searchHere: 'Cherche ici',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Supprimer plusieurs émissions',
          deleteBroadcast: 'Supprimer la diffusion',
        }
      },
      createBroadcastTelegram: {
        broadcastName: 'Nom de diffusion',
        broadcastNameIsRequired: 'Le nom de diffusion est requis',
        flow: 'Couler',
        segments: 'Groupes',
        whenToSend: 'Quand envoyer',
        selectDate: 'Sélectionner une date',
        selectTimeSlot: 'Sélectionnez le plage horaire',
        cancel: 'Annuler',
        createBroadcast: 'Créer une diffusion',
        now: 'Maintenant',
        later: 'Plus tard',
        placeholders: {
          enterBroadcastName: 'Entrez le nom de la diffusion',
          selectFlow: 'Sélectionner le flux',
          searchFlowByName: 'Rechercher le flux par nom',
          selectSegments: 'Sélectionner des Groupes',
          searchSegmentByName: 'Rechercher le Groupe par nom',
          selectTimeSlot: 'Sélectionnez le plage horaire',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Sélectionnez la date de début',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Créer une campagne d`égouttement',
        youCanCreateMultipleCampaigns: 'Vous pouvez créer plusieurs campagnes',
        dripCampaignName: 'Nom de la campagne goutte à goutte',
        dripCampaignNameIsRequired: 'Le nom de campagne goutte à goutte est requis',
        continue: 'Continuer',
        manageDripCampaign: 'Gérer la campagne d`égouttement',
        youCanCreateMultipleDripCampaignsWith: 'Vous pouvez créer plusieurs campagnes DRIP avec des messages automatisés planifiés pour déclencher des réponses à partir de Groupes d`utilisateurs spécifiques.',
        createNewDripCampaign: 'Créer une nouvelle campagne d`égouttement',
        campaignName: 'Nom de la campagne',
        appliedOn: 'Appliqué sur',
        segment: 'Groupe',
        flow: 'Couler',
        startDate: 'Date de début',
        action: 'Action',
        nA: 'N / A',
        deleteDripCampaign: 'Supprimer les campagnes DRIP',
        wantToDeleteThisDripCampaign: 'Êtes-vous sûr de vouloir supprimer ces campagnes goutte à goutte?',
        placeholders: {
          enterDripCampaignName: 'Entrez le nom de la campagne goutte à goutte',
          searchByName: 'Recherche par nom',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Supprimer plusieurs campagnes DRIP',
          deleteDripCampaign: 'Supprimer la campagne d`égouttement',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Nom de la campagne goutte à goutte',
          appliedOn: 'Appliqué sur',
          segments: 'Groupes',
          startDate: 'Sélectionner une date',
          scheduleYourFlows: 'Planifiez vos flux',
          schedule: 'Calendrier',
          flow: 'Couler',
          sendAfter: 'Envoyer après',
          cancel: 'Annuler',
          createDripCampaign: 'Créer une campagne d`égouttement',
          newSubscriber: 'Nouvel abonné',
          days: 'Jours',
          minutes: 'Minutes',
          hours: 'Heures',
          placeholders: {
            enterDripName: 'Entrez le nom de goutte',
            selectAppliedOn: 'Sélectionner appliqué sur',
            selectSegment: 'Sélectionner le Groupe',
            searchByName: 'Recherche par nom',
            selectFlow: 'Sélectionner le flux',
            searchFlowByName: 'Rechercher le flux par nom',
          },
          dateTimePicker: {
            selectStartDate: 'Sélectionnez la date de début',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Nom',
        email: 'E-mail',
        phone: 'Téléphone',
        errorCode: 'Code d`erreur',
        errorMessage: 'Message d`erreur',
        nA: 'N / A',
      },
      flows: {
        chatFlows: 'Flux de discussion',
        createChatFlow: 'Créer un flux de discussion',
        youCanCreateMultipleChatFlows: 'Vous pouvez créer plusieurs flux de conversations sur plusieurs canaux pour les tests et diffusions A / B.',
        flowName: 'Nom de flux',
        nameIsRequired: 'Le nom est requis',
        cancel: 'Annuler',
        createFlow: 'Créer un flux',
        importFlow: 'Flux d`importation',
        createNewFlow: 'Créer un nouveau flux',
        // flowName: 'Nom de flux',
        ofMessages: ' des messages',
        createdOn: 'Créé sur',
        lastModified: 'Dernière modifié',
        defaultFlow: 'Flux par défaut',
        action: 'Action',
        exportStatus: 'Statut d`exportation',
        flowExport: 'Exportation',
        inProgress: 'en cours',
        available: 'Disponible',
        initiated: 'Initié',
        download: 'Télécharger',
        // importFlow: 'Flux d'importation',
        beforeYouStartUploadPleaseMakeSure: 'Avant de démarrer le téléchargement, assurez-vous',
        point1: '1. La limite de téléchargement de taille de fichier maximale est de 1 Mo',
        point2: '2. Le format de fichier doit être en JSON',
        selectFile: 'Choisir le dossier',
        placeholders: {
          enterFlowName: 'Entrer le nom du flux',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Importer le flux pour Instagram Bot',
          dateAndTimeOfCreationOfChatFlow: 'Date et heure de la création du flux de chat',
          dateAndTimeWhenChatFlowLastModified: 'Date et heure où le flux de chat a été modifié pour la dernière fois',
          TOOLTIP_CREATE_FLOW: 'Cliquez pour créer un nouveau flux de chat',
          TOOLTIP_FLOW_NAME: 'Entrez le nom du flux de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nom du flux de chat',
          TOOLTIP_NO_OF_MESSAGES: 'Nombre de composants de message dans le flux de chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilisez cette bascule pour définir un flux de chat par défaut, qui sera servi à tous les nouveaux utilisateurs.',
          TOOLTIP_ACTION: 'Modifier ou supprimer le flux de chat',
        },
        noDataFound: {
          flows: 'Les flux',
          noFlowsAddedYet: 'Aucun flux ajouté encore!',
        },
        modal: {
          deleteFlow: 'Supprimer le flux',
          wantToDeleteThisFlow: 'Êtes-vous sûr de vouloir supprimer ce flux?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Profil de télégramme',
        manageYourBotProfile: 'Gérez votre profil de bot comme vous le souhaitez.',
        allSetNoActionRequired: 'Tout ensemble, aucune action requise!',
        congratulationYourAccountIsLive: 'Toutes nos félicitations! Votre compte est en direct avec un accès complet',
        botDetails: 'Détails du bot',
        name: 'Nom',
        nA: 'N / A',
        b: 'B',
        botName: 'Nom de bot',
        bot: 'Bot',
        info: 'Info',
        none: 'Aucun',
        username: 'Nom d`utilisateur',
        notifications: 'Notifications',
        on: 'Sur',
        addToGroupOrChannel: 'Ajouter au groupe ou au canal',
        userName: 'Nom d`utilisateur',
        botToken: 'Jeton de bot',
      },
      widgets: {
        manageWidgets: 'Gérer les widgets',
        widgetsAreRoutingTools: 'Les widgets sont les outils de routage pour augmenter les utilisations du bot',
        needHelp: 'Besoin d`aide?',
        wantToMakeChanges: 'Vous voulez apporter des modifications?',
        editIcon: 'Icône de modification',
        discard: 'Jeter',
        updateWidget: 'Mettre à jour le widget',
        installChatBotIconOnYourWebsite: 'Installez l`icône chatbot sur votre site Web',
        shareWhereEverYouWantRedirectUsersTo: 'Partagez où vous voulez et redirigez les utilisateurs vers votre bot télégramme en un seul clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copiez et ajoutez le fichier d`en-tête de votre site Web',
        copyLinkAndStartSharing: 'Copiez le lien et commencez à partager!',
        downloadQrCodeAndStartSharing: 'Téléchargez le code QR et commencez à partager!',
        addCodeAndDeployNewCodeAndDone: 'Ajoutez le code, déployez un nouveau code et fait!',
        wantToLearnHowToUseIt: 'Vous voulez apprendre à l`utiliser?',
        copyChatBotCode: 'Copier le code du chatbot',
        downloadQrCode: 'Télécharger le code QR',
        copyTelegramLink: 'Copier le lien télégramme',
        link: 'Lien',
        chatBotIcon: 'Icône de chatbot',
        qrcode: 'QR Code',
        small: 'Petit',
        medium: 'Moyen',
        large: 'Grand',
        leftMiddle: 'Gauche à gauche',
        rightMiddle: 'À droite',
        leftBottom: 'En bas à gauche',
        rightBottom: 'Fondateur',
        bpDropDown: {
          selectIcons: 'Sélectionner les icônes',
          iconSize: 'Taille de l`icône',
          iconPlacement: 'Placement d`icône',
        }
      },
      generalSetting: {
        sessionTimeOutInHours: 'Expiration de session',
        sendInactivityMessage: 'Envoyer un message d\'inactivité',
        enterSessionTimeOutWindowInHours: `Entrez la fenêtre d'expiration de session en heures`,
        sessionTimeOutInHoursTooltip: `Entrez la durée après laquelle la session de chat expirera automatiquement en raison de l'inactivité. Le flux de chat redémarrera si l'utilisateur envoie un message après l'expiration de la session.`,
        restartChatFlowTriggers: 'Déclencheurs de redémarrage du flux de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Entrez des mots-clés pour redémarrer le chat et appuyez sur Entrée',
        restartChatFlowTriggersTooltip: `Spécifiez ici les mots-clés qui, lorsqu'ils sont saisis par l'utilisateur et soumis, déclencheront le redémarrage du flux de chat. Cela permet aux utilisateurs de redémarrer la conversation à tout moment.
         Appuyez sur Entrée après avoir ajouté chaque mot-clé pour en ajouter plusieurs.`,
        liveChatTriggers: 'Déclencheurs de chat en direct',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Entrez des mots-clés pour déclencher le chat en direct et appuyez sur Entrée',
        liveChatTriggersTooltip: `Définissez les mots-clés qui, lorsqu'ils sont saisis par l'utilisateur et soumis, initieront une transition du chat automatisé vers le chat en direct. C'est utile lorsque les utilisateurs ont besoin de l'aide d'un agent en direct.
         Appuyez sur Entrée après avoir ajouté chaque mot-clé pour en ajouter plusieurs.`,
        messageAfterAutomatedFlowCompleted: 'Message après la fin du flux automatisé',
        enterYourMessage: 'Entrez votre message',
        messageAfterAutomatedFlowCompletedTooltip: `Saisissez un message qui sera affiché à l'utilisateur après avoir terminé le flux de chat automatisé, mais que la session n'a pas expirée.`,
        restart: 'Redémarrer',
        liveChat: 'Chat en Direct',
        lineOneForDefaultMessage: `Si vous avez plus de questions ou avez besoin d'aide, voici ce que vous pouvez faire ensuite:`,
        to: 'À',
        restartTheChat: 'redémarrer le chat',
        simpleTypeRestartAndHitEnter: `il suffit de taper 'Redémarrer' et d'appuyer sur Entrée. Cela vous ramènera au début de notre conversation.`,
        lineThreeForDefaultMessagePartOne: `Si vous souhaitez parler à un`,
        liveAgent: `agent en direct,`,
        lineThreeForDefaultMessageLastPart: `tapez 'Chat en Direct' et appuyez sur Entrée. Notre équipe est là pour vous aider en temps réel.`,
        saveChanges: 'Enregistrer les modifications',
        discard: 'Annuler',
        configureBotsGeneralSettings: 'Configurez les paramètres généraux de votre bot',
        minutes: 'Minutes',
        hours: 'Heures',

      }
    },
    whatsapp: {
      whatsappAutomation: 'Automatisation WhatsApp',
      generalHeader: 'Général',
      whatsappBotSettings: 'Paramètres de bot WhatsApp',
      chatFlowsHeader: 'Flux de discussion',
      whatsappProfileHeader: 'Profil WhatsApp',
      broadcastHeader: 'Diffuser',
      dripCampaignHeader: 'Campagne goutte à goutte',
      widgetHeader: 'Widget',
      templatesHeader: 'Modèles',
      alertsHeader: 'Alertes',
      apiKeyHeader: 'clé API',
      catalogHeader: 'Catalogue',
      otherConfiguration: 'Paramètres IA',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Personnalisez les modèles de flux de chat et créez plusieurs' +
          ' flux de chat pour différents publics cibles.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Configurez les paramètres d`alerte de votre bot',
        specifyHowYouBotWillNotify: 'Spécifiez comment votre bot vous informera des nouveaux prospects.',
        subject: 'Sujet',
        emailNotification: 'Notification par e-mail',
        emailAddresses: 'Adresses mail',
        leadRevisitNotifications: 'Notifications de révision du lead',
        whenToSendLeadInfo: 'Quand envoyer des informations de plomb',
        discard: 'Jeter',
        saveChanges: 'Sauvegarder les modifications',
        seeHowToUse: 'Voir comment utiliser',
        beginningTheFlow: 'Commencer le flux',
        fiveMinutesLater: '5 minutes plus tard',
        endOfFlow: 'Fin de flux',
        placeholders: {
          enterSubjectForYourEmail: 'Entrez le sujet pour votre e-mail',
          typeEmailAndHitEnter: 'Tapez votre adresse e-mail et appuyez sur Entrée',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Personnalisez le sujet de vos notifications par e-mail à partir du bot',
          TOOLTIP_EMAIL_NOTIFICATION: 'Activer / désactiver la notification du bot sur votre e-mail pour de nouvelles prospects',
          TOOLTIP_EMAIL_ADDRESS: 'Soumettez l`adresse e-mail du compte où vous souhaitez recevoir des notifications par e-mail. Vous pouvez ajouter plusieurs adresses e-mail en entrant une et en appuyant sur Entrée pour en ajouter une autre.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: ' Activer / désactiver la notification de Bot sur votre e-mail si un utilisateur précédemment capturé comme un lead interagit à nouveau avec le bot.',
        }
      },
      editProfile: {
        updateWhatsappChatBot: 'Mettre à jour le chatbot WhatsApp',
        businessNameIsRequired: 'Le nom de l`entreprise est requis.',
        maxLengthForBusinessName40Characters: 'La longueur maximale pour le nom de l`entreprise est de 40 caractères.',
        categoryIsRequired: 'Une catégorie est requise.',
        descriptionIsRequired: 'Description est requise.',
        maxLengthForDescription200Characters: 'La longueur maximale pour la description est de 200 caractères.',
        aboutIsRequired: 'Est requis.',
        maxLengthForAbout139Characters: 'La longueur maximale pour environ 139 caractères.',
        pleaseFillAValidBusinessEmail: 'Veuillez remplir un e-mail commercial valide.',
        businessEmailIsRequired: 'Un e-mail d`entreprise est requis.',
        websiteIsRequired: 'Le site Web est requis.',
        maxLengthForWebsite40Characters: 'La longueur maximale pour le site Web est de 40 caractères.',
        maxLengthForAddress150Characters: 'La longueur maximale pour l`adresse est de 150 caractères.',
        cancel: 'Annuler',
        update: 'Mise à jour',
        businessCategory: 'Catégorie Business',
        placeholders: {
          yourBusinessName: 'Nom de votre entreprise',
          businessDescription: 'Description de l`activité',
          about: 'À propos',
          businessEmail: 'E-mail d`entreprise',
          website: 'Site Web (par exemple www.example.com)',
          address: 'Adresse',
        }
      },
      flows: {
        chatFlows: 'Flux de discussion',
        createChatFlow: 'Créer un flux de discussion',
        youCanCreateMultipleChatFlows: 'Vous pouvez créer plusieurs flux de conversations sur plusieurs canaux pour les tests et diffusions A / B.',
        flowName: 'Nom de flux',
        nameIsRequired: 'Le nom est requis',
        cancel: 'Annuler',
        createFlow: 'Créer un flux',
        importFlow: 'Flux d`importation',
        createNewFlow: 'Créer un nouveau flux',
        ofMessages: ' des messages',
        createdOn: 'Créé sur',
        lastModified: 'Dernière modifié',
        defaultFlow: 'Flux par défaut',
        revisitFlow: 'Revisiter le flux',
        action: 'Action',
        exportStatus: 'Statut d`exportation',
        flowExport: 'Exportation',
        // importFlow: 'Flux d'importation',
        beforeYouStartUploadPleaseMakeSure: 'Avant de démarrer le téléchargement, assurez-vous',
        point1: '1. La limite de téléchargement de taille de fichier maximale est de 1 Mo',
        point2: '2. Le format de fichier doit être en JSON',
        selectFile: 'Choisir le dossier',
        seeHowToUse: 'Voir comment utiliser',
        download: 'Télécharger',
        available: 'Disponible',
        initiated: 'Initié',
        inProgress: 'en cours',
        placeholders: {
          enterFlowName: 'Entrer le nom du flux',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Flux d`importation pour WhatsApp Bot',
          dateAndTimeOfCreationOfChatFlow: 'Date et heure de la création du flux de chat',
          dateAndTimeWhenChatFlowLastModified: 'Date et heure où le flux de chat a été modifié pour la dernière fois',
          TOOLTIP_CREATE_FLOW: 'Cliquez pour créer un nouveau flux de chat',
          TOOLTIP_FLOW_NAME: 'Entrez le nom du flux de chat',
          TOOLTIP_FLOW_TABLE_NAME: 'Nom du flux de chat',
          TOOLTIP_NO_OF_MESSAGES: 'Nombre de composants de message dans le flux de chat',
          TOOLTIP_DEFAULT_FLOW: 'Utilisez cette bascule pour définir un flux de chat par défaut, qui sera servi à tous les nouveaux utilisateurs.',
          TOOLTIP_REVISIT_FLOW: 'Utilisez cette bascule pour sélectionner un flux de chat à servir aux utilisateurs lorsqu`ils revisitent votre chatbot.',
          TOOLTIP_ACTION: 'Modifier ou supprimer le flux de chat',
        },
        modal: {
          deleteFlow: 'Supprimer le flux',
          wantToDeleteThisFlow: 'Êtes-vous sûr de vouloir supprimer ce flux?',
        },
        noDataFound: {
          flows: 'Les flux',
          noFlowsAddedYet: 'Aucun flux ajouté encore!',
        }
      },
      input: {
        manageTemplates: 'Gérer les modèles',
        manageYourTemplatesForMarketingAutomation: 'Gérez vos modèles pour l`automatisation marketing',
        syncTemplates: 'Modèles de synchronisation',
        createTemplateHeader: 'Créer un modèle',
        title: 'Titre',
        info: 'Info',
        category: 'Catégorie',
        status: 'Statut',
        action: 'Action',
        shipmentUpdate: 'Mise à jour de l`expédition',
        name: 'Nom',
        // category: 'Catégorie',
        headerOptional: 'En-tête (facultatif)',
        bodyRequired: 'Corps (requis)',
        footerOptional: 'Pied de page (facultatif)',
        buttonsOptional: 'Boutons (facultatif)',
        makeUpto3Buttons: 'Faire jusqu`à 3 boutons',
        makeUpto2ButtonsToRedirect: 'Faites jusqu`à 2 boutons pour rediriger le client vers votre site Web',
        placeholders: {
          nameYourTemplate: 'Nommez votre modèle (utilisez un soulignement pour séparer les mots)',
          enterText: 'Entrez du texte (max 60 caractères)',
          enterImageUrl: 'Entrez l`URL de l`image',
          enterVideoUrl: 'Entrez l`URL vidéo',
          templateMessage: 'Message de modèle',
          smallFooterAtBottom: 'Petit pied de page en bas',
          buttons: 'Boutons',
          text: 'Texte',
          numberWithCountryCode: 'Numéro avec code de pays',
          enterButtonText: 'Entrez le texte du bouton (caractère max 20)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'Titre du modèle WhatsApp',
          TOOLTIP_INPUT_TABLE_INFO: 'Des informations détaillées sur les messages initiés par l`entreprise',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Type de messages initiés par l`entreprise comme les rendez-vous, les rappels, les messages de service client, les mises à jour d`expédition, les alertes et plus',
          TOOLTIP_INPUT_TABLE_STATUS: 'L`état du modèle est rejeté ou approuvé par WhatsApp. Les modèles WhatsApp doivent être approuvés pour la première fois par WhatsApp et il s`agit d`un service payant.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Supprimer le modèle',
        },
        noDataFound: {
          templates: 'Modèles',
          noTemplatesCreatedYet: 'Pas encore de modèles créés!',
        },
        modal: {
          deleteTemplate: 'Supprimer',
          wantToDeleteThisTemplate: 'Êtes-vous sûr de vouloir supprimer ce modèle?',
        },
        createTemplate: {
          createTemplates: 'Créer des modèles',
          createTemplatesForMarketingAutomation: 'Créez vos modèles pour l`automatisation marketing',
          templateMeta: 'Modèle de méta',
          selectLanguage: 'Choisir la langue',
          header: 'Entête',
          optional: 'Facultatif',
          samplesForHeaderContent: 'Échantillons pour le contenu de l`en-tête',
          toHelpMetaReviewYourContent: 'Pour aider Meta à consulter votre contenu, fournissez des exemples de variables ou de supports dans l`en-tête. N`incluez aucune information client.',
          body: 'Corps',
          addVariable: 'Ajouter une variable',
          samplesForBodyContent: 'Échantillons pour le contenu du corps',
          toHelpUsReviewYourContent: 'Pour nous aider à revoir votre contenu, fournissez des exemples des variables du corps. N`incluez aucune information client.',
          footer: 'Bas de page',
          // optional: 'Facultatif',
          buttons: 'Boutons',
          // optional: 'Facultatif',
          typeOfAction: 'Type d`action',
          call: 'Appel',
          phone: 'Téléphone',
          website: 'Site Internet',
          static: 'Statique',
          urlType: 'Type d`URL',
          type: 'Taper',
          customButton: 'Bouton personnalisé',
          addAnotherButton: 'Ajouter un autre bouton',
          preview: 'Aperçu',
          test: 'Test',
          create: 'Créer',
          addSampleUrl: 'Ajouter une URL de l`échantillon',
          toHelpMetaReviewYourMessage: 'Pour aider Meta à consulter votre modèle de message, veuillez ajouter un exemple de l`URL du site Web. N`utilisez pas de véritables informations clients.',
          placeholders: {
            enterMessageTemplateName: 'Entrez le nom du modèle de message',
            searchTemplateByName: 'Rechercher le modèle par nom',
            selectTemplate: 'Sélectionner le modèle',
            enterText: 'Entrez du texte',
            enterBody: 'Entrer dans le corps',
            enterContentForVariable: 'Entrez le contenu pour la variable',
            enterFooter: 'Entrer le pied de page',
            phone: 'Téléphone*',
            enterWebsiteUrl: 'Entrez l`URL du site Web',
          },
          bpInput: {
            name: 'Nom',
            buttonText: 'Texte du bouton',
            websiteUrl: 'URL de site web',
          },
          bpDropDown: {
            selectCategory: 'Choisir une catégorie',
          }
        },
        templatePreview: {
          preview: 'Aperçu',
          test: 'Test',
        },
        templateConfig: {
          discard: 'Jeter',
          saveChanges: 'Sauvegarder les modifications',
          triggerChatFlowOnTemplateReply: `Déclencher un flux de discussion lors d'une réponse de modèle`,
          templateDetails: 'Détails du modèle',
          configureFlows: 'Configurer les flux',
          selectFlow: 'Sélectionner le Flux',
          name: 'Nom'
        }
      },
      shopManagement: {
        shopManagement: 'Gestion du magasin',
        manageECommerceShopFromBotPenguin: 'Gérer les magasins de commerce électronique liés à BotPenguin',
        comingSoon: '( À venir )',
        wooCommerce: {
          createConfiguration: 'Créer une configuration',
          eventConfiguration: {
            createConfiguration: 'Créer une configuration',
            templateHeader: 'En-tête de modèle',
            selectFile: 'Choisir le dossier',
            templateMessage: 'Message de modèle',
            saveChanges: 'Sauvegarder les modifications',
            bpDropDown: {
              selectEvent: 'Sélectionner l`événement',
              selectTemplate: 'Sélectionner le modèle',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'Profil WhatsApp',
        manageYourBotProfile: 'Gérez votre profil de bot comme vous le souhaitez.',
        allSetNoActionRequired: 'Tout ensemble, aucune action requise!',
        congratulationsYourAccountIsLive: 'Toutes nos félicitations! Votre compte est en direct avec un accès complet',
        somethingIsWrongReactivateYourBot: 'Quelque chose ne va pas, réactivez votre bot!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Il semble que votre bot ne fonctionne pas correctement, veuillez réinitialiser votre bot',
        reConfigureYourBot: 'Re-Configurez votre bot',
        phoneNumberDetails: 'Détails du numéro de téléphone',
        phoneNumber: 'Numéro de téléphone',
        nA: 'N / A',
        phoneNumberId: 'ID de numéro de téléphone',
        wabaId: 'Waba id',
        change: 'Changement',
        accessToken: 'Jeton d`accès',
        // change: 'Changement',
        messagingLimit: 'Limite de messagerie',
        qualityRating: 'Note de qualité',
        whatsappBusinessAccountName: 'Nom du compte commercial WhatsApp',
        whatsappPublicProfile: 'Profil public WhatsApp',
        profilePicture: 'Image de profil',
        aboutText: 'À propos du texte',
        address: 'Adresse',
        description: 'Description',
        email: 'E-mail',
        websitePrimary: 'Site Web (primaire)',
        websiteSecondary: 'Site Web (secondaire)',
        businessVertical: 'Entreprise verticale',
        editProfile: 'Editer le profil',
        thisIsABusinessAccount: 'Ceci est un compte commercial',
        aboutAndPhoneNumber: 'About et numéro de téléphone',
        yourTrialAccountIsLive: 'Toutes nos félicitations! Votre compte d`essai est en direct avec un accès complet sur votre numéro vérifié',
        updateWithNewAccessToken: 'Mise à jour avec un nouvel AccessToken',
      },
      widgets: {
        manageWidgets: 'Gérer les widgets',
        widgetsAreRoutingTools: 'Les widgets sont les outils de routage pour augmenter les utilisations du bot',
        needHelp: 'Besoin d`aide?',
        wantToMakeChanges: 'Vous voulez apporter des modifications?',
        prePopulatedMessage: 'Message pré-peuplé',
        discard: 'Jeter',
        updateWidget: 'Mettre à jour le widget',
        hiThere: 'Bonjour à tous',
        seeHowToUse: 'Voir comment utiliser',
        editIcon: 'Icône de modification',
        editPrePopulatedMessage: 'Modifier le message pré-peuplé',
        small: 'Petit',
        medium: 'Moyen',
        large: 'Grand',
        leftMiddle: 'Gauche à gauche',
        rightMiddle: 'À droite',
        leftBottom: 'En bas à gauche',
        rightBottom: 'Fondateur',
        chatBotIcon: 'Icône de chatbot',
        link: 'Lien',
        qrcode: 'QR Code',
        copyChatBotCode: 'Copier le code du chatbot',
        copyWALink: 'Copier le lien WA',
        downloadQrCode: 'Télécharger le code QR',
        installChatBotIconOnYourWebsite: 'Installez l`icône chatbot sur votre site Web',
        shareWhereEverYouWantRedirectUsersTo: 'Partagez où vous voulez et redirigez les utilisateurs vers votre bot WhatsApp en un seul clic',
        copyAndAddInYourWebsiteHeaderFile: 'Copiez et ajoutez le fichier d`en-tête de votre site Web',
        copyLinkAndStartSharing: 'Copiez le lien et commencez à partager!',
        downloadQrCodeAndStartSharing: 'Téléchargez le code QR et commencez à partager!',
        addCodeAndDeployNewCodeAndDone: 'Ajoutez le code, déployez un nouveau code et fait!',
        wantToLearnHowToUseIt: 'Vous voulez apprendre à l`utiliser?',
        placeholders: {
          enterWhatsappNumber: 'Entrez le numéro WhatsApp',
          updateYourMessageHere: 'Mettez à jour votre message ici ...',
        },
        bpDropDown: {
          selectIcons: 'Sélectionner les icônes',
          iconSize: 'Taille de l`icône',
          iconPlacement: 'Placement d`icône',
        },
        bpInput: {
          whatsappNumber: 'Numéro WhatsApp',
        }
      },
      catalog: {
        createCatalog: 'Créer un Catalogue',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Créer un catalogue de produits sur le gestionnaire de commerce Meta ou le lier avec Shopify',
        useYourMetaCommerceManagertoManageCatalogs: 'Utiliser votre gestionnaire de commerce Meta pour gérer les catalogues',
        connectCatalogToWhatsAppAccount: 'Connecter le catalogue au compte WhatsApp',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Connecter vos catalogues au compte WhatsApp en utilisant votre gestionnaire WhatsApp',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Utiliser votre gestionnaire Meta WhatsApp pour connecter les catalogues et les utiliser avec BotPenguin',
        goToMeta: 'Aller à Meta',
        goToShopify: 'Aller à Shopify',
        next: 'Suivant',
        back: 'Retour',
        setupCatalog: 'Configurer le Catalogue',
        completeAllTheStepsToEnableCatalogMessages: 'Complétez toutes les étapes pour activer les messages de catalogue'
      },
      generalSetting: {
        sessionTimeOutInHours: 'Expiration de session',
        sendInactivityMessage: 'Envoyer un message d\'inactivité',
        enterSessionTimeOutWindowInHours: `Entrez la fenêtre d'expiration de session en heures`,
        sessionTimeOutInHoursTooltip: `Entrez la durée après laquelle la session de chat expirera automatiquement en raison de l'inactivité. Le flux de chat redémarrera si l'utilisateur envoie un message après l'expiration de la session.`,
        restartChatFlowTriggers: 'Déclencheurs de redémarrage du flux de chat',
        enterKeyWordsToRestartChatAndhitEnter: 'Entrez des mots-clés pour redémarrer le chat et appuyez sur Entrée',
        restartChatFlowTriggersTooltip: `Spécifiez ici les mots-clés qui, lorsqu'ils sont saisis par l'utilisateur et soumis, déclencheront le redémarrage du flux de chat. Cela permet aux utilisateurs de redémarrer la conversation à tout moment.
         Appuyez sur Entrée après avoir ajouté chaque mot-clé pour en ajouter plusieurs.`,
        liveChatTriggers: 'Déclencheurs de chat en direct',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Entrez des mots-clés pour déclencher le chat en direct et appuyez sur Entrée',
        liveChatTriggersTooltip: `Définissez les mots-clés qui, lorsqu'ils sont saisis par l'utilisateur et soumis, initieront une transition du chat automatisé vers le chat en direct. C'est utile lorsque les utilisateurs ont besoin de l'aide d'un agent en direct.
         Appuyez sur Entrée après avoir ajouté chaque mot-clé pour en ajouter plusieurs.`,
        messageAfterAutomatedFlowCompleted: 'Message après la fin du flux automatisé',
        enterYourMessage: 'Entrez votre message',
        messageAfterAutomatedFlowCompletedTooltip: `Saisissez un message qui sera affiché à l'utilisateur après avoir terminé le flux de chat automatisé, mais que la session n'a pas expirée.`,
        restart: 'Redémarrer',
        liveChat: 'Chat en Direct',
        lineOneForDefaultMessage: `Si vous avez plus de questions ou avez besoin d'aide, voici ce que vous pouvez faire ensuite:`,
        to: 'À',
        restartTheChat: 'redémarrer le chat',
        simpleTypeRestartAndHitEnter: `il suffit de taper 'Redémarrer' et d'appuyer sur Entrée. Cela vous ramènera au début de notre conversation.`,
        lineThreeForDefaultMessagePartOne: `Si vous souhaitez parler à un`,
        liveAgent: `agent en direct,`,
        lineThreeForDefaultMessageLastPart: `tapez 'Chat en Direct' et appuyez sur Entrée. Notre équipe est là pour vous aider en temps réel.`,
        saveChanges: 'Enregistrer les modifications',
        discard: 'Annuler',
        configureBotsGeneralSettings: 'Configurez les paramètres généraux de votre bot',
        minutes: 'Minutes',
        hours: 'Heures',

      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'À un pas',
      seeHowToUse: 'Voir comment utiliser',
      testWhatsAppTrialBot: 'Testez votre Chatbot d\'essai WhatsApp',
      sendMessage: 'Envoyer un message',
      sendWhatsAppMessageOn: 'Envoyez-nous un message WhatsApp sur ',
      bySavingInYourContact: 'en l\'enregistrant dans vos contacts et le message devrait commencer par ',
      connect: 'Connecter',
      or: 'OU',
      scanQRCode: 'Scanner le code QR',
      yourPhoneWillOpen: 'Votre téléphone ouvrira un message pré-rempli à envoyer à notre numéro Sandbox. Appuyez sur \'Envoyer\' sur WhatsApp',
      sendVerificationCode: 'Envoyer le code de vérification',
      copyAndEnterCode: 'Copiez et entrez le code à 6 chiffres',
      shareWhereYouWant: 'Partagez où vous voulez et redirigez les utilisateurs vers votre bot WhatsApp en un seul clic',
      youShouldHaveReceived: 'Vous auriez dû recevoir un code à 6 chiffres sur votre numéro WhatsApp',
      EnterYourPhoneNumber: 'Entrez votre numéro de téléphone',
      send: 'Envoyer',
      sixDigitCode: 'Entrez le code à 6 chiffres',
      pleaseNote: 'Veuillez noter : Le code de vérification à 6 chiffres peut prendre jusqu\'à 3 minutes. Veuillez attendre avant de le renvoyer.',
      resendVerificationCode: 'Renvoyer le code de vérification',
      verify: 'Vérifier',
      providePhoneNumber: 'Veuillez fournir le numéro que vous souhaitez utiliser pour les tests',
      demoBotisReady: 'Super ! Votre bot de démonstration est prêt à être utilisé',
      placeHolder: {
        phone: 'Entrez votre numéro de téléphone',
        code: 'Entrez le code de vérification',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Intégration des applications tierces',
    thirdPartyApps: 'Applications tierces',
    customApps: 'Applications personnalisées',
    preview: 'Aperçu',
    test: 'Test',
    install: 'Installer',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Cliquez pour installer votre bot sur la plate-forme sélectionnée',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Intégrez vos applications créées personnalisées à votre bot',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Connectez votre bot avec les applications / CRMS tiers disponibles',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Intégrations d`applications tierces',
        integrateYourBotWithListedCrms: 'Intégrez votre bot avec l`une des applications / CRM répertoriés.',
        help: 'Aider',
        name: 'Nom',
        status: 'Statut',
        action: 'Action',
        chatGpt: 'Chatte',
        addOn: 'Ajouter',
        purchased: 'Acheté',
        readyToRideGptWave: 'Prêt à monter sur la vague GPT? Intégrer maintenant.',
        purchase: 'Achat',
        notConnected: 'Pas connecté',
        integrated: 'Intégré',
        configure: 'Configurer',
        connected: 'Connecté',
        edit: 'Modifier',
        connect: 'Connecter',
        requestYourIntegration: 'Demandez votre intégration',
        toRequestNewIntegration: 'Pour demander une nouvelle intégration, veuillez saisir les détails mentionnés ci-dessous, puis nous vous répondrons',
        // name: 'Nom',
        emailId: 'ID de messagerie',
        integrationName: 'Nom d`intégration',
        contactNumber: 'Numéro de contact',
        howSoonYouWantToAddThisIntegration: 'Combien de temps vous souhaitez ajouter cette intégration',
        sendRequest: 'Envoyer une demande',
        cancel: 'Annuler',
        requestSent: 'Demande envoyée!',
        yourRequestSuccessfullySent: 'Votre demande de nouvelle intégration a été envoyée avec succès.',
        goBack: 'Retourner',
        disconnect: 'Se déconnecter',
        OopsIntegrationAreNotAvailable: 'Oups ! Les intégrations ne sont pas disponibles dans le ',
        pleaseUpgradeTo: ' Veuillez passer au ',
        planForIntegration: ' pour les intégrations.',
        placeholders: {
          searchIntegrationByName: 'Rechercher l`intégration par nom',
          enterTeamMemberName: 'Entrez le nom du membre de l`équipe',
          enterEmailId: 'Entrez l`ID de messagerie',
          enterIntegrationName: 'Entrez un nom d`intégration',
          searchCountry: 'Pays de recherche',
          enterContactNumber: 'Entrez le numéro de contact',
          addComment: 'Ajouter un commentaire',
        },
        noDataFound: {
          integrations: 'Intégrations',
          noIntegrationsFound: 'Aucune intégration trouvée!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Nom de l`application / CRM',
          statusAsConnectedOrNotConnected: 'Statut comme connecté / non connecté',
          clickToConnectToAppOrCrm: 'Cliquez pour connecter l`application / CRM',
        },
        feedbackModal: {
          submit: 'Soumettre',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          agileCrmDomain: 'Domaine CRM agile',
          nameIsRequired: 'Le nom est requis',
          emailAddress: 'Adresse e-mail',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameter: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            exampleSite: 'https://company.agilecrm.com',
            exampleEmail: 'exemple@company.extention',
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'Clé API',
          accessKey: 'Clef d`accès',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiKeyHere: 'Entrez la clé de l`API ici',
            enterAccessKeyHere: 'Entrez la clé d`accès ici',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          nameColon: 'Nom:',
          inboundWebhookUrl: 'URL de webhook entrant',
          nameIsRequired: 'Le nom est requis',
          leadTitle: 'Titre de leader',
          source: 'Source',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterInboundWebhookUrl: 'Entrez l`URL de la webhook entrant ...',
            enterTitle: 'Entrer le titre',
            enterSource: 'Entrez la source',
          }
        },
        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          testLead: 'Responsable des Tests',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          testLead: 'Responsable des Tests',
          connect: 'Connecter',
          selectTheQuestion: 'Sélectionnez la question dont vous souhaitez envoyer la réponse de l`utilisateur en tant que valeur',
          mapWith: 'cartographier avec',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
            attributes: 'Attributs (clé)',
            values: 'valeurs',
          }
        },
        chatGpt: {
          chatGptApiKey: 'CHATGPT (Open AI) API Key',
          chatGpt: 'Chatgpt (Open AI)',
          readyToRideWave: 'Prêt à monter sur la vague GPT? Intégrer maintenant.',
          doNotHaveAnyApiKeyNeedHelp: 'Vous n`avez pas de clé API, avez-vous besoin d`aide?',
          authenticate: 'Authentifier',
          nowYouCanAccessChatGpt: 'Vous pouvez maintenant accéder au composant GPT de chat dans le constructeur de flux de chat',
          paymentSuccessful: 'Paiement réussi!',
          paymentHasBeenCompletedSuccessfully: 'Le paiement a été achevé avec succès. Vous êtes prêt à former votre bot',
          cancel: 'Annuler',
          trainNow: 'S`entraîner maintenant',
          paymentInProgress: 'Paiement en cours ...',
          apiKey: 'Chatgpt API Key',
          model: 'Modell',
          integrateWithChatGPT: 'Intégrer avec ChatGPT',
          updateKey: 'Mettre à jour la clé',
          connect: 'Connecter',
          update: 'Mise à jour',
          placeholders: {
            chatGptApiKey: 'CHATGPT (Open AI) API Key',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          leadId: 'ID de plomb',
          selectLeadId: 'Sélectionnez LeadID',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          emailAddress: 'Adresse e-mail',
          leadTitle: 'Titre de leader',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            exampleEmail: 'exemple@company.extention',
            enterYourLeadTitle: 'Entrez votre titre principal',
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          dripCrmAccountId: 'ID de compte CRM Drip',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterAccountIdHere: 'Entrez l`ID de compte ici',
            enterYourTokenHere: 'Entrez votre jeton ici',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apikey: 'Clé API',
          accessToken: 'Jeton d`accès',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          selectParameter: 'Sélectionner le paramètre',
          disconnect: 'Se déconnecter',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          placeholders: {
            enterApiKeyHere: 'Entrez la clé de l`API ici',
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          questionColon: 'Question:',
          testLead: 'Responsable des Tests',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          authToken: 'Authentique',
          source: 'Source',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterAuthTokenHere: 'Entrez Authtoken ici',
            enterSourceHere: 'Entrez la source ici',
          }
        },
        facebookPixel: {
          facebookPixel: 'Pixel Facebook',
          getInsight: `Obtenez des informations qui transforment votre entreprise en une entreprise de premier plan grâce
           à l'intégration de Facebook Pixel de BotPenguin.`,
          pixelId: 'ID pixel Facebook',
          connect: 'connecter',
          disconnect: 'déconnecter',
          placeholders: {
            enterYourId: 'ID du pixel Facebook'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          companyName: 'Nom de l`entreprise',
          emailAddress: 'Adresse e-mail',
          password: 'Mot de passe',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapsWith: 'cartes avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterCompanyName: 'Entrez le nom de l`entreprise',
            enterEmailAddress: 'Entrer l`adresse e-mail',
            enterPassword: 'Entrer le mot de passe',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiKeyHere: 'Entrez Apikey ici',
          }
        },
        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          flowluDomain: 'Domaine Flowlu',
          apiToken: 'Jeton API',
          title: 'Titre',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            exampleDomain: 'https://yourcompany.flowlu.com',
            enterTokenHere: 'Entrez le jeton ici',
            enterYourLeadTitle: 'Entrez votre titre principal',
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          freshdeskDomain: 'Domaine FreshDesk',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            exampleDomain: 'https://yoursubdomain.freshdesk.com',
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          freshworkCrmDomain: 'Domaine CRM frais',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            exampleDomain: 'https://yourcompany.myfreshworks.com',
            enterToken: 'Entrez le jeton ici',
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          mapWith: 'cartographier avec',
          select: 'Sélectionner',
          update: 'Mise à jour',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          campaignList: 'Liste de campagnes',
          selectCampaign: 'Sélectionner la campagne',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          measurementId: 'ID de mesure',
          connect: 'Connecter',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterYourId: 'Entrer votre identifiant',
          }
        },
        googleCalendar: {
          testLead: 'Responsable des Tests',
          name: 'Nom:',
          email: 'E-mail:',
          appointmentSummary: 'Résumé de la nomination',
          appointmentDescription: 'Description de rendez-vous',
          addParameters: ' Ajouter des paramètres',
          questionColon: 'Question:',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          pause: 'Pause',
          resume: 'CV',
          disconnect: 'Se déconnecter',
          reconnect: 'Reconnecter',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          name: 'Nom :',
          email: 'E-mail :',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
        },
        googleRedirect: {
        },
        googleSheet: {
          testLead: 'Responsable des Tests',
          title: 'Titre',
          questionColon: 'Question:',
          addParameters: 'Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          pause: 'Pause',
          resume: 'CV',
          disconnect: 'Se déconnecter',
          reconnect: 'Reconnecter',
          placeholders: {
            mappingValues: 'Valeurs de cartographie',
          }
        },
        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          name: 'Nom:',
          email: 'E-mail:',
          taskList: 'Liste de tâches',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          pause: 'Pause',
          resume: 'CV',
          disconnect: 'Se déconnecter',
          reconnect: 'Reconnecter',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          select: 'Sélectionner',
          addParameters: ' Ajouter des paramètres',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiHere: 'Entrez l`API ici',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          testLead: 'Responsable des Tests',
          accountId: 'Identifiant de compte',
          accessToken: 'Jeton d`accès',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterAccountIdHere: 'Entrez le compte-id ici',
            enterAccessTokenHere: 'Entrez le jeton d`accès ici',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'clé API',
          connect: 'Connecter',
          questionColon: 'Question:',
          testLead: 'Responsable des Tests',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        helpLink: {
          help: 'Aider',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          helpRaceCrmDomain: 'Domaine CRM Helprace',
          emailAddress: 'Adresse e-mail',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          update: 'Mise à jour',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'example@company.extension',
            token: 'Jeton',
          }
        },
        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          domainName: 'Nom de domaine',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiHere: 'Entrez l`API ici',
          }
        },
        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          emailAddress: 'Adresse e-mail',
          apiUrl: 'URL de l`API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          // emailAddress: 'Adresse e-mail',
          selectProjectName: 'Sélectionner le nom du projet',
          selectIssueType: 'Sélectionnez le type de problème',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapsWith: 'cartes avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
            enterEmailAddress: 'Entrer l`adresse e-mail',
            exampleUrl: 'https://example.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiUrl: 'URL de l`API',
          nameIsRequired: 'Le nom est requis',
          apiKey: 'clé API',
          apiId: 'ID API',
          // nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          mapWith: 'cartographier avec',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          selectTheQuestion: 'Sélectionnez la question dont vous souhaitez envoyer la réponse de l`utilisateur en tant que valeur',
          placeholders: {
            enterApiUrl: 'Entrez l`URL de l`API',
            enterApiKey: 'Entrez la touche API',
            enterApiId: 'Entrez l`ID de l`API',
            attributes: 'Attributs (clé)',
            values: 'valeurs',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          accessKey: 'Clef d`accès',
          secretKey: 'Clef secrète',
          connect: 'Connecter',
          apiLogs: 'Journaux API',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          udpate: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          filterApplied: 'filtre appliqué',
          retry: 'Recommencez',
          back: 'Dos',
          filterYourLeads: 'Filtrez vos pistes',
          filterOn: 'Filtrer',
          filterBy: 'Filtrer par',
          discard: 'Jeter',
          applyFilter: 'Appliquer le filtre',
          loadMore: 'Charger plus',
          placeholders: {
            enterAccessKeyHere: 'Entrez la clé d`accès ici',
            enterSecretKeyHere: 'Entrez la clé secrète ici',
            attributes: 'Attributs (clé)',
            values: 'valeurs',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Filtre les données basées sur la réponse',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          apiUrl: 'URL de l`API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiHere: 'Entrez l`API ici',
            exampleUrl: 'https://comapanyname.ladesk.com',
          }
        },
        microsoftTeams: {
          downloadPackage: 'Téléchargez le package d`application Microsoft Teams',
          downloadApp: 'Télécharger l`application',
          wantToLearnHowToUploadIt: 'Vous voulez apprendre à le télécharger?',
          needHelp: 'Besoin d`aide?',
        },
        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiUrl: 'URL de l`API',
          appId: 'Identifiant d`application',
          nameIsRequired: 'L`ID de l`application est requis',
          dataApiKey: 'Clé de l`API de données',
          dataApiKeyIsRequired: 'La clé de l`API de données est requise',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          addParameters: ' Ajouter des paramètres',
          mapWith: 'cartographier avec',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          selectTheQuestion: 'Sélectionnez la question dont vous souhaitez envoyer la réponse de l`utilisateur en tant que valeur',
          placeholders: {
            enterApiUrl: 'Entrez l`URL de l`API',
            enterAppId: 'Entrez l`ID d`application',
            enterDataApiKey: 'Entrez la clé de l`API de données',
            attributes: 'Attributs (clé)',
            pressForMappingQuestion: 'Appuyez sur / pour la question de cartographie',
          }
        },
        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          emailAddress: 'Adresse e-mail',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          leadId: 'ID de plomb',
          selectLeadId: 'Sélectionnez LeadID',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterEmailHere: 'Entrez le courrier électronique ici',
            enterApiKeyHere: 'Entrez Apikey ici',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          noCrmDomain: 'Domaine du nocrm',
          leadTitle: 'Titre de leader',
          titleIsRequired: 'Le titre est requis',
          token: 'Jeton',
          tokenIsRequired: 'Le jeton est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            exampleDomain: 'https://your_subdomain_here.nocrm.io',
            enterYourLeadTitle: 'Entrez votre titre principal',
            enterYourToken: 'Entrez votre jeton',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          emailAddress: 'Adresse e-mail',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
        },
        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          secretToken: 'Token secret',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          appId: 'Identifiant d`application',
          appIdIsRequired: 'L`ID de l`application est requis',
          apiKey: 'clé API',
          apiKeyIsRequired: 'La clé API est requise',
          connect: 'Connecter',
          update: 'Mise à jour',
          disconnect: 'Se déconnecter',
          placeholders: {
            appId: 'Identifiant d`application',
            apiKey: 'clé API',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          clientId: 'Identité du client',
          connect: 'Connecter',
          clientSecret: 'Secret client',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          save: 'Sauvegarder',
          update: 'Mise à jour',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterClientIdHere: 'Entrez l`ID client ici',
            enterClientSecretHere: 'Entrez le secret client ici',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          pipedriverDomain: 'Domaine Pipedriver',
          domainIsRequired: 'Le domaine est requis',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            exampleDomain: 'https://yourcompany.pipedrive.com',
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          apiKeyIsRequired: 'La clé API est requise',
          appKey: 'Clé d`application',
          appKeyIsRequired: 'La clé de l`application est requise',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiKey: 'Entrez la touche API',
            enterAppKey: 'Entrez la touche d`application',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          authId: 'ID d`authe',
          authToken: 'Token Auth',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiHere: 'Entrez l`API ici',
          }
        },
        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiKey: 'Entrez la touche API',
          }
        },
        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          domain: 'Domaine',
          clientId: 'Identité du client',
          clientSecret: 'Secret client',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterDomainHere: 'Entrez le domaine ici',
            enterClientIdHere: 'Entrez l`ID client ici',
            enterClientSecretHere: 'Entrez le secret client ici',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'Clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          addEventsAndTemplates: 'Ajouter des événements et des modèles:',
          add: ' Ajouter',
          question: 'Question',
          reply: 'Répondre',
          requestBody: 'Demander un corps',
          validateJson: 'Valider JSON',
          emailAddress: 'Adresse e-mail',
          // addParameters: ' Ajouter des paramètres',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          selectTheQuestion: 'Sélectionnez la question dont vous souhaitez envoyer la réponse de l`utilisateur en tant que valeur',
          placeholders: {
            enterApiHere: 'Entrez l`API ici',
            enterTemplateId: 'Entrez l`ID de modèle',
            typeYourJsonCodeHere: 'Tapez votre code JSON ici',
          },
          appTooltip: {
            typeYourJsonCode: 'Tapez votre code JSON',
            clickToValidateJsonCode: 'Cliquez pour valider le code JSON',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          userName: 'Nom d`utilisateur',
          licenseKey: 'Clé de licence',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterNameHere: 'Entrez le nom ici',
            enterKeyHere: 'Entrez la clé ici',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez créé l\'intégration avec succès. Maintenant, vous pouvez la configurer.',
          company: 'Société',
          loginId: 'Identifiant de connexion',
          password: 'Mot de passe',
          testLead: 'Chef de test',
          connect: 'Connecter',
          selectTheQuestion: 'Sélectionnez la question dont vous souhaitez envoyer la réponse de l\'utilisateur en tant que valeur',
          mapWith: 'Mapper avec',
          update: 'Mettre à jour',
          save: 'Enregistrer',
          disconnect: 'Déconnecter',
          selectParameter: 'Sélectionner le paramètre',
          placeholders: {
            enterCompanyHere: 'Entrez le nom de la société ici',
            enterLoginIdHere: 'Entrez l\'identifiant de connexion ici',
            enterPasswordHere: 'Entrez le mot de passe ici',
            values: 'Valeurs',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          applicationKey: 'Clé d`application',
          authToken: 'Token Auth',
          connect: 'Connecter',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApplicationKeyHere: 'Entrez la clé d`application ici',
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          accessToken: 'Jeton d`access',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          question: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          webhoperDomain: 'Domaine webhoper',
          domainIsRequired: 'Le domaine est requis',
          companyName: 'Nom de l`entreprise',
          companyIsRequired: 'La société est requise',
          leadSource: 'Source principale',
          leadSourceIsRequired: 'La source de plomb est requise',
          division: 'Division',
          divisionIsRequired: 'La division est requise',
          enterPassword: 'Entrer le mot de passe',
          passwordIsRequired: 'Mot de passe requis',
          disconnect: 'Se déconnecter',
          update: 'Mise à jour',
          connect: 'Connecter',
          placeholders: {
            exampleDomain: 'http: // yourCompanydomain',
            enterYourCompanyName: 'Entrez le nom de votre entreprise',
            enterLeadSource: 'Entrez la source de plomb',
            enterDivision: 'Entrer la division',
            enterPassword: 'Entrer le mot de passe',
          }
        },
        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          name: 'Nom:',
          zapier: 'Zéro',
          inboundWebhookUrl: 'URL de webhook entrant',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterWebhookUrl: 'Entrez l`URL du webhook',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          emailAddress: 'Adresse e-mail',
          apiUrl: 'URL de l`API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterEmailAddress: 'Entrer l`adresse e-mail',
            enterUrlHere: 'Entrez URL ici',
            enterTokenHere: 'Entrez le jeton ici',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiKey: 'Clé API',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiHere: 'Entrez l`API ici',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          connect: 'Connecter',
          update: 'Mise à jour',
          disconnect: 'Se déconnecter',
          companyName: 'Nom de l`entreprise',
          emailAddress: 'Adresse e-mail',
          phone: 'Téléphone',
          countryCode: 'Code postal',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          // update: 'Mise à jour',
          save: 'Sauvegarder',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          connect: 'Connecter',
          disconnect: 'Se déconnecter',
          testLead: 'Responsable des Tests',
          emailAddress: 'Adresse e-mail',
          selectOrganizationName: 'Sélectionnez le nom de l`organisation',
          selectDepartmentName: 'Sélectionnez le nom du département',
          addParameters: ' Ajouter des paramètres',
          questionColon: 'Question:',
          select: 'Sélectionner',
          mapsWith: 'cartes avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          testLead: 'Responsable des Tests',
          connect: 'Connecter',
          disconnect: 'Se déconnecter',
          projectsList: 'Liste des projets',
          selectProjectsName: 'Sélectionner le nom des projets',
          projectsTaskList: 'Projets TasksList',
          selectTaskListName: 'Sélectionner le nom de la liste de tâche',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
        },
        wooCommerce: {
          configure: 'Configurar',
          automatedMessage: 'Mensaje Automatizado',
          configureTab: {
            wooCommercePluginApiKey: 'Clave API del Plugin de WooCommerce',
            downloadWooCommercePlugin: 'Descargar Plugin de WooCommerce'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Mensaje de Recuperación de Carritos Abandonados',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Configurar mensajes automatizados de recuperación para recuperar a los clientes que abandonaron sus carritos',
            enableAbandonedCartRecoveryMessaegs: 'Habilitar mensajes de recuperación de carritos abandonados',
            selectTemplate: 'Seleccionar Plantilla',
            sendMessageAfter: 'Enviar mensaje después de',
            templateMessage: 'Mensaje de Plantilla',
            templateHeader: 'En-tête de modèle',
            selectFile: 'Choisir le dossier',
            orderFulfillMent: 'Traitement de la commande',
            saveChanges: 'Guardar Cambios',
            orderCompleted: 'Orden Completada',
            enableOrderConfirmationMessages: 'Habilitar mensajes de confirmación de orden',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Enviar un mensaje de confirmación de orden tan pronto como un cliente realiza una orden.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Se envía un mensaje de envío de orden cuando se cumple una orden.',
            enableOrderFulfillmentMessages: 'Habilitar mensajes de cumplimiento de orden',
            returnExchangeRequest: 'Solicitud de Devolución/Intercambio',
            theMessageIsSentAfterARefundIsRequested: 'El mensaje se envía después de que se solicite un reembolso.',
            enableOrderRefundMessages: 'Habilitar mensajes de reembolso de orden',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
          apiToken: 'Jeton API',
          nameIsRequired: 'Le nom est requis',
          connect: 'Connecter',
          testLead: 'Responsable des Tests',
          questionColon: 'Question:',
          addParameters: ' Ajouter des paramètres',
          select: 'Sélectionner',
          mapWith: 'cartographier avec',
          selectParameter: 'Sélectionner le paramètre',
          update: 'Mise à jour',
          save: 'Sauvegarder',
          disconnect: 'Se déconnecter',
          placeholders: {
            enterApiToken: 'Entrez le jeton API',
          }
      }

      }
    },
    createIntegration: {
      createNewIntegration: 'Créer une nouvelle intégation',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'Vous n`avez aucune configuration d`intégration pour l`instant.',
      pleaseCreateOneToIntegrateYourFavourite: 'Veuillez en créer un pour intégrer votre préféré',
      appsWith: 'applications avec',
      integrationName: 'Nom d`intégration',
      integrationNameIsRequired: 'Le nom d`intégration est requis',
      continue: 'Continuer',
      addAnImageToRepresentYourIntegration: 'Ajoutez une image pour représenter votre intégration.',
      description: 'Description',
      submit: 'Soumettre',
      youHaveSuccessfullyCreatedIntegration: 'Vous avez réussi à créer une intégration. Maintenant, vous pouvez le configurer.',
      apiUrl: 'URL de l`API',
      pleaseEnterAValidApiUrl: 'Veuillez saisir une URL de l`API valide',
      requestMethod: 'Méthode de demande',
      get: 'Obtenir',
      post: 'Poste',
      put: 'Mettre',
      patch: 'Correctif',
      queryParameter: 'Paramètre de requête',
      addParameter: ' Ajouter un paramètre',
      mapsWith: ' cartes avec',
      question: 'Question',
      addQuestion: ' Ajouter une question',
      select: 'Sélectionner',
      selectParameter: 'Sélectionner le paramètre',
      header: 'Entête',
      addHeader: ' Ajouter',
      value: 'Valeur',
      connect: 'Connecter',
      requestBody: 'Demander un corps',
      validate: 'Valider',
      // select: 'Sélectionner',
      key: 'Clé',
      // value: 'Valeur',
      // connect: 'Connecter',
    },
    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: ' Peut également être intégré à ce CRM gratuit.',
        apiUrl: 'URL de l`API',
        pleaseEnterAValidApiUrl: 'Veuillez saisir une URL de l`API valide',
        requestMethod: 'Méthode de demande:',
        get: 'Obtenir',
        post: 'Poste',
        put: 'Mettre',
        patch: 'Correctif',
        queryParameter: 'Paramètre de requête',
        addParameter: ' Ajouter un paramètre',
        header: 'Entête',
        addHeader: ' Ajouter',
        requestBody: 'Demander un corps',
        validateJson: 'Valider JSON',
        connect: 'Connecter',
        update: 'Mise à jour',
        delete: 'Supprimer',
        selectTheQuestion: 'Sélectionnez la question dont vous souhaitez envoyer la réponse de l`utilisateur en tant que valeur',
        appTooltip: {
          typeYourJsonCode: 'Tapez votre code JSON',
          clickToValidateJsonCode: 'Cliquez pour valider le code JSON',
        }
      },
      list: {
        customAppsIntegrations: 'Intégrations des applications personnalisées',
        integrateYourAppsWith: 'Intégrer vos propres applications avec',
        help: 'Aider',
        addIntegration: ' Ajouter une intégration',
        name: 'Nom',
        status: 'Statut',
        action: 'Action',
        insatlled: 'installée',
        edit: 'Modifier',
        connect: 'Connecter',
        requestYourIntegration: 'Demandez votre intégration',
        toRequestNewIntegration: 'Pour demander une nouvelle intégration, veuillez saisir les détails mentionnés ci-dessous, puis nous vous répondrons',
        // name: 'Nom',
        emailId: 'ID de messagerie',
        integrationName: 'Nom d`intégration',
        contactNumber: 'Numéro de contact',
        howSoonYouWantToAddThisIntegration: 'Combien de temps vous souhaitez ajouter cette intégration',
        sendRequest: 'Envoyer une demande',
        cancel: 'Annuler',
        delete: 'Supprimer',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Oups ! Vos propres intégrations sont prises en charge uniquement dans',
        plan: 'Plan',
        pleaseUpgradeTo: 'Veuillez passer à',
        toCreateOwnIntegrations: 'pour créer vos propres intégrations',
        appTooltip: {
          integrations: 'Intégrations',
          noIntegrationsFound: 'Aucune intégration trouvée!',
          nameOfYourApp: 'Nom de votre application',
          clickToConnectApp: 'Cliquez pour connecter l`application',
          statusAsConnectedOrNotConnected: 'Statut comme connecté / non connecté',
        }
      }
    }
  }
};
