const brand = sessionStorage.getItem('brand');
export const settings = {
  myAccount: 'Minha conta',
  yourProfileAndPasswordSecurity: 'Seu perfil e segurança de senha',
  detailsHeader: 'Detalhes',
  passwordHeader: 'Senha',
  customAttributeHeader: 'Atributos Personalizados',
  tagsHeader: 'Marcação',
  accessTokenHeader: 'Acesso token',
  thirdPartyIntegrationsHeader: 'Integrações de terceiros',
  appTooltip: {
    TOOLTIP_DETAILS_HEADING: 'Atualize o perfil da sua conta',
    TOOLTIP_PASSWORD_HEADING: 'Mude sua senha',
    TOOLTIP_TAGS_HEADING: 'Gerencie suas Tags',
    TOOLTIP_THIRD_PARTY_HEADING: 'Integrações de terceiros',
    TOOLTIP_CHANGE_PASSWORD_TITLE: 'Uma senha segura ajuda a proteger sua conta $ {Brand}',
    TOOLTIP_INTEGRATIONS: 'Integrar',
    TOOLTIP_ADD_UPDATE_CUSTOM_ATTRIBUTE: 'Adicionar ou atualizar atributos personalizados',
    TOOLTIP_CUSTOM_ATTRIBUTES: 'Gerencie seus atributos personalizados'
  },
  accessToken: {
    accessToken: 'Acesso token',
    generateYourAccessTokenForUsingPlatformAPIs: 'Gere seu token de acesso para usar APIs de plataforma',
    generateApiToken: 'Gerar token de API',
    revokeApiToken: 'Revogar o token da API',
    regenerateApiToken: 'Regenerar o token da API',
    placeholders: {
      yourApiKey: 'Sua chave da API',
    }
  },
  details: {
    name: 'Nome',
    emailId: 'Identificação do email',
    yourPlan: 'Seu plano',
    messagesPerMonth: ' mensagens/mês',
    daysOfStoragePlan: ' dias de plano de armazenamento',
    conditionalFeatures: 'Recursos condicionais',
    upgradeToEnterprisePlanToGetMore: 'Atualize para o plano corporativo para obter mais',
    featuresAndStorageCapacity: 'Capacidade de recurso e armazenamento',
    upgradePlan: 'Plano de atualização',
    submit: 'Enviar',
    selectLanguage: 'Selecione o idioma',
    baby: 'Bebê',
    king: 'Rei',
    conversationsperMonth: 'Cobes/mês',
    english: 'Inglês',
    hindi: 'hindi',
    german: 'Alemão',
    french: 'Francês',
    portuguese: 'Português',
    spanish: 'Espanhol',
    italian: 'italiano',
    russian: 'russo',
    chatBotBrandName: 'Nome da chatbot Brand',
    placeholders: {
      john: 'John',
      email: 'E-mail',
      searchLanguage: 'Linguagem de pesquisa',
      chatBotBrandName: 'Nome da chatbot Brand'
    },
    appTooltip: {
      TOOLTIP_NAME: 'Digite o nome do seu perfil',
      TOOLTIP_EMAIL_ADDRESS: 'Insira o seu endereço de email',
    }
  },
  customAttribute: {
    addAttribute: 'Adicionar Atributo',
    discard: 'Descartar',
    customAttribute: 'Atributos Personalizados',
    checkOrAddCustomAttribute: 'Aqui você pode verificar e adicionar seus atributos personalizados.',
    attributeKey: 'Chave do Atributo',
    attributeName: 'Nome do Atributo',
    bot: 'Bot',
    flow: 'Fluxo',
    loadMore: 'Carregar Mais',
    question: 'Pergunta',
    action: 'Ação',
    search: 'Pesquisar',
    selectBot: 'Selecionar Bot',
    selectFlow: 'Selecionar Fluxo',
    selectQuestion: 'Selecionar Pergunta',
    noDataFoundHeading: 'Você ainda não possui atributos criados',
    noDataFoundSubheading: 'Todos os seus tributos aparecerão aqui',
    deleteTitle: 'Excluir Atributo',
    deleteDescription: 'Tem certeza de que deseja excluir este Atributo?',
    deleteAttributes: 'Excluir Atributos',
    areYouSureYouWantToDeleteThisAttributes: 'Tem certeza de que deseja excluir estes atributos?',
    editAttribute: 'Editar Atributo',
    headsUp: 'Atenção',
    okay: 'OK',
    dataAddedToLead: 'Os atributos mapeados para suas APIs ou Fluxos de Bot não serão atualizados. Por favor, atualize as APIs ou Fluxos de Bot conforme necessário.',
    placeholders: {
      enterAttributeKey: 'Digite a Chave do Atributo',
      enterAttributeName: 'Digite o Nome do Atributo',
      selectBot: 'Selecionar Bot',
      selectFlow: 'Selecionar Fluxo',
      selectQuestion: 'Selecionar Pergunta',
      search: 'Pesquisar por Nome do Atributo'
    },
    appToolTip: {
      TOOLTIP_ATTRIBUTE_KEY: 'Digite a Chave do Atributo',
      TOOLTIP_ATTRIBUTE_NAME: 'Digite o Nome do Atributo',
      TOOLTIP_SELECT_BOT: 'Selecionar Bot',
      TOOLTIP_SELECT_FLOW: 'Selecionar Fluxo',
      TOOLTIP_SELECT_QUESTION: 'Selecionar Pergunta',
    }
  },
  password: {
    changePassword: 'Alterar a senha',
    aSecurePasswordHelpsProtectYour: 'Uma senha segura ajuda a proteger seu',
    account: ' Conta',
    currentPassword: 'Senha atual',
    newPassword: 'Nova Senha',
    confirmPassword: 'Confirme sua senha',
    cancel: 'Cancelar',
    updatePassword: 'Atualizar senha',
    deleteAccount: 'Deletar conta',
    placeholders: {
      enterYourCurrentPassword: 'Digite sua senha atual',
      enterYourNewPassword: 'Digite sua nova senha',
      enterYourConfirmPassword: 'Digite sua senha de confirmação',
    },
    appTooltip: {
      TOOLTIP_CURRENT_PASSWORD: 'Digite uma senha antiga',
      TOOLTIP_NEW_PASSWORD: 'Insira a nova senha',
      TOOLTIP_CONFIRM_PASSWORD: 'Confirme sua nova senha',
      TOOLTIP_DELETE_ACCOUNT: `Delete your current ${brand} Account`,
    }
  },
  tags: {
    manageTags: 'Gerenciar Tags',
    createNewTags: 'Criar Novas Tags',
    createNewTagsSubText: 'Gerencie suas tags aqui para melhor organizar seus leads, chats, grupos, etc.',
    tagName: 'Nome da Tag',
    updateTag: 'Atualizar Tag',
    createTag: 'Criar Tag',
    discard: 'Descartar',
    searchTag: 'Pesquisar Tag por Nome',
    noTagFound: 'Nenhuma Tag Encontrada!',
    createATag: 'Criar uma Tag',
    deleteTagModalSubText: 'Você tem certeza de que deseja excluir esta tag? A exclusão desta tag também a removerá dos usuários atribuídos!',
    deleteTagModalHeading: 'Excluir Tags',
    deleteOneTagModalHeading: 'Excluir Tag',
    actions: 'Ações',
    placeholders: {
      enterTag: 'Digite o Nome da Tag',
    },
    appTooltip: {
      TOOLTIP_DELETE_TAGS: 'Excluir Tags',
    }
},
  thirdPartyIntegration: {
    list: {
      name: 'Nome',
      status: 'Status',
      action: 'Ação',
      messageBird: 'MessageBird',
      messageBirdForSendingMessagesAndEmails: 'MessageBird para enviar mensagens e e -mails',
      notConnected: 'Não conectado',
      connected: 'Conectado',
      edit: 'Editar',
      plivo: 'Plivo',
      plivoForSendingMessages: 'Plivo para enviar mensagens',
      connect: 'Conectar',
      appTooltip: {
        nameOfAppOrCrm: 'Nome de App/CRM',
        statusAsConnectedOrNotConnected: 'Status como conectado / não conectado',
        clickToConnectAppOrCrm: 'Clique para conectar o aplicativo/CRM',
      },
      upgradeModal: {
        oopsIntegrationsAreNotAvailableForBabyPlan: 'Opa! As integrações não estão disponíveis no plano de bebê. Atualize o planejamento do rei para as integrações.',
      }
    },
    providers: {
      messageBird: {
        messageBird: 'MessageBird',
        integrateWithMessageBird: 'Integrar -se ao MessageBird',
        apiKey: 'Chave API',
        originator: 'Originador',
        connect: 'Conectar',
        disconnect: 'desconectar',
        seeActivities: 'Veja atividades',
        sms: 'SMS',
        time: 'Tempo',
        phoneNumber: 'Número de telefone',
        status: 'Status',
        botName: 'Nome do bot',
        message: 'Mensagem',
        error: 'Erro',
        placeholders: {
          apiKey: 'Chave API',
          messageBirdPhoneNumber: 'Número de telefone MessageBird',
        },
        noDataFound: {
          activities: 'Atividades',
          noActivitiesFound: 'Nenhuma atividade encontrada!',
        }
      },
      plivo: {
        plivo: 'Plivo',
        integrateWithPlivo: 'Integrar -se ao Plivo',
        authId: 'Auth ID',
        authToken: 'Token de autenticação',
        yourPlivoPhoneNumber: 'Seu número de telefone Plivo',
        connect: 'Conectar',
        disconnect: 'desconectar',
        seeActivities: 'Veja atividades',
        sms: 'SMS',
        time: 'Tempo',
        phoneNumber: 'Número de telefone',
        status: 'Status',
        botName: 'Nome do bot',
        message: 'Mensagem',
        error: 'Erro',
        noDataFound: {
          activities: 'Atividades',
          noActivitiesFound: 'Nenhuma atividade encontrada!',
        },
        placeholders: {
          authId: 'Auth ID',
          authToken: 'Token de autenticação',
          yourPlivoPhoneNumber: 'Seu número de telefone Plivo',
        }
      }
    }
  }
};
