const brand = sessionStorage.getItem('brand');
export const botBuilder = {
  canvas: {
    editYourChatFlow: 'Sohbet akışınızı düzenleyin',
    seeHowToUse: 'Kullanımı nasıl göster',
    help: 'Yardım',
    classicBuilder: 'Klasik Oluşturucu',
    visualiseFlow: 'Akışı Görselleştir',
    testLiveOnTelegramWeb: 'Telegram Web\'de Canlı Test Edin',
    testFlow: 'Akışı Test Et',
    importTemplate: 'Şablon İçe Aktar',
    landingPageBotReview: 'Landing Sayfa Bot İncelemesi',
    install: 'Yükle',
    testLiveOnMessengerWeb: 'Messenger Web\'de Canlı Test Edin',
    testLiveOnWhatsappWeb: 'WhatsApp Web\'de Canlı Test Edin',
    testLiveOnInstagramWeb: 'Instagram Web\'de Canlı Test Edin',
    seeAllChatFlow: 'Tüm Sohbet Akışlarını Görüntüle',
    launchDemoModal: 'Demo penceresini başlat',
    selectATemplateToKickStartYourBot: 'Botunuzu başlatmak için bir şablon seçin',
    hireChatBotDesigner: 'Sohbetbotu Tasarımcısı Kirala',
    recommendedForYou: 'Sizin için önerilen',
    useTemplate: 'Şablonu kullan',
    exportStatus: 'Dışa Aktarma Durumu',
    flowExport: 'Akış Dışa Aktar',
    download: 'İndir',
    importFlow: 'Akışı İçe Aktar',
    beforeYouStartUploadPleaseMakeSure: 'Yüklemeye başlamadan önce lütfen şunları kontrol edin',
    ponit1: '1. Maksimum dosya boyutu yükleme sınırı 20MB\'dir',
    point2: '2. Dosya formatı JSON biçiminde olmalıdır',
    selectFile: 'Dosya Seç',
    preview: 'Önizleme',
    test: 'Test',
    OopsIntegrationAreNotAvailable: 'Üzgünüz! Entegrasyonlar ',
    pleaseUpgradeTo: ' mevcut değil. Lütfen ',
    planForIntegration: ' yükseltme yapın.',
    placeholders: {
      searchTemplateYouNeed: 'İhtiyacınız olan şablonu arayın',
    },
    noDataFound: {
      botFlow: 'Bot Akışı',
      noBotFlowAddedYet: 'Henüz bot akışı eklenmedi!',
      templates: 'Şablonlar',
      noTemplatesFound: 'Hiç Şablon Bulunamadı!',
    },
    appTooltip: {
      exportFlow: 'Akışı Dışa Aktar',
      importFlow: 'Akışı İçe Aktar',
      clickToViewChatFlow: 'Sürükle ve bırak oluşturucuda sohbet akışını görüntülemek için tıklayın',
      clickToPreviewChatFlow: 'Sohbet akışını önizlemek için tıklayın',
      clickToViewChatFlowAsFlowDiagram: 'Sohbet akışını bir akış diyagramı olarak görüntülemek için tıklayın',
      clickToTestChatFlow: 'Sohbet akışını test etmek için tıklayın',
      clickToSelectAndImportTemplate: 'İşinize en uygun şablonu seçip içe aktarmak için tıklayın',
      clickToInstallYourBotToSelectedPlatform: 'Botunuzu seçilen platforma yüklemek için tıklayın',
      clickToTestDefaultFlowOfTelegramBot: 'Botunuzun varsayılan (etkin) akışını Telegram web üzerinde test etmek için tıklayın',
      clickToTestDefaultFlowOfFacebookBot: 'Botunuzun varsayılan (etkin) akışını Facebook web üzerinde test etmek için tıklayın',
      clickToTestDefaultFlowOfWhatsappBot: 'Botunuzun varsayılan (etkin) akışını WhatsApp web üzerinde test etmek için tıklayın',
      clickToTestDefaultFlowOfInstagramBot: 'Botunuzun varsayılan (etkin) akışını Instagram web üzerinde test etmek için tıklayın',
    }
  },
  createNewBot: {
    createNewBot: 'Yeni Bot Oluştur',
    stepOneOfTwo: 'Adım 1/2',
    selectThePlatform: 'Platformu Seçin',
    selectTheBotType: 'Bot Türünü Seçin',
    aiBasedChatbot: 'AI Tabanlı Sohbet Botu',
    ruleBasedChatBot: 'Kural Tabanlı Sohbet Botu',
    webPlatforms: 'Web Platformları',
    websiteChatBot: 'Web Sitesi Sohbet Botu',
    withLiveChat: '(Canlı Sohbet ile)',
    landingPageBot: 'Landing Sayfa Botu',
    liveChatWidget: 'Canlı Sohbet Aracı',
    onlyLiveChat: '(Sadece Canlı Sohbet)',
    socialPlatforms: 'Sosyal Platformlar',
    whatsappChatBot: 'WhatsApp Sohbet Botu',
    telegramChatBot: 'Telegram Sohbet Botu',
    facebookChatBot: 'Facebook Sohbet Botu',
    instagramChatBot: 'Instagram Sohbet Botu',
    selectBotType: 'Bot Türünü Seçin',
    selectProblem: 'Çözmek istediğiniz sorunu seçin',
    leadGeneration: 'Potansiyel Müşteri Oluşturma',
    customerSupport: 'Müşteri Desteği',
    appointmentBooking: 'Randevu Rezervasyonu',
    marketingAutomation: 'Pazarlama Otomasyonu',
    ecommerceWebsite: 'E-Ticaret Web Sitesi',
    otherUseCase: 'Diğerleri',
    back: 'Geri',
    typeDomainUrl: 'Doğrulama için web sitenizin URL\'sini yazın.',
    dataSourceForChatBotTraining: 'Sohbet botu eğitimi için veri kaynağı',
    upload: 'Yükle',
    uploadDocuments: 'Belgeleri Yükle',
    uploadDocument: 'Belgeyi Yükle',
    selectFile: 'Dosya Seç',
    continue: 'Devam',
    websiteUrl: 'Web sitenizin URL\'sini yazın',
    skipNow: 'Şimdi Atla',
    completeTheExistingBotSetup: 'Mevcut bot kurulumunu tamamlayın',
    youHaveAlreadyCreatedOneWhatsAppBot: 'Zaten bir WhatsApp Botu oluşturdunuz. Yeni bir tane oluşturmak için lütfen aynısının kurulumunu tamamlayın.',
    completeSetup: 'Kurulumu Tamamla',
    seeHowToUse: 'Kullanımı nasıl görmek için tıklayın',
    help: 'Yardım',
    fbBot: {
      createNewBot: 'Yeni Bot Oluştur',
      help: 'Yardım',
      back: 'Geri',
      step: 'Adım ',
      of3: ' / 3',
      loginWithFacebook: 'Facebook ile Giriş Yapın',
      asPerLatestChangesInFacebookPolicy: 'Son Facebook politika değişikliklerine göre, Facebook bot sahibinin ' +
        '`yönetici` veya daha üst düzey rolü olması gerekmektedir, böylece Facebook sayfasının tüm özelliklerine erişebilirsiniz.',
      login: 'Giriş',
      connectFacebookBot: 'Facebook Botunu Bağlayın',
      connectBotWithYourFacebookPagesAnd: 'Botu Facebook Sayfalarınızla bağlayın ve ',
      exploreTruePowerOfFacebook: 'Facebook\'un gerçek gücünü keşfedin',
      connect: 'Bağlan',
      connected: 'Bağlandı',
      createNewPage: 'Yeni sayfa oluştur',
      refreshPageList: 'Sayfa Listesini Yenile',
      thisPageIsSuccessfullyIntegratedWith: 'Bu sayfa başarıyla şununla entegre edildi: ',
      yourMessagesFromMessengerWillAppearIn: 'Messenger\'dan gelen mesajlar ',
      inbox: ' Gelen Kutusu\'nda görünecektir.',
      continueToBotConfiguration: 'Bot Yapılandırmasına Devam Edin',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} hoş geldiniz. Size yardımcı olmaktan mutluluk duyarız.`,
      letsTryYourBotAndSeeHowItWorks: 'Botunuzu deneyelim ve nasıl çalıştığını görelim! "Facebook\'ta Test Et"i tıklayın.',
      howAreYouDoingToday: 'Bugün nasılsınız?',
      thisIsHowSimpleToUseFacebookBot: 'İşte bu kadar! Facebook botunu kullanmak bu kadar basit.',
      letsCreateYourOwnChatFlowNow: 'Şimdi kendi sohbet akışınızı oluşturalım.',
    },

    instagramBot: {
      createNewBot: 'Yeni Bot Oluştur',
      back: 'Geri',
      step: 'Adım ',
      of3: ' / 3',
      loginWithInstagram: 'Instagram ile Giriş Yapın',
      loginToYourInstagramAccount: 'Instagram hesabınıza giriş yapın ve sohbetbotunu etkinleştirin.',
      managerLevelDescription: 'Instagram bot sahiplerinin, tüm özelliklere erişebilmek için en az "yönetici" düzeyinde izinlere sahip olmaları gerekmektedir.',
      login: 'Giriş',
      connectInstagramBot: 'Instagram Botunu Bağlayın',
      connectBotWithYourInstagramAnd: 'Botu Instagram\'ınızla bağlayın ve ',
      exploreTruePowerOfInstagram: ' Instagram\'ın gerçek gücünü keşfedin',
      connected: 'Bağlandı',
      connect: 'Bağlan',
      createNewPage: 'Yeni sayfa oluştur',
      refreshPageList: 'Sayfa Listesini Yenile',
      thisPageIsSuccessfullyintegratedWith: 'Bu sayfa başarıyla şununla entegre edildi: ',
      yourMessagesFromMessengerWillAppearIn: 'Messenger\'dan gelen mesajlar ',
      inbox: ' Gelen Kutusu\'nda görünecektir.',
      continueToBotConfiguration: 'Bot Yapılandırmasına Devam Edin',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} hoş geldiniz. Size yardımcı olmaktan mutluluk duyarız.`,
      letsTryYourBotAndSeeHowItWorks: 'Botunuzu deneyelim ve nasıl çalıştığını görelim! "Instagram\'da Test Et"i tıklayın.',
      howAreYouDoingToday: 'Bugün nasılsınız?',
      thisIsHowSimpleToUseInstagramBot: 'İşte bu kadar! Instagram botunu kullanmak bu kadar basit.',
      letsCreateYourOwnChatFlowNow: 'Şimdi kendi sohbet akışınızı oluşturalım.',
    },
    telegramBot: {
      back: 'Geri',
      step: 'Adım ',
      of3: ' / 3',
      seeHowToUse: 'Kullanımı nasıl görmek için tıklayın',
      help: 'Yardım',
      loginToYourTelegramAccount: 'Telegram Hesabınıza Giriş Yapın',
      loginToYourAccountToCreateNewBotOrConnectExistingBot: 'Yeni bot oluşturmak veya mevcut botu bağlamak için hesabınıza giriş yapın',
      verificationCodeSentOnRegisteredNumber: 'Kayıtlı numaraya gönderilen doğrulama kodu',
      createYourBot: 'Botunuzu Oluşturun',
      enterYourBotsNameAnd: `Bot adınızı girin, bağlayın ve eğlence Telegram botuyla başlasın `,
      botUserNameMustEndWithBotAtTheEnd: 'Bot kullanıcı adının sonunda "bot" olmalıdır.',
      terrisBot: 'Örneğin, teris_bot veya terisbot',
      botNameAlreadyTaken: 'Bu bot adı zaten alınmış. Lütfen farklı bir şey deneyin',
      connect: 'Bağlan',
      createBot: 'Bot Oluştur',
      codeNotReceivedResend: 'Kod alınmadı mı? Tekrar gönder!',
      changeNumber: 'Numarayı Değiştir',
      telegramPreview: 'Telegram Önizleme',
      worldsFastestMessagingApp: `Dünyanın En Hızlı Mesajlaşma Uygulaması & Ücretsizdir!`,
      bot: 'Bot',
      phoneNumber: 'Telefon numarası',
      userName: 'Kullanıcı Adı',
      none: 'Hiçbiri',
      bio: 'Biyografi',
      fewWordsAboutYourself: 'Kendiniz hakkında birkaç kelime',
      settings: 'Ayarlar',
      notificationsAndSound: 'Bildirimler ve Sesler',
      privacyAndSecurity: 'Gizlilik ve Güvenlik',
      dataAndStorage: 'Veri ve Depolama',
      chatSettings: 'Sohbet Ayarları',
      chatFolders: 'Sohbet Klasörleri',
      devices: 'Cihazlar',
      connectYourBot: 'Botunuzu Bağlayın',
      createNewBotOrConnectWithExistingOne: 'Yeni Bot oluşturun veya Mevcut Olanı Bağlayın',
      createNewBot: 'Yeni Bot Oluştur',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} hoş geldiniz. Size yardımcı olmaktan mutluluk duyarız.`,
      letsTryYourBotAndSeeHowItWorks: 'Botunuzu deneyelim ve nasıl çalıştığını görelim! "Telegram\'da Test Et"i tıklayın.',
      howAreYouDoingToday: 'Bugün nasılsınız?',
      thisIsHowSimpleToUseTelegramBot: 'İşte bu kadar! Telegram botunu kullanmak bu kadar basit.',
      letsCreateYourOwnChatFlowNow: 'Şimdi kendi sohbet akışınızı oluşturalım.',
      placeholders: {
        searchCountry: 'Ülke Ara',
        enterTelegramMobileNumber: 'Telegram Mobil Numarasını Girin',
        enterLoginCodeReceived: 'Telegram hesabına gelen giriş kodunu girin *',
        twoFactorAuthentication: 'İki faktörlü kimlik doğrulama şifresi (isteğe bağlı)',
        botName: 'Bot Adı *',
        botUserName: 'Bot Kullanıcı Adı *',
      }
    },


    websiteLandingPageLiveChatBot: {
      createNewBot: 'Yeni Bot Oluştur',
      seeHowToUse: 'Nasıl Kullanılacağını Gör',
      help: 'Yardım',
      back: 'Geri',
      step: 'Adım ',
      of2: ' / 2',
      buildA: 'Bir ',
      thatMatchesYourBrandPersonality: `markanızın kişiliğine uygun `,
      chatBotIcon: 'Sohbet Botu İkonu ',
      optional: 'İsteğe Bağlı',
      themeColor: 'Tema Rengi',
      chatBotName: 'Sohbet Botu Adı',
      botNameIsRequired: 'Bot adı gereklidir',
      botNameShouldBeLessThan50Characters: 'Bot Adı 50 karakterden az olmalıdır',
      continue: 'Devam',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} Hoş Geldiniz. Size yardımcı olmaktan memnuniyet duyarız.`,
      whatIsYourEmailAddress: 'E-posta adresiniz nedir?',
      invalidEmailAddress: 'Geçersiz e-posta adresi',
      customizeYourLandingPageBotLook: 'Landing Page Bot görünümünüzü özelleştirin',
      customizeYourWidgetLook: 'Widget görünümünüzü özelleştirin',
      customizeYourBotLook: 'Bot görünümünüzü özelleştirin',
      buildYourLandingPageBotAndAutomateInteraction: 'Landing sayfa botunuzu oluşturun ve müşterilerinizle etkileşimi otomatikleştirerek bağlantı paylaşımı yapın',
      useLiveChatBotToConnectToCustomers: 'Müşterilerinizle gerçek zamanlı bağlantı kurmak için Canlı Sohbet Botunu kullanın',
      connectChatBotToYourWebsiteAndAutomateInteractions: 'Sohbet Botunu web sitenize bağlayın ve ziyaretçilerle etkileşimleri otomatikleştirin',
      widget: 'widget',
      botInterface: 'bot arayüzü',
      mergedBot: 'Web Sitesi Sohbet Botu, Landing Sayfa Botu, Mobil Uygulama Sohbet Botu',
      mergedBotToolTip: 'Ziyaretçilerin dikkatini çekmek ve onları sohbetlere dahil etmek için bir bot sayfası oluşturun.',
      placeholders: {
        typeANameForYourBot: 'Yeni botunuz için bir ad yazın',
      },
      appTooltip: {
        TOOLTIP_WEBSITE_BOT_NAME: 'Bot adınızı girin',
        mergedBotToolTip: 'Ziyaretçilerin dikkatini çekmek ve onları sohbetlere dahil etmek için bir bot sayfası oluşturun.',
        TOOLTIP_WEBSITE_BOT_THEME_COLOR: 'Sohbet pencereniz için bir tema rengi seçin',
        thisLogoWillBeUsedInTheBrandNamePlatformOnly: `Bu logo yalnızca ${brand ? brand : ''} platformunda kullanılacaktır. Şirket logosunu seçmenizi öneririz. 1 MB'dan az olan bir PNG veya JPG kare resim ekleyin.`
      }
    },
    whatsappBot: {
      back: 'Geri',
      justAStepAway: 'Sadece Bir Adım Uzaktayız',
      seeHowToUse: 'Nasıl Kullanılacağını Gör',
      help: 'Yardım',
      createWhatsappChatBot: 'WhatsApp Botu Oluştur',
      businessNameIsRequired: 'İşletme Adı gereklidir.',
      maximumLengthForBusinessName40Characters: 'İşletme Adı için maksimum uzunluk 40 karakterdir.',
      businessCategory: 'İş Kategorisi',
      categoryIsRequired: 'Kategori gereklidir.',
      descriptionIsRequired: 'Açıklama gereklidir.',
      maximumLengthForDescription200Characters: 'Açıklama için maksimum uzunluk 200 karakterdir.',
      pleaseFillAValidEmailAddress: 'Lütfen geçerli bir iş e-postası doldurun.',
      businessEmailIsRequired: 'İş e-postası gereklidir.',
      phoneNumberShouldHaveMinimum7Digits: 'Telefon numarası en az 7 haneli olmalıdır',
      phoneNumberIsRequired: 'Telefon numarası gereklidir',
      websiteIsRequired: 'Web sitesi gereklidir.',
      maximumLengthForWebsite40Characters: 'Web Sitesi için maksimum uzunluk 40 karakterdir.',
      maximumLengthForAddress150Characters: 'Adres için maksimum uzunluk 150 karakterdir.',
      doYouHaveWhatsappApiKey: 'WhatsApp API anahtarınız var mı?',
      apiProvider: 'API sağlayıcı',
      three60Dialog: '360 Dialog',
      whatsappCloud: 'WhatsApp Cloud',
      netcore: 'Netcore',
      apiKeyIsRequired: 'API Anahtarı gereklidir.',
      whatsappBusinessAccountIdIsRequired: 'WhatsApp İş Hesabı Kimliği gereklidir.',
      whatsappPhoneNumberIdIsRequired: 'WhatsApp Telefon Numarası Kimliği gereklidir.',
      systemAccessTokenIsRequired: 'Sistem Erişim Anahtarı gereklidir.',
      sourceIdIsRequired: 'Kaynak Kimliği gereklidir.',
      businessName: 'İşletme Adı',
      businessAccount: 'İşletme Hesabı',
      businessDescription: 'İşletme Açıklaması',
      address: 'Adres',
      businessEmail: 'İş E-postası',
      websiteUrl: 'Web Sitesi URL\'si',
      thankYouForYourInterestIn: 'İlgilendiğiniz için teşekkür ederiz ',
      whatsappChatBot: ' WhatsApp Sohbet Botu.',
      weHaveReceivedYourInterests: 'İlgilinizi aldık ve Satış ekibimiz en geç 24 saat içinde sizinle iletişime geçecektir.',
      theyWillProvideYouWhatsappApiKey: 'Size WhatsApp API Anahtarını sağlayacaklar.',
      readyToCreateWhatsappBot: 'WhatsApp Botu oluşturmaya hazırım',
      exploreWhatsappFeatures: 'WhatsApp özelliklerini keşfet',
      welcomeToBrandNameWeAreHereToHelpYou: `${brand} Hoş Geldiniz. Size yardımcı olmaktan memnuniyet duyarız.`,
      letsTryYourBotAndSeeHowItWorks: `Botunuzu denemeye ve nasıl çalıştığını görmeye hazırız! "WhatsApp Üzerinde Test Et"i tıklayın.`,
      howAreYouDoingToday: 'Bugün nasılsınız?',
      thisIsHowSimpleToUseWhatsappBot: `İşte bu kadar basit! WhatsApp botu kullanmak bu kadar basit.`,
      letsCreateYourOwnChatFlowNow: `Şimdi kendi sohbet akışınızı oluşturalım`,
      connectWhatsAppBot: 'WhatsApp Botunu Bağlayın',
      createtWhatsAppBot: 'WhatsApp Botu Oluştur',
      proceed: 'Devam et',
      whatsApiProvider: 'WhatsApp API Sağlayıcısı',
      cloudApi: 'Bulut API',
      netcoreApi: 'Netcore API',
      placeholders: {
        yourBusinessName: 'İşletme Adınız',
        businessDescription: 'İşletme Açıklaması',
        businessEmail: 'İş E-postası',
        phone: 'Telefon*',
        website: 'Web Sitesi (örn. www.ornek.com)',
        address: 'Adres',
        enterApiKey: 'API Anahtarını Girin',
        enterWhatsappBusinessAccountId: 'WhatsApp İş Hesabı Kimliğini Girin',
        enterWhatsappPhoneNumberId: 'WhatsApp Telefon Numarası Kimliğini Girin',
        enterSystemAccessToken: 'Sistem Erişim Anahtarını Girin',
        enterSourceId: 'Kaynak Kimliğini Girin',
        businessName: 'İşletme Adı',
        businessWebsite: 'İşletme Web Sitesi',
        selectWhatsappApiProvider: 'WA API Sağlayıcısını Seç',
      },
      stepFour: {
        chooseYourWhatsAppAPIProvider: 'WhatsApp API Sağlayıcınızı Seçin',
        doYouHaveWhatsAppBusinessAPI: 'WhatsApp İş API\'nız var mı?',
        yes: 'Evet',
        noIAmNotSure: 'Hayır, Emin Değilim',
        selectApiProvider: 'API Sağlayıcısını Seçin',
        whatsAppCloudAPI: 'WhatsApp Bulut API',
        enterYourPhoneNumberId: 'Telefon Numarası Kimliğinizi Girin',
        phoneNumberId: 'Telefon Numarası Kimliği',
        enterYourWhatsAppBusinessAccountId: 'WhatsApp İş Hesap Kimliğinizi Girin',
        whatsAppBusinessAccountId: 'WhatsApp İş Hesap Kimliği',
        enterYourSystemUserToken: 'Sistem Kullanıcı Anahtarınızı Girin',
        systemUserToken: 'Sistem Kullanıcı Anahtarı',
        help: 'Yardım',
        netcoreAPI: 'Netcore API',
        enterYourSourceId: 'Kaynak Kimliğinizi Girin',
        sourceId: 'Kaynak Kimliği',
        enterYourNetcoreApiKey: 'Netcore API Anahtarınızı Girin',
        netcoreApiKey: 'Netcore API Anahtarı',
        submit: 'Gönder',
        noneOftheAbove: 'Yukarıdakilerden Hiçbiri',
        next: 'Sonraki'
      },
      stepTwo: {
        legallyRegisteredBusiness: 'Hukuki olarak kayıtlı işletme',
        validWebsiteWithDataPrivacyPolicy: 'Veri gizliliği politikası olan geçerli web sitesi',
        debit: 'Banka Kartı',
        creditCard: 'Kredi Kartı',
        gstin: 'GSTIN',
        onlyRequiredForIndianResidents: 'Sadece Hindistan sakinleri için gereklidir',
        onlyRequiredFor: 'Sadece',
        indianResidents: 'Hindistan sakinleri için gereklidir',
        validPaymentMethod: 'Geçerli Ödeme Yöntemi',
        enabledWithInternationalPayments: 'Uluslararası Ödemeler ile etkinleştirilmiş',
        validUseCaseInComplianceWithWhatsAppCommercePolicy: 'WhatsApp ticaret politikasına uygun geçerli kullanım durumu',
        PhoneNumberWhichDoesNotHaveActiveWhatsAppAndCapableOfReceivingTheSMS: 'Aktif WhatsApp\'ı olmayan ve SMS alabilen telefon numarası',
        discard: 'At',
        proceed: 'Devam et',
        whatsAppBotPrerequisites: 'WhatsApp Bot Önkoşulları',
        letUsGetItDoneForYou: 'Sizin için halledelim!',
        ifYouAreFacingSomeIssues: 'Bazı sorunlarla karşılaşıyorsanız',
        ourTeamCanHelpYouDoTheSame: 'Ekibimiz aynısını yapmanıza yardımcı olabilir',
        requestACallbackNow: 'Şimdi bir geri arama isteği gönderin!',
      },
      stepThree: {
        setupWhatsAppBot: 'WhatsApp Bot Kurulumu',
        selectingTheBestBusinessManagerForYourNeeds: 'İhtiyaçlarınıza En İyi İş Yöneticisini Seçme',
        createMetaApp: 'Meta Uygulama Nasıl Oluşturulur',
        createaMetaAppOn: 'Meta Uygulama Oluşturma',
        howToCreate: 'Nasıl oluşturulur',
        addWhatsAppNumber: 'WhatsApp Numarası Nasıl Eklenir',
        addYourWhatsAppNumberAndVerifyTheSame: 'WhatsApp Numaranızı Ekleyin ve Aynısını Doğrulayın',
        subscribe: 'Abone Ol',
        copyAndSubscribeWebhook: 'Webhook\'u Kopyala ve Abone Ol',
        howToSubscribe: 'Nasıl abone olunur',
        copy: 'Kopyala',
        appSecretId: 'Uygulama Gizli Kimliği',
        enterYourAppSecretId: 'Uygulama gizli kimliğinizi girin',
        enterAppSecret: 'Uygulama Gizli Kimliği Girin',
        phoneNumberId: 'Telefon Numarası Kimliği',
        whatsAppBusinessId: 'WhatsApp İş Kimliği',
        assignSystemUser: 'Sistem Kullanıcısı Nasıl Atanır',
        assign: 'Ata',
        createApp: 'Meta Oluştur',
        registerAndVerify: 'Kayıt Ol ve Doğrula',
        add: 'Ekle',
        systemUserToken: 'Sistem Kullanıcı Tokenı',
        enterYourSystemUserToken: 'Sistem Kullanıcı Tokenınızı Girin',
        getAllNecessaryDetails: 'Gerekli Tüm Detayları Nasıl Alırız',
        subscribeWhatsAppWebhook: 'WhatsApp Webhook\'una Nasıl Abone Olunur',
        configureTheWebhookForWhatsAppAccount: 'WhatsApp hesabı için Webhook\'u Nasıl Yapılandırırız',
        upadatePaymentMethod: 'Ödeme Yöntemini Nasıl Güncellersiniz',
        assignThePaymentMethodToWhatsAppAccount: 'Ödeme yöntemini herhangi bir WhatsApp Hesabına Atayın',
        grantControlOfWhatsAppBusinessAccountToASystemUser: 'WhatsApp İş Hesabının Kontrolünü Bir Sistem Kullanıcısına Verme',
        keyDetailsThatYouNeedToCopyFromMetaDashboard: 'Meta Kontrol Panelinden Kopyalamanız Gereken Anahtar Detaylar',
        updatePayment: 'Ödeme Güncelleme',
        letUsGetItDoneForYou: 'Size bunu yapmamıza izin verin!',
        ifYouAreFacingSomeIssues: 'Bazı sorunlarla karşılaşıyorsanız',
        ourTeamCanHelpYouDoTheSame: 'Ekibimiz aynısını yapmanıza yardımcı olabilir',
        requestACallbackNow: 'Şimdi bir geri arama isteği gönderin!',
        update: 'Güncelle',
        connectNow: 'Şimdi Bağlan'
      },
      stepFive: {
        selectBusinessManager: 'İş Yöneticisi Seç',
        selectingTheBestBusinessManagerForYourNeeds: 'İhtiyaçlarınıza Uygun En İyi İş Yöneticisini Seçme'
      },
      stepSix: {
        yourBotIsInstalledAndActive: 'Botunuz Yüklendi ve Etkin',
        youhaveSuccessfullyConnected: 'Başarıyla bağlandınız',
        withWhatsApp: 'WhatsApp ile',
        youHaveBeenAllocaated100ConversationsPerMonthByDefault: 'Varsayılan olarak aylık 100 konuşma hakkınız bulunmaktadır',
        upgradeYourPlanForMore: 'Daha fazlası için planınızı yükseltin.',
        createChatFlow: 'Sohbet Akışı Oluştur',
        upgradePlan: 'Planı Yükselt'
      },
      activeDemoBotStepFive: {
        scanQrOrSendMessageToActivateBot: 'Botu etkinleştirmek için QR kodunu tarayın veya mesaj gönderin',
        scanTheQrCodeFrom: 'QR kodunu tarayın',
        yourPhoneWillOpen: `Telefonunuz, Sandbox numaramıza gönderilecek önceden doldurulmuş bir mesajı açacaktır. WhatsApp'ta 'Gönder'e basın`,
        sendMessageFromYourWhatsAppNumber: 'WhatsApp numaranızdan mesaj gönderin',
        sendUsAWhatsAppMessageOn: 'Bize WhatsApp üzerinden bir mesaj gönderin',
        bySavingInYourContactsAndMessageShouldStartwith: 'kişilerinizde kaydederek ve mesajın şununla başlaması gerekiyor:',
        send: 'Gönder',
        or: 'VEYA'
      }

    }
  },
  gettingStarted: {
    hello: 'Merhaba, ',
    upgrade: 'Yükselt',
    gettingStarted: 'Başlangıç - ',
    yourFacebookBotToken: 'Facebook bot belirteciniz ',
    hasBeenExpired: 'süresi dolmuş',
    isExpiringIn: 'süresi ',
    days: ' gün sonra doluyor',
    facebookAuthenticationTokenRemainsFor60Days: 'Facebook kimlik doğrulama belirteci yalnızca 60 gün boyunca geçerlidir',
    kindlyProceedWithTheRenewalOfYourBotToken: 'Bot belirtecinizin sürekli sohbetbot hizmetlerine kesintisiz erişim sağlamak için yenileme işlemine devam edin.',
    renewYourBotToken: 'Bot belirtecini yenile',
    editYourChatFlow: 'Sohbet akışınızı düzenleyin',
    buildConversationEngagingBot: 'Kullanıcılarınız için etkileşimli sohbet akışları oluşturun.',
    takes5minutes: '5 dakika sürer',
    previewYourLiveChatWidget: 'Canlı Sohbet Widget\'ınızı İnceleyin',
    discoverHowThe: 'Şunun nasıl çalıştığını keşfedin: ',
    liveChatFeatureWorks: ' Canlı Sohbet özelliği nasıl çalışır',
    takes2minutes: '2 dakika sürer',
    takes1minute: '1 dakika sürer',
    connected: 'Bağlandı',
    customizeYourBotApperance: 'Botunuzun görünümünü özelleştirin',
    takes3minutes: '3 dakika sürer',
    thirdPartyAppsIntegration: 'Üçüncü Taraf Uygulamaları Entegrasyonu',
    integrateYourBot: 'Botunuzu diğer uygulamalar / CRM\'lerle entegre ederek iş otomasyonu oluşturun.',
    upgradeYourPlanToReceiveLeadsInEmail: 'Leads\'ları e-postanızda almak için planınızı yükseltin',
    getStartedWithUs: 'Bizimle başlayın',
    howToBuildAGoodChatFlow: 'İyi bir sohbet akışı nasıl oluşturulur',
    howToUseBotPenguinsInboxAndItsFeatures: 'BotPenguin\'ın Gelen Kutusu ve Özelliklerinin Nasıl Kullanılacağı',
    botPenguinsPricing: 'BotPenguin\'ın Fiyatlandırması: Planlar ve Özellikleri Keşfetme',
    openHelpDesk: 'Yardım masasını aç',
    helpAndSupport: 'Yardım ve destek',
    extensiveGuides: 'Tüm sorularınız ve iş akışlarınız için kapsamlı rehberlerimiz, ',
    resourceCentre: 'Kaynak Merkezi\'nde',
    checkoutOurAnswers: 'Aşağıdaki yaygın sorulara verdiğimiz cevapları inceleyebilirsiniz.',
    createANewBot: 'Yeni bir bot oluşturun',
    installYourBot: 'Botunuzu yükleyin',
    plansAndPricing: 'Planlar ve Fiyatlandırma',
    previewYourLandingPageBot: 'Landing sayfa botunuzu önizleyin',
    installYourLiveChatWidget: 'Canlı Sohbet Widget\'ınızı yükleyin',
    installYourWebsiteBot: 'Web sitenize, mobil uygulamaya yükleyin veya bir ana sayfa veya gömülü sohbet botu olarak başlatın',
    integrateBotToLandingPage: 'Botu landing sayfanıza entegre edin',
    installWidgetToWebsite: 'Widget\'ı web sitenize ekleyin',
    installBotToWebsite: 'Sohbetbotunuzu web sitenize ekleyin',
    whatsappAutomation: 'WhatsApp Otomasyonu',
    facebookAutomation: 'Facebook Otomasyonu',
    telegramBotSettings: 'Telegram Bot Ayarları',
    liveChatWidgetSettings: 'Canlı Sohbet Widget Ayarları',
    landingPageBotSettings: 'Landing Sayfa Bot Ayarları',

    websiteChatBotSettings: 'Website Sohbet Botu Ayarları',
    instagramAutomation: 'Instagram Otomasyonu',
    diveDeeperIntoBotPenguin: 'BotPenguin\'a Daha Detaylı Bir Bakış Atın',
    knowWhereWeAreGoing: 'Nereye gittiğimizi, son güncellemeleri öğrenin ve bazı hataları düzeltmemize yardımcı olun.',
    productUpdates: 'Ürün Güncellemeleri',
    requestAFeature: 'Bir Özellik İsteği',
    reportABug: 'Bir Hata Bildir',
    prodcutRoadmap: 'Ürün Yol Haritası',
    trainYourChatbot: 'AI Sohbet Botunuzu Eğitin (Bilgi Tabanı)',
    buildEngagingConversation: 'Kullanıcılarınız için etkileşimli sohbet akışları oluşturun.',
    previewYourAIBot: 'AI Botunuzu Önizleyin',
    integrateAIbot: 'Botu AI botunuza entegre edin',
    aiBotSettings: 'AI Bot Ayarları',
    CustomizeAIBot: 'Botunuzun görünümünü ve işlevselliğini özelleştirin.',
    gptKeyWarning: 'ChatGPT API hizmetimize hoş geldiniz! Lütfen her bot için 100 mesajlık bir sınırlama olduğunu unutmayın. 100 mesaj sınırına ulaştıktan sonra hizmetimizi kullanmaya devam etmek için kendi ChatGPT API anahtarınızı kullanmanız gerekecek. ChatGPT API anahtarınızı girmek için ChatGPT Model Yapılandırması\'na gidin.',
    whatsappBotSettings: 'WhatsApp Bot Ayarları',
    automateCustomerEngagementWithWhatsApp: 'WhatsApp ile müşteri etkileşimini otomatikleştirin.',
    connectToYourWhatsappNumber: 'WhatsApp Numaranızla Bağlan',
    connectYourWhatsappBotWithYourWhatsappNumber: 'WhatsApp Botunuzu WhatsApp numaranızla bağlayın',
    facebookBotSettings: 'Facebook Bot Ayarları',
    automateCustomerEngagementWithTelegram: 'Telegram ile müşteri etkileşimini otomatikleştirin',
    automateCustomerEngagementWithFacebook: 'Facebook ile müşteri etkileşimini otomatikleştirin',
    automateCustomerEngagementWithInstagram: 'Instagram ile müşteri etkileşimini otomatikleştirin',
    telegramAutomation: 'Telegram Otomasyonu',
  },
  install: {
    previewYourLandingPageBot: 'Landing Sayfa Botunuzu Önizleyin',
    preview: 'Önizleme',
    test: 'Test',
    squareSpace: 'SquareSpace',
    installYourChatBot: 'Sohbet Botunuzu Yükleyin',
    seeHowToUse: 'Nasıl kullanılacağını görün',
    help: 'Yardım',
    uniqueLinkToLandingPageBot: 'İşte Landing sayfa botunuzun benzersiz bağlantısı.',
    shareOnSocialMedia: 'Sosyal medyada, iletişim sayfalarında veya iş kartlarında kullanıcılarınızla paylaşın.',
    or: 'YA DA',
    copyUrl: 'URL Kopyala',
    selectYourWebsiteCms: 'Web Sitesi CMS\'inizi Seçin',
    selectCms: 'Web sitenizi oluşturmak için kullandığınız CMS/Oluşturucuyu seçin',
    custom: 'Özel',
    wordpress: 'WordPress',
    shopify: 'Shopify',
    wix: 'Wix',
    zoomla: 'Zoomla',
    otherCms: 'Diğer CMS',
    howWouldYouLikeToInstall: 'Nasıl yüklemek istersiniz?',
    installingWebsiteBot: 'Botu web sitenize nasıl kuracağınızı seçin',
    iWillAddMyself: 'Kendim ekleyeceğim',
    getChatBotScript: 'Sohbet botu betik kodunu alın ve kendiniz kurun',
    getWordpressPlugin: 'WordPress Eklenti API Anahtarını Alın',
    getOurOfficialPlugin: 'Resmi eklentimizi WordPress eklenti mağazasından alın',
    getPlugin: 'Eklenti Alın',
    quickGuideToInstall: 'Kurulum için Hızlı Kılavuz',
    placeApiKeyBelowIn: 'API anahtarını aşağıdaki alana yapıştırın ',
    afterDownloadingAndActivatingPlugin: ' eklentiyi indirip etkinleştirdikten sonra',
    copy: 'Kopyala',
    enterYourWebsite: 'Web sitenizi girin ve sohbet botunuzun nasıl göründüğünü görelim',
    enterAValidUrl: 'Geçerli bir URL girin',
    verifyYourInstallation: 'Kurulumunuzu doğrulayın',
    testInstallation: 'Kurulumu Test Et',
    getChatBotScriptCode: 'Sohbet Botu Betik Kodunu Alın',
    copyThisCodeSnippet: 'Bu kod parçasını kopyalayın ve sohbet botunu görüntülemek istediğiniz her web sayfasının HTML\'sine yapıştırın veya her sayfanın kapanmadan önceki bölümüne yapıştırın.',
    sendCodeToMyDeveloper: 'Kodu Geliştiricime Gönder',
    sendCodeToMyDevelopmentTeam: 'Kodu geliştirme ekibime gönder',
    sendEmail: 'E-posta Gönder',
    enterAValidEmail: 'Geçerli bir e-posta girin',
    codeHasBeen: 'Kod gönderildi ',
    toYourDeveloper: ' geliştiricinize ',
    resent: 'yeniden gönderildi',
    sent: 'gönderildi',
    resendEmail: 'E-postayı Yeniden Gönder',
    ask: 'Sor ',
    supportTeamToInstall: ' Destek Ekibi\'ne yüklemesini sormak için',
    raiseTheInstallationRequestTo: 'Yükleme talebini ',
    team: ' ekibine bildirin',
    helpMeInstallingChatBot: 'Sohbet Botu Kurulumu Konusunda Bana Yardım Edin',
    yourBotInstallationIsNotActive: 'Bot kurulumunuz etkin değil!',
    monthlyMessageLimitExceeded: 'Aylık mesaj sınırı aşıldı',
    exceededMonthlyMessageLimitUpgradeNow: 'Aylık mesaj sınırını aştınız, Şimdi yükseltin.',
    upgradeNow: 'Şimdi yükselt',
    botInstallationNotActivePleaseFix: 'Bot kurulumunuz etkin değil, Lütfen düzeltin. ',
    incorrectBotScript: 'Yanlış Bot Betiği',
    installationCodeYouAreVerfiyingNotMatchingOnYourSite: 'Doğruladığınız kurulum kodu, sitenizdekiyle eşleşmiyor. Lütfen bu bot için doğru kodun kurulduğundan emin olun.',
    unableToSee: 'Görülemiyor ',
    codeOnYourWebsite: ' Web sitenizdeki kod',
    unableToFindCodePleaseReinstall: 'Kodu web sitenizde bulamıyoruz, Lütfen yeniden kurun.',
    reinstallBot: 'Botu yeniden kur',
    yourBotIsInstalledAndActive: 'Botunuz kuruldu ve etkin!',
    noPendingAction: 'Bekleyen bir işlem yok, Botunuz kuruldu ve etkin',
    youAreAllSet: 'Hepsi hazır!',
    showMeMyLeads: 'Bana leadlarımı göster',
    connectingToYourWebsiteToVerifyInstallation: 'Kurulumunuzu doğrulamak için web sitenize bağlanılıyor',
    thisMayTakeUpto30Seconds: 'Bu işlem en fazla 30 saniye sürebilir',
    hereIsTheuniqueAILink: 'İşte benzersiz AI Bot bağlantınız',
    installHeading: 'Sohbet Botunuzu Kurun',
    websiteChatbot: 'Web Sitesi Sohbet Botu',
    aiChatBot: 'AI Sohbet Botu',
    landingPageBot: 'Landing Sayfa Botu',
    mobileChatBot: 'Mobil Uygulama Sohbet Botu',
    embeddedBot: 'Gömülü Sohbet Botu',
    selectMobileDevice: 'Mobil cihazınızı seçin',
    mobileDeviceSubheading: 'Kullandığınız mobil uygulama için kullanılan yerel dili seçin',
    ios: 'IOS',
    android: 'Android',
    reactNative: 'React Native',
    otherMobileDevice: 'Diğer',
    copyEmbeddedCodeSnippet: 'Bu kod parçasını kopyalayın ve sohbet botunu görüntülemek istediğiniz her web sayfasının HTML\'sine yapıştırın.',
    copyMobileCodeSnippet: 'Bu kod parçasını mobil uygulama kodunuza yapıştırın ve sohbet botunu görüntülemek istediğiniz yere ekleyin.',
    placeholders: {
      exampleSite: 'https://www.siteniz.com',
      enterDeveloperEmail: 'Geliştirici E-postasını Girin'
    },
    installGeneral: {
      sendInstructionsToYourDeveloper: 'Geliştiricinize Talimat Gönderin',
      notSureHowToDoIt: 'Nasıl yapılacağını bilmiyor musunuz? Geliştiricileriniz hızlıca yapabilir.',
      developersEmail: 'Geliştiricinin E-postası',
      emailIsRequired: 'E-posta gereklidir',
      subject: 'Konu',
      message: 'Mesaj',
      send: 'Gönder',
      placeholders: {
        enterYourWorkEmail: 'İş E-postanızı Girin',
        createBotUsingBotPenguin: 'Heyecanlı! BotPenguin kullanarak bot oluşturun',
      }

    },
    platforms: {
      hereIsUniqueLinkToLandingPageBot: 'İşte Landing Sayfası Botunuzun benzersiz bağlantısı.',
      shareWithUsersOnSocialMedia: 'Sosyal medya, iletişim sayfaları veya iş kartları gibi kullanıcılarınızla paylaşın.',
      previewMyLandingPageBot: 'Landing Sayfa Botumu Önizle',
      or: 'VEYA',
      copyUrl: 'URL’yi Kopyala',
      howYouLikeToAddBot: 'Bot kodunuzu web sitenize nasıl eklemek istediğinizi seçin',
      installTheBotToYourCustomCodedOrWordPressWebsite: 'Bunu kopyalayarak Botu özel kodlu veya WordPress web sitenize kurun benzersiz bir kod.',
      name: 'Ad',
      actions: 'Eylemler',
      javascript: 'Javascript',
      install: 'Yükle ',
      onYourCustomCodedWebsite: ' özel kodlu web sitenizde',
      copyAndPaste: 'Kopyala ve yapıştır',
      copyThisCodeSnippet: 'Bu kod parçacığını kopyalayın ve Widget’ı görüntülemek istediğiniz her web sayfasının HTML’sine ekleyin. Daha fazla ayrıntı için',
      seeGuide: 'rehberi görüntüleyin',
      getCode: 'Kod Alın',
      howToInstall: 'Nasıl yüklenir?',
      copyCode: 'Kodu kopyala',
      wordpress: 'WordPress',
      // install: 'Install ',
      onYourWordPressWebiste: ' WordPress web sitenizde',
      preview: 'Önizle',

    }
  },
  liveChatPreview: {
    liveChatPreview: 'Canlı Sohbet Önizlemesi',
    agentWindow: 'Müşteri Temsilcisi Penceresi',
    person: 'kişi',
    inOrderToTestTypeHere: 'Test etmek için buraya yazın',
    agentWindowCannotBeCustomized: 'Not: Müşteri Temsilcisi Penceresi özelleştirilemez',
    customerWindow: 'Müşteri Penceresi',
    clickHereToChat: 'Sohbet etmek için burayı tıklayın',
    customerWillChatHere: 'Müşteri burada sohbet edecek',
    chat: 'Sohbet',
    by: 'tarafından',
    changeYourBotLookFromLeftPanel: 'Bot görünümünüzü sol panelden değiştirin',
    placeholders: {
      typeYourMessage: 'Mesajınızı yazın...',
      typeYourAnswer: 'Cevabınızı yazın',
    }

  },
  previews: {
    previewYourLiveChatWidget: 'Canlı Sohbet Widget\'ınızı Önizleyin',
    install: 'Yükle',
    seeHowToUse: 'Kullanımı Nasıl Görmek İstersiniz?',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Seçilen platforma botunuzu yüklemek için tıklayın',
    }

  },
  trainYourBot: {
    trainYourBot: 'Botunuzu Eğitin',
    knowledgeBase: 'Bilgi Tabanı',
    faqs: 'Sık Sorulan Sorular',
    history: 'Geçmiş',
    faqHeading: 'Sıkça Sorulan Sorular',
    faqSubHeading: 'Botunuzun Eğitimi İle İlgili Sorularınıza Cevaplar',
    knowledgeBaseHeading: 'Botunuzu Eğitin',
    knowledgeBaseSubheading: 'Gelişmiş Sohbetler İçin Botunuzu Eğitin',
    websiteLink: 'Web Sitesi Bağlantısı',
    uploadFile: 'Dosyanızı Yükleyin',
    selectFile: 'Dosya Seçin',
    submit: 'Gönder',
    searchHere: 'Burada Ara',
    question: 'Soru',
    answer: 'Cevap',
    questionPlaceholder: 'Sorunuzu buraya yazın',
    answerPlaceholder: 'Cevabınızı buraya yazın',
    save: 'Kaydet',
    discard: 'İptal Et',
    addFaq: 'Sık Sorulan Sorular Ekle',
    createdAt: 'Oluşturulma Tarihi',
    updatedAt: 'Son Güncelleme Tarihi',
    actions: 'İşlemler',
    noFaqFound: 'Henüz oluşturulmuş bir Sık Sorulan Soru bulunmamaktadır',
    noFaqFoundMessage: 'Tüm Sık Sorulan Sorularınız burada görünecektir',
    historyTitle: 'Bilgi Tabanı Geçmişi',
    historySubtitle: 'Botunuzun Eğitimi İle İlgili Sorularınıza Cevaplar',
    fileName: 'Dosya Adı',
    status: 'Durum',
    noHistoryTitle: 'Henüz oluşturulmuş eğitilmiş bir model bulunmamaktadır',
    noHistoryDescription: 'Tüm eğitilmiş modelleriniz burada görünecektir',
    fileUpload: 'Dosya Yükleme',
    files: 'Dosyalar',
    fileSubHeading: 'Botunuzu eğitmek istediğiniz tüm dosyaları ekleyin.',
    noFileFoundHeading: 'Henüz oluşturulmuş bir dosya bulunmamaktadır',
    noFileFoundSubheading: 'Tüm dosyalarınız burada görünecektir',
    uploadYourFiles: 'Dosyalarınızı Yükleyin',
    upload: 'Yükle',
    fileShouldBe: 'Dosya PDF, DOC, DOCX, PPTX, TXT olmalıdır.',
    uploadStatus: 'Yükleme Durumu',
    uploadStatusSubHeading: 'Bot eğitimi için dosyaların yükleme durumu',
    addFile: 'Dosya Ekle',
    otherConfiguration: 'AI Ayarları',
    deleteFiles: 'Dosyaları silmek için tıklayın',
    websiteURL: 'Web Sitesi URL\'leri',
    websiteUrlSubheading: 'Botunuzu eğitmek istediğiniz tüm web sitesi URL\'lerini ekleyin.',
    enterWebsiteUrl: 'URL\'yi Girin',
    addWebsite: 'Web Sitesi Ekle',
    tableWebsiteUrl: 'Web Sitesi URL\'si',
    trainingStatus: 'Eğitim Durumu',
    lastTrained: 'Son Eğitim',
    noWebsitesFound: 'Henüz oluşturulmuş bir web sitesi bulunmamaktadır',
    noWebsitesFoundMessage: 'Tüm web siteleriniz burada görünecektir',
    loadMore: 'Daha Fazla Yükle',
    botBehaviour: 'Bot Davranışını Yapılandırın',
    botBehaviourSubHeading: 'Botun kişiliğini, müşteri verilerini sansürlemeyi, akışı ve ChatGPT modelini yapılandırın',
    botPersonality: 'Bot Kişiliğini Yapılandırın',
    botPersonalitySubHeading: 'Botunuzun kişilik açısından nasıl cevap vereceğini yönetin',
    selectAnswerLength: 'Cevap Uzunluğunu Seçin',
    answerLength: 'Cevap Uzunluğu',
    answerAs: 'Şu şekilde cevap ver',
    tone: 'Ton',
    language: 'Dil',
    answerFormatting: 'Cevap Biçimlendirme',
    customInstructions: 'Özel Talimatlar',
    customerInstructionPlaceholder: 'Örnek Talimat: Parola, kredi kartı bilgileri veya hesap bilgileri gibi kişisel bilgileri talep etmemeniz veya paylaşmamanız gerektiğinden emin olun.',
    redactCustomerData: 'Müşteri Verilerini Topla',
    redactDataSubheading: 'Toplamak istediğiniz müşteri bilgilerini yapılandırın',
    collectName: 'Ad Topla',
    configureQuestion: 'Soruyu Yapılandır',
    maxCharacterLimit: 'Sağlanan Ad İçin Maksimum Karakter Sınırı',
    collectEmail: 'E-Posta Topla',
    excludeEmail: 'Alanlarıyla E-Postaları Hariç Tut',
    collectPhone: 'Telefon Topla',
    manageUnusualMessages: 'Olağandışı Sohbet Senaryolarını Yönet',
    unusualMessagesSubheading: 'Cevap verilmemiş ve diğer nadir senaryoları bot için ele alın',
    startConversation: 'Sohbeti başlatmak için iletilen mesaj',
    configureMessage: 'Bot cevap veremediğinde iletilen mesaj',
    profanityMessage: 'Uygunsuz dil kullanımına yanıt vermek için iletilen mesaj',
    chatGptModel: 'ChatGPT Modeli Yapılandırma',
    chatGptModelSubheading: 'Hangi ChatGPT modelinin kullanılacağını yönetin ve yanlış kullanımı önleyin',
    chatGptApi: 'ChatGPT API anahtarı',
    enableDisableRedactData: 'Müşteri Verilerini Toplamayı Aç/Kapat',
    triggerToCollectData: 'Soruların ardından iletişim bilgilerini toplamayı etkinleştir',
    train: 'Eğit',
    website: 'Web Sitesi',
    includedSourcesForResponse: 'Cevap için dahil edilen kaynaklar',
    visibility: 'Görünürlük',
    analytics: 'Analitik',
    yourConversation: 'Konuşmalarınız',
    reviewAndSelectConversation: 'Chatbot Eğitimi için İncele ve Konuşmaları Seçin',
    exportAiAnalytics: 'Analitikleri Dışa Aktar',
    startDate: 'Başlangıç Tarihi',
    endDate: 'Bitiş Tarihi',
    userQuestion: 'Kullanıcının Sorusu',
    botsReply: 'Botun Cevabı',
    dateCreated: 'Oluşturulma Tarihi',
    noAnalyticsFound: 'Henüz oluşturulmuş herhangi bir analitik bilginiz yok',
    noAnalyticsFoundMessage: 'Tüm analitik mesajlarınız burada görünecektir',
    exportData: 'Veri Dışa Aktar',
    exportCompleteAnalyticsData: 'Tüm Analitik Verileri Dışa Aktar',
    export: 'Dışa Aktar',
    integrateWithChatGpt: 'ChatGPT ile Entegrasyon',
    integrated: 'Entegrasyonlandı',
    configure: 'Yapılandır',
    appToolTip: {
      deleteWebsite: 'Web sitelerini silmek için tıklayın',
      knowledgeBase: 'Bilgi tabanı ile botunuzu eğitin',
      faqs: 'Sıkça Sorulan Sorular ile botunuzu eğitin',
      knowledgeHistory: 'Bilgi tabanı geçmişi',
      uploadFaqs: 'Sıkça Sorulan Soruları yükle',
      addFaqs: 'Sıkça Sorulan Soruları eklemek için tıklayın',
      deleteFaqs: 'Sıkça Sorulan Soruları silmek için tıklayın',
      deleteHistory: 'Geçmişi silmek için tıklayın',
      uploadFile: 'Yüklenen dosya PDF, DOCX, DOC veya CSV formatında olmalı ve maksimum 1 MB boyutunda olmalıdır.',
      trainWebsite: 'Bir web sitesi kullanarak botunuzu eğitin.',
      fileUpload: 'PDF, TXT, PPTX, DOC ve DOCX gibi dosyaları kullanarak botunuzu eğitin.',
      configureBotBehaviour: 'Bot kişiliğini özelleştirin, akışı yönetin ve ChatGPT modelini ayarlayın.',
      clickToAddWebsite: 'Web sitesi eklemek için tıklayın',
      clickToDeleteWebsites: 'Web sitelerini silmek için tıklayın',
      syncWebsite: 'Web sitesini senkronize edin',
      copyUrl: 'URL kopyala',
      weWillBeFetchingAllTheWebpages: 'Tüm web sayfalarını çekecek ve ChatGPT modelini onlara eğiteceğiz.'
    },
    modal: {
      deleteWebsiteUrl: 'Web Sitesi URL\'sini Sil',
      areYouSureYouWantToDeleteThisWebsiteUrl: 'Bu Web Sitesi URL\'sini silmek istediğinizden emin misiniz?'
    }

  },
  configuration: {
    websiteChatbotSetting: 'Web Sitesi Sohbet Botu Ayarı',
    preview: 'Önizleme',
    test: 'Test',
    install: 'Yükle',
    designHeader: 'Tasarım',
    generalHeader: 'Genel',
    alertsHeader: 'Uyarılar',
    triggerHeader: 'Tetikleyici',
    userAccessHeader: 'Kullanıcı Erişimi',
    chatWindowHeader: 'Sohbet Penceresi',
    otherConfiguration: 'AI Ayarları',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Botunuzu seçilen platforma yüklemek için tıklayın',
      customizeAppearanceOfBot: 'Bot arayüzünün konumunu ve görünümünü özelleştirin.',
      configureBotsSettingsWhenAgentUnavailable: 'Acenteniz müsait olmadığında botun ayarlarını yapılandırın.',
      configureHowBotWillAlertYou: 'Botun sizi yeni potansiyel müşterilerden nasıl haberdar edeceğini yapılandırın.',
      makeSureYourBotsPopUpProperlyTimed: 'Botunuzun açılışlarının mükemmel zamanlamada olduğundan emin olun.',
      customizeWhereYouWantYourBotToAppear: 'Botunuzun nerede görünmesini istediğinizi özelleştirin ve nerede görünmemesini sağlayın.',
      enableChatOptionsForVisitorsToTransferChat: 'Ziyaretçilerinizin sohbeti aktarma, canlı sohbet ve sohbeti yenileme seçeneklerini etkinleştirin.',
      configureBotBehaviour: 'Bot kişiliğini özelleştirin, akışı yönetin ve ChatGPT modelini ayarlayın.',
    },
    alerts: {
      configureYourBotsAlertSettings: 'Bot Uyarı Ayarlarınızı Yapılandırın',
      specifyHowYourBotWillNotify: 'Botunuzun yeni potansiyel müşteriler hakkında sizi nasıl bilgilendireceğini belirtin.',
      subject: 'Konu',
      emailNotification: 'E-posta Bildirimi',
      emailAddresses: 'E-posta Adresleri',
      leadRevisitNotification: 'Potansiyel Müşteri Yeniden Ziyaret Bildirimleri',
      youHaveSuccessfullyRegisteredWithDesktopNotifications: 'Masaüstü bildirimleri ile başarıyla kaydoldunuz',
      youHaveBlockedYourNotificationsOnBrowser: 'Tarayıcı ayarlarınızda bildirimleri engellediniz.',
      discard: 'Reddet',
      saveChanges: 'Değişiklikleri Kaydet',
      seeHowToUse: 'Nasıl kullanılacağını görün',
      placeholders: {
        enterSubjectForYourEmail: 'E-posta için konuyu girin',
        typeYourEmailAddressAndHitEnter: 'E-posta adresinizi yazın ve Enter tuşuna basın',
      },
      appTooltip: {
        TOOLTIP_SUBJECTS: 'Botunuzun e-posta bildirimlerinin konusunu özelleştirin',
        TOOLTIP_EMAIL_NOTIFICATION: 'Yeni potansiyel müşteriler için botun e-posta bildirimini etkinleştirin/devre dışı bırakın',
        TOOLTIP_EMAIL_ADDRESS: 'E-posta bildirimlerini almak istediğiniz hesabın e-posta adresini girin. Birden fazla e-posta adresi eklemek için birini girip başka bir tane eklemek için Enter tuşuna basın.',
        TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'Daha önce bir potansiyel müşteri olarak yakalanan bir kullanıcı bot ile tekrar etkileşime geçerse e-posta bildiriminin etkinleştirilip/devre dışı bırakılmasını sağlayın.',
      }

    },
    broadcasts: {
      createBroadcastHeading: 'Yayın Oluştur',
      youCanCreateMultipleBroadcasts: 'Çoklu yayınlar oluşturabilirsiniz',
      broadcastName: 'Yayın Adı',
      broadcastNameIsRequired: 'Yayın adı zorunludur',
      continue: 'Devam Et',
      manageBroadcasts: 'Yayınları Yönet',
      youCanCreateMultipleBroadcastsWithMultipleTemplatesAndSegments: 'Farklı müşteri grupları için birden fazla şablon içeren birden fazla yayın oluşturabilirsiniz.',
      createNewBroadcast: 'Yeni Yayın Oluştur',
      name: 'Ad',
      segment: 'Grup',
      template: 'Şablon',
      contacts: 'Kişiler',
      send: 'Gönder',
      delivered: 'Teslim Edildi',
      read: 'Okundu',
      failed: 'Başarısız',
      broadcastAt: 'Yayın Zamanı',
      status: 'Durum',
      actions: 'İşlemler',
      nA: 'Yok',
      seeHowToUse: 'Kullanımı nasıl görüntüleyebilirsiniz',
      deleteBroadcast: 'Yayını Sil',
      wantToDeleteThisBroadcast: 'Bu Yayını silmek istediğinizden emin misiniz?',
      placeholders: {
        searchHere: 'Burada Ara',
        enterBroadcastName: 'Yayın adını girin'
      },
      appTooltip: {
        deleteMultipleBroadcasts: 'Çoklu yayınları sil',
        broadcastAnalytics: 'Yayın Analitiği',
        deleteBroadcast: 'Yayını Sil',
      },
      broadcastAnalytics: {
        broadcastInsights: 'Yayın Analitiği',
        broadcastStatus: 'Yayın Durumu',
        youCanFindDetailsOfEachContactBelow: 'Her kişinin ayrıntılarını aşağıda bulabilir ve sorunları olabilecek olanları dışa aktarabilirsiniz.',
        filterApplied: 'Filtre Uygulandı',
        placeholders: {
          searchByPhoneNumber: 'Telefon Numarasına Göre Ara',
          searchByName: 'Adıyla filtrele'
        },
        appTooltip: {
          filterDataBasedOnStatus: 'Duruma göre verileri filtrele',
          exportData: 'Verileri Dışa Aktar',
        },
        modal: {
          exportData: 'Verileri Dışa Aktar',
          exportCompleteBroadcastData: 'Tüm yayın verilerini dışa aktar',
          export: 'Dışa Aktar'
        }
      },
      createBroadcast: {
        broadcastName: 'Yayın adı',
        broadcastNameIsRequired: 'Yayın adı zorunludur',
        template: 'Şablon',
        segments: 'Gruplar',
        whenToSend: 'Gönderme Zamanı',
        now: 'ŞİMDİ',
        later: 'SONRA',
        selectBroadcastUsing: 'Kullanarak yayını seçin',
        usingCsv: 'CSV Kullanarak',
        usingSegment: 'Grup Kullanarak',
        allContacts: 'Tüm Kişiler',
        selectDate: 'Tarih Seç',
        selectTimeSlot: 'Zaman Aralığı Seç',
        assignVariablesToTemplates: 'Şablona değişken atama',
        addHeaderMedia: 'Başlık Medyası Ekle',
        selectFile: 'Dosya Seç',
        cancel: 'İptal',
        createBroadcast: 'Yayın Oluştur',
        broadcastUsing: 'Kullanarak Yayın Yap',
        selectCSVFile: 'CSV Dosyası Seç',
        importContacts: 'Kişileri İçe Aktar',
        beforeYouStartUpload: 'Yüklemeden önce lütfen şunları yapın',
        point1: '1. Örnek CSV dosyasını indirin.',
        point2: '2. Verilerinizi 2. sıradan itibaren girmeye başlayın.',

        point3: '3. Her satırda bir kişiye ait veri bulunacaktır.',
        point4: '4. Tüm telefon numaralarının benzersiz olduğundan emin olun.',
        point5: '5. Maksimum dosya boyutu yükleme sınırı 20 MB\'dır.',
        clickHere: 'Buraya tıklayın',
        toDownloadSampleFile: 'örnek dosyayı indirmek için',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          selectTemplate: 'Şablon Seç',
          searchTemplateByName: 'İsme göre şablon ara',
          selectSegments: 'Grupları Seç',
          selectTimeSlot: 'Zaman Aralığı Seç',
          selectValueForThisVariable: 'Bu değişken için değer seçin',
          searchHeaderByVariableName: 'Değişken adına göre başlık değişkenini arayın',
          variableFallbackValue: 'Değişken Yedek Değeri',
          searchBodyVariableByName: 'Adına göre gövde değişkenini ara',
          searchSegmentByName: 'İsme Göre Grup Ara',
          selectBroadcastUsing: 'Kullanarak yayını seçin',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Başlangıç tarihi seçin',
        },
        appTooltip: {
          importContactsForWhatsappBroadcast: 'Whatsapp yayını için kişileri içe aktar',
        }
      },
      failedBroadcast: {
        whatsappNumber: 'WhatsApp Numarası',
        errorCode: 'Hata Kodu',
        errorMessage: 'Hata Mesajı',
        nA: 'Yok',
      }
    },
    design: {
      customizeYourLandingPage: 'Landing Sayfanızı Özelleştirin',
      customizeYourConversationalBot: 'AI Botunuzu Özelleştirin',
      customizeYourBot: 'Botunuzu Özelleştirin',
      createHeadingTextAndTheme: 'Markanızın kişiliğini yansıtan başlık metni ve tema oluşturun',
      content: 'İçerik',
      theme: 'Tema',
      layout: 'Düzen',
      landingPageName: 'Landing Sayfa Adı',
      botName: 'Bot Adı',
      charactersRemaining: ' kalan karakter',
      landingPageHeader: 'Landing Sayfa Başlığı',
      landingPageDescription: 'Landing Sayfa Açıklaması',
      hideContent: 'İçeriği Gizle',
      profilePic: 'Profil Resmi',
      selectFile: 'Dosya Seç',
      socialMediaLinks: 'Sosyal Medya Bağlantıları',
      initialMessage: 'Sohbet botu başlangıç mesajı',
      flowTriggerDetail: 'İletişimsel bot iletişim bilgilerini toplamak için tetikleyici',
      selectType: 'Tür Seçin',
      url: 'URL',
      delete: 'Sil',
      editLink: 'Bağlantıyı Düzenle',
      addLink: 'Bağlantı Ekle',
      callToAction: 'Eylem Çağrısı',
      liveChatWelcomeMessage: 'Canlı Sohbet Hoş Geldiniz Mesajı',
      liveChatTriggerMessage: 'Canlı Sohbet Tetikleyici Mesajı',
      autoHideCallToAction: 'Eylem Çağrısını Otomatik Gizle',
      maxDelayShouldBe300Seconds: 'Maksimum gecikme 300 saniye olmalıdır',
      landingPageThemeColor: 'Landing Sayfa Tema Rengi',
      themeColor: 'Tema Rengi',
      landingPageBackground: 'Landing Sayfa Arkaplanı',
      chatBackgroundColor: 'Sohbet Arkaplan Rengi',
      color: 'Renk',
      gradient: 'Gradyan',
      image: 'Resim',
      video: 'Video',
      gradientStart: 'Gradyan Başlangıcı',
      gradientEnd: 'Gradyan Sonu',
      // selectFile: 'Dosya Seç',
      positionOnWeb: 'Web üzerinde konum',
      positionOnMobile: 'Mobilde konum',
      windowSize: 'Pencere boyutu',
      s: 'S',
      m: 'M',
      l: 'L',
      xl: 'XL',
      xxl: 'XXL',
      custom: 'Özel',
      height: 'Yükseklik',
      minimum300HeightIsRequired: 'Minimum 300px yükseklik gereklidir',
      heightCannotExceed720: 'Yükseklik 720px\'yi aşamaz',
      width: 'Genişlik',
      px: 'px',
      minimum200WidthIsRequired: 'Minimum 200px genişlik gereklidir',
      widthCannotExceed1050: 'Genişlik 1050px\'yi aşamaz',
      discard: 'At',
      saveChanges: 'Değişiklikleri Kaydet',
      landingPageBot: 'Landing Sayfa Botu',
      chatBot: 'Sohbet Botu',
      preview: 'Önizleme',
      test: 'Test',
      botIcon: 'Bot Simgesi',
      websiteBot: 'Web Sitesi Sohbet Botu',
      selectFont: 'Yazı Tipi Seç',
      fontLibrary: 'Yazı Tipi Kütüphanesi',
      uploadFont: 'Yazı Tipi Yükle',
      selectFontForBot: 'Bot için yazı tipi seç',
      uploadedFont: 'Yüklenen Yazı Tipi',
      placeholders: {
        enterName: 'Adınızı Girin',
        enterHeaderText: 'Başlık metnini girin',
        enterDescription: 'Açıklama metnini girin',
        enterUrl: 'URL girin',
        howCanIHelpYou: 'Size nasıl yardımcı olabilirim?',
        enterYourWelcomeMessageHere: 'Hoş geldiniz mesajınızı buraya girin',
        enterYourLiveChatMessageHere: 'Canlı sohbet mesajınızı buraya girin',
        enterTimeInSeconds: 'Saniye cinsinden zamanı girin',
        enterHeight: 'Yüksekliği girin',
        enterWidth: 'Genişliği girin',
        flowInitialMessage: 'İlk soruyu girin',
      },
      appTooltip: {
        standardLayout: 'Standart düzen',
        rightLayout: 'Sağ düzen',
        mergedLayout: 'Birleştirilmiş düzen',
        enterNameOfYourBot: 'Bot adınızı girin',
        enterNameOfYourLandingPageBot: 'Landing sayfa botunuzun adını girin',
        headingTextThatAppearOnLandingPageBot: 'Landing sayfa botunuzda görünmesini istediğiniz başlık metnini girin',
        descriptionTextThatAppearOnLandingPageBot: 'Landing sayfa botunuzda görünmesini istediğiniz açıklama metnini girin.',
        addLinksAndSocialMediaIcons: 'Bağlantılar ve sosyal medya simgeleri ekleyin',
        initialFlowMessage: 'Müşteriyle iletişimi başlatmak için botun göndereceği ilk mesajı girin',
        anInvitingCatchPhrase: 'Kullanıcının botla konuşmasını sağlayacak davetkar bir slogan',
        setAWelcomeMessageForYourVisitors:
          'Ziyaretçileriniz için bir hoş geldiniz mesajı ayarlayın. Bu, konuşmanızın ilk mesajı olacaktır.',
        setAMessageToInitiateLiveChat: 'Ziyaretçinin sonunda canlı sohbeti başlatmak için bir mesaj ayarlayın. Bu mesajın herhangi bir konuşmanızda tetiklendiğinde bildirim alacaksınız.',
        autoHideCallToAction: `'Eylem Çağrısı' metnini belirtilen süre sonra otomatik olarak gizle`,
        TOOLTIP_HIDE_LANDINGPAGE_INFO: 'Ekranın sadece sohbet penceresini göstermek için logosu ve metinleri gizlemeyi seçebilirsiniz',
        TOOLTIP_LANDINGPAGE_PROFILE_ICON: 'Sohbetbotu sayfası için bir logo yükleyin.',
        TOOLTIP_CHAT_THEME_COLOR: 'Botunuzun sohbet penceresinin rengini seçin',
        TOOLTIP_CHAT_THEME_BACKGROUND_COLOR: 'Botun sohbet penceresinin arka plan rengini seçin',
        TOOLTIP_WEB_1ST_POSITION: 'Botunuzu kullanıcının masaüstünün sol alt köşesine yerleştirin',
        TOOLTIP_WEB_2ND_POSITION: 'Botunuzu kullanıcının masaüstünün sağ orta köşesine yerleştirin',
        TOOLTIP_WEB_3RD_POSITION: 'Botunuzu kullanıcının masaüstünün sağ alt köşesine yerleştirin',
        TOOLTIP_MOBILE_1ST_POSITION: 'Botunuzu kullanıcının mobil cihazının sol alt köşesine yerleştirin',
        TOOLTIP_MOBILE_2ND_POSITION: 'Botunuzu kullanıcının mobil cihazının sağ orta köşesine yerleştirin',
        TOOLTIP_MOBILE_3RD_POSITION: 'Botunuzu kullanıcının mobil cihazının sağ alt köşesine yerleştirin',
        TOOLTIP_WINDOW_HEIGHT: 'Botun sohbet penceresini yeniden boyutlandırın',
        TOOLTIP_BOT_ICON: 'Botunuz için bir simge seçin veya logo/resim yükleyin',
        flowTriggerMessage: 'İsim, E-posta ve Telefon gibi iletişim bilgilerini toplamadan önce sorulacak mesaj sayısını girin',
      }
    },
    dripCampaigns: {
      createDripCampaignHeader: 'Damlalama Kampanyası Oluştur',
      youCanCreateMultipleCampaigns: 'Birden çok damlalama kampanyası oluşturabilirsiniz',
      dripCampaignName: 'Damlalama Kampanyası Adı',
      dripCampaignNameIsReuired: 'Damlalama Kampanyası Adı gereklidir',
      continue: 'Devam Et',
      manageDripCampaign: 'Damlalama Kampanyalarını Yönet',
      youCanCreateMultipleCampaignsWith: 'Planlanmış birden fazla damlama kampanyası oluşturabilirsiniz' +
        'belirli kullanıcı gruplarından yanıt almayı tetikleyen otomatik mesajlar.',
      createNewDripCampaign: 'Yeni Damlalama Kampanyası Oluştur',
      campaignName: 'Kampanya Adı',


      appliedOn: 'Uygulandı',
      segment: 'Grup',
      template: 'Şablon',
      startDate: 'Başlangıç Tarihi',
      action: 'Aksiyon',
      nA: 'NA',
      seeHowToUse: 'Nasıl kullanılacağını gör',
      deleteDripCampaign: 'Damlalama Kampanyalarını Sil',
      wantToDeleteThisDripCampaign: 'Bu Damlalama Kampanyalarını silmek istediğinizden emin misiniz?',
      placeholders: {
        enterDripCampaignName: 'Damlalama Kampanyası Adını Girin',
        searchByName: 'İsimle Ara'
      },
      appTooltip: {
        deleteMultipleDripCampaigns: 'Birden çok damlalama kampanyasını sil',
        deleteDripCampaign: 'Damlalama Kampanyasını Sil',
      },
      createDripCampaign: {
        dripCampaignName: 'Damlalama Kampanya Adı',
        appliedOn: 'Uygulandı',
        segments: 'Gruplar',
        selectDate: 'Tarih Seç',
        scheduleYourTemplate: 'Şablonlarınızı Zamanlayın',
        schedule: 'Zamanlama',
        template: 'Şablon',
        sendAfter: 'Sonrasında Gönder',
        assignVariablesToTemplate: 'Şablonlara Değişken Ata',
        addHeaderMedia: 'Başlık Medyası Ekle ',
        selectFile: 'Dosya Seç',
        createDripCampaign: 'Damlalama Kampanyası Oluştur',
        newSubscriber: 'Yeni Abone',
        minutes: 'Dakika',
        hours: 'Saat',
        days: 'Gün',
        placeholders: {
          enterDripName: 'Damlalama adını girin',
          selectAppliedOn: 'Uygulama türünü seçin',
          selectSegments: 'Grupları Seç',
          searchByName: 'İsimle Ara',
          selectTemplate: 'Şablon Seçin',
          searchTemplateByName: 'İsme Göre Şablon Ara',
          enterSendAfter: 'Sonrasında Gönder',
          selectValueForThisVariable: 'Bu değişken için değer seçin',
          searchHeaderVariableByName: 'Başlık Değişkenini İsme Göre Ara',
          searchBodyVariableByName: 'Gövde Değişkenini İsme Göre Ara',
        },
        dateTimePicker: {
          selectStartDate: 'Başlangıç tarihini seçin',
        }
      }
    },
    general: {
      configureLandingPageGeneralSettings: `Landing Sayfası Genel Ayarları Yapın`,
      configureBotsGeneralSettings: `Bot Genel Ayarları Yapın`,
      selectTheTimeZone: 'Zaman dilimini, dili ve müşterilerinize bir not eklemek için seçin ',
      isNotAvailable: ` kullanılamıyor`,
      landingPagesBot: `landing sayfası botu`,
      bot: 'bot',
      waitingMessage: 'Bekleme Mesajı',
      landingPageBotStatus: 'Landing Sayfası Bot Durumu',
      chatBotStatus: 'Chatbot Durumu',
      unavailabilityMessage: 'Kullanılamazlık Mesajı',
      sendIncompleteResponses: 'Eksik Cevapları Gönder',
      language: 'Dil',
      invalidateCache: 'Önbelleği Geçersiz Kıl',
      timezone: 'Zaman Dilimi',
      chatWindowMessageHistory: 'Sohbet Penceresi Mesaj Geçmişi',
      session: 'oturum',
      forever: 'her zaman',
      english: 'İngilizce',
      spanish: 'İspanyolca',
      french: 'Fransızca',
      portuguese: 'Portekizce',
      german: 'Almanca',
      italian: 'İtalyanca',
      swedish: 'İsveççe',
      arabic: 'Arapça',
      malay: 'Malayca',
      russian: 'Rusça',
      discard: 'At',
      saveChanges: 'Değişiklikleri Kaydet',
      seeHowToUse: 'Kullanımı Nasıl Görüntüle',
      placeholders: {
        enterYourWaitingMessageHere: 'Bekleme mesajınızı buraya girin',
        // selectLanguage: '',
        searchTimeZone: 'Zaman dilimi arayın',
        // selectChatRetentionPolicy: '',
      },
      appTooltip: {
        TOOLTIP_WAITING_MESSAGES: 'Bot kullanıcısı canlı sohbet için talepte bulunduğunda hemen sohbet penceresinde görünmesini istediğiniz mesajı özelleştirin',
        TOOLTIP_CHATBOT_STATUS: 'Botu etkinleştirin / devre dışı bırakın',
        TOOLTIP_UNAVAILABILITY_MESSAGE: 'Hiçbir ajanınızın bot kullanıcısıyla canlı sohbet yapamadığı durumda sohbet penceresinde görünmesini istediğiniz mesajı özelleştirin',
        TOOLTIP_SEND_INCOMPLETE_RESPONSES: 'Bot kullanıcısı sohbeti tamamlamamış olsa bile e-posta bildirimleri alın.',
        TOOLTIP_LANGUAGE: 'Botun dilini seçin',
        TOOLTIP_INVALIDATE_CACHE: 'Bot sohbet akışını veya ayarlarını düzenlediğinizde, değişiklikler yalnızca 15 dakika sonra yansıdığı için önbelleği temizlemek için burayı tıklayın',
        TOOLTIP_TIME_ZONE: 'Botunuz için zaman dilimini seçin',
        TOOLTIP_CHAT_RETENTION: 'Bot kullanıcısının sohbetlerini sohbet penceresinde her zaman mı saklamak istediğinizi yoksa yalnızca bir tarayıcı oturumu için mi saklamak istediğinizi seçin.',
      }
    },
    liveChat: {
      customizeYourChatWindow: 'Sohbet pencerenizi özelleştirin',
      controlAndCustomizeTheChatWindow: 'Müşterilerinizin etkileşimde bulunacağı sohbet penceresini kontrol edin ve özelleştirin.',
      allowVisitorsToTransferChatToWhatsapp: 'Ziyaretçilerin sohbeti WhatsApp\'a aktarmasına izin verin',
      allowVisitorsToRefreshChat: 'Ziyaretçilere sohbeti yenilemelerine izin verin',
      displayNameForTransferringChatToWhatsapp: 'Sohbeti WhatsApp\'a aktarma için görüntülenen ad',
      displayNameForTransferringToLiveChat: 'Canlı sohbet veya ajan aktarma için görüntülenen ad',
      displayNameForTransferringAChatToBot: 'Sohbeti Bota aktarma için görüntülenen ad',
      discard: 'At',
      saveChanges: 'Değişiklikleri Kaydet',
      allowVisitorsToTransferChatToBot: 'Ziyaretçilerin sohbeti Bota aktarmasına izin verin.',
      allowVisitorsToRequestForLiveChat: 'Ziyaretçilere canlı sohbet isteme izni verin.',
      indicativeTextForCustomersReply: `Müşterinin Yanıtı için Belirleyici Metin`,
      displayNameForRestartingChatBot: 'Sohbet Botunu Yeniden Başlatma için Görüntülenen Ad',
      seeHowToUse: 'Nasıl Kullanılacağını Görüntüle',
      placeholders: {
        enterButtonNameForWhatsapp: 'WhatsApp\'a aktarma için düğme adını girin',
        enterButtonNameForLiveChat: 'Canlı sohbet veya ajan aktarma için düğme adını girin',
        enterButtonNameForBot: 'Bota aktarma için düğme adını girin',
        enterTextDisplayInEmptyReplyArea: 'Boş yanıt alanında görüntülenen metni girin',
        enterButtonForRestartingConversation: 'Sohbeti Yeniden Başlatma için düğme adını girin',
      },
      appTooltip: {
        TOOLTIP_TRANSFER_CHAT_TO_WHATSAPP: 'Web sitesi ziyaretçilerinin sohbeti bağlı oldukları ilgili ajanın WhatsApp numarasına aktarmalarına izin verin. Bu özelliğin çalışması için Canlı Sohbet özelliğinin etkin olması gerekmektedir.',
        TOOLTIP_ALLOW_VISITOR_TO_REFRESH_CHAT: 'Bot kullanıcılarının sohbeti yenileyebilmeleri için izin verin, böylece sohbeti yeniden başlayabilirler.',
        TOOLTIP_ALLOW_TRANSFER_CHAT_TO_BOT: 'Bot kullanıcılarının sohbeti canlı sohbet ajanıyla yapılan sohbetin ortasında bot\'a aktarmalarına izin verin.',
        TOOLTIP_ALLOW_REQUEST_FOR_CHAT: 'Bot kullanıcılarının her istediklerinde canlı sohbet için talepte bulunmalarına izin verin.',
        ifYouHaveEnabledUserToTransferToWhatsapp: 'Kullanıcının WhatsApp\'a aktarmasına izin verdiyseniz, bu seçeneği nasıl görüntülemek istediğinizi doldurun',
        ifYouHaveEnabledUserToTransferToLiveChat: 'Kullanıcının canlı sohbete aktarmasına izin verdiyseniz, bu seçeneği nasıl görüntülemek istediğinizi doldurun',
        ifYouHaveEnabledUserToTransferToWhatsapp2: 'Kullanıcının WhatsApp\'a aktarmasına izin verdiyseniz, geri bot\'a nasıl aktarmak istediğinizi doldurun',
        howWouldYouLikeToDisplayReplySpaceForCustomers: 'Müşteriler için yanıt alanını nasıl görüntülemek istediğinizi doldurun',
        ifYouHaveEnabledUserToRestartChatBot: 'Kullanıcının sohbet botu konuşmasını yeniden başlatmasına izin verdiyseniz, bu seçeneği nasıl görüntülemek istediğinizi doldurun',
      }
    },
    socialPlatforms: {
      chatFlowsHeader: 'Sohbet Akışları',
      dripCampaignHeader: 'Damlalama kampanyası',
      widgetsHeader: 'Araçlar',
      broadcastHeader: 'Yayın',
      ordersHeader: 'Siparişler',
      manageHeader: 'Yönet',
      inputHeader: 'Giriş',
      apiKeyHeader: 'API Anahtarı',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Zamanlanmış sohbet akışlarının bir damlama kampanyasıyla müşteri onboard yolculuğu oluşturun.',
        TOOLTIP_CHAT_FLOW_TITLE: 'Sohbet akış şablonlarını özelleştirin ve farklı hedef kitleler için birden fazla sohbet akışı oluşturun.',
        TOOLTIP_WIDGET_TITLE: 'Araçlar (sohbet arayüzleri) oluşturun ve bunları tanıtım web sayfalarına yerleştirerek ziyaretçileri bota yönlendirin.',
        TOOLTIP_BROADCAST_TITLE: 'İletileri veya akışları, belirlenen saatte kişilerinize tek tıklamayla gönderin.',
        TOOLTIP_ORDERS_TITLE: 'Müşteriler tarafından verilen siparişlerin listesi',
        TOOLTIP_MANAGE_TITLE: 'Kişiler/oluşumlar ve anahtar kelimeler için bot işlevlerini yönetme',
        TOOLTIP_INPUT_TITLE: 'Kişiler/oluşumlar için cevap malzemesi olarak WhatsApp şablonları, resimler ve metin oluşturun.',
      },
      apiWrapper: {
        whatsappApiCredentials: 'WhatsApp API Kimlik Bilgileri',
        generateYourWhatsappApiKey: 'Özel olarak geliştirilmiş uygulamalarınızla entegre etmek için WhatsApp API anahtarınızı oluşturun.',
        generateApiKey: 'API Anahtarı Oluştur',
        webhookUrl: 'Webhook URL',
        addHeader: 'Başlık Ekle',
        test: 'Test Et',
        webhookNotSet: 'Webhook Ayarlanmadı',
        webhookHeaders: 'Webhook Başlıkları',
        setWebhookUrl: 'Webhook URL Ayarla',
        placeholders: {
          yourApiKey: 'API Anahtarınız',
          enterWebhookUrl: 'Webhook URL\'sini Girin',
          enterHeaderKey: 'Başlık Anahtarını Girin',
          enterValue: 'Değeri Girin',
        }
      },
      broadcast: {
        createBroadcast: 'Yayın Oluştur',
        createMultipleBroadcasts: 'Farklı müşteri grupları için birden fazla planlanmış akışa/şablona sahip birden fazla yayın oluşturabilirsiniz.',
        broadcastName: 'Yayın adı',
        nameIsRequired: 'Ad gereklidir',
        selectType: 'Türü Seç',
        selectYourFlowType: 'Akış türünüzü seçin',
        segment: 'Grup',
        selectYourSegmentType: 'Grup Türünüzü Seçin',
        whenToSend: 'Ne Zaman Gönderilsin',
        selectDate: 'Tarih Seç',
        messageTag: 'Mesaj Etiketi',
        selectMessageTag: 'Mesaj etiketi seçin',
        contentType: 'İçerik Türü',
        nonPromotional: 'Promosyon dışı (mesaj etiketleri uygulanmış)',
        tagEachMessgeThatComesBeforeAnyInteraction: 'Herhangi bir etkileşimden önce veya 24 saatten fazla gecikme adımlarından sonra gelen her mesajı uygun etiketle. Etiketsiz mesaj, 24 saat önce bot ile etkileşimde bulunan abonilere ulaşmaz.',
        promotional: 'Promosyonel (Mesaj etiketleri yok sayılır)',
        theseBroadcasts: 'Bu yayınlar promosyonları içerebilir, ancak hedef kitle, botunuzla son 24 saat içinde etkileşimde bulunan abonilerle sınırlıdır.',
        createNewBroadcast: 'Yeni Yayın Oluştur',
        sentMessages: 'Gönderilen Mesajlar',
        deliveredMessages: 'Teslim Edilen Mesajlar',
        readMessages: 'Okunan Mesajlar',
        failedMessages: 'Başarısız Mesajlar',
        type: 'Tür',
        template: 'Şablon',
        sent: 'Gönderildi',
        delivered: 'Teslim Edildi',
        read: 'Okundu',
        failed: 'Başarısız',
        broadcastAt: 'Yayın Zamanı',
        status: 'Durum',
        shipmentUpdate: 'Gönderi Güncellemesi',
        flow: 'Akış',
        resendFailedMessages: 'Başarısız Mesajları Yeniden Gönder',
        goBack: 'Geri Dön',
        number: 'Numara',
        errorCode: 'Hata Kodu',
        failedAt: 'Başarısızlık Tarihi',
        badGateway: '502 kötü ağ geçidi',
        loadMore: 'Daha Fazla Yükle',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          searchBroadcastByName: 'İsme göre yayını arayın',
        },
        appTooltip: {
          totalBroadcastMessagesSent: 'Toplam gönderilen yayın mesajları',
          totalBroadcastMessagesDelivered: 'Toplam teslim edilen yayın mesajları',
          totalBroadcastMessagesReadByContacts: 'Kişiler tarafından okunan toplam yayın mesajları',
          totalFailedBroadcastMessages: 'Toplam başarısız yayın mesajları',
          clickToViewDetailedInformationOfFailedMessages: 'Başarısız mesajların ayrıntılı bilgilerini görüntülemek için tıklayın',
          clickToCreateNewBroadcast: 'Yeni Yayın Oluşturmak için tıklayın',
          templateUsedInBroadcast: 'Yayında kullanılan şablon',
          numberOfBroadcastMessagesSent: 'Gönderilen yayın mesajlarının sayısı',
          numberOfBroadcastMessagesDelivered: 'Teslim edilen yayın mesajlarının sayısı',
          numberOfBroadcastMessagesReadByContacts: 'Kişiler tarafından okunan yayın mesajlarının sayısı',
          numberOfTimesBroadcastMessagesHasFailed: 'Yayın mesajlarının kaç kez başarısız olduğunun sayısı',
          contactNumberOnWhichMessageDeliveryFailed: 'Mesaj teslimatının başarısız olduğu iletişim numarası',
          unDeliveredTemplate: 'Teslim Edilmeyen Şablon',
          errorReceivedForFailedMessage: 'Başarısız mesaj için alınan hata',
          timeWhenMessageDeliveryFailed: 'Mesaj teslimatının başarısız olduğu zaman',
          TOOLTIP_ADD_BROADCAST_NAME: 'Yeni bir yayın adı girin',
          TOOLTIP_BROADCAST_SELECT_TYPE: 'Akış veya mesaj yayını yapıp yapmadığınızı seçin.',
          TOOLTIP_BROADCAST_FLOW: 'Yayınlamak istediğiniz sohbet akışını seçin,',
          TOOLTIP_BROADCAST_SELECT_YOUR_SEGMENT: 'Yayın yapmak istediğiniz iletişim gruplarını seçin',
          TOOLTIP_BROADCAST_NAME: 'Yayının adı',
          TOOLTIP_BROADCAST_TYPE: 'Yayın için seçilen akış veya mesaj',
          TOOLTIP_BROADCAST_SEGMENT: 'Yayın oluşturulan grup',
          TOOLTIP_BROADCAST_AT: 'Yayın için zamanlanan tarih ve saat',
          TOOLTIP_BROADCAST_STATUS: 'Zamanlanmış, şu anda çalışan veya tamamlanan yayın durumu',
        }
      },

      drip: {
        createDripCampaign: 'Damlama Kampanyası Oluştur',
        youCanCreateMultipleCampaigns: 'Birden fazla kampanya oluşturabilirsiniz',
        dripCampaignName: 'Damlama Kampanyası Adı',
        dripCampaignNameIsRequired: 'Damlama Kampanya adı gereklidir',
        youCanCreateMultipleDripCampaigns: 'Belirli kullanıcı gruplarından yanıtları tetiklemek için planlanmış, otomatik mesajlarla birden fazla damlama kampanyası oluşturabilirsiniz.',
        campaignName: 'Kampanya adı',
        campaignNameIsRequired: 'Kampanya adı gereklidir',
        after: 'Sonra ',
        thisMessageWillBeSendAfter: 'Bu mesaj şu süre sonra gönderilecek',
        saveAndUpdate: 'Kaydet ve Güncelle',
        schedule: ' Zamanlama',
        select: 'Seç',
        messages: 'Mesajlar',
        cancel: 'İptal',
        createCampaign: 'Kampanya Oluştur',
        updateCampaign: 'Kampanyayı Güncelle',
        createNewCampaign: 'Yeni kampanya oluştur',
        messagesSent: 'Gönderilen Mesajlar',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncelleme Tarihi',
        action: 'Aksiyon',
        placeholders: {
          nameYourDripCampaign: 'Damlama kampanyanızı adlandırın',
          enterCampaignName: 'Kampanya adını girin',
          searchCampaignByName: 'İsme göre kampanyayı arayın',
        },
        appTooltip: {
          TOOLTIP_CREATE_CAMPAIGN: 'Yeni bir kampanya eklemek için tıklayın',
          TOOLTIP_SCHEDULE: 'Bu kampanyada hangi sohbet akışının ne zaman ve hangi sıklıkla gönderileceğini planlayın.',
          TOOLTIP_AFTER_DAYS: 'Kampanyanın ilk akışını ne zaman göndereceğinizi dakika, saat veya gün cinsinden planlayın.',
          TOOLTIP_ADD_MESSAGES: 'Kampanyaya yeni bir akış eklemek için tıklayın',
          TOOLTIP_ADD_CAMPAIGN_NAME: 'Yeni bir kampanya adı girin',
          TOOLTIP_CAMPAIGN_NAME: 'Kampanyanın adı',
          TOOLTIP_NO_MESSAGES_SENT: 'Kampanya tarafından gönderilen mesajların sayısı',
          TOOLTIP_CAMPAIGN_CREATED_AT: 'Kampanyanın oluşturulma tarihi ve saati',
          TOOLTIP_CAMPAIGN_UPDATED_AT: 'Kampanyanın son olarak ne zaman değiştirildiğinin tarihi ve saati',
          TOOLTIP_CAMPAIGN_ACTION: 'Kampanyayı düzenlemek veya silmek için',
          TOOLTIP_UPDATE_CAMPAIGN: 'Kampanya ayarlarındaki yapılan değişiklikleri kaydetmek için tıklayın',
        }
      },

      flows: {
        createChatFlow: 'Sohbet Akışı Oluştur',
        youCanCreateMultipleConversationsFlow: 'A/B testleri ve yayınlar için birden fazla konuşma akışı oluşturabilirsiniz.',
        flowName: 'Akış adı',
        nameIsRequired: 'Ad gereklidir',
        cancel: 'İptal',
        createFlow: 'Akış Oluştur',
        createNewFlow: 'Yeni Akış Oluştur',
        ofMessages: 'mesajdan',
        triggered: 'Tetiklendi',
        stepsFinsished: 'Adımlar tamamlandı',
        finished: 'Tamamlandı',
        createdOn: 'Oluşturulduğu tarih',
        lastModified: 'Son düzenleme',
        defaultFlow: 'Varsayılan akış',
        action: 'Aksiyon',
        flowAnalytics: 'Akış Analitiği',
        placeholders: {
          enterFlowName: 'Akış adını girin',
          searchFlowByName: 'Akışı ada göre arayın',
        },
        appTooltip: {
          numberOfTimesChatFlowTriggered: 'Sohbet akışının kaç kez tetiklendiğinin sayısı',
          numberOfCompletedChatFlowStepsWhenTriggered: 'Tetiklendiğinde tamamlanan sohbet akışı adımlarının sayısı',
          numberOfTimesUsersCompletedChatFlowSteps: 'Kullanıcıların tüm sohbet akışı adımlarını kaç kez tamamladığının sayısı',
          dateAndTimeOfCreationOfChatFlow: 'Sohbet akışının oluşturulma tarihi ve saati',
          dateAndTimeWhenChatFlowLastModified: 'Sohbet akışının son olarak ne zaman değiştirildiğinin tarihi ve saati',
          TOOLTIP_CREATE_FLOW: 'Yeni bir sohbet akışı oluşturmak için tıklayın',
          TOOLTIP_FLOW_NAME: 'Sohbet akışı adını girin',
          TOOLTIP_FLOW_TABLE_NAME: 'Sohbet akışının adı',
          TOOLTIP_NO_OF_MESSAGES: 'Sohbet akışındaki mesaj bileşenlerinin sayısı',
          TOOLTIP_DEFAULT_FLOW: 'Bu anahtarı kullanarak bir sohbet akışını varsayılan olarak ayarlayın, bu akış yeni kullanıcılara sunulacaktır.',
          TOOLTIP_ACTION: 'Sohbet akışını düzenle veya sil',
        }
      },
      manage: {
        leads: 'Potansiyel Müşteriler',
        manageBotsFunctionalityForContacts: 'İletişim / potansiyel müşteriler için bot işlevselliğini yönet',
        whenToSendLeadInfo: 'Potansiyel Müşteri Bilgilerini Ne Zaman Göndermek İstediğinizi Belirtin',
        smartFlow: 'Akıllı Akış',
        welcomeMessageWhenNoSearchKeywordIsMet: 'Arama anahtar kelimesi karşılanmadığında hoş geldiniz mesajı',
        selectTemplates: 'Şablonları Seçin',
        selectFlows: 'Akışları Seçin',
        selectText: 'Metinleri Seçin',
        selectImages: 'Resimleri Seçin',
        whenItAlmostReaches24HoursSinceMessage: 'Son mesajdan neredeyse 24 saat olduğunda',
        updateChanges: 'Değişiklikleri Güncelle',
        manageBotsFunctionalityAccordingToKeywords: 'Anahtar kelimeleri, akıllı akış durumunda bot kullanıcısı tarafından girdiğine göre bot işlevselliğini yönetin',
        addKeywordAction: 'Anahtar Kelime Eylemi Ekle',
        keywords: 'Anahtar Kelimeler',
        matchingMethod: 'Eşleşme Yöntemi',
        replyMaterial: 'Cevap Malzemesi',
        action: 'Aksiyon',
        select: 'Seç',
        placeholders: {
          keyword: 'anahtar kelime'
        },
        appTooltip: {
          TOOLTIP_LEADS_WHEN_TO_SEND_LEAD: 'Botun, iletişim / potansiyel müşteri bilgilerini entegrasyon CRM\'ine hemen, 5 dakika sonra veya akışın sonunda gönderip göndermeyeceğini önerin',
          TOOLTIP_LEAD_SMART_FLOW: 'Tüm bot kullanıcılarına gönderilmek üzere herhangi bir varsayılan akışı seçin veya akıllı akışı seçin (bot kullanıcısı tarafından girilen anahtar kelimelerle belirlenir).',
          TOOLTIP_LEAD_WELCOME_MESSAGE: 'Botun, bot kullanıcısından gelen yanıtlarda anahtar kelime eşleşmesi almadığında göndereceği yanıt materyalini (akış, şablon, metin, resim) seçin',
          TOOLTIP_IT_ALMOST_REACHED:
            'Son mesajdan 24 saat önce iletişim / potansiyel müşterilere gönderilmek üzere yanıt malzemesini seçin',
          TOOLTIP_KEYWORD_ACTION_TITLE:
            'Akıllı akış durumunda bot kullanıcısı tarafından girilen anahtar kelimelere göre bot işlevselliğini yönetin',
          TOOLTIP_ADD_KEYWORD_ACTIONS_HEADING:
            'Belirli anahtar kelimelere karşı botun yanıtını şablon, akış, metin veya resim olarak seçin',
          TOOLTIP_ADD_KEYWORD: 'Anahtar kelimeleri ekleyin',
          TOOLTIP_MATCHING_METHOD: 'Anahtar kelimenin eşleşme yöntemini "tam" veya "içerir" olarak ekleyin',
          TOOLTIP_REPLY_MATERIAL: 'Önerilen anahtar kelime için şablon, akış, metin veya resmi seçin',
          TOOLTIP_KEYWORD_ACTIONS: 'Anahtar kelime eylemini silin',
        },
        socialAlerts: {
          alerts: 'Bildirimler',
          specifyHowYourBotWillNotify: 'Botunuzun yeni potansiyel müşteriler hakkında sizi nasıl bilgilendireceğini belirtin.'
        }
      },

      orders: {
        manageCustomersOrder: 'Müşteri Siparişlerini Yönet',
        checkAndManageAllOfYourCustomerOrders: 'Tüm müşteri siparişlerinizi kontrol edin ve yönetin.',
        selectShipmentTemplate: 'Sevkiyat Şablonunu Seç',
        send: 'Gönder',
        export: 'Dışa Aktar',
        phoneNumber: 'Telefon Numarası',
        numberOfItems: 'Ürün Sayısı',
        orderValue: 'Sipariş Değeri',
        orderDate: 'Sipariş Tarihi',
        status: 'Durum',
        chat: 'Sohbet',
        item: 'Ürün',
        quantity: 'Miktar',
        amount: 'Miktar',
        unitPrice: 'Birim Fiyat',
        placeholders: {
          searchOrdersByPhoneNumber: 'Telefon numarasına göre siparişleri arayın',
        },
        appTooltip: {
          exportOrdersDataInCSVFile: 'Sipariş verilerini CSV dosyasına aktarın',
          TOOLTIP_ORDERS_PHONE_NO: 'Siparişi veren müşterinin telefon numarası',
          TOOLTIP_NO_OF_ITEMS: 'Sipariş edilen ürünlerin sayısı',
          TOOLTIP_ORDER_VALUE: 'Siparişin değeri',
          TOOLTIP_ORDER_DATE: 'Sipariş bu tarihte verildi.',
          TOOLTIP_ORDER_STATUS: 'Siparişin onaylanmış, bekleyen, reddedilmiş veya gönderilmiş durumu',
          TOOLTIP_ORDER_CHAT: 'Bu siparişle ilgili tüm önceki sohbet konuşmaları',
        }
      },
      widgets: {
        installWidgetHeader: 'Widget Kurulumu',
        createWidgetHeader: 'Widget Oluştur',
        copyTheCodeAndPasteItWhereYouWant: 'Kodu kopyalayın ve istediğiniz herhangi bir HTML, web sitesi vb. yerde yapıştırın.',
        youCanCreateMultipleWidgets: 'Farklı web sayfalarında botunuzu farklı bir görünüme sahip yapmak için birden fazla widget oluşturabilirsiniz.',
        installWidget: {
          javascript: 'JavaScript',
          install: 'Kur',
          onYourWebsite: ' web sitenize',
          copyCode: 'Kodu Kopyala',
          copyAndPaste: 'Kopyala ve Yapıştır',
          copyThisCodeSnippet: 'Bu kod parçasını kopyalayın ve Widget\'ı göstermek istediğiniz her web sayfasının HTML\'ine ekleyin. Daha fazla ayrıntı için',
          seeGuide: 'rehberi görüntüle',
        },
        widgetList: {
          widgetName: 'Widget Adı',
          nameIsRequired: 'Ad gereklidir',
          cancel: 'İptal',
          createWidget: 'Widget Oluştur',
          icon: 'Simge',
          name: 'Ad',
          createdAt: 'Oluşturulma Tarihi',
          action: 'Eylem',
          placeholders: {
            enterWidgetName: 'Widget adını girin',
            searchWidgetByName: 'Adına göre widget arayın',
          },
          appTooltip: {
            widgetIcon: 'Widget simgesi',
            nameOfWidget: 'Widget adı',
            dateAndTimeOfCreationOfWidget: 'Widget oluşturulma tarihi ve saati',
            editOrDeleteWidget: 'Widget\'ı düzenle veya sil',
          }
        },
        widgetTemplates: {
          widgetName: 'Widget Adı',
          nameIsRequired: 'Ad gereklidir',
          chooseTypeOfWidget: 'Widget Türünü Seçin',
          bar: 'Çubuk',
          slideIn: 'Sürükle ve Bırak',
          pageTakeOver: 'Sayfa Kaplaması',
          button: 'Düğme',
          facebookModal: 'Facebook Modal',
          comment: 'Yorum',
          direct: 'Doğrudan',
          whatsappIcon: 'WhatsApp Simgesi',
          refUrl: 'Ref URL',
          qrCode: 'QR Kod',

          // refUrl: 'Ref URL',
          backgroundColor: 'Arka Plan Rengi',
          descriptionColor: 'Açıklama Rengi',
          headlineColor: 'Başlık Rengi',
          buttonBackgroundColor: 'Düğme Arka Plan Rengi',
          contactNumber: 'İletişim Numarası',
          entryMessage: 'Giriş Mesajı',
          buttonText: 'Düğme Metni',
          selectButtonText: 'Düğme metnini seçin',
          buttonSize: 'Düğme Boyutu',
          selectOne: 'Birini Seçin',
          placement: 'Yerleştirme',
          rightBottom: 'Sağ-Alt',
          optInFlow: 'Opt-In Akışı',
          selectFlow: 'Akışı Seçin',
          addSubscriberToSequence: 'Aboneyi Sıraya Ekle',
          selectDrip: 'Damlamayı Seçin',
          whenDoesItDisplay: 'Ne Zaman Gösteriliyor (Saniye)',
          showWidgetToSameUserAgainAfter: 'Aynı kullanıcıya widget\'ı tekrar göster',
          ifManuallyClosedByUser: 'Kullanıcı tarafından manuel olarak kapatılırsa, sonra göster',
          // comment: 'Comment',
          specificPosts: 'Belirli Gönderiler',
          allPosts: 'Tüm Gönderiler',
          // entryMessage: 'Giriş Mesajı',
          postMessage: 'Gönderi Mesajı',
          postDate: 'Gönderi Tarihi',
          status: 'Durum',
          action: 'Eylem',
          discard: 'At',
          saveChanges: 'Değişiklikleri Kaydet',
          nextStep: 'Sonraki Adım',
          widgetDescription: 'Widget Açıklaması',
          integrateTheBarWidget: `Çubuk Widget'ınızı web sitenizin içine entegre edin ve sayfa abone sayınızı artırın ` +
            `ve onlara özel bot mesaj akışları gönderin.`,
          hereIsYourWidgetHeadline: 'Widget başlığınız burada, değiştirmek için buraya tıklayın.',
          sendUsMessage: 'Bize Mesaj Gönderin',
          weAlsoPutDefaultTextHere: 'Varsayılan metni buraya koyduk. Onu değerli bir mesaja çevirmeniz önemlidir.',
          selectYourPagePost: 'Sayfa Gönderinizi Seçin',
          nA: 'Yok',
          // entryMessage: 'Giriş Mesajı',
          saveRule: 'Kuralı Kaydet',
          placeholders: {
            enterWidgetName: 'Widget adını girin',
            enterNumber: 'Numara girin',
            enterMessageHere: 'Buraya mesaj girin',
            enterTimeInSeconds: 'Saniye cinsinden süre girin',
          }
        }

      }
    },
    triggers: {
      customizeYourBotsTriggerSettings: 'Bot tetikleme ayarlarınızı özelleştirin',
      makeYourBotsTimingPerfect: 'Botunuzun zamanlamasını mükemmel hale getirin',
      onDesktopTriggerTime: 'Masaüstü tetikleme süresi (saniye)',
      onMobileTriggerTime: 'Mobil tetikleme süresi (saniye)',
      chatBubble: 'Sohbet balonu otomatik açılış',
      seconds: 'Saniye',
      discard: 'At',
      saveChanges: 'Değişiklikleri Kaydet',
      autoTriggerOnOrOff: 'Otomatik Tetikleme: Açık/Kapalı',
      seeHowToUse: 'Kullanımı Nasıl Görüntülenir',
      alwaysOpenAfter: 'Her zaman yukarıda belirtilen tetiklemeler sonrası açık',
      automaticallyAfterSelectedNumberOfTime: 'Seçilen süre sonrası otomatik olarak',
      neverAutomaticallyOpenBot: 'Botu asla otomatik olarak açma',
      placeholders: {
        enterTimeInSeconds: 'Saniye cinsinden süre girin',
      },
      appTooltip: {
        TOOLTIP_DESKTOP_TRIGGER_TIME: 'Ziyaretçinin masaüstü cihazlarında, botunuzun otomatik olarak ortaya çıkmadan önce bekleme süresi (saniye)',
        TOOLTIP_MOBILE_TRIGGER_TIME: 'Ziyaretçinin mobil cihazlarında, botunuzun otomatik olarak ortaya çıkmadan önce bekleme süresi (saniye)',
        TOOLTIP_CHAT_BUBBLE_OPENING: 'Ziyaretçi sohbet penceresini kapattığında, botun otomatik olarak nasıl ortaya çıkacağını seçin',
        customerWaitingTimeToOpenBubble: 'Sohbet balonunu açmak için müşteri bekleme süresi',
        wantToDisableAutoTriggering: 'Botun web sayfalarında otomatik olarak tetiklenmesini devre dışı bırakmak istiyorsanız tıklayın'
      }
    },
    userAccess: {
      customizeYourBotsSettings: 'Botunuzun kullanıcı ayarlarını özelleştirin',
      decideWhoCanAccessYourBot: 'Kimin botunuza erişebileceğini belirleyin',
      deviceSupport: 'Cihaz Desteği',
      blockIpAddresses: 'IP Adreslerini Engelle',
      hideChatBotOnSpecificPages: 'Botu belirli sayfalarda gizle',
      selectCountriesToEnableBot: 'Botu etkinleştirmek için ülkeleri seçin',
      discard: 'At',
      saveChanges: 'Değişiklikleri Kaydet',
      seeHowToUse: 'Kullanımı Nasıl Görüntülenir',
      allDevices: 'Tüm Cihazlar',
      mobileOnly: 'Sadece Mobil',
      desktopOnly: 'Sadece Masaüstü',
      placeholders: {
        enterIpAddress: 'IP adresini girin (ipv4 veya ipv6) ve \'Enter\' tuşuna basın',
        enterUrl: 'Web sayfalarının URL\'sini girin ve \'Enter\' tuşuna basın',
        searchCountry: 'lke ara',
      },
      appTooltip: {
        TOOLTIP_DEVICE_SUPPORT: 'Botunuzun hangi cihazlarda görüneceğini seçin',
        TOOLTIP_BLOCK_IP_ADDRESS: 'Botunuzun görünmemesini istemediğiniz IP adreslerini girin',
        TOOLTIP_HIDE_CHATBOT_SPECIFIC_PAGES: 'Botunuzun görünmesini istemediğiniz web sayfalarının URL\'sini girin',
        TOOLTIP_COUNTRIES_TO_ENABLE_BOT: 'Kullanıcıların botunuza erişmesini istediğiniz konumları seçin'
      }
    },
    facebook: {
      facebookBotSettings: 'Facebook Bot Ayarları',
      chatFlowsHeader: 'Sohbet Akışları',
      dripCampaignHeader: 'Damlalama Kampanyası',
      broadcastHeader: 'Yayın',
      widgetsHeader: 'Araçlar',
      autoReplyHeader: 'Otomatik Yanıt',
      generalHeader: 'Genel',
      facebookAutomation: 'Facebook Otomasyonu',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE: 'Sohbet akış şablonlarını özelleştirin ve farklı hedef kitleler için birden çok sohbet akışı oluşturun.',
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Zamanlanmış sohbet akışları damlalama kampanyası kullanarak müşteri onboarding yolculuğu oluşturun.',
      },
      broadcasts: {
        createBroadcast: 'Yayın Oluştur',
        youCanCreateMultipleBroadcasts: 'Birden çok yayın oluşturabilirsiniz',
        broadcastName: 'Yayın Adı',
        broadcastNameIsRequired: 'Yayın adı gereklidir',
        continue: 'Devam etmek',
        manageBroadcasts: 'Yayınları Yönet',
        youCanCreateMultipleBroadcastsWith:
          'Farklı müşteri grupları için birden fazla şablon içeren birden fazla yayın oluşturabilirsiniz.',
        createNewBroadcast: 'Yeni Yayın Oluştur',
        name: 'Ad',
        segment: 'Grup',
        template: 'Şablon',
        contacts: 'İletişim',
        send: 'Gönder',
        failed: 'Başarısız',
        broadcastAt: 'Yayın Tarihi',
        status: 'Durum',
        actions: 'Eylemler',
        nA: 'NA',
        deleteBroadcast: 'Yayını Sil',
        wantToDeleteThisBroadcast: 'Bu Yayını silmek istediğinizden emin misiniz?',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          searchHere: 'Burada ara',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Birden çok yayını sil',
          deleteBroadcast: 'Yayını Sil'
        }
      },
      createBroadcastFacebook: {
        braodcastName: 'Yayın adı',
        broadcastNameIsRequired: 'Yayın adı gereklidir',
        flow: 'Akış',
        segments: 'Gruplar',
        whenToSend: 'Ne Zaman Gönderileceği',
        selectDate: 'Tarih Seç',
        selectTimeSlot: 'Zaman Aralığı Seç',
        createBroadcast: 'Yayın Oluştur',
        cancel: 'İptal',
        now: 'Şimdi',
        later: 'Daha Sonra',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          selectFlow: 'Akış Seçin',
          searchFlowByName: 'Akışı ada göre arayın',
          selectSegments: 'Grupları Seç',
          searchSegmentByName: 'İsme Göre Grup Ara',
          selectTimeSlot: 'Zaman Aralığı Seçin',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Başlangıç tarihini seçin',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Damlalama Kampanyası Oluştur',
        youCanCreateMultipleCampaign: 'Birden çok kampanya oluşturabilirsiniz',
        dripCampaignName: 'Damlalama Kampanya Adı',
        dripCampaignNameIsRequired: 'Damlalama Kampanya Adı gereklidir',
        continue: 'Devam',
        manageDripCampaign: 'Damlalama Kampanyalarını Yönet',
        youCanCreateMutlipleDripCampaign: 'Belirli kullanıcı gruplarından yanıtları tetiklemek için planlanmış, otomatik mesajlarla birden fazla damlama kampanyası oluşturabilirsiniz.',
        createNewDripCampaign: 'Yeni Damlalama Kampanyası Oluştur',
        campaignName: 'Kampanya Adı',
        appliedOn: 'Uygulama Zamanı',
        segment: 'Grup',
        flow: 'Akış',
        startDate: 'Başlangıç Tarihi',


        action: 'Eylem',
        nA: 'NA',
        deleteDripCampaign: 'Damlalama Kampanyalarını Sil',
        wantToDeleteThisDripCampaign: 'Bu Damlalama Kampanyalarını silmek istediğinizden emin misiniz?',
        placeholders: {
          enterDripCampaignName: 'Damlalama Kampanya Adını Girin',
          searchByName: 'Ada Göre Ara',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Birden fazla damlalama kampanyasını sil',
          deleteDripCampaign: 'Damlalama Kampanyasını Sil',
        },

        createDripCampaignFacebook: {
          dripCampaignName: 'Damlalama Kampanya Adı',
          appliedOn: 'Uygulama Zamanı',
          segments: 'Gruplar',
          selectDate: 'Tarih Seç',
          scheduleYourFlows: 'Akışlarınızı Zamanlayın',
          schedule: 'Zamanlama',

          // flow: 'Flow',


          flow: 'Akış',
          sendAfter: 'Senden Sonra',
          cancel: 'İptal',
          newSubscriber: 'Yeni Abone',
          days: 'Günler',
          minutes: 'Dakikalar',
          hours: 'Saatler',
          createDripCampaign: 'Damlalama Kampanyası Oluştur',
          placeholders: {
            enterDripName: 'Damlalama Adını Girin',
            selectAppliedOn: 'Uygulama Zamanını Seçin',
            selectSegment: 'Grup Seçin',
            searchByName: 'Ada Göre Ara',
            selectTemplate: 'Şablon Seçin',
            searchFlowByName: 'Ada Göre Akış Ara',
            enterSendAfter: 'Senden Sonra Girin',
          },
          dateTimePicker: {
            selectStartDate: 'Başlangıç tarihini seçin',
          }
        }
      },
      failedBroadcastFacebook: {
        chatId: 'Sohbet Kimliği',
        errorCode: 'Hata Kodu',
        errorMessage: 'Hata Mesajı',
        nA: 'NA',
      },
      flows: {
        chatFlows: 'Sohbet Akışları',
        createChatFlow: 'Sohbet Akışı Oluştur',
        youCanCreateMultipleChatFlows: 'A/B testleri ve yayınlar için birden çok konuşma akışı oluşturabilirsiniz.',
        flowName: 'Akış Adı',
        nameIsRequired: 'Ad gereklidir',
        cancel: 'İptal',
        createFlow: 'Akış Oluştur',
        importFlow: 'Akışı İçe Aktar',
        createNewFlow: 'Yeni Akış Oluştur',
        ofMessages: 'mesajlar',
        createdOn: 'Oluşturulma Tarihi',
        lastModified: 'Son Değiştirilme',
        defaultFlow: 'Varsayılan akış',
        action: 'Eylem',
        exportStatus: 'Dışa Aktarma Durumu',
        flowExport: 'Akış Dışa Aktar',
        download: 'İndir',
        beforeYouStartUploadPleaseMakeSure: 'Yükleme işlemine başlamadan önce lütfen emin olun',
        point1: '1. Yükleme sınırı maksimum 20MB',
        point2: '2. Dosya formatı JSON olmalıdır',
        selectFile: 'Dosya Seç',
        inProgress: 'Devam Ediyor',
        available: 'Mevcut',
        initiated: 'Başlatıldı',
        placeholders: {
          enterFlowName: 'Akış adını girin',
        },






        appTooltip: {
          importFlowForFacebookBot: 'Facebook botu için akışı içe aktar',
          dateAndTimeOfCreationOfChatFlow: 'Sohbet akışının oluşturulma tarihi ve saati',
          dateAndTimeWhenChatFlowLastModified: 'Sohbet akışının son düzenlenme tarihi ve saati',
          TOOLTIP_CREATE_FLOW: 'Yeni bir sohbet akışı oluşturmak için tıklayın',
          TOOLTIP_FLOW_NAME: 'Sohbet akışı adını girin',
          TOOLTIP_FLOW_TABLE_NAME: 'Sohbet akışının adı',
          TOOLTIP_NO_OF_MESSAGES: 'Sohbet akışındaki mesaj bileşenlerinin sayısı',
          TOOLTIP_DEFAULT_FLOW: 'Bu anahtarı kullanarak bir sohbet akışını varsayılan olarak ayarlayın, bu yeni tüm kullanıcılara sunulur.',
          TOOLTIP_ACTION: 'Sohbet akışını düzenle veya sil',
        },
        noDataFound: {
          flows: 'Akışlar',
          noFlowsAddedYet: 'Henüz eklenen akış yok!',
        },
        modal: {
          deleteFlow: 'Akışı Sil',
          wantToDeleteThisFlow: 'Bu Akışı silmek istediğinizden emin misiniz?',
        }
      },
      widgets: {
        manageWidgets: 'Manage Widgets',
        widgetsAreRoutingTools: 'Widgets are the routing tools to increase uses of bot',
        needHelp: 'Need Help?',
        wantToMakeChanges: 'Want to make changes?',
        editIcon: 'Edit Icon',
        discard: 'Discard',
        updateWidget: 'Update Widget',
        installChatBotIconOnYourWebsite: 'Install chatbot icon on your website',
        shareWhereEverYouWantToRedirectUsersTo: 'Share wherever you want & redirect users to your Facebook bot in single click',
        copyAndAddInYourWebsiteHeaderFile: 'Copy & add in your website header file',
        copyLinkAndStartSharing: 'Copy link & start sharing!',
        downloadQrCodeAndStartSharing: 'Download QR code & start sharing!',
        addCodeAndDeployNewCodeAndDone: 'Add the code, deploy new code and Done!',
        wantToLearnHowToUseIt: 'Want to learn how to use it?',
        copyChatBotCode: 'Copy Chatbot Code',
        copyFacebookLink: 'Copy Facebook Link',
        downloadQrCode: 'Download QR Code',
        link: 'Link',
        chatBotIcon: 'Chatbot Icon',
        qrcode: 'QR Code',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        leftMiddle: 'Left-Middle',
        rightMiddle: 'Right-Middle',
        leftBottom: 'Left-Bottom',
        rightBottom: 'Right-Bottom',
        bpDropDown: {
          selectIcons: 'Select Icons',
          iconSize: 'Icon Size',
          iconPlacement: 'Icon Placement',
        }
      },
      autoReply: {
        autoReplyTitle: 'Otomatik Yanıt',
        configureYour: 'Şunları yapılandırın: ',
        message: ' mesaj',
        customizeAutoReplyMessagesForYourPostsComments: 'Gönderilerinizin yorumları için otomatik yanıt mesajlarını özelleştirin',
        wouldYouLikeToApply: 'Tüm gönderilere ',
        autoreply: 'Otomatik Yanıt',
        onAllPost: ' uygulamak ister misiniz?',
        configureYourMessageForEachPost: 'Her gönderi için mesajınızı yapılandırın',
        configureFlowToTriggerAfterOnReply: 'Yanıt geldiğinde tetiklenecek akışı yapılandırın',
        whenUserLeavesACommentSendMessageAfter: 'Kullanıcı yorum bıraktığında, yorumdan sonra mesaj gönder',
        triggerFlowOnReply: 'Yanıt geldiğinde akışı tetikle',
        add: 'Ekle',
        update: 'Güncelle',
        save: 'Kaydet',
        cancel: 'İptal',
        discard: 'At',
        bpDropDown: {
          selectPost: 'Gönderiyi Seç',
          triggerFlowOnReply: 'Yanıt geldiğinde akışı tetikle',
          immendiately: 'Hemen',
          minutes: 'dakika'
        },
        bpTable: {
          message: 'Mesaj',
          selectedPost: 'Seçilen Gönderi',
          triggerFlow: 'Akışı Tetikle',
          actions: 'Eylemler'
        },
        placeholder: {
          configureYourMessage: 'Mesajınızı yapılandırın'
        },
        deleteModel: {
          title: 'Gönderi Otomatik Yanıtı Sil',
          description: 'Bu gönderi otomatik yanıtı silmek istediğinizden emin misiniz?'
        }
      },
      generalSetting: {
        sendInactivityMessage: 'Kullanıcı Hareketsizlik Mesajı',
        sessionTimeOutInHours: 'Oturum Süresi (saat cinsinden)',
        enterSessionTimeOutWindowInHours: 'Oturum süresi penceresini saat cinsinden girin',
        sessionTimeOutInHoursTooltip: `Kullanıcının etkisizlik nedeniyle sohbet oturumunun otomatik olarak sona ereceği süreyi (saat cinsinden) girin. Kullanıcı, oturum süresinden sonra herhangi bir mesaj gönderirse sohbet akışı yeniden başlatılır`,
        restartChatFlowTriggers: 'Sohbet Akışını Yeniden Başlatma Tetikleri',
        enterKeyWordsToRestartChatAndhitEnter: 'Sohbeti yeniden başlatmak için anahtar kelimeleri girin ve Enter tuşuna basın',
        restartChatFlowTriggersTooltip: `Kullanıcının girdiğinde ve gönderdiğinde sohbet akışının yeniden başlamasına neden olacak anahtar kelimeleri belirtin. Bu, kullanıcıların herhangi bir noktada sohbet akışını yeniden başlatmalarına olanak tanır.
                Birden çok anahtar kelime eklemek için her anahtar kelimenin ardından Enter tuşuna basın.`,
        liveChatTriggers: 'Canlı Sohbet Tetikleri',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Canlı sohbeti tetiklemek için anahtar kelimeleri girin ve Enter tuşuna basın',
        liveChatTriggersTooltip: `Kullanıcı tarafından girildiğinde ve gönderildiğinde otomatik sohbetten canlı sohbete geçişi başlatacak anahtar kelimeleri tanımlayın. Kullanıcıların bir canlı temsilciye yardım gerektiğinde kullanışlıdır.
                Birden çok anahtar kelime eklemek için her anahtar kelimenin ardından Enter tuşuna basın.`,
        messageAfterAutomatedFlowCompleted: 'Otomatik Akış Tamamlandıktan Sonra Mesaj',
        enterYourMessage: 'Mesajınızı girin',
        messageAfterAutomatedFlowCompletedTooltip: `Kullanıcılar otomatik sohbet akışını tamamladıktan sonra oturum zaman aşımı olmamışsa gösterilecek bir mesaj girin.`,
        restart: 'Yeniden Başlat',
        liveChat: 'Canlı Sohbet',
        lineOneForDefaultMessage: 'Daha fazla sorunuz varsa veya yardıma ihtiyacınız varsa, şunları yapabilirsiniz:',
        to: 'Şuna',
        restartTheChat: 'sohbeti yeniden başlatın',
        simpleTypeRestartAndHitEnter: `basitçe 'Yeniden Başlat' yazın ve Enter tuşuna basın. Bu sizi sohbetimizin başlangıcına geri götürecek.`,
        lineThreeForDefaultMessagePartOne: `Bir`,
        liveAgent: `canlı temsilciyle`,
        lineThreeForDefaultMessageLastPart: `konuşmak isterseniz 'Canlı Sohbet' yazın ve Enter tuşuna basın. Ekibimiz size gerçek zamanlı olarak yardımcı olacaktır.`,
        saveChanges: 'Değişiklikleri Kaydet',
        discard: 'At',
        configureBotsGeneralSettings: `Botun Genel Ayarlarını Yaplandır`,
        minutes: 'dakika',
        hours: 'saat',
      }
    },
    instagram: {
      instagramBotSettings: 'Instagram Bot Ayarları',
      chatFlowsHeader: 'Sohbet Akışları',
      broadcastHeader: 'Yayın',
      dripCampaignHeader: 'Damlama Kampanyası',
      widgetsHeader: 'Araçlar',
      alertsHeader: 'Bildirimler',
      alerts: {
        configureYourBotsSettings: `Botunuzun uyarı ayarlarını yapılandırın`,
        specifyHowYourBotNotifyYou: 'Botunuzun yeni müşteriler hakkında size nasıl bildirimde bulunacağını belirtin.',
        subject: 'Konu',
        emailNotification: 'E-posta Bildirimi',
        emailAddresses: 'E-posta Adresleri',
        leadRevisitNotifications: 'Potansiyel Müşteri Ziyaret Bildirimleri',
        whenToSendLeadInfo: 'Potansiyel Müşteri Bilgilerinin Ne Zaman Gönderilmesi Gerektiğini Belirtin',
        discard: 'At',
        saveChanges: 'Değişiklikleri Kaydet',
        placeholders: {
          enterSubjectForYourEmail: 'E-posta için konuyu girin',
          typeYourEmailAddressAndHitEnter: 'E-posta adresinizi yazın ve Enter tuşuna basın',
        }
      },
      broadcasts: {
        createBroadcast: 'Yayın Oluştur',
        youCanCreateMultipleBroadcasts: 'Birden fazla yayın oluşturabilirsiniz',
        broadcastName: 'Yayın Adı',
        broadcastNameIsRequired: 'Yayın adı gereklidir',
        continue: 'Devam Et',
        manageBroadcasts: 'Yayınları Yönet',
        youCanCreateMultipleBroadcastsWithMultipleTemplatesAndDifferentSegments: 'Farklı müşteri grupları için birden fazla şablon içeren birden fazla yayın oluşturabilirsiniz.',
        createNewBroadcast: 'Yeni Yayın Oluştur',
        segment: 'Grup',
        template: 'Şablon',
        contacts: 'Kişiler',
        send: 'Gönder',
        failed: 'Başarısız',
        broadcastAt: 'Yayın Zamanı',
        status: 'Durum',
        actions: 'İşlemler',
        nA: 'NA',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          searchHere: 'Burada ara',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Birden fazla yayını sil',
          deleteBroadcast: 'Yayını Sil',
        }
      },
      createBroadcast: {
        broadcastName: 'Yayın adı',
        broadcastNameIsRequired: 'Yayın adı gereklidir',
        flow: 'Akış',
        segments: 'Gruplar',
        whenToSend: 'Ne zaman gönderileceği',
        selectDate: 'Tarih Seç',
        selectTimeSlot: 'Zaman Aralığı Seç',
        cancel: 'İptal',
        createBroadcast: 'Yayın Oluştur',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          selectFlow: 'Akışı Seç',
          searchFlowByName: 'Akışı ada göre arayın',
          selectSegments: 'Grupları Seç',
          searchSegmentByName: 'İsme Göre Grup Ara',
          selectTimeSlot: 'Zaman Aralığı Seç',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Başlangıç tarihi seçin',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Drip Kampanyası Oluştur',
        youCanCreateMultipleCampaigns: 'Birden fazla kampanya oluşturabilirsiniz',
        dripCampaignName: 'Drip Kampanyası Adı',
        dripCampaignNameIsRequired: 'Drip Kampanyası Adı gereklidir',
        continue: 'Devam',
        manageDripCampaign: 'Drip Kampanyasını Yönet',
        youCanCreateMultipleCampaignsWith: 'Planlanmış birden fazla damlama kampanyası oluşturabilirsiniz' +
          'belirli kullanıcı gruplarından yanıt almayı tetikleyen otomatik mesajlar.',
        createNewDripCampaign: 'Yeni Drip Kampanyası Oluştur',
        campaignName: 'Kampanya Adı',
        appliedOn: 'Uygulanan Alan',
        segment: 'Grup',
        flow: 'Akış',
        startDate: 'Başlangıç Tarihi',
        action: 'Eylem',
        nA: 'NA',
        placeholders: {
          enterDripCampaignName: 'Drip Kampanya Adını Girin',
          searchByName: 'İsme Göre Ara',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Birden fazla drip kampanyasını silin',
          deleteDripCampaign: 'Drip Kampanyasını Sil',
        },
        createDripCampaignInstagram: {
          dripCampaignName: 'Drip Kampanya Adı',
          appliedOn: 'Uygulandığı Yer',
          segments: 'Gruplar',
          selectDate: 'Tarih Seçin',
          scheduleYourFlows: 'Akışları Zamanlayın',
          flow: 'Akış',
          sendAfter: 'Şu süre sonra gönder',
          cancel: 'İptal',
          createDripCampaign: 'Drip Kampanyası Oluştur',
          placeholders: {
            enterDripName: 'Drip Adını Girin',
            selectAppliedOn: 'Uygulanan Yeri Seçin',
            selectSegment: 'Grup Seçin',
            searchByName: 'İsme Göre Ara',
            selectTemplate: 'Şablon Seçin',
            searchFlowByName: 'Akışı İsme Göre Ara',
            enterSendAfter: 'Gönderdikten Sonra Girin',
          },
          dateTimePicker: {
            selectStartDate: 'Başlangıç tarihini seçin',
          }
        }
      },
      failedBroadcastInstagram: {
        chatId: 'Sohbet Kimliği',
        errorCode: 'Hata Kodu',
        errorMessage: 'Hata Mesajı',
        nA: 'NA',
      },
      flows: {
        chatFlows: 'Sohbet Akışları',
        createChatFlow: 'Sohbet Akışı Oluştur',
        youCanCreateMultipleChatFlows: 'A/B testi ve yayınlar için birden fazla konuşma akışı oluşturabilirsiniz.',
        flowName: 'Akış adı',
        nameIsRequired: 'Ad gerekli',
        cancel: 'İptal',
        createFlow: 'Akış Oluştur',
        importFlow: 'Akışı İçe Aktar',
        createNewFlow: 'Yeni Akış Oluştur',
        ofMessages: ' mesajlar',
        createdOn: 'Oluşturulma tarihi',
        lastModified: 'Son düzenleme',
        defaultFlow: 'Varsayılan akış',
        action: 'Eylem',
        exportStatus: 'Dışa Aktarma Durumu',
        flowExport: 'Akış Dışa Aktar ',
        download: 'İndir',
        beforeYouStartUploadPleaseMakeSure: 'Yüklemeye başlamadan önce lütfen emin olun',
        point1: '1. Maksimum dosya boyutu yükleme sınırı 20MB\'dir',
        point2: '2. Dosya formatının JSON olması gerekmektedir',
        selectFile: 'Dosya Seç',
        placeholders: {
          enterFlowName: 'Akış adını girin',
        },
        appTooltip: {
          importFlowForInstagramBot: 'Instagram botu için akışı içe aktar',
          dateAndTimeOfCreationOfChatFlow: 'Sohbet akışının oluşturulma tarihi ve saati',
          dateAndTimeWhenChatFlowLastModified: 'Sohbet akışının son düzenlenme tarihi ve saati',
        },
        noDataFound: {
          flows: 'Akışlar',
          noFlowsAddedYet: 'Henüz Akış eklenmedi!',
        },
        modal: {
          deleteFlow: 'Akışı Sil',
          wantToDeleteThisFlow: 'Bu Akışı silmek istediğinizden emin misiniz?',
        }
      },
      widgets: {
        manageWidgets: 'Araçları Yönet',
        widgetsAreRoutingTools: 'Araçlar, botun kullanımlarını artırmak için yönlendirme araçlarıdır',
        needHelp: 'Yardım mı gerekiyor?',
        wantToMakeChanges: 'Değişiklik yapmak istiyor musunuz?',
        editIcon: 'Simgeyi Düzenle',
        discard: 'At',
        updateWidget: 'Araç Güncelle',
        bpDropDown: {
          selectIcons: 'Simge Seçin',
          iconSize: 'Simge Boyutu',
          iconPlacement: 'Simge Yerleşimi',
        }
      }
    },
    telegram: {
      telegramBotSettings: 'Telegram Bot Ayarları',
      chatFlowsHeader: 'Sohbet Akışları',
      telegramProfileHeader: 'Telegram Profili',
      widgetsHeader: 'Araçlar',
      broadcastHeader: 'Yayın',
      dripCampaignHeader: 'Damlalık Kampanya',
      generalHeader: 'Genel',
      telegramAutomation: 'Telegram Otomasyonu',
      appTooltip: {
        TOOLTIP_DRIP_CAMPAIGN_TITLE: 'Zamanlanmış sohbet akışları kullanarak müşteri katılımı süreci oluşturun.',
        TOOLTIP_CHAT_FLOW_TITLE:
          'Sohbet akışı şablonlarını özelleştirin ve farklı hedef kitleler için birden fazla sohbet akışı oluşturun.',
      },
      broadcasts: {
        createBroadcast: 'Yayın Oluştur',
        youCanCreateMutlipleBroadcasts: 'Birden fazla yayın oluşturabilirsiniz',
        broadcastName: 'Yayın Adı',
        broadcastNameIsRequired: 'Yayın adı gereklidir',
        continue: 'Devam',
        manageBroadcasts: 'Yayınları Yönet',
        youCanCreateMultipleBroadcastsWith:
          'Farklı müşteri grupları için birden fazla şablon içeren birden fazla yayın oluşturabilirsiniz.',
        createNewBroadcast: 'Yeni Yayın Oluştur',
        name: 'Ad',
        segment: 'Grup',
        template: 'Şablon',
        contacts: 'Kişiler',
        send: 'Gönder',
        failed: 'Başarısız',
        broadcastAt: 'Yayın Zamanı',
        status: 'Durum',
        actions: 'Eylemler',
        nA: 'NA',
        deleteBroadcast: 'Yayını Sil',
        wantToDeleteThisBroadcast: 'Bu Yayını silmek istediğinizden emin misiniz?',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          searchHere: 'Burada arayın',
        },
        appTooltip: {
          deleteMultipleBroadcasts: 'Birden fazla yayını silin',
          deleteBroadcast: 'Yayını Sil',
        }
      },


      createBroadcastTelegram: {
        broadcastName: 'Yayın Adı',
        broadcastNameIsRequired: 'Yayın adı gereklidir',
        flow: 'Akış',
        segments: 'Gruplar',
        whenToSend: 'Ne zaman gönderileceği',
        selectDate: 'Tarih Seç',
        selectTimeSlot: 'Zaman Aralığı Seç',
        cancel: 'İptal',
        createBroadcast: 'Yayın Oluştur',
        now: 'Şimdi',
        later: 'Daha Sonra',
        placeholders: {
          enterBroadcastName: 'Yayın adını girin',
          selectFlow: 'Akış Seç',
          searchFlowByName: 'İsimle akış arayın',
          selectSegments: 'Grupları Seç',
          searchSegmentByName: 'İsme Göre Grup Ara',
          selectTimeSlot: 'Zaman Aralığı Seç',
        },
        indexMergeDatePicker: {
          selectStartDate: 'Başlangıç tarihini seç',
        }
      },
      dripCampaigns: {
        createDripCampaign: 'Damlama Kampanyası Oluştur',
        youCanCreateMultipleCampaigns: 'Birden fazla kampanya oluşturabilirsiniz',
        dripCampaignName: 'Damlama Kampanyası Adı',
        dripCampaignNameIsRequired: 'Damlama Kampanya Adı zorunludur',
        continue: 'Devam Et',
        manageDripCampaign: 'Damlama Kampanyalarını Yönet',
        youCanCreateMultipleDripCampaignsWith: 'Belirli kullanıcı gruplarından yanıtları tetiklemek için planlanmış, otomatik mesajlarla birden fazla damlama kampanyası oluşturabilirsiniz.',
        createNewDripCampaign: 'Yeni Damlama Kampanyası Oluştur',
        campaignName: 'Kampanya Adı',
        appliedOn: 'Uygulandı',
        segment: 'Grup',
        flow: 'Akış',
        startDate: 'Başlangıç Tarihi',
        action: 'Eylem',
        nA: 'NA',
        deleteDripCampaign: 'Damlama Kampanyalarını Sil',
        wantToDeleteThisDripCampaign: 'Bu Damlama Kampanyasını silmek istediğinizden emin misiniz?',
        placeholders: {
          enterDripCampaignName: 'Damlama Kampanyası Adını Girin',
          searchByName: 'İsme Göre Ara',
        },
        appTooltip: {
          deleteMultipleDripCampaigns: 'Birden fazla damlama kampanyasını sil',
          deleteDripCampaign: 'Damlama Kampanyasını Sil',
        },
        createDripCampaignTelegram: {
          dripCampaignName: 'Damlama Kampanyası Adı',
          appliedOn: 'Uygulandı',
          segments: 'Gruplar',
          startDate: 'Tarih Seç',
          scheduleYourFlows: 'Akışlarınızı Zamanlayın',
          schedule: 'Zamanlama',
          flow: 'Akış',
          sendAfter: 'Şu Süre Sonra Gönder',
          cancel: 'İptal',
          createDripCampaign: 'Damlama Kampanyası Oluştur',
          newSubscriber: 'Yeni Abone',
          days: 'Günler',
          minutes: 'Dakikalar',
          hours: 'Saatler',
          placeholders: {
            enterDripName: 'Damlama Adını Girin',
            selectAppliedOn: 'Uygulandı Seç',
            selectSegment: 'Grup Seç',
            searchByName: 'İsme Göre Ara',
            selectFlow: 'Akış Seç',
            searchFlowByName: 'İsme Göre Akış Ara',
          },
          dateTimePicker: {
            selectStartDate: 'Başlangıç tarihini seç',
          }
        }
      },
      failedBroadcastTelegram: {
        name: 'Ad',
        email: 'E-posta',
        phone: 'Telefon',
        errorCode: 'Hata Kodu',
        errorMessage: 'Hata Mesajı',
        nA: 'NA',
      },

      flows: {
        chatFlows: 'Sohbet Akışları',
        createChatFlow: 'Sohbet Akışı Oluştur',
        youCanCreateMultipleChatFlows: 'A/B testi ve yayınlar için birden fazla konuşma akışı oluşturabilirsiniz.',
        flowName: 'Akış adı',
        nameIsRequired: 'Ad gereklidir',
        cancel: 'İptal',
        createFlow: 'Akış Oluştur',
        importFlow: 'Akışı İçe Aktar',
        createNewFlow: 'Yeni Akış Oluştur',
        ofMessages: ' mesaj',
        createdOn: 'Oluşturulma tarihi',
        lastModified: 'Son düzenleme',
        defaultFlow: 'Varsayılan akış',
        action: 'Eylem',
        exportStatus: 'Dışa Aktarma Durumu',
        flowExport: 'Akış Dışa Aktar ',
        inProgress: 'Devam ediyor',
        available: 'Kullanılabilir',
        initiated: 'Başlatıldı',
        download: 'İndir',
        beforeYouStartUploadPleaseMakeSure: 'Yükleme işlemine başlamadan önce lütfen şunları sağlayın',
        point1: '1. Maksimum dosya boyutu sınırı 20 MB\'dır',
        point2: '2. Dosya formatı JSON olmalıdır',
        selectFile: 'Dosya Seç',
        placeholders: {
          enterFlowName: 'Akış adını girin',
        },
        appTooltip: {
          importFlowForTelegramBot: 'Telegram botu için akışı içe aktar',
          dateAndTimeOfCreationOfChatFlow: 'Sohbet akışının oluşturulma tarihi ve saati',
          dateAndTimeWhenChatFlowLastModified: 'Sohbet akışının son düzenleme tarihi ve saati',
          TOOLTIP_CREATE_FLOW: 'Yeni bir sohbet akışı oluşturmak için tıklayın',
          TOOLTIP_FLOW_NAME: 'Sohbet akışı adını girin',
          TOOLTIP_FLOW_TABLE_NAME: 'Sohbet akışının adı',
          TOOLTIP_NO_OF_MESSAGES: 'Sohbet akışındaki ileti bileşenlerinin sayısı',
          TOOLTIP_DEFAULT_FLOW: 'Bu anahtarı kullanarak bir sohbet akışını varsayılan olarak ayarlayın, bu akış tüm yeni kullanıcılara sunulur.',
          TOOLTIP_ACTION: 'Sohbet akışını düzenleyin veya silin',
        },
        noDataFound: {
          flows: 'Akışlar',
          noFlowsAddedYet: 'Henüz akış eklenmedi!',
        },
        modal: {
          deleteFlow: 'Akışı Sil',
          wantToDeleteThisFlow: 'Bu Akışı silmek istediğinizden emin misiniz?',
        }
      },
      telegramProfile: {
        telegramProfile: 'Telegram Profili',
        manageYourBotProfile: 'Bot profilinizi istediğiniz zaman yönetin.',
        allSetNoActionRequired: 'Her şey ayarlı, herhangi bir eylem gerekmiyor!',
        congratulationYourAccountIsLive: 'Tebrikler! Hesabınız tam erişimle canlı',
        botDetails: 'Bot Ayrıntıları',
        name: 'Ad',
        nA: 'NA',
        b: 'B',
        botName: 'Bot Adı',
        bot: 'Bot',
        info: 'Bilgi',
        none: 'Hiçbiri',
        username: 'Kullanıcı Adı',
        notifications: 'Bildirimler',
        on: 'Açık',
        addToGroupOrChannel: 'Gruba veya Kanala Ekle',
        userName: 'Kullanıcı Adı',
        botToken: 'Bot Token',
      },

      widgets: {
        manageWidgets: 'Araçları Yönet',
        widgetsAreRoutingTools: 'Araçlar, bot kullanımını artırmak için yönlendirme araçlarıdır',
        needHelp: 'Yardıma mı ihtiyacınız var?',
        wantToMakeChanges: 'Değişiklik yapmak istiyor musunuz?',
        editIcon: 'Simgeyi Düzenle',
        discard: 'At',
        updateWidget: 'Araç Güncelle',
        installChatBotIconOnYourWebsite: 'Web sitenize sohbetbotu simgesi ekleyin',
        shareWhereEverYouWantRedirectUsersTo: 'Nereye isterseniz paylaşın ve kullanıcıları tek tıklamayla Telegram botunuza yönlendirin',
        copyAndAddInYourWebsiteHeaderFile: 'Kopyalayın ve web sitenizin üstbilgi dosyasına ekleyin',
        copyLinkAndStartSharing: 'Bağlantıyı kopyalayın ve paylaşmaya başlayın!',
        downloadQrCodeAndStartSharing: 'QR kodunu indirin ve paylaşmaya başlayın!',
        addCodeAndDeployNewCodeAndDone: 'Kodu ekleyin, yeni kodu dağıtın ve Tamam!',
        wantToLearnHowToUseIt: 'Nasıl kullanılacağını öğrenmek ister misiniz?',
        copyChatBotCode: 'Sohbetbotu Kodunu Kopyala',
        downloadQrCode: 'QR Kodunu İndir',
        copyTelegramLink: 'Telegram Bağlantısını Kopyala',
        link: 'Bağlantı',
        chatBotIcon: 'Sohbetbotu Simgesi',
        qrcode: 'QR Kodu',
        small: 'Küçük',
        medium: 'Orta',
        large: 'Büyük',
        leftMiddle: 'Sol-Orta',
        rightMiddle: 'Sağ-Orta',
        leftBottom: 'Sol-Alt',
        rightBottom: 'Sağ-Alt',
        bpDropDown: {
          selectIcons: 'Simge Seç',
          iconSize: 'Simge Boyutu',
          iconPlacement: 'Simge Yerleştirme',
        }
      },
      generalSetting: {
        sendInactivityMessage: 'Kullanıcı Hareketsizlik Mesajı',
        sessionTimeOutInHours: 'Oturum Süresi (saat cinsinden)',
        enterSessionTimeOutWindowInHours: 'Oturum süresi penceresini saat cinsinden girin',
        sessionTimeOutInHoursTooltip: `Kullanıcının etkisizlik nedeniyle sohbet oturumunun otomatik olarak sona ereceği süreyi (saat cinsinden) girin. Kullanıcı, oturum süresinden sonra herhangi bir mesaj gönderirse sohbet akışı yeniden başlatılır`,
        restartChatFlowTriggers: 'Sohbet Akışını Yeniden Başlatma Tetikleri',
        enterKeyWordsToRestartChatAndhitEnter: 'Sohbeti yeniden başlatmak için anahtar kelimeleri girin ve Enter tuşuna basın',
        restartChatFlowTriggersTooltip: `Kullanıcının girdiğinde ve gönderdiğinde sohbet akışının yeniden başlamasına neden olacak anahtar kelimeleri belirtin. Bu, kullanıcıların herhangi bir noktada sohbet akışını yeniden başlatmalarına olanak tanır.
                Birden çok anahtar kelime eklemek için her anahtar kelimenin ardından Enter tuşuna basın.`,
        liveChatTriggers: 'Canlı Sohbet Tetikleri',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Canlı sohbeti tetiklemek için anahtar kelimeleri girin ve Enter tuşuna basın',
        liveChatTriggersTooltip: `Kullanıcı tarafından girildiğinde ve gönderildiğinde otomatik sohbetten canlı sohbete geçişi başlatacak anahtar kelimeleri tanımlayın. Kullanıcıların bir canlı temsilciye yardım gerektiğinde kullanışlıdır.
                Birden çok anahtar kelime eklemek için her anahtar kelimenin ardından Enter tuşuna basın.`,
        messageAfterAutomatedFlowCompleted: 'Otomatik Akış Tamamlandıktan Sonra Mesaj',
        enterYourMessage: 'Mesajınızı girin',
        messageAfterAutomatedFlowCompletedTooltip: `Kullanıcılar otomatik sohbet akışını tamamladıktan sonra oturum zaman aşımı olmamışsa gösterilecek bir mesaj girin.`,
        restart: 'Yeniden Başlat',
        liveChat: 'Canlı Sohbet',
        lineOneForDefaultMessage: 'Daha fazla sorunuz varsa veya yardıma ihtiyacınız varsa, şunları yapabilirsiniz:',
        to: 'Şuna',
        restartTheChat: 'sohbeti yeniden başlatın',
        simpleTypeRestartAndHitEnter: `basitçe 'Yeniden Başlat' yazın ve Enter tuşuna basın. Bu sizi sohbetimizin başlangıcına geri götürecek.`,
        lineThreeForDefaultMessagePartOne: `Bir`,
        liveAgent: `canlı temsilciyle`,
        lineThreeForDefaultMessageLastPart: `konuşmak isterseniz 'Canlı Sohbet' yazın ve Enter tuşuna basın. Ekibimiz size gerçek zamanlı olarak yardımcı olacaktır.`,
        saveChanges: 'Değişiklikleri Kaydet',
        discard: 'At',
        configureBotsGeneralSettings: `Botun Genel Ayarlarını Yaplandır`,
        minutes: 'dakika',
        hours: 'saat',
      }

    },
    whatsapp: {
      whatsappAutomation: 'WhatsApp Otomasyonu',
      whatsappBotSettings: 'WhatsApp Bot Ayarları',
      generalHeader: 'Genel',
      chatFlowsHeader: 'Sohbet Akışları',
      whatsappProfileHeader: 'WhatsApp Profili',
      broadcastHeader: 'Yayın',
      dripCampaignHeader: 'Damla Kampanyası',
      widgetHeader: 'Araç',
      templatesHeader: 'Şablonlar',
      alertsHeader: 'Uyarılar',
      apiKeyHeader: 'API Anahtarı',
      catalogHeader: 'Katalog',
      otherConfiguration: 'AI Ayarları',
      appTooltip: {
        TOOLTIP_CHAT_FLOW_TITLE:
          'Farklı hedef kitleler için sohbet akışı şablonlarını özelleştirin ve birden fazla sohbet akışı oluşturun.',
      },
      alerts: {
        configureYourBotsAlertSettings: 'Botunuzun uyarı ayarlarını yapılandırın',
        specifyHowYouBotWillNotify: 'Botunuzun yeni müşteri bildirimlerini nasıl bildireceğini belirtin.',
        subject: 'Konu',
        emailNotification: 'E-posta Bildirimi',
        emailAddresses: 'E-posta Adresleri',
        leadRevisitNotifications: 'Potansiyel Müşteri Yeniden Ziyareti Bildirimleri',
        whenToSendLeadInfo: 'Potansiyel Müşteri Bilgilerini Gönderme Zamanı',
        discard: 'At',
        saveChanges: 'Değişiklikleri Kaydet',
        seeHowToUse: 'Nasıl kullanılacağını gör',
        beginningTheFlow: 'Akışı Başlatma',
        fiveMinutesLater: '5 Dakika Sonra',
        endOfFlow: 'Akışın Sonu',
        placeholders: {
          enterSubjectForYourEmail: 'E-posta bildiriminiz için konuyu girin',
          typeEmailAndHitEnter: 'E-posta adresinizi girin ve Enter tuşuna basın',
        },
        appTooltip: {
          TOOLTIP_SUBJECTS: 'Botunuzun e-posta bildirimlerinin konusunu özelleştirin',
          TOOLTIP_EMAIL_NOTIFICATION: 'Yeni müşteri bildirimlerini e-posta üzerinden botun uyarısını aç/kapatın',
          TOOLTIP_EMAIL_ADDRESS: 'E-posta bildirimlerinizi almak istediğiniz hesabın e-posta adresini verin. Birden fazla e-posta adresi ekleyebilirsiniz, birini girdikten sonra başka bir e-posta adresi eklemek için Enter tuşuna basın.',
          TOOLTIP_LEAD_REVISIT_NOTIFICATION: 'Potansiyel müşteri olarak daha önce kaydedilen bir kullanıcı bot ile tekrar etkileşime geçerse, botun e-posta üzerinden bildirimini aç/kapatın.',
        }
      },


      editProfile: {
        updateWhatsappChatBot: 'WhatsApp Sohbet Botunu Güncelle',
        businessNameIsRequired: 'İşletme Adı gereklidir.',
        maxLengthForBusinessName40Characters: 'İşletme Adı için maksimum uzunluk 40 karakterdir.',
        categoryIsRequired: 'Kategori gereklidir.',
        descriptionIsRequired: 'Açıklama gereklidir.',
        maxLengthForDescription200Characters: 'Açıklama için maksimum uzunluk 200 karakterdir.',
        aboutIsRequired: 'Hakkında gereklidir.',
        maxLengthForAbout139Characters: 'Hakkında için maksimum uzunluk 139 karakterdir.',
        pleaseFillAValidBusinessEmail: 'Lütfen geçerli bir iş e-postası doldurun.',
        businessEmailIsRequired: 'İş e-postası gereklidir.',
        websiteIsRequired: 'Web sitesi gereklidir.',
        maxLengthForWebsite40Characters: 'Web sitesi için maksimum uzunluk 40 karakterdir.',
        maxLengthForAddress150Characters: 'Adres için maksimum uzunluk 150 karakterdir.',
        cancel: 'İptal',
        update: 'Güncelle',
        businessCategory: 'İş Kategorisi',
        placeholders: {
          yourBusinessName: 'İşletme Adınız',
          businessDescription: 'İş Açıklaması',
          about: 'Hakkında',
          businessEmail: 'İş E-postası',
          website: 'Web Sitesi (örn. www.ornek.com)',
          address: 'Adres',
        }
      },
      flows: {
        chatFlows: 'Sohbet Akışları',
        createChatFlow: 'Sohbet Akışı Oluştur',
        youCanCreateMultipleChatFlows: 'A/B testleri ve yayınlar için birden fazla sohbet akışı oluşturabilirsiniz.',
        flowName: 'Akış adı',
        nameIsRequired: 'Ad gereklidir',
        cancel: 'İptal',
        createFlow: 'Akış Oluştur',
        importFlow: 'Akışı İçe Aktar',
        createNewFlow: 'Yeni Akış Oluştur',
        ofMessages: ' mesajdan',
        createdOn: 'Oluşturulma tarihi',
        lastModified: 'Son düzenleme',
        defaultFlow: 'Varsayılan akış',
        revisitFlow: 'Tekrar Ziyaret Akışı',
        action: 'Aksiyon',
        exportStatus: 'Dışa Aktarma Durumu',
        flowExport: 'Akış Dışa Aktar',
        beforeYouStartUploadPleaseMakeSure: 'Yüklemeye başlamadan önce lütfen emin olun',
        point1: '1. Maksimum dosya boyutu yükleme sınırı 20MB\'dir',
        point2: '2. Dosya biçimi JSON biçiminde olmalıdır',
        selectFile: 'Dosya Seç',
        seeHowToUse: 'Nasıl kullanılacağını gör',
        download: 'İndir',
        available: 'Mevcut',
        initiated: 'Başlatıldı',
        inProgress: 'Devam Ediyor',
        placeholders: {
          enterFlowName: 'Akış adını girin',
        },
        appTooltip: {
          importFlowForWhatsappBot: 'Whatsapp botu için akışı içe aktar',
          dateAndTimeOfCreationOfChatFlow: 'Sohbet akışının oluşturulma tarihi ve saati',
          dateAndTimeWhenChatFlowLastModified: 'Sohbet akışının son düzenleme tarihi ve saati',
          TOOLTIP_CREATE_FLOW: 'Yeni bir sohbet akışı oluşturmak için tıklayın',
          TOOLTIP_FLOW_NAME: 'Sohbet akışı adını girin',
          TOOLTIP_FLOW_TABLE_NAME: 'Sohbet akışının adı',
          TOOLTIP_NO_OF_MESSAGES: 'Sohbet akışındaki mesaj bileşenlerinin sayısı',
          TOOLTIP_DEFAULT_FLOW: 'Bu anahtarı, tüm yeni kullanıcılara sunulacak bir sohbet akışını varsayılan olarak ayarlamak için kullanın.',
          TOOLTIP_REVISIT_FLOW: 'Bu anahtarı, kullanıcılar botunuzu tekrar ziyaret ettiklerinde sunulacak bir sohbet akışını seçmek için kullanın.',
          TOOLTIP_ACTION: 'Sohbet akışını düzenleyin veya silin',
        },
        modal: {
          deleteFlow: 'Akışı Sil',
          wantToDeleteThisFlow: 'Bu Akışı silmek istediğinizden emin misiniz?',
        },
        noDataFound: {
          flows: 'Akışlar',
          noFlowsAddedYet: 'Henüz Akış eklenmedi!',
        }
      },

      input: {
        manageTemplates: 'Şablonları Yönet',
        manageYourTemplatesForMarketingAutomation: 'Pazarlama otomasyonu için şablonlarınızı yönetin',
        syncTemplates: 'Şablonları Senkronize Et',
        createTemplateHeader: 'Şablon Oluştur',
        title: 'Başlık',
        info: 'Bilgi',
        category: 'Kategori',
        status: 'Durum',
        action: 'Eylem',
        shipmentUpdate: 'Sevkiyat Güncellemesi',
        name: 'Ad',
        // category: 'Kategori',
        headerOptional: 'Başlık (İsteğe bağlı)',
        bodyRequired: 'Gövde (gerekli)',
        footerOptional: 'Altbilgi (İsteğe bağlı)',
        buttonsOptional: 'Düğmeler (İsteğe bağlı)',
        makeUpto3Buttons: 'En fazla 3 düğme oluşturun',
        makeUpto2ButtonsToRedirect: 'Müşteriyi web sitenize yönlendirmek için en fazla 2 düğme oluşturun',
        placeholders: {
          nameYourTemplate: 'Şablonunuzun adını belirtin (kelimeleri ayırmak için alt çizgi kullanın)',
          enterText: 'Metin girin (en fazla 60 karakter)',
          enterImageUrl: 'Resim URL\'sini girin',
          enterVideoUrl: 'Video URL\'sini girin',
          templateMessage: 'Şablon Mesajı',
          smallFooterAtBottom: 'Altta küçük bir altbilgi',
          buttons: 'Düğmeler',
          text: 'Metin',
          numberWithCountryCode: 'Ülke kodlu numara',
          enterButtonText: 'Düğme Metnini Girin (en fazla 20 karakter)',
        },
        appTooltip: {
          TOOLTIP_INPUT_TABLE_TITLE: 'WhatsApp şablonunun başlığı',
          TOOLTIP_INPUT_TABLE_INFO: 'İşletme başlatılan mesajların ayrıntılı bilgisi',
          TOOLTIP_INPUT_TABLE_CATEGORY: 'Randevular, hatırlatmalar, müşteri hizmetleri mesajları, sevkiyat güncellemeleri, uyarılar gibi işletme başlatılan mesajların türü',
          TOOLTIP_INPUT_TABLE_STATUS: 'Şablon durumu, WhatsApp tarafından reddedilmiş veya onaylanmıştır. WhatsApp şablonları önce WhatsApp tarafından onaylanmalıdır ve bu bir ücretli hizmettir.',
          TOOLTIP_INPUT_TABLE_ACTIONS: 'Şablonu sil',
        },
        noDataFound: {
          templates: 'Şablonlar',
          noTemplatesCreatedYet: 'Henüz Şablon oluşturulmadı!',
        },
        modal: {
          deleteTemplate: 'Şablonu Sil',
          wantToDeleteThisTemplate: 'Bu Şablonu silmek istediğinizden emin misiniz?',
        },

        createTemplate: {
          createTemplates: 'Şablonlar Oluştur',
          createTemplatesForMarketingAutomation: 'Pazarlama otomasyonu için şablonlarınızı oluşturun',
          templateMeta: 'Şablon Meta',
          selectLanguage: 'Dil seçin',
          header: 'Başlık',
          optional: 'İsteğe bağlı',
          samplesForHeaderContent: 'Başlık içeriği için örnekler',
          toHelpMetaReviewYourContent: 'Meta içeriğinizi gözden geçirmemize yardımcı olmak için başlık içindeki değişkenlerin veya medyanın örneklerini sağlayın. Lütfen müşteri bilgilerini eklemeyin.',
          body: 'Gövde',
          addVariable: 'Değişken Ekle',
          samplesForBodyContent: 'Gövde içeriği için örnekler',
          toHelpUsReviewYourContent: 'İçeriğinizi gözden geçirmemize yardımcı olmak için gövde içindeki değişkenlerin örneklerini sağlayın. Lütfen müşteri bilgilerini eklemeyin.',
          footer: 'Altbilgi',
          // optional: 'İsteğe bağlı',
          buttons: 'Düğmeler',
          // optional: 'İsteğe bağlı',
          typeOfAction: 'Eylem Türü',
          call: 'Ara',
          phone: 'Telefon',
          website: 'Web Sitesi',
          static: 'Statik',
          urlType: 'URL Türü',
          type: 'Tür',
          customButton: 'Özel Düğme',
          addAnotherButton: 'Başka bir düğme ekleyin',
          preview: 'Önizleme',
          test: 'Test',
          create: 'Oluştur',
          addSampleUrl: 'Örnek URL Ekle',
          toHelpMetaReviewYourMessage: 'Meta mesaj şablonunuzu gözden geçirmemize yardımcı olmak için lütfen web sitesi URL örneği ekleyin. Lütfen gerçek müşteri bilgilerini kullanmayın.',


          placeholders: {
            enterMessageTemplateName: 'Mesaj şablon adını girin',
            searchTemplateByName: 'İsimle şablon ara',
            selectTemplate: 'Şablon Seç',
            enterText: 'Metin Girin',
            enterBody: 'Gövdeyi Girin',
            enterContentForVariable: 'Değişken içeriğini girin',
            enterFooter: 'Alt Bilgiyi Girin',
            phone: 'Telefon*',
            enterWebsiteUrl: 'Web sitesi URL\'sini girin',
          },
          bpInput: {
            name: 'Ad',
            buttonText: 'Düğme Metni',
            websiteUrl: 'Web Sitesi URL\'si',
          },
          bpDropDown: {
            selectCategory: 'Kategori Seç',
          }
        },
        templatePreview: {
          preview: 'Önizleme',
          test: 'Test',
        },
        templateConfig: {
          saveChanges: 'Değişiklikleri Kaydet',
          discard: 'At',
          triggerChatFlowOnTemplateReply: 'Şablon Yanıtında Sohbet Akışını Tetikle',
          templateDetails: 'Şablon Ayrıntıları',
          configureFlows: 'Akışları Yapılandır',
          selectFlow: 'Akış Seç',
          name: 'Ad'
        }
      },

      shopManagement: {
        shopManagement: 'Mağaza Yönetimi',
        manageECommerceShopFromBotPenguin: 'BotPenguin ile bağlantılı e-ticaret mağazalarını yönetin',
        comingSoon: '( Yakında Geliyor )',
        wooCommerce: {
          createConfiguration: 'Yapılandırma Oluştur',
          eventConfiguration: {
            createConfiguration: 'Yapılandırma Oluştur',
            templateHeader: 'Şablon Başlığı',
            selectFile: 'Dosya Seç',
            templateMessage: 'Şablon Mesajı',
            saveChanges: 'Değişiklikleri Kaydet',
            bpDropDown: {
              selectEvent: 'Etkinlik Seç',
              selectTemplate: 'Şablon Seç',
            }
          }
        }
      },
      whatsappProfile: {
        whatsappProfile: 'WhatsApp Profili',
        manageYourBotProfile: 'Bot Profilinizi istediğiniz zaman yönetin.',
        allSetNoActionRequired: 'Her şey ayarlı, herhangi bir işlem gerekmiyor!',
        congratulationsYourAccountIsLive: 'Tebrikler! Hesabınız tam erişimle etkinleştirildi',
        somethingIsWrongReactivateYourBot: 'Bir şeyler yanlış, botunuzu yeniden etkinleştirin!',
        itSeemsLikeYourBotIsNotWorkingProperly: 'Botunuzun düzgün çalışmadığı gibi görünüyor, lütfen botunuzu sıfırlayın',
        reConfigureYourBot: 'Botunuzu yeniden yapılandırın',
        phoneNumberDetails: 'Telefon Numarası Detayları',
        phoneNumber: 'Telefon Numarası',
        nA: 'NA',
        phoneNumberId: 'Telefon Numarası Kimliği',
        wabaId: 'WABA Kimliği',
        change: 'Değiştir',
        accessToken: 'Erişim Anahtarı',
        messagingLimit: 'Mesajlaşma Sınırı',
        qualityRating: 'Kalite Değerlendirmesi',
        whatsappBusinessAccountName: 'WhatsApp İş Hesabı Adı',
        whatsappPublicProfile: 'WhatsApp Genel Profil',
        profilePicture: 'Profil Resmi',
        aboutText: 'Hakkında Metni',
        address: 'Adres',
        description: 'Açıklama',
        email: 'E-posta',
        websitePrimary: 'Web Sitesi (Birincil)',
        websiteSecondary: 'Web Sitesi (İkincil)',
        businessVertical: 'İş Kolu',
        editProfile: 'Profili Düzenle',
        thisIsABusinessAccount: 'Bu bir iş hesabıdır',
        aboutAndPhoneNumber: 'Hakkında ve Telefon Numarası',
        yourTrialAccountIsLive: 'Tebrikler! Deneme hesabınız, doğrulanan numaranız üzerinde tam erişimle etkinleştirildi',
        updateWithNewAccessToken: 'Yeni erişim anahtarı ile güncelle',
      },
      widgets: {
        manageWidgets: 'Widgetları Yönet',
        widgetsAreRoutingTools: 'Widgetlar, bot kullanımını artırmak için yönlendirme araçlarıdır',
        needHelp: 'Yardıma mı ihtiyacınız var?',
        wantToMakeChanges: 'Değişiklik yapmak istiyor musunuz?',
        prePopulatedMessage: 'Önceden Doldurulmuş Mesaj',
        discard: 'At',
        updateWidget: 'Widget\'ı Güncelle',
        hiThere: 'merhaba ',

        seeHowToUse: 'Nasıl kullanılacağını gör',
        editIcon: 'Simgeyi Düzenle',
        editPrePopulatedMessage: 'Önceden Doldurulmuş Mesajı Düzenle',
        small: 'Küçük',
        medium: 'Orta',
        large: 'Büyük',
        leftMiddle: 'Sol-Orta',
        rightMiddle: 'Sağ-Orta',
        leftBottom: 'Sol-Alt',
        rightBottom: 'Sağ-Alt',
        chatBotIcon: 'Sohbet Botu Simgesi',
        link: 'Bağlantı',
        qrcode: 'QR Kodu',
        copyChatBotCode: 'Sohbet Botu Kodunu Kopyala',
        copyWALink: 'WA Bağlantısını Kopyala',
        downloadQrCode: 'QR Kodunu İndir',
        installChatBotIconOnYourWebsite: 'Web sitenize sohbetbotu simgesini kurun',
        shareWhereEverYouWantRedirectUsersTo: 'Nereye isterseniz paylaşın ve kullanıcıları tek tıklamayla WhatsApp botunuza yönlendirin',
        copyAndAddInYourWebsiteHeaderFile: 'Kopyala ve web sitenizin başlık dosyasına ekleyin',
        copyLinkAndStartSharing: 'Bağlantıyı Kopyala ve Paylaşmaya Başla!',
        downloadQrCodeAndStartSharing: 'QR kodunu İndir ve Paylaşmaya Başla!',
        addCodeAndDeployNewCodeAndDone: 'Kodu Ekleyin, Yeni Kodu Yayınlayın ve Tamamlandı!',
        wantToLearnHowToUseIt: 'Nasıl kullanılacağını öğrenmek istiyor musunuz?',
        placeholders: {
          enterWhatsappNumber: 'WhatsApp numarasını girin',
          updateYourMessageHere: 'Mesajınızı burada güncelleyin...',
        },
        bpDropDown: {
          selectIcons: 'Simge Seçin',
          iconSize: 'Simge Boyutu',
          iconPlacement: 'Simge Yerleşimi',
        },
        bpInput: {
          whatsappNumber: 'WhatsApp Numarası',
        }

      },
      catalog: {
        createCatalog: 'Katalog Oluştur',
        createProductCatalogOnTheMetaCommerceManagerOrLinkItWithShopify: 'Meta Commerce Yöneticisi üzerinde ürün kataloğu oluşturun veya Shopify ile bağlayın',
        useYourMetaCommerceManagertoManageCatalogs: 'Katalogları yönetmek için Meta ticaret yöneticinizi kullanın',
        connectCatalogToWhatsAppAccount: 'Kataloğu WhatsApp hesabına bağla',
        connectYourCatalogsToWhatsAppAccountUsingYourWhatsAppManager: 'Kataloğunuzu WhatsApp hesabına, WhatsApp yöneticiniz kullanarak bağlayın',
        useYourMetaWhatsAppManagerToConnectCatalogsAndUsethemWithBotPenguin: 'Meta WhatsApp yöneticinizi kullanarak katalogları bağlayın ve BotPenguin ile kullanın',
        goToMeta: 'Meta\'ya git',
        goToShopify: 'Shopify\'a git',
        next: 'İleri',
        back: 'Geri',
        setupCatalog: 'Katalog Kurulumu',
        completeAllTheStepsToEnableCatalogMessages: 'Katalog mesajlarını etkinleştirmek için tüm adımları tamamlayın'
      },
      generalSetting: {
        sendInactivityMessage: 'Kullanıcı Hareketsizlik Mesajı',
        sessionTimeOutInHours: 'Oturum Süresi (saat cinsinden)',
        enterSessionTimeOutWindowInHours: 'Oturum süresi penceresini saat cinsinden girin',
        sessionTimeOutInHoursTooltip: `Kullanıcının etkisizlik nedeniyle sohbet oturumunun otomatik olarak sona ereceği süreyi (saat cinsinden) girin. Kullanıcı, oturum süresinden sonra herhangi bir mesaj gönderirse sohbet akışı yeniden başlatılır`,
        restartChatFlowTriggers: 'Sohbet Akışını Yeniden Başlatma Tetikleri',
        enterKeyWordsToRestartChatAndhitEnter: 'Sohbeti yeniden başlatmak için anahtar kelimeleri girin ve Enter tuşuna basın',
        restartChatFlowTriggersTooltip: `Kullanıcının girdiğinde ve gönderdiğinde sohbet akışının yeniden başlamasına neden olacak anahtar kelimeleri belirtin. Bu, kullanıcıların herhangi bir noktada sohbet akışını yeniden başlatmalarına olanak tanır.
                Birden çok anahtar kelime eklemek için her anahtar kelimenin ardından Enter tuşuna basın.`,
        liveChatTriggers: 'Canlı Sohbet Tetikleri',
        enterKeyWordsToTriggerLiveChatAndhitEnter: 'Canlı sohbeti tetiklemek için anahtar kelimeleri girin ve Enter tuşuna basın',
        liveChatTriggersTooltip: `Kullanıcı tarafından girildiğinde ve gönderildiğinde otomatik sohbetten canlı sohbete geçişi başlatacak anahtar kelimeleri tanımlayın. Kullanıcıların bir canlı temsilciye yardım gerektiğinde kullanışlıdır.
                Birden çok anahtar kelime eklemek için her anahtar kelimenin ardından Enter tuşuna basın.`,
        messageAfterAutomatedFlowCompleted: 'Otomatik Akış Tamamlandıktan Sonra Mesaj',
        enterYourMessage: 'Mesajınızı girin',
        messageAfterAutomatedFlowCompletedTooltip: `Kullanıcılar otomatik sohbet akışını tamamladıktan sonra oturum zaman aşımı olmamışsa gösterilecek bir mesaj girin.`,
        restart: 'Yeniden Başlat',
        liveChat: 'Canlı Sohbet',
        lineOneForDefaultMessage: 'Daha fazla sorunuz varsa veya yardıma ihtiyacınız varsa, şunları yapabilirsiniz:',
        to: 'Şuna',
        restartTheChat: 'sohbeti yeniden başlatın',
        simpleTypeRestartAndHitEnter: `basitçe 'Yeniden Başlat' yazın ve Enter tuşuna basın. Bu sizi sohbetimizin başlangıcına geri götürecek.`,
        lineThreeForDefaultMessagePartOne: `Bir`,
        liveAgent: `canlı temsilciyle`,
        lineThreeForDefaultMessageLastPart: `konuşmak isterseniz 'Canlı Sohbet' yazın ve Enter tuşuna basın. Ekibimiz size gerçek zamanlı olarak yardımcı olacaktır.`,
        saveChanges: 'Değişiklikleri Kaydet',
        discard: 'At',
        configureBotsGeneralSettings: `Botun Genel Ayarlarını Yaplandır`,
        minutes: 'dakika',
        hours: 'saat',
      }
    },
    activateWhatsAppBot: {
      justAStepAway: 'Sadece Bir Adım Uzaktasınız',
      seeHowToUse: 'Nasıl Kullanılacağını Görün',
      testWhatsAppTrialBot: 'WhatsApp Deneme Botunuzu Test Edin',
      sendMessage: 'Mesaj Gönder',
      sendWhatsAppMessageOn: 'WhatsApp üzerinden bize mesaj gönderin: ',
      bySavingInYourContact: 'Kişilerinize kaydederek başlayın ve mesajınız şununla başlamalıdır: ',
      connect: 'Bağlan',
      or: 'VEYA',
      scanQRCode: 'QR kodu tarayın',
      yourPhoneWillOpen: 'Telefonunuz, Sandbox numaramıza göndermek için önceden doldurulmuş bir mesaj açacak. WhatsApp üzerinden "Gönder"e basın',
      sendVerificationCode: 'Doğrulama Kodu Gönder',
      copyAndEnterCode: 'Kopyalayın ve 6 haneli kodu girin',
      shareWhereYouWant: 'Nereye isterseniz paylaşın ve kullanıcıları tek tıklamayla WhatsApp botunuza yönlendirin',
      youShouldHaveReceived: 'Telefonunuza 6 haneli bir kod aldınız',
      EnterYourPhoneNumber: 'Telefon Numaranızı Girin',
      send: 'Gönder',
      sixDigitCode: '6 haneli kodu girin',
      pleaseNote: 'Lütfen dikkat: 6 haneli doğrulama kodu en fazla 3 dakika sürebilir. Tekrar göndermeden önce lütfen bekleyin.',
      resendVerificationCode: 'Doğrulama kodunu tekrar gönder',
      verify: 'Doğrula',
      providePhoneNumber: 'Lütfen test için kullanmak istediğiniz numarayı girin',
      demoBotisReady: 'Harika! Demo botunuz kullanıma hazır',
      placeHolder: {
        phone: 'Telefon numaranızı girin',
        code: 'Doğrulama kodunu girin',
      }
    }
  },
  integrations: {
    thirdPartyAppsIntegration: 'Üçüncü Taraf Uygulamalar Entegrasyonu',
    thirdPartyApps: 'Üçüncü Taraf Uygulamalar',
    customApps: 'Özel Uygulamalar',
    preview: 'Önizleme',
    test: 'Test',
    install: 'Yükle',
    appTooltip: {
      clickToInstallYourBotToSelectedPlatform: 'Botunuzu seçilen platforma yüklemek için tıklayın',
      TOOLTIP_MY_INTEGRATION_TITLE: 'Özel oluşturulan Uygulamalarınızı botunuzla entegre edin',
      TOOLTIP_ALL_INTEGRATION_TITLE: 'Botunuzu mevcut üçüncü taraf Uygulamalar/Customer Relationship Management (CRM) ile bağlayın',
    },
    allIntegration: {
      list: {
        thirdPartyAppsIntegrations: 'Üçüncü Taraf Uygulamalar Entegrasyonları',
        integrateYourBotWithListedCrms: 'Botunuzu listelenen Uygulamalardan/CRM\'lerden biri ile entegre edin.',
        help: 'Yardım',
        name: 'Ad',
        status: 'Durum',
        action: 'Eylem',
        chatGpt: 'ChatGPT',
        addOn: 'Eklenti',
        purchased: 'Satın Alındı',
        readyToRideGptWave: 'GPT dalgasına hazır mısınız? Şimdi entegre edin.',
        purchase: 'Satın Al',
        notConnected: 'Bağlı Değil',
        integrated: 'Entegre Edildi',
        configure: 'Yapılandır',
        connected: 'Bağlı',
        edit: 'Düzenle',
        connect: 'Bağlan',
        requestYourIntegration: 'Entegrasyonunuzu İsteyin',
        toRequestNewIntegration: 'Yeni bir entegrasyon istemek için lütfen aşağıdaki bilgileri girin ve ardından size geri döneceğiz.',
        emailId: 'E-posta Kimliği',
        integrationName: 'Entegrasyon Adı',
        contactNumber: 'İletişim Numarası',
        howSoonYouWantToAddThisIntegration: 'Bu Entegrasyonu Ne Kadar Hızlı Eklemek İstiyorsunuz',
        sendRequest: 'İstek Gönder',
        cancel: 'İptal',
        requestSent: 'İstek Gönderildi!',
        yourRequestSuccessfullySent: 'Yeni entegrasyon isteğiniz başarıyla gönderildi.',
        goBack: 'Geri Dön',
        disconnect: 'Bağlantıyı Kes',
        OopsIntegrationAreNotAvailable: 'Üzgünüz! Entegrasyonlar ',
        pleaseUpgradeTo: ' mevcut değil. Lütfen ',
        planForIntegration: ' yükseltme yapın.',
        placeholders: {
          searchIntegrationByName: 'İsimle entegrasyon ara',
          enterTeamMemberName: 'Takım üyesi adını girin',
          enterEmailId: 'E-posta Kimliği girin',
          enterIntegrationName: 'Bir Entegrasyon Adı Girin',
          searchCountry: 'Ülke ara',
          enterContactNumber: 'İletişim Numarası girin',
          addComment: 'Yorum Ekle',
        },
        noDataFound: {
          integrations: 'Entegrasyonlar',
          noIntegrationsFound: 'Entegrasyon Bulunamadı!',
        },
        appTooltip: {
          nameOfAppOrCrm: 'Uygulama/CRM Adı',
          statusAsConnectedOrNotConnected: 'Bağlı / Bağlı Değil olarak durum',
          clickToConnectToAppOrCrm: 'Uygulamayı/CRM\'yi bağlamak için tıklayın',
        },
        feedbackModal: {
          submit: 'Gönder',
        }
      },
      providers: {
        agileCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Başarıyla Entegrasyon Oluşturdunuz. Şimdi bunu yapılandırabilirsiniz.',
          agileCrmDomain: 'Agile CRM Alanı',
          nameIsRequired: 'Ad gerekli',
          emailAddress: 'E-posta Adresi',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Lead',
          questionColon: 'Soru:',
          addParameter: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            exampleSite: 'https://firma.agilecrm.com',
            exampleEmail: 'ornek@firma.com.tr',
            enterTokenHere: 'Buraya anahtarınızı girin',
          }
        },
        appTivo: {
          youHaveSuccessfullyCreatedIntegration: 'Başarıyla bir entegrasyon oluşturdunuz. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          accessKey: 'Erişim Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiKeyHere: 'API Anahtarını Buraya Girin',
            enterAccessKeyHere: 'Erişim Anahtarını Buraya Girin',
          }
        },
        autoPilot: {
          youHaveSuccessfullyCreatedIntegration: 'Başarıyla bir entegrasyon oluşturdunuz. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gerekli',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
          }
        },

        bitrixCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Başarıyla bir entegrasyon oluşturdunuz. Şimdi bunu yapılandırabilirsiniz.',
          nameColon: 'Ad:',
          inboundWebhookUrl: 'Gelen Webhook URL',
          nameIsRequired: 'Ad gerekli',
          leadTitle: 'Potansiyel Müşteri Başlığı',
          source: 'Kaynak',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterInboundWebhookUrl: 'Gelen Webhook URL\'sini Girin...',
            enterTitle: 'Başlık Girin',
            enterSource: 'Kaynak Girin'
          }
        },

        calendarHeroCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Buraya Anahtar Girin',
          }
        },

        capsuleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gerekli',
          testLead: 'Test Müşterisi',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
          }
        },
        chakraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          testLead: 'Test Müşterisi',
          connect: 'Bağlan',
          selectTheQuestion: 'Değer olarak göndermek istediğiniz kullanıcı yanıtının bulunduğu soruyu seçin',
          mapWith: 'ile eşleştir',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
            attributes: 'Özellikler (anahtar)',
            values: 'Değerler',
          }
        },
        chatGpt: {
          chatGptApiKey: 'ChatGPT (Open AI) API anahtarı',
          chatGpt: 'ChatGPT (Open AI)',
          readyToRideWave: 'GPT dalgasına binmeye hazır mısınız? Şimdi entegre edin.',
          doNotHaveAnyApiKeyNeedHelp: 'Herhangi bir API anahtarınız yok mu, Yardım mı gerekiyor?',
          authenticate: 'Doğrula',
          nowYouCanAccessChatGpt: 'Şimdi sohbet akışı oluşturucu içinde Chat GPT bileşimine erişebilirsiniz',
          paymentSuccessful: 'Ödeme başarılı!',
          paymentHasBeenCompletedSuccessfully: 'Ödeme başarıyla tamamlandı. Botunuzu eğitmek için her şey hazır.',
          cancel: 'İptal',
          trainNow: 'Şimdi Eğitin',
          paymentInProgress: 'Ödeme İşlemde...',
          apiKey: 'API Anahtarı',
          model: 'Model',
          integrateWithChatGPT: 'ChatGPT ile Entegrasyon',
          updateKey: 'API Anahtarı Guncelle',
          connect: 'Bağlan',
          update: 'Güncelle',
          placeholders: {
            chatGptApiKey: 'ChatGPT (Open AI) API anahtarı',
          }
        },
        closeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          leadId: 'Potansiyel Müşteri Kimliği',
          selectLeadId: 'Potansiyel Müşteri Kimliği Seçin',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Buraya Anahtar Girin',
          }
        },

        copperCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          emailAddress: 'E-posta Adresi',
          leadTitle: 'Potansiyel Müşteri Başlığı',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gerekli',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            exampleEmail: 'ornek@firma.com.tr',
            enterYourLeadTitle: 'Potansiyel Müşteri Başlığınızı Girin',
            enterTokenHere: 'Buraya Anahtar Girin',
          }
        },
        dripCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          dripCrmAccountId: 'Drip CRM Hesap Kimliği',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterAccountIdHere: 'Hesap Kimliğini Buraya Girin',
            enterYourTokenHere: 'Anahtarınızı Buraya Girin',
          }
        },
        elevioCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apikey: 'API Anahtarı',
          accessToken: 'Erişim Anahtarı',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          selectParameter: 'Parametre Seçin',
          disconnect: 'Bağlantıyı Kes',
          update: 'Güncelle',
          save: 'Kaydet',
          placeholders: {
            enterApiKeyHere: 'API Anahtarını Buraya Girin',
            enterTokenHere: 'Erişim Anahtarını Buraya Girin',
          }
        },
        engageBay: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          nameIsRequired: 'Ad gerekli',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          testLead: 'Test Müşterisi',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
          }
        },

        extraEdge: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          authToken: 'AuthToken',
          source: 'Kaynak',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterAuthTokenHere: 'AuthToken\'ı Buraya Girin',
            enterSourceHere: 'Kaynağı Buraya Girin'
          }
        },
        facebookPixel: {
          facebookPixel: 'Facebook Pixel',
          getInsight: `BotPenguin'ın Google Analytics Entegrasyonu ile işletmenizi bir kurumsal işletmeye dönüştüren İçgörüler elde edin`,
          pixelId: 'Facebook Pixel Kimliği',
          connect: 'Bağlan',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterYourId: 'Facebook Pixel Kimliğinizi Girin'
          }
        },
        firmao: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          companyName: 'Şirket Adı',
          emailAddress: 'E-posta Adresi',
          password: 'Şifre',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapsWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterCompanyName: 'Şirket Adını Girin',
            enterEmailAddress: 'E-posta Adresini Girin',
            enterPassword: 'Şifreyi Girin',
          }
        },
        firstPromoterCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiKeyHere: 'API Anahtarını Buraya Girin',
          }
        },


        flowlu: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          flowluDomain: 'Flowlu Alan Adı',
          apiToken: 'API Anahtarı',
          title: 'Başlık',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            exampleDomain: 'https://sirketiniz.flowlu.com',
            enterTokenHere: 'API Anahtarını Buraya Girin',
            enterYourLeadTitle: 'Lidere Başlık Girin'
          }
        },
        freshdeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          freshdeskDomain: 'Freshdesk Alan Adı',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gerekli',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            exampleDomain: 'https://altalan.freshdesk.com',
            enterTokenHere: 'API Anahtarını Buraya Girin',
          }
        },
        freshworksCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          freshworkCrmDomain: 'Freshwork CRM Alan Adı',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            exampleDomain: 'https://sirketiniz.myfreshworks.com',
            enterToken: 'API Anahtarını Buraya Girin'
          }
        },
        frontApp: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          mapWith: 'ile eşleştir',
          select: 'Seç',
          update: 'Güncelle',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
          }
        },

        getResponseCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          campaignList: 'Kampanya Listesi',
          selectCampaign: 'Kampanya Seçin',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'API Anahtarını Buraya Girin',
          }
        },
        googleAnalytics: {
          googleAnalytics: 'Google Analytics',
          getInsights: `BotPenguin'ın Google Analytics Entegrasyonu ile işinizi bir işletmeye dönüştüren İçgörüler alın`,
          measurementId: 'Ölçüm Kimliği',
          connect: 'Bağlan',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterYourId: 'Kimliğinizi Girin',
          }
        },
        googleCalendar: {
          testLead: 'Test Müşterisi',
          name: 'Ad:',
          email: 'E-posta:',
          appointmentSummary: 'Randevu Özeti',
          appointmentDescription: 'Randevu Açıklaması',
          addParameters: ' Parametre Ekle',
          questionColon: 'Soru:',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          pause: 'Durdur',
          resume: 'Devam Et',
          disconnect: 'Bağlantıyı Kes',
          reconnect: 'Yeniden Bağlan',
        },
        googleContacts: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          name: 'Ad:',
          email: 'E-posta:',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
        },

        googleRedirect: {

        },
        googleSheet: {
          testLead: 'Test Müşterisi',
          title: 'Başlık',
          questionColon: 'Soru:',
          addParameters: 'Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          update: 'Güncelle',
          save: 'Kaydet',
          pause: 'Durdur',
          resume: 'Devam Et',
          disconnect: 'Bağlantıyı Kes',
          reconnect: 'Yeniden Bağlan',
          placeholders: {
            mappingValues: 'Eşleme Değerleri',
          }
        },

        googleTask: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          name: 'Ad:',
          email: 'E-posta:',
          taskList: 'Görev Listesi',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          pause: 'Durdur',
          resume: 'Devam Et',
          disconnect: 'Bağlantıyı Kes',
          reconnect: 'Yeniden Bağlan',
        },
        grooveHq: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          select: 'Seç',
          addParameters: ' Parametre Ekle',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'API Anahtarını Buraya Girin',
          }
        },
        helpCrunchCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiHere: 'API Anahtarını Buraya Girin',
          }
        },
        helpDeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          testLead: 'Test Müşterisi',
          accountId: 'Hesap Kimliği',
          accessToken: 'Erişim Anahtarı',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterAccountIdHere: 'Hesap Kimliğini Buraya Girin',
            enterAccessTokenHere: 'Erişim Anahtarını Buraya Girin',
          }
        },
        helpDocsCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          testLead: 'Test Müşterisi',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'API Anahtarını Buraya Girin',
          }
        },
        helpLink: {
          help: 'Yardım',
        },
        helpRaceCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          helpRaceCrmDomain: 'Helprace CRM Alan Adı',
          emailAddress: 'E-posta Adresi',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          update: 'Güncelle',
          placeholders: {
            exampleDomain: 'https://company.helprace.com',
            exampleEmail: 'example@company.extension',
            token: 'Token',
          }
        },

        helpShiftCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          domainName: 'Alan Adı',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Token\'ı Buraya Girin',
          }
        },

        hubspotCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gereklidir',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Token\'ını Buraya Girin',
          }
        },
        infobipCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiHere: 'API\'yi Buraya Girin',
          }
        },

        insightlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gereklidir',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Token\'ını Buraya Girin',
          }
        },
        jiraCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          emailAddress: 'E-posta Adresi',
          apiUrl: 'API Url',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          selectProjectName: 'Proje Adı Seçin',
          selectIssueType: 'Sorun Türü Seçin',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapsWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Token\'ı Buraya Girin',
            enterEmailAddress: 'E-posta adresini girin',
            exampleUrl: 'https://ornek.atlassian.net',
          }
        },
        jogetCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiUrl: 'API URL',
          nameIsRequired: 'Ad gereklidir',
          apiKey: 'API Anahtarı',
          apiId: 'API ID',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          mapWith: 'ile eşleştir',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          selectTheQuestion: 'Cevap olarak göndermek istediğiniz soruyu seçin',
          placeholders: {
            enterApiUrl: 'API Url\'yi Buraya Girin',
            enterApiKey: 'API Anahtarını Buraya Girin',
            enterApiId: 'API Kimliğini Buraya Girin',
          }
        },
        karmaCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Token\'ı Buraya Girin',
          }
        },
        kylasCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gereklidir',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Token\'ını Buraya Girin',
          }
        },

        leadSquared: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          accessKey: 'Erişim Anahtarı',
          secretKey: 'Gizli Anahtar',
          connect: 'Bağlan',
          apiLogs: 'API Günlükleri',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          udpate: 'Güncelle', // Typo: Should be "update"
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          filterApplied: 'Filtre uygulandı',
          retry: 'Tekrar Dene',
          back: 'Geri',
          filterYourLeads: 'Müşterilerinizi filtreleyin',
          filterOn: 'Filtrele',
          filterBy: 'Tarafından filtrele',
          discard: 'At',
          applyFilter: 'Filtre Uygula',
          loadMore: 'Daha Fazla Yükle',
          placeholders: {
            enterAccessKeyHere: 'Erişim Anahtarını Buraya Girin',
            enterSecretKeyHere: 'Gizli Anahtarı Buraya Girin',
            attributes: 'Özellikler (anahtar)',
            values: 'değerler',
          },
          appTooltip: {
            filterDataBasedOnResponse: 'Yanıta göre verileri filtrele',
          }
        },
        liveAgentCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          apiUrl: 'API Url',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiHere: 'API Url\'yi Buraya Girin',
            exampleUrl: 'https://sirketAdi.ladesk.com'
          }
        },

        microsoftTeams: {
          downloadPackage: 'Microsoft Teams uygulama paketini indir',
          downloadApp: 'Uygulamayı İndir',
          wantToLearnHowToUploadIt: 'Yüklemeyi nasıl yapacağınızı mı öğrenmek istiyorsunuz?',
          needHelp: 'Yardıma mı ihtiyacınız var?',
        },

        moengageUser: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiUrl: 'API URL',
          appId: 'APP ID',
          nameIsRequired: 'App ID gereklidir',
          dataApiKey: 'DATA API KEY',
          dataApiKeyIsRequired: 'Data API Key gereklidir',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          addParameters: ' Parametre Ekle',
          mapWith: 'ile eşleştir',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          selectTheQuestion: 'Cevap olarak göndermek istediğiniz soruyu seçin',
          placeholders: {
            enterApiUrl: 'API URL\'yi Buraya Girin',
            enterAppId: 'APP ID\'yi Buraya Girin',
            enterDataApiKey: 'DATA API KEY\'i Buraya Girin',
            attributes: 'Özellikler (anahtar)',
            pressForMappingQuestion: 'Eşleme Sorusu için / Tuşuna Basın',
          }
        },

        nethuntCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          emailAddress: 'Email Adresi',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          leadId: 'Müşteri Kimliği',
          selectLeadId: 'Müşteri Kimliği Seç',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterEmailHere: 'Emaili Buraya Girin',
            enterApiKeyHere: 'API Anahtarını Buraya Girin',
          }
        },
        nimbleCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
          }
        },
        noCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          noCrmDomain: 'noCRM Alan Adı',
          leadTitle: 'Müşteri Başlığı',
          titleIsRequired: 'Başlık gereklidir',
          token: 'Token',
          tokenIsRequired: 'Token gereklidir',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            exampleDomain: 'https://SIZIN_ALTDOMAIN_BURAYA.nocrm.io',
            enterYourLeadTitle: 'Müşteri Başlığınızı Girin',
            enterYourToken: 'Token\'ınızı Girin',
          }
        },
        nutshellCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          emailAddress: 'Email Adresi',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
          }
        },

        onpipelineCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          secretToken: 'Gizli Token',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Token\'ı Buraya Girin',
          }
        },
        ontraportCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          appId: 'Uygulama Kimliği',
          appIdIsRequired: 'Uygulama Kimliği gereklidir',
          apiKey: 'API Anahtarı',
          apiKeyIsRequired: 'API anahtarı gereklidir',
          connect: 'Bağlan',
          update: 'Güncelle',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            appId: 'Uygulama Kimliği',
            apiKey: 'API Anahtarı',
          }
        },
        outlook: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          clientId: 'Müşteri Kimliği',
          connect: 'Bağlan',
          clientSecret: 'Müşteri Gizliliği',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          save: 'Kaydet',
          update: 'Güncelle',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterClientIdHere: 'Müşteri Kimliğini Buraya Girin',
            enterClientSecretHere: 'Müşteri Gizliliğini Buraya Girin',
          }
        },
        pipedriverCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          pipedriverDomain: 'PipeDrive Alan Adı',
          domainIsRequired: 'Alan Adı gereklidir',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            exampleDomain: 'https://şirketiniz.pipedrive.com',
            enterTokenHere: 'Token\'ı Buraya Girin',
          }
        },
        pipeline: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          apiKeyIsRequired: 'API anahtarı gereklidir',
          appKey: 'Uygulama Anahtarı',
          appKeyIsRequired: 'Uygulama anahtarı gereklidir',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiKey: 'API Anahtarını Girin',
            enterAppKey: 'Uygulama Anahtarını Girin',
          }
        },
        plivoSmsBot: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          authId: 'Kimlik Doğrulama Kimliği',
          authToken: 'Kimlik Doğrulama Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Token\'ı Buraya Girin',
          }
        },

        recurlyCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiHere: 'API\'yi Buraya Girin',
          }
        },

        salesfareCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiKey: 'API Anahtarını Girin',
          }
        },

        salesforce: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          domain: 'Alan Adı',
          clientId: 'Müşteri Kimliği',
          clientSecret: 'Müşteri Gizliliği',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterDomainHere: 'Alan Adını Buraya Girin',
            enterClientIdHere: 'Müşteri Kimliğini Buraya Girin',
            enterClientSecretHere: 'Müşteri Gizliliğini Buraya Girin',
          }
        },
        sendInBlueCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          addEventsAndTemplates: 'Etkinlikler ve Şablonlar Ekle:',
          add: ' Ekle',
          question: 'Soru',
          reply: 'Cevap',
          requestBody: 'İstek Gövdesi',
          validateJson: 'JSON Doğrula',
          emailAddress: 'E-posta Adresi',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          selectTheQuestion: 'Cevap olarak göndermek istediğiniz soruyu seçin',
          placeholders: {
            enterApiHere: 'API\'yi Buraya Girin',
            enterTemplateId: 'Şablon Kimliğini Girin',
            typeYourJsonCodeHere: 'JSON Kodunuzu Buraya Yazın',
          },
          appTooltip: {
            typeYourJsonCode: 'JSON kodunuzu yazın',
            clickToValidateJsonCode: 'JSON kodunu doğrulamak için tıklayın',
          }
        },
        shipwayCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          userName: 'Kullanıcı Adı',
          licenseKey: 'Lisans Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterNameHere: 'İsmini Buraya Girin',
            enterKeyHere: 'Anahtarı Buraya Girin',
          }
        },
        supportBeeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Anahtarı Buraya Girin',
          }
        },
        simplybook: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          company: 'Şirket',
          loginId: 'Giriş Kimliği',
          password: 'Şifre',
          testLead: 'Test Lideri',
          connect: 'Bağlan',
          selectTheQuestion: 'Kullanıcının cevabını değer olarak göndermek istediğiniz soruyu seçin',
          mapWith: 'Eşleştir',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          selectParameter: 'Parametre Seçin',
          placeholders: {
            enterCompanyHere: 'Şirket Adını Buraya Girin',
            enterLoginIdHere: 'Giriş Kimliğini Buraya Girin',
            enterPasswordHere: 'Şifreyi Buraya Girin',
            values: 'değerler',
          },
        },
        teamGate: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          applicationKey: 'Uygulama Anahtarı',
          authToken: 'Yetkilendirme Anahtarı',
          connect: 'Bağlan',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApplicationKeyHere: 'Uygulama Anahtarını Buraya Girin',
            enterTokenHere: 'Yetkilendirme Anahtarını Buraya Girin',
          }
        },
        wealthboxCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          accessToken: 'Erişim Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          question: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterTokenHere: 'Erişim Anahtarını Buraya Girin',
          }
        },
        webhopersCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          webhoperDomain: 'Webhoper Alan Adı',
          domainIsRequired: 'Alan Adı gereklidir',
          companyName: 'Şirket Adı',
          companyIsRequired: 'Şirket gereklidir',
          leadSource: 'Müşteri Kaynağı',
          leadSourceIsRequired: 'Müşteri Kaynağı gereklidir',
          division: 'Bölüm',
          divisionIsRequired: 'Bölüm gereklidir',
          enterPassword: 'Parolayı Girin',
          passwordIsRequired: 'Parola gereklidir',
          disconnect: 'Bağlantıyı Kes',
          update: 'Güncelle',
          connect: 'Bağlan',
          placeholders: {
            exampleDomain: 'http://sirketadiniz.com',
            enterYourCompanyName: 'Şirket Adınızı Girin',
            enterLeadSource: 'Müşteri Kaynağınızı Girin',
            enterDivision: 'Bölümünüzü Girin',
            enterPassword: 'Parolanızı Girin',
          }
        },

        zapierCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          name: 'Ad:',
          zapier: 'Zapier',
          inboundWebhookUrl: 'Gelen Webhook URL',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterWebhookUrl: 'Webhook URL\'sini Girin',
          }
        },
        zendeskCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          emailAddress: 'E-posta Adresi',
          apiUrl: 'API URL\'si',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterEmailAddress: 'E-posta adresini girin',
            enterUrlHere: 'URL\'yi buraya girin',
            enterTokenHere: 'Anahtarı buraya girin',
          }
        },
        zingtreeCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          apiKey: 'API Anahtarı',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiHere: 'API\'yi buraya girin',
          }
        },
        zohoCrm: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          connect: 'Bağlan',
          update: 'Güncelle',
          disconnect: 'Bağlantıyı Kes',
          companyName: 'Şirket Adı',
          emailAddress: 'E-posta Adresi',
          phone: 'Telefon',
          countryCode: 'Ülke Kodu',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          save: 'Kaydet',
        },
        zohoDesk: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          connect: 'Bağlan',
          disconnect: 'Bağlantıyı Kes',
          testLead: 'Test Müşterisi',
          emailAddress: 'E-posta Adresi',
          selectOrganizationName: 'Organizasyon Adını Seçin',
          selectDepartmentName: 'Bölüm Adını Seçin',
          addParameters: ' Parametre Ekle',
          questionColon: 'Soru:',
          select: 'Seç',
          mapsWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
        },
        zohoProject: {
          youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
          testLead: 'Test Müşterisi',
          connect: 'Bağlan',
          disconnect: 'Bağlantıyı Kes',
          projectsList: 'Proje Listesi',
          selectProjectsName: 'Proje Adını Seçin',
          projectsTaskList: 'Proje Görevleri Listesi',
          selectTaskListName: 'Görev Listesi Adını Seçin',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
        },
        wooCommerce: {
          configure: 'Yapılandır',
          automatedMessage: 'Otomatik Mesaj',
          configureTab: {
            wooCommercePluginApiKey: 'WooCommerce Eklenti API Anahtarı',
            downloadWooCommercePlugin: 'WooCommerce Eklentisini İndir'
          },
          automatedMessageTab: {
            abandonedRecoveryMessage: 'Terkedilmiş Kurtarma Mesajı',
            configureAutomatedRecoveryMessagesToWinBackCustomerWhoAbandonedTheirCarts: 'Alışveriş sepetlerini terk eden müşterileri geri kazanmak için otomatik kurtarma mesajlarını yapılandırın',
            enableAbandonedCartRecoveryMessaegs: 'Terkedilen sepet kurtarma mesajlarını etkinleştir',
            selectTemplate: 'Şablon Seç',
            sendMessageAfter: 'Mesajı Gönderme Süresi',
            templateMessage: 'Şablon Mesajı',
            saveChanges: 'Değişiklikleri Kaydet',
            selectFile: 'Dosya Seç',
            orderCompleted: 'Sipariş Tamamlandı',
            templateHeader: 'Şablon Başlığı',
            orderFulfillMent: 'Siparişin Yerine Getirilmesi',
            enableOrderConfirmationMessages: 'Sipariş onay mesajlarını etkinleştir',
            sendAnOrderConfirmationMessageAsSoonAsACustomerPlacesAnOrder: 'Bir müşteri bir sipariş verdiğinde hemen sipariş onay mesajı gönderin.',
            orderShipmentMessageIsSentWhenYouFulfillAnOrder: 'Sipariş yerine getirildiğinde sipariş gönderimi mesajı gönderilir.',
            enableOrderFulfillmentMessages: 'Sipariş yerine getirme mesajlarını etkinleştir',
            returnExchangeRequest: 'İade/Değişim Talebi',
            theMessageIsSentAfterARefundIsRequested: 'İade talebi yapıldıktan sonra mesaj gönderilir',
            enableOrderRefundMessages: 'Sipariş iade mesajlarını etkinleştir',
          }
        },
        goHighLevel : {
          youHaveSuccessfullyCreatedIntegration: 'Başarıyla bir entegrasyon oluşturdunuz. Şimdi bunu yapılandırabilirsiniz.',
          apiToken: 'API Anahtarı',
          nameIsRequired: 'Ad gerekli',
          connect: 'Bağlan',
          testLead: 'Test Müşterisi',
          questionColon: 'Soru:',
          addParameters: ' Parametre Ekle',
          select: 'Seç',
          mapWith: 'ile eşleştir',
          selectParameter: 'Parametre Seçin',
          update: 'Güncelle',
          save: 'Kaydet',
          disconnect: 'Bağlantıyı Kes',
          placeholders: {
            enterApiToken: 'API Anahtarını Girin',
          }
      }

      }
    },







    createIntegration: {
      createNewIntegration: 'Yeni Entegrasyon Oluştur',
      youDoNotHaveAnyIntegrationSetupAsOfNow: 'Şu anda hiçbir entegrasyon ayarınız bulunmuyor.',
      pleaseCreateOneToIntegrateYourFavourite: 'Favori uygulamalarınızı entegre etmek için lütfen bir tane oluşturun.',
      appsWith: ' uygulamalarıyla birleştir',
      integrationName: 'Entegrasyon Adı',
      integrationNameIsRequired: 'Entegrasyon adı gereklidir',
      continue: 'Devam Et',
      addAnImageToRepresentYourIntegration: 'Entegrasyonunuzu temsil etmek için bir resim ekleyin.',
      description: 'Açıklama',
      submit: 'Gönder',
      youHaveSuccessfullyCreatedIntegration: 'Entegrasyon başarıyla oluşturuldu. Şimdi bunu yapılandırabilirsiniz.',
      apiUrl: 'API URL',
      pleaseEnterAValidApiUrl: 'Lütfen geçerli bir API URL girin',
      requestMethod: 'İstek Yöntemi',
      get: 'Get',
      post: 'Post',
      put: 'Put',
      patch: 'Patch',
      queryParameter: 'Sorgu Parametresi',
      addParameter: ' Parametre Ekle',
      mapsWith: ' ile eşleştir',
      question: 'Soru',
      addQuestion: ' Soru Ekle',
      select: 'Seç',
      selectParameter: 'Parametre Seçin',
      header: 'Başlık',
      addHeader: ' Başlık Ekle',
      value: 'Değer',
      connect: 'Bağlan',
      requestBody: 'İstek Gövdesi',
      validate: 'Doğrula',
      key: 'Anahtar',

      // value: 'Value',
      // connect: 'Connect',
    },

    myIntegrations: {
      integrationDetails: {
        canAlsoBeIntegratedWith: 'bu ücretsiz CRM ile de entegre edilebilir.',
        apiUrl: 'API URL',
        pleaseEnterAValidApiUrl: 'Lütfen geçerli bir API URL girin',
        requestMethod: 'İstek Yöntemi:',
        get: 'Get',
        post: 'Post',
        put: 'Put',
        patch: 'Patch',
        queryParameter: 'Sorgu Parametresi',
        addParameter: ' Parametre Ekle',
        header: 'Başlık',
        addHeader: ' Başlık Ekle',
        requestBody: 'İstek Gövdesi',
        validateJson: 'JSON Doğrula',
        connect: 'Bağlan',
        update: 'Güncelle',
        delete: 'Sil',
        selectTheQuestion: 'Kullanıcının cevap olarak göndermek istediği soruyu seçin',
        appTooltip: {
          typeYourJsonCode: 'JSON kodunuzu yazın',
          clickToValidateJsonCode: 'JSON kodunu doğrulamak için tıklayın',
        }
      },

      list: {
        customAppsIntegrations: 'Özel Uygulama Entegrasyonları',
        integrateYourAppsWith: 'Kendi uygulamalarınızı şununla entegre edin: ',
        help: 'Yardım',
        addIntegration: ' Entegrasyon Ekle',
        name: 'Adı',
        status: 'Durum',
        action: 'İşlem',
        installed: 'Yüklendi',
        edit: 'Düzenle',
        connect: 'Bağlan',
        requestYourIntegration: 'Entegrasyonunuzu isteyin',
        toRequestNewIntegration: 'Yeni bir entegrasyon talep etmek için lütfen aşağıdaki bilgileri girin ve' +
          ' ardından size döneceğiz.',
        emailId: 'E-posta Kimliği',
        integrationName: 'Entegrasyon Adı',
        contactNumber: 'İletişim Numarası',
        howSoonYouWantToAddThisIntegration: 'Bu entegrasyonu ne kadar hızlı eklemek istersiniz',
        sendRequest: 'Talep Gönder',
        cancel: 'İptal',
        delete: 'Sil',
        oopsOwnIntegrationsAreSupportedOnlyIn: 'Üzgünüz! Kendi entegrasyonlarınız sadece şurada desteklenir',
        plan: 'Plan',
        pleaseUpgradeTo: 'Lütfen yükseltin',
        toCreateOwnIntegrations: 'kendi entegrasyonlarınızı oluşturmak için',
        appTooltip: {
          integrations: 'Entegrasyonlar',
          noIntegrationsFound: 'Entegrasyon bulunamadı!',
          nameOfYourApp: 'Uygulamanızın Adı',
          clickToConnectApp: 'Uygulamayı bağlamak için tıklayın',
          statusAsConnectedOrNotConnected: 'Bağlı / bağlı değil olarak durum',
        }
      }

    }
  }
};
