import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SharedService } from 'src/appv2/modules/shared/shared.service';
import { State } from 'src/appv2/ngrx/reducers';
import { BotsService } from 'src/appv2/services/bots.service';
import { CanvasService } from 'src/appv2/services/canvas.service';
import { NetworkService } from 'src/appv2/services/network.service';
import { environment } from 'src/environments/environment';
import { websiteTooTipText } from 'src/appv2/tooltip';
import { updateBot } from 'src/appv2/ngrx/actions/bot.actions';
import { share } from 'rxjs/operators';
import { ConfigurationService } from '../../../../services/configuration.service';
declare var $: any;
import * as download from 'downloadjs';
import { v4 as uuid } from 'uuid';
import { env } from 'process';
import { LanguageService } from 'src/appv2/services/language.service';
import { AgencyService } from 'src/appv2/services/agency.service';
import { SubscriptionService } from 'src/appv2/services/subscription.service';
@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  encapsulation: ViewEncapsulation.None // ViewEncapsulation.None to apply css on dynamically added html via flowy
})
export class CanvasComponent implements OnInit {
  staticText: any;
  isSearchFocused = false;
  showCanvas = false;
  show = true;
  canvasUrl = '';
  flowsOpen = false;
  brandName = '';
  currentFlowId = '';
  initialFetch = true;
  environment = environment;
  storeSubscribe$: Subscription;
  canvasView: 'v1' | 'v2' = 'v1';
  flows: { id: string, name: string }[] = [];
  botType: 'website' | 'telegram' | 'whatsapp' | 'facebook' | 'instagram';
  showChatbotPreview = false;
  storeLoaded = false;
  isRender = false;
  templates: Array<object>;
  Alltemplates: Array<object>;
  bot: any;
  toolTip = websiteTooTipText;
  copyScript = '';
  brandPhone: string;
  jsonExport = false;
  jsonExportStatus: 'AVAILABLE' | 'INITIATED' = 'INITIATED';
  exportedFlow: any;
  currentDate: Date;
  @ViewChild('canvas') iframe: ElementRef;
  showDropdown = false;
  previewOptionList = [{ title: 'Website', value: 'website' }, { title: 'Landing Page', value: 'landing' }];
  flowData: { payload: any, platform: any };
  flowDetails: { name: string };
  websiteBotComponents = ['statement', 'question', 'name', 'email', 'phone', 'button', 'multi_select', 'appointment', 'image', 'rating', 'contact', 'date', 'range', 'number', 'location', 'video', 'file', 'smart_question', 'chatGPT', 'live_chat', 'AI'];
  private upgradeMessage = 'Oops! Integrations are not available in baby plan. Please upgrade to King plan for integrations.';

  constructor(
    public router: Router,
    private store: Store<State>,
    private botService: BotsService,
    private sharedService: SharedService,
    private networkService: NetworkService,
    public canvasService: CanvasService,
    private configurationService: ConfigurationService,
    private languageService: LanguageService,
    private ngZone: NgZone,
    private agencyService: AgencyService,
    private subscriptionService: SubscriptionService
  ) {
    this.flowDetails = { name: sessionStorage.getItem('flowName') };
    this.staticText = languageService.getStaticText().botBuilder.canvas;
    this.storeSubscribe$ = this.store.select(state => state)
      .subscribe(({ bots, agencyDetails, profile }) => {
        this.brandName = profile.agency?.meta?.brand || '';
        this.brandPhone = environment.rootAgencyDomain.includes(window.location.host) ?
          '919740374298' : agencyDetails?.contact?.phone?.number;
        const bot: any = bots.find(i => (i._id || (i as any).bot._id) === this.sharedService.selectedBotMeta._id);
        this.bot = bot;

        if (bot && this.initialFetch) {
          this.botType = bot.type;
          this.storeLoaded = true;

          if (bot.type !== 'website') {
            this.initialFetch = false;
            this.flowsOpen = true;
            this.getFlows(bot._id || (bot as any).bot.accountApiKey || (bot as any).bot._id);
          } else {
            this.initialFetch = false;
            this.currentFlowId = (bot as any).flows[0]?._id;
            this.getUrl();
            this.copyScript = `${environment.landingPage}${this.bot._id}/${this.bot._user}`;

          }
          this.configurationService.updateLeadSquared('chatflowInitiated', {
            value: this.botType
          })
            .catch(console.log);
        }
        this.upgradeMessage = this.staticText.OopsIntegrationAreNotAvailable + this.getPlanName('baby')
          + this.staticText.pleaseUpgradeTo + this.getPlanName('king') + this.staticText.planForIntegration;
      });
    this.networkService
      .httpRequest('bots/templates')
      .then((response: any) => {
        this.templates = response;
        this.Alltemplates = response;
      })
      .catch((error) => { });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.sharedService.sideBarStatus.next('collapsed');
    }, 390);

    window.addEventListener('message', (event) => {

      // Handle the received message
      this.ngZone.run(() => {
        const receivedMessage = event.data;
        if (receivedMessage === 'trainAI') {
          this.router.navigate(['/bot-builder/train-bot/website']);
        } else if (receivedMessage === 'manageTeam') {
          this.router.navigate(['/access-control/users']);
        } else if (receivedMessage === 'configApi') {
          this.router.navigate(['/developer/api']);
        } else if (receivedMessage.type === 'flowName') {
          this.flowDetails = { name: receivedMessage.flowName };
        } else if (receivedMessage === 'googleCalendar') {
          this.subscriptionService.isBabyPlan
            ? this.sharedService.openUpgradeModal.next({ isUpgradeModal: true, textMessage: this.upgradeMessage })
            : this.router.navigate(['/bot-builder/integration/all-integrations/google-calendar']);
        } else if (receivedMessage === 'simply-book') {
          this.subscriptionService.isBabyPlan
            ? this.sharedService.openUpgradeModal.next({ isUpgradeModal: true, textMessage: this.upgradeMessage })
            : this.router.navigate(['/bot-builder/integration/all-integrations/simplybook']);
        } else if (receivedMessage.type === 'apiInProgress') {
          const currentState = this.sharedService.unsavedWarning.getValue();
          this.sharedService.unsavedWarning.next({ ...currentState, apiInProgress: receivedMessage.isHitting });
        }
      });
    });
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.showDropdown = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(event: BeforeUnloadEvent): void {
    const unsavedWarning = this.sharedService.unsavedWarning.getValue();
    if (unsavedWarning.apiInProgress) {
      event.returnValue = true;
    }
  }

  getUrl() {
    // this.sharedService.loader('start');
    this.canvasUrl = `${environment.canvasUrl}/${localStorage.getItem('impersonate') || localStorage.getItem('token')}/${this.sharedService.selectedBotMeta._id}/${this.currentFlowId}/${this.botType}/${this.canvasView}/${JSON.parse(localStorage.getItem('language')).value}`;
    this.showCanvas = true;
  }

  seeAllFlow() {
    this.flowsOpen = !this.flowsOpen;
  }

  getFlows(botId) {
    let query: Promise<any>;

    switch (this.botType) {
      case 'facebook':
        query = this.botService.getFacebookBotFlows(botId);
        break;
      case 'telegram':
        query = this.botService.getTelegramBotFlows(botId);
        break;
      case 'whatsapp':
        query = this.botService.getWhatsappBotFlows(botId);
        break;
      case 'instagram':
        query = this.botService.getInstagramBotFlows(botId);
        break;

      default:
        return [];
    }

    query
      .then(data => {
        this.flows = data;
        if (sessionStorage.getItem('flowId') && sessionStorage.getItem('flowId') !== 'null' && sessionStorage.getItem('flowId') !== 'undefined') {
          this.currentFlowId = sessionStorage.getItem('flowId');
          this.getUrl();
        } else {
          this.currentFlowId = data[0]?._id;
          sessionStorage.setItem('flowId', this.currentFlowId);

          if (!!this.currentFlowId) {
            this.getUrl();
          }
        }
      })
      .catch((err) => {
        this.sharedService.showToast('error', '', err.message || err);
      })
      .finally(() => this.isRender = true);

  }

  selectFlow(flowId: string) {
    sessionStorage.setItem('flowId', flowId);
    this.currentFlowId = flowId;
    this.getUrl();
  }

  createFlow() {
    switch (this.botType) {
      case 'facebook':
        this.router.navigateByUrl('/bot-builder/facebook/flows');
        break;
      case 'telegram':
        this.router.navigateByUrl('/bot-builder/telegram/flows');
        break;
      case 'whatsapp':
        this.router.navigateByUrl('/bot-builder/whatsapp/flows');
        break;
      case 'instagram':
        this.router.navigateByUrl('/bot-builder/instagram/flows');
        break;
      default:
        break;
    }
  }

  hireAgent() {
    window.open(
      `https://wa.me/${this.brandPhone}/?text=Hi there! I need help creating a chat flow using ${this.brandName}.
      Can you please help me create a chatbot for my business?`,
      '_blank'
    );
  }

  search(value) {
    this.templates = this.Alltemplates.filter((template: any) =>
      template.name.toLowerCase().includes(value.toLowerCase(), 0)
    );
  }

  showImportFlow() {
    document.getElementById('importTemplateModalButton').click();
  }

  importFlow(template) {
    this.sharedService.loader('start');
    this.networkService.httpRequest(`bots/flow/${template._id}`)
      .then((response: any) => {
        let questions = [];
        if (response && response[0] && response[0].flows && response[0].flows[0]
          && response[0].flows[0].questions && response[0].flows[0].questions.length) {
          questions = response[0].flows[0].questions;
        }

        questions = this.sharedService.getWhiteLabelledFlow(questions, this.brandName);

        this.store.dispatch(updateBot({
          bot: this.sharedService.selectedBotMeta._id, update: {
            ...this.bot,
            flows: [
              { ...this.bot.flows[0], questions }
            ]
          }
        }));
        const payload = {
          ...this.bot,
          flows: [
            { ...this.bot.flows[0], questions }
          ]
        };
        this.configurationService.updateLeadSquared('templateSelected', { value: template?.name })
          .catch(console.log);
        return payload;
      })
      .then((payload) => {
        return this.networkService.httpRequest(`bots/${this.sharedService.selectedBotMeta._id}`, {
          ...payload
        }, 'PUT');
      })
      .then(() => {
        this.sharedService.showToast('success', '', 'Flow imported successfully');
        this.showCanvas = false;
        setTimeout(() => {
          this.showCanvas = true;
          $('#importTemplate').modal('hide');
          this.sharedService.loader('stop');
        }, 300);
      })
      .catch(() => {
        this.sharedService.showToast('error', '', 'Error Occured');
        this.sharedService.loader('stop');
      });
  }

  iframeLoadEvent(): void {
    this.sharedService.loader('stop');
  }

  showLandingPreview() {
    window.open(this.copyScript, '_blank');
  }

  /**
   * Preview/Test Telegram Bot
   */
  telegramWeb(url: string) {
    window.open('https://web.telegram.org/k/#@' + url, '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=20000, top=20000, width=700, height=1000vh, visible=none');
  }

  facebookWeb(url: string) {
    window.open(`https://www.messenger.com/t/${url}/`, '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=20000, top=20000, width=700, height=1000vh, visible=none');
  }

  whatsAppWeb() {
    let phoneNumber = this.bot.isDemoBot
      ? environment.demoBotNumber
      : this.bot?.profile?.phoneNumber;

    phoneNumber = phoneNumber
      .split(' ')
      .join('')
      .split('+')
      .join('');

    window.open(`https://wa.me/${phoneNumber}`, '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=20000, top=20000, width=700, height=1000vh, visible=none');
  }

  instagramWeb(userName: string) {
    window.open(`https://ig.me/m/${userName}`, '_blank', 'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=20000, top=20000, width=700, height=1000vh, visible=none');
  }

  createExportedFlow(data) {
    if (!data.flows[0].name) {
      this.sharedService.loader('stop');
      return this.sharedService.showToast(
        'error',
        '',
        'You need to provide flow name'
      );
    }

    if (!this.flowData.platform || this.flowData.platform !== 'WEBSITE') {
      this.sharedService.loader('stop');
      return this.sharedService.showToast(
        'error',
        '',
        'Uh-oh. The flow you are trying to import is based on another platform.'
      );
    }

    const areQuestionsValid = data.flows[0].questions.every(question => {
      return this.websiteBotComponents.includes(question.type);
    });

    if (!areQuestionsValid) {
      this.sharedService.loader('stop');
      return this.sharedService.showToast(
        'error',
        '',
        'The flow you are trying to import has been tampered with or corrupted. Please try again with a new export.'
      );
    }

    this.sharedService.loader('start');
    this.networkService.httpRequest(`bots/${this.sharedService.selectedBotMeta._id}`, {
      ...data
    }, 'PUT')
      .then((response: any) => {
        this.showCanvas = false;
        setTimeout(() => {
          this.showCanvas = true;
          $('#import-modal').click();
        }, 300);
        this.sharedService.showToast('success', '', 'Flow imported successfully');
      })
      .finally(() => {
        this.sharedService.loader('stop');
      })
      .catch((error) => {
        this.sharedService.showToast('error', '', error);
      });
  }

  async importFlows(event: any): Promise<void> {
    try {
      const file = event.target.files[0];

      if (file.type !== 'application/json') {
        event.target.value = ''; // Empty the input field
        this.sharedService.showToast(
          'error',
          '',
          'Uh-oh. The uploaded file isn\'t supported. Please upload a .json file and retry.'
        );
        return;
      }

      const form = new FormData();
      form.append('file', file, file?.name);
      const fsize = file.size;
      const fsizeReadable = Math.round(fsize / 1024);
      // The size of the file.
      if (fsizeReadable > 20480) {
        this.sharedService.showToast('warning', '', 'File too Big, please select a file less than 20MB');
        return;
      }

      this.sharedService.loader('start');
      const json = await this.networkService.httpFormRequest('upload', form, 'POST');

      setTimeout(async () => {
        try {
          const res = await fetch(json as string);
          if (!res.ok) {
            throw new Error('Failed to fetch the response');
          }
          if (res.headers.get('content-length') === '0') {
            throw new Error('The JSON file is empty');
          }
          const jsonResponse = await res.json();
          this.flowData = jsonResponse;
          if (this.flowData.payload.flows) {
            const updatedFlow = this.exportDataSanitize(jsonResponse.payload);
            this.flowData = { ...this.flowData, payload: updatedFlow };
            this.createExportedFlow(updatedFlow);
          } else {
            this.sharedService.showToast(
              'error',
              '',
              'Uh-oh. The flow you are trying to import is based on another platform.'
            );
          }
        } catch (err) {
          this.sharedService.showToast('error', '', err.message || err);
        } finally {
          event.target.value = '';
          this.sharedService.loader('stop');
        }
      }, 5000);
    } catch (err) {
      event.target.value = '';
      this.sharedService.loader('stop');
      this.sharedService.showToast('error', '', err.message || err);
    }
  }


  exportFlow() {
    this.jsonExport = true;
    this.networkService
      .httpRequest(`bots/flow/${this.sharedService.selectedBotMeta._id}`)
      .then((result) => {
        const data = {
          platform: 'WEBSITE',
          payload: result[0]
        };
        this.exportedFlow = JSON.stringify(data);
      })
      .catch((error) => {
        this.sharedService.showToast('error', '', error);
      })
      .finally(() => {
        this.currentDate = new Date();
        this.jsonExportStatus = 'AVAILABLE';
      });
  }

  closeExport() {
    this.jsonExport = !this.jsonExport;
  }

  downloadExport() {
    download(this.exportedFlow, 'flow.json', 'application/json');
  }

  exportDataSanitize(payload) {
    const updatedIds = [];
    const data = {
      ...payload,
      flows: payload.flows.map((flow) => ({
        ...flow,
        _id: undefined,
        id: uuid(),
        questions: flow.questions.map((question) => {
          const id = uuid();
          updatedIds.push({ oldId: question.id, newId: id });
          return {
            ...question,
            _id: undefined,
            id
          };
        })
      }))
    };

    const newFlow = data.flows.map((flow) => ({
      ...flow,
      questions: flow.questions.map((question) => ({
        ...question,
        next: {
          ...question.next,
          type: question?.next?.type || 'question',
          target: question?.next?.target && question?.next?.target !== 'end'
            ? updatedIds.find((id) => id.oldId === question?.next?.target)?.newId : question?.next?.target === 'end' ? 'end' : ''
        },
        options: question.options
          ? question.options.map((option) => ({
            ...option,
            _id: undefined,
            id: uuid(),
            next: {
              ...option.next,
              type: option?.next?.type || 'question',
              target: option?.next?.target !== 'end'
                ? updatedIds.find((id) => id.oldId === option?.next?.target)?.newId || option?.next?.target
                : option?.next?.target === 'end' ? 'end' : ''
            }
          }))
          : [],
      })),
    }));

    return payload = { ...payload, _id: this.sharedService.selectedBotMeta._id, flows: newFlow };
  }

  togglePreviewDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  previewChatBot(botType: 'website' | 'landing') {
    switch (botType) {
      case 'website':
        this.canvasService.showChatbotPreview = !this.canvasService.showChatbotPreview;
        this.canvasService.emitChatPreviewStatus();
        this.togglePreviewDropdown();
        break;
      case 'landing':
        window.open(`${this.agencyService.getDomain('landingPage')}/${this.bot._id}/${this.bot._user}`, '_blank');
        this.togglePreviewDropdown();
        break;
      default:
        break;
    }
  }

  goBack() {
    switch (this.sharedService.selectedBotMeta.type) {
      case 'whatsapp':
        return this.router.navigate([`/bot-builder/whatsapp/flows`]);
      case 'facebook':
        return this.router.navigate(['/bot-builder/facebook/flows']);
      case 'instagram':
        return this.router.navigate(['/bot-builder/instagram/flows']);
      case 'telegram':
        return this.router.navigate(['/bot-builder/telegram/flows']);
      case 'website':
        return this.router.navigate(['/bot-builder/getting-started']);
      default:
        return this.router.navigate(['/bot-builder/getting-started']);
    }
  }

  changeCanvasView(view) {
    const currentState = this.sharedService.unsavedWarning.getValue();
    this.sharedService.unsavedWarning.next({
      ...currentState,
      apiInProgress: false
    });

    this.canvasView = view;
    this.getUrl();
  }

  getPlanName(type: 'baby' | 'king') {
    // If plan name is default then  add Plan with name baby or king
    return type === 'king'
      ? this.sharedService.plansDisplayName.value.king + `${this.sharedService.plansDisplayName.value.king.toLowerCase() === 'king' ? ' Plan' : ''}`
      : this.sharedService.plansDisplayName.value.baby + `${this.sharedService.plansDisplayName.value.baby.toLowerCase() === 'baby' ? ' Plan' : ''}`;
  }

}
