import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessagesService } from '../modules/inbox/components/messages/messages.service';
import { loadNewLiveChatRequests, removeLiveChatRequests } from '../ngrx/actions/live-chat.action';
import { removeOwnLiveChats } from '../ngrx/actions/own-live-chat.action';
import { CommonService } from './common.service';
import { DesktopNotificationService } from './desktop-notifictaion.service';


@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private currentTime: number;
  debounceTimer: any;
  public state: any = {
    website: [],
    whatsapp: []
  };

  public liveChatRequest: any;
  public socket: any;

  notification = {
    count: 0,
    userName: '',
    usersCount: 0,
    lastMessage: '',
    uniqueUsers: []
  };

  advanceMessage: BehaviorSubject<{ advanceMessage: string, uuid: string } | null> = new BehaviorSubject(null);
  chatTransferred: BehaviorSubject<{ mode: 'BOT' | 'WHATSAPP', uuid: string } | null> = new BehaviorSubject(null);
  chatTransferredByUser: BehaviorSubject<{ uuid: string }> = new BehaviorSubject(null);

  // TODO: Remove once ßeta is live
  newWhatsappMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  newFacebookMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  newTelegramMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  newInstagramMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  newInboxSubscriber: BehaviorSubject<any> = new BehaviorSubject(null);

  // New common handler
  newMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  newInboxMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  newNotification: BehaviorSubject<any | 'reset'> = new BehaviorSubject(null);
  demoBotVerification: BehaviorSubject<any> = new BehaviorSubject(null);
  // Unread message count for update on sidebar count
  unreadMessageCount: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private store: Store<any>,
    private commonService: CommonService,
    private messageService: MessagesService,
    private desktopNotificationService: DesktopNotificationService
  ) {
    this.store.select(state => {
      return state.website;
    })
      .subscribe(state => {
        this.state.website = state;
      });

    this.newNotification.subscribe(data => {
      if (data === 'reset') {
        this.notification = {
          count: 0, userName: '', usersCount: 0, lastMessage: '', uniqueUsers: []
        };
      }
    });
  }

  makeConnection(payload = {
    token: localStorage.getItem('userToken'),
    user: '2'
  }) {
    this.socket =
      io(`${environment.socket}`, {
        reconnection: true,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5,
        path: '/ws/chatbot',
        query: {
          token: payload.token,
          user: payload.user
        }
      });

    this.registerEvents();
  }

  registerEvents() {
    this.socket.on('message', this.handleWebsiteMessage);
    this.socket.on('NAVIGATION', this.handleNavigation);
    this.socket.on('new-chat-request', (payload) => {
      this.handleNewChatRequest(payload);
    });
    this.socket.on('transferred-chat-request', (payload) => {
      if (typeof (payload) === 'string') {
        this.handleNewChatRequest(JSON.parse(payload));
      } else {
        this.handleNewChatRequest(payload);
      }
    });
    this.socket.on('chat-request-expired', (payload) => {
      this.store.dispatch(removeLiveChatRequests({ requestId: payload.requestId }));
    });
    this.socket.on('chat-transferred', (payload) => {
      this.chatTransferred.next(payload);
      this.store.dispatch(removeOwnLiveChats({ uuid: payload.uuid }));
      // this.router.navigateByUrl(`/inbox/own-live`);
    });
    this.socket.on('adv-message', (payload) => {
      this.advanceMessage.next(payload);
    });
    // this.socket.on('WEBSITE_JOIN', (payload) => {
    //   this.socket.emit('WEBSITE_JOIN', payload);
    // });
    // this.socket.on('USER_LEAVE', (payload) => {
    //   this.store.dispatch(websiteAction.deleteWebsiteChat({ id: payload.id }));
    // });
    this.socket.on('new-whatsapp-message', (payload) => {
      this.newWhatsappMessage.next(payload);
      this.newMessage.next({ ...payload, isWhatsApp: true });
    });
    this.socket.on('new-facebook-message', (payload) => {
      this.newFacebookMessage.next(payload);
      this.newMessage.next({ ...payload, isFacebook: true });
    });
    this.socket.on('new-telegram-message', (payload) => {
      this.newTelegramMessage.next(payload);
      this.newMessage.next({ ...payload, isTelegram: true });
    });
    this.socket.on('new-instagram-message', (payload) => {
      this.newInstagramMessage.next(payload);
      this.newMessage.next({ ...payload, isInstagram: true });
    });
    this.socket.on('inbox-message', (payload) => {
      this.newInboxMessage.next(payload);
      this.unreadMessageCount.next(true);
    });
    this.socket.on('whatsapp-subscriber', (payload) => {
      this.newInboxSubscriber.next(payload);
    });

    this.socket.on('dN', (data: any) => {
      if (data) {
        if (!this.notification.uniqueUsers.some(userId => userId === data._user)) {
          this.notification.uniqueUsers.push(data._user);
        }

        this.notification = {
          ...this.notification,
          count: this.notification.count + 1,
          userName: data.name || this.notification.userName,
          usersCount: this.notification.uniqueUsers.length,
          lastMessage: data.message,
        };
      }
      this.newNotification.next(this.notification);
    });


    this.socket.on('new-desktop-notification', (payload) => {
      switch (payload.notificationType) {
        case 'NEW_MESSAGE':
          this.desktopNotificationService.pushNewInboxMessageNotification('New Message', payload.data, {});
          break;
        case 'NEW_LEAD':
          this.desktopNotificationService.pushNewLeadNotification('New Lead', payload.data);
          break;
        case 'LIVE_CHAT':
          this.desktopNotificationService.pushLiveChatRequestNotification('Live Chat', payload.data);
          break;
        default:
          break;
      }
    });

    this.socket.on('demo-bot-verified', (payload) => {
      this.demoBotVerification.next(payload);
    });
  }

  sendMessage(event, payload) {
    this.socket?.emit(event, payload);
    this.messageService.newMessage$.next(payload);
  }

  startTyping(event, payload) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() =>
      this.socket?.emit(event, payload), 500);
  }

  deleteConnection() {
    this.socket.disconnect();
  }


  handleWebsiteMessage = (payload) => {

    this.messageService.newMessage$.next(payload);
    this.newMessage.next({ ...payload, isWebsite: true });

    setTimeout(() => this.advanceMessage.next(null), 500);
    // switch (Notification.permission) {
    //   case 'granted': {
    //     if (payload.messageBy === 'user') {
    //       this.commonService.sendNotification('New message', payload.text);
    //     }
    //     break;
    //   }
    //   case 'denied': {
    //     break;
    //   }
    //   case 'default': {
    //     if (payload.messageBy === 'user') {
    //       this.commonService.requestNotificationPermission().then(() => {
    //         this.commonService.sendNotification('New message', payload.text);
    //       });
    //     }
    //     break;
    //   }
    //   default: {
    //   }
    // }

    // if (Date.now() >= Number(sessionStorage.getItem('beepIntervalTime')) || !(sessionStorage.getItem('beepIntervalTime'))) {
    //   if ((window as any).document.hidden) {
    //     this.currentTime = Date.now() + 300000;
    //     sessionStorage.setItem('beepIntervalTime', JSON.stringify(this.currentTime));

    //     const beep: any = (window as any).document.getElementById('beep');
    //     beep.play();
    //   }
    // }
  }

  handleNavigation(payload) {
  }

  handleNewChatRequest(payload) {
    this.store.dispatch(loadNewLiveChatRequests({ requests: payload }));
  }

  transferChat(payload) {
    this.socket.emit('transfer-chat', payload);
    if (payload.type && payload.type === 'WHATSAPP') {
      this.router.navigateByUrl(`/inbox/own-live`);
    } else {
      this.router.navigateByUrl(`/inbox/own-live`);
    }
  }
}
