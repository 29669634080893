import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../../../services/subscription.service';
import { SharedService } from '../../../modules/shared/shared.service';
import { OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LanguageService } from 'src/appv2/services/language.service';
import AgencyDetails from 'src/appv2/models/agency-details';

@Component({
  selector: 'app-payment-link',
  templateUrl: './payment-link.component.html',
  styleUrls: ['./payment-link.component.scss']
})
export class PaymentLinkComponent implements OnInit, OnDestroy {
  pendingPayments: Array<any> = [];
  showError: boolean;
  storeSubscription$: Subscription;
  selectedPaymentLinKId: string;
  staticText: any;
  modal: any = { handler: () => { }, show: false };
  agencyDetails: AgencyDetails;

  constructor(
    private subscriptionService: SubscriptionService,
    private sharedService: SharedService,
    private store: Store<any>,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.staticText = this.languageService.getStaticText().shared.paymentLink;
    // this.subscriptionService.getPendingPayments()
    //   .then((payments: Array<any>) => {
    //     this.pendingPayments = payments;
    //   });

    this.storeSubscription$ = this.store.subscribe(
      ({ profile, agencyDetails }) => {
        this.showError = profile.limitOver;
        this.agencyDetails = agencyDetails;
      });
  }

  ngOnDestroy(): void {
    this.storeSubscription$.unsubscribe();
  }


  initialDiscardPayment(linkId: string): void {
    this.selectedPaymentLinKId = linkId;
    this.modal.handler = this.discardPayment.bind(this);
    this.modal.show = true;
  }

  discardPayment(userInput: string) {

    this.modal.show = false;

    if (userInput) {
      this.sharedService.loader('start');
      this.subscriptionService.discardPaymentLink({ linkId: this.selectedPaymentLinKId })
        .then(() => {
          this.pendingPayments = this.pendingPayments.filter(p => p.linkId !== this.selectedPaymentLinKId);
          this.sharedService.showToast('success', '', 'Discard successfully');
        })
        .catch((error) => {
          this.sharedService.showToast('error', '', error?.message || error);
        }).finally(() => {
          this.sharedService.loader('stop');
        });
    }
  }

}
